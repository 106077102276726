import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import AppMarketingHistory from "../../../../AppComponents/Forms/AppMarketingHistory";
import { SettingPageMainBoxTitle } from "../../../../Customization/CommonElements";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import UploadTemplate from "../../SMS/BroadCastTemplate/UploadTemplate";
import {
  AnalyticsWrapper,
  BroadcastListWrapper,
  WrapperContainer,
} from "../../SMS/BroadcastHistory/SmsHistoryStyles";
import React from "react";
import { BroadCastTitleBar } from "./HistoryStyles";
import { BroadcastTopTitle } from "../../../../TableStyle";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";

const EmailBroadcastHistory = () => {
  const header = [
    "Name",
    "Sent On",
    "Recipients",
    "Status",
    "Sent",
    "Delivered",
    "Failed",
    "Opened",
    "Clicked",
    "Bounced",
    "Actions",
  ];
  const keyList = [
    "broadcast_name",
    "sent_on",
    "recipients",
    "status",
    "sent",
    "delivered",
    "failed",
    "opened",
    "clicked",
    "bounced",
    "",
  ];
  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const has_low_balance = localStorage.getItem("has_low_balance");
  const handleNavigate = () => {
    navigate("/broadcast/email/create-broadcast/1");
  };
  return (
    <React.Fragment>
      <WrapperContainer style={{ padding: "0px 16px" }}>
        {/* <AnalyticsWrapper></AnalyticsWrapper> */}

        <BroadcastListWrapper style={{ padding: 0 }}>
          <BroadcastTopTitle style={{ padding: "16px 0px" }}>
            <Box className="topTitle-left">
              <Typography fontSize="16px" fontWeight="500" component="h6">
                Email Broadcast List
              </Typography>
            </Box>
            <Box className="topTitle-right">
              <ProtectedAppButton
                moduleId="email_template"
                specificPermissionKey="create"
                variant="outline"
                onClick={() => navigate("/broadcast/email/create-template")}
                style={{ minWidth: "170px" }}
              >
                Create Template
                <AppMaterialIcons iconName="add" />
              </ProtectedAppButton>
              <ProtectedAppButton
                moduleId="email_broadcast_crud"
                specificPermissionKey="create"
                onClick={handleNavigate}
                style={{ minWidth: "170px" }}
                isDisabled={has_low_balance === "true"}
                title={
                  has_low_balance === "true"
                    ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit."
                    : ""
                }
              >
                Create Broadcast
                <AppMaterialIcons iconName="add" />
              </ProtectedAppButton>
            </Box>
          </BroadcastTopTitle>

          <AppMarketingHistory
            height={"238"}
            customStyle={{ background: "#fff" }}
            type="email"
            header={header}
            keyList={keyList}
            skeltonLoaderHeight={"282"}
          />
        </BroadcastListWrapper>
      </WrapperContainer>
      <AppModel
        isShowing={isShowing}
        onClose={() => toggle()}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <UploadTemplate />
      </AppModel>
    </React.Fragment>
  );
};

// Default Export of component
export default EmailBroadcastHistory;
