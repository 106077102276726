// vendors
import { createAsyncThunk } from "@reduxjs/toolkit";
import { FlowBuilderService } from "../../../Services/Apis/FlowBuilder";
import {
  setActiveFlow,
  setActiveNode,
  updateModifiedData,
  setUnSavedNodeId,
  setQuickAction,
  setButtonsList,
  setDateConfiguration,
  setTimeConfiguration,
  setCarouselData,
} from "./main.slice";

export const fetchFlows = createAsyncThunk(
  "flowBuilder/fetchFlows",
  async ({ botId }: { botId: { botid: string } }) => {
    return FlowBuilderService.getFlows(
      {},
      { appid: JSON.stringify(botId) }
    ).then((response: any) => response.data.data as any[]);
  }
);

export const fetchFlow = createAsyncThunk(
  "flowBuilder/fetchFlow",
  async ({ botId, flowId }: { botId: { botid: string }; flowId: string }) => {
    return FlowBuilderService.getFlowById(flowId.toString(), {
      appid: JSON.stringify(botId),
    }).then((response: any) => {
      if (response.data.status !== 200) {
        return [];
      }
      return response.data.data as any[];
    });
  }
);

export const createFlow = createAsyncThunk(
  "flowBuilder/createFlow",
  async ({ botId }: { botId: { botid: string } }, { dispatch }) => {
    return FlowBuilderService.createFlow(
      { name: "Untitled" },
      {
        appid: JSON.stringify(botId),
      }
    ).then((response: any) => {
      dispatch(fetchFlows({ botId }));
      return response.data.data as any[];
    });
  }
);

export const deleteFlow = createAsyncThunk(
  "flowBuilder/deleteFlow",
  async (
    { botId, qid }: { botId: { botid: string }; qid: string },
    { dispatch }
  ) => {
    return FlowBuilderService.deleteFlow(
      qid,
      {},
      {
        appid: JSON.stringify(botId),
      }
    ).then((response: any) => {
      dispatch(fetchFlows({ botId }));
      return response.data.data as any[];
    });
  }
);

export const updateFlow = createAsyncThunk(
  "flowBuilder/updateFlow",
  async (
    {
      botId,
      qid,
      value,
    }: { botId: { botid: string }; qid: string; value: string },
    { dispatch }
  ) => {
    return FlowBuilderService.updateFlow(
      qid,
      { name: value },
      { appid: JSON.stringify(botId) }
    ).then((response: any) => {
      dispatch(fetchFlows({ botId })).then(() => {
        dispatch(setActiveFlow(qid));
      });
      return response.data.data as any[];
    });
  }
);

export const deleteNode = createAsyncThunk(
  "flowBuilder/deleteNode",
  async (
    {
      botId,
      qid,
      nodeId,
      isInBetween,
      fetchFlowNeeded = true,
      parentId,
      id,
      childrens,
    }: {
      botId: { botid: string };
      qid: string;
      nodeId: string;
      isInBetween: boolean;
      fetchFlowNeeded?: boolean;
      parentId?: string;
      id?: string;
      childrens?: string[];
    },
    { dispatch }
  ) => {
    return FlowBuilderService.deleteNode(
      {
        qid,
        nodeId: nodeId,
        isInBetween,
      },
      { appid: JSON.stringify(botId) }
    ).then((response: any) => {
      if(parentId) {
        dispatch(
          updateNode({
            botId,
            data: {
              nodeId: parentId,
              qid: qid,
              hasOTPVarification: false,
              isParent: Boolean(parentId),
              id,
              childrens
            },
          })
        );
      }

      if(!parentId && childrens) {        
        childrens.map((children) => {
          return FlowBuilderService.deleteNode(
            {
              qid,
              nodeId: children,
              isInBetween,
            },
            { appid: JSON.stringify(botId) }
          ).then(() => {
            fetchFlowNeeded && dispatch(fetchFlow({ botId, flowId: qid }));
          });
        });
      }

      fetchFlowNeeded && dispatch(fetchFlow({ botId, flowId: qid }));
      return response as any[];
    });
  }
);

export const createNode = createAsyncThunk(
  "flowBuilder/createNode",
  async (
    { botId, node }: { botId: { botid: string }; node: any },
    { dispatch }
  ) => {
    return FlowBuilderService.createNode(node, {
      appid: JSON.stringify(botId),
    }).then((response: any) => {
      dispatch(fetchFlow({ botId, flowId: node.qid }));
      return response.data as any[];
    });
  }
);

export const updateNode = createAsyncThunk(
  "flowBuilder/createNode",
  async (
    { botId, data }: { botId: { botid: string }; data: any },
    { dispatch }
  ) => {

    return FlowBuilderService.updateNode(data, {
      appid: JSON.stringify(botId),
    }).then((response: any) => {
      dispatch(fetchFlow({ botId, flowId: data.qid }));
      dispatch(setActiveNode(null));
      dispatch(updateModifiedData([]));
      dispatch(setUnSavedNodeId(null));
      dispatch(setQuickAction([]));
      dispatch(setButtonsList([]));
      dispatch(setDateConfiguration({}));
      dispatch(setTimeConfiguration({}));
      dispatch(setCarouselData([]));
      return response.data as any[];
    });
  }
);
