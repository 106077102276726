import { AxiosResponse } from 'axios';
import { getHttpService } from "../HttpService";

import {
  CHATBOT_CELERY_ENDPOINTS,
} from "./Endpoints";

import { chatbotConsoleCeleryAxios } from '../../Utils';


export class ChatbotConsoleCeleryService {
  static httpService: any = getHttpService(chatbotConsoleCeleryAxios);

  static chatbotConsoleCeleryTask = (data: any): Promise<AxiosResponse<any>> => { 
    return ChatbotConsoleCeleryService.httpService.post({url:CHATBOT_CELERY_ENDPOINTS.CELERYTASKS, data: data});
  };
 
}