import { IconButton } from "@mui/material";
import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  allMessagesStore,
  removeAttachedReplyId,
} from "../../Store/Slices/socket/AllMessagesSlice";
import { isEmptyObject } from "../../Utils";
import { AppMaterialIcons } from "../appComponents/Icons";
import { MESSAGE_TYPES } from "../utils/Enum";
import { getFileNameFromUrl, getObjectByidFromArray } from "../utils/utils";
import { StyledReactVideoPlayer } from "../Styles/StyledScreens";

const FallbackImage = require("../../assets/images/Image_not_available.png");

interface Props {}

const AppReplyScreen: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const {
    showAttachedReplyItem,
    attachedReplyId,
    userInfoData,
    isInputEnabled,
    allMessages,
  } = useAppSelector(allMessagesStore);

  const item = getObjectByidFromArray(
    allMessages,
    attachedReplyId,
    "message_id"
  );
  const userMessage = (!item?.isUser ? true : false) || false;

  const text = getMessageTextFromMessage(item?.value, item?.type);

  const handleCancelClick = () => {
    dispatch(removeAttachedReplyId());
  };

  if (
    isInputEnabled &&
    userInfoData?.channel === "whatsapp" &&
    showAttachedReplyItem &&
    attachedReplyId &&
    !isEmptyObject(item)
  )
    return (
      <StyledReplyScreenWrapper userMessage={userMessage}>
        <div className="title">{userMessage ? "You" : userInfoData?.name} </div>
        <div className="replyItemWrapper">
          {item?.type === MESSAGE_TYPES.IMAGE ? (
            <img
              src={item?.value?.url}
              alt={item?.value?.text}
              className="renderImage"
              onError={(error: any) => {
                error.target.src = FallbackImage;
              }}
            />
          ) : null}
          {item?.type === MESSAGE_TYPES.VIDEO ? (
            <AppMaterialIcons iconName="Movie"
            />
          ) : null}
          {item?.type === MESSAGE_TYPES.CAROUSEL ? (
            <AppMaterialIcons
              iconName="ViewCarousel"
              // style={{ fontSize: "1.8rem" }}
            />
          ) : null}
          {item?.type === MESSAGE_TYPES.FILE ||
          item?.type === MESSAGE_TYPES.DOCUMENT ? (
            <AppMaterialIcons
              iconName="Description"
              // style={{ fontSize: "1.8rem" }}
            />
          ) : null}
          {item?.type === MESSAGE_TYPES.AUDIO ? (
            <AppMaterialIcons
              iconName="Audiotrack"
              // style={{ fontSize: "1.8rem" }}
            />
          ) : null}
          <div
            className="replyItem"
            dangerouslySetInnerHTML={{
              __html: text ?? "",
            }}
          />
        </div>
        <div className="closeAction">
          <IconButton onClick={() => handleCancelClick()}>
            <AppMaterialIcons iconName="Close" />
          </IconButton>
        </div>
      </StyledReplyScreenWrapper>
    );

  return null;
};

export default memo(AppReplyScreen);

const getMessageTextFromMessage = (value: any, type: string) => {
  let text = "";

  if (type === MESSAGE_TYPES.TEXT) {
    text = value;
  }

  if (type === MESSAGE_TYPES.CAROUSEL) {
    text =
      value?.[0]?.title ||
      value?.[0]?.subtitle ||
      value?.[0]?.image ||
      "Carousel";
  }

  if (
    type === MESSAGE_TYPES.IMAGE ||
    type === MESSAGE_TYPES.FILE ||
    type === MESSAGE_TYPES.VIDEO ||
    type === MESSAGE_TYPES.ATTACHMENT ||
    type === MESSAGE_TYPES.DOCUMENT ||
    type === MESSAGE_TYPES.AUDIO
  ) {
    text = value?.text || getFileNameFromUrl(value?.url) || type;
  }

  return text;
};

interface StyledProps {
  userMessage?: boolean;
}

const StyledReplyScreenWrapper = styled.div<StyledProps>`
  && {
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    border-radius: 8px;
    border: 1px solid ${theme.palette.primary.main};
    border-left: 4px solid ${theme.palette.primary.main};
    width: 95%;
    position: absolute;
    z-index: 1;
    bottom: 182px;
    left: 2%;
    right: 3%;
    padding: 0.3rem 0.5rem 0.3rem 0.5em;

    .title {
      font-size: 0.8rem;
      font-weight: 600;
      color: ${(props: StyledProps) =>
        props?.userMessage
          ? theme.palette.primary.main
          : theme.palette.default.text};
    }

    .replyItemWrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      width: 100%;
      padding: 4px 8px;
      border-radius: 8px;
      background-color: ${theme.palette.default.greyishWhite};

      .replyItem {
        color: ${theme.palette.default.text};
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 14.5px;
        line-height: 22px;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      svg{
       font-size : 1.5rem;
       color: ${theme.palette.default.darkGrey};
      }
    }

    .closeAction {
      position: absolute;
      z-index: 1;
      right: -0.6rem;
      top: -0.6rem;
      .MuiIconButton-root {
        background-color: ${theme.palette.default.white};
        width: 30px;
        height: 30px;
      }
      svg {
        font-size: 1.3rem;
      }
    }

    .renderImage {
      width: 40px;
      height: 40px;
      border-radius: 6px;
      object-fit: cover;
      border: 0.5px solid ${theme.palette.default.darkGrey};
    }
  }
`;
