import { createAsyncThunk } from "@reduxjs/toolkit";
import { APIKeyNetworkService } from "./apikeyNetworkService";

export class APIKeyActions {
  // get API Key
  static getAPIKey = createAsyncThunk(
    "ApiKeySlice/getAPIKey",
    (data: any, { dispatch, rejectWithValue }) => {
      return APIKeyNetworkService.getAPIKey()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );
  // generate API Key
  static generatedAPIKey = createAsyncThunk(
    "ApiKeySlice/generatedAPIKey",
    (data: any, { dispatch, rejectWithValue }) => {
      debugger;
      return APIKeyNetworkService.generatedAPIKey()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );
}
