import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";

export const WrapperScroller = styled.div`
  overflow-x: auto;
  background-color: #fff;
  border-left: 1px solid ${theme.palette.default.border};
    border-right: 1px solid ${theme.palette.default.border};
    border-bottom: 1px solid ${theme.palette.default.border};
 .main_container{
    width: 100%;
 }

 .tableBody {
  height: calc(100vh - 353px);
  background-color:#ffffff;
  width: fit-content;
  min-width: 100%;
  }

  .MuiTablePagination-root {
    padding: 0 !important;
    overflow: hidden;
    height: 3rem;
    background-color: red;
    .MuiTablePagination-toolbar {
      padding: 0 !important;
    }
  }

  .MuiTablePagination-actions {
    svg {
      color: ${theme.palette.default.darkGrey} !important;
    }
  }

  .table {
}
.tableStickyHead {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff !important;
  border-bottom: 1px solid ${theme.palette.default.border};
  width: fit-content;
  min-width: 100%;
  // padding:20px;
}


.tablHeadRow {
    display: flex;
    flex: 1 !important;
    width: fit-content;
    background-color: #fff;
   
  }

  .tableHeadCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align:center;
    min-width: 220px;
    max-width: 300px;
    padding: 12px 20px;
    &:first-child {
      min-width: 240px;
      width:auto;
      text-align:center;
    }
  }

  .tableBodyCell {
    min-width: 220px;
    word-break: break-all;
    display: flex;
    align-items: center;
    padding:20px;
    justify-content: flex-start;
    
    
    &:first-child {
      min-width: 240px;
      width:auto;
      text-align:center;
      // border: 1px solid;
      // text-decoration: none;
      // maxWidth: 180px;
      // white-space: no-wrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    &:second-child {
      min-width: 220px;
      width:auto;
      text-align:center;
    }
    &:third-child {
      min-width: 220px;
      width:auto;
      text-align:center;
    }
    &:fourth-child {
      min-width: 220px;
      width:auto;
      text-align:center;
    }
    
    
  }
  .tableBodyRow {
    display: flex;
    width: fit-content;
    min-width: 100%;
    border-bottom: 1px solid ${theme.palette.default.border};
    height:50px;
  }
  .activeRow {
    width: fit-content;
    background-color: ${theme.palette.action.selected};
  }

  .no-more-entries {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }
}`