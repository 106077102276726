import { Autocomplete, Box, Popper, TextField } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import uniqid from "uniqid";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
interface StyledProps {
  isError?: any;
}

interface Props {
  Options: any;
  label?: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  tooltip?: any;
  children?: React.ReactNode;
  initialValue?: any;
  [otherProps: string]: any;
}

const AppFormAutoSearchField: React.FC<Props> = ({
  Options = [],
  label,
  placeholder = "Select an option",
  name,
  disabled = false,
  tooltip,
  initialValue,
  ...otherProps
}) => {
  const {
    // setFieldTouched,
    // handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    setTouched,
  } = useFormikContext<any>();

  const [value, setValue] = useState<any>(initialValue || Options?.[0]);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      setFieldValue(name, initialValue);
    } else if (!getIn(values, name) && !value) {
    } else {
      let temp = Options?.find((item: any) => {
        if (item?.qid) {
          return item?.qid === getIn(values, name)?.qid;
        } else if (item?.id) {
          return item?.id === getIn(values, name)?.id;
        } else {
          return item?.value === getIn(values, name)?.value;
        }
      });
      setValue(temp ? temp : null);
      setFieldValue(name, temp ? temp : null);
    }
  }, [getIn(values, name), initialValue]);

  return (
    <Div>
      <Label>
        {" "}
        {label}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <Autocomplete
        autoHighlight={true}
        autoComplete={true}
        autoSelect={false}
        value={value}
        disabled={disabled ? true : false}
        openOnFocus={true}
        onChange={(event: any, newValue: any) => {
          setFieldValue(name, newValue);
          setValue(newValue);
          setTouched({ ...touched, [name]: true });
        }}
        options={Options || []}
        PopperComponent={(props) => {
          return <StyledPopper {...props}>{props.children}</StyledPopper>;
        }}
        componentsProps={{
          paper: {
            sx: {},
          },
        }}
        isOptionEqualToValue={(option: any, value: any) =>
          option?.id === value?.id ||
          option?.qid === value?.qid ||
          option?.value === value?.value
        }
        getOptionLabel={(option: any) =>
          option?.name || option?.id || option?.question || option?.qid
        }
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
            key={uniqid()}
          >
            {option?.name || option?.id || option?.question || option?.qid}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            {...otherProps}
            onFocus={() => {
              setIsFocus(true);
            }}
            onBlur={() => {
              setTouched({ ...touched, [name]: true });
              setIsFocus(false);
            }}
            style={{
              border: isFocus
                ? `2px solid ${theme.palette.default.border}`
                : (getIn(values, name) == 0 || !getIn(values, name)) &&
                  getIn(touched, name)
                ? `2px solid  ${theme.palette.default.error}`
                : `2px solid transparent`,
              borderRadius: "4px",
            }}
            placeholder={isFocus ? "" : placeholder}
          />
        )}
      />

      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default AppFormAutoSearchField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 1rem;

    .MuiInputLabel-root {
    }
    .MuiSvgIcon-root {
      font-size: 1.1rem;
    }
    .MuiOutlinedInput-root {
      width: 100%;
      height: 40px !important;
      border-radius: 4px !important;
      background-color: #f5f6f8 !important;

      outline: none !important;
      padding: 8px 16px 8px 14px;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.95rem !important;
      line-height: 15px;
      position: relative;
      display: flex !important;
      align-items: center !important;
      border: none;
      color: ${theme.palette.default.text} !important;
    }

    .MuiOutlinedInput-root,
    .MuiAutocomplete-inputRoot {
      padding-right: 0px !important;
      background-color: #f5f6f8;
      border-radius: 4px !important;
    }

    .MuiOutlinedInput-input {
      border-radius: 4px !important;
      &::placeholder {
        color: ${theme.palette.default.text} !important;
        font-weight: 400;
        opacity: 1;
        font-size: 1rem;
      }
      &:active {
        /* border: 2px solid transparent; */
      }
    }

    .MuiIconButton-root {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.border} !important;
        font-size: 1.2rem;
      }
    }

    .MuiAutocomplete-endAdornment {
      right: 1px !important;
      top: calc(50% - 17px);
    }

    .MuiOutlinedInput-root .MuiAutocomplete-input {
      padding: 0px !important;
    }

    .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    }

    .MuiAutocomplete-inputFocused {
      /* background-color: #ffffff; */
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 14px;
      cursor: pointer;
    }
  }
`;
const StyledPopper = styled(Popper)`
  && {
    /* background-color: #ca0a0a; */
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgb(0 0 0 / 10%) !important;
    padding: 0px;
    margin: 0 !important;
    transition: opacity 600ms !important;

    li {
    }

    .MuiAutocomplete-paper {
      background-color: #ffffff;
      margin: 4px -2px 0px -2px !important;
      width: calc(100% + 4px) !important;
      padding: 0rem 0 0 6px;
      ul {
        padding: 6px 0rem 4px !important;
        margin-bottom: 8px;
        max-height: 350px;
      }

      li {
        font-size: 0.95rem !important;
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        height: 38px;
        margin-bottom: 2px;
        color: #282828;
        opacity: 1;
        background-color: white;

        &:hover,
        &:active,
        &:visited,
        &:target {
          /* background-color: #f5f6f8; */
        }
      }
    }
  }
`;
