import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import uniqid from "uniqid";
import { TOOLTIP_POSITIONS_OPTIONS, WIDGET_POSITIONS_OPTIONS } from "./Enum";

export const DefinedCustomDates = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  today: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  },
  yesterday: {
    startDate: startOfDay(addDays(new Date(), -1)),
    endDate: endOfDay(new Date()),
  },
  last7Days: {
    startDate: addDays(new Date(Date.now()), -6),
    endDate: endOfDay(new Date()),
  },
  last30Days: {
    startDate: addDays(new Date(Date.now()), -29),
    endDate: endOfDay(new Date()),
  },
  last90Days: {
    startDate: addDays(new Date(Date.now()), -89),
    endDate: endOfDay(new Date()),
  },
  custom: {
    startDate: addDays(new Date(Date.now()), -179),
    endDate: endOfDay(new Date()),
  },
};

export const DefinedCustomDatesExcludeToday = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),

  today: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  },
  yesterday: {
    startDate: startOfDay(addDays(new Date(), -1)),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  last7Days: {
    startDate: addDays(new Date(Date.now()), -7),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  last30Days: {
    startDate: addDays(new Date(Date.now()), -30),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  last90Days: {
    startDate: addDays(new Date(Date.now()), -90),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
  custom: {
    startDate: addDays(new Date(Date.now()), -180),
    endDate: endOfDay(addDays(new Date(), -1)),
  },
};

export const defaultRanges = [
  {
    label: "Today",
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    label: "Yesterday",
    startDate: addDays(new Date(Date.now()), -1),
    endDate: addDays(new Date(Date.now()), -1),
  },
  {
    label: "Last 7 Days",
    startDate: DefinedCustomDates?.last7Days?.startDate,
    endDate: DefinedCustomDates?.last7Days?.endDate,
  },
  {
    label: "Last 30 Days",
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
  },
  {
    label: "Last 90 Days",
    startDate: DefinedCustomDates?.last90Days?.startDate,
    endDate: DefinedCustomDates?.last90Days?.endDate,
  },
  {
    label: "Custom",
    startDate: DefinedCustomDates?.custom?.startDate,
    endDate: DefinedCustomDates?.custom?.endDate,
  },
]

export const defaultRangesExcludeToday = [
  {
    label: "Yesterday",
    startDate: addDays(new Date(Date.now()), -1),
    endDate: addDays(new Date(Date.now()), -1),
  },
  {
    label: "Last 7 Days",
    startDate: DefinedCustomDatesExcludeToday?.last7Days?.startDate,
    endDate: DefinedCustomDatesExcludeToday?.last7Days?.endDate,
  },
  {
    label: "Last 30 Days",
    startDate: DefinedCustomDatesExcludeToday?.last30Days?.startDate,
    endDate: DefinedCustomDatesExcludeToday?.last30Days?.endDate,
  },
  {
    label: "Last 90 Days",
    startDate: DefinedCustomDatesExcludeToday?.last90Days?.startDate,
    endDate: DefinedCustomDatesExcludeToday?.last90Days?.endDate,
  },
  {
    label: "Custom",
    startDate: DefinedCustomDatesExcludeToday?.custom?.startDate,
    endDate: DefinedCustomDatesExcludeToday?.custom?.endDate,
  },
]

export const DateRangeOptions = [
  {
    id: uniqid(),
    name: "Today",
    value: "today",
    description: null,
    range: {
      startDate: DefinedCustomDates.startOfToday,
      endDate: DefinedCustomDates.endOfToday,
    },
  },
  {
    id: uniqid(),
    name: "Yesterday",
    value: "yesterday",
    description: null,
    range: {
      startDate: DefinedCustomDates.startOfYesterday,
      endDate: DefinedCustomDates.endOfYesterday,
    },
  },
  {
    id: uniqid(),
    name: "Last 7 Days",
    value: "7",
    description: null,
    range: {
      startDate: addDays(new Date(Date.now()), -7),
      endDate: DefinedCustomDates.endOfYesterday,
    },
  },
  {
    id: uniqid(),
    name: "Last 30 Days",
    value: "30",
    description: null,
    range: {
      startDate: addDays(new Date(Date.now()), -30),
      endDate: DefinedCustomDates.endOfYesterday,
    },
  },
  {
    id: uniqid(),
    name: "Last 90 Days",
    value: "90",
    description: null,
    range: {
      startDate: addDays(new Date(Date.now()), -90),
      endDate: DefinedCustomDates.endOfYesterday,
    },
  },
  {
    id: uniqid(),
    name: "custom",
    value: "custom",
    description: null,
    range: {
      startDate: addDays(new Date(Date.now()), -179),
      endDate: DefinedCustomDates.endOfYesterday,
    },
  },
];

export const DefinedCustomFutureDates = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfNextWeek: startOfWeek(addDays(new Date(), 7)),
  endOfNextWeek: endOfWeek(addDays(new Date(), 7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfTommorow: startOfDay(addDays(new Date(), 1)),
  endOfTommorow: endOfDay(addDays(new Date(), 1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfNextMonth: startOfMonth(addMonths(new Date(), 1)),
  endOfNextMonth: endOfMonth(addMonths(new Date(), 1)),

  today: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  },
  tommorow: {
    startDate: startOfDay(addDays(new Date(), 1)),
    endDate: endOfDay(addDays(new Date(), 1)),
  },
  next7Days: {
    startDate: endOfDay(new Date()),
    endDate: addDays(new Date(Date.now()), 6),
  },
  next30Days: {
    startDate: endOfDay(new Date()),
    endDate: addDays(new Date(Date.now()), 29),
  },
  next90Days: {
    startDate: endOfDay(new Date()),
    endDate: addDays(new Date(Date.now()), 89),
  },
  custom: {
    startDate: addDays(new Date(Date.now()), -179),
    endDate: endOfDay(new Date()),
  },
};

export const FutureDateRangeOptions = [
  {
    id: uniqid(),
    name: "Today",
    value: "today",
    description: null,
    range: {
      startDate: DefinedCustomFutureDates.startOfToday,
      endDate: DefinedCustomFutureDates.endOfToday,
    },
  },
  {
    id: uniqid(),
    name: "Tomorrow",
    value: "tomorrow",
    description: null,
    range: {
      startDate: DefinedCustomFutureDates.startOfTommorow,
      endDate: DefinedCustomFutureDates.endOfTommorow,
    },
  },
  {
    id: uniqid(),
    name: "Next 7 Days",
    value: "7",
    description: null,
    range: {
      startDate: DefinedCustomFutureDates.startOfTommorow,
      endDate: addDays(new Date(Date.now()), 7),
    },
  },
  {
    id: uniqid(),
    name: "Next 30 Days",
    value: "30",
    description: null,
    range: {
      startDate: DefinedCustomFutureDates.startOfTommorow,
      endDate: addDays(new Date(Date.now()), 30),
    },
  },
  {
    id: uniqid(),
    name: "Last 90 Days",
    value: "90",
    description: null,
    range: {
      startDate: DefinedCustomFutureDates.startOfTommorow,
      endDate: addDays(new Date(Date.now()), 90),
    },
  },
  {
    id: uniqid(),
    name: "custom",
    value: "custom",
    description: null,
    range: {
      startDate: addDays(new Date(Date.now()), -179),
      endDate: DefinedCustomDates.endOfYesterday,
    },
  },
];

export const DefaultIndiaMobileFormat = {
  countryCode: "91",
  dialCode: "91",
  format: "+.. .....-.....",
  iso2: "in",
  name: "India",
  priority: 0,
  regions: ["asia"],
};

export const notSupportedHexCodes = ["#ffffff"];

export const topUserMenuOptions = [
  // {
  //   id: uniqid(),
  //   name: "Plans",
  //   avatar: "ri-copper-diamond-line",
  //   url: "/plans",
  //   isAgentRoute: false,
  // },
  {
    name: "Logout",
    avatar: "ri-logout-circle-r-line",
    url: "/login",
    isAgentRoute: true,
  },
];

export const LeftTopMenuOptions = [
  {
    icon: "https://assets.getalabs.com/left_menu_assets/analytics.svg",
    label: "Analytics",
    value: "analytics",
    url: "/analytics/dashboard-summary",
    moduleId: "analytics",
    isAvailable: false,
    isDisabled: false,
    children: [
      {
        icon: "https://assets.getalabs.com/left_menu_assets/summary_analytics.svg",
        label: "Summary",
        value: "dashboard-summary",
        url: "/analytics/dashboard-summary",
        moduleId: "conversation_summary",
        parentModuleId: "analytics",
        isDisabled: false,
        isAvailable: false,
      },
      {
        icon: "https://assets.getalabs.com/left_menu_assets/agent_analytics.svg",
        label: "Agent",
        value: "dashboard-agent",
        url: "/analytics/dashboard-agent",
        moduleId: "agent_summary",
        parentModuleId: "analytics",
        isDisabled: false,
        isAvailable: false,
      },
      {
        icon: "https://assets.getalabs.com/left_menu_assets/bot_analytics.svg",
        label: "Bot",
        value: "dashboard-bot",
        url: "/analytics/dashboard-bot",
        moduleId: "bot_summary",
        parentModuleId: "analytics",
        isDisabled: false,
        isAvailable: false,
      },
    ],
  },
  {
    icon: "https://assets.getalabs.com/left_menu_assets/conversation.svg",
    label: "Conversation Inbox",
    value: "conversations",
    url: "/conversations/chats",
    moduleId: "conversation_inbox",
    isDisabled: false,
    isAvailable: false,
  },
  {
    icon: "https://assets.getalabs.com/left_menu_assets/bot_builder.svg",
    label: "Bot Builder",
    value: "setup",
    url: "workspace/{0}/setup/bots",
    moduleId: "bot_builder",
    isDisabled: false,
    isAvailable: false,
  },
  {
    icon: "https://assets.getalabs.com/left_menu_assets/contacts.svg",
    label: "Contacts",
    value: "contacts",
    url: "/contacts/list/",
    moduleId: "contacts",
    isDisabled: false,
    isAvailable: false,
    children: [
      {
        icon: null,
        label: "Contacts",
        value: "contacts",
        url: "/contacts/list",
        moduleId: "contacts",
        parentModuleId: "contacts",
        isDisabled: false,
        isAvailable: false,
      },
      {
        icon: null,
        label: "Marketing List",
        value: "marketing_list",
        url: "/contacts/marketing/list",
        moduleId: "marketing_list",
        parentModuleId: "contacts",
        isDisabled: false,
        isAvailable: false,
      },
      {
        icon: null,
        label: "Segments",
        value: "segment",
        url: "/contacts/segment/list",
        moduleId: "segments",
        parentModuleId: "contacts",
        isDisabled: false,
        isAvailable: false,
      },
    ],
  },
  {
    icon: "https://assets.getalabs.com/left_menu_assets/broadcast.svg",
    label: "Broadcast",
    value: "broadcast",
    url: "/broadcast/whatsapp/history/",
    moduleId: "broadcast",
    isDisabled: false,
    isAvailable: false,
    children: [
      {
        icon: "https://assets.getalabs.com/left_menu_assets/whatsapp.svg",
        label: "WhatsApp Broadcast",
        value: "broadcast",
        url: "/broadcast/whatsapp/history",
        moduleId: "whatsapp_broadcast",
        parentModuleId: "broadcast",
        isDisabled: false,
        isAvailable: false,
      },
      {
        icon: "https://assets.getalabs.com/left_menu_assets/email.svg",
        label: "Email Broadcast",
        value: "broadcast",
        url: "/broadcast/email/dashboard/summary",
        moduleId: "email_broadcast",
        parentModuleId: "broadcast",
        isDisabled: false,
        isAvailable: false,
      },
      {
        icon: "https://assets.getalabs.com/left_menu_assets/sms.svg",
        label: "SMS Broadcast",
        value: "broadcast",
        url: "/broadcast/sms/dashboard/summary",
        moduleId: "sms_broadcast",
        parentModuleId: "broadcast",
        isDisabled: false,
        isAvailable: false,
      },
    ],
  },
  {
    icon: "https://assets.getalabs.com/left_menu_assets/automation.svg",
    label: "Automation",
    value: "automation",
    url: "/automation/lists",
    moduleId: "automation",
    isDisabled: false,
    isAvailable: false,
  },
];

export const LeftBottomMenuOptions = [
  {
    icon: "https://assets.getalabs.com/left_menu_assets/marketplace.svg",
    label: "marketplace",
    value: "marketplace",
    url: "/marketplace",
    moduleId: "marketplace",
    isDisabled: false,
    isAvailable: true,
  },
  {
    icon: "https://assets.getalabs.com/left_menu_assets/subscription.svg",
    label: "Subscription",
    value: "subscription",
    url: "/subscription/subscription-plans",
    moduleId: "subscriptions",
    isDisabled: false,
    isAvailable: true,
  },
  {
    icon: "https://assets.getalabs.com/left_menu_assets/settings.svg",
    label: "Settings",
    value: "settings",
    url: "/settings/users-and-permissions/users/list",
    moduleId: "settings",
    isDisabled: false,
    isAvailable: true,
  },
];

export const botTypeOptions = [
  {
    id: 1,
    name: "Lead Generation",
    description: null,
  },
  {
    id: 2,
    name: "Admissions",
    description: null,
  },
  {
    id: 3,
    name: "General Enquiry",
    description: null,
  },
  {
    id: 4,
    name: "Appointment Booking",
    description: null,
  },
];

export const NotificationBlockIconOptions = [
  {
    id: 1,
    name: "Alert",
    description: null,
  },
  {
    id: 2,
    name: "Ticket",
    description: null,
  },
  {
    id: 3,
    name: "Broadcast",
    description: null,
  },
  {
    id: 4,
    name: "Bell",
    description: null,
  },
];

export const botCategoriesOption = [
  {
    id: 1,
    name: "Travel Booking",
    description: null,
  },
  {
    id: 2,
    name: "Discount & Offers",
    description: null,
  },
  {
    id: 3,
    name: "Health Appointment",
    description: null,
  },
  {
    id: 4,
    name: "Education",
    description: null,
  },
];

export const ChatVisibilityOptions = [
  {
    id: 1,
    name: "Mobiles",
    description: null,
  },
  {
    id: 2,
    name: "Tablets",
    description: null,
  },
  {
    id: 3,
    name: "Desktop",
    description: null,
  },
  {
    id: 4,
    name: "All",
    description: null,
  },
];

export const SoundVisibilityOptions = [
  {
    id: 1,
    name: "Mobiles",
    description: null,
  },
  {
    id: 2,
    name: "Tablets",
    description: null,
  },
  {
    id: 3,
    name: "Desktop",
    description: null,
  },
  {
    id: 4,
    name: "All",
    description: null,
  },
];

export const LanguageOptions = [
  {
    id: uniqid(),
    name: "English",
    value: "english",
    description: null,
    enabled: true,
  },
  {
    id: uniqid(),
    name: "Hindi",
    value: "hindi",
    description: null,
    enabled: true,
  },
  {
    id: uniqid(),
    name: "Marathi",
    value: "marathi",
    description: null,
    enabled: true,
  },
  {
    id: uniqid(),
    name: "Gujrati",
    value: "gujrati",
    description: null,
    enabled: true,
  },
  {
    id: uniqid(),
    name: "Punjabi",
    value: "punjabi",
    description: null,
    enabled: true,
  },
];

export const FontOptions = [
  {
    id: uniqid(),
    name: "Poppins",
    value: "'Poppins', sans-serif",
    description: null,
  },
  {
    id: uniqid(),
    name: "Roboto",
    value: "Roboto",
    description: null,
  },
  {
    id: uniqid(),
    name: "Open Sans",
    value: "Open Sans",
    description: null,
  },
  {
    id: uniqid(),
    name: "Inter",
    value: "Inter",
    description: null,
  },
];
export const RetryOptions = [
  {
    id: uniqid(),
    name: "1",
    value: "1",
    description: null,
  },
  {
    id: uniqid(),
    name: "2",
    value: "2",
    description: null,
  },
  {
    id: uniqid(),
    name: "3",
    value: "3",
    description: null,
  },
  {
    id: uniqid(),
    name: "4",
    value: "4",
    description: null,
  },
  {
    id: uniqid(),
    name: "5",
    value: "5",
    description: null,
  },
];

export const FontSizeOptions = [
  {
    id: uniqid(),
    name: "11px",
    value: "11",
    description: null,
  },
  {
    id: uniqid(),
    name: "12px",
    value: "12",
    description: null,
  },
  {
    id: uniqid(),
    name: "13px",
    value: "13",
    description: null,
  },
  {
    id: uniqid(),
    name: "14px",
    value: "14",
    description: null,
  },
  {
    id: uniqid(),
    name: "15px",
    value: "15",
    description: null,
  },
  {
    id: uniqid(),
    name: "16px",
    value: "16",
    description: null,
  },
  {
    id: uniqid(),
    name: "17px",
    value: "17",
    description: null,
  },
  {
    id: uniqid(),
    name: "18px",
    value: "18",
    description: null,
  },
  {
    id: uniqid(),
    name: "19px",
    value: "19",
    description: null,
  },
  {
    id: uniqid(),
    name: "20px",
    value: "20",
    description: null,
  },
];

export const widgetPositionOptions = [
  WIDGET_POSITIONS_OPTIONS.LEFT,
  WIDGET_POSITIONS_OPTIONS.RIGHT,
];

export const popupPositionOptions = [
  TOOLTIP_POSITIONS_OPTIONS.SIDE,
  TOOLTIP_POSITIONS_OPTIONS.TOP,
];
export const QuickMenuViewOptions = ["Lists", "Blocks"];
export const QuickMenuLinkOptions = ["Add Link", "Link To Flow"];
export const QuickBannerLinkOptions = ["Image", "Video"];
export const EmailDomainOptions = [
  { label: "I did not find the record described above", value: "notFound" },
  { label: "I found the record described above", value: "found" },
];

export const EmailViewOptions = [
  {
    id: uniqid(),
    value: "Modern",
    name: "Modern",
    image: require("../../src/assets/images/EmailModernStyle.png"),
  },
  // {
  //   id: uniqid(),
  //   value: "Classic",
  //   name: "Classic",
  //   image:"https://upload.wikimedia.org/wikipedia/commons/2/26/Logo_Sitio_Web.png",
  // },
];

export const RoutingMethodOptions = [
  {
    id: "Assigned",
    value: "Assigned",
    name: "Assigned",
    caption:
      "Chats help maintain an even workload distribution, especially for larger teams.",
  },
  {
    id: "Pooled",
    value: "Pooled",
    name: "Pooled",
    caption:
      "Chats will not be assigned to agents automatically, instead they will have to manually get chats from the not assigned ones.",
  },
];
export const BotTypeOptions = [
  {
    id: uniqid(),
    value: 1,
    name: "Answers",
    caption:
      "Train your bot to answer different kinds of questions so that it can figure out what the customer wants and send them to",
    icon: "ri-question-answer-line",
  },
  {
    id: uniqid(),
    value: 2,
    name: "Flow Based",
    caption:
      "Build fully functional flow based conversational bot using visual builder.",
    icon: "ri-question-answer-line",
  },
];

export const LeadsTableDefaultColumns: any = [];

export const EmailFrequencyOptions = ["Hourly", "Daily", "Weekly"];

//email_broadcast_dashboard

export const WhatsAppBroadcastDashboardOptions = [
  {
    name: "Overview",
    id: uniqid(),
    url: "/broadcast/whatsapp/broadcast-details/{0}/overview",
    isAvailable: true,
    value: "overview",
  },
  {
    name: "Logs",
    id: uniqid(),
    url: "/broadcast/whatsapp/broadcast-details/{0}/logs",
    isAvailable: true,
    value: "logs",
  },
];


export const EmailBroadcastDashboardOptions = [
  {
    name: "Overview",
    id: uniqid(),
    url: "/broadcast/email/broadcast-details/{0}/overview",
    isAvailable: true,
    value: "overview",
  },
  {
    name: "Logs",
    id: uniqid(),
    url: "/broadcast/email/broadcast-details/{0}/logs",
    isAvailable: true,
    value: "logs",
  },
];

export const SMSBroadcastDashboardOptions = [
  {
    name: "Overview",
    id: uniqid(),
    url: "/broadcast/sms/broadcast-details/{0}/overview",
    isAvailable: true,
    value: "overview",
  },
  {
    name: "Logs",
    id: uniqid(),
    url: "/broadcast/sms/broadcast-details/{0}/logs",
    isAvailable: true,
    value: "logs",
  },
];

export const SmsBroadcastDashboardSummaryOptions = [
  {
    name: "Overview",
    id: uniqid(),
    url: "/broadcast/sms/dashboard/summary",
    isAvailable: true,
    value: "summary",
    moduleId: "sms_insights",
  },
  {
    name: "Logs",
    id: uniqid(),
    url: "/broadcast/sms/dashboard/logs",
    isAvailable: true,
    value: "logs",
    moduleId: "sms_insights",
  },
];

export const WhastAppBroadcastDashboardSummaryOptions = [
  {
    name: "Overview",
    id: uniqid(),
    url: "/broadcast/whatsapp/dashboard/summary",
    isAvailable: true,
    value: "summary",
    moduleId: "sms_insights",
  },
  {
    name: "Logs",
    id: uniqid(),
    url: "/broadcast/whatsapp/dashboard/logs",
    isAvailable: true,
    value: "logs",
    moduleId: "sms_insights",
  },
];


export const EmailBroadcastDashboardSummaryOptions = [
  {
    name: "Overview",
    id: uniqid(),
    url: "/broadcast/email/dashboard/summary",
    isAvailable: true,
    value: "summary",
    moduleId: "email_analytics",
  },
  {
    name: "Logs",
    id: uniqid(),
    url: "/broadcast/email/dashboard/logs",
    isAvailable: true,
    value: "logs",
    moduleId: "email_logs",
  },
];

// bot knowladge user Queries routing urls
export const BotKnowladgeUserQueriesOptions = [
  {
    name: "Not Answered",
    id: uniqid(),
    url: "/bot/{0}/setup/bot-knowledge/user-queries/not-answered",
    isAvailable: true,
    value: "user-queries",
  },
  {
    name: "Answered",
    id: uniqid(),
    url: "/bot/{0}/setup/bot-knowledge/user-queries/answered",
    isAvailable: true,
    value: "user-queries/answered",
  },
];

export const customFormTabsOptions = [
  {
    name: "Build",
    url: "build",
    isAvailable: true,
    id: uniqid(),
    value: "sources",
  },
  {
    name: "Setting",
    url: "setting/generalSettings",
    isAvailable: true,
    id: uniqid(),
    value: "sources"
  },
  {
    name: "Style & Preview",
    url: "style-preview",
    isAvailable: true,
    id: uniqid(),
    value: "sources",
  },
];

export const automationAssignmentTabsOptions = [
  {
    name: "Contact",
    url: "contact",
    isAvailable: true,
    id: uniqid(),
    value: "contact",
    moduleId: "settings"
  },
  {
    name: "Application",
    url: "application",
    isAvailable: true,
    id: uniqid(),
    value: "application", 
    moduleId: "application_forms"
  },
];


// Settings automation options *********
export const SettingsAutomationOptions = [
  {
    name: "Rules",
    id: uniqid(),
    url: "/settings/automation/auto-assignment-rules/rules",
    isAvailable: true,
    value: "auto-assignment-rules",
  },
  // {
  //   name: "Create",
  //   id: uniqid(),
  //   url: "/settings/automation/auto-assignment-rules/contact/create",
  //   isAvailable: true,
  //   value: "auto-assignment-rules/contact/create",
  // },
];

export const SettingMenuOptions = [
  {
    label: "Users & Permissions",
    value: "users-and-permissions",
    url: "/settings/users-and-permissions",
    moduleId: "users_and_permissions",
    isAvailable: false,
    children: [
      {
        label: "Users",
        value: "users-and-permissions/users",
        url: "/settings/users-and-permissions/users/list",
        moduleId: "manage_users",
        parentModuleId: "users_and_permissions",
        isAvailable: false,
      },
      {
        label: "Roles & Permissions",
        value: "users-and-permissions/roles",
        url: "/settings/users-and-permissions/roles/list",
        moduleId: "manage_roles",
        parentModuleId: "users_and_permissions",
        isAvailable: false,
      },
    ],
  },
  {
    label: "Data Management",
    value: "data-management",
    url: "/settings/data-management",
    moduleId: "data_management",
    isAvailable: true,
    options: [
      {
        title: "Contact ",
        url: "/settings/datamanagement/contact",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Teams",
        url: "/settings/datamanagement/lifecycle",
        id: uniqid(),
        isAvailable: false,
        brandLogo: "",
      },
      {
        title: "Import History",
        url: "/settings/datamanagement/import-history",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Contacts History",
        url: "/settings/datamanagement/contact-history",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
    ],
  },

  {
    category: "Automation",
    isAvailable: true,
    options: [
      {
        title: "Auto Assignment Rules",
        url: "/settings/automation/auto-assignment-rules/contacts",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
    ],
  },
  {
    category: "Chat Settings",
    isAvailable: true,
    options: [
      {
        title: "Chat Assignment",
        url: "/settings/chat-assignment",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Labels",
        url: "/settings/labels",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Canned Responses",
        url: "/settings/canned-response",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Business Hours",
        url: "/settings/business-hours",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Inactivity Timeouts",
        url: "/settings/inactivity-timeouts",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
    ],
  },
  {
    category: "Channels",
    isAvailable: true,
    options: [
      // {
      //   title: "Website",
      //   url: "/bot/{0}/settings/lifecycle",
      //   id: uniqid(),
      //   isAvailable: false,
      //   brandLogo: "",
      // },
      {
        title: "Whatsapp",
        url: "/settings/whatsapp",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Email",
        url: "/settings/email",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Facebook",
        url: "/settings/facebook",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
      {
        title: "Instagram",
        url: "/settings/instagram",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "",
      },
    ],
  },
  {
    category: "Account Settings",
    isAvailable: false,
    children: [
      {
        label: "Import History",
        value: "data-management/import-history",
        url: "/settings/data-management/import-history",
        moduleId: "import_history",
        parentModuleId: "data_management",
        isAvailable: false,
      },
      {
        label: "Export History",
        value: "data-management/export-contact-history",
        url: "/settings/data-management/export-contact-history",
        moduleId: "export_history",
        parentModuleId: "data_management",
        isAvailable: false,
      },
      {
        label: "Contact Properties",
        value: "data-management/contact-properties/list",
        url: "/settings/data-management/contact-properties/list",
        moduleId: "manage_contact_properties",
        parentModuleId: "data_management",
        isAvailable: false,
      },
      {
        label: "Lifecycle Stages",
        value: "data-management/lifecycle",
        url: "/settings/data-management/lifecycle",
        moduleId: "manage_lifecycle",
        parentModuleId: "data_management",
        isAvailable: false,
      },
    ],
  },
  {
    label: "Chat Settings",
    value: "chat-settings",
    url: "/settings/chat-settings",
    moduleId: "chat_settings",
    isAvailable: false,
    children: [
      {
        label: "Chat Assignment",
        value: "chat-settings/assignment",
        url: "/settings/chat-settings/assignment",
        moduleId: "chat_assignment",
        parentModuleId: "chat_settings",
        isAvailable: false,
      },
      {
        label: "Labels",
        value: "chat-settings/labels",
        url: "/settings/chat-settings/labels",
        moduleId: "manage_conversation_labels",
        parentModuleId: "chat_settings",
        isAvailable: false,
      },
      {
        label: "Canned Responses",
        value: "chat-settings/canned-response",
        url: "/settings/chat-settings/canned-response",
        moduleId: "manage_canned_responses",
        parentModuleId: "chat_settings",
        isAvailable: false,
      },
      {
        label: "Business Hours",
        value: "chat-settings/business-hours",
        url: "/settings/chat-settings/business-hours",
        moduleId: "manage_business_hours",
        parentModuleId: "chat_settings",
        isAvailable: false,
      },
      {
        label: "Inactivity Timeouts",
        value: "chat-settings/inactivity-timeouts",
        url: "/settings/chat-settings/inactivity-timeouts",
        moduleId: "inactivity_timeouts",
        parentModuleId: "chat_settings",
        isAvailable: false,
      },
    ],
  },
  {
    label: "Channels",
    value: "channels",
    url: "/settings/channels",
    moduleId: "manage_channels",
    isAvailable: false,
    children: [
      {
        label: "Whatsapp",
        value: "channels/whatsapp",
        url: "/settings/channels/whatsapp",
        moduleId: "manage_channel_whatsapp",
        parentModuleId: "manage_channels",
        isAvailable: false,
      },
      {
        label: "Email",
        value: "channels/email",
        url: "/settings/channels/email",
        moduleId: "manage_channel_email",
        parentModuleId: "manage_channels",
        isAvailable: false,
      },
    ],
  },
];
export const IntegrationMenuOptions = [
  {
    category: "Other Integrations",
    isAvailable: true,
    options: [
      {
        title: "Zoho",
        url: "/integrations/zoho",
        id: uniqid(),
        isAvailable: true,
        brandLogo: "https://www.zoho.com/branding/images/zoho-logo.png",
      },

      {
        title: "Google Sheets",
        url: "/integrations/google-sheets",
        id: uniqid(),
        isAvailable: true,
        brandLogo:
          "https://upload.wikimedia.org/wikipedia/commons/3/30/Google_Sheets_logo_%282014-2020%29.svg",
      },
      {
        title: "Shopify",
        url: "/integrations/shopify",
        id: uniqid(),
        isAvailable: false,
        brandLogo:
          "https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-shopping-bag-full-color-66166b2e55d67988b56b4bd28b63c271e2b9713358cb723070a92bde17ad7d63.svg",
      },
    ],
  },

  {
    category: "Channels Integrations",
    isAvailable: false,
    options: [
      {
        title: "Facebook",
        url: "/bot/{0}/integrations/facebook",
        id: uniqid(),
        isAvailable: true,
        brandLogo:
          "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
      },
      {
        title: "Instagram",
        url: "/bot/{0}/integrations/instagram",
        id: uniqid(),
        isAvailable: true,
        brandLogo:
          "https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg",
      },
      {
        title: "Whatsapp",
        url: "/bot/{0}/integrations/whatsapp",
        id: uniqid(),
        isAvailable: false,
        brandLogo:
          "https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg",
      },
      {
        title: "Sms",
        url: "/bot/{0}/integrations/sms",
        id: uniqid(),
        isAvailable: false,
        brandLogo:
          "https://upload.wikimedia.org/wikipedia/commons/7/72/Message-icon-grey.png",
      },
    ],
  },
];

export const ChatHistorySidebarOptions = [
  {
    category: "Conversations",
    isAvailable: true,
    options: [
      {
        title: "Bot Chats",
        url: "/bot/{0}/chathistory/bot-chats",
        isAvailable: true,
        id: uniqid(),
        icon: "ri-inbox-line",
      },
      {
        icon: "ri-message-2-line",
        title: "Live Chat",
        id: uniqid(),
        url: "/bot/{0}/chathistory/live-chat",
        isAvailable: true,
      },
      {
        title: "Unassigned",
        url: "/bot/{0}/chathistory/unassigned",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-inbox-line",
      },
      {
        title: "You",
        url: "/bot/{0}/chathistory/you",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-user-line",
      },
      {
        title: "Team",
        url: "/bot/{0}/chathistory/team",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-team-line",
      },

      {
        title: "Resolved",
        url: "/bot/{0}/chathistory/resolved",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-archive-line",
      },
      {
        title: "All",
        url: "/bot/{0}/chathistory/all",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-filter-3-line",
      },
    ],
  },
  {
    category: "Channels",
    isAvailable: false,
    options: [
      {
        title: "All",
        url: "/bot/{0}/chathistory/channels",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-message-3-line",
      },
      {
        title: "Facebook",
        url: "/bot/{0}/chathistory/facebook",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-messenger-fill",
      },
      {
        title: "Instagram",
        url: "/bot/{0}/chathistory/instagram",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-instagram-line",
      },
      {
        title: "Whatsapp",
        url: "/bot/{0}/chathistory/whatsapp",
        isAvailable: false,
        id: uniqid(),
        icon: "ri-whatsapp-line",
      },
    ],
  },
];

export const SampleTableUrls = [
  // {
  //   title: "About us",
  //   url: "www.google.com/about-us",
  //   short_code: "help",
  //   response: "Hi, How can i help you?",
  //   language:'English',
  //   visitors:12332,
  // },
];

export const xLabelsHeatMap: string[] = [
  "0 AM",
  "1 AM",
  "2 AM",
  "3 AM",
  "4 AM",
  "5 AM",
  "6 AM",
  "7 AM",
  "8 AM",
  "9 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "1 PM",
  "2 PM",
  "3 PM",
  "4 PM",
  "5 PM",
  "6 PM",
  "7 PM",
  "8 PM",
  "9 PM",
  "10 PM",
  "11 PM",
];

export const yLabelsHeatMap: string[] = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const daySorterHeatMap: any = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};


export const RoleOptions = [
  {
    id: uniqid(),
    name: "Admin",
    value: 1,
    description: null,
  },
  {
    id: uniqid(),
    name: "Agent",
    value: 2,
    description: null,
  },
];
export const DepartmentOptions = [
  {
    id: uniqid(),
    name: "All",
    value: 3,
    description: null,
  },
  {
    id: uniqid(),
    name: "Management",
    value: 1,
    description: null,
  },
  {
    id: uniqid(),
    name: "Sales",
    value: 2,
    description: null,
  },
];
