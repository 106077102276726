import React, { Fragment } from "react";
import Select, { createFilter } from "react-select";
import styled from "styled-components";

import { Tooltip } from "@mui/material";
// import { AppMaterialIcons } from "../Icons";
import { theme } from "../Customization/Theme";
import { AppMaterialIcons } from "./AppMaterialIcons";
import AppErrorMessage from "./Forms/AppErrorMessage";

// import defaultProps from "react-select/dist/declarations/src/Select";
interface Props {
  options: any[];
  label?: string;
  displayKey?: string;
  valueKey?: string;
  value: any;
  placeholder?: string;
  defaultIndexValue?: number;
  name: string;
  divStyle?: any;
  tooltip?: any;
  onChangeCallbackFn: (value: any) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  defaultInputValue?: string;
  defaultValue?: any;
  isOptionDisabled?: ((option: any, selectValue: any) => boolean) | undefined;
  hideSelectedOptions?: boolean;
  [otherProps: string]: any;
  children?: React.ReactNode;
  field?: any;
  requiredSign?: boolean;
  maxWidth?: string;
  onMenuOpen?: any;
  handleOnBlur?: any;
  menuPlacement?:any;
  indent?: any;
}

const AppReactSelect: React.FC<Props> = ({
  options,
  label,
  name,
  placeholder = "Select an option",
  divStyle,
  displayKey = "label",
  valueKey = "value",
  value,
  tooltip,
  onChangeCallbackFn,
  isSearchable = true,
  isClearable = false,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  requiredSign,
  defaultInputValue,
  defaultValue,
  field,
  isOptionDisabled = (option: any) => option?.disabled,
  hideSelectedOptions = true,
  maxWidth,
  onMenuOpen,
  handleOnBlur,
  indent,
  menuPlacement = "auto",
  ...otherProps
}) => {
  // for search
  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    stringify: (option: any) => `${option[displayKey]}`,
  };
  return (
    <Div style={{ maxWidth: maxWidth }} indent={indent}>
      {label && (
        <Label>
          {requiredSign ? (
            <Fragment>
              {label}
              <sup
                className="starSuperScript"
                style={{ color: `${theme.palette.default.error}` }}
              >
                {" "}
                *
              </sup>
            </Fragment>
          ) : (
            label
          )}
          {tooltip && (
            <Tooltip title={tooltip} placement="right">
              <AppMaterialIcons iconName="HelpOutline" />
            </Tooltip>
          )}
        </Label>
      )}
      <Select
        name={name}
        value={value ?? null}
        className="custom-select"
        onChange={(e: any) => {
          onChangeCallbackFn(e);
        }}
        isDisabled={isDisabled}
        options={options}
        placeholder={placeholder || "Select an status"}
        getOptionLabel={(option: any) =>
          option?.label || option?.name || option?.value
        }
        menuPlacement={menuPlacement}
        isOptionSelected={(option: any) => {
          return option?.[valueKey] === value?.[valueKey];
        }}
        hideSelectedOptions={true}
        components={{
          IndicatorSeparator: () => null,
        }}
        //@ts-ignore
        styles={{
          indicatorSeparator: () => ({ display: "none" }),
          control: (base: any, state: any) => ({
            ...base,
            // backgroundImage: "linear-gradient(to bottom,#264966,#12344d)",
            border: "none", // Remove the default border
            boxShadow: "none", // Remove any box shadow
            svg: {
              // fill: "#fff", // Change to your desired hover color
            },
          }),
          placeholder: (base: any) => ({
            ...base,
            // color: "#fff", // Change this to your desired placeholder text color
          }),
          singleValue: (base: any) => ({
            ...base,
            // color: "#fff", // Change this to your desired text color
          }),
        }}
      />
    </Div>
  );
};

export default AppReactSelect;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: ${(props: any) => {
      return props?.indent === "horizontal" ? "row" : "column";
    }};

    width: 100%;
    max-width: 750px;
    gap: 6px;
    position: relative;
    [class$="-control"] {
      min-height: 26px;
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;

export const StyledReactSelectInput = styled(Select)`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 0 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 2.4rem;
    position: relative;
    .clearBtn {
      background-color: #f5f6f8;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }

    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 2px solid ${theme.palette.default.lightGrey};
    }
  }
`;
