import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import loginIllustration from "../../assets/images/loginIllustration.svg";
import logo from "../../assets/images/logo.svg";
import {
  BrandBox,
  LeftSide,
  LoginPageWrap,
  LogoText,
  RightSide
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { logoutAuth, useAuthStore } from "../../Store/Slices/AuthSlice";
import { device } from "../../Customization/BreakPoints";

const EmailValidation = () => {
  let { showAlert } = useNotification();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: authData } = useSelector(useAuthStore);

  const handleSignupBtn = () => {
    dispatch(logoutAuth(null));
    navigate("/signup");
  };

  const handleResendVerifyLinkBtn = () => {
    showAlert('under developement', 'warning')
  };

  // useEffect(() => {
  //   if (!authData) {
  //     // navigate('/login')
  //   }
  //   if (authData) {
  //     dispatch(logoutAuth(null));
  //     // navigate("/login");
  //   }
  // }, [authData])



  return (
    <LoginPageWrap style={{ margin: "-1rem", marginTop: '-5rem', overflow: "hidden" }}>
      <LeftSide>
        <img src={loginIllustration} alt="" />
      </LeftSide>

      <RightSide
        style={{
          marginTop: "0rem",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <ContaierBox>
          <BrandBox>
            <img src={logo} alt="" />
            <LogoText>Geta</LogoText>
          </BrandBox>

          <p className="title">
            Congratulations on taking the first step to streamline your customer relationships and boost your business efficiency with Geta.ai. <br />
          </p>

          <p className="caption">
            To get started, please check your email for the activation link and click it to verify your account. If you can't find it, check your spam folder or reach out to us for help. Excited to have you onboard!
          </p>

          <div className="actionBox">
            {/* <AppButton
              onClick={() => handleResendVerifyLinkBtn()}
            >
              Resend verification link
            </AppButton> */}

            <AppButton
              variant="outline"
              onClick={() => handleSignupBtn()}
            >
              New Signup
            </AppButton>
          </div>
        </ContaierBox>
      </RightSide>
    </LoginPageWrap>
  );
};

export default EmailValidation;

const ContaierBox = styled.div`
  && {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 55%;
    gap: 1.2rem;
    padding: 1rem;
    @media ${device.laptopM} {
      width: 65%;
    }

    /* padding-top: 10rem; */
    .title {
      font-size: 0.95rem;
      font-weight: 500;
      letter-spacing: 1px;
      color: ${theme.palette.default.text};
      font-family: ${theme.typography.fontFamily};
    }
    .caption {
      font-size: 0.9rem;
      font-weight: 400;
      letter-spacing: 1px;
      color: ${theme.palette.primary.main};
      color: ${theme.palette.default.darkGrey};
      font-family: ${theme.typography.fontFamily};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
    }

    .actionBox {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
      gap: 1.5rem;
    }
  }
`;
