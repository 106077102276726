import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { SMS_PRIMARY_COLOR, WHATSAPP_PRIMARY_COLOR } from "./Constants";
import { device } from "../../../Customization/BreakPoints";

interface StyledProps {
  colorScheme: any;
}

export const WhatsappBotArea = styled.div`
  && {
    width: 36%;
    // height: calc(100vh - 300px);
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: sticky;
    // top: 120px;
  }
`;

export const StyledBroadcastPreviewWrap = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    width: 25rem;
    max-width: 350px;
    z-index: 1;
    padding: 1rem;
    overflow: hidden;
    @media ${device.laptopL} {
      min-width: 0;
    }

    // height: 560px;
    // max-height: 750px;
    height: 100%;

    .widgetWrap {
      background-color: ${(props: any) =>
    props?.colorScheme?.chat_wall?.background
      ? props?.colorScheme?.chat_wall?.background
      : theme.palette.default.lightGrey};
      width: 100%;
      box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      overflow: hidden;
      height: 75%;
      max-height: 570px;

      .headerWrap {
        background-color: ${(props: any) =>
    props?.colorScheme?.header?.background
      ? props?.colorScheme?.header?.background
      : WHATSAPP_PRIMARY_COLOR};
        height: 56px;
        width: 100%;
        overflow: hidden;
        padding: 1rem 0.7rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .headerLeft {
          width: 100%;
          height: 100%;

          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 0.7rem;

          .headerLogo {
            width: 30px;
            height: 30px;
            background-color: ${(props: any) =>
    props?.colorScheme?.header?.text
      ? props?.colorScheme?.header?.text
      : theme.palette.default.white};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            text-transform: capitalize;
            font-size: 1.1rem;
            font-weight: 500;
            color: ${(props: any) =>
    props?.colorScheme?.header?.background
      ? props?.colorScheme?.header?.background
      : WHATSAPP_PRIMARY_COLOR};
          }

          .headerTitle {
            font-family: ${theme.typography.fontFamily};
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: ${(props: any) =>
    props?.colorScheme?.header?.text
      ? props?.colorScheme?.header?.text
      : theme.palette.default.white};
          }
        }
        .headerRight {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 1rem;

          svg {
            font-size: 1.3rem;
            color: ${(props: any) =>
    props?.colorScheme?.header?.text
      ? props?.colorScheme?.header?.text
      : theme.palette.default.white};
          }
        }
      }

      

      .chatScreenWrap {
        width: 100%;
        height: calc(100% - (60px + 56px));
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 0.5rem;
        padding: 0rem 0rem 0rem 0.5rem;
        min-height: 0 !important;
        .chat-screen__content {
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
        }

        .singleLineBox {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-end;
          gap: 0.5rem;

          .avatar {
            width: 24px;
            height: 24px;
            background-color: ${(props: any) =>
    props?.colorScheme?.header?.text
      ? props?.colorScheme?.header?.text
      : theme.palette.default.white};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            text-transform: capitalize;
            font-size: 0.85rem;
            font-weight: 400;
            color: ${(props: any) =>
    props?.colorScheme?.header?.background
      ? props?.colorScheme?.header?.background
      : SMS_PRIMARY_COLOR};
          }

          .MsgContainer {
            width: 75%;
            height: 100%;
            padding: 0.5rem 0.7rem;
            background-color: ${(props: any) =>
    props?.colorScheme?.bot_message?.background
      ? props?.colorScheme?.bot_message?.background
      : theme.palette.default.white};
            color: ${(props: any) =>
    props?.colorScheme?.bot_message?.text
      ? props?.colorScheme?.bot_message?.text
      : theme.palette.default.text};

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 0.5rem;
            border-radius: 12px 12px 12px 0px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

            .headerMsgContainer {
              font-family: ${theme.typography.fontFamily};
              font-weight: 500;
              font-size: 0.85rem;
              line-height: 18px;
              letter-spacing: 0px;
              color: ${(props: any) =>
    props?.colorScheme?.bot_message?.text
      ? props?.colorScheme?.bot_message?.text
      : theme.palette.default.white};

              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;

              width: 100%;
            }
            .mediaBox {
              background-color: ${theme.palette.default.border};

              width: 100%;
              height: 110px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              svg {
                font-size: 2rem;
                color: ${(props: any) =>
    props?.colorScheme?.header?.text
      ? props?.colorScheme?.header?.text
      : theme.palette.default.white};
              }

              img,
              video {
                border-radius: 6px;
                width: 100%;
                height: 110px;
                object-fit: cover;
              }
            }

            .bodyMsgContainer {
              width: 100%;
              font-family: ${theme.typography.fontFamily};
              font-weight: 400;
              font-size: 0.85rem;
              line-height: 21px;
              letter-spacing: 0px;
              color: ${(props: any) =>
    props?.colorScheme?.bot_message?.text
      ? props?.colorScheme?.bot_message?.text
      : theme.palette.default.text};
              word-wrap: break-word;
              overflow: hidden;
            }
            .buttonMsgContainer {
              font-family: ${theme.typography.fontFamily};
              color: ${(props: any) =>
    props?.colorScheme?.bot_message?.text
      ? props?.colorScheme?.bot_message?.text
      : theme.palette.default.text};
              font-weight: 400;
              font-size: 0.8rem;
              width: 100%;
              height: 30px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 0.5rem;
              border-top: 2px solid ${theme.palette.default.lightGrey};
              text-align: center;

              svg {
                font-size: 1.2rem;
                color: ${(props: any) =>
    props?.colorScheme?.header?.background
      ? props?.colorScheme?.header?.background
      : WHATSAPP_PRIMARY_COLOR};
              }
            }
            .footerMsgContainer {
              font-family: ${theme.typography.fontFamily};
              width: 100%;
              color: ${theme.palette.default.darkGrey};
              font-weight: 300;
              font-size: 0.7rem;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      



      .inputBarWrap {
        padding: 0.7rem 0.5rem;
        height: 60px;
        width: 100%;
        overflow: hidden;
        margin-bottom: 1rem;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;

        .inputBarTextBox {
          width: 100%;
          height: 100%;
          background-color: white;
          border-radius: 20px;
          padding: 0.2rem 0.7rem;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          gap: 0.5rem;

          .textBoxLeft {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;

            font-family: ${theme.typography.fontFamily};
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: ${theme.palette.default.darkGrey};
          }
          .textBoxRight {
            display: flex;
            flex-direction: row;
            justify-content: right;
            align-items: center;
            gap: 0.5rem;
          }

          svg {
            font-size: 1.3rem;
            color: ${theme.palette.default.darkGrey};
          }
        }

        .inputBarRightBox {
          .MuiIconButton-root {
            background-color: ${(props: any) =>
    props?.colorScheme?.header?.background
      ? props?.colorScheme?.header?.background
      : WHATSAPP_PRIMARY_COLOR};
            svg {
              font-size: 1.3rem;
              color: ${(props: any) =>
    props?.colorScheme?.header?.text
      ? props?.colorScheme?.header?.text
      : theme.palette.default.white};
            }
          }
        }
      }

    }

    .extraInfoWrap {
      background-color: ${theme.palette.default.white};
      border-radius: 6px;
      padding: 1rem;
      margin: 0 0.5rem;

      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 20px;
      letter-spacing: 0.5px;
    }
  }
`;
