import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import * as React from "react";
import styled from "styled-components";
import star_icon from "../Dash_Assests/Vector.svg";
import {
  SkeletonCSATCard,
  SkeletonProgressBar,
} from "../SkeletonLoader.tsx/SkeletonCard";
import { CSATWRapper } from "./CSAT1";
import { v4 as uuid } from "uuid";

interface Props {
  ratingCount: any;
  loading: any;
}

const CSAT: React.FC<Props> = ({ ratingCount, loading }) => {
  const colors = ["#1fd126", "#1fd126", "#d7ad3a", "#f37833", "#e60909"];
  const ratingCountArray: any = Object.values(ratingCount);
  const [totalSum, setTotalSum] = React.useState(0);

  const totalSumFn = () => {
    let temp = 0;
    for (let i = 0; i < ratingCountArray.length; i++) {
      temp += ratingCountArray[i];
    }
    setTotalSum(temp);
  };

  React.useEffect(() => {
    totalSumFn();
  }, [ratingCount]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CSATWRapper>
        {loading ? (
          <SkeletonCSATCard />
        ) : (
          [5, 4, 3, 2, 1].map((item: any, index: number) => {
            return (
              <div className="csat_child" key={uuid()}>
                <img src={star_icon} className="csat_child_icon" alt="" />
                <p className="star_count">{5 - index} </p>{" "}
                <div className="progress_div">
                  {loading ? (
                    <SkeletonProgressBar />
                  ) : (
                    <BorderLinearProgress1
                      $colorsname={colors?.[index]}
                      variant="determinate"
                      value={(ratingCount[item] / totalSum) * 100 || 0}
                      key={uuid()}
                    />
                  )}
                </div>{" "}
                <p className="progress_div_value">{ratingCount[item]} </p>
              </div>
            );
          })
        )}
      </CSATWRapper>
    </Box>
  );
};
export default CSAT;

const BorderLinearProgress1 = styled(LinearProgress)`
  && {
    height: 10px;
    border-radius: 5px;
    background-color: #e5e4e2;
    .MuiLinearProgress-bar1Determinate {
      background-color: ${(props: any) => {
        return props?.$colorsname ? `${props?.$colorsname}` : "#E5E4E2";
      }};
    }
  }
`;
