import React from "react";

import { IconButton } from "@mui/material";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import {
  StyledWidgetBotInputField,
  StyledWidgetInputBox,
} from "./styles-web-bot-static-preview";

const WebBotStaticInput = () => {
  return (
    <StyledWidgetInputBox disabled={true} className="Web-StyledWidgetInputBox">
      <StyledWidgetBotInputField
        defaultValue={""}
        placeholder={"Type a message..."}
        autoFocus={true}
        disabled={true}
      />

      <div className="inputActionBox Web-inputActionBox">
        <IconButton
          type="submit"
          disabled={true}
          disableFocusRipple
          disableRipple
        >
          <SendRoundedIcon />
        </IconButton>
      </div>
    </StyledWidgetInputBox>
  );
};

export default WebBotStaticInput;
