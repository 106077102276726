import { FieldArray, getIn, useFormikContext } from "formik";
import React, { Fragment } from "react";
import AppErrorMessage from "../AppErrorMessage";

interface Props {
  name: string;
  render: (arrayHelpers: any, value: any[]) => React.ReactNode;
}

const AppFormFieldArrayFastField = ({ name, render }: Props) => {
  const { values, errors, touched } = useFormikContext<any>();
  return (
    <Fragment>
      <FieldArray name={name}>
        {(arrayHelpers) => render(arrayHelpers, getIn(values, name))}
      </FieldArray>
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Fragment>
  );
};

export default AppFormFieldArrayFastField;
