import styled from 'styled-components';
import { theme } from '../../../Customization/Theme';

export const BroadCastTitleBar = styled.div`
& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 10px;
    margin: 0;
    border-radius: 4px;
    .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p {
            font-size: 18px;
            font-weight: 500;
        }
        .MuiSvgIcon-root {
            font-size: 1rem;
            fill: ${theme.palette.default.black};
        }
    }
    .right {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }
}
`;

export const MainBroadCastTitleBar = styled.div`
& {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    background-color: ${theme.palette.default.white};
    padding: 0rem 1rem;
    box-shadow: 0 2px 10px 0 rgb(0, 0, 0,  0.08);
    .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p {
            font-size: 18px;
            font-weight: 500;
        }
        .MuiSvgIcon-root {
            font-size: 1rem;
            fill: ${theme.palette.default.black};
        }
    }
    .right {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        p {
            font-size: 18px;
        }
    }
}
`;

export const InlineFilterFormWrapper = styled.div`
& {
    width: 100%;
    background-color: #fff;
    padding: 6px 10px;
    border-bottom: 1px solid ${theme.palette.default.grey};
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    margin-top: 1rem;
    .left{
        padding-left: 16px
    }
}
`;

export const InlineFilterForm = styled.div`
& {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .left {
        display: flex;
        align-items: center;
        p {
            font-size: 1rem;
        }
    }
    .right {

    }
}
`;


export const StickyHeader = styled.div`
& {
    position: sticky;
    top: 0;
    z-index: 9;
    background: #fff;
}
`;