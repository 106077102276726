import { useAppSelector } from "../../../../Store";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import ChannelsDeployScript from "./ChannelsDeployScript";
import WebsiteDeployScript from "./WebsiteDeployScript";

const DeployScripts = (): JSX.Element => {
  const currentBot: any = useAppSelector(useCurrentBot);
  const currentBotComponent: any = {
    "whatsapp": <ChannelsDeployScript />,
    "messenger": <ChannelsDeployScript />,
    "telegram": <ChannelsDeployScript />,
    "Web Bot": <WebsiteDeployScript />
  }
  const channeBotDeployScriptComponent = currentBotComponent[currentBot?.channel]

  return channeBotDeployScriptComponent;
};

export default DeployScripts;
