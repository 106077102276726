import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

interface Props {
  botConfig: any;
  localBotConfig: any,
  whatsappBotWelcomeText: string;
}

const INITIAL_STATE: Props = {
  botConfig: null,
  localBotConfig: null,
  whatsappBotWelcomeText: ""
};

const BotConfigSlice = createSlice({
  name: "BotConfigSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setBotConfig(state, { payload }: PayloadAction<any>) {
      state.botConfig = payload;
    },
    setLocalBotConfig(state, { payload }: PayloadAction<any>) {
      state.localBotConfig = payload;
    },

    removeBotConfig() {
      return INITIAL_STATE;
    },
    setWhatsAppBotWelcomeText(state, { payload }: PayloadAction<any>) {
      state.whatsappBotWelcomeText = payload;
    }
  },
});

export default BotConfigSlice.reducer;

export const { setBotConfig, removeBotConfig, setLocalBotConfig, setWhatsAppBotWelcomeText } =
  BotConfigSlice.actions;

export const useBotConfigStore = (state: RootState) =>
  state.currrentBotConfig;

export const getWhatsAppBotWelcomeText = (state: any) => {
  return state.currrentBotConfig.whatsappBotWelcomeText;
}
