import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class APIKeyNetworkService {
  // get API Key
  static getAPIKey() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_API_KEY}`);
  }
  // generate API Key
  static generatedAPIKey() {
    return http.get(`${consoleBaseUrl}${Endpoints.GENERATE_API_KEY}`);
  }
}
class Endpoints {
  static GET_API_KEY = "/workspace/get-api-key";
  static GENERATE_API_KEY = "/workspace/regenerate-api-key";
}
