import { InputBaseProps, Stack } from "@mui/material";
import { debounce } from "lodash";
import { useRef } from "react";
import { theme } from "../../Customization/Theme";
import AppInput from "./app-input";

interface Props extends InputBaseProps {
  handleOnSearch?: (e: any) => void;
  [otherProps: string]: any;
}

//DO NOT ADD FORM HERE
const AppInputSearch = ({ handleOnSearch, ...otherProps }: Props) => {
  const formRef = useRef<HTMLFormElement | any>();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    //using ref to get values not event e bcz we are submitting form on input change also
    // const formData = new FormData(e.currentTarget);
    const formData = new FormData(formRef?.current);
    // Using Object.fromEntries and formData.entries() to convert the FormData to an object
    const inputObject = Object.fromEntries(formData.entries());
    handleOnSearch && handleOnSearch(inputObject);
  };

  const debouncedHandleChange = debounce(handleSubmit, 1000);

  const handleChange = (e: any) => {
    debouncedHandleChange(e);
  };

  return (
    <>
      <Stack
        direction="row"
        component={"form"}
        justifyContent={"space-between"}
        alignItems={"center"}
        spacing={1}
        width={"100%"}
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <AppInput
          id={"search"}
          name="search"
          type="search"
          placeholder="Search"
          {...otherProps}
          onChange={handleChange}
          sx={{
            "& .MuiInputBase-input": {
              height: 28,
              border: `1px solid ${theme.palette.default.border}`,
            },
          }}
        />
      </Stack>
    </>
  );
};

export default AppInputSearch;
