import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { FastField, getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Caption } from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import AppErrorMessage from "../AppErrorMessage";
import { getNodeConfig } from "../../../Modules/FlowBuilder/Utils";

interface Props {
  label?: string;
  subTitle?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  charactersLimit?: number;
  onChangeCallbackFn?: (value: any) => void;
  children?: React.ReactNode;
  [otherProps: string]: any;
  disabled?: boolean;
  mediaType?: string;
  disableOnEnter?: boolean
}

const AppFormFastField: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  subTitle,
  children,
  onChangeCallbackFn,
  charactersLimit,
  disabled = false,
  mediaType,
  style,
  require,
  disableOnEnter = false,
  ...otherProps
}) => {
  const { errors, touched, values, handleChange, setFieldTouched } = useFormikContext<any>();
  const [isDisabled, setIsDisabled] = useState<any>(null);
  const [isMediaSupport, setIsMediaSupport] = useState<boolean>(true);
  const nodeConfig = getNodeConfig("img-file");
  useEffect(() => {
    if (isDisabled !== null && isDisabled !== disabled) {
      setTimeout(() => {
        setIsDisabled(disabled)
        setFieldTouched(name, !touched[name]);
      }, 200)
    }
  }, [isDisabled]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setIsDisabled(disabled)
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkMediaInput = (value: any) => {
    if (mediaType == "Image") {
      setIsMediaSupport(false)
      var getLastExtension = value.split('.').reverse()[0]
      nodeConfig.allowedFiles.map((file: any) => {
        if (file.name == "Images") {
          file.supportedExtensions.map((res: any) => {
            if (res.label.includes(getLastExtension)) {
              setIsMediaSupport(true)
            }
          })
        }
      })
    }
  }

  return (
    <Div style={divStyle}>
      <Label>
        {label}
        {require && (
          <p style={{color:"red"}}>*</p>
        )}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      {subTitle && <Caption>{subTitle}</Caption>}
      <FastField name={name} placeholder="Select a option">
        {(props: any) => {
          props.field.disabled = disabled;
          return (
            <Input
              {...otherProps}
              {...props.field}
              disabled={disabled}
              value={getIn(values, name) || ''}
              onChange={(e: any) => {
                handleChange(e);
                onChangeCallbackFn && onChangeCallbackFn(e.target.value);
                checkMediaInput(e.target.value)
              }}
              style={{
                border:
                  (getIn(errors, name) && getIn(touched, name)) || !isMediaSupport
                    ? `2px solid ${theme.palette.default.error}`
                    : `2px solid transparent`,
              }}
              maxLength={charactersLimit && charactersLimit}
              onKeyDown={(e: any) => {
                if (e.key === "Enter" && disableOnEnter) {
                  e.stopPropagation()
                  e.preventDefault();
                }
              }}
            />
          )
        }}
      </FastField>

      {charactersLimit && charactersLimit !== 0 && (
        <Caption>
          {" "}
          Characters: {getIn(values, name)?.length || 0}/{charactersLimit}
        </Caption>
      )}

      {!isMediaSupport ? (
        <AppErrorMessage
          error={"Please Enter valid url"}
          visible={getIn(touched, name)}
        />
      ) : <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />}

    </Div>
  );
};

export default AppFormFastField;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
const Input = styled.input`
  && {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 1.3rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    /* border: 2px solid ${theme.palette.default.border} !important; */
    color: ${theme.palette.default.darkGrey};
    opacity: 0.7;
  }
`;
