import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import FacebookPageTable from "./FacebookPageTable";
import FacebookIntegration from "./FacebookIntegration";
import { theme } from "../../../../Customization/Theme";

interface Props {
  handleDisconnectButton: any;
  handleSingleDisconnectButton: any;
  pageData: any;
  componentClicked: any;
}

const FacebookConfigure: React.FC<Props> = ({
  handleDisconnectButton,
  pageData,
  handleSingleDisconnectButton,
  componentClicked,
}) => {
  return (
    <FacebookPageWrap>
      <div className="pageTopbar">
        {pageData?.response?.length ? (
          <div className="channelHeader">
            <img
              className="companyLogo"
              src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
              alt=""
            />
            <p className="ctaTitle">Facebook Messenger</p>
          </div>
        ) : null}
        <div className="accountInfo">
          <AppButton variant="danger" onClick={() => handleDisconnectButton()}>
            Disconnect
          </AppButton>
        </div>
      </div>
      <div className="ctaBox">
        {pageData?.response?.length ? (
          <>
            <FacebookPageTable
              handleSingleDisconnectButton={handleSingleDisconnectButton}
              rows={pageData?.response}
            ></FacebookPageTable>
          </>
        ) : (
          <FacebookIntegration
            componentClicked={componentClicked}
            hideLogo={true}
          />
        )}
      </div>
    </FacebookPageWrap>
  );
};

export default FacebookConfigure;

const FacebookPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .pageTopbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0 0.4rem;
      width: 100%;
      background-color: ${theme.palette.default.white};
      .channelHeader {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .accountInfo {
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 2rem;
        font-size: 1rem;
        color: #7e8392;
      }
    }

    .ctaBox {
      display: flex;
      min-height: 20%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .companyLogo {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }

    .configureFormFieldsWrap {
      display: flex;
      padding: 1rem 2rem;
      flex-direction: column;
      gap: 1rem;
    }
  }
`;
