import {
  Box,
  Grow,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import uniqid from "uniqid";
import useOnClickOutside from "use-onclickoutside";
import { AppConfig } from "../../Config/app-config";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { getProjectConfigFn } from "../../Services/Apis/CoreApis";
import { CORE_ENDPOINTS } from "../../Services/Apis/Endpoints";
import { useAppSelector } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import {
  ChangeSelectedBot,
  useCurrentBot,
} from "../../Store/Slices/DashboardSlices";
import { formatString, isEmptyObject } from "../../Utils";
import { getSelectedMenuObject } from "../../Utils/menuUtils";
// import { getRestrictedMenuOptions } from "../../Utils/rolesUtils";
import logo from "../../assets/images/logo.svg";
import { LeftMenuWrap, MenuStyles } from "./MenuStyle";
interface MenuItem {
  children: any;
  id: string;
  label: string;
  isDisabled: boolean;
  url: string;
  Micon: string;
  newicon: any;
}


const LeftMenu = (props: any): JSX.Element => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentBot = useAppSelector(useCurrentBot);
  const { userConfig,loading }: any = useAppSelector(useAuthStore);

  var [selectedIndex, setSelectedIndex] = useState<any>("");
  var [renderSubMenu, setRenderSubMenu] = useState<any>("");
  var [parentId, setParentId] = useState<string>("");

  const getBotDataIfExitsInUrl = () => {
    const botId = AppConfig.getLoggedInData()?.data?.user.bots[0];
    botId && !currentBot?.bot_id && ChatbotConsoleService.getBotById({ bot_id: botId }).then((response) => {
      const bot = response.data;
      dispatch(ChangeSelectedBot(response.data));
      const proj_v_url = formatString(CORE_ENDPOINTS.PROJECT_VER_CONFIG, [
        bot.project_version_id,
      ]);
      getProjectConfigFn(dispatch)({ url: proj_v_url }).then((res: any) => {
      });
    });
  }

  const getCurrentPathAfterRefresh = () => {
    //* HARDCODING THIS VALUE BECAUSE CHANGING THIS REQUIRES A LOT OF CHANGES IN THE MULTIPLE FILES //
    if (location.pathname.indexOf("/setup") >= 0) {
      getBotDataIfExitsInUrl();
      return setSelectedIndex("setup");
    }
    if (location.pathname.indexOf("/settings") >= 0) {
      return setSelectedIndex("settings");
    }
    if (location.pathname.indexOf("/subscription") >= 0) {
      return setSelectedIndex("subscription");
    }

    let SelectedMenu = getSelectedMenuObject(
      userConfig?.payload?.menu_items?.left_top_menu_options,
      location.pathname
    );

    if (!SelectedMenu) {
      SelectedMenu = getSelectedMenuObject(
        userConfig?.payload?.menu_items?.left_bottom_menu_options,
        location.pathname
      );
    }

    if (SelectedMenu) {
      setSelectedIndex(SelectedMenu?.value);
    }
  };

  const memoizedTopMenuOptions = useMemo(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;
    if (isEmptyObject(permissionsData)) return;

    const result = userConfig?.payload?.menu_items?.left_top_menu_options ?? [];

    return result;
  }, [userConfig?.payload?.menu_items?.left_top_menu_options]); // eslint-disable-line react-hooks/exhaustive-deps

  const memoizedBottomMenuOptions = useMemo(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;

    const result = userConfig?.payload?.menu_items?.left_bottom_menu_options ?? [];

    return result;
  }, [userConfig?.payload?.menu_items?.left_bottom_menu_options]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    getCurrentPathAfterRefresh();
  }, [memoizedTopMenuOptions, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleListItemClick = (curElem: any) => {
    if (loading) return;
    setParentId(curElem.value);

    if (curElem?.children?.length > 0) {
      setRenderSubMenu(true);
      return;
    }

    if (curElem?.value === "setup") {
      const subUrl = `/workspace/{0}/setup/bots`;
      const workspace_id: string = window.localStorage.getItem("ws_id") || "";

      const url = formatString(subUrl, [workspace_id]);
      navigate(url);
      return;
    }


    renderSubMenu && setRenderSubMenu(false);
    const subUrl = curElem?.url;
    navigate(subUrl);

  };

  const handleChildrenItemClick = (curElem: any, parent: any) => {
    if (loading) return;
    setRenderSubMenu(curElem?.value);

    const subUrl = curElem.url;
    navigate(subUrl);
    closeMenu();
  };

  const redirectToHome = () => {
    // const subUrl = "/analytics/dashboard";
    const subUrl = "/";
    navigate(subUrl);
  };

  const closeMenu = () => {
    setRenderSubMenu(false);
  };

  const ref = useRef(null);
  useOnClickOutside(ref, closeMenu);
  return (
    <LeftMenuWrap>
      <Box className="leftTopMenu">
        <img src={logo} onClick={() => redirectToHome()} alt="logo" />
        <Box className="leftMenuWrapper">
          <List style={{ height: "100%" }}>
            {memoizedTopMenuOptions &&
              memoizedTopMenuOptions.length > 0 &&
              memoizedTopMenuOptions.map((curElem: any, index: number) => {
                if (!curElem?.isAvailable) return null;
                if (curElem?.label === "Broadcast" && !userConfig?.payload?.whatsapp_enabled && !userConfig?.payload?.email_enabled && !userConfig?.payload?.sms_enabled) return null;
                return (
                  <ListItem key={index} className="menuItem">
                    <Tooltip
                      arrow
                      enterDelay={1}
                      leaveDelay={0}
                      TransitionComponent={Grow}
                      placement="right"
                      title={curElem.label}
                    >
                      <ListItemButton
                        selected={(selectedIndex) === curElem.value}
                        onClick={() => handleListItemClick(curElem)}

                        // onClick={() => console.log("curElem", curElem)}
                        disabled={curElem?.isDisabled ?? false}
                        sx={{
                          textAlign: "center",
                          height: "48px",
                          // background: "#6150e1",
                          "&:hover": {
                            background: "#6150e1",
                          },
                          "&.Mui-selected": {
                            backgroundColor: "#6150e1", // Change the background color when selected
                            "&:hover": {
                              backgroundColor: "#6962f2", // Change the background color on hover for selected items
                            },
                          },
                        }}
                        className="menuButton"
                        disableRipple
                      >
                        <img
                          alt="img_menu"
                          style={{ width: "24px", height: "24px" }}
                          src={curElem?.icon ?? "null"}
                        />
                      </ListItemButton>
                    </Tooltip>
                    {renderSubMenu &&
                      parentId === curElem?.value &&
                      curElem?.children?.length ? (
                      <MenuStyles ref={ref}>
                        {curElem?.children?.map((child: any) => {
                          if (!child?.isAvailable) return null;
                          if (child?.label === "WhatsApp Broadcast" && !userConfig?.payload?.whatsapp_enabled) return null;
                          if (child?.label === "Email Broadcast" && !userConfig?.payload?.email_enabled) return null;
                          if (child?.label === "SMS Broadcast" && !userConfig?.payload?.sms_enabled) return null;
                          return (
                            <ListItemButton
                              key={uniqid()}
                              className="listItem"
                              selected={
                                selectedIndex === child.value ||
                                location?.pathname === child?.url
                              }
                              onClick={() => handleChildrenItemClick(child, curElem)}
                              sx={{ textAlign: "left" }}
                              disabled={child.isDisabled}
                            >
                              {child?.icon ? (
                                <img
                                  style={{
                                    height: "18px",
                                    width: "18px",
                                    margin: "0px 10px 0 4px",
                                  }}
                                  src={child?.icon}
                                  alt={child?.label}
                                />
                              ) : null}
                              {child?.label}
                            </ListItemButton>
                          );
                        })}
                      </MenuStyles>
                    ) : null}
                  </ListItem>
                );
              })}
          </List>
          <List>
            {memoizedBottomMenuOptions &&
              memoizedBottomMenuOptions?.length > 0 &&
              memoizedBottomMenuOptions.map(
                (curElem: any, index: number) => {
                  if (!curElem?.isAvailable) return null;
                  return (
                    <ListItem key={index} className="menuItem">
                      <Tooltip
                        arrow
                        enterDelay={1}
                        leaveDelay={0}
                        TransitionComponent={Grow}
                        placement="right"
                        title={curElem.label}
                      >
                        <ListItemButton
                          selected={(selectedIndex) === curElem.value}
                          onClick={() => handleListItemClick(curElem)}
                          disabled={curElem?.isDisabled ?? false}
                          sx={{
                            textAlign: "center",
                            height: "48px",
                            // background: "#6150e1",
                            "&:hover": {
                              background: "#6150e1",
                            },
                            "&.Mui-selected": {
                              backgroundColor: "#6150e1", // Change the background color when selected
                              "&:hover": {
                                backgroundColor: "#6962f2", // Change the background color on hover for selected items
                              },
                            },
                          }}
                          className="menuButton"
                          disableRipple
                        >
                          <img
                            alt="img_menu"
                            style={{ width: "24px", height: "24px" }}
                            src={curElem?.icon ?? "null"}
                          />

                        </ListItemButton>
                      </Tooltip>
                      {renderSubMenu &&
                        parentId === curElem?.value &&
                        curElem?.children?.length ? (
                        <MenuStyles ref={ref}>
                          {curElem?.children.map((child: any) => {
                            if (!child?.isAvailable) return null;

                            return (
                              <ListItemButton
                                key={uniqid()}
                                className="listItem"
                                selected={
                                  selectedIndex === child.value ||
                                  window.location?.pathname === child?.url
                                }
                                onClick={() => handleChildrenItemClick(child, curElem)}
                                sx={{ textAlign: "left" }}
                                disabled={child.isDisabled}
                              >
                                {child?.icon ? (
                                  <img
                                    style={{
                                      height: "18px",
                                      width: "18px",
                                      margin: "0px 10px 0 4px",
                                    }}
                                    src={child?.icon}
                                    alt={child?.label}
                                  />
                                ) : null}
                                {child?.label}
                              </ListItemButton>
                            );
                          })}
                        </MenuStyles>
                      ) : null}
                    </ListItem>
                  );
                }
              )}
          </List>
        </Box>
      </Box>
    </LeftMenuWrap>
  );
};

export default LeftMenu;
