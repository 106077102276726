import styled from "styled-components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppConfig } from "../../../../Config/app-config";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const BOT_ID_KEY = 'currentBotId'
interface Props{
  handleConnectButton: any;
  authorizationCode: any;
}

const ZohoIntegrations:React.FC<Props> = ({
  handleConnectButton,
  authorizationCode
}) => {
  const currentBot = useSelector(useCurrentBot);
  
  return (
    <ZohoPageWrap>
      <div className="pageTopbar">
      {/* <AppIcon title={'ri-arrow-left-line'} size="26px" color="#282828" /> */}
      </div>
      <div className="ctaBox">
        <img
          className="companyLogo"
          src="https://www.zoho.com/branding/images/zoho-logo.png"
          alt=""
        />
        <p className="ctaTitle">Connect with Zoho Account</p>
        {/* <div className="buttonBox"> */}
        {
          currentBot && <AppButton startIcon="ri-links-line" onClick={()=>handleConnectButton(currentBot.bot_id)}>Connect with Click</AppButton>
        }
        {
          authorizationCode && <span>Please wait...</span> 
        }
        {/* </div> */}
      </div>

    </ZohoPageWrap>
  );
};

export default ZohoIntegrations;

const ZohoPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
   
    .pageTopbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      width: 100%;
    }

    .ctaBox {
      display: flex;
      height: 40%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
      /* background-color: red; */
      display: flex;
      justify-content: center;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }
  }
`;
