import { Box, LinearProgress, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { getRoundOffNumbers } from "../../../../../Utils";
import { theme } from "../../../../../Customization/Theme";


interface Props {
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
}

const AppProgressBar: React.FC<Props> = ({ progress, setProgress }) => {
  const [buffer, setBuffer] = React.useState(10);

  const progressRef = React.useRef(() => {});

  React.useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });
  return (
    <StyledLoaderWrap>
      <Box sx={{ width: "20%" }}>
        <LinearProgress
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
        />
      </Box>
      <Typography variant="h6">
        {progress < 100 ? getRoundOffNumbers(progress) : "100"} %
      </Typography>
      <Typography variant="body1">
        {progress >= 100
          ? "We're still processing your file. Please wait for few mins."
          : "Please wait while we are processing your request."}
      </Typography>
    </StyledLoaderWrap>
  );
};

export default AppProgressBar;

const StyledLoaderWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.3rem;
    width: 100%;
    height: 100%;
    max-width: 300px;
    z-index: 99012 !important;
    p,
    h6 {
      color: ${theme.palette.default
        .darkGrey} !important; //* have to overwrite this *//
    }
  }
`;
