import { FunctionComponent, useEffect, useState } from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../../../Store";
import { SummaryWrapper, Wrapper } from "./SmsBroadcastDashboardStyles";
import { broadcastActions } from "../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { Box } from "@mui/material";
import { removebroadcastDashboard } from "../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.slice";
import NoRecordImage from "../../../../../../assets/images/norecordsanalytics.svg";
import Doughnout from "../../../../../Broadcast_Dashboard/Email_Broadcast_Overview_Summary/Doughnout";
import NoRecordsFound from "../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { smsBroadcastLayout } from "../../../../Utils/utils";
import TopLinksStats from "../../SMS_Dashboard_Insights/Logs/Overview/OverviewComponents/Errorreasons";
import Recipients from "../../SMS_Dashboard_Insights/Logs/Overview/OverviewComponents/Recipients";
import { Statistics } from "../../SMS_Dashboard_Insights/Logs/Overview/OverviewComponents/Statistics";

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
}

const ResponsiveGridLayout: React.ComponentClass<
  RGL.ResponsiveProps & RGL.WidthProviderProps,
  any
> = WidthProvider(Responsive);

const SmsBroadcastDashboard: FunctionComponent<Props> = (props) => {
  let { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const [statsData, setStatsData] = useState<any>([]);
  const [broadcastDetails, setBroadcastDetails] = useState<any>([]);
  const [topLinks, setTopLinks] = useState([]);
  const [cost, setCost] = useState(0);
  const broadcast_id = useParams();
  const [loading, setLoading] = useState<any>(false);
  const [deliverabilityRate, setDeliverabilityRate] = useState<any>(0);
  const [failureRate, setFailureRate] = useState<any>(0);

  const getStatsData = () => {
    setLoading(true);
    dispatch(
      broadcastActions.getBroadcastStatsData(broadcast_id?.broadcast_id)
    ).then((res: any) => {
      setStatsData(res?.payload?.data?.broadcast_stats);
      setBroadcastDetails(res?.payload?.data?.broadcast_details);
      setCost(res?.payload?.data?.broadcast_stats?.total_cost);
      setDeliverabilityRate(res?.payload?.data?.broadcast_stats?.deliverability_rate);
      setFailureRate(res?.payload?.data?.broadcast_stats?.failed_rate);
      setTopLinks(res?.payload?.data?.reason_stats);
      setLoading(false);
    }).catch((error: any) => {
      showAlert(
        error?.response?.statusText || "Unable to fetch data",
        "error"
      );
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  };


  useEffect(() => {
    getStatsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      dispatch(removebroadcastDashboard());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SummaryWrapper>
      {/* <p>SMS Broadcast Deatils</p> */}
      {
        !loading &&
          (statsData?.recipients ?? 0) === 0 &&
          (statsData?.total_cost ?? 0) === 0 &&
          (deliverabilityRate ?? 0) === 0 &&
          (failureRate ?? 0) === 0 &&
          (statsData?.sent ?? 0) === 0 &&
          (statsData?.delivered ?? 0) === 0 &&
          (statsData?.failed ?? 0) === 0 &&
          topLinks?.length === 0 ?
          (
            <Box sx={{ padding: "10px 10px 0px 10px" }}>
              <NoRecordsFound
                imageSrc={NoRecordImage}
                headerTextSize={"28px"}
                subTextSize="22px"
                imageWidth="240px"
                height={"calc(100vh - 199px)"}
              />
            </Box>
          ) : (
            <div className="parent_div2">

              <ResponsiveGridLayout
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
                layouts={smsBroadcastLayout}
                isDraggable={false}
                isResizable={false}
              >
                <div key={"1"}>
                  <Recipients
                    totalCost={cost}
                    broadcastStats={statsData}
                    loading={loading}
                  />
                </div>

                <div key={"2"}>
                  <Box className="box-wrapper">
                    <Wrapper>
                      <Doughnout
                        broadcastStats={deliverabilityRate}
                        loading={loading}
                        heading={"Deliverability Rate"}
                        color={"#FFAA6C"}
                        tooltiptext={
                          "Percentage of successfully sent SMS messages from total attempts."
                        }
                        chartText={`Your SMS to ${broadcastDetails?.recipients || 0} people was delivered to a total of ${broadcastDetails?.delivered || 0} recipients.`}
                        downloadBtnPosition={-44}
                        totalText={`Total: ${broadcastDetails?.delivered || 0}`}
                      />
                    </Wrapper>
                  </Box>
                </div>

                <div key={"3"}>
                  <Box className="box-wrapper">
                    <Wrapper>
                      <Doughnout
                        broadcastStats={failureRate}
                        loading={loading}
                        heading={"Failure rate"}
                        color={"#51CBFF"}
                        tooltiptext={
                          "Percentage of unsuccessful SMS messages delivery from total attempts."
                        }
                        chartText={`Your SMS to ${broadcastDetails?.recipients || 0} people was not delivered to a total of ${broadcastDetails?.failed || 0} recipients.`}
                        downloadBtnPosition={-44}
                        totalText={`Total: ${broadcastDetails?.failed || 0}`}
                      />
                    </Wrapper>
                  </Box>
                </div>

                <div key={"4"}>
                  <Statistics broadcastStats={statsData} loading={loading} />
                </div>

                <div key={"5"}>
                  <TopLinksStats linkStats={topLinks} loading={loading} />
                </div>

              </ResponsiveGridLayout>
            </div>
          )}
    </SummaryWrapper>
  );
};

SmsBroadcastDashboard.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => { },
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  preventCollision: true,
};

export default SmsBroadcastDashboard;

export const DateBox = styled.div`
  color: blue;
`;




