// vendors
import { useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import Typography from "@mui/material/Typography";

// components
import Icon from "../../../Components/Icon";
import Input from "../../../Components/Input";
import UIButton from "../../../Components/Button";
import UIButtonList from "../../../Nodes/Buttons/ButtonsList";

type Item = {
  id: string;
  title: string;
  description: string;
};

type Section = {
  title: string;
  rows: Item[];
};

const ButtonList = ({
  list,
  activeNode,
  charLimit,
  type,
  onChange,
  addDescription,
  descriptionLimit,
}: {
  list: Section[];
  activeNode: boolean;
  charLimit: number;
  type: string;
  onChange: (list: Section[]) => void;
  addDescription: boolean;
  descriptionLimit: number;
}) => {
  const [sectionsList, updateSectionsList] = useState<Section[]>(list || []);

  const getTotalItem = () => {
    let totalItems = 0;
    sectionsList.map((section) => {
      totalItems += section.rows.length;
    });
    return totalItems;
  };

  const handleSectionInputChange = (event: any, index: number) => {
    const value = event.target.value;
    const tempList = cloneDeep(sectionsList);
    tempList[index].title = value;
    updateSectionsList(tempList);
    onChange(tempList);
  };

  const handleDeleteSection = (event: any, index: number) => {
    const tempList = cloneDeep(sectionsList);
    tempList.splice(index, 1);
    updateSectionsList(tempList);
    onChange(tempList);
  };

  const isValidSection = () => {
    const data = sectionsList.filter((section) => section.title !== "");
    return data.length > 0;
  };

  const handleAddSectionClick = () => {
    if (sectionsList.length > 0 && !isValidSection()) return;

    const tempList = [...sectionsList, ...[{ title: "", rows: [] }]];
    updateSectionsList(tempList);
    onChange(tempList);
  };

  const handleButtonLabelChange = (
    label: string,
    activeButtonIdx: number | null,
    sectionIndex: number,
    description?: boolean,
  ) => {
    if (activeButtonIdx !== null) {
      const tempSectionList = cloneDeep(sectionsList);
      const newState = tempSectionList[sectionIndex].rows.map(
        (button: any, index: number) => {
          if (index === activeButtonIdx && !description) {
            return {
              ...button,
              value: {
                ...button.value,
                ...{ text: label.substring(0, charLimit) },
              },
            };
          } else if (index === activeButtonIdx && description) {
            return {
              ...button,
              value: {
                ...button.value,
                ...{ description: label.substring(0, descriptionLimit) },
              },
            };
          }
          return button;
        },
      );

      tempSectionList[sectionIndex].rows = newState;
      updateSectionsList(tempSectionList);
      onChange(tempSectionList);
    }
  };

  const getById = (sectionIndex: number, id: string) =>
    sectionsList[sectionIndex].rows.find((button: any) => button.id === id);

  const handleButtonSave = (updatedAction: any, sectionIndex: number) => {
    if (updatedAction.id) {
      const previousValue = getById(sectionIndex, updatedAction.id);
      const isDifferent = isEqual(previousValue, updatedAction);
      const tempSectionList = cloneDeep(sectionsList);
      if (previousValue) {
        if (!isDifferent) {
          const newState = tempSectionList[sectionIndex].rows.map(
            (button: any) => {
              if (button.id === updatedAction.id) {
                return {
                  ...button,
                  type: updatedAction.type,
                  value: {
                    ...button.value,
                    ...{
                      text: updatedAction.value.text.substring(0, charLimit),
                      url: updatedAction.value.url,
                      ...updatedAction.value,
                    },
                  },
                  ...(updatedAction.type === "transfer_to_faqs" && {
                    text: updatedAction.text,
                  }),
                };
              }
              return button;
            },
          );
          tempSectionList[sectionIndex].rows = newState;
          updateSectionsList(tempSectionList);
          onChange(tempSectionList);
        }
        return;
      } else {
        tempSectionList[sectionIndex].rows = [
          ...tempSectionList[sectionIndex].rows,
          ...[updatedAction],
        ];
        updateSectionsList(tempSectionList);
        onChange(tempSectionList);
      }
    }
  };

  const handlePreviewButtonClick = () => {};

  const handleButtonDelete = (
    activeButton: any,
    index: number,
    sectionIndex: number,
  ) => {
    const tempSectionList = cloneDeep(sectionsList);
    const buttonsList = tempSectionList[sectionIndex].rows;

    if (activeButton.id) {
      const newState = buttonsList.filter(
        (button: any) => button.id !== activeButton.id,
      );
      tempSectionList[sectionIndex].rows = newState;
      updateSectionsList(tempSectionList);
      onChange(tempSectionList);
      return;
    }
    /* NOTE: in case button doesn't have ids. delete operation will perform on the basis of index */
    if (index !== -1) {
      const newState = cloneDeep(buttonsList);
      newState.splice(index, 1);
      tempSectionList[sectionIndex].rows = newState;
      updateSectionsList(tempSectionList);
      onChange(tempSectionList);
      return;
    }
  };
  const handleOrderChange = (buttonsList: any, sectionIndex: number) => {
    let tempSectionList: any = cloneDeep(sectionsList);
    const newState = cloneDeep(buttonsList);
    tempSectionList[sectionIndex].rows = newState;
    updateSectionsList(tempSectionList);
    onChange(tempSectionList);
    // dispatch(setButtonsList(buttonsList));
  };
  return activeNode ? (
    <>
      {sectionsList.map((section, index) => (
        <Box sx={{ pb: "10px" }}>
          <Box sx={{ pb: "10px" }}>
            <Input
              onChange={(event) => handleSectionInputChange(event, index)}
              value={section.title}
              placeholder="Add title"
              error={!section.title.trim()}
              endAdornment={
                <InputAdornment position="end" sx={{ width: "40px" }}>
                  <IconButton
                    disableRipple
                    onClick={(event) => handleDeleteSection(event, index)}
                    onMouseDown={(event) => handleDeleteSection(event, index)}
                    edge="end"
                    sx={{
                      "&.Mui-disabled": {
                        color: "#c7c7c9",
                      },
                    }}
                  >
                    <Icon icon="delete" size={16} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
          <Box sx={{ ml: "30px" }}>
            <UIButtonList
              isActiveNode={activeNode}
              buttonsList={section.rows}
              onLabelChange={(label, activeButtonIdx, isDescription) =>
                handleButtonLabelChange(
                  label,
                  activeButtonIdx,
                  index,
                  isDescription,
                )
              }
              onButtonSave={(updatedAction) =>
                handleButtonSave(updatedAction, index)
              }
              onPreviewButtonClick={handlePreviewButtonClick}
              onButtonDelete={(activeButton, currentIdx) =>
                handleButtonDelete(activeButton, currentIdx, index)
              }
              nodeType={type}
              disableAddButton={getTotalItem() >= 10}
              onOrderChange={(buttonsList: any) =>
                handleOrderChange(buttonsList, index)
              }
              addDescription={addDescription}
            />
          </Box>
        </Box>
      ))}
      {getTotalItem() < 10 && (
        <UIButton variant="outlined" fullWidth onClick={handleAddSectionClick}>
          Add Section
        </UIButton>
      )}
      {getTotalItem() < 1 && (
        <Typography style={{ color: "red", fontSize: "10px" }} align="right">
          Please add at least one section.
        </Typography>
      )}
    </>
  ) : (
    <>
      {sectionsList.map((section, index) => (
        <>
          <Typography>{section.title}</Typography>
          <Box sx={{ mx: "-16px" }}>
            <UIButtonList
              isActiveNode={activeNode}
              buttonsList={section.rows}
              onLabelChange={(label, activeButtonIdx) =>
                handleButtonLabelChange(label, activeButtonIdx, index)
              }
              onButtonSave={(updatedAction) =>
                handleButtonSave(updatedAction, index)
              }
              onPreviewButtonClick={handlePreviewButtonClick}
              onButtonDelete={(activeButton, currentIdx) =>
                handleButtonDelete(activeButton, currentIdx, index)
              }
              nodeType={type}
              disableAddButton={getTotalItem() >= 10}
              onOrderChange={() => {}}
            />
          </Box>
        </>
      ))}
    </>
  );
};
export default ButtonList;
