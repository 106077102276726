import { Box } from "@mui/material";
import { RecipientsWrapper } from "./AllStyles";
import { AppToolTip } from "../../../../../../../UiComponents/AppToolTip/AppToolTip";
import { AppMaterialIcons } from "../../../../../../../../AppComponents/AppMaterialIcons";
import { SkeletonSmsRecipients } from "../../../../../../../Dashboard/SkeletonLoader.tsx/SkeletonCard";
import { WrapRecipients } from "../DashboardStyles";
import NoRecordsFound from "../../../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import NoRecordImage from "../../../../../../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../../../../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
interface Props {
  totalCost?: any;
  broadcastStats?: any;
  loading?: any;
}

const Recipients: React.FC<Props> = ({
  totalCost,
  broadcastStats,
  loading,
}) => {
  return loading ? (
    <AppSkeletonLoader />
  ) : (
      
      
        <WrapRecipients>

          
                      <RecipientsWrapper>
        <Box height="100%">
          <Box display = { "flex" } alignItems = { "center" }>
            <p className = "main_header">Recipients</p >
    <AppToolTip
      tooltipProps={{
        title: "Total individuals for SMS message delivery.",
        placement: "top",
      }}
      iconButtonProps={{ disableRipple: true }}
      IconComponent={
        <AppMaterialIcons
          iconName="HelpOutline"
          style={{ color: "#fff", fontSize: "16px" }}
        />
      }
    />
          </Box >


      {
                    (broadcastStats?.recipients ?? 0) === 0 &&
                      (broadcastStats?.total_cost ?? 0) === 0 ? (
              <NoRecordsFound
                imageSrc={NoRecordImage}
                imageWidth={"200px"}
                headerTextSize={"19px"}
                bgColor="transparent"
                headerTextColor="#fff"
                subTextColor="#fff"
                height="calc(100% - 32px)"
              />
                      ) : (
                        <div className = "info_values">
                  <p className="header">Total Recipients</p>
                  <p className="value">{broadcastStats?.recipients || 0}</p>
                  <Box className="credits_btn">
                    <p>Cost: Rs {broadcastStats?.total_cost || 0} </p>
                  </Box>
                </div>
                      )
      }

  
        </Box >
      </RecipientsWrapper > 
           
      
        </WrapRecipients >
        
      
  );
};

export default Recipients;
