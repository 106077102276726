import { Box, MenuItem, Select, Typography, debounce } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { format } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppRangeDatePicker from "../../../../AppComponents/AppRangeDatePicker";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import { NewNativeSelect } from "../../../../AppComponents/Forms/Styles";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../Store";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import TemplateAPIERROR from "../../../../assets/images/No_records_found.svg";
import {
  ErrorComponent,
  SmsMenuFilter,
  BroadcastBodyWrapper,
  BroadcastMainWrapper,
  BroadcastMainWrapperTitle,
} from "./LogsStyles";

import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../HOC/HocBackdropLoader";
import { AppPagination } from "../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { TableFilter, TableWrapper } from "../../../../TableStyle";
import {
  filtersValuesData,
  logListDataObj,
} from "../../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import { broadcastActions } from "../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";

interface Props extends HocBackdropLoaderProps {}
const SmsLogs: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const filtersValues = useSelector(filtersValuesData);
  const logListData = useSelector(logListDataObj);
  const totalcount = logListData?.count;
  const list = logListData?.results;
  const [logsData, setLogsData] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<any>("");
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: null,
    endDate: null,
    search: "",
    itemsPerPage: 10,
    currentPage: 0,
    broadcast_type: "sms",
    destination: "",
    sender_id: "",
    unit: "",
    direction: "",
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorDownloadEl, setAnchorDownloadEl] = useState<null | HTMLElement>(
    null,
  );
  const open = Boolean(anchorEl);
  const downloadOpen = Boolean(anchorDownloadEl);

  const handleDownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorDownloadEl(event.currentTarget);
  };
  const handleDownClose = () => {
    setAnchorDownloadEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setLogsData(true);
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    setLogsData(true);
  };

  const getLogsData = () => {
    let start = null;
    let end = null;
    if (localValues.startDate && localValues.endDate) {
      start = format(localValues.startDate, "yyyy-MM-dd");
      end = format(localValues.endDate, "yyyy-MM-dd");
    }
    setLoading(true);
    dispatch(
      broadcastActions.getAllCommunicationLogs({
        start: start,
        limit: localValues.itemsPerPage,
        offset: localValues.currentPage,
        end: end,
        status: localValues?.status,
        search: localValues?.search,
        type: localValues?.broadcast_type,
        destination: localValues?.destination,
        sender_id: localValues?.sender_id,
        unit: localValues?.unit,
        direction: localValues?.direction,
      }),
    )
      .then((res: any) => {
        setErrorApi(false);
        setLoading(false);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error",
        );
        setLoading(false);
        // setOnSearch(false);
      });
  };

  const getValueForFilterLogs = () => {
    dispatch(
      broadcastActions.getAllCommunicationFilterSettings({ type: "sms" }),
    )
      .then((res: any) => {})
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error",
        );
      });
  };

  const restFilter = () => {
    setLocalValues({
      status: "",
      startDate: null,
      endDate: null,
      search: "",
      itemsPerPage: 10,
      currentPage: 0,
      broadcast_type: "sms",
      destination: "",
      sender_id: "",
      unit: "",
      direction: "",
    });
    setLogsData(true);
    handleClose();
  };

  const applyFilter = () => {
    setLogsData(true);
    handleClose();
  };

  useEffect(() => {
    if (logsData) {
      getLogsData();
      setLogsData(false);
    }
  }, [logsData]);

  useEffect(() => {
    getValueForFilterLogs();
    getLogsData();
  }, []);

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
    setLogsData(true);
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
    setLogsData(true);
  };
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
      currentPage: 0,
    });
    setLogsData(true);
  };
  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
      currentPage: 0,
    });
    setLogsData(false);
  };

  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format("yyyy-MM-dd");
    return date;
  };

  useEffect(() => {
    handleExportClick();
  }, [filterType && isDownloading]);

  const handleExportClick = () => {
    if (filterType) {
      setIsDownloading(true);
      let payload: any;
      if (filterType === "filter") {
        payload = {
          start: format(localValues.startDate, "yyyy-MM-dd"),
          end: format(localValues.endDate, "yyyy-MM-dd"),
          status: localValues?.status,
          search: localValues?.search,
          type: "sms",
          destination: localValues?.destination,
          sender_id: localValues?.sender_id,
          unit: localValues?.unit,
          direction: localValues?.direction,
          filter_type: filterType,
        };
      }
      if (filterType === "all") {
        payload = {
          type: "sms",
          filter_type: filterType,
        };
      }
      dispatch(marketingListActions.getCommunicationLogsDownload(payload))
        .then((res) => {
          window.open(res?.payload?.data?.data.source, "_blank");
        })
        .catch((error) => {
          console.error("Error occurred while fetching the file URL.", error);
        });
      setIsDownloading(false);
      setFilterType("");
    }
  };

  const setDownloadFiled = (filterType: any) => {
    setFilterType(filterType);
    setIsDownloading(true);
    handleDownClose();
  };

  const renderSelectIconStatus = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconSenderId = localValues.sender_id ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconUnit = localValues.unit ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconDestination = localValues.destination ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconDirection = localValues.direction ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  //   Table settings
  const header = [
    "Date",
    "From",
    "To",
    "Body",
    "Direction",
    "Status",
    "Error Reason",
    "Price",
    "Unit",
    "Total Price",
  ];

  const keyToHeaderMap: any = {
    Date: "sent_on",
    From: "sender_id",
    To: "mobile",
    Body: "template_body",
    Direction: "direction",
    Status: "status",
    "Error Reason": "error_reason",
    Price: "amount",
    Unit: "unit",
    "Total Price": "total_price",
  };

  const colWidth: any = {
    Date: "220",
    From: "150",
    To: "150",
    Body: "420",
    Status: "120",
    Direction: "150",
    "Error Reason": "200",
    Price: "150",
    Unit: "150",
    "Total Price": "150",
  };

  return (
    <>
      <BroadcastMainWrapper>
        <BroadcastMainWrapperTitle>
          <Box>
            <Typography fontSize="16px" fontWeight="500" component="h6">
              SMS Logs 456
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}></Box>
        </BroadcastMainWrapperTitle>
        <BroadcastBodyWrapper>
          <AppForm // requestSearch(values, submitProps);
            initialValues={{ search: "" }}
            onSubmit={(values: any, submitProps: any) => {}}
            // validationSchema={BotValidationSchema}
            divStyle={{ width: "100%" }}
          >
            <TableFilter style={{ padding: "0px 16px" }}>
              <div className="filter-left">
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  defaultBorder={true}
                  // sendValuesToParent={(values: any) => sendValuesToParent(values)}
                  onChange={(e: any) => handleSelectSearch(e, "search")}
                  clearCallback={(e: any) => clearCallback(e, "search")}
                  divStyle={{
                    marginBottom: "0rem",
                    width: "100%",
                    minWidth: "100%",
                  }}
                  disabled={logsData}
                />
              </div>
              <div className="filter-right">
                <AppButton
                  variant="grey"
                  style={{ width: "100%" }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <AppMaterialIcons iconName={"Sort"} />
                  <span style={{ marginLeft: "0.7rem" }}>Filter</span>
                </AppButton>
                <SmsMenuFilter
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <div className="setting-page-mainboxtopbar__right">
                    <MenuItem>
                      <p>Status</p>
                      {/* Select 1 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="status"
                          value={localValues?.status}
                          onChange={(e: any) => handleSelectChange(e, "status")}
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.status?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "status")}
                        >
                          {renderSelectIconStatus}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Sender Ids</p>
                      {/* Select 2 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="sender_id"
                          value={localValues?.sender_id}
                          onChange={(e: any) =>
                            handleSelectChange(e, "sender_id")
                          }
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.sender_ids?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "sender_id")}
                        >
                          {renderSelectIconSenderId}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Unit</p>
                      {/* Select 3 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="unit"
                          value={localValues?.unit}
                          onChange={(e: any) => handleSelectChange(e, "unit")}
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.units?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "unit")}
                        >
                          {renderSelectIconUnit}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Destinations</p>
                      {/* Select 4 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="destination"
                          value={localValues?.destination}
                          onChange={(e: any) =>
                            handleSelectChange(e, "destination")
                          }
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.destinations?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "destination")}
                        >
                          {renderSelectIconDestination}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                    <MenuItem>
                      <p>Directions</p>
                      {/* Select 5 */}
                      <NewNativeSelect>
                        <Select
                          defaultValue={undefined}
                          name="direction"
                          value={localValues?.direction}
                          onChange={(e: any) =>
                            handleSelectChange(e, "direction")
                          }
                          displayEmpty
                          className="new-select-main"
                        >
                          <MenuItem value="" disabled>
                            {" "}
                            Select an option
                          </MenuItem>
                          {filtersValues?.directions?.map(
                            (item: any, index: any) => (
                              <MenuItem value={item.value} key={index}>
                                {item.label}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        <div
                          className="clear-icon"
                          onClick={(e: any) => clearCallback(e, "direction")}
                        >
                          {renderSelectIconDirection}
                        </div>
                      </NewNativeSelect>
                    </MenuItem>
                  </div>
                  <hr />
                  <div className="setting-page-mainboxtopbar__right">
                    <Box display="flex" sx={{ gap: "1rem" }}>
                      <AppButton
                        style={{ minWidth: "95px" }}
                        onClick={() => {
                          restFilter();
                        }}
                      >
                        Reset
                      </AppButton>
                      <AppButton
                        variant="outline"
                        style={{ minWidth: "95px" }}
                        onClick={() => {
                          applyFilter();
                        }}
                      >
                        Apply
                      </AppButton>
                    </Box>
                  </div>
                </SmsMenuFilter>
                <Box>
                  <AppRangeDatePicker
                    divStyle={{
                      width: "225px",
                      padding: "0px",
                    }}
                    reset={true}
                    dateRange={localValues}
                    setDateRange={handleChangeDate}
                  />
                </Box>
              </div>
            </TableFilter>
          </AppForm>

          {/* SMS Log Table  */}
          <TableWrapper height={list?.length ? "259px" : "200px"}>
            <div>
              <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* Table Body */}
              </Box>

              <Box className="table-body">
                {errorApi || list?.length === 0 ? (
                  <ErrorComponent>
                    <Box>
                      <img src={TemplateAPIERROR} width="300" alt="" />
                    </Box>
                    <Box className="err-content">
                      <Typography variant="h6" fontSize="20px">
                        No Records Found.
                      </Typography>
                    </Box>
                  </ErrorComponent>
                ) : (
                  <>
                    {list?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                  header === "Date"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]],
                                      )
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"
                                }
                                style={{
                                  maxWidth: isActionColumn
                                    ? "400px"
                                    : colWidth[header] + "px",
                                  minWidth: isActionColumn
                                    ? "400px"
                                    : colWidth[header] + "px",
                                }}
                              >
                                <Typography
                                  className="table-text"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {header === "Date"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]],
                                      )
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
            </div>
          </TableWrapper>
        </BroadcastBodyWrapper>
        {list?.length ? (
          <AppPagination
            component="div"
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={localValues.currentPage}
            count={totalcount}
            rowsPerPage={localValues.itemsPerPage}
          />
        ) : null}
      </BroadcastMainWrapper>

      {/* Old */}
    </>
  );
};

// Default Export of component
export default HocBackdropLoader(SmsLogs);
