import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class InstagramSettingsService {
    // post whatsapp config 
    static instagramConfigData(data: any) {
        return http.post(
            `${consoleBaseUrl}${Endpoints.ADD_INSTAGRAM_CONFIG_URL}`, data
        )
    }

    // get whatsapp config data list
    static getInstagramConfigData() {
        return http.get(`${consoleBaseUrl}${Endpoints.INSTAGRAM_CONFIG_DATA_URL}`);
    }

    // get whatsapp config data list
    static logoutInstagramConfiguration(data:any) {
        return http.post(`${consoleBaseUrl}${Endpoints.INSTAGRAM_LOGOUT_URL}`,data);
    }
    
    static disconnectSingleInstaPage(data:any) {
        return http.post(`${consoleBaseUrl}${Endpoints.INSTAGRAM_DISCONNECT_PAGE_URL}`,data);
    }

}

// Label EndpointValidate whatsapp Settings 
class Endpoints {
    static ADD_INSTAGRAM_CONFIG_URL = "/workspace/instagram-integrations"
    static INSTAGRAM_CONFIG_DATA_URL = "/workspace/get-insta-configuration"
    static INSTAGRAM_LOGOUT_URL = "/workspace/instagram-logout"
    static INSTAGRAM_DISCONNECT_PAGE_URL = "/workspace/delete-insta-subscribe-page"
}