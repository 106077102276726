import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const WrapperTable = styled.div`
 & {
    height:90%;
    border:none;
    margin:auto;   

    .div_tooltip{
      font-size: 10px;
    }
    
   
 }
`;



export const AcquitionTableWrapper = styled.div`
  & {
    width: 100%;
    
    background-color: ${theme.palette.default.white};
    > div {
      overflow: hidden;
      height: calc(100vh - ${(props: any) => props.height});
    }
    > div:hover {
      overflow: overlay;
    }
    > div::-webkit-scrollbar {
      width: 6px;
    }
    > div::-webkit-scrollbar-track {
  
    }
    > div::-webkit-scrollbar-thumb {
      background-color: rgba(126, 131, 146, 0.5);
      border-radius: 4px;
    }
    .table-header {
      position: sticky;
      top: 0;
      
      .table-row {
        display: flex;
        align-items: center;
      }
      .col-head {
        max-height: 47px;
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
       
        padding: 13px 20px;
        background-color: ${theme.palette.default.white};
        p {
          font-size: 13px;
          font-weight: 500;
        }
        &:first-child {
          p {
          }
        }
      }
    }
    .table-body {
      margin-top:4px;
      max-height: 240px;
    overflow: auto;


      ::-webkit-scrollbar {
        width: 4px;
        border-radius:50px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        background: ${theme.palette.default.lightGrey};
        border-radius:50px;
      }
      
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${theme.palette.default.darkGrey};
        border-radius:50px;
      }
      
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
     
      .actionColumn {
        border-bottom: 1px solid rgba(126, 131, 146, 0.2);
      }
      
      .table-row {
        display: flex;
        jsutify-content: center;
        margin-right:12px;
        color: rgb(126, 131, 146, 1.5);
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        max-height: 53px;
        padding: 13px 20px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.2);
        &:first-child {
          p {
            color: #101010;
          }
        }
        P {
          font-size: 13px;
        }
      }
    }
  }
`;