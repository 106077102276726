import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import ShopifyConfigure from "./ShopifyConfigure";
import ShopifyInteration from "./ShopifyInteration";
// import ZohoConfigure from "./ZohoConfigure";
// import ZohoIntegrations from "./ZohoIntegrations";

const ShopifyIndex = () => {
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  let { bot_id } = useParams();

  const [isConnected, setIsConnected] = useState<boolean>(false);
  const handleConnectButton = () => {
    setIsConnected(true);
    // window.open('https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.users.ALL,ZohoCRM.settings.ALL,ZohoCRM.modules.ALL,aaaserver.profile.ALL&client_id=1000.WH0V4KK6ZJ43GGXYB5R6CTBY8JPI5G&response_type=code&access_type=offline&redirect_uri=https://keep.google.com/')
      
  };
  const handleDisconnectButton = () => {
      setIsConnected(false);
  };

  useEffect(() => {}, []);

  return (
    <ZohoWrap>
      {isConnected ? (
        <ShopifyConfigure handleDisconnectButton={handleDisconnectButton} />
      ) : (
        <ShopifyInteration handleConnectButton={handleConnectButton} />
      )}
    </ZohoWrap>
  );
};

export default ShopifyIndex;

const ZohoWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
  }
`;
