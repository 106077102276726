
export const QueryBuilderTranslations = {
  fields: {
    title: "Fields",
    placeholderName: "~",
    placeholderLabel: "------",
    placeholderGroupLabel: "------",
  },
  operators: {
    title: "Operators",
    placeholderName: "~",
    placeholderLabel: "------",
    placeholderGroupLabel: "------",
  },
  value: {
    title: "Value",
  },
  removeRule: {
    label: "X",
    title: "Remove rule",
  },
  removeGroup: {
    label: "X",
    title: "Remove group",
  },
  addRule: {
    label: "+ Add condition",
    title: "Add rule",
  },
  addGroup: {
    label: "+Group",
    title: "Add group",
  },
  combinators: {
    title: "Combinators",
  },
  notToggle: {
    label: "Not",
    title: "Invert this group",
  },
  cloneRule: {
    label: "⧉",
    title: "Clone rule",
  },
  cloneRuleGroup: {
    label: "⧉",
    title: "Clone group",
  },
  dragHandle: {
    label: "⁞⁞",
    title: "Drag handle",
  },
  lockRule: {
    label: "🔓",
    title: "Lock rule",
  },
  lockGroup: {
    label: "🔓",
    title: "Lock group",
  },
  lockRuleDisabled: {
    label: "🔒",
    title: "Unlock rule",
  },
  lockGroupDisabled: {
    label: "🔒",
    title: "Unlock group",
  },
};


