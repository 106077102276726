import { IconButton } from "@mui/material";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { generateWidgetColorPallete } from "../../../Utils/ColorUtils";
import { WHATSAPP_PRIMARY_COLOR } from "./Constants";
import { StyledBroadcastPreviewWrap, WhatsappBotArea } from "./Styles";
import { convertWhatsappTextToHtml } from "../WhatsApp/Utils/utils";

const BroadcastPreview: React.FC<IBroadcastPreviewProps> = ({
  primaryColor = WHATSAPP_PRIMARY_COLOR,
  previewType = "WHATSAPP",
  bodyValue = "",
  headerValue = "",
  footerValue = "",
  buttonsData = [],
  buttonsType = "none",
  headerType = "none",
}) => {
  const scheme = generateWidgetColorPallete(primaryColor);
  const text = convertWhatsappTextToHtml(bodyValue)?.replaceAll("\n", "<br/>");
  return (
    <WhatsappBotArea>
      <StyledBroadcastPreviewWrap colorScheme={scheme}>
        <div className="widgetWrap">
          {/* HEADER */}
          {renderTopHeader(previewType)}

          {/* SCREEN WRAP */}

          <div className="chatScreenWrap">
            <div className="chat-screen__content">
              <div className="singleLineBox">
                <p className="avatar">A</p>
                <div className="MsgContainer">
                  {/* RENDER DYANMIC HEADER */}

                  {renderHeader(headerType, headerValue)}
                  <div
                    className="bodyMsgContainer"
                    dangerouslySetInnerHTML={{
                      __html: text || "",
                    }}
                  />
                  <div className="footerMsgContainer">{footerValue}</div>
                  {/* RENDER DYANMIC BUTTONS */}
                  {renderButtons(buttonsData)}
                </div>
              </div>
            </div>
          </div>
          {/* INPUT BAR  */}

          {renderInputBar(previewType)}
        </div>


      </StyledBroadcastPreviewWrap>
    </WhatsappBotArea>
  );
};

export default BroadcastPreview;

const renderHeader = (headerType: string, headerValue: any) => {
  return (
    <>
      <div className="headerMsgContainer">
        {typeof headerValue === "string" && headerValue}
      </div>
      {headerType === "none" && null}
      {headerType === "text" && null}
      {headerValue?.source ? (
        <div className="mediaBox">
          {headerType === "image" && <img src={headerValue?.source} alt="" />}
          {headerType === "video" && <video src={headerValue?.source} />}
          {headerType === "document" && (
            <AppMaterialIcons iconName={"DescriptionOutlined"} />
          )}
        </div>
      ) : null}
    </>
  );
};
const renderButtons = (buttonsData: any = []) => {
  return buttonsData?.map((curItem: any, index: number) => {
    return (
      <div className="buttonMsgContainer" key={index.toString()}>
        {curItem?.action_type?.length > 0 && (
          <AppMaterialIcons
            iconName={curItem?.action_type === "URL" ? "Link" : "Call"}
          />
        )}

        {curItem?.button_text}
      </div>
    );
  })
};

const renderTopHeader = (previewType: string) => {
  if (previewType === "SMS") {
    return (<div className="headerWrap">
      <div className="headerLeft">
        <p className="headerLogo">A</p>
        <p className="headerTitle">User</p>
      </div>
      <div className="headerRight">

      </div>
    </div>)
  } else {
    return (<div className="headerWrap">
      <div className="headerLeft">
        <p className="headerLogo">U</p>
        <p className="headerTitle">User</p>
      </div>
      <div className="headerRight">
        <AppMaterialIcons iconName={"MoreVert"} />
      </div>
    </div>)
  }

}

const renderInputBar = (previewType: string) => {
  if (previewType === "SMS") {
    return (<div className="inputBarWrap">
      <div className="inputBarTextBox">
        <div className="textBoxLeft">
          <AppMaterialIcons iconName={"AddCircleOutline"} />
          Message
        </div>
      </div>

      <div className="inputBarRightBox">
        <IconButton>
          <AppMaterialIcons iconName={"Send"} />
        </IconButton>
      </div>
    </div>)
  } else {
    return (<div className="inputBarWrap">
      <div className="inputBarTextBox">
        <div className="textBoxLeft">
          <AppMaterialIcons iconName={"SentimentVerySatisfied"} />
          Hello div..
        </div>
        <div className="textBoxRight">
          <AppMaterialIcons iconName={"CameraAlt"} />
          <AppMaterialIcons iconName={"AttachFile"} />
        </div>
      </div>

      <div className="inputBarRightBox">
        <IconButton>
          <AppMaterialIcons iconName={"Mic"} />
        </IconButton>
      </div>
    </div>)
  }
}
