import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class SegmentNetworkService{
    static getAllSegmentListData(data:any){
        
        return http.post(`${consoleBaseUrl}${Endpoints.GET_ALL_SEGMENT_LIST}`,data)
    }

    static getSegmentById(id:any){
        return http.get(`${consoleBaseUrl}${Endpoints.GET_SEGMENT_BY_ID}/${id}`)
    }

    static getAllSegmentConatctListData(data:any){
        
        return http.post(`${consoleBaseUrl}${Endpoints.GET_SEG_CONTACT}`,data)
    }

    static createSegmentData(data:any){
        return http.post(`${consoleBaseUrl}${Endpoints.CREATE_SEGMENT_DATA}`,data)
    }

    static deleteSegmentById(id:any){
        return http.delete(`${consoleBaseUrl}${Endpoints.DELETE_SEGMENT}${id}`)
    }

    static editSegment(data: any, id: string){
        return http.put(`${consoleBaseUrl}${Endpoints.EDIT_SEGMENT}/${id}`,data)
    }

    // static postDownloadLeadData = (queryData = {}, config = {}) => {
    //     return ConversationService.httpService.post({url:CH_BOT_ENDPOINTS.DOWNLOADLEADS, data:queryData,  headers: config});
    //   };

    static DownloadConatctsFromSegment = (data:any)=>{
        return http.post(`${consoleBaseUrl}${Endpoints.EXPORT_CONTACT_SEG}`,data)
    }

}


class Endpoints{
    static GET_ALL_SEGMENT_LIST = "/workspace/segment-list";
    static GET_SEGMENT_BY_ID = "/workspace/get-segment"
    static CREATE_SEGMENT_DATA = "/workspace/create-segment";
    static DELETE_SEGMENT = "/workspace/delete-segment/";
    static EDIT_SEGMENT = "/workspace/update-segment";
    static GET_SEG_CONTACT = "/workspace/get-segment-contacts";
    static EXPORT_CONTACT_SEG = "/workspace/download-segment-contacts";
}