import { getIn, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { CreditDetailsActions } from "../../../../../Store/Slices/CreditDetails/CreditDetails.actions";
import { whatsappDataStore } from "../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import WhatsappBroadcastResolveVaribles from "../../../../Leads/contact-action/utils/WhatsappBroadcastResolveVaribles";
import { dataToTemplateFormData } from "../../Utils/utils";

interface Props {
  formStep: number;
  sizeErrorMsg?: string;
  formatErrorMsg?: string;
  setFormStep: Dispatch<SetStateAction<number>>;
  label?: string;
  children?: React.ReactNode;
  mediaType: string;
  [otherProps: string]: any;
  onValueChange?: (res: any) => void;
  onFileChange?: (res: any) => void;
}

const RenderFormStep3: React.FC<Props> = ({
  formStep,
  setFormStep,
  mediaType,
}) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext<any>();
  const { templateByIdData }: any = useAppSelector(whatsappDataStore);

  useEffect(() => {
    dispatch(CreditDetailsActions.getBroadcastCreditDetails(getIn(values, "id")))
  }, [formStep]); // eslint-disable-line react-hooks/exhaustive-deps
  async function fetchData() {
    const data = { ...await dataToTemplateFormData(templateByIdData?.original_response), components: templateByIdData?.original_response?.components };
    setFieldValue("template", data);
  }
  useEffect(() => {
    fetchData();
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <WhatsappBroadcastResolveVaribles />
  );
};

export default RenderFormStep3;


