import styled from "styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface AppInputFieldProps {
  label?: string;
  name: string;
  mobileCountryFormat: string;
  divStyle?: any;
  tooltip?: any;
  disabled?: boolean;
  children?: React.ReactNode;
  [otherProps: string]: any;
  requiredSign?: boolean;
  defaultCountry?: string;
  onChangeCallbackFn?: (name: any, value: any) => void;
}

interface StyledProps {
  isError?: any;
}

const AppFormMobileField: React.FC<AppInputFieldProps> = ({
  label,
  name,
  mobileCountryFormat,
  divStyle,
  tooltip,
  children,
  disabled,
  requiredSign,
  defaultCountry = "in",
  onChangeCallbackFn,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext<any>();

  let [mobileCountry] = useState<any>({});
  let [invalidPhone] = useState<any>(null);
  let [value, setValue] = useState<any>();

  const handleValueChange = (e: any) => {
    setFieldValue(mobileCountryFormat, mobileCountry);
    //do not remove settimeout
    setTimeout(() => {
      setFieldValue(name, e);
      onChangeCallbackFn && onChangeCallbackFn(name, e);
    }, 1);
  };

  useEffect(() =>{
    if(typeof getIn(values, name) == "number"){
      setValue(getIn(values, name).toString())
    }else{
      setValue(getIn(values, name))
    }
  },[getIn(values, name)]) // eslint-disable-line react-hooks/exhaustive-deps
  
  
  return (
    <Div style={divStyle}>
      <Label>
        {requiredSign ? (
          <Fragment>
            {label}
            <sup
              className="starSuperScript"
              style={{ color: `${theme.palette.default.error}` }}
            >
              {" "}
              *
            </sup>
          </Fragment>
        ) : (
          label
        )}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <StyledMobileInput
        {...otherProps}
        value={value}
        onBlur={() => setFieldTouched(name)}
        country={defaultCountry}
        onChange={(e: any) => handleValueChange(e)}
        isValid={(value: any, country: any) => {
          mobileCountry = country;
          return true;
        }}
        enableLongNumbers={17}
        countryCodeEditable={false}
        jumpCursorToEnd={true}
        enableSearch={true}
        autocompleteSearch={true}
        disabled={disabled}
        inputProps={{
          name: "phone",
          required: true,
        }}
        isError={
          getIn(errors, name) && getIn(touched, name)
            ? getIn(errors, name) + invalidPhone
            : false
        }
      />
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default AppFormMobileField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;

const StyledMobileInput = styled(PhoneInput)<StyledProps>`
  && {
  }

  .form-control {
    position: relative;
    width: 100%;
    width: 100%;
    height: 40px;
    padding-left: 60px;
    background-color: ${theme.palette.secondary.main};
    color: ${theme.palette.default.text} !important;
    border-radius: 4px;
    outline: none !important;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    margin-bottom: 0.5rem;
    border: 2px solid
      ${(props: any) =>
        props.isError ? theme.palette.default.error : "transparent"}!important;

    &::placeholder {
      color: ${theme.palette.default.darkGrey};
    }

    &:active {
      border: 2px solid ${theme.palette.default.border};
      color: ${theme.palette.default.text};
    }
    &:focus {
      border: 2px solid ${theme.palette.default.border} !important;
      color: ${theme.palette.default.text};
    }
  }

  .invalid-number {
    border: 1px solid #cbcdd3 !important;

    background-color: blue !important;
    border-left-color: #cacaca;
  }

  .flag-dropdown {
    margin: 2px;
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: none;
    border-top: none;
    border-bottom: none;
  }

  .country-list {
    outline: none;
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 10px 0 10px -1px;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: #f5f6f8;
    color: black;
    width: 250px;
    max-height: 250px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    left: 10%;
    top: 90%;
  }

  .search {
    padding: 12px;

    margin: 0 0;
    top: 0;
    left: 0;
    width: 100%;
    .search-emoji {
      font-size: 13px;
      position: absolute;
      top: 4;
      right: 2%;
      display: none;
    }

    .search-box {
      width: 95%;
      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
      font-size: 14px;
      height: 1.8rem;
      line-height: 16px;
      outline: none;
      margin-left: 0;
      margin: 0 0px;
      padding: 0 8px;
    }
  }
`;
