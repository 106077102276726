import { InputAdornment, TextField } from "@mui/material"
import { useEffect, useState } from "react";

const AppInput = (props:any) => {
    const CHARACTER_LIMIT = 20;
    const [name , setName] = useState('')
    const setNameFn = (e:any) => {
        setName(e.target.value)
        if(props?.handleChange){
            props?.handleChange(e.target.value)
        }
    }
    useEffect(() => {
        setName(props?.value)

    },[props?.value])
    
    return (
        <>
            <TextField
                label=""
                inputProps={{
                    maxlength: props?.characterLimit || CHARACTER_LIMIT
                }}
                value={props?.value}
                onChange={(e:any) => setNameFn(e) }
                placeholder={props?.placeholder}
                margin="normal"
                autoComplete="off"
                variant="outlined"
                {...props}
                InputProps={{
                    endAdornment: <InputAdornment position="start">{props?.value?.length}/{props?.characterLimit || CHARACTER_LIMIT}</InputAdornment>,
                    startAdornment: props?.startText ? <a style={{marginRight:"10px",color:"#716565"}}>{props?.startText}</a>  : ""
                }}
            />
        </>
    )
}
export default AppInput