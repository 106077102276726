import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";

export const AnsweredTable = styled.div`
  .search-class {
    display: flex;
    align-items: center;
  }
  .website {
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    padding-bottom: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    P {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: ${theme.palette.default.newTextGrey};
    }
  }

  .left-part {
    width: 700px;
  }
  .front-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .front-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: ${theme.palette.default.newTextGrey};
  }
`;
export const PopOverText = styled.div`
  box-shadow: 0px 8px 24px 0px ${theme.palette.default.PopoverShadow};

  .filter-header p {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.blue};
    background-color: ${theme.palette.default.GhostWhite};
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .faq-filter ul {
    list-style: none;
    padding-left: 16px;
  }
  .faq-filter span {
    color: ${theme.palette.default.newTextGrey};
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
  }
  .button {
    display: flex;
    gap: 1rem;
    padding: 12px 16px;
  }
`;
export const AnsweredFaqList = styled.div`
  .table-body {
    height: calc(100vh - 510px);
    overflow: auto;
  }
  .table-header {
    display: flex;
    align-items: stretch;
    font-weight: bold;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    padding-bottom: 4px;
    padding-top: 4px;
    flex: 1;
  }

  .table-row {
    display: flex;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
  }
  .query1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .query2 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .query3 {
    color: ${theme.palette.default.AccentOrange};
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding: 3px;
    border: 1px solid ${theme.palette.default.AccentOrange};
    width: 200px;
    text-align: center;
    border-radius: 4px;
  }

  .col-head,
  .col-body {
    padding: 16px 16px;
    color: ${theme.palette.default.newTextGrey};
    font-size: 14px;
    font-weight: 400;
    :nth-child(1) {
      flex-basis: 300px;
    }
    :nth-child(2) {
      flex-basis: 420px;
      @media (max-width: 1400px) {
        flex-basis: 280px;
      }
    }

    :nth-child(3) {
      flex-basis: 315px;
    }
    :nth-child(n + 3) {
      flex: 1; /* columns from the third child to grow equally */
    }
    :nth-child(7) {
      display: flex;
      align-items: baseline;
      justify-content: end;
      height: 70px;
    }
  }

  .col-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    padding: 10px;
    border-right: 1px solid ${theme.palette.default.RightBorderDivder};

    :nth-child(2) {
      flex-basis: 426px;
      @media (max-width: 1400px) {
        flex-basis: 280px;
      }
    }

    :nth-child(3) {
      flex-basis: 315px;
    }
    :nth-child(8) {
      border-right: none;
    }
    :nth-child(n + 3) {
      flex: 1; /* Allow columns from the third child to grow equally */
    }
  }
`;
export const AnsweredWrap = styled.div`
height: 100%;

  .form-wrapper {

    .customInput {
      .wrapper {
        max-height: 400px;
        .inner-wrapper {
          height: 200px;
        }
      }
    }
  }

  .answered-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
    align-items: center;
    padding: 16px;
    height: 63px;
  }
  .answered-heading p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: ${theme.palette.default.NightRider};
  }
  .input-field label {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .input-field p {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .tooltip label {
    display: flex;
    align-items: center;
  }

  .body-popup {
    overflow: auto;
    // height: calc(70vh);
  }

  .btn {
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 16px;
    height: 68px;
  }

  .custom-textarea::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${theme.palette.default.newTextGrey};
  }
  .custom-textarea {
    margin-top: 16px;
  }

  .radio-button {
    gap: 16px;
    padding: 10px 0;
  }
  .body-popup {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 22px;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .MuiFormGroup-root {
    display: contents;
  }

  .list h3 {
    padding-top: 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.newTextBlack};
  }
  .list li {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${theme.palette.default.newTextGrey};
    margin-bottom: 8px;
  }

  .list ul {
    padding-bottom: 16px;
    padding-left: 16px;
    padding-top: 8px;
  }
  .select-field h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.newTextBlack};
  }
  .btn {
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 16px;
    border-top: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
`;
