import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { GrahWrapper } from "./style";
import { TextTitle } from "./style";
import { SkeletonCard, SkeletonProgressBar } from "./SkeletonCard";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import { formatTime } from "../../Utils/Utils";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import AppSkeletonLoader from "../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
interface BorderLinearProgress2Props {
  backgroundColor?: string;
}
const BorderLinearProgress2 = styled(LinearProgress)<BorderLinearProgress2Props>(({ theme,backgroundColor }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: backgroundColor ? backgroundColor : theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "purple" : "#308fe8",
  },
}));

interface Props {
  data?: any
  resolution_time?: any;
  response_time?: any;
  xAxisMinValue?: any;
  responseTimeTooltip?: any;
  xAxisCount?: any;
  resolutionTimeTooltip?: any;
  loading?: any;
}

const ResponsePrgressBar: React.FC<Props> = ({
  data,
  resolution_time,
  response_time,
  xAxisMinValue,
  responseTimeTooltip,
  xAxisCount,
  resolutionTimeTooltip,
  loading,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }} className="graph_container" height={"inherit"}>
      <GrahWrapper>
        <div className="header">
          <p className="time_stats">
            <TextTitle>{data?.label || ""}</TextTitle>
            <AppToolTip
              divStyle={{ padding: "0 8px" }}
              tooltipProps={{
                title: data?.info,
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<InfoOutlinedIcon className="div_tooltip" style={{ fontSize: "16px" }}
              />}
            />
            <p>{data?.total_count}</p>
          </p>
        </div>
        <Box className="graph_body">
        {data && !data?.data?.every((item:any) => item.value === 0) &&  data?.data?.length > 0 ?
          data?.data?.map((res: any) => {
          return (
            <div className="progess_bar" style={{display:"grid"}}>
              <div className="progress_bar_text" style={{margin:"-19px 11px"}}>
                {
                  loading ? <SkeletonCard /> :
                    <p >{res?.label}</p>
                }
              </div>
              {loading ? (
                <SkeletonProgressBar />
              ) : (
                <div className="progress_bar_count">
                  <p className="stats">{res?.value}</p>
                  <BorderLinearProgress2
                    variant="determinate"
                    value={
                      (res.value / data?.total_count) * 100 ? (res.value / data?.total_count) * 100 : 0
                      // (res.value / 100) * 100
                    }
                    backgroundColor = {res?.backgroundColor}
                  />
                </div>
              )}
            </div>
          )
        })
       : loading ? <Box p={1} height={"100%"}><AppSkeletonLoader /></Box>  :<NoRecordsFound />}
        </Box>
      </GrahWrapper>
    </Box>
  );
};

export default ResponsePrgressBar;
