// vendors
import { ButtonProps } from "@mui/material/Button";

// styled
import { ButtonStyled } from "./Button.styled";

const UIButton = (props: ButtonProps) => {
  const { children, ...rest } = props;

  return (
    <ButtonStyled disableElevation {...rest}>
      {children}
    </ButtonStyled>
  );
};

export default UIButton;
