// vendors
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PopoverProps } from "@mui/material/Popover";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ListItemText from "@mui/material/ListItemText";

export const commonStyles = {
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 400,
  color: "#7E8392",
};

export const ErrorTextStyled = styled(Typography)(() => ({
  ...commonStyles,
  color: "#ef5350",
  marginTop: "8px",
}));

export const TextStyled = styled(Typography)(() => ({
  ...commonStyles,
})) as typeof Typography;

export const PlaceholderTextStyled = styled(Typography)(() => ({
  ...commonStyles,
  color: "rgba(126, 131, 146, 0.5)",
})) as typeof Typography;

export const DateFormatLabel = styled(Typography)(() => ({
  ...commonStyles,
  marginBottom: "4px",
})) as typeof Typography;

export const SectionContainerStyled = styled(Box)(() => ({
  "& .MuiInputBase-root": {
    backgroundColor: "#F5F6F8",
  },
  "& .MuiSelect-multiple.MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
  },
})) as typeof Box;

export const FormLabelWrapperStyled = styled(Box)(() => ({
  display: "inline-flex",
  alignItems: "center",
  gap: "6px",
})) as typeof Box;

export const DateFormatWrapperStyled = styled(Box)(() => ({
  marginTop: "34px",
  "& .MuiInputBase-root": {
    backgroundColor: "#F5F6F8",
  },
})) as typeof Box;

export const SectionWrapperStyled = styled(Box)(() => ({
  marginTop: "16px",
})) as typeof Box;

export const EditConfigurationButton = styled(IconButton)(() => ({
  height: "24px",
  width: "24px",
  padding: "0px !important",
  borderRadius: "4px",
  border: "1px solid #CBCDD3",
})) as typeof IconButton;

export const PaperStyled = {
  width: "403px",
  backgroundColor: "#F5F6F8",
  marginLeft: "23px",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1);",
  padding: "14px 12px",
  '@media(max-width: 1599.98px)': {
    marginLeft: "0px",
    marginRight: '23px',
  },
};

export const TabsStyled = styled(Tabs)(() => ({
  border: "1px solid #5B73E8",
  borderRadius: "4px",
  "& .MuiTabs-indicator": {
    display: "none",
  },
})) as typeof Tabs;

export const TabStyled = styled(Tab)(() => ({
  textTransform: "capitalize",
  flex: 1,
  height: "38px",
  minHeight: "38px",
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: 400,
  color: "#5B73E8",
  "&.Mui-selected": {
    color: "#FFFFFF",
    backgroundColor: "#5B73E8",
  },
})) as typeof Tab;

export const SectionStyled = styled(Box)(() => ({
  backgroundColor: "#FFFFFF",
  marginTop: "8px",
  padding: "16px",
})) as typeof Box;

export const PopoverConfig = (open: boolean): PopoverProps => ({
  open,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center" as "center",
    horizontal: "left",
  },
  sx: { ml: "24px", borderRadius: "8px" },
  PaperProps: {
    sx: PaperStyled,
  },
});

export const DatePickerStyled = styled(DatePicker)(() => ({
  width: "100%",
  "& > .MuiInputBase-root": {
    padding: "8px 0 8px 12px",
  },
  "& .MuiButtonBase-root": {
    marginRight: "12px",
  },
  "& .MuiInputBase-root": {
    color: '#7E8392',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
  }
})) as typeof DatePicker;

export const DropdownLabelStyled = styled(Typography)(() => ({
  ...commonStyles,
  marginBottom: '8px'
})) as typeof Typography;

export const SelectedLabelStyled = styled(Typography)(() => ({
  color: "#7E8392",
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: "400",
})) as typeof Typography;

export const ListItemTextStyled = styled(ListItemText)(() => ({
  "& .MuiTypography-root": {
    ...commonStyles
  }
})) as typeof ListItemText;

export const MultiSelectWrapperStyled = styled(Box)(() => ({
  "& .MuiInputBase-root": {
    backgroundColor: "#F5F6F8",
  },
  "& .MuiSelect-multiple.MuiInputBase-input": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
})) as typeof Box;

export const DatePickerWrapperStyled = styled(Box)(() => ({
  "& .MuiPickersToolbar-root.MuiPickersLayout-toolbar": {
    display: "none",
  },
  "& .MuiDayCalendar-header": {
    backgroundColor: "#F5F6F8",
  },
  "& .MuiDayCalendar-weekDayLabel": {
    color: "#101010",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    letterSpacing: "0.03em",
    textTransform: "uppercase",
  },
  "& .MuiPickersDay-root": {
    color: "#101010",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    letterSpacing: "0.03em",
    textTransform: "uppercase",
  },
  "& .MuiPickersDay-root.Mui-selected": {
    color: "#fff",
  },
  "& .MuiPickersLayout-actionBar": {
    display: "none",
  },
  "& .MuiPickersDay-root.Mui-disabled": {
    color: "#CBCDD3",
  },
  "& .MuiInputBase-root": {
    color: "#7E8392",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
  },
})) as typeof Box;