import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContactPropertiesActions } from "./contactProperties.action";

interface initialStateTypes {
  data: {
    contactPropertiesListData: any;
    contactSubSectionList: any;
    DeleteModelAction: any;
    HideModelAction: any;
    ChangeSectionModelAction: any;
    DeleteModelPropertyAction: any;
    parentSectionId: any;
    type: any;
    selectedSectionData: any;
    selectedFieldData: any;
    addUpdateSectionModel: boolean;
    allSectionDetails: any;
    allFieldOptions: any;
    hiddenFields: any;
    DependecyPropertiesListing: any;
    PrimaryOptions: any;
    DependantOptions: any;
    dependencyObject: any;
    SelectedOptionID: any;
    // Duplicate Select Fileds
    selectedMappingID: any;
    PrimaryOptionsSelectFields: any[];
    MappingObjectByID: any;
    DependencyList: any;
    DependencyListCount: any;
    ContactPropertiesList :any;
    Level: any; // Field Level or Section Level
    ClearIds: any;
  };
}

const initialState: initialStateTypes = {
  data: {
    contactPropertiesListData: [],
    contactSubSectionList: [],
    DeleteModelAction: false,
    HideModelAction: false,
    ChangeSectionModelAction: false,
    DeleteModelPropertyAction: false,
    parentSectionId: [],
    type: "",
    selectedSectionData: null,
    addUpdateSectionModel: false,
    allSectionDetails: [],
    selectedFieldData: null,
    allFieldOptions: [
      { id: 1, label: "", position: 1 },
      { id: 2, label: "", position: 2 },
    ],
    hiddenFields: [],

    DependecyPropertiesListing: [],
    PrimaryOptions: [],
    DependantOptions: [],
    dependencyObject: [],
    SelectedOptionID: "",
    // Duplicate Select Fileds
    selectedMappingID: "",
    PrimaryOptionsSelectFields: [],
    MappingObjectByID: {},
    DependencyList: [],
    DependencyListCount: [],
    Level: "",
    ClearIds: [],
    ContactPropertiesList:[]
  },
};

const ContactPropertiesSettingsSlice = createSlice({
  name: "ContactPropertiesSettingsSlice",
  initialState,
  reducers: {
    resetError: (state: any, action?: PayloadAction<any>) => {
      // state.error = "";

      // console.log("action.payload.activeSteps", state.data.activeSteps);
      return state;
    },
    resetContactPropertiesListData: (
      state: any,
      action: PayloadAction<any>
    ) => {
      state.data.contactPropertiesListData = [];
      return state;
    },
    setDeleteModel: (state: any, action: PayloadAction<any>) => {
      state.data.DeleteModelAction = action.payload;

      return state;
    },

    setHideModel: (state: any, action: PayloadAction<any>) => {
      state.data.HideModelAction = action.payload;
      return state;
    },

    setChangeSectionModel: (state: any, action: PayloadAction<any>) => {
      state.data.ChangeSectionModelAction = action.payload;
      return state;
    },

    setParentSectionValue: (state: any, action: PayloadAction<any>) => {
      state.data.parentSectionId = action.payload;
      return state;
    },

    setfieldtype: (state: any, action: PayloadAction<any>) => {
      state.data.type = action.payload;
      return state;
    },
    setSelectedSectionData: (state: any, action: PayloadAction<any>) => {
      state.data.selectedSectionData = action.payload;
      return state;
    },
    setAddUpdateSectionModel: (state: any, action: PayloadAction<any>) => {
      state.data.addUpdateSectionModel = action.payload;
      return state;
    },
    setSelectedFieldData: (state: any, action: PayloadAction<any>) => {
      state.data.selectedFieldData = action.payload;
      return state;
    },
    setLevel(state, action: PayloadAction<any>) {
      state.data.Level = action.payload;
    },
    setContactPropertices(state, action: PayloadAction<any>) {
      state.data.ContactPropertiesList = action.payload;
    },
    // UpdatedOptionID(state, { payload }: PayloadAction<any>) {
    UpdatedOptionID(state, action: PayloadAction<any>) {
      state.data.SelectedOptionID = action.payload;
    },
    UpdatedFields(state, action: PayloadAction<any>) {
      const { field_id, dependant_field_id, option_mapping, currentId } =
        action.payload;
      const existingIndex = state.data.dependencyObject.findIndex(
        (item: any) => item.field_id === field_id
      );
      let optindex = -1;
      if (!!state.data.SelectedOptionID) {
        optindex = state.data.dependencyObject[0]?.option_mapping?.findIndex(
          (i: any) => i?.option_id === state.data.SelectedOptionID
        );
      }

      if (existingIndex === -1) {
        // If field_id doesn't exist, create a new entry
        state.data.dependencyObject = [
          ...state.data.dependencyObject,
          {
            field_id,
            dependant_field_id,
            // option_mapping: [...option_mapping], // Ensure immutability
            option_mapping: Array.isArray(option_mapping)
              ? [...option_mapping]
              : [], // Ensure immutability
          },
        ];
      } else {
        // If field_id exists, check if the option_id exists within option_mapping
        const optionIndex = state?.data?.dependencyObject[
          existingIndex
        ]?.option_mapping.findIndex(
          (option: any) => option?.option_id === option_mapping[0]?.option_id
        );

        if (optionIndex === -1) {
          // If option_id doesn't exist, create a new entry within option_mapping
          state.data.dependencyObject = state.data.dependencyObject.map(
            (item: any, index: any) => {
              if (index === existingIndex) {
                return {
                  ...item,
                  dependant_field_id,
                  option_mapping: [...item.option_mapping, ...option_mapping], // Ensure immutability
                };
              }
              return item;
            }
          );
        } else {
          // If option_id exists, update the existing entry within option_mapping
          state.data.dependencyObject = state.data.dependencyObject.map(
            (item: any, index: any) => {
              if (index === existingIndex) {
                return {
                  ...item,
                  option_mapping: item?.option_mapping?.map(
                    (option: any, optIndex: any) => {
                      if (optIndex === optionIndex && optIndex === optindex) {
                        let mergedIds: any[] = []; // or any other default value
                        if (!option?.ids.includes(currentId)) {
                          mergedIds = option?.ids
                            .concat(option_mapping?.[0]?.ids || [])
                            .filter(
                              (value: any, index: any, self: any) =>
                                self.indexOf(value) === index
                            );
                          return {
                            ...option,
                            ids: mergedIds, // Ensure immutability
                          };
                        } else {
                          mergedIds = option?.ids.filter(
                            (item: any) => item !== currentId
                          );

                          return {
                            ...option,
                            ids: mergedIds, // Ensure immutability
                          };
                        }
                      }

                      return option;
                    }
                  ),
                };
              }
              return item;
            }
          );
          // console.log("option_mapping?.[0]?.ids", option_mapping?.[0]?.ids)
          // console.log("optindex", optindex)
          // if (option_mapping?.[0]?.ids?.length !== 0)
          //     state.data.dependencyObject.option_mapping[optindex].ids = [...state?.data?.dependencyObject?.option_mapping?.[optindex]?.ids, option_mapping?.[0]?.ids]
        }
      }
    },

    resetDependencyObject(state) {
      return {
        ...state,
        data: {
          ...state.data,
          dependencyObject: initialState.data.dependencyObject,
        },
      };
    },
    resetPrimaryOptions(state) {
      state.data.PrimaryOptions = [];
    },
    resetDependantOptions(state) {
      state.data.DependantOptions = [];
    },
    selectPrimaryOption: (state, action: PayloadAction<string>) => {
      const selectedValue = action.payload;
      state.data.selectedMappingID = selectedValue;
      return state.data.DependecyPropertiesListing;
    },
    // clearTheIds: (state, action: PayloadAction<any>) => {
    //     console.log(action?.payload)
    //     console.log(state.data.dependencyObject[0]?.option_mapping[0]?.option_id)
    //     console.log(state.data.dependencyObject[0]?.option_mapping[0]?.ids[0])
    //     // state.data.dependencyObject[0]?.option_mapping[0]

    //     console.log(state.data.dependencyObject[0]?.option_mapping.find((obj: any) => {
    //         if (obj.option_id === action?.payload) {
    //             console.log("option_id", obj?.option_id)
    //             console.log("Ids", obj?.ids?.length)
    //         }

    //     }));

    //     const dependencyObject = state.data.dependencyObject[0];

    //     dependencyObject.option_mapping = dependencyObject.option_mapping.map((obj: any) => {

    //         if (obj.option_id === action?.payload) {
    //             obj.option_id = "";
    //             obj.ids = [];
    //         }
    //         return obj;
    //     });

    //     // Log the updated state if needed
    //     console.log(state);

    //     // Return the state
    //     return state;

    //     // console.log("target", target?.ids[0], target?.option_id)
    // }

    // clearTheIds: (state, action: PayloadAction<any>) => {
    //     const updatedDependencyObject = {
    //         ...state.data.dependencyObject[0],
    //         option_mapping: state.data.dependencyObject[0]?.option_mapping.filter((obj: any) => {
    //             return obj.option_id !== action?.payload;
    //         })
    //     };

    //     // Create a new state object with the updated dependencyObject
    //     const newState = {
    //         ...state,
    //         data: {
    //             ...state.data,
    //             dependencyObject: [updatedDependencyObject]
    //         }
    //     };

    //     // Log the updated state if needed
    //     console.log(newState);

    //     // Return the updated state
    //     return newState;
    // }

    clearTheIds: (state, action: PayloadAction<any>) => {
      const updatedDependencyObject = {
        ...state.data.dependencyObject[0],
        option_mapping: state.data.dependencyObject[0]?.option_mapping.map(
          (obj: any) => {
            if (obj.option_id === action?.payload) {
              return {
                ...obj,
                ids: [],
              };
            }
            return obj;
          }
        ),
      };

      // Create a new state object with the updated dependencyObject
      const newState = {
        ...state,
        data: {
          ...state.data,
          dependencyObject: [updatedDependencyObject],
        },
      };

      // Log the updated state if needed

      // Return the updated state
      return newState;
    },
  },

  extraReducers: (builder) => {
    builder

      // Enable Channel Settings
      .addCase(
        ContactPropertiesActions.getListProperties.pending,
        (state, action) => {
          // state.data.contactPropertiesListData = []
        }
      )
      .addCase(
        ContactPropertiesActions.getListProperties.fulfilled,
        (state, action) => {
          // state.data.contactPropertiesListData = []
          if (action?.payload?.data?.status === 200) {
            state.data.contactPropertiesListData = action?.payload?.data?.data;
            state.data.addUpdateSectionModel = false;
          }
        }
      )
      .addCase(
        ContactPropertiesActions.getListProperties.rejected,
        (state, action) => {
          // state.data.contactPropertiesListData = []
        }
      )

      // Update Field
      .addCase(
        ContactPropertiesActions.updateFieldProperties.pending,
        (state, action) => {}
      )
      .addCase(
        ContactPropertiesActions.updateFieldProperties.fulfilled,
        (state, action) => {}
      )
      .addCase(
        ContactPropertiesActions.updateFieldProperties.rejected,
        (state, action) => {}
      )

      // Get All Section Details
      .addCase(
        ContactPropertiesActions.GetAllSectionDetails.pending,
        (state, action) => {}
      )
      .addCase(
        ContactPropertiesActions.GetAllSectionDetails.fulfilled,
        (state, action) => {
          state.data.allSectionDetails = action?.payload?.data?.data;
        }
      )
      .addCase(
        ContactPropertiesActions.GetAllSectionDetails.rejected,
        (state, action) => {}
      )

      // Get All Option
      .addCase(
        ContactPropertiesActions.GetAllOptions.pending,
        (state, action) => {}
      )
      .addCase(
        ContactPropertiesActions.GetAllOptions.fulfilled,
        (state, action) => {
          state.data.allFieldOptions =
            action?.payload?.data?.data?.options ||
            action?.payload?.data?.data ||
            [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetAllOptions.rejected,
        (state, action) => {}
      )

      // Get Hidden Field
      .addCase(
        ContactPropertiesActions.GetHiddenField.pending,
        (state, action) => {}
      )
      .addCase(
        ContactPropertiesActions.GetHiddenField.fulfilled,
        (state, action) => {
          state.data.hiddenFields = action?.payload?.data?.data;
        }
      )
      .addCase(
        ContactPropertiesActions.GetHiddenField.rejected,
        (state, action) => {}
      )
      .addCase(
        ContactPropertiesActions.GetDependencyProperties.pending,
        (state, action) => {
          state.data.DependecyPropertiesListing = [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetDependencyProperties.fulfilled,
        (state, action) => {
          state.data.DependecyPropertiesListing =
            action?.payload?.data?.fields || [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetDependencyProperties.rejected,
        (state, action) => {
          state.data.DependecyPropertiesListing = [];
        }
      )

      // PrimaryOptions
      .addCase(
        ContactPropertiesActions.GetPrimaryOptions.pending,
        (state, action) => {
          state.data.PrimaryOptions = [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetPrimaryOptions.fulfilled,
        (state, action) => {
          state.data.PrimaryOptions =
            action?.payload?.data?.data?.options ||
            action?.payload?.data?.data ||
            [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetPrimaryOptions.rejected,
        (state, action) => {
          state.data.PrimaryOptions = [];
        }
      )

      // Dependant Options
      .addCase(
        ContactPropertiesActions.GetDependantOptions.pending,
        (state, action) => {
          state.data.DependantOptions = [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetDependantOptions.fulfilled,
        (state, action) => {
          state.data.DependantOptions =
            action?.payload?.data?.data?.options ||
            action?.payload?.data?.data ||
            [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetDependantOptions.rejected,
        (state, action) => {
          state.data.DependantOptions = [];
        }
      )

      // Get Mapping By ID
      .addCase(
        ContactPropertiesActions.GetMappingByID.pending,
        (state, action) => {
          state.data.MappingObjectByID = [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetMappingByID.fulfilled,
        (state, action) => {
          state.data.MappingObjectByID =
            action?.payload?.data?.data?.options || [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetMappingByID.rejected,
        (state, action) => {
          state.data.MappingObjectByID = [];
        }
      )

      // Get Dependency List
      .addCase(
        ContactPropertiesActions.GetDependencyList.pending,
        (state, action) => {
          state.data.DependencyList = [];
          state.data.DependencyListCount = null;
        }
      )
      .addCase(
        ContactPropertiesActions.GetDependencyList.fulfilled,
        (state, action) => {
          state.data.DependencyList = action?.payload?.data?.data || [];
          state.data.DependencyListCount = action?.payload?.data?.count || [];
        }
      )
      .addCase(
        ContactPropertiesActions.GetDependencyList.rejected,
        (state, action) => {
          state.data.DependencyList = [];
          state.data.DependencyListCount = null;
        }
      );
  },
});

export const {
  resetError,
  resetContactPropertiesListData,
  setDeleteModel,
  setHideModel,
  setParentSectionValue,
  setfieldtype,
  setChangeSectionModel,
  UpdatedFields,
  UpdatedOptionID,
  resetDependencyObject,
  resetPrimaryOptions,
  resetDependantOptions,
  selectPrimaryOption,
  setSelectedSectionData,
  setAddUpdateSectionModel,
  setSelectedFieldData,
  setLevel,
  clearTheIds,
  setContactPropertices
} = ContactPropertiesSettingsSlice.actions;
export default ContactPropertiesSettingsSlice.reducer;
