import {
  Backdrop,
  Box,
  CircularProgress,
  CircularProgressProps,
  circularProgressClasses
} from "@mui/material";
import React from "react";
interface Props {
  loading?: boolean;
}

// Inspired by the former Facebook spinners.
export function FacebookCircularProgress(props: CircularProgressProps) {
  return (
    <Box sx={{ position: "relative" }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.default.greyishWhite,
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const AppNoActivityScreen: React.FC<Props> = ({ loading }) => {
  if (loading) {
    return (
      <Backdrop
        sx={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 9999999 }}
        open={true}
      >
        <FacebookCircularProgress />
      </Backdrop>
    );
  }

  return null;
};

export default AppNoActivityScreen;
