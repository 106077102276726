import React, { createRef } from 'react'
import AppSmallWindowPane from '../../../../../AppComponents/AppSmallWindowPane'
import AppButton from '../../../../../AppComponents/AppButton'
import { useNotification } from '../../../../../Hooks/useNotification/useNotification';
import QRCode from 'qrcode.react';
import { useCurrentBot } from '../../../../../Store/Slices/DashboardSlices';
import { useAppSelector } from '../../../../../Store';
import { capitalizeFirstLetter } from '../../../../../Utils';
import { getWhatsAppBotWelcomeText } from '../../../../../Store/Slices/botConfig/BotConfigSlice';

const LinkAndQRCode = (): JSX.Element => {
    const { showAlert } = useNotification();
    const currentBot: any = useAppSelector(useCurrentBot);
    let url: string = ""
    const qrCodeRef: any = createRef();
    const whatsapp_welcome_text : any = useAppSelector(getWhatsAppBotWelcomeText)
    const downloadQRCode = () => {
        const canvas: any = document.getElementById('canvas');
        const qrCodeUrl = canvas
            ?.toDataURL("image/png");

        const qrImage = new Image();
        qrImage.src = qrCodeUrl;
        qrImage.onload = () => {
            const newCanvas = document.createElement('canvas');
            const ctx: any = newCanvas.getContext('2d');
            const width = qrImage.width;
            const height = qrImage.height;
            newCanvas.width = width;
            newCanvas.height = height;
            // Draw white background
            ctx.fillStyle = '#FFF'; // White color
            ctx.fillRect(0, 0, width, height);
            // Draw QR code on top of white background
            ctx.drawImage(qrImage, 0, 0);
            // Convert to data URL and download
            const newQrCodeUrl = newCanvas.toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            const a = document.createElement('a');
            a.href = newQrCodeUrl;
            a.download = 'qr-code.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };
    };

    const copyToClipBoard = (content: string) => {
        // Get the text field
        let copyText: any = content
        navigator.clipboard.writeText(copyText);
        showAlert(capitalizeFirstLetter(currentBot?.channel) + ` Link Copied`, "success");
    }
    const currentBotComponent: any = {
        "whatsapp": `https://wa.me/${currentBot?.whatsapp_number}?text=${whatsapp_welcome_text}`,
        "messenger": `https://www.messenger.com/t/${currentBot?.facebook_page_id}`,
        "telegram": `https://t.me/${currentBot?.bot_username}`,
    }
    url = currentBotComponent[currentBot?.channel]
    return (
        <AppSmallWindowPane
            style={{
                minWidth: "90%",
                borderRadius: "8px",
                padding: "1.1rem 1.5rem 0rem 1.5rem",
                position: "relative",
            }}
        >
            <div>
                <p className='title'>Link</p>
                <p>Share where you want and redirect your users to Facebook bot in a Sinlge Click. </p>
                <p style={{ marginBottom: "10px" }}>Copy link & start Sharing</p>
                <AppButton startIcon='ri-file-copy-line' onClick={() => copyToClipBoard(url)} >
                    Copy {currentBot?.channel}  Link
                </AppButton>
            </div>

            <div className='qr_Container'>
                <p className='title'>QR Code</p>
                <p>Share where you want and redirect your users to Facebook bot in a Sinlge Click. </p>
                <div className='section'>
                    <div>
                        <p className='subTitle'>Preview</p>
                        <QRCode id='canvas' value={url} ref={qrCodeRef} />
                        <p className='link' onClick={() => downloadQRCode()}>Download QR Code</p>
                    </div>
                </div>
            </div>
        </AppSmallWindowPane>
    )
}

export default LinkAndQRCode