
import { useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAuthStore } from '../Store/Slices/AuthSlice';
import { useCurrentBot } from '../Store/Slices/DashboardSlices';

const useDocumentTitle=(title:string, prevailOnUnmount = false)=>{
  const defaultTitle = useRef(document.title);
  const currentBot = useSelector(useCurrentBot);
  const { data: authData} = useSelector(useAuthStore);
  
  useLayoutEffect(() => {
      if (authData && authData?.data && authData?.data?.user && authData?.data?.user?.first_name) {
        document.title = `${title} | ${authData?.data?.user?.first_name}`;
      }
      else {
        document.title = `${title} | Geta`;
      }
     
  }, [title, currentBot, authData?.data?.user]);  // eslint-disable-line react-hooks/exhaustive-deps


  useLayoutEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps
}

export default useDocumentTitle;