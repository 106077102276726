import styled from "styled-components";
import recibackground_logo from "../../../../../../assets/images/receipent2.png";
import { theme } from "../../../../../../Customization/Theme";

export const DWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius:4px;
  }
`;

export const SummaryWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    width: 100%;
    .parent_div2 {
      width: 100%;
      padding: 10px;
      height: auto;
    }
    
    .horizontal_line {
      height: auto;
      border: 0.2px solid gray;
      opacity: 50%;
    }
    
    .credits_btn {


      width: max-content;
      max-width: 70%;
      text-align: center;
      border-radius: 38px;
      background-color: #ffffff;
      color: ${theme.palette.default.black};
      margin-top: 10px;
    }
    .credits_btn > p {
      font-color: black;
    }

    .box-wrapper{
      width: 100%;
      height: 100%;
    }
   
  
    .j_div_Link {
      height: 100%;
    }
    .j_div_Link_all {
      height: calc(100% - 69px);
      display: flex;
      flex-direction: column;
      overflow: auto;
      // border: 4px solid green;
      // max-height: 17rem;
      padding-left: 1rem;
      margin-right: 8px;
      padding-right: 8px;
      margin-bottom: 8px;
    }
    
    .date_with_link {
      // width: 98%;
      // margin: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid ${theme.palette.default.grey};
    }
    .Date_info {
      color: #7e8392;
      font-family: Poppins;
      font-weight: 400;
      font-size: 14px;
    }
    .link_a {
      color: #5b73e8;
      text-decoration: none;
    }
    .horizontal_line {
      border: 0.4px solid #cbcdd3;
    }
    
    .dotted_hr {
      height: auto;
      border: 0.2px dashed gray;
      opacity: 50%;
    }
    
    .link {
      align-items: center;
    }
    .link > p {
      text-align: center;
    }

    .chart_text {
      font-weight: 400;
      font-family: Inter;
      font-size: 14px;
      color: #7e8392;
    }

    .chart_text_div {
      padding: 0px 40px;
      align-items: center;
      text-align: center;
    }
    
    .chart_text {
      font-weight: 400;
      font-family: Inter;
      font-size: 14px;
      color: #7e8392;
    }

    .chartTotalCount {
      font-size: 16px;
      font-weight: 500;
      font-family: Inter;
      text-align: center;
      color: #101010;
      padding-top: 18px;
    }

  }
`;

export const Wraprecipients = styled.div`
 & {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  background-image: url(${recibackground_logo});
  background-position: right bottom;
  background-size: cover;
  background-repeat: no-repeat;
 }
`;

export const Wrapper = styled.div`
 & {
  width: 100%;
  height: 100%;
  background-color: transperant;
  border-radius: 4px;
 }
`;


