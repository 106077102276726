import { RootState } from "../../index";


export const emailbroadcastInsightsAll = (state:RootState) => state.broadcast.data.broadcastDataEmailAll
export const logListDataObj = (state: RootState) =>state.broadcast.data.logListDataObj;
export const whatapplogListDataObj = (state: RootState) =>state.broadcast.data.whatapplogListDataObj;
export const filtersValuesData = (state: RootState) =>state.broadcast.data.filtersValues;
export const broadcastDataById = (state:RootState) => state.broadcast.data?.broadcastDataById;
export const logReportListDataObj = (state: RootState) =>state.broadcast.data.logReportListDataObj;
export const broadcastType = (state:RootState) => state.broadcast.data?.broadcastType
export const logReportLoadingSelector = (state: RootState) => state.broadcast.loading;

export const whatsappGraphTemplateData = (state: RootState) => state.broadcast.data.whatsappGraphTemplateData;
export const WhatsappAnalyticsLoadingArray = (state: RootState) => state.broadcast.whatsApploadingArray;

export const whatsappTotalMessagesData = (state: RootState) => state.broadcast.data.whatsappTotalMessagesData;

export const whatsappTotalMessagesLogsData = (state: RootState) => state.broadcast.data.whatsappTotalMessagesLogsData;
