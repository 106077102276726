import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../Customization/Theme";
import styled from "styled-components";

const toolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          border: `1px solid ${theme.palette.default.shadow}`,
          backgroundColor: `${theme.palette.common.white}`,
          color: `${theme.palette.default.text}`,
          padding: "10px 16px",
          // boxShadow: '0px 0px 2px 0px #999',
          boxShadow: `0px 0px 2px ${theme.palette.default.darkGrey}`,
          fontSize: "13px",
          lineHeight: "19.6px",
          borderRadius: "4px",
          letterSpacing: "0.05em",
        },
        arrow: {
          color: "white",
        },
      },
    },
  },
});

type CustomIconButtonWithTooltipProps = {
  tooltipProps: any;
  iconButtonProps?: any;
  IconComponent?: any;
  followCursor?: boolean;
  children?: any;
  className?: string;
  divStyle?: any;
  wrapperStyle?: any;
  iconWrapperStyle?: any;
};

const AppToolTip = ({
  tooltipProps,
  iconButtonProps,
  IconComponent,
  followCursor,
  children,
  className,
  divStyle,
  wrapperStyle,
  iconWrapperStyle,
}: CustomIconButtonWithTooltipProps) => {
  return (
    <ThemeProvider theme={toolTipTheme}>
      <Box className={className ? className : ""} style={wrapperStyle}>
        <Tooltip {...tooltipProps} followCursor>
          <IconWrapper style={iconWrapperStyle} className="icon-wrapper">
            {IconComponent && (
              <IconButton style={divStyle} {...iconButtonProps}>
                {IconComponent}
              </IconButton>
            )}
            {children || null}
          </IconWrapper>
        </Tooltip>
      </Box>
    </ThemeProvider>
  );
};

export { AppToolTip };

const IconWrapper = styled.div`
&& {
  display: flex;
  align-items: center;
}
&&::before {
  content: "";
}
`;
