import { Box, IconButton, Typography, Button, Link } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React from "react";
import AppButton from "../../../../../../AppComponents/AppButton";
import StyledCard from "../../../../../UiComponents/Card/StyledCard";
import MediaError from "../../../Assets/MediaError.svg";
import { StyledNotificationCard } from "./NotificationCardCss";
interface props {
  curItem?: any;
  handleEditButton?: any;
  handleDeleteButton?: any;
  handleOpenUrlFn?: any;
  isEditing?: boolean;
  hideActionBtn?: boolean;
}
const NotificationCard: React.FC<props> = ({
  curItem = "",
  handleEditButton = "",
  handleDeleteButton = "",
  handleOpenUrlFn = "",
  isEditing = false,
  hideActionBtn = false,
}) => {
  return (
    <StyledNotificationCard style={hideActionBtn ? {pointerEvents:"none"} : {}}>
      <Box className="available-notification__block">
        <Box className="notification-block__left">

          <Box className="notification-block__img-wrap">
            <img
              src={curItem?.image?.source || MediaError}
              alt="Notification"
              title={curItem?.image?.source ? "Notification Image" : "Media Not Found"}
            />
          </Box>

        </Box>
        <Box className="notification-block__right">
          <Box>
            <Box className="notification-block__content-title">
              <Typography variant="h6" className="notification-block__heading">
                {curItem?.notification_title}
              </Typography>
              {
                hideActionBtn ? null :    (<Box className="actionIcons" >
                <IconButton onClick={() => handleEditButton(curItem)}>
                  <EditOutlinedIcon />
                </IconButton>
                <IconButton disabled={isEditing} onClick={() => handleDeleteButton(curItem)}>
                  <i className="ri-delete-bin-line" ></i>
                </IconButton>
              </Box>)
              }
           
            </Box>
            <Typography className="notification-block__description" variant="body2">
              {curItem?.notification_description}
            </Typography>
          </Box>
          <Box className="notification-block__btn">
            <Typography
              variant="body2"
              className="notification-block__btn-title"
            >
              <Link href={curItem?.button_url}>
                {curItem?.button_title}
              </Link>
            </Typography>
            <Typography className="notification-block__footer" variant="caption" title={curItem?.footer_text}>
              {curItem?.footer_text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </StyledNotificationCard>
  );
};

export default NotificationCard;
