import styled from "styled-components";
import { FieldArray } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AppButton from "../../../../AppComponents/AppButton";
import AppIcon from "../../../../AppComponents/AppIcon";
import AppInputField from "../../../../AppComponents/AppInputField";
import {
  AppForm, AppFormSelectField, AppSubmitButton
} from "../../../../AppComponents/Forms";

import AppMultiColumnsFields from "../../../../AppComponents/Forms/AppMultiColumnsFields";
import { AppConfig } from "../../../../Config/app-config";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { ConversationService } from "../../../../Services/Apis/ConversationService";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const BOT_ID_KEY = 'currentBotId'

export const ZohoConfigureValidationSchema = Yup.object().shape({
  botname: Yup.string()
    .required("botname is required")
    .label("Email"),
  friends: Yup.array().of(Yup.object({
    name: Yup.string().required("name is required"),
    email: Yup.string().email('Please enter a valid email').required("email is required"),
    phone: Yup.number().required("name is required"),
  }))
});

export const VariableTypeOptions: any = [
  {
    id: 1,
    name: "Question",
    value: "question",
    description: null,
  },
  {
    id: 2,
    name: "Static Text / Select a choice",
    value: "static-text-select-a-choice",
    description: null,
  }
];

interface Props {
  handleDisconnectButton: any;
  configData: any;
  fieldMapping: any;
  handleSubmitBtn: any;
  userInput: any;
  setUserInput: any;
  getaVariables: any;
}

const ZohoConfigure: React.FC<Props> = ({
  handleDisconnectButton,
  configData,
  fieldMapping,
  handleSubmitBtn,
  userInput,
  setUserInput,
  getaVariables
}) => {

  // const [customValues, setCustomDropdown] = useState<any>(Boolean(true));
  const bot_id = AppConfig.getUserData(BOT_ID_KEY);
  let navigate = useNavigate();

  const data = {
    'bot_id': bot_id
  }

  const FieldMappingArrayForm = (fvalues: any) => {
    const formValues = fvalues?.values;
    return (
      <AppMultiColumnsFields>
        {
          fieldMapping &&
          <>
            <FieldArray
              name={'field_mapping'}
              render={({ insert, remove, push }) => (
                <div className="zoho-crm-field-div">
                  {fieldMapping.length > 0 &&
                    fieldMapping.map((item: any, index: any) => {
                      return (
                        <AppMultiColumnsFields key={index}>
                          <div className="parent-div">
                            <div className="zoho-label-name">
                              <label>{item['api_name']}</label>
                            </div>
                            <div className="form-div">
                              <AppFormSelectField
                                label="Variable Type"
                                Options={VariableTypeOptions}
                                name={item['api_name'] + '_type'}
                                id={'question'}
                              />
                              {formValues && formValues[item['api_name'] + '_type'] === 'question' &&
                                <AppFormSelectField
                                  label="Geta Variable"
                                  Options={getaVariables}
                                  name={item['api_name'] + '_variable'}
                                />
                              }
                              {formValues && formValues[item['api_name'] + '_type'] === 'static-text-select-a-choice' &&
                                <div className="inputWidth">
                                  {
                                    item['data_type'] === "picklist" &&
                                    <AppFormSelectField
                                      label="Select a Choice"
                                      Options={item['pick_list_values']}
                                      name={item['api_name'] + '_type'}
                                    />
                                  }
                                  {
                                    item['data_type'] != "picklist" &&
                                    <AppInputField style={{ backgroundColor: '#F5F6F8', marginBottom: '0rem !important' }}
                                      label="Static Text"
                                      type="text"
                                      name={item['api_name'] + '_type'}
                                      placeholder="Enter Value" errorMessage=""></AppInputField>
                                  }
                                </div>
                              }
                            </div>
                          </div>
                        </AppMultiColumnsFields>
                      )
                    }
                    )
                  }
                </div>
              )}
            />
            <br />
          </>
        }
        <div className="btnCenter">
          <AppSubmitButton
            style={{ margin: "0.5rem 0" }}
            title={"Save Changes"}
          />
        </div>
      </AppMultiColumnsFields>
    );
  }

  useEffect(() => {
  }, [userInput])

  return (
    <ZohoPageWrap>
      <div className="pageTopbar">
        <AppIcon
          title={"ri-arrow-left-line"}
          onClick={() => handleDisconnectButton()}
          size="26px"
          color="#282828"
        />
        <div className="accountInfo">
          {/* Connected: abc@gmail.com */}
          <AppButton variant="danger" onClick={() => handleDisconnectButton()}>
            Disconnect
          </AppButton>
        </div>
      </div>
      <div className="ctaBox">
        <img
          className="companyLogo"
          src="https://www.zoho.com/branding/images/zoho-logo.png"
          alt=""
        />
        <p className="ctaTitle">Configure your Zoho Account</p>
      </div>

      <div className="wid100">
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
            setUserInput(values);
          }}
          render={(formValues: any) => FieldMappingArrayForm(formValues)}
        // validationSchema={ZohoConfigureValidationSchema}
        >
        </AppForm>
      </div>
    </ZohoPageWrap>
  );
};

export default ZohoConfigure;

const ZohoPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;

    .pageTopbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      /* background-color: #F5F6F8; */
      padding: 0 0.4rem;
      width: 100%;

      .accountInfo {
        /* background-color: red; */
        height: 100%;
        /* width: 30%; */
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 2rem;
        font-size: 1rem;
        color: #7e8392;
      }
    }

    .ctaBox {
      display: flex;
      height: 20%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }

    .configureFormFieldsWrap {
      display: flex;
      /* justify-content: center; */
      /* background-color: #f5f6f8; */
      padding: 1rem 2rem;
      flex-direction: column;
      gap: 1rem;
      /* width: 60%; */
      /* width: 100%; */
    }

    .zoho-label-name {
      padding: 1rem 0;
      font-weight: 600;
    }

    .wid100 {
      width: 100%;
    }

    .zoho-crm-field-div {
      width:100%;
      padding: 0 6rem;
    }

    .form-div {
      display: flex;
      gap: 2rem;
      width: 100%;
    }

    .btnCenter {
      display: flex;
      justify-content: center;
    }

    .parent-div {
      width: 100%;
    }

    .inputWidth {
      width: 100%;
      margin: auto;
    }
  
  }
`;
