import { createSlice } from "@reduxjs/toolkit";
import { LabelActions } from "./chatlabel.actions";

interface initialStateTypes {
    data: {
        allLabelListingData: any;
        getLabelData: any;
        updateLabelData: any;
        deletedLabelData: any;
        allLabelListingCount: any;
    };
    error: string;
    loading: boolean;
}

const initialState: initialStateTypes = {
    data: {
        allLabelListingData: null,
        getLabelData: null,
        updateLabelData: null,
        deletedLabelData: null,
        allLabelListingCount: null,
    },
    error: "",
    loading: false,
};

const LabelSlice = createSlice(
    {
        name: "LabelSlice",
        initialState,
        reducers: {},
        extraReducers: (builder) => {
            builder

                // Listing Of labels
                .addCase(
                    LabelActions.getListingOfLabels.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    LabelActions.getListingOfLabels.fulfilled,
                    (state, action) => {
                        state.data.allLabelListingData = action?.payload?.data?.data?.data || []
                        state.data.allLabelListingCount = action.payload?.data?.data?.count || 0;
                    }
                )
                .addCase(
                    LabelActions.getListingOfLabels.rejected,
                    (state, action) => {
                        state.loading = false
                        state.data.allLabelListingData = null
                        state.error = action?.error?.message || ""
                    }
                )

                //  Get Signle Label Data
                .addCase(LabelActions.getLabelById.pending, (state, action) => {
                    state.error = "";
                    state.loading = true;
                })
                .addCase(LabelActions.getLabelById.fulfilled, (state, action) => {
                    state.data.getLabelData = action.payload?.data?.data || [];
                    state.error = "";
                    state.loading = false;
                })
                .addCase(LabelActions.getLabelById.rejected, (state, action) => {
                    state.loading = false;
                    state.data.getLabelData = null;
                    state.error = action?.error?.message || "";
                })



                //* Update Canned Message
                .addCase(LabelActions.updateLabelById.pending, (state, action) => {
                    state.error = "";
                    state.loading = true;
                })
                .addCase(LabelActions.updateLabelById.fulfilled, (state, action) => {
                    state.data.updateLabelData = action?.payload?.data?.response;
                    state.error = "";
                    state.loading = false;
                })
                .addCase(LabelActions.updateLabelById.rejected, (state, action) => {
                    state.loading = false;
                    state.data.updateLabelData = null;
                    state.error = action?.error?.message || "";
                })


                //* Delete Canned Message
                .addCase(LabelActions.deleteLabelById.pending, (state, action) => {
                    state.error = "";
                    state.loading = true;
                })
                .addCase(LabelActions.deleteLabelById.fulfilled, (state, action) => {
                    state.data.deletedLabelData = action?.payload?.data?.response;
                    state.error = "";
                    state.loading = false;
                })
                .addCase(LabelActions.deleteLabelById.rejected, (state, action) => {
                    state.loading = false;
                    state.data.deletedLabelData = null;
                    state.error = action?.error?.message || "";
                })

        }
    }
)

export default LabelSlice.reducer;