import { Paper } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const LabelWrapper = styled.div`
    width: 100%;
    // height: 100%;
`;

export const LabelTable = styled.div`
    height: 100%;
    .table-header {
        display: flex;
        padding: 1rem;
        border-bottom: 1px solid ${theme.palette.default.grey};
        .heading-text {
            min-width: 280px;
            h4 {
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }

    .table-header > :nth-child(2) {
        min-width: 150px;
    }

    .table-data {
        height: calc(100% - 54px);
        overflow: auto;
    }

    .table-row__content {
        border-bottom:1px solid rgba(203, 205, 211, 0.3);;
    }

    .table-content {
        display: flex;
        padding: 1rem;
        .table-content__text {
            display: flex;
            align-items: center;
             h4 {
                font-weight: 500;
                font-size: 0.875rem;
             }
             p {
                color: ${theme.palette.default.darkGrey};
                font-size: 0.875rem;
             }
            .table-actions {

            }

            .color {
                margin-right: 0.5rem;
                width: 15px;
                height: 15px;
                border-radius: 50%;
            }
            
        }
    }
    
    .table-content > :first-child {
        min-width: 280px;
    }
    .table-content > :last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        min-width: 120px;
    }
    .table-content > :nth-child(2) {
        min-width: 150px;

    }
    .table-content > :nth-child(3) {
        width: calc(100% - (360px + 80px));
    }
`

export const LabelContainer = styled(Paper)`
    // width: 100%;
    height: calc(100% - (60px + 52px));
    margin-right: 1rem;
    margin-left: 1rem;
`

export const AddLabel = styled.div`
& {
    height: 100%;
    .header {
        padding: 1rem 1.1rem;
        display: flex;
        justify-content: space-between;
        overflow-x: visible;
        align-items: center;
        border-bottom:1px solid rgba(203, 205, 211, 0.3);
        h4 {
            font-size: 1.1rem;
                font-weight: 500;
        }
        p {
            font-size: 0.75rem;
            color: ${theme.palette.default.darkGrey};
        }
    }
    .body {
        padding: 1.1rem;
        height: calc(100% - 77px);
        form {
            height: 100%;
            justify-content: space-between;
        }
        textarea {
            height: 140px;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
}
`

export const DeleteLabel = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0.875rem;
        border-bottom:1px solid rgba(203, 205, 211, 0.3);
        .header-left {
            display: flex;
            gap: 0.875rem;
            .danger {
                padding-top: 4px;
                img {
                    width: 1.2rem;
                }
            }
            .text h4 {
                font-size: 1.1rem;
                font-weight: 500;
            }
            .text p {
                color: ${theme.palette.default.darkGrey};
            }
        }
        .header-right {
            .MuiSvgIcon-root {
                color: ${theme.palette.default.darkGrey};
            }
            cursor: pointer;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        padding: 0.875rem;
    }
`
