import { styled } from "@mui/material/styles";
import {
  ColumnResizeMode,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import React, { useEffect, useState } from "react";
import { theme } from "../Customization/Theme";
import DefaultSortPng from "../assets/images/icons/default-table-sort.png";
import { ReactComponent as DescArrow } from "../assets/images/icons/sort-asc-arrow.svg";
import { ReactComponent as AscArrow } from "../assets/images/icons/sort-desc-arrow.svg";
import AppButton from "./AppButton";
import SkeletonLoader from "./Loader/SkeletonLoader";

interface AppCustomTableProps {
  data?: any[];
  dynamicsColumns?: any[];
  selectedRow?: boolean[] | any[];
  setSelectedRow?: (row: any) => void;
  getSortData?: any;
  loader?: boolean;
  removeContactDetailsLink?: boolean;
  sortingApply?: boolean;
  type?: string;
  tableHeight?: any;
  hideSelection?: boolean;
}

const AppCustomTable: React.FC<AppCustomTableProps> = React.memo(
  (props: any) => {
    const {
      data,
      dynamicsColumns,
      setSelectedRow,
      selectedRow,
      getSortData,
      loader,
      removeContactDetailsLink,
      sortingApply,
      type,
      tableHeight,
      hideSelection
    } = props;
    const [rowSelection, setRowSelection] = useState({});
    const [columns, setColumns] = useState<any>(() => [
      ...dynamicsColumns,
    ]);

    const [columnResizeMode] = useState<ColumnResizeMode>("onChange");
    const [sorting, setSorting] = useState("desc");
    const [shouldApply, setShouldApply] = useState(false);
    const [selectedField, setField] = useState("created_time");
    const [heightTable, setHeightTable] = useState(null)
    useEffect(() => {
      if (tableHeight) {
        setHeightTable(tableHeight)
      }
    }, [tableHeight])
    // clear selection
    useEffect(() => {
      if (!selectedRow && selectedRow === false) {
        setRowSelection({});
        setSelectedRow([]);
      }
    }, [selectedRow]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
      if (sorting?.length && getSortData && shouldApply) {
        getSortData([
          { id: selectedField, desc: sorting === "desc" ? true : false },
        ]);
        setShouldApply(false);
      }
    }, [sorting]); // eslint-disable-line react-hooks/exhaustive-deps
    // render columns
    useEffect(() => {
      setColumns(dynamicsColumns);
    }, [dynamicsColumns]);

    let selectArray: any[] = [];

    // get select ids
    useEffect(() => {
      const hasData = Object?.keys(rowSelection);
      if (hasData) {
        hasData.map((number) => {
          const selected = data[parseInt(number)];
          selectArray.push(selected?.id);
          return selected;
        });
      }
      setSelectedRow && setSelectedRow(selectArray);
    }, [rowSelection]); // eslint-disable-line react-hooks/exhaustive-deps
    const handleSort = (sort: any, id: string) => {
      if (sorting === "asc" && sort !== "false") {
        setSorting("desc");
        setField(id);
      } else {
        setSorting("asc");
        setField(id);
      }
      setShouldApply(true);

      // if (sort()?.[0]?.desc === false) {
      //   setSorting([{ id: "created_time", desc: true }]);
      // } else {
      //   setSorting([{ id: "created_time", desc: false }]);
      // }
    };

    //table hooks
    const table = useReactTable({
      data,
      columns,
      columnResizeMode,
      getCoreRowModel: getCoreRowModel(),
      debugTable: false,
      debugHeaders: false,
      debugColumns: false,
      onRowSelectionChange: setRowSelection,
      // manualSorting: true,
      state: {
        rowSelection,
      },
      getSortedRowModel: getSortedRowModel(),
      // onSortingChange: handleSort,
      enableRowSelection: true,
      // enableSorting: !removeContactDetailsLink,
      enableSorting: false,
      //to hide selection checkbox pass hideSelection prop as true
      initialState: {
        columnVisibility: {
          "select": hideSelection ? false : true
        }
      }

    });

    return (
      <TableWrapper tableHeight={heightTable} className="customTable">
        <div className="p-2">
          <div className="h-4" />
          <div className="overflow-x-auto">
            {!loader && data?.length ? (
              <table
                {...{
                  style: {
                    width: table.getCenterTotalSize(),
                    minWidth: "100%",
                  },
                }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "#FFF",
                    zIndex: 1,
                  }}
                >
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          {...{
                            key: header.id,
                            colSpan: header.colSpan,
                            onClick:
                              header.id !== "select"
                                ? header.column.getToggleSortingHandler()
                                : () => { },
                            onMouseDown: header.getResizeHandler(),
                            onTouchStart: header.getResizeHandler(),
                            style: {
                              maxWidth: header.getSize(),
                              padding: "0.5rem",
                              fontStyle: "12px",
                            },
                          }}
                        >
                          {header.isPlaceholder ? null : (
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                gap: "10px",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "100%",
                                  minWidth: "50px",
                                }}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext(),
                                )}
                              </div>

                              {!removeContactDetailsLink &&
                                header.id !== "select" && (
                                  <>
                                    {header.id === selectedField &&
                                      sorting === "asc" ? (
                                      <AppButton
                                        variant="text"
                                        onClick={() => {
                                          sortingApply &&
                                            handleSort("asc", header.id);
                                        }}
                                        buttonStyle={{
                                          padding: 0,
                                          height: "auto",
                                          minHeight: "10px",
                                        }}
                                      >
                                        <AscArrow
                                          style={{
                                            width: "10px",
                                          }}
                                        />
                                      </AppButton>
                                    ) : header.id === selectedField &&
                                      sorting === "desc" ? (
                                      <AppButton
                                        variant="text"
                                        onClick={() => {
                                          sortingApply &&
                                            handleSort("desc", header.id);
                                        }}
                                        buttonStyle={{
                                          padding: 0,
                                          height: "auto",
                                          minHeight: "10px",
                                        }}
                                      >
                                        <DescArrow style={{ width: "10px" }} />
                                      </AppButton>
                                    ) : (
                                      <AppButton
                                        variant="text"
                                        onClick={() => {
                                          sortingApply &&
                                            handleSort("false", header.id);
                                        }}
                                        buttonStyle={{
                                          padding: 0,
                                          height: "auto",
                                          minHeight: "10px",
                                        }}
                                      >
                                        <img
                                          src={DefaultSortPng}
                                          style={{
                                            width: "15px",
                                            opacity: 0.3,
                                          }}
                                          alt="sort"
                                        />
                                      </AppButton>
                                    )}
                                  </>
                                )}
                            </div>
                          )}
                          {header.id !== "select" && (
                            <div
                              {...{
                                onMouseDown: header.getResizeHandler(),
                                onTouchStart: header.getResizeHandler(),
                                className: `resizer ${header.column.getIsResizing()
                                  ? "isResizing"
                                  : "cursor-pointer select-none"
                                  }
                             `,
                              }}
                            ></div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table?.getRowModel()?.rows?.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          className="table-data"
                          {...{
                            key: cell.id,
                            style: {
                              width: cell.column.getSize(),
                              fontStyle: "12px",
                              padding: `${type === "biggerRows" ? "0.7rem" : "0.5rem"
                                }`,
                            },
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="">
                <SkeletonLoader numberOfCol={4} numberofRow={10} />
              </div>
            )}
          </div>
        </div>
      </TableWrapper>
    );
  },
);

export default AppCustomTable;

export const TableWrapper = styled("div") <AppCustomTableProps>`
  & {
    height: ${(props: any) => props?.tableHeight ? props?.tableHeight : "calc(100vh - 240px)"};
    // height: calc(100vh - 240px); /* Set your desired height */
    overflow: auto;
    background: ${theme.palette.default.white};
    * {
      box-sizing: border-box;
    }
    .long-word {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 100px;
      max-width: 250px;
      color: ${theme.palette.default.black};
      &.overflow-visible {
        overflow: visible;
      }
    }
    html {
      /* font-family: sans-serif; */
      font-size: 14px;
    }

    table {
      border: none;
      box-shadow: none;
      position: relative;
      tr {
        th {
          box-shadow: none;
          border-bottom: 1px solid lightgray;
        }
      }
    }
    .table-container {
      overflow: auto;
    }

    table,
    .divTable {
      /* border: 1px solid lightgray; */

      /* width: fit-content; */
    }

    .tr {
      display: flex;
    }

    tr,
    .tr {
      width: fit-content;
      height: 30px;
    }

    th,
    td {
      /* box-shadow: 0 0 0 1px lightgray; */
      padding: 0.25rem;
      font-size: 12px;
      * {
        font-size: 12px;
      }
    }

    th,
    .th {
      padding: 1rem;
      position: relative;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
      width: 100px;
      * {
      }
    }

    td,
    .td {
      width: 100px;
      text-align: left;
    }

    .table-data {
      font-style: 14px !important;
      color: #7e8392 !important;
      line-height: 21px;
      font-style: normal !important;
      // border: none !important;
      /* border: 1px solid red !important; */
    }
    .sc-gCkVGe kePTvV {
      overflow: auto;
    }
    .borderless-table {
      border: none;
    }
    .resizer {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 3px;
      background: #cbcdd3;
      cursor: col-resize;
      user-select: none;
      touch-action: none;
      /* margin: 5px; */
    }

    .resizer.isResizing {
      background: #cbcdd3;
      opacity: 1;
    }

    @media (hover: hover) {
      .resizer {
        opacity: 0;
      }

      *:hover > .resizer {
        opacity: 1;
      }
    }
  }
`;
