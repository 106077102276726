import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import AppButton from '../../../../../../AppComponents/AppButton';
import { AppErrorMessage, AppFormField, AppFormMobileField, AppFormSelectField } from '../../../../../../AppComponents/Forms';
import AppUploadCardHeaderField from '../../../../../../AppComponents/Forms/AppUploadCardHeaderField';
import { ChatbotConsoleService } from '../../../../../../Services/Apis/ChatbotConsoleService';
import WhatsappTextField from '../WhatsappTextField';
import { whatsappCtaValueAction } from '../../../Utils/Constants';
import { Divider, IconButton } from '@mui/material';
import { AppMaterialIcons } from '../../../../../../AppComponents/AppMaterialIcons';
import AppReactSelectField from '../../../../../../AppComponents/Forms/AppFormReactSelect';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { theme } from '../../../../../../Customization/Theme';

interface CarouselFieldArrayProps {
    disabled: boolean
}
const CarouselFieldArray = (props: CarouselFieldArrayProps) => {
    const { disabled } = props;
    const { values, errors, isValid, touched, setFieldValue } = useFormikContext<any>();
    const name = "carousel";
    const childsName = "carousel_buttons"
    // Store the previous length of carousel_buttons to determine add/delete
    const [previousButtonLength, setPreviousButtonLength] = useState(values?.carousel?.[0]?.carousel_buttons?.length || 0);
    //methos to add carousel card with buttons of the first indices
    const handleCarouselAddButtonClick = (push: any) => {
        const baseButtons = values?.carousel?.[0]?.carousel_buttons || [];
        const newButtonArray = baseButtons?.map((button: any) => {
            const newButton: any = {};
            Object.keys(button).forEach(key => {
                if (key === 'sub_type' || key === 'buttons_type') {
                    newButton[key] = button[key];
                } else {
                    newButton[key] = "";
                }
            });
            return newButton;
        });

        const newButton = {
            carousel_buttons: newButtonArray,
            button_text: "",
            text: "",
            card_header: ""
        };
        push(newButton);
    }

    //useEffect to add/update buttons to the new carousel card according to the first carousel card
    useEffect(() => {
        if (values?.carousel?.[0]?.carousel_buttons?.length >= 0) {
            const baseButtons = values?.carousel[0]?.carousel_buttons;
            // If adding a new button (i.e., the length increased)
            if (baseButtons.length > previousButtonLength) {
                const newButton: any = {};
                Object.keys(baseButtons[0] || {}).forEach(key => {
                    newButton[key] = "";
                });
                const updatedCarousel = values?.carousel?.map((item: any, index: number) => {
                    return { ...item, carousel_buttons: baseButtons };
                });
                setFieldValue('carousel', updatedCarousel);
            }
            // If deleting a button (i.e., the length decreased)
            else if (baseButtons.length < previousButtonLength) {
                const updatedCarousel = values?.carousel?.map((item: any, index: number) => {
                    if (index !== 0) {
                        return {
                            ...item, carousel_buttons: baseButtons.map((button: any, i: number) => {
                                return { ...item.carousel_buttons[i], ...button };
                            })
                        };
                    }
                    return item;
                });
                setFieldValue('carousel', updatedCarousel);
            }
            // Update the previousButtonLength to the current length
            setPreviousButtonLength(baseButtons.length);
        }
    }, [values?.carousel?.[0]?.carousel_buttons?.length]); // eslint-disable-line
    const onDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(values.carousel);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFieldValue(name, items);
    };
    return (
        <Container>
            <p className='heading'>Carousel</p>
            <p className='sub_heading'>Create buttons that let customers respond to your message or take action.</p>
            <p className='info'>The total number of carousel cards must not exceed 10.</p>
            <FieldArray name={name}>
                {({ remove, push }) => (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="carousel">
                            {(provided) => (
                                <InnerContainer
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {values &&
                                        name &&
                                        getIn(values, name)?.length > 0 &&
                                        getIn(values, name)?.map((item: any, index: number) => {
                                            const carouselFieldName: string = `${name}.${index}.card_header`;
                                            const carouselFieldType: string = `${name}.${index}.card_header_type`;
                                            const textField: string = `${name}.${index}.text`;
                                            const carouselDynamicVariableField = `${name}.${index}.carousel_example`;
                                            return (
                                                <Draggable key={index.toString()} draggableId={index.toString()} index={index}
                                                    isDragDisabled={disabled || getIn(values, name)?.length === 1}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <React.Fragment>
                                                                <CarouselCardHeader key={index.toString()}>
                                                                    <CarouselHeaderBodyContainer>
                                                                        <AppMaterialIcons iconName={"DragIndicator"}
                                                                            style={{ opacity: disabled ? "0.5" : 1, pointerEvents: disabled ? "none" : "auto" }}
                                                                        />
                                                                        <AppUploadCardHeaderField
                                                                            name={carouselFieldName}
                                                                            typeName={carouselFieldType}
                                                                            label='Upload Card Header'
                                                                            disabled={disabled}
                                                                            fileUpload={ChatbotConsoleService.uploadWhatsappMedia}
                                                                        />
                                                                        <WhatsappTextField
                                                                            name={textField}
                                                                            label={`Carousel Body`}
                                                                            placeholder="Write the text for the card body"
                                                                            charactersLimit={160}
                                                                            variablesKey={carouselDynamicVariableField}
                                                                            variablesButtonLimit={10}
                                                                            endVariableRequired={true}
                                                                            disabled={disabled}
                                                                            removeSpaceBeforAfterVariable={true}
                                                                        />

                                                                        <IconButton
                                                                            onClick={() => remove(index)}
                                                                            disabled={index === 0 || disabled}
                                                                        >
                                                                            <AppMaterialIcons iconName={"Delete"} />
                                                                        </IconButton>
                                                                    </CarouselHeaderBodyContainer>
                                                                </CarouselCardHeader>
                                                                {/* Resolve Variables FieldArray */}
                                                                <FieldArray name={name}>
                                                                    {() => (
                                                                        <React.Fragment>
                                                                            {values &&
                                                                                name &&
                                                                                getIn(values, `carousel.${index}.carousel_example`)?.length > 0 &&
                                                                                getIn(values, `carousel.${index}.carousel_example`)?.map((item: any, variableIndex: number) => {
                                                                                    return (
                                                                                        <CarouselVariableContainer>
                                                                                            <p>{`{{${variableIndex + 1}}}`} </p>
                                                                                            <AppFormField
                                                                                                name={`carousel.${index}.carousel_example.${variableIndex}.resolve`}
                                                                                                disabled={disabled}
                                                                                                placeholder="Resolve Body Variable *"
                                                                                                charactersLimit={25} />
                                                                                        </CarouselVariableContainer>
                                                                                    )
                                                                                })}
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>
                                                                {/* Card Button FieldArray */}
                                                                <FieldArray name={`${name}.${index}.${childsName}`}>
                                                                    {({ remove }) => (
                                                                        <React.Fragment>
                                                                            {values &&
                                                                                childsName &&
                                                                                getIn(values, `${name}.${index}.${childsName}`)?.length > 0 &&
                                                                                getIn(values, `${name}.${index}.${childsName}`)?.map((item: any, childIndex: number) => {
                                                                                    const dynamic_variables_for_url = `${name}.${index}.${childsName}.${childIndex}.dyanmic_variable_for_url_mapping`;
                                                                                    const phone_number = `${name}.${index}.${childsName}.${childIndex}.phone_number`;
                                                                                    const phone_number_text = `${name}.${index}.${childsName}.${childIndex}.phone_number_text`;
                                                                                    return (
                                                                                        <React.Fragment>
                                                                                            {item?.buttons_type === "QUICK_REPLY" ? (
                                                                                                <QuickRepliesContainer>
                                                                                                    <AppFormField name={`${name}.${index}.${childsName}.${childIndex}.quick_reply`}
                                                                                                        placeholder="Quick Replies"
                                                                                                        disabled={disabled}
                                                                                                        charactersLimit={200} />

                                                                                                    <IconButton
                                                                                                        onClick={() => remove(childIndex)}
                                                                                                        disabled={index !== 0 || disabled}
                                                                                                    >
                                                                                                        <AppMaterialIcons iconName={"Delete"} />
                                                                                                    </IconButton>
                                                                                                </QuickRepliesContainer>
                                                                                            ) : item?.buttons_type === "PHONE_NUMBER" ? (
                                                                                                <PhoneNumberContainer>
                                                                                                    <AppFormMobileField name={phone_number} label="" mobileCountryFormat="" disabled={disabled} />
                                                                                                    <AppFormField name={phone_number_text} disabled={disabled} placeholder="Enter text for the button" charactersLimit={25} />
                                                                                                    <IconButton
                                                                                                        onClick={() => remove(childIndex)}
                                                                                                        disabled={index !== 0 || disabled}
                                                                                                    >
                                                                                                        <AppMaterialIcons iconName={"Delete"} />
                                                                                                    </IconButton>
                                                                                                </PhoneNumberContainer>
                                                                                            ) : <React.Fragment>
                                                                                                <UrlTypeSelectionContainer>
                                                                                                    <AppFormSelectField
                                                                                                        label=""
                                                                                                        Options={whatsappCtaValueAction}
                                                                                                        name={`${name}.${index}.${childsName}.${childIndex}.sub_type`}
                                                                                                        disabled={disabled}
                                                                                                        placeholder="Select URL type"
                                                                                                        divStyle={{ width: "200px" }}
                                                                                                    />

                                                                                                    {values?.[name]?.[index]?.[childsName]?.[childIndex]?.sub_type === "dynamic" ?
                                                                                                        <WhatsappTextField
                                                                                                            name={`${name}.${index}.${childsName}.${childIndex}.url`}
                                                                                                            label={``}
                                                                                                            placeholder="Enter url, example: https://www.geta.ai/{{1}}"
                                                                                                            charactersLimit={2000}
                                                                                                            variablesKey={dynamic_variables_for_url}
                                                                                                            variablesButtonLimit={1}
                                                                                                            endVariableRequired={true}
                                                                                                            disabled={disabled}
                                                                                                            removeSpaceBeforAfterVariable={true}
                                                                                                        />
                                                                                                        :
                                                                                                        <AppFormField
                                                                                                            name={`${name}.${index}.${childsName}.${childIndex}.url`}
                                                                                                            placeholder="Enter url, example: https://www.geta.ai/"
                                                                                                            charactersLimit={2000}
                                                                                                            disabled={disabled}
                                                                                                            id={
                                                                                                                getIn(values, name)?.length > 1 &&
                                                                                                                    index + 1 === getIn(values, name)?.length
                                                                                                                    ? "add_button"
                                                                                                                    : ""
                                                                                                            }
                                                                                                        />
                                                                                                    }
                                                                                                </UrlTypeSelectionContainer>
                                                                                                <ButtonTextContainer>
                                                                                                    <AppFormField
                                                                                                        name={`${name}.${index}.${childsName}.${childIndex}.text`}
                                                                                                        placeholder="Enter text for the button"
                                                                                                        charactersLimit={25}
                                                                                                        disabled={disabled}
                                                                                                        id={
                                                                                                            getIn(values, name)?.length > 1 &&
                                                                                                                index + 1 === getIn(values, name)?.length
                                                                                                                ? "add_button"
                                                                                                                : ""
                                                                                                        }
                                                                                                    />
                                                                                                    {/* Remove */}
                                                                                                    <IconButton
                                                                                                        onClick={() => remove(childIndex)}
                                                                                                        disabled={index !== 0 || disabled}
                                                                                                    >
                                                                                                        <AppMaterialIcons iconName={"Delete"} />
                                                                                                    </IconButton>
                                                                                                </ButtonTextContainer>
                                                                                                <div>
                                                                                                    <FieldArray name={`${name}.${index}.${childsName}`}>
                                                                                                        {() => (
                                                                                                            <React.Fragment>
                                                                                                                {values &&
                                                                                                                    name &&
                                                                                                                    getIn(values, dynamic_variables_for_url)?.length > 0 &&
                                                                                                                    getIn(values, dynamic_variables_for_url)?.map((item: any, buttonIndex: number) => {
                                                                                                                        return (
                                                                                                                            <ButtonsVariableContainer>
                                                                                                                                <p>{`{{${buttonIndex + 1}}}`} </p>

                                                                                                                                <AppFormField
                                                                                                                                    name={`${dynamic_variables_for_url}.${buttonIndex}.resolve`}
                                                                                                                                    placeholder="Resolve Button Variable"
                                                                                                                                    disabled={disabled}
                                                                                                                                    charactersLimit={25} />
                                                                                                                            </ButtonsVariableContainer>
                                                                                                                        )
                                                                                                                    })}
                                                                                                            </React.Fragment>
                                                                                                        )}
                                                                                                    </FieldArray>
                                                                                                </div>
                                                                                            </React.Fragment>}
                                                                                        </React.Fragment>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </React.Fragment>
                                                                    )}
                                                                </FieldArray>

                                                                < AppErrorMessage
                                                                    error={getIn(errors, name)?.[index]?.name}
                                                                    visible={getIn(touched, name)}
                                                                    index={index}
                                                                />
                                                                <AppReactSelectField
                                                                    name={`${name}.${index}.sub_type`}
                                                                    label="Add a Card Button"
                                                                    options={[
                                                                        { value: "CTA", label: "CTA" },
                                                                        { value: "QUICK_REPLY", label: "Quick Reply" },
                                                                        { value: "PHONE_NUMBER", label: "Phone Number" }
                                                                    ]}
                                                                    onChangeCallbackFn={(e) => {
                                                                        if (e) {
                                                                            const card_button_path = `${name}.${index}.${childsName}`;
                                                                            const currentChildren = getIn(values, card_button_path) || [];
                                                                            if (currentChildren?.length === 2) return;
                                                                            let newChildren = [...currentChildren, { buttons_type: e?.value }];
                                                                            if (e?.value === "CTA")
                                                                                newChildren = [...currentChildren, { sub_type: "static" }]
                                                                            setFieldValue(card_button_path, newChildren);
                                                                            setFieldValue(`${name}.${index}.sub_type`, "");
                                                                            return;
                                                                        }
                                                                    }}
                                                                    isDisabled={getIn(values, `${name}.${index}.${childsName}`)?.length === 2 ? true : false || index !== 0 || disabled}
                                                                    maxWidth='200px'
                                                                    divStyle={{ paddingBottom: "2rem", paddingTop: "2rem" }}
                                                                />
                                                                <AppErrorMessage
                                                                    error={!Array.isArray(getIn(errors, `${name}.${index}.${childsName}`)) && getIn(errors, `${name}.${index}.${childsName}`)}
                                                                    visible={true}
                                                                />
                                                                <Divider className='divider' />
                                                            </React.Fragment>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    {provided.placeholder}

                                    {/* Add new field at last position */}
                                    <AppButton
                                        variant="text"
                                        onClick={() => handleCarouselAddButtonClick(push)}
                                        style={{
                                            marginTop: "0.5rem",
                                            minWidth: "170px",
                                            width: "170px",
                                            border: "1px solid rgba(91, 115, 232, 1)",
                                            borderRadius: "6px",
                                        }}
                                        startIcon={"ri-add-line"}
                                        isDisabled={
                                            (getIn(values, name)?.length === 10 ? true : false) || !isValid || disabled
                                        }
                                        id="add_button"
                                    >
                                        Add Another Carousel Card
                                    </AppButton>

                                </InnerContainer>
                            )}
                        </Droppable>
                    </DragDropContext>
                )}
            </FieldArray>
        </Container >
    )
}

export default CarouselFieldArray;

const Container = styled.div`
&&{
    display:flex;
    flex-direction:column;
    justify-content:start;
    background-color:${theme.palette.default.white};
    .heading{
        font-size: 15px;
        display: block;
    }
    .sub_heading{
        font-size: 15px;
        font-weight: 400;
        color: rgba(126, 131, 146, 1);
        }
    .info{
        font-size: 14px;
        color: rgba(126, 131, 146, 1);
        margin-top: 1rem;
        padding: 10px;
        background-color: #f7f7f7;
        border: 1px dashed #e0e0e0;
        margin-bottom: 10px;
    }
}`

const InnerContainer = styled.div`
&&{
.divider{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 2px solid rgba(126, 131, 146, 0.2);
    }
}`

const CarouselHeaderBodyContainer = styled.div`
&&{ 
    display:flex;
    align-items:center;
    gap: 1rem;
}`

const UrlTypeSelectionContainer = styled.div`
&&{
    width:100%;
    display:flex;
    gap:1rem;
    padding:0.5rem 0;
}`

const ButtonTextContainer = styled.div`
&&{
    display:flex;
    align-items:start;
    margin-top:-1rem;
}`

const QuickRepliesContainer = styled.div`
&&{
    display:flex;
    gap:1rem;
    align-items:start;
    padding:0.5rem 0;
}`

const CarouselVariableContainer = styled.div`
&&{
    display:flex;
    gap:1rem;
    align-items:start;
    width:70%;
    p{
        margin-top:0.8rem;
        font-size:14px;
        font-weight:500;
    }
}`

const CarouselCardHeader = styled.div`
&&{
    padding:1rem 0;
}`

const ButtonsVariableContainer = styled.div`
&&{
    display:flex;
    gap:1rem;
    align-items:start;
    width:70%;
        p{
        margin-top:0.8rem;
        font-size:14px;
        font-weight:500;
    }
}`

const PhoneNumberContainer = styled.div`
&&{
  display:flex;
  align-items:start;
  gap:1rem;
  padding:0.5rem 0;
}`    