// vendors
import React, { Fragment } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListSubheader from "@mui/material/ListSubheader";
import Grid from "@mui/material/Grid";

// components
import InputOption from "../InputOption";

type Option = {
  category: string;
  color: string;
  icon: string;
  name: string;
  type: string;
  displayComponent: any;
  childNode: any;
};

interface InputOptionListProps {
  list: Array<{
    name: string;
    children: Array<Option>;
  }>;
  onOptionClick: (option: Option) => void;
}

const InputOptionList = (props: InputOptionListProps) => {
  const { list, onOptionClick } = props;
  return (
    <List sx={{ maxWidth: "380px" }}>
      {list.map((listItem, index) => (
        <Fragment key={`input-option-${index}`}>
          {listItem.name && listItem.name !== "null" && (
            <ListSubheader component="div" id="nested-list-subheader">
              {listItem.name}
            </ListSubheader>
          )}
          <Grid container spacing={0} columns={2}>
            {listItem?.children?.map((option, optionIndex) => (
              <Grid item xs={1} key={`item=${optionIndex + 1}`}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: "16px !important" }}
                    onClick={() => onOptionClick(option)}
                  >
                    <InputOption
                      label={option.name}
                      icon={option.icon}
                      color={option.color}
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </Fragment>
      ))}
    </List>
  );
};

export default React.memo(InputOptionList);
