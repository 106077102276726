import { IconButton } from "@mui/material";
import { EditorState, RichUtils } from "draft-js";
import React from "react";
import styled from "styled-components";

import { theme } from "../../../../../Customization/Theme";
import { AppMaterialIcons } from "../../../Icons";
import EditorEmojiPicker from "./EditorEmojiPicker";
import EditorLinkAttach from "./EditorLinkAttach";
import EditorMediaUpload from "./EditorMediaUpload";
import { editorInlineStyles } from "./constants";
import { moveFocusToEnd } from "./utils";
import { ProtectedAppUiElement } from "../../../../../AppComponents/ProtectedComponents";

interface Props {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  handleEditorChange: (editorState: EditorState) => void;
  toolbarOptions: string[];
  focusEditor: () => void;
}

const EditorToolbar: React.FC<Props> = ({
  editorState,
  setEditorState,
  handleEditorChange,
  toolbarOptions,
  focusEditor,
}) => {
  const focusEditorFn = () => {
    const newState = moveFocusToEnd(editorState);
    setEditorState(newState);
    focusEditor();
  };

  const handleInlineStyle = (event: any, type: string) => {
    // event.preventDefault();

    if (type === "unordered-list-item" || type === "ordered-list-item") {
      setEditorState(RichUtils.toggleBlockType(editorState, type));
      return;
    }

    setEditorState(RichUtils.toggleInlineStyle(editorState, type));
  };

  const renderInlineStyleButton = (style: any, index: number) => {
    const currentInlineStyle = editorState.getCurrentInlineStyle();
    let className = "toolbar-button";
    if (currentInlineStyle.has(style.type)) {
      className = "toolbar-button-selected";
    }
    return (
      <IconButton
        key={index}
        className={className}
        title={style?.toolTip}
        onClick={(e: any) => {
          focusEditor && focusEditor();
          e.preventDefault();
        }}
        onMouseDown={(e: any) => {
          handleInlineStyle(e, style?.type);
          focusEditor && focusEditor();
        }}
      >
        <AppMaterialIcons iconName={style?.icon} style={{ fontSize: "1rem" }} />
      </IconButton>
    );
  };

  return (
    <StyledEditorToolbar id="editor-toolbar">
      {editorInlineStyles?.map((style: any, index: any) => {
        if (!toolbarOptions?.includes(style.type)) {
          return null;
        }

        if (style.type === "LINK") {
          return (
            <EditorLinkAttach
              editorState={editorState}
              handleEditorChange={handleEditorChange}
              key={index}
              icon={style?.icon}
              focusEditor={focusEditorFn}
            />
          );
        }
        if (style.type === "EMOJI") {
          return (
            <EditorEmojiPicker
              editorState={editorState}
              handleEditorChange={handleEditorChange}
              key={index}
              icon={style?.icon}
              focusEditor={focusEditorFn}
            />
          );
        }
        if (style.type === "MEDIA") {
          return (
            <ProtectedAppUiElement moduleId="send_files" key={index}>
            <EditorMediaUpload
              key={index}
              icon={style?.icon}
                focusEditor={focusEditorFn}
              />
            </ProtectedAppUiElement>
          );
        }
        return renderInlineStyleButton(style, index);
      })}
    </StyledEditorToolbar>
  );
};

export default EditorToolbar;

const StyledEditorToolbar = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0%;
    right: 0%;
    display: flex;
    align-items: center;
    width: 100%;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 0 8px;
    gap: 6px;
    .MuiSvgIcon-root {
      fill: #184c6a;
    }
    & > *:nth-child(8) {
      .MuiSvgIcon-root {
        fill: #ff8360;
      }
    }
  }
`;
