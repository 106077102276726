import { InputBase, InputBaseProps, styled } from "@mui/material";
import { theme } from "../../Customization/Theme";

interface Props extends InputBaseProps {
  [otherProps: string]: any;
}

//DO NOT ADD FORM HERE
const AppInput = ({ ...otherProps }: Props) => {
  return (
    <>
      <StyledInputBase {...otherProps} />
    </>
  );
};

export default AppInput;

const StyledInputBase = styled(InputBase)(() => ({
  width: "100%",
  borderRadius: "4px",
  "& .MuiInputBase-input": {
    width: "100%",
    height: "32px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "4px",
    outline: "none",
    padding: "0 12px 0 1rem",
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: "15px",
    lineHeight: "24px",
    position: "relative",
    border: "1px solid transparent",
    "&:focus": {
      border: `1px solid ${theme.palette.default.border}`,
      color: theme.palette.default.text,
    },
  },
}));
