export const DEVICE_SIZE = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

export const DEVICE_BREAKPOINT = {
  mobileS: `(min-width: ${DEVICE_SIZE.mobileS})`,
  mobileM: `(min-width: ${DEVICE_SIZE.mobileM})`,
  mobileL: `(max-width: ${DEVICE_SIZE.mobileL})`,
  tablet: `(max-width: ${DEVICE_SIZE.tablet})`,
  laptop: `(max-width: ${DEVICE_SIZE.laptop})`,
  laptopL: `(max-width: ${DEVICE_SIZE.laptopL})`,
  desktop: `(max-width: ${DEVICE_SIZE.desktop})`,
  desktopL: `(max-width: ${DEVICE_SIZE.desktop})`,
};

export const getDeviceSize = (size: any) => {
  if (size < DEVICE_SIZE.mobileS) {
    return DEVICE_SIZE.mobileS;
  }
  if (size < DEVICE_SIZE.mobileM) {
    return DEVICE_SIZE.mobileM;
  }
  if (size < DEVICE_SIZE.mobileL) {
    return DEVICE_SIZE.mobileL;
  }
  if (size < DEVICE_SIZE.tablet) {
    return DEVICE_SIZE.tablet;
  }
  if (size < DEVICE_SIZE.laptop) {
    return DEVICE_SIZE.laptop;
  }
  if (size < DEVICE_SIZE.laptopL) {
    return DEVICE_SIZE.laptopL;
  }
  return DEVICE_SIZE.desktop;
}
