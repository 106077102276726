import { Box, FormControlLabel, IconButton, Radio, RadioGroup } from "@mui/material";
import AppButton from "../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormField,
  AppFormSelectField,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import { AppToolTip } from "../../../../UiComponents/AppToolTip/AppToolTip";
import { AnsweredWrap } from "../../NotAnswered/FaqAnswered/AnsweredFaqStyle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ReactComponent as FailedIcon } from "../../../../../assets/images/close.svg";
import AppFormRichTextEditor from "../../../../../AppComponents/Forms/AppFormRichTextEditor";
import { useState } from "react";

interface Props {
  isShowingModalEdit: any;
  toggleModalEdit: any;
  onCloseResetValues: any;
  userInput: any;
  handleSubmitBtn: any;
  radioCheck: any;
  handleRadioChange: any;
  FlowData: any;
  FaqQuestion: any;
  setSelectedItem: any;
  selectedItem: any;
  setFaqQuestionId: any;
  faqQuestionId: any;
  type?: any;
}

const AnsNotAnsModal: React.FC<Props> = ({
  isShowingModalEdit,
  toggleModalEdit,
  onCloseResetValues,
  userInput,
  handleSubmitBtn,
  radioCheck,
  handleRadioChange,
  FlowData,
  FaqQuestion,
  setSelectedItem,
  selectedItem,
  setFaqQuestionId,
  faqQuestionId,
  type,
}) => {
  return (
    <>
      <AppModel
        isShowing={isShowingModalEdit}
        onClose={() => toggleModalEdit(true)}
        divStyle={{
          width: "931px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "0px",
          height: "90vh",
        }}
      >
        <AnsweredWrap>
          
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            className="app-form-container"
            divStyle={{
              gap: 0,
              height: "100%",
            }}
          >

            <div className="answered-popup">
              <div className="answered-heading">
                <p>{type} FAQ</p>
              </div>
              <div className="close-icon">
                <IconButton
                  onClick={() => {
                    toggleModalEdit();
                    onCloseResetValues();
                  }}
                >
                  <FailedIcon />
                </IconButton>
              </div>
            </div>

              <Box className="form-wrapper" sx={{height: "100%", overflow: "auto"}}>
            <AnsweredFaqForm
              radioCheck={radioCheck}
              handleRadioChange={handleRadioChange}
              FlowData={FlowData}
              FaqQuestion={FaqQuestion}
              toggleModalEdit={toggleModalEdit}
              setSelectedItem={setSelectedItem}
              setFaqQuestionId={setFaqQuestionId}
              selectedItem={selectedItem}
              />
              </Box>

            <div className="btn" style={{ height: "68px" }}>
              <AppButton
                variant="grey"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  toggleModalEdit();
                  onCloseResetValues();
                }}
              >
                cancel
              </AppButton>
              <AppSubmitButton
                title={faqQuestionId ? "Add variation" : "Add New"}
              >
                <AppMaterialIcons
                  iconName={faqQuestionId ? "ArrowForwardOutlined" : "Add"}
                />
              </AppSubmitButton>
            </div>
          </AppForm>
        </AnsweredWrap>
      </AppModel>
    </>
  );
};

export default AnsNotAnsModal;

const AnsweredFaqForm = (props: any) => {
  const {
    radioCheck,
    handleRadioChange,
    FlowData,
    FaqQuestion,
    setFaqQuestionId,
  } = props;
  return (
    <>
      <div className="body-popup">
        <div className="input-field">
          <div className="tooltip">
            <label>
              User query
              <AppToolTip
                tooltipProps={{
                  title: `Lorem ipsum`,
                  placement: "right",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={
                  <InfoOutlinedIcon style={{ fontSize: "16px" }} />
                }
              />
            </label>
          </div>

          <AppFormField
            disabled={true}
            label=""
            name="question"
            placeholder="Where is my order?"
          />
        </div>

        <div className="radio-button">
          <RadioGroup
            name="use-radio-group"
            value={radioCheck}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value="first"
              label="Add Response"
              control={<Radio />}
            />
            <FormControlLabel
              value="second"
              label="Link to Flow"
              control={<Radio />}
            />
          </RadioGroup>
        </div>

        {/* ----------------Link to flow (Radio Button) selectfield ------------------------- */}
        {radioCheck === "second" ? (
          <div className="select-field">
            <h3>Flow</h3>
            <AppFormSelectField
              name="journey"
              Options={FlowData || []}
              valueKey="qid"
              labelKey="name"
              divStyle={{
                width: "100%",
                minWidth: "886px",
                maxWidth: "886px",
              }}
              enableSearch
              popOverMaxWidth={"100%"}
            />
          </div>
        ) : (
          <div className="select-field">
            <h3>Triggered FAQ Question</h3>
            <AppFormSelectField
              name="faqQuestionId"
              Options={FaqQuestion || []}
              valueKey="qid"
              labelKey="name"
              divStyle={{
                width: "100%",
                minWidth: "886px",
                maxWidth: "886px",
              }}
              enableSearch
              popOverMaxWidth={"100%"}
              onChange={(e: any) => {
                setFaqQuestionId(e);
              }}
            />
            <AppFormRichTextEditor
              label=""
              placeholder="Start typing..."
              name="text"
              mediaKey="media"
            />
          </div>
        )}

        <div className="list">
          <h3>What is Add Variation and Add New Option?</h3>
          <ul>
            <li>
              Add Variation: Adding variation refers to the action of saving the
              current user query as a new variation to an existing Frequently
              Asked Question (FAQ) in the database.
            </li>
            <li>
              Add New : Adding new means creating a brand new Frequently Asked
              Question (FAQ) in the database for the current user query. Saving
              frequently asked questions reduces costs, as the chatbot relies
              less on Open AI services for future similar queries.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
