import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import { EditorState, Modifier } from "draft-js";
import EmojiPicker from "emoji-picker-react";
import { useState } from "react";
import { AppMaterialIcons } from "../../../Icons";

interface Props {
  editorState: EditorState;
  // setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  handleEditorChange: (editorState: EditorState) => void;
  icon?: string;
  focusEditor: () => void;
}

const EditorEmojiPicker: React.FC<Props> = ({
  editorState,
  handleEditorChange,
  focusEditor,
  icon = "AddReaction",
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleEmojiClick = (emoji: any) => {
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      emoji.emoji,
      editorState.getCurrentInlineStyle()
    );

    const selection = editorState.getSelection();

    const newEditorState = EditorState.push(
      editorState,
      contentState,
      "insert-characters"
    );

    // Set the cursor position to the end of the inserted emoji
    const newSelection = selection.merge({
      anchorOffset: selection.getAnchorOffset() + emoji.emoji.length,
      focusOffset: selection.getAnchorOffset() + emoji.emoji.length,
    });

    const newStateWithSelection = EditorState.forceSelection(
      newEditorState,
      newSelection
    );
    handleEditorChange(newStateWithSelection);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
        focusEditor();
       }, 1);
  };

  return (
    <Box
      sx={{
        position: "relative",
        "&:hover > div": {
          display: "flex",
          opacity: 1,
        },
      }}
    >
      <IconButton title={"emoji"} onClick={handleClick}>
        <AppMaterialIcons iconName={icon} style={{ fontSize: "1rem" }} />
      </IconButton>
      <Popover
        className="ignore-onClickOutside"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginLeft: "8px",
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
            padding: "8px 4px 8px 8px",
            maxHeight: "220px",
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            "& .EmojiPickerReact": {
              borderWidth: "0px !important",
              borderRadius: "0px !important",
            },
            "& .epr-category-nav": {
              display: "none",
              padding: "2px 2px 8px",
            },
            "& .epr-skin-tones": {
              display: "none",
            },
            "& .epr-emoji-category": {
              "& .epr-emoji-category-label": {
                display: "none !important",
              },
            },
            "& .epr-hidden-on-search": {
              display: "none",
            },
            "&. epr-emoji-category-content": {
              margin: "0 !important",
            },
            "& .epr-header-overlay": {
              padding: "0px !important",
              marginBottom: "8px",
            },
          }}
        >
          <EmojiPicker
            width={315}
            height={211}
            previewConfig={{ showPreview: false }}
            onEmojiClick={(e: any) => handleEmojiClick(e)}
          />
        </Box>
      </Popover>
    </Box>
  );
};
export default EditorEmojiPicker;
