import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useEffect, useState } from "react";
import {
  BotList,
  botDateRange,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { AvgDurationWrapper } from "./BotStyle";
import DurationLogo from "../Dash_Assests/totaltimesaved.svg";
import { format } from "date-fns";
import {
  SkeletonCard,
  SkeletonCircleIconCard,
} from "../SkeletonLoader.tsx/SkeletonCard";
import { theme } from "../../../Customization/Theme";
import styled from "styled-components";
import { TextTitle } from "../AnalyticsSummaryStyles";
import { formatTime, getRoundOffNumbers } from "../../../Utils";
//import AverageDuration from "./Dash_Assests/AverageDuration.svg";
import AverageDuration from "../Dash_Assests/AverageDuration.svg";

interface Props {}

const AvgDuration: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(botDateRange);
  const botList = useAppSelector(BotList);
  const [avg, setAvg] = useState<any>(0);
  const [botSavedTime, setBotSavedTime] = useState<any>(0);
  const [loading, setLoading] = useState(false);

  // Avg Duration API Call
  const getDuration = () => {
    setLoading(true);
    let payload3: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      filter_type: "bot",
    };
    if (botList.length > 0) {
      payload3 = {
        ...payload3,
        bot_id: botList,
      };
    }
    dispatch(DashboardActions.getDashDuration(payload3))
      .then((res) => {
        setAvg(res.payload.data.average || 0);
        setBotSavedTime(res.payload.data.time_saved_by_bot || 0);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (dashdateRange?.startDate) {
      getDuration();
    }
  }, [dashdateRange, botList]);

  return (
    <>
      <AvgDurationWrapper>
        <Header>
          <p className="time_stats">
            <TextTitle>Conversation Duration</TextTitle>
            <AppToolTip
              divStyle={{ padding: "0 8px" }}
              tooltipProps={{
                title: "The total number of messages for bot",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<InfoOutlinedIcon style={{ fontSize: "10px" }} />}
            />
          </p>
        </Header>
        <div className="avg_duration_box">
          <div className="b_div1">
            <p className="b_div1_header">
              Avg. Duration
              <AppToolTip
                tooltipProps={{
                  title: "Average conversation durations with the bot ",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
              />
            </p>
            <div className="b_div1_info">
              <p className="b_div1_info_count">
                {loading ? <SkeletonCard /> : formatTime(avg, "min") || 0}{" "}
                <span className="time">Min</span>
              </p>
            </div>
          </div>
          {/* <div className="parent-img">
        <img src={AverageDuration} alt="" className="img-bot"/>
      </div> */}
          <hr className="dotted_hr"></hr>
          <div className="b_div1">
            <p className="b_div1_header">
              Time Saved By Bot
              <AppToolTip
                tooltipProps={{
                  title: "The time saved due the bot",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
              />
            </p>
            <div style={{ display: "flex" }}>
              <div className="b_div1_info">
                <p className="b_div1_info_count">
                  {loading ? (
                    <SkeletonCard />
                  ) : (
                    formatTime(botSavedTime, "hrs") || 0
                  )}{" "}
                  <span className="time"> Hrs</span>
                </p>
              </div>

              {/* {loading ? (
          <SkeletonCircleIconCard />
        ) : (
          <div className="b_div2_info_icon">
            <img src={DurationLogo} alt="Not Found" />
          </div>
        )} */}
            </div>
          </div>
        </div>
      </AvgDurationWrapper>
    </>
  );
};
export default AvgDuration;

const Header = styled.div`
  && {
    .time_stats {
      display: flex;
      font-weight: 500;
      align-items: center;
      padding: 10px 24px 10px 10px;
      border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      .div_tooltip {
        font-size: 15px;
        margin-top: 0.094rem;
      }
    }
  }
`;
