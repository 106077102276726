import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { Toolbar } from "@mui/material";

export const ApplicationFormWrapper = styled.div`
  .topmenu {
    padding: 12px 15px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
  }
  .custom-btn {
    padding: 0;
  }
  .left-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .left-part {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
  }
  .menu-right {
    display: flex;
    align-items: center;
    gap:10px;
  }
  .data-container {
    background: ${theme.palette.default.white};
    margin: 1rem 1rem 0;
  }
  .image {
    padding-top: 24px;
    padding-left: 3rem;
  }
  .norecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    /* padding-top: 128px; */
  }
  .data-details {
    display: flex;
    /* align-items:center; */
    justify-content: center;
    padding-top: 120px;
    gap: 72px;
  }
  .data-details p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.darkGrey};
  }
  .Import-details {
    width: 640px;
  }
  .Import-details button {
    width: 148px;
  }
  .Import-details li {
    margin-bottom: 1rem;
  }
  .Import-details p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.black};
  }
  .button {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .norecord-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-top: 128px;
  }
  .norecord-data p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.default.darkGrey};
    text-transform: capitalize;
  }
  .add-list {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;
  }
  .add-list button {
    width: 148px;
  }
  .add-list p {
    width: 1115px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${theme.palette.default.black};
  }
`;
export const NewSelect = styled.div`
  display: flex;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .drop-down {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
export const TextWrap = styled.div`
  padding: 24px 16px;
  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: ${theme.palette.default.newTextBlack};
  }
`;
export const MarketingListImport = styled.div`
  padding-left: 16px;
  .import-records {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-right: 10px;
  }
  .classified-record-progress {
    width: 357px;
    height: 95px;
    padding: 24px;
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};
    border-bottom: 6px solid ${theme.palette.default.lightYellow};
  }
  .progress {
    display: flex;
    justify-content: space-between;
  }
  .classified-record-created {
    width: 357px;
    height: 95px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};
    border-bottom: 6px solid ${theme.palette.default.prettyGreen};
  }
  .classified-record-updated {
    width: 357px;
    height: 95px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};
    border-bottom: 6px solid ${theme.palette.default.cyanBlue};
  }
  .classified-record-skipped {
    width: 357px;
    height: 95px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};
    border-bottom: 6px solid ${theme.palette.default.pinkFlamingo};
  }
  .classified-record-errored {
    width: 357px;
    height: 95px;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};
    border-bottom: 6px solid ${theme.palette.default.redSalmon};
  }
  .record-details {
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.newTextGrey};
  }
  .linear_Progress {
    flex: 1;
    padding-top: 24px;
  }
  .record-percent {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.newTextGrey};
  }
  .record-count {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: ${theme.palette.default.black};
    padding-top: 8px;
  }
`;
export const CreateListWrap = styled.div`
  .popup-container {
    // height: 745px;
    // widht: "795px";
    overflow: auto;
  }
  &.automationWrapper {
    .listitem {
      padding-right: 30px;
      padding-left: 30px;
    }
    .input-div {
      padding-bottom: 0;
    }
  }

  .popup-container h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    /* padding-top: 2rem; */
  }
  .answered-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
    align-items: center;
    padding: 16px;
  }
  .answered-heading p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: ${theme.palette.default.NightRider};
  }
  .subscription label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.BlackOpacity};
  }
  .radio-btn-heading {
    padding-top: 1rem;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.newTextBlack};
  }
  .radiobutton-group {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-bottom: 2rem;
    padding-right: 24px;
    padding-top: 1rem;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
  .select-field {
    display: flex;
    padding-left: 24px;
    align-items: center;
    gap: 1rem;
    padding-right: 24px;
    padding-top: 1rem;
  }
  .select label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .label-styling {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.BlackOpacity};
  }
  .paragraph {
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.palette.default.newTextGrey};
  }
  .checkbox-div {
    padding-top: 1rem;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
  .radio-list {
    padding-left: 24px;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
    padding-bottom: 2rem;
    padding-right: 24px;
  }
  .radio-list p {
    padding-left: 2rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${theme.palette.default.newTextGrey};
  }
  .step {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: ${theme.palette.default.newTextGrey};
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .input-div {
    padding: 1rem;
  }
  .input-div label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .listitem {
    padding-left: 3rem;
    padding-right: 1rem;
  }
  .listitem li {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.palette.default.newTextGrey};
    margin-bottom: 1rem;
  }
  .btn {
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 16px;
    border-top: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
  .footer-warning {
    width: -webkit-fill-available;
    height: 24px;
    font-size: small;
    display: flex;
    align-items: end;
    gap: 10px;
  }
`;
export const AnsweredTable = styled.div``;
export const ImportListWrap = styled.div``;
export const MarketingCountList = styled.div`
  .list-status {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 1rem;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .contact-count {
    display: flex;
    align-items: center;
    gap: 72px;
    width: 600px;
    height: 105px;
    border-bottom: 6px solid ${theme.palette.default.lightRed};
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};

    .contact-count-right > li:nth-child(5) {
      flex-basis: 232px;
    }
  }
  .mobile-contact-count {
    display: flex;
    align-items: center;
    gap: 39px;
    width: 596px;
    height: 105px;
    border-bottom: 6px solid ${theme.palette.default.skyBlue};
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};
  }
  .email-contact-count {
    display: flex;
    align-items: center;
    gap: 39px;
    /* padding-left: 28px; */
    width: 600px;
    height: 105px;
    border-bottom: 6px solid ${theme.palette.default.brightLavender};
    box-shadow: ${theme.palette.default.borderShadow};
    background-color: ${theme.palette.default.white};
  }
  .contact-count-first {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    padding-left: 24px;
    width: 193px;
    position: relative;
  }

  .contact-count-first::after {
    content: "";
    display: block;
    border-right: 1px solid ${theme.palette.default.RightBorderDivder};
    height: 88px;
    position: absolute;
    top: -30px;
    left: 100%;
  }
  .contact-count-left {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    padding-left: 24px;
    width: 193px;
    position: relative;
  }

  .contact-count-left::after {
    content: "";
    display: block;
    border-right: 1px solid ${theme.palette.default.RightBorderDivder};
    height: 88px;
    position: absolute;
    top: -19px;
    left: 100%;
  }

  .contact-count-right {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
  }
  .contact-count-right li {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: ${theme.palette.default.black};
  }
`;

export const AnsweredFaqList = styled.div`
  height: calc(100vh - 380px);
  overflow: auto;
  .table-body {
    .col-body {
      border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    }
    .MuiCheckbox-root {
      padding: 0;
    }
  }

  .table-header {
    display: flex;
    align-items: baseline;
    flex: 1;
    .col-head {
      border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
      padding: 10px;
      background-color: #fff;
    }
  }
  .table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    .MuiCheckbox-root {
      padding: 0;
    }
  }
  .nameWidth {
    min-width: 200px;
  }
  .fixedWidth {
    min-width: 150px;
  }
  .table-row {
    display: flex;
    align-items: stretch;
  }
  .table-body a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: ${theme.palette.default.newTextGrey};
  }
  .avtar {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .fallback_ui {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;
    img {
      transform: scale(1.3);
    }
  }
  .button {
    margin-top: 50px;
  }
  .import {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    cursor: pointer;
    @media (max-width: 1600px) {
      svg {
        width: 20px;
      }
    }
  }
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .col-head,
  .col-body {
    padding: 5px 16px;
    color: ${theme.palette.default.newTextGrey};
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: center;
    :nth-child(1) {
      flex-basis: 250px;
    }

    :nth-child(2) {
      flex-basis: 244px;
    }
    :nth-child(5) {
      flex-basis: 232px;
    }

    :nth-child(n + 3) {
      flex: 1;
    }
    /* :nth-child(7) {
        display: flex;
        align-items: baseline;
        justify-content: end;
      } */
  }

  .col-head {
    font-size: 13px;
    line-height: 17px;
    color: ${theme.palette.default.black};
    border-right: 1px solid ${theme.palette.default.BorderColorGrey};
    padding: 5px 10px;

    :nth-child(2) {
      /* Apply styles for the body first child */
      flex-basis: 244px;
    }
    :nth-child(5) {
      flex-basis: 232px;
    }

    /* :nth-child(3) {
        flex-basis: 154px
      } */
    :nth-child(1) {
      flex-basis: 250px;
    }

    :nth-child(9) {
      border-right: none;
    }
    :nth-child(n + 3) {
      flex: 1; /* Allow columns from the third child to grow equally */
    }
    :last-child {
      border-right: none;
    }
  }
`;

export const UploadFile = styled.div`
  .popup-container {
    height: 85vh;
    overflow: auto;
  }
  .step {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: ${theme.palette.default.newTextGrey};
  }
  .popup-container h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    /* padding-top: 2rem; */
  }
  .answered-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
    align-items: center;
    padding: 16px;
  }
  .answered-heading p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: ${theme.palette.default.NightRider};
  }

  .radio-btn-heading {
    padding-top: 1rem;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    color: ${theme.palette.default.newTextBlack};
  }
  .file-upload__area {
    width: 747px;
    height: 164px;
    padding: 40px 241px 40px 241px;
    border-radius: 4px;
    border: 1px dotted gray;
    gap: 56px;
  }
  .file-upload__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;
  }
  .use-radio-group {
    margin-left: 1.4rem;
  }
  .note {
    padding: 13px 0px;
  }
  .upload_ul {
    margin-left: 9px;
    height: calc(100vh-47vh);
  }

  .upload_ul li::before {
    color: red;
  }
  .btn {
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 16px;
    border-top: 1px solid ${theme.palette.default.BlackOpacityBorder};
    padding: 1rem;
  }
`;

export const Center = styled.div`
  .fallback_ui_container {
    margin: 20px;
    height: calc(100vh - 170px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    background-color: white;
    .fallback_ui {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        transform: scale(1.3);
      }
      .error-message {
        margin: 50px 0px 20px 0px;
        font-size: 20px;
      }
    }
  }
`;


export const QueryBuilderWrapper = styled.div`
  & {
    .ruleGroup {
      height: calc(100vh - 160px);
    }
    .form-heading {
      display: flex;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      padding: 0rem 1rem 0rem 1rem;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 9;
    }

    .form-first__area {
      padding: 2rem 1.5rem 1rem 1.5rem;
    }
    .form-first_area_c {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .footer {
      padding: 2rem 0rem 0rem 0rem;
      .footer-btns {
        justify-content: end;
        gap: 1.3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 1rem;
        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const StyledModelHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    height: 56px;
    min-height: 56px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h6 {
      font-size: 1.05rem;
      color: ${theme.palette.default.text};
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }
    .footer {
    }
  }
`;
export const StyledModelFooter = styled.div`
  && {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

    .footer-btns {
      justify-content: end;
      gap: 1.3rem;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      padding-right: 1rem;
      .saveBtn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const StyledTableToolbar = styled(Toolbar)`
  && {
    width: 100%;

    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
    .top {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      width: 100%;
    }
    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      width: 100%;

      padding: 0 0rem 1rem;

      .toolbarText {
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 1rem;
        color: ${theme.palette.default.text};
        line-height: 20px;
        white-space: nowrap;
      }

      .displayFlex {
        display: flex;
      }

      .toolbarTextred {
        color: ${theme.palette.default.error} !important;
        padding-right: 1rem;
        font-weight: 500;
      }

      .toolbarTextgreen {
        color: ${theme.palette.default.success} !important;
        font-weight: 500;
      }
    }
  }
`;
