import moment from "moment";
import { Wrappertable } from "./RecipientsStyle";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import NoRecordImage from "../../../../assets/images/norecordsanalytics.svg";
interface Props {
  broadcastDetails?: any;
}

const RecipientsTableInfo: React.FC<Props> = ({
  broadcastDetails,
}) => {
  const formateDate = (d: any) => {
    return moment(d).format("DD-MM-YYYY h:mm A")
  };

  const hasData = (
    broadcastDetails &&
    (broadcastDetails?.reply_to ||
       broadcastDetails?.modified_time ||
        broadcastDetails?.sent_on ||
         broadcastDetails?.created_time )
  ) ? true : false;

  return (
    <Wrappertable>
      {
        hasData ? (
          <>
            <div className="roww_table">
              <p className="text">Reply to</p>
              <p className="value">{broadcastDetails?.reply_to || "N/A"}</p>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table">
              <p className="text">Updated at</p>
              <p className="value">{formateDate(broadcastDetails?.modified_time) || "N/A"}</p>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table">
              <p className="text">Run at</p>
              <p className="value">{formateDate(broadcastDetails?.sent_on) || "N/A"}</p>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table_last">
              <p className="text">Delivery at</p>
              <p className="value">{formateDate(broadcastDetails?.created_time) || "N/A"}</p>
            </div>
          </>
        ) : (
            <NoRecordsFound
                imageSrc={NoRecordImage}
                imageWidth={"200px"}
                headerTextSize={"19px"}
              />
        )
      }
    </Wrappertable>
  );
};

export default RecipientsTableInfo;
