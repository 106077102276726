import { useSelector } from "react-redux";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../../Store";
import { testWhatsappModel } from "../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { LocalStorage } from "../../../../../Utils";
import { useFormikContext } from "formik";
import { testWhatsappData } from "../../../../../Store/Slices/Whatsapp/whatsapp.slice";
import { WhatsappActions } from "../../../../../Store/Slices/Whatsapp/whatsapp.actions";
import { useEffect } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { AppFormTextArea } from "../../../../../AppComponents/Forms";
import AppButton from "../../../../../AppComponents/AppButton";
import styled from "styled-components";
import { convertFormToPayloadSingleWhatsappBroadcast } from "../../../../Leads/contact-action/utils/constants";

const localStorage = new LocalStorage();

interface Props {
    openModel?: boolean
}
const TestWhatsappTemplate: React.FC<Props> = ({
    openModel = false
}) => {
    const dispatch = useAppDispatch()
    let { showAlert } = useNotification();
    const editedPhone = useSelector(testWhatsappModel);
    const { values, setFieldValue } = useFormikContext<any>();

    const style = {
        position: 'fixed' as 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "662px",
        height: "auto",
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: "15px",
    };
    const notesList = [
        "If multiple recipients, separate the phone no. by a comma.",
        "The tracking will not be enabled.",
        "Credits will be deducted according to the number of whatsapp message sent for this test",
        "Phone no. should be in these format i.e +91xxxxxxxx,+91xxxxxxxx"
    ]
    const validatePhone = (phone: any) => {
        let status = false;
        phone?.map((res: any) => {
            if (String(res)
                .toLowerCase()
                .match(
                    /^\+\+?(\d{1,4})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}$/
                )) {
                status = true
            } else {
                status = false
            }
        })
        return status
    };

    const getBroadcastData = () => {
        let stringValue = localStorage.get("BROADCAST_WHATSAPP_FORM_DATA")
        if (stringValue && typeof (stringValue) == 'string') {
            return JSON.parse(stringValue);
        }
        return stringValue;
    }

    const addPhone = () => {
        let broadcastData = getBroadcastData();
        if (values?.recipient?.length > 0 && validatePhone(values?.recipient?.split(','))) {
            dispatch(testWhatsappData(false))
            let payload: any = {};
            payload = convertFormToPayloadSingleWhatsappBroadcast(values);
            payload = {
                ...payload,
                phone_no: values.recipient?.split(','),
                broadcast_id: broadcastData?.id,
                template_id: values?.template?.template_id,
            }
            dispatch(WhatsappActions.postTestWhatsappMessage(payload))
                .then((res: any) => {
                    if (res?.payload?.data?.status) {
                        showAlert("Successfullly Sent!", "success");
                        values.recipient = ""
                    } else {
                        showAlert(
                            res?.payload?.data?.error ||  "unable to send test message please try again later!",
                            "error"
                        );
                    }

                })
                .catch((error: any) => {
                    showAlert(
                        "Template Name Already Exist",
                        "error"
                    );
                })
                .finally(() => {
                });
        } else {
            showAlert(
                "Please Enter Valid Whatspp Number",
                "error"
            );
        }
    }
    useEffect(() => {
        setFieldValue("recipient", [])
    }, [])
    return (
        <>
            <h1>{editedPhone}</h1>
            <Modal open={editedPhone} >
                <Box sx={style}>
                    <Typography variant="body1" gutterBottom >Send a test whatsapp message</Typography>
                    <TestWhatsappNots>
                        Notes:
                        <TestWhatsappNoteList>
                            <ul className="list">
                                {notesList.map((list: any, index: number) => {
                                    return <li key={"list_" + index.toString()}>{list}</li>
                                })}
                            </ul>
                        </TestWhatsappNoteList>
                    </TestWhatsappNots>
                    <AppFormTextArea
                        name={"recipient"}
                        label="Recipient (s)"
                        placeholder={"i.e: +91xxxxxxxx,+91xxxxxxxx,+91xxxxxxxx"}
                        rows={5}
                    />
                    <TestWhatsappControlOpration>
                        <AppButton
                            variant="grey"
                            onClick={() => dispatch(testWhatsappData(false))}
                        >
                            Cancel
                        </AppButton>

                        <Box display="flex">
                            <AppButton
                                variant="cta"
                                onClick={() => addPhone()}
                                endIcon="Send"
                            >
                                Send
                            </AppButton>
                        </Box>
                    </TestWhatsappControlOpration>
                </Box>
            </Modal>
        </>
    )
}

const TestWhatsappNots = styled.div`
    height: auto;
    padding: 13px 18px 23px 19px;
    border-radius: 4px;
    background: rgba(91, 115, 232, 0.15);
`
const TestWhatsappNoteList = styled.div`
    padding-left: 2rem;
`
const TestWhatsappControlOpration = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
gap: 0.8rem;
`
export default TestWhatsappTemplate