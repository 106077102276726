import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { device } from "../../../Customization/BreakPoints";
// Css main Layout
export const WrapperContainer = styled.div`
  width: 100%;
  /* height: calc(100% - 64px); */
  height: 100%;
  form {
    padding: 25px;
  }
`;

export const Title = styled.span`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.h4.fontWeight};
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #323232;
  }
`;

export const Section = styled.span`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.h6.fontWeight};
    font-size: 1rem;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #323232;
  }
`;

export const TaskType = [
  {
    "label": "To-Do",
    "name": "to_do",
    "id": "TASKCONFTYPE5tg9FdAS",
    "visible": true,
    "position": 1
  },
  {
    "label": "Follow-up",
    "name": "follow_up",
    "id": "TASKCONFTYPEL7kFTHb5",
    "visible": true,
    "position": 2
  },
  {
    "label": "Call",
    "name": "call",
    "id": "TASKCONFTYPEsizoayLY",
    "visible": true,
    "position": 3
  },
  {
    "label": "Meeting",
    "name": "meeting",
    "id": "77363b7fc262sizoayLY",
    "visible": true,
    "position": 4
  }
]

export const TaskPriority = [
  {
    "label": "Low",
    "name": "low",
    "id": "70536f11b814",
    "visible": true,
    "color": "#27B783",
    "position": 1
  },
  {
    "label": "Medium",
    "name": "medium",
    "id": "47a0f1fcf231",
    "visible": true,
    "color": "#FF9C40",
    "position": 2
  },
  {
    "label": "High",
    "name": "high",
    "id": "36b48ba7222d",
    "visible": true,
    "color": "#DA4343",
    "position": 3
  }
]

export const TaskStatus = [
  {
    "label": "Upcoming",
    "name": "upcoming",
    "visible": true,
    "position": 1
  },
  {
    "label": "Completed",
    "name": "completed",
    "visible": true,
    "position": 2
  }
]