import { useDrag } from 'react-dnd';
import styled from 'styled-components';
import { isIdPresent } from './helper';
import { formBuilderStore } from '../../../Store/Slices/FormBuilder/formbuilder.selectors';
import { useAppSelector } from '../../../Store';
interface FieldsProps {
    label: string | undefined;
    onClick?: any;
    type?: string;
    name?: string;
    id?: number;
    default_value?: string;
    visible?: boolean;
    is_dependant?: boolean;
    dependant_field_id?: string;
    custom?: boolean;
}
interface props {
    item: FieldsProps;
    isCustomProperty?: boolean;
    onClick?: any;
}
const Fields = (props: props): JSX.Element => {
    const formFieldsData: any = useAppSelector(formBuilderStore);
    //Draggin Hook
    const [{ isDragging }, drag] = useDrag({
        type: "BOX",
        item: props?.item,
        collect: (monitor: any) => ({
            isDragging: !!monitor.isDragging()
        }),
    });
    return (
        <Container ref={drag} isDragging={isDragging}
            onClick={props?.onClick}
            disabled={!props?.isCustomProperty && isIdPresent(formFieldsData, props?.item?.id)}>
            <DraggableIcon />
            <Field title={props?.item?.label}>{props?.item?.label}</Field>
        </Container>
    );
}
const Container = styled.div`
&&{
    display:flex;
    align-items:center;
    height: 50px;
    width: 50%;
    margin:10px 0;
    padding: 0 8px;
    cursor: "grab";
    opacity:${(props: { isDragging: boolean, disabled: boolean }) => props.isDragging || props.disabled ? '0.5' : '1'};
    pointer-events: ${(props: { disabled: boolean; }) => props.disabled ? 'none' : 'auto'};
}`
const Field = styled.div`
&&{
    display: grid;
    width: 90%;
    height: 40px;
    border: 1px solid rgba(126, 131, 146, 0.3);
    border-left: 0px;
    align-items:center;
    padding:0px 10px;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    cursor: pointer;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide any content that overflows the box */
    text-overflow: ellipsis !important; /* Display an ellipsis (...) to indicate truncated text */
}`
const DraggableIcon = () => {
    return (
        <svg width="22" height="44" viewBox="0 0 22 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H22V44H4C1.79086 44 0 42.2091 0 40V4Z" fill="#7E8392" fill-opacity="0.3" />
            <path d="M7.47222 17C8.28531 17 8.94444 16.3284 8.94444 15.5C8.94444 14.6716 8.28531 14 7.47222 14C6.65913 14 6 14.6716 6 15.5C6 16.3284 6.65913 17 7.47222 17ZM7.47222 23.5C8.28531 23.5 8.94444 22.8284 8.94444 22C8.94444 21.1716 8.28531 20.5 7.47222 20.5C6.65913 20.5 6 21.1716 6 22C6 22.8284 6.65913 23.5 7.47222 23.5ZM8.94444 28.5C8.94444 29.3284 8.28531 30 7.47222 30C6.65913 30 6 29.3284 6 28.5C6 27.6716 6.65913 27 7.47222 27C8.28531 27 8.94444 27.6716 8.94444 28.5ZM14.3426 17C15.1557 17 15.8148 16.3284 15.8148 15.5C15.8148 14.6716 15.1557 14 14.3426 14C13.5295 14 12.8704 14.6716 12.8704 15.5C12.8704 16.3284 13.5295 17 14.3426 17ZM15.8148 22C15.8148 22.8284 15.1557 23.5 14.3426 23.5C13.5295 23.5 12.8704 22.8284 12.8704 22C12.8704 21.1716 13.5295 20.5 14.3426 20.5C15.1557 20.5 15.8148 21.1716 15.8148 22ZM14.3426 30C15.1557 30 15.8148 29.3284 15.8148 28.5C15.8148 27.6716 15.1557 27 14.3426 27C13.5295 27 12.8704 27.6716 12.8704 28.5C12.8704 29.3284 13.5295 30 14.3426 30Z" fill="#7E8392" />
        </svg>
    )
}
export default Fields;
