import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import {
  allMessagesStore,
  removeAllMessages,
  setSessionId,
} from "../../../../../Store/Slices/socket/AllMessagesSlice";
import {
  setActiveId,
  setCurrentSessionData,
} from "../../../../../Store/Slices/socket/liveChat/chat.slice";
import { renderTime } from "../../../../../Utils";
import { StyledPreviousConvoBox } from "../UsersDetailStyles";
import useMessagesData from "../../../../hooks/useMessagesData";

interface Props {}

const UserPreviousNotes: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { sessionId, previousConversationsData } =
    useAppSelector(allMessagesStore);

  const { sendBotHistoryRequestEvent } = useMessagesData();

  const handleItemClick = (curItem: any) => {
    dispatch(removeAllMessages({}));
    setTimeout(() => {
      // dispatch(setSessionId(curItem?.session_id));
      // dispatch(setActiveId(curItem?.session_id));
      // dispatch(setCurrentSessionData(curItem));
      sendBotHistoryRequestEvent(
        curItem?.session_id,
        curItem?.bot_id ?? curItem?.botid
      )
        .then((res) => {
          dispatch(setSessionId(curItem?.session_id));
          dispatch(setActiveId(curItem?.session_id));
          dispatch(setCurrentSessionData({ ...curItem, count: 0 }));
        })
        .catch((err) => {})
        .finally(() => {});
    }, 100);
  };
  return (
    <>
      {previousConversationsData &&
        previousConversationsData?.length > 0 &&
        previousConversationsData?.map((curItem: any, index: number) => {
          //remove current session from previous sessions
          if (curItem?.session_id === sessionId) return null;
          if (!curItem?.session_id) return null;

          return (
            <StyledPreviousConvoBox
              key={index}
              onClick={() => handleItemClick(curItem)}
            >
              <p className="message">{curItem?.name}</p>
              <div className="bottomBox">
                <p className="date">{renderTime(curItem?.date?.toString())}</p>
                {curItem?.conversation_status && (
                  <div className="action-bottom__left">
                    <span
                      className={`open ${statusClassgenerator(
                        curItem?.conversation_status
                      )}`}
                    >
                      {curItem?.conversation_status}
                    </span>
                  </div>
                )}
              </div>
            </StyledPreviousConvoBox>
          );
        })}
    </>
  );
};

export default UserPreviousNotes;

const statusClassgenerator = (status: any) => {
  if (status === "Pending") {
    return "pending-status";
  }
  if (status === "Open") {
    return "open-status";
  }
  if (status === "Closed") {
    return "close-status";
  }
};
