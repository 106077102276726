import { Box, Button } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../../../socket/appComponents/Icons";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices/BotIdSlice";
import { useAppSelector } from "../../../../../Store";

interface Props {
  prefilled_message_text: string;
  whatsapp_number?: string;
  button_background_color: string;
  startButtonText?: string;
}

const BotInputScreen: React.FC<Props> = ({
  prefilled_message_text,
  whatsapp_number = "+919876543210",
  startButtonText = "Start conversation",
  button_background_color = "#25D366",
}) => {
  const currentBot: any = useAppSelector(useCurrentBot);
  const customMessage = encodeURIComponent(
    prefilled_message_text ?? "hello, how can i help you?"
  );
  let finalUrl: string = ``;
  const currentBotComponent: any = {
    "whatsapp": `https://wa.me/${whatsapp_number}?text=${customMessage}`,
    "messenger": `https://www.messenger.com/t/${currentBot?.facebook_page_id}`,
    "telegram": `https://web.telegram.org/k/${currentBot?.telegram_id}`,
  }
  finalUrl = currentBotComponent[currentBot?.channel]


  return (
    <StyledInputScreenContainer>
      <StyledWBCTAButton
        component="a"
        startIcon={<AppMaterialIcons iconName="SendRounded" />}
        href={finalUrl}
        target="_blank"
        rel="noreferrer"
        $button_background_color={button_background_color}
        style={{ width: "100%", margin: " 0px 8px" }}
      >
        {startButtonText}
      </StyledWBCTAButton>
    </StyledInputScreenContainer>
  );
};

export default React.memo(BotInputScreen);

const StyledInputScreenContainer = styled(Box)`
  && {
    width: 100%;
    height: 56px;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px -1px 1px 0px #e3e1e1;
  }
`;

interface StyledAppButtonProps {
  $button_background_color?: boolean;
}

const StyledWBCTAButton = styled(Button) <StyledAppButtonProps>`
  && {
    background: ${({ $button_background_color }: any) =>
    $button_background_color ?? "#25D366"};
    color: #ffffff;
    padding: 4 8px;
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    text-align: center;
    border-radius: 30px;

    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-transform: capitalize;
    pointer-events: none;
    &:hover {
      background: ${({ $button_background_color }: any) =>
    $button_background_color ?? "#25D366"};
    }

    & .MuiSvgIcon-root {
      font-size: 1.3rem;
    }
  }
`;
