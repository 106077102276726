import { useSelector } from "react-redux";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../Store";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import moment from "moment";
import { GridCloseIcon } from "@mui/x-data-grid";
import {AppPagination,} from "../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import {NewNativeSelect} from "../../WhatsApp/BroadcastHistory/HistoryStyles";
import {Box,MenuItem,Typography,debounce,Select} from "@mui/material";

import {
  BroadcastBodyWrapper,
  BroadcastLogTable,
  BroadcastMainWrapper,
  BroadcastMainWrapperTitle,
} from "../Logs/EmailLogStyles";
import AppRangeDatePicker from "../../../../AppComponents/AppRangeDatePicker";
import { ErrorComponent } from "../Template/TemplatesListingCSS";
import TemplateAPIERROR from "../../../../assets/images/No_records_found.svg";
import { filtersValuesData, logListDataObj } from "../../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import { broadcastActions } from "../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";

interface Props extends HocBackdropLoaderProps { }
const EmailLogs: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const filtersValues = useSelector(filtersValuesData);
  const logListData = useSelector(logListDataObj);
  const totalcount = logListData?.count;
  const list = logListData?.results;
  const [logsData, setLogsData] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<any>("");
  const header = [
    "Date",
    "From",
    "To",
    "Subject",
    "Status",
    "Error Reason",
    "Price",
  ];

  const keyToHeaderMap: any = {
    Date: "sent_on",
    From: "from_email",
    To: "email",
    Subject: "subject",
    Status: "status",
    "Error Reason": "error_reason",
    Price: "amount",
  };

  const colWidth: any = {
    Date: "220",
    From: "250",
    To: "300",
    Subject: "420",
    Status: "150",
    "Error Reason": "200",
    Price: "150",
  };
  // const keyList = ["sent_on", "recipients", "status", "sent", "delivered", "failed"]
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: null,
    endDate: null,
    search: "",
    itemsPerPage: 10,
    currentPage: 0,
    broadcast_type: "email",
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorDownloadEl, setAnchorDownloadEl] = useState<null | HTMLElement>(
    null
  );

  const handleDownClose = () => {
    setAnchorDownloadEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setLogsData(true);
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    setLogsData(true);
  };

  const getLogsData = () => {
    let start = null;
    let end = null;
    if (localValues.startDate && localValues.endDate) {
      start = format(localValues.startDate, "yyyy-MM-dd");
      end = format(localValues.endDate, "yyyy-MM-dd");
    }
    setLoading(true);
    dispatch(
      broadcastActions.getAllCommunicationLogs({
        start: start,
        limit: localValues.itemsPerPage,
        offset: localValues.currentPage,
        end: end,
        status: localValues?.status,
        search: localValues?.search,
        type: localValues?.broadcast_type,
      })
    )
      .then((res: any) => {
        setErrorApi(false);
        setLoading(false);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
        setLoading(false);
        // setOnSearch(false);
      });
  };

  const getValueForFilterLogs = () => {
    dispatch(
      broadcastActions.getAllCommunicationFilterSettings({ type: "email" })
    )
      .then((res: any) => { })
      .catch((error: any) => { });
  };

  const restFilter = () => {
    setLocalValues({
      status: "",
      startDate: null,
      endDate: null,
      search: "",
      itemsPerPage: 10,
      currentPage: 0,
      broadcast_type: "email",
    });
    setLogsData(true);
    handleClose();
  };

  const applyFilter = () => {
    setLogsData(true);
    handleClose();
  };
  

  useEffect(() => {
    if (logsData) {
      getLogsData();
      setLogsData(false);
    }
  }, [logsData]);

  useEffect(() => {
    getValueForFilterLogs();
    getLogsData();
  }, []);

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
    setLogsData(true);
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
    setLogsData(true);
  };
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
    });
    setLogsData(true);
  };
  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
    setLogsData(true);
  };

  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  useEffect(() => {
    handleExportClick();
  }, [filterType && isDownloading]);

  const handleExportClick = () => {
    if (filterType) {
      setIsDownloading(true);
      let payload: any;
      if (filterType === "filter") {
        payload = {
          start: format(localValues.startDate, "yyyy-MM-dd"),
          end: format(localValues.endDate, "yyyy-MM-dd"),
          status: localValues?.status,
          search: localValues?.search,
          type: "email",
          filter_type: filterType,
        };
      }
      if (filterType === "all") {
        payload = {
          type: "email",
          filter_type: filterType,
        };
      }
      dispatch(marketingListActions.getCommunicationLogsDownload(payload))
        .then((res) => {
          window.open(res?.payload?.data?.data.source, "_blank");
        })
        .catch((error) => {
          console.error("Error occurred while fetching the file URL.", error);
        });
      setIsDownloading(false);
      setFilterType("");
    }
  };

  const setDownloadFiled = (filterType: any) => {
    setFilterType(filterType);
    setIsDownloading(true);
    handleDownClose();
  };

  const renderSelectIconStatus = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  return (
    <>
      <BroadcastMainWrapper>
        <BroadcastMainWrapperTitle>
          <Box>
            <Typography fontSize="16px" fontWeight="500" component="h6">
              Email Logs
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px" }}></Box>
        </BroadcastMainWrapperTitle>
        <BroadcastBodyWrapper>
          <AppForm // requestSearch(values, submitProps);
            initialValues={{ search: "" }}
            onSubmit={(values: any, submitProps: any) => { }}
            // validationSchema={BotValidationSchema}
            divStyle={{ width: "100%" }}
          >
            <div className="fields-container">
              <div className="sms_left">
                <div className="">
                  <AppFormSearchField
                    name="search"
                    placeholder="Search"
                    defaultBorder={true}
                    // sendValuesToParent={(values: any) => sendValuesToParent(values)}
                    onChange={(e: any) => handleSelectSearch(e, "search")}
                    clearCallback={(e: any) => clearCallback(e, "search")}
                    divStyle={{
                      marginBottom: "0rem",
                      width: "100%",
                      minWidth: "100%",
                    }}
                    disabled={logsData}
                  />
                </div>
              </div>
              <div className="sms_right">
                <div className="sms_select">
                  <MenuItem className="setting-page__menu">
                    <NewNativeSelect>
                      <Select
                        defaultValue={""}
                        name="status"
                        value={localValues?.status}
                        onChange={(e: any) => handleSelectChange(e, "status")}
                        displayEmpty
                        className="new-select-main"
                      >
                        <MenuItem value=""> Filter By Status</MenuItem>
                        {filtersValues?.status?.map((item: any, index: any) => (
                          <MenuItem value={item.value} key={index}>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div
                        className="clear-icon"
                        onClick={(e: any) => clearCallback(e, "status")}
                      >
                        {renderSelectIconStatus}
                      </div>
                    </NewNativeSelect>
                  </MenuItem>
                </div>
                <div className="sms-date_picker">
                  <AppRangeDatePicker
                    divStyle={{
                      width: "225px",
                    }}
                    reset={true}
                    dateRange={localValues}
                    setDateRange={handleChangeDate}
                  />
                </div>
              </div>
            </div>
          </AppForm>
          <BroadcastLogTable length={list?.length} width={colWidth}>
            <div>
              {/* <LogTableWrapper> */}
              <Box className="table-header">
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{
                          maxWidth: colWidth[header] + "px",
                          minWidth: colWidth[header] + "px",
                          // border: "1px solid",
                        }}
                      >
                        <Typography>{header}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box className="table-body">
                {errorApi || list?.length === 0 ? (
                  <ErrorComponent
                    style={{
                      border: "none",
                      height: "calc(100vh - 214px)",
                    }}
                  >
                    <Box display="flex" justifyContent="center">
                      <img width="400" src={TemplateAPIERROR} alt="" />
                    </Box>
                    <Box className="error-Info">
                      <Typography variant="h6" fontSize="20px">
                        No Records Found
                      </Typography>
                    </Box>
                  </ErrorComponent>
                ) : (
                  <>
                    {list?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => (
                            <Box
                              className={`col-body ${header
                                .toLowerCase()
                                .replaceAll(" ", "_")}`}
                              title={
                                header === "Date"
                                  ? formatDateField(row[keyToHeaderMap[header]])
                                  : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"
                              }
                              style={{
                                maxWidth: colWidth[header] + "px",
                                minWidth: colWidth[header] + "px",
                                // border: "1px solid",
                              }}
                            >
                              <Typography className="table-text">
                                {header === "Date"
                                  ? formatDateField(row[keyToHeaderMap[header]])
                                  : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
            </div>
            {/* </LogTableWrapper> */}
          </BroadcastLogTable>
        </BroadcastBodyWrapper>
        {list?.length ? (
          <AppPagination
            component="div"
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={localValues.currentPage}
            count={totalcount}
            rowsPerPage={localValues.itemsPerPage}
          />
        ) : null}
      </BroadcastMainWrapper>

      {/* Old */}
    </>
  );
};

// Default Export of component
export default HocBackdropLoader(EmailLogs);
