import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Field, getIn, useFormikContext } from "formik";
import styled from "styled-components";
import { Caption } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";
import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import moment from "moment";

var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

interface Props {
  label?: string;
  subTitle?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  children?: React.ReactNode;
  disablePast?: boolean;
  [otherProps: string]: any;
  broadcastData?: any;
  requiredSign?: boolean;
  labelStyle?: React.CSSProperties;
  optionalText?: string;
  onChangeCallbackFn?: (value: any) => void;
}

const AppFormDateAndTimePicker: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  subTitle,
  children,
  disablePast,
  broadcastData,
  requiredSign,
  labelStyle,
  optionalText,
  onChangeCallbackFn,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext<any>();
  const [open,setOpen] = useState(false);

  useEffect(() => {
    if (getIn(values, name)) {
      setFieldValue(
        name,
        dayjs(getIn(values, name), "DD-MM-YYYY hh:mm A")
        // moment(getIn(values, name)).format("DD-MM-YYYY h:mm A")
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Div style={divStyle}>
        <div className="textLabelOptional">
          <Label style={labelStyle}>
            {requiredSign ? (
              <Fragment>
                {label}
                <sup className="starSuperScript"> *</sup>
              </Fragment>
            ) : (
              label
            )}
            {tooltip && (
              <Tooltip title={tooltip} placement="top">
                <HelpOutlineIcon />
              </Tooltip>
            )}
          </Label>
          {optionalText && (
            <span
              style={{ color: "rgba(126, 131, 146, 1)", marginLeft: "5px" }}
            >
              {optionalText}
            </span>
          )}
        </div>
        {subTitle && <Caption>{subTitle}</Caption>}
        <Input>
          {({ field }: any) => (
            <DateTimePicker
              // defaultValue={dayjs("2022-04-17T18:30")}
              {...field}
              
              value={dayjs(getIn(values, name), "DD-MM-YYYY hh:mm A")}
              className="input-date-picker"
              onChange={(newValue) => {
                setFieldValue(name, newValue);
                setFieldTouched(name, true);
                onChangeCallbackFn && onChangeCallbackFn(newValue);
              }}
              disablePast={disablePast}
              open={open}
              onClose={() => setOpen(false)}
              slotProps={{
                textField: {
                 onClick: () => setOpen(true),             
                },
              }}
              {...otherProps}
            />
          )}
        </Input>
        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </Div>
    </LocalizationProvider>
  );
};
export default AppFormDateAndTimePicker;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
    .MuiTextField-root {
      width: 100%;
      border-radius: 4px;
      background-color: #f5f6f8;
      outline: none !important;
      padding: 0.4rem 0.5rem 0.4rem 1rem;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      border: 2px solid transparent;
    }
    .textLabelOptional {
      display: flex;
      gap: 0.5rem;
    }
  }
`;

const Input = styled(Field)`
  && {
    width: 100%;

    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    border: 2px solid ${theme.palette.default.border};
  }
  .input-date-picker {
    .MuiInputBase-input {
      padding: 0;
    }
  }
  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.darkGrey};
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 6px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
    sup {
      color: ${theme.palette.default.error};
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;
