import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { ChatbotTrackingCode } from "../../Models";
import { IntegrationMenuOptions } from "../../Models/defaultSelectOptions";
import { getBotTrackingCodeFn } from "../../Services/Apis/ChatbotConsoleApis/ChatbotTrackingCode";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import IntegrationsSidebar from "./IntegrationsSidebar";

const Integrations = () => {
  useDocumentTitle("Integrations")
  const [trackingCode, setTrackingCode] = useState<ChatbotTrackingCode>();
  const authData = useSelector(useAuthStore);
  const currentBot = useSelector(useCurrentBot);
  const dispatch = useDispatch();


  useEffect(() => {
    if (currentBot?.id) {
      getBotTrackingCodeFn(dispatch)({ pathParams: [currentBot?.id + ""] }).then(
        (res: ChatbotTrackingCode) => {
          setTrackingCode(res);
        }
      );
    }
  }, [authData, currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IntegrationsPageWrap>
      <IntegrationsSidebar Options={IntegrationMenuOptions} />
      <MainBox>
        <Outlet />
      </MainBox>
    </IntegrationsPageWrap>
  );
};

const IntegrationsPageWrap = styled.div`
  && {
    height: 100%;
    display: flex;
    background-color: ${theme.palette.default.greyishWhite};
    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
  }
`;

const MainBox = styled.div`
  && {
    min-height: 100%;
    width: calc(100vw - 300px);
    overflow: hidden;
  }
`;

export default Integrations;
