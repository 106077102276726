import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const ChatsMainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${theme.palette.default.lightGrey};
  transition: all 0.5s linear 0s;
  overflow: hidden;
  /* padding: 1rem; */
`;

export const ChatAndDetailContainer = styled.div`
  display: flex;
  // height: calc(100% - 84px);
  height: 100%;
  // gap: 4px;
  // position: relative;
  // background-color: ${theme.palette.default.lightGrey};
  background-color: #ebeff3;
  overflow: hidden;
`;

export const ChatAndDetailOuterWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0rem 1rem 0rem 1rem;
  background-color: #ebeff3;
`;
export const StyleImageAvatar = styled.img`
  width: 20px;
  height: 20px;
`;
