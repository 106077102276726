import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#4539a0",
      light: "#5B73E8",
      dark: "#211c52",
    },
    success: {
      main: "#27B783",
      light: "#27B783",
      dark: "#27B783",
    },
    error: {
      main: "#F34E4E",
      light: "#F34E4E",
      dark: "#F34E4E",
    },
    default: {
      background: "#ffffff",
      text: "#282828",
      placeholder: "#AFAFAF",
      red: "#FF0000",
      white: "#ffffff",
      fadeWhite: "#e1e1e1",
      teaGreen: "#C9FFCE",
      greyishWhite: "#f1f1f1",
      azureishWhite: "#F1E2DB",
      keyLime: "#e3f49a",
      paleLavender: "#ddd2ef",
      bisque: "#ffe1c6",
      nyanza: "#D8FFD8",
      palePink: "#FED5D5",
      aeroBlue: "#CAFBE9",
      bananaMania: "#FFEBB8",
      lotion: "#fafafa",
      black: "#101010",
      lighterBlack: "#313131",
      chineseBlack: "#111111",
      grey: " #CBCDD3",
      fadeGrey: " #dfdfdf",
      lightGrey: "#F5F6F8",
      lighterGrey: "#f3e8e8",
      moreLighterGrey: "#F9F9F9",
      lighterGreyNew: "#F5F6F8",
      darkGrey: "#7E8392",
      doveGrey: "#666666",
      darkLiver: "#504f4f",
      blue: "#5B73E8",
      bluePurple: "#a6b1e6",
      pattensBlue: "#d6eeff",
      diamond: "#C6FCFF",
      border: "#CBCDD3",
      error: "#F34E4E",
      errorLighter: "rgba(255, 237, 237, 1)",
      lightError: "#FFE3E3",
      success: "#27B783",
      lightSuccess: "#D6FFF0",
      iconLight: "rgba(255, 255, 255, 0.4)",
      shadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
      errorLight: "#eba4a4",
      lightPrimaryBorder: "#C1CCFF",
      lightPrimaryBackground: "#F1F4FF",
      warning: "#ff9800",
      lightPurple: "rgba(216, 222, 250, 0.5)",
      purple: "#E6E6FA",
      newTextGrey: "#7E8392",
      newTextBlack: "#000000",
      AccentOrange: "#FF9F47",
      GhostWhite: "#EEF1FF",
      NightRider: "#323232",
      BorderColorGrey: "rgba(126, 131, 146, 0.5)",
      PopoverShadow: "rgba(0, 0, 0, 0.03)",
      RightBorderDivder: "rgba(126, 131, 146, 0.3)",
      BlackOpacityBorder: "rgba(16, 16, 16, 0.1)",
      successNew: "#25926a",
      successNewBackground: "#e5fff6",
      successNewBorder: "#12c584",
      LavenderBlue: "#f5f7ff",
      PastelCyan: "#bfcaff",
      GrayishBlue: "#7e83924d",
      lightRed: "#F19898",
      borderShadow: "rgba(0, 0, 0, 0.08)",
      skyBlue: "#ABE1FF",
      brightLavender: "#AF98F1",
      lightYellow: "#FFEE94",
      prettyGreen: "#BDFF94",
      cyanBlue: "#94C5FF",
      pinkFlamingo: "#F694FF",
      redSalmon: "#FF9494",
      paleGold: "#FFE55E",
      EarlyDawn: "#FFFBE5",
      Timberwolf: "#D9D9D9",
      BlackOpacity: "rgba(16, 16, 16, 0.5)",
      Coffee: "#918548",
      blazeOrange: "#F76707",
      karry: "#FFE5D4",
      corn: "#FFE55E",
      coffee: "#918548",
      earlyDawn: "#FFFBE5",
      tawny: "#CC590C",
      tequila: "#FFE5D4",
      greyText: "#7e8392,",
      lightBlue: "#D8DEFA",
      newFontColor: "#1B2863",
      midGrey: "#55546B",
      lighterFilterGrey: "#f5f6f8",
      coralRed: "#ff4545",
      antiFlashWhite: "#f3f3f3",
      bottomShadow:
        "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
      errorBg: "#FFEDED",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
      errorColor: "rgba(243, 78, 78, 0.5)",
    },
    secondary: {
      main: "#fcfcfc",
    },
    action: {
      // hover:'#F5F6F8',
      selected: "rgba(25, 118, 210, 0.08)",
      disabled: "#F5F6F8",
    },
    common: {
      white: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'Rubik', sans-serif",
    h1: {
      fontSize: "6rem",
    },
    h2: {
      fontSize: "3.75rem",
    },
    h3: {
      fontSize: "3rem",
      fontWeight: 400,
      lineHeight: 1.13,
    },
    h4: {
      fontSize: "2.125rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1.25rem",
    },

    body1: {
      fontSize: "1rem",
    },
    body2: {
      fontSize: "0.875rem",
    },
    caption: {
      fontSize: "0.75rem",
    },

    subtitle1: {
      fontSize: "1rem",
    },
    subtitle2: {
      fontSize: "0.875rem",
    },
    overline: {
      fontSize: "0.75rem",
    },
    button: {
      fontSize: "0.875rem",
    },
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
});
