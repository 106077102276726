import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";

export const StyledListCard = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    .list-notification__wrapper {
      display: flex; align-items: center; width: 100%;
      .notification-image {
        width: 70px;
        height: 70px;
        background-size: cover;
      }
      .notification-text {
        flex-grow: 1; 
        margin-left: 8px; 
        word-break: break-all;
      }
      .action-icons {
        display: flex;
        button.Mui-disabled {
          background-color: #f2f2f2;
          border-radius: 5px;
          cursor: not-allowed;
          svg {
            fill: #e1e1e1;
          }
        }
      }
    }

    /* .cardLeft {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      flex-wrap: wrap;
      width: 30%;

      img {
        height: 80%;
        width: 50%;
        object-fit: cover;
        margin-right: 40px;
      }
    } */
    /* .cardRight {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      height: 100%;
      overflow: hidden;
      .rightTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow: hidden;
        margin-bottom: 4px;
        .rightTopTitle {
          display: flex;
          overflow: hidden;
          max-width: 70%;
          font-family: ${theme.typography.fontFamily};
          font-weight: 500;
          font-size: 0.9rem;
          line-height: 1.2rem;
          text-transform: capitalize;
          color: ${theme.palette.default.text};
          margin-top: 10px;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .rightTopAction {

          i {
            margin-right: 10px;
            font-size: 20px;
          }
          .MuiIconButton-root {
            width: 30px;
            height: 30px;
            svg {
              font-size: 20px;
            }
          }
        }
      }
    } */
  }
`;
