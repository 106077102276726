import * as Yup from "yup";
import { convertDateTimeToUTC } from "../../Broadcast/WhatsApp/Utils/utils";

export const InitialSelectValues = [
  "id",
  "first_name",
  "last_name",
  // "Actions",
  "email",
  "mobile_phone_number",
  // "Street Address",
  // "Country Region",
  // "State Region",
  // "Time Zone",
  // "Job Title",
  // "Industry",
  // "Postal Code",
  // "Company Name",
  // "Annual Revenue",
  // "Fax Number",
  // "Number of Employees",
  // "Father Name",
  // "Mother Name",
  // "Mobile Phone Number",
  // "WhatsApp Phone Number",
  // "Preferred language",
  // "Lifecycle Stage",
  // "Lifecycle Stage Status",
];
export const IdCoulumns = {
  label: "id",
  id: "id",
  icon: null,
  size: 50,
};
export const FirstNameColumn = {
  label: "First Name",
  id: "first_name",
  size: 500,
  toolTip: true,
  edit: true,
  icon: [
    {
      name: "LocalPhoneOutlined",
      action: () => {
        alert("hello");
      },
    },
    {
      name: "AlternateEmail",
      action: () => {
        alert("hello");
      },
    },
    {
      name: "ChatBubbleOutline",
      action: () => {
        alert("hello");
      },
    },
    {
      name: "ForumOutlined",
      action: () => {
        alert("hello");
      },
    },
  ],
};
export const CreateTaskSchema = Yup.object().shape({
  create_task_title: Yup.string()
    .ensure()
    .required()
    .min(3)
    .max(100)
    .label("Title"),
  create_task_type: Yup.string()
    .ensure()
    .required()
    .min(2, "Task Type is required field")
    .label("Task Type"),
  create_task_date: Yup.string()
    .ensure()
    .required()
    .min(2, "Task Date is required field")
    .label("Task Date"),
  create_task_time: Yup.string()
    .ensure()
    .required()
    .min(2, "Task Time is required field")
    .label("Task Time"),
  create_task_priority: Yup.string()
    .ensure()
    .required()
    .min(2, "Task Priority is required field")
    .label("Task Priority"),
  create_task_owner: Yup.string()
    .ensure()
    .required()
    .min(2, "Task Owner is required field")
    .label("Task Owner"),
  // create_task_related_to: Yup.string()
  //   .ensure()
  //   .required()
  //   .min(2, "Task Related To is required field")
  //   .label("Related To"),
  task_completed_date: Yup.object()
    .nullable()
    .when(["mark_as_completed"], (value: any, schema: any) => {
      return value
        ? Yup.string()
          .required("Completed Date is required")
          .min(2, "Select a desired option")
          .label("Completed Date")
        : schema.optional();
    }),
  task_completed_time: Yup.object()
    .nullable()
    .when(["mark_as_completed"], (value: any, schema: any) => {
      return value
        ? Yup.string()
          .required("Completed Time is required")
          .min(2, "Select a desired option")
          .label("Completed Time")
        : schema.optional();
    }),
  create_task_outcome: Yup.object()
    .nullable()
    .when(["mark_as_completed"], (value: any, schema: any) => {
      return value
      ? Yup.string()
        .required("Task outcome is required")
        .min(2, "Select a desired option")
        .label("Task Outcome")
        : schema.optional();
    }),
});
export function getCreateTaskBody(values: any) {
  let payload = {
    "task_title": values?.create_task_title,
    "task_type": values?.create_task_type,
    "task_date": convertUTCToISO(convertDateTimeToUTC(
      values?.create_task_date,
      values?.create_task_time
    )),
    "task_description": values?.create_task_description,
    "task_owner": values?.create_task_owner,
    "mark_as_completed": values?.mark_as_completed,
    "completed_date": values?.task_completed_date && values?.task_completed_time ? convertUTCToISO(convertDateTimeToUTC(
      values?.task_completed_date,
      values?.task_completed_time
    )) : null,
    "task_priority": values?.create_task_priority,
    "related_to": values?.selectRelatedToValue?.id,
    "task_outcome": values?.create_task_outcome
  }
  return payload
}
export function convertDateTimeIntoISTForm(date:any)  {
  const gmtDate = new Date(date);

  // Get the time offset for IST (IST is UTC+5:30)
  const istOffset = 5.5 * 60 * 60 * 1000;
  
  // Convert GMT to IST
  const istDate = new Date(gmtDate.getTime() + istOffset);

  return istDate.toLocaleString('en-IN', { timeZone: 'Asia/Kolkata' })
}
export function convertUTCToISO(dateString: any) {
  // Create a Date object from the UTC date string
  const date = new Date(dateString);

  // Use toISOString() to convert to ISO 8601 format
  return date.toISOString();
}