// vendors
import IcoMoon, { IconProps } from "react-icomoon";

// selection
import iconSet from "../../assets/icomoon/selection.json";

interface UIIconProps extends IconProps {
  icon: string;
  size?: number;
  color?: string;
} 

// @ts-ignore
const Icon:React.FC<UIIconProps> = (props) => <IcoMoon iconSet={iconSet} {...props} />;

export default Icon;
