import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ProtectedAppUiElement } from "../../../../../../AppComponents/ProtectedComponents";
import { SmsBroadcastDashboardSummaryOptions, WhastAppBroadcastDashboardSummaryOptions } from "../../../../../../Models/defaultSelectOptions";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
import { CustomTabPanelContainer, WebsiteWrap } from "./WhatsappTopbarStyles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import AppRangeDatePicker from "../../../../../../AppComponents/AppRangeDatePicker";
import { setDashboardDateRange } from "../../../../../../Store/Slices/DashboardRedux/dashboard.slice";
import { dateRange } from "../../../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";
import AutoGraphOutlinedIcon from "@mui/icons-material/AutoGraphOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

interface Props {}

export const WhatsappTopbar: React.FC<Props> = () => {
  const [value, setValue] = useState(0);
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(dateRange);
  const navigate = useNavigate();
  const location: any = useLocation();
  const [tabsOptions, setTabsOptions] = useState<any>(
    WhastAppBroadcastDashboardSummaryOptions,
  );

  const getCurrentTabAfterRefresh = () => {
    tabsOptions?.forEach((item: any, index: number) => {
      if (location.pathname.includes(item?.value)) {
        setValue(index);
      }
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let url: string = tabsOptions?.[newValue]?.url;
    navigate(url);
  };

  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (date: any) => {
    dispatch(setDashboardDateRange(date));
  };

  return (
    <WebsiteWrap>
      <Box sx={{ width: "100%", height: "100%" }}>
        <TabWrapper>
          <WhatsappInsightTopBar>
            <Typography fontSize="16px" fontWeight="600" component="h6">
              Insights
            </Typography>
          </WhatsappInsightTopBar>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            width={"100%"}
          >
            <Tabs
              className="website-tabs"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{
                display: "flex !important",
                flexDirection: "row",
                alignItems: "center !important",
                justifyContent: "space-between !important",
                width: "100%",
              }}
            >
              {tabsOptions.map((option: any, index: number) => (
                <Tab
                  label={option.name}
                  id={`simple-tab-${index}`}
                  key={index}
                  icon={
                    option?.name === "Overview" ? (
                      <AutoGraphOutlinedIcon />
                    ) : (
                      <ArticleOutlinedIcon />
                    )
                  }
                  iconPosition="start"
                  sx={{ minHeight: "55px" }}
                />
              ))}
            </Tabs>
            <Box className="date">
              <AppRangeDatePicker
                dateRange={dashdateRange}
                setDateRange={onChange}
              />
            </Box>
          </Box>
        </TabWrapper>

        {tabsOptions.map((option: any, index: number) => (
          <CustomTabPanel value={value} index={index} key={index}>
            <ProtectedAppUiElement moduleId="sms_insights">
              <Outlet />
            </ProtectedAppUiElement>
          </CustomTabPanel>
        ))}
      </Box>
    </WebsiteWrap>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <CustomTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </CustomTabPanelContainer>
  );
}

const WhatsappInsightTopBar = styled.div`
  && {
    width: 100%;
    padding: 16px 16px 16px 32px;
    background-color: ${theme.palette.default.white};
    text-align: left;
    h6 {
      color: ${theme.palette.default.black};
    }
  }
`;

const TabWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    border-bottom: 1px solid ${theme.palette.default.grey};
    justify-content: space-between;
    align-items: center;
    margin: 0 16px;
    width: calc(100% - 4px);
    margin-left: 4px;
    align-items: center;
    flex-direction: column;
    position: sticky;
    top: 0;
    z-index: 2;
    .website-tabs {
      background-color: ${theme.palette.default.greyishWhite};
    }
    .date {
      background-color: ${theme.palette.default.greyishWhite};
      height: 55px;
      display: flex;
      align-items: center;
    }
  }
`;
