import { Theme, createTheme } from "@mui/material/styles";
import { theme } from "../../../Customization/Theme";

const flowBuilderTheme: Theme = createTheme({
  // Override or create new styles, colors, palettes...

  palette: {
    ...theme.palette,
    mode: "light",
    text: {
      primary: '#7E8392'
    },
    default: {
      ...theme.palette.default,
      text: "#7E8392",
    },
    common: {
      ...theme.palette.common,
      black: '#000000'
    }
  },
  typography: {
    body1: {
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 400,
    },
  },
});

export default flowBuilderTheme;