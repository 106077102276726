import * as Yup from "yup";

export const CompanyProfileSchema = Yup.object().shape({
    companyName: Yup.string().required().label("Company Name"),
    firstName: Yup.string().required().label("First Name"),
    lastName: Yup.string().required().label("Last Name"),
    state: Yup.string().required().label("State"),
    zip: Yup.string().required().min(4, "Invalid Zip").max(8, "Invalid Zip").label("Zip Code"),
    countryID: Yup.string().required().min(2, "Invalid Country code, eg: 91").label("Country Code"),
    city: Yup.string().required().label("City"),
    address1: Yup.string().min(8, "Invalid Address").required().label("City"),
    phone: Yup.string().required().min(10, "Invalid Phone Number").max(18, "Invalid Phone Number").label("Phone Number"),
})