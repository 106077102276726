import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AppFormField, AppFormSelectField } from '../../../../../../AppComponents/Forms';
import { useAppDispatch } from '../../../../../../Store';
import { FlowBuilderActions } from '../../../../../../Whatsapp/Store/FlowBuilder.actions';

interface CarouselFieldArrayProps {
    disabled: boolean
}
const FlowButton = (props: CarouselFieldArrayProps) => {
    const { disabled } = props;
    const { values, setFieldValue } = useFormikContext<any>();
    const [flowList, setFlowList] = useState([])
    const dispatch = useAppDispatch()
    const name = "flow";
    useEffect(() => {
        setFieldValue(`${name}.${0}.name`, "flow")
        let data = {
            status: "PUBLISHED",
        }
        dispatch(FlowBuilderActions.getFlows(data))
            .then((res: any) => {
                if (res?.payload?.error) {
                    setFlowList([])
                } else {
                    setFlowList(res?.payload?.data?.data)
                }
            })
    }, [])// eslint-disable-line
    useEffect(() => {
        if (values?.flow && values?.flow[0]?.flow_name) {
            const matchedFlow: any = flowList?.find((res: any) => res?.name === values?.flow[0]?.flow_name);
            if (matchedFlow) {
                setFieldValue(`${name}.0.flow_id`, matchedFlow?.meta_id);
                setFieldValue(`${name}.0.navigate_screen`, matchedFlow?.template_flow?.screens[0]?.id);
            }
        }
    }, [values?.flow && values?.flow[0]?.flow_name, flowList, setFieldValue, name]); // eslint-disable-line
    return (
        <Container>
            <p className='heading'>Flow</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ margin: "16px 8px 11px" }}>
                    <p className='heading'>Select Flow       :-</p>
                </div>

                <AppFormSelectField
                    label=""
                    Options={flowList}
                    name={`${name}.${0}.flow_name`}
                    disabled={disabled}
                    placeholder="Select URL type"
                    style={{ height: "40px" }}
                    divStyle={{ width: "55.5rem" }}
                />
            </div>
            <AppFormField name={`${name}.${0}.text`}
                placeholder="Flow Button Name"
                disabled={disabled}
                charactersLimit={200} />
        </Container >
    )
}

export default FlowButton;

const Container = styled.div`
&&{
    display:flex;
    flex-direction:column;
    justify-content:start;
    .heading{
        font-size: 15px;
        display: block;
    }
    .sub_heading{
        font-size: 15px;
        font-weight: 400;
        margin: 0;
        color: rgba(126, 131, 146, 1);
    }
}`