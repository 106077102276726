import { Chatbot } from './../../../Models';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE: any = {
  bot_id: "",
};

const sliceBotId = createSlice({
  name: "currentBot",
  initialState: INITIAL_STATE,
  reducers: {
    ChangeSelectedBot(state, { payload }: PayloadAction<Chatbot>) {
      return payload;
    },
  },
});

export default sliceBotId.reducer;
export const { ChangeSelectedBot } = sliceBotId.actions;

export const useCurrentBot = (state: any) => {
  return state.userData.dashboardData.currentBot as Chatbot;
};
