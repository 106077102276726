import { RootState } from "../..";



export const getAllSegmentList = (state:RootState) => state.segment.data.getAllSegmentData;

export const getAllSegmentConatctList = (state:RootState) => state.segment.data.allContactListData;

export const getAllSegmentListStore = (state:RootState) => state.segment.data;

export const deleteSegmentData = (state:RootState) => state.segment.data.deletedSegmentData;

export const createSegmentData = (state:RootState) => state.segment.data.createSegmentData;

export const segmentDataCount = (state:RootState) => state.segment.data.segmentDataCount;

export const segmentContactsEdit = (state:RootState) => state.segment.data.getAllSegmentConatctData
