import http from "../../../NetworkServices/http";


let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

// Services Functions
export class FormNetworkService {

  static createForm(data: any) {
    console.log(data);
    return http.post(`${consoleBaseUrl}${Endpoints.CREATE_FORM}`, {
      ...data,
    });
  }

  static getForm(formId: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_FORM}?form_id=${formId}`);
  }

  static updateForm(payload: any) {
    let { data, id } = payload
    return http.put(`${consoleBaseUrl}${Endpoints.CREATE_FORM}?form_id=${id}`, {
      ...data,
    });
  }

  static getFormsListing(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.GET_FORMS}`;
    return http.get(url, { params });
  }

  static deleteForm(formId: any) {
    return http.delete(`${consoleBaseUrl}${Endpoints.CREATE_FORM}?form_id=${formId}`);
  }

  static getAddContactsDropDown(payload: any) {
    const { parentId, selectedId } = payload;
    if (!selectedId) {
      return http.get(
        `${consoleBaseUrl}${Endpoints.GET_ADDCONTACT_DROPDOWN}/${parentId}/options`,
        payload
      );
    }
    else {
      return http.get(
        `${consoleBaseUrl}${Endpoints.GET_ADDCONTACT_DROPDOWN}/${parentId}/options?is_dependant_field=true&selection=${selectedId}`,
        payload
      );
    }
  }

}

class Endpoints {
  static CREATE_FORM = "/workspace/forms";
  static GET_FORM = "/workspace/forms";
  static GET_FORMS = "/workspace/form-listing";
  static GET_ADDCONTACT_DROPDOWN = "/workspace/contact-properties/field";
}
