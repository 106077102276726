import AppButton from "../../AppButton";

export const CustomQueryRuleButton = (props: any) => {
  return (
    <AppButton
      variant="grey"
      onClick={(e: any) => props?.handleOnClick(e)}
      title={props?.label ?? "Add Rule"}
    >
      {props?.label ?? "Add Rule"}
    </AppButton>
  );
};
