import React, { ReactNode, useMemo } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useAppSelector } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { isEmptyObject } from "../../Utils";
import SidebarAccordionMenu from "./SidebarAccordionMenu";
import SidebarMenu from "./SidebarMenu";

interface Props {
  documentTitle?: string;
  sidebarTitle?: string;
  sidebarMenuOptions?: any[];
  menuOptionsKeyName?: string;
  menuDisplayType?: "accordion" | "menu";
  outletCommonHeader?: ReactNode;
  Logo?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > | null;
  dynamicRouteSlug?: string;
  layoutWithDiv?: boolean;
}

const SidebarLayoutWrapper: React.FC<Props> = ({
  documentTitle = "Geta",
  menuDisplayType = "accordion",
  sidebarTitle,
  sidebarMenuOptions,
  menuOptionsKeyName,
  outletCommonHeader,
  Logo,
  dynamicRouteSlug,
  layoutWithDiv = false,
}) => {
  useDocumentTitle(documentTitle);
  const { userConfig }: any = useAppSelector(useAuthStore);
  const memoizedMenuOptions = useMemo(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;

    const result = menuOptionsKeyName
      ? userConfig?.payload?.menu_items?.[menuOptionsKeyName]
      : sidebarMenuOptions ?? [];
    return result;
  }, [menuOptionsKeyName, sidebarMenuOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (memoizedMenuOptions?.length === 0) return null;

  return (
    <StyledLayoutWrapper className="sidebarLayoutWrapper">
      {menuDisplayType === "menu" ? (
        <SidebarMenu
          Options={memoizedMenuOptions}
          Logo={Logo}
          sidebarTitle={sidebarTitle}
          dynamicRouteSlug={dynamicRouteSlug}
        />
      ) : (
        <SidebarAccordionMenu Options={memoizedMenuOptions} />
      )}
      <MainBox>
        {outletCommonHeader && (
          <div className="innderLayout">{outletCommonHeader}</div>
        )}
        {layoutWithDiv ? (
          <div>
            <Outlet />
          </div>
        ) : (
          <Outlet />
        )}
      </MainBox>
    </StyledLayoutWrapper>
  );
};

const StyledLayoutWrapper = styled.div`
  && {
    display: flex;
    width: 100%;
    overflow: hidden;
    background-color: ${theme.palette.default.greyishWhite};
    position: relative;
    min-height: 100%;
    align-items: stretch;
    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
  }
`;

const MainBox = styled.div`
  && {
    width: 100%;
    overflow: auto;
    flex: 1;
    // > div {
    //   height: auto !important;
    //   padding: 0 10px 0 10px;
    //   margin-top: 10px;
    // }
  }
`;

export default React.memo(SidebarLayoutWrapper);
