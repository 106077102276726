import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../../Customization/Theme";
import { StyledUserChatAddNotesWrapper } from ".././UsersDetailStyles";
import {
  AppForm,
  AppFormFastField,
  AppFormObserver,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { add } from "date-fns";
import { loginSchema } from "../../../../../Models/ValidationSchema";
import * as Yup from "yup";
import AppButton from "../../../../../AppComponents/AppButton";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import {
  allMessagesStore,
  setChatUserNotesData,
} from "../../../../../Store/Slices/socket/AllMessagesSlice";

export const addUserNoteSchema = Yup.object().shape({
  noteInput: Yup.string().min(1).required().label("Note"),
});
interface Props {}
interface Note {
  id: number;
  content: string;
}
const UserChatAddNotes: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const { userNotesData } = useAppSelector(allMessagesStore);
  // const [notes, setNotes] = useState<Note[]>([]);

  const [editNoteId, setEditNoteId] = useState<number | number | null>(null);
  const [userInput, setUserInput] = useState<any>({
    noteInput: "",
  });
  const [formikProps, setFormikProps] = useState<any>(null);
  const { values, errors }: any = formikProps || {};

  const handleAddNote = (userValue: string) => {
    if (userValue?.trim() !== "") {
      const newNote: Note = {
        id: Date.now(),
        content: userValue,
      };
      dispatch(setChatUserNotesData([...userNotesData, newNote]));
      // setNotes([...notes, newNote]);
    }
  };

  const handleEditNote = (item: any) => {
    setEditNoteId(item?.id);
    formikProps?.setFieldValue("noteInput", item?.content || "");
  };

  const handleUpdateNote = (text: any, editNoteId: string | number) => {
    const updatedNotes = userNotesData?.map((note: any) =>
      note.id === editNoteId ? { ...note, content: text } : note
    );
    dispatch(setChatUserNotesData(updatedNotes));
    setEditNoteId(null);
  };

  const handleDeleteNote = (id: number) => {
    dispatch(
      setChatUserNotesData(
        userNotesData?.filter((note: any) => note?.id !== id)
      )
    );
    formikProps?.setFieldValue("noteInput", "");
    setEditNoteId(null);
  };

  const handleSubmitBtn = (values: any, submitProps: any) => {
    submitProps.setSubmitting(false);

    if (values?.noteInput?.trim() === "") return;

    handleAddNote(values?.noteInput);

    submitProps.resetForm();
  };
  const handleUpdateBtn = (
    values: any,
    submitProps: any,
    editNoteId: string | number
  ) => {
    submitProps.setSubmitting(false);

    if (values?.noteInput?.trim() === "") return;

    handleUpdateNote(values?.noteInput, editNoteId);

    submitProps.resetForm();
  };

  return (
    <StyledUserChatAddNotesWrapper>
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          editNoteId
            ? handleUpdateBtn(values, submitProps, editNoteId)
            : handleSubmitBtn(values, submitProps);
        }}
        // validationSchema={addUserNoteSchema}
      >
        <Box className="addNote__wrapper">
          {/* <AppFormFastField name="noteInput" /> */}
          <AppFormTextArea
            name="noteInput"
            placeholder={"I have made an appointment for tomorrow's  meeting."}
            disabled={true}
          />
          <AppFormObserver setFormikProps={setFormikProps} />
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "-0.5rem",
            }}
          >
            {editNoteId ? (
              <AppButton
                variant="danger"
                onClick={() => {
                  setEditNoteId(null);
                  formikProps?.setFieldValue("noteInput", "");
                }}
              >
                Cancel
              </AppButton>
            ) : null}

            <AppSubmitButton
              className="addupdateBtn"
              title={editNoteId ? "Update Note" : "Add Note"}
              disabled={true}
            />
          </div>
        </Box>
      </AppForm>
      <List>
        {userNotesData?.map((note: any, index: number) => (
          <ListItem key={index}>
            <ListItemText primary={note?.content} />
            <ListItemSecondaryAction>
              <IconButton
                onClick={() => handleEditNote(note)}
                disabled={editNoteId === note?.id ? true : false}
              >
                <AppMaterialIcons
                  iconName="Edit"
                  style={{ fontSize: "1rem" }}
                />
              </IconButton>
              <IconButton onClick={() => handleDeleteNote(note?.id)}>
                <AppMaterialIcons
                  iconName="Delete"
                  style={{ fontSize: "1rem" }}
                />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </StyledUserChatAddNotesWrapper>
  );
};

export default UserChatAddNotes;
