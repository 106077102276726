import { alpha } from "@material-ui/core/styles";
import styled from "styled-components";
import { StyledDialog } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";

export const LeftMenuWrap = styled.div`
  && {
    // background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    padding: 0.7rem 8px 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
  }
  img {
    width: 36px;
    height: 36px;
    cursor: pointer;
  }
  .leftTopMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    .menuItem {
      .menuButton {
        padding: 12px;
        border-radius: 4px;
      }
    }
  }
  .leftMenuWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1rem;
  }
`;

export const MenuStyles = styled.div`
  position: absolute;
  top: 65px;
  left: 5rem;
  border: 2px solid white;
  background: red;
  transform: translateY(-50%);
  background: #211c52;
  width: 200px;
  padding: 12px 0;
  border-radius: 8px;
  z-index: 99999;
  svg {
    max-width: 13px;
    max-height: 13px;
    margin-right: 5px;
  }
  .listItem {
    padding: 6px 12px;
    font-size: 14px;
    line-height: 21px;
    color: ${alpha(theme.palette.default.white, 1)};
    display: flex;
    justify-content: flex-start;
    &:hover,
    .active {
      color: ${alpha(theme.palette.default.white, 1)};
    }
  }
`;

export const Wallet = styled.div`
  & {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    color: ${theme.palette.default.darkGrey};
    .creditsTitle {
      width: 15rem;
    }
    p span.credits {
      color: ${theme.palette.default.black};
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export const StyledWalletDialog = styled(StyledDialog)`
  .walletHeader {
  }
  .walletTitle {
    color: #101010;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    .walletInput {
      width: 500px !important;
      margin: 13px 0px !important;
    }
  }
  .walletValue {
    border-radius: 33px !important;
    border: 1px solid #c5cfff !important;
    background: #eef1ff !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #5b73e8 !important;
    margin: 0px 4px !important;
  }
  .walletCondition {
    border-radius: 4px;
    border: 1px solid #cbcdd3;
    padding: 3px 11px;
  }
  .walletValues {
    display: flex;
  }
`;
export const BroadcastText = styled.p`
  color: #101010;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 20px 0px 12px;
`;
export const BroadcastInfo = styled.div`
  color: #7e8392;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  display: flex;
`;
export const actionIson = {
  fontSize: "10px",
  margin: "10px 5px",
};
