import React from "react";
import useRolesPermissionsAccess from "../../../Hooks/useRolesPermissionsAccess";
import AppButton from "../../AppButton";

interface AppButtonProps {
  variant?: string;
  startIcon?: string;
  endIcon?: string;
  iconColor?: any;
  specificPermissionKey?: "create" | "read" | "update" | "delete";
  iconHoverColor?: any;
  iconSize?: any;
  width?: any;
  [otherProps: string]: any;
  children?: React.ReactNode;
  autoFocus?: boolean;
  onClick?: any;
  moduleId: string; // it is compulsory to pass moduleId
}

const ProtectedAppButton: React.FC<AppButtonProps> = ({
  children,
  moduleId,
  specificPermissionKey,
  ...otherProps
}) => {
  const { isValidAccess, isContentReady } = useRolesPermissionsAccess(moduleId, specificPermissionKey);

  // React.useEffect(() => { }, [isValidAccess]);

  if (!isContentReady) return null; // using this approach to avoid flickering of 404 page

  if (!moduleId) return null;

  if (!isValidAccess) return null;

  return <AppButton {...otherProps}>{children}</AppButton>;
};

export default ProtectedAppButton;
