import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserQueryNetworkService } from "./UserQueryNetworkService";

export class FaqUserQueryAction {
  // Listing Of Answered
  static GeteAnsweredListing = createAsyncThunk(
    "FaqSourceSlice/GeteAnsweredListing",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.getAnsweredListing(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //  Answered flow dropdown data
  static GeteAnsweredFlowOptions = createAsyncThunk(
    "FaqSourceSlice/GeteAnsweredFlowOptions",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.getAnsweredFlowOption(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //  Answered faq question dropdown data
  static GeteAnsweredFaqQuestionOption = createAsyncThunk(
    "FaqSourceSlice/GeteAnsweredFaqQuestionOption",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.getAnsweredFaqQuestionOption(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static UpdateNotAnswered = createAsyncThunk(
    "FaqSourceSlice/UpdateNotAnswered",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.updateNotAnswered(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  // send suburl id in every check
  static SubmitAnsweredData = createAsyncThunk(
    "FaqSourceSlice/SubmitAnsweredData",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.submitAnsweredData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static UpdateFlow = createAsyncThunk(
    "FaqSourceSlice/UpdateFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.updateFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Delete  By Conversation ID's
  static DeleteConversationByIds = createAsyncThunk(
    "FaqSourceSlice/DeleteSourceByIds",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.deleteConversationById(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );

  // Delete Source By ID's
  static DeleteFaqsByIds = createAsyncThunk(
    "FaqSourceSlice/DeleteFaqsByIds",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.deleteFaqsById(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );

  // Delete Not Answered Question By Conversation ID's
  static DeleteNotAnsConversationByIds = createAsyncThunk(
    "FaqSourceSlice/DeleteNotAnsConversationByIds",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.deleteNotAnsConversationById(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );

  // Remove By Conversation ID's
  static RemoveNotAnsConversationByIds = createAsyncThunk(
    "FaqSourceSlice/DeleteNotAnsConversationByIds",
    (data: any, { dispatch, rejectWithValue }) => {
      return UserQueryNetworkService.removeNotAnsConversationById(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );
}
