import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const TemplateListWrapper = styled.div`
  && {
       width: 100%;
    // height:calc(93% - 0px);
    overflow: scroll;
  }
`;

export const TemplateTable = styled.div`
  && {
    position: relative;
    .table-wrapper {
      .tabel-heading {
        h6 {
          font-size: 1rem;
          font-weight: 500;
          font-family: ${theme.typography.fontFamily};
        }
      }

      .pending {
        color: ${theme.palette.default.text};
        font-weight: 400;
      }
      .rejected {
        color: ${theme.palette.default.error};
        font-weight: 400;
      }
      .success {
        color: ${theme.palette.default.success};
        font-weight: 400;
      }
      .align-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .table-head {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .first-element {
        padding-left: 16px;
      }
      .table-content {
        height: calc(100vh - 375px);
        overflow-y: auto;
      }
    }
  }
`;

export const ErrorComponent = styled.div`
  && {
    border: 1px solid rgba(126, 131, 146, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 1rem 0rem;
    height: calc(100% - 48px);
    /* height: 60vh; */
    /* margin-top: 2rem; */
    .err-img {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 80%;
      height: auto;
    }
    .error-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .err-content {
      gap: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .error-Info {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h6 {
        color: ${theme.palette.default.black};
      }
    }
    .err-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      p {
        color: ${theme.palette.default.darkGrey};
      }
    }
  }
`;
