import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppForm, AppSubmitButton } from "../../../AppComponents/Forms";
import AppButton from "../../../AppComponents/AppButton";
import { ReactComponent as SaveIcon } from "../../../assets/images/save-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { getAddToList } from "../../../Store/Slices/Contacts/contacts.selector";
import { BulkAddTolList } from "../../../Models/ValidationSchema";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../HOC/HocBackdropLoader";
interface Props extends HocBackdropLoaderProps {
  closeModal: any;
  payload: any;
  SubmitReset?: any;
}
const AddToList: React.FC<Props> = ({
  closeModal,
  payload,
  SubmitReset,
  setLoading,
}) => {
  // props
  // const { closeModal, payload, SubmitReset } = props;
  let payLoad = payload;
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // form name key
  const formkey9 = "addtolist";
  // tag list data from redux
  const TagsList = useAppSelector(getAddToList);

  // states
  const [userInput, setUserInput] = useState({ tags: [] });
  const [taglistData, setTagListData] = useState<any[]>([]);

  // get list data
  useEffect(() => {
    getAddTolist();
    return () => {
      setTagListData([]);
    }; // eslint-disable-line react-hooks/exhaustive-deps
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // convert object
  useEffect(() => {
    if (TagsList && TagsList?.length && taglistData?.length === 0) {
      TagsList.forEach((el: any) => {
        let a = {
          value: el.id,
          label: el.name,
        };
        setTagListData((prev: any) => [...prev, a]);
      });
    }
  }, [TagsList]);
  const getAddTolist = () => {
    setTagListData([]);
    dispatch(ContactsActions.getAddToList({}))
      .then(() => {})
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error",
        );
      })
      .finally(() => {});
  };

  // post call for bulk add tags
  const handleSubmitBtn = (values: any, submitProps: any) => {
    submitProps.setSubmitting(true);
    payLoad["id"] = values.addtolist.value;
    setLoading(true);
    dispatch(ContactsActions.addToList(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.error || "Contact list not added",
            "error",
          );
          submitProps.setSubmitting(false);
        } else {
          submitProps.setSubmitting(false);
          setUserInput({ tags: [] });
          showAlert(
            res?.payload?.data?.msg || "Contact list  added",
            "success",
          );
          setLoading(false);
          SubmitReset();
        }
      })
      .catch((error: any) => {
        submitProps.setSubmitting(false);
        showAlert(
          error?.response?.statusText || "Contact list not added",
          "error",
        );
      })
      .finally(() => {
        setLoading(false);
        delete payLoad.id;
        submitProps.setSubmitting(false);
      });
  };

  return (
    <Box
      sx={{
        minWidth: "671px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "1rem",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", fontSize: "20px" }}>Add to list</span>
        <CloseIcon
          onClick={() => closeModal()}
          sx={{
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider sx={{ my: 0.5 }} />
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
          setUserInput(values);
        }}
        validationSchema={BulkAddTolList}
        divStyle={{
          gap: 0
        }}
      >
        <Box sx={{ flex: 1, p: 2 }}>
          <AppReactSelectField
            menuPosition="fixed"
            label="Contact List"
            isMulti={false}
            name={formkey9}
            valueKey="value"
            options={taglistData}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
            gap: "6px",
          }}
        >
          <AppButton
            style={{ width: "115px" }}
            variant="outline"
            startIcon="add"
            onClick={() => closeModal()}
          >
            Cancel
          </AppButton>
          <AppSubmitButton title="Add To List">
            <SaveIcon />
          </AppSubmitButton>
        </Box>
      </AppForm>
    </Box>
  );
};
export default HocBackdropLoader(AddToList);
