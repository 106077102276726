import { Paper } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const RolesPermissionWrapper = styled.div`
  & {
    width: 100%;
    padding: 1rem;
    padding-top: 0;
    height: 100%;
    .footer-button {
      width: 100%;
      margin: auto;
      background-color: ${theme.palette.default.white};
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0.5rem 1rem 0.5rem 0rem;
      justify-content: flex-end;
    }
  }
`;
export const RolesWrapper = styled.div`
  & {
    .footer-pagination {
      .MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
        margin-left: 0px !important;
      }
    }
    .link {
        color: ${theme.palette.default.blue};
        text-decoration: underline;
        cursor: pointer;
    }
  }
`;

export const UsersContainer = styled(Paper)`
  width: 100%;
  height: calc(100vh - 13rem);
  overflow-x: hidden;
  background-color: ${theme.palette.default.greyishWhite} !important;
`;

export const AdminContainer = styled(Paper)`
  height: calc(100vh - 193px);
  overflow-x: auto;
  margin: 15px 15px 0;
`;

export const RolePermissionTable = styled.div`
  .table-head {
    width: 100%;
    border-bottom: 1px solid ${theme.palette.default.border};
    display: flex;
    .table-col {
      max-width: 250px;
      min-width: 250px;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      display: flex;
      align-items: center;
      h4 {
        font-weight: 500;
        font-size: 14px;
        padding-left: 21px;
      }
    }
  }

  .table-content-row {
    display: flex;
    justify-content: space-between;
    .table-data {
      display: flex;
      .table-col {
        max-width: 250px;
        min-width: 250px;
        padding: 0.625rem;
        font-size: 0.875rem;
        h5 {
          font-size: 0.875rem;
        }

        .name-role {
          display: flex;
          gap: 0.5rem;
          h5 {
            font-weight: 500;
            font-size: 14px;
            padding-left: 21px;
          }
          .link {
            color: ${theme.palette.default.blue};
            text-decoration: underline;
            cursor: pointer;
          }
          p {
            color: ${theme.palette.default.blue};
          }
        }
      }
    }
    .table-col {
      max-width: 200px;
      padding: 0.3rem;
      display: flex;
      align-items: center;
      &:first-child {
        min-width: 200px;
      }
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export const RolePermissionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: ${theme.palette.default.white};
  .response-header__left {
    .arrow {
      height: 20px;
      width: 12px;
    }
    .heading {
    }
    h4 {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .response-header__right {
    display: flex;
    align-items: center;
    gap: 15px;

    input {
      border: 1px solid #cbcdd3 !important;
    }
  }
`;
