// vendors
import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import parsePhoneNumber from "libphonenumber-js";

// components
import { UIButton, Input, Icon } from "./../../../ui-components";

const CallNumber = ({
  onSaveClick,
  activeButtonData,
  onCancelClick,
  language,
}: any) => {
  const [value, setValue] = useState<string>("");
  const [phoneValue, setPhoneValue] = useState<string>("");
  const [isPhoneNumberValid, setPhoneNumberValidity] = useState<boolean>(false);

  const checkPhoneNumberValidity = (phoneNumber: string) => {
    const phoneNumberValidity = parsePhoneNumber(phoneNumber, "IN");
    if (phoneNumberValidity) {
      return phoneNumberValidity.isValid();
    } else {
      return false;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneValue(event.target.value);
    setPhoneNumberValidity(checkPhoneNumberValidity(event.target.value));
  };

  const handleSaveClick = () => {
    let updatedAction = activeButtonData;
    if (
      activeButtonData.value.url === phoneValue &&
      updatedAction.value.text === value
    ) {
      return false;
    }
    updatedAction.type = "mobile";
    updatedAction.value.url = phoneValue;
    updatedAction.value.text = value;
    onSaveClick && onSaveClick(updatedAction);
  };

  const handleCancelClick = () => {
    onCancelClick && onCancelClick();
  };

  useEffect(() => {
    if (activeButtonData && activeButtonData.type == "mobile") {
      setPhoneValue(activeButtonData.value.url);
      setValue(activeButtonData.value.text);
      setPhoneNumberValidity(
        checkPhoneNumberValidity(activeButtonData.value?.url || ""),
      );
    }
  }, [activeButtonData]);
  return (
    <>
      <Box sx={{ px: "16px" }}>
        <UIButton fullWidth color="success" variant="outlined">
          Call Number
        </UIButton>
      </Box>
      <Box sx={{ p: "16px" }}>
        <Box>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Button title
          </Typography>
          <Input
            placeholder="Title"
            onChange={handleChange}
            value={value}
            error={value === ""}
          />
        </Box>
        <Box sx={{ pt: "16px" }}>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "12px",
              lineHeight: "18px",
              color: "#7E8392",
            }}
          >
            Phone
          </Typography>
          <Input
            placeholder="Phone Number"
            onChange={handlePhoneChange}
            value={phoneValue}
            error={phoneValue !== "" && !isPhoneNumberValid}
          />
        </Box>
      </Box>
      <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleCancelClick}>
            Cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" color="#fff" size={13} />}
            disabled={
              language !== "english" || !isPhoneNumberValid || value === ""
            }
            onClick={handleSaveClick}
          >
            Save
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
export default CallNumber;
