// vendors
import { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";

// components
import UIButtonGroup from "../../Components/ButtonGroup";
import Icon from "../../Components/Icon";
import NodeType from "../../Components/NodeType";
import ReOrder from "../../Components/Reorder";

// utils
import { getIcon } from "./helper";

// slices
import { getActiveNode } from "../../Slices/main.slice";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

type Props = {
  activeButton: number | null;
  buttonList: any;
  onButtonClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    buttonIndex: number,
  ) => void;
  onDelete: (activeButton: any, index: number) => void;
  minLimit?: number | null;
  onOrderChange: (buttonList: any) => void;
};

// @ts-ignore
Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
  return this;
};

const EditableButton = (props: Props) => {
  const {
    activeButton,
    buttonList,
    onButtonClick,
    onDelete,
    onOrderChange,
    minLimit,
  } = props;
  const activeNodeId = useSelector(getActiveNode);
  const [buttonsList, updateButtonsList] = useState(buttonList);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [nActiveButton, updateActiveButton] = useState(null);
  const [deleteButtonIndex, updateDeleteButtonIndex] = useState<number>(-1);

  useEffect(() => {
    updateButtonsList(buttonList);
  }, [buttonList]);

  useEffect(() => {
    if (activeButton !== null) {
      const button = document.getElementById(
        `button-${activeNodeId}-${activeButton}`,
      );

      if (button) {
        setTimeout(() => {
          button.click();
        }, 100);
      }
    }
  }, [activeButton]);

  const handleDeleteButtonClick = (button: any, index: number) => {
    updateActiveButton(button);
    setShowConfirmationPopup(true);
    updateDeleteButtonIndex(index);
  };

  const handleModalClose = () => {
    updateDeleteButtonIndex(-1);
    setShowConfirmationPopup(false);
  };

  const renderButtons = (button: any) => {
    const index = buttonsList.findIndex(
      ({ id }: { id: string }) => id === button.id,
    );
    const type = button?.value?.flow_type
      ? button?.value?.flow_type
      : button.type;
    const stage =
      button.type === "flow" && button?.value?.stage
        ? button?.value?.stage
        : "";
    const icon = getIcon(type);

    return (
      <ButtonWrapper key={`button-${button.id}-${index + 1}`}>
        <UIButtonGroup
          id={`button-${button.id}-${index}`}
          fullWidth
          withOptions
          onClick={(event) => onButtonClick(event, index)}
          icon={icon}
        >
          <Box sx={{ flex: 1, display: "flex" }}>
            <Tooltip title={button.value.text}>
              <Typography sx={{ flex: 1, cursor: "pointer" }}>
                {`${button.value?.text?.slice(0, 18).trim()}...`}
              </Typography>
            </Tooltip>
            {stage && (
              <Typography
                component={"span"}
                sx={{
                  marginLeft: "10px",
                  fontStyle: "italic",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "20px",
                  backgroundColor: "rgb(117 124 232 / 38%)",
                  color: "#3654E3",
                  p: "1px 5px",
                  borderRadius: "4px",
                }}
              >
                {`Stage ${stage}`}
              </Typography>
            )}
          </Box>
        </UIButtonGroup>
        <IconButton
          sx={{
            height: "36px",
            width: "36px",
            border:
              buttonList.length === minLimit
                ? "1px solid #c7c7c9"
                : "1px solid rgba(54, 84, 227, 0.5)",
            borderRadius: "4px",
            p: "10px",
            "&.Mui-disabled": {
              color: "#c7c7c9",
            },
          }}
          color="primary"
          size="large"
          onClick={() => handleDeleteButtonClick(button, index)}
          disabled={buttonList.length === minLimit}
        >
          <Icon icon="delete" size={16} />
        </IconButton>
      </ButtonWrapper>
    );
  };

  const handleOrderChange = (item: any) => {
    if (!isEqual(item, buttonsList)) {
      updateButtonsList(item);
      onOrderChange(item);
    }
  };

  return (
    <>
      <ReOrder
        items={buttonsList}
        onChange={handleOrderChange}
        renderItem={(item) => (
          <ReOrder.Item id={item.id}>
            <ReOrder.DragHandle />
            {renderButtons(item)}
          </ReOrder.Item>
        )}
      />
      <Modal
        className="ignore-onClickOutside"
        open={showConfirmationPopup}
        onClose={handleModalClose}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              flexDirection="row"
              sx={{
                p: "16px",
                borderBottom: "1px solid #F5F6F8",
                width: "100%",
              }}
              justifyContent="space-between"
            >
              <NodeType text="Confirm" noPadding />
              <IconButton size="small" onClick={handleModalClose}>
                <CloseIcon sx={{ fontSize: "24px" }} />
              </IconButton>
            </Stack>
          </Box>
          <Box sx={{ px: "16px", py: "8px" }}>
            <Typography
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
              sx={{ color: "#7E8392", pb: "4px" }}
            >
              Do you want to delete the button?
            </Typography>
          </Box>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ py: "8px", px: "16px", borderTop: "1px solid #F5F6F8" }}
          >
            <Button variant="outlined" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                onDelete && onDelete(nActiveButton, deleteButtonIndex);
                handleModalClose();
              }}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default EditableButton;

const ButtonWrapper = styled(Box)`
  && {
    margin: 4px 0;
    display: flex;
    align-items: center;
    gap: 8px;
    flex: 1;
    button {
      background-color: ${theme.palette.default.white};
    }
  }
`;
