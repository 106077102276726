import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

interface Props {
  userCreditsInfo: any;
  isUserAvailable?: boolean;
}

const INITIAL_STATE: Props = {
  userCreditsInfo: null,
  isUserAvailable: false,
};

const CurrentUserSlice = createSlice({
  name: "CurrentUserSlice",
  initialState: INITIAL_STATE,
  reducers: {
    // setUserGroupId(state, { payload }: PayloadAction<any>) {
    //   state.groupId = payload;
    //   let tempPayload = String(payload);
      
    //   if(tempPayload === '1'){
    //     state.currentUserRole = 'admin';
    //     state.isUserSuperAdmin = true;
    //     state.isUserBotAdmin = true;
    //   }
    //   if(tempPayload === '2'){
    //     state.currentUserRole = 'bot-admin';
    //     state.isUserBotAdmin = true;
    //   }
    // },
    setUserCreditsInfo(state, { payload }: PayloadAction<any>) {
      let temp :any ={
        ...payload,
        remaining_amounts: payload?.remaining_amounts || payload?.remaining_amount ? parseFloat(payload?.remaining_amounts || payload?.remaining_amount).toFixed(2) : 0,
      }
      state.userCreditsInfo = temp;
    
    },

    setIsUserAvailable(state, { payload }: PayloadAction<any>) {
      state.isUserAvailable = payload;
    },
  },
});

export default CurrentUserSlice.reducer;

export const {  setUserCreditsInfo, setIsUserAvailable } = CurrentUserSlice.actions;

export const useCurrentUser = (state: RootState) => state.currentUser;
