import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";


interface BreadcrumbsItemProps {
    iconColorCondition: boolean;
}

export const EmailMainBoxTopbar = styled.div`
& {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: ${theme.palette.default.white};
    justify-content: space-between;
    padding: 1rem 1rem;
    height: 64PX;
    border-bottom: 1px solid ${theme.palette.default.grey};
    
    .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p.heading {
            font-size: 16px;
            color: ${theme.palette.default.black};
            font-weight: 500;
        }
    }

    .test-tamplate__btn {
        display: flex;
        gap: 0.5rem;
        min-width: 287px;
        .back-btn {
            

        }
    }
}
`;


export const EmailMainBoxStepperWrapper = styled.div`
& {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: ${theme.palette.default.white};
    justify-content: space-between;
    padding: 1rem 1rem;
    height: 64px;
    border-bottom: 1px solid ${theme.palette.default.grey}; 
}
`
export const BreadcrumbsItem = styled.div`
& {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0px 10px;
    .icon {
        display: flex;
        align-items: center;
        img {
            
        }

        svg path {
            fill: ${(props: any) => (props.iconColorCondition ? `${theme.palette.primary.light}` : `${theme.palette.default.darkGrey}`)};
          }

    }
    .text {
    display: flex;
    align - items: center;
        p {
        font-size: 14px;
        margin: 0;
        color:  ${(props: any) => (props.iconColorCondition ? `${theme.palette.primary.light}` : `${theme.palette.default.darkGrey}`)
    }
}
}
`;

export const EmailFilter = styled.div`
    & {
        position: relative;
        display: flex;
        align-items: center;
justify-content: space-between;
width: 100%;
// height: 90px;
// padding: 16px 16px 6px 16px;
padding-bottom: 18px;
background-color: ${theme.palette.default.white};
// box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
border-bottom: 1px solid ${theme.palette.default.border};




h4 {
    color: ${theme.palette.default.black};
    font-weight: 500;
    font-size: 0.875rem;
}
    .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 120px);
    gap: 0.5rem;
    // flex-grow: 1;
        .select-email-template {
            .dropdown-label {
                gap: 0;
                p {
                    margin-top: 4px;
                    margin-bottom: 0px;
                }
            }
        }

        .text {
            display: flex;
            align-items: center;
            height: 40px;
            h4 {
            color: ${theme.palette.default.black};
            font-weight: 500;
            font-size: 0.875rem;
            
        }
    }
}
    .right {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
        .btn-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    button.view {
        padding: 0;
    }
}
}
`;

