import styled from "styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box, Tooltip } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import {
  getHTMLContent,
  getInitialEditiorText,
} from "../../Components/FlowBuilder/nodes/utils/editor";
import { Editor } from "../../Components/FlowBuilder/ui-components";
import { theme } from "../../Customization/Theme";
import { getTextWithoutHtml } from "../../Utils";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  mediaKey: any;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormRichTextEditor: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  mediaKey,
  sendValuesToParent,
  children,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext<any>();

  // const [mediaInfo, setMediaInfo] = useState<any>({ file: { url: "" },
  // image: { url: "" },
  // video: { url: "" }});

  const [editorState, setEditorState] = useState<any>(
    getInitialEditiorText("")
  );

  //*do not remove this sending values to parent component
  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]);

  useEffect(() => {
    if (
      !getTextWithoutHtml(getIn(values, name)) ||
      getTextWithoutHtml(getIn(values, name))?.length == 0
    ) {
      setFieldValue(name, null);
    }
  }, []);

  useEffect(() => {
    if (getIn(values, name)) {
      setEditorState(getInitialEditiorText(getIn(values, name)));
    }
    else {
      setFieldValue(name, '');
    }

  }, [getIn(values, name)]);

  const onEditorStateChange = (editorState: any, mediaState: any) => {
    setEditorState(editorState);
    setFieldValue(mediaKey, mediaState);
    // setMediaInfo(mediaState)
  };

  

  const handleBlur = () => {
    setFieldValue(name, getHTMLContent(editorState) || '');
    setFieldTouched(name,true)
    // setFieldValue(mediaKey, mediaInfo);
  };

  if (typeof getIn(values, name) === "undefined") {
    setFieldValue(name, '');
  }

  return (
    <Div style={divStyle}>
      <Label>
        {label}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <Box className="customInput">

      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        hideFocusOnLoad={true}
        mediaInfo={getIn(values, mediaKey)}
        onBlur={handleBlur}
        />
        </Box>

      <AppErrorMessage error={getIn(errors, name)} visible={getIn(touched, name)} />
    </Div>
  );
};

export default AppFormRichTextEditor;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
