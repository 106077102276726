import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Store";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { APIKeyActions } from "../../Store/Slices/APIKey/apikey.actions";
import { getAPIKey } from "../../Store/Slices/APIKey/apikey.selectors";
import AppButton from "../../AppComponents/AppButton";
import useModal from "../../Hooks/useModel/useModel";
import { IconButton } from "@mui/material";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import AppModel from "../../Hooks/useModel/AppModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";

// Subscriptio Plan Page
const APIKeyGeneration = () => {
  const { showAlert } = useNotification();
  const ApiModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingApiModal: isShowing, toggleApiModal: toggle };
  };
  const { isShowingApiModal, toggleApiModal } = ApiModal();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [copyStatus, setCopyStatus] = useState<string>("Click to copy");

  const apiKey = useSelector(getAPIKey);

  const getGeneratedAPIKey = () => {
    dispatch(APIKeyActions.getAPIKey({}));
  };
  const generatedAPIKey = () => {
    dispatch(APIKeyActions.generatedAPIKey({}));
  };
  useEffect(() => {
    if (apiKey && isShowingApiModal) {
      showAlert("API Key Generated Sucessfully", "success");
      toggleApiModal();
    }
  }, [apiKey]);
  useEffect(() => {
    getGeneratedAPIKey();
  }, []);
  const handleCopy = () => {
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      setCopyStatus("Copied!");

      // Revert back to "Click to copy" after 2 seconds
      setTimeout(() => {
        setCopyStatus("Click to copy");
      }, 2000);
    }
  };
  const handleRegenerateKey = () => {
    generatedAPIKey();
  };
  return (
    <ContentMainBox>
      {/* <QuickGuideBox>
        <h3>Quick Guide</h3>
        <p>
          API key is the secret key used for authentication while making a
          request to our APIs.
        </p>
        <p>
          You just need to generate an API key once and use it for communicating
          with our APIs.
        </p>
      </QuickGuideBox> */}
      <APIKeyBox>
        <h3>API Key</h3>
        {apiKey ? (
          <textarea value={apiKey} readOnly rows={4} disabled={true} />
        ) : (
          <AppButton
            buttonStyle={{ margin: "0 auto" }}
            onClick={toggleApiModal}
          >
            Generate API Key
          </AppButton>
        )}
      </APIKeyBox>
      {apiKey && (
        <ButtonWrapper>
          <AppButton onClick={toggleApiModal}>Regenerate Key</AppButton>
          <AppButton variant="secondary" onClick={handleCopy}>
            {copyStatus}
          </AppButton>
        </ButtonWrapper>
      )}
      {/* Template Modal */}
      <AppModel
        isShowing={isShowingApiModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "700px",
          },
        }}
      >
        <ModalBodyStyles>
          <div className="crawl-popup">
            <div className="crawl-heading">
              <p>Generate API Key</p>
            </div>
            <div className="close-icon">
              <IconButton
                onClick={() => {
                  toggleApiModal();
                }}
              >
                <AppMaterialIcons iconName="Close" />
                {/* <FailedIcon /> */}
              </IconButton>
            </div>
          </div>

          <div className="upload-file">
            Are you sure you want to Generate a New API Key?
          </div>
          <div className="popup-buttons">
            <AppButton
              onClick={() => {
                toggleApiModal();
              }}
              variant="grey"
            >
              Cancel
            </AppButton>
            <AppButton onClick={handleRegenerateKey}>Generate Key</AppButton>
          </div>
        </ModalBodyStyles>
      </AppModel>
    </ContentMainBox>
  );
};
export default APIKeyGeneration;
const ContentMainBox = styled.div`
  && {
    width: 100%;
    height: 100%;

    overflow: hidden;
    padding: 30px;
  }
`;
const QuickGuideBox = styled.div`
  && {
    width: 100%;
    h3 {
      margin-bottom: 20px;
    }
  }
`;
const APIKeyBox = styled.div`
  && {
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background-color: ${theme.palette.secondary.main};
    h3 {
      margin-bottom: 20px;
    }
    textarea {
      background-color: rgb(234, 234, 234);
      width: 100%;
      padding: 10px;
      fontsize: 14px;
      borderradius: 4px;
      border: 1px solid #ccc;
      resize: none;
      display: block;
    }
  }
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const ModalBodyStyles = styled.div`
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.grey};
    align-items: center;
    padding: 12px 16px;

    p {
      color: #323232;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .header {
    margin-bottom: 55px;
  }

  h3 {
    line-height: 30px;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #f5f7ff;
    border: 1px solid #bfcaff;
    padding: 10px;
    margin-top: 16px;
    p {
      font-family: inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .input-field {
    margin-top: 1rem;
  }
  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .popup-buttons {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border-top: 1px solid ${theme.palette.default.grey};
  }

  .upload-file {
    display: flex;
    align-items: center;
    padding: 16px;
    .header {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  }

  .upload-file p {
    font-family: inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
`;
