import {
  Badge,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  debounce
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppButton from "../../../AppComponents/AppButton";
import { AppForm } from "../../../AppComponents/Forms";
import AppFormSearchField from "../../../AppComponents/Forms/AppFormSearchField";
import {
  SettingPageMainBoxBtnBox,
  SettingPageMainBoxTopbar,
} from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import useDocumentTitle from "../../../Hooks/useDocumentTitle";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  getFilterQuery,
  resetFilterQuery,
  selectedContactViewState,
  viewsListing
} from "../../../Store/Slices/Contacts/contacts.selector";
import BulkActionHeader from "../bulkActions/BulkActionHeader";
import CustomizedMenus from "../contactDetailedComponents/CustomizedMenus";
import AddContactsForm from "./AddContactsForm";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/system";
import styled from "styled-components";
import AppDrawer from "../../../AppComponents/AppDrawer";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import {
  ProtectedAppButton,
  ProtectedAppUiElement,
} from "../../../AppComponents/ProtectedComponents";
import { convertQueryToMongo } from "../../../AppComponents/QueryBuilder/utils/utils";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { resetContactsPaginationData, setFilterQuery } from "../../../Store/Slices/Contacts/contacts.slice";
import { checkMultipleModuleIdAccess } from "../../../Utils/rolesUtils";
import { CreateSegmentWrapper } from "../../Segment/CreateSegmentStyles";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import ViewsTab from "../views/views-tab";
import ExportConfirmation from "./ExportConfirmation";
import QueryBuilderForContacts from "./QueryBuilderForContacts";
// interface Props extends LoaderComponentProps { }

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme?.palette?.default?.newTextGrey,
    fontSize: "10px",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },

    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 15,
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        color: theme?.palette?.default?.blue,
        background: theme?.palette?.default?.GhostWhite,
      },
    },
  },
}));


const ContactHeaderFilter = (props: any) => {
  const {
    setShouldApply,
    contactHeaderMapping,
    setContactHeaderMapping,
    localValues,
    setLocalValues,
    setSelectedDocumentIds,
    selectedDocumentIds,
    availableFields,
    selectedFields,
    setSelectedFields,
    dynamicsColumn,
    setDynamicsColumn,
    toggleContactModal,
    isShowingContactModal,
    setSelectAll,
    selectAll,
    ChangeView,
    toggleDrawer,
    isDrawerOpened,
    AllContactPropertiesListdata,
    updateContactList,
    createImportDoc,
    leadRefresh,
    sortingObject
    // currentViewId,
  } = props;
  useDocumentTitle("Contacts");
  const retrievedValue = localStorage.getItem("grid_view") || null;
  const { showAlert } = useNotification();
  const filterQuery: any = useSelector(getFilterQuery);
  const resetFilterQueryData = useSelector(resetFilterQuery);
  // const dataRows = useAppSelector(ContactsListingData);
  const dispatch = useAppDispatch();
  const [selectedComponent, setSelectedComponent] = useState<any>(null);
  const selectedContactView:any = useAppSelector(selectedContactViewState);
  const allViewsData: any = useAppSelector(viewsListing);
  const { userConfig }: any = useAppSelector(useAuthStore);
  const [isMoreBtnAccessible, setIsMoreBtnAccessible] =
    useState<boolean>(false);

  useEffect(() => {
    if (userConfig?.payload?.allowed_permissions) {
      const res = checkMultipleModuleIdAccess(
        ["import_contacts", "export_contacts"],
        userConfig?.payload?.allowed_permissions,
      );
      setIsMoreBtnAccessible(res ?? false);
    }
  }, [userConfig]);

  const contacts = [
    { name: "All Contacts", id: "all_contacts" },
    { name: "My Contacts", id: "my_contacts" },
    { name: "Unassigned Contacts", id: "unassigned_contacts" },
  ];

  // search =======*******
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      search: e,
      currentPage: 0,
    });
  }, 800);
  const clearCallback = (e?: any, field?: any) => {
    if (e === "leads_search_term" || e === "") {
      setLocalValues({
        ...localValues,
        search: "",
      });
    }
  };



  // export button
  const handleExport = () => {
    // setLoading(true);
    const isFiltersApplied = filterQuery?.rules?.length > 0 ?? false;
    const mongodb_query = convertQueryToMongo(filterQuery);
    let payLoad: any = {
      selectAll: true,
      contactId_list: [],
      search: localValues?.search ?? "",
      columns: dynamicsColumn?.map((data: any) => data?.name),
      sort_by: sortingObject?.sort_by,
      sort_field: sortingObject?.sort_field
    };

    if (isFiltersApplied) {
      payLoad = {
        ...payLoad,
        filter_query: filterQuery,
        mongodb_query: mongodb_query,
      };
    }

    dispatch(ContactsActions.bulkExport(payLoad))
      .then((res: any) => {
        // setLoading(false);
        if (res?.error?.message === "Rejected") {
          showAlert(res?.error?.message || "Contact not exported", "error");
        } else {
          setSelectedComponent(true);
        }
      })
      .catch((error) => {
        showAlert("something went wrong", "error");
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  function Menus() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <AppButton
          onClick={handleClick}
          variant="outline"
          style={{
            width: "37px",
            minWidth: "37px",
          }}
          className="btn-grp"
        >
          <MoreVertIcon />
        </AppButton>

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
        >
          <ProtectedAppUiElement moduleId="export_contacts">
            <MenuItem
              onClick={() => {
                handleExport();
                handleMenuClose();
              }}
              disableRipple
              sx={{
                fontSize: "12px",
              }}
            >
              Export Contacts
            </MenuItem>
          </ProtectedAppUiElement>
          <ProtectedAppUiElement moduleId="import_contacts">
            <MenuItem
              onClick={() => {
                createImportDoc();
                handleMenuClose();
              }}
              disableRipple
              sx={{
                fontSize: "12px",
              }}
            >
              Import Contacts
            </MenuItem>
          </ProtectedAppUiElement>
        </StyledMenu>
      </div>
    );
  }

  //close confirmation modal
  const closeModal = () => {
    setSelectedComponent(null);
  };

  useEffect(() => {
    if(!selectedContactView) return;
    dispatch(setFilterQuery(selectedContactView?.filter_query));
    dispatch(resetContactsPaginationData({}));
    setLocalValues((prev: any) => ({
      ...prev,
      currentPage: 0,
      search: "",
      contact_filter:"",
      selected_view: selectedContactView,
    }));
  }, [selectedContactView]); // eslint-disable-line react-hooks/exhaustive-deps


  const getViewsListings = () => {
    dispatch(ContactsActions.getAllContactsViews({}));
  };

  useEffect(() => {
    getViewsListings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <SettingPageMainBoxTopbar
      style={{
        overflow: "visible",
        minHeight: "60px",
        // height: "110px",
        background: "#FFFF",
        boxShadow: "box-shadow: 0px 2px 10px 0px #00000014",
        position: "sticky",
        top: "0",
        zIndex: "2",
      }}
    >
      {selectedDocumentIds && selectedDocumentIds.length >= 1 ? (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "flex-start",
            "@media (max-width: 1199.98px)": {
              flexWrap: "wrap",
              gap: "5px",
              button: {
                width: "auto !important",
                fontSize: "11px !important",
                padding: "4px !important",
              },
            },
          }}
        >
          <BulkActionHeader
            selectedDocumentIds={selectedDocumentIds}
            setShouldApply={setShouldApply}
            setSelectAll={setSelectAll}
            selectAll={selectAll}
            setSelectedDocumentIds={setSelectedDocumentIds}
            localValues={localValues}
            leadRefresh={leadRefresh}
            dynamicColumn={dynamicsColumn}
            sortingObject={sortingObject}
          />
        </Box>
      ) : (
          <div style={{
            display: "flex", flexDirection: "column", gap: "1rem", height:"100%", width:"100%", paddingTop:"1.25rem"}}>

        <AppForm
          initialValues={{
            leads_search_term: localValues?.search,
            contact_filter: localValues?.contact_filter,
          }}
          divStyle={{ width: "100%" }}
          onSubmit={(values: any, submitProps: any) => {}}
        >
          <SettingPageMainBoxBtnBox
            style={{
              height: "100%",
              flex: "1",
              gap: "10px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              className="select"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
                  <ContactFilter className="test123">
                  <ProtectedAppUiElement moduleId="search_contacts">
                <AppFormSearchField
                  name="leads_search_term"
                  onChange={(e: any) => handleSelectSearch(e, "search")}
                  clearCallback={(e: any) => clearCallback(e, "search")}
                  placeholder="Search"
                  divStyle={{
                    width: "275px",
                    Height: "37px",
                    marginBottom: "0px",
                  }}
                        defaultBorder={true}
                        id="search_field"
                />
              </ProtectedAppUiElement>
                {/* <AppFormSelectField
                  Options={contacts}
                  valueKey="id"
                  label=""
                  name="contact_filter"
                  placeholder="Jhon"
                  onChange={(e) => {
                    setLocalValues((prev: any) => ({
                      ...prev,
                      contact_filter: e,
                      currentPage: 0,
                    }));
                  }}
                /> */}
              </ContactFilter>
              {/* {paginationCount?.totalRecords > 0
                  ? getRoundOffNumbers(paginationCount?.totalRecords)
                  : null} */}
            </div>
            <SettingPageMainBoxBtnBox>
              {/* <ProtectedAppUiElement moduleId="search_contacts">
                <AppFormSearchField
                  name="leads_search_term"
                  onChange={(e: any) => handleSelectSearch(e, "search")}
                  clearCallback={(e: any) => clearCallback(e, "search")}
                  placeholder="Search"
                  divStyle={{
                    width: "275px",
                    Height: "37px",
                    marginBottom: "0px",
                  }}
                  defaultBorder={true}
                />
              </ProtectedAppUiElement> */}

              <ProtectedAppButton
                moduleId="contacts_crud"
                specificPermissionKey="create"
                onClick={() => toggleContactModal()}
                variant="outline"
                startIcon="ri-add-line"
                style={{ padding: "6px 12px" }}
              >
                add contact
              </ProtectedAppButton>

              {/* view */}
              {/* <ButtonGroup
                variant="outlined"
                aria-label="outlined button group"
              >
                <AppButton
                  variant={retrievedValue !== "true" ? "primary" : "outline"}
                  onClick={() => ChangeView()}
                  className="btn-grp"
                  disableRipple={true}
                >
                  <TableViewIcon />
                </AppButton>
                <AppButton
                  variant={retrievedValue === "true" ? "primary" : "outline"}
                  onClick={() => ChangeView()}
                  className="btn-grp"
                  disableRipple={true}
                >
                  <KanBanIcon />
                </AppButton>
              </ButtonGroup> */}

              {retrievedValue !== "true" ? (
                <CustomizedMenus
                  availableFields={availableFields}
                  selectedFields={selectedFields}
                  setSelectedFields={setSelectedFields}
                  dynamicsColumn={dynamicsColumn}
                  setDynamicsColumn={setDynamicsColumn}
                  contactHeaderMapping={contactHeaderMapping}
                  setContactHeaderMapping={setContactHeaderMapping}
                  AllContactPropertiesListdata={AllContactPropertiesListdata}
                  updateContactList={updateContactList}
                />
              ) : null}
              <AppButton
                variant="outline"
                onClick={() => {
                  toggleDrawer();
                }}
                    className="btn-grp"
                    isDisabled={allViewsData?.length === 0}
                    
              >
                <Badge
                  variant={filterQuery?.rules?.length > 0 ? "dot" : "standard"}
                  max={5}
                  color="primary"
                  showZero={false}
                  className="btn-grp"
                >
                  <AppMaterialIcons
                    iconName="FilterList"
                    style={{
                      fontSize: "18px",
                      color: theme.palette.primary.dark,
                    }}
                  />
                </Badge>
              </AppButton>

              {/* query builder  filter */}
              <AppDrawer
                open={isDrawerOpened}
                onClose={() => undefined}
                sx={{
                  "& .MuiPaper-root": {
                    width: "65%",
                    minWidth: "950px",
                    "@media (max-width: 1199.98px)": {
                      minWidth: "100% !important",
                    },
                  },
                }}
              >
                <CreateSegmentWrapper style={{ height: "100%" }}>
                  <Box className="form-heading">
                    <Box>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            Edit View  ({
                              selectedContactView?.name
                        }) 
                      </Typography>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          toggleDrawer();
                          dispatch(setFilterQuery(resetFilterQueryData));
                        }}
                      >
                        <AppMaterialIcons iconName={"Close"} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Divider />
                  <QueryBuilderForContacts
                    setLocalValues={setLocalValues}
                    toggleDrawer={toggleDrawer}
                  />
                </CreateSegmentWrapper>
              </AppDrawer>

              {retrievedValue !== "true" && isMoreBtnAccessible ? (
                <Menus />
              ) : null}

              {/* Add contacts modal */}
              <AddContactsForm
                isShow={isShowingContactModal}
                toggle={toggleContactModal}
                setShouldApply={setShouldApply}
                leadRefresh={leadRefresh}
              />
              {/* export contact handling */}
              <ExportConfirmation
                onClose={closeModal}
                selectedComponent={selectedComponent}
              />

              <AppButton variant="outline" onClick={leadRefresh} isDisabled={allViewsData?.length === 0}>
                <AppToolTip
                  tooltipProps={{
                    title: "Refresh contact list.",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                >
                  <AppMaterialIcons
                    style={{ cursor: "pointer",color:theme.palette.primary.dark }}
                    iconName="Refresh"
                  />
                </AppToolTip>
              </AppButton>
            </SettingPageMainBoxBtnBox>
          </SettingPageMainBoxBtnBox>
            </AppForm>

      <ViewsTab/>

          </div>
      )}
    </SettingPageMainBoxTopbar>
  );
};

export default ContactHeaderFilter;

const ContactFilter = styled.div`
  && {
  }
`;
