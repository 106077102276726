import {
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../Customization/Theme";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../HOC/HocBackdropLoader";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { useCurrentUser } from "../../../../Store/Slices/CurrentUser/CurrentUserSlice";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { removeAllMessages } from "../../../../Store/Slices/socket/AllMessagesSlice";

import { LiveChatActions } from "../../../../Store/Slices/socket/liveChat/chat.actions";
import { LiveChatData } from "../../../../Store/Slices/socket/liveChat/chat.selectors";
import {
  TEMP_ACTIVE_FILTERS,
  setActiveChatTab,
  setAllAgentsData,
  setChatSessionActiveFilters,
  setPageNumber,
} from "../../../../Store/Slices/socket/liveChat/chat.slice";
import { getDefaultAvatar } from "../../../../Utils";
import BotLogoLive from "../../../../assets/images/bot-logo-live.svg";
import { getChatSessionFilterPayloadData } from "../../../utils/utils";
import { StyleImageAvatar } from "../ChatMainStyles";
import { getChannelAvatar } from "./Constants";
import {
  LiveChatAccordian,
  LiveChatAccordianAgent,
  LiveChatSidebarWrapper,
} from "./LiveChatSidebarStyles";
import uniqid from "uniqid";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { AppConfig } from "../../../../Config/app-config";
import { SOCKET_CONNECTION_TYPES } from "../../../utils/Enum";
import { useSocket } from "../../../hooks/socket-context";
import { ChatNetworkService } from "../../../../Store/Slices/socket/liveChat/ChatNetworkService";

interface Props extends HocBackdropLoaderProps {}

const LiveChatSidebar: React.FC<Props> = ({ setLoading }) => {
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(useCurrentBot);
  const { socket } = useSocket();
  const {
    allAgentsData,
    allLabelsData,
    allChannelsData,
    allConversationData,
    chatSessionActiveFilter,
    limit,
  } = useAppSelector(LiveChatData);
  const { isUserAvailable } = useAppSelector(useCurrentUser);
  const data = AppConfig.getLoggedInData();
  // const domain = data?.data?.geta_host;
  const online_status = data?.data?.user?.onlinestatus ?? false;
  const workspace_id = data?.data?.user?.workspace_id ?? "";
  const username = data?.data?.user?.id;

  const { userConfig } = useAppSelector(useAuthStore);

  const [width, setWidth] = useState<string>("0px");
  // const [screenWidth, setScreenWidth] = useState<number>(1600);
  const [
    isAccordionExpandedConversations,
    setIsAccordionExpandedConversations,
  ] = useState<boolean>(true);
  const [isAccordionExpandedChannels, setIsAccordionExpandedChannels] =
    useState<boolean>(true);
  const [isAccordionExpandedLabels, setIsAccordionExpandedLabels] =
    useState<boolean>(true);
  const [isAccordionExpandedAgents, setIsAccordionExpandedAgents] =
    useState<boolean>(true);
    
    const [onlineAgents, setOnlineAgents] =
    useState<any>([]);
  const [allUsers, setAllUsers] =
    useState<any>([]);
  
  
    const getAllUsers = () => {
      ChatNetworkService.getAgentsListings(config)
        .then((response: any) => {
          setAllUsers(response?.data?.data ?? []);
        })
        .catch((error: any) => {});
    };
  
    useEffect(() => {
      getAllUsers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
  
    useEffect(() => {
      const updatedAgents = allUsers?.map((item: any) => { 
        const liveagent = onlineAgents?.find((user: any) => user?.id === item?.id);
        return {
          id: item?.id,
          name: item?.first_name + item?.last_name,
          status: liveagent?.status ?? "offline",
          chat_limit: liveagent?.chat_limit ?? item?.concurrent_chat ?? 0,
          assigned: liveagent?.assigned ?? 0,
        }
      });
      dispatch(setAllAgentsData(updatedAgents ?? []));
    }, [onlineAgents,allUsers]); // eslint-disable-line react-hooks/exhaustive-deps

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };

  const toggleMenu = (): void => {
    if (width === "0px") {
      setWidth("200px");
    } else {
      setWidth("0px");
    }
  };
  const config = {
    HEADERS,
    limit: limit,
    offset: 0,
  };

  useEffect(() => {
    dispatch(LiveChatActions.getChannelsListings(config));
    dispatch(LiveChatActions.getLabelsListings(config));
    dispatch(LiveChatActions.getChatCannedResponses({ HEADERS }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleListItemClick = (payload: any) => {
    dispatch(removeAllMessages({}));
    dispatch(setActiveChatTab(0));
    dispatch(setPageNumber(0));
    dispatch(setChatSessionActiveFilters(payload));

    if (typeof payload !== "object") return;

    let keys: string[] = Object.keys(payload);
    let temp: any = {};
    if (keys?.length === 1 && keys?.[0] === "conversation") {
      temp = { ...TEMP_ACTIVE_FILTERS, ...payload };
    } else {
      temp = {
        ...TEMP_ACTIVE_FILTERS,
        ...payload,
        conversation: "",
      };
    }

    getSessionsList(temp);
  };

  const getSessionsList = (values?: any) => {
    let data = getChatSessionFilterPayloadData(
      values ?? chatSessionActiveFilter
    );
    let config = {
      limit: limit,
      offset: 0,
      data: data,
    };

    setLoading(true);
    dispatch(LiveChatActions.getSessionsListings(config)).finally(() => {
      setLoading(false);
    });
    dispatch(removeAllMessages({}));
  };

  useEffect(() => {
    if (!socket) return;
    socket.emit(
      SOCKET_CONNECTION_TYPES.ONLINE_AGENT_LISTINGS,
      { workspace_id: workspace_id, user_id: username },
      (error: any, response: any) => {
        if (error) {
          console.warn("error while getting online agents list", error);
        } else if (!error && response) {
          setOnlineAgents(response?.data);
        } else {
          //do nothing
        }
      }
    );

    const handleLiveAgentsListings = (response: any) => {
      setOnlineAgents(response?.data);
    };

    // //HANDLE LIVE AGENT LISTINGS
    socket.on("agent-refresh-list", handleLiveAgentsListings);

    //CLEAN UP
    return () => {
      socket.off("agent-refresh-list", handleLiveAgentsListings);
    };
  }, [socket, online_status]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (width === "200px") {
          toggleMenu(); // Only toggle when the width is 200px
        }
      }}
    >
      <LiveChatSidebarWrapper
        style={{
          width: `${width}`,
          transition: "width 0.2s",
          // paddingLeft: width === "0px" ? "0px" : "6px",
          // paddingRight: width === "0px" ? "0px" : "6px",
          paddingTop: "12px",
          paddingBottom: "12px",
        }}
      >
        <div className="sideBarToggleBtn" onClick={toggleMenu}>
          {width === "0px" ? (
            <AppMaterialIcons iconName={"FormatIndentIncreaseOutlined"} />
          ) : (
            <AppMaterialIcons iconName={"FormatIndentDecreaseOutlined"} />
          )}
        </div>

        <div
          className="boxContainer"
          style={{
            // width: `${width}`,
            transition: "width 0.2s",
            paddingLeft: width === "0px" ? "0px" : "6px",
            paddingRight: width === "0px" ? "0px" : "6px",
          }}
        >
          {/* Conversation */}
          <LiveChatAccordian
            disableGutters
            elevation={0}
            expanded={isAccordionExpandedConversations}
            onChange={() =>
              setIsAccordionExpandedConversations(
                !isAccordionExpandedConversations
              )
            }
          >
            <AccordionSummary
              expandIcon={<AppMaterialIcons iconName="ExpandMore" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                borderRadius: isAccordionExpandedConversations
                  ? "4px 4px 0px 0px"
                  : "4px",
              }}
            >
              <Typography>Conversation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {allConversationData &&
                allConversationData?.length > 0 &&
                allConversationData?.map((curElem: any, index: number) => {
                  return (
                    <ListItem className="items" key={index}>
                      <ListItemButton
                        selected={
                          chatSessionActiveFilter?.conversation === curElem?.id
                        }
                        onClick={() =>
                          handleListItemClick({ conversation: curElem?.id })
                        }
                        sx={{ textAlign: "center", display: "flex", gap: 1 }}
                        disabled={!curElem.isAvailable}
                      >
                        <StyleImageAvatar
                          src={curElem?.avatar}
                          style={{ width: "16px", height: "16px" }}
                        />
                        <p>{curElem?.name}</p>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </AccordionDetails>
          </LiveChatAccordian>

          {/* =========== Channels ============= */}
          <LiveChatAccordian
            isExpanded={isAccordionExpandedChannels}
            disableGutters
            height="160px"
            elevation={0}
            expanded={isAccordionExpandedChannels}
            onChange={() =>
              setIsAccordionExpandedChannels(!isAccordionExpandedChannels)
            }
          >
            <AccordionSummary
              expandIcon={<AppMaterialIcons iconName="ExpandMore" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                borderRadius: isAccordionExpandedChannels
                  ? "4px 4px 0px 0px"
                  : "4px",
              }}
            >
              <Typography>Channels</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {allChannelsData?.length > 0 ? (
                allChannelsData?.map((curElem: any, index: number) => {
                  return (
                    <ListItem className="items" key={index}>
                      <ListItemButton
                        selected={
                          chatSessionActiveFilter?.channels?.length === 1 &&
                          chatSessionActiveFilter?.channels?.[0]?.value ===
                            curElem?.value
                        }
                        onClick={() =>
                          handleListItemClick({ channels: [curElem] })
                        }
                        sx={{ textAlign: "center", display: "flex", gap: 1 }}
                      >
                        {getChannelAvatar(curElem?.value)}
                        <p>{curElem?.name}</p>
                      </ListItemButton>
                    </ListItem>
                  );
                })
              ) : (
                <ListItem className="items" key={uniqid()}>
                  <ListItemButton disabled={true}>
                    <p>No Channels Found.</p>
                  </ListItemButton>
                </ListItem>
              )}
            </AccordionDetails>
          </LiveChatAccordian>

          {/* =============== Label Accordian ======== */}
          <LiveChatAccordian
            disableGutters
            elevation={0}
            isExpanded={isAccordionExpandedLabels}
            height="200px"
            expanded={isAccordionExpandedLabels}
            onChange={() =>
              setIsAccordionExpandedLabels(!isAccordionExpandedLabels)
            }
          >
            <AccordionSummary
              expandIcon={<AppMaterialIcons iconName="ExpandMore" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                borderRadius: isAccordionExpandedLabels
                  ? "4px 4px 0px 0px"
                  : "4px",
              }}
            >
              <Typography>Labels</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {allLabelsData && allLabelsData?.length > 0 ? (
                allLabelsData?.map((curElem: any, index: number) => {
                  return (
                    <ListItem className="items" key={index}>
                      <ListItemButton
                        selected={
                          chatSessionActiveFilter?.labels?.length === 1 &&
                          chatSessionActiveFilter?.labels?.[0]?.id ===
                            curElem?.id
                        }
                        onClick={() =>
                          handleListItemClick({ labels: [curElem] })
                        }
                        sx={{
                          textAlign: "left",
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <Box display="flex" alignItems="flex-start" gap="8px">
                          <AppMaterialIcons
                            iconName="LabelImportant"
                            style={{ color: curElem?.color }}
                          />
                          <p>{curElem?.name}</p>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                })
              ) : (
                <ListItem className="items" key={uniqid()}>
                  <ListItemButton disabled={true}>
                    <p>No Labels Found.</p>
                  </ListItemButton>
                </ListItem>
              )}
            </AccordionDetails>
          </LiveChatAccordian>

          {/* =============== Agent Accordian ======== */}

          <LiveChatAccordianAgent
            disableGutters
            elevation={0}
            height="100px"
            expanded={isAccordionExpandedAgents}
            onChange={() =>
              setIsAccordionExpandedAgents(!isAccordionExpandedAgents)
            }
          >
            <AccordionSummary
              expandIcon={<AppMaterialIcons iconName="ExpandMore" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                borderRadius: isAccordionExpandedAgents
                  ? "4px 4px 0px 0px"
                  : "4px",
              }}
            >
              <Typography>Online Agents</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ListItem className="items">
                <ListItemButton
                  selected={chatSessionActiveFilter?.conversation === "bots"}
                  onClick={() => handleListItemClick({ conversation: "bots" })}
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    gap: 1,
                    padding: "8px",
                    borderBottom: `1px solid ${theme.palette.default.grey}`,
                  }}
                >
                  <div className="avatar">
                    <Avatar
                      sx={{
                        width: 30,
                        height: 30,
                        fontSize: "1rem",
                      }}
                      aria-label="agent"
                      src={BotLogoLive}
                    ></Avatar>
                  </div>
                  <p>{"Bot Conversation"}</p>
                </ListItemButton>
              </ListItem>

              <div className="agent-wrapper">
                {isUserAvailable && onlineAgents?.length > 0 ? (
                  onlineAgents?.map((curElem: any, index: number) => {
                    if (curElem.status === "offline") return null;
                    if (curElem.id === username) return null;

                    return (
                      <ListItem className="items" key={index}>
                        <ListItemButton
                          selected={
                            chatSessionActiveFilter?.agents?.length === 1 &&
                            chatSessionActiveFilter?.agents?.[0]?.id ===
                              curElem?.id
                          }
                          onClick={() =>
                            handleListItemClick({ agents: [curElem] })
                          }
                          disabled={
                            userConfig?.payload?.role?.name === "admin"
                              ? false
                              : true
                          }
                        >
                          <div className="avatar">
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <AppMaterialIcons
                                  iconName="FiberManualRecord"
                                  style={{
                                    fontSize: "13px",
                                    color: theme.palette.default.success,
                                  }}
                                />
                              }
                            >
                              <Avatar
                                sx={{
                                  bgcolor: "red[500]",
                                  width: 30,
                                  height: 30,
                                  fontSize: "13px",
                                }}
                                aria-label="recipe"
                              >
                                {getDefaultAvatar(curElem?.name)}
                              </Avatar>
                            </Badge>
                            {curElem?.isLive && (
                              <img src={curElem?.isLive} alt="icon" />
                            )}
                          </div>
                          <p className="p-data">{curElem?.name}</p>
                        </ListItemButton>
                      </ListItem>
                    );
                  })
                ) : isUserAvailable && !onlineAgents ? (
                  <ListItem className="items" key={uniqid()}>
                    <ListItemButton disabled={true}>
                      <p>No agents online.</p>
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem className="items" key={uniqid()}>
                    <ListItemButton disabled={true}>
                      <p>You're offline.</p>
                    </ListItemButton>
                  </ListItem>
                )}
              </div>
            </AccordionDetails>
          </LiveChatAccordianAgent>
        </div>
      </LiveChatSidebarWrapper>
    </ClickAwayListener>
  );
};

export default HocBackdropLoader(LiveChatSidebar);
