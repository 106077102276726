import StarBorderIcon from "@material-ui/icons/StarBorder";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Avatar, Rating, Typography } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import styled from "styled-components";
import { v4 as uuid } from "uuid";
import SkeletonLoader from "../../../AppComponents/Loader/SkeletonLoader";
import { getDefaultAvatar } from "../../../Utils";
import { colorPairs } from "../../../socket/utils/utils";
import { ErrorComponent } from "../../Broadcast/SMS/SenderID/ListingStyles";
import {
  BroadcastDetailListWrapper
} from "../../Broadcast/SMS/SenderID/SenderIDStyles";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "../AnalyticsSummaryStyles";
import TemplateAPIError from "../Dash_Assests/No_data_found.svg";
import {
  AcquitionTableWrapper,
  BroadcastTopTitle,
} from "./ConversationAgentstyle";

interface Props {
  list?: any;
  loading?: any;
  handleFileDownload?: any
}

const DenseTable: React.FC<Props> = (props) => {
  // Headers For Table
  const header = [
    "Agent",
    "Open",
    "Pending",
    "Closed",
    "Assigned",
    "Unassigned",
    "Unattended",
    "Avg. Resolution Time",
    "Avg. Conversation Duration",
    "Avg. CSAT",
  ];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Agent: "name",
    Open: "open",
    Pending: "pending",
    Closed: "closed",
    Assigned: "assigned",
    Unassigned: "unassigned",
    Unattended: "unattended",
    "Avg. Resolution Time": "avg_resolution_time",
    "Avg. Conversation Duration": "avg_conversation_time",
    "Avg. CSAT": "avg_csat",
  };
  // Width For Table Column
  const colWidth: any = {
    Agent: "300",
    Open: "100",
    Pending: "100",
    Closed: "100",
    Assigned: "100",
    Unassigned: "125",
    Unattended: "130",
    "Avg. Resolution Time": "171",
    "Avg. Conversation Duration": "212",
    "Avg. CSAT": "110",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    return date;
  };

  function formatTime(seconds: any) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.round(seconds % 60);
    var timeString = "";
    if (hours > 0) {
      timeString += hours + " hr";
      if (hours > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (minutes > 0) {
      timeString += minutes + " min";
      if (minutes > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (remainingSeconds > 0 || timeString === "") {
      timeString += remainingSeconds + " sec";
      if (remainingSeconds !== 1) {
        timeString += "s";
      }
    }
    return timeString;
  }

  return (
    <WrapperContainer>
      <BroadcastDetailListWrapper style={{ padding: "20px" }}>
        <BroadcastTopTitle style={{}}>
          <Box
            className="topTitle-left"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >

            <p className="time_stats">
              <TextTitle>Conversation by Agents</TextTitle>
              <AppToolTip
                divStyle={{ padding: "0 8px" }}
                tooltipProps={{
                  title: "List of user intents captured by the bot",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
              />
            </p>
          </Box>
        </BroadcastTopTitle>

        <AcquitionTableWrapper>
          <Box className="table-header">
            {<Box className="table-row">
              {header?.map((header: string, index: number) => {
                return (
                  <Box
                    className={`col-head ${header
                      .toLowerCase()
                      .replaceAll(" ", "_")}`}
                    style={{
                      minWidth: colWidth[header] + "px",
                    }}
                    key={index}
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      {header}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            }
          </Box>

          <Box className="table-body">
            {props.list?.length === 0 ? (
              !props.loading ? (
                <ErrorComponent style={{ height: "225px" }}>
                  <Box>
                    <img src={TemplateAPIError} width="150" alt="conversation_dashboard_image_error" />
                  </Box>
                  <Box className="err-content">
                    <Typography variant="h6" fontSize="20px">
                      No Records Found.
                    </Typography>
                  </Box>
                </ErrorComponent>
              ) : (
                <SkeletonLoader numberOfCol={6} numberofRow={4} />
              )
            ) : (
              <React.Fragment>
                {props.list &&
                  props.list?.map((row: any, rowIndex: any) => {
                    const colorPair = colorPairs[rowIndex % colorPairs.length];
                    return (
                      <Box key={rowIndex} className="table-row">
                        {header?.map((header: any, ColumnIndex: any) => {
                          return (
                            <Box
                              className={`col-body ${header
                                .toLowerCase()
                                .replaceAll(" ", "_")}`}
                              title={
                                header === "Scheduled"
                                  ? formatDateField(
                                    row[keyToHeaderMap[header]]
                                  )
                                  : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"
                              }
                              style={{ minWidth: colWidth[header] + "px" }}
                              key={ColumnIndex}
                            >
                              <Typography
                                title={row[keyToHeaderMap[header]]}
                                className="table-text"
                              >

                                {header === "Avg. Resolution Time" ||
                                  header === "Avg. Conversation Duration" ? (
                                  formatTime(row[keyToHeaderMap[header]])
                                ) : header === "Avg. CSAT" ? (
                                  <Rating
                                    name="size-small"
                                    defaultValue={row[keyToHeaderMap[header]] || 0}
                                    size="small"
                                    readOnly
                                    key={uuid()}
                                    emptyIcon={
                                      <StarBorderIcon
                                        style={{
                                          opacity: 0.55,
                                          color: "#FDBD02",
                                        }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                ) : header === "Agent" ? (
                                  <Box className="conversationbox">
                                    <Avatar sx={{
                                      backgroundColor: colorPair.bg,
                                      texcolor: colorPair.textColor,

                                    }}>{getDefaultAvatar(row[keyToHeaderMap[header]])}</Avatar>
                                    <p className="agent-name">{row[keyToHeaderMap[header]]} </p>
                                  </Box>
                                ) : (
                                  row[keyToHeaderMap[header]]
                                )}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  })}
              </React.Fragment>
            )}
          </Box>
        </AcquitionTableWrapper>
      </BroadcastDetailListWrapper>
    </WrapperContainer>
  );
};

// Default Export of component
export default DenseTable;

export const WrapperContainer = styled.div`
  width: 100%;
  
`;
