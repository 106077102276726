import {
  Divider,
  Paper,
  TablePagination,
  Typography,
  debounce,
} from "@mui/material";
import { Box } from "@mui/system";
import { uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import { LoaderComponentProps } from "../../../../AppComponents/Loader";
import { AppConfig } from "../../../../Config/app-config";
import {
  FilterPannel,
  SettingPageMainBoxBtnBox,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
} from "../../../../Customization/CommonElements";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { DefinedCustomDates } from "../../../../Models/defaultSelectOptions";
import { useAppDispatch } from "../../../../Store";
import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
// import TemplateAPIERROR from "../../../../assets/images/TemplateAPIERROR.png";
import TemplateAPIERROR from "../../../../assets/images/No_records_found.svg";
import { BROADCAST_WHATSAPP_FORM_DATA } from "../../WhatsApp/Utils/Constants";
import { ErrorComponent, TemplateTable } from "./ListingStyles";
import { BroadcastDetailListWrapper, WrapperContainer } from "./SenderIDStyles";
import AppButton from "../../../../AppComponents/AppButton";
import { useNavigate } from "react-router-dom";
import {
  AppPagination,
  BroadcastTopTitle,
  TableFilter,
  TableWrapper,
} from "../../../../TableStyle";
import moment from "moment";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
interface Props extends LoaderComponentProps {}

const SenderIDListing = () => {
  const navigate = useNavigate();
  const [errorApi, setErrorApi] = useState(false);
  const [pagination,setPagination] = useState<any>()
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const has_low_balance = localStorage.getItem("has_low_balance")
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
  });

  const dispatch = useAppDispatch();
  let { showAlert } = useNotification();

  const getPreviousData = () => {
    setLoading(true);
    dispatch(SMSActions.getSMSSenderIDListData(localValues))
      .then((res: any) => {        
        setList(res?.payload?.data.results);
        setPagination(res?.payload?.data?.pagination)
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false)
        // setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousData();
  }, [localValues]);

  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
  }, []);

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value:any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };

  // Headers For Table
  const header = ["Name", "Satus"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Name: "name",
    Satus: "detailed_status",
  };
  // Width For Table Column
  const colWidth: any = {
    Name: "420",
    Satus: "700",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    // const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  return (
    <WrapperContainer>
      <BroadcastDetailListWrapper style={{ padding: "16px 16px 0px 16px" }}>
        <BroadcastTopTitle style={{ padding: "0px 0px 16px 0px" }}>
          <Box className="topTitle-left">
            <Typography fontSize="16px" fontWeight="500" component="h6">
              SMS Broadcast Sender IDs
            </Typography>
          </Box>
          <Box className="topTitle-right">
            <ProtectedAppButton
              moduleId="sms_template"
              specificPermissionKey="create"
              variant="outline"
              onClick={() => navigate("/broadcast/sms/upload-template")}
            >
              Upload Template
            </ProtectedAppButton>
            <ProtectedAppButton
              moduleId="sms_broadcast_crud"
              specificPermissionKey="create"
              variant="primarydark"
              onClick={() => navigate("/broadcast/sms/create-broadcast/1")}
              isDisabled={has_low_balance === "true"}
              title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
            >
              Create Broadcast
              <AppMaterialIcons iconName="Add" />
            </ProtectedAppButton>
          </Box>
        </BroadcastTopTitle>

        <AppForm // requestSearch(values, submitProps);
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => {}}
          // validationSchema={BotValidationSchema}
          divStyle={{ width: "100%" }}
        >
          <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="filter-left">
              {/* <Typography fontSize="14px" variant="h6">
                Sender IDs
              </Typography> */}
              <AppFormSearchField
                name="search"
                placeholder="Search"
                defaultBorder={true}
                divStyle={{ width: "240px", marginBottom: "0" }}
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearCallback(e, "search")}
              />
            </div>
            <div className="filter-right"></div>
          </TableFilter>
        </AppForm>

        {
          loading ? (<AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - 287px)`}
          />) : (
          <>
            <TableWrapper height={list?.length === 0 ? "calc(100vh - 214px)" : "calc(100vh - 245px)"}>
              <div>
                <Box className="table-header">
                  <Box className="table-row">
                    {header?.map((header: string, index: number) => {
                      return (
                        <Box
                          className={`col-head ${header
                            .toLowerCase()
                            .replaceAll(" ", "_")}`}
                          style={{
                            // maxWidth: colWidth[header] + "px",
                            minWidth: colWidth[header] + "px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>{header}</Typography>
                        </Box>
                      );
                    })}
                    {/* <Box
                  className="col-head"
                  sx={{
                    maxWidth: "100px",
                    minWidth: "100px",
                    height: "2.969rem",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography></Typography>
                </Box> */}
                  </Box>
                </Box>

                    

                {
                    errorApi || list?.length === 0 ? <NoRecordsFound /> : (
                        <Box className="table-body">
                        {list?.map((row: any, rowIndex: any) => {
                          return (
                            <Box key={rowIndex} className="table-row">
                              {header?.map((header: any, ColumnIndex: any) => {
                                const isActionColumn = header === "Action";
                                return (
                                  <Box
                                    className={`col-body ${header
                                      .toLowerCase()
                                      .replaceAll(" ", "_")}`}
                                    title={
                                      header === "Scheduled"
                                        ? formatDateField(row[keyToHeaderMap[header]])
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"
                                    }
                                    style={{
                                      // maxWidth: isActionColumn
                                      //   ? "400px"
                                      //   : colWidth[header] + "px",
                                      minWidth: isActionColumn
                                        ? "400px"
                                        : colWidth[header] + "px",
                                    }}
                                  >
                                    <Typography className="table-text">
                                      {header === "Scheduled"
                                        ? formatDateField(row[keyToHeaderMap[header]])
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"}
                                    </Typography>
                                  </Box>
                                );
                              })}
                            </Box>
                          );
                        })}
                        </Box>
                    )
                }

                    

                {/* <Box className="table-body">
                  {errorApi || list?.length === 0 ? (
                    <ErrorComponent style={{ height: "calc(100vh - 360px)" }}>
                      <Box>
                        <img src={TemplateAPIERROR} width="300" alt="" />
                      </Box>
                      <Box className="err-content">
                        <Typography variant="h6" fontSize="20px">
                          No Records Found.
                        </Typography>
                      </Box>
                    </ErrorComponent>
                  ) : (
                    
                  )}
                </Box> */}
              </div>
            </TableWrapper>

            {list?.length ? (
              <AppPaginationComponent
                totalCount={pagination?.totalRecords}
                handlePageChange={handlePageChange}
                currentPage={localValues?.currentPage}
                totalPages={pagination?.totalPages}
                rowsPerPage={localValues?.itemsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : null}
          </>)
        }

        
      </BroadcastDetailListWrapper>
    </WrapperContainer>
  );
};

// Default Export of component
export default SenderIDListing;
