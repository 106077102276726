import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import ModalBody from "./modal-body";
import ModalFooter from "./modal-footer";
import ModalHeader from "./modal-header";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 420,
  bgcolor: "background.paper",
  // boxShadow: 24,
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
  // p: 4,
  borderRadius: "8px",
};

interface IModal {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

const TransitionModal = (props: IModal) => {
  const { open, children, onClose } = props;
  // const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={onClose}
      // closeAfterTransition
      // BackdropComponent={Backdrop}
      // BackdropProps={{
      //   timeout: 500,
      //   sx: {
      //     background: "rgba(16, 16, 16, 0.08)",
      //     backdropFilter: "blur(3px)",
      //   },
      // }}
    >
      {/* <Fade in={open}> */}
        <Box sx={style}>
          {children}
        </Box>
      {/* </Fade> */}
    </Modal>
  );
};

TransitionModal.Body = ModalBody;
TransitionModal.Header = ModalHeader;
TransitionModal.Footer = ModalFooter;

export default TransitionModal;
