import SecureLS from "secure-ls";
const encodingType = process.env.NODE_ENV === "development" ? "" : "des";
let storage = window.localStorage
  ? new SecureLS({
      encodingType: encodingType,
      isCompression: false,
      encryptionSecret: process.env.REACT_APP_STORAGE_SECRET,
    })
  : {
      set: () => {},
      get: () => {},
      getAllKeys: () => {},
      remove: () => {},
      removeAll: () => {}
    };

class LocalStorage {
  set = function (key: string, value: any) {
    storage.set(key, value);
  };

  get = function (key: string) {
    return storage.get(key) || {};
  };

  getAllKeys = function () {
    storage.getAllKeys();
  };

  removeAll = function () {
    storage.removeAll();
  };

  remove = function (key: string) {
    storage.remove(key);
  };

  getOrSet = function (key: string, value: any) {
    let result = storage.get(key);
    if (!result) {
      storage.set(key, value);
      result = value;
    }
    return result;
  };
}

export { LocalStorage };
