// vendors
import { useContext, useState, useEffect } from "react";
import { AxiosResponse } from "axios";

// context
import { BuilderContext } from "../../flow-builder-v2/contexts";

// services
import { FlowBuilderService } from "../../../../Services/Apis/FlowBuilder";

const useFetchFlowById = (id: string) => {
  const builderContext = useContext(BuilderContext);
  const [data, setdata] = useState<any>(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getNodes(id).then((response:any) => {
      setloading(false);
      setdata(response)
    })
  }, [id]);

  const getNodes = async (id: string) => {
    const response = await FlowBuilderService.getFlowById(id, {
      appid: JSON.stringify({ botid: builderContext.botId }),
    });
    if (response.data.status === 200) {
      return response.data.data;
    }
    if (response.data.status === 500) {
      return null;
    }
  };
  return { data, loading, getNodes };
};
export default useFetchFlowById;
