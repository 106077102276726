import { Close } from "@material-ui/icons";
import { Backdrop, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { UpdateLifeCycleStageSchema } from "../../../Models/ValidationSchema";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import { FacebookCircularProgress } from "../../../socket/appComponents/UtilsComponents/AppNoActivityScreen";

interface Props {
  actionDrawerChildComponent: any;
  setActionDrawerChildComponent: (value: any) => void;
}

const ChangeLifeCycleStage = ({
  actionDrawerChildComponent,
  setActionDrawerChildComponent,
}: Props) => {
  const { showAlert } = useNotification();
  const [loading, setLoading] = useState<boolean>(true);
  const [stageOptions, setStageOptions] = useState<any>([]);
  const [statusOptions, setStatusOptions] = useState<any>([]);
  const [lostReasonOptions, setLostReasonOptions] = useState<any>([]);
  const [showLostReasonField, setShowLostReasonField] =
    useState<boolean>(false);
  const [showOtherLostReasonField, setShowOtherLostReasonField] =
    useState<boolean>(false);
  const [userInput, setUserInput] = useState({
    lifecycle_stage: null,
    lifecycle_status: null,
    lost_reason: null,
    other_lost_reason: "",
    context: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
  });

  const getContactDetails = () => {
    let payload = {
      contact_id: actionDrawerChildComponent?.id,
    };

    Promise.allSettled([
      ContactsNetworkService.getLifeCycleStage({}),
      ContactsNetworkService.getBasicInfo(payload),
    ])
      .then((results) => {
        const [lifeCycleStageResult, basicInfoResult] = results;

        if (basicInfoResult?.status === "fulfilled" && lifeCycleStageResult?.status === "fulfilled") {
          
          let data = basicInfoResult.value?.data?.lifecycle_information ?? [];
          const selectedStage =
            data?.find((item: any) => item?.is_selected === true) ?? null;
          const selectedStatus =
            selectedStage?.statuses?.find(
              (item: any) => item?.is_selected === true
            ) ?? null;
          const selectedStageFromOptions = lifeCycleStageResult?.value?.data?.data?.find((item: any) => item?.id === selectedStage?.id);
          const selectedStatusFromOptions = selectedStageFromOptions?.statuses?.find((item: any) => item?.id === selectedStatus?.id);
          setStageOptions(lifeCycleStageResult?.value?.data?.data ?? []);
          setStatusOptions(selectedStageFromOptions?.statuses ?? []);
          setLostReasonOptions(lifeCycleStageResult?.value?.data?.lost_reson_data?.options ?? []);

          if (selectedStatusFromOptions?.type === "closed_lost") { 
            setShowLostReasonField(true);
          }

          setUserInput({
            lifecycle_stage: selectedStageFromOptions ? selectedStageFromOptions : null,
            lifecycle_status: selectedStatusFromOptions ? selectedStatusFromOptions : null,
            lost_reason: null,
            other_lost_reason: "",
            context: "",
            first_name:
              basicInfoResult.value?.data?.general_information?.first_name ??
              "",
            last_name:
              basicInfoResult.value?.data?.general_information?.last_name ?? "",
            email:
              basicInfoResult.value?.data?.general_information?.email ?? "",
            mobile: basicInfoResult.value?.data?.general_information?.mobile_phone_number ?? ""
          });
        } else {
          showAlert(
              "Enabled to fetch Data",
            "error"
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (actionDrawerChildComponent?.id) {
      getContactDetails();
    }
  }, [actionDrawerChildComponent?.id]); // eslint-disable-line

  const handleSubmitBtn = (values: any, submitProps: any) => {
    let payload: any = {
      id: actionDrawerChildComponent?.id,
      data: {
        lifecycle_stage: values?.lifecycle_stage?.id,
        lifecycle_stage_status: values?.lifecycle_status?.id,
        lost_reason: values?.lost_reason?.id,
        other_lost_reason: values?.other_lost_reason,
      },
    };

    setLoading(true);
    ContactsNetworkService.editContactsByID(payload)
      .then((res: any) => {
        showAlert(res?.data?.message, "success");
        submitProps?.resetForm();
        setActionDrawerChildComponent(null);
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Contact not update", "error");
      })
      .finally(() => {
        if (values?.context?.length > 0) {      
          const notesPayload = {
            contact_id: actionDrawerChildComponent?.id,
            context: values?.context,
          };
          ContactsNetworkService.addNoteAsPerId(notesPayload).finally(() => {
            setLoading(false);
            submitProps.setSubmitting(false);
          });;
        }
      });
    
      
  };

  return (
    <>
            {
        loading ? (
          <Backdrop open={loading} style={{ zIndex: 9999999 }}>
            <FacebookCircularProgress />
          </Backdrop>
        ) : null
}
      <StyledDrawerHeader>
        <p className="title">Change Lifecycle Stage</p>
        <IconButton
          onClick={(e: any) => setActionDrawerChildComponent(null)}
          title="close"
        >
          <Close />
        </IconButton>
      </StyledDrawerHeader>
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
          }}
          validationSchema={UpdateLifeCycleStageSchema}
          divStyle={{
            flex: 1,
          }}
        >
          <StyledDrawerWrapper>
            <div className="basicInfoBox">
              <p className="row">
                <span className="label">First Name:</span>
                <span className="value">{userInput?.first_name}</span>
              </p>
              <p className="row">
                <span className="label">Last Name:</span>
                <span className="value">{userInput?.last_name}</span>
              </p>
              <p className="row">
                <span className="label">Mobile:</span>
                <span className="value">{userInput?.mobile}</span>
              </p>
              <p className="row">
                <span className="label">Email:</span>
                <span className="value">{userInput?.email}</span>
              </p>
            </div>
            <AppReactSelectField
              menuPosition="fixed"
              label={"Lifecycle Stage"}
              options={stageOptions ?? []}
              defaultValue={userInput?.lifecycle_stage}
              valueKey={"id"}
              displayKey="label"
              name={"lifecycle_stage"}
              placeholder={`Select stage`}
              isClearable={true}
              requiredSign={true}
              isLoading={loading}
              onChangeCallbackFn={(value: any) => {
                setStatusOptions(value?.statuses ?? []);
                setShowLostReasonField(false);
                setShowOtherLostReasonField(false);
              }}
              resetDependentFieldName={["lifecycle_status","lost_reason","other_lost_reason"]}
            />
            <AppReactSelectField
              menuPosition="fixed"
              label={"Lifecycle Status"}
              options={statusOptions ?? []}
              defaultValue={userInput?.lifecycle_status}
              valueKey={"id"}
              displayKey="label"
              name={"lifecycle_status"}
              placeholder={`Select status`}
              isClearable={true}
              requiredSign={true}
              isLoading={loading}
              resetDependentFieldName={["lost_reason","other_lost_reason"]}
              onChangeCallbackFn={(value: any) => {
                if (value?.type === "closed_lost") {
                  setShowLostReasonField(true);
                  setShowOtherLostReasonField(false);
                } else {
                  setShowLostReasonField(false);
                  setShowOtherLostReasonField(false);
                }
              }}
            />
            {showLostReasonField ? (
              <AppReactSelectField
                menuPosition="fixed"
                label={"Lost Reason"}
                options={lostReasonOptions ?? []}
                defaultValue={userInput?.lost_reason}
                valueKey={"id"}
                displayKey="label"
                name={"lost_reason"}
                placeholder={`Select Lost Reason`}
                isClearable={true}
                requiredSign={true}
                isLoading={loading}
                resetDependentFieldName={["other_lost_reason"]}
                onChangeCallbackFn={(value: any) => {
                  if (value?.label === "Other") {
                    setShowOtherLostReasonField(true);
                  } else {
                    setShowOtherLostReasonField(false);
                  }
                }}
              />
            ) : null}
            {showOtherLostReasonField ? (
              <AppFormField
                name="other_lost_reason"
                placeholder="Other Lost Reason"
                label="Other lost reason"
                requiredSign={true}
              />
            ) : null}

            <AppFormTextArea
              name="context"
              placeholder="Add Notes"
              label="Notes"
              rows={4}
            />
          </StyledDrawerWrapper>
          <StyledDrawerFooter>
            <AppButton
              variant="grey"
              onClick={(e: any) => setActionDrawerChildComponent(null)}
            >
              Cancel
            </AppButton>
            <AppSubmitButton title="Update Status" checkIsValidProperty={false}></AppSubmitButton>
          </StyledDrawerFooter>
        </AppForm>

    </>
  );
};

export default ChangeLifeCycleStage;

const StyledDrawerWrapper = styled.div`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1;
    padding: 1rem;

    .basicInfoBox {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem 0;
      .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        .label {
          font-weight: 500;
          font-size: 15px;
        }
        .value {
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
`;

const StyledDrawerFooter = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
const StyledDrawerHeader = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
