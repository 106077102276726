import { AccountBalanceWalletOutlined } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../Store";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { SubscriptionsNetworkService } from "../../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import AddWallet from "../../Menu/AddWallet";


interface BroadcastCreditDetailsProps {
    creaditsData: any;
}

const BroadcastCreditDetails: React.FC<BroadcastCreditDetailsProps> = ({ creaditsData }) => {
    const [openAddBalanceModal, setOpenAddBalanceModal] = useState(false);
    const { userConfig } = useAppSelector(useAuthStore);
    const addBalance = (balance: number) => {
        setOpenAddBalanceModal(false);
        let data = {
            subscription_id: userConfig?.payload?.subscription_id,
            addons: [
                {
                    addon_code: "wallet",
                    quantity: balance,
                },
            ],
            redirect_url: "/broadcast/whatsapp/create-broadcast/3",
        };
        SubscriptionsNetworkService.addCredits(
            data,
            userConfig?.payload?.subscription_id
        )
            .then((res: any) => {
                window.open(res?.data?.hostedpage?.url, "_self");
            })
    };
    useEffect(() => {
        if (creaditsData?.credits_balance < creaditsData?.total_cost || creaditsData?.credits_balance <= 0) {
            setOpenAddBalanceModal(true)
        } else {
            setOpenAddBalanceModal(false)
        }
    }, [creaditsData]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <CreditCard>
            {creaditsData != null ?
                <div className={`creditDiv ${creaditsData.valid ? "divBgColorGreen" : "divBgColorRed"}`}>
                    <div>
                        <IconButton className={`creditIcon ${creaditsData.valid ? "fillGreenIcon" : "fillRedIcon"}`}>
                            <AccountBalanceWalletOutlined />
                        </IconButton>
                    </div>
                    <div className="descDiv">
                        {creaditsData.valid ?
                            <p className="creditMsg">This campaign will be sent to all users who meet the selected audience criteria.</p> :
                            <p className="creditMsg">Looks like your current wallet balance is lower than the estimated campaign cost. Please ensure that your wallet balance meets the minimum estimated cost to send this campaign successfully.
                            </p>
                        }
                        <div className="divWidth">
                            {creaditsData.total_cost ?
                                <><div className={`titletext ${creaditsData.valid ? "titletextGreen" : "titletextRed"}`} >Estimated campaign cost: <div className="costText"> ₹ {creaditsData.total_cost} </div> </div></> : null
                            }
                            {creaditsData.credits_balance ?
                                <> <div className={`titletext ${creaditsData.valid ? "titletextGreen" : "titletextRed"}`}>Your wallet balance:<div className="costText">₹ {creaditsData.credits_balance} </div></div></> : null
                            }
                            {creaditsData.credits_required ?
                                <>
                                    <div className="borderLine"></div>
                                    <div className={`titletext ${creaditsData.valid ? "titletextGreen" : "titletextRed"}`}>Required balance:<div className="costText">₹ {creaditsData.credits_required} </div></div>
                                </> : null
                            }
                        </div>
                        <AddWallet check={openAddBalanceModal} setCheck={setOpenAddBalanceModal} addBalance={addBalance} />
                    </div>
                </div> : null
            }
        </CreditCard>
    );
}

export default BroadcastCreditDetails;


const CreditCard = styled.div`
  &{

    .creditDiv {
        display: flex;
        gap: 1.5rem;
        justify-content: flex-start;
        padding: 1rem 1rem 1.6875rem 1rem;
        border-radius: 8px;
    }

    .divBgColorGreen {
        background-color: rgba(237, 255, 243, 1);
        border: 1px solid rgb(167, 215, 183);
    }

    .divBgColorRed {
        background-color: rgba(255, 237, 237, 1);
        border: 1px solid rgba(249, 199, 199, 1);
    }

    .creditIcon {
        border: "1px solid";
        padding: 0.657rem;
        color: #fff
    }

    .fillGreenIcon {   
        background-color: rgba(86, 170, 114, 1);
    }

    .fillRedIcon {   
        background-color: rgba(243, 78, 78, 1);
    }

    .descDiv {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    
    .creditMsg {
        color: rgba(126, 131, 146, 1);
        font-size: 0.9rem;
    }

    .titletext {
        font-size: 1rem;
        font-weight: 400;
        line-height: 28px;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
    }

    .titletextGreen {
        color: rgba(46, 99, 64, 1);
    }

    .titletextRed {
        color: rgba(133, 62, 62, 1);
    }

    .borderLine {
        border: 1px solid rgba(126, 131, 146, 0.5);
        margin: 0.5rem -1.5rem 0rem 0px;
    }

    .costText {
        font-weight: 600;
    }

    .divWidth {
        width: fit-content;
    }
}
`;