import { IconButton } from "@mui/material";
import { memo } from "react";

import {
  StyledBannerCarouselSlickSlider,
  StyledBannerCarouselWrapper,
  StyledReactVideoPlayer,
} from "../../Styles/StyledComponents";
import { AppMaterialIcons } from "../Icons";

interface Props {
  items: any[];
  hideActionBtn?: boolean;
}

const AppRenderBannerCarousel: React.FC<Props> = ({ items, hideActionBtn=false }) => {
  return (
    <StyledBannerCarouselWrapper style={hideActionBtn ? {pointerEvents:"none"} : {}}>
      <StyledBannerCarouselSlickSlider {...settings}>
        {items?.length > 0 &&
          items?.map((item: any, index: number) => {
            return (
              <div className="sliderSingleItem" key={index.toString()}>
                {item?.video && item?.View_options === "Video" ? (
                  <StyledReactVideoPlayer
                    url={item?.video}
                    width={"100%"}
                    controls={true}
                    light={true}
                    
                  />
                ) : (
                  <img src={item?.image?.source} alt="" />
                )}
              </div>
            );
          })}
      </StyledBannerCarouselSlickSlider>
    </StyledBannerCarouselWrapper>
  );
};

export default memo(AppRenderBannerCarousel);

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton onClick={onClick} className={className} style={{ ...style }}>
      <AppMaterialIcons iconName={"ChevronRight"} />
    </IconButton>
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton onClick={onClick} className={className} style={{ ...style }}>
      <AppMaterialIcons iconName={"ChevronLeft"} />
    </IconButton>
  );
};

const settings = {
  className: "slickSlider",
  adaptiveHeight: true,
  lazyLoad: true,
  fade: true,
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: false,
  // autoplaySpeed: 2000,
  // pauseOnHover: true,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,

  // nextArrow: <CustomNextArrow />,
  // prevArrow: <CustomPrevArrow />,
};
