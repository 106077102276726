// vendors
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const UIButton = (props: ButtonProps) => {
  const { children, ...rest } = props;

  return (
    <ButtonStyled disableElevation {...rest}>
      {children}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)(({ color, variant }) => ({
  borderRadius: "6px",
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: 500,
  padding: "6px 12px !important",
  textTransform: 'capitalize',
  "& .MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: '6px'
  },
  ...(color === "primary" && {
    ...(variant === "contained" && {
      backgroundColor: "#5B73E8",
      "& :hover": {
        backroundColor: "#7b8fec",
      },
      "& :active": {
        backroundColor: "#3f50a2",
      },
    }),
    ...(variant === "outlined" && {
      borderColor: "#5B73E8",
      color: '#5B73E8',
      "& :hover": {
        borderColor: "#7b8fec",
      },
      "& :active": {
        borderColor: "#3f50a2",
      },
    }),
  }),
  ...(color === "secondary" && {
    ...(variant === "outlined" && {
      borderColor: "#CBCDD3",
      color: "#7E8392",
    }),
  }),
}));

export default UIButton;
