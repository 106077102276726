import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import AppTablePagination from "../../../../../AppComponents/AppTablePagination";
import AppFormCheckboxField from "../../../../../AppComponents/Forms/AppFormCheckboxField";
import { LinkListWraper, ListItemWraper } from "../../BotKnowledgeStyles";
import { useAppSelector } from "../../../../../Store";
import { crawlLinkPagination } from "../../../../../Store/Slices/Faq/Source/source.selectors";
import AppPaginationComponent from "../../../../../AppComponents/Pagination/Pagination";

interface LinksListProps {
  urlList: any[];
  setLocalValues: (e: any) => void;
  localValues: any;
  addSubUrlOnCheck: any;
  setSourceAndDocsId: any;
  sourceAndDocsId: any;
  setShouldApply: any;
  siteMapData?: any;
  handleChangeRowsPerPage?: any;
}

const LinksList: React.FC<LinksListProps> = ({
  urlList,
  setLocalValues,
  localValues,
  addSubUrlOnCheck,
  setSourceAndDocsId,
  sourceAndDocsId,
  setShouldApply,
  siteMapData,
  handleChangeRowsPerPage,
}) => {
  const pagination = useAppSelector(crawlLinkPagination);
  const { values } = useFormikContext();
  const [callBack, setCallBack] = useState<any>({ status: false, value: "" });
  useEffect(() => {
    if (callBack.status) {
      addSubUrlOnCheck(values, callBack.value);
      setCallBack({ status: false, value: "" });
    }
  }, [values, callBack]); // eslint-disable-line react-hooks/exhaustive-deps
  const [checkAll, setcheckAll] = useState(true);
  const [selectedUrlsLength, setSelectedUrlsLength] = useState<number>(0);

  useEffect(() => {
    if (urlList && urlList.length) {
      const allCountries = urlList?.map((item: any) => {
        if (item.selected) {
          return item.documentId;
        } else {
          setcheckAll(false);
        }
      });

      setLocalValues({
        ...localValues,
        checkboxAll: checkAll,
        sourceAndDocsId,
        checkbox: allCountries,
      });
    }
  }, [urlList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedUrlsLength(sourceAndDocsId?.count);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleCheckAllChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const allCountries = urlList?.map((item: any) => item.documentId);
      setcheckAll(e.target.checked);
      setSelectedUrlsLength(e.target.checked ? sourceAndDocsId?.count : 0);
      setLocalValues({
        ...localValues,
        checkboxAll: e.target.checked,
        checkbox: e.target.checked ? allCountries : [],
      });

      setTimeout(() => {
        setCallBack({ status: true, value: "" });
      }, 500);
    },
    [urlList, localValues, setLocalValues] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSelectChanges = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, selectedId: any) => {
      const selectedIdsArray = e.target.checked
        ? [...localValues.checkbox, selectedId]
        : localValues.checkbox.filter((id: any) => id !== selectedId);
      const checkboxAll = selectedIdsArray.length === urlList.length;
      setcheckAll(checkboxAll);
      let difference: number = urlList.length - selectedIdsArray.length;
      const checkedList = checkboxAll
        ? sourceAndDocsId?.count
        : sourceAndDocsId?.count - difference;
      setSelectedUrlsLength(checkedList);
      difference = 0;

      setLocalValues({
        ...localValues,
        checkboxAll,
        checkbox: selectedIdsArray,
      });
      setTimeout(() => {
        setCallBack({ status: true, value: selectedId });
      }, 500);
    },
    [urlList, localValues, setLocalValues] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // pagination
  const handleFetchMoreData = (p: number, value: number) => {
    setShouldApply(true);
    setSourceAndDocsId((prev: any) => ({
      ...prev,
      offset: value - 1,
    }));
  };
  return (
    <LinkListWraper>
      {siteMapData?.count > 0 ? (
        <>
          <ListItem className="list-item" disablePadding>
            <ListItemButton role={undefined} dense style={{ padding: 0 }}>
              <ListItemIcon>
                <AppFormCheckboxField
                  label={
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="12px"
                      color={"#000"}
                    >
                      <Typography variant="subtitle1">URLs</Typography>
                      {sourceAndDocsId?.count}
                    </Box>
                  }
                  name="checkboxAll"
                  onChange={handleCheckAllChange}
                />
              </ListItemIcon>
            </ListItemButton>
            <Typography variant="subtitle1" color="#000">
              Selected
            </Typography>
            <span style={{ padding: "0 8px" }}>{selectedUrlsLength}</span>
          </ListItem>
          <Box className="list-body">
            <List className="lists">
              {urlList?.map((value: any) => (
                <ListItemWraper
                  key={value.documentId}
                  style={{}}
                  disablePadding
                >
                  <AppFormCheckboxField
                    label={value.url}
                    name="checkbox"
                    onChange={(e: any) =>
                      handleSelectChanges(e, value.documentId)
                    }
                    isArray={true}
                    checked={true}
                    uniqueId={value.documentId}
                    style={{ padding: 0 }}
                  />
                </ListItemWraper>
              ))}
            </List>
          </Box>
          {urlList && urlList?.length ? (
            <AppPaginationComponent
              totalCount={sourceAndDocsId.count}
              handlePageChange={handleFetchMoreData}
              currentPage={localValues?.currentPage}
              totalPages={pagination?.totalPages}
              rowsPerPage={sourceAndDocsId?.perPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </>
      ) : (
        <p className="error-message">
          {siteMapData?.status !== "success" ? siteMapData?.status : null}
        </p>
      )}
    </LinkListWraper>
  );
};

export default LinksList;
