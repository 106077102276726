import { Box, Grid, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
const CustomBack = (props: any) => {
    return (
        <Wrapper>
            <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                <IconButton color="inherit" onClick={props.handleBack}>
                    <ArrowBackIosIcon style={{ fontSize: "15px" }} />
                </IconButton>
                <Typography id="modal-modal-title" variant="h6" fontWeight={500} fontSize={"1rem"}>
                    {props.name || "Custom Field"}
                </Typography>
            </Box>
            <Box >
                <IconButton color="inherit" onClick={props.handleBack}>
                    <AppMaterialIcons style={{ fontSize: "18px" }} iconName="Close" />
                </IconButton>
            </Box>
        </Wrapper>

    )
}
export default CustomBack;


const Wrapper = styled.div`
& {
    display: flex;
    width: 100%;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid ${theme.palette.default.lighterGrey};
    padding: 0px 16px;
    .left{
       
    }
    .right{
       
    }
}
`;