import { Chatbot, ChatbotTrackingCode, PaginatedResponse } from '../../../Models';
import { chatbotConsoleAxios } from '../../../Utils/Axios';

import { CH_CONSOLE_ENDPOINTS } from '../Endpoints';
import { createGenericSliceAPICaller, HTTPMethod } from '../Common';
import { GenericState } from '../../../Store';



const {
  reducer: botTrackingCodeReducer,
  apiFn: getBotTrackingCodeFn,
  storeAccessFn: useBotTrackingCodeStore
} = createGenericSliceAPICaller<GenericState<ChatbotTrackingCode>, any>({
  sliceName: 'chatbot_tracking_code',
  axiosObj: chatbotConsoleAxios,
  endpoint: CH_CONSOLE_ENDPOINTS.CHATBOT_TRACKING_CODE,
  data: {},
  httpMethod: HTTPMethod.GET,
  storeAccessPath: 'userData.dashboardData.currentBotConfiguration.botTrackingCode'
});
// const useMyBotsStore = (state: any): PaginatedResponse<Chatbot> => {
//   return state.userData.dashboardData.myBots as PaginatedResponse<Chatbot>;
// };

export {getBotTrackingCodeFn};
export {botTrackingCodeReducer};
export {useBotTrackingCodeStore};
