// components
import React from "react";
import Icon from "../../../Components/Icon";

// styles
import { LeftArrowButtonStyled } from "./../Carousel.styled";

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick && onClick(event);
  };

  return (
    <LeftArrowButtonStyled
      className={`${className} ignore-onClickOutside`}
      onClick={handleClick}
      style={{ ...style }}
      disabled={className.includes("slick-disabled")}
    >
      <Icon icon="chevron-left" size={12} color="#7E8392" />
    </LeftArrowButtonStyled>
  );
};
export default PrevArrow;
