import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import uniqid from "uniqid";
import {
  AppForm,
  AppFormSwitchField,
  AppFormTextArea,
  AppSubmitButton
} from "../../../../AppComponents/Forms";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { BusinessHoursNetworkService } from "../../../../Store/Slices/Settings/LiveChat/Workspace/WorkspaceNetworkService";
import { getHoursOptions, getTimezonesList } from "../../../../Utils";
import AppReactSelectField from "../../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { findObjectInArray } from "../../../../socket/utils/utils";
import {
  BusinessBody,
  BusinessHoursBody,
  BusinessHoursTop,
  BusinessWrapper,
  HoursContainer,
  PreviewArea
} from "./BusinessHoursStyles";
import { BusinessHrsValidationSchema } from "./BusinessHrsValidationSchema";
import Preview from "./Components/Preview/Preview";

interface Props extends LoaderComponentProps {}

const BusinessHours: React.FC<Props> = ({ setLoading }) => {
  
  const { bot_id } = useParams();
  const { showAlert } = useNotification();
  // Initial Form Values
  const [localValues, setlocalValues] = useState<any>(null);

  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };

  const [userInput, setUserInput] = useState<any>({
    activeOperatingHours: false,
    message: "YOU HAVE REACHED US OUTSIDE OF OUR WORK HOURS",
    timezone: { id: "Asia/Kolkata", name: "Asia/Kolkata" },

    monday: {
      end: getHoursOptions?.[37],
      start: getHoursOptions?.[20],
      enabled: true,
    },
    tuesday: {
      end: getHoursOptions?.[37],
      start: getHoursOptions?.[20],
      enabled: true,
    },
    wednesday: {
      end: getHoursOptions?.[37],
      start: getHoursOptions?.[20],
      enabled: true,
    },
    thursday: {
      end: getHoursOptions?.[37],
      start: getHoursOptions?.[20],
      enabled: true,
    },
    friday: {
      end: getHoursOptions?.[37],
      start: getHoursOptions?.[20],
      enabled: true,
    },
    saturday: {
      end: getHoursOptions?.[37],
      start: getHoursOptions?.[20],
      enabled: true,
    },
    sunday: {
      end: getHoursOptions?.[37],
      start: getHoursOptions?.[20],
      enabled: true,
    },
  });

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    const formValues = {
      activeOperatingHours: values?.activeOperatingHours,
      timezone: values?.timezone,
      outside_business_hours_message: values?.message,
      monday: {
        end: values?.monday?.end?.value,
        start: values?.monday?.start?.value,
        enabled: values?.monday?.enabled,
      },
      tuesday: {
        end: values?.tuesday?.end?.value,
        start: values?.tuesday?.start?.value,
        enabled: values?.tuesday?.enabled,
      },
      wednesday: {
        end: values?.wednesday?.end?.value,
        start: values?.wednesday?.start?.value,
        enabled: values?.wednesday?.enabled,
      },
      thursday: {
        end: values?.thursday?.end?.value,
        start: values?.thursday?.start?.value,
        enabled: values?.thursday?.enabled,
      },
      friday: {
        end: values?.friday?.end?.value,
        start: values?.friday?.start?.value,
        enabled: values?.friday?.enabled,
      },
      saturday: {
        end: values?.saturday?.end?.value,
        start: values?.saturday?.start?.value,
        enabled: values?.saturday?.enabled,
      },
      sunday: {
        end: values?.sunday?.end?.value,
        start: values?.sunday?.start?.value,
        enabled: values?.sunday?.enabled,
      },
    };

    const data = {
      payload: {
        active_timings: formValues,
      },
      HEADERS,
    };

    BusinessHoursNetworkService.UpdateWorkspaceData(data)
      .then((response: any) => {
        if (response?.data) {
          showAlert("Timings Updated", "success");
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      })
      .finally(() => {
        setLoading(false);
        SubmitProps?.setSubmitting(false);
      });
  };

  useEffect(() => {
    // if (bot_id) {
      getWorkspaceData();
    // }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getWorkspaceData = () => {
    BusinessHoursNetworkService.workspaceConfigData()
      .then((response: any) => {
        if (response?.data) {
          let data = response?.data?.data?.active_timings;
          setUserInput({
            activeOperatingHours: data?.activeOperatingHours,
            message: data?.outside_business_hours_message,
            timezone: data?.timezone,

            monday: {
              end: findObjectInArray(
                getHoursOptions,
                data?.monday?.end,
                "value"
              ),
              start: findObjectInArray(
                getHoursOptions,
                data?.monday?.start,
                "value"
              ),
              enabled: data?.monday?.enabled,
            },
            tuesday: {
              end: findObjectInArray(
                getHoursOptions,
                data?.tuesday?.end,
                "value"
              ),
              start: findObjectInArray(
                getHoursOptions,
                data?.tuesday?.start,
                "value"
              ),
              enabled: data?.tuesday?.enabled,
            },
            wednesday: {
              end: findObjectInArray(
                getHoursOptions,
                data?.wednesday?.end,
                "value"
              ),
              start: findObjectInArray(
                getHoursOptions,
                data?.wednesday?.start,
                "value"
              ),
              enabled: data?.wednesday?.enabled,
            },
            thursday: {
              end: findObjectInArray(
                getHoursOptions,
                data?.thursday?.end,
                "value"
              ),
              start: findObjectInArray(
                getHoursOptions,
                data?.thursday?.start,
                "value"
              ),
              enabled: data?.thursday?.enabled,
            },
            friday: {
              end: findObjectInArray(
                getHoursOptions,
                data?.friday?.end,
                "value"
              ),
              start: findObjectInArray(
                getHoursOptions,
                data?.friday?.start,
                "value"
              ),
              enabled: data?.friday?.enabled,
            },
            saturday: {
              end: findObjectInArray(
                getHoursOptions,
                data?.saturday?.end,
                "value"
              ),
              start: findObjectInArray(
                getHoursOptions,
                data?.saturday?.start,
                "value"
              ),
              enabled: data?.saturday?.enabled,
            },
            sunday: {
              end: findObjectInArray(
                getHoursOptions,
                data?.sunday?.end,
                "value"
              ),
              start: findObjectInArray(
                getHoursOptions,
                data?.sunday?.start,
                "value"
              ),
              enabled: data?.sunday?.enabled,
            },
          });
        }
      })
      .catch((error: any) => {
        console.error("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // // Set Initial User Values for Formik
  const sendValuesToParent = (values: any): void => {
    setlocalValues(values);
  };


  const isFormFieldDisabled = (key?: string) => {
    
    if (localValues?.activeOperatingHours === false) return true;
    
    if (key && !localValues?.[key]?.enabled) return true;
    
    return false;
  };
  return (
    <BusinessWrapper>
      <BusinessBody>
        <HoursContainer>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, SubmitProps: any) => {
              handleSubmitBtn(values, SubmitProps);
            }}
            validationSchema={BusinessHrsValidationSchema}
          >
            <BusinessHoursTop>
              <AppFormSwitchField name="activeOperatingHours" />
              <AppFormTextArea
                disableNewLineOnEnter ={true}
                name="message"
                label="Unavailable message for users"
                placeholder=""
                disabled={isFormFieldDisabled()}
                sendValuesToParent={(values: any) => sendValuesToParent(values)}
              />

              <AppReactSelectField
                label="Timezone"
                options={getTimezonesList()}
                name="timezone"
                isDisabled={true}
                valueKey={"id"}
                isClearable={false}
              />
            </BusinessHoursTop>

            <BusinessHoursBody>
              <Table sx={{ maxWidth: 800 }} aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Day</StyledTableCell>
                    <StyledTableCell align="left">Status</StyledTableCell>
                    <StyledTableCell align="left">Start Time</StyledTableCell>
                    <StyledTableCell align="left">End Time</StyledTableCell>
                  </StyledTableRow>
                </TableHead>

                <TableBody>
                  {/* monday */}
                  <StyledTableRow key={uniqid()}>
                    <StyledTableCell>Monday</StyledTableCell>
                    <StyledTableCell align="right">
                      <AppFormSwitchField
                        divStyle={{ marginBottom: 0 }}
                        name={`monday.enabled`}
                        disabled={isFormFieldDisabled()}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`monday.start`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('monday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`monday.end`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('monday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  {/* tuesday */}

                  <StyledTableRow key={uniqid()}>
                    <StyledTableCell>Tuesday</StyledTableCell>
                    <StyledTableCell align="right">
                      <AppFormSwitchField
                        divStyle={{ marginBottom: 0 }}
                        name={`tuesday.enabled`}
                        disabled={isFormFieldDisabled()}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`tuesday.start`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('tuesday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`tuesday.end`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('tuesday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  {/* wednesday */}

                  <StyledTableRow key={uniqid()}>
                    <StyledTableCell>Wednesday</StyledTableCell>
                    <StyledTableCell align="right">
                      <AppFormSwitchField
                        divStyle={{ marginBottom: 0 }}
                        name={`wednesday.enabled`}
                        disabled={isFormFieldDisabled()}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`wednesday.start`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('wednesday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`wednesday.end`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('wednesday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  {/* thursday */}

                  <StyledTableRow key={uniqid()}>
                    <StyledTableCell>Thursday</StyledTableCell>
                    <StyledTableCell align="right">
                      <AppFormSwitchField
                        divStyle={{ marginBottom: 0 }}
                        name={`thursday.enabled`}
                        disabled={isFormFieldDisabled()}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`thursday.start`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('thursday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`thursday.end`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('thursday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  {/* friday */}

                  <StyledTableRow key={uniqid()}>
                    <StyledTableCell>Friday</StyledTableCell>
                    <StyledTableCell align="right">
                      <AppFormSwitchField
                        divStyle={{ marginBottom: 0 }}
                        name={`friday.enabled`}
                        disabled={isFormFieldDisabled()}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`friday.start`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('friday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`friday.end`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('friday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  {/* saturday */}

                  <StyledTableRow key={uniqid()}>
                    <StyledTableCell>Saturday</StyledTableCell>
                    <StyledTableCell align="right">
                      <AppFormSwitchField
                        divStyle={{ marginBottom: 0 }}
                        name={`saturday.enabled`}
                        disabled={isFormFieldDisabled()}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`saturday.start`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('saturday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`saturday.end`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('saturday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>

                  {/* sunday */}
                  <StyledTableRow key={uniqid()}>
                    <StyledTableCell>Sunday</StyledTableCell>
                    <StyledTableCell align="right">
                      <AppFormSwitchField
                        divStyle={{ marginBottom: 0 }}
                        name={`sunday.enabled`}
                        disabled={isFormFieldDisabled()}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`sunday.start`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('sunday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <AppReactSelectField
                        name={`sunday.end`}
                        options={getHoursOptions ?? []}
                        label=""
                        valueKey={"value"}
                        isClearable={false}
                        isDisabled={isFormFieldDisabled('sunday')}
                        divStyle={{ maxWidth: "98px", minWidth: "98px" }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>

              <div className="submit-btn">
                <div className="">
                  <AppSubmitButton
                    variant="cta"
                    style={{ minWidth: "180px" }}
                    title="Save"
                  />
                </div>
              </div>
            </BusinessHoursBody>
          </AppForm>

          <div className="body"></div>
        </HoursContainer>

        <PreviewArea>
          <Preview message={localValues?.message} />
        </PreviewArea>
      </BusinessBody>
    </BusinessWrapper>
  );
};

export default withLoader(BusinessHours);

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#282828",
    fontWeight: "400",
    fontSize: "1rem",
    textTransform: "capitalize",
    lineHeight: "8px",
    borderBottom: "none !important",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#7E8392",
    fontWeight: "400",
    fontSize: "0.9rem",
    borderBottom: "none !important",
    lineHeight: "16px",
    height: 16,
    maxHeight: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    padding: "2px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    borderBottom: "none !important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: "none !important",
  },
  "&:hover": {
  },
}));
