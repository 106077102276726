import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { useDispatch } from "react-redux";
import { logoutAuth } from "../../../Store/Slices/AuthSlice";

const ExpireSubscription = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const handleLogout = () => { 
        dispatch(logoutAuth(null));
        navigate("/login");
    }
    return (
        <>
          <StyledDialog
                open={true}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ backgroundColor: "lightgrey" }}
                
            >
                <DialogTitle id="alert-dialog-title">
                    {"Your Plan has expired"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your plan has expired, but there's no need to worry. You can renew or upgrade it to keep growing your business.
                    </DialogContentText>
                </DialogContent>
                <div style={{borderBottom: "1px solid lightgray",padding: "1.5rem", display:'flex', justifyContent:'center', alignContent:'center', gap:'2rem'}}>
                    <AppButton
                        onClick={() => handleLogout()}
                        variant="danger"
                >
                    Logout
                    </AppButton>
                <AppButton
                    onClick={() => {navigate("/subscription/subscription-plans/plans",{replace :true})}}
                    >
                    Upgrade Now
                </AppButton>
                    </div>
            
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
                </DialogContent>
                <div>
                <AppButton
              variant="grey"
              component={"a"}
              target={"_blank"}
              href={"https://www.geta.ai/contact-us/"}
              rel={"noopener"}
              style={{ width: "150px" }}
                >
                    Contact Us
                </AppButton></div>
            </StyledDialog>
        </>
    )
}
const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    text-align: -webkit-center;
    max-width: 38% !important;
    padding: 50px !important;
  }
`;
export default ExpireSubscription