// vendors
import React from "react";
import Paper from "@mui/material/Paper";

interface CardProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void;
  secondary?: boolean;
}

const Card = (props: CardProps) => {
  const { children, onClick, onMouseEnter, onMouseLeave, secondary } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        width: '100%',
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
        position: 'relative',
        ...(secondary && {
          border: '1px solid #6579DE',
          backgroundColor: 'rgba(101, 121, 222, 0.08);'
        }),
        'ul': {
          marginLeft: '15px',
        }
      }}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="nodeCardWrapper"
    >
      {children}
    </Paper>
  );
};
export default Card;
