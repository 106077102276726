import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  allMessagesStore,
  setToggleScrollFn,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { StyledMessageText } from "../../Styles/StyledComponents";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppRenderRepliedMessage from "./AppRenderRepliedMessage";
import { theme } from "../../../Customization/Theme";
import { getHtmlTextChannelWise } from "../Forms/RichEditor/WebLiveChat/utils";
import AppSmallButton from "../UtilsComponents/AppSmallButtons";
import { AppMaterialIcons } from "../Icons";
import {
  StyledFileContainerBox,
  StyledReactVideoPlayer,
} from "../../Styles/StyledScreens";
import { IconButton } from "@mui/material";
import { MESSAGE_TYPES } from "../../utils/Enum";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface Props {
  src: string;
  alt?: string;
  userMessage: boolean;
  time: string;
  avatar: string;
  children?: any;
  msgId: string;
  replyToType: string;
  replyToValue: string;
  replyToAvatar: string;
  seen: string;
  sent: string;
  delivered: string;
  [otherProps: string]: any;
  templateData?: any;
}

const AppRenderTemplate: React.FC<Props> = ({
  src = "",
  alt = "",
  time,
  avatar,
  userMessage = false,
  msgId,

  replyToType,
  replyToValue,
  replyToAvatar,
  seen,
  sent,
  delivered,
  templateData,
}) => {
  const dispatch = useAppDispatch();
  const { toggleScrollFn } = useAppSelector(allMessagesStore);

  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      avatar={avatar || ""}
      time={time}
      isMedia={true}
      msgId={msgId}
      seen={seen}
      sent={sent}
      delivered={delivered}
    >
      <AppRenderRepliedMessage
        userMessage={userMessage}
        replyToType={replyToType}
        replyToValue={replyToValue}
        replyToAvatar={replyToAvatar}
      >
        {templateData?.header ? (
          templateData?.header?.[0]?.type === MESSAGE_TYPES.IMAGE ? (
            <img
              src={templateData?.header?.[0]?.value}
              // alt={alt}
              onClick={() =>
                window.open(templateData?.header?.[0]?.value, "_blank")
              }
              onLoad={() => {
                if (!isImageLoaded) {
                  setIsImageLoaded(true);
                  dispatch(setToggleScrollFn(!toggleScrollFn));
                }
              }}
              onError={(error: any) => {
                error.target.src = FallbackImage;
              }}
            />
          ) : templateData?.header?.[0]?.type === MESSAGE_TYPES.DOCUMENT ? (
            <StyledFileContainerBox
              href={src}
              target="_blank"
              rel="noopener,noreferrer"
            >
              <div className="fileActionBox">
                <AppMaterialIcons
                  iconName="FilePresent"
                  style={{ color: theme.palette.default.darkGrey }}
                />
              </div>
              <div className="fileNameBox">
                {templateData?.header?.[0]?.value}
              </div>
              <IconButton
                title={templateData?.header?.[0]?.value || "Download"}
              >
                <AppMaterialIcons
                  iconName="DownloadForOffline"
                  style={{ fontSize: "1.5rem" }}
                />
              </IconButton>
            </StyledFileContainerBox>
          ) : templateData?.header?.[0]?.type === MESSAGE_TYPES.VIDEO ? (
            <StyledReactVideoPlayer
              url={templateData?.header?.[0]?.value}
              width={"100%"}
              controls={true}
              light={false}
              alt={alt}
            />
          ) : null
        ) : null}
        {templateData?.body ? (
          <StyledMessageText
            userMessage={
              templateData?.header?.[0]?.type === MESSAGE_TYPES.TEXT
                ? getHtmlTextChannelWise(
                    templateData?.header?.[0]?.value +
                      "\n" +
                      templateData?.body +
                      templateData?.footer ?? "\n" + templateData?.footer,
                    "whatsapp",
                  )
                : getHtmlTextChannelWise(
                    templateData?.body + templateData?.footer ??
                      "\n" + templateData?.footer,
                    "whatsapp",
                  )
            }
            dangerouslySetInnerHTML={{
              __html:
                templateData?.header?.[0]?.type === MESSAGE_TYPES.TEXT
                  ? getHtmlTextChannelWise(
                      templateData?.header?.[0]?.value +
                        "\n" +
                        templateData?.body +
                        templateData?.footer ?? "\n" + templateData?.footer,
                      "whatsapp",
                    )
                  : getHtmlTextChannelWise(
                      templateData?.body + templateData?.footer ??
                        "\n" + templateData?.footer,
                      "whatsapp",
                    ),
            }}
            className="getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
          />
        ) : null}
        {templateData?.button?.value?.length > 0 ? (
          <AppSmallButton
            variant="outline"
            style={{ cursor: "not-allowed" }}
            disableRipple
            disableFocusRipple
            disabled
          >
            {templateData?.button?.value[0]?.button_text}
          </AppSmallButton>
        ) : null}
      </AppRenderRepliedMessage>
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderTemplate);
