import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { ReactComponent as SearchIcon } from "../../../assets/images/Searchicon.svg";
import AppButton from "../../../AppComponents/AppButton";
import ExportLogo from "../../../assets/images/export 1.svg";
import { AppToolTip } from "../AppToolTip/AppToolTip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
interface DashBoardHeaderProps {
  title: string,
  enableSearch?: boolean,
  handleSelectSearch?: (e: any) => void,
  searchPlaceHolder?: string,
  enableExport?: boolean
  handleExport?: any
  enableToolTip?: boolean
  toolTipText?: string
}

const DashBoardHeader: React.FC<DashBoardHeaderProps> = ({
  title,
  enableSearch = false,
  handleSelectSearch,
  searchPlaceHolder = "Search",
  enableExport = false,
  handleExport,
  enableToolTip = false,
  toolTipText = ""
}) => {
  return <HeaderWrapper>
    <div className="text-title">
      {title}
      {
        enableToolTip ? <AppToolTip
          divStyle={{ padding: "0 8px" }}
          tooltipProps={{
            title: toolTipText,
            placement: "top",
          }}
          iconButtonProps={{ disableRipple: "true" }}
          IconComponent={<InfoOutlinedIcon className="div_tooltip" style={{ fontSize: "16px" }}
          />}
        /> : null
      }
    </div>
    <div className="search" style={{ display: "inline-flex" }}>
      {enableSearch ? <StyledAppInput>
        <input
          type="text"
          onChange={(e: any) => handleSelectSearch && handleSelectSearch(e)}
          name="query"
          id="query"
          placeholder={searchPlaceHolder}
        />
        <SearchIcon />
      </StyledAppInput> : null}
      {enableExport ? <AppButton
        style={{
          display: "flex",
          gap: "15px",
          backgroundColor: theme.palette.default.lighterFilterGrey,
          color: "gray",
          marginLeft: "10px"
        }}
        variant="gray"
        onClick={(e: any) => handleExport(e)}
      >
        Export
        <img src={ExportLogo} alt="Not Found" />
      </AppButton> : null
      }
    </div>
  </HeaderWrapper>
}

export default DashBoardHeader;

const HeaderWrapper = styled.div`
&& {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-bottom: 1px solid #E9E8EE;
    .text-title {
        font-size: 16px;
        font-wight: 500;
        position: relative;
        padding-left: 10px;
        align-items: center;
        display: flex;
        ::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 4px;
        background: linear-gradient(
            to bottom,
            rgba(132, 90, 223, 0.5) 50%,
            rgba(35, 183, 229, 0.5) 50%
        );
        border-radius: 0.5rem;
        }
    }
}
`;

const StyledAppInput = styled.div`
  & {
    display: flex;
    align-items: center;
    position: relative;
    input {
      border-radius: 4px;
      outline: none;
      border: 1px solid transparent;
      padding: 0 1.3rem 0 14px;
      font-family: ${theme.typography.fontFamily};
      border: 1px solid ${theme.palette.default.grey};
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      background-color: #F5F6F8;
      max-width: 318px;
      min-width: 318px;
      min-height: 30px;
    }
    svg {
      position: absolute;
      right: 10px;
      width: 15px;
    }
  }

  &::placeholder {
    color: ${theme.palette.default.grey};
  }
`;