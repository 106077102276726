import { Accordion } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const UsersDetailWrapper = styled.div`
  box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 4px;
  // z-index: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  transition: width 0.2s;
  overflow: hidden;
  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  width: ${(props: any) => props.width};

  .user-deatil-only {
    width: 100%;
    // border: 1px solid yellow;
    box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 4px;
  }

  .UserDetailContentWrap {
    height: 100%;
    overflow: auto;
    background-color: #ebeff3;
  }

  @media (max-width: 1300px) {
    width: ${(props: any) => props.width};
  }
`;

export const UserDetailAccordian = styled(Accordion)`
  width: calc(100%-38px);
  border-radius: 4px;
  background-color: transperant;

  background-color: blue;
  // border: 1px solid pink;
  margin-bottom: 12px;
  // margin-bottom: 0 !important;
  // box-shadow: 0 1px 4px rgba(18, 52, 77, 0.16) !important;

  .MuiAccordion-root {
    background-color: blue;
  }

  & .MuiPaper-root-MuiAccordion-root:before {
    // content: "asdadhasbdhj";
  }

  & .Mui-expanded {
    margin: 0px !important;
  }

  &.MuiAccordion-root {
    width: 100%;
    // opacity: 0.1;

    &.MuiAccordion-root::before {
      background-color: none !important;
      height: 0px !important;
    }

    &.MuiPaper-root-MuiAccordion-root {
      background-color: red !important;
    }

    & MuiAccordionDetails-root {
      width: 100%;
    }

    & .MuiAccordionSummary-root {
      background-color: #f5f7f9;
      height: 40px;
      border-radius: 4px 4px 0 0;
      border: 1px solid;
      // box-shadow: 0 1px 4px rgba(18, 52, 77, 0.16);
      svg {
        color: ${theme.palette.default.darkGrey};
      }
      &.Mui-expanded {
        min-height: 40px;
      }
    }
    & .MuiAccordionDetails-root {
      padding: 0.875rem;
      // box-shadow: 0 1px 4px rgba(18, 52, 77, 0.16);
      border: 1px solid;
      border-radius: 0 0 4px 4px;
    }
  }

  & .MuiAccordion-root::before {
    background-color: none !important;
  }
  .title-wrapper {
    width: 100%;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    svg {
      font-size: 15px;
    }
  }
  .accordian-title {
    font-size: 0.875rem;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.08em;
    color: #091e42;
  }
  .my-team-head {
    display: flex;
    gap: 0.875rem;
    font-size: 0.875rem;
    align-items: center;
    color: ${theme.palette.default.darkGrey};
    border-bottom: 1px solid ${theme.palette.default.grey};
    padding-bottom: 0.875rem;
  }
`;

export const UserDetailSummary = styled.div`
  padding: 0.675rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  .title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    .icon {
      display: flex;
      & .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
        font-size: 15px;
      }
    }
    .title {
      p {
        font-size: 13px;
        color: #576c7d;
        margin-bottom: 4px;
        font-weight: 600;
        letter-spacing: 0.08em;
      }
      h6 {
        font-size: 13px;
        color: #12344d;
        font-weight: 600;
        letter-spacing: 0.06em;
      }
    }
  }
`;

export const StyledPreviousConvoBox = styled.div`
  && {
    width: 100%;
    min-height: 72px;
    max-height: 72px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 8px;
    justify-content: center;
    padding: 6px 6px;
    background-color: rgba(216, 222, 250, 0.5);
    cursor: pointer;
    // margin-bottom: 12px;
    .message {
      color: ${theme.palette.default.black};
      font-weight: 400;
      display: block !important;
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }
    p.message {
      font-size: 14px !important;
      line-height: 1.2rem;
    }
    .date {
      font-size: 12px;
      font-weight: 400;
      color: ${theme.palette.default.darkGrey};
    }
    &:hover {
      background-color: ${theme.palette.default.grey};
    }

    .bottomBox{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .action-bottom__left {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      align-items: center;
      cursor: pointer !important;
      gap: 8px;
    }
    .open-status {
      color: #548faf;
      background-color: rgba(116, 205, 255, 0.15);
    }

    .close-status {
      background-color: #cbeee1;
      color: #353636;
    }

    .pending-status {
      background-color: rgba(255, 166, 3, 0.15);
      color: #ffa603;
    }

    .open {
      font-size: 0.75rem;
      padding: 0.2rem 0.6rem;

      border-radius: 116px;
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledUserChatAddNotesWrapper = styled.div`
  && {
    .addNote__wrapper {
      padding: 0px 6px 0px 10px;
      // .addupdateBtn {
      //   min-height: 30px;
      // }
      button {
        min-height: 30px !important;
        height: 30px !important;
        margin-top: 12px;
        min-width: 110px;
      }
    }

    .addInputField {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: #f5f6f8;
      outline: none !important;
      padding: 0 1.3rem 0 14px;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      // text-transform: capitalize;

      &::placeholder {
        color: ${theme.palette.default.darkGrey};
      }

      &:active {
        border: 2px solid ${theme.palette.default.border};
        color: ${theme.palette.default.text};
        font-size: 13px;
      }
      &:focus {
        border: 2px solid ${theme.palette.default.border} !important;
        color: ${theme.palette.default.text};
      }
      &:disabled {
        color: ${theme.palette.default.darkGrey};
        opacity: 0.7;
      }
    }
  }
`;
