import { Box, Typography } from "@mui/material";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import { ApexChatWrapper } from "./Doughnout";
import { TextTitle, Wrapstatistics } from "./EmailBroadcastSummaryStyles";

interface Props {
  linkStats?: any;
  loading?: boolean;
}

const TopLinks: React.FC<Props> = ({ linkStats, loading }) => {
  return loading ? (
    <AppSkeletonLoader />
  ) : (
    <Wrapstatistics>
      <div className="j_div_Link" >
        <Box display="flex" alignItems="center">
          <ApexChatWrapper>
            <Box className="heading__tooltip">
              <TextTitle>
                <Typography className="heading">Top link clicked</Typography>
              </TextTitle>
              <AppToolTip
                tooltipProps={{
                  title:
                    "The most frequently clicked hyperlink or URL within an email campaign, highlighting the content or call-to-action that generated the highest recipient engagement.",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={
                  <AppMaterialIcons
                    iconName="HelpOutline"
                    style={{ fontSize: "14px" }}
                  />
                }
              />
            </Box>
          </ApexChatWrapper>
        </Box>

        
        <div className="j_div_Link_all" style={{ height: "100%"}}>
          {linkStats?.length > 0 ? (
            linkStats?.map((el: any) => {
              return (
                <>
                  <div className="date_with_link">
                    <a href="#" className="link_a">
                      {el?.url}
                    </a>
                    <p className="Date_info">{el?.count}</p>
                  </div>
                </>
              );
            })
          ) : (  
                <NoRecordsFound
                  imageWidth={"200px"}
                  headerTextSize={"19px"}
                  imageSrc={NoRecordImage}
                  />
          )}
        </div>
      </div>
    </Wrapstatistics>
  );
};

export default TopLinks;
