import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class SMSNetworkService {

  static postSelectedListTable(payload: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.POST_SELECTED_LIST_TABLE}`, payload);
  }

  static getSMSTemplateList(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SMS_TEMPLATE_LIST}`,
      data
    );
  }
  static getSMSTemplateTag(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SMS_TEMPLATE_TAG_DATA}`,
      data
    );
  }

  static getFieldsTypeMappingList(id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_FIELDS_TYPE_MAPPING_LIST}?list_id=${id}`
    );
  }

  static getSMSTemplateUpdateTag(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SMS_TEMPLATE_UPDATE_TAG}`,
      data
    );
  }
  static getSMSTemplateTagList() {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_SMS_TEMPLATE_TAG_LIST}`

    );
  }
  static updateSelectedContactItem(data: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_SELECTED_CONTACT_SINGLE_ITEM}`,
      data
    );
  }
  static getTemplateById(id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.SMS_TEMPLATE_DATA_BY_ID}${id}`
    );
  }
  static DeleteTemplateById(id: string) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.SMS_TEMPLATE_DELETE_BY_ID}${id}`
    );
  }

  static getSMSAnalyticsList(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_SMS_ANALYTICS_LIST}`,
      data
    );
  }
  static getSMSBroadcastDetailList(id: string, data?: any) {

    return http.post(
      `${consoleBaseUrl}${Endpoints.SMS_BROADCAST_DETAILS_LISTINGS}${id}`, data
    );
  }
  static deleteSMSTemplateTags(data?: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.DELETE_SMS_TEMPLATE_TAGS}`, data
    );
  }

  static uploadSmsDocumentFile(data: any, config: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.UPLOAD_SMS_DOCUMENT_FILE}`,
      data,
      config
    );
  }

  static getSMSSenderIDList(data?: any) {  
    return http.get(
      data?.search ? `${consoleBaseUrl}${Endpoints.GET_SMS_SENDERID_LIST}?search=${data?.search}`  :`${consoleBaseUrl}${Endpoints.GET_SMS_SENDERID_LIST}?offset=${data?.currentPage}&limit=${data?.itemsPerPage?.value}`,);

  }

  static postSMSBroadcast(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CREATE_SMS_BROADCAST}`,
      data
    );
  }

  static postTestSMS(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.TEST_SMS_SEND}`,
      data
    );
  }

  static updateSMSBroadcast(data: any, id: string) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_SMS_BROADCAST}/${id}`,
      data
    );
  }

  static getBroadcastById(id: string) {
    return http.post(`${consoleBaseUrl}${Endpoints.BROADCAST_BY_ID}/${id}`, {});
  }

  static getSMSTemplateCategories() {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_SMS_TEMPLATE_UPDATE_CATEGORIES}`

    );
  }
  static deleteSmsBroadcast(data: any) {
    const {id}=data
    const type={
      type:data.type
    }
        return http.post(
      `${consoleBaseUrl}${Endpoints.DELETE_SMS_BROADCAST
      }/${id}`,type
    );
  }

  static sendSmsMessage(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.SEND_SMS_MESSAGE}`,
      data
    );
  }
  
}
//*SMS *//
class Endpoints {
  
  static GET_SMS_ANALYTICS_LIST = "/workspace/all-sms-broadcast";
  static SMS_BROADCAST_DETAILS_LISTINGS = "/workspace/sms-broadcastById/";
  
  static POST_SELECTED_LIST_TABLE = "/workspace/add-broadcast-data";
  static BROADCAST_BY_ID = "/workspace/all-sms-broadcast";
  
  //new endpoints for node microservice
  static UPLOAD_SMS_DOCUMENT_FILE = "/workspace/broadcast/sms-template/upload";
  static GET_SMS_TEMPLATE_LIST = "/workspace/broadcast/sms-template-list";
  static GET_SMS_SENDERID_LIST = "/workspace/broadcast/sms-senderId-list";
  static GET_SMS_TEMPLATE_TAG_LIST = "/workspace/broadcast/sms-tags-list";
  static GET_SMS_TEMPLATE_UPDATE_CATEGORIES = "/workspace/broadcast/sms-categories-list";
  static SMS_TEMPLATE_DATA_BY_ID = "/workspace/broadcast/sms-templateById/";
  static DELETE_SMS_TEMPLATE_TAGS = "/workspace/broadcast/remove-sms-tag";
  static GET_SMS_TEMPLATE_UPDATE_TAG = "/workspace/broadcast/add-sms-tag";
  static GET_SMS_TEMPLATE_TAG_DATA = "/workspace/broadcast/create-tag";
  static SMS_TEMPLATE_DELETE_BY_ID = "/workspace/broadcast/delete-sms-template/";
  static CREATE_SMS_BROADCAST = "/workspace/broadcast/create-sms-broadcast";
  static UPDATE_SMS_BROADCAST = "/workspace/broadcast/update-sms-broadcast";
  static TEST_SMS_SEND = "/workspace/broadcast/test-sms/send";

  static UPDATE_SELECTED_CONTACT_SINGLE_ITEM = "/workspace/broadcast/update-broadcast-list-temp-data";
  static GET_FIELDS_TYPE_MAPPING_LIST = "/workspace/broadcast/fields_type_mapping_list";
  static DELETE_SMS_BROADCAST = "/workspace/broadcast/delete-broadcast-by-id";
  
  static SEND_SMS_MESSAGE = "/workspace/send-sms-single-contact";

}
