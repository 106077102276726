import Box from "@mui/material/Box";

interface IModalBody {
  children: React.ReactNode;
}
const ModalBody = (props: IModalBody) => {
  const { children } = props;
  return <Box sx={{ px: "16px", py: "8px" }}>{children}</Box>;
};
export default ModalBody;
