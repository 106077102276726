import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";

export const BlockCardStyle = styled.div`
  && {
    display: flex;
    // min-width: 160px;
  width: calc(33.33% - 3px);
  @media(max-width: 1440px) {
    width: ${(props: any) => {
      return props?.pannelPreview && "calc(50% - 3px)"; 
    }};
  }
  @media(max-width: 1140px) {
    width: ${(props: any) => {
      return props?.pannelPreview && "100%";  
    }};
  }
    margin-bottom: '0 !important';
    .block-notification__wrapepr{
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      button {
          svg {
          max-width: 18px;
        }
      }
      .action-icons {
        display: flex;
        justify-content: end !important;
        margin-top: -8px;
        margin-right: -8px;
      }
      .block-image{
        display: flex;
        justify-content: center;
        img {
          width: 70px; 
          height: 70px;
          /* object-fit: cover; */
          background-size: cover;
        }
      }
      .block-text__container {
        width: 100%;
        text-align: center;
        .block-text{
          font-weight: 500 !important;
          cursor: pointer;
          color: ${theme.palette.primary.light};
          word-break: break-all;
        }
      }
    }
  }
}`;
