import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppForm, AppFormObserver, AppSubmitButton } from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  automationBuilderPayload,
  automationBuilderState
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { marketingListActions } from "../../../Store/Slices/MarketingList/marketingList.actions";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { IsInListsConfigSchema } from "../validationSchema";
import { withLoader } from "../../../AppComponents/Loader";
import { getCurrentTimeZone } from "../../../Utils";

const IsInList = ({ setLoading }: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formRef: any = useRef();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [initialValues, setInitialValues]: any = useState({
    marketingListId: "",
  });
  const [listOptions, setListOptions] = useState<any>([]);
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  const [formikProps, setFormikProps] = useState<any>(null);
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("marketingListId", "", { shouldDirty: true })
  }, []);
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.marketingListId &&
      listOptions?.length > 0
    ) {
      //function to filter object based on Value
      setInitialValues({
        marketingListId: {
          id: listOptions?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.marketingListId?.id
          )?.id,
          name: listOptions?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.marketingListId
          )?.name,
        },
      });
    }
  }, [automationPayload, listOptions]);  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    let temp = {
      limit: 999,
      offset: 0,
      timezone:getCurrentTimeZone()
    };
    dispatch(marketingListActions.getAllMarketingListings(temp))
      .then((res: any) => {
        let tempArray: any = [];
        res?.payload.data?.results?.map((ele: any) => {
          tempArray.push({ id: ele.id, name: ele.name });
          setListOptions(tempArray);
        });
      }).then(() => {
        setLoading(false)
      }).catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleSubmit = (values: any, submitProps: any) => {
    let payload: any = {
      nodeObject: {
        marketingListId: values?.marketingListId.id,
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: values?.marketingListId.name,
    };
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };

  return (
    <ModelFormWrapper isDataAvaliable={listOptions?.length}>
      <Typography variant="h6">Is In List</Typography>
      {listOptions?.length ? (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={IsInListsConfigSchema}
          innerRef={formRef}
        >
          {listOptions?.length > 0 && (
            <AppReactSelectField
              name={"marketingListId"}
              options={listOptions}
              label="Marketing List"
              displayKey="name"
              valueKey="id"
              placeholder="Select a List"
              isSearchable={true}
              isClearable={true}
              hideSelectedOptions={true}
              isRequired={true}
              divStyle={{ background: "none", padding: "0" }}
            />
          )}
          <AppFormObserver setFormikProps={setFormikProps} />

          <ModelFooter>
            <AppButton onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
          </ModelFooter>
        </AppForm>
      ) : (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      )}
    </ModelFormWrapper >
  );
};

export default withLoader(IsInList);

const ModelFormWrapper = styled.div`
width: ${(props: any) => props.isDataAvaliable ? "400px" : "500px"};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
