import { RootState } from "../../Store";

export const getScreenId = (state: RootState) => state.whatsappFlowBuilder.data.screenId;
export const getContentData = (state: RootState) => state.whatsappFlowBuilder.data.contentData;
export const getFlowBuilderList = (state: RootState) => state.whatsappFlowBuilder.data.flowBuilderList;
export const getSubScreenList = (state: RootState) => state.whatsappFlowBuilder.data.subScreenList;
export const getScreenType = (state: RootState) => state.whatsappFlowBuilder.data.screenType;
export const getContactMapping = (state: RootState) => state.whatsappFlowBuilder.data.contactMapping;
export const getCategoriesList = (state: RootState) => state.whatsappFlowBuilder.data.categoriesList;
export const getDefaultFlowsList = (state: RootState) => state.whatsappFlowBuilder.data.defaultFlows;
export const getFormName = (state: RootState) => state.whatsappFlowBuilder.data.formName;