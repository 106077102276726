// vendors
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

export const ButtonStyled = styled(Button)(({ theme, color, variant }) => ({
  borderRadius: "6px",
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: 500,
  padding: "6px 12px !important",
  textTransform: "capitalize",
  "& .MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: "6px",
  },
  ...(color === "primary" && {
    ...(variant === "contained" && {
      backgroundColor: theme.palette.primary.light,
      "& :hover": {
        backroundColor: "#7b8fec",
      },
      "& :active": {
        backroundColor: theme.palette.primary.dark,
      },
    }),
    ...(variant === "outlined" && {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
      "& :hover": {
        borderColor: "#7b8fec",
      },
      "& :active": {
        borderColor: theme.palette.primary.dark,
      },
    }),
  }),
  ...(color === "secondary" && {
    ...(variant === "outlined" && {
      borderColor: theme.palette.default.grey,
      color: theme.palette.default.darkGrey,
    }),
  }),
}));
