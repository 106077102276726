import { List } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";

export const StyledWhatsappChooseButtonWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* max-width: 182px; */
    max-height: 180px;
    border: 1px solid ${theme.palette.default.grey};
    padding: 1rem;
    /* position: absolute;
    z-index:1;
    top: 40px;
    right: 1rem; */
  }
`;

export const StyledchooseBtnModelContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // background-color: red;

    .modelHeader {
      display: flex;
      justify-content: space-between;
      // border: 1px solid white;
      align-items: center;
      width: 100%;
      height: 56px;
      padding: 1rem;
      // background-color: green;
      background-color: ${theme.palette.primary.main};
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

      .modelheaderTitle {
        font-size: 1.1rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: ${theme.palette.default.white};
        gap: 8px;
        letter-spacing: 0.5px;
      }

      .modelHeaderAction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        .create-template {
          border: 1px solid #fff !important;
          background-color: #fff;
          color: ${theme.palette.primary.main};
        }
      }
    }
    .modelFooter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      height: 56px;
      padding: 1rem;
      background-color: ${theme.palette.default.white};
      box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

      .modelheaderTitle {
        font-size: 1.1rem;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      .modelFooterAction {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
      }
    }

    .modelBody {
      width: 100%;
      height: 100%;
      // background-color: blue;
      display: flex;
      flex-direction: row;
      // padding: 0.5rem;
      overflow: hidden;
      .modelBodyLeft {
        // border: 3px solid;
        min-width: 300px;
        border-right: 1px solid ${theme.palette.default.grey};
        width: 30%;
        height: 100%;
        // background-color: red;
        display: flex;
        flex-direction: column;
        /* align-content: flex-start;
        justify-content: space-between; */

        .modelBodyLeftTop {
          width: 100%;
          // background-color: brown;
          border-bottom: 1px solid ${theme.palette.default.grey};
          height: 56px;
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .modelBodyLeftBottom {
          width: 100%;
          height: 100%;
          height: calc(100% - 56px);
          /* margin-top: 12px; */
          padding: 2px 4px;
          // background-color: skyblue;
          display: flex;
          flex-direction: column;
          /* justify-content: center; */
          align-items: center;
          overflow-y: auto;
          overflow-x: hidden;
        }
      }
      .modelBodyRight {
        width: 100%;
        height: 100%;
        // background-color: yellow;
        padding: 1rem;
        gap: 1rem;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
          overflow-x: hidden;

        .variablesTitle{
          font-size: 1rem;
          font-weight: 500;
        }
        .variablesContent {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1rem;
        }
      }
    }
  }
`;

export const StyledModelList = styled(List)`
  && {
    padding-top: 0;
    padding-bottom: 0;

    .MuiListItem-root {
    }

    .MuiListItem-root.Mui-selected {
    }

    .MuiListItemText-root {
      span {
        font-size: 0.9rem;
      }
    }
  }
`;
