import uniqid from "uniqid";
import * as Yup from "yup";
import { ReactComponent as WB_LEFT_POS } from "../../../assets/images/whatsappBot/frameLeftPosition.svg";
import { ReactComponent as WB_RIGHT_POS } from "../../../assets/images/whatsappBot/frameRightPosition.svg";
import { ReactComponent as WB_CTA_BUTTON } from "../../../assets/images/whatsappBot/whatsappBotCTAButton.svg";
import { ReactComponent as WB_CTA_ICON } from "../../../assets/images/whatsappBot/whatsappBotCTAIcon.svg";
import { ReactComponent as TELEGRAM_CTA_BUTTON } from "../../../assets/images/telegramBot/telegramBotCTAButton.svg";
import { ReactComponent as TELEGRAM_CTA_ICON } from "../../../assets/images/telegramBot/telegramCTAIcon.svg";
import { ReactComponent as MESSENGER_CTA_BUTTON } from "../../../assets/images/messengerBot/messengerBotCTAButton.svg";
import { ReactComponent as MESSENGER_CTA_ICON } from "../../../assets/images/messengerBot/messengerWidgetPreviewIcon.svg";

export const WB_CTA_BUTTON_TPYES: any[] = [
  {
    name: "Icon",
    value: "icon",
    id: uniqid(),
    image: <WB_CTA_ICON />,
  },
  {
    name: "Button",
    value: "button",
    id: uniqid(),
    image: <WB_CTA_BUTTON />,
  },
];

export const TELEGRAM_CTA_BUTTON_TPYES: any[] = [
  {
    name: "Icon",
    value: "icon",
    id: uniqid(),
    image: <TELEGRAM_CTA_ICON />,
  },
  {
    name: "Button",
    value: "button",
    id: uniqid(),
    image: <TELEGRAM_CTA_BUTTON />,
  },
];

export const MESSENGER_CTA_BUTTON_TPYES: any[] = [
  {
    name: "Icon",
    value: "icon",
    id: uniqid(),
    image: <MESSENGER_CTA_ICON />,
  },
  {
    name: "Button",
    value: "button",
    id: uniqid(),
    image: <MESSENGER_CTA_BUTTON />,
  },
];
export const CHANNELS_WIDGET_POSITION: any[] = [
  {
    name: "Left",
    value: "Left",
    id: uniqid(),
    image: <WB_LEFT_POS />,
  },
  {
    name: "Right",
    value: "Right",
    id: uniqid(),
    image: <WB_RIGHT_POS />,
  },
];

export const WB_VISIBILITY_OPTIONS = [
  { value: "all", label: "Both (Desktop & Mobile)" },
  { value: "mobile", label: "Mobile" },
  { value: "desktop", label: "Desktop" },
];

export const STATIC_BUTTON_GRADIENTS = [
  {
    id: 0,
    gradient: "linear-gradient(180deg, #1BFF84, #09AC37)",
    startColor: "#1BFF84",
    endColor: "#09AC37",
  },
  {
    id: 1,
    gradient: "linear-gradient(180deg, #12CEFE, #5743FE)",
    startColor: "#12CEFE",
    endColor: "#5743FE",
  },
  {
    id: 2,
    gradient: "linear-gradient(180deg, #F76100, #CD1701)",
    startColor: "#F76100",
    endColor: "#CD1701",
  },
  {
    id: 3,
    gradient: "linear-gradient(180deg, #FFCDAC, #FF1396)",
    startColor: "#FFCDAC",
    endColor: "#FF1396",
  },
  {
    id: 4,
    gradient: "linear-gradient(180deg, #FFB33F, #AB6700)",
    startColor: "#FFB33F",
    endColor: "#AB6700",
  },
  {
    id: 5,
    gradient: "linear-gradient(180deg, #F70100, #A30E0D)",
    startColor: "#F70100",
    endColor: "#A30E0D",
  },
  {
    id: 6,
    gradient: "linear-gradient(180deg, #EB29EF, #A208FE)",
    startColor: "#EB29EF",
    endColor: "#A208FE",
  },
  {
    id: 7,
    gradient: "linear-gradient(180deg, #696969, #141315)",
    startColor: "#696969",
    endColor: "#141315",
  },
  // {
  //   id: 8,
  //   gradient: "linear-gradient(180deg, #FAB91C, #FF1358)",
  //   startColor: "#FF1358",
  //   endColor: "#FAB91C",
  // },
];

export const BOT_CUSTOMISE_SCHEMA = Yup.object().shape({
  name: Yup.string().min(2).max(24).required().label("Bot Name"),
  company_logo: Yup.mixed().notRequired().optional().label("Company Logo"),
  primary_color: Yup.string().min(7).max(7).required().label("Primary Color"),
  button_type: Yup.mixed()
    .label("Button Type")
    .test(
      "strongValueTest",
      "Button type is a required Field",
      (currentValue: any, ctx: any) => {
        if (currentValue?.value) {
          return true;
        }

        return false;
      },
    ),
  // button_type: Yup.mixed().required().label("Button Type"),
  button_text: Yup.string().when("button_type", {
    is: (key: any) => (key?.value === "button" ? true : false), // Check the value of "button_type"
    then: Yup.string().min(2).max(24).required().label("Button Text"), // Make "button_text" required when "button_type" is set
    otherwise: Yup.string().optional(), // Make "button_text" optional when "button_type" is not set
  }),
  button_gradient_enabled: Yup.bool(),
  // button_background_color: Yup.string().when("button_gradient_enabled", {
  //   is: (key: any) => (key ? false : true),
  //   then: Yup.string()
  //     .min(7)
  //     .max(7)
  //     .required()
  //     .label("Button Background Color"),
  //   otherwise: Yup.string().optional(),
  // }),
  button_gradient_color: Yup.mixed()
    .label("Button Gradient Color")
    .test(
      "strongValueTest34",
      "Button gradient color is a required Field",
      (currentValue: any, ctx: any) => {
        //* used to access nested objects
        let topParentArrays: any = ctx.from;
        let parentValues: any = topParentArrays?.at(-1)?.value;

        if (parentValues?.button_gradient_enabled) {
          if (currentValue?.gradient) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
    ),
  welcome_message_text: Yup.string()
    .min(2)
    .max(200)
    .required()
    .label("Welcome Message"),
  subheader_text: Yup.string().max(36).label("Subheader Text"),
  cta_button_text: Yup.string().max(20).label("CTA button text"),
  prefilled_message_text: Yup.string()
    .min(2)
    .max(200)
    .required()
    .label("Prefilled Message"),

  desktop_display_position: Yup.mixed()
    .label("Desktop Position")
    .test(
      "strongValueTest2",
      "Desktop position is a required Field",
      (currentValue: any, ctx: any) => {
        if (currentValue?.value === "Left" || currentValue?.value === "Right") {
          return true;
        }

        return false;
      },
    ),
  // desktop_display_position: Yup.mixed().required().label("Desktop Position"),
  mobile_display_position: Yup.mixed()
    .label("Mobile Position")
    .test(
      "strongValueTest6",
      "Mobile position is a required Field",
      (currentValue: any, ctx: any) => {
        if (currentValue?.value === "Left" || currentValue?.value === "Right") {
          return true;
        }

        return false;
      },
    ),

  mobile_side_margin: Yup.number()
    .min(8)
    .max(30)
    .required()
    .label("Mobile side margin"),
  mobile_bottom_margin: Yup.number()
    .min(8)
    .max(200)
    .required()
    .label("Mobile side margin"),
  desktop_side_margin: Yup.number()
    .min(8)
    .max(200)
    .required()
    .label("Desktop side margin"),
  desktop_bottom_margin: Yup.number()
    .min(8)
    .max(400)
    .required()
    .label("Desktop side margin"),

  whatsapp_number: Yup.string()
    // .required()
    .min(8, "Invalid Mobile format")
    .max(19)
    // .required()
    .label("Mobile"),
  // .test(
  //   "strongPhoneTes3t",
  //   "Please enter valid Mobile number",
  //   (currentValue: any, ctx: any) => {
  //     let mobileCountryFormat: any = ctx?.parent?.mobileCountryFormat;

  //     let format: any = mobileCountryFormat?.format
  //       ?.replaceAll(".", "0")
  //       ?.replaceAll(" ", "")
  //       ?.replaceAll("(", "")
  //       ?.replaceAll(")", "")
  //       ?.replaceAll("-", "")
  //       ?.replaceAll("+", "");

  //     let dialCodeLength: any = mobileCountryFormat?.dialCode?.length;

  //     let formatWithoutDialCode: any = format?.substring(dialCodeLength);

  //     const currentValueWithoutPlusSign = currentValue?.startsWith("+")
  //       ? currentValue?.substring(1)
  //       : currentValue;
  //     let valueWithoutDialCode: any =
  //       currentValueWithoutPlusSign?.substring(dialCodeLength);

  //     if (formatWithoutDialCode?.length === valueWithoutDialCode?.length) {
  //       let count = 0;
  //       for (var i = 0; i < valueWithoutDialCode?.length; i++) {
  //         if (
  //           valueWithoutDialCode.charAt(i) === 0 ||
  //           valueWithoutDialCode.charAt(i) === "0"
  //         ) {
  //           count = count + 1;
  //         }
  //       }

  //       if (formatWithoutDialCode?.length === count) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     } else {
  //       return false;
  //     }
  //   }
  // ),
});
