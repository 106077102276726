import { Box, Typography } from "@mui/material";
// import { WrapperBUS } from "./BUSStyles";
import { SkeletonBUS } from "../../../Dashboard/SkeletonLoader.tsx/SkeletonCard";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { WrapperBUS } from "../../Email_Broadcast_Overview_Summary/EmailBroadcastSummaryStyles";
import NoRecordImage from "../../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";

interface Props {
  statsData?: any;
  loading?:boolean;
}

const BUS: React.FC<Props> = ({ statsData,loading }) => {
  return (
    <WrapperBUS>
      <div className="Bus_wrap">
        <div className="BUS_body">
          <div className="Bounces_d">
            {
              loading ? <AppSkeletonLoader /> :
                    <div className = "Bounces_c">
                      {
                            statsData?.bounced === 0 ? (
                      <Box>
                        <NoRecordsFound
                          imageWidth={"200px"}
                          headerTextSize={"19px"}
                          bgColor="transparent"
                          imageSrc={NoRecordImage}
                        />
                      </Box>
                            ) : (
<>
                    <p className="count">{statsData?.bounced || 0}</p>
                    <p className="text">Bounces</p>
                    <Box width={"65%"}>
                      <Typography
                        className="description"
                        fontSize={"12px"}
                        fontWeight={"400"}
                      >
                        Their inbox was unavailable. They were removed from your list.
                      </Typography>
                    </Box>
</>
                            )
                      }                          
                  </div>
                  
            }
            
          </div>
          <div className="Bounces_d">
            {
              loading ? <AppSkeletonLoader /> : <div className="Bounces_c">
                {
                  statsData?.unsubscribed === 0 ? (
                    <Box>
                      <NoRecordsFound
                        imageWidth={"200px"}
                        headerTextSize={"19px"}
                        bgColor="transparent"
                        imageSrc={NoRecordImage}
                      />
                    </Box>
                  ) : (
                    <>
                        <p className="count">{statsData?.unsubscribed || 0}</p>
                        <p className="text">Unsubscribed</p>
                        <Box width={"65%"}>
                          <Typography
                            className="description"
                            fontSize={"12px"}
                            fontWeight={"400"}
                          >
                            They removed themselves from your list.
                          </Typography>
                        </Box>
                    </>
                  )
                }    
            </div>
            }
            
          </div>
          <div className="Bounces_d">
            {
              loading ? <AppSkeletonLoader/> : <div className="Bounces_c">
                {
                  statsData?.abusereport === 0 ? (
                    <Box>
                      <NoRecordsFound
                        imageWidth={"200px"}
                        headerTextSize={"19px"}
                        bgColor="transparent"
                        imageSrc={NoRecordImage}
                      />
                    </Box>
                  ) : (
                    <>
                        <p className="count">{statsData?.abusereport || 0}</p>
                        <p className="text">Marked spam</p>
                        <Box width={"65%"}>
                          <Typography
                            className="description"
                            fontSize={"12px"}
                            fontWeight={"400"}
                            bgcolor={"transparent"}
                          >
                            No subscribers marked your emails as spam.
                          </Typography>
                        </Box>
                    </>
                  )
                }
              
            </div>
            }
            
          </div>
        </div>
      </div>
    </WrapperBUS>
  );
};

export default BUS;
