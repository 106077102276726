import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { getSubscriptionData } from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import { SubscriptionsNetworkService } from "../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import {
  ContentTitle,
  LivePlanContainer,
  TrialTitle
} from "./style";
interface MenuProps extends HocBackdropLoaderProps {
  list: any;
}
const NavigationBar: React.FC<MenuProps> = ({ list, setLoading }) => {
  const { userConfig } = useSelector(useAuthStore);
  const getActiveSubscriptionData = useSelector(getSubscriptionData);
  const navigate = useNavigate();
  const renewSubscription = () => {
    setLoading(true);
    let planData = {
      plan: {
        plan_code: getActiveSubscriptionData?.plan_code,
        quantity: 1,
      },
      redirect_url: "/subscription/success",
      customer_id: userConfig?.payload?.customer_id,
      business_id: userConfig?.payload?.business_id,
    };
    SubscriptionsNetworkService.updateSubscriptionData(
      planData,
      getActiveSubscriptionData?.subscription_id
    )
      .then((res: any) => {
        if (res?.data?.url) {
          window.open(res?.data?.url, "_self");
        }
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <ContentTitle>
        {list?.map((res: any, index: number) => {
          return (
            <React.Fragment key={index}>
              <a
                onClick={() => (list?.length - 1 !== index ? navigate(-2) : "")}
                style={{ cursor: "pointer" }}
              >
                {list?.length > 1 ? (
                  <AppMaterialIcons
                    iconName={
                      list?.length - 1 === index
                        ? "ArrowForwardIosOutlined"
                        : "ArrowBackIosOutlined"
                    }
                    style={{ fontSize: "18px", margin: "-2px 8.5px" }}
                  />
                ) : null}
                {res}
              </a>
            </React.Fragment>
          );
        })}
        {list.length === 1 && userConfig?.payload?.overdue_days > 0 ? (
          <LivePlanContainer>
            <TrialTitle>
              Your plan will expire in {userConfig?.payload?.overdue_days} days{" "}
            </TrialTitle>
          </LivePlanContainer>
        ) : null}
      </ContentTitle>
    </>
  );
};

export default HocBackdropLoader(NavigationBar);
