// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";

// components
import Icon from "../Components/Icon";
import SearchInput from "../Components/SearchInput";
import InputOptionList from "../Components/InputOptionList";

// hooks
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";

// utils
import { groupByCategory } from "../Utils";

// data
import { registerNodes } from "../Config/RegisterNodes";

interface OptionsPopoverProps {
  open: HTMLElement | null;
  onClose: () => void;
  onOptionSelect: (option: any, type?: string) => void;
}

const OptionsPopover = (props: OptionsPopoverProps) => {
  const { open, onClose, onOptionSelect } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const currentBot = useSelector(useCurrentBot);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleOptionClick = (option: any) => {
    onOptionSelect(option);
  };

  const isInitialNode = (registerNodes: any, type?: string) =>
    registerNodes.find((item: any) => item.type === type)?.isStart;

  const groupInputs = () => {
    const addableOptions = searchTerm
      ? registerNodes.filter(
          (item: any) =>
            !isInitialNode(registerNodes, item.type) &&
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            item?.supports?.includes(currentBot?.channel)
        )
      : currentBot?.channel && currentBot?.channel !== null
      ? registerNodes
          .filter((item: any) => !isInitialNode(registerNodes, item.type))
          .filter((item: any) => item?.supports?.includes(currentBot?.channel))
          // Temp hide language option from nodes
          .filter((item: any) => item.type !== "language")
      : registerNodes
          .filter((item: any) => !isInitialNode(registerNodes, item.type))
          // Temp hide language option from nodes
          .filter((item: any) => item.type !== "language");
    return groupByCategory(addableOptions);
  };

  useEffect(() => {
    setSearchResults(groupInputs());
  }, [searchTerm]);

  return (
    <Popover
      open={Boolean(open)}
      onClose={onClose}
      anchorEl={open}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{ mt: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)" }}
      elevation={0}
      slotProps={{
        // @ts-ignore
        paper: {
          sx: {
            borderRadius: "8px",
            boxShadow:
              "0px 13.9892px 69.9461px rgba(126, 131, 146, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.08);",
          },
        },
      }}
    >
      <Box
        sx={{
          minWidth: "380px",
          maxWidth: "380px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: "16px" }}
        >
          <Typography
            sx={{ color: "#101010" }}
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
          >
            Flow Node
          </Typography>
          <IconButton onClick={onClose} sx={{ height: "30px", width: "30px" }}>
            <Icon icon="close" size={24} color="default.darkGrey" />
          </IconButton>
        </Stack>
        <Box sx={{ py: "16px" }}>
          <Box sx={{ p: "16px", pt: 0 }}>
            <SearchInput
              placeholder="Search"
              onChange={handleSearchInputChange}
              value={searchTerm}
            />
          </Box>
          {searchResults.length > 0 ? (
            <InputOptionList
              list={searchResults}
              onOptionClick={(option) => handleOptionClick(option)}
            />
          ) : (
            <Typography
              sx={{ px: "16px" }}
              align="center"
              color="#7E8392"
              fontWeight={400}
              fontSize={"14px"}
              lineHeight="21px"
            >
              No match found!
            </Typography>
          )}
        </Box>
      </Box>
    </Popover>
  );
};
export default OptionsPopover;
