import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WhatsappSettingsActions } from "./whatsappSettings.actions";

interface initialStateTypes {
    data: {
        whatsappConfigData: any;
        whatsappConfigList: any
    };
    error: string;
    loading: boolean;
}

const initialState: initialStateTypes = {
    data: {
        whatsappConfigData: null,
        whatsappConfigList: null
    },
    error: "",
    loading: false,
};

const WhatsappSettingsSlice = createSlice(
    {
        name: "WhatsappSettingsSlice",
        initialState,
        reducers: {
            resetError: (state: any, action?: PayloadAction<any>) => {
                state.error = "";
                return state;
            }
        },
        extraReducers: (builder) => {
            builder
                // add  whatsapp config data
                .addCase(
                    WhatsappSettingsActions.whatsappConfigData.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    WhatsappSettingsActions.whatsappConfigData.fulfilled,
                    (state, action) => {
                        if (action?.payload?.data?.status === 200) {
                            state.data.whatsappConfigData = true
                        } else {
                            state.data.whatsappConfigData = null
                        }

                    }
                )
                .addCase(
                    WhatsappSettingsActions.whatsappConfigData.rejected,
                    (state, action) => {
                        state.loading = false
                        state.error = action?.error?.message || ""
                    }
                )


                // get  whatsapp config data list
                .addCase(
                    WhatsappSettingsActions.whatsappConfigList.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    WhatsappSettingsActions.whatsappConfigList.fulfilled,
                    (state, action) => {
                        state.data.whatsappConfigList = action.payload.data;
                        state.error = "";
                        state.loading = false;
                    }
                )
                .addCase(
                    WhatsappSettingsActions.whatsappConfigList.rejected,
                    (state, action) => {
                        state.loading = false;
                        state.error = action?.error?.message || ""
                    }
                )
        }
    }
)
export const { resetError } = WhatsappSettingsSlice.actions;
export default WhatsappSettingsSlice.reducer;