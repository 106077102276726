import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

const LinearLoader = () => {
  return (
    <StyledFullPageBox>
      <StyledLinearLoader />
    </StyledFullPageBox>
  );
};

export default LinearLoader;

const StyledFullPageBox = styled.div`
  && {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    z-index: 1000;
  }
`;

const moving = keyframes`
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: unset;
  }
`;

const StyledLinearLoader = styled.div`
  display: block;
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: ${moving} 1s ease-in-out infinite;
  }
`;
