import styled from "styled-components";
export const GrahWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    height: 100%;

    .time_stats {
      font-size: 18px;
      display: flex;
      font-family: Inter;
      color: #101010;
      font-weight: 500;
      .div_tooltip {
        font-size: 15px;
      }
    }
    .header_avg {
      font-size: 16px;
      font-family: Inter;
      font-weight: 500;
      text-align: left;
    }
    .progess_bar {
      padding: 0 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: end;
      text-align: center;
      z-index: 998;
      .css-1d3ml05-MuiPopper-root-MuiTooltip-popper {
        z-index: 998;
      }
    }
    .progress_bar_text {
      width: 15%;
      font-family: Inter;
    }
    .progress_bar_count {
      width: 85%;
      font-family: Inter;
      .stats {
        display: flex;
        justify-content: end;
        padding-bottom: 0.8rem;
      }
    }
    .graph_container {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 20px;
      font-family: Inter;
    }
    .x_axis_data {
      width: 85%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #4d4f52;
      font-family: Inter;
    }
  }
`;

export const RecipientsWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    position: relative;

    .main_header {
      font-size: 20px;
      font-weight: 600;
      font-family: Inter;
      color: #ffffff;
    }

    .info_values {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .header {
      font-size: 20px;
      text-align: center;
      font-family: Inter;
      font-weight: 600;
      text-align: center;
      color: #ffffff;
    }
    .value {
      font-size: 50px;
      font-weight: 700;
      font-family: Inter;
      text-align: center;
      color: #ffffff;
    }
    .credits_btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 38px;
      background-color: #ffffff;
      color: black;
      margin-top: 10px;
    }
  }
`;
