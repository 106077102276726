import { useState } from "react";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { useAppSelector } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { StyledLookFeelContainer } from "../../../utils/styles-bot-builder";
import { BotWelcomeMessage } from "../../../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";

// import WebBotLookFeel from "./WebBotLookFeel";
// import WhatsappLookFeel from "./WhatsappLookFeel";

const WelcomeMessage = () => {
  const { showAlert } = useNotification();
  const currentBot = useAppSelector(useCurrentBot);
  const [userInput, setUserInput] = useState<any>({
    welcome_message: "",
  });
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (!currentBot?.id) {
      SubmitProps.setSubmitting(false);
      return;
    }
    let payload = values;
    ChatbotConsoleService.updatePersistentMenu(
      currentBot.bot_id as string,
      {
        greeting: payload,
      },
      currentBot?.channel === "messenger"
        ? "facebook-configuration"
        : "telegram-configuration",
    )
      .then((response) => {
        SubmitProps.setSubmitting(false);
        showAlert("Message updated successfully", "success");
      })
      .catch((error) => {
        SubmitProps.setSubmitting(false);
        showAlert(error?.response?.message, "error");
      });
  };
  return (
    <>
      <StyledLookFeelContainer>
        <AppSmallWindowPane
          style={{
            minWidth: "55%",
            borderRadius: "8px",
            padding: "1.1rem 1.5rem 0.5rem 1.5rem",
            position: "relative",
          }}
        >
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            validationSchema={BotWelcomeMessage}
          >
            <AppFormTextArea
              label="Welcome Message"
              placeholder="Please enter a greeting message"
              name="welcome_message"
            />
            <AppSubmitButton
              variant="cta"
              style={{ width: "100%", margin: "0.5rem 0" }}
              title={"Save"}
            />
          </AppForm>
        </AppSmallWindowPane>
      </StyledLookFeelContainer>
    </>
  );
  //   if (currentBot?.channel === "whatsapp") {
  //     return <WhatsappLookFeel />;
  //   }

  //   return <WebBotLookFeel />;
};

export default WelcomeMessage;
