import { LeadPageWrapper, MessagesTable } from "../../../LeadsStyles";
import { Box, IconButton, Paper } from "@mui/material";
import AppButton from "../../../../../AppComponents/AppButton";
import { DeleteCannedResponse } from "../../../../Broadcast/WhatsApp/Templates/TemplatesListing";
import Danger from "../../../../../assets/images/danger-live.svg";
import { ReactComponent as TagCrossIcon } from "../../../../../assets/images/tag-cross-icon.svg";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { ReactComponent as Assign } from "../../../../../assets/images/assign.svg";
import { ReactComponent as FrameIcon } from "../../../../../assets/images/frame-icon.svg";
import { ReactComponent as NoRecord } from "../../../../../assets/images/noDatafound.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/deletegrey.svg";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import useModal from "../../../../../Hooks/useModel/useModel";
import { useEffect, useState } from "react";
import { ContactsActions } from "../../../../../Store/Slices/Contacts/contacts.action";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import {
  Files,
  fileUploaded,
} from "../../../../../Store/Slices/Contacts/contacts.selector";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import DragDropUpload from "../../../../../AppComponents/Forms/AppUploadFiles";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import ProtectedAppButton from "../../../../../AppComponents/ProtectedComponents/Button";
import AppCustomTable from "../../../../../AppComponents/AppCustomTable";
import useRolesPermissionsAccess from "../../../../../Hooks/useRolesPermissionsAccess";
import { CreateCoulumns } from "../../../../../Utils/tableUtils/TableUtils";
import { ProtectedAppUiElement } from "../../../../../AppComponents/ProtectedComponents";
import AppNoDataLoader from "../../../../../AppComponents/AppNoDataLoader";
import { AppPagination } from "../../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { resetfileUploadedSuccess } from "../../../../../Store/Slices/Contacts/contacts.slice";

interface Props extends LoaderComponentProps {}
const LeadFiles = (props: Props) => {
  // roles & permissions
  const { isValidAccess, isContentReady } =
    useRolesPermissionsAccess("add_files");

  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
  });
  const { setLoading } = props;
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const contactFiles: any = useAppSelector(Files);
  const fileUploadedSuccess = useAppSelector(fileUploaded);

  const params = useParams();
  const { contact_id } = params;

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 10,
  });

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [resetFiles, setResetFiles] = useState<any>(false);
  // Table Functions
  const [dynamicsColumn, setdynamicsColumn] = useState<any>([
    {
      id: "id",
      label: "id",
    },
    { id: "file_name", label: "Name" },
    { id: "uploaded_by", label: "Uploaded by" },
    { id: "file_type", label: "Type" },
    { id: "file_size", label: "Size" },
    { id: "uploaded_at", label: "Uploaded at" },
  ]);
  const [dynamicsColumns, setDynamicsColumns] = useState<any>([]);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);

  const fileDownload = (fileId: any) => {
    let data = {
      contact_id: contact_id,
      id: fileId,
    };
    dispatch(ContactsActions.downloadFile(data))
      .then((res: any) => {
        if (res?.payload?.data?.url) {
          window.open(res.payload.data.url, "_blank");
        } else {
          showAlert(res?.payload?.data?.error, "error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (contactFiles?.data?.length) {
      CreateCoulumns(
        !isValidAccess,
        dynamicsColumn,
        setDynamicsColumns,
        fileDownload,
      );
    }
  }, [contactFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fileUploadedSuccess && fileUploadedSuccess !== null) {
      showAlert("File(s) uploaded successfully", "success");
      getFiles();
      setResetFiles(true);
    } else if (fileUploadedSuccess === false) {
    }
  }, [fileUploadedSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get all files
  const getFiles = () => {
    setLoading(true);
    dispatch(
      ContactsActions.getFiles({
        contact_id: contact_id,
        limit: localValues.itemsPerPage,
        offset: localValues.currentPage,
      }),
    )
      .then((res: any) => {
        // setPagination()
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getFiles();
    dispatch(resetfileUploadedSuccess({}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const useModal1 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal1: isShowing, toggleModal1: toggle };
  };

  const useModal2 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal2: isShowing, toggleModal2: toggle };
  };

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };
  useEffect(() => {
    if (localValues) {
      getFiles();
    }
  }, [localValues]);

  const { isShowingModal1, toggleModal1 } = useModal1();
  const { isShowingModal2, toggleModal2 } = useModal2();

  const saveUploadedFiles = () => {
    setLoading(true);
    let payload: any = new FormData();
    payload.append("contact_id", contact_id);
    selectedFiles.forEach((file: any) => {
      payload.append("file[]", file);
    });
    setResetFiles(true);
    dispatch(ContactsActions.addFiles(payload))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
        } else {
          toggleModal2();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteFile = () => {
    let data = {
      contact_id: contact_id,
      ids: selectedDocumentIds,
    };
    dispatch(ContactsActions.deleteFile(data))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
        } else {
          showAlert(res?.payload?.data?.msg, "success");
          getFiles();
          toggleModal1();
          setSelectedDocumentIds(false);
        }
        // setPagination()
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <FilesWrapper>
      <div className="userprofile-main-div">
        <LeadPageWrapper>
          <div className="contact-task">
            <div className="task-heading">
              Files{" "}
              <span className="task-button-span">
                ({contactFiles?.data?.length || 0})
              </span>
              {selectedDocumentIds.length > 0 && (
                <AppButton variant="danger" onClick={() => toggleModal1()}>
                  {" "}
                  <AppMaterialIcons iconName="delete" />
                  Delete Files
                </AppButton>
              )}
            </div>
            <div className="task-buttons">
              {contactFiles?.can_update && (
                <AppButton onClick={() => toggleModal2()}>
                  <AppMaterialIcons iconName="add" />
                  Add File
                </AppButton>
              )}
            </div>
          </div>
          <div className="data-container" style={{ overflow: "auto" }}>
            {contactFiles?.data?.length > 0 && dynamicsColumns?.length > 0 ? (
              <AppCustomTable
                data={contactFiles?.data}
                dynamicsColumns={dynamicsColumns}
                setSelectedRow={setSelectedDocumentIds}
                selectedRow={selectedDocumentIds}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  padding: "4rem 2rem 2rem 2rem",
                  boxShadow: "none important",
                  backgroundColor: "white",
                }}
              >
                <Paper
                  sx={{
                    width: "40%",
                    mb: 2,
                    boxShadow: "none !important",
                    backgroundColor: "transparent",
                  }}
                >
                  <AppNoDataLoader />
                </Paper>
              </Box>
            )}
          </div>
        </LeadPageWrapper>
      </div>

      <AppModel
        isShowing={isShowingModal1}
        onClose={() => toggleModal1()}
        divStyle={{
          width: "550px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The File</h4>
                <p>
                  Are you sure, you want to delete {selectedDocumentIds?.length}{" "}
                  file(s)?
                </p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal1()}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal1()} style={{}}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => deleteFile()}
              style={{}}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>

      <AppModel
        isShowing={isShowingModal2}
        onClose={() => toggleModal2()}
        divStyle={{
          width: "460px",
          height: "416px",
          // gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <ModalWrapper>
          <ModalHeader>
            <div className="mark-complete-text-class">Add file</div>
            <div className="popup-heading-icon">
              <IconButton onClick={() => toggleModal2()}>
                <TagCrossIcon />
              </IconButton>
            </div>
          </ModalHeader>
          <DragDropUpload
            sendToParent={setSelectedFiles}
            resetFiles={resetFiles}
            setResetFiles={setResetFiles}
          />
          <div className="cancle-save-buttons">
            <AppButton onClick={() => toggleModal2()} variant="grey">
              cancel
            </AppButton>
            <AppButton
              onClick={() => {
                saveUploadedFiles();
              }}
            >
              <IconButton>
                <FrameIcon />
              </IconButton>
              save
            </AppButton>
          </div>
        </ModalWrapper>
      </AppModel>
      <AppPagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={localValues?.currentPage}
        count={contactFiles?.total_count}
        rowsPerPage={localValues?.itemsPerPage}
      />
    </FilesWrapper>
  );
};
export default withLoader(LeadFiles);

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .mark-complete-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    padding-left: 16px;
  }
  .mark-complete-text-class {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    /* padding-left: 16px; */
  }
  .mark-btn button {
    background: none;
    border: none;
  }
  .cancle-save-buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    padding-top: 15px;
    border-top: 1px solid ${theme.palette.default.border};
    svg path {
      fill: ${theme.palette.primary.main};
    }
  }
`;
const ModalHeader = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 15px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.palette.default.border};
`;
const FilesWrapper = styled.div`
  background: ${theme.palette.default.white};
  margin: 0 15px;
  .task-button-span,
  .task-heading {
    display: flex;
    align-items: center;
    gap: 05px;
    font-weight: bold;
    padding: 0;
    button {
      margin-left: 20px;
      line-height: 10px;
    }
  }
  .mark-complete-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    padding-left: 16px;
  }
  .mark-complete-text-class {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    /* padding-left: 16px; */
  }
  .mark-btn button {
    background: none;
    border: none;
  }
  .mark-complete-close-icon {
    color: red;
  }
  .mark-complete-buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    /* margin-top: 28%; */
  }
`;
