import { RootState } from "../../../index";

// answered Listing
export const AnsweredListing = (state: RootState) =>
  state?.faqUserQueries?.data?.answeredListing;
// answered Listing
export const answeredListingCount = (state: RootState) =>
  state?.faqUserQueries?.data?.answeredListingCount;
// answered total pages
export const pagesCount = (state: RootState) =>
  state?.faqUserQueries?.data?.totalPages;

// answered flow options
export const AnsweredFlowOptionData = (state: RootState) =>
  state?.faqUserQueries?.data?.answeredFlowOptionData;

// answered faq questions
export const AnsweredFaqQuestionOptionData = (state: RootState) =>
  state?.faqUserQueries?.data?.answeredFaqQuestionOptionData;

// answered faq questions
export const NotAnsUserInput = (state: RootState) => {
  return state?.faqUserQueries?.data?.newInput;
};
export const AnsweredSubmitData = (state: RootState) =>
  state?.faqUserQueries?.data?.answeredSubmitData;
