// vendors
import { styled } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React from "react";

// components
import Icon from "../icon";

const UIButtonGroupStyled = styled(ButtonGroup)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  fontWeight: 500,
  lineHeight: "21px",
  borderRadius: 4,
  borderColor: "#5B73E8",
  flex: 1,
  "&:hover": {
    borderColor: "#5B73E8",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    borderColor: "#5B73E8",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem  rgba(91, 115, 232, 0.4)",
  },
  "&:focus-within": {
    boxShadow: "0 0 0 0.2rem  rgba(91, 115, 232, 0.4)",
  },
  
  "& .MuiButtonBase-root": {
    textTransform: "none",
  }
});

const UIButtonGroup = (props: {
  ref?: any,
  children: React.ReactNode | string;
  fullWidth: boolean;
  withOptions: boolean;
  onClick?: (event:any) => void;
  id?: string;
  icon?: string;
}) => {
  const {
    id,
    children,
    fullWidth,
    withOptions,
    icon="options",
    onClick,
    ref,
  } = props;
  return (
    <Stack spacing={2} direction="row" sx={{ ...(fullWidth && { flex: 1})}}>
      <UIButtonGroupStyled
        variant="outlined"
        disableRipple
        onClick={onClick}
        {...props}
      >
        <Button fullWidth>{children}</Button>
        {withOptions && (
          <Button id={id} size="small" sx={{ width: "40px" }} >
            <Icon disableFill icon={icon} size={12} />
          </Button>
        )}
      </UIButtonGroupStyled>
    </Stack>
  );
};

UIButtonGroup.defaultProps = {
  fullWidth: false,
  withOptions: false,
};

export default UIButtonGroup;
