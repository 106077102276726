import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormRadioField,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import { theme } from "../../../../Customization/Theme";
import { AppMaterialIcons } from "../../../appComponents/Icons";

import { useAppDispatch, useAppSelector } from "../../../../Store";
import { LiveChatData } from "../../../../Store/Slices/socket/liveChat/chat.selectors";
import AppReactSelectField from "../../../appComponents/UtilsComponents/AppFormReactSelect";

import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../HOC/HocBackdropLoader";
import { removeAllMessages } from "../../../../Store/Slices/socket/AllMessagesSlice";
import { LiveChatActions } from "../../../../Store/Slices/socket/liveChat/chat.actions";
import {
  TEMP_ACTIVE_FILTERS,
  setChatSessionActiveFilters,
} from "../../../../Store/Slices/socket/liveChat/chat.slice";
import { getChatSessionFilterPayloadData } from "../../../utils/utils";
import {
  chatFilterConversationStatusOptions,
  chatFilterOrderByOptions,
  chatFilterStatusOptions,
} from "./constants";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";

interface Props extends HocBackdropLoaderProps {
  setIsFilterScreenOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isFilterScreenOpen: boolean;
}

const ChatFiltersScreen: React.FC<Props> = ({
  setIsFilterScreenOpen,
  isFilterScreenOpen,
  setLoading,
}) => {
  const dispatch = useAppDispatch();
  const {
    allAgentsData,
    allLabelsData,
    allChannelsData,
    chatSessionActiveFilter,
  } = useAppSelector(LiveChatData);
  const { userConfig } = useAppSelector(useAuthStore);

  const getSessionsList = (values?: any) => {
    let data = getChatSessionFilterPayloadData(
      values ?? chatSessionActiveFilter
    );
    const config = {
      limit: 20,
      offset: 0,
      data: data,
    };

    setLoading(true);
    dispatch(LiveChatActions.getSessionsListings(config))
      .then(() => {
        setIsFilterScreenOpen(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitFn = (values: any, submitProps: any) => {
    submitProps?.setSubmitting(false);
    let tempValues: any = { ...values };

    if (tempValues?.conversation) {
      delete tempValues.conversation;
    }
    if (tempValues?.search) {
      delete tempValues.search;
    }

    dispatch(removeAllMessages({}));

    dispatch(setChatSessionActiveFilters(tempValues));

    setLoading(true);

    getSessionsList(tempValues);
  };

  const handleResetBtn = () => {
    dispatch(removeAllMessages({}));
    let data = {
      ...TEMP_ACTIVE_FILTERS,
      chat_status: [],
    };

    dispatch(setChatSessionActiveFilters(data));

    getSessionsList(data);
  };

  return (
    <StyledChatFilterScreenWrap>
      <AppForm
        initialValues={chatSessionActiveFilter}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitFn(values, submitProps);
        }}
        // validationSchema={LiveChatFilterSchema}
        validateOnChange={false}
      >
        <div className="fixed-top" style={{ paddingLeft: "14%" }}>
          Filters{" "}
          <IconButton
            className="filter"
            onClick={() => setIsFilterScreenOpen(false)}
          >
            <AppMaterialIcons iconName="close" style={{ fontSize: "20px" }} />
          </IconButton>
        </div>
        <div className="scrollable-content">
          <AppReactSelectField
            name="chat_status"
            options={chatFilterStatusOptions ?? []}
            label="Chat Status"
            valueKey={"value"}
            isMulti={true}
          />
          <AppReactSelectField
            name="conversation_status"
            options={chatFilterConversationStatusOptions ?? []}
            label="Conversation Status"
            valueKey={"value"}
            isMulti={true}
          />
          <AppReactSelectField
            name="labels"
            options={allLabelsData ?? []}
            label="Labels"
            valueKey={"id"}
            isMulti={true}
          />
          <AppReactSelectField
            name="channels"
            options={allChannelsData ?? []}
            label="Channels"
            valueKey={"value"}
            isMulti={true}
          />
          {userConfig?.payload?.role?.name === "admin" ? (
            <AppReactSelectField
              name="agents"
              options={allAgentsData ?? []}
              label="Agents"
              valueKey={"id"}
              isMulti={true}
            />
          ) : null}

          <AppFormRadioField
            name="order"
            Options={chatFilterOrderByOptions ?? []}
            label="Sort By"
          />
        </div>
        <div className="fixed-bottom" style={{ paddingBottom: "0.7rem" }}>
          <AppButton variant="danger" onClick={() => handleResetBtn()}>
            Reset
          </AppButton>{" "}
          <AppSubmitButton title={"Apply Filters"} />
        </div>
      </AppForm>
    </StyledChatFilterScreenWrap>
  );
};

export default HocBackdropLoader(ChatFiltersScreen);

const StyledChatFilterScreenWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    padding: 0rem 0rem;
    display: flex;
    flex-direction: column;

    .fixed-top,
    .fixed-bottom {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1rem;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    }

    .scrollable-content {
      flex: 1;
      height: 100%;
      min-height: calc(100vh - 200px);
      max-height: 250px;
      overflow: auto;
      padding: 0.5rem 1rem;
    }

    .filter {
      width: 28px;
      height: 28px;
      & .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
        font-size: 1.4rem;
      }
    }
  }
`;
