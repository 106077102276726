import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import styled from 'styled-components'
import AppCustomTooltip from '../../../../../AppComponents/AppTooltip'
import { AppForm, AppFormField, AppFormSelectField, AppFormSwitchField } from '../../../../../AppComponents/Forms'
import AppFormRichTextEditor from '../../../../../AppComponents/Forms/AppFormRichTextEditor'
import AppFormTimePicker from '../../../../../AppComponents/Forms/AppFormTimePicker'
import { theme } from '../../../../../Customization/Theme'
import FieldLabel from '../../../Components/FieldLabel'

const ScheduleSetting = (): JSX.Element => {
  const options = [
    {

      label: "Restrict Number of Submissions",
      text: "Hello"
    },
    {

      label: "Restrict to One Submission per IP",
      text: "Hello"
    },
    {

      label: "Restrict to One Submission per IP",
      text: "Hello"
    }

  ];
  return (
    <AppForm initialValues={{}} validationSchema={undefined} onSubmit={() => console.log("Hello")}>
      <Container>
        <div className='container'>
          <FieldLabel label='Schedule Form' isTooltipAvaliable={true} tooltipText='Hii' />
          <AppFormSwitchField name="schedule" />
        </div>
        <p className='infoText'>The form will be accessible only between the specified dates and times.</p>
        <div className='container'>
          <AppFormSelectField name='startDate' label='Start Date & Time' Options={[]} labelStyle={{ color: `${theme.palette.default.lighterFilterGrey}` }} />
          <AppFormTimePicker name='starttime' label="" placeholder="Select time" />
        </div>
        <div className='container'>
          <AppFormSelectField name='startDate' label='End Date & Time' Options={[]} labelStyle={{ color: `${theme.palette.default.lighterFilterGrey}` }} />
          <AppFormTimePicker name='endtime' label="" placeholder="Select time" />
        </div>
        <p className='red-alert'>*All times are in Asia/Kolkata</p>
        <FieldLabel label='Message on form expiry' isTooltipAvaliable={true} tooltipText='Hello' />
        <AppFormRichTextEditor name='expiry' mediaKey={"expiry"} />
        <OptionsContainer>
          {options.map((option, index: number) => (
            <div className='container' key={index}>
              <span className='tooltip'>
                <AppCustomTooltip placement="right" title={option.text}>
                  <InfoOutlinedIcon fontSize="small" />
                </AppCustomTooltip>
              </span>
              <FieldLabel label={option.label} />
              <span className="switch-field">
                <AppFormSwitchField name='actions' />
              </span>
            </div>
          ))}
        </OptionsContainer>
        <MaxLimitFieldContainer>
          <FieldLabel label='Maximum submission limit' />
          <AppFormField name="limit" />
        </MaxLimitFieldContainer>
        <FieldLabel label='Message on reaching limit' isTooltipAvaliable tooltipText='Hey' />
        <AppFormRichTextEditor name='messaheonTextLimit' mediaKey={"messaheonTextLimit"} />
      </Container>
    </AppForm>
  )
}

const Container = styled.div`
&&{
  max-width: 900px;
  .infoText{
    color:${theme.palette.default.darkGrey};
    padding: 1rem 0;
  }
  .container{
      display:flex;    
      align-items:end;
      justify-content:center;
      gap:20px;
  }
  .red-alert{
    color: #FF4A4A;
    padding: 1rem 0;
  }
}`
const OptionsContainer = styled.div`
&&{
  display:flex;
  gap:20px;
  padding: 1rem 0;
  .container {
    position: relative;
    width: 180px;
    height: 113px;
    border-radius: 6px;
    border: 1px solid #C0CBFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.07);
    display:flex;
    flex-direction:column;
    align-items:center; 
    .tooltip{
      position:absolute;
      top: 10px;
      right: 10px;
    }
    .switch-field{
      position:absolute;
      top: 80px;
      right: 50px;
    }
  }
}`

const MaxLimitFieldContainer = styled.div`
&&{
  width: 400px;
}`
export default ScheduleSetting