import Box from "@mui/material/Box";

import UploadHandlers from "./handlers";
import InfoCard from "./handlers/info";

interface UploadSectionProps {
  type: string;
  onSave: (type: string, url: string) => void;
  url: string;
  onDelete: () => void;
}

const UploadSection = (props: UploadSectionProps) => {
  const { type, onSave, url, onDelete } = props;

  // @ts-ignore
  const UploadHandler = UploadHandlers[type];

  const handleSave = (url: string) => {
    onSave && onSave(url, type);
  };

  const handleDelete = () => {
    onDelete && onDelete();
  };

  return (
    <Box>
      <UploadHandler url={url} onSave={handleSave} onDelete={handleDelete} />
    </Box>
  );
};
export default UploadSection;
