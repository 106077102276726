import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppForm, AppSubmitButton } from "../../../AppComponents/Forms";
import AppButton from "../../../AppComponents/AppButton";
import { ReactComponent as SaveIcon } from "../../../assets/images/save-icon.svg";

import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { AssigneeList } from "../../../Store/Slices/Contacts/contacts.selector";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { BulkAssignTo } from "../../../Models/ValidationSchema";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../HOC/HocBackdropLoader";
interface Props extends HocBackdropLoaderProps {
  closeModal: any;
  payload: any;
  SubmitReset?: any;
  hideTitle?: any;
  ownerLabel?: any;
}
const AssignTo: React.FC<Props> = ({
  closeModal,
  payload,
  SubmitReset,
  hideTitle,
  ownerLabel,
  setLoading,
}) => {
  let payLoad = payload;
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // states
  const [userInput, setUserInput] = useState({});
  const [assignData, setAssignData] = useState<string[]>([]);
  // form key
  const formkey9 = "assign_to";
  // assignee list data from redux
  const assigneeList = useAppSelector(AssigneeList);
  // get list data
  useEffect(() => {
    dispatch(ContactsActions.getAssigneeList({}))
      .then(() => { })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => { });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (assigneeList?.length > 0) {
      let list: string[] = assigneeList?.map((res: any) => {
        let obj: any = {
          label: res?.first_name + " " + res?.last_name,
          value: res?.id,
        };
        return obj;
      });
      if (ownerLabel) {
        setUserInput({
          [formkey9]: list?.filter(
            (owner: any) => owner.label === ownerLabel
          )[0],
        });
      }
      setAssignData(list);
    }
  }, [assigneeList]); // eslint-disable-line react-hooks/exhaustive-deps

  // post call for bulk add tags
  const handleSubmitBtn = (values: any, submitProps: any) => {
    submitProps.setSubmitting(true);
    setLoading(true);
    payLoad["contact_owner"] = values[formkey9].value;
    dispatch(ContactsActions.addAssignee(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(res?.payload?.data?.message || "Rejected", "error");
          submitProps.setSubmitting(false);
        } else {
          submitProps.setSubmitting(false);
          setUserInput({ [formkey9]: [] });
          showAlert("Contact assigned", "success");
          SubmitReset && SubmitReset();
          setLoading(false);
        }
      })
      .catch((error: any) => {
        submitProps.setSubmitting(false);
        showAlert(error?.response?.statusText || "not assign", "error");
      })
      .finally(() => {
        delete payLoad.contact_owner;
        submitProps.setSubmitting(false);
        setLoading(false);
      });
  };
  return (
    <Box sx={{
      minWidth: "671px",
    }} >
      {!hideTitle && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
              alignItems: "center",
            }}
          >
            <span style={{ fontWeight: "500", fontSize: "20px" }}>
              Assign to new
            </span>
            <CloseIcon
              onClick={() => closeModal()}
              sx={{
                cursor: "pointer",
              }}
            />
          </Box>
          <Divider />
        </>
      )}
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={BulkAssignTo}
        divStyle={{
          gap: "0px",
          padding: "0px 16px 0px 0px"
        }}
      >
        <Box sx={{ flex: 1, padding: "16px" }}>
          <AppReactSelectField
            label="Assign To"
            menuPosition="fixed"
            name={formkey9}
            isMulti={false}
            options={assignData}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
            gap: "6px",
          }}
        >
          <AppButton
            style={{ width: "115px", }}
            variant="outline"
            startIcon="add"
            onClick={() => closeModal()}
          >
            Cancel
          </AppButton>
          <AppSubmitButton title="Assign">
            <SaveIcon />
          </AppSubmitButton>
        </Box>
      </AppForm>
    </Box>
  );
};
export default HocBackdropLoader(AssignTo);
