import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { isEmptyObject } from "../../Utils";

const redirectToLoginWithNext = (navigate: any, next?: any) => {
  if (!next) {
    next = window.location.href;
  }
  navigate(`/login?next=${next}`);
};

interface Props { 
  dynamicMenuKeyName: string;
  routeIndexPath: string;
}

const HomeRouteIndex:React.FC<Props> = ({dynamicMenuKeyName, routeIndexPath}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { userConfig } = useAppSelector(useAuthStore);

  const getUrl = () => {

    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) {
      return redirectToLoginWithNext(navigate);
    }

    const menuOptions =
      userConfig?.payload?.menu_items?.[dynamicMenuKeyName] ?? [];
    const firstMenuOption = menuOptions?.[0] ?? null;

    // If user has no permissions to access any of the menu options
    if (!firstMenuOption) {
      return navigate("/contacts/list",{replace:true});
    }

    // If user has permissions to access the first menu option
    // then redirect to the first menu option's first child
    // else redirect to the first menu option
    if (firstMenuOption?.children?.length > 0) {
      navigate(firstMenuOption?.children?.[0]?.url,{replace:true});
    } else {
      navigate(firstMenuOption?.url,{replace:true});
    }
  };

  React.useEffect(() => {
    if (userConfig && location.pathname === routeIndexPath) {
        getUrl();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default HomeRouteIndex;