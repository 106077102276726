import { useEffect, useState } from "react";
import styled from "styled-components";
import AppRangeDatePicker from "../../AppComponents/AppRangeDatePicker";
import { useAppDispatch, useAppSelector } from "../../Store";
import { Box, MenuItem, Select } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { DashboardNetworkService } from "../../Store/Slices/DashboardRedux/DashboardNetworkService";
import {
  AgentBotID,
  AgentChannelName,
  setAgentDashboardDateRange,
} from "../../Store/Slices/DashboardRedux/dashboard.slice";
import { theme } from "../../Customization/Theme";
import { agentDateRange } from "../../Store/Slices/DashboardRedux/dashboard.selectors";

interface Props {}

const AgentTopMenu: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(agentDateRange);

  const [ChannelList, setChannelList] = useState<any>([]);
  const [agentList, setAgentList] = useState<any>([]);
  const [localValues, setLocalValues] = useState<any>({
    agent: "",
    channel: "",
  });

  const onChange = (date: any) => {
    dispatch(setAgentDashboardDateRange(date));
  };

  const getAgentList = () => {
    DashboardNetworkService.getAgentList().then((res: any) => {
      setAgentList(res?.data?.agent_list || []);
    });
  };
  const getChannelList = () => {
    DashboardNetworkService.getChannelList().then((res: any) => {
      setChannelList(res.data?.channel_name || []);
    });
  };

  const HandleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });

    if (field === "agent") {
      dispatch(AgentBotID(e.target.value));
    }

    if (field === "channel") {
      dispatch(AgentChannelName(e.target.value));
    }
  };

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      channel: "",
    });
    dispatch(AgentChannelName(""));
  };

  const clearCallbacks = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      agent: "",
    });
    dispatch(AgentBotID(""));
  };

  useEffect(() => {
    getAgentList();
    getChannelList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSelectIcon = localValues.channel ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const agentSelectIcon = localValues.agent ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  return (
    <MenubarWrap>
      <MenubarLeftBox>
        <Box className="subheader">Agent</Box>
      </MenubarLeftBox>

      <MenubarRightBox>
        <div>
          <NewNativeSelect>
            <Select
              defaultValue={undefined}
              name="channel"
              value={localValues?.channel}
              onChange={(e) => HandleSelectChange(e, "channel")}
              displayEmpty
              className="new-select-main"
            >
              <MenuItem value="" disabled>
                Select Channel
              </MenuItem>
              {ChannelList?.map((item: any, index: number) => {
                return (
                  <MenuItem value={item} key={index}>
                    {item.charAt(0).toUpperCase() + item.slice(1)}
                  </MenuItem>
                );
              })}
            </Select>
            <div
              className="clear-icon"
              onClick={(e: any) => clearCallback(e, "bot")}
            >
              {renderSelectIcon}
            </div>
          </NewNativeSelect>
        </div>

        <div>
          <NewNativeSelect>
            <Select
              defaultValue={undefined}
              name="agent"
              value={localValues?.agent}
              onChange={(e) => HandleSelectChange(e, "agent")}
              displayEmpty
              className="new-select-main"
            >
              <MenuItem value="" disabled style={{ fontSize: "13px" }}>
                Select Agent
              </MenuItem>
              {agentList?.map((item: any, index: number) => {
                return (
                  <MenuItem
                    value={item?.id}
                    key={index}
                    style={{ fontSize: "13px" }}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <div
              className="clear-icon"
              onClick={(e: any) => clearCallbacks(e, "agent")}
            >
              {agentSelectIcon}
            </div>
          </NewNativeSelect>
        </div>

        <DateBox>
          <AppRangeDatePicker
            dateRange={dashdateRange}
            setDateRange={onChange}
            // hideTodayOption={true}
          />
        </DateBox>
      </MenubarRightBox>
    </MenubarWrap>
  );
};

export default AgentTopMenu;

export const MenubarWrap = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    z-index: 1;
    height: 4rem;
    width: ${(props: any) => (props?.fullWidth ? `100%` : "calc(100% - 2px)")};
    padding: 0 1rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenubarRightBox = styled.div`
  && {
    gap: 1rem;
    align-items: center;
    display: flex;
    float: right;
    text-align: right;
    margin-left: auto;
    .new-select-main {
      width: 165px !important;
      .MuiSelect-select {
        padding-left: 10px;
        text-align: left;
      }
    }
    .new-select-main-agent {
      width: 165px !important;
    }
  }
`;

export const MenubarLeftBox = styled.div`
  && {
    gap: 2rem;
    align-items: center;
    display: flex;
    float: left;
    text-align: left;

    .subheader {
      color: #101010;
      font-size: 13px;
      font-family: Inter;
      font-weight: 500;
      padding: 18px 6px;
    }
  }
`;

export const SelectBox = styled.select`
  border: none;
  outline: none;
  background-color: white;
  font-size: 13px;
  color: blue;
`;

export const OptionTag = styled.option`
  border: none;
  outline: none;
  background-color: white;
  font-size: 13px;
  color: blue;
`;

export const DateBox = styled.div`
  color: blue;
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  &.datePicker {
    > div > div:first-child {
      padding: 0;
      height: 39px;
    }
  }
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 151px;
    width: 165px;
    height: 35px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;

    .MuiSelect-select {
      padding: 5px;
      font-size: 13px;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .new-select-main > MenuItem {
    font-size: 13px;
  }

  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
