
// Import necessary React hooks and components
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Box, Divider, List, ListItem, ListItemButton, Popover } from "@mui/material";
import { theme } from "../../../../../Customization/Theme";
import { ReactComponent as MenuButtonIcon } from "../../../../../assets/images/field-filter.svg";
import AppButton from "../../../../../AppComponents/AppButton";

type Props = {
    onCheckedItemsChange: any,
    dropdownData: any[]
}


const CheckboxComponent = ({ onCheckedItemsChange, dropdownData = [] }: Props) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [checkedItems, setCheckedItems] = useState<any>(
        dropdownData?.reduce((acc: any, item: any) => {
            acc[item._id] = false;
            return acc;
        }, {})
    );
    const [checkAll, setCheckAll] = useState(false);

    const handleCheckAll = () => {
        const newCheckAll = !checkAll;
        setCheckAll(newCheckAll);
        const newCheckedItems = Object.keys(checkedItems)?.reduce((acc: any, key: any) => {
            acc[key] = newCheckAll;
            return acc;
        }, {});
        setCheckedItems(newCheckedItems);
    };

    const handleIndividualCheck = (id: any) => {
        const newCheckedItems = {
            ...checkedItems,
            [id]: !checkedItems[id]
        };
        setCheckedItems(newCheckedItems);

        const allChecked = Object.values(newCheckedItems).every(value => value === true);
        setCheckAll(allChecked);
    };

    // Action Click for table
    const handleClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

        const onCancel = () => {
            handleClose();
        };

    const OnSaveChanges = () => {
        const checkedIds = Object.keys(checkedItems).filter(key => checkedItems[key]);
        onCheckedItemsChange(checkedIds);
             handleClose();
         };

    return (
        <div>

            <AppButton
                onClick={(e: any) => handleClick(e)}
                aria-describedby={id}
                            variant="primary"
                            style={{
                                width: "37px",
                                minWidth: "37px",
                            }}
                            className="btn-grp"
                        >
                            <MenuButtonIcon />
                        </AppButton>
           

           

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    width: "100%",
                    "& .MuiPaper-root": {
                        borderRadius: 1,
                        boxShadow: "0px 8px 24px 0px #00000029",
                    },
                }}
            >
                <Box
                    sx={{ width: "100%", maxWidth: 348, minWidth: 348 }}
                >
                    <nav aria-label="secondary mailbox folders">
                        <List
                            disablePadding
                            sx={{
                                color: `${theme.palette.default.darkGrey}`,
                            }}
                        >




                            <ListItem onClick={handleCheckAll} >
                                    <Checkbox
                                        edge="start"
                                        checked={checkAll}
                                        onClick={handleCheckAll}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": "" }}
                                        size="small"
                                    />
                                    <label>Select All</label>
                            </ListItem>
                            <Divider />

                            <Box sx={{
                                height: "65vh",
                                overflow: "auto",
                            }}>

                            

                            {dropdownData?.map((item: any) => (<>
                                <ListItem onClick={() => handleIndividualCheck(item?._id)} disablePadding key={item?._id}>
                                    <ListItemButton disableRipple={true}>
                                        <Checkbox
                                            edge="start"
                                            checked={!!checkedItems[item?._id]}
                                            onClick={() => handleIndividualCheck(item?._id)}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": item?._id }}
                                            size="small"
                                        />
                                        <label htmlFor={item?._id}>{item.label}</label>
                                    </ListItemButton>
                                </ListItem>
                                {/* <Divider /> */}
                            </>
                            ))}

                            </Box>
                            
      

                            
                            
                                    <Divider />
                            <ListItem >
                                           
                                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                               
                                                         <AppButton
                                                             onClick={onCancel}
                                                             variant="grey"
                                                             style={{
                                                                 margin: "0.5rem",
                                                             }}
                                                         >
                                                             Cancel
                                                         </AppButton>
                                                         <AppButton
                                                             onClick={OnSaveChanges}
                                                             variant="primary"
                                                             style={{
                                                                 margin: "0.5rem",
                                                             }}
                                                         >
                                                             Save
                                                         </AppButton>        
                                                 </Box>
                            </ListItem>
                               

                        </List>
                    </nav>
                </Box>
            </Popover>
        </div>
    );
};

export default CheckboxComponent;




