import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import nodata from "../../../../../assets/images/noDatafound.svg";
import SkeletonLoader from "../../../../../AppComponents/Loader/SkeletonLoader";

const NoDataFound = (props: any) => {
  return (
    <>
      {props.data === 0 ? (
        !props.loading ? (
          <NoData>
            <div className="no-data-img">
              <img src={nodata} alt="No Data Found" />
            </div>
            <div className="front-text">No Data Found</div>
          </NoData>
        ) : (
          <SkeletonLoader numberOfCol={4} numberofRow={5} />
        )
      ) : null}
    </>
  );
};

export default NoDataFound;

const NoData = styled.div`
  & {
    background-color: ${theme.palette.default.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: calc(100vh - 362px);
  }
`;
