import { useEffect, useState } from "react";
import {
  BroadcastMainWrapper,
} from "../../Broadcast/Email/Logs/EmailLogStyles";
import BroadcastLogsTable from "../../../CommonComponents/BroadcastLogs/BroadcastLogsTable";
import { getTableData } from "../../Subscription/TrackingExpenses/components/utils";

// interface Props extends LoaderComponentProps {}
interface Props { }
const EmailLogsInsights: React.FC<Props> = () => {
  const [tableData, setTableData] = useState<any>({})
  const tableType = "email"

  useEffect(() => {
    const tData: any = getTableData("email")
    setTableData(tData)
  }, [])

  return (
    <BroadcastMainWrapper>
      <BroadcastLogsTable
        // categoryForWhatsapp={categoryForWhatsapp}
        type={tableType}
        header={tableData.header}
        keyToHeaderMap={tableData.keyToHeaderMap}
        colWidth={tableData.colWidth}
        // tableDateRange={tableDateRange}
        showDateRange={false}
        tableHeight={"calc(100vh - 316px)"}
      />
    </BroadcastMainWrapper>
  );
};

export default EmailLogsInsights;
