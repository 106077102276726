import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Drawer, IconButton } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  SettingPageMainBoxBtnBox,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
} from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { PreviewType } from "../../../Models";
import { useBotTrackingCodeStore } from "../../../Services/Apis";
import { useAppSelector } from "../../../Store";

interface Props {
  isBotPreviewOpen: boolean;
  setIsBotPreviewOpen: (value: React.SetStateAction<boolean>) => void;
}

const WebBotLivePreview: React.FC<Props> = ({
  isBotPreviewOpen,
  setIsBotPreviewOpen,
}) => {
  let IFRAMEURL: string =
    process.env.REACT_APP_IFRAME_SCRIPT_BASE_URL || "http://localhost:8081";
  const currentBot = useSelector(useCurrentBot);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { data: trackingCode } = useAppSelector(useBotTrackingCodeStore);
  // @ts-ignore
  const html: any = `
  (function(w, d, s) {
      var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s);
      j.async = true;
      j.onload = function() {
          var geta = window.GetA('${trackingCode?.geta_host}', ${trackingCode?.matomo_site_id}, "${trackingCode?.bot_id}", "${PreviewType.getaWidgetPreview}");
      }
      j.src =
          '${IFRAMEURL}/output.js'
      f.parentNode.insertBefore(j, f);

  })(window, document, 'script');           
  ;`;

  const include = (filename: any, status: any) => {
    if (status === "on") {
      const elem: any = document.getElementById("getaTestScriptName");
      const elem2: any = document.getElementById("gct_" + currentBot?.bot_id);
      if (elem || elem2) {
        return;
      }

      const head: any = document.getElementsByTagName("head")[0];
      const script: HTMLScriptElement = document.createElement("script");
      script.type = "text/javascript";
      script.id = "getaTestScriptName";
      script.innerHTML = filename;
      head.appendChild(script);
    } else {
      const elem: any = document.getElementById("getaTestScriptName");
      elem?.parentNode?.removeChild(elem);
    }
  };

  const checkScriptExists = () => {
    const script = document.getElementById("getaTestScriptName");
    if (script) {
      setTimeout(() => {
        setIsLoading(false);
      }, 30000);
    } else {
      setTimeout(checkScriptExists, 100);
    }
  };

  useEffect(() => {
    if (trackingCode?.bot_id && trackingCode?.geta_host) {
      if (isBotPreviewOpen) {
        setIsLoading(true);
        include(html, "on");
        checkScriptExists();
      } else {
        include("", "off");
        let iframe = document.querySelectorAll(
          '[title="getaWidgetPreview"]'
        )[0];
        iframe?.parentNode?.removeChild(iframe);
        setIsLoading(true);
      }
    } else {
      console.error("tracking code not found");
    }
  }, [isBotPreviewOpen, trackingCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Drawer
      anchor={"right"}
      open={isBotPreviewOpen}
      onClose={() => setIsBotPreviewOpen(false)}
      hideBackdrop={false}
    >
      <SettingPageMainBoxTopbar
        style={{
          backgroundColor: theme.palette.default.white,
          height: "68px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
        }}
      >
        <SettingPageMainBoxTitle style={{ width: "100%" }}>
          Web Bot Preview
        </SettingPageMainBoxTitle>
        <SettingPageMainBoxBtnBox>
          <IconButton onClick={() => setIsBotPreviewOpen(false)}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </SettingPageMainBoxBtnBox>
      </SettingPageMainBoxTopbar>
      <PreviewWrapBox>
        {isLoading ? <CircularProgress color="inherit" /> : null}
        {(isLoading || !trackingCode?.bot_id || !trackingCode?.geta_host) && (
          <h4 style={{ fontWeight: "500 !important", textAlign: "center" }}>
            {" "}
            Please wait while <br /> we fetching bot script
          </h4>
        )}
        {(!trackingCode?.bot_id || !trackingCode?.geta_host) && !isLoading && (
          <h4 style={{ fontWeight: "500 !important" }}>
            {" "}
            Bot script not found
          </h4>
        )}
      </PreviewWrapBox>
    </Drawer>
  );
};

export default memo(WebBotLivePreview);

const PreviewWrapBox = styled.div`
  && {
    width: 470px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background-color: ${theme.palette.secondary.main};
  }
`;
