import { Box, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import NoRecordsFound from "../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import moment from "moment";
import React from "react";
import AppSkeletonTableLoader from "../../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";

interface FieldHistorySlidesProps {
    data: any,
    isLoading: boolean,
    isError: boolean,
}

const FieldHistorySlides: React.FC<FieldHistorySlidesProps> = ({
    data = [],
    isLoading = false,
    isError = false
}) => {
    const [formattedData, setFormattedData] = React.useState<any>([]);

    const renderUpdateTo = (value: any) => {
        if (Array.isArray(value)) {
            return value.length === 0 ? "Null" : value.join(",");
        } else if (moment(value, moment.ISO_8601, true).isValid()) {
            return moment(value).format('YYYY-MM-DD'); 
        } else if (value === "Invalid Date") {
            return "-"
        }else if (value === "") {
            return "-"
        }
        return value;
    }

    React.useEffect(() => {
        const groupByDate = (data: any[]): Record<string, any[]> => {
            return data?.reduce((acc: Record<string, any[]>, item: any) => {
                const date = item.created_time?.split('T')[0]; 
                if (!acc[date]) {
                    acc[date] = [];
                }
                acc[date]?.push(item);
                return acc;
            }, {});
        };

        const groupedData = groupByDate(data);

        const formattedData = Object?.keys(groupedData).map(date => ({
            date,
            data: groupedData[date]
        }));

        setFormattedData(formattedData);
    }, [data]);
   

    return <Box pr={2}>

        {
            isError ? <NoRecordsFound 
                    height="calc(100vh - 193px)"
                    headerText="Something Went Wrong!"
                    subText="Unable to fetch data"
                 /> : <>
                 
                 {
            isLoading ? <AppSkeletonTableLoader
                numberOfCol={1}
                numberofRow={10}
                skeletonHeight={30}
                skeletonWidth={"100%"}
                skeletonHeaderHeight={40}
                skeletonHeaderWidth={"100%"}
                tableHeight={"calc(100vh -  292px)"}

            /> : <GridWrapper container spacing={2}>
                {data.length > 0 ? formattedData?.map((item: any, index: number) => {
                    return (<>
                        <Box className="timeline-day">

                            {moment(item?.date).format('D MMMM, YYYY')}
                        </Box>

                        {
                            item.data?.map((innerItem: any, innerIndex: number) => <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={innerIndex}>
                                <Box className="cost_box" sx={{ padding: "1rem" }}>
                                    <Box className="box">
                                        <Typography title={innerItem?.field_name} fontSize={"15px"} className="title handled-large-text">{innerItem?.field_name} Updated</Typography>
                                        <Typography title={Array.isArray(innerItem?.value) ? innerItem?.value.join(",") : innerItem?.value} className="inr_value handled-large-text"><span className="inr">Updated To: </span>
                                            {renderUpdateTo(innerItem?.value)}
                                        </Typography>
                                    </Box>
                                    <Box className="box updated-by" px={2}>
                                        <Typography title={innerItem?.created_by}  className="inr_value handled-large-text"><span className="inr">Updated By: </span>{innerItem?.created_by}</Typography>
                                    </Box>
                                    <Box className="box">
                                        <Typography className="handled-large-text date" title={moment(innerItem?.created_time).format('DD/MM/YYYY [at] hh:mm A')}>
                                        {moment(innerItem?.created_time).format('DD/MM/YYYY [at] hh:mm A')}
                                        </Typography>
                                    </Box>
                                </Box>

                            </Grid>
                            )
                        }
                    </>
                    )
                }) : <NoRecordsFound height="calc(100vh - 193px)" />}

            </GridWrapper>   
        }
                 
                 </>
        }


        
    </Box>
}

export default FieldHistorySlides;


const GridWrapper = styled(Grid)`
&& {

    .handled-large-text {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    }
    

    .timeline-day {
        display: flex;
        color: ${theme.palette.default.darkGrey};
        width: 100%;
        margin-top: 24px;
    }

    .timeline-day:before,
    .timeline-day:after{
        content: "";
            border-bottom: 1px solid rgba(126, 131, 146, 0.2);
            display: block;
            flex: 1;
            margin: auto;
            margin-right: 10px;
            margin-left: 10px;
    }

    .cost_box {
        border: 1px solid rgba(126, 131, 146, 0.3);
        display: flex;
        padding: 16px;
        position: relative;
        align-items: center;
        height: 68px;
        max-height: 68px;
        background-color: ${theme.palette.default.white};
        border-radius: 4px;
        width: 100%;

        .box {
            width: 33.33%;
            min-width: 33.33%;
        }

        .date {

                font-size: 13px;
                font-weight: 400;
                color: ${theme.palette.default.darkGrey};
                display: flex;
                justify-content: flex-end;
                align-items: center;
        
        }

        

        .updated-by {
        display: flex;
        justify-content: center;
        align-items: center;
        }

        .title {
            font-size: 15px;
            font-weight: 500;
            color: ${theme.palette.default.black};
            
        }

        .inr {
            font-size: 12px;
            font-weight: 400;
            color: ${theme.palette.default.black};
        }

        .inr_value {
            color: ${theme.palette.default.darkGrey};
            font-size: 13px;
            font-weight: 400;
        }
    }
}
`;



