// vendors
import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { styled } from "@mui/material/styles";

interface OperationalZoneProps {
  children: React.ReactNode;
  error?: boolean; 
  className?: string;
}

interface BoxContainerProps extends BoxProps {
  error?: boolean
};

const BoxContainerStyled = styled(Box)<BoxContainerProps>(({ error }) => ({
  height: "206px",
  width: "100%",
  position: 'relative',
  borderRadius: "4px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  mb: "16px",
  ...(error ? {
    border: "1px dashed #F34E4E",
    backgroundColor: 'rgb(243 209 209 / 36%)',
    backgroundImage: `
        repeating-linear-gradient(0deg, #F34E4E, #F34E4E 10px, transparent 10px, transparent 20px),
        repeating-linear-gradient(90deg, #F34E4E, #F34E4E 10px, transparent 10px, transparent 20px),
        repeating-linear-gradient(180deg, #F34E4E, #F34E4E 10px, transparent 10px, transparent 20px),
        repeating-linear-gradient(270deg, #F34E4E, #F34E4E 10px, transparent 10px, transparent 20px)
      `,
    borderImage: `repeating-linear-gradient(0deg, #F34E4E, #F34E4E 10px, transparent 10px, transparent 20px)`,
  } : {
    border: "1px dashed #CBCDD3",
    backgroundImage: `
        repeating-linear-gradient(0deg, #CBCDD3, #CBCDD3 10px, transparent 10px, transparent 20px),
        repeating-linear-gradient(90deg, #CBCDD3, #CBCDD3 10px, transparent 10px, transparent 20px),
        repeating-linear-gradient(180deg, #CBCDD3, #CBCDD3 10px, transparent 10px, transparent 20px),
        repeating-linear-gradient(270deg, #CBCDD3, #CBCDD3 10px, transparent 10px, transparent 20px)
      `,
    borderImage: `repeating-linear-gradient(0deg, #CBCDD3, #CBCDD3 10px, transparent 10px, transparent 20px)`,
  }),

  backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
  backgroundSize: "1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px",
  backgroundRepeat: "no-repeat",
}));

const MediaUploadPlaceholder = (props: OperationalZoneProps) => {
  const { children, error, className } = props;
  return <BoxContainerStyled error={error} className={className}>{children}</BoxContainerStyled>;
};
export default MediaUploadPlaceholder;
