import UploadHandlers from "./handlers";

interface UploadSectionProps {
  type: string;
  onSave: (type: string, url: string) => void;
  url: string;
  onDelete: () => void;
}

const UploadSection = (props: UploadSectionProps) => {
  const { type, onSave, url, onDelete } = props;

  // @ts-ignore
  const UploadHandler = UploadHandlers[type];

  const handleSave = (url: string) => {
    onSave && onSave(url, type);
  };

  const handleDelete = () => {
    onDelete && onDelete();
  };

  return (
    <UploadHandler url={url} onSave={handleSave} onDelete={handleDelete} />
  );
};
export default UploadSection;
