import { AccordionDetails, AccordionSummary, Box, Button, FormControl, FormControlLabel, IconButton, MenuItem, OutlinedInput, Select, Switch, TextField, Tooltip } from "@mui/material";
import AppButton from "../Components/Button"
import { AccordionDelete, AccordionInput, AccordionSelect, FlowBuilderContentAccordian, FlowBuilderContentHeader, FlowBuilderContentList, FlowBuilderContentWapper, FlowBuilderScreenHeader, FlowBuilderScreenWapper, ImgaeUploadStyle } from "../Utils/style"
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import AppInput from "../Components/Input";
import { getContentData, getFlowBuilderList } from "../Store/FlowBuilder.selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../Store";
import { setContentData, setFlowBuilderList } from "../Store/FlowBuilder.slice";
import { MenuProps, generateChoiceData, generateFormElementData, generateHeadingData, generateOptInData, generateUniqueName, getContentTitle, getSectionCount, getTextConentTextDropdownOption, getTextInputConentTextDropdownOption, randomString } from "../Utils/common";
import AppTextArea from "../Components/Textarea";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
const FlowBuilderSubScreenContent = (props :any) => {
    const dispatch = useAppDispatch()
    const contentData: any = useSelector(getContentData);
    const flowBuilderList = useSelector(getFlowBuilderList);
    const changeScreenTitleName = (name: string) => {
        let data = {
            ...contentData,
            title: name
        }
        dispatch(setContentData(data))
    }
    const updateContentData = (updatedChildren: any) => {
        let data = {
            ...contentData,
            layout: {
                ...contentData.layout,
                children: updatedChildren
            }
        };
        dispatch(setContentData(data));
    }
    const changeScreenButtonName = (name: string) => {
        let updatedChildren = [...contentData.layout.children];
        const lastChildIndex = updatedChildren.length - 1;
        updatedChildren[lastChildIndex] = {
            ...updatedChildren[lastChildIndex],
            label: name
        };
        updateContentData(updatedChildren)
    };
    const contentSubValue = (value: any) => {
        console.log("data -------------------->",contentData);
        const textElements: any = {
            large_heading: "TextHeading",
            small_heading: "TextSubheading",
            body: "TextBody",
            caption: "TextCaption",
        };

        const formElements: any = {
            textInput: "TextInput",
            textArea: "TextArea",
            datePicker: "DatePicker",
        };

        const choiceElements: any = {
            single_choice: "RadioButtonsGroup",
            multipal_choice: "CheckboxGroup",
            dropdown: "Dropdown"
        };

        if (value in textElements || value in formElements || value in choiceElements || value === "image" || value === "optIn") {
            let headingData: any = {};

            if (value in textElements) {
                headingData = generateHeadingData(textElements[value], "Text");
            } 
            else if (value === "image") {
                headingData = {
                    type: "Image",
                    src: "",
                    height: 400,
                    "scale-type": "contain",
                };
            }

            if (contentData?.layout?.children) {
                const childrenArray = contentData.layout.children;
                let updatedChildren;

                if (childrenArray.length > 0 && childrenArray[childrenArray.length - 1].type === "Footer") {
                    updatedChildren = [
                        ...childrenArray.slice(0, -1),
                        headingData,
                        childrenArray[childrenArray.length - 1],
                    ];
                } else {
                    updatedChildren = [...childrenArray, headingData];
                }
                console.log("updatedChildren",updatedChildren);
                
                const data = {
                    ...contentData,
                    layout: {
                        ...contentData.layout,
                        children: updatedChildren
                    },
                };
                console.log("data -------------------->",data);
                
                dispatch(setContentData(data));
            } else {
                console.error("contentData.layout.children[0].children is undefined");
            }
        }
    };

    const setHeadingValue = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children];
            updatedChildren[index] = {
                ...updatedChildren[index],
                text: value
            };
            console.log("setHeadingValue",updatedChildren);
            
        updateContentData(updatedChildren)
    }
    const setShortAnswerValue = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            label: value
        };
        updateContentData(updatedChildren)
    }
    const setShortAnswerRequired = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            required: value
        };
        updateContentData(updatedChildren)
    }
    const setShortAnswerHelperText = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'helper-text': value
        };
        updateContentData(updatedChildren)
    }
    const setHeadingTextType = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            type: value === 'large_heading' ? "TextHeading" : value === "small_heading" ? "TextSubheading" : value === "body" ? "TextBody" : value === "caption" ? "TextCaption" : ""
        };
        console.log("setHeadingTextType",updatedChildren);
        
        updateContentData(updatedChildren)
    }

    const setShortAnswerType = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'input-type': value
        };
        updateContentData(updatedChildren)
    }
    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const updatedChildren = Array.from(contentData.layout.children);
        const [movedItem] = updatedChildren.splice(result.source.index, 1);
        updatedChildren.splice(result.destination.index, 0, movedItem);
        console.log("updatedChildren",updatedChildren);

        updateContentData(updatedChildren)
    }
    const handleRemoveContent = (index: any,content:any) => {
        const updatedChildren = Array.from(contentData.layout.children);
        updatedChildren.splice(index, 1)
        updateContentData(updatedChildren)
        if(content?.type === "OptIn"){

            let list :any = {
                version: "3.1",
                screens: flowBuilderList.screens.filter(
                    (screen: any) => screen.id !== content['on-click-action']?.next?.name
                ),
            };
            console.log("list-------------------->",list);
            
            // Dispatch the updated list to the store
            dispatch(setFlowBuilderList(list));
        
        }
    }
    const addReadMoreScreen = (index: any) => {
        const val = randomString(5);
        let updatedChildren = [...contentData.layout.children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            "on-click-action": {
                "name": "navigate",
                "next": {
                    "type": "screen",
                    "name": "TERMS_AND_CONDITIONS_" + val
                },
                "payload": {}
            }
        };
        let data = {
            "id": "TERMS_AND_CONDITIONS_" + val,
            "title": "Terms and conditions",
            "data": {},
            "layout": {
                "type": "SingleColumnLayout",
                "children": [
                ]
            }
        }
        let list :any = {
            "version": "3.1",
            "screens": [
                ...flowBuilderList["screens"],
                data
            ]
        }        

        dispatch(setFlowBuilderList(list))

        updateContentData(updatedChildren);
        }
        const removeReadMoreScreen = (index: number, data: { [key: string]: any }) => {
            // Make a copy of the children array and update the specific child at the index
            let updatedChildren = [...contentData.layout.children];
            updatedChildren[index] = {
                ...updatedChildren[index],
            };
            delete updatedChildren[index]['on-click-action'];
        
            // Create a copy of the screens array, removing the screen with the specific name
            console.log("list-------------------->",flowBuilderList,data);

            let list :any = {
                version: "3.1",
                screens: flowBuilderList.screens.filter(
                    (screen: any) => screen.id !== data?.next?.name
                ),
            };
            console.log("list-------------------->",list);
            
            // Dispatch the updated list to the store
            dispatch(setFlowBuilderList(list));
        
            // Update the content data with the modified children
            updateContentData(updatedChildren);
        };
        const getDropdownOption = (type: any) => {
            if (type === "TextInput") {
                return getTextInputConentTextDropdownOption()
            } else {
                return getTextConentTextDropdownOption()
            }
            return []
        }
        const onImageChange = (file: any, index: any) => {
            const maxSize = 300 * 1024; // 300KB
            const allowedTypes = ["image/png", "image/jpeg"]; // Allowed file types

            if (file) {
                if (file.size > maxSize) {
                    console.error("File size exceeds the limit of 300KB");
                    alert("File size exceeds the limit of 300KB");
                    return;
                }
                if (!allowedTypes.includes(file?.type)) {
                    console.error("Only PNG and JPG files are allowed");
                    alert("Only PNG and JPG files are allowed");
                    return;
                }
                const formData = new FormData();
                formData.append("source", file);
                formData.append("resolved", "true");

                ChatbotConsoleService.uploadWhatsappMedia(formData, true)
                    .then((response: any) => {
                        let updatedChildren = [...contentData.layout.children];
                        updatedChildren[index] = {
                            ...updatedChildren[index],
                            'src': response?.data?.source
                        };
                        updateContentData(updatedChildren)
                    })
                    .catch((error: any) => {

                    })
                    .finally(() => {
                        setTimeout(() => {
                        }, 1000);
                    });
            }
        };
        const resetImageSource = (index: any) => {
            let updatedChildren = [...contentData.layout.children];
            updatedChildren[index] = {
                ...updatedChildren[index],
                'src': ""
            };
            updateContentData(updatedChildren)
        }
        const setImageHeight = (index: any, value: any) => {
            let updatedChildren = [...contentData.layout.children];
            updatedChildren[index] = {
                ...updatedChildren[index],
                'height': value
            };
            updateContentData(updatedChildren)
        }
        console.log("contentData",contentData);
        
        return (
            <FlowBuilderContentWapper>
                <FlowBuilderContentHeader>
                    <div className="headerTitle">Edit content</div>
                    <Tooltip componentsProps={{
                        tooltip: {
                            sx: {
                                bgcolor: 'white',
                                color: "common.black",
                                padding: "5px !important",
                                border: "1px solid lightgray",
                                fontSize: "12px",
                                '& .MuiTooltip-arrow': {
                                    color: 'common.black',
                                },
                            },
                        },
                    }} title={contentData?.layout?.children?.length === 9 ? "You can add a maximum of 8" : ""}>
                        <span> <AppButton isSubScreen ={true} disabled={contentData?.layout?.children[0]?.children?.length === 9} onSubMenuClick={contentSubValue} startIcon={<AddIcon />} isSubMenu={true} endIcon={<ArrowDropDownIcon />} backgroundColor={contentData?.layout?.children[0]?.children?.length === 9 ? "rgba(229,232,240,1)" : "rgb(33, 28, 82)"} text={"Add Content"} /> </span>
                    </Tooltip>
                </FlowBuilderContentHeader>
                <div className="body" >


                    <FlowBuilderContentList>
                        <FlowBuilderContentAccordian style={{ padding: "0px 9px !important" }}>
                            <AccordionSummary
                                expandIcon={<GridExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                Screen title{contentData?.title === "" ? <WarningAmberIcon style={{
                                    transform: "",
                                    color: "red",
                                    marginLeft: "6px",
                                    fontSize: "20px"
                                }} /> : null}
                            </AccordionSummary>
                            <AccordionDetails style={{ textAlign: "justify", color: contentData?.title === "" ? "red" : "" }}>
                                <AppInput handleChange={changeScreenTitleName} value={contentData?.title} placeholder="Enter your screen name" />
                                {contentData?.title === "" ? <a>This field cannot be empty</a> : null}
                            </AccordionDetails>
                        </FlowBuilderContentAccordian>
                    </FlowBuilderContentList>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable-1" type="row">
                            {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps}>
                                    {
                                        contentData?.layout?.children?.map((content: any, index: any) => {
                                            return (
                                                <Draggable key={index} draggableId={index?.toString()} index={index}>
                                                    {(provided) => (
                                                        <div

                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            {content?.type !== "Footer" ?
                                                                <FlowBuilderContentList>
                                                                    <FlowBuilderContentAccordian style={{ padding: "0px 9px !important" }}>
                                                                        <AccordionSummary
                                                                            expandIcon={<GridExpandMoreIcon />}
                                                                            aria-controls="panel1-content"
                                                                            id="panel1-header"
                                                                        >
                                                                            <DragIndicatorIcon style={{
                                                                                cursor: "pointer", fontSize: "22px",
                                                                                marginRight: "4px"
                                                                            }} />
                                                                            {getContentTitle(content?.type)?.title}{content?.text === "" || content?.label === "" ? <WarningAmberIcon style={{
                                                                                transform: "",
                                                                                color: "red",
                                                                                marginLeft: "6px",
                                                                                fontSize: "20px"
                                                                            }} /> : null}
                                                                        </AccordionSummary>
                                                                        <AccordionDetails>
                                                                            <>
                                                                                <AccordionSelect>
                                                                                    {content?.type === "TextInput" || content?.type === 'TextHeading' || content?.type === "TextSubheading" || content?.type === "TextBody" || content?.type === "TextCaption" ? <Select
                                                                                        displayEmpty
                                                                                        value={getContentTitle(content?.type, content['input-type'])?.name}
                                                                                        onChange={(e: any) => setHeadingTextType(e?.target?.value, index)}
                                                                                        input={<OutlinedInput />}
                                                                                        MenuProps={MenuProps}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        {getDropdownOption(content?.type).map((name) => (
                                                                                            <MenuItem
                                                                                                key={name?.name}
                                                                                                value={name?.name}
                                                                                            >
                                                                                                {name?.label}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select> : null}
                                                                                </AccordionSelect>
                                                                                <AccordionInput style={{ textAlign: "left" }}>
                                                                                    {content?.type === "TextInput" || content?.type === "TextArea" || content?.type === "DatePicker" || content?.type === "RadioButtonsGroup" || content?.type === "CheckboxGroup" || content?.type === "Dropdown" ?
                                                                                        <>
                                                                                            <div style={{ color: content?.label === "" ? "red" : "", }}>
                                                                                                <AppInput handleChange={(value: any) => setShortAnswerValue(value, index)} value={content?.label} placeholder="Enter your label name" />
                                                                                                {content?.label === "" ? <a>This field cannot be empty</a> : null}

                                                                                            </div>
                                                                                        </>
                                                                                        : content?.type === "Image" ? <>
                                                                                            <ImgaeUploadStyle>
                                                                                                {content?.src ? <div className="imageContainerPreview">
                                                                                                    <div>
                                                                                                        <img style={{ width: "100px" }} src={content?.src} />
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <AppButton onClick={() => resetImageSource(index)} className="bodyOptionsButton" width="20%" fontColor="black" backgroundColor={"rgba(229,232,240,1)"} text={"Remove"} />

                                                                                                    </div>
                                                                                                </div> : null}
                                                                                                {content?.src === "" ? <> <div className="imageContainerTitle">
                                                                                                    Choose JPG or PNG file
                                                                                                </div>
                                                                                                    <Box className="file-upload__container">
                                                                                                        <div className="container">
                                                                                                            <input type="file" onChange={(event: any) => onImageChange(event.target.files[0], index)} accept="image/png, image/jpeg" className="chooser" />
                                                                                                            <a href="#">Choose file on your device</a>
                                                                                                        </div>

                                                                                                    </Box>
                                                                                                    <a style={{ color: "red" }}>An image must be selected</a> </>
                                                                                                    : null
                                                                                                }
                                                                                                {content?.src ? <> <div className="imageContainerTitle">
                                                                                                    Image height
                                                                                                </div>
                                                                                                    <FormControl sx={{ m: 1, width: '25ch', marginLeft: "0px" }} variant="outlined">
                                                                                                        <OutlinedInput
                                                                                                            id="outlined-adornment-weight"
                                                                                                            type="number"
                                                                                                            value={content?.height}
                                                                                                            onChange={(e: any) => setImageHeight(index, e.target.value)}
                                                                                                            aria-describedby="outlined-weight-helper-text"
                                                                                                            inputProps={{
                                                                                                                'aria-label': 'weight',
                                                                                                            }}
                                                                                                        />
                                                                                                    </FormControl> </> : null}
                                                                                                <div style={{ marginTop: "10px" }}>
                                                                                                    Maximum file size: 300kb <br />
                                                                                                    Acceptable file types : JPG, PNG
                                                                                                </div>
                                                                                            </ImgaeUploadStyle>
                                                                                        </> : <> <AppTextArea style={{
                                                                                            width: "100%", marginTop: "20px",
                                                                                            borderRadius: "5px",
                                                                                            height: "120px",
                                                                                            padding: "12px 65px 11px 19px",
                                                                                            fontSize: "16px",
                                                                                            color: content?.text === "" ? "red" : "",
                                                                                            border: content?.text === "" ? "1px solid red" : "1px solid",
                                                                                        }} handleChange={(value: any) => setHeadingValue(value, index)} characterLimit={80} value={content?.text || content?.label} />
                                                                                            {content?.text === "" ? <a style={{ color: content?.text === "" ? "red" : "", }}>This field cannot be empty</a> : null}
                                                                                            {content?.type === "OptIn" ?
                                                                                                <>
                                                                                                    <div className="bodyTitle">Read More Link</div>
                                                                                                    {content['on-click-action'] ? <div style={{ marginTop: "15px", marginLeft: "5px" }} >
                                                                                                        {/* <AppButton onClick={() => removeReadMoreScreen(index,content['on-click-action'])} width="20%" fontColor="black" backgroundColor={"rgba(229,232,240,1)"} text={`Delete Link`} /> */}
                                                                                                        <AppButton onClick={() => removeReadMoreScreen(index,content['on-click-action'])}  startIcon={<AddIcon />} className="bodyOptionsButton" width="20%" fontColor="black" backgroundColor={"#fff"} text={"Add Options"} />

                                                                                                    </div> : <div style={{ marginTop: "15px", marginLeft: "5px" }} >
                                                                                                        <AppButton onClick={() => addReadMoreScreen(index)} width="25%" fontColor="black" backgroundColor={"rgba(229,232,240,1)"} text={`Add "Read More" screen`} />

                                                                                                    </div>}

                                                                                                </>
                                                                                                : null}
                                                                                        </>
                                                                                    }

                                                                                </AccordionInput>
                                                                                <AccordionDelete>
                                                                                    {content?.type === "TextInput" || content?.type === "TextArea" || content?.type === "DatePicker" || content?.type === "RadioButtonsGroup" || content?.type === "CheckboxGroup" || content?.type === "Dropdown" || content?.type === "OptIn" ? <FormControlLabel
                                                                                        checked={content?.required}
                                                                                        control={<Switch color="primary" />}
                                                                                        label="Required"
                                                                                        labelPlacement="start"
                                                                                        onClick={(e: any) => setShortAnswerRequired(e?.target?.checked, index)}
                                                                                    /> : null}
                                                                                    <DeleteOutlineIcon onClick={() => handleRemoveContent(index,content)} />
                                                                                </AccordionDelete>
                                                                            </>
                                                                        </AccordionDetails>
                                                                    </FlowBuilderContentAccordian>
                                                                </FlowBuilderContentList> : null}
                                                        </div>
                                                    )}
                                                </Draggable>

                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </FlowBuilderContentWapper>
        )
    }
    export default FlowBuilderSubScreenContent