import { TableContainer } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import { AppConfig } from "../../../../Config/app-config";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
const BOT_ID_KEY = 'currentBotId'
interface Props {
  ZohoCRMFieldsData: any;
  handleDisconnectButton: any;
}

const ZohoConnectedPage: React.FC<Props> = ({
  ZohoCRMFieldsData,
  handleDisconnectButton
}) => {
  const bot_id = AppConfig.getUserData(BOT_ID_KEY);
  const { showAlert } = useNotification();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - ZohoCRMFieldsData.length) : 0;

  return (
    <ZohoPageWrap>
      <div className="pageTopbar">
        <div className="accountInfo">
          <AppButton variant="danger" onClick={() => handleDisconnectButton()}>
            Disconnect
          </AppButton>
        </div>
      </div>
      <div className="ctaBox">
        <img
          className="companyLogo"
          src="https://www.zoho.com/branding/images/zoho-logo.png"
          alt=""
        />
        Connected to ZOHO CRM
      </div>
      <div className="zohoData">
        {ZohoCRMFieldsData.length > 0 &&
          ZohoCRMFieldsData.map((key: any, index: number) => {
            return (
              <TableContainer key={index}>
                <Table size={"small"} >
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>
                        {key[0]}
                      </StyledTableCell>
                      <StyledTableCell>
                        {key[1]}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }
          )
        }
      </div>
    </ZohoPageWrap>
  );
};

export default ZohoConnectedPage;

const ZohoPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .zohoData {
      margin-top: 3rem;
    }

    .pageTopbar {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 48px;
      padding: 0 0.4rem;
      width: 100%;

      .accountInfo {
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 2rem;
        font-size: 1rem;
        color: #7e8392;
      }
    }

    .ctaBox {
      display: flex;
      height: 20%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }

    .configureFormFieldsWrap {
      display: flex;
      padding: 1rem 2rem;
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

const StyledSessionDevicesBox = styled.div`
  && {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    min-height: 480px;
    table{
     
    }
    .extraInfoValue{
      margin-left: 8px;
      padding: 4px 8px;
      font-weight: 400;
      font-size: 12px;
      border-radius: 500px;
      color: #27B783;
      background-color: #D6FFF0;
    }

    .success{
      color: #27B783;
      background-color: #D6FFF0;
    }
    .error{
      color: #F34E4E;
      background-color: #FFE3E3;
    }
  }
`;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#7E8392",
    fontWeight: "500",
    fontSize: "16px",
    textTransform: "capitalize",
    height: "48px",
    borderBottom: "1px solid #F5F6F8",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#7E8392",
    fontWeight: "400",
    fontSize: "16px",
    height: "48px",
    borderBottom: "1px solid #F5F6F8",

  },
  [`&.${tableRowClasses.root}`]: {

  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {

  },
  "&:nth-of-type(odd)": {
    // backgroundColor: "blue",
  },
  // hide last border
  "&:last-child td, &:last-child th": {

  },
  "&:hover": {
    backgroundColor: "#F5F6F8",
  },
}));



