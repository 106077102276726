import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import AppButton from "../../AppComponents/AppButton";
import loginIllustration from "../../assets/images/loginIllustration.svg";
import {
  BrandBox,
  LeftSide,
  LoginPageWrap,
  LogoText,
  RightSide
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { CoreService } from "../../Services/Apis/CoreApis";
import logo from "../../assets/images/logo.svg";
import { LoaderComponentProps, withLoader } from '../../AppComponents/Loader';
import { logoutAuth } from '../../Store/Slices/AuthSlice';
import { useAppDispatch } from '../../Store';

interface Props extends LoaderComponentProps { }

const BusniessVerificationPage = () => {
  let navigate = useNavigate();
  let { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSuccessVerification, setShowSuccessVerification] =
    useState<boolean>(false);
  const [showReload, setShowReload] =
    useState<boolean>(false);
  const invitationId = searchParams.get("invitation_id");
  const getaHost = searchParams.get("geta_host");

  const verifyCall = () => {
    const headers = {
      "GETA-HOST": getaHost,
    };
    CoreService.businessAccountVerify(
      { invitation_id: invitationId },
      headers
    )
      .then((res: any) => {
        if (res.status == 200) {
          setShowSuccessVerification(true);
          showAlert("Verification Success!", "success");
          //           window.localStorage.clear()
          // window.sessionStorage.clear()
          dispatch(logoutAuth(null));
          // setTimeout(() => {
          //   window.location.href = "/login";
          // },200)
        }
      })
      .catch((error: any) => {
        setShowReload(true)
        // setTimeout(() => {
        showAlert("Verification failed!", "error");
        // }, 2000);
      })
  };

  useEffect(() => {
    if (getaHost && invitationId) {
      verifyCall();
    }
  }, []);

  return (
    <LoginPageWrap>
      <LeftSide>
        <img src={loginIllustration} alt="" />
      </LeftSide>

      <RightSide
        style={{
          marginTop: "-1.5rem",
          alignItems: "center",
        }}
      >
        <ContaierBox>

          <BrandBox>
            <img src={logo} alt="" />
            <LogoText>Geta</LogoText>
          </BrandBox>

          {showSuccessVerification ? (
            <>
              <p
                className="title"
                style={{ color: theme.palette.default.success }}
              >
                
              <CheckCircleIcon style={{ fontSize: '32px', margin: '-0.5rem 1rem', color: theme.palette.default.success }} />Account Verification Complete
              </p>
              <p className="caption">
                {" "}
                Your account has been verified successfully.{" "}
                <AppButton
                  variant="outline"
                  onClick={() => navigate("/login")}
                >
                  Go to Login
                </AppButton>
              </p>
            </>
          ) : (
            <>
              <p className="title">Verification in-process!</p>
              <CircularProgress size={36} />

              <p className="caption">
                {" "}
                Please wait while we process your details...{" "}
                {showReload ?<AppButton
                  variant="outline"
                  onClick={() => window.location.reload()}
                >
                  Try again
                </AppButton> :null}
              </p>
            </>
          )}
        </ContaierBox>
      </RightSide>
    </LoginPageWrap>
  );
};

export default BusniessVerificationPage;

const ContaierBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 55%;
    gap: 3rem;
    padding: 1rem;
    margin-top: -2rem;
    .title {
      font-size: 19px;
      font-weight: 500;
      letter-spacing: 1px;
      color: ${theme.palette.primary.main};
      color: ${theme.palette.default.error};
      font-family: ${theme.typography.fontFamily};
    }
    .caption {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      color: ${theme.palette.primary.main};
      color: ${theme.palette.default.darkGrey};
      font-family: ${theme.typography.fontFamily};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
    }
  }
`;
