import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

// Services Functions
export class SourceNetworkService {
  // add websites urls
  static postWebsiteCrawUrl(data: any) {
    const { url, headers } = data;
    return http.post(`${consoleBaseUrl}${Endpoints.WEBSITE_ADD_LINKS}`, url, {
      headers: headers,
    });
  }

  // add sitemap urls
  static postSiteMapCrawUrl(data: any) {
    const { url, headers } = data;
    return http.post(`${consoleBaseUrl}${Endpoints.SITEMAP_ADD_LINKS}`, url, {
      headers: headers,
    });
  }

  // get websites crawl urls
  static getWebsiteCrawUrls(data: any) {
    const { headers, limit, offset, sourceId, query } = data;
    const params = { sourceId, offset, limit, query };
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_WEBSITES_CRAWL_URL_LIST}`,
      {
        headers,
        params,
      }
    );
  }

  // get sitemap crawl  urls
  static getSiteMapCrawUrls(data: any) {
    const { headers, limit, offset, sourceId, query } = data;
    const params = { sourceId, offset, limit, query };
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_SITEMAP_CRAWL_URL_LIST}`,
      {
        headers,
        params,
      }
    );
  }

  // add websites subLinks in everyClicks
  static putSublinks(data: any) {
    const { url, headers } = data;
    return http.put(`${consoleBaseUrl}${Endpoints.PUT_SUBLINKs_URL}`, url, {
      headers: headers,
    });
  }

  // submit sub url links
  static postSelectedUrls(data: any) {
    const { urls, headers } = data;
    return http.post(`${consoleBaseUrl}${Endpoints.SCRAP_LINKS_URL}`, urls, {
      headers: headers,
    });
  }

  // Source Type Text
  // Create Message
  static postSourceText(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.SOURCE_TEXT}`, {
      ...data.payload,
      headers: data.HEADERS,
    });
  }

  // Listng Of Source Type
  static getSourceListing(data: any) {
    const { limit, offset, query } = data;
    const headers = data?.HEADERS;
    const sourceType = data?.sourceType;
    const params = { limit, offset, query, sourceType };
    // const queryParams = sourceType ? `?sourceType=${sourceType}` : "";
    return http.get(`${consoleBaseUrl}${Endpoints.GET_SOURCE_LIST}`, {
      headers,
      params,
    });
  }

  // Get Source Text By ID
  static getSourceTextbyId(data: any) {
    const { documentid, HEADERS } = data;
    return http.get(
      `${consoleBaseUrl}${Endpoints.SOURCE_TEXT}?documentId=${documentid}`,
      { headers: HEADERS }
    );
  }

  // Update Source Text By ID
  static updateSourceTextById(data: any) {
    const { payload, HEADERS } = data;
    return http.put(`${consoleBaseUrl}${Endpoints.SOURCE_TEXT}`, payload, {
      headers: HEADERS,
    });
  }
  static addOneUrlLink(data: any) {
    const { urls, headers } = data;
    return http.post(`${consoleBaseUrl}${Endpoints.ADD_ONE_LINKS}`, urls, {
      headers: headers,
    });
  }

  // Delete Source Text By ID
  static deleteSourceById(data: any) {
    const headers = {
      ...data.HEADERS,
    };
    const requestBody = {
      documentIds: data.documentIds,
    };
    return http.delete(`${consoleBaseUrl}${Endpoints.SOURCE_DELETE}`, {
      headers,
      data: JSON.stringify(requestBody),
    });
  }

  static uploadFaqDocumentFile(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.UPLOAD_FAQ_DOCUMENT_FILE}`,
      data.file,
      {
        headers: data.HEADERS,
      }
    );
  }
}

// API Endpoints
class Endpoints {
  // website****
  static WEBSITE_ADD_LINKS = "/console/getlinks";
  static PUT_SUBLINKs_URL = "/console/getlinks";
  static SCRAP_LINKS_URL = "/console/scrapelinks";
  static GET_WEBSITES_CRAWL_URL_LIST = "/console/getlinks";

  // sitemap***
  static SITEMAP_ADD_LINKS = "/console/getsitemap";
  static GET_SITEMAP_CRAWL_URL_LIST = "/console/getsitemap";

  static GET_SOURCE_LIST = "/console/listsources";
  static SOURCE_TEXT = "/console/gettexts";

  static SOURCE_DELETE = "/console/deletesources";
  static ADD_ONE_LINKS = "/console/addonelink";
  static UPLOAD_FAQ_DOCUMENT_FILE = "/console/getfiles";
}
