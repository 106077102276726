/**
* Utility function to execute callback for eack key->value pair.
*/
export function forEach(obj: any, callback: any) {
    if (obj) {
      for (const key in obj) { // eslint-disable-line no-restricted-syntax
        if ({}.hasOwnProperty.call(obj, key)) {
          callback(key, obj[key]);
        }
      }
    }
  }
  
  export function hasProperty(obj: any, property: any) {
    let result = false;
    if (obj) {
      for (const key in obj) { // eslint-disable-line no-restricted-syntax
        if ({}.hasOwnProperty.call(obj, key) && property === key) {
          result = true;
          break;
        }
      }
    }
    return result;
  }
  
  /**
  * The function returns true if the string passed to it has no content.
  */
  export function isEmptyString(str: any) {
    return !str || !str.trim()
  }
  
  /**
  * The function will return true for simple javascript object,
  * which is not any other built in type like Array.
  */
  export function isMap(obj: any) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }
  
  /**
  * The function will return filter out props fron and return new props.
  */
  export function filter(obj: any, keys: any) {
    const filteredKeys: any = Object.keys(obj).filter(key => keys.indexOf(key) < 0);
    const filteredObject: any = {};
    if (filteredKeys && filteredKeys.length > 0) {
      filteredKeys.forEach((key: any) => {
        filteredObject[key] = obj[key];
      });
    }
    return filteredObject;
  }
  
  export function stopPropagation(event: any) {
    event.stopPropagation();
  }
  