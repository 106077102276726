import { Module } from './ModulePackage';
import { BaseModel } from "./Common";

export interface Project extends BaseModel {
    name: string;
    description: string;
    tracking_id: string;
    module: Module | number | string | null;
}

export interface ProjectVersion extends BaseModel {
    base_project: Project | number | string | null;
    version_name: string;
    version_description: string;
    tracking_id: string;
    domain?: string;
}

export interface ProjectVersionConfiguration extends BaseModel {
    project_version: ProjectVersion | number | string | null;
    config_key: string;
    config_value: any;
    is_json: boolean;
}

export enum PV_CONFIG_TYPES {
    MATOMO_CONFIG = 'MATOMO_CONFIG'
}