// vendors
import { useContext, useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useOnClickOutside from "use-onclickoutside";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { useSelector } from "react-redux";

// commponents
import Card from "../card";
import {
  NodeType,
  Icon,
  MessagePreview,
  Input,
  Editor,
  MediaUploadPlaceholder,
  UIButton,
} from "../../ui-components";
import ButtonList from "../button/buttonsList";
import ImgModal from "./img-modal";
import Action from "../action";

// styled
import {
  LeftArrowButtonStyled,
  RightArrowButtonStyled,
  CarouselDotStyled,
  DeleteButtonStyled,
  LabelStyled,
} from "./carousel.styled";

// builder
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";
import { useAction } from "./../../flow-builder-v2/hooks";
import { getNodeConfig } from "./../../flow-builder-v2/utils";

// helpers
import { getResponseWithoutText, isValidCarousel, getCarouselResponse, getQuickAction, } from "./../utils/helper";
import {
  getInitialEditiorText,
  isInIgnoredElement,
  getHTMLContent,
  getPlainTextData,
} from "../utils/editor";

// services
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const CarouselNode = (props: any) => {
  const builderContext = useContext(BuilderContext);
  const nodeContext = useContext(NodeContext);
  const { activeNode, setActiveNode, language } = builderContext;
  const node = getNodeConfig(nodeContext.subType || nodeContext.type);
  const resSlides = getCarouselResponse(nodeContext, language);
  const sliderRef = useRef();
  const uploadInputRef = useRef(null);
  const wrapperRef = useRef(null);

  const [showPreview, setPreview] = useState(true);
  const { updateNode, removeNode } = useAction();
  const [slides, updateSlides] = useState<any>(cloneDeep(resSlides) || []);
  const [editorState, setEditorState] = useState<any[]>([]);
  const [activeIndex, updateActiveIndex] = useState<number | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [imageURL, updateImageURL] = useState<string>("");
  const [showErrors, updateError] = useState<boolean>(false);
  const [userInputQuery, updateUserInputQuery] = useState<boolean>(
    getQuickAction(nodeContext.quickActions, "user-query") || false
  );
  const actions = nodeContext?.actions || [];
  const currentBot = useSelector(useCurrentBot);
  const validation = currentBot?.channel ? node.validation[currentBot?.channel] : {};
  const maxLimit = validation?.buttonsMaxLimit;

  useOnClickOutside(wrapperRef, (event) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }

    if (!showPreview) {
      updateText();
      updateError(!isValidCarousel(slides));
      if (
        nodeContext.id &&
        !showPreview &&
        slides.length > 0 &&
        isValidCarousel(slides)
      ) {
        const lodashResult = isEqual(
          getResponseWithoutText(nodeContext, language),
          slides
        );
        if (!lodashResult || getQuickAction(nodeContext.quickActions, "user-query") !== userInputQuery) {
          updateNode(nodeContext.nodeId, "", null, slides, false, {
            type: "user-query",
            enabled: userInputQuery,
          });
          updateSlides(cloneDeep(slides));
        }
      }
      isValidCarousel(slides) && setPreview(true);
    }
  });

  const updateText = () => {
    const tempSlides = slides.slice();
    tempSlides.map((slide: any, index: number) => {
      if (editorState[index]) {
        slide.text = getHTMLContent(editorState[index]);
      }
    });
    updateSlides(tempSlides);
  };

  const togglePreview = (index?: number) => {
    if (index !== undefined) {
      updateActiveIndex(index);
    }

    setPreview(!showPreview);
    setActiveNode(nodeContext.nodeId || nodeContext.id);
  };

  const handleCancelEditing = () => {
    if (nodeContext?.nodeId) {
      setActiveNode(null);
    } else {
      removeNode();
    }
    togglePreview();
  };

  const onSlideAddClick = () => {
    const initSlide = {
      buttons: [],
      image: "",
      subTitle: "",
      title: "",
      text: "",
      type: "carousel",
    };

    if (
      activeIndex !== null &&
      slides[activeIndex]["text"] !== "" &&
      slides[activeIndex]["image"] !== ""
    ) {
      updateSlides([...slides, ...[initSlide]]);
      // @ts-ignore
      sliderRef.current.slickNext();
      activeIndex !== null && updateActiveIndex(activeIndex + 1);
    } else {
      updateError(true);
    }
  };

  function SampleNextArrow(props: any) {
    const { className, style, onClick, currentSlide, slideCount } = props;
    return (
      <RightArrowButtonStyled
        className={`${className}`}
        onClick={
          currentSlide + 1 !== slideCount
            ? onClick
            : !showPreview
              ? onSlideAddClick
              : onClick
        }
        style={{ ...style }}
        sx={{
          "& svg": {
            transform: "rotate(180deg)",
          },
        }}
      >
        {currentSlide + 1 === slideCount && !showPreview ? (
          <Icon icon="add" size={12} />
        ) : (
          <Icon icon="chevron-left" size={12} />
        )}
      </RightArrowButtonStyled>
    );
  }

  function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <LeftArrowButtonStyled
        className={`${className}`}
        onClick={onClick}
        style={{ ...style }}
        disabled={className.includes("slick-disabled")}
      >
        <Icon icon="chevron-left" size={12} color="#7E8392" />
      </LeftArrowButtonStyled>
    );
  }

  const handleDelete = (index: number) => {
    let temporarySlides = slides.slice();
    // @ts-ignore
    temporarySlides[index]["image"] = "";
    updateSlides(temporarySlides);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let temporarySlides = slides.slice();
    // @ts-ignore
    temporarySlides[index]["title"] = event.target.value;
    updateSlides(temporarySlides);
  };

  const handleSubTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let temporarySlides = slides.slice();
    // @ts-ignore
    temporarySlides[index]["subTitle"] = event.target.value;
    updateSlides(temporarySlides);
  };

  const onEditorStateChange = (updatedEditorState: any, mediaInfo: any) => {
    const message = getHTMLContent(updatedEditorState);

    let temporaryEditorState = editorState.slice();
    // @ts-ignore
    temporaryEditorState[activeIndex] = updatedEditorState;
    setEditorState(temporaryEditorState);
  };

  const handleEditorBlur = () => {
    updateText();
  };

  const handleButtonLabelChange = (
    label: string,
    activeButtonIdx: number | null,
    index: number
  ) => {
    let temporarySlides = slides.slice();
    if (activeButtonIdx !== null) {
      const buttonsList = temporarySlides[index]["buttons"];
      const newState = buttonsList.map((button: any, index: number) => {
        if (index === activeButtonIdx) {
          return {
            ...button,
            value: { ...button.value, ...{ text: label } },
          };
        }
        return button;
      });
      temporarySlides[index]["buttons"] = newState;
      updateSlides(temporarySlides);
    }
  };

  const handleButtonDelete = (activeButton: any, index: number) => {
    let temporarySlides = cloneDeep(slides);
    if (activeButton) {
      const buttonsList = temporarySlides[index]["buttons"];
      const newState = buttonsList.filter((button: any) => button.id !== activeButton.id);
      temporarySlides[index]["buttons"] = newState;
      updateSlides(temporarySlides);
    }
  };

  const handleButtonSave = (updatedAction: any, index: number) => {
    let temporarySlides = cloneDeep(slides);
    if (updatedAction.id) {
      const buttonsList = temporarySlides[index]["buttons"];
      const previousValue = buttonsList.find(
        (button: any) => button.id === updatedAction.id
      );
      const isDifferent = isEqual(
        previousValue,
        updatedAction
      );
      if (previousValue) {
        if (!isDifferent) {
          const newState = buttonsList.map((button: any, index: number) => {
            if (button.id === updatedAction.id) {
              return {
                ...button,
                type: updatedAction.type,
                value: {
                  ...button.value,
                  ...{
                    text: updatedAction.value.text,
                    url: updatedAction.value.url,
                  },
                },
              };
            }
            return button;
          });
          temporarySlides[index]["buttons"] = newState;
          updateSlides(temporarySlides);
        }
      } else {
        temporarySlides[index]["buttons"] = [
          ...temporarySlides[index]["buttons"],
          ...[updatedAction],
        ];
        updateSlides(temporarySlides);
      }
    }
  };

  const handlePreviewButtonClick = () => {
    setPreview(false);
  };

  const afterSlideChange = (current: any) => {
    updateActiveIndex(current);
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 5242880) {
        const formData = new FormData();
        formData.append("source", file);
        ChatbotConsoleService.uploadFile(formData)
          .then((response: any) => {
            let temporarySlides = slides.slice();
            // @ts-ignore
            temporarySlides[index]["image"] = response?.data?.source;
            updateSlides(temporarySlides);
          })
          .catch((error: any) => {
          });
      } else {
      }
    }
  };

  const handleImageURLButtonClick = () => {
    updateImageURL("");
    toggleModal();
  };

  const handleSaveImgURL = (imgURL: string) => {
    updateImageURL(imgURL);
    let temporarySlides = slides.slice();
    // @ts-ignore
    temporarySlides[activeIndex]["image"] = imgURL;
    updateSlides(temporarySlides);
    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleUserInputQueryChange = (checked: boolean) => {
    updateUserInputQuery(checked);
  };

  useEffect(() => {
    if (slides && slides.length > 0) {
      const editorStateArr = editorState.slice();
      slides.map((slide: any, index: number) => {
        if (editorStateArr[index]) {
          const plainText = getPlainTextData(
            getInitialEditiorText(slide.text)
          );
          if (editorStateArr[index] && plainText !== "") {
            editorStateArr[index] = getInitialEditiorText(slide.text);
          }
        } else {
          editorStateArr[index] = getInitialEditiorText(slide.text);
        }
      });
      setEditorState(editorStateArr);
    }
  }, [slides]);

  useEffect(() => {
    if (slides && slides.length === 0) {
      updateSlides([
        {
          buttons: [],
          image: "",
          subTitle: "",
          title: "",
          text: "",
          type: "carousel",
        },
      ]);
      updateActiveIndex(0);
      setPreview(slides.length !== 0);
    }
  }, []);

  return (
    <Box
      sx={{ position: "relative" }}
      id={props.id}
      {...(((activeNode !== null && activeNode === nodeContext.id) ||
        activeNode === nodeContext.nodeId) && { ref: wrapperRef })}
    >
      <Card
        hideNodeType
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
        onUserInputQueryChange={handleUserInputQueryChange}
        userInputQuery={userInputQuery}
      >
        <NodeType
          text={node.name}
          color={node.color}
          icon={node.icon}
          secondaryText={nodeContext.stage}
        />
        <Divider />
        <Box
          onClick={(event) => {
            event.stopPropagation();
            const target = event.target as Element;
            const igClick = target.closest(".ignore-onClickOutside");
            if (Boolean(igClick)) {
              return;
            }
            togglePreview();
          }}
        >
          <Box
            className={!showPreview ? `ignore-onClickOutside` : ``}
            sx={{
              "& .slick-arrow:before": {
                fontSize: "30px",
                color: "#3654E3",
              },
              "& .slick-prev": {
                left: "-35px",
              },
              "& .slick-prev:before": {
                content: '" "',
                fontSize: 0,
              },
              "& .slick-next:before": {
                content: '" "',
                fontSize: 0,
              },
              "& .slick-dots li": {
                color: "#7E8392",
              },
              "& .slick-dots li.slick-active": {
                color: "#5B73E8",
              },
              "& .slick-dots": {
                bottom: "16px",
              },
            }}
          >
            <Slider
              className="ignore-onClickOutside"
              // @ts-ignore
              ref={(slider) => (sliderRef.current = slider)}
              {...{
                // arrows: true,
                swipe: false,
                dots: true,
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                nextArrow: <SampleNextArrow />,
                prevArrow: <SamplePrevArrow />,
                afterChange: (current) => afterSlideChange(current),
                appendDots: (dots) => <ul>{dots}</ul>,
                customPaging: (i) => (
                  <CarouselDotStyled key={`carousel-dots-${i + 1}`}>
                    <Icon
                      icon="carousel"
                      size={14}
                    />
                  </CarouselDotStyled>
                ),
              }}
            >
              {slides.map((slide: any, index: number) => (
                <Box
                  key={`carousel-slide-${index}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    showPreview && togglePreview(index);
                  }}
                  sx={{ p: "16px", pb: "40px" }}
                >
                  {/* carousel image */}
                  <Box sx={{ position: "relative" }}>
                    {showPreview || slide.image ? (
                      <>
                        <Box
                          component="img"
                          src={slide.image}
                          alt="carousel-image"
                          sx={{
                            width: "100%",
                            height: "240px",
                            objectFit: "cover",
                          }}
                        />
                        {!showPreview && (
                          <DeleteButtonStyled
                            component="button"
                            onClick={() => handleDelete(index)}
                          >
                            <Icon icon="delete" color="#7E8392" size={14} />
                          </DeleteButtonStyled>
                        )}
                      </>
                    ) : (
                      <MediaUploadPlaceholder error={showErrors}>
                        <Icon icon="add-image" color="#5B73E8" size={22} />
                        <Typography
                          sx={{
                            color: "#7E8392",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "21px",
                            my: "8px",
                          }}
                        >
                          Drop JPG, PNG File to upload or{" "}
                          <input
                            ref={uploadInputRef}
                            type="file"
                            accept="image/*"
                            id="raised-button-file"
                            style={{ display: "none" }}
                            onChange={(event) =>
                              handleFileChange(event, index)
                            }
                          />
                          <label htmlFor="raised-button-file">
                            <UIButton
                              disableRipple
                              sx={{
                                p: "0 !important",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                              onClick={() =>
                                // @ts-ignore
                                uploadInputRef.current && uploadInputRef?.current?.click()
                              }
                              variant="text"
                            >
                              Browse
                            </UIButton>
                          </label>
                        </Typography>
                        <UIButton
                          variant="outlined"
                          startIcon={<Icon icon="link" size={14} />}
                          onClick={handleImageURLButtonClick}
                        >
                          Image URL
                        </UIButton>
                      </MediaUploadPlaceholder>
                    )}
                  </Box>
                  {/* text block */}
                  <Box>
                    {showPreview ? (
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "21px",
                          py: "4px",
                          color: "#101010",
                        }}
                      >
                        {slide.title}
                      </Typography>
                    ) : (
                      <>
                        <LabelStyled>Title</LabelStyled>
                        <Input
                          placeholder="Title"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleTitleChange(event, index)}
                          value={slide.title}
                        />
                      </>
                    )}
                    {showPreview ? (
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "16px",
                          lineHeight: "21px",
                          py: "4px",
                          color: "#101010",
                        }}
                      >
                        {slide.subTitle}
                      </Typography>
                    ) : (
                      <>
                        <LabelStyled>Subtitle</LabelStyled>
                        <Input
                          placeholder="Subtitle"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleSubTitleChange(event, index)}
                          value={slide.subTitle}
                        />
                      </>
                    )}
                    {showPreview ? (
                      <MessagePreview text={slide.text} />
                    ) : (
                      <>
                        <LabelStyled>Description</LabelStyled>
                        <Editor
                          error={showErrors && getPlainTextData(editorState[index]) === ''}
                          hideFocusOnLoad
                          editorState={editorState[index]}
                          onEditorStateChange={onEditorStateChange}
                          onBlur={handleEditorBlur}
                          media={false}
                        />
                      </>
                    )}
                  </Box>
                  {/* button block */}
                  <Box sx={{ mx: "-16px" }}>
                    <ButtonList
                      language={language}
                      showPreview={showPreview}
                      buttonsList={!slide.buttons ? [] : slide.buttons}
                      onLabelChange={(label, activeBtnIdx) =>
                        handleButtonLabelChange(label, activeBtnIdx, index)
                      }
                      onButtonSave={(buttonAction) =>
                        handleButtonSave(buttonAction, index)
                      }
                      onPreviewButtonClick={handlePreviewButtonClick}
                      onButtonDelete={(activeButton) => handleButtonDelete(activeButton, index)}
                      maxLimit={maxLimit}
                    />
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>
        {userInputQuery && showPreview && (
          <>
            <Divider />
            <Box
              sx={{ p: "12px 16px" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Typography
                sx={{
                  display: 'inline-block',
                  p: '4px',
                  borderRadius: '4px',
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 400,
                  color: "#7E8392",
                  backgroundColor: "#F5F6F8"
                }}
              >
                User input query
              </Typography>
            </Box>
          </>
        )}
      </Card>
      <Action actions={actions} nodeContext={nodeContext} />
      <ImgModal
        open={showModal}
        onClose={toggleModal}
        url={imageURL}
        onSave={handleSaveImgURL}
      />
      {slides.length === 1 && !showPreview && (
        <Box
          className={"add-button"}
          sx={{ position: "absolute", right: 0, top: "50%" }}
        >
          <RightArrowButtonStyled
            onClick={onSlideAddClick}
            sx={{
              "& svg": {
                transform: "rotate(180deg)",
              },
            }}
          >
            <Icon icon="add" size={12} />
          </RightArrowButtonStyled>
        </Box>
      )}
    </Box>
  );
};
export default CarouselNode;
