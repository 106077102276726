import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InstagramSettingsActions } from "./instagramSettings.actions";

interface initialStateTypes {
  data: {
    instagramConfigData: any;
    instagramConfigList: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    instagramConfigData: "",
    instagramConfigList: "",
  },
  error: "",
  loading: false,
};

const InstagramSettingsSlice = createSlice({
  name: "InstagramSettingsSlice",
  initialState,
  reducers: {
    resetError: (state: any, action?: PayloadAction<any>) => {
      state.error = "";
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      // add  Facebook config data
      .addCase(
        InstagramSettingsActions.instagramConfigData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        InstagramSettingsActions.instagramConfigData.fulfilled,
        (state, action) => {
          if (action?.payload?.data?.status === 200) {
            state.data.instagramConfigData = true;
            state.data.instagramConfigList = "";
          } else {
            state.data.instagramConfigData = null;
            state.data.instagramConfigList = "";
          }
        },
      )
      .addCase(
        InstagramSettingsActions.instagramConfigData.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // get  facebook config data
      .addCase(
        InstagramSettingsActions.instagramConfigList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        InstagramSettingsActions.instagramConfigList.fulfilled,
        (state, action) => {
          state.data.instagramConfigList = action?.payload?.data;
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        InstagramSettingsActions.instagramConfigList.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // get  Facebook config data
      .addCase(
        InstagramSettingsActions.logoutInstagramConfiguration.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        InstagramSettingsActions.logoutInstagramConfiguration.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        InstagramSettingsActions.logoutInstagramConfiguration.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // get  Facebook Disconnect page
      .addCase(
        InstagramSettingsActions.disconnectSingleInstagramPage.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        InstagramSettingsActions.disconnectSingleInstagramPage.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        InstagramSettingsActions.disconnectSingleInstagramPage.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      );
  },
});
export const { resetError } = InstagramSettingsSlice.actions;
export default InstagramSettingsSlice.reducer;
