import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const AppInput = (props: any) => {
    const CHARACTER_LIMIT = 20;
    const [name, setName] = useState('');

    const setNameFn = (e: any) => {
        setName(e.target.value);

        if (props?.handleChange) {
            props?.handleChange(e.target.value);
        }
    };

    useEffect(() => {
        setName(props?.value || '');
    }, [props?.value]);

    return (
        <>
            <textarea
                {...props}
                value={props?.value}
                onChange={setNameFn}
                maxLength={props?.characterLimit || CHARACTER_LIMIT}
                placeholder={props?.placeholder}
            />
            <InputAdornment style={{
                position: "absolute",
                right: "50px",
                top: "145px"
            }} position="start">{props?.value?.length}/{props?.characterLimit || CHARACTER_LIMIT}</InputAdornment>
        </>
    );
};

export default AppInput;
