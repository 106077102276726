import { useEffect } from "react";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { Box } from "@mui/material";
import Paper from '@mui/material/Paper';
import AppNoDataLoader from "../../AppComponents/AppNoDataLoader";
import uniqid from "uniqid";
import InfiniteScroll from "react-infinite-scroll-component";
import { InfiniteScrollTableDiv } from '../../Customization/CommonElements'
import InfiniteLoaderComponent from "../../AppComponents/Loader/InfiniteLoaderComponent";
import styled from "styled-components";

export interface Props extends LoaderComponentProps {
  columns: any[];
  dataRows: any[];
  fetchMoreData: () => {};
  hasMore: boolean;
  loadingState?: boolean;
}

const DashboardCommonInfScrollTable: React.FC<Props> = ({ columns, dataRows, fetchMoreData, hasMore, loadingState, setLoading }) => {

  useEffect(() => {
    if (loadingState === false) {
      setLoading(false)
    }
  }, [loadingState]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, dataRows.length);
  // no data found
  if (!dataRows || dataRows.length < 1) {
    return (
      <Box className="inf-scroll-no-data-div">
        <Paper className="inf-scroll-no-data-div__paper">
          <AppNoDataLoader />
        </Paper>
      </Box>
    )

  }

  function renderRowAsPerColumn(row: any, index: number) {
    return (
      <Box className="table-body-row" key={uniqid()}>
        {
          columns?.map((column: any) => {
            return (
              <Box key={uniqid()} p={1} sx={{ textAlign: `${column.align || 'start'}`, width: `${column.width || 'auto'}` }} className="table-row">
                {row[column?.dataKey]}
              </Box>
            )
          })
        }
      </Box>
    )
  }

  return (
    <InfiniteScrollTableDiv>
      <Paper >
        <Box className="table-wrapper">
          <Box className="table-heading">
            {
              columns?.map((column: any) => {
                return (
                  <Box key={uniqid()} sx={{ textAlign: `${column.align || 'start'}`, width: `${column.width || 'auto'}` }} className='table-row'>
                    {column.label}
                  </Box>
                )
              })
            }
          </Box>
          <InfiniteScroll
            dataLength={dataRows.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={<InfiniteLoaderComponent />}
            scrollThreshold={0.9}
            height={235}
            endMessage={<StyledEmptyRowMessageBox> ~~~ No more entries to show ~~~</StyledEmptyRowMessageBox>}
          >
            {
              dataRows?.map((singleRow: any, index: number) => {
                return (
                  renderRowAsPerColumn(singleRow, index)
                )
              })
            }
          </InfiniteScroll>
        </Box>
      </Paper>
    </InfiniteScrollTableDiv>
  )
};

export default withLoader(DashboardCommonInfScrollTable);


const StyledEmptyRowMessageBox = styled.span`
&&{
  width:100%; display:flex; justify-content:center; align-items:center;
}
`


