import http from "../../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class LiveChatNetworkService {
  // Create Message
  static postCannedMessage(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.CANNED_MESSAGES}`, {
      ...data.payload,
      headers: data.HEADERS,
    });
  }

  // Get All The Listing Of Messages
  static getCannedMessageListings(data: any) {
    const { limit, offset, search } = data;
    const url = `${consoleBaseUrl}${Endpoints.CANNED_MESSAGES}`;
    const headers = data.HEADERS;
    const params = { limit, offset };

    return http.get(url, { headers, params });
  }

  // Delete Message By ID
  static deleteCannedMessage(data: any) {
    const { id, HEADERS } = data;
    return http.delete(`${consoleBaseUrl}${Endpoints.CANNED_MESSAGES}/${id}`, {
      headers: HEADERS,
    });
  }

  // Get Message By ID
  static getCannedMessage(data: any) {
    const { id, HEADERS } = data;
    return http.get(`${consoleBaseUrl}${Endpoints.CANNED_MESSAGES}/${id}`, {
      headers: HEADERS,
    });
  }

  // Update Message By ID
  static updateCannedMessage(data: any) {
    const { id, payload, HEADERS } = data;
    return http.put(
      `${consoleBaseUrl}${Endpoints.CANNED_MESSAGES}/${id}`,
      payload,
      { headers: HEADERS }
    );
  }

    // Search 
    static searchCannedMessage(data: any) {
        const { limit, offset, search } = data;
        let searchQuery = '';
        if (search?.length > 0) {
        searchQuery =`?query=${search}` ?? '';
        }
        const url = `${consoleBaseUrl}${Endpoints.CNNED_SEARCH}${searchQuery}`;
        const headers = data.HEADERS;
        const params = { limit, offset };

    return http.get(url, { headers, params });

    // const { limit, offset, search } = data;
    // const url = `${consoleBaseUrl}${Endpoints.CANNED_MESSAGES}`;
    // const headers = data.HEADERS;
    // const params = { limit, offset };
  }
}

// Live Chat End Points
class Endpoints {
  static CANNED_MESSAGES = "/console/canned-responses";
  static CNNED_SEARCH = "/console/search/canned-responses";
}
