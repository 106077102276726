import AppButton from "../../../../AppComponents/AppButton"
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons"
import AppModel from "../../../../Hooks/useModel/AppModel"
import { DeleteCannedResponse } from "../../ChatSettings/CannedResponse/CannedResponsesstyles"
import Danger from "../../../../assets/images/danger-live.svg";
import { useDispatch } from "react-redux";
import { setDeleteModel } from "../../../../Store/Slices/Settings/ContactProperties/contactProperties.slice";
const DeleteModel = (props :any) => {
    const dispatch = useDispatch()
    
    return (
        <AppModel
            isShowing={props.deleteModel}
            onClose={props.setDeleteModel}
            divStyle={{
                padding: 0,
            }}
            PaperProps={{
                style: {
                    boxShadow: "none",
                    width: "500px",
                },
            }}
        >
            <DeleteCannedResponse>
                <div className='header'>
                    <div className='header-left'>
                        <div className='danger'>
                            <img src={Danger} alt=""/>
                        </div>
                        <div className='text'>
                            <h4>Delete Section</h4>
                            <p>Are you sure, you want to delete?</p>
                        </div>
                    </div>
                    <div className='header-right'>
                        <span onClick={() => dispatch(setDeleteModel(false))}>
                            <AppMaterialIcons iconName="close" />
                        </span>
                    </div>
                </div>
                <div className='footer'>
                    <AppButton variant='grey' onClick={() =>  dispatch(setDeleteModel(false))} >
                        Cancel
                    </AppButton>
                    <AppButton variant='danger-filled' startIcon="ri-delete-bin-7-fill" onClick={props.deleteSection} >
                        Delete
                    </AppButton>
                </div>

            </DeleteCannedResponse>
        </AppModel>
    )
}
export default DeleteModel