import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import { Paper } from "@mui/material";

export const RoleUserTable = styled.div`
  height: calc(100vh - 220px);
  background-color: ${theme.palette.default.white};
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow:scroll;
  .menu_item{
    color:${theme.palette.default.darkGrey};
    font-size:14px;
  }
  .role_name_container{
    padding: 10px 0px 0px 20px;
    width:300px;
  }
  .parent_module{
    margin-left:10px;
  }
  .row {
    margin: 1rem 0;
    background-color: ${theme.palette.default.white};
    .select {
      width: 180px;
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100px;
    border-bottom: 1px solid rgb(126, 131, 146, 0.2);
    padding: 0rem 0.5rem;
    .module_title {
      display: flex;
      flex-direction: column;
    }
    .title {
      font-weight: 500;
      font-size: 16px;
      color: ${theme.palette.default.black};
    }
  }
  .main {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(126, 131, 146, 0.2);
    padding: 0.5rem 0rem 0.5rem 8rem;
    .title {
      padding-top: -10px;
    }
    .module_title_container {
      display: flex;
      align-items: center;
      min-width: 10%;
      margin-right: 20px;
    }
    .subDataRight {
      padding: 1.5rem 0.5rem;
      display: flex;
      align-items: center;
      .featuresWrapper {
        display: flex;
        align-items: flex-start;
        gap: 25px;
      }
    }
  }
  .sub_header {
    padding: 1rem 0px 1rem 18rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgb(126, 131, 146, 0.2);
    .title {
      font-weight: 400;
      font-size: 14px;
      font-color: ${theme.palette.default.black};
    }
    .select_container {
      display: flex;
      flex-direction: column;
    }
    .sub_header_container {
      display: flex;
      gap: 20px;
      align-items: center;
      .sub_header_container_switch {
        min-width: 20%;
        display: flex;
        align-items: center;
      }
    }
    .createField {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 20px;
    }
    .sub_childs_container {
      display: block;
      gap: 10px;
      .fields {
        display: flex;
        width: 250px;
      }
    }
    .sub_childs_child_container{
      padding:1rem 0px 1rem 12rem;
      padding-left:12rem;
    }
  }
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 133px;
    min-width: 152px;
    width: 210px;
    height: 40px;
    background-color: ${theme.palette.default.lighterFilterGrey};
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }
    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
      background-color: ${theme.palette.default.lightGrey};
      position: absolute;
      top: 1px;
      right: 10px;
      height: calc(100% - 2px);
      display: flex;
      align-items:center; 
      justify-content: center;      
}`

export const Container = styled(Paper)`
  width: 100%;
  overflow-x: clip;
`;