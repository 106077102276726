import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import NoRecordsFound from './NoRecordsFound/NoRecordsFound';
import { theme } from '../../Customization/Theme';
import AppSkeletonTableLoader from './AppSkeletonTableLoader/AppSkeletonTableLoader';


interface BorderedTableProps {
    // handleClick?: any;
    handleClick?: (data: any) => void;
    handleActionClick?: (data: any) => void;
    keyToHeaderMap: any;
    tableData: any;
    height?: any;
    pagination?: boolean;
    border?: boolean;
    apiError?: boolean;
    type?: string;
    tableHeaderColor?: boolean;
    actionColumn?: boolean
    isLoading?: boolean,
    tableColWidth?: any
}

const BorderedTable: React.FC<BorderedTableProps> = ({
    keyToHeaderMap,
    handleClick = () => { },
    handleActionClick = () => {},
    tableData,
    // handleClick,
    height,
    pagination = false,
    border = true,
    apiError,
    type,
    tableHeaderColor = false,
    actionColumn = false,
    isLoading = false,
    tableColWidth
}) => {

  const renderContent = (key: any, item: any, keyToHeaderMap: any) => {
    if (keyToHeaderMap[key] === "Cost") {
      return (
        <span>
          {item[key] < 0 ? (
            <span> ₹ {item[key]} </span>
          ) : (
            <span style={{ color: "green" }}> ₹ + {item[key]} </span>
          )}
        </span>
      );
    }
    if (keyToHeaderMap[key] === "Description") {
      if (item["total_amount"] < 0) {
        return `Balance deducted for ${item[key]}`;
      } else {
        return `Balance added for ${item[key]}`;
      }
    }
    return item[key];
  };

  const renderActionContent = (item: any, key: string) => {
    if (key === "total_amount") {
        return item[key] < 0 ?  <div style={{ cursor: "pointer" }} onClick={() => handleActionClick({ ...item })}>View Details</div>  : <div onClick={() => handleActionClick({type: "invoice", ...item})} style={{ cursor: "pointer" }}>Download Invoice</div>;
    }
    return null;
  };

    return (

        <>
        
        
        
        {
                  isLoading ? <AppSkeletonTableLoader
                  numberOfCol={4}
                  numberofRow={10}
                  skeletonHeight={30}
                  skeletonWidth={"100%"}
                  skeletonHeaderHeight={40}
                  skeletonHeaderWidth={"100%"}
                  tableHeight={"calc(100vh - 603px)"}
  
              />  : <TableWrapper
              border={border}
              height={pagination ? height : "calc(100%)"}
              headerColor={tableHeaderColor}
          >
              <div>
                  <Box className="table-header">
                      <Box className="table-row">
                          {Object.keys(keyToHeaderMap).map((key) => {
                          return    <div className="col-head"  key={key} style={{
                                  backgroundColor: tableHeaderColor ? "#EFEEFC" : "#fff",
                                  width: tableColWidth[key],
                                  maxWidth: tableColWidth[key]
                              }}>
                                  <span className="header-item">
                                  {keyToHeaderMap[key]}
                                  </span>
                              </div>
                          })}
  
                {
                  actionColumn && <div className='col-head action-col-head'>
                    Action
                  </div>
                }
                            
                      </Box>
                  </Box>
  
  
                 
  
  
  
                  {apiError ? (
                      <Box className="api-error">
                          API Error: Unable to fetch data.
                      </Box>
                  ) : tableData?.length === 0 ? (
                      <Box className="no-records-found">
                          <NoRecordsFound height="100%" />
                      </Box>
                  ) : (
                      <Box className="table-body">
                          {tableData?.map((item: any, rowIndex: any) => {
                              return (
                                  <Box key={rowIndex} className="table-row">
                                      {Object.keys(keyToHeaderMap).map((key, columnIndex) => (
                                          <div
                                              title={item[key]}
                                              className={`col-body ${columnIndex === 0 ? 'first-column' : ''} ${keyToHeaderMap[key] == "Cost" && "cost-column"}`}
                                              style={{
                                                width: tableColWidth[key],
                                                maxWidth: tableColWidth[key]
                                              }}
                                              key={`${rowIndex}-${key}`}
                                              onClick={() => {
                                                  if (columnIndex === 0) {
                                                      handleClick({ ...item, type });
                                                  } else {
                                                      return;
                                                  }
                                              }}
                                          >
                                          {renderContent(key, item, keyToHeaderMap)}
                                          </div>
                                      ))}
  
                                  {actionColumn && (
                                    <div
                                      className='col-body action-col'
                                      
                                    >
                                      {renderActionContent(item, "total_amount")}
                                    </div>
                                  )}
                                  </Box>
                              );
                          })}
                      </Box>
                  )}
              </div>
          </TableWrapper>
                }
        
        
        
        
        
        </>


        

    );
};

export default BorderedTable;

const TableWrapper = styled.div`
  & {
    // padding: 0px 12px 10px;
    border-radius: 4px;
    width: 100%;
    // height: 100%;
    height: ${(props: any) => props.height};
    border-left: 1px solid ${(props: any) => props.border ? theme.palette.default.border : "transperant"};
    border-right: 1px solid ${(props: any) => props.border ? theme.palette.default.border : "transperant"};
    border-bottom: 1px solid ${(props: any) => props.border ? theme.palette.default.border : "transperant"};
    background-color: ${theme.palette.default.white};

    .no-records-found {
      height: calc(100% - 47px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div {
      overflow: overlay;
      // height: calc(100vh - ${(props: any) => props.height});
      height: 100%;
    }
    > div:hover {
      overflow: overlay;

        ::-webkit-scrollbar-thumb {
      background-color: rgba(126, 131, 146, 0.5);
      // background-color: rgba(126, 131, 146, 0.5);
      border-radius: 4px;
    }

    }
    > div::-webkit-scrollbar:hover {
      width: 6px;
    }

    > div::-webkit-scrollbar-track {
        background-color: transparent;
    }
    > div::-webkit-scrollbar-thumb {
      background-color: transparent;
      // background-color: rgba(126, 131, 146, 0.5);
      border-radius: 4px;
    }
    .table-header {
      position: sticky;
      top: 0;
      z-index: 1;
      .table-row {
        display: flex;
        align-items: center;
      }
      .col-head {
        min-width: 100px;
        max-height: 52px;
        height: 52px;
        text-transform: capitalize;
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        // color: #211C52;
        font-size: 13px;

        
        .header-item {
            border-right: 1px solid rgba(126, 131, 146, 0.3);
            width: 100%;
            text-align: left;
            height: 32px;
            max-height: 32px;
            display: flex;
            align-items: center;
            &:last-child {
              border-right: none;
            }
        }

        
        

        backgroundColor: ${(props: any) => props.headerColor ? "#EFEEFC" : "#fff"}; 

        border-bottom: 1px solid ${(props: any) => props.headerColor ? "transparent" : "rgba(126, 131, 146, 0.3)"};
        border-top: 1px solid ${(props: any) => props.headerColor ? "transparent" : "rgba(126, 131, 146, 0.3)"};
        padding: 10px 34px;
        padding-right: 0;
        // background-color: ${theme.palette.default.white};
        p {
          font-size: 13px;
          font-weight: 500;
        }
        &:first-child {
          p {
          }
        }
      }

      .action-col-head {
        max-width: 180px;
        padding-right: 34px;
        justify-content: flex-end;
        background-color: ${theme.palette.default.white};
      }
    }
    .table-body {
      .actionColumn {
        border-bottom: 1px solid #E9E8EE;
      }
      .table-row {
        display: flex;
        // justify-content: center;
        color: ${theme.palette.default.black};
        .first-column {
          cursor: pointer;
        }
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .col-body {
        white-space: nowrap;
        min-width: 100px;
        font-size: 13px;
        font-weight: 400;
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
        color: rgba(126, 131, 146, 1);
        max-height: 49px;
        height: 49px;


        text-transform: capitalize;
        div > :first-child {
        }
        flex: 1;
        // display: flex;
        word-break: break-all;
        align-items: center;
        padding: 16px 34px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.2);
        
        
        &:first-child {
          p {
            color: #101010;
            text-decoration: none;
            max-width: ${(props: any) => props.firstColTextWidth};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        P {
          font-size: 13px;
        }
      }

      .action-col {
        color: rgba(97, 80, 225, 1);
        font-size: 13px;
        font-weight: 400;
        cursor: pointer;
        max-width: 180px;
        text-align: right;
      }

      .cost-column {
        color: rgba(243, 78, 78, 1);
      }
    }
  }
`;
