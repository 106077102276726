// import styled from "styled-components";
import { addYears, isSameDay } from "date-fns";
import { addDays } from "date-fns/esm";
import moment from "moment";
import { DateRange, DateRangePicker } from "mui-daterange-picker";
import React, { useEffect, useRef, useState } from "react";
import AppButton from "./AppButton";
import AppIcon from "./AppIcon";
import { Backdrop } from "@mui/material";
import {
  ActionBox,
  StyledBox,
  StyledDateField,
  StyledDatePickerWrapper,
  StyledTextField,
} from "../Customization/CommonElements";
import { theme } from "../Customization/Theme";
import {
  DateRangeOptions,
  defaultRanges,
  defaultRangesExcludeToday,
} from "../Models/defaultSelectOptions";


interface Props {
  OPENING_SIDE?: string;
  OPENING_POSITION?: string;
  dateRange: any;
  setDateRange: any;
  divStyle?: any;
  reset?: boolean;
  subClass?: string;
  disabled?: boolean;
  hideTodayOption?: boolean;
  dateFieldStyle?: any;
}
const AppRangeDatePicker: React.FC<Props> = ({
  dateRange,
  setDateRange,
  divStyle,
  reset,
  subClass,
  hideTodayOption,
  disabled = false,
  dateFieldStyle
}) => {
  const myRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [tempDateRange, setTempDateRange] = useState<DateRange | null>(
    dateRange
  );
  
  // To update the date range when the date range is updated from the parent component
  useEffect(() => {
    setTempDateRange(dateRange);
  }, [dateRange]);

  const [OPENING_SIDE, setOPENING_SIDE] = useState<string>("Left");
  const [OPENING_POSITION, setOPENING_POSITION] = useState<string>("Bottom");
  const [selectedInputLabel, setSelectedInputLabel] = useState<string | number>(
    0
  );
  const [showRestButton, setshowRestButton] = useState<boolean>(false);
  const toggle = () => setOpen(!open);

  const handleCancelBtn = () => {
    setTempDateRange(dateRange);
    toggle();
  };
  const handleApplyBtn = () => {
    setDateRange(tempDateRange);
    toggle();
  };
  const handleResetBtn = () => {
    setDateRange({ startDate: "", endDate: "" });
    setTempDateRange(null);
    setSelectedInputLabel(0);
    toggle();
  };

  useEffect(() => {
    const x = myRef?.current?.offsetLeft;
    const y = myRef?.current?.offsetTop;
    const z = myRef.current.offsetParent.offsetLeft;
    let tempSide = window.innerWidth / 2 - x > 0;

    let tempSide1 = window.innerWidth / 2 - z > 0;
    let tempPosition = window.innerHeight / 2 - y > 0;
    if (tempSide && tempSide1) {
      setOPENING_SIDE("Right");
    } else {
      setOPENING_SIDE("Left");
    }
    if (tempPosition) {
      setOPENING_POSITION("Bottom");
    } else {
      setOPENING_POSITION("Top");
    }
  }, [myRef?.current, open]);   // eslint-disable-line react-hooks/exhaustive-deps

  const checkLabelActive = (tempDate: any) => {
    const result: any =
      DateRangeOptions &&
      DateRangeOptions?.length > 0 &&
      DateRangeOptions?.map((curItem: any, index) => {
        if (
          isSameDay(curItem?.range?.startDate, tempDate?.startDate) &&
          isSameDay(curItem?.range?.endDate, tempDate?.endDate)
        ) {
          setSelectedInputLabel(curItem?.value || 0);
          return "found";
        } else {
          return "notFound";
        }
      });
    if (!result?.includes("found")) {
      setSelectedInputLabel(0);
    }
  };

  const handleLabelRangeSelection = (selectedValue: string | number) => {
    if (!selectedValue) {
      return;
    }

    let selectedLabel: any = DateRangeOptions.find(
      (item) => item?.value === selectedValue
    );
    if (selectedLabel) {
      setTempDateRange(selectedLabel?.range);
    }
  };
  useEffect(() => {
    if (reset) {
      setshowRestButton(true);
    }

    if (selectedInputLabel) {
      handleLabelRangeSelection(selectedInputLabel);
    }
  }, [selectedInputLabel]);   // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tempDateRange) {
      checkLabelActive(tempDateRange);
    }
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <StyledBox ref={myRef} style={divStyle} $disabled={disabled}>
      <StyledDateField onClick={toggle} >
        <StyledTextField style={dateFieldStyle}>
          {tempDateRange?.startDate
            ? moment(tempDateRange?.startDate).format("L")
            : "Start Date"}
          {" "}
          <AppIcon
            title={"ri-arrow-right-line"}
            size="16px"
            color={`${theme.palette.default.lighterBlack}`}
          />
          {tempDateRange?.endDate
            ? moment(tempDateRange?.endDate).format("L")
            : "End Date"}
        </StyledTextField>
      </StyledDateField>

      {open && (
        <StyledDatePickerWrapper
          OPENING_SIDE={OPENING_SIDE}
          OPENING_POSITION={OPENING_POSITION}
        >
          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range: any) => {
              setTempDateRange(range);
              checkLabelActive(range);
            }}
            closeOnClickOutside
            initialDateRange={tempDateRange ? tempDateRange : undefined}
            maxDate={hideTodayOption ? addDays(new Date(Date.now()), -1) : new Date(Date.now())}
            minDate={addYears(new Date(Date.now()), -2)}
            definedRanges={hideTodayOption ? defaultRangesExcludeToday : defaultRanges}
            wrapperClassName={"styledDate"}
          />
          <ActionBox>
            <div className="leftBtnBox">
              {showRestButton ? (
                <AppButton
                  variant="outline"
                  className={"applyBtn"}
                  style={{ width: "80px" }}
                  onClick={() => {
                    handleResetBtn();
                  }}
                >
                  Reset
                </AppButton>
              ) : (
                ""
              )}
            </div>
            <div className="rightBtnBox">
              <AppButton
                variant="outline"
                className={"applyBtn"}
                style={{ width: "80px" }}
                onClick={() => {
                  handleCancelBtn();
                }}
              >
                Cancel
              </AppButton>
              <AppButton
                className={"applyBtn"}
                style={{ width: "90px" }}
                onClick={() => {
                  handleApplyBtn();
                }}
              >
                Apply
              </AppButton>
            </div>
          </ActionBox>
          <Backdrop
            sx={{
              color: `${theme.palette.default.white}`,
              zIndex: (theme) => theme.zIndex.drawer + 1,
              overflow: "auto",
            }}
            open={open}
            onClick={toggle}
          ></Backdrop>
        </StyledDatePickerWrapper>
      )}
    </StyledBox>
  );
};

export default AppRangeDatePicker;
