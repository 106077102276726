import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { TextTitle } from "../../Email_Broadcast_Overview_Summary/EmailBroadcastSummaryStyles";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import SkeletonLoader from "../../../../AppComponents/Loader/SkeletonLoader";
import { ErrorComponent } from "../../../Broadcast/SMS/SenderID/ListingStyles";
import NoData from "../../../../assets/images/noDatafound.svg";
import styled from "styled-components";
import { getRoundOffNumbers } from "../../../../Utils";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { WrapperDeviceTable } from "../../Email_Broadcast_Overview_Summary/DeviceTableStyles";
import NoRecordImage from "../../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";

interface Props {
  data?: any[];
  loading?: boolean;
}

interface RowProps {
  row: any;
  i: any;
  open: any;
  handleRowClick: any;
}

const Row: React.FC<RowProps> = ({ row, i, handleRowClick, open }) => {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ width: "5%" }}>
          {row?.opened_count === 0 &&
          row?.clicked_count === 0 &&
          row?.unsubscribed_count === 0 ? (
            ""
          ) : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleRowClick(i)}
            >
              {open === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: "65%" }}>
          {row?.device_type}
        </TableCell>
        <TableCell align="center">
          {getRoundOffNumbers(row?.opened_count || 0) || 0}
        </TableCell>
        <TableCell align="center">
          {getRoundOffNumbers(row?.clicked_count || 0) || 0}
        </TableCell>
        <TableCell align="center">
          {getRoundOffNumbers(row?.unsubscribed_count || 0) || 0}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open === i} timeout="auto" unmountOnExit={true}>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row?.device_info?.map((historyRow: any, index: number) => (
                    <TableRow sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }} key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          width: " 70%",
                          paddingLeft: "68px",
                          color: "#7E8392",
                          height: "60px",
                        }}
                      >
                        {historyRow?.device}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "#7E8392", height: "60px" }}
                      >
                        {getRoundOffNumbers(historyRow?.opened_count || 0) || 0}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "#7E8392", height: "60px" }}
                      >
                        {getRoundOffNumbers(historyRow?.clicked_count || 0) ||
                          0}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "#7E8392", height: "60px" }}
                      >
                        {getRoundOffNumbers(
                          historyRow?.unsubscribed_count || 0
                        ) || 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const DevicesGraphTable: React.FC<Props> = ({ data, loading }) => {
  const [open, setOpen] = React.useState(null);

  const handleRowClick = (rowIndex: any) => {
    setOpen((prevOpenRow) => (prevOpenRow === rowIndex ? undefined : rowIndex));
  };

  const hasNoData = data?.every((row: any) => row?.opened_count === 0 && row?.clicked_count === 0 && row?.unsubscribed_count === 0);

  if (data?.length === 0) {
    return <p> no data found </p>;
  }
  return (
    <Wrappertable>
      {
        loading ? (
          <AppSkeletonLoader />
        ) : (
            <Box height={"inherit"} sx={{backgroundColor: "#fff"}}>
      <ApexChatWrapper>
        <Box className="heading__tooltip">
          <TextTitle>
            <Typography className="heading">Device Stats</Typography>
          </TextTitle>
          <AppToolTip
            tooltipProps={{
              title:
                "Insights into the types of devices used by recipients to interact with emails, providing information on the diversity of devices utilized for accessing email content.",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={
              <AppMaterialIcons
                iconName="HelpOutline"
                style={{ fontSize: "14px" }}
              />
            }
          />
        </Box>
      </ApexChatWrapper>

      <WrapperDeviceTable >
        <TableContainer
          component={Paper}
          className="table-container"
          style={{ height: "calc(100% - 16px)", margin: "8px", width: "calc(100% - 16px)" }}
          elevation={0}
        >
          {loading ? (
            <AppSkeletonLoader />
          ) : data?.length === 0 ? (
            <NoRecordsFound
              imageWidth={"200px"}
              headerTextSize={"19px"}
              imageSrc={NoRecordImage}
            />
          ) : (
            <>
              {
                hasNoData ? (
                  <NoRecordsFound
                    imageWidth={"200px"}
                    headerTextSize={"19px"}
                    imageSrc={NoRecordImage}
                  />
                ) : (
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell> Device Type </TableCell>
                        <TableCell align="center">Opened</TableCell>
                        <TableCell align="center">Clicked&nbsp;</TableCell>
                        <TableCell align="center">Unsubscribed&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((row: any, index: number) => (
                        <Row
                          key={index}
                          row={row}
                          i={index}
                          open={open}
                          handleRowClick={handleRowClick}
                        />
                      ))}
                    </TableBody>
                  </Table>
                )
              }
            </>
          )}
        </TableContainer>
      </WrapperDeviceTable>
            </Box>
        )
      }
    </Wrappertable>
  );
};

export default DevicesGraphTable;

export const ApexChatWrapper = styled.div`
  && {
    .apexcharts-toolbar {
      .exportCSV {
        border: 1px solid red !important;
        display: none !important;
      }
    }
    width: 100%;
    .heading__tooltip {
      height: 61px;
       padding: 16px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      z-index: 1;
      .heading {
        font-size: 16px;
        font-weight: 500;
      }
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }
`;

const Wrappertable = styled.div`
  & {
    width: 100%;
    height: 100%;
    // background-color: white;
    border-radius: 4px;
    // padding: 16px 20px;
  }
`;
