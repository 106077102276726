import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { Toolbar } from "@mui/material";

export const StyledCreateBroadcastWrap = styled.div`
  && {
    // background-color: ${theme.palette.default.lightPrimaryBackground};
    width: 100%;
    // height: 100vh;
    // border: 1px solid blue;
    height: 100%;
    overflow: hidden;
    // padding: 0rem 0rem 0.5rem;
    // border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    z-index: 1;
  }
`;

export const StyledTableToolbar = styled(Toolbar)`
  && {
    width: 100%;
  
    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
    .top{
      display: flex;
      flex-direction: row;
      gap: 2rem;
      width: 100%;
    }
    .bottom{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      width: 100%;
      padding: 0 0rem 1rem;

      .toolbarText {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 1rem;
      color: ${theme.palette.default.text};
      line-height: 20px;
      white-space: nowrap;
    }
    }

  }
`;
export const StyledRenderQueryFilterWrap = styled.div`
  && {
    .form-heading {
      display: flex;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      padding: 0rem 1rem 0rem 1rem;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 9;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

      h6 {
        font-size: 1.05rem;
        color: ${theme.palette.default.text};
      }

      svg {
        color: ${theme.palette.default.darkGrey};
      }
    }

    .footer {
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

      .footer-btns {
        justify-content: end;
        gap: 1.3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 1rem;
        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const SchenduleBoradcastWrapper = styled.div`
  // padding: 1rem;
  width: 100%;
  height: calc(100% - 64px);
`;

export const StyledResponsiveDivTable = styled.div`
  && {
    width: 100%;
    /* max-height: 430px; */

    .tableBody {
      max-height: 400px;
      overflow: overlay;
    }

    .MuiTablePagination-root {
      padding: 0 !important;
      overflow: hidden;
      height: 3rem;
      background-color: red;
      .MuiTablePagination-toolbar {
        padding: 0 !important;
      }
    }
    .MuiTablePagination-actions {
      svg {
        color: ${theme.palette.default.darkGrey} !important;
      }
    }
    .table {
    }
    .tableStickyHead {
      position: sticky;
      top: 0;
      z-index: 999;
      background-color: #fff !important;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
      width: fit-content;
      min-width: 100%;
    }

    .tablHeadRow {
      display: flex;
      flex: 1 !important;
      width: fit-content;
      background-color: #fff;
    }
    .tableHeadCell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-width: 220px;
      max-width: 300px;
      &:first-child {
        min-width: 50px;
      }
    }
    .tableHeadCell:first-child {
      width: 50px;
    }
    .tableBodyCell {
      min-width: 220px;
      max-width: 300px;
      word-break: break-all;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:first-child {
        min-width: 50px;
      }
    }
    .tableBodyRow {
      display: flex;
      width: fit-content;
      min-width: 100%;
      border-bottom: 1px solid ${theme.palette.default.border};
    }
    .activeRow {
      width: fit-content;
      background-color: ${theme.palette.action.selected};
    }
    .no-more-entries {
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-weight: 400;
      font-size: 1rem;
      display: flex;
      justify-content: center;
    }
  }
`;

export const PreviewNotAvailable = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 50%;
  gap: 1rem;
  transform: translateY(-50%);
  .not-found {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .not-found img {
    width: 90%;
  }
  h4 {
    font-weight: 400;
    font-size: 20px;
  }
`;

export const EditBroadcast = styled.div`
  .hedaer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid ${theme.palette.default.grey};

    .title {
      padding-left: 0.9rem;
    }

    .title h4 {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .close {
      padding-right: 0.9rem;
      display: flex;
      align-items: center;
    }

    .close svg {
      font-size: 1.4rem;
      font-weight: 500;
      color: ${theme.palette.default.darkGrey};
    }
  }

  .body {
    padding: 1rem;
    .form-wrap {
      label {
        font-weight: 500;
        font-size: 1.1rem;
        color: ${theme.palette.default.black};
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
    border-top: 1px solid ${theme.palette.default.grey};
    .save {
      padding-right: 0.9rem;
    }
  }
`;

export const SMSBoxTopbar = styled.div`
& {
    display: flex;
    width: calc(100% - 1px);
    margin-left: 1px;
    align-items: center;
    background-color: ${theme.palette.default.white};
    justify-content: space-between;
    padding: 1rem 1rem;
    height: 54px;
    border-bottom: 1px solid ${theme.palette.default.grey};
    position: sticky;
    top: 0;
    z-index: 1;
    .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p.heading {
            font-size: 16px;
            color: ${theme.palette.default.black};
            font-weight: 500;
        }
    }

    .test-tamplate__btn {
        display: flex;
        gap: 0.5rem;
        min-width: 287px;
        .back-btn {
            

        }
    }
}
`;

export const SMSBoxStepperbar = styled.div`
& {
    display: flex;
    width: calc(100% - 1px);
    margin-left: 1px;
    align-items: center;
    background-color: ${theme.palette.default.white};
    justify-content: space-between;
    padding: 1rem 1rem;
    height: 54px;
    position: sticky;
    top: 54px;
    z-index: 1;
    box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.3);
    .BtnsBox {
      display: flex;
      align-items: center;
      button {
        margin-left: 10px;
      }
    }
}
`
export const BreadcrumbsItem = styled.div`
& {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0px 10px;
    .icon {
        display: flex;
        align-items: center;
        img {
            
        }
        svg path {
            fill: ${(props: any) => (props.iconColorCondition ? `${theme.palette.primary.light}` : `${theme.palette.default.darkGrey}`)};
          }

    }
    .text {
    display: flex;
    align - items: center;
        p {
        font-size: 14px;
        margin: 0;
        color:  ${(props: any) => (props.iconColorCondition ? `${theme.palette.primary.light}` : `${theme.palette.default.darkGrey}`)
  }
}
}
`;