import React, { useEffect, useState, useMemo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { formatString } from "../../../../Utils";
import { CustomTabPanelContainer, WebsiteWrap } from "../BotKnowledgeStyles";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { BotKnowladgeOptions } from "../../utils/contants";

interface Props { }

const FaqIndex: React.FC<Props> = () => {
  const [value, setValue] = useState(0);

  const currentBot = useSelector(useCurrentBot);
  const navigate = useNavigate();
  const location = useLocation();

  const botKnowledgeOptionsMemo = useMemo(() => {
    return BotKnowladgeOptions.map((option: any) => ({
      ...option,
      url: formatString(option.url, [currentBot.bot_id]),
    }));
  }, [currentBot.bot_id, location]);

  const getCurrentTabAfterRefresh = () => {
    botKnowledgeOptionsMemo.forEach((item: any, index: number) => {
      if (location.pathname.includes(item.value)) {
        setValue(index);
      }
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(botKnowledgeOptionsMemo[newValue].url);
  };

  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [location]);

  return (
    <WebsiteWrap>
      <Box sx={{ width: "100%" }}>
        <Tabs
          className="website-tabs"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {botKnowledgeOptionsMemo.map((option: any, index: number) => (
            <Tab label={option.name} id={`simple-tab-${index}`} key={index} />
          ))}
        </Tabs>

        {botKnowledgeOptionsMemo.map((option: any, index: number) => (
          <CustomTabPanel value={value} index={index} key={index}>
            <Outlet />
          </CustomTabPanel>
        ))}
      </Box>
    </WebsiteWrap>
  );
};

export default FaqIndex;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <CustomTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </CustomTabPanelContainer>
  );
}
