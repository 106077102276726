import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { Select } from "@mui/material";

interface StyledProps {
  userMessage?: any;
  tooltipPosition?: any;
  isTooltipVisible?: any;
  WIDGET_POSITION?: any;
  variant?: any;
  enableuserinput?: number;
  chatopened?: any;
  devicewidth?: any;
  deviceheight?: any;
  jumpPosition?: any;
  theme?: any;
  selected?: any;
  isInvalid?: any;
  disabled?: boolean;
  ref?: any;
}
interface InputRef {
  ref?: any;
  innerRef?: any;
}

export const StyledErrorText = styled.p`
  && {
    // position: absolute;
    z-index: 999999;
    color: #f34e4e;
    font-size: 13px;
    line-height: 21px;
    background-color: aliceblue;
    top: -26px;
    min-height: 24px;
    left: 0px;
    padding-left: 6px;
    width: 100%;
    transition: all 0.5s linear 0s;
  }
`;

export const StyledAppInput = styled.input`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #fff;
    outline: none;
    border: 1px solid transparent;
    padding: 0 1.3rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 1px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 1px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    color: ${theme.palette.default.darkGrey};
    opacity: 0.7;
  }
`;

export const StyledWidgetInputBox = styled.div<StyledProps>`
  && {
    width: 100%;
    max-height: 44px;
    min-height: 44px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.08);
    background-color: ${({ theme }: any) =>
      theme?.components?.user_input.normal.background.color};
    pointer-events: ${(props: any) => (props.disabled ? "none" : "auto")};
    transition: all 0.5s linear 0s;
    .inputIconBox {
      background-color: ${({ theme }: any) =>
        theme?.components?.user_input.normal.background.color};
      background-color: transparent;
      min-width: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};

      .MuiSvgIcon-root {
        font-size: 1.3rem;
        color: ${({ theme }: any) =>
          theme?.components?.user_input.placeholder.text.color};
        color: ${({ theme }: any) =>
          theme?.components?.primary_color_scheme["500"]};
        color: ${(props: any) =>
          props.disabled
            ? theme?.palette?.default.darkGrey
            : props.theme?.components?.primary_color_scheme["500"]};
      }
    }

    .inputActionBox {
      background-color: ${({ theme }: any) =>
        theme?.components?.user_input.normal.background.color};
      background-color: transparent;
      min-width: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      overflow: hidden;

      cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};

      .MuiIconButton-root {
        border-radius: 8px;
        height: 32px;
        width: 34px;

        &:hover {
        }
      }

      .MuiSvgIcon-root {
        font-size: 1.6rem;
        color: ${({ theme }: any) => theme?.palette?.primary?.main};
        color: ${({ theme }: any) =>
          theme?.components?.primary_color_scheme["500"]};
        color: ${(props: any) =>
          props.disabled
            ? theme?.palette?.default.darkGrey
            : props.theme?.components?.primary_color_scheme["500"]};
      }
    }
  }
`;


export const StyledInputField = styled.input<InputRef>`
  && {
    background-color: ${({ theme }: any) =>
      theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    height: 42px;
    width: 100%;
    outline: none;
    border: none;
    padding: 0px 1rem 0px 1rem;
    font-family: ${({ theme }: any) =>
      theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::placeholder {
      color: ${({ theme }: any) =>
        theme?.components?.user_input.placeholder.text.color};
    }

    &:active {
      color: ${({ theme }: any) =>
        theme?.components?.user_input.focus.text.color};
    }
    &:focus {
      color: ${({ theme }: any) =>
        theme?.components?.user_input.focus.text.color};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
`;
export const StyledTextAreaField = styled.textarea`
  && {
    background-color: ${({ theme }: any) => {
      return theme?.components?.chat_wall.background.color;
    }};
    background-color: ${({ theme }: any) =>
      theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;
    outline: none;
    resize: none !important;
    padding: 1rem;
    border-radius: 8px;
    font-family: ${({ theme }: any) =>
      theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 1rem;
    line-height: 18px;

    &::placeholder {
      color: ${({ theme }: any) =>
        theme?.components?.user_input.placeholder.text.color};
    }

    &:active {
      border: 2px solid transparent;
      color: ${({ theme }: any) =>
        theme?.components?.user_input.focus.text.color};
    }
    &:focus {
      border: 2px solid transparent;
      color: ${({ theme }: any) =>
        theme?.components?.user_input.focus.text.color};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
`;
export const StyledSelectField = styled(Select)`
  && {
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
      min-height: 0px;
    }

    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 0 14px !important;
    }

    fieldset {
      border: none !important;
      outline: none !important;
      display: none !important;
    }
    legend {
      display: none !important;
    }
    input {
      display: none !important;
    }

    .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 0 !important;
      max-height: 3rem !important;
    }
    background-color: ${({ theme }: any) =>
      theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    height: 42px !important;
    max-height: 44px !important;
    width: 100%;
    outline: none;
    border: none;

    padding: 0px 3.1rem 0px 3rem;

    font-family: ${({ theme }: any) =>
      theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;

    &::placeholder {
      color: ${({ theme }: any) =>
        theme?.components?.user_input.placeholder.text.color} !important;
    }

    &:active {
      border: 2px solid transparent !important;
      color: ${({ theme }: any) =>
        theme?.components?.user_input.focus.text.color};
    }
    &:focus {
      border: 2px solid transparent !important;
      color: ${({ theme }: any) =>
        theme?.components?.user_input.focus.text.color};
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
`;

export const StyledMobileInput = styled(PhoneInput)`
  && {
    width: 100%;

    .special-label {
      display: none !important;
    }

    .form-control {
      background-color: ${({ theme }: any) =>
        theme?.components?.user_input.normal.background.color};

      display: flex;
      align-items: center;
      position: relative;
      height: 42px;
      width: 100%;
      outline: none;
      border: none;
      padding: 0px 3.1rem 0px 4rem;

      font-family: ${({ theme }: any) =>
        theme?.components?.user_input.normal.text.family};
      font-weight: 400;
      font-size: 1rem;
      line-height: 18px;
      border: 2px solid transparent;
      &::placeholder {
        color: ${({ theme }: any) =>
          theme?.components?.user_input.placeholder.text.color};
      }

      &:active {
        border: 2px solid transparent;
        color: ${({ theme }: any) =>
          theme?.components?.user_input.focus.text.color};
      }
      &:focus {
        border: 2px solid transparent;
        color: ${({ theme }: any) =>
          theme?.components?.user_input.focus.text.color};
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
      }
    }
    .invalid-number {
    }

    .flag-dropdown {
      width: 3.5rem;
      height: 40px;
      position: absolute;
      top: 2px;
      left: 2px;
      z-index: 113;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      outline: none;
      background-color: ${({ theme }: any) =>
        theme?.components?.user_input.normal.background.color};
      &:hover {
        background-color: ${({ theme }: any) =>
          theme?.components?.user_input.normal.background.color};
      }

      .selected-flag {
        background-color: ${({ theme }: any) =>
          theme?.components?.user_input.normal.background.color};
        &:hover {
          background-color: ${({ theme }: any) =>
            theme?.components?.user_input.normal.background.color};
        }
      }
    }

    .country-list {
      outline: none;
      z-index: 1;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 10px 0 3rem -1px;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
      background-color: #fafafa;
      width: 300px;
      max-height: 220px;
      overflow-y: scroll;
      border-radius: 6px;
      left: 10%;
      bottom: 0;
      overflow-x: hidden;
      z-index: 333333333;
      .search {
        padding: 10px 0 16px 10px;
        background-color: ${({ theme }: any) =>
          theme?.components?.user_input.normal.background.color};
      }

      .search-box {
        width: 95%;
      }
      /* -ms-overflow-style: none;
    scrollbar-width: none; */

      /* Hide scrollbar for Chrome, Safari and Opera */
      ::-webkit-scrollbar {
        /* display: none; */
        width: 4px;
      }
      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
`;
