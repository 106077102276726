// vendors
import { useContext, useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

// builder
import { NodeContext } from "./../../flow-builder-v2/contexts";

// components
import { UIButtonGroup, Icon, NodeType } from "../../ui-components";

// utils
import { getIcon } from "./utils";

type Props = {
  activeButton: number | null;
  buttonList: any;
  onButtonClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    buttonIndex: number,
  ) => void;
  onDelete: (activeButton: any) => void;
};

const EditableButton = (props: Props) => {
  const { activeButton, buttonList, onButtonClick, onDelete } = props;
  const nodeCntx = useContext(NodeContext);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [nActiveButton, updateActiveButton] = useState(null);

  useEffect(() => {
    if (activeButton !== null) {
      const button = document.getElementById(
        `button-${nodeCntx.id}-${activeButton}`,
      );

      if (button) {
        setTimeout(() => {
          button.click();
        }, 100);
      }
    }
  }, [activeButton]);

  const handleDeleteButtonClick = (button: any) => {
    updateActiveButton(button);
    setShowConfirmationPopup(true);
  };

  const handleModalClose = () => {
    setShowConfirmationPopup(false);
  };

  const renderButtons = () => {
    return buttonList?.map((button: any, index: number) => {
      const type = button?.value?.flow_type
        ? button?.value?.flow_type
        : button.type;
      const stage =
        button.type === "flow" && button?.value?.stage
          ? button?.value?.stage
          : "";
      const icon =
        button?.value?.stage || button?.value?.url ? getIcon(type) : undefined;

      return (
        <Box
          sx={{
            mt: index === 0 ? 0 : "5px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
          key={`button-${nodeCntx.id}-${index + 1}`}
        >
          <UIButtonGroup
            id={`button-${nodeCntx.id}-${index}`}
            fullWidth
            withOptions
            onClick={(event) => onButtonClick(event, index)}
            icon={icon}
          >
            {button.value.text}
            {stage && (
              <Typography
                component={"span"}
                sx={{
                  marginLeft: "10px",
                  fontStyle: "italic",
                  fontWeight: 500,
                  fontSize: "12px",
                  lineHeight: "20px",
                  backgroundColor: "rgb(117 124 232 / 38%)",
                  color: "#3654E3",
                  p: "1px 5px",
                  borderRadius: "4px",
                }}
              >
                {`Stage ${stage}`}
              </Typography>
            )}
          </UIButtonGroup>
          <IconButton
            sx={{
              height: "42px",
              width: "42px",
              border: "1px solid rgba(54, 84, 227, 0.5)",
              borderRadius: "4px",
            }}
            color="primary"
            size="large"
            onClick={() => handleDeleteButtonClick(button)}
          >
            <Icon icon="delete" size={16} />
          </IconButton>
        </Box>
      );
    });
  };

  return (
    <>
      {renderButtons()}
      <Modal
        className="ignore-onClickOutside"
        open={showConfirmationPopup}
        onClose={handleModalClose}
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              flexDirection="row"
              sx={{
                p: "16px",
                borderBottom: "1px solid #F5F6F8",
                width: "100%",
              }}
              justifyContent="space-between"
            >
              <NodeType text="Confirm" noPadding />
              <IconButton size="small" onClick={handleModalClose}>
                <CloseIcon sx={{ fontSize: "24px" }} />
              </IconButton>
            </Stack>
          </Box>
          <Box sx={{ px: "16px", py: "8px" }}>
            <Typography
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
              sx={{ color: "#7E8392", pb: "4px" }}
            >
              Do you want to delete the button?
            </Typography>
          </Box>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ py: "8px", px: "16px", borderTop: "1px solid #F5F6F8" }}
          >
            <Button variant="outlined" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                handleModalClose();
                onDelete && onDelete(nActiveButton);
              }}
            >
              Delete
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default EditableButton;
