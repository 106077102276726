import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactsNetworkService } from "./contactsNetworkService";

export class ContactsActions {
  // * Save Social Media Data
  static saveContactsSocialMedia = createAsyncThunk(
    "ContactSlice/saveContactsSocialMedia",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.saveContactsSocialMedia(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // * GET Contacts List
  static getContactsListData = createAsyncThunk(
    "ContactSlice/getContactsListData",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactsList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // // * GET Broadcast BY ID
  static getContactsSelectData = createAsyncThunk(
    "ContactSlice/getContactsSelectData",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactsSelectData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  static getAddContactsFields = createAsyncThunk(
    "ContactSlice/getAddContactsFields",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAddContactsFilds(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  static getEditContactsFilds = createAsyncThunk(
    "ContactSlice/getEditContactsFilds",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getEditContactsFilds(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  static getAddContactsDropDown = createAsyncThunk(
    "ContactSlice/getAddContactsDropDown",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAddContactsDropDown(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  static getContactsDropDownForUndependant = createAsyncThunk(
    "ContactSlice/getContactsDropDownForUndependant",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactsDropDownForUndependant(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  static getLifeCycleContactsDropDown = createAsyncThunk(
    "ContactSlice/getLifeCycleContactsDropDown",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getLifeCycleStage(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // getContact by id
  static getContactsByID = createAsyncThunk(
    "ContactSlice/getContactsByID",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactsByID(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // edit Contact by id
  static editContactsByID = createAsyncThunk(
    "ContactSlice/editContactsByID",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.editContactsByID(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // getContact tags list
  static getContactsTagsList = createAsyncThunk(
    "ContactSlice/getContactsTagsList",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactsTagsList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // add tags list
  static addTagsList = createAsyncThunk(
    "ContactSlice/addTagsList",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.addTagsList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // getContact tags list
  static getAddToList = createAsyncThunk(
    "ContactSlice/getAddToList",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAddToList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // add tags list
  static addToList = createAsyncThunk(
    "ContactSlice/addToList",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.addToList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // get Assignee  list
  static getAssigneeList = createAsyncThunk(
    "ContactSlice/getAssigneeList",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAssigneeList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // add tags list
  static addAssignee = createAsyncThunk(
    "ContactSlice/addAssignee",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.addAssignee(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // add tags list
  static bulkUpdateContact = createAsyncThunk(
    "ContactSlice/bulkUpdateContact",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.bulkUpdateContact(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // get bulkupdate field list tags list
  static getBulkUpdateFields = createAsyncThunk(
    "ContactSlice/getBulkUpdateFields",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getBulkUpdateFields(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // get bulkupdate field list tags list
  static deleteBulkContact = createAsyncThunk(
    "ContactSlice/deleteBulkContact",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.deleteBulkContact(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // // * GET contact grid view
  static getContactGridView = createAsyncThunk(
    "ContactSlice/getContactGridView",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactGridView(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // // * update contact grid view status
  static ContactGridViewUpdate = createAsyncThunk(
    "ContactSlice/ContactGridViewUpdate",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.ContactGridViewUpdate(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // // * update contact grid view status
  static GetMoreContacts = createAsyncThunk(
    "ContactSlice/GetMoreContacts",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.GetMoreContacts(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // kanban delete
  static DeletContacts = createAsyncThunk(
    "ContactSlice/deleteKanbanContacts",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.deleteKanbanContacts(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // get bulkupdate field list tags list
  static bulkExport = createAsyncThunk(
    "ContactSlice/bulkExport",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.bulkExport(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // // * get list
  static GetExportedContacts = createAsyncThunk(
    "ContactSlice/GetExportedContacts",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.GetExportedContacts(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // // * get data
  static DownloadExportedContacts = createAsyncThunk(
    "ContactSlice/DownloadExportedContacts",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.DownloadExportedContacts(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // get basic info
  static getBasicInfo = createAsyncThunk(
    "ContactSlice/getBasicInfo",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getBasicInfo(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // get basic info
  static getCommunication = createAsyncThunk(
    "ContactSlice/getCommunication",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getCommunication(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // get SourceDetails
  static getSourceDetails = createAsyncThunk(
    "ContactSlice/getSourceDetails",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getSourceDetails(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  //getMarketingInformation
  static getMarketingInformation = createAsyncThunk(
    "ContactSlice/getMarketingInformation",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getMarketingInformation(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  //getActivities
  static getActivities = createAsyncThunk(
    "ContactSlice/getActivities",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getActivities(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // getFiles
  static getFiles = createAsyncThunk(
    "ContactSlice/getFiles",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getFiles(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // getChatTranscript
  static getChatTranscript = createAsyncThunk(
    "ContactSlice/getChatTranscript",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getChatTranscript(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Add Note As Per Id
  static addNoteAsPerId = createAsyncThunk(
    "ContactSlice/getChatTranscript",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.addNoteAsPerId(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // Get Contact Section Detail
  static getSectionDetails = createAsyncThunk(
    "ContactSlice/getSectionDetails",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getSectionDetails(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Get Contact Section Fields
  static getSectionFields = createAsyncThunk(
    "ContactSlice/getSectionFields",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getSectionFields(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // getContact tags list by contact id
  static getContactsTagsListByID = createAsyncThunk(
    "ContactSlice/getContactsTagsListByID",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactsTagsListByID(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // getFiles
  static addFiles = createAsyncThunk(
    "ContactSlice/addFiles",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.addFiles(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // getNotes
  static getAllNotes = createAsyncThunk(
    "ContactSlice/getAllNotes",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAllNotes(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // updateNotes
  static updateNote = createAsyncThunk(
    "ContactSlice/updateNote",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.updateNote(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // deleteNotes
  static deleteNote = createAsyncThunk(
    "ContactSlice/deleteNote",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.deleteNote(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // download File
  static downloadFile = createAsyncThunk(
    "ContactSlice/downloadFile",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.downloadFile(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // delete File
  static deleteFile = createAsyncThunk(
    "ContactSlice/deleteFile",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.deleteFile(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Get marketing status option
  static getMarketingStatusOption = createAsyncThunk(
    "ContactSlice/getMarketingStatusOption",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getMarketingStatusOption(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  //  API to fetch Leads Table Columns
  static getLeadsTableColumn = createAsyncThunk(
    "ContactSlice/getLeadsTableColumn",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getLeadsTableColumnNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // getContact tags list by contact id
  static leadsForContact = createAsyncThunk(
    "ContactSlice/leadsForContact",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.leadsForContactNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // getAllContactsViews
  static getAllContactsViews = createAsyncThunk(
    "ContactSlice/getAllContactsViews",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAllContactsViews(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // // * Field History
  static fieldHistoryAction = createAsyncThunk(
    "ContactSlice/fieldHistoryAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.filedHistoryNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // // * Contact Fields
  static getContactsSelectDataAction = createAsyncThunk(
    "ContactSlice/getContactsSelectDataAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContactsSelectData(data)
        .then(async (response: any) => {
          let properties = response.data.data;
          if (response.data.data.length > 0) {
            properties = JSON.parse(
              JSON.stringify(properties).split('"name":').join('"value":'),
            );
          }
          return properties;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Contact Task 
  // Contact Related To ( Auto Complete )
  static getContectList = createAsyncThunk(
    "ContactSlice/getContectList",
    (name: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getContectList(name)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Contact Task Details Options
  static getContactTaskDetailsOptions = createAsyncThunk(
    "ContactSlice/getTaskDetailsOptions",
    (name: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getTaskDetailsOptions(name)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Create Task
  static createTask = createAsyncThunk(
    "ContactSlice/createTask",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.createTask(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Get All Task 
  static getAllTask = createAsyncThunk(
    "ContactSlice/getAllTask",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAllTask(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );

  // Get Single Task 
  static getSingleTask = createAsyncThunk(
    "ContactSlice/getSingleTask",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getSingleTask(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // update Task
  static updateTask = createAsyncThunk(
    "ContactSlice/updateTask",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.updateTask(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // Get All Task 
  static getAllTaskByContactId = createAsyncThunk(
    "ContactSlice/getAllTaskByContactId",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.getAllTaskByContactId(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // Delete Task 
  static deleteTaskByIds = createAsyncThunk(
    "ContactSlice/deleteTaskByIds",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.deleteTaskByIds(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // Delete outcome 
  static deleteOutcomeById = createAsyncThunk(
    "ContactSlice/deleteOutcomeById",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.deleteOutcomeById(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
  // Set outcome 
  static setOutcome = createAsyncThunk(
    "ContactSlice/setOutcome",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactsNetworkService.setOutcome(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    },
  );
}
