
import { Badge, Box, Divider, IconButton, Typography, debounce } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import AppDrawer from "../../AppComponents/AppDrawer";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../AppComponents/Forms";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ApplicationFormNetworkActions } from "../../Store/Slices/ApplicationsForm/applicationForm.actions";
import { getFilterQuery, getapplicationFormList } from "../../Store/Slices/ApplicationsForm/applicationForm.selectors";
import { setFilterQuery } from "../../Store/Slices/ApplicationsForm/applicationForm.slice";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import { ReactComponent as NavigateIcon } from "../../assets/images/navigate.svg";
import norecord from "../../assets/images/noDatafound.svg";
import {
    FirstNameColumn,
    IdCoulumns,
} from "../Leads/contactDetailedComponents/ContactConsts";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import { ApplicationFormWrapper, QueryBuilderWrapper } from "./ApplicationFormStyles";
import ApplicationsListTable from "./ApplicationsListTable";
import CustomizedMenus from "./CustomizeMenus";
import QueryBuilderForApplication from "./QueryBuilderForApplicationForm";
import { convertQueryToMongo } from "../../AppComponents/QueryBuilder/utils/utils";

interface Props extends LoaderComponentProps { }
const ApplicationFormsListing: React.FC<Props> = ({ setLoading }) => {
    //roles & permissions
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    // drawer
    const toggleDrawer = () => {
        setIsDrawerOpened(!isDrawerOpened);
    };
    // hooks
    const { showAlert } = useNotification();
    const dispatch: any = useAppDispatch();
    const navigate = useNavigate();
    // select row
    const [selectAll, setSelectAll] = useState<boolean>(false);
    // redux data
    const { allApplicationsListData, initialSelectValues, resetFilterQuery, pagination } = useAppSelector(getapplicationFormList);
    // states
    const [applicationFormHeader, setApplicationFormHeaderMapping] = useState<any>([]);
    const [skeltonLodaer, setSkeltonLodaer] = useState<boolean>(true);
    const [dynamicsColumn, setDynamicsColumn] = useState<any>([]);
    // New Drawer
    const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);
    const filterQuery = useAppSelector(getFilterQuery);
    const [localValues, setLocalValues] = useState<any>({
        itemsPerPage: { label: "25  per page", value: 25 },
        currentPage: 0,
        query: "",
        checkbox: [],
        checkboxAll: false,
        search: "",
    });
    const [selectedFields, setSelectedFields] = useState<any>(initialSelectValues);

    useEffect(() => {
        let payload: any = {
            data: "",
        };
        dispatch(ContactsActions.getContactsSelectData(payload))
            .then()
            .catch((error: any) => {
                showAlert(
                    error?.response?.statusText || "Enabled to fetch Data",
                    "error"
                );
            })
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    // getSelected headers
    useEffect(() => {
        if (initialSelectValues?.length && !applicationFormHeader?.length) {
            let tempArray: any = [];
            const updatedContactHeader = initialSelectValues?.map(
                (item: { label: any; name: string }) => {
                    tempArray.push(item.label);
                    let allColumn = {
                        label: item.label,
                        id: item.name,
                        icon: null,
                        size: 250,
                    };
                    if (item.name === "first_name") {
                        return [FirstNameColumn];
                    } else {
                        return [allColumn];
                    }
                }
            ).flat();
            setApplicationFormHeaderMapping([IdCoulumns, ...updatedContactHeader]);
        }
    }, [initialSelectValues]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (initialSelectValues?.length) {
            setDynamicsColumn(initialSelectValues);
            setSelectedFields(initialSelectValues);
        }
    }, [initialSelectValues]);

    // get list data 
    const getApplicationsFormsTableData = (fields?: any[]) => {
        setSkeltonLodaer(true);
        setLoading(true);
        let payload: any = {
            page_size: localValues.itemsPerPage?.value,
            page_number: localValues.currentPage,
            search: localValues.search,
            filter_query: localValues?.filter_query,
            mongodb_query: localValues?.mongoQuery
        };
        if (fields) {
            payload.columns = fields?.map((data: any) => {
                return data.name;
            });
        } else {
            payload.columns = dynamicsColumn?.map((data: any) => {
                return data.name;
            });
        }
        dispatch(ApplicationFormNetworkActions.getApplicationFormsList(payload))
            .then((res: any) => {
                setSkeltonLodaer(false);
                setLoading(false);
            }).catch((error: any) => {
                console.error("Applications not found please try again!");
            })
            .finally(() => {
                setLoading(false);
                setSkeltonLodaer(false);
            });
    };

    useEffect(() => {
        getApplicationsFormsTableData();
    }, [localValues]); //   eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        type Module = "contact" | "application" | "payment";
        const modules: Module[] = ["contact", "application", "payment"]
        dispatch(ApplicationFormNetworkActions.getFormProperties({
            modules: modules
        }));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (selectAll) {
            const allDocumentIds = allApplicationsListData?.map((item: any) => item.id);
            setSelectedDocumentIds(allDocumentIds);
        }
    }, [allApplicationsListData, selectAll]); // eslint-disable-line react-hooks/exhaustive-deps

    // Handle Pagination Functions
    const handlePageChange = (event: any, value: any) => {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    };
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 0,
        });
    };

    // reset all select initial
    useEffect(() => {
        if (
            (selectedDocumentIds && selectedDocumentIds?.length === 0) ||
            (selectedDocumentIds?.length > 0 &&
                selectedDocumentIds?.length !== allApplicationsListData?.length &&
                selectAll)
        ) {
            setSelectAll(false);
        }
    }, [selectedDocumentIds]); //eslint-disable-line react-hooks/exhaustive-deps

    // handling search
    const handleSelectSearch = debounce((e?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            search: e,
            currentPage: 0,
        });
    }, 800);

    // clear search
    const clearCallback = (e?: any, field?: any) => {
        if (e === "search" || e === "") {
            setLocalValues({
                ...localValues,
                search: "",
            });
        }
    };
    // export applications 
    const handleExport = () => {
        const isFiltersApplied = filterQuery?.rules?.length > 0 ?? false;
        const mongodb_query = convertQueryToMongo(filterQuery);
        let payload: any = {
            selectAll: selectedDocumentIds?.length === 0 ? true : selectAll,
            applicationId_list: selectAll ? [] : selectedDocumentIds,
            search: localValues?.search ?? "",
            columns: dynamicsColumn?.map((data: any) => data?.name)
        };

        if (isFiltersApplied) {
            payload = {
                ...payload,
                filter_query: filterQuery,
                mongodb_query: mongodb_query,
            };
        }
        dispatch(ApplicationFormNetworkActions.bulkApplicationsExport(payload))
            .then((res: any) => {
                if (res?.payload?.data?.status) {
                    showAlert(res?.payload?.data?.msg || "Applications will be exported shortly. Exported Data can be downloaded from Settings --> Data Management --> Export History.", "success");
                }
            }).catch((error: any) => {
                showAlert("Applications not exported please try again!", "error");
            })
    }
    return (
        <ApplicationFormWrapper>
            <div className="topmenu">
                <div className="left-part">
                    <div
                        className="icon"
                        onClick={() => navigate("/")}
                    >
                        <IconButton>
                            <NavigateIcon />
                        </IconButton>
                    </div>
                    <div className="menu-left">Applications</div>
                </div>
                <div className="menu-right">
                    <AppForm
                        initialValues={{
                            search: localValues?.search,
                        }}
                        divStyle={{ width: "100%" }}
                        onSubmit={(values: any, submitProps: any) => { }}
                    >
                        <AppFormSearchField
                            name="search"
                            onChange={(e: any) => handleSelectSearch(e, "search")}
                            clearCallback={(e: any) => clearCallback(e, "search")}
                            placeholder="Search"
                            divStyle={{
                                marginBottom: "0px",
                            }}
                            defaultBorder={true}
                        />
                    </AppForm>

                    <div className="button">
                        {allApplicationsListData &&
                            applicationFormHeader &&
                            allApplicationsListData?.length &&
                            dynamicsColumn?.length > 0 ? (
                            <CustomizedMenus
                                availableFields={initialSelectValues}
                                selectedFields={selectedFields}
                                setSelectedFields={setSelectedFields}
                                dynamicsColumn={selectedFields}
                                setDynamicsColumn={setSelectedFields}
                                updateContactList={getApplicationsFormsTableData}
                            />
                        ) : null}
                    </div>

                    <AppButton
                        variant="outline"
                        onClick={() => {
                            toggleDrawer();
                        }}
                        className="btn-grp"
                    >
                        <Badge
                            variant={filterQuery?.rules?.length > 0 ? "dot" : "standard"}
                            max={5}
                            color="primary"
                            showZero={false}
                            className="btn-grp"
                        >
                            <AppMaterialIcons
                                iconName="FilterList"
                                style={{
                                    fontSize: "18px",
                                    color: theme.palette.primary.dark,
                                }}
                            />
                        </Badge>
                    </AppButton>

                    <AppButton
                        onClick={handleExport}
                        variant="outline"
                        style={{ width: "170px" }}
                    >
                        Export Data
                    </AppButton>

                    <AppButton variant="outline" className="btn-grp" onClick={() => getApplicationsFormsTableData([])} isDisabled={allApplicationsListData?.length === 0}>
                        <AppToolTip
                            tooltipProps={{
                                title: "Refresh applications list.",
                                placement: "top",
                            }}
                            iconButtonProps={{ disableRipple: true }}
                        >
                            <AppMaterialIcons
                                style={{ cursor: "pointer", color: theme.palette.primary.dark }}
                                iconName="Refresh"
                            />
                        </AppToolTip>
                    </AppButton>
                </div>
                {/* query builder  filter */}
                <AppDrawer
                    open={isDrawerOpened}
                    onClose={() => undefined}
                    sx={{
                        "& .MuiPaper-root": {
                            width: "65%",
                            minWidth: "950px",
                            "@media (max-width: 1199.98px)": {
                                minWidth: "100% !important",
                            },
                        },
                    }}
                >
                    <QueryBuilderWrapper style={{ height: "100%" }}>
                        <Box className="form-heading">
                            <Box>
                                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                    Apply
                                </Typography>
                            </Box>
                            <Box>
                                <IconButton
                                    onClick={() => {
                                        toggleDrawer();
                                        dispatch(setFilterQuery(resetFilterQuery));
                                    }}
                                >
                                    <AppMaterialIcons iconName={"Close"} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Divider />
                        <QueryBuilderForApplication
                            setLocalValues={setLocalValues}
                            toggleDrawer={toggleDrawer}
                        />
                    </QueryBuilderWrapper>
                </AppDrawer>

            </div>

            {allApplicationsListData &&
                dynamicsColumn.length > 0 &&
                allApplicationsListData.length > 0 ? (
                <ApplicationsListTable
                    loader={skeltonLodaer}
                    dynamicsColumn={dynamicsColumn}
                    data={allApplicationsListData}
                    contactHeaderMapping={applicationFormHeader}
                    setLocalValues={setLocalValues}
                    localValues={localValues}
                    setSelectedDocumentIds={setSelectedDocumentIds}
                    selectedDocumentIds={selectedDocumentIds}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    hidePagination={true}
                    removeApplicationsFormsLink={true}
                    hasUpdateAceess={"global_access"}
                    totalCount={pagination?.totalRecords}
                    sortingApply={true}
                    getApplicationsFormsTableData={getApplicationsFormsTableData}
                />
            ) : (
                // no record found
                <React.Fragment>
                    {pagination?.totalPages === 0 ? (
                        <div
                            className="data-details"
                            style={{ height: "calc(100vh - 200px)" }}
                        >
                            <div className="norecord">
                                <img src={norecord} alt="img" />
                                <p>No Applications found</p>
                            </div>
                        </div>
                    ) : null}
                </React.Fragment>
            )}
            {/* pagination  */}
            {allApplicationsListData && allApplicationsListData?.length ? (
                <AppPaginationComponent
                    totalCount={pagination?.totalRecords}
                    handlePageChange={handlePageChange}
                    currentPage={localValues?.currentPage}
                    totalPages={pagination?.totalPages}
                    rowsPerPage={localValues?.itemsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    title="Applications Forms"
                />
            ) : null}
        </ApplicationFormWrapper>
    );
};
export default withLoader(ApplicationFormsListing);
