import React from 'react'
import styled from 'styled-components';
import { theme } from '../../../Customization/Theme';
import { AppToolTip } from '../../UiComponents/AppToolTip/AppToolTip';
import { AppMaterialIcons } from '../../../AppComponents/AppMaterialIcons';

interface FieldLabelProps {
    label: string | undefined;
    isTooltipAvaliable?: boolean;
    tooltipText?: string;
}
const FieldLabel = ({ label, isTooltipAvaliable, tooltipText }: FieldLabelProps) => {
    return (
        <Wrapper>
            <Label>{label}</Label>
            {
                isTooltipAvaliable &&
                <AppToolTip
                    tooltipProps={{
                        title: tooltipText,
                        placement: "top",
                    }}
                >
                    <AppMaterialIcons iconName="InfoOutlined" style={{ fontSize: "18px" }} />
                </AppToolTip >
            }
        </Wrapper>
    )
}
const Wrapper = styled.div`
&&{
    display: flex;
    align-items: center;
    gap:10px;
}`
const Label = styled.p`
&{
    color: ${theme.palette.default.midGrey};
}`
export default FieldLabel;