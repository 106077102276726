// vendors
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

export const MediaPlaceholderStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  clipPath: "inset(0 round 2px 2px 2px 2px)",
  height: "206px",
  marginBottom: "16px",

  "&::before": {
    content: '""',
    position: "absolute",
    left: "-7px",
    top: "-6px",
    right: "-6px",
    bottom: "-6px",
    border: "8px dashed #CBCDD3",
    boxSizing: "border-box",
  },
}));

export const InfoCardStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "16px",
  top: "16px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #CBCDD3",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  padding: "11px 14px",
}));

export const DocumentUploadedSectionStyled = styled(Box)(({ theme }) => ({
  width: "100%",
  alignItems: "center",
  justifyContent: "flex-end",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  clipPath: "inset(0 round 2px 2px 2px 2px)",
  height: "106px",
  marginBottom: "16px",
  "&::before": {
    content: '""',
    position: "absolute",
    left: "-7px",
    top: "-6px",
    right: "-6px",
    bottom: "-6px",
    border: "8px dashed #CBCDD3",
    boxSizing: "border-box",
  },
}));

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #CBCDD3",
  boxShadow: "box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08)",
  padding: "12px",
  "&:hover": { 
    backgroundColor: "#FFFFFF" 
  },
}));
