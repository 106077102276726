const PreviewText = (props: any) => {
    return (
        <>
            <div
                {...props}
                className="bodyMsgContainer"
                dangerouslySetInnerHTML={{
                    __html: props?.formattedValue || "",
                }}
            />
        </>
    )
}
export default PreviewText