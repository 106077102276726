import { createAsyncThunk } from "@reduxjs/toolkit";
import { SourceNetworkService } from "./SourceNetworkService";

export class FaqSourceAction {
  // create website Crawl URL
  static CreateWebsiteCrawlUrl = createAsyncThunk(
    "FaqSourceSlice/CreateCrawlUrl",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.postWebsiteCrawUrl(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // create site map Crawl URL
  static CreateSiteMapCrawlUrl = createAsyncThunk(
    "FaqSourceSlice/CreateSiteMapCrawlUrl",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.postSiteMapCrawUrl(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //  get website Crawl URL list
  static GetWebsitesCrawlUrls = createAsyncThunk(
    "FaqSourceSlice/ShowCrawlLists",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.getWebsiteCrawUrls(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // get sitemap Crawl URL list
  static GetSiteMapCrawlUrls = createAsyncThunk(
    "FaqSourceSlice/GetSiteMapCrawlUrls",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.getSiteMapCrawUrls(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // send suburl id in every check
  static AddSubUrl = createAsyncThunk(
    "FaqSourceSlice/AddSubUrl",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.putSublinks(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // scrapLink onSubmit
  static PostSelectedSubUrl = createAsyncThunk(
    "FaqSourceSlice/PostSelectedSubUrl",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.postSelectedUrls(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Source Type Text
  // Create Message
  static CreateSourceText = createAsyncThunk(
    "LiveChatSlice/PostSourceText",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.postSourceText(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Listing Of Source
  static GeteSourceListing = createAsyncThunk(
    "FaqSourceSlice/GetSourceListing",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.getSourceListing(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Get Source Text By its ID
  static GetSourceTextById = createAsyncThunk(
    "FaqSourceSlice/GetSourceTextById",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.getSourceTextbyId(data);
    }
  );

  // add specific suburls for  website and sitemap
  static addOneLink = createAsyncThunk(
    "FaqSourceSlice/addOneLink",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.addOneUrlLink(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Update Message By ID
  static UpdateSourceTextById = createAsyncThunk(
    "FaqSourceSlice/UpdateSourceTextById",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.updateSourceTextById(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );

  // Delete Source By ID's
  static DeleteSourceByIds = createAsyncThunk(
    "FaqSourceSlice/DeleteSourceByIds",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.deleteSourceById(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );

  //* UPLOAD File  //
  static UploadFaqDocument = createAsyncThunk(
    "FaqSourceSlice/uploadFaqDocument",
    (data: any, { dispatch, rejectWithValue }) => {
      return SourceNetworkService.uploadFaqDocumentFile(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
}
