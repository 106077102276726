import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactListNetworkService } from "./ContactListNetworkService";

export class contactListActions {
  // * get contact properties field options *//
  static getContactPropertiesFieldOptions = createAsyncThunk(
    "ContactListSlice/getContactPropertiesFieldOptions",
    (id: string, { dispatch, rejectWithValue }) => {
      return ContactListNetworkService.getContactPropertiesFieldOptions(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  static getAllVisibleContactPropertiesFieldOptions = createAsyncThunk(
    "ContactListSlice/getAllContactPropertiesFieldOptions",
    (id: string, { dispatch, rejectWithValue }) => {
      return ContactListNetworkService.getAllVisibleContactPropertiesFieldOptions()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
    });
  // get contact properties fileds

  static getContactPropertiesList = createAsyncThunk(
    "ContactListSlice/getContactPropertiesList",
    (dispatch, rejectWithValue) => {
      return ContactListNetworkService.getContactPropertiesList({})
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );


}
