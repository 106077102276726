// components
import Icon from "../../../Components/Icon";

// styles
import { RightArrowButtonStyled } from "./../Carousel.styled";

function NextArrow(props: any) {
  const {
    className,
    style,
    onClick,
    currentSlide,
    slideCount,
    active,
    onAddClick,
  } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick && onClick(event);
  };

  return (
    <RightArrowButtonStyled
      className={`${className} ignore-onClickOutside`}
      onClick={
        currentSlide + 1 !== slideCount
          ? handleClick
          : active
          ? onAddClick
          : handleClick
      }
      style={{ ...style }}
      sx={{
        "& svg": {
          transform: "rotate(180deg)",
        },
      }}
    >
      {currentSlide + 1 === slideCount && active ? (
        <Icon icon="add" size={12} />
      ) : (
        <Icon icon="chevron-left" size={12} />
      )}
    </RightArrowButtonStyled>
  );
};
export default NextArrow;
