import { createSlice } from "@reduxjs/toolkit";
import { ApplicationFormNetworkActions } from "./applicationForm.actions";
interface initialStateTypes {
    data: {
        allApplicationsListData: any;
        initialSelectValues: any;
        pagination: any;
        filterQuery: any;
        resetFilterQuery: any;
        allProperties: any;
        attachmentData: any;
        paymentHistoryData: any[];
    };
    queryBuilderData: {
        applicationsFieldsMapping: any;
        applicationsOperators: any;
        loading:boolean;
    }
    error: string;
    loading: boolean;
}

const initialState: initialStateTypes = {
    data: {
        allApplicationsListData: null,
        initialSelectValues: null,
        pagination: {},
        filterQuery: {},
        resetFilterQuery: {},
        allProperties: [],
        attachmentData: null,
        paymentHistoryData:[],
    },
    queryBuilderData: {
        applicationsFieldsMapping: [],
        applicationsOperators: [],
        loading: false,
    },
    error: "",
    loading: false,
};

const formApplicationsSlice = createSlice({
    name: "formApplicationsSlice",
    initialState,
    reducers: {
        setFilterQuery(state, { payload }: any) {
            state.data.filterQuery = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            //* all Marketing List
            .addCase(
                ApplicationFormNetworkActions.getApplicationFormsList.pending,
                (state, action) => {
                    state.error = "";
                    state.loading = true;
                }
            )
            .addCase(
                ApplicationFormNetworkActions.getApplicationFormsList.fulfilled,
                (state, action) => {
                    state.data.allApplicationsListData = action?.payload?.data?.data?.data;
                    state.data.pagination = action.payload.data?.data?.pagination;
                    state.data.initialSelectValues = action.payload.data?.data?.columns;
                    state.data.filterQuery = action.payload.data?.data?.filter_query;
                    state.data.resetFilterQuery = action.payload.data?.data?.filter_query;
                    state.error = "";
                    state.loading = false;
                }
            )

            .addCase(
                ApplicationFormNetworkActions.getApplicationFormsList.rejected,
                (state, action) => {
                    state.data.allApplicationsListData = {};
                    state.data.initialSelectValues = []
                    state.error = "";
                    state.loading = false;
                }
            )
            //get properties
            .addCase(
                ApplicationFormNetworkActions.getFormProperties.pending,
                (state, action) => {
                    state.error = "";
                    state.loading = true;
                }
            )
            .addCase(
                ApplicationFormNetworkActions.getFormProperties.fulfilled,
                (state, action) => {
                    state.data.allProperties = action?.payload?.data?.data;
                    state.error = "";
                    state.loading = false;
                }
            )
            .addCase(
                ApplicationFormNetworkActions.getFormProperties.rejected,
                (state, action) => {
                    state.data.allProperties = {};
                    state.error = "";
                    state.loading = false;
                }
            )
            //get Applications fields listig
            .addCase(
                ApplicationFormNetworkActions.getApplicationFieldsMapping.pending,
                (state, action) => {
                    state.error = "";
                    state.queryBuilderData.loading = true;
                }
            )
            .addCase(
                ApplicationFormNetworkActions.getApplicationFieldsMapping.fulfilled,
                (state, action) => {
                    state.queryBuilderData.applicationsFieldsMapping = action?.payload?.data?.mappingFields;
                    state.queryBuilderData.applicationsOperators = action?.payload?.data?.response?.type_mapping_data ?? null;
                    state.error = "";
                    state.queryBuilderData.loading = false;
                }
            )
            .addCase(
                ApplicationFormNetworkActions.getApplicationFieldsMapping.rejected,
                (state, action) => {
                    state.queryBuilderData.applicationsFieldsMapping = [];
                    state.queryBuilderData.applicationsOperators = []
                    state.error = "";
                    state.queryBuilderData.loading = false;
                }
            )

            // Attachment 
            .addCase(ApplicationFormNetworkActions.getAttachmentData.pending, (state, action) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(ApplicationFormNetworkActions.getAttachmentData.fulfilled, (state, action) => {
                state.loading = false;
                state.data.attachmentData = action?.payload?.data?.data?.attachments;
            })
            .addCase(ApplicationFormNetworkActions.getAttachmentData.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message || "";
            })
            
            // Payment Details
            .addCase(ApplicationFormNetworkActions.getPaymentDetails.pending, (state, action) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(ApplicationFormNetworkActions.getPaymentDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.data.paymentHistoryData = action?.payload?.data?.data;
            })
            .addCase(ApplicationFormNetworkActions.getPaymentDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message || "";
            })
            
            // CHange Status To Draft
            .addCase(ApplicationFormNetworkActions.changeStatusToDraft.pending, (state, action) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(ApplicationFormNetworkActions.changeStatusToDraft.fulfilled, (state, action) => {
                state.loading = false;
                state.data.paymentHistoryData = action?.payload?.data?.data;
            })
            .addCase(ApplicationFormNetworkActions.changeStatusToDraft.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.error?.message || "";
            });
    },
});

export default formApplicationsSlice.reducer;

export const { setFilterQuery } = formApplicationsSlice.actions;
