import { domainError, domainVerifyError } from './email.selectors';
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmailActions } from "./email.actions";
// import { broadcastActions } from "./broadcast.actions";
interface initialStateTypes {
  data: {
    activeSteps: number;
    htmlData: any;
    editHtml: any;
    NewData: number
    emailVerifiedStatus: any;
    filteredData: any,
    filteredDataByID: any,
    allTemplatesData: any,
    emailTemplateListData: any,
    emailTemplateLists: any,
    allGelleryTemplatesData: any
    validEmailList: any
    filteredEmail: any
    emailObject: any
    filteredTemplateByID: any
    postEmailBroadcastData: any
    broadcastByIdData: any
    filteredDataTotalCount: number
    footerListData: any,
    formatFooterData: any;
    domainError: string;
    domainVerifyError: string;
    emailError: string;
    deleteError: string;
    deleteSuccess: string;
    testEmailModel: boolean;
    isDomainDeleted: boolean;
    templateDetailsByID: any;
    DateRange: any;
    emailEnable: boolean;
    checkTemplateExist: boolean;
    isCountinued?: boolean;
  };
  error: string;
  loading: boolean;

}

const initialState: initialStateTypes = {
  data: {
    activeSteps: 1,
    htmlData: null,
    editHtml: null,
    NewData: 0,
    emailVerifiedStatus: null,
    filteredData: null,
    filteredDataByID: null,
    allTemplatesData: null,
    emailTemplateListData: [],
    emailTemplateLists: [],
    allGelleryTemplatesData: null,
    validEmailList: null,
    filteredEmail: [],
    emailObject: {},
    filteredTemplateByID: null,
    postEmailBroadcastData: null,
    broadcastByIdData: null,
    filteredDataTotalCount: 0,
    footerListData: null,
    formatFooterData: [],
    domainError: "",
    domainVerifyError: "",
    emailError: "",
    deleteError: "",
    deleteSuccess: "",
    testEmailModel: false,
    isDomainDeleted: false,
    templateDetailsByID: null,
    DateRange: {},
    emailEnable: false,
    checkTemplateExist: false,
    isCountinued: false
  },
  error: "",
  loading: false,
};

const EmailSlice = createSlice({
  name: "EmailSlice",
  initialState,
  reducers: {
    resetDelete: (state: any, action?: PayloadAction<any>) => {
      state.data.isDeleted = false;
      return state;
    },
    isEmailEnabled: (state: any, action: PayloadAction<any>) => {
      state.data.emailEnable = action.payload;
      return state;
    },
    verifyActiveStep: (state: any, action: PayloadAction<number>) => {
      state.data.activeSteps = action.payload;

      return state;
    },
    htmlTemplateData: (state: any, action: PayloadAction<any>) => {
      state.data.htmlData = action.payload;
      return state;
    },
    editedHtmlData: (state: any, action: PayloadAction<any>) => {
      state.data.editHtml = action.payload;
      return state;
    },
    testEmailTemplateData: (state: any, action: PayloadAction<any>) => {
      state.data.testEmailModel = action.payload;
      return state;
    },
    resetEmailData: (state: any, action: PayloadAction<any>) => {
      state.data = {
        activeSteps: 1,
        htmlData: null,
        editHtml: null,
        NewData: 0,
        emailVerifiedStatus: null,
        filteredData: null,
        filteredDataByID: null,
        allTemplatesData: null,
        allGelleryTemplatesData: null,
        validEmailList: null,
        filteredEmail: [],
        emailObject: {},
        filteredTemplateByID: null,
        postEmailBroadcastData: null,
        broadcastByIdData: null,
        filteredDataTotalCount: 0,
        footerListData: null,
        formatFooterData: [],
        domainError: "",
        domainVerifyError: "",
        emailError: "",
        testEmailModel: false
      }
    },
    resetError: (state: any, action: PayloadAction<any>) => {
      state.data.domainError = action.payload;
      state.data.domainVerifyError = action.payload
      state.data.emailError = action.payload
      state.data.deleteError = action.payload
      state.data.deleteSuccess = action.payload
      return state;
    },

    setDashboardDateRange(state, action) {
      state.data.DateRange = action.payload

    },
    isTemplateExist(state, { payload }: PayloadAction<any>) {
      const value = payload;
      state.data.checkTemplateExist = value;
    },
    setClickOnContinue(state, { payload }: PayloadAction<any>) {
      state.data.isCountinued = payload;
    }
  },
  extraReducers: (builder) => {
    builder

      // * get Email verified status
      .addCase(
        EmailActions.postEmailDomainVerify.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postEmailDomainVerify.fulfilled,
        (state, action) => {
          if (state.data.activeSteps < 7) {
            if (action.payload?.data.status === "success") {
              state.data.emailVerifiedStatus = action.payload?.data?.response?.config_value?.domain_verification;
              state.data.activeSteps = state.data.activeSteps;
            }
          }
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postEmailDomainVerify.rejected,
        (state, error: any) => {
          state.loading = false;
          state.data.emailVerifiedStatus = null;
          state.error = error?.payload?.data?.error || "";
          state.data.domainVerifyError = error?.payload?.data?.error || "";
        }
      )


      //* post filtered data queries
      .addCase(EmailActions.postFilteredData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(EmailActions.postFilteredData.fulfilled, (state, action) => {
        state.data.filteredData = action.payload.data?.response;
        state.error = "";
        state.loading = false;
      })
      .addCase(EmailActions.postFilteredData.rejected, (state, action) => {
        state.loading = false;
        state.data.filteredData = null;
        state.error = action?.error?.message || "";
      })
      //* post Email Domain data
      .addCase(
        EmailActions.postEmailDomainCreate.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postEmailDomainCreate.fulfilled,
        (state, action) => {
          if (state.data.activeSteps < 6) {
            if (action?.payload?.data?.response && action?.payload?.data?.response !== "Domain already exists") {

              state.data.activeSteps = state.data.activeSteps + 1;
            }
          }
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postEmailDomainCreate.rejected,
        (state, error: any) => {
          state.loading = false;
          state.error = error?.payload?.data?.error || "";
          state.data.domainError = error?.payload?.data?.error || "";
        }
      )

      //* post set as default Email Doamin 
      .addCase(
        EmailActions.postEmailSetDefault.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postEmailSetDefault.fulfilled,
        (state, action) => {
          if (state.data.activeSteps < 7) {
            if (action.payload?.data?.status !== 500) {
              state.data.activeSteps = state.data.activeSteps + 1;

            }
          }
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postEmailSetDefault.rejected,
        (state, error: any) => {
          state.loading = false;
          state.error = error?.payload?.data?.error || "";
          state.data.emailError = error?.payload?.data?.error || "";
        }
      )

      //* post Email Bounce Domain data
      .addCase(
        EmailActions.postBounceDomainVerification.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postBounceDomainVerification.fulfilled,
        (state, action) => {

          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postBounceDomainVerification.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      //create  Template
      .addCase(
        EmailActions.postEmailCreateBroadcast.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postEmailCreateBroadcast.fulfilled,
        (state, action) => {
          state.data.postEmailBroadcastData = action?.payload?.data?.data?.id;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postEmailCreateBroadcast.rejected,
        (state, action) => {
          state.loading = false;
          state.data.postEmailBroadcastData = null;
          state.error = action?.error?.message || "";
        }
      )
      //  GET SMS BroadCast List Data

      .addCase(
        EmailActions.getEmailAnalyticsListData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getEmailAnalyticsListData.fulfilled,
        (state, action) => {
          state.data.filteredData = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getEmailAnalyticsListData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.filteredData = null;
          state.error = action?.error?.message || "";
        }
      )


      //  GET Email BroadCast List Data

      .addCase(
        EmailActions.getEmailAnalyticsListDataByID.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getEmailAnalyticsListDataByID.fulfilled,
        (state, action) => {
          state.data.filteredDataByID = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getEmailAnalyticsListDataByID.rejected,
        (state, action) => {
          state.loading = false;
          state.data.filteredDataByID = null;
          state.error = action?.error?.message || "";
        }
      )
      //  GET Email BroadCast Template List ByID

      .addCase(
        EmailActions.getEmailTempListById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getEmailTempListById.fulfilled,
        (state, action) => {
          state.data.filteredTemplateByID = action.payload?.data?.template_design;
          state.data.templateDetailsByID = action.payload?.data
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getEmailTempListById.rejected,
        (state, action) => {
          state.loading = false;
          state.data.filteredTemplateByID = null;
          state.error = action?.error?.message || "";
        }
      )

      // get broadcast list byID
      .addCase(EmailActions.getBroadcastById.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(EmailActions.getBroadcastById.fulfilled, (state, action) => {
        state.data.broadcastByIdData = action.payload;
        state.error = "";
        state.loading = false;
      })
      .addCase(EmailActions.getBroadcastById.rejected, (state, action) => {
        state.loading = false;
        state.data.broadcastByIdData = null;
        state.error = action?.error?.message || "";
      })


      //* all mytemplates
      .addCase(
        EmailActions.getEmailTemplatesListings.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getEmailTemplatesListings.fulfilled,
        (state, action) => {
          let temp: any = [];
          action.payload?.data?.results?.map((item: any) => {
            temp.push({ name: item.name, value: item.id });
          });
          state.data.allTemplatesData = temp;
          state.data.emailTemplateListData = action.payload?.data?.results;
          state.data.emailTemplateLists = action.payload?.data
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getEmailTemplatesListings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allTemplatesData = [];
          state.data.emailTemplateListData = [];
          state.data.emailTemplateLists = [];
          state.error = action?.error?.message || "";
        }
      )

      //* all gellery templates
      .addCase(
        EmailActions.getEmailgalleryTemplatesListings.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getEmailgalleryTemplatesListings.fulfilled,
        (state, action) => {
          state.data.allGelleryTemplatesData = action.payload?.data?.results;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getEmailgalleryTemplatesListings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allGelleryTemplatesData = null;
          state.error = action?.error?.message || "";
        }
      )

      //Email Template
      .addCase(
        EmailActions.postEmailTemplateCreate.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postEmailTemplateCreate.fulfilled,
        (state, action) => {

          state.data.activeSteps = state.data.activeSteps + 1;


          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postEmailTemplateCreate.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      //Email tag list
      .addCase(
        EmailActions.getEmailTagList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getEmailTagList.fulfilled,
        (state, action) => {
          state.data.activeSteps = state.data.activeSteps + 1;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getEmailTagList.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      //get Validate email list
      .addCase(
        EmailActions.getValidateEmailList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getValidateEmailList.fulfilled,
        (state, action) => {
          if (action.payload?.data?.response?.length > 0) {
            state.data.filteredEmail = []
            action.payload?.data?.response?.map((item: any) => {
              if (item.validated) {
                return state.data.filteredEmail.push({ name: item.email });
              }

            })
            state.data.validEmailList = state.data.filteredEmail;

          }
          state.data.activeSteps = state.data.activeSteps + 1;

          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getValidateEmailList.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      //get Footer list
      .addCase(
        EmailActions.getBroadcastFooterList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.getBroadcastFooterList.fulfilled,
        (state, action) => {
          if (action?.payload?.data?.response?.data?.length > 0) {
            state.data.formatFooterData = []
            action?.payload?.data?.response?.data.map((item: any) => {
              return state.data.formatFooterData.push({ name: item.address1 || "NA" });
            })

            state.data.footerListData = state.data.formatFooterData;
          }

          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.getBroadcastFooterList.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )
      //get delete broadcast list
      .addCase(
        EmailActions.deleteEmailBroadcastById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.deleteEmailBroadcastById.fulfilled,
        (state, action) => {


          state.data.deleteSuccess = action?.payload?.data?.message || "Broadcast Deleted Successfully";
          state.data.deleteError = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.deleteEmailBroadcastById.rejected,
        (state, action: any) => {
          state.loading = false;
          state.data.deleteSuccess = "";
          state.data.deleteError = action?.payload?.data?.message || "Failed to Delete Broadcast";
        }
      )


      //* Upload Attacthment Broadcast
      .addCase(
        EmailActions.postUploadAttachment.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postUploadAttachment.fulfilled,
        (state, action) => {
          if (state.data.activeSteps < 7) {
            if (action.payload?.data?.status !== 500) {
              state.data.activeSteps = state.data.activeSteps + 1;

            }
          }
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postUploadAttachment.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )


      //* Test Email Template
      .addCase(
        EmailActions.postTestEmailTemplate.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.postTestEmailTemplate.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.postTestEmailTemplate.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      //* Update Email thumnail
      .addCase(
        EmailActions.updateEmailThumbnail.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.updateEmailThumbnail.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.updateEmailThumbnail.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )
      //* Delete Email thumnail
      .addCase(
        EmailActions.deleteEmailDomain.pending,
        (state, action) => {
          state.error = "";
          state.data.isDomainDeleted = false;
          state.loading = true;
        }
      )
      .addCase(
        EmailActions.deleteEmailDomain.fulfilled,
        (state, action) => {
          state.error = "";
          state.data.isDomainDeleted = true;
          state.loading = false;
        }
      )
      .addCase(
        EmailActions.deleteEmailDomain.rejected,
        (state, action) => {
          state.loading = false;
          state.data.isDomainDeleted = false;
          state.error = action?.error?.message || "";
        }
      )
  },
});
export const { verifyActiveStep, htmlTemplateData, editedHtmlData, resetError, testEmailTemplateData, resetEmailData, resetDelete, setDashboardDateRange, isEmailEnabled, isTemplateExist, setClickOnContinue } = EmailSlice.actions;



export default EmailSlice.reducer;
