import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { device } from "../../../../Customization/BreakPoints";
import { Menu } from "@mui/material";
import { TablePagination } from "@mui/material";
// Css main Layout
export const WrapperContainer = styled.div`
  width: 100%;
  /* height: calc(100%-64px); */
  height: 100%;
`;
// Css for topbar
export const TopBar = styled.div`
  box-shadow: ${theme.palette.default.shadow};
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  // border-radius: 8px;
`;
// Css for topbar leftCol
export const LeftCol = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  color: ${theme.palette.default.darkGrey};
  flex-wrap: wrap;
  .limit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    strong {
      color: ${theme.palette.default.text};
    }
  }
  span {
    color: ${theme.palette.default.text};
    margin-left: 5px;
  }
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-left: 5px;
  }
  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
  }
`;
// Css for topbar rightCol
export const RightCol = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  gap: 1.4rem;
  .contact {
    display: flex;
    flex-direction: column;
  }
  .conversations {
    display: flex;
    align-items: center;
    /* gap: 0.6rem; */
  }
  .credits {
    display: flex;
    align-items: center;
    padding: 16px;
  }

  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
    gap: 0.8rem;
    .buy-credits__btn {
      button {
        width: 93px;
      }
    }
  }
`;
// Css for topbar col
export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  /* margin-left: 32px; */
  flex-direction: column;
  color: ${theme.palette.default.darkGrey};
  label {
  }
  &:first-child {
    strong {
      margin-left: 0;
    }
  }
  strong {
    color: ${theme.palette.default.text};
    font-weight: 500;
    font-size: 1rem;
    margin-left: 4px;
  }
`;
// Css for Analytics List Wrapper
export const AnalyticsBoxWrapper = styled.div`
  & {
    display: flex;
    gap: 10px;
    -webkit-box-pack: start;
    justify-content: space-between;
    overflow: auto;
    padding-bottom: 8px;
    margin-top: 16px;
  }
`;

export const AnalyticsBox = styled.div`
  & {
    display: flex;
    padding: 2rem 0;
    border-radius: 1rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    flex-grow: 1;
    max-height: 191px;
    min-height: 191px;
    background-color: ${theme.palette.default.white};
    .top {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      // margin-bottom: 0.75rem;
      .icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #0d9d1b;

        .MuiSvgIcon-root {
          font-size: 22px;
          color: ${theme.palette.default.white};
        }
      }

      .send {
        .MuiSvgIcon-root {
          transform: rotate(315deg);
        }
      }

      h6 {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }

    .bottom {
      h4 {
        font-size: 2.25rem;
        font-weight: 400;
        .percent {
          font-size: 1rem;
          color: #0f5500;
          /* font-weight: 600; */
        }
      }
    }

    .box-left {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      /* padding-right: 1rem; */
      padding-left: 1rem;
      gap: 1rem;
      width: 50%;
    }

    .box-right {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      padding-left: 1rem;
      border-left: 1px dashed ${theme.palette.default.grey};
      padding-left: 1rem;
      width: 50%;
    }
  }
`;

export const AnalyticsWrapper = styled.div`
  padding: 10px 16px 6px;
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      color: ${theme.palette.default.black};
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      font-weight: 500;
    }
  }
  .analyticsDataset {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .col {
      // border: 1px solid ${theme.palette.default.lightPrimaryBorder};
      // background-color: ${theme.palette.default.lightPrimaryBackground};

      height: 154px;
      padding: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-radius: 8px;
      margin-top: 17px;

      .iconWrapper {
        background-color: ${theme.palette.default.white};
        width: 40px;
        height: 40px;
        border-radius: 5px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        background: #ffffff;
        box-shadow: 3px 3px 6px rgba(112, 170, 112, 0.15),
          inset 2px 2px 4px rgba(177, 199, 177, 0.15);
        border-radius: 8px;
        svg {
          color: ${theme.palette.primary.main};
        }
      }
      h6 {
        display: flex;
        align-items: center;
        color: ${theme.palette.default.black};
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        .customTooltip {
          margin-left: 5px;
          max-width: 13px;
          max-height: 13px;
        }
      }
      strong {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
`;
export const PageDiv = styled.div`
  padding: 1rem !important;
`;
export const BroadcastListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  padding: 0 1rem;
`;

export const BroadcastDetailListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  /* padding: 0px 24px 14px; */
  .detailSMStableAction-wrapepr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detailSMStableAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
  .setting-page-mainboxtopbar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    .setting-page__menu:last-child {
      margin-left: auto;
      > button {
        margin-left: 10px;
      }
    }

    & .MuiMenuItem-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-weight: 400;
        margin-bottom: 0.4rem;
        font-size: 0.875rem;
        color: ${theme.palette.default.black};
      }
    }
    &:first-child {
      padding-left: 16px;
    }
  }
`;
export const FilterDatePannel = styled.div`
  .date-range__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0.5rem;
    p {
      font-weight: 400;
      font-size: 0.875rem;
      color: ${theme.palette.default.black};
    }
  }
`;

export const SmsMenuFilter = styled(Menu)`
  && {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
    & .MuiMenu-list {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }

    & .MuiMenuItem-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-weight: 400;
        margin-bottom: 0.4rem;
        font-size: 0.875rem;
        color: ${theme.palette.default.black};
      }
    }
    .setting-page-mainboxtopbar__right {
      padding: 1rem;
    }
    .css-tucewo {
      display: flex;
      gap: 1rem;
      justify-content: space-evenly;
    }
  }
`;
export const ErrorComponent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    min-height: calc(100vh - 350px);
    overflow: auto;
    img {
      max-width: 100%;
      height: auto;
    }
    .error-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .err-content {
      gap: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
`;

export const DataTable = styled.div`
  && {
    .table-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .ri-file-download-line {
        color: ${theme.palette.default.white};
      }

      //     .table-content__text {
      //         display: flex;
      //         align-items: start;
      //         .tool-tip {
      //             display: flex;
      //             align-items: center;
      //             padding-top: 0.875rem;
      //             p {
      //                 padding-bottom: 0;
      //             }
      //         }
      //         .checkbox-text {
      //             padding-top: 7px;
      //             // p {
      //             //     padding-bottom: 0.875rem;
      //             // }
      //         }
      //         h4 {
      //             font-weight: 500;
      //             font-size: 0.875rem;
      //         }
      //         p {
      //             font-size: 0.875rem;
      //             color: ${theme.palette.default.darkGrey};
      //             padding-bottom: 0.875rem;
      //         }
      //         .table-actions {
      //             display: flex;
      //             gap: 1rem;
      //             padding-top: 1rem;
      //         }

      //     }
      // }

      // .table-content > :first-child {
      //     min-width: 280px;

      // }
      // .table-content > :last-child {

      // }
      // .table-content > :nth-child(2) {
      //     width: calc(100%);
      // }
    }
  }
`;
export const BroadcastMainWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    padding: 1rem;
    // border: 1px solid;
  }
`;

export const BroadcastMainWrapperTitle = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const BroadcastHistoryTableWrapper = styled.div`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    padding: 0px 16px 16px 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .table-header {
      color: ${theme.palette.default.black};
      .table-row {
        padding: 16px 0;
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        .table-text {
          color: ${theme.palette.default.black} !important;
        }
      }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:last-child {
      //     border: none;
      //   }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
      }
    }
    // Body
    .table-body {
      overflow: auto;
      height: calc(100vh - 367px);
      .table-row {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
      }
    }
  }
`;

export const BroadcastLogTable = styled.div`
  & {
    width: 100%;
    background-color: ${theme.palette.default.white};
    // padding: 0px 16px 16px 16px;
    > div {
      overflow: auto;
      height: calc(100vh - 300px);
    }
    .table-header {
      position: sticky;
      top: 0;
      .table-row {
        display: flex;
        align-items: center;
      }
      .date {
        max-width: 220px;
        min-width: 220px;
        // border: 1px solid;
      }
      .from {
        // max-width: 150px;
        min-width: 150px;
        // border: 1px solid;
      }
      .to {
        // max-width: 150px;
        min-width: 150px;
        // border: 1px solid;
      }
      .body {
        max-width: 420px;
        min-width: 420px;
      }
      .status {
        // max-width: 150px;
        min-width: 150px;
      }
      .error_reason {
        max-width: 200px;
        min-width: 200px;
      }
      .price {
        // max-width: 150px;
        min-width: 150px;
      }
      .price {
        // max-width: 150px;
        min-width: 150px;
      }
      .unit {
        // max-width: 150px;
        min-width: 150px;
      }
      .total_price {
        // max-width: 150px;
        min-width: 150px;
      }
      .direction {
        // max-width: 150px;
        min-width: 150px;
      }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 140px;
      //     min-width: 140px;
      //   }
      //   .table-row > div:nth-child(3) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(4) {
      //     max-width: 420px;
      //     min-width: 420px;
      //   }
      //   .table-row > div:last-child {
      //     border: none;
      //   }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        padding: 16px 0;
        background-color: ${theme.palette.default.white};
        p {
          padding: 0 16px;
          border-left: 1px solid rgba(126, 131, 146, 0.5);
        }
        &:first-child {
          p {
            border: none;
          }
        }
      }
    }
    .table-body {
      .table-row {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        color: rgb(126, 131, 146, 1.5);
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .date {
        max-width: 220px;
        min-width: 220px;
        // border: 1px solid;
      }
      .from {
        // max-width: 150px;
        min-width: 150px;
        // border: 1px solid;
      }
      .to {
        // max-width: 150px;
        min-width: 150px;
        // border: 1px solid;
      }
      .body {
        max-width: 420px;
        min-width: 420px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .status {
        // max-width: 150px;
        min-width: 150px;
      }
      .error_reason {
        max-width: 200px;
        min-width: 200px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .price {
        // max-width: 150px;
        min-width: 150px;
      }
      .unit {
        // max-width: 150px;
        min-width: 150px;
      }
      .total_price {
        // max-width: 150px;
        min-width: 150px;
      }
      .direction {
        // max-width: 150px;
        min-width: 150px;
      }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
    }
  }
`;

export const BroadcastReportLogTable = styled.div`
  & {
    width: 100%;
    background-color: ${theme.palette.default.white};
    // padding: 0px 16px 16px 16px;
    > div {
      overflow: auto;
      height: calc(100vh - 450px);
    }
    .table-header {
      position: sticky;
      top: 0;
      .table-row {
        display: flex;
        align-items: center;
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
      }
      .report_date {
        max-width: 270px;
        min-width: 270px;
        // border: 1px solid;
      }
      .download_report {
        max-width: 250px;
        min-width: 250px;
      }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 140px;
      //     min-width: 140px;
      //   }
      //   .table-row > div:nth-child(3) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(4) {
      //     max-width: 420px;
      //     min-width: 420px;
      //   }
      //   .table-row > div:last-child {
      //     border: none;
      //   }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        padding: 16px 0;
        background-color: ${theme.palette.default.white};
        p {
          padding: 0 16px;
          border-left: 1px solid rgba(126, 131, 146, 0.5);
        }
        &:first-child {
          p {
            border: none;
          }
        }
      }
    }
    .table-body {
      .table-row {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        color: rgb(126, 131, 146, 1.5);
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .report_date {
        max-width: 270px;
        min-width: 270px;
        // border: 1px solid;
      }
      .download_report {
        max-width: 250px;
        min-width: 250px;
      }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
    }
  }
`;

export const AppPagination = styled(TablePagination)`
  & {
    padding: 8px 0 !important;
    .MuiSelect-select {
      border: 1px solid ${theme.palette.default.grey};
      border-radius: 6px;
    }

    p {
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-select {
      text-align: center !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-actions > button:last-child {
      margin-left: 8px;
    }
    .MuiTablePagination-actions > button {
      border: 1px solid ${theme.palette.default.grey} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.darkGrey};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
    .MuiTablePagination-actions .Mui-disabled {
      border: 1px solid ${theme.palette.default.RightBorderDivder} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.RightBorderDivder};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
  }
`;

export const BroadcastBodyWrapper = styled.div`
  & {
    // padding: 1rem;
    /* background-color: #fff; */
    .fields-container {
      /* border: 1px solid red; */
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      gap: 0.4rem;
      .sms_left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .create-table-select {
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        .create-table-select {
          z-index: 2;
          .css-b62m3t-container {
            width: 220px;
            height: 40px;
          }
        }
      }
      .sms_right {
        display: flex;
        align-items: center;

        .sms_select {
          .sms-filter {
          }
        }
      }
    }
    .setting-page-mainboxtopbar {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 20px;
      .setting-page__menu:last-child {
        margin-left: auto;
      }
      > button {
        margin-left: 10px;
      }
      & .MuiMenuItem-root {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          font-weight: 400;
          margin-bottom: 0.4rem;
          font-size: 0.875rem;
          color: ${theme.palette.default.black};
        }
      }
    }
  }
`;

export const BroadcastFiletr = styled.div`
  & {
    background-color: ${theme.palette.default.white};
    padding: 1rem;
    .filter-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;
