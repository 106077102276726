import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";

export const PreviewWrapper = styled.div`
    & {
        min-width: 300Px;
        max-width: 350Px;
        margin: 0 auto;
        border-radius: 14px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const PreviewMain = styled.div`
& {
    height: 80%;
    max-height: 700px;
    box-shadow: 0px 13.9892px 69.9461px rgba(126, 131, 146, 0.24);
    .header {
        padding: 14px 16px;
        display: flex;
        justify-content: space-between;
        background-color: ${theme.palette.default.darkGrey};
        border-radius: 14px 14px 0 0;
        height: 60px;
        .header_left {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.5rem;
            img {
                width: 40px;
                min-width: 34px;
            }
            .left-text {
                color: #fff;
                h4 {
                    font-size: 0.875rem;
                    font-weight: 500;
                    margin: 0;
                }
                p {
                    font-size: 12px;
                    margin: 0;
                }
            }
        }

        .header_right {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            img {
                width: 40px;
                min-width: 34px;
            }
            .MuiSvgIcon-root {
                color: #fff;
                font-size: 1.2rem;
            }
        }
    }

    .body {
        height: calc(100% - 108px);
        overflow: auto;
        background-color: #fff;
        padding: 7px;
        .start-text {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase; 
            font-size: 0.875rem;
            text-align: center;
            margin-bottom: 0.875rem;    
            color: ${theme.palette.default.darkGrey}
        }

        .chat_box {
            width: 85%;
            display: flex;
            gap: 0.5rem;
            .chat_logo {
                display: flex;
                align-items: flex-end;
                img {
                    width: 25px;
                    min-width: 20px;
                }
            }
            .chat_content {
                font-size: 0.875rem;
                padding: 10px 6px;
                border-radius: 6px 6px 6px 0px;
                background-color: #F5F6F8;
                word-break: break-all;
                p {
                    color: ${theme.palette.default.black}
                }
            }
        }
    }
    .footer {
        height: 48px;
        background-color: #fff;
        padding: 14px 16px;
        border-radius:0 0 14px 14px;   
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid ${theme.palette.default.grey};
        .MuiSvgIcon-root {
            color: ${theme.palette.default.darkGrey};;
            font-size: 1.2rem;
        }
        .footer_left {
            display: flex;
            align-items: center;
            gap:0.5rem;
            p {
                font-size: 0.875rem;
                color: ${theme.palette.default.darkGrey}
            }
        }

        .footer_right {
            display: flex;
            align-items: center;
        }
    }

}
`;