import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Stack, Tooltip } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../HOC/HocBackdropLoader";
import AppImageUpload from "../AppImageUpload";
import AppErrorMessage from "./AppErrorMessage";

interface Props extends HocBackdropLoaderProps {
  label?: string;
  name: string;
  tooltip?: any;
  sizeLimit: number;
  sizeErrorMsg?: string;
  formatErrorMsg?: string;
  galleryVisible?: boolean;
  squareImageBox?: boolean;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}
const AppFormImagePicker: React.FC<Props> = ({
  label,
  name,
  tooltip,
  galleryVisible = false,
  squareImageBox = false,
  sizeLimit = 0,
  sizeErrorMsg = 'Image size must be smaller than 1MB.',
  formatErrorMsg = 'Image should be PNG, JPEG or JPG.',
  setLoading,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldError,
  } = useFormikContext<any>();

  return (
    <Div>
      <Label>
        {label}{" "}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <Stack
        style={{
          display: "flex",
          gap: "1rem",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <AppImageUpload
          name={name}
          sizeLimit={sizeLimit}
          sizeErrorMsg={sizeErrorMsg}
          formatErrorMsg={formatErrorMsg}
          squareImageBox={squareImageBox} />
      </Stack>
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </Div>
  );
};

export default HocBackdropLoader(AppFormImagePicker);

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.3rem;
    small {
      margin-top: -4px;
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
      color: #aaabaf;
    }
  }
`;

const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
