// vemdors
import { useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import cloneDeep from "lodash.clonedeep";

// components
import Icon from "../Components/Icon";
import Card from "../Components/Card";

// slices
import {
  useNodes,
  getActiveAction,
  setActiveAction,
} from "../Slices/main.slice";
import { useAppDispatch } from "../../../Store";

// utils
import { getActionConfig } from "../Utils";

// hooks
import useActions from "./useActions";

// styled
import {
  ToolbarContainerStyled,
  ToolbarActionsWrapperStyled,
} from "./NodeWrapper.styled";

type Props = {
  actions: any;
  id: any;
  editableState: boolean;
};

const Action = (props: Props) => {
  const { actions, id, editableState } = props;
  const nodes = useSelector(useNodes);
  const activeAction = useSelector(getActiveAction);
  const { updateActions } = useActions();
  const [showPreview, setPreview] = useState<boolean>(editableState);
  const [hoveredActionId, setHoveredActionId] = useState<string>('');
  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const togglePreview = () => {
    setPreview(!showPreview);
  };

  const handleCardClick = (
    event: React.MouseEvent<HTMLElement>,
    action: any
  ) => {
    dispatch(setActiveAction(action));
    togglePreview();
    event.stopPropagation();
  };

  const handleMouseEnter = (actionId: any) => {
    setShowToolbar(true);
    setHoveredActionId(actionId);
  };

  const handleMouseLeave = () => {
    setShowToolbar(false);
    setHoveredActionId('');
  };

  const handleDeleteClick = (
    actionId: string,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    let tempActions = cloneDeep(actions);
    tempActions = tempActions.filter((tAction: any) => tAction.id !== actionId);
    const matchedNode = nodes.find((node: any) => node.nodeId === id);
    if (actions && !isEqual(actions, tempActions)) {
      updateActions(tempActions, matchedNode.nodeId, matchedNode.qid);
      dispatch(setActiveAction({}));
    }
  };

  const handleSave = (activeAction: any) => {
    const matchedNode = nodes.find((node: any) => node.nodeId === id);

    if (matchedNode?.actions) {
      const currentAction = matchedNode.actions.find(
        (action: any) => action.id === activeAction.id
      );
      if (!currentAction) {
        if (!showPreview) {
          togglePreview();
          dispatch(setActiveAction({}));
        }
        return;
      }

      const updateAction = matchedNode.actions.map((actionItem: any) =>
        actionItem.id === activeAction.id ? activeAction : actionItem
      );

      if (activeAction?.type === "function" && !activeAction?.text) return;

      if (matchedNode.actions && !isEqual(matchedNode.actions, updateAction)) {
        updateActions(updateAction, matchedNode.nodeId, matchedNode.qid);
        dispatch(setActiveAction({}));
      }
      dispatch(setActiveAction({}));
      if (!showPreview) {
        togglePreview();
        dispatch(setActiveAction({}));
      }
    }
  };

  const handleCancel = (isEmpty: boolean) => {
    if(isEmpty) {
      let tempActions = cloneDeep(actions);
      tempActions = tempActions.filter((tAction: any) => tAction.id !== activeAction.id);
      const matchedNode = nodes.find((node: any) => node.nodeId === id);
      if (actions && !isEqual(actions, tempActions)) {
        updateActions(tempActions, matchedNode.nodeId, matchedNode.qid);
        dispatch(setActiveAction({}));
      }
    } else {
      togglePreview();
      dispatch(setActiveAction({}));
    }
  };

  return (
    actions.length > 0 &&
    actions.map((action: any, index: number) => {
      if (getActionConfig(action.type)?.displayComponent) {
        const Component = getActionConfig(action.type).displayComponent;
        return (
          <Box
            sx={{ mt: "16px" }}
            key={`actions-${index + 1}-${id}`}
          >
            <Card
              secondary
              onClick={(event) => handleCardClick(event, action)}
              onMouseEnter={() => handleMouseEnter(action.id)}
              onMouseLeave={handleMouseLeave}
            >
              {(hoveredActionId === action.id && showToolbar) && (
                <ToolbarContainerStyled>
                  <ToolbarActionsWrapperStyled>
                    <Tooltip title="Delete" placement="right-start">
                      <IconButton
                        onClick={(event: React.MouseEvent<HTMLElement>) =>
                          handleDeleteClick(action.id, event)
                        }
                      >
                        <Icon
                          icon="delete"
                          color="default.darkGrey"
                          size={16}
                        />
                      </IconButton>
                    </Tooltip>
                  </ToolbarActionsWrapperStyled>
                </ToolbarContainerStyled>
              )}
              <Box
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Component
                  id={id}
                  actionId={action.id}
                  {...action}
                  showPreview={!(activeAction.id === action.id)}
                  onSave={handleSave}
                  onCancel={handleCancel}
                />
              </Box>
            </Card>
          </Box>
        );
      } else {
        return null;
      }
    })
  );
};

export default Action;
