// vendors
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box, { BoxProps } from "@mui/material/Box";
import IconButton, {IconButtonProps} from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

interface UIButtonProps extends BoxProps {
  disabled?: boolean;
}

const commonButtonStyle = {
  background: "#FFFFFF",
  border: "1px solid #CBCDD3",
  borderRadius: "8px",
  height: "40px",
  width: "40px",
  minWidth: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  zIndex: 1,
  "&:hover": {
    backgroundColor: "#CBCDD3",
    transition: "all 300ms ease-out",
  },
  "&:focus": {
    background: "#FFFFFF",
  },
};

const commonButtonDisabledStyle = {
  cursor: "not-allowed",
  backgroundColor: "#f6f6f6",
  "&:hover": {
    backgroundColor: "#f6f6f6",
  },
};

export const LeftArrowButtonStyled = styled(Button)(
  ({ disabled }) => ({
    position: "absolute",
    left: "-45px",
    ...commonButtonStyle,
    ...(disabled && commonButtonDisabledStyle),
  })
);

export const RightArrowButtonStyled = styled(Button)(
  ({ disabled }) => ({
    position: "absolute",
    right: "-45px",
    ...commonButtonStyle,
    ...(disabled && commonButtonDisabledStyle),
  })
);

export const CarouselDotStyled = styled(Box)(() => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "24px",
  height: "24px",
  border: "1px solid #CBCDD3",
  borderRadius: "8px",
  cursor: "pointer",
  backgroundColor: "#fff",
  padding: "5px",
  "&:hover": {
    backgroundColor: "#f1f1f1",
    transition: "all 300ms ease-out",
  },
  "& .slick-active": {
    color: "#5B73E8",
  },
}));

export const DeleteButtonStyled = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid #cbcdd3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  &.Mui-disabled {
    background-color: #f2f2f2;
  }

  &:hover {
    background-color: #f2f2f2;
  }
`;

export const RemoveImageButtonStyled = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border: 1px solid #cbcdd3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  top: 16px;
  right: 68px;
  cursor: pointer;
  &:hover {
    background-color: #f2f2f2;
  }
`;

export const LabelStyled = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#7E8392",
  marginBottom: "4px",
  padding:"5px 0"
}));

interface ISliderWrapperBoxProps extends BoxProps {
  isActive?: boolean;
}


export const SliderWrapperStyled = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== "isActive";
  },
})<ISliderWrapperBoxProps>(({ isActive }) => ({
  "& .slick-arrow:before": {
    fontSize: "30px",
    color: "#3654E3",
  },
  "& .slick-prev": {
    left: "-45px",
  },
  "& .slick-prev:before": {
    content: '" "',
    fontSize: 0,
  },
  "& .slick-next:before": {
    content: '" "',
    fontSize: 0,
  },
  "& .slick-dots li": {
    color: "#7E8392",
  },
  "& .slick-dots li.slick-active": {
    color: "#5B73E8",
  },
  "& .slick-dots": {
    bottom: 0,
  },
  "& ul.slick-dots ": {
    marginLeft: 0,
  },
  
  '& .slick-initialized .slick-slide.slick-active': {
    zIndex: '1 !important'
  },
  
  "& .slick-slider .slick-track, .slick-slider .slick-list": {
    ...(isActive && { transform: 'none !important' }),
  },

}));

export const ImgStyled = styled('img')(() => ({
  width: "100%",
  height: "240px",
  objectFit: "cover",
}));
