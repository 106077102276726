
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../Customization/Theme";
import AppModel from "../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../Store";
import { ApplicationFormNetworkActions } from "../../../Store/Slices/ApplicationsForm/applicationForm.actions";
import { AppForm, AppFormTextArea, AppSubmitButton } from "../../../AppComponents/Forms";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { useEffect, useState } from "react";
import * as Yup from "yup";

interface MarkedAsReviewedModalProps {
    isShowingReviewModal: any;
    toggleReviewModal: any;
    applicationFomrRow: any;
    getApplicationsFormsTableData: any;
}


const MarkedAsReviewedModal: React.FC<MarkedAsReviewedModalProps> = ({
    isShowingReviewModal,
    toggleReviewModal,
    applicationFomrRow,
    getApplicationsFormsTableData
}) => {
    const dispatch = useAppDispatch();
    let { showAlert } = useNotification();
    const [reviewCriteriaOptions, setReviewCriteriaOptions] = useState<any>([]);
    useEffect(() => {
        if (applicationFomrRow?.hvJvHdZX !== "Yes") {
            dispatch(ApplicationFormNetworkActions.getApplicationPropertyOptions("hCvajFup")).then((res: any) => {
                setReviewCriteriaOptions(res?.payload?.data?.data?.options || [])
            })
        }
    }, [applicationFomrRow]) // eslint-disable-line
    const handleSubmit = (values: any, submitProps: any) => {
        if (applicationFomrRow?.hvJvHdZX === "Yes") {
            const payload = {
                id: applicationFomrRow?.application_response_id,
                data: {
                    "hvJvHdZX": false,
                    "hCvajFup": "",
                    "ebf7y3H8": ""
                }
            }
            dispatch(ApplicationFormNetworkActions.markedAsNotReviewed(payload)).then((res: any) => {
                if (res.payload.status === 200) {
                    showAlert(res?.payload?.data?.data || "Application has been marked as not reviewed", "success");
                    toggleReviewModal();
                    getApplicationsFormsTableData();
                } else {
                    showAlert("Something went wrong", "error");
                }
            }).catch((err) => {
                showAlert("Something went wrong", "error");
            }).finally(() => {
                submitProps.setSubmitting(false);
            })

        } else {
            const payload = {
                id: applicationFomrRow?.application_response_id,
                data: {
                    "hvJvHdZX": true,
                    "hCvajFup": values?.review_criteria?.value,
                    "ebf7y3H8": values?.note
                }
            }
            dispatch(ApplicationFormNetworkActions.markAsReviewed(payload)).then((res: any) => {
                if (res.payload.status === 200) {
                    showAlert(res?.payload?.data?.data || "Application has been reviewed", "success");
                    toggleReviewModal();
                    getApplicationsFormsTableData();
                } else {
                    showAlert("Something went wrong", "error");
                }
            }).catch((err) => {
                showAlert("Something went wrong", "error");
            }).finally(() => {
                submitProps.setSubmitting(false);
            })

        }
    }
    const validationSchema = Yup.object().shape({
        review_criteria : Yup.object().required("Review Criteria is required")
    })
    return (
        <AppModel
            isShowing={isShowingReviewModal}
            onClose={() => toggleReviewModal()}
            divStyle={{
                padding: 0,
            }}
            PaperProps={{
                style: {
                    boxShadow: "none",
                    width: "450px",
                },
            }}
        >
            <AppForm initialValues={{
                review_criteria: "",
                note: ""
            }}
                validationSchema={applicationFomrRow?.hvJvHdZX !== "Yes" ? validationSchema : undefined}
                onSubmit={(values: any, submitProps: any) => {
                    handleSubmit(values, submitProps)
                }}>

                <ConfirmDelete>
                    <div className="header">
                        <div className="header-left">
                            <div className="text">
                                <h4>
                                    Marked as {applicationFomrRow?.hvJvHdZX === "Yes" ? "Not Review" : "Reviewed"}
                                </h4>
                                <p>Are you sure, you want to mark <span className="student">{applicationFomrRow?.applicant_name || "user"}</span> as reviewed?</p>
                            </div>
                        </div>
                        <div className="header-right">
                            <span onClick={() => toggleReviewModal()}>
                                <AppMaterialIcons iconName="close" />
                            </span>
                        </div>
                    </div>
                    {applicationFomrRow?.hvJvHdZX !== "Yes" ?
                        <div className="form">
                            <AppReactSelectField
                                name={"review_criteria"}
                                options={reviewCriteriaOptions || []}
                                label="Review Criteria"
                                displayKey="name"
                                valueKey="id"
                                placeholder="Select Review Criteria"
                                isSearchable={true}
                                isClearable={true}
                                hideSelectedOptions={true}
                                isRequired={true}
                                divStyle={{ background: "none", padding: "0" }}
                                optionsPlaceholder="No Review Criteria Found"
                                requiredSign
                            />
                            <AppFormTextArea
                                rows={3}
                                label="Review Notes"
                                name="note"
                                placeholder="Please add review notes here..."
                            />
                        </div>
                        : null}
                    <div className="footer">
                        <AppButton variant="grey" onClick={() => toggleReviewModal()}>
                            Cancel
                        </AppButton>
                        <AppSubmitButton
                            title={applicationFomrRow?.hvJvHdZX === "Yes" ? "Not Review" : "Reviewed"}
                            variant="success"
                        >
                        </AppSubmitButton>
                    </div>
                </ConfirmDelete>
            </AppForm>
        </AppModel >)
};

export default MarkedAsReviewedModal;

const ConfirmDelete = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.875rem;
    .student {
      color: ${theme.palette.default.black};
    }
    .header-left {
      display: flex;
      gap: 0.875rem;
      .danger {
        padding-top: 4px;
        img {
          width: 1.2rem;
        }
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
.form{
   padding: 0.875rem;
   display: flex;
   flex-direction: column;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
   }
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;
