// vendors
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const ContainerStyled = styled(Box)({
  display: "flex",
  backgroundColor: "#F5F6F8",
  width: "100%",
  height: "100%",
}) as typeof Box;


export const RightContainerStyled = styled(Box)({
  display: "flex",
  flex: 1,
  padding: "24px 105px",
  overflow: "auto",
  justifyContent: "center",
  height: "100%",
}) as typeof Box;

export const RightContentWrapperStyled = styled(Box)({
  flex: 1,
  height: "fit-content",
  justifyContent: "center",
  display: "flex",
}) as typeof Box;

export const NodesWrapperStyled  = styled(Box)({
  width: "380px"
}) as typeof Box;