import TransferToFAQs from "./../actions/transfer-to-faqs";
import NodeToNode from "./../actions/node-to-node";
import Function from "../actions/function";

export const registerActions: any = [
  // {
  //   icon: "faq-icon",
  //   type: "transfer_to_faqs",
  //   color: "#C8EF87",
  //   name: "Transfer to FAQs",
  //   displayComponent: TransferToFAQs,
  //   defaultText: "Please input your query below.",
  //   enableOnLastNode: true,
  //   hideAddButton: true
  // },
  {
    icon: "nodes",
    type: "connect_node",
    color: "#B8C4FF",
    name: "Node to node",
    displayComponent: NodeToNode,
    enableOnLastNode: true,
    hideAddButton: true
  },
  {
    icon: "function",
    type: "function",
    name: "Trigger a JS Function",
    displayComponent: Function,
    enableOnLastNode: false,
    hideAddButton: false
  }
];
