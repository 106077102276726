import styled from "styled-components";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import AppIcon from "../../../../AppComponents/AppIcon";
import AppInputField from "../../../../AppComponents/AppInputField";
import {
  AppForm,
  AppFormSelectField,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";

import AppMultiColumnsFields from "../../../../AppComponents/Forms/AppMultiColumnsFields";
import { AppConfig } from "../../../../Config/app-config";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import {
  Box,
  CircularProgress,
  Paper,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { SampleTableUrls } from "../../../../Models/defaultSelectOptions";
import InstagramPageTable from "./InstagramPageTable";

const BOT_ID_KEY = "currentBotId";

interface Props {
  handleDisconnectButton: any;
  pageData: any;
}

const InstagramConfigure: React.FC<Props> = ({
  handleDisconnectButton,
  pageData,
}) => {
  // const [pageData, setPageData] = useState<any>();
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const bot_id = AppConfig.getUserData(BOT_ID_KEY);
  const data = {
    bot_id: bot_id,
    // 'fb_exchange_token': response.accessToken
  };


  return (
    <InstagramPageWrap>
      <div className="pageTopbar">
        {/* <AppIcon
            title={"ri-arrow-left-line"}
            onClick={() => handleDisconnectButton()}
            size="26px"
            color="#282828"
          /> */}
        <div className="accountInfo">
          {/* Connected: {} */}
          <AppButton variant="danger" onClick={() => handleDisconnectButton()}>
            Disconnect
          </AppButton>
        </div>
      </div>
      <div className="ctaBox">
      <img
          className="companyLogo"
          src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg"
          alt=""
        />
        <p className="ctaTitle">Instagram Bot Connected Pages</p>
        <InstagramPageTable rows={pageData}></InstagramPageTable>
      </div>
    </InstagramPageWrap>
  );
};

export default InstagramConfigure;

const InstagramPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;

    .pageTopbar {
      display: flex;
      justify-content: end;
      align-items: center;
      height: 48px;
      /* background-color: #F5F6F8; */
      padding: 0 0.4rem;
      width: 100%;

      .accountInfo {
        /* background-color: red; */
        height: 100%;
        /* width: 30%; */
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 2rem;
        font-size: 1rem;
        color: #7e8392;
      }
    }

    .ctaBox {
      display: flex;
      height: 20%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }

    .configureFormFieldsWrap {
      display: flex;
      /* justify-content: center; */
      /* background-color: #f5f6f8; */
      padding: 1rem 2rem;
      flex-direction: column;
      gap: 1rem;
      /* width: 60%; */
    }
  }
`;
