import { Paper } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const UsersWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const UsersContainer = styled(Paper)`
  overflow-x: auto;
  margin-left: 1rem;
  margin-right: 1rem;
  height: calc(100vh - 200px);
  padding-top: 16px;
`;

export const UserTable = styled.div`
  width: 100%;
  // overflow: auto;
  min-width: 1500px;
  height: 100%;
  .table-head {
    border-bottom: 1px solid ${theme.palette.default.grey};
    display: flex;
    .table-col {
      max-width: 200px;
      min-width: 218px;
      padding: 0.625rem;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${theme.palette.default};
      h4 {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }

    .table-col:nth-child(3) {
      min-width: 240px;
      max-width: 240px;
    }
    .table-col:nth-child(5) {
      min-width: 170px;
      max-width: 170px;
      display: flex;
      align-items: center;
      & .MuiSvgIcon-root {
        font-size: 0.875rem;
        padding-top: 0;
      }
    }
  }

  .table-head > div:last-child {
    flex: 1;
    max-width: 100%;
  }

  .table-content-row {
    display: felx;
    justify-content: space-between;
    .table-data {
      display: felx;
      .table-col {
        max-width: 200px;
        min-width: 218px;
        padding: 0.625rem;
        font-size: 0.875rem;
        h5 {
          font-size: 0.875rem;
        }

        .name-role {
          display: flex;
          gap: 0.5rem;
          h5 {
            font-weight: 500;
          }
          p {
            color: ${theme.palette.default.blue};
          }
        }
      }
    }
    .table-col:nth-child(3) {
      min-width: 240px;
      max-width: 240px;
      word-break: break-all;
    }
    .table-col:nth-child(5) {
      min-width: 170px;
      max-width: 170px;
    }
    .table-col {
      max-width: 200px;
      min-width: 200px;
      padding: 0.625rem;
      display: flex;
      align-items: center;
    }
    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export const AddUserWrapper = styled.div`
  width: 100%;
  .add-user__left {
    width: 70%;
    // background-color: #fff;
    margin-left: 1rem;
    height: calc(100vh - 200px);
    form {
      height: 100%;
    }

    .form-field__container {
      padding: 1rem;
      height: 100%;
      background-color: #fff;
    }
  }
  .navigate-back {
    display: flex;
    font-size: 1.125rem;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    h4 {
      font-weight: 500;
    }
  }
`;
