import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreditDetailsNetworkService } from "./FlowBuilderService";

export class FlowBuilderActions {
  // * get Credit broadcast details *//
  static getDefaultFlowAndCategories = createAsyncThunk(
    "CreditDetailSlice/getDefaultFlowAndCategories",
    (id: string, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getDefaultFlowAndCategories()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * Create Flow *//
  static createFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.createFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );


  // * Update Flow *//
  static updateFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.updateFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * Get Flow *//
  static getFlows = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getFlows(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * delete Flow *//
  static deleteFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.deleteFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * clone Flow *//
  static cloneFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.cloneFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * deprecate Flow *//
  static deprecateFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.deprecateFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );


  // * publish Flow *//
  static publishFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.publishFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );


  // * test Flow *//
  static testFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.testFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * sync Flow *//
  static syncFlow = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.syncFlow(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * Whastapp Config *//
  static whatsappConfig = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getWhastappConfig()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * Whastapp Config *//
  static dropDownValue = createAsyncThunk(
    "CreditDetailSlice/createFlow",
    (data: any, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getDropDownValue(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
}

