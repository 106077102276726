import { AppConfig } from "../../Config/app-config";
import http from "../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class CreditDetailsNetworkService {

    // get whatsapp config data list
    static async getDefaultFlowAndCategories() {


        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/default_flows`, {
                method: 'GET',
                headers: {
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // get whatsapp config data list
    static async createFlow(payload: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/create_flow`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // update whatsapp flow 
    static async updateFlow(payload: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/update_flow`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // get whatsapp flow 
    static async getFlows(payload: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/flow_list?status=${payload?.status ? payload?.status : ''}&search=${payload?.search ? payload?.search : ''}&limit=${payload?.limit ? payload?.limit : 25}&offset=${payload?.offset ? payload?.offset : 0}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // delete whatsapp flow 
    static async deleteFlow(flow_id: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/delete_flow?flow_id=${flow_id}`, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // Clone whatsapp flow 
    static async cloneFlow(payload: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/clone`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }


    // Deprecate whatsapp flow 
    static async deprecateFlow(flow_id: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/deprecated_flow?flow_id=${flow_id}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // Deprecate whatsapp flow 
    static async publishFlow(flow_id: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/publish_flow?flow_id=${flow_id}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // Test whatsapp flow 
    static async testFlow(payload: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/test_flow`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                },
                body: JSON.stringify(payload)
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }

    // Sync whatsapp flow 
    static async syncFlow(payload: any) {
        try {
            const response = await fetch(`${process.env.REACT_APP_WHASTAPP_DOAMIN}api/v1/whatsapp/sync_flow`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "geta-host": AppConfig.getLoggedInData()?.data?.geta_host,
                },
                body: null
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching default flow and categories:', error);
            throw error;
        }
    }


  static getWhastappConfig() {
    return http.get(
      `${consoleBaseUrl}${Endpoints.WHATSAPP_CONFIG}`
    );
  }

  static getDropDownValue(fieldId:any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_DROPDOWN_VALUE}${fieldId}/options`
    );
  }
}

//*Broadcast credit details endpoints *//

class Endpoints {
    static GET_DEFAULT_FLOW_AND_CATEGORIES = "/whatsapp/default_flows";
    static CREATE_FLOW = "/whatsapp/create_flow";
    static UPDATE_FLOW = "/whatsapp/update_flow"
    static GET_FLOW_LIST = "/whatsapp/flow_list"
    static CLONE_FLOW = "/whatsapp/clone"
    static DEPRECATE_FLOW = "/whatsapp/deprecated_flow"
    static PUBLISH_FLOW = "/whatsapp/publish_flow"
    static WHATSAPP_CONFIG = "/workspace/whatsapp/config"
    static GET_DROPDOWN_VALUE = "/workspace/contact-properties/field/"
    // new endpoints for node microservice
}
