import { memo } from "react";
import { AppMaterialIcons } from "../../../../../../AppComponents/AppMaterialIcons";
import { PreviewMain, PreviewWrapper } from "./PreviewStyles";

// Images
import { IconButton } from "@mui/material";
import BusinessChat from "../../../../../../assets/images/business_chat.svg";
import HeaderLogo from "../../../../../../assets/images/business_hrs.svg";

interface Props {
  message?: string;
}

const Preview: React.FC<Props> = ({ message }) => {
  return (
    <PreviewWrapper>
      <PreviewMain>
        <div className="header">
          <div className="header_left">
            <img src={HeaderLogo} alt="previewheader" />
            <div className="left-text">
              <h4>Chatbot</h4>
              <p>Online</p>
            </div>
          </div>
          <div className="header_right">
            <IconButton>
              <AppMaterialIcons iconName="RefreshOutlined" />
            </IconButton>
            <IconButton>
              <AppMaterialIcons iconName="close" />
            </IconButton>
          </div>
        </div>
        <div className="body">
          <div className="start-text">
            <p>
              You have reached us outside of
              <br /> our work hours
            </p>
          </div>
          <div className="chat_box">
            <div className="chat_logo">
              <img src={BusinessChat} alt="preview" />
            </div>
            <div className="chat_content">
              {/* <p>We are unavailable at this moment, leave a message we will get back to you soon</p> */}
              <p>{message}</p>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="footer_left">
            <AppMaterialIcons iconName="SentimentSatisfiedAltOutlined" />
            <p>Start A Conversation</p>
          </div>
          <div className="footer_right">
            <AppMaterialIcons iconName="AttachFileOutlined" />
            <AppMaterialIcons iconName="SendOutlined" />
          </div>
        </div>
      </PreviewMain>
    </PreviewWrapper>
  );
};
export default memo(Preview);
