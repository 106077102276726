const getInteractiveMessageData = (node: any, language: string) => {
  const lanResponse =
  language !== "english" ? `response_${language.toLowerCase()}` : "response";
  const response = node?.[lanResponse] ? node?.[lanResponse] : node?.response;
  if (response && response !== null && response.length > 0) {
    const filteredResponse = response.filter(
      (iRes: any) => iRes.hasOwnProperty("interactive")
    );
    return filteredResponse.length > 0 ? filteredResponse[0] : {};
  }
  return {};
};

export default getInteractiveMessageData;

