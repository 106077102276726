import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import type { RuleGroupType } from "react-querybuilder";

import AppModel from "../../../Hooks/useModel/AppModel";
import AppButton from "../../AppButton";
import { AppMaterialIcons } from "../../AppMaterialIcons";
import AppErrorMessage from "../AppErrorMessage";
import {
  StyledModelFooter,
  StyledModelHeader,
  StyledQueryBuilderWrap,
} from "./Styled";
import "react-querybuilder/dist/query-builder.scss";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppSelector } from "../../../Store";
import { contactListStore } from "../../../Store/Slices/ContactList/ContactList.selectors";
import { AppQueryBuilder } from "../../QueryBuilder";

const convertCustomFields = (fields: any[], operators: any, options: any) => {
  let customFields: any = [];
  fields?.map((item: any, index: number) => {
    if (item?.type === "datetime") {
      item.type = "date";
    }
    if (item?.type === "dropdown" || item?.type === "multiselect") {
      item.type = "select";
    }
    if (item?.type === "radio") {
      item.type = "select";
    }
    if (item?.type === "checkbox") {
      item.type = "select";
    }
    let temp: any = {
      ...item,
      operators: operators?.[item?.type],
      valueEditorType: item?.type,
      values: options,
      inputType: item?.type,
      validator: item?.type === "number" ? "number" : "text",
    };
    customFields?.push(temp);
  });
  return customFields;
};

const initialQuery: RuleGroupType = {
  combinator: "$and",
  rules: [],
};

interface Props {
  name: string;
  fields: any[];
  operators: any;
  toggle: (val?: boolean | undefined) => void;
  onChangeCallbackFn?: (value: any) => void;
  children?: React.ReactNode;
  [otherProps: string]: any;
}
const AppFormQueryBuilder: React.FC<Props> = ({
  name,
  isShowing,
  toggle,
  operators,
  fields,
  onChangeCallbackFn,
}) => {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext<any>();

  const [optionsValue, setOptionsValue] = useState<any>([]);
  const [customFields, setCustomFields] = useState<any>(convertCustomFields(fields, operators, optionsValue));
  const [query, setQuery] = useState(initialQuery);
  const [isDocumentReady, setIsDocumentReady] = useState<boolean>(false);
  const { allContactListData } = useAppSelector(contactListStore);
  const { showAlert } = useNotification();

  useEffect(() => {
    if (!isShowing) {
      setIsDocumentReady(false);
      return;
    }
    if (getIn(values, name)) {
      setQuery(getIn(values, name) || initialQuery);
    }
    setTimeout(() => {
      if (customFields?.length) {
        setIsDocumentReady(true);
      } else {
        showAlert("Something went wrong", "error");
        setIsDocumentReady(true);
      }
    }, 900);
  }, [isShowing]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allContactListData) {
      let optionsList: any = [];
      allContactListData?.options?.forEach((item: any) => {
        let temp: any = {
          label: item?.label,
          name: item?.value,
        };
        optionsList.push(temp);
      });
      setOptionsValue(optionsList);
      let tempt: any = [...customFields];
      tempt.filter((ele: any) => ele?.id === allContactListData.id)[0].values = [...optionsList];
      setCustomFields([...tempt])

    }
  }, [allContactListData]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSaveBtn = () => {
    setFieldValue(name, query);
    setFieldTouched(name, true);
    if (onChangeCallbackFn) {
      onChangeCallbackFn(query);
    }
    toggle(false);
  };
  const handleCancelBtn = () => {
    setQuery(getIn(values, name) || initialQuery);
    toggle(false);
  };
  const onQueryChangeCallbackFn = (filtersValue: any) => {
    setFieldValue(name, filtersValue);
    setQuery(filtersValue);
  };
  return (
    <AppModel
      isShowing={isShowing}
      onClose={() => toggle()}
      divStyle={{
        padding: 0,
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
          width: "1000px",
        },
      }}
    >
      <StyledModelHeader>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 500 }}>
            Filter Data
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => toggle(false)}>
            <AppMaterialIcons
              iconName={"Close"}
              style={{ fontSize: "1.2rem" }}
            />
          </IconButton>
        </Box>
      </StyledModelHeader>
      {isDocumentReady && customFields?.length ? (
        <StyledQueryBuilderWrap>
          {/* <QueryBuilderMaterial> */}
          <div style={{ height: "calc(89vh - 154px)" }}>
            <AppQueryBuilder
              fields={fields}
              operators={operators}
              onChangeCallbackFn={onQueryChangeCallbackFn}
              defaultInputValueFromApi={
                getIn(values, name) && Object.keys(getIn(values, name))?.length !== 0 ? getIn(values, name) : {
                  combinator: "$and",
                  rules: [],
                }
              }
            />
          </div>
          {/* <QueryBuilder
              // fields={fieldsDemo}
              fields={customFields}
              query={query}
              // operators={QueryBuilderOperators}
              combinators={QueryBuilderCombinators}
              onQueryChange={(q: any) => {setQuery(q)}}
              translations={QueryBuilderTranslations}
              resetOnFieldChange={true}
              showLockButtons={false}
              showCloneButtons={false}
              resetOnOperatorChange={true}
              listsAsArrays={true}
              parseNumbers={true}
              showNotToggle={false}
              showCombinatorsBetweenRules={false}
              controlElements={{
                // addGroupAction: () => null,
                fieldSelector: FieldSelector,
              }}
              controlClassnames={{
                queryBuilder: "queryBuilder",
                ruleGroup: "ruleGroup",
                header: "header",
                body: "body",
                combinators: "combinators",
                addRule: "addRule",
                cloneRule: "cloneRule",
                cloneGroup: "cloneGroup",
                removeGroup: "removeGroup",
                lockRule: "lockRule",
                lockGroup: "lockGroup",
                notToggle: "notToggle",
                rule: "rule",
                fields: "customFields",
                operators: "operators",
                value: "value",
                removeRule: "removeRule",
                dragHandle: "dragHandle",
                valueSource: "valueSource",
              }}
              onAddGroup={() => false}
            /> */}
          {/* </QueryBuilderMaterial> */}

          <AppErrorMessage
            error={getIn(errors, name)}
            visible={getIn(touched, name)}
          />
          {/* <h4>Query</h4>
              <pre>
                <code>{formatQuery(query, "json")}</code>
              </pre> */}
        </StyledQueryBuilderWrap>
      ) : (
        <Backdrop sx={{ color: "#fff", zIndex: 99999999999 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <StyledModelFooter>
        <Box display="flex" sx={{}} className="footer-btns">
          <AppButton onClick={() => handleCancelBtn()} variant="grey">
            Cancel
          </AppButton>

          <AppButton
            onClick={() => handleSaveBtn()}
            className="saveBtn"
            variant=""
            sx={{}}
          >
            {" "}
            Save
          </AppButton>
        </Box>
      </StyledModelFooter>
    </AppModel>
  );
};

export default AppFormQueryBuilder;
