import { createAsyncThunk } from "@reduxjs/toolkit";
import { WhatsappNetworkService } from "./WhatsappNetworkService";
import { templateDataToUserFormData } from "../../../Components/Broadcast/WhatsApp/Utils/utils";

export class WhatsappActions {
  //* GET ALL TEMPLATES LIST
  static getTemplatesListings = createAsyncThunk(
    "WhatsappSlice/getTemplatesListings",
    (data: any, { dispatch, rejectWithValue }: any) => {
      return WhatsappNetworkService.getWhatsappTemplatesListings(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * GET TEMPLATE BY ID
  static getTemplateById = createAsyncThunk(
    "WhatsappSlice/getTemplateById",
    (id: string, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.getTemplateById(id)
        .then(async (response: any) => {
          let data: any = templateDataToUserFormData(response?.data);
          return data;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * REMOVE TEMPLATE BY ID
  static removeTemplateById = createAsyncThunk(
    "WhatsappSlice/getTemplateById",
    ({ }: any, { dispatch, rejectWithValue }) => {
      return rejectWithValue(null);
    }
  );

  // * GET contactListData
  static getContactListData = createAsyncThunk(
    "WhatsappSlice/getContactListData",
    ({ }: any, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.getContactListData()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * postDummyContactData
  static postDummyContactData = createAsyncThunk(
    "WhatsappSlice/postDummyContactData",
    (data: any, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.postDummyContactData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* POST FILTERED DATA //
  static postFilteredData = createAsyncThunk(
    "WhatsappSlice/postFilteredData",
    (data: any, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.postFilteredData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  static removeFilteredData = createAsyncThunk(
    "WhatsappSlice/postFilteredData",
    ({ }: any, { dispatch, rejectWithValue }) => {
      return rejectWithValue(null);
    }
  );
  // * GET MARKETING BY ID
  static getExistingTempData = createAsyncThunk(
    "WhatsappSlice/getExistingTempData",
    (id: string, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.getExistingTempData(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * GET Broadcast BY ID
  static getBroadcastById = createAsyncThunk(
    "WhatsappSlice/getBroadcastById",
    (id: string, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.getBroadcastById(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // delete Broadcasts
  static deleteWhatsappBroadcastById = createAsyncThunk(
    "EmailSlice/deleteWhatsappBroadcastById",
    (data: any, { dispatch, rejectWithValue }) => {

      return WhatsappNetworkService.deleteWhatsappBroadcast(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //* POST Whatsapp Broadcast // 
  static postWhatsappBroadcast = createAsyncThunk(
    "WhatsappSlice/postWhatsappBroadcast",
    (data: any, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.postWhatsappBroadcast(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* getFieldsTypeMappingList // 
  static getFieldsTypeMappingList = createAsyncThunk(
    "WhatsappSlice/getFieldsTypeMappingList",
    (id: string, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.getFieldsTypeMappingList(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* testWhatsapp // 
  static postTestWhatsappMessage = createAsyncThunk(
    "WhatsappSlice/postTestWhatsappMessage",
    (data: any, { dispatch, rejectWithValue }) => {
      return WhatsappNetworkService.postTestWhatsappMessage(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
}
