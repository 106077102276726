import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../assets/images/tag-cross-icon.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/upload-icon.svg";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
const arrayContainsObject = <T extends Record<string, unknown>>(
  array: Array<any>,
  object: any
) => {
  return array.some(
    (item) => item.name === object.name && item.size === object.size
  );
};
const DragDropUpload = (props: any) => {
  const { sendToParent, resetFiles, setResetFiles } = props;
  const [dropActive, setDropActive] = useState<any>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  useEffect(() => {
    if (resetFiles) {
      setResetFiles(false);
      setSelectedFiles([]);
    }
  }, [resetFiles]);

  const onDragEnter = (ev: any) => {
    setDropActive(true);
  };

  const onDragOver = (ev: any) => {
    ev.preventDefault();
  };

  const onDragLeave = (ev: any) => {
    setDropActive(false);
  };

  const onDrop = (ev: any) => {
    ev.preventDefault();
    let tempArray: any = Array?.prototype?.map
      .call(ev?.dataTransfer?.files || ev?.target?.files, (file) => {
        if (!arrayContainsObject(selectedFiles, file)) {
          return file;
        }
      })
      ?.filter((file: any) => file?.type);

    if (tempArray.length > 0) {
      setSelectedFiles([...selectedFiles, ...tempArray]);
      sendToParent && sendToParent([...selectedFiles, ...tempArray]);
    }
    setDropActive(false);
  };

  const removeFiles = (file: any) => {
    setSelectedFiles(
      selectedFiles?.filter((ele: any) => ele.name !== file.name)
    );
    sendToParent &&
      sendToParent(selectedFiles?.filter((ele: any) => ele.name !== file.name));
  };
  return (
    <FileUploader>
      <div className={`file-label  ${dropActive ? "disabled" : ""}`}>
        <div
          className={`file-cta`}
          onDragEnter={onDragEnter}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <span className={`file-label`}>
            {!dropActive ? (
              <>
                <input
                  type="file"
                  value=""
                  name="images[]"
                  id="uploadFile"
                  style={{ opacity: "0", position: "absolute" }}
                  onChange={onDrop}
                  multiple
                />
                <label htmlFor="uploadFile">
                  {!dropActive ? "Drop file here..." : "Drop File"}
                  <UploadIcon />
                </label>
              </>
            ) : null}
          </span>
        </div>
        {selectedFiles?.length > 0 && (
          <span className="file-name" style={{ height: "auto" }}>
            <ul>
              {selectedFiles.map((file: any) => (
                <li title={file.name}>
                  <span>{file.name}</span>{" "}
                  <IconButton onClick={() => removeFiles(file)}>
                    <CloseIcon />
                  </IconButton>
                </li>
              ))}
            </ul>
          </span>
        )}
      </div>
    </FileUploader>
  );
};

export default DragDropUpload;

const FileUploader = styled.div`
  .file-cta {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    border: 1px dashed #cbcdd3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    text-align: center;
    line-height: 200px;
    position: relative;
    &.disabled {
      background: rgba(0, 0, 0, 0.3);
      * {
        color: ${theme.palette.default.white};
        pointer-events: none;
      }
    }
  }
  .mark-complete-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    padding-left: 16px;
  }
  .file-name {
    margin-top: 15px;
    display: block;
    ul {
      display: flex;
      gap: 15px;
      list-style: none;
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
    li {
      border: 1px solid ${theme.palette.default.border};
      display: flex;
      padding: 5px 0;
      border-radius: 15px;
      max-width: 100px;
      justify-content: center;
      align-items: center;
      gap: 3px;
      background: rgba(54, 84, 227, 0.12);
      span {
        max-width: calc(100% - 30px);
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        color: rgb(54, 84, 227);
      }
      button {
        padding: 0 !important;
        height: auto !important;
        min-height: 0 !important;
        font-size: 12px;
        svg path {
          fill: rgb(54, 84, 227);
        }
      }
    }
  }
`;
