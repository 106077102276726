import { createAsyncThunk } from "@reduxjs/toolkit";
import { QueryBuilderNetworkService } from "./QueryBuilderNetworkService";

export class QueryBuilderActions {
  // * get contact properties field options *//
  static getQueryBuilderFieldDynamicOptions = createAsyncThunk(
    "QueryBuilderSlice/getQueryBuilderFieldDynamicOptions",
    (data: any, { dispatch, rejectWithValue }) => {
      return QueryBuilderNetworkService.getQueryBuilderFieldDynamicOptions(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  // * get module properties field options *//
  static getQueryBuilderFieldDynamicOptionsAsPerModuleName = createAsyncThunk(
    "QueryBuilderSlice/getQueryBuilderFieldDynamicOptionsAsPerModuleName",
    (data: any, { dispatch, rejectWithValue }) => {
      return QueryBuilderNetworkService.getQueryBuilderFieldDynamicOptionsAsPerModuleName(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getQueryBuilderMappingFields = createAsyncThunk(
    "QueryBuilderSlice/getQueryBuilderMappingFields",
    (data: any, { dispatch, rejectWithValue }) => {
      return QueryBuilderNetworkService.getQueryBuilderMappingFields(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
}
