import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  AppForm,
  AppFormRadioField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import { device } from "../../../../../Customization/BreakPoints";
import {
  Label,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox,
} from "../../../../../Customization/CommonElements";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import {
  ChatbotConfiguration,
  DefaultMessages,
} from "../../../../../Models/ChatbotConfiguration";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import AppReactSelectField from "../../../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { useParams } from "react-router-dom";
import {
  fetchFlow,
  fetchFlows,
} from "../../../../../Modules/FlowBuilder/Slices/main.service";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { useFlows } from "../../../../../Modules/FlowBuilder/Slices/main.slice";
import { ReactComponent as DangerSVG } from "../../../../../assets/images/danger.svg";
interface Props extends LoaderComponentProps {}
const OptIn = (props: Props) => {
  const dispatch = useAppDispatch();
  const flowsList = useSelector(useFlows);
  const form: any = useRef();
  const { bot_id }: any = useParams();
  const currentBot = useSelector(useCurrentBot);
  let { showAlert } = useNotification();
  const botId = { botid: bot_id || currentBot.bot_id };

  const [subFlowList, setSubFlowList] = useState<any>([]);

  const [userInput, setUserInput] = useState<any>({
    isFlowLinked: "false",
    message:
      "Thanks for opt-in to WhatsApp communication, we are pleased to serve you.",
  });

  const onFlowChange = (values: any) => {
    if (values?.qid) {
      dispatch(fetchFlow({ botId, flowId: values?.qid }))
        .then((res: any) => {
          setSubFlowList(res?.payload);
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  };
  const onChangeCallbackFn = (values: any) => {
    if (values.value === "true" && flowsList.length === 0) {
      dispatch(fetchFlows({ botId }))
        .then((res: any) => {
          console.log(res?.data?.data);
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
    setUserInput({
      ...userInput,
      isFlowLinked: values.value,
    });
  };

  const getData = () => {
    if (
      currentBot?.chatbot_configuration &&
      currentBot?.chatbot_configuration
    ) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot?.chatbot_configuration as string,
      )
        .then((res: AxiosResponse<ChatbotConfiguration>) => {
          if (res.data?.enabled_devices) {
            const data: DefaultMessages | undefined | null =
              res.data?.default_messages;
            if (!data) {
              return;
            }
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setTimeout(() => {
            props.setLoading(false);
          }, 310);
        });
    } else {
      props.setLoading(false);
    }
  };

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    props.setLoading(true);

    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {},
      },
    )
      .then((response) => {
        SubmitProps.resetForm();
        showAlert("Configuration updated", "success");
        getData();
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error",
        );
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        props.setLoading(false);
      });
  };

  useEffect(() => {
    if (currentBot) {
      getData();
    }
  }, []);
  return (
    <SettingPageMainBoxWrap>
      <SettingPageMainBoxTopbar>
        <SettingPageMainBoxTitle>Opt In Management</SettingPageMainBoxTitle>
      </SettingPageMainBoxTopbar>

      <SettingPageMainContentBox
        style={{
          alignItems: "flex-start",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          marginBottom: "1rem",
        }}
      >
        <StyledFormBox>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            innerRef={form}
            // validationSchema={AutoRepliesSchema}
          >
            <GroupBox>
              <Label style={{ fontWeight: "500" }}>
                What would you like to do when user opt-in for WhatsApp
                communication? (Not applicable for already opt-in users)
              </Label>

              <AppFormRadioField
                name="isFlowLinked"
                sendOnlyValue={true}
                Options={[
                  {
                    label: "Welcome Message",
                    value: "false",
                  },
                  {
                    label: "Link to flow",
                    value: "true",
                  },
                ]}
                onChangeCallbackFn={onChangeCallbackFn}
              />
              {userInput?.isFlowLinked === "false" ? (
                <>
                  <AppFormTextArea
                    name="message"
                    count={true}
                    maxCount={1000}
                  />
                </>
              ) : (
                flowsList?.length && (
                  <>
                    <AppReactSelectField
                      displayKey="name"
                      valueKey="qid"
                      options={flowsList}
                      name={"flow"}
                      label="Select a Flow"
                      onChangeCallbackFn={onFlowChange}
                    />
                    {subFlowList && (
                      <AppReactSelectField
                        options={subFlowList}
                        name={"subFlow"}
                        label="Select a Node"
                        valueKey="nodeId"
                        displayKey="name"
                      />
                    )}
                  </>
                )
              )}
            </GroupBox>

            <AppSubmitButton
              variant="cta"
              style={{ width: "200px", margin: "0.8rem 0" }}
              title={"Save Changes"}
            />
          </AppForm>
        </StyledFormBox>
      </SettingPageMainContentBox>
    </SettingPageMainBoxWrap>
  );
};

export default withLoader(OptIn);

const StyledFormBox = styled.div`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .checkboxDiv {
      display: flex;
      gap: 1rem;
      margin-top: -0.5rem;
      flex-wrap: nowrap;
      @media ${device.laptop} {
        flex-wrap: wrap;
      }
    }
  }
`;

const GroupBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: 100%;
    margin-bottom: 1rem;
  }
`;
