import { Box, Modal, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from 'styled-components';
import AppButton from "../../../../../AppComponents/AppButton";
import { AppFormFastField } from "../../../../../AppComponents/Forms";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../../Store";
import { EmailActions } from "../../../../../Store/Slices/email/email.actions";
import { testEmailModel } from "../../../../../Store/Slices/email/email.selectors";
import { testEmailTemplateData } from "../../../../../Store/Slices/email/email.slice";
import { theme } from "../../../../../Customization/Theme";

interface Props {
    openModel?: boolean
}
interface StyledProps {
    isError?: any;
}

const TestEmailTemplate: React.FC<Props> = ({
    openModel = false
}) => {
    const dispatch = useAppDispatch()
    let { showAlert } = useNotification();
    const editedHtml = useSelector(testEmailModel);
    const [recipient, setRecipient] = useState<any>([]);
    const { values, setFieldValue } = useFormikContext<any>();
    const style = {
        position: 'fixed' as 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "662px",
        height: "auto",
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: "15px",
    };
    const notesList = [
        "if multiple recipients, separate the email addresses by a comma.",
        // "the email tags will be parsed and we will pick a random subscriber to impersonate.",
        // "the tracking will not be enabled.",
        "make sure you save the template changes before you send the test.",
        "credits will be deducted according to the number of emails sent for this test"
    ]
    const validateEmail = (email: any) => {
        let status = false;
        email?.map((res: any) => {
            if (String(res)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
                status = true
            } else {
                status = false
            }
        })
        return status
    };
    const addEmail = () => {
        if (recipient?.length > 0 && validateEmail(recipient?.split(','))) {
            dispatch(testEmailTemplateData(false))
            let payload = {
                template_body: JSON.stringify(localStorage.getItem("HTMLTemplate")),
                from_email: values?.from_email,
                email: recipient?.split(','),
                subject: values?.subject,
                email_attachment: values?.email_attachment?.FileName
            };
            dispatch(EmailActions.postTestEmailTemplate(payload))
                .then((res: any) => {
                    if (res?.payload?.data?.status) {
                        showAlert(
                            res?.payload?.data?.msg,
                            "success"
                        );
                    } else {
                        showAlert(
                            res?.payload?.data?.error || "Unable to send test email please try again!",
                            "error"
                        );
                    }

                }).catch((error: any) => {
                    showAlert(
                        "Template Name Already Exist",
                        "error"
                    );
                }).finally(() => {
                    setRecipient([])
                });
        } else {
            showAlert(
                "Please Enter Valid Email",
                "error"
            );
        }
    }
    useEffect(() => {
        setFieldValue("recipient", [])
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal open={editedHtml} >
            <Box sx={style}>
                <Typography variant="body1" gutterBottom >Send a test email</Typography>
                <TestEmailNots>
                    Notes:
                    <TestEmailNoteList>
                        <ul className="list">
                            {notesList.map((list: any, index: number) => {
                                return <li key={"list_" + index.toString()}>{list}</li>
                            })}
                        </ul>
                    </TestEmailNoteList>
                </TestEmailNots>
                <AppFormFastField
                    name="from_email"
                    label="From email (optional)"
                    disabled={true}
                />
                <AppFormFastField
                    name="subject"
                    label="Subject"
                    disabled={true}
                />
                <Label>Recipient (s)</Label>
                <Input
                    rows={5}
                    placeholder={"i.e: a@domain.com, b@domain.com, c@domain.com"}
                    onChange={(e: any) => setRecipient(e?.target?.value)}
                />

                <TestEmailControlOpration>
                    <AppButton
                        variant="grey"
                        onClick={() => dispatch(testEmailTemplateData(false))}
                    >
                        Cancel
                    </AppButton>

                    <Box display="flex">
                        <AppButton
                            variant="cta"
                            endIcon="Send"
                            onClick={() => addEmail()}
                        >Send</AppButton>
                    </Box>
                </TestEmailControlOpration>
            </Box>
        </Modal>
    )
}

const TestEmailNots = styled.div`
    height: auto;
    padding: 13px 18px 23px 19px;
    border-radius: 4px;
    background: rgba(91, 115, 232, 0.15);
`
const TestEmailNoteList = styled.div`
    padding-left: 2rem;
`
const TestEmailControlOpration = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;
`
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;

const Input = styled.textarea<StyledProps>`
  && {
    width: 100%;
    resize: none;
    background-color: #f5f6f8;
    max-height: 200px;
    border-radius: 4px;
    outline: none !important;
    padding: 0.5rem 2rem 0.5rem 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    border: 2px solid transparent;

    &::placeholder {
      color: ${theme.palette.default.darkGrey};
    }

    &:active {
      border: 2px solid ${theme.palette.default.border};
      color: ${theme.palette.default.text};
    }
    &:focus {
      border: 2px solid ${theme.palette.default.border} !important;
      color: ${theme.palette.default.text};
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`;


export default TestEmailTemplate