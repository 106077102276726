import { createSlice } from "@reduxjs/toolkit";
import { contactListActions } from "./ContactList.actions";

interface initialStateTypes {
  data: {
    allContactListData: any;
    allVisibleContactPropertiesData: any;
    allContactfieldsData: any;
    allFieldsOptions: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    allContactListData: null,
    allVisibleContactPropertiesData: [],
    allContactfieldsData: null,
    allFieldsOptions:[],
  },
  error: "",
  loading: false,
};

const ContactListSlice = createSlice({
  name: "ContactListSlice",
  initialState,
  reducers: {
    setAllFieldsOptions: (state, action) => {
      state.data.allFieldsOptions = [...state.data.allFieldsOptions,...action.payload];
    }
  },
  extraReducers: (builder) => {
    builder

      // Get Contact List by Id
      .addCase(
        contactListActions.getContactPropertiesFieldOptions.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        contactListActions.getContactPropertiesFieldOptions.fulfilled,
        (state, action) => {
          state.data.allContactListData = action.payload?.data?.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        contactListActions.getContactPropertiesFieldOptions.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allContactListData = null;
          state.error = action?.error?.message || "";
        }
      )



      .addCase(
        contactListActions.getAllVisibleContactPropertiesFieldOptions.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        contactListActions.getAllVisibleContactPropertiesFieldOptions.fulfilled,
        (state, action) => {
          state.data.allVisibleContactPropertiesData = action.payload.data?.data;
          state.loading = false;
        }
      )
      .addCase(
        contactListActions.getAllVisibleContactPropertiesFieldOptions.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allVisibleContactPropertiesData = null;
          state.error = action?.error?.message || "";
        }
      )
      // Get Contact fields list
      .addCase(
        contactListActions.getContactPropertiesList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        contactListActions.getContactPropertiesList.fulfilled,
        (state, action) => {
          state.data.allContactfieldsData = action.payload?.data?.data;
          //console.log("allContactfieldsData",state.data.allContactfieldsData)
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        contactListActions.getContactPropertiesList.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allContactfieldsData = null;
          state.error = action?.error?.message || "";
        }
      )




  },
});

export const { setAllFieldsOptions } = ContactListSlice.actions;

export default ContactListSlice.reducer;
