import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { Wrappertable } from "./RecipientsStyle";
import styled from "styled-components";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import NoRecordImage from "../../../../assets/images/norecordsanalytics.svg";
interface Props {
  broadcastDetails?: any;
}

const EmailRecipientsTable: React.FC<Props> = ({ broadcastDetails }) => {

  const hasData = (
    broadcastDetails &&
    (broadcastDetails.subject || broadcastDetails.from_email || broadcastDetails.from_name !== null && broadcastDetails.from_name !== 0)
  ) ? true : false;

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#ffffff",
      color: 'rgba(0, 0, 0, 0.87)',
       boxShadow: "1px 1px 1px 1px rgba(99, 99, 99, 0.2)",
      fontSize: 11,
      
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: "white",
      "&::before": {
      backgroundColor: "white",
      border: "1px solid rgba(99, 99, 99, 0.2)",
    }
    },
  }));

  return (
    <Wrappertable>
      {
        hasData ? (
          <>
            <div className="roww_table">
              <p className="text">From</p>
              <LightTooltip title="Geta labs" arrow placement="top-start">
                <p className="value">Geta labs</p>
              </LightTooltip>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table">
              <p className="text">Email Subject</p>
              <LightTooltip title={broadcastDetails?.subject} arrow placement="top-start">
                <p className="value">{broadcastDetails?.subject || "N/A"}</p>
              </LightTooltip>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table">
              <p className="text">From email</p>
              <LightTooltip title={broadcastDetails?.from_email} arrow placement="top-start">
                <p className="value">{broadcastDetails?.from_email || "N/A"}</p>
              </LightTooltip>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table_last">
              <p className="text">From name</p>
              <p className="value">{broadcastDetails?.from_name === 0 ? "N/A" : broadcastDetails?.from_name}</p>
            </div>
          </>
        ) : (
            <NoRecordsFound
              imageSrc={NoRecordImage}
              imageWidth={"180px"}
              headerTextSize={"19px"}
            />
        )
      }
    </Wrappertable>
  );
};

export default EmailRecipientsTable;
