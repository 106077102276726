import { IconButton } from "@mui/material";
import React from "react";
import { theme } from "../../../Customization/Theme";
import {
  StyledFileContainerBox,
  StyledMessageText,
  StyledReactVideoPlayer,
  StyledSlickSlider,
  StyledTyping,
  WidgetSliderBox,
} from "../../Styles/StyledComponents";
import { AppMaterialIcons } from "../Icons";
import AppSmallButton from "../UtilsComponents/AppSmallButtons";
import { MESSAGE_TYPES } from "../../utils/Enum";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface RenderTextProps {
  text: string;
  [key: string]: any;
}

const RenderText = React.memo(({ text, ...otherProps }: RenderTextProps) => {
  if (!text) return null;
  return (
  //   <StyledMessageText
  //   userMessage={false}
  //   dangerouslySetInnerHTML={{
  //     __html: text,
  //   }}
  //   {...otherProps}
  // />
    <p
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      {...otherProps}
    />
  );
});

interface RenderInfoProps {
  text: string;
  [key: string]: any;
}

const RenderInfo = React.memo(({ text, ...otherProps }: RenderInfoProps) => {
  if (!text) return null;

  return (
    <p
      dangerouslySetInnerHTML={{
        __html: text,
      }}
      {...otherProps}
    />
  );
});

interface RenderPrivateNoteProps {
  text: string;
  [key: string]: any;
}

const RenderPrivateNote = React.memo(
  ({ text, ...otherProps }: RenderPrivateNoteProps) => {
    if (!text) return null;

    return (
      <p
        dangerouslySetInnerHTML={{
          __html: text,
        }}
        style={{
          backgroundColor: "rgba(243, 218, 88, 0.25)",
          color: `${theme.palette.default.black}`,
          fontSize: "14px",
          padding: "8px 12px",
        }}
        {...otherProps}
      />
    );
  }
);

interface RenderImageProps {
  src: string;
  setIsImageLoaded: any;
  [key: string]: any;
}

const RenderImage = React.memo(
  ({ src, setIsImageLoaded, ...otherProps }: RenderImageProps) => {
    if (!src) return null;

    return (
      <img
        src={src}
        alt={"whatsapp interactive"}
        onClick={() => window.open(src, "_blank")}
        onLoad={() => {
          setIsImageLoaded && setIsImageLoaded(true);
        }}
        onError={(error: any) => {
          error.target.src = FallbackImage;
        }}
        {...otherProps}
      />
    );
  }
);

interface RenderVideoProps {
  src: string;
  setIsImageLoaded: any;
  [key: string]: any;
}

const RenderVideo = React.memo(
  ({ src, setIsImageLoaded, ...otherProps }: RenderVideoProps) => {
    if (!src) return null;

    return (
      <StyledReactVideoPlayer
        url={src}
        width={"100%"}
        controls={true}
        light={false}
        alt={"whatsapp_interactive_video"}
        onLoad={() => setIsImageLoaded && setIsImageLoaded(true)}
        {...otherProps}
      />
    );
  }
);

interface RenderAudioProps {
  src: string;
  setIsImageLoaded: any;
  [key: string]: any;
}

const RenderAudio = React.memo(
  ({ src, setIsImageLoaded, ...otherProps }: RenderAudioProps) => {
    if (!src) return null;

    return (
      <audio
        src={src}
        controls
        autoPlay={false}
        style={{ width: "100%" }}
        {...otherProps}
        onLoad={() => setIsImageLoaded && setIsImageLoaded(true)}
      />
    );
  }
);

interface RenderDocumentProps {
  src: string;
  name?: string;
  [key: string]: any;
}

const RenderDocument = React.memo(
  ({ src, name = "Document", ...otherProps }: RenderDocumentProps) => {
    if (!src) return null;

    return (
      <StyledFileContainerBox
        href={src}
        target="_blank"
        rel="noopener,noreferrer"
        {...otherProps}
      >
        <div className="fileActionBox">
          <AppMaterialIcons
            iconName="FilePresent"
            style={{ color: theme.palette.default.darkGrey }}
          />
        </div>
        <div className="fileNameBox">{name}</div>
        <IconButton title={name || "Download"}>
          <AppMaterialIcons
            iconName="DownloadForOffline"
            style={{ fontSize: "1.5rem" }}
          />
        </IconButton>
      </StyledFileContainerBox>
    );
  }
);

interface RenderFlowButtonProps {
  item: any;
  [key: string]: any;
}

const RenderFlowButton = React.memo(
  ({ item, ...otherProps }: RenderFlowButtonProps) => {
    if (!item?.value) return null;

    return (
      <AppSmallButton variant="outline" {...otherProps}>
        {item?.value?.text}
      </AppSmallButton>
    );
  }
);

const RenderLiveChatButton = React.memo(
  ({ item, ...otherProps }: RenderFlowButtonProps) => {
    if (!item?.value) return null;

    return (
      <AppSmallButton variant="outline" {...otherProps}>
        {item?.value?.text}
      </AppSmallButton>
    );
  }
);

const RenderOptOutButton = React.memo(
  ({ item, ...otherProps }: RenderFlowButtonProps) => {
    if (!item?.value) return null;

    return (
      <AppSmallButton variant="outline" {...otherProps}>
        {item?.value?.text}
      </AppSmallButton>
    );
  }
);

const RenderCTALinkButton = React.memo(
  ({ item, ...otherProps }: RenderFlowButtonProps) => {
    if (!item?.value) return null;

    let finalUrl: string = item?.value?.url;
    if (
      !item?.value?.url.includes("https://") &&
      !item?.value?.url.includes("Https://") &&
      !item?.value?.url.includes("http://")
    ) {
      finalUrl = "https://" + item?.value?.url;
    }
    return (
      <AppSmallButton {...otherProps}>
        <a href={`${finalUrl}`} target="_blank" rel="noreferrer">
          <AppMaterialIcons iconName="OpenInNew" />
          {item?.value?.text ?? item?.value?.url}
        </a>
      </AppSmallButton>
    );
  }
);

const RenderCTACallButton = React.memo(
  ({ item, ...otherProps }: RenderFlowButtonProps) => {
    if (!item?.value) return null;

    return (
      <AppSmallButton {...otherProps}>
        <a href={`tel:${item?.value?.url}`} target="_blank" rel="noreferrer">
          <AppMaterialIcons iconName="AddIcCall" />
          {item?.value?.text ?? item?.value?.url}
        </a>
      </AppSmallButton>
    );
  }
);

const RenderCTAEmailButton = React.memo(
  ({ item, ...otherProps }: RenderFlowButtonProps) => {
    if (!item?.value) return null;

    return (
      <AppSmallButton {...otherProps}>
        <a
          href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${item?.value?.url}`}
          target="_blank"
          rel="noreferrer"
        >
          <AppMaterialIcons iconName="AlternateEmail" />
          {item?.value?.text ?? item?.value?.url}
        </a>
      </AppSmallButton>
    );
  }
);

interface RenderTypingProps {
  userMessage?: any;
  [otherProps: string]: any;
}

const RenderTyping = React.memo(({ userMessage }: RenderTypingProps) => {
  return (
    <StyledTyping userMessage={userMessage}>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </StyledTyping>
  );
});

const RendeCarouselSlider = React.memo((items: any) => {
  if (!items || items?.length === 0) return null;

  return (
    <StyledSlickSlider {...settings} className="getaChatMessageTextBox">
      {items?.length &&
        items?.map((item: any, index: number) => {
          return (
            <WidgetSliderBox key={index.toString()}>
              {item?.image ? (
                <RenderImage
                  setIsImageLoaded={() => undefined}
                  key={index.toString()}
                  src={item?.image}
                  alt={"CarouselSlider_" + (index + 1)}
                  className="sliderImage"
                />
              ) : null}

              <RenderText
                className="title getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
                text={item?.title}
              />
              <RenderText
                className="subTitle getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
                text={item?.subTitle}
              />
              <RenderText
                className="description getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
                text={item?.text}
              />

              <div className="btnWrapper">
                {item?.buttons?.map((curBtn: any, index: number) => {
                  //* FLOW BUTTONS
                  if (curBtn?.type === MESSAGE_TYPES.FLOW) {
                    return (
                      <RenderFlowButton item={curBtn} key={index.toString()} />
                    );
                  }

                  //* CTA BUTTONS
                  if (curBtn?.type === MESSAGE_TYPES.LINK) {
                    return (
                      <RenderCTALinkButton
                        item={curBtn}
                        key={index.toString()}
                      />
                    );
                  }

                  if (curBtn?.type === MESSAGE_TYPES.MOBILE) {
                    return (
                      <RenderCTACallButton
                        item={curBtn}
                        key={index.toString()}
                      />
                    );
                  }
                  if (curBtn?.type === MESSAGE_TYPES.EMAIL) {
                    return (
                      <RenderCTAEmailButton
                        item={curBtn}
                        key={index.toString()}
                      />
                    );
                  }

                  //* LIVE BUTTONS
                  if (curBtn?.type === MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
                    return (
                      <RenderLiveChatButton
                        item={curBtn}
                        key={index.toString()}
                      />
                    );
                  }

                  return null;
                })}
              </div>
            </WidgetSliderBox>
          );
        })}
    </StyledSlickSlider>
  );
});

//EXTRA COMPONENTS
const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      className={`${className} nextPreBtn`}
      style={{ ...style }}
    >
      <AppMaterialIcons iconName={"ChevronRight"} />
    </IconButton>
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      className={`${className} nextPreBtn`}
      style={{ ...style }}
    >
      <AppMaterialIcons iconName={"ChevronLeft"} />
    </IconButton>
  );
};

const settings = {
  className: "slickSlider",
  adaptiveHeight: true,
  lazyLoad: true,
  fade: true,
  dots: true,
  infinite: true,
  speed: 500,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  pauseOnHover: true,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,

  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};

export {
  RenderCTACallButton,
  RenderCTAEmailButton,
  RenderCTALinkButton,
  RenderDocument,
  RenderFlowButton,
  RenderImage,
  RenderLiveChatButton,
  RenderOptOutButton,
  RenderText,
  RenderVideo,
  RenderAudio,
  RendeCarouselSlider,
  RenderTyping,
  RenderInfo,
  RenderPrivateNote,
};
