import React from "react";
import {
  StyledInfoMessageText,
  StyledMessageRow,
  StyledMessageTimeStamps,
} from "./../../Styles/StyledComponents";
import { getMessageTime } from "../../../Utils";
import styled from "styled-components";

interface Props {
  userMessage: boolean;
  children: any;
  time: string;
  msgId: string;
  [otherProps: string]: any;
}
const AppRenderInfo: React.FC<Props> = React.memo(
  ({ userMessage = true, time, children, msgId,...otherProps }) => {
    if (!children || children?.length < 1 || userMessage) {
      return null;
    }

    return (
      <ExtendedStyledMessageRow >
        <StyledInfoMessageText
          dangerouslySetInnerHTML={{
            __html: children,
          }}
          style={{ justifyContent: "center", alignItems: "center", flexDirection: "column" }}
        />
        <StyledMessageTimeStamps
          userMessage={userMessage}
          style={{ justifyContent: "center" }}
        >
          {getMessageTime(time)}
        </StyledMessageTimeStamps>
      </ExtendedStyledMessageRow>
    );
  }
);

export default AppRenderInfo;

const ExtendedStyledMessageRow = styled(StyledMessageRow)`
  && {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    border: none;
  }
`;
