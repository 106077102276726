export const UserDetailRightMenu = [
  {
    id: 0,
    avatar: "ri-user-line",
    name: "Contact Details",
    moduleId: "conversation_inbox"

  },
  {
    id: 1,
    avatar: "ri-file-edit-line",
    name: "Add Notes",
    disabled: false,
    moduleId: "can_add_notes"
  },
  {
    id: 2,
    avatar: "ri-time-line",
    name: "Previous Conversations",
    moduleId: "conversation_inbox"
  },
];