import { Box, Modal, Typography } from "@mui/material";
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { AppFormFastField, AppFormTextArea } from "../../../../../AppComponents/Forms"
import { useFormikContext } from "formik";
import { EmailActions } from "../../../../../Store/Slices/email/email.actions";
import { useAppDispatch } from "../../../../../Store";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useEffect } from "react";
import { testSMSModel } from "../../../../../Store/Slices/SMS/sms.selectors";
import { testSMSData } from "../../../../../Store/Slices/SMS/sms.slice";
import AppButton from "../../../../../AppComponents/AppButton";
import { SMSActions } from "../../../../../Store/Slices/SMS/sms.actions";
import { LocalStorage } from "../../../../../Utils";

const localStorage = new LocalStorage();

interface Props {
    openModel?: boolean
}
const TestSMSTemplate: React.FC<Props> = ({
    openModel = false
}) => {
    const dispatch = useAppDispatch()
    let { showAlert } = useNotification();
    const editedPhone = useSelector(testSMSModel);

    const { values, setFieldValue } = useFormikContext<any>();

    const style = {
        position: 'fixed' as 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "662px",
        height: "auto",
        bgcolor: 'background.paper',
        boxShadow: 24,
        padding: "15px",
    };
    const notesList = [
        "If multiple recipients, separate the phone no. by a comma.",
        "The tracking will not be enabled.",
        "Credits will be deducted according to the number of sms sent for this test",
        "Phone no. should be in these format i.e +91xxxxxxxx,+91xxxxxxxx ( shouldn't be any space between two consecutive numbers )"
    ]
    const validatePhone = (phone :any) => {
        let status = false ;
        phone?.map((res:any) => {
            if(String(res)
            .toLowerCase()
            .match(
                /^\+\+?(\d{1,4})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}$/
            )){
                status = true
            }else{
                status = false
            }
        })
        return status
      };
    
    const getBroadcastData = () => {
        let stringValue = localStorage.get("BROADCAST_SMS_FORM_DATA")
        if (stringValue && typeof(stringValue) == 'string') {
            return JSON.parse(stringValue);
        }
        return stringValue;
    }

    const addPhone = () => {
        let broadcastData = getBroadcastData();
        if(values?.recipient?.length > 0 && validatePhone(values?.recipient?.split(','))){
            dispatch(testSMSData(false))
            let payload = {
                broadcast_id:broadcastData?.id,
                template_id: broadcastData?.broadcast_template_id,
                phone_no: values.recipient?.split(','),
            };
    
            dispatch(SMSActions.postTestSMS(payload))
                .then((res: any) => {
                    if (res?.payload?.data?.status) {
                        showAlert(
                            res?.payload?.data?.message,
                            "success"
                        );
                        values.recipient = ""
                    } else {
                        showAlert(
                            res?.payload?.data?.error || "Unable to send SMS at the moment please try again later",
                            "error"
                        );
                    }
    
                })
                .catch((error: any) => {
                    showAlert(
                        "Template Name Already Exist",
                        "error"
                    );
                })
                .finally(() => {
                });
        }else{
            showAlert(
                "Please Enter Valid SMS Number",
                "error"
            );
        }
    }
    useEffect(() => {
        setFieldValue("recipient", [])
    },[])    // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <>
            <h1>{editedPhone}</h1>
            <Modal open={editedPhone} >
                <Box sx={style}>
                    <Typography variant="body1" gutterBottom >Send a test sms</Typography>
                    <TestSMSNots>
                        Notes:
                        <TestSMSNoteList>
                            <ul className="list">
                                {notesList.map((list: any, index: number) => {
                                    return <li key={"list_" + index.toString()}>{list}</li>
                                })}
                            </ul>
                        </TestSMSNoteList>
                    </TestSMSNots>
                    <AppFormTextArea
                        name={"recipient"}
                        label="Recipient (s)"
                        placeholder={"i.e: +91xxxxxxxx,+91xxxxxxxx,+91xxxxxxxx"}
                        rows={5}
                    />
                    <TestSMSControlOpration>
                        <AppButton
                            variant="grey"
                            onClick={() => dispatch(testSMSData(false))}
                        >
                            Cancel
                        </AppButton>

                        <Box display="flex">
                        <AppButton
                            variant="cta"
                            onClick={() => addPhone()}
                            endIcon="Send"
                            >
                            Send
                        </AppButton>
                            </Box>
                    </TestSMSControlOpration>
                </Box>
            </Modal>
        </>
    )
}

const TestSMSNots = styled.div`
    height: auto;
    padding: 13px 18px 23px 19px;
    border-radius: 4px;
    background: rgba(91, 115, 232, 0.15);
`
const TestSMSNoteList = styled.div`
    padding-left: 2rem;
`
const TestSMSControlOpration = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
gap: 0.8rem;
`
export default TestSMSTemplate