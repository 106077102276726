import {
  setActiveNode,
  updateModifiedData,
  setUnSavedNodeId,
  setQuickAction,
  setButtonsList,
  setDateConfiguration,
  setTimeConfiguration,
  setCarouselData,
} from "../Slices/main.slice";

type Extension = { label: string; value: string };

type AllowedExnts = {
  type: string;
  extensions: Extension[];
};

export const getInputTypes = (
  type: string,
  inputValues?: { type: string; value: string | AllowedExnts[] }
) => {
  switch (type) {
    case "name":
      return { input: true, input_type: "NAME" };
    case "email":
      return { input: true, input_type: "EMAIL" };
    case "phone-number":
      return { input: true, input_type: "MOBILE" };
    case "transfer_to_faqs":
      return { input: true, input_type: "TRANSFER_TO_FAQS" };
    case "otp":
      return { input: true, input_type: "OTP" };
    case "alphanumeric":
      return { input: true, input_type: "ALPHANUMERIC" };
    case "number":
      return { input: true, input_type: "NUMBER" };
    case "img-file":
      return {
        input: true,
        input_type: "FILE_UPLOAD",
        ...(inputValues?.type === "img-file" && {
          allowedFileTypes: inputValues.value,
        }),
      };
    case "textfield":
      return { input: true, input_type: "TEXT" };
    case "date":
      return { input: true, input_type: "DATE" };
    case "time":
      return { input: true, input_type: "TIME" };
    case "custom":
      return {
        input: true,
        input_type: "CUSTOM",
        ...(inputValues?.type === "custom" && {
          customRegex: inputValues.value,
        }),
      };
    case "dropdown":
      return { input: true, input_type: "dropdown" };
  }
};

export const reset = (dispatch: any) => {
  dispatch(setActiveNode(null));
  dispatch(updateModifiedData([]));
  dispatch(setUnSavedNodeId(null));
  dispatch(setQuickAction([]));
  dispatch(setButtonsList([]));
  dispatch(setDateConfiguration({}));
  dispatch(setTimeConfiguration({}));
  dispatch(setCarouselData([]));
};
