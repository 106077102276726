import React, { memo } from "react";
import { useAppSelector } from "../../../../../Store";
import { allMessagesStore } from "../../../../../Store/Slices/socket/AllMessagesSlice";
import { LiveChatData } from "../../../../../Store/Slices/socket/liveChat/chat.selectors";
import AppFlowWebLiveChat from "./AppFlowWebLiveChat";
import WhatsappChooseTemplateBtn from "./WhatsappChooseTemplate/WhatsappChooseTemplateBtn";

interface Props {
  name?: string;
  label?: string;
  toolbarOptions?: string[];
  disabled?: boolean;
  isPrivateNoteShown?: boolean;
  [otherProps: string]: any;
}

const AppFlowEditor: React.FC<Props> = ({
  name,
  label,
  toolbarOptions,
  disabled = false,
  isPrivateNoteShown=false,
  ...otherProps
}) => {
  const { userInfoData } = useAppSelector(allMessagesStore);

  if (isPrivateNoteShown) {
    return (
      <AppFlowWebLiveChat
        name={name}
        label={label}
        toolbarOptions={["BOLD", "ITALIC", "STRIKETHROUGH", "EMOJI"]}
        disabled={disabled}
        {...otherProps}
      />
    );
  }

  if (userInfoData?.channel === "whatsapp" && userInfoData?.whatsapp_window) {
      return (
        <AppFlowWebLiveChat
        name={name}
        label={label}
        toolbarOptions={[
          "BOLD",
          "ITALIC",
          "STRIKETHROUGH",
          "EMOJI",
          "MEDIA",
        ]}
        disabled={disabled}
        {...otherProps}
        /> 
        );

  }

  return (
    <AppFlowWebLiveChat
      name={name}
      label={label}
      toolbarOptions={[
        "BOLD",
        "ITALIC",
        "UNDERLINE",
        "STRIKETHROUGH",
        "unordered-list-item",
        "ordered-list-item",
        "EMOJI",
        "MEDIA",
      ]}
      disabled={disabled}
      {...otherProps}
    />
  );
};

export default memo(AppFlowEditor);
