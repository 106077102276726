import { RootState } from "../../index";

export const marketingListStore = (state: RootState) => state.marketing.data;
export const isLoading = (state: RootState) => state.marketing.loading;

export const ContactsSelectData = (state: RootState) =>
  state.marketing.data.contactsSelectData;
export const importHistoryData = (state: RootState) =>
  state.marketing.data.importHistoryData;
export const fileDownloadUrlData = (state: RootState) =>
  state.marketing.data.fileDownloadUrlData;
export const importHistoryProgressData = (state: RootState) =>
  state.marketing.data.importHistoryProgressData;

export const AllMarketingListData = (state: RootState) => {
  return state.marketing.data.allMarketingListData;
};

// Form Step Values
export const SaveFormStepsValues = (state: RootState) => {
  return state?.marketing?.data?.saveFormValues;
};

export const userListigData = (state: RootState) =>
  state.marketing.data?.allUserListing;
export const userListingCount = (state: RootState) =>
  state.marketing.data?.allUserListingCount;

export const importFormStepOneRes = (state: RootState) =>
  state.marketing.data?.ImportFormStepOneRes;

export const GetImportID = (state: RootState) => state.marketing.data?.ImportID;

export const getSequenceList = (state: RootState) =>
  state.marketing.data?.sequence;

export const broadcastByIdData = (state: RootState) =>
  state.marketing.data.broadcastByIdData;
