import { Box, Divider, IconButton, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm, AppSubmitButton } from "../../../../AppComponents/Forms";
import AppFormCreateAbleSelect from "../../../../AppComponents/Forms/AppFormCreateAbleSelect";
import {
  ProtectedAppButton
} from "../../../../AppComponents/ProtectedComponents";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import useWindowSize from "../../../../Hooks/useWindowSize";
import { BulkAddTags } from "../../../../Models/ValidationSchema";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { ContactsActions } from "../../../../Store/Slices/Contacts/contacts.action";
import {
  ContactBasicInfo,
  ContactsTagsList,
  SingleContactTagsList,
} from "../../../../Store/Slices/Contacts/contacts.selector";
import { ReactComponent as EditIcon } from "../../../../assets/images/icons/pencil.svg";
import { ReactComponent as TagCrossIcon } from "../../../../assets/images/tag-cross-icon.svg";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { ModalUI } from "../SubPages/BasicInformation/LeadsDetailsPage";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../../HOC/HocBackdropLoader";
interface Props extends HocBackdropLoaderProps {
  isLoadingData?: any;
}
const TagAssignmentWrapper : React.FC<Props> = ({
  isLoadingData,
  setLoading
}) => {
  const params = useParams();
  const { contact_id } = params;
  const basicInformation: any = useAppSelector(ContactBasicInfo);
  const windowDimentions = useWindowSize();
  const formRef: any = useRef();
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // form name key
  const formkey9 = "tags";
  // tag list data from redux
  const TagsList: any = useAppSelector(ContactsTagsList);
  const SingleContactTagList: any = useAppSelector(SingleContactTagsList);
  // console.log
  // states
  const [userInput, setUserInput] = useState<any>({ tags: [] });
  const [taglistData, setTagListData] = useState<any[]>([]);
  const [isEditMode, setEditMode] = useState<boolean>(false);

  const filterContactTags = async (array: any) => {
    let tempList: Array<{ value: string; label: string }> = [];
    let tempArray = array?.length > 0 ? TagsList : [];
    // ? TagsList.filter(function (obj: any) {
    //     return !SingleContactTagList.find(
    //       (single: any) => single.tag_title === obj.tag_title
    //     );
    //   })
    // : [];
    array.forEach((el: any) => {
      tempList.push({
        value: el.id,
        label: el.tag_title,
      });
    });
    await setTagListData(tempList);
  };

  // get list data
  useEffect(() => {
    getTagListCall();
    getTagListCallByID();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // convert object
  useEffect(() => {
    if (TagsList && TagsList?.length) {
      let tempArray = TagsList.filter(
        (obj: any) => !SingleContactTagList.some(({ id }: any) => obj.id === id)
      );
      filterContactTags(tempArray);
    }
    if (SingleContactTagList?.length) {
      let tempArray: any = SingleContactTagList.map((ele: any) => {
        return { value: ele.id, label: ele.tag_title };
      });
      setUserInput({
        tags: tempArray,
      });
    } else {
      setUserInput({
        tags: [],
      });
    }
  }, [TagsList, SingleContactTagList]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTagListCall = () => {
    dispatch(ContactsActions.getContactsTagsList({}))
      .then(() => { })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => { });
  };
  const getTagListCallByID = () => {
    dispatch(ContactsActions.getContactsTagsListByID({ id: contact_id }))
      .then(() => { })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => { });
  };
  // post call for bulk add tags
  const handleSubmitBtn = (values: any, submitProps: any) => {
    submitProps.setSubmitting(true);
    let payLoad: any = {
      contactId_list: [contact_id],
      selectAll: false,
    };

    let tagsValue = values?.tags;
    const new_tags = tagsValue
      .filter((item: any) => item?.label === item?.value)
      .map((item: any) => item?.label);
    const existing_tags = tagsValue
      .filter((item: any) => item?.label !== item?.value)
      .map((ele: any) => ele.value);
    payLoad["existing_tags"] = existing_tags;
    payLoad["new_tags"] = new_tags;
    payLoad["operation"] = "replace";
    setLoading(true)
    dispatch(ContactsActions.addTagsList(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Unable to add tags",
            "error"
          );
        } else {
          submitProps.setSubmitting(false);
          showAlert(
            res?.payload?.data?.message || "Unable to add tags",
            "success"
          );
          setEditMode(false);
          formRef?.current?.resetForm();
          setUserInput({ tags: [] });
          getTagListCall();
          getTagListCallByID();
          setLoading(false)

        }
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Unable to add tags", "error");
      })
      .finally(() => {
        submitProps.setSubmitting(false);
        setLoading(false)
      });
  };
  const RemoveTags = (tagID: string) => {
    let payLoad: any = {
      contactId_list: [contact_id],
      selectAll: false,
    };
    const existing_tags = [tagID];
    payLoad["existing_tags"] = existing_tags;
    payLoad["operation"] = "remove";
    dispatch(ContactsActions.addTagsList(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Unable to remove tag",
            "error"
          );
        } else {
          getTagListCallByID();
          showAlert(
            res?.payload?.data?.message || "Tag removed successfully",
            "success"
          );
        }
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Unable to add tags", "error");
      });
  };
  return (
    <AssignTagWrapperUI className="tagsWrapper">
      <AppModel
        isShowing={isEditMode}
        onClose={() => { }}
        divStyle={{
          width: "671px",
          //   height:'371px',
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <ModalUI>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Edit Contact Tags</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => setEditMode(false)}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <AppForm
            initialValues={userInput}
            innerRef={formRef}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
              setUserInput(values);
            }}
            validationSchema={BulkAddTags}
          >
            <Box sx={{ flex: 1, p: 1 }}>
              <AppFormCreateAbleSelect
                menuPosition="fixed"
                label="Tags"
                isMulti={true}
                name={formkey9}
                options={taglistData}
                displayKey="label"
                valueKey="value"
              />
            </Box>
            <Divider sx={{ mt: 0.2 }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 7px",
                gap: "6px",
              }}
            >
              <AppButton
                style={{ width: "70px" }}
                variant="outline"
                startIcon="add"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </AppButton>
              <AppSubmitButton title={"Save"}></AppSubmitButton>
            </Box>
          </AppForm>
        </ModalUI>
      </AppModel>
      {!isEditMode && (
        <div className="section">
          {!isLoadingData ? (
            <div className="tagListWrapper">
              <AppButton
                variant="text"
                className="tagListToggleBtn"
                onClick={() => {
                  // filterContactTags();
                  setEditMode(true);
                }}
              >
                {SingleContactTagList?.length > 0 ? (
                  SingleContactTagList?.map(
                    (ele: { id: string; tag_title: string }, index: number) => {
                      // if (windowDimentions?.width > 1400 || index < 3) {
                      return (
                        <div className="tag">
                          {ele.tag_title}
                          {/* <CloseIcon /> */}
                        </div>
                      );
                      // } else {
                      //   return false;
                      // }
                    }
                  )
                ) : (
                  <>&nbsp; Add Tags</>
                )}
                {/* {windowDimentions?.width < 1400 &&
                SingleContactTagList.length > 3 && (
                  <MoreSelectedBadge items={SingleContactTagList.slice(3)} />
                )} */}
                {basicInformation?.can_update &&
                  <EditIcon onClick={() => {
                    // filterContactTags();
                    setEditMode(true);
                  }} />
                }
              </AppButton>
            </div>
          ) : (
            <Skeleton variant="rectangular" width={"100%"} height={"20px"} />
          )}
        </div>
      )}
    </AssignTagWrapperUI>
  );
};
export default HocBackdropLoader(TagAssignmentWrapper);

const MoreSelectedBadge = ({ items }: any) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };
  const length = items.length;
  // const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
  const label = `+${length}`;

  return (
    <div style={style}>
      <AppToolTip
        tooltipProps={{
          title: (
            <React.Fragment>
              {items.map((ele: any) => {
                return <div>&#8226;&nbsp;&nbsp;{ele.tag_title}</div>;
              })}
            </React.Fragment>
          ),
          placement: "top",
        }}
        className="chip"
        iconButtonProps={{ disableRipple: true }}
        divStyle={{ padding: "2px" }}
        IconComponent={<div style={{ fontSize: "13px" }}>{label}</div>}
      />
    </div>
  );
};
const AssignTagWrapperUI = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .tagListToggleBtn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    padding: 0;
    > svg {
      margin-left: auto;
    }
  }
  label {
    font-size: 13px;
    color: rgb(126, 131, 146);
    display: flex;
    gap: 21px;
    align-items: center;
  }
  .tagListWrapper {
    display: flex;
    align-items: flex-top;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0;
  }
  .tag {
    border-radius: 50px;
    font-weight: 300;
    padding: 2px 10px !important;
    font-size: 12px;
    line-height: 20px;
    background-color: ${theme.palette.primary.dark};
    color: ${theme.palette.default.white};
    // &:hover {
    //   border-color: ${theme.palette.primary.dark};
    // }
    // span {
    //   display: block;
    //   max-width: 50px;
    //   white-space: nowrap;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    // }
  }
`;
