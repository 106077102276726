import { createAsyncThunk } from "@reduxjs/toolkit";
import { MarketingListNetworkService } from "./MarketingListNetworkService";

export class marketingListActions {
  //* CREATE MARKETING LIST  //
  static createMarketingList = createAsyncThunk(
    "broadcastSlice/createMarketingList",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.postCreateMarketingList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  //* CREATE MARKETING LIST Contact //
  static postCreateMarketingListContact = createAsyncThunk(
    "broadcastSlice/postCreateMarketingListContact",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.postCreateMarketingListContact(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //* UPLOAD FILE RESPONSE //
  static uploadDocumentFile = createAsyncThunk(
    "broadcastSlice/uploadDocumentFile",
    (data: any, { dispatch, rejectWithValue }) => {
      let { formData, config } = data || {};
      return MarketingListNetworkService.uploadDocumentFile(formData, config)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  //* Get All MARKETING LIST  //
  static getAllMarketingListings = createAsyncThunk(
    "broadcastSlice/getAllMarketingListings",
    (type: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getAllMarketingListings(type)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static resetFieldsTypeMappingList = createAsyncThunk(
    "broadcastSlice/getAllMarketingListings",
    ({}: any, { dispatch, rejectWithValue }) => {
      return rejectWithValue([]);
    }
  );

  // * GET MARKETING BY ID
  static getNewMarketingListById = createAsyncThunk(
    "broadcastSlice/getNewMarketingListById",
    (data: string, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getNewMarketingListById(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // * GET MARKETING BY ID
  static getMarketingListById = createAsyncThunk(
    "broadcastSlice/getMarketingListById",
    (data: string, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getMarketingListById(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // * GET FIELDS TYPE MAPPING LIST
  static getFieldsTypeMappingList = createAsyncThunk(
    "broadcastSlice/getFieldsTypeMappingList",
    (id: string, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getFieldsTypeMappingList(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // * Delete MARKETING BY ID
  static deleteMarketingListById = createAsyncThunk(
    "broadcastSlice/deleteMarketingListById",
    (id: string, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.deleteMarketingListById(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // * Delete MARKETING Detail list BY ID
  static deleteMarketingDeatilsListById = createAsyncThunk(
    "broadcastSlice/deleteMarketingDeatilsListById",
    (id: string, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.deleteMarketingDeatilsListById(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Broadcast Actions

  static getAllBroadcastData = createAsyncThunk(
    "broadcastSlice/getAllBroadcastData",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getAllBroadcastData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );





  static getCommunicationLogsDownload = createAsyncThunk(
    "broadcastSlice/getCommunicationLogsDownload",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getCommunicationLogsDownload(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  

  

  static getScheduledBroadcastByID = createAsyncThunk(
    "broadcastSlice/getScheduledBroadcastByID",
    (id: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getScheduledBroadcastByID(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  

  static getAllImportHistoryData = createAsyncThunk(
    "broadcastSlice/getAllImportHistoryData",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getContactImpotHistory(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static generateCommunicationLogsReport = createAsyncThunk(
    "broadcastSlice/generateCommunicationLogsReport",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.generateCommunicationLogsReport(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Marketing List Import Records Actions
  static CreateImportID = createAsyncThunk(
    "broadcastSlice/CreateImportID",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.createImportIDNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getAllProgressData = createAsyncThunk(
    "broadcastSlice/getAllProgressData",
    (importId: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getProgressBarData()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  // Import Step 1 Action
  static ImportFormStepOne = createAsyncThunk(
    "broadcastSlice/uploadFaqDocument",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.ImportFormStepOneNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static downloadImportHistoryFile = createAsyncThunk(
    "broadcastSlice/downloadImportHistoryFile",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getImportHistoryFile(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  // Users For Import
  static getUserListing = createAsyncThunk(
    "broadcastSlice/getUserListing",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getUserListing(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // // * GET Broadcast BY ID
  static getContactsSelectData = createAsyncThunk(
    "ContactSlice/getContactsSelectData",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getContactsSelectData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static ImportFormStepTwo = createAsyncThunk(
    "broadcastSlice/ImportFormStepTwo",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.ImportFormStepTwoNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // // * GET Broadcast BY ID
  static getExportMarketingList = createAsyncThunk(
    "ContactSlice/getExportMarketingList",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.getExportMarketingList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static ImportFormStepThree = createAsyncThunk(
    "broadcastSlice/ImportFormStepThree",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.ImportFormStepThreeNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static DeletTempObj = createAsyncThunk(
    "broadcastSlice/DeletTempObj",
    (data: any, { dispatch, rejectWithValue }) => {
      return MarketingListNetworkService.DeleteTempImportContactsObj(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
}
