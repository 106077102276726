// vendors
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Grid from "@mui/material/Grid";

// components
import Icon from "../Components/Icon";
import SearchInput from "../Components/SearchInput";
import InputOption from "../Components/InputOption";

// data
import { registerActions } from "../Config/RegisterActions";

interface ActionsPopoverProps {
  actions: any;
  open: HTMLElement | null;
  onClose: () => void;
  onActionSelect: (option: any, type?: string) => void;
  isLast: boolean;
  error: string;
}

const ActionsPopover = (props: ActionsPopoverProps) => {
  const { open, onClose, onActionSelect, isLast, actions, error } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any>([]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    const actions = searchTerm
      ? registerActions.filter((item: any) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : registerActions;
    setSearchResults(actions);
  }, [searchTerm]);

  return (
    <Popover
      className="ignore-onClickOutside"
      open={Boolean(open)}
      onClose={onClose}
      anchorEl={open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
      sx={{ ml: "10px", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)" }}
      elevation={0}
      PaperProps={{
        sx: {
          boxShadow:
            "0px 13.9892px 69.9461px rgba(126, 131, 146, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.08) !important",
        },
      }}
    >
      <Box
        sx={{
          minWidth: "380px",
          maxWidth: "380px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: "16px" }}
        >
          <Typography
            sx={{ color: "#101010" }}
            fontSize="16px"
            lineHeight="24px"
            fontWeight={600}
          >
            Actions
          </Typography>
          <IconButton onClick={onClose} sx={{ height: "30px", width: "30px" }}>
            <Icon icon="close" size={24} color="default.darkGrey" />
          </IconButton>
        </Stack>
        <Box sx={{ py: "16px" }}>
          <Box sx={{ p: "16px", pt: 0 }}>
            <SearchInput
              placeholder="Search"
              onChange={handleSearchInputChange}
              value={searchTerm}
            />
          </Box>
          {error !== '' && <Box sx={{px: '16px'}}>
            <Typography variant="body1" color={'default.error'}>{error}</Typography>
          </Box>}
          {searchResults.length > 0 ? (
            <List sx={{ maxWidth: "380px" }}>
              <Grid container spacing={0} columns={1}>
                {searchResults.map((action: any, index: number) => (
                  <Grid item xs={1} key={`action-key-${index + 1}`}>
                    <ListItem disablePadding>
                      <ListItemButton
                        sx={{ px: "16px !important", cursor: 'pointer !important' }}
                        onClick={() => onActionSelect(action)}
                        disabled={action.enableOnLastNode && !isLast}
                      >
                        <InputOption
                          label={action.name}
                          icon={action.icon}
                          color={action.color}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Grid>
                ))}
              </Grid>
            </List>
          ) : (
            <Typography
              sx={{ px: "16px" }}
              align="center"
              color="#7E8392"
              fontWeight={400}
              fontSize={"14px"}
              lineHeight="21px"
            >
              No match found!
            </Typography>
          )}
        </Box>
      </Box>
    </Popover>
  );
};
export default ActionsPopover;
