import styled from "styled-components"
import AppButton from "../../../../../AppComponents/AppButton"
import { EmailHtmlPreview, EmailTemplateButton, EmailTemplateCard } from "../Styles"
import { useCallback, useEffect, useState } from "react"
import AppFormSearchField from "../../../../../AppComponents/Forms/AppFormSearchField"
import { Box, FormControl, Grid, IconButton, InputAdornment, OutlinedInput, Typography, debounce } from "@mui/material"
import { Search, Close } from "@material-ui/icons"
import TemplateCard from "../../../../../assets/images/templateCard.svg"
import TemplateAPIERROR from "../../../../../assets/images/No_records_found.svg";
import { EmailActions } from "../../../../../Store/Slices/email/email.actions"
import { useAppDispatch, useAppSelector } from "../../../../../Store"
import { emailTemplateListing, emailTemplateListsData, galleryEmailTemplates, getEmailTemplateList } from "../../../../../Store/Slices/email/email.selectors"
import AppModel from "../../../../../Hooks/useModel/AppModel"
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons"
import { isTemplateExist } from "../../../../../Store/Slices/email/email.slice"
import AppPaginationComponent from "../../../../../AppComponents/Pagination/Pagination"
import { BROADCAST_EMAIL_FORM_DATA } from "../../Utils/Constants"
import { AppConfig } from "../../../../../Config/app-config"
import { useFormikContext } from "formik"
import Yakou from "../../Template/Editors/yakou"
import { BasicType, BlockManager, JsonToMjml } from "easy-email-core"
import { FamilyRestroomSharp } from "@mui/icons-material"
import { useNotification } from "../../../../../Hooks/useNotification/useNotification"
import HTMLEditor from "../../Template/Editors/htmlEditor"
import { ErrorComponent } from "../../Template/TemplatesListingCSS"
import { LoaderComponentProps } from "../../../../../AppComponents/Loader"
import { HocBackdropLoader } from "../../../../../HOC/HocBackdropLoader"
var mjml2html = require('mjml-browser');

export interface Props extends LoaderComponentProps {
    setFormStep:any
    formStep:any
  }
const RenderFormStepNew2 : React.FC<Props> = ({
    setFormStep,
    setLoading,
    formStep
  }) => {
    const dispatch = useAppDispatch()
    const galleryTemplateListing = useAppSelector(galleryEmailTemplates);
    const emailTemplateListingData = useAppSelector(emailTemplateListsData);
    let { showAlert } = useNotification();

    const [selectedTemplate, setSelectedTemplate] = useState("template_gallery")
    const [showPreview, setShowPreview] = useState(false)
    const [templateBody, setTemplateBody] = useState<any>({})
    const [templateList, setTemplateList] = useState<any>([])
    const [inputValue, setInputValue] = useState('');
    const [templateRerender, setRerender] = useState(false)
    const [isNewTemplate, setIsNewTemplate] = useState(false)
    const [isShowingModal, toggleModal] = useState<boolean>(false);
    const [applySavedTemplate, setApplySavedTemplate] = useState(true)
    const [htmlTemplate, setHtmlTemplate] = useState<any>(null)
    const { submitForm, values, setFieldValue } = useFormikContext<any>();
    const [fullScreenStyle, setFullScreenStyle] = useState({});
    const [localValues, setLocalValues] = useState<any>({
        search: "",
        itemsPerPage: { label: "10  per page", value: 10 },
        currentPage: 0,
    });
    const headerList = [
        {
            "label": "Template Gallery",
            "value": "template_gallery"
        },
        {
            "label": "Saved Templates",
            "value": "saved_templates"
        }
    ]
    const initialValue = {
        "attributes": {
            "background-color": "#efeeea",
            "width": "600px"
        },
        "children": [
 
        ],
        "data": {
            "value": {
                "breakpoint": "480px",
                "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans','Helvetica Neue', sans-serif",
                "font-size": "14px",
                "font-weight": "400",
                "fonts": [],
                "headAttributes": "",
                "headStyles": [],
                "line-height": "1.7",
                "responsive": true,
                "text-color": "#000000"
            }
        },
        "type": "page"
    }
    const setTemplateDataFromData = (data: any) => {
        let footerText = AppConfig.getUserData(
            BROADCAST_EMAIL_FORM_DATA
        )?.footer
        let baseTemplate: any = "";
        if (data?.template_design) {
            baseTemplate = Object.assign({}, data?.template_design?.content || data?.template_design);
            if (!baseTemplate.children[baseTemplate.children.length - 1]?.data?.value?.content?.includes("footer")) {
                baseTemplate.children = [
                    ...baseTemplate.children,
                    {
                        "type": "button",
                        "data": {
                            "value": {
                                "content": `<font disabled=\"disabled\" size=\"1\" id=\"footer\">${footerText}</font>`,
                            }
                        },
                        "attributes": {
                            "padding": "10px 25px 10px 25px",
                            "align": "center",
                            "container-background-color": "#FFFFFF",
                            "background-color": "#FFFFFF",
                            "color": "#000000",
                            "disabled": "disabled",
                            "width": "100%",
                            "margin": "10px 0px"
                        },
                        "children": []
                    }
                ]
            }

            setHtmlTemplate(null);
            setFieldValue("JSONTemplate", data?.template_design);
        } else {
            baseTemplate = data?.template_body;
            let updatedHTML = data?.template_body.split("</body>")[0] + '<footer disabled="disabled" size="1" id="footer" style="text-align: center;">' + footerText + '</footer></body>' + data?.template_body.split("</body>")[1];

            setHtmlTemplate(updatedHTML);
            localStorage.setItem("HTMLTemplate", JSON.stringify(updatedHTML));
            localStorage.removeItem("JSONTemplate");
            setFieldValue("JSONTemplate", null);

        }
        setFieldValue("emailTemplate", baseTemplate);
        setFieldValue("previewEmailTemplateBody", data?.template_body);
        setRerender(true);
    }
    const createScratchTemplate = () => {
        let payload: any = {
            template_design: initialValue
        }
        setTemplateDataFromData(payload);
    }
    const setTemplateData = (tempalte: any) => {
        setIsNewTemplate(false)
        setApplySavedTemplate(false)
        setFieldValue("template_name", tempalte?.name);
        setFieldValue("template_type", selectedTemplate === "template_gallery" ? "gallery" : "savedTemplate");
        setFieldValue("broadcast_template_id", tempalte?.id);
        localStorage.setItem("broadcast_template_id", tempalte?.id);
        let payload: any = {
            template_body: selectedTemplate === "template_gallery" ? tempalte.template_body : JSON.parse(tempalte.template_body),
            template_design: selectedTemplate === "template_gallery" ? JSON.parse(tempalte.template_design) : tempalte.template_design
        }
        setTemplateDataFromData(payload);
    }
    useEffect(() => {
        const newFullScreenStyle: any = {
            // backgroundColor: '#fff',
            backgroundColor: "white",
            position: "fixed",
            top: htmlTemplate ? 0 : "64px",
            left: 60,
            zIndex: 1000,
            width: "calc(100% - 60px)",
            height: htmlTemplate ? "100vh" : "calc(100vh - 64px)"
        }
        setFullScreenStyle(newFullScreenStyle)
    }, [htmlTemplate])


    useEffect(() => {
        if (selectedTemplate === "template_gallery") {
            const payload: any = {
                search: localValues?.search,
                category: ""
            };
            dispatch(EmailActions.getEmailgalleryTemplatesListings(payload)).then((res: any) => {
            })
                .finally(() => {
                    setSelectedTemplate("template_gallery")
                });
        } else {
            const listingPayload: any = {
                limit: localValues?.itemsPerPage?.value,
                offset: localValues?.currentPage,
                search: localValues?.search
                // category: localValues?.category,
            };
            dispatch(EmailActions.getEmailTemplatesListings(listingPayload))
                .finally(() => {
                    //   setLoading(false);
                });
        }

    }, [localValues])

    const handleSelectSearch = useCallback(
        debounce((e:any, field:any) => {
          setLocalValues((prevValues:any) => ({
            ...prevValues,
            [field]: e.target.value,
            currentPage : 0
          }));
        }, 700),
        [] // The empty dependency array ensures the debounce function is not recreated on every render
      );

    const clearCallback = (e?: any, field?: any) => {
        setInputValue('')
        setLocalValues({
            ...localValues,
            [field]: "",
        });
        // setShouldApply(true)
    };
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 0,
        });
    };
    function handlePageChange(event: any, value: any) {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    }
    useEffect(() => {
        if (galleryTemplateListing || emailTemplateListingData) {
            if (selectedTemplate === "template_gallery") {
                setTemplateList(galleryTemplateListing)
            } else if (selectedTemplate === "saved_templates") {
                setTemplateList(emailTemplateListingData?.results)
            }
        }

    }, [selectedTemplate, galleryTemplateListing, emailTemplateListingData])
    const onChangeTemplate = (value: any) => {
        setInputValue('')
        setLocalValues({
            ...localValues,
            "search": "",
        });
        setSelectedTemplate(value)

    }
    const onSubmitJSON = (editorValues: any) => {
        const mjmlString = JsonToMjml({
            data: { ...editorValues.content },
            mode: 'production',
            context: { ...editorValues.content },
        });
        const html = mjml2html(mjmlString, {}).html;
        setFieldValue("HTMLTemplate", JSON.stringify(html));
        setFieldValue("JSONTemplate", editorValues);
        localStorage.setItem("HTMLTemplate", JSON.stringify(html));
        localStorage.setItem("JSONTemplate", JSON.stringify(editorValues));
        // if (htmlTemplate) {
        //   setFieldValue("emailTemplate", html);

        // } else {
        setFieldValue("emailTemplate", editorValues.content);
        //}

        submitForm();

    }
    const onSubmitHTMLJSON = () => {
        setFieldValue("HTMLTemplate", localStorage.getItem("HTMLTemplate"));
        setFieldValue("JSONTemplate", null);
        submitForm();

    }
    const updateTemplateAndSubmit = (editorValues?: any) => {
        let emailTemplate = localStorage.getItem("HTMLTemplate") || null;
        let HtmlTemplate = localStorage.getItem("JSONTemplate") || "";
        setFieldValue("JSONTemplate", HtmlTemplate);
        let payload ={}
        if(values?.JSONTemplate){
            payload = {
                template_body: emailTemplate,
                template_design: JSON.parse(HtmlTemplate),
            };
        }else{
            payload = {
                template_body: emailTemplate,
            };
        }
        const data = {
            id: values?.broadcast_template_id,
            payload,
        };
        setLoading(true)
        dispatch(EmailActions.updatetemplateById(data))
            .then((res: any) => {
                if (res?.payload?.data?.status ) {
                    setLoading(false)
                    if(values?.JSONTemplate){
                        onSubmitJSON(editorValues)

                    }else{
                        onSubmitHTMLJSON();

                    }
                } else {

                }
            })
            .catch((error: any) => {
                showAlert("Template Name Already Exist", "error");
                setLoading(false)

            })
            .finally(() => {
                setLoading(false)

            });
    }
    const saveEmailTemplate = (name: any, isNew: any, editorValues?: any): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            let emailTemplate = localStorage.getItem("HTMLTemplate") || null;
            let HtmlTemplate = localStorage.getItem("JSONTemplate") || "";
            let payload: any = {
                name: name,
                template_body: emailTemplate,
                template_design: JSON.parse(HtmlTemplate),
            };
            setLoading(true)
            dispatch(EmailActions.postEmailTemplateCreate(payload))
                .then((res: any) => {
                    console.log("res?.payload", res?.payload);

                    if (res?.payload?.data?.status && res?.payload?.data?.data) {
                        setApplySavedTemplate(false);
                        setIsNewTemplate(false);
                        showAlert("Template created", "success");
                        setFieldValue("template_name", res?.payload?.data?.data?.name);
                        setFieldValue("template_type", "savedTemplate");
                        setFieldValue("broadcast_template_id", res?.payload?.data?.data?.id);
                        localStorage.setItem("broadcast_template_id", res?.payload?.data?.data?.id);
                        resolve(true);
                        setLoading(false)
                        if (isNew) {
                            onSubmitJSON(editorValues);
                        } else {
                            let payload: any = {
                                template_body: JSON.parse(res?.payload?.data?.data?.template_body),
                                template_design: JSON.parse(res?.payload?.data?.data?.template_design)
                            };
                            setTemplateDataFromData(payload);
                        }
                    } else {
                        showAlert(res?.payload?.data?.error || "Unable to fetch data", "error");
                        resolve(false);
                        setLoading(false)
                    }
                })
                .catch((error: any) => {
                    setLoading(false)
                });
        });
    };

    useEffect(() => {
        if (values?.broadcast_template_id && values?.HTMLTemplate && applySavedTemplate) {
            let payload: any = {
                template_body: values?.HTMLTemplate ? JSON.parse(values?.HTMLTemplate || "") : "",
                template_design: values?.JSONTemplate
            }
            setTemplateDataFromData(payload);
        }
    }, [values?.broadcast_template_id])
    const handleEditorChanges = () => {
        setFieldValue("emailTemplate", localStorage.getItem("HTMLTemplate"));
        setFieldValue("HTMLTemplate", localStorage.getItem("HTMLTemplate"));
        setFieldValue("JSONTemplate", null);
        submitForm();
    }
    if (formStep !== 2) {
        return null;
      }
    return (
        <div>
            <EmailTemplateButton>
                <div style={{ display: "flex" }}>
                    {headerList.map((data: any) => {
                        return (
                            <>
                                <Button
                                    variant="outline"
                                    radius={"50px"}
                                    margin={"-1rem 1rem 1rem"}
                                    selected={selectedTemplate === data?.value}
                                    onClick={() => {
                                        onChangeTemplate(data?.value)
                                    }}
                                >
                                    {data?.label}
                                </Button>
                            </>
                        )
                    })}
                </div>
                <FormControl sx={{ m: 1, width: '347px', height: "40px" }} style={{
                    border: "1px solid lightgray",
                    marginTop: "-18px",
                    backgroundColor: "#f0f2f7",
                    borderRadius: "5px"
                }}
                    variant="outlined">
                    <OutlinedInput
                        sx={{ height: "4ch" }}
                        id="outlined-adornment-weight"
                        placeholder="Search"
                        value={inputValue}
                        onChange={(e: any) => { handleSelectSearch(e, "search"); setInputValue(e.target.value) }}
                        // clearCallback={(e: any) => clearCallback(e, "search")}
                        endAdornment={
                            <InputAdornment position="end" >
                                <IconButton
                                    onClick={(e: any) => clearCallback(e, "search")}
                                    edge="end"
                                >
                                    {localValues?.search ? <Close /> : <Search />}
                                </IconButton>
                            </InputAdornment>
                        }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',

                        }}
                    />
                </FormControl>

            </EmailTemplateButton>
            <div>
                <Grid
                    container
                    rowSpacing={1} columnSpacing={1}
                    columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                >
                    {selectedTemplate === "template_gallery" ? <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={0} style={{ marginBottom: "4%" }}>
                        <EmailTemplateCard>
                            <img src={TemplateCard} onClick={() => { setIsNewTemplate(true); createScratchTemplate();setHtmlTemplate(null) }} style={{
                                maxWidth: "100%",
                                minWidth: "100%",
                                width: "200px",
                                height: "inherit",
                                borderRadius: "11px",
                                border: "1px solid lightgray",
                                objectFit: "cover"
                            }} alt="no data available" />
                            <a>Starting from scratch</a>
                        </EmailTemplateCard>
                    </Grid> : null}
            
                    
                    {templateList && templateList?.length >= 0 && templateList?.map((res: any, index: any) => {
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index + 1} style={{ marginBottom: "4%" }}>
                                <EmailTemplateCard>
                                    {res?.thumbnail ? <img src={res?.thumbnail?.source ? res?.thumbnail?.source : res?.thumbnail} style={{
                                        maxWidth: "100%",
                                        minWidth: "100%",
                                        margin: "-1% 0% 0% 0%",
                                        objectFit: "cover",
                                        width: "200px",
                                        height: "315px",
                                        borderRadius: "11px",
                                        border: "1px solid lightgray"
                                    }} alt="no data available" />
                                        : <iframe
                                            scrolling="no"
                                            title="HTML Preview"
                                            srcDoc={JSON.parse(res?.template_body) || `""` || {}}
                                            className="iframe"
                                            style={{
                                                border: "1px solid rgb(204, 204, 204)",
                                                margin: "0 auto",
                                                borderRadius: "12px",
                                                height: "315px",
                                                width: "100%"
                                            }}
                                        />}
                                    <a>{res.name}</a>
                                    <div className="overlay">
                                        <div className="buttons">
                                            <ActionButton variant="outline"
                                                onClick={() => setTemplateData(res)}
                                                radius={"50px"}
                                                margin={"-1rem 1rem 1rem"} style={{    height: "41px"}}
                                                backgroundColor="#6150E1">Select</ActionButton>
                                            <ActionButton
                                                onClick={() => {
                                                    setTemplateBody(selectedTemplate === "template_gallery" ? res?.template_body : JSON.parse(res?.template_body));
                                                    setShowPreview(true)
                                                }}
                                                variant="outline"
                                                radius={"50px"}
                                                margin={"-1rem 1rem 1rem"}
                                                backgroundColor="transparent"
                                                border="1px solid white"
                                                style={{    height: "41px"}}
                                            >Preview</ActionButton>
                                        </div>
                                    </div>
                                </EmailTemplateCard>
                            </Grid>
                        )
                    })}
                </Grid>
                {!templateList && templateList?.length == 0 &&
                      <ErrorComponent
                      style={{
                        border: "none",
                        height: "calc(100vh - 214px)",
                      }}
                    >
                      <Box display="flex" justifyContent="center">
                        <img width="400" src={TemplateAPIERROR} alt="" />
                      </Box>
                      <Box className="error-Info">
                        <Typography variant="h6" fontSize="20px">
                          No Records Found
                        </Typography>
                      </Box>
                    </ErrorComponent>}
            </div>
            <AppModel
                isShowing={showPreview}
                onClose={setShowPreview}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "750px",
                    },
                }}
            >
                <EmailHtmlPreview>
                    <div className="header">
                        <div className="header-top" style={{ alignItems: "center" }}>
                            <Typography
                                sx={{
                                    fontSize: "1.4rem",
                                    fontWeight: "500",
                                }}
                            >
                                Template Preview
                            </Typography>
                            <span
                                onClick={() => {
                                    setShowPreview(false);
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <AppMaterialIcons iconName="close" />
                            </span>
                        </div>
                        <div className="header-left">
                            <iframe
                                title="HTML Preview"
                                srcDoc={templateBody || {}}
                                className="iframe"
                                style={{
                                    width: "calc(100% - 40px)",
                                    height: "80vh",
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                    margin: "0 auto",
                                    display: "block",
                                }}
                            />
                        </div>
                    </div>
                </EmailHtmlPreview>
            </AppModel>
            <div className="html-template"
                style={
                    templateRerender ? fullScreenStyle : {}
                }
            >
                {templateRerender ?
                    <div className="editor" style={{
                        height: "inherit"
                    }}>
                        {htmlTemplate ?
                            <HTMLEditor
                                defaultValue={values.emailTemplate}
                                isFullScreen={true}
                                setiSFullScreen={null}
                                handleEditorChanges={handleEditorChanges}
                                toggleModal={toggleModal}
                                isBroadcast={true}
                                handleBack={() => setRerender(false)}
                                templateName={values?.template_name}
                                updateTemplateAndSubmit={updateTemplateAndSubmit}
                                handlePageBack = {setFormStep}

                            />
                            :
                            <EmailEditorWrapper>
                                <Yakou
                                    isFullScreen={true}
                                    setiSFullScreen={null}
                                    onSubmit={onSubmitJSON}
                                    initialValues={{
                                        subject: 'Welcome to Geta.ai',
                                        subTitle: 'Nice to meet you!',
                                        content: values.emailTemplate,
                                    }}
                                    handleBack={() => setRerender(false)}
                                    isBroadcast={true}
                                    templateName={isNewTemplate ? "" : values?.template_name}
                                    data={values}
                                    saveEmailTemplate={saveEmailTemplate}
                                    templateType={values?.template_type}
                                    updateTemplateAndSubmit={updateTemplateAndSubmit}
                                    isNewTemplate={isNewTemplate}
                                    handlePageBack = {setFormStep}
                                />
                            </EmailEditorWrapper>
                        }
                    </div>
                    : null}


                {/* <TestEmailTemplate /> */}
            </div>
            {isShowingModal && <AppModel
                isShowing={true}
                onClose={toggleModal}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "750px",
                    },
                }}
            >
                <EmailHtmlPreview>
                    <div className='header'>
                        <div className='header-top'>
                            <h2>HTML Preview</h2>
                            <AppButton variant='grey' onClick={() => toggleModal(false)} >
                                Exit Preview
                            </AppButton>
                        </div>
                        <div className='header-left'>
                            <iframe
                                title="HTML Preview"
                                srcDoc={JSON.parse(localStorage?.getItem('HTMLTemplate')?.replaceAll("\n", "") || "")}
                                className='iframe'
                                style={{ width: "700px", height: "80vh", border: "1px solid #ccc", margin: "25px 0 0 23px", padding: "5px" }}
                            />
                        </div>
                    </div>
                </EmailHtmlPreview>
            </AppModel>}
            {templateList?.length && selectedTemplate === "saved_templates" ? (
                <AppPaginationComponent
                    totalCount={emailTemplateListingData?.pagination?.totalRecords}
                    handlePageChange={handlePageChange}
                    currentPage={localValues?.currentPage}
                    totalPages={emailTemplateListingData?.pagination?.totalPages}
                    rowsPerPage={localValues?.itemsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            ) : null}
        </div>
    )
}
const Button = styled(AppButton) <{ props: any }>`
  min-width: ${(props: any) =>
        props.minWidth ? props.minWidth + "!important" : "170px !important"};
  font-size: 16px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  background-color: ${(props: any) =>
        props.selected ? "rgb(33, 28, 82)" + "!important" : ""};
  margin: ${(props: any) => (props.margin ? props.margin + "!important" : "")};
  border-radius: ${(props: any) =>
        props.radius ? props.radius + "!important" : ""};
  border: 1px solid white !important;
  color : ${(props: any) =>
        props.selected ? "white" + "!important" : ""};
  height : 41px !important;

`;

const ActionButton = styled(AppButton) <{ props: any }>`
  min-width: ${(props: any) =>
        props.minWidth ? props.minWidth + "!important" : "170px !important"};
  font-size: 16px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  background-color: ${(props: any) =>
        props.backgroundColor ? props.backgroundColor + "!important" : ""};
  margin: ${(props: any) => (props.margin ? props.margin + "!important" : "")};
  border-radius: ${(props: any) =>
        props.radius ? props.radius + "!important" : ""};
  border:  ${(props: any) =>
        props.border ? props.border + "!important" : ""};
  color : white !important;
  height : 41px

`;
const EmailEditorWrapper = styled.div`
  .arco-layout .arco-layout-sider:last-child ._Item-fill_1jdgv_131{
      
    .arco-collapse .arco-collapse-item:first-child {
      display: none;
    }
    .arco-tabs-header .arco-tabs-header-title{
      display: none;
      &.arco-tabs-header-title-active {
        display: block;
      }
    }
  } 
`
export default HocBackdropLoader(RenderFormStepNew2)