import React, { Component } from "react";
import PropTypes from "prop-types";
import { ensureSafeUrl } from "../../utils/url";
// import openlink from '../../../images/openlink.svg';
import "./styles.css";

function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}

function getLinkComponent(config: any) {
  const showOpenOptionOnHover = config.showOpenOptionOnHover;
  interface Props {
    entityKey: string;
    children: any;
    contentState: any;
  }

  return class Link extends Component<Props> {
    static propTypes = {
      entityKey: PropTypes.string.isRequired,
      children: PropTypes.array,
      contentState: PropTypes.object,
    };

    state = {
      showPopOver: false,
    };

    openLink = () => {
      const { entityKey, contentState } = this.props;
      const { url } = contentState.getEntity(entityKey).getData();
      const linkTab = window.open(ensureSafeUrl(url), "blank"); // eslint-disable-line no-undef
      // linkTab can be null when the window failed to open.
      if (linkTab) {
        linkTab.focus();
      }
    };

    toggleShowPopOver = () => {
      const showPopOver = !this.state.showPopOver;
      this.setState({
        showPopOver,
      });
    };

    render() {
      const { children, entityKey, contentState } = this.props;
      const { url, targetOption } = contentState.getEntity(entityKey).getData();
      const { showPopOver } = this.state;

      return (
        <span
          className="rdw-link-decorator-wrapper"
          onMouseEnter={this.toggleShowPopOver}
          onMouseLeave={this.toggleShowPopOver}
        >
          <a href={ensureSafeUrl(url)} target={targetOption}>
            {children}
          </a>
          {/* {showPopOver && showOpenOptionOnHover ?
            <img
              src={openlink}
              alt=""
              onClick={this.openLink}
              className="rdw-link-decorator-icon"
            />
            : undefined
          } */}
        </span>
      );
    }
  };
}

export default (config: any) => ({
  strategy: findLinkEntities,
  component: getLinkComponent(config),
});
