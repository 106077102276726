// vendors
import React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";

interface UIDialogProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const UIDialog = (props: UIDialogProps) => {
  const { children, className } = props;
  return (
    <Dialog
      className={className}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          background: "rgba(16, 16, 16, 0.08)",
          backdropFilter: "blur(3px)",
        },
      }}
      {...props}
    >
      {children}
    </Dialog>
  );
};

UIDialog.Header = DialogTitle;
UIDialog.Body = DialogContent;
UIDialog.Footer = DialogActions;

export default UIDialog;
