import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import { formatString } from "../../Utils";
import rocketImg from "../../assets/images/Rocket launch.svg";
import { useMyBotsStore } from "../../Services/Apis";
import { useAppSelector } from "../../Store";
import React from "react";
import Group39922 from "../../assets/images/Group39922.svg";

type Props = {};

const FreshStartPage = (props: Props) => {
  const navigate = useNavigate();
  const { data: paginatedMyBots } = useAppSelector(useMyBotsStore);
  const location = useLocation();

  const handleRedirectToBots = (subUrl: string) => {
    const workspace_id: string = window.localStorage.getItem("ws_id") || "";
    const URL = formatString(subUrl, [workspace_id]);
    navigate(URL);
  };

  React.useEffect(() => {
    if (paginatedMyBots && paginatedMyBots?.results?.length > 0 && !location.pathname.includes('/setup/bots')) {
        handleRedirectToBots(`/workspace/{0}/setup/bots`);
    }
  }, [paginatedMyBots]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledWrap>
      <div className="bgSection">
        <div>
          <img src={rocketImg} alt="" />
        </div>
        <div>
          <h2 className="h2Text">Looks like you're off to a fresh start.</h2>
          <h4 className="h4Text">
            Get things going by creating a new chatbot.
          </h4>
        </div>
        <div className="btn">
          <AppButton
            // startIcon="ri-add-line"
            onClick={() =>
              handleRedirectToBots(`/workspace/{0}/setup/create-bot`)
            }
            className="create-btn"
          >
            Create bot
          </AppButton>
        </div>
      </div>
    </StyledWrap>
  );
};

export default FreshStartPage;

const StyledWrap = styled.div`
  && {
    width: 100%;
    height: 100%;
    background-image: url(${Group39922});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .create-btn {
      width: 130px;
      margin-top: 55px;
      padding: 0;
      display: inline-block;
    }
    .bgSection {
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    .h2Text {
      font-weight: 400;
      font-size: 32px;
      line-height: 50px;
      text-align: center;
      text-transform: capitalize;
      color: #101010;
      margin-top: 45px;
    }

    .h4Text {
      font-weight: 300;
      font-size: 20px;
      line-height: 160%;
      text-align: center;
      text-transform: capitalize;
      color: #101010;
    }
    .btn {
      display: flex;
      justify-content: center;
    }
  }
`;
