import * as Yup from "yup";

export const LiveChatFilterSchema = Yup.object().shape({
  label: Yup.mixed().required().label("Label"),
});


export const chatFilterStatusOptions = [
  // { value: 'all', label: 'All' },
  { value: 'Open', label: 'Open' },
  { value: 'Pending', label: 'Pending' },
  { value: 'Closed', label: 'Closed' },
];
export const chatFilterConversationStatusOptions = [
  // { value: 'all', label: 'All' },
  { value: 'unassigned', label: 'Unassigned' },
  { value: 'assigned', label: 'Assigned' },
  { value: 'unattended', label: 'Unattended' },
];
export const chatFilterOrderByOptions = [
  { value: 'recent', label: 'Recent' },
  { value: 'old', label: 'Old' },
];
export const chatFilterChatTypeOptions = [
  { value: 'bot', label: 'Bot conversation' },
  { value: 'livechat', label: 'Agent conversation ' },
];

