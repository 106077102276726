import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmailSettingsService } from "./emailSettingsService";

export class EmailSettingsActions {

    // Enable Channel Settings
    static enableEmailChannel = createAsyncThunk(
        "EmailSettingsSlice/enableEmailChannel",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.enableEmailChannel()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    // Validate Email Settings
    static validateEmailList = createAsyncThunk(
        "EmailSettingsSlice/validateEmailList",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.validateEmailList()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    // Validate Email Settings
    static updateProfile = createAsyncThunk(
        "EmailSettingsSlice/updateProfile",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.updateProfile(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    // Validate Email Settings
    static getAddressesList = createAsyncThunk(
        "EmailSettingsSlice/getAddressesList",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.getAddressesList()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    // Validate Email Settings
    static getValidEmailLists = createAsyncThunk(
        "EmailSettingsSlice/getValidEmailLists",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.getValidEmailLists()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    // Validate Email Settings
    static deleteEmail = createAsyncThunk(
        "EmailSettingsSlice/deleteEmail",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.deleteEmail(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    // Validate Email Settings
    static getDomainLists = createAsyncThunk(
        "EmailSettingsSlice/getDomainLists",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.getDomainLists()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    // Add Email Settings
    static addEmail = createAsyncThunk(
        "EmailSettingsSlice/addEmail",
        (data: any, { dispatch, rejectWithValue }) => {
            return EmailSettingsService.addEmail(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )

}