import React from "react";
import styled from "styled-components";

interface Props {
  url?: any;
}

const AppVideoPlayer: React.FC<Props> = ({ url }) => {
  return (
    <StyledIframeVideoPlayer
      src={url}
      allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture; SAMEORIGIN;"
      allowFullScreen
      frameBorder="0"
      X-Frame-Options="SAMEORIGIN"
      style={{
        backgroundColor: "#282828",
        width: "100%",
        borderRadius: "6px",
        objectFit: "cover",
      }}
    />
  );
};

export default AppVideoPlayer;

const StyledIframeVideoPlayer = styled.iframe`
  && {
    overflow: hidden !important;
    border-radius: 6px;
    width: 100%;
    object-fit: contain;
    min-height: 10rem;
    max-height: 12rem;
    background-color: #282828;
  }
`;
