import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class LifecycleStageService {

    static getLifecycleStageStructure() {
        return http.get(
            `${consoleBaseUrl}${Endpoints.GET_LIFECYCLE_STAGE_STRUCTURE}`
        )
    }
    
    static getLifecycleStage(data: any) {
        return http.get(
            `${consoleBaseUrl}${Endpoints.LIFECYCLE_STAGE}`, data
        )
    }

    static lifecycleStageChangeState(data: any) {
        const BODY= data
        return http.post(
            `${consoleBaseUrl}${Endpoints.LIFECYCLE_STAGE_CHANGE_STATE}/${BODY?.state_type}/${BODY?.stage_id}`
        )
    }

    static createLifecycleStage(data: any) {
        const BODY= data
        return http.post(
            `${consoleBaseUrl}${Endpoints.LIFECYCLE_STAGE}`, BODY
        )
    }

    static updateLifecycleStage(data: any) {
        const BODY= data
        return http.put(
            `${consoleBaseUrl}${Endpoints.LIFECYCLE_STAGE}`, BODY
        )
    }

    static deleteLifecycleStage(data: any) {
        const BODY= data
        return http.delete(
            `${consoleBaseUrl}${Endpoints.LIFECYCLE_STAGE}`, { data: BODY}
        )
    }

    static changeLifecycleStageOrder(data: any) {
        const BODY= data
        return http.patch(
            `${consoleBaseUrl}${Endpoints.LIFECYCLE_STAGE_CHANGE_POSITION}`, BODY
        )
    }
}

class Endpoints {
    static GET_LIFECYCLE_STAGE_STRUCTURE = "/workspace/get-lifecycle-stage-structure"
    static LIFECYCLE_STAGE_CHANGE_STATE = "/workspace/lifecycle-stage/change-state"
    static LIFECYCLE_STAGE = "/workspace/lifecycle-stage"
    static LIFECYCLE_STAGE_CHANGE_POSITION = "/workspace/lifecycle-stage/change-position"
}