/**
 * This is default toolbar configuration,
 */
export default {
  options: ["inline", "upload", "link", "emoji", "insert"],
  inline: {
    icon: "format-text",
    options: ["bold", "italic", "underline"],
    blockOptions: ["unordered", "ordered"],
    bold: { icon: "bold" },
    italic: { icon: "italic" },
    underline: { icon: "underline" },
    unordered: { icon: "normal-bulletlist" },
    ordered: { icon: "numbered-bulletlist" },
  },
  link: {
    showOpenOptionOnHover: true,
    defaultTargetOption: "_self",
    options: ["link"],
    link: { icon: "link" },
    linkCallback: undefined,
  },
  emoji: {
    options: ["emoji"],
    emoji: { icon: "emoji" },
  },
  upload: {
    icon: "upload",
    options: [
      "image",
      "video",
      // "document"
    ],
    image: { icon: "add-image" },
    video: { icon: "video" },
    document: { icon: "document" },
  },
  insert: {
    options: ["insert"],
    insert: { icon: "document" },
  }
};

/**
 * - add option property to color-picker, emoji.
 */
