import { Box, Typography } from '@mui/material';
import React from 'react';
import { TableWrapper } from '../../../../../../TableStyle';
import NoRecordsFound from '../../../../../UiComponents/NoRecordsFound/NoRecordsFound';
interface LogsTableInterface {
    data: any;
    headers: any;
}
const Table = ({ headers, data }: LogsTableInterface) => {
    const keyToHeaderMap: any = {
        "Sent On": "sent_on",
        "Mobile Number": "mobile",
        "Sent Status": "sent_status",
        "Delivered": "delivered",
        "Read": "read",
        "Failed": "failed",
        "Replied": "replied",
        "Amount": "amount",
        "Amount Details": "amount_details",
        "Refunded": "refunded",
        "Source": "source",
        "Template": "template_name",
        "Template Category": "template_category"
    };
    const colWidth: any = {
        "Sent On": 250,
        "Mobile Number": 200,
        "Sent Status": 200,
        "Delivered": 150,
        "Read": 150,
        "Failed": 150,
        "Replied": 150,
        "Amount": 150,
        "Amount Details": 300,
        "Refunded": 150,
        "Source": 150,
        "Template": 200,
        "Template Category": 200
    };
    return (
        <TableWrapper height={"calc(100vh - 350px)"}>
            <Box sx={{ overflowX: 'auto' }}>
                <div>
                    <Box className="table-header">
                        <Box className="table-row">
                            {headers?.map((header: any, index: number) => {
                                return (
                                    <Box
                                        className={`col-head ${header?.key
                                            .toLowerCase()
                                            .replaceAll(" ", "_")}`}
                                        style={{ minWidth: colWidth[header?.label] + "px" }}
                                        justifyContent={header === "Actions" ? "flex-end" : ""}
                                    >
                                        <Typography sx={{ fontWeight: 500 }}>
                                            {header?.label}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    <Box className="table-body">
                        {data && data?.length === 0 ? (
                            <NoRecordsFound height="calc(100vh - 279px)" />
                        ) : (
                            <React.Fragment>
                                {data && data?.map((row: any, rowIndex: any) => {
                                    return (
                                        <Box key={rowIndex} className={`table-row ${row?.is_active ? "" : "table_row_disabled"}`} >
                                            {headers?.map((header: any, ColumnIndex: any) => {
                                                return (
                                                    <Box
                                                        className={`col-body ${header?.key
                                                            .toLowerCase()
                                                            .replaceAll(" ", "_")}`}
                                                        title={
                                                            row[keyToHeaderMap[header?.key]]
                                                        }
                                                        style={{ minWidth: colWidth[header?.label] + "px" }}
                                                    >
                                                        {row[keyToHeaderMap[header?.label]]}
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </Box>
                </div>
            </Box>
        </TableWrapper>
    )
}
export default Table;