import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useEffect, useState } from "react";
import { ContactsActions } from "../../../../Store/Slices/Contacts/contacts.action";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { getContactTaskOutcome } from "../../../../Store/Slices/Contacts/contacts.selector";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { randomString } from "../../../../Whatsapp/Utils/common";
import AppButton from "../../../../AppComponents/AppButton";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";

const TaskOutcome = () => {
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const TaskOutcome = useAppSelector(getContactTaskOutcome);
    const [outcomeList, setOutcomeList] = useState<any>([]);

    useEffect(() => {
        dispatch(ContactsActions.getContactTaskDetailsOptions({}));
    }, [dispatch]);

    useEffect(() => {
        setOutcomeList(TaskOutcome);
    }, [TaskOutcome]);

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const updatedChildren = Array.from(outcomeList);
        const [movedItem] = updatedChildren.splice(result.source.index, 1);
        updatedChildren.splice(result.destination.index, 0, movedItem);
        setOutcomeList(updatedChildren);
    };

    const addTask = () => {
        const newTask = {
            label: "New Task Outcome",
            name: "New Task Outcome",
        };
        setOutcomeList([...outcomeList, newTask]);
    };

    const handleLabelChange = (index: number, value: string) => {
        if(value.length > 50 ){ return false}
        const updatedList = outcomeList.map((task: any, i: number) =>
            i === index
                ? { ...task, label: value, name: value.split(" ").join("_") } // Create new object with updated values
                : task
        );
        setOutcomeList(updatedList);
    };


    const removeTask = (index: number, task_id: any) => {
        dispatch(ContactsActions.deleteOutcomeById(task_id))
            .then((res: any) => {
                if (res?.payload?.data?.status) {
                    const updatedList = outcomeList.filter((_: any, i: number) => i !== index);
                    setOutcomeList(updatedList);
                } else {
                    showAlert(res?.payload?.data?.error, "error");
                }
            })
            .catch((error: any) => {
                console.error(error);
            })
    };

    const saveConfig = () => {
        let payload = {
            "outcomes" : outcomeList
        }
        dispatch(ContactsActions.setOutcome(payload))
            .then((res: any) => {
                if (res?.payload?.data?.status) {
                    showAlert(res?.payload?.data?.msg, "success");
                } else {
                    showAlert(res?.payload?.data?.error, "error");
                }
            })
            .catch((error: any) => {
                console.error(error);
            })
    }

    return (
        <TaskOutcomeWrap>
            <div className="topmenu">
                <div
                    className="menu-left"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                    }}
                >
                    Task Outcome
                </div>
            </div>
            <div className="body">
                <div className="body-header-text">
                    A Task Outcome refers to the final result or resolution of a specific task. Adding task outcomes helps
                    in tracking and categorizing how tasks are resolved or what action was taken.
                </div>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable-1" type="row">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} style={{ marginLeft: "30px", overflow: "scroll", height: "565px" }}>
                                {
                                    outcomeList.map((task: any, index: any) => (
                                        <Draggable key={task.id} draggableId={task.id} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div style={{ display: "flex" }}>
                                                        <div className="body-text">
                                                            <DragIndicatorIcon
                                                                style={{
                                                                    cursor: "pointer", fontSize: "22px",
                                                                    marginRight: "4px"
                                                                }}
                                                            />
                                                            <input
                                                                type="text"
                                                                value={task.label}
                                                                onChange={(e) => handleLabelChange(index, e.target.value)}
                                                                // onBlur={handleLabelSave} // Save label on blur
                                                                autoFocus
                                                                style={{ width: "100%", border: "none" }}
                                                            />
                                                        </div>
                                                        {index === 0 ? (
                                                            <AddCircleOutlineIcon onClick={addTask} style={{ margin: "12px", cursor: "pointer" }} />
                                                        ) : (
                                                            <DeleteOutlineIcon onClick={() => removeTask(index, task.id)} style={{ margin: "12px", cursor: "pointer" }} />
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div className='footer'>
                    <div>
                        <AppButton onClick={saveConfig} style={{ width: "100px" }}>
                            Save Config
                        </AppButton>
                    </div>
                </div>
            </div>
        </TaskOutcomeWrap>
    );
};

const TaskOutcomeWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
    border-bottom: 2px solid ${theme.palette.default.lightGrey};
  }
  .body {
        width: 939px;
        background-color: white;
        height: 750px;
        margin: 16px;
        .body-header-text {
            padding: 31px;
            width: 80%;
            font-size: 14px;
            opacity: 75%;
        }
        .body-text {
            border: 1px solid lightgray;
            padding: 11px 14px;
            width: 60%;
            margin-bottom: 18px;
            cursor: grab;
            display: flex;
        }
        .footer {
            display: flex;
            gap: 1rem;
            justify-content: flex-end;
            margin: 25px 33px;
        }
    }
`;

export default TaskOutcome;
