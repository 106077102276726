import React from "react";

import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { theme } from "../../../Customization/Theme";
import { useSocket } from "../../hooks/socket-context";

const SocketSessionInfoModel: React.FC = () => {
  const { socket, livechatSocket } = useSocket();
  const handleUseHereBtn = () => {
    window.location.reload();
  };

  const handleCloseTab = () => {
    window.open("about:blank", "_self");
    window.close();
  };

  if (socket && livechatSocket === false) {
    return (
      <React.Fragment>
        <Dialog
          open={true}
          onClose={undefined}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ backgroundColor: "white" }}
        >
          <DialogTitle style={{ lineHeight: "24px" }} id="alert-dialog-title">
            <ErrorIcon
              style={{
                color: theme.palette.default.error,
                verticalAlign: "top",
                marginRight: "0.5rem",
              }}
            />
            Multiple Sessions Detected
          </DialogTitle>
          <DialogContent style={{ maxWidth: "500px !important" }}>
            <DialogContentText id="alert-dialog-description">
              Geta is open in another browser/tab. Click `Use Here` <br />{" "}
              button to use Geta in this tab.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ gap: "1rem" }}>
            <Button
              variant="text"
              style={{ color: theme.palette.default.darkGrey }}
              onClick={handleCloseTab}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={handleUseHereBtn}
              autoFocus={false}
            >
              Use Here
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }

  return null;
};

export default SocketSessionInfoModel;
