import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField
} from "../../../AppComponents/Forms";
import ProtectedAppSubmitButton from "../../../AppComponents/ProtectedComponents/Button/ProtectedAppSubmitButton";
import AppModel from "../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { CreateMarketingListValidations } from "../../../Models/ValidationSchema";
import { useAppDispatch } from "../../../Store";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import { ReactComponent as FailedIcon } from "../../../assets/images/Failed.svg";
import { CreateListWrap } from "../../ContactMarketingList/ContactMarketingListStyle";

interface CreateListModalProps {
  toggleModalCreate: () => void;
  isShowing: boolean;
}

const CreateAutomationModal: React.FC<CreateListModalProps> = ({
  toggleModalCreate,
  isShowing,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const [userInput, setUserInput] = useState<any>({
    name: "",
  });

  const handleSubmit = (values: any, SubmitProps: any) => {
    // Handle form submission here
    SubmitProps.setSubmitting(true);
    dispatch(AutomationBuilderActions.postCreateAutomation(values))
      .then((res: any) => {
        if (res?.payload?.data.status === "Success") {
          setUserInput({ name: "" });
          const id = res?.payload?.data?.automation?.automationId
          navigate(`/automation/edit/${id}`);
          toggleModalCreate();
          showAlert(res?.payload?.data?.status, "success");
          SubmitProps.setSubmitting(false);
        } else {
          showAlert(res?.payload?.data.error, "error");
        }
      }).catch((err: any) => {
        showAlert(
          err?.payload?.data?.error || "Something went wrong",
          "error"
        );
      }).finally(() => {
        SubmitProps.setSubmitting(false);
      });
  };

  return (
    <AppModel
      isShowing={isShowing}
      onClose={() => { }}
      divStyle={{
        width: "872px",
        gap: "0.5rem",
        overflowY: "hidden",
        padding: "0px",
      }}
    >
      <CreateListWrap className="automationWrapper">
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmit(values, submitProps);
            setUserInput(values);
          }}
          validationSchema={CreateMarketingListValidations}
        >
          <div className="answered-popup">
            <div className="answered-heading">
              <p>Create Automation</p>
            </div>
            <div className="close-icon">
              <IconButton onClick={toggleModalCreate}>
                <FailedIcon />
              </IconButton>
            </div>
          </div>
          <div className="input-div">
            <AppFormField
              name="name"
              label="Automation name"
              placeholder="Enter a name"
            />
          </div>

          <div className="listitem">
            <ul>
              <li>
                Choose a name that clearly reflects the purpose or criteria of
                the list. For example, if you're targeting customers interested
                in a particular product category, you might name the automation
                "ProductX Enthusiasts."
              </li>
              <li>
                Keep the name concise and descriptive so you can easily identify
                the list later when selecting recipients for your campaigns.
              </li>
              <li>
                Once you've entered the name, click "Save" to finalize the
                creation of your automation.
              </li>
            </ul>
          </div>
          <div className="btn">
            <div style={{ display: "flex", gap: "1rem" }}>
              <Box>
                <AppButton width={"150px"} variant="grey" onClick={() => toggleModalCreate()}>
                  Cancel
                </AppButton>
              </Box>
              <ProtectedAppSubmitButton
                moduleId="automation_crud"
                specificPermissionKey="create"
                variant="cta"
                title={"Save"}
                style={{
                  width: "150px !important",
                }}
              />
            </div>
          </div>
        </AppForm>
      </CreateListWrap>
    </AppModel>
  );
};

export default CreateAutomationModal;
