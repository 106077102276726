import React, { useEffect, useState } from "react";
import { Box, IconButton, Checkbox } from "@mui/material";
import { NotAnsweredTableWrapper, Scroll } from "../../BotKnowledgeStyles";
import { ReactComponent as EditIcon } from "../../../../../assets/images/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/deletegrey.svg";
import NoDataFound from "./NoDataFound";
import useModal from "../../../../../Hooks/useModel/useModel";
import { useAppDispatch } from "../../../../../Store";
import { useSelector } from "react-redux";
import {
  AnsweredFaqQuestionOptionData,
  AnsweredFlowOptionData,
} from "../../../../../Store/Slices/Faq/UserQueries/userquery.selectors";
import { FaqUserQueryAction } from "../../../../../Store/Slices/Faq/UserQueries/userquery.actions";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { updateUserInput } from "../../../../../Store/Slices/Faq/UserQueries/userquery.slice";
import { formatString } from "../../../../../Utils";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { ConversationService } from "../../../../../Services/Apis/ConversationService";
import AnsNotAnsModal from "./AnsNotAnsModal";
import moment from "moment";
import { ReactComponent as QueryIcon } from "../../../../../assets/images/query.svg";

interface Props {
  SourceListing: any;
  sourceType: string;
  id: any;
  open: boolean;
  setSelectedDocumentIds?: any;
  selectedDocumentIds?: any;
  anchorEl: HTMLButtonElement | null;
  handleClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    documentId: string
  ) => void;
  handleClose: () => void;
  getDocumentByID?: any;
  toggle?: any;
  setMode?: any;
  toggleConfirmModal?: any;
  selectedDocumentId: any;
  setSelectedDocumentId: any;
  setLocalValues: any;
  localValues: any;
  loading: boolean;
}

const NotAnsweredTable: React.FC<Props> = ({
  SourceListing = [],
  id,
  setSelectedDocumentIds,
  selectedDocumentIds,
  sourceType,
  toggleConfirmModal,
  selectedDocumentId,
  setSelectedDocumentId,
  setLocalValues,
  localValues,
  loading = true,
}) => {
  // Initial Form Values
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState<any>({
    question: "",
    response: [],
    response_or_flow: "Add Response",
    journey: null,

    text: "",
    media: {
      file: { url: "" },
      image: { url: "" },
      video: { url: "" },
    },

    language: "english",

    notansewred: null,
    qid: null,
  });

  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  let { bot_id } = useParams();
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };
  const currentBot = useSelector(useCurrentBot);
  // Select And Unselct State
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const FaqQuestion = useSelector(AnsweredFaqQuestionOptionData);
  const FlowData = useSelector(AnsweredFlowOptionData);
  const [flowJourney, setFlowJourney] = useState<any>({});

  // Handle All Check
  const handleCheckAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDocumentId("");
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allDocumentIds = SourceListing.map(
        (item: any) => item?.conversation_id
      );
      setSelectedDocumentIds(allDocumentIds);
    } else {
      setSelectedDocumentIds([]);
    }
  };
  // Handle Single Check
  const handleSelectChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    documentId: string
  ) => {
    setSelectedDocumentId("");
    const isChecked = event.target.checked;
    setSelectedDocumentIds((prevSelectedIds: any) => {
      let updatedSelectedIds = isChecked
        ? [...prevSelectedIds, documentId]
        : prevSelectedIds.filter((id: any) => id !== documentId);

      // Check if all individual checkboxes are selected
      const allDocumentsSelected = SourceListing.every((item: any) =>
        updatedSelectedIds.includes(item.conversation_id)
      );
      // Update selectAll state based on the allDocumentsSelected flag
      setSelectAll(allDocumentsSelected);

      return updatedSelectedIds;
    });
  };

  const getDropDownOptions = () => {
    dispatch(FaqUserQueryAction.GeteAnsweredFaqQuestionOption(HEADERS));
  };

  const getDropDownFlowOptions = () => {
    const headers = HEADERS;
    dispatch(FaqUserQueryAction.GeteAnsweredFlowOptions(headers));
  };

  useEffect(() => {
    getDropDownOptions();
    getDropDownFlowOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //select all functionality changes
  useEffect(() => {
    const allDocumentIds = SourceListing?.map(
      (item: any) => item?.conversation_id
    );
    let idsArray: any;
    idsArray = [...selectedDocumentIds, ...allDocumentIds];
    idsArray = idsArray.filter(
      (value: any, index: number, array: any) => array.indexOf(value) === index
    );
    selectAll && setSelectedDocumentIds(idsArray);
  }, [SourceListing, localValues]); // eslint-disable-line react-hooks/exhaustive-deps
  // new functions ********************************************************
  const [radioCheck, setRadioCheck] = useState("first");
  const [faqQuestionId, setFaqQuestionId] = useState("");

  const [selectedItem, setSelectedItem] = useState<any>({
    question: "",
    faqQuestionId: "",
  });

  useEffect(() => {
    if (faqQuestionId) {
      const mappedItems = FaqQuestion?.find(
        (item: any) => faqQuestionId === item?.qid
      );
      if (mappedItems?.journey && mappedItems?.journey?.qid.length) {
        setRadioCheck("second");
        setUserInput({
          ...userInput,
          journey: mappedItems?.journey?.qid,
        });
        setFlowJourney(mappedItems?.journey);
      } else {
        setRadioCheck("first");
        setAlreadyValuesFn(mappedItems);
      }
    }
  }, [faqQuestionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAlreadyValuesFn = (
    data: any,
    getPhrasesData?: any,
    key?: string
  ) => {
    let text = "";

    let video: any = {
      url: "",
    };
    let file: any = {
      url: "",
    };
    let image: any = {
      url: "",
    };

    let question = selectedItem?.query;
    let journey = data?.journey;
    let phrases = data?.["phrases"] || [];

    data &&
      data?.["response"]?.length > 0 &&
      data?.["response"]?.map((curElem: any, index: number) => {
        if (curElem?.type === "text") {
          text = curElem?.value || "";
        }
        if (curElem.type === "video") {
          video["url"] = curElem?.value?.url || "";
        }
        if (curElem.type === "file") {
          file["url"] = curElem?.value?.url || "";
        }
        if (curElem.type === "image") {
          image["url"] = curElem?.value?.url || "";
        }
        if (curElem.type === "live_agent") {
          // setValue(3);
        }
      });

    let media = {
      file: file,
      video: video,
      image: image,
    };

    setUserInput({
      ...userInput,
      faqQuestionId: data?.qid,
      question: question,
      journey: journey?.qid || null,
      phrases: phrases,
      text: text || "",
      media: media,
    });
  };

  // Edit Modal
  const useModalEdit = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModalEdit: isShowing, toggleModalEdit: toggle };
  };

  const { isShowingModalEdit, toggleModalEdit } = useModalEdit();

  // onClose modale reset
  const onCloseResetValues = () => {
    setUserInput({});
    setSelectedItem({});
    setFaqQuestionId("");
    setRadioCheck("first");
  };

  //   Add urls API Call
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    // validation ****************
    if (radioCheck === "second") {
      if (!values?.journey) {
        SubmitProps.setFieldError(
          "journey",
          "value required in selected field"
        );
        SubmitProps?.setSubmitting(false);
        return;
      }
    } else {
      if (
        !values?.text ||
        values?.text === "<p></p>\n" ||
        values?.text === "<p></p>↵"
      ) {
        SubmitProps.setFieldError("text", "value required in selected field");
        SubmitProps?.setSubmitting(false);
        return;
      }
    }
    const mappedItems = FlowData?.find(
      (item: any) => values.journey === item?.qid
    );
    let newValues;
    if (values?.journey && radioCheck === "second") {
      newValues = {
        ...values,
        journey: mappedItems,
        response_or_flow: "second",
      };
    } else {
      newValues = {
        ...values,
        journey: undefined,
        response_or_flow: "Add Response",
      };
    }
    dispatch(updateUserInput(newValues));
    // add to variation
    if (faqQuestionId) {
      const headers = {
        appid: JSON.stringify({
          botid: currentBot.bot_id,
        }),
      };
      ConversationService.postNotAnsweredFaqsQuestion(
        {
          qid: faqQuestionId,
          query: selectedItem.query,
          conversation_id: selectedItem.conversation_id,
        },
        headers
      ).then(
        (data: any) => {
          const headers = {
            appid: JSON.stringify({
              botid: currentBot.bot_id,
            }),
          };
          const conversation_id = selectedItem.conversation_id;
          const dataWithID = {
            headers,
            conversation_id,
          };
          dispatch(
            FaqUserQueryAction.RemoveNotAnsConversationByIds(dataWithID)
          ).then((res) => {});
          SubmitProps.setSubmitting(false);
          SubmitProps.resetForm();
          showAlert("Variation added to list", "success");
          if (data) {
            let url = formatString(
              "/bot/{0}/setup/bot-knowledge/faqs/faq-answer/{1}",
              [currentBot.bot_id, faqQuestionId]
            );
            navigate(url);
          }
        },
        (err: any) => {}
      );
    } else {
      // add new ******
      const headers = {
        appid: JSON.stringify({
          botid: currentBot.bot_id,
        }),
      };
      const conversation_id = selectedItem.conversation_id;
      const data = {
        headers,
        conversation_id,
      };
      dispatch(FaqUserQueryAction.RemoveNotAnsConversationByIds(data)).then(
        (res) => {}
      );
      handleRedirect();
    }
    // dispatch(setAnsweredSubmitData(values));
  };
  // handle toggel radio button
  const handleRadioChange = (event: any) => {
    setRadioCheck(event.target.value);
    setFaqQuestionId("");
    setUserInput({
      ...userInput,
      journey: null,
      faqQuestionId: null,
      text: "",
      media: {
        file: { url: "" },
        image: { url: "" },
        video: { url: "" },
      },
    });
  };

  // redirect
  const handleRedirect = () => {
    let url = formatString("/bot/{0}/setup/bot-knowledge/faqs/faq-answer", [
      currentBot.bot_id,
    ]);
    navigate(url);
  };
  const getEditModalData = (row: any) => {
    toggleModalEdit(); //modal
    setSelectedItem({
      ...row,
      faqQuestionId: "",
    });

    setUserInput((prev: any) => ({
      ...prev,
      question: row?.query,
    }));
  };
  return (
    <>
      {SourceListing?.length === 0 ? (
        <NoDataFound loading={loading} data={SourceListing?.length} />
      ) : (
        <Scroll>
          <NotAnsweredTableWrapper>
            <Box className="table-header">
              <Box className="table-row">
                {/* <Box className="col-head">Sr.No</Box> */}
                <Box className="col-head">
                  <Checkbox
                    checked={selectAll}
                    onChange={handleCheckAllChange}
                  />
                </Box>
                <Box className="col-head">
                  Questions
                  <IconButton
                    style={{ paddingLeft: "1rem" }}
                    onClick={() =>
                      setLocalValues({
                        ...localValues,
                        sort: localValues.sort ? false : true,
                      })
                    }
                  >
                    {localValues.sort ? (
                      <QueryIcon />
                    ) : (
                      <QueryIcon style={{ transform: "scale(-1)" }} />
                    )}
                  </IconButton>
                </Box>
                <Box className="col-head">Created On</Box>
                <Box className="col-head"></Box>
              </Box>
            </Box>
            <Box className="table-body">
              {SourceListing?.map((item: any, index: any) => {
                return (
                  <Box className="table-row" key={`${index}` + 1}>
                    {/* <Box className="col-body">{index + 1}</Box> */}
                    <Box className="col-body">
                      <Checkbox
                        checked={selectedDocumentIds.includes(
                          item?.conversation_id
                        )}
                        onChange={(event: any) =>
                          handleSelectChanges(event, item?.conversation_id)
                        }
                        id={item?.conversation_id}
                      />
                    </Box>
                    <Box className="col-body">{item?.query}</Box>

                    <Box className="col-body">
                      {moment(item?.createdAt).format("DD-MM-YYYY HH:MM A")}
                    </Box>
                    <Box className="col-body">
                      <IconButton
                        aria-describedby={id}
                        onClick={() => {
                          getEditModalData(item);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-describedby={id}
                        onClick={() => {
                          setSelectedDocumentIds([]);
                          setSelectedDocumentId(item?.conversation_id);
                          toggleConfirmModal();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </NotAnsweredTableWrapper>
        </Scroll>
      )}

      <AnsNotAnsModal
        isShowingModalEdit={isShowingModalEdit}
        toggleModalEdit={toggleModalEdit}
        onCloseResetValues={onCloseResetValues}
        userInput={userInput}
        handleSubmitBtn={handleSubmitBtn}
        radioCheck={radioCheck}
        handleRadioChange={handleRadioChange}
        FlowData={FlowData}
        FaqQuestion={FaqQuestion}
        setSelectedItem={setSelectedItem}
        setFaqQuestionId={setFaqQuestionId}
        selectedItem={selectedItem}
        faqQuestionId={faqQuestionId}
        type="Not Answered"
      />
    </>
  );
};

export default NotAnsweredTable;
