import { Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppButton from "../../../AppComponents/AppButton";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useAppDispatch } from "../../../Store";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../HOC/HocBackdropLoader";
interface Props extends HocBackdropLoaderProps {
  closeModal: any;
  payload: any;
  selectedDocumentIds?: any;
  SubmitReset?:any;
  selectAll?:any
}
const UnassignBulk : React.FC<Props> = ({
  closeModal,
  payload,
  selectedDocumentIds,
  SubmitReset,
  selectAll,
  setLoading
}) => {
  let payLoad = payload;
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  let count = selectAll ? "All" : `${selectedDocumentIds?.length}`;

  // post call for bulk add tags
  const handleSubmitBtn = () => {
    payLoad["contact_owner"] = null;
    dispatch(ContactsActions.addAssignee(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(res?.payload?.data?.error || "Rejected", "error");
        } else {
          showAlert(
            res?.payload?.data?.message || "Contact unassigned",
            "success"
          );
          SubmitReset();
        }
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Not unassigned", "error");
      })
      .finally(() => {
        delete payLoad.contact_owner;
      });
  };
  return (
    <Box
      sx={{
        minWidth: "671px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "1rem",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", fontSize: "20px" }}>Unassign</span>
        <CloseIcon
          onClick={() => closeModal()}
          sx={{
            // color: theme?.palette?.default?.darkGrey,
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider  />
      <div>
        <Message style={{ fontSize: "1rem", color: "gray" }}>
          Are You Sure Want to Unassign {count} Contacts ?
        </Message>
        <Divider  />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
            gap: "6px",
          }}
        >
          <AppButton
            style={{ width: "115px" }}
            variant="outline"
            startIcon="add"
            onClick={() => closeModal()}
          >
            Cancel
          </AppButton>

          <AppButton
            style={{ width: "115px" }}
            variant="filled"
            startIcon="add"
            onClick={() => handleSubmitBtn()}
          >
            Unassign
          </AppButton>
        </Box>
      </div>
    </Box>
  );
};

export default HocBackdropLoader(UnassignBulk);

const Message = styled("p")`
  font-size: 1rem;
  color: ${theme?.palette?.default?.grey};
  display: flex;
  justify-content: center;
  padding: 16px;
`;
