import React from "react";
import useRolesPermissionsAccess from "../../../Hooks/useRolesPermissionsAccess";

interface Props {
  moduleId: string; // it is compulsory to pass moduleId
  children: React.ReactNode;
  specificPermissionKey?: "create" | "read" | "update" | "delete"
  // [otherProps: string]: any;
}

const ProtectedAppUiElement: React.FC<Props> = ({ moduleId, children, specificPermissionKey }) => {
  const { isValidAccess, isContentReady } = useRolesPermissionsAccess(moduleId, specificPermissionKey);

  if (!isContentReady) return null; // using this approach to avoid flickering
  if (!moduleId) return null;

  if (!isValidAccess) return null;
  return children as React.ReactElement<any>;
};

export default ProtectedAppUiElement;
