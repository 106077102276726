import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
  debounce,
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination";
import { ProtectedAppButton, ProtectedAppUiElement } from "../../../../AppComponents/ProtectedComponents";
import { AppConfig } from "../../../../Config/app-config";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { DefinedCustomDates } from "../../../../Models/defaultSelectOptions";
import { useAppDispatch } from "../../../../Store";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import { resetEmailData } from "../../../../Store/Slices/email/email.slice";
import { TableWrapper } from "../../../../TableStyle";
import TemplateAPIERROR from "../../../../assets/images/No_records_found.svg";
import Danger from "../../../../assets/images/danger-live.svg";
import { BroadCastTitleBar } from "../../Utils/CommomBroadcastStyle";
import { DeleteCannedResponse } from "../../WhatsApp/Templates/TemplatesListing";
import { BROADCAST_EMAIL_FORM_DATA, emailTemplateSchema } from "../Utils/Constants";
import {
  ActionPopover,
  EmailHtmlPreview,
  EmailListingWrpper,
  EmailTemplateListActions
} from "./EmailTemplateListingStyle";
import MediaPicker from "./MediaPicker";
import { ErrorComponent, TemplateListWrapper } from "./TemplatesListingCSS";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";


// interface Props extends LoaderComponentProps {
//   galleryList: any;
//   handleGalleryTemplateList: any;
// }


interface Props  {
  galleryList: any;
  handleGalleryTemplateList: any;
}

const EmailTemplatesListing: React.FC<Props> = ({
  galleryList,
  handleGalleryTemplateList,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorApi, setErrorApi] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [previewId, setPreviewId] = useState<string>("");
  const [isShowingModal, toggleModal] = useState<boolean>(false);
  const [isShowingModal1, toggleModal1] = useState<boolean>(false);
  const [isShowingModal2, toggleModal2] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<any>(null);
  const [pagination, setPagination] = useState<any>();
  const [action, setAction] = useState<string>("");
  // PopOver ================
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const has_low_balance = localStorage.getItem("has_low_balance")
  const [initialValues, setInitialValues] = useState<any>({
    template_name: "",
    email_attachment: "",
    checkbox: [],
    checkboxAll: false,
  });
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    checkbox: [],
    checkboxAll: false,
  });
  // const debouncedSearchTerm = debounce<string>(localValues?.search, 600);
  let { showAlert } = useNotification();
  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_EMAIL_FORM_DATA, null);
    dispatch(resetEmailData({}));
  }, []);      // eslint-disable-line react-hooks/exhaustive-deps
  const getPreviousData = () => {
    const data: any = {
      limit: localValues?.itemsPerPage?.value,
      offset: localValues.currentPage,
      search: localValues?.search,
      // category: localValues?.category,
    };
    setLoading(true)
    dispatch(EmailActions.getEmailTemplatesListings(data))
      .then((res: any) => {
        setList(res?.payload?.data?.results);
        setPagination(res?.payload?.data?.pagination);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousData();
  }, [localValues]);      // eslint-disable-line react-hooks/exhaustive-deps

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }

  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setPreviewId(id);
    setSelectedId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // setPreviewId("")
    setSelectedId("");
  };

  const handleClickDelete = (id: any) => {
    const tempId = id;
    dispatch(EmailActions.deleteEmailTemplateData(tempId))
      .then((res: any) => {
        toggleModal(false);
        getPreviousData();
        // setTagData(res?.payload?.data.results);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    setAnchorEl(null);
    setInitialValues({
      ...initialValues,
      template_name:
        action === "change_name"
          ? initialValues?.template_name || templateData?.name
          : "",
      email_attachment: "",
    });
  }, [isShowingModal2]);    // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
    //your code here
    if (previewId) {
      dispatch(EmailActions.getEmailTempListById(previewId))
        .then((res: any) => {
          let data = res?.payload?.data;
          setTemplateData(data);
        })
        .finally(() => { });
    }
  }, [previewId]);    // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (previewId && action === "change_name" && values.template_name) {
      const payload = {
        name: values.template_name,
      };
      setInitialValues({
        ...initialValues,
        template_name: values.template_name,
      });
      const data = {
        id: previewId,
        payload,
      };
      dispatch(EmailActions.updatetemplateById(data))
        .then((res: any) => {
          if (res?.payload?.data?.status && !res?.payload?.data?.error) {
            toggleModal2(false);
            setAnchorEl(null);
            toggleModal(false);
            getPreviousData();
            SubmitProps.setSubmitting(false);
            values.template_name = "";
            setAction("");
            setPreviewId("");
            // setFieldValue('template_name', '')
          } else {
            showAlert(res?.payload?.data?.error, "error");
            SubmitProps.setSubmitting(false);
          }
        })
        .catch((error: any) => {
          setErrorApi(true);
          showAlert("Template Name Already Exist", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (previewId && action === "copy" && values.template_name) {
      let payload: any = {
        name: values.template_name,
        template_body: templateData?.template_body,
        template_design: templateData?.template_design
          ? templateData?.template_design
          : undefined,
      };
      dispatch(EmailActions.postEmailTemplateCreate(payload))
        .then((res: any) => {
          if (res?.payload?.data?.status && !res?.payload?.data?.error) {
            toggleModal2(false);
            setAnchorEl(null);
            toggleModal(false);
            getPreviousData();
            SubmitProps.setSubmitting(false);
            values.template_name = "";
            setAction("");
          } else {
            showAlert(res?.payload?.data?.error, "error");
            SubmitProps.setSubmitting(false);
          }
        })
        .catch((error: any) => {
          setErrorApi(true);
          showAlert("Template Name Already Exist", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (
      previewId &&
      action === "change_thumbnail" &&
      values.email_attachment
    ) {
      const payload = {
        source: values.email_attachment,
      };
      const data = {
        id: previewId,
        payload,
      };
      dispatch(EmailActions.updateEmailThumbnail(data))
        .then((res: any) => {
          if (res?.payload?.data && !res?.payload?.data?.error) {
            toggleModal2(false);
            setAnchorEl(null);
            toggleModal(false);
            getPreviousData();
            SubmitProps.setSubmitting(false);
            values.email_attachment = "";
            setAction("");
          } else {
            showAlert(res?.payload?.data?.error, "error");
            SubmitProps.setSubmitting(false);
          }
        })
        .catch((error: any) => {
          setErrorApi(true);
          showAlert("Template Name Already Exist", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleSelectChanges = (e?: any, selectedId?: any) => {
    let selectedIdsArray: Array<any> = [...initialValues.checkbox];
    if (e.target.checked && !selectedIdsArray.includes(selectedId)) {
      selectedIdsArray = [...initialValues.checkbox, selectedId];
    } else {
      const idx: any = selectedIdsArray?.indexOf(selectedId);
      selectedIdsArray.splice(idx, 1);
    }
    if (selectedIdsArray?.length === list?.length) {
      setInitialValues({
        ...initialValues,
        checkbox: [...selectedIdsArray],
        checkboxAll: true,
      });
    } else {
      setInitialValues({
        ...initialValues,
        checkbox: [...selectedIdsArray],
        checkboxAll: false,
      });
    }
  };
  const handleCheckAllChange = (e: any) => {
    if (e.target.checked) {
      const allCountries = list?.map((item: any) => item.id);
      setInitialValues({
        ...initialValues,
        checkbox: [...allCountries],
        checkboxAll: true,
      });
    } else {
      setInitialValues({
        ...initialValues,
        checkbox: [],
        checkboxAll: false,
      });
    }
  };

  // Headers For Table
  const header = ["Name", "Created At", "Category"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Name: "name",
    "Created At": "created_time",
    Category: "category",
  };
  // Width For Table Column
  const colWidth: any = {
    Name: "320",
    "Created At": "200",
    Category: "300",
  };

  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    // const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  return (
    <TemplateListWrapper>
      <AppForm
        id="parentCo"
        initialValues={initialValues}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={action !== "change_thumbnail" ? emailTemplateSchema : ""}
      >
        <EmailListingWrpper style={{ padding: "0 1rem" }}>
          <BroadCastTitleBar style={{ padding: "16px 0" }}>
            <div className="left">
              <AppFormSearchField
                name="search"
                placeholder="Search"
                defaultBorder={true}
                divStyle={{
                  marginBottom: "0px",
                  width: "220px",
                }}
                v
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearCallback(e, "search")}
              />
              {initialValues?.checkbox?.length >= 2 ? (
                <AppButton variant="outline" onClick={() => { }}>
                  Delete
                </AppButton>
              ) : null}
            </div>
            <div className="right">
              <ProtectedAppButton
                moduleId="email_broadcast_crud"
                specificPermissionKey="create"
                variant="outline"
                onClick={() => navigate("/broadcast/email/create-broadcast/1")}
                isDisabled={has_low_balance === "true"}
                title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
              >
                Create Broadcast
              </ProtectedAppButton>
              <ProtectedAppButton
                moduleId="email_template"
                specificPermissionKey="create"
                variant="outline"
                onClick={() => navigate("/broadcast/email/create-template")}
              >
                Create Template
              </ProtectedAppButton>
            </div>
          </BroadCastTitleBar>
          {
            loading ? <AppSkeletonTableLoader
              numberOfCol={4}
              numberofRow={10}
              skeletonHeight={30}
              skeletonWidth={"100%"}
              skeletonHeaderHeight={40}
              skeletonHeaderWidth={"100%"}
              tableHeight={`calc(100vh - 288px)`}
            /> :<>
                <TableWrapper height={list?.length ? "calc(100vh - 249px)" : "calc(100vh - 215px)"}>
                  <div>
                    <Box className="table-header">
                      <Box className="table-row">
                        {header?.map((header: string, index: number) => {
                          return (
                            <Box
                              className={`col-head ${header
                                .toLowerCase()
                                .replaceAll(" ", "_")}`}
                              style={{
                                // maxWidth: colWidth[header] + "px",
                                minWidth: colWidth[header] + "px",
                              }}
                            >
                              <Typography sx={{ fontWeight: 500 }}>
                                {header}
                              </Typography>
                            </Box>
                          );
                        })}
                        <Box
                          className="col-head"
                          sx={{
                            maxWidth: "100px",
                            minWidth: "100px",
                            height: "2.969rem",
                          }}
                        >
                          <Typography>Actions</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      className="table-body"
                      style={{ height: "calc(100% - 47px)" }}
                    >
                      {errorApi || list?.length === 0 ? (
                        // <ErrorComponent style={{ border: "none" }}>
                        //   <Box display="flex" justifyContent="center">
                        //     <img width="400" src={TemplateAPIERROR} alt="" />
                        //   </Box>
                        //   <Box className="error-Info">
                        //     <Typography variant="h6" fontSize="20px">
                        //       No Records Found
                        //     </Typography>
                        //     <Box className="err-action">
                        //       <Typography variant="body1" fontSize="16px">
                        //         Create New Template List
                        //       </Typography>
                        //       <Box>
                        //         <ProtectedAppButton
                        //           moduleId="email_template"
                        //           specificPermissionKey="create"
                        //           variant="primarydark"
                        //           onClick={() =>
                        //             navigate("/broadcast/email/create-template")
                        //           }
                        //         >
                        //           Create Template
                        //           <AppMaterialIcons iconName="Add" />
                        //         </ProtectedAppButton>
                        //       </Box>
                        //     </Box>
                        //   </Box>
                        // </ErrorComponent>
                        <NoRecordsFound subText="Create New Template List">
                          <ProtectedAppButton
                            moduleId="email_template"
                            specificPermissionKey="create"
                            variant="primarydark"
                            onClick={() =>
                              navigate("/broadcast/email/create-template")
                            }
                          >
                            Create Template
                            <AppMaterialIcons iconName="Add" />
                          </ProtectedAppButton>
                        </NoRecordsFound>
                      ) : (
                        list?.map((row: any, rowIndex: any) => {
                          return (
                            <Box key={rowIndex} className="table-row">
                              {header?.map((header: any, ColumnIndex: any) => {
                                const isActionColumn = header === "Action";
                                return (
                                  <Box
                                    className={`col-body ${header
                                      .toLowerCase()
                                      .replaceAll(" ", "_")}`}
                                    title={
                                      header === "Created At"
                                        ? formatDateField(row[keyToHeaderMap[header]])
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"
                                    }
                                    style={{
                                      // maxWidth: isActionColumn
                                      //   ? "400px"
                                      //   : colWidth[header] + "px",
                                      minWidth: isActionColumn
                                        ? "400px"
                                        : colWidth[header] + "px",
                                    }}
                                    key={ColumnIndex}
                                  >
                                    <Typography className="table-text">
                                      {header === "Created At"
                                        ? formatDateField(row[keyToHeaderMap[header]])
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"}
                                    </Typography>
                                  </Box>
                                );
                              })}
                              <Box
                                display="flex"
                                sx={{
                                  maxWidth: "100px",
                                  minWidth: "100px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                className="actionColumn"
                              >
                                <IconButton
                                  aria-describedby={id}
                                  onClick={(e: any) => handleClick(e, row?.id)}
                                >
                                  <AppMaterialIcons iconName="MoreVert" />
                                </IconButton>
                                <ActionPopover
                                  id={id}
                                  name="popup"
                                  open={open}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <List>
                                    <ProtectedAppUiElement moduleId="email_template" specificPermissionKey="read">
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            toggleModal1(true);
                                          }}
                                        >
                                          <EmailTemplateListActions>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: " 0.5rem",
                                              }}
                                            >
                                              <AppMaterialIcons iconName="RemoveRedEyeOutlined" />
                                              <p>Preview</p>
                                            </div>
                                          </EmailTemplateListActions>
                                        </ListItemButton>
                                      </ListItem>
                                    </ProtectedAppUiElement>
                                    <ProtectedAppUiElement moduleId="email_template" specificPermissionKey="create">
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            toggleModal2(true);
                                            setAction("copy");
                                          }}
                                        >
                                          <EmailTemplateListActions>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: " 0.5rem",
                                              }}
                                            >
                                              <AppMaterialIcons iconName="FileCopyOutlined" />
                                              <p>Copy</p>
                                            </div>
                                          </EmailTemplateListActions>
                                        </ListItemButton>
                                      </ListItem>
                                    </ProtectedAppUiElement>
                                    {row?.can_update &&
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            toggleModal2(true);
                                            setAction("change_name");
                                          }}
                                        >
                                          <EmailTemplateListActions>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: " 0.5rem",
                                              }}
                                            >
                                              <AppMaterialIcons iconName="EditOutlined" />
                                              <p>Change name</p>
                                            </div>
                                          </EmailTemplateListActions>
                                        </ListItemButton>
                                      </ListItem>}
                                    {row?.can_update &&
                                      <ListItem disablePadding >
                                        <ListItemButton
                                          onClick={() => {
                                            toggleModal2(true);
                                            setAction("change_thumbnail");
                                          }}
                                        >
                                          <EmailTemplateListActions>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: " 0.5rem",
                                              }}
                                            >
                                              <AppMaterialIcons iconName="BrokenImageOutlined" />
                                              <p>Change Thumbnail</p>
                                            </div>
                                          </EmailTemplateListActions>
                                        </ListItemButton>
                                      </ListItem>}
                                    {row?.can_delete &&
                                      <ListItem disablePadding>
                                        <ListItemButton
                                          onClick={() => {
                                            toggleModal(true);
                                          }}
                                        >
                                          <EmailTemplateListActions>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: " 0.5rem",
                                              }}
                                            >
                                              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                                              <p>Delete</p>
                                            </div>
                                          </EmailTemplateListActions>
                                        </ListItemButton>
                                      </ListItem>}
                                  </List>
                                </ActionPopover>
                              </Box>
                            </Box>
                          );
                        })
                      )}
                    </Box>
                  </div>
                </TableWrapper>
                <Box sx={{ pr: "16px" }}>
                  {list?.length ? (
                    <AppPaginationComponent
                      totalCount={pagination?.totalRecords}
                      handlePageChange={handlePageChange}
                      currentPage={localValues?.currentPage}
                      totalPages={pagination?.totalPages}
                      rowsPerPage={localValues?.itemsPerPage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  ) : null}
                </Box>
            </>
          }
          
        </EmailListingWrpper>
        

        <AppModel
          isShowing={isShowingModal1}
          onClose={toggleModal1}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "750px",
            },
          }}
        >
          <EmailHtmlPreview>
            <div className="header">
              <div className="header-top" style={{ alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: "1.4rem",
                    fontWeight: "500",
                  }}
                >
                  Template Preview
                </Typography>
                <span
                  onClick={() => {
                    toggleModal1(false);
                    setAnchorEl(null);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <AppMaterialIcons iconName="close" />
                </span>
              </div>
              <div className="header-left">
                <iframe
                  title="HTML Preview"
                  srcDoc={JSON.parse(templateData?.template_body || `""`) || {}}
                  className="iframe"
                  style={{
                    width: "calc(100% - 40px)",
                    height: "80vh",
                    border: "1px solid #ccc",
                    padding: "5px",
                    margin: "0 auto",
                    display: "block",
                  }}
                />
              </div>
            </div>
          </EmailHtmlPreview>
        </AppModel>

        <AppModel
          isShowing={isShowingModal}
          onClose={toggleModal}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "500px",
            },
          }}
        >
          <DeleteCannedResponse>
            <div className="header">
              <div className="header-left">
                <div className="danger">
                  <img src={Danger} alt="" />
                </div>
                <div className="text">
                  <h4>Delete The Email Template</h4>
                  <p>Are you sure, you want to delete?</p>
                </div>
              </div>
              <div className="header-right">
                <span onClick={() => toggleModal(false)}>
                  <AppMaterialIcons iconName="close" />
                </span>
              </div>
            </div>
            <div className="footer">
              <AppButton
                variant="grey"
                onClick={() => {
                  toggleModal(false);
                  setAnchorEl(null);
                }}
              >
                Cancel
              </AppButton>
              <AppButton
                variant="danger-filled"
                onClick={() => {
                  handleClickDelete(selectedId);
                  setAnchorEl(null);
                }}
              >
                <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                Delete
              </AppButton>
            </div>
          </DeleteCannedResponse>
        </AppModel>
        <AppModel
          isShowing={isShowingModal2}
          onClose={toggleModal2}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "500px",
            },
          }}
          container={() => document.getElementById("parentCo")}
        >
          <DeleteCannedResponse>
            <div className="header">
              <div className="header-left">
                <div className="text">
                  <h4>
                    {action === "copy"
                      ? "Copy Template"
                      : action === "change_thumbnail"
                        ? "Upload thumbnail"
                        : "Change Template Name"}
                  </h4>
                </div>
              </div>
              <div className="header-right">
                <span
                  onClick={() => {
                    toggleModal2(false);
                    setAnchorEl(null);
                    setAction("");
                  }}
                >
                  <AppMaterialIcons iconName="close" />
                </span>
              </div>
            </div>

            {action !== "change_thumbnail" ? (
              <div className="text" style={{ padding: "2.2rem 2rem" }}>
                <label>Template name</label>
                <AppFormField
                  name="template_name"
                  label=""
                  style={{
                    width: "100%",
                  }}
                  placeholder="Enter template name"
                />
              </div>
            ) : (
              <EmailListigFilter style={{ margin: "2rem 14px" }}>
                <MediaPicker
                  name="email_attachment"
                  mediaType="image"
                  sizeLimit={500000000}
                  extensions={[
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "image/svg+xml",
                  ]}
                  className=""
                  divStyle={{ margin: "0rem", width: "auto" }}
                // disabled={isView ? true : false}
                />
                <Typography sx={{ fontSize: "10px", color: "grey" }}>
                  (.jpg, .jpeg .png formats supported
                  {/* max file size 500 MB */})
                </Typography>
              </EmailListigFilter>
            )}
            <div
              className="footer"
              style={{ borderTop: "1px solid rgba(203, 205, 211, 0.3)" }}
            >
              <AppButton
                variant="grey"
                onClick={() => {
                  toggleModal2(false);
                  setAnchorEl(null);
                  setAction("");
                }}
              >
                Cancel
              </AppButton>
              <AppSubmitButton
                title={`${action === "copy" ? "Save" : "Update"}`}
                isValid={false}
                isSubmitting={false}
              ></AppSubmitButton>
            </div>
          </DeleteCannedResponse>
        </AppModel>
      </AppForm >
    </TemplateListWrapper >
  );
};
const EmailListigFilter = styled.div`
  border: 1px dashed ${theme.palette.default.darkGrey};
  max-width: 530px;
  min-width: -webkit-fill-available;
  display: flex;
  padding: 2rem 0;
  margin-top: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default EmailTemplatesListing;
