import React from "react";
import {
  StyledContentWrapper,
  StyledMessageContainer,
  StyledMessageRow,
  StyledMessageText,
  StyledMessageTimeStamps,
} from "../../../../../../socket/Styles/StyledComponents";
import { getCurrentUTCTime, getMessageTime } from "../../../../../../Utils";
import { Avatar } from "@mui/material";
import { useAppSelector } from "../../../../../../Store";
import { useBotConfigStore } from "../../../../../../Store/Slices/botConfig/BotConfigSlice";

interface Props {
  userMessage?: boolean;
  children?: any;
  time?: string;
  [otherProps: string]: any;
}
const WebBotStaticRenderText: React.FC<Props> = React.memo(
  ({
    userMessage = false,
    children = "Hey! We are here to help you! Chat with us to get started. Please Enter your name to continue.",
    time = getCurrentUTCTime(),
    readStatus = "delivered",
    ...otherProps
  }) => {
    const { localBotConfig } = useAppSelector(useBotConfigStore);

    if (!children || children?.length < 1) {
      return null;
    }

    return (
      <StyledMessageRow
        userMessage={userMessage}
        className="Web-StyledMessageRow Web-StyledMessageRowOverflow-Visible"
        style={{
          overflow: "visible !important"
        }}
      >
        <Avatar
          sx={{
            display: userMessage ? "none" : "block",
          }}
          aria-label="recipe"
          src={localBotConfig?.chat_avatar}
          className="Web-ChatAvatar"
        />
        <StyledContentWrapper
          userMessage={userMessage}
          className="Web-StyledContentWrapper"
        >
          <StyledMessageContainer
            userMessage={userMessage}
            className="Web-StyledMessageContainer"
          >
            <StyledMessageText
              dangerouslySetInnerHTML={{
                __html: children,
              }}
              className="Web-StyledMessageText"
              style={{
                color: userMessage
                  ? localBotConfig?.userMessageTextColor
                  : localBotConfig?.botMessageTextColor,
                backgroundColor: userMessage
                  ? localBotConfig?.userMessageBgColor
                  : localBotConfig?.botMessageBgColor,
                borderRadius: userMessage
                  ? "10px 10px 2px 10px"
                  : "10px 10px 10px 0px",
              }}
            />
          </StyledMessageContainer>
          <StyledMessageTimeStamps
            className="Web-StyledMessageTimeStamps"
            userMessage={userMessage}
          >
            {getMessageTime(getCurrentUTCTime())}
          </StyledMessageTimeStamps>
        </StyledContentWrapper>
      </StyledMessageRow>
    );
  }
);

export default WebBotStaticRenderText;
