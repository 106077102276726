import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { useAppDispatch, useAppSelector } from "../../Store";
import { isEmailEnabled } from "../../Store/Slices/email/email.slice";
import EmailWelcome from "../../assets/images/EmailWelcome.png";
import Assign from "../../assets/images/assign.svg";
import {
  Container,
  ContentWrapper,
  IconWrapper,
  InfoText,
  ListWrapper,
} from "./Welcome";
import { useEffect } from "react";
import { emailEnabled } from "../../Store/Slices/email/email.selectors";
import { AppConfig } from "../../Config/app-config";
import { Box, Typography } from "@mui/material";
import { FontSize } from "easy-email-extensions";

function WelcomeEmailBroadcast() {
  const instruction = [
    `Click on the "Import" button and click on it.`,
    `Choose the data source you want to import from. This could be a CSV file or Excel spreadsheet`,
    `Upload your file and map the columns from your data source to the corresponding fields in your market list (e.g. name,email, location)`,
    `Review the mapping to ensure accuracy, then proceed to import the data`,
    `Once the import is complete, your contact will be added to the marketing list, ready for your targeted campaigns`,
  ];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const emailEnable = useAppSelector(emailEnabled);
  const userData: any = AppConfig.getUserData("loggedInUserData")?.data?.user;

  useEffect(() => {
    if (emailEnable) {
      navigate("/broadcast/email/create-template");
    }
  }, [isEmailEnabled]);
  const handleJourney = () => {
    dispatch(isEmailEnabled(true));
  };

  return (
    <Container>
      <ContentWrapper>
        <ListWrapper>
          <img src={EmailWelcome} alt="" />
        </ListWrapper>
        <InfoText>
          <h1>
            Hi {userData?.first_name},<br />
            Welcome to E-mail broadcasting
          </h1>
          <Box sx={{ mb: "1rem" }}>
            <Typography fontSize={"15px"}>
              This feature enables you to seamlessly send messages to multiple
              recipients via email, revolutionizing your communication process.
              With this tool, you can efficiently share newsletters, updates,
              and vital information with your contacts.
            </Typography>
          </Box>
          <Box sx={{ mb: "8px" }}>
            <Typography fontSize={"15px"} variant="h6">
              Key Benifits:
            </Typography>
          </Box>
          <Box>
            <ol>
              <li>
                <Typography fontSize={"14px"}>
                  Efficiency: Send important updates to multiple contacts
                  simultaneously.
                </Typography>
              </li>
              <li>
                <Typography fontSize={"14px"}>
                  Personalization: Customize emails with merge tags or dynamic
                  content to address each recipient individually.
                </Typography>
              </li>
              <li>
                <Typography fontSize={"14px"}>
                  Analytics: Track open rates, clicks, and engagement to improve
                  your communication strategy.
                </Typography>
              </li>
            </ol>
          </Box>

          <Box>
            <Typography fontSize={"13px"}>
              <span style={{ fontWeight: 600 }}>Note:</span> Adhere to email
              marketing regulations and best practices to ensure a positive and
              compliant communication experience for your recipients. <br />
              Experience the power of Email Broadcast to enhance your email
              marketing strategies!
            </Typography>
          </Box>

          {/* <ol type="1">
            {instruction?.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))}
          </ol> */}
          <IconWrapper>
            <img src={Assign} alt="" />
          </IconWrapper>
          <AppButton className="button" onClick={handleJourney}>
            Start Journey
          </AppButton>
        </InfoText>
      </ContentWrapper>
    </Container>
  );
}

export default WelcomeEmailBroadcast;
