import CheckIcon from "@mui/icons-material/Check";
import { Chip } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { AppToolTip } from "../Components/UiComponents/AppToolTip/AppToolTip";
import { theme } from "../Customization/Theme";
import { ReactComponent as ProcessingIcon } from "../assets/images/icons/processingIcon.svg";
import { ReactComponent as CompletedIcon } from "../assets/images/icons/completedIcon.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/deletegrey.svg";
import { ReactComponent as DoneIcon } from "../assets/images/done.svg";
import { ReactComponent as FailedIcon } from "../assets/images/failed_red.svg";
import { ReactComponent as ProgressIcon } from "../assets/images/progress.svg";

interface AppBadgeProps {
  status: string;
  isTooltipAvaliabe?: boolean;
}
const AppBadge = ({ status, isTooltipAvaliabe }: AppBadgeProps) => {
  const [properties, setProperties] = useState<any>({});
  interface StatusProperties {
    label: string;
    color: string;
    backgroundColor: string;
    borderColor: string;
    toolTip?: string;
    padding?: number | string;
    icon: ReactElement;
  }
  // Define the interface for the entire statuses object
  interface StatusesObject {
    pending: StatusProperties;
    ready: StatusProperties;
    uploading: StatusProperties;
    uploaded: StatusProperties;
    deleting: StatusProperties;
    failed: StatusProperties;
    completed: StatusProperties;
    processing: StatusProperties;
    in_progress: StatusProperties;
  }


  function getStatus(status: string) {
    const statusesObject: StatusesObject = {
      pending: {
        label: "In-Progress",
        color: `${theme.palette.default.coffee}`,
        backgroundColor: `${theme.palette.default.earlyDawn}`,
        borderColor: `1px solid ${theme.palette.default.corn}`,
        toolTip: "Bot knowledgebase is getting updated.",
        icon: <ProgressIcon />,
      },

      ready: {
        label: "Ready",
        color: `${theme.palette.default.successNew}`,
        padding: 0,
        backgroundColor: `${theme.palette.default.successNewBackground}`,
        borderColor: `1px solid ${theme.palette.default.successNewBorder}`,
        toolTip: "Bot knowledgebase is ready to be published.",
        icon: <DoneIcon />,
      },
      uploading: {
        label: "Uploading",
        color: `${theme.palette.default.coffee}`,
        backgroundColor: `${theme.palette.default.earlyDawn}`,
        borderColor: `1px solid ${theme.palette.default.corn}`,
        toolTip: " Bot is getting trained with the new knowledgebase.",
        icon: <ProgressIcon />,
      },
      uploaded: {
        label: "Uploaded",
        color: `${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.default.pattensBlue}`,
        borderColor: `1px solid ${theme.palette.primary.main}`,
        toolTip:
          "Bot is trained on the new supplied knowledgebase and now can answer user queries.",
        icon: <CheckIcon style={{ color: `${theme.palette.primary.main}` }} />,
      },
      deleting: {
        label: "Deleting",
        color: `${theme.palette.default.error}`,
        backgroundColor: `${theme.palette.default.errorLighter}`,
        borderColor: `1px solid ${theme.palette.default.error}`,
        toolTip: "Bot knowledgebase is being deleted.",
        icon: <DeleteIcon />,
      },
      failed: {
        label: "Failed",
        color: `${theme.palette.default.error}`,
        backgroundColor: `${theme.palette.default.errorLighter}`,
        borderColor: `1px solid ${theme.palette.default.error}`,
        toolTip: "Failed due to some problem.",
        icon: <FailedIcon />,
      },
      completed: {
        label: "Completed",
        color: theme.palette.default.successNewBorder,
        borderColor: `1px solid ${theme.palette.default.successNewBorder}`,
        backgroundColor: theme.palette.default.successNewBackground,
        icon: <CompletedIcon />,
      },
      processing: {
        label: "In-Progress",
        color: theme.palette.default.Coffee,
        borderColor: `1px solid ${theme.palette.default.paleGold}`,
        backgroundColor: theme.palette.default.EarlyDawn,
        icon: <ProcessingIcon />,
      },
      in_progress: {
        label: "In-Progress",
        color: theme.palette.default.Coffee,
        borderColor: `1px solid ${theme.palette.default.paleGold}`,
        backgroundColor: theme.palette.default.EarlyDawn,
        icon: <ProcessingIcon />,
      },
    };
    return statusesObject[status as keyof StatusesObject];
  }
  useEffect(() => {
    setProperties(getStatus(status));
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  return isTooltipAvaliabe ? (
    <AppToolTip
      tooltipProps={{
        title: properties?.toolTip,
        placement: "top",
      }}
      className="chip"
      iconButtonProps={{ disableRipple: true }}
      IconComponent={
        <Chip
          label={properties?.label}
          icon={properties?.icon}
          style={{
            color: properties?.color,
            backgroundColor: properties?.backgroundColor,
            border: properties?.borderColor,
            padding: "8px",
            borderRadius: "0px",
          }}
        />
      }
    />
  ) : (
    <Chip
      label={properties?.label}
      icon={properties?.icon}
      style={{
        color: properties?.color,
        backgroundColor: properties?.backgroundColor,
        border: properties?.borderColor,
        padding: "8px",
        borderRadius: "0px",
        minWidth: "120px",
      }}
    />
  );
};

export default AppBadge;
