import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { useAppDispatch, useAppSelector } from "../../Store";
import SmsWelcome from "../../assets/images/SMSWelcome.png";
import Assign from "../../assets/images/assign.svg";
import {
  Container,
  ContentWrapper,
  IconWrapper,
  InfoText,
  ListWrapper,
} from "./Welcome";
import { isSmsEnabled } from "../../Store/Slices/SMS/sms.slice";
import { useEffect } from "react";
import { smsEnabled } from "../../Store/Slices/SMS/sms.selectors";
import { AppConfig } from "../../Config/app-config";
import { Box, Typography } from "@mui/material";

function WelcomeSmsBroadcast() {
  const instruction = [
    `Click on the "Import" button and click on it.`,
    `Choose the data source you want to import from. This could be a CSV file or Excel spreadsheet`,
    `Upload your file and map the columns from your data source to the corresponding fields in your market list (e.g. name,email, location)`,
    `Review the mapping to ensure accuracy, then proceed to import the data`,
    `Once the import is complete, your contact will be added to the marketing list, ready for your targeted campaigns`,
  ];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const smsEnable = useAppSelector(smsEnabled);
  const userData: any = AppConfig.getUserData("loggedInUserData")?.data?.user;

  useEffect(() => {
    if (smsEnable) {
      navigate("/broadcast/sms/template");
    }
  }, [isSmsEnabled]);
  const handleJourney = () => {
    dispatch(isSmsEnabled(true));
  };

  return (
    <Container>
      <ContentWrapper>
        <ListWrapper>
          <img src={SmsWelcome} alt="" />
        </ListWrapper>
        <InfoText>
          <h1>
            Hi {userData?.first_name},<br />
            Welcome to Sms broadcasting
          </h1>
          <Box sx={{ mb: "1rem" }}>
            <Typography fontSize={"15px"}>
              This feature empowers you to effortlessly send messages to
              multiple recipients via SMS, streamlining your communication
              process. With this tool, you can efficiently share updates,
              promotions, and essential information with your contacts.
            </Typography>
          </Box>
          <Box sx={{ mb: "8px" }}>
            <Typography fontSize={"15px"} variant="h6">
              Key Benifits:
            </Typography>
          </Box>
          <Box>
            <ol>
              <li>
                <Typography fontSize={"14px"}>
                  Time-saving: Send important updates to multiple contacts
                  simultaneously.
                </Typography>
              </li>
              <li>
                <Typography fontSize={"14px"}>
                  Personalization: Customize messages with merge tags to address
                  each recipient personally.
                </Typography>
              </li>
              <li>
                <Typography fontSize={"14px"}>
                  Privacy: Each recipient receives the message individually,
                  maintaining confidentiality.
                </Typography>
              </li>
            </ol>
          </Box>

          <Box>
            <Typography fontSize={"13px"}>
              <span style={{ fontWeight: 600 }}>Note:</span> Ensure compliance
              with SMS regulations and best practices to maintain a positive
              communication experience. <br />
              Unlock the power of SMS Broadcast to revolutionize your messaging
              strategy!
            </Typography>
          </Box>
          <IconWrapper>
            <img src={Assign} alt="" />
          </IconWrapper>
          <AppButton className="button" onClick={handleJourney}>
            Start Journey
          </AppButton>
        </InfoText>
      </ContentWrapper>
    </Container>
  );
}

export default WelcomeSmsBroadcast;
