// vendors
import { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { v4 as uuid } from "uuid";

// components
import Icon from "../../Components/Icon";
import ImgModal from "./ImgModal";
import NextArrow from "./SliderElements/NextArrow";
import PrevArrow from "./SliderElements/PrevArrow";
import Slide from "./Slide";
import AddSlideButton from "./AddSlideButton";

// styles
import { CarouselDotStyled, SliderWrapperStyled } from "./Carousel.styled";

// slices
import {
  getLanguage,
  getActiveNode,
  setCarouselData,
} from "../../Slices/main.slice";
import { useAppDispatch } from "../../../../Store";

// services
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";

// utils
import {
  getInitialEditiorText,
  getPlainTextData,
  getHTMLContent,
  getCarouselSlides,
} from "../../Utils";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { getChannelWiseText } from "../../Utils/editor";

const INITCAROUSEL = {
  buttons: [],
  image: "",
  subTitle: "",
  title: "",
  text: "",
  type: "carousel",
};

const CarouselNode = (props: NodeProps) => {
  const { channel } = useSelector(useCurrentBot);
  const { node } = props;
  const language = useSelector(getLanguage);
  const activeNode = useSelector(getActiveNode);
  const isActiveNode = activeNode === node.nodeId;
  const response = getCarouselSlides(node, language);
  const [editorState, setEditorState] = useState<any[]>([]);
  const [activeIndex, updateActiveIndex] = useState<number | null>(null);
  const [showErrors, updateError] = useState<boolean>(false);
  const [slides, updateSlides] = useState<any>(response || []);
  const [imageURL, updateImageURL] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const sliderRef = useRef();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (slides && slides.length === 0) {
      updateSlides([{ ...INITCAROUSEL, id: uuid() }]);
      updateActiveIndex(0);
    } else {
      updateActiveIndex(0);
    }
  }, []);

  useEffect(() => {
    if (slides && slides.length > 0) {
      isActiveNode &&
        dispatch(setCarouselData({ type: "carousel", value: slides }));
      const editorStateArr = cloneDeep(editorState);
      slides.map((slide: any, index: number) => {
        if (editorStateArr[index]) {
          const plainText = getPlainTextData(getInitialEditiorText(slide.text));
          if (editorStateArr[index] && plainText !== "") {
            editorStateArr[index] = getInitialEditiorText(slide.text);
          }
        } else {
          editorStateArr[index] = getInitialEditiorText(slide.text);
        }
      });
      setEditorState(editorStateArr);
    }
  }, [slides, isActiveNode]);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    let temporaryEditorState = cloneDeep(editorState);
    if (activeIndex !== null) {
      temporaryEditorState[activeIndex] = editorState;
    }
    setEditorState(temporaryEditorState);
  };

  const handleButtonLabelChange = (
    label: string,
    activeButtonIdx: number | null,
    index: number,
  ) => {
    let temporarySlides = cloneDeep(slides);
    if (activeButtonIdx !== null) {
      const buttonsList = temporarySlides[index]["buttons"];
      const newState = buttonsList.map((button: any, index: number) => {
        if (index === activeButtonIdx) {
          return {
            ...button,
            value: { ...button.value, ...{ text: label } },
          };
        }
        return button;
      });
      temporarySlides[index]["buttons"] = newState;
      updateSlides(temporarySlides);
    }
  };

  const handleButtonDelete = (activeButton: any, index: number) => {
    let temporarySlides = cloneDeep(slides);
    if (activeButton) {
      const buttonsList = temporarySlides[index]["buttons"];
      const newState = buttonsList.filter(
        (button: any) => button.id !== activeButton.id,
      );
      temporarySlides[index]["buttons"] = newState;
      updateSlides(temporarySlides);
    }
  };

  const handleButtonSave = (updatedAction: any, index: number) => {
    let temporarySlides = cloneDeep(slides);
    if (updatedAction.id) {
      const buttonsList = temporarySlides[index]["buttons"];
      const previousValue = buttonsList.find(
        (button: any) => button.id === updatedAction.id,
      );
      const isDifferent = isEqual(previousValue, updatedAction);
      if (previousValue) {
        if (!isDifferent) {
          const newState = buttonsList.map((button: any, index: number) => {
            if (button.id === updatedAction.id) {
              return {
                ...button,
                type: updatedAction.type,
                value: {
                  ...button.value,
                  ...{
                    text: updatedAction.value.text,
                    url: updatedAction.value.url,
                    ...updatedAction.value,
                  },
                },
              };
            }
            return button;
          });
          temporarySlides[index]["buttons"] = newState;
          updateSlides(temporarySlides);
        }
      } else {
        temporarySlides[index]["buttons"] = [
          ...temporarySlides[index]["buttons"],
          ...[updatedAction],
        ];
        updateSlides(temporarySlides);
      }
    }
  };

  const handleButtonOrderChange = (buttonsList: any, index: number) => {
    let temporarySlides = cloneDeep(slides);
    temporarySlides[index]["buttons"] = buttonsList;
    updateSlides(temporarySlides);
  };

  const handlePreviewButtonClick = () => {};

  const handleSubTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    let temporarySlides = cloneDeep(slides);
    temporarySlides[index]["subTitle"] = event.target.value;
    updateSlides(temporarySlides);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    let temporarySlides = cloneDeep(slides);
    temporarySlides[index]["title"] = event.target.value;
    updateSlides(temporarySlides);
  };

  const handleImageURLButtonClick = () => {
    updateImageURL("");
    toggleModal();
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSaveImgURL = (imgURL: string) => {
    updateImageURL(imgURL);
    let temporarySlides = cloneDeep(slides);
    if (activeIndex !== null) {
      temporarySlides[activeIndex]["image"] = imgURL;
      updateSlides(temporarySlides);
    }
    toggleModal();
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 5242880) {
        const formData = new FormData();
        formData.append("source", file);
        ChatbotConsoleService.uploadFile(formData).then((response: any) => {
          let temporarySlides = cloneDeep(slides);
          // @ts-ignore
          temporarySlides[index]["image"] = response?.data?.source;
          updateSlides(temporarySlides);
        });
      } else {
      }
    }
  };

  const handleDelete = (index: number) => {
    let temporarySlides = cloneDeep(slides);
    // @ts-ignore
    temporarySlides[index]["image"] = "";
    updateSlides(temporarySlides);
  };

  const handleDeleteSlide = (index: number) => {
    let temporarySlides = cloneDeep(slides);
    if (temporarySlides.length > 1) {
      temporarySlides.splice(index, 1);
    } else {
      setEditorState([]);
      temporarySlides = [{ ...INITCAROUSEL, id: uuid() }];
    }
    updateSlides(temporarySlides);
  };

  const afterSlideChange = (current: any) => {
    updateActiveIndex(current);
  };

  const onSlideAddClick = () => {
    if (
      activeIndex !== null &&
      slides[activeIndex]["text"] !== "" &&
      slides[activeIndex]["image"] !== ""
    ) {
      updateError(false);
      updateSlides([...slides, ...[{ ...INITCAROUSEL, id: uuid() }]]);
      // @ts-ignore
      sliderRef.current.slickNext();
      activeIndex !== null && updateActiveIndex(activeIndex + 1);
    } else {
      updateError(true);
    }
  };

  const handleEditorBlur = () => {
    updateText();
  };

  const updateText = () => {
    const tempSlides = cloneDeep(slides);
    tempSlides.map((slide: any, index: number) => {
      if (editorState[index]) {
        slide.text = getChannelWiseText(
          getHTMLContent(editorState[index]),
          channel,
        );
      }
    });
    updateSlides(tempSlides);
  };

  return (
    <>
      <Box p={"8px 16px"} pb="16px" position={"relative"}>
        <SliderWrapperStyled isActive={isActiveNode}>
          <Slider
            // @ts-ignore
            ref={(slider) => (sliderRef.current = slider)}
            {...{
              fade: true,
              swipe: false,
              dots: true,
              infinite: false,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1,
              nextArrow: (
                <NextArrow onAddClick={onSlideAddClick} active={isActiveNode} />
              ),
              prevArrow: <PrevArrow />,
              afterChange: (current) => afterSlideChange(current),
              appendDots: (dots) => <ul>{dots}</ul>,
              customPaging: (i) => (
                <CarouselDotStyled key={`carousel-dots-${i + 1}`}>
                  <Icon icon="carousel" size={14} />
                </CarouselDotStyled>
              ),
            }}
          >
            {slides.map((slide: any, index: number) => (
              <Slide
                slide={slide}
                activeNode={isActiveNode}
                index={index}
                error={showErrors}
                onDelete={handleDelete}
                onFileChange={handleFileChange}
                onAddImgUrlButtonClick={handleImageURLButtonClick}
                onTitleChange={handleTitleChange}
                onSubTitleChange={handleSubTitleChange}
                editorState={editorState}
                onEditorBlur={handleEditorBlur}
                onEditorChange={onEditorStateChange}
                onButtonLabelChange={handleButtonLabelChange}
                onButtonSave={handleButtonSave}
                onButtonDelete={handleButtonDelete}
                onButtonPreview={handlePreviewButtonClick}
                onDeleteSlide={handleDeleteSlide}
                onButtonOrderChange={handleButtonOrderChange}
              />
            ))}
          </Slider>
        </SliderWrapperStyled>
        {slides.length === 1 && isActiveNode && (
          <AddSlideButton onClick={onSlideAddClick} />
        )}
      </Box>
      <ImgModal
        open={showModal}
        onClose={toggleModal}
        url={imageURL}
        onSave={handleSaveImgURL}
      />
    </>
  );
};

export default CarouselNode;
