import { Grid } from "@mui/material"
import { AppSubmitButton } from "../../../AppComponents/Forms";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { theme } from "../../../Customization/Theme";


const Actions = (props: any) => {

    return (
        <Wrapper>
            <div style={props.style} >
                <CancleButton >
                    <AppButton onClick={props.handleBack} variant="grey">Cancel</AppButton>

                    <AppSubmitButton minWidth="100px" variant={props?.type === true ? " " : "outline"} title="Add" startIcon="ri-save-line" />
                </CancleButton>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.div`
& {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid ${theme.palette.default.lighterGrey};
    height: 65px;
    padding: 0px 16px;

    
}
`;
const CancleButton = styled.div<{}>`
display:flex;
gap:1rem
`
export default Actions 