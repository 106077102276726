import { Navigate, Route, Routes } from "react-router-dom";
import App404 from "../AppComponents/App404";
import EmailValidation from "../Components/Welcome/EmailValidation";
import LoginPage from "../Components/Welcome/LoginPage";
import NewPasswordPage from "../Components/Welcome/NewPasswordPage";
import ResetPasswordPage from "../Components/Welcome/ResetPasswordPage";
import SignupPage from "../Components/Welcome/SignupPage";
import UserVerificationPage from "../Components/Welcome/UserVerify";
import VerifyMobilePage from "../Components/Welcome/VerifyMobilePage";
import BusniessVerificationPage from "../Components/Welcome/BusniessVerificationPage";

const WelcomeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/agent/verify" element={<UserVerificationPage />} />
      <Route path="/account/verify" element={<BusniessVerificationPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/signup/email=:emailFromUrl" element={<SignupPage />} />
      <Route path="/verify-mobile" element={<VerifyMobilePage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/change-password/:useremail" element={<NewPasswordPage />} />
      <Route path="/verify" element={<EmailValidation />} />
      <Route path="/404" element={<App404 />} />
      {/* <Route path="*" element={<Navigate to="/login" replace />} />  */}
    </Routes>
  );
};

export default WelcomeRoutes;
