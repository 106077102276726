import { Badge, Box, IconButton, Paper } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { broadcastActionToggleOptions } from "../../Components/Broadcast/Email/Utils/Constants";
import { customizedFilter } from "../../Components/Broadcast/Utils/utils";
import { HocBackdropLoader } from "../../HOC/HocBackdropLoader";
import useDebounce from "../../Hooks/useDebounce";
import AppModel from "../../Hooks/useModel/AppModel";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { MarketingListNetworkService } from "../../Store/Slices/MarketingList/MarketingListNetworkService";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../Store/Slices/MarketingList/marketingList.selectors";
import {
  setMarketingListPageNumber,
  updateAllMarketingFilteredData,
  updateMarketingFilteredData,
  updateMarketingListSelecetedRowCount,
} from "../../Store/Slices/MarketingList/marketingList.slice";
import { AppMaterialIcons } from "../AppMaterialIcons";
import AppNoDataLoader from "../AppNoDataLoader";
import { LoaderComponentProps } from "../Loader";
import AppPaginationComponent from "../Pagination/Pagination";
import { convertQueryToMongo } from "../QueryBuilder/utils/utils";
import UploadMarketingData from "../UploadMarketingData/UploadMarketingData";
import AppErrorMessage from "./AppErrorMessage";
import AppFormSelectField from "./AppFormSelectField";
import AppFormFastField from "./FastField/AppFormFastField";
import AppFormToggleButtonFastField from "./FastField/AppFormToggleButtonFastField";
import AppFormQueryBuilder from "./QueryBuilder/AppFormQueryBuilder";
import ResponsiveTable from "./ResponsiveTable";
import { StyledTableToolbar } from "./Styles";
import { saveRecipientFilterData } from "../../Store/Slices/Whatsapp/whatsapp.slice";
import { getBroadcastRecipientFilterData } from "../../Store/Slices/Whatsapp/whatsapp.selectors";

export interface Props extends LoaderComponentProps {
  type: any;
  broadcast_id?: any;
  subscriptionName?: string;
}
const AppMarketingList: React.FC<Props> = ({
  type,
  broadcast_id,
  subscriptionName,
  setLoading
}) => {
  const formkey1 = "broadcast_action";
  const formkey2 = "marketing_list";
  const formkey3 = "filters";
  const formkey4 = "selected_rows";
  const formkey5 = "search_term";
  const dispatch = useAppDispatch();
  const queryModel: any = useModal();
  const { isShowing, toggle } = useModal();
  const { showAlert } = useNotification();
  const { values, setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext<any>();
  const {
    allMarketingListData,
    filteredData,
    pageNumber,
    rowsPerPage,
    totalCount,
    filteredDataCount,
    selectedRowCount,
    unsubscribeCount,
    priority_column,
  } = useAppSelector(marketingListStore);
  const [pagination, setPagination] = useState<any>();
  const [localValues, setLocalValues] = useState<any>({
    currentPage: 0,
    perPage: { label: "10  per page", value: 10 },
  });
  const initialfilterData = useAppSelector(getBroadcastRecipientFilterData);

  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [filterFieldData, setFilterFieldData] = useState<any>(null);
  const [broadCastType] = useState<any>(
    type[0].toUpperCase() + type.slice(1) || ""
  );
  let filters: any = customizedFilter(getIn(values, formkey3));
  const debouncedSearchTerm = useDebounce<string>(
    getIn(values, formkey5),
    1000
  );

  useEffect(() => {
    if (initialfilterData) {
      setFieldValue(formkey3, initialfilterData);
    }
  }, [initialfilterData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setFieldTouched(formkey4, false);
    dispatch(marketingListActions.getAllMarketingListings(type));
    if (
      getIn(values, formkey1) === "broadcast_marketing_list" &&
      getIn(values, formkey2)
    ) {
      let abc: any = customizedFilter(getIn(values, formkey3));
      let temp: any = {
        list_id: getIn(values, formkey2),
        broadcast_id: getIn(values, "id") || broadcast_id?.id,
        queryset: abc?.rules?.length > 0 ? abc : {},
        search: debouncedSearchTerm,
        limit: localValues?.perPage?.value,
        mongoQuery: getIn(values, "filters")?.length !== 0 ? convertQueryToMongo(getIn(values, "filters")) : "",
        offset: localValues?.currentPage,
      };
      getMarketingTableData(temp);
    }

    return () => {
      dispatch(setMarketingListPageNumber(0));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (localValues) {
      let abc: any = customizedFilter(getIn(values, formkey3));
      let temp: any = {
        list_id: getIn(values, formkey2),
        broadcast_id: values?.id || broadcast_id?.id,
        queryset: abc?.rules?.length > 0 ? abc : {},
        search: debouncedSearchTerm,
        mongoQuery: getIn(values, "filters")?.length !== 0 ? convertQueryToMongo(getIn(values, "filters")) : "",
        limit: localValues?.perPage?.value,
        offset: localValues?.currentPage,
      };
      getMarketingTableData(temp);
    }
  }, [localValues]);  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (debouncedSearchTerm?.length) {
      dispatch(setMarketingListPageNumber(0));
      let abc: any = customizedFilter(getIn(values, formkey3));
      let temp: any = {
        list_id: getIn(values, formkey2),
        broadcast_id: values?.id || broadcast_id?.id,
        queryset: abc?.rules?.length > 0 ? abc : {},
        search: debouncedSearchTerm,
        mongoQuery: getIn(values, "filters")?.length !== 0 ? convertQueryToMongo(getIn(values, "filters")) : "",
        limit: localValues?.perPage?.value,
        offset: localValues?.currentPage,
      };
      getMarketingTableData(temp);
    }
  }, [debouncedSearchTerm]);  // eslint-disable-line react-hooks/exhaustive-deps
  const   getMarketingTableData = (temp: any) => {
    if (!temp.list_id) return;
    setLoading(true);
    dispatch(marketingListActions.getMarketingListById(temp))
      .then((res: any) => {
        console.log("🚀 ~ .then ~ res:", res)
        setFieldValue(formkey4, res?.payload?.data?.selection_count);
        setPagination(res?.payload?.data?.pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      perPage: value,
      currentPage: 0,
    });
  };
  const onSelectChangeCallbackFn = (value: any) => {
    setFieldValue(formkey5, "");
    if (getIn(values, formkey1) === "broadcast_marketing_list" && value) {
      let temp: any = {
        list_id: value,
        broadcast_id: getIn(values, "id") || broadcast_id?.id,
        queryset: {},
        search: "",
        limit: localValues?.perPage?.value,
        offset: localValues?.currentPage,
      };
      getMarketingTableData(temp);
    }
  };
  const handleResetFields = () => {
    dispatch(setMarketingListPageNumber(0));
    setFieldValue(formkey3, null);
    setFieldValue(formkey4, 0);
    setFieldTouched(formkey4, false);
    setFieldValue(formkey5, "");
    setHasMoreData(true);
    if (getIn(values, formkey1) === "broadcast_to_contacts") {
      setFieldValue(formkey2, "");
    }
  };
  const onUploadMarketingChangeCallbackFn = (value: any) => {
    setLoading(true);
    dispatch(marketingListActions.getAllMarketingListings(type)).finally(() => {
      handleResetFields();
      setTimeout(() => {
        MarketingListNetworkService.getFieldsTypeMappingList(
          value,
          getIn(values, "id") || broadcast_id?.id
        );
        setFieldValue(formkey2, value);
        onSelectChangeCallbackFn(value);
      }, 200);
    });
  };

  const handleUpdateCheckboxStatus = (temp: any, index?: boolean | number) => {
    MarketingListNetworkService.updateSelectedContactItem(temp)
      .then((res: any) => {
        setLoading(false);
        dispatch(updateMarketingListSelecetedRowCount(res?.data?.count));
        setFieldValue(formkey4, res?.data?.count);
        if (typeof index === "number") {
          dispatch(updateMarketingFilteredData(index));
        }
        if (typeof index === "boolean") {
          dispatch(updateAllMarketingFilteredData(index));
        }
      }).catch((error: any) => { })
      .finally(() => { });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldTouched(formkey4, false);
    setLoading(true);
    if (event.target.checked) {
      let temp = {
        id: "all",
        list_id: getIn(values, formkey2),
        broadcast_id: getIn(values, "id") || broadcast_id?.id,
        data: {
          isSelected: true,
        },
      };
      handleUpdateCheckboxStatus(temp, true);
      return;
    }
    let temp = {
      list_id: getIn(values, formkey2),
      broadcast_id: getIn(values, "id") || broadcast_id?.id,
      id: "all",
      data: {
        isSelected: false,
      },
    };
    handleUpdateCheckboxStatus(temp, false);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    item: any,
    index: number
  ) => {
    setFieldTouched(formkey4, false);
    let temp = {
      list_id: getIn(values, formkey2),
      broadcast_id: getIn(values, "id") || broadcast_id?.id,
      id: item?.id,
      data: {
        isSelected: item?.isSelected ? false : true,
      },
    };
    handleUpdateCheckboxStatus(temp, index);
  };

  const onSearchChangeCallbackFn = (value: any) => {
    if (
      getIn(values, formkey1) === "broadcast_marketing_list" &&
      getIn(values, formkey2)
    ) {
      if (!value) {
        setFieldTouched(formkey4, false);
        dispatch(setMarketingListPageNumber(0));
        let temp: any = {
          list_id: getIn(values, formkey2),
          broadcast_id: getIn(values, "id") || broadcast_id?.id,
          queryset: getIn(values, "filters"),
          search: "",
          mongoQuery: getIn(values, "filters")?.length !== 0 ? convertQueryToMongo(getIn(values, "filters")) : "",
          limit: localValues?.perPage?.value,
          offset: localValues?.currentPage,
        };
        getMarketingTableData(temp);
      }
    }
  };

  const handleFilterBtnClick = () => {
    MarketingListNetworkService.getFieldsTypeMappingList(
      getIn(values, formkey2),
      getIn(values, "id") || broadcast_id?.id
    ).then((res: any) => {
      setFilterFieldData(res?.data?.response);
      if (res?.data?.response?.fields?.length) {
        queryModel?.toggle(true);
      } else {
        showAlert("Filters not found", "error");
      }
    });
  };

  const clearSelectMarketingList = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleSelectAllClick(event);
    setFieldValue(formkey2, "");
    setFieldValue(formkey4, 0);
    dispatch(setMarketingListPageNumber(0));
  };

  const onQueryChangeCallbackFn = (filtersValue: any) => {
    if (!filtersValue) {
      return null;
    }
    dispatch(saveRecipientFilterData(filtersValue))
    setFieldValue(formkey4, 0);
    setFieldTouched(formkey4, false);
    setFieldValue(formkey5, "");
    dispatch(setMarketingListPageNumber(0));
    let temp: any = {
      list_id: getIn(values, formkey2),
      broadcast_id: getIn(values, "id") || broadcast_id?.id,
      queryset: getIn(values, "filters"),
      mongoQuery: getIn(values, "filters")?.length !== 0 ? convertQueryToMongo(getIn(values, "filters")) : "",
      search: "",
      limit: localValues?.perPage?.value,
      offset: localValues?.currentPage,
    };
    getMarketingTableData(temp);
  };

  return (
    <>
      <AppFormToggleButtonFastField
        name={formkey1}
        label="Broadcast Action"
        Options={broadcastActionToggleOptions}
      />
      {getIn(values, formkey1) === "broadcast_marketing_list" ? (
        <div
          style={{
            display: "flex",
            alignItems: "end",
            gap: "2rem",
          }}
        >
          {allMarketingListData?.length ? (
            <>
              <AppFormSelectField
                name={formkey2}
                label="Marketing List"
                Options={allMarketingListData}
                divStyle={{ maxWidth: "70%", marginBottom: 0 }}
                defaultText="Select a List"
                enableSearch
                onChange={onSelectChangeCallbackFn}
                onClear={clearSelectMarketingList}
              />
            </>
          ) : (
            <p>No List Found</p>
          )}
        </div>
      ) : null}

      {getIn(values, formkey2) ? (
        <StyledTableToolbar style={{ padding: 0 }}>
          <div className="top">
            <AppFormFastField
              name={formkey5}
              placeholder="Search contacts"
              divStyle={{ minWidth: "30%" }}
              disableOnEnter={true}
              onChangeCallbackFn={onSearchChangeCallbackFn}
            />

            <IconButton
              onClick={() => {
                handleFilterBtnClick();
              }}
            >
              <Badge
                badgeContent={getIn(values, formkey3)?.rules?.length || 0}
                color="primary"
              >
                <AppMaterialIcons iconName={"FilterList"} />
              </Badge>
            </IconButton>
          </div>

          <div className="bottom">
            <p className="toolbarText">
              {filters?.rules?.length > 0 || getIn(values, formkey5)?.length > 0
                ? ` ${filteredDataCount} users out of `
                : ""}
              {totalCount} users in your audience
            </p>
            <div className="displayFlex">
              {unsubscribeCount > 0 && (
                <p className="toolbarTextred">
                  {unsubscribeCount}
                  &nbsp;unsubscribe count
                </p>
              )}
              {selectedRowCount > 0 && (
                <p className="toolbarTextgreen">
                  {selectedRowCount}
                  &nbsp; selected
                </p>
              )}
            </div>
            <AppErrorMessage
              error={getIn(errors, formkey4)}
              visible={getIn(touched, formkey4)}
            />
          </div>
        </StyledTableToolbar>
      ) : null}

      {priority_column && getIn(values, formkey2) ? (
        <>
          <ResponsiveTable
            columns={priority_column}
            data={filteredData}
            rowsPerPage={rowsPerPage}
            pageNumber={pageNumber}
            totalDataCount={totalCount}
            selectedDataCount={selectedRowCount}
            hasMoreData={hasMoreData}
            name={formkey4}
            setCheckboxFieldValue={setFieldValue}
            handleClick={handleClick}
            handleSelectAllClick={handleSelectAllClick}
            subscriptionName={subscriptionName}
          />

          <AppPaginationComponent
            totalCount={pagination?.totalRecords}
            handlePageChange={handlePageChange}
            currentPage={localValues?.currentPage}
            totalPages={pagination?.totalPages}
            rowsPerPage={localValues?.perPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            title="Contacts"
          />
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "4rem 2rem 2rem 2rem",
            boxShadow: "none important",
            backgroundColor: "white",
          }}
        >
          <Paper
            sx={{
              width: "40%",
              mb: 2,
              boxShadow: "none !important",
              backgroundColor: "transparent",
            }}
          >
            <AppNoDataLoader />
          </Paper>
        </Box>
      )}

      {filterFieldData?.fields?.length ? (
        <AppFormQueryBuilder
          name={formkey3}
          isShowing={queryModel?.isShowing}
          toggle={queryModel?.toggle}
          fields={filterFieldData?.fields}
          operators={filterFieldData?.type_mapping_data}
          onChangeCallbackFn={onQueryChangeCallbackFn}
          options={filterFieldData?.options}
        />
      ) : null}

      <AppModel
        isShowing={isShowing}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "1000px",
          },
        }}
      >
        <AppModel
          isShowing={isShowing}
          divStyle={{
            padding: 0,
          }}
          PaperProps={{
            style: {
              boxShadow: "none",
              width: "1000px",
            },
          }}
        >
          <UploadMarketingData
            toggle={toggle}
            isShowing={isShowing}
            onChangeCallbackFn={onUploadMarketingChangeCallbackFn}
            type={broadCastType}
          />
        </AppModel>
      </AppModel>
    </>
  );
};

export default HocBackdropLoader(AppMarketingList);
