import React, { memo, useState } from "react";
import { theme } from "../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  allMessagesStore,
  removeMessage,
  setAllMessages,
} from "../../Store/Slices/socket/AllMessagesSlice";

import { AppFlowEditor, AppForm } from "../appComponents/Forms";

import { LiveChatData } from "../../Store/Slices/socket/liveChat/chat.selectors";
import { getCurrentUTCTime } from "../../Utils";
import { getLiveEditorValidationSchema } from "../appComponents/Forms/RichEditor/WebLiveChat/constants";
import { getChannelWiseText } from "../appComponents/Forms/RichEditor/WebLiveChat/utils";
import { AppMaterialIcons } from "../appComponents/Icons";
import { MESSAGE_TYPES, SOCKET_CONNECTION_TYPES } from "../utils/Enum";
import {
  getMessageMediaType,
  getObjectByidFromArray,
  isEmptyObject,
} from "../utils/utils";

import { updateSessionData } from "../../Store/Slices/socket/liveChat/chat.slice";

import {
  ChatTextEditorWrapper,
  StyledEditorHeader,
} from "../Styles/MainStyles";
import uniqid from "uniqid";
import useInternetConnection from "../hooks/useInternetConnection";
import WhatsappChooseTemplateBtn from "../appComponents/Forms/RichEditor/WebLiveChat/WhatsappChooseTemplate/WhatsappChooseTemplateBtn";
import { useSocket } from "../hooks/socket-context";
import { useNotification } from "../../Hooks/useNotification/useNotification";

interface Props {}

const AppInputHandler: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const {
    lastNodeResponse,
    sessionId,
    userInfoData,
    showAttachedReplyItem,
    attachedReplyId,
    allMessages,
  } = useAppSelector(allMessagesStore);
  const { currentSessionData } = useAppSelector(LiveChatData);
  const { socket } = useSocket();
  const { isOnline } = useInternetConnection();
  const { showAlert } = useNotification();

  const [isPrivateNoteShown, setIsPrivateNoteShown] = useState<boolean>(false);

  const handleMediaResponse = async (values: any) => {
    let mediaObj = {};
    const textValue =
      getChannelWiseText(
        String(Object?.values(values)?.[0]),
        userInfoData?.channel
      ) || "";
    if (values?.media?.source) {
      mediaObj = {
        type: getMessageMediaType(values?.media?.type),
        isUser: false,
        value: {
          type: getMessageMediaType(values?.media?.type),
          text: textValue || "",
          url: values?.media?.source,
          fileName: values?.media?.name,
        },
        time: getCurrentUTCTime(),
        userId: socket?.id,
        session_id: sessionId,
        conversation_id: lastNodeResponse?.conversation_id,
        message_id: uniqid(),
        replyTo:
          showAttachedReplyItem && attachedReplyId
            ? {
                ...getObjectByidFromArray(
                  allMessages,
                  attachedReplyId,
                  "message_id"
                ),
                isUserReplied: true,
              }
            : null,
      };
    }
    
    return mediaObj;
  };
  const handleTextResponse = async (values: any) => {
    let userInput: string = "";

    if (typeof values === "string") {
      userInput = values || "";
    } else if (typeof values === "object" && !isEmptyObject(values)) {
      let firstValue: string = String(Object?.values(values)[0]) || "";
      userInput = firstValue;
    } else {
      userInput = "";
    }

    if (!userInput || userInput?.length < 1) {
      return;
    }
    const textValue = getChannelWiseText(userInput, userInfoData?.channel);
    let data = {
      type: isPrivateNoteShown
        ? MESSAGE_TYPES.PRIVATE_NOTE
        : MESSAGE_TYPES.TEXT,
      isUser: false,
      value: textValue,
      userId: socket?.id,
      session_id: sessionId,
      conversation_id: lastNodeResponse?.conversation_id,
      time: getCurrentUTCTime(),
      message_id: uniqid(),
      replyTo:
        showAttachedReplyItem && attachedReplyId
          ? {
              ...getObjectByidFromArray(
                allMessages,
                attachedReplyId,
                "message_id"
              ),
              isUserReplied: true,
            }
          : null,
    };

    // dispatch(setAllMessages([data]));
    return data;
  };

  const handleOfflineEvent = () => {
    if (!isOnline) return;
    showAlert("Message not sent. Try again", "error");
    setTimeout(() => {
      socket?.connect();
    }, 10);
  };

  const handleLiveChatSubmitBtn = async (values: any, SubmitProps: any) => {
    SubmitProps?.validateForm();
    SubmitProps?.setSubmitting(false);

    if (!socket?.connected || !isOnline) {
      return handleOfflineEvent();
    }

    let data: any = null;
    if (values?.media?.source && values?.media?.name) {
      data = (await handleMediaResponse(values)) ?? null;
    } else {
      data = (await handleTextResponse(values)) ?? null;
    }
    //add message to store
    dispatch(setAllMessages([data]));
    
    socket?.emit(
      SOCKET_CONNECTION_TYPES.AGENT_CHAT,
      data,
       (error: any, response: any) => {
        // console.warn(`🚀 ~ MSG SENT STATUS:`, { error }, { response });
        if (!error && response) {
          //update session data
          let tempSession = {
            ...currentSessionData,
            date: getCurrentUTCTime(),
            last_message:
              typeof data?.value === "string"
                ? data?.value
                : data?.value?.text ?? "🖼️ Media",
          };
          dispatch(updateSessionData(tempSession));
        } else {
          console.warn("Message not sent. Try again", error);
          // if message not sent, return
          showAlert("Message not sent. Try again", "error");
          dispatch(removeMessage(data));
        }
      }
    );

    //reset form
    SubmitProps.resetForm();
  };

  return (
    <ChatTextEditorWrapper>
      <div className="disable-box" /> {/* This is for disabling the editor */}
      {userInfoData?.channel === "whatsapp" &&
      !userInfoData?.whatsapp_window ? (
        <WhatsappChooseTemplateBtn />
      ) : (
        <AppForm
          initialValues={{ livechat: "", media: null }}
          onSubmit={(values: any, submitProps: any) => {
            handleLiveChatSubmitBtn(values, submitProps);
          }}
          validationSchema={
            isPrivateNoteShown
              ? null
              : getLiveEditorValidationSchema(userInfoData?.channel)
          }
        >
          <StyledEditorHeader>
            <div
              className={`titleBox ${!isPrivateNoteShown && "active"}`}
              onClick={() => setIsPrivateNoteShown(false)}
            >
              <p className="tab-text">Reply</p>
            </div>
            <div
              className={`titleBox ${isPrivateNoteShown && "active"}`}
              onClick={() => setIsPrivateNoteShown(true)}
            >
              <p className="tab-text">Private Note </p>
            </div>
            {isPrivateNoteShown ? (
              <div className={`infoBox`}>
                <AppMaterialIcons
                  iconName="Lock"
                  style={{
                    color: theme.palette.default.error,
                    fontSize: "0.8rem",
                  }}
                />
                Private note: Only viewable by you and your team
              </div>
            ) : null}
          </StyledEditorHeader>
          <div className="appflowweblivechat_wrapper">
            <AppFlowEditor
              label="Shift + enter for new line. Start with '/' to select a Canned Response"
              name="livechat"
              isPrivateNoteShown={isPrivateNoteShown}
            />
          </div>
        </AppForm>
      )}
    </ChatTextEditorWrapper>
  );

  //* return null if not matches anything
  // return null;
};

export default memo(AppInputHandler);
