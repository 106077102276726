import * as Yup from "yup";

export const EditorUrlSchema = Yup.object().shape({
  linkInput: Yup.string().url().required().label("Url"),
});

export const webLiveEditorSchema = Yup.object().shape({
  livechat: Yup.string()
    .label("Field")
    .when(["media"], (key1: any, schema: any) => {
      return key1 !== null && key1?.source !== null
        ? schema.optional()
        : schema.required().min(9, "Field is a required field");
    }),
  media: Yup.mixed().label("Media"),
});
export const whatsappLiveEditorSchema = Yup.object().shape({
  livechat: Yup.string()
    .label("Field")
    .when(["media"], (key1: any, schema: any) => {
      return key1 !== null && key1?.source !== null
        ? schema.optional()
        : schema.required().min(1, "Field is a required field");
    }),
  media: Yup.mixed().label("Media"),
});
export const privateNoteEditorSchema = Yup.object().shape({
  private_note: Yup.string()
    .label("Field")
    .when(["media"], (key1: any, schema: any) => {
      return key1 !== null && key1?.source !== null
        ? schema.optional()
        : schema.required().min(9, "Field is a required field");
    }),
  media: Yup.mixed().label("Media"),
});

export const getLiveEditorValidationSchema = (channelType: string) => {
  if (channelType === "whatsapp") return webLiveEditorSchema;

  return webLiveEditorSchema;
};

export const editorInlineStyles = [
  { type: "BOLD", label: "Bold", toolTip: "Bold", icon: "FormatBold" },
  { type: "ITALIC", label: "Italic", toolTip: "Italic", icon: "FormatItalic" },
  { type: "UNDERLINE", label: "Underline", toolTip: "Underline", icon: "FormatUnderlined" },
  {
    type: "STRIKETHROUGH",
    label: "StrikeThrough",
    toolTip: "Strike Through",
    icon: "FormatStrikethrough",
  },
  {
    type: "unordered-list-item",
    label: "Unordered List",
    toolTip: "Unordered List",
    icon: "List",
  },
  {
    type: "ordered-list-item",
    label: "Ordered List",
    toolTip: "Ordered List",
    icon: "FormatListNumberedRtl",
  },
  // {
  //   type: "LINK",
  //   label: "Link",
  //   toolTip: "Link",
  //   icon: "Link",
  // },
  {
    type: "MEDIA",
    label: "Media",
    toolTip: "Media",
    icon: "FileUploadOutlined",
  },
  {
    type: "EMOJI",
    label: "Emoji",
    toolTip: "Emoji",
    icon: "AddReaction",
  },
];

export const contactProperticesList = [
  "first_name",
  "facebook_profile",
  "twitter_profile",
  "last_name",
  "email",
  "linkedin_profile",
  "email_confirmed",
  "mobile_phone_number",
  "whatsapp_phone_number",
  "country_region",
  "device_type",
  "state_region", "city", "lifecycle_stage", "lifecycle_stage_status", "lost_reason", "contact_owner", "whatsapp_subscription_status", "email_subscription_status",
  "sms_subscription_status", "created_time", "modified_time", "modified_time"
]