import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";

interface FormState {
  step: number;
  data: {
    [key: string]: any;
  };
}

const initialState: FormState = {
  step: 1,
  data: {},
};

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    },
    setData(state, action: PayloadAction<{ [key: string]: any }>) {
      state.data = { ...state.data, ...action.payload };
    },
    resetForm(state) {
      state.step = 1;
      state.data = {};
    },
  },
});

export const { setStep, setData, resetForm } = formSlice.actions;

export const selectStep = (state: RootState) => state.form.step;
export const selectFormData = (state: RootState) => state.form.data;

export default formSlice.reducer;
