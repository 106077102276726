import { createSlice } from "@reduxjs/toolkit";
import { WebhookActions } from "./webhook.actions";

interface initialStateTypes {
  webhook: any;
  getWebhook: any;
  error: any;
  loading: boolean;
}

const initialState: initialStateTypes = {
  webhook: null,
  getWebhook: null,
  error: null,
  loading: false,
};
const ApiKeySlice = createSlice({
  name: "WebhookSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //  get Webhook
      .addCase(WebhookActions.getWebhookAction.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(WebhookActions.getWebhookAction.fulfilled, (state, action) => {
        state.getWebhook = action?.payload?.data?.data;
      })
      .addCase(WebhookActions.getWebhookAction.rejected, (state, error: any) => {
        state.getWebhook = null;
        state.error = error?.data?.error;
      })



      //  Generate API Key
      .addCase(WebhookActions.generateWebhookAction.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(WebhookActions.generateWebhookAction.fulfilled, (state, action) => {
        state.webhook = action?.payload?.data?.data;
      })
      .addCase(WebhookActions.generateWebhookAction.rejected, (state, error: any) => {
        state.webhook = null;
        state.error = error?.data?.error;
      });
  },
});

export default ApiKeySlice.reducer;
