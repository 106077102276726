import styled from "styled-components";
import ContactTask from "./ContactTask"
import { theme } from "../../../../../Customization/Theme";

const AllTaskScreen = () => {
    return (
        <TaskWrap>
            <div className="topmenu">
                <div
                    className="menu-left"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                    }}
                >
                    Task All
                </div>
            </div>
            <ContactTask />
        </TaskWrap>
    )
}
const TaskWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
    padding: 13px;
  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
    border-bottom: 2px solid ${theme.palette.default.lightGrey};
  }
  `;
export default AllTaskScreen