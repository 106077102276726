// vendors
import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import debounce from "lodash/debounce";
import ReactPlayer from "react-player";
import parse from "html-react-parser";

// components
import Icon from "../../../Components/Icon";
import Input from "../../../Components/Input";
import InteractiveContent from "../common/interactive";
import ButtonList from "./ButtonList";

// slice
import {
  getLanguage,
  getActiveNode,
  updateModifiedData,
} from "../../../Slices/main.slice";
import { useAppDispatch } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";

// utils
import { getNodeConfig, getInteractiveMessageData } from "../../../Utils";
import {
  convertHtmlToWhatsappText,
  convertWhatsappToHtmlText,
  getChannelWiseText,
} from "../../../Utils/editor";

type Item = {
  id: string;
  title: string;
  description: string;
};

type Section = {
  title: string;
  rows: Item[];
};

const InteractiveMessageList = (props: NodeProps) => {
  const { node } = props;
  const language = useSelector(getLanguage);
  const { interactive } = getInteractiveMessageData(node, language);

  const [header, updateHeader] = useState(interactive?.header || "");
  const [body, updateBody] = useState(
    convertWhatsappToHtmlText(interactive?.body) || "",
  );
  const [footerText, updateFooterText] = useState<string>(
    interactive?.footer || "",
  );
  const [buttonText, updateButtonText] = useState<string>(
    interactive?.action?.button || "",
  );
  const [sectionsList, updateSectionsList] = useState<Section[]>(
    interactive?.action?.sections || [],
  );

  const activeNode = useSelector(getActiveNode);
  const currentBot = useSelector(useCurrentBot);
  const nodeConfig = getNodeConfig(node.subType);
  const validation = currentBot?.channel
    ? nodeConfig.validation[currentBot?.channel]
    : {};
  const dispatch = useAppDispatch();
  const charLimit = validation?.charLimit;
  const descriptionLimit = validation?.descriptionLimit;
  const CHARACTER_LIMITS = {
    header: validation?.headerMaxLimit,
    body: validation?.bodyMaxLimit,
    footer: validation?.footerMaxLimit,
    buttonLabel: validation?.buttonLabelMaxLimit,
  };
  const isActiveNode = activeNode === node.nodeId;

  const handleDebounceFn = (
    header: string,
    body: string,
    footer: string,
    list: Section[],
    buttonTitle: string,
  ) => {
    let data = {
      type: "list",
      header,
      footer,
      body: getChannelWiseText(body, "whatsapp"),
      action: {
        button: buttonTitle,
        sections: list,
      },
    };
    dispatch(
      updateModifiedData([
        { interactive: data, type: "whatsapp_interactive_list" },
      ]),
    );
  };

  const debounceFn = useCallback(handleDebounceFn, []);

  const handleFooterTextChange = (event: any) => {
    updateFooterText(event.target.value);
  };

  const handleHeaderChange = (data: any) => {
    updateHeader(data);
    debounceFn(data, body, footerText, sectionsList, buttonText);
  };

  const handleBodyTextChange = (bodyText: string) => {
    updateBody(bodyText);
    debounceFn(header, bodyText, footerText, sectionsList, buttonText);
  };

  const handleButtonListChange = (list: Section[]) => {
    updateSectionsList(list);
    debounceFn(header, body, footerText, list, buttonText);
  };

  const handleButtonListTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    if (value.length > CHARACTER_LIMITS.buttonLabel) return;
    updateButtonText(value);
    debounceFn(header, body, footerText, sectionsList, value);
  };

  return (
    <Box sx={{ p: "16px" }}>
      {isActiveNode ? (
        <>
          <Box>
            <InteractiveContent
              characterLimit={CHARACTER_LIMITS}
              isList={true}
              header={header}
              body={body}
              footer={footerText}
              onHeaderChange={handleHeaderChange}
              onBodyChange={handleBodyTextChange}
              onFooterChange={handleFooterTextChange}
            />
          </Box>
          <Box sx={{ pb: "20px" }}>
            Button List Title <sup style={{ color: "red" }}>*</sup>
            <Input
              onChange={handleButtonListTitleChange}
              value={buttonText}
              error={!buttonText.trim()}
              placeholder="Add button title"
              {...{
                helperText: CHARACTER_LIMITS.buttonLabel ? (
                  <Typography variant="body2" align="right">
                    {`${buttonText.length}/${CHARACTER_LIMITS.buttonLabel}`}
                  </Typography>
                ) : (
                  ""
                ),
              }}
            />
          </Box>
          <ButtonList
            list={sectionsList}
            charLimit={charLimit}
            descriptionLimit={descriptionLimit}
            type={node.subType}
            activeNode={isActiveNode}
            onChange={handleButtonListChange}
            addDescription={true}
          />
        </>
      ) : (
        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
          {header.type === "text" && (
            <Typography variant="body1">{header.text}</Typography>
          )}
          {header.type === "document" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "6px",
                  backgroundColor: "rgb(203, 205, 211)",
                  height: "110px",
                }}
              >
                <Icon icon={"article"} size={24} color="#fff" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  color={"#5B73E8"}
                  variant="body1"
                  component={"a"}
                  href={header.link}
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Icon icon={"link"} size={14} />
                  {header.filename.substring(header.filename.indexOf("_") + 1)}
                </Typography>
              </Box>
            </>
          )}
          {header.type === "image" && (
            <Box
              component="img"
              src={header.link}
              sx={{ width: "100%", height: "150px", objectFit: "cover" }}
            />
          )}
          {header.type === "video" && (
            <ReactPlayer
              url={header.link}
              width={"100%"}
              height="150px"
              controls={true}
            />
          )}
          {body && body !== "" && (
            <Typography variant="body1">{parse(body)}</Typography>
          )}
          {footerText && footerText !== "" && (
            <Typography variant="caption">{footerText}</Typography>
          )}
          {sectionsList.length > 0 && (
            <ButtonList
              list={sectionsList}
              charLimit={charLimit}
              descriptionLimit={descriptionLimit}
              type={node.subType}
              activeNode={isActiveNode}
              onChange={handleButtonListChange}
              addDescription={true}
            />
          )}
        </Box>
      )}
    </Box>
  );
};
export default InteractiveMessageList;
