import AppButton from "../../../../../AppComponents/AppButton";
import useModal from "../../../../../Hooks/useModel/useModel";
import { ReactComponent as CrawlurlIcon } from "../../../../../assets/images/url.svg";
import WebsiteAndSitemapTable from "../commonComponents/WebsiteAndSitemapTable";
import WebsiteAndSitemapAddModal from "../commonComponents/WebsiteAndSitemapAddModal";
import {
  AppPagination,
  FaqTextWrap,
  StyledAppInput,
} from "../../BotKnowledgeStyles";
import React, { useEffect, useState } from "react";
import { withLoader } from "../../../../../AppComponents/Loader";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import {
  SourceList,
  SourceListCount,
} from "../../../../../Store/Slices/Faq/Source/source.selectors";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { FaqSourceAction } from "../../../../../Store/Slices/Faq/Source/source.actions";
import { debounce } from "@mui/material";
import DeleteConfirmationModal from "../commonComponents/DeleteConfirmationModal";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/images/Searchicon.svg";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import AppPaginationComponent from "../../../../../AppComponents/Pagination/Pagination";

const FaqSitemap: React.FC = (props: any) => {
  const sourceType = "sitemap";
  const sourceListCount = useAppSelector(SourceListCount);
  const params = useParams();
  const { isShowing, toggle } = useModal();
  let { showAlert } = useNotification();
  let dispatch = useAppDispatch();
  let websiteUrlData = useAppSelector(SourceList);
  // selected ides
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);

  const { bot_id } = params;

  // modals
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();
  // Setting Values Locally
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "10 per page", value: 10 },
    currentPage: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
  });

  // api call to get website list
  const getWebsiteList = () => {
    props.setLoading(true);
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    const data: any = {
      HEADERS,
      sourceType,
      offset: localValues.currentPage,
      limit: localValues.itemsPerPage.value,
      query: localValues.query,
    };
    dispatch(FaqSourceAction.GeteSourceListing(data))
      .then((res: any) => {
        props.setLoading(false);
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        props.setLoading(false);
      });
  };
  // Function To Delete website
  const deletSourceByDocumentId = () => {
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    let documentIds;
    if (selectedDocumentIds) {
      documentIds = selectedDocumentIds;
    } else {
      documentIds = selectedDocumentIds;
    }
    const data = {
      HEADERS,
      documentIds,
    };

    dispatch(FaqSourceAction.DeleteSourceByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          getWebsiteList();
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
    setSelectedDocumentIds([]);
    toggleConfirmModal();
  };

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };
  const handleChangeRowsPerPage = (event: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: event,
      currentPage: 0,
    });
  };

  // render list data*************

  useEffect(() => {
    if (websiteUrlData?.lenght === 0 || websiteUrlData?.lenght === undefined) {
      getWebsiteList();
    } else {
      if (websiteUrlData?.lenght > 0) {
        getWebsiteList();
      }
    }
  }, [localValues]);

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  return (
    <>
      <FaqTextWrap width={"640px"}>
        <div className="top-header">
          <div className="header-left">
            <p>
              Leverage your website's sitemap for an intelligent bot! Share the
              sitemap and witness the magic unfold. Our bot will utilize it as a
              knowledge base to respond accurately to user queries. Try it out
              now!
            </p>
          </div>
          <div className="serach-class">
            {selectedDocumentIds.length ? (
              <span className="delete-button">
                <AppButton
                  onClick={() => toggleConfirmModal()}
                  variant="danger-filled"
                >
                  Delete <DeleteIcon />
                </AppButton>
              </span>
            ) : null}
            <div className="faq-search">
              <StyledAppInput>
                <input
                  type="text"
                  onChange={(e: any) => handleSelectSearch(e)}
                  name="query"
                  id="query"
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
            <div className="button">
              <AppButton onClick={toggle}>
                Crawl Sitemap
                <AppMaterialIcons iconName="LinkOutlined" />
              </AppButton>
            </div>
          </div>
        </div>

        {/* URL Listing Table */}
        <WebsiteAndSitemapTable
          type="sitemap"
          listData={websiteUrlData || []} //websit list data
          setSelectedDocumentIds={setSelectedDocumentIds}
          selectedDocumentIds={selectedDocumentIds}
          setLocalValues={setLocalValues}
        />

        {/* Modal To Add Website's Sitemap URL */}
        <WebsiteAndSitemapAddModal
          toggle={toggle}
          isShowing={isShowing}
          type="sitemap"
          getListingData={setLocalValues}
        />

        {/* Delete Confirmation Modal */}
        <DeleteConfirmationModal
          toggleConfirmModal={toggleConfirmModal}
          deletSourceByDocumentId={deletSourceByDocumentId}
          selectedDocumentIds={selectedDocumentIds}
          isShowingConfirmModal={isShowingConfirmModal}
          type={"Url"}
        />
      </FaqTextWrap>
      {/* pagination */}
      {websiteUrlData?.length ? (
        <AppPaginationComponent
          totalCount={sourceListCount?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={sourceListCount?.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </>
  );
};

export default withLoader(FaqSitemap);
