export const getTableData = (type: string) => {
    if (type === "email") {
        return {
            header: [
                "Date",
                "From",
                "To",
                "Subject",
                "Status",
                "Error Reason",
                "Price",
                "Source"
            ],
            keyToHeaderMap: {
                Date: "sent_on",
                From: "from_email",
                To: "primary_email",
                Subject: "subject",
                Status: "status",
                "Error Reason": "error_reason",
                Price: "amount",
                Source: "source",
            },
            colWidth: {
                Date: "220",
                From: "250",
                To: "300",
                Subject: "420",
                Status: "150",
                "Error Reason": "200",
                Price: "150",
                Source: "150",
            }
        };
    } else if (type === "sms") {
        return {
            header: [
                "Date",
                "Source",
                "From",
                "To",
                "Body",
                // "Direction",
                "Status",
                "Error Reason",
                "Price",
                "Unit",
                "Total Price",
            ],
            keyToHeaderMap: {
                Date: "sent_on",
                Source: "source",
                From: "sender_id",
                To: "mobile",
                Body: "template_body",
                // Direction: "direction",
                Status: "status",
                "Error Reason": "error_reason",
                Price: "amount",
                Unit: "unit",
                "Total Price": "cost",
            },
            colWidth: {
                Date: "220",
                Source: "120",
                From: "150",
                To: "150",
                Body: "420",
                Status: "120",
                // Direction: "150",
                "Error Reason": "200",
                Price: "150",
                Unit: "150",
                "Total Price": "150",
            }
        };
    } else {
        return {
        header: [
            "Sent On",
            "Mobile",
            "Sent Status",
            "Delivered",
            "Read",
            "Replied",
            "Amount",
            "Source",
            "Template Name",
            "Template Category"
          ],
        
          keyToHeaderMap: {
            "Sent On": "sent_on",
            "Mobile": "mobile",
            "Sent Status": "sent_status",
            "Delivered": "delivered",
            "Read": "read",
            "Replied": "replied",
            "Amount": "amount",
            "Source": "source",
            "Template Name": "template_name",
            "Template Category": "template_category"
          },
        
          
          colWidth: {
            "Sent On": "260",
            Mobile: "190",
            "Sent Status": "250",
            Delivered: "120",
            Read: "120",
            Replied: "150",
            "Amount": "200",
            Source: "150",
            "Template Name": "150",
            "Template Category": "200"
          }

        }
    } 
}
