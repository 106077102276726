
import { useEffect, useState } from "react";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../../../../HOC/HocBackdropLoader";
import {
  BroadcastMainWrapper,
} from "./LogsTableStyles";

import BroadcastLogsTable from "../../../../../../../CommonComponents/BroadcastLogs/BroadcastLogsTable";
import { getTableData } from "../../../../../../Subscription/TrackingExpenses/components/utils";

interface Props extends HocBackdropLoaderProps {}
const DashboardLogs: React.FC<Props> = () => {
  const [tableData, setTableData] = useState<any>({})
  const tableType = "sms"
  useEffect(() => {
    const tData: any = getTableData("sms")
    setTableData(tData)
  }, [])

  return (
    <>
      <BroadcastMainWrapper>
        
                      <BroadcastLogsTable 
                        // categoryForWhatsapp={categoryForWhatsapp}
                        type={tableType} 
                        header={tableData.header} 
                        keyToHeaderMap={tableData.keyToHeaderMap} 
                        colWidth={tableData.colWidth} 
                        // tableDateRange={tableDateRange}
                        showDateRange={false}
                        tableHeight={"calc(100vh - 316px)"}
                        />

      </BroadcastMainWrapper>
    </>
  );
};

// Default Export of component
export default HocBackdropLoader(DashboardLogs);
