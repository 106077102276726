import { Outlet } from "react-router-dom";
import useDocumentTitle from "../../../Hooks/useDocumentTitle";
import CustomizeSidebar from "../Customize/CustomizeSidebar";
import { CustomizeWrap, MainBox } from "./BotKnowledgeStyles";
import { BotKnowledgeMenuOptions } from "../utils/contants";
import { useMemo } from "react";
import { useAppSelector } from "../../../Store";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { isEmptyObject } from "../../../Utils";
import { getRestrictedMenuOptions } from "../../../Utils/rolesUtils";

const BotKnowledge = () => {
  useDocumentTitle("Bot Knowledge");
  const { userConfig }: any = useAppSelector(useAuthStore);

  const memoizedMenuOptions = useMemo(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;

    const result =
      getRestrictedMenuOptions(BotKnowledgeMenuOptions, permissionsData) ?? [];
    // console.error("result", result);
    // const result = menuOptionsKeyName ? userConfig?.payload?.menu_items?.[menuOptionsKeyName] : getRestrictedMenuOptions(sidebarMenuOptions, permissionsData) ?? [];
    return result;
  }, [userConfig?.payload]); // eslint-disable-line react-hooks/exhaustive-deps

  if (memoizedMenuOptions?.length === 0) return null;

  return (
    <CustomizeWrap>
      <CustomizeSidebar Options={memoizedMenuOptions} />
      <MainBox>
        <Outlet />
      </MainBox>
    </CustomizeWrap>
  );
};

export default BotKnowledge;
