import { configureStore, combineReducers } from "@reduxjs/toolkit";
import currentBotReducer from './BotIdSlice';
import {botTrackingCodeReducer, myBotsReducer} from "../../../Services/Apis/ChatbotConsoleApis";
import { projectVersionConfigReducer } from "../../../Services/Apis/CoreApis";

const botConfigReducer = combineReducers({
  projectConfig: projectVersionConfigReducer,
  botTrackingCode:botTrackingCodeReducer
});

export * from './BotIdSlice';
export const dashboardReducer = combineReducers({
  myBots: myBotsReducer,
  currentBot: currentBotReducer,
  currentBotConfiguration: botConfigReducer
});
