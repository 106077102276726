import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";

export const SelectActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 16px 16px 16px 26px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  .select {
    border: 1px solid;
  }

  .main-select {
    display: flex;
    align-items: flex-start;
  }
  .actions {
    display: flex;
    align-items: flex-start;
    gap: 0.6rem;
    .actions-item {
      display: flex;
      gap: 1.4rem;
      .MuiPaper-root {
        border: 1px solid red;
      }
    }

    .control {
      border: 1px solid;
    }
    .MuiIconButton-root {
      background-color: ${theme.palette.default.white};
      width: 26px;
      height: 26px;
      svg{
        font-size: 1.1rem;
      }
    }
  }
`;
