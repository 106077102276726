// vendors
import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";

// components
import {
  Card,
  NodeType,
  Input,
  Switch,
  Icon,
  Select,
} from "./../../ui-components";

// builder
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";
import { useAction } from "../../flow-builder-v2/hooks";
import { getNodeConfig } from "./../../flow-builder-v2/utils";

type CardWrapperProps = {
  id: string;
  activeIndex: number;
  nodeOrder: string;
  children: React.ReactNode;
  isEditState?: boolean;
  onCancelClick: (deleteNode: boolean) => void;
  hideNodeType?: boolean;
  isAction?: boolean
} & typeof defaultProps;

const defaultProps = {
  visibility: true,
  isEditState: false,
  hideNodeType: false,
};

const dropdownOptions = [
  { label: "opt-1", value: "opt-1" },
  { label: "opt-2", value: "opt-2" },
  { label: "opt-3", value: "opt-3" },
  { label: "opt-4", value: "opt-4" },
];

const CardWrapper = (props: CardWrapperProps) => {
  const { nodeOrder, children, isEditState, activeIndex, onCancelClick, hideNodeType, isAction } = props;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [selectedBackNode, setSelectedBackNode] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [actionType, setActionType] = useState("");
  const builderContext = useContext(BuilderContext);
  const messageNode = getNodeConfig("text");
  const { activeFlow, nodes, activeNode } = builderContext;

  const [checked, setChecked] = useState(false);
  const [showToolbar, setShowToolbar] = useState<boolean>(false);
  const { removeNode, cloneNode, deleteAction } = useAction();
  const nodeCntx = useContext(NodeContext);

  const handleModalOpen = () => setShowConfirmationPopup(true);
  const handleModalClose = () => setShowConfirmationPopup(false);

  const getDDPOptions = () => {
    const options: any[] = [];
    nodes.map((node: any, index: number) => {
      if (index < activeIndex) {
        options.push({
          id: node.id,
          label: `node - ${activeFlow.displayOrder}.${index + 1}`,
        });
      }
    });
    return options;
  };

  const handleBackNodeDropdownChange = (event: any) => {
    const selectedOpts = dropdownOptions.filter(
      (option) => option.value === event.target.value
    );
    if (selectedOpts.length === 1) {
      setSelectedBackNode(event.target.value);
    }
  };

  const handleDeleteClick = () => {
    setModalMessage("Are you sure to remove this node?");
    setActionType("delete");
    handleModalOpen();
  };

  const handleDuplicateClick = () => {
    setModalMessage("Are you sure to duplicate this node?");
    setActionType("duplicate");
    handleModalOpen();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleMouseEnter = () => {
    !isEditState && setShowToolbar(true);
  };

  const handleMouseLeave = () => {
    setShowToolbar(false);
  };

  const handleCancelButtonClick = () => {
    onCancelClick && onCancelClick(nodeCntx?.nodeId)
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {isEditState && <Box className="ignore-onClickOutside" sx={{ position: "absolute", top: 0, right: "-48px" }}>
        <Box
          sx={{
            p: "10px",
            backgroundColor: '#fff',
            borderRadius: "8px",
            border: "1px solid #CBCDD3",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            '&:hover': {
              backgroundColor: '#F5F6F8',
              transition: 'background-color 300ms ease-out'
            }
          }}
          component="button"
          onClick={handleCancelButtonClick}
        >
          {nodeCntx.nodeId ?
            <Icon icon="close" size={16} color="#7E8392" />
            :
            <Icon icon="delete" size={16} color="#7E8392" />
          }

        </Box>
      </Box>}
      <Card secondary={isAction} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {showToolbar && !isEditState && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: "-68px",
              pl: "150px",
              transform: "translateY(-50%)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "4px 8px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                borderRadius: "8px",
              }}
            >
              {/* <IconButton size="small" onClick={handleDuplicateClick}>
                <Icon icon="duplicate" color="#7E8392" size={16} />
              </IconButton>
              <IconButton size="small">
                <Icon icon="disable" color="#7E8392" size={16} />
              </IconButton> */}
              <IconButton size="small" onClick={handleDeleteClick}>
                <Icon icon="delete" color="#7E8392" size={16} />
              </IconButton>
            </Box>
          </Box>
        )}
        {isEditState && <>
          <NodeType
            text={'Action'}
            color={'transparent'}
            icon={'actions'}
          // secondaryText={`${activeFlow.displayOrder}.${nodeOrder}`}
          />
          <Divider />
        </>}
        {children}

        {isEditState && (
          <>
            <Divider sx={{ display: "none" }} />
            <Box
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Box sx={{ p: "16px", display: "none" }}>
                <Typography
                  color="#7E8392"
                  fontWeight={400}
                  fontSize="12px"
                  lineHeight="18px"
                >
                  Save the response as custom field
                </Typography>
                <Input
                  placeholder="Variable Name"
                  onChange={() => { }}
                  value=""
                />
              </Box>
              <Divider />
              <Box sx={{ p: "16px", display: 'none' }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  spacing={1}
                >
                  <Stack direction="row" alignItems={"center"} spacing={1}>
                    <Icon icon="back" size={14} color="#7E8392" />
                    <Typography
                      color={"#7E8392"}
                      fontSize="14px"
                      lineHeight="21px"
                      fontWeight={500}
                    >
                      Enable Back
                    </Typography>
                    <Switch checked={checked} onChange={handleChange} />
                  </Stack>
                  <Box>
                    <Select
                      id={"back-node"}
                      value={selectedBackNode}
                      onChange={handleBackNodeDropdownChange}
                      options={getDDPOptions()}
                      disabled={!checked}
                    />
                  </Box>
                </Stack>
              </Box>
            </Box>
          </>
        )}
      </Card>

      <Modal open={showConfirmationPopup} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 420,
            bgcolor: "background.paper",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
          }}
        >
          {" "}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Stack
              flexDirection="row"
              sx={{
                p: "16px",
                borderBottom: "1px solid #F5F6F8",
                width: "100%",
              }}
              justifyContent="space-between"
            >
              <NodeType text="Confirm" noPadding />
              <IconButton size="small" onClick={handleModalClose}>
                <CloseIcon sx={{ fontSize: "24px" }} />
              </IconButton>
            </Stack>
          </Box>
          <Box sx={{ px: "16px", py: "8px" }}>
            <Typography
              fontSize="12px"
              lineHeight="18px"
              fontWeight={400}
              sx={{ color: "#7E8392", pb: "4px" }}
            >
              {modalMessage}
            </Typography>
          </Box>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={{ py: "8px", px: "16px", borderTop: "1px solid #F5F6F8" }}
          >
            <Button variant="outlined" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                handleModalClose();
                if (actionType === "delete") {
                  // removeNode();
                  deleteAction(nodeCntx, props.id);
                }

                if (actionType === "duplicate") {
                  cloneNode();
                }
              }}
            >
              {actionType}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};
CardWrapper.defaultProps = defaultProps;
export default React.memo(CardWrapper);
