import { createAsyncThunk } from "@reduxjs/toolkit";
import { InstagramSettingsService } from "./instagramSettingsService";

export class InstagramSettingsActions {
  // Validate whatsappConfigData Settings
  static instagramConfigData = createAsyncThunk(
    "InstagramSettingsSlice/instagramConfigData",
    (data: any, { dispatch, rejectWithValue }) => {
      return InstagramSettingsService.instagramConfigData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Validate whatsappConfigData Settings
  static instagramConfigList = createAsyncThunk(
    "InstagramSettingsSlice/instagramConfigList",
    ({}: any, { dispatch, rejectWithValue }) => {
      return InstagramSettingsService.getInstagramConfigData()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Validate whatsappConfigData Settings
  static logoutInstagramConfiguration = createAsyncThunk(
    "InstagramSettingsSlice/logoutInstagramConfiguration",
    ({}: any, { dispatch, rejectWithValue }) => {
      return InstagramSettingsService.logoutInstagramConfiguration({})
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  static disconnectSingleInstagramPage = createAsyncThunk(
    "InstagramSettingsSlice/disconnectSingleInstagramPage",
    ({}: any, { dispatch, rejectWithValue }) => {
      return InstagramSettingsService.disconnectSingleInstaPage({})
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );
}
