import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../../../Customization/Theme';

const Badge = styled.p`
  padding: 8px 16px;
  border-radius: 4px;
  color: ${theme.palette.default.black}
`;

const getBadgeDetails = (status: string | undefined,template_data:any) => {
    if(template_data?.status === "REJECTED"){
        return { text: 'Low', title: "Your Message Template has received more negative feedback from your customers. The Message Template may soon be paused or disabled by Meta. Please address the issue that customers are reporting." };
    } 
    switch (status) {
        case 'RED':
            return { text: 'Low', title: "Your Message Template has received more negative feedback from your customers. The Message Template may soon be paused or disabled by Meta. Please address the issue that customers are reporting." };
        case 'YELLOW':
            return { text: 'Medium', title: "Your Message Template has received some negative feedback from multiple customers and may soon be paused or disabled by Meta. You can still use the Message Template." };
        case 'GREEN':
            return { text: 'High', title: "The Message Template has received little to o negative customers feedback." };
        default:
            return { text: 'Unknown', title: "The Message Template has yet to receive quality feedback from your customers." };
    }
};

const QualityBadge = ({ status,template_data }: { status: string | undefined;template_data:any }) => {
    const { text, title } = getBadgeDetails(status,template_data);

    return (
        <Badge title={title}>{text}</Badge>
    );
};

export default QualityBadge;
