import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton, Tooltip } from "@mui/material";
import { useFormikContext } from "formik";
import { useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  divStyle?: any;
  tooltip?: string;
  close?: boolean;

  onColorChange?: (color: ColorResult) => void;
  notSupportedHexCodes?: string[];
  disabled?: boolean;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}
const AppFormColorPicker: React.FC<Props> = ({
  label,
  name,
  divStyle,
  tooltip,
  close,
  onColorChange,
  disabled = false,
  notSupportedHexCodes,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldError,
  } = useFormikContext<any>();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [displayPicker, setDisplayPicker] = useState<any>(false);

  const handleClick = () => {
    setDisplayPicker(!displayPicker);
  };
  const handleClose = () => {
    setDisplayPicker(false);
  };
  const colorResetHandler = () => {
    setFieldValue(name, theme.palette.default.black);
  };

  const handleColorChange = (color: any) => {
    setFieldTouched(name, true);
    if (onColorChange) {
      onColorChange(color?.hex);
    }
    setFieldValue(name, color?.hex);
    if (notSupportedHexCodes?.includes(color?.hex)) {
      //* do not remove setTimeout
      setTimeout(() => {
        setFieldError(name, `Currently ${color?.hex} is not supported`);
        return;
      }, 100);
    }
  };
  return (
    <Div style={divStyle} isError={errors[name]}>
      <Label>
        {label}{" "}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>

      <StyledColorPicker
        disabled
        value={values[name]}
        onBlur={() => {
          setFieldTouched(name);
          setIsFocus(false);
        }}

        onClick={() => disabled ? undefined : handleClick()}
        onFocus={() => setIsFocus(true)}
        style={{
          color: values[name]?.hex,
          border: isFocus
            ? `2px solid ${theme.palette.default.border}`
            : `2px solid transparent`,
        }}
      />
      {displayPicker ? (
        <Popover >
          <Cover onClick={() => handleClose()} />
          <SketchPicker
            color={values[name]}
            onChange={(color) => handleColorChange(color)}
            onSwatchHover={(color, e) => { }}
            
            styles={{
              default: {
                picker: {
                  cursor: disabled ? "not-allowed" : "pointer",
                },
              },
            }}
          />
        </Popover>
      ) : null}

      <StyledDisplayColorBox
        style={{
          bottom: errors[name] && touched[name] ? "36%" : "10%",
        }}
      >
        <div
          className="colorBlock customInput"
          onClick={() => disabled ? undefined : handleClick()}
          style={{
            backgroundColor: values[name],
            cursor: disabled ? "not-allowed" : "pointer",
          }}
        ></div>
        {

          !disabled &&
        <IconButton
        className="resetBlock"
        onClick={() => disabled ? undefined : colorResetHandler()}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        >
          <ClearRoundedIcon />
        </IconButton>
        }
      </StyledDisplayColorBox>

      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </Div>
  );
};

export default AppFormColorPicker;

const StyledDisplayColorBox = styled.div`
  && {
    position: absolute;
    right: 3%;
    height: 28px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .colorBlock {
      height: 24px;
      width: 40px;
      border-radius: 4px;
      cursor: pointer;
    }
    .resetBlock {
      width: 32px;
      height: 32px;
      svg {
        font-size: 1.3rem;
      }
    }
  }
`;

const Popover = styled.div`
  && {
    position: absolute;
    z-index: 2;
  }
`;
const Cover = styled.div`
  && {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
`;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;

const StyledColorPicker = styled.input<StyledProps>`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    border-radius: 4px;
    outline: none !important;
    padding: 0 1rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;

    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
    }
    &:focus {
    }

    &:disabled {
      border: 1px solid ${theme.palette.default.lightGrey};
      cursor: not-allowed;
    }

    .css-1ja9cqb-MuiSvgIcon-root-MuiSelect-icon,
    .css-n9yzhl-MuiSvgIcon-root-MuiSelect-icon {
      color: ${theme.palette.default.border};
    }
  }
`;
