import React from "react";
import AppModel from "../../../Hooks/useModel/AppModel";
import useModal from "../../../Hooks/useModel/useModel";
import UploadTemplate from "../../Broadcast/SMS/BroadCastTemplate/UploadTemplate";
import { WrapperContainer,AnalyticsWrapper,BroadcastListWrapper } from "../../Broadcast/SMS/BroadcastHistory/SmsHistoryStyles";
import EmailLogsTableData from "./EmailLogsTableData";


const EmailDashboardLogs = () => {
 
  const { isShowing, toggle } = useModal();
  
  
  return (
      <React.Fragment>
          <WrapperContainer className="check">
              <AnalyticsWrapper>
              </AnalyticsWrapper>
              <BroadcastListWrapper>
             
                  
                  <EmailLogsTableData  />
              </BroadcastListWrapper>
          </WrapperContainer>
          <AppModel
              isShowing={isShowing}
              onClose={() => toggle()}
              divStyle={{
                  padding: 0,
              }}
              PaperProps={{
                  style: {
                      boxShadow: "none",
                      width: "500px",
                  },
              }}
          >
              <UploadTemplate />
          </AppModel>
      </React.Fragment>
  );
};

// Default Export of component
export default EmailDashboardLogs;
