
import { Grid, IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import { AppFormFastField, AppFormRadioField } from '../../../AppComponents/Forms';
import AppFormCheckboxField from '../../../AppComponents/Forms/AppFormCheckboxField';
import AppReactSelectField from '../../../AppComponents/Forms/AppFormReactSelect';
import { theme } from '../../../Customization/Theme';
import { useAppDispatch } from "../../../Store";
import { setfieldtype } from "../../../Store/Slices/Settings/ContactProperties/contactProperties.slice";
import { ReactComponent as Landline } from "../../../assets/images/icons/Landline.svg";
import { ReactComponent as Mobile } from "../../../assets/images/icons/Mobile.svg";
import { ReactComponent as Checkbox } from "../../../assets/images/icons/checkbox.svg";
import { ReactComponent as DatePicker } from "../../../assets/images/icons/datepicker.svg";
import { ReactComponent as Dropdown } from "../../../assets/images/icons/dropdown.svg";
import { ReactComponent as Email } from "../../../assets/images/icons/email.svg";
import { ReactComponent as MultiSelect } from "../../../assets/images/icons/multiSelect.svg";
import { ReactComponent as Number } from "../../../assets/images/icons/number.svg";
import { ReactComponent as RadioButton } from "../../../assets/images/icons/radio.svg";
import { ReactComponent as TextArea } from "../../../assets/images/icons/textArea.svg";
import { ReactComponent as TextField } from "../../../assets/images/icons/textField.svg";
import Actions from "./Actions";
import CustomBack from "./CustomBack";
import OptionMapping from './OptionMapping';
import { formKeys } from "./formkeys";

interface Props {
    openModel?: boolean
    listData?: any
    closeModel?: any
    sectionId?: any
    selectPropertyData?: any
    allSectionDetails?: any
}
const CustomField: React.FC<Props> = ({
    openModel = false,
    listData = [],
    closeModel,
    sectionId,
    selectPropertyData,
    allSectionDetails
}) => {
    const { setFieldValue } = useFormikContext<any>();

    const dispatch = useAppDispatch()
    const [customFieldId, setCustomFieldId] = useState<any>(1)
    const [fieldProperties, setFieldProperties] = useState<any>([])

    const [selectCustomField] = useState<any>([
        {
            "name": 'Text field',
            "value": "string",
            "icon": <TextField />,
            "color": "#93E9C8",
            "id": 1,
            "type": "string",
            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": formKeys.FIELD,
                    "type": "input",
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                    ],
                    "type": "checkbox"
                },
                {
                    "label": "Field Type",
                    "placeholder": "Text type",
                    "name": "type",
                    "type": "input",
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": false,
                    "name": formKeys.TOOLTIP,
                    "type": "input",
                    "display": "grid"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": false,
                    "name": formKeys.PLACEHOLDER,
                    "type": "input",
                    "display": "grid",
                    // "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }
            ]
        },
        {
            "name": 'Text area',
            "value": "textarea",
            "icon": <TextArea />,
            "color": "#FFB9C5",
            "id": 2,
            "type": "textarea",
            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": "label",
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                    ],
                    "type": "checkbox",
                },

                {
                    "label": "Field Type",
                    "placeholder": "Text type",
                    "name": "type",
                    "type": "input",
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": true,
                    "name": "tooltip",
                    "type": "input",
                    "display": "grid"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": true,
                    "name": "placeholder",
                    "type": "input",
                    "display": "grid",
                    "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }

            ]
        },
        {
            "name": 'Number',
            "value": "number",
            "icon": <Number />,
            "color": "#DFCBFF",
            "id": 3,
            "type": "number",

            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": "label",
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "text type",
                    "name": "type",
                    "type": "input",
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": true,
                    "name": "tooltip",
                    "type": "input",
                    "display": "grid"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": true,
                    "name": "placeholder",
                    "type": "input",
                    "display": "grid",
                    "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }

            ]
        },
        {
            "name": 'Dropdown',
            "value": "dropdown",
            "icon": <Dropdown />,
            "color": "#91EAF5",
            "id": 4,
            "type": "dropdown",

            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": "label",
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "Text type",
                    "name": "type",
                    "type": "input",
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": true,
                    "name": "tooltip",
                    "type": "input",
                    "display": "grid",
                    "position": "left"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": true,
                    "name": "placeholder",
                    "type": "input",
                    "display": "grid",
                    "position": "right",
                    "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                },
                {
                    "label": "",
                    "placeholder": "write",
                    "require": true,
                    "name": formKeys.GROUP,
                    "type": "choies"
                }
            ]
        },
        {
            "name": 'Checkbox',
            "value": "checkbox",

            "icon": <Checkbox />,
            "color": "#FFD1AB",
            "id": 5,
            "type": "checkbox",

            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": "label",
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "Checkbox",
                    "name": "type",
                    "type": "input",
                    "display": "grid",
                    "disabled": true,
                    "position": "left"
                },

                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": true,
                    "name": "tooltip",
                    "type": "input",
                    "display": "grid",
                    "position": "right"
                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }

            ]
        },
        {
            "name": 'Radio button',
            "value": "radio",
            "icon": <RadioButton />,
            "color": "#93E9C8",
            "id": 6,
            "type": "radio",

            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": "label",
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "Radio button",
                    "name": "type",
                    "type": "input",
                    "display": "grid",
                    "disabled": true,
                    "position": "left"
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": true,
                    "name": "tooltip",
                    "type": "input",
                    "display": "grid",
                    "position": "right"
                },

                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                },
                {
                    "label": "",
                    "placeholder": "write",
                    "require": true,
                    "name": formKeys.GROUP,
                    "type": "choies"
                }
            ]
        },
        {
            "name": 'Date picker',
            "value": "datetime",

            "icon": <DatePicker />,
            "color": "#FFB9C5",
            "id": 7,
            "type": "datetime",

            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": formKeys.FIELD,
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "Date Picker",
                    "type": "input",
                    "name": formKeys.TYPE,
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": false,
                    "name": formKeys.TOOLTIP,
                    "type": "input",
                    "display": "grid"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": false,
                    "name": formKeys.PLACEHOLDER,
                    "type": "input",
                    "display": "grid",
                    "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }
            ]
        },

        {
            "name": 'Multiselect',
            "value": "multiselect",

            "icon": <MultiSelect />,
            "color": "#91EAF5",
            "id": 8,
            "type": "multiselect",

            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": "label",
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "MultiSelect",
                    "name": "type",
                    "type": "input",
                    "display": "grid",
                    "disabled": true,
                    "position": "left"
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": true,
                    "name": "tooltip",
                    "type": "input",
                    "display": "grid",
                    "position": "right"
                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                },

                {
                    "label": "",
                    "placeholder": "write",
                    "require": true,
                    "name": formKeys.GROUP,
                    "type": "choies"
                }
            ]

        },
        {
            "name": 'Phone',
            "value": "phone",
            "icon": <Mobile />,
            "color": "#FFD1AB",
            "id": 9,
            "type": "phone",
            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": formKeys.FIELD,
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "phone",
                    // "require": true,
                    "type": "input",
                    "name": formKeys.TYPE,
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": false,
                    "name": formKeys.TOOLTIP,
                    "type": "input",
                    "display": "grid"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": false,
                    "name": formKeys.PLACEHOLDER,
                    "type": "input",
                    "display": "grid",
                    "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }
            ]
        },
        {
            "name": 'Landline',
            "value": "landline",
            "icon": <Landline />,
            "color": "#93E9C8",
            "id": 10,
            "type": "landline",
            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": formKeys.FIELD,
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "landline",
                    // "require": true,
                    "type": "input",
                    "name": formKeys.TYPE,
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": false,
                    "name": formKeys.TOOLTIP,
                    "type": "input",
                    "display": "grid"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": false,
                    "name": formKeys.PLACEHOLDER,
                    "type": "input",
                    "display": "grid",
                    "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }
            ]
        },
        {
            "name": 'Email',
            "value": "email",
            "icon": <Email />,
            "color": "#FFB9C5",
            "id": 11,
            "type": "email",
            "fields": [
                {
                    "label": "Field Label",
                    "placeholder": "Field Label",
                    "require": true,
                    "name": formKeys.FIELD,
                    "type": "input"
                },
                {
                    "label": "",
                    "placeholder": "",
                    // "require": true,
                    "options": [
                        {
                            "label": "Required",
                            "value": "required",
                            "defaultValue": false
                        },
                        {
                            "label": "Read-Only",
                            "value": "read_only",
                            "defaultValue": false
                        },
                        {
                            "label": "Show In Contact Form",
                            "value": "visible_in_form",
                            "defaultValue": false
                        },
                        // {
                        //     "label": "Unique",
                        //     "value": "unique",
                        //     "defaultValue": false
                        // }
                    ],
                    "type": "checkbox"
                },

                {
                    "label": "Field Type",
                    "placeholder": "email",
                    // "require": true,
                    "type": "input",
                    "name": formKeys.TYPE,
                    "disabled": true
                },
                {
                    "label": "Tooltip",
                    "placeholder": "tooltip",
                    // "require": false,
                    "name": formKeys.TOOLTIP,
                    "type": "input",
                    "display": "grid"
                },
                {
                    "label": "Placeholder Text",
                    "placeholder": "placeholder",
                    // "require": false,
                    "name": formKeys.PLACEHOLDER,
                    "type": "input",
                    "display": "grid",
                    "tooltip": "new field"

                },
                {
                    "label": "Section or Sub-section",
                    "placeholder": "select",
                    // "require": false,
                    "name": "parent",
                    "type": "select",
                    "data": allSectionDetails,
                    // "tooltip": "new field"
                }
            ]
        }
    ])
    const AppFormInputField = (name: any, label: any, placeholder: any, disabled: any, require: any, tooltip: any) => {
        return (
            <AppFormFastField
                name={name}
                label={label}
                placeholder={placeholder}
                style={CustomInputField}
                require={require}
                tooltip={tooltip}
                disabled={disabled}
            />
        )
    }
    const AppFormSelectFields = (name: any, label: any, placeholder: any, data: any, require: any, tooltip: any) => {
        return (
            <AppReactSelectField
                name={name}
                label={label}
                placeholder={placeholder}
                //   style={CustomInputField}
                require={require}
                displayKey="label"
                valueKey="id"
                tooltip={tooltip}
                options={data}
                MenuProps={{}}
                menuPlacement="auto"
            />
        )
    }
    useEffect(() => {
        setFieldProperties(selectCustomField[0]?.fields)
        setFieldValue(formKeys.TYPE, 'string')
    }, [selectCustomField]) // eslint-disable-line react-hooks/exhaustive-deps

    const setCustomField = (field: any) => {
        const typefield = field.value

        dispatch(setfieldtype(typefield))
        setCustomFieldId(field.id)

        setFieldProperties(field.fields)
        setFieldValue(formKeys.TYPE, field.value)
    }

    const onDrop = (items: any[], prevIndex: number, nextIndex: number) => {
        setFieldValue("options", items)
    }
    return (
        <Box id="name">
            <CustomBack handleBack={() => closeModel(false)} />
            <Box display={"flex"} sx={{ height: "calc(100vh - 129px)", overflowY: "auto" }}>
                <Box sx={{ width: "50%", padding: "1.5rem", borderRight: `1px solid ${theme.palette.default.lighterGrey}`, overflow: "auto" }}>
                    <Typography className='leftTitle'> Select a custom field </Typography>
                    <Box display={"flex"} flexWrap={"wrap"} gap={"4%"} pt={2}>
                        {selectCustomField.map((res: any) => (
                            <Box sx={{ width: "48%", mb: "1.4rem" }}>
                                <Box onClick={() => setCustomField(res)} style={{ border: res.id === customFieldId ? "2px solid " + res.color : "1px solid lightgray", }}>
                                    <IconButton color="inherit">
                                        {res.icon}
                                    </IconButton>
                                    {res.name}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box sx={{ width: "50%", padding: "0.5rem", }}>
                    <Grid xs={6} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ margin: 0, padding: "1rem", height: "-webkit-fill-available", overflow: "auto", }}>
                        <Typography className='rightTitle'>  Field properties</Typography>

                        <Grid container className="rightDiv" rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ margin: "2% 0%", justifyContent: "space-between", lineHeight: 2 }}>
                            {fieldProperties.map((res: any) => (
                                <>
                                    {res.type === "input" && res?.display !== "grid" ?
                                        AppFormInputField(res.name, res.label, res.placeholder, res.disabled, res.require, res.tooltip)
                                        : null}
                                    {res.type === "checkbox" ?
                                        <CheckBoxField>
                                            {res.options.map((name: any) => (
                                                <AppFormCheckboxField
                                                    label={name.label}
                                                    name={name.value}

                                                />
                                            ))}
                                        </CheckBoxField>
                                        : null}
                                    {res.type === 'select' && res?.display !== "grid" ?
                                        <Grid item spacing={2} xs={12}>
                                            {AppFormSelectFields(res.name, res.label, "", res?.data, res.require, res.tooltip)}
                                        </Grid>

                                        : null}
                                    {res.type === "input" && res?.display === "grid" ?
                                        <Grid item spacing={2} xs={5.7} >
                                            {AppFormInputField(res.name, res.label, res.placeholder, res.disabled, res.require, res.tooltip)}
                                        </Grid>
                                        : null}
                                    {res.type === "select" && res?.display === "grid" ?
                                        <Grid item spacing={2} xs={6}>
                                            {AppFormSelectFields(res.name, res.label, "", res?.data, res.require, res.tooltip)}
                                        </Grid> : null}
                                    {res.type === 'choies' ?
                                        <>
                                            <Grid item spacing={1} xs={12} style={{ color: "rgb(126, 131, 146)", fontSize: "14px", margin: "2% 1%" }}>
                                                {res.label}
                                            </Grid>
                                            <OptionMapping name={'options'} onDrop={onDrop}
                                            />
                                        </>
                                        : null}
                                    {res.type === "radio" && res?.display === "grid" ?
                                        <Grid item spacing={2} xs={6}>
                                            <RadioField>
                                                <AppFormRadioField
                                                    label={res.label}
                                                    name={res.value}
                                                    Options={res.options}
                                                />
                                            </RadioField>
                                        </Grid> : null}
                                    <br /><br /><br />
                                </>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Actions handleBack={() => closeModel(false)} style={{ margin: "10px 14px 14px 0  ", gap: "13px" }} />
        </Box >
    )
}

const DragList = styled("div")`
  & {
    width: 231px;
    height: 37px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
const CustomSelectField = {
    minWidth: "100%",
    maxWidth: "0px",
    border: "1px solid #D9D9D9",
    backgroundColor: 'white'
}
const CustomInputField = {
    border: "1px solid #D9D9D9",
    backgroundColor: 'white',
    width: "50%"
}
const CustomFieldWrapper = styled(Grid)`
&& {
    
   
}
.rightDiv input{
    width:98%
}
`;
const CustomFieldCancelOperation = {
    textTransform: "capitalize" as "capitalize",
    border: "1px solid #CBCDD3",
    borderRadius: "6px",
    color: "#7E8392"
}
const CustomFieldAddOperation = {
    textTransform: "capitalize" as "capitalize",
    background: "#5B73E8",
    border: "1px solid #3654E3",
    borderRadius: "6px",
    marginLeft: "6px",
    marginRight: "6px",
    color: "white"
}
const AddChoice = styled.div`
    display: flex;
    margin: 3% 0%;
    color: #3654E3;
    width:100%;
    cursor: pointer;
`
const CheckBoxField = styled.div`
    display : flex;
    flex-wrap: wrap;
    gap: 4%;
`
const RadioField = styled.div`
    margin: -2% 0% 0% -1%;
    display : inline-flex;
`
const CustomFieldContainer = styled(Grid) <{ visiable: any }>`
// width: 616px
// height: 589px


    padding:11px 39px  11px  39px;
    .leftTitle{
        font-weight:500;
        font-family:poppins;
        font-size:16px;
        margin:7px 0 0 44px
    }
    .rightTitle{
        font-weight:500;
        font-family:poppins;
        font-size:16px;
        line-height:24px;
        margin-top:12px
        
    }
`

export const ChoiceWrapp = styled.div`
& {
    .choice-array > div:first-child {
        display: flex;
        flex-wrap: wrap;
        gap:1rem;   
    }
}
`;

export default CustomField






// < CustomFieldWrapper style = {{ }
//         } >
//             <CustomBack handleBack={() => closeModel(false)} />
//             <CustomFieldContainer style={{ marginTop: 0, }} container rowSpacing={2} columnSpacing={{ xs: 13, sm: 14, md: 15 }} >
//                 <Grid item xs={6} style={{ borderRight: "1px solid lightgray", height: "auto" }} sx={{ border: "1px solid green" }}>
//                     <Typography className='leftTitle'> Select a custom field </Typography>
//                     <Grid container rowSpacing={3} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ margin: "0 0 0 7%" }}>
//                         {selectCustomField.map((res: any) => (
//                             <Grid item xs={6} >
//                                 <Box onClick={() => setCustomField(res)} style={{ border: res.id === customFieldId ? "2px solid " + res.color : "1px solid lightgray", width: "230px" }}>
//                                     <IconButton color="inherit">
//                                         {res.icon}
//                                     </IconButton>
//                                     {res.name}
//                                 </Box>
//                             </Grid>
//                         ))}
//                     </Grid>
//                 </Grid>
//                 <Grid xs={6} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ margin: 0, padding: "18px 32px 0px 32px", width: "928px", height: "-webkit-fill-available", overflow: "auto", border: "3px solid blue" }}>
//                     <Typography className='rightTitle'>  Field properties</Typography>

//                     <Grid container className="rightDiv" rowSpacing={1} columnSpacing={{ xs: 0, sm: 0, md: 0 }} style={{ margin: "2% 0%", justifyContent: "space-between", lineHeight: 2 }}>
//                         {fieldProperties.map((res: any) => (
//                             <>
//                                 {res.type === "input" && res?.display !== "grid" ?
//                                     AppFormInputField(res.name, res.label, res.placeholder, res.disabled, res.require, res.tooltip)
//                                     : null}
//                                 {res.type === "checkbox" ?
//                                     <CheckBoxField>
//                                         {res.options.map((name: any) => (
//                                             <AppFormCheckboxField
//                                                 label={name.label}
//                                                 name={name.value}

//                                             />
//                                         ))}
//                                     </CheckBoxField>
//                                     : null}
//                                 {res.type === 'select' && res?.display !== "grid" ?
//                                     <Grid item spacing={2} xs={12}>
//                                         {AppFormSelectFields(res.name, res.label, "", res?.data, res.require, res.tooltip)}
//                                     </Grid>

//                                     : null}
//                                 {res.type === "input" && res?.display === "grid" ?
//                                     <Grid item spacing={2} xs={5.7} >
//                                         {AppFormInputField(res.name, res.label, res.placeholder, res.disabled, res.require, res.tooltip)}
//                                     </Grid>
//                                     : null}
//                                 {res.type === "select" && res?.display === "grid" ?
//                                     <Grid item spacing={2} xs={6}>
//                                         {AppFormSelectFields(res.name, res.label, "", res?.data, res.require, res.tooltip)}
//                                     </Grid> : null}
//                                 {res.type === 'choies' ?
//                                     <>
//                                         <Grid item spacing={1} xs={12} style={{ color: "rgb(126, 131, 146)", fontSize: "14px", margin: "2% 1%" }}>
//                                             {res.label}
//                                         </Grid>
{/* <FieldArray name="options">
                                                {({ insert, remove, push, form, swap }) => (
                                                    <>


                                                        <ChoiceWrapp>

                                                            <div className="choice-array">

                                                                {values?.options?.map((res: any, index: number) => {
                                                                    return (
                                                                        ChildrenComponent(res, index,swap,remove)
                                                                    )
                                                                })}
  

                                                            </div>

                                                        </ChoiceWrapp>

                                                        <AddChoice onClick={() => push({ id: (values?.options.length + 1), label: "" })}>
                                                            Add Field
                                                        </AddChoice>
                                                    </>

                                                )}

                                            </FieldArray> */}
{/* <OptionMapping name={'options'} onDrop={onDrop}
/> */}
{/* /> */ }
//                                         </>
//                                         : null}
// {
//     res.type === "radio" && res?.display === "grid" ?
//     <Grid item spacing={2} xs={6}>
//         <RadioField>
//             <AppFormRadioField
//                 label={res.label}
//                 name={res.value}
//                 Options={res.options}
//             />
//         </RadioField>
//     </Grid> : null
// }
//                                     <br /><br /><br />
//                                 </>
//                             ))}
//                         </Grid >
//                     </Grid >
//                 </CustomFieldContainer >
//     <Actions handleBack={() => closeModel(false)} type={true} style={{ margin: "10px 14px 14px 0  ", gap: "13px" }} />
//             </CustomFieldWrapper >