// vendors
import { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// builder
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";
import { getActionConfig } from "./../../flow-builder-v2/utils";

// hooks
import { useAction } from "./../../flow-builder-v2/hooks";

// components
import { Icon, Input, UIButton } from "./../../ui-components";
import Card from "./../card";

const Function = (props: any) => {
  const nodeCntx = useContext(NodeContext);
  const [showPreview, setPreview] = useState<boolean>(true);
  const [text, updateText] = useState<string>(props.text || "");
  const { deleteAction, updateAction } = useAction();
  const action = getActionConfig(props.type);

  const togglePreview = () => {
    setPreview(!showPreview);
  };

  const handleCancelEditing = () => {
    if(!text) {
      deleteAction(nodeCntx, props.actionId);
    }
    togglePreview();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateText(event.target.value);
  };

  const handleSave = () => {
    updateAction(props, nodeCntx, {text});
  };

  const handleCancel = () => {
    if(!text) {
      deleteAction(nodeCntx, props.actionId);
    }
    togglePreview();
  };

  useEffect(() => {
    if(props.text) {
      setPreview(true);
      return;
    }
    setPreview(false);
  }, []);

  return (
    <Box id={props.id}>
      <Card
        id={props.actionId}
        isAction
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
      >
        {showPreview ? <Box
            sx={{ p: "12px 16px" }}
            onClick={(e) => {
              e.stopPropagation();
              togglePreview();
            }}
          >
             <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Icon icon={action?.icon} size={16} color={"#5B73E8"} />
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "21px" }}
                color="#5B73E8"
              >
                {action?.name} {props.stage && props.stage}
              </Typography>
            </Box>
          </Box> : 
        <Box sx={{ p: "12px 16px" }}>
          <Input
            placeholder="Type here"
            value={text}
            onChange={handleChange}
            multiline
            rows={10}
            maxRows={10}
          />
           <Box
              sx={{
                mt: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UIButton
                color="error"
                variant="contained"
                onClick={handleCancel}
              >
                Cancel
              </UIButton>
              <UIButton variant="contained" onClick={handleSave} disabled={!text}>
                Save
              </UIButton>
            </Box>
        </Box>}
      </Card>
    </Box>
  );
};

export default Function;
