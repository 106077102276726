import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton
} from "@mui/material";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { theme } from "../../../Customization/Theme";
import { useAppDispatch } from "../../../Store";
import {
  setContactPropertices,
  setDeleteModel,
  setLevel,
  setSelectedFieldData,
  setSelectedSectionData,
} from "../../../Store/Slices/Settings/ContactProperties/contactProperties.slice";
import { ReactComponent as HideField } from "../../../assets/images/icons/hide.svg";
import { ReactComponent as ShowField } from "../../../assets/images/icons/show.svg";
// import { TreeItem, TreeView } from '@mui/lab';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { useNotification } from "../../../Hooks/useNotification/useNotification";

const ContactPropertiesList = (props: any) => {
  const [id, setId] = useState("");
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<any>([]);
  const { showAlert } = useNotification();
  const [list, setList] = useState<any[]>([])
  useEffect(() => {
    if (props?.list) {
      setList(props?.list)
    }
  }, [props?.list])
  const handleToggle = (data?: any) => {
    let listData;

    if (!expanded?.includes(data?.id)) {
      listData = expanded ? [...expanded, data?.id] : [data?.id];
    } else {
      listData = expanded?.filter((res: any) => res !== data?.id);
    }

    setExpanded(listData);
  };
  const onSectionDelete = (data: any, event: any) => {
    if (data.fields?.length > 0) {
      showAlert(
        "To delete the section, move or delete the fields from this section.",
        "error"
      );
    } else {
      dispatch(setDeleteModel(true));
      dispatch(setLevel("Section"));
      dispatch(setSelectedSectionData(data));
    }
    event.stopPropagation();
  };
  const contactAction = (data: any) => {
    return (
      <div style={{ marginLeft: "5px" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            xs={6}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <p>{data.label}</p>
            <HideAction>
              {/* <IconButton
                onClick={(event: any) => {
                  props.reorderFields(data.id, "move_up");
                  event.stopPropagation();
                }}
              >
                <AppMaterialIcons iconName="ArrowUpwardOutlined" />
              </IconButton>
              <IconButton
                onClick={(event: any) => {
                  props.reorderFields(data.id, "move_down");
                  event.stopPropagation();
                }}
              >
                <AppMaterialIcons iconName="ArrowDownwardOutlined" />
              </IconButton> */}
              <IconButton
                color="inherit"
                onClick={(event: any) => {
                  dispatch(setSelectedSectionData(data));
                  props?.setAction("Add_Section");
                  event.stopPropagation();
                }}
              >
                {/* <EditIcon /> */}
                <AppMaterialIcons iconName="EditOutlined" />
              </IconButton>
              <IconButton
                onClick={(event: any) => {
                  onSectionDelete(data, event);
                }}
              >
                {/* <DeleteIcon /> */}
                <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              </IconButton>
            </HideAction>
          </Grid>
          <Grid item xs={6}>
            <ButtonControl style={{ cursor: "pointer" }}>
              <AppButton
                variant="outline"
                startIcon={"ri-add-circle-line"}
                iconSize={"150%"}
                onClick={(event: any) => {
                  props?.setAction("Add_Custom_Field");
                  dispatch(setSelectedSectionData(data));
                  event.stopPropagation();
                }}
              >
                {" "}
                Custom Field{" "}
              </AppButton>
              <AppButton
                variant="outline"
                startIcon={"ri-add-circle-line"}
                iconSize={"150%"}
                onClick={(event: any) => {
                  props?.setAction("Default_Field");
                  dispatch(setSelectedSectionData(data));
                  event.stopPropagation();
                }}
              >
                {" "}
                Hidden Field{" "}
              </AppButton>
            </ButtonControl>
          </Grid>
        </Grid>
      </div>
    );
  };
  const getFieldIcon = (type: any) => {
    // if(type === "string"){
    //     return <TextField style={{ margin: "6px 7px 1px 0px" }} />
    // } else if(type === "number"){
    //     return <NumberField style={{ margin: "6px 7px 1px 0px" }}    />
    // }
  };
  const contactFieldAction = (data: any) => {
    return (
      <Box>
        <RowWrapper>
          <DragIndicatorIcon style={{ cursor: "pointer" }} />

          <Box className="title">
            <p
              title={data.label}
              style={{
                textDecoration: "none",
                maxWidth: "190px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginLeft:
                  data?.is_dependant ||
                    data?.name === "lost_reason" ||
                    data?.name === "other_lost_reason"
                    ? "17px"
                    : "15px",
              }}
            >
              {data.label}
            </p>
            {data.id === id ? (
              <HideAction>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    props?.setAction("Edit_Field");
                    dispatch(setSelectedFieldData(data));
                  }}
                >
                  <AppMaterialIcons iconName="EditOutlined" />
                </IconButton>
                {data.custom && !data?.is_dependant ? (
                  <IconButton
                    onClick={() => {
                      dispatch(setDeleteModel(true));
                      dispatch(setLevel("Field"));
                      dispatch(setSelectedFieldData(data));
                    }}
                  >
                    <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                  </IconButton>
                ) : null}
              </HideAction>
            ) : null}
          </Box>
          <Box className="showinform">
            <FormControlLabel
              label="Show In Contact Form"
              disabled={
                data?.is_dependant
                  ? data?.is_dependant
                  : data?.system_default
                    ? data?.system_default
                    : data?.name === "lost_reason"
                      ? true
                      : data?.name === "other_lost_reason"
                        ? true
                        : false
              }
              checked={data?.visible_in_form}
              onClick={() => props.updateField(data, "visible_in_form")}
              sx={{
                fontSize: "14px",
              }}
              control={<Checkbox size="small" />}
            />
          </Box>
          <Box className="options">
            <FormControlLabel
              label="Required"
              checked={data?.required}
              disabled={
                data?.read_only
                  ? data?.read_only
                  : data?.is_dependant
                    ? data?.is_dependant
                    : data?.system_default
                      ? data?.system_default
                      : data?.name === "lost_reason"
                        ? true
                        : data?.name === "other_lost_reason"
                          ? true
                          : false
              }
              control={<Checkbox checked={data?.required} size="small" onClick={() => props.updateField(data, "required")} />}
            />
            <FormControlLabel
              label="Read-Only"
              checked={data?.read_only}
              disabled={
                data?.required
                  ? data?.required
                  : data?.is_dependant
                    ? data?.is_dependant
                    : data?.system_default
                      ? data?.system_default
                      : data?.name === "lost_reason"
                        ? true
                        : data?.name === "other_lost_reason"
                          ? true
                          : false
              }
              control={<Checkbox size="small" onClick={() => props.updateField(data, "read_only")} />}
            />
          </Box>
          <Box className="view">
            <IconButton
              sx={{ padding: "4px" }}
              disabled={
                data?.is_dependant
                  ? data?.is_dependant
                  : data?.system_default
                    ? data?.system_default
                    : data?.name === "lost_reason"
                      ? true
                      : data?.name === "other_lost_reason"
                        ? true
                        : false
              }
              onClick={() => props?.hideUnhideField(data, data?.visible ? "hide" : "unhide")}
            >
              {data?.visible ? (
                <ShowField
                // onClick={() => props?.hideUnhideField(data, "hide")}
                />
              ) : (
                <HideField
                // onClick={() => props?.hideUnhideField(data, "unhide")}
                />
              )}
            </IconButton>
          </Box>
        </RowWrapper>
      </Box>
    );
  };
  const contactChildFieldAction = (data: any) => {
    return (
      <Box>
        <RowWrapper>
          <Box className="title">
            <p
              title={data.label}
              style={{
                textDecoration: "none",
                maxWidth: "190px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginLeft:
                  data?.is_dependant ||
                    data?.name === "lost_reason" ||
                    data?.name === "other_lost_reason"
                    ? "65px"
                    : "",
              }}
            >
              {data.label}
            </p>
            {data.id === id ? (
              <HideAction>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    props?.setAction("Edit_Field");
                    dispatch(setSelectedFieldData(data));
                  }}
                >
                  <AppMaterialIcons iconName="EditOutlined" />
                </IconButton>
              </HideAction>
            ) : null}
          </Box>
        </RowWrapper>
      </Box>
    );
  };
  const handleDragEnd = (e: any) => {
    if (!e.destination) return;
    let data1: any = list.find((res: any) => res?.fields?.find((res1: any) => res1.name === e.draggableId))
    let tempData: any = [...data1?.fields];
    let [sourceData] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, sourceData);
    let data = tempData.map((item: any, index: any) => {
      return {
        ...item,
        position: index + 1
      };
    });
    let sectionFieldData = list.map((res: any, index: any) => {
      if (res.id === data1.id) {
        // Create a new object with the updated 'fields' property
        return {
          ...res,
          fields: data
        };
      }
      return res;
    });
    setList(sectionFieldData)
    dispatch(setContactPropertices(list))
    props?.recoredField(data1?.id, data)
  }

  const printChildLabels = (data: any, provider: any) => {
    // if (currentChildIndex >= childData?.length) {
    //   return null; // All children processed
    // }
    let childData = data
    if (!childData[0]?.children && !childData[0]?.is_dependant) {
      return null
    }
    const currentChild = childData[0];

    // Increment the index for the next render
    // setCurrentChildIndex(currentChildIndex + 1);

    return (
      <React.Fragment>
        <TreeItemDivInner
          id={currentChild?.id}
          {...provider.dragHandleProps}
          style={{
            padding: "4px 0px",
            borderBottom: `1px solid ${theme.palette.default.RightBorderDivder}`,
          }}
          onMouseEnter={() => setId(currentChild?.id)}
          key={currentChild?.label}
          nodeId={currentChild?.id}
          label={contactChildFieldAction(currentChild)}
        />
        {childData[0].children && childData[0].children.length > 0 && (
          <div >
            {printChildLabels(childData[0].children, provider)}
          </div>
        )}
      </React.Fragment>

    );
  };
  return (
    <div>
      {list?.map((data: any, index: number) => {
        return (
          <TreeView
            aria-label="file system navigator"
            key={index}
            defaultCollapseIcon={
              <ExpandMoreIcon
                style={{
                  cursor: "pointer",
                  height: "15px",
                  width: "15px",
                  border: `1px solid ${theme.palette.default.border}`,
                  marginLeft: "13px",
                  backgroundColor: "white",
                }}
                onClick={() => handleToggle(data)}
              />
            }
            defaultExpandIcon={
              <ChevronRightIcon
                style={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                  marginLeft: "13px",
                  backgroundColor: "white",
                }}
                onClick={() => handleToggle(data)}
              />
            }
            defaultExpanded={expanded}
            style={{
              marginBottom: "10px",
              border: `1px solid ${theme.palette.default.border}`,
            }}
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable-1" type="row">
                {(provider) => (
                  <TreeItemDiv ref={provider.innerRef} {...provider.droppableProps} nodeId={data?.id} label={contactAction(data)} key={data?.id}>
                    <Box key={data?.id}>
                      {data?.fields?.map((field: any, index: number) => {
                        return field.type === "section" ? (
                          <TreeItemDiv
                            onClick={() => handleToggle(field)}
                            nodeId={field?.id}
                            label={contactAction(field)}
                            key={index}
                          >
                            {field.fields?.map((field: any, index: number) => {
                              return (
                                <TreeItemDivNested
                                  style={{
                                    borderBottom: `1px solid ${theme.palette.default.RightBorderDivder}`,
                                  }}
                                  id={field?.id}
                                  onMouseEnter={() => setId(field?.id)}
                                  key={field?.label + " " + index}
                                  nodeId={index}
                                  label={contactFieldAction(field)}
                                />
                              );
                            })}
                          </TreeItemDiv>
                        ) : (
                          <Box>
                            <Draggable
                              key={field?.name}
                              draggableId={field?.name}
                              index={field?.position - 1}
                            >
                              {(provider: any) => (
                                <>
                                  <div className={field?.id} id={field?.id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                    {...provider.draggableProps}
                                    ref={provider.innerRef}
                                  >
                                    {" "}
                                    {!field.is_dependant ? <TreeItemDivInner
                                      id={field?.id}
                                      {...provider.dragHandleProps}
                                      style={{
                                        padding: "4px 0px",
                                        borderBottom: `1px solid ${theme.palette.default.RightBorderDivder}`,
                                      }}
                                      onMouseEnter={() => setId(field?.id)}
                                      key={field?.label + " " + index}
                                      nodeId={field?.id}
                                      label={contactFieldAction(field)}
                                    /> : null}
                                    {field.children && field.children.length > 0 && (
                                      <>{printChildLabels(field?.children, provider)}</>
                                    )}
                                  </div>
                                </>
                              )}
                            </Draggable>
                          </Box>
                        );
                      })}
                    </Box>
                  </TreeItemDiv>
                )}
              </Droppable>
            </DragDropContext>
          </TreeView>
        );
      })}
    </div>
  );
};
const TreeItemDiv = styled(TreeItem)`
  && {
    .MuiCollapse-wrapperInner {
      z-index: 1;
    }
    .MuiTreeItem-content {
      height: 55px;
      position: sticky;
      top: -13px;
      background-color: #eff2fd;
      z-index: 99;
      .MuiTreeItem-label {
        padding-left: 11px;
      }
    }

    .MuiTreeItem-content.Mui-selected {
      background-color: #eff2fd;
    }

    .MuiTreeItem-group {
      margin-right: 17px;
      // margin: 0px;
    }
    .MuiCollapse-wrapperInner {
      // z-index: 1;
    }

    // border-radius: 4px;
    // // background: rgba(91, 115, 232, 0.10);
    // display: flex;
    // padding: 19px;
    // flex-direction: column;
    // gap: 10px;
    // .MuiTreeItem-content{
    //     background-color: transparent;
    // };
    // .MuiTreeItem-content:focus{
    //     background-color: transparent !important;
    // };
    // .Mui-focused:focus{
    //     background-color: transparent !important;

    // };
    // .MuiCollapse-root{
    //     background-color: white;
    //     width: 95rem;
    //     margin-left: -17px;
    // }
  }
`;
const TreeItemDivInner = styled(TreeItem)`
  && {
    .MuiTreeItem-content {
      height: 50px;
      background: #fff;
    }
    .MuiTreeItem-content.Mui-selected {
      background-color: #fff;
    }
    .MuiTreeItem-iconContainer {
      display: none;
    }
  }
`;

const TreeItemDivNested = styled(TreeItem)`
  && {
    .MuiTreeItem-content {
      background-color: #fff;
    }
    .MuiTreeItem-content.Mui-selected {
      background-color: #fff;
    }
  }
`;
const HideAction = styled.text<{ visiable: any }>`
  // display : ${(props: any) => (props.visiable ? "inline" : "none")};
  display: flex;
  // gap: 4px;
  margin-left: 8px;
  .MuiSvgIcon-root {
    font-size: 18px;
    color: ${theme.palette.default.darkGrey};
  }
`;
const ButtonControl = styled.div<{}>`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  height: 100%;
`;
export default ContactPropertiesList;

const RowWrapper = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      min-width: 190px;
      display: flex;
      min-height: 40px;
      align-items: center;
      margin-right: auto;
      p {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .showinform {
      .MuiTypography-root {
        font-size: 14px;
      }
    }
    .options {
      .MuiTypography-root {
        font-size: 14px;
      }
    }
    .view {
      svg {
        width: 20px;
      }
    }

    @media (max-width: 1499px) {
      .title {
        p {
          font-size: 13px;
        }
      }
      .showinform {
        .MuiTypography-root {
          font-size: 13px;
        }
      }
      .options {
        .MuiTypography-root {
          font-size: 13px;
        }
      }
    }
  }
`;
