// vendors
import { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";

// slice
import { getBotId } from "../Slices/main.slice";

// services
import { FlowBuilderService } from "../../../Services/Apis/FlowBuilder";

const useFetchFlowById = (id: string) => {
  const botId = useSelector(getBotId);
  const [data, setdata] = useState<any>(null);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    getNodes(id).then((response: any) => {
      setloading(false);
      setdata(response);
    });
  }, [id]);

  const getNodes = async (id: string) => {
    const response = await FlowBuilderService.getFlowById(id, {
      appid: JSON.stringify(botId),
    });
    if (response.data.status === 200) {
      return response.data.data;
    }
    if (response.data.status === 500) {
      return null;
    }
  };
  return { data, loading, getNodes };
};
export default useFetchFlowById;
