import {  Box, IconButton, Typography } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";


type Props = {
    title?: string;
    date?: string;
    type?:string
};

const BroadcastTopInfoBar: React.FC<Props> = ({
    title,
    date,
    type
}) => {

    const navigate = useNavigate();

    return (
        <BroadcastTopInfoBarBox>
            <Box>
                <IconButton
                    onClick={() =>
                        navigate("/broadcast/"+type+"/history")
                    }
                    >
                    <AppMaterialIcons iconName="ChevronLeftOutlined" />
                </IconButton>
            </Box>
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} gap={"16px"}>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Typography fontSize="17px" fontWeight="500" component="h6">
                        {title}
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Typography fontSize={"12px"} variant="body2">{date}</Typography>
                </Box>
            </Box>
        </BroadcastTopInfoBarBox>
    );
}


export default BroadcastTopInfoBar;

const BroadcastTopInfoBarBox = styled.div`
&& {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 8px;
    background-color: ${theme.palette.default.white};
    padding: 10px 16px;

    h6 {
        color: ${theme.palette.default.black};
    }

    p {
        color: ${theme.palette.default.darkGrey};
    }

}
`;