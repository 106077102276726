import React, { useState, useEffect, useRef } from 'react';
import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import 'keen-slider/keen-slider.min.css';

const AppDatePicker = (props:any) => {
  const [selectedDate, setSelectedDate] = useState<number>(new Date().getDate());
  const [selectedMonth, setSelectedMonth] = useState<number>(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());

  const dateSliderRef = useRef<HTMLDivElement | null>(null);
  const monthSliderRef = useRef<HTMLDivElement | null>(null);
  const yearSliderRef = useRef<HTMLDivElement | null>(null);

  const dates = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i);
  const years = Array.from({ length: 21 }, (_, i) => new Date().getFullYear() - 10 + i);

  useEffect(() => {
    if (dateSliderRef.current) {
      const instance = new KeenSlider(dateSliderRef.current, {
        vertical: true,
        slides: { perView: 3, spacing: 10 },
        loop: false,
        mode: 'snap',
        // centered: true,
        slideChanged(s) {          
          setSelectedDate(dates[s.track.details.rel+1]);
        },
      });
      return () => instance.destroy();
    }
  }, [dateSliderRef]);

  useEffect(() => {
    if (monthSliderRef.current) {
      const instance = new KeenSlider(monthSliderRef.current, {
        vertical: true,
        slides: { perView: 3, spacing: 10 },
        loop: false,
        mode: 'snap',
        // centered: true,
        slideChanged(s) {
          setSelectedMonth(months[s.track.details.rel+1]);
        },
      });
      return () => instance.destroy();
    }
  }, [monthSliderRef]);

  useEffect(() => {
    if (yearSliderRef.current) {
      const instance = new KeenSlider(yearSliderRef.current, {
        vertical: true,
        slides: { perView: 3, spacing: 10 },
        loop: false,
        mode: 'snap',
        // centered: true,
        slideChanged(s) {
          setSelectedYear(years[s.track.details.rel+1]);
        },
      });
      return () => instance.destroy();
    }
  }, [yearSliderRef]);

  return (
    <div className="date-picker">
      <div className="slider-wrapper">
        <div className="slider-container" ref={dateSliderRef}>
          {dates.map((date) => (
            <div key={date} className="keen-slider__slide">
              {date}
            </div>
          ))}
        </div>
        <div className="slider-container" ref={monthSliderRef}>
          {months.map((month) => (
            <div key={month} className="keen-slider__slide">
              {new Date(0, month).toLocaleString('default', { month: 'short' })}
            </div>
          ))}
        </div>
        <div className="slider-container" ref={yearSliderRef}>
          {years.map((year) => (
            <div key={year} className="keen-slider__slide">
              {year}
            </div>
          ))}
        </div>
      </div>
      <div className="selected-date">
        <h3>
          Selected Date: {`${selectedDate} ${new Date(0, selectedMonth).toLocaleString('default', { month: 'short' })} ${selectedYear}`}
        </h3>
      </div>
      <div className="actions">
        <button onClick={(e:any) => props?.handleClose(e,"")} className="cancel-button">CANCEL</button>
        <button onClick={(e:any) => props?.handleClose(e,`${selectedDate} ${new Date(0, selectedMonth).toLocaleString('default', { month: 'short' })} ${selectedYear}`)} className="ok-button">OK</button>
      </div>
    </div>
  );
};
export default AppDatePicker;
