// vendors
import { useEffect, useState, Fragment } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";

// components
import UIButton from "../../Components/Button";
import SearchInput from "../../Components/SearchInput";
import Icon from "../../Components/Icon";
import DisplayNodes from "../../Nodes/Buttons/actions/components/display-nodes";

// slice
import {
  useFlows,
  getBotId,
  getLanguage,
  setActiveAction,
} from "../../Slices/main.slice";
import { createFlow } from "../../Slices/main.service";
import { useAppDispatch } from "../../../../Store";

// utils
import { getActionConfig } from "../../Utils";

const NodeToNode = (props: any) => {
  const { showPreview, onSave, onCancel } = props;
  const action = getActionConfig(props.type);
  const updatedFlows = useSelector(useFlows);
  const botId = useSelector(getBotId);
  const language = useSelector(getLanguage);
  const [hierarchyLevel, setHierarchyLevel] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState(updatedFlows || []);
  const [flowId, setflowId] = useState<string | null>(props.qid || null);
  const [flowOrder, setFlowOrder] = useState(0);
  const [displayNodes, setShowNodes] = useState<string>("");
  const [selectedFlow, setFlow] = useState<{
    qid: string;
    stage: string | null;
    nodeId: string | null;
  } | null>(
    { qid: props.qid, stage: props.stage, nodeId: props.nodeId } || null
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      updatedFlows &&
      updatedFlows?.length > 0 &&
      Array.isArray(updatedFlows)
    ) {
      const results: any = updatedFlows?.filter((flow: any) =>
        flow?.name?.toLowerCase()?.includes(searchTerm.toLowerCase())
      );
      setSearchResults(results);
    }
  }, [searchTerm, updatedFlows]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleNewFlowCreate = () => {
    dispatch(createFlow({ botId }));
  };

  const handleClick = (id: string, order: number) => {
    setHierarchyLevel(2);
    setFlowOrder(order);
    setflowId(id);
    setShowNodes((prevState: string) => {
      if (prevState !== id) {
        return id;
      } else {
        return prevState;
      }
    });
  };

  const handleBackClick = () => {
    setHierarchyLevel(1);
  };

  const handleNodeSelect = (qId: string, nodeId: string | null, order: number | null) => {
    setFlow({
      qid: qId,
      stage: `${flowOrder}.${order}`,
      nodeId: nodeId,
    });
  };

  const handleSaveClick = () => {
    const myPromise = () => Promise.resolve(
      dispatch(setActiveAction({ ...props, ...selectedFlow }))
    ); /// creating promise 
    myPromise().then(()=>{
      const activeAction = (({ onSave, onCancel, showPreview, ...o }) => o)({ ...props, ...selectedFlow })
      onSave && onSave(activeAction);
    });
  };

  const handleCancelClick = () => {
    if(!props.nodeId || !props.qid) {
      onCancel && onCancel(true);
      return;
    };
    onCancel && onCancel(false);
  };

  return (
    <>
      {showPreview ? (
        <Box sx={{ p: "12px 16px" }}>
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Icon icon={action?.icon} size={16} color={"primary.light"} />
            <Typography
              variant="body1"
              sx={{ fontWeight: 500}}
              color="primary.light"
            >
              {action?.name} {props.stage && props.stage}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          {displayNodes && hierarchyLevel == 2 ? (
            <>
              <Box
                sx={{
                  py: "4px",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #F5F6F8;",
                  borderTop: "1px solid #F5F6F8;",
                }}
              >
                <IconButton
                  sx={{
                    ml: "5px",
                    mr: "-10px",
                  }}
                  onClick={handleBackClick}
                >
                  <Icon icon="chevron-left" color="" size={12} />
                </IconButton>
                <Typography
                  variant="body1"
                  color="default.darkGrey"
                  sx={{ ml: "20px" }}
                >
                  Node List
                </Typography>
              </Box>
              <DisplayNodes
                activeFlowId={displayNodes}
                flowOrder={flowOrder}
                onNodeSelect={handleNodeSelect}
                selectedNode={selectedFlow?.nodeId !== null ? selectedFlow?.nodeId : 'null'}
                connectedFlow={selectedFlow}
              />
            </>
          ) : (
            <Box sx={{ p: "16px" }}>
              <SearchInput
                placeholder="Search"
                size="small"
                onChange={handleSearchInputChange}
              />
              <UIButton
                startIcon={<Icon icon="add" size={10} disableFill />}
                fullWidth
                variant="outlined"
                sx={{ mt: "16px" }}
                onClick={handleNewFlowCreate}
                disabled={language !== "english"}
              >
                New Flow
              </UIButton>
              <Box
                sx={{
                  mt: "16px",
                  maxHeight: "200px",
                  minHeight: "200px",
                  overflowY: "scroll",
                }}
              >
                {searchResults.length > 0 ? (
                  <>
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      component="nav"
                    >
                      {searchResults.map((flow: any, index: number) => {
                        const openChild = false;

                        // activeButtonData.value?.qid === flow?.qid;
                        return (
                          <Fragment key={`flow-${index + 1}`}>
                            <ListItem
                              disablePadding
                              sx={{
                                "&:hover": {
                                  backgroundColor:
                                    language !== "english"
                                      ? openChild
                                        ? "#F5F6F8"
                                        : "transparent"
                                      : "#F5F6F8",
                                },
                                border: "1px solid #F5F6F8",
                              }}
                            >
                              <ListItemButton
                                sx={{
                                  "&:hover": { backgroundColor: "transparent" },
                                }}
                                onClick={() =>
                                  handleClick(flow.qid, flow.displayOrder)
                                }
                                disabled={language !== "english"}
                              >
                                <Typography
                                  variant="body1"
                                  color="default.darkGrey"
                                >{`${index + 1} ${flow?.name}`}</Typography>
                                {flowId === flow.qid && (
                                  <Box sx={{ marginLeft: "auto" }}>
                                    <Icon
                                      icon="done"
                                      size={14}
                                      color="default.success"
                                    />
                                  </Box>
                                )}
                              </ListItemButton>
                            </ListItem>
                          </Fragment>
                        );
                      })}
                    </List>
                  </>
                ) : (
                  <Typography
                    fontWeight={400}
                    fontSize={12}
                    lineHeight="16px"
                    color="#7E8392"
                  >
                    No Flows Available
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <UIButton variant="outlined" onClick={handleCancelClick}>
                Cancel
              </UIButton>
              <UIButton
                color="primary"
                variant="contained"
                startIcon={<Icon icon="save" color="#fff" size={13} />}
                disabled={
                  language !== "english" ||
                  !selectedFlow?.qid ||
                  !selectedFlow?.nodeId
                }
                onClick={handleSaveClick}
              >
                Save
              </UIButton>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default NodeToNode;
