import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  applicationsListData,
  automationBuilderPayload,
  automationBuilderState,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import { marketingListActions } from "../../../Store/Slices/MarketingList/marketingList.actions";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { AppliactionSubmittedConfigSchema } from "../validationSchema";
import { withLoader } from "../../../AppComponents/Loader";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";

const AppliactionSubmitted = ({ setLoading }: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formRef: any = useRef();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const applicationsLists: any = useAppSelector(applicationsListData);
  const [initialValues, setInitialValues]: any = useState({
    application_list_id: "",
  });
  const [formikProps, setFormikProps] = useState<any>(null);
  const [listOptions, setListOptions] = useState<any>(applicationsLists || []);
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.application_list_id &&
      listOptions?.length > 0
    ) {
      //function to filter object based on Value
      setInitialValues({
        application_list_id: {
          id: listOptions?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.application_list_id?.id,
          )?.id,
          name: listOptions?.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.application_list_id,
          )?.form_name,
        },
      });
    }
  }, [automationPayload, listOptions]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(AutomationBuilderActions.getApplicationsList({}))
      .then(() => {
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error",
        );
      });
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("application_list_id", "", {
      shouldDirty: true,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const handleSubmit = (values: any, submitProps: any) => {
    let payload: any = {
      nodeObject: {
        application_list_id: values?.application_list_id.id,
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: values?.application_list_id.form_name,
    };
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  useEffect(() => {
    setListOptions(applicationsLists);
  }, [applicationsLists]);
  return (
    <ModelFormWrapper isDataAvaliable={listOptions?.length}>
      <Typography variant="h6">Applications List</Typography>
      {listOptions?.length ? (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={AppliactionSubmittedConfigSchema}
          innerRef={formRef}
        >
          {listOptions?.length > 0 && (
            <AppReactSelectField
              name={"application_list_id"}
              options={listOptions}
              label="Applications List"
              displayKey="form_name"
              valueKey="id"
              placeholder="Select a List"
              isSearchable={true}
              isClearable={true}
              hideSelectedOptions={true}
              isRequired={true}
              divStyle={{ background: "none", padding: "0" }}
            />
          )}
          <AppFormObserver setFormikProps={setFormikProps} />
          <ModelFooter>
            <AppButton onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton
              title={"Save Config"}
              disabled={!formRef?.current?.isValid}
            />
          </ModelFooter>
        </AppForm>
      ) : (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      )}
    </ModelFormWrapper>
  );
};

export default withLoader(AppliactionSubmitted);

const ModelFormWrapper = styled.div`
  width: ${(props: any) => (props.isDataAvaliable ? "400px" : "500px")};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container {
    text-align: center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
