import { Popover } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const EmailListingTable = styled.div`
  & {
    margin: 0 1rem;
    height: 100%;
    border: 1px solid ${theme.palette.default.border};

    .table-body {
      overflow: auto;
      height: calc(100vh - 334px);
    }

    .table-header {
      display: flex;
      border-bottom: 1px solid ${theme.palette.default.grey};
      position: sticky;
      top: 0;
      z-index: 9;
      background: ${theme.palette.default.white};
    }
    .table-data_row {
      display: flex;
      border-bottom: 1px solid rgba(203, 205, 211, 0.5);
    }

    .table-header > div:first-child {
      max-width: 60px;
      min-width: 60px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .table-header > div:nth-child(2) {
      max-width: 520px;
      min-width: 520px;
    }

    .table-data_row > div:first-child {
      max-width: 60px;
      min-width: 60px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .table-data_row > div:nth-child(2) {
      max-width: 520px;
      min-width: 520px;
    }

    .table-data_row > div:nth-child(4) {
      min-width: calc(100% - 950px);
    }

    .actions {
      display: flex;
      align-items: center;
      min-width: 50px;
      justify-content: center;
      & .MuiSvgIcon-root {
        border-radius: 50%;
        color: ${theme.palette.default.darkGrey};
        font-size: 1.2rem;
      }
    }

    .table-col {
      padding: 1rem;
      min-width: 140px;
      width: 100%;
      display: flex;
      align-items: center;
      h4 {
        font-weight: 500;
        color: ${theme.palette.default.black};
        font-size: 14px;
      }
      p {
        color: ${theme.palette.default.darkGrey};
        font-size: 0.938rem;
      }
      .verification-status__success {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        p {
          color: ${theme.palette.default.success};
          font-weight: 500;
          font-size: 0.938rem;
        }
        & .MuiSvgIcon-root {
          border: 1px solid ${theme.palette.default.success};
          border-radius: 50%;
          color: ${theme.palette.default.success};
          font-size: 0.975rem;
        }
      }

      .verification-status__failed {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        p {
          color: ${theme.palette.default.error};
          font-weight: 500;
          font-size: 0.938rem;
        }
        & .MuiSvgIcon-root {
          border: 1px solid ${theme.palette.default.error};
          border-radius: 50%;
          color: ${theme.palette.default.error};
          font-size: 0.975rem;
        }
      }
    }
  }
`;

export const TemplateDetailsBox = styled.div`
  & {
    display: felx;
    gap: 0.75rem;
    .deatils {
      .header {
        margin-bottom: 0.5rem;
        h4 {
          font-size: 14px;
        }
        p {
          color: ${theme.palette.default.black};
          font-weight: 500;
          font-size: 0.875rem;
        }
      }
      .description {
        p {
          font-size: 0.75rem;
        }
      }
    }
    img {
      min-width: 111px;
      max-width: 111px;
      min-height: 130px;
      max-height: 130px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 10px 2px;
    }
  }
`;

export const EmailTemplateListActions = styled.div`
  & {
    p {
      font-size: 1rem;
      color: ${theme.palette.default.darkGrey};
    }
    .MuiSvgIcon-root {
      font-size: 1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

export const ActionPopover = styled(Popover)`
  & {
    width: 100%;
    .MuiList-root {
    }
    .MuiPaper-root {
      box-shadow: rgba(0, 0, 0, 0.025) 0px 0px 8px 0px !important;
      border: 1px solid ${theme.palette.default.grey};
    }
  }
`;

export const EmailListingWrpper = styled.div`
  & {
    height: 100%;
    form {
      height: 100%;
    }
  }
`;
export const EmailHtmlPreview = styled.div`
  & {
    height: 100vh;
    .header {
      display: flex;
      flex-direction: column;
    }
    .header-top {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
  }
`;
