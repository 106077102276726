import React from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { getIn, useFormikContext } from "formik";

interface Props{
  name:string;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppFormError:React.FC<Props>=({ name, ...otherProps })=>{
  const {
    errors,
  } = useFormikContext<any>();

  if(!getIn(errors,name) || typeof getIn(errors,name) !== "string" ){
    return null;
  }

  return <StyledText {...otherProps}>{getIn(errors,name)}</StyledText>
 
}


export default AppFormError;

const StyledText = styled.p`
  color: #F34E4E;
  font-size: 13px;
  font-family: ${theme.typography.fontFamily};
`;
