export const contactsViewsShareWithOptions = [
    { value: 'me', label: 'Just Me' },
    { value: 'everyone', label: 'Everyone' },
    { value: 'selected_users', label: 'Selected users' },
];
  
export const contactsViewsAccessOptions = [
    { value: 'can_view', label: 'can view' },
    { value: 'can_edit', label: 'can view and edit' },
];
  