import styled from 'styled-components';
import { theme } from '../../Customization/Theme';
// Css main Layout
export const UploadMarketingWrapper = styled.div`
& {

    .pop-up__header {
        padding: 2rem 2rem 0rem 2rem;
        h5 {
            color: ${theme.palette.default.black};
            font-size: 1.125rem;
            font-weight: 500;
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        padding-left: 1rem;
    }
    .error-message{
        margin-top: 2rem;
        border: 1px solid ${theme.palette.default.red};
        background-color: ${theme.palette.default.errorLighter};
        padding: 1rem;
        border-radius: 10px;
        svg {
            max-width: 15px;
            vertical-align: text-bottom;
        }
        p{
            color: ${theme.palette.default.red} !important;
            margin-bottom: 0.5rem;
        }
        .p-metadata{
            margin-bottom: 0.3rem;
        }
        li{
            color: ${theme.palette.default.red} !important;
            width:8rem;
            margin-bottom: 0.3rem;
            margin-right: 1rem;
            font-size: 14px;
        }
    }
        
        .form-heading {
            display: flex;
            justify-content: space-between;
            height: 56px;
            align-items: center;
            padding: 0rem 1rem 0rem 1rem;
            position: sticky;
            background-color: #fff;
            top: 0;
            z-index: 9;
            .back-btn__text {
                display: flex;
                gap: 0.7rem;
                align-items: center;
                color: ${theme.palette.default.text};
                font-weight: 700;
            }       
            h6 {
                color: ${theme.palette.default.text}
            }
            svg {
                color: ${theme.palette.default.darkGrey};
            }
            
        }

        .form-first__area {
            padding: 2rem 1rem 0rem 1rem;
        }

        .file-upload__area {
            padding: 2rem;
            .file-upload__container {
                border: 1px dashed ${theme.palette.default.darkGrey};
                border-radius: 4px;
                display: flex;
                flex-direction: column; 
                justify-content: center; 
                align-items: center; 
                gap: 0.6rem;
                padding-top: 24px; 
                padding-bottom: 24px;

                .upload {
                   display: flex;
                   flex-direction: column;
                   justify-content: center;
                   align-items: center;
                   label {
                    width: 100%;
                   }
                    p {
                        text-transform: capitalize;
                        color: ${theme.palette.default.error};
                    }
                }
                
                .file-info {
                    text-align: center;
                    h6 {
                        font-size: 0.75rem;
                        color: ${theme.palette.default.black};
                        font-weight: 500;
                        margin-bottom: 4px;
                    }
                    p {
                        font-size: 0.625rem;
                        color: ${theme.palette.default.darkGrey};
                        font-weight: 500;
                    }
                }
                p {
                    color:  ${theme.palette.default.darkGrey};
                    font-weight: 400;
                }
                svg {
                    width: 2rem;
                }
            }
            .footer-content__info {
                padding-top: 0.875rem;
                display: flex;
                .content-left {
                    width: 50%;
                    p {
                        font-size: 0.75rem;
                        color: ${theme.palette.default.black};
                        display: flex;
                        align-items: center;
                        margin: 0;
                        gap: 0.5rem;
                        svg  {
                            width: 1rem;
                        }
                    }
                }
                .content-right {
                    width: 50%;
                    p {
                        font-size: 0.75rem;
                        color: ${theme.palette.default.black};
                        
                        margin: 0;
                    }
                }
            } 
            .sample-csv {
                margin-top: 0.7rem; 
                font-size: 13px; 
                color: ${theme.palette.default.darkGrey};
            }   
        }

        .skip-duplicate {

            .checkbox-text {
                color: ${theme.palette.default.darkGrey};
                .MuiFormControlLabel-label {
                    font-size: 13px;
                }   
            }

            label {
                margin: 0;
            }

            svg {
                
            }
        }
        .sample-csv-btn{
            text-transform: lowercase;
            font-size: 13px;
            padding: 2px;
            margin: 2% 0%;
            background-color: white;
            text-decoration-line: underline;
        }

        .subscriptions {
            padding: 2rem 1rem 0rem 1rem;
            color: ${theme.palette.default.darkGrey}; 
            h6 {
                font-weight: 500;
                font-size: 1rem;
            }
            p {
                font-size: 13px;
            }
        }

        .sales-and-lifecycle {
            padding: 2rem 1rem 0rem 1rem;
            h6 {
                font-size: 1rem;
                font-weight: 500;
            }
            p {
                margin-top: 0.7rem; 
                font-size: 13px; 
                color: ${theme.palette.default.darkGrey};
            }
        }


        .footer {
            padding: 2rem 0rem 0rem 0rem;
            .footer-btns {
                justify-content: end;
                gap: 1.3rem; 
                padding-top: 0.7rem;
                padding-bottom: 0.7rem; 
                padding-right: 1rem;
                .saveBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }    
        }

        .crm-field__area {
            padding: 2rem 1rem 0rem 1rem;
            .crm-field__header {
                /* background-color: ${theme.palette.default.lighterGreyNew}; */
                .field-header__text {
                    /* padding-top: 0; */
                    h6 {
                        padding: 0.7rem 0rem 0.7rem 1rem;
                        color: ${theme.palette.default.text};
                        font-size: 1rem;
                        font-weight: 500;
                    }
                }
            }

            .fieldto-map__row {
                padding: 1rem;
                h6 {
                    color: ${theme.palette.default.text};
                    font-size: 1rem;
                    font-weight: 400;
                }
                p {
                    color: ${theme.palette.default.darkGrey}
                }
                svg.cancel-img {
                    color: ${theme.palette.default.error}
                }
                svg.success-img {
                    color: ${theme.palette.primary.main}
                }
            }
        }
        
    }
`;