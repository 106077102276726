import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import AppButton from "../AppButton";

interface Props {
  title: string;
  children?: React.ReactNode;
  disabled?: boolean;
  [otherProps: string]: any;
  variant?: any;
  startIcon?: string;
  minWidth?: string;
  checkIsValidProperty?: boolean;
}
const AppSubmitButton: React.FC<Props> = ({
  title,
  disabled,
  checkIsValidProperty = true,
  ...otherProps
}) => {
  const { isSubmitting, isValid, validateOnChange, isValidating } =
    useFormikContext();
  const { showAlert } = useNotification();

  useEffect(() => {
    if (validateOnChange && !isValid && isSubmitting && !isValidating) {
      showAlert(`Please fill the required fields`, "warning");
    }
  }, [isValid, isSubmitting, isValidating]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AppButton
      loading={isValid && isSubmitting}
      loadingPosition="center"
      isDisabled={disabled || isSubmitting || (checkIsValidProperty && !isValid)}
      type="submit"
      style={{ minWidth: "150px" }}
      className="appFormSubmitButton" // DO NOT USE it for styling purpose only used to identify this component and disable conditional rendering
      {...otherProps}
    >
      {isSubmitting ? title : title}
    </AppButton>
  );
};

export default AppSubmitButton;
