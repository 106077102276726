import React from 'react'
import styled from 'styled-components';
import { theme } from '../../Customization/Theme';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";

interface Props {
    label?: string;
    tooltip?: any;
    children?: React.ReactNode;
    [otherProps: string]: any;
  }

const AppFormLabel: React.FC<Props> = ({
    label,
    tooltip,
    children,
    ...otherProps
}) => {
  return (
    <Label {...otherProps}>
        {label}
        {children}
        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
  )
}

export default AppFormLabel;

const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;