import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import { AppErrorMessage } from "../../../../../AppComponents/Forms";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
interface Props {
  label?: string;
  Options: any;
  name: string;
  tooltip?: any;
  divStyle?: any;
    submitOnChange?: boolean;

  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const IconToggleButton: React.FC<Props> = ({
  label,
  name,
  Options,
  tooltip,
  divStyle,
    submitOnChange= false,
  sendValuesToParent,
  children,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    // handleChange,
    errors,
    touched,
    handleSubmit,
    // getFieldMeta,
    // initialValues,
    setFieldValue,
    values,
  } = useFormikContext<any>();

  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]);

  return (
    <Div style={divStyle}>
      <Label>
        {label}

        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <div style={{ display: "flex", gap: "16px" }} className="customInput">
        {Options &&
          Options.length > 0 &&
          Options.map((curElem: any, index: any) => {
            return (
              <SingleIconBox
                style={{ border: getIn(values,name) === index + 1 ?  `1px solid ${theme.palette.primary.main}` : null,  color: getIn(values,name) === index + 1 ?  theme.palette.primary.main: theme.palette.default.darkGrey }}
                key={index}
                onClick={()=> {setFieldValue(name, index+1);submitOnChange && handleSubmit()}}
              >
                {curElem?.id === 1 && <NotificationsOutlinedIcon  />}
                {curElem?.id === 2 && <ConfirmationNumberIcon />}
                {curElem?.id === 3 && <Inventory2OutlinedIcon />}
                {curElem?.id === 4 && <MarkChatUnreadIcon />}
              </SingleIconBox>
            );
          })}
      </div>
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default IconToggleButton;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
const Input = styled.input`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 1.3rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.darkGrey};
  }
`;

const SingleIconBox = styled.div`
  && {
    display: flex;
    justify-content:center;
    align-items:center;
    width:32px;
    height:32px;
    cursor:pointer;
    border-radius:4px;
    /* border:1px solid ${theme.palette.primary.main}; */
   
  }
`;
