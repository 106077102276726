import { createAsyncThunk } from "@reduxjs/toolkit";
import { ContactPropertiesSettingsService } from "./contactProperties";

export class ContactPropertiesActions {
  static getListProperties = createAsyncThunk(
    "ContactPropertiesSettingsService/getListProperties",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.getListProperties()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static updateFieldProperties = createAsyncThunk(
    "ContactPropertiesSettingsService/updateFieldProperties",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.updateFieldProperties(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static changeOrderFieldProperties = createAsyncThunk(
    "ContactPropertiesSettingsService/ChangeOrderFieldProperties",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.ChangeOrderFieldProperties(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static AddContactPropertiesSection = createAsyncThunk(
    "ContactPropertiesSettingsService/AddContactPropertiesSection",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.AddContactPropertiesSection(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static DeleteContactPropertiesSection = createAsyncThunk(
    "ContactPropertiesSettingsService/DeleteContactPropertiesSection",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.DeleteContactPropertiesSection(
        data
      )
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static UpdateContactPropertiesSection = createAsyncThunk(
    "ContactPropertiesSettingsService/UpdateContactPropertiesSection",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.UpdateContactPropertiesSection(
        data
      )
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static AddContactPropertiesFields = createAsyncThunk(
    "ContactPropertiesSettingsService/AddContactPropertiesFields",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.AddContactPropertiesFields(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static DeleteContactProperties = createAsyncThunk(
    "ContactPropertiesSettingsService/DeleteContactPropertiesSection",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.DeleteContactProperties(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static UpdateContactProperties = createAsyncThunk(
    "ContactPropertiesSettingsService/UpdateContactPropertiesSection",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.UpdateContactProperties(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static HideContactProperties = createAsyncThunk(
    "ContactPropertiesSettingsService/UpdateContactPropertiesSection",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.HideContactProperties(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static GetAllSectionDetails = createAsyncThunk(
    "ContactPropertiesSettingsService/GetAllSectionDetails",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetAllSectionDetails()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static GetAllOptions = createAsyncThunk(
    "ContactPropertiesSettingsService/GetAllOptions",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetAllOptions(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static reorderFieldPosition = createAsyncThunk(
    "ContactPropertiesSettingsService/reorderFieldPosition",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.reorderFieldPosition(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static GetHiddenField = createAsyncThunk(
    "ContactPropertiesSettingsService/GetHiddenField",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetHiddenField()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static UpdateHiddenField = createAsyncThunk(
    "ContactPropertiesSettingsService/UpdateHiddenField",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.UpdateHiddenField(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static GetDependencyProperties = createAsyncThunk(
    "ContactPropertiesSettingsService/GetDependencyProperties",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetDependencyProperties()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static GetPrimaryOptions = createAsyncThunk(
    "ContactPropertiesSettingsService/GetPrimaryOptions",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetDependencyOptions(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static GetDependantOptions = createAsyncThunk(
    "ContactPropertiesSettingsService/GetDependantOptions",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetDependencyOptions(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static CreateDependencyMapping = createAsyncThunk(
    "ContactPropertiesSettingsService/CreateDependencyMapping",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.CreateDependencyMapping(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static GetMappingByID = createAsyncThunk(
    "ContactPropertiesSettingsService/GetMappingByID",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetMappingByID(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static GetDependencyList = createAsyncThunk(
    "ContactPropertiesSettingsService/GetDependencyList",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.GetDependencyList()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static DeleteDependency = createAsyncThunk(
    "ContactPropertiesSettingsService/DeleteDependency",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.DeleteDependency(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static DeleteContactPropertiesField = createAsyncThunk(
    "ContactPropertiesSettingsService/DeleteContactPropertiesField",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.DeleteContactPropertiesField(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static changeFieldPosition = createAsyncThunk(
    "ContactPropertiesSettingsService/changeFieldPosition",
    (data: any, { dispatch, rejectWithValue }) => {
      return ContactPropertiesSettingsService.changeFieldPosition(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
}
