import { RootState } from "../../index";

export const automationBuilderState = (state: RootState) =>
  state.automationBuilder.data;
export const automationBuilderPayload = (state: RootState) =>
  state.automationBuilder.payload;
export const showModel = (state: RootState) =>
  state.automationBuilder.data.showModel;
export const Automationlisting = (state: RootState) =>
  state.automationBuilder.data.listitems;
export const modelConfigData = (state: RootState) =>
  state.automationBuilder.data.modelConfigData;
export const modelConfigError = (state: RootState) =>
  state.automationBuilder.modelConfigError;
export const listingCount = (state: RootState) =>
  state.automationBuilder.data.count;
export const singleAutomationData = (state: RootState) =>
  state.automationBuilder.data.automationData;
export const getAutomationError = (state: RootState) =>
  state.automationBuilder.getAutomationError;
export const AutomationFolderOptionList = (state: RootState) =>
  state.automationBuilder.data.folderListOptionsData;
export const isAutomationUpdated = (state: RootState) =>
  state.automationBuilder.data.isAutomationUpdated;
export const isAutomationPublished = (state: RootState) =>
  state.automationBuilder.data.isAutomationPublished;
export const automationUpdateError = (state: RootState) =>
  state.automationBuilder.automationUpdateError;
export const applicationsListData = (state: RootState) =>
  state.automationBuilder.data.applicationsList;
