import React, { memo } from "react";
import { getMessageTime } from "../../../Utils";
import {
  StyledContentWrapper,
  StyledMediaContainer,
  StyledMessageContainer,
  StyledMessageReplyRenderBox,
  StyledMessageRow,
  StyledMessageTimeStamps,
} from "../../Styles/StyledComponents";
import { AppMaterialIcons } from "../Icons";
import AppChatReadReceipts from "./AppChatReadReceipts";
import AppChatUserAvatar from "./AppChatUserAvatar";
import AppSpecificMessageReply from "./AppSpecificMessageReply";
import AppRenderName from "./AppRenderName";

interface Props {
  children: React.ReactNode;
  userMessage: boolean;
  isMedia?: boolean;
  isButton?: boolean;
  divStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
  time?: string;
  isPrivateNote?: boolean;
  msgId: string;
  avatar?: string;
  seen?: string;
  sent?: string;
  delivered?: string;
}

const AppMessageBoxWrapper: React.FC<Props> = ({
  userMessage = false,
  isMedia = false,
  isButton = false,
  isPrivateNote = false,
  divStyle,
  rowStyle,
  children,
  time = "a",
  msgId,
  avatar,
  seen,
  sent,
  delivered,
}) => {
  if (isMedia) {
    return (
      <StyledMessageRow
        userMessage={userMessage}
        style={rowStyle}
        className="styledMessageRow"
      >
        <AppChatUserAvatar userMessage={userMessage} />
        <StyledContentWrapper userMessage={userMessage} isButton={isButton}>
          <AppRenderName userMessage={userMessage} />
          <StyledMediaContainer
            userMessage={userMessage}
            style={divStyle}
            isButton={isButton}
          >
            {children}
          </StyledMediaContainer>

          <StyledMessageTimeStamps
            userMessage={userMessage}
            isButton={isButton}
          >
            {getMessageTime(time)}
            <AppChatReadReceipts
              userMessage={userMessage}
              seen={seen}
              sent={sent}
              delivered={delivered}
            />
          </StyledMessageTimeStamps>
        </StyledContentWrapper>
      </StyledMessageRow>
    );
  }

  return (
    <StyledMessageRow
      userMessage={userMessage}
      style={rowStyle}
      className="styledMessageRow"
    >
      <AppChatUserAvatar userMessage={userMessage} />
      <StyledContentWrapper userMessage={userMessage}>
        <AppRenderName userMessage={userMessage} />
        <StyledMessageContainer userMessage={userMessage} style={divStyle}>
          {children}
        </StyledMessageContainer>
        <StyledMessageTimeStamps userMessage={userMessage} isButton={isButton}>
          {getMessageTime(time)}
          {isPrivateNote ? (
            <AppMaterialIcons
              iconName="LockOutlined"
              style={{ fontSize: "0.95rem" }}
            />
          ) : (
            <AppChatReadReceipts
              userMessage={userMessage}
              seen={seen}
              sent={sent}
              delivered={delivered}
            />
          )}
        </StyledMessageTimeStamps>
      </StyledContentWrapper>
    </StyledMessageRow>
  );
};

export default memo(AppMessageBoxWrapper);
