import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm, AppFormField } from "../../../../AppComponents/Forms";
import ProtectedAppSubmitButton from "../../../../AppComponents/ProtectedComponents/Button/ProtectedAppSubmitButton";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { CreateMarketingListValidations } from "../../../../Models/ValidationSchema";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { marketingListActions } from "../../../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../../../Store/Slices/MarketingList/marketingList.selectors";
import { ReactComponent as FailedIcon } from "../../../../assets/images/Failed.svg";
import { CreateListWrap } from "../../ContactMarketingListStyle";
import { ProtectedAppForm } from "../../../../AppComponents/ProtectedComponents";
import { findPopupFormSpecificPermissionKeyUsingId } from "../../../../Utils/rolesUtils";
interface CreateListModalProps {
  toggleModalCreate: () => void;
  handlClick: () => void;
  isShowing: boolean;
}

const CreateListModal: React.FC<CreateListModalProps> = ({
  toggleModalCreate,
  handlClick,
  isShowing,
}) => {
  const navigate = useNavigate();
  const bot = useParams();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const { createMarketingListData }: any = useAppSelector(marketingListStore);

  //*WILL BE USED TO SET THE FORM ACCESS TYPE
  const [formAccessType, setFormAccessType] = useState<
    "create" | "read" | "update" | "delete" | undefined
  >();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findPopupFormSpecificPermissionKeyUsingId();
    setFormAccessType(result ?? "create");
    // setFormAccessType("read" ?? "create");
  }, []);

  const [userInput, setUserInput] = useState<any>({
    name: "",
  });

  const handleSubmit = (values: any, SubmitProps: any) => {
    // Handle form submission here
    SubmitProps.setSubmitting(true);
    dispatch(marketingListActions.createMarketingList(values))
      .then((res: any) => {
        if (res?.payload?.data.status === true) {
          setUserInput({ name: "" });
          toggleModalCreate();
          showAlert("Successfully created marketing list", "success");
          navigate(`/contacts/marketing/detail/${res.payload.data.data.id}`);
          SubmitProps.setSubmitting(false);
        } else {
          showAlert(
            res?.payload?.data.error || "Something went wrong",
            "error"
          );
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        SubmitProps.setSubmitting(false);
      });
  };

  return (
    <AppModel
      isShowing={isShowing}
      onClose={() => {}}
      divStyle={{
        width: "872px",
        gap: "0.5rem",
        overflowY: "auto",
        padding: "0px",
      }}
    >
      <CreateListWrap>
        <ProtectedAppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmit(values, submitProps);
            setUserInput(values);
          }}
          validationSchema={CreateMarketingListValidations}
          moduleId="marketing_list_crud"
          specificPermissionKey={formAccessType}
        >
          <div className="answered-popup">
            <div className="answered-heading">
              <p>Create List</p>
            </div>
            <div className="close-icon">
              <IconButton onClick={toggleModalCreate}>
                <FailedIcon />
              </IconButton>
            </div>
          </div>
          <div className="input-div">
            <AppFormField
              name="name"
              label="List name"
              placeholder="Enter List Name"
            />
          </div>

          <div className="listitem">
            <ul>
              <li>
                Choose a name that clearly reflects the purpose or criteria of
                the list. For example, if you're targeting customers interested
                in a particular product category, you might name the list
                "ProductX Enthusiasts."
              </li>
              <li>
                Keep the name concise and descriptive so you can easily identify
                the list later when selecting recipients for your campaigns.
              </li>
              <li>
                Once you've entered the name, click "Save" to finalize the
                creation of your marketing list and then import the users using
                the import button.
              </li>
            </ul>
          </div>
          <div className="btn">
            <div style={{ display: "flex", gap: "1rem" }}>
              <AppButton variant="grey" onClick={() => toggleModalCreate()}>
                Cancel
              </AppButton>
              <ProtectedAppSubmitButton
                moduleId="marketing_list_crud"
                specificPermissionKey="create"
                variant="cta"
                title={"Save"}
                style={{
                  width: "150px !important",
                }}
              />
            </div>
          </div>
        </ProtectedAppForm>
      </CreateListWrap>
    </AppModel>
  );
};

export default CreateListModal;
