import styled from "styled-components";
import { Checkbox } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { Fragment } from "react";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: any;
  name: string;
  children?: React.ReactNode;
  onChange?: (value: any, uniqueId?: any) => void;
  isArray?: boolean;
  uniqueId?: string;
  divStyle?: React.CSSProperties;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormCheckboxField: React.FC<Props> = ({
  label,
  name,
  children,
  onChange,
  submitOnChange = false,
  isArray,
  uniqueId,
  divStyle,
  ...otherProps
}) => {
  const {
    // setFieldTouched,
    // handleChange,
    errors,
    touched,
    // getFieldMeta,
    // initialValues,
    setFieldValue,
    handleSubmit,
    values,
  } = useFormikContext<any>();
  const handleSelectChange = (event: any, uniqueId?: any) => {
    if (isArray) {
      if (event.target.checked) {
        setFieldValue(name, [...getIn(values, name), uniqueId]);
      } else {
        setFieldValue(
          name,
          getIn(values, name).filter((item: any) => item !== uniqueId)
        );
      }
    } else {
      setFieldValue(name, event.target.checked);
    }

    onChange && onChange(event, uniqueId);
  };
  return (
    <Fragment>
      <Div style={divStyle}>
        <Checkbox
          {...otherProps}
          checked={
            isArray
              ? getIn(values, name).includes(uniqueId)
              : getIn(values, name) || false
          }
          onChange={(e: any) => {
            handleSelectChange(e, uniqueId);
          }}
          size="small"
        />
        <Label>{label}</Label>
      </Div>
      <div
        style={{
          marginBottom: "0.6rem",
          marginTop: "-0.3rem",
          // marginLeft: '2.5rem',
        }}
      >
        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </div>
    </Fragment>
  );
};

export default AppFormCheckboxField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 40%;
    gap: 4px;
    position: relative;
    /* margin-bottom: 0.6rem; */
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    width: 100%;
    white-space: nowrap;
  }
`;
