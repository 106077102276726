import { createSlice } from "@reduxjs/toolkit";
import { LiveChatActions } from "./cannedres.actions";

interface initialStateTypes {
    data: {
        allCannedMessagesData: any;
        allCannedMessagesDataCount: any;
        postCannedMessageData: any;
        deletedCannedMessageData: any;
        getCannedMessageData: any;
        updatedCannedMessageData: any;
        searchedCannedMessage: any;
        searchCannedMessageCount: any;
    };
    error: string;
    loading: boolean;
}

const initialState: initialStateTypes = {
    data: {
        allCannedMessagesData: null,
        postCannedMessageData: null,
        deletedCannedMessageData: null,
        getCannedMessageData: null,
        updatedCannedMessageData: null,
        allCannedMessagesDataCount: null,
        searchedCannedMessage: null,
        searchCannedMessageCount: null,
    },
    error: "",
    loading: false,
};


const LiveChatSlice = createSlice({
    name: "LiveChatSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder





            //* All Canned Messages
            .addCase(
                LiveChatActions.getCannedMessagesListings.pending,
                (state, action) => {
                    state.error = "";
                    state.loading = true;
                }
            )
            .addCase(
                LiveChatActions.getCannedMessagesListings.fulfilled,
                (state, action) => {
                    state.data.allCannedMessagesData = action.payload?.data?.data?.data || [];
                    state.data.allCannedMessagesDataCount = action.payload?.data?.data?.count || 0;
                    state.error = "";
                    state.loading = false;
                }
            )
            .addCase(
                LiveChatActions.getCannedMessagesListings.rejected,
                (state, action) => {
                    state.loading = false;
                    state.data.allCannedMessagesData = null;
                    state.error = action?.error?.message || "";
                }
            )

            //* Post Canned Message data
            .addCase(
                LiveChatActions.postCannedMessage.pending,
                (state, action) => {
                    state.error = "";
                    state.loading = true;
                }
            )
            .addCase(
                LiveChatActions.postCannedMessage.fulfilled,
                (state, action) => {
                    state.data.postCannedMessageData = action.payload.data?.response;
                    state.error = "";
                    state.loading = false;
                }
            )
            .addCase(
                LiveChatActions.postCannedMessage.rejected,
                (state, action) => {
                    state.loading = false;
                    state.data.postCannedMessageData = null;
                    state.error = action?.error?.message || "";
                }
            )

            //* Delete Canned Message
            .addCase(LiveChatActions.deleteCannedMessageById.pending, (state, action) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(LiveChatActions.deleteCannedMessageById.fulfilled, (state, action) => {
                state.data.deletedCannedMessageData = action.payload.data?.response;
                state.error = "";
                state.loading = false;
            })
            .addCase(LiveChatActions.deleteCannedMessageById.rejected, (state, action) => {
                state.loading = false;
                state.data.deletedCannedMessageData = null;
                state.error = action?.error?.message || "";
            })

            //* Get MEssage
            .addCase(LiveChatActions.getCannedMessageById.pending, (state, action) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(LiveChatActions.getCannedMessageById.fulfilled, (state, action) => {
                state.data.getCannedMessageData = action.payload?.data?.data || [];
                state.error = "";
                state.loading = false;
            })
            .addCase(LiveChatActions.getCannedMessageById.rejected, (state, action) => {
                state.loading = false;
                state.data.getCannedMessageData = null;
                state.error = action?.error?.message || "";
            })

            //* Update Canned Message
            .addCase(LiveChatActions.updateCannedMessageById.pending, (state, action) => {
                state.error = "";
                state.loading = true;
            })
            .addCase(LiveChatActions.updateCannedMessageById.fulfilled, (state, action) => {
                state.data.updatedCannedMessageData = action.payload.data?.response;
                state.error = "";
                state.loading = false;
            })
            .addCase(LiveChatActions.updateCannedMessageById.rejected, (state, action) => {
                state.loading = false;
                state.data.updatedCannedMessageData = null;
                state.error = action?.error?.message || "";
            })

             //* Search Canned Messages
             .addCase(
                LiveChatActions.searchCannedMessages.pending,
                (state, action) => {
                    state.error = "";
                    state.loading = true;
                }
            )
            .addCase(
                LiveChatActions.searchCannedMessages.fulfilled,
                (state, action) => {
                    state.data.searchedCannedMessage = action.payload?.data?.data?.data || [];
                    state.data.searchCannedMessageCount = action.payload?.data?.data?.count || [];
                    state.error = "";
                    state.loading = false;
                }
            )
            .addCase(
                LiveChatActions.searchCannedMessages.rejected,
                (state, action) => {
                    state.loading = false;
                    state.data.allCannedMessagesData = null;
                    state.error = action?.error?.message || "";
                }
            )



    }
})

export default LiveChatSlice.reducer;