import React from "react";
import {  useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { format } from "date-fns";
import { AnalyticsLoadingArray, applicationData, applicationFilter, dateRange } from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import { removeLoadingFromArray, setDashboardDateRange } from "../../../Store/Slices/DashboardRedux/dashboard.slice";

import AppSkeletonTableLoader from "../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { Box, IconButton, Typography } from "@mui/material";
import styled from "styled-components";
import AppRangeDatePicker from "../../../AppComponents/AppRangeDatePicker";
import DetailsTable from "../DeatilsTable/DetailsTable";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";
import { theme } from "../../../Customization/Theme";

const ApplicationSummaryDetails = () => {
    
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dashdateRange: any = useAppSelector(dateRange);
    const { id }: any = useParams();
    const loadingArray: any = useAppSelector(AnalyticsLoadingArray);
    const ApplicationData = useAppSelector(applicationData);
    const [searchParams, setSearchParams] = useSearchParams();
    const section = searchParams.get('section');


    const [storedData, setStoredData] = React.useState<any>(null);
    const [startDate, setStartDate] = React.useState<any>(null);
    const [endDate, setEndDate] = React.useState<any>(null);
    const [key, setKey] = React.useState<any>(null);
    const [label, setLabel] = React.useState<any>(null);
    const [type, setType] = React.useState<any>(null);

    React.useEffect(() => {
        let storedDataString: any = localStorage.getItem("applicationData");
        let data = JSON.parse(storedDataString);

        if (data) {
            setStoredData(data);
            setStartDate(data?.startdate);
            setEndDate(data?.enddate);
            setKey(data?.key);
            setLabel(data?.label);
            setType(data?.type);
        }
    }, []);

    React.useEffect(() => {
        const updatedStartDate: any = format(dashdateRange.startDate, "yyyy-MM-dd");
        const updatedEndDate: any = format(dashdateRange.endDate, "yyyy-MM-dd");
        setStartDate(updatedStartDate);
        setEndDate(updatedEndDate);
        const updatedData = { ...storedData, startdate: updatedStartDate, enddate: updatedEndDate };
        localStorage.setItem("applicationData", JSON.stringify(updatedData));

    }, [dashdateRange, storedData])

    console.log(startDate, endDate, key, "storedData")

    const [localValues, setLocalValues] = React.useState<any>({
        itemsPerPage: { label: "10  per page", value: 10 },
        currentPage: 0,
    });

    function handlePageChange(event: any, value: any) {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    }

    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 0,
        });
    };
    

    const onChange = (date: any) => {
        let temp = { ...date };
        delete temp.label;
        dispatch(setDashboardDateRange(temp));
    };


    React.useEffect(() => {
        if (id && key && startDate && endDate) {
            const payload = {
                key: key,
                value: id,
                limit: localValues?.itemsPerPage?.value,
                offset: localValues.currentPage,

                start_date: startDate,
                end_date: endDate
            };
            dispatch(DashboardActions.getApplicationDataAction(payload)).then(() => {
                dispatch(removeLoadingFromArray("applicationData"));
            }).catch(() => {
                dispatch(removeLoadingFromArray("applicationData"));
            });

        }

    }, [id, key, startDate, endDate, localValues]);

    
    const handleBackClick = () => {
        if (section) {
            navigate(`/analytics/dashboard#${section}`);
        } else {
            navigate('/analytics/dashboard');
        }
    };

    return <DetailsWrapper>
        <Box p={2} className="top-bar">
            <Box display={'flex'} gap={1} justifyContent={"center"} alignItems={"center"}>
                <IconButton onClick={() => handleBackClick()}>
                    <AppMaterialIcons
                        iconName={"ArrowBackIosRounded"}
                        style={{ fontSize: "0.8rem", color: "black" }}
                    />
                </IconButton>
                <Typography fontSize="16px" fontWeight="500" component="h6">
                    {type && type + "  -"} {label} 
                </Typography>

            </Box>
            <AppRangeDatePicker
                dateRange={dashdateRange}
                setDateRange={onChange}
            />
        </Box>

        <Box className="table-container">
            {loadingArray.includes("applicationData") ? (
                <AppSkeletonTableLoader
                    numberOfCol={4}
                    numberofRow={12}
                    skeletonHeight={30}
                    skeletonWidth={"100%"}
                    skeletonHeaderHeight={40}
                    skeletonHeaderWidth={"100%"}
                    tableHeight={`calc(100vh - 221px)`}
                />

            ) : (
                <>

                    {
                        ApplicationData && ApplicationData["response"] && ApplicationData["label_mapping"] && <DetailsTable
                            keyToHeaderMap={ApplicationData["label_mapping"]}
                            tableData={ApplicationData["response"]}
                            height={"calc(100% - 29px)"}
                            pagination={true}
                            border={false}
                            tableHeaderColor={true}
                        />
                    }

                </>
            )}

            {ApplicationData && ApplicationData["response"]?.length ? (
                <AppPaginationComponent
                    totalCount={ApplicationData?.pagination?.totalRecords}
                    handlePageChange={handlePageChange}
                    currentPage={localValues?.currentPage}
                    totalPages={ApplicationData?.pagination?.totalPages}
                    rowsPerPage={localValues?.itemsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            ) : null}
        </Box>

    </DetailsWrapper>
}

export default ApplicationSummaryDetails;

const DetailsWrapper = styled.div`
&& {
    height: 100%;
    .table-container {
        height: calc(100% - 81px);
        padding: 16px 16px 0 16px;
    }
    .top-bar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        background-color: ${theme.palette.default.white};
        z-index: 1;
        height: 4rem;
        width: ${(props: any) => (props?.fullWidth ? `100%` : "calc(100% - 2px)")};
        padding: 0 1rem;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    }
}
`;