import React from "react";
import { WidgetCopyrightBox } from "./styles-web-bot-static-preview";
import Footer_logo from "../../../../../../assets/images/getaFooter.svg";

const WebBotStaticFooter = () => {

  return (
    <WidgetCopyrightBox className="Web-WidgetCopyrightBox">
      Powered by
      <a href={`https://geta.ai/`} target="_blank" rel="noreferrer">
        <img src={Footer_logo} alt="Geta Widget" />
      </a>
    </WidgetCopyrightBox>
  );
};

export default WebBotStaticFooter;
