import React from "react";
import { Outlet } from "react-router-dom";

interface Props {
  layoutWithDiv?: boolean;
}

const BlankLayout: React.FC<Props> = ({ layoutWithDiv = false }) => {
  if (layoutWithDiv) {
    return (
      <div>
        <Outlet />
      </div>
    );
  }

  return <Outlet />;
};

export default BlankLayout;
