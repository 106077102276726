import styled from "styled-components";
import CustomBack from "./CustomBack";
import { theme } from "../../../Customization/Theme";
import Actions from "./Actions";
import { Box, Checkbox, Grid, ListItem, ListItemText } from "@mui/material";
import { useAppDispatch } from "../../../Store";
import { useEffect, useState } from "react";
import { ContactPropertiesActions } from "../../../Store/Slices/Settings/ContactProperties/contactProperties.action";
import { getAllHiddenField } from "../../../Store/Slices/Settings/ContactProperties/contactProperties.selector";
import { useSelector } from "react-redux";
import { FieldArray, useFormikContext } from "formik";

const DefaultField = (props: any) => {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext<any>();
  const [defaultField, setDefaultField] = useState(values?.defaultFields);

  useEffect(() => {
    props?.setDefaultList([]);
  }, []);
  useEffect(() => {
    if (values?.defaultFields) {
      setDefaultField(values?.defaultFields);
    }
  }, [values?.defaultFields]);
  const handlePrimaryOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedContactMapping = values?.defaultFields.filter((item: any) =>
      item?.label.toLowerCase()?.includes(event.target.value.toLowerCase())
    );
    setDefaultField(updatedContactMapping);
  };
  return (
    <>
      <CustomFieldWrapper id="name">
        <CustomBack
          name="Hidden Fields"
          handleBack={() => props?.closeModel(false)}
        />
        <Box
          sx={{
            height: "calc(100vh - 139px)",
            overflowY: "auto",
            padding: "1rem",
          }}
        >
          <Box display={"flex"} sx={{ width: "100%" }}>
            <StyledAppInput style={{ width: "100%", margin: 0 }}>
              <input
                type="text"
                onChange={(e: any) => handlePrimaryOptionChange(e)}
                placeholder="Search Domain"
                style={{ maxWidth: "100%", width: "100%", margin: 0 }}
              />
            </StyledAppInput>
          </Box>
          <Box>
            <FieldArray name={"defaultFields"}>
              {({ insert, remove, push, swap, unshift, pop, replace }) => (
                <>
                  {defaultField?.map((res: any, index: any) => {
                    return (
                      <ListItem
                        key={res?.name}
                        disablePadding
                        sx={{
                          borderBottom: `1px solid ${theme.palette.default.RightBorderDivder}`,
                          height: "50px",
                        }}
                      >
                        <Box display={"flex"} width={"100%"}>
                          <Box minWidth={"50px"}>
                            {!res?.is_dependant ? (
                              <Checkbox
                                size="small"
                                onClick={() => props?.setDefaultField(res)}
                              />
                            ) : null}
                          </Box>
                          <Box
                            minWidth={"50%"}
                            display={"flex"}
                            alignItems={"center"}
                          >
                            <ListItemText
                              id={index}
                              primary={res?.label?.toString()}
                              sx={{ fontWeight: "400" }}
                            />
                          </Box>
                          <Box display={"flex"} alignItems={"center"}>
                            <ListItemText
                              id={index}
                              primary={res?.type?.toString()}
                              sx={{ fontWeight: "400",textTransform: "capitalize" }}
                            />
                          </Box>
                        </Box>
                      </ListItem>
                    );
                  })}
                </>
              )}
            </FieldArray>
          </Box>
        </Box>
      </CustomFieldWrapper>
      <Actions handleBack={() => props?.closeModel(false)} />
    </>
  );
};

const CustomFieldWrapper = styled.div`
  // margin-left : 1%
  // border: 1px solid;
`;
const StyledAppInput = styled("div")`
  & {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0.6rem;
    input {
      height: 48px;
      border-radius: 4px;
      outline: none;
      border: 1px solid transparent;
      padding: 0 1.3rem 0 14px;
      font-family: "Rubik", sans-serif;
      border: 1px solid #cbcdd3;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      background-color: #fcfcfc;
      max-width: 238px;
      min-width: 238px;
      min-height: 35px;
      max-height: 35px;
    }
    svg {
      position: absolute;
      right: 10px;
    }
  }

  &::placeholder {
    color: ${theme.palette.default.grey};
  }
`;
export default DefaultField;
