import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import { ApexChatWrapper } from "./Doughnout";
import { GrahWrapper, TextTitle, Wrapstatistics } from "./EmailBroadcastSummaryStyles";


interface Props {
  broadcastStats?: any;
  loading?: boolean;
}

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#C589BF" : "#308fe8",
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FFDD55" : "#308fe8",
  },
}));

const BorderLinearProgress4 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1FA3D1" : "#308fe8",
  },
}));

const BorderLinearProgress5 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1FD171" : "#308fe8",
  },
}));

const BorderLinearProgress6 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "red" : "#308fe8",
  },
}));

export const ResponseTimeStatistics: React.FC<Props> = ({ broadcastStats, loading }) => {


  return (
    loading ? <AppSkeletonLoader /> :
              <Wrapstatistics>
          <Box sx = {{ flexGrow: 1 }} className="graph_container" style={{height: "100%"}}>
        <GrahWrapper>
          
            <Box display={"flex"} alignItems={"center"}>
              <ApexChatWrapper>
                <Box className="heading__tooltip">
                  <TextTitle>
                    <p className="time_stats">Statistics</p>
                  </TextTitle>
                  <AppToolTip
                    tooltipProps={{
                      title:
                        "Overview of email campaign performance, including sent, delivered, opened, clicked, and failed counts",
                      placement: "top-end",
                      value: 80,
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={
                      <AppMaterialIcons
                        iconName="HelpOutline"
                        style={{ fontSize: "16px" }}
                      />
                    }
                  />
                </Box>
              </ApexChatWrapper>
            </Box>
          

          {
            (broadcastStats?.sent ?? 0) === 0 &&
            (broadcastStats?.delivered ?? 0) === 0 &&
            (broadcastStats?.opened ?? 0) === 0 &&
            (broadcastStats?.clicked ?? 0) === 0 &&
            (broadcastStats?.failed ?? 0) === 0 ? (
              <NoRecordsFound
                imageWidth={"200px"}
                headerTextSize={"19px"}
                    imageSrc={NoRecordImage}
                />
            ) : (
                  <StatisticsBarsWrapper>
                            <div className="progess_bar">
                              <div className="progress_bar_text">
                                <p className="header_avg">Sent</p>
                              </div>

                              <div className="progress_bar_count">
                                <p className="stats">{broadcastStats?.sent}</p>
                                <BorderLinearProgress2
                                  variant="determinate"
                                  value={Math.min((broadcastStats?.sent / broadcastStats?.recipients) * 100, 100)}
                                />
                              </div>
                            </div>

                            <div className="progess_bar">
                              <div className="progress_bar_text">
                                <p className="header_avg">Delivered</p>
                              </div>

                              <div className="progress_bar_count">
                                <p className="stats">{broadcastStats?.delivered}</p>
                                <BorderLinearProgress3
                                  variant="determinate"
                                  value={ Math.min((broadcastStats?.delivered / broadcastStats?.recipients) * 100, 100) }
                                />
                              </div>
                            </div>

                            <div className="progess_bar">
                              <div className="progress_bar_text">
                                <p className="header_avg">Open</p>
                              </div>

                              <div className="progress_bar_count">
                                <p className="stats">{broadcastStats?.opened}</p>
                                <BorderLinearProgress4
                                  variant="determinate"
                                  value={ Math.min((broadcastStats?.opened / broadcastStats?.recipients) * 100, 100) }
                                />
                              </div>
                            </div>

                            <div className="progess_bar">
                              <div className="progress_bar_text">
                                <p className="header_avg">Clicks</p>
                              </div>
                              <div className="progress_bar_count">
                                <p className="stats">{broadcastStats?.clicked}</p>
                                <BorderLinearProgress5
                                  variant="determinate"
                                  value={ Math.min((broadcastStats?.clicked / broadcastStats?.recipients) * 100, 100) }
                                />
                              </div>
                            </div>

                            <div className="progess_bar">
                              <div className="progress_bar_text">
                                <p className="header_avg">Failed</p>
                              </div>

                              <div className="progress_bar_count">
                                <p className="stats">{broadcastStats?.failed}</p>
                                <BorderLinearProgress6
                                  variant="determinate"
                                  value={ Math.min((broadcastStats?.failed / broadcastStats?.recipients) * 100, 100) }
                                />
                              </div>
                            </div>
                  </StatisticsBarsWrapper>
            )
          }
        </GrahWrapper>
      </Box >
        </Wrapstatistics >
      
  );
};

const StatisticsBarsWrapper = styled(Box)`
&& {
  height: inherit;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
`;