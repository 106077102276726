import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AutoAssignmentRulesSettingsActions } from "./autoAssignmentRulesSettings.actions";

interface initialStateTypes {
  data: {
    searchTerm: string;
    contactList: any;
    contactRulesById: any;
    allTabspagination: any;
    count: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    searchTerm: "",
    contactList: [],
    contactRulesById: null,
    count: null,
    allTabspagination: {
      currentPage: 0,
      itemsPerPage: 10,
      query: "",
    },
  },
  error: "",
  loading: false,
};

const AutoAssignmentRulesSettings = createSlice({
  name: "AutoAssignmentRulesSettings",
  initialState,
  reducers: {
    paginationHandling: (state: any, action?: PayloadAction<any>) => {
      state.data.allTabspagination = action?.payload;
      return state;
    },
    unmountListing: (state: any, action?: PayloadAction<any>) => {
      state.data.contactList = [];
      state.data.count = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder

      // create contacts rules
      .addCase(
        AutoAssignmentRulesSettingsActions.createContactRules.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.createContactRules.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.createContactRules.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      // get  contacts list
      .addCase(
        AutoAssignmentRulesSettingsActions.getRulesList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.getRulesList.fulfilled,
        (state, action) => {
          state.data.contactList = action?.payload?.data?.assignment_rules;
          state.data.count = action?.payload?.data?.count;
          state.data.contactRulesById = null;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.getRulesList.rejected,
        (state, action) => {
          state.data.contactList = [];
          state.data.count = null
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      // get  contact by id
      .addCase(
        AutoAssignmentRulesSettingsActions.getContactsRulesById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.getContactsRulesById.fulfilled,
        (state, action) => {
          state.data.contactRulesById = action.payload.data?.assignment_rule;

          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.getContactsRulesById.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      // Update  Contacts rules
      .addCase(
        AutoAssignmentRulesSettingsActions.UpdateContactsRulesById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.UpdateContactsRulesById.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.UpdateContactsRulesById.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      )

      // delete contacts By ID
      .addCase(
        AutoAssignmentRulesSettingsActions.deleteContactsRulesById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.deleteContactsRulesById.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        AutoAssignmentRulesSettingsActions.deleteContactsRulesById.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        }
      );
  },
});
export const { paginationHandling, unmountListing } =
  AutoAssignmentRulesSettings.actions;
export default AutoAssignmentRulesSettings.reducer;
