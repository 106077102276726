import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import uniqid from "uniqid";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormSelectField,
  AppFormSwitchField,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppDeleteIcon from "../../../AppComponents/Forms/AppDeleteIcon";
import AppFormAutoSearchField from "../../../AppComponents/Forms/AppFormAutoSearchField";
import AppFormCheckboxField from "../../../AppComponents/Forms/AppFormCheckboxField";
import AppFormLabel from "../../../AppComponents/Forms/AppFormLabel";
import {
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox,
} from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../HOC/HocBackdropLoader";
import useDocumentTitle from "../../../Hooks/useDocumentTitle";
import AppModel from "../../../Hooks/useModel/AppModel";
import useModal from "../../../Hooks/useModel/useModel";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { Chatbot } from "../../../Models";
import { LanguageOptions } from "../../../Models/defaultSelectOptions";
import { AdditionalLanguageSchema } from "../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../Services/Apis/ChatbotConsoleService";
import { ParaphraserService } from "../../../Services/Apis/ParaphraserService";
import {
  ChangeSelectedBot,
  useCurrentBot,
} from "../../../Store/Slices/DashboardSlices";
import { formatString, makeStringFirstLetterUppercase } from "../../../Utils";

interface Props extends HocBackdropLoaderProps { }


const ChatLanguages: React.FC<Props> = ({ setLoading }) => {
  useDocumentTitle("Languages");
  const { showAlert } = useNotification();
  const { isShowing, toggle } = useModal();
  const currentBot: Chatbot = useSelector(useCurrentBot);
  const dispatch = useDispatch();
  let URL = formatString("/bot/{0}/setup/faq", [currentBot.bot_id]);
  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };

  const [localValues, setLocalValues] = useState<any>(null);
  const [languageOptions, setLanguageOptions] = useState<any>([]);

  const [userInput, setUserInput] = useState<any>({
    multi_lingual: true,
    primary_language: "english",
    display_language_option: false,
    display_message: "Before we start, Please choose your language?",
  });

  const [languageInput, setLanguageInput] = useState<any>({
    language: null,
    enabled: true,
    translate: true,
  });

  const [botData, setBotData] = useState<Chatbot>(currentBot);

  const getBotData = () => {
    if (currentBot.bot_id) {
      ChatbotConsoleService.getBotById({ bot_id: currentBot.bot_id })
        .then((response: any) => {
          let data: Chatbot = response?.data
          setBotData(data);
          setUserInput({
            ...userInput,
            multi_lingual: data.multi_lingual?.multi_lingual,
            display_language_option:
              data.multi_lingual?.display_language_option,
            display_message: data.multi_lingual?.display_message,
          });
        })
        .catch((error: any) => {
        });
    }
  };

  useEffect(() => {
    getBotData();
    ChatbotConsoleService.getAllLanguages()
      .then((response: any) => {
        setLanguageOptions(response?.data);
      })
      .catch((error: any) => {
      });
  }, [currentBot]);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    let multi_lingual = {
      multi_lingual: values?.multi_lingual,
      display_language_option: values?.display_language_option,
      display_message: values?.display_message,
    };

    ChatbotConsoleService.updateBot(currentBot?.id, {
      ...botData,
      multi_lingual: multi_lingual,
    })
      .then((response: any) => {
        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        showAlert("Configuration updated", "success");
        dispatch(ChangeSelectedBot(response?.data));
      })
      .catch((error) => {
        SubmitProps?.setSubmitting(false);
        showAlert("something went wrong", "error");
      });
  };

  const handleLanguageBtn = (values: any, SubmitProps: any) => {

    let abc = {
      enabled: values?.enabled,
      translate: values?.translate,
      language: values?.language?.value,
    };

    let temp: any = [];
    if (botData?.chatbot_languages && botData?.chatbot_languages?.length > 0) {
      temp = [...botData?.chatbot_languages];
    }

    temp.push(abc);

    ChatbotConsoleService.updateBot(currentBot?.id, {
      ...botData,
      chatbot_languages: temp,
    })
      .then((response: any) => {
        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        toggle();
        dispatch(ChangeSelectedBot(response?.data));
        showAlert("New language added", "success");
        getBotData();

        if (abc?.translate && abc?.language) {
          handleTranslationBtn(abc, SubmitProps);
        }
      })
      .catch((error) => {
        SubmitProps?.setSubmitting(false);
        showAlert("something went wrong", "error");
        toggle();
      });

    if (!values?.language) {
      SubmitProps?.setSubmitting(false);
    }
    // }
  };

  const handleTranslationBtn = (values: any, SubmitProps: any) => {

    setLoading(true);

    ParaphraserService.allLanguageTranslate(
      { language: values?.language },
      HEADERS
    )
      .then((res: any) => {

        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        toggle();
        showAlert("Language translation finished", "success");
        setLoading(false);
        getBotData();
      })
      .catch((error: any) => {
        SubmitProps?.setSubmitting(false);
        SubmitProps?.resetForm();
        toggle();
        showAlert("Language translation failed", "error");
        setLoading(false);
      });
  };

  const handleStatusBtn = (
    event: React.MouseEvent<unknown>,
    item: any,
    rowIndex: number
  ) => {
    let tempAllLanguage: any = [...botData.chatbot_languages];
    tempAllLanguage.splice(rowIndex, 1);

    item["enabled"] = !item.enabled;

    tempAllLanguage.push(item);

    ChatbotConsoleService.updateBot(currentBot?.id, {
      ...botData,
      chatbot_languages: tempAllLanguage,
    })
      .then((response: any) => {
        dispatch(ChangeSelectedBot(response?.data));
        showAlert(`${item?.language} status changed`, "success");
      })
      .catch((error) => {
        showAlert("something went wrong", "error");
      });
  };

  const handleDeleteBtn = (item: any, rowIndex: number) => {
    let tempAllLanguage: any = [...botData.chatbot_languages];
    tempAllLanguage.splice(rowIndex, 1);
    ChatbotConsoleService.updateBot(currentBot?.id, {
      ...botData,
      chatbot_languages: tempAllLanguage,
    })
      .then((response: any) => {
        dispatch(ChangeSelectedBot(response?.data));
        showAlert(`${item?.language} langauge deleted`, "success");
      })
      .catch((error) => {
        showAlert("something went wrong", "error");
      });
  };

  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  return (
    <SettingPageMainBoxWrap style={{ padding: "0.5rem 0.5rem" }}>
      <SettingPageMainBoxTopbar>
        <SettingPageMainBoxTitle
          style={{
            color: theme.palette.primary.main,
            fontWeight: 500,
            fontSize: "1.05rem",
            lineHeight: "1.5rem",
            letterSpacing: "0.8px",
          }}
        >
          Languages
        </SettingPageMainBoxTitle>
      </SettingPageMainBoxTopbar>

      <SettingPageMainContentBox
        style={{
          boxShadow: "none",
          alignItems: "start",
          justifyContent: "left",
          padding: "0",
          flexDirection: "row",
          gap: "5%",
          width: "100%",
          backgroundColor: "transparent",
          paddingBottom: "0rem",
        }}
      >
        {/* enable multi language */}
        <FormDivBox>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, SubmitProps: any) => {
              handleSubmitBtn(values, SubmitProps);
            }}
          // validationSchema={MultiLanguageSchema}
          >
            <AppFormSelectField
              label="Primary Language"
              Options={LanguageOptions}
              name="primary_language"
              disabled={true}
              divStyle={{ marginBottom: "1rem" }}
              tooltip={
                "The primary language has been set while creating the workspace and cannot be changed."
              }
            />
            <AppFormSwitchField
              disabled={false}
              label="Enable Multilingual Bot"
              name="multi_lingual"
              sendValuesToParent={(values: any) => sendValuesToParent(values)}
            />
            <AppFormSwitchField
              label="Display Switch Language Option on the Chat Widget "
              name="display_language_option"
              disabled={
                localValues && localValues?.multi_lingual ? false : true
              }
            />
            <AppSubmitButton
              variant="cta"
              title={"Save Changes"}
              style={{ margin: "0rem 0 1rem" }}
            />
          </AppForm>

          {/* add language */}
          <AppModel
            isShowing={isShowing}
            onClose={() => toggle()}
            divStyle={{ gap: "0.5rem" }}
          >
            <AppForm
              initialValues={languageInput}
              onSubmit={(values: any, SubmitProps: any) => {
                handleLanguageBtn(values, SubmitProps);
              }}
              validationSchema={AdditionalLanguageSchema}
            >
              <AppFormAutoSearchField
                label="Additional Language"
                Options={languageOptions}
                name="language"
                placeholder="Select a Language"
              />
              <AppFormCheckboxField
                label="Would you like to automatically translate the new language."
                name={`translate`}
              />
              <AppSubmitButton variant="cta" title={"Add Language to Bot"} />
            </AppForm>
          </AppModel>
        </FormDivBox>

        {/* table */}
        <FormDivBox
          style={{
            pointerEvents:
              localValues && localValues?.multi_lingual ? "auto" : "none",
            opacity: localValues && localValues?.multi_lingual ? 1 : 0.5,
            userSelect:
              localValues && localValues?.multi_lingual ? "auto" : "none",
            overflowY: "auto",
            minWidth: "600px",
          }}
        >
          <Table
            sx={{ width: "100%", overflowY: "auto" }}
            aria-label="simple table"
          >
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">Sr.</StyledTableCell>
                <StyledTableCell>
                  {" "}
                  <AppFormLabel
                    tooltip={"Additional languages that you have added"}
                  >
                    Additional Languages
                  </AppFormLabel>
                </StyledTableCell>
                {/* <StyledTableCell align="right">Status</StyledTableCell> */}
                <StyledTableCell align="right">Action</StyledTableCell>
              </StyledTableRow>
            </TableHead>

            <TableBody
              sx={{
                width: "100%",
                overflowY: "auto",
                // backgroundColor: 'yellow'
              }}
            >
              {botData?.chatbot_languages &&
                botData?.chatbot_languages?.length > 0 &&
                botData?.chatbot_languages?.map(
                  (curItem: any, index: number) => {
                    return (
                      <StyledTableRow key={uniqid()}>
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>
                          {
                            // curItem?.language
                            makeStringFirstLetterUppercase(curItem?.language)
                          }
                        </StyledTableCell>
                        {/* <StyledTableCell align="right">
                          <Android12Switch
                            checked={curItem?.enabled}
                            onChange={(event: any) =>
                              handleStatusBtn(event, curItem, index)
                            }
                            value={curItem?.enabled}
                            inputProps={{ "aria-label": "Langugae Switch" }}
                          />
                        </StyledTableCell> */}
                        <StyledTableCell align="right">
                          <AppDeleteIcon
                            tooltip={`Delete ${curItem?.language}`}
                            onClick={() => handleDeleteBtn(curItem, index)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  }
                )}
            </TableBody>
          </Table>
          <AppButton
            variant="text"
            startIcon="ri-add-line"
            style={{ width: "30%", minWidth: "190px", margin: "0rem 0 1rem" }}
            onClick={toggle}
            disabled={localValues && localValues?.multi_lingual ? false : true}
          >
            Add New Languages
          </AppButton>
        </FormDivBox>
      </SettingPageMainContentBox>
    </SettingPageMainBoxWrap>
  );
};

export default HocBackdropLoader(ChatLanguages);

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.default.white,
    color: theme.palette.default.darkGrey,
    fontWeight: "400",
    fontSize: "0.9rem",
    textTransform: "capitalize",
    lineHeight: "8px",
    borderBottom: "none !important",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.default.darkGrey,
    fontWeight: "400",
    fontSize: "0.9rem",
    borderBottom: "none !important",
    lineHeight: "16px",
    height: 32,
    maxHeight: 32,
    whiteSpace: "nowrap",
    overflow: "hidden",
    padding: "8px 16px",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    borderBottom: "none !important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: "none !important",
  },
  "&:hover": {},
}));

const FormDivBox = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    box-shadow: ${theme.palette.default.shadow};
    width: 35%;
    min-height: 65vh;
    overflow-y: auto;
    padding: 1rem 1rem 0rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    border-radius: 8px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;
