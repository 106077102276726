import DeleteOutlineTwoToneIcon from "@mui/icons-material/DeleteOutlineTwoTone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {IconButton } from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormField,
  AppFormImagePicker,
  AppFormRadioInput,
  AppFormSelectField,
  AppFormSwitchField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import {
  SettingPageMainBoxTitle,
  StyledCustomizerActionBtnsWrapper,
} from "../../../../../Customization/CommonElements";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import {
  QuickBannerLinkOptions,
  QuickMenuLinkOptions,
} from "../../../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { ConversationService } from "../../../../../Services/Apis/ConversationService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { getDisplayValidNodes } from "../../../../../Utils";
import AppVideoPlayer from "../../../../ChatHistory/ChatWindow/RenderComponents/AppVideoPlayer";
import MediaError from "../../Assets/MediaError.svg";
import { StyledNotificationCard } from "./SubComponents/BannerCSS";

import AppButton from "../../../../../AppComponents/AppButton";
import { BannerCarouselSchema } from "../../../../../Models/ValidationSchema";
import WebBotStaticPreview from "../../StaticPreview/webBot/WebBotStaticPreview";
import { setBotConfig, useBotConfigStore } from "../../../../../Store/Slices/botConfig/BotConfigSlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
const BannerCarousel = () => {
  let { showAlert } = useNotification();
  const currentBot = useSelector(useCurrentBot);
  const [localValues, setLocalValues] = useState<any>([]);
  const [reRenderState, setReRenderState] = useState<boolean>(false);

  const [enableState, setEnableState] = useState<any>({
    enabled: false,
  });
  const [userInput, setUserInput] = useState<any>({
    image: "",
    video: "",
    banner_url: "https://",
    View_options: "Image",
    position: "Add Link",
    journey: null,
    main_flow: 0,
    sub_flow: 0,
  });
  const [bannermenudata, setBannerMenuData] = useState<any>([]);
  const [subFlowOptions, setSubFlowOptions] = useState<any>([]);
  const [flowOptions, setFlowOptions] = useState([]);
  const [isEditing, setIsEditing] = useState<any>(null);
  const [originalSubNodes, setOriginalSubNodes] = useState<any>([]);
  const { botConfig } = useAppSelector(useBotConfigStore);
  const dispatch=useAppDispatch();

  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };
  const getPreviousData = () => {
    if (
      currentBot?.chatbot_configuration &&
      currentBot?.chatbot_configuration
    ) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot?.chatbot_configuration as string
      )
        .then((res: AxiosResponse<ChatbotConfiguration>) => {
          dispatch(
            setBotConfig({ ...botConfig, chatbot_configuration: res.data })
          );
          if (res.data?.banner_carousel) {
            setBannerMenuData(res?.data?.banner_carousel.data);
            setEnableState({
              enabled: res.data?.banner_carousel?.enabled ? true : false,
            });
          }
          setReRenderState(!reRenderState);
        })
        .catch((error: any) => {
        });
    }
  };
  useEffect(() => {
    getPreviousData();
  }, [currentBot]);
  const handleEnableStateForm = (values: any, SubmitProps: any) => {
    let SorId: any = [];
    bannermenudata?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          banner_carousel: {
            enabled: values?.enabled,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps?.setSubmitting(false);
      });
  };

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    let abc: any = [];
    bannermenudata.forEach((currentItem: any) => {
      let current = {
        ...currentItem,
        image: currentItem?.image?.id,
      };
      abc.push(current);
    });

    // Replace all iframe tags with just their URLs
    var dat = values?.video;
    function extractVideoUrl(dat: any) {
      var urlRegex = /src="(.+?)"/g;
      var match = urlRegex.exec(dat);
      if (match) {
        return match[1];
      }
      return values?.video;
    }

    let santdata = extractVideoUrl(dat);
    let temp = {
      image: values?.image?.id,
      video: santdata,
      banner_url: values?.banner_url,
      View_options: values?.View_options,
      position: values.position,
      journey: {
        main_flow: values?.main_flow,
        sub_flow: originalSubNodes[values?.sub_flow?.nodeIndex],
      },
    };

    let lmn: any = [...abc];
    lmn.push(temp);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          banner_carousel: {
            enabled: enableState?.enabled,
            data: lmn,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        SubmitProps.resetForm();

        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps?.setSubmitting(false);
      });
  };
  useEffect(() => {
    ConversationService.getFlowList(HEADERS)
      .then((res: AxiosResponse<any>) => {
        if (Array.isArray(res?.data?.data)) {
          setFlowOptions(res?.data?.data);
        } else {
          setFlowOptions([]);
        }
      })
      .catch((error: any) => {
      });
  }, []);
  const getSubFlow = async (qid: string) => {
    const headers = {
      appid: JSON.stringify({
        botid: currentBot.bot_id,
      }),
    };
    await ConversationService.getLinkSubFlow(qid, headers)
      .then(async (response: any) => {
        setOriginalSubNodes(response?.data?.data);
        let abc: any = getDisplayValidNodes(response?.data?.data);
        setSubFlowOptions(abc);

      })
      .catch((error: any) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (localValues?.main_flow?.qid) {
      getSubFlow(localValues?.main_flow?.qid);
    }
  }, [localValues?.main_flow]);

  const handleUpdateBtn = (values: any, SubmitProps: any) => {
    SubmitProps.validateForm();
    let xyz = bannermenudata?.filter((index: any) => index !== isEditing);
    let SorId: any = [];
    xyz?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });
    let videoUrl = values?.video;
    function extractVideoUrl(dat: any) {
      var urlRegex = /src="(.+?)"/g;
      var match = urlRegex.exec(dat);
      if (match) {
        return match[1];
      }
      return dat;
    }
    let newUrl = extractVideoUrl(videoUrl);
    let temp = {
      image: values?.image?.id,
      video: newUrl,
      banner_url: values?.banner_url,
      View_options: values?.View_options,
      position: values?.position,
      journey: {
        main_flow: values?.main_flow,
        sub_flow: originalSubNodes.filter((ele: any) => ele.id === values.sub_flow.id)[0],
      },
      enabled: enableState.enabled,
    };

    SorId.push(temp);

    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          banner_carousel: {
            enabled: enableState?.enabled,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        SubmitProps.resetForm();
        setUserInput({
          image: "",
          video: "",
          banner_url: "https://",
          View_options: "Image",
          position: "Add Link",
          journey: null,
          main_flow: 0,
          sub_flow: 0,
        });
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        setIsEditing(null);
      });
  };

  const handleEditButton = async (item: any) => {
    setIsEditing(item);
    setUserInput({
      image: item?.image || "",
      video: item?.video || "",
      banner_url: item?.banner_url,
      View_options: item?.View_options,
      position: item?.position || "Add Link",
      main_flow: item?.journey ? item?.journey?.main_flow : 0,
      sub_flow: item?.journey?.sub_flow ? item?.journey?.sub_flow : 0,
    });
  };

  const handleDeleteButton = (item: any) => {
    let abc = bannermenudata?.filter(
      (el: any) => el.image !== item.image || el.video !== item.video
    );
    let SorId: any = [];

    abc?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          banner_carousel: {
            enabled: enableState?.enabled,
            ...bannermenudata,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      });
  };

  const handleCancel = (SubmitProps: any) => {
    setIsEditing(false);
    setUserInput({
      image: "",
      video: "",
      banner_url: "https://",
      View_options: "Image",
      position: "Add Link",
      main_flow: 0,
      sub_flow: 0,
      journey: null,
    });
  };
  return (
    <>
      <AppSmallWindowPane>
        <AppForm
          initialValues={enableState}
          onSubmit={(values: any, submitProps: any) => {
            handleEnableStateForm(values, submitProps);
          }}
        >
          <AppFormSwitchField
            label="Banner"
            name="enabled"
            submitOnChange={true}
          />
        </AppForm>
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            isEditing
              ? handleUpdateBtn(values, submitProps)
              : handleSubmitBtn(values, submitProps);
          }}
          validationSchema={BannerCarouselSchema}
        >
          <AppFormRadioInput
            label="Options"
            name="View_options"
            Options={QuickBannerLinkOptions}
            sendValuesToParent={(values: any) => sendValuesToParent(values)}
          />

          {localValues?.View_options === "Image" ? (
            <>
              <AppFormImagePicker
                label="Upload Banner Image"
                name="image"
                tooltip={
                  <ul>
                    <li>Recommended size 64*64 px</li>
                    <li>Your logo must be smaller than 1 MB</li>
                    <li>A PNG, JPEG or JPG works best</li>
                  </ul>
                }
                sizeLimit={1000000}
                sizeErrorMsg={"Image size must be smaller than 1MB."}
                formatErrorMsg={"image should be PNG, JPEG or JPG."}
                squareImageBox={true}
              />

              <AppFormRadioInput
                label="Select Button Action"
                name="position"
                Options={QuickMenuLinkOptions}
                sendValuesToParent={(values: any) => sendValuesToParent(values)}
              />
            </>
          ) : (
            <AppFormTextArea
              label="Embed Video Banner"
              name="video"
              tooltip={"Enter embed"}
              placeholder="Embed video code"
            />
          )}

          {localValues?.position === "Link To Flow" && localValues?.View_options !== "Video" ? (
            <>
              <AppFormSelectField
                label="Select a flow"
                defaulttext={"Select a Flow"}
                Options={flowOptions}
                name="main_flow"
                style={{ minWidth: "300px", maxWidth: "450px" }}
                variant="object"
                valueKey="qid"
                triggerEmptyField="sub_flow"
              />
              <AppFormSelectField
                valueKey="id"
                label="Select a node"
                defaulttext={"Select a node"}
                variant="object"
                Options={subFlowOptions}
                name="sub_flow"
                style={{ minWidth: "300px", maxWidth: "450px" }}
              />
            </>
          ) : (
            localValues?.View_options === "Image" && (
              <AppFormField
                label="Banner Link"
                name="banner_url"
                placeholder="https://www.geta.ai"
              />
            )
          )}
          <StyledCustomizerActionBtnsWrapper>
            {isEditing && (
              <div className="Cancel_button">
                <AppButton
                  onClick={handleCancel}
                  variant="outline"
                  style={{ width: "150px " }}
                >
                  Cancel
                </AppButton>
              </div>
            )}
            <AppSubmitButton title={isEditing ? "Update" : "Create"} />
          </StyledCustomizerActionBtnsWrapper>
        </AppForm>
      </AppSmallWindowPane>

        <AppSmallWindowPane style={{ paddingBottom: "5rem" }}>
          <SettingPageMainBoxTitle
            style={{ fontSize: "16px", marginBottom: "0.5rem" }}
          >
            Available Banner
          </SettingPageMainBoxTitle>
          <div style={{ width: "100%", height: "100" }}>
            {bannermenudata?.map((curItem: any, index: number) => {
              return (
                <StyledNotificationCard key={`carousel-${index}`}>
                  {curItem?.video && curItem.View_options === "Video" ? (
                    <AppVideoPlayer url={curItem?.video} />
                  ) : (
                    <img
                      src={curItem?.image?.source || MediaError}
                      alt="Banner"
                      title={
                        curItem?.image?.source
                          ? "Banner Image"
                          : "Media Not Found"
                      }
                    />
                  )}

                  <div className="rightTopAction" key={index}>
                    <IconButton onClick={() => handleEditButton(curItem)}>
                      <EditOutlinedIcon />
                    </IconButton>
                    <IconButton disabled={isEditing ? true : false} onClick={() => handleDeleteButton(curItem)}>
                      <DeleteOutlineTwoToneIcon />
                    </IconButton>
                  </div>
                </StyledNotificationCard>
              );
            })}
          </div>
        </AppSmallWindowPane>

      <WebBotStaticPreview />
    </>
  );
};
export default BannerCarousel;
