import {
  Box,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
  debounce,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppRangeDatePicker from "../../../../../AppComponents/AppRangeDatePicker";
import { AppForm } from "../../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../../AppComponents/Forms/AppFormSearchField";
import { NewNativeSelect } from "../../../../../AppComponents/Forms/Styles";
import AppPaginationComponent from "../../../../../AppComponents/Pagination/Pagination";
import {
  ProtectedAppButton,
  ProtectedAppUiElement,
} from "../../../../../AppComponents/ProtectedComponents";
import { theme } from "../../../../../Customization/Theme";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { DefinedCustomDates } from "../../../../../Models/defaultSelectOptions";
import { useAppDispatch } from "../../../../../Store";
import { filtersValuesData, logReportListDataObj, logReportLoadingSelector } from "../../../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import { broadcastActions } from "../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { marketingListActions } from "../../../../../Store/Slices/MarketingList/marketingList.actions";
import {
  BroadcastTopTitle,
  TableFilter,
  TableWrapper,
} from "../../../../../TableStyle";
import AppSkeletonTableLoader from "../../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import NoRecordsFound from "../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import {
  BroadcastBodyWrapper,
  BroadcastMainWrapper,
  BroadcastMainWrapperTitle
} from "../LogsStyles";

const SmsLogsReport = () => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const filtersValues = useSelector(filtersValuesData);
  const listData = useSelector(logReportListDataObj);
  const list = listData?.results;
  const loading = useSelector(logReportLoadingSelector)
  const [logsData, setLogsData] = useState<boolean>(false);
  const [errorApi, setErrorApi] = useState(false);
  const [pagination, setPagination] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    broadcast_type: "sms",
  });
  useEffect(() => {
    if (listData) {
      setPagination(listData?.pagination);
    }
  }, [listData]);

  const [reportValue, setReportValue] = useState<any>({
    status: "",
    destination: "",
    startDate: DefinedCustomDates?.yesterday?.startDate,
    endDate: DefinedCustomDates?.yesterday?.endDate,
    sender_id: "",
    unit: "",
    direction: "",
    dates: "yesterday",
  });

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setLogsData(true);
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setReportValue({
      ...reportValue,
      [field]: "",
    });
    setLogsData(true);
  };

  const clearSearchCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    setLogsData(true);
  };
  useEffect(() => {
    setDisabled(false)
  }, [reportValue])
  const getReportLogsData = () => {
    dispatch(
      broadcastActions.getCommunicationReportsList({
        limit: localValues.itemsPerPage?.value,
        offset: localValues.currentPage,
        type: localValues?.broadcast_type,
        search: localValues?.search,
      }),
    ).then((res: any) => {
      setErrorApi(false);
    }).catch((error: any) => {
      setErrorApi(true);
      showAlert(
        error?.response?.statusText || "Unable to fetch data",
        "error",
      );
    });
  };

  const getValueForFilterLogs = () => {
    dispatch(
      broadcastActions.getAllCommunicationFilterSettings({ type: "sms" }),
    ).then((res: any) => { }).catch((error: any) => {
      showAlert(
        error?.response?.statusText || "Unable to fetch data",
        "error",
      );
    });
  };

  const restFilter = () => {
    setReportValue({
      status: "",
      destination: "",
      startDate: DefinedCustomDates?.yesterday?.startDate,
      endDate: DefinedCustomDates?.yesterday?.endDate,
      sender_id: "",
      unit: "",
      direction: "",
      dates: "yesterday",
    });
    setLogsData(true);
  };

  const applyFilter = () => {
    handleExportClick();
    setDisabled(true)
  };

  useEffect(() => {
    getValueForFilterLogs();
    getReportLogsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (logsData) {
      getReportLogsData();
      setLogsData(false);
    }
  }, [logsData]); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
    setLogsData(true);
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
    setLogsData(true);
  };
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setReportValue({
      ...reportValue,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };
  const handleSelectChange = (e?: any, field?: any) => {
    setReportValue({
      ...reportValue,
      [field]: e.target.value,
    });
  };
  const handleExportButton = (url: any) => {
    window.open(url, "_blank");
  };

  const handleExportClick = () => {
    let payload: any;
    payload = {
      start: format(reportValue.startDate, "yyyy-MM-dd"),
      end: format(reportValue.endDate, "yyyy-MM-dd"),
      status: reportValue?.status,
      search: reportValue?.search,
      type: "sms",
      destination: reportValue?.destination,
      sender_id: reportValue?.sender_id,
      unit: reportValue?.unit,
      direction: reportValue?.direction,
    };
    dispatch(marketingListActions.generateCommunicationLogsReport(payload))
      .then((res) => {
        getReportLogsData();
      }).catch((error) => {
        console.error("Error occurred while fetching the file URL.", error);
      });
  };

  const renderSelectIconStatus = reportValue.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconSenderId = reportValue.sender_id ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconUnit = reportValue.unit ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconDestination = reportValue.destination ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconDirection = reportValue.direction ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectIconDates = reportValue.dates ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  // Headers For Table
  const header = ["Date", "Latest Generated Report"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Date: "requested_on",
    "Latest Generated Report": "name",
  };
  // Width For Table Column
  const colWidth: any = {
    Date: "208",
    "Latest Generated Report": "500",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    return date;
  };

  return (
    <BroadcastMainWrapper style={{ padding: "0" }}>
      <BroadcastTopTitle
        style={{
          padding: "16px",
          backgroundColor: `${theme.palette.default.white}`,
          boxShadow: `${theme.palette.default.bottomShadow}`,
          marginBottom: "8px",
        }}
      >
        <Box className="topTitle-left">
          <Typography fontSize="16px" fontWeight="500" component="h6">
            Generate Reports
          </Typography>
        </Box>
        <Box className="topTitle-right"></Box>
      </BroadcastTopTitle>

      <BroadcastBodyWrapper style={{ padding: "0 1rem" }}>

        <Grid container >
          <Grid
            px={1}
            display={'flex-start'}
            alignItems={"center"}
            flexDirection={"column"}
            item
            md={2}
            xl={2}
          >
            <p>Status</p>
            <NewNativeSelect>
              <Select
                defaultValue={""}
                name="status"
                value={reportValue?.status}
                onChange={(e: any) => handleSelectChange(e, "status")}
                displayEmpty
                className="new-select-main"
                style={{
                  marginBottom: "0px",
                  height: "28px",
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "100%",
                  maxWidth: "auto",
                  minWidth: "100%",
                }}
              >
                <MenuItem value=""> All</MenuItem>
                {filtersValues?.status?.map((item: any, index: any) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <div
                className="clear-icon"
                onClick={(e: any) => clearCallback(e, "status")}
              >
                {renderSelectIconStatus}
              </div>
            </NewNativeSelect>
          </Grid>

          <Grid
            px={1}
            display={'flex-start'}
            alignItems={"center"}
            flexDirection={"column"}
            item
            md={2}
            xl={2}
          >
            <p>Sender Ids</p>
            <NewNativeSelect>
              <Select
                defaultValue={""}
                name="sender_id"
                value={reportValue?.sender_id}
                onChange={(e: any) => handleSelectChange(e, "sender_id")}
                displayEmpty
                className="new-select-main"
                style={{
                  marginBottom: "0px",
                  height: "28px",
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "100%",
                  maxWidth: "auto",
                  minWidth: "100%",
                }}
              >
                <MenuItem value=""> All</MenuItem>
                {filtersValues?.sender_ids?.map((item: any, index: any) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <div
                className="clear-icon"
                onClick={(e: any) => clearCallback(e, "sender_id")}
              >
                {renderSelectIconSenderId}
              </div>
            </NewNativeSelect>
          </Grid>
          <Grid
            px={1}
            display={'flex-start'}
            alignItems={"center"}
            flexDirection={"column"}
            item
            md={2}
            xl={2}
          >
            <p>Directions</p>
            <NewNativeSelect>
              <Select
                defaultValue={""}
                name="direction"
                value={reportValue?.direction}
                onChange={(e: any) => handleSelectChange(e, "direction")}
                displayEmpty
                className="new-select-main"
                style={{
                  marginBottom: "0px",
                  height: "28px",
                  fontSize: "14px",
                  fontWeight: 400,
                  width: "100%",
                  maxWidth: "auto",
                  minWidth: "100%",
                }}
              >
                <MenuItem value=""> All</MenuItem>
                {filtersValues?.directions?.map((item: any, index: any) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <div
                className="clear-icon"
                onClick={(e: any) => clearCallback(e, "direction")}
              >
                {renderSelectIconDirection}
              </div>
            </NewNativeSelect>
          </Grid>
          <Grid
            px={1}
            display={'flex-start'}
            alignItems={"center"}
            flexDirection={"column"}
            item
            md={2.5}
            xl={2}
          >
            <p>Dates</p>
            <NewNativeSelect className="datePicker">
              <AppRangeDatePicker
                dateRange={reportValue}
                setDateRange={handleChangeDate}
                divStyle={{
                  padding: "0px",
                  width: "inherit",
                  height: "28px"
                }}
                dateFieldStyle={{
                  height: "28px",
                  padding: "0 6px",
                  fontSize: "10px"
                }}
              />
            </NewNativeSelect>
          </Grid>
          <Grid
            px={1}
            display={'flex-start'}
            alignItems={"center"}
            flexDirection={"column"}
            item
            md={1}
            xl={2}
          >

          </Grid>
          <Grid
            px={1}
            display={'flex'}
            alignItems={"flex-end"}
            gap={1}
            justifyContent={"flex-end"}
            item
            md={2.5}
            xl={2}

          >
            <ProtectedAppButton
              moduleId="sms_export_report"
              variant="outline"
              style={{}}
              onClick={() => {
                restFilter();
              }}
            >
              Reset
            </ProtectedAppButton>
            <ProtectedAppButton
              moduleId="sms_export_report"
              variant="primarydark"
              onClick={() => {
                applyFilter();
              }}
              isDisabled={disabled}
            >
              Generate Report
            </ProtectedAppButton>
          </Grid>
        </Grid>

      </BroadcastBodyWrapper>
      <BroadcastMainWrapperTitle>
        <Box></Box>
        <Box sx={{ display: "flex", gap: "10px" }}></Box>
      </BroadcastMainWrapperTitle>

      <BroadcastBodyWrapper style={{ padding: "0 1rem" }}>
        <AppForm
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => { }}
          divStyle={{ width: "100%" }}
        >
          <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "16px" }}>
            <div className="filter-left">
              <AppFormSearchField
                name="search"
                placeholder="Search"
                defaultBorder={true}
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearSearchCallback(e, "search")}
                divStyle={{
                  marginBottom: "0rem",
                  width: "100%",
                  minWidth: "100%",
                }}
                disabled={logsData}
              />
            </div>
            <div className="filter-right"></div>
          </TableFilter>
        </AppForm>

        {
          loading ? (
            <AppSkeletonTableLoader
              numberOfCol={4}
              numberofRow={10}
              skeletonHeight={30}
              skeletonWidth={"100%"}
              skeletonHeaderHeight={40}
              skeletonHeaderWidth={"100%"}
              tableHeight={`calc(100vh - 356px)`}
            />
          ) : (
            <React.Fragment>
              <TableWrapper height={list?.length ? "calc(100vh - 317px)" : "calc(100vh - 283px)"}>
                <div>
                  <Box className="table-header">
                    <Box
                      className="table-row"
                      sx={{
                        "div:first-child": {
                          maxWidth: "220px",
                        },
                      }}
                    >
                      {header?.map((header: string, index: number) => {
                        return (
                          <Box
                            className={`col-head ${header
                              .toLowerCase()
                              .replaceAll(" ", "_")}`}
                            style={{
                              minWidth: colWidth[header] + "px",
                              width: colWidth[header] + "px",
                            }}
                          >
                            <Typography sx={{ fontWeight: 500 }}>
                              {header}
                            </Typography>
                          </Box>
                        );
                      })}
                      <Box
                        className="col-head"
                        sx={{
                          maxWidth: "170px",
                          minWidth: "170px",
                          height: "2.969rem",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography>Download Status</Typography>
                      </Box>
                    </Box>
                  </Box>

                  {
                    errorApi || list?.length === 0 ? (
                      <NoRecordsFound />
                    ) : (
                      <Box className="table-body">
                        {list?.map((row: any, rowIndex: any) => {
                          return (
                            <Box
                              key={rowIndex}
                              className="table-row"
                              sx={{
                                "div:first-child": {
                                  maxWidth: "220px",
                                },
                              }}
                            >
                              {header?.map((header: any, ColumnIndex: any) => {
                                const isActionColumn = header === "Action";
                                return (
                                  <Box
                                    className={`col-body ${header
                                      .toLowerCase()
                                      .replaceAll(" ", "_")}`}
                                    title={
                                      header === "Date"
                                        ? formatDateField(
                                          row[keyToHeaderMap[header]]
                                        )
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"
                                    }
                                    style={{
                                      minWidth: isActionColumn
                                        ? "400px"
                                        : colWidth[header] + "px",
                                      width: colWidth[header] + "px",
                                    }}
                                  >
                                    <Typography className="table-text">
                                      {header === "Date"
                                        ? formatDateField(
                                          row[keyToHeaderMap[header]]
                                        )
                                        : row[keyToHeaderMap[header]]
                                          ? row[keyToHeaderMap[header]]
                                          : "-"}
                                    </Typography>
                                  </Box>
                                );
                              })}

                              <Box
                                display="flex"
                                justifyContent={"flex-end"}
                                sx={{
                                  maxWidth: "170px",
                                  minWidth: "170px",
                                  alignItems: "right",
                                }}
                                className="col-body"
                              >
                                <Box className="" title="">
                                  <ProtectedAppUiElement moduleId="sms_export_report">
                                    {row?.is_ready ? (
                                      row?.attachment ? (
                                        <Link
                                          className="table-text"
                                          onClick={() =>
                                            handleExportButton(
                                              row?.attachment?.source
                                            )
                                          }
                                          underline="hover"
                                        >
                                          Download
                                        </Link>
                                      ) : (
                                        <Typography className="table-text">
                                          Failed
                                        </Typography>
                                      )
                                    ) : (
                                      <Typography className="table-text">
                                        Generating Report...
                                      </Typography>
                                    )}
                                  </ProtectedAppUiElement>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    )
                  }

                  {/* <Box className="table-body">
                {errorApi || list?.length !== 0 ? (
                  
                  <ErrorComponent>
                    <Box>
                      <img src={TemplateAPIERROR} width="300" alt="" />
                    </Box>
                    <Box className="err-content">
                      <Typography variant="h6" fontSize="20px">
                        No Records Found.
                      </Typography>
                    </Box>
                  </ErrorComponent>
                ) : (
                  <>
                    {list?.map((row: any, rowIndex: any) => {
                      return (
                        <Box
                          key={rowIndex}
                          className="table-row"
                          sx={{
                            "div:first-child": {
                              maxWidth: "220px",
                            },
                          }}
                        >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                  header === "Date"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]],
                                      )
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"
                                }
                                style={{
                                  minWidth: isActionColumn
                                    ? "400px"
                                    : colWidth[header] + "px",
                                  width: colWidth[header] + "px",
                                }}
                              >
                                <Typography className="table-text">
                                  {header === "Date"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]],
                                      )
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"}
                                </Typography>
                              </Box>
                            );
                          })}

                          <Box
                            display="flex"
                            justifyContent={"flex-end"}
                            sx={{
                              maxWidth: "170px",
                              minWidth: "170px",
                              alignItems: "right",
                            }}
                            className="col-body"
                          >
                            <Box className="" title="">
                              <ProtectedAppUiElement moduleId="sms_export_report">
                                {row?.is_ready ? (
                                  row?.attachment ? (
                                    <Link
                                      className="table-text"
                                      onClick={() =>
                                        handleExportButton(
                                          row?.attachment?.source,
                                        )
                                      }
                                      underline="hover"
                                    >
                                      Download
                                    </Link>
                                  ) : (
                                    <Typography className="table-text">
                                      Failed
                                    </Typography>
                                  )
                                ) : (
                                  <Typography className="table-text">
                                    Generating Report...
                                  </Typography>
                                )}
                              </ProtectedAppUiElement>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box> */}
                </div>
              </TableWrapper>
              <Box pr={"1rem"}>
                {list?.length ? (
                  <AppPaginationComponent
                    totalCount={pagination?.pagination?.totalRecords}
                    handlePageChange={handlePageChange}
                    currentPage={localValues?.currentPage}
                    totalPages={pagination?.pagination?.totalPages}
                    rowsPerPage={localValues?.itemsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                ) : null}
              </Box>
            </React.Fragment>
          )
        }


      </BroadcastBodyWrapper>



    </BroadcastMainWrapper>
  );
};

// Default Export of component
export default SmsLogsReport;
