import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
  debounce,
} from "@mui/material";
import Danger from "../../../../assets/images/danger-live.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import DefaultImage from "../../../../assets/images/icons/defaultTemplate.png";
import { ErrorComponent, TemplateListWrapper } from "./TemplatesListingCSS";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { DefinedCustomDates } from "../../../../Models/defaultSelectOptions";
import TemplateAPIERROR from "../../../../assets/images/No_records_found.svg";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { useAppDispatch } from "../../../../Store";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import { MainBroadCastTitleBar } from "../../Utils/CommomBroadcastStyle";
import { DeleteCannedResponse } from "../../WhatsApp/Templates/TemplatesListing";
import EmailTemplateListing from "./EmailTemplateListing";
import {
  ActionPopover,
  EmailHtmlPreview,
  EmailTemplateListActions,
} from "./EmailTemplateListingStyle";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
// interface Props extends LoaderComponentProps { }
interface Props { }

const EmailCardTemplatesListing: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [errorApi, setErrorApi] = useState(false);
  const [myTemplateEnable, setMyTemplateEnable] = useState(true);
  const [totalcount, setTotolCount] = useState(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [isShowingModal, toggleModal] = useState<boolean>(false);
  const [isPreviewModel, previewModal] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<any>();
  const [list, setList] = useState<any>([]);
  const has_low_balance = localStorage.getItem("has_low_balance")
  const [galleryList, setGellaeryList] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
    search: "",
    itemsPerPage: 10,
    currentPage: 0,
    category: "",
  });
  // const debouncedSearchTerm = debounce<string>(localValues?.search, 600);

  let { showAlert } = useNotification();

  const getMyTemplateListData = () => {
    if (myTemplateEnable) {
      const payload: any = {
        limit: localValues.itemsPerPage,
        offset: localValues.currentPage,
        search: localValues?.search,
        // category: localValues?.category,
      };
      dispatch(EmailActions.getEmailTemplatesListings(payload))
        .then((res: any) => {
          setList(res?.payload?.data?.results);
          setTotolCount(res?.payload?.data?.count);
          setGellaeryList([]);
        })
        .catch((error: any) => {
          setErrorApi(true);
          showAlert(
            error?.response?.statusText || "Unable to fetch data",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const payload: any = {
        search: localValues?.search,
        category: localValues?.category,
      };
      dispatch(EmailActions.getEmailgalleryTemplatesListings(payload))
        .then((res: any) => {
          setGellaeryList(res?.payload?.data?.results);
          setTotolCount(res?.payload?.data?.count);
        })
        .catch((error: any) => {
          setErrorApi(true);
          showAlert(
            error?.response?.statusText || "Unable to fetch data",
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // deleteEmailTemplateData

  const getgalleryTemplateListData = () => {
    const payload: any = {
      search: localValues?.search,
      category: localValues?.category,
    };

    dispatch(EmailActions.getEmailgalleryTemplatesListings(payload))
      .then((res: any) => {
        setGellaeryList(res?.payload?.data?.results);
        setTotolCount(res?.payload?.data?.count);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMyTemplateListData();
    // getgalleryTemplateListData();
  }, [localValues]);
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  };
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };
  const editBroadcastTemplate = (id: any) => {
    navigate(`/broadcast/email/view-template/${id}`);
  };
  const navigate = useNavigate();

  const handleMyTemplateList = () => {
    setMyTemplateEnable(true);
    setGellaeryList([]);
  };

  const handleGalleryTemplateList = () => {
    getgalleryTemplateListData();
    setMyTemplateEnable(false);
  };

  // PopOver ================
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (currentItem: any) => {
    setTemplateData(currentItem);
    previewModal(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleClickDelete = (id: any) => {
    const tempId = id;
    dispatch(EmailActions.deleteEmailTemplateData(tempId))
      .then((res: any) => {
        getMyTemplateListData();
        toggleModal(false);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createTemp = () => {
    localStorage.removeItem("HTMLTemplate");
    localStorage.removeItem("JSONTemplate");
    navigate("/broadcast/email/create-template");
  };
  return (
    <TemplateListWrapper>
      <AppModel
        isShowing={isShowingModal}
        onClose={toggleModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The Email Template</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal(false)}>
              Cancel
            </AppButton>
            <ProtectedAppButton
              moduleId="email_broadcast_crud"
              specificPermissionKey="delete"
              variant="danger-filled"
              onClick={() => handleClickDelete(selectedId)}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </ProtectedAppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>

      <AppModel
        isShowing={isPreviewModel}
        onClose={previewModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "750px",
          },
        }}
      >
        <EmailHtmlPreview>
          <div className="header">
            <div className="header-top" style={{ alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: "1.4rem",
                  fontWeight: "500",
                }}
              >
                HTML Preview
              </Typography>
              <span onClick={() => previewModal(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
            <div className="header-left">
              <iframe
                title="HTML Preview"
                srcDoc={
                  !templateData?.template_design
                    ? JSON.parse(templateData?.template_body || `""`) || {}
                    : templateData?.template_body
                }
                className="iframe"
                style={{
                  width: "calc(100% - 40px)",
                  height: "80vh",
                  border: "1px solid #ccc",
                  padding: "5px",
                  margin: "0 auto",
                  display: "block",
                }}
              />
            </div>
          </div>
        </EmailHtmlPreview>
      </AppModel>

      <MainBroadCastTitleBar>
        <div className="left">
          <p style={{ fontSize: "16px", fontWeight: 500 }}>
            Broadcast Template
          </p>
        </div>
        <div className="right"></div>
      </MainBroadCastTitleBar>
      {/* Filter Section Start */}

      {/* Edited */}
      <AppForm // requestSearch(values, submitProps);
        initialValues={{ search: "" }}
        onSubmit={(values: any, submitProps: any) => { }}
        // validationSchema={BotValidationSchema}
        divStyle={{ width: "100%" }}
      >
        {myTemplateEnable === true ? null : (
          <EmailListigFilter>
            <div className="left">
              <AppFormSearchField
                name="search"
                placeholder="Search"
                defaultBorder={true}
                divStyle={{
                  marginBottom: "0px",
                  width: "220px",
                }}
                // sendValuesToParent={(values: any) => sendValuesToParent(values)}
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearCallback(e, "search")}
              />
            </div>

            <Box display="flex" alignItems="center" sx={{ gap: "1rem" }}>
              <ProtectedAppButton
                moduleId="email_broadcast_crud"
                specificPermissionKey="create"
                onClick={() => navigate("/broadcast/email/create-broadcast/1")}
                isDisabled={has_low_balance === "true"}
                title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
              >
                Create Broadcast
              </ProtectedAppButton>
              <AppButton variant="outline" onClick={() => createTemp()}>
                Create Template
                <AppMaterialIcons iconName="Add" />
              </AppButton>
            </Box>
          </EmailListigFilter>
        )}
      </AppForm>
      {/* <Paper elevation={3} sx={{ height: "calc(100%)" }}> */}

      {myTemplateEnable === true ? (
        // Listing
        <EmailTemplateListing
          galleryList={galleryList}
          handleGalleryTemplateList={handleGalleryTemplateList}
        />
      ) : (
        <>
          {errorApi || list?.length === 0 || galleryList?.length === 0 ? (
            // <ErrorComponent
            //   style={{
            //     height: "calc(100vh - 214px)",
            //     margin: "0 1rem 0 1rem",
            //     overflow: "auto",
            //   }}
            // >
            //   <Box display="flex" justifyContent="center">
            //     <img width="400" src={TemplateAPIERROR} alt="" />
            //   </Box>
            //   <Box className="error-Info">
            //     <Typography variant="h6" fontSize="20px">
            //       No Record Found...
            //     </Typography>
            //     <Box className="err-action">
            //       <Typography variant="body1" fontSize="16px">
            //         Create New Template List
            //       </Typography>
            //       <Box>
            //         <ProtectedAppButton
            //           moduleId="email_template"
            //           specificPermissionKey="create"
            //           variant="primarydark"
            //           onClick={() =>
            //             navigate("/broadcast/email/create-template")
            //           }
            //         >
            //           Create Template
            //           <AppMaterialIcons iconName="Add" />
            //         </ProtectedAppButton >
            //       </Box >
            //     </Box >
            //   </Box >
            // </ErrorComponent >
            <Box p={2} height={"100%"}>
            <NoRecordsFound subText="Create New Template List">
              <ProtectedAppButton
                      moduleId="email_template"
                      specificPermissionKey="create"
                      variant="primarydark"
                      onClick={() =>
                        navigate("/broadcast/email/create-template")
                      }
                    >
                      Create Template
                      <AppMaterialIcons iconName="Add" />
                    </ProtectedAppButton >
            </NoRecordsFound>
          </Box>
          ) : (
            <TemplateListingWrapper
              style={{
                height: "calc(100vh - 214px)",
                overflow: "auto",
              }}
            >
              {galleryList?.map((currentItem: any) => {
                return (
                  // Grid
                  <EmailTemplateBox>
                    <Box className="imageWrapper">
                      <img
                        style={{ objectFit: "cover" }}
                        src={currentItem?.thumbnail || DefaultImage}
                        alt=""
                      />
                    </Box>
                    <Box className="cardActionWrapper">
                      <div className="card-content">
                        <h4>{currentItem?.name || "NA"}</h4>
                        <p>{currentItem?.category}</p>
                      </div>
                      <div className="actionButtonWrapper">
                        <AppButton
                          aria-describedby={id}
                          onClick={() => handleClick(currentItem)}
                          variant="grey"
                          className="action-btn"
                        >
                          Preview
                        </AppButton>
                        {/* <div
                          style={{
                            boxShadow:
                              "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
                          }}
                        >
                          <ActionPopover
                            id={id}
                            name="popup"
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                          >
                            <List style={{}}>
                              <ListItem disablePadding>
                                <ListItemButton>
                                  <EmailTemplateListActions>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: " 0.5rem",
                                      }}
                                    >
                                      <AppMaterialIcons iconName="RemoveRedEyeOutlined" />
                                      <p>Preview</p>
                                    </div>
                                  </EmailTemplateListActions>
                                </ListItemButton>
                              </ListItem>

                              <ListItem disablePadding>
                                <ListItemButton>
                                  <EmailTemplateListActions>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: " 0.5rem",
                                      }}
                                    >
                                      <AppMaterialIcons iconName="FileCopyOutlined" />
                                      <p>Copy</p>
                                    </div>
                                  </EmailTemplateListActions>
                                </ListItemButton>
                              </ListItem>
                              <ListItem disablePadding>
                                <ListItemButton>
                                  <EmailTemplateListActions>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: " 0.5rem",
                                      }}
                                    >
                                      <AppMaterialIcons iconName="EditOutlined" />
                                      <p>Change name</p>
                                    </div>
                                  </EmailTemplateListActions>
                                </ListItemButton>
                              </ListItem>
                              <ListItem disablePadding>
                                <ListItemButton>
                                  <EmailTemplateListActions>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: " 0.5rem",
                                      }}
                                    >
                                      <AppMaterialIcons iconName="BrokenImageOutlined" />
                                      <p>Change Thumbnail</p>
                                    </div>
                                  </EmailTemplateListActions>
                                </ListItemButton>
                              </ListItem>
                              <ListItem disablePadding>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedId(currentItem?.id);
                                    toggleModal(true);
                                  }}
                                >
                                  <EmailTemplateListActions>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: " 0.5rem",
                                      }}
                                    >
                                      <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                                      <p>Delete</p>
                                    </div>
                                  </EmailTemplateListActions>
                                </ListItemButton>
                              </ListItem>
                            </List>
                          </ActionPopover>
                        </div> */}
                      </div>
                    </Box>
                  </EmailTemplateBox>
                );
              })}
            </TemplateListingWrapper>
          )}
        </>
      )}

      {/* </Paper > */}
    </TemplateListWrapper >
  );
};

const EmailTemplateBox = styled.div`
  & {
    min-width: calc(20% - 16px);
    max-width: calc(20% - 16px);
    min-height: 389px;
    max-height: 389px;
    padding: 0.5rem;
    // margin-left: 16px;
    margin-bottom: 16px;
    border: 1px solid ${theme.palette.default.grey};
    .action-btn {
      & .MuiSvgIcon-root {
        font-size: 1.4rem;
      }
    }
    .cardActionWrapper {
      .card-content {
        margin-bottom: 0.65rem;
        h4 {
          color: ${theme.palette.default.black};
          font-weight: 500;
          word-break: break-all;
          font-size: 1rem;
        }
        p {
          font-size: 0.875rem;
          color: ${theme.palette.default.black};
        }
      }
    }
    .imageWrapper {
      img {
        width: 100%;
        max-height: 243px;
        min-height: 243px;
      }
    }
  }
`;

const TemplateListingWrapper = styled.div`
  & {
    border: 1px solid ${theme.palette.default.border};
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0rem 1rem;
    padding-top: 1rem;
    padding-left: 1rem;
    & > div {
      margin-right: 1rem;
    }

    & > div:nth-child(5n) {
      margin-right: 0;
    }
  }
`;

const EmailListigFilter = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    margin: 1rem;

    .left {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
`;
export default EmailCardTemplatesListing;
