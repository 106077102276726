import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SkeletonLoader from "../../../AppComponents/Loader/SkeletonLoader";
import { useAppSelector } from "../../../Store";
import { DashboardNetworkService } from "../../../Store/Slices/DashboardRedux/DashboardNetworkService";
import {
  BotList,
  botDateRange,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { ErrorComponent } from "../../Broadcast/SMS/SenderID/ListingStyles";
import { BroadcastDetailListWrapper } from "../../Broadcast/SMS/SenderID/SenderIDStyles";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "../AnalyticsSummaryStyles";
import TemplateAPIError from "../Dash_Assests/No_data_found.svg";
import { AcquitionTableWrapper } from "./IntentsStyles";

interface Props {}

const Intents: React.FC<Props> = (props) => {
  const [errorApi, setErrorApi] = useState(false);
  const dashdateRange: any = useAppSelector(botDateRange);
  const botList = useAppSelector(BotList);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<any>([]);

  const labelTable = () => {
    setLoading(true);
    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
      };
    }
    DashboardNetworkService.getBotIntent(payload)
      .then((res: any) => {
        setList(res?.data);
        setErrorApi(false);
      })
      .catch((err) => {
        setErrorApi(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      labelTable();
    }
  }, [dashdateRange, botList]); // eslint-disable-line react-hooks/exhaustive-deps

  // Headers For Table
  const header = ["Phrases", "Intent", "Occurence", "Usage%"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Phrases: "phrases",
    Intent: "intent",
    Occurence: "total_uses",
    "Usage%": "uses_percentage",
  };
  // Width For Table Column
  const colWidth: any = {
    Phrases: "400",
    Intent: "200",
    Occurence: "100",
    Usage: "100",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    return date;
  };
  return (
    <WrapperContainer>
      <p className="time_stats">
        <TextTitle>Intents</TextTitle>
        <AppToolTip
          divStyle={{ padding: "0 8px" }}
          tooltipProps={{
            title: "List of user intents captured by the bot.",
            placement: "top",
          }}
          iconButtonProps={{ disableRipple: true }}
          IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
        />
      </p>
      <BroadcastDetailListWrapper style={{ padding: "0px 16px 0px 16px" }}>
        <AcquitionTableWrapper>
          <div>
            <Box className="table-header">
              <Box className="table-row">
                {header?.map((header: string, index: number) => {
                  return (
                    <Box
                      className={`col-head ${header
                        .toLowerCase()
                        .replaceAll(" ", "_")}`}
                      style={{
                        minWidth: colWidth[header] + "px",
                      }}
                      key={index}
                    >
                      <Typography sx={{ fontWeight: 500 }}>{header}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className="table-body">
              {errorApi || list?.length === 0 ? (
                !loading ? (
                  <ErrorComponent style={{ height: "225px" }}>
                    <Box>
                      <img src={TemplateAPIError} width="120" alt="" />
                    </Box>
                    <Box className="err-content">
                      <Typography variant="h6" fontSize="16px">
                        No Records Found.
                      </Typography>
                    </Box>
                  </ErrorComponent>
                ) : (
                  <SkeletonLoader numberOfCol={4} numberofRow={4} />
                )
              ) : (
                <React.Fragment>
                  {list &&
                    list?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                  header === "Scheduled"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]]
                                      )
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"
                                }
                                style={{
                                  minWidth: isActionColumn
                                    ? "400px"
                                    : colWidth[header] + "px",
                                }}
                                key={ColumnIndex}
                              >
                                <Typography
                                  title={row[keyToHeaderMap[header]]}
                                  className="table-text"
                                  style={
                                    header === "Phrases"
                                      ? {
                                          whiteSpace: "nowrap",
                                          width: "350px",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }
                                      : header === "Occurence" ||
                                        header === "Usage%"
                                      ? { padding: "13px 12px" }
                                      : {}
                                  }
                                >
                                  {header === "Scheduled"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]]
                                      )
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                </React.Fragment>
              )}
            </Box>
          </div>
        </AcquitionTableWrapper>
      </BroadcastDetailListWrapper>
    </WrapperContainer>
  );
};

// Default Export of component
export default Intents;

export const WrapperContainer = styled.div`
  width: 100%;
`;
