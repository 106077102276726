// CustomValueEditor.tsx
import React from "react";
import { FieldSelectorProps } from "react-querybuilder";
import Select, {
  DropdownIndicatorProps,
  components,
  AriaOnFocus,
} from "react-select";
import { useAppDispatch } from "../../../Store";
import { getReactSelectCurrentValue } from "../utils/utils";
import { QueryBuilderActions } from "../../../Store/Slices/QueryBuilder/QueryBuilder.actions";
import { theme } from "../../../Customization/Theme";
import { Tooltip } from "@mui/material";

const DYNAMIC_OPTIONS_FIELD = [
  "radio",
  "select",
  "dropdown",
  "checkbox",
  "multiselect",
];

export const CustomQueryFieldSelect = (
  props: any | FieldSelectorProps | undefined,
) => {
  const dispatch = useAppDispatch();

  const [value, setValue] = React.useState<any>(null);

  React.useEffect(() => {
    setValue(
      getReactSelectCurrentValue(props?.options, props?.value, "name", true),
    );
  }, [props?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (props?.value && props?.options?.length > 0) {
      const selectedOption = props.options?.find(
        (opt: any) => opt?.name === props?.value,
      );

      if (
        selectedOption?.type &&
        DYNAMIC_OPTIONS_FIELD?.includes(selectedOption?.type)
      ) {
        const payload: any = {
          id: selectedOption?.id
        };

        if(selectedOption?.module_type) {
          payload.module_name = selectedOption?.module_type;
          dispatch(
            QueryBuilderActions.getQueryBuilderFieldDynamicOptionsAsPerModuleName(payload),
          );
        }else {
          dispatch(
            QueryBuilderActions.getQueryBuilderFieldDynamicOptions(payload),
          );
        }
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFieldSelectChange = (selectedValue: any) => {
    props?.handleOnChange(selectedValue?.name);
    props?.schema?.getOperators(selectedValue?.name);

    const selectedOption = props.options?.find(
      (opt: any) => opt?.name === selectedValue?.name,
    );
    if (
      selectedOption?.type &&
      DYNAMIC_OPTIONS_FIELD?.includes(selectedOption?.type)
    ) {
      const payload: any = {
        id: selectedOption?.id
      };
      
      if(selectedOption?.module_type) {
        payload.module_name = selectedOption?.module_type;
        dispatch(
          QueryBuilderActions.getQueryBuilderFieldDynamicOptionsAsPerModuleName(payload),
        );
      }
      else {
        dispatch(
          QueryBuilderActions.getQueryBuilderFieldDynamicOptions(payload),
        );
      }
    }
  };

  let customName: string = "CustomQueryFieldSelect." + props?.path?.[0] || "";

  return (
    <Select
      id={customName}
      options={props?.options}
      value={value}
      onChange={(e: any) => handleFieldSelectChange(e)}
      placeholder={"Select"}
      getOptionLabel={(option: any) =>
        option?.label || option?.name || option?.value
      }
      isOptionSelected={(option: any) => option?.name === props.value}
      isMulti={false}
      isSearchable={true}
      isClearable={false}
      blurInputOnSelect={false}
      isLoading={typeof value === "string" ? true : false}
      noOptionsMessage={() => "No Options "}
      styles={customStyles}
      components={{ DropdownIndicator }}
      menuPosition="fixed"
      className="react-select-container"
      classNamePrefix="react-select-query-builder-field"
    />
  );
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        height="20"
        width="20"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        className="css-tj5bde-Svg"
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "rgb(245, 246, 248) !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    // width: "130px !important",
    minWidth: "234px !important",
    maxWidth: "234px !important",
    boxShadow: "none !important",
    paddingBottom: "0px",
    borderColor: state?.isFocused ? "transparent" : "transparent",
    "&:hover": {
      borderColor: state?.isFocused ? "transparent" : "transparent",
    },
  }),

  menu: (base: any) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 2,
    // kill the gap
    marginTop: 4,
    // backgroundColor: "rgb(245, 246, 248) !important",
  }),

  menuList: (base: any) => ({
    ...base,
    // kill the white space on first and last option
    // backgroundColor: "white !important",
  }),
  option: (base: any, state: any) => ({
    ...base,
    // backgroundColor: "transaparent !important",
    color: theme.palette.default.darkGrey,
    fontSize: "14px",

    "&:hover": {
      // backgroundColor: "rgb(245, 246, 248) !important",y
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "rgba(0, 0, 0, 0.54) !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "16px !important",
    padding: "0px !important",
    marginRight: "8px !important",
    svg: {
      fill: "rgba(0, 0, 0, 0.54) !important",
    },
  }),

  ValueEditor: (base: any) => ({
    ...base,
    backgroundColor: "red !important",
    fontSize: "23px",
  }),
  ".react-select__single-value": {
    backgroundColor: "red !important",
    fontSize: "19px",
  },
};
