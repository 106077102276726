import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import {
  Card,
  Grid,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  tableCellClasses,
} from "@mui/material";
import Subscription from "../../assets/images/ContactUs.svg";
import AppButton from "../../AppComponents/AppButton";
import { StyledDialog } from "../../Customization/CommonElements";

export const LivePlan = styled.div`
  border-radius: 50px;
  border: 5px solid #fff;
  background: #27b783;
  padding: 12px 18px;
  color: #ffff;
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 16px;
  margin: -3%;
`;
export const LeftDay = styled.p`
  color: red;
  margin: 2%;
`;

export const CurrentPlan = styled.p`
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
`;

export const PlanDetails = styled.p`
  color: #7e8392;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  margin: 2%;
`;
export const SubscriptionDetails = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin: 4%;
`;

export const SubscriptionInfo = styled.p<{ prpos: any }>`
  color: ${(props: any) => (props.color ? props.color : "#7E8392")};
  text-align: ${(props: any) => (props.align ? props.align : "center")};
  leading-trim: both;
  text-edge: cap;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: ${(props: any) => (props.width ? props.width : "")};
  margin: 2% 3% 7%;
  //color : "#ffff"
`;

export const LivePlanContainer = styled.div<{ props: any }>`
  display: flex;
  justify-content: ${(props: any) => (props.align ? props.align : "center")};
  gap: ${(props: any) => (props.gap ? props.gap : "")};
`;
export const ActivePlan = styled.div<{ prpos: any }>`
  width: ${(props: any) => (props.width ? props.width : "auto")};
  height: ${(props: any) => (props.height ? props.height : "auto")};
  margin: 0% 9%;
  flex-shrink: 0;
  border-radius: 15px;
  border: 2px solid #5b73e8;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(91, 115, 232, 0.3);
  color: ${(props: any) => (props.color ? props.color : "")};
`;
export const SubscriptionPlan = styled.div<{ prpos: any }>`
  /* margin : 5% 1% 1% 1%; */
  height: ${(props: any) => (props.height ? props.height : "fit-content")};
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  /* padding : ${(props: any) => (props.padding ? props.padding : "60px")};  */
  width: ${(props: any) => (props.width ? props.width : "")};
  overflow: scroll;
  max-height: -webkit-fill-available;
  padding: 2rem !important;
`;
export const ContentTitle = styled.div`
  width: inherit;
  height: 65px;
  padding: 15px 24px !important;
  flex-direction: column;
  justify-content: center;
  /* position: absolute; */
  gap: 10px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  font-family: Inter;
  font-size: 20px;
  text-transform: capitalize;
`;
export const SidebarLayoutWrap = styled.div`
  && {
    display: flex;
  }
`;
export const SidebarWrapBox = styled.div<{}>`
  flex-shrink: 0;
  background: #fff;
  box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.08);
`;
export const ContentMainBox = styled.div`
  && {
    width: 100%;
    height: calc(100vh - 5rem);
    /* height: calc(100vh - 65px); */
    background-color: ${theme.palette.secondary.main};
    overflow: hidden;
  }
`;

export const WelcomeMessage = styled.div`
  color: #5b73e8;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  font-family: Inter;
`;

export const WelcomeInfo = styled.div`
  color: #000;
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  font-family: Inter;
  margin: 1% 0%;
`;

export const SubscriptionToggleButton = styled(ToggleButtonGroup)`
  border-radius: 38px !important;
  border: 1px solid #27b783;
  background: #fff;
  width: 222px;
  height: 42px;
`;

export const SubscriptionPlanToggleButton = styled(ToggleButton)<{
  prpos: any;
}>`
  border-radius: 38px !important;
  border: ${(props: any) => (!props.status ? "none !important" : "")};
  background: ${(props: any) =>
    props.status ? "#27B783 !important" : "white !important"};
  color: ${(props: any) =>
    props.status ? "#ffff !important" : "#27B783 !important"};
  width: 105px;
  height: 36px;
  flex-shrink: 0;
  margin: 2px !important;
`;

export const PlanCardDetails = styled.div`
  border-radius: 20px !important;
  // background: linear-gradient(180deg, #5B73E8 0%, #5B73E8 0.01%, #183ADC 100%);
  box-shadow: rgba(91, 115, 232, 0.3) 1px 14px 29px 0px !important;
  width: 321px;
  height: auto;
  flex-shrink: 0;
  display: table-header-group;
  color: #ffff !important;
  // clip-path: ellipse(457px 227px at 44% 1%);
`;
export const Amount = styled.div`
  font-size: 30px;
  color: #101010;
  font-family: Inter;
  text-transform: capitalize;
  margin: 40px 0px 36px;
`;
export const PlanCards = styled(Grid)<{ props: any }>`
  padding: ${(props: any) => (props.padding ? props.padding : "0rem 9rem")};
  text-align: -webkit-center;
`;
export const ContactUsDetails = styled.div`
  background: url(${Subscription});
  width: 1476px;
  height: 401px;
  margin: 4% 0% 0%;
  text-align: center;
  color: #ffff !important;
  display: grid;
  padding: 3rem 16rem;
`;
export const Title = styled.div<{ props: number }>`
  font-size: ${(props: any) => (props.fontSize ? props.fontSize + "px" : "")};
  color: ${(props: any) => (props.color ? props.color : "")};
`;

export const AmountCard = styled.div`
  width: min-content;
  height: auto;
  border: 1px solid rgba(126, 131, 146, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(91, 115, 232, 0.15);
  margin: 0px 0px 20px 0px;
`;

export const Button = styled(AppButton)<{ props: any }>`
  min-width: ${(props: any) =>
    props.minWidth ? props.minWidth + "!important" : "170px !important"};
  font-size: 16px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  background-color: ${(props: any) =>
    props.backgroundColor ? props.backgroundColor + "!important" : ""};
  margin: ${(props: any) => (props.margin ? props.margin + "!important" : "")};
  border-radius: ${(props: any) =>
    props.radius ? props.radius + "!important" : ""};
`;
export const BillingDetailsCard = styled.div<{ props: any }>`
  width: -webkit-fill-available;
  height: ${(props: any) => (props.height ? props.height : "134px")};
  border-radius: 8px;
  border: 1px solid rgba(126, 131, 146, 0.15);
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(91, 115, 232, 0.15);
  margin: 1rem 0rem;
`;

export const TotalCost = styled.div`
  margin: 2rem 1.5rem 4rem;
  font-size: 18px;
  color: #101010;
  font-weight: 500;
`;
export const DetailsTable = styled.div`
  width: -webkit-fill-available;
  height: inherit;
  border-radius: 4px;
  border: 0px solid #6b6b6b;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 15px;
  margin: 0 15px;
  .flex-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .search-input {
    min-height: 34px;
  }
  i.ri-search-2-line {
    bottom: 10px;
  }
`;

export const CreditBalanceDetails = styled.div`
  width: -webkit-fill-available;
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px 0px;
  margin: 1rem 1rem 1rem;
  padding: 12px;
  text-align-last: left;
  border: 1px solid ${theme.palette.default.border};
`;

export const CreditDetails = styled.div`
  width: -webkit-fill-available;
`;

export const DetailsCard = styled.div<{ prpos: any }>`
  border-right: 1px solid rgba(126, 131, 146, 0.4);
  width: 170px;
  max-height: ${(props: any) => (props.height ? props.height : "")};
  margin: 1rem 2rem;
`;
export const TrialTitle = styled.div`
  margin: -1.5rem 0rem;
  font-size: 16px;
  font-style: italic;
  color: #f34e4e;
`;

export const ManageSubscriptionDetails = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px;
    margin: 40px 20px;
    border-top: 1px solid rgba(126, 131, 146, 0.3);
    .left {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      gap: 0.5rem;
      p {
        font-size: 18px;
        font-weight: 500;
      }
      .MuiSvgIcon-root {
        font-size: 1rem;
        fill: ${theme.palette.default.black};
      }
    }
    .right {
      display: flex;
      gap: 0.5rem;
      font-size: 16px;
      align-items: center;
    }
  }
`;

export const SubscriptionPlanDetails = styled.div<{ prpos: any }>`
  & {
    margin: 7% 1% 0%;
    height: fit-content;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 21px;
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-evenly;
    gap: 2rem;
    .totalCost {
      width: 178px;
      .planNameHeader {
        font-size: 14px;
        margin: 11px 1px;
      }
    }
    .planName {
      width: 250px;
      color: #101010;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      .planNameHeader {
        font-size: 14px;
        margin: 11px 1px;
      }
      .subscriptionName {
        font-size: 16px;
        margin: 11px 1px;
      }
    }
  }
`;
export const SubscriptionPlanAddonDetails = styled.div`
  margin: 3% 1% 0%;
  height: fit-content;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
  padding: 21px;
  width: -webkit-fill-available;
`;
export const SubscriptionSummaryCardDetails = styled.div`
  & {
    width: 431px;
    height: 812px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid #5b73e8;
    font-family: Inter;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin: 5rem 1rem;
    .summaryTitle {
      background-color: #5b73e8;
      padding: 1rem;
      color: white;
      font-size: 16px;
      font-family: Inter;
      text-align: center;
    }
    .infoCard {
      width: 383px;
      height: 104px;
      box-shadow: 0px 0px 10px 0px rgba(91, 115, 232, 0.15);
      margin: 11px 22px 20px;
      padding: 31px 10px 4px;
      border-radius: 6px;
      border: 1px solid rgba(91, 115, 232, 0.5);
      background: #fff;
      .borderRight {
        border-right: 1px solid rgba(126, 131, 146, 0.4);
        text-align: center;
        height: 57px;
      }
    }
  }
`;
export const TitleBar = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px;
    margin: 40px 20px;
    border-top: 1px solid rgba(126, 131, 146, 0.3);
    .left {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      gap: 0.5rem;
      p {
        font-size: 18px;
        font-weight: 500;
      }
      .MuiSvgIcon-root {
        font-size: 1rem;
        fill: ${theme.palette.default.black};
      }
    }
    .right {
      display: flex;
      gap: 0.5rem;
      font-size: 16px;
      align-items: center;
    }
  }
`;

export const DocumentAndTextTableWrapper = styled.div`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    .table-header {
      .table-row {
        display: flex;
        height: 64px;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
      }

      .table-row > div:first-child {
        max-width: 80px;
      }

      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 120px;
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
      }
    }

    // Body
    .table-body {
      overflow: auto;
      .table-row {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      .table-row > div:first-child {
        max-width: 80px;
      }
    }
    .table-row > div:nth-child(8) {
      border-right: none;
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 120px;
    }
    .col-body {
      flex: 1;
      display: flex;
      word-break: break-all;
      align-items: center;
      padding: 8px 16px;
      font-weight: 500;
      color: ${theme.palette.default.darkGrey};
      .status {
        display: flex;
        gap: 8px;
        color: #25926a;
        padding: 4px 16px;
        align-items: center;
        background-color: #e5fff6;
        border: 1px solid #12c584;
        border-radius: 4px;
        justify-content: center;
      }
      .status-uploaded {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }
      .in-progress {
        display: flex;
        gap: 8px;
        color: #918548;
        padding: 4px 16px;
        align-items: center;
        background-color: #fffbe5;
        border: 1px solid #ffe55e;
        border-radius: 4px;
        justify-content: center;
      }
    }
  }
`;
export const Scroll = styled.div`
  & {
    overflow: auto;
  }
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  margin: 5px 6px 5px 8px;
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    // width: 100%;
    max-width: 210px;
    min-width: 180px;
    width: 210px;
    height: 40px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;

    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }
  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    // width: 30px;
    justify-content: center;
  }
`;
export const StyledWalletDialog = styled(StyledDialog)`
  .title {
    width: 720px;
  }
`;

export const WebsiteWrap = styled.div`
  .website-tabs .MuiTab-root {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: ${theme.palette.default.darkGrey};
    margin-left: 1rem;
    text-transform: capitalize;
  }

  .website-tabs .MuiTab-root.Mui-selected,
  .website-tabs .MuiTab-root.Mui-selected:hover {
    color: ${theme.palette.default.blue};
  }
  .website-tabs .MuiTab-root.MuiTabs-scroller {
    margin-bottom: 20px !important;
  }
`;

export const StyledTableCell = styled(TableCell)(({ theme }: any) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ffff",
    color: "rgba(0, 0, 0, 0.87)",
    textTransform: "capitalize",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }: any) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f4f4f2",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
