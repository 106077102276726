import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { device } from "../../Customization/BreakPoints";

export const SegmentWrap = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  height: 100%;

  .topmenu {
    padding: 18px 22px;
    background: ${theme.palette.default.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.black};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 61px;
  }
  .menu-left {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-weight: 500;
    color: ${theme.palette.default.black};
    font-size: 20px;
  }

  .custom-btn {
    padding: 0;
  }
  .left-menu {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .left-part {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .menu-right {
    display: flex;
    align-items: center;
  }
  .edit-conditions {
    border: 1px solid ${theme.palette.default.BorderColorGrey};
  }
  .edit_text {
    font-size: 14px;
  }
  .AllFilterLogo {
    padding-right: 5px;
  }
  .data-container {
    background: ${theme.palette.default.white};
    margin: 1rem;
  }
  .image {
    padding-top: 24px;
    padding-left: 3rem;
  }
  .norecord {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    /* padding-top: 128px; */
  }
  .data-details {
    display: flex;
    /* align-items:center; */
    justify-content: center;
    padding-top: 120px;
    gap: 72px;
  }
  .data-details p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: ${theme.palette.default.darkGrey};
  }
  .Import-details {
    width: 640px;
  }
  .Import-details button {
    width: 148px;
  }
  .Import-details li {
    margin-bottom: 1rem;
  }
  .Import-details p {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: ${theme.palette.default.black};
  }
  .button {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: rgb(54, 84, 227);
    color: white !important;
  }
  .button button {
    /* padding: 0; */
  }
  .norecord-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .norecord-data p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${theme.palette.default.darkGrey};
    text-transform: capitalize;
  }
  .add-list {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 1rem;
    gap: 2rem;
  }
  .add-list button {
    width: 148px;
  }
  .add-list p {
    width: 1115px;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: ${theme.palette.default.black};
  }
  .parent_Drawer {
    width: 700px;
  }
`;

export const AnsweredTable = styled.div``;

export const AnsweredFaqList = styled.div`
  .table-body {
    height: calc(100vh - 550px);
    overflow: auto;
  }
  .table-body .MuiCheckbox-root {
    padding: 0;
  }

  .table-header {
    display: flex;
    align-items: baseline;
    font-weight: bold;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    padding-bottom: 4px;
    padding-top: 4px;
    flex: 1;
  }
  .table-header .MuiCheckbox-root {
    padding: 0;
  }
  .table-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
  }
  .table-body a {
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: ${theme.palette.default.newTextGrey};
  }
  .avtar {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .fallback_ui {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 100px;
    img {
      transform: scale(1.3);
    }
  }
  .button {
    margin-top: 50px;
  }
  .import {
    display: flex;
    align-items: center;
    gap: 2rem;
    cursor: pointer;
  }
  .disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .col-head,
  .col-body {
    padding: 16px 16px;
    color: ${theme.palette.default.newTextGrey};
    font-size: 14px;
    font-weight: 400;

    :nth-child(1) {
      flex-basis: 250px;
    }

    :nth-child(2) {
      flex-basis: 244px;
    }
    :nth-child(5) {
      flex-basis: 232px;
    }

    :nth-child(n + 3) {
      flex: 1;
    }
    /* :nth-child(7) {
        display: flex;
        align-items: baseline;
        justify-content: end;
      } */
  }

  .col-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    border-right: 1px solid ${theme.palette.default.BorderColorGrey};
    padding: 1rem;

    :nth-child(2) {
      /* Apply styles for the body first child */
      flex-basis: 244px;
    }
    :nth-child(5) {
      flex-basis: 232px;
    }

    /* :nth-child(3) {
        flex-basis: 154px
      } */
    :nth-child(1) {
      flex-basis: 250px;
    }

    :nth-child(9) {
      border-right: none;
    }
    :nth-child(n + 3) {
      flex: 1; /* Allow columns from the third child to grow equally */
    }
    :last-child {
      border-right: none;
    }
  }
`;

export const CreateListWrap = styled.div`
  .popup-container {
    overflow: auto;
  }

  .popup-container h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    /* padding-top: 2rem; */
  }
  .answered-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
    align-items: center;
    padding: 16px;
  }
  .answered-heading p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    color: ${theme.palette.default.NightRider};
  }
  .subscription label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.BlackOpacity};
  }
  .radio-btn-heading {
    padding-top: 1rem;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.newTextBlack};
  }
  .radiobutton-group {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-bottom: 2rem;
    padding-right: 24px;
    padding-top: 1rem;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
  .select-field {
    display: flex;
    padding-left: 24px;
    align-items: center;
    gap: 1rem;
    padding-right: 24px;
    padding-top: 1rem;
  }
  .select label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .label-styling {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.BlackOpacity};
  }
  .paragraph {
    padding-left: 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${theme.palette.default.newTextGrey};
  }
  .checkbox-div {
    padding-top: 1rem;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
  .radio-list {
    padding-left: 24px;
    border-bottom: 1px solid ${theme.palette.default.BlackOpacityBorder};
    padding-bottom: 2rem;
    padding-right: 24px;
  }
  .radio-list p {
    padding-left: 2rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${theme.palette.default.newTextGrey};
  }
  .step {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: ${theme.palette.default.newTextGrey};
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .input-div {
    padding: 1rem;
  }
  .input-div label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
  .listitem {
    padding-left: 3rem;
  }
  .listitem li {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${theme.palette.default.newTextGrey};
    margin-bottom: 1rem;
  }
  .btn {
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 16px;
    border-top: 1px solid ${theme.palette.default.BlackOpacityBorder};
  }
  .btn button {
    padding: 0;
  }
  .footer-warning {
    width: -webkit-fill-available;
    height: 24px;
    font-size: small;
    display: flex;
    align-items: end;
    gap: 10px;
  }
`;

export const SettingPageMainBoxWrapp = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding: 0.8rem 0rem 0;
    display: block;
    gap: 0.5rem;
    overflow: hidden;
  }
`;

export const SettingPageMainBoxTopbarr = styled.div`
  && {
    padding-top: 18px;
    overflow: visible;

    display: flex;
    align-items: center;
    box-shadow: ${theme.palette.default.darkGrey};
    background-color: white;
    text-align: center;
    margin-top: -10px;
    padding-bottom: 15px;

    .setting-page-mainboxtopbar__left {
      .sms-list {
        font-size: 1rem;
        display: flex;
        align-items: center;
        font-weight: 500;
        color: ${theme.palette.default.black};
      }
    }

    width: 100%;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem 0rem 1rem;
    overflow: hidden;
    .btnDiv {
      width: 15%;
    }
    .setting-page-mainboxtopbar__left {
    }
    .setting-page-mainboxtopbar__right {
      background-color: ${theme.palette.default.lightGrey};
      z-index: 2;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
`;

export const SettingPageMainBoxTitlee = styled.div`
  & {
    font-size: 13px;
    h4 {
      color: ${theme.palette.default.black};
      font-size: 1.125rem;
      font-weight: 500;
    }
    p {
      color: ${theme.palette.default.text};
      font-size: 0.875rem;
      font-weight: 400;
      margin: 0;
    }
    .boldText {
      font-size: 13px;
    }
    .checktext {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      p {
        margin: 0;
      }
    }
    .whatsapp-analytics {
      gap: 2.5rem;
      font-weight: 500;
    }

    @media ${device.laptopM} {
      .whatsapp-analytics {
        font-size: 13px;
      }
    }
    @media ${device.laptop2} {
      .whatsapp-analytics {
        font-size: 12px !important;
      }
    }
    svg {
      font-size: 13px;
      color: ${theme.palette.default.darkGrey};
      cursor: pointer;
    }
  }
`;

export const SettingPageMainBoxBtnBoxx = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    min-width: 55%;
    gap: 2rem;
    padding: 0 0.5rem;
    @media ${device.laptopL} {
      width: 68%;
    }
    @media ${device.laptopM} {
      width: 78%;
    }
  }
`;
