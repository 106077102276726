import styled from "styled-components";
import { Chip } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface AppInputFieldProps {
  label?: string;
  caption?: string;
  name: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormMultipleValues: React.FC<AppInputFieldProps> = ({
  label,
  caption,
  name,
  children,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    getFieldMeta,
    initialValues,
    setFieldValue,
    values,
    setFieldError,
  } = useFormikContext<any>();

  const [items, setItems] = useState<any>([]);
  const [tempValue, setTempvalue] = useState<any>("");

  const handleKeyDown = (evt: any) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      if (tempValue && isValid(tempValue)) {
        if (items.length < 5) {
          setItems([...items, tempValue]);
          setFieldValue(name, [...items, tempValue]);
          setTempvalue("");
        } else {
          setFieldError(name, `Max 5 emails allowed`);
        }
      }
    }
  };

  const handleValueChange = (evt: any) => {
    // setItems(evt.target.value);
    setTempvalue(evt.target.value);
  };

  useEffect(() => {

    if (values[name] && values[name].length > 0 && values[name][0].length > 0) {
      setItems(values[name]);
    }
    else {
      setItems([]);
    }
  }, [values]);

  const handleDelete = (item: any) => {
    setItems(items.filter((i: any) => i !== item));
  };

  // const handlePaste = (evt: any) => {
  //   evt.preventDefault();

  //   var paste = evt.clipboardData.getData("text");
  //   var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);

  //   if (emails) {
  //     var toBeAdded = emails.filter((email: any) => !isInList(email));

  //     setItems([...items, ...toBeAdded]);
  //   }
  // };

  const isValid = (email: any) => {
    let error = null;

    if (isInList(email)) {
      setFieldError(name, `${email} has already been added.`);
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
      setFieldError(name, `${email} is not a valid email address.`);
    }

    if (error) {
      setFieldError(name, error);
      return false;
    }

    return true;
  };

  const isInList = (email: any) => {
    return items.includes(email);
  };

  const isEmail = (email: any) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  return (
    <Div>
      <Label>{label}</Label>
      <Caption>{caption}</Caption>
      <Input
        {...otherProps}
        onBlur={() => setFieldTouched(name)}
        // onChange={handleChange(name)}
        // onChange={(e:any) => setFieldValue(name, e.target.value)}
        value={tempValue}
        onKeyDown={(e: any) => handleKeyDown(e)}
        onChange={(e: any) => handleValueChange(e)}
        // onPaste={(e: any) => handlePaste(e)}
        style={{
          border:
            errors[name] && touched[name]
              ? `2px solid ${theme.palette.default.error}`
              : `none`,
        }}
      />
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
      <div
        style={{
          display: "flex",
          gap: "2rem",
          flexWrap: "wrap",
          marginTop: "1rem",
        }}
      >
        {items &&
          items.length > 0 &&
          items.map((item: any, index: any) => (
            <Chip
              key={index}
              label={item}
              variant="outlined"
              onClick={() => { }}
              onDelete={() => handleDelete(item)}
            // style={{ width: "40%" }}
            />
          ))}
      </div>
    </Div>
  );
};

export default AppFormMultipleValues;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 1rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
  }
`;
const Caption = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    margin-bottom: 1rem;
  }
`;
const Input = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    /* outline: 1px solid ${theme.palette.default.border} !important; */
  }
`;
