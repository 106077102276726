import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FaqUserQueryAction } from "./userquery.actions";

interface initialStateTypes {
  data: {
    answeredListing: any;
    answeredListingCount: any;
    answeredFlowOptionData: any;
    answeredFaqQuestionOptionData: any;
    newInput: any;
    answeredSubmitData: any;
    deletedFaqs: any;
    totalPages: number;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    answeredListing: null,
    answeredListingCount: null,
    answeredFlowOptionData: null,
    answeredFaqQuestionOptionData: null,
    newInput: {},
    answeredSubmitData: null,
    deletedFaqs: null,
    totalPages: 0,
  },
  error: "",
  loading: false,
};

const FaqUserQuerySliceReducer = createSlice({
  name: "FaqUserQuerySliceReducer",
  initialState,
  reducers: {
    updateUserInput(state, { payload }: PayloadAction<any>) {
      const newUserInput = payload;
      state.data.newInput = newUserInput;
    },
    setAnsweredSubmitData(state, { payload }: PayloadAction<any>) {
      state.data.answeredSubmitData = payload;
    },
    resetAnsweredListing(state, { payload }: PayloadAction<any>) {
      state.data.answeredListing = [];
    },
  },
  extraReducers: (builder) => {
    builder

      // answered Listing
      .addCase(
        FaqUserQueryAction.GeteAnsweredListing.pending,
        (state, action) => {
          state.data.answeredListing = [];
          state.loading = true;
        }
      )
      .addCase(
        FaqUserQueryAction.GeteAnsweredListing.fulfilled,
        (state, action) => {
          state.data.answeredListing = action?.payload?.data?.data || [];
          state.data.answeredListingCount = action?.payload?.data?.count || 0;
          state.data.totalPages =
            action?.payload?.data?.pagination?.totalPages || 0;
          state.loading = false;
        }
      )
      .addCase(
        FaqUserQueryAction.GeteAnsweredListing.rejected,
        (state, action) => {
          state.loading = false;
          state.data.answeredListing = [];
        }
      )

      // answered  flow option data
      .addCase(
        FaqUserQueryAction.GeteAnsweredFlowOptions.pending,
        (state, action) => {
          state.data.answeredFlowOptionData = [];
          state.loading = true;
        }
      )
      .addCase(
        FaqUserQueryAction.GeteAnsweredFlowOptions.fulfilled,
        (state, action) => {
          state.data.answeredFlowOptionData = action?.payload?.data?.data || [];
          state.loading = false;
        }
      )
      .addCase(
        FaqUserQueryAction.GeteAnsweredFlowOptions.rejected,
        (state, action) => {
          state.loading = false;
          state.data.answeredFlowOptionData = [];
        }
      )

      // answered  flow option data
      .addCase(
        FaqUserQueryAction.GeteAnsweredFaqQuestionOption.pending,
        (state, action) => {
          state.data.answeredFaqQuestionOptionData = [];
          state.loading = true;
        }
      )
      .addCase(
        FaqUserQueryAction.GeteAnsweredFaqQuestionOption.fulfilled,
        (state, action) => {
          state.data.answeredFaqQuestionOptionData =
            action?.payload?.data?.data?.data || [];
          state.loading = false;
        }
      )
      .addCase(
        FaqUserQueryAction.GeteAnsweredFaqQuestionOption.rejected,
        (state, action) => {
          state.loading = false;
          state.data.answeredFaqQuestionOptionData = [];
        }
      )

      // answered submit  data
      .addCase(
        FaqUserQueryAction.SubmitAnsweredData.pending,
        (state, action) => {
          state.loading = true;
        }
      )
      .addCase(
        FaqUserQueryAction.SubmitAnsweredData.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(
        FaqUserQueryAction.SubmitAnsweredData.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      //* Delete Source
      .addCase(FaqUserQueryAction.DeleteFaqsByIds.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        FaqUserQueryAction.DeleteFaqsByIds.fulfilled,
        (state, action) => {
          state.data.deletedFaqs = action?.payload?.data?.status;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(FaqUserQueryAction.DeleteFaqsByIds.rejected, (state, action) => {
        state.loading = false;
        state.data.deletedFaqs = null;
        state.error = action?.error?.message || "";
      });
  },
});
export const { setAnsweredSubmitData, updateUserInput, resetAnsweredListing } =
  FaqUserQuerySliceReducer.actions;

export default FaqUserQuerySliceReducer.reducer;
