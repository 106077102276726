// vendors
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

// components
import Icon from "../Icon";

// styled
import { ListItemStyled, ListItemButtonStyled } from "./drawer.styled";

// utils
import { getText } from "../../Utils";

interface Props {
  flowOrder: number;
  childItems: any;
}

const ChildList = (props: Props) => {
  const { childItems, flowOrder } = props;

  const handleItemClick = (nodeId: string) => {
    const element = document.getElementById(`node-${nodeId}`);
    if(element) {
      element.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  };

  return (
    <List component="div" disablePadding sx={{ pl: 0 }}>
      {childItems.map(
        (
          {
            id,
            text,
            name,
            isValid,
            response,
            nodeId,
            ...rest
          }: {
            id: any;
            text: any;
            isValid: boolean;
            response: any;
            name: string;
            nodeId: string;
          },
          nodeIndex: number
        ) => {
          let messageText = getText(response)?.replace(/<[^>]+>/g, "");
          messageText = messageText.replace(/&nbsp;/g, '');
          return (
            response && (
              <ListItemStyled disableVerticlePadding key={`nodes-${flowOrder}.${nodeIndex + 1}`}>
                <ListItemButtonStyled onClick={() => handleItemClick(nodeId)}>
                  <Typography sx={{ mr: "8px" }}>{`${flowOrder}.${
                    nodeIndex + 1
                  }`}</Typography>
                  <Typography title={messageText}>
                    {messageText.length > 40
                      ? `${messageText.slice(0, 30)}...`
                      : messageText}
                  </Typography>
                  <Box sx={{ ml: "auto" }} component="span">
                    <Icon icon="message" size={13} color="default.darkGrey" />
                  </Box>
                </ListItemButtonStyled>
              </ListItemStyled>
            )
          );
        }
      )}
    </List>
  );
};
export default ChildList;
