import { Avatar, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  allMessagesStore,
  removeAllMessages,
  setIsSearchBoxVisible,
  setUserInfoData,
} from "../../../../Store/Slices/socket/AllMessagesSlice";
import {
  MainChatTopHeaderWrapper,
  UserAvatar,
} from "./MainChatTopHeaderStyles";

import Select from "react-select";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatNetworkService } from "../../../../Store/Slices/socket/liveChat/ChatNetworkService";
import { LiveChatData } from "../../../../Store/Slices/socket/liveChat/chat.selectors";
import {
  getDefaultAvatar,
  makeStringFirstLetterUppercase,
} from "../../../../Utils";

import { ProtectedAppUiElement } from "../../../../AppComponents/ProtectedComponents";
import useRolesPermissionsAccess from "../../../../Hooks/useRolesPermissionsAccess";
import { useCurrentUser } from "../../../../Store/Slices/CurrentUser/CurrentUserSlice";
import {
  removeSessionData,
  updateSessionData,
} from "../../../../Store/Slices/socket/liveChat/chat.slice";
import { AppMaterialIcons } from "../../../appComponents/Icons";
import { useSocket } from "../../../hooks/socket-context";
import { SOCKET_CONNECTION_TYPES } from "../../../utils/Enum";
import { getColorFromChar, shortStringFn } from "../../../utils/utils";
import { getChannelIcon } from "../AllCahatPannel/ChatCard/ChatCard";
import { chatSelectStatusOptions } from "./constants";
import { ChatbotConversationService } from "../../../../Services/Apis/ChatbotConversationService";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";

interface Props extends HocBackdropLoaderProps {}

const MainChatTopHeader: React.FC<Props> = ({ setLoading }) => {
  const dispatch = useAppDispatch();
  const { isValidAccess } = useRolesPermissionsAccess("can_close_conversation");

  const { userInfoData, sessionId, isSearchBoxVisible } =
    useAppSelector(allMessagesStore);
  const {socket} = useSocket();
  const { isUserAvailable } = useAppSelector(useCurrentUser);
  const { showAlert } = useNotification();
  const { allAgentsData, currentSessionData, chatSessionActiveFilter } =
    useAppSelector(LiveChatData);

  const [selectedStatus, setSelectedStatus] = React.useState<any>(null);

  const [selectedAgent, setSelectedAgent] = React.useState<any>(null);
  const { data: authData } = useAppSelector(useAuthStore);

  const handleChangeStatus = (e: any) => {
    if (e?.value) {
      setLoading(true);
      let data = {
        conversation_status: e?.value,
        session_id: sessionId,
        username:authData?.data?.user?.id
      };
      const botHeaders = {
          connection_type: "agent",
        };
      ChatbotConversationService.changeChatStatus(data,botHeaders)
        .then((res) => {
          showAlert(`Status Changed to ${e?.label} `, "success");
          let data = {
            ...userInfoData,
            ...currentSessionData,
            conversation_status: e?.value,
          };
          dispatch(updateSessionData(data));
          dispatch(setUserInfoData(data));
          setSelectedStatus(e);
          dispatch(removeAllMessages({}));
        })
        .catch((err) => {
          showAlert("Something went wrong. Try again", "error");
          console.warn(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleAssignAgent = (e: any) => {
    if (e?.id) {
      let data = {
        fromAgent: userInfoData?.agent,
        toAgent: e?.id,
        session_id: sessionId,
      };
      
      socket?.emit(SOCKET_CONNECTION_TYPES.AGENT_REASSIGN, data, (error: any, response: any) => { 
        if (error) {
          showAlert("Something went wrong. Try again", "error");
          console.warn(error);
        } else if (!error && response?.status) {
          showAlert(`Chat assigned to ${e?.name}`, "success");
        }
        else {}
      });

        dispatch(removeAllMessages({}));
        if (chatSessionActiveFilter?.conversation !== "all") {
          dispatch(removeSessionData(currentSessionData));
        }
      
    }
  };

  React.useEffect(() => {
    if (sessionId && userInfoData) {
      getCurrentStatus();
      getCurrentAgent();
    }
  }, [sessionId, userInfoData, allAgentsData]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentAgent = () => {
    if (allAgentsData?.length < 1) {
      return;
    }

    allAgentsData?.forEach((element: any, index: number) => {
      if (element?.id === userInfoData?.agent) {
        setSelectedAgent(element ?? null);
      }
    });
  };
  const getCurrentStatus = () => {
    if (chatSelectStatusOptions?.length < 1) {
      return;
    }

    chatSelectStatusOptions?.forEach((element: any, index: number) => {
      if (element?.value === userInfoData?.conversation_status) {
        setSelectedStatus(element ?? null);
      }
    });
  };
  const { bgColor, textColor } = getColorFromChar(userInfoData?.name ?? userInfoData?.user);

  return (
    <MainChatTopHeaderWrapper>
      <div className="left">
        <UserAvatar className="userAvatar">
          <div className="avatar">
            <Avatar
              style={{
                backgroundColor: bgColor,
                color: textColor,
                width: "35px",
                height: "35px",
                fontSize: "14px",
              }}
              alt={userInfoData?.name ?? userInfoData?.user}
              src={userInfoData?.avatar}
            >
              {getDefaultAvatar(userInfoData?.name ?? userInfoData?.user)}
            </Avatar>
          </div>
          <div className="body">
            <Tooltip
              title={userInfoData?.name}
              enterDelay={500}
              placement="top"
            >
              <h5>{shortStringFn(userInfoData?.name?.toString())}</h5>
            </Tooltip>

            <div className="channel">
              {userInfoData?.channel && (
                <>
                  {getChannelIcon(userInfoData?.channel)}
                  <p>{makeStringFirstLetterUppercase(userInfoData?.channel_name ?? userInfoData?.channel)}</p>
                </>
              )}
            </div>
          </div>
          <div className="actions">
            <div className="action-top"></div>
          </div>
        </UserAvatar>
      </div>
      <div className="right">
        <ProtectedAppUiElement moduleId="can_assign_conversations">
          {userInfoData?.conversation_type === "livechat" &&
            allAgentsData?.length > 0 && (
              <div className="select-agent">
                <p style={{ width: "70px" }}>Assign To</p>
                <Select
                  className="agentSelection"
                  name={"agentSelection"}
                  value={selectedAgent ? selectedAgent : null}
                  onChange={(e) => {
                    handleAssignAgent(e);
                  }}
                  options={allAgentsData ?? []}
                  placeholder="Select an agent"
                  getOptionLabel={(option: any) =>
                    option?.label || option?.name || option?.value
                  }
                  isOptionSelected={(option: any) =>
                    option?.id === userInfoData?.agent
                  }
                  isOptionDisabled={(option: any) =>
                    option?.status === "offline"
                  }
                  isDisabled={
                    !isUserAvailable ||
                    userInfoData?.conversation_type === "bot" ||
                    userInfoData?.conversation_status === "Closed"
                      ? true
                      : false
                  }
                  hideSelectedOptions={true}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  //@ts-ignore
                  styles={{
                    indicatorSeparator: () => ({ display: "none" }),
                    control: (base: any, state: any) => ({
                      ...base,
                      backgroundColor: "rgb(243, 245, 247)",
                      // backgroundImage:
                      //   "linear-gradient(rgb(255, 255, 255), rgb(243, 245, 247));",
                      border: "1px solid rgb(207, 215, 223);", // Remove the default border
                      boxShadow: "none", // Remove any box shadow

                      "&:hover": {
                        border: "1px solid rgb(207, 215, 223);", // Remove border on hover
                      },
                    }),
                  }}
                />
              </div>
            )}
        </ProtectedAppUiElement>
        <ProtectedAppUiElement moduleId="can_close_conversation">
          {userInfoData?.conversation_type &&
            userInfoData?.conversation_status && (
              <Select
                name={"chatSelection"}
                value={selectedStatus ?? null}
                className="status"
                onChange={(e) => {
                  handleChangeStatus(e);
                }}
                isDisabled={
                  !userInfoData?.agent
                    // && userInfoData?.conversation_state === "unassigned"
                    ? true
                    : false
                }
                options={chatSelectStatusOptions}
                placeholder="Select an status"
                getOptionLabel={(option: any) =>
                  option?.label || option?.name || option?.value
                }
                hideSelectedOptions={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                filterOption={(option: any, rawInput: any) => {
                  if (option?.value === "Closed" && !isValidAccess) {
                    return null;
                  }

                  return option;
                }}
                //@ts-ignore
                styles={{
                  indicatorSeparator: () => ({ display: "none" }),
                  control: (base: any, state: any) => ({
                    ...base,
                    backgroundColor: "#264966",
                    // backgroundImage: "linear-gradient(to bottom,#264966,#12344d)",
                    border: "none", // Remove the default border
                    boxShadow: "none", // Remove any box shadow
                    svg: {
                      fill: "#fff", // Change to your desired hover color
                    },
                    "&:hover": {
                      border: "none", // Remove border on hover
                      color: "#fff",
                    },
                  }),
                  placeholder: (base: any) => ({
                    ...base,
                    color: "#fff", // Change this to your desired placeholder text color
                  }),
                  singleValue: (base: any) => ({
                    ...base,
                    color: "#fff", // Change this to your desired text color
                  }),
                }}
              />
            )}
        </ProtectedAppUiElement>
        <div className="search-assign">
          <IconButton
            onClick={() => dispatch(setIsSearchBoxVisible(!isSearchBoxVisible))}
          >
            <AppMaterialIcons iconName="Search" />
          </IconButton>
        </div>
      </div>
    </MainChatTopHeaderWrapper>
  );
};

export default HocBackdropLoader(MainChatTopHeader);
