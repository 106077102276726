// vendors
import NativeSelect, { NativeSelectProps } from '@mui/material/NativeSelect'
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

// components
import Icon from './../icon';

type Options = {
  label: string,
  value: string
}

interface UISelect extends NativeSelectProps {
  options: Options[]
};

const InputBaseStyled = styled(InputBase)(({ theme }) => ({
  position: 'relative',
  'label + &': {
    marginTop: theme.spacing(1.5),
  },
  '& svg': {
    position: 'absolute',
    top: '14px',
    right: '16px',
    zIndex: 1,
    marginLeft: '-16px'
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#fff',
    border: '1px solid #CBCDD3',
    color: '#7E8392',
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
    padding: '7px 26px 7px 12px',
    paddingRight: '40px !important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },

  },

  '&.MuiInputBase-root.Mui-disabled': {
    border: 'none !important'
  }
}));

const UISelect = (props: any) => {
  const { id, value, onChange, options, disabled } = props;

  return (
    <NativeSelect
      id={id}
      value={value || 0} //* 0 added as optional to remove uncontrolled component warning
      onChange={onChange}
      input={<InputBaseStyled />}
      IconComponent={() => <Icon icon="chevron-down" size={9} color="#7E8392" />}
      disabled={disabled}
      sx={{width: '100%'}}
    >
      <>
        <option value="">Select back node</option>
        {options.map((option: any, index: number) => (
          <option value={option.value} key={`select-option-${id}-${index}`}>{option.label}</option>
        ))}
      </>
    </NativeSelect>
  );
};
export default UISelect;