import React, { useEffect, useState } from "react";
import {
  DeleteEmailVarification,
  EmailVerificationTable,
  EmailVerificationWrapper,
} from "./styles";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import AppButton from "../../../../AppComponents/AppButton";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import Danger from "../../../../assets/images/danger-live.svg";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import { EmailSettingsActions } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.actions";
import {
  emailLists,
  isDeleted,
} from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.selectors";
import { TableWrapper } from "../../../../TableStyle";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";

const EmailVerification: React.FC<LoaderComponentProps> = ({ setLoading }) => {
  const dispatch = useAppDispatch();

  const { showAlert } = useNotification();
  const validEmailLists = useAppSelector(emailLists);
  const isEmailDelted = useAppSelector(isDeleted);

  const [selectedEmail, setSelectedEmail] = useState<any>();
  useEffect(() => {
    dispatch(EmailSettingsActions.getValidEmailLists({}));
  }, []);
  const { isShowing, toggle } = useModal();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (isEmailDelted) {
      dispatch(EmailSettingsActions.getValidEmailLists({}));
      setLoading(false);
      showAlert("Email Deleted Successfully", "success");
    }
  }, [isEmailDelted]);
  useEffect(() => {
    setLoading(false);
  }, [validEmailLists]);
  const handleDeletetoggle = (item: any) => {
    setSelectedEmail(item);
    setAnchorEl(null);
    toggle(true);
  };
  const handleDeleteEmail = () => {
    setLoading(true);
    dispatch(EmailSettingsActions.deleteEmail(selectedEmail)).finally(() => {
      setLoading(false);
    });
    toggle(false);
  };
  return (
    <EmailVerificationWrapper>

      <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 252px)"}>
      <div>
      
          <div className="table-header">

          <Box className="table-row">
              <Box className="col-head">
              Email
              </Box>
              <Box
                className="col-head"
                justifyContent={"center"}
              >
                Verification
              </Box>
              <Box
                className="col-head"
                justifyContent={"flex-end"}
              >
                Actions
              </Box>
            </Box>
          </div>

          <div className="table-body">

              {

                validEmailLists && validEmailLists.length === 0 ? <NoRecordsFound height="calc(100vh - 299px)" /> : (

                  validEmailLists &&
                    validEmailLists?.map((item: any) => {
                      return (
                        <div className="table-row">
                          <Box className="col-body" minWidth={200}>
                            <p title={item.email}>{item.email}</p>
                          </Box>
                          <Box className="col-body" justifyContent={"center"}>
                            {item?.validated || item?.default ? (
                              <div className="verification-status__success">
                                <AppMaterialIcons iconName="DoneOutlined" />
                                <p>{"Verified"}</p>
                              </div>
                            ) : (
                              <div className="verification-status__failed">
                                <AppMaterialIcons iconName="CloseOutlined" />
                                <p>{"Unverified"}</p>
                              </div>
                            )}
                          </Box>
                          <Box className="col-body" justifyContent={"flex-end"}>
                            {!item?.default ? (
                              <>
                                <IconButton
                                  id="basic-button"
                                  aria-controls={open ? "basic-menu" : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={handleClick}
                                >
                                  <AppMaterialIcons iconName="MoreVertOutlined" />
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                  sx={{
                                    "& .MuiPaper-root": {
                                      borderRadius: 1,
                                      boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.1)",
                                    },
                                  }}
                                >
                                  <MenuItem onClick={() => handleDeletetoggle(item)}>
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </>
                            ) : (
                              <></>
                            )}
                          </Box>
                        </div>
                      );
                    })
                  
                )

              }
          </div>

        </div>
      </TableWrapper>

      <AppModel
        isShowing={isShowing}
        onClose={() => toggle(false)}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteEmailVarification>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} />
              </div>
              <div className="text">
                <h4>Delete {selectedEmail?.email}?</h4>
                <p>
                  Are you sure you want to remove this email? You will no longer
                  be able to send from it.
                </p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggle(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggle(false)}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => handleDeleteEmail()}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteEmailVarification>
      </AppModel>
    </EmailVerificationWrapper>
  );
};
export default withLoader(EmailVerification);
