export const formBuilderJSONCreator = (array: any, key: any) => {
    let groupingViaCommonProperties = Object.values(
        array.reduce((acc: any, current: any) => {
            acc[current[key]] = acc[current[key]] ?? [];
            acc[current[key]].push(current);
            return acc;
        }, {})
    );
    return groupingViaCommonProperties;
}

// Function to check if an ID is present in the items
export const isIdPresent = (arrayOfArrays: any, idToCheck: any) => {
    // Flatten the array of arrays into a single array of objects
    const flattenedArray = arrayOfArrays?.flat();

    // Check if the ID is present in any of the objects
    for (const item of flattenedArray) {
        if (item.id === idToCheck) {
            return true; // ID found
        }
    }
    return false; // ID not found
}
