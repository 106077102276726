import { emailDataStore } from "../../../../../Store/Slices/email/email.selectors";
import { useAppSelector } from "../../../../../Store";
import AppMarketingList from "../../../../../AppComponents/Forms/AppMarketingList"
const RenderFormStep2 = (prop: any) => {
  const { postEmailBroadcastData } = useAppSelector(emailDataStore);
  const emailData = {
    "id": postEmailBroadcastData
  }
  return (
    <>
      <div style={{ backgroundColor: "#ffff", padding: "1rem" }}>
        <AppMarketingList type="email" subscriptionName = "email_subscription" broadcast_id={emailData} />
      </div>
    </>
  )
}

export default RenderFormStep2;
