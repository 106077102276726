import { Avatar, Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { ColorUtils } from "../../../../../Utils/ColorUtils";

interface Props {
  name: string;
  company_logo: any;
  subheader_text: string;
  primary_color: string;
}

const BotHeaderScreen: React.FC<Props> = ({
  company_logo,
  name,
  subheader_text,
  primary_color,
}) => {
  return (
    <StyledHeaderContainer $primaryColor={primary_color}>
      <Avatar alt={name} src={company_logo ?? undefined} />
      <div className="infoBox">
        <p className="title">{name ?? "Whatsapp Chat"}</p>
        {subheader_text?.length > 0 ? (
          <p className="lastActiveInfo">{subheader_text}</p>
        ) : null}
      </div>
    </StyledHeaderContainer>
  );
};

export default React.memo(BotHeaderScreen);

const StyledHeaderContainer = styled(Box)`
  && {
    width: 100%;
    height: 56px;
    padding: 12px 16px 12px 12px;
    background-color: ${({ $primaryColor }: any) => $primaryColor ?? "#128c7e"};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    box-shadow: 0px 1px 1px 0px #a5a5a51a;
    & .MuiAvatar-root {
      width: 32px;
      height: 32px;
      font-size: 16px;
      border: 1px solid #ffffff;
    }
    & .infoBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2px;
      & .title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 20px;
        color: ${({ $primaryColor }: any) =>
          ColorUtils.getContrastTextColor($primaryColor, 0.87) ?? "#ffffff"};
        letter-spacing: 0.25px;
        text-transform: capitalize;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .lastActiveInfo {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color: ${({ $primaryColor }: any) =>
          ColorUtils.getContrastTextColor($primaryColor, 0.87) ?? "#ffffff"};
        letter-spacing: 0.25px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      flex: 1;
    }

    & .actionBox {
      .MuiButtonBase-root {
      }

      .MuiSvgIcon-root {
        font-size: 1.2rem;
        color: ${({ $primaryColor }: any) =>
          ColorUtils.getContrastTextColor($primaryColor, 0.87) ?? "#ffffff"};
      }
    }
  }
`;
