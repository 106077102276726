// vendors
import IconButton, { IconButtonProps } from "@mui/material/IconButton";

// components
import Icon from "../../../Icon";

// styles
import { IconButtonStyled } from "./common.styles";

interface DeleteButtonProps extends IconButtonProps {}

const DeleteButton = (props: DeleteButtonProps) => {
  return (
    <IconButtonStyled onClick={props.onClick}>
      <Icon icon="delete" size={16} color="#7E8392" />
    </IconButtonStyled>
  );
};
export default DeleteButton;
