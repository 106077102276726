import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  AddLabel,
  DeleteLabel,
  LabelContainer,
  LabelTable,
  LabelWrapper,
} from "./LabelStyles";
import { ErrorComponent } from "../../../Broadcast/WhatsApp/Templates/TemplatesListingCSS";
import { Box, IconButton, TablePagination, Typography } from "@mui/material";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import useModal from "../../../../Hooks/useModel/useModel";

import TemplateAPIERROR from "../../../../assets/images/TemplateAPIERROR.png";

import {
  AppForm,
  AppFormColorPicker,
  AppFormField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import Danger from "../../../../assets/images/danger-live.svg";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { useSelector } from "react-redux";
import { LabelActions } from "../../../../Store/Slices/Settings/LiveChat/ChatLabel/chatlabel.actions";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import {
  chatLabel,
  labelListingData,
  labelListingDataCount,
} from "../../../../Store/Slices/Settings/LiveChat/ChatLabel/chatlabel.selectors";
import { ResponseHeader } from "../CannedResponse/CannedResponsesstyles";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import { labelSchema } from "./ChatLabelValidationSchema";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { TableWrapper } from "../../../../TableStyle";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";

interface Props extends LoaderComponentProps { }

const ChatLabels: React.FC<Props> = ({ setLoading }) => {
  const currentBot = useSelector(useCurrentBot);
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  const labelListing = useSelector(labelListingData);
  const chatLabelData = useSelector(chatLabel);
  const labelListingCount = useSelector(labelListingDataCount);

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedId, setSelectedID] = useState();

  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
  });

  // Modals
  const useModal1 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal1: isShowing, toggleModal1: toggle };
  };

  const useModal2 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal2: isShowing, toggleModal2: toggle };
  };
  const { isShowingModal1, toggleModal1 } = useModal1();
  const { isShowingModal2, toggleModal2 } = useModal2();

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot?.bot_id,
    }),
  };

  // Initial Form Values

  const [initialVlues, setInitialVlues] = useState({
    label_name: "",
    label_description: "",
    color: "#000000",
  });

  const editLabelById = (id: any) => {
    setLoading(true);
    const data = {
      id,
      HEADERS,
    };
    dispatch(LabelActions.getLabelById(data)).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (chatLabelData) {
      setInitialVlues({
        label_name: chatLabelData?.label,
        label_description: chatLabelData?.description,
        color: chatLabelData?.color,
      });
    }
  }, [chatLabelData]);

  // Edit / Update Label
  const updateLabel = (values: any, SubmitProps: any) => {
    setLoading(true);
    const payload = {
      name: values.label_name,
      description: values.label_description,
      color: values.color,
    };
    const data = {
      id: selectedId,
      payload,
      HEADERS,
    };
    if (selectedId) {
      dispatch(LabelActions.updateLabelById(data))
        .then((res: any) => {
          showAlert("Label is updated", "success");
          toggleModal1();
          getAllLabelsListing();
        })
        .catch((err: any) => {
          showAlert("Something went wrong", "success");
          console.error("error", err);
        })
        .finally(() => {
          setLoading(false);
          SubmitProps?.resetForm();
        });
    }
  };

  // Get listing of all Labels messages
  useEffect(() => {
    // if (currentBot?.bot_id) {
    getAllLabelsListing();
    // }
  }, [localValues, currentBot?.bot_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllLabelsListing = () => {
    const data = {
      HEADERS,
      limit: localValues.itemsPerPage,
      offset: localValues.currentPage,
    };

    dispatch(LabelActions.getListingOfLabels(data))
      .then((res: any) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  // Add Label
  const handleAddLabel = (values: any, SubmitProps: any) => {
    setLoading(true);
    const payload = {
      name: values.label_name,
      description: values.label_description,
      color: values.color,
    };
    const data = {
      payload,
      HEADERS,
    };
    SubmitProps?.resetForm();
    dispatch(LabelActions.createChatLabel(data))
      .then((res: any) => {
        showAlert("Message is added", "success");
        toggleModal1();
        getAllLabelsListing();
      })
      .catch((err: any) => {
        showAlert("Something went wrong", "error");
        console.error("error", err);
      })
      .finally(() => {
        toggleModal1();
        setLoading(true);
      });
  };

  // Delete Label
  const handleDeleteLabel = (id: any) => {
    toggleModal2();
    setLoading(true);
    const data = {
      id,
      HEADERS,
    };

    dispatch(LabelActions.deleteLabelById(data));
    setLoading(false);
    getAllLabelsListing();
    showAlert("Label is deleted", "success");
  };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };


  const header = [
    "Name",
    "Color",
    "Message",
    "Actions",
  ];

  const keyToHeaderMap: any = {
    "Name": "label",
    "Color": "color",
    "Message": "description",
  };

  const colWidth: any = {
    "Name": "200",
    "Color": "200",
    "Message": "200",
    "Actions": "200"
  };


  const renderColumns = (row: any, header: any,) => {
    if(header === "Actions") {
        return <Box style={{
          display: row?.name === "admin" ? "none" : "block",
        }}>

                          <IconButton
                            onClick={() => {
                              editLabelById(row.id);
                              toggleModal1();
                              setIsEditing(true);
                              setSelectedID(row.id);
                            }}
                          >
                            <AppMaterialIcons iconName="EditOutlined" />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              toggleModal2(row.id);
                              setSelectedID(row.id);
                            }}
                          >
                            <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                          </IconButton>
               
            </Box>
    } else {
        return <Typography
            className="table-text"
            sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            }}
        >
          {
            
            header === "Color" ? <Box display={"flex"} gap={1}>

                          <Box
                          width={17}
                          height={17}
                          borderRadius={100}
                          className="color"
                          style={{ background: `${row.color}` }}
                        >
                          
                        </Box>
                        <Box>
                        {row?.color}
                          </Box>
            
            </Box> :
           row[keyToHeaderMap[header]]
              ? row[keyToHeaderMap[header]]
              : "-"
          }
        </Typography>
    }
  }

  return (
    <LabelWrapper>
      <ResponseHeader>
        <div className="response-header__left">
          <h4>Labels</h4>
        </div>
        <div className="response-header__right">
          <div className="response-search"></div>
          <div className="response-button">
            <AppButton
              onClick={() => {
                toggleModal1();
                setIsEditing(false);
                setInitialVlues({
                  label_name: "",
                  label_description: "",
                  color: "#000000",
                });
              }}
            >
              <AppMaterialIcons iconName="add" />
              Add Label
            </AppButton>
          </div>
        </div>
      </ResponseHeader>


      <LabelContainer>




      <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 178px)"}>
              <div>
              <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                      key={index}
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* Table Body */}
              </Box>

              <Box className="table-body">
                {labelListing && labelListing?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 279px)" />
                ) : (
                  <>
                    {labelListing && labelListing?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className={`table-row`} >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
              </div>
        </TableWrapper>






        <LabelTable>

          {/* Add / Edit Modal */}
          {isShowingModal1 && (
            <AppModel
              isShowing={isShowingModal1}
              onClose={() => toggleModal1()}
              divStyle={{
                padding: 0,
                "& .MuiPaper-root-MuiDialog-paper": {
                  overflow: "visible",
                },
              }}
              PaperProps={{
                style: {
                  boxShadow: "none",
                  width: "500px",
                  height: "522px",
                },
              }}
            >
              <AddLabel>
                <div className="header">
                  <div className="header_left">
                    <h4>Add Label</h4>
                    <p>Labels Let You Group The Conversations Together.</p>
                  </div>
                  <div className="header_right">
                    <span onClick={() => toggleModal1()}>
                      <IconButton>
                        <AppMaterialIcons iconName="close" />
                      </IconButton>
                    </span>
                  </div>
                </div>
                <div className="body">
                  <AppForm
                    initialValues={initialVlues}
                    onSubmit={(values: any, submitProps: any) => {
                      if (isEditing && selectedId) {
                        updateLabel(values, submitProps);
                      } else {
                        handleAddLabel(values, submitProps);
                      }
                    }}
                    validationSchema={labelSchema}
                  >
                    <div className="">
                      <AppFormField
                        label="Label Name"
                        name="label_name"
                        placeholder="Marketing"
                        requiredSign={true}
                      />

                      <AppFormColorPicker
                        label="Color"
                        name="color"
                        onColorChange={(color) => { }}
                      // notSupportedHexCodes={notSupportedHexCodes}
                      // tooltip="Used as a background color to highlight areas and buttons"
                      // customOnBlur={changeTheme}
                      ></AppFormColorPicker>

                      <AppFormTextArea
                        label="Description"
                        name="label_description"
                        tooltip={""}
                        placeholder="Label Description"
                      />
                    </div>

                    <div className="footer">
                      <AppButton variant="grey" onClick={() => toggleModal1()}>
                        Cancel
                      </AppButton>
                      <div className="submit-button">
                        {isEditing ? (
                          <AppSubmitButton
                            variant="cta"
                            style={{ width: "180px" }}
                            title={"Update Label"}
                          />
                        ) : (
                          <AppSubmitButton
                            variant="cta"
                            style={{ width: "180px" }}
                            title={"Add"}
                          />
                        )}
                      </div>
                    </div>
                  </AppForm>
                </div>
              </AddLabel>
            </AppModel>
          )}

          {/* Delete Modal */}
          <AppModel
            isShowing={isShowingModal2}
            onClose={toggleModal2}
            divStyle={{
              padding: 0,
            }}
            PaperProps={{
              style: {
                boxShadow: "none",
                width: "500px",
              },
            }}
          >
            <DeleteLabel>
              <div className="header">
                <div className="header-left">
                  <div className="danger">
                    <img src={Danger} alt='danger_imgae' />
                  </div>
                  <div className="text">
                    <h4>Delete Label</h4>
                    <p>Are you sure, you want to delete?</p>
                  </div>
                </div>
                <div className="header-right">
                  <span onClick={() => toggleModal2()}>
                    <AppMaterialIcons iconName="close" />
                  </span>
                </div>
              </div>
              <div className="footer">
                <AppButton variant="grey" onClick={() => toggleModal2()}>
                  Cancel
                </AppButton>
                <AppButton
                  variant="danger-filled"
                  onClick={() => {
                    return handleDeleteLabel(selectedId);
                  }}
                >
                  <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                  Delete
                </AppButton>
              </div>
            </DeleteLabel>
          </AppModel>
        </LabelTable>
      </LabelContainer>

      {/* Pagination Remove */}
      {/* <div className="footer-pagination">
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={localValues?.currentPage}
          count={+labelListingCount}
          rowsPerPage={localValues?.itemsPerPage}
        />
      </div> */}
    </LabelWrapper>
  );
};

export default withLoader(ChatLabels);
