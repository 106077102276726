import styled from "styled-components";

export const GrahWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    // gap: 24px;
    height: 100%;

    .bar-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      height: 100%;
      // border: 1px solid red;
    }

    .time_stats {
      font-size: 18px;
      display: flex;
      font-family: Inter;
      color: #101010;
      font-weight: 500;
      .div_tooltip {
        font-size: 15px;
      }
    }
    .header_avg {
      font-size: 16px;
      font-family: Inter;
      font-weight: 400;
      margin-bottom: -6px;
    }
    .progess_bar {
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-end;
    }
    .progress_bar_text {
      width: 15%;
      font-family: Inter;
    }
    .progress_bar_count {
      width: 85%;
      font-family: Inter;
      .stats {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 6px;
      }
    }
    .graph_container {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 20px;
      font-family: Inter;
    }
    .x_axis_data {
      width: 85%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #4d4f52;
      // padding-bottom: 20px;
      font-family: Inter;
    }
  }
`;

export const RecipientsWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;

    .main_header {
      font-size: 16px;
      font-weight: 500;
      font-family: Inter;
      color: #ffffff;
    }

    .info_values {
      height: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .header {
      font-size: 16px;
      text-align: center;
      font-family: Inter;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
    .value {
      font-size: 32px;
      font-weight: 700;
      font-family: Inter;
      text-align: center;
      color: #ffffff;
    }
    .credits_btn {
      width: max-content;
      max-width: 70%;
      padding: 8px 16px;
      text-align: center; 
    }
  }
`;
