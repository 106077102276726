import { Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import uniqid from "uniqid";
import { theme } from "../../../../../Customization/Theme";
import { ColorUtils } from "../../../../../Utils/ColorUtils";

interface Props {
  userMessage: boolean;
  children: any;
  time: string;
  [otherProps: string]: any;
}
const AppRenderText: React.FC<Props> = React.memo(
  ({ userMessage = false, children, time, ...otherProps }) => {
    if (!children || children?.length < 1) {
      return null;
    }

    return (
      <StyledRowWrapper key={uniqid()} $userMessage={userMessage}>
        <StyledMessageBox>
          <StyledMessageText
            $userMessage={userMessage}
            dangerouslySetInnerHTML={{
              __html: children,
            }}
            {...otherProps}
          />
          <StyledTimeBox>{time}</StyledTimeBox>
        </StyledMessageBox>
      </StyledRowWrapper>
    );
  }
);

export default AppRenderText;

interface IStyleProps {
  $userMessage?: boolean;
}

const StyledRowWrapper = styled(Box)<IStyleProps>`
  && {
    padding: 4px 10px;
    position: relative;
    max-width: 85%;
  }
`;
const StyledMessageText = styled(Box)<IStyleProps>`
  && {
    padding: 1px 4px;
    color: ${ColorUtils.hexToRgba("#000000", 0.87)};
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-transform: capitalize;
  }
`;
const StyledTimeBox = styled(Box)<IStyleProps>`
  && {
    padding: 2px 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    color: ${ColorUtils.hexToRgba("#000000", 0.6)};

    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0.5px;
    text-transform: capitalize;
  }
`;
const StyledMessageBox = styled(Box)<IStyleProps>`
  && {
    background-color: ${theme.palette.default.white};
    box-shadow: 0px 1px 1px 0px #0000001a;
    padding: 6px;
    border-radius: 8px 8px 8px 0px;

    &::after {
      left: 6px;
      top: 15%;
      -webkit-transform: rotate(230deg);
      -ms-transform: rotate(230deg);
      transform: rotate(230deg);
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      border: 8px solid;
      border-color: #ffffff transparent transparent transparent;
    }
  }
`;
