// import { API_ENDPOINTS, CH_BOT_ENDPOINTS, CH_CONSOLE_ENDPOINTS } from './Apis/Endpoints';
// import { coreAxios, conversationAxios, chatbotConsoleAxios } from "./Axios";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { formatString } from "../Utils";
// export const getAxiosInstance = (api_endpoint_base: API_ENDPOINTS) => {
//   switch (api_endpoint_base) {
//     case API_ENDPOINTS.CORE_API:
//       return coreAxios;
//     case API_ENDPOINTS.CH_BOT_ENDPOINTS:
//       return conversationAxios;
//     case API_ENDPOINTS.CH_CONSOLE_ENDPOINTS:
//       return chatbotConsoleAxios;
//     default:
//       throw new Error("Axios Instance type not defined!");
//   }
// }

export interface HTTPFnParams {
  url: string;
  method?: string;
  params?: { [key: string]: any; };
  data?: { [key: string]: any; };
  headers?: { [key: string]: string; };
  pathParams?: Array<any>;
  formData?: FormData;
  config?:any;
}

const getHttpService = (axios: AxiosInstance) => {
  const get = async (params: HTTPFnParams) => {
    let url = params.url;
    if (params?.pathParams) {
      url = formatString(params.url, params.pathParams)
    }
    const config: AxiosRequestConfig = {
      url: url,
      method: params.method ? params.method : 'get'
    };
    if (params?.params) {
      config.params = params.params;
    }
    if (params?.headers) {
      config.headers = params?.headers
    }

    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
  
  const post = async (params: HTTPFnParams) => {
    let url = params.url;
    if (params?.pathParams) {
      url = formatString(params.url, params.pathParams)
    }
    const config: AxiosRequestConfig = {
      url: url,
      method: params.method ? params.method : 'post'
    };
    if (params?.params) {
      config.params = params.params;
    }
    if (params?.headers) {
      config.headers = params?.headers
    }
    if (params?.data) {
      config.data = params?.data;
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
  
  const patch = async (params: HTTPFnParams) => {
    let url = params.url;
    if (params?.pathParams) {
      url = formatString(params.url, params.pathParams)
    }
    const config: AxiosRequestConfig = {
      url: url,
      method: params.method ? params.method : 'patch'
    };
    if (params?.params) {
      config.params = params?.params;
    }
    if (params?.data) {
      config.data = params?.data;
    }
    if (params?.headers) {
      config.headers = params?.headers
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
  const put = async (params: HTTPFnParams) => {
    let url = params.url;
    if (params?.pathParams) {
      url = formatString(params.url, params.pathParams)
    }
    const config: AxiosRequestConfig = {
      url: url,
      method: params.method ? params.method : 'put'
    };
    if (params?.params) {
      config.params = params?.params;
    }
    if (params?.data) {
      config.data = params?.data;
    }
    if (params?.headers) {
      config.headers = params?.headers
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };

  const upload = async (params: HTTPFnParams) => {
    let url = params.url;
    if (params?.pathParams) {
      url = formatString(params.url, params.pathParams)
    }
    const config: AxiosRequestConfig = {
      url: url,
      method: params.method ? params.method : 'post'
    };
    if (params?.params) {
      config.params = params.params;
    }
    if (params?.headers) {
      config.headers = params?.headers
    }
    if (params?.data) {
      config.data = params?.data;
    }
    if (params?.formData) {
      config.data = params?.formData;
    }
    if(params?.config){
      config.onUploadProgress = params?.config.onUploadProgress
      delete params.config;
    }
    return await axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
  
  const httpDelete = async (params: HTTPFnParams) => {
    let url:any = params.url;
    if (params?.pathParams) {
      url = formatString(params.url, params.pathParams)
    }
    const config: AxiosRequestConfig = {
      url: url,
      method: params.method ? params.method : 'delete'
    };
    if (params?.params) {
      config.params = params?.params;
    }
    if (params?.data) {
      config.data = params?.data;
    }
    if (params?.headers) {
      config.headers = params?.headers
    }
    return axios
      .request(config)
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  };
  
  return {get, post, put, patch, httpDelete, upload}
}



export { getHttpService };
