// vendors

import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import debounce from "lodash/debounce";
import parse from "html-react-parser";
import ReactPlayer from "react-player";

// components
import Icon from "../../../Components/Icon";
import ButtonList from "../../../Nodes/Buttons/ButtonsList";
import InteractiveContent from "../common/interactive";

// slice
import {
  getLanguage,
  getActiveNode,
  updateModifiedData,
} from "../../../Slices/main.slice";
import { useAppDispatch } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";

// utils
import { getNodeConfig, getInteractiveMessageData } from "../../../Utils";
import {
  convertWhatsappToHtmlText,
  getChannelWiseText,
} from "../../../Utils/editor";

const InteractiveMessage = (props: NodeProps) => {
  const { node } = props;
  const language = useSelector(getLanguage);
  const { interactive } = getInteractiveMessageData(node, language);
  const [header, updateHeader] = useState(interactive?.header || "");
  const [body, updateBody] = useState(
    convertWhatsappToHtmlText(interactive?.body) || "",
  );
  const [footer, updateFooter] = useState(interactive?.footer || "");
  const [buttonsList, updateButtonsList] = useState<any[]>(
    interactive?.action?.buttons || [],
  );
  const activeNode = useSelector(getActiveNode);
  const currentBot = useSelector(useCurrentBot);
  const nodeConfig = getNodeConfig(node.subType);
  const validation = currentBot?.channel
    ? nodeConfig.validation[currentBot?.channel]
    : {};
  const charLimit = validation?.charLimit;
  const dispatch = useAppDispatch();
  const CHARACTER_LIMITS = {
    header: validation?.headerMaxLimit,
    body: validation?.bodyMaxLimit,
    footer: validation?.footerMaxLimit,
  };
  const isActiveNode = activeNode === node.nodeId;

  const handleDebounceFn = (
    header: string,
    body: string,
    footer: string,
    list: any[],
  ) => {
    const data = {
      type: "button",
      header: header,
      footer: footer,
      body: getChannelWiseText(body, "whatsapp"),
      action: {
        buttons: list,
      },
    };
    dispatch(
      updateModifiedData([{ interactive: data, type: "whatsapp_interactive" }]),
    );
  };

  const debounceFn = useCallback(handleDebounceFn, []);

  const handleButtonLabelChange = (
    label: string,
    activeButtonIdx: number | null,
  ) => {
    if (activeButtonIdx !== null) {
      const tempButtonList = cloneDeep(buttonsList);
      const newState = tempButtonList.map((button: any, index: number) => {
        if (index === activeButtonIdx) {
          return {
            ...button,
            value: {
              ...button.value,
              ...{ text: label?.substring(0, charLimit) },
            },
          };
        }
        return button;
      });
      updateButtonsList(newState);
      debounceFn(header, body, footer, newState);
    }
  };

  const getById = (id: string) =>
    buttonsList.find((button: any) => button.id === id);

  const handleButtonSave = (updatedAction: any) => {
    if (updatedAction.id) {
      const previousValue = getById(updatedAction.id);

      const isDifferent = isEqual(previousValue, updatedAction);

      if (previousValue) {
        if (!isDifferent) {
          const tempButtonList = cloneDeep(buttonsList);
          const newState = tempButtonList.map((button: any, index: number) => {
            if (button.id === updatedAction.id) {
              return {
                ...button,
                type: updatedAction.type,
                value: {
                  ...button.value,
                  ...{
                    text: updatedAction.value.text.substring(0, charLimit),
                    url: updatedAction.value.url,
                    ...updatedAction.value,
                  },
                },
                ...(updatedAction.type === "transfer_to_faqs" && {
                  text: updatedAction.text,
                }),
              };
            }
            return button;
          });
          updateButtonsList(newState);
          debounceFn(header, body, footer, newState);
        }
        return;
      } else {
        const updatedBtn = [...buttonsList, ...[updatedAction]];
        updateButtonsList(updatedBtn);
        debounceFn(header, body, footer, updatedBtn);
      }
    }
  };

  const handleButtonDelete = (activeButton: any, index: number) => {
    const tempButtonList = cloneDeep(buttonsList);
    if (tempButtonList.length === 1) return;
    if (activeButton.id) {
      const newState = tempButtonList.filter(
        (button: any) => button.id !== activeButton.id,
      );
      updateButtonsList(newState);
      debounceFn(header, body, footer, newState);
      return;
    }
    /* NOTE: in case button doesn't have ids. delete operation will perform on the basis of index */
    if (index !== -1) {
      tempButtonList.splice(index, 1);
      updateButtonsList(tempButtonList);
      debounceFn(header, body, footer, tempButtonList);
      return;
    }
  };

  const handlePreviewButtonClick = () => {
    // setPreview(false);
  };

  const handleFooterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length > CHARACTER_LIMITS.footer) return;
    updateFooter(value);
    debounceFn(header, body, value, buttonsList);
  };

  const handleBodyTextChange = (bodyText: string) => {
    updateBody(bodyText);
    debounceFn(header, bodyText, footer, buttonsList);
  };

  const handleHeaderChange = (data: any) => {
    updateHeader(data);
    debounceFn(data, body, footer, buttonsList);
  };
  const handleOrderChange = (buttonsList: any) => {
    const tempButtonList = cloneDeep(buttonsList);
    updateButtonsList(tempButtonList);
    debounceFn(header, body, footer, tempButtonList);
    return;
    // dispatch(setButtonsList(buttonsList));
  };

  return (
    <Box sx={{ p: "16px" }}>
      {isActiveNode ? (
        <>
          <InteractiveContent
            characterLimit={CHARACTER_LIMITS}
            header={header}
            body={body}
            footer={footer}
            onBodyChange={handleBodyTextChange}
            onHeaderChange={handleHeaderChange}
            onFooterChange={handleFooterChange}
            isActive={isActiveNode}
          />
          <InputLabel
            shrink
            htmlFor="footer"
            sx={{ position: "static", transform: "none" }}
          >
            Quick reply buttons <sup style={{ color: "red" }}>*</sup>
          </InputLabel>
          <Box sx={{ mx: "-16px", mt: "-16px" }}>
            <ButtonList
              isActiveNode={activeNode}
              buttonsList={activeNode ? buttonsList : []}
              onLabelChange={handleButtonLabelChange}
              onButtonSave={handleButtonSave}
              onPreviewButtonClick={handlePreviewButtonClick}
              onButtonDelete={handleButtonDelete}
              nodeType={node.subType}
              minLimit={1}
              onOrderChange={(buttonsList: any) =>
                handleOrderChange(buttonsList)
              }
            />
          </Box>
        </>
      ) : (
        <>
          {header.type === "text" && (
            <Typography variant="body1">{header.text}</Typography>
          )}
          {header.type === "document" && (
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "6px",
                  backgroundColor: "rgb(203, 205, 211)",
                  height: "110px",
                }}
              >
                <Icon icon={"article"} size={24} color="#fff" />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Typography
                  color={"#5B73E8"}
                  variant="body1"
                  component={"a"}
                  href={header.link}
                  target="_blank"
                  sx={{
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <Icon icon={"link"} size={14} />
                  {header.filename.substring(header.filename.indexOf("_") + 1)}
                </Typography>
              </Box>
            </>
          )}
          {header.type === "image" && (
            <Box
              component="img"
              src={header.link}
              sx={{ width: "100%", height: "150px", objectFit: "cover" }}
            />
          )}
          {header.type === "video" && (
            <ReactPlayer
              url={header.link}
              width={"100%"}
              height="150px"
              controls={true}
            />
          )}
          {body && body !== "" && (
            <Typography variant="body1">{parse(body)}</Typography>
          )}
          {footer && footer !== "" && (
            <Typography variant="caption">{footer}</Typography>
          )}
          {buttonsList.length > 0 && (
            <Box sx={{ mx: "-16px" }}>
              <ButtonList
                isActiveNode={isActiveNode}
                buttonsList={buttonsList}
                onLabelChange={handleButtonLabelChange}
                onButtonSave={handleButtonSave}
                onPreviewButtonClick={handlePreviewButtonClick}
                onButtonDelete={handleButtonDelete}
                nodeType={node.subType}
                minLimit={1}
                onOrderChange={() => {}}
              />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
export default InteractiveMessage;
