import React from "react";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./SortableCards";
import styled from "styled-components";
import { Backdrop, Chip, CircularProgress } from "@mui/material";
import AppButton from "../../../AppComponents/AppButton";
import { theme } from "../../../Customization/Theme";

export default function Container(props: {
  id: string;
  items: any;
  label: string;
  setPagination: any;
  totalRecords: any;
  statusId: string;
  stageId: string;
  index: number;
  setDeleteData: any;
  Loader: boolean;
  pagination: any;
  toggleContactModal: any;
}) {
  const {
    id,
    items,
    label,
    setPagination,
    totalRecords,
    statusId,
    stageId,
    index,
    setDeleteData,
    Loader,
    pagination,
    toggleContactModal,
  } = props;
  const { setNodeRef } = useDroppable({
    id,
    data: {
      type: "container",
    },
  });

  const colorPairs = [
    {
      backgroundColor: "#FFE6D1",
      color: "#b440e7",
    },
    {
      backgroundColor: "#D1FAFF",
      color: "#4088E7",
    },
    {
      backgroundColor: "#FFE2E7",
      color: "#FFA41C",
    },
    {
      backgroundColor: "#D9FFE4",
      color: "#63B078",
    },
    {
      backgroundColor: "#FBFFD1",
      color: "#9AA241",
    },
  ];

  let colorIndex = index % colorPairs.length;
  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "8px",
        }}
      >
        <CardTitle $getColor={colorPairs[colorIndex]?.color}>
          <span>{label}</span>
          <Chip label={`${totalRecords} Leads`} />
        </CardTitle>
        <ParentDiv>
          <CardContainer ref={setNodeRef} className="check">
            {items.map((item: { id: any }) => (
              <>
                <SortableItem
                  key={item?.id}
                  id={item?.id}
                  items={item}
                  setDeleteData={setDeleteData}
                  index={items?.length - 1}
                  status={label}
                  statusId={statusId}
                  stageId={stageId}
                  toggleContactModal={toggleContactModal}
                />
              </>
            ))}
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
                position: "absolute",
              }}
              open={Loader && label === pagination?.status}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </CardContainer>

          {totalRecords &&
          totalRecords > "10" &&
          parseInt(totalRecords) !== items?.length ? (
            <>
              <AppButton
                style={{
                  width: "calc(100% - 40px)",
                  marginLeft: "20px",
                  bottom: "10px",
                  height: "2.5rem",
                  marginTop: "0.9rem",
                  // background: theme.palette.default.white,
                  position: "absolute",
                }}
                variant="outline"
                startIcon="add"
                onClick={() =>
                  setPagination((prev: any) => ({
                    index: items?.length,
                    status: label,
                    statusId: statusId,
                    stageId: stageId,
                    limit: 10,
                  }))
                }
              >
                See more
              </AppButton>
            </>
          ) : null}
        </ParentDiv>
      </div>
    </SortableContext>
  );
}
const ParentDiv = styled.div`
  width: 306px;
  max-height: 664px;
  padding: 20px, 16px, 179px, 16px;
  border-radius: 8px;
  background: #5b73e80d;
  margin: 10px;
  align-items: center;
  overflow: auto;
  position: relative;
  min-height: calc(100vh - 338px);
`;
const CardContainer = styled.div`
  width: 306px;
  // max-height: 609px;
  padding: 20px, 16px, 179px, 16px;
  position: relative;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 266px);
  scroll-behavior: auto;
`;

const CardTitle = styled.div<{ $getColor?: any }>`
  width: 306px;
  height: 43px;
  box-shadow: 0px 2px 20px 0px #7e83921f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  border-left: ${(props: any) => `6px solid ${props.$getColor}`};
  border-radius: 4px;
  padding: 0px 7px;
`;
