import moment from "moment";
import NoRecordImage from "../../../../../../assets/images/norecordsanalytics.svg";
import { Wrappertable } from "../Styles";
import NoRecordsFound from "../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import React from "react";

interface Props {
  broadcastDetails?: any;
}

const BroadcastTableInfo: React.FC<Props> = ({
  broadcastDetails,
}) => {
  const formateDate = (d: any) => {
    return moment(d).format("DD-MM-YYYY h:mm A")
  };

  const hasData = (
    broadcastDetails &&
    (broadcastDetails?.delivered_at ||
      broadcastDetails?.run_at ||
      broadcastDetails?.updated_at)
  ) ? true : false;

  return (
    <Wrappertable>
      {
        hasData ? (
          <React.Fragment>
            <div className="roww_table">
              <p className="text">Updated at</p>
              <p className="value">{formateDate(broadcastDetails?.updated_at) || "N/A"}</p>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table">
              <p className="text">Run at</p>
              <p className="value">{formateDate(broadcastDetails?.run_at) || "N/A"}</p>
            </div>
            <hr className="horizontal_line" />
            <div className="roww_table">
              <p className="text">Delivery at</p>
              <p className="value">{formateDate(broadcastDetails?.delivered_at) || "N/A"}</p>
            </div>
          </React.Fragment>
        ) : (
          <NoRecordsFound
            imageSrc={NoRecordImage}
            imageWidth={"200px"}
            headerTextSize={"19px"}
          />
        )
      }
    </Wrappertable>
  );
};

export default BroadcastTableInfo;
