export enum formKeys {
    FIELD = "label",
    INTERNAL_NAME = "internalName",
    TYPE = "type",
    TOOLTIP = 'tooltip',
    PLACEHOLDER = "placeholder",
    GROUP = "group",
    PARENT_SECTION = "parent_section",
    REQUIRED = 'required',
    UNIQUE = 'unique',
    SECTION_ID = 'section_id'
 }

 export const getSectionInitialValues = () =>{
    return (
        {
            parent: "",
            label : "",
            is_child_of_other_section :false,
        }
    )
 }