import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { GrahWrapper } from "./ResponseTimeAgentstyle";
import { TextTitle } from "../AnalyticsSummaryStyles";
import { SkeletonCard, SkeletonProgressBar } from "../SkeletonLoader.tsx/SkeletonCard";
import { formatTime } from "../../../Utils/Utils";

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "purple" : "#308fe8",
  },
}));

interface Props {
  resolution_time?: any;
  response_time?: any;
  xAxisMinValue?: any;
  responseTimeTooltip?: any;
  xAxisCount?: any;
  resolutionTimeTooltip?: any;
  loading?: any;
}

const ResponseTimeAgent: React.FC<Props> = ({
  resolution_time,
  response_time,
  xAxisMinValue,
  responseTimeTooltip,
  xAxisCount,
  resolutionTimeTooltip,
  loading,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }} className="graph_container">
      <GrahWrapper>
        <div>
          <p className="time_stats">
            <TextTitle>Time Stats</TextTitle>
            <AppToolTip
              divStyle={{ padding: "0 8px" }}
              tooltipProps={{
                title: "Agent time performance metrics",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
            />
          </p>
        </div>
        <div className="progess_bar">
          <div className="progress_bar_text">
            {
              loading ? <SkeletonCard /> :
                <p className="header_avg">Average Resolution Time</p>
            }
          </div>
          {loading ? (
            <SkeletonProgressBar />
          ) : (
            <div className="progress_bar_count">
              <p className="stats">{formatTime(resolutionTimeTooltip)}</p>
              <BorderLinearProgress2
                variant="determinate"
                value={
                  (resolution_time / (xAxisMinValue * xAxisCount)) * 100 || 0
                }
              />
            </div>
          )}
        </div>
        <div className="progess_bar">
          <div className="progress_bar_text">
            {
              loading ? <SkeletonCard /> :
                <p className="header_avg">Avg. Conversation Duration</p>
            }
          </div>

          {loading ? (
            <SkeletonProgressBar />
          ) : (
            <div className="progress_bar_count">
              <p className="stats">{formatTime(responseTimeTooltip)}</p>
              <BorderLinearProgress3
                variant="determinate"
                value={
                  (response_time / (xAxisMinValue * xAxisCount)) * 100 || 0
                }
              />
            </div>
          )}
        </div>
      </GrahWrapper>
    </Box>
  );
};

export default ResponseTimeAgent;
