import createMentionPlugin from "@draft-js-plugins/mention";
import { IconButton } from "@mui/material";
import {
  DraftHandleValue,
  EditorState,
  KeyBindingUtil,
  RichUtils,
  getDefaultKeyBinding,
} from "draft-js";
import { getIn, useFormikContext } from "formik";
import React, {
  Fragment,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAppSelector } from "../../../../../Store";
import { LiveChatData } from "../../../../../Store/Slices/socket/liveChat/chat.selectors";
import { AppMaterialIcons } from "../../../Icons";
import AppErrorMessage from "../../AppErrorMessagesField";
import AppSendSubmitButton from "../../AppSendSubmitButtonFields";
import {
  StyledInputRichText,
  StyledInputWrapper,
  StyledUploadedMediaBox,
  StyledWidgetInputBoxExtended,
} from "./EditorStyles";
import EditorToolbar from "./EditorToolbar";
import MentionEntry from "./mentions";
import {
  filterEditorCannedResponsesSearch,
  getHTMLContent,
  getInitialEditiorText,
  getMentionCustomNameKeys
} from "./utils";
import uniqid from "uniqid";
import { allMessagesStore } from "../../../../../Store/Slices/socket/AllMessagesSlice";
const { hasCommandModifier } = KeyBindingUtil;

interface Props {
  name?: string;
  label?: string;
  toolbarOptions?: string[];
  disabled?: boolean;
  [otherProps: string]: any;
}

const AppFlowWebLiveChat: React.FC<Props> = ({
  name = "livechat",
  label = "Shift + Enter for new line. Start with '/' to select a canned response.",
  toolbarOptions = [
    "BOLD",
    "ITALIC",
    "UNDERLINE",
    "STRIKETHROUGH",
    "unordered-list-item",
    "ordered-list-item",
    "EMOJI",
    // "LINK",
    "MEDIA",
  ],
  disabled = false,
  ...otherProps
}) => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    submitCount,
  } = useFormikContext<any>();
  const editorRef = useRef<any>(null);
  const { allCannedResponsesData } = useAppSelector(LiveChatData);
  const {attachedReplyId  } = useAppSelector(allMessagesStore);
  const [editorState, setEditorState] = useState(() =>
    getInitialEditiorText(getIn(values, name) || "")
  );
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<any[]>([]);

  React.useEffect(() => {
    if(!disabled){
      focusEditor();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!getIn(values, name)) {
      setEditorState(getInitialEditiorText(getIn(values, name) || ""));
    }
  }, [getIn(values, name)]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    focusEditor();
  }, [getIn(values, "media") , attachedReplyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const focusEditor = () => {
    if (editorRef?.current) {
      editorRef?.current?.focus();
    }
  };

  const handleEditorChange = useCallback((newEditorState: EditorState) => {
    setEditorState(newEditorState);
    getSetEditorValue(newEditorState);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const getSetEditorValue = (newEditorState: EditorState) => {
    const htmlText = getHTMLContent(newEditorState);
    setFieldValue(name, htmlText);
    return;
  };

  const myKeyBindingFn = (e: React.KeyboardEvent<Element>) => {
    focusEditor();
    if ((e?.key === "b" || e?.key === "B") && hasCommandModifier(e)) {
      return "myeditor-bold";
    }
    if ((e?.key === "i" || e?.key === "I") && hasCommandModifier(e)) {
      return "myeditor-italic";
    }
    if ((e?.key === "u" || e?.key === "U") && hasCommandModifier(e)) {
      return "myeditor-underline";
    }

    if (e?.key === "Enter" /* `Enter` key */) {
      if (e?.shiftKey) {
        //* add new block
      } else {
        return "submit-form";
      }
    }
    return getDefaultKeyBinding(e);
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === "myeditor-bold") {
      handleEditorChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));
      return "handled";
    }
    if (command === "myeditor-italic") {
      handleEditorChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
      return "handled";
    }
    if (command === "myeditor-underline") {
      handleEditorChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
      return "handled";
    }

    if (command === "submit-form") {
      focusEditor(); //* do not remove this line it breaks the editor
      handleSubmit();
      return "handled";
    }

    return "not-handled";
  };

  //* handle canned response suggestions

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionComponent(mentionProps: any) {
        return (
          <span
            className={mentionProps.className +  " mentionedtext"}
            key={uniqid()}
          >
            {mentionProps.children}
          </span>
        );
      },
      mentionTrigger: "/",
      mentionPrefix: "",
      entityMutability: "MUTABLE",
      supportWhitespace: true,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((newValue: boolean) => {
    setOpen(newValue);
  }, []);
  const onSearchChange = useCallback(
    ({ trigger, value }: { trigger: string; value: string }) => {
      if (!value)
        return setSuggestions(getMentionCustomNameKeys(allCannedResponsesData));

      const result = filterEditorCannedResponsesSearch(
        getMentionCustomNameKeys(allCannedResponsesData),
        value
      );
      if (result?.length > 0) {
        setSuggestions(result);
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Fragment>
      {getIn(values, "media")?.name && getIn(values, "media")?.source ? (
        <StyledUploadedMediaBox>
          <p>{getIn(values, "media")?.name}</p>
          <IconButton
            title={"File"}
            onClick={() => setFieldValue("media", null)}
          >
            <AppMaterialIcons iconName={"Close"} />
          </IconButton>
        </StyledUploadedMediaBox>
      ) : null}
      <StyledWidgetInputBoxExtended
        disbaled={
          getIn(errors, name) && getIn(touched, name) && submitCount > 0
        }
      >
        <StyledInputWrapper onClick={() => focusEditor()}>
          <StyledInputRichText
            editorKey={"editor"}
            editorState={editorState}
            onChange={handleEditorChange}
            plugins={plugins}
            ref={editorRef}
            keyBindingFn={(e: any) => myKeyBindingFn(e)}
            handleKeyCommand={handleKeyCommand}
            onBlur={() => {
              setFieldTouched(name, true);
            }}
            placeholder={label}
            stripPastedStyles={true}
            spellCheck={true}
            // name={name}
          />
        </StyledInputWrapper>
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={allCannedResponsesData?.length > 0 ? suggestions : []}
          onSearchChange={onSearchChange}
          entryComponent={MentionEntry}
          // popoverContainer={({ children }: any) => {
          //   return <div className="mentionBoxWrapper">{children}</div>;
          // }}
        />

        <EditorToolbar
          editorState={editorState}
          setEditorState={setEditorState}
          handleEditorChange={handleEditorChange}
          toolbarOptions={toolbarOptions}
          focusEditor={focusEditor}
        />
        <AppErrorMessage
          style={{ top: "-25px" }}
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
        <AppSendSubmitButton style={values[name] ? {} : { color: "#7E8392" }} />
      </StyledWidgetInputBoxExtended>
    </Fragment>
  );
};

export default memo(AppFlowWebLiveChat);
