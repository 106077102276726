import React from "react";
import { Formik, FormikProps } from "formik";
interface Props {
  initialValues: any;
  onSubmit: any;
  validationSchema?: any;
  children: React.ReactNode;
  innerRef?: any;
  validateOnChange?: boolean;
  divStyle?: React.CSSProperties;
  id?: string;
  disabled?: boolean;
  [otherProps: string]: any;
  enableReinitialize?: boolean;
}

const AppForm: React.FC<Props> = ({
  initialValues,
  onSubmit,
  validationSchema,
  innerRef,
  children,
  id = "",
  divStyle,
  validateOnChange = true,
  disabled = false,
  ...otherProps
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
      innerRef={innerRef}
      validateOnChange={validateOnChange}
      {...otherProps}
    >
      {(props: FormikProps<any>) => {
        return (
          <form
            onSubmit={props.handleSubmit}
            id={id}
            style={divStyle}
            className={`formikFormWrapper ${disabled ? "disableEntireForm" : ""}`} //DO NOT USE disableEntireForm for styling purpose only used to identify this component and disable conditional rendering
          >
            {children}
          </form>
        );
      }}
    </Formik>
  );
};

export default AppForm;
