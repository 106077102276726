import { createSlice } from "@reduxjs/toolkit";
import { UserActions } from "./user.action";

interface initialStateTypes {
  data: {
    allUserListing: any;
    allUserListingCount: any;
    userData: any;
    userRoles: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    allUserListing: "",
    allUserListingCount: "",
    userData: "",
    userRoles: "",
  },
  error: "",
  loading: false,
};

const UserSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Listing Of Users
      .addCase(UserActions.getUserListing.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(UserActions.getUserListing.fulfilled, (state, action) => {
        state.data.allUserListing = action?.payload?.data || [];
        state.data.allUserListingCount = action?.payload?.data?.count || 0;
      })
      .addCase(UserActions.getUserListing.rejected, (state, action) => {
        state.loading = false;
        state.data.allUserListing = null;
        state.error = action?.error?.message || "";
      })

      //  Get Signle User Data
      .addCase(UserActions.getUserByID.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(UserActions.getUserByID.fulfilled, (state, action) => {
        state.data.userData = action.payload?.data?.data || [];
        state.error = "";
        state.loading = false;
      })
      .addCase(UserActions.getUserByID.rejected, (state, action) => {
        state.loading = false;
        state.data.userData = null;
        state.error = action?.error?.message || "";
      })

      //  Get  User Role
      .addCase(UserActions.getUserROles.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(UserActions.getUserROles.fulfilled, (state, action) => {
        state.data.userRoles = action?.payload?.data?.data || [];
        state.error = "";
        state.loading = false;
      })
      .addCase(UserActions.getUserROles.rejected, (state, action) => {
        state.loading = false;
        state.data.userRoles = null;
        state.error = action?.error?.message || "";
      });
  },
});

export default UserSlice.reducer;
