import { StyledTyping } from "../../Styles/StyledComponents";


interface Props {
  userMessage?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppTyping: React.FC<Props> = ({ userMessage }) => {
  return (
    <StyledTyping userMessage={userMessage}>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
      <div className="typing__dot"></div>
    </StyledTyping>
  );
};

export default AppTyping;
