import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../Customization/Theme";
import { Title } from "./AddContactStyles";
import styled from "styled-components";
import AppDrawer from "../../../AppComponents/AppDrawer";
import GridSkeletonLoader from "../../../AppComponents/Loader/GridSkeletonLoader";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import {
  ContactsAddFields,
  contactEditId,
  editContactsAddFields
} from "../../../Store/Slices/Contacts/contacts.selector";
import {
  UnmountEditContact,
  editId,
} from "../../../Store/Slices/Contacts/contacts.slice";
import RenderDynamicFields from "./FieldsLogic/RenderDynamicFields";
interface AddContactProps {
  isShow: boolean;
  toggle: () => void;
  setShouldApply: any;
  type?: any;
  setType?: any;
  leadRefresh?: any;
}

const AddContactsForm: React.FC<AddContactProps> = (props) => {
  const { isShow, toggle, setShouldApply } = props;
  const AddContactsFields = useAppSelector(ContactsAddFields);
  const ConatctDetailsById: any = useAppSelector(editContactsAddFields);
  const { showAlert } = useNotification();

  const ContactId: any = useAppSelector(contactEditId);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);

  //
  const closeModal = () => {
    dispatch(UnmountEditContact());
    dispatch(editId(""));

    toggle();
  };

  useEffect(() => {
    if (isShow) {
      dispatch(ContactsActions.getAddContactsFields({}))
        .then((res) => {
          setLoading(false);
        }).catch((error: any) => {
          showAlert(
            error?.response?.statusText || "Enabled to fetch Data",
            "error"
          );
        }).finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow]);

  return (
    <AppDrawer
      open={isShow}
      onClose={() => undefined}
      sx={{
        "& .MuiPaper-root": { width: "650px" },
      }}
    >
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0.5rem 1rem",
          alignItems: "center",
        }}
      >
        <Title>{ContactId?.length ? "Edit Contact" : "Add Contact"}</Title>
        <CloseIcon
          onClick={() => closeModal()}
          sx={{
            color: theme?.palette?.default?.darkGrey,
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider />
      <FormWrapper>
        {!(AddContactsFields && AddContactsFields.length) || loading ? (
          <>
            <GridSkeletonLoader
              height={35}
              numberCols={1}
              numberRows={30}
              spacing={"0.5rem 1rem"}
              variant="rectangular"
            />
          </>
        ) : (
          <RenderDynamicFields
            AddContactsFields={AddContactsFields}
            ConatctDetailsById={ConatctDetailsById}
            ContactId={ContactId}
            cancel={closeModal}
              setShouldApply={setShouldApply}
              leadRefresh={props?.leadRefresh}
            // parentHeight="calc(100vh - 185px)"
          />
        )}
      </FormWrapper>
    </AppDrawer>
  );
};

export default AddContactsForm;

const FormWrapper = styled.div`
  width: 100%;
  height: calc(100% - 45px);
  form {
    padding: 10px 15px 0;
  }
`;
