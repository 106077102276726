import QuickReplies from "../Nodes/QuickReplies";
import {
  AlphanumericNode,
  ArticleNode,
  ButtonNode,
  CarouselNode,
  CustomNode,
  DateNode,
  DropdownNode,
  EmailNode,
  FeedbackNode,
  ImgFileNode,
  LanguageNode,
  NameNode,
  NumberNode,
  OTPNode,
  PhoneNode,
  TextNode,
  TextfieldNode,
  TimeNode,
  InteractiveMessage,
  InteractiveMessageList,
  WhatsappFlow,
} from "./../Nodes";

export const registerNodes: any = [
  {
    type: "start",
    name: "Hello",
    icon: "nodes",
    color: "#B8C4FF",
    isStart: true,
    category: null,
    displayComponent: null,
  },
  {
    type: "text",
    name: "Message",
    icon: "message",
    color: "#C8EF87",
    category: null,
    displayComponent: TextNode,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  {
    type: "name",
    name: "Name",
    icon: "user",
    category: "customer-details",
    color: "#BDD4FF",
    displayComponent: NameNode,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  {
    type: "phone-number",
    name: "Phone Number",
    icon: "phone",
    category: "customer-details",
    color: "#93E9C8",
    displayComponent: PhoneNode,
    childNodes: [
      {
        type: "otp",
        name: "OTP",
        icon: "otp",
        category: "customer-details",
        color: "#FFCC9C",
        displayComponent: OTPNode,
        parentNode: "phone-number",
      },
    ],
    supports: ["Web Bot", "messenger", "telegram"],
  },
  {
    type: "language",
    name: "Language",
    icon: "language",
    category: "customer-details",
    color: "#FFC0AC",
    displayComponent: LanguageNode,
    supports: ["Web Bot", "whatsapp", "messenger"],
  },
  {
    type: "email",
    name: "Email",
    icon: "email",
    category: "customer-details",
    color: "#FFB9C5",
    displayComponent: EmailNode,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  // {
  //   type: "article",
  //   name: "Article",
  //   icon: "article",
  //   category: "input-options",
  //   color: "#DFCBFF",
  //   displayComponent: ArticleNode,
  //   supports: ["Web Bot"],
  // },
  {
    type: "button",
    name: "Button",
    icon: "mouse",
    category: "input-options",
    color: "#FDDB84",
    displayComponent: ButtonNode,
    supports: ["Web Bot", "messenger", "telegram"],
    validation: {
      web: {},
      whatsapp: {
        maxLimit: 3,
        charLimit: 20,
        descriptionLimit: 72,
      },
      messenger: {
        maxLimit: 3,
        charLimit: 20,
      },
    },
  },
  {
    type: "keyboard_button",
    name: "Keyboard Button",
    icon: "mouse",
    category: "input-options",
    color: "#FDDB84",
    displayComponent: ButtonNode,
    supports: ["telegram"],
    validation: {
      web: {},
      whatsapp: {
        maxLimit: 3,
        charLimit: 20,
        descriptionLimit: 72,
      },
      messenger: {
        maxLimit: 3,
        charLimit: 20,
      },
    },
  },

  {
    type: "quick_replies",
    name: "Quick Actions",
    icon: "quickreply",
    category: "input-options",
    color: "#FDDB84",
    displayComponent: QuickReplies,
    supports: ["messenger"],
    validation: {
      web: {},
      whatsapp: {},
      messenger: {
        maxLimit: 13,
        charLimit: 20,
      },
    },
  },
  {
    type: "carousel",
    name: "Carousel",
    icon: "carousel",
    category: "input-options",
    color: "#B7D3FE",
    displayComponent: CarouselNode,
    supports: ["Web Bot", "messenger"],
  },
  {
    type: "alphanumeric",
    name: "Alphanumeric",
    icon: "alphanumeric",
    category: "input-options",
    color: "#FFD1AB",
    displayComponent: AlphanumericNode,
    supports: ["Web Bot", "whatsapp", "telegram"],
  },
  // {
  //   type: "date",
  //   name: "Date",
  //   icon: "date",
  //   category: "input-options",
  //   color: "#EDEF8A",
  //   displayComponent: DateNode,
  //   supports: ["Web Bot"],
  // },
  // {
  //   type: "feedback",
  //   name: "Feedback",
  //   icon: "feedback",
  //   category: "input-options",
  //   color: "#9DF8D7",
  //   displayComponent: FeedbackNode,
  //   supports: ["Web Bot", "whatsapp"],
  // },
  // {
  //   type: "dropdown",
  //   name: "Dropdown",
  //   icon: "dropdown",
  //   category: "input-options",
  //   color: "#91EAF5",
  //   displayComponent: DropdownNode,
  //   optionsLimit: null,
  //   supports: ["Web Bot", "whatsapp"],
  //   validation: {
  //     web: {},
  //     whatsapp: {
  //       maxLimit: 10,
  //     },
  //   },
  // },
  {
    type: "number",
    name: "Number",
    icon: "number",
    category: "input-options",
    color: "#ECB3DF",
    displayComponent: NumberNode,
    supports: ["Web Bot", "whatsapp", "telegram"],
  },
  {
    type: "img-file",
    name: "Img/File",
    icon: "file-attachment",
    category: "input-options",
    color: "#FFCC9C",
    displayComponent: ImgFileNode,
    allowedFiles: [
      {
        name: "Images",
        supportedExtensions: [
          {
            label: "jpg/jpeg",
            value: "image/jpeg",
          },
          {
            label: "png",
            value: "image/png",
          },
          {
            label: "svg",
            value: "image/svg+xml",
          },
          {
            label: "webp",
            value: "image/webp",
          },
          {
            label: "gif",
            value: "image/gif",
          },
        ],
      },
      {
        name: "Documents",
        supportedExtensions: [
          {
            label: "pdf",
            value: "application/pdf",
          },
          {
            label: "docx",
            value:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          },
          {
            label: "xlsx",
            value:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          {
            label: "csv",
            value: "text/csv",
          },
        ],
      },
    ],
    supports: ["Web Bot", "whatsapp"],
  },
  // {
  //   type: "custom",
  //   name: "Custom",
  //   icon: "custom",
  //   category: "input-options",
  //   color: "#C3D1FF",
  //   displayComponent: CustomNode,
  //   supports: ["Web Bot", "whatsapp", "messenger"],
  // },
  {
    type: "textfield",
    name: "Textfield",
    icon: "text-field",
    category: "input-options",
    color: "#9AE2B2",
    displayComponent: TextfieldNode,
    supports: ["Web Bot", "whatsapp", "messenger", "telegram"],
  },
  // {
  //   type: "time",
  //   name: "Time",
  //   icon: "time",
  //   category: "input-options",
  //   color: "#D6F1AA",
  //   displayComponent: TimeNode,
  //   supports: ["Web Bot"],
  // },
  {
    type: "interactive-message",
    name: "Interactive Message",
    icon: "message",
    color: "#C8EF87",
    category: null,
    displayComponent: InteractiveMessage,
    supports: ["whatsapp"],
    validation: {
      web: {},
      whatsapp: {
        maxLimit: 3,
        charLimit: 20,
        headerMaxLimit: 60,
        bodyMaxLimit: 1024,
        footerMaxLimit: 20,
        descriptionLimit: 72,
      },
    },
  },
  {
    type: "interactive-message-list",
    name: "Interactive Message List",
    icon: "message",
    color: "#C8EF87",
    category: null,
    displayComponent: InteractiveMessageList,
    supports: ["whatsapp"],
    validation: {
      web: {},
      whatsapp: {
        maxLimit: 10,
        charLimit: 20,
        headerMaxLimit: 60,
        bodyMaxLimit: 1024,
        footerMaxLimit: 20,
        buttonLabelMaxLimit: 20,
        descriptionLimit: 72,
      },
    },
  },
  {
    type: "flow-list",
    name: "Whatsapp flow",
    icon: "message",
    color: "#C8EF87",
    category: null,
    displayComponent: WhatsappFlow,
    supports: ["whatsapp"],
    validation: {
      web: {},
      whatsapp: {
        maxLimit: 10,
        charLimit: 20,
        headerMaxLimit: 60,
        bodyMaxLimit: 1024,
        footerMaxLimit: 20,
        buttonLabelMaxLimit: 20,
        descriptionLimit: 72,
      },
    },
  },
];
