import { RootState } from "../../../../index";

export const livechatDataStore = (state: RootState) => state?.livechat?.data;

export const cannedResponseListing = (state: RootState) => state?.livechat?.data?.allCannedMessagesData;
export const cannedResponseListingCount = (state: RootState) => state?.livechat?.data?.allCannedMessagesDataCount;

export const cannedSearchResponse = (state: RootState) => state?.livechat?.data?.searchedCannedMessage;
export const searchCannedMessageCount = (state: RootState) => state?.livechat?.data?.searchCannedMessageCount;

export const isWhatsappLoading = (state: RootState) => state?.livechat?.loading;
export const deletedCannedMessageData = (state: RootState) => state?.livechat?.data?.deletedCannedMessageData;
export const getCannedMessageData = (state: RootState) => state?.livechat?.data?.getCannedMessageData;
export const updatedCannedMessageData = (state: RootState) => state?.livechat?.data?.updatedCannedMessageData;