import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Box,
  Paper,
  TablePagination,
  Typography,
  debounce
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppRangeDatePicker from "../../AppComponents/AppRangeDatePicker";
import { AppForm } from "../../AppComponents/Forms";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import { ErrorComponent } from "../../AppComponents/Forms/Styles";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import {
  DefinedCustomDates
} from "../../Models/defaultSelectOptions";
import { useAppDispatch } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { SubscriptionActions } from "../../Store/Slices/Subscriptions/subscriptions.actions";
import {
  getPendingInvoiceStatus,
  invoicePaidStatus,
} from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import {
  resetInvoicePaidStatus,
  resetPendingInvoiceStatus,
} from "../../Store/Slices/Subscriptions/subscriptions.slice";
import { SubscriptionsNetworkService } from "../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import TemplateAPIERROR from "../../assets/images/noDatafound.svg";
import AddWallet from "../Menu/AddWallet";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import PayNowIfram from "./Pay_now/payNowIfram";
import { renderSkeleton } from "./const";
import {
  Button,
  DetailsTable,
  DocumentAndTextTableWrapper,
  LivePlanContainer,
  Scroll
} from "./style";
import { TableWrapper } from "../../TableStyle";
interface MenuProps extends HocBackdropLoaderProps {
  check: any;
  setCheck: any;
  callBackApi: any;
  invoiceList: any;
  totalInvoiceCount: number;
}
const ResponsiveTable: React.FC<MenuProps> = ({
  setLoading,
  check,
  setCheck,
  callBackApi,
  invoiceList,
  totalInvoiceCount,
}) => {
  const { userConfig } = useSelector(useAuthStore);
  const pendingInvoiceStatus = useSelector(getPendingInvoiceStatus);
  const invoicePaid = useSelector(invoicePaidStatus);
  const { data: authData } = useSelector(useAuthStore);
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    status: "All invoices",
    category: "",
    start_date: DefinedCustomDates?.last30Days?.startDate,
    end_date: DefinedCustomDates?.last30Days?.endDate,
    itemsPerPage: 10,
    currentPage: 0,
  });
  const [dateRange, setDateRange] = useState<any>({
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
  });
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [payNow, setPayNow] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceLink, setInvoiceLink] = useState("");
  const dispatch = useAppDispatch();
  // get invoice details
  const getPreviousData = () => {
    // setLoading(true)
    setShowSkeleton(true);
    callBackApi(localValues, dateRange);
    // dispatch(resetInvoicePlanList({}))
    // const payload: any = {
    //     start_date: format(dateRange.startDate, "yyyy-MM-dd"),
    //     end_date: format(dateRange.endDate, "yyyy-MM-dd"),
    //     limit: localValues?.itemsPerPage,
    //     offset: localValues?.currentPage + 1,
    //     filter_by: "",
    //     search: localValues?.search,
    //     customer_Id: authData?.data?.business?.subscription_data?.customer_id
    // };
    // dispatch(SubscriptionActions.getAllInvoiceList(payload))
    // const totalCountPayload: any = {
    //     start_date: format(dateRange.startDate, "yyyy-MM-dd"),
    //     end_date: format(dateRange.endDate, "yyyy-MM-dd"),
    //     filter_by: "",
    //     search: localValues?.search,
    //     customer_Id: authData?.data?.business?.subscription_data?.customer_id
    // };
    // dispatch(SubscriptionActions.getInvoiceCount(totalCountPayload))
  };
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };
  useEffect(() => {
    if (invoiceList?.data?.length) {
      setShowSkeleton(false);
      setLoading(false);
    }
  }, [invoiceList]);

  useEffect(() => {
    if (totalInvoiceCount === 0) {
      setShowSkeleton(false);
    }
  }, [totalInvoiceCount]);

  useEffect(() => {
    getPreviousData();
  }, [localValues, dateRange]);

  // view Invoice API
  const downloadInvoice = (invoice_Id: any) => {
    setLoading(true);
    SubscriptionsNetworkService.getInvoiceByID(invoice_Id)
      .then((res: any) => {
        setLoading(false);
        window.open(res?.data?.url, "_blank");
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => { });
  };
  const addBalance = (balance: number) => {
    setLoading(true);
    setCheck(false);
    let data = {
      subscription_id: userConfig?.payload?.subscription_id,
      addons: [
        {
          addon_code: "wallet",
          quantity: balance,
        },
      ],
      redirect_url: "/subscription/success",
    };
    SubscriptionsNetworkService.addCredits(
      data,
      userConfig?.payload?.subscription_id
    )
      .then((res: any) => {
        window.open(res?.data?.hostedpage?.url, "_self");
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getInvoiceLink = (invoice_id?: any) => {
    // setPayNow(true)
    setLoading(true);
    setInvoiceId(invoice_id);
    SubscriptionsNetworkService.getInvoicePaymentLink(invoice_id)
      .then((res: any) => {
        setInvoiceLink(res?.data?.response);
        setLoading(false);
        setPayNow(true);
        updateInvoiceStatus(invoice_id);
        setInvoiceId(invoice_id);
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateInvoiceStatus = (invoice_id?: any) => {
    let data = {
      subscription_id: userConfig?.payload?.subscription_id,
      business_id: authData?.data?.user?.business_id,
      customer_id: authData?.data?.business?.subscription_data?.customer_id,
      invoice_id: invoice_id ? invoice_id : invoiceId,
    };
    setTimeout(() => {
      dispatch(SubscriptionActions.updateSubscriptionCallbackData(data));
      dispatch(resetPendingInvoiceStatus(false));
    }, 2000);
  };
  const onClose = () => {
    setPayNow(false);
  };
  useEffect(() => {
    if (invoicePaid) {
      setPayNow(false);
      callBackApi(localValues, dateRange);
      dispatch(resetInvoicePaidStatus(false));
    }
  }, [invoicePaid]);
  useEffect(() => {
    if (pendingInvoiceStatus && payNow) {
      updateInvoiceStatus(invoiceId);
    }
  }, [pendingInvoiceStatus, payNow]);



  const header = [
    "Sr No",
    "Invoice #",
    "Date",
    "Total ( Incl. tax )",
    "Status",
    "Invoice",
    "Actions"
  ];

  const keyToHeaderMap: any = {
    "Invoice #": "invoice_number",
    "Date": "invoice_date",
    "Total ( Incl. tax )": "total",
    "Status": "status",
  };

  const colWidth: any = {
    "Sr No": "90",
    "Invoice #": "200",
    "Date": "120",
    "Total ( Incl. tax )": "200",
    "Status": "140",
    "Invoice": "120",
    "Actions": "200"
  };


  const renderColumns = (row: any, header: any, rowIndex: any) => {
    return <Box
        className="table-text"
        sx={{
          width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        }}
    >
       {
          header === "Actions" ? <Box display={"flex"} justifyContent={"flex-end"}>


                    {row?.status !== "paid" ? (
                        
                          <Button
                            minWidth={"100px"}
                            onClick={() => getInvoiceLink(row?.invoice_id)}
                          >
                            Pay Now
                          </Button>
                        
                      ) : (
                       "-"
                      )}
            

          </Box> :
          header === "Sr No" ?  <Box> {rowIndex + 1} </Box> :
          header === "Date" ?  <>{moment(row?.invoice_date).format("DD/MM/yyyy")}</> :
          header === "Total ( Incl. tax )" ? <>{`₹ ${row?.total}`}</> :
          header === "Invoice" ? <><a
          className="sample-csv-btn"
          onClick={() => downloadInvoice(row?.invoice_id)}
          style={{cursor: "pointer"}}
        >
          <FileDownloadOutlinedIcon
            style={{ color: "#5B73E8" }}
          />
        </a></> :
          row[keyToHeaderMap[header]]
          ? row[keyToHeaderMap[header]]
          : "-"
        }
    </Box>



}

  return (
    <>
      {/* <div style={{display:"flex"}}>
            <a style={{margin :"2.5rem 2rem 3rem",borderBottom :"4px solid #5B73E8",    maxWidth: "119px",textAlign:"center"}}> <Title  fontSize={16} color={"#7E8392"}>Credit Details</Title></a>
            <a style={{margin :"2.5rem 2rem 3rem",borderBottom :"4px solid #5B73E8",maxWidth: "119px",textAlign:"center"}}> <Title  fontSize={16} color={"#7E8392"}>Credit Details</Title></a>
            <a style={{margin :"2.5rem 2rem 3rem",borderBottom :"4px solid #5B73E8",maxWidth: "119px",textAlign:"center"}}> <Title  fontSize={16} color={"#7E8392"}>Credit Details</Title></a>
            </div>
            */}
      {/* <WebsiteWrap>
                <Tabs
                    className="website-tabs"
                    value={0}
                    //   onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {["Credit details", "credit usage", "Credit rate"].map((option: any, index: number) => (
                        <Tab label={option} id={`simple-tab-${index}`} key={index} />
                    ))}
                </Tabs></WebsiteWrap> */}
      <DetailsTable>
        <LivePlanContainer
          align={"flex-end"}
          gap={"8px"}
          // style={{ margin: "-2rem 0rem 2rem" }}
        >
          <AppForm
            initialValues={{ search: "" }}
            onSubmit={(values: any, submitProps: any) => { }}
            divStyle={{ width: "100%", paddingLeft: "10px" }}
          >
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Box>
              Total Invoices: <strong> {totalInvoiceCount}</strong>
              </Box>
            <Box display={"flex"}>
              <AppFormSearchField
                name="search"
                placeholder="Search"
                defaultBorder={true}
                onChange={(e: any) => {
                  handleSelectSearch(e, "search");
                  }}
                  clearCallback={(e: any) => clearCallback("", "search")}
                  divStyle={{
                    marginBottom: "0",
                  minWidth: "280px",
                  maxWidth: "280px",
                  }}
                  />
              <div className="right">
                <AppRangeDatePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  />
              </div>
                  </Box>
            </Box>
          </AppForm>
        </LivePlanContainer>





        <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 414px)"}>
          <div>
            <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                      key={index}
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ 
                          minWidth: colWidth[header] + "px",
                          maxWidth: header === "Sr No" ? "60px" : "auto"
                        }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                
                {/* Table Body */}
              </Box>

              <Box className="table-body">

              {!showSkeleton && totalInvoiceCount === 0 ? (
                <NoRecordsFound height="calc(100vh - 515px)" />
              ) : null}


            {showSkeleton ? (
                <>
                  {showSkeleton ? (
                    renderSkeleton()
                  ) : (
                    <ErrorComponent>
                      <Paper elevation={3} className="error-container">
                        <Box>
                          <img src={TemplateAPIERROR} alt="" />
                        </Box>
                        <Box className="err-content">
                          <Typography variant="h4">No Records Found</Typography>
                        </Box>
                      </Paper>
                    </ErrorComponent>
                  )}
                </>
              ) : (
                  <>
                    {invoiceList && invoiceList?.data?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className={`table-row`} >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ 
                                  minWidth: colWidth[header] + "px",
                                  maxWidth: header === "Sr No" ? "60px" : "auto"
                                }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header, rowIndex)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
          </div>
        </TableWrapper>


        


        {/* <Scroll>
          <DocumentAndTextTableWrapper>
            <Box className="table-header">
              <Box className="table-row">
                <Box className="col-head">Sr No</Box>
                <Box className="col-head">Invoice #</Box>
                <Box className="col-head">Date</Box>
                <Box className="col-head">Total ( Incl. tax )</Box>

                <Box className="col-head">Status</Box>
                <Box className="col-head">Invoice</Box>
                <Box className="col-head"></Box>
              </Box>
            </Box>
            <Box
              className="table-body"
              style={{ overflowY: "auto", height: "calc(55vh)" }}
            >
              {!showSkeleton && totalInvoiceCount === 0 ? (
                <NoRecordsFound />
              ) : null}
              {showSkeleton ? (
                <>
                  {showSkeleton ? (
                    renderSkeleton()
                  ) : (
                    <ErrorComponent>
                      <Paper elevation={3} className="error-container">
                        <Box>
                          <img src={TemplateAPIERROR} alt="" />
                        </Box>
                        <Box className="err-content">
                          <Typography variant="h4">No Records Found</Typography>
                        </Box>
                      </Paper>
                    </ErrorComponent>
                  )}
                </>
              ) : (
                invoiceList?.data?.map((item: any, index: any) => {
                  return (
                    <Box className="table-row" key={`${index}` + 1}>
                      <Box className="col-body">{index + 1}</Box>
                      <Box className="col-body">{item?.invoice_number}</Box>
                      <Box className="col-body">
                        {moment(item?.invoice_date).format("DD/MM/yyyy")}
                      </Box>
                      <Box className="col-body">{`₹ ${item?.total}`}</Box>
                      <Box
                        className="col-body"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.status}
                      </Box>
                      <Box className="col-body">
                        <a
                          className="sample-csv-btn"
                          onClick={() => downloadInvoice(item?.invoice_id)}
                        >
                          <FileDownloadOutlinedIcon
                            style={{ color: "#5B73E8" }}
                          />
                        </a>
                      </Box>
                        {item?.status !== "paid" ? (
                          <Box className="col-body">
                            <Button
                              minWidth={"79x"}
                              onClick={() => getInvoiceLink(item?.invoice_id)}
                            >
                              Pay Now
                            </Button>
                          </Box>
                        ) : (
                          <Box className="col-body"> </Box>
                        )}
                    </Box>
                  );
                })
              )}
            </Box>
          </DocumentAndTextTableWrapper>
        </Scroll> */}


       
      </DetailsTable >

      <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={localValues?.currentPage}
          count={Number(totalInvoiceCount)}
          rowsPerPage={localValues?.itemsPerPage}
        />

      <PayNowIfram
        open={payNow}
        invoiceLink={invoiceLink}
        onClose={() => onClose()}
      />
      <AddWallet check={check} setCheck={setCheck} addBalance={addBalance} />
    </>
  );
};

export default HocBackdropLoader(ResponsiveTable);
