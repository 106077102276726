
import { RootState } from "../../index";

export const whatsappDataStore = (state: RootState) => state.whatsapp.data;
export const isWhatsappLoading = (state: RootState) => state.whatsapp.loading;
export const testWhatsappModel = (state: RootState) => state.whatsapp.data.testWhatsappModel; 
export const DeleteError = (state: RootState) => state?.whatsapp?.data?.deleteError;
export const DeleteSuccess = (state: RootState) => state?.whatsapp?.data?.deleteSuccess;
export const whatsappTemplateListing = (state: RootState) => state.whatsapp.data.allTemplatesData;
export const whatsappEnabled = (state: RootState) => state.whatsapp.data?.whatsappEnable;
export const getBroadcastRecipientFilterData = (state: RootState) => state.whatsapp.data.broadcastRecipientFilterData;
export const getTemplateButtonsLengthChecker = (state: RootState) => state.whatsapp.data.templateButtonsLengthChecker;
