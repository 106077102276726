import { Box, Grid } from "@mui/material";
import { useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { AppFormField, AppFormSelectField } from "../../Forms";
import { listSourceNameOptions, listTypeOptions } from "../Constants";

interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
  type: any;
}

const RenderFormStep1: React.FC<Props> = ({ formStep, setFormStep, type }) => {
  const { showAlert } = useNotification();
  const { setFieldTouched, setFieldValue, errors, values, validateForm, setTouched, touched } =
    useFormikContext<any>();

  const formkey1 = "name";
  const formkey2 = "list_source_name";
  const formkey3 = "list_type";

  useEffect(() => {
    setFieldValue(formkey3, type);
    setTouched(touched, true);
  }, []);

  if (formStep !== 1) {
    return null;
  }
  return (
    <Box className="form-first__area" sx={{}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <AppFormField
            label="List name"
            name={formkey1}
            placeholder="Enter list name"
            disabled={formStep !== 1 ? true : false}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AppFormSelectField
            label="List source name"
            Options={listSourceNameOptions}
            name={formkey2}
            // disabled={formStep !== 1 ? true : false}
            disabled={true}
            defaultIndexValue={0}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <AppFormField
            label="List Type"
            name={formkey3}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default RenderFormStep1;
