import styled from "styled-components";
import { theme } from "../../Customization/Theme";

export const StyledQueryBuilderWrap = styled.div`
  && {
    background-color: white;
    padding: 0px 0px 0px 0px;
    .queryBuilder {
    }
    .multiSelectContainer {
      .react-select__control {
        min-width: 250px !important;
      }
      .react-select__indicators {
        height: 30px !important;
      }
      .react-select__multi-value {
        margin: 0 2px;
        height: 22px;
      }
      .react-select__value-container {
        height: 28px;
      }
      .react-select__input-container {
        margin: 0;
      }
      .chip .MuiIconButton-root {
        width: 80px;
        font-size: 11px;
        padding: 2px;
      }
    }
    .ruleGroup {
      display: flex;
      flex-direction: column;
      padding: 0rem 0rem;
      gap: 0.5rem;
      border-color: transparent;
      border-style: none;
      border-radius: 0.25rem;
      border-width: 1px;
      background: none;
      height: calc(100vh - 154px);
      overflow-y: auto;

      .ruleGroup-body {
        gap: 1rem;
        button.removeRule {
          border: 1px solid ${theme.palette.default.darkGrey} !important;
          box-shadow: none !important;
        }

        button.removeRule:hover {
          background-color: ${theme.palette.primary.main} !important;
        }

        .MuiInputBase-root {
          background-color: ${theme.palette.default.lightGrey} !important;
          padding-left: 12px;
        }
      }

      .ruleGroup-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: ${theme.palette.default.white};
        .addRule {
          border: 1px solid ${theme.palette.default.darkGrey} !important;
          box-shadow: none !important;
          color: ${theme.palette.default.darkGrey};
        }
        .combinators {
          border: 1px solid ${theme.palette.default.darkGrey} !important;
          border-radius: 6px !important;
          .MuiSelect-select {
            padding-left: 8px;
            height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: ${theme.palette.default.darkGrey} !important;
          }
        }
      }
    }

    .header {
      display: flex;
      gap: 2rem;
      align-items: center;
      padding: 0rem 0rem;
    }
    .ruleGroup-addGroup {
      display: none;
    }
    .ruleGroup-lock {
      display: none;
    }
    fieldset {
      display: flex !important;
    }
    .addRule {
      word-break: keep-all;
      white-space: nowrap;
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 40px;
      height: 40px;
      padding: 6px 12px;
      border-radius: 6px;
      background-color: transparent;
      color: ${theme.palette.primary.main};
      border: none;
      outline: none;
      cursor: pointer;
      font-family: ${theme.typography.fontFamily};
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      &:disabled {
        color: ${theme.palette.default.grey};
        span,
        i {
          color: ${theme.palette.default.grey} !important;
        }
      }
      &:hover {
        color: ${theme.palette.primary.dark};
      }
      svg {
        font-size: 18px;
        margin: 0 4px;
      }
    }

    .combinators {
      width: 100px;
      /* display: none; */
    }

    .body {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    .rule {
      display: flex;
      gap: 1rem;
      align-items: center;
      border: 1px solid transparent;
      position: relative;
      .remove-rule__btn {
        position: absolute;
        right: 8px;
      }
      // react-select__option--is-selected
      .react-select-query-builder-field__option--is-selected {
        background-color: ${theme.palette.default.PastelCyan} !important;
      }
    }

    .queryBuilder-invalid {
      /* background-color: red; */
      /* border: 1px solid ${theme.palette.default.error}; */
    }

    .fields,
    .operators,
    .value,
    select,
    input,
    textarea {
      width: 100%;
    }

    input[type="checkbox"],
    input[type="radio"] {
      /* width: 100%;
      height: 20px; */
    }

    span,
    label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .queryBuilder-dragHandle {
      display: none;
    }

    .removeRule,
    .cloneRule,
    .lockRule {
      word-break: keep-all;
      white-space: nowrap;
      text-transform: capitalize;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 6px 12px;
      min-height: 34px;
      height: 34px;
      border-radius: 6px;
      background-color: ${theme.palette.default.white};
      color: ${theme.palette.default.darkGrey};
      border: none;
      outline: none;
      cursor: pointer;
      font-family: ${theme.typography.fontFamily};
      font-size: 1rem;
      font-weight: 500;
      line-height: 21px;
      border: 1px solid ${theme.palette.default.darkGrey};
      &:hover {
        color: ${theme.palette.default.white};
        background-color: ${theme.palette.default.error};
        i {
          color: ${theme.palette.default.white} !important;
        }
      }
      &:disabled {
        color: ${theme.palette.default.error};
        border: 1px solid ${theme.palette.default.error};
      }
      svg {
        font-size: 18px;
        margin: 0 4px;
      }

      /* &:nth-child(2n) {
        margin: 0 -1rem;
      } */
    }
  }
`;
export const StyledModelHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    height: 56px;
    min-height: 56px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h6 {
      font-size: 1.05rem;
      color: ${theme.palette.default.text};
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }

    .footer {
    }
  }
`;
export const StyledModelFooter = styled.div`
  && {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

    .footer-btns {
      justify-content: end;
      gap: 1.3rem;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      padding-right: 1rem;
      .saveBtn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
