const getResponseWithoutText = (node: any, language: string) => {
  let buttons = [];
  const lanResponse =
    language !== "english" ? `response_${language.toLowerCase()}` : "response";
  const response = node?.[lanResponse] ? node?.[lanResponse] : node?.response;
  if (response && response !== null && response.length > 0) {
    buttons = response.filter((item: any) => item.type !== "text");
  }
  return JSON.parse(JSON.stringify(buttons));
};

export default getResponseWithoutText;
