import { createSlice } from "@reduxjs/toolkit";
import { SegmentActions } from "./Segment.actions";


interface SegProps{
    data:{
        getAllSegmentData:any,
        createSegmentData:any,
        deletedSegmentData:any,
        allContactListData:any,
        segmentDataCount:any,
        getAllSegmentConatctData:any
    },
    error:boolean,
    loading:boolean
}


const initialState : SegProps={
    data:{
        getAllSegmentData :[],
        createSegmentData:null,
        deletedSegmentData:null,
        allContactListData: null,
        segmentDataCount:0,
        getAllSegmentConatctData:[]
    },
    error:false,
    loading:false
}



const SegmentSlice = createSlice({
    name : "SegmentSlice",
    initialState,
    reducers:{
        resetSegmentData: (state, action) => {
            return {
              ...state,
              getAllSegmentData: [],
            };
          },
    },
    extraReducers(builder) {
        //getAllSegmentListData
        builder.addCase(SegmentActions.getAllSegmentListData.pending , (state,action)=>{
            state.error = false;
            state.loading = true
        })

        .addCase(SegmentActions.getAllSegmentListData.fulfilled , (state,action)=>{
            let updatedData = action.payload?.data.response?.segment_list
            state.data.getAllSegmentData = updatedData
            state.data.segmentDataCount = action.payload?.data.response?.pagination
            state.error = false;
            state.loading = false;
        })
        .addCase(SegmentActions.getAllSegmentListData.rejected , (state,action)=>{
            state.data.getAllSegmentData = []
            state.error = true;
            state.loading = false;
        })


        //AllContactData

        .addCase(SegmentActions.getAllSegmentConatctListData.pending , (state,action)=>{
            state.error = false;
            state.loading = true
        })

        .addCase(SegmentActions.getAllSegmentConatctListData.fulfilled , (state,action)=>{
            state.data.getAllSegmentConatctData = action.payload?.data
            state.error = false;
            state.loading = false;
        })
        .addCase(SegmentActions.getAllSegmentConatctListData.rejected , (state,action)=>{
            state.data.getAllSegmentConatctData = []
            state.error = true;
            state.loading = false;
        })

        //createSegment
        .addCase(SegmentActions.createSegment.pending , (state,action)=>{
            state.error = false;
            state.loading = true
        })
        .addCase(SegmentActions.createSegment.fulfilled, (state,action)=>{
            state.data.createSegmentData = action.payload
            state.error = false;
            state.loading = false;
        })
        .addCase(SegmentActions.createSegment.rejected , (state,action)=>{
            state.error = true;
            state.loading = false;
        })
        //deleteSegmentById
        .addCase(SegmentActions.deleteSegmentById.pending , (state,action)=>{
            state.error = false;
            state.loading = true;
        })
        .addCase(SegmentActions.deleteSegmentById.fulfilled , (state,action)=>{
            state.data.deletedSegmentData = action.payload.data;
            state.error = false;
            state.loading = false;
        })
        .addCase(SegmentActions.deleteSegmentById.rejected , (state,action)=>{
            state.error = true;
            state.loading = false;
        })
    },

})
export const { resetSegmentData } = SegmentSlice.actions;
export default SegmentSlice.reducer