import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { FC } from "react";
import { CSS } from "@dnd-kit/utilities";
import { v4 as uuid } from "uuid";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";

interface SortableItemProps {
  obj?: any;
  elementTemp?: any;
  index: any;
}
interface DragAndDropProps {
  listData?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const SortableItem: FC<SortableItemProps> = ({ elementTemp, obj, index }) => {
  const id = obj?.id ? obj?.id : obj;
  const { setNodeRef, listeners, transform, transition, isDragging } =
    useSortable({ id });
  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      {elementTemp(
        obj,
        index,
        setNodeRef,
        listeners,
        styles,
        restrictToVerticalAxis,
        restrictToWindowEdges,
        isDragging
      )}
    </>
  );
};

export default function AppDragAndDrop(props: DragAndDropProps): JSX.Element {
  const { element, listData, ...otherProps } = props;
  const [items, setItems] = useState(listData);
  console.log("RulesList", listData)
  // update in each time**********
  useEffect(() => {
    setItems(listData);
  }, [listData]);

  // updated list
  useEffect(() => {
    if (otherProps && otherProps?.setUpdatedColumn) {
      otherProps?.setUpdatedColumn(items);
    }
  }, [items]); // eslint-disable-line

  useEffect(() => {
    setItems(listData);
  }, [listData]);
  function dragEndEvent(e: DragEndEvent) {
    // console.log("dragabble")
    const { over, active } = e;
    if (otherProps && otherProps?.setDragedPosition) {
      otherProps?.setDragedPosition({ over: over, active: active });
    }
    setItems((element: any) => {
      return arrayMove(
        element,
        active?.data?.current?.sortable.index,
        over?.data?.current?.sortable.index
      );
    });
  }
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );

  return (
    <div>
      <DndContext
        onDragEnd={dragEndEvent}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
        sensors={sensors}
      >
        <SortableContext items={items}>
          {items?.map((v: any, index: any) => (
            <SortableItem
              key={uuid()}
              obj={v}
              elementTemp={element}
              index={index}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
