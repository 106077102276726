import { theme } from "../../Customization/Theme";
import { DEVICE_SIZE } from "./BreakPoints";
import { MESSAGE_TYPES } from "./Enum";

let audio: any = new Audio(require("../../assets/tong.mp3"));
export const playSoundLastBotMsg = () => {
  audio.volume = 1;
  audio.play();
};

export const handleURLClick = (url: any) => {
  window.open(url, "_blank");
};

export const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)},${parseInt(
        result[3],
        16
      )}`
    : null;
};

export const extractPreviousBtnConversationId = (
  originalNodeResponses: any[],
  item: any
) => {
  const data_filter: any = originalNodeResponses.filter(function (
    element: any,
    index: number
  ) {
    return element?.response?.some(function (subElement: any, index4: number) {
      return (
        subElement?.type === MESSAGE_TYPES.FLOW && subElement.id === item?.id
      );
    });
  });

  let foundConversationId = data_filter[0]?.conversation_id;

  return foundConversationId;
};

export const stringToRegex = (str: any) => {
  // Main regex
  const main = str?.match(/\/(.+)\/.*/)[1];

  // Regex options
  const options = str?.match(/\/.+\/(.*)/)[1];

  // Compiled regex
  return new RegExp(main, options);
};

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0;
};

export const getText = (response: any) => {
  let text = "";
  if (response && response !== null && response.length > 0) {
    const match = response.filter(
      (item: any) => item.type === "text" || item.type === "carousel"
    );
    if (match.length > 0) {
      if (match?.[0]?.value) {
        if (Array.isArray(match?.[0]?.value) && match?.[0]?.value?.length > 0) {
          text = match?.[0]?.value?.[0].text;
        } else {
          text = match?.[0]?.value;
        }
      } else if (match?.[0]?.text) {
        text = match?.[0]?.text;
      } else {
        text = "";
      }
    }
  }
  return text;
};

export const getChatbotWidth = (size?: number) => {
  if (size && size < DEVICE_SIZE.tablet) {
    let temp = size - 16;
    return temp;
  } else {
    return 448;
  }
};

export const getChatbotHeight = (
  deviceWidth?: number,
  deviceHeight?: number
) => {
  if (deviceWidth && deviceHeight && deviceWidth < DEVICE_SIZE.tablet) {
    let temp = deviceHeight - 30;

    return temp;
  } else {
    if (deviceHeight && deviceHeight < 590) {
      let temp = deviceHeight - 100;

      return temp;
    }
    return 590;
  }
};

export const titlize = (str: string) => {
  return str
    ? str
        ?.toLowerCase()
        ?.split(" ")
        ?.map((word) => {
          return word.replace(word[0], word[0].toUpperCase());
        })
        ?.join(" ")
    : "";
};

export const secondsToHms = (value: string) => {
  const sec = parseInt(value, 10);
  let hours: string | number = Math.floor(sec / 3600);
  let minutes: string | number = Math.floor((sec - hours * 3600) / 60);
  let seconds: string | number = sec - hours * 3600 - minutes * 60;
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (hours === 0) {
    return minutes + ":" + seconds; // Return in MM:SS format
  } else {
    return hours + ":" + minutes + ":" + seconds; // Return in HH:MM:SS format
  }
};

export const getMessageMediaType = (type: string) => {
  if (type === "image") {
    return MESSAGE_TYPES.IMAGE;
  } else if (type === "document") {
    return MESSAGE_TYPES.FILE;
  } else if (type === "video") {
    return MESSAGE_TYPES.VIDEO;
  } else if (type === "audio") {
    return MESSAGE_TYPES.AUDIO;
  } else {
    return MESSAGE_TYPES.FILE;
  }
};

export const totalUnreadMsg = (data: any = []) => {
  let result = 0;
  data?.forEach((item: any) => {
    if (item?.count) {
      result = result + item?.unread_count;
    }
  });
  return result;
};

interface MyObject {
  [key: string]: any;
}

export const convertArrayToStringArray = (
  arr: MyObject[],
  key: string
): string[] => {
  return arr?.length > 0 ? arr?.map((obj: any) => String(obj?.[key])) : [];
};

export const searchTextAndScrollUseRef = (
  ulRef: React.RefObject<HTMLUListElement>,
  searchText: string
) => {
  if (ulRef?.current) {
    const liElements = ulRef.current?.getElementsByTagName("p");

    for (let i = 0; i < liElements.length; i++) {
      liElements[i].style.backgroundColor = "transparent";
      const liText = liElements[i].textContent?.toLowerCase() || "";

      if (liText.includes(searchText?.toLowerCase())) {
        liElements[i].style.backgroundColor = theme.palette.default.success;
        // liElements[i].style.color = theme.palette.default.white;
        liElements[i].scrollIntoView({ behavior: "smooth", block: "center" });
        break;
      }
    }
  }
};
export const clearStylingSearchTextAndScrollUseRef = (
  ulRef: React.RefObject<HTMLUListElement>
) => {
  if (ulRef?.current) {
    const liElements = ulRef.current?.getElementsByTagName("p");
    for (let i = 0; i < liElements.length; i++) {
      liElements[i].style.backgroundColor = "transparent";
    }
  }
};

export const getCurrentObjectArrayById = (
  array: any,
  id: string,
  valueKey: string = "id",
  returnAsArray: boolean = true
) => {
  let obj = array?.find((item: any) => item?.[valueKey] === id);
  return returnAsArray ? [obj] : obj;
};

export const getCurrentChatTabId = (
  newValue: number,
  isUserAdmin: boolean = false
): string => {
  if (isUserAdmin) {
    if (newValue === 0) return "all";
    if (newValue === 1) return "mine";
    if (newValue === 2) return "unassigned";
  } else {
    if (newValue === 0) return "mine";
    if (newValue === 1) return "unassigned";
  }

  return "all";
};

export const getChatCardConversationStatus = (
  status: "unassigned" | "assigned" | "unattended",
  activeId: string
) => {
  if (
    status === "assigned" &&
    activeId !== "unassigned" &&
    activeId !== "unattended"
  ) {
    return true;
  }

  if (status === "unassigned" && activeId === "unassigned") {
    return true;
  }

  if (status === "unattended" && activeId === "unattended") {
    return true;
  }

  return false;
};

export const getFileNameFromUrl = (url: string) => {
  let result = url?.substring(url?.lastIndexOf("/") + 1);

  return result || "Download";
};

export const findObjectInArray = (
  array: MyObject[],
  value: any,
  keyName: string
): any | null => {
  const foundObject = array?.find((obj: any) => obj?.[keyName] === value);
  return foundObject || null;
};

export const objectExistsInArray = (
  array: MyObject[],
  value: any,
  keyName: string
): boolean => {
  const foundObjects = array?.filter((obj: any) => obj?.[keyName] === value);
  return Object.keys(foundObjects)?.length > 0 ? true : false;
};

export const getChatSessionFilterPayloadData = (filter: any) => {
  let temp = {
    type: "filter",
    label: convertArrayToStringArray(filter?.labels, "id") || [],
    state:
      convertArrayToStringArray(filter?.conversation_status, "value") || [],
    agent: convertArrayToStringArray(filter?.agents, "id") || [],
    status: convertArrayToStringArray(filter?.chat_status, "value") || [],
    channel: filter?.channels || [],
    sortby: filter?.order?.value || "recent",
    conversation: filter?.conversation ?? null,
    search: filter?.search ?? null,
  };
  return temp;
};

export const getChatAppliedFiltersCount = (filter: any) => {
  let count = 0;

  if (filter?.labels?.length > 0) count++;

  if (filter?.conversation_status?.length > 0) count++;

  if (filter?.agents?.length > 0) count++;

  if (filter?.chat_status?.length > 0) count++;

  if (filter?.channels?.length > 0) count++;

  if (filter?.search?.length > 0) count++;

  if (filter?.order?.value === "old") count++;

  if (
    filter?.conversation?.length > 1 &&
    filter?.conversation !== "all" &&
    filter?.conversation !== "mine"
  )
    count++;

  // if (filter?.conversation === "all") count = 0; 
  if (filter?.chat_status?.length === 0 && filter?.conversation === "all") count = 0; // MODIFIED DUE TO SHOW DEFAULT OPEN STATUS WITH ALL CHATS

  return count;
};

export const CheckAppliedFilterMatchesIncomingSession = (
  filter: any,
  newSession: any
) => {
  let check: boolean = false;

  //check for channels
  if (
    filter?.labels?.length === 0 &&
    filter?.agents?.length === 0 &&
    !filter?.conversation &&
    filter?.channels?.length > 0
  ) {
    if (newSession?.botid?.length > 0) {
      check = objectExistsInArray(filter?.channels, newSession?.botid, "value");

      return check;
    }
    if (newSession?.channel?.length > 0) {
      check = objectExistsInArray(
        filter?.channels,
        newSession?.channel,
        "value"
      );

      return check;
    }
  }

  //check for agents
  if (
    filter?.labels?.length === 0 &&
    filter?.channels?.length === 0 &&
    !filter?.conversation &&
    filter?.agents?.length > 0
  ) {
    if (newSession?.agent?.length > 0) {
      check = objectExistsInArray(filter?.agents, newSession?.agent, "id");

      return check;
    }
  }

  //check for conversation
  if (
    filter?.labels?.length === 0 &&
    filter?.channels?.length === 0 &&
    filter?.agents?.length === 0 &&
    filter?.conversation?.length > 1
  ) {
    if (
      (filter?.conversation === "all" || filter?.conversation === "mine") &&
      newSession?.conversation_state === "assigned"
    ) {
      check = true;

      return check;
    }
  }
  //check for open
  if (
    filter?.labels?.length === 0 &&
    filter?.channels?.length === 0 &&
    filter?.agents?.length === 0 &&
    !filter?.conversation &&
    filter?.chat_status?.length === 1
  ) {
    if (newSession?.conversation_status === "Open") {
      check = objectExistsInArray(
        filter?.chat_status,
        newSession?.conversation_status,
        "value"
      );
      return check;
    }
  }

  return check;
};

export const shortStringFn = (text: string, textLimit: number = 20) => {
  if (text?.length > textLimit) {
    return text?.substring(0, textLimit) + "...";
  }
  return text;
};

export const getObjectByidFromArray = (
  arr: any[],
  id: string,
  keyName: string = "message_id"
) => {
  if (arr?.length === 0) return null;

  const foundObject = arr?.find((obj: any) => obj?.[keyName] === id);

  return foundObject || null;
};

export const getMessageTextFromMessage = (value: any, type: string) => {
  let text = "";

  if (type === MESSAGE_TYPES.TEXT) {
    text = value;
  }

  if (type === MESSAGE_TYPES.CAROUSEL) {
    text =
      value?.[0]?.image ||
      value?.[0]?.title ||
      value?.[0]?.subtitle ||
      "Carousel";
  }

  if (
    type === MESSAGE_TYPES.IMAGE ||
    type === MESSAGE_TYPES.FILE ||
    type === MESSAGE_TYPES.VIDEO ||
    type === MESSAGE_TYPES.ATTACHMENT ||
    type === MESSAGE_TYPES.DOCUMENT ||
    type === MESSAGE_TYPES.AUDIO
  ) {
    text = value?.url || value?.text || type;
  }

  return text;
};

export const generateRandomColor = (opacity = 0.4) => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  const hexColor = `#${randomColor}`;
  const rgbaColor = `rgba(${parseInt(randomColor.slice(0, 2), 16)}, ${parseInt(
    randomColor.slice(2, 4),
    16
  )}, ${parseInt(randomColor.slice(4, 6), 16)}, ${opacity})`;
  return { hexColor, rgbaColor };
};

export const colorPairs = [
  {
    bg: "#0c13a2",
    textColor: "#fff",
  },
  {
    bg: "#ed7d31",
    textColor: "#fff",
  },
  {
    bg: "#F92A82",
    textColor: "#fff",
  },
  {
    bg: "#A52422",
    textColor: "#fff",
  },
  {
    bg: "#00e0a2",
    textColor: "#fff",
  },
];
export const generateColorPairs = (index: any) => {
  const backgroundColors = [
    "#dab4e5",
    "#fecad5",
    "#c55e58",
    "#f0ca86",
    "#dde59b",
    "#9ad7a4",
  ];
  const textColors = [
    "#8a5499",
    "#b46475",
    "#c55e58",
    "#8e7242",
    "#78812f",
    "#3c914a",
  ];

  if (index < 0 || index >= backgroundColors.length) {
    throw new Error("Invalid index for color pair");
  }

  return { bgColor: backgroundColors[index], textColor: textColors[index] };
};



export const stringToColor = (string: string) => {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}


export function getColorFromChar(char: string="U") {
  const backgroundColors = [
    "#dab4e5",
    "#fecad5",
    "#c55e58",
    "#f0ca86",
    "#dde59b",
    "#9ad7a4",
  ];
  const textColors = [
    "#8a5499",
    "#b46475",
    "#c55e58",
    "#8e7242",
    "#78812f",
    "#3c914a",
  ];

  const lowerChar = char?.toLowerCase() ?? "U";
  const index = lowerChar.charCodeAt(0) - 'a'.charCodeAt(0);
  const mappedIndex = index % backgroundColors.length;

  return { bgColor: backgroundColors[mappedIndex], textColor: textColors[mappedIndex] };
}


export async function sleep(ms:number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}