import * as React from "react";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { ContactsActions } from "../../../../../Store/Slices/Contacts/contacts.action";
import {
  contactSectionData,
  contactSectionFieldsData,
  contactSectionValuesData,
  getFieldSectionData,
} from "../../../../../Store/Slices/Contacts/contacts.selector";
import moment from "moment";
import { theme } from "../../../../../Customization/Theme";
import { useParams } from "react-router-dom";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader/LoaderComponent";
import { AppForm, AppSubmitButton } from "../../../../../AppComponents/Forms";
import RenderFields from "./RenderFields";
import DynamicsInputFields from "../../FieldsLogic/DynamicsFieldsLogic";
import RenderDynamicFields from "../../FieldsLogic/RenderDynamicFields";

interface Props extends LoaderComponentProps {
  setEditMode: any;
  selectedSectionID: string;
  updateData: any;
}
function EditContactsBasicInformation(props: Props) {
  const params = useParams();
  const formRef = React.useRef();
  const { contact_id } = params;
  const { setEditMode, selectedSectionID, setLoading } = props;
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const sectionData: any = useAppSelector(contactSectionData);
  const sectionFieldsData: any = useAppSelector(getFieldSectionData);
  const sectionFieldsValue: any = useAppSelector(contactSectionValuesData);
  const [userInputs, setUserInputs] = React.useState<any>({});
  // React.useEffect(() => {
  //   if (sectionFieldsData?.data?.length > 0) {
  //     let fieldValueObject: any = {};
  //     sectionFieldsData?.data.forEach((field: any) => {
  //       fieldValueObject[field.name] = field.value;
  //     });
  //     setUserInputs(fieldValueObject);
  //   }
  // }, [sectionFieldsData?.data]);

  React.useEffect(() => {
    let payload = {
      contact_id: contact_id,
      section_id: selectedSectionID,
    };
    dispatch(ContactsActions.getSectionFields(payload))
      .then(() => {})
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SectionContent>
      <RenderDynamicFields
        AddContactsFields={sectionFieldsData}
        ConatctDetailsById={sectionFieldsValue}
        ContactId={contact_id}
        cancel={setEditMode}
        updateData={props.updateData}
      />
    </SectionContent>
  );
}
export default withLoader(EditContactsBasicInformation);

const SectionContent = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 24px;
  }
`;
