// vendors
import { v4 as uuid } from "uuid";

// config
import { registerNodes } from "./../../config/register-nodes";
import { registerActions } from "../../config/register-actions";

const getRegisterNode = (registerNodes: any, type?: string) =>
  registerNodes.find((node: any) => type && node.type === type);

const getRegisterChildNode = (
  registerNodes: any,
  type?: string,
  childNodeType?: string
) => {
  const parentNode = registerNodes.find(
    (node: any) => type && node.type === type
  );
  return parentNode.childNodes.find(
    (node: any) => type && node.type === childNodeType
  );
};

const getIsStartNode = (registerNodes: any, type?: string) =>
  registerNodes.find((item: any) => item.type === type)?.isStart;

const getNodeConfig = (type: string, childNode?: string) => {
  let selectedNode = registerNodes.filter(
    (regNode: any) => regNode.type === type
  );
  if (childNode) {
    selectedNode = selectedNode[0].childNodes.filter(
      (regNode: any) => regNode.type === childNode
    );
  }

  return selectedNode[0];
};

const getActionConfig = (type: string, childNode?: string) => {
  let selectedNode = registerActions.filter(
    (regNode: any) => regNode.type === type
  );
  return selectedNode[0];
};

const groupByCategory = (options: any[]) => {
  const grouped = options.reduce((acc: any, currItem: any) => {
    const groupKey = currItem.category;
    if (!acc[groupKey]) {
      acc[groupKey] = [currItem];
    } else {
      acc[groupKey].push(currItem);
    }
    return acc;
  }, {});
  const newFormat = Object.keys(grouped).map((key) => ({
    name: key,
    children: grouped[key].map((groupItem: any) => ({
      ...groupItem,
    })),
  }));
  return newFormat;
};

const createNewNode = (
  registerNodes: any,
  type?: string,
  childNodeType?: string,
  mainType?: string
) => {
  const registerNode = getRegisterNode(registerNodes, type);
  if (!registerNode) return;
  if (childNodeType) {
    const registerChildNode = getRegisterChildNode(
      registerNodes,
      type,
      childNodeType
    );
    return {
      id: uuid(),
      type: registerChildNode.type,
      name: registerChildNode.name,
      text: registerChildNode.text,
      isValid: false,
      parentNode: registerChildNode.parentNode,
    };
  } else {
    let defaultResponse = {};
    let text = '';
    if (mainType === "action") {
      defaultResponse = {
        response: [{ type: "text", value: registerNode.defaultText }],
      };
      text = registerNode.defaultText;
    }
    return {
      id: uuid(),
      isAction: mainType === "action",
      type: registerNode.type,
      name: registerNode.name,
      text,
      isValid: false,
      childNodes: registerNode.childNodes,
      ...(mainType === "action" && defaultResponse)
    };
  }
};

const computeNodesPath = (nodes: any[], displayOrder: number) => {
  for (let index = 0; index < nodes.length; index++) {
    const node = nodes[index];
    node.displayOrder = index;
    node.stage = String(`${displayOrder}.${index + 1}`);
  }
  return nodes;
};

export {
  getNodeConfig,
  groupByCategory,
  getRegisterNode,
  getIsStartNode,
  createNewNode,
  computeNodesPath,
  getRegisterChildNode,
  getActionConfig,
};
