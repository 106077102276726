import {
  Accordion,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../../Customization/BreakPoints";
import { theme } from "../../Customization/Theme";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { formatString, getRegexesForAccordionOptions } from "../../Utils";

interface Props {
  Options: any;
}

const IntegrationsSidebar: React.FC<Props> = ({ Options }) => {
  const navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);
  const location = useLocation();

  var [selectedIndex, setSelectedIndex] = useState<any>(
    Options[0].options[0].id
  );
  var [selectedAccordion, setSelectedAccordion] = useState<any>(0);

  const getCurrentPathAfterRefresh = () => {
    const MenuOptionsWithRegexes = getRegexesForAccordionOptions(Options);

    const SelectedMenu = MenuOptionsWithRegexes.find(
      (o: any, index: number) => {
        return o.urlRegex.test(location.pathname);
      }
    );

    setSelectedIndex(SelectedMenu?.id);
    setSelectedAccordion(SelectedMenu?.categoryIndex);

  };
  useEffect(() => {
    getCurrentPathAfterRefresh();
  }, []);

  const handleAccordionClick = (item: any, index: number) => {
    selectedIndex = item?.options[0]?.id;
    setSelectedIndex(selectedIndex);
    setSelectedAccordion(index);
    handleRedirect(item?.options[0].url);
  };

  const handleClickListItem = (item: any, index: number) => {
    selectedIndex = item?.options[index]?.id;
    setSelectedIndex(selectedIndex);
    handleRedirect(item?.options[index].url);
  };

  const handleRedirect = (subUrl: string) => {
    const url = formatString(subUrl, ["currentBot.bot_id"]);
    navigate(url);
  };
  return (
    <SidebarWrap>
      <CustomList>
        {Options &&
          Options.length > 0 &&
          Options.map((item: any, index: number) => {
            if (item?.isAvailable) {
              return (
                <Accordion
                  key={index}
                  expanded={selectedAccordion === index ? true : false}
                >
                  <AccordionSummary
                    expandIcon={
                      <GridExpandMoreIcon
                        sx={{
                          color:
                            selectedAccordion === index
                              ? theme.palette.primary.main
                              : theme.palette.default.darkGrey,
                        }}
                      />
                    }
                    onClick={() => handleAccordionClick(item, index)}
                    sx={{
                      color:
                        selectedAccordion === index
                          ? theme.palette.primary.main
                          : theme.palette.default.darkGrey,
                    }}
                  >
                    {item.category}
                  </AccordionSummary>

                  {item.options.length > 0 &&
                    item.options.map((curElem: any, index2: number) => {
                      return (
                        <MenuList
                          variant="selectedMenu"
                          dense
                          key={index2}
                          style={{ marginBottom: "0.2rem" }}
                        >
                          <ListItemButton
                            selected={selectedIndex === curElem.id}
                            onClick={() => {
                              handleClickListItem(item, index2);
                            }}
                            disabled={!curElem.isAvailable}
                            sx={{
                              color:
                                selectedIndex === curElem.id
                                  ? theme.palette.primary.main
                                  : theme.palette.default.darkGrey,
                            }}
                          >
                            {curElem.brandLogo ? (
                              <ListItemIcon>
                                <img
                                  src={curElem.brandLogo}
                                  alt=""
                                  style={{
                                    width: 24,
                                    height: 24,
                                    objectFit: "contain",
                                    borderRadius: "50%",
                                  }}
                                />
                              </ListItemIcon>
                            ) : null}

                            <ListItemText>
                              {curElem.title}{" "}
                              {!curElem.isAvailable ? (
                                <small>(coming soon)</small>
                              ) : null}
                            </ListItemText>
                          </ListItemButton>
                        </MenuList>
                      );
                    })}
                </Accordion>
              );
            }
            return null;
          })}
      </CustomList>
    </SidebarWrap>
  );
};

export default IntegrationsSidebar;


const SidebarWrap = styled.div`
  && {
    background-color: white;
    display: flex;
    min-width: 300px;
    height: 100%;
    overflow: none;
    scroll-behavior: none;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    padding: 0.5rem 0.5rem;
  

    .MuiAccordion-root {
      box-shadow: none !important;
      margin-bottom: 0.5rem;

      &::before {
        background-color: transparent !important;
      }
      &::after {
        background-color: transparent !important;
      }

      .MuiAccordionSummary-root {
        padding: 1rem 1rem;
        min-height: 2.5rem;
        height: 2.5rem;
        max-height: 2.5rem;

        font-family: ${theme.typography.fontFamily};
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .MuiList-root {
        padding: 0px 0;

        .MuiMenuItem-root {
          padding: 0px 0;
          margin-bottom: 0.2rem;
          font-family: ${theme.typography.fontFamily};
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 1.2rem;
        }

        .Mui-selected {
          padding: 6px 16px;
          background-color: rgba(54, 84, 227, 0.08);
        }
      }
    }

    @media ${device.laptop} {
      width: 60px;
    }
  }
`;

const CustomList = styled(List)`
  && {
    padding-top: 0.5rem;
    width: 100%;
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;
