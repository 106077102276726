import { Box } from "@mui/material"
import { AddSectionStyle } from "./utils/ContactProperties"
import CustomBack from "./CustomBack"
import styled from "styled-components"
import Actions from "./Actions"
import { AppFormFastField, AppFormSelectField } from "../../../AppComponents/Forms"
import AppFormCheckboxField from "../../../AppComponents/Forms/AppFormCheckboxField"
import { useEffect, useState } from "react"
import { useFormikContext } from "formik"
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect"
import { getAllFieldOptions, getAllSectionDetails } from "../../../Store/Slices/Settings/ContactProperties/contactProperties.selector"
import { useSelector } from "react-redux"
import { ContactPropertiesActions } from "../../../Store/Slices/Settings/ContactProperties/contactProperties.action"
import { useAppDispatch } from "../../../Store"
import OptionMapping from "./OptionMapping"
import { useNavigate } from "react-router-dom"
import { LinkText } from "../../../Customization/CommonElements"
import { theme } from "../../../Customization/Theme"
const EditSection = (props: any) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const [options, setOptions] = useState([
        {
            "label": "Required",
            "value": "required",
            "defaultValue": false
        },
        {
            "label": "Read-Only",
            "value": "read_only",
            "defaultValue": false
        },
        {
            "label": "Show In Contact Form",
            "value": "visible_in_form",
            "defaultValue": false
        },
        // {
        //     "label": "Unique",
        //     "value": "unique",
        //     "defaultValue": false
        // }
    ])
    const allSectionDetails = useSelector(getAllSectionDetails)
    const fieldOptions = useSelector(getAllFieldOptions)
    
    useEffect(() => {
        if (fieldOptions) {
            // let updatedArray = fieldOptions.map((obj: any, index: any) =>
            //      obj.id  ? { ...obj, delete : false } : obj
            // );  
            setFieldValue("options", fieldOptions)
            setFieldValue("removeIds", [])
            // setFieldValue("previousOptions", fieldOptions)
        }
    }, [fieldOptions])
    useEffect(() => {
        if (values?.parent_id) {
            setFieldValue("is_child_of_other_section", true)
            let parentDetails = allSectionDetails.filter((section: any) => section.id === values?.parent_id)
            setFieldValue("parent", parentDetails)
        }
        if (values?.type === "dropdown" || values?.type === "radio" || values?.type === "multiselect" || values?.type === "autocomplete") {
            dispatch(ContactPropertiesActions.GetAllOptions(values?.id))
            setFieldValue("options", [
                { id: 1, label: '', position: 1 },
                { id: 2, label: '', position: 2 }])

        }
    }, [values?.parent_id])
    const onDrop = (items: any[], prevIndex: number, nextIndex: number) => {
        setFieldValue("options", items)
    }
    return (
        <>

            {/* <Box sx={AddSectionStyle}> */}
            <CustomFieldWrapper id="name">
                <CustomBack name="Edit Field" handleBack={() => props?.closeModel(false)} />
                <AddSectionField>
                    <AppFormFastField
                        name={"label"}
                        label={"Section Label"}
                        placeholder={"Enter Label"}
                        // style={CustomInputField}
                        // tooltip="Section Label"
                        require={true}
                        disabled={false}
                    />
                    <CheckBoxField>
                        {options.map((name: any) => (
                            <AppFormCheckboxField
                                label={name.label}
                                name={name.value}
                                disabled={values?.is_dependant ? values?.is_dependant : values?.system_default ? values?.system_default : values?.name === "lost_reason" ? true : values?.name === "other_lost_reason" ? true : values?.required && name.value === "read_only" ? values?.required : values?.read_only && name.value === "required" ? values?.read_only : false}
                            />
                        ))}
                    </CheckBoxField>
                    <InputField>

                        <AppFormFastField
                            name={"type"}
                            label={"Field Type"}

                            placeholder={"Enter Section Name"}
                            // style={CustomInputField}
                            // tooltip="Section Label"
                            disabled={true}
                        />
                    </InputField>
                    <InputField>

                        <AppFormFastField
                            name={"placeholder"}
                            label={"Placeholder Text"}
                            placeholder={"placeholder"}
                            // style={CustomInputField}
                            // tooltip="Section Label"
                            disabled={false}
                        />
                    </InputField>
                    <InputField>
                        <AppFormFastField
                            name={"tooltip"}
                            label={"Tooltip"}
                            placeholder={"tooltip"}
                            // style={CustomInputField}
                            // tooltip="Section Label"
                            disabled={false}
                        />
                    </InputField>
                     {!values?.is_dependant ?<InputField>
                        <AppReactSelectField
                            name={"parent"}
                            label="Section or Sub-section"
                            options={props?.allSectionDetails}
                            valueKey={"name"}
                            divStyle={{ maxWidth: "-webkit-fill-available" }}
                            maxWidth="-webkit-fill-available"
                            defaultText="Select a template"
                            isInModel={true}
                            isMulti={false}
                            enableSearch={true}
                        />
                    </InputField>:null}
                    {
                        values?.name !== "lifecycle_stage_status" && values?.name !== "lifecycle_stage" && values?.name !== "lost_reason" && values?.name !== "other_lost_reason" ? <div>
                            {values?.system_default !== true && values?.name !== "country_region" && values?.name !== "state_region" && values?.name !== "city" ?
                                values?.type === "dropdown" || values?.type === "radio" || values?.type === "multiselect" || values?.type === "autocomplete" && values?.options ? <InputField>
                                    <OptionMapping dependantCheck={true} name={'options'} onDrop={onDrop}
                                    />
                                </InputField> : null : null}
                        </div> : <LinkText
                            sx={{ marginTop: "1rem" }}
                            variant="subtitle2"
                            color={theme.palette.primary.main}
                        >
                            To create lifecycle stages and edit statuses for each stage,{" "}
                            <span onClick={() => window.location.href = '/settings/data-management/lifecycle'}> go here</span>
                        </LinkText>}


                </AddSectionField>
            </CustomFieldWrapper>
            <Actions handleBack={() => props?.closeModel(false)} />
            {/* </Box> */}
        </>
    )
}
const AddSectionField = styled.div`
&& {
    height: calc(100vh - 137px);
    padding: 20px;
    overflow-y: auto;
}
`
const CustomFieldWrapper = styled.div`
&& {
    
}
`
const CheckBoxField = styled.div`
    margin: 2rem 0rem 0rem;
    display : inline-flex;
`
const InputField = styled.div`
    margin: 2rem 0rem;
`
export default EditSection