import Box from "@mui/material/Box";

// utils
import { getActionConfig } from "../../flow-builder-v2/utils";

type Props = {
  actions: any;
  nodeContext: any;
};

const Action = (props: Props) => {
  const { actions, nodeContext } = props;
  return (
    actions.length > 0 &&
    actions.map((action: any, index: number) => {
      if (getActionConfig(action.type)?.displayComponent) {
        const Component = getActionConfig(action.type).displayComponent;
        return (
          <Box sx={{ mt: "16px" }} key={`actions-${index + 1}-${nodeContext.id}`}>
            <Component id={nodeContext.id} actionId={action.id} {...action} />
          </Box>
        );
      } else {
        return null
      }

    })
  );
};

export default Action;
