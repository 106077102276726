import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";

interface GridSkeletonLoaderProps {
  width?: number | string;
  height?: number;
  numberCols?: number;
  numberRows?: number;
  spacing?: string;
  variant?: any;
  loading?: boolean;
  otherProps?: any;
}

const GridSkeletonLoader: React.FC<GridSkeletonLoaderProps> = ({
  width = "100%",
  height = 10,
  numberCols = 1,
  numberRows = 1,
  spacing = "1rem",
  variant = "rectangular",
  loading = true,
  ...otherProps
}) => {
  const [numberOfCols, setNumberOfCols] = useState<number[]>([]);
  const [numberOfRows, setNumberOfRows] = useState<number[]>([]);

  useEffect(() => {
    setNumberOfCols(Array.from(Array(numberCols).keys()));
    setNumberOfRows(Array.from(Array(numberRows).keys()));
  }, [numberCols, numberRows]);

  return (
    <>
      {numberOfRows.map((row) => (
        <div key={row} style={{ display: "flex" }}>
          {numberOfCols.map((col) => {
            const skeletonProps = {
              key: `${row}-${col}`,
              variant,
              width,
              height,
              style: { margin: spacing },
              ...otherProps,
            };

            return <Skeleton {...skeletonProps} />;
          })}
        </div>
      ))}
    </>
  );
};

export default GridSkeletonLoader;
