import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { EmailBroadcastDashboardSummaryOptions } from "../../../Models/defaultSelectOptions";
import AppRangeDatePicker from "../../../AppComponents/AppRangeDatePicker";
import { ProtectedAppUiElement } from "../../../AppComponents/ProtectedComponents";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  CustomTabPanelContainer,
  WebsiteWrap,
} from "./EmailDashboardTopbarStyles";
import { useEffect, useState } from "react";
import { dateRange } from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { setDashboardDateRange } from "../../../Store/Slices/DashboardRedux/dashboard.slice";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { Typography } from "@mui/material";
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';


interface Props {}

export const TopMenubarSummary: React.FC<Props> = () => {
  const [value, setValue] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location: any = useLocation();
  const [tabsOptions, setTabsOptions] = useState<any>(
    EmailBroadcastDashboardSummaryOptions
  );
  const dashdateRange: any = useAppSelector(dateRange);


  

  const getCurrentTabAfterRefresh = () => {
    tabsOptions.forEach((item: any, index: number) => {
      if (location.pathname.includes(item?.value)) {
        setValue(index);
      }
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let url: string = tabsOptions?.[newValue].url;
    navigate(url);
  };

  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (date: any) => {
    dispatch(setDashboardDateRange(date));
  };



  return (
    <WebsiteWrap>
      <Box sx={{ width: "100%" }}>

        <TabWrapper>
          <SMSInsightTopBar>
            <Typography fontSize="16px" fontWeight="600" component="h6">
              Insights
            </Typography>
          </SMSInsightTopBar>
          <Box className="tab-and-date">
            <Tabs
              className="website-tabs"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              style={{
                display: "flex !important",
                flexDirection: "row",
                alignItems: "center !important",
                justifyContent: "space-between !important",
              }}
            >
              {tabsOptions.map((option: any, index: number) => (
                <Tab 
                  label={option.name} 
                  id={`simple-tab-${index}`} 
                  key={index}
                  icon={option?.name === "Overview" ? <AutoGraphOutlinedIcon /> : <ArticleOutlinedIcon />}
                  iconPosition="start"
                  sx={{ minHeight: "55px" }}  
                  />
                  ))}
            </Tabs>
            <Box>
              <AppRangeDatePicker
                dateRange={dashdateRange}
                setDateRange={onChange}
                />
            </Box>
          </Box>
             
        </TabWrapper>

        {tabsOptions.map((option: any, index: number) => (
          <CustomTabPanel value={value} index={index} key={index}>
            <ProtectedAppUiElement moduleId="email_insights">
              <Outlet />
            </ProtectedAppUiElement>
          </CustomTabPanel>
        ))}
      </Box>
    </WebsiteWrap>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <CustomTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </CustomTabPanelContainer>
  );
}
const SMSInsightTopBar = styled.div`
&& {
  width: 100%;
  padding: 16px 16px 16px 32px;
  background-color: ${theme.palette.default.white};
  text-align: left;
  h6 {
    color: ${theme.palette.default.black};
  }
}
`;

const TabWrapper = styled.div`
&& {
  width: calc(100% - 4px);
  margin-left: 4px;
  display: flex; 
  border-bottom:  1px solid ${theme.palette.default.grey};
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1;
  .tab-and-date{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.palette.default.greyishWhite};
    width: 100%;
  }
}
`;