// vendors
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import cloneDeep from "lodash.clonedeep";

// components
import Icon from "../../Components/Icon";
import NodeType from "../../Components/NodeType";
import UIButton from "../../Components/Button";

// styles
import {
  EditConfigurationButton,
  PopoverConfig,
  SectionStyled,
} from "./Configurations.styled";

// utils
import { getNodeConfig } from "../../Utils";

type FileType = {
  type: string;
  extensions: Array<{ label: string; value: string }>;
};

const Configurations = ({
  onSave,
  extensions,
}: {
  onSave: (selectedExtensions: FileType[]) => void;
  extensions: FileType[];
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const open = Boolean(anchorEl);
  const nodeConfig = getNodeConfig("img-file");
  const [selected, setSelected] = useState<FileType[]>(
    cloneDeep(extensions) || []
  );

  const handlePopoverToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelClick = () => {
    setSelected([]);
    setAnchorEl(null);
  };

  const handleSaveClick = () => {
    onSave(selected);
    setAnchorEl(null);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    options: Array<{ label: string; value: string }>
  ) => {
    const value = event.target.value;
    if (selected === null) {
      setSelected([{ type: value, extensions: options }]);
      return;
    }

    if (selected !== null) {
      const list = [...selected];
      if (event.target.checked) {
        const updatedList = list.filter(
          (listItem: any) => listItem.type !== value
        );
        setSelected([
          ...updatedList,
          ...[{ type: value, extensions: options }],
        ]);
      } else {
        const filterCategory = list.filter(
          (item: FileType) => item.type !== value
        );
        setSelected([...filterCategory]);
      }
    }
  };

  const isCategorySelected = (parentCategory: string) => {
    var nodeData = [...cloneDeep(nodeConfig.allowedFiles)];
    if (selected !== null) {
      const list = [...selected];
      const filterCategory = list.filter(
        (item: FileType) => item.type === parentCategory
      );
      const listCategory = nodeData.filter(
        (item: { name: string; supportedExtensions: Array<any> }) =>
          item.name === parentCategory
      );
      return (
        filterCategory.length > 0 &&
        filterCategory?.[0]?.extensions?.length ===
          listCategory?.[0]?.supportedExtensions?.length
      );
    }
    return false;
  };

  const handleChangeChild = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: { label: string; value: string },
    parentCategory: string
  ) => {
    if (selected === null) {
      setSelected([{ type: parentCategory, extensions: [value] }]);
      return;
    }

    if (selected !== null) {
      const list = [...selected];
      const filterCategory = list.filter(
        (item: FileType) => item.type === parentCategory
      );

      const objIndex = list.findIndex(
        (item: FileType) => item.type === parentCategory
      );

      if (objIndex === -1) {
        setSelected([
          ...selected,
          ...[{ type: parentCategory, extensions: [value] }],
        ]);
      } else {
        if (event.target.checked) {
          const currentCategory = [...filterCategory[0].extensions];
          const index = currentCategory.indexOf(value);
          index === -1
            ? currentCategory.push(value)
            : currentCategory.splice(index, 1);
          list[objIndex].extensions = currentCategory;
          setSelected(list);
        } else {
          const currentCategory = [...filterCategory[0].extensions];
          const updatedCat = currentCategory.filter(
            (currentCategoryItem) => currentCategoryItem.label !== value.label
          );
          list[objIndex].extensions = updatedCat;
          setSelected(list);
        }
      }
    }
  };

  const isSubCategorySelected = (
    parentCategory: string,
    subCategory: string
  ) => {
    if (selected !== null) {
      const list = [...selected];
      const filterCategory = list.filter(
        (item: FileType) => item.type === parentCategory
      );
      if (filterCategory.length > 0) {
        const subCategories = [...filterCategory[0].extensions];
        return (
          subCategories.length > 0 &&
          subCategories.filter(
            (ele: { label: string; value: string }) => ele.value === subCategory
          )?.length > 0
        );
      }
      return false;
    }
    return false;
  };

  return (
    <>
      <EditConfigurationButton onClick={handlePopoverToggle}>
        <Icon icon="pencil" size={14} color="#7E8392" />
      </EditConfigurationButton>
      <Popover
        className="ignore-onClickOutside"
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={3}
        {...PopoverConfig(open)}
        sx={{
          "& .MuiPopover-paper": {
            macHeight: "calc(100vh - 192px)",
            minWidth: "320px",
            maxWidth: "415px",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
          }}
        >
          <NodeType
            text={nodeConfig.name}
            color={nodeConfig.color}
            icon={nodeConfig.icon}
          />
        </Box>
        <SectionStyled>
          <Box
            sx={{
              maxHeight: "calc(100vh - 405px)",
              overflowY: "auto",
              paddingRight: "6px",
              marginTop: "8px",
            }}
          >
            {nodeConfig.allowedFiles.map(
              (
                allowedFile: {
                  name: string;
                  supportedExtensions: Array<{ label: string; value: string }>;
                },
                index: number
              ) => {
                const sup_extensions =
                  allowedFile.supportedExtensions.length > 0
                    ? allowedFile.supportedExtensions
                    : [];

                return (
                  <Box key={`img-file-${allowedFile.name}-${index + 1}`}>
                    <Box
                      display="flex"
                      sx={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "#F5F6F8",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ margin: 0, padding: "0.7rem" }}
                      >
                        {allowedFile.name === "Images" ? "Images" : "Documents"}
                      </Typography>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disableRipple
                              value={allowedFile.name}
                              onChange={(event) =>
                                handleChange(
                                  event,
                                  allowedFile.supportedExtensions
                                )
                              }
                              checked={isCategorySelected(allowedFile.name)}
                              sx={{
                                margin: 0,
                                "&.MuiButtonBase-root": {
                                  padding: "0 !important",
                                },
                                "& .MuiSvgIcon-root": {
                                  width: "1.3rem", // Replace 'your_desired_width' with the desired value, e.g. '40px'
                                  height: "auto",
                                },
                              }}
                            />
                          }
                          label={"Select All"}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "16px",
                              lineHeight: "21px",
                              fontWeight: 400,
                              color: "#7E8392",
                              pl: "8px",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      className="checkRow"
                      sx={{
                        mt: "0.9rem",
                        mb: "0rem",
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      {sup_extensions.map(
                        (
                          sup_extension: { label: string; value: string },
                          sEIndex: number
                        ) => {
                          return (
                            <Box
                              key={`img-file-${sup_extension}-${sEIndex + 1}`}
                              sx={{
                                mb: "1.7rem",
                                marginLeft: "0.3rem",
                                minWidth: "calc(33.33% - 0.3rem)",
                                boxSizing: "border-box",
                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    value={sup_extension.value}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      handleChangeChild(
                                        event,
                                        sup_extension,
                                        allowedFile.name
                                      )
                                    }
                                    checked={
                                      isSubCategorySelected(
                                        allowedFile.name,
                                        sup_extension.value
                                      ) ||
                                      selected?.filter(
                                        (fileType: any) =>
                                          fileType.type === allowedFile.name
                                      )?.[0]?.extensions?.length ===
                                        allowedFile.supportedExtensions?.length
                                    }
                                    sx={{
                                      "&.MuiButtonBase-root": {
                                        padding: "0 !important",
                                        svg: {
                                          color: "red",
                                        },
                                      },
                                      "&.MuiCheckbox-root": {
                                        // Change the border color when not selected
                                        "& .MuiSvgIcon-root": {
                                          stroke: "borderColor",
                                          // strokeWidth: 2,
                                          borderRadius: 2,
                                          fill: "#7E8392",
                                        },
                                      },
                                      "&.Mui-checked": {
                                        // Change the border color when selected (checked)
                                        "& .MuiSvgIcon-root": {
                                          borderRadius: 2,
                                          fill: "#3654E3",
                                        },
                                      },
                                      "& .MuiSvgIcon-root": {
                                        width: "1.3rem", // Replace 'your_desired_width' with the desired value, e.g. '40px'
                                        height: "auto",
                                      },
                                    }}
                                  />
                                }
                                label={sup_extension.label}
                                sx={{
                                  ml: 0,
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    fontWeight: 400,
                                    color: "#7E8392",
                                    pl: "8px",
                                  },
                                }}
                              />
                            </Box>
                          );
                        }
                      )}
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
        </SectionStyled>
        <SectionStyled>
          <Box display={"flex"} gap={"20px"} justifyContent={"flex-end"}>
            <UIButton variant="outlined" onClick={handleCancelClick}>
              Cancel
            </UIButton>
            <UIButton
              variant="contained"
              disabled={selected.length === 0}
              onClick={handleSaveClick}
            >
              Save
            </UIButton>
          </Box>
        </SectionStyled>
      </Popover>
    </>
  );
};
export default Configurations;
