import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import NoDataImage from "../../../assets/images/animated-no-data.gif";
import AppButton from "../../../AppComponents/AppButton";

interface NoDataProps {
  title?: string;
  subtitle?: string;
  onClick?: () => void;
  buttonText?: string;
}

const BroadcastNoData: React.FC<NoDataProps> = ({
  title,
  subtitle,
  onClick,
  buttonText,
}) => {
  return (
    <NoDataWrapper>
      <img src={NoDataImage} width="300" height="300" alt=""/>
      <div className="content">
        <h5 className="title">{title || "No Records Found"}</h5>
        <p className="subtitle">{subtitle}</p>
      </div>
      <AppButton onClick={onClick}>{buttonText || "Submit"}</AppButton>
    </NoDataWrapper>
  );
};

export default BroadcastNoData;

export const NoDataWrapper = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    border-radius: 6px;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    h5.title {
      font-weight: 500;
      font-size: 22px;
      margin-bottom: 8px;
    }
    p.subtitle {
      font-weight: 400;
      font-size: 16px;
    }
  }
`;
