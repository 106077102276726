import React, { memo } from "react";

import { IconButton } from "@mui/material";
import { theme } from "../../../Customization/Theme";
import {
  StyledFileContainerBox,
  StyledMessageText
} from "../../Styles/StyledComponents";
import { AppMaterialIcons } from "../Icons";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppRenderRepliedMessage from "./AppRenderRepliedMessage";

interface Props {
  src: string;
  name: string;
  alt?: string;
  userMessage: boolean;
  children?: any;
  time: string;
  msgId: string;
  seen: string;
  sent: string;
  delivered: string;
  [otherProps: string]: any;
}

const AppRenderFile: React.FC<Props> = ({
  src,
  name,
  alt,
  time,
  userMessage = false,
  msgId,
  seen,
  sent,
  delivered,
}) => {
  if (!src) {
    return null;
  }

  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      isMedia={true}
      time={time}
      msgId={msgId}
      seen={seen}
      sent={sent}
      delivered={delivered}
    >

      <StyledFileContainerBox
        href={src}
        target="_blank"
        rel="noopener,noreferrer"
      >
        <div className="fileActionBox">
          <AppMaterialIcons
            iconName="FilePresent"
            style={{ color: theme.palette.default.darkGrey }}
          />
        </div>
        <div className="fileNameBox">{name}</div>
        <IconButton title={name || "Download"}>
          <AppMaterialIcons
            iconName="DownloadForOffline"
            style={{ fontSize: "1.5rem" }}
          />
        </IconButton>
      </StyledFileContainerBox>
      {alt && alt?.length > 0 ? (
        <StyledMessageText
          userMessage={userMessage}
          dangerouslySetInnerHTML={{
            __html: alt,
          }}
          className="getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
        />
        ) : null}
        
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderFile);
