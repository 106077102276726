import { CaseReducerActions, Slice, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';
import { AxiosInstance } from 'axios';
import { createGenericSlice, GenericState } from '../../Store/Slices';
import { formatString, getDeepProperty } from '../../Utils';
import { getHttpService } from '../HttpService';

export class HTTPMethod {
    static GET='get'
    static POST='post'
    static PUT='put'
    static PATCH='patch'
    static DELETE='delete'
}



export interface GenericAPICallerParams<T> {
    sliceName: string,
    axiosObj: AxiosInstance,
    endpoint: string,
    httpMethod: HTTPMethod,
    data: any,
    storeAccessPath: string,
    extra_config?: any,
    extra_reducers: any
}
export interface GenericAPICallerResult<T> {
    slice: Slice<GenericState<T>, SliceCaseReducers<GenericState<T>>, string>,
    reducer: ValidateSliceCaseReducers<GenericState<T>, SliceCaseReducers<GenericState<T>>>
    //@ts-ignore
    actions: CaseReducerActions<SliceCaseReducers<GenericState<T>>,any>;
    storeAccessFn: (state: any) => any
}


export function createGenericSliceAPICaller<T, Reducers extends SliceCaseReducers<GenericState<T>>>(params: {
    sliceName: string,
    axiosObj: AxiosInstance,
    endpoint: string,
    httpMethod: HTTPMethod,
    data?: any,
    params?: any,
    storeAccessPath: string,
    extra_reducers?: ValidateSliceCaseReducers<GenericState<T>, Reducers>
    extra_config?: any,
}) {
    
    const sliceParams = {
        name: params.sliceName,
        initialState: { status: 'loading' } as GenericState<T>,
        reducers: params.extra_reducers ? {...params.extra_reducers} : {}
    }
    const mySlice = createGenericSlice(sliceParams);

    const { get, post, put, patch, httpDelete} = getHttpService(params.axiosObj);
    let httpFnToCall:any= null;
    switch(params.httpMethod) {
        case HTTPMethod.GET:
            httpFnToCall = get;
            break;
        case HTTPMethod.POST:
            httpFnToCall = post;
            break;
        case HTTPMethod.PUT:
            httpFnToCall = put;
            break;
        case HTTPMethod.PATCH:
            httpFnToCall = patch;
            break;
        case HTTPMethod.DELETE:
            httpFnToCall = httpDelete;
            break;
    }

    const apiFn = (dispatch: any, endpoint?: string) => {
        return async (fnparams: {
            url?: string;
            params?: { [key: string]: any; };
            data?: { [key: string]: any; };
            headers?: { [key: string]: string; };
            pathParams?: Array<any>;
          }) => {
          const { success, start, error } = mySlice.actions;
          dispatch(start());
            let endpoint_to_use = params.endpoint;
            if (endpoint) {
                endpoint_to_use = endpoint
            }
            if (fnparams?.url) {
                endpoint_to_use = fnparams.url;
            }
          if (fnparams.pathParams) {
              endpoint_to_use = formatString(endpoint_to_use, fnparams.pathParams);
          }
            const data: any = { url: endpoint_to_use, headers: fnparams.headers, data: fnparams.data, params: fnparams.params }
            
          
          const response = await httpFnToCall(data);
          if (response.status == 200) {
              const data = response.data;
            dispatch(success(data));
            return Promise.resolve(data);
          } else {
            const error_msg = response.data.error;
            dispatch(error(error_msg));
            return Promise.reject(error_msg)
          }
      
        };
    }

    const storeAccessFn = (state: any): T => {
        const value = getDeepProperty(state, params.storeAccessPath) as T;
        return value;
      };

    return {
        'slice': mySlice,
        'reducer': mySlice.reducer,
        'actions': mySlice.actions,
        'apiFn': apiFn,
        storeAccessFn
    }
}

function ActionCreatorWithPayload<T, U>() {
    throw new Error('Function not implemented.');
}
