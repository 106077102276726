import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import uniqid from "uniqid";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import AppCustomTooltip from "../../../../../AppComponents/AppTooltip";
import { getTextWithoutHtml } from "../../../../../Utils";

// Render WhatsApp Analytics Statistics
export const renderAnalyticsColumn = (data: any, apiData?: any) => {
  return data.map((col: any) => {
    return (

      <Grid item xs={3} md={3} lg={2} xl={1.5}>
        <Box key={uniqid()} className="col">
          <span className="iconWrapper">
            <AppMaterialIcons iconName={col.icon} />
          </span>
          <h6>
            {col.label}{" "}
            <AppCustomTooltip placement="bottom" title={col.info}>
              <InfoOutlinedIcon />
            </AppCustomTooltip>
          </h6>
          <strong>{apiData[col.key]}</strong>
        </Box>
      </Grid>
    );
  });
};

export const convertHtmlToWhatsappText = (text: string) => {
  if (!text) return;

  let strBold = text;
  let replacedBold = strBold
    ?.replace(/<strong>/g, "*")
    ?.replace(/<\/strong>/g, "*");

  let strItalic = replacedBold;
  let replacedItalic = strItalic
    ?.replace(/<em>/g, "_")
    ?.replace(/<\/em>/g, "_");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    ?.replace(/<del>/g, "~")
    ?.replace(/<\/del>/g, "~");

  let strMono = replacedStrike;
  let replacedMono = strMono
    ?.replace(/<tt>/g, "```")
    ?.replace(/<\/tt>/g, "```")
    ?.replace(/<code>/g, "```")
    ?.replace(/<\/code>/g, "```");

  let smsText: string = getTextWithoutHtml(replacedMono || "")
    .replace(/\n/g, "")
    .replace(/&nbsp;/g, "");
  return smsText;
};
export const convertSMSTextToHtml = (text: string) => {
  if (!text) return;

  let strBold = text;
  let replacedBold = strBold
    .replace(/\*(?=\w)/, "<strong>")
    .replace(/(?<=\w)\*/, "</strong>");

  let strItalic = replacedBold;
  let replacedItalic = strItalic
    .replace(/\_(?=\w)/, "<em>")
    .replace(/(?<=\w)\_/, "</em>");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    .replace(/\~(?=\w)/, "<del>")
    .replace(/(?<=\w)\~/, "</del>");

  return replacedStrike;
};

export const VariableRegexReplace = (
  originalString: string,
  replacementArray: string[]
) => {
  // Define the regex pattern and replacement array
  const regexPattern = /\{\{([1-9]|10)\}\}/gm;

  // Function to handle the replacement
  const handleReplacement = (match: string, index: number) => {
    // Get the index from the match
    const matchIndex = parseInt(match?.slice(2, -2), 10);

    // Check if the index is within the range of the replacement array
    if (matchIndex >= 1 && matchIndex <= replacementArray?.length) {
      // Return the replacement string from the array
      return replacementArray[matchIndex - 1];
    } else {
      // Return the original match if the index is out of range
      return match;
    }
  };
};

export const dataToSMSTemplatePayload = (values: any) => {
  let header: any = {};

  if (values?.header_type === "text") {
    header["header_type"] = "text";
  }

  if (
    values?.header_type === "document" ||
    values?.header_type === "image" ||
    values?.header_type === "video"
  ) {
    header["header_type"] = "media";
  }

  header["value"] = {
    type: values?.header_type,
    value:
      values?.header_type === "text"
        ? values?.header_value
        : values?.header_value?.id,
    header_text: values?.variables_header || [],
    attachment_id: values?.header_value?.attachment_id,
  };

  let body: any = {
    type: "text",
    value: values?.body_value,
    body_text: values?.variables_body,
  };

  let footer: any = {
    type: "text",
    value: values?.footer_value,
  };

  let button: any = {
    button_type: values?.buttons_type?.value,
    value:
      (values?.buttons_type?.value === "quick_reply" &&
        values?.quick_buttons) ||
      (values?.buttons_type?.value === "call_to_action" && values?.cta_buttons),
  };

  button &&
    button["value"]?.length &&
    button["value"]?.forEach((curItem: any) => {
      if (curItem?.url_type === "dynamic") {
        curItem["example"] = values?.variables_button;
      } else {
        delete curItem?.["example"];
      }
    });

  let payloadObject: any = {
    name: values?.template_name,
    category: values?.template_category,
    language: values?.template_language,
    header: header,
    body: body,
    footer: footer,
    button: button,
  };

  if (values?.buttons_type?.value === "none") {
    delete payloadObject?.button;
  }
  if (values?.header_type === "none") {
    delete payloadObject?.header;
  }
  if (values?.footer_value?.length < 1 || !values?.footer_value) {
    delete payloadObject?.footer;
  }

  return payloadObject;
};

export const templateDataToUserFormData = async (response: any) => {
  let userInput: any = {
    template_name: response?.name,
    template_category: response?.category,
    dlt_entity_id: response?.dlt_entity_id,
    sender_ids: response?.sender_ids,
    tags: response?.tags,
    template_type: response?.template_type,
    dlt_template_id: response?.dlt_template_id,
    message_type: response?.message_type,
    type: response?.type,
    body_value: response?.components?.body?.value,
    body_text: generateResolveVariables(
      response?.components?.body?.value,
      response?.components?.body?.body_text
    ),
    variables_body: response?.components?.body?.body_text,

    id: response?.id || null,
    original_response: response || null,
  };
  return userInput;
};

export const dataToTemplateFormData = async (response: any) => {
  let userInput: any = {
    template_name: response?.name,
    template_category: response?.category,
    template_language: response?.language,

    header_type: response?.components?.header?.value?.type,
    header_value: response?.components?.header?.value?.value,
    header_text:
      response?.components?.header?.value?.type === "text"
        ?
        response?.components?.header?.value?.header_text

        : [],
    body_value: response?.components?.body?.value,
    body_text:
      response?.components?.body?.body_text || []
    ,

    footer_value: response?.components?.footer?.value,
    footer_text: [],

    buttons_type: response?.components?.button?.button_type || "none",
    cta_buttons:
      response?.components?.button?.button_type === "call_to_action"
        ? response?.components?.button?.value
        : [],
    quick_buttons:
      response?.components?.button?.button_type === "quick_reply"
        ? response?.components?.button?.value
        : [],
    buttons_text: [],

    variables_header: response?.components?.header?.value?.header_text,
    variables_body: response?.components?.body?.body_text,
    variables_footer: [],
    variables_button: response?.components?.button?.value[0]?.example || response?.components?.button?.value[1]?.example || [],

    id: response?.id || null,
    original_response: response || null,
  };

  if (response?.components?.header?.value?.type !== "text") {
    userInput["header_value"] = response?.components?.header?.value?.attachment;
  }

  response?.components?.button?.value?.length &&
    response?.components?.button?.value?.forEach((curItem: any) => {
      if (curItem?.url_type === "dynamic") {
        userInput["buttons_text"] = [{ key: "{{1}}", value: "", index: 0 }];
      }
    });
  return userInput;
};

const generateResolveVariables = (text: string, variables: any[]) => {
  if (!variables?.length) {
    return [];
  }

  

  let array: any[] = [];
  let regexArray: any = getVariablesArray(text);
  
  variables?.length > 0 &&
    variables?.forEach((curItem: any, index: number) => {
      let temp = {
        key: regexArray[index],
        // value: typeof curItem === "string" && curItem,
        value: "",
        index: 0,
      };
      array.push(temp);
    });

    
  return array;
};

const getVariablesArray = (text:string) => {
  // const text = '{{1}} days left to acquire excellent Business skills + Legal knowledge via an experience driven curriculum! Apply for Symbiosis SLS, Hyderabad BA: {{2}}';

  const getUniqueMatches = (matches: string[]): string[] => {
    const sortedMatches = matches?.sort((a:any, b:any) => {
            return parseInt(a.match(/\d+/)![0]) - parseInt(b.match(/\d+/)![0]);
          });

    const uniqueMatches: string[] = [];
    for (const match of sortedMatches) {
      const isLastOccurrence = matches?.indexOf(match) === matches?.lastIndexOf(match);
      const isNotIncludedInPrevious = uniqueMatches?.every((uniqueMatch) => !uniqueMatch?.includes(match));

      if (isLastOccurrence && isNotIncludedInPrevious) {
        uniqueMatches?.push(match);
      }
    }

    return uniqueMatches;
  };

  const pattern = /\{\{([1-9]|10)\}\}/gm;
  const matches = text?.match(pattern) || [];
  const uniqueMatches = getUniqueMatches(matches);

  return uniqueMatches;
};
getVariablesArray('{{1}} days left to acquire excellent Business skills + Legal knowledge via an experience driven curriculum! Apply for Symbiosis SLS, Hyderabad BA: {{2}}')

export const removeDuplicateObjects = (array: any[], property: string) => {
  const uniqueIds: any[] = [];

  const unique = array?.filter((element) => {
    const isDuplicate = uniqueIds?.includes(element[property]);

    if (!isDuplicate) {
      uniqueIds?.push(element[property]);

      return true;
    }

    return false;
  });

  return unique;
};

export const removeEmptyKeysObj = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const convertDateTimeToUTC = (date: any, time: any) => {
  let a = moment(date).format("YYYY-MM-DD");
  let b = moment(time).format("HH:mm:ss");
  let c = moment(a + " " + b, "YYYY-MM-DD HH:mm:ss").toString();
  let utc = moment(c).utc().format();
  return utc;
};
export const convertUTCToDateTime = (utcDateTime: any) => {
  let a = moment.utc(utcDateTime).local().format()
  return a;
};

export const convertDataToSMSBroadcastForm = (res: any) => {
  let formData: any = {
    broadcast_name: res?.broadcast_name,
    broadcast_template_id: res?.broadcast_template_id,
    sender_id: res?.sender_id,
    broadcast_action: "broadcast_marketing_list",
    marketing_list: 0,
    filters: null,
    selected_rows: [],
    search_term: "",

    when_to_send: "schedule_broadcast",
    date: res?.sent_on ? convertUTCToDateTime(res?.sent_on) : new Date(Date.now()),
    time: res?.sent_on ? convertUTCToDateTime(res?.sent_on) : new Date(Date.now()),

    resolved_header_variables: [],
    resolved_body_variables: [],
    resolved_buttons_variables: [],
  };
  return formData;
};
