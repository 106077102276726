import styled from "styled-components";
import { getIn, useFormikContext } from "formik";
import React, { Fragment, useState } from "react";
import { theme } from "../../Customization/Theme";
import AppIcon from "../AppIcon";
import AppErrorMessage from "./AppErrorMessage";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from "@mui/material";

interface Props {
  label?: string;
  name: string;
  divStyle?: any;
  tooltip?: any;
  children?: React.ReactNode;
  requiredSign?: boolean;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormPasswordField: React.FC<Props> = ({
  label,
  name,
  divStyle,
  tooltip,
  requiredSign,
  ...otherProps
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const handleShowPassword = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    getFieldMeta,
    initialValues,
    setFieldValue,
    values,
  } = useFormikContext<any>();

  return (
    <Div style={divStyle}>
      <Label>{requiredSign ? (
        <Fragment>
          {label}
          <sup style={{ color: `${theme.palette.default.error}` }}> *</sup>
        </Fragment>

      ) : (
        label
      )}  {tooltip && (
        <Tooltip title={tooltip} placement="top">
          <HelpOutlineIcon />
        </Tooltip>
      )}</Label>
      <span
        style={{
          position: 'absolute',
          right: '8px',
          zIndex: 1,
          top:
            errors[name] && touched[name] ? "40%" : "55%",

        }}
      >
        <AppIcon
          title={isPasswordVisible ? "ri-eye-line" : "ri-eye-close-line"}
          onClick={handleShowPassword}
          size={"20px"}
          color={"#AFAFAF"}
        />
      </span>
      <Input
        {...otherProps}
        type={isPasswordVisible ? "text" : "password"}
        onBlur={() => setFieldTouched(name)}
        onChange={(e: any) => setFieldValue(name, e.target.value)}
        value={getIn(values, name) || ''}
        style={{
          border:
            getIn(errors, name) && getIn(touched, name)
              ? `2px solid ${theme.palette.default.error}`
              : `2px solid transparent`,
        }}
      />
      <AppErrorMessage error={getIn(errors, name)} visible={getIn(touched, name)} />
    </Div>
  );
};

export default AppFormPasswordField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
const Input = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    /* outline: 1px solid ${theme.palette.default.border} !important; */
  }
`;
