const groupByCategory = (options: any[]) => {
  const grouped = options.reduce((acc: any, currItem: any) => {
    const groupKey = currItem.category;
    if (!acc[groupKey]) {
      acc[groupKey] = [currItem];
    } else {
      acc[groupKey].push(currItem);
    }
    return acc;
  }, {});
  const newFormat = Object.keys(grouped).map((key) => ({
    name: key,
    children: grouped[key].map((groupItem: any) => ({
      ...groupItem,
    })),
  }));
  return newFormat;
};

export default groupByCategory;