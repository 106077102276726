import { List, ListItem, Pagination } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

// Pagination Styles
export const AppPagination = styled(Pagination)`
  & {
    .MuiPaginationItem-root {
      font-size: 13px;
      &.Mui-selected {
        background-color: ${theme.palette.primary.dark};
        color: ${theme.palette.default.white};
        border-radius: 6px;
      }
      &:hover {
        border-radius: 6px;
      }
    }
    .MuiTablePagination-toolbar {
      min-height: 30px;
    }
    .MuiSelect-select {
      border: 1px solid ${theme.palette.default.grey};
      border-radius: 6px;
    }

    p {
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-select {
      text-align: center !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-actions > button:last-child {
      margin-left: 8px;
    }
    .MuiTablePagination-actions > button {
      border: 1px solid ${theme.palette.default.grey} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.darkGrey};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
    .MuiTablePagination-actions .Mui-disabled {
      border: 1px solid ${theme.palette.default.RightBorderDivder} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.RightBorderDivder};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
  }
`;

// Wrapper For Website
export const FaqWebsiteWrap = styled.div`
  .container {
    padding: 16px 0;
    align-items: center;
    display: flex;
    gap: 10rem;
    justify-content: space-between;
    .header {
      display: flex;
      align-items: center;
      gap: 32px;
      h5 {
        font-weight: 500;
        font-size: 16px;
        display: block;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        display: block;
        color: ${theme.palette.default.darkGrey};
      }
    }
  }

  .front-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .front-text {
    font-family: inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: ${theme.palette.default.darkGrey};
  }
`;

// Wrapper For Sitemap
export const FaqSitemapWrap = styled.div`
  .container {
    padding-bottom: 22px;
    display: flex;
    gap: 10rem;
    .search-class {
      display: flex;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: ${theme.palette.default.darkGrey};
    }
  }
  .front-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .front-text {
    font-family: inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: ${theme.palette.default.darkGrey};
  }
`;

// Table CSS
export const NewTable = styled.div`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    min-height: calc(100vh - 400px);
    .chip .MuiButtonBase-root {
      padding: 0 !important;
    }
    .table-header {
      position: sticky;
      top: 0;
      z-index: 1;
      .table-row {
        display: flex;
        align-items: stretch;
        > div {
          border-top: 1px solid ${theme.palette.default.border};
        }
      }
      .table-row > div:nth-child(2) {
        min-width: 388px;
      }

      .table-row > div:nth-child(5) {
        border-right: none;
      }

      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 60px;
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
        font-size: 13px;
      }
      .hide-column-line {
        border-right: none !important;
      }
      .hidden-text {
        visibility: hidden;
      }
    }

    // Body
    .table-body {
      .table-row {
        display: flex;
        > div {
          border-top: 1px solid ${theme.palette.default.border};
        }
      }
      .table-row > div:first-child {
        max-width: 60px;
      }
      .table-row > div:nth-child(2) {
        min-width: 388px;
        color: ${theme.palette.default.black};
      }
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 60px;
    }
    .col-body {
      display: flex;
      word-break: break-all;
      flex: 1;
      align-items: center;
      padding: 8px 16px;
      color: ${theme.palette.default.darkGrey};
      font-size: 13px;
      .url-count {
        .MuiButtonBase-root {
          font-size: 13px;
        }
      }
      .status {
        display: flex;
        gap: 8px;
        color: #25926a;
        padding: 4px 16px;
        align-items: center;
        background-color: #e5fff6;
        border: 1px solid #12c584;
        border-radius: 4px;
        justify-content: center;
      }
      .status-uploaded {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }
      .in-progress {
        display: flex;
        gap: 8px;
        color: #918548;
        padding: 4px 16px;
        align-items: center;
        background-color: #fffbe5;
        border: 1px solid #ffe55e;
        border-radius: 4px;
        justify-content: center;
      }
    }
    .col-body.url {
      color: ${theme.palette.primary.main} !important;
    }
  }
`;

export const ShowAllLinkTable = styled.div`
  & {
    width: 100%;
    min-width: 1343px;
    background-color: ${theme.palette.default.white};
    .table-header {
      .table-row {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
      }

      .table-row > div:first-child {
        height: 55px;
        padding-left: 30px;
        min-width: 532px;
        max-width: 532px;
      }

      .table-row > div:nth-child(3) {
        min-width: 317px;
        max-width: 317px;
        border-right: none;
      }

      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 60px;
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
        font-size: 13px;
      }
    }

    // Body
    .table-body {
      overflow: auto;
      .table-row {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      .table-row > div:first-child {
        max-width: 60px;
      }
      .table-row > div:nth-child(2) {
        min-width: 532px;
        max-width: 532px;
        color: ${theme.palette.default.black};
      }
      .table-row > div:nth-child(3) {
        min-width: 317px;
        max-width: 317px;
      }
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 60px;
    }
    .col-body {
      display: flex;
      word-break: break-all;
      flex: 1;
      align-items: center;
      padding: 8px 16px;
      font-size: 13px;
      color: ${theme.palette.default.darkGrey};
      .status {
        display: flex;
        gap: 8px;
        color: #25926a;
        padding: 4px 16px;
        align-items: center;
        background-color: #e5fff6;
        border: 1px solid #12c584;
        border-radius: 4px;
        justify-content: center;
      }
      .status-uploaded {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }
      .in-progress {
        display: flex;
        gap: 8px;
        color: #918548;
        padding: 4px 16px;
        align-items: center;
        background-color: #fffbe5;
        border: 1px solid #ffe55e;
        border-radius: 4px;
        justify-content: center;
      }
    }
    .col-body.url {
      color: ${theme.palette.primary.main} !important;
    }
  }
`;

export const WebsiteTableStyle = styled("div")({
  padding: "16px 16px",
  ".leftside-popup": {
    display: "flex",
    gap: "2rem",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid lightgray",
    paddingBottom: "16px",
    h3: {
      fontStyle: "Rubik",
      fontWeight: "500",
      fontSize: "16px",
      color: `${theme.palette.default.newTextBlack}`,
    },
    p: {
      fontStyle: "Rubik",
      fontWeight: "400",
      fontSize: "14px",
      color: `${theme.palette.default.darkGrey}`,
    },
  },
  ".input-field": {
    marginTop: "1rem",
    borderBottom: "1px solid lightgray",
    paddingBottom: "16px",
    // Add more styles as needed
    label: {
      fontStyle: "Rubik",
      fontWeight: "400",
      fontSize: "14px",
      color: `${theme.palette.default.newTextBlack}`,
    },
  },
  ".buttons": {
    display: "flex",
    justifyContent: "end",
    gap: "8px",
    paddingTop: "16px",
  },
});

export const CrawlPopupWrap = styled.div`
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    align-items: center;

    .heading {
      padding: 16px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }
  label {
    color: red;
  }
  .crawl-heading p {
    color: ${theme.palette.default.NightRider};
    font-weight: 400;
    font-style: inter;
    font-size: 14px;
    line-height: 24px;
  }
  h3 {
    padding-top: 10px;
    line-height: 30px;
    font-weight: 500;
    font-style: inter;
    font-size: 20px;
    color: ${theme.palette.default.newTextBlack};
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: ${theme.palette.default.LavenderBlue};
    border: 1px solid ${theme.palette.default.PastelCyan};
    padding: 10px;
    margin-top: 2rem;
  }
  .paragraph p {
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .popup-buttons {
    display: flex;
    justify-content: end;
    padding-top: 1rem;
    gap: 2rem;
  }
  .warning_text {
    color: ${theme.palette.default.red};
    font-size: 0.813rem;
    margin-top: 10px;
  }
  .app-input {
    margin-bottom: 0; /* Override the default margin */
  }
  .input-field {
    margin-top: 1rem;
  }
  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${theme.palette.default.newTextBlack};
  }

  .body {
    padding: 0px 16px;
  }
  .footer {
    padding: 0 16px;
    margin-bottom: 16px;
    border-top: 1px solid ${theme.palette.default.grey};
  }
`;

// Search Styles
export const StyledAppInput = styled.div`
  & {
    display: flex;
    align-items: center;
    position: relative;
    input {
      border-radius: 4px;
      outline: none;
      border: 1px solid transparent;
      padding: 0 1.3rem 0 14px;
      font-family: ${theme.typography.fontFamily};
      border: 1px solid ${theme.palette.default.grey};
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      background-color: ${theme.palette.secondary.main};
      max-width: 320px;
      min-width: 320px;
      min-height: 30px;
    }
    svg {
      position: absolute;
      right: 10px;
      width: 15px;
    }
  }

  &::placeholder {
    color: ${theme.palette.default.grey};
  }
`;

export const Scroll = styled.div`
  & {
    overflow: auto;
    height: calc(100vh - 400px);
  }
`;
// Source Type Text
export const FaqTextWrap = styled.div`
  background-color: ${theme.palette.default.white};
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  .ri-add-line {
    color: ${theme.palette.primary.main} !important;
  }
  .top-header {
    background-color: ${theme.palette.default.white};
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 2rem;

    .header-left {
      max-width: calc(100% - ${(props: any) => props.width});
      p {
        font-size: 14px;
        color: red;
        font-weight: 400;
        line-height: 22px;
        color: #7e8392;
      }
    }
    .serach-class {
      // min-width: 438px;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      // .delete-button {
      //   position: absolute;
      //   top: auto;
      //   left: -120px;
      // }
      button {
      }
    }
  }

  .front-image {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 368px);
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  .front-text {
    font-family: inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #7e8392;
  }
  .button svg {
    max-width: 25px;
  }
`;

// Document and text table
export const DocumentAndTextTableWrapper = styled.div`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    .table-header {
      .table-row {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        height: 60px;
      }

      .table-row > div:first-child {
        max-width: 60px;
        border-right: none;
      }
      .table-row > div:nth-child(2) {
        min-width: 688px;
      }

      .table-row > div:nth-child(5) {
        border-right: none;
      }

      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 60px;
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
        font-size: 13px;
      }
    }

    // Body
    .table-body {
      .table-row {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      .table-row > div:first-child {
        max-width: 60px;
      }
      .table-row > div:nth-child(2) {
        min-width: 688px;
        color: ${theme.palette.default.black};
      }
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 60px;
    }
    .col-body {
      display: flex;
      word-break: break-all;
      flex: 1;
      align-items: center;
      padding: 8px 16px;
      font-size: 13px;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

export const NotAnsweredTableWrapper = styled.div`
  & {
    width: 100%;
    min-width: 1010px;
    background-color: ${theme.palette.default.white};
    .table-header {
      .table-row {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        height: 60px;
      }

      .table-row > div:first-child {
        max-width: 60px;
        border: none;
      }
      .table-row > div:nth-child(2) {
        min-width: 570px;
      }

      .table-row > div:nth-child(3) {
        // min-width: 570px;
        // border: 1px solid;
      }

      .table-row > div:nth-child(4) {
        border-right: none;
      }

      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 160px;
        .MuiSvgIcon-root {
          width: 24px;
        }
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
        font-size: 13px;
        font-weight: normal;
      }
    }

    // Body
    .table-body {
      overflow: auto;
      .table-row {
        display: flex;
        padding: 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      .table-row > div:first-child {
        max-width: 60px;
        // padding-left: 30px;
      }
      .table-row > div:nth-child(2) {
        min-width: 570px;
        color: ${theme.palette.default.black};
      }
      .table-row > div:nth-child(3) {
        // min-width: 570px;
        // color: ${theme.palette.default.black};
      }
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 160px;
    }
    .col-body {
      display: flex;
      word-break: break-all;
      flex: 1;
      align-items: center;
      padding: 8px 16px;
      font-size: 13px;
      color: ${theme.palette.default.black};

      .status {
        display: flex;
        gap: 8px;
        color: #25926a;
        padding: 4px 16px;
        align-items: center;
        background-color: #e5fff6;
        border: 1px solid #12c584;
        border-radius: 4px;
        justify-content: center;
      }

      .status-failed {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }

      .status-uploaded {
        display: flex;
        gap: 8px;
        color: ${theme.palette.primary.main};
        padding: 4px 16px;
        align-items: center;
        background-color: ${theme.palette.default.pattensBlue};
        border: 1px solid ${theme.palette.primary.main};
        border-radius: 4px;
        justify-content: center;
      }
      .in-progress {
        display: flex;
        gap: 8px;
        color: #918548;
        padding: 4px 16px;
        align-items: center;
        background-color: #fffbe5;
        border: 1px solid #ffe55e;
        border-radius: 4px;
        justify-content: center;
      }
    }
  }
`;

export const UploadPopupWrap = styled.div`
height: 100%;
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    align-items: center;
    padding: 16px 22px;
    height: 63px;
  }

  .tex-area-wrapper {
    textarea {
      min-height: calc(100vh - 536px) !important;
    }
  }


  .popup-buttons {
        padding: 16px 22px;
        height: 68px;
        .submit-btn {
          button {
            span {
              .ri-arrow-right-line:before {
                color: #fff;
              }
            }
          }
        }
      }

  .body {
    .input-field {
      .content {
        // height: calc(100vh - 500px);
        overflow: auto;
        padding: 24px 16px 32px 16px;
        .label-tooltip {
          display: flex;
          gap: 8px;
          align-items: center;
          .MuiSvgIcon-root {
            font-size: 16px;
            color: ${theme.palette.default.darkGrey};
          }
        }
        .title {
          margin-bottom: 24px;
        }
      }
      .popup-buttons {
        padding: 16px 22px;
        height: 68px;
        .submit-btn {
          button {
            span {
              .ri-arrow-right-line:before {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .crawl-heading p {
    color: #323232;
    font-weight: 400;
    font-style: inter;
    font-size: 16px;
    line-height: 24px;
  }

  h3 {
    line-height: 30px;
    font-weight: 500;
    font-style: inter;
    font-size: 20px;
    color: #000000;
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #f5f7ff;
    border: 1px solid #bfcaff;
    padding: 10px;
    margin-top: 19px;
  }
  .paragraph p {
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .popup-buttons {
    display: flex;
    justify-content: end;
    gap: 2rem;
    border-top: 1px solid lightgray;
  }
  .app-input {
    margin-bottom: 0;
  }

  .upload-file {
    border: 1px dotted rgba(16, 16, 16, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 29px;
    margin-bottom: 16px;
  }
`;

export const UploadFilePopupWrap = styled.div`
  .crawl-popup {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${theme.palette.default.grey};
    align-items: center;
    padding: 22px 16px;

    p {
      color: #323232;
      font-weight: 400;
      font-style: inter;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .header {
    margin-bottom: 55px;
  }

  h3 {
    line-height: 30px;
    font-weight: 500;
    font-style: inter;
    font-size: 20px;
    color: #000000;
  }
  .paragraph {
    display: flex;
    gap: 15px;
    align-items: center;
    background-color: #f5f7ff;
    border: 1px solid #bfcaff;
    padding: 10px;
    margin-top: 16px;
    p {
      font-family: inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .input-field {
    margin-top: 1rem;
  }
  .input-field label {
    font-style: inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }

  .popup-buttons {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    border-top: 1px solid ${theme.palette.default.grey};
  }

  .upload-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 16px 40px 16px;
    .header {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
  }

  .upload-file p {
    font-family: inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }
`;
export const WebsiteWrap = styled.div`
  .website-tabs .MuiTab-root {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: ${theme.palette.default.darkGrey};
    margin-left: 1rem;
    text-transform: capitalize;
  }

  .website-tabs .MuiTab-root.Mui-selected,
  .website-tabs .MuiTab-root.Mui-selected:hover {
    color: ${theme.palette.default.blue};
  }
`;
export const CustomTabPanelContainer = styled.div`
  // background-color: white;
  margin: 1rem;
  // height: 700px;
`;

// boatknowladge******

export const CustomizeWrap = styled.div`
  && {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
  }
`;
export const MainBox = styled.div`
  && {
    width: 100%;
    height: 100%;
    height: calc(100vh - 163px);
    background-color: ${theme.palette.default.lighterGreyNew};
    overflow: auto;
  }
`;
// botknowladge sub crawl url
export const LinkListWraper = styled(List)`
  & {
    border: 1px solid ${theme.palette.default.lightGrey};
    width: 100%;

    overflow: auto;

    li.MuiListItem-root.list-item {
      height: 48px;
      border-bottom: 1px solid #7e839226;
      background: #7e83921a;
    }
    .list-body.MuiBox-root {
      max-height: calc(100vh - 618px);
      overflow: auto;
    }
    .list-body.MuiBox-root li {
      border-bottom: 1px solid #7e839226;
    }

    .error-message {
      text-align: center;
      font-size: 16px;
      padding: 20px 0;
    }
  }
`;

export const ListItemWraper = styled(ListItem)`
  & {
    border-bottom: "1px solid #7E839226";
    color: ${theme.palette.default.blue};
    font-size: "13px";
    padding: 2px 10px;
  }
`;

export const NotAnsweredModalWrap = styled.div`
  & {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 16px;
      border-bottom: 1px solid ${theme.palette.default.grey};
      .header_left {
        h4 {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .header_right {
      }
    }
    .body {
      padding: 22px 16px;
    }
    .info {
      padding: 0px 16px 16px;
      h4 {
        font-size: 14px;
        font-weight: 500;
      }
      ul {
        padding-top: 10px;
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        color: ${theme.palette.default.darkGrey};
        li {
          margin-bottom: 10px;
        }
      }
    }
    .footer {
      border-top: 1px solid ${theme.palette.default.grey};
      display: flex;
      padding: 22px 16px;
      gap: 8px;
      justify-content: flex-end;
    }
  }
`;
// style for table

// Document and text table
export const FaqTable = styled.div`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    .table-header {
      .table-row {
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
      }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
        font-size: 13px;
        font-weight: normal;
      }

      .table-row > div:first-child {
        max-width: 60px;
        border: none;
      }
      // .table-row > div:nth-child(2) {
      //   max-width: 80px;
      //   border: none;

      // }
      .table-row > div:nth-child(2) {
        min-width: 800px;
        border-right: 1px solid rgba(126, 131, 146, 0.5) !important;
      }
      .table-row > div:nth-child(4) {
        border: none;
      }
      .table-row > div:last-child {
        border-right: none;
        justify-content: flex-end;
        max-width: 200px;
        min-width: 200px;
      }
    }

    // Body
    .table-body {
      .table-row {
        display: flex;
        padding: 16px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      .table-row > div:first-child {
        max-width: 80px;
      }
      // .table-row > div:nth-child(2) {
      //   max-width: 80px;
      //   padding-left: 30px;
      // }
      .table-row > div:nth-child(2) {
        min-width: 800px;
        padding-left: 20px;
        color: ${theme.palette.default.black};
      }
    }

    .table-row > div:last-child {
      border-right: none;
      justify-content: flex-end;
      max-width: 200px;
      min-width: 200px;
    }
    .col-body {
      display: flex;
      word-break: break-all;
      flex: 1;
      align-items: center;
      padding: 8px 16px;
      color: ${theme.palette.default.darkGrey};
      font-size: 13px;
    }
  }
`;
