import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { format } from "date-fns";
import { FunctionComponent, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { device } from "../../../Customization/BreakPoints";
import { ProjectVersionConfiguration } from "../../../Models";
import {
  getMatomoConfig,
  useProjectConfigStore,
} from "../../../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import {
  AnalyticsLoadingArray,
  BotList,
  botDateRange,
  project_id
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import {
  removeLoadingFromArray
} from "../../../Store/Slices/DashboardRedux/dashboard.slice";
import SessionDevicesGraph from "../../Analytics/SessionDevicesGraph";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "../AnalyticsSummaryStyles";
import ConversationRateLogo from "../Dash_Assests/conversationrate.svg";
import EngagementRateLogo from "../Dash_Assests/enagagementrate.svg";
import {
  SkeletonCard,
  SkeletonShortTableData,
} from "../SkeletonLoader.tsx/SkeletonCard";
import CSAT from "../SummaryCSAT/CSAT";
import AcquisitionByLocation from "./AcquisitionByLocation";
import AvgDuration from "./AvgDuration";
import { WrapperBot } from "./BotStyle";
import BrowserLanguage from "./BrowserLanguage";
import ChannelOverview from "./ChannelOverview";
import Intents from "./Intents";
import MessageExchanged from "./MessagesExchanged";
import TotalConversationFailed from "./TotalAnsweredFailed";
import TotalConversations from "./TotalConversations";

const ResponsiveGridLayout: any = WidthProvider(Responsive);

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
  isDraggable?: boolean;
  isResizable?: boolean;
  isRearrangeable?: boolean;
}

const Bot2: FunctionComponent<Props> = (props) => {
  const loadingArray: any = useAppSelector(AnalyticsLoadingArray);

  const [mounted, setMounted] = useState(false);
  const [loadingArray1, setLoadingArray] = useState<any>([]);
  const [compactType, setCompactType] = useState<
    "vertical" | "horizontal" | null | undefined
  >("vertical");
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });
  var [deviceData, setDeviceData] = useState<any>([]);
  const [engagementRate, setEngagementRate] = useState<any>(0);
  const [conversionRate, setConversionRate] = useState<any>(0);
  const [botratingCount, setBotratingCount] = useState<any>({});
  const [botavgCsat, setBotavgCsat] = useState<any>(0);
  const [campaignData, setCampaignData] = useState<any>([]);

  const [matomoConfig, setMatomoConfig] =
    useState<ProjectVersionConfiguration>();
  const { data: projectConfigs } = useSelector(useProjectConfigStore);

  useEffect(() => {
    if (projectConfigs) {
      const mConfig = getMatomoConfig(projectConfigs);
      setMatomoConfig(mConfig);
    }
  }, [projectConfigs]);

  const dispatch = useAppDispatch();
  const botList = useAppSelector(BotList);
  const projectId = useAppSelector(project_id);

  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onDrop = (layout: any, layoutItem: any, _ev: any) => {
    // alert(
    //   `Element parameters:\n${JSON.stringify(
    //     layoutItem,
    //     ["x", "y", "w", "h"],
    //     2
    //   )}`
    // );
  };

  // Device ApI Call
  const getDevicesData = () => {
    let payload: any = {
      from_date: format(dashdateRangeFromRedux.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRangeFromRedux.endDate, "yyyy-MM-dd"),
    };
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
        site_id: projectId,
      };
    }
    dispatch(DashboardActions.getAquisitionByDevice(payload))
      .then((res: any) => {
        setDeviceData(res.payload.data || 0);
        dispatch(removeLoadingFromArray("deviceData"));
      })
      .catch((err) => {
        dispatch(removeLoadingFromArray("deviceData"));
      });
  };

  const getaverageCsat = () => {
    let payload: any = {
      from_date: format(dashdateRangeFromRedux.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRangeFromRedux.endDate, "yyyy-MM-dd"),
      type: "bot",
    };
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
      };
    }
    payload.csatRef = "csatDetails";
    dispatch(DashboardActions.getCsatDetails(payload))
      .then((res: any) => {
        let data: any = res?.payload;
        setBotratingCount(data?.data?.per_rating_count || {});
        setBotavgCsat(data?.data?.average_csat_rating || 0);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(removeLoadingFromArray("csatDetails"));
      });
  };
  // Conversion and Engagement Rate Api Call

  const getConversionAndEngagementRate = () => {
    let payload: any = {
      from_date: format(dashdateRangeFromRedux.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRangeFromRedux.endDate, "yyyy-MM-dd"),
      site_id: projectId,
    };
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
      };
    }
    dispatch(DashboardActions.getEnagementandConversion(payload))
      .then((res: any) => {
        setEngagementRate(res?.payload?.data?.data?.engagement_rate || 0);
        setConversionRate(res?.payload?.data?.data?.conversion_rate || 0);
        dispatch(removeLoadingFromArray("engagementRate"));
      })
      .catch((err) => {
        dispatch(removeLoadingFromArray("engagementRate"));
      });
  };

  const getchanneloverview = () => {
    setLoadingArray([...loadingArray, "channelOverview"]);
    let payload: any = {
      from_date: format(dashdateRangeFromRedux.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRangeFromRedux.endDate, "yyyy-MM-dd"),
    };
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
      };
    }

    dispatch(DashboardActions.getAquisitionByOverview(payload))
      .then((res: any) => {
        let data: any = res?.payload;
        setCampaignData(data?.data?.data || []);
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(removeLoadingFromArray("channelOverview"));
      });
  };

  const dashdateRangeFromRedux: any = useAppSelector(botDateRange);

  useEffect(() => {
    if (dashdateRangeFromRedux?.startDate) {
      getaverageCsat();
      getchanneloverview();
    }
  }, [dashdateRangeFromRedux, botList]); //eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (dashdateRangeFromRedux?.startDate && projectId && botList?.length) {
      getConversionAndEngagementRate();
      getDevicesData();
    }
  }, [dashdateRangeFromRedux, botList, projectId]); //eslint-disable-line react-hooks/exhaustive-deps
  return (
      <WrapperBot>
        <div className="parent_agent">
          <ResponsiveGridLayout
            {...props}
            //   layouts={layouts}
            measureBeforeMount={false}
            useCSSTransforms={mounted}
            compactType={compactType}
            preventCollision={!compactType}
            //onLayoutChange={onLayoutChange}
            onBreakpointChange={onBreakpointChange}
            onDrop={onDrop}
            isDroppable
            isBounded={true}
          >
            <div
              key="a"
              data-grid={{
                x: 0,
                y: 0,
                w: 4,
                h: 6,
                minW: 4,
                minH: 7,
                maxH: 7,
                maxW: 4,
                static: true,
              }}
              className="MessageBox"
            >
              <MessageExchanged />
            </div>

            <div
              key="b"
              data-grid={{
                x: 4,
                y: 0,
                w: 3,
                h: 6,
                minW: 3,
                minH: 7,
                maxH: 7,
                maxW: 3,
                static: true,
              }}
              className="MessageBox"
            >
              <AvgDuration />
            </div>

            <div
              key="d"
              data-grid={{
                x: 7,
                y: 0,
                w: 3,
                h: 6,
                minW: 3,
                minH: 7,
                maxH: 7,
                maxW: 3,
                static: true,
              }}
              className="botCsat"
            >
              <div>
                <p className="time_stats">
                  <TextTitle>Overall CSAT</TextTitle>
                  <AppToolTip
                    divStyle={{ padding: "0 8px" }}
                    tooltipProps={{
                      title: "The overall CSAT of the bots",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
                  />
                </p>
              </div>
              <div className="botCsat_info">
                <div className="botCsat_info_d">
                  <p className="botCsat_info_count">{botavgCsat || "0"}</p>
                  <p className="botCsat_info_total">Avg CSAT</p>
                </div>
                <div className="csat_graph">
                  <CSAT
                    ratingCount={botratingCount}
                    loading={loadingArray.includes("csatDetails")}
                  />
                </div>
              </div>
            </div>

            <div
              key="e"
              data-grid={{
                x: 0,
                y: 6,
                w: 7,
                h: 5,
                minW: 7,
                minH: 6,
                maxH: 6,
                maxW: 7,
                static: true,
              }}
            >
              <TotalConversations />
            </div>

            <div
              key="f"
              data-grid={{
                x: 7,
                y: 6,
                w: 3,
                h: 5,
                minW: 3,
                minH: 6,
                maxH: 6,
                maxW: 3,
                static: true,
              }}
              className="failedCoversation"
            >
              <TotalConversationFailed />
            </div>

            <div
              key="g"
              data-grid={{
                x: 0,
                y: 11,
                w: 5,
                h: 5,
                minW: 5,
                minH: 6,
                maxH: 6,
                maxW: 5,
                static: true,
              }}
              className="rate"
            >
              <div className="rateWrapper">
                <p className="rateHeader">
                  Conversation Rate
                  <AppToolTip
                    tooltipProps={{
                      title:
                        "The number of visitors that given their contact information",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
                  />
                </p>
                <div className="rateInformation">
                  <div className="rateCount">
                    {loadingArray.includes("engagementRate") ? (
                      <SkeletonCard />
                    ) : (
                      conversionRate + "%"
                    )}
                  </div>
                </div>

                <div className="conversationRateLogo">
                  <img src={ConversationRateLogo} alt="" />
                </div>
              </div>
              <hr className="dotted_hr"></hr>
              <div className="rateWrapper">
                <p className="rateHeader">
                  Engagement Rate
                  <AppToolTip
                    tooltipProps={{
                      title:
                        "Percentage of visitors who interacted with the bot out of total visitors.",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
                  />
                </p>
                <div className="rateInformation">
                  <div className="rateCount">
                    {loadingArray.includes("engagementRate") ? (
                      <SkeletonCard />
                    ) : (
                      engagementRate + "%"
                    )}
                  </div>
                </div>
                <div className="engagementRateLogo">
                  <img src={EngagementRateLogo} alt="" />
                </div>
              </div>
            </div>

            <div
              key="h"
              data-grid={{
                x: 0,
                y: 16,
                w: 7,
                h: 8,
                minW: 7,
                minH: 9,
                maxH: 9,
                maxW: 7,
                static: true,
              }}
              className="tableContainer"
            >
              <ChannelOverview
                campaignData={campaignData}
                loading={loadingArray.includes("channelOverview")}
              />
            </div>

            <div
              key="i"
              data-grid={{ x: 7, y: 16, w: 3, h: 8, static: true }}
              className="tableContainer"
            >
              <p className="time_stats">
                <TextTitle>Acquisitions by Device</TextTitle>
                <AppToolTip
                  divStyle={{ padding: "0 8px" }}
                  tooltipProps={{
                    title:
                      "Tells you what type of devices are used by your visitors.",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
                />
              </p>
              <StyledBox>
                {loadingArray.includes("deviceData") ? (
                  <div>
                    <SkeletonShortTableData />
                  </div>
                ) : (
                  <SessionDevicesGraph rows={deviceData} />
                )}
              </StyledBox>
            </div>

            <div
              key="j"
              data-grid={{ x: 0, y: 24, w: 7, h: 8, static: true }}
              className="tableContainer"
            >
              <AcquisitionByLocation />
            </div>

            <div
              key="k"
              data-grid={{ x: 7, y: 24, w: 3, h: 8, static: true }}
              className="tableContainer"
            >
              <BrowserLanguage />
            </div>

            <div
              key="l"
              data-grid={{
                x: 0,
                y: 32,
                w: 10,
                h: 8,
                minW: 10,
                minH: 14,
                maxH: 14,
                maxW: 10,
                static: true,
              }}
              className="tableContainer"
            >
              <Intents />
            </div>
          </ResponsiveGridLayout>
        </div>
      </WrapperBot>
  );
};

Bot2.defaultProps = {
  className: "layout",
  rowHeight: 30,
  // onLayoutChange: (layout: any, layouts: any) => {},
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  preventCollision: true,
  isDraggable: false,
  isResizable: false,
  isRearrangeable: false,
};

export default Bot2;

const StyledBox = styled.div`
  && {
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
    background-color: white;

    border-radius: 8px;
    /* width: 28rem !important; */
    false &:nth-of-type(1) {
      width: 100%;
    }
    &:nth-of-type(2) {
      width: 30%;
    }
    @media ${device.laptopM} {
      width: 100%;
      &:nth-of-type(1) {
        width: 100%;
      }
      &:nth-of-type(2) {
        width: 100%;
      }
    }

    /* @media ${device.laptopL} {
      width: 100%;
      &:nth-of-type(1) {
        width: 60%;
      }
      &:nth-of-type(2) {
        width: 40%;
      }
    } */
  }
`;
