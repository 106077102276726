// vendors
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ButtonProps } from "@mui/material/Button";

// components
import NodeType from "../NodeType";
import Icon from "../Icon";
import Button from "../Button";

// styles
import {
  ContainerStyled,
  HeaderStyled,
  ContentWrapperStyled,
  FooterStyled,
} from "./modal.styled";

interface PrimaryButtonConfig extends ButtonProps {
  label: string;
}

interface SecondaryButtonConfig extends ButtonProps {
  label: string;
}

interface Props {
  type: "alert" | "default";
  size: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth?: boolean;
  fullScreen?: boolean;
  open: boolean;
  onClose: (event: React.MouseEvent<HTMLElement>) => void;
  primaryButtonConfig: PrimaryButtonConfig;
  secondaryButtonConfig: SecondaryButtonConfig;
  children: React.ReactElement;
  header: {  title: string, icon?: string, color?: string};
}

const UIModal = (props: Props) => {
  const {
    open,
    onClose,
    primaryButtonConfig,
    secondaryButtonConfig,
    children,
    size = "xs",
    fullWidth = false,
    fullScreen = false,
    header,
  } = props;

  return (
    <Modal
      slotProps={{
        backdrop: {
          timeout: 500,
          sx: {
            background: "rgba(16, 16, 16, 0.08)",
            backdropFilter: "blur(3px)",
          },
        },
      }}
      open={open}
      onClose={onClose}
      className="ignore-onClickOutside"
    >
      <ContainerStyled
        className="ignore-onClickOutside"
        size={size}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
      >
        {header && <HeaderStyled flexDirection="row" justifyContent="space-between">
          <NodeType icon={header.icon} text={header.title} color={header.color} noPadding />
          <IconButton size="small" onClick={onClose}>
            <Icon icon="close" size={16} color="default.darkGrey" />
          </IconButton>
        </HeaderStyled>}
        <ContentWrapperStyled className="ignore-onClickOutside">{children}</ContentWrapperStyled>
        <FooterStyled flexDirection="row" justifyContent="space-between">
          {secondaryButtonConfig.label && secondaryButtonConfig.onClick && (
            <Button
              variant="outlined"
              {...secondaryButtonConfig}
              onClick={secondaryButtonConfig.onClick}
            >
              {secondaryButtonConfig.label}
            </Button>
          )}
          {primaryButtonConfig.label && primaryButtonConfig.onClick && (
            <Button
              variant="contained"
              color="warning"
              {...primaryButtonConfig}
              onClick={primaryButtonConfig.onClick}
            >
              {primaryButtonConfig.label}
            </Button>
          )}
        </FooterStyled>
      </ContainerStyled>
    </Modal>
  );
};

export default UIModal;
