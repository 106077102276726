import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Field, getIn, useFormikContext } from "formik";
import styled from "styled-components";
import { Caption, Label } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";
import moment from "moment";
import { useAppSelector } from "../../Store";
import { SMSDataStore } from "../../Store/Slices/SMS/sms.selectors";
import { useParams } from "react-router-dom";

interface Props {
  label?: string;
  subTitle?: string;
  name: string;
  utcTime?: boolean;
  tooltip?: any;
  divStyle?: any;
  broadcastByIdData ?:any
  children?: React.ReactNode;
  disablePast?: boolean;
  requiredSign?:boolean;
  [otherProps: string]: any;
}

const AppFormTimePickerMui: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  utcTime = false,
  subTitle,
  children,
  broadcastByIdData,
  disablePast,
  requiredSign = false,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values,
  } = useFormikContext<any>();
  const {  broadcast_id }: any = useParams();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Div style={divStyle}>
        <Label>
        {requiredSign ? (
            <>
              {label}
              <sup className="starSuperScript"> *</sup>
            </>
          ) : (
            label
          )}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        {subTitle && <Caption>{subTitle}</Caption>}
        <Input>
          {({ field }: any) => (
            <MobileTimePicker
              {...field}
              closeOnSelect
              className="input-time-picker"
              value={broadcastByIdData?.sent_on ? new Date(broadcastByIdData?.sent_on): getIn(values, name)}
              onClose={() => setFieldTouched(name, true)}
              onChange={(newValue) => {
                newValue && utcTime
                  // ? setFieldValue(name, moment.utc(newValue).format())
                  ? setFieldValue(name, newValue)
                  : setFieldValue(name, newValue);
                setFieldTouched(name, true)
              }}
              {...otherProps}
            />
          )}
        </Input>

        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </Div>
    </LocalizationProvider>
  );
};
export default AppFormTimePickerMui;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
    .MuiOutlinedInput-input {
      padding: 0 0 0 0;
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
      color: red;
    }
    .MuiTextField-root {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background-color: #f5f6f8;
      outline: none !important;
      padding: 0.4rem 0.5rem 0.4rem 1rem;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      border: 2px solid transparent;
    }
  }
`;

const Input = styled(Field)`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    border: 2px solid ${theme.palette.default.border};
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.darkGrey} !important;
    background-color: #e0e0e0 !important;
    opacity: 65% !important;
  }
`;
