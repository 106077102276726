import styled from "styled-components";
import { theme } from "../../Customization/Theme";

export const ParentDonoughtWrapper = styled.div`
  && {
    .dounoht_header {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 24px 10px 10px;
      border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      .div_tooltip {
        font-size: 10px;
      }
    }
  }
`;
export const DonoughtWrapper = styled.div`
  & {
    display: flex;
    align-items: center;
    justify-content: ${(props: any) =>
      props.isdata ? "flex-start" : "space-around"};
    gap: 15px;
    height: 100%;
  }

  .dounout_info {
    width: 50%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding-left: 10px;
  }

  .tooltip_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 400px;
  }

  .dounought_sub_header {
    font-size: 16px;
  }

  .dounought_sub_header_div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    padding: 5px;
  }

  .dounhoght_dot {
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;
  }

  .subheader_Sub {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .value_div {
    display: flex;
    width: 100%;
    gap: 15px;
    align-items: center;
  }

  .value_div_p {
    position: relative;
    font-size: 14px;
    font-weight: 400;
    max-width: 70%;
    margin-right: 10px;
    color: ${theme.palette.default.greyText};
  }

  .value_div_span {
    position: relative;
    font-size: 16px;
    font-weight: 500;
    width: 30%;
  }

  .donout_graph {
    height: 95%;
    width: 180px;
    margin: 0 auto;
  }

  @media (max-width: 1660px) {
    .dounoht_header {
      font-size: 14px;
    }
    .dounought_sub_header {
      font-size: 14px;
    }
    .donout_graph {
      width: 165px;
      height: 50%;
      margin: 0 auto;
    }
    .value_div_p {
      position: relative;
      font-size: 12px;
    }
    .value_div {
      display: flex;
      algn-items: center;
      width: 100%;
      gap: 0px;
    }
    .value_div_span {
      font-size: 12px;
    }
  }
  @media (max-width: 1440px) {
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
    .dounhoght_dot {
      min-width: 12px;
      min-height: 12px;
    }
    .dounout_info {
      width: 100%;
      height: auto;
      gap: 5px;
      margin-left: 15px;
      margin-top: 15px;
      &.agent {
        flex-direction: row;
        margin-left: 0;
      }
    }
  }
`;
