import { Avatar } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";

export const ChatCardWrapper = styled.div`
  && {
    border-left: ${(props: any) =>
      props?.active
        ? `4px solid ${theme.palette.primary.main}`
        : "4px solid tranparent"};

    background-color: ${(props: any) =>
      props?.active
        ? `rgba(91, 115, 232, 0.08)`
        : `${theme.palette.default.white}`};
    display: flex;
    width: 364px;
    border-bottom: 1px solid ${theme.palette.default.fadeGrey};
    /* max-height: 162px; */

    .main-left {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0px 0px 0px ${(props: any) => (props?.active ? "4px" : "8px")};
      z-index: 9;
      .avatar {
        .MuiCheckbox-root {
          width: 40px;
          height: 40px;
          display: none;
          svg {
            width: 22px;
            height: 22px;
          }
        }
        .MuiBadge-root {
          display: inline-flex;
        }
      }

      .showCheckbox {
        .MuiCheckbox-root {
          display: block;
        }
        .MuiBadge-root {
          display: none;
        }
      }
    }

    .main-right {
      width: calc(100% - 40px);
      display: flex;
      flex-direction: column;
      cursor: pointer !important;
      justify-content: space-between;
      padding: 4px 0px 4px 12px;
      min-height: 124px;
      .top {
        display: flex;
        flex-direction: column;
        cursor: pointer !important;
      }

      .content {
        cursor: pointer;
        width: 100%;
        h5 {
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
        }
      }

      .middle {
        cursor: pointer;
        width: fit-content !important;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-bottom: 4px;

        .description {
          font-size: 0.875rem;
          font-weight: 400;
          height:24px;
          color: ${theme.palette.default.darkGrey};
          cursor: pointer;
          padding-bottom: 4px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .tags {
          width: fit-content !important;
          display: flex;
          gap: 5px;
          .remainingTags {
            display: flex;
            align-items: center;
            font-size: 12px;
          }
        }
      }

      .title-content{
        /* padding-top:6px; */
        /* padding-bottom:4px; */
        cursor: pointer !important;
      }
      .bottom {
        cursor: pointer !important;
        display: flex;
        justify-content: space-between;
        .action-top {
          display: flex;
          align-items: center;
          gap: 10px;
          p {
            font-size: 10px;
            color: ${theme.palette.default.darkGrey};
          }
          & .MuiSvgIcon-root {
            display: flex;
            color: ${theme.palette.default.darkGrey};
            font-size: 1.1rem;
          }
          .chat-menu {
            display: flex;
            align-items: center;
          }
        }

        .channels {
          display: flex;
          align-items: center;
          gap: 4px;
          p {
            font-size: 0.8rem;
            font-weight: 400;
            color: ${theme.palette.default.darkGrey};
            cursor: pointer;
          }
          img {
            object-fit: contain;
          }
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .action-bottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 6px 8px 4px 0px;
      cursor: pointer !important;
      .action-bottom__left {
        display: flex;
        justify-content: space-between;
        gap: 4px;
        align-items: center;
        cursor: pointer !important;
        gap: 8px;
      }
      .open-status {
        color: #548faf;
        background-color: rgba(116, 205, 255, 0.15);
      }

      .close-status {
        background-color: #cbeee1;
        color: #353636;
      }

      .pending-status {
        background-color: rgba(255, 166, 3, 0.15);
        color: #ffa603;
      }

      .open {
        font-size: 0.75rem;
        padding: 0.2rem 0.6rem;

        border-radius: 116px;
        display: flex;
        align-items: center;
      }
      .open-with {
        font-family: ${theme.typography.fontFamily};
        background: rgba(91, 115, 232, 0.1);
        font-size: 0.7rem;
        display: flex;
        align-items: center;
        width: 22px;
        justify-content: center;
        border-radius: 50%;
      }

      .unreadCount {
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.default.white};
        font-weight: 500;
        font-size: 10px;
        border-radius: 50%;
        min-width: 18px;
        height: 18px;
        padding: 4px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        overflow: hidden;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .agentAvatar{
        width: 18px;
        height: 18px;
        font-size: 12px !important;
        cursor: pointer !important;
      }
    }

    .main-right:hover {
      cursor: pointer !important;
    }

    .avatar {
      cursor: pointer;
      .check {
        display: none;
      }
      .avatar-icon {
        position: relative;
      }
      .avatar-channel {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 9999;
        background-color: #fff;
        border-radius: 50%;
      }
    }
    .body {
      padding-left: 12px;
      flex-grow: 1;
      display: flex;
      gap: 8px;
      flex-direction: column;
      cursor: pointer;
      .content {
        min-height: 34px;
      }

      h5 {
        font-size: 0.875rem;
        font-weight: 500;
        cursor: pointer;
      }
      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${theme.palette.default.darkGrey};
        cursor: pointer;
      }
      .channels {
        display: flex;
        gap: 4px;
        img {
          object-fit: contain;
        }
      }
      .tags {
        display: flex;
        gap: 5px;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      width: 50%;

      cursor: pointer;
      .action-top {
        display: flex;
        align-items: center;
        gap: 10px;
        p {
          font-size: 10px;
          color: ${theme.palette.default.darkGrey};
        }
        & .MuiSvgIcon-root {
          display: flex;
          color: ${theme.palette.default.darkGrey};
          font-size: 1.1rem;
        }
        .chat-menu {
          display: flex;
          align-items: center;
        }
      }
      .action-bottom {
        display: flex;
        justify-content: space-between;
        gap: 8px;

        .open-status {
          color: #548faf;
          background-color: rgba(116, 205, 255, 0.15);
        }

        .close-status {
          background-color: #cbeee1;
          color: #353636;
        }

        .pending-status {
          background-color: rgba(255, 166, 3, 0.15);
          color: #ffa603;
        }

        .open {
          font-size: 0.75rem;
          padding: 0.2rem 0.6rem;

          border-radius: 116px;
          display: flex;
          align-items: center;
        }
        .open-with {
          font-family: ${theme.typography.fontFamily};
          background: rgba(91, 115, 232, 0.1);
          font-size: 0.7rem;
          display: flex;
          align-items: center;
          width: 22px;
          justify-content: center;
          border-radius: 50%;
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    &:hover {
      .avatar {
        .MuiCheckbox-root {
          display: block;
        }
        .MuiBadge-root {
          display: none;
        }
      }
    }
  }
`;

interface StyledProps {
  color: string;
}

export const CustomTag = styled.div<StyledProps>`
  && {
    background-color: ${theme.palette.default.white};
    color: ${(props: any) =>
      props?.color ? props?.color : theme.palette.default.darkGrey};

    font-size: 0.75rem;
    font-weight: 400;
    border: 1px solid rgba(126, 131, 146, 0.15);
    padding: 2px 12px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
    .bullet {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${(props: any) =>
        props?.color ? props?.color : theme.palette.default.darkGrey};
    }

    .removeTag {
      display: none;
      cursor: pointer;
      svg {
        font-size: 12px;
      }
    }

    &:hover {
      cursor: pointer;
      .removeTag {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const CardLine = styled.hr`
  border-top: 1px solid rgba(126, 131, 146, 0.2);
`;
export const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${(props: any) => props.$bgColor};
    color: ${(props: any) => props.$textColor};
    width: 32;
    height: 32;
    font-size: 1.1rem;
    font-weight: 400;
  }
`;

export const StyledChannelBadgeWrapper = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    padding: 2px 2px 2px 2px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;
