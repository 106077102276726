import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { IconButton } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";

interface Props {
  children?: React.ReactNode;
  [otherProps: string]: any;
}
const AppSendSubmitButton: React.FC<Props> = ({ children, ...otherProps }) => {
  const { isValid } = useFormikContext();

  return (
    <div className="inputActionBox">
      <IconButton
        disableRipple
        type="submit"
        {...otherProps}
      >
        <SendRoundedIcon
          className="SendRoundedIcon-3D"
          style={!isValid ? { color: `#7E8392` } : {}}
        />
      </IconButton>
    </div>
  );
};

export default AppSendSubmitButton;
