// vendors
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// components
import Icon from "../../Components/Icon";
import Input from "../../Components/Input";
import UIButton from "../../Components/Button";

// utils
import { getActionConfig } from "../../Utils";

// slices
import { setActiveAction } from "../../Slices/main.slice";
import { useAppDispatch } from "../../../../Store";

const Function = (props: any) => {
  const { showPreview, onSave, onCancel } = props;
  const action = getActionConfig(props.type);
  const [text, updateText] = useState<string>(props.text || "");
  const dispatch = useAppDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    updateText(event.target.value);
  };

  const handleSave = () => {
    const activeAction = (({ onSave, onCancel, showPreview, ...o }) => o)({ ...props, text })
    onSave && onSave(activeAction);
  };

  const handleCancel = () => {
    if (!text) {
      const activeAction = (({ onSave, onCancel, showPreview, ...o }) => o)({ ...props })
      dispatch(setActiveAction(activeAction));
      onCancel && onCancel(true);
      return;
    }
    onCancel && onCancel(false);
  };

  return (
    <>
      {showPreview ? (
        <Box
        className="ignore-onClickOutside"
          sx={{ p: "12px 16px" }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Icon icon={action?.icon} size={16} color={"primary.light"} />
            <Typography
              variant="body1"
              sx={{ fontWeight: 500}}
              color="primary.light"
            >
              {action?.name} {props.stage && props.stage}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box sx={{ p: "12px 16px" }} className="ignore-onClickOutside">
          <Input
            placeholder="Type here"
            value={text}
            onChange={handleChange}
            multiline
            rows={10}
            className="ignore-onClickOutside"
          />
          <Box
            sx={{
              mt: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <UIButton variant="outlined" onClick={handleCancel}>
              Cancel
            </UIButton>
            <UIButton variant="contained" onClick={handleSave} disabled={!text}>
              Save
            </UIButton>
          </Box>
        </Box>
      )}
    </>
  );
};

const FunctionAction = React.memo(Function);

export default FunctionAction;