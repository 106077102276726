import React, { useEffect } from 'react'
import { ConfirmationWrapper, StaticDataWrapper } from '../Styles';
import { ReactComponent as ConfirmImage } from "../../../../../assets/images/email-confirm.svg";
import ConfirmImageSVG from "../../../../../assets/images/email-confirm.svg";
import AppButton from '../../../../../AppComponents/AppButton';
import { AppSubmitButton } from '../../../../../AppComponents/Forms';
import { AppConfig } from '../../../../../Config/app-config';
import { BROADCAST_EMAIL_FORM_DATA, Marketing_Name } from '../../Utils/Constants';
import { getIn, useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../Store';
import { marketingListStore } from '../../../../../Store/Slices/MarketingList/marketingList.selectors';
import { CreditDetailsActions } from "../../../../../Store/Slices/CreditDetails/CreditDetails.actions";
import { Box } from '@mui/material';
import TestEmailTemplate from './TestEmailTemp';

function RenderFormStep4(props: any) {
    let currentUser = (AppConfig.getUserData(BROADCAST_EMAIL_FORM_DATA)
        || '{}');
    const {
        allMarketingListData,
    } = useAppSelector(marketingListStore);
    const dispatch = useAppDispatch();
    let marketinglistName = (localStorage.getItem(Marketing_Name) || '{}');

    const { values } = useFormikContext<any>()


    useEffect(() => {
        dispatch(CreditDetailsActions.getBroadcastCreditDetails(values.id))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <ConfirmationWrapper>
            {/* <div className='heading'>Broadcast Confirmation</div> */}
            <StaticDataWrapper>
                <div className='Main_Box'>
                    <div className='Main_div alt-row'>
                        <div className='activities'>BroadCast Name</div>
                        <div className='sub_div'>{currentUser.broadcast_name || "NA"}</div>
                    </div>
                    <div className='Main_div'>

                        <div className='activities'>Email Subject</div><div className='sub_div'>{currentUser.subject || "NA"}</div>
                    </div>
                    <div className='Main_div alt-row'>

                        <div className='activities'>From Name</div><div className='sub_div'>{currentUser.from_name || "NA"}</div>
                    </div>

                    <div className='Main_div'>

                        <div className='activities'>From Email</div><div className='sub_div'>{currentUser.from_email || "NA"}</div>
                    </div>

                    <div className='Main_div alt-row' >

                        <div className='activities'>Reply To</div><div className='sub_div'>{currentUser.reply_to || "NA"}</div>
                    </div>

                    <div className='Main_div'>

                        <div className='activities'>List Name</div><div className='sub_div'>{allMarketingListData.filter((res: any) => res?.id === values?.marketing_list)?.[0]?.name || "NA"}</div>
                    </div>

                    {/* <div className='Main_div'>

                <div className='activities' >Created_at</div><div className='sub_div'>Message</div>
            </div> */}



                    {/* <div className="BtnsBox">
                        <AppButton
                            onClick={() => props.handleBackClick()}
                            variant="grey"
                            disabled={props.isEditingBroadcast}
                        >
                            {props.formStep === 1 ? "Reset" : "Back"}
                        </AppButton>
                        {props.formStep === 5 ? (
                            <AppSubmitButton
                                title={
                                    props.isEditingBroadcast ? "Update" : "Schedule Broadcast"
                                }
                            />
                        ) : (
                            <AppSubmitButton title="continue" />
                        )}
                    </div> */}
                </div>
                {localStorage.getItem("HTMLTemplate") ? <div style={{
                    width: "50%",
                    marginTop: "-9.7rem"
                }}>
                    <iframe
                        title="HTML Preview"
                        srcDoc={JSON.parse(localStorage.getItem("HTMLTemplate") || "") || ""}
                        className="iframe"
                        style={{
                            width: "calc(100% - 40px)",
                            height: "60vh",
                            border: "1px solid #ccc",
                            padding: "5px",
                            margin: "0 auto",
                            display: "block",
                        }}
                    />
                </div> :null}
                <TestEmailTemplate />
            </StaticDataWrapper>
        </ConfirmationWrapper>
    )
}

export default RenderFormStep4