import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
export const WrapperBot = styled.div`
  & {
    display:flex;
    flex-direction:column;
     width: 100%;
     background-color:#F5F6F8;
     
     .parent_agent{
      width: 100%;
      padding: 10px;
      background-color:#F5F6F8;
      height: auto;
     }

     .MessageBox{
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius:10px;
      font-family: Inter;
     }
   
   
     .botCsat{
      width: 100%;
      height: 100%;
      background-color: white;
      border-radius:10px;
      font-family: Inter;
      display:flex;
      flex-direction:column;
      .time_stats {
        font-size: 18px;
        display: flex;
        font-weight: 500;
        align-items: center;
        padding: 10px 24px 10px 10px;
        border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
       
        .div_tooltip {
          font-size: 10px;
          margin-top: 0.094rem;
        }
      }
     }
     .botCsat_header{
      display:flex;
      flex-direction:row;
      align-items:center;
      margin-top: 30px;
      margin-left: 22px;
      font-size:16px;
      font-family:Inter;
      font-weight:500;
      color:#282828;
      .div_tooltip{
          font-size: 10px;
      }
     }
     .botCsat_header1{
      font-size:20px;
      font-family: Inter;
      font-weight:400;
     }
     .botCsat_info{
      width:100%;
      display:flex;
      flex-direction:row;
      justify-content:space-around;
      padding:10px;
      gap:5px;
      margin:auto;
      font-family: Inter;
      align-items: center;
     }
     .botCsat_info_d{
      width:30%;
      display:flex;
      flex-direction:column;
      justify-content:space-evenly;
      padding:20px;
      gap:47px;
      font-family: Inter;
     }
     .botCsat_info_count{
      font-size:32px;
      font-family:sans-serif;
      font-family: Inter;
      text-align:center;
     }
     .botCsat_info_total{
      font-size:14px;
      margin-top:-40px;
      font-family:sans-serif;
      font-family: Inter;
      text-align:center;
     }
     .csat_graph{
      width:60%;
      font-family: Inter;
     }
     
     .failedCoversation{
      width: 100%;
      height: 100%;
      padding: 40px;
      background-color: rgb(255, 255, 255);
      border-radius: 10px;
      justify-content: center;
     }
  
     
     
     .intentHeader{
      font-size: 16px;
       padding-bottom: 20px;
       display: flex;
     }

     .channel-table-cell{
      width:  73%;
      padding-left: 68px;
      color: #7E8392;
      height: 60px;
     }

     @media only screen and (max-width: 1550px){
      .channel-table-cell{
        width:  77%;
        padding-left: 60px;
        color: #7E8392;
        height: 60px;
       }
     }

     @media only screen and (max-width: 1800px){
      .channel-table-cell{
        width:  75%;
        padding-left: 60px;
        color: #7E8392;
        height: 60px;
       }
     }

     @media (max-width:1820px){
      .intentHeader{
        display:flex;
        flex-direction:row;
        align-items:center;
        font-size: 14px,
         padding-bottom: 20px,
         display: flex,
       }
     }
     
     .rate {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color: white;
      border-radius:10px;
      padding:20px;
      font-family: Inter;
    }
    
   
     
      .dotted_hr {
        height: 80%;
        border: 0.2px dashed gray; 
        opacity:50%; 
        margin:auto;
      }
     
     .rateWrapper{
      
      width: 50%;
      
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items:start;
      font-size: 20px;
      padding:20px;
      font-family: Inter;
     }
     .rateHeader{
      text-align: left;
      color:#282828;
      font-size: 16px;
      display:flex;
      font-family: Inter;
      font-weight:500;
      align-items:center;
      .div_tooltip{
        font-size: 10px;
      }
     }
     .rateInformation{
      width: 100%;
      display: flex;
      justify-content: space-between;
     
     
      padding-left:10px;
      background-color: white;
      align-items:left;
      text-align:left;
      gap:10px
      padding-right:10px;
      font-family: Inter;
     }
     .rateCount{
       font-size: 32px;
       font-family: Inter;
       font-weight:500;
     }
     
      .engagementRateLogo{
        pointer-events: all;
        right: 14px;
        width: 100%;
        
        display: flex;
        position: absolute;
         justify-content: flex-end;
        bottom: 12px;
       }
       .engagementRateLogo>img{
        width:56px;
        height:56px;
       }

       .conversationRateLogo{
        pointer-events: all;
        right: 14px;
        width: 100%;
        
        display: flex;
        position: absolute;
        -webkit-box-pack: end;
         justify-content: center;
        bottom: 12px;
        padding-right:68px;
       }
       .conversationRateLogo>img{
        width:56px;
        height:56px;
       }
      
     .tableContainer{
      height:100%;
      width:100%;
      border-radius:10px;
      font-family: Inter;
      background-color:#ffffff;
      .time_stats {
        font-size: 18px;
        display: flex;
        font-weight: 500;
        align-items: center;
        padding: 10px 24px 10px 10px;
        border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
        
        .div_tooltip {
          font-size: 10px;
          margin-top: 0.094rem;
        }
      }
      .time_stats {
        font-size: 18px;
        display: flex;
        font-weight: 500;
        align-items: center;
        padding: 10px 24px 10px 10px;
        border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
        
        .div_tooltip {
          font-size: 10px;
          margin-top: 0.094rem;
        }
      }
      .div_tooltip{
        font-size: 10px;
      }
     }
    
    
     .IntenttableHeader{
      font-size: 18px;
       display:flex;
       flex-direction:row;
       align-items:center;
       font-family:Inter;
       font-weight:500;
       .div_tooltip{
        font-size: 10px;
       }
     }
  }

  @media (max-width:1820px){
    .botCsat_header{
      display:flex;
      flex-direction:row;
      align-items:center;
      margin-top: 30px;
      margin-left: 22px;
      font-size:14px;
      font-family:Inter;
      font-weight:500;
      .div_tooltip{
          font-size: 10px;
      }
     }

    

    .engagementRateLogo{
      pointer-events: all;
      right: 14px;
      width: 100%;
    
      display: flex;
      position: absolute;
      -webkit-box-pack: end;
      justify-content: flex-end;
      bottom: 12px;
     }
     .engagementRateLogo>img{
      width:56px;
      height:56px;
     }

     .conversationRateLogo{
      pointer-events: all;
      right: 14px;
      width: 100%;
     
      display: flex;
      position: absolute;
      -webkit-box-pack: end;
       justify-content: center;
      bottom: 12px;
      padding-right:68px;
     }
     .conversationRateLogo>img{
      width:56px;
      height:56px;
     }
    
  }
`;
export const TotalTable = styled.div`
  & {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;

    background-color: white;
    border-radius: 10px;
    .totalConversation {
      width: 50%;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-bottom: 50px;
      gap: 40px;
    }

    .conversationInfo {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
    }
    .conversationCount {
      font-size: 32px;
      font-family: Inter;
      font-weight: 500;
    }
    .doted_hr {
      height: auto;
      border: 0.2px dashed gray;
    }

    .avgMessages {
      width: 50%;
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-start;
      gap: 30px;
    }
    .avgMessages_text {
      text-align: left;
      font-size: 16px;
      display: flex;
      font-family: Inter;
      font-weight: 500;
      color: #282828;
      .div_tooltip {
        font-size: 10px;
      }
    }
    .avgMessagesInformation {
      width: 100%;
      display: flex;
      justify-content: space-between;

      gap: 30px;
      background-color: white;
    }
    .avgMessagesCount {
      font-size: 32px;
      font-family: Inter;
      font-weight: 500;
    }
    .avgMessagesIcon {
      pointer-events: all;
      right: 14px;
      width: 100%;

      display: flex;
      position: absolute;
      -webkit-box-pack: end;
      justify-content: flex-end;
      bottom: 12px;
    }
    .avgMessagesIcon > img {
      width: 56px;
      height: 56px;
    }
    .dotted_hr {
      height: 88%;
      border: 0.2px dashed gray;
      opacity: 50%;
      margin: auto;
    }

    .parent-img {
      position: relative;
      right: 10px;
      bottom: 10px;
    }

    .image-bot {
      width: 56px;
      height: 56px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  @media (max-width: 1820px) {
    .avgMessages_text {
      text-align: left;
      font-size: 14px;
      display: flex;
      font-family: Inter;
      font-weight: 500;
      vertical-align: text-top;
      .div_tooltip {
        font-size: 10px;
      }
    }

    .avgMessagesIcon {
      pointer-events: all;
      right: 14px;
      width: 100%;

      display: flex;
      position: absolute;
      -webkit-box-pack: end;
      justify-content: flex-end;
      bottom: 12px;
    }
    .avgMessagesIcon > img {
      width: 56px;
      height: 56px;
    }
  }
`;
export const AvgDurationWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 10px;

    .avg_duration_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
    }

    .parent-img {
      position: relative;
    }

    .img-bot {
      position: absolute;
      right: 10px;
      top: 147px;
      width: 56px;
      height: 56px;
    }

    .b_div1 {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.5rem;
      vertical-align: middle;
      align-items: flex-start;
      padding-left: 45px;
      padding-top: 35px;
    }
    .b_div1_header {
      text-align: left;
      font-size: 15px;
      align-items: center;
      justify-content: center;
      display: flex;
      font-family: Inter;
      font-weight: 500;
      color: ${theme.palette.default.black};
      .div_tooltip {
        font-size: 10px;
      }
    }
    .b_div1_info {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
    .b_div1_info_count {
      font-size: 32px;
      text-align: left;
      font-family: Inter;
      font-weight: 500;
    }
    .b_div1_parametr {
      font-size: 18px;

      font-family: Inter;
      font-weight: 500;
    }
    .dotted_hr {
      height: 85%;
      border: 0.2px dashed gray;
      opacity: 50%;
      margin: auto;
    }

    .b_div2_info_icon {
      pointer-events: all;
      right: 14px;
      width: 100%;

      display: flex;
      position: absolute;
      -webkit-box-pack: end;
      justify-content: flex-end;
      bottom: 12px;
    }
    .b_div2_info_icon > img {
      width: 56px;
      height: 56px;
    }
    .time {
      font-size: 16px;
    }
  }

  @media (max-width: 1600px) {
    .b_div1 {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      vertical-align: middle;
      align-items: flex-start;
      padding-left: 24px;
    }

    .b_div2 {
      width: 50%;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      justify-content: center;
      gap: 1rem;
      vertical-align: middle;
      align-items: flex-start;
      padding-left: 24px;
    }
  }
  @media (max-width: 1440px) {
    .b_div1_info_count {
      font-size: 28px;
    }
    .b_div1 {
      padding-left: 15px;
    }
    .b_div1_header {
      font-size: 13px;
    }
  }
`;
export const TotalFailedWrapper = styled.div`
  & {
    .FailedWrap {
      display: flex;
      gap: 35px;
      flex-direction: column;
      justify-content: space-around;
    }
    .failed_text {
      text-align: left;
      font-size: 16px;
      display: flex;
      font-family: Inter;
      font-weight: 500;
      color: #282828;
      .div_tooltip {
        font-size: 10px;
      }
    }
    .failedInformation {
      width: 100%;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;

      gap: 30px;
      background-color: white;
    }
    .failedCount {
      font-size: 32px;
      font-family: Inter;
      font-weight: 500;
    }
    .failedImage {
      pointer-events: all;
      right: 14px;
      width: 100%;

      display: flex;
      position: absolute;
      -webkit-box-pack: end;
      justify-content: flex-end;
      bottom: 12px;
    }
    .failedImage > img {
      width: 56px;
      height: 56px;
    }
    .dotted_hr {
      height: 60%;
      border: 0.2px dashed gray;
      opacity: 50%;
      margin: auto;
    }
  }
`;
