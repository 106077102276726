import { Backdrop, Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../Customization/Theme";
import { getRoundOffNumbers } from "../Utils";

export interface HocProgressBarLoaderProps {
  setLoading: (status: boolean) => void;
  setProgress: (status: Number) => void;
}

export const HocProgressBarLoader = (WrappedComponent: any) => {
  function HocProgressBarLoader(props: any) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    const [buffer, setBuffer] = useState(10);

    const progressRef = useRef(() => {});
    useEffect(() => {
      progressRef.current = () => {
        if (progress > 100) {
          setProgress(0);
          setBuffer(10);
        } else {
          const diff = Math.random() * 10;
          const diff2 = Math.random() * 10;
          setProgress(progress + diff);
          setBuffer(progress + diff + diff2);
        }
      };
    });

    return (
      <>
        {isLoading && (
          <Backdrop
            open={true}
            sx={{
              zIndex: 9999999999999,
            }}
          >
            <StyledLoaderWrap>
              <Box sx={{ width: "20%" }}>
                <LinearProgress
                  variant="buffer"
                  value={progress}
                  valueBuffer={buffer}
                />
              </Box>
              <Typography variant="h6">
                {progress < 100 ? getRoundOffNumbers(progress) : "100"} %
              </Typography>
              <Typography variant="body1">
                {
                  progress >= 100 ? "Your File has been uploaded successfully, we're still processing your file. Please wait for few mins." : 'Please wait while we are processing your request.'
                }
                
              </Typography>
            </StyledLoaderWrap>
          </Backdrop>
        )}
        <WrappedComponent
          {...props}
          setLoading={setIsLoading}
          setProgress={setProgress}
        />
      </>
    );
  }
  return HocProgressBarLoader;
};

const StyledLoaderWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.3rem;
    width: 100%;

    p,h6{
      color: ${theme.palette.default.white} !important; //* have to overwrite this *//
    }
  }
`;
