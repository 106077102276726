import { Box, Typography } from "@mui/material";
import { AppMaterialIcons } from "../../../../../../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../../../../../../assets/images/norecordsanalytics.svg";
import { TextTitle } from "../../../../../../../Dashboard/AnalyticsSummaryStyles";
import AppSkeletonLoader from "../../../../../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../../../../../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { AnalyticsHeader, Wrapper } from "../DashboardStyles";

interface Props {
  linkStats?: any;
  loading?: any;
}

const TopLinksStats: React.FC<Props> = ({ linkStats, loading }) => {
  return loading ? (
    <AppSkeletonLoader />
  ) : (
    <Wrapper>
      <div className="j_div_Link">
        <AnalyticsHeader>
          <Box className="heading__tooltip">
              <TextTitle style={{
                marginLeft: "0px",
              }}>
              <Typography className="heading">Top Error Reasons</Typography>
            </TextTitle>
            <AppToolTip
              tooltipProps={{
                title:
                  "Displays the primary causes for failed or undelivered messages in detail.",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<AppMaterialIcons iconName="HelpOutline" />}
            />
          </Box>
        </AnalyticsHeader>

        <div className="j_div_Link_all">
          {linkStats && linkStats?.length > 0 ? (
            linkStats.map((el: any) => {
              return (
                
                  <div className="date_with_link">
                    <p className="link_a">{el?.error_reason}</p>
                    <p className="Date_info">{el?.count}</p>
                  </div>
                  
                
              );
            })
          ) : (
                  <NoRecordsFound
                    imageSrc={NoRecordImage}
                    imageWidth={"200px"}
                    headerTextSize={"19px"}
                  />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default TopLinksStats;
