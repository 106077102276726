import axios from "axios";
import { AppConfig, USER_BUSINESSES_KEY } from "../Config/app-config";
import { convertToLocaleDates } from "./Utils";
// import store from '../Store';
// import {logoutAuth} from '../Store/Slices/AuthSlice';

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const PAYMENT_REQUIRED = 402;

const addLoginHeaders = (config: any) => {
  const data = AppConfig.getLoggedInData();
  if (data) {
    const token = data?.token;
    const domain = data?.data?.geta_host;

    if (token && token.length > 0) {
      config.headers["Authorization"] = token;
    }
    if (domain && domain.length > 0) {
      config.headers["GETA-HOST"] = domain;
    }
  }
  return config;
};

let coreBaseUrl = process.env.REACT_APP_CORE_API_BASE_URL ? process.env.REACT_APP_CORE_API_BASE_URL : 'http://core.local:8000/';
coreBaseUrl = coreBaseUrl + 'api/v1/'
let conversationBaseUrl = process.env.REACT_APP_CH_BOT_API_BASE_URL ? process.env.REACT_APP_CH_BOT_API_BASE_URL : 'http://core.local:8000/';
conversationBaseUrl = conversationBaseUrl + 'api/v1/'

let chatbotConversationBaseUrl = process.env.REACT_APP_CHATBOT_CONVERSATION_API_BASE_URL ? process.env.REACT_APP_CHATBOT_CONVERSATION_API_BASE_URL : 'http://localhost:8002/';
chatbotConversationBaseUrl = chatbotConversationBaseUrl + 'api/v1/'
let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL ? process.env.REACT_APP_CH_CONSOLE_API_BASE_URL : 'http://core.local:8000/';
consoleBaseUrl = consoleBaseUrl + 'api/v1/'
let paraphraserBaseUrl = process.env.REACT_APP_PARAPHRASER_BASE_URL ? process.env.REACT_APP_PARAPHRASER_BASE_URL : 'http://core.local:8004/';
paraphraserBaseUrl = paraphraserBaseUrl + 'api/v1/';
let chatbotConsoleCeleryBaseUrl = process.env.REACT_APP_CELERY_API_ENDPOINT ? process.env.REACT_APP_CELERY_API_ENDPOINT : 'http://localhost:8005/';
chatbotConsoleCeleryBaseUrl = chatbotConsoleCeleryBaseUrl + 'api/v1/';

const coreAxios = axios.create({
  baseURL: coreBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const conversationAxios = axios.create({
  baseURL: conversationBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
const chatbotConversationAxios = axios.create({
  baseURL: chatbotConversationBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
const chatbotConsoleAxios = axios.create({
  baseURL: consoleBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
const chatbotConsoleCeleryAxios = axios.create({
  baseURL: chatbotConsoleCeleryBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

const matomoAxios = axios.create({
  baseURL: process.env.REACT_APP_MATOMO_API_BASE_URL
    ? process.env.REACT_APP_MATOMO_API_BASE_URL
    : "http://core.local:8080/",
  headers: {
    Accept: "*/*",
  },
});
const paraphraserAxios = axios.create({
  baseURL: paraphraserBaseUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});

//request interceptors for core api
coreAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

//response interceptors for core api
coreAxios.interceptors.response.use(
  function (response) {

    return convertToLocaleDates(response);
  },
  function (error) {
    if (error.hasOwnProperty("response")) {
      const { status } = error.response;
      if (status === FORBIDDEN) {
        console.log("Forbidden 403 found access denied");
        window.location.href = "/forbidden";
      }
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
        AppConfig.setLoggedInData(null);
        AppConfig.setUserData(USER_BUSINESSES_KEY, null);
        if (error.request.responseURL.indexOf("auth/login") < 0) {
          window.location.href = "/login";
        }
      }
      if (status === PAYMENT_REQUIRED) {
        if (window.location.href.indexOf("/plans") < 0) {
          window.location.href = "/plans";
        }
      }
    }
    return Promise.reject(error);
  },
);

//request interceptors for conversation api
conversationAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

//request interceptors for chatbot conversation api
chatbotConversationAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

//request interceptors for chatbot conversation api
chatbotConversationAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
//request interceptors for paraphraser api
paraphraserAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

//response interceptors for conversation api
conversationAxios.interceptors.response.use(
  response => convertToLocaleDates(response),
  error => {
    // 
    if (error.hasOwnProperty('response')) {
      const { status } = error.response;
      if (status === FORBIDDEN) {
        console.log("Forbidden 403 found access denied")
        window.location.href = "/forbidden"
      }
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
        AppConfig.setLoggedInData(null);
        AppConfig.setUserData(USER_BUSINESSES_KEY, null);
        window.location.href = "/login"
      }
      if (status === PAYMENT_REQUIRED) {
        if (window.location.href.indexOf("/plans") < 0) {
          window.location.href = "/plans"
        }
      }
    }
    return Promise.reject(error);
  }
);

//response interceptors for chatbot conversation api
chatbotConversationAxios.interceptors.response.use(
  response => convertToLocaleDates(response),
  error => {
    // 
    if (error.hasOwnProperty('response')) {
      const { status } = error.response;
      if (status === FORBIDDEN) {
        console.log("Forbidden 403 found access denied")
        window.location.href = "/forbidden"
      }
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
        AppConfig.setLoggedInData(null);
        AppConfig.setUserData(USER_BUSINESSES_KEY, null);
        window.location.href = "/login"
      }
      if (status === PAYMENT_REQUIRED) {
        if (window.location.href.indexOf("/plans") < 0) {
          window.location.href = "/plans"
        }
      }
    }
    return Promise.reject(error);
  }
);
//response interceptors for paraphraser api
paraphraserAxios.interceptors.response.use(
  (response) => convertToLocaleDates(response),
  (error) => {
    //
    if (error.hasOwnProperty("response")) {
      const { status } = error.response;
      if (status === FORBIDDEN) {
        console.log("Forbidden 403 found access denied");
        window.location.href = "/forbidden";
      }
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
        AppConfig.setLoggedInData(null);
        AppConfig.setUserData(USER_BUSINESSES_KEY, null);
        window.location.href = "/login";
      }
      if (status === PAYMENT_REQUIRED) {
        if (window.location.href.indexOf("/plans") < 0) {
          window.location.href = "/plans";
        }
      }
    }
    return Promise.reject(error);
  },
);

//request interceptors for chatbot-console api
chatbotConsoleAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

//response interceptors for chatbot-console api
chatbotConsoleAxios.interceptors.response.use(
  (response) => convertToLocaleDates(response),
  (error) => {
    //
    if (error.hasOwnProperty("response")) {
      const isVerifyLink = error?.request?.responseURL?.indexOf("/verify");
      const { status } = error.response;
      if (status === FORBIDDEN) {
        console.log("Forbidden 403 found access denied");
        window.location.href = "/forbidden";
      }
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
        AppConfig.setLoggedInData(null);
        AppConfig.setUserData(USER_BUSINESSES_KEY, null);

        if (!isVerifyLink) {
          window.location.href = "/login";
        }
      }
      if (status === PAYMENT_REQUIRED) {
        if (window.location.href.indexOf("/plans") < 0) {
          window.location.href = "/plans";
        }
      }
    }
    return Promise.reject(error);
  },
);

//request interceptors for chatbot-console api
chatbotConsoleCeleryAxios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

//response interceptors for chatbot-console api
chatbotConsoleCeleryAxios.interceptors.response.use(
  (response) => convertToLocaleDates(response),
  (error) => {
    //
    if (error.hasOwnProperty("response")) {
      const isVerifyLink = error?.request?.responseURL?.indexOf("/verify");
      const { status } = error.response;
      if (status === FORBIDDEN) {
        console.log("Forbidden 403 found access denied");
        window.location.href = "/forbidden";
      }
      if (status === UNAUTHORIZED) {
        // store.dispatch(logoutAuth(null));
        AppConfig.setLoggedInData(null);
        AppConfig.setUserData(USER_BUSINESSES_KEY, null);

        if (!isVerifyLink) {
          window.location.href = "/login";
        }
      }
      if (status === PAYMENT_REQUIRED) {
        if (window.location.href.indexOf("/plans") < 0) {
          window.location.href = "/plans";
        }
      }
    }
    return Promise.reject(error);
  },
);

export { coreAxios, conversationAxios, chatbotConsoleAxios, matomoAxios, paraphraserAxios, chatbotConsoleCeleryAxios, chatbotConversationAxios };