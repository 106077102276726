import { Close } from "@material-ui/icons";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Backdrop, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormObserver,
  AppFormRadioField,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { singleEmailBroadcastSchema } from "../../../Models/ValidationSchema";
import { CoreService } from "../../../Services/Apis/CoreApis";
import { useAppDispatch } from "../../../Store";
import { ContactListNetworkService } from "../../../Store/Slices/ContactList/ContactListNetworkService";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import { setUserCreditsInfo } from "../../../Store/Slices/CurrentUser/CurrentUserSlice";
import { EmailNetworkService } from "../../../Store/Slices/email/EmailNetworkService";
import { FacebookCircularProgress } from "../../../socket/appComponents/UtilsComponents/AppNoActivityScreen";
import MediaFilePicker from "../../Broadcast/Email/ScheduledBroadcast/MediaFilePicker";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { convertFormToPayloadSingleEmailBroadcast } from "./utils/constants";
import AppFormRichTextEditor from "../../../AppComponents/Forms/AppFormRichTextEditor";

interface Props {
  actionDrawerChildComponent: any;
  setActionDrawerChildComponent: (value: any) => void;
}

const EmailSingleBroadcast = ({
  actionDrawerChildComponent,
  setActionDrawerChildComponent,
}: Props) => {
  const dispatch = useAppDispatch();
  const formikRef: any = useRef<any>(null);
  const { showAlert } = useNotification();
  const [loading, setLoading] = useState<boolean>(true);

  const [basicData, setBasicInfo] = useState<any>(null);
  const [templateOptions, setTemplateOptions] = useState<any>([]);
  const [senderFieldOptions, setSenderFieldOptions] = useState<any>([]);
  const [validatedEmailOptions, setValidatedEmailOptions] = useState<any>([]);
  const [emailFooterOptions, setEmailFooterOptions] = useState<any>([]);
  const [formikProps, setFormikProps] = useState<any>(null);

  const [userInput, setUserInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    sender_field_value: null,
    sender_field: "",
    template: null,
    resolved_header_variables: [],
    header_media: null,
    header_type: null,
    header_media_type: null,
    body_preview: "",
    resolved_body_variables: [],
    resolved_buttons_variables: [],
    footer_value: null,
    contact_id: "",
    from_email: "",
    from_name: "",
    reply_to: "",
    subject: "",
    footer: "",
    email_attachment: null,
    choose_template_type: {
      name: "Choose Email Template",
      value: "choose_email_template",
      id: "1",
    },
    html_email_body: ""
  });

  const getTemplateData = (value: any) => {
    if (!value?.id) return;
    setLoading(true);
    EmailNetworkService.getEmailTemplateList(value?.id)
      .then((res: any) => {
        formikRef?.current?.setFieldValue("template", res?.data ?? null);
      })
      .catch((error: any) => {
        formikRef?.current?.setFieldValue("template", null);
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getIntialData = () => {
    let payload = {
      contact_id: actionDrawerChildComponent?.id,
    };
    Promise.allSettled([
      ContactListNetworkService.getContactPropertiesList({}),
      ContactsNetworkService.getBasicInfo(payload),
      EmailNetworkService.getEmailTemplatesLists({
        limit: 9999,
        offset: 0,
      }),
      EmailNetworkService.getValidateEmail(),
      EmailNetworkService.getFooterList(),
    ])
      .then((results) => {
        const [
          allContactPropertiesResult,
          basicInfoResult,
          templatesResult,
          validatedEmailsResult,
          emailFooterResults,
        ] = results;

        if (
          basicInfoResult?.status === "fulfilled" &&
          allContactPropertiesResult?.status === "fulfilled" &&
          templatesResult?.status === "fulfilled" &&
          validatedEmailsResult?.status === "fulfilled" &&
          emailFooterResults?.status === "fulfilled"
        ) {
          const basicData = basicInfoResult?.value?.data ?? null;
          const templatesData =
            templatesResult?.value?.data?.results?.filter(
              (item: any) => item?.status === "APPROVED"
            ) ?? [];
          const allContactPropertiesData =
            allContactPropertiesResult?.value?.data?.data ?? null;
          const validatedEmailsData =
            validatedEmailsResult?.value?.data?.response.filter(
              (item: any) => item?.validated === true
            ) ?? null;
          const emailFooterData =
            emailFooterResults?.value?.data?.response?.data ?? null;

          const senderFields =
            allContactPropertiesData?.filter(
              (item: any) => item?.type === "email"
            ) ?? [];
          setSenderFieldOptions(senderFields ?? []);
          setTemplateOptions(templatesData ?? []);
          setValidatedEmailOptions(validatedEmailsData ?? []);
          setEmailFooterOptions(emailFooterData ?? []);
          setUserInput({
            ...userInput,
            contact_id: actionDrawerChildComponent?.id,
            first_name: basicData?.general_information?.first_name ?? "",
            last_name: basicData?.general_information?.last_name ?? "",
            email: basicData?.general_information?.email ?? "",
            mobile: basicData?.general_information?.mobile_phone_number ?? "",
            sender_field_value: basicData?.general_information?.email ?? "", // adding bcz of api not ready for getting field value
            sender_field:
              senderFields?.find((item: any) => item?.name === "email") ?? "", // adding bcz of api not ready for getting field value
            // from_email:
            // validatedEmailsData?.find(
            //     (item: any) => item?.default === true
            //   ) ?? "",
          });
        } else {
          showAlert("Enabled to fetch Data", "error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (actionDrawerChildComponent?.id) {
      getIntialData();
    }
  }, [actionDrawerChildComponent?.id]); // eslint-disable-line

  const handleSubmitBtn = (values: any, submitProps: any) => {
    setLoading(true);
    const payload = convertFormToPayloadSingleEmailBroadcast(values);
    EmailNetworkService.sendEmailMessage(payload)
      .then((res: any) => {
        showAlert(res?.data?.message || "Message sent!", "success");
        submitProps?.resetForm();
        setActionDrawerChildComponent(null);
        CoreService.getCredits().then((response: any) => {
          const reversedItems = response?.data;
          dispatch(setUserCreditsInfo(reversedItems));
        });
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Message sending failed",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
        submitProps.setSubmitting(false);
      });
  };

  const onSenderFieldChange = (value: any) => {
    if (!formikRef.current) return;
    //TODO GET DYNAMIC FIELD VALUE FROM CONTACT
    //API NOT READY FOR GETTING 1 FIELD VALUE OF SINGLE CONTACT
    //THEREFORE SETTING STATIC VALUE FOR MOBILE VALUE
    const dynamicValue = basicData?.general_information?.[value?.name] ?? "";
    formikRef.current?.setFieldValue("sender_field_value", dynamicValue ?? "");
  };

  const chooseTemplateRadioOptions: any[] = [
    {
      name: "Choose Email Template",
      value: "choose_email_template",
      id: "1",
    },
    {
      name: "Write HTML",
      value: "write_html",
      id: "2",
    },
  ];

  return (
    <React.Fragment>
      {loading ? (
        <Backdrop open={loading} style={{ zIndex: 9999999 }}>
          <FacebookCircularProgress />
        </Backdrop>
      ) : null}
      <StyledDrawerHeader>
        <p className="title">
          {" "}
          <EmailOutlinedIcon /> Send Email{" "}
        </p>
        <IconButton
          onClick={(e: any) => setActionDrawerChildComponent(null)}
          title="close"
        >
          <Close />
        </IconButton>
      </StyledDrawerHeader>
      <AppForm
        innerRef={formikRef}
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={singleEmailBroadcastSchema}
        divStyle={{
          flex: 1,
        }}
      >
        <StyledDrawerWrapper>
          <div className="basicInfoBox">
            <p className="row">
              <span className="label">First Name:</span>
              <span className="value">{userInput?.first_name}</span>
            </p>
            <p className="row">
              <span className="label">Last Name:</span>
              <span className="value">{userInput?.last_name}</span>
            </p>
            <p className="row">
              <span className="label">Mobile:</span>
              <span className="value">{userInput?.mobile}</span>
            </p>
            <p className="row">
              <span className="label">Email:</span>
              <span className="value">{userInput?.email}</span>
            </p>
          </div>
          <div className="inlineFields">
            <AppReactSelectField
              menuPosition="fixed"
              label={"Sender Field"}
              options={senderFieldOptions ?? []}
              valueKey={"id"}
              displayKey="label"
              name={"sender_field"}
              placeholder={`Select field`}
              isClearable={true}
              requiredSign={true}
              isLoading={loading}
              onChangeCallbackFn={(value: any) => {
                onSenderFieldChange(value);
              }}
              resetDependentFieldName={["template"]}
              isDisabled={true}
            />
            <AppFormField
              name="sender_field_value"
              placeholder="abc@domain.com"
              label="Sender email"
              disabled={true}
              requiredSign={true}
            />
          </div>
          <div className="inlineFields">
            <AppReactSelectField
              menuPosition="fixed"
              label={"From Email"}
              options={validatedEmailOptions ?? []}
              valueKey={"email"}
              displayKey="email"
              name={"from_email"}
              placeholder={`Select email`}
              isClearable={true}
              requiredSign={true}
              isLoading={loading}
            // onChangeCallbackFn={(value: any) => {
            //   onSenderFieldChange(value);
            // }}
            // resetDependentFieldName={["template"]}
            // isDisabled={true}
            />
            <AppFormField
              name="from_name"
              placeholder="Jhon"
              label="From name"
              // disabled={true}
              requiredSign={true}
            />
          </div>
          <div className="inlineFields">
            <AppFormField
              name="reply_to"
              placeholder="abc@domain.com"
              label="Reply to"
              // disabled={true}
            />
            <AppReactSelectField
              menuPosition="fixed"
              label={"Footer"}
              options={emailFooterOptions ?? []}
              valueKey={"address1"}
              displayKey="address1"
              name={"footer"}
              placeholder={`Select footer`}
              isClearable={true}
              requiredSign={true}
              isLoading={loading}
            // onChangeCallbackFn={(value: any) => {
            //   onSenderFieldChange(value);
            // }}
            // resetDependentFieldName={["template"]}
            // isDisabled={true}
            />
          </div>
          <AppFormField
            name="subject"
            placeholder="Subject:"
            label="Subject"
            requiredSign={true}
          />

          <AppFormRadioField
            name={"choose_template_type"}
            Options={chooseTemplateRadioOptions}
          />

          {formikRef.current?.values?.choose_template_type?.value !== "write_html" ? (
            <AppReactSelectField
              menuPosition="fixed"
              label={"Email Template"}
              options={templateOptions ?? []}
              valueKey={"id"}
              displayKey="label"
              name={"template"}
              placeholder={`Select template`}
              isClearable={true}
              requiredSign={true}
              isLoading={loading}
              onChangeCallbackFn={(value: any) => {
                getTemplateData(value);
              }}
              resetDependentFieldName={[
                "resolved_header_variables",
                "header_media",
                "header_type",
                "header_media_type",
                "body_preview",
                "resolved_body_variables",
                "resolved_buttons_variables",
                "footer_value",
              ]}
            />) : (
            <AppFormRichTextEditor name='html_email_body' mediaKey={"messageonTextLimit"} />
          )}
          <div style={{ display: "flex", width: "100%" }}>
            <label style={{ marginTop: "8px" }}>Email Attachment</label>
            <AppToolTip
              tooltipProps={{
                title:
                  "User is able to Upload document as attachment with different format like pdf,img ,video with valid document size 10MB.",
                placement: "right",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<InfoOutlinedIcon fontSize="small" />}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
            className="upload_file"
          >
            <MediaFilePicker
              name="email_attachment"
              mediaType={"document"}
              sizeLimit={10000000}
              attachmentResponseKeyName="response"
              extensions={[
                "text/csv",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                "application/pdf",
                "application/wps-office.xlsx",
                "application/vnd.ms-excel",
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/svg+xml",
                "video/mp4",
                "video/3gp",
                "image/webp",
              ]}
              className=""
              divStyle={{ margin: "0rem", width: "auto" }}
            // disabled={isView ? true : false}
            />

            <Typography variant="body2">(Maximum file size 10 MB )</Typography>
          </div>
          <AppFormObserver setFormikProps={setFormikProps} />
        </StyledDrawerWrapper>
        <StyledDrawerFooter>
          <AppButton
            variant="grey"
            onClick={(e: any) => setActionDrawerChildComponent(null)}
          >
            Cancel
          </AppButton>
          <AppSubmitButton
            title="Send"
            checkIsValidProperty={false}
          ></AppSubmitButton>
        </StyledDrawerFooter>
      </AppForm>
    </React.Fragment>
  );
};

export default EmailSingleBroadcast;

const StyledDrawerWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    padding: 1rem 2rem;
    height: calc(100vh - 128px);
    overflow-y: auto;
    overflow-x: hidden;
    .inlineFields {
      display: flex;
      gap: 1rem;
    }
    .basicInfoBox {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0rem 0 1rem;
      .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        .label {
          font-weight: 500;
          font-size: 15px;
        }
        .value {
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
`;

const StyledDrawerFooter = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    padding: 1rem 2rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
const StyledDrawerHeader = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        font-size: 21px;
        margin-right: 8px;
      }
    }
  }
`;
