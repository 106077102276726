// styled
import { PaperStyled } from "./Card.styled";

const Card = (props: CardProps) => {
  const {
    children,
    onClick,
    onMouseEnter,
    onMouseLeave,
    secondary,
    focused,
    className,
    ref,
  } = props;
  return (
    <PaperStyled
      ref={ref}
      elevation={0}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={className}
      secondary={secondary}
      focused={focused}
    >
      {children}
    </PaperStyled>
  );
};
export default Card;
