// imports the React Javascript Library
import { useEffect, useState } from "react";

import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { IconButton, Tooltip } from "@mui/material";
import Grow from "@mui/material/Grow";
import { getIn, useFormikContext } from "formik";
import styled from "styled-components";
import { theme } from "../Customization/Theme";
import { ChatbotConsoleService } from "../Services/Apis/ChatbotConsoleService";
import { LoaderComponentProps, withLoader } from "./Loader";
import { HocBackdropLoader } from "../HOC/HocBackdropLoader";

const ALLOWED_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
];

enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
}

interface Props extends LoaderComponentProps {
  name: string;
  sizeLimit: number;
  sizeErrorMsg: string;
  formatErrorMsg: string;
  squareImageBox?: boolean;
}
interface PropsState {
  mainState: any; // initial, search, gallery, uploaded
  imageUploaded: any;
  selectedFile: any;
  fileReader?: any;
  filename?: any;
}

const ImageUploadCard = (props: Props) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    values,
    setFieldValue,
    setFieldError,
  } = useFormikContext<any>();

  const [mainState, setMainState] = useState<ImageState>(ImageState.INITIAL); // initial, search, gallery, uploaded
  const [uploadedFile, setUploadedFile] = useState<any>(null); // initial, search, gallery, uploaded

  useEffect(() => {
    if (
      getIn(values, props?.name)?.source &&
      getIn(values, props?.name)?.source?.length > 0
    ) {
      setMainState(ImageState.UPLOADED);
    } else {
      setMainState(ImageState.INITIAL);

    }
  }, [getIn(values, props?.name)]);

  const handleUploadClick = (event: any) => {
    setFieldTouched(props.name, true);

    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader?.readAsDataURL(file);

    if (props.sizeLimit && file.size > props.sizeLimit) {
      setTimeout(() => {
        setFieldError(props.name, props.sizeErrorMsg);
      }, 1);
      return;
    }

    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      if (!ALLOWED_IMAGE_TYPES.includes(mimeType)) {
        setTimeout(() => {
          setFieldError(props.name, props.formatErrorMsg);
        }, 1);
        return;
      }
      onImageChange(file);
    };
  };

  const onImageChange = (file: any) => {
    setFieldTouched(props.name, true);
    if (file) {
      props.setLoading(true);

      const formData = new FormData();
      formData.append("source", file);

      ChatbotConsoleService.uploadFile(formData)
        .then((response: any) => {
          setFieldValue(props?.name, response?.data);
          setMainState(ImageState.UPLOADED);
        })
        .catch((error: any) => {
          setMainState(ImageState.INITIAL);
          setFieldValue(props?.name, null);
        })
        .finally(() => {
          props.setLoading(false);
        });
    }
  };

  const imageResetHandler = () => {
    setMainState(ImageState.INITIAL);
    setFieldValue(props?.name, null);
  };

  const renderInitialState = () => {
    return (
      <StyledIntialState>
        <input
          accept="image/*"
          id={props.name}
          multiple
          type="file"
          onChange={handleUploadClick}
        // ref={inputRef}
        />
        <label htmlFor={props.name} className="customInput">
          <Tooltip
            arrow
            enterDelay={1}
            leaveDelay={0}
            TransitionComponent={Grow}
            placement="top"
            title={"Upload"}
          >
            {props.squareImageBox ? (
              <SquareImageBox>
                <AddPhotoAlternateIcon />
              </SquareImageBox>
            ) : (
              <IconButton component="span">
                <AddPhotoAlternateIcon />
              </IconButton>
            )}
          </Tooltip>
        </label>
      </StyledIntialState>
    );
  };

  const renderUploadedState = () => {
    return (
      <StyledUploadedState>
        <StyledUploadedImageWrap>
          <img
            src={getIn(values, props?.name)?.source || null}
            style={{ borderRadius: props.squareImageBox ? "4px" : "50%" }}
            alt="media"
          />
          <IconButton onClick={() => imageResetHandler()}>
            <ClearRoundedIcon />
          </IconButton>
        </StyledUploadedImageWrap>
      </StyledUploadedState>
    );
  };

  // useEffect(() => {
  //   props.setLoading(false);
  // }, []);

  return (
    <StyledViewWrap>
      {(mainState == ImageState.INITIAL && renderInitialState()) ||
        (mainState == ImageState.UPLOADED && renderUploadedState())}
    </StyledViewWrap>
  );
};

export default HocBackdropLoader(ImageUploadCard);

const StyledUploadedImageWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      cursor: pointer;
      font-size: 1.1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

const StyledViewWrap = styled.div`
  && {
    height: 70px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 0.5rem;

    input {
      display: none;
    }
  }
`;

const StyledIntialState = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;

    .MuiFab-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
    }

    .MuiIconButton-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.secondary.main};
      border-radius: 50%;
    }
  }
`;

const StyledUploadedState = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    align-items: center;

    img {
      width: 52px !important;
      height: 52px !important;
      object-fit: cover !important;
      overflow: hidden;
    }
  }
`;

const SquareImageBox = styled.div`
  && {
    width: 64px;
    height: 64px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${theme.palette.default.lightGrey};
    border-radius: 6px;
  }
`;
