import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { marketingListActions } from "./marketingList.actions";

interface initialStateTypes {
  data: {
    allMarketingListData: any;
    allMarketingListDataCount: any;
    marketingListByIdData: any;
    createMarketingListData: any;
    createMarketingListContactData: any;
    getFieldsTypeMappingList: any;
    uploadDocumentFileData: any;
    marketingListDeletedData: any;
    filteredDataTotalCount: any;
    marketingListByIdDataTotalCount: any;
    filteredData: any;
    priority_column: any;
    selectedRowCount: number;
    filteredDataCount: number;
    pageNumber: number;
    rowsPerPage: any;
    broadcastByIdData: any;
    saveFormValues: any;
    allUserListing: any;
    allUserListingCount: any;
    ImportFormStepOneRes: any;
    ImportID: any;
    debouncedSearchTerm: any;
    importHistoryData: any;
    fileDownloadUrlData: any;
    importHistoryProgressData: any;
    contactsSelectData: any;
    exportData: any;
    marketingListName: any;
    totalCount: any;
    InitialSelectValues: any[];
    unsubscribeCount: number;
    reportsList: any;
    marketingListByIdDataTotalPages: any;
    totalPages: any;
    sequence: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    allMarketingListData: null,
    allMarketingListDataCount: 0,
    marketingListByIdData: null,
    createMarketingListData: null,
    createMarketingListContactData: null,
    getFieldsTypeMappingList: null,
    uploadDocumentFileData: null,
    marketingListDeletedData: null,
    filteredDataTotalCount: 0,
    filteredData: null,
    priority_column: null,
    totalCount: 0,
    filteredDataCount: 0,
    selectedRowCount: 0,
    unsubscribeCount: 0,
    pageNumber: 0,
    broadcastByIdData: null,
    saveFormValues: {},
    allUserListing: null,
    allUserListingCount: null,
    ImportFormStepOneRes: null,
    ImportID: null,
    debouncedSearchTerm: null,
    importHistoryData: null,
    fileDownloadUrlData: null,
    importHistoryProgressData: null,
    marketingListByIdDataTotalCount: 0,
    contactsSelectData: null,
    exportData: null,
    marketingListName: "",
    InitialSelectValues: [],
    rowsPerPage: { label: "10  per page", value: 10 },
    reportsList: [],
    marketingListByIdDataTotalPages: 0,
    totalPages: 0,
    sequence: [],
  },
  error: "",
  loading: false,
};

const MarketingListSlice = createSlice({
  name: "MarketingListSlice",
  initialState,
  reducers: {
    saveFormSteps(state, { payload }: PayloadAction<any>) {
      state.data.saveFormValues = payload;
    },
    setMarketingListPageNumber(state, { payload }: PayloadAction<any>) {
      state.data.pageNumber = payload;
    },
    updateMarketingListSelecetedRowCount(
      state,
      { payload }: PayloadAction<any>
    ) {
      state.data.selectedRowCount = payload;
    },
    updateMarketingFilteredData(state, { payload }: PayloadAction<any>) {
      state.data.filteredData[payload].isSelected =
        !state.data.filteredData[payload].isSelected;
    },

    removeMarketingListData(state, { payload }: PayloadAction<any>) {
      state.data.filteredData = [];
      state.data.priority_column = {};

      state.data.totalCount = 0;
      state.data.selectedRowCount = 0;
      state.data.unsubscribeCount = 0;
      state.data.filteredDataCount = 0;
    },

    updateAllMarketingFilteredData(state, { payload }: PayloadAction<any>) {
      state.data.filteredData.forEach((item: any) => {
        item.isSelected = payload;
      });
    },

    allImportContactHistory(state, { payload }: PayloadAction<any>) {
      state.data.importHistoryData = payload;
    },
    unMountmarketingListByIdData(state, { payload }: PayloadAction<any>) {
      payload?.data?.forEach((item: any) => {
        item.can_access = item.can_access.toString();
        item.can_update = item.can_update.toString();
      });
      state.data.marketingListByIdData = payload;
      state.data.InitialSelectValues = [];
      state.data.marketingListByIdDataTotalCount = 0;
      state.data.marketingListName = "";
    },
  },
  extraReducers: (builder) => {
    builder

      //* all Marketing List
      .addCase(
        marketingListActions.getAllMarketingListings.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.getAllMarketingListings.fulfilled,
        (state, action) => {
          let updatedData = action.payload.data?.results
          state.data.allMarketingListData = updatedData;
          state.data.allMarketingListDataCount =
            action.payload.data.totalRecords;
          state.data.totalPages = action.payload?.data?.totalPages;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.getAllMarketingListings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allMarketingListData = null;
          state.error = action?.error?.message || "";
        }
      )

      // new marketing list by id
      .addCase(
        marketingListActions.getNewMarketingListById.pending,
        (state, action) => {
          state.error = "";

          state.data.totalCount = 0;
          state.data.selectedRowCount = 0;

          state.data.filteredDataCount = 0;
          state.data.filteredData = [];
          state.data.priority_column = {};
          state.data.marketingListByIdData = []
        }
      )
      .addCase(
        marketingListActions.getNewMarketingListById.fulfilled,
        (state, action) => {
          // new marketinglist selectors**************
          state.data.marketingListByIdData = action.payload.data?.data;
          state.data.InitialSelectValues = action.payload.data?.columns;
          state.data.marketingListName = action.payload.data?.list_name || "";
          state.data.marketingListByIdDataTotalCount =
            action.payload?.data?.pagination?.totalRecords;
          state.data.marketingListByIdDataTotalPages =
            action.payload?.data?.pagination?.totalPages;

          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.getNewMarketingListById.rejected,
        (state, action) => {
          state.data.marketingListByIdData = null;
          state.data.marketingListName = "";
          state.data.InitialSelectValues = [];
          state.error = action?.error?.message || "";
        }
      )

      .addCase(
        marketingListActions.getMarketingListById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
          state.data.totalCount = 0;
          state.data.selectedRowCount = 0;
          state.data.unsubscribeCount = 0;
          state.data.filteredDataCount = 0;
          state.data.filteredData = [];
          state.data.priority_column = {};
        }
      )
      .addCase(
        marketingListActions.getMarketingListById.fulfilled,
        (state, action) => {
          console.log("🚀 ~ action:", action)
          state.data.filteredData = action.payload.data?.results;
          state.data.priority_column = action.payload.data?.priority_dict;
          state.data.totalCount = action.payload.data?.count;
          state.data.selectedRowCount = action.payload.data?.selection_count;
          state.data.filteredDataCount = action.payload.data?.search_count;
          state.data.sequence = action.payload.data?.columns;
          state.data.totalCount = action.payload.data?.count;
          state.data.selectedRowCount = action.payload.data?.selection_count;
          state.data.unsubscribeCount = action.payload.data?.unsubscribe_count;
          state.data.filteredDataCount = action.payload.data?.search_count;

          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.getMarketingListById.rejected,
        (state, action) => {
          state.loading = false;
          state.data.filteredData = null;
          state.data.priority_column = null;
          state.data.marketingListByIdData = null;
          state.data.marketingListName = "";
          state.data.InitialSelectValues = [];
          // state.data.filteredDataTotalCount = 0;
          state.error = action?.error?.message || "";
        }
      )

      //* create marketing list
      .addCase(
        marketingListActions.createMarketingList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.createMarketingList.fulfilled,
        (state, action) => {
          state.data.createMarketingListData = action.payload.data?.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.createMarketingList.rejected,
        (state, action) => {
          state.loading = false;
          state.data.createMarketingListData = null;
          state.error = action?.error?.message || "";
        }
      )
      //* create marketing list contact
      .addCase(
        marketingListActions.postCreateMarketingListContact.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.postCreateMarketingListContact.fulfilled,
        (state, action) => {
          state.data.createMarketingListContactData = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.postCreateMarketingListContact.rejected,
        (state, action) => {
          state.loading = false;
          state.data.createMarketingListContactData = null;
          state.error = action?.error?.message || "";
        }
      )

      //* getFieldsTypeMappingList
      .addCase(
        marketingListActions.getFieldsTypeMappingList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.getFieldsTypeMappingList.fulfilled,
        (state, action) => {
          state.data.getFieldsTypeMappingList = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.getFieldsTypeMappingList.rejected,
        (state, action) => {
          state.loading = false;
          state.data.getFieldsTypeMappingList = null;
          state.error = action?.error?.message || "";
        }
      )

      //* uploadDocumentFileData
      .addCase(
        marketingListActions.uploadDocumentFile.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.uploadDocumentFile.fulfilled,
        (state, action) => {
          state.data.uploadDocumentFileData = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.uploadDocumentFile.rejected,
        (state, action) => {
          state.loading = false;
          state.data.uploadDocumentFileData = null;
          state.error = action?.error?.message || "";
        }
      )

      //* deleteMarketingList
      .addCase(
        marketingListActions.deleteMarketingListById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.deleteMarketingListById.fulfilled,
        (state, action) => {
          state.data.marketingListDeletedData = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.deleteMarketingListById.rejected,
        (state, action) => {
          state.loading = false;
          state.data.marketingListDeletedData = null;
          state.error = action?.error?.message || "";
        }
      )

      //* deleteMarketing deatails List
      .addCase(
        marketingListActions.deleteMarketingDeatilsListById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.deleteMarketingDeatilsListById.fulfilled,
        (state, action) => {
          state.data.marketingListDeletedData = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.deleteMarketingDeatilsListById.rejected,
        (state, action) => {
          state.loading = false;
          state.data.marketingListDeletedData = null;
          state.error = action?.error?.message || "";
        }
      )

      .addCase(
        marketingListActions.getScheduledBroadcastByID.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.getScheduledBroadcastByID.fulfilled,
        (state, action) => {
          state.data.broadcastByIdData = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.getScheduledBroadcastByID.rejected,
        (state, action) => {
          state.loading = false;
          state.data.broadcastByIdData = null;
          state.error = action?.error?.message || "";
        }
      )

      // Listing Of Users
      .addCase(marketingListActions.getUserListing.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        marketingListActions.getUserListing.fulfilled,
        (state, action) => {
          state.data.allUserListing = action?.payload?.data?.data || [];
          state.data.allUserListingCount = action?.payload?.data?.count || 0;
        }
      )
      .addCase(
        marketingListActions.getUserListing.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allUserListing = null;
          state.error = action?.error?.message || "";
        }
      )

      //* Import History
      .addCase(
        marketingListActions.getAllImportHistoryData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )

      .addCase(
        marketingListActions.getAllImportHistoryData.fulfilled,
        (state, action) => {
          state.data.importHistoryData = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.getAllImportHistoryData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.importHistoryData = null;
          state.error = action?.error?.message || "";
        }
      )

      //* Preogress Bar Data
      .addCase(
        marketingListActions.getAllProgressData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )

      .addCase(
        marketingListActions.getAllProgressData.fulfilled,
        (state, action) => {
          state.data.importHistoryProgressData = action.payload.data;
        }
      )
      .addCase(
        marketingListActions.getAllProgressData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.importHistoryProgressData = null;
          state.error = action?.error?.message || "";
        }
      )
      //*  Download Import History File
      .addCase(
        marketingListActions.downloadImportHistoryFile.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.downloadImportHistoryFile.fulfilled,
        (state, action) => {
          state.data.fileDownloadUrlData = action.payload.data;
        }
      )
      .addCase(
        marketingListActions.downloadImportHistoryFile.rejected,
        (state, action) => {
          state.loading = false;
          state.data.fileDownloadUrlData = null;
          state.error = action?.error?.message || "";
        }
      )
      // action.payload?.data?.results;
      .addCase(
        marketingListActions.getContactsSelectData.fulfilled,
        (state, action) => {
          state.data.contactsSelectData = action.payload.data?.data;

          // state.data.contactsListData = action?.payload?.data?.count;
        }
      )
      .addCase(
        marketingListActions.getContactsSelectData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.contactsSelectData = null;
          state.error = action?.error?.message || "";
        }
      )

      //  GET Contacts deatils export Data
      .addCase(
        marketingListActions.getExportMarketingList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      // action.payload?.data?.results;
      .addCase(
        marketingListActions.getExportMarketingList.fulfilled,
        (state, action) => {
          state.data.exportData = action.payload.data?.data;
          // state.data.contactsListData = action?.payload?.data?.count;
        }
      )
      .addCase(
        marketingListActions.getExportMarketingList.rejected,
        (state, action) => {
          state.loading = false;
          state.data.exportData = null;
          state.error = action?.error?.message || "";
        }
      )

      .addCase(
        marketingListActions.ImportFormStepOne.pending,
        (state, action: any) => {
          state.loading = false;
          state.data.allUserListing = null;
          state.error = action?.error?.message || "";
        }
      )
      .addCase(
        marketingListActions.ImportFormStepOne.fulfilled,
        (state, action) => {
          state.data.ImportFormStepOneRes = action?.payload?.data;
        }
      )
      .addCase(
        marketingListActions.ImportFormStepOne.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allUserListing = null;
          state.error = action?.error?.message || "";
        }
      )
      // Create Import ID
      .addCase(marketingListActions.CreateImportID.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        marketingListActions.CreateImportID.fulfilled,
        (state, action) => {
          state.data.ImportID = action?.payload?.data?.importId || [];
        }
      )
      .addCase(
        marketingListActions.CreateImportID.rejected,
        (state, action) => {
          state.loading = false;
          state.data.ImportID = null;
        }
      )

      .addCase(
        marketingListActions.getCommunicationLogsDownload.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )

      .addCase(
        marketingListActions.getCommunicationLogsDownload.fulfilled,
        (state, action) => {
          state.data.broadcastByIdData = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.getCommunicationLogsDownload.rejected,
        (state, action) => {
          state.loading = false;
          state.data.broadcastByIdData = null;
          state.error = action?.error?.message || "";
        }
      )

      .addCase(
        marketingListActions.generateCommunicationLogsReport.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        marketingListActions.generateCommunicationLogsReport.fulfilled,
        (state, action) => {
          state.data.broadcastByIdData = action.payload.data;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        marketingListActions.generateCommunicationLogsReport.rejected,
        (state, action) => {
          state.loading = false;
          state.data.broadcastByIdData = null;
          state.error = action?.error?.message || "";
        }
      );
  },
});

export default MarketingListSlice.reducer;

export const {
  unMountmarketingListByIdData,
  saveFormSteps,
  setMarketingListPageNumber,
  removeMarketingListData,
  updateMarketingListSelecetedRowCount,
  updateMarketingFilteredData,
  updateAllMarketingFilteredData,
} = MarketingListSlice.actions;
