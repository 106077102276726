import { AxiosResponse } from "axios";
import {
  Chatbot,
  ChatbotCategory,
  ChatbotType,
  Department,
  Role,
} from "../../Models";
import { ChatbotLookFeel } from "../../Models/ChatbotTheme";
import { chatbotConsoleAxios } from "../../Utils/Axios";
import { getHttpService } from "../HttpService";

import { ChatbotConfiguration } from "../../Models/ChatbotConfiguration";

import { formatString } from "../../Utils";
import { CH_CONSOLE_ENDPOINTS } from "./Endpoints";

export class ChatbotConsoleService {
  static httpService: any = getHttpService(chatbotConsoleAxios);

  static agentOnlineStatus = (data: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.AGENT_ONLINE_STATUS,
      data,
    });
  };

  static getAllBots = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOTS,
      params: queryData,
      headers: config,
    });
  };
  static getBotById = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_BY_BOT_ID,
      params: queryData,
      headers: config,
    });
  };
  static getWorkspaceById = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_BY_WORKSPACE_ID,
      params: queryData,
      headers: config,
    });
  };
  static createNewBot = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_SETUP,
      data: queryData,
      headers: config,
    });
  };
  static updateWorkspace = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.put({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_UPDATE_WORKSPACE,
      data: queryData,
      headers: config,
    });
  };
  static updateBot = (
    bot_id: string,
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.put({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_SINGLE,
      data: queryData,
      headers: config,
      pathParams: [bot_id],
    });
  };
  static getAllBotCategories = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<ChatbotCategory[]>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_CATEGORY,
      params: queryData,
      headers: config,
    });
  };

  static getAllBotTypes = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<ChatbotType[]>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_TYPE,
      params: queryData,
      headers: config,
    });
  };

  static createUpdateBotConfiguration = (
    bot_id: string,
    configData: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.put({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_CONFIGURATION_UPDATE,
      data: configData,
      pathParams: [bot_id],
    });
  };
  static getLandingPageBotData = (
    bot_id: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.LANDING_PAGE_BOT_DATA,
      pathParams: [bot_id],
    });
  };
  static getBotConfiguration = (
    config_id: string,
  ): Promise<AxiosResponse<ChatbotConfiguration>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_SINGLE_CONFIGURATION,
      pathParams: [config_id],
    });
  };
  static getZohoOauthRedirectUrl = (): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_ZOHO_OAUTH_REDIRECT,
    });
  };
  static getZohoFieldsMapping = (
    bot_id: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_ZOHO_FIELDS_GET,
      params: { bot_id },
    });
  };
  static getZohoFieldsData = (bot_id: string): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_ZOHO_GET_FIELD_DATA,
      params: { bot_id },
    });
  };
  static updateZohoFieldsMapping = (data: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.put({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_ZOHO_FIELDS_UPDATE,
      data: data,
    });
  };
  static saveZohoConfiguration = (data: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_ZOHO_CONFIG_SAVE,
      data: data,
    });
  };
  static logoutZohoConfiguration = (data: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_ZOHO_LOGOUT,
      data: data,
    });
  };

  static getGoogleSheetOauthRedirectUrl = (): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_GOOGLE_SHEET_OAUTH_REDIRECT,
    });
  };

  static saveGoogleSheetConfiguration = (
    data: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_GOOGLE_SHEET_CONFIG_SAVE,
      data: data,
    });
  };

  static getcreatedGoogleSheet = (
    bot_id: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_CREATED_GOOGLE_SHEET,
      params: { bot_id },
    });
  };

  static logoutGoogleConfiguration = (
    data: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_GOOGLE_LOGOUT,
      data: data,
    });
  };

  static getFacebookUserID = (data: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_FACEBOOK_GET,
      data: data,
    });
  };
  static getFacebookData = (bot_id: string): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_FACEBOOK_GET,
      params: { bot_id },
    });
  };
  static saveFacebookConfiguration = (
    data: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_FACEBOOK_SAVE,
      data: data,
    });
  };
  static logoutFacebookConfiguration = (
    data: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_FACEBOOK_LOGOUT,
      data: data,
    });
  };
  static getInstagramData = (bot_id: string): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_INSTAGRAM_GET,
      params: { bot_id },
    });
  };
  static getInstagramOauthRedirectUrl = (): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_INSTAGRAM_REDIRECT,
    });
  };
  static saveInstagramConfiguration = (
    data: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_INSTAGRAM_SAVE,
      data: data,
    });
  };
  static logoutInstagramConfiguration = (
    data: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_INSTAGRAM_LOGOUT,
      data: data,
    });
  };

  static changeLookAndFeel = (
    queryData = {},
    pathParams: string[],
  ): Promise<AxiosResponse<ChatbotLookFeel>> => {
    return ChatbotConsoleService.httpService.put({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_THEME_UPDATE,
      data: queryData,
      pathParams: pathParams,
    });
  };
  static getChatbotLookFeel = (
    bot_id: string,
  ): Promise<AxiosResponse<ChatbotLookFeel>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_LOOK_FEEL,
      pathParams: [bot_id],
    });
  };
  // static getLiveChatToken = (id: string): Promise<AxiosResponse<any>> => {
  //   return ChatbotConsoleService.httpService.get({url: CH_CONSOLE_ENDPOINTS.CHATBOT_LIVE_CHAT_TOKEN, pathParams: [id]});
  // };

  static uploadFile = (formData: any): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.upload({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_UPLOAD_FILE,
      formData,
    });
  };
  static uploadFileRichEditor = (
    data: any,
  ): Promise<AxiosResponse<Chatbot>> => {
    const { formData, config } = data;
    return ChatbotConsoleService.httpService.upload({
      url: CH_CONSOLE_ENDPOINTS.PUBLIC_UPLOAD_FILE,
      formData,
      config,
    });
  };

  static upsertAgent = (
    queryData: any = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    if (queryData.hasOwnProperty("id") && queryData.id) {
      return ChatbotConsoleService.httpService.put({
        url: formatString(CH_CONSOLE_ENDPOINTS.CHATBOT_AGENT_UPDATE, [
          queryData.id,
        ]),
        data: queryData,
        headers: config,
      });
    }
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_AGENT,
      data: queryData,
      headers: config,
    });
  };
  static getAllAgents = (
    chatbot_id?: string,
    bot_user_id?: string,
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_AGENT,
      params: { chatbot: chatbot_id, user_id: bot_user_id },
    });
  };

  static getAllGroups = (): Promise<AxiosResponse<Role[]>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_ROLE,
    });
  };
  static getAllDepartments = (
    chatbot_id?: string,
  ): Promise<AxiosResponse<Department[]>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_DEPARTMENT,
      params: { chatbot: chatbot_id },
    });
  };
  static agentVerify = (
    data: any,
    headers: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_AGENT_VERIFY,
      data,
      headers,
    });
  };

  static deleteAgent = (agent_id: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.httpDelete({
      url: formatString(CH_CONSOLE_ENDPOINTS.CHATBOT_AGENT_UPDATE, [agent_id]),
    });
  };
  static getAllLanguages = (
    queryData = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_LANGUAGES,
      params: queryData,
      headers: config,
    });
  };
  static upsertDepartment = (
    queryData: any = {},
    config = {},
  ): Promise<AxiosResponse<Chatbot>> => {
    if (queryData.hasOwnProperty("id") && queryData.id) {
      return ChatbotConsoleService.httpService.put({
        url: formatString(CH_CONSOLE_ENDPOINTS.CHATBOT_DEPARTMENT_UPDATE, [
          queryData.id,
        ]),
        data: queryData,
        headers: config,
      });
    }
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_DEPARTMENT,
      data: queryData,
      headers: config,
    });
  };
  //*whatsapp
  static uploadWhatsappMedia = (
    formData: any,
    isResolveVariable?: boolean,
  ): Promise<AxiosResponse<any>> => {
    if (isResolveVariable) {
      delete formData.resolved;
      return ChatbotConsoleService.httpService.upload({
        url: CH_CONSOLE_ENDPOINTS.WHATSAPP_ATTACHMENT_RESOLVED,
        formData,
      });
    } else {
      return ChatbotConsoleService.httpService.upload({
        url: CH_CONSOLE_ENDPOINTS.WHATSAPP_ATTACHMENT,
        formData,
      });
    }
  };
  static getWhatsappBroadcastListings = (
    queryData = {},
    config = {},
  ): Promise<any> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.WHATSAPP_BROADCAST_LISTINGS,
      data: queryData,
    });
  };
  static getWhatsappBroadcastDetailsById = (
    broadcast_id: string,
    queryData: {},
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.WHATSAPP_BROADCAST_DETAILS_LISTINGS,
      pathParams: [broadcast_id],
      data: queryData,
    });
  };
  static getWhatsappBroadcastDetailsDownload = (
    broadcast_id: string,
    type?: string,
    searchText?: string,
    status?: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: `${CH_CONSOLE_ENDPOINTS.WHATSAPP_BROADCAST_LISTINGS_DOWNLOAD}?broadcast_id=${broadcast_id}&type=${type}&searchText=${searchText}&status=${status}`,
    });
  };
  static getWhatsappTemplateById = (
    template_id: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.WHATSAPP_TEMPLATE_BY_ID,
      pathParams: [template_id],
    });
  };
  static deleteWhatsappTemplateById = (
    template_id: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.WHATSAPP_BROADCAST_DELETE_TEMPLATE,
      pathParams: [template_id],
    });
  };
  static getWhatsappTemplateListings = (
    queryData = {},
    config = {},
  ): Promise<any> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.WHATSAPP_TEMPLATE_LISTINGS,
      data: queryData,
    });
  };
  static getWhatsappTemplateCopyById = (
    template_id: string,
  ): Promise<AxiosResponse<Role[]>> => {
    return ChatbotConsoleService.httpService.get({
      url: CH_CONSOLE_ENDPOINTS.WHATSAPP_TEMPLATE_BY_ID,
      pathParams: [template_id],
    });
  };
  static createWhatsappTemplate = (data: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.WHATSAPP_TEMPLATE_CREATE,
      data: data,
    });
  };

  //dashboard
  static getUsers = (queryData = {}, config = {}): Promise<any> => {
    return ChatbotConsoleService.httpService.post({
      url: CH_CONSOLE_ENDPOINTS.DASHBOARD_USERS,
      data: queryData,
    });
  };

  // email
  static uploadEmailMedia = (formData: any): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.upload({
      url: CH_CONSOLE_ENDPOINTS.EMAIL_ATTACHMENT,
      formData,
    });
  };
  static getEmailBroadcastDetailsDownload = (
    broadcast_id: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: `${CH_CONSOLE_ENDPOINTS.EMAIL_BROADCAST_LISTINGS_DOWNLOAD}?broadcast_id=${broadcast_id}`,
    });
  };
  static getWhstappTemplateSync = (
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: `${CH_CONSOLE_ENDPOINTS.WHATSAPP_APP_SYNC}`,
    });
  };
  static getDefaultTemplateData = (
    data: any,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.get({
      url: `${CH_CONSOLE_ENDPOINTS.GET_DEFAULT_TEMPLATE}?limit=${data?.limit}&offset=${data?.offset}&category=${data?.category}`,
    });
  };
  static updatePersistentMenu = (
    bot_id: string,
    configData: any,
    pathParams: string,
  ): Promise<AxiosResponse<any>> => {
    return ChatbotConsoleService.httpService.put({
      url: CH_CONSOLE_ENDPOINTS.CHATBOT_PERSISTENT_UPDATE,
      data: configData,
      pathParams: [bot_id, pathParams],
    });
  };
}
