// vendors
import Select, { SelectProps } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// utils
import { SingleMenuProps } from "./Helper";

// styles
import {
  PlaceholderTextStyled,
  SelectedLabelStyled,
  ListItemTextStyled,
} from "./Select.styled";

type Option = {
  label: string;
  value: string;
};
// interface Props extends SelectProps {
//   options: Option[];
//   placeholder?: string;
// }

const UISelect = (props: any) => {
  const { options, placeholder, value, defaultValue, onChange, isDisabled } =
    props;

  return (
    <Select
      displayEmpty
      IconComponent={KeyboardArrowDownIcon}
      value={value}
      defaultValue={defaultValue}
      input={
        <OutlinedInput
          sx={{
            backgroundColor: "#F5F6F8",
            border: "1px solid #D9D9D9",
            borderRadius: "4px",
          }}
          size="small"
        />
      }
      onChange={onChange}
      disabled={isDisabled}
      renderValue={(selected: any) => {
        if (selected && selected.length === 0) {
          return <PlaceholderTextStyled>{placeholder}</PlaceholderTextStyled>;
        }

        const match = options.filter(
          (option: any) => option.value === selected,
        )[0];
        return (
          <SelectedLabelStyled>
            {match?.label ? match?.label : ""}
          </SelectedLabelStyled>
        );
      }}
      fullWidth
      // @ts-ignore
      MenuProps={SingleMenuProps}
    >
      {options.map((option: Option) => (
        <MenuItem key={option.value} value={option.value}>
          <ListItemTextStyled primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  );
};

export default UISelect;
