import React from "react";
import uniqid from "uniqid";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppTyping from "../UtilsComponents/AppTyping";

interface Props {
  [otherProps: string]: any;
}
const AppRenderTypingIndicator: React.FC<Props> = React.memo(() => {
  const { typingStatus,} = useAppSelector(allMessagesStore);

  if (!typingStatus) {
    return null;
  }

  return (
    <AppMessageBoxWrapper userMessage={false} key={uniqid()} msgId={uniqid()}>
      <AppTyping />
    </AppMessageBoxWrapper>
  );
});

export default AppRenderTypingIndicator;
