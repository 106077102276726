import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { MenuList } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import * as React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SidebarMenuContentWrap } from "./styles";
import { replaceIdInPath } from "../../Utils";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxShadow: `2px 0px 4px rgba(0, 0, 0, 0.08)`,
  border: "none",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  border: "none",
  boxShadow: `2px 0px 4px rgba(0, 0, 0, 0.08)`,
  width: `26px`,
  [theme.breakpoints.up("sm")]: {
    width: `26px`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      position: "absolute",
      zIndex: "1 !important",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      position: "absolute",
      zIndex: "1 !important",
    },
  }),

  "& .drawerIcon": {
    // backgroundColor: theme.palette.primary.main,
    position: "absolute",
    zIndex: 9,
    top: "43%",
    right: 0,
    padding: "4px",
  },
}));

interface Props {
  Logo?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > | null;
  Options: any[];
  sidebarTitle?: string;
  dynamicRouteSlug?:string
}

const SidebarMenu: React.FC<Props> = ({ Logo, Options, sidebarTitle,dynamicRouteSlug }) => {
  const navigate = useNavigate();
  const location = useLocation();
  let id = useParams();
  const [selectedIndex, setSelectedIndex] = React.useState<any>(0);

  const memoizedOptions = React.useMemo(() => {
    return (
      Options?.filter((curElem: any) => curElem?.isAvailable !== false) || []
    );
  }, [Options]);

  const handleRedirect = (item: any, index: number) => {
    const dyanamicRoute = dynamicRouteSlug ? id?.[dynamicRouteSlug] : '';
    if (!dyanamicRoute) {
      navigate(item.url);
    }
    else {
      let URL = replaceIdInPath(item?.url, dyanamicRoute);
      navigate(URL);
    }
    setSelectedIndex(index);
  };

  const getCurrentPathAfterRefresh = () => {
    if (Options?.length === 0) return;

    const index = Options?.findIndex((item: any) => {
      return location.pathname.includes(item?.value);
    });

    setSelectedIndex(index);
  };

  // do not remove options dependency
  React.useLayoutEffect(() => {
    getCurrentPathAfterRefresh();
  }, [Options, location]); // eslint-disable-line react-hooks/exhaustive-deps

  // export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <IconButton
        color="inherit"
        className="drawerIcon"
        aria-label="open drawer"
        onClick={handleDrawer}
        disableRipple
      >
        {open ? (
          <ChevronLeftIcon style={{ color: theme.palette.default.darkGrey }} />
        ) : (
          <ChevronRightIcon style={{ color: theme.palette.default.darkGrey }} />
        )}
      </IconButton>
      <SidebarMenuContentWrap style={{ display: open ? " flex" : "none" }}>
        {Logo ? (
          <div className="sidebarLogoBox">
            <Logo />
          </div>
        ) : null}
        {sidebarTitle ? (
          <div className="sidebarTitle">{sidebarTitle}</div>
        ) : null}
        <MenuList
          variant="selectedMenu"
          dense
          // key={index}
          // style={{ marginBottom: "0.2rem" }}
        >
          {memoizedOptions &&
            memoizedOptions?.length > 0 &&
            memoizedOptions?.map((curElem: any, index: number) => {
              if (curElem?.isAvailable === false) return null;

              return (
                <ListItemButton
                  key={index.toString()}
                  selected={selectedIndex === index ? true : false}
                  onClick={() => {
                    handleRedirect(curElem, index);
                  }}
                  disabled={curElem.isDisabled}
                  sx={{
                    color:
                      selectedIndex === index
                        ? theme.palette.primary.main
                        : theme.palette.default.darkGrey,
                  }}
                  disableRipple
                >
                  <ListItemText style={{ cursor: "pointer" }}>
                    {curElem?.label}
                  </ListItemText>
                </ListItemButton>
              );
            })}
        </MenuList>
      </SidebarMenuContentWrap>
    </Drawer>
  );
};

export default React.memo(SidebarMenu);
