import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  debounce
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { GridCloseIcon } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppRangeDatePicker from "../../../../AppComponents/AppRangeDatePicker";
import { AppForm } from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import { AppConfig } from "../../../../Config/app-config";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { CoreService } from "../../../../Services/Apis/CoreApis";
import {
  BroadcastTopTitle,
  TableFilter,
  TableWrapper,
  TypographyColumn
} from "../../../../TableStyle";
import Danger from "../../../../assets/images/danger-live.svg";
import AppSkeletonTableLoader from "../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import {
  LeftCol,
  NewNativeSelect,
  RightCol,
  TopBar,
} from "../BroadcastHistory/HistoryStyles";
import { PageDiv } from "../ScheduledBroadcast/Styles";
import { BROADCAST_WHATSAPP_FORM_DATA } from "../Utils/Constants";
import {
  TemplateListWrapper
} from "./TemplatesListingCSS";
import QualityBadge from "./components/ListingComponents/QualityBadge";
import StatusBadge from "./components/ListingComponents/StatusBadge";
import SyncIcon from '@mui/icons-material/Sync'
import useDocumentTitle from "../../../../Hooks/useDocumentTitle";

interface Props { }

const TemplatesListing: React.FC<Props> = () => {
  useDocumentTitle("Templates");
  const [isShowingModal, toggleModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const formikRef = useRef(null);
  const [errorApi, setErrorApi] = useState(false);
  const [pagination, setPagination] = useState<any>();
  const has_low_balance = localStorage.getItem("has_low_balance")
  const header = [
    "Name",
    "Category",
    "Status",
    "Quality",
    "Language",
    "Last Updated"
  ];

  const keyToHeaderMap: any = {
    Name: "name",
    Category: "category",
    Status: "status",
    Quality: "quality",
    Language: "language",
    "Last Updated": "modified_time"

  };

  const colWidth: any = {
    Name: "460",
    Category: "224",
    Status: "224",
    Quality: "224",
    Language: "224",
    "Last Updated": "287",
  };

  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    category: "",
  });
  const [dateRange, setDateRange] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const [credits, setCredits] = useState<any>([]);

  let { showAlert } = useNotification();
  const getPreviousData = () => {
    let startDate = null;
    let endDate = null;
    if (dateRange.startDate && dateRange.endDate) {
      startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
      endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    }
    setLoading(true);
    ChatbotConsoleService.getWhatsappTemplateListings({
      limit: localValues.itemsPerPage?.value,
      offset: localValues.currentPage,
      start: startDate,
      end: endDate,
      search: localValues?.search,
      status: localValues?.status,
      category: localValues?.category,
    })
      .then((res: any) => {
        setList(res.data.results);
        setPagination(res.data.pagination)
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPreviousData();
  }, [localValues, dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
  }, []);

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      currentPage: 0,
      [field]: e.target.value,
    });
  };
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      currentPage: 0,
      [field]: e,
    });
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };
  const clearAllCallback = () => {
    setLocalValues({
      ...localValues,
      search: "",
      status: "",
      category: "",
    });
  };
  const handleClick = (id: any) => {
    ChatbotConsoleService.deleteWhatsappTemplateById(id)
      .then((res: any) => {
        getPreviousData();
        toggleModal(false);
        showAlert("Template deleted successfully", "success");
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      });
  };

  const handleButtonClick = (formikRef: any) => {
    formikRef.current.handleReset();
  };
  const editBroadcastTemplate = (id: any) => {
    navigate(`/broadcast/whatsapp/view-template/${id}`);
  };
  const navigate = useNavigate();
  const renderSelectIcon = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  const renderSelectedIcon = localValues.category ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  useEffect(() => {
    CoreService.getCredits().then((response) => {
      const reversedItems = response?.data;
      setCredits(reversedItems);
    });
  }, [localValues]);

  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  const handleTemplateClick = (row: any) => {
    navigate(row?.meta_template_id);
  }
  const syncTemplate = () => {
    ChatbotConsoleService.getWhstappTemplateSync()
      .then((res: any) => {
        showAlert(res?.data?.msg, "success");
      })
      .catch((error: any) => {
        showAlert(error?.data?.msg, "error");
      })
      .finally(() => {
      });
  }
  return (
    <TemplateListWrapper style={{height: "inherit", overflow: "hidden"}}>
      <AppModel
        isShowing={isShowingModal}
        onClose={toggleModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img loading="lazy" src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The Whatsapp Template</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal(false)}>
              Cancel
            </AppButton>
            <ProtectedAppButton
              moduleId="whatsapp_template"
              specificPermissionKey="delete"
              variant="danger-filled"
              onClick={() => handleClick(selectedId)}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </ProtectedAppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
      {/* <TopBar>
        <LeftCol>
          <label>To know your Messaging Limit please</label>
          <Box className="limit">
            <a
              href="https://developers.facebook.com/docs/whatsapp/messaging-limits/"
              rel="noreferrer"
              target="_blank"
            >
              Click here
            </a>
          </Box>
        </LeftCol>

        <RightCol>
          <Box className="contact">
            <label>
              <span className="contact-label">Connected WA No.:</span>
              <span> {credits?.whatsapp_no}</span>
            </label>
          </Box>
          <Box>
            <Box className="monthly-conversations__wrapper" >
              <Box className="conversations">
                <Box className="monthly-conversations">
                  <AppToolTip
                    tooltipProps={{
                      title: `Each WhatsApp Business Account gets 1,000 free service conversations (user-initiated conversations) each month across all of its business phone numbers. This number is refreshed at the beginning of each month, based on WhatsApp Business Account time zone. Marketing, utility and authentication conversations are not part of the free tier`,
                      placement: "right",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={<InfoOutlinedIcon />}
                    divStyle={{ marginRight: "5px", marginBottom: "2px" }}
                  />{" "}
                  <div className="conversations-label">
                    Free Monthly conversations:<strong> {credits?.free_conversation || 0}</strong>
                  </div>
                </Box>
              </Box>
            </Box>
            <Box className="credits">
              <Box></Box>
            </Box>
          </Box>
        </RightCol>
      </TopBar> */}
      <PageDiv>
{/* 
        <BroadcastTopTitle style={{ padding: "16px 0" }}>
          <Box className="topTitle-left">
            <Typography fontSize="16px" fontWeight="500" component="h6">
              Broadcast Template
            </Typography>
          </Box>
          <Box className="topTitle-right">
            <ProtectedAppButton
              moduleId="whatsapp_broadcast_crud"
              specificPermissionKey="create"
              variant="primarydark"
              onClick={() => navigate("/broadcast/whatsapp/create-broadcast/1")}
              style={{ minWidth: "170px" }}
              isDisabled={has_low_balance === "true"}
              title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
            >
              Create Broadcast
              <AppMaterialIcons iconName="Add" />
            </ProtectedAppButton>

            {credits?.whatsapp_no && (
              <ProtectedAppButton
                moduleId="whatsapp_template"
                specificPermissionKey="create"
                variant="primarydark"
                onClick={() => navigate("/broadcast/whatsapp/create-template")}
                style={{ minWidth: "170px" }}
              >
                Create Template
                <AppMaterialIcons iconName="Add" />
              </ProtectedAppButton>
            )}
          </Box>
        </BroadcastTopTitle> */}
        <AppForm
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => { }}
          innerRef={formikRef}
          divStyle={{ width: "100%" }}
        >
          <TableFilter
            style={{
              padding: "0px 14px 0px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
            <Box>
              <Typography fontSize="13px" component="h6" sx={{ textWrap: "nowrap" }} mr={0.5}>
                Sort By
              </Typography>
            </Box>
            <Grid container >
              <Grid
                px={1}
                display={'flex'}
                alignItems={"center"}
                item
                md={2.5}
                xl={2}
              >
                <NewNativeSelect style={{ width: "100%" }}>
                  <Select
                    defaultValue={undefined}
                    name="status"
                    value={localValues?.status}
                    onChange={(e) => handleSelectChange(e, "status")}
                    displayEmpty
                    className="new-select-main mb0"
                    style={{
                      marginBottom: "0px",
                      height: "28px",
                      fontSize: "14px",
                      fontWeight: 400,
                      width: "100%",
                      maxWidth: "auto",
                      minWidth: "100%",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Status
                    </MenuItem>
                    <MenuItem value="APPROVED">Approved</MenuItem>
                    <MenuItem value="REJECTED">Rejected</MenuItem>
                    <MenuItem value="PENDING">Pending</MenuItem>
                  </Select>
                  <div
                    className="clear-icon"
                    onClick={(e: any) => clearCallback(e, "status")}
                  >
                    {renderSelectIcon}
                  </div>
                </NewNativeSelect>
              </Grid>
              <Grid
                px={1}
                display={'flex'}
                alignItems={"center"}
                item
                md={2.5}
                xl={2}
              >
                <NewNativeSelect style={{ width: "100%" }}>
                  <Select
                    defaultValue={undefined}
                    name="category"
                    value={localValues?.category}
                    onChange={(e) => handleSelectChange(e, "category")}
                    displayEmpty
                    className="new-select-main"
                    style={{
                      marginBottom: "0px",
                      fontSize: "14px",
                      fontWeight: 400,
                      width: "100%",
                      maxWidth: "auto",
                      minWidth: "100%",
                      height: "28px",
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select Category
                    </MenuItem>
                    <MenuItem value="MARKETING">MARKETING</MenuItem>
                    <MenuItem value="UTILITY">UTILITY</MenuItem>
                  </Select>
                  <div
                    className="clear-icon"
                    onClick={(e: any) => clearCallback(e, "category")}
                  >
                    {renderSelectedIcon}
                  </div>
                </NewNativeSelect>
              </Grid>
              <Grid
                px={1}
                display={'flex'}
                alignItems={"center"}
                item
                md={2.5}
                xl={2}
              >
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  defaultBorder={true}
                  divStyle={{ marginBottom: "0px", width: "100%" }}
                  onChange={(e: any) => handleSelectSearch(e, "search")}
                  clearCallback={(e: any) => clearCallback(e, "search")}
                />
                <Tooltip title="Sync With Meta">

                  <IconButton onClick={() => syncTemplate()} style={{
                    marginBottom: "-10px",
                    paddingTop: "1px",
                    backgroundColor: "white"
                  }}>
                    <SyncIcon style={{ marginRight: "10px", cursor: "pointer" }} />
                  </IconButton>
                </Tooltip>
              </Grid>

              <Grid item md={4.5} xl={6}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                  <Box>
                    {localValues.category &&
                      localValues.search &&
                      localValues.status && (
                        <AppButton
                          variant="outline"
                          onClick={() => {
                            clearAllCallback();
                            handleButtonClick(formikRef);
                          }}
                        >
                          Reset
                        </AppButton>
                      )}
                  </Box>

                  <AppRangeDatePicker
                    dateRange={dateRange}
                    reset={true}
                    setDateRange={setDateRange}
                    divStyle={{
                      padding: "0px",
                      maxWidth: "250px",
                      minWidth: "170px"
                    }}
                    dateFieldStyle={{
                      height: "28px",
                      "@media screen and (max-width: 1366px)": {
                        color: "red !important"
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <div className="filter-right">
            </div>
          </TableFilter>
        </AppForm>

        {loading ? <AppSkeletonTableLoader
          numberOfCol={4}
          numberofRow={10}
          skeletonHeight={30}
          skeletonWidth={"100%"}
          skeletonHeaderHeight={40}
          skeletonHeaderWidth={"100%"}
          tableHeight={`calc(100vh - 340px)`}
        /> :
          <React.Fragment>
            <TableWrapper height={list?.length === 0 ? "calc(100vh - 267px)" : "calc(100vh - 314px)"}>
              <div>
                <Box className="table-header">
                  <Box className="table-row">
                    {header?.map((header: string, index: number) => {
                      return (
                        <Box
                          className={`col-head ${header
                            .toLowerCase()
                            .replaceAll(" ", "_")}`}
                          style={{
                            maxWidth: colWidth[header] + "px",
                            minWidth: colWidth[header] + "px",
                          }}
                          key={index}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            {header}
                          </Typography>
                        </Box>
                      );
                    })}
                    <Box
                      className="col-head"
                      sx={{
                        width: "100%",
                        minWidth: "100px",
                        height: "2.969rem",
                      }}
                    >
                      <Typography></Typography>
                    </Box>
                  </Box>
                </Box>

                {errorApi || list?.length === 0 ?
                  (
                    <NoRecordsFound subText="Get started by creating a template first." height="calc(100vh - 257px)">
                      <ProtectedAppButton
                        moduleId="whatsapp_template"
                        specificPermissionKey="create"
                        variant="primarydark"
                        onClick={() =>
                          navigate("/broadcast/whatsapp/create-template")
                        }
                      >
                        Create Template
                        <AppMaterialIcons iconName="Add" />
                      </ProtectedAppButton>
                    </NoRecordsFound>
                  ) : (
                    <Box className="table-body">
                      {list?.map((row: any, rowIndex: any) => {
                        return (
                          <Box key={rowIndex} className="table-row">
                            {header?.map((header: any, ColumnIndex: any) => {
                              const isActionColumn = header === "Action";
                              return (
                                <Box
                                  className={`col-body ${header
                                    .toLowerCase()
                                    .replaceAll(" ", "_")}`}
                                  title={
                                    header === "Date"
                                      ? formatDateField(
                                        row[keyToHeaderMap[header]]
                                      )
                                      : row[keyToHeaderMap[header]]
                                        ? row[keyToHeaderMap[header]]
                                        : "-"
                                  }
                                  style={{
                                    maxWidth: isActionColumn
                                      ? "400px"
                                      : colWidth[header] + "px",
                                    minWidth: isActionColumn
                                      ? "400px"
                                      : colWidth[header] + "px",
                                  }}
                                >
                                  <TypographyColumn
                                    sx={{
                                      cursor: header === "Name" ? "pointer" : ""
                                    }}
                                    header={header}
                                    row={row}
                                    onClick={header === "Name" ? () => handleTemplateClick(row) : undefined}
                                  >
                                    {header === "Date"
                                      ? formatDateField(row[keyToHeaderMap[header]])
                                      : header === "Status" ? <StatusBadge status={row[keyToHeaderMap[header]]} /> : header === "Quality" ? <QualityBadge status={row[keyToHeaderMap[header]] || "GREEN"} template_data={row} /> : row[keyToHeaderMap[header]]
                                        ? row[keyToHeaderMap[header]]
                                        : "-"}
                                  </TypographyColumn>
                                </Box>
                              );
                            })}
                            <Box
                              p={1}
                              display="flex"
                              sx={{
                                width: "100%",
                                minWidth: "100px",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                              className="actionColumn"
                            >
                              <IconButton>
                                {row?.can_delete &&
                                  <DeleteOutlineOutlinedIcon
                                    onClick={() => {
                                      setSelectedId(row?.id);
                                      toggleModal(true);
                                    }}
                                    fontSize="small"
                                  />
                                }
                              </IconButton>
                              <IconButton>
                                <RemoveRedEyeOutlinedIcon
                                  onClick={() => editBroadcastTemplate(row?.id)}
                                  fontSize="small"
                                />
                              </IconButton>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  )}
              </div>
            </TableWrapper>
            {list?.length ? (
              <AppPaginationComponent
                totalCount={pagination?.totalRecords}
                handlePageChange={handlePageChange}
                currentPage={localValues?.currentPage}
                totalPages={pagination?.totalPages}
                rowsPerPage={localValues?.itemsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : null}
          </React.Fragment>
        }
      </PageDiv>
    </TemplateListWrapper >
  );
};
export const DeleteCannedResponse = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.875rem;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    .header-left {
      display: flex;
      gap: 0.875rem;
      .danger {
        padding-top: 4px;
        img {
          width: 1.2rem;
        }
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;

    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;
export default TemplatesListing;
