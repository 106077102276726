import styled from "styled-components";
import { Outlet } from "react-router-dom";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { ChatHistorySidebarOptions } from "../../Models/defaultSelectOptions";
import ChatHistorySidebar from "./Sidebar/ChatHistorySidebar";

interface Props { }

const ChatHistory = (props: Props) => {
  useDocumentTitle("Chat History");
  return (<Outlet />
  );
};

export default ChatHistory;

const ChatHistoryWrap = styled.div`
  && {
    background-color: #f5f6f8;
    display: flex;
    height: 100%;
    width: 100%;
    overflow: hidden;
    gap: 1px;
  }
`;

export const ChatHistorySidebarWrap = styled.div`
  && {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    min-width: 15%;
    overflow: hidden;
    scroll-behavior: none;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
  }
`;

export const ChatWindowWrap = styled.div`
  && {
    background-color: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
     /* margin-bottom: 2rem; */
     padding-bottom: 4rem;
    overflow: hidden;
    /* gap: 1px; */
  }
`;

export const UserInfoWrap = styled.div`
  && {
    background-color: #f5f6f8;
    min-width: 20%;
    width: 20%;
    max-width: 20%;
    overflow-y: auto;
    .MuiList-root {
      -ms-overflow-style: none !important;
      scrollbar-width: none !important;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
export const ChatListWrap = styled.div`
  && {
    background-color: #ffffff;
    min-width: 20%;
    width: 20%;
    max-width: 20%;
    height: 100%;
    padding-bottom: 0.7rem;
    /* overflow-y: auto; */
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }
`;
const MainBox = styled.div`
&& {
width: 85%;
height: 100%;
background-color: #f5f6f8;
/* background-color: white; */
/* background-color: red; */
display: flex;
overflow: hidden;
/* overflow-x: hidden; */
overflow-y: auto;
}
`;