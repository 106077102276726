import Editor from "@draft-js-plugins/editor";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import { StyledWidgetInputBox } from "../../../../Styles/StyledForms";
export const StyledInputRichText = styled(Editor)`
  && {
    background-color: ${({ theme }: any) =>
      theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    outline: none;
    border: none;
    padding: 0px 1rem 0px 1rem;
    font-family: ${({ theme }: any) =>
      theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
` as typeof Editor;
export const StyledInputWrapper = styled.div`
  && {
    width: 100%;
    height: 100%;
    min-height: 60px;
    max-height: 94px; //do not play with this value
    cursor: pointer;
  }
`;

export const StyledWidgetInputBoxExtended = styled(StyledWidgetInputBox)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    padding-bottom: 40px;
    position: relative;
    background-color: ${theme.palette.default.lightGrey};

    border: ${(props: any) =>
      props?.disabled
        ? `1px solid ${theme.palette.default.errorLight}`
        : "1px solid transparent"};

    .yellow-link {
      color: yellow;
    }
    .DraftEditor-root {
      width: 100%;
      height: 100%;
      min-height: 60px;
      max-height: 86px; //do not play with this value
      overflow: hidden;
      overflow-y: auto;
      word-break: break-all;
      padding: 8px 1rem 8px 1rem;

      ul,
      li {
        padding: 0 0 0 1rem;
        list-style-position: outside;
      }
    }

    .RichEditor-controls {
      display: flex;
      gap: 6px;
      .MuiIconButton-root {
        width: 30px;
        height: 30px;
      }
      .RichEditor-activeButton {
        background-color: rgba(0, 0, 0, 0.04);
        svg {
          color: ${theme.palette.primary.main};
        }
      }
    }

    .superFancyBlockquote {
      color: #ff0000;
      font-family: "Hoefler Text", Georgia, serif;
      font-style: italic;
      text-align: center;
    }

    .inputActionBox {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 9;
    }

    .MuiIconButton-root,
    .toolbar-button {
      width: 28px;
      height: 28px;
    }

    .toolbar-button-selected {
      background-color: rgba(0, 0, 0, 0.05);
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      svg {
        color: ${theme.palette.primary.dark};
        fill: ${theme.palette.primary.dark};
      }
    }

    .public-DraftEditorPlaceholder-root {
      color: ${theme.palette.default.darkGrey};
      font-size: 14px;
      position: absolute;
      z-index: 1;
    }

    //mentions
    .mentionBoxWrapper {
      position: absolute;
      margin: 0 8px;
      z-index: 1;
      bottom: 134px;
      width: calc(100% - 16px);
      background-color: #fff;
      border-top: 6px solid ${theme.palette.primary.dark};
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
      padding: 0.875rem;
      overflow: auto;
      max-height: 350px;
      min-height: 300px;
      overflow: auto;
    }

    .mentionItem {
      cursor: pointer;
      .item {
        display: flex;
        align-items: start;
        gap: 0.5rem;
        font-size: 0.875rem;
        cursor: pointer;
        overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 1;
-webkit-box-orient: vertical;
color: ${theme.palette.default.darkGrey};

        span {
          font-weight: 500;
          color: ${theme.palette.default.darkGrey};
          text-transform: capitalize;
        }
        
      }
    }
  }
`;

export const StyledUploadedMediaBox = styled.div`
  && {
    display: flex;
    width: 100%;
    height: 40px;
    position: absolute;
    bottom: 40px;
    z-index: 99;
    padding: 0 1rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    font-size: 14px;
    background-color: ${theme.palette.default.white};
    p {
      color: ${theme.palette.default.text};
    }
    svg {
      font-size: 1.3rem;
      color: ${theme.palette.default.text};
    }
  }
`;
