import React, { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "../../../../../Store";
import { marketingListStore } from "../../../../../Store/Slices/MarketingList/marketingList.selectors";
import ScheduleBroadcast from "../../../../../AppComponents/Forms/AppScheduleBroadcast";
interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
}

const RenderFormStep5: React.FC<Props> = ({ formStep, setFormStep }) => {
  const { broadcastByIdData }: any = useAppSelector(marketingListStore);

  if (formStep !== 5) {
    return null;
  }
  const isHTMLTemplateExists = !!localStorage.getItem('HTMLTemplate') ?? false;
  const HTMLTemplate = localStorage.getItem('HTMLTemplate') ?? "";
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "85%" }}>
        <ScheduleBroadcast broadcastByIdData={broadcastByIdData} />
      </div>
      <div style={{
        width: "50%"
      }}>
        <iframe
          title="HTML Preview"
          srcDoc={isHTMLTemplateExists ? JSON.parse(HTMLTemplate) : ""}
          className="iframe"
          style={{
            width: "calc(100% - 40px)",
            height: "60vh",
            border: "1px solid #ccc",
            padding: "5px",
            margin: "0 auto",
            display: "block",
          }}
        />
      </div>
    </div>

  );
};

export default RenderFormStep5;