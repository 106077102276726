import { Box } from "@mui/material"
import { AddSectionStyle } from "./utils/ContactProperties"
import CustomBack from "./CustomBack"
import styled from "styled-components"
import Actions from "./Actions"
import { AppFormFastField, AppFormSelectField } from "../../../AppComponents/Forms"
import AppFormCheckboxField from "../../../AppComponents/Forms/AppFormCheckboxField"
import { useEffect } from "react"
import { useFormikContext } from "formik"
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect"
import { getAllSectionDetails } from "../../../Store/Slices/Settings/ContactProperties/contactProperties.selector"
import { useSelector } from "react-redux"
const AddSection = (props: any) => {
    const { values, setFieldValue } = useFormikContext<any>();
    const allSectionDetails = useSelector(getAllSectionDetails)
    useEffect(() => {
        if (values?.parent_id) {
            setFieldValue("is_child_of_other_section", true)
            let parentDetails = allSectionDetails.filter((section: any) => section.id === values?.parent_id)
            setFieldValue("parent", parentDetails)
        }
    }, [values?.parent_id])
    return (
        <>

            {/* <Box sx={AddSectionStyle}> */}
            <CustomFieldWrapper id="name">
                <CustomBack name="Add Section" handleBack={() => props?.closeModel(false)} />
                <AddSectionField>
                    <AppFormFastField
                        name={"label"}
                        label={"Section Label"}
                        placeholder={"Enter Section Name"}
                        // style={CustomInputField}
                        // tooltip="Section Label"
                        require={true}
                        disabled={false}
                    />
                    {/* <AppFormCheckboxField
                        label={"Nest This Under Section"}
                        name={"is_child_of_other_section"}
                        style={{ margin: "14px -2px 17px -13px" }}
                    />
                    <AppReactSelectField
                        name={"parent"}
                        label="Select Sub-Section"
                        options={props?.listData}
                        valueKey={"name"}
                        divStyle={{ maxWidth: "-webkit-fill-available" }}
                        maxWidth="-webkit-fill-available"
                        defaultText="Select a template"
                        isInModel={true}
                        isMulti={false}
                        enableSearch={true}
                        isDisabled={!values?.is_child_of_other_section ? true : false}
                    /> */}
                </AddSectionField>
            </CustomFieldWrapper>
            <Actions handleBack={() => props?.closeModel(false)} />
            {/* </Box> */}
        </>
    )
}
const AddSectionField = styled.div`
    // margin : 30px 35px 30px 22px;
    padding: 20px;
    height: calc(100vh - 137px);
    overflow-y: auto;
`
const CustomFieldWrapper = styled.div`
    // margin-left : 1%
`
export default AddSection