// check for multiselect
export const arraysAreEqual = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]?.value) {
      return false;
    }
  }
  return true;
};
// check diffrence between user input and get-by-id
export const editPayload = async (payload: any, ConatctDetailsById: any) => {
  let differences: any = {};
  
  if (ConatctDetailsById) {
    for (let key in payload) {
      const contactValue = ConatctDetailsById[key];
      if (
        payload.hasOwnProperty(key) &&
        ConatctDetailsById.hasOwnProperty(key)
      ) {
        if (Array.isArray(contactValue)) {
          if (!arraysAreEqual(payload[key], contactValue)) {
            differences[key] = payload[key];
          }
        } else if (
          typeof payload[key] === "boolean" &&
          payload[key] !== contactValue
        ) {
          differences[key] = payload[key];
        } else if (typeof contactValue === "object") {
          if (contactValue?.id && payload[key] !== contactValue?.id) {
            if (payload[key]?.value && payload[key] !== contactValue?.label) {
              differences[key] = payload[key]?.value;
            } else if (payload[key] !== contactValue?.label) {
              differences[key] = payload[key];
            }
          }
          if (
            contactValue?.extension &&
            payload[key]?.number !== contactValue?.number
          ) {
            differences[key] = {
              extension: payload[key]?.extension,
              number: payload[key]?.number,
            };
          }
          if (
            contactValue.country_code &&
            payload[key]?.number !== contactValue?.number
          ) {
            differences[key] = {
              country_code: payload[key]?.country_code,
              number: payload[key]?.number,
            };
          }
        } else if (typeof contactValue === "string") {
          if (payload[key] !== contactValue) {
            differences[key] = payload[key];
          }
        }
      } else {
        if (payload[key] !== contactValue) {
          differences[key] = payload[key];
        }
      }
    }
  }  
  return differences;
};
