// vendors
import { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

// config
import { registerActions } from "./buttonRegisterActions";

// components
import { Input, InputOption, NodeType, Icon, UIButton } from "../../ui-components";

// contexts
import { NodeContext, BuilderContext } from "./../../flow-builder-v2/contexts";

// utils
import { getNodeConfig } from "../../flow-builder-v2/utils";


type Props = {
  language: string;
  onDelete: (button: any) => void;
  onClose: () => void;
  onOptionClick: (action: any) => void;
  selectedAction: any;
  onButtonLabelInputChange: (value: string) => void;
  activeButtonData: any;
};

const ButtonInputOptions = (props: Props) => {
  const { onClose, onOptionClick, selectedAction, onDelete, onButtonLabelInputChange, activeButtonData, language } = props;
  const nodeCntx = useContext(NodeContext);
  const [value, setValue] = useState<string>('');
  const node = getNodeConfig(nodeCntx.subType || nodeCntx.type);

  useEffect(() => {
    if (activeButtonData) {
      setValue(activeButtonData.value.text);
    }
  }, [activeButtonData])

  const handleOptionClick = (action: any) => {
    onOptionClick && onOptionClick(action);
  };

  const handleClose = () => {
    onClose && onClose();
  };

  const handleDeleteButtonClick = () => {
    onDelete && onDelete(activeButtonData);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onButtonLabelInputChange && onButtonLabelInputChange(event.target.value);
  }

  return (
    <Box sx={{ width: "345px", maxWidth: "345px" }}>
      <Box
        sx={{
          py: "4px",
          position: "relative",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #F5F6F8;",
        }}
      >
        <NodeType text={node.name} color={node.color} icon={node.icon} />
        <Box
          sx={{
            position: "absolute",
            height: "24px",
            width: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "20px",
            right: "16px",
          }}
        >
          <IconButton onClick={handleClose}>
            <Icon icon="close" size={12} color="#7E8392" />
          </IconButton>
        </Box>
      </Box>
      <>
        <Box sx={{ p: "16px" }}>
          <Typography
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            Button Title
          </Typography>
          <Input
            error={value === ''}
            placeholder="Title"
            onChange={handleInputChange}
            value={value}
          />
        </Box>
        <Typography
          sx={{
            px: "16px",
            color: "#101010",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            mb: "16px",
          }}
        >
          Button Pressed action
        </Typography>
        <List sx={{ maxWidth: "380px" }}>
          <Grid container spacing={0} columns={2}>
            {registerActions.map((action, index) => (
              <Grid item xs={1} key={`button-action-${index}`}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ px: "16px !important" }}
                    disabled={language !== 'english'}
                    onClick={() => handleOptionClick(action)}
                  >
                    <InputOption
                      label={action.title}
                      color={action.color}
                      icon={action.icon}
                    />
                  </ListItemButton>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        </List>
      </>
      {selectedAction === null && <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleClose}>
            Cancel
          </UIButton>
          <UIButton
            color="error"
            variant="contained"
            startIcon={<Icon icon="delete" color="#fff" size={13} />}
            disabled={language !== 'english'}
            onClick={handleDeleteButtonClick}
          >
            Delete
          </UIButton>
        </Box>
      </Box>}
    </Box>
  );
};

export default ButtonInputOptions;
