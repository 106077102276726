// vendors
import { AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";

// slice
import { getBotId } from "../Slices/main.slice";

// services
import { FlowBuilderService } from "../../../Services/Apis/FlowBuilder";

const defaultData = {
  response: [{ type: "text", value: "<p>Message comes here</p>" }],
  subType: "text",
};

const useAddNewNode = () => {
  const botId = useSelector(getBotId);

  const addNode = (
    id: string,
    stage: string,
    displayOrder: number,
    callback: Function
  ) => {
    const payload = {
      ...defaultData,
      qid: id,
      stage,
      displayOrder,
      id: uuid(),
    };
    FlowBuilderService.createNode(payload, {
      appid: JSON.stringify(botId),
    }).then((response: AxiosResponse<any>) => {
      callback(response.data.status === 200, payload);
    });
  };

  return { addNode };
};
export default useAddNewNode;
