import { useRef, useState } from 'react'
import styled from 'styled-components'
import * as Yup from "yup"
import { AppForm, AppFormObserver, AppFormTextArea, AppSubmitButton } from '../../../../../AppComponents/Forms'
import AppFormCheckboxField from "../../../../../AppComponents/Forms/AppFormCheckboxField"
import { theme } from '../../../../../Customization/Theme'
import { useNotification } from '../../../../../Hooks/useNotification/useNotification'
import { useAppDispatch, useAppSelector } from "../../../../../Store"
import { formSettingsStoreData } from '../../../../../Store/Slices/FormBuilder/formbuilder.selectors'
import { addSettings } from "../../../../../Store/Slices/FormBuilder/formbuilder.slice"

const validationSchema = Yup.object().shape({
    declaration: Yup.string()
        .label("Declaration")
        .min(5, "Declaration must be at least 5 characters")
});
const DeclarationTextSettings = (): JSX.Element => {
    const formRef: any = useRef()
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const formSettings: any = useAppSelector(formSettingsStoreData);
    const [formikProps, setFormikProps] = useState<any>(null);
    return (
        <AppForm
            initialValues={{
                declaration: formSettings?.declaration || "",
                declaration_value: formSettings?.declaration_value || false,
            }}
            validationSchema={validationSchema}
            onSubmit={(values: any, formikProps: any) => {
                const formBuilderObject: any = {
                    declaration: values.declaration,
                    declaration_value: values.declaration_value,
                }
                showAlert("Declaration Settings Saved Successfully", "success");
                dispatch(addSettings(formBuilderObject))
                formikProps.setSubmitting(false)
            }}
            innerRef={formRef}
        >
            <Container>
                <FieldLabel>Declaration Settings</FieldLabel>
                <AppFormTextArea
                    name="declaration"
                    errorMessage=''
                    label='Declaration Text'
                    placeholder='Declaration Text'
                    defaultBorder={true} labelStyle={{ color: theme.palette.default.newFontColor }}
                    rows={10}
                />
                <div className='fields-container'>
                    <AppFormCheckboxField name="declaration_value" label={"Keep default Declaration Selected ?"} />
                </div>
                <AppFormObserver setFormikProps={setFormikProps} />
                <div style={{ width: "250px", marginTop: "10px" }}>
                    <AppSubmitButton title="Save" variant={"primarydark"} />
                </div>
            </Container>
        </AppForm >
    )
}
const Container = styled.div`
&&{
  padding: 16px;
    .infoText{
      color:${theme.palette.default.darkGrey};
      padding: 0.5rem 0;
    }
    .fields-container{
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
    .fields-container > div {
      flex: 1 1 calc(33.333% - 20px); /* Adjust the width to fit three items per row */
      box-sizing: border-box;
    }
}`

const FieldLabel = styled('p')({
    color: theme.palette.default.newFontColor,
    fontSize: "1.1rem",
    fontWeight: 600,
    padding: "0.8rem 0",
});


export default DeclarationTextSettings;
