import { Grid } from "@mui/material";
import { Button, ContactUsDetails, LivePlanContainer, PlanCards, SubscriptionInfo, Title } from "./style"
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
const ContactUs = () => {
    const listData = ["Product Customization", "On-Premise Installation", "AI/NLP Solutions", "Custom Integrations", "Custom Feature Development"]
    return (
        <ContactUsDetails>
            <Title fontSize={30}>Looking for Enterprise/On-Premise Solutions</Title>
            <Title fontSize={20}>Need something specific? Contact us now. We have a proven track record in providing custom & enterprise solutions to businesses across industries and size.</Title>
            <PlanCards container spacing={2} padding={"2.5rem 11rem 1rem"}>
                {listData.map((res: any, index: number) => {
                    return (
                        <Grid item xs={6} md={index >= 3 ? 6 : 4} style={{ marginLeft: index === 3 ? "5rem" : index === 4 ? "-7rem" : "" }}>
                            <LivePlanContainer>
                                <CheckCircleOutlinedIcon color="success" style={{ color: "#F5C51C" }} />
                                <SubscriptionInfo color={"#ffff"} align={"left"} width={"200px"}>{res}</SubscriptionInfo>
                            </LivePlanContainer>
                        </Grid>
                    )
                })}</PlanCards>
            <LivePlanContainer>
                <Button
                    variant="outline"
                    minWidth={"235px"}
                    backgroundColor={"#ffff"}
                    style={{ color: "rgb(54, 84, 227)"}}
                    onClick={() => window.open("https://www.geta.ai/contact-us/", "_blank")}
                >
                    Contact Us
                </Button>
            </LivePlanContainer>
        </ContactUsDetails>
    )
}
export default ContactUs