import React, { memo } from "react";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";

interface Props {
  userMessage: boolean;
}

const AppRenderName: React.FC<Props> = ({
  userMessage = true,
}) => {

  const { userInfoData } = useAppSelector(allMessagesStore);
  const userName:string= userInfoData?.user?.toString() ?? userInfoData?.name?.toString()?? 'User';

  if (userMessage) {
    return null;
  }

  return (
    <p style={{ fontSize: "12px", color: "#2c5cc5", fontWeight: "600" }}>
      {userName}
    </p>
  );
};

export default memo(AppRenderName);
