import {
  Box,
  IconButton,
  TablePagination,
  Typography,
  debounce,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import TemplateAPIERROR from "../../../../../src/assets/images/TemplateAPIERROR.png";
import Danger from "../../../../../src/assets/images/danger-live.svg";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../AppComponents/Forms/AppFormSearchField";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader/LoaderComponent";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../Store";
import { RoleActions } from "../../../../Store/Slices/Settings/LiveChat/RolesPermission/role.action";
import {
  userRolesListing,
  userRolesListingCount,
} from "../../../../Store/Slices/Settings/LiveChat/RolesPermission/role.selectors";
import { ErrorComponent } from "../../../Broadcast/WhatsApp/Templates/TemplatesListingCSS";
import {
  AddCannedResponse,
  DeleteCannedResponse,
} from "../../ChatSettings/CannedResponse/CannedResponsesstyles";
import {
  AdminContainer,
  RolePermissionHeader,
  RolePermissionTable,
  RolesWrapper,
} from "./RolesPermissionsStyle";
import moment from "moment";
import { TableWrapper } from "../../../../TableStyle";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";

interface Props extends LoaderComponentProps { }

const RolesPermissions: React.FC<Props> = ({ setLoading }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const userRoleListing = useSelector(userRolesListing);
  const userRoleListingCount = useSelector(userRolesListingCount);
  const [selectedId, setSelectedID] = useState();

  // Initial Form Values
  const [userInput, setUserInput] = useState<any>({
    role_name: "",
    roleOptions: "",
  });

  const [localValues, setLocalValues] = useState<any>({
    search: "",
    itemsPerPage: 10,
    currentPage: 0,
  });

  // Modals
  const useModal1 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal1: isShowing, toggleModal1: toggle };
  };
  const useModal2 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal2: isShowing, toggleModal2: toggle };
  };
  const { isShowingModal1, toggleModal1 } = useModal1();
  const { isShowingModal2, toggleModal2 } = useModal2();
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Get listing of Roles
  useEffect(() => {
    getAllRolesListing();
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllRolesListing = () => {
    setLoading(true);
    const data = {
      search: localValues?.search,
      page_size: localValues.itemsPerPage,
      page_number: localValues.currentPage + 1,
    };
    dispatch(RoleActions.getUserListing(data)).then((res: any) => {
      setLoading(false);
    });
  };

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };

  //  // Add  Role
  const addRole = (values: any, SubmitProps: any) => {
    setLoading(true);
    const payload = {
      label: values.role_name,
      clone_role: values.roleOptions,
    };

    dispatch(RoleActions.AddRoles(payload))
      .then((res: any) => {
        // const id = res?.payload?.data?.id
        showAlert(res?.payload?.data?.message, "success");
        getAllRolesListing();
        toggleModal1();
        setLoading(false);
      })
      .catch((err: any) => {
        showAlert(err?.payload?.data?.message, "error");
      })
      .finally(() => {
        toggleModal1();
        SubmitProps?.resetForm();
      });
  };
  // Edit / Update Role
  const updateRole = (values: any, SubmitProps: any) => {
    setLoading(true);
    const payload = {
      label: values.role_name,
      id: selectedId,
    };

    dispatch(RoleActions.UpdateRole(payload))
      .then((res: any) => {
        if (res.payload.data.status) {
          showAlert(res.payload.data.message, "success");
        } else {
          showAlert(res.payload.data.message, "error");
        }
        getAllRolesListing();

        toggleModal1();
      })

      .finally(() => {
        toggleModal1();
        setLoading(false);

        SubmitProps?.resetForm();
      });
  };

  // Delete Role
  const handleDeleteMessage = (id: any) => {
    setLoading(true);
    const data = {
      id,
    };
    dispatch(RoleActions.DeleteRole(data))
      .then((res: any) => {
        toggleModal2();
        getAllRolesListing();
        setLoading(false);
        if (res.payload.data.status === true) {
          showAlert(res.payload.data.message, "success");
        } else {
          showAlert(res.payload.data.message, "error");
        }
      })
      .finally(() => {
        toggleModal2();
        setLoading(false);
      });
  };
  // Get Permission ID
  const getPermissionID = (id: any) => {
    navigate(`/settings/users-and-permissions/roles/list/${id}`);
  };
  // Search Functionality
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };

  const BotValidationSchema = Yup.object().shape({
    role_name: Yup.string()
      .min(2)
      .max(24)
      .required("Please fill required field")
      .label("role_name"),
  });


  const header = [
    "Name",
    "Users",
    "Created On",
    "Updated On",
    "Actions",
  ];

  const keyToHeaderMap: any = {
    "Name": "label",
    "Users": "user_count",
    "Created On": "created_time",
    "Updated On": "modified_time",
  };

  const colWidth: any = {
    "Name": "200",
    "Users": "200",
    "Created On": "200",
    "Updated On": "200",
    "Actions": "200",
  };


  const renderColumns = (row: any, header: any,) => {
    if(header === "Actions") {
        return <Box style={{
          display: row?.name === "admin" ? "none" : "block",
        }}>
                <IconButton
                      onClick={() => {
                        getPermissionID(row?.id);
                      }}
                    >
                      <AppMaterialIcons iconName="EditOutlined" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        toggleModal2(row.id);
                        setSelectedID(row.id);
                      }}
                    >
                      <AppMaterialIcons iconName="DeleteOutlined" />
                    </IconButton>
            </Box>
    } else {
        return <Typography
            className="table-text"
            sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            }}
        >
          {
            header === "Name" ? <Box
              onClick={() =>
              row?.name === "admin"
                ? undefined
                : getPermissionID(row?.id)
            }
            className={row?.name === "admin" ? "" : "link"}>
              {row[keyToHeaderMap[header]]}
            </Box> 
            : header === "Users" ? <>{row[keyToHeaderMap[header]] || 0}</> :
            row[keyToHeaderMap[header]]
              ? row[keyToHeaderMap[header]]
              : "-"
          }
        </Typography>
    }
  }

  return (
    <RolesWrapper>
      <RolePermissionHeader>
        <div className="response-header__left">

          <h4>Roles & Permissions</h4>
        </div>
        <div className="response-header__right">
          <div className="response-search">
            <AppForm initialValues={{}} onSubmit={() => { }}>
              <AppFormSearchField
                divStyle={{ marginBottom: "0" }}
                placeholder="Search"
                name="search"
                defaultBorder={true}
                onChange={(e: any) => handleSelectSearch(e, "search")}
                clearCallback={(e: any) => clearCallback(e, "search")}
              />
            </AppForm>
          </div>
          <div className="response-button">
            <AppButton
              onClick={() => {
                toggleModal1();
                setIsEditing(false);
                setUserInput({
                  role_name: "",
                });
                navigate("/settings/users-and-permissions/roles/list/create");
              }}
            >
              <AppMaterialIcons iconName="add" />
              Add Role
            </AppButton>
          </div>
        </div>
      </RolePermissionHeader>
      <AdminContainer>



      <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 228px)"}>
              <div>
              <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                      key={index}
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* Table Body */}
              </Box>

              <Box className="table-body">
                {userRoleListing && userRoleListing?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 279px)" />
                ) : (
                  <>
                    {userRoleListing && userRoleListing?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className={`table-row`} >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>

              </div>

          
        </TableWrapper>


        <AppModel
            isShowing={isShowingModal1}
            onClose={() => toggleModal1()}
            divStyle={{
              padding: 0,
            }}
            PaperProps={{
              style: {
                boxShadow: "none",
                width: "500px",
              },
            }}
          >
            <AddCannedResponse>
              <div className="header">
                {isEditing ? <h4>Edit Role</h4> : <h4>Add Role</h4>}
                <span onClick={() => toggleModal1()}>
                  <IconButton>
                    <AppMaterialIcons iconName="close" />
                  </IconButton>
                </span>
              </div>
              <div className="body">
                <AppForm
                  initialValues={userInput}
                  onSubmit={(values: any, submitProps: any) => {
                    if (isEditing && selectedId) {
                      updateRole(values, submitProps);
                    } else {
                      addRole(values, submitProps);
                    }
                  }}
                  validationSchema={BotValidationSchema}
                >
                  <AppFormField
                    label="Role Name"
                    name="role_name"
                    placeholder="Super Admin"
                  />
                  <div className="footer">
                    <AppButton variant="grey" onClick={() => toggleModal1()}>
                      Cancel
                    </AppButton>
                    <div className="submit-button">
                      <AppSubmitButton
                        variant="cta"
                        style={{ width: "180px" }}
                        title={isEditing ? "Update Role" : "Add Role"}
                      />
                    </div>
                  </div>
                </AppForm>
              </div>
            </AddCannedResponse>
          </AppModel>

          <AppModel
            isShowing={isShowingModal2}
            onClose={toggleModal2}
            divStyle={{
              padding: 0,
            }}
            PaperProps={{
              style: {
                boxShadow: "none",
                width: "500px",
              },
            }}
          >
            <DeleteCannedResponse>
              <div className="header">
                <div className="header-left">
                  <div className="danger">
                    <img src={Danger} alt="" />
                  </div>
                  <div className="text">
                    <h4>Delete The Role</h4>
                    <p>Are you sure, you want to delete?</p>
                  </div>
                </div>
                <div className="header-right">
                  <span onClick={() => toggleModal2()}>
                    <AppMaterialIcons iconName="close" />
                  </span>
                </div>
              </div>
              <div className="footer">
                <AppButton variant="grey" onClick={() => toggleModal2()}>
                  Cancel
                </AppButton>
                <AppButton
                  variant="danger-filled"
                  onClick={() => {
                    return handleDeleteMessage(selectedId);
                  }}
                >
                  <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                  Delete
                </AppButton>
              </div>
            </DeleteCannedResponse>
          </AppModel>
      </AdminContainer>
      <div className="footer-pagination">
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={localValues?.currentPage}
          count={Number(userRoleListingCount)}
          rowsPerPage={localValues?.itemsPerPage}
        />
      </div>
    </RolesWrapper>
  );
};

export default withLoader(RolesPermissions);
