import { Switch, debounce } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { Label } from "../../Customization/CommonElements";
import useDebounce from "../../Hooks/useDebounce";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../Models/ChatbotConfiguration";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { ConversationService } from "../../Services/Apis/ConversationService";
import { useAppDispatch } from "../../Store";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { FaqUserQueryAction } from "../../Store/Slices/Faq/UserQueries/userquery.actions";
import { AppPagination } from "../../TableStyle";
import { formatString } from "../../Utils";
import { ReactComponent as SearchIcon } from "../../assets/images/Searchicon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import {
  FaqTextWrap,
  StyledAppInput
} from "../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import DeleteConfirmationModal from "../BotBuilder/BotKnowledge/Faq/commonComponents/DeleteConfirmationModal";
import FaqlistTable from "./FaqTable";
interface Props extends LoaderComponentProps { }
const FaqBuilder: React.FC<Props> = ({ setLoading }) => {
  useDocumentTitle("Faq Builder");
  const currentBot = useSelector(useCurrentBot);
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const [dataRows, setDataRows] = useState<any>([]);
  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [botConfigData, setBotConfigData] = useState<any>(null);
  const [dataCount, setDataCount] = useState(0);
  const dispatch = useAppDispatch();
  // Setting Values Locally
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
  });
  const [searchInput, setSearchInput] = useState<any>({
    leads_search_term: "",
  });

  // Multiple Document Ids
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);

  // ! this hook is used so that we can use Search  onchange with lower number of api calls
  // ! as it return the value once user stopped typing after given time

  const debouncedSearchTerm = useDebounce<string>(
    searchInput?.leads_search_term,
    300
  );

  const getFaqList = () => {
    const headers = {
      appid: JSON.stringify({
        botid: currentBot.bot_id,
      }),
    };
    ConversationService.getFaqList(
      {
        page: localValues.currentPage,
        limit: localValues.itemsPerPage,
        query: localValues.query,
      },
      headers
    )
      .then((response: any) => {
        setDataRows(response?.data?.data.data || null);
        setFilteredRows(response?.data?.data.data || null);
        setDataCount(response?.data?.data.count);
      })
      .catch((error: any) => { })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (debouncedSearchTerm.length === 0) {
      setFilteredRows(dataRows);
    } else {
      let temp = dataRows.filter((row: any) => {
        for (let key in row) {
          if (
            new String(row[key])
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase())
          ) {
            return new String(row);
          }
        }
      });
      setFilteredRows(temp);
    }
  }, [debouncedSearchTerm]);  // eslint-disable-line react-hooks/exhaustive-deps
  const requestSearch = (values: any, SubmitProps: any) => {
    setSearchInput(values);
  };

  useEffect(() => {
    getFaqList();
  }, [localValues]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (currentBot.chatbot_configuration && currentBot.chatbot_configuration) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot.chatbot_configuration as string
      ).then((res: AxiosResponse<ChatbotConfiguration>) => {
        if (res?.data?.show_suggestion) {
          setBotConfigData(res?.data);
          setShowSuggestions(res?.data?.show_suggestion);
        }
      });
    }
  }, [currentBot]);

  const handleChangeSuggestionSettings = (value: boolean) => {
    setShowSuggestions(value);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          ...botConfigData,
          show_suggestion: value,
        },
      }
    ).then((response) => {
      showAlert("Suggestion settings updated", "success");
    }).catch((error) => {
      showAlert("Something went wrong", "error");
    });
  };

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  // Modals
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();

  // / Function To Delete website
  const deletSourceByDocumentId = () => {
    // headers
    const HEADERS = {
      appid: JSON.stringify({
        botid: currentBot.bot_id,
      }),
    };
    let faqids;
    if (selectedDocumentIds) {
      faqids = selectedDocumentIds;
    } else {
      faqids = selectedDocumentIds;
    }
    const data = {
      HEADERS,
      faqids,
    };

    dispatch(FaqUserQueryAction.DeleteFaqsByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          getFaqList();
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
    setSelectedDocumentIds([]);
    toggleConfirmModal();
  };

  return (
    <React.Fragment>
      <FaqTextWrap style={{ padding: "0px" }}>
        <div className="top-header" style={{ padding: "1rem 1rem" }}>
          <div className="header-left">
            <p>
              Empower your bot with any text you desire! Simply paste it into
              the text box and witness the transformation. Our AI engine will
              convert your input into engaging dialogues, ready to interact and
              respond to user queries. Give it a try now!
            </p>
          </div>
          <div
            className="serach-class"
            style={{ alignItems: "center", maxWidth: "800px" }}
          >
            {selectedDocumentIds.length ? (
              <span className="delete-button">
                <AppButton
                  onClick={() => toggleConfirmModal()}
                  variant="danger-filled"
                >
                  Delete <DeleteIcon />
                </AppButton>
              </span>
            ) : null}
            <div
              style={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                width: "max-content",
              }}
            >
              <Switch
                checked={
                  showSuggestions ||
                    localStorage.getItem("showSuggestions") === "true"
                    ? true
                    : false
                }
                inputProps={{ "aria-label": "controlled" }}
                onChange={(e: any) => {
                  handleChangeSuggestionSettings(e?.target?.checked);
                  localStorage.setItem("showSuggestions", e?.target?.checked);
                }}
              />
              <Label>Show auto suggestions</Label>
            </div>
            <div className="faq-search">
              <StyledAppInput>
                <input
                  type="text"
                  onChange={(e: any) => handleSelectSearch(e)}
                  name="query"
                  id="query"
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
            <div className="button">
              <AppButton
                startIcon="ri-add-line"
                onClick={() => {
                  let url = formatString(
                    "/bot/{0}/setup/bot-knowledge/faqs/faq-answer",
                    [currentBot.bot_id]
                  );
                  navigate(url);
                }}
                style={{ minWidth: "150px" }}
              >
                Add FAQs
              </AppButton>
            </div>
          </div>
        </div>
        <FaqlistTable
          rows={filteredRows}
          getFaqList={getFaqList}
          setSelectedDocumentIds={setSelectedDocumentIds}
          selectedDocumentIds={selectedDocumentIds}
          localValues={localValues}
        />
      </FaqTextWrap>
      <DeleteConfirmationModal
        toggleConfirmModal={toggleConfirmModal}
        deletSourceByDocumentId={deletSourceByDocumentId}
        selectedDocumentIds={selectedDocumentIds}
        isShowingConfirmModal={isShowingConfirmModal}
        type={"FaQs"}
      />
      {filteredRows?.length ? (
        <AppPagination
          style={{ paddingRight: "-1px" }}
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={localValues?.currentPage}
          count={dataCount}
          rowsPerPage={localValues?.itemsPerPage}
        />
      ) : null}
    </React.Fragment>
  );
};

export default withLoader(FaqBuilder);
