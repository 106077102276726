import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import AppButton from "../../AppComponents/AppButton";
import loginIllustration from "../../assets/images/Successful.svg";
import {
  BrandBox,
  LeftSide,
  LoginPageWrap,
  LogoText,
  RightSide
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { CoreService } from "../../Services/Apis/CoreApis";
import logo from "../../assets/images/logo.svg";
import { LoaderComponentProps, withLoader } from '../../AppComponents/Loader';
import { logoutAuth, useAuthStore } from '../../Store/Slices/AuthSlice';
import { useAppDispatch } from '../../Store';
import { SubscriptionsNetworkService } from '../../Store/Slices/Subscriptions/subscriptionsNetworkService';
import { useSelector } from 'react-redux';
import { setUserCreditsInfo } from '../../Store/Slices/CurrentUser/CurrentUserSlice';

// Subscription Success Page 
const SuccessSubscription = () => {
  let navigate = useNavigate();
  let { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const { userConfig } = useSelector(useAuthStore);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSuccessVerification, setShowSuccessVerification] =
    useState<boolean>(false);

  // Subscription Success Callback
  const verifyCall = (customer_Id:any) => {    
    let data = {
      "business_id" : userConfig?.payload?.business_id,
      "customer_id" : customer_Id,
      "hostedpage_id" : window?.location?.search.split("=")[1]
    }
    SubscriptionsNetworkService.successSubscripation(
      data
    )
      .then((res: any) => {
          if(res?.data?.status){
            showAlert(res?.data?.message, "success");
            CoreService.getCredits().then((response) => {
              const reversedItems = response?.data
              dispatch(setUserCreditsInfo(reversedItems));
            });
            setTimeout(() => {
              if(res?.data?.message?.includes("credits have been added successfully")){
                window.location.href= "/subscription/wallet-invoices"
              }else{
                window.location.href= "/subscription/subscription-plans"
              }
              
              // navigate("/broadcast/subscription/subscription-plans/", { replace: true })
            },1000)
          }
      })
      .catch((err: any) => {
      })
      .finally(() => {
      });
  };

  useEffect(() => {
    if (userConfig?.payload?.customer_id) {
      verifyCall(userConfig?.payload?.customer_id);
    }
  }, [userConfig?.payload?.customer_id]);

  return (
    <>
      <LoginPageWrap widthDisable = {true} heightDisable = {true}>
        <LeftSide>
          <img src={loginIllustration} style={{width:"70%"}} alt="" />
        </LeftSide>

        <RightSide
          style={{
            marginTop: "-1.5rem",
            alignItems: "center",
          }}
        >
          <ContaierBox>

            <BrandBox>
              <img src={logo} alt="" />
              <LogoText>Geta</LogoText>
            </BrandBox>

            {showSuccessVerification ? (
              <>
                {/* <p
                  className="title"
                  style={{ color: theme.palette.default.success }}
                >
                  congratulations 
                </p> */}
                <CheckCircleIcon style={{ fontSize: '32px', margin: '-1.5rem 0', color: theme.palette.default.success }} />
                <p className="caption">
                  {" "}
                  Please wait while we redirecting to login...{" "}
                  <AppButton
                    variant="outline"
                    onClick={() => navigate("/login")}
                  >
                    Go to Login
                  </AppButton>
                </p>
              </>
            ) : (
              <>
                {/* <p className="title" style={{ color: theme.palette.default.success }}>Congratulations </p> */}
                <CircularProgress size={36} />

                <p className="caption">
                  {" "}
                  Please wait while we process your details...{" "}
                </p>
              </>
            )}
          </ContaierBox>
        </RightSide>
      </LoginPageWrap>
    </>
  );
};

export default SuccessSubscription;

const ContaierBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 55%;
    gap: 3rem;
    padding: 1rem;
    margin-top: -2rem;
    height: calc(53rem - 100px) !important;
    .title {
      font-size: 19px;
      font-weight: 500;
      letter-spacing: 1px;
      color: ${theme.palette.primary.main};
      color: ${theme.palette.default.error};
      font-family: ${theme.typography.fontFamily};
    }
    .caption {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 1px;
      color: ${theme.palette.primary.main};
      color: ${theme.palette.default.darkGrey};
      font-family: ${theme.typography.fontFamily};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
    }
  }
`;
