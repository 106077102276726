import styled from "styled-components";
import { theme } from "../../Customization/Theme";

export const TextTitle = styled.p`
  && {
    font-size: 16px;
    font-wight: 500;
    position: relative;
    padding-left: 10px;
    margin-left: 10px;
    align-items: center;
    display: flex;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: linear-gradient(
        to bottom,
        rgba(132, 90, 223, 0.5) 50%,
        rgba(35, 183, 229, 0.5) 50%
      );
      border-radius: 0.5rem;
    }
  }
`;
export const SummaryWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #f5f6f8;

    .cover {
      background-color: ${theme.palette.default.white};
      border-radius: 4px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }

    .alt_cover {
      background-color: ${theme.palette.default.white};
      border-radius: 4px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      padding: 16px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
    }

    .graph_wrapper {
      height: calc(100% - 57px);
      padding: 16px;
    }

    .react-grid-layout {
      margin-bottom: 20px;
    }

  

    .wrapper_div {
      width: 100%;
      padding: 10px;
      // background-color: white;
      height: auto;
      background-color: #F1F1F1;
      
    }

    .parent_div2 {
      width: 100%;
      padding: 10px;
      background-color: white;
      height: auto;
      background-color: #f5f6f8;
    }
    .users {
      width: 50%;
      height: 100%;
      background-color: ${theme.palette.default.white};
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-radius: 10px;
    }

    .users_div {
      width: 50%;
      height: 100%;
      background-color: ${theme.palette.default.white};
      padding: 10px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-radius: 4px;
    }

    .users_text {
      text-align: left;
      font-size: 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      .users_tooltip {
        font-size: 10px;
      }
    }
    .users_count {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Inter;
      font-weight: 400;
    }
    .users_count_text {
      font-size: 32px;
      font-family: Inter;
      font-weight: 500;
      > span {
        margin-left: 10px;
        font-size: 20px;
      }
    }

    .average {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      background-color: white;
      border-radius: 10px;
      padding: 10px 20px;
    }
    .section_f {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-radius: 10px;
    }
    .section_f_text {
      text-align: left;
      font-size: 16px;
      display: flex;
      align-items: center;
      font-family: Inter;
      font-weight: 500;
      .c_div_tooltip {
        font-size: 10px;
      }
    }

    .c_div1_icon {
      width: 38px;
      height: 38px;
    }
    .c_div1_icon > img {
      width: 100%;
    }
    .b_div1_parametr {
      font-size: 18px;
      font-family: Interb_div_text;
      font-weight: 500;
    }
    .section_s {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .avg_count {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
    }
    .avg_icon {
      width: 38px;
      height: 38px;
    }
    .avg_icon > img {
      width: 100%;
    }
    .dotted_hr {
      height: auto;
      border: 0.2px dashed gray;
      opacity: 50%;
      margin: 0 20px;
    }

    .csat_header {
      font-size: 16px;
      padding: 10px 24px 10px 10px;
      display: flex;
      align-items: center;
      font-family: Inter;
      font-weight: 500;
      margin-bottom: 10px;
      border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      .d_div_tooltip {
        font-size: 10px;
      }
    }
    .csat_box {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 24px;
      gap: 10px;
    }
    .csat_info {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 10px;
      font-family: Inter;
    }
    .csat_count {
      font-size: 32px;
      font-family: Inter;
      font-weight: 500;
    }
    .csat_name {
      font-size: 14px;
      font-family: Inter;
      font-weight: 500;
    }
    .csat_graph {
      width: 70%;
    }
    .conversation_box {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      background-color: white;
      border-radius: 10px;
    }
    .status_box {
      width: 22%;
    }
    .conversation_header {
      text-align: center;
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 10px 24px 10px 10px;
      border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      .g_div_tooltip {
        font-size: 10px;
      }
    }
    .conversation_header_text {
      font-size: 16px;
      font-family: Inter;
      font-weight: 500;
    }

    .conversation_status_count {
      font-size: 72px;
      text-align: center;
      font-family: Inter;
      font-weight: 500;
    }
    .g_div1_i {
      font-size: 24px;
      margin-top: 18px;
      font-family: Inter;
      font-weight: 300;
    }
    .g_div2 {
      width: 40%;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-top: 50px;
    }
    .status_container_parent {
      width: 100%;
      display: flex;
      padding: 50px 20px;
    }
    .status_container {
      height: auto;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .status {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .status_text {
      font-size: 14px;
      font-family: Inter;
      font-weight: 300;
    }
    .status_count {
      font-size: 32px;
      font-family: Inter;
      font-weight: 500;
    }

    .g_div2_open {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-family: Inter;
    }
    .g_div2_icon {
      align-items: center;
      height: 38px;
      width: 38px;
    }
    .g_div2_text {
      text-align: center;
      font-size: 14px;
      font-family: Inter;
      font-weight: 300;
    }
    .g_div2_count {
      font-size: 32px;
      text-align: center;
      font-family: Inter;
      font-weight: 500;
    }
    .content_box {
      width: 100%;
      height: 100%;
      background-color: ${theme.palette.default.white};
      border-radius: 10px;
      .h_div_tooltip {
        font-size: 10px;
      }

      .title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-family: Inter;
        font-weight: 500;
        padding: 10px 24px 10px 10px;
        border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      }
    }

    .i_div_in {
      display: flex;
      height: 90%;
      gap: 50px;
      justify-content: center;
      align-items: center;
      font-family: Inter;
      padding: 30px;
    }
    .count_box {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-family: Inter;
    }
    .total_msg_count {
      font-size: 72px;
      font-family: Inter;
      font-weight: 500;
      line-height: 72px;
    }
    .i_div_total_text {
      font-size: 24px;
      text-align: center;
      font-family: Inter;
      font-weight: 300;
      margin-right: 70px;
    }
    .msg_box {
      width: 100%;
    }
    .messages_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      font-family: Inter;
    }
    .messages {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: Inter;
    }
    .message_text {
      font-size: 16px;
      font-family: Inter;
      font-weight: 400;
    }
    .message_count {
      font-size: 16px;
      font-family: Inter;
      font-weight: 400;
    }

    .usergrowth {
      min-width: 98.2%;
      min-height: 440px;
      background-color: white;
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      font-family: Inter;
      padding: 20px;
    }

    .l_div_label {
      font-size: 16px;
      display: flex;
      align-items: center;
      font-family: Inter;
      font-weight: 500;
    }

    .traffic_graph {
      width: 100%;
      height: 100%;
      padding: 20px;
    }

    .dotted_hr {
      height: auto;
      border: 0.2px dashed gray;
    }

    .traffic_header {
      font-size: 16px;
      font-weight: 500;
    }
  }
  @media (max-width: 1660px) {
    .g_div1_header_p {
      font-size: 16px;
      font-family: Inter;
      font-weight: 500;
    }
    .conversation_status_count {
      font-size: 65px;
    }
    .g_div1_i {
      font-size: 20px;
      margin-top: 18px;
      font-family: Inter;
      font-weight: 300;
    }
    .total_msg_count {
      font-size: 65px;
      font-family: Inter;
      font-weight: 500;
      line-height: 65px;
    }
    .i_div_in {
      flex-direction: column;
      gap: 0px;
      justify-content: flex-start;
    }
    .messages {
      flex-direction: row;
      gap: 2px;
    }
  }
  @media (max-width: 1440px) {
    .users_count_text {
      font-size: 25px;
      font-family: Inter;
      font-weight: 500;
      > span {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .conversation_status_count {
      font-size: 45px;
    }
    .status_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .status_count {
      font-size: 22px;
    }
  }
`;
