import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { CompositeDecorator, EditorState, Modifier } from "draft-js";
import { AppMaterialIcons } from "../../../Icons";
import { getEditorSelectedText, moveFocusToEnd } from "./utils";

interface Props {
  editorState: EditorState;
  handleEditorChange: (editorState: EditorState) => void;
  icon?: string;
  focusEditor: () => void;
}

const EditorLinkAttach: React.FC<Props> = ({
  editorState,
  // setEditorState,
  handleEditorChange,
  icon = "Link",
  focusEditor,
}) => {
  const handleAddLink = () => {
    const link = getEditorSelectedText(editorState);
    if (!link) return;

    const decorator = createLinkDecorator();

    const currentContent = editorState?.getCurrentContent();
    const createEntity = currentContent?.createEntity("LINK", "MUTABLE", {
      url: link,
    }); //eslint-disable-line
    let entityKey = currentContent.getLastCreatedEntityKey();
    const selection = editorState.getSelection();
    const textWithEntity = Modifier.replaceText(
      currentContent,
      selection,
      link,
      undefined,
      entityKey
    );
    const newEditorState = EditorState.createWithContent(
      textWithEntity,
      decorator
    );
    const newStateWithFocus = moveFocusToEnd(newEditorState);
    handleEditorChange(newStateWithFocus);
   
  };

  return (
    <Box
      sx={{
        position: "relative",
        "&:hover > div": {
          display: "flex",
          opacity: 1,
        },
      }}
    >
      <IconButton title={"Link"} onClick={() => handleAddLink()}>
        <AppMaterialIcons
          iconName={icon}
          style={{ fontSize: "1rem", rotate: "-45deg" }}
        />
      </IconButton>
    </Box>
  );
};
export default EditorLinkAttach;

const Link = ({
  entityKey,
  contentState,
  children,
}: {
  entityKey: any;
  contentState: any;
  children: any;
}) => {
  let { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      style={{ color: "blue", fontStyle: "italic" }}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

const findLinkEntities = (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);
