import { RootState } from "../../index";

export const templateDetailsByID = (state: RootState) => state.email.data.templateDetailsByID;
export const emailVerification = (state: RootState) => state.email.data.emailVerifiedStatus;
export const emailDomainSteps = (state: RootState) => state.email.data.activeSteps;
export const emailDataStore = (state: RootState) => state.email.data;
export const emailHTMLTemplate = (state: RootState) => state.email.data.htmlData;
export const emailEditedHTML = (state: RootState) => state.email.data.editHtml;
export const validateEmailListing = (state: RootState) => state.email.data.validEmailList;
export const emailTemplateListing = (state: RootState) => state.email.data.allTemplatesData;
export const emailTemplateListingById = (state: RootState) => state.email.data.filteredTemplateByID;
export const error = (state: RootState) => state?.email?.error;
export const domainError = (state: RootState) => state?.email?.data?.domainError;
export const DeleteError = (state: RootState) => state?.email?.data?.deleteError;
export const DeleteSuccess = (state: RootState) => state?.email?.data?.deleteSuccess;
export const emailEnabled = (state: RootState) => state.email.data?.emailEnable;
export const broadcastByIdData = (state: RootState) => state.email.data?.broadcastByIdData;
export const dateRange = (state: RootState) => state.email.data?.DateRange

export const domainVerifyError = (state: RootState) => state?.email?.data?.domainVerifyError;
export const emailError = (state: RootState) => state?.email?.data?.emailError;
export const testEmailModel = (state: RootState) => state?.email?.data?.testEmailModel;
export const galleryEmailTemplates = (state: RootState) => state?.email?.data?.allGelleryTemplatesData;
export const isDomainDeleted = (state: RootState) => state?.email?.data?.isDomainDeleted;
export const getEmailTemplateList = (state:RootState) => state?.email?.data?.emailTemplateListData 
export const emailTemplateListsData = (state:RootState) => state?.email?.data?.emailTemplateLists
export const getIsCountinued = (state: RootState) => state?.email?.data?.isCountinued;

// answered faq questions
export const isEmailTemplateExist = (state: RootState) => {
    return state?.email?.data?.checkTemplateExist;
};