import React, { useMemo, useState } from "react";
import type { ReactNode } from "react";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import type { Active, UniqueIdentifier } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import { DragHandle, SortableItem, SortableOverlay } from "./Components";
import { StyledLifeCycleWrapper } from "./SortableListStyles";

interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: any[];
  onChange(items: T[], prevIndex: number, nextIndex: number): void;
  renderItem(item: any, index?: number): ReactNode;
  orientation?: "horizontal" | "vertical";
}

export function SortableList<T extends BaseItem>({
  items = [],
  onChange,
  renderItem,
  orientation,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);

  const activeItem = useMemo(
    () =>
      items?.length > 0 ? items?.find((item) => item.id === active?.id) : items,
    [active, items]
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(
            arrayMove(items, activeIndex, overIndex),
            activeIndex,
            overIndex
          );
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        <StyledLifeCycleWrapper orientation={orientation} role="application">
          {items?.length > 0 &&
            items?.map((item, index) => (
              <React.Fragment key={index + 1}>
                {renderItem(item, index)}
              </React.Fragment>
            ))}
        </StyledLifeCycleWrapper>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;
