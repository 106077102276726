import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { Table } from ".";

interface SkeletonLoaderProps {
  numberOfCol?: number;
  numberofRow?: number;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  numberOfCol = 4,
  numberofRow = 4,
}) => {
  const [numbersOfCol, setNumbersOfCol] = useState<number[]>([]);
  const [numbersofRow, setNumbersofRow] = useState<number[]>([]);

  useEffect(() => {
    if (numberofRow) {
      setNumbersOfCol(Array.from(Array(numberOfCol).keys()));
      setNumbersofRow(Array.from(Array(numberofRow).keys()));
    }
  }, [numberOfCol, numberofRow]);
  return (
    <Table>
      <div className="table-header">
        {numbersOfCol.map((colIndex) => (
          <div className="col-head" key={colIndex}>
            <Skeleton width={150} height={50} />
          </div>
        ))}
      </div>
      <div className="table-body">
        {numbersofRow.map((rowIndex) => (
          <div className="table-row" key={rowIndex}>
            {numbersOfCol.map((colIndex) => (
              <div className="col-body" key={`${rowIndex}-${colIndex}`}>
                <Skeleton width={150} height={50} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Table>
  );
};

export default SkeletonLoader;
