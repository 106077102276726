import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { Paper, Toolbar } from "@mui/material";

export const StyledCreateBroadcastWrap = styled.div`
  && {
    // background-color: ${theme.palette.default.lightGrey};
    width: 100%;
    height: 100%;
    // height: auto;
    overflow: overlay;
    padding: 0rem;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    z-index: 1;
    .main {
      margin:  ${(props: any) => (props.step === 2 ? `0px` : `1rem`)};
      height: calc(100%);
      border-radius: 8px;
      
      div.formBox {
        form {
          height: 100%;
        }
        height: 100%;
        background:  ${(props: any) => (props.step === 3 ? `rgb(245, 246, 248)` : ``)};
        padding:  ${(props: any) => (props.step === 3 || props.step === 2 || props.step === 4 ? `0px` : `1rem`)};
      }
    }
  }
`;

export const StyledTableToolbar = styled(Toolbar)`
  && {
    width: 100%;
    height: 64px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    padding: 0px 8px;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-start;
    overflow: hidden;

    p {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.9rem;
      color: ${theme.palette.default.text};
      line-height: 20px;
      white-space: nowrap;
    }
  }
`;
export const StyledFormOne = styled(Toolbar)`
  && {
  
    display: flex;
    width:100%;
    flex-wrap: wrap;
    justify-content:space-between;
    gap:1rem;
  }
`;
export const EmailTemplateButton = styled.div`
  display: flex;
  margin: 25px 0px 0px;
  justify-content: space-between;
  border-bottom:1px solid lightgray;
}
`
export const EmailTemplateCard = styled.div`
width: 100%;
  max-width: 270px;
  height: 100%;
  max-height: 315px;
  border: 1px solid lightgray;
  border-radius: 12px;
  margin: 20px auto;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  box-sizing: border-box;
  position: relative; // Add this for positioning the overlay and buttons

  @media (max-width: 1200px) {
    max-width: 250px;
    max-height: 290px;
  }

  @media (max-width: 992px) {
    max-width: 230px;
    max-height: 265px;
  }

  @media (max-width: 768px) {
    max-width: 210px;
    max-height: 240px;
  }

  @media (max-width: 576px) {
    max-width: 190px;
    max-height: 220px;
    font-size: 12px;
  }

  &:hover .overlay {
    display: flex; // Show overlay on hover
  }

  .overlay {
    display: none; // Hide overlay initially
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #211C52    ; // Lightgray with opacity
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    flex-direction: column; // Arrange buttons in column
  }

  .buttons {
    display: flex;
    flex-direction: column; // Arrange buttons in column
    gap: 10px; // Space between buttons
  }
`
export const EmailBroadcast1Step = styled.div`
  display:flex;
`
export const EmailBroadcastStep1Tooptip = styled.div`
  
`
export const EmailHtmlPreview = styled.div`
  & {
    height: 100vh;
    .header {
      display: flex;
      flex-direction: column;
    }
    .header-top {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
  }
`;

export const StyledEmailFormOne = styled.div`
& {

  min-width: 900px;
  max-width: 900px;
    .select-field {
      
      
    }
    input {
      background-color: red;
      // max-width: 530px;
    }

    .upload_file {
      border: 1px dashed ${theme.palette.default.darkGrey};
      max-width: 530px;
      min-width:530px;
      display: flex;
      padding: 2rem 0;
      margin-top: 0.5rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      div {
        justify-content: center;
        align-items: center;
      }
    }
}
`;
export const StyledRenderQueryFilterWrap = styled.div`
  && {
    .form-heading {
      display: flex;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      padding: 0rem 1rem 0rem 1rem;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 9;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

      h6 {
        font-size: 1.05rem;
        color: ${theme.palette.default.text};
      }

      svg {
        color: ${theme.palette.default.darkGrey};
      }
    }

    .footer {
      box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

      .footer-btns {
        justify-content: end;
        gap: 1.3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 1rem;
        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const SchenduleBoradcastWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  height: calc(100% - 64px);
`
export const ConfirmationWrapper = styled.div`
  display: flex;
  height: 100%;
  word-break: break-all;
`
export const StaticDataWrapper = styled.div`
  // padding: 1rem;
  width: 100%;
  height: calc(100% - 64px);
  display: flex;
  .confirmation_image {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
  .heading{
    font-weight: 500;
    margin-bottom: 16px
  }
  .Main_Box{
    padding: 1rem;
    width: 90%;
    background-color: ${theme.palette.default.white};
    height: 100%;
    .alt-row {
      background-color: rgba(91, 115, 232, 0.1);
    }
  }
  .Main_div{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .activities{
    font-weight: 400 ;
    font-size: 14px ;
    line-height: 21px;
    color: #101010 ;
    width: 50%;
    padding: 0.875rem;
}
  .sub_div{
    padding: 0.875rem;
    border-left: 1px solid ${theme.palette.default.grey};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #7E8392;
    width: 50%;
  }
`

export const StyledResponsiveDivTable = styled.div`
&& {
  width: 100%;
  .table {
  }
  .tableStickyHead {
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: #fff !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08); 
    width: fit-content;
    min-width: 100%;
  }

  .tablHeadRow {
    display: flex;
    flex: 1 !important;
    width: fit-content;
    background-color: #fff;
  }
  .tableHeadCell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 220px;
    max-width: 300px;
    &:first-child {
      min-width: 50px;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
  .tableHeadCell:first-child {
    width: 50px; 
  }
  .tableBodyCell {  
    min-width: 220px;
    max-width: 300px;
    word-break: break-all;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &:first-child {
      min-width: 50px;
    }
    &:last-child {
      justify-content: flex-end;
    }
  } 
  .tableBodyRow {
    display: flex;
    width: fit-content; 
    min-width: 100%;
    border-bottom: 1px solid ${theme.palette.default.border};
  }
  .activeRow{
    width: fit-content;
    background-color: ${theme.palette.action.selected};
    &.disabled {
      background-color: ${theme.palette.default.lightGrey};
      color: ${theme.palette.default.grey};
    }
  }
  .no-more-entries {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }
}
`;


export const Emailwrapper = styled.div
  `
&&{
    display:flex;

marign-left:1rem;
background-color:white;
.mainDiv{
    padding:3rem;

}
.mainDivSecond{
    width:100%;
    padding:3rem 3rem 0 3rem;

}
.text{
    opacity:0.5;
    padding:1rem 0 0 0 ;
}
.buttons{
    
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        flex-wrap: wrap;
        padding:1rem;

}
.radio {
    & .MuiFormGroup-root {
        flex-direction: column;
    }
}
.note{
    margin-top:3rem;
    background-color:#E9EDFF; 
    padding:1rem;
    width:650px
}
}
`

export const StepThreeWrapper = styled.div`
& {
  // max-width: 1151px;
  // min-width: 750px;
  // height: 100%;
  width: 100%;

  .table {
    .infinite-scroll-component__outerdiv {
      height: calc(100% - 612px);
      overflow: auto;
    }
  }

  .broadcast-actions {
    background: #fff;
    min-height: 151px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    padding: 0rem 1rem;
  }

  .marketing-list {
    display: flex;
    align-items: end;
    gap: 2rem;
    background: #fff;
    border-bottom: 1px solid ${theme.palette.default.grey}; 
    padding: 1rem;
  }

  .search-contacts {
    background: #fff;
    border-bottom: 1px solid ${theme.palette.default.grey}; 
  }

  

  div label {
    color: ${theme.palette.default.black} !important;
    font-weight: 500 !important;
  }
}
`;


export const FormFiveWrapper = styled.div`
& {
  height: 100%;
  .input-fields {
    display: flex;
    gap: 0.5rem;
  }

}
`;
