
const WhatsappPreferences = () => {
  return (
    <div>
      
    </div>
  )
}

export default WhatsappPreferences
