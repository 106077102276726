import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

interface AppSkeletonTableLoaderProps {
    numberOfCol?: number;
    numberofRow?: number;
    skeletonHeight?: any;
    skeletonWidth?: any;
    skeletonHeaderHeight?: any;
    skeletonHeaderWidth?: any;
    tableHeight?: any;
}

const AppSkeletonTableLoader: React.FC<AppSkeletonTableLoaderProps> = ({
    numberOfCol = 4,
    numberofRow = 4,
    skeletonHeight = 50,
    skeletonWidth = 150,
    skeletonHeaderHeight = 50,
    skeletonHeaderWidth = 150,
    tableHeight = "100%",
}) => {
    const [numbersOfCol, setNumbersOfCol] = useState<number[]>([]);
    const [numbersofRow, setNumbersofRow] = useState<number[]>([]);

    useEffect(() => {
        if (numberofRow) {
            setNumbersOfCol(Array.from(Array(numberOfCol).keys()));
            setNumbersofRow(Array.from(Array(numberofRow).keys()));
        }
    }, [numberOfCol, numberofRow]);
    return (
        <SkeletonTable>
            <div className="table-header">
                {numbersOfCol.map((colIndex) => (
                    <div className="col-head" key={colIndex}>
                        <Skeleton width={skeletonHeaderWidth} height={skeletonHeaderHeight} />
                    </div>
                ))}
            </div>
            <div className="table-body" style={{ 
                height: tableHeight,
                display: "flex",
                flexDirection: "column",
            }}>
                {numbersofRow.map((rowIndex) => (
                    <div className="table-row" key={rowIndex}>
                        {numbersOfCol.map((colIndex) => (
                            <div className="col-body" key={`${rowIndex}-${colIndex}`} style={{
                                // border: "1px solid red"
                            }}>
                                <Skeleton width={skeletonWidth} height={skeletonHeight} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </SkeletonTable>
    );
};

export default AppSkeletonTableLoader;



const SkeletonTable = styled.div`
width: 100%;
    overflow: hidden;
    height: 100%;
    border: 1px solid ${theme.palette.default.BorderColorGrey};
  .table-body {
    // overflow: auto;
    background-color: ${theme.palette.default.white};
  }
  .table-header {
    display: flex;
    align-items: baseline;
    font-weight: bold;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    padding-bottom: 4px;
    padding-top: 4px;
    min-width: fit-content;
    flex: 1;
    position: sticky;
    top: 0;
    background-color: ${theme.palette.default.white};
    // z-index: 1;
    // overflow:auto;
  }

  .table-row {
    display: flex;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    min-width: fit-content;
    flex: 1;
  }
  .query1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .query2 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .query3 {
    color: ${theme.palette.default.AccentOrange};
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding: 3px;
    border: 1px solid ${theme.palette.default.AccentOrange};
    width: 200px;
    text-align: center;
    border-radius: 4px;
  }

  .col-head,
  .col-body {
    min-width: 200px;
    padding: 16px 16px;
    color: ${theme.palette.default.newTextGrey};
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    :nth-child(1) {
      /* Apply styles for the head first child */
    //   min-width: 426px;
    }

    :nth-child(2) {
    //   min-width: 315px;
    }
    // :nth-child(n + 3) {
    //   flex: 1; /* columns from the third child to grow equally */
    // }
    :nth-child(7) {
      display: flex;
      align-items: baseline;
      justify-content: end;
    }
  }

  .col-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    padding: 1rem;
    border-right: 1px solid ${theme.palette.default.RightBorderDivder};
    flex: 1;

    :nth-child(1) {
      /* Apply styles for the body first child */
    //   min-width: 426px;
    }

    :nth-child(2) {
    //   min-width: 315px;
    }
    :nth-child(6) {
      border-right: none;
    }
    :nth-child(7) {
      border-right: none;
    }
    :nth-child(n + 3) {
      flex: 1; /* Allow columns from the third child to grow equally */
    }
  }
`;
