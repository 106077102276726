import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
import { WhatsappNetworkService } from "../../../../../../Store/Slices/Whatsapp/WhatsappNetworkService";
import { WhatsappActions } from "../../../../../../Store/Slices/Whatsapp/whatsapp.actions";
import { useLiveChatWhatsappTemplateData } from "../../../../../../Store/Slices/socket/liveChatWhatsappTemplateSelect/LiveChatWhatsappTemplateSelect.selectors";
import {
  setSelectedTemplateData,
  setWhatsappTemplateData,
} from "../../../../../../Store/Slices/socket/liveChatWhatsappTemplateSelect/LiveChatWhatsappTemplateSelect.slice";
import { StyledAppInput } from "../../../../../Styles/StyledForms";
import { StyledModelList } from "./Styles";
import { dataToTemplateFormData } from "../../../../../../Components/Broadcast/WhatsApp/Utils/utils";
interface Props {
  isShowing: boolean;
  toggle: (val?: boolean | undefined) => void;
}

const ModelSidebar: React.FC<Props> = ({ isShowing, toggle }) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const dipatch = useAppDispatch();
  const { whatsappTemplateData, selectedTemplateData } = useAppSelector(
    useLiveChatWhatsappTemplateData
  );
  const [whatsappTemplateList, setWhatsappTemplateList] = useState<any[]>([])
  useEffect(() => {
    setWhatsappTemplateList(whatsappTemplateData);
  }, [whatsappTemplateData]);

  const handleListItemClick = async (item: any) => {
    const res = await dispatch(WhatsappActions.getTemplateById(item?.id));
    dispatch(setSelectedTemplateData([]));
    const data = {
      ...await dataToTemplateFormData(res?.payload?.original_response),
      components: res?.payload?.original_response?.components
    };
    dispatch(setSelectedTemplateData(data));
  };

  const getPreviousData = () => {
    WhatsappNetworkService.getWhatsappTemplatesListings({ limit: 9999, offset: 0 })
      .then((res: any) => {
        dipatch(setWhatsappTemplateData(res?.data?.results));
      })
      .catch((error: any) => {
        // setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
  };
  const search = (val?: any) => {
    if (val) {
      let list = whatsappTemplateData.filter(
        (item: any) =>
          item?.name.toLowerCase()?.includes(val.toLowerCase())
      );
      setWhatsappTemplateList(list)
    } else {
      setWhatsappTemplateList(whatsappTemplateData)
    }
  }
  React.useEffect(() => {
    if (isShowing) {
      getPreviousData();
    }
  }, [isShowing]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="modelBodyLeft">
      <div className="modelBodyLeftTop">
        <StyledAppInput
          type="text"
          onChange={(e: any) => search(e?.target?.value)}
          name="search"
          id="search"
          placeholder="Search"
          style={{
            height: "50px",
          }}
        />
      </div>
      <div className="modelBodyLeftBottom">
        <StyledModelList>
          {whatsappTemplateList?.map((item: any, index: number) => {
            return (
              <ListItem
                disablePadding
                key={index}
                onClick={() => handleListItemClick(item)}
              >
                <ListItemButton
                  selected={selectedTemplateData?.id === item?.id}
                >
                  <ListItemText primary={item?.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </StyledModelList>
      </div>
    </div>
  );
};

export default ModelSidebar;
