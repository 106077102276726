// vendors
import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

// components
import Icon from "../../../icon";
import UIDialog from "./../../../dialog";
import DeleteButton from "./../handlers/delete-button";
import UIButton from "../../../button";
import NodeType from "../../../node-type";
import Input from "../../../input";

// styles
import {
  MediaPlaceholderStyled,
  DocumentUploadedSectionStyled,
} from "./common.styles";

const Document = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const [url, setURL] = useState(props.url);
  const [showUploadArea, setShowUploadArea] = useState(
    props?.url ? false : true
  );
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const toggleDialog = () => {
    setShowModal(!showModal);
  };

  const onChange = (event: any) => {
    setURL(event.target.value);
  };

  const onSave = () => {
    setShowUploadArea(false);
    toggleDialog();
    props.onSave && props.onSave(url);
  };

  const handleDeleteClick = () => {
    setShowDeleteConfirmModal(true);
  };

  const handleConfirmDelete = () => {
    setURL("");
    setShowUploadArea(true);
    setShowDeleteConfirmModal(false);
    props.onDelete && props.onDelete();
  };

  return showUploadArea ? (
    <>
      <MediaPlaceholderStyled>
        <Icon icon="document" size={24} color="#5B73E8" />
        <Typography sx={{ my: "10px" }}>Drag and drop file or</Typography>
        <UIButton
          variant="outlined"
          startIcon={<Icon icon="document" size={16} />}
          onClick={toggleDialog}
        >
          File
        </UIButton>
      </MediaPlaceholderStyled>
      <UIDialog
        className="ignore-onClickOutside"
        open={showModal}
        onClose={toggleDialog}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="File URL" icon="document" color="#FFB084" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={toggleDialog}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px", width: "420px" }}>
          <Typography
            component="label"
            sx={{
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
              color: "#7E8392",
            }}
          >
            URL*
            <Input
              placeholder="link"
              id="document-link"
              size="small"
              name="url"
              onChange={onChange}
              onBlur={onChange}
              value={url}
              fullWidth
            />
          </Typography>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <UIButton color="secondary" variant="outlined" onClick={toggleDialog}>
            cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" size={16} />}
            onClick={onSave}
          >
            Save
          </UIButton>
        </UIDialog.Footer>
      </UIDialog>
    </>
  ) : (
    <DocumentUploadedSectionStyled>
      <Box sx={{ position: "absolute", top: "16px", right: "16px" }}>
        <DeleteButton onClick={handleDeleteClick} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Stack
          direction="row"
          spacing="16px"
          sx={{ px: "20px", py: "16px", width: "90%" }}
        >
          <Box>
            <Icon icon="document" size={24} color="#5B73E8" />
          </Box>
          <Typography
            noWrap
            sx={{
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 400,
              wordBreak: "break-all",
              width: "100%",
            }}
          >
            {url}
          </Typography>
        </Stack>
      </Box>
      <UIDialog
        className="ignore-onClickOutside"
        open={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        sx={{ minWidth: "420px" }}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="Remove URL" icon="document" color="#FFB084" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={() => setShowDeleteConfirmModal(false)}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px", minWidth: "420px" }}>
          <Typography
            component="label"
            sx={{
              color: "#7E8392",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: 400,
            }}
          >
            Are you sure you want to remove this document?
          </Typography>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <UIButton
            variant="outlined"
            onClick={() => setShowDeleteConfirmModal(false)}
          >
            No
          </UIButton>
          <UIButton
            variant="contained"
            color="error"
            startIcon={<Icon icon="delete" size={16} />}
            onClick={handleConfirmDelete}
          >
            Yes
          </UIButton>
        </UIDialog.Footer>
      </UIDialog>
    </DocumentUploadedSectionStyled>
  );
};
export default Document;
