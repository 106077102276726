import * as Icons from "@mui/icons-material";
import * as React from "react";

type IconType = typeof Icons;

interface DynamicIconProps {
  iconName: string;
  style?: React.CSSProperties | undefined;
}

function upperFirst(string: string) {
  return string.slice(0, 1).toUpperCase() + string.slice(1, string.length);
}

function fixIconNames(string: string) {
  const name = string.split("-").map(upperFirst).join("");
  if (name === "3dRotation") {
    return "ThreeDRotation";
  } else if (name === "4k") {
    return "FourK";
  } else if (name === "360") {
    return "ThreeSixty";
  }
  return name;
}

const AppMaterialIcons: React.FC<DynamicIconProps> = ({
  iconName = "ArrowBackIosRounded",
  style,
}) => {
  const iconKey = fixIconNames(iconName) as keyof IconType | undefined;
  const Icon = iconKey ? Icons[iconKey] : null;
  return Icon ? React.createElement(Icon, { style }) : null;
};

export default AppMaterialIcons;
