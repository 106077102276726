import axios from "axios";
import { AppConfig } from "../Config/app-config";
import { convertToLocaleDates } from "../Utils";
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const PAYMENT_REQUIRED = 402;

// const headers = {
//   "Content-type": "application/json",
//   accept: "application/json",
//   // 'Access-Control-Allow-Origin' : 'http://localhost:3000/',
//   // 'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
// };

axios.defaults.headers.common.accept = "application/json";
// axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
// axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const addLoginHeaders = (config: any) => {
  const data = AppConfig.getLoggedInData();
  if (data) {
    const token = data?.token;
    const domain = data?.data?.geta_host;

    if (token && token.length > 0) {
      config.headers["authorization"] = token;
    }
    if (domain && domain.length > 0) {
      config.headers["geta-host"] = domain;
    }
  }
  if (config?.data?.headers?.appid) {
    config.headers["appid"] = config.data.headers.appid;
    delete config.data.headers;
  }
  if (config?.params?.headers?.appid) {
    config.headers["appid"] = config.params.headers.appid;
    delete config.params.headers;
  }
  return config;
};

axios.interceptors.request.use(
  function (config: any) {
    config = addLoginHeaders(config);
    delete config.headers.common.Accept;
    if (config?.data?.headers) {
      delete config?.data?.headers;
      config.data = config.data.data;
    }

    delete config?.data?.params;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// axios.interceptors.request.use((config: any) => {
//   function (config: any) {
//     config = addLoginHeaders(config);
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }

//   if (localStorage.getItem("token")) {
//     if (config?.headers) config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
//   }

//   if (config?.data?.params?.use_form_data || config?.params?.use_form_data) {
//     if (config?.headers) config.headers["Content-Type"] = "application/x-www-form-urlencoded";
//   }

//   delete config?.params;
//   delete config?.data?.params;

//   return config;
// });

axios.interceptors.response.use(
  (response: any) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // response.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000/';
    // response.headers['Access-Control-Allow-Credentials'] = true
    return convertToLocaleDates(response);
  },
  function (error: any) {
    let status = error?.response?.status;
    if (status === FORBIDDEN) {
      window.location.href = "/forbidden";
    }
    if (
      error?.response?.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user"); // login time
      // if (window.location.pathname !== "/login" && window.location.pathname !== "/404") {
      //   window.localStorage.setItem("prevPath", window.location.href);
      // }
      // window.location.pathname = "/404";
    }
    //something with error
    return Promise.reject(error);
  }
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
  // headers,
};
