// vendors
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { PopoverProps } from "@mui/material/Popover";

export const EditConfigurationButton = styled(IconButton)(() => ({
  height: "24px",
  width: "24px",
  padding: "0px !important",
  borderRadius: "4px",
  border: "1px solid #CBCDD3",
})) as typeof IconButton;

export const SectionStyled = styled(Box)(() => ({
  backgroundColor: "#FFFFFF",
  marginTop: "8px",
  padding: "16px",
})) as typeof Box;

export const PaperStyled = {
  width: "403px",
  backgroundColor: "#F5F6F8",
  marginLeft: "23px",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1);",
  padding: "14px 12px",
  "@media(max-width: 1599.98px)": {
    marginLeft: "0px",
    marginRight: "23px",
  },
};

export const PopoverConfig = (open: boolean): PopoverProps => ({
  open,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center" as "center",
    horizontal: "left",
  },
  sx: { ml: "24px", borderRadius: "8px" },
  PaperProps: {
    sx: PaperStyled,
  },
});
