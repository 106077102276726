// vendors
import { useState } from "react";
import Typography from "@mui/material/Typography";

// components
import { Editor } from "../../../../../Components/FlowBuilder/ui-components";

// utils
import {
  getHTMLContent,
  getInitialEditiorText,
  getPlainTextData,
} from "../../../Utils/editor";

type InteractiveMessageBodyProps = {
  maxTextLimit: number;
  text: string;
  onChange: (text: string) => void;
  error:any;
};

const InteractiveMessageBody = ({
  text,
  maxTextLimit = 0,
  onChange,
  error
}: InteractiveMessageBodyProps) => {
  const [editorState, setEditorState] = useState<any>(
    getInitialEditiorText(text || ""),
  );

  const onEditorStateChange = (editorStateValue: any) => {
    // @ts-ignore
    const charLength = getPlainTextData(editorStateValue)?.length || 0;
    if (charLength > maxTextLimit) {
      return;
    }

    setEditorState(editorStateValue);
    let htmlText = getHTMLContent(editorStateValue);
    onChange(htmlText.replace(/\*(.*?)\*/g, "<strong>$1</strong>"));
  };

  const handleBlur = () => {
    let htmlText = getHTMLContent(editorState);
    onChange(htmlText.replace(/\*(.*?)\*/g, "<strong>$1</strong>"));
  };
  return (
    <>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        hideFocusOnLoad={true}
        whatsappEditorOptions={true}
        onBlur={handleBlur}
        error={error}
      />

      
      <Typography style={{color:error ? "red":""}}align="right"  variant="body2">
        {getPlainTextData(editorState)?.length}/{maxTextLimit}
      </Typography>
    </>
  );
};
export default InteractiveMessageBody;
