// actions
import CallNumber from "./actions/call-number";
import ConnectFlow from "./actions/connect-flow";
import ExternalApis from "./actions/external-apis";
import Functions from "./actions/functions";
import OpenUrl from "./actions/open-url";
import Properties from "./actions/properties";
import SendEmail from "./actions/send-email";
import LiveAgent from "./actions/live-agent";
import TransferToFAQs from "./actions/transfer-to-faqs";

export const registerActions = [
  {
    icon: "nodes",
    type: "flow",
    color: "#B8C4FF",
    title: "Connect flow",
    displayComponent: ConnectFlow,
  },
  {
    icon: "phone",
    type: "mobile",
    color: "#93E9C8",
    title: "Call Number",
    displayComponent: CallNumber,
  },
  {
    icon: "email",
    type: "email",
    color: "#FFB9C5",
    title: "Send Email",
    displayComponent: SendEmail,
  },
  {
    icon: "url",
    type: "link",
    color: "#89C6FF",
    title: "Open URL",
    displayComponent: OpenUrl,
  },
  // {
  //   icon: "api",
  //   type: "api",
  //   color: "#B4EDB3",
  //   title: "External API",
  //   displayComponent: ExternalApis,
  // },
  // {
  //   icon: "function",
  //   type: "function",
  //   color: "#9DF8D7",
  //   title: "Function",
  //   displayComponent: Functions,
  // },
  // {
  //   icon: "properties",
  //   type: "properties",
  //   color: "#EDEF8A",
  //   title: "Properties",
  //   displayComponent: Properties,
  // },
  // {
  //   icon: "live-agent",
  //   type: "live_agent",
  //   color: "#FFD1AB",
  //   title: "Live Agent",
  //   displayComponent: LiveAgent,
  // },
  // {
  //   icon: "faq-icon",
  //   type: "transfer_to_faqs",
  //   color: "#C8EF87",
  //   title: "Transfer to FAQs",
  //   displayComponent: TransferToFAQs,
  // },
];
