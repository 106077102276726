import React from "react";
import { QueryBuilder } from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";
import { useAppSelector } from "../../Store";
import { useQueryBuilderStore } from "../../Store/Slices/QueryBuilder/QueryBuilder.selectors";
import { StyledQueryBuilderWrap } from "./Styled";
import { CustomQueryCombinatorSelect } from "./customComponents/CustomQueryCombinatorSelect";
import { CustomQueryDeleteRuleButton } from "./customComponents/CustomQueryDeleteRuleButton";
import { CustomQueryFieldSelect } from "./customComponents/CustomQueryFieldSelect";
import { CustomQueryOperatorSelect } from "./customComponents/CustomQueryOperatorSelect";
import { CustomQueryRuleButton } from "./customComponents/CustomQueryRuleButton";
import { CustomQueryValueField } from "./customComponents/CustomQueryValueField";
import { QueryBuilderCombinators } from "./utils/combinators";
import { QueryBuilderTranslations } from "./utils/translations";
import {
  getQueryBuilderModifiedOperatorsType,
  getQueryBuilderModifiedValueEditorType,
  initialQuery,
} from "./utils/utils";

interface Props {
  fields: any[];
  operators: any[];
  defaultInputValueFromApi?: any;
  onChangeCallbackFn?: (value: any) => void;
  [otherProps: string]: any;
}
const AppQueryBuilder: React.FC<Props> = ({
  operators,
  fields,
  onChangeCallbackFn,
  defaultInputValueFromApi,
}) => {
  const { queryBuilderDynamicFieldsOptions } =
    useAppSelector(useQueryBuilderStore);
  const [query, setQuery] = React.useState(initialQuery);

  React.useEffect(() => {
    if (defaultInputValueFromApi?.rules?.length !== 0) {
      setQuery(defaultInputValueFromApi);
      if (onChangeCallbackFn) {
        onChangeCallbackFn(defaultInputValueFromApi);
      }
    }
  }, [defaultInputValueFromApi]); // eslint-disable-line react-hooks/exhaustive-deps

  const getQueryOperators = (val: string) => {
    if (!val) return [];

    const field = fields?.find((fld: any) => fld?.name === val);

    if (!field) return [];

    let modifiedType: any =
      getQueryBuilderModifiedOperatorsType(field, val) ?? "text";

    const dynamicOperators = operators?.[modifiedType] ?? [];

    return dynamicOperators as any;
  };

  const getValueOptions = (val: string, op: string) => {
    if (!val) return null as any;
    if (queryBuilderDynamicFieldsOptions?.length === 0) return null as any;

    const field = fields?.find((fld: any) => fld?.name === val);

    const fieldWithOptions = queryBuilderDynamicFieldsOptions?.find(
      (fld: any) => {
        return fld?.identi_name === val && field?.id === fld?.id
      }
    );


    if (!fieldWithOptions) return null as any;

    if (fieldWithOptions?.["identi_name"] === val) {
      let optionsList: any = fieldWithOptions?.options ?? [];
      return optionsList as any;
    }

    return null as any; // Return undefined for other fields to use the default values
  };

  const getValueEditorType = (val: string, op: string) => {
    const field = fields?.find((fld: any) => fld?.name === val);

    let modifiedType: string = getQueryBuilderModifiedValueEditorType(
      field,
      val,
      op
    );
    return modifiedType as any;
  };

  const handleQueryChange = (q: any) => {
    setQuery(q);
    if (q && onChangeCallbackFn) {
      onChangeCallbackFn(q);
    }
  };

  if (fields?.length === 0) {
    return <p>no custom field</p>;
  }

  return (
    <StyledQueryBuilderWrap className="customInput">
      <QueryBuilder
        fields={fields}
        combinators={QueryBuilderCombinators}
        translations={QueryBuilderTranslations}
        query={query}
        onQueryChange={(q: any) => {
          handleQueryChange(q);
        }}
        //custom functions
        getOperators={getQueryOperators}
        getValues={getValueOptions as any}
        getValueEditorType={getValueEditorType}
        getValueEditorSeparator={(field: any, op: any) => {
          return <span />;
        }}
        //custom component
        controlElements={{
          addGroupAction: () => null,
          removeRuleAction: CustomQueryDeleteRuleButton,
          addRuleAction: CustomQueryRuleButton,
          combinatorSelector: CustomQueryCombinatorSelect,
          fieldSelector: CustomQueryFieldSelect,
          operatorSelector: CustomQueryOperatorSelect,
          valueEditor: CustomQueryValueField,
        }}
        //custom classes
        controlClassnames={{
          queryBuilder: "queryBuilder",
          ruleGroup: "ruleGroup",
          header: "header",
          body: "body",
          combinators: "combinators",
          addRule: "addRule",
          cloneRule: "cloneRule",
          cloneGroup: "cloneGroup",
          removeGroup: "removeGroup",
          lockRule: "lockRule",
          lockGroup: "lockGroup",
          notToggle: "notToggle",
          rule: "rule",
          fields: "customFields",
          operators: "operators",
          value: "value",
          removeRule: "removeRule",
          dragHandle: "dragHandle",
          valueSource: "valueSource",
        }}
        //default props
        resetOnFieldChange={true}
        showLockButtons={false}
        showCloneButtons={false}
        resetOnOperatorChange={true}
        listsAsArrays={true}
        parseNumbers={true}
        showNotToggle={false}
        showCombinatorsBetweenRules={false}
        onAddGroup={() => false}
        //do not change this otherwise it will break
        onAddRule={(
          rule: any,
          parentPath: number[],
          query: any,
          context?: any
        ) => {
          return {
            ...rule,
            field: "",
            operator: "",
            value: "",
          };
        }}
      />
    </StyledQueryBuilderWrap>
  );
};

export default AppQueryBuilder;
