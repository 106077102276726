import { Grid, IconButton, Typography } from "@mui/material";
import { FieldArray, getIn, useFormikContext } from "formik";
import React, { memo } from "react";
import { AppFormField } from "../../../../AppComponents/Forms";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import deleteIcon from "../../../../assets/images/delete-icon.svg";
import AppButton from "../../../../AppComponents/AppButton";
import { hasLostStatus, hasWonStatus } from "./utils";
import { useSelector } from "react-redux";
import { LifecycleStage } from "../../../../Store/Slices/Settings/LifecycleStage/lifecycleStage.selectors";
import { SortableList } from "../../../UiComponents/DndKitDraggable/SortableList/SortableList";
// import { SortableList } from "../../../UiComponents/DndKitDraggable/SortableList/SortableList";
export enum CreateEditModes {
  CREATE = "create",
  EDIT = "edit",
}

interface Props {
  name: string;
  onDrop: (items: any[], prevIndex: number, nextIndex: number) => void;
  removeStatus: (arrayHelpers: any, remove: any, index: any) => void;
  handleAddNewStatus: (
    values: any,
    push: any,
    insert: any,
    index: any,
    type?: string
  ) => void;
  mode: string;
}

const LifecycleStatusArrayForm: React.FC<Props> = ({
  name,
  onDrop,
  removeStatus,
  handleAddNewStatus,
  mode,
}) => {
  const { values, errors, touched } = useFormikContext<any>();
  const currentLifecycleStage = useSelector(LifecycleStage);

  const onDragStart = (event: any, index: any) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid item>
        <Typography className="add-stage" variant="body2">
          Sub Stages
        </Typography>
      </Grid>
      <FieldArray name={name}>
        {({ insert, remove, push, swap, unshift, pop, replace }) => (
          <>
            <SortableList
              items={getIn(values, name)}
              onChange={onDrop}
              orientation="vertical"
              renderItem={(item, index) => {
                const fieldName: string = `${name}.${index}.label`;
                return (
                  <SortableList.Item id={item.id}>
                    <div className="drag-list">
                      <AppFormField
                        name={fieldName}
                        placeholder="Status name"
                        className="field"
                      />
                    </div>
                    <IconButton
                      onClick={() => removeStatus(values, remove, index)}
                      component="span"
                    >
                      <img src={deleteIcon} />
                    </IconButton>
                    {!(
                      item.type === "closed_lost" || item.type === "closed_won"
                    ) ? (
                      <SortableList.DragHandle />
                    ) : (
                      <></>
                    )}
                  </SortableList.Item>
                );
              }}
            />
            <div className="sub-button">
              <AppButton
                variant="outline"
                onClick={() =>
                  handleAddNewStatus(
                    values,
                    push,
                    insert,
                    getIn(values, name)?.length,
                    "open"
                  )
                }
              >
                <AppMaterialIcons iconName="AddOutlined" /> Add Status
              </AppButton>
              {!hasLostStatus(getIn(values, name)) && (
                <>
                  <div className="button">
                    <AppButton
                      variant="outline"
                      onClick={() =>
                        handleAddNewStatus(
                          values,
                          push,
                          insert,
                          getIn(values, name)?.length,
                          "closed_lost"
                        )
                      }
                    >
                      <AppMaterialIcons iconName="AddOutlined" /> Add Lost
                      Status
                    </AppButton>
                  </div>
                </>
              )}
            </div>

            {mode === CreateEditModes.EDIT &&
              currentLifecycleStage?.default &&
              !currentLifecycleStage?.custom &&
              !hasWonStatus(getIn(values, name)) && (
                <>
                  <div className="button">
                    <AppButton
                      variant="outline"
                      onClick={() =>
                        handleAddNewStatus(
                          values,
                          push,
                          insert,
                          getIn(values, name)?.length,
                          "closed_won"
                        )
                      }
                    >
                      <AppMaterialIcons iconName="AddOutlined" /> Add Won Status
                    </AppButton>
                  </div>
                </>
              )}
          </>
        )}
      </FieldArray>
    </>
  );
};

export default memo(LifecycleStatusArrayForm);
