import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  StyledDialog,
  StyledDialogTitle,
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import { UserBusiness } from "../../Models";

interface BusinessSelectionDialogProps {
  showDialog: boolean;
  userBusinesses: UserBusiness[];
  selectedBusinessId?: string | number;
  onBusinessSelect: (ub: UserBusiness) => void;
}

const BusinessSelectionDialog = ({
  showDialog,
  userBusinesses,
  selectedBusinessId,
  onBusinessSelect,
}: BusinessSelectionDialogProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedUserBusiness, setSelectedUserBusiness] =
    useState<UserBusiness>();
  const handleDialogClose = () => {
    setShow(!show);
  };
  useEffect(() => {
    setShow(showDialog);
  }, [showDialog]);

  useEffect(() => {
    if (selectedBusinessId) {
      if (userBusinesses) {
        const selB = userBusinesses.find(
          (ub) => ub.business.id == selectedBusinessId
        );
        setSelectedUserBusiness(selB);
      }
    }
  }, [selectedBusinessId]);

  const onSelect = (ub: UserBusiness) => {
    if (onBusinessSelect) {
      if (!isSelectedItem(ub)) {
        onBusinessSelect(ub);
      }
    }
  };
  const isSelectedItem = (ub: UserBusiness) => {
    return (
      selectedUserBusiness && selectedUserBusiness.business.id == ub.business.id
    );
  };
  return (
    <StyledDialog
      maxWidth={"xs"}
      fullWidth={true}
      open={show}
      onClose={() => handleDialogClose()}
    >
      <StyledDialogTitle>
        <span>Select Account</span>
        <span>
          <IconButton
            color="default"
            aria-label="minimize chat"
            component="span"
            onClick={() => {
              handleDialogClose();
            }}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </StyledDialogTitle>
      <small>Please select an account to login into</small>

      <DialogContent>
        <BusinessSelectionWrap>
          <List sx={{ pt: 0 }}>
            {userBusinesses.map((ub: UserBusiness) => {
              return (
                <StyledListItem
                  disabled={isSelectedItem(ub)}
                  className={isSelectedItem(ub) ? "selected" : ""}
                  style={{ cursor: "pointer" }}
                  onClick={() => onSelect(ub)}
                  key={ub.id}
                >
                  <ListItemAvatar style={{ cursor: "pointer" }}>
                    <Avatar
                      style={{ cursor: "pointer" }}
                      sx={{
                        backgroundColor: theme.palette?.primary?.main,
                        color: theme?.palette?.default?.white,
                      }}
                    >
                      <PersonIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    style={{ cursor: "pointer" }}
                    sx={{
                      color: isSelectedItem(ub)
                        ? theme.palette?.default?.white
                        : theme.palette?.default?.black,
                    }}
                    primary={`${ub.business?.name} - (${ub.role?.name})`}
                  />
                </StyledListItem>

                // <BusinessDiv>
                //   <div>
                //     <span>{ub.business.name} - {ub.role.name}</span>
                //   </div>
                //   <AppButton variant="outline" onClick={() =>  onBusinessSelect(ub)}>Select</AppButton>
                // </BusinessDiv>
              );
            })}
          </List>
        </BusinessSelectionWrap>
      </DialogContent>
    </StyledDialog>
  );
};

export default BusinessSelectionDialog;

const BusinessSelectionWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;

    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
  }
`;
const StyledListItem = styled(ListItem)`
  &.selected {
    background: ${theme.palette?.primary?.main};
  }
`;
const BusinessDiv = styled.div`
  && {
    width: 100%;
    display: flex;

    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
    padding: 1rem;
    div {
      width: 70%;
    }
    .select-business {
      width: 30%;
    }
  }
`;
