import { Card, withTheme } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const StyledLifeCycleNewWrapper = styled.div`
    && {
    background: ${theme.palette.default.white};
    width: calc(100% - 30px);
		height: calc(100vh - 150px);
		overflow-y: auto;
    margin: 15px;
    border-radius: 4px;
    .sortableItem {
			max-width: calc(25% - 12px);
			min-width: 230px;
			width: 100%;
			display: flex;
			.MuiTypography-body1 {
				font-size: 13px;
			}
		}
		ul {
			overflow-x: auto;
			align-items: stretch;
			.stylelife {
				width: 100%;
			}
		}
		.box-stage{
				display: flex;
				justify-content: space-between;
				align-items: baseline;
				padding: 16px;
				border-bottom: 1px solid #F5F6F8;
		}
		}
        .lifecycle-form{
            display: flex;
            margin-left: 1rem;
             height: 70%;
            min-width: 100%;
            border-radius: 8px;
        }
       
        .button-wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .lifecycle-stages p{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            color: #323232;
            padding-left:16px;
        }
        .main-box{
            border: 1px solid #D8DEFA;
            height: 100%;
            width: 100%;
            text-overflow: ellipsis; 
            overflow: visible;
        }
       .myBox span{
        display: 'flex';
        gap: '16px';
         padding: '16px';
       }
       }
       .drag svg{
        fill: currentColor;
    width: 1em;
    height: 1em;
    display: block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
       }
       .button-type{
        width: 118px;
    margin-right: 16px;
    height: 37px;
       }
`;

export const StyledLifeCycle = styled(Card)`
  && {
    .header {
      background: ${theme.palette.default.moreLighterGrey};
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #eef1ff;
      border-bottom: 1px solid #f5f6f8;
      text-overflow: ellipsis;
      height: 45px;
    }
    .sortableItem {
      display: flex;
    }
    .left {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .left svg {
      color: #7e8392;
      cursor: pointer;
    }
    .right {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
    .right svg {
      color: #7e8392;
      width: 17.97px;
      height: 17.97px;
    }

    .divider {
      border-color: red;
    }

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      text-transform: capitalize;
      color: #323232;
    }
    .main-card {
      height: 280px;
      width: 280px;
    }
    .list-item {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: ${theme.palette.default.black};
      padding: 4px 16px;
    }

    .list-item hr {
      background: #ffffff;
      border-bottom: 1px solid #f5f6f8;
    }
    .body-list {
      display: flex;
      flex-direction: column;
      background: #ffffff;
    }
  }
`;
export const StyledContentBox = styled.div`{
&& {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		max-width: 100%;

		.formBox {
			z-index:0;
			background-color: ${theme.palette.default.white};
			display: flex;
			flex-direction: column;
			align-items: left;
			justify-content: flex-start;
			border-radius: 8px;
			gap: 1rem;
			overflow-x: hidden;
			ul {
				padding: 0;
			}
		}
	 .sub-button {
		display: flex;
		align-items: center;
		gap: 10px;
	 }
   .form{
    display: flex;
    justify-content: space-between;
    padding-bottom:16px;
    border-bottom:1px solid #f3f3f5;

}
.form button{
    height: 24px;
    width: 24px;
    background: none;
    border: none;
    color: #7E8392;
}
   }
   .form h4{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #101010;
   }
 
   

.add-stage{
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 21px;
text-transform: capitalize;
color: #101010;
}


.drag{
    color: #7E8392;
    cursor: pointer;
}
.drag-list input{
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
text-transform: capitalize;
color: ${theme.palette.default.black};
}

.drag-list{
    width: 100%;
}
}
.drag-list .field::placeholder {
    color:#dedfe3;
}
.form-app label{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #101010; 
}

.form-app input{
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
text-transform: capitalize;
color: ${theme.palette.default.black};
}

.form-app input::placeholder {
   color:#dedfe3;
}
 }`;
