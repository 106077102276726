import styled from "styled-components";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { theme } from "../../Customization/Theme";
import AppIcon from "../AppIcon";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  divStyle?: any;
  defaultBorder?: boolean;
  onChange?: (value: any) => void;
  clearCallback?: (value: any) => void;
  children?: React.ReactNode;
  [otherProps: string]: any;
  height?:any
}

interface StyledProps {
  isError?: any;
}

const AppFormSearchField: React.FC<Props> = ({
  label,
  name,
  children,
  divStyle,
  onChange,
  clearCallback,
  defaultBorder = false,
  height="",
  ...otherProps
}) => {
  const {
    setFieldTouched,
    handleChange,
    errors,
    touched,
    getFieldMeta,
    initialValues,
    setFieldValue,
    values,
    handleSubmit,
    submitForm,
  } = useFormikContext<any>();

  const [isClearBtnVisible, setIsClearBtnVisible] = useState<boolean>(false);

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitForm();
    }
  };

  useEffect(() => {
    if (getIn(values, name) && getIn(values, name)?.length > 0)
      setIsClearBtnVisible(true);
    else {
      setIsClearBtnVisible(false);
    }
  }, [values[name]]);

  return (
    <Div style={divStyle}>
      {label ? <Label>{label}</Label> : null}
      <Input
        {...otherProps}
        value={values[name] || ""}
        // onKeyDown={(e: any) => handleKeyDown(e)}
        onChange={(e: any) => {
          setFieldValue(name, e.target.value);

          if (onChange) {
            onChange(e.target.value);
            handleSubmit();
          }
        }}
        onBlur={() => setFieldTouched(name)}
        style={{
          height:height ? height : "28px",
          border:
            errors[name] && touched[name]
              ? `1px solid ${theme.palette.default.error}`
              : `1px solid ${
                  defaultBorder ? theme.palette.default.border : "transparent"
                }`,
        }}
        className="search-input"
      />
      {isClearBtnVisible ? (
        <AppIcon
          title={"ri-close-line"}
          onClick={() => {
            {
              setFieldValue(name, "");
              submitForm();
              if (clearCallback) {
                clearCallback(name);
              }
            }
          }}
          size="24px"
          color={theme.palette.default.darkGrey}
          hoverColor={theme.palette.default.darkGrey}
        />
      ) : (
        <AppIcon
          title={"ri-search-2-line"}
          onClick={() => {
            submitForm();
          }}
          size="18px"
          color={theme.palette.default.darkGrey}
          hoverColor={theme.palette.default.darkGrey}
        />
      )}

      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </Div>
  );
};

export default AppFormSearchField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.7rem;
    i {
      /* background-color: red; */
      height: 28px;
      width: 35px;
      bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0%;
      /* top: 2%; */
      z-index: 2;
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
  }
`;
const Input = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 28px;
    border-radius: 4px;
    background-color: #f9f9f9;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    border: 1px solid ${theme.palette.default.border};
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 1px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 1px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    /* outline: 1px solid ${theme.palette.default.border} !important; */
  }
`;
