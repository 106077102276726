import { createAsyncThunk } from "@reduxjs/toolkit";
import { EmailNetworkService } from "./EmailNetworkService";
import { templateDataToUserFormData } from "../../../Components/Broadcast/WhatsApp/Utils/utils";

export class EmailActions {




  //* GET Email verified status //

  static verifyActiveStep = createAsyncThunk(
    "EmailSlice/verifyActiveStep",
    (activeSteps: number, { dispatch, rejectWithValue }) => {
      return activeSteps;
    })

  //  get Email HTML TEmplate  //
  static htmlTemplateData = createAsyncThunk(
    "EmailSlice/htmlTemplateData",
    (html: any, { dispatch, rejectWithValue }) => {

      return html;

    })

  static testEmailTemplateData = createAsyncThunk(
    "EmailSlice/testEmailTemplateData",
    (html: any, { dispatch, rejectWithValue }) => {

      return html;

    })

  //  get Email HTML TEmplate  //
  static editedHtmlData = createAsyncThunk(
    "EmailSlice/editedHtmlData",
    (editHtml: any, { dispatch, rejectWithValue }) => {

      return editHtml;

    })

  //* POST FILTERED DATA //
  static postFilteredData = createAsyncThunk(
    "EmailSlice/postFilteredData",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.postFilteredData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  static removeFilteredData = createAsyncThunk(
    "EmailSlice/postFilteredData",
    ({ }: any, { dispatch, rejectWithValue }) => {
      return rejectWithValue(null);
    }
  );

  //* POST Email Domain Name //
  static postEmailDomainCreate = createAsyncThunk(
    "EmailSlice/postEmailDomainCreate",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.postEmailDomain(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* POST Email domain verification //
  static postEmailDomainVerify = createAsyncThunk(
    "EmailSlice/postEmailDomainVerify",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.postEmailDomainVerification(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* POST Email bounce domain  //
  static postBounceDomainVerification = createAsyncThunk(
    "EmailSlice/postBounceDomainVerification",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.postBounceDomain(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
        })
        .finally(() => { });
    }
  );


  //* POST Email set as default //
  static postEmailSetDefault = createAsyncThunk(
    "EmailSlice/postEmailSetDefault",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.postEmailDefault(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* DELETE Email Domain//

  static deleteEmailDomain = createAsyncThunk(
    "EmailSlice/deleteEmailDomain",
    (data: any, { dispatch, rejectWithValue }) => {

      return EmailNetworkService.deleteDomain(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // * GET Broadcast BY ID
  static getBroadcastById = createAsyncThunk(
    "WhatsappSlice/getBroadcastById",
    (id: string, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.getBroadcastById(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //  get broadcasting list
  static getEmailAnalyticsListData = createAsyncThunk(
    "EmailSlice/getEmailAnalyticsListData",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.getEmailAnalyticsList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

    // delete Broadcasts
    static deleteEmailBroadcastById= createAsyncThunk(
      "EmailSlice/deleteEmailBroadcastData",
      (data: any, { dispatch, rejectWithValue }) => {
  
        return EmailNetworkService.deleteEmailBroadcast(data)
          .then(async (response: any) => {
            return response;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => { });
      }
    );

  //* Create Email Template//

  static postEmailTemplateCreate = createAsyncThunk(
    "EmailSlice/postEmailTemplateCreate",
    (data: any, { dispatch, rejectWithValue }) => {

      return EmailNetworkService.postEmailTemplate(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );



  //  get broadcasting list by id 
  static getEmailAnalyticsListDataByID = createAsyncThunk(
    "EmailSlice/getEmailAnalyticsListDataByID",
    (id: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.getEmailAnalyticsListByID(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //  get Email template listing

  static getEmailTemplatesListings = createAsyncThunk(
    "EmailSlice/getEmailTemplatesListings",
    (data: any, { dispatch, rejectWithValue }: any) => {
      return EmailNetworkService.getEmailTemplatesLists(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // update email template name

  static updatetemplateById = createAsyncThunk(
    "LiveChatSlice/updateCannedMessageById",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.updateEmailTemplate(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );

  //  get Email template listing

  static getEmailgalleryTemplatesListings = createAsyncThunk(
    "EmailSlice/getEmailgalleryTemplatesListings",
    ({ search, category }: any, { dispatch, rejectWithValue }: any) => {
      return EmailNetworkService.getEmailgalleryTemplatesListings(search, category)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* geta Email TagList//
  static getEmailTagList = createAsyncThunk(
    "EmailSlice/getEmailTagList",
    (dispatch, rejectWithValue) => {

      return EmailNetworkService.getEmailTag()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* get Email Template List//

  static getEmailTempListById = createAsyncThunk(
    "EmailSlice/getEmailTempListById",
    (id: any, { dispatch, rejectWithValue }) => {

      return EmailNetworkService.getEmailTemplateList(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  // delete template
  static deleteEmailTemplateData = createAsyncThunk(
    "EmailSlice/deleteEmailTemplateData",
    (id: any, { dispatch, rejectWithValue }) => {

      return EmailNetworkService.deleteEmailTemplate(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* Create Email Template//

  static postEmailCreateBroadcast = createAsyncThunk(
    "EmailSlice/postEmailCreateBroadcast",
    (data: any, { dispatch, rejectWithValue }) => {

      return EmailNetworkService.postCreateBroadcast(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //* get validate email list//

  static getValidateEmailList = createAsyncThunk(
    "EmailSlice/getValidateEmailList",
    (dispatch, rejectWithValue) => {

      return EmailNetworkService.getValidateEmail()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //* get footer email list//

  static getBroadcastFooterList = createAsyncThunk(
    "EmailSlice/getBroadcastFooterList",
    (dispatch, rejectWithValue) => {

      return EmailNetworkService.getFooterList()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          // return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );



  //* Upload Email Attachment//
  static postUploadAttachment = createAsyncThunk(
    "EmailSlice/postUploadAttachment",
    (data: any, { dispatch, rejectWithValue }) => {
      let { formData, config } = data || {};
      return EmailNetworkService.postUpload(formData, config)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //* getFieldsTypeMappingList // 
  static getFieldsTypeMappingList = createAsyncThunk(
    "EmailSlice/getFieldsTypeMappingList",
    (id: string, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.getFieldsTypeMappingList(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* testEmailTemplate // 
  static postTestEmailTemplate = createAsyncThunk(
    "EmailSlice/postTestEmailTemplate",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.postTestEmailTemplate(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );


  //* testEmailTemplate // 
  static updateEmailThumbnail = createAsyncThunk(
    "EmailSlice/updateEmailThumbnail",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.updateEmailThumbnail(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );


  static getContactPropertiesList = createAsyncThunk(
    "EmailSlice/getContactPropertiesList",
    (data: any, { dispatch, rejectWithValue }) => {
      return EmailNetworkService.getContactPropertiesList({})
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
}
