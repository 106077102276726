import styled from "styled-components";

export const CSATWRapper = styled.div`
  & {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .csat_child {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 5px;
      align-items: center;
      font-family: Inter;
    }
    .csat_child_icon {
      width: 11px;
      height: 11px;
    }
    .star_count {
      font-size: 12px;
      font-family: Inter;
      font-weight: 500;
    }
    .progress_div {
      width: 90%;
    }
    .progress_div_value {
      color: #8b8b8b;
      font-size: 12px;
      font-family: Inter;
      font-weight: 500;
    }
  }
`;
