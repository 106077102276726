import { IconButton } from "@mui/material";
import React from "react";
import {
  StyledWidgetHeader,
  WidgetHeaderBrand,
  WidgetHeaderBrandTitle,
  WidgetHeaderRight,
  WidgetHeaderStatus,
} from "./styles-web-bot-static-preview";
import { AppMaterialIcons } from "../../../../../../socket/appComponents/Icons";
import { theme } from "../../../../../../Customization/Theme";
import { useAppSelector } from "../../../../../../Store";
import { useBotConfigStore } from "../../../../../../Store/Slices/botConfig/BotConfigSlice";

import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuIcon from "@mui/icons-material/Menu";

const WebBotStaticHeader = () => {
  const { localBotConfig } = useAppSelector(useBotConfigStore);

  return (
    <StyledWidgetHeader className="Web-StyledWidgetHeader">
      {localBotConfig?.quick_menu?.enabled && (
        <IconButton disabled disableRipple>
          <MenuIcon
            style={{
              fill: "#101010",
            }}
          />
        </IconButton>
      )}
      <WidgetHeaderBrand>
        <img
          src={localBotConfig?.company_logo}
          alt={localBotConfig?.bot_name}
        />
        <div className="titleBox">
          <WidgetHeaderBrandTitle title={localBotConfig?.bot_name}>
            {localBotConfig?.bot_name}
          </WidgetHeaderBrandTitle>
          <WidgetHeaderStatus>{""}</WidgetHeaderStatus>
        </div>
      </WidgetHeaderBrand>
      <WidgetHeaderRight>
        {localBotConfig?.notification_block?.enabled && (
          <>
            {localBotConfig?.notification_block?.selected_icon === 1 && (
              <IconButton
                color="inherit"
                disabled={true}
                disableFocusRipple={true}
                disableRipple={true}
              >
                <NotificationsOutlinedIcon
                  style={{
                    color: theme.palette.default.darkGrey,
                    fontSize: "22px",
                  }}
                />
              </IconButton>
            )}
            {localBotConfig?.notification_block?.selected_icon === 2 && (
              <IconButton
                color="inherit"
                disabled={true}
                disableFocusRipple={true}
                disableRipple={true}
              >
                <ConfirmationNumberIcon
                  style={{
                    color: theme.palette.default.darkGrey,
                    fontSize: "22px",
                  }}
                />
              </IconButton>
            )}
            {localBotConfig?.notification_block?.selected_icon === 3 && (
              <IconButton
                color="inherit"
                disabled={true}
                disableFocusRipple={true}
                disableRipple={true}
              >
                <Inventory2OutlinedIcon
                  style={{
                    color: theme.palette.default.darkGrey,
                    fontSize: "22px",
                  }}
                />
              </IconButton>
            )}
            {localBotConfig?.notification_block?.selected_icon === 4 && (
              <IconButton
                color="inherit"
                disabled={true}
                disableFocusRipple={true}
                disableRipple={true}
              >
                <MarkChatUnreadIcon
                  style={{
                    color: theme.palette.default.darkGrey,
                    fontSize: "22px",
                  }}
                />
              </IconButton>
            )}
          </>
        )}
        <IconButton
          aria-label="minimize chat"
          disabled={true}
          disableFocusRipple={true}
          disableRipple={true}
        >
          <AppMaterialIcons
            iconName="ExpandMore"
            style={{
              color: theme.palette.default.darkGrey,
              fontSize: "26px",
            }}
          />
        </IconButton>
        <IconButton
          aria-label="minimize chat"
          disabled={true}
          disableFocusRipple={true}
          disableRipple={true}
        >
          <AppMaterialIcons
            iconName="Close"
            style={{
              color: theme.palette.default.darkGrey,
              fontSize: "26px",
            }}
          />
        </IconButton>
      </WidgetHeaderRight>
    </StyledWidgetHeader>
  );
};

export default WebBotStaticHeader;
