import { Drawer, IconButton, debounce } from "@mui/material";
import React, { HTMLProps, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppDeleteItem from "../../AppComponents/AppDeleteItem";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import { ContactsSelectData } from "../../Store/Slices/Contacts/contacts.selector";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../Store/Slices/MarketingList/marketingList.selectors";
import { unMountmarketingListByIdData } from "../../Store/Slices/MarketingList/marketingList.slice";
import { ReactComponent as SearchIcon } from "../../assets/images/Searchicon.svg";
import assign from "../../assets/images/assign.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { ReactComponent as ForwardIcon } from "../../assets/images/forward-arrow.svg";
import { ReactComponent as NavigateIcon } from "../../assets/images/navigate.svg";
import norecord from "../../assets/images/noDatafound.svg";
import LeadTable from "../Leads/LeadTable";
import {
  FirstNameColumn,
  IdCoulumns,
} from "../Leads/contactDetailedComponents/ContactConsts";
import CustomizedMenus from "../Leads/contactDetailedComponents/CustomizedMenus";
import ExportConfirmation from "../Leads/contactDetailedComponents/ExportConfirmation";
import { ContactMarketingWrap } from "./ContactMarketingListStyle";
import DocumentUploadFirstStep from "./commonComponents/drawer/drawerSteps/DocumentUploadFirstStep";

interface Props extends LoaderComponentProps { }
const MarketingImportDetail: React.FC<Props> = ({ setLoading }) => {
  // hooks
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  // redux data
  const {
    marketingListByIdData,
    marketingListByIdDataTotalCount,
    marketingListName,
    InitialSelectValues,
    marketingListByIdDataTotalPages,
  } = useAppSelector(marketingListStore);

  const SelectDropDownData = useAppSelector(ContactsSelectData);

  // states
  const [contactHeaderMapping, setContactHeaderMapping] = useState<any>([]);
  const [skeltonLodaer, setSkeltonLodaer] = useState<boolean>(true);
  const [dynamicsColumn, setDynamicsColumn] = useState<any>([]);
  const [deleteData, setDeleteData] = useState<any>(false);
  const [selectedComponent, setSelectedComponent] = useState<any>(null);

  // select row
  const [selectAll, setSelectAll] = useState<boolean>(false);
  // source id for update modal
  const [subTitle, setSubtitle] = useState("");
  // New Drawer
  const [openImportDrawer, setOpenImportDrawer] = useState(false);
  const inputRef = useRef(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);
  const [firstStep, setFirstStep] = React.useState(false);
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
  });

  const [selectedFields, setSelectedFields] =
    useState<any>(InitialSelectValues);

  useEffect(() => {
    let payload: any = {
      data: "",
      // headers: headers,
    };
    dispatch(ContactsActions.getContactsSelectData(payload))
      .then()
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        // setSkeltonLoader(false);
      });
    return () => {
      dispatch(unMountmarketingListByIdData({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // getSelected headers
  useEffect(() => {
    // working

    if (SelectDropDownData?.length && !contactHeaderMapping?.length) {
      let tempArray: any = [];
      const updatedContactHeader = SelectDropDownData?.map(
        (item: { label: any; name: string }) => {
          tempArray.push(item.label);
          let allColumn = {
            label: item.label,
            id: item.name,
            icon: null,
            size: 250,
          };
          if (item.name === "first_name") {
            return [FirstNameColumn];
          } else {
            return [allColumn];
          }
        }
      ).flat();
      setContactHeaderMapping([IdCoulumns, ...updatedContactHeader]);
    }
  }, [SelectDropDownData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (InitialSelectValues?.length) {
      setDynamicsColumn(InitialSelectValues);
      setSelectedFields(InitialSelectValues);
    }
  }, [InitialSelectValues]);
  // get databy id
  const getMarketingDeatilsTableData = (fields?: any[]) => {
    setSkeltonLodaer(true);
    setLoading(true);
    let payload: any = {
      id,
      page_size: localValues.itemsPerPage?.value,
      page_number: localValues.currentPage,
      search: localValues.query
    };
    if (fields) {
      payload.columns = fields.map((data: any) => {
        return data.name;
      });
    } else {
      payload.columns = dynamicsColumn.map((data: any) => {
        return data.name;
      });
    }
    dispatch(marketingListActions.getNewMarketingListById(payload))
      .then((res: any) => {
        setSkeltonLodaer(false);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
        setSkeltonLodaer(false);
      });
  };

  useEffect(() => {
    getMarketingDeatilsTableData();
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteBtn = (item: any) => {
    setLoading(true);
    let payLoad: any = {
      id,
      data: { selectAll: selectAll, contactId_list: selectedDocumentIds },
    };
    dispatch(marketingListActions.deleteMarketingDeatilsListById(payLoad))
      .then((res: any) => {
        if (res?.payload?.data.status === true) {
          setLoading(false);
          setDeleteData(false);
          showAlert(res?.payload?.data.msg, "success");
          setSelectedDocumentIds(false);
          getMarketingDeatilsTableData();
        } else {
          showAlert(
            res?.payload?.data.error || "Something went wrong",
            "error"
          );
        }
      })
      .catch((err: any) => {
        showAlert("Something went wrong", "error");
      });
  };

  // get file on click
  const downloadFile = () => {
    setLoading(true);
    let payLoad: any = {
      id,
      data: { selectAll: selectedDocumentIds.length > 0 ? selectAll : true, contactId_list: selectedDocumentIds },
    };
    dispatch(marketingListActions.getExportMarketingList(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(res?.error?.message || "Contact not exported", "error");
        } else {
          setSelectedComponent(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        showAlert(
          error?.response?.statusText || "Failed to fetch Data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectAll) {
      const allDocumentIds = marketingListByIdData?.map((item: any) => item.id);
      setSelectedDocumentIds(allDocumentIds);
    }
  }, [marketingListByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  useEffect(() => {
    let title: string = `Are you sure, you want to delete ${selectAll ? "All record" : selectedDocumentIds.length
      } item from ${marketingListName} list?`;
    setSubtitle(title);
  }, [selectAll, selectedDocumentIds]); // eslint-disable-line react-hooks/exhaustive-deps

  // Import Code
  const createImportID = () => {
    dispatch(marketingListActions.CreateImportID({})).then((res) => {
      if (res?.payload?.data?.error) {
        setOpenImportDrawer(false);
        showAlert(res?.payload?.data.error || "Something went wrong", "error");
      } else {
        setOpenImportDrawer(true);
        localStorage.setItem("M_ImportID", res?.payload?.data?.importId);
      }
    });
  };

  React.useEffect(() => {
    let ImportID = localStorage.getItem("M_ImportID");
    if (ImportID) {
      let data = {
        ImportID,
      };
      dispatch(marketingListActions.DeletTempObj(data)).then((res) => {
        localStorage.removeItem("M_ImportID");
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const closeModal = () => {
    setSelectedComponent(null);
  };

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  const handleClearSearch = () => {
    setLocalValues({
      ...localValues,
      query: "",
      currentPage: 0,
    });
    if (inputRef.current) {
      (inputRef.current as HTMLInputElement).value = "";
    }
  };
  useEffect(() => {
    // reset all select initial
    if (
      (selectedDocumentIds && selectedDocumentIds?.length === 0) ||
      (selectedDocumentIds?.length > 0 &&
        selectedDocumentIds?.length !== marketingListByIdData.length &&
        selectAll)
    ) {
      setSelectAll(false);
    }
  }, [selectedDocumentIds]); //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <ContactMarketingWrap>
      <div className="topmenu">
        <div className="left-part">
          <div
            className="icon"
            onClick={() => navigate("/contacts/marketing/list")}
          >
            <IconButton>
              <NavigateIcon />
            </IconButton>
          </div>
          <div className="menu-left">Marketing List</div>
          <div className="icon">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div className="menu-left">{marketingListName}</div>
          {selectedDocumentIds && selectedDocumentIds.length ? (
            <ProtectedAppButton
              moduleId="remove_contacts_from_list"
              onClick={() => setDeleteData(true)}
              variant="danger-filled"
            >
              Remove from list{" "}
            </ProtectedAppButton>
          ) : null}
        </div>
        <div className="right-menu">
          <Drawer anchor="right" open={openImportDrawer}>
            <DrawerWrapper>
              <DocumentUploadFirstStep
                setFirstStep={setFirstStep}
                setState={setFirstStep}
                state={firstStep}
                setOpenImportDrawer={setOpenImportDrawer}
              />
            </DrawerWrapper>
          </Drawer>

          <StyledAppInput>
            <input
              ref={inputRef}
              type="text"
              onChange={(e: any) => handleSelectSearch(e)}
              name="query"
              id="query"
              placeholder="Search by name, email or mobile"
            />
            {
              localValues?.query ? <CloseIcon onClick={handleClearSearch} style={{ cursor: "pointer" }} /> : <SearchIcon />
            }
          </StyledAppInput>

          <ProtectedAppButton
            moduleId="import_contact_to_list"
            variant="outline"
            onClick={() => {
              createImportID();
            }}
          >
            Import Records
          </ProtectedAppButton>
          <ProtectedAppButton
            moduleId="import_contact_to_list"
            variant="outline"
            onClick={() => {
              downloadFile();
            }}
          >
            Export Records
          </ProtectedAppButton>
          <div className="button">
            {marketingListByIdData &&
              marketingListByIdData?.length &&
              dynamicsColumn.length > 0 ? (
              <CustomizedMenus
                availableFields={SelectDropDownData}
                selectedFields={selectedFields}
                setSelectedFields={setSelectedFields}
                dynamicsColumn={selectedFields}
                setDynamicsColumn={setSelectedFields}
                contactHeaderMapping={contactHeaderMapping}
                setContactHeaderMapping={setContactHeaderMapping}
                AllContactPropertiesListdata={SelectDropDownData}
                updateContactList={getMarketingDeatilsTableData}
              />
            ) : null}
          </div>
        </div>
      </div>

      {/* {selectAll ? (
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#cfe8f1",

            // display:
            //   hasUpdateAceess === "global_access" ? "block" : "none",
          }}
        >
          <>
            {selectAll
              ? "Clear selection"
              : `${selectedDocumentIds?.length} contacts selected`}
            <Button
              variant="text"
              size="small"
              onClick={() => {
                setSelectedDocumentIds((prevState: any) => {
                  if (selectAll) {
                    setSelectAll(false);
                    return false;
                  } else {
                    setSelectAll(true);
                    return prevState;
                  }
                });
              }}
            >
              {selectAll
                ? `${selectedDocumentIds.length} contacts selected.`
                : `Select all ${selectedDocumentIds.length} contacts ?`}
            </Button>
            <IconButton
              size="small"
              style={{ color: theme.palette.default.black }}
              disableRipple={true}
              onClick={() => {
                setSelectedDocumentIds(false);
                setSelectAll(false);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        </div>
      ) : null} */}
      {marketingListByIdData &&
        dynamicsColumn.length > 0 &&
        marketingListByIdData.length > 0 ? (
        <>
          <LeadTable
            loader={skeltonLodaer}
            dynamicsColumn={dynamicsColumn}
            data={marketingListByIdData}
            contactHeaderMapping={contactHeaderMapping}
            setLocalValues={setLocalValues}
            localValues={localValues}
            setSelectedDocumentIds={setSelectedDocumentIds}
            selectedDocumentIds={selectedDocumentIds}
            setSelectAll={setSelectAll}
            hidePagination={true}
            selectAll={selectAll}
            removeContactDetailsLink={true}
            hasUpdateAceess={"global_access"}
            totalCount={marketingListByIdDataTotalCount}
            sortingApply={false}
          />
        </>
      ) : (
        <>
          {marketingListByIdDataTotalPages === 0 ? (
            <div
              className="data-details"
              style={{ height: "calc(100vh - 200px)" }}
            >
              <div className="norecord">
                <img src={norecord} alt="img" />
                <p>No Marketing list found</p>
              </div>
              <div className="Import-details">
                <ol>
                  <li>Click on the "Import" button and click on it.</li>
                  <li>
                    Choose the data source you want to import from. This could
                    be a CSV file or Excel spreadsheet.
                  </li>
                  <li>
                    Upload your file and map the columns from your data source
                    to the corresponding fields in your marketing list (e.g.,
                    name, email, location).
                  </li>
                  <li>
                    Review the mapping to ensure accuracy, then proceed to
                    import the data.
                  </li>
                  <li>
                    Once the import is complete, your contacts will be added to
                    the marketing list, ready for your targeted campaigns.
                  </li>
                </ol>
                <p>
                  Remember, accurate and up-to-date data is crucial for
                  successful campaigns, so double-check the imported
                  information. Happy marketing!
                </p>
                <div className="image">
                  <img src={assign} alt="img" />
                </div>

                <ProtectedAppButton
                  moduleId="import_contact_to_list"
                  variant="outline"
                  onClick={() => {
                    createImportID();
                  }}
                >
                  Import Records
                </ProtectedAppButton>
              </div>
            </div>
          ) : null}
        </>
      )}
      {/* pagination  */}
      {marketingListByIdData && marketingListByIdData?.length ? (
        <AppPaginationComponent
          totalCount={marketingListByIdDataTotalCount}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={marketingListByIdDataTotalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          title="Contacts"
        />
      ) : null}

      {/* export  modal */}
      <ExportConfirmation
        onClose={closeModal}
        selectedComponent={selectedComponent}
      />

      {/* delete modal */}
      <AppDeleteItem
        title="Delete From Marketing list"
        subTitle={subTitle}
        onDelete={() => handleDeleteBtn(deleteData)}
        setDeleteData={setDeleteData}
        deleteData={deleteData}
      />
    </ContactMarketingWrap>
  );
};
export default withLoader(MarketingImportDetail);

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className="tanstack-check"
      {...rest}
      style={{ width: "18px", height: "18px" }}
    />
  );
}

const DrawerWrapper = styled.div`
  & {
    .header {
      border-bottom: 1px solid ${theme.palette.default.grey};
      padding: 0 24px;
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        font-size: 20px;
        font-weight: 600;
      }
      .form-steps {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          margin-top: 4px;
          color: ${theme.palette.default.darkGrey};
          font-size: 16px;
        }
      }
    }

    // .body {
    .MuiRadio-root {
      padding: 0px;
    }
    .step-one__wrap {
      padding: 24px;
      height: calc(100vh - 133px);
      overflow: auto;
      .MuiButtonBase-root {
        padding: 0px;
      }
      label {
        color: ${theme.palette.default.black};
        font-size: 14px;
      }
      p.note {
        span {
          font-size: 14px;
          color: ${theme.palette.default.darkGrey};
        }
        margin-bottom: 24px;
      }

      .info {
        margin-top: 48px;
        padding: 0px 24px;
        color: ${theme.palette.default.darkGrey};
      }

      .info ul li {
        margin-bottom: 16px;
      }

      .sample-csv {
        margin-top: 12px;
        color: ${theme.palette.default.darkGrey};
      }
    }
    .step-two__wrap {
      padding: 24px;
      height: calc(100vh - 133px);
      overflow: auto;
      .marketing-list__select {
        border: 1px solid ${theme.palette.default.grey} !important;
        background-color: rgb(245, 246, 248);
        height: 40px;
      }
      .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-disabled {
        border: 1px solid yellow !important;
      }

      h4 {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 32px;
      }
      .duplicate {
        .MuiFormGroup-root {
          flex-direction: column;
          gap: 24px;
          margin-bottom: 16px;
        }
        label {
          align-items: flex-start;
          .main_label {
            color: ${theme.palette.default.black};
            font-size: 14px;
            font-weight: 500;
          }
          .info {
          }
        }
      }

      .unique_value {
        label {
          margin-bottom: 10px;
        }
      }

      .subscription {
        .label {
          font-weight: 500;
          font-size: 14px;
        }
        label {
          margin-bottom: 10px;
        }
      }

      .list {
        width: 100%;
      }
    }

    .step-three__wrap {
      padding: 24px;
      height: calc(100vh - 133px);
      overflow: auto;
    }
    // }

    .footer {
      border-top: 1px solid ${theme.palette.default.grey};
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      padding-right: 24px;
      padding-left: 24px;

      height: 69px;
      align-items: center;
      .step3_text {
        font-size: 14px;
        color: ${theme.palette.default.darkGrey};
      }
    }
  }
`;

const StyledAppInput = styled.div`
  & {
    display: flex;
    align-items: center;
    position: relative;
    input {
      border-radius: 4px;
      outline: none;
      border: 1px solid transparent;
      padding: 0 1.3rem 0 14px;
      font-family: ${theme.typography.fontFamily};
      border: 1px solid ${theme.palette.default.grey};
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      background-color: ${theme.palette.secondary.main};
      max-width: 250px;
      min-width: 250px;
      min-height: 30px;
    }
    svg {
      position: absolute;
      right: 10px;
      width: 15px;
    }
  }

  &::placeholder {
    color: ${theme.palette.default.grey};
  }
`;
