import {
  ValueProcessorByRule,
  defaultRuleProcessorMongoDB,
  formatQuery,
} from "react-querybuilder";

export const getQueryBuilderModifiedValueEditorType = (
  field: any,
  val: string,
  op?: string,
) => {
  if (!val) return "text";

  //   const field = fields?.find((fld: any) => fld?.value === val);

  if (!field) return "text";

  //* allowed types: "text" | "select" | "checkbox" | "radio" | "textarea"

  const DYNAMIC_OPTIONS_FIELD = [
    "radio",
    "select",
    "dropdown",
    "checkbox",
    "multiselect",
  ];

  let modifiedType: string = field?.type ?? "text";

  if (field?.type === "string") {
    modifiedType = "text";
  }
  if (field?.type === "datetime") {
    modifiedType = "date";
  }
  if (DYNAMIC_OPTIONS_FIELD?.includes(field?.type)) {
    modifiedType = "select";
  }

  return modifiedType;
};
export const getQueryBuilderModifiedOperatorsType = (
  field: any,
  val: string,
  op?: string,
) => {
  if (!val) return "text";

  //   const field = fields?.find((fld: any) => fld?.value === val);

  if (!field) return "text";

  const DYNAMIC_OPTIONS_FIELD = ["select", "dropdown"];

  //* allowed types: "text" | "select" | "checkbox" | "radio" | "textarea"

  let modifiedType: string = field?.type ?? "text";

  if (field?.type === "string") {
    modifiedType = "text";
  }
  if (field?.type === "datetime") {
    modifiedType = "date";
  }
  if (DYNAMIC_OPTIONS_FIELD?.includes(field?.type)) {
    modifiedType = "select";
  }
  if (field?.type === "multiselect") {
    modifiedType = "multiselect";
  }
  return modifiedType;
};

export const initialQuery: any = {
  combinator: "and",
  rules: [],
};

export const isQueryValid = (query: any, savedQuery?: any) => {
  let result = false;
  if (query?.rules?.length === 0 && savedQuery?.rules?.length === 0) {
    result = false;
  } else if (savedQuery?.rules?.length > 0) {
    result = true;
  }
  if (query?.rules?.length > 0) {
    let emptyCount = 0;
    let emptyQuery = query?.rules?.find((el: any) => {
      if (el?.operator === "null" || el?.operator === "notNull") {
        return el?.field?.length === 0 || el?.operator?.length === 0;
      }

      return (
        el?.field?.length === 0 ||
        el?.value?.length === 0 ||
        el?.operator?.length === 0
      );
    });

    if (emptyQuery) {
      emptyCount = emptyCount + 1;
    }
    if (emptyCount === 0) {
      result = true;
    } else {
      result = false;
    }

    return result;
  }
  return result;
};

const customValueProcessor: ValueProcessorByRule = (rule, opts) => {
  const DEFAULT_MONGO_REGEX_OPERATORS = [
    "contains",
    "doesNotContain",
    "beginsWith",
    "doesNotBeginWith",
    "endsWith",
    "doesNotEndWith",
    // "between",
    // "notBetween"
  ];
  // const f = rule?.field;
  // const value = rule?.value;

  if (DEFAULT_MONGO_REGEX_OPERATORS.includes(rule?.operator)) {
    const f = rule?.field;
    const value = rule?.value;
    if (rule?.operator === "doesNotContain") {
      const modifiedRule = `{"${f}":{"$not":{"$regex":"${value}","$options":"i"}}}`;
      return modifiedRule;
    }

    if (rule?.operator === "beginsWith") {
      const modifiedRule = `{"${f}":{"$regex":"^${value}","$options":"i"}}`;
      return modifiedRule;
    }

    if (rule?.operator === "doesNotBeginWith") {
      const modifiedRule = `{"${f}":{"$not":{"$regex":"^${value}","$options":"i"}}}`;
      return modifiedRule;
    }

    if (rule?.operator === "endsWith") {
      const modifiedRule = `{"${f}":{"$regex":"${value}$","$options":"i"}}`;
      return modifiedRule;
    }

    if (rule?.operator === "doesNotEndWith") {
      const modifiedRule = `{"${f}":{"$not":{"$regex":"${value}$","$options":"i"}}}`;
      return modifiedRule;
    }
    const modifiedRule = `{"${f}":{"$regex":"${value}","$options":"i"}}`;
    // if (["notBetween", "between"].includes(rule?.operator)) {
    //   const modifiedRule = `{"${f}":{"$gte":ISODate("${value[0]}"),"$lte":ISODate("${value[1]}")}}`;
    //   return modifiedRule;
    // }
    // const modifiedRule = `{"${f}":{ISODate("${value}")}}`;
    // return modifiedRule;
    // }
    // if (["datepicker", "created_time"].includes(rule?.field)) {
    //   const modifiedRule = `{"${f}":{ISODate("${value}")}}`;
    return modifiedRule;
  }
  return defaultRuleProcessorMongoDB(rule, opts);
};

export const convertQueryToMongo = (query: any) => {
  if (query) {
    const mongoQuery = formatQuery(query, {
      format: "mongodb",
      valueProcessor: customValueProcessor,
      parseNumbers: true,
    });
    return mongoQuery;
  }
};

export const getReactSelectCurrentValue = (
  opts: any[],
  val: string,
  valueKey: string = "value",
  showPlaceholder: boolean = true,
) => {
  if (val === undefined || val === null)
    return showPlaceholder ? null : opts?.[0] ?? null;

  if (typeof val === "object") return val;

  const found = opts.find((o: any) => o?.[valueKey] === val);
  if (found) return found;
};
