import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { AppConfig } from "../../../Config/app-config";

const data = AppConfig.getLoggedInData();
const getaHost: string | null = data?.data?.geta_host;

interface Props {
  chatOpened: boolean;
  botId: string | any;
  getaHost: string | any;
  widgetPreviewType: string | any;
  visitorId: string | any;
  isOpenedFirstTime: boolean;
  deviceSize: { width: number; height: number };
}

let INITIAL_STATE: Props = {
  chatOpened: false,
  botId: null,

  widgetPreviewType: null,
  visitorId: null,
  isOpenedFirstTime: false,
  deviceSize: {
    width: 0,
    height: 0,
  },
  getaHost: getaHost,
  // getaHost: 'WXFEanVyTElGTkdrV0szMjowZGJlY2FhNzU4ZTIyMzM3Mzk0YTdlYzVjM2M3M2VkMGViN2I2YzYzYTk3YWY2YmM3MGZmMzA4M2EzOTM4NjE5NmQzMjE5MWFjOWIwNTJkN2I3YmVmMDkyZDQ0OTYyNjk',
};

const BotInfoSlice = createSlice({
  name: "BotInfoSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setOpenStatus(state, { payload }: PayloadAction<any>) {
      state.chatOpened = payload ? true : false;
    },

    setBotId(state, { payload }: PayloadAction<any>) {
      state.botId = payload;
    },

    setWidgetPreviewType(state, { payload }: PayloadAction<any>) {
      state.widgetPreviewType = payload;
    },

    setIsOpenedFirstTime(state, { payload }: PayloadAction<any>) {
      state.isOpenedFirstTime = payload;
    },
    setVisitorId(state, { payload }: PayloadAction<any>) {
      state.visitorId = payload;
    },
    setGetaHost(state, { payload }: PayloadAction<any>) {
      state.getaHost = payload;
    },
  },
});

export default BotInfoSlice.reducer;

export const {
  setOpenStatus,
  setBotId,
  setWidgetPreviewType,
  setIsOpenedFirstTime,
  setVisitorId,
  setGetaHost,
} = BotInfoSlice.actions;

export const botInfoStore = (state: RootState) => state.currrentBotInfo;
