import { IconButton } from "@mui/material";
import React, { Fragment } from "react";
import { theme } from "../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  allMessagesStore,
  setIsSearchBoxVisible,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { StyledAppInput } from "../../Styles/StyledForms";
import { StyledChatSearchBox } from "../../Styles/StyledScreens";
import { AppMaterialIcons } from "../Icons";

interface Props {
  messagesEndRef: React.MutableRefObject<HTMLUListElement | null | any>;
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  debouncedSearchTerm: string;
}

const AppSearchWithInChat: React.FC<Props> = ({
  messagesEndRef,
  searchValue,
  setSearchValue,
  debouncedSearchTerm,
}) => {
  const dispatch = useAppDispatch();
  const { isSearchBoxVisible, sessionId } = useAppSelector(allMessagesStore);
  const [allFoundedIndexes, setAllFoundedIndexes] = React.useState<number[]>(
    []
  );

  const [currentIndex, setCurrentIndex] = React.useState<number>(0);

  const searchTextAndScrollUseRef = (
    ulRef: React.RefObject<HTMLUListElement>,
    searchText: string
  ) => {
    const foundIndexes: number[] = [];
    if (ulRef?.current) {
      const pElements = ulRef.current.getElementsByClassName(
        "getaChatMessageTextBox"
      );
      for (let i = 0; i < pElements.length; i++) {
        const pElement = pElements[i] as HTMLElement;
        pElement.style.backgroundColor = "";

        const pText = pElement.textContent?.toLowerCase() || "";

        if (pText.includes(searchText?.toLowerCase())) {
          pElement.style.backgroundColor = theme.palette.default.warning;
          foundIndexes.push(i);
        }
      }
    }

    return foundIndexes;
  };

  const clearStylingSearchTextAndScrollUseRef = (
    ulRef: React.RefObject<HTMLUListElement>
  ) => {
    if (ulRef?.current) {
      const pElements = ulRef.current.getElementsByClassName(
        "getaChatMessageTextBox"
      );

      for (let i = 0; i < pElements.length; i++) {
        const pElement = pElements[i] as HTMLElement;
        pElement.style.backgroundColor = "";
      }
    }
  };

  const handleSearch = (searchTextWithInChat: string) => {
    if (searchTextWithInChat?.length > 0) {
      const result = searchTextAndScrollUseRef(
        messagesEndRef,
        searchTextWithInChat
      );
      setAllFoundedIndexes(result);
      if (result?.length > 0) {
        scrollToFoundIndex(messagesEndRef, result, result?.length - 1);
      } else {
        console.warn("not found");
      }
    } else {
      clearStylingSearchTextAndScrollUseRef(messagesEndRef);
    }
  };

  const handleClose = () => {
    clearStylingSearchTextAndScrollUseRef(messagesEndRef);
    dispatch(setIsSearchBoxVisible(false));
    setSearchValue("");
    setAllFoundedIndexes([]);
    setCurrentIndex(0);
  };

  const scrollToFoundIndex = (
    ulRef: React.RefObject<HTMLUListElement>,
    arr: number[],
    index: number
  ) => {
    if (debouncedSearchTerm?.length > 0 && ulRef?.current && index >= 0) {
      const currentMsgRow: number = arr?.[index] ?? 0;

      const pElements = ulRef.current.getElementsByClassName(
        "getaChatMessageTextBox"
      );
      pElements?.[currentMsgRow].scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end",
      });
      setCurrentIndex(index);
    }
  };

  React.useEffect(() => {
    handleSearch(debouncedSearchTerm);
    if (debouncedSearchTerm === "") {
      clearStylingSearchTextAndScrollUseRef(messagesEndRef);
      setAllFoundedIndexes([]);
      setCurrentIndex(0);
    }
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (sessionId) {
      handleClose();
    }
  }, [sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isSearchBoxVisible) {
    return null;
  }

  return (
    <StyledChatSearchBox
      isSearchBoxVisible={isSearchBoxVisible}
      className="searchMain"
    >
      <div className="searchBox">
        <div className="search-left">
          <StyledAppInput
            type="text"
            value={searchValue}
            onChange={(e: any) => setSearchValue(e?.target?.value)}
            name="search"
            id="search"
            placeholder="Search"
            autoFocus={true}
          />
        </div>
      </div>

      <div className="search-right">
        {allFoundedIndexes?.length > 0 ? (
          <p className="foundSearchInfo">
            {" "}
            {currentIndex + 1} / {allFoundedIndexes?.length}
          </p>
        ) : null}

        <div className="actionBox">
          {allFoundedIndexes?.length > 0 ? (
            <Fragment>
              <IconButton
                onClick={() =>
                  scrollToFoundIndex(
                    messagesEndRef,
                    allFoundedIndexes,
                    currentIndex - 1 <= 0 ? 0 : currentIndex - 1
                  )
                }
              >
                <AppMaterialIcons iconName="ArrowDropUp" />
              </IconButton>
              <IconButton
                onClick={() =>
                  scrollToFoundIndex(
                    messagesEndRef,
                    allFoundedIndexes,
                    currentIndex + 1 > allFoundedIndexes?.length - 1
                      ? allFoundedIndexes?.length - 1
                      : currentIndex + 1
                  )
                }
              >
                <AppMaterialIcons iconName="ArrowDropDown" />
              </IconButton>
            </Fragment>
          ) : debouncedSearchTerm?.length > 0 ? (
            <p> No results found</p>
          ) : null}
        </div>

        <IconButton onClick={() => handleClose()}>
          <AppMaterialIcons iconName="Close" />
        </IconButton>
      </div>
    </StyledChatSearchBox>
  );
};

export default AppSearchWithInChat;
