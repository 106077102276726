// vendors
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

// components
import Icon from "./../Icon";

interface SearchInput extends InputBaseProps {}

export const InputBaseStyled = styled(InputBase)`
  width: 100%;
  border: 1px solid ${(props) => props.theme.palette.default.grey};
  border-radius: 4px;
  padding: 8px 12px;
  & input {
    padding: 0;
    ::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: ${(props) => props.theme.palette.default.darkGrey};
      opacity: 1;
    }
  }
`;

const SearchInput = (props: SearchInput) => {
  return (
    <InputBaseStyled
      endAdornment={<Icon icon="search" size={16} color="default.doveGrey" />}
      {...props}
    />
  );
};
export default SearchInput;
