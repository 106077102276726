import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";

interface Props{
  handleConnectButton:any
}

const ShopifyInteration:React.FC<Props> = ({handleConnectButton}) => {

  return (
    <ZohoPageWrap>
      <div className="pageTopbar">
        {/* <AppIcon title={"ri-arrow-left-line"} size="26px" color="#282828" /> */}
      </div>
      <div className="ctaBox">
        <img
          className="companyLogo"
          src="https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-shopping-bag-full-color-66166b2e55d67988b56b4bd28b63c271e2b9713358cb723070a92bde17ad7d63.svg"
          alt="Shopify"
        />
        <p className="ctaTitle">Connect with Shopify Account</p>
        <div className="buttonBox">
          <AppButton startIcon="ri-links-line">Connect with Click</AppButton>
        </div>
      </div>
    </ZohoPageWrap>
  );
};

export default ShopifyInteration;

const ZohoPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .pageTopbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      width: 100%;
    }

    .ctaBox {
      display: flex;
      height: 40%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }
  }
`;
