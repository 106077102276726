

// vendors
import InputBase, { InputBaseProps } from '@mui/material/InputBase';

// components
import Icon from "./../icon";

interface SearchInput extends InputBaseProps { };

const SearchInput = (props: SearchInput) => {
  return <InputBase endAdornment={<Icon icon='search' size={16} color="#6C6C6C" />} {...props} sx={{
    width: '100%', border: '1px solid #CBCDD3', borderRadius: '4px', p: '8px 12px', '& input': {
      p: 0, '::placeholder': {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '21px',
        color: '#7E8392',
        opacity: 1
      }
    }
  }} />;
};
export default SearchInput;