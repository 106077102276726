import { IconButton } from "@mui/material";
import React from "react";
import "slick-carousel/slick/slick.css";
import { WidgetSliderBox } from "../..//Styles/StyledComponents";
import { StyledSlickSlider } from "../../Styles/StyledComponents";

import { theme } from "../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  allMessagesStore,
  setToggleScrollFn,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { MESSAGE_TYPES } from "../../utils/Enum";
import { AppMaterialIcons } from "../Icons";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppRenderCtaButtons from "./AppRenderCtaButtons";
import AppRenderFlowButtons from "./AppRenderFlowButtons";
import AppRenderLiveButtons from "./AppRenderLiveButtons";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface SliderProps {
  userMessage: boolean;
  time: string;
  items: any[];
  msgId: string;
  seen: string;
  sent: string;
  delivered: string;
  [otherProps: string]: any;
}

const AppRenderSlider = React.memo(
  ({
    userMessage = false,
    items,
    time,
    msgId,
    seen,
    sent,
    delivered,
    ...otherProps
  }: SliderProps) => {
    const dispatch = useAppDispatch();
    const { toggleScrollFn } = useAppSelector(allMessagesStore);

    const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        time={time}
        isMedia={true}
        msgId={msgId}
        isButton={false}
        divStyle={{
          flexWrap: "nowrap",
          minWidth: "328px",
          maxWidth: "328px",
          backgroundColor: theme.palette.primary.main,
          justifyContent: "flex-end",
        }}
        seen={seen}
        sent={sent}
        delivered={delivered}
      >
        <StyledSlickSlider {...settings}  className='getaChatMessageTextBox' >
          {items?.length &&
            items?.map((item: any, index: number) => {
              return (
                <WidgetSliderBox key={index.toString()}>
                  {item?.image ? (
                    <img
                      className="sliderImage"
                      src={item?.image}
                      alt={"image " + (index + 1)}
                      onClick={() => window.open(item?.image, "_blank")}
                      onLoad={() => {
                        if (!isImageLoaded) {
                          setIsImageLoaded(true);
                          dispatch(setToggleScrollFn(!toggleScrollFn));
                        }
                      }}
                      onError={(error: any) => {
                        error.target.src = FallbackImage;
                      }}
                    />
                  ) : null}

                  <p
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item?.title ? item?.title : null,
                    }}
                  />
                  <p
                    className="subTitle"
                    dangerouslySetInnerHTML={{
                      __html: item?.subTitle ? item?.subTitle : null,
                    }}
                  />
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: item?.text ? item?.text : null,
                    }}
                  />

                  <div className="btnWrapper">
                    {item?.buttons?.map((curBtn: any, index: number) => {
                      //* FLOW BUTTONS
                      if (curBtn?.type === MESSAGE_TYPES.FLOW) {
                        return (
                          <AppRenderFlowButtons
                            userMessage={userMessage}
                            key={index.toString()}
                            item={curBtn}
                            containerStyle={{ minWidth: "100%" }}
                            rowWrapperStyle={{ margin: "2px" }}
                            time={time}
                            msgId={msgId}
                          />
                        );
                      }
                      //* CTA BUTTONS
                      if (
                        curBtn?.type === MESSAGE_TYPES.LINK ||
                        curBtn?.type === MESSAGE_TYPES.MOBILE ||
                        curBtn?.type === MESSAGE_TYPES.EMAIL
                      ) {
                        return (
                          <AppRenderCtaButtons
                            userMessage={userMessage}
                            item={curBtn}
                            key={index.toString()}
                            containerStyle={{ minWidth: "100%" }}
                            rowWrapperStyle={{ margin: "2px" }}
                            time={time}
                            msgId={msgId}
                          />
                        );
                      }

                      //* LIVE BUTTONS
                      if (curBtn?.type === MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
                        return (
                          <AppRenderLiveButtons
                            userMessage={userMessage}
                            item={curBtn}
                            key={index.toString()}
                            containerStyle={{ minWidth: "100%" }}
                            rowWrapperStyle={{ margin: "2px" }}
                            time={time}
                            msgId={msgId}
                          />
                        );
                      }

                      return null;
                    })}
                  </div>
                </WidgetSliderBox>
              );
            })}
        </StyledSlickSlider>
      </AppMessageBoxWrapper>
    );
  }
);

export default AppRenderSlider;

const CustomNextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      className={`${className} nextPreBtn`}
      style={{ ...style }}
    >
      <AppMaterialIcons iconName={"ChevronRight"} />
    </IconButton>
  );
};

const CustomPrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <IconButton
      onClick={onClick}
      className={`${className} nextPreBtn`}
      style={{ ...style }}
    >
      <AppMaterialIcons iconName={"ChevronLeft"} />
    </IconButton>
  );
};

const settings = {
  className: "slickSlider",
  adaptiveHeight: true,
  lazyLoad: true,
  fade: true,
  dots: true,
  infinite: true,
  speed: 500,
  //   autoplay: true,
  //   autoplaySpeed: 2000,
  pauseOnHover: true,
  swipeToSlide: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,

  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
};
