import { useContext, useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useOnClickOutside from "use-onclickoutside";

// builder
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";
import { getActionConfig, getNodeConfig } from "./../../flow-builder-v2/utils";

// hooks
import { useAction } from "./../../flow-builder-v2/hooks";

// components
import { NodeType, Editor, MessagePreview } from "./../../ui-components";
import Card from "../card";
import Action from "../action";

// utils
import {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
} from "./../utils/editor";
import {
  getText,
  getResponse,
  cleanMediaDetails,
  isEmptyObj,
  getMediaDetails,
} from "./../utils/helper";

const OTPNode = (props: any) => {
  const wrapperRef = useRef(null);
  const builderContext = useContext(BuilderContext);
  const nodeCntx = useContext(NodeContext);
  const { setActiveNode, activeNode, language } = builderContext;
  const { updateNode, removeNode } = useAction();
  const [message, setMessage] = useState("");
  const [nodeConfig, setNodeConfig] = useState<any>(null);
  const [showPreview, setPreview] = useState<boolean>(true);
  const [editorState, setEditorState] = useState(getInitialEditiorText("Please enter the OTP"));
  const [isOutsideClicked, setOutsideClicked] = useState<boolean>(false);
  const response = getResponse(nodeCntx, language);
  const [mediaDetails, setMediaDetails] = useState<any>(
    getMediaDetails(response) || {}
  );
  const node = getNodeConfig(nodeCntx.parentNode || nodeCntx.subType || nodeCntx.type, nodeCntx.parentNode ? nodeCntx.subType || nodeCntx.type : null);
  const actions = nodeCntx?.actions || [];

  useOnClickOutside(wrapperRef, (event) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }
    setOutsideClicked(true);
    if (!showPreview) {
      const previousPlainText = response
        ? getPlainTextData(getInitialEditiorText(getText(response)))
        : "";
      const plainText = getPlainTextData(editorState);
      const previousMediaDetails = response ? getMediaDetails(response) : {};
      const currentMediaDetails = mediaDetails;

      if (plainText === '') return;

      if (nodeCntx.id && !showPreview || !isEmptyObj(mediaDetails)) {
        if (
          plainText !== previousPlainText ||
          JSON.stringify(previousMediaDetails) !==
          JSON.stringify(currentMediaDetails)
        ) {
          updateNode(nodeCntx.nodeId || nodeCntx.id, message, mediaDetails, undefined);
        }
      }
      setPreview(true);
      // setActiveNode(null);
    }
  });

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setMessage(getHTMLContent(editorState));
    setEditorState(editorState);
    setMediaDetails(cleanMediaDetails(mediaInfo));
  };

  const handleBlur = () => {
    setMessage(getHTMLContent(editorState));
  };

  const togglePreview = () => {
    setPreview(!showPreview);
    setActiveNode(nodeCntx.nodeId || nodeCntx.id);
  };

  const handleCancelEditing = () => {
    if (nodeCntx?.nodeId) {
      const plainText = getPlainTextData(editorState);
      setOutsideClicked(true);
      if (plainText !== '') {
        setActiveNode(null);
        togglePreview();
        return;
      }
    } else {
      togglePreview();
      removeNode();
    }
  };

  useEffect(() => {
    if (nodeCntx.id && activeNode !== null && activeNode === nodeCntx.id) {
      const text = "Please enter the OTP";
      setEditorState(getInitialEditiorText(text));
      setMessage(text);
      updateNode(nodeCntx.nodeId || nodeCntx.id, text, mediaDetails, undefined);
      setNodeConfig(getNodeConfig(nodeCntx.parentNode || nodeCntx.subType || nodeCntx.type, nodeCntx.parentNode ? nodeCntx.type : null));
      return;
    }

    const text = "Please enter the OTP";
    setEditorState(getInitialEditiorText(text));
    setMessage(text);
  }, [activeNode]);

  useEffect(() => {
    const value = response?.[0]?.value || "";
    setMessage(value);
  }, [language]);

  useEffect(() => {
    setOutsideClicked(false);
  }, []);
  return (
    <Box
      id={props.id}
      {...(((activeNode !== null && activeNode === nodeCntx.id) ||
        activeNode === nodeCntx.nodeId) && { ref: wrapperRef })}
    >
      <Card
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
      >
        <Box
          sx={{ p: "12px 16px" }}
          onClick={(e) => {
            e.stopPropagation();
            togglePreview();
          }}
        >
          {!showPreview ? (
            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onBlur={handleBlur}
              mediaInfo={mediaDetails}
              error={isOutsideClicked && getPlainTextData(editorState) === ""}
            />
          ) : (
            <MessagePreview mediaDetails={mediaDetails} text={message} />
          )}
        </Box>
        <Divider />
        {node !== null ? (
          <NodeType text={node.name} color={node.color} icon={node.icon} />
        ) : (
          <p>Test</p>
        )}
      </Card>
      <Action actions={actions} nodeContext={nodeCntx} />
    </Box>
  );
};
export default OTPNode;
