import styled from "styled-components";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import uniqid from "uniqid";
import mobilelogo from "../../assets/images/mobile.svg";
import tabletlogo from "../../assets/images/tablet.svg";
import desktoplogo from "../../assets/images/desktop.svg";

interface Props {
  rows?: any;
}

const SessionDevicesGraph: React.FC<Props> = ({ rows }) => {
  return (
    <StyledSessionDevicesBox>
      <Table size={"small"} style={{ padding: "0px 20px" }}>
        <TableHead>
          <TableRow>
            <StyledTableCell
              sx={{ color: "red" }}
              key={uniqid()}
              align={"left"}
              padding={"none"}
            >
              Devices
            </StyledTableCell>
            <StyledTableCell key={uniqid()} align={"right"} padding={"none"}>
              Conversations
            </StyledTableCell>
            <StyledTableCell key={uniqid()} align={"right"} padding={"none"}>
              Contacts
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow hover={false} tabIndex={-1} key={uniqid()}>
            <StyledTableCell
              key={uniqid()}
              align={"left"}
              padding="none"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <img src={mobilelogo} alt="" />
              Mobile
            </StyledTableCell>

            <StyledTableCell key={uniqid()} align={"right"} padding="normal">
              {rows?.[0]
                ? rows?.filter((ele: any) => ele.name === "Mobile")?.[0]
                    ?.conversation || 0
                : "N/A"}
            </StyledTableCell>

            <StyledTableCell key={uniqid()} align={"right"} padding="normal">
              {rows?.[0]
                ? rows?.filter((ele: any) => ele.name === "Mobile")?.[0]
                    ?.contacts || 0
                : "N/A"}
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hover tabIndex={-1} key={uniqid()}>
            <StyledTableCell
              key={uniqid()}
              align={"left"}
              padding="none"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <img src={tabletlogo} alt="" />
              Tablet
            </StyledTableCell>

            <StyledTableCell key={uniqid()} align={"right"} padding="normal">
              {rows?.[0]
                ? rows?.filter((ele: any) => ele.name === "Tablet")?.[0]
                    ?.conversation || 0
                : "N/A"}
            </StyledTableCell>

            <StyledTableCell key={uniqid()} align={"right"} padding="normal">
              {rows?.[0]
                ? rows?.filter((ele: any) => ele.name === "Tablet")?.[0]
                    ?.contacts || 0
                : "N/A"}
            </StyledTableCell>
          </StyledTableRow>

          <StyledTableRow hover tabIndex={-1} key={uniqid()}>
            <StyledTableCell
              key={uniqid()}
              align={"left"}
              padding="none"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              <img src={desktoplogo} alt="" />
              Desktop
            </StyledTableCell>

            <StyledTableCell
              width="40%"
              key={uniqid()}
              align={"right"}
              padding="normal"
            >
              {rows?.[0]
                ? rows?.filter((ele: any) => ele.name === "Desktop")?.[0]
                    ?.conversation || 0
                : "N/A"}
            </StyledTableCell>

            <StyledTableCell
              width="40%"
              key={uniqid()}
              align={"right"}
              padding="normal"
            >
              {rows?.[0]
                ? rows?.filter((ele: any) => ele.name === "Desktop")?.[0]
                    ?.contacts || 0
                : "N/A"}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </StyledSessionDevicesBox>
  );
};

export default SessionDevicesGraph;

const StyledSessionDevicesBox = styled.div`
  && {
    width: 27rem !important;
    width: 100% !important;
    overflow: hidden;
    table {
      border-spacing: 0rem 0.5rem !important;
      border-collapse: separate !important;
    }
    .MuiTable-root {
      padding-right: 2px;
      padding: 0 1rem;
    }
  }
`;

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#000",
    fontWeight: "500",
    fontSize: "15px",
    textTransform: "capitalize",
    borderBottom: "none",
    height: "40px",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#7E8392",
    backgroundColor: "white",
    fontWeight: "400",
    fontSize: "15px",
    height: "60px",
    borderBottom: "none",
  },
  [`&.${tableRowClasses.root}`]: {},
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {
    backgroundColor: "white !important",
  },
  "&:nth-of-type(odd)": {},
  // hide last border
  "&:last-child td, &:last-child th": {},
  "&:hover": {
    backgroundColor: "white !important",
  },
}));
