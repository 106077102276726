import { List, ListItem, ListItemButton, Tooltip } from "@mui/material";
import React from "react";
import AppIcon from "../../../../../AppComponents/AppIcon";
import { UserDetailRightMenu } from "../constants";
import { UsersDetailMenuWrapper } from "./UserDetailMenuStyles";
import { ProtectedAppUiElement } from "../../../../../AppComponents/ProtectedComponents";

interface UserDetailsMenuProps {
  toggleMenu: (value: boolean) => void;
  setMenuBtn?: any;
  menuBtn?: boolean;
  selected?: any;
  onDataSend?: any;
  accName?: any;
  setAccName?: any;
}

const UserDetailMenu: React.FC<UserDetailsMenuProps> = ({
  toggleMenu,
  menuBtn,
  accName,
  setAccName,
  onDataSend,
  setMenuBtn,
}) => {
  const handleAccoradingClick = (item: any) => {
    setAccName(item?.id);
    if (menuBtn) {
      toggleMenu(false);
    }
  };

  return (
    <UsersDetailMenuWrapper>
      <List>
        <ListItem sx={{ p: "6px 0" }}>
          <Tooltip
            // disableInteractive={true}
            arrow
            enterDelay={1}
            leaveDelay={0}
            // TransitionComponent={Grow}
            placement="right"
            title=""
          >
            <ListItemButton
              // selected={selectedIndex === curElem.id}
              onClick={() => toggleMenu(!menuBtn)}
              sx={{ p: "8px 0", display: "flex", justifyContent: "center" }}
            // disabled={!curElem.isAvailable}
            >
              <AppIcon
                size="1rem"
                title={
                  menuBtn ? "ri-arrow-left-s-line" : "ri-arrow-right-s-line"
                }
                color="#5B73E8"
              />
            </ListItemButton>
          </Tooltip>
        </ListItem>

        {UserDetailRightMenu &&
          UserDetailRightMenu?.length > 0 &&
          UserDetailRightMenu?.map((curElem: any, index: number) => {

            if (!curElem?.moduleId) return null;
            
            return (
                  <ProtectedAppUiElement moduleId={curElem?.moduleId} key={index}>
                    <ListItem key={index} sx={{ p: "6px 0" }}>
                      <Tooltip
                        // disableInteractive={true}
                        arrow
                        enterDelay={1}
                        leaveDelay={0}
                        // TransitionComponent={Grow}
                        placement="right"
                        title={curElem.name}
                      >
                        <ListItemButton
                          selected={!menuBtn && accName === curElem?.id}
                          onClick={() => {
                            handleAccoradingClick(curElem);
                          }}
                          sx={{
                            p: "8px 0",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          disabled={curElem?.disabled}
                        >
                          <AppIcon
                            size="1rem"
                            color="#5B73E8"
                            title={curElem.avatar}
                          />
                        </ListItemButton>
                      </Tooltip>
                    </ListItem>
                  </ProtectedAppUiElement>
            );
          })}
      </List>
    </UsersDetailMenuWrapper >
  );
};

export default UserDetailMenu;

