import React from "react";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { useAppSelector } from "../../../Store";
import { useCurrentUser } from "../../../Store/Slices/CurrentUser/CurrentUserSlice";

interface Props {
  lowCreditsMessage?: string;
  ChatWindowMessage?: string;
}

const AppWhatsappInboxTimeBox: React.FC<Props> = ({
  ChatWindowMessage = "The customer has not spoken to you in the last 24 hours.",
  lowCreditsMessage = "Insufficient credit balance - WhatsApp messaging blocked. Please recharge now to continue.",
}) => {
  const userInfoData = useAppSelector(
    (state) => state.currrentAllMessagesStore.userInfoData
  );
  
  const { userCreditsInfo } = useAppSelector(useCurrentUser);

  if (userInfoData?.channel === 'whatsapp' && userCreditsInfo?.remaining_amount < 100) {
    return <StyledBoxWrapper>{lowCreditsMessage}</StyledBoxWrapper>;
  }

  if (userInfoData?.conversation_type === "livechat" && userInfoData?.channel === "whatsapp" && !userInfoData?.whatsapp_window) {
    return <StyledBoxWrapper>{ChatWindowMessage}</StyledBoxWrapper>;
  }
  

  return null;
};

export default AppWhatsappInboxTimeBox;

const StyledBoxWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    padding: 8px 8px;
    border-radius: 4px;
    background-color: ${theme.palette.default.error};
    color: ${theme.palette.default.white};
  }
`;
