import { useEffect } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  removeAllSessionsData,
  removeChatSessionActiveFilters,
} from "../../Store/Slices/socket/liveChat/chat.slice";
import MainUI from "../../socket/screens/MainUI";
import AppNoActivityScreen from "../appComponents/UtilsComponents/AppNoActivityScreen";
import NoChatScreen from "../appComponents/UtilsComponents/NoChatScreen";
import { useSocket } from "../hooks/socket-context";
import AllchatPannel from "./Chats/AllCahatPannel/AllchatPannel";
import {
  ChatAndDetailContainer,
  ChatAndDetailOuterWrap,
  ChatsMainWrapper,
} from "./Chats/ChatMainStyles";
import LiveChatSidebar from "./Chats/Sidebar/LiveChatSidebar";
import UsersDetail from "./Chats/UsersDetail/UsersDetail";

import {useCurrentUser} from "../../Store/Slices/CurrentUser/CurrentUserSlice";
import { removeAllMessages } from "../../Store/Slices/socket/AllMessagesSlice";
import ServerError from "../appComponents/UtilsComponents/ServerError";

interface Props {}

const NO_INTERNET_MSG =
  "You are offline. Please connect to the internet to continue.";
const SOCKET_FAILED_MSG = "Something went wrong. Please refresh the page.";
const AGENT_OFFLINE_MSG =
  "You are currently offline. No chats will be assigned. Toggle to `Available` to start receiving chats again.";

const Home: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const allMessages = useAppSelector(
    (state) => state.currrentAllMessagesStore.allMessages
  );
  const isPreviousChatLoading = useAppSelector(
    (state) => state.currrentAllMessagesStore.isPreviousChatLoading
  );
  const sessionId = useAppSelector(
    (state) => state.currrentAllMessagesStore.sessionId
  );
  const { socket } = useSocket();
  const { isUserAvailable } = useAppSelector(useCurrentUser);

  useEffect(() => {
    return () => {
      dispatch(removeChatSessionActiveFilters({}));
      dispatch(removeAllSessionsData());
      dispatch(removeAllMessages({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!isUserAvailable ? (
        <StatusWapper>{AGENT_OFFLINE_MSG}</StatusWapper>
      ) : null}
      {socket && !socket?.connected ? (
        <StatusWapper>{SOCKET_FAILED_MSG}</StatusWapper>
      ) : null}
      <ChatsMainWrapper>
        <LiveChatSidebar />
        <div
          className=""
          style={{ width: "calc(100% - 314px)", display: "flex", flexGrow: 1 }}
        >
          <div className="" style={{ display: "flex", width: "100%" }}>
            <AllchatPannel />
            <ChatAndDetailOuterWrap
              style={{
                width: "calc(100% - 380px)",
                position: "relative",
              }}
            >
              {sessionId?.length > 0 && allMessages?.length > 0 ? (
                <div style={{ width: "100%", height: "100%" }}>
                  <AppNoActivityScreen loading={isPreviousChatLoading} />
                  <ChatAndDetailContainer>
                    <MainUI />
                    <UsersDetail />
                  </ChatAndDetailContainer>
                </div>
              ) : (
              socket?.username ? (<NoChatScreen />) : ( <ServerError />)
              )}
            </ChatAndDetailOuterWrap>
          </div>
        </div>
      </ChatsMainWrapper>
    </>
  );
};

export default Home;

export const StatusWapper = styled.div`
  && {
    text-align: center;
    color: white;
    background-color: #d93939;
    padding: 6px;
    height: 36px;
  }
`;

export const StyledHomeWrapper = styled.div`
  && {
    background-color: #ebc6c6;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    transition: all 0.5s linear 0s;
    overflow: hidden;
  }
`;
export const StyledStatusBox = styled.div`
  && {
    background-color: #b1a3a3;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    width: 100%;
    height: 64px;
    transition: all 0.5s linear 0s;
  }
`;
