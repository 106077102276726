import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  debounce
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import AppDrawer from "../../AppComponents/AppDrawer";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../AppComponents/Forms";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import { SettingPageMainContentBox } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import {
  ContactsSelectData
} from "../../Store/Slices/MarketingList/marketingList.selectors";
import { SegmentActions } from "../../Store/Slices/Segment/Segment.actions";
import { SegmentNetworkService } from "../../Store/Slices/Segment/SegmentNetworkService";
import LeadTable from "../Leads/LeadTable";
import {
  FirstNameColumn,
  IdCoulumns,
} from "../Leads/contactDetailedComponents/ContactConsts";
import CustomizedMenus from "../Leads/contactDetailedComponents/CustomizedMenus";
import CreateSegment from "./CreateSegment";
import { CreateSegmentWrapper } from "./CreateSegmentStyles";
import {
  SettingPageMainBoxBtnBoxx,
  SettingPageMainBoxTitlee,
  SettingPageMainBoxTopbarr,
  SettingPageMainBoxWrapp,
} from "./SegmentListStyles";

interface Props extends LoaderComponentProps { }

const SegmentContactList = (props: Props) => {
  useDocumentTitle("Segments");
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const SelectDropDownData = useAppSelector(ContactsSelectData);
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    tags: "",
    search: "",
    perPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    mongoQuery: "",
    filter_query: "all_contacts",
    contact_filter: "all_contacts",
  });
  const [segmentInput] = useState<any>({
    search: "",
  });

  const [selectedFields, setSelectedFields] = useState<any>([]);
  const [contactHeaderMapping, setContactHeaderMapping] = useState<any>([]);
  const [paginationData, setPaginationData] = useState<any>({
    rowsPerPage: { label: "50  per page", value: 50 },
    pageNumber: 0,
    total: 0,
    totalPages: 0,
  });

  const [contactList, setContactList] = useState([]);
  const [editData, setEditData] = useState<any>(null);
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const [skeltonLodaer, setSkeltonLoader] = useState(true);
  const dispatch = useAppDispatch();
  const urlParams = useParams();

  useEffect(() => {
    if (
      SelectDropDownData &&
      SelectDropDownData.length &&
      contactHeaderMapping.length <= 0
    ) {
      const updatedContactHeader: any = [];
      SelectDropDownData?.map((item: { label: any; name: string }) => {
        let allColumn = {
          label: item.label,
          id: item.name,
          icon: null,
          size: 250,
        };
        if (item.name === "first_name") {
          return updatedContactHeader.push(FirstNameColumn);
        } else {
          return updatedContactHeader.push(allColumn);
        }
      });
      setContactHeaderMapping([IdCoulumns, ...updatedContactHeader]);
    }
  }, [SelectDropDownData]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    getContact();
    let payload: any = {
      data: "",
      // headers: headers,
    };
    dispatch(ContactsActions.getContactsSelectData(payload))
      .then()
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        // setSkeltonLoader(false);
      });
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps
  const getProductById = () => {
    dispatch(SegmentActions.getSegmentById(urlParams.seg_id))
      .then((res: any) => {
        setEditData(res?.payload?.data?.response);
      })
      .catch(() => { })
      .finally(() => {
        props.setLoading(false);
      });
  };

  useEffect(() => {
    if (isDrawerOpened) {
      getProductById();
    }
  }, [isDrawerOpened]); //  eslint-disable-line react-hooks/exhaustive-deps

  const getContact = (updatedColums?: any) => {
    setSkeltonLoader(true);

    let payload: any = {
      search: localValues?.search,
      limit: paginationData.rowsPerPage.value,
      offset: paginationData.pageNumber,
      segment_id: urlParams.seg_id,
    };

    if (updatedColums) {
      payload.columns = updatedColums.map((data: any) => {
        return data.name;
      });
    }

    SegmentNetworkService.getAllSegmentConatctListData(payload)
      .then((response: any) => {
        setPaginationData({
          rowsPerPage: paginationData.rowsPerPage,
          pageNumber: response?.data?.pagination?.currentPage,
          totalRecords: response?.data?.pagination?.totalRecords,
          totalPages: response?.data?.pagination?.totalPages,
          search: "",
        });
        setContactList(response?.data?.data);
        setSelectedFields(response?.data?.columns);
        setSkeltonLoader(false);
      })
      .catch(() => {
        setSkeltonLoader(false);
      })
      .finally(() => {
        setTimeout(() => {
          props.setLoading(false);
        }, 300);
        closeDrawer();
      });
  };
  const handlePageChange = (event: any, value: any) => {
    setPaginationData({
      ...paginationData,
      pageNumber: value - 1,
    });
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };

  const handleChangeRowsPerPage = (value: any) => {
    setPaginationData({
      ...paginationData,
      rowsPerPage: value,
      pageNumber: 0,
    });
    setLocalValues({
      ...localValues,
      perPage: value,
    });
  };

  const handleExportButton = () => {
    props.setLoading(true);
    SegmentNetworkService.DownloadConatctsFromSegment({
      segment_id: urlParams.seg_id,
    }).then((response: any) => {
      window.open(response?.data?.data?.source, "_blank");
      props.setLoading(false);
      showAlert(
        "Exported Successfully. Please check your download folder.",
        "success"
      );
    }).catch(() => {
      props.setLoading(false);
      showAlert("Unable to export", "error");
    });
  };

  const clearCallback = (e?: any, field?: any) => {
    setPaginationData({
      ...paginationData,
      currentPage: 0,
    });
    setLocalValues({
      ...localValues,
      [field]: "",
    });
  };
  const GotoSegment = () => {
    const subUrl = `/contacts/segment/list`;
    navigate(subUrl);
  };

  const toggleDrawer = () => {
    setIsDrawerOpened(!isDrawerOpened);
  };

  const closeDrawer = () => {
    setIsDrawerOpened(false);
    setEditData(null);
  };

  //Search Functionality
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setPaginationData({
      ...paginationData,
      currentPage: 0,
    });
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);
  return (
    <SettingPageMainBoxWrapp
      style={{
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <SettingPageMainBoxTopbarr
        style={{
          overflow: "visible",
          minHeight: "60px",
          height: "60px",
        }}
      >
        <ArrowBackIosNewIcon
          sx={{ paddingRight: "5px", cursor: "pointer" }}
          onClick={GotoSegment}
        />
        <SettingPageMainBoxTitlee
          style={{
            fontWeight: 500,
            color: theme.palette.default.black,
            fontSize: "16px",
          }}
        >
          Contacts : {paginationData.totalRecords}
        </SettingPageMainBoxTitlee>
        <SettingPageMainBoxBtnBoxx
          style={{
            height: "100%",
            flex: "1",
            gap: "1rem",
          }}
        >
          <AppForm initialValues={segmentInput} onSubmit={() => { }}>
            <AppFormSearchField
              name="search"
              placeholder="Search"
              defaultBorder={true}
              divStyle={{
                marginBottom: 0,
                marginRight: "1rem",
                width: "100%",
                maxWidth: "450px",
                minWidth: "100px",
                gap: 0,
              }}
              onChange={(e: any) => {
                handleSelectSearch(e, "search");
              }}
              clearCallback={(e: any) => clearCallback(e, "search")}
            />
          </AppForm>

          <div className="button">
            <AppButton
              variant="outline"
              style={{
                borderRadius: "6px",
              }}
              onClick={() => {
                toggleDrawer();
              }}
            >
              Edit Conditions
            </AppButton>
            <AppDrawer
              open={isDrawerOpened}
              onClose={() => undefined}
              sx={{
                "& .MuiPaper-root": { minWidth: "999px", width: "999px" },
              }}
            >
              <CreateSegmentWrapper>
                <Box className="form-heading">
                  <Box>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                      Edit Segment
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton
                      onClick={() => {
                        toggleDrawer();
                      }}
                    >
                      <AppMaterialIcons iconName={"Close"} />
                    </IconButton>
                  </Box>
                </Box>

                <Divider />
                <CreateSegment getTableData={getContact} editData={editData} />
              </CreateSegmentWrapper>
            </AppDrawer>
          </div>
          {contactList ?
            <CustomizedMenus
              availableFields={SelectDropDownData}
              selectedFields={selectedFields}
              setSelectedFields={setSelectedFields}
              dynamicsColumn={selectedFields}
              setDynamicsColumn={setSelectedFields}
              contactHeaderMapping={contactHeaderMapping}
              setContactHeaderMapping={setContactHeaderMapping}
              AllContactPropertiesListdata={SelectDropDownData}
              updateContactList={getContact}
            /> : null}
          <ProtectedAppButton
            moduleId="export_segment_contacts"
            onClick={() => handleExportButton()}
            variant="outline"
            style={{
              width: "100px",
              minWidth: "100px",
              justifyContent: "center",
            }}
            disabled={contactList?.length > 0 ? false : true}
          >
            Export
          </ProtectedAppButton>
        </SettingPageMainBoxBtnBoxx>
      </SettingPageMainBoxTopbarr>

      <SettingPageMainContentBox
        style={{
          alignItems: "start",
          paddingBottom: "0rem",
          padding: "0rem",
          overflow: "hidden",
          marginTop: "10px"
        }}
      >
        <LeadTable
          loader={skeltonLodaer}
          dynamicsColumn={selectedFields}
          data={contactList}
          contactHeaderMapping={contactHeaderMapping}
          setLocalValues={setLocalValues}
          localValues={localValues}
          hidePagination={true}
          sortingApply={false}
          type={"segment"}
          hideSelection={true}
        />
      </SettingPageMainContentBox>

      {paginationData?.totalPages > 0 ? (
        <AppPaginationComponent
          totalCount={paginationData?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={paginationData?.pageNumber - 1}
          totalPages={paginationData?.totalPages}
          rowsPerPage={paginationData?.rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          title="Contacts"
        />
      ) : (
        ""
      )}
    </SettingPageMainBoxWrapp>
  );
};

export default withLoader(SegmentContactList);
