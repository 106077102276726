import { RootState } from "../../index";

export const getapplicationFormList = (state: RootState) => state.applicationForm.data;


export const getFilterQuery = (state: RootState) =>
    state.applicationForm.data.filterQuery;

export const getapplicationFieldsMapping = (state: RootState) => state.applicationForm.queryBuilderData;

// ATTCHMENT History Data
export const getAttachmentData = (state: RootState) =>
    state.applicationForm.data?.attachmentData;

// ATTCHMENT Loading State
export const getAttachmentLoading = (state: RootState) =>
    state.applicationForm.loading;

// ATTCHMENT Error in API
export const getAttachmentError = (state: RootState) =>
    state.applicationForm.error;

// Payment History Data
export const getPaymentHistoryData = (state: RootState) =>
    state.applicationForm.data?.paymentHistoryData;

// Payment Loading State
export const getPaymentHistoryLoading = (state: RootState) =>
    state.applicationForm.loading;

// Payment Error in API
export const getPaymentHistoryError = (state: RootState) =>
    state.applicationForm.error;
