import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class ContactListNetworkService {

    // * get contact properties field options *//
    static getContactPropertiesFieldOptions(id: any) {
        return http.get(`${consoleBaseUrl}${Endpoints.GET_CONTACT_PROPERTIES_FIELD_OPTIONS}/${id}/options`);
      }

    // get contact properties fileds
    static getContactPropertiesList(header:any) {    
        return http.get(`${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES}`);
    }
    static getAllVisibleContactPropertiesFieldOptions() {
        return http.get(`${consoleBaseUrl}${Endpoints.GET_CONTACT_PROPERTIES_FIELD}`)
    }

}

//*contact list endpoints *//

// get contact properties field options path
class Endpoints {
    static CONTACT_PROPERTIES =  "/workspace/contact-properties/fields?status=visible";
    static GET_CONTACT_PROPERTIES_FIELD_OPTIONS = "/workspace/contact-properties/field";
    static GET_CONTACT_PROPERTIES_FIELD = "/workspace/contact-properties/fields?status=visible"
}

