import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../Customization/Theme";
import useDebounce from "../../../../Hooks/useDebounce";
import { SettingsAutomationOptions } from "../../../../Models/defaultSelectOptions";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  allTabsPagination,
  contactListCount,
} from "../../../../Store/Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.selectors";
import { paginationHandling } from "../../../../Store/Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.slice";
import { ReactComponent as SearchIcon } from "../../../../assets/images/Searchicon.svg";
import { ReactComponent as ForwardIcon } from "../../../../assets/images/forward-arrow.svg";
import {
  FaqTextWrap,
  StyledAppInput,
} from "../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import {
  ContactMarketingWrap,
  NewSelect,
} from "../../../ContactMarketingList/ContactMarketingListStyle";

interface Props { }

const AutoAssignmentTabs: React.FC<Props> = () => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(0);
  const count = useAppSelector(contactListCount);
  const pagination: any = useAppSelector(allTabsPagination);
  const navigate = useNavigate();
  const location = useLocation();
  const [searcTerm, setSearchTerm] = useState<any>("");
  const autoAssignmentRulesSettings = useMemo(() => {
    return SettingsAutomationOptions.map((option: any) => ({
      ...option,
      url: option.url,
    }));
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentTabAfterRefresh = () => {
    autoAssignmentRulesSettings.forEach((item: any, index: number) => {
      if (location.pathname.includes(item.value)) {
        setValue(index);
      }
    });
  };

  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  // Hadnling Search Functionality
  const handleSelectSearch = (e?: any) => {
    setSearchTerm(e.target.value);
  };

  const debouncedSearchTerm = useDebounce<string>(searcTerm, 300);
  useEffect(() => {
    let intialFilter: any = {
      ...pagination,
      query: searcTerm,
      currentPage: 0,
    };
    if (searcTerm !== null && count !== null) {
      dispatch(paginationHandling(intialFilter));
    }
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    let intialFilter = {
      currentPage: 0,
      itemsPerPage: 10,
      query: "",
    };
    setSearchTerm(null);
    dispatch(paginationHandling(intialFilter));
    setValue(newValue);
    navigate(autoAssignmentRulesSettings[newValue].url);
  };
  // handling pagination ************
  
  const handlePageChange = (event: any, value: any) => {
    let intialFilter: any = {
      ...pagination,
      currentPage: value,
    };
    dispatch(paginationHandling(intialFilter));
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let intialFilter: any = {
      ...pagination,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    };
    dispatch(paginationHandling(intialFilter));
  };
  return (
    <ContactMarketingWrap>
      <div className="topmenu">
        <div className="left-part">
          <div className="icon">Settings</div>
          <div className="menu-left">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div className="icon">Automation</div>
          <div className="menu-left">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div className="icon">Auto Assignment Rules</div>
          <div className="menu-left">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div
            className="icon"
            style={{ color: `${theme.palette.default.blue}` }}
          >
            {SettingsAutomationOptions[value]?.name || ""}
          </div>
        </div>
        <div className="right-part">
          <NewSelect>
            <div className="faq-search">
              {!id ? <AppButton
                onClick={() =>
                  navigate(
                    "/settings/automation/auto-assignment/rules/create"
                  )
                }
              >
                <AppMaterialIcons iconName="add" />
                Create Rules
              </AppButton> : null}
            </div>
            <div className="button"></div>
          </NewSelect>
        </div>
      </div>
      <FaqTextWrap style={{
        width: "auto",
        overflow: "hidden",
        marginLeft: "16px",
        marginRight: "16px",
        marginTop: " 16px",
      }}>
        <span>Auto Assignment Rules</span>
        <div className="top-header">
          <div className="header-left">
            <p style={{ width: "90%" }}>
              The route automatically assigns tasks to your team, saving time and effort. This lets your sales team focus on building relationships with the best leads.
            </p>
          </div>
          {!id ? <div className="serach-class">
            <div className="faq-search">
              <StyledAppInput>
                <input
                  type="text"
                  value={searcTerm}
                  onChange={(e: any) => {
                    handleSelectSearch(e);
                    setSearchTerm(e.target.value);
                  }}
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
          </div> : null}
        </div>
        <Box sx={{ width: "100%" }}>
          {/* <Tabs
            className="website-tabs"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {autoAssignmentRulesSettings.map((option: any, index: number) => (
              <Tab label={option.name} id={`simple-tab-${index}`} key={index} />
            ))}
          </Tabs> */}
          {autoAssignmentRulesSettings.map((option: any, index: number) => (
            <CustomTabPanel value={value} index={index} key={index}>
              <Outlet />
            </CustomTabPanel>
          ))}
        </Box>
      </FaqTextWrap>
      {/* {count > 0 ? (
        <AppPagination
          component="div"
          style={{ marginRight: "21px" }}
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={pagination?.currentPage}
          count={count}
          rowsPerPage={pagination?.itemsPerPage}
        />
      ) : null} */}
    </ContactMarketingWrap>
  );
};

export default AutoAssignmentTabs;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <CustomTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </CustomTabPanelContainer>
  );
}
const CustomTabPanelContainer = styled.div``;
