import { getIn, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  AppFormFastField,
  AppFormSelectField,
  AppFormTextArea,
} from "../../../../../AppComponents/Forms";
import { LoaderComponentProps } from "../../../../../AppComponents/Loader";
import { HocBackdropLoader } from "../../../../../HOC/HocBackdropLoader";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { marketingListActions } from "../../../../../Store/Slices/MarketingList/marketingList.actions";
import { WhatsappActions } from "../../../../../Store/Slices/Whatsapp/whatsapp.actions";
import { whatsappDataStore } from "../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { getBodyComponentDetails } from "../../../../../Utils";

interface Props extends LoaderComponentProps {
  formStep: number;
  isView: boolean;
  setFormStep: Dispatch<SetStateAction<number>>;
}

const RenderFormStep1: React.FC<Props> = ({ setLoading, formStep, isView }) => {
  const dispatch = useAppDispatch();
  const { allTemplatesData, templateByIdData }: any =
    useAppSelector(whatsappDataStore);
  const { values, setFieldValue, setValues } = useFormikContext<any>();

  const formkey1 = "broadcast_name";
  const formkey2 = "broadcast_template_id";
  const formkey3 = "body_preview";

  useEffect(() => {
    if (getIn(values, formkey2)) {
      setLoading(true);
      dispatch(
        WhatsappActions.getTemplateById(getIn(values, formkey2))
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
    }
  }, [getIn(values, formkey2)]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getIn(values, formkey2)) {
      setFieldValue(formkey3, getBodyComponentDetails(templateByIdData?.original_response?.components)?.text);
    }
  }, [templateByIdData,]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(marketingListActions.resetFieldsTypeMappingList({}));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const changeTemplate = (e: any) => {
    setValues({
      ...values,
      "broadcast_template_id": e,
      "resolved_header_variables": [],
      "resolved_body_variables": [],
      "resolved_buttons_variables": []
    })
  }

  if (formStep !== 1) {
    return null;
  }

  return (
    <React.Fragment>
      <AppFormFastField
        name={formkey1}
        label={"Broadcast Name"}
        placeholder="Broadcast Name"
        disabled={isView ? true : false}
        require={true}
      />
      <AppFormSelectField
        name={formkey2}
        label="Broadcast template"
        Options={allTemplatesData?.filter(
          (ele: any) => ele?.status === "APPROVED"
        )}
        divStyle={{ maxWidth: "1000px" }}
        defaultText="Select a template"
        onChange={changeTemplate}
        enableSearch={true}
        isDisabled={isView ? true : false}
        requiredSign={true}
      />

      <AppFormTextArea
        name={formkey3}
        label="Message Template Preview"
        placeholder="Template body"
        rows={5}
        disabled={true}
      />
    </React.Fragment>

  )
};

export default HocBackdropLoader(RenderFormStep1);
