import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum GETA_LAYOUT {
  CLIENT_LAYOUT = 'client',
  BUILD_LAYOUT = 'build',
  WELCOME_LAYOUT = 'welcome'
}
interface LayoutProps {
  activeLayout: GETA_LAYOUT;
}


const INITIAL_STATE: LayoutProps = {
  activeLayout: GETA_LAYOUT.WELCOME_LAYOUT,
};

const sliceLayout = createSlice({
  name: "LayoutProps",
  initialState: INITIAL_STATE,
  reducers: {
    ChangeLayout(state, { payload }: PayloadAction<LayoutProps>) {
      return payload;
    },
  },
});

export default sliceLayout.reducer;
export const { ChangeLayout } = sliceLayout.actions;

export const useLayoutStore = (state: any) => {
  return state.activeLayout as LayoutProps;
};
