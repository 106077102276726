import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AppInputField from '../../../AppComponents/AppInputField'
import { AppMaterialIcons } from '../../../AppComponents/AppMaterialIcons'
import { AppForm } from '../../../AppComponents/Forms'
import { theme } from '../../../Customization/Theme'
import { useAppDispatch, useAppSelector } from '../../../Store'
import { formNameStoreData } from '../../../Store/Slices/FormBuilder/formbuilder.selectors'
import { saveFormName } from '../../../Store/Slices/FormBuilder/formbuilder.slice'
import { ReactComponent as PencilIcon } from '../../../assets/images/edit-icon.svg'
interface NavigationProps {
    isPreviousPageAvaliable?: boolean;
    previousRoute?: string | any;
    actionsSection?: JSX.Element;
}
const Navigation = ({ isPreviousPageAvaliable, previousRoute, actionsSection }: NavigationProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formDetails: any = useAppSelector(formNameStoreData);
    const [isEdit, setIsEdit] = useState(false)
    const location = window.location.pathname;
    const [formName, setFormName] = useState(formDetails?.form_name)

    return (
        <Container>
            <div className='infoContainer'>
                {isPreviousPageAvaliable ?
                    <IconButton onClick={() => navigate(previousRoute)}>
                        <AppMaterialIcons
                            iconName={"ArrowBackIosRounded"}
                            style={{ fontSize: "1rem", color: "black" }}
                        />
                    </IconButton>
                    : null}
                <p>Forms</p>
                <AppForm initialValues={{ formName: formDetails?.form_name || "" }}
                    onSubmit={(values: any) => {
                        dispatch(saveFormName({ form_name: formName }))
                        formName?.length > 0 && setIsEdit(false)
                    }}>
                    <div className='formContainer'>
                        {formDetails && !isEdit ?
                            <React.Fragment>
                                <span className='formName'>
                                    {formDetails?.form_name}
                                </span>
                                <PencilIcon
                                    onClick={() => setIsEdit(true)}
                                    style={{
                                        marginLeft: "20px",
                                        cursor: "pointer"
                                    }} />
                            </React.Fragment>
                            : !location?.includes("lists") ?
                                <AppInputField
                                    name="formName"
                                    errorMessage=''
                                    defaultValue={formDetails?.form_name}
                                    placeholder="Write your form name"
                                    onBlur={() => {
                                        dispatch(saveFormName({ form_name: formName }))
                                        formName && setIsEdit(false)
                                    }}
                                    autoFocus={true}
                                    onChange={(e: any) => setFormName(e.target.value)}
                                    value={formName}
                                    style={{ marginTop: "10px" }}
                                /> : null
                        }
                    </div>
                </AppForm>
            </div>
            <div className='header_form'>
                {actionsSection}
            </div>
        </Container>
    )
}


const Container = styled.div`
&& {
    height: 64px;
    background-color:${theme.palette.default.white};
    margin-top: 1px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    overflow: hidden;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    position:relative;
    p {
        font-size:16px;
    }
    .header_form{
        display:flex;
        align-items:center;
        gap:25px;
    }
    .infoContainer{
        display:flex;
        align-items:center;
    }
    .breadcrumb{
        display:flex;
        align-items:center;
        gap: 8px;
    }
    .icon{
        cursor:pointer;
    }
    .formContainer{
        margin-left: 30px;
        display:flex;
        align-items:center;
    }
    .formName{
        font-size: 16px;
        font-weight: 600;
        color: ${theme.palette.default.black};
    }
}`

export default Navigation