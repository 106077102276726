import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const EmailSettingsWrapper = styled.div`
& {
    width: 100%;
    // height: inherit;
    color: ${theme.palette.default.black};
    .headerWrapper {
        background-color: ${theme.palette.default.white};
        height: 65px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
        display: flex;
        width: 100%;
        align-items: center;
        font-size: 20px;
        line-height: 30px;
        padding: 1rem;
    }
    
}
`
export const CenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: calc(100vh - 130px);
    height: 100%;
    overflow: auto;
`;
export const NoRecordWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 55px;
`;
export const ContentWrapper = styled.div`
    max-width: 615px;
    margin: 65px auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h3 {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 22px;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
    }
`;

export const AddEmailWrapper = styled.div`
& {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(16, 16, 16, 0.1);
        padding: 1rem;
        h3 {
            font-size: 1.1rem;
            font-weight: 500;
            span {
                color: ${theme.palette.default.darkGrey};
                font-size: 0.875rem;
                margin-left: 0.5rem;
            }
        }
    }
    .body {
        p {
            font-size: 0.875rem;
            padding: 1rem 2rem;
            color: ${theme.palette.default.darkGrey};
        }
        form {
            .input {
                padding: 0rem 2rem  0rem 2rem;
            }
            
            .footer {
                border-top: 1px solid rgba(16, 16, 16, 0.1);
                padding: 1rem;
                gap: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
}
`;
export const FooterSetupWrapper = styled.div`
    background: #fff;
    height: calc(100vh - 228px);
    padding-top: 16px;
    overflow-y: auto;
        margin: 0px 16px;
    label {
        font-size:16px;
        margin-botoom: 20px;
    }
    .d-flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        > div {
            width: 50%;
            &:first-child {
                max-width: calc(50% - 20px);
            }
        }
    }
    h4 {
        font-size: 22px;
        font-weight: 500;
        margin: 30px 0 15px;
    }
    .formFooter {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        button {
            margin-left: 20px;
        }
    }
    form {
        padding: 15px;
    }
`

export const TabsWrapper = styled.div`
& {
    borderBottom:none;
    borderColor:divider;
    display:flex;
    justifyContent:space-between; 
    alignItems:center;
    padding-right:1rem;
    boredr:1px solid red;
}
`
export const AddressesTable = styled.div`
& {
height: 100%;
    overflow: auto;
    .table-header {
        display: flex;
        border-bottom: 1px solid ${theme.palette.default.grey};
        min-width: 1980px;
        max-width: 1980px;
    }
    .table-body {
        min-width: 1980px;
        max-width: 1980px;
    }
    .table-data_row {
        display: flex;
        border-bottom: 1px solid ${theme.palette.default.grey};
    }

    

    .table-data_row > div:last-child {
        display: flex;
        justify-content: flex-end;
    }

    .actions {
        display: flex;
        align-items: center;
        & .MuiSvgIcon-root {
            border-radius: 50%;
            color: ${theme.palette.default.darkGrey};
            font-size: 1.4rem;
        }
    }

    .table-col {
        padding: 1rem;
        max-width: 180px;
        min-width: 180px;
        display: flex;
        align-items: center;
       
        h4 {
            font-weight: 500;
            color: ${theme.palette.default.black};
            font-size: 0.875rem;
            margin: 0;
        }
        p {
            color: ${theme.palette.default.darkGrey};
            font-size: 0.875rem;
        }
        .verification-status__success {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            p{
                color: ${theme.palette.default.success};
                font-weight: 500;
                font-size: 0.938rem;
            }
            & .MuiSvgIcon-root {
                border: 1px solid ${theme.palette.default.success};
                border-radius: 50%;
                color: ${theme.palette.default.success};
                font-size: 0.975rem;
            }
        }

        .verification-status__failed {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            p{
                color: ${theme.palette.default.error};
                font-weight: 500;
                font-size: 0.938rem;
            }
            & .MuiSvgIcon-root {
                border: 1px solid ${theme.palette.default.error};
                border-radius: 50%;
                color: ${theme.palette.default.error};
                font-size: 0.975rem;
            }
        }
    }
}
`

export const WhatsappSettingsWrapper = styled.div`
    background: #fff;
    min-height: 100%;
    padding: 27px;
    margin: 1rem;
    // width: 100%;
    label {
        font-size:16px;
        margin-botoom: 20px;
    }
    .d-flex {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 30px;
        > div {
            width: calc(100% - 20px);
           
        }
    }
    h4 {
        font-size: 22px;
        font-weight: 500;
        margin: 30px 0 15px;
    }
    .formFooter {
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
`
export const DomainListing = styled.div`
&& {
    background-color: #ffffff;
    padding: 24px;
    width: 100%;
    height: 100%;
    overflow: auto;
    border-radius: 4px;
    .listWrapper {
        width: 100%;
        border-radius: 4px;
        border: 1px solid ${theme.palette.default.grey};
        background: ${theme.palette.default.lightGrey};
        padding: 0px 24px;
        padding-right: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        height: 128px;
        max-height: 128px;
        .menu-text{
            color: ${theme.palette.default.darkGrey};
            font-size: 13px;
            font-weight: 400;
        }
            .listCover {
            border: 2px solid red;
            }
        .listLeft {
            width: 100%;
            display: flex;
            .column {
                width: 33.33%;
                max-width: 33.33%;
                display: flex;
                flex-direction: column;
                gap: 16px;
                padding: 12px;
            }

        }
        
    }
    label {
        color: ${theme.palette.default.black};
        font-size: 14px;
        font-weight: 500;
    }
    .info {
        color: ${theme.palette.default.darkGrey};
        font-size: 14px;
        font-weight: 400;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .info.status.green {
        color: ${theme.palette.default.success};
    }

    .info.status.red {
        color: ${theme.palette.default.error};
    }
}
`
export const DomainDetailsWrapper = styled.div`
    max-height: calc(100vh - 300px);
    overflow: auto;
    .text-dark {
        color: ${theme.palette.default.black};
    }
        .text-color-gray {
            color: ${theme.palette.default.darkGrey};
        }
    .title {
        color: ${theme.palette.default.black};
        font-size: 20px;
        font-weight: 500;
        border-bottom: 1px solid ${theme.palette.default.border};
        padding-bottom: 24px;
        margin-bottom: 32px;
    }
    .radioInput{
        border: 1px solid ${theme.palette.default.border};
    }
    .domainDetails{

        .text-gray {
            color: ${theme.palette.default.darkGrey};
            font-size: 14px;
            font-weight: 400;
        }

        // margin-bottom: 64px;
        > p {
            color: #7E8392;
            font-size: 14px;
            margin-bottom: 42px;
        }
        .tasksDetails {
            margin-bottom: 32px;
            &:last-child {
                padding-bottom: 24px;
                // border-bottom: 1px solid ${theme.palette.default.border};
            }
        }
        .customDomain{
            .subDiv{
                display: flex;
                align-items: center;
            }
            h4{
                background: ${theme.palette.default.lightGrey};
                border-radius: 4px;
                padding: 8px 56px;
                font-size: 16px;
                font-weight: 500;
                margin-right: 16px;
                margin-bottom: 5px;

                }
               
        }
        .buttonContinue{
            border-top: 1px solid ${theme.palette.default.border};
            display: flex;
            justify-content: flex-end;
            padding-top: 24px;
            margin-top: 24px;
        width: 70%;
        gap: 16px;
        }
       
    }
    .checktext{
        display: flex;
        border-radius: 4px;
        padding: 10px;
        margin-bottom: 16px;
        max-width: 785px;
        align-items: center;
        P {
            font-size: 14px;
            font-weight: 500;
        }
        svg {
            margin-right: 10px;
        }
        &.green {
            border: 1px solid ${theme.palette.default.success};
        }
        &.red {
            border: 1px solid ${theme.palette.default.red};
        }
    }
    .domainFooter {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        button {
            margin-left: 20px;
        }
    }
    .domainBounceDetails {
        .bounceFooter {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
export const BreadcrumbsItem = styled.div`
& {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .icon {
        display: flex;
        align-items: center;
        img {
            path: {
                fill: red;
            }
        }
    }
    .text {
        display: flex;
        align-items: center;
        p {
            font-size: 0.875rem;
            margin: 0;
            color: ${theme.palette.default.darkGrey}
        }
    }
}
`;
