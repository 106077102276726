// vendors
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import React from "react";

// components
import Icon from "../Icon";

// styles
import { UIButtonGroupStyled } from "./buttonGroup.styled";

const UIButtonGroup = (props: {
  ref?: any;
  children: React.ReactNode | string;
  fullWidth: boolean;
  withOptions: boolean;
  onClick?: (event: any) => void;
  id?: string;
  icon?: string;
}) => {
  const {
    id,
    children,
    fullWidth,
    withOptions,
    icon = "options",
    onClick,
    ref,
  } = props;
  return (
    <Stack spacing={2} direction="row" sx={{ ...(fullWidth && { flex: 1 }) }}>
      <UIButtonGroupStyled
        variant="outlined"
        disableRipple
        onClick={onClick}
        {...props}
      >
        <Button
          style={{
            borderTopLeftRadius: "1rem",
            borderBottomLeftRadius: "1rem",
          }}
          fullWidth
        >
          {children}
        </Button>
        {withOptions && (
          <Button
            id={id}
            size="small"
            sx={{
              width: "40px",
              borderTopRightRadius: "1rem",
              borderBottomRightRadius: "1rem",
            }}
          >
            <Icon disableFill icon={icon} size={12} />
          </Button>
        )}
      </UIButtonGroupStyled>
    </Stack>
  );
};

UIButtonGroup.defaultProps = {
  fullWidth: false,
  withOptions: false,
};

export default UIButtonGroup;
