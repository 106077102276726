import { Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { AppForm } from "../../../AppComponents/Forms";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";
import { useAppDispatch } from "../../../Store";
import { SubscriptionActions } from "../../../Store/Slices/Subscriptions/subscriptions.actions";
import { getCountryList, getCreditListById } from "../../../Store/Slices/Subscriptions/subscriptions.selectors";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { creditRate } from "../const";
import { StyledTableCell, StyledTableRow, Title } from "../style";

const BroadcastCreditRateList = (props: any) => {
    const dispatch = useAppDispatch()
    const getCountryListData = useSelector(getCountryList);
    const getCreditListByIdData = useSelector(getCreditListById);
    const [headerKey, setHeaderKey] = useState<any>([]);
    const [localValues, setLocalValues] = useState<any>({
        limit: 10,
        perPage: { label: "10  per page", value: 10 },
        currentPage: 0,
        question: "",
        checkbox: [],
        checkboxAll: false,
        query: "",
        sort: true,
        search: ""
    });

    const goBack = () => {
        props.setBroadcastType("")
    }
    let getCreditListByIdAPICall = (value?: any) => {
        let data = {
            type: props.broadcastType.toLowerCase(),
            search: value,
            limit: value ? 10 : localValues?.perPage?.value,
            offset: value ? 0 : localValues?.currentPage
        }
        dispatch(SubscriptionActions.getCreditListById(data))
    }
    useEffect(() => {
        setHeaderKey([])
        dispatch(SubscriptionActions.getAllCountryList({}))
        getCreditListByIdAPICall("")
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.broadcastType.toLowerCase()) {
            let data: any = creditRate
            setHeaderKey(data[props.broadcastType.toLowerCase()])
        }
    }, [props.broadcastType.toLowerCase()]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeCallbackFn = (value: any) => {
        let name: any = "";
        value.map((res: any) => {
            if (name === "") {
                name = res.value
            } else {
                name = name + "," + res.value
            }
        })
        getCreditListByIdAPICall(name)
    }
    useEffect(() => {
        if (localValues?.perPage?.value || localValues?.currentPage) {
            getCreditListByIdAPICall()
        }
    }, [localValues?.perPage?.value, localValues?.currentPage]) // eslint-disable-line react-hooks/exhaustive-deps
    // Handle Pagination Functions
    const handlePageChange = (event: any, value: any) => {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    };
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            perPage: value,
            currentPage: 0
        });
    };
    return (
        <CreditRateWapper>
            <AppForm
                initialValues={localValues}
                onSubmit={(values: any, submitProps: any) => { }}
                divStyle={{ width: "100%", paddingLeft: "10px" }}
            >
                <a onClick={() => goBack()} style={{ cursor: "pointer", display: "flex" }}>
                    <AppMaterialIcons
                        iconName={"ArrowBackIosOutlined"}
                        style={{ fontSize: "18px", margin: "9px" }}

                    />
                    <Title fontSize={25}>{props.broadcastType}</Title>

                </a>
                {props.broadcastType !== "Email" ? <> <div>Destination country</div>
                    <AppReactSelectField
                        name={"Timezone"}
                        options={getCountryListData}
                        label=""
                        valueKey={"label"}
                        displayKey={"label"}
                        isMulti={true}
                        isClearable={false}
                        onChangeCallbackFn={onChangeCallbackFn}
                        // isDisabled={isFormFieldDisabled('tuesday')}
                        divStyle={{ maxWidth: "300px", minWidth: "98px" }}
                    /></> : null}
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                {headerKey.map((key: any, index: number) => {
                                    return (
                                        props.broadcastType === "WhatsApp" ?
                                            <StyledTableCell align={index === 0 ? "" : "right"}>{key?.name || ""}</StyledTableCell>

                                            : <StyledTableCell component="th" scope="row" >{key?.name || ""}</StyledTableCell>

                                    )
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.broadcastType !== "Email" ? getCreditListByIdData?.results.map((row: any, index: any) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                        {row[headerKey[0]?.value]}
                                    </StyledTableCell>
                                    {props.broadcastType === "WhatsApp" ?
                                        <>
                                            <StyledTableCell align="right">{parseFloat(row[headerKey[1]?.name]?.price + row[headerKey[1]?.name]?.margin)?.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="right">{parseFloat(row[headerKey[2]?.name]?.price + row[headerKey[2]?.name]?.margin)?.toFixed(2) || 0}</StyledTableCell>
                                            <StyledTableCell align="right">{parseFloat(row[headerKey[3]?.name]?.price + row[headerKey[3]?.name]?.margin)?.toFixed(2) || 0}</StyledTableCell>
                                            <StyledTableCell align="right">{parseFloat(row[headerKey[4]?.name]?.price + row[headerKey[4]?.name]?.margin)?.toFixed(2) || 0}</StyledTableCell>
                                        </>

                                        : <StyledTableCell component="th" scope="row">{parseFloat(row[headerKey[1]?.value] + row[headerKey[1]?.margin]).toFixed(2) || 0}</StyledTableCell>
                                    }
                                </StyledTableRow>
                            )) :
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">{"Worldwide services"}</StyledTableCell>
                                    <StyledTableCell component="th" scope="row">{parseFloat(getCreditListByIdData?.results[0]?.[headerKey[1]?.value] + getCreditListByIdData?.results[0]?.[headerKey[1]?.margin] || 0).toFixed(2) || 0}</StyledTableCell>
                                </StyledTableRow>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                {props.broadcastType !== "Email" ? <AppPaginationComponent
                    totalCount={getCreditListByIdData?.pagination?.totalRecords}
                    handlePageChange={handlePageChange}
                    currentPage={localValues?.currentPage}
                    totalPages={getCreditListByIdData?.pagination?.totalPages}
                    rowsPerPage={localValues?.perPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                /> : null}

            </AppForm>
        </CreditRateWapper>
    )
}
const CreditRateWapper = styled.div`
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,.17);
    padding: 48px;
`
export default BroadcastCreditRateList