import { Box, Drawer, Grid, Typography, debounce } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import AppRangeDatePicker from "../../../AppComponents/AppRangeDatePicker";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { agentDateRange } from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { setAgentDashboardDateRange } from "../../../Store/Slices/DashboardRedux/dashboard.slice";
import TotalCostSummaryBoxes from "./components/TotalCostSummaryBoxes";

import {ReactComponent as SmsCostIcon} from "../../../assets/images/sms-cost.svg"
import { ReactComponent as EmailCostIcon } from "../../../assets/images/email-cost.svg"
import { ReactComponent as SmartAiCostIcon } from "../../../assets/images/smartai-cost.svg"
import { ReactComponent as InboxCostIcon } from "../../../assets/images/inbox-cost.svg"

import { ReactComponent as WhatsappCostIcon } from "../../../assets/images/whatsapp-cost.svg"


import BorderedTable from "../../UiComponents/NewTable";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import AppSkeletonTableLoader from "../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import React from "react";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";
import { AILOGDATA, SMSLOGSDATA } from "./util";

import { SubscriptionActions } from "../../../Store/Slices/Subscriptions/subscriptions.actions";
import moment from "moment";
import { creditSummaryData, creditSummaryDataError, creditSummaryLogsData, creditSummaryLogsDataLoading, creditSummaryLogsDataPagination } from "../../../Store/Slices/Subscriptions/subscriptions.selectors";
import BroadcastLogsTable from "../../../CommonComponents/BroadcastLogs/BroadcastLogsTable";
import { getTableData } from "./components/utils";
import { SubscriptionsNetworkService } from "../../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../HOC/HocBackdropLoader";
import { getCurrentTimeZone } from "../../../Utils";




const labelMapping = {
    _id: "Date",
    module: "Description",
    total_amount: "Cost",
}

const tableColWidth = {
    "_id": "150px",
    "module": "auto",
    "total_amount": "150px"
}



interface TrackingEXpenseProps extends HocBackdropLoaderProps {
}


const TrackingExpenses: React.FC<TrackingEXpenseProps> = ({setLoading}) => {

    const dispatch = useAppDispatch();
    const dashdateRange: any = useAppSelector(agentDateRange);
    const CreditSummaryLogsData = useAppSelector(creditSummaryLogsData)
    const CreditSummaryLogsDataPagination = useAppSelector(creditSummaryLogsDataPagination)
    const CreditSummaryLogsDataLoading = useAppSelector(creditSummaryLogsDataLoading)

    const CreditSummaryData = useAppSelector(creditSummaryData)
    const CreditSummaryDataLoading = useAppSelector(creditSummaryLogsDataLoading)
    const CreditSummaryDataError = useAppSelector(creditSummaryDataError)

    

    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [dataForDrawer, setDataForDrawer] = React.useState<any>(false);

    const [tableData, setTableData] = React.useState<any>({})
    const [tableType, setTableType] = React.useState<string>("")
    const [categoryForWhatsapp, setCategoryForWhatsapp] = React.useState<string>("")
    const [tableDateRange, setTableDateRange] = React.useState<string>("")

    const inputRef = React.useRef(null);

    const [localValues, setLocalValues] = React.useState<any>({
        itemsPerPage: { label: "10  per page", value: 10 },
        currentPage: 0,
        totalPages: 0,
    });


  
    const onChange = (date: any) => {
        dispatch(setAgentDashboardDateRange(date));
    };

    // Hadnling Search Functionality
    const handleSelectSearch = debounce((e?: any) => {
        setLocalValues({
            ...localValues,
            query: e.target.value,
            currentPage: 0,
        });
    }, 700);

    const handleClearSearch = () => {
        setLocalValues({
            ...localValues,
            query: "",
            currentPage: 0,
        });
        if (inputRef.current) {
            (inputRef.current as HTMLInputElement).value = "";
        }
    };

    const handleViewDetailsAction = (data: any) => {
        if (!data) return;
        const { type, invoice_id, module, _id } = data;
        if (type === "invoice" && invoice_id) {
            setLoading(true)
            SubscriptionsNetworkService.getInvoiceByID(invoice_id)
                .then((res: any) => {
                    setLoading(false);
                    window.open(res?.data?.url, "_blank");
                })
                .catch((err: any) => {
                    setLoading(false);
                })
                .finally(() => { });
        } else {
            setIsOpen(true);
            setDataForDrawer(data);
            const tData: any = getTableData(module);
            setTableData(tData);
            setTableType(module);
            setTableDateRange(_id);
    
            if (module === "sms") {
                setTableType("sms");
            } else if (module === "email") {
                setTableType("email");
            } else {
                setTableType("whatsapp");
                if (module === "whatsapp marketing") {
                    setCategoryForWhatsapp("marketing");
                }
                if (module === "whatsapp utility") {
                    setCategoryForWhatsapp("utility");
                }
                if (module === "whatsapp service") {
                    setCategoryForWhatsapp("service");
                }
            }
        }
    };

     // All About Pagination
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

    React.useEffect(() => {
        const payload = {
             start: moment(dashdateRange.startDate).format("yyyy-MM-DD"),
            end: moment(dashdateRange.endDate).format("yyyy-MM-DD"),
            limit: localValues.itemsPerPage?.value,
            offset: localValues.currentPage, 
            timezone:getCurrentTimeZone()
        }
        dispatch(SubscriptionActions.getCreditSummaryLogsAction(payload))
    }, [localValues, dashdateRange])

    React.useEffect(() => {
        const payload = {
             start: moment(dashdateRange.startDate).format("yyyy-MM-DD"),
            end: moment(dashdateRange.endDate).format("yyyy-MM-DD"),
        }
        dispatch(SubscriptionActions.getCreditSummaryAction(payload))
    }, [dashdateRange])

    return (<TrackingExpensesWrapper>
        <Box className="top_bar">
            <Typography 
                variant="h6" 
                fontSize={"15px"} 
                fontWeight={500}
            >
                Track Expenses
            </Typography>
        </Box>
        <Box className="data_container">
            <Box className="data_top_bar">
                <Typography 
                    fontSize={"15px"} 
                    fontWeight={400}
                >
                    Total Cost Summary
                </Typography>
                <AppRangeDatePicker
                    dateRange={dashdateRange}
                    setDateRange={onChange}
                // hideTodayOption={true}
                />
            </Box>
            <Box className="total_cost_summary">
                {/* Boxes Temporarly Commented cus of statci data */}
                <TotalCostSummaryBoxes isLoading={CreditSummaryDataLoading} data={CreditSummaryData || []} />
            </Box>
            <Box className="table_area">
                <BorderedTable
                    keyToHeaderMap={labelMapping}
                    tableData={CreditSummaryLogsData && CreditSummaryLogsData}
                    handleActionClick={handleViewDetailsAction}
                    border={false}
                    actionColumn={true}
                    // tableHeaderColor={true}
                    height={"100%"}
                    pagination={true}
                    isLoading={CreditSummaryLogsDataLoading}
                    tableColWidth={tableColWidth}
                    
                />

                
            </Box>

            {
                CreditSummaryLogsData && CreditSummaryLogsData.length > 0 ? <AppPaginationComponent
                totalCount={CreditSummaryLogsDataPagination && CreditSummaryLogsDataPagination?.total_records}
                handlePageChange={handlePageChange}
                currentPage={localValues?.currentPage}
                totalPages={CreditSummaryLogsDataPagination && CreditSummaryLogsDataPagination?.total_pages}
                rowsPerPage={localValues?.itemsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              /> : null
            }
        </Box>
        


        {/* Drawer */}
        {isOpen && (
            <Drawer 
                anchor="right" open={isOpen}
                // onClose={() => setIsOpen(false)}
            >
                <Box
                    p={2}
                    width={"100%"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                        backgroundColor: "#EEF1FF",
                        height: "54px",
                        maxHeight: "54px"
                    }}
                >
                    <Typography
                        variant="h6"
                        component="div"
                        fontSize={"18px"}
                        fontWeight={500}
                        textTransform={"uppercase"}
                    >
                        {tableType}
                    </Typography>
                    <Box onClick={() => setIsOpen(false)} sx={{ cursor: "pointer" }}>
                        <AppMaterialIcons iconName="Close" />
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "80vw",
                        pt: "12px",
                        px: "16px",
                        pb: 0,
                        height: "calc(100% - 54px)",
                        overflow: "auto",
                    }}
                    role="presentation"
                >
                    <BroadcastLogsTable 
                        categoryForWhatsapp={categoryForWhatsapp}
                        type={tableType} 
                        header={tableData.header} 
                        keyToHeaderMap={tableData.keyToHeaderMap} 
                        colWidth={tableData.colWidth} 
                        tableDateRange={tableDateRange}
                        showDateRange={false}
                        tableHeight={"calc(100vh - 190px)"}
                    />
                </Box>
            </Drawer>
        )}


    </TrackingExpensesWrapper>)
}

export default HocBackdropLoader(TrackingExpenses);

const TrackingExpensesWrapper = styled.div`
&& {
    height: calc(100vh - 64px);
    .top_bar {
        max-height: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        padding: 0px 16px;
        background-color: ${theme.palette.default.white};
        box-shadow: ${theme.palette.default.boxShadow};
        position: sticky;
        top: 0;
        z-index: 1;
    }

    .data_container {
        height: calc(100% - 54px);
        padding: 0px 16px 0px 16px;
        .data_top_bar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding: 24px 9.6px 16px 16px;
            max-height: 64px;
            height: 64px;
        }

        .total_cost_summary {
            min-height: 234px;
        }

        .table_area {
            padding: 20px 24px;
            margin-top: 16px;
            background-color: ${theme.palette.default.white};
            box-shadow: ${theme.palette.default.boxShadow};
            border-radius: 4px;
            
                height: calc(100vh - 480px);
                min-height: 464px;
        }
    }
}
`;

const StyledAppInput = styled.div`
  & {
    display: flex;
    align-items: center;
    position: relative;
    input {
      border-radius: 4px;
      outline: none;
      border: 1px solid transparent;
      padding: 0 1.3rem 0 14px;
      font-family: ${theme.typography.fontFamily};
      border: 1px solid rgba(203, 205, 211, 1);
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      background-color: ${theme.palette.secondary.main};
      max-width: 300px;
      min-width: 300px;
      min-height: 28px;
      height: 28px;
    }
    svg {
      position: absolute;
      right: 10px;
      width: 15px;
    }
  }

  &::placeholder {
    color: ${theme.palette.default.grey};
  }
`;

// SMS =========
// Hide Direction column for SMS also from main insight
// Pass date range of track expende listing also remove the date range from details drawer
// add tooltip on cost boxes title ?


