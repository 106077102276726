import { Close } from "@material-ui/icons";
import { Backdrop, Box, IconButton, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import { Tooltip } from "@mui/material";
import Grow from "@mui/material/Grow";
import { ReactComponent as NotesIcon } from "../../../assets/images/icons/notes.svg";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import { FacebookCircularProgress } from "../../../socket/appComponents/UtilsComponents/AppNoActivityScreen";
import {
  getAllNotes,
  getTotalNoteCount,
} from "../../../Store/Slices/Contacts/contacts.selector";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import moment from "moment";
import AppNoDataLoader from "../../../AppComponents/AppNoDataLoader";
import { AppPagination } from "../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { theme } from "../../../Customization/Theme";
import AppPaginationComponent from "../../../AppComponents/Pagination/Pagination";

interface Props {
  actionDrawerChildComponent: any;
  setActionDrawerChildComponent: (value: any) => void;
}

const ChangeNotes = ({
  actionDrawerChildComponent,
  setActionDrawerChildComponent,
}: Props) => {
  const { showAlert } = useNotification();
  const notesPagination: any = useAppSelector(getTotalNoteCount);
  const dispatch = useAppDispatch();
  const [noData, setNoData] = useState(false);
  const notes: any = useAppSelector(getAllNotes);
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "10 per page", value: 10 },
    currentPage: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [userInput, setUserInput] = useState({
    context: "",
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
  });
  const getContactDetails = () => {
    let payload = {
      contact_id: actionDrawerChildComponent?.id,
    };

    Promise.allSettled([ContactsNetworkService.getBasicInfo(payload)])
      .then((results) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const getNotes = () => {
    let data = {
      contact_id: actionDrawerChildComponent?.id,
      limit: localValues.itemsPerPage.value,
      offset: localValues.currentPage,
    };
    setLoading(true);
    dispatch(ContactsActions.getAllNotes(data)).finally(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    getContactDetails();
  }, []);
  useEffect(() => {
    if (notes.length === 0) {
      setNoData(true);
    }
  }, [notes]);
  useEffect(() => {
    getNotes();
  }, [localValues]);

  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  const handleSubmitBtn = (values: any, submitProps: any) => {
    let payload: any = {
      id: actionDrawerChildComponent?.id,
      data: {
        lifecycle_stage: values?.lifecycle_stage?.id,
        lifecycle_stage_status: values?.lifecycle_status?.id,
        lost_reason: values?.lost_reason?.id,
        other_lost_reason: values?.other_lost_reason,
      },
    };

    setLoading(true);
    ContactsNetworkService.editContactsByID(payload)
      .then((res: any) => {
        showAlert(res?.data?.message, "success");
        submitProps?.resetForm();
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Contact not update", "error");
      })
      .finally(() => {
        if (values?.context?.length > 0) {
          const notesPayload = {
            contact_id: actionDrawerChildComponent?.id,
            context: values?.context,
          };
          ContactsNetworkService.addNoteAsPerId(notesPayload).finally(() => {
            setLoading(false);
            submitProps.setSubmitting(false);
            getNotes();
          });
        }
      });
  };
  const truncateString = (str: string) => {
    return str.length > 50 ? str.substring(0, 50) + "..." : str;
  };
  console.log(localValues, "localValues");
  return (
    <>
      {loading ? (
        <Backdrop open={loading} style={{ zIndex: 9999999 }}>
          <FacebookCircularProgress />
        </Backdrop>
      ) : null}
      <StyledDrawerHeader>
        <p className="title">{userInput.first_name} Notes</p>
        <IconButton
          onClick={(e: any) => setActionDrawerChildComponent(null)}
          title="close"
        >
          <Close />
        </IconButton>
      </StyledDrawerHeader>
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        divStyle={{
          flex: 1,
        }}
      >
        <StyledDrawerWrapper>
          <AppFormTextArea
            name="context"
            placeholder="Add Notes"
            label="Notes"
            rows={4}
          />
          {notes.length !== 0 && (
            <>
              <div className="leads-wrapper">
                {notes.length !== 0 &&
                  notes.map((res: any) => {
                    return (
                      <div className="notes-details">
                        <div className="notes-icon">
                          <NotesIcon />
                        </div>
                        <div className="note-avtar">
                          <p className="name">
                            Created By:{" "}
                            <strong>{res.created_by_username}</strong>
                          </p>
                          <small className="updated-time">
                            {moment(res.updated_at).format(
                              "DD MMM , YYYY hh:mm A ",
                            )}
                          </small>
                        </div>
                        <div className="notes-text">
                          <Tooltip
                            arrow
                            enterDelay={1}
                            leaveDelay={0}
                            TransitionComponent={Grow}
                            placement="top"
                            title={res.context}
                          >
                            <p>{truncateString(res.context)}</p>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })}
                {notes.length === 0 && noData ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      padding: "4rem 2rem 2rem 2rem",
                      boxShadow: "none important",
                      backgroundColor: "white",
                    }}
                  >
                    <Paper
                      sx={{
                        width: "40%",
                        mb: 2,
                        boxShadow: "none !important",
                        backgroundColor: "transparent",
                      }}
                    >
                      <AppNoDataLoader />
                    </Paper>
                  </Box>
                ) : null}
              </div>
              <AppPaginationComponent
                totalCount={notesPagination.totalRecords}
                handlePageChange={handlePageChange}
                currentPage={notesPagination?.currentPage}
                totalPages={notesPagination.totalPages}
                rowsPerPage={localValues?.itemsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </StyledDrawerWrapper>
        <StyledDrawerFooter>
          <AppButton
            variant="grey"
            onClick={(e: any) => setActionDrawerChildComponent(null)}
          >
            Cancel
          </AppButton>
          <AppSubmitButton
            title="Add Notes"
            checkIsValidProperty={false}
          ></AppSubmitButton>
        </StyledDrawerFooter>
      </AppForm>
    </>
  );
};

export default ChangeNotes;

const StyledDrawerWrapper = styled.div`
  && {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1;
    padding: 1rem;
    .leads-wrapper {
      max-height: calc(100vh - 400px);
      overflow: auto;
    }
    .basicInfoBox {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem 0;
      .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        .label {
          font-weight: 500;
          font-size: 15px;
        }
        .value {
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
    .notes-details {
      border: 1px solid ${theme.palette.default.border};
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: 15px;
      gap: 15px;
      border-radius: 10px;
    }
    .notes-text {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-left: 30px;
      align-items: center;
    }
    .notes-text p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7e8392;
      word-break: break-word;
    }

    .notes-icon {
      padding-right: 15px;
      border-right: 1px solid ${theme.palette.default.border};
      display: flex;
      align-items: center;
    }
    .note-avtar {
      gap: 8px;
      min-width: 220px;
    }
  }
`;

const StyledDrawerFooter = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
const StyledDrawerHeader = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
const ContactNotesWrapper = styled.div`
  .contact-notes {
    padding: 16px;
  }
  .notes {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px 0px 8px;
    margin-bottom: 16px;
  }
  .notes button {
    min-height: 37px;
    height: 37px;
  }
  .notes h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
  }

  .note-edit-delete {
    display: flex;
    gap: 21px;
    button {
      padding: 0px;
      width: 40px;
      height: 40px;
    }
  }
`;
