import React, { Children } from "react";
import styled from "styled-components";

interface props {
  divStyle?: any;
  children?: any;
}
const StyledCard: React.FC<props> = ({ children, divStyle }) => {
  return <Blankcard style={divStyle}>{children}</Blankcard>;
};

export default StyledCard;
const Blankcard = styled.div`
  && {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    padding: 10px;
    width: 100%;
    margin-bottom: 0.7rem;
  }
`;
