import { RootState } from "../../../index";

export const getListProperties = (state: RootState) => state?.contactProperties?.data?.contactPropertiesListData;
export const getDeleteModelAction = (state: RootState) => state?.contactProperties?.data?.DeleteModelAction;
export const getHideModelAction = (state: RootState) => state?.contactProperties?.data?.HideModelAction;
export const getChangeSectionAction = (state: RootState) => state?.contactProperties?.data?.ChangeSectionModelAction;
export const getParentselectedId =(state: RootState) => state?.contactProperties?.data?.parentSectionId;
export const getfieldtype =(state: RootState) => state?.contactProperties?.data?.type;
export const getSelectedSectionData =(state: RootState) => state?.contactProperties?.data?.selectedSectionData;
export const getAddUpdateSectionModel =(state: RootState) => state?.contactProperties?.data?.addUpdateSectionModel;
export const getAllSectionDetails =(state: RootState) => state?.contactProperties?.data?.allSectionDetails;
export const getSelectedFieldData =(state: RootState) => state?.contactProperties?.data?.selectedFieldData;
export const getAllFieldOptions =(state: RootState) => state?.contactProperties?.data?.allFieldOptions;
export const getAllHiddenField =(state: RootState) => state?.contactProperties?.data?.hiddenFields;
export const getLevel =(state: RootState) => state?.contactProperties?.data?.Level;
// Source Listing
export const DependencyPropertiesListing = (state: RootState) => {
    return state?.contactProperties?.data?.DependecyPropertiesListing;
}

export const PrimaryOptions = (state: RootState) => {
    return state?.contactProperties?.data?.PrimaryOptions;
}

export const DependantOptions = (state: RootState) => {
    return state?.contactProperties?.data?.DependantOptions;
}

export const FieldsMappingObject = (state: RootState) => {
    return state?.contactProperties?.data?.dependencyObject;
};

export const UpdatedSlectOptionID = (state: RootState) => {
    return state?.contactProperties?.data?.SelectedOptionID;
}

export const PrimaryOptionsSelectFields = (state: RootState) => {
    return state?.contactProperties?.data?.PrimaryOptionsSelectFields;
}

export const MappingObjectByID = (state: RootState) => {
    return state?.contactProperties?.data?.MappingObjectByID;
}

export const GetDependencyList = (state: RootState) => {
    return state?.contactProperties?.data?.DependencyList;
}

export const GetDependencyListCount = (state: RootState) => {
    return state?.contactProperties?.data?.DependencyListCount;
}

export const GetContactPropertices = (state: RootState) => {
    return state?.contactProperties?.data?.ContactPropertiesList;
}


