import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { HTMLProps } from "react";
import { theme } from "../../Customization/Theme";
import { MultiListWrapper } from "../../Components/Leads/LeadsStyles";

const columnHelper = createColumnHelper<any>();

export const renderColumnData = (info: any, elm: any) => {
  return (
    <span
      className="long-word"
      title={info?.cell?.row?.original[elm?.id] || "-"}
    >
      <span>{info?.cell?.row?.original[elm?.id] || "-"}</span>
    </span>
  );
};

export const CreateCoulumns = (
  checkboxAccessFlag: boolean = true,
  dynamicsColumn: any,
  setDynamicsColumns: any,
  fileDownload: any,
) => {
  let temp: any = [];
  let idColumn: any = null; // Variable to store the column with "id" header
  dynamicsColumn?.forEach((elm: any) => {
    let colData;
    if (elm?.id === "id") {
      idColumn = columnHelper.accessor("id", {
        id: "select",
        size: 10,
        maxSize: 10,
        header: ({ table }) =>
          checkboxAccessFlag ? (
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ) : null,
        cell: ({ row }) =>
          checkboxAccessFlag ? (
            <IndeterminateCheckbox
              {...{
                checked: row?.getIsSelected(),
                disabled: !row?.getCanSelect(),
                indeterminate: row?.getIsSomeSelected(),
                onChange: row?.getToggleSelectedHandler(),
              }}
            />
          ) : null,
        footer: (info: any) => info.column.id,
      });
    }
    if (elm?.id !== "id" && elm?.label !== "Name") {
      colData = columnHelper.accessor(elm?.id, {
        header: elm.label,
        id: elm.id,
        cell: (info: any) => renderColumnData(info, elm),
        size: 240,
        minSize: 240,
      });
      temp.push(colData);
    }
    if (elm?.label === "Name") {
      colData = columnHelper.accessor(elm?.id, {
        header: elm.label,
        id: elm.id,
        cell: (info: any) => {
          const original = info?.cell?.row?.original;
          return (
            <div
              className="avatar long-word"
              style={{
                display: "flex",
                fontSize: "14px",
                gap: "1rem",
                alignItems: "center",
                fontWeight: "400",
                cursor: "pointer",
                color: theme.palette.primary.main,
              }}
              onClick={() => {
                fileDownload(original?.id);
              }}
            >
              {original?.file_name}
            </div>
          );
        },
        size: 240,
        minSize: 240,
      });
      temp.push(colData);
    }
  });
  temp.unshift(idColumn);
  setDynamicsColumns(temp);
};

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className="tanstack-check"
      {...rest}
      style={{ width: "18px", height: "18px" }}
    />
  );
}

export const RenderArrayValues = (valuesArray: Array<any>) => {
  if (valuesArray.length > 0) {
    return (
      <MultiListWrapper>
        <div className="items-list">
          <span title={valuesArray[0]} className="tagShape">
            {valuesArray[0]}
          </span>
          {valuesArray.length > 1 && (
            <div className="hoverWrapper">
              <span title={valuesArray[0]} className="tagShape viewMore">
                + {valuesArray.length - 1}
              </span>
              <div className="hover-card-container">
                <div className="hover-card-title">Lists</div>
                <div className="hover-card-items-list-container">
                  <div className="hover-card-items-list">
                    {valuesArray.map((ele: string, index: number) => {
                      return (
                        <span title={ele} className="tagShape">
                          {ele}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </MultiListWrapper>
    );
  }
  return null;
};
