import styled from "styled-components";
import AppModel from "../../../Hooks/useModel/AppModel";
import { theme } from "../../../Customization/Theme";
import AppButton from "../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import Danger from "../../../assets/images/danger-live.svg";
import { ApplicationFormNetworkActions } from "../../../Store/Slices/ApplicationsForm/applicationForm.actions";
import { useAppDispatch } from "../../../Store";
import { useNotification } from "../../../Hooks/useNotification/useNotification";

interface ConfirmationModalProps {
  isShowingConfirmationDraftModal: any;
  toggleConfirmationDraftModal: any;
  applicationFomrRow: any;
  getApplicationsFormsTableData: any;
}


const ConfirmationDraftModal: React.FC<ConfirmationModalProps> = ({ 
  isShowingConfirmationDraftModal,
  toggleConfirmationDraftModal,
  applicationFomrRow,
  getApplicationsFormsTableData
 }) => {

  const dispatch = useAppDispatch();
  let { showAlert } = useNotification();

  const allowFormResubmission = () => {
    dispatch(ApplicationFormNetworkActions.changeStatusToDraft(applicationFomrRow?.application_response_id)).then((res: any) => {
      if (res.payload.status === 200) {
        showAlert("Resubmission has been allowed", "success");
        toggleConfirmationDraftModal();
        getApplicationsFormsTableData();
      }else {
        showAlert("Something went wrong", "error");
      }
    }).catch((err) => {
        showAlert("Something went wrong", "error");
    })
    ;
  }

    return (<AppModel
      isShowing={isShowingConfirmationDraftModal}
      onClose={() => toggleConfirmationDraftModal()}
        divStyle={{
            padding: 0,
        }}
        PaperProps={{
            style: {
                boxShadow: "none",
                width: "497px",
            },
        }}
    >
        <ConfirmDelete>
            <div className="header">
                <div className="header-left">
                    <div className="danger">
                        <img src={Danger} alt="" />
                    </div>
                    <div className="text">
                        <h4>
                            Allow Resubmission
                        </h4>
              <p>Are you sure, you want to allow <span className="student">{applicationFomrRow?.applicant_name || "user"}</span> to re-submit this form?</p>
                    </div>
                </div>
                <div className="header-right">
            <span onClick={() => toggleConfirmationDraftModal()}>
                        <AppMaterialIcons iconName="close" />
                    </span>
                </div>
            </div>
            <div className="footer">
          <AppButton variant="grey" onClick={() => toggleConfirmationDraftModal()}>
                    Cancel
                </AppButton>
                <AppButton
            variant="success"
              onClick={() => allowFormResubmission()}
                >
                    Allow
                </AppButton>
            </div>
        </ConfirmDelete>
    </AppModel>)
};

export default ConfirmationDraftModal;

const ConfirmDelete = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.875rem;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    .student {
      color: ${theme.palette.default.black};
    }
    .header-left {
      display: flex;
      gap: 0.875rem;
      .danger {
        padding-top: 4px;
        img {
          width: 1.2rem;
        }
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;
  }
`;
