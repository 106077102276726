// vendors
import React from "react";
import Paper from "@mui/material/Paper";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import FormHelperText from "@mui/material/FormHelperText";

interface InputProps extends InputBaseProps{
  helperText?: React.ReactElement | string | false;
  max?: number;
}

const Input = (props: InputProps) => {
  const { placeholder, onChange, error, helperText, value, max } = props;
  return (
    <>
      <Paper
        elevation={0}
        component="form"
        sx={{
          ...(props.fullWidth && { flex: 1}),
          display: "flex",
          alignItems: "center",
          backgroundColor: error ? "#FFE7D9" : "#F5F6F8",
        }}
      >
        <InputBase
          {...props}
          size="medium"
          sx={{ flex: 1 }}
          inputProps={{
            style: {
              color: "#7E8392",
              fontSize: "14px",
              lineHeight: "21px",
              fontWeight: 400,
              padding: "10px 12px",
            },
            inputProps: {
              max: max,
            }

          }}
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          value={value}
        />
      </Paper>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </>
  );
};
export default Input;
