import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Accordion,
  AccordionSummary,
  List,
  ListItemButton,
  ListItemText,
  MenuList,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppIcon from "../../../AppComponents/AppIcon";
import { device } from "../../../Customization/BreakPoints";
import { theme } from "../../../Customization/Theme";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { formatString, getModifiedRegexesForAccordionOptions } from "../../../Utils";

interface Props {
  Options: any;
}

const CustomizeSidebar: React.FC<Props> = ({ Options }) => {
  const navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);
  const location = useLocation();

  const [width, setWidth] = useState<number>(0);
  const [screenWidth, setScreenWidth] = useState<number>(1600);

  var [selectedIndex, setSelectedIndex] = useState<any>(0);
  let [selectedAccordion, setSelectedAccordion] = useState<any>(0);

  // Memoize Options array
  const memoizedOptions = React.useMemo(() => {
    return (
      Options?.filter((curElem: any) => {
        return curElem?.isAvailable !== false && curElem?.children?.length > 0;
      }) || []
    );
  }, [Options])

  const getCurrentPathAfterRefresh = () => {
    if (Options?.length === 0) return;
    const MenuOptionsWithRegexes = getModifiedRegexesForAccordionOptions(Options);

    let SelectedMenu = MenuOptionsWithRegexes.find((o: any, index: number) => {
      return o.urlRegex.test(location.pathname) || location.pathname.includes(o?.value);
    });
    setSelectedIndex(SelectedMenu?.value);
    setSelectedAccordion(SelectedMenu?.categoryIndex);
  };

  useEffect(() => {
    getCurrentPathAfterRefresh();
  }, [Options,location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAccordionClick = (item: any, index: number) => {
    selectedIndex = item?.children?.[0]?.value;
    setSelectedIndex(selectedIndex);
    setSelectedAccordion(index);
    handleRedirect(item?.children?.[0]?.url);
  };

  const handleClickListItem = (item: any, child:any,index: any) => {
    selectedIndex = child?.value;
    setSelectedIndex(selectedIndex);
    handleRedirect(child?.url);
  };

  const handleRedirect = (subUrl: string) => {
    const url = formatString(subUrl, [currentBot.bot_id]);
    navigate(url);
  };
  
  const toggleMenu = (): void => {
    if (width === 0) {
      setWidth(300);
    } else {
      setWidth(0);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setWidth(screenWidth < 1599.98 ? 0 : 300);
  }, [screenWidth]);

  return (
    <SidebarWrap
      id="sidebar"
      style={{ width: `${width}px`, transition: "width 0.5s" }}
    >
      <div className="sideBarToggleBtn" onClick={toggleMenu}>
        {width === 0 ? (
          <NavigateNextIcon style={{ fontSize: "1.7rem" }} />
        ) : (
          <NavigateBeforeIcon style={{ fontSize: "1.7rem" }} />
        )}
      </div>
      <CustomList>
        {memoizedOptions &&
          memoizedOptions?.length > 0 &&
          memoizedOptions.map((item: any, index: number) => {
            const IS_CATEGORY_AVAILABLE = item?.channels?.includes(currentBot?.channel ?? "Web Bot");
            if (!IS_CATEGORY_AVAILABLE) return null;
            if (!item?.isAvailable) return null;
            return (
              <Accordion
                key={index}
                expanded={selectedAccordion === index ? true : false}
              >
                <AccordionSummary
                  expandIcon={
                    <GridExpandMoreIcon
                      sx={{
                        color:
                          selectedAccordion === index
                            ? theme.palette.primary.main
                            : theme.palette.default.darkGrey,
                      }}
                    />
                  }
                  onClick={() => handleAccordionClick(item, index)}
                  sx={{
                    color:
                      selectedAccordion === index
                        ? theme.palette.primary.main
                        : theme.palette.default.darkGrey,
                  }}
                  disabled={item?.isAvailable ? false : true}
                >
                  {item.label}
                </AccordionSummary>

                <MenuList
                  variant="selectedMenu"
                  dense
                  style={{ marginBottom: "0.2rem" }}
                >
                {item?.children.length > 0 &&
                  item.children.map((curElem: any, index2: number) => {

                    const IS_ITEM_AVAILABLE = curElem?.channels?.includes(currentBot?.channel ?? "Web Bot");
                    if (!IS_ITEM_AVAILABLE) return null;
                    if (!curElem?.isAvailable) return null;
                    
                    return (
                      <ListItemButton
                        key={index2}
                          selected={selectedIndex === curElem.value}
                          onClick={() => {
                            handleClickListItem(item, curElem,index2);
                          }}
                          sx={{
                            color:
                              selectedIndex === curElem.value
                                ? theme.palette.primary.main
                                : theme.palette.default.darkGrey,
                          }}
                          disableRipple
                        >
                          <ListItemText>
                            <AppIcon
                              title={curElem?.icon}
                              size="20px"
                              color={
                                selectedIndex === curElem.value
                                  ? theme.palette.primary.main
                                  : theme.palette.default.darkGrey
                              }
                            />
                            {curElem?.label}
                          </ListItemText>
                        </ListItemButton>
                    );
                  })}
                  </MenuList>
              </Accordion>
            );
          })}
      </CustomList>
    </SidebarWrap>
  );
};

export default CustomizeSidebar;

const SidebarWrap = styled.div`
  && {
    position: relative;
    background-color: #ffffff;
    display: flex;
    /* min-width: 300px; */
    margin-right: 1px;
    overflow: none;
    scroll-behavior: none;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    padding: 0.5rem 0.5rem;
    .MuiAccordion-root {
      box-shadow: none !important;
      margin-bottom: 0.5rem;

      &::before {
        background-color: transparent !important;
      }
      &::after {
        background-color: transparent !important;
      }

      .MuiAccordionSummary-root {
        /* background-color: green; */
        padding: 1rem 1rem;
        min-height: 2.5rem;
        height: 2.5rem;
        max-height: 2.5rem;

        font-family: ${theme.typography.fontFamily};
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.5rem;
        /* color: #5b73e8; */
      }

      .MuiList-root {
        /* background-color: green; */
        padding: 0px 0;

        .MuiMenuItem-root {
          padding: 0px 0;
          /* padding: 8px 16px; */
          /* margin-bottom: 0.2rem; */
          font-family: ${theme.typography.fontFamily};
          font-weight: 400;
          font-size: 0.9rem;
          line-height: 1.2rem;
        }

        .Mui-selected {
          /* padding: 6px 16px; */
          background-color: rgba(54, 84, 227, 0.08);
        }

        .MuiListItemText-root {
          .MuiTypography-root {
            gap: 0.5rem;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    //menu list

    @media ${device.laptop} {
      width: 60px;
    }

    .sideBarToggleBtn {
      display: none;
    }

    @media (max-width: 1599.98px) {
      .sideBarToggleBtn {
        position: absolute;
        z-index: 1;
        right: -1.2rem;
        top: 50%;
        /* border: 1px solid; */
        cursor: pointer;
        padding: 0.3rem;
        border-radius: 50%;
        display: flex;
        box-shadow: 0 4px 4px rgb(0 0 0 / 30%), 0 0 4px rgb(0 0 0 / 20%);
        background-color: #ffffff;
        color: rgb(126, 131, 146);
      }
    }
  }
`;

const CustomList = styled(List)`
  && {
    padding-top: 0.5rem;
    width: 100%;
    overflow-y: auto;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: nStyledTextone;
  }
`;

const StyledText = styled.p`
  && {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    @media ${device.laptop} {
      display: none;
    }
  }
`;
