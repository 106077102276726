import styled from "styled-components";
import { TablePagination } from "@mui/material";
import { theme } from "../../../../Customization/Theme";

export const BroadcastMainWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    padding: 1rem;
    // border: 1px solid;
  }
`;

export const BroadcastMainWrapperTitle = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const BroadcastHistoryTableWrapper = styled.div`
  & {
    width: 100%;
    min-width: 1410px;
    background-color: ${theme.palette.default.white};
    padding: 0px 16px 16px 16px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .table-header {
      .table-row {
        padding: 16px 0;
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
      }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:last-child {
      //     border: none;
      //   }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        font-weight: 500;
        border-right: 1px solid rgba(126, 131, 146, 0.5);
      }
    }
    // Body
    .table-body {
      overflow: auto;
      height: calc(100vh - 367px);
      .table-row {
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
      }
    }
  }
`;

export const BroadcastLogTable = styled.div`
  & {
    width: 100%;
    border-left: 1px solid ${theme.palette.default.border};
    border-right: 1px solid ${theme.palette.default.border};
    border-bottom: 1px solid ${theme.palette.default.border};
    background-color: ${theme.palette.default.white};
    // padding: 0px 16px 16px 16px;
    > div {
      overflow: auto;
      height: ${(props: any) =>
        props.length ? "calc(100vh - 317px)" : "calc(100vh - 277px)"};
    }
    .table-header {
      position: sticky;
      top: 0;
      .table-row {
        display: flex;
        align-items: center;
      }
      // .date {
      //   max-width: 220px;
      //   min-width: 220px;
      //   // border: 1px solid;
      // }
      // .from {
      //   max-width: 250px;
      //   min-width: 250px;
      //   // border: 1px solid;
      // }
      // .to {
      //   max-width: 300px;
      //   min-width: 300px;
      //   // border: 1px solid;
      // }
      // .subject {
      //   max-width: 420px;
      //   min-width: 420px;
      // }
      // .status {
      //   max-width: 150px;
      //   min-width: 150px;
      // }
      // .error_reason {
      //   max-width: 200px;
      //   min-width: 200px;
      // }
      // .price {
      //   max-width: 150px;
      //   min-width: 150px;
      // }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 140px;
      //     min-width: 140px;
      //   }
      //   .table-row > div:nth-child(3) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(4) {
      //     max-width: 420px;
      //     min-width: 420px;
      //   }
      //   .table-row > div:last-child {
      //     border: none;
      //   }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        padding: 13px 20px;
        background-color: ${theme.palette.default.white};
        p {
          font-size: 13px;
          // padding: 0 16px;
          // border-left: 1px solid rgba(126, 131, 146, 0.5);
        }
        &:first-child {
          p {
            // border: none;
          }
        }
      }
    }
    .table-body {
      .table-row {
        display: flex;
        justify-content: center;
        // padding: 10px 0;
        color: rgb(126, 131, 146, 1.5);
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      // .date {
      //   max-width: 220px;
      //   min-width: 220px;
      //   // border: 1px solid;
      // }
      // .from {
      //   max-width: 250px;
      //   min-width: 250px;
      //   // border: 1px solid;
      // }
      // .to {
      //   max-width: 300px;
      //   min-width: 300px;
      //   // border: 1px solid;
      // }
      // .subject {
      //   max-width: 420px;
      //   min-width: 420px;
      //   p {
      //     white-space: nowrap;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //   }
      // }
      // .status {
      //   max-width: 150px;
      //   min-width: 150px;
      // }
      // .error_reason {
      //   max-width: 200px;
      //   min-width: 200px;
      //   p {
      //     white-space: nowrap;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //   }
      // }
      // .price {
      //   max-width: 150px;
      //   min-width: 150px;
      // }
      // .status {
      //   max-width: 150px;
      //   min-width: 150px;
      // }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 13px 20px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
        P {
          font-size: 13px;
        }
      }
    }
  }
`;

export const BroadcastReportLogTable = styled.div`
  & {
    width: 100%;
    background-color: ${theme.palette.default.white};
    // padding: 0px 16px 16px 16px;
    > div {
      overflow: auto;
      height: calc(100vh - 450px);
    }
    .table-header {
      position: sticky;
      top: 0;
      .table-row {
        display: flex;
        align-items: center;
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
      }
      .report_date {
        max-width: 270px;
        min-width: 270px;
        // border: 1px solid;
      }
      .download_report {
        max-width: 250px;
        min-width: 250px;
      }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      //   .table-row > div:first-child {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(2) {
      //     max-width: 140px;
      //     min-width: 140px;
      //   }
      //   .table-row > div:nth-child(3) {
      //     max-width: 220px;
      //     min-width: 220px;
      //   }
      //   .table-row > div:nth-child(4) {
      //     max-width: 420px;
      //     min-width: 420px;
      //   }
      //   .table-row > div:last-child {
      //     border: none;
      //   }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        padding: 16px 0;
        background-color: ${theme.palette.default.white};
        p {
          padding: 0 16px;
          border-left: 1px solid rgba(126, 131, 146, 0.5);
        }
        &:first-child {
          p {
            border: none;
          }
        }
      }
    }
    .table-body {
      .table-row {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        color: rgb(126, 131, 146, 1.5);
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .report_date {
        max-width: 270px;
        min-width: 270px;
        // border: 1px solid;
      }
      .download_report {
        max-width: 250px;
        min-width: 250px;
      }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
    }
  }
`;

export const AppPagination = styled(TablePagination)`
  & {
    padding: 8px 0 !important;
    .MuiSelect-select {
      border: 1px solid ${theme.palette.default.grey};
      border-radius: 6px;
    }

    p {
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-select {
      text-align: center !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-actions > button:last-child {
      margin-left: 8px;
    }
    .MuiTablePagination-actions > button {
      border: 1px solid ${theme.palette.default.grey} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.darkGrey};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
    .MuiTablePagination-actions .Mui-disabled {
      border: 1px solid ${theme.palette.default.RightBorderDivder} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.RightBorderDivder};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
  }
`;

export const BroadcastBodyWrapper = styled.div`
  & {
    // padding: 1rem;
    /* background-color: #fff; */
    .fields-container {
      border: 1px solid ${theme.palette.default.border};
      border-bottom: none;
      // border-bottom: 1px solid ${theme.palette.default.border};
      background-color: ${theme.palette.default.white};
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      padding: 0 20px;
      // padding: 12px 6px;
      gap: 0.4rem;
      .sms_left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .create-table-select {
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        .create-table-select {
          z-index: 2;
          .css-b62m3t-container {
            width: 220px;
            height: 40px;
          }
        }
      }
      .sms_right {
        display: flex;
        align-items: center;

        .sms_select {
          .sms-filter {
          }
        }
      }
    }
    .setting-page-mainboxtopbar{
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap:20px;
      .setting-page__menu:last-child {
        margin-left: auto;
      }
      > button {
        margin-left: 10px;
      }
      & .MuiMenuItem-root {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          p {
              font-weight: 400;
              margin-bottom: 0.4rem;
              font-size: 0.875rem;
              color: ${theme.palette.default.black};
          }
    }
  }
}
`;

export const BroadcastFiletr = styled.div`
  & {
    background-color: ${theme.palette.default.white};
    padding: 1rem;
    .filter-left {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
`;
