// vendors
import { styled, alpha } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";

interface IListItemProps extends BoxProps {
  size: "xs" | "sm" | "md" | "lg" | "xl";
  fullWidth: boolean;
  fullScreen:  boolean;
}

export const ContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== "size" && prop !== "fullWidth";
  },
})<IListItemProps>(({ size, fullWidth, fullScreen, theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: size === "xs" ? '420px' : `${theme.breakpoints.values[size]}${theme.breakpoints.unit}`,
  ...(fullWidth && {
    width: 'calc(100% - 64px)',
  }),
  ...(fullScreen && {
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: 'none',
    borderRadius: 0,
  }),
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 4px 16px
    ${alpha(theme.palette.common.black, 0.08)}`,
  borderRadius: '8px',
}))

export const HeaderStyled = styled(Stack)`
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary.main};
  width: 100%;
`;

export const ContentWrapperStyled = styled(Box)`
  padding: 8px 16px;
`;

export const FooterStyled = styled(Stack)`
  padding: 8px  16px;
  border-top: 1px solid ${(props) => props.theme.palette.secondary.main};
`;
