import {
  Amount,
  LivePlan,
  LivePlanContainer,
  SubscriptionInfo,
  PlanCards,
  Title,
  ActivePlan,
  Button,
  StyledWalletDialog,
} from "./style";
import Subscription from "../../assets/images/curved.svg";
import PremiumCurved from "../../assets/images/premiumCurved.svg";
import { ReactComponent as FreePlan } from "../../assets/images/icons/freePlan.svg";
import { ReactComponent as BasicPlan } from "../../assets/images/icons/basicPlan.svg";
import { ReactComponent as PremiumPlan } from "../../assets/images/icons/premiumPlan.svg";
import { ReactComponent as MostPopuler } from "../../assets/images/mostPopuler.svg";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setPlanData } from "../../Store/Slices/Subscriptions/subscriptions.slice";
import { useNavigate } from "react-router-dom";
import { SubscriptionsNetworkService } from "../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import { getSubscriptionData } from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import React, { useEffect, useState } from "react";
import { SubscriptionActions } from "../../Store/Slices/Subscriptions/subscriptions.actions";
import { useAppDispatch } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { StyledDialog } from "../../Customization/CommonElements";
import AppButton from "../../AppComponents/AppButton";
import { TransitionProps } from "@mui/material/transitions";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import CloseIcon from "@mui/icons-material/Close";
import { useNotification } from "../../Hooks/useNotification/useNotification";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
interface MenuProps extends HocBackdropLoaderProps {
  list: any;
}

const PlanCard: React.FC<MenuProps> = ({ list, setLoading }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const getActiveSubscriptionData = useSelector(getSubscriptionData);
  const { data: authData } = useSelector(useAuthStore);
  const { userConfig } = useSelector(useAuthStore);
  const glocalWindow: any = window;
  const [check, setCheck] = useState(false);
  const [activeData, setActiveData] = useState<any>();
  useEffect(() => {
    // get current subscripation plan details
    dispatch(
      SubscriptionActions.getSubscriptionData(
        userConfig?.payload?.subscription_id
      )
    );
    setActiveData({});
  }, [userConfig?.payload?.subscription_id]);

  // Update Subscription API
  const updateSubscription = (data?: any) => {
    setLoading(true);
    setCheck(false);
    // if (data?.plan_name === "Free") {
    //     let planData =
    //     {
    //         "business_id": userConfig?.payload?.business_id,
    //         "customer_id": userConfig?.payload?.customer_id,
    //         "subscription_id": getActiveSubscriptionData?.subscription_id
    //     }
    //     SubscriptionsNetworkService.freePlanSuccessCallback(
    //         planData,
    //     )
    //         .then((res: any) => {
    //             if (res?.data?.status) {
    //                 navigate("/subscription/subscription-plans/", { replace: true })
    //                 window.location.reload()
    //             }
    //         })
    //         .catch((err: any) => {
    //             setLoading(false);
    //         })
    //         .finally(() => {
    //             setLoading(false);
    //         });
    // } else {
    let planData = {
      plan: {
        plan_code: data
          ? data.plan_code || activeData?.plan_code
          : activeData?.plan_code,
        quantity: 1,
      },
      redirect_url: "/subscription/success",
      customer_id: userConfig?.payload?.customer_id,
      business_id: userConfig?.payload?.business_id,
    };
    SubscriptionsNetworkService.updateSubscriptionData(
      planData,
      getActiveSubscriptionData?.subscription_id
    )
      .then((res: any) => {
        if (res?.data?.url) {
          window.open(res?.data?.url, "_self");
        } else {
          showAlert(res?.data?.message || "Something went wrong", "success");
        }
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    //}
  };

  // Set Select Plan Details
  const saveSelectPlanDeatils = (data: any, selectIndex: any) => {
    // dispatch select plan details
    let activeIndex = list.findIndex(
      (x: any) => x.plan_name == getActiveSubscriptionData?.name
    );
    setActiveData(data);
    if (data?.price < getActiveSubscriptionData?.amount) {
      setCheck(true);
      return;
    } else {
      updateSubscription(data);
    }
  };
  return (
    <PlanCards container padding={0}>
      {list.length === 0 ? (
        <div
          style={{
            textAlign: "center",
            height: "223px",
            margin: "14rem 45rem",
          }}
        >
          <CircularProgress />{" "}
        </div>
      ) : (
        list.map((res: any, index: any) => {
          return (
            <Grid xs={3} spacing={2}>
              <ActivePlan width={"314px"} height={"72rem"} color={"#ffff"}>
                <LivePlanContainer
                  style={{
                    width: "310px",
                    height: "163px",
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    background: `url(${
                      res.plan_name != "Premium" ? Subscription : PremiumCurved
                    })`,
                    display: "grid",
                  }}
                >
                  {res.plan_name == getActiveSubscriptionData?.name &&
                  userConfig?.payload?.subscription_active &&
                  getActiveSubscriptionData?.interval_unit ===
                    res?.interval_unit ? (
                    <LivePlan
                      style={{
                        backgroundColor: "white",
                        color: "#FFA842",
                        margin: "-1.5rem 1rem 1rem",
                        border: "1px solid #FFA842",
                      }}
                    >
                      Live Plan
                    </LivePlan>
                  ) : res.plan_name == "Premium" ? (
                    <MostPopuler style={{ margin: "-0.2rem -2rem 1rem" }} />
                  ) : (
                    <div style={{ margin: "1.5rem 1rem 1rem" }}></div>
                  )}
                  <Title fontSize={30}>{res?.plan_name}</Title>
                  {res.plan_name == "Free" ? (
                    <FreePlan style={{ margin: "0rem 2rem" }} />
                  ) : null}
                  {res.plan_name == "Basic" ? (
                    <BasicPlan style={{ margin: "0rem 2rem" }} />
                  ) : null}
                  {res.plan_name == "Ultimate" || res.plan_name == "Starter" ? (
                    <PremiumPlan style={{ margin: "0rem 2rem" }} />
                  ) : null}
                </LivePlanContainer>
                <LivePlanContainer>
                  {" "}
                  <Amount>
                    ₹ {res?.price}{" "}
                    <Title fontSize={16}>/{res?.interval_unit}</Title>
                  </Amount>{" "}
                </LivePlanContainer>
                {/* Subscription Addon List  */}
                <div style={{ maxHeight: "50rem", overflow: "scroll", height: "calc(92vh - 100px)" }}>
                  {res?.config?.map((res: any) => {
                    return res.status && res?.is_visible ? (
                      <LivePlanContainer>
                        <CheckCircleOutlinedIcon color="success" />
                        <SubscriptionInfo align={"left"} width={"200px"}>
                          {res?.limit !== 0 ? res?.limit : ""} {res?.name}
                        </SubscriptionInfo>
                      </LivePlanContainer>
                    ) : null;
                  })}
                </div>
                {res.plan_name == getActiveSubscriptionData?.name &&
                userConfig?.payload?.subscription_active &&
                getActiveSubscriptionData?.interval_unit ===
                  res?.interval_unit ? (
                  <div style={{ margin: "4.3rem 4rem" }}></div>
                ) : (
                  <>
                    {res?.can_upgrade ? (
                      <Button
                        minWidth={"-webkit-fill-available"}
                        margin={"1rem"}
                        onClick={() => saveSelectPlanDeatils(res, index)}
                      >
                        Select
                      </Button>
                    ) : null}{" "}
                  </>
                )}
              </ActivePlan>
            </Grid>
          );
        })
      )}
      {/* Downgrad popover */}
      <StyledWalletDialog
        open={check}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setCheck(false)}
        aria-describedby="alert-dialog-slide-description"
        // style={{width :"30rem"}}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Are you sure you want to downgrade your subscription ?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setCheck(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText className="title" id="alert-dialog-description">
            Downgrading your subscription will be applicable at the end of the
            term. This means you'll continue to have access to your current plan
            until the end of the billing cycle. After that, your subscription
            will be downgraded. Please confirm
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <AppButton
            onClick={() => {
              updateSubscription(activeData);
            }}
          >
            update
          </AppButton>
        </DialogActions>
      </StyledWalletDialog>
    </PlanCards>
  );
};
export default HocBackdropLoader(PlanCard);
