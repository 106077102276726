import { useAppSelector } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import WebBotLookFeel from "./WebBotLookFeel";
import ChannelsLookFeel from "./ChannelsLookFeel";

const LookFeel = (): JSX.Element => {
  const currentBot: any = useAppSelector(useCurrentBot);
  const currentBotComponent: any = {
    "whatsapp": <ChannelsLookFeel />,
    "messenger": <ChannelsLookFeel />,
    "telegram": <ChannelsLookFeel />,
    "Web Bot": <WebBotLookFeel />
  }
  const channeBotLookAndFeelComponent = currentBotComponent[currentBot?.channel]

  return channeBotLookAndFeelComponent;
};

export default LookFeel;
