import styled from "styled-components";

export const Wrappertable = styled.div`
& {
    width : 100%;
    height : 100%;
    // border: 1px solid green;
    display: flex;
    flex-direction: column;

    .roww_table{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        text-align:center;
        flex: 1;
    }

    .text{
        padding:10px;
        flex:1;
        text-align:left;
        max-width:129px;
        border-right:1px solid #CBCDD3;
        display:flex;
        align-items:center;
    }

    .value{
        padding:10px;
        flex:2;
        text-align:left;
        color:#7E8392;

        white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
       padding:10px;
       display:flex;
       align-items:center;
    }

    .staightght_line{
        height:auto;
        background-color:#CBCDD3;
        opacity:50%;
    }

    .horizontal_line{
        background-color:#CBCDD3;
        opacity:50%;
        border:0.1px solid #CBCDD3;
    }
    .roww_table_last{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        text-align:center;
    }

    .info_box{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    
}
`;