
import React from 'react';
import styled from 'styled-components';
import NoRecordsFound from '../UiComponents/NoRecordsFound/NoRecordsFound';
import { theme } from '../../Customization/Theme';
import CustomDashboardTooltip from '../UiComponents/CustomDashboardTooltip/CustomDashboardTooltip';
import { Box } from '@mui/material';

interface FunnelProps {
    funnelData: any;
    userData: any;
    handleFunnelClick?: (item: any) => void;
}

const Funnel: React.FC<FunnelProps> = ({ funnelData, userData, handleFunnelClick = () => { } }) => {

    return (

        <Box sx={{
            height: "100%",
            display: "flex",
            alignItems: "center"
        }}>

            {
                (userData === 0 && funnelData?.number_of_applications === 0 && funnelData?.applications_paid === 0 && funnelData?.applications_submitted === 0 && funnelData?.applications_unpaid === 0) ? (
                    <NoRecordsFound height='calc(100% - 55px)' />
                ) : (
                    <FunnelWrapper>
                        <div className="funnel">            
                                <CustomDashboardTooltip 
                                label='Contacts' 
                                count={userData && userData}
                                    dotColor='#00A2FE' >
                                <div className='bar_1'>
                                <svg width="" height="56" viewBox="0 0 433 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.44529 8.30022C-0.194766 4.34908 2.70886 0 6.98685 0H426.013C430.291 0 433.195 4.34907 431.555 8.30021L413.291 52.3002C412.361 54.5401 410.175 56 407.749 56H25.2505C22.8254 56 20.6387 54.5401 19.709 52.3002L1.44529 8.30022Z" fill="url(#paint0_linear_299_4071)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_299_4071" x1="27.5" y1="56" x2="426.5" y2="5.5" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#067FC4" />
                                            <stop offset="1" stop-color="#00A2FE" />
                                        </linearGradient>
                                    </defs>
                                    <text x="50%" y="60%" fill="#fff" text-anchor="middle">
                                        Contacts: {userData && userData}
                                    </text>
                                </svg>
                                </div>
                                </CustomDashboardTooltip>
                                    
                                <CustomDashboardTooltip
                                    label='Applications'
                                    count={funnelData && funnelData.number_of_applications}
                                    dotColor='#08D7C8' >
                            <div className='bar_2'>
                            <svg width="" height="56" viewBox="0 0 369 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.44441 8.29982C-0.195286 4.34873 2.70833 0 6.98614 0H362.014C366.292 0 369.195 4.34873 367.556 8.29981L349.296 52.2998C348.366 54.5399 346.179 56 343.754 56H25.246C22.8207 56 20.6339 54.5399 19.7043 52.2998L1.44441 8.29982Z" fill="url(#paint0_linear_299_4072)" />
                                <defs>
                                    <linearGradient id="paint0_linear_299_4072" x1="28.5" y1="56" x2="364" y2="7.5" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#07A498" />
                                        <stop offset="1" stop-color="#08D7C8" />
                                    </linearGradient>
                                </defs>
                                <text x="50%" y="60%" fill="#fff" text-anchor="middle">
                                    Applications: {funnelData && funnelData.number_of_applications}
                                </text>
                            </svg>
                            </div>
                                </CustomDashboardTooltip>


                                <CustomDashboardTooltip
                                    label='Paid'
                                    count={funnelData && funnelData.applications_paid}
                                    dotColor='#9EE20B' >
                            <div className='bar_3' onClick={() => handleFunnelClick({ key: "payment_status", value: "completed", label: "Paid Applications" })}>
                                <svg width="" height="56" viewBox="0 0 305 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.44316 8.29924C-0.196023 4.34823 2.70759 0 6.98514 0H298.015C302.292 0 305.196 4.34823 303.557 8.29924L285.302 52.2992C284.373 54.5396 282.186 56 279.76 56H25.2397C22.8142 56 20.6272 54.5396 19.6977 52.2992L1.44316 8.29924Z" fill="url(#paint0_linear_299_4073)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_299_4073" x1="26" y1="56" x2="307" y2="7.5" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#90C223" />
                                            <stop offset="1" stop-color="#9EE20B" />
                                        </linearGradient>
                                    </defs>
                                    <text x="50%" y="60%" fill="#fff" text-anchor="middle">
                                        Paid: {funnelData && funnelData.applications_paid}
                                    </text>
                                </svg>
                            
                            </div>
                            </CustomDashboardTooltip>


                                <CustomDashboardTooltip
                                    label='Submitted'
                                    count={funnelData && funnelData.applications_submitted}
                                    dotColor='#FBB500' >

                                
                            <div className='bar_4' onClick={() => handleFunnelClick({ key: "status", value: "submitted", label: "Submitted Applications" })}>
                                <svg width="" height="56" viewBox="0 0 241 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.44126 8.29836C-0.197141 4.34747 2.70647 0 6.9836 0H234.016C238.294 0 241.197 4.34747 239.559 8.29836L221.312 52.2984C220.383 54.5392 218.196 56 215.77 56H25.23C22.8042 56 20.617 54.5392 19.6877 52.2984L1.44126 8.29836Z" fill="url(#paint0_linear_299_4074)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_299_4074" x1="22.5" y1="56" x2="243" y2="1.97778e-06" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#FBA200" />
                                            <stop offset="1" stop-color="#FBB500" />
                                        </linearGradient>
                                    </defs>
                                    <text x="50%" y="60%" fill="#fff" text-anchor="middle">
                                        Submitted: {funnelData && funnelData.applications_submitted}
                                    </text>
                                </svg>
                            </div>
                                    </CustomDashboardTooltip>



                                <CustomDashboardTooltip
                                    label='Unpaid'
                                    count={funnelData && funnelData.applications_unpaid}
                                    dotColor='#FF2900' >

                            <div className='bar_5' onClick={() => handleFunnelClick({ key: "payment_status", value: "pending", label: "Unpaid Applications" })}>
                                <svg width="177" height="56" viewBox="0 0 177 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.43804 8.29688C-0.19904 4.34618 2.70454 0 6.98099 0H170.019C174.295 0 177.199 4.34618 175.562 8.29688L157.329 52.2969C156.4 54.5385 154.213 56 151.786 56H25.2136C22.7871 56 20.5995 54.5385 19.6706 52.2969L1.43804 8.29688Z" fill="url(#paint0_linear_299_4075)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_299_4075" x1="24" y1="56" x2="172" y2="6" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#E92601" />
                                            <stop offset="1" stop-color="#FF2900" />
                                        </linearGradient>
                                    </defs>
                                    <text x="50%" y="60%" fill="#fff" text-anchor="middle">
                                        Unpaid: {funnelData && funnelData.applications_unpaid}
                                    </text>
                                </svg>

                            </div>
                                    </CustomDashboardTooltip>

                        
                        </div>
                    </FunnelWrapper>
                )
            }
        </Box>
    );
};

export default Funnel;




const FunnelWrapper = styled.div`
&& {
    width: 100%;
        display: flex;
    justify-content: center;
    height: calc(100% - 55px);
    align-items: center;

    .funnel {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    width: 100%;


        .bar_1 {
            cursor: pointer;
            max-width: 433px;
            svg {
                max-width: 433px;
            }
        }

        .bar_2 {
            cursor: pointer;
            max-width: 369px;
            svg {
                max-width: 369px;
            }
        }

        .bar_3 {
            cursor: pointer;
            max-width: 305px;
            svg {
                max-width: 305px;
            }
        }
        .bar_4 {
            cursor: pointer;
            max-width: 241px;
            svg {
                max-width: 241px;
            }
        }
        .bar_5 {
            cursor: pointer;
            max-width: 177px;
            svg {
                max-width: 177px;
            }
        }
    }

    .bar1 {
   background: red;
   height: 55px;
   text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 400px;
   clip-path: polygon(0 0, 100% 0, 100% 9%, 85% 93%, 79% 100%, 19% 99%, 12% 100%, 0 10%);
}
.bar1:before {
 content: "";
    position: absolute;
    top: -7px;
    left: -5px;
    border-bottom: 40px solid transparent;
    border-top: 110px solid transparent;
    border-left: 50px solid white;
    transform: rotate(3deg);
}
.bar1:after {
 content: "";
    position: absolute;
    bottom: -20px;
    right: -68px;
    border-bottom: 30px solid transparent;
    border-top: 93px solid transparent;
    border-left: 91px solid white;
    transform: rotate(19deg);

}
.bar2 {
   background: purple;
   height: 55px;
   position: relative;
   text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 345px;
   margin-top: 5px;
   margin-left: 30px;
   overflow: hidden;
   z-index: 1
}
.bar2:before {
 content: "";
    position: absolute;
    top: -7px;
    left: -5px;
    border-bottom: 40px solid transparent;
    border-top: 110px solid transparent;
    border-left: 50px solid white;
    transform: rotate(3deg);
}
.bar2:after {
 content: "";
    position: absolute;
    bottom: -20px;
    right: -68px;
    border-bottom: 30px solid transparent;
    border-top: 93px solid transparent;
    border-left: 91px solid white;
    transform: rotate(19deg);

}

.bar3 {
   background: orange;
    height: 55px;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 290px;
    margin-top: 5px;
    margin-left: 60px;
    overflow: hidden;
    z-index: 1;

    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}
.bar3:before {
 content: "";
    position: absolute;
    top: -7px;
    left: -5px;
    border-bottom: 40px solid transparent;
    border-top: 110px solid transparent;
    border-left: 50px solid white;
    transform: rotate(3deg);
}
.bar3:after {
 content: "";
    position: absolute;
    bottom: -20px;
    right: -68px;
    border-bottom: 30px solid transparent;
    border-top: 93px solid transparent;
    border-left: 91px solid white;
    transform: rotate(19deg);

}

.bar4 {
  background: #475e13;
    height: 55px;
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 235px;
    margin-top: 5px;
    margin-left: 90px;
    overflow: hidden;
    z-index: 1;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
}
.bar4:before {
 content: "";
    position: absolute;
    top: -7px;
    left: -5px;
    border-bottom: 40px solid transparent;
    border-top: 110px solid transparent;
    border-left: 50px solid white;
    transform: rotate(3deg);
}
.bar4:after {
 content: "";
    position: absolute;
    bottom: -20px;
    right: -68px;
    border-bottom: 30px solid transparent;
    border-top: 93px solid transparent;
    border-left: 91px solid white;
    transform: rotate(19deg);

}

.bar5 {
  background: rgb(255, 98, 35);
    height: 55px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    margin-top: 5px;
    text-align: center;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    margin-left: 120px;
    overflow: hidden;
    z-index: 1;
    .text {
        border: 1px solid #fff;
    }
}
.bar5:before {
 content: "";
    position: absolute;
    top: -7px;
    left: -5px;
    border-bottom: 40px solid transparent;
    border-top: 110px solid transparent;
    border-left: 50px solid white;
    transform: rotate(3deg);
}
.bar5:after {
 content: "";
    position: absolute;
    bottom: -20px;
    right: -68px;
    border-bottom: 30px solid transparent;
    border-top: 93px solid transparent;
    border-left: 91px solid white;
    transform: rotate(19deg);

}


}
`;

