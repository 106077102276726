import React from "react"
import { FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material"
import { CustomFormControl } from "../Utils/common"

const AppPreviewInput = (props:any) => {    
    return (
        <>
            <CustomFormControl sx={{ width: '100%', position: "relative", marginTop: "5px" }} variant="outlined">
                {props?.type !== "dropdown" ?  <InputLabel htmlFor="component-error">{`${props?.content?.label}${!props?.content?.required ? " (optional)" : ""}`}</InputLabel>:null}

                <OutlinedInput
                    id="outlined-adornment-weight"
                    fullWidth
                    value={props?.value}
                    // type={props?.content['input-type']}
                    placeholder={`${props?.content?.label}${!props?.content?.required ? " (optional)" : ""}`}
                    autoComplete="off"
                    {...props}
                    onClick={props?.onClick}
                    onChange={props?.onChange}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                // onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {props?.icon}
                            </IconButton>
                        </InputAdornment>
                    } aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                        'aria-label': 'weight',
                        style: { padding: '10px' }
                    }}
                />
                <FormHelperText id="outlined-weight-helper-text">{props?.content['helper-text']}</FormHelperText>
            </CustomFormControl>
        </>
    )
}

export default AppPreviewInput