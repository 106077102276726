// vendors
import { useState, useEffect } from "react";
import { Modifier, EditorState, convertToRaw } from "draft-js";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import isEmpty from "lodash/isEmpty";

// components
import Icon from "./../../../icon";

// services
import { ContactsNetworkService } from "../../../../../../Store/Slices/Contacts/contactsNetworkService";


const Insert = (props: any) => {
  const { editorState, onChange, config } = props;
  const { insert } = config;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [properties, updateProdperties] = useState([]);
  const [disable, updateDisableStatus] = useState(false);

  useEffect(() => {
    ContactsNetworkService.getContactsSelectData({
      data: "",
    }).then(async (response: any) => {
      updateProdperties(response.data.data);
      return response;
    });
  }, []);

  useEffect(() => {
    const contentState1 = editorState.getCurrentContent();
    const rawData = convertToRaw(contentState1);
    const entityCount = getEntityMapCount(rawData.entityMap, "PROPERTY");
    updateDisableStatus(entityCount === 4);
  }, [anchorEl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getEntityMapCount = (data:any, name:string) => {
      let count = 1; 
      if(!isEmpty(data)) {
        for (const property in data) {
          if(data[property].type === name) 
            count++;
        }
      }
    return count;     
  };

  const handleItemClick = (property: any) => {
    const contentState1 = editorState.getCurrentContent()
    const rawData = convertToRaw(contentState1);
    const entityCount = getEntityMapCount(rawData.entityMap, "PROPERTY")

    if(entityCount === 4) return;

    let selection = editorState.getSelection();
    const entityKey = editorState
      .getCurrentContent()
      .createEntity("PROPERTY", "MUTABLE", {
        text: `{{${property.name}}}`,
        value: property.name,
        id: property.id,
      })
      .getLastCreatedEntityKey();

    let contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      `{{${property.name}}}`,
      editorState.getCurrentInlineStyle(),
      entityKey
    );

    let newEditorState = EditorState.push(
      editorState,
      contentState,
      "insert-characters"
    );

    // insert a blank space after link
    selection = newEditorState.getSelection().merge({
      anchorOffset: selection.get("anchorOffset") + property.label.length + 4,
      focusOffset: selection.get("anchorOffset") + property.label.length + 4,
    });

    newEditorState = EditorState.acceptSelection(newEditorState, selection);
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      selection,
      " ",
      newEditorState.getCurrentInlineStyle(),
      undefined
    );

    onChange(
      EditorState.push(newEditorState, contentState, "insert-characters")
    );
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        ml: "auto !important",
        "&:hover > div": {
          display: "flex",
          opacity: 1,
        },
      }}
    >
      <IconButton
        sx={{ p: "2px !important" }}
        size="small"
        onClick={handleClick}
      >
        <Icon icon={insert?.icon} size={16} color="#7E8392" />
      </IconButton>
      <Popover
        className="ignore-onClickOutside"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginLeft: "8px",
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
            padding: "8px 4px 8px 8px",
            maxHeight: "220px",
            overflow: "auto",
          },
        }}
      >
        <Box>
          <MenuList dense>
            {properties.length > 0 &&
              properties.map(
                (property: { label: string; id: string }, index: number) => {
                  return (
                    <MenuItem onClick={() => handleItemClick(property)} disabled={disable}>
                      <ListItemText>{property?.label}</ListItemText>
                    </MenuItem>
                  );
                }
              )}
          </MenuList>
        </Box>
      </Popover>
    </Box>
  );
};
export default Insert;
