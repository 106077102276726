import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  Options: any;
  divStyle?: any;
  sendValuesToParent?: (values: any) => void | undefined;
  submitOnChange?: boolean;

  tooltip?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
  onChange?: (value: any) => void;
}

interface StyledProps {
  isError?: any;
}

const AppFormRadioInput: React.FC<Props> = ({
  label,
  name,
  Options,
  divStyle,
  sendValuesToParent,
  tooltip,
  submitOnChange = false,
  children,
  ...otherProps
}) => {
  const {
    // setFieldTouched,
    handleSubmit,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormikContext<any>();
  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]);

  useEffect(() => {
    if (getIn(values, name) === 0) {
      setFieldValue(name, Options[0]);
    }
  }, []);
  const handleValueChange = (event: any) => {
    setFieldValue(name, event.target.value);
    submitOnChange && handleSubmit();

    // Call the onChange function if provided
    if (otherProps.onChange) {
      otherProps.onChange(event.target.value);
    }
  };

  return (
    <Div style={divStyle}>
      <FormControl style={{ width: "100%" }}>
        <Label>
          {label}{" "}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        <RadioGroup
          row
          name="row-radio-buttons-group"
          onChange={(event: any) => handleValueChange(event)}
          value={getIn(values, name) || Options[0]}
        >
          {Options &&
            Options.length > 0 &&
            Options.map((curItem: any, index: number) => {
              return (
                <FormControlLabel
                  key={index}
                  disabled={curItem.isDisabled}
                  value={curItem?.value || curItem}
                  control={<Radio />}
                  label={curItem?.label || curItem}
                />
              );
            })}
        </RadioGroup>
        <AppErrorMessage error={errors[name]} visible={touched[name]} />
      </FormControl>
    </Div>
  );
};

export default AppFormRadioInput;
const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
    .MuiFormControlLabel-label {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.darkGrey};
    }
    .MuiRadio-root {
      margin: 0 0.5rem 0 0;
    }
    .MuiFormControlLabel-root {
      margin: 0 !important;
      margin-right: 1rem !important ;
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
