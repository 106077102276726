import styled from "styled-components";
import { MenuItem, Select } from "@mui/material";
import { getIn, useFormikContext } from "formik";


import React, { useEffect, useRef, useState } from 'react'
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";
import { getTextWithoutHtml } from "../../Utils";

interface StyledProps {
  isError?: any;
}


interface Props {
  Options: any;
  label?: string;
  defaulttext?: string;
  name: string;
  valuekey: string;
  displaykey: string;
  variant?: string;
  children?: React.ReactNode;
  onChange?: (value: any) => void;
  [otherProps: string]: any;
}

const AppFormSelectFieldFlowOptions: React.FC<Props> = ({
  Options,
  label,
  name,
  defaulttext = 'Select a option',
  valuekey,
  displaykey,
  variant = 'value',
  onChange,
  ...otherProps
}) => {
  const { setFieldTouched, handleChange, errors, touched, values, setFieldValue, handleSubmit } =
    useFormikContext<any>();
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const fieldRef: any = useRef<any>(null);

  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    disablePortal: true,
    PaperProps: {
      sx: {
        backgroundColor: "white",
        minWidth: `${fieldRef?.current?.offsetWidth}px !important`,
        marginTop: "4px",
        maxWidth: "600px !important",
        maxHeight: "350px !important",
        boxShadow: `0px 2px 4px rgb(0 0 0 / 10%) !important`,
        transition:
          "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
        "& ul": {
          padding: "6px 0rem 4px",
        },
        "& .MuiMenuItem-root": {
          padding: "0px",
        },
        "& li": {
          fontSize: "0.95rem !important",
          height: "38px",
          marginBottom: "2px",
          color: '#282828',
        },
      },
    },

  };


  return (
    <Div className="dropdown-label">
      <Label>{label}</Label>
      <StyledSelectInput
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={(e: any) => {
          setFieldValue(name, e.target.value);
          handleSubmit();
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        value={values ? values[name] : ''}
        style={{
          border: isFocus ? `2px solid ${theme.palette.default.border}` : values[name] == 0 ? `2px solid transparent` : `2px solid ${theme.palette.default.border}`
        }}
        autoWidth={false}
        fullWidth={true}
        {...otherProps}

        MenuProps={menuProps}
      >

        <StyledMenuItem value={0}>
          {defaulttext}
        </StyledMenuItem>
        {Options &&
          Options.length > 0 &&
          Options.map((curElem: any, index: any) => {
            // return null;
            return (
              <StyledMenuItem value={variant == 'object' ? curElem : curElem[valuekey] ? curElem[valuekey] : curElem?.id} key={index}>
                {getTextWithoutHtml(getIn(curElem, displaykey)) || getIn(curElem, displaykey)}
              </StyledMenuItem>
            );
          })}
      </StyledSelectInput>
      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </Div>
  );
};

export default AppFormSelectFieldFlowOptions;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 1rem;
    
    .MuiList-root{
      display: flex;
    flex-direction: column;
    padding: 0.5rem 0rem;
    gap: 0.8rem;
    }

   ul, .MuiList-root-MuiMenu-list{
      display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
    padding: 0.5rem 0rem;
    gap: 0.8rem;
    }

    .dropdown-label {
      width: 50% !important;
    }
  
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
  }
`;
const StyledMenuItem = styled(MenuItem)`
  && {
   /* white-space: nowrap !important; */
  /* overflow: hidden !important; */
  text-overflow: ellipsis !important;
  }
`;


const StyledSelectInput = styled(Select) <StyledProps>`
  && {
    width: 100%;
    max-width: 400px;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;

  &::placeholder {
    color: ${theme.palette.default.darkGrey} !important;
  }

  &:active {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    background-color: red;
  }

    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 1px solid ${theme.palette.default.lightGrey};
    }

    .css-1ja9cqb-MuiSvgIcon-root-MuiSelect-icon,
    .css-n9yzhl-MuiSvgIcon-root-MuiSelect-icon {
      color: ${theme.palette.default.border};
    }

    fieldset {
      border: none !important;
      outline: none !important;
      display: none !important;
    }
    legend {
      display: none !important;
    }
    input {
      display: none !important;
    }
  }
`;
