import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  ButtonGroup,
  Chip,
  IconButton,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { uniqueId } from "lodash";
import { useNavigate } from "react-router-dom";
import { initialQuery } from "../../../AppComponents/QueryBuilder/utils/utils";
import AppInputSearch from "../../../AppComponents/ui/app-input-search";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import {
  enabledViewsListing,
  viewsListing,
} from "../../../Store/Slices/Contacts/contacts.selector";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  color: theme.palette.default.darkGrey,
  "& .MuiBox-root": {
    fontSize: 14,
  },
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 350,
    height: 350,
    marginTop: 0,
    // padding: theme.spacing(2),
    fontSize: 14,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.default.darkGrey,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "2px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 12,
      "&. MuiListItemText-primary": {
        fontSize: 12,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.default.darkGrey,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {},
    },

    "& .MuiTypography-root": {
      fontSize: 12,
    },
    "& .MuiListItemText-root": {
      fontSize: 12,
      cursor: "pointer",
    },
    "& .MuiStack-root-root": {
      marginTop: 12,
    },
  },
}));

interface Props {
  // id: string;
}

export default function AddViewBtn() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { showAlert } = useNotification();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectedViewType, setSelectedViewType] = useState<string>("all");
  const allViewsData: any = useAppSelector(viewsListing);
  const enabledViewsData: any = useAppSelector(enabledViewsListing);
  const [filteredViews, setFilteredViews] = useState<any>(allViewsData ?? []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddView = (view: any) => {
    let payload = {
      id: view?.viewId,
      data: {
        ...view,
        position:
          (enabledViewsData?.[enabledViewsData?.length - 1]?.position ?? 0) + 1,
      },
    };

    ContactsNetworkService.updateContactView(payload)
      .then((res: any) => {
        showAlert("View added", "success");
        dispatch(ContactsActions.getAllContactsViews({}));
        navigate(`/contacts/list?view=${view?.viewId}`);
      })
      .catch((err: any) => {
        console.log("err", err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleCreateView = () => {
    let payload = {
      name: "Untitled",
      type: "custom",
      filter_query: initialQuery,
      mongodb_query: null,
      position:
        (enabledViewsData?.[enabledViewsData?.length - 1]?.position ?? 0) + 1,
      shared_with: "me",
      access_type: "can_edit",
      user_list: null,
    };
    ContactsNetworkService.createContactView(payload)
      .then((res: any) => {
        dispatch(ContactsActions.getAllContactsViews({})).then(
          (response: any) => {
            if (response?.payload?.data) {
              navigate(`/contacts/list/?view=${res?.data?.data?.viewId}`);
            }
          }
        );
      })
      .catch((err: any) => {
        console.log("err", err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleOnSearch = (e: any) => {
    setSearchValue(e?.search);
    setFilteredViews(
      allViewsData?.filter((view: any) =>
        view?.name?.toLowerCase().includes(e?.search?.toLowerCase())
      )
    );

    if (selectedViewType !== "all") {
      setSelectedViewType("all");
    }
    // dispatch(ContactsActions.getAllContactsViews({}));
  };

  const handleChipClick = (e: any) => {
    setSelectedViewType(e);
    // let results = allViewsData;
    if (searchValue) {
      setFilteredViews(
        allViewsData?.filter((view: any) =>
          view?.name?.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    }

    if (e === "all") {
      setFilteredViews(allViewsData);
    }

    if (e === "system") {
      setFilteredViews(allViewsData?.filter((view: any) => view?.type === e));
    }

    if (e === "custom") {
      setFilteredViews(allViewsData?.filter((view: any) => view?.type === e));
    }
  };

  useEffect(() => {
    setFilteredViews(allViewsData);
  }, [allViewsData]);
  useEffect(() => {
    return () => {
      setSelectedViewType("all");
    };
  }, []);

  return (
    <>
      <Button
        className="createViewBtn"
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={handleClick}
        variant="text"
      >
        <AddIcon />{" "}
        {allViewsData?.filter((view: any) => view.position === null)?.length > 0
          ? allViewsData?.filter((view: any) => view.position === null)
              ?.length + " more... "
          : ""}
      </Button>
      <StyledMenu
        id="add-view-btn"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        component={"div"}
        sx={{ overflow: "hidden" }}
      >
        <Stack
          direction="column"
          spacing={2}
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#fff",
            padding: "16px 16px 16px 16px",
          }}
        >
          <Stack direction="row" justifyContent={"space-between"} spacing={1}>
            <Typography>Select a view</Typography>
            <Button
              variant="text"
              sx={{
                color: theme.palette.primary.main,
                padding: "0 12px",
                fontSize: 14,
                lineHeight: 0,
                textTransform: "none",
                fontWeight: 400,
              }}
              startIcon={<AddIcon style={{ fontSize: 18 }} />}
              onClick={handleCreateView}
            >
              Create new view
            </Button>
          </Stack>
          <AppInputSearch
            placeholder="Search views..."
            handleOnSearch={handleOnSearch}
          />
          <ButtonGroup
            variant="outlined"
            aria-label="Loading button group"
            sx={{
              gap: "8px",
              margin: 0,
              "& .MuiChip-root": {
                fontSize: 12,
                height: "26px",
              },
            }}
          >
            <Chip
              label="All"
              style={{
                backgroundColor:
                  selectedViewType === "all"
                    ? theme.palette.primary.lightPrimary
                    : "transparent",
              }}
              onClick={() => handleChipClick("all")}
            />
            <Chip
              label="System default"
              style={{
                backgroundColor:
                  selectedViewType === "system"
                    ? theme.palette.primary.lightPrimary
                    : "transparent",
              }}
              onClick={() => handleChipClick("system")}
            />
            <Chip
              label="Custom"
              style={{
                backgroundColor:
                  selectedViewType === "custom"
                    ? theme.palette.primary.lightPrimary
                    : "transparent",
              }}
              onClick={() => handleChipClick("custom")}
            />
          </ButtonGroup>
          <Divider sx={{ marginTop: "8px !important" }} />
        </Stack>
        <Stack
          component={"ul"}
          direction="column"
          spacing={0.5}
          style={{ padding: "0px 0px 0px" }}
          sx={{ overflowY: "overlay" }}
        >
          {filteredViews?.map((view: any, index: number) => {
            if (view?.position !== null) return null;
            return (
              <MenuItem
                key={index}
                onClick={() => handleAddView(view)}
                disableRipple
                selected={
                  enabledViewsData?.find((v: any) => v?.viewId === view?.viewId)
                    ? true
                    : false
                }
                disabled={
                  enabledViewsData?.find((v: any) => v?.viewId === view?.viewId)
                    ? true
                    : false
                }
              >
                <ListItemText>{view?.name}</ListItemText>
              </MenuItem>
            );
          })}
        </Stack>
      </StyledMenu>
    </>
  );
}
