import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material"
import { BroadcastInfo, BroadcastText, StyledWalletDialog, actionIson } from "./MenuStyle"
import Input from "../../Modules/FlowBuilder/Components/Input"
import AppButton from "../../AppComponents/AppButton"
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";

const AddWallet = (prpos: any): JSX.Element => {
    const [balance, setBalance] = useState(0)
    return (
        <StyledWalletDialog
            open={prpos.check}
            onClose={() => prpos.setCheck(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="walletHeader" sx={{ m: 0, p: 2 }}>
                Add credits to your wallet
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => { prpos.setCheck(false) }}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogTitle className="walletTitle" sx={{ m: 0, p: 2 }}>
                Enter the Amount you want to add balance to Wallet
                <div className="walletInput"> <Input
                    placeholder="Enter Your Amount"
                    onChange={(e: any) => setBalance(e.target.value)}
                    value={balance}
                // error={phoneValue !== "" && !isPhoneNumberValid}
                /></div>
                <div className="walletValues">
                    <AppButton
                        variant="grey"
                        className="walletValue"
                        onClick={(e: any) => setBalance(100)}
                    >
                        ₹ 100
                    </AppButton>
                    <AppButton
                        variant="grey"
                        className="walletValue"
                        onClick={(e: any) => setBalance(500)}
                    >
                        ₹ 500
                    </AppButton>
                    <AppButton
                        variant="grey"
                        className="walletValue"
                        onClick={(e: any) => setBalance(1000)}
                    >
                        ₹ 1000
                    </AppButton>
                    <AppButton
                        variant="grey"
                        className="walletValue"
                        onClick={(e: any) => setBalance(2000)}
                    >
                        ₹ 2000
                    </AppButton></div>
                <BroadcastText>
                    Term And Conditions
                </BroadcastText>
                <div className="walletCondition">
                    <BroadcastInfo>
                        <FiberManualRecordIcon style={actionIson} /> Subscription charges are not deducted from wallet balance
                    </BroadcastInfo>
                    <BroadcastInfo >
                        <FiberManualRecordIcon style={actionIson} /> Wallet balance is non-refundable & non-transferable
                    </BroadcastInfo>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ProtectedAppButton
                    moduleId="add_credits_to_wallet"
                    onClick={() => { prpos.addBalance(balance) }}
                    disabled={!isNaN(balance) && balance ? balance === 0 ? true : false : true}
                    style={{ margin: "10px 8px" }}
                >
                    Add Balance
                </ProtectedAppButton>
            </DialogActions>
        </StyledWalletDialog>
    )
}
export default AddWallet