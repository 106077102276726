import React from "react";
import { theme } from "../../../../../Customization/Theme";
import WhatsappMediaPicker from "./WhatsappMediaPicker";
import WhatsappTextField from "./WhatsappTextField";

const RenderDynamicHeaderTypes: React.FC<IRenderDynamicHeaderTypes> = ({
  headerType,
  disabled = false
}) => {

  return (
    <React.Fragment>
      {headerType === "TEXT" ? (
        <div className="flexBox">
          <WhatsappTextField
            name="header_value"
            label="Header text"
            placeholder="Hey, {{1}} is sample header text"
            charactersLimit={60}
            variablesKey="header_text"
            divStyle={{ marginTop: '0rem' }}
            variablesButtonLimit={1}
            disabled={disabled}
            labelStyle={{ color: theme.palette.default.black }}
          />
        </div>
      ) : null}
      {headerType === "IMAGE" ? (
        <WhatsappMediaPicker
          name="header_value"
          mediaType={headerType}
          divStyle={{ marginTop: '1rem' }}
          disabled={disabled}
          tooltipTitle={"The supported formats are - jpg, jpeg, and png. The image size must be smaller than 5MB."}
        />
      ) : null}
      {headerType === "VIDEO" ? (
        <WhatsappMediaPicker
          name="header_value"
          mediaType={headerType}
          divStyle={{ marginTop: '1rem' }}
          disabled={disabled}
          tooltipTitle={"The supported format is mp4. The video size must be smaller than 16MB."}
        />
      ) : null}
      {headerType === "DOCUMENT" ? (
        <WhatsappMediaPicker
          name="header_value"
          mediaType={headerType}
          supportedFormat={["application/pdf"]}
          divStyle={{ marginTop: '1rem' }}
          disabled={disabled}
          tooltipTitle={"The supported formats are - pdf. The document size must be smaller than 100MB."}
        />
      ) : null}
    </React.Fragment>
  );
};

export default RenderDynamicHeaderTypes;
