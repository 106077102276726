// import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton, MenuItem, Tooltip } from "@mui/material";
import { ErrorMessage, Field, FieldArray, getIn, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import { AppErrorMessage, AppFormField } from "../../AppComponents/Forms";
import AppDeleteIcon from "../../AppComponents/Forms/AppDeleteIcon";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { checkDuplicatesArray, getFaqVaritionProgressValue, getNestedPropertyByString } from "../../Utils";

interface Props {
  label?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  dynamicVaritionValue: any;
  setProgress: any;
  setDynamicVaritionValue?: any;
  disabled?: boolean;
  renderAddBtn?: React.ReactNode;
  secondaryFieldNameForError?: string;
  addBtnDisable: boolean;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const FaqFieldArray: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  setProgress,
  renderAddBtn,
  disabled = false,
  addBtnDisable = false,
  secondaryFieldNameForError,
  dynamicVaritionValue,
  setDynamicVaritionValue,
  sendValuesToParent,
  children,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    // handleChange,
    errors,
    touched,
    // getFieldMeta,
    // initialValues,
    setFieldValue,
    // setTouched,
    setFieldError,
    values,
  } = useFormikContext<any>();

  const { showAlert } = useNotification();
  const messagesEndRef = useRef<any>(null);
  const scrollToBottom = async () => {
    await messagesEndRef?.current?.scrollIntoView({ behavior: "smooth",block: 'nearest', inline: 'start'  });
  };

  useEffect(() => {
    scrollToBottom();
  }, [values[name]]);

  const buttonRef = useRef<any>(null);
  const TriggerClickEvent = () => {
    buttonRef?.current?.click();
  };

  useEffect(() => {
    if (dynamicVaritionValue && dynamicVaritionValue?.length > 0) {
      TriggerClickEvent();
      setFieldTouched(name, true)
    }
  }, [dynamicVaritionValue]);

  useEffect(() => {
    if (values[name]) {
      let invalidCount = 0
      values[name]?.length > 0 && values[name]?.map((cur: any, index: number) => {
        if (cur?.length <= 0) {
          invalidCount = invalidCount + 1;
          // values[name]?.splice(index, 1);
        }
      })
      let valid = values[name]?.length - invalidCount
      setProgress(getFaqVaritionProgressValue(valid));
    }
  }, [values, errors]);
 
  return (
    <Div style={divStyle}>
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
      <Label>
        {label}

        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <FieldArray name={name}>
        {({ insert, remove, push, swap, unshift, pop, replace }) => (
          <StyledFilteredArray>
            {values &&
              name &&
              values[name] &&
              values[name].length > 0 &&
              values[name].map((item: any, index: number) => {
                const fieldName: string = `${name}[${index}]`;
                return (
                  <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>

                    <StyledRowWrapper key={index}>
                      <p>{index + 1}.</p>
                      <Input
                        
                        as={ 'textarea'}
                      name={fieldName}
                      placeholder={`Enter variation ${index + 1}`}
                      style={{
                        border:
                          getNestedPropertyByString(values, name, index) && getNestedPropertyByString(values, name, index).length > 0   
                        ? `2px solid transparent`
                        : `2px solid ${theme.palette.default.error}`
                      }}
                        disabled={disabled}
                        // rows={1}
                        value={getIn(values, fieldName)}
                        onChange={(e: any) => setFieldValue(fieldName, e.target.value)}
                      />
                                     
                      {/* Remove this Language */}
                      {
                        !addBtnDisable && <AppDeleteIcon disabled={disabled} tooltip={`Delete ${fieldName}`} onClick={() => remove(index)}/>
                      }
                      
                    {/* <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton> */}

                      {/* Add a new empty Language at this position */}
                      
                    {/* <IconButton onClick={() => insert(index, '')}>
                    <AddIcon style={{ color: "grey", fontSize: "20px" }} />
                  </IconButton> */}
                      
                  </StyledRowWrapper>
            </div>              
                );
              })}

      
<AppErrorMessage style={{marginLeft:'30px'}} error={errors[name]} visible={touched[name]} />
            {/* Add new field at last position */}

            {
              !addBtnDisable &&   <AppButton
              innerRef={buttonRef}
              variant="text"
                onClick={() => {
                  if (secondaryFieldNameForError && !values?.[secondaryFieldNameForError]) {
                    setFieldTouched(secondaryFieldNameForError,true)
                    setFieldError(secondaryFieldNameForError,"Question is required")
                  }

                  else if (values[name] && values[name]?.length >= 1  && values[name]?.slice(-1) == '') {
                    setFieldTouched(name,true)
                    setFieldError(name,"variation is required")
                  }
                    
                  else if(values[name] && values[name]?.length >= 1 && checkDuplicatesArray(values[name])){
                    showAlert("Variation has duplicate values", "error");
                  }
                    
                  else if (
                    values[name] &&
                    values[name]?.length >= 1 &&
                    secondaryFieldNameForError &&
                    values[name]?.map((value: any) => value?.toLowerCase())?.includes(
                      values?.[secondaryFieldNameForError]?.toLowerCase()
                    )
                  ) {
                    showAlert("Question and variation can't be same.", "error");
                  }
                    
                  else {
                    // setFieldTouched(name,false)
                    push(dynamicVaritionValue);
                    setDynamicVaritionValue("");
                  }
              }}
              style={{ marginTop: "0.5rem", minWidth: "130px", width: "25%" }}
              startIcon={"ri-add-line"}
                className={'buttonInsideForm'}
                disableRipple={true}
            >
              Add variation
            </AppButton>
            }
           
            
          </StyledFilteredArray>
        )}
      </FieldArray>
      <div ref={messagesEndRef}></div>
    </Div>
  );
};

export default FaqFieldArray;

interface StyledProps {
  isError?: any;
}

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 1rem;
    .MuiFormControl-root {
      width: 100% !important;
    }
  }
`;

const StyledFilteredArray = styled.div`
  && {
    
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;
const StyledRowWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
    margin: 0.6rem 0rem 0.6rem 0;
    padding-right: 1rem;

    p{
      font-size: 14px;
      color: #7e8392;
      max-width: 16px;
    }
    .MuiIconButton-root {
      svg {
        font-size: 20px;
        color: #7e8392;
      }
      &:hover {
        svg {
          color: red;
        }
      }
    }
  }
`;

const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
// const Input = styled(Field)<StyledProps>`
const Input = styled.textarea<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 4px 2rem 8px 14px;
    padding: 6px 1rem 6px 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    /* overflow:hidden; */
    resize: none;

    /* ::-webkit-scrollbar {
   display: none;
 } */

  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    height: 80px;
  }
`;
