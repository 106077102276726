import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreditDetailsNetworkService } from "./CreditDetailsNetworkService";

export class CreditDetailsActions {
  // * get Credit broadcast details *//
  static getBroadcastCreditDetails = createAsyncThunk(
    "CreditDetailSlice/getBroadcastCreditDetails",
    (id: string, { dispatch, rejectWithValue }) => {
      return CreditDetailsNetworkService.getBroadcastCreditDetails(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  }
