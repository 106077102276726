import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Typography } from "@mui/material"
import { AddScreenModel, CreateScreen, FlowScreenHeader, WhatsappFlowWapper } from "../Utils/style"
import FlowBuilderPreview from "./flowBuilderPreview"
import AppInput from "../Components/Input"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../../Store"
import { setCategorieList, setContentData, setDefaultList, setFlowBuilderList, setScreenType } from "../Store/FlowBuilder.slice"
import { ArrowBack, ArrowForward, Refresh } from '@mui/icons-material';
import { useSelector } from "react-redux"
import { getCategoriesList, getDefaultFlowsList, getScreenType } from "../Store/FlowBuilder.selectors"
import AppButton from "../Components/Button"
import SaveAsIcon from '@mui/icons-material/SaveAs';
import { FlowBuilderActions } from "../Store/FlowBuilder.actions"
import { HocBackdropLoader, HocBackdropLoaderProps } from "../HOC/HocBackdropLoader"
import { useNavigate } from "react-router-dom"
import { mapNamesToIds } from "../Utils/common"
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};

interface Props extends HocBackdropLoaderProps {

}

const FlowCreateScreen: React.FC<Props> = ({
    setLoading,
}) => {
    let parentScreen: any = "parentScreen"
    let childScreen: any = "childScreen"
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const screenType = useSelector(getScreenType);
    const categoriesList = useSelector(getCategoriesList)
    const defaultFlowList = useSelector(getDefaultFlowsList)
    const [categories, setCategories] = useState<string[]>(["Select Categories"]);
    const [flowName, setFlowName] = useState<string>('');
    const [template, setTemplate] = useState<string>("");
    const [categorieListData, setCategorieListData] = useState<any>([]);
    const [flowListDataWithEndPoint, setFlowListDataWithEndPoint] = useState<any>([]);
    const [flowListDataWithOutEndPoint, setFlowListDataWithOutEndPoint] = useState<any>([]);
    const [allScreens, setAllScreens] = useState<any>({});
    const [selectScreen, setSelectScreen] = useState<any>("");
    const [currentScreenIndex, setCurrentScreenIndex] = useState<any>(0);
    const [errorMessage, setErrorMessage] = useState<any>("");
    console.log("values", flowName, categories, template);

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        console.log("vlauesssssssssssssssssssss", value);

        if (value.length === 0) {
            setCategories(['Select Categories'])
        } else {
            const filteredCategories = value.filter((category: any) => category !== 'Select Categories');
            setCategories(
                // On autofill we get a stringified value.
                typeof filteredCategories === 'string' ? filteredCategories.split(',') : filteredCategories,
            );
        }

    };
    const setTemplateFn = (event: any) => {
        const {
            target: { value },
        } = event;
        setTemplate(value)
        dispatch(setScreenType(parentScreen))
        let selectTemplate = defaultFlowList?.filter((res: any) => res?.id === value)
        let templateJSON = JSON.parse(selectTemplate[0]?.flow_json)
        setAllScreens(templateJSON)
        setSelectScreen(templateJSON?.screens[0]?.title)
        setCurrentScreenIndex(0)
        dispatch(setContentData(templateJSON?.screens[0]));
    }
    const setScreen = (event: any) => {
        const {
            target: { value },
        } = event;

        // setTemplate(value)
        // let data: any = getJSON()[0]
        let selectTemplate = allScreens?.screens?.filter((res: any) => res?.title === value)
        const screenIndex = allScreens?.screens?.findIndex((screen: any) => screen?.title === value);
        setCurrentScreenIndex(screenIndex)

        if (!selectTemplate[0]?.layout?.children[0]?.children) {
            dispatch(setScreenType(childScreen))
        } else {
            dispatch(setScreenType(parentScreen))
        }
        setSelectScreen(selectTemplate[0]?.title)
        dispatch(setContentData(selectTemplate[0]));
    }
    const handleBack = () => {
        if (currentScreenIndex > 0 && allScreens && selectScreen) {
            const newIndex = currentScreenIndex - 1;
            setCurrentScreenIndex(newIndex);
            setSelectScreen(allScreens?.screens[newIndex]?.title);
            dispatch(setContentData(allScreens?.screens[newIndex]));
        }
    };

    const handleForward = () => {
        if (currentScreenIndex < allScreens?.screens?.length - 1 && allScreens && selectScreen) {
            const newIndex = currentScreenIndex + 1;
            setCurrentScreenIndex(newIndex);
            dispatch(setContentData(allScreens?.screens[newIndex]));
            setSelectScreen(allScreens?.screens[newIndex]?.title);
        }
    };

    const resetHandle = () => {
        if (allScreens && selectScreen) {
            setCurrentScreenIndex(0);
            dispatch(setContentData(allScreens?.screens[0]));
            setSelectScreen(allScreens?.screens[0]?.title);
        }
    };

    useEffect(() => {
        setLoading(true)
        dispatch(FlowBuilderActions.getDefaultFlowAndCategories(""))
            .then((res: any) => {
                console.log("res", res?.payload?.data[0]);
                setCategorieListData(res?.payload?.data[0]?.categorie_list)
                dispatch(setCategorieList(res?.payload?.data[0]?.categorie_list))
                dispatch(setDefaultList(res?.payload?.data[0]?.flow_list))
                setFlowListDataWithEndPoint(res?.payload?.data[0]?.flow_list?.filter((res: any) => res?.is_endpoint_configured))
                setFlowListDataWithOutEndPoint(res?.payload?.data[0]?.flow_list?.filter((res: any) => !res?.is_endpoint_configured))
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })

    }, [])
    const checkValid = () => {
        if (flowName && categories?.map((res: any) => res !== "Select Categories")[0] && template) {
            return true
        } else {
            return false
        }
    }
    const createFlow = () => {
        console.log("allScreens", allScreens, flowName, categories);
        let data = {
            "name": flowName,
            "categories": mapNamesToIds(categoriesList, categories),
            "template_flow": allScreens,
            "mapping_object": {}
        }
        setLoading(true)
        dispatch(FlowBuilderActions.createFlow(data))
            .then((res: any) => {
                console.log("res----------------------->", res);
                if (res?.payload?.error) {
                    setLoading(false)
                    setErrorMessage(res?.payload?.error)
                } else {
                    dispatch(setFlowBuilderList(allScreens))
                    navigate("/whatsapp/flow/builder/" + res?.payload?.data?.id);
                }
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("res----------------------->", error?.payload?.error);
            })
    }
    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.500',
        borderRadius: 1,
        height: '36px',
        width: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };
    return (
        <>
            <FlowScreenHeader>
                <div className="headerContainer" style={{ justifyContent: "space-between" }}>
                    <div style={{ padding: "12px" }}>
                        <div style={{ display: "flex", cursor: "pointer" }} onClick={() => navigate("/whatsapp/flow")}>
                            <KeyboardArrowLeftIcon />
                            <a style={{ margin: "2px 0px 0px 7px" }}>Whatsapp Flow</a>
                        </div>
                    </div>
                    <div style={{ margin: "12px" }}>
                        <AppButton onClick={createFlow} disabled={checkValid() ? false : true} startIcon={<SaveAsIcon />} className="bodyOptionsButton" fontColor={checkValid() ? '#ffff' : "#6b6868"} backgroundColor={checkValid() ? "rgb(33, 28, 82)" : "rgba(229,232,240,1)"} text={"Create"} />
                    </div>
                </div>
            </FlowScreenHeader >
            <WhatsappFlowWapper>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid xs={6} style={{
                        textAlign: "left",
                        paddingLeft: "100px",
                        paddingTop: "30px"
                    }}>
                        <div>
                            <CreateScreen>
                                <div className="bodyTitle">Flow name<a style={{ color: "red", marginLeft: "5px" }}>*</a></div>
                                <AppInput value={flowName} handleChange={setFlowName} characterLimit={200} placeholder="Enter your screen name" />
                                <FormHelperText style={{
                                    margin: "-15px 3px 2px",

                                    color: "red"
                                }} id="outlined-weight-helper-text">{errorMessage}</FormHelperText>
                            </CreateScreen>
                            <CreateScreen style={{ opacity: "70%" }}>
                                <div className="bodyTitle">Categories<a style={{ color: "red", marginLeft: "5px" }}>*</a></div>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={categories}
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Tag" />}
                                    placeholder="Select value"
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return <em>Placeholder</em>;
                                        }

                                        return selected.join(', ');
                                    }} MenuProps={MenuProps}
                                    style={{
                                        margin: "12px 0px 11px",
                                        border: "1px solid",
                                        width: "50%",
                                        height: "36px"
                                    }}
                                >
                                    {categorieListData?.map((name: any) => (
                                        <MenuItem key={name?.id} value={name?.name}>
                                            <ListItemText primary={name?.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </CreateScreen>
                            <CreateScreen>
                                <div style={{ opacity: "70%" }}>
                                    <div className="bodyTitle">
                                        Template<a style={{ color: "red", marginLeft: "5px" }}>*</a>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "65%",
                                        marginTop: "15px"
                                    }}>
                                        <div className="bodyTitle">Without endpoint</div>
                                        <div className="bodyTitle">With endpoint ( coming soon )</div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        width: "77%"
                                    }}>
                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={template}
                                                onChange={setTemplateFn}
                                                sx={{ fontSize: "12px" }}
                                            >
                                                {flowListDataWithOutEndPoint?.map((value: any) => (
                                                    <FormControlLabel
                                                        key={value?.id}
                                                        value={value?.id}
                                                        control={<Radio />}
                                                        label={<Typography style={{ fontSize: '14px' }}>{value?.name}</Typography>}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </FormControl>

                                        <FormControl>
                                            <RadioGroup
                                                aria-labelledby="demo-controlled-radio-buttons-group-1"
                                                name="controlled-radio-buttons-group-1"
                                                value={template}
                                                onChange={setTemplateFn}
                                            >
                                                {flowListDataWithEndPoint?.map((value: any) => (
                                                    <FormControlLabel
                                                        key={value?.id}
                                                        disabled={true}
                                                        value={value?.id}
                                                        control={<Radio />}
                                                        label={<Typography onChange={setTemplateFn} style={{ fontSize: '14px', color: "gray" }}>{value?.name}</Typography>}
                                                    />))}
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </CreateScreen>


                        </div>

                    </Grid>
                    <Grid xs={6} style={{
                        left: "34%",
                        width: "30%",
                        position: "absolute",
                        top: "15%"
                    }}>
                        {<FlowBuilderPreview height="560px" />}
                        <div>
                            <Box display="flex" alignItems="center" justifyContent="center" mt={2} gap={2}>
                                <IconButton style={buttonStyle} onClick={() => handleBack()} disabled={currentScreenIndex === 0}>

                                    <ArrowBack />
                                </IconButton>
                                <IconButton style={buttonStyle} onClick={() => handleForward()} disabled={currentScreenIndex === allScreens?.screens?.length - 1}>
                                    <ArrowForward />
                                </IconButton>
                                <FormControl variant="outlined" sx={{ minWidth: 150, border: '1px solid', borderColor: 'grey.500', borderRadius: 1, height: '36px' }}>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={selectScreen}
                                        onChange={setScreen}
                                        label="Select"

                                        placeholder="select"
                                        sx={{ height: '36px', lineHeight: '36px' }}
                                    >
                                        {allScreens?.screens?.map((res: any) => {
                                            return (
                                                <MenuItem value={res?.title}>{res.title}</MenuItem>
                                            )
                                        })}

                                        {/* Add more MenuItem components as needed */}
                                    </Select>
                                </FormControl>
                                <IconButton onClick={() => resetHandle()} style={buttonStyle}>
                                    <Refresh />
                                </IconButton>
                            </Box>
                        </div>
                    </Grid>

                </Grid>

            </WhatsappFlowWapper >
        </>
    )
}

export default HocBackdropLoader(FlowCreateScreen)

