import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React, { ChangeEvent } from 'react';
import FieldLabel from './FieldLabel';
import { getIn, useFormikContext } from 'formik';
import { ChatbotConsoleService } from '../../../Services/Apis/ChatbotConsoleService';
import { useNotification } from '../../../Hooks/useNotification/useNotification';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

interface ImageUploaderProps {
  name: string;
  label: string;
}

const ImageUploader = ({ name, label }: ImageUploaderProps): JSX.Element => {

  const { setFieldValue, values } = useFormikContext<any>();
  const { showAlert } = useNotification();

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("source", file);
      formData.append("resolved", "true");
      ChatbotConsoleService.uploadWhatsappMedia(formData).then((response: any) => {
        if (response?.data?.status) {
          setFieldValue(name, response?.data?.source);
        } else {
          showAlert("Image upload failed please try again!", "error");
        }
      }).catch(() => {
        showAlert("Image upload failed please try again!", "error");
      })
    }
  };

  return (
    <Container>
      <FieldLabel label={label} />
      <Box display="flex" width={"100%"} alignItems="start" justifyContent={"space-between"}>
        <input
          accept="image/*"
          type="file"
          id="image-upload"
          style={{ display: 'none' }}
          onChange={handleImageChange}
        />
        <label htmlFor="image-upload">
          <IconButton component="span">
            <CloudUploadIcon />
          </IconButton>
        </label>
        {getIn(values, name) && <CloseIcon onClick={() => setFieldValue(name, "")} />}
      </Box>
    </Container>
  );
};

export default ImageUploader;

const Container = styled.div`
&&{
  display:flex;
  flex-direction:column;
}`