import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../../Store";
import { useBotConfigStore } from "../../../../../../Store/Slices/botConfig/BotConfigSlice";
import NotificationCard from "../../../Conversation/NotificationBlock/SubComponents/NotificationCard";
import WebBotStaticRenderText from "./WebBotStaticRenderText";
import {
  WidgetMainChat,
  WidgetMainContent,
} from "./styles-web-bot-static-preview";
import QuickListCard from "../../../Conversation/QuickMenu/SubComponents/ListCard";
import BlockCard from "../../../Conversation/QuickMenu/SubComponents/BlockCard";
import {
  AppRenderBannerCarousel
} from "../../../../../../socket/appComponents/RenderComponents";

const FAKE_MSG_DATA = [
  {
    message:
      "Hey! We are here to help you! Please Enter your name to continue.",
    userMessage: false,
  },
  {
    message: "Jhon doe",
    userMessage: true,
  },
  {
    message: "thats great. How can I help you?",
    userMessage: false,
  },
];

const WebBotStaticChat = () => {
  const { localBotConfig } = useAppSelector(useBotConfigStore);
  const location = useLocation();
  
  const isNotificationTab =
    location.pathname.includes("notification-block") ?? false;
  const isQuickMenuTab = location.pathname.includes("quick-menu") ?? false;
  const isBannerCarouselTab = location.pathname.includes("banner") ?? false;
  const notification_block = localBotConfig?.notification_block;
  const quick_menu = localBotConfig?.quick_menu;
  const banner_carousel = localBotConfig?.banner_carousel;

  return (
    <WidgetMainContent
      className="Web-WidgetMainContent"
      style={
        localBotConfig?.backgroundImage
          ? {
              backgroundImage: "url(" + localBotConfig?.backgroundImage + ")",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }
          : {}
      }
    >
      <WidgetMainChat>
        {/* NOTIFICATIONS */}
        {isNotificationTab &&
        notification_block?.enabled &&
        notification_block?.data?.length > 0 ? (
          <div className="notificationBlockWrapper">
            {notification_block?.data?.map((curItem: any, index: number) => {
              return (
                <NotificationCard
                  key={index}
                  curItem={curItem}
                  hideActionBtn={true}
                />
              );
            })}
          </div>
        ) : null}

        {/* QUICK MENU */}
        {isQuickMenuTab &&
        quick_menu?.enabled &&
        quick_menu?.data?.length > 0 ? (
          <div
            className="quickMenuBlockWrapper"
            style={
              quick_menu.view_style === "Blocks"
                ? {
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "4px",
                    padding: "8px",
                  }
                : { display: "block" }
            }
          >
            {quick_menu?.data?.map((curItem: any, index: number) => {
              if (quick_menu.view_style === "Lists") {
                return (
                  <QuickListCard
                    key={index}
                    curItem={curItem}
                    hideActionBtn={true}
                  />
                );
              }

              return (
                <BlockCard key={index} curItem={curItem} hideActionBtn={true} />
              );
            })}
          </div>
        ) : null}

        {/* BANNER CAROUSEL */}
        {isBannerCarouselTab &&
        banner_carousel?.enabled &&
        banner_carousel?.data?.length > 0 ? (
          <div className="bannerCarouselBlockWrapper">
            <AppRenderBannerCarousel items={banner_carousel?.data ?? []} />
          </div>
        ) : null}

        {/* RENDER FAKE MSGS */}
        {FAKE_MSG_DATA?.map((curItem: any, index: number) => {
          return (
            <WebBotStaticRenderText
              userMessage={curItem.userMessage}
              key={index.toString()}
            >
              {curItem.message}
            </WebBotStaticRenderText>
          );
        })}
      </WidgetMainChat>
    </WidgetMainContent>
  );
};

export default WebBotStaticChat;
