import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { ApexChatWrapper } from "../../Broadcast_Dashboard/Email_Broadcast_Overview_Summary/Doughnout";
import { TextTitle } from "../../Broadcast_Dashboard/Email_Broadcast_Overview_Summary/EmailBroadcastSummaryStyles";
import { ErrorComponent } from "../../Broadcast/SMS/SenderID/ListingStyles";
import NoData from "../../../assets/images/noDatafound.svg";
import SkeletonLoader from "../../../AppComponents/Loader/SkeletonLoader";

interface Props {
  campaignData?: any;
  loading?: boolean;
}

interface RowProps {
  row: any;
  i: any;
  open: any;
  handleRowClick: any;
}

const Row: React.FC<RowProps> = ({
  key,
  row,
  i,
  handleRowClick,
  open,
}: any) => {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell style={{ width: "5%" }}>
          {row?.name === "Direct" || row?.value?.total === 0 ? null : (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleRowClick(i)}
            >
              {open === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: "65%" }}>
          {row?.name}
        </TableCell>
        <TableCell align="center">{row?.value?.total}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open === i} timeout="auto" unmountOnExit={true}>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  {row?.value?.data?.map((historyRow: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className="channel-table-cell"
                      >
                        {historyRow?.name}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ color: "#7E8392", height: "60px" }}
                      >
                        {historyRow?.count}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const ChannelOverview: React.FC<Props> = ({ campaignData, loading }) => {
  const [open, setOpen] = React.useState(null);

  const handleRowClick = (rowIndex: any) => {
    setOpen((prevOpenRow) => (prevOpenRow === rowIndex ? undefined : rowIndex));
  };

  return (
    <div>
      <ApexChatWrapper>
        <Box className="heading__tooltip">
          <TextTitle>
            <Typography className="heading">Acquisition Overview</Typography>
          </TextTitle>
          <AppToolTip
            tooltipProps={{
              title:
                "Insights into the types of devices used by recipients to interact with emails, providing information on the diversity of devices utilized for accessing email content.",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={
              <AppMaterialIcons
                iconName="HelpOutline"
                style={{ fontSize: "10px" }}
              />
            }
          />
        </Box>
      </ApexChatWrapper>

      <TableContainer
        component={Paper}
        style={{
          maxHeight: "271px",
          overflow: "auto",
          padding: "0 1rem 1rem 1rem",
          background: "#fff !important",
          zIndex: 1,
          borderRadius: "8px",
        }}
        elevation={0}
      >
        {loading ? (
          <SkeletonLoader numberOfCol={3} numberofRow={2} />
        ) : campaignData.length === 0 ? (
          <ErrorComponent style={{ height: "225px" }}>
            <Box>
              <img src={NoData} width="150" alt="" />
            </Box>
            <Box className="err-content">
              <Typography variant="h6" fontSize="20px">
                No Records Found.
              </Typography>
            </Box>
          </ErrorComponent>
        ) : (
          <Table aria-label="collapsible table">
            <TableHead
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                background: "#fff",
              }}
            >
              <TableRow>
                <TableCell />
                <TableCell> Device Type </TableCell>
                <TableCell align="center">Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignData?.length > 0 &&
                campaignData?.map((row: any, index: number) => (
                  <Row
                    key={index + 1}
                    row={row}
                    i={index}
                    open={open}
                    handleRowClick={handleRowClick}
                  />
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default ChannelOverview;
