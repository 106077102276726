// vendors
import { styled, alpha } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";

interface ToolbarContainerProps extends BoxProps {
  isActiveNode?: boolean;
  isUnSavedNode?: boolean;
}

export const ToolbarContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== "isActiveNode" && prop !== "isUnSavedNode";
  },
})<ToolbarContainerProps>(({ isActiveNode, isUnSavedNode }) => ({
  position: "absolute",
  top: 0,
  right: "-60px",
  paddingLeft: "22px",
}));

export const ToolbarActionsWrapperStyled = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;
  background-color: ${(props) => props.theme.palette.common.white};
  box-shadow: 0px 2px 4px
    ${(props) => alpha(props.theme.palette.common.black, 0.08)};
  border-radius: 8px;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: 0;
  & .MuiFormControlLabel-label {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #7e8392;
    padding-left: 4px;
  }
`;
