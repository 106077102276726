import styled from "styled-components";
import { device } from "../../Customization/BreakPoints";
import { theme } from "../../Customization/Theme";

export const Container = styled.div`
  & {
    height: calc(100vh - 68px);
    background-color: ${theme.palette.default.lightGrey};
  }
`;
export const IconWrapper = styled.div`
  & {
    padding-left: 23%;
  }
`;
export const ContentWrapper = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    gap: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 68px);
    @media ${device.laptop2} {
      display: flex;
      flex-direction: column;
    }
  }
`;
export const ListWrapper = styled.div`
  && {
  }
`;

export const InfoText = styled.div`
  && {
    max-width: 758px;
    h1 {
      color: ${theme.palette.primary.light};
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      margin: 1rem 0;
    }

    li {
      margin-left: 1.1rem;
      line-height: 23px;
      font-size: 16px;
      font-family: "Rubik";
      margin-bottom: 1rem;
    }

    .button {
      widht: 127px;
      height: 37px;
    }
  }
`;
