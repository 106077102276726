import { createAsyncThunk } from "@reduxjs/toolkit";
import { SMSNetworkService } from "./smsNetworkService";
import { templateDataToUserFormData } from "../../../Components/Broadcast/SMS/ScheduledBroadcast/Utils/utils";

export class SMSActions {

  // * GET SMS Template List
  static getSMSTemplateListData = createAsyncThunk(
    "SMSSlice/getSMSTemplateListData",
    (data: any, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.getSMSTemplateList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    } 
  );
  static getSMSTemplateTagData = createAsyncThunk(
    "SMSSlice/getSMSTemplateTagtData",
    (data: any, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.getSMSTemplateTag(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getSMSTemplateTagListOptions = createAsyncThunk(
    "SMSSlice/getSMSTemplateTagListData",
    (dispatch, rejectWithValue ) => {
      return SMSNetworkService.getSMSTemplateTagList()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return (error?.response);
        })
        .finally(() => {});
    }
  );

  static getSMSTemplateTagUpdate = createAsyncThunk(
    "SMSSlice/getSMSTemplateTagUpdate",
    (data: any, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.getSMSTemplateUpdateTag(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getSMSTemplateTagDelete = createAsyncThunk(
    "SMSSlice/getSMSTemplateTagDelete",
    (data: any, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.deleteSMSTemplateTags(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getSMSAnalyticsListData = createAsyncThunk(
    "SMSSlice/getSMSAnalyticsListData",
    (data: any, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.getSMSAnalyticsList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        }) 
        .finally(() => {});
    }
  );
  static getSMSBroadcastDetailList = createAsyncThunk(
    "SMSSlice/getSMSBroadcastDetailList",
    (data:any, { dispatch, rejectWithValue }) => {
      let id = data.id || {};
      return SMSNetworkService.getSMSBroadcastDetailList(id, data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static removeFilteredData = createAsyncThunk(
    "WhatsappSlice/postFilteredData",
    ({}: any, { dispatch, rejectWithValue }) => {
      return rejectWithValue(null);
    }
  );

    
    // * GET TEMPLATE BY ID
    static getTemplateById = createAsyncThunk(
      "SMSSlice/getTemplateById",
      (id: string, { dispatch, rejectWithValue }) => {
        return SMSNetworkService.getTemplateById(id)
          .then(async (response: any) => {
            let data: any = templateDataToUserFormData(response?.data);
            return data;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => {});

      }
    );
    // * Delete TEMPLATE BY ID
    static deleteSmsTemplateById = createAsyncThunk(
      "SMSSlice/deleteSmsTemplateById",
      (id: string, { dispatch, rejectWithValue }) => {
        return SMSNetworkService.DeleteTemplateById(id)
          .then(async (response: any) => {
            let data: any = templateDataToUserFormData(response?.data);
            return data;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => {});

      }
    );
  
    // // * REMOVE TEMPLATE BY ID
    // static removeTemplateById = createAsyncThunk(
    //   "SMSSlice/getTemplateById",
    //   ({}: any, { dispatch, rejectWithValue }) => {
    //     return rejectWithValue(null);
    //   }
    // );


  // * GET SMS SenderID List
  static getSMSSenderIDListData = createAsyncThunk(
    "SMSSlice/getSMSSenderIDListData",
    (status: any, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.getSMSSenderIDList(status)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

    //* UPLOAD SMSFILE RESPONSE //
    static uploadSMSDocumentFile = createAsyncThunk(
      "SMSSlice/uploadSMSDocumentFile",
      (data: any, { dispatch, rejectWithValue }) => {
        let { formData, config } = data || {};
        return SMSNetworkService.uploadSmsDocumentFile(formData, config)
          .then(async (response: any) => {
            return response;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => {});
      }
    );

    //* POST SMS Broadcast //
    static postSMSBroadcast = createAsyncThunk(
      "SMSSlice/postSMSBroadcast",
      (data: any, { dispatch, rejectWithValue }) => {
        return SMSNetworkService.postSMSBroadcast(data)
          .then(async (response: any) => {
            
            return response.data;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => {});
      }
    );
     // delete Broadcasts
   static deleteSmsBroadcastById= createAsyncThunk(
    "EmailSlice/deleteWhatsappBroadcastById",
    (data: any, { dispatch, rejectWithValue }) => {

      return SMSNetworkService.deleteSmsBroadcast(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

    //* testSMS // 
    static postTestSMS = createAsyncThunk(
      "SMSSlice/postTestSMS",
      (data: any, { dispatch, rejectWithValue }) => {
        return SMSNetworkService.postTestSMS(data)
          .then(async (response: any) => {
            return response;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => { });
      }
    );

    //* UPDATE SMS Broadcast //
    // static putWhatsappBroadcast = createAsyncThunk(
    //   "SMSSlice/postWhatsappBroadcast",
    //   (data: any, id: any, { dispatch, rejectWithValue }) => {
    //     return SMSNetworkService.updateSMSBroadcast(data, id)
    //       .then(async (response: any) => {
    //         return response;
    //       })
    //       .catch((error: { response: unknown }) => {
    //         return rejectWithValue(error?.response);
    //       }) 
    //       .finally(() => {});
    //   }
    // );

    // * GET Broadcast BY ID
    static getBroadcastById = createAsyncThunk(
      "SMSSlice/getBroadcastById",
      (id: string, { dispatch, rejectWithValue }) => {
        return SMSNetworkService.getBroadcastById(id)
          .then(async (response: any) => {
            return response;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => {});
      }
    );
    //* getFieldsTypeMappingList //
  static getFieldsTypeMappingList = createAsyncThunk(
    "SMSSlice/getFieldsTypeMappingList",
    (id: string, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.getFieldsTypeMappingList(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getSMSTemplateCategories = createAsyncThunk(
    "SMSSlice/getSMSTemplateCategories",
    (data: any, { dispatch, rejectWithValue }) => {
      return SMSNetworkService.getSMSTemplateCategories()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static testSMSData = createAsyncThunk(
    "SMSSlice/testSMSData",
    (html: any, { dispatch, rejectWithValue }) => {
      return html;
    })
}
  

