import React from "react";

import { Backdrop, CircularProgress } from "@mui/material";
import styled from "styled-components";
import useInternetConnection from "../../hooks/useInternetConnection";

const NO_INTERNET_MSG =
  "You are offline. Please connect to the internet to continue.";

const AppInternetReconnectingBox: React.FC = () => {
  const { isOnline } = useInternetConnection();

  if (isOnline) return null;

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      onClick={undefined}
    >
      <BoxContainer>
        <CircularProgress color="inherit" />
        <p>{NO_INTERNET_MSG}</p>
      </BoxContainer>
    </Backdrop>
  );
};

export default AppInternetReconnectingBox;

export const BoxContainer = styled.div`
  && {
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    height: 100vh;
    width: 100%;
    p {
      font-size: 1.1rem;
    }
  }
`;
