import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { CustomTabPanelContainer, WebsiteWrap } from "./TopMenu2";
import { theme } from "../../Customization/Theme";
import { EmailBroadcastDashboardOptions } from "../../Models/defaultSelectOptions";
import BroadcastTopInfoBar from "../Broadcast/components/BroadcastTopInfoBar";
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { useSelector } from "react-redux";
import { broadcastDataById, broadcastType } from "../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import styled from "styled-components";


interface Props { }

export const TopMenubar: React.FC<Props> = () => {
  const [value, setValue] = useState(0);
  const broadcastTypeData = useSelector(broadcastType);
  const broadcastDataByIdData = useSelector(broadcastDataById);
  const navigate = useNavigate();
  const location: any = useLocation();
  const { broadcast_id } = useParams();
  const EmailBroadcastDashboardOptionsMemo = useMemo(() => {
    return EmailBroadcastDashboardOptions.map((option: any) => ({
      ...option,
      url: option.url,
    }));
  }, [location]);   // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentTabAfterRefresh = () => {
    EmailBroadcastDashboardOptionsMemo.forEach((item: any, index: number) => {
      if (location.pathname.indexOf(item.value) > -1) {
        setValue(index);
      }
    });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (broadcast_id) {
      let url: string = EmailBroadcastDashboardOptionsMemo[newValue].url.replace("{0}", broadcast_id);
      navigate(url);
    }
  };

  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [location]);   // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WebsiteWrap>
      <Box sx={{ width: "100%", height: "100%" }}>
        {EmailBroadcastDashboardOptionsMemo.map(
          (option: any, index: number) => (
            <CustomTabPanel value={value} index={index} key={index}>
              <TabWrapper>
                <BroadcastTopInfoBar
                  title={broadcastTypeData?.broadcast_name || broadcastDataByIdData?.broadcast_name}
                  date={broadcastTypeData?.broadcast_details?.sent_on || broadcastDataByIdData?.sent_on}
                  type="email"
                />
                <Tabs
                  className="website-tabs"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  style={{
                    display: "flex !important",
                    flexDirection: "row",
                    alignItems: "center !important",
                    justifyContent: "space-between !important",
                    width: "100%",
                  }}
                >
                  {EmailBroadcastDashboardOptionsMemo?.map(
                    (option: any, index: number) => (
                      <Tab
                        sx={{ minHeight: "55px" }}
                        label={option.name}
                        id={`simple-tab-${index}`}
                        key={index}
                        icon={option?.name === "Overview" ? <AutoGraphOutlinedIcon /> : <ArticleOutlinedIcon />}
                        iconPosition="start"
                      />
                    )
                  )}
                </Tabs>
              </TabWrapper>
              <Outlet />
            </CustomTabPanel>
          )
        )}
      </Box>
    </WebsiteWrap>
  );
};


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <CustomTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }} style={{}} >
          {children}
        </Box>
      )}
    </CustomTabPanelContainer>
  );
}


const TabWrapper = styled.div`
&& {
   width: calc(100% - 4px);
   margin-left: 4px;
  display: flex; 
  border-bottom:  1px solid ${theme.palette.default.grey};
  align-items: center;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1;
  .website-tabs {
     background-color: ${theme.palette.default.greyishWhite};
  }
}
`;
