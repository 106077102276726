import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import NoRecordDefaultImage from "../../../assets/images/noDatafound.svg";
import { theme } from '../../../Customization/Theme';

interface NoRecordsFoundProps {
    imageWidth?: string;
    imageSrc?: any;
    altText?: string;
    headerText?: string;
    headerTextSize?: string;
    headerTextColor?: string;
    subText?: string;
    subTextSize?: string;
    subTextColor?: string;
    bgColor?: string;
    height?: string;
    overflow?: boolean;
    border?: boolean;
    borderRadius?: string;
    children?: any;
}

const NoRecordsFound: React.FC<NoRecordsFoundProps> = ({
    imageWidth = "170px",
    imageSrc = NoRecordDefaultImage,
    altText = 'No Record Image',
    headerText = 'No Records Found!',
    headerTextSize = "20px",
    headerTextColor = `${theme.palette.default.black}`,
    subText = 'Sorry, there are no records available.',
    subTextSize = "1rem",
    subTextColor = `${theme.palette.default.darkGrey}`,
    bgColor = `${theme.palette.default.white}`,
    height = "100%",
    overflow = false,
    border = false,
    borderRadius = "8px",
    children
}) => {
    return (
        <Grid
            container
            // spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="no-records-container"
            height={height}
            width={"100%"}
            sx={{
                backgroundColor: bgColor,
                overflow: overflow ? "auto" : "hidden",
                borderRadius: borderRadius,
                border: border ? `1px solid ${theme.palette.default.border}` : "none",
            }}
        >
            <Grid item xs={12}>

                <Box
                    mb={"20px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <img width={imageWidth} src={imageSrc} alt={altText} className="no-records-image" />
                </Box>

                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Box>
                        <Typography
                            variant="h3"
                            fontSize={headerTextSize}
                            sx={{
                                fontSize: `${headerTextSize} !important`,
                                fontWeight: "500 !important",
                                color: `${headerTextColor} !important`,
                            }}
                            className="no-records-header"
                            mb={"2px"}
                            color={theme.palette.default.black}
                        >
                            {headerText}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            color={subTextColor}
                            variant="body2"
                            className="no-records-subtext"
                            fontSize={subTextSize}
                        >
                            {subText}
                        </Typography>
                        {
                            children && <Box mt={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                {children}
                            </Box>
                        }
                    </Box>
                </Box>

            </Grid>
            
        </Grid>
    );
};

export default NoRecordsFound;
