import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { WhatsappActions } from "./whatsapp.actions";
// import { broadcastActions } from "./broadcast.actions";

interface initialStateTypes {
  data: {
    allTemplatesData: any;
    templateByIdData: any;
    contactListData: any;
    dummyContactListData: any;
    filteredData: any;
    filteredDataTotalCount: number;
    fieldsTypeMappingListData: any;
    postWhatsappBroadcastData: any;
    allMarketingListData: any;
    broadcastByIdData: any;
    testWhatsappModel: any;
    testWhatsappSucessMessage: any;
    deleteError: string;
    deleteSuccess: string;
    whatsappEnable: boolean;
    broadcastRecipientFilterData: any;
    templateButtonsLengthChecker: false,
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    allTemplatesData: null,
    templateByIdData: null,
    contactListData: null,
    dummyContactListData: null,
    filteredData: null,
    filteredDataTotalCount: 0,
    fieldsTypeMappingListData: null,
    postWhatsappBroadcastData: null,
    allMarketingListData: null,
    broadcastByIdData: null,
    testWhatsappModel: false,
    testWhatsappSucessMessage: null,
    deleteError: "",
    deleteSuccess: "",
    whatsappEnable: false,
    broadcastRecipientFilterData: [],
    templateButtonsLengthChecker: false,
  },
  error: "",
  loading: false,
};

const WhatsappSlice = createSlice({
  name: "WhatsappSlice",
  initialState,
  reducers: {
    isWhatsappEnabled: (state: any, action: PayloadAction<any>) => {
      state.data.whatsappEnable = action?.payload;
      return state;
    },
    resetTemplateData: (state: any, action: PayloadAction<any>) => {
      state.data.templateByIdData = null;
      state.data.testWhatsappModel = false;
      return state;
    },
    testWhatsappData: (state: any, action: PayloadAction<any>) => {
      state.data.testWhatsappModel = action.payload;
      return state;
    },
    resetError: (state: any, action: PayloadAction<any>) => {

      state.data.deleteError = action.payload
      state.data.deleteSuccess = action.payload

      return state;
    },
    saveRecipientFilterData: (state: any, action: PayloadAction<any>) => {
      state.data.broadcastRecipientFilterData = action.payload;
      return state;
    },
    setTemplateButtonsLengthChecker: (state: any, action: PayloadAction<any>) => {
      let counter = 0;
      let website_url = 0;
      let quick_replies = 0;

      const coupon_code = action?.payload?.copyUrlOptions?.find((option: any) => option.name === "add_coupon_code" && option.checked) ? 1 : 0;
      const add_phone_number = action?.payload?.copyUrlOptions?.find((option: any) => option.name === "add_phone_number" && option.checked) ? 1 : 0;

      if (action?.payload?.copyUrlOptions?.find((option: any) => option.name === "add_website_url" && option.checked)) {
        website_url = action?.payload?.website_url?.length || 0;
      }

      if (action?.payload?.copyUrlOptions?.find((option: any) => option.name === "add_quick_replies" && option.checked)) {
        quick_replies = action?.payload?.quick_replies?.length || 0;
      }
      counter = coupon_code + add_phone_number + website_url + quick_replies;
      counter = Math.max(counter, 0);
      state.data.templateButtonsLengthChecker = counter >= 10 ? true : false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder

      //* all templates
      .addCase(
        WhatsappActions.getTemplatesListings.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        WhatsappActions.getTemplatesListings.fulfilled,
        (state, action) => {
          state.data.allTemplatesData = action.payload?.data?.results;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        WhatsappActions.getTemplatesListings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allTemplatesData = [];
          state.error = action?.error?.message || "";
        }
      )

      //* template by id
      .addCase(WhatsappActions.getTemplateById.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(WhatsappActions.getTemplateById.fulfilled, (state, action) => {
        state.data.templateByIdData = action.payload;
        state.error = "";
        state.loading = false;
      })
      .addCase(WhatsappActions.getTemplateById.rejected, (state, action) => {
        state.loading = false;
        state.data.templateByIdData = null;
        state.error = action?.error?.message || "";
      })

      //* broadcast by id
      .addCase(WhatsappActions.getBroadcastById.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(WhatsappActions.getBroadcastById.fulfilled, (state, action) => {
        state.data.broadcastByIdData = action.payload?.data;
        state.error = "";
        state.loading = false;
      })
      .addCase(WhatsappActions.getBroadcastById.rejected, (state, action) => {
        state.loading = false;
        state.data.broadcastByIdData = null;
        state.error = action?.error?.message || "";
      })

      //* getFieldsTypeMappingList
      .addCase(
        WhatsappActions.getFieldsTypeMappingList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        WhatsappActions.getFieldsTypeMappingList.fulfilled,
        (state, action) => {
          state.data.fieldsTypeMappingListData = action.payload?.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        WhatsappActions.getFieldsTypeMappingList.rejected,
        (state, action) => {
          state.loading = false;
          state.data.fieldsTypeMappingListData = null;
          state.error = action?.error?.message || "";
        }
      )

      //* contactListData
      .addCase(WhatsappActions.getContactListData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        WhatsappActions.getContactListData.fulfilled,
        (state, action) => {
          state.data.contactListData = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(WhatsappActions.getContactListData.rejected, (state, action) => {
        state.loading = false;
        state.data.contactListData = null;
        state.error = action?.error?.message || "";
      })

      //* post filtered data queries
      .addCase(WhatsappActions.postFilteredData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(WhatsappActions.postFilteredData.fulfilled, (state, action) => {
        state.data.filteredData = action.payload.data?.response;
        state.error = "";
        state.loading = false;
      })
      .addCase(WhatsappActions.postFilteredData.rejected, (state, action) => {
        state.loading = false;
        state.data.filteredData = null;
        state.error = action?.error?.message || "";
      })

      //* post whatsapp broadcast data
      .addCase(
        WhatsappActions.postWhatsappBroadcast.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        WhatsappActions.postWhatsappBroadcast.fulfilled,
        (state, action) => {
          state.data.postWhatsappBroadcastData = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        WhatsappActions.postWhatsappBroadcast.rejected,
        (state, action) => {
          state.loading = false;
          state.data.postWhatsappBroadcastData = null;
          state.error = action?.error?.message || "";
        }
      )

      //get delete broadcast list
      .addCase(
        WhatsappActions.deleteWhatsappBroadcastById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        WhatsappActions.deleteWhatsappBroadcastById.fulfilled,
        (state, action) => {


          state.data.deleteSuccess = action?.payload?.data?.message || "Broadcast Deleted Successfully";
          state.data.deleteError = "";
          state.loading = false;
        }
      )
      .addCase(
        WhatsappActions.deleteWhatsappBroadcastById.rejected,
        (state, action: any) => {
          state.loading = false;
          state.data.deleteSuccess = "";
          state.data.deleteError = action?.payload?.data?.message || "Failed to Delete Broadcast";
        }
      )


      //* dummyContactListData *//
      .addCase(
        WhatsappActions.postDummyContactData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        WhatsappActions.postDummyContactData.fulfilled,
        (state, action) => {
          state.data.filteredData = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        WhatsappActions.postDummyContactData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.filteredData = null;
          state.error = action?.error?.message || "";
        }
      )
      //* get existing temp data *//
      .addCase(WhatsappActions.getExistingTempData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        WhatsappActions.getExistingTempData.fulfilled,
        (state, action) => {
          state.data.filteredData = action.payload.data?.response;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        WhatsappActions.getExistingTempData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.filteredData = null;
          state.error = action?.error?.message || "";
        }
      )
      //* test sms send
      .addCase(
        WhatsappActions.postTestWhatsappMessage.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        WhatsappActions.postTestWhatsappMessage.fulfilled,
        (state, action) => {
          state.data.testWhatsappSucessMessage = action.payload.data?.message;
        }
      )
      .addCase(
        WhatsappActions.postTestWhatsappMessage.rejected,
        (state, action) => {
          state.loading = false;
          state.data.testWhatsappSucessMessage = null;
          state.error = action?.error?.message || "";
        }
      )
  },
});

export const { resetTemplateData, testWhatsappData, resetError, isWhatsappEnabled, saveRecipientFilterData, setTemplateButtonsLengthChecker } = WhatsappSlice.actions;

export default WhatsappSlice.reducer;
