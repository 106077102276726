import React from "react";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import useModal from "../../../../../Hooks/useModel/useModel";
import UploadTemplate from "../../../SMS/BroadCastTemplate/UploadTemplate";
import { BroadcastListWrapper, WrapperContainer } from "../HistoryStyles";
import LogsTable from "./components/LogsTable";


const WhatsappBroadcastHistoryLogs = () => {
 
  const { isShowing, toggle } = useModal();
  
  
  return (
      <React.Fragment>
          <WrapperContainer className="check">
              <BroadcastListWrapper>
                  <LogsTable  />
              </BroadcastListWrapper>
          </WrapperContainer>
          <AppModel
              isShowing={isShowing}
              onClose={() => toggle()}
              divStyle={{
                  padding: 0,
              }}
              PaperProps={{
                  style: {
                      boxShadow: "none",
                      width: "500px",
                  },
              }}
          >
              <UploadTemplate />
          </AppModel>
      </React.Fragment>
  );
};

// Default Export of component
export default WhatsappBroadcastHistoryLogs;
