import { Link } from "@mui/material"
import styled from "styled-components"
import { Title } from "../style"
import { useNavigate } from "react-router-dom"


const CreditRateList = (props:any) => {
    const navigate = useNavigate()
    return (
        <CreditRateWapper>
            <PricePlanWapper>
                <Title fontSize={45}>Pricing and plans</Title>
                <Title fontSize={15} color={"gray"}>Find pricing for all our communication channels, products, numbers, and services. Be sure to add funds to your account balance before you start sending messages.</Title>
            </PricePlanWapper>
            {["SMS", "WhatsApp", "Email"].map((res: any) => {
                return (
                    <CreditRate>
                        <Title fontSize={25}>{res}</Title>
                        <DetailedPricing>
                            <Link
                                //   href={curItem?.button_url}
                                onClick={() =>   props.setSelectBroadcast(res)}
                                sx={{ textDecoration: "none !important" }}
                            >
                                Detailed pricing
                            </Link>
                        </DetailedPricing>
                    </CreditRate>
                )
            })}
        </CreditRateWapper>
    )
}
const CreditRate = styled.div`
    display: flex;
    -webkit-box-pack: justify;
    border-bottom: 1px solid lightgray;
    padding: 45px 11px;
    justify-content: space-between;
`
const CreditRateWapper = styled.div`
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0,0,0,.17);
    padding: 48px;
`
const DetailedPricing = styled.p`
    text-transform : uppercase;
    cursor: pointer;
`
const PricePlanWapper = styled.div`
display :grid;
width:55rem
`
export default CreditRateList