import { AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, IconButton, MenuItem, OutlinedInput, Select, Switch, TextField, Tooltip } from "@mui/material";
import AppButton from "../Components/Button"
import { AccordionDelete, AccordionInput, AccordionSelect, AddScreenModel, FlowBuilderContentAccordian, FlowBuilderContentHeader, FlowBuilderContentList, FlowBuilderContentWapper, FlowBuilderScreenHeader, FlowBuilderScreenWapper, ImgaeUploadStyle } from "../Utils/style"
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import AppInput from "../Components/Input";
import { getContactMapping, getContentData, getFlowBuilderList, getScreenId } from "../Store/FlowBuilder.selectors";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../Store";
import { setContactMapping, setContentData, setFlowBuilderList } from "../Store/FlowBuilder.slice";
import { MenuProps, convertArray, generateChoiceData, generateFormElementData, generateHeadingData, generateOptInData, generateUniqueName, getContentTitle, getSectionCount, getTextConentTextDropdownOption, getTextInputConentTextDropdownOption, randomString } from "../Utils/common";
import AppTextArea from "../Components/Textarea";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import DeleteIcon from '@mui/icons-material/Delete';
import styled from "styled-components";
import AppModel from "../Components/Model";
import { useEffect, useState } from "react";
import WarningIcon from '@material-ui/icons/Warning';
import { contactListActions } from "../../Store/Slices/ContactList/ContactList.actions";
import { FlowBuilderActions } from "../Store/FlowBuilder.actions";
const FlowBuilderContent = () => {
    const dispatch = useAppDispatch()
    const contentData: any = useSelector(getContentData);
    const flowBuilderList = useSelector(getFlowBuilderList);
    const contactMapping = useSelector(getContactMapping);
    const [isShowing, setIsShowing] = useState(false)
    const [uploadError, setUploadError] = useState(false)
    const currentScreen = useSelector(getScreenId);
    const [selectContectData, setSelectContectData] = useState<any>({})
    const [propertyList, setPropertyList] = useState<any>([])
    const [propertyName, setPropertyName] = useState<any>()
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [errorMessgae, setErrorMessgae] = useState<any>("")
    const changeScreenTitleName = (name: string) => {
        let data = {
            ...contentData,
            title: name
        }
        dispatch(setContentData(data))
    }
    const updateContentData = (updatedChildren: any) => {
        let data = {
            ...contentData,
            layout: {
                ...contentData.layout,
                children: [
                    {
                        ...contentData.layout.children[0],
                        children: updatedChildren
                    }
                ]
            }
        };
        console.log("data-------------->", data);

        dispatch(setContentData(data));
    }
    useEffect(() => {
        dispatch(contactListActions.getAllVisibleContactPropertiesFieldOptions(""))
            .then((res: any) => {
                console.log("res", res);
                setPropertyList(res?.payload?.data?.data)
            })
    }, [])
    const changeScreenButtonName = (name: string) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        const lastChildIndex = updatedChildren.length - 1;
        updatedChildren[lastChildIndex] = {
            ...updatedChildren[lastChildIndex],
            label: name
        };
        updateContentData(updatedChildren)
    };
    const contentSubValue = (value: any) => {
        if (value === "upload_image" || value === "upload_document") {
            // Get the length of the screens array if it exists

            // Find the index of the screen with id "currentScreen" if screens array exists
            let data = flowBuilderList?.screens?.filter((res:any) => !res.id.includes("TERMS_AND_CONDITIONS"))
            let screenLength = data?.length;
            let lastScreenIndex = data?.findIndex((res: any) => res.id === currentScreen);
            
            let isMediaPicker = contentData?.layout?.children[0]?.children?.filter((res: any) => res.type === "PhotoPicker" || res.type === "DocumentPicker")
            if (isMediaPicker.length > 0) {
                setErrorMessgae("Each screen can have at most one PhotoPicker or DocumentPicker component.")
                setUploadError(true)
                return true
            }
            if (screenLength - 1 !== lastScreenIndex) {
                setErrorMessgae("Upload Image and Upload Document components are only allowed on the final screen ( last screen ).")
                setUploadError(true)
                return true
            }
        }
        const textElements: any = {
            large_heading: "TextHeading",
            small_heading: "TextSubheading",
            body: "TextBody",
            caption: "TextCaption",
        };

        const formElements: any = {
            textInput: "TextInput",
            textArea: "TextArea",
            datePicker: "DatePicker",
        };

        const choiceElements: any = {
            single_choice: "RadioButtonsGroup",
            multipal_choice: "CheckboxGroup",
            dropdown: "Dropdown"
        };

        if (value in textElements || value in formElements || value in choiceElements || value === "image" || value === "optIn" || value === "upload_image" || value === "upload_document") {
            let headingData: any = {};

            if (value in textElements) {
                headingData = generateHeadingData(textElements[value], "Text");
            } else if (value in formElements) {
                headingData = generateFormElementData(formElements[value], "Label", generateUniqueName(formElements[value]));
                if (value === "textArea" || value === "datePicker") {
                    delete headingData['input-type']
                }
            } else if (value in choiceElements) {
                headingData = generateChoiceData(choiceElements[value], generateUniqueName(choiceElements[value]));
            } else if (value === "optIn") {
                headingData = generateOptInData("OptIn", generateUniqueName('OptIn'));
            }
            else if (value === "image") {
                headingData = {
                    type: "Image",
                    src: "",
                    height: 400,
                    "scale-type": "contain",
                };
            } else if (value === "upload_image") {
                headingData = {
                    "type": "PhotoPicker",
                    "name": "photo_picker",
                    "label": "Upload photos",
                    "description": "Please attach images about the received items",
                    "photo-source": "camera_gallery",
                    "min-uploaded-photos": 0,
                    "max-uploaded-photos": 1
                };
            } else if (value === "upload_document") {
                headingData = {
                    "type": "DocumentPicker",
                    "name": "document_picker",
                    "label": "Upload document",
                    "description": "Attach the signed copy of the contract",
                    "min-uploaded-documents": 0,
                    "max-uploaded-documents": 1
                }
            }

            if (contentData?.layout?.children?.[0]?.children) {
                const childrenArray = contentData.layout.children[0].children;
                let updatedChildren;

                if (childrenArray.length > 0 && childrenArray[childrenArray.length - 1].type === "Footer") {
                    updatedChildren = [
                        ...childrenArray.slice(0, -1),
                        headingData,
                        childrenArray[childrenArray.length - 1],
                    ];
                } else {
                    updatedChildren = [...childrenArray, headingData];
                }

                const data = {
                    ...contentData,
                    layout: {
                        ...contentData.layout,
                        children: [
                            {
                                ...contentData.layout.children[0],
                                children: updatedChildren,
                            },
                        ],
                    },
                };

                dispatch(setContentData(data));
            } else {
                console.error("contentData.layout.children[0].children is undefined");
            }
        }
    };

    const setHeadingValue = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        if (updatedChildren[index]?.type !== "OptIn") {
            updatedChildren[index] = {
                ...updatedChildren[index],
                text: value
            };
        } else {
            updatedChildren[index] = {
                ...updatedChildren[index],
                label: value
            };
        }

        updateContentData(updatedChildren)
    }
    const setShortAnswerValue = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            label: value
        };
        updateContentData(updatedChildren)
    }
    const setShortAnswerRequired = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        if (updatedChildren[index]?.name === "photo_picker") {
            updatedChildren[index] = {
                ...updatedChildren[index],
                ['min-uploaded-photos']: value == true ? 1 : 0
            };
        } else if (updatedChildren[index]?.name === "document_picker") {
            updatedChildren[index] = {
                ...updatedChildren[index],
                ['min-uploaded-documents']: value == true ? 1 : 0
            };
        }
        else {
            updatedChildren[index] = {
                ...updatedChildren[index],
                required: value
            };
        }

        updateContentData(updatedChildren)
    }
    const setShortAnswerHelperText = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'helper-text': value
        };
        updateContentData(updatedChildren)
    }
    const setDecriptionText = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'description': value
        };
        updateContentData(updatedChildren)
    }
    const setHeadingTextType = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            type: value === 'large_heading' ? "TextHeading" : value === "small_heading" ? "TextSubheading" : value === "body" ? "TextBody" : value === "caption" ? "TextCaption" : ""
        };
        updateContentData(updatedChildren)
    }

    const setShortAnswerType = (value: any, index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'input-type': value
        };
        updateContentData(updatedChildren)
    }
    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const updatedChildren = Array.from(contentData.layout.children[0].children);
        const [movedItem] = updatedChildren.splice(result.source.index, 1);
        updatedChildren.splice(result.destination.index, 0, movedItem);
        updateContentData(updatedChildren)
    }
    const handleRemoveContent = (index: any, content: any) => {
        const updatedChildren = Array.from(contentData.layout.children[0].children);
        updatedChildren.splice(index, 1)
        updateContentData(updatedChildren)
        if (content?.type === "OptIn") {

            let list: any = {
                version: "3.1",
                screens: flowBuilderList.screens.filter(
                    (screen: any) => screen.id !== content['on-click-action']?.next?.name
                ),
            };
            console.log("list-------------------->", list);

            // Dispatch the updated list to the store
            dispatch(setFlowBuilderList(list));

        }
    }
    const getValue = (value: any) => {
        if (!Array.isArray(value['data-source'])) {
            return contentData?.data?.[value?.name]?.['__example__']
        } else {
            return value['data-source']
        }
    }
    const addOptions = (index: any) => {
        const val = Math.floor(1000 + Math.random() * 9000);
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'data-source': [
                ...updatedChildren[index]['data-source'],
                { id: `Option_${val}`, title: "Option" }
            ]
        };
        console.log("updatedChildren", updatedChildren);

        updateContentData(updatedChildren);
    }
    const removeOptions = (index: any, otpIndex: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'data-source': getValue(updatedChildren[index])?.filter((item: any) => item.id !== otpIndex)
        };
        updateContentData(updatedChildren);
    }
    const updateOptionTitle = (index: any, otpId: any, newTitle: string) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'data-source': updatedChildren[index]['data-source']?.map((item: any) => {
                if (item.id === otpId) {
                    return { ...item, title: newTitle };
                }
                return item;
            })

        };
        console.log("updatedChildren", updatedChildren, newTitle, otpId);

        updateContentData(updatedChildren);
    }
    const addReadMoreScreen = (index: any) => {
        const val = randomString(5);
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            "on-click-action": {
                "name": "navigate",
                "next": {
                    "type": "screen",
                    "name": "TERMS_AND_CONDITIONS_" + val
                },
                "payload": {}
            }
        };
        let data = {
            "id": "TERMS_AND_CONDITIONS_" + val,
            "title": "Terms and conditions",
            "data": {},
            "layout": {
                "type": "SingleColumnLayout",
                "children": [
                ]
            }
        }
        let list: any = {
            "version": "3.1",
            "screens": [
                ...flowBuilderList["screens"],
                data
            ]
        }

        dispatch(setFlowBuilderList(list))

        updateContentData(updatedChildren);
    }
    const removeReadMoreScreen = (index: number, data: { [key: string]: any }) => {
        // Make a copy of the children array and update the specific child at the index
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
        };
        delete updatedChildren[index]['on-click-action'];

        // Create a copy of the screens array, removing the screen with the specific name
        console.log("list-------------------->", flowBuilderList, data);

        let list: any = {
            version: "3.1",
            screens: flowBuilderList.screens.filter(
                (screen: any) => screen.id !== data?.next?.name
            ),
        };
        console.log("list-------------------->", list);

        // Dispatch the updated list to the store
        dispatch(setFlowBuilderList(list));

        // Update the content data with the modified children
        updateContentData(updatedChildren);
    };
    const getDropdownOption = (type: any) => {
        if (type === "TextInput") {
            return getTextInputConentTextDropdownOption()
        } else {
            return getTextConentTextDropdownOption()
        }
        return []
    }

    const onImageChange = async (file: any, index: any) => {
        const maxSize = 300 * 1024; // 300KB
        const allowedTypes = ["image/png", "image/jpeg"]; // Allowed file types
        let base64String: any
        if (file) {
            if (file.size > maxSize) {
                console.error("File size exceeds the limit of 300KB");
                alert("File size exceeds the limit of 300KB");
                return;
            }
            if (!allowedTypes?.includes(file?.type)) {
                console.error("Only PNG and JPG files are allowed");
                alert("Only PNG and JPG files are allowed");
                return;
            }
            const formData = new FormData();
            formData.append("source", file);
            formData.append("resolved", "true");
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64StringWithPrefix: any = reader.result;
                    base64String = base64StringWithPrefix?.replace(/^data:image\/[a-z]+;base64,/, '');
                    let updatedChildren = [...contentData.layout.children[0].children];
                    updatedChildren[index] = {
                        ...updatedChildren[index],
                        'src': base64String
                    };
                    updateContentData(updatedChildren)
                };
                reader.readAsDataURL(file);
            }
        }
    };
    const resetImageSource = (index: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'src': ""
        };
        updateContentData(updatedChildren)
    }
    const setImageHeight = (index: any, value: any) => {
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            'height': Number(value)
        };
        updateContentData(updatedChildren)
    }
    const setContactMappingFn = () => {
        console.log("propertyName", propertyName);
        let updatedChildren = [...contentData.layout.children[0].children];
        if (propertyName.type === "dropdown") {
            dispatch(FlowBuilderActions.dropDownValue(propertyName?.id))
                .then(async (res: any) => {
                    let options = await convertArray(res?.payload?.data?.data?.options)
                    updatedChildren[selectedIndex] = {
                        ...updatedChildren[selectedIndex],
                        "data-source": options,
                        name: propertyName?.name
                    };
                    updateContentData(updatedChildren)
                })
                .catch((error: any) => {
                    // setLoading(false)
                })
        } else {
            if (selectContectData && propertyName) {
                let data = [{
                    id: "option",
                    title: "Option 1"
                }]
                updatedChildren[selectedIndex] = {
                    ...updatedChildren[selectedIndex],
                    name: propertyName?.name,
                    "data-source": data,
                };
               
                updateContentData(updatedChildren)
            }
        }
        dispatch(setContactMapping({
            ...contactMapping,
            [propertyName?.name]: propertyName?.name
        }));
        setIsShowing(false)
        setPropertyName("")
    }
    const setSaveProperty = (event: any, content_Data: any, index: any) => {
        setSelectContectData(content_Data)
        setSelectedIndex(index)
        setPropertyName("")
        const val = randomString(5);
        console.log("event", event.target?.checked);
        setIsShowing(true);
        setPropertyName("")

        // if (!event.target?.checked) {
        //     let data: any = { ...contactMapping }
        //     delete data[content_Data?.name]
        //     let updatedChildren = [...contentData.layout.children[0].children];
        //     updatedChildren[index] = {
        //         ...updatedChildren[index],
        //         name: updatedChildren[index]?.name + val
        //     };
        //     updateContentData(updatedChildren)
        //     dispatch(setContactMapping(data));
        // } else {
        //     setIsShowing(true);
        //     setPropertyName("")
        // }
    }
    const removeSaveProperty = (event: any, content_Data: any, index: any) => {
        setSelectContectData(content_Data)
        setSelectedIndex(index)
        setPropertyName("")
        const val = randomString(5);
        let data: any = { ...contactMapping }
        delete data[content_Data?.name]
        let updatedChildren = [...contentData.layout.children[0].children];
        updatedChildren[index] = {
            ...updatedChildren[index],
            name: updatedChildren[index]?.name + val
        };
        updateContentData(updatedChildren)
        dispatch(setContactMapping(data));
        // if (!event.target?.checked) {
        //     let data: any = { ...contactMapping }
        //     delete data[content_Data?.name]
        //     let updatedChildren = [...contentData.layout.children[0].children];
        //     updatedChildren[index] = {
        //         ...updatedChildren[index],
        //         name: updatedChildren[index]?.name + val
        //     };
        //     updateContentData(updatedChildren)
        //     dispatch(setContactMapping(data));
        // } else {
        //     setIsShowing(true);
        //     setPropertyName("")
        // }
    }
    const CustomCheckbox = styled(Checkbox)({
        // Override the CSS here
        // margin: "-4px -8px 5px 2px !important",
        backgroundColor: "white !important",
        '&.MuiButtonBase-root .MuiSvgIcon-root': {
            margin: "-4px -8px 5px 2px !important", // Change the color when checked
        },
        // Add more custom styles as needed
    });
    console.log("contentData =======================>", contentData, currentScreen, contactMapping, propertyName);
    const filterArray = () => {
        const keysToMatch: any = contentData?.layout?.children[0]?.children?.map((field: any) => field.name).filter((name: any) => name !== undefined);
        const filteredArray = propertyList.filter((item: any) => !keysToMatch?.includes(item?.name));
        return filteredArray || propertyList
    }
    const getDropDownValue = (options: any) => {
        if (!Array.isArray(options['data-source'])) {
            return contentData?.data?.[options?.name]?.['__example__']
        } else {
            return options['data-source']
        }
    }
    return (
        <FlowBuilderContentWapper>
            <FlowBuilderContentHeader>
                <div className="headerTitle">Edit content</div>
                <Tooltip componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            color: "common.black",
                            padding: "5px !important",
                            border: "1px solid lightgray",
                            fontSize: "12px",
                            '& .MuiTooltip-arrow': {
                                color: 'common.black',
                            },
                        },
                    },
                }} title={contentData?.layout?.children[0]?.children?.length === 9 ? "You can add a maximum of 8" : ""}>
                    <span> <AppButton disabled={!currentScreen ? true : contentData?.layout?.children[0]?.children?.length === 9} onSubMenuClick={contentSubValue} startIcon={<AddIcon />} isSubMenu={true} endIcon={<ArrowDropDownIcon />} backgroundColor={!currentScreen ? "rgba(229,232,240,1)" : contentData?.layout?.children[0]?.children?.length === 9 ? "rgba(229,232,240,1)" : "rgb(33, 28, 82)"} text={"Add Content"} /> </span>
                </Tooltip>
            </FlowBuilderContentHeader>
            <div className="body" >


                <FlowBuilderContentList>
                    <FlowBuilderContentAccordian style={{ padding: "0px 9px !important" }}>
                        <AccordionSummary
                            expandIcon={<GridExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Screen title{contentData?.title === "" ? <WarningAmberIcon style={{
                                transform: "",
                                color: "red",
                                marginLeft: "6px",
                                fontSize: "20px"
                            }} /> : null}
                        </AccordionSummary>
                        <AccordionDetails style={{ textAlign: "justify", color: contentData?.title === "" ? "red" : "" }}>
                            <AppInput handleChange={changeScreenTitleName} value={contentData?.title} placeholder="Enter your screen name" />
                            {contentData?.title === "" ? <a>This field cannot be empty</a> : null}
                        </AccordionDetails>
                    </FlowBuilderContentAccordian>
                </FlowBuilderContentList>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable-1" type="row">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                {
                                    contentData?.layout?.children[0]?.children?.map((content: any, index: any) => {
                                        return (
                                            <Draggable key={index} draggableId={index?.toString()} index={index}>
                                                {(provided) => (
                                                    <div

                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        {content?.type !== "Footer" ?
                                                            <FlowBuilderContentList>
                                                                <FlowBuilderContentAccordian style={{ padding: "0px 9px !important" }}>
                                                                    <AccordionSummary
                                                                        expandIcon={<GridExpandMoreIcon />}
                                                                        aria-controls="panel1-content"
                                                                        id="panel1-header"
                                                                    >
                                                                        <DragIndicatorIcon style={{
                                                                            cursor: "pointer", fontSize: "22px",
                                                                            marginRight: "4px"
                                                                        }} />
                                                                        {getContentTitle(content?.type)?.title}{content?.text === "" || content?.label === "" ? <WarningAmberIcon style={{
                                                                            transform: "",
                                                                            color: "red",
                                                                            marginLeft: "6px",
                                                                            fontSize: "20px"
                                                                        }} /> : null}
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <>
                                                                            <AccordionSelect>
                                                                                {content?.type === "TextInput" || content?.type === 'TextHeading' || content?.type === "TextSubheading" || content?.type === "TextBody" || content?.type === "TextCaption" ?
                                                                                    <Select
                                                                                        displayEmpty
                                                                                        value={getContentTitle(content?.type, content['input-type'])?.name}
                                                                                        onChange={(e: any) => content?.type === "TextInput" ? setShortAnswerType(e?.target?.value, index) : setHeadingTextType(e?.target?.value, index)}
                                                                                        input={<OutlinedInput />}
                                                                                        MenuProps={MenuProps}
                                                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                                                    >
                                                                                        {getDropdownOption(content?.type)?.map((name) => (
                                                                                            <MenuItem
                                                                                                key={name?.name}
                                                                                                value={name?.name}
                                                                                            >
                                                                                                {name?.label}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select> : null}
                                                                            </AccordionSelect>
                                                                            <AccordionInput style={{ textAlign: "left" }}>
                                                                                {content?.type === "TextInput" || content?.type === "TextArea" || content?.type === "DatePicker" || content?.type === "RadioButtonsGroup" || content?.type === "CheckboxGroup" || content?.type === "Dropdown" ?
                                                                                    <>
                                                                                        <div style={{ color: content?.label === "" ? "red" : "", }}>
                                                                                            <AppInput handleChange={(value: any) => setShortAnswerValue(value, index)} value={content?.label} placeholder="Enter your label name" />
                                                                                            {content?.label === "" ? <a>This field cannot be empty</a> : null}

                                                                                        </div>
                                                                                        {content?.type !== "RadioButtonsGroup" && content?.type !== "CheckboxGroup" && content?.type !== "Dropdown" ? <>
                                                                                            <div className="bodyTitle">Helper Text . <a> Optional </a></div>
                                                                                            <AppInput handleChange={(value: any) => setShortAnswerHelperText(value, index)} characterLimit={80} value={content?.['helper-text']} placeholder="Enter your helper text" />
                                                                                        </> :
                                                                                            <>
                                                                                                <div className="bodyTitle">Options</div>
                                                                                                {getDropDownValue(content)?.map((options: any, optIndex: any) => {
                                                                                                    return (
                                                                                                        <div className="bodyOptions">
                                                                                                            <div className="bodyOptionsInput">
                                                                                                                <AppInput handleChange={(value: any) => updateOptionTitle(index, options?.id, value)} characterLimit={30} value={options?.title} placeholder="Enter your option name" />
                                                                                                                {options?.title === "" ? <a style={{ color: "red" }}>This field cannot be empty</a> : null}

                                                                                                            </div>
                                                                                                            <IconButton
                                                                                                                onClick={() => removeOptions(index, options?.id)}
                                                                                                                className="bodyOptionsDelete"
                                                                                                                disabled={
                                                                                                                    (content['data-source']?.length < (content?.type === "CheckboxGroup" || content?.type === "Dropdown" ? 2 : 3))
                                                                                                                }
                                                                                                            >
                                                                                                                <DeleteOutlineIcon />
                                                                                                            </IconButton>

                                                                                                        </div>
                                                                                                    )
                                                                                                })}
                                                                                                {
                                                                                                    !contactMapping[content?.name] ||
                                                                                                        propertyList.some((item: any) =>
                                                                                                            item.name === contactMapping[content?.name] &&
                                                                                                            item.type !== "dropdown"
                                                                                                        ) ? (
                                                                                                        <Tooltip
                                                                                                            componentsProps={{
                                                                                                                tooltip: {
                                                                                                                    sx: {
                                                                                                                        bgcolor: 'white',
                                                                                                                        color: "common.black",
                                                                                                                        padding: "5px !important",
                                                                                                                        border: "1px solid lightgray",
                                                                                                                        fontSize: "12px",
                                                                                                                        '& .MuiTooltip-arrow': {
                                                                                                                            color: 'common.black',
                                                                                                                        },
                                                                                                                    },
                                                                                                                },
                                                                                                            }}
                                                                                                            title={
                                                                                                                getSectionCount(content?.type)?.size === content?.['data-source']?.length
                                                                                                                    ? "You can add a maximum of " + getSectionCount(content?.type)?.size
                                                                                                                    : ""
                                                                                                            }
                                                                                                        >
                                                                                                            <span>
                                                                                                                <AppButton
                                                                                                                    onClick={() => addOptions(index)}
                                                                                                                    disabled={getSectionCount(content?.type)?.size === content?.['data-source']?.length}
                                                                                                                    startIcon={<AddIcon />}
                                                                                                                    className="bodyOptionsButton"
                                                                                                                    width="20%"
                                                                                                                    fontColor="black"
                                                                                                                    backgroundColor={getSectionCount(content?.type)?.size === content['data-source']?.length
                                                                                                                        ? "rgba(229,232,240,1)"
                                                                                                                        : "#fff"}
                                                                                                                    text={"Add Options"}
                                                                                                                />
                                                                                                            </span>
                                                                                                        </Tooltip>
                                                                                                    ) : null
                                                                                                }

                                                                                            </>
                                                                                        }
                                                                                    </>
                                                                                    : content?.type === "Image" ? <>
                                                                                        <ImgaeUploadStyle>
                                                                                            {content?.src ? <div className="imageContainerPreview">
                                                                                                <div>
                                                                                                    <img style={{ width: "100px" }} src={"data:image/png;base64," + content?.src} />
                                                                                                </div>
                                                                                                <div>
                                                                                                    <AppButton onClick={() => resetImageSource(index)} className="bodyOptionsButton" width="20%" fontColor="black" backgroundColor={"rgba(229,232,240,1)"} text={"Remove"} />

                                                                                                </div>
                                                                                            </div> : null}
                                                                                            {content?.src === "" ? <> <div className="imageContainerTitle">
                                                                                                Choose JPG or PNG file
                                                                                            </div>
                                                                                                <Box className="file-upload__container">
                                                                                                    <div className="container">
                                                                                                        <input type="file" onChange={(event: any) => onImageChange(event.target.files[0], index)} accept="image/png, image/jpeg" className="chooser" />
                                                                                                        <a href="#">Choose file on your device</a>
                                                                                                    </div>

                                                                                                </Box>
                                                                                                <a style={{ color: "red" }}>An image must be selected</a> </>
                                                                                                : null
                                                                                            }
                                                                                            {content?.src ? <> <div className="imageContainerTitle">
                                                                                                Image height
                                                                                            </div>
                                                                                                <FormControl sx={{ m: 1, width: '25ch', marginLeft: "0px" }} variant="outlined">
                                                                                                    <OutlinedInput
                                                                                                        id="outlined-adornment-weight"
                                                                                                        type="number"
                                                                                                        value={content?.height}
                                                                                                        onChange={(e: any) => setImageHeight(index, e.target.value)}
                                                                                                        aria-describedby="outlined-weight-helper-text"
                                                                                                        inputProps={{
                                                                                                            'aria-label': 'weight',
                                                                                                        }}
                                                                                                    />
                                                                                                </FormControl> </> : null}
                                                                                            <div style={{ marginTop: "10px" }}>
                                                                                                Maximum file size: 300kb <br />
                                                                                                Acceptable file types : JPG, PNG
                                                                                            </div>
                                                                                        </ImgaeUploadStyle>
                                                                                    </>
                                                                                        : content?.type === "PhotoPicker" || content?.type === "DocumentPicker" ?
                                                                                            <>
                                                                                                <AppInput handleChange={(value: any) => setShortAnswerValue(value, index)} value={content?.label} placeholder="Enter your label name" />
                                                                                                <div className="bodyTitle">Description . <a> Optional </a></div>
                                                                                                <AppInput handleChange={(value: any) => setDecriptionText(value, index)} characterLimit={80} value={content?.['description']} placeholder="Enter your helper text" />
                                                                                            </>
                                                                                            : <> <AppTextArea style={{
                                                                                                width: "100%", marginTop: "20px",
                                                                                                borderRadius: "5px",
                                                                                                height: "120px",
                                                                                                padding: "12px 65px 11px 19px",
                                                                                                fontSize: "16px",
                                                                                                color: content?.text === "" ? "red" : "",
                                                                                                border: content?.text === "" ? "1px solid red" : "1px solid",
                                                                                            }} handleChange={(value: any) => setHeadingValue(value, index)} characterLimit={80} value={content?.text || content?.label} />
                                                                                                {content?.text === "" ? <a style={{ color: content?.text === "" ? "red" : "", }}>This field cannot be empty</a> : null}
                                                                                                {content?.type === "OptIn" ?
                                                                                                    <>
                                                                                                        <div className="bodyTitle">Read More Link</div>
                                                                                                        {content['on-click-action'] ? <div style={{ marginTop: "15px", marginLeft: "-22px" }} >
                                                                                                            <AppButton onClick={() => removeReadMoreScreen(index, content['on-click-action'])} startIcon={<DeleteIcon />} className="bodyOptionsButton" width="20%" fontColor="black" backgroundColor={"#fff"} text={"Delete Screen"} />


                                                                                                        </div> : <div style={{ marginTop: "15px", marginLeft: "-22px" }} >
                                                                                                            <AppButton onClick={() => addReadMoreScreen(index)} startIcon={<AddIcon />} className="bodyOptionsButton" width="30%" fontColor="black" backgroundColor={"#fff"} text={`Add "Read More" screen`} />

                                                                                                        </div>}

                                                                                                    </>
                                                                                                    : null}
                                                                                            </>
                                                                                }

                                                                            </AccordionInput>
                                                                            <AccordionDelete>

                                                                                <div>
                                                                                    {(content?.type === "TextInput" ||
                                                                                        content?.type === "TextArea" ||
                                                                                        content?.type === "DatePicker" ||
                                                                                        content?.type === "RadioButtonsGroup" ||
                                                                                        content?.type === "CheckboxGroup" ||
                                                                                        content?.type === "Dropdown" ||
                                                                                        content?.type === "OptIn" ||
                                                                                        content?.type === "PhotoPicker" ||
                                                                                        content?.type === "DocumentPicker") && (
                                                                                            <FormControlLabel
                                                                                                checked={content?.required}
                                                                                                control={<Switch color="primary" />}
                                                                                                label="Required"
                                                                                                labelPlacement="start"
                                                                                                onClick={(e: any) => setShortAnswerRequired(e?.target?.checked, index)}
                                                                                            />
                                                                                        )}
                                                                                    <DeleteOutlineIcon style={{cursor:"pointer",marginBottom: content?.type === "Image" || content?.type === "Document" ? "25px" : "0px"}} onClick={() => handleRemoveContent(index, content)} />
                                                                                </div>
                                                                            </AccordionDelete>
                                                                            <div style={{
                                                                                textAlign: "justify",
                                                                                marginTop: "-40px"
                                                                            }}>
                                                                                {content?.type !== "Image" &&
                                                                                    content?.type !== 'TextHeading' &&
                                                                                    content?.type !== "TextSubheading" &&
                                                                                    content?.type !== "TextBody" &&
                                                                                    content?.type !== "TextCaption" && (
                                                                                        <div >
                                                                                            <div style={{ marginRight: "auto" }}>
                                                                                                {propertyList.find((res: any) => res.name === content?.name)?.label ? `( ${propertyList.find((res: any) => res.name === content?.name)?.label} )` : "Mapping Contact Property"}
                                                                                            </div>
                                                                                            <div style={{ display: "flex", gap: "12px", cursor: "pointer", color: 'green' }}>
                                                                                                <a onClick={(e) => setSaveProperty(e, content, index)}>{propertyList.find((res: any) => res.name === content?.name)?.label ? "Change" : "Add"}</a>
                                                                                                <a onClick={(e) => removeSaveProperty(e, content, index)}>Remove</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                            </div>

                                                                        </>
                                                                    </AccordionDetails>
                                                                </FlowBuilderContentAccordian>
                                                            </FlowBuilderContentList> : null}
                                                    </div>
                                                )}
                                            </Draggable>

                                        )
                                    })
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <FlowBuilderContentList>
                    <FlowBuilderContentAccordian style={{ padding: "0px 9px !important" }}>
                        <AccordionSummary
                            expandIcon={<> <GridExpandMoreIcon /> </>}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            Button{contentData?.layout?.children[0]?.children[contentData?.layout?.children[0]?.children?.length - 1]?.label === "" ? <WarningAmberIcon style={{
                                transform: "",
                                color: "red",
                                marginLeft: "6px",
                                fontSize: "20px"
                            }} /> : null}
                        </AccordionSummary>
                        <AccordionDetails style={{ textAlign: "justify", color: contentData?.layout?.children[0]?.children[contentData?.layout?.children[0]?.children?.length - 1]?.label === "" ? "red" : "" }}>
                            <AppInput style={{ borderRadius: "6px" }} characterLimit={30} handleChange={changeScreenButtonName} value={contentData?.layout?.children[0]?.children[contentData?.layout?.children[0]?.children?.length - 1]?.label} placeholder="Enter your button name" />
                            {contentData?.layout?.children[0]?.children[contentData?.layout?.children[0]?.children?.length - 1]?.label === "" ? <a>This field cannot be empty</a> : null}
                        </AccordionDetails>
                    </FlowBuilderContentAccordian>
                </FlowBuilderContentList>
            </div>
            <AppModel width="546px" style={{ width: "100%" }} isShowing={isShowing} onClose={() => setIsShowing(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">Save Response</div>
                        <button className="closeBtn" onClick={() => setIsShowing(false)}>
                            &times;
                        </button>
                    </div>
                    <div>
                        <div className="bodyTitle">Property Name</div>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={filterArray()}
                            sx={{ width: 300 }}
                            value={""}
                            onChange={(event: any, newValue: any) => {
                                setPropertyName(newValue);
                            }}

                            style={{
                                margin: "12px 0px 11px",
                                width: "100%",
                                height: "36px"
                            }}
                            renderInput={(params: any) => <TextField {...params} label="" />}
                        />
                        {/* <Select

                            value={propertyName}
                            defaultValue=""
                            onChange={(res: any) => { setPropertyName(res?.target?.value) }}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            placeholder="Select Value"
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{
                                margin: "12px 0px 11px",
                                border: "1px solid",
                                width: "100%",
                                height: "36px"
                            }}
                        >

                            <MenuItem
                                value={""}
                                disabled
                            >
                                {"Select Value"}
                            </MenuItem>
                            {filterArray()?.map((name: any) => (
                                <MenuItem
                                    key={name?.label}
                                    value={name?.name}
                                >
                                    {name?.label}
                                </MenuItem>
                            ))}
                        </Select> */}
                        <a
                            href={"/settings/data-management/contact-properties/list"}
                            title="Create New"
                            style={{
                                fontSize: "12px",
                                color: "#7E8392",
                                textDecoration: "none",
                            }}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Create New
                        </a>

                    </div>
                    <div className='footer'>
                        <AppButton onClick={() => setIsShowing(false)} width="220%" fontColor="black" backgroundColor="#fff" text={"Cancel"} />
                        <AppButton onClick={() => setContactMappingFn()} width="220%" backgroundColor="#075E54" text={"Add"} />
                    </div>
                </AddScreenModel>
            </AppModel>
            <AppModel width="546px" style={{ width: "100%" }} isShowing={uploadError} onClose={() => setUploadError(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">{<WarningIcon style={{ color: "red" }} />}
                        </div>
                    </div>
                    <div>
                        <div className="leaveBodyTitle">{errorMessgae}                           </div>
                    </div>
                    <div className='footer'>
                        <AppButton onClick={() => setUploadError(false)} width="220%" fontColor="black" backgroundColor="#fff" text={"Close"} />
                    </div>
                </AddScreenModel>
            </AppModel>

        </FlowBuilderContentWapper>
    )
}
export default FlowBuilderContent