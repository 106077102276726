import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormColorPicker,
  AppFormField,
  AppFormImagePicker,
  AppFormRadioInput,
  AppFormSelectField,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotLookFeel } from "../../../../../Models/ChatbotTheme";
import { BotCustomizeSchema } from "../../../../../Models/ValidationSchema";
import {
  FontOptions,
  FontSizeOptions,
  notSupportedHexCodes,
  widgetPositionOptions,
} from "../../../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import {
  ChangeSelectedBot,
  useCurrentBot,
} from "../../../../../Store/Slices/DashboardSlices";
import { ColorUtils } from "../../../../../Utils/ColorUtils";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import { setBotConfig } from "../../../../../Store/Slices/botConfig/BotConfigSlice";
import { StyledLookFeelContainer } from "../../../utils/styles-bot-builder";
import WebBotStaticPreview from "../../StaticPreview/webBot/WebBotStaticPreview";

interface Props extends LoaderComponentProps {}

const WebBotLookFeel: React.FC<Props> = ({ setLoading }) => {
  const [localValues, setLocalValues] = useState<any>(null);

  const [lookFeelData, setLookFeelData] = useState<any>(null);
  const currentBot = useSelector(useCurrentBot);
  const dispatch = useDispatch();
  let { showAlert } = useNotification();
  const [userInput, setUserInput] = useState<any>({
    bot_name: "",
    primary: "#121ecf",
    font_size: "14",
    font_family: "Rubik",
    widget_position: "Left",
    widget_visibility: "0",
    company_logo: "",
    avatar: "",
    background_image: "",
    button_background: "#000000",
    hover_background: "#000000",
  });
  // const [tempValues, setTempValues] = useState<any>({
  //   avatar: null,
  //   company_logo: null,
  //   background_image: null,
  // });

  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  const getPreviousData = () => {
    ChatbotConsoleService.getChatbotLookFeel(currentBot.id as string)
      .then((res: AxiosResponse<ChatbotLookFeel>) => {
        if (res.data) {
          setLookFeelData(res.data);
          dispatch(setBotConfig(res?.data));
          const bot: any = res.data.chatbot;
          const ctheme: any = res.data.chatbot_theme;
          const cconfig: any = res.data.chatbot_configuration;

          // setTempValues({
          //   avatar: cconfig?.avatar?.id,
          //   company_logo: cconfig?.company_logo?.id,
          //   background_image: cconfig?.background_image?.id,
          // });
          const data = {
            bot_name: bot.name,
            primary: ctheme?.primary?.main ? ctheme?.primary?.main : "",
            button_background: ctheme?.button?.background
              ? ctheme?.button?.background
              : "",
            hover_background: ctheme?.button_hover?.background
              ? ctheme?.button_hover?.background
              : "",

            hover_text: ctheme?.button_hover?.text
              ? ctheme?.button_hover?.text
              : "",

            font_family: ctheme?.font?.primary?.family
              ? ctheme?.font?.primary?.family
              : "Rubik', sans-serif",
            font_size: ctheme?.font?.primary?.size
              ? ctheme?.font?.primary?.size
              : 14,

            widget_position: cconfig?.display_position
              ? cconfig?.display_position
              : "Left",
            company_logo: cconfig?.company_logo,
            avatar: cconfig?.avatar,
            background_image: cconfig?.background_image,
          };
          setUserInput(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentBot?.id && !lookFeelData) {
      getPreviousData();
    } else {
      setLoading(false);
    }
  }, [currentBot]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (!currentBot?.id) {
      SubmitProps.setSubmitting(false);
      return;
    }
    let textColor = ColorUtils.getContrastTextColorHex(
      values.button_background
    );
    let hoverTextColor = ColorUtils.getContrastTextColorHex(
      values.hover_background
    );
    const data = {
      chatbot: {
        name: values.bot_name,
      },
      chatbot_theme: {
        primary: {
          main: values.primary,
        },
        button: {
          background: values?.button_background,
          text: textColor,
        },
        button_hover: {
          background: values.hover_background,
          text: hoverTextColor,
        },
        font: {
          primary: {
            family: values.font_family,
            size: values.font_size,
          },
        },
      },
      chatbot_layout: {
        display_position: values.widget_position,
      },
      chatbot_configuration: {
        company_logo: values?.company_logo?.id || null,
        avatar: values?.avatar?.id || null,
        display_position: values.widget_position,
        background_image: values?.background_image?.id || null,
      },
    };
    ChatbotConsoleService.changeLookAndFeel(data, [currentBot?.id as string])
      .then((res: any) => {
        SubmitProps.setSubmitting(false);
        setLookFeelData(res.data);
        dispatch(setBotConfig(res?.data));
        dispatch(ChangeSelectedBot(res?.data?.chatbot));
        showAlert("Configuration Saved!", "success");
      })
      .catch((error: any) => {
        SubmitProps.setSubmitting(false);
        showAlert("something went wrong", "error");
      });
  };
  return (
    <StyledLookFeelContainer>
      <AppSmallWindowPane
        style={{
          minWidth: "55%",
          borderRadius: "8px",
          padding: "1.1rem 1.5rem 0.5rem 1.5rem",
          position: "relative",
        }}
      >
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
          }}
          validationSchema={BotCustomizeSchema}
        >
          <AppFormImagePicker
            label="Upload a Company Logo"
            name="company_logo"
            tooltip={
              <ul>
                <li>Recommended size 64*64 px</li>
                <li>Your logo must be smaller than 1 MB</li>
                <li>A PNG, JPEG or JPG works best</li>
              </ul>
            }
            sizeLimit={1000000}
            sizeErrorMsg={"Image size must be smaller than 1MB."}
            formatErrorMsg={"image should be PNG, JPEG or JPG."}
          />
          <AppFormImagePicker
            label="Upload a ChatBot Avatar"
            name="avatar"
            tooltip={
              <ul>
                <li>Recommended size 64*64 px</li>
                <li>Your avatar must be smaller than 1 MB</li>
                <li>A PNG, JPEG or JPG works best</li>
              </ul>
            }
            sizeLimit={1000000}
          />
          <AppFormImagePicker
            label="Chat Background (optional)"
            name="background_image"
            tooltip={
              <ul>
                <li>Recommended size 64*64 px</li>
                <li>Your image must be smaller than 1 MB</li>
                <li>A PNG, JPEG or JPG works best</li>
              </ul>
            }
            sizeLimit={1000000}
          />
          <AppFormField
            label="Bot Name"
            name="bot_name"
            placeholder="Travel Booking Bot"
            sendValuesToParent={(values: any) => sendValuesToParent(values)}
            maxLength={24}
          />

          <AppFormColorPicker
            label="Primary Color"
            name="primary"
            onColorChange={(color) => {}}
            notSupportedHexCodes={notSupportedHexCodes}
            tooltip="Used as a background color to highlight areas and buttons"
            // customOnBlur={changeTheme}
          ></AppFormColorPicker>

          <AppFormSelectField
            label="Font Family"
            Options={FontOptions}
            name="font_family"
            tooltip="Used as the chatbots font option for chats."
            enableSearch
          />
          <AppFormColorPicker
            label="Button Background"
            name="button_background"
            onColorChange={(color) => {}}
            tooltip="Used as a background color to highlight areas and buttons"
            // customOnBlur={changeTheme}
          />
          <AppFormColorPicker
            label="hover Background"
            name="hover_background"
            onColorChange={(color) => {}}
            tooltip="Used as a background color to highlight areas and buttons"
            // customOnBlur={changeTheme}
          />
          <AppFormSelectField
            label="Font Size"
            Options={FontSizeOptions}
            name="font_size"
            tooltip="Font Options"
          />

          <AppFormRadioInput
            label="Chat Widget Position"
            name="widget_position"
            Options={widgetPositionOptions}
            tooltip="Select Left or Right position for widget"
          />

          <AppSubmitButton
            variant="cta"
            style={{ width: "100%", margin: "0.5rem 0" }}
            title={"Save"}
          />
        </AppForm>
      </AppSmallWindowPane>
      <WebBotStaticPreview localConfig={localValues} />
    </StyledLookFeelContainer>
  );
};

export default withLoader(WebBotLookFeel);
