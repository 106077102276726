import uniqid from "uniqid";
import * as Yup from "yup";
export const DataContainsRadioOptions: any[] = [
    {
        name: "Display a thank you message",
        value: "thank_you_message",
        id: uniqid(),
    },
    {
        name: "Redirect to another page",
        value: "redirect_to_another_page",
        id: uniqid(),
    },
];

export const dublicateRecordsContainsRadioOptions: any[] = [
    {
        name: "Always create a new record",
        value: "always_create_a_new_record",
        id: uniqid(),
    },
    {
        name: "Update existing record",
        value: "update_existing_record",
        id: uniqid(),
    },
];

export const buttonPositionOptions = [{
    label: "Start",
    value: "start"
},
{
    label: "End",
    value: "end"
},
{
    label: "Center",
    value: "center"
}]

export const coverWholeWidthOptions = [{
    label: "Yes",
    value: "yes"
},
{
    label: "No",
    value: "no"
}]

export const showLableOptions = [{
    label: "Yes",
    value: "yes"
},
{
    label: "No",
    value: "no"
}]


export const applicationWidgetFormOptions = [{
    label: "Yes",
    value: "yes"
},
{
    label: "No",
    value: "no"
}]

export const optVarificationOptions = [{
    label: "Yes",
    value: "yes"
},
{
    label: "No",
    value: "no"
}]

export const formatOptions: any[] = [
    {
        name: "%",
        value: "percentage",
        id: uniqid(),
    },
    {
        name: "px",
        value: "px",
        id: uniqid(),
    },
]

export const labelUpperCase: any[] = [
    {
        name: "Yes",
        value: "yes",
        id: uniqid(),
    },
    {
        name: "No",
        value: "no",
        id: uniqid(),
    },
]


export const customPropertiesArray: any = [
    {
        label: "Divider",
        type: "divider",
        value: "divider",
        custom: true,
        name: "divider",
        visible: true,
    },
    {
        label: "Image",
        type: "image",
        value: "image",
        custom: true,
        name: "image",
        visible: true,
    },
    {
        label: "Link",
        type: "link",
        value: "link",
        custom: true,
        name: "link",
        visible: true,
    },
    {
        label: "Custom Paragraph",
        type: "custom_paragraph",
        value: "custom_paragraph",
        custom: true,
        name: "custom_paragraph",
        visible: true,
    }
];

export const defaultOptions: any = {
    id: 1,
    name: "columns",
    type: "text",
    label: "Columns",
};

export const gridPreview = [
    {
        id: 1,
        number_of_fields: 1,
        widthsArray: [{ width: 99 }],
        icon: <svg height="36" version="1.1" width="85" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 1224 512"><path d="M1224 482.52a29.54 29.54 0 01-29.6 29.48H29.6A29.54 29.54 0 010 482.52V29.48A29.54 29.54 0 0129.6 0h1164.8c16.35 0 29.6 13.2 29.6 29.48v453.05z" fill="currentColor"></path></svg>
    },
    {
        id: 2,
        number_of_fields: 2,
        widthsArray: [{ width: 49.50 }, { width: 49.50 }],
        icon: <svg height="36" version="1.1" width="85" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 1224 512"><path d="M1194.4 512c16.35 0 29.6-13.2 29.6-29.48V29.48A29.54 29.54 0 001194.4 0H627v512h567.4zM597 512V0H29.6A29.54 29.54 0 000 29.48v453.04A29.54 29.54 0 0029.6 512H597z" fill="currentColor"></path></svg>
    },
    {
        id: 3,
        number_of_fields: 3,
        widthsArray: [{ width: 33 }, { width: 33 }, { width: 33 }],
        icon: <svg height="36" version="1.1" width="85" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" viewBox="0 0 1224 512"><path d="M1224 482.52a29.54 29.54 0 01-29.6 29.48H831V0h363.4c16.35 0 29.6 13.2 29.6 29.48v453.05zM801 0H423v512h378V0zM393 0v512H29.6A29.54 29.54 0 010 482.52V29.48A29.54 29.54 0 0129.6 0H393z" fill="currentColor"></path></svg>
    },
];

export const countryCodeMobileOptions = [
    { "label": "Afghanistan", "value": "af" },
    { "label": "Albania", "value": "al" },
    { "label": "Algeria", "value": "dz" },
    { "label": "Andorra", "value": "ad" },
    { "label": "Angola", "value": "ao" },
    { "label": "Antigua and Barbuda", "value": "ag" },
    { "label": "Argentina", "value": "ar" },
    { "label": "Armenia", "value": "am" },
    { "label": "Australia", "value": "au" },
    { "label": "Austria", "value": "at" },
    { "label": "Azerbaijan", "value": "az" },
    { "label": "Bahamas", "value": "bs" },
    { "label": "Bahrain", "value": "bh" },
    { "label": "Bangladesh", "value": "bd" },
    { "label": "Barbados", "value": "bb" },
    { "label": "Belarus", "value": "by" },
    { "label": "Belgium", "value": "be" },
    { "label": "Belize", "value": "bz" },
    { "label": "Benin", "value": "bj" },
    { "label": "Bhutan", "value": "bt" },
    { "label": "Bolivia", "value": "bo" },
    { "label": "Bosnia and Herzegovina", "value": "ba" },
    { "label": "Botswana", "value": "bw" },
    { "label": "Brazil", "value": "br" },
    { "label": "Brunei", "value": "bn" },
    { "label": "Bulgaria", "value": "bg" },
    { "label": "Burkina Faso", "value": "bf" },
    { "label": "Burundi", "value": "bi" },
    { "label": "Cabo Verde", "value": "cv" },
    { "label": "Cambodia", "value": "kh" },
    { "label": "Cameroon", "value": "cm" },
    { "label": "Canada", "value": "ca" },
    { "label": "Central african republic", "value": "cf" },
    { "label": "Chad", "value": "td" },
    { "label": "Chile", "value": "cl" },
    { "label": "China", "value": "cn" },
    { "label": "Colombia", "value": "co" },
    { "label": "Comoros", "value": "km" },
    { "label": "Congo", "value": "cg" },
    { "label": "Costa Rica", "value": "cr" },
    { "label": "Croatia", "value": "hr" },
    { "label": "Cuba", "value": "cu" },
    { "label": "Cyprus", "value": "cy" },
    { "label": "Czech Republic", "value": "cz" },
    { "label": "Democratic Republic of The Congo", "value": "cd" },
    { "label": "Denmark", "value": "dk" },
    { "label": "Djibouti", "value": "dj" },
    { "label": "Dominica", "value": "dm" },
    { "label": "Dominican Republic", "value": "do" },
    { "label": "East Timor", "value": "tl" },
    { "label": "Ecuador", "value": "ec" },
    { "label": "Egypt", "value": "eg" },
    { "label": "El Salvador", "value": "sv" },
    { "label": "Equatorial Guinea", "value": "gq" },
    { "label": "Eritrea", "value": "er" },
    { "label": "Estonia", "value": "ee" },
    { "label": "Rswatini", "value": "sz" },
    { "label": "Rthiopia", "value": "et" },
    { "label": "Fiji", "value": "fj" },
    { "label": "Finland", "value": "fi" },
    { "label": "France", "value": "fr" },
    { "label": "Gabon", "value": "ga" },
    { "label": "Gambia", "value": "gm" },
    { "label": "Georgia", "value": "ge" },
    { "label": "Germany", "value": "de" },
    { "label": "Ghana", "value": "gh" },
    { "label": "Greece", "value": "gr" },
    { "label": "Grenada", "value": "gd" },
    { "label": "Guatemala", "value": "gt" },
    { "label": "Guinea", "value": "gn" },
    { "label": "Guinea-bissau", "value": "gw" },
    { "label": "Guyana", "value": "gy" },
    { "label": "Haiti", "value": "ht" },
    { "label": "Honduras", "value": "hn" },
    { "label": "Hungary", "value": "hu" },
    { "label": "Iceland", "value": "is" },
    { "label": "India", "value": "in" },
    { "label": "Indonesia", "value": "id" },
    { "label": "Iran", "value": "ir" },
    { "label": "Iraq", "value": "iq" },
    { "label": "Ireland", "value": "ie" },
    { "label": "Israel", "value": "il" },
    { "label": "Italy", "value": "it" },
    { "label": "Ivory Coast", "value": "ci" },
    { "label": "Iamaica", "value": "jm" },
    { "label": "Japan", "value": "jp" },
    { "label": "Jordan", "value": "jo" },
    { "label": "Kazakhstan", "value": "kz" },
    { "label": "Kenya", "value": "ke" },
    { "label": "Kiribati", "value": "ki" },
    { "label": "Kosovo", "value": "xk" },
    { "label": "Kuwait", "value": "kw" },
    { "label": "Kyrgyzstan", "value": "kg" },
    { "label": "Laos", "value": "la" },
    { "label": "Latvia", "value": "lv" },
    { "label": "Lebanon", "value": "lb" },
    { "label": "Lesotho", "value": "ls" },
    { "label": "Liberia", "value": "lr" },
    { "label": "ibya", "value": "ly" },
    { "label": "Liechtenstein", "value": "li" },
    { "label": "Lithuania", "value": "lt" },
    { "label": "Luxembourg", "value": "lu" },
    { "label": "Madagascar", "value": "mg" },
    { "label": "Malawi", "value": "mw" },
    { "label": "Malaysia", "value": "my" },
    { "label": "Maldives", "value": "mv" },
    { "label": "Mali", "value": "ml" },
    { "label": "Malta", "value": "mt" },
    { "label": "Marshall islands", "value": "mh" },
    { "label": "Mauritania", "value": "mr" },
    { "label": "Mauritius", "value": "mu" },
    { "label": "Mexico", "value": "mx" },
    { "label": "Micronesia", "value": "fm" },
    { "label": "Moldova", "value": "md" },
    { "label": "Monaco", "value": "mc" },
    { "label": "Mongolia", "value": "mn" },
    { "label": "Montenegro", "value": "me" },
    { "label": "Morocco", "value": "ma" },
    { "label": "Mozambique", "value": "mz" },
    { "label": "Myanmar", "value": "mm" },
    { "label": "Namibia", "value": "na" },
    { "label": "Nauru", "value": "nr" },
    { "label": "Nepal", "value": "np" },
    { "label": "Netherlands", "value": "nl" },
    { "label": "New Zealand", "value": "nz" },
    { "label": "Nicaragua", "value": "ni" },
    { "label": "Niger", "value": "ne" },
    { "label": "Nigeria", "value": "ng" },
    { "label": "North Korea", "value": "kp" },
    { "label": "North Macedonia", "value": "mk" },
    { "label": "Morway", "value": "no" },
    { "label": "Oman", "value": "om" },
    { "label": "Pakistan", "value": "pk" },
    { "label": "Palau", "value": "pw" },
    { "label": "Palestine", "value": "ps" },
    { "label": "Panama", "value": "pa" },
    { "label": "Papua New Guinea", "value": "pg" },
    { "label": "Paraguay", "value": "py" },
    { "label": "Peru", "value": "pe" },
    { "label": "Philippines", "value": "ph" },
    { "label": "Poland", "value": "pl" },
    { "label": "Portugal", "value": "pt" },
    { "label": "Qatar", "value": "qa" },
    { "label": "Romania", "value": "ro" },
    { "label": "Russia", "value": "ru" },
    { "label": "Rwanda", "value": "rw" },
    { "label": "Saint Kitts and Nevis", "value": "kn" },
    { "label": "Saint Lucia", "value": "lc" },
    { "label": "Saint Vincent and The Grenadines", "value": "vc" },
    { "label": "Samoa", "value": "ws" },
    { "label": "San Marino", "value": "sm" },
    { "label": "Sao Tome and Principe", "value": "st" },
    { "label": "Saudi Arabia", "value": "sa" },
    { "label": "Senegal", "value": "sn" },
    { "label": "Serbia", "value": "rs" },
    { "label": "Seychelles", "value": "sc" },
    { "label": "Sierra Leone", "value": "sl" },
    { "label": "Singapore", "value": "sg" },
    { "label": "Slovakia", "value": "sk" },
    { "label": "Slovenia", "value": "si" },
    { "label": "Solomon Islands", "value": "sb" },
    { "label": "Somalia", "value": "so" },
    { "label": "South Africa", "value": "za" },
    { "label": "South Korea", "value": "kr" },
    { "label": "South Sudan", "value": "ss" },
    { "label": "Spain", "value": "es" },
    { "label": "Sri Lanka", "value": "lk" },
    { "label": "Sudan", "value": "sd" },
    { "label": "Suriname", "value": "sr" },
    { "label": "Sweden", "value": "se" },
    { "label": "Switzerland", "value": "ch" },
    { "label": "Syria", "value": "sy" },
    { "label": "Taiwan", "value": "tw" },
    { "label": "Tajikistan", "value": "tj" },
    { "label": "Tanzania", "value": "tz" },
    { "label": "Thailand", "value": "th" },
    { "label": "Togo", "value": "tg" },
    { "label": "Tonga", "value": "to" },
    { "label": "Trinidad and Tobago", "value": "tt" },
    { "label": "Tunisia", "value": "tn" },
    { "label": "Turkey", "value": "tr" },
    { "label": "Turkmenistan", "value": "tm" },
    { "label": "Tuvalu", "value": "tv" },
    { "label": "Uganda", "value": "ug" },
    { "label": "Ukraine", "value": "ua" },
    { "label": "United Arab Emirates", "value": "ae" },
    { "label": "United Kingdom", "value": "gb" },
    { "label": "United States", "value": "us" },
    { "label": "Uruguay", "value": "uy" },
    { "label": "Uzbekistan", "value": "uz" },
    { "label": "Vanuatu", "value": "vu" },
    { "label": "Vatican city", "value": "va" },
    { "label": "Venezuela", "value": "ve" },
    { "label": "Vietnam", "value": "vn" },
    { "label": "Yemen", "value": "ye" },
    { "label": "Zambia", "value": "zm" },
    { "label": "Zimbabwe", "value": "zw" }
]

export const formBorderOptions = [
    {
        value: "none",
        label: "None"
    }
    ,
    {
        value: "solid",
        label: "Solid"
    },
    {
        value: "dashed",
        label: "Dashed"
    },
    {
        value: "dotted",
        label: "Dotted"
    },
    {
        value: "double",
        label: "Double"
    }
];

export const formBordeWidthOptions = [
    {
        value: "0px",
        label: "0px"
    },
    {
        value: "1px",
        label: "1px"
    },
    {
        value: "2px",
        label: "2px"
    },
    {
        value: "3px",
        label: "3px"
    },
    {
        value: "4px",
        label: "4px"
    },
    {
        value: "5px",
        label: "5px"
    },
    {
        value: "6px",
        label: "6px"
    },
    {
        value: "7px",
        label: "7px"
    },
    {
        value: "8px",
        label: "8px"
    },
    {
        value: "9px",
        label: "9px"
    },
    {
        value: "10px",
        label: "10px"
    }
];

export const formBackgroundImageFittingOptions = [
    {
        value: "cover",
        label: "Cover"
    },
    {
        value: "contain",
        label: "Contain"
    },
    {
        value: "fill",
        label: "Fill"
    },
    {
        value: "none",
        label: "None"
    }
];

export const formButtonFontSizeOptions = [
    {
        value: "10px",
        label: "10px"
    },
    {
        value: "12px",
        label: "12px"
    },
    {
        value: "14px",
        label: "14px"
    },
    {
        value: "16px",
        label: "16px"
    },
    {
        value: "18px",
        label: "18px"
    },
    {
        value: "20px",
        label: "20px"
    },
    {
        value: "22px",
        label: "22px"
    },
    {
        value: "24px",
        label: "24px"
    },
    {
        value: "26px",
        label: "26px"
    },
    {
        value: "28px",
        label: "28px"
    },
    {
        value: "30px",
        label: "30px"
    },
    {
        value: "32px",
        label: "32px"
    },
    {
        value: "34px",
        label: "34px"
    },
    {
        value: "36px",
        label: "36px"
    },
    {
        value: "38px",
        label: "38px"
    },
    {
        value: "40px",
        label: "40px"
    }
]

export const formFieldBorderRadiusOptions = [
    {
        value: "2px",
        label: "2px"
    },
    {
        value: "4px",
        label: "4px"
    },
    {
        value: "6px",
        label: "6px"
    },
    {
        value: "8px",
        label: "8px"
    },
    {
        value: "10px",
        label: "10px"
    },
    {
        value: "12px",
        label: "12px"
    },
    {
        value: "14px",
        label: "14px"
    },
    {
        value: "16px",
        label: "16px"
    },
    {
        value: "18px",
        label: "18px"
    },
    {
        value: "20px",
        label: "20px"
    },
    {
        value: "22px",
        label: "22px"
    },
    {
        value: "24px",
        label: "24px"
    },
    {
        value: "26px",
        label: "26px"
    },
    {
        value: "28px",
        label: "28px"
    },
    {
        value: "30px",
        label: "30px"
    }
];

export const buttonBorderWidthOptions = [
    {

        value: "0px",
        label: "0px"
    },
    {
        value: "1px",
        label: "1px"
    },
    {
        value: "2px",
        label: "2px"
    },
    {
        value: "3px",
        label: "3px"
    },
    {
        value: "4px",
        label: "4px"
    },
    {
        value: "5px",
        label: "5px"
    },
    {
        value: "6px",
        label: "6px"
    },
    {
        value: "7px",
        label: "7px"
    },
    {
        value: "8px",
        label: "8px"
    },
    {
        value: "9px",
        label: "9px"
    },
    {
        value: "10px",
        label: "10px"
    }
];

export const buttonBoldOptions = [
    {
        value: "100",
        label: "Thin"
    },
    {
        value: "200",
        label: "Extra Light"
    },
    {
        value: "300",
        label: "Light"
    },
    {
        value: "400",
        label: "Normal"
    },
    {
        value: "500",
        label: "Medium"
    },
    {
        value: "600",
        label: "Semi Bold"
    },
    {
        value: "700",
        label: "Bold"
    },
    {
        value: "800",
        label: "Extra Bold"
    },
    {
        value: "900",
        label: "Black (Heavy)"
    }
];

export const labelMarginBottomOptions = [
    {
        value: "0px",
        label: "0px"
    },
    {
        value: "1px",
        label: "1px"
    },
    {
        value: "2px",
        label: "2px"
    },
    {
        value: "3px",
        label: "3px"
    },
    {
        value: "4px",
        label: "4px"
    },
    {
        value: "5px",
        label: "5px"
    },
    {
        value: "6px",
        label: "6px"
    },
    {
        value: "7px",
        label: "7px"
    },
    {
        value: "8px",
        label: "8px"
    },
    {
        value: "9px",
        label: "9px"
    },
    {
        value: "10px",
        label: "10px"
    },
    {
        value: "11px",
        label: "11px"
    },
    {
        value: "12px",
        label: "12px"
    },
    {
        value: "13px",
        label: "13px"
    },
    {
        value: "14px",
        label: "14px"
    },
    {
        value: "15px",
        label: "15px"
    },
    {
        value: "16px",
        label: "16px"
    },
    {
        value: "17px",
        label: "17px"
    },
    {
        value: "18px",
        label: "18px"
    },
    {
        value: "19px",
        label: "19px"
    },
    {
        value: "20px",
        label: "20px"
    }
];
export const backgroundImagePositions = [
    {
        value: "center",
        label: "Center"
    },
    {
        value: "top",
        label: "Top"
    },
    {
        value: "bottom",
        label: "Bottom"
    },
    {
        value: "left",
        label: "Left"
    },
    {
        value: "right",
        label: "Right"
    },
    {
        value: "top-left",
        label: "Top Left"
    },
    {
        value: "top-right",
        label: "Top Right"
    },
    {
        value: "bottom-left",
        label: "Bottom Left"
    },
    {
        value: "bottom-right",
        label: "Bottom Right"
    }
];

export const backgroundRepeatOptions= [
    {
        value: "no-repeat",
        label: "No Repeat"
    },
    {
        value: "repeat",
        label: "Repeat"
    },
    {
        value: "repeat-x",
        label: "Repeat X"
    },
    {
        value: "repeat-y",
        label: "Repeat Y"
    }
];

export const FormStylesSchema = Yup.object().shape({
    formWidth: Yup.number()
        .min("1", "Width must be greater than 0")
        .typeError("Width must be a number Only").required("Enter Width").label("Width"),
    button_name: Yup.string().max(30, "Button Text should not be more than 30 characters"),
});
