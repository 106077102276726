const verticalOptions = [
  "UNDEFINED",
  "AUTO",
  "BEAUTY",
  "APPAREL",
  "EDU",
  "ENTERTAIN",
  "EVENT_PLAN",
  "FINANCE",
  "GROCERY",
  "GOVT",
  "HOTEL",
  "HEALTH",
  "NONPROFIT",
  "PROF_SERVICES",
  "RETAIL",
  "TRAVEL",
  "RESTAURANT",
  "NOT_A_BIZ",
  "OTHER",
];

export const whatsappBusinessVerticalOptions = verticalOptions.map((value) => ({
  label: value
    .split("_")
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" "),
  value,
}));

export const whatsappBusinessTypeOptions = [
  {
    label: "Limited liability company",
    value: "Limited liability company",
  },
  {
    label: "Sole proprietorship",
    value: "Sole proprietorship",
  },
  {
    label: "Partership",
    value: "Partership",
  },
  {
    label: "Public Company",
    value: "Public Company",
  },
  {
    label: "Private Company",
    value: "Private Company",
  },
  {
    label: "Other",
    value: "Other",
  },
];


export function convertStringToArray(commaSeparatedString:string):string[] {
  return commaSeparatedString?.split(",")?.map(item => item?.trim());
}

export function convertStringArrayToString(arr:string[]):string {
  return arr?.join(", ");
}