// vendors
import React, { useEffect, useRef } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface UIDialogProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  invoiceLink: any
  //   children: React.ReactNode;
}

const PayNowIfram = (props: UIDialogProps) => {
  const { children, className } = props;
  return (
    <>
    <Dialog
      className={className}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        sx: {
          background: "rgba(16, 16, 16, 0.08)",
          backdropFilter: "blur(3px)",
        },
      }}
      
      {...props}
    >
          <div className="warningMessage"> Your payment is in progress please do not close until payment done</div>

      <DialogTitle className="walletHeader" sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Invoice
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => { props.onClose() }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <iframe
        title="HTML Preview"
        src={props?.invoiceLink}
        className='iframe'
        style={{ width: "42rem", height: "80vh", border: "1px solid #ccc", padding: "5px", margin: "0 auto", display: "block" }}
      />
    </Dialog>
    </>
  );
};

PayNowIfram.Header = DialogTitle;
PayNowIfram.Body = DialogContent;
PayNowIfram.Footer = DialogActions;

export default PayNowIfram;
