import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Stack,
  Typography,
  alpha,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormImagePicker,
  AppFormMobileField,
  AppFormRadioField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { device } from "../../../Customization/BreakPoints";
import { DecsText, Label } from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { OnboardingNetworkService } from "../../../Store/Slices/Onboarding/OnboardingNetworkService";
import { useOnboardingStore } from "../../../Store/Slices/Onboarding/onboarding.selectors";
import { setWhatsappOnboardingConfig } from "../../../Store/Slices/Onboarding/onboarding.slice";
import {
  convertStringArrayToString,
  convertStringToArray,
  whatsappBusinessTypeOptions,
  whatsappBusinessVerticalOptions,
} from "./constants";
import { getReactSelectCurrentValue } from "../../../Utils";
import { whatsappMangeProfileSchema } from "../../../Models/ValidationSchema";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../HOC/HocBackdropLoader";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../AppComponents/Loader";
import { useNotification } from "../../../Hooks/useNotification/useNotification";

// const TEMP_CONFIG = {
//   display_name: "Geta App",
//   quality_rating: "UNKNOWN",
//   phoneID: "335033909698882",
//   display_number: "+917778873882",
//   display_name_status: "AVAILABLE_WITHOUT_REVIEW",
//   auth_key: "0dAEqfSOcVsUvE9li4BEO72Yg0t87Osx",
//   waba_id: "371433726046073",
//   whatsapp_no: "+917778873882",
//   status: "CONNECTED",
//   limit: "TIER_250",
//   "entity_name": "Lucky Shrub",
//   "entity_type": "Limited liability company",
//   "is_registered": true,
//   "grievance_officer_details": {
//       "name": "Chandravati P.",
//       "email": "chandravati@luckyshrub.com",
//       "landline_number": "+913857614343",
//       "mobile_number": "+913854559033"
//   },
//   "customer_care_details": {
//       "email": "support@luckyshrub.com",
//       "landline_number": "+913857614343",
//       "mobile_number": "+913854559033"
//   },
//   "messaging_product": "whatsapp",

//   // "messaging_product": "whatsapp",
//   "address": "business-address",
//   "description": "business-description",
//   "vertical": "AUTO",
//   "about": "profile-about-text",
//   "email": "business-email",
//   "websites": [
//       "https://website-1",
//       "https://website-2"
//   ],
//   "profile_picture_url": "<PROFILE_PICTURE_URL>",
//   customer_email:"abc@a.com",
//   customer_mobile:"+919876543210",
//   grievance_officer_email:"abc@a.com",
//   grievance_officer_mobile:"+919876543210",
//   grievance_officer_name:"Jhon Doe",
// };

const BUSINESS_REGISTERED_OPTIONS = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];

interface Props extends LoaderComponentProps {}

const WhatsappManageProfile = ({ setLoading }: Props) => {
  const dispatch = useAppDispatch();
  const { data: config } = useAppSelector<any>(useOnboardingStore);
  const [userInput, setUserInput] = useState<any>({
    vertical: whatsappBusinessVerticalOptions?.[0],
  });
  const [BusinessType,setBusinessType] = useState<any>({})
  const { showAlert } = useNotification();


  const getConfigData = () => {
    OnboardingNetworkService.getOnboardingConfig({})
      .then((res: any) => {

        // dispatch(setWhatsappOnboardingConfig(res?.data));
        dispatch(setWhatsappOnboardingConfig(res?.data));

        const data = res?.data;
        setUserInput({
          ...userInput,
          // Business Logo Section
          display_picture: data?.display_picture,

          // Description Section
          description: data?.description,
          vertical: getReactSelectCurrentValue(
            whatsappBusinessVerticalOptions,
            data?.vertical,
            "value"
          ),

          // Contact Information Section
          address: data?.address,
          email: data?.email,
          websites: convertStringArrayToString(data?.websites),

          // Compliance Info Section
          entity_name : data?.entity_name,
          entity_type: getReactSelectCurrentValue(
            whatsappBusinessTypeOptions,
            data?.entity_type,
            "value"
          ) ?? whatsappBusinessTypeOptions[5],
          is_registered : data?.business_verified
             ? BUSINESS_REGISTERED_OPTIONS?.[0]
             : BUSINESS_REGISTERED_OPTIONS?.[1], 
          customer_email : data?.customer_email,
          customer_mobile : data?.customer_mobile || "+91",
          grievance_officer_mobile : data?.grievance_officer_mobile || "+91",
          grievance_officer_email : data?.grievance_officer_email,
          grievance_officer_name : data?.grievance_officer_name
        });
        setLoading(false);
      })
      .catch((err: any) => {
      });
  };
  
  useEffect(() => {
    getConfigData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  //form
  const formikRef = useRef<any>(null);
  function compareAndExtractDifferences(obj1 :any, obj2 :any) {
    let differences :any = {};

    // Loop through obj1 keys to compare with obj2
    for (let key in obj1) {
        if (obj1.hasOwnProperty(key) && obj1[key] !== null) {
          if(key === "vertical"){
            if(obj1[key]?.value !== obj2[key]){
              differences[key] = obj2[key]
            }
          }
          if(key === "display_picture"){
            if(obj2[key]?.id !== undefined){
              differences[key] = obj2[key]
            }
          }
          if(key === "websites"){
            if(obj1[key].toString() !== obj2[key][0].toString()){
              differences[key] = obj2[key]
            }
          }
          if(key === "entity_type"){            
            if(obj1[key]?.value.toString() !== obj2[key]){
              differences[key] = obj2[key]
            }
          }
          if(key === "is_registered"){
            let value = obj1[key]?.value === "no" ? false : true
            if(value !== obj2[key]){
              differences[key] = value
            }
          }
          if(obj1[key].toString() && key !== "websites" && key !== "display_picture" && key !== "entity_type" && key !== "is_registered" && key !== "vertical"){
            if(obj1[key] !== obj2[key]){
              differences[key] = obj2[key]
            }
          }
        }else{
          if (obj2[key] !== null && !(typeof obj2[key] === 'object' && !Array.isArray(obj2[key]) && Object.keys(obj2[key]).length !== 0)) {
            differences[key] = obj2[key];
          }
          
        }
    }

    return differences;
}
  const handleSubmitBtn = (values: any, submitProps: any) => {
    const payload = {
      ...values,
      vertical: values?.vertical?.value,
      entity_type: values?.entity_type?.value,
      display_picture: {
        id : values?.display_picture?.id,
        url : values?.display_picture?.source
      },
      is_registered: values?.entity_type?.value === "Partership" || values?.entity_type?.value === "Other" ? null : values?.is_registered?.value === "no" ? false : true ?? false,
      websites:convertStringToArray(values?.websites)
    };        
    let updatedPayload = compareAndExtractDifferences(userInput,payload)
    setLoading(true);
    OnboardingNetworkService.updateWhatsappProfile(updatedPayload)
      .then((res) => {
        getConfigData();
        showAlert("Profile updated", "success");
      })
      .catch((err) => {
        showAlert("Error while updating profile", "error");

      })
      .finally(() => {
        submitProps.setSubmitting(false);
        setLoading(false);
      });
    submitProps.setSubmitting(false)
  };

  //Accordion
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const businessTypeFn = (type:any) => {
    setBusinessType(type)
  }
  return (
    <Container>
      <div className="topmenu">
        <div className="left-part">
          <Breadcrumbs
            aria-label="breadcrumb "
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link to="/settings">Settings</Link>
            <Link to="/settings/manage-profile/whatsapp">Manage Profile</Link>
            <Link to="/settings/manage-profile/whatsapp" color="inherit">
              Whatsapp
            </Link>
          </Breadcrumbs>
        </div>
      </div>

      <div className="contentBox">
        <div className="formBox">
          {config?.whatsapp_no ? (
            <AppForm
              innerRef={formikRef}
              initialValues={userInput}
              onSubmit={(values: any, submitProps: any, event: any) => {
                handleSubmitBtn(values, submitProps);
              }}
              validationSchema={whatsappMangeProfileSchema}
              validateOnMount={true}
            >
              <div className="inner-wrapper">
                <StyledAccordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="section"
                    id="section"
                  >
                    <Typography className="title">Business Logo</Typography>
                    <Typography className="caption">
                      Logo will appear as the profile picture for your business
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AppFormImagePicker
                      label="Upload logo"
                      name="display_picture"
                      tooltip={
                        <ul>
                          <li>Square image with max edge of 640px </li>
                          <li>Your logo must be smaller than 5 MB</li>
                          <li>A PNG, JPEG or JPG works best</li>
                          <li>
                            Images with a height or width less than 192px may
                            cause issues when resizing occurs
                          </li>
                        </ul>
                      }
                      sizeLimit={5000000}
                      sizeErrorMsg={"Image size must be smaller than 1MB."}
                      formatErrorMsg={"image should be PNG, JPEG or JPG."}
                      urlImage={true}
                      isResolveVariable = {true}
                    />
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="section"
                    id="section"
                  >
                    <Typography className="title">Description</Typography>
                    <Typography className="caption">
                      Add description for your business
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AppFormTextArea
                      // disableNewLineOnEnter={true}
                      label="Your Business Bio"
                      name="description"
                      placeholder="Hey, need help? try me"
                      subTitle="Introduce your business and tell customers how you can help"
                      rows={3}
                    />
                    <AppReactSelectField
                      name="vertical"
                      options={whatsappBusinessVerticalOptions ?? []}
                      label="Category"
                      valueKey={"value"}
                      subTitle="Pick one the category for your business"
                    />
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="section"
                    id="section"
                  >
                    <Typography className="title">
                      Contact Information
                    </Typography>
                    <Typography className="caption">
                      Add your contact information for your WhatsApp profile
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AppFormTextArea
                      label="Address"
                      name="address"
                      placeholder="Enter valid Address"
                      rows={3}
                    />
                    <AppFormField
                      name="email"
                      placeholder="Enter valid email address"
                      label="Email Address"
                      requiredSign={true}
                      // disabled={true}
                    />
                    <AppFormField
                      name="websites"
                      placeholder="https://www.domain.com"
                      label="Website"
                      requiredSign={false}
                      // disabled={true}
                    />
                  </AccordionDetails>
                </StyledAccordion>
                <StyledAccordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="section"
                    id="section"
                  >
                    <Typography className="title">Compliance Info</Typography>
                    <Typography className="caption">
                      Mandatory for sending catalog messages. Please fill all
                      fields
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <AppFormField
                      name="entity_name"
                      placeholder="Enter Business Name"
                      label="Legal name of Business"
                      requiredSign={true}
                    />
                    <AppReactSelectField
                      name="entity_type"
                      options={whatsappBusinessTypeOptions ?? []}
                      label="Business Type"
                      valueKey={"value"}
                      onChangeCallbackFn={businessTypeFn}
                    />
                    <AppFormRadioField
                      name={"is_registered"}
                      Options={BUSINESS_REGISTERED_OPTIONS}
                      divStyle={{ marginTop: "12px" }}
                      disabled={BusinessType?.value === "Partership" || BusinessType?.value === "Other" ? false : true}
                      label="Is your business registered?"
                    />
                    <Label className="highlighted-label">
                      Customer care information
                    </Label>
                    <DecsText>
                      Enter the contact information of the individual who will
                      respond to and resolve any complaints from your customers
                    </DecsText>
                    <Stack direction={"row"} gap="1.5rem" marginTop={"0.5rem"}>
                      <AppFormField
                        name="customer_email"
                        placeholder="Enter valid email address"
                        label="Email"
                      />
                      <AppFormMobileField
                        label="Mobile Number"
                        name="customer_mobile"
                        placeholder="+91923943233"
                        mobileCountryFormat="mobileCountryFormat"
                      />
                    </Stack>
                    <Label className="highlighted-label">
                      Grievance Officer Information
                    </Label>
                    <DecsText>
                      Enter the contact information of the individual who will
                      respond to and resolve any complaints from your customers
                    </DecsText>
                    <Stack direction={"row"} gap="1.5rem" marginTop={"0.5rem"}>
                      <AppFormField
                        name="grievance_officer_name"
                        placeholder="Enter valid Name"
                        label="Name"
                      />
                      <AppFormField
                        name="grievance_officer_email"
                        placeholder="Enter valid email address"
                        label="Email"
                      />
                      <AppFormMobileField
                        label="Mobile Number"
                        name="grievance_officer_mobile"
                        placeholder="+91923943233"
                        mobileCountryFormat="mobileCountryFormat"
                      />
                    </Stack>
                  </AccordionDetails>
                </StyledAccordion>
              </div>
              <div className="formFooter">
                <div className="left"></div>
                <div className="right">
                  <AppButton
                    onClick={()=>undefined}
                    isDisabled={true}
                    type="button"
                    variant="grey"
                  >
                    Cancel
                  </AppButton>
                  <AppSubmitButton
                    style={{ width: "200px" }}
                    title={"Update"}
                    // disabled={true}
                  />
                </div>
              </div>
            </AppForm>
          ) : (
            <div className="notConnectedBox">
              <h4>Connect your WhatsApp Business Account</h4>
              <p>To get started, connect your WhatsApp Business Account.</p>
              <AppButton component={Link} to={"/"}>
                Connect WhatsApp
              </AppButton>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default withLoader(WhatsappManageProfile);

const Container = styled.div`
  && {
    height: 100%;
    overflow: hidden;
    .topmenu {
      padding: 12px 15px;
      background: ${theme.palette.default.white};
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: ${theme.palette.default.black};
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
    }

    .formikFormWrapper {
      height: 100%;
      width: 70%;
      overflow: hidden;

      @media ${device.laptopM} {
        width: 100%;
      }
    }
    a {
      font-size: 14px;
      font-weight: 500;
      color: ${theme.palette.default.darkGrey};
    }

    label {
      font-weight: 500;
    }

    .highlighted-label {
      color: ${alpha(theme.palette.default.text, 0.75)};
    }

    .contentBox {
      width: 100%;
      height: calc(100vh - 120px);
      padding: 1rem;
      overflow: hidden;
      /* border: 1px solid; */
    }
    .formBox {
      width: 100%;
      border-radius: 4px;
      padding: 1rem;
      padding-right: 0px;
      height: 100%;
      background-color: ${theme.palette.default.white};
      overflow: overlay;
      height: calc(100vh - 120px);
    }
    .inner-wrapper {
      height: 100vh;
      overflow: auto;
      padding-right: 16px;
    }
    .formFooter {
      margin-top: auto;
      display: flex;
      gap: 1rem;
      padding: 1rem;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid ${theme.palette.default.border};
      .left {
        display: flex;
        width: 70%;
        overflow: hidden;
        flex-direction: column;
        justify-content: flex-start;
      }

      .right {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
        width: 100%;
      }
    }

    .Mui-expanded {
      margin: 0 !important;
    }

    .inline-form-rows {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      flex-wrap: nowrap;

      @media ${device.laptopM} {
        flex-wrap: wrap;
      }
    }

    .notConnectedBox {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      /* justify-content: center; */
      margin-top: 1rem;
    }
  }
`;

const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08) !important;
    /* margin-bottom: 1rem; */
    margin: 0px;
    border: 1px solid rgba(0, 0, 0, 0.08);
    .MuiAccordionSummary-content {
      margin: 0;
    }

    .Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-root {
      min-height: 40px;
    }

    &&::before {
      display: none;
    }

    .title {
      width: 33%;
      flex-shrink: 0;
      font-size: 15px;
      font-weight: 500;
      color: ${alpha(theme.palette.default.text, 0.85)};
    }
    .caption {
      color: ${theme.palette.default.darkGrey};
      font-size: 14px;
    }
  }
`;
