import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Divider, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AppFormRadioField,
  AppFormSelectField,
} from "../../../../../../AppComponents/Forms";
import { useAppDispatch } from "../../../../../../Store";
import { ContactsActions } from "../../../../../../Store/Slices/Contacts/contacts.action";
import { ConatctsLifeCycle, getLostReasonData, getMarketingStatusOption } from "../../../../../../Store/Slices/Contacts/contacts.selector";
import { useCurrentBot } from "../../../../../../Store/Slices/DashboardSlices";
import { marketingListActions } from "../../../../../../Store/Slices/MarketingList/marketingList.actions";
import { userListigData } from "../../../../../../Store/Slices/MarketingList/marketingList.selectors";
import { AppToolTip } from "../../../../../UiComponents/AppToolTip/AppToolTip";
import {
  UniqueValueData,
  WhatIfDuplicateData
} from "../constants";

interface Props {
  formStep: number;
  marketinglist: any;
  uniqValue: any;
  setFormStep: Dispatch<SetStateAction<number>>;
  type?: string;
}

const RenderImportRecordsFormStep2: React.FC<Props> = ({
  marketinglist,
  uniqValue,
  type,
}) => {
  const { setFieldTouched, setFieldValue, values } = useFormikContext<any>();
  const UserListOptions = useSelector(userListigData);
  const formkey3 = "import_option";
  const formkey4 = "unique_value";
  const formkey9 = "marketing_list";
  const formkey10 = "contact_owner";
  const formkey11 = "lifecycle_stage";
  const formkey12 = "lifecycle_status";
  const formkey13 = "lost_reason"
  const formkey14 = "lifecycle_status_list"
  const formkey15 = "lost_reason_list"

  const currentBot = useSelector(useCurrentBot);
  const ContactsLifeCycleFields = useSelector(ConatctsLifeCycle);
  const lostReasonData = useSelector(getLostReasonData)
  const marketingStatusOption = useSelector(getMarketingStatusOption)
  const dispatch = useAppDispatch();
  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot?.bot_id,
    }),
  };

  React.useEffect(() => {
    setFieldTouched(formkey9, false);
    setFieldTouched(formkey10, false);

    return () => {
      setFieldTouched(formkey9, false);
      setFieldTouched(formkey10, false);
    };
  }, []); // eslint-disable-line
  
  useEffect(() => {
    if(marketingStatusOption){
      marketingStatusOption.map((res:any) => {
        if(values[res?.name] === ""){
          setFieldValue(res?.name, res?.options[0]);
        }
      })
    }
  },[marketingStatusOption]) // eslint-disable-line

  const manipulatedArray = UserListOptions?.map((user: any) => {
    return {
      ...user,
      name: user?.first_name,
    };
  });

  useEffect(() => {
    if (uniqValue?.value === "both") {
      const emailOption = UniqueValueData?.find(
        (option: any) => option.value === "email"
      );
      if (emailOption) {
        setFieldValue(formkey4, emailOption);
      }
    } else {
      setFieldValue(formkey4, uniqValue);
    }
  }, [uniqValue]); // eslint-disable-line

  const { id }: any = useParams();

  const selectedMarketingList = marketinglist.find(
    (item: any) => item.id === id
  );

  // Use setFieldValue to update the selected value in Formik
  React.useEffect(() => {
    if (selectedMarketingList) {
      setFieldValue(formkey9, selectedMarketingList.id);
    }
  }, [selectedMarketingList, setFieldValue]);

  const getAllUsersListing = () => {
    const data = {
      HEADERS,
      limit: 100,
      offset: 0,
    };
    dispatch(marketingListActions.getUserListing(data));
  };

  useEffect(() => {
    dispatch(ContactsActions.getLifeCycleContactsDropDown({}))
    dispatch(ContactsActions.getMarketingStatusOption({}))
    getAllUsersListing();
  }, []);// eslint-disable-line
  const onChange = (value: any) => {
    setFieldValue(formkey14, ContactsLifeCycleFields.filter((res: any) => res.id === value)[0]?.statuses)
    setFieldValue(formkey12, '')
    setFieldValue(formkey13, '')
    setFieldValue(formkey15, [])
  }
  const onChangeStatus = (value: any) => {
    let data: any = values?.lifecycle_status_list.filter((res: any) => res.id === value)
    if (data[0]?.type === 'closed_lost') {
      setFieldValue(formkey15, lostReasonData?.options)
      document.getElementById("test")?.focus();
      setFieldValue("lostReasonStatus", false)
      setFieldValue(formkey13, '')
    } else {
      setFieldValue(formkey15, [])
      setFieldValue("lostReasonStatus", false)
    }
  }
  const onChangeLostReason = (value: any) => {
    if (value) {
      setFieldValue("lostReasonStatus", false)
    }
  }

  return (
    <Box className="step-two__wrap">
      <Typography variant="body1" sx={{ fontWeight: 500 }}></Typography>
      <Box className="duplicate">
        <AppFormRadioField name={formkey3} Options={WhatIfDuplicateData} />
      </Box>
      <Divider />

      <Box sx={{ mt: 2, mb: 2 }} className="unique_value">
        <Box display="flex" alignItems="center">
          <label style={{ margin: 0 }}>Unique Value</label>
          <AppToolTip
            tooltipProps={{
              title: `${uniqValue?.value === "both"
                ? "Recognize current contacts that match with <strong></strong>Mobile Number or Email ID since both were selected under management"
                : uniqValue?.value === "email"
                  ? "Recognize current contacts that match with Email is marked since mobile number was chosen under data management"
                  : "Recognize current contacts that match with Mobile Number is marked since mobile number was chosen under data management"
                }`,
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon fontSize="small" />}
          />
        </Box>
        <AppFormRadioField
          name="unique_value"
          Options={UniqueValueData?.map((option) => ({
            ...option,
            disabled:
              uniqValue?.value === "both"
                ? false
                : uniqValue?.value !== option?.value,
          }))}
        />

      </Box>
      <Divider />

      <Box className="subscription" sx={{ mt: 2, mb: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Subscription
        </Typography>
        <Box display="flex" sx={{ mt: 1, gap: 3 }}>
          {marketingStatusOption?.map((res: any) => {
            return (
              <Box>
                <label>{res?.label}</label>
                <AppFormRadioField
                  label=""
                  name={res?.name}
                  Options={res?.options}
                />
              </Box>
            )
          })}
        </Box>
      </Box>

      <Divider />

      <Box className="list" display="flex" sx={{ gap: 2 }}>
        <Box sx={{ mt: 2, flex: 1 }}>
          <label>Sales Owner</label>
          <AppFormSelectField
            label=""
            name={formkey10}
            Options={manipulatedArray || []}
          />
        </Box>

        {type !== "contact" && (
          <Box sx={{ mt: 2, flex: 1 }}>
            <label>Marketing List</label>
            <AppFormSelectField
              label=""
              name={formkey9}
              Options={marketinglist || []}
              isDisabled={selectedMarketingList ? true : false}
            />
          </Box>
        )}
      </Box>

      <Box className="list" display="flex" sx={{ gap: 2 }}>
        <Box sx={{ mt: 2, flex: 1 }}>
          <label>Lifecycle stage*</label>
          <AppFormSelectField
            label=""
            name={formkey11}
            Options={ContactsLifeCycleFields || []}
            onChange={onChange}
            disableOnChange={false}
            displayLabel={"label"}
          />
        </Box>
        <Box sx={{ mt: 2, flex: 1 }}>
          <label>Lifecycle status*</label>
          <AppFormSelectField
            label=""
            name={formkey12}
            Options={values?.lifecycle_status_list || []}
            valueKey="name"
            displayLabel={"label"}
            isDisabled={values?.lifecycle_status_list?.length > 0 ? false : true}
            onChange={onChangeStatus}
            disableOnChange={false}
          />
        </Box>
      </Box>
      <div id="test">
        {values?.lost_reason_list?.length > 0 ? <Box className="list" display="flex" sx={{ gap: 2 }}>
          <Box sx={{ mt: 2, flex: 1 }} >
            <label>Lost Reason*</label>
            <AppFormSelectField
              label=""
              name={formkey13}
              Options={values?.lost_reason_list || []}
              onChange={onChangeLostReason}
            />
          </Box>
          <Box sx={{ mt: 2, flex: 1 }}></Box>
        </Box> : null}
      </div>
    </Box>
  );
};

export default RenderImportRecordsFormStep2;
