// vendors
import IcoMoon, { IconProps } from "react-icomoon";
import theme from "./../../Theme";

// selection
import iconSet from "./../../assets/icomoon/selection.json";
interface UIIconProps extends IconProps {
  icon: string;
  size?: number;
  color?: string;
}

const resolve = (path: string, object: any, separator = ".") => {
  const properties: Array<string> = Array.isArray(path)
    ? path
    : path.split(separator);
  return properties.reduce((prev: string, curr: any) => prev?.[curr], object);
};

const Icon = (props: UIIconProps) => {
  let hexCode = props.color;
  if (props.color) {
    hexCode = props.color.startsWith("#")
      ? props.color
      : resolve(props.color, theme.palette);
  }

  return <IcoMoon iconSet={iconSet} {...props} color={hexCode} />;
};

export default Icon;
