import React, { FC } from "react";
import ReactApexChart from "react-apexcharts"
import styled from "styled-components";
import NoRecordsFound from "../../NoRecordsFound/NoRecordsFound";
import { green } from "@mui/material/colors";
import { Box } from "@mui/material";
import moment from "moment";

interface ApexGraphComponentProps {
    type?: any,
    data: any;
    categories?: any;
    height?: number;
    yaxisTitle?: string;
    handleClick?: (config: any) => void;
    dataLabels?: boolean;
    colors?: any;
    columnWidth?: any;
    showLegend?: boolean;
    hideTooltipIndex?: boolean;
}

const ApexGraphComponent: FC<ApexGraphComponentProps> = ({
    type = "bar",
    data,
    categories,
    height,
    yaxisTitle = "",
    handleClick = () => { },
    dataLabels = false,
    colors,
    columnWidth,
    showLegend = false,
    hideTooltipIndex = false
}) => {
    const [hasNoData, setHasNoData] = React.useState<any>(false);
    React.useEffect(() => {
        const isAllDataZero = (dataArray: any) => {
            if (type === "pie") {
                if (dataArray?.length === 0) {
                    return true;
                }
            } else {
                if (dataArray?.every((obj: any) => obj?.data?.length === 0 || obj?.data?.every((value: any) => value === 0))) {
                    return true;
                }
            }
        };
        const noDataFound = isAllDataZero(data);
        setHasNoData(noDataFound)
    }, [data]) // eslint-disable-line

    const threshold = 10; // Threshold for switching to large width

    const maxThreshold = categories && categories.length > threshold ? true : false;





    const analytcisData: any = {
        series: data && data,
        options: {
            chart: {
                // type: "pie",
                height: 350,
                events: {
                    dataPointSelection: function (event: any, chartContext: any, config: any) {
                        handleClick(config)
                    }
                },
                toolbar: {
                    show: true,
                    offsetX: -12,
                    offsetY: 16,
                },

            },
            colors: colors || [],
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: columnWidth || '55%',
                    endingShape: 'rounded',
                    distributed: true,
                },
            },
            dataLabels: {
                enabled: dataLabels,
            },
            legend: {
                show: showLegend
            },
            // stroke: {
            //     show: true,
            //     width: 2,
            //     colors: ['transparent']
            // },
            xaxis: {
                categories: categories && categories,
                // tickPlacement: 'on',
                labels: {
                    show: true,
                    rotate: maxThreshold ? -45 : 0,
                    hideOverlappingLabels: true,
                    rotateAlways: maxThreshold ? true : false,
                    trim: false,
                    formatter: function (value: any, timestamp: any, opts: any) {
                        if (moment(value, moment.ISO_8601, true).isValid()) {
                            const index = categories?.indexOf(value);
                            if ((categories?.length === 91) || (categories?.length === 90)) {
                                if (index % 6) {
                                    return '';
                                } else {
                                    return moment(value).format("MMM D");
                                }
                            } else if ((categories?.length === 31) || (categories?.length === 30)) {
                                if (index % 2) {
                                    return '';
                                } else {
                                    return moment(value).format("MMM D");
                                }
                            } else if ((categories?.length === 8) || (categories?.length === 7)) {
                                return moment(value).format("MMM D");
                            } else if (categories?.length > 91) {
                                if (index % 25) {
                                    return '';
                                } else {
                                    return moment(value).format("MMM D");
                                }
                            }
                        } else {
                            return value;
                        }
                    }
                }
            },
            yaxis: {
                title: {
                    text: yaxisTitle
                }
            },
            fill: {
                opacity: 1,
            },
            labels: categories && categories || [],
            tooltip: {
                x: {
                    formatter: function (value: any, { dataPointIndex }: any) {
                        if (moment(value, moment.ISO_8601, true).isValid()) {
                            return moment(categories[dataPointIndex])?.format("MMM D");
                        } else {
                            return hideTooltipIndex ? "" : value;
                        }
                    }
                },
                y: {
                    formatter: function (val: any) {
                        return val
                    }
                },

            },
        },
    };

    return (
        <ApexWrapper>
            {
                hasNoData ? (<NoRecordsFound />)
                    : (<Box
                        className="graph_area"
                        sx={{

                        }}>

                        <ReactApexChart
                            type={type}
                            options={analytcisData?.options}
                            series={analytcisData?.series}
                            width={type === "bar" && maxThreshold ? 3000 : "100%"}
                            height={height || 350}
                        />
                    </Box>
                    )}
        </ApexWrapper>
    )
}

export default ApexGraphComponent;

const ApexWrapper = styled.div`
  && {
    .apexcharts-toolbar {
        position: fixed;
    }
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .graph_area {
        width: 100%;
        overflow: auto;
        overflow-y: hidden;

        /* Styles for WebKit (Chrome, Safari, etc.) scrollbar */
    &::-webkit-scrollbar {
       height: 10px; /* Width of the scrollbar */
     }

     &::-webkit-scrollbar-track {
        background: #fff;
        border: 1px solid rgb(178 181 188);
    }


    /* Handle (the draggable scrolling element) */
    &::-webkit-scrollbar-thumb {
      background: rgb(175 175 175);
      cursor: grab !important;
      cursor: -webkit-grab !important;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
     background: rgb(126, 131, 146);; /* Color of the handle on hover */
     cursor: -webkit-grab;
     cursor: grab;
    }
    }
  }
`;