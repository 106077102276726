import { Backdrop, Box, CircularProgress, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppButton from "../../AppComponents/AppButton";
import {
    AppForm,
    AppFormObserver,
    AppSubmitButton
} from "../../AppComponents/Forms";
import { AppQueryBuilder } from "../../AppComponents/QueryBuilder";
import {
    convertQueryToMongo,
    initialQuery,
    isQueryValid,
} from "../../AppComponents/QueryBuilder/utils/utils";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ApplicationFormNetworkActions } from "../../Store/Slices/ApplicationsForm/applicationForm.actions";
import {  getFilterQuery, getapplicationFieldsMapping } from "../../Store/Slices/ApplicationsForm/applicationForm.selectors";
import { setFilterQuery } from "../../Store/Slices/ApplicationsForm/applicationForm.slice";

interface Props {
    setLocalValues: any;
    toggleDrawer: any;
}

const QueryBuilderForApplication: React.FC<Props> = ({
    setLocalValues,
    toggleDrawer
}) => {
    const { showAlert } = useNotification();
    const dispatch = useAppDispatch();
    const savedFilterQuery = useSelector(getFilterQuery);
    const { applicationsFieldsMapping, applicationsOperators, loading } = useAppSelector(getapplicationFieldsMapping);
    const [formikProps, setFormikProps] = useState<any>(null);
    const [queryInput, setQueryInput] = useState({
        name: "",
        filter_query: savedFilterQuery ? savedFilterQuery : initialQuery,
    });
    useEffect(() => {
        setQueryInput({
            ...queryInput,
            filter_query: savedFilterQuery,
        });
        dispatch(setFilterQuery(savedFilterQuery));
    }, [savedFilterQuery]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitBtn = (values: any, SubmitProps: any) => {
        const validQuery = isQueryValid(values?.filter_query, savedFilterQuery);

        if (!validQuery) {
            showAlert("Please add only valid rules.", "error");
            SubmitProps.setSubmitting(false);
            return;
        }
        const mongoQuery = convertQueryToMongo(values?.filter_query);
        dispatch(setFilterQuery(values?.filter_query));
        setLocalValues((prev: any) => ({
            ...prev,
            mongoQuery: mongoQuery,
            currentPage: 0,
            filter_query: values?.filter_query,
        }));
        toggleDrawer();
    };

    const getFieldsDataFromBackend = () => {
        // dispatch(QueryBuilderActions.getQueryBuilderMappingFields(payload));
        dispatch(ApplicationFormNetworkActions.getApplicationFieldsMapping(""));
    };

    useEffect(() => {
        getFieldsDataFromBackend();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onQueryChangeCallbackFn = (filtersValue: any) => {
        formikProps?.setFieldValue("filter_query", filtersValue);
        dispatch(setFilterQuery(filtersValue));
    };

    const resetFilter = () => {
        dispatch(setFilterQuery(initialQuery));
        const mongoQuery = convertQueryToMongo(initialQuery);
        dispatch(setFilterQuery(initialQuery));
        setLocalValues((prev: any) => ({
            ...prev,
            mongoQuery: mongoQuery,
            currentPage: 0,
            filter_query: initialQuery,
        }));
        toggleDrawer();
    };

    return (
        <Box className="form-first__area" sx={{ height: "calc(100% - 57px)" }}>
            <AppForm
                initialValues={queryInput}
                onSubmit={(values: any, submitProps: any) => {
                    handleSubmitBtn(values, submitProps);
                    setQueryInput(values);
                }}
                divStyle={{
                    gap: 0
                }}
            // validationSchema={SegmentSchema}
            >
                <BackdropLoader loading={loading} />
                {applicationsFieldsMapping?.length > 0 ? (
                    <AppQueryBuilder
                        fields={applicationsFieldsMapping}
                        operators={applicationsOperators}
                        onChangeCallbackFn={onQueryChangeCallbackFn}
                        defaultInputValueFromApi={
                            queryInput?.filter_query?.id ? queryInput?.filter_query : null
                        }
                    />
                ) : null}

                <AppFormObserver setFormikProps={setFormikProps} />

                <Divider />
                <Box
                    display="flex"
                    sx={{ justifyContent: "flex-end", padding: "16px 0" }}
                >
                    <AppButton
                        onClick={() => resetFilter()}
                        style={{ marginRight: "26px" }}
                        disabled={savedFilterQuery?.rules?.length === 0}
                    >
                        Reset
                    </AppButton>

                    {isQueryValid(savedFilterQuery, savedFilterQuery) ? (
                        <AppSubmitButton title="SAVE" />
                    ) : (
                        <AppSubmitButton disabled={true} title="SAVE" />
                    )}
                </Box>
            </AppForm>
        </Box>
    );
};

export default QueryBuilderForApplication;

interface BackdropProps {
    loading: boolean;
}
export const BackdropLoader: React.FC<BackdropProps> = ({
    loading = false,
}) => {
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: 99 }}
            open={loading ? true : false}
            onClick={() => undefined}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
