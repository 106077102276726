import styled from "styled-components";
import { Menu } from "@mui/material";
import { theme } from "../../../../../../../Customization/Theme";
// Css main Layout

export const SmsMenuFilter = styled(Menu)`
  && {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 400px;
    & .MuiMenu-list {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }

    & .MuiMenuItem-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-weight: 400;
        margin-bottom: 0.4rem;
        font-size: 0.875rem;
        color: ${theme.palette.default.black};
      }
    }
    .setting-page-mainboxtopbar__right {
      padding: 1rem;
    }
    .css-tucewo {
      display: flex;
      gap: 1rem;
      justify-content: space-evenly;
    }
  }
`;
export const ErrorComponent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    min-height: calc(100vh - 350px);
    overflow: auto;
    img {
      max-width: 100%;
      height: auto;
    }
    .error-container {
      width: 100 %;
      height: 100 %;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .err-content {
      gap: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
`;

export const BroadcastMainWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
`;

export const BroadcastMainWrapperTitle = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const BroadcastLogTable = styled.div`
  & {
    width: 100%;
    background-color: ${theme.palette.default.white};
   
    > div {
      overflow: auto;
      height: calc(100vh - 300px);
    }
    .table-header {
      position: sticky;
      top: 0;
       

      .table-row {
        display: flex;
        align-items: center;
      }
      .date {
        max-width: 220px;
        min-width: 220px;
      }
      .from {
        max-width: 150px;
        min-width: 150px;
      }
      .to {
        max-width: 150px;
        min-width: 150px;
      }
      .body {
        max-width: 420px;
        min-width: 420px;
      }
      .status {
        max-width: 150px;
        min-width: 150px;
      }
      .error_reason {
        max-width: 200px;
        min-width: 200px;
      }
      .price {
        max-width: 150px;
        min-width: 150px;
      }
      .price {
        max-width: 150px;
        min-width: 150px;
      }
      .unit {
        max-width: 150px;
        min-width: 150px;
      }
      .total_price {
        max-width: 150px;
        min-width: 150px;
      }
      .direction {
        max-width: 150px;
        min-width: 150px;
      }

      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        padding: 16px 0;
        background-color: ${theme.palette.default.white};
        p {
          padding: 0 16px;
          border-left: 1px solid rgba(126, 131, 146, 0.5);
        }
        &:first-child {
          p {
            border: none;
          }
        }
      }
    }
    .table-body {
      .table-row {
        display: flex;
        jsutify-content: center;
        padding: 10px 0;
        color: rgb(126, 131, 146, 1.5);
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .date {
        max-width: 220px;
        min-width: 220px;
      }
      .from {
        max-width: 150px;
        min-width: 150px;
      }
      .to {
        max-width: 150px;
        min-width: 150px;
      }
      .body {
        max-width: 420px;
        min-width: 420px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .status {
        max-width: 150px;
        min-width: 150px;
      }
      .error_reason {
        max-width: 200px;
        min-width: 200px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .price {
        max-width: 150px;
        min-width: 150px;
      }
      .unit {
        max-width: 150px;
        min-width: 150px;
      }
      .total_price {
        max-width: 150px;
        min-width: 150px;
      }
      .direction {
        max-width: 150px;
        min-width: 150px;
      }

      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
      }
    }
  }
`;

export const BroadcastBodyWrapper = styled.div`
  & {
    padding: 1rem;
    background-color: #fff;


    .fields-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      gap: 0.4rem;
      .sms_left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .create-table-select {
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        .create-table-select {
          z-index: 2;
          .css-b62m3t-container {
            width: 220px;
            height: 40px;
          }
        }
      }
      .sms_right {
        display: flex;
        align-items: center;

        .sms_select {
          .sms-filter {
          }
        }
      }
    }
    .setting-page-mainboxtopbar{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      flex-wrap: wrap;
      gap:20px;
      .setting-page__menu:last-child {
        margin-left: auto;
      }
      > button {
        margin-left: 10px;
      }
      & .MuiMenuItem-root {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          p {
              font-weight: 400;
              margin-bottom: 0.4rem;
              font-size: 0.875rem;
              color: ${theme.palette.default.black};
          }
    }
  }
`;
