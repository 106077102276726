import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import "chart.js/auto";
import React from "react";
import { Line } from "react-chartjs-2";
import { theme } from "../../Customization/Theme";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "./AnalyticsSummaryStyles";
import { SkeletonGraph } from "./SkeletonLoader.tsx/SkeletonCard";
import { GraphWrapper, Graph, NewGraphNativeSelect } from "./UserGrowth";
import { ErrorComponent } from "../../AppComponents/Forms/Styles";
import NoData from "../../assets/images/noDatafound.svg";

ChartJS.register(
  ArcElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler
);

interface Props {
  data_Arr?: any;
  loading?: boolean;
  localValues?: any;
  setLocalValues?: any;
  ChannelList?: any;
}

const UserGrowth: React.FC<Props> = ({
  data_Arr,
  loading,
  localValues,
  setLocalValues,
  ChannelList,
}) => {
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      title: {
        display: false,
        text: "User Growth (Channel-Wise)",
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: function (context: any) {
            return "Users";
          },
          label: function (context: any) {
            let label = context.dataset.label || "";
            if (label) {
              label += ":";
            }
            if (context.parsed.y !== null) {
              label += `${context.label}: ${context.parsed.y}`;
            }
            return label;
          },
        },
        backgroundColor: `${theme.palette.default.white}`,
        borderColor: `${theme.palette.default.fadeGrey}`,
        borderWidth: 1,
        boxPadding: 1,
        titleColor: `${theme.palette.default.chineseBlack}`,
        titleFont: {
          size: 15,
          family: `${theme.typography.fontFamily}`,
          weight: "500",
        },
        titleAlign: "left" as const,
        titleMarginBottom: 6,
        titleSpacing: 8,
        bodyColor: `${theme.palette.default.chineseBlack}`,
        bodyAlign: "center" as const,
        bodyFont: {
          size: 14,
          family: `${theme.typography.fontFamily}`,
          weight: "500",
        },
        footerColor: "green",
        caretSize: 8,
        caretPadding: 18,
        displayColors: false,
        cornerRadius: 8,
        usePointStyle: true,
        yAlign: "bottom" as const,
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          beginAtZero: true,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
  };

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      bot: "",
    });
  };

  const renderSelectIcon = localValues.bot === "All channel" ? (
    <GridCloseIcon fontSize="small" />
  ) : null;
  return (
    <React.Fragment>
      <GraphWrapper>
        <p className="graph_header">
          <TextTitle>User Growth (Channel Wise)</TextTitle>
          <AppToolTip
            tooltipProps={{
              title: "User growth by channel",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="userGrowthTooltip" />}
          />
        </p>

        <NewGraphNativeSelect>
          <Select
            defaultValue={undefined}
            name="bot"
            value={localValues?.bot}
            onChange={(e) => handleSelectChange(e, "bot")}
            displayEmpty
            className="new-select-main"
            sx={{ border: `1px solid ${theme.palette.default.lighterGreyNew}` }}
          >
            <MenuItem value="">All Channel</MenuItem>
            {ChannelList?.map((item: any, index: number) => {
              return (
                <MenuItem value={item?.bot_id} key={index}>
                  {item?.name}
                </MenuItem>
              );
            })}
          </Select>
          <div
            className="clear-icon"
            onClick={(e: any) => clearCallback(e, "bot")}
          >
            {renderSelectIcon}
          </div>
        </NewGraphNativeSelect>
      </GraphWrapper>
      {loading ? (
        <SkeletonGraph />
      ) : data_Arr.datasets.length === 0 ? (
        <ErrorComponent>
          <Box>
            <img src={NoData} className="imageError" alt="errorImage" />
          </Box>
          <Box className="err-content">
            <Typography className="title">No Records Found.</Typography>
          </Box>
        </ErrorComponent>
      ) : (
        <Graph>
          <Line data={data_Arr} options={options} />
        </Graph>
      )}
    </React.Fragment>
  );
};

export default UserGrowth;
