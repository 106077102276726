import { createAsyncThunk } from "@reduxjs/toolkit";
import { AutoAssignmentRulesSettingsService } from "./autoAssignmentRulesSettingsService";

export class AutoAssignmentRulesSettingsActions {
  // create assignment rule
  static createContactRules = createAsyncThunk(
    "LiveChatSlice/createContactRules",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutoAssignmentRulesSettingsService.createContactRules(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // get contacts
  static getRulesList = createAsyncThunk(
    "AutoAssignmentRulesSettingsActions/getRulesList",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutoAssignmentRulesSettingsService.getRulesList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // get Contacts rules by id
  static getContactsRulesById = createAsyncThunk(
    "AutoAssignmentRulesSettingsActions/getContactsRulesById",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutoAssignmentRulesSettingsService.getContactsRulesById(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  // Update  Contacts rules
  static UpdateContactsRulesById = createAsyncThunk(
    "FaqSourceSlice/UpdateContactsRulesById",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutoAssignmentRulesSettingsService.updateContactsRules(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );

  // delete contacts By ID
  static deleteContactsRulesById = createAsyncThunk(
    "FaqSourceSlice/deleteContactsRulesById",
    (data: any, { dispatch, rejectWithValue }) => {
      return AutoAssignmentRulesSettingsService.deleteContactsRules(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        });
    }
  );
}
