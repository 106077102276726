import { Tooltip } from "@mui/material";
import Grow from "@mui/material/Grow";

import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import {
  HocProgressBarLoader,
  HocProgressBarLoaderProps,
} from "../../../../HOC/HocProgressBarLoader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../Store";
import { AppErrorMessage } from "../../../../AppComponents/Forms";
import { ReactComponent as File } from "../../../../assets/images/FileUpload.svg";
import { ReactComponent as FileUploaded } from "../../../../assets/images/FIleUploaded.svg";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";

const IMAGE_TYPES = ["image/jpeg", "image/png"];
const VIDEO_TYPES = ["video/mp4", "video/3gp"];
const AUDIO_TYPES = ["audio/aac", "audio/mp4", "audio/mpeg", "audio/amr"];
const STICKER_TYPES = ["image/webp"];

const DOCUMENT_TYPES = [
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
}

// interface Props extends LoaderComponentProps {
interface Props extends HocProgressBarLoaderProps {
  name: string;
  mediaType: string;
  extensions?: string[];
  sizeLimit?: number;
  divStyle?: any;
  setfname?: any;
  fname: string;
  setfileData?: any;
  fileData: any;
}

const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "document") {
    return DOCUMENT_TYPES;
  } else {
    return [];
  }
};
const getCorrectMediaSize = (mediaType: string) => {
  if (mediaType === "document") {
    return 10000000;
  } else {
    return 500000;
  }
};

const MediaPicker = (props: Props, handleChange: any) => {
  const {
    setFieldTouched,
    values,
    setFieldValue,
    setFieldError,
    errors,
    touched,
  } = useFormikContext<any>();

  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const [mainState, setMainState] = useState<ImageState>(ImageState.INITIAL); // initial, search, gallery, uploaded
  const [allowedMediaTypes, setAllowedMediaTypes] = useState<string[]>(
    props?.extensions || getCorrectMediaFormats(props.mediaType)
  );
  const [allowedMediaSize, setAllowedMediaSize] = useState<number>(
    props?.sizeLimit || getCorrectMediaSize(props.mediaType)
  );

  useEffect(() => {
    if (getIn(values, props?.name)?.name) {
      setMainState(ImageState.UPLOADED);
    } else {
      setMainState(ImageState.INITIAL);
    }
  }, []);

  const handleUploadClick = (event: any) => {
    setFieldTouched(props.name, true);

    var file = event.target.files[0];

    // props.setfname(event.target.files[0].name);
    const reader: any = new FileReader();
    if (!reader || !file) {
      imageResetHandler();
      return null;
    }
    var url = reader?.readAsDataURL(file);
    if (allowedMediaSize && file.size > allowedMediaSize) {
      setTimeout(() => {
        setFieldError(
          props.name,
          `${props.mediaType} size must be less than ${
            allowedMediaSize / 1000000
          } MB`
        );
      }, 1);
      return;
    }

    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      if (!allowedMediaTypes.includes(mimeType)) {
        setTimeout(() => {
          setFieldError(props.name, "Invalid File format");
        }, 1);
        return;
      }
      onImageChange(file);
    };
  };
  handleChange = { handleUploadClick };

  const onImageChange = (file: any) => {
    setFieldTouched(props.name, true);

    if (file) {
      // props.setLoading(true);
      props.setfileData(file);
      const formData = new FormData();
      formData.append("source", file);
      // setTimeout(() => {

      // }, 500);
      // showAlert("File uploaded", "success");

      setMainState(ImageState.UPLOADED);
      setFieldValue(props.name, file);
    }
  };

  const imageResetHandler = () => {
    setMainState(ImageState.INITIAL);
    setFieldValue(props?.name, null);
  };

  const renderInitialState = () => {
    return (
      <StyledIntialState>
        <File />
        <input
          accept={allowedMediaTypes.toString()}
          id={props.name}
          name={props.name}
          type="file"
          onChange={handleUploadClick}
          value=""
        />
        <label htmlFor={props.name}>
          <Tooltip
            arrow
            enterDelay={1}
            leaveDelay={0}
            TransitionComponent={Grow}
            placement="top"
            title={"Upload"}
          >
            <MediaUploadBox variant="text">
              Upload {props.mediaType}
            </MediaUploadBox>
          </Tooltip>
        </label>
      </StyledIntialState>
    );
  };

  const renderUploadedState = () => {
    const file: any = getIn(values, props?.name);
    return (
      <StyledUploadedState>
        <StyledUploadedImageWrap>
          <FileUploaded />
          <div className="fname_wrapper">
            {/* {props.fname} */}
            {file?.name}
            <Tooltip
              arrow
              enterDelay={1}
              leaveDelay={0}
              TransitionComponent={Grow}
              placement="top"
              title={"Delete"}
            >
              <span className="close" onClick={() => imageResetHandler()}>
                <AppMaterialIcons iconName="close" />
              </span>
            </Tooltip>
          </div>
        </StyledUploadedImageWrap>
      </StyledUploadedState>
    );
  };

  return (
    <StyledViewWrap style={props?.divStyle} className={"customInput"}>
      {(mainState === ImageState.INITIAL && renderInitialState()) ||
        (mainState === ImageState.UPLOADED && renderUploadedState())}
      <AppErrorMessage
        error={getIn(errors, props.name)}
        visible={getIn(touched, props.name)}
      />
    </StyledViewWrap>
  );
};

export default HocProgressBarLoader(MediaPicker);

const StyledUploadedImageWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    .fname_wrapper {
      display: flex;
      align-items: center;
      .close {
        display: flex;
        align-items: center;
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }
    svg {
      cursor: pointer;
      font-size: 1.1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
const MediaUploadBox = styled.div`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40px;
    height: 40px;

    padding: 6px 12px;
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.palette.primary.main};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    &:disabled {
      color: ${theme.palette.default.grey};
    }
    &:hover {
      color: ${theme.palette.primary.dark};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;

const StyledViewWrap = styled.div`
  && {
    border: 1px dashed ${theme.palette.default.grey};
    // height: 139px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // gap: 0.5rem;
    padding: 2.6rem 0.5rem;
    margin: -1rem 0 1rem;
    position: relative;
    input {
      display: none;
    }
    p {
      color: ${theme.palette.default.error};
    }
  }
`;

const StyledIntialState = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    // gap: 1rem;

    .MuiFab-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
    }

    .MuiIconButton-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.secondary.main};
      border-radius: 50%;
    }
  }
`;

const StyledUploadedState = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    align-items: center;

    img {
      width: 52px !important;
      height: 52px !important;
      object-fit: cover !important;
      overflow: hidden;
    }
  }
`;
