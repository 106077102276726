import { getIn, useFormikContext } from "formik";
import React from "react";
import Select from "react-select";
import styled from "styled-components";

import { Tooltip } from "@mui/material";
import { AppErrorMessage } from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { AppMaterialIcons } from "../Icons";
// import defaultProps from "react-select/dist/declarations/src/Select";
interface Props {
  options: any[];
  label?: string;
  displayKey?: string;
  valueKey?: string;
  placeholder?: string;
  defaultIndexValue?: number;
  name: string;
  divStyle?: any;
  tooltip?: any;
  onChangeCallbackFn?: (value: any) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isRequired?: boolean;
  isOptionDisabled?: ((option: any, selectValue: any) => boolean) | undefined;
  hideSelectedOptions?: boolean;
  intialValueType?: "object" | "string"; // new prop
  optionsPlaceholder?: string;
  [otherProps: string]: any;
  children?: React.ReactNode;
  styles?: any;
  components?: any;
  onMenuOpen?: any;
}

const AppReactSelectField: React.FC<Props> = ({
  options,
  label,
  name,
  placeholder = "Select an option",
  divStyle,
  displayKey = "label",
  valueKey = "value",
  tooltip,
  onChangeCallbackFn,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  isRequired = false,
  isOptionDisabled = (option: any) => option?.disabled,
  hideSelectedOptions = true,
  styles,
  components,
  intialValueType = "object", // default value for new prop
  optionsPlaceholder,
  onMenuOpen,
  ...otherProps
}) => {
  const {
    errors,
    touched,
    setFieldValue,
    values,
    setFieldTouched,
    setFieldError,
  } = useFormikContext<any>();

  React.useEffect(() => {
    if (intialValueType === "string" && typeof getIn(values, name) === "string") {
      const selectedValue = getIn(values, name);
      const selectedOption = options?.find(
        (option:any) => option?.[valueKey] === selectedValue
      );
      if (selectedOption) {
        setFieldValue(name, selectedOption);
      }
    }
  }, [getIn(values, name)]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e: any) => {
    setFieldTouched(name, true);
    if (e) {
      setFieldValue(name, e);
      onChangeCallbackFn && onChangeCallbackFn(e);
    } else {
      setFieldValue(name, null);
      onChangeCallbackFn && onChangeCallbackFn(null);
    }
  };

  return (
    <Div style={divStyle}>
      <Label>
        {label} {isRequired && <sup style={{ color: "red" }}>*</sup>}

        {tooltip && (
          <Tooltip title={tooltip} placement="right">
            <AppMaterialIcons iconName="HelpOutline" />
          </Tooltip>
        )}
      </Label>
      <Select
        id={name}
        name={name}
        options={options}
        value={getIn(values, name) ?? null}
        onChange={(e) => handleChange(e)}
        onBlur={(e: any) => {
          if (!getIn(values, name)) setFieldError(name, undefined);
        }}
        placeholder={placeholder}
        getOptionLabel={(option: any) =>
          option?.[displayKey] || option?.label || option?.name || option?.value
        }
        isOptionSelected={(option: any) =>
          option?.[valueKey] === getIn(values, name)?.[valueKey]
        }
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        blurInputOnSelect={true}
        isLoading={isLoading}
        isOptionDisabled={isOptionDisabled}
        hideSelectedOptions={hideSelectedOptions}
        menuPosition="fixed"
        noOptionsMessage={() => optionsPlaceholder ? optionsPlaceholder : 'No Categories Found'}
        // maxMenuHeight={150}
        // menuPlacement="top"
        styles={styles}
        components={components}
        onMenuOpen={onMenuOpen}
      />
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default AppReactSelectField;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    gap: 6px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    margin-bottom: 6px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;

export const StyledReactSelectInput = styled(Select)`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 0 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 2.4rem;
    position: relative;
    .clearBtn {
      background-color: #f5f6f8;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }

    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 2px solid ${theme.palette.default.lightGrey};
    }
  }
`;

export const customReactSelectCss = {
  control: (styles: any) => ({
    ...styles,
    backgroundColor: "rgb(245, 246, 248) !important",
    borderColor: "transparent !important",
    minHeight: "34px !important",
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#7e8392 !important",
  }),
};
