const getValueFromObject = (object: any, key: string): any => {
  let result;
  for (let i in object) {
    if (i === key) {
      return object[i];
    }
    if (typeof object[i] === "object" && object[i] !== null) {
      result = getValueFromObject(object[i], key);
      if (result) {
        return result;
      }
    }
  }
  return result;
};

export default getValueFromObject;
