import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../../../Customization/Theme";
import { generateWidgetColorPallete } from "../../../../../../Utils/ColorUtils";
import { WHATSAPP_PRIMARY_COLOR } from "../../../../BroadcastPreview/Constants";
import { StyledBroadcastPreviewWrap, WhatsappBotArea } from "../../../../BroadcastPreview/Styles";
import { convertWhatsappTextToHtml } from "../../../Utils/utils";
import ListIcon from '@mui/icons-material/List';

interface IBroadcastPreviewInterface extends IBroadcastPreviewProps {
    phoneNumberButtonData: any;
    websiteButtonData?: any;
    carouselButtonData?: any;
    limitedTimeOfferData?: any;
    copyCodeUrlQuickRepliesEtcStateRenderState?: any;
    flowButton?: any
    button_type?: string;
}

const BroadcastTemplatePreview: React.FC<IBroadcastPreviewInterface> = ({
    primaryColor = WHATSAPP_PRIMARY_COLOR,
    previewType = "WHATSAPP",
    bodyValue = "",
    headerValue = "",
    footerValue = "",
    buttonsData = [],
    headerType = "NONE",
    phoneNumberButtonData = {},
    websiteButtonData = [],
    carouselButtonData = [],
    limitedTimeOfferData = {},
    flowButton = [],
    copyCodeUrlQuickRepliesEtcStateRenderState = [],
    button_type = "",
}) => {
    const scheme = generateWidgetColorPallete(primaryColor);
    const text = convertWhatsappTextToHtml(bodyValue)?.replaceAll("\n", "<br/>");
    const [carouselIndex, setcarouselIndex] = useState(0)
    const renderComponent = (component: any) => {
        switch (component.name) {
            case "add_quick_replies":
                return component.checked && renderButtons(buttonsData);
            case "add_coupon_code":
                return component.checked && renderCopyCodeButton();
            case "add_website_url":
                return component.checked && renderWebsiteUrlButtons(websiteButtonData, buttonsData)
            case "add_phone_number":
                return component.checked && renderPhoneNumberButton(phoneNumberButtonData)
            default:
                return null;
        }
    };

    return (
        <WhatsappBotArea>
            <StyledBroadcastPreviewWrap colorScheme={scheme}>
                <div className="widgetWrap">
                    {/* HEADER */}
                    {renderTopHeader(previewType)}
                    {/* SCREEN WRAP */}
                    <div className="chatScreenWrap">
                        <div className="chat-screen__content">
                            <div className="singleLineBox">
                                <p className="avatar">A</p>
                                <div className="MsgContainer">
                                    {/* RENDER DYANMIC HEADER */}
                                    {renderHeader(headerType, headerValue)}
                                    <div
                                        className="bodyMsgContainer"
                                        dangerouslySetInnerHTML={{
                                            __html: text || "",
                                        }}
                                    />
                                    {/* RENDER DYANMIC BUTTONS WITH ORDER*/}
                                    {copyCodeUrlQuickRepliesEtcStateRenderState?.map((component: any) => renderComponent(component))}
                                    {button_type === "carousel" && carouselButtonData && renderCarousel(carouselButtonData, carouselIndex, setcarouselIndex)}
                                    {limitedTimeOfferData && renderLimitedTimeOfferData(limitedTimeOfferData)}
                                    {flowButton && renderButtons(flowButton)}
                                    {/* Footer */}
                                    <div className="footerMsgContainer">{footerValue}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* INPUT BAR  */}
                    {renderInputBar(previewType)}
                </div>
            </StyledBroadcastPreviewWrap>
        </WhatsappBotArea>
    );
};

export default BroadcastTemplatePreview;

const renderHeader = (headerType: string, headerValue: any) => {
    return (
        <React.Fragment>
            <div className="headerMsgContainer">
                {typeof headerValue === "string" && headerValue}
            </div>
            {headerType === "NONE" && null}
            {headerType === "TEXT" && null}
            {headerValue?.source ? (
                <div className="mediaBox">
                    {headerType === "IMAGE" && <img src={headerValue?.source} alt="" />}
                    {headerType === "VIDEO" && <video src={headerValue?.source} />}
                    {headerType === "DOCUMENT" && (
                        <AppMaterialIcons iconName={"DescriptionOutlined"} />
                    )}
                </div>
            ) : null}
        </React.Fragment>
    );
};
const renderButtons = (buttonsData: any = []) => {
    const maxVisibleButtons = 2;
    return (
        <React.Fragment>
            {buttonsData?.slice(0, maxVisibleButtons).map((curItem: any, index: number) => (
                <div className="buttonMsgContainer" key={index.toString()}>
                    {curItem?.action_type?.length > 0 && (
                        <AppMaterialIcons
                            iconName={curItem?.action_type === "URL" ? "Link" : "Call"}
                        />
                    )}
                    {curItem?.text}
                </div>
            ))}
            {buttonsData?.length > maxVisibleButtons && (
                <div className="buttonMsgContainer">
                    <ListIcon />
                    More Options
                </div>
            )}
        </React.Fragment>
    );
};
const renderCarousel = (renderCarousel: any = [], carouselIndex: number, setcarouselIndex: any) => {
    return (
        <CarouselContainer key={carouselIndex} >
            <div className="imageContainer">
                {renderCarousel?.[carouselIndex]?.card_header && (
                    <div className="" key={carouselIndex.toString()}>
                        <img src={renderCarousel?.[carouselIndex]?.card_header?.source} alt="" className="image" />
                    </div>
                )}
                <div className="body_text">
                    {renderCarousel?.[carouselIndex]?.text}
                </div>
            </div>
            <div>
                <IconButton
                    onClick={() => setcarouselIndex((prev: number) => prev - 1)}
                    disabled={carouselIndex === 0}
                    className="leftArrow"
                >
                    <AppMaterialIcons iconName={"ArrowLeft"} style={{ width: "50px", height: "50px" }} />
                </IconButton>

                <IconButton
                    onClick={() => setcarouselIndex((prev: number) => prev + 1)}
                    disabled={renderCarousel.length === 0 || carouselIndex === renderCarousel.length - 1}
                    className="rightArrow"
                >
                    <AppMaterialIcons iconName={"ArrowRight"} style={{ width: "50px", height: "50px" }} />
                </IconButton>
            </div>
            {renderCarousel?.[carouselIndex]?.carousel_buttons?.map((item: any) => {
                return (
                    <div className="buttonMsgContainer" key={carouselIndex.toString()}>
                        {["dynamic", "static"].includes(item?.sub_type) ? (
                            <div className="buttonMsgContainer" key={carouselIndex.toString()}>
                                <AppMaterialIcons iconName={"OpenInNew"} />
                                {item?.text}
                            </div>
                        ) : item?.buttons_type === "PHONE_NUMBER" ?
                            <div className="buttonMsgContainer" key={carouselIndex.toString()}>
                                <AppMaterialIcons iconName={"Call"} />
                                {item?.phone_number_text}
                            </div> : (
                                <div className="buttonMsgContainer" key={carouselIndex.toString()}>
                                    <AppMaterialIcons iconName={"Reply"} />
                                    {item?.quick_reply}
                                </div>
                            )}
                    </div>
                )
            })}
        </CarouselContainer>
    );
};


const renderWebsiteUrlButtons = (buttonsData: any = [], quickRepliesButtonsData: any = []) => {
    if (quickRepliesButtonsData?.length >= 2) return null;
    return buttonsData?.map((curItem: any, index: number) => {
        return (
            <div className="buttonMsgContainer" key={index.toString()}>
                <AppMaterialIcons iconName={"OpenInNew"} />
                {curItem?.button_text || curItem?.text}
            </div>
        );
    })
};

const renderCopyCodeButton = () => {
    return (
        <div className="buttonMsgContainer">
            <ContentCopyIcon />
            {'Copy offer Code'}
        </div>
    );
};
const renderPhoneNumberButton = (renderPhoneNumberButton: any) => {
    return (
        <div className="buttonMsgContainer">
            <AppMaterialIcons iconName={"Call"} />
            {renderPhoneNumberButton?.button_text}
        </div>
    );
};

const renderTopHeader = (previewType: string) => {
    return (<div className="headerWrap">
        <div className="headerLeft">
            <p className="headerLogo">U</p>
            <p className="headerTitle">User</p>
        </div>
        <div className="headerRight">
            <AppMaterialIcons iconName={"MoreVert"} />
        </div>
    </div>
    )
}

const renderInputBar = (previewType: string) => {
    return (
        <div className="inputBarWrap">
            <div className="inputBarTextBox">
                <div className="textBoxLeft">
                    <AppMaterialIcons iconName={"SentimentVerySatisfied"} />
                    Hello div..
                </div>
                <div className="textBoxRight">
                    <AppMaterialIcons iconName={"CameraAlt"} />
                    <AppMaterialIcons iconName={"AttachFile"} />
                </div>
            </div>

            <div className="inputBarRightBox">
                <IconButton>
                    <AppMaterialIcons iconName={"Mic"} />
                </IconButton>
            </div>
        </div>
    )
}

const renderLimitedTimeOfferData = (limitedTimeOfferData: any) => {
    return (
        <React.Fragment>
            <div className="buttonMsgContainer">
                <ContentCopyIcon />
                {'Copy offer Code'}
            </div>
            <div className="buttonMsgContainer">
                <AppMaterialIcons iconName={"OpenInNew"} />
                {limitedTimeOfferData?.offer_button_text}
            </div>
        </React.Fragment>
    );
}

const CarouselContainer = styled.div`
&&{
    width: 100%;
    position: relative; 
    .imageContainer{
        min-height: 180px;
        width: 100%;
        overflow: hidden;
    .image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .body_text{
            background: ${theme.palette.default.white};
            margin-top: 10px;
        }
    }
    .leftArrow{
        position: absolute;
        top: 40px;
        left: -60px;
    }
    .rightArrow{
        position: absolute;
        top: 40px;
        right: -60px;
    }
}`
