import styled from 'styled-components'
import { theme } from '../../../../Customization/Theme'
import LivePreview from './components/LivePreview'
import Styles from './components/Styles'

const StylePreview = (): JSX.Element => {
  return (
    <Container>
      <LeftSection>
        <Styles />
      </LeftSection>
      <RightSection>
        <LivePreview />
      </RightSection>
    </Container>
  )
}

const Container = styled.div`
&&{
  margin:1rem;
  display:flex;
  justify-content:center;
  height: calc(100vh - 250px);
  width: 100%;
  gap:20px;
  .info{
    color:${theme.palette.default.darkGrey};
    padding:1rem;
  }
}`

const LeftSection = styled.div`
&&{
  width:30%;
  background: ${theme.palette.default.white};
  overflow-y:auto;
  padding:0 10px;
}`;

const RightSection = styled.div`
&&{
  width: 70%;
  background: ${theme.palette.default.white};
  overflow-y:auto;
}`
export default StylePreview