import {
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  allMessagesStore,
  removeAllMessages,
  setPreviousConversationsData,
  setSessionId,
  setUserContactDetailsData,
  setUserInfoData,
} from "../../../../Store/Slices/socket/AllMessagesSlice";
import { socketStatusStore } from "../../../../Store/Slices/socket/SocketSlice";
import { ChatNetworkService } from "../../../../Store/Slices/socket/liveChat/ChatNetworkService";
import { setActiveId } from "../../../../Store/Slices/socket/liveChat/chat.slice";
import { formatString, humanizeDate } from "../../../../Utils";
import UserChatAddNotes from ".././UsersDetail/UserAddNotes/UserChatAddNotes";
import UserDetailHeader from "./UserDetailHeader/UserDetailHeader";
import UserDetailMenu from "./UserDetailMenu/UserDetailMenu";
import {
  StyledPreviousConvoBox,
  UserDetailAccordian,
  UserDetailSummary,
  UsersDetailWrapper,
} from "./UsersDetailStyles";
import UserPreviousNotes from "./PreviousNotes/UserPreviousNotes";
import UserInfo from "./UserInfo/UserInfo";
import { UserDetailRightMenu } from "./constants";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { theme } from "../../../../Customization/Theme";
import { LiveChatAccordian } from "../Sidebar/LiveChatSidebarStyles";
import { ProtectedAppUiElement } from "../../../../AppComponents/ProtectedComponents";

const UsersDetail = () => {
  const navigate = useNavigate();
  const currentBot = useAppSelector(useCurrentBot);

  const dispatch = useAppDispatch();
  const [width, setWidth] = useState<string>("0px");
  const [screenWidth, setScreenWidth] = useState<number>(1600);
  const [menuBtn, setMenuBtn] = useState<boolean>(true);
  const { showAlert } = useNotification();
  const {
    sessionId,
    userInfoData,
  } = useSelector(allMessagesStore);
  const toggleMenu = (value: boolean): void => {
    setMenuBtn(value);

    if (width === "0px") {
      setWidth("300px");
    } else {
      setWidth("0px");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUserContactDetails = (
    email: string | null,
    phone: string | null,
    id: string | null
  ) => {
    const config = {
      email: email,
      phone: phone,
      session_id: sessionId,
    };
    ChatNetworkService.getChatContactDetails(config)
      .then((res: any) => {
        dispatch(setUserContactDetailsData(res?.data?.data));
      })
      .catch((err) => {
      })
      .finally(() => {});

    ChatNetworkService.getChatContactPreviousConversations(config)
      .then((res: any) => {
        dispatch(setPreviousConversationsData(res.data?.data));
      })
      .catch((err) => {
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (
      sessionId &&
      (userInfoData?.email || userInfoData?.phone)
    ) {
      getUserContactDetails(
        userInfoData?.email,
        userInfoData?.phone,
        sessionId
      );
    }
  }, [sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [accName, setAccName] = useState<number>(0);

  const handleAccoradingClick = (item: any) => {
    setAccName(item?.id);
  };

  const handleDataFromChild = (data: any) => {

    if (width === "0px") {
      setMenuBtn(!menuBtn);
      setWidth("300px");
    }

    setAccName(data.name);
    // You can now use the data received from the child in the parent component's state or perform other actions with it.
  };

  const handleRedirectContact = () => {
    if (currentBot?.id) {
      const subUrl = `/contacts/list`;
      const url = formatString(subUrl, [currentBot?.bot_id]);
      navigate(url);
    } else {
      showAlert("Please select a bot first", "error");
    }
  };

  return (
    <Fragment>
      <UsersDetailWrapper width={width} style={{ marginRight: "16px" }}>
        <div className="user-deatil-only">
          {/* <UserDetailHeader /> */}
          <Box
            sx={{
              // border: "1px solid",
              height: "100%",
              // pl: "px",
              pr: "8px",
              pt: "16px",
              backgroundColor: "#EBEFF3",
            }}
          >
            <div className="UserDetailContentWrap" style={{ padding: "6px" }}>
              <LiveChatAccordian
                disableGutters
                elevation={0}
                className="main-accordian"
                onClick={() => handleAccoradingClick(UserDetailRightMenu?.[0])}
                expanded={accName === 0}
              >
                <AccordionSummary
                  expandIcon={
                    <AppMaterialIcons
                      style={{ fontSize: "16px" }}
                      iconName="ExpandMore"
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderRadius: accName === 0 ? "4px 4px 0px 0px" : "4px",
                  }}
                >
                  <div className="title-wrapper">
                    <Box
                      display="flex"
                      sx={{ gap: "8px" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <AppMaterialIcons
                        iconName="Contacts"
                        style={{ color: "rgb(44, 92, 197)" }}
                      />
                      <Typography className="accordian-title">
                        Contact Details
                      </Typography>
                    </Box>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <UserInfo />
                </AccordionDetails>
              </LiveChatAccordian>
              
              <ProtectedAppUiElement moduleId="can_add_notes">          
              <LiveChatAccordian
                disableGutters
                elevation={0}
                className="main-accordian"
                onClick={() => handleAccoradingClick(UserDetailRightMenu?.[1])}
                expanded={accName === 1}
              >
                <AccordionSummary
                  expandIcon={
                    <AppMaterialIcons
                      style={{ fontSize: "16px" }}
                      iconName="ExpandMore"
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderRadius: accName === 1 ? "8px 8px 0px 0px" : "4px",
                  }}
                >
                  <div className="title-wrapper">
                    <Box
                      display="flex"
                      sx={{ gap: "8px" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <AppMaterialIcons
                        iconName="PostAddOutlined"
                        style={{ color: "rgb(44, 92, 197)" }}
                      />
                      <Typography className="accordian-title">
                        Add Notes{" "}
                        <span
                          style={{
                            fontSize: "10px",
                            color: theme.palette.default.darkGrey,
                          }}
                        >
                          {" "}
                          (coming soon)
                        </span>
                      </Typography>
                    </Box>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <UserChatAddNotes />
                </AccordionDetails>
              </LiveChatAccordian>
              </ProtectedAppUiElement>

              <LiveChatAccordian
                disableGutters
                elevation={0}
                className="main-accordian"
                onClick={() => handleAccoradingClick(UserDetailRightMenu?.[2])}
                expanded={accName === 2}
              >
                <AccordionSummary
                  expandIcon={
                    <AppMaterialIcons
                      style={{ fontSize: "16px" }}
                      iconName="ExpandMore"
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    borderRadius: accName === 2 ? "4px 4px 0px 0px" : "4px",
                  }}
                >
                  <div className="title-wrapper">
                    <Box
                      display="flex"
                      sx={{ gap: "8px" }}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <AppMaterialIcons
                        iconName="ChatOutlined"
                        style={{ color: "rgb(44, 92, 197)" }}
                      />
                      <Typography className="accordian-title">
                        Previous Conversation{" "}
                      </Typography>
                    </Box>
                  </div>
                </AccordionSummary>

                <AccordionDetails style={{margin:0, padding:8}}>
                  <UserPreviousNotes />
                </AccordionDetails>
              </LiveChatAccordian>
            </div>
          </Box>
        </div>
      </UsersDetailWrapper>

      <UserDetailMenu
        toggleMenu={toggleMenu}
        menuBtn={menuBtn}
        onDataSend={handleDataFromChild}
        accName={accName}
        setAccName={setAccName}
        setMenuBtn={setMenuBtn}
      />
    </Fragment>
  );
};

export default UsersDetail;
