import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import AppButton from "../../AppComponents/AppButton";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import { useAppDispatch, useAppSelector } from "../../Store";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../Store/Slices/MarketingList/marketingList.selectors";
import {
  MarketingListFormStep1Schema,
  MarketingListFormStep2Schema,
} from "./Constants";
import { UploadMarketingWrapper } from "./UploadMarketingDataCSS";
import RenderFormStep1 from "./components/RenderFormStep1";
import RenderFormStep2 from "./components/RenderFormStep2";
import RenderFormStep3 from "./components/RenderFormStep3";
import { MarketingListNetworkService } from "../../Store/Slices/MarketingList/MarketingListNetworkService";
import { LoaderComponentProps, withLoader } from "../Loader";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../HOC/HocBackdropLoader";
import { removeEmptyKeysObj } from "../../Components/Broadcast/WhatsApp/Utils/utils";
import {
  HocProgressBarLoader,
  HocProgressBarLoaderProps,
} from "../../HOC/HocProgressBarLoader";

interface Props extends HocBackdropLoaderProps {
  toggle: any;
  isShowing: any;
  onChangeCallbackFn?: (value: any) => void;
  type: any;
}

const UploadMarketingData: React.FC<Props> = ({
  toggle,
  isShowing,
  type,
  onChangeCallbackFn,
  setLoading,
}) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const { createMarketingListData, marketingListDeletedData }: any = useAppSelector(marketingListStore);
  const [formikProps, setFormikProps] = useState<any>(null);
  const localValues: any = formikProps?.values;
  useEffect(() => {
  }, [localValues]);

  const [formStep, setFormStep] = useState<number>(1);

  const [userInput, setUserInput] = useState<any>({
    name: "",
    list_source_name: 0,
    list_type: 0,
    upload_file: null,
    import_action: 0,
    skip_duplicates_enabled: false,
    skip_duplicates_value: "",
    whatsapp_subscription: "yes",
    sms_subscription: "yes",
    email_subscription: "yes",
    sales_owner: 0,
    lifecycle_stage: 0,
    mapping: null,
  });

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (formStep === 1 && formikProps?.values?.name) {
      let temp = {
        name: formikProps?.values?.name.trim() || "",
        list_source_name: formikProps?.values?.list_source_name,
        type: formikProps?.values?.list_type.toLocaleLowerCase(),
      };
      dispatch(marketingListActions.createMarketingList(temp))
        .then((res: any) => {
          if (res?.payload?.data.status === true) {
            setFormStep(formStep + 1);
          } else {
            showAlert(res?.payload?.data.error || "Something went wrong", "error");
          }
        })
        .catch((err: any) => {
        })
        .finally(() => {
          SubmitProps.setSubmitting(false);
        });
      return;
    }

    if (formStep === 2) {
      setFormStep(formStep + 1);
      SubmitProps.setSubmitting(false);
      return;
    }
    if (formStep === 3) {
      setLoading(true);
      let payload: any = payloadObject(formikProps?.values);
      payload["type"] = createMarketingListData?.type;
      MarketingListNetworkService.updateMarketingListData(
        payload,
        createMarketingListData?.id
      )
        .then((res: any) => {
          onChangeCallbackFn && onChangeCallbackFn(createMarketingListData?.id);
          toggle();
        })
        .catch((err: any) => {
          showAlert(err?.response?.data.error || "Something went wrong", "error");
          SubmitProps.resetForm();
        })
        .finally(() => {
          setLoading(false);
          SubmitProps.setSubmitting(false);
        });
      return;
    }
  };

  const payloadObject = (values: any) => {
    let payload = {
      // list_id: createMarketingListData?.id,

      // skip_duplicates_match: values?.skip_duplicates_enabled
      //   ? values?.skip_duplicates_value
      //   : "none",
      // csv_sample_hash: values?.upload_file?.csv_sample_hash,
      //   file_id: values?.upload_file?.file_id,
      //   data: values?.upload_file?.data,

      skip_duplicates_enabled: values?.skip_duplicates_enabled,
      skip_duplicates_value: values?.skip_duplicates_value?.value,

      lifecycle_stage: values?.lifecycle_stage,

      import_option: values?.import_action,
      whatsapp_subscription: values?.whatsapp_subscription?.value,
      sms_subscription: values?.sms_subscription?.value,
      email_subscription: values?.email_subscription?.value,
      sale_owner: values?.sales_owner,
      file_name: values?.upload_file?.file_name,
      mapping: removeEmptyKeysObj(values?.upload_file?.mapping),
    };
    return payload;
  };

  const handleBackClick = () => {
    if (formStep === 1) {
      toggle();
      setFormStep(1);
      return;
    }

    if (formStep === 2) {
      dispatch(
        marketingListActions.deleteMarketingListById(createMarketingListData?.id)
      ).finally(() => {
        setTimeout(() => {
          setLoading(false);
          toggle();
          setFormStep(1);
        }, 1000);
      });
      return;
    }
    if (formStep === 3) {
      setFormStep(formStep - 1);
      return;
    }
  };

  return (
    <UploadMarketingWrapper>
      <Box className="form-heading">
        <Box>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {formStep === 3 ? "Map Columns To CRM Field" : "Import Records"}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => toggle()}>
            <CloseIcon fontSize="small" fontWeight="600" />
          </IconButton>
        </Box>
      </Box>

      <Divider />

      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
          setUserInput(values);
        }}
        validateOnChange={formStep === 3 ? false : true}
        validationSchema={
          (formStep === 1 && MarketingListFormStep1Schema) ||
          (formStep === 2 && MarketingListFormStep2Schema) ||
          null
        }
      >
        <AppFormObserver setFormikProps={setFormikProps} />
        {formStep === 1 && (
          <RenderFormStep1 setFormStep={setFormStep} formStep={formStep} type={type} />
        )}
        {formStep === 2 && (
          <RenderFormStep2 setFormStep={setFormStep} type={type} formStep={formStep} />
        )}
        {formStep === 3 && (
          <RenderFormStep3 setFormStep={setFormStep} formStep={formStep} />
        )}

        <Box className="footer" sx={{}}>
          <Divider />
          <Box display="flex" sx={{}} className="footer-btns">
            <AppButton onClick={() => handleBackClick()} variant="grey">
              {formStep === 1 || formStep === 2 ? "Cancel" : "Back"}
            </AppButton>
            {formStep === 3 ? (
              <AppSubmitButton title="Add List" />
            ) : (
              <AppSubmitButton title="continue" />
            )}
          </Box>
        </Box>
      </AppForm>
    </UploadMarketingWrapper>
  );
};

export default HocBackdropLoader(UploadMarketingData);
