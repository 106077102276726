import { Drawer } from "@mui/material";
import React from "react";

interface Props {
  open: boolean;
  onClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
  anchor?: "bottom" | "left" | "right" | "top" | undefined;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppDrawer: React.FC<Props> = ({
  open,
  onClose = () => undefined,
  anchor = "right",
  style,
  children,
  ...otherProps
}) => {
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      hideBackdrop={false}
      style={style}
      {...otherProps}
    >
      {children}
    </Drawer>
  );
};

export default AppDrawer;
