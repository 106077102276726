import type react from "react";
import { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";
import * as Yup from "yup";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { AppForm, AppSubmitButton } from "../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../AppComponents/Forms/AppFormReactSelect";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { FormAction } from "../../../../Store/Slices/FormBuilder/formbuilder.actions";
import { contactsDropDownList, formBuilderStore, getDroppedFieldsIds } from "../../../../Store/Slices/FormBuilder/formbuilder.selectors";
import { addFieldToRows, deleteDroppedFieldsIds, deletePropertyFromDefaultObject, editField, editingFieldObject, removeFieldFromRow, removeOptionsToShow, saveDroppedFieldsIds } from "../../../../Store/Slices/FormBuilder/formbuilder.slice";
import { ReactComponent as DeleteIcon } from '../../../../assets/images/formBuilderDeleteIcon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/formBuilderEditFieldIcon.svg';
import FieldLabel from "../../Components/FieldLabel";

function getStyle(item: any, column: any, isOver: boolean): react.CSSProperties {
    const baseBorder = isOver ? `2px dashed #5B73E8` : "1.2px dashed rgba(0,0,0,0.2)";
    return {
        borderTop: baseBorder,
        borderRight: baseBorder,
        borderBottom: baseBorder,
        borderLeft: column > 0 ? "none" : baseBorder,
        maxHeight: "10rem",
        minHeight: "4rem",
        height: "auto",
        color: "white",
        textAlign: "center",
        fontSize: "1rem",
        // width: `${item?.width || 99}%`,
        width: "100%",
        background: isOver ? "#F694FF" : "#FFF",
        gap: "5px"
    };
}
export interface DroppableProps {
    item?: any;
    row?: any;
    column?: any;
}

export const DroppableRow: react.FC<DroppableProps> = ({ item, row, column }): JSX.Element => {
    const [hasDropped, setHasDropped] = useState(false);
    const dispatch = useAppDispatch();
    const { isShowing, toggle } = useModal();
    const formFieldsData: any = useAppSelector(formBuilderStore);
    const droppedIds = useAppSelector(getDroppedFieldsIds)
    const contactsDropDownListOptions = useAppSelector(contactsDropDownList);
    useEffect(() => {
        formFieldsData?.flat()?.map((item: any) => {
            dispatch(saveDroppedFieldsIds(item?.id))
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (item?.is_dependant) {
            const payload = { parentId: item.dependant_field_id };
            dispatch(FormAction.getAddContactsDropDown(payload))
        }
    }, [item])// eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (hasDropped) {
            if (item?.is_dependant && !droppedIds?.includes(item?.dependant_field_id) && !item.hasOwnProperty('dependant_field_option_id')) {
                dispatch(saveDroppedFieldsIds(item?.id))
                dispatch(addFieldToRows({ row, column, newFormField: item }))
                toggle();
            }
        }
    }, [hasDropped]) // eslint-disable-line react-hooks/exhaustive-deps
    const [{ isOver }, drop] = useDrop(
        () => ({
            accept: "BOX",
            drop(item: any, monitor: any) {
                setHasDropped(true);
                const newFormField: any = {
                    id: item.id,
                    label: item.label,
                    name: item.name,
                    type: item.type,
                    default_value: item.default_value || "",
                    required: false,
                    visible: true,
                    regex_pattern: "",
                    placeholder: item?.placeholder || "",
                    help_text: "",
                    custom: item?.custom,
                    is_dependant: item?.is_dependant,
                    dependant_field_id: item?.dependant_field_id,
                    row: row,
                    column: column
                };
                dispatch(saveDroppedFieldsIds(item?.id))
                dispatch(addFieldToRows({ row, column, newFormField }))
            },
            collect: (monitor: any) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({ shallow: true })
            }),
        }),
        [setHasDropped]
    );

    //useEffect for checking if item is already dropped if yes then set hasDropped to true
    useEffect(() => {
        if (item?.name)
            setHasDropped(true)
    }, [item]);
    return (
        <div ref={drop} style={getStyle(item, column, isOver)} aria-colindex={column + 1} aria-rowindex={row + 1}>
            <br />
            {hasDropped && item?.name &&
                (<span style={{ color: theme.palette.default.lighterFilterGrey }}>
                    <Container>
                        <div className="actions-container">
                            <div className="label-container">
                                <p className="label">{item?.label}</p>
                                {item?.required ? <p style={{ color: theme.palette.default.red }}>*</p> : null}
                            </div>
                            <div className="actions-buttons">
                                <EditIcon onClick={() => {
                                    dispatch(editField(true))
                                    dispatch(editingFieldObject(item))
                                }} />
                                <DeleteIcon onClick={() => {
                                    dispatch(removeFieldFromRow({ width: item?.width, row, column }));
                                    dispatch(deletePropertyFromDefaultObject(item?.name))
                                    dispatch(removeOptionsToShow(item?.name))
                                    dispatch(editField(false))
                                    dispatch(deleteDroppedFieldsIds(item?.id))
                                    setHasDropped(false);
                                }} />
                            </div>
                        </div>
                        <Field>{item?.placeholder}</Field>
                    </Container>
                </span>
                )}
            <AppModel
                isShowing={isShowing}
                onClose={toggle}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "500px",
                    },
                }}>
                <ModalContainer>
                    <div className="header">
                        <div className="header-left">
                            <div className="text">
                                <h4>Please Select Parent Field</h4>
                            </div>
                        </div>
                        <div className="header-right">
                            <span onClick={() => toggle(false)}>
                                <AppMaterialIcons iconName="close" />
                            </span>
                        </div>
                    </div>
                    <AppForm
                        initialValues={{ "dependant_field_id": "" }}
                        validationSchema={
                            Yup.object({
                                dependant_field_id: Yup.object()
                                    .required(`${contactsDropDownListOptions?.label} is Required`)
                            })
                        }
                        onSubmit={async (values: any, submitProps: any) => {
                            const payload = {
                                dependant_field_id: item?.dependant_field_id,
                                dependant_field_option_id: values.dependant_field_id?.value
                            }
                            let newFormField = {
                                ...item,
                                ...payload,
                            }
                            dispatch(addFieldToRows({ row, column, newFormField }))
                            toggle(false);
                            submitProps.setSubmitting(false);
                        }}
                    >
                        <div className="content">
                            <FieldLabel label={contactsDropDownListOptions?.label || 'Dependant Field'} />
                            <AppReactSelectField
                                name={"dependant_field_id"}
                                options={contactsDropDownListOptions?.options || []}
                                placeholder={`Select ${contactsDropDownListOptions?.label || 'Select Dependant Field'}`}
                                displayKey="label"
                                valueKey="value"
                                isClearable={true}
                                hideSelectedOptions={true}
                            />
                        </div>
                        <div className="footer">
                            <AppButton
                                variant="grey"
                                onClick={() => {
                                    toggle(false);
                                }}
                            >
                                Cancel
                            </AppButton>
                            <AppSubmitButton
                                variant={"primarydark"}
                                title={"Save"}
                            />
                        </div>
                    </AppForm>
                </ModalContainer>
            </AppModel>
        </div >
    );
};

const Container = styled.div`
&&{
    display:flex;
    min-width: 100%;
    margin:10px 0;
    padding: 10px 15px;
    flex-direction:column;
    .actions-container{
        padding-bottom:10px;
        display:flex;
        justify-content:space-between;
    }
    .actions-buttons{
        display:flex;
        align-items:center;
        gap:8px;
        cursor:pointer;
    }
    :hover{
        display:block;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(126, 131, 146, 0.3);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
    }
    .label-container{
        display:flex;
        font-size:14px;
        gap:10px;
    }
    .label{
        color:${theme.palette.default.newFontColor} !important;
    }
}`
const Field = styled.p`
&&{
    display: grid;
    width: 100%;
    height: 40px;
    font-size:14px;
    border: 1px solid rgba(126, 131, 146, 0.3);
    align-items:center;
    padding:0px 10px;
    border-radius: 5px;
    cursor: pointer;
    color:${theme.palette.default.darkGrey};
    text-align:left;
}`

const ModalContainer = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    padding:0 0.8rem;
    .header-left {
      display: flex;
      gap: 0.875rem;
      }
      .text h4 {
        font-size: 1.1rem;
        font-weight: 500;
      }
      .text p {
        color: ${theme.palette.default.darkGrey};
      }
    }
    .header-right {
      .MuiSvgIcon-root {
        color: ${theme.palette.default.darkGrey};
      }
      cursor: pointer;
    }
  }
  .content{
    margin:10px 0;
    padding: 10px 15px; 
}
  .footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    padding: 0.875rem;

    .change-template-name {
      display: flex;
      justify-content: space-between;
      align-items: start;

      p.change-name {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
`;