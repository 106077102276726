import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { useAppDispatch, useAppSelector } from "../../Store";
import WhatsappWelcome from "../../assets/images/WhatsappWelcom.png";
import Assign from "../../assets/images/assign.svg";
import {
  Container,
  ContentWrapper,
  IconWrapper,
  InfoText,
  ListWrapper,
} from "./Welcome";
import { isWhatsappEnabled } from "../../Store/Slices/Whatsapp/whatsapp.slice";
import { useEffect } from "react";
import { whatsappEnabled } from "../../Store/Slices/Whatsapp/whatsapp.selectors";
import { AppConfig } from "../../Config/app-config";
import { Box, Typography } from "@mui/material";

function WelcomeWhatsappBroadcast() {
  const instruction = [
    `Click on the "Import" button and click on it.`,
    `Choose the data source you want to import from. This could be a CSV file or Excel spreadsheet`,
    `Upload your file and map the columns from your data source to the corresponding fields in your market list (e.g. name,email, location)`,
    `Review the mapping to ensure accuracy, then proceed to import the data`,
    `Once the import is complete, your contact will be added to the marketing list, ready for your targeted campaigns`,
  ];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const whatsappEnable = useAppSelector(whatsappEnabled);
  const userData: any = AppConfig.getUserData("loggedInUserData")?.data?.user;

  useEffect(() => {
    if (whatsappEnable) {
      navigate("/broadcast/whatsapp/create-template");
    }
  }, [isWhatsappEnabled]);
  const handleJourney = () => {
    dispatch(isWhatsappEnabled(true));
  };
  return (
    <Container>
      <ContentWrapper>
        <ListWrapper>
          <img src={WhatsappWelcome} alt="" />
        </ListWrapper>
        <InfoText>
          <h1>
            Hi {userData?.first_name},<br />
            Welcome to Whatsapp broadcasting
          </h1>
          <Box sx={{ mb: "1rem" }}>
            <Typography fontSize={"15px"}>
              This powerful feature allows you to effortlessly send WhatsApp
              messages to multiple recipients at once, simplifying your
              communication process. With this tool, you can easily share
              updates, announcements, and important information with your
              contacts.
            </Typography>
          </Box>
          <Box sx={{ mb: "8px" }}>
            <Typography fontSize={"15px"} variant="h6">
              Key Benifits:
            </Typography>
          </Box>
          <Box>
            <ol>
              <li>
                <Typography fontSize={"14px"}>
                  Efficiency: Send important information to multiple contacts in
                  one go.
                </Typography>
              </li>
              <li>
                <Typography fontSize={"14px"}>
                  Personalization: Tailor messages with names or specific
                  details for a more personalized touch.
                </Typography>
              </li>
              <li>
                <Typography fontSize={"14px"}>
                  Privacy: Each recipient receives the message individually,
                  ensuring confidentiality.
                </Typography>
              </li>
            </ol>
          </Box>

          <Box>
            <Typography fontSize={"13px"}>
              <span style={{ fontWeight: 600 }}>Note:</span> Keep in mind that
              this feature works within WhatsApp's guidelines, ensuring a
              positive and spam-free communication experience. <br />
              Get started and elevate your communication game with WhatsApp
              Broadcast today!
            </Typography>
          </Box>

          <IconWrapper>
            <img src={Assign} alt="" />
          </IconWrapper>
          <AppButton className="button" onClick={handleJourney}>
            Start Journey
          </AppButton>
        </InfoText>
      </ContentWrapper>
    </Container>
  );
}

export default WelcomeWhatsappBroadcast;
