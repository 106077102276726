import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AppConfig } from "../../../../Config/app-config";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleCeleryService } from "../../../../Services/Apis/ChatbotConsoleCeleryService";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import GoogleSheetConfig from "./GoogleSheetConfig";
import GoogleSheetsIntegration from "./GoogleSheetsIntegration";
const BOT_ID_KEY = 'currentBotId'

const GoogleIndex = () => {
  const { showAlert } = useNotification();
  const bot_id = AppConfig.getUserData(BOT_ID_KEY);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [googleSheetLink, setGoogleSheetLink] = useState<any>([]);

  let navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);
  const urlParams = new URLSearchParams(window.location.search);
  const authorizationCode = urlParams.get('code');
  const { data: authData } = useSelector(useAuthStore);
  const loggedData = AppConfig.getLoggedInData();

  if (authorizationCode) {
    // const bot_id = AppConfig.getUserData(BOT_ID_KEY);
    const data = {
      'bot_id': bot_id,
      'code': authorizationCode
    }
    ChatbotConsoleService.saveGoogleSheetConfiguration(data).then((res: any) => {
      const googleRedirect:any = navigate(`/bot/${bot_id}/integrations/google-sheets`);
      if(googleRedirect) {
        showAlert('Connected from Google successfully!', 'success');
        setIsConnected(true)
      }
    })
  }


  const handleDisconnectButton = () => {
    let result = JSON.parse(googleSheetLink.google_response)
    const data = {
      'bot_id': bot_id,
      'refresh_token': result.refresh_token
    }
    ChatbotConsoleService.logoutGoogleConfiguration(data).then((save_result: any) => {
      showAlert('Disconnected from Google Account successfully!', 'success');
      setIsConnected(false)
    })  
  }

  const handleConnectSheet = () => {
    if(googleSheetLink) {
      // window.location.href = googleSheetLink.createdlinkID['link']
      const data = {
        "task_name": "conv.push_leads_to_google_sheet",
        "cron_string": "*/10 * * * *",
        "core_business_id":authData?.data?.business.id,
        "args": [],
        "kwargs": {},
        "ptask_name": "googlesheet_data_push",
        "data_info": {
          "botid": bot_id,
          "geta_host": loggedData?.data?.geta_host
        }
      }
      ChatbotConsoleCeleryService.chatbotConsoleCeleryTask(data).then((save_result: any) => {
        showAlert('Task Started sucessfully', 'success');
        setIsConnected(false)
        if(data) {
          window.open(googleSheetLink.createdlinkID['link'],  "_blank")
        }
      }) 
    }
  }

  const handleConnectButton = (bot_id: string) => {
    AppConfig.setUserData(BOT_ID_KEY, bot_id);
    ChatbotConsoleService.getGoogleSheetOauthRedirectUrl().then((res: any) => {
      window.location.href = res.data.url
    })
  };


  useEffect(() => {
    const bot_id = AppConfig.getUserData(BOT_ID_KEY);

    ChatbotConsoleService.getcreatedGoogleSheet(bot_id).then((res: any) => {
      // result = JSON.parse(res?.data?.google_response)
      setGoogleSheetLink(res.data)
      if(res.data != '') {
        setIsConnected(true)
      } else {
        setIsConnected(false)
      }
    });
  }, []);

  return (
    <GoogleWrap>
      {isConnected ? (
        <GoogleSheetConfig handleConnectSheet={handleConnectSheet} handleDisconnectButton={handleDisconnectButton} googleSheetLink={googleSheetLink}/>
      ) : (
        <GoogleSheetsIntegration handleConnectButton={handleConnectButton} authorizationCode={authorizationCode}/>
      )}
    </GoogleWrap>
  );
};

export default GoogleIndex;

const GoogleWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
  }
`;