import { useState, useEffect } from "react";
import { EditorState, Modifier } from "draft-js";
import {
  getSelectionText,
  getEntityRange,
  getSelectionEntity,
} from "draftjs-utils";
import linkifyIt from "linkify-it";
import IconButton from "@mui/material/IconButton";
// import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import UIDialog from "../../../dialog";
import { stopPropagation } from "./../../utils/common";
import Icon from "./../../../icon";
import Input from "../../../input";
import Button from "../../../button";
import NodeType from "../../../node-type";

const linkify = linkifyIt();
const linkifyLink = (params: { target: string }) => {
  const links = linkify.match(params.target);
  return {
    ...params,
    target: (links && links[0] && links[0].url) || params.target,
  };
};

const Link = (props: any) => {
  const { config, editorState } = props;
  const { options, link } = config;
  const [currentEntity, setCurrentEntity] = useState(
    editorState ? getSelectionEntity(editorState) : undefined
  );
  const [showModal, setShowModal] = useState(false);
  const [linkTarget, setLinkTarget] = useState("");
  const [linkTargetOption, setLinkTargetOption] = useState("_blank");
  const [linkTitle, setLinkTitle] = useState("");

  useEffect(() => {
    setCurrentEntity(getSelectionEntity(editorState));
  }, [editorState]);

  const forceExpandAndShowModal = () => {
    // @ts-ignore
    const { link, selectionText } = getCurrentValues();
    setShowModal(true);
    setLinkTarget(link && link.target);
    setLinkTargetOption((link && link.targetOption) || linkTargetOption);
    setLinkTitle((link && link.title) || selectionText);
  };

  const getCurrentValues = () => {
    const { editorState } = props;
    const contentState = editorState.getCurrentContent();
    const currentValues: any = {};
    if (
      currentEntity &&
      contentState.getEntity(currentEntity).get("type") === "LINK"
    ) {
      currentValues.link = {};
      const entityRange =
        currentEntity && getEntityRange(editorState, currentEntity);
      currentValues.link.target =
        currentEntity && contentState.getEntity(currentEntity).get("data").url;
      currentValues.link.targetOption =
        currentEntity &&
        contentState.getEntity(currentEntity).get("data").targetOption;
      currentValues.link.title = entityRange && entityRange.text;
    }
    currentValues.selectionText = getSelectionText(editorState);
    return currentValues;
  };

  const addLink = () => {
    const {
      config: { linkCallback },
    } = props;
    const { editorState, onChange } = props;
    const linkifyCallback = linkCallback || linkifyLink;
    const linkified = linkifyCallback({
      title: linkTitle,
      target: linkTarget,
      targetOption: linkTargetOption,
    });

    let selection = editorState.getSelection();
    if (currentEntity) {
      const entityRange = getEntityRange(editorState, currentEntity);
      const isBackward = selection.getIsBackward();
      if (isBackward) {
        selection = selection.merge({
          anchorOffset: entityRange.end,
          focusOffset: entityRange.start,
        });
      } else {
        selection = selection.merge({
          anchorOffset: entityRange.start,
          focusOffset: entityRange.end,
        });
      }
    }
    const entityKey = editorState
      .getCurrentContent()
      .createEntity("LINK", "MUTABLE", {
        url: linkified.target,
        targetOption: linkified.targetOption,
      })
      .getLastCreatedEntityKey();

    let contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      selection,
      `${linkified.title}`,
      editorState.getCurrentInlineStyle(),
      entityKey
    );
    let newEditorState = EditorState.push(
      editorState,
      contentState,
      "insert-characters"
    );

    // insert a blank space after link
    selection = newEditorState.getSelection().merge({
      anchorOffset: selection.get("anchorOffset") + linkTitle.length,
      focusOffset: selection.get("anchorOffset") + linkTitle.length,
    });
    newEditorState = EditorState.acceptSelection(newEditorState, selection);
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      selection,
      " ",
      newEditorState.getCurrentInlineStyle(),
      undefined
    );
    onChange(
      EditorState.push(newEditorState, contentState, "insert-characters")
    );

    setTimeout(() => {
      setShowModal(false);
    });
  };

  const updateValue = (event: any) => {
    if (event.target.name === "linkTitle") {
      setLinkTitle(event.target.value);
    } else if (event.target.name === "linkTarget") {
      setLinkTarget(event.target.value);
    }
  };

  const updateTargetOption = (event: { target: { checked: any } }) => {
    // @ts-ignore
    setLinkTargetOption(event?.target?.checked ? "_blank" : "_self");
  };

  const handleModalToggle = () => {
    setShowModal(false);
  };

  const renderAddLinkModal = () => {
    return (
      <UIDialog
        className="ignore-onClickOutside"
        open={showModal}
        onClose={handleModalToggle}
      >
        <UIDialog.Header component="div" sx={{ padding: 0 }}>
          <Stack direction="row" justifyContent="space-between">
            <NodeType text="Insert Link " icon="link" color="#9AC7FD" />
            <Box
              sx={{
                px: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton onClick={handleModalToggle}>
                <Icon icon="close" size={16} />
              </IconButton>
            </Box>
          </Stack>
        </UIDialog.Header>
        <UIDialog.Body sx={{ px: "16px", pb: "8px", minWidth: "420px" }}>
          <Box onClick={stopPropagation}>
            <Box sx={{ mb: "16px" }}>
              <Typography
                component="div"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#7E8392",
                }}
              >
                Text*
                <Input
                  placeholder="link text"
                  id="link-text-input"
                  size="small"
                  name="linkTitle"
                  onChange={updateValue}
                  onBlur={updateValue}
                  value={linkTitle}
                />
              </Typography>
            </Box>
            <Box sx={{ mb: "16px" }}>
              <Typography
                component="div"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#7E8392",
                }}
              >
                Insert Link*
                <Input
                  placeholder="link"
                  id="link-target-input"
                  size="small"
                  name="linkTarget"
                  onChange={updateValue}
                  onBlur={updateValue}
                  value={linkTarget}
                />
              </Typography>
            </Box>

            <FormControlLabel
              label="Open link in new window"
              control={
                <Checkbox
                  checked={linkTargetOption === "_blank"}
                  onChange={updateTargetOption}
                  value="_blank"
                />
              }
            />
          </Box>
        </UIDialog.Body>
        <UIDialog.Footer
          sx={{ justifyContent: "space-between", px: "16px", pb: "8px" }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleModalToggle}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={addLink}
            disabled={!linkTarget || !linkTitle}
            startIcon={<Icon icon="save" size={16} />}
          >
            Save
          </Button>
        </UIDialog.Footer>
      </UIDialog>
    );
  };

  return (
    <>
      {options.indexOf("link") >= 0 && (
        <Box sx={{ position: "relative" }}>
          <IconButton
            onClick={forceExpandAndShowModal}
            title={link.title}
            sx={{
              p: "2px !important",
            }}
            size="small"
          >
            <Icon icon={link.icon} size={16} color="#7E8392" />
          </IconButton>
        </Box>
      )}
      {showModal ? renderAddLinkModal() : undefined}
    </>
  );
};

export default Link;
