import {
  Box,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import React from "react";
import { HeatMapGrid } from "react-grid-heatmap";
import styled from "styled-components";
import { SkeletonGraph } from "./SkeletonLoader.tsx/SkeletonCard";
import { ErrorComponent } from "../Broadcast/SMS/SenderID/ListingStyles";
import NoData from "../../assets/images/noDatafound.svg";
import { theme } from "../../Customization/Theme";

const windowSize = window.innerWidth;

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f6f8",
    color: "rgb(91, 115, 232,1)",
    fontSize: "12px",
    family: "Poppins",
    weight: "400",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#f5f6f8",
  },
}));

interface Props {
  graphDataObj?: any;
  datalength?: any;
  loading?: boolean;
}

const CoversationalTraffic: React.FC<Props> = ({
  graphDataObj,
  datalength,
  loading,
}) => {
  if (!graphDataObj?.data) return null;
  return (
    <StyledHeatMapWrap>
      {loading ? (
        <SkeletonGraph />
      ) : datalength === 0 ? (
        <ErrorComponent style={{ height: "125px" }}>
          <Box>
            <img src={NoData} width="150" alt="" />
          </Box>
          <Box className="err-content">
            <Typography variant="h6" fontSize="20px">
              No Records Found.
            </Typography>
          </Box>
        </ErrorComponent>
      ) : (
        <HeatMapGrid
          data={graphDataObj?.data}
          xLabels={graphDataObj?.xLabels}
          yLabels={graphDataObj?.yLabels}
          xLabelsPos="bottom"
          yLabelsPos="left"
          style={{ width: "100%" }}
          // Reder cell with tooltip
          cellRender={(x: any, y: any, value: any) => (
            <LightTooltip
              title={
                <StyledTooltipTitle>
                  {graphDataObj?.yLabels[x]}
                  <p>{`${graphDataObj?.xLabels[y]}: ${value}`}</p>
                </StyledTooltipTitle>
              }
              placement="top"
              arrow
            >
              <StyledSingleBox>{value}</StyledSingleBox>
            </LightTooltip>
          )}
          xLabelsStyle={(index: any) => ({
            color: index % 2 ? "transparent" : "black",
            fontSize: "14px",
            weight: "400",
            marginTop: "7px",
          })}
          yLabelsStyle={() => ({
            color: "black",
            fontSize: "14px",
            weight: "400",
            marginRight: "8px",
            marginBottom: windowSize > 1500 ? "2px" : "4px",
          })}
          cellStyle={(_x: any, _y: any, ratio: any) => ({
            background: `rgb(18, 54, 265, ${ratio})`,
            color: `rgb(0, 0, 0, ${ratio / 1 + 7})`,
            fontSize: "10px",
            weight: "500",
            marginBottom: windowSize > 1500 ? "3px" : "4px",
            marginRight: "3px",
          })}
          cellWidth={windowSize > 1500 ? "3.9rem" : "1.9rem"}
          cellHeight={windowSize > 1500 ? "3.4rem" : "1.9rem"}
        />
      )}
    </StyledHeatMapWrap>
  );
};

export default CoversationalTraffic;

const StyledHeatMapWrap = styled.div`
  && {
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
  }
`;
const StyledSingleBox = styled.div`
  && {
    height: 5rem !important;
    &:hover {
      background-color: #5b73e8 !important;
      color: white !important;
    }
  }
`;
const StyledTooltipTitle = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.default.black};
    gap: 1px;
    font-size: 12px;
    p {
      color: #5b73e8;
      font-size: 14px;
      font-size: 13px;
    }
  }
`;
