import { useContext, useState, useRef, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useOnClickOutside from "use-onclickoutside";
import { AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import IconButton from "@mui/material/IconButton";

// builder
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";
import { getActionConfig } from "./../../flow-builder-v2/utils";

// hooks
import { useAction } from "./../../flow-builder-v2/hooks";

// components
import { NodeType, Editor, MessagePreview, Icon, UIButton, SearchInput } from "./../../ui-components";
import Card from "./../card";
import DisplayNodes from "../../nodes/button/actions/components/display-nodes";

// services
import { FlowBuilderService } from "../../../../Services/Apis/FlowBuilder";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

// utils
import {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
} from "./../../nodes/utils/editor";

interface Flow {
  displayOrder: number;
  name: string;
  nodeCounts: number;
  qid: string;
}

const NodeToNode = (props: any) => {
  const wrapperRef = useRef(null);
  const builderContext = useContext(BuilderContext);
  const nodeCntx = useContext(NodeContext);
  const { setActiveNode, activeNode, language } = builderContext;
  const { updateNode, removeNode, deleteAction, updateAction } = useAction();
  const [showPreview, setPreview] = useState<boolean>(true);
  const currentBot = useSelector(useCurrentBot);
  const botId = { botid: currentBot.bot_id };

  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [flowId, setflowId] = useState<string | null>(null);
  const [flows, setFlows] = useState([]);
  const [hierarchyLevel, setHierarchyLevel] = useState(1);
  const [flowOrder, setFlowOrder] = useState(0);
  const [displayNodes, setShowNodes] = useState<string>("");
  const [selectedFlow, setFlow] = useState<{
    qid: string;
    stage: string;
    nodeId: string;
    displayOrder: number;
  } | null>(null);
  const [error, updateError] = useState<boolean>(false);
  const action = getActionConfig(props.type)

  useOnClickOutside(wrapperRef, (event) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }

    if (!showPreview) {
      if (nodeCntx.id && !showPreview) {
        if(selectedFlow?.qid && selectedFlow?.nodeId) {
          updateNode(nodeCntx.nodeId, null, null, undefined);
        }
      }
      if(selectedFlow?.qid && selectedFlow?.nodeId) {
        setPreview(true);
        updateError(false);
      } else {
        updateError(true);
      }
    }
  });

  const initFlow = (flowList: any) => {
    for (let index = 0; index < flowList.length; index++) {
      const flow = flowList[index];
      if (flow.displayOrder) {
        flow.displayOrder = flow.displayOrder;
      } else {
        flow.displayOrder = 0;
      }
    }
    return flowList.sort((a: Flow, b: Flow) =>
      a.displayOrder < b.displayOrder
        ? -1
        : Number(a.displayOrder > b.displayOrder)
    );
  };

  const togglePreview = () => {
    setPreview(!showPreview);
    setActiveNode(nodeCntx.nodeId || nodeCntx.id);
  };

  const handleCancelEditing = () => {
    if(selectedFlow?.qid && selectedFlow?.nodeId) {
      setActiveNode(null);
    } else {
      deleteAction(nodeCntx, props.actionId);
    }
    togglePreview();
  };

  const handleClick = (id: string, order: number) => {
    setHierarchyLevel(2);
    setFlowOrder(order);
    setflowId(id);
    setShowNodes((prevState: string) => {
      if (prevState !== id) {
        return id;
      } else {
        return prevState;
      }
    });
  };

  const handleBackClick = () => {
    if(!selectedFlow?.nodeId) {
      setflowId(null);
    }
    setHierarchyLevel(1);
  };

  const handleNodeSelect = (qId: string, nodeId: string, order: number) => {
    setFlow({
      qid: qId,
      stage: `${flowOrder}.${order}`,
      nodeId: nodeId,
      displayOrder: order - 1,
    });
  };

  const handleSave = () => {
    updateAction(props, nodeCntx, selectedFlow);
    setPreview(true);
  };

  const handleCancel = () => {
    if(selectedFlow?.qid && selectedFlow?.nodeId) {
      setPreview(true);
    } else {
      deleteAction(nodeCntx, props.actionId);
    }
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    if (flows && flows?.length > 0 && Array.isArray(flows)) {
      const results:any = flows?.filter((flow: any) =>
        flow?.name?.toLowerCase()?.includes(searchTerm)
      );
      setSearchResults(results);
    }
  }, [searchTerm]);

  useEffect(() => {
    if(props.nodeId && props.qid) {
      setPreview(true);
      return;
    }
    setPreview(false);
  }, []);

  useEffect(() => {
    FlowBuilderService.getFlows({}, { appid: JSON.stringify(botId) }).then(
      (response: AxiosResponse<any>) => {
        if (response.data.status === 200) {
          setSearchResults(initFlow(response.data.data));
          setFlows(initFlow(response.data.data));
          // setflowId(connectedFlow?.qid);
        }
      }
    );
  }, []);

  return (
    <Box
      id={props.id}
    >
      <Card
        id={props.actionId}
        isAction
        hideNodeType
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
      >
        {showPreview ? (
          <Box
            sx={{ p: "12px 16px" }}
            onClick={(e) => {
              e.stopPropagation();
              togglePreview();
            }}
          >
             <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Icon icon={action?.icon} size={16} color={"#5B73E8"} />
              <Typography
                sx={{ fontSize: "14px", fontWeight: 500, lineHeight: "21px" }}
                color="#5B73E8"
              >
                {action?.name} {props.stage && props.stage}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box sx={{ p: "12px 16px" }}>
            {displayNodes && hierarchyLevel == 2 ? (
              <>
                <Box
                  sx={{
                    mb: "16px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    sx={{
                      mr: "-10px",
                    }}
                    onClick={handleBackClick}
                  >
                    <Icon icon="chevron-left" color="" size={12} />
                  </IconButton>
                  <Typography
                    fontWeight={500}
                    fontSize={14}
                    lineHeight="21px"
                    color="#7E8392"
                    sx={{ ml: "10px" }}
                  >
                    Back
                  </Typography>
                </Box>
                <Box sx={{ margin: "-16px" }}>
                  <DisplayNodes
                    activeFlowId={displayNodes}
                    flowOrder={flowOrder}
                    onNodeSelect={handleNodeSelect}
                    selectedNode={selectedFlow?.nodeId}
                    connectedFlow={selectedFlow}
                  />
                </Box>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "21px",
                  }}
                >
                  Flow List
                </Typography>
                <SearchInput
                  placeholder="Search"
                  size="small"
                  onChange={handleSearchInputChange}
                />
                <Box
                  sx={{
                    mt: "12px",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {searchResults.length > 0 ? (
                    <>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                          pt: 0,
                          pb: 0,
                        }}
                        component="nav"
                      >
                        {searchResults.map((flow: any, index: number) => {
                          const openChild = false;
                          // activeButtonData.value?.qid === flow?.qid;
                          return (
                            <Fragment key={`flow-${index + 1}`}>
                              <ListItem
                                disablePadding
                                sx={{
                                  "&:hover": {
                                    backgroundColor:
                                      language !== "english"
                                        ? openChild
                                          ? "#F5F6F8"
                                          : "transparent"
                                        : "#F5F6F8",
                                  },
                                  border: "1px solid #F5F6F8",
                                  // ...(openChild && { backgroundColor: "#F5F6F8" }),
                                }}
                              >
                                <ListItemButton
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                  onClick={() =>
                                    handleClick(flow.qid, flow.displayOrder)
                                  }
                                  disabled={language !== "english"}
                                >
                                  <Typography
                                    fontWeight={400}
                                    fontSize={14}
                                    lineHeight="21px"
                                    color="#7E8392"
                                  >{`${flow.displayOrder} ${flow?.name}`}</Typography>
                                  {flowId === flow.qid && (
                                    <Box sx={{ marginLeft: "auto" }}>
                                      <Icon
                                        icon="done"
                                        size={14}
                                        color="#2ECC71"
                                      />
                                    </Box>
                                  )}
                                </ListItemButton>
                              </ListItem>
                            </Fragment>
                          );
                        })}
                      </List>
                    </>
                  ) : (
                    <Typography
                      fontWeight={400}
                      fontSize={12}
                      lineHeight="16px"
                      color="#7E8392"
                    >
                      No Flows Available
                    </Typography>
                  )}
                </Box>
                
              </>
            )}
            <Box
              sx={{
                mt: "10px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UIButton
                color="error"
                variant="contained"
                onClick={handleCancel}
              >
                Cancel
              </UIButton>
              <UIButton variant="contained" onClick={handleSave} disabled={!selectedFlow?.qid && !selectedFlow?.nodeId}>
                Save
              </UIButton>
            </Box>
          </Box>
        )}
        {/* <Divider /> */}
      </Card>
    </Box>
  );
};

export default NodeToNode;
