import { createAsyncThunk } from "@reduxjs/toolkit";
import { LifecycleStageService } from "./lifecycleStageService";

export class LifecycleStageActions {
    static getLifecycleStageStructure = createAsyncThunk(
        "LifecyleStageSlice/getLifecycleStageStructure",
        (data: any, { dispatch, rejectWithValue }) => {
            return LifecycleStageService.getLifecycleStageStructure()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static getLifecycleStage = createAsyncThunk(
        "LifecyleStageSlice/getLifecycleStage",
        (data: any, { dispatch, rejectWithValue }) => {
            return LifecycleStageService.getLifecycleStage(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
}