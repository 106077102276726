import styled from "styled-components";
import { theme } from "../../Customization/Theme";

interface StyledProps {
  disabled?: boolean;
}

export const ChatTextEditorWrapper = styled.div<StyledProps>`
  & {
    position: relative;
    // min-height: 171px;
    min-height: 179px;
    // box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16);
    /* margin-top: 5px; */
    // margin: 2px;
    // border: 1px solid red;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 2px 8px #ebeff3;
    form {
      height: 100%;
      .appflowweblivechat_wrapper #editor-toolbar {
        border-top: 1px solid ${theme.palette.default.grey};
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
        height: 40px !important;
      }

      .appflowweblivechat_wrapper {
        position: relative;
        > div > div[role="listbox"] {
          background: red;
          position: absolute;
          z-index: 99999991;
          bottom: 134px;
          left: 50% !important; //do not remove important statements
          transform: translate(-50%, -50%) !important;
          width: calc(100% - 3rem);
          background-color: #fff;
          border-top: 6px solid ${theme.palette.primary.dark};
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
          padding: 0.875rem;
          max-height: 300px;
          min-height: 300px;
          overflow-y: overlay;
        }
      }

      .appflowweblivechat_wrapper > div:last-child {
        min-height: 137px !important;
        background-color: #fff;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .disable-box {
      height: 171px;
      position: absolute;
      z-index: 999999;
      width: 100%;
      left: 0;
      top: 0;
      cursor: not-allowed;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
      display: ${(props: any) => (props?.disabled ? "block" : "none")};
    }
  }
`;

export const StyledEditorHeader = styled.div`
  && {
    width: 100%;
    background-color: ${theme.palette.default.white};
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #cfd7df;
    gap: 1rem;
    .titleBox {
      font-size: 12px;
      height: 100%;
      padding: 4px 1rem;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      align-items: center;
      .tab-text {
        color: #475857;
      }
    }
    .infoBox {
      font-size: 13px;
      height: 100%;
      margin-left: 2rem;
      padding: 4px 1rem;
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .active {
      background-color: ${theme.palette.primary.lightPrimary};
      border-bottom: 1px solid ${theme.palette.primary.dark};
      .tab-text {
        color: #2c5cc5;
        font-weight: 700;
      }
    }
  }
`;
