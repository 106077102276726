import { Paper, Switch } from "@mui/material";
import styled from "styled-components";
import * as MUI from "@mui/material";

export const NotificationWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const NotificationHeader = styled.div`
    /* width: 100%; */
    width: auto;
    // margin-left: -0.95rem;
    // margin-right: -1rem;
    // margin-top: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fff;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .response-header__left {
        h4 {
            font-size: 1rem;
            font-weight: 500;
        }
    }
    .response-header__right {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`


export const NotificationContainer = styled(Paper)`
    width: 98%;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 10px 12px;
    .body_title{
        padding: 19px 26px 0px;
        font-size: 16px;
    }
        .body_contain{
            padding: 5px 35px;
        }
        .body_contain_row{
            margin: 11px 0px;
            display: flex
        }
`
export const CustomSwitch = MUI.styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",

    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 4,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#FFFFFF",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#008000",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 8,
        height: 8,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
    "& .MuiFormControlLabel-root": {
        marginLeft: "-4px",
    },
}));