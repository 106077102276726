import { Box, Typography } from "@mui/material";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import { ApexChatWrapper } from "./Doughnout";
import {
  TextTitle,
  UniqueEmailWrapper,
  WrapEmail
} from "./EmailBroadcastSummaryStyles";

interface Props {
  totalCampaign?: any;
  completedCampaign?: any;
  scheduledCampaign?: any;
  loading?:any;
}

const TotalCampaign: React.FC<Props> = ({
  totalCampaign,
  completedCampaign,
  scheduledCampaign,
  loading
}) => {
 

  return (
    loading ? <AppSkeletonLoader/> : 
    <Box height={"inherit"}>
      
              <WrapEmail>
          <UniqueEmailWrapper>
            <Box display = { "flex" } alignItems = { "center" }>
              <ApexChatWrapper>
                <Box className = "heading__tooltip">
                  <TextTitle>
                    <p className = "main_header">Campaign Stats</p >
                  </TextTitle >
  <AppToolTip
    tooltipProps={{
      title:
        "Aggregated count of both completed and scheduled campaigns.",
      placement: "top",
    }}
    iconButtonProps={{ disableRipple: true }}
    IconComponent={
      <AppMaterialIcons
        iconName="HelpOutline"
        style={{ fontSize: "16px" }}
      />
    }
  />
                </Box >
              </ApexChatWrapper >
            </Box >
                  {
                    (totalCampaign ?? 0) === 0 &&
                    (completedCampaign ?? 0) === 0 &&
                    (scheduledCampaign ?? 0) === 0 ? (
                        <NoRecordsFound
                          imageWidth={"200px"}
                          headerTextSize={"19px"}
                          imageSrc={NoRecordImage}
                          height="calc(100% - 61px)"
                          bgColor="transparent"
                        />
                    ) : (
                         <Box className="body">
    <Box className="content">
      <Box>
        <p className="header">Total Campaign</p>
        <p className="value">{totalCampaign || 0}</p>
      </Box>

      <Box className="campaign_info">
        <Box className="campaign_value-wrap">
          <Typography>Completed</Typography>
          <Typography className="campaign_value">
            {" "}
            {completedCampaign || 0}
          </Typography>
        </Box>
        <div className="dotted_hr" />
        <Box className="campaign_value-wrap">
          <Typography>Schedule</Typography>
          <Typography className="campaign_value">
            {scheduledCampaign || 0}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box> 
                    )
                  }

                  

  
          </UniqueEmailWrapper >
        </WrapEmail >
        
      </Box>
  );
};

export default TotalCampaign;
