// vendors
import { styled, alpha } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";

export const UIButtonGroupStyled = styled(ButtonGroup)`
  box-shadow: none;
  text-transform: none;
  font-size: 14;
  font-weight: 500;
  line-height: 21px;
  border-radius: 4px;
  border-color: ${(props) => props.theme.palette.primary.light};
  flex: 1;
  &:hover {
    border-radius: 16px;
    border-color: ${(props) => props.theme.palette.primary.light};
    box-shadow: none;
  }
  &:active {
    border-radius: 16px;
    box-shadow: none;
    border-color: ${(props) => props.theme.palette.primary.light};
  }
  &:focus {
    border-radius: 16px;
    box-shadow: 0 0 0 0.2rem
      ${(props) => alpha(props.theme.palette.primary.light, 0.4)};
  }
  &:focus-within {
    border-radius: 16px;
    box-shadow: 0 0 0 0.2rem
      ${(props) => alpha(props.theme.palette.primary.light, 0.4)};
  }
  & .MuiButtonBase-root {
    text-transform: none;
  }
`;
