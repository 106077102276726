import { registerActions } from "./buttonRegisterActions";

export const getIcon = (type: string) => {
  let tempType = type === "connect-flow" ? "flow" : type;
  const matchingNode = registerActions.filter(
    (action) => action.type === tempType
  );
  return matchingNode?.[0]?.icon;
};

export const getActionByType = (type: string) => {
  const matchingNode = registerActions.filter(
    (action) => action.type === type
  );
  return matchingNode?.[0];
}