import { Box, Link } from "@mui/material";
import React from "react";

import styled from "styled-components";

import BRANDING_IMAGE from "../../../../../assets/images/getaLogo.svg";
import { ColorUtils } from "../../../../../Utils/ColorUtils";
const BRANDING_TEXT = "Powerd by";
const BRANDING_URL = "https://www.geta.ai";

interface Props {}

const BotFooterScreen: React.FC<Props> = () => {
  return (
    <StyledFooterScreenContainer>
      {BRANDING_TEXT}
      <Link component={"a"} href={BRANDING_URL} target="_blank"
        rel="noreferrer">
        <img src={BRANDING_IMAGE} alt="geta logo" className="brandImage" />
      </Link>
    </StyledFooterScreenContainer>
  );
};

export default React.memo(BotFooterScreen);

const StyledFooterScreenContainer = styled(Box)`
  && {
    width: 100%;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px -1px 1px 0px #e3e1e1;

    border-radius: 0px, 0px, 8px, 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${ColorUtils.hexToRgba("#000000", 0.6)};

    letter-spacing: 0.25px;
    text-transform: capitalize;

    & .brandImage {
      margin-top: 4px;
      margin-left: 6px;
      height: 16px;
      width: auto;
      object-fit: center;
    }
  }
`;
