// vendors
import Stack from "@mui/material/Stack";

interface IModalFooter {
  children: React.ReactNode;
}

const ModalFooter = (props: IModalFooter) => {
  const { children } = props;

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{ py: "8px", px: "16px", borderTop: "1px solid #F5F6F8" }}
    >
      {children}
    </Stack>
  );
};
export default ModalFooter;
