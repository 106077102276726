import { Box, IconButton, Typography, debounce } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppBadge from "../../AppComponents/AppBadge";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import useModal from "../../Hooks/useModel/useModel";
import { useAppDispatch } from "../../Store";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import { exportedContactsList } from "../../Store/Slices/Contacts/contacts.selector";
import { fileDownload } from "../../Utils";
import { ReactComponent as ForwardIcon } from "../../assets/images/forward-arrow.svg";
import { ReactComponent as NoTableData } from "../../assets/images/noDatafound.svg";
import { ReactComponent as SkipIcon } from "../../assets/images/skippedrecord.svg";
import {
  AnsweredFaqList,
  AnsweredTable,
  Center,
  ContactMarketingWrap,
  NewSelect,
  TextWrap,
} from "../ContactMarketingList/ContactMarketingListStyle";
import { TableWrapper } from "../../TableStyle";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
// import CreateListModal from "./commonComponents/marketingListModals/CreateListModal";
const ContactsExportHistory = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { toggle } = useModal();
  const [requestData, setRequestData] = useState({
    search: "",
    offset: 0,
    limit: { label: "50 per page", value: 50 },
  });
  const data: any = useSelector(exportedContactsList);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    // Servie call for getAllContactHistory
    let payload: any = {
      search: requestData.search,
      offset: requestData.offset,
      limit: requestData.limit.value,
    };
    dispatch(ContactsActions.GetExportedContacts(payload));
  }, [requestData]);
  // modal toggle functions
  const handleCreateListClick = useCallback(() => {
    toggle();
  }, [toggle]);
  // const handlClick = () => {
  //   navigate(`/bot/${bot.bot_id}/contact-marketing-detail`);
  // };
  //function to change page
  const handleFetchMoreData = (event: any, offset: number) => {
    setRequestData({
      ...requestData,
      offset: offset - 1,
    });
  };
  //function to change rows per page
  const handleChangeRowsPerPage = (value: any) => {
    setRequestData({
      ...requestData,
      limit: value,
    });
  };

  // Servie call for FileDownload for "Skipped" & "Errored"
  const handleFileDownload = (exportId: any) => {
    dispatch(ContactsActions.DownloadExportedContacts(exportId)).then(
      (res: any) => {
        fileDownload(res?.payload?.data?.url);
      }
    );
  };

  // Servie call for getAllProgressBarData
  useEffect(() => {
    getProgressIntereval();
    function getProgressIntereval() { }
    const interval = setInterval(() => getProgressIntereval(), 5000);
    return () => {
      clearInterval(interval);
      setProgress(0);
    };
  }, []);

  //function to update search field & State fo that field
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setRequestData({
      ...requestData,
      [field]: e,
      offset: 0,
    });
  }, 700);


  const header = [
    "Initiated By",
    "Initiated At",
    "Type",
    "Status",
    "Download",
  ];

  const keyToHeaderMap: any = {
    "Initiated By": "exported_by",
    "Initiated At": "exported_at",
    "Type": "export_type",
    "Status": "export_status",
    "Download": "",
  };

  const colWidth: any = {
    "Initiated By": "200",
    "Initiated At": "200",
    "Type": "200",
    "Status": "200",
    "Download": "200",
  };


  const renderColumns = (row: any, header: any,) => {
    return <Box
        className="table-text"
        sx={{
          width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        }}
    >
        {
          header === "Status" ? 
                            <AppBadge
                              status={row?.export_status?.toLowerCase()}
                              isTooltipAvaliabe={false}
                            /> :
          header === "Download" ? <IconButton
          disabled={
            row?.export_status === "completed" && row?.total_records > 0 ? false : true
          }
          sx={{
            opacity: `${row?.export_status === "completed" && row?.total_records > 0 ? 1 : 0.5
              }`,
          }}
          title={row?.export_status !== "completed" ? "Status not completed" : row?.total_records < 1 ? "0 Records" : "Ready to download"}
          onClick={() => {
            handleFileDownload(row?.exportId);
          }}
        >
          <SkipIcon />
        </IconButton> :
          header === "Initiated At" ? <>{moment(row?.exported_at).format("DD/MM/YYYY") || "-"}</> :
           row[keyToHeaderMap[header]]
          ? row[keyToHeaderMap[header]]
          : "-"
        }
    </Box>

}

  return (
    <ContactMarketingWrap>
      <div className="topmenu">
        <div className="left-part">
          <div className="icon">Setting</div>
          <div className="menu-left">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div className="icon">Data management</div>
          <div className="menu-left">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div className="icon">Export History</div>
        </div>

        <div className="right-part">
          <NewSelect></NewSelect>
        </div>
      </div>
      {data?.data?.length > 0 ? (
        <React.Fragment>
            <Box px={2} pt={2} className="text">Recent exported records </Box>
          {/* <MarketingListImport>
            <div className="import-records"></div>
          </MarketingListImport> */}
          <div className="data-container">


          <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 248px)"}>
            <div>
            <Box className="table-header">
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                      key={index}
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>

              <Box className="table-body">
                {data && data?.data?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 299px)" />
                ) : (
                  <>
                    {data && data?.data?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className={`table-row`} >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                              key={"table_" + rowIndex.toString()}
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                  header === "Initiated At" ? moment(row[keyToHeaderMap[header]]).format("DD/MM/YYYY") :
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
            </div>
          </TableWrapper>

          </div>
        </React.Fragment>
      ) : (
        <Center>
          <div className="fallback_ui_container">
            <div className="fallback_ui">
              <NoTableData />
              <p className="error-message">No import history found.</p>
            </div>
          </div>
        </Center>
      )}
      {data?.data?.length > 0 ? (
        <AppPaginationComponent
          totalCount={data?.pagination?.totalRecords}
          handlePageChange={handleFetchMoreData}
          currentPage={requestData?.offset}
          totalPages={data?.pagination?.totalPages}
          rowsPerPage={requestData?.limit}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </ContactMarketingWrap>
  );
};
export default ContactsExportHistory;
