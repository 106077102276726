import styled from "styled-components";
import SwitchButton from "../../../Settings/UsersAndPermissions/RolesPermissions/components/SwitchButton";
import React, { useEffect } from "react";
import { useAppDispatch } from "../../../../Store";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { DropdownIndicatorProps, components } from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { FacebookNetworkService } from "../../../../Store/Slices/Integrations/facebookNetworkService";
import AppReactSelect from "../../../../AppComponents/AppReactSelect";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { theme } from "../../../../Customization/Theme";
import { Breadcrumbs, Skeleton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

interface Props {}

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        height="20"
        width="20"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        className="css-tj5bde-Svg"
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </components.DropdownIndicator>
  );
};

const FacebookSettings: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { showAlert } = useNotification();
  const [showBotDiv, setShowBotDiv] = React.useState<boolean>(false);
  const [selectedBot, setSelectedBot] = React.useState<any>(null);
  const [botListing, setBotListing] = React.useState([]);
  const [radioCheck, setRadioCheck] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<boolean>(false);
  const getBotListing = () => {
    setLoader(true);
    ChatbotConsoleService.getAllBots()
      .then((res: any) => {
        if (res.data?.results?.length > 0) {
          setBotListing(
            res.data.results.filter(
              (item: any) => item.channel === "messenger" && item.is_active,
            ),
          );
          setShowBotDiv(true);
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };
  const unassignBot = () => {
    setLoader(true);
    let payload = {
      page_id: params.page_id,
      bot_id: selectedBot?.bot_id,
    };
    FacebookNetworkService.unassignFacbookConfig(payload)
      .then((res: any) => {
        if (res.data.status === true) {
          setSelectedBot(null);
          setShowBotDiv(false);
          setBotListing([]);
          showAlert(res.data.message || "Bot removed successfully", "success");
          setLoader(false);
        }
      })
      .catch((err: any) => {
        showAlert("Something went wrong", "error");
        setLoader(false);
      });
  };
  const toggleBotDiv = (isChecked: boolean) => {
    if (isChecked) {
      if (botListing?.length < 1) {
        getBotListing();
      }
    } else if (selectedBot?.bot_id) {
      unassignBot();
    } else {
      setShowBotDiv(false);
    }
  };
  const saveConfig = () => {
    setLoader(true);
    let payload = {
      page_id: params.page_id,
      bot_id: selectedBot?.bot_id,
    };
    FacebookNetworkService.saveFacbookConfig(payload)
      .then((res: any) => {
        if (res.data.status === true) {
          showAlert(res.data.message || "Bot Assgined Successfully", "success");
          setLoader(false);
        }
      })
      .catch((err: any) => {
        showAlert("Something went wrong", "error");
        setLoader(false);
      });
  };

  useEffect(() => {
    setLoader(true);
    FacebookNetworkService.getFacebookPageConfig({ page_id: params.page_id })
      .then((res: any) => {
        if (res.data.status === true) {
          setShowBotDiv(true);
          setSelectedBot(res.data.bot);
          setLoader(false);
          getBotListing();
        }
      })
      .catch((err: any) => {
        showAlert("Something went wrong", "error");
        setLoader(false);
      });
  }, []);
  return (
    <FacebookPageWrap>
      <div className="configHeader">
        <Breadcrumbs aria-label="breadcrumb" separator=">">
          <BreadcrumbsItem
            iconColorCondition={true}
            onClick={() => navigate("/settings/channels/facebook")}
          >
            <div className="text">
              <p>Facebook Channel</p>
            </div>
          </BreadcrumbsItem>

          <BreadcrumbsItem iconColorCondition={false}>
            <div className="text">
              <p>Facebook Config</p>
            </div>
          </BreadcrumbsItem>
        </Breadcrumbs>
      </div>
      <div className="bot-assignment-wrapper">
        <h4>
          Assign a Bot to your page
          <SwitchButton
            checked={showBotDiv}
            onChange={(e: any) => toggleBotDiv(e.target.checked)}
          />
        </h4>
        <small>Assign a chatbot to engage with your customers.</small>

        {showBotDiv &&
          (loader ? (
            <div className="fields-wrapper loader-wrapper">
              <Skeleton width={650} height={50} />
              <Skeleton width={100} height={50} />
            </div>
          ) : (
            <div className="fields-wrapper">
              <AppReactSelect
                value={selectedBot}
                label="Select a Bot"
                placeholder="Select Field"
                options={botListing || []}
                name={`bot_listing`}
                valueKey={"id"}
                labelKey={"name"}
                isClearable={false}
                onChangeCallbackFn={(value) => setSelectedBot(value)}
                components={{ DropdownIndicator }}
              />
              {/* <label>Set business hours to trigger a bot</label>
              <RadioGroup
                name="use-radio-group"
                value={radioCheck}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="inside"
                  label="Inside Business Hours"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="outside"
                  label="Outside Business Hours"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="both"
                  label="Both"
                  control={<Radio />}
                />
              </RadioGroup> */}
              <AppButton
                className="save-btn"
                onClick={saveConfig}
                isDisabled={!selectedBot?.bot_id}
              >
                Assign bot config
              </AppButton>
            </div>
          ))}
      </div>
    </FacebookPageWrap>
  );
};

export default FacebookSettings;

const FacebookPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    padding: 10px;
    .configHeader {
      background-color: ${theme.palette.default.white};
      padding: 10px 5px;
      border: 1px solid ${theme.palette.default.border};
    }
    h4 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 10px 10px 0;
      background-color: ${theme.palette.default.white};
      font-weight: normal;
    }
    small {
      display: block;
      padding: 0 10px 5px 10px;
      background-color: ${theme.palette.default.white};
      color: ${theme.palette.default.darkGrey};
      border-bottom: 1px solid ${theme.palette.default.border};
    }
    .loader-wrapper {
      max-width: 200px;
    }
    .fields-wrapper {
      max-width: 750px;
      margin-top: 15px;
      margin-bottom: 15px;
      padding: 5px 10px;
      display: flex;
      align-items: flex-end;
      gap: 15px;
      > div:first-child {
        max-width: 600px;
      }
    }
    label {
      color: ${theme.palette.default.black};
    }
    .MuiFormGroup-root {
      display: flex;
      gap: 15px;
    }
    .bot-assignment-wrapper {
      border: 1px solid ${theme.palette.default.border};
      margin: 15px 0;
      .save-btn {
        margin-bottom: 3px;
        min-width: 130px;
      }
    }
  }
`;
export const BreadcrumbsItem = styled.div`
& {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0px 10px;
    &:last-child {
      color: ${theme.palette.default.black};
    }
    .icon {
        display: flex;
        align-items: center;
        img {
            
        }

        svg path {
            fill: ${(props: any) =>
              props.iconColorCondition
                ? `${theme.palette.primary.light}`
                : `${theme.palette.default.darkGrey}`};
          }

    }
    .text {
    display: flex;
    align - items: center;
        p {
        font-size: 14px;
        margin: 0;
        cursor: ${(props: any) => (props.onClick ? "pointer" : "default")};
        color:  ${(props: any) =>
          props.iconColorCondition
            ? `${theme.palette.primary.light}`
            : `${theme.palette.default.darkGrey}`};
        &:hover {
          color:  ${theme.palette.default.darkGrey};
        }
        
        }
      }
    }
`;
