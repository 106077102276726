import { CSSProperties } from "@material-ui/core/styles/withStyles";
import * as Icons from "@material-ui/icons/";
import * as React from "react";
// type IconType = typeof import("@material-ui/icons/index");
type IconType = typeof import("@material-ui/icons/index");

interface DynamicIconProps {
  iconName: string;
  style?: CSSProperties | undefined;
}

function upperFirst(string: string) {
  return string.slice(0, 1).toUpperCase() + string.slice(1, string.length);
}

function fixIconNames(string: string) {
  const name = string.split("-").map(upperFirst).join("");
  if (name === "3dRotation") {
    return "ThreeDRotation";
  } else if (name === "4k") {
    return "FourK";
  } else if (name === "360") {
    return "ThreeSixty";
  }
  return name;
}

export const AppMaterialIcons: React.FC<DynamicIconProps> = ({
  iconName = "ArrowBackIosRounded",
  style,
}) => {
  return React.createElement(Icons[fixIconNames(iconName)! as keyof IconType], {
    style: style,
  });
};
