import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// import { broadcastActions } from "./broadcast.actions";

interface initialStateTypes {
  data: {

  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {

  },
  error: "",
  loading: false,
};

const NotificationSlice = createSlice({
  name: "NotificationSlice",
  initialState,
  reducers: {

  }
});

export const { } = NotificationSlice.actions;

export default NotificationSlice.reducer;
