import http from "../../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class AutoAssignmentRulesSettingsService {
  // create assignment rule
  static createContactRules(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CREATE_CONTACTS_RULES}`,
      data
    );
  }
  // get rules list
  static getRulesList(data: any) {
    const { itemsPerPage, query, currentPage } = data;
    const params = {
      search: query || "",
    };
    return http.get(`${consoleBaseUrl}${Endpoints.GET_RULES}`, {
      params,
    });
  }
  // get Contacts rules by id
  static getContactsRulesById(id: string) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_ASSIGNMENT_RULES}/${id}`);
  }

  // create assignment rule
  static updateContactsRules(payload: any) {
    const { data, rule_id } = payload;
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_CONTACTS_RULES}/${rule_id}`,
      data
    );
  }

  // create assignment rule
  static deleteContactsRules(id: any) {
    return http.delete(
      `${consoleBaseUrl}${Endpoints.DELETE_CONTACTS_RULES}/${id}`
    );
  }
}

// Label EndpointValidate whatsapp Settings
class Endpoints {
  static CREATE_CONTACTS_RULES = "/workspace/create-assignment-rule";
  static GET_ASSIGNMENT_RULES = "/workspace/get-assignment-rule";
  static GET_RULES = "/workspace/assignment-rule-list";
  static UPDATE_CONTACTS_RULES = "/workspace/update-assignment-rule";
  static DELETE_CONTACTS_RULES = "/workspace/delete-assignment-rule";
}
