import { useSelector } from "react-redux";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../../Store";
import { useEffect, useState } from "react";
import {
  Box,
  Link,
  MenuItem,
  Select,
  Typography,
  debounce,
} from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import { GridCloseIcon } from "@mui/x-data-grid";
import {
  AppPagination,
  BroadcastBodyWrapper,
  BroadcastReportLogTable,
  BroadcastMainWrapper,
  BroadcastMainWrapperTitle,
} from "../EmailLogStyles";
import { AppForm } from "../../../../../AppComponents/Forms";
import AppFormSearchField from "../../../../../AppComponents/Forms/AppFormSearchField";
import { NewNativeSelect } from "../../../../../AppComponents/Forms/Styles";
import AppRangeDatePicker from "../../../../../AppComponents/AppRangeDatePicker";
import { ErrorComponent } from "../../Template/TemplatesListingCSS";
import TemplateAPIERROR from "../../../../../assets/images/No_records_found.svg";
import { DefinedCustomDates } from "../../../../../Models/defaultSelectOptions";
import AppButton from "../../../../../AppComponents/AppButton";
import { marketingListActions } from "../../../../../Store/Slices/MarketingList/marketingList.actions";
import {
  BroadcastTopTitle,
  TableFilter,
  TableWrapper,
} from "../../../../../TableStyle";
import { theme } from "../../../../../Customization/Theme";
import { ProtectedAppButton, ProtectedAppUiElement } from "../../../../../AppComponents/ProtectedComponents";

import { broadcastActions } from "../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { filtersValuesData, logReportListDataObj, logReportLoadingSelector } from "../../../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import AppPaginationComponent from "../../../../../AppComponents/Pagination/Pagination";
import NoRecordsFound from "../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import AppSkeletonTableLoader from "../../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";

interface Props extends HocBackdropLoaderProps { }
const EmailReports: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const filtersValues = useSelector(filtersValuesData);
  const listData = useSelector(logReportListDataObj);
  const isLoading = useSelector(logReportLoadingSelector)
  const list: any = listData?.results;
  const [disable, setDisable] = useState<boolean>(false)
  const [logsData, setLogsData] = useState(false);
  const [pagination, setPagination] = useState<any>();
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    itemsPerPage: { label: "10  per page", value: 10 },
    currentPage: 0,
    broadcast_type: "email",
  });

  const [reportValue, setReportValue] = useState<any>({
    status: "",
    startDate: DefinedCustomDates?.yesterday?.startDate,
    endDate: DefinedCustomDates?.yesterday?.endDate,
  });

  useEffect(() => {
    if (listData) {
      setPagination(listData?.pagination)
    }
  }, [listData])

  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
      currentPage: 0,
    });
    setLogsData(true);
  }, 700);

  const clearCallback = (e?: any, field?: any) => {
    setReportValue({
      ...reportValue,
      [field]: "",
    });
  };

  const clearSearchCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
      currentPage: 0,
    });
    setLogsData(true);
  };

  const getReportLogsData = () => {
    dispatch(
      broadcastActions.getCommunicationReportsList({
        limit: localValues?.itemsPerPage?.value,
        offset: localValues.currentPage,
        type: localValues?.broadcast_type,
        search: localValues?.search,
      })
    ).then((res: any) => {
    }).catch((error: any) => {
      showAlert(
        error?.response?.statusText || "Unable to fetch data",
        "error"
      );
      // setOnSearch(false);
    });
  };

  const getValueForFilterLogs = () => {
    dispatch(
      broadcastActions.getAllCommunicationFilterSettings({ type: "email" })
    )
      .then((res: any) => {

      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      });
  };

  const restFilter = () => {
    setReportValue({
      status: "",
      startDate: DefinedCustomDates?.yesterday?.startDate,
      endDate: DefinedCustomDates?.yesterday?.endDate,
    });
    setDisable(false)
  };

  const applyFilter = () => {
    handleExportClick();
    setDisable(true);
  };

  useEffect(() => {
    setDisable(false)
  }, [reportValue])

  useEffect(() => {
    if (logsData) {
      getReportLogsData();
      setLogsData(false);
    }
  }, [logsData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getValueForFilterLogs();
    getReportLogsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
    setLogsData(true);
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
    setLogsData(true);
  };
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setReportValue({
      ...reportValue,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  };

  const handleSelectChange = (e?: any, field?: any) => {
    setReportValue({
      ...reportValue,
      [field]: e.target.value,
    });
  };

  //   const formatDateField = (dateObj: any) => {
  //     const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
  //     return date;
  //   };

  const handleExportClick = () => {
    let payload: any;
    payload = {
      start: format(reportValue.startDate, "yyyy-MM-dd"),
      end: format(reportValue.endDate, "yyyy-MM-dd"),
      status: reportValue?.status,
      type: "email",
    };
    dispatch(marketingListActions.generateCommunicationLogsReport(payload))
      .then((res) => {
        getReportLogsData();
      }).catch((error) => {
        console.error("Error occurred while fetching the file URL.", error);
      });
  };

  const handleExportButton = (url: any) => {
    window.open(url, "_blank");
  };

  const renderSelectIconStatus = reportValue.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  // Headers For Table
  const header = ["Date", "Latest Generated Report"];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Date: "requested_on",
    "Latest Generated Report": "name",
  };
  // Width For Table Column
  const colWidth: any = {
    Date: "208",
    "Latest Generated Report": "900",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    // const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };


  return (
    <BroadcastMainWrapper style={{ padding: "0" }}>
      <BroadcastTopTitle
        style={{
          padding: "16px",
          backgroundColor: `${theme.palette.default.white}`,
          boxShadow: `${theme.palette.default.bottomShadow}`,
          // border: "1px solid",
          marginBottom: "8px",
        }}
      >
        <Box className="topTitle-left">
          <Typography fontSize="16px" fontWeight="500" component="h6">
            Generate Reports
          </Typography>
        </Box>
        <Box className="topTitle-right"></Box>
      </BroadcastTopTitle>

      <BroadcastBodyWrapper style={{ padding: "0 1rem" }}>
        <div className="setting-page-mainboxtopbar">
          <div className="setting-page__menu">
            <p>Status</p>
            <NewNativeSelect>
              <Select
                defaultValue={""}
                name="status"
                value={reportValue?.status}
                onChange={(e: any) => handleSelectChange(e, "status")}
                displayEmpty
                className="new-select-main"
              >
                <MenuItem value=""> All</MenuItem>
                {filtersValues?.status?.map((item: any, index: any) => (
                  <MenuItem value={item.value} key={index}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              <div
                className="clear-icon"
                onClick={(e: any) => clearCallback(e, "status")}
              >
                {renderSelectIconStatus}
              </div>
            </NewNativeSelect>
          </div>
          <div className="setting-page__menu">
            <p>Dates</p>
            <NewNativeSelect>
              <AppRangeDatePicker
                divStyle={{
                  width: "225px",
                  height: "42px",
                }}
                dateRange={reportValue}
                setDateRange={handleChangeDate}
              />
            </NewNativeSelect>
          </div>
          <Box
            className="setting-page__menu"
            display="flex"
            sx={{ gap: "1rem", padding: "" }}
          >
            <ProtectedAppButton
              moduleId="email_export_report"
              variant="primarydark"
              style={{ minWidth: "95px" }}
              onClick={() => {
                applyFilter();
              }}
              isDisabled={disable}
            >
              Generate Report
            </ProtectedAppButton>
            <ProtectedAppButton
              moduleId="email_export_report"
              variant="outline"
              style={{ minWidth: "95px" }}
              onClick={() => {
                restFilter();
              }}
            >
              Reset
            </ProtectedAppButton>
          </Box>
        </div>
      </BroadcastBodyWrapper>
      <BroadcastMainWrapperTitle>
        <Box></Box>
        <Box sx={{ display: "flex", gap: "10px" }}></Box>
      </BroadcastMainWrapperTitle>
      <BroadcastBodyWrapper style={{ padding: "0 1rem" }}>
        <AppForm
          initialValues={{ search: "" }}
          onSubmit={(values: any, submitProps: any) => { }}
          divStyle={{ width: "100%" }}
        >
          <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="filter-left">
              <Typography fontSize="14px" variant="h6">
                Reports
              </Typography>
              <Box>
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  defaultBorder={true}
                  onChange={(e: any) => handleSelectSearch(e, "search")}
                  clearCallback={(e: any) => clearSearchCallback(e, "search")}
                  divStyle={{
                    marginBottom: "0rem",
                    width: "100%",
                    minWidth: "100%",
                  }}
                  disabled={logsData}
                />
              </Box>
            </div>
            <div className="filter-right"></div>
          </TableFilter>
        </AppForm>

        {
          isLoading ? (<AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - 370px)`}
          />) : (<>
            <TableWrapper height={list?.length ? "calc(100vh - 331px)" : "calc(100vh - 297px)"}>
              <div>
                <Box className="table-header">
                  <Box
                    className="table-row"
                    sx={{
                      "div:first-child": {
                        maxWidth: "220px",
                      },
                    }}
                  >
                    {header?.map((header: string, index: number) => {
                      return (
                        <Box
                          className={`col-head ${header
                            .toLowerCase()
                            .replaceAll(" ", "_")}`}
                          style={{
                            //   maxWidth: colWidth[header] + "px",
                            minWidth: colWidth[header] + "px",
                          }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            {header}
                          </Typography>
                        </Box>
                      );
                    })}
                    <Box
                      className="col-head"
                      sx={{
                        maxWidth: "200px",
                        minWidth: "200px",
                        height: "2.969rem",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography>Download Status</Typography>
                    </Box>
                  </Box>
                </Box>


                {
                  list?.length === 0 ? (<NoRecordsFound>
                  </NoRecordsFound>) : (

                    <Box className="table-body">
                      {list?.map((row: any, rowIndex: any) => {

                        return (
                          <Box
                            key={rowIndex}
                            className="table-row"
                            sx={{
                              "div:first-child": {
                                maxWidth: "220px",
                              },
                            }}
                          >
                            {header?.map((header: any, ColumnIndex: any) => {
                              const isActionColumn = header === "Action";
                              return (
                                <Box
                                  className={`col-body ${header
                                    .toLowerCase()
                                    .replaceAll(" ", "_")}`}
                                  title={
                                    header === "Date"
                                      ? formatDateField(
                                        row[keyToHeaderMap[header]]
                                      )
                                      : row[keyToHeaderMap[header]]
                                        ? row[keyToHeaderMap[header]]
                                        : "-"
                                  }
                                  style={{
                                    //   maxWidth: isActionColumn
                                    //     ? "400px"
                                    //     : colWidth[header] + "px",
                                    minWidth: isActionColumn
                                      ? "400px"
                                      : colWidth[header] + "px",
                                  }}
                                >
                                  <Typography className="table-text">
                                    {header === "Date"
                                      ? formatDateField(
                                        row[keyToHeaderMap[header]]
                                      )
                                      : row[keyToHeaderMap[header]]
                                        ? row[keyToHeaderMap[header]]
                                        : "-"}
                                  </Typography>
                                </Box>
                              );
                            })}

                            <Box
                              display="flex"
                              sx={{
                                maxWidth: "200px",
                                minWidth: "200px",
                                alignItems: "center",
                              }}
                              className="col-body"
                            >
                              <Box className="" title="">
                                <ProtectedAppUiElement moduleId="email_export_report">
                                  {row?.is_ready ? (
                                    row?.attachment ? (
                                      <Link
                                        className="table-text"
                                        onClick={() =>
                                          handleExportButton(
                                            row?.attachment?.source
                                          )
                                        }
                                        underline="hover"
                                      >
                                        Download
                                      </Link>
                                    ) : (

                                      <Typography className="table-text">
                                        Failed
                                      </Typography>
                                    )
                                  ) : (
                                    <Typography className="table-text">
                                      Generating Report...
                                    </Typography>
                                  )}
                                </ProtectedAppUiElement>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>

                  )
                }
              </div>
            </TableWrapper>
            <Box pr={"1rem"}>
              {list?.length ? (
                <AppPaginationComponent
                  totalCount={pagination?.pagination?.totalRecords}
                  handlePageChange={handlePageChange}
                  currentPage={localValues?.currentPage}
                  totalPages={pagination?.pagination?.totalPages}
                  rowsPerPage={localValues?.itemsPerPage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              ) : null}
            </Box>
          </>)
        }


      </BroadcastBodyWrapper>

    </BroadcastMainWrapper>
  );
};

// Default Export of component
export default HocBackdropLoader(EmailReports);
