import { PayloadAction, createSlice, current } from "@reduxjs/toolkit";
import {
  chatFilterConversationStatusOptions,
  chatFilterOrderByOptions,
  chatFilterStatusOptions,
} from "../../../../socket/screens/Chats/AllCahatPannel/constants";
import { ConversationMenuOptions } from "../../../../socket/screens/Chats/Sidebar/Constants";
import { CheckAppliedFilterMatchesIncomingSession } from "../../../../socket/utils/utils";
import { LiveChatActions } from "./chat.actions";

export const TEMP_ACTIVE_FILTERS = {
  type: "filter",
  order: chatFilterOrderByOptions?.[0],
  labels: [],
  agents: [],
  channels: [],
  conversation_status: [],
  chat_status: [chatFilterStatusOptions?.[0]], //by default open chats only
  conversation: "mine",
  search: "",
};

interface initialStateTypes {
  data: {
    allChannelsData: Array<any> | [];
    allLabelsData: Array<any> | [];
    allAgentsData: Array<any> | [];
    allSessionsData: Array<any> | [];
    allConversationData: Array<any> | [];
    allCannedResponsesData: Array<any> | [];
    activeId?: string;
    avatarColor?: any;
    currentSessionData: null | any;
    bulkSelectedSessions: any[];
    //new changes
    activeChatTab: number;
    limit: number;
    offset: number;
    // isPrivateNoteShown: boolean;
    chatSessionActiveFilter: {
      type: string;
      labels: any;
      agents: any;
      channels: any;
      order: any;
      conversation_status: any;
      chat_status: any;
      // conversation_type: any;
      conversation: string | any;
      search: string | any;
    };
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    allChannelsData: [],
    allLabelsData: [],
    allAgentsData: [],
    allSessionsData: [],
    allConversationData: ConversationMenuOptions,
    allCannedResponsesData: [],
    activeId: "",
    avatarColor: {},
    currentSessionData: null,
    bulkSelectedSessions: [],
    //new changes
    activeChatTab: 0,
    limit: 25,
    offset: 0,
    // isPrivateNoteShown: false,
    chatSessionActiveFilter: TEMP_ACTIVE_FILTERS,
  },
  error: "",
  loading: false,
};

const LiveChatSlice = createSlice({
  name: "LiveChatSlice",
  initialState,
  reducers: {
    appendNewSessionData(state, { payload }: PayloadAction<any>) {
      let tempActiveFilters = current(state)?.data?.chatSessionActiveFilter;
      let result: boolean =CheckAppliedFilterMatchesIncomingSession(tempActiveFilters, payload) ||
      false;
      let currentSessionTempId: string =current(state)?.data?.currentSessionData?.session_id ?? "";
      // if (!result) return;

      const temp = state?.data?.allSessionsData ?? [];
      const index = temp?.findIndex(       
        (x) => x?.session_id === payload?.session_id
        );

      if (index > -1) {
        temp?.splice(index, 1);
        // state.data.allSessionsData = temp;
      }
      else {
        if (!result) return;
      }

      if (!Array.isArray(payload) && typeof payload === "object") {
        // if (payload?.session_id === currentSessionTempId) {
        //   payload = {         
        //     ...payload,
        //     count: 0,
        //     date: payload?.date ?? payload?.createdAt,
        //   };
        //   state.data.currentSessionData = payload;
        // }
        payload = [payload];
      }

      const existing =
        JSON.parse(JSON.stringify(state.data.allSessionsData)) || [];
      const newSessions = [...payload, ...existing];
      // state.data.allSessionsData = newSessions;

      // Shuffle and move the updated card to the top based on 'date' and 'count'
      const shuffledSessions = newSessions?.sort((a, b) => {
        // Sort based on 'date' in descending order
        const dateComparison = Date.parse(b?.date ?? b?.createdAt) - Date.parse(a?.date ?? a?.createdAt);
        if (dateComparison !== 0) {
          return dateComparison;
        }

        // If 'date' is the same, sort based on 'count' in descending order
        return b?.count - a?.count;
      });
      state.data.allSessionsData = shuffledSessions;
    },
    removeSessionData(state, { payload }: PayloadAction<any>) {
      const temp = state?.data?.allSessionsData ?? [];
      const index = temp?.findIndex(
        (x) => x?.session_id === payload?.session_id
      );

      if (index > -1) {
        temp?.splice(index, 1);
        state.data.allSessionsData = temp;
        return;
      }

      return;
    },

    setActiveId(state, { payload }: PayloadAction<any>) {
      state.data.activeId = payload;
    },
    setAvatarColor(state, { payload }: PayloadAction<any>) {
      state.data.avatarColor = payload;
    },
    setCurrentSessionData(state, { payload }: PayloadAction<any>) {
      state.data.currentSessionData = payload;
    },
    // setTemporaryActiveAccordionId(state, { payload }: PayloadAction<any>) {
    //   state.data.temporaryActiveFilters = payload;
    // },
    updateSessionData(state, { payload }: PayloadAction<any>) {
      const temp = state?.data?.allSessionsData ?? [];
      const index = temp?.findIndex(
        (x) => x?.session_id === payload?.session_id
      );

      if (index > -1) {
        temp[index] = {
          ...temp[index],
          ...payload,
        };
        state.data.currentSessionData = payload;

        // Shuffle and move the updated card to the top based on 'date' and 'count'
        const shuffledSessions = temp?.sort((a, b) => {
          // Sort based on 'date' in descending order
          const currentOrderByActiveFilter =
            current(state)?.data.chatSessionActiveFilter?.order?.value;
          if (currentOrderByActiveFilter === "recent") {
            const dateComparison = Date.parse(b.date) - Date.parse(a.date);
            if (dateComparison !== 0) {
              return dateComparison;
            }
          }

          // If 'date' is the same, sort based on 'count' in descending order
          return b?.count - a?.count;
        });
        state.data.allSessionsData = shuffledSessions;

        return;
      }

      return;
    },

    //new changes
    updateChatSessionActiveFilters(state, { payload }: PayloadAction<any>) {
      if (typeof payload !== "object") return;
      let temp: any = state.data.chatSessionActiveFilter;
      temp = { ...temp, ...payload };
      state.data.chatSessionActiveFilter = temp;

      state.data.offset = 0;
      state.data.activeChatTab = 0;
      // state.data.isPrivateNoteShown = false;
      return;
    },
    setChatSessionActiveFilters(state, { payload }: PayloadAction<any>) {
      if (typeof payload !== "object") return;

      let keys: string[] = Object.keys(payload);

      if (keys?.length === 1 && keys?.[0] === "conversation") {
        let temp: any = { ...TEMP_ACTIVE_FILTERS, ...payload };

        if (temp?.["conversation"] === "unassigned") {
          temp = {
            ...temp,
            conversation_status: chatFilterConversationStatusOptions?.[0],
          };
        }
        if (temp?.["conversation"] === "unattended") {
          temp = {
            ...temp,
            conversation_status: chatFilterConversationStatusOptions?.[2],
          };
        }

        state.data.chatSessionActiveFilter = temp;
        return;
      } else {
        let temp: any = {
          ...TEMP_ACTIVE_FILTERS,
          ...payload,
          conversation: "",
        };
        state.data.chatSessionActiveFilter = temp;
        state.data.offset = 0;
        state.data.activeChatTab = 0;
        // state.data.isPrivateNoteShown = false;

        return;
      }
    },
    removeChatSessionActiveFilters(state, { payload }: PayloadAction<any>) {
      if (typeof payload !== "object") return;
      let temp = TEMP_ACTIVE_FILTERS;
      state.data.chatSessionActiveFilter = temp;
      state.data.offset = 0;
      state.data.activeChatTab = 0;
      // state.data.isPrivateNoteShown = false;
    },

    setPageNumber(state, { payload }: PayloadAction<any>) {
      state.data.offset = payload;
    },

    setActiveChatTab(state, { payload }: PayloadAction<any>) {
      state.data.activeChatTab = payload;
    },
    // setIsPrivateNoteShown(state, { payload }: PayloadAction<any>) {
    //   state.data.isPrivateNoteShown = payload;
    // },

    setAllBulkSelectedSessions(state, { payload }: PayloadAction<any>) {
      let currentAllSessions = current(state)?.data?.allSessionsData;
      let temp: any[] = [];

      currentAllSessions?.forEach((ele: any, index: number) => {
        if (ele?.session_id) {
          temp?.push(ele?.session_id);
        }
      });

      if (payload) {
        state.data.bulkSelectedSessions = temp;
      } else {
        state.data.bulkSelectedSessions = [];
      }
    },

    setBulkSelectedSessions(state, { payload }: PayloadAction<any>) {
      let id: string = payload?.id;

      if (!id) return;
      let currentAllSelections: any[] =
        current(state)?.data?.bulkSelectedSessions;

      let isAlreadyExists: boolean = currentAllSelections?.includes(id);

      if (isAlreadyExists) {
        currentAllSelections = currentAllSelections?.filter(
          (e: string) => e !== id
        );
      } else {
        currentAllSelections = [...currentAllSelections, id];
      }

      state.data.bulkSelectedSessions = currentAllSelections;
    },
    removeAllSessionsData(state) {
      state.data.allSessionsData = [];
    },
    setAllAgentsData(state,{ payload }: PayloadAction<any>) {
      state.data.allAgentsData = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      //* all channels
      .addCase(LiveChatActions.getChannelsListings.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        LiveChatActions.getChannelsListings.fulfilled,
        (state, action) => {
          if (action.payload?.data?.data?.length > 0) {
            state.data.allChannelsData = action.payload?.data?.data;
          } else {
            state.error = action.payload?.data?.data || "";
          }
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        LiveChatActions.getChannelsListings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allChannelsData = [];
          state.error = action?.error?.message || "";
        }
      )

      //* all labels
      .addCase(LiveChatActions.getLabelsListings.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(LiveChatActions.getLabelsListings.fulfilled, (state, action) => {
        if (action.payload?.data?.status === 200) {
          state.data.allLabelsData = action.payload?.data?.data?.data;
        } else {
          state.error = action.payload?.data?.data || "";
        }
        state.error = "";
        state.loading = false;
      })
      .addCase(LiveChatActions.getLabelsListings.rejected, (state, action) => {
        state.loading = false;
        state.data.allLabelsData = [];
        state.error = action?.error?.message || "";
      })
      //* all agents
      .addCase(LiveChatActions.getAgentsListings.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(LiveChatActions.getAgentsListings.fulfilled, (state, action) => {
        if (action.payload?.data?.status === 200) {
          state.data.allAgentsData = action.payload?.data?.data;
        } else {
          state.error = action.payload?.data?.data || "";
        }

        state.error = "";
        state.loading = false;
      })
      .addCase(LiveChatActions.getAgentsListings.rejected, (state, action) => {
        state.loading = false;
        state.data.allAgentsData = [];
        state.error = action?.error?.message || "";
      })

      //* all sessions
      .addCase(LiveChatActions.getSessionsListings.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        LiveChatActions.getSessionsListings.fulfilled,
        (state, action) => {
          if (action.payload?.data?.status === 200) {
            let payload = action.payload?.data?.data;

            state.data.allSessionsData = payload;
          } else {
            state.error = action.payload?.data?.data || "";
          }
          state.data.bulkSelectedSessions = [];
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        LiveChatActions.getSessionsListings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allSessionsData = [];
          state.error = action?.error?.message || "";
        }
      )

      //* append sessions
      .addCase(
        LiveChatActions.appendSessionsListings.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        LiveChatActions.appendSessionsListings.fulfilled,
        (state, action) => {
          if (action.payload?.data?.status === 200) {
            let payload = action.payload?.data?.data;
            if (!Array.isArray(payload) && typeof payload === "object") {
              payload = [payload];
            }
            const existing = JSON.parse(
              JSON.stringify(state.data.allSessionsData)
            );
            if (existing?.length <= 0) {
              const newSessions = [...payload];
              state.data.allSessionsData = newSessions;
              return;
            }
            const newSessions = [...existing, ...payload];
            state.data.allSessionsData = newSessions;
          } else {
            state.error = action.payload?.data?.data || "";
          }
          state.data.bulkSelectedSessions = [];
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        LiveChatActions.appendSessionsListings.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allSessionsData = [];
          state.error = action?.error?.message || "";
        }
      )

      //* all canned responses
      .addCase(
        LiveChatActions.getChatCannedResponses.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        LiveChatActions.getChatCannedResponses.fulfilled,
        (state, action) => {
          if (action.payload?.data?.status === 200) {
            let payload = action.payload?.data?.data?.data;

            state.data.allCannedResponsesData = payload;
          } else {
            state.error = action.payload?.data?.data || "";
          }
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        LiveChatActions.getChatCannedResponses.rejected,
        (state, action) => {
          state.loading = false;
          state.data.allCannedResponsesData = [];
          state.error = action?.error?.message || "";
        }
      );
  },
});

export default LiveChatSlice.reducer;

export const {
  appendNewSessionData,
  removeSessionData,
  setActiveId,
  setAvatarColor,
  updateSessionData,
  // setTemporaryActiveAccordionId,
  setCurrentSessionData,
  updateChatSessionActiveFilters,
  setChatSessionActiveFilters,
  removeChatSessionActiveFilters,
  setPageNumber,
  setActiveChatTab,
  // setIsPrivateNoteShown,
  removeAllSessionsData,
  setAllBulkSelectedSessions,
  setBulkSelectedSessions,
  setAllAgentsData,
} = LiveChatSlice.actions;
