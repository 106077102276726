import { Box, IconButton, LinearProgress, Typography, debounce } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppBadge from "../../AppComponents/AppBadge";
import { AppForm } from "../../AppComponents/Forms";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import useModal from "../../Hooks/useModel/useModel";
import { useAppDispatch } from "../../Store";
import { marketingListActions } from "../../Store/Slices/MarketingList/marketingList.actions";
import {
  importHistoryData,
  importHistoryProgressData,
} from "../../Store/Slices/MarketingList/marketingList.selectors";
import { fileDownload } from "../../Utils";
import { ReactComponent as ForwardIcon } from "../../assets/images/forward-arrow.svg";
import noTableData from "../../assets/images/noDatafound.svg";
import { ReactComponent as SkipIcon } from "../../assets/images/skippedrecord.svg";
import {
  AnsweredFaqList,
  AnsweredTable,
  Center,
  ContactMarketingWrap,
  MarketingListImport,
  NewSelect,
  TextWrap,
} from "./ContactMarketingListStyle";
import CreateListModal from "./commonComponents/marketingListModals/CreateListModal";
import { TableWrapper } from "../../TableStyle";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
const ContactMarketingImport = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isShowing, toggle } = useModal();
  const nullValuesArray: any = [0, "", null];
  const [requestData, setRequestData] = useState({
    search: "",
    offset: 0,
    limit: { label: "50 per page", value: 50 },
  });
  const data = useSelector(importHistoryData);
  const progressData = useSelector(importHistoryProgressData);
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    // Servie call for getAllContactHistory
    let payload: any = {
      search: requestData.search,
      offset: requestData.offset,
      limit: requestData.limit.value,
    };
    dispatch(marketingListActions.getAllImportHistoryData(payload));
  }, [requestData]); // eslint-disable-line react-hooks/exhaustive-deps
  // modal toggle functions
  const handleCreateListClick = useCallback(() => {
    toggle();
  }, [toggle]);
  const handlClick = () => {
    // navigate(`/bot/${bot.bot_id}/contact-marketing-detail`);
  };
  //function to change page
  const handleFetchMoreData = (event: any, offset: number) => {
    setRequestData({
      ...requestData,
      offset: offset - 1,
    });
  };
  //function to change rows per page
  const handleChangeRowsPerPage = (value: any) => {
    setRequestData({
      ...requestData,
      limit: value,
    });
  };

  // Servie call for FileDownload for "Skipped" & "Errored"
  const handleFileDownload = (importId: any, count: number, type: string) => {
    const data = {
      importId,
      type,
    };
    if (nullValuesArray.includes(count)) return;
    dispatch(marketingListActions.downloadImportHistoryFile(data)).then(
      (res: any) => {
        fileDownload(res?.payload?.data?.url);
      }
    );
  };

  // Servie call for getAllProgressBarData
  useEffect(() => {
    getProgressIntereval();
    function getProgressIntereval() {
      dispatch(marketingListActions.getAllProgressData(""))
        .then((response: any) => {
          if (!response?.payload?.data?.data?.status) {
            clearInterval(interval);
          }
          //Calling Contact Import History List API Once progress completed 100%
          if (response?.payload?.data?.data?.percentage === 100) {
            setProgress(100);
            let payload: any = {
              search: requestData.search,
              offset: requestData.offset,
              limit: requestData.limit.value,
            };
            dispatch(marketingListActions.getAllImportHistoryData(payload));
            clearInterval(interval);
          } else {
            //calculating last & New Value and measuring the difference between them inorder to Linear Progress
            let difference =
              response?.payload?.data?.data?.percentage - progress;
            setProgress(difference);
            difference = 0;
          }
        })
        .catch((Error: any) => console.error("error", Error));
    }
    const interval = setInterval(() => getProgressIntereval(), 5000);
    return () => {
      clearInterval(interval);
      setProgress(0);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //function to clear Searchfield with Values
  const clearCallback = (e?: any, field?: any) => {
    setRequestData({
      ...requestData,
      [field]: "",
    });
  };
  //function to update search field & State fo that field
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setRequestData({
      ...requestData,
      [field]: e,
      offset: 0,
    });
  }, 700);


  const header = [
    "List Name",
    "Record created",
    "Record updated",
    "Record skipped",
    "Record errored",
    "Imported by",
    "Date",
    "Status"
  ];

  const keyToHeaderMap: any = {
    "List Name": "name",
    "Record created": "record_created",
    "Record updated": "record_updated",
    "Record skipped": "record_skipped",
    "Record errored": "record_errored",
    "Imported by": "imported_by",
    "Date": "import_date",
    "Status": "status"
  };

  const colWidth: any = {
    "List Name": "200",
    "Record created": "200",
    "Record updated": "200",
    "Record skipped": "200",
    "Record errored": "200",
    "Imported by": "200",
    "Date": "200",
    "Status": "200"
  };

  const renderColumns = (row: any, header: any,) => {
            return <Box
                className="table-text"
                sx={{
                  width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                }}
            >
                {


                  header === "Date" ? <>{moment(row?.import_date).format("DD/MM/YYYY") || "-"}</> :
                  header === "Record errored" ? <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                    <Box>
                      {row?.record_errored || 0}
                    </Box>
                        <Box
                        sx={{cursor: "pointer"}}
                          className={`${
                            nullValuesArray.includes(row?.record_errored)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <SkipIcon
                            onClick={() => {
                              handleFileDownload(
                                row.importId,
                                row?.record_errored,
                                "errored"
                              );
                            }}
                          />
                        </Box>
                  </Box> :
                  header === "Record skipped" ? <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                    <Box>
                      {
                        row[keyToHeaderMap[header]]
                        ? row[keyToHeaderMap[header]]
                        : 0
                        }
                      </Box>
                        <Box
                          sx={{cursor: "pointer"}}
                          className={`${
                            nullValuesArray.includes(row?.record_skipped)
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <SkipIcon
                            onClick={() => {
                              handleFileDownload(
                                row.importId,
                                row?.record_skipped,
                                "skipped"
                              );
                            }}
                          />
                        </Box>   
                  </Box> :
                  header === "Status" ? <AppBadge
                              status={row?.status?.toLowerCase()}
                              isTooltipAvaliabe={false}
                            />
                   : row[keyToHeaderMap[header]]
                  ? row[keyToHeaderMap[header]]
                  : "-"
                }
            </Box>
        
      }

  return (
    <ContactMarketingWrap>
      <div className="topmenu">
        <div className="left-part">
          <div className="icon">Setting</div>
          <div className="menu-left">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div className="icon">Data management</div>
          <div className="menu-left">
            <IconButton>
              <ForwardIcon />
            </IconButton>
          </div>
          <div className="icon">Import history</div>
        </div>

        <div className="right-part">
          <NewSelect>
            <div className="faq-search">
              <AppForm
                initialValues={requestData}
                onSubmit={(values: any) => {}}
              >
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  divStyle={{
                    marginBottom: 0,
                    marginRight: "1rem",
                    width: "80%",
                    maxWidth: "320px",
                    minWidth: "120px",
                    gap: 0,
                  }}
                  onChange={(e: any) => {
                    handleSelectSearch(e, "search");
                  }}
                  clearCallback={(e: any) => clearCallback(e, "search")}
                  defaultBorder={true}
                />
              </AppForm>
            </div>
            <div className="button">
              <ProtectedAppButton
                moduleId="marketing_list_crud"
                specificPermissionKey="create"
                onClick={() => handleCreateListClick()}
              >
                Create List
              </ProtectedAppButton>
            </div>
          </NewSelect>
        </div>
      </div>
      {data?.totalRecords > 0 ? (
        <React.Fragment>
          <TextWrap>
            <div className="text">Recent import records</div>
          </TextWrap>
          <MarketingListImport>
            <div className="import-records">
              <div className="classified-record-progress">
                <div className="progress">
                  <div className="record-details">
                    <p>{progress !== 100 ? "InProgress" : "Completed"}</p>
                  </div>
                  <div className="record-percent">{`${progress} %`}</div>
                </div>

                <Box className="linear_Progress">
                  <LinearProgress
                    variant="buffer"
                    value={progress || 0}
                    valueBuffer={5}
                  />
                </Box>
              </div>

              <div className="classified-record-created">
                <div className="record-details">
                  <p>Records created:</p>
                  <p className="record-count">
                    {progressData?.status
                      ? progressData?.data?.record_created
                      : "--"}{" "}
                  </p>
                </div>
              </div>
              <div className="classified-record-updated">
                <div className="record-details">
                  <p>Records updated:</p>
                  <p className="record-count">
                    {progressData?.status
                      ? progressData?.data?.record_updated
                      : "--"}{" "}
                  </p>
                </div>
              </div>
              <div className="classified-record-skipped">
                <div className="record-details">
                  <p>Records skipped:</p>
                  <p className="record-count">
                    {progressData?.status
                      ? progressData?.data?.record_skipped
                      : "--"}{" "}
                  </p>
                </div>
              </div>
              <div className="classified-record-errored">
                <div className="record-details">
                  <p>Records errored:</p>
                  <p className="record-count">
                    {progressData?.status
                      ? progressData?.data?.record_errored
                      : "--"}{" "}
                  </p>
                </div>
              </div>
            </div>
          </MarketingListImport>
          <div className="data-container">


                       <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 373px)"}>
              <div>
              <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                      key={index}
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* Table Body */}
              </Box>

              <Box className="table-body">
                {data && data?.results?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 424px)" />
                ) : (
                  <>
                    {data && data?.results?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className={`table-row`} >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
              </div>
        </TableWrapper>
          </div>
        </React.Fragment>
      ) : (
        <Center>
          <div className="fallback_ui_container">
            <div className="fallback_ui">
              <img src={noTableData} alt="" />
              <p className="error-message">No past import history found.</p>
              <ProtectedAppButton
                moduleId="marketing_list_crud"
                specificPermissionKey="create"
                className="button"
                onClick={() => handleCreateListClick()}
              >
                Create List
              </ProtectedAppButton>
            </div>
          </div>
        </Center>
      )}

      {/* create marketing list  modal */}
      <CreateListModal
        toggleModalCreate={handleCreateListClick}
        handlClick={handlClick}
        isShowing={isShowing}
      />
      {data?.totalRecords > 0 ? (
        <AppPaginationComponent
          totalCount={data?.totalRecords}
          handlePageChange={handleFetchMoreData}
          currentPage={requestData?.offset}
          totalPages={data?.totalPages}
          rowsPerPage={requestData?.limit}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      ) : null}
    </ContactMarketingWrap>
  );
};
export default ContactMarketingImport;
