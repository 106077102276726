import { useState, useEffect } from "react";

const useInternetConnection = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const goOnline = () => {
      console.warn("you are online");
      setIsOnline(true);
    };
    const goOffline = () => {
      setIsOnline(false);
      console.warn("You are offline. Some features may not work.");
    };

    window.addEventListener("online", goOnline);
    window.addEventListener("offline", goOffline);

    return () => {
      window.removeEventListener("online", goOnline);
      window.removeEventListener("offline", goOffline);
    };
  }, []);

  return {isOnline};
};

export default useInternetConnection;
