import { Box, Typography } from "@mui/material";
import { WrapperBUS } from "./EmailBroadcastSummaryStyles";
import { SkeletonBUS } from "../../Dashboard/SkeletonLoader.tsx/SkeletonCard";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";

interface Props {
  broadcastStats?: any;
  loading?:boolean;
}

const BUSSummary: React.FC<Props> = ({ broadcastStats ,loading}) => {
  return (
    
       <WrapperBUS>
      <div className="Bus_wrap">
        <div className="BUS_body">
          
          <div className="Bounces_d">
            {
              loading ? <AppSkeletonLoader /> : 
                      <div className="Bounces_c">
                          {
                            (broadcastStats?.bounced ?? 0) === 0 ?
                            (
                              
                              <NoRecordsFound
                                imageWidth={"200px"}
                                headerTextSize={"19px"}
                                bgColor="transparent"
                                imageSrc={NoRecordImage}
                                />
                              
                            ):(
                              <>
                                <p className="count">{broadcastStats?.bounced || 0}</p>
                                <Box display={"flex"} alignItems={"center"}>
                                  <p className="text">Bounces</p>
                                </Box>
                                <Box width={"65%"}>
                                  <Typography
                                    className="text-info"
                                    fontSize={"12px"}
                                    fontWeight={"400"}
                                  >
                                    Indicates the count of recipients who have opted out or
                                    unsubscribed from receiving further emails, offering insights
                                    into audience preferences and engagement.
                                  </Typography>
                                </Box>
                              </>
                            )
                          }
                      </div>      
            }
            
          </div>

          <div className="Bounces_d">
            {
              loading ? <AppSkeletonLoader /> : <div className="Bounces_c">
                {
                  (broadcastStats?.unsubscribed ?? 0) === 0 ?
                  (
                        <NoRecordsFound
                          imageWidth={"200px"}
                          headerTextSize={"19px"}
                          bgColor="transparent"
                        imageSrc={NoRecordImage}
                        />
                  ) : (
                    <>
                        <p className="count">{broadcastStats?.unsubscribed || 0}</p>
                        <Box display={"flex"} alignItems={"center"}>
                          <p className="text">Unsubscribed</p>
                        </Box>
                        <Box width={"65%"}>
                          <Typography
                            className="text-info"
                            fontSize={"12px"}
                            fontWeight={"400"}
                          >
                            Reflects the count of emails flagged or reported as spam by
                            recipients, providing insights into email deliverability and
                            potential issues with content or sending practices.
                          </Typography>
                        </Box>
                    </>
                  )
                }
            </div>
            }
            
          </div>

          <div className="Bounces_d">
            {
              loading ? <AppSkeletonLoader /> :<div className="Bounces_c">
                {
                  (broadcastStats?.abusereport ?? 0) === 0 ?
                  (
                      <NoRecordsFound
                        imageWidth={"200px"}
                        headerTextSize={"19px"}
                        bgColor="transparent"
                        imageSrc={NoRecordImage}
                      />
                  ) : (
                    <>
                        <p className="count">{broadcastStats?.abusereport || 0}</p>
                        <Box display={"flex"} alignItems={"center"}>
                          <p className="text">Marked spam</p>
                        </Box>
                        <Box width={"65%"}>
                          <Typography
                            className="text-info"
                            fontSize={"12px"}
                            fontWeight={"400"}
                          >
                            Reflects the count of emails flagged or reported as spam by
                            recipients, providing insights into email deliverability and
                            potential issues with content or sending practices.
                          </Typography>
                        </Box>
                    </>
                  )
                }
            </div>
            }
            
          </div>

        </div>
      </div>
    </WrapperBUS>
    
    
  );
};

export default BUSSummary;
