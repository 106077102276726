import { Avatar } from "@mui/material";
import styled from "styled-components";
import { ColorUtils } from "../../../../../Utils/ColorUtils";
import React from "react";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { useAppSelector } from "../../../../../Store";
import messengerWidgetPreviewIcon from "../../../../../assets/images/messengerBot/messengerIcon.svg";
import WHATSAPP_SVG from "../../../../../assets/images/whatsappBot/whatsappIcon.svg";
import TELEGRAM_SVG from "../../../../../assets/images/telegramBot/telegramWidgetPrevireIcon.svg";

interface Props {
  button_text: string;
  button_background_color: string;
}

const BotFloatingButton: React.FC<Props> = ({
  button_text,
  button_background_color,
}) => {
  const currentBot: any = useAppSelector(useCurrentBot);
  return (
    <SytledChipButton
      onClick={() => undefined}
      $textLength={button_text?.length}
      $button_background_color={button_background_color}
    >
      <Avatar src={currentBot?.channel === "messenger" ? messengerWidgetPreviewIcon : currentBot?.channel === "telegram" ? TELEGRAM_SVG : WHATSAPP_SVG} />
      <p className="calloutText">{button_text}</p>
    </SytledChipButton>
  );
};

export default React.memo(BotFloatingButton);

const SytledChipButton = styled.div`
  && {
    background: ${({ $button_background_color }: any) =>
      $button_background_color ?? "#25D366"};
    display: flex;
    align-items: center;
    align-items: center;
    justify-content: center;
    height: 44px;
    pointer-events: none;
    gap: 10px;
    padding: 8px 10px 8px 16px;
    border-radius: 28px;
    z-index: 1;
    & .MuiAvatar-root {
      width: 22px;
      height: 22px;
      border-radius: 0;
    }
    & .calloutText {
      font-size: 17px;
      font-weight: 500;
      line-height: 24px;
      width: ${({ $textLength }: any) => {
        return $textLength * 10;
      }}px;
      min-width: 50px;
      max-width: 260px;
      color: ${ColorUtils.hexToRgba("#ffffff", 0.87)};

      letter-spacing: 0.25px;
      text-transform: capitalize;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;
