import { isEmptyObject } from "../Utils";

//menus

export const checkMenuTabsHaveValidPermissions = (
  permissionsData: any,
  moduleId: string
) => {
  if (isEmptyObject(permissionsData)) return false;

  if (!moduleId) return false;

  let result: any = permissionsData?.[moduleId];
  if (!result) return false;

  if (typeof permissionsData?.[moduleId] === "boolean") return result ?? false;

  if (Array.isArray(result) && result?.length > 0 && result?.includes("read")) {
    return true;
  }
  return false;
};

export const getRestrictedMenuOptions = (
  options: any,
  permissionsData: any
) => {
  options?.forEach((singleItem: any) => {
    const moduleId = singleItem?.moduleId;
    const result: boolean =
      checkMenuTabsHaveValidPermissions(permissionsData, moduleId) ?? false;

    // if (result) {
    singleItem.isAvailable = result;

    singleItem?.children?.forEach((child: any) => {
      const childModuleId = child?.moduleId;
      const childResult: boolean =
        checkMenuTabsHaveValidPermissions(permissionsData, childModuleId) ??
        false;

      // if (childResult) {
      child.isAvailable = childResult;
      // }
    });
    // }
  });

  const result = filterAvailableMenuOptions(options) ?? [];

  return result;
};

export const filterAvailableMenuOptions = (options: any) => {
  const filteredMenuOptions = options?.filter((singleItem: any) => {
    let isAvailable = singleItem?.isAvailable;

    if (isAvailable) {
      if (singleItem?.children) {
        singleItem.children = singleItem.children.filter((child: any) => {
          return child?.isAvailable;
        });
      }
      return true;
    }

    return false;
  });

  return filteredMenuOptions;
};

//disabled items
export const getChildrenDisabledItems = (child: any, userConfig: any) => {
  let isDisabled = child?.isDisabled ?? false;

  if (isDisabled) return;

  //check if broadcast module config is available or not
  if (!isDisabled && child?.parentModuleId === "broadcast") {
    if (
      !userConfig?.whatsapp_enabled &&
      child?.moduleId === "whatsapp_broadcast"
    ) {
      return true;
    }
    if (!userConfig?.email_enabled && child?.moduleId === "email_broadcast") {
      return true;
    }
    if (!userConfig?.sms_enabled && child?.moduleId === "sms_broadcast") {
      return true;
    }
  }

  return false;
};

//route check permissions
export const checkRouteHaveValidPermissions = (
  permissionsData: any,
  moduleId: string,
  specificPermissionKey: "create" | "read" | "update" | "delete"
) => {
  if (isEmptyObject(permissionsData)) return false;

  if (!moduleId) return false;

  let result: any = permissionsData?.[moduleId];
  if (typeof permissionsData?.[moduleId] === "boolean") return result ?? false;

  if (
    Array.isArray(result) &&
    result?.length > 0 &&
    result?.includes(specificPermissionKey)
  ) {
    return true;
  }

  return false;
};

//accordion menus

// export const filterAvailableAccordionMenuOptions = (options: any) => {
//   const filteredMenuOptions = options?.filter((singleItem: any) => {
//     let isAvailable = singleItem?.isAvailable;

//     if (isAvailable) {
//       if (singleItem?.options?.length > 0) {
//         singleItem.options = singleItem.options.filter((child: any) => {
//           return child?.isAvailable;
//         });
//       } else {
//         return false;
//       }
//       // return true;
//     }

//     return false;
//   });

//   return filteredMenuOptions;
// };

export const filterAvailableAccordionMenuOptions = (options: any) => {
  const filteredMenuOptions = options?.filter((singleItem: any) => {
    let isAvailable = singleItem?.isAvailable;

    if (isAvailable) {
      if (singleItem?.options?.length > 0) {
        const childrenWithAvailability = singleItem?.options?.filter((child: any) => {
          return child?.isAvailable;
        });

        if (childrenWithAvailability.length === 0) {
          singleItem.isAvailable = false;
        } else {
          singleItem.options = childrenWithAvailability;
        }
      } else {
        return false;
      }
      return true;
    }

    return false;
  });

  return filteredMenuOptions;
};


export const getRestrictedAccordionMenuOptions = (
  accOptions: any,
  permissionsData: any
) => {
  accOptions?.forEach((singleItem: any) => {
    const moduleId = singleItem?.moduleId;
    const result: boolean =
      checkMenuTabsHaveValidPermissions(permissionsData, moduleId) ?? false;
    // if (result) {
    if (singleItem?.options?.length > 0) {
      singleItem.isAvailable = result;

      singleItem?.options?.forEach((child: any) => {
        const childModuleId = child?.moduleId;
        const childResult: boolean =
          checkMenuTabsHaveValidPermissions(permissionsData, childModuleId) ??
          false;
        // if (childResult) {
        child.isAvailable = childResult;
        // }
      });
    }
    // }
  });

  const result = filterAvailableMenuOptions(accOptions) ?? [];

  return result;
};

// export const findFormSpecificPermissionKeyUsingLocation = () => {
//   const availablePermissions: ("create" | "read" | "update" | "delete")[] = ["create", "read", "update", "delete"];
//   let result: "create" | "read" | "update" | "delete" = "create";
//   let pathname = window.location.pathname;

//   for (let i = 0; i < availablePermissions?.length; i++) {
//     // break condition    
//     let key:"create" | "read" | "update" | "delete" = availablePermissions[i] ?? "create";
//     if ((key==='update' && pathname.includes("edit")) || (key==='read' && pathname.includes("view")) || pathname.includes(key)) {
//       result = key;
//         break;

//     }
// }
//   return result;
// };


export const findFormSpecificPermissionKeyUsingLocation = () => {
  let pathname = window.location.pathname;
  if (pathname.includes("edit")) return "update";
  if (pathname.includes("create")) return "create";
  if (pathname.includes("view")) return "read";
  if (pathname.includes("delete")) return "delete";
  return "create";
};


export const findPopupFormSpecificPermissionKeyUsingId = (data?: any, valueKey: string = "id") => {
  if (!data) return "create";

  if (typeof data === "string") {
    return "update"
  }
  if (typeof data === "object" && !isEmptyObject(data) && data?.[valueKey]) {
    return "update"
  }

  return "create"

};


export const checkMultipleModuleIdAccess = (moduleIds: string[], permissionsData: any) => {
  if (isEmptyObject(permissionsData)) return false;

  if (moduleIds?.length === 0) return false;

  let result: any = false;

  for (let i = 0; i < moduleIds?.length; i++) {
    const moduleId = moduleIds?.[i];
    result = permissionsData?.[moduleId] ?? false;

    if (result) {
      break;
    }
  }

  return result;
};