import AppButton from "../../AppButton";

export const CustomQueryDeleteRuleButton = (props: any) => {
  return (
    <AppButton
      variant="danger"
      onClick={(e: any) => props?.handleOnClick(e)}
      title={props?.label ?? "Delete"}
      style={{ minHeight: "32px", height: "32px" }}
      className="remove-rule__btn"
    >
      {props?.label ?? "Delete"}
    </AppButton>
  );
};
