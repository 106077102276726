import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';
import NoRecordsFound from '../../UiComponents/NoRecordsFound/NoRecordsFound';
import { theme } from '../../../Customization/Theme';

interface DetailsTableProps {
  // handleClick?: any;
  handleClick?: (data: any) => void;
  keyToHeaderMap: any;
  tableData: any;
  height?: any;
  pagination?: boolean;
  border?: boolean;
  apiError?: boolean;
  type?: string;
  tableHeaderColor?: boolean;
}

const DetailsTable: React.FC<DetailsTableProps> = ({
  keyToHeaderMap,
  handleClick = () => { },
  tableData,
  // handleClick,
  height,
  pagination = false,
  border = true,
  apiError,
  type,
  tableHeaderColor = false
}) => {
  return (
    <TableWrapper
      border={border}
      height={pagination ? height : "calc(100%)"}
      headerColor={tableHeaderColor}
    >
      <div>
        <Box className="table-header">
          <Box className="table-row">
            {Object.keys(keyToHeaderMap).map((key) => (
              <div className="col-head" title={keyToHeaderMap[key]} key={key} style={{
                backgroundColor: tableHeaderColor ? "#EFEEFC" : "#fff"
              }}>
                {typeof keyToHeaderMap?.[key] === "object" ? keyToHeaderMap?.[key]?.key : keyToHeaderMap?.[key]}
              </div>
            ))}
          </Box>
        </Box>

        {apiError ? (
          <Box className="api-error">
            API Error: Unable to fetch data.
          </Box>
        ) : tableData?.length === 0 ? (
          <Box className="no-records-found">
            <NoRecordsFound height="100%" />
          </Box>
        ) : (
          <Box className="table-body">
            {tableData?.map((item: any, rowIndex: any) => {
              return (
                <Box key={rowIndex} className="table-row">
                  {Object.keys(keyToHeaderMap).map((key, columnIndex) => (
                    <div
                      title={item[key]}
                      className={`col-body ${columnIndex === 0 ? 'first-column' : ''}`}
                      key={`${rowIndex}-${key}`}
                      onClick={() => {
                        if (columnIndex === 0) {
                          handleClick({ ...item, type });
                        } else {
                          return;
                        }
                      }}
                    >
                      {item[key] ? item[key] : "-"}
                    </div>
                  ))}
                </Box>
              );
            })}
          </Box>
        )}
      </div>
    </TableWrapper>

  );
};

export default DetailsTable;

const TableWrapper = styled.div`
  & {
    // padding: 0px 12px 10px;
    border-radius: 4px;
    width: 100%;
    // height: 100%;
    height: ${(props: any) => props.height};
    border-left: 1px solid ${(props: any) => props.border ? theme.palette.default.border : "transperant"};
    border-right: 1px solid ${(props: any) => props.border ? theme.palette.default.border : "transperant"};
    border-bottom: 1px solid ${(props: any) => props.border ? theme.palette.default.border : "transperant"};
    background-color: ${theme.palette.default.white};

    .no-records-found {
      height: calc(100% - 47px);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div {
      overflow: overlay;
      // height: calc(100vh - ${(props: any) => props.height});
      height: 100%;
    }
    > div:hover {
      overflow: overlay;

        ::-webkit-scrollbar-thumb {
      background-color: rgba(126, 131, 146, 0.5);
      // background-color: rgba(126, 131, 146, 0.5);
      border-radius: 4px;
    }

    }
    > div::-webkit-scrollbar:hover {
      width: 6px;
    }

    > div::-webkit-scrollbar-track {
        background-color: transparent;
    }
    > div::-webkit-scrollbar-thumb {
      background-color: transparent;
      // background-color: rgba(126, 131, 146, 0.5);
      border-radius: 4px;
    }
    .table-header {
      position: sticky;
      top: 0;
      // z-index: 1;
      .table-row {
        display: flex;
        align-items: center;
      }
      .col-head {
        min-width: 100px;
        max-height: 47px;
        text-transform: capitalize;
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        font-weight: 500;
        color: #211C52;
        font-size: 14px;
        display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    vertical-align: top;

        backgroundColor: ${(props: any) => props.headerColor ? "#EFEEFC" : "#fff"} 

        border-bottom: 1px solid ${(props: any) => props.headerColor ? "transparent" : "rgba(126, 131, 146, 0.3)"};
        border-top: 1px solid ${(props: any) => props.headerColor ? "transparent" : "rgba(126, 131, 146, 0.3)"};
        padding: 13px 20px;
        // background-color: ${theme.palette.default.white};
        p {
          font-size: 13px;
          font-weight: 500;
        }
        &:first-child {
          p {
          }
        }
      }
    }
    .table-body {
      .actionColumn {
        border-bottom: 1px solid #E9E8EE;
      }
      .table-row {
        display: flex;
        jsutify-content: center;
        color: ${theme.palette.default.black};
        .first-column {
          cursor: pointer;
        }
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .col-body {
        min-width: 100px;
        font-size: 13px;
        font-weight: 400;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    vertical-align: top;

        text-transform: capitalize;
        div > :first-child {
        }
        flex: 1;
        // display: flex;
        word-break: break-all;
        align-items: center;
        max-height: 53px;
        padding: 13px 20px;
        border-bottom: 1px solid #E9E8EE;
        
        &:first-child {
          p {
            color: #101010;
            text-decoration: none;
            max-width: ${(props: any) => props.firstColTextWidth};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        P {
          font-size: 13px;
        }
      }
    }
  }
`;
