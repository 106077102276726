import { CheckOutlined } from "@material-ui/icons";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Box, Typography } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm, AppFormObserver, AppSubmitButton } from "../../../../AppComponents/Forms";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { EmailSettingsActions } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.actions";
import { domainLists } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.selectors";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import { emailDomainSteps } from "../../../../Store/Slices/email/email.selectors";
import { verifyActiveStep } from "../../../../Store/Slices/email/email.slice";
import RenderFormStep1 from "./Components/RenderFormStep1";
import RenderFormStep2 from "./Components/RenderFormStep2";
import RenderFormStep3 from "./Components/RenderFormStep3";
import RenderFormStep4 from "./Components/RenderFormStep4";
import RenderFormStep6 from "./Components/RenderFormStep6";
import RenderFormSub4 from "./Components/RenderFormSub4";
import RenderFormStep5 from "./Components/RenderFromStep5";
import DomainList from "./DomainLists";
import { ButtonContainer, CustomTimeLine, DomainScroll, DomainSetupPannel, DomainSetupWrapper, DomainSetupsteps, Domainwrapper, VerificationStep } from "./DomainStyles";
import { EmailDefaultValidation, EmailDomainValdiation, EmailVerifyValidation } from "./EmailValidationSchema";

const DomainVerification = (props: any) => {
    const activeStep = useAppSelector(emailDomainSteps)
    const domainsList = useAppSelector(domainLists);
    const dispatch = useAppDispatch();
    const { isShowing, toggle } = useModal();
    const navigate = useNavigate();
    const pathName: string = window.location.pathname
    const [cancel, setCancel] = useState(false)
    const [close, setClose] = useState(true)
    const parameters = new URLSearchParams(window.location.search);

    const [userStep, setUserStep] = useState<any>((
        activeStep && !parameters?.get("view")
            ? Number(activeStep)
            : activeStep && activeStep
                ? Number(activeStep)
                : 1
    ));

    const [formikProps, setFormikProps] = useState<any>(null);
    const { values, setFieldValue }: any = formikProps || {};

    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [userInput, setUserInput] = useState({
        domain: domainsList?.[0]?.domain || "",
        view_options: [],
        spfNameNotfound: "include:_spf.elasticemail.com",
        spfNamefound: "v=spf1 a mx include:_spf.elasticemail.com ~all",
        dkimNameInput: "api._domainkey",
        dkimNameTextArea: "k=rsa;t=s;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbmGbQMzYeMvxwtNQoXN0waGYaciuKx8mtMh5czguT4EZlJXuCt6V+l56mmt3t68FEX5JJ0q4ijG71BGoFRkl87uJi7LrQt1ZZmZCvrEII0YO4mp8sDLXC8g1aUAoi8TJgxq2MJqCaMyj5kAm3Fdy2tzftPCV/lbdiJqmBnWKjtwIDAQAB",
        CnameNameInput: "tracking",
        CnameNameTextArea: "api.elasticemail.com",
        email: "",
        isVerification: false
    })
    const { showAlert } = useNotification();

    useEffect(() => {
        dispatch(EmailSettingsActions.getDomainLists({}));
    }, []) // eslint-disable-line
    useEffect(() => {
        if (domainsList?.length) {
            setUserInput({
                ...userInput,
                domain: domainsList?.[0]?.domain
            })
            // dispatch(verifyActiveStep(3))
            setTimeout(function () {
                props.setLoading(false);
            }, 500)

        } else {
            props.setLoading(false);
        }
    }, [domainsList]) // eslint-disable-line

    useEffect(() => {
        setUserStep(activeStep)
        let url = `/settings/channels/email/${activeStep}`;
        if (window.location.search) {
            url = url + window.location.search
        }
        navigate(url);
    }, [activeStep]) // eslint-disable-line
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number
    ) => {
        setSelectedIndex(index);
    };

    const handleBackClick = (e: any) => {
        e.preventDefault();
        if (activeStep >= 3) {
            dispatch(verifyActiveStep(activeStep - 1))
            setUserStep(activeStep - 1)
        } else {
            setUserInput({
                ...userInput,
                isVerification: false
            })
        }
    };

    const handleSubmitBtn = ((values: any, SubmitProps: any) => {

        if (activeStep === 1 && values?.domain) {
            const data = {
                "domain": values?.domain
            }
            dispatch(EmailActions.postEmailDomainCreate(data))
                .then((res: any) => {
                    if (res?.payload?.data?.status) {
                        dispatch(verifyActiveStep(activeStep + 1))
                    }
                })
                .finally(() => {
                    SubmitProps.setSubmitting(false);
                });
        } else if (activeStep === 2 || activeStep === 4) {
            SubmitProps.setSubmitting(false);
            dispatch(verifyActiveStep(activeStep + 1))
        } else if (activeStep === 3) {
            const data = {
                "domain": values?.domain,
                "view_options": values?.view_options
            }
            dispatch(EmailActions.postEmailDomainVerify(data))
                .then((res: any) => {
                    SubmitProps.setSubmitting(false);
                    if (res?.payload?.data?.status) {
                        toggle(true)
                    } else {
                        showAlert(
                            res?.payload?.data?.error || "Valid SPF not found",
                            "error"
                        );
                        setFieldValue("view_options", "")
                    }
                })
                .catch((error: any) => {
                    showAlert(
                        error?.response?.data.error || "Unable to fetch data",
                        "error"
                    );
                    setFieldValue("view_options", "")
                })
                .finally(() => {

                });

        } else if (activeStep === 5 && values?.email) {
            const data = {
                "email": values?.email
            }
            dispatch(EmailActions.postEmailSetDefault(data))
                .then(() => {
                    setUserInput({
                        ...userInput,
                        isVerification: false
                    })
                })
                .finally(() => {
                    SubmitProps.setSubmitting(false);
                });
        } else {
            SubmitProps.setSubmitting(false);
            dispatch(verifyActiveStep(1));
        }
    })

    const handleCancelClick = () => {
        if (activeStep === 5) {
            setCancel(!cancel)
        }
    }
    const handleVerifyDomain = () => {
        props.setLoading(true);
        const data = {
            "domain": values?.domain,
            "view_options": values?.view_options
        }
        dispatch(EmailActions.postEmailDomainVerify(data))
            .then((res: any) => {
                if (res?.payload?.data?.status) {
                    toggle(true)
                } else {
                    showAlert(
                        res?.payload?.data?.error || "Valid SPF not found",
                        "error"
                    );
                    setFieldValue("view_options", "")
                }
            })
            .catch((error: any) => {
                showAlert(
                    error?.response?.data.error || "Unable to fetch data",
                    "error"
                );
                setFieldValue("view_options", "")
            })
            .finally(() => {
                props.setLoading(false);
            });

    }
    return (
        <DomainSetupWrapper>
            {(domainsList !== null && (!domainsList?.length || (!domainsList?.[0].spf && userInput.isVerification))) ? <DomainScroll><DomainSetupsteps>
                <VerificationStep>
                    <ListItemButton
                        sx={{
                            backgroundColor: pathName?.includes("email") ? "#5B73E8 !important" : null,

                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <div className="circle" style={{ position: "relative" }}>
                            <Box
                                width={40}
                                height={40}
                                borderRadius="50%"
                                bgcolor="white"
                            />
                            {userStep >= "1" ?
                                <CheckOutlined className="check" style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }} />
                                : null
                            }
                        </div>
                        <Box ml={2}>
                            <Typography sx={{ color: pathName?.includes("email") ? "#fff !important" : "#000" }} variant="body2" >Domain you want Verify</Typography>
                        </Box>
                    </ListItemButton>
                    <CustomTimeLine>
                        <TimelineItem>
                            <TimelineSeparator sx={{
                                height: "42px", "& .MuiTimelineConnector-root": {
                                    backgroundColor: userStep >= 2 ? "#5B73E8 !important" : null
                                }
                            }}>
                                <TimelineConnector sx={{

                                }} />
                            </TimelineSeparator>
                        </TimelineItem>
                    </CustomTimeLine>
                </VerificationStep>

                <VerificationStep>
                    <ListItemButton
                        sx={{
                            backgroundColor: userStep >= 2 ? "#5B73E8 !important" : "#fff !important"
                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <div className="circle" style={{ position: "relative" }}>
                            <Box
                                width={40}
                                height={40}
                                borderRadius="50%"
                                bgcolor="white"
                            />
                            {userStep >= "2" ?
                                <CheckOutlined className="check" style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }} />
                                : null
                            }
                        </div>

                        <Box ml={2}>
                            <Typography variant="body2" sx={{ color: userStep >= 2 ? "#fff !important" : "#000" }}>Login To Your Domain Provider</Typography>
                        </Box>
                    </ListItemButton>

                    <CustomTimeLine>
                        <TimelineItem>
                            <TimelineSeparator sx={{
                                height: "42px", "& .MuiTimelineConnector-root": {
                                    backgroundColor: userStep >= 3 ? "#5B73E8 !important" : null
                                }
                            }}>
                                <TimelineConnector />
                            </TimelineSeparator>
                        </TimelineItem>
                    </CustomTimeLine>
                </VerificationStep>

                <VerificationStep>
                    <ListItemButton
                        sx={{
                            backgroundColor: userStep >= "3" ? "#5B73E8 !important" : "#fff!important",

                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <div className="circle" style={{ position: "relative" }}>
                            <Box
                                width={40}
                                height={40}
                                borderRadius="50%"
                                bgcolor="white"
                            />
                            {userStep >= "3" ?
                                <CheckOutlined className="check" style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }} />
                                : null
                            }
                        </div>

                        <Box ml={2}>
                            <Typography sx={{ color: userStep >= 3 ? "#fff !important" : "#000" }} variant="body2">Recommended Add and Verify</Typography>
                        </Box>
                    </ListItemButton>

                    <CustomTimeLine>
                        <TimelineItem>
                            <TimelineSeparator sx={{
                                height: "42px", "& .MuiTimelineConnector-root": {
                                    backgroundColor: userStep >= 4 ? "#5B73E8 !important" : null
                                }
                            }}>
                                <TimelineConnector />
                            </TimelineSeparator>
                        </TimelineItem>
                    </CustomTimeLine>
                </VerificationStep>

                <VerificationStep>
                    <ListItemButton
                        sx={{
                            backgroundColor: userStep >= "4" ? "#5B73E8 !important" : "#fff!important"
                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <div className="circle" style={{ position: "relative" }}>
                            <Box
                                width={40}
                                height={40}
                                borderRadius="50%"
                                bgcolor="white"
                            />
                            {userStep >= "4" ?
                                <CheckOutlined className="check" style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }} />
                                : null
                            }
                        </div>
                        <Box ml={2}>
                            <Typography sx={{ color: userStep >= 4 ? "#fff !important" : "#000" }} variant="body2">Confirmation</Typography>
                        </Box>
                    </ListItemButton>

                    <CustomTimeLine>
                        <TimelineItem>
                            <TimelineSeparator sx={{
                                height: "42px", "& .MuiTimelineConnector-root": {
                                    backgroundColor: userStep >= 5 ? "#5B73E8 !important" : null
                                }
                            }}>
                                <TimelineConnector />
                            </TimelineSeparator>
                        </TimelineItem>
                    </CustomTimeLine>
                </VerificationStep>

                <VerificationStep>
                    <ListItemButton
                        sx={{
                            backgroundColor: userStep >= "5" ? "#5B73E8 !important" : "#fff!important",
                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <div className="circle" style={{ position: "relative" }}>
                            <Box
                                width={40}
                                height={40}
                                borderRadius="50%"
                                bgcolor="white"
                            />
                            {userStep >= "5" ?
                                <CheckOutlined className="check" style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }} />
                                : null
                            }
                        </div>

                        <Box ml={2}>
                            <Typography sx={{ color: userStep >= 5 ? "#fff !important" : "#000" }} variant="body2">Set Default Sender</Typography>
                        </Box>
                    </ListItemButton>

                    <CustomTimeLine>
                        <TimelineItem>
                            <TimelineSeparator sx={{
                                height: "42px", "& .MuiTimelineConnector-root": {
                                    backgroundColor: userStep >= 6 ? "#5B73E8 !important" : null
                                }
                            }}>
                                <TimelineConnector />
                            </TimelineSeparator>
                        </TimelineItem>
                    </CustomTimeLine>
                </VerificationStep>

                <VerificationStep>
                    <ListItemButton
                        sx={{

                            backgroundColor: userStep >= "6" ? "#5B73E8 !important" : "#fff!important"
                        }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <div className="circle" style={{ position: "relative" }}>
                            <Box
                                width={40}
                                height={40}
                                borderRadius="50%"
                                bgcolor="white"
                            />
                            {userStep >= "6" ?
                                <CheckOutlined className="check" style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)"
                                }} />
                                : null
                            }
                        </div>

                        <Box ml={2}>
                            <Typography sx={{ color: userStep >= 6 ? "#fff !important" : "#000" }} variant="body2">Success</Typography>
                        </Box>
                    </ListItemButton>
                </VerificationStep>
            </DomainSetupsteps>

                {/* ============================= Left Side ======================= */}

                <DomainSetupPannel>
                    <AppForm
                        initialValues={userInput}
                        validationSchema={
                            (activeStep === 1 && EmailDomainValdiation) ||
                            (activeStep === 5 && EmailVerifyValidation) ||
                            (activeStep === 3 && EmailDefaultValidation) ||
                            null
                        }
                        onSubmit={(values: any, submitProps: any) => {
                            handleSubmitBtn(values, submitProps);
                        }}>
                        <Domainwrapper style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>

                            {activeStep === 1 && (
                                <RenderFormStep1 setFormStep={setUserStep} formStep={activeStep} />
                            )}

                            {activeStep === 2 && (
                                <RenderFormStep2 formStep={activeStep} />
                            )}
                            {activeStep === 3 && (
                                <RenderFormStep3 formStep={activeStep} />
                            )}
                            {activeStep === 4 && (
                                <RenderFormStep4 formStep={activeStep} />
                            )}
                            {activeStep === 5 && (
                                <RenderFormStep5 formStep={activeStep} />
                            )}
                            {activeStep === 6 && (
                                <RenderFormStep6 formStep={activeStep} />
                            )}
                            <AppFormObserver setFormikProps={setFormikProps} />
                            <ButtonContainer>

                                <div className="buttons">
                                    {activeStep < 5 && activeStep !== 2 &&
                                        <AppButton variant="outline" onClick={handleBackClick}
                                        >Back</AppButton>
                                    }
                                    {activeStep === 3 &&
                                        <AppButton title="" onClick={handleVerifyDomain}>Verify</AppButton>
                                    }
                                    {activeStep <= 2 &&
                                        <AppSubmitButton title="Next" />
                                    }
                                    {activeStep === 4 &&
                                        <AppSubmitButton title="Next" />
                                    }
                                    {activeStep === 5 &&
                                        <AppButton variant="outline" onClick={handleCancelClick}
                                        >Not Right Now</AppButton>
                                    }
                                    {activeStep === 5 &&
                                        <AppSubmitButton title="Set Default Sender" />
                                    }
                                    {activeStep === 6 &&
                                        <AppSubmitButton title="Finish" />
                                    }
                                </div>
                            </ButtonContainer>
                        </Domainwrapper>


                    </AppForm>
                </DomainSetupPannel></DomainScroll>
                : <DomainList setLoading={props.setLoading} setUserInput={setUserInput} userInput={userInput}></DomainList>}
            {
                close === true &&
                <AppModel
                    isShowing={isShowing}
                    onClose={() => toggle()}
                    divStyle={{
                        padding: 0,
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: "none",
                            width: "400px",
                            height: "400px",
                        },
                    }}
                >

                    <RenderFormSub4 setClose={setClose}

                    />

                </AppModel>
            }
            {cancel === true &&
                <AppModel
                    isShowing={isShowing}
                    onClose={() => toggle()}
                    divStyle={{
                        padding: 0,
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: "none",
                            width: "600px",
                            height: "350px",
                        },
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <p style={{ padding: "50px 10px 0px 10px" }}>Setting a default sender increases your deliverability performance</p>
                        <p style={{ padding: "30px 10px 0px 10px" }}>
                            Are you sure you want to complete domain verification without setting the default sender domain? You will be able to set the default sender later.
                        </p>
                        <div style={{
                            display: "flex", flexDirection: "row", gap: "1rem", justifyContent: "flex-end", padding: "100px 20px 10px 10px"
                        }}>
                            <AppButton variant="outline" onClick={() => toggle()}>Cancel</AppButton>
                            <AppButton onClick={() => navigate("/settings/channels/email/1")}>Finish</AppButton>
                        </div>
                    </div>
                </AppModel>
            }



        </DomainSetupWrapper>
    );
};
export default DomainVerification;

