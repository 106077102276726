// vendors
import Box from "@mui/material/Box";

// components
import Icon from "../../Components/Icon";

// styled
import { RightArrowButtonStyled } from "./Carousel.styled";

type Props = {
  onClick: () => void;
};

const AddSlideButton = ({ onClick }: Props) => {
  return (
    <Box
      className={"add-button"}
      sx={{ position: "absolute", right: 0, top: "50%" }}
    >
      <RightArrowButtonStyled
        onClick={onClick}
        sx={{
          "& svg": {
            transform: "rotate(180deg)",
          },
        }}
      >
        <Icon icon="add" size={12} />
      </RightArrowButtonStyled>
    </Box>
  );
};
export default AddSlideButton;
