import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import AppRangeDatePicker from "../../AppComponents/AppRangeDatePicker";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";
import { Box, MenuItem, Select } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import { DashboardNetworkService } from "../../Store/Slices/DashboardRedux/DashboardNetworkService";
import {
  BotID,
  BotType,
  removeDashboard,
  setBotDashboardDateRange,
} from "../../Store/Slices/DashboardRedux/dashboard.slice";
import {
  botDateRange,
  BotList,
  BotTypeName,
} from "../../Store/Slices/DashboardRedux/dashboard.selectors";
import { DashboardActions } from "../../Store/Slices/DashboardRedux/dashboard.actions";

interface MenubarProps {
  theme?: any;
}

const BotTopMenu = (props: MenubarProps) => {
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(botDateRange);
  const selectedBotId: any = useAppSelector(BotList);
  const [BotListArray, setBotList] = useState<any>([]);
  const botType = useAppSelector(BotTypeName);

  const onChange = (date: any) => {
    dispatch(setBotDashboardDateRange(date));
  };
  const [localValues, setLocalValues] = useState<any>({
    bot: selectedBotId || "",
  });

  const getBotList = () => {
    DashboardNetworkService.getBotList().then((res: any) => {
      setBotList(res?.data?.bot_list || []);
    });
  };

  const HandleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });

    if (field === "bot") {
      dispatch(BotID(e.target.value));
      let botType: string = BotListArray.find(
        (ele: any) => ele.bot_id === e.target.value,
      )?.bot_type;
      dispatch(DashboardActions.getProjectVersion(e.target.value))
        .then((res: any) => {
          dispatch(
            DashboardActions.getMatomoDetails(
              res.payload.data.project_version_id,
            ),
          ).then((res: any) => {});
        })
        .catch((error: any) => {});

      dispatch(BotType(botType));
    }
  };

  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: "",
    });
    dispatch(BotID(""));
    let bot: any = "";
    dispatch(BotType(bot));
  };

  useEffect(() => {
    getBotList();

    return () => {
      dispatch(removeDashboard("botType"));
    };
  }, []);

  const renderSelectIcon = localValues.bot ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  return (
    <MenubarWrap>
      <MenubarLeftBox>
        <Box className="subheader">Bot</Box>
      </MenubarLeftBox>

      <MenubarRightBox>
        <div>
          <NewNativeSelect>
            <Select
              defaultValue={undefined}
              name="bot"
              value={localValues?.bot}
              onChange={(e) => HandleSelectChange(e, "bot")}
              displayEmpty
              className="new-select-main"
            >
              <MenuItem value="" disabled style={{ fontSize: "13px" }}>
                Select Bot
              </MenuItem>
              {BotListArray?.map((item: any, index: number) => {
                return (
                  <MenuItem
                    value={item?.bot_id}
                    key={index}
                    style={{ fontSize: "13px" }}
                  >
                    {item?.name}
                  </MenuItem>
                );
              })}
            </Select>
            <div
              className="clear-icon"
              onClick={(e: any) => clearCallback(e, "bot")}
            >
              {renderSelectIcon}
            </div>
          </NewNativeSelect>
        </div>
        <DateBox>
          <AppRangeDatePicker
            dateRange={dashdateRange}
            setDateRange={onChange}
            // hideTodayOption={true}
          />
        </DateBox>
      </MenubarRightBox>
    </MenubarWrap>
  );
};

export default BotTopMenu;

export const MenubarWrap = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    z-index: 1;
    height: 4rem;
    width: ${(props: any) => (props?.fullWidth ? `100%` : "calc(100% - 2px)")};
    padding: 0 1rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenubarRightBox = styled.div`
&& { 
    gap: 2rem;
    align-items: center;
    display: flex;
    float:right;
    text-align:right;
    margin-left: auto;
    .new-select-main{
        width:165px !important;
        .MuiSelect-select {
            padding-left:10px;
            text-align:left;
        }
    }
    
`;

export const MenubarLeftBox = styled.div`
  && {
    gap: 2rem;
    align-items: center;
    display: flex;
    float: left;
    text-align: left;

    .subheader {
      color: #101010;
      font-size: 13px !important;
      font-family: Inter;
      font-weight: 500;
      padding: 18px 6px;
    }
  }
`;

export const SelectBox = styled.select`
  border: none;
  outline: none;
  background-color: white;
  font-size: 13px !important;
  color: blue;
`;

export const OptionTag = styled.option`
  border: none;
  outline: none;
  background-color: white;
  font-size: 13px !important;
  color: blue;
`;

export const DateBox = styled.div`
  color: blue;
`;

export const NewNativeSelect = styled.div`
  position: relative;
  width: 100%;
  &.datePicker {
    > div > div:first-child {
      padding: 0;
      height: 39px;
    }
  }
  .mb0 {
    margin-bottom: 0px !important;
  }
  .new-select-main {
    max-width: 210px;
    min-width: 151px;
    width: 165px;
    height: 35px;
    background-color: #f5f6f8;
    border: 1px solid rgb(203, 205, 211);
    border-radius: 4px;

    .MuiSelect-select {
      padding: 5px;
      font-size: 13px !important;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
  }

  .new-select-main > MenuItem {
    font-size: 13px !important;
  }

  .clear-icon {
    background-color: ${theme.palette.default.lightGrey};
    position: absolute;
    top: 1px;
    right: 10px;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
