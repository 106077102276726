import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import MessageLogo from "../Dash_Assests/avgmsgperconversationbot.svg";
import HandedToAgent from "../Dash_Assests/conversationhandedtoagent.svg";
import HandledByBot from "../Dash_Assests/totalconversationbot.svg";

import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import {
  BotList,
  botDateRange,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { getRoundOffNumbers } from "../../../Utils";
import { SkeletonCard } from "../SkeletonLoader.tsx/SkeletonCard";
import { TotalTable } from "./BotStyle";
interface Props {}

const TotalConversation: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>([]);
  const dashdateRange: any = useAppSelector(botDateRange);
  const botList = useAppSelector(BotList);
  const [loading, setLoading] = useState(false);

  // Total Conversation API call
  const getConversationStatus = () => {
    setLoading(true);

    let payload4: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    if (botList.length > 0) {
      payload4 = {
        ...payload4,
        bot_id: botList,
      };
    }
    dispatch(DashboardActions.getAgentCoversationStatus(payload4))
      .then((res) => {
        setData(res.payload.data || []);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (dashdateRange?.startDate) {
      getConversationStatus();
    }
  }, [dashdateRange, botList]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <TotalTable>
      <div className="totalConversation">
        <p className="avgMessages_text">
          Total Conversation
          <br />
          Handled By BOT
          <AppToolTip
            tooltipProps={{
              title: "Bot Conversations Distribution Metrics",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </p>
        <div>
          <div className="conversationInfo">
            <p className="conversationCount">
              {" "}
              {loading ? (
                <SkeletonCard />
              ) : (
                getRoundOffNumbers(data?.bot_conversations?.total) || 0
              )}
            </p>
          </div>
        </div>
      </div>

      <div className="parent-img">
        <img src={HandledByBot} alt="" className="image-bot" />
      </div>

      <hr className="dotted_hr"></hr>

      <div className="totalConversation">
        <p className="avgMessages_text">
          Total Conversation
          <br />
          Handed To Agent
          <AppToolTip
            tooltipProps={{
              title: "Bot Conversations Distribution Metrics",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </p>
        <div className="conversationInfo">
          <p className="conversationCount">
            {loading ? (
              <SkeletonCard />
            ) : (
              getRoundOffNumbers(data?.agent_conversations?.total) || 0
            )}
          </p>
        </div>
      </div>
      <div className="parent-img">
        <img src={HandedToAgent} alt="" className="image-bot" />
      </div>
      <hr className="dotted_hr"></hr>

      <div className="totalConversation">
        <p className="avgMessages_text">
          Average Messages
          <br />
          per Conversation
          <AppToolTip
            tooltipProps={{
              title: "Bot Conversations Distribution Metrics",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </p>
        <div className="avgMessagesInformation">
          <p className="avgMessagesCount">
            {loading ? (
              <SkeletonCard />
            ) : (
              getRoundOffNumbers(
                data?.bot_conversations?.total_messages /
                  data?.bot_conversations?.total
              ) || 0
            )}
          </p>
        </div>

        <div className="avgMessagesIcon">
          <img src={MessageLogo} alt="Message Icon" />
        </div>
      </div>
    </TotalTable>
  );
};

export default TotalConversation;
