const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const SingleMenuProps = {
  PaperProps: {
    className: "ignore-onClickOutside",
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      boxShadow: "0px 0px 10px 2px rgba(0, 0, 0, 0.1) !important",
      borderRadius: "8px",
      marginTop: "10px",
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top" as "top",
    horizontal: "center",
  },
  variant: "menu" as "menu",
};