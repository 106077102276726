import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class ApplicationFormNetworkService {
  static getApplicationFormsList(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ALL_APPLICATION_LIST}`,
      payload
    );
  }
  static getFormProperties(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_FORM_PROPERTIES}`,
      payload
    );
  }
  static getApplicationFieldsMapping() {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_APPLICATIONS_FIELDS_MAPPING}`
    )
  }

  // Get Attachments Details
  static getAttachmentNetwork(payload: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_ATTACHMENTS}/${payload}`,
    );
  }

  // Mark Payment Offline
  static markPaymentOfflineNetwork(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.MARK_PAYMENT_OFFLINE}/${payload?.applicationResID?.id}`,
      payload?.payloadData
    );
  }

  // Get Payment Details
  static getPaymentDetailsNetwork(payload: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_PAYMENT_DETAILS}/${payload}`,
    );
  }

  // Change Status To Draft
  static changeStatusToDraftNetwork(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.CHANGE_STATUS_TO_DRAFT}/${payload}`,
    );
  }

  // Mark As Reviewed
  static markAsReviewedNetwork(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.MARK_AS_REVIEWED}/${payload?.id}`,
      payload?.data
    );
  }
  // Marked As Not Reviewed
  static markedAsNotReviewedNetwork(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.MARKED_AS_NOT_REVIEWED}/${payload?.id}`,
      payload?.data
    );
  }
  // Update Attachment Status
  static updateAttachmentStatusNetwork(payload: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_ATTACHMENT_STATUS}/${payload?.id}`,
      payload?.data
    );
  }
  // Get Application Form Pdf
  static getApplicationFormPdf(id: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_APPLICATION_FORM_PDF}/${id}`,
    );
  }
  //bulkApplicationsExport
  static bulkApplicationsExport(payload: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BULK_APPLICATIONS_EXPORT}`, payload
    );
  }
  //get application property options
  static getApplicationPropertyOptions(optionId: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_APPLICATIONS_OPTIONS}/field/${optionId}/options`,
    );
  }
}

class Endpoints {
  static GET_ALL_APPLICATION_LIST = "/workspace/get-generic-application-view";
  static GET_FORM_PROPERTIES = "/workspace/module-properties/fields";
  static GET_APPLICATIONS_FIELDS_MAPPING = "/workspace/broadcast/application-view/fields_type_mapping_list"
  static GET_ATTACHMENTS = "/workspace/application-response/get-attachments";
  // Payment Offline
  static MARK_PAYMENT_OFFLINE = "/workspace/application-response/mark-payment-offline"
  static GET_APPLICATIONS_OPTIONS = "/workspace/module-properties/application";
  // Payment Offline
  static GET_PAYMENT_DETAILS = "/workspace/application-response/get-payment-details"

  // Chnage Status To Draft
  static CHANGE_STATUS_TO_DRAFT = "/workspace/application-response/change-status-to-draft"

  static MARK_AS_REVIEWED = "/workspace/mark-application-reviewed"
  static MARKED_AS_NOT_REVIEWED = "/workspace/mark-application-not-reviewed"
  static UPDATE_ATTACHMENT_STATUS = "/workspace/application-response/update-attachment-status"
  static GET_APPLICATION_FORM_PDF = "/workspace/application-response/print-pdf"
  static BULK_APPLICATIONS_EXPORT = "/workspace/get-generic-application-view/export"
}