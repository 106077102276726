import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ProtectedAccessDeniedPage } from "../../AppComponents/ProtectedComponents";
import { device } from "../../Customization/BreakPoints";
import { theme } from "../../Customization/Theme";
import { useAppSelector } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { formatString, isEmptyObject } from "../../Utils";
import AnalysisDashboardIcon from "../../assets/images/AnalysisDashboardIcon.svg";
import AutomationBuilderIcon from "../../assets/images/AutomationBuilderIcon.svg";
import BotBuilderSettingsIcon from "../../assets/images/BotBuilderSettingsIcon.svg";
import BroadcastIcon from "../../assets/images/BroadcastIcon.svg";
import ContactIcon from "../../assets/images/ContactIcon.svg";
import IndexModuleIcon from "../../assets/images/IndexModuleIcon.svg";
import { ReactComponent as ThumbsUp } from "../../assets/images/ThumbsUp.svg";
import { ReactComponent as NextArrow } from "../../assets/images/WelcomeScreenArrow.svg";
import WelcomeScreenIcon from "../../assets/images/WelcomeScreenIcon.svg";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { useEffect } from "react";

const HOMEPAGE_DATA = [
  {
    title: "Analysis Dashboard",
    description:
      "Gain valuable insights with our Analytics Dashboard. Track performance, measure success, and make data-driven decisions to propel your business forward.",
    icon: AnalysisDashboardIcon,
    moduleId: "analytics",
  },
  {
    title: "Inbox Module",
    description:
      "Streamline communication with the Inbox Module. Stay on top of messages and collaborations, ensuring efficient and prompt responses.",
    icon: IndexModuleIcon,
    moduleId: "conversation_inbox",
  },
  {
    title: "Bot Builder",
    description:
      "Craft personalized interactions with our Bot Builder Settings. Tailor your chatbots to meet your unique needs and enhance user experiences effortlessly.",
    icon: BotBuilderSettingsIcon,
    moduleId: "bot_builder",
  },
  {
    title: "Contacts",
    description:
      "Effortlessly manage your contacts with our Contacts Module. Keep your connections organized and accessible in just a few clicks",
    icon: ContactIcon,
    moduleId: "contacts",
  },
  {
    title: "Broadcasting Feature",
    description:
      "Amplify your reach using Broadcasting. Easily send targeted messages, updates, and announcements to your audience, enhancing your engagement strategy.",
    icon: BroadcastIcon,
    moduleId: "broadcast",
  },
  {
    title: "Automation Builder",
    description:
      "Boost productivity with our Automation Builder. Automate repetitive tasks, saving time and resources for more strategic initiatives.",
    icon: AutomationBuilderIcon,
    moduleId: "automation",
  },
];

interface Props extends LoaderComponentProps {}

const Homepage = (props:Props): JSX.Element => {
  const navigate = useNavigate();
  const { userConfig }: any = useAppSelector(useAuthStore);
  const permissionsData: any = userConfig?.payload?.allowed_permissions ?? null;
  const { data: authData } = useAppSelector(useAuthStore);
  const leftMenuOptions =
    userConfig?.payload?.menu_items?.left_top_menu_options ?? [];

  const handleCardClick = (curElem: any) => {
    if (curElem?.value === "setup") {
      const subUrl = `/workspace/{0}/setup/bots`;
      const workspace_id: string = window.localStorage.getItem("ws_id") || "";
      const url = formatString(subUrl, [workspace_id]);
      navigate(url);
      return;
    }

    const subUrl = curElem?.url;
    navigate(subUrl);
  };

  useEffect(() => {
    if (userConfig?.payload) {
      props.setLoading(false);
    }
  },[]) //eslint-disable-line react-hooks/exhaustive-deps

  if (
    authData?.data?.user &&
    userConfig?.payload?.subscription_id &&
    isEmptyObject(permissionsData)
  ) {
    return <ProtectedAccessDeniedPage />;
  }

  return (
    <Container>
      <LeftSection>
        <Title>Welcome, {authData?.data?.user?.first_name}</Title>
        <Paragraph>
          Get familiar with Geta, here are some ways to get started.
        </Paragraph>
        <CardWrapper>
          {leftMenuOptions?.map((item: any, index: number) => {
            const currentModuleItem: any = HOMEPAGE_DATA.find(
              (data: any) => data?.moduleId === item?.moduleId
            );
            if (!currentModuleItem) return null;

            return (
              <CardContainer
                key={index}
                index={index}
                onClick={() => handleCardClick(item)}
              >
                <div className="arrow_container">
                  <NextArrow />
                </div>
                <Img
                  src={currentModuleItem?.icon}
                  alt={currentModuleItem?.title}
                />
                <CardContainerTitle>
                  {currentModuleItem?.title}
                </CardContainerTitle>
                <CardContainerDescription>
                  {currentModuleItem?.description}
                </CardContainerDescription>
              </CardContainer>
            );
          })}
        </CardWrapper>
        <Highlight>
          <ThumbsUp />
          <div className="text_container">
            <p className="title">Ready to elevate your experience Geta?</p>
            <p className="text">
              Dive into each module and unleash the full potential of ours SaaS
              solution. Your success begins here!
            </p>
          </div>
        </Highlight>
      </LeftSection>
      <RightSection>
        <IconImage src={WelcomeScreenIcon} alt="WelcomeScreenIcon" />
      </RightSection>
    </Container>
  );
};

export default withLoader(Homepage);

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  padding: 4rem;
  gap: 30px;
  display: flex;
  justify-content:space-between;
  @media ${device.laptop} {
    flex-direction: column;
    padding: 2rem;
  }
`;
const LeftSection = styled.div`
  width: 75%;
`;
const RightSection = styled.div`
  // width: calc(100vw - 1070px);
  @media ${device.laptopL} {
    text-align: center;
  }
`;
const Title = styled.p`
  font-size: 1.75rem;
  font-weight: 600;
  @media ${device.laptopL} {
    font-size: 1.50rem;
  }
  @media ${device.laptop2} {
    font-size: 1.25rem;
  }
`;
const CardWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;
const CardContainer = styled.div`
  position: relative;
  padding: 0.8rem;
  width: 354px;
  height: 200px;
  border-radius: 4px;
  border: 1px solid #7e839233;
  box-shadow: none;
  @media ${device.laptopL} {
    padding: 0.5rem;
    width: 300px;
    height: 170px;
  }
  @media ${device.laptop2} {
    padding: 0.5rem;
    width: 280px;
    height: 135px;
  }

  .arrow_container {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  &:hover {
    box-shadow: 0px 0px 20px 0px #5b73e84d;
    transform: scale(1.05);
    .arrow_container {
      transform: rotate(-30deg);
    }
    svg path {
      fill: ${theme.palette.default.white};
    }
    svg circle {
      fill: ${theme.palette.primary.dark};
    }
  }
`;

const CardContainerTitle = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  color: ${theme.palette.default.black};
  @media ${device.laptopM} {
    font-size: 0.7rem;
  }
`;
const CardContainerDescription = styled.p`
  color: ${theme.palette.default.darkGrey};
  font-size: 13px;
  @media ${device.laptopM} {
    font-size: 0.6rem;
  }
`;

const Img = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
  @media ${device.laptopL} {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
`;

const IconImage = styled.img`
  width: 420px;
  height:420px;
  object-fit:contain;
  @media ${device.laptopL} {
    width: 350px;
    height: 350px;
    object-fit: contain;
  }
`;

const Paragraph = styled.p`
  font-size: 14px;
`;

const Highlight = styled.div`
  display: flex;
  background: linear-gradient(94.26deg, #0965ff 1.09%, #d705ff 101.89%);
  height: 100px;
  margin-top: 2rem;
  width: 600px;
  gap: 20px;
  padding: 1rem;
  border-radius: 8px;
  @media ${device.laptopL} {
    width: 500px;
    gap: 15px;
    height: 80px;
    padding: 0.5rem;
  }
  .text_container {
    .title {
      color: ${theme.palette.default.white};
      font-size: 18px;
      font-weight: 600;
      @media ${device.laptopL} {
        font-size: 14px;
      }
    }
    .text {
      color: ${theme.palette.default.white};
      font-size: 14px;
      @media ${device.laptopL} {
        font-size: 11px;
      } 
    }
  }
`;
