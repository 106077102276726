import { useState } from "react";
import { AppForm } from "../../../../../AppComponents/Forms";
import AppButton from "../../../../../AppComponents/AppButton";

const EditBounceDomain = (props: any) => {
    const [userInput, setUserInput] = useState<any>({
        settingsType: true,
    });
    const selectedDomain = props.selectedDomain;
    const handleSubmit = (values: any, submittingProps: any) => {

    }
    return <div className="domainBounceDetails">
        <h3>{selectedDomain.domain}</h3>
        <AppForm
            initialValues={userInput}
            onSubmit={handleSubmit}
        >
            <div className="bounceFooter">
                <AppButton onClick={() => props.setEditBounceDomain(false)} variant="grey">Back</AppButton>
            </div>
        </AppForm>

    </div>
}

export default EditBounceDomain;