import styled from "styled-components";
import { theme } from "../../Customization/Theme";

export const MultiListWrapper = styled.div`
  position: relative;
  width: 100%;
  .items-list {
    display: flex;
    align-items: flex-start;
  }
  .tagShape {
    background-color: #ebeff3;
    border-radius: 4px;
    display: inline-block;
    line-height: 18px;
    margin-right: 5px;
    max-width: 100%;
    min-width: 15px;
    overflow-x: hidden;
    padding: 2.5px 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .viewMore {
    background-color: #e5f2fd;
    border-radius: 4px;
  }
  .hoverWrapper {
    position: relative;
    &:hover .hover-card-container {
      display: block;
    }
  }
  .hover-card-container {
    position: absolute;
    bottom: 0px;
    right: 0px;
    min-width: max-content;
    will-change: transform;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 5px 0 rgba(39, 49, 58, 0.15);
    max-width: 350px;
    max-height: 200px;
    padding: 10px;
    overflow: auto;
    display: none;
  }
  .hover-card-title {
    font-weight: 500;
  }
  .hover-card-items-list {
    max-width: 200px;
    flex-wrap: wrap;
    display: flex;
    gap: 5px;
  }
`;
export const ContactWrapper = styled.div`
  /* background-color: #5B73E8; */
  /* padding: 2rem; */
  overflow: hidden;
  width: 100%;
  height: 100%;

  .leadsPageWrapper {
    background: #f5f6f8;
    padding-left: 24px;
    padding-right: 24px;
  }
  .header {
    display: flex;
    align-items: center;
    padding-top: 38px;
    padding-bottom: 21px;
  }
  .btn {
    background: none;
    border: none;
  }
  .btn svg {
    width: 12px;
    height: 7px;
  }
  .header h3 {
    color: #101010;
    margin-left: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .userprofile-main-div {
    display: flex;
    gap: 8px;
    min-height: calc(100vh - 18.3vh);
    align-items: stretch;
  }
  .side-bar {
    width: 20%;
    max-width: 272px;
    min-width: 272px;
    background-color: white;
  }
  .side-bar li {
    margin: 8px;
  }
  .side-bar ul {
    list-style-type: none;
  }
  .side-bar li a {
    display: block;
    padding: 8px 0 8px 12px;
  }
  li a.list-link {
    display: flex;
    justify-content: space-between;
  }

  .list-link .img {
    padding-right: 14px;
  }

  .side-bar a {
    text-decoration: none;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #7e8392;
    border-radius: 4px;
  }
  .side-bar li a:hover,
  .side-bar li a.active {
    color: white;
    background: #5b73e8;
  }
`;

export const LeadPageWrapper = styled.div`
  && {
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    border-radius: 0px 0px 4px 4px;
    min-height: calc(100vh - 4rem);
    .webtrack-wrapper {
      padding: 2rem;
      overflow: scroll;
    }

    .user-profile {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .top-bar {
      padding: 16px;
      background: #ffffff;
      border-radius: 4px 4px 0px 0px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid lightGrey;
    }

    .userprofile-details {
      display: flex;
      // gap:8px;
    }

    .avtar {
      background: none;
      border: none;
      width: 64px;
      height: 64px;
      //    margin:16px;
    }

    .avtar svg {
      width: 64px;
      height: 64px;
    }

    .user-name {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #101010;
      padding-top: 4px;
      padding-left: 28px;
    }

    .assign-user {
      display: flex;
      flex-direction: column;
      justify-content: end;
    }

    .assign-name {
      display: flex;
      align-items: center;
      gap: 21px;
      align-items: center;
      // padding-top:20px;
    }

    .user-icons-button {
      display: flex;
      align-items: end;
      gap: 24px;
    }
    .user-icons-button button {
      padding: 0px;
    }
    .assign-name button {
      background: none;
      border: none;
    }

    .icon-details {
      display: flex;
      align-items: center;
      gap: 7px;
    }

    .assign-name p {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #7e8392;
    }

    .assign-name svg {
      width: 17px;
      height: 17px;
    }

    .icons-listing-name {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #101010;
    }

    .icons-listing {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: #7e8392;
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .icons-listing h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #101010;
    }

    .svg-icon {
      // width:19px;
      // height:19px;
    }

    .list-button {
      background: none;
      border: none;
      padding-top: 3px;
    }

    .list-button svg {
      width: 17px;
      height: 17px;
    }

    .social-media-list {
      display: flex;
      align-items: center;
      gap: 13px;
      padding-top: 10px;
    }

    .social-media-list a {
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 50%;
    }

    .social-media-list a:hover {
      background-color: #f0f0f0;
    }

    .social-media-list button.edit-btn {
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 50%;
    }

    .social-media-list button.edit-btn:hover {
      background-color: #f0f0f0;
    }

    .edit-btn {
      border: none;
      background: none;
      // padding-left: 5px;
      // padding-right: 5px;
      border-radius: 50%;
    }

    .edit-btn svg {
      width: 18px;
    }
  }

  .body-data {
    height: 500px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .data-row_1 {
      // border: 1px solid;
      display: flex;
      gap: 8px;

      .summary {
        width: 640px;

        padding: 16px;
        background: #ffffff;
        border-right: 1px solid #f5f6f8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
      }

      .first-visit {
        width: 438px;
        padding: 16px;
        background: #ffffff;
        border-right: 1px solid #f5f6f8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
      }

      .first-visit p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #7e8392;
        margin-top: 4px;
      }

      h3 {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px !important;
        text-transform: capitalize !important;
        color: #101010 !important;
        // padding-bottom: 12px !important;
      }

      .summary p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #7e8392;
        margin-top: 12px;
      }
    }

    .data-row_2 {
      background: #f5f6f8;
      display: flex;
      gap: 8px;

      .box-one {
        padding-left: 16px;
        width: 377px;
        didisplay: flex;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
      }

      .unverified {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #f34e4e;
      }

      .basic-name-info {
        display: flex;
        gap: 6px;
        margin-bottom: 16px;
      }

      .basic-details {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .first-last-name-deatail {
        display: flex;
        gap: 4rem;
      }
    }

    .basic-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding-bottom: 16px;
    }

    .basic-info p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: capitalize;
      color: #101010;
    }
    .basic-info button {
      height: 37px;
    }
    .basic-info svg {
      width: 17px;
    }

    .name-info {
      display: flex;
      justify-content: space-between;
    }
  }

  .second-para {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
  }

  .main-info-div {
    display: flex;
    flex-direction: column;
    // gap: 16px;
  }

  .box-two {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 377px;
    padding-left: 16px;
  }

  .inside-box-two {
    display: flex;
    flex-direction: column;
    // column-gap: 1.2rem;
    padding-top: 25px;
  }

  .inside-box-two p {
    // padding-left: 27px;
  }

  .box-four {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 377px;
    padding-left: 16px;
  }

  .inside-box-four {
    display: flex;
    flex-direction: column;
    column-gap: 1.2rem;
    padding-top: 40px;
  }
  .para-box-four {
    margin-bottom: 16px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
  }

  .box-three {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    width: 377px;
    padding-left: 16px;
    padding-right: 16px;
    textarea {
      height: 74px;
    }
  }

  .inside-box-three {
    display: flex;
    flex-direction: column;
    column-gap: 1.2rem;
    padding-top: 40px;
  }
  .para-box-three {
    margin-bottom: 16px;
  }

  label {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #7e8392 !important;
  }

  .box-information {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #7e8392;
    margin-bottom: 16px;
  }

  .box-information p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
    padding-left: 25px;
  }

  .tag-field {
    display: flex;
    gap: 8px;
    height: 37px;

    button {
      height: 37px;
    }
  }

  .tag-field input[type="text"] {
    width: 239px;
    padding: 8px 0 8px 12px;
    background: #f5f6f8;
    border-radius: 4px;
    border: none;
  }
  .remove-tag {
    display: flex;
    gap: 8px;
    padding-top: 8px;
  }

  // .remove-tag p{

  .tag-span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #5b73e8;
    background: #eef1ff;
    border-radius: 99px;
    height: 29px;
    padding: 4px 0 4px 8px;
    display: flex;
    align-items: center;
  }
  // }
  .tag-field button {
    width: 104px;
    padding: 0px;
    gap: 0px;
    color: #5b73e8;
    height: 37px;
  }

  .communication-stats {
    padding: 16px;
  }

  .box-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
  }
  .summary-boxes {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .summary-inner-box {
    border: 1px solid #c1ccff;
    background: #f1f4ff;
    border-radius: 8px;
    padding: 24px;
    width: 243px;
    height: 109px;
  }
  .box-text {
    display: flex;
    gap: 5px;
    // align-items: end;
  }

  .heading-text h3 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
  }
  .summary-number {
    font-weight: 500;
    font-size: 24px;
    line-height: 100%;
    color: #101010;
    padding-top: 16px;
  }

  .source-details {
    padding: 16px;
    // display: flex;
  }

  .source-details h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
    padding-bottom: 16px;
  }
  .system-summary-activities {
    display: flex;
    padding: 16px;
  }

  .system-info {
    width: 766px;
  }
  .Summary-of-activities {
    width: 766px;
  }
  h2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
    margin-bottom: 16px;
  }
  .second-text {
    margin-bottom: 16px;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392 !important;
  }
  .second-text-div {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
    margin-bottom: 10px;
  }
  .input {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 634px;
  }
  .input input,
  select {
    background: #f5f6f8;
    border-radius: 4px;
    border: none;
    height: 37px;
    // margin-bottom:16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
    padding: 8px 0 8px 12px;
  }
  .contact-more-information {
    display: flex;
  }
  .Left-side-info {
    width: 766px;
  }
  .right-side-info {
    width: 766px;
    padding-top: 40px;
  }
  .web {
    color: red !important;
  }

  .web-option {
    color: #7e8392;
  }

  .summary-activities-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  .activities {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #101010 !important;
  }

  .activities-schedule {
    min-width: 375px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-transform: capitalize;
    color: #7e8392;
  }

  .contact-user-timeline {
    padding: 16px;
  }

  .contact-user-timeline h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
    margin-bottom: 40px;
  }

  .timeline-icons {
    display: flex;
    gap: 16px;
    -webkit-box-align: center;
    align-items: center;
    border-left: 1px solid rgb(203, 205, 211);
    margin-left: 16px;
    & .MuiTimelineItem-root::before {
      padding: 0 !important;
    }

    & .MuiTimeline-root {
      padding: 0 !important;
    }
    & .MuiTimelineItem-root {
      /* margin-left: -16px; */
      /* box-shadow:0 0 0 6px #fff; */
    }
    & .MuiTimeline-root {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 1);
      margin-left: -16px;
    }
    /* &.timeline-icons:last-child {
		border: none;
	} */
  }

  .timezone {
    border: 1px solid #f5f6f8;
    border-radius: 4px;
    padding: 8px 16px;
    width: 100%;
    margin-bottom: 16px;
  }
  .user-time-date {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .date {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #6c6c6c;
  }
  .time {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
  }
  .timeline-span {
    color: #5b73e8;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    pointer: cursor;
  }
  .timeline-icons p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
    padding-top: 4px;
  }

  .name {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
  }
  .updated-time {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
  }

  .mark-complete {
    /* padding: 1rem; */
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .mark-complete-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    padding-left: 16px;
  }
  .mark-complete-text-class {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #323232;
    /* padding-left: 16px; */
  }
  .mark-btn button {
    background: none;
    border: none;
  }
  .mark-complete-close-icon {
    color: red;
  }
  .mark-complete-buttons {
    display: flex;
    gap: 16px;
    justify-content: flex-end;
    /* margin-top: 28%; */
  }
  .mark-popup-left-side {
    width: 671px;
  }
  .mark-popup-right-side {
    width: 303px;
  }
  .date-time-picker {
    display: flex;
    gap: 8px;
    align-items: end;

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      padding: 0;
    }
  }
  .input-add-task {
    width: 246px;
  }
  .cancle-save-buttons {
    display: flex;
    gap: 16px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    padding: 1rem;
  }
  .upload-data {
    padding: 4rem 0rem;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    border: 1px dashed #cbcdd3;
  }
  .text {
    color: #7e8392;
    font-size: 16px;
    text-transform: capitalize;
  }
  .text a {
    color: #5b73e8;
    font-size: 16px;
    text-transform: capitalize;
    text-decoration: none;
  }
  .upload-data p {
    padding-top: 16px;
  }

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 10px;
  }
  .btn {
    border: none;
    background: none;
  }
  .popup-heading-text h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #323232;
  }
  .popup-heading-icon svg {
    width: 12px;
    height: 12px;
    color: red;
  }
  .fname {
    display: flex;
    gap: 16px;
  }
  .verify-email-button {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .email-verification {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .input-field-email {
    width: 80%;
  }
  .verify-email-button {
    padding-top: 19px;
  }
  .assigned-to-popup-input {
    padding-top: 15px;
  }
  .input-add-task {
    width: 236x;
  }
  .popup-addtask {
    display: flex;
    gap: 16px;
  }
  .task-tag {
    width: 185px;
    padding: 8px 8px;
    color: #7e8392;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #cbcdd3;
  }
  .task-tag-button {
    padding-left: 20px;
  }
  .webtrack-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 2rem;
    overflow: scroll;
    height: 489px;
    & .MuiAccordion-root {
      margin: 0px !important;
    }
  }
  .web-track {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding-top: 16px;
    padding-left: 8px;
    color: #101010;
    text-transform: capitalize;
  }
  .icon-details p {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #7e8392;
  }
  .icon-details-svg svg {
    width: 14px;
  }
  .icon-details-svg {
    display: flex;
    gap: 6px;
    align-items: end;
  }

  .web-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
  }
  .web-paragraph {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #101010 !important;
  }
  .web-anchor-tag {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #5b73e8 !important;
    text-decoration: none !important;
  }
  .background {
    background: white;
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    margin: 0;
  }
  .accordition-body-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #101010;
  }
  .action {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
  }
  .accordion-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .header-right {
    display: flex;
    gap: 20px;
  }
  .icon-listing {
    display: flex;
    gap: 17px;
    align-items: flex-end;
  }
  .css-yw020d-MuiAccordionSummary-expandIconWrapper {
    transform: rotate(90deg);
  }

  .action-date-time {
    display: flex;
    gap: 20px;
    padding-right: 40px;
  }
  .visit-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #5b73e8;
  }
  .file-icon {
    padding-bottom: 40px;
    margin-left: -12px;
    box-shadow: 0 0 0 6px #fff;
  }
  .contact-file {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .file-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
  }
  .file-right-side {
    display: flex;
    gap: 40px;
    align-items: center;
  }
  .update-text p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
  }
  .file-span {
    padding-left: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7e8392;
  }
  .contact-task {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }
  .task-buttons {
    display: flex;
  }
  .task-button-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #7e8392;
    padding-left: 8px;
  }
  .app-button-class {
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #101010;
  }

  .task-heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
  }
`;

export const LeadDetailWrapper = styled.div`
  border-bottom: 1px solid #f5f6f8;
  && {
    width: 80%;
  }
`;

//   export const ContactTag = styled.div`
// & {

// }
// `

export const MessagesTable = styled.div`
  height: 100%;
  .table-header {
    display: flex;
    padding-left: 16px;
    padding: 1rem;
    /* border-bottom: 1px solid ${theme.palette.default.darkGrey}; */
    .heading-text {
      /* min-width: 280px; */
      h4 {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-transform: capitalize;
        color: #101010;
      }
    }
  }
  .table-header > :first-child {
    min-width: 280px;
    /* border: 1px solid; */
  }
  .table-header > :nth-child(2) {
    min-width: 200px;
    /* border: 1px solid; */
  }

  .table-header > :nth-child(3) {
    min-width: 200px;
    /* border: 1px solid; */
  }
  .table-header > :nth-child(4) {
    min-width: 200px;
    /* border: 1px solid; */
  }

  .table-header > :nth-child(5) {
    /* min-width: 280px; */
    /* border: 1px solid red; */
    width: calc(100%);
  }

  .table-data {
    height: calc(100% - 54px);
    overflow: auto;
  }

  .table-row__content {
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
  }

  .table-content {
    display: flex;
    padding: 8px;
    .table-content__text {
      display: flex;
      align-items: center;
      h4 {
        font-weight: 500;
        font-size: 0.875rem;
      }
      a {
        text-decoration: none;
        color: #5b73e8;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
      p {
        padding-left: 8px;
        font-size: 0.875rem;
        /* color: ${theme.palette.default.darkGrey}; */
      }
      .table-actions {
        display: flex;
        gap: 16px;
      }
    }
  }

  .table-content > :first-child {
    min-width: 280px;
    /* border: 1px solid; */
  }

  .table-content > :nth-child(2) {
    min-width: 200px;
    /* border: 1px solid; */
  }

  .table-content > :nth-child(3) {
    min-width: 200px;
    /* border: 1px solid; */
  }

  .table-content > :nth-child(4) {
    /* min-width: 280px; */
    /* border: 1px solid red; */
    min-width: 200px;
  }
  .table-content > :nth-child(5) {
    /* min-width: 280px; */
    /* border: 1px solid red; */
    width: calc(100%);
  }
  .table-content > :last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 300px;
    /* border: 1px solid yellow; */
  }
  /* .table-content > :nth-child(2) {
        width: calc(100% - 360px);
    } */
`;
