// vendors
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

// component
import MessagePreview from "../../Components/MessagePreview";
import Editor from "../../Components/Editor";

// slice
import {
  getLanguage,
  getActiveNode,
  updateModifiedData,
  getModifiedNodeData,
} from "../../Slices/main.slice";
import { useAppDispatch } from "../../../../Store";

// utils
import {
  getResponse,
  getText,
  getInitialEditiorText,
  getPlainTextData,
  getHTMLContent,
  cleanMediaDetails,
  getMediaDetails,
  getValueFromObject,
} from "../../Utils";
import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
import { SMSTemplateListing } from "../../../../Store/Slices/SMS/sms.selectors";
import AppReactSelect from "../../../../AppComponents/AppReactSelect";
import { theme } from "../../../../Customization/Theme";

const OTPNode = (props: NodeProps) => {
  const { node } = props;
  const listingData = useSelector(SMSTemplateListing);
  const language = useSelector(getLanguage);
  const activeNode = useSelector(getActiveNode);
  const response = getResponse(node, language);
  const modifiedData = useSelector(getModifiedNodeData);
  const text = getText(response);
  const media = getMediaDetails(response);
  const templateObject = getValueFromObject(response, "otp_template");
  const [templateListing, setTemplatesListing] = useState<any>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(
    templateObject || null
  );
  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || "")
  );
  const isActiveNode = activeNode === node.nodeId;
  const dispatch = useAppDispatch();

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setEditorState(editorState);
    if (cleanMediaDetails(mediaInfo)) {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getHTMLContent(editorState),
            type: "text",
            media: cleanMediaDetails(mediaInfo),
            otp_template: selectedTemplate,
          },
        ])
      );
    } else {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getHTMLContent(editorState),
            type: "text",
            otp_template: selectedTemplate,
          },
        ])
      );
    }
  };

  useEffect(() => {
    const payload: any = {
      otp: true,
    };
    dispatch(SMSActions.getSMSTemplateListData(payload)).then((res: any) => {
      setTemplatesListing(res?.payload?.data?.results);
    });
  }, [dispatch]);
  // handle template select change
  const handleSelectChange = (value: any) => {
    dispatch(
      updateModifiedData([
        {
          ...modifiedData[0],
          otp_template: value,
        },
      ])
    );
    setSelectedTemplate(value);
  };
  return (
    <Box p={"8px 16px"}>
      {isActiveNode ? (
        <>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            error={getPlainTextData(editorState) === ""}
            mediaInfo={media}
            media={false}
          />
          <Box
            sx={{
              p: "16px",
              m: "1rem 0",
              backgroundColor: theme.palette.default.lightGrey,
              boxShadow: selectedTemplate?.id ? "none" : "0px 0px 3px 0px red",
            }}
          >
            {templateListing?.length ? (
              <AppReactSelect
                options={templateListing}
                value={selectedTemplate}
                name={"template"}
                onChangeCallbackFn={handleSelectChange}
                displayKey="name"
                valueKey="id"
                label="Select OTP Template"
                placeholder="Select Template"
              />
            ) : (
              "No Template Found"
            )}
          </Box>
        </>
      ) : (
        <MessagePreview text={text} mediaDetails={media} />
      )}
    </Box>
  );
};
export default OTPNode;
