import { RootState } from "../../../../index";


export const userRolesListing = (state: RootState) => state?.userRoles?.data?.allRolesListing;

export const userPermissionOptions = (state: RootState) => state?.userRoles?.data?.allPermissionOptions
export const userRolesListingCount = (state: RootState) => state?.userRoles?.data?.allRolesListingCount;

export const userPermissionListing = (state: RootState) => state?.userRoles?.data?.allPermissionListing;
export const updatedPermissionData = (state: RootState) => state?.userRoles?.data?.permissionData;
export const updatePermissionID = (state: RootState) => state?.userRoles?.data?.permissionID;
export const hide = (state: RootState) => state?.userRoles?.data?.hide;
export const hideType= (state: RootState) => state?.userRoles?.data?.hideType;



// export const userListingCount = (state: RootState) => state?.userRoles?.data?.