import http from "../../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class ChatLabelNetworkService {

    // Create Label
    static postChatLabel(data: any) {
        return http.post(
            `${consoleBaseUrl}${Endpoints.CHAT_LABEL}`,
            { ...data.payload, headers: data.HEADERS }
        )
    }

    // Get Listing of labels
    static getChatLabelListing(data: any) {
        const { limit, offset } = data;
        const url = `${consoleBaseUrl}${Endpoints.CHAT_LABEL}`;
        const headers = data.HEADERS;
        const params = { limit, offset };

        return http.get(url, { headers, params });
    }

    // Update Label By ID
    static updateLabel(data: any) {
        const { id, payload, HEADERS } = data;
        return http.put(`${consoleBaseUrl}${Endpoints.CHAT_LABEL}/${id}`, payload, { headers: HEADERS });
    }

    // Get Label By ID
    static getLabel(data: any) {
        const { id, HEADERS } = data;
        return http.get(`${consoleBaseUrl}${Endpoints.CHAT_LABEL}/${id}`, { headers: HEADERS });
    }

    // Delete Message By ID
    static deleteLabel(data: any) {
        const { id, HEADERS } = data;
        return http.delete(`${consoleBaseUrl}${Endpoints.CHAT_LABEL}/${id}`, { headers: HEADERS });
    }

}

// Label Endpoint
class Endpoints {
    static CHAT_LABEL = "/workspace/chat-labels"
}