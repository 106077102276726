import React from "react";
import styled, { css } from "styled-components";
import BotChatScreen from "./BotChatScreen";
import BotFooterScreen from "./BotFooterScreen";
import BotHeaderScreen from "./BotHeaderScreen";
import BotInputScreen from "./BotInputScreen";
import BotFloatingButton from "./BotFloatingButton";
import BotFloatingIcon from "./BotFloatingIcon";

interface Props {
  name: string;
  company_logo: any;
  subheader_text: string;
  welcome_message_text: string;
  prefilled_message_text: string;
  button_type: "icon" | "button";
  button_text: string;
  button_gradient_enabled: boolean;
  button_gradient_color: any;
  primary_color: string;
  whatsapp_number?: string;
  startButtonText?: string;
  // children?: React.ReactNode;
}

const StaticPreview: React.FC<Props> = ({
  company_logo,
  name,
  subheader_text,
  welcome_message_text,
  prefilled_message_text,
  button_type,
  button_text,
  button_gradient_enabled,
  button_gradient_color,
  primary_color = "#ff0000",
  whatsapp_number,
  startButtonText = "Start conversation",
  // children,
}) => {
  return (
    <StyledPageWrapper>
      <div className="frameWrapper">
        <StyledMainScreenContainer>
          <BotHeaderScreen
            name={name}
            company_logo={company_logo}
            subheader_text={subheader_text}
            primary_color={primary_color}
          />
          <BotChatScreen
            welcome_message_text={welcome_message_text}
          />
          <BotInputScreen
            button_background_color={
              button_gradient_enabled
                ? button_gradient_color?.gradient
                : primary_color
            }
            whatsapp_number={whatsapp_number}
            prefilled_message_text={prefilled_message_text}
            startButtonText={startButtonText}
          />
          <BotFooterScreen />
        </StyledMainScreenContainer>
        <div className="ctaWrapper">
          {button_type === "button" ? (
            <BotFloatingButton
              button_text={button_text}
              button_background_color={
                button_gradient_enabled
                  ? button_gradient_color?.gradient
                  : primary_color
              }
            />
          ) : (
            <BotFloatingIcon
              button_background_color={
                button_gradient_enabled
                  ? button_gradient_color?.gradient
                  : primary_color
              }
            />
          )}
        </div>
      </div>
    </StyledPageWrapper>
  );
};

export default React.memo(StaticPreview);

const StyledPageWrapper = styled.div`
  && {
    ${({ $primaryColor }: any) => {
      return css`
        width: 100%;
        height: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden;

        .frameWrapper {
          max-width: 450px;
          min-width: 260px;
          min-height: 400px;
          max-height: 600px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          gap: 1.5rem;
        }

        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      `;
    }};
  }
`;

export const StyledMainScreenContainer = styled.div`
  && {
    background-color: #eeeae3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    border-radius: 20px 20px 4px 4px;
    margin: 0px 20px 0px 0px;
    height: 400px;
    min-width: 260px;
    max-width: 360px;
  }
`;
