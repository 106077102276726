import styled from "styled-components";
import { theme } from "../Customization/Theme";

interface Props {
  children?: React.ReactNode;
  [otherProps: string]: any;
}
const AppSmallWindowPane: React.FC<Props> = ({ children, ...otherProps }) => {
  return <SmallSettingSidebar {...otherProps}>{children}</SmallSettingSidebar>;
};

export default AppSmallWindowPane;

const SmallSettingSidebar = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    height: 100%;
    width: 55%;
    display: flex;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    border-right: 1px solid rgba(0, 0, 0, 0.08);
    padding: 0.7rem 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    overflow-y: auto;
  }
  // Class for download Link
  .qr_Container{
    margin-top:50px;
  }
  .title{
    font-size:20px;
    font-weight:600;
    color:${theme.palette.default.darkGrey};
  }
  .link{
    color:${theme.palette.primary.main};
    text-decoration:underline;
    cursor:pointer;
    padding-top:20px;

  }
  //class for QR Code
  .section{
    display:flex;
    // justify-content:space-around;
    .subTitle{
      font-weight:600;
      font-size:18px;
      padding: 20px 0; 
    }
  }
`;
