import { chatbotConversationAxios } from "../../Utils/Axios";
import { getHttpService } from "../HttpService";
import { CHATBOT_CONVERSATION_ENDPOINTS } from "./Endpoints";

export class ChatbotConversationService {
  static httpService = getHttpService(chatbotConversationAxios);


  static getHistoryMessages = (queryData = {}, config = {}) => {
    return ChatbotConversationService.httpService.get({
      url: CHATBOT_CONVERSATION_ENDPOINTS.HISTORY,
      params: queryData,
      headers: config,
    });
  };

  static postAgentChat = (queryData = {}, config = {}) => {
    return ChatbotConversationService.httpService.post({
      url: CHATBOT_CONVERSATION_ENDPOINTS.AGENT_CHAT,
      data: queryData,
      headers: config,
    });
  };

  static changeChatStatus = (queryData = {}, config = {}) => {
    return ChatbotConversationService.httpService.post({
      url: CHATBOT_CONVERSATION_ENDPOINTS.CHANGE_STATUS,
      data: queryData,
      headers: config,
    });
  };
  static changeChatStatusBulk = (queryData = {}, config = {}) => {
    return ChatbotConversationService.httpService.post({
      url: CHATBOT_CONVERSATION_ENDPOINTS.BULK_CHANGE_STATUS,
      data: queryData,
      headers: config,
    });
  };
}
