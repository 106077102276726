import { getIn, useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";

interface Props {
  name: string;
  options: any[];
  divStyle?: React.CSSProperties;
  onChangeCallback?: () => void;
}

const GradientPickerField: React.FC<Props> = ({ name, options,divStyle,onChangeCallback }) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();

  const handleClick = (item: any) => {
    setFieldValue(name, item);
    setFieldTouched(name, true);
    onChangeCallback && onChangeCallback();
  };

  return (
    <Div style={divStyle}>
      {options?.map((item: any, index: number) => (
        <SingleGradientBox
          title={item?.gradient}
          key={index.toString()}
          $gradient={item?.gradient}
          $active={getIn(values, name)?.id === item?.id}
          onClick={() => handleClick(item)}
        >
          <div className="innerDiv" />
        </SingleGradientBox>
      ))}
    </Div>
  );
};

export default GradientPickerField;

const Div = styled.div`
  && {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
    max-width: 750px;
    position: relative;
  }
`;

const SingleGradientBox = styled.div`
  && {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin: 4px;
    background: ${({ $active, $gradient }: any) => $gradient};
    .innerDiv {
      height: 36px;
      width: 36px;
      border-radius: 50%;
      cursor: pointer;
      background: ${({ $active, $gradient }: any) => $gradient};
       border: 2px solid
   ${({ $active, $gradient }: any) => ($active ? "#fff" :  $gradient)};
      &:hover {
        border: 2px solid
   ${({ $active, $gradient }: any) => "#fff"};
      }
    }
  }
`;
