import { Box, Divider } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm, AppSubmitButton } from "../../../../AppComponents/Forms";
import LinearLoader from "../../../../AppComponents/Loader/LinearLoader";
import { theme } from "../../../../Customization/Theme";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { AddContactFormSchema } from "../../../../Models/ValidationSchema";
import { ConversationService } from "../../../../Services/Apis/ConversationService";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { ContactsActions } from "../../../../Store/Slices/Contacts/contacts.action";
import { ConatctsLifeCycle } from "../../../../Store/Slices/Contacts/contacts.selector";
import { ReactComponent as SaveIcon } from "../../../../assets/images/save-icon.svg";
import DynamicsInputFields from "./DynamicsFieldsLogic";
import { editPayload } from "./FieldsLogic";

const RenderDynamicFields: React.FC<any> = (props: any) => {
  const {
    ConatctDetailsById,
    AddContactsFields,
    ContactId,
    cancel,
    setShouldApply,
    hideLabelSectionTitle,
    updateData,
    parentHeight,
    leadRefresh,
  } = props;
  // common state
  const [localStore, setLocalStore] = useState<any>({
    userInput: {},
    schemaName: [],
    lifeCycleStage: [],
    lifeCycleStatus: [],
    lifeCycleLostReason: [],
    isEnabled: [],
    countryStates: [],
    stateCity: [],
  });

  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const ContactsLifeCycleFields = useAppSelector(ConatctsLifeCycle);
  const [isLoading, setLoading] = useState<any>(false);
  const [lifeCycleStage, setLifeCycleStage] = useState<any>([]);
  const [lifeCycleStatus, setLifeCycleStatus] = useState<any>([]);
  const [valid, setValid] = useState<any>({
    lost_reason: true,
  });
  // Add && Edit Contact api =====
  const handleSubmitBtn = async (values: any, submitProps: any) => {
    setLoading(true);
    try {
      let payload: any = { ...values };
      Object.keys(payload).forEach((key) => {
        const field = AddContactsFields?.flatMap((it: any) =>
          it?.fields?.filter((obj: { name: string }) => obj.name === key)
        )[0];

        if (field && payload[key] !== "") {
          if (field.type === "dropdown") {
            payload[key] = payload[key]?.id;
          } else if (field.type === "radio") {
            payload[key] = payload[key]?.id;
          } else if (field.type === "autocomplete") {
            payload[key] = payload[key]?.label;
          } else if (field.type === "checkbox") {
            // payload[key] = payload[key]?.label;
          } else if (field.type === "multiselect") {
            payload[key] = values[key]?.map((i: any) => i?.id);
          } else if (field.type === "datetime") {
            payload[key] = dayjs(values[key], "DD-MM-YYYY hh:mm A").format(
              "DD-MM-YYYY hh:mm A"
            );
          } else if (field.type === "landline") {
            payload[key] = `${payload?.[`landlineExtension${key}`]} - ${payload[key]
              }`;
            delete payload?.[`landlineExtension${key}`];
          }
        }
      });

      // removing null value key from payload *****
      Object.keys(payload).forEach(
        (key) =>
          (key !== "other_lost_reason" && payload[key] === "" ||
            payload[key]?.number === "" ||
            payload[key] === undefined ||
            payload[key] === null) &&
          delete payload[key]
      );
      delete payload["mobileCountryFormatmobile_phone_number"];
      let data = await editPayload(payload, ConatctDetailsById);
      const response: any =
        ContactId && ContactId?.length
          ? (
            await dispatch(
              ContactsActions.editContactsByID({
                id: ContactId,
                data: data,
              })
            )
          )?.payload
          : await ConversationService.getAddContacts(data, {});

      if (response?.data?.status) {
        setShouldApply && setShouldApply(true);
        cancel();
        updateData && updateData();
        showAlert(response?.data.message, "success");
        submitProps.resetForm();
      } else {
        showAlert(response?.data?.message, "warning");
      }
    } catch (error: any) {
      showAlert(
        error?.response?.data?.message || "Something went wrong",
        "warning"
      );
    } finally {
      submitProps.setSubmitting(false);
      setLoading(false);
      leadRefresh && leadRefresh();
    }
  };
  // form rendering  ***************

  const FormRendering = () => {
    if (AddContactsFields && AddContactsFields?.length > 0) {
      if (AddContactsFields && AddContactsFields?.length > 0) {
        const names = AddContactsFields?.flatMap((it: any) =>
          it?.fields?.flatMap(
            (el: { name: any; type?: string; fields?: any[] }) => {
              if (el.fields && el.fields.length && el.type === "section") {
                return el.fields.flatMap((nestedField: any) => nestedField);
              } else {
                return el.type !== "section" ? [el] : null;
              }
            }
          )
        );
        const namesObject = names?.reduce((obj: any, name: any) => {
          if (ContactId && ContactId?.length) {
            const contactDetail = ConatctDetailsById[name?.name];
            switch (name.type) {
              case "string":
              case "email":
                obj[name.name] = contactDetail;
                break;
              case "dropdown":
                const dependentField = localStore.schemaName.find(
                  (el: any) => el.dependant_field_id === name?.id
                );

                if (dependentField?.is_dependant) {
                  // Set options and value when the field is dependent
                  setLocalStore((prevLocalStore: any) => ({
                    ...prevLocalStore,
                    [dependentField.name]:
                      ConatctDetailsById[dependentField?.name]?.options,
                    isEnabled: [
                      ...prevLocalStore?.isEnabled,
                      dependentField?.id,
                    ],
                  }));
                }

                if (!name?.is_dependant) {
                  const dropDownObj = name?.options?.find(
                    (res: any) => res?.id === contactDetail?.id
                  );
                  obj[name.name] = dropDownObj;
                }

                if (
                  name?.is_dependant &&
                  name.name !== "lifecycle_stage" &&
                  name.name !== "lifecycle_stage_status"
                ) {
                  obj[name.name] = {
                    id: contactDetail?.id,
                    value: contactDetail?.label,
                  };
                }

                if (name.name === "lifecycle_stage") {
                  const dropDownObj = ContactsLifeCycleFields?.find(
                    (res: any) =>
                      contactDetail?.id
                        ? res.id === contactDetail?.id
                        : res.id === contactDetail
                  );
                  let stageObj = { ...dropDownObj, value: dropDownObj?.id };
                  obj[name.name] = stageObj;

                  setLifeCycleStatus(
                    stageObj?.statuses?.map((el: any) => ({
                      ...el,
                      value: el?.id,
                    })) || []
                  );
                }
                if (name.name === "lifecycle_stage_status") {
                  const foundObject = ContactsLifeCycleFields.flatMap(
                    (obj: any) =>
                      obj.statuses.find((status: any) =>
                        contactDetail?.id
                          ? status.id === contactDetail.id
                          : status.id === contactDetail
                      )
                  ).find((status: any) => status !== undefined);
                  if (foundObject?.type !== "closed_lost") {
                    // setValid((prev: any) => ({ ...prev, lost_reason: false }));
                  }
                  let statusObj = { ...foundObject, value: foundObject?.id };
                  obj[name.name] = statusObj;
                }
                // console.log("name?.name",updateData,ConatctDetailsById);
                if (name?.name === "city" || name?.name === "state_region") {
                  obj[name?.name] = ConatctDetailsById[name?.name];
                  setLocalStore((prevLocalStore: any) => ({
                    ...prevLocalStore,
                    [name?.name]: ConatctDetailsById[name?.name],
                    isEnabled: [...prevLocalStore?.isEnabled, name?.id],
                  }));
                }
                break;
              case "phone":
                // let formated =;
                obj[`mobileCountryFormat${name?.name}`] = {
                  format: true,
                };
                obj[name.name] = `${contactDetail || ""}`;
                break;
              case "landline":
                if (typeof contactDetail === "string" && contactDetail !== "") {
                  let landlineNumber = contactDetail.split("-");
                  if (landlineNumber[1]) {
                    obj[`landlineExtension${name?.name}`] = contactDetail
                      .split("-")[0]
                      ?.replace(" ", "");
                    obj[`${name?.name}`] = contactDetail
                      .split("-")[1]
                      ?.replace(" ", "");
                  } else {
                    obj[`landlineExtension${name?.name}`] = "";
                    obj[`${name?.name}`] = landlineNumber;
                  }
                } else {
                  obj[`landlineExtension${name?.name}`] =
                    contactDetail?.number?.length > 1
                      ? contactDetail?.extension.split("-")[0]
                      : "";
                  obj[name?.name] = `${contactDetail?.number?.length > 1
                      ? contactDetail?.number.split("-")[1]
                      : ""
                    }`;
                }

                break;
              default:
                obj[name.name] = contactDetail;
                if (
                  name?.name === "whatsapp_subscription_status" ||
                  name?.name === "email_subscription_status" ||
                  name?.name === "sms_subscription_status"
                ) {
                  if (!obj[name?.name]) {
                    obj[name.name] = name?.options[0];
                  }
                } else {
                  if (name.type === "radio" && !contactDetail) {
                    obj[name.name] = name?.options[0];
                  }
                }
                break;
            }
            return obj;
          } else {
            if (
              name?.name === "whatsapp_subscription_status" ||
              name?.name === "email_subscription_status" ||
              name?.name === "sms_subscription_status"
            ) {
              obj[name.name] = name?.options[0];
            } else {
              obj[name.name] = "";
            }
            return obj;
          }
        }, {});

        setLocalStore((prevLocalStore: any) => ({
          ...prevLocalStore,
          schemaName: names,
          userInput: namesObject,
        }));
      }
    }
  };

  // **************
  useEffect(() => {
    if (
      !lifeCycleStage?.length &&
      ContactsLifeCycleFields &&
      ContactsLifeCycleFields.length
    ) {
      let tempArray: any = [];
      ContactsLifeCycleFields.forEach((el: any) => {
        tempArray.push({
          ...el,
          value: el.id,
        });
      });
      setLifeCycleStage(tempArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContactsLifeCycleFields, ConatctDetailsById]);
  useEffect(() => {
    setLocalStore((prevLocalStore: any) => ({
      ...prevLocalStore,
      isEnabled: [],
    }));
    setValid({ lost_reason: true });
  }, []);
  useEffect(() => {
    FormRendering();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    AddContactsFields,
    ConatctDetailsById,
    ContactsLifeCycleFields,
    ContactId,
  ]);

  useEffect(() => {
    dispatch(ContactsActions.getLifeCycleContactsDropDown({}))
      .then()
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => { });
    return () => {
      setLocalStore({
        userInput: {},
        schemaName: [],
        lifeCycleStage: [],
        lifeCycleStatus: [],
        isEnabled: [],
        countryStates: [],
        stateCity: [],
      });
      setValid({ lost_reason: true });
    };
  }, []);
  return (
    <FormWrapperUI className="formWrapperUI">
      <AppForm
        initialValues={localStore.userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
          setLocalStore({ ...localStore, userInput: values });
        }}
        validationSchema={AddContactFormSchema(localStore.schemaName, valid)}
        divStyle={{
          height: "100%",
          gap: 0
        }}
      >
        {isLoading ? <LinearLoader /> : null}
        <Box className="input-field_wrapper">
        <DynamicsInputFields
          AddContactsFields={AddContactsFields}
          schemaName={localStore.schemaName}
          lifeCycleStage={lifeCycleStage}
          setLifeCycleStatus={setLifeCycleStatus}
          lifeCycleStatus={lifeCycleStatus}
          ContactsLifeCycleFields={ContactsLifeCycleFields}
          setLocalStore={setLocalStore}
          localStore={localStore}
          setValid={setValid}
          valid={valid}
          hideLabelSectionTitle={hideLabelSectionTitle}
          parentHeight={parentHeight}
          />
        </Box>
        {AddContactsFields?.length ? (
          <Box height={"inherit"} maxHeight={"48px"}>
            <Divider sx={{}} />
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={"8px"}
              height={"calc(100% - 1px)"}>

              <AppButton
                style={{ width: "115px" }}
                variant="outline"
                startIcon="add"
                onClick={cancel}
              >
                Cancel
              </AppButton>
              <AppSubmitButton title="Save">
                <SaveIcon />
              </AppSubmitButton>
            </Box>

          </Box>
        ) : null}
      </AppForm>
    </FormWrapperUI>
  );
};

export default RenderDynamicFields;

const FormWrapperUI = styled.div`
&& {
  overflow: hidden;
  height: 100%;
  .input-field_wrapper{
    height: calc(100vh - 96px);
    overflow: auto;
  }
  .formikFormWrapper label {
    font-size: 13px;
    color: ${theme.palette.default.black};
  }
}
`;
