import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppForm, AppSubmitButton } from "../../../../AppComponents/Forms";
import { UploadMarketingWrapper } from "../../../../AppComponents/UploadMarketingData/UploadMarketingDataCSS";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../Store";
import { SMSActions } from "../../../../Store/Slices/SMS/sms.actions";
import { ReactComponent as Danger } from "../../../../assets/images/danger-live.svg";
import { ReactComponent as File } from "../../../../assets/images/file.svg";
import Filepicker from "./Filepicker";
import { ProtectedAppForm } from "../../../../AppComponents/ProtectedComponents";
// import SampleFile from "../../../../assets/downloacdds/samplesms.csv";

interface Props {
  close?: any;
  localvalues?: any;
}

const UploadTemplate: React.FC<Props> = ({ close, localvalues }) => {
  const dispatch = useAppDispatch();
  let { showAlert } = useNotification();
  const formikRef: any = useRef();
  //*WILL BE USED TO SET THE FORM ACCESS TYPE
  const [formAccessType, setFormAccessType] = useState<
    "create" | "read" | "update" | "delete" | undefined
  >("create");
  //*getting the form access type using the location on first render

  const navigate = useNavigate();
  const [fname, setfname] = useState("");
  const [fileData, setfileData] = useState<any>(null);
  const [showError, setShowError] = useState(false);
  const [missingColumns, setmissingColumns] = useState([]);
  const [userInput, setUserInput] = useState<any>({
    upload_file: null,
  });

  useEffect(() => {}, []);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setShowError(false);
    if (!values?.upload_file) {
      SubmitProps?.setFieldError("upload_file", "File is required");
      SubmitProps.setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("source", fileData);
    // formData.append("file_name", fname);
    let temp = {
      formData: formData,
    };

    dispatch(SMSActions.uploadSMSDocumentFile(temp))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error")
          formikRef.current.setFieldError("upload_file", "Invalid File");
          setTimeout(function () {
            if (res?.payload?.data?.missing_columns) {
              setmissingColumns(res?.payload?.data?.missing_columns);
            }
          }, 500);
          return;
        }
        showAlert("File uploaded", "success");
        setUserInput(res?.upload_file?.data);
        if (window.location.pathname !== "/broadcast/sms/template") {
          navigate("/broadcast/sms/template");
        } else {
          const payload: any = {
            start: localvalues.startDate,
            limit: localvalues.itemsPerPage,
            offset: localvalues.currentPage,
            end: localvalues.endDate,
          };
          dispatch(SMSActions.getSMSTemplateListData(payload));
          close();
        }
      })
      .catch((err: any) => {
        showAlert("error while creating attachment", err);
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
      });
    return;
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const handleChange = () => {
    setShowError(false);
  };
  return (
    <ProtectedAppForm
      initialValues={userInput}
      innerRef={formikRef}
      onSubmit={(values: any, submitProps: any) => {
        handleSubmitBtn(values, submitProps);
        setUserInput(values);
      }}
      moduleId="sms_template"
      specificPermissionKey={formAccessType}
      // validationSchema={SMSUploadValidationSchema}
    >
      <UploadMarketingWrapper>
        <div className="pop-up__header">
          <h5>Upload SMS Template File</h5>
        </div>
        <Box className="file-upload__area">
          <Box sx={{}}>
            <Box className="file-upload__container">
              <div className="upload">
                <File />
                <Filepicker
                  name="upload_file"
                  mediaType={"document"}
                  sizeLimit={5000000}
                  extensions={[
                    "text/csv",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  ]}
                  divStyle={{ margin: "0rem", textAlign: "center" }}
                  handleChange={() => handleChange()}
                  setfname={setfname}
                  fname={fname}
                  fileData={fileData}
                  setfileData={setfileData}
                />
              </div>
              <div className="file-info">
                <h6>Drop & drag your DLT Approved file or Browse here</h6>
                <p>(.csv, .xlsx formats supported; max file size 5 MB)</p>
              </div>
            </Box>
          </Box>
          <div className="footer-content__info">
            <div className="content-left">
              <p>
                <Danger />
                Only DLT approved templates should be uploaded
              </p>
            </div>
            <div className="content-right">
              {/* <p>Columns should be: Template name, DLT template ID,  Template type, Sender ID, DLT Entity ID, Template Category and SMS type in sequence. <Link href={SampleFile}
                                download="sample_file.csv" sx={{ cursor: "pointer" }}>Download sample file</Link></p> */}
              <p>
                Columns should be: Template name, DLT Entity ID, DLT template
                ID, Template type, Template Category and SMS type in sequence,
                Sender ID.{" "}
                <a
                  className="sample-csv-btn"
                  href={"/csv/sms-Template-Columns.xlsx"}
                  download
                >
                  {" "}
                  Download sample file
                </a>
              </p>
            </div>
          </div>
          {formikRef.current &&
            formikRef.current.errors?.upload_file?.length > 0 && (
              <div className="error-message">
                <p>
                  The SMS template you provided is missing required columns.
                  Please rectify and retry again.
                </p>
                <p className="p-metadata">Missing Columns Name - </p>
                <ul>
                  {missingColumns.map((item: any) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
            )}
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ mt: "2rem" }}
            gap="1rem"
          >
            <Box>
              <AppButton variant="outline" onClick={close}>
                cancel
              </AppButton>
            </Box>
            <Box>
              <AppSubmitButton title="Submit" />
            </Box>
          </Box>
        </Box>
      </UploadMarketingWrapper>
    </ProtectedAppForm>
  );
};

export default UploadTemplate;
