import { IconButton } from "@mui/material";
import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { getMessageTime } from "../../../Utils";
import { AppMaterialIcons } from "../Icons";
import AppTooltipCustom from "./AppTooltipCustom";

interface Props {
  seen?: string;
  sent?: string;
  delivered?: string;
  userMessage?: boolean;
}

const AppChatReadReceipts: React.FC<Props> = ({
  userMessage = false,
  seen = "",
  delivered = "",
  sent = "",
}) => {
  if (!userMessage) return null;

  let status =
    seen?.length > 0
      ? "seen"
      : delivered?.length > 0
      ? "delivered"
      : sent?.length > 0
      ? "sent"
      : "";

  const TooltipJSX = () => {
    return (
      <StyleToolipContainer>
        {sent ? (
          <div className="singleRow">Sent: {getMessageTime(sent)}</div>
        ) : null}
        {delivered ? (
          <div className="singleRow">Delivered: {getMessageTime(delivered)}</div>
        ) : null}
        {seen ? (
          <div className="singleRow">Seen: {getMessageTime(seen)}</div>
        ) : null}
      </StyleToolipContainer>
    );
  };

  if (status === "sent") {
    return (
      <AppTooltipCustom title={<TooltipJSX />}>
        <IconButton className="chatReadStatusClassShowHide">
          <AppMaterialIcons iconName="Done" />
        </IconButton>
      </AppTooltipCustom>
    );
  }

  if (status === "delivered") {
    return (
      <AppTooltipCustom title={<TooltipJSX />}>
        <IconButton className="chatReadStatusClassShowHide">
          <AppMaterialIcons iconName="DoneAll" />
        </IconButton>
      </AppTooltipCustom>
    );
  }

  if (status === "seen") {
    return (
      <AppTooltipCustom title={<TooltipJSX />}>
        <IconButton  className="chatReadStatusClassShowHide">
          <AppMaterialIcons
            iconName="DoneAll"
            style={{ color: theme.palette.primary.main }}
          />
        </IconButton>
      </AppTooltipCustom>
    );
  }

  return null;
};

export default memo(AppChatReadReceipts);

const StyleToolipContainer = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.4rem;
    gap: 0.5rem;
    background-color: ${theme.palette.default.white};
    .singleRow {
      display: flex;
      gap: 0.4rem;
      align-items: center;
      color: ${theme.palette.default.darkGrey};
    }
    svg {
      font-size: 1rem;
    }
  }
`;
