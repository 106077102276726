import { FormControlLabel, FormLabel, RadioGroup } from "@mui/material";
import { FormLabelStyle } from "../Utils/style";
import { replacePlaceholders } from "../Utils/common";

const AppPreviewRadioCheckbox = (props: any) => {
    console.log("props?.content['data-source']", props?.content['data-source']);

    const getOptions = () => {
        if (Array.isArray(props?.content['data-source'])) {
            return props?.content['data-source'];
        } else {
            return props?.contentData?.data[props?.content['name']]['__example__'];            
        }
    };
    return (
        <>
            <FormLabelStyle>
                <FormLabel id="demo-controlled-radio-buttons-group">
                    {`${props?.content?.label}${!props?.content?.required ? " (optional)" : ""}`}
                </FormLabel>
                <RadioGroup aria-labelledby="demo-controlled-radio-buttons-group" name="controlled-radio-buttons-group">
                    {getOptions().map((res: any) => (
                        <FormControlLabel
                            key={res?.id}
                            labelPlacement="start"
                            value={res?.id}
                            control={props?.icon}
                            label={res?.title}
                        />
                    ))}
                </RadioGroup>
            </FormLabelStyle>
        </>
    );
};

export default AppPreviewRadioCheckbox;
