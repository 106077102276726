import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class NotificationNetworkService {
  static getNotificationConfig() {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_NOTIFICSTION_CONFIG}`
    );
  }
  static updateNotificationConfig(data:any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.UPDATE_NOTIFICSTION_CONFIG}`,
      data
    );
  }
}

//*whatsapp *//
class Endpoints {
  static GET_NOTIFICSTION_CONFIG = "/workspace/notification/get-config";
  static UPDATE_NOTIFICSTION_CONFIG = "/workspace/notification/update-config";
}
