import http from "../../../NetworkServices/http";
import { getCurrentTimeZone } from "../../../Utils";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class MarketingListNetworkService {
  static postCreateMarketingList(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CREATE_MARKETING_LIST}`,
      data
    );
  }

  static postCreateMarketingListContact(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.CREATE_MARKETING_LIST_CONTACT}`,
      data
    );
  }

  // static getAllMarketingListings(type: any) {
  //   return http.get(
  //     `${consoleBaseUrl}${Endpoints.ALL_MARKETING_LIST}?type=${type}`
  //   );
  // }

  static getAllMarketingListings(data: any) {
    const { limit, offset, search } = data;

    const timezone = getCurrentTimeZone()
    const params = { offset, limit, search ,timezone};
    if (params?.offset !== undefined) {
      return http.get(`${consoleBaseUrl}${Endpoints.ALL_MARKETING_LIST}`, {
        params,
      });
    } else {
      return http.get(`${consoleBaseUrl}${Endpoints.ALL_MARKETING_LIST}`);
    }
  }

  static getMarketingListById(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.MARKETING_LIST_BY_ID}`,
      data
    );
  }

  static getNewMarketingListById(data: any) {
    const { page_size, page_number, id, columns, search } = data;
    const requestBody = { page_size, page_number, columns, search };
    return http.post(
      `${consoleBaseUrl}${Endpoints.New_MARKETING_LIST_BY_ID}/${id}`,
      requestBody
    );
  }

  static uploadDocumentFile(data: any, config: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.UPLOAD_DOCUMENT_FILE}`,
      data,
      config
    );
  }

  static updateNewMarketingListData(data: any, id: string) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.NEW_UPDATE_MARKETING_LIST_CONTACT}/${id}`,
      data
    );
  }

  static updateMarketingListData(data: any, id: string) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_MARKETING_LIST_CONTACT}/${id}`,
      data
    );
  }

  static getFieldsTypeMappingList(id?: string, broadCastId?: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_FIELDS_TYPE_MAPPING_LIST}${
        id ? `?list_id=${id}&broadcast_id=${broadCastId}` : ""
      }`
    );
  }

  static deleteMarketingListById(id: any) {
    return http.delete(
      `${consoleBaseUrl}${Endpoints.DELETE_MARKETING_LIST_BY_ID}`,
      { data: id }
    );
  }

  static deleteMarketingDeatilsListById(payLoad: any) {
    const { id, data } = payLoad;
    return http.delete(
      `${consoleBaseUrl}${Endpoints.DELETE_MARKETING_DETAILS_LIST_BY_ID}/${id}`,
      { data: data }
    );
  }

  static updateSelectedContactItem(data: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_SELECTED_CONTACT_SINGLE_ITEM}`,
      data
    );
  }

  // Broadcast APIS

  static getAllBroadcastData(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.BROADCASTING_LIST}`, data);
  }

  static getAllCommunicationLogsData(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.COMMUNICATION_LOGS_LIST}`,
      data
    );
  }

  static getCommunicationLogsDownload(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.COMMUNICATION_LOGS_DOWNLOAD}`,
      data
    );
  }

  static getScheduledBroadcastByID(id: string) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCASTING_LIST}/${id}`,
      {}
    );
  }

  static getContactsSelectData(payload: any) {
    const { data, headers } = payload;
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_CONTACT_SELECT}`,
      payload
    );
  }

  static getExportMarketingList(payload: any) {
    const { id } = payload;
    return http.post(
      `${consoleBaseUrl}${Endpoints.MARKETING_DETAILS_EXPORT_ID}/${id}`,
      payload?.data
    );
  }
  
  static getContactImpotHistory(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.IMPORT_HISTORY}?search=${data?.search}&offset=${data?.offset}&limit=${data?.limit}`
    );
  }

  static getProgressBarData() {
    return http.get(`${consoleBaseUrl}${Endpoints.PROGRESSBAR_DATA}`);
  }
  static getImportHistoryFile(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.IMPORT_HISTORY_FileDownload}/${data.importId}?type=${data.type}`
    );
  }
  // Create Import ID
  static createImportIDNetwork(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CREATE_IMPORT_ID}`;
    const { limit, offset } = data;
    const params = { limit, offset };
    return http.get(url, { headers: data.HEADERS, params });
  }

  // User Listig
  static getUserListing(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.USER}`;
    const { limit, offset } = data;
    const params = { limit, offset };
    return http.get(url, { headers: data.HEADERS, params });
  }

  //Import Step 1 Network call
  static ImportFormStepOneNetwork(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.IMPORT_CONTACT_STEP_ONE}/${data?.ImportID}`,
      data?.formData
    );
  }

  //Import Step 2 Network call
  static ImportFormStepTwoNetwork(data: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.IMPORT_CONTACTS_STEP_TWO}/${data?.ImportID}`,
      data?.step2data
    );
  }

  //Import Step 3 Network call
  static ImportFormStepThreeNetwork(data: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.IMPORT_CONTACTS_STEP_THREE}/${data?.ImportID}`,
      { mapping: data?.mapping }
    );
  }

  //Import Step 3 Network call
  static DeleteTempImportContactsObj(data: any) {
    return http.delete(
      `${consoleBaseUrl}${Endpoints.DELETE_TEMP_IMPORT_CONTACTS_OBJ}/${data?.ImportID}`
    );
  }

  static generateCommunicationLogsReport(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.COMMUNICATION_GENERATE_REPORT}`,
      data
    );
  }
}

//*marketing list endpoints *//
class Endpoints {
  // old-url
  // new-url
  static UPLOAD_DOCUMENT_FILE = "/workspace/upload-marketing-list-contact-data";
  static CREATE_MARKETING_LIST_CONTACT =
    "/workspace/create--limarketingst-contact";

  // old-url
  static UPDATE_MARKETING_LIST_CONTACT =
    "/workspace/update-marketing-list-contact-data";
  // new-url

  // Broadcasting APIS

  // new marketing list redux
  static USER = "/workspace/user";
  static GET_CONTACT_SELECT =
    "/workspace/contact-properties/fields?status=visible";

  // Import Step Form

  // Broadcasting APIS

  //new endpoints for node microservice
  static MARKETING_LIST_BY_ID =
    "/workspace/broadcast/marketing-contact-by-list-id";
  static UPDATE_SELECTED_CONTACT_SINGLE_ITEM =
    "/workspace/broadcast/update-broadcast-list-temp-data";
  static GET_FIELDS_TYPE_MAPPING_LIST =
    "/workspace/broadcast/fields_type_mapping_list";
  static COMMUNICATION_LOGS_DOWNLOAD =
    "/workspace/broadcast/communication-logs/download";
  static COMMUNICATION_LOGS_LIST = "/workspace/broadcast/communication-logs";
  static COMMUNICATION_GENERATE_REPORT =
    "/workspace/broadcast/communication-logs/generate-report";
  static BROADCASTING_LIST = "/workspace/broadcast/broadcasting-list";
  static EMAIL_BROADCAST_INSIGHTS =
    "/workspace/broadcast/all-broadcasting-stats";

  static CREATE_IMPORT_ID =
    "/workspace/marketing/create-temp-import-contacts-obj";
  static DELETE_TEMP_IMPORT_CONTACTS_OBJ =
    "/workspace/marketing/delete-temp-import-contacts-obj";
  static IMPORT_CONTACT_STEP_ONE =
    "/workspace/marketing/import-contacts-step-one";
  static IMPORT_CONTACTS_STEP_TWO =
    "/workspace/marketing/import-contacts-step-two";
  static IMPORT_CONTACTS_STEP_THREE =
    "/workspace/marketing/import-contacts-step-three";
  static PROGRESSBAR_DATA = "/workspace/marketing/import-contacts-progress";
  static IMPORT_HISTORY_FileDownload = "/workspace/marketing/download-file";
  static IMPORT_HISTORY = "/workspace/marketing/get-import-history";
  static CREATE_MARKETING_LIST = "/workspace/marketing/create-marketing-list";
  static NEW_UPDATE_MARKETING_LIST_CONTACT =
    "/workspace/marketing/update-marketing-list";
  static DELETE_MARKETING_LIST_BY_ID =
    "/workspace/marketing/delete-marketing-list";
  static DELETE_MARKETING_DETAILS_LIST_BY_ID =
    "/workspace/marketing/delete-contacts-marketing-list";
  static New_MARKETING_LIST_BY_ID =
    "/workspace/marketing/get-contacts-marketing-list";
  static MARKETING_DETAILS_EXPORT_ID =
    "/workspace/marketing/export-marketing-list";
  static ALL_MARKETING_LIST = "/workspace/marketing/all-marketing-list";
}
