import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { theme } from "../../../../Customization/Theme";

export const EmailVerificationWrapper = styled(Paper)`
& {
    width: 100%;
    height: calc(100vh - 226px);
    overflow: auto;
    // padding: 1rem 3rem;
    padding: 0px 16px;
    padding-left: 16px;
    padding-right: 16px;
    background: transparent;

    .verification-status__success {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                p{
                    color: ${theme.palette.default.success};
                    font-weight: 500;
                    font-size: 0.938rem;
                }
                & .MuiSvgIcon-root {
                    border: 1px solid ${theme.palette.default.success};
                    border-radius: 50%;
                    color: ${theme.palette.default.success};
                    font-size: 0.975rem;
                }
            }

    .verification-status__failed {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                p{
                    color: ${theme.palette.default.error};
                    font-weight: 500;
                    font-size: 0.938rem;
                }
                & .MuiSvgIcon-root {
                    border: 1px solid ${theme.palette.default.error};
                    border-radius: 50%;
                    color: ${theme.palette.default.error};
                    font-size: 0.975rem;
                }
            }
}
`;

export const EmailVerificationTable = styled.div`
    & {
        background: ${theme.palette.default.white};
        border-radius: 4px;
        height: 100%;
        .table-header {
            display: flex;
            border-bottom: 1px solid ${theme.palette.default.grey};
        }
        .table-data_row {
            display: flex;
            border-bottom: 1px solid ${theme.palette.default.grey};
        }

        .table-header > div:first-child {
            max-width: 450px;
            min-width: 450px;
        }

        .table-header > div:nth-child(2) {
            width: calc(100%);
        }

        .table-data_row > div:first-child {
            max-width: 450px;
            min-width: 450px;
            p {
                text-decoration: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .table-data_row > div:nth-child(2) {
            width: calc(100% - 80px);
        }

        .more {
            display: flex;
            align-items: center;
            & .MuiSvgIcon-root {
                border-radius: 50%;
                color: ${theme.palette.default.darkGrey};
                font-size: 1.2rem;
            }
        }

        .table-col {
            padding: 1rem;
            // max-width: 280px;
            // min-width: 280px;
            display: flex;
            align-items: center;
            h4 {
                font-weight: 500;
                color: ${theme.palette.default.black};
                font-size: 1rem;
            }
            p {
                color: ${theme.palette.default.darkGrey};
                font-size: 0.938rem;
            }
            .verification-status__success {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                p{
                    color: ${theme.palette.default.success};
                    font-weight: 500;
                    font-size: 0.938rem;
                }
                & .MuiSvgIcon-root {
                    border: 1px solid ${theme.palette.default.success};
                    border-radius: 50%;
                    color: ${theme.palette.default.success};
                    font-size: 0.975rem;
                }
            }

            .verification-status__failed {
                display: flex;
                gap: 0.5rem;
                align-items: center;
                p{
                    color: ${theme.palette.default.error};
                    font-weight: 500;
                    font-size: 0.938rem;
                }
                & .MuiSvgIcon-root {
                    border: 1px solid ${theme.palette.default.error};
                    border-radius: 50%;
                    color: ${theme.palette.default.error};
                    font-size: 0.975rem;
                }
            }
        }
    }
`;


export const DeleteEmailVarification = styled.div`
    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0.875rem;
        border-bottom:1px solid rgba(203, 205, 211, 0.3);
        .header-left {
            display: flex;
            gap: 0.875rem;
            .danger {
                padding-top: 4px;
                img {
                    width: 1.2rem;
                }
            }
            .text h4 {
                font-size: 1.1rem;
                font-weight: 500;
            }
            .text p {
                color: ${theme.palette.default.darkGrey};
            }
        }
        .header-right {
            .MuiSvgIcon-root {
                color: ${theme.palette.default.darkGrey};
            }
            cursor: pointer;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
        padding: 0.875rem;
    }
`