import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";
export const StyledNotificationCard = styled.div`
  && {
    background-color: #fff;
    .available-notification__block {
      display: flex;
      max-width: 100%;
      width: 100%;
      word-break: 'break-all';
      border: 1px solid #E6E6E6;
      padding: 0.5rem;
      border-radius: 4px;
      word-break: break-all;
      .notification-block__left {
        max-width: 6.25rem;
        height: 6.25rem;
        border-radius: 4px;
        .notification-block__img-wrap {
          width: 100%; 
          height: 100%;
          display: flex;
          img {
            max-width: 6.25rem;
            height: 6.25rem;
            border-radius: 4px;
          }
        }
      }
      .notification-block__right {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
        margin-left: 0.7rem;
        max-width: calc(100% - 70px);
        width: 100%;
        justify-content: space-between;
        .notification-block__content-title {
          display: flex; 
          align-items: center; 
          justify-content: space-between;
          @media(max-width: 1340px) {
            flex-wrap: wrap-reverse;
          }
          .notification-block__heading {
            font-size: 0.9rem !important; 
            line-height: 1rem; 
            color: #101010;
            margin-bottom: 0.7rem;
          }
          .actionIcons {
            display: flex;
            svg {
              font-size: 1.15rem;
            }
            i {
              font-size: 1.15rem;
            }
            button.Mui-disabled {
              background-color: #f2f2f2;
              border-radius: 5px;
              cursor: not-allowed;
              i {
                color: #e1e1e1;
              }
            }
          }
        }
        .notification-block__description {
          font-size: 0.8rem; 
          color: ${theme.palette.default.darkGrey};
        }
        .notification-block__btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          .notification-block__btn-title {
            font-size: 0.8rem;
            line-height: 1rem;
            color: ${theme.palette.primary.main};
            font-weight: 500;
            cursor: pointer;
            a {
              text-decoration: none !important;
            }
          }
          .notification-block__footer {
            max-width: 106px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.6rem; 
            margin-top: 0.4rem; 
            display: block; 
            color: ${theme.palette.default.darkGrey};
          }
        }
      } 
    }

  }
`;
