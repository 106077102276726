import React, { useEffect, useState } from "react";
import "./loader.css";
import styled from "styled-components";
import { keyframes } from "styled-components";

export interface LoaderComponentProps {
  setLoading: (status: boolean) => void;
  setDebounceDelay?: React.Dispatch<React.SetStateAction<number>>;
  loading?:boolean
}

export const withLoader = (WrappedComponent: any, loadingMessage?: string) => {
  function LoadingHOC(props: any) {
    // const debounceDelay = 400;
    let isLoadingIntermediate = true;
    // const [isLoadingIntermediate, setLoadingIntermediate] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [debounceDelay] = useState<number>(600);
    let timeoutVar: any = null;

    const setLoadingState = (isComponentLoading: boolean) => {
      isLoadingIntermediate = isComponentLoading;
      if (!timeoutVar) {
        timeoutVar = setTimeout(() => {
          setLoading(isLoadingIntermediate);
          timeoutVar = null;
        }, debounceDelay);
      }
    };

    return (
      <div
        style={{
          position: "relative",
          width: "100%",
          minHeight: isLoading ? "300px" : "0",
          height: "100%",
        }}
      >
        {isLoading && (
          <div
            style={{
              width: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(255,255,255,1)",
              height: "100%",
              zIndex: 1000,
            }}
          >
            <div>
              <Loader />
            </div>
          </div>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} loading={isLoading} />
      </div>
    );
  }
  return LoadingHOC;
};

export const LoadingHOC = (
  WrappedComponent: any,
  props: any,
  loaded?: boolean,
  loadingMessage?: string
) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    if (loaded) {
      setLoading(false);
    }
  }, [loaded]);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        minHeight: "100%",
      }}
    >
      {isLoading && (
        <div>
          <div
            style={{
              width: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "300px",
              zIndex: 1000,
            }}
          >
            <div>
              <Loader />
            </div>
          </div>
        </div>
      )}
      <WrappedComponent {...props} setLoading={setLoading} />
    </div>
  );
};

export const LoaderIndicator = () => {
  return (
    <div
      style={{
        width: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255,255,255,1)",
        height: "100%",
        zIndex: 1000,
      }}
    >
      <div>
        <Loader />
      </div>
    </div>
  );
};

const moving = keyframes`
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: unset;
  }
`;

const Loader = styled.div`
  display: block;
  --height-of-loader: 4px;
  --loader-color: #0071e2;
  width: 130px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background: var(--loader-color);
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    border-radius: 30px;
    animation: ${moving} 1s ease-in-out infinite;
  }
`;
