import { getIn, useFormikContext } from "formik";
import { useEffect } from "react";

const useVariableHook = (
    name: string,
    variablesKey: string,
    variablesButtonLimit: number = 1,
    endVariableRequired: boolean = false
) => {
    const { setFieldValue, values } = useFormikContext<any>();
    const getUniqueMatches = (matches: string[]): string[] => {
        const sortedMatches = matches
            .sort((a, b) => {
                return parseInt(a.match(/\d+/)![0]) - parseInt(b.match(/\d+/)![0]);
            })
            .slice(0, variablesButtonLimit); // Limit to maximum 10 matches

        const isConsecutiveSeries = matches.every((match, index) => {
            if (index === 0) return parseInt(match?.match(/\d+/)![0]) === 1;
            const prevMatch = sortedMatches[index - 1];
            const currentNumber = parseInt(match?.match(/\d+/)![0]);
            const prevNumber = parseInt(prevMatch?.match(/\d+/)![0]);
            return currentNumber === prevNumber + 1;
        });

        if (!isConsecutiveSeries) {
            sortedMatches.pop(); // Remove unmatched element
        }
        return sortedMatches
    };

    useEffect(() => {

        const pattern = /\{\{([1-9]|10)\}\}/gm;
        const valueType = typeof (getIn(values, name))
        const matches = valueType === "string" ? getIn(values, name)?.match(pattern) : null;
        if (matches) {
            const uniqueMatches = getUniqueMatches(matches);
            setFieldValue(variablesKey, uniqueMatches);
        }
        else {
            if (valueType !== "string") {
                setFieldValue(variablesKey, "");
            } else {
                setFieldValue(variablesKey, []);
            }

        }
    }, [getIn(values, name)]); // eslint-disable-line react-hooks/exhaustive-deps


    return null;
};

export default useVariableHook;
