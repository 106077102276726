import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { IconButton, Tooltip } from "@mui/material";
import Grow from "@mui/material/Grow";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { LoaderComponentProps } from "../../../../../AppComponents/Loader";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { AppErrorMessage } from "../../../../../AppComponents/Forms";
import { theme } from "../../../../../Customization/Theme";
import { HocBackdropLoader } from "../../../../../HOC/HocBackdropLoader";


const IMAGE_TYPES = ["image/jpeg", "image/jpg", "image/png"];
const VIDEO_TYPES = ["video/mp4"];
const AUDIO_TYPES = ["audio/aac", "audio/mp4", "audio/mpeg", "audio/amr"];
const STICKER_TYPES = ["image/webp"];

const DOCUMENT_TYPES = [
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
}

interface Props extends LoaderComponentProps {
  source: boolean | undefined;
  name: string;
  mediaType: string;
  disabled?: boolean
  divStyle?: any;
  isResolveVariable?: boolean;
  tooltipTitle?: string;
  supportedFormat?: any
}

const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "VIDEO") {
    return VIDEO_TYPES;
  } else if (mediaType === "IMAGE") {
    return IMAGE_TYPES;
  } else if (mediaType === "DOCUMENT") {
    return DOCUMENT_TYPES;
  } else if (mediaType === "STICKER") {
    return STICKER_TYPES;
  } else if (mediaType === "AUDIO") {
    return AUDIO_TYPES;
  } else {
    return [];
  }
};
const getCorrectMediaSize = (mediaType: string) => {
  if (mediaType === "VIDEO") {
    return 16000000;
  } else if (mediaType === "IMAGE") {
    return 5000000;
  } else if (mediaType === "DOCUMENT") {
    return 100000000;
  } else if (mediaType === "STICKER") {
    return 1000000;
  } else if (mediaType === "AUDIO") {
    return 16000000;
  } else {
    return 500000;
  }
};

const WhatsappMediaPicker = (props: Props) => {
  const {
    setFieldTouched,
    values,
    setFieldValue,
    setFieldError,
    errors,
    touched,
  } = useFormikContext<any>();

  const { showAlert } = useNotification();
  const [mainState, setMainState] = useState<ImageState>(ImageState.INITIAL); // initial, search, gallery, uploaded
  const [allowedMediaTypes, setAllowedMediaTypes] = useState<string[]>(
    props?.supportedFormat ? props?.supportedFormat : getCorrectMediaFormats(props.mediaType)
  );

  useEffect(() => {
    if (props?.supportedFormat) {
      setAllowedMediaTypes(props?.supportedFormat)
    } else {
      setAllowedMediaTypes(getCorrectMediaFormats(props.mediaType))
    }
  }, [props?.supportedFormat]) // eslint-disable-line react-hooks/exhaustive-deps

  const [allowedMediaSize, setAllowedMediaSize] = useState<number>(
    getCorrectMediaSize(props.mediaType)
  );

  useEffect(() => {
    if (
      getIn(values, props?.name)?.source &&
      getIn(values, props?.name)?.source?.length > 0
    ) {
      setMainState(ImageState.UPLOADED);
    } else {
      setMainState(ImageState.INITIAL);
    }
    setAllowedMediaSize(getCorrectMediaSize(props.mediaType))
  }, [getIn(values, props?.name)]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadClick = (event: any) => {
    var file = event.target.files[0];
    setFieldValue(props?.name, file);
    setFieldTouched(props.name, true);
    setAllowedMediaSize(getCorrectMediaSize(props.mediaType))

    const reader = new FileReader();
    if (!reader || !file) {
      return
    }
    if (getCorrectMediaSize(props.mediaType) && file.size > getCorrectMediaSize(props.mediaType)) {
      setTimeout(() => {
        setFieldError(
          props.name,
          `${props.mediaType} size must be smaller than ${getCorrectMediaSize(props.mediaType) / 1000000
          } MB`
        );
      }, 1);
      return;
    }

    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      if (props?.supportedFormat ? !props?.supportedFormat?.includes(mimeType) : !allowedMediaTypes.includes(mimeType)) {

        setTimeout(() => {
          setFieldError(props.name, "Invalid File format");
        }, 1);
        return;
      }
      onImageChange(file);
    };
    // Ensure the file is read
    reader.readAsDataURL(file);
  };

  const onImageChange = (file: any) => {
    setFieldTouched(props.name, true);
    if (file) {
      props.setLoading(true);

      const formData = new FormData();
      formData.append("source", file);
      if (props.isResolveVariable) {
        formData.append("resolved", "true");
      }
      ChatbotConsoleService.uploadWhatsappMedia(formData, props.isResolveVariable)
        .then((response: any) => {
          setFieldValue(props?.name, response?.data, props?.source);
          setMainState(ImageState.UPLOADED);
          showAlert("Media uploaded", "success");
        })
        .catch((error: any) => {
          showAlert("Media upload failed", "error");
          setMainState(ImageState.INITIAL);
          setFieldValue(props?.name, null);
        })
        .finally(() => {
          setTimeout(() => {
            props.setLoading(false);
          }, 1000);
        });
    }
  };

  const imageResetHandler = () => {
    setMainState(ImageState.INITIAL);
    setFieldValue(props?.name, null);
  };

  const renderInitialState = () => {
    return (
      <>
        <StyledIntialState>
          <input
            accept={allowedMediaTypes.toString()}
            id={props.name}
            name={props.name}
            type="file"
            onChange={handleUploadClick}
          />
          <label htmlFor={props.name}>
            <Tooltip
              arrow
              enterDelay={1}
              leaveDelay={0}
              TransitionComponent={Grow}
              placement="top"
              title={props?.tooltipTitle || "upload"}
            >
              <MediaUploadBox variant="text" className="MediaUploadBox">
                Upload {props.mediaType}
              </MediaUploadBox>
            </Tooltip>
          </label>
        </StyledIntialState>
      </>
    );
  };

  const renderUploadedState = () => {
    const file: any = getIn(values, props?.name);
    return (
      <StyledUploadedState >
        <StyledUploadedImageWrap className="StyledUploadedImageWrap">
          {file?.name || file?.file_name}
          <Tooltip
            arrow
            enterDelay={1}
            leaveDelay={0}
            TransitionComponent={Grow}
            placement="top"
            title={"Delete"}
          >
            <IconButton onClick={() => imageResetHandler()} disabled={props.disabled}>
              <ClearRoundedIcon />
            </IconButton>
          </Tooltip>
        </StyledUploadedImageWrap>
      </StyledUploadedState>
    );
  };

  return (
    <StyledViewWrap style={props?.divStyle}>
      {(mainState === ImageState.INITIAL && renderInitialState()) ||
        (mainState === ImageState.UPLOADED && renderUploadedState())}
      <AppErrorMessage
        error={getIn(errors, props.name)}
        visible={getIn(touched, props.name)}
      />
    </StyledViewWrap>
  );
};

export default HocBackdropLoader(WhatsappMediaPicker);

const StyledUploadedImageWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin : 27px 0px 0px 0px;
    svg {
      cursor: pointer;
      font-size: 1.1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
const MediaUploadBox = styled.div`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40px;
    height: 40px;
    margin : 27px 0px 0px 0px;
    padding: 6px 12px;
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.palette.primary.main};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    &:disabled {
      color: ${theme.palette.default.grey};
    }
    &:hover {
      color: ${theme.palette.primary.dark};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;

const StyledViewWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 0.5rem;
    margin: -1rem 0 1rem;
    input {
      display: none;
    }
  }
`;

const StyledIntialState = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;

    .MuiFab-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
    }

    .MuiIconButton-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.secondary.main};
      border-radius: 50%;
    }
  }
`;

const StyledUploadedState = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    align-items: center;

    img {
      width: 52px !important;
      height: 52px !important;
      object-fit: cover !important;
      overflow: hidden;
    }
  }
`;
