import React from "react";
import { theme } from "../../../Customization/Theme";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import { StyledMessageText } from "./../../Styles/StyledComponents";

interface Props {
  userMessage: boolean;
  children: any;
  time: string;
  msgId: string;
  seen: string;
  sent: string;
  delivered: string;

  [otherProps: string]: any;
}
const AppRenderPrivateNote: React.FC<Props> = React.memo(
  ({ userMessage = false, children, time,msgId,  
    seen,
    sent,
    delivered,...otherProps }) => {
    if (!children || children?.length < 1) {
      return null;
    }

    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        isMedia={false}
        time={time}
        isPrivateNote={true}
        msgId={msgId}

        seen={seen}
        sent={sent}
        delivered={delivered}
      >
        <StyledMessageText
          // className="getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
          userMessage={userMessage}
          dangerouslySetInnerHTML={{
            __html: children,
          }}
          style={{
            backgroundColor: "rgba(243, 218, 88, 0.25)",
            color: `${theme.palette.default.black}`,
            fontSize: "14px",
            padding: "8px 12px",
          }}
          {...otherProps}
        />
      </AppMessageBoxWrapper>
    );
  }
);

export default AppRenderPrivateNote;
