import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import {
  AppPageCaption,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox,
} from "../../../../Customization/CommonElements";
import { theme } from "../../../../Customization/Theme";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotTrackingCode } from "../../../../Models";
import { getBotTrackingCodeFn } from "../../../../Services/Apis";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const ChannelsDeployScript = (): JSX.Element => {
  const [encodedCode, setEncodedCode] = useState<any>();
  const currentBot: any = useSelector(useCurrentBot);
  const dispatch = useDispatch();
  const { showAlert } = useNotification();
  const myRef = useRef<HTMLPreElement>(null);

  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  useEffect(() => {
    if (currentBot?.id) {
      getBotTrackingCodeFn(dispatch)({
        pathParams: [currentBot?.id],
      }).then((res: ChatbotTrackingCode) => {
        // if (res?.geta_host && res?.matomo_site_id && res?.bot_id) {
          setEncodedCode(res);
        // }
      });
    }
  }, [currentBot?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const CopyTextToClipboard = async () => {
    let temp: any = myRef.current?.innerText;
    await navigator.clipboard.writeText(temp);
    showAlert("Script copied to clipboard", "success");
  };

  let IFRAMEURL: string = "http://localhost:8083"
  // const channelBotComponent: any = {
  //   "whatsapp": process.env.REACT_APP_WHATSAPP_BOT_IFRAME_SCRIPT_BASE_URL,
  //   "messenger": process.env.REACT_APP_MESSENGER_BOT_IFRAME_SCRIPT_BASE_URL,
  //   "telegram": process.env.REACT_APP_TELEGRAM_BOT_IFRAME_SCRIPT_BASE_URL,
  // }
  const channelBotComponent: any = {
    "whatsapp": process.env.REACT_APP_WHATSAPP_BOT_IFRAME_SCRIPT_BASE_URL,
    "messenger": process.env.REACT_APP_WHATSAPP_BOT_IFRAME_SCRIPT_BASE_URL,
    "telegram": process.env.REACT_APP_WHATSAPP_BOT_IFRAME_SCRIPT_BASE_URL,
  }
  IFRAMEURL = channelBotComponent[currentBot?.channel] || "http://localhost:8083"

  return (
    <SettingPageMainBoxWrap>
      <SettingPageMainBoxTopbar>
        <SettingPageMainBoxTitle>
          {`${capitalizeFirstLetter(currentBot?.channel)} Script`}
        </SettingPageMainBoxTitle>
      </SettingPageMainBoxTopbar>
      <AppPageCaption
        style={{
          marginTop: "-1.2rem",
          marginBottom: "1rem",
          marginLeft: "1.5rem",
        }}
      >
        Copy the code snippet below, and paste it into the Head tag on page of
        your website
      </AppPageCaption>

      <SettingPageMainContentBox
        style={{
          alignItems: "start",
          gap: "1rem",
          padding: "1rem",
          overflow: "hidden",
        }}
      >
        <CodeDiv>
          <CopyBtnBox>
            <AppButton
              variant="outline"
              onClick={() => CopyTextToClipboard()}
              endIcon="ri-file-copy-line"
            >
              Copy
            </AppButton>
          </CopyBtnBox>
          <StyledPre ref={myRef}>
            {`
              <script src="${IFRAMEURL}/output.js" async onload="window.on('${encodedCode?.geta_host}',${encodedCode?.matomo_site_id},'${encodedCode?.bot_id}');"></script>
            `}
          </StyledPre>
        </CodeDiv>
      </SettingPageMainContentBox>
    </SettingPageMainBoxWrap>
  );
};

export default ChannelsDeployScript;

const CodeDiv = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 3.5rem 1rem 2rem;
    background-color: ${theme.palette.secondary.main};
    overflow: auto;
    width: 80%;
    height: 100%;
    min-height: 300px;
    border-radius: 4px;
    position: relative;
  }
`;
const CopyBtnBox = styled.div`
  && {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
`;

const StyledPre = styled.pre`
  && {
    color: ${theme.palette.default.text};
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: ${theme.typography.fontFamily};
    width: 100%;
    height: 100%;
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
  }
`;
