import { LoadingButton } from "@mui/lab";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

interface AppButtonProps {
  variant?: string;
  [otherProps: string]: any;
  children?: React.ReactNode;
}

const AppSmallButton: React.FC<AppButtonProps> = ({
  variant = "primary",
  children,
  ...otherProps
}) => {
  if (variant === "outline")
    return <OutlineButton {...otherProps}>{children}</OutlineButton>;

  if (variant === "grey")
    return <GreyButton {...otherProps}>{children}</GreyButton>;

  if (variant === "text")
    return <TextButton {...otherProps}>{children}</TextButton>;

  return <PrimaryButton {...otherProps}>{children}</PrimaryButton>;
};

export const GreyButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: capitalize;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid ${theme.palette.default.placeholder} !important;

    background-color: transparent;
    color: ${theme.palette.default.darkGrey};

    a {
      color: ${theme.palette.default.darkGrey};
      width: 100%;
    }

    &:hover {
      background-color: ${theme.palette.default.placeholder};
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;

export const TextButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: capitalize;
    text-align: center;

    padding: 2px 8px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: none;

    background-color: transparent;
    color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.background?.color;
  }};

    a {
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
      width: 100%;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};

      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;

export const OutlineButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: none;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid
      ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }} !important;

    background-color: transparent;
    color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};

    a {
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
      width: 100%;
      font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }} !important;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      svg {
        font-size: 17px;
      }
    }

    &:hover {
      background-color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
    &:disabled {
    
      pointer-events: all;
      cursor: not-allowed;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

export const PrimaryButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: capitalize;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid
      ${({ theme }: any) => {
    return theme?.components?.button_background?.main;
  }} !important;

    background-color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.background?.color;
  }};
    color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.primary.main};
      width: 100%;
      text-decoration: none;
      svg {
        margin-right: 10px;
        max-width: 13px;
      }
    }

    &:hover {
      background-color: transparent;
    color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
        width: 100%;
      }
    }

    &:disabled {
      background-color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
      pointer-events: all;
      cursor: not-allowed;
    }
  }
`;

export default AppSmallButton;
