import { useState } from "react";
import { Modifier, EditorState } from "draft-js";
import EmojiPicker from "emoji-picker-react";
import Icon from "./../../../Icon";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";

const Emoji = (props: any) => {
  const { editorState, onChange, config } = props;
  const { emoji } = config;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleEmojiClick = (emoji: any) => {
    const { editorState, onChange } = props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      emoji.emoji,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        position: "relative",
        "&:hover > div": {
          display: "flex",
          opacity: 1,
        },
      }}
    >
      <IconButton sx={{ p: "2px !important" }} size="small" onClick={handleClick}>
        <Icon icon={emoji?.icon} size={16} color="#7E8392" />
      </IconButton>
      <Popover
        className="ignore-onClickOutside"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginLeft: '8px',
            borderRadius: "8px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
            padding: "8px 4px 8px 8px",
            maxHeight: "220px",
            overflow: 'hidden',
          },
        }}
      >
        <Box
          sx={{
            "& .EmojiPickerReact": {
              borderWidth: "0px !important",
              borderRadius: "0px !important",
            },
            "& .epr-category-nav": {
              display: "none",
              padding: '2px 2px 8px',
            },
            "& .epr-skin-tones": {
              display: "none",
            },
            "& .epr-emoji-category": {
              "& .epr-emoji-category-label": {
                display: "none !important",
              },
            },
            "& .epr-hidden-on-search": {
              display: "none",
            },
            "&. epr-emoji-category-content": {
              margin: "0 !important",
            },
            "& .epr-header-overlay": {
              padding: "0px !important",
              marginBottom: "8px",
            },
          }}
        >
          <EmojiPicker

            width={315}
            height={211}
            previewConfig={{ showPreview: false }}
            onEmojiClick={handleEmojiClick}
          />
        </Box>
      </Popover>
    </Box>
  );
};
export default Emoji;
