import styled from "styled-components";

export const ButtonListWrapperStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  list-style: none;
  margin-left: 0 !important;
`;
ButtonListWrapperStyled.displayName = 'ButtonListWrapperStyled';

export const SortableItemStyled = styled.li`
  display: flex;
  flex-grow: 1;
  align-items: center;
  box-sizing: border-box;
  list-style: none;
`;
SortableItemStyled.displayName = 'SortableItemStyled';

export const DragHandleButtonStyled = styled.button`
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: grab;
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:focus-visible {
    box-shadow: 0 0px 0px 2px #4c9ffe;
  }

  svg {
    flex: 0 0 auto;
    margin: auto;
    height: 100%;
    overflow: visible;
    fill: #919eab;
  }

`;
DragHandleButtonStyled.displayName = 'DragHandleButtonStyled';