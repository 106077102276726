import { registerActions } from "./../Config/RegisterActions";

const getActionConfig = (type: string, childNode?: string) => {
  let selectedNode = registerActions.filter(
    (regNode: any) => regNode.type === type
  );
  return selectedNode[0];
};

export default getActionConfig;
