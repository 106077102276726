// vendors
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";

export const commonStyles = {
  fontSize: "12px",
  lineHeight: "18px",
  fontWeight: 400,
  color: "#7E8392",
};

export const PlaceholderTextStyled = styled(Typography)(() => ({
  ...commonStyles,
  color: "rgba(126, 131, 146, 0.5)",
})) as typeof Typography;

export const SelectedLabelStyled = styled(Typography)(() => ({
  color: "#7E8392",
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: "400",
})) as typeof Typography;

export const ListItemTextStyled = styled(ListItemText)(() => ({
  "& .MuiTypography-root": {
    ...commonStyles,
  },
})) as typeof ListItemText;