import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import styled   from "styled-components";

import * as React from "react";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import {
  GraphContainer,
  HeaderWrapper,
  WholeWrap,
  WrapperD,
} from "./ResponseTimeStyl";
import {
  SkeletonProgressBar,
  SkeletonSkeletonDoughnutCard,
} from "./SkeletonLoader.tsx/SkeletonCard";
import { TextTitle } from "./AnalyticsSummaryStyles";
import { Typography } from "@mui/material";
import ResolutionImage from "../../assets/images/resolution-time.png";
import ConversationImage from "../../assets/images/conversation-time.png";



interface Props {
  resolution_time?: any;
  response_time?: any;
  xAxisMinValue?: any;
  resolutionTimeTooltip?: any;
  responseTimeTooltip?: any;
  xAxisCount?: any;
  loading?: boolean;
}

const ResponseTime: React.FC<Props> = ({
  resolution_time,
  response_time,
  xAxisMinValue,
  resolutionTimeTooltip,
  responseTimeTooltip,
  xAxisCount,
  loading,
}) => {
  function formatTime(seconds: any) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.round(seconds % 60);
    var timeString = "";
    if (hours > 0) {
      timeString += hours + " hr";
      if (hours > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (minutes > 0) {
      timeString += minutes + " min";
      if (minutes > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (remainingSeconds > 0 || timeString === "") {
      timeString += remainingSeconds + " sec";
      if (remainingSeconds !== 1) {
        timeString += "s";
      }
    }
    return timeString;
  }

  return (
    <Box sx={{ flexGrow: 1,  height: '100%' }} className="responseWrap">
      <WholeWrap style={{height: '100%', gap: 0}}>
        <HeaderWrapper>
          <p className="ResponseTimeHeader">
            <TextTitle>Time Stats</TextTitle>
          </p>
          <AppToolTip
            tooltipProps={{
              title: "Time performance metrics for agents and bots",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </HeaderWrapper>
        <GraphContainer style={{height: '100%'}}>

       < WrapperStats>
        <div className="section_f">
              <p className="section_f_text">
              {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                "Average Resolution Time"
              )}
              </p>
              <div className="avg_count">
                <div className="users_count_text">
                
                {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                ` ${formatTime(resolutionTimeTooltip) || 0}`
              )}
                </div>


{
  loading ? <SkeletonSkeletonDoughnutCard /> :  <div className="c_div1_icon">
    <img src={ResolutionImage} />
                  </div>
}
               
              </div>
            </div>

            <hr className="dotted_hr"></hr>
            <div className="section_f">
              <p className="section_f_text">
              {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                "Avg. Conversation Duration"
              )}
              </p>
              <div className="avg_count">
                <p className="users_count_text">
                  {loading ? (
                <SkeletonSkeletonDoughnutCard />
              ) : (
                ` ${formatTime(responseTimeTooltip) || 0} `
              )}
                </p>


              {
                loading ? <SkeletonSkeletonDoughnutCard /> : <div className="c_div1_icon"><img src={ConversationImage} /></div>
              }

                
              </div>
            </div>

            </ WrapperStats>
          
        </GraphContainer>
      </WholeWrap>
    </Box>
  );
};
export default ResponseTime;


const WrapperStats = styled.div`
&& {
    
gap: 16px;
height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .dotted_hr {
    margin: 0
    }

    .section_f_text {
    margin-bottom: 4px;
    }

  .section_f {
    width: 100%;
  }

  .avg_count {
  // border: 1px solid red !important;
  width: 100%;
  }
  
}
`