import { Alert } from "@mui/material";

const {
  SnackbarProvider,
  CustomContentProps,
  useSnackbar,
  closeSnackbar,
} = require("notistack");

interface CustomProps {
  [otherProps: string]: any;
  children?: React.ReactNode;
}

const CustomSnackbarProvider = (
  props: CustomProps,
  oldProps: typeof CustomContentProps
) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      content={(key: any, message: any) => {
        return (
          <Alert key={key} action={message.action} severity={message.variant}>
            {message.message}
          </Alert>
        );
      }}
      autoHideDuration={2500}
      preventDuplicate={true}
    >
      {props.children}
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
