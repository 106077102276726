import {
    Tooltip as MuiTooltip,
    TooltipProps,
    Zoom,
    tooltipClasses,
} from "@mui/material";
import React from "react";
// import styled from "styled-components";
import { styled } from "@mui/material/styles";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }: any) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme?.palette?.default?.white,
    color: theme?.palette?.default?.text,
    fontSize: 12,
    maxWidth: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme?.palette?.default?.darkGrey,
  },
}));
const AppTooltipCustom: React.FC<TooltipProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <LightTooltip
      arrow={true}
      TransitionComponent={Zoom}
      {...otherProps}
    >
      {children}
    </LightTooltip>
  );
};

export default AppTooltipCustom;
