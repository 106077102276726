import styled from "styled-components";
import { Box, Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../Customization/Theme";

interface CustomDasgboardTootipProps {
    label: string;
    count: number;
    children: any;
    dotColor?: string;
}

const CustomDashboardTooltip: React.FC<CustomDasgboardTootipProps> = ({
    label,
    count,
    children,
    dotColor
}) => {
    return (
        <ThemeProvider theme={toolTipTheme}>
            <Tooltip title={<ToolTipWrapper>
                <Box className="heading">
                    {label}
                </Box>
                <Box className="value">
                   <span className="dot" style={{
                    backgroundColor: dotColor || `${theme.palette.primary.main}`,
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    marginRight: "5px"
                   }}></span> Total: <span className='data-value'> {count}</span>
                </Box>
            </ToolTipWrapper>} followCursor>
                {children}
            </Tooltip>    
        </ThemeProvider>
    )
}



export default CustomDashboardTooltip;


const toolTipTheme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    border: `1px solid ${theme.palette.default.shadow}`,
                    backgroundColor: `${theme.palette.common.white}`,
                    color: `${theme.palette.default.text}`,
                    padding: "0px",
                    // boxShadow: '0px 0px 2px 0px #999',
                    boxShadow: `0px 0px 2px ${theme.palette.default.darkGrey}`,
                    // fontSize: "13px",
                    // lineHeight: "19.6px",
                    borderRadius: "4px",
                    // letterSpacing: "0.05em",
                },
                arrow: {
                    color: "white",
                },
            },
        },
    },
});

const ToolTipWrapper = styled.div`
&& {
    max-width: 200px;
    width: 100px;
    border-radius: 4px;
    .heading {
        font-weight: 400;
        font-size: 13px;
        background: #ebebeb;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .value {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 13px;
        height: 30px;
        background: #fff;
        .data-value {
            font-weight: 500;
            margin-left: 5px;
        }
    }
}
`;