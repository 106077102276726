import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AppForm, AppSubmitButton } from "../../AppComponents/Forms";
import AppFormPasswordField from "../../AppComponents/Forms/AppFormPasswordField";
import loginIllustration from "../../assets/images/loginIllustration.svg";
import logo from "../../assets/images/logo.svg";
import {
  BrandBox,
  DetailsBox,
  ErrorText,
  LeftSide,
  LinkText,
  LoginPageWrap,
  LogoText,
  RightSide,
  SubTitleText,
  TitleText
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { NewPasswordSchema } from "../../Models/ValidationSchema";
import { setNewPassword } from "../../Store/Slices/AuthSlice";

const NewPasswordPage = () => {
  let navigate = useNavigate();
  let { useremail } = useParams();

  const dispatch = useDispatch();
  let { showAlert } = useNotification();


  const [invalidMessage, setInvalidMessage] = useState<string>("");
  const [userInput, setUserInput] = useState<any>({
    password: "",
    confirmPassword: ""
  });
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setNewPassword(dispatch)({
      code: useremail,
      password: values.password
    }).then(
      (data: any) => {
        navigate("/");
        showAlert('Password was changed succesfully!', 'success');
      },

      (err: any) => {
        SubmitProps.setSubmitting(false);
        SubmitProps.resetForm();
        setInvalidMessage("" + err.response.data.message);
      }
    );
    setUserInput(values);
  };

  const redirectLogin = () => {
    navigate("/login");
  };

  return (
    <LoginPageWrap>
      <LeftSide>
        <img src={loginIllustration} alt="" />
      </LeftSide>
      <RightSide>
        <DetailsBox>
          <BrandBox>
            <img src={logo} alt="" />
            <LogoText>Geta</LogoText>
          </BrandBox>
          <TitleText>Change Password</TitleText>
          <SubTitleText
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              color: "#282828",
            }}
          >
          </SubTitleText>
          <ErrorText>{invalidMessage}</ErrorText>

          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
              setUserInput(values);
            }}
            validationSchema={NewPasswordSchema}

          >
            <AppFormPasswordField
              label="Password"
              name="password"
              placeholder="Xpi9B*Ne8G6@"
            // onKeyDown={(e: any) => handlePressEnter(e)}
            />
            <AppFormPasswordField
              label="Confirm Password"
              name="confirmPassword"
              placeholder="Xpi9B*Ne8G6@"
            // onKeyDown={(e: any) => handlePressEnter(e)}
            />



            <AppSubmitButton
              variant="cta"
              style={{ width: "100%", margin: "0.5rem 0" }}
              title={"Update Password"}
            />
          </AppForm>

          <LinkText
            sx={{
              marginTop: "1rem",
            }}
            variant="subtitle2"
            color={theme.palette.primary.main}
          >
            Back to  <span onClick={() => redirectLogin()}> Login</span>
          </LinkText>
        </DetailsBox>
      </RightSide>
    </LoginPageWrap>
  );
};

export default NewPasswordPage;
