import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  allMessagesStore, setIsSearchBoxVisible
} from "../../Store/Slices/socket/AllMessagesSlice";

import { StyledChatUIBoxWrap } from "../Styles/StyledScreens";

import InputBoxScreen from "./InputBoxScreen";
import MainChatTopHeader from "./Chats/MainChatTopHeader/MainChatTopHeader";
import AppChatScreenWrapper from "../appComponents/UtilsComponents/AppChatScreenWrapper";

interface Props {}

const MainUI: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { sessionId, userInfoData, allMessages, isSearchBoxVisible } = useAppSelector(allMessagesStore);

  const toggleSearchBox = () => { 
    dispatch(setIsSearchBoxVisible(!isSearchBoxVisible));
  }
  
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      // Check if the user presses Control (or Command on macOS) + F
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        // Open your custom search functionality here
         // Prevent the default browser search action
        event.preventDefault();
        toggleSearchBox();
        // Add your custom search logic here
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('keydown', handleKeyPress);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isSearchBoxVisible]); // eslint-disable-line react-hooks/exhaustive-deps
  
  if (!sessionId || allMessages.length === 0) {
    return null;
  }

  return (
    <StyledChatUIBoxWrap chatChannel={userInfoData?.channel}>
     <MainChatTopHeader/>
      <AppChatScreenWrapper />
      <InputBoxScreen />
    </StyledChatUIBoxWrap>
  );
};

export default React.memo(MainUI);