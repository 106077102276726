import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm, AppFormSwitchField,
  AppSubmitButton
} from "../../../../../AppComponents/Forms";
import AppFormSliderField from "../../../../../AppComponents/Forms/AppFormSliderField";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import { NotificationsPreferencesSchema } from "../../../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { LoaderComponentProps, withLoader } from "../../../../../AppComponents/Loader";

interface Props extends LoaderComponentProps {
  // children?: React.ReactNode;
}


const NotificationsPreferences:React.FC<Props> = ({setLoading}) => {
  const { showAlert } = useNotification();
  const currentBot = useSelector(useCurrentBot);
  const [userInput, setUserInput] = useState<any>({
    enabled: false,
    volume_level: 50,
    // sound_file:''
    // desktop: true,
    // mobile: false,
    // tablet: false,
  });

  const getPreviousData = () => {
    ChatbotConsoleService.getBotConfiguration(
      currentBot?.chatbot_configuration as string
    ).then((res: AxiosResponse<ChatbotConfiguration>) => {
      if (res.data?.message_tone) {

        setUserInput({
          enabled: res.data?.message_tone?.enabled ? res.data?.message_tone?.enabled : false,
          volume_level: res.data?.message_tone?.volume_level ? res.data?.message_tone?.volume_level : 50,
        });
      }
    }).finally(()=>setLoading(false));
  }

  useEffect(() => {
    if (currentBot && currentBot?.chatbot_configuration) {
      getPreviousData();
    }
    else {
      setLoading(false);
    }
  }, [currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          message_tone: {
            enabled: values.enabled,
            volume_level: values.volume_level,
          },
        },
      }
    )
      .then((response) => {
        SubmitProps.setSubmitting(false);
        SubmitProps.resetForm();
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        SubmitProps.setSubmitting(false);
        showAlert(error.response.statusText, "error");
      });
  };

  return (
    <AppSmallWindowPane   style={{
      minWidth: "55%",
      borderRadius: "8px",
      padding: "1.1rem 1.5rem 0rem 1.5rem",
      position: "relative",
    }}>
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={NotificationsPreferencesSchema}
      >
        <AppFormSwitchField
          label="Allow Chat notification sound"
          name="enabled"
        />
        <AppFormSliderField name="volume_level" />
        <AppSubmitButton
          variant="cta"
          style={{ width: "100%", margin: "0.5rem 0" }}
          title={"Save Changes"}
        />
      </AppForm>
    </AppSmallWindowPane>
  );
};

export default withLoader(NotificationsPreferences);
