import React, { FunctionComponent } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useAppSelector } from "../../../Store";
import { AnalyticsLoadingArray } from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { kFormatter } from "../../../Utils";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "../AnalyticsSummaryStyles";
import AssignLogo from "../Dash_Assests/Assigned.svg";
import OpenLogo from "../Dash_Assests/Open.svg";
import PendingLogo from "../Dash_Assests/Pending.svg";
import UnassignedLogo from "../Dash_Assests/Unassigned.svg";
import UnattendedLogo from "../Dash_Assests/Unattended.svg";
import ClosedLogo from "../Dash_Assests/closed_conversations.svg";
import {
  SkeletonCard,
  SkeletonSkeletonDoughnutCard,
  SkeletonSquareIconCard,
} from "../SkeletonLoader.tsx/SkeletonCard";
interface Props {
  conversationStats: any;
}

const ConversationStatus: FunctionComponent<Props> = (props: Props) => {
  const { conversationStats } = props;
  const loadingArray: any = useAppSelector(AnalyticsLoadingArray);
  return (
    <React.Fragment>
      <p className="conversation_header">
        <p className="conversation_header_text">
          <TextTitle>Conversation Status</TextTitle>
        </p>
        <AppToolTip
          tooltipProps={{
            title:
              "Displays key conversation status metrics of agents and bots",
            placement: "top",
          }}
          iconButtonProps={{ disableRipple: true }}
          IconComponent={<InfoOutlinedIcon className="g_div_tooltip" />}
        />
      </p>

      <div className="status_container_parent">
        <div className="status_box">
          <p className="conversation_status_count">
            {loadingArray.includes("conversationStatus") ? (
              <SkeletonCard />
            ) : (
              kFormatter(conversationStats.totalConversations)
            )}
          </p>
        </div>
        <div className="status_container">
          <div className="status">
            <center>
              {loadingArray.includes("conversationStatus") ? (
                <SkeletonSquareIconCard />
              ) : (
                <img src={OpenLogo} alt="Not Found" className="" />
              )}
              <p className="status_text open_text">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonCard />
                ) : (
                  "Open"
                )}
              </p>
              <div className="status_count">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonSkeletonDoughnutCard />
                ) : (
                  kFormatter(conversationStats.openConversations)
                )}
              </div>
            </center>
          </div>

          <div className="status">
            <center>
              {loadingArray.includes("conversationStatus") ? (
                <SkeletonSquareIconCard />
              ) : (
                <img src={PendingLogo} className="" alt="Not Found" />
              )}
              <p className="status_text">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonCard />
                ) : (
                  "Pending"
                )}
              </p>
              <div className="status_count">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonSkeletonDoughnutCard />
                ) : (
                  kFormatter(conversationStats.pendingConversations)
                )}
              </div>
            </center>
          </div>

          <div className="status">
            <center>
              {loadingArray.includes("conversationStatus") ? (
                <SkeletonSquareIconCard />
              ) : (
                <img src={ClosedLogo} className="" alt="Not Found" />
              )}
              <p className="status_text">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonCard />
                ) : (
                  "Closed"
                )}
              </p>
              <div className="status_count">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonSkeletonDoughnutCard />
                ) : (
                  kFormatter(conversationStats.closeConversations)
                )}
              </div>
            </center>
          </div>
        </div>

        <hr className="dotted_hr"></hr>
        <div className="status_container">
          <div className="status">
            <center>
              {loadingArray.includes("conversationStatus") ? (
                <SkeletonSquareIconCard />
              ) : (
                <img src={AssignLogo} className="" alt="Not Found" />
              )}
              <p className="status_text">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonCard />
                ) : (
                  "Assigned"
                )}
              </p>
              <div className="status_count">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonSkeletonDoughnutCard />
                ) : (
                  kFormatter(conversationStats.assginedConversations)
                )}
              </div>
            </center>
          </div>
          <div className="status">
            <center>
              {loadingArray.includes("conversationStatus") ? (
                <SkeletonSquareIconCard />
              ) : (
                <img src={UnassignedLogo} className="" alt="Not Found" />
              )}
              <p className="status_text">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonCard />
                ) : (
                  "Unassigned"
                )}
              </p>
              <div className="status_count">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonSkeletonDoughnutCard />
                ) : (
                  kFormatter(conversationStats.unassignedConverstations)
                )}
              </div>
            </center>
          </div>
          <div className="status">
            <center>
              {loadingArray.includes("conversationStatus") ? (
                <SkeletonSquareIconCard />
              ) : (
                <img src={UnattendedLogo} className="" alt="Not Found" />
              )}
              <div className="status_text">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonCard />
                ) : (
                  "Unattended"
                )}
              </div>
              <div className="status_count">
                {loadingArray.includes("conversationStatus") ? (
                  <SkeletonSkeletonDoughnutCard />
                ) : (
                  kFormatter(conversationStats.unattendedConversations)
                )}
              </div>
            </center>
          </div>
        </div>
        <div></div>
      </div>
    </React.Fragment>
  );
};

export default ConversationStatus;
