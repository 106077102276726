import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApplicationFormNetworkService } from "./applicationFormNetworkService";

export class ApplicationFormNetworkActions {
    //* CREATE MARKETING LIST  //
    static getApplicationFormsList = createAsyncThunk(
        "broadcastSlice/createMarketingList",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.getApplicationFormsList(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    static getFormProperties = createAsyncThunk(
        "broadcastSlice/getFormProperties",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.getFormProperties(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );


    static getApplicationFieldsMapping = createAsyncThunk(
        "broadcastSlice/getApplicationFieldsMapping",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.getApplicationFieldsMapping().then(async (response: any) => {
                return response;
            })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        })

    // Get Paymet Details
    static getAttachmentData = createAsyncThunk(
        "ContactSlice/getAttachmentData",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.getAttachmentNetwork(data)

                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }

    );

    // Application Forms
    // Make Payment Offline
    static markPaymentOffline = createAsyncThunk(
        "ContactSlice/markPaymentOffline",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.markPaymentOfflineNetwork(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Get Paymet Details
    static getPaymentDetails = createAsyncThunk(
        "ContactSlice/getPaymentDetails",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.getPaymentDetailsNetwork(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Change Form Status To Draft
    static changeStatusToDraft = createAsyncThunk(
        "ContactSlice/changeStatusToDraft",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.changeStatusToDraftNetwork(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Mark As Reviewed
    static markAsReviewed = createAsyncThunk(
        "ContactSlice/markAsReviewed",
        (id: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.markAsReviewedNetwork(id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    // Marked As Not Reviewed
    static markedAsNotReviewed = createAsyncThunk(
        "ContactSlice/markedAsNotReviewed",
        (id: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.markedAsNotReviewedNetwork(id)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    //Update Document Status
    static updateAttachmentStatus = createAsyncThunk(
        "ContactSlice/updateAttachmentStatus",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.updateAttachmentStatusNetwork(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static getApplicationFormPdf = createAsyncThunk(
        "broadcastSlice/getApplicationFormPdf",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.getApplicationFormPdf(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
    // get bulk excel record
    static bulkApplicationsExport = createAsyncThunk(
        "ContactSlice/bulkExport",
        (data: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.bulkApplicationsExport(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        },
    );
    //getApplicationPropertyOptions
    static getApplicationPropertyOptions = createAsyncThunk(
        "ContactSlice/getApplicationPropertyOptions",
        (optionId: any, { dispatch, rejectWithValue }) => {
            return ApplicationFormNetworkService.getApplicationPropertyOptions(optionId)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        },
    );
}