// vendors
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem, { ListItemProps } from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

interface IListItemProps extends ListItemProps {
  openChild?: boolean;
  disableVerticlePadding?: boolean;
}

export const NoResultFoundStyled = styled(Typography)`
  font-weight: 500;
  color: ${(props) => props.theme.palette.default.black};
  padding: 0px 16px;
`;

export const NoNodeExistStyled = styled(Typography)`
  padding: 8px 16px;
  border: 1px solid ${(props) => props.theme.palette.default.lightGrey};
  text-align: center;
`;

export const ContainerStyled = styled(Box)`
  min-width: 350px;
  max-width: 350px;
  box-shadow: 0px 2px 4px;
    ${(props) => alpha(props.theme.palette.common.black, 0.08)};
  height: 100%;
  background-color: ${(props) => props.theme.palette.common.white};
`;

export const ToolbarStyled = styled(Toolbar)`
  padding: 3px;
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: 3px 16px;
  }
`;

export const ListStyled = styled(List)`
  padding-top: 0;
  overflow-y: auto;
  height: calc(100% - 150px);
  padding-bottom: 20px;
`;

export const ListItemStyled = styled(ListItem, {
  shouldForwardProp: (prop) => {
    return prop !== "openChild" && prop !== "disableVerticlePadding";
  },
})<IListItemProps>(({ openChild, disableVerticlePadding, theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
    ".hoverHide": {
      display: "none",
    },
    ".hoverShow": {
      display: "flex",
    },
  },
  borderTop: `1px solid ${theme.palette.secondary.main}`,
  "&:last-of-type": {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  ...(openChild && { backgroundColor: theme.palette.secondary.main }),
  ...(disableVerticlePadding && { paddingTop: 0, paddingBottom: 0 }),
}));

export const ListItemButtonStyled = styled(ListItemButton)`
  padding-right: 16px !important;
  padding-left: 16px !important;
  &:hover {
    background-color: transparent;
  }
`;

export const NodeListItemStyled = styled(ListItem)`
  padding-left: 40px !important;
  padding-right: 16px !important;
`;

export const NodeCountTextStyled = styled(Typography)`
  padding: 12px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteButtonWrapperStyled = styled(Box)`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 40px;
`;