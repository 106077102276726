import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { IconButton, Tooltip } from "@mui/material";
import Grow from "@mui/material/Grow";
import { getIn, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { marketingListActions } from "../../../Store/Slices/MarketingList/marketingList.actions";
import { marketingListStore } from "../../../Store/Slices/MarketingList/marketingList.selectors";
import { MEDIA_FILE_UPLOAD_STATUS, getMediaSizeStatus } from "../../../Utils";
import { AppErrorMessage } from "../../Forms";

const IMAGE_TYPES = ["image/jpeg", "image/png"];
const VIDEO_TYPES = ["video/mp4", "video/3gp"];
const AUDIO_TYPES = ["audio/aac", "audio/mp4", "audio/mpeg", "audio/amr"];
const STICKER_TYPES = ["image/webp"];

const DOCUMENT_TYPES = [
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
}

// interface Props extends LoaderComponentProps {
interface Props extends HocBackdropLoaderProps {
  name: string;
  mediaType: string;
  extensions?: string[];
  sizeLimit?: number;
  divStyle?: any;
}

const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "video") {
    return VIDEO_TYPES;
  } else if (mediaType === "image") {
    return IMAGE_TYPES;
  } else if (mediaType === "document") {
    return DOCUMENT_TYPES;
  } else if (mediaType === "sticker") {
    return STICKER_TYPES;
  } else if (mediaType === "audio") {
    return AUDIO_TYPES;
  } else {
    return [];
  }
};
const getCorrectMediaSize = (mediaType: string) => {
  if (mediaType === "video") {
    return 16000000;
  } else if (mediaType === "image") {
    return 5000000;
  } else if (mediaType === "document") {
    return 10000000;
    // return 1024000000;
  } else if (mediaType === "sticker") {
    return 1000000;
  } else if (mediaType === "audio") {
    return 16000000;
  } else {
    return 500000;
  }
};

const MediaPicker = (props: Props) => {
  const {
    setFieldTouched,
    values,
    setFieldValue,
    setFieldError,
    errors,
    touched,
  } = useFormikContext<any>();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const { createMarketingListData }: any = useAppSelector(marketingListStore);
  const [mainState, setMainState] = useState<ImageState>(ImageState.INITIAL); // initial, search, gallery, uploaded
  const [allowedMediaTypes] = useState<string[]>(
    props?.extensions || getCorrectMediaFormats(props.mediaType)
  );
  const [allowedMediaSize] = useState<number>(
    props?.sizeLimit || getCorrectMediaSize(props.mediaType)
  );
  useEffect(() => {
    if (
      getIn(values, props?.name)?.source &&
      getIn(values, props?.name)?.source?.length > 0
    ) {
      setMainState(ImageState.UPLOADED);
    } else {
      setMainState(ImageState.INITIAL);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUploadClick = (event: any) => {

    var file = event.target?.files[0];
    const reader: any = new FileReader();
    if (!reader || !file) {
      imageResetHandler();
      return null;
    }
    var url = reader?.readAsDataURL(file);

    if (allowedMediaSize && file.size > allowedMediaSize) {

      showAlert(
        getMediaSizeStatus(allowedMediaSize / 1000000),
        "error"
      );
      setTimeout(() => {
        setFieldError(
          props.name,
          getMediaSizeStatus(allowedMediaSize / 1000000)
        );

      }, 10);
      return;
    }

    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      if (!allowedMediaTypes.includes(mimeType)) {
        // console.warn("wrong format");
        showAlert(
          MEDIA_FILE_UPLOAD_STATUS.FORMAT,
          "error"
        );
        setTimeout(() => {
          setFieldError(props.name, MEDIA_FILE_UPLOAD_STATUS.FORMAT);
        }, 10);
        return;
      }
      onImageChange(file);
    };
  };

  const onImageChange = (file: any) => {

    if (file) {
      props.setLoading(true);
      const formData = new FormData();
      formData.append("source", file);
      formData.append("list_id", createMarketingListData?.id);
      formData.append("type", createMarketingListData?.type);

      const config: any = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;

          let percent = Math.floor((loaded * 100) / total);
        },
      };

      let temp = {
        formData: formData,
        config: config,
      };

      dispatch(marketingListActions.uploadDocumentFile(temp))
        .then((res: any) => {
          if (res?.payload.data?.response) {
            setFieldValue(props?.name, res?.payload.data?.response);
            setTimeout(() => {
              setMainState(ImageState.UPLOADED);
            }, 500);
            showAlert(MEDIA_FILE_UPLOAD_STATUS.SUCCESS, "success");
          } else {
            showAlert(
              res?.payload.data?.error ||
              MEDIA_FILE_UPLOAD_STATUS.ERROR_UNDEFINED,
              "error"
            );
            // console.warn("error while creating attachment", res?.payload.data);
            setMainState(ImageState.INITIAL);
            setFieldValue(props?.name, null);
          }
        })
        .catch((err: any) => {
          showAlert(
            err?.error || MEDIA_FILE_UPLOAD_STATUS.ERROR_UNDEFINED,
            "error"
          );
          // console.warn("error while creating attachment", err);
          setMainState(ImageState.INITIAL);
          setFieldValue(props?.name, null);
        })
        .finally(() => {
          setTimeout(() => {
            props.setLoading(false);
          }, 1000);
        });
    }
  };

  useEffect(() => {
    setFieldTouched(props.name, false);
  }, [])

  const imageResetHandler = () => {
    setMainState(ImageState.INITIAL);
    setFieldValue(props?.name, null);
  };

  const renderInitialState = () => {
    return (
      <StyledIntialState>
        <input
          accept={allowedMediaTypes.toString()}
          id={props.name}
          name={props.name}
          type="file"
          onChange={handleUploadClick}
          value=""
        />
        <label htmlFor={props.name}>
          <Tooltip
            arrow
            enterDelay={1}
            leaveDelay={0}
            TransitionComponent={Grow}
            placement="top"
            title={"Upload"}
          >
            <MediaUploadBox variant="text">
              Upload {props.mediaType}
            </MediaUploadBox>
          </Tooltip>
        </label>
      </StyledIntialState>
    );
  };

  const renderUploadedState = () => {
    const file: any = getIn(values, props?.name);
    return (
      <StyledUploadedState>
        <StyledUploadedImageWrap>
          {file?.file_name}
          <Tooltip
            arrow
            enterDelay={1}
            leaveDelay={0}
            TransitionComponent={Grow}
            placement="top"
            title={"Delete"}
          >
            <IconButton onClick={() => imageResetHandler()}>
              <ClearRoundedIcon />
            </IconButton>
          </Tooltip>
        </StyledUploadedImageWrap>
      </StyledUploadedState>
    );
  };
  return (
    <>
      <StyledViewWrap style={props?.divStyle}>
        {(mainState === ImageState.INITIAL && renderInitialState()) ||
          (mainState === ImageState.UPLOADED && renderUploadedState())}
        <AppErrorMessage
          error={getIn(errors, props.name)}
          visible={getIn(touched, props.name)}
          style={{ color: theme.palette.default.error }}
        />
      </StyledViewWrap>
    </>
  );
};

export default HocBackdropLoader(MediaPicker);

const StyledUploadedImageWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      cursor: pointer;
      font-size: 1.1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
const MediaUploadBox = styled.div`
  && {
    word-break: keep-all;
    white-space: nowrap;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40px;
    height: 40px;

    padding: 6px 12px;
    border-radius: 6px;
    background-color: transparent;
    color: ${theme.palette.primary.main};
    border: none;
    outline: none;
    cursor: pointer;
    font-family: ${theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    &:disabled {
      color: ${theme.palette.default.grey};
    }
    &:hover {
      color: ${theme.palette.primary.dark};
    }
    svg {
      font-size: 18px;
      margin: 0 4px;
    }
  }
`;

const StyledViewWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    padding: 0 0.5rem;
    margin: -1rem 0 1rem;
    input {
      display: none;
    }
  }
`;

const StyledIntialState = styled.div`
  && {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 1rem;

    .MuiFab-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
    }

    .MuiIconButton-root {
      width: 48px !important;
      height: 48px !important;
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.secondary.main};
      border-radius: 50%;
    }
  }
`;

const StyledUploadedState = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    align-items: center;

    img {
      width: 52px !important;
      height: 52px !important;
      object-fit: cover !important;
      overflow: hidden;
    }
  }
`;
