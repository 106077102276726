// vendors
import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

// component
import MessagePreview from "../../Components/MessagePreview";
import Editor from "../../Components/Editor";
import Configurations from "./Configurations";

// slice
import {
  getLanguage,
  getActiveNode,
  updateModifiedData,
  setInputValues,
} from "../../Slices/main.slice";
import { useAppDispatch } from "../../../../Store";

// utils
import {
  getResponse,
  getText,
  getInitialEditiorText,
  getPlainTextData,
  getHTMLContent,
  cleanMediaDetails,
  getMediaDetails,
} from "../../Utils";
import { getChannelWiseText } from "../../Utils/editor";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const ImgFileNode = (props: NodeProps) => {
  const { node } = props;
  const { channel } = useSelector(useCurrentBot);
  const language = useSelector(getLanguage);
  const activeNode = useSelector(getActiveNode);
  const response = getResponse(node, language);
  const text = getText(response);
  const media = getMediaDetails(response);
  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || ""),
  );
  const isActiveNode = activeNode === node.nodeId;
  const dispatch = useAppDispatch();

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setEditorState(editorState);
    if (cleanMediaDetails(mediaInfo)) {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getChannelWiseText(getHTMLContent(editorState), channel),
            type: "text",
            media: cleanMediaDetails(mediaInfo),
          },
        ]),
      );
    } else {
      dispatch(
        updateModifiedData([
          {
            id: uuid(),
            value: getChannelWiseText(getHTMLContent(editorState), channel),
          },
        ]),
      );
    }
  };

  const handleSave = (selectedExt: any) => {
    dispatch(setInputValues({ value: selectedExt, type: "img-file" }));
  };

  return (
    <>
      <Box p={"8px 16px"}>
        {isActiveNode ? (
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            error={getPlainTextData(editorState) === ""}
            mediaInfo={media}
            media={false}
          />
        ) : (
          <MessagePreview text={text} mediaDetails={media} />
        )}
      </Box>
      {isActiveNode && (
        <Fragment>
          <Divider />
          <Box p={"16px"} display={"flex"} justifyContent={"space-between"}>
            <Typography>Allow Extensions</Typography>
            <Configurations
              onSave={handleSave}
              extensions={node?.allowedFileTypes || []}
            />
          </Box>
        </Fragment>
      )}
    </>
  );
};
export default ImgFileNode;
