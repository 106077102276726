import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppButton from "../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { AppForm, AppFormField, AppSubmitButton } from "../../../AppComponents/Forms";
import { LoaderComponentProps, withLoader } from "../../../AppComponents/Loader";
import AppModel from "../../../Hooks/useModel/AppModel";
import useModal from "../../../Hooks/useModel/useModel";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { EmailSettingsActions } from "../../../Store/Slices/Settings/EmailSettings/emailSettings.actions";
import { addressLists, error, isEmailEnabled } from "../../../Store/Slices/Settings/EmailSettings/emailSettings.selectors";
import { makeEmailEnabled, resetError } from "../../../Store/Slices/Settings/EmailSettings/emailSettings.slice";
import { ReactComponent as EnableEmail } from "../../../assets/images/settings/email/email-seetings.svg";
import DomainVerification from "./Domain/DomainVerification";
import EmailVerification from "./EmailVerification";
import FooterSetup from "./FooterSetup";
import { AddEmailWrapper, CenterWrapper, ContentWrapper, EmailSettingsWrapper, NoRecordWrapper } from "./Styles";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            key={index}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const EmailSettings: React.FC<LoaderComponentProps> = ({
    setLoading
}) => {
    const dispatch = useAppDispatch();
    const { userConfig }: any = useAppSelector(useAuthStore);
    const { showAlert } = useNotification();
    const [addAddress, setAddAddress] = useState<boolean>(false);
    const isChannelEnabled: any = useAppSelector(isEmailEnabled);
    const channelError: any = useAppSelector(error);
    const addresses = useAppSelector(addressLists);
    const [value, setValue] = useState(0);
    const [stepNumber] = useState(1);
    const [inputEmailForm] = useState({ email: "" });
    const { isShowing, toggle } = useModal();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        setLoading(true);
        dispatch(EmailSettingsActions.validateEmailList({}));
        dispatch(EmailSettingsActions.getAddressesList({}));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (userConfig?.payload?.email_enabled) {
            dispatch(makeEmailEnabled({}));
        } else {
            setLoading(false);
        }
    }, [userConfig?.payload?.email_enabled]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (channelError) {
            showAlert((channelError || "Something Went Wrong."), "error");
            setTimeout(() => { resetError("") }, 1000);
        }
    }, [channelError, isEmailEnabled]) // eslint-disable-line react-hooks/exhaustive-deps
    const enableEmailChannel = () => {
        dispatch(EmailSettingsActions.enableEmailChannel({}));
    }
    const onSubmit = (values: any, submittingProps: any) => {
        let data = {
            emailAddress: values?.email
        }
        dispatch(EmailSettingsActions.addEmail(data))
            .then((res: any) => {
                if (res?.payload?.data?.status === 200) {
                    dispatch(EmailSettingsActions.getAddressesList({}));
                    dispatch(EmailSettingsActions.getValidEmailLists({}));
                    toggle(false);
                    showAlert("Email Added Successfully", "success");
                } else {
                    showAlert(res?.payload?.data?.error || "Something went wrong", "error");
                }
            })
            .catch((err: any) => {
                showAlert("Something went wrong", "error");
                toggle(false)
            })
            .finally(() => {
                submittingProps.setSubmitting(false)
            });
    }
    return (
        <EmailSettingsWrapper>
            <Box className="headerWrapper">
                Email
            </Box>
            {!isChannelEnabled ? (
                <CenterWrapper>
                    <NoRecordWrapper>
                        <EnableEmail />
                    </NoRecordWrapper>
                    <ContentWrapper>
                        <h3>Enable the email module and unlock powerful communication tools</h3>
                        <p>Seamlessly broadcast emails, customize sending domains, andset up email footers to enhance your email campaigns.</p>
                        <AppButton onClick={enableEmailChannel}>
                            Enable
                        </AppButton>
                    </ContentWrapper>
                </CenterWrapper>
            ) :

                (<Box sx={{ width: '100%', height: "100%" }}>
                    <Box sx={{ pt: "1rem", pb: "1rem", borderBottom: 'none', borderColor: 'divider', display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: '1rem' }}>
                        <div className="tabs-container">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Domain setup" {...a11yProps(0)} />
                                <Tab label="Email verification" {...a11yProps(1)} />
                                <Tab label="Footer setup" {...a11yProps(2)} />
                            </Tabs>
                        </div>
                        {value === 1 ? (<div className="add-address__btn">
                            <AppButton variant="cta" onClick={() => toggle()}>
                                Add New Email
                            </AppButton>
                        </div>) : null}
                        {value === 2 && addresses?.length ? (<div className="add-address__btn">
                            <AppButton variant="cta" onClick={() => setAddAddress(true)}>
                                Add New Address
                            </AppButton>
                        </div>) : null}
                    </Box>
                    <TabPanel value={value} index={0}>

                        <DomainVerification setLoading={setLoading} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <EmailVerification />
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <FooterSetup setAddAddress={setAddAddress} addAddress={addAddress} />
                    </TabPanel >
                </Box >)}
                
            <AppModel
                isShowing={isShowing}
                onClose={() => toggle()}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "750px",
                    },
                }}
            >
                <AddEmailWrapper>
                    <div className="header">
                        <h3>Add Email<span>Step {stepNumber}</span></h3>
                        <IconButton onClick={() => toggle()}>
                            <AppMaterialIcons iconName="close" />
                        </IconButton>
                    </div>
                    <div className="body">
                        {stepNumber === 1 ?
                            <React.Fragment>
                                <div>Enter an email address you want to use as your “from” email.</div>
                                <AppForm initialValues={inputEmailForm} onSubmit={onSubmit}>
                                    <div className="input">
                                        <AppFormField label="Email Address" name="email" divStyle={{ width: "auto" }} />
                                    </div>
                                    <div className="footer">
                                        <AppButton variant="grey" onClick={() => toggle()}>
                                            Cancel
                                        </AppButton>
                                        <AppSubmitButton title="Add" />
                                    </div>
                                </AppForm>
                            </React.Fragment> : <React.Fragment>
                                <div>Enter an email address you want to use as your “from” email.</div>
                                <AppForm initialValues={inputEmailForm} onSubmit={() => { }}>
                                    <div className="input">
                                        <AppFormField label="Email Address" name="email" divStyle={{ width: "auto" }} />
                                    </div>
                                    <div className="footer">
                                        <AppButton variant="grey" onClick={() => toggle()}>
                                            Cancel
                                        </AppButton>
                                        <AppSubmitButton title="Add" />
                                    </div>
                                </AppForm>
                            </React.Fragment>}
                    </div>
                </AddEmailWrapper>
            </AppModel>
        </EmailSettingsWrapper >
    )
}
export default withLoader(EmailSettings);