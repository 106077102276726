import React, { Dispatch, SetStateAction } from "react";
import { whatsappDataStore } from "../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { useAppSelector } from "../../../../../Store";
import ScheduleBroadcast from "../../../../../AppComponents/Forms/AppScheduleBroadcast";
import { marketingListStore } from "../../../../../Store/Slices/MarketingList/marketingList.selectors";
interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
}
const RenderFormStep4: React.FC<Props> = ({ formStep, setFormStep }) => {
  const {broadcastByIdData }: any = useAppSelector(marketingListStore);
  if (formStep !== 4) {
    return null;
  }
  return (
    <ScheduleBroadcast broadcastByIdData = {broadcastByIdData} />
  );
};

export default RenderFormStep4;
