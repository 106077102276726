import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const MainChatTopHeaderWrapper = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(207, 215, 223, 0.4);
  justify-content: space-between;
  /* overflow: hidden;*/ // do not enable this otherwise the select options will be hidden
  background-color: ${theme.palette.default.white};
  width: 100%;
  z-index: 999;
  min-height: 83px;
  .left {
    display: flex;
    align-items: center;
    // width: calc(100% - 163px);
    .userAvatar {
      // width: 150px;
      .body {
        h5 {
          display: block !important;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }

        .channel {
          display: flex;
          align-items: center;
          gap: 4px;
          p {
            font-size: 0.8rem;
            font-weight: 400;
            color: ${theme.palette.default.darkGrey};
            cursor: pointer;
          }
          img {
            object-fit: contain;
          }
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    .search-assign {
      display: flex;
      justify-content: space-between;
      // gap: 1rem;
      width: 100%;
      .search {
        form {
          div {
            min-width: 246px;
            max-width: 246px;
          }
        }
      }
      .select-agent {
        display: flex;
        gap: 8px;
        .agentSelection {
          min-width: 200px;
          max-width: 200px;
        }
        align-items: center;
        p {
          width: 40%;
          font-size: 12px;
        }
        form {
          width: 100%;
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
    margin-right: 40px;
    width: 50%;
    .status {
      min-width: 170px;
      max-width: 170px;
    }

    .select-agent {
      display: flex;
      gap: 8px;
      align-items: center;
      .agentSelection {
        min-width: 180px;
        max-width: 180px;
      }
      .custom-btn {
        display: flex;
        align-items: center;
        background-color: ${theme.palette.primary.main};
        box-shadow: none;
        border: 0;
        color: #fff;
        padding: 6px 7px;
        border-radius: 4px;
        cursor: pointer;
        gap: 8px;
      }
    }
    .search-assign {
      display: flex;
    }
  }
`;
export const UserAvatar = styled.div`
  && {
    // min-width: 200px;
    display: flex;
    background-color: #fff;
    padding: 0.675rem;
    .avatar {
    }
    .body {
      padding-left: 8px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      h5 {
        font-size: 0.875rem;
        font-weight: 500;
      }
      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${theme.palette.default.darkGrey};
      }
      .tags {
        display: flex;
        gap: 5px;
      }
    }
    .actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .action-top {
        gap: 0.5rem;
        display: flex;
        align-items: center;
        & .MuiSvgIcon-root {
          color: ${theme.palette.default.darkGrey};
          font-size: 1.1rem;
        }
      }
    }
  }
`;
