import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const WhatsappTemplateWrap = styled.div`
  && {
    background-color: ${theme.palette.default.lightPrimaryBackground};
    width: 100%;
    height: 100%;
    height: auto;
    overflow: overlay;
    padding: 0rem 0rem 0.5rem;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: flex-start;
    z-index: 1;

    .MuiTablePagination-root {
      height: 50px !important;
      min-height: 50px !important;
      width: 100%;
    }
    .MuiTablePagination-toolbar {
      height: 50px !important;
      min-height: 50px !important;
    }

    .MuiPaper-elevation1 {
      margin-bottom: 0px !important;
    }

    
  }
`;

export const StyledFieldArrayWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 1rem;
    .MuiFormControl-root {
      width: 100% !important;
    }
  }
`;
export const StyledContentBox = styled.div`
  && {
    form {
      label {
        font-weight: 500;
        color: #000;
      }
    }
    
    // display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 4rem;
    .formBox {
      

      .content_wrapper {
        // background-color: ${theme.palette.default.white};
        padding: 1rem;
        // margin: 1rem;
        // border-radius: 8px;
        .main_content{
          position: relative;
          background-color: ${theme.palette.default.white};
          padding: 16px;
          border-radius: 8px;
          // max-width: max-content;
        }
      }

      // width: 1151px;

      // background-color: ${theme.palette.default.white};
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: flex-start;
      // border-radius: 8px;
      gap: 1rem;
      overflow-x: hidden;
      .tableBody {
        max-height: none;
        overflow: hidden;
      }
      &.w-100 {
        width: 100%;
        margin: 0;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        .filterPanel {
            margin: 0 !important;
            border-radius: 0;
            .MuiGrid-container {
              display: flex;
              .d-flex  {
                margin: 0 5px 0 10px;
              }
              > .MuiGrid-root, .d-flex {
                align-items: center;
                display: flex;
                &:first-child {
                  color: #101010;
                  font-size: 16px;
                  font-weight: 500;
                  text-transform: capitalize;
                }
              }
            }
            .topBar {
              box-shadow: none;
            }
            
        } 
      }
      .btnWidth {
        width: 150px;
      
      }
      @media(max-width: 1149.98px) {
        .btnWidth {
          width: 100px;
        } 
      }
      .flexBox {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        button {
          height: 100%;
        }
      }

      .styledVariblesBox {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        width: 100%;
        .variablesTitle {
          font-family: ${theme.typography.fontFamily};
          font-weight: 500;
          font-size: 1.05rem;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: ${theme.palette.default.text};
          display: flex;
          align-items: center;
          text-align: left;
          gap: 5px;
        }
        .variablesType {
          font-family: ${theme.typography.fontFamily};
          font-weight: 400;
          font-size: 1rem;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: ${theme.palette.default.text};
          display: flex;
          align-items: center;
          text-align: left;
        }
        .variablesContent {
          display: flex;
        /* align-items: center; */
        gap: 2rem;
          
          .variableIndex{
            font-family: ${theme.typography.fontFamily};
          font-weight: 400;
          font-size: 1rem;
          line-height: 21px;
          letter-spacing: 0.5px;
          color: ${theme.palette.default.text};

          display: flex;
        align-items: center;
        text-align: left;
        gap: 5px;
        }
       
        }
      }
    }

    .BtnsBox{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      margin: 0.7rem 0;
      flex-wrap: wrap;
      margin-top: 2rem;
    }
   
  }
`;

export const StyledFieldArrayQuickRowWrapper = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding-right: 0.2rem;

    .removeBtnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .MuiIconButton-root {
      &:disabled {
        svg {
          color: ${theme.palette.default.border};
        }
      }
      svg {
        font-size: 20px;
        color: ${theme.palette.default.darkGrey};
      }
      &:hover {
        svg {
          color: red;
        }
      }
    }
  }
`;

export const TemplateListWrapper = styled.div`
    && {
        width: 100%;
        padding: 1rem;
        
    }
`;

export const TemplateTable = styled.div`
    && {
        .table-wrapper {
            .tabel-heading {
                h6 {
                    font-size: 1rem;
                    font-weight: 500;
                    font-family: ${theme.typography.fontFamily};
                }
            }

            .pending {
                color: ${theme.palette.default.text};
                font-weight: 600;
            }
            .rejected {
                color: ${theme.palette.default.error};
                font-weight: 600;
            }
            .success {
                color: ${theme.palette.default.success};
                font-weight: 600;
            }
        }
        
    }
`;
