import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { AppForm, AppFormField, AppFormImagePicker, AppFormRadioInput, AppSubmitButton } from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import AppModel from "../../../Hooks/useModel/AppModel";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../Store";
import { ApplicationFormNetworkActions } from "../../../Store/Slices/ApplicationsForm/applicationForm.actions";


const PAYMENT_OPTIONS = [
    { label: "Cash", value: "cash" },
    { label: "Cheque", value: "cheque" },
    { label: "Draft", value: "draft" }
]
interface HandleOfflinePaymentModalProps {
    isShowingOpenHandleOfflineModal: any;
    toggleOpenHandleOfflineModal: any;
    applicationFormResID: any
    getApplicationsFormsTableData: any;
}

const HandleOfflinePaymentModal: React.FC<HandleOfflinePaymentModalProps> = ({
    isShowingOpenHandleOfflineModal,
    toggleOpenHandleOfflineModal,
    applicationFormResID,
    getApplicationsFormsTableData
}) => {
    const [userInput, setUserInput] = React.useState({
        paymentMethod: "cash",
        amount: "",
        cheque_image: "",
        cheque_number: "",
        draft_number: "",
        draft_image: "",
    })
    const [localValues, setlocalValues] = React.useState<any>(null);
    let { showAlert } = useNotification();
    const dispatch = useAppDispatch();
    const sendValuesToParent = (values: any): void => {
        setlocalValues(values);
    };
    const ref = useRef(null)
    const handleSubmit = (values: any, submitProps: any) => {
        if (applicationFormResID) {
            let data;
            if (values.paymentMethod === "cash") {
                data = {
                    "applicationResID": {
                        "id": applicationFormResID
                    },
                    "payloadData": {
                        "offline_payment_type": values.paymentMethod,
                        "amount": +values.amount,
                    }
                };
            }

            if (values.paymentMethod === "cheque") {
                data = {
                    "applicationResID": {
                        "id": applicationFormResID
                    },
                    "payloadData": {
                        "offline_payment_type": values.paymentMethod,
                        "amount": +values.amount,
                        "cheque_image": values?.cheque_image?.id,
                        "cheque_number": +values?.cheque_number
                    }
                };
            }

            if (values.paymentMethod === "draft") {
                data = {
                    "applicationResID": {
                        "id": applicationFormResID
                    },
                    "payloadData": {
                        "offline_payment_type": values.paymentMethod,
                        "amount": +values.amount,
                        "draft_image": values?.draft_image?.id,
                        "draft_number": +values?.draft_number
                        // "draft_image_url": values?.draft_image_url?.id
                    }
                };
            }
            dispatch(ApplicationFormNetworkActions.markPaymentOffline(data)).then((res: any) => {
                if (res.payload.status === 200) {
                    showAlert("The mode of payment has been added successfully.", "success");
                    getApplicationsFormsTableData();
                    setlocalValues({});
                    setUserInput({
                        paymentMethod: "cash",
                        amount: "",
                        cheque_image: "",
                        cheque_number: "",
                        draft_number: "",
                        draft_image: "",
                    })
                    toggleOpenHandleOfflineModal();
                } else if (res.payload.status === 400) {
                    showAlert(res?.payload?.data?.data, "error");
                    // getApplicationsFormsTableData();
                    setlocalValues({});
                    setUserInput({
                        paymentMethod: "cash",
                        amount: "",
                        cheque_image: "",
                        cheque_number: "",
                        draft_number: "",
                        draft_image: "",
                    })
                    toggleOpenHandleOfflineModal();
                } else {
                    showAlert("Something went wrong. Please try again.", "error");
                    setlocalValues({});
                    setUserInput({
                        paymentMethod: "cash",
                        amount: "",
                        cheque_image: "",
                        cheque_number: "",
                        draft_number: "",
                        draft_image: "",
                    })
                    toggleOpenHandleOfflineModal();
                }

            }).catch((err: any) => {
                showAlert("Something went wrong. Please try again.", "error");
                setlocalValues({});
                setUserInput({
                    paymentMethod: "cash",
                    amount: "",
                    cheque_image: "",
                    cheque_number: "",
                    draft_number: "",
                    draft_image: "",
                })
            });
        }
    }

    const handleMethodChange = (value: any) => {
        setUserInput({
            paymentMethod: value,
            amount: "",
            cheque_image: "",
            cheque_number: "",
            draft_number: "",
            draft_image: "",
        })
    }
    return (
        <AppModel
            isShowing={isShowingOpenHandleOfflineModal}
            onClose={() => toggleOpenHandleOfflineModal()}
            divStyle={{
                padding: 0,
            }}
            PaperProps={{
                style: {
                    boxShadow: "none",
                    width: "600px",
                },
            }}
        >
            <AppForm
                initialValues={userInput}
                onSubmit={(values: any, submitProps: any) => {
                    handleSubmit(values, submitProps)
                }}
                innerRef={ref}
                validationSchema={PaymentSchema}
            >
                <HandleOfflinePaymentModalWrapper>
                    <div className="header" >
                        <Typography variant='h6' fontSize={"18px"} > Offline Payment </Typography>
                        < Box onClick={() => {
                            toggleOpenHandleOfflineModal();
                            setUserInput({
                                paymentMethod: "cash",
                                amount: "",
                                cheque_image: "",
                                cheque_number: "",
                                draft_number: "",
                                draft_image: "",
                            })
                        }} className="close" >
                            <AppMaterialIcons iconName='Close' />
                        </Box>
                    </div>
                    < div className="body" >
                        <AppFormRadioInput
                            label="Select Payment Method"
                            name="paymentMethod"
                            Options={PAYMENT_OPTIONS}
                            onChange={handleMethodChange}
                        />
                        <AppFormField
                            placeholder="Enter ammount"
                            type="number"
                            name="amount" sendValuesToParent={(values: any) => sendValuesToParent(values)}
                        />

                        {localValues && localValues?.paymentMethod === "cheque" && (
                            <>
                                <AppFormField name="cheque_number" placeholder="Cheque Number" />

                                <AppFormImagePicker
                                    label="Upload Cheque Image"
                                    name="cheque_image"
                                    tooltip={
                                        <ul>
                                            <li>Your Image / gif must be smaller than 5 MB </li>
                                            < li > A PNG, JPEG, JPG or GIF works best </li>
                                        </ul>
                                    }
                                    sizeLimit={5000000}
                                    sizeErrorMsg={`File size exceeds 5MB`}
                                // formatErrorMsg={MEDIA_FILE_UPLOAD_STATUS.FORMAT}
                                />

                            </>
                        )}
                        {localValues && localValues?.paymentMethod === "draft" && (
                            <>
                                <AppFormField name="draft_number" placeholder="Enter DD Number" />
                                <AppFormImagePicker
                                    label="Upload DD Image"
                                    name="draft_image"
                                    tooltip={
                                        < ul >
                                            <li>Your Image / gif must be smaller than 5 MB </li>
                                            < li > A PNG, JPEG, JPG or GIF works best </li>
                                        </ul>
                                    }
                                    sizeLimit={5000000}
                                    sizeErrorMsg={`File size exceeds 5MB`}
                                // formatErrorMsg={MEDIA_FILE_UPLOAD_STATUS.FORMAT}
                                />
                            </>
                        )}
                    </div>
                    < div className="footer" >
                        <AppSubmitButton
                            title={"Submit"}
                        />
                    </div>
                </HandleOfflinePaymentModalWrapper>
            </AppForm>
        </ AppModel>
    );
}

export default HandleOfflinePaymentModal;

const HandleOfflinePaymentModalWrapper = styled.div`
&& {
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        height: 63px;
        border-bottom: 1px solid ${theme.palette.default.border};
        .close {
            cursor: pointer; 
        }
    }
    .body {
        padding: 16px;
        max-height: 340px;
        overflow-y: auto;
    }
    .footer {
        border-top: 1px solid ${theme.palette.default.border};
        padding: 16px;
        display: flex;
        justify-content: flex-end;
        height: 63px;
    }
}
`;

const PaymentSchema = Yup.object().shape({
    paymentMethod: Yup.string()
        // .oneOf(PAYMENT_OPTIONS, "only select from these options")
        .required("Select Payment Method")
        .label("Payment Method"),

    amount: Yup.number()
        .min("1", "Amount must be greater than 0")
        .typeError("Amount must be a number").required("Enter Amount").label("Amount"),


    cheque_number: Yup.number().when("paymentMethod", {
        is: "cheque",
        then: Yup.number().typeError("Amount must be a number").required("Cheque number is required.").label("Cheque Number"),
        otherwise: Yup.number().label("Cheque Number")
    }),
    cheque_image: Yup.mixed().when("paymentMethod", {
        is: "cheque",
        then: Yup.mixed().required("Cheque image is required.").label("Cheque Image"),
        otherwise: Yup.mixed().label("Cheque Image")
    }),


    draft_number: Yup.number().when("paymentMethod", {
        is: "draft",
        then: Yup.number().typeError("Amount must be a number").required("DD number is required.").label("Draft Number"),
        otherwise: Yup.number().label("Draft Number")
    }),
    draft_image: Yup.mixed().when("paymentMethod", {
        is: "draft",
        then: Yup.mixed().required("DD image is required.").label("Draft Image"),
        otherwise: Yup.mixed().label("Draft Image")
    })
})