import { Avatar, Badge, Checkbox, Tooltip } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React, { Fragment, memo } from "react";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import {
  allMessagesStore,
  setSessionId
} from "../../../../../Store/Slices/socket/AllMessagesSlice";
import { ChatNetworkService } from "../../../../../Store/Slices/socket/liveChat/ChatNetworkService";
import { LiveChatData } from "../../../../../Store/Slices/socket/liveChat/chat.selectors";
import {
  setActiveId,
  setBulkSelectedSessions,
  setCurrentSessionData,
  updateSessionData,
} from "../../../../../Store/Slices/socket/liveChat/chat.slice";
import {
  getMessageTimeAndDate,
  makeStringFirstLetterUppercase,
  renderTime,
} from "../../../../../Utils";
import { ReactComponent as Facebook } from "../../../../../assets/images/facebook.svg";
import { ReactComponent as Telegram } from "../../../../../assets/images/telegram.svg";
import { ReactComponent as Website } from "../../../../../assets/images/web-live.svg";
import { ReactComponent as Whatsapp } from "../../../../../assets/images/whatsapp.svg";
import { useSocket } from "../../../../hooks/socket-context";
import useMessagesData from "../../../../hooks/useMessagesData";
import {
  getColorFromChar,
  shortStringFn,
  sleep
} from "../../../../utils/utils";
import {
  ChatCardWrapper,
  CustomTag,
  StyledAvatar,
  StyledChannelBadgeWrapper,
} from "./ChatCardStyles";
import ChatCardMenu from "./ChatMenu/ChatCardMenu";

interface ChatCardProps {
  item: any;
  name?: string;
  avatar?: any;
  avatarName?: any;
  channel?: any;
  status?: string;
  description?: string;
  avatarColor?: any;
  date?: any;
  customTag?: any;
  session_id: string;
  channelName: string;
  unreadCount: number;
  botType?: "bot" | "liveagent";
  agentName: string;
  last_message: string;
  // onClick: (data: any, session_id: string) => void;
}

const ChatCard: React.FC<ChatCardProps> = ({
  session_id,
  // onClick,
  name = "Anonymous",
  avatar,
  avatarName = "A",
  unreadCount,
  channel,
  status,
  description,
  avatarColor,
  date,
  customTag,
  item,
  channelName,
  botType = "bot",
  agentName = "",
  last_message = "",
}) => {
  const { sessionId: activeSessionId, userInfoData } =
    useAppSelector(allMessagesStore);
  const dispatch = useAppDispatch();
  const { socket } = useSocket();
  const { bulkSelectedSessions } =
    useAppSelector(LiveChatData);

  const {sendBotHistoryRequestEvent} = useMessagesData();


  const handleChatCardClick = async(
    e: any,
    curItem: any,
    id: string
  ) => {
    e.stopPropagation();
    e.preventDefault();

    // Check if the click originated from the inner div
    const isInnerLabelDivClick = e.target.closest(".customTag");
    if (isInnerLabelDivClick) {
      // Handle click on the inner div (you can add specific logic if needed)
      return;
    }

    // Handle click on the whole card
    e.stopPropagation();
    e.preventDefault();

    if (id === activeSessionId) return;

    if (!socket?.connected) {
      console.warn("socket not connected");
      socket?.connect();
      await sleep(1000);
      return;
    }
    sendBotHistoryRequestEvent(id,curItem?.botid).then((res) => {
      dispatch(setSessionId(id));
      dispatch(setActiveId(id));
      dispatch(setCurrentSessionData({ ...curItem, count: 0 }));
     }).catch((err) => { }).finally(() => { });
  };

  const handleLabelClick = (curElem: any) => {
    let data = {
      id: curElem?.id,
      session_id: item?.session_id,
    };

    ChatNetworkService.deleteChatLabel(data)
      .then((res) => {
        let data = {
          ...userInfoData,
          ...item,
          label: Array.isArray(res?.data?.data)
            ? res?.data?.data
            : userInfoData?.label || [],
        };
        dispatch(updateSessionData(data));
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const themeTip = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            paddingTop: "1rem",
            paddingBottom: "1rem",
            backgroundColor: `#fff`,
            color: `#000`,
            borderRadius: "4px",
            boxShadow: `0px 0px 2px ${theme.palette.default.darkGrey}`,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            textAlign: "start",
          },
          arrow: {
            color: "white",
          },
        },
      },
    },
  });

  const handleCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    elem: any
  ) => {
    dispatch(
      setBulkSelectedSessions({
        id: elem?.session_id,
        checked: event?.target?.checked,
      })
    );
  };

  const { bgColor, textColor } = getColorFromChar(avatarName);

  return (
    <ChatCardWrapper active={activeSessionId === session_id}>
      <div className="main-left">
        <div
          className={`avatar ${
            bulkSelectedSessions?.length > 0 ? "showCheckbox" : ""
          }`}
        >
          <Checkbox
            checked={bulkSelectedSessions?.includes(item?.session_id)}
            onChange={(event: any) => handleCheckboxClick(event, item)}
            id={item?.session_id}
            style={{ zIndex: 1 }}
          />
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={getChannelBadgeIcon(channel)}
          >
            <StyledAvatar
              $bgColor={bgColor}
              $textColor={textColor}
              aria-label="avatar-user"
              src={avatar}
            >
              {avatarName}
            </StyledAvatar>
          </Badge>
        </div>
      </div>
      <div
        className="main-right"
        onClick={(e: any) =>
          handleChatCardClick(e, item, session_id)
        }
      >
        <div
          className="top"
        >
          <div className="bottom">
            <div className="channels">
              {channel && (
                <Fragment>
                  <p>{getChannelName(channel, channelName)}</p>
                </Fragment>
              )}
            </div>
            <div className="action-top">
              <Tooltip
                title={getMessageTimeAndDate(date)}
                placement="top"
                arrow
              >
                <p>{renderTime(date)}</p>
              </Tooltip>
              <ChatCardMenu currentItem={item} />
            </div>
          </div>

          <div
            className="title-content"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="content">
              <h5>{shortStringFn(name, 26)}</h5>
            </div>
          </div>
        </div>
        <div className="middle">
          <p
            className="description"
            dangerouslySetInnerHTML={{
              __html: last_message,
            }}
          />

          {customTag?.length > 0 && (
            <div className="tags">
              {customTag &&
                customTag?.length > 0 &&
                customTag?.slice(0, 2)?.map((tag: any, index: number) => {
                  return (
                    <CustomTag
                      color={tag?.color}
                      key={index}
                      className="customTag"
                    >
                      {shortStringFn(tag?.name, 12)}
                      <div
                        className="removeTag"
                        onClick={() => {
                          handleLabelClick(tag);
                        }}
                      >
                        <AppMaterialIcons iconName="Close" />
                      </div>
                    </CustomTag>
                  );
                })}
              {customTag?.length > 2 && (
                <ThemeProvider theme={themeTip}>
                  <Tooltip
                    title={
                      <>
                        {customTag?.map((tag: any, index: number) => (
                          <div key={index}>
                            <div
                              className="bullet"
                              style={{ backgroundColor: tag?.color }}
                            />
                            <CustomTag
                              style={{ color: tag?.color }}
                              key={index}
                            >
                              {tag?.name}
                              <div
                                className="removeTag"
                                onClick={() => {
                                  handleLabelClick(tag);
                                }}
                              >
                                <AppMaterialIcons iconName="Close" />
                              </div>
                            </CustomTag>
                          </div>
                        ))}
                      </>
                    }
                  >
                    <span className="remainingTags">
                      + {customTag.length - 2} more
                    </span>
                  </Tooltip>
                </ThemeProvider>
              )}
            </div>
          )}
        </div>

        <div className="action-bottom">
          <div className="action-bottom__left">
            {status && (
              <span className={`open ${statusClassgenerator(status)}`}>
                {status}
              </span>
            )}

            <Tooltip title="Live chat" enterDelay={500} placement="top">
              <span className="icon">{getbotTypeImage(botType)}</span>
            </Tooltip>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {unreadCount > 0 && (
              <span className="unreadCount">
                {unreadCount > 9 ? "9+" : unreadCount}
              </span>
            )}
            {agentName?.length > 0 && (
              <Avatar
                title={agentName}
                className="agentAvatar"
                style={{
                  backgroundColor: getColorFromChar(agentName).bgColor,
                  color: getColorFromChar(agentName)?.textColor,
                }}
              >
                {agentName[0]?.toUpperCase()}
              </Avatar>
            )}
          </div>
        </div>
      </div>
    </ChatCardWrapper>
  );
};

export default memo(ChatCard);

const statusClassgenerator = (status: any) => {
  if (status === "Pending") {
    return "pending-status";
  }
  if (status === "Open") {
    return "open-status";
  }
  if (status === "Closed") {
    return "close-status";
  }
};
const getbotTypeImage = (type: string) => {
  if (type === "livechat") {
    return (
      <AppMaterialIcons
        iconName="HeadsetMic"
        style={{ fontSize: "16px", color: theme.palette.primary.main }}
      />
    );
  }
  // if (type === "bot") {
  //   return <Website />;
  // }

  return null;
};

export const getChannelIcon = (type: string) => {
  if (type === "whatsapp") {
    return <Whatsapp />;
  }
  if (type === "website" || type === "Website") {
    return <Website />;
  }

  if (type === "messenger") {
    return <Facebook />;
  }

  if (type === "telegram") {
    return <Telegram />;
  }

  return <Website />;
};
export const getChannelName = (type: string, name: string = "") => {
  if (name?.length > 0) {
    return makeStringFirstLetterUppercase(name);
  }

  return makeStringFirstLetterUppercase(type);
};

export const getChannelBadgeIcon = (type: string) => {
  if (type === "whatsapp") {
    return (
      <StyledChannelBadgeWrapper>
        <Whatsapp />
      </StyledChannelBadgeWrapper>
    );
  }
  if (type === "website" || type === "Website") {
    return (
      <StyledChannelBadgeWrapper>
        <Website />
      </StyledChannelBadgeWrapper>
    );
  }

  if (type === "messenger") {
    return (
      <StyledChannelBadgeWrapper>
        <Facebook />
      </StyledChannelBadgeWrapper>
    );
  }

  if (type === "telegram") {
    return (
      <StyledChannelBadgeWrapper>
        <Telegram />
      </StyledChannelBadgeWrapper>
    );
  }

  return (
    <StyledChannelBadgeWrapper>
      <Website />
    </StyledChannelBadgeWrapper>
  );
};
