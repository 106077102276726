import { AxiosResponse } from 'axios';
import { FAQ } from '../../Models';
import { paraphraserAxios } from '../../Utils/Axios';
import { getHttpService } from "../HttpService";



import {
  PARAPHRASES_ENDPOINTS
} from "./Endpoints";


export class ParaphraserService {
  static httpService = getHttpService(paraphraserAxios);
  static allLanguageTranslate = (queryData = {}, config = {}) => {
    return ParaphraserService.httpService.post({url:PARAPHRASES_ENDPOINTS.ALL_LANGUAGE_TRANSLATE, data:queryData,  headers: config});
  };
  static trainBot = (queryData = {}, config = {}) => {
    return ParaphraserService.httpService.post({url:PARAPHRASES_ENDPOINTS.TRAIN_BOT, data:queryData,  headers: config});
  };
 
}

