// vendors
import { useContext, useRef, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import useOnClickOutside from "use-onclickoutside";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// contexts
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";

// hooks
import { useAction } from "./../../flow-builder-v2/hooks";

// components
import Card from "../card";
import { Editor, MessagePreview } from "./../../ui-components";
import Action from "../action";

// utils
import {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
} from "./../utils/editor";
import {
  getText,
  getResponse,
  cleanMediaDetails,
  isEmptyObj,
  getMediaDetails,
  getQuickAction,
} from "./../utils/helper";

const MessageNode = (props: any) => {
  const wrapperRef = useRef(null);
  const editorRef = useRef(null);
  const builderContext = useContext(BuilderContext);
  const nodeCntx = useContext(NodeContext);
  const { setActiveNode, activeNode, language } = builderContext;
  const { updateNode, removeNode } = useAction();
  const [message, setMessage] = useState("");
  const [showPreview, setPreview] = useState<boolean>(true);

  const [editorState, setEditorState] = useState(getInitialEditiorText(""));
  const [isOutsideClicked, setOutsideClicked] = useState<boolean>(false);
  const [userInputQuery, updateUserInputQuery] = useState<boolean>(
    getQuickAction(nodeCntx.quickActions, "user-query") || false
  );
  const response = getResponse(nodeCntx, language);
  const [mediaDetails, setMediaDetails] = useState<any>(
    getMediaDetails(response) || {}
  );
  const actions = nodeCntx?.actions || [];

  useOnClickOutside(wrapperRef, (event) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }
    setOutsideClicked(true);

    const previousPlainText = response
      ? getPlainTextData(getInitialEditiorText(getText(response)))
      : "";
    const plainText = getPlainTextData(editorState);
    const previousMediaDetails = response ? getMediaDetails(response) : {};
    const currentMediaDetails = mediaDetails;

    if (plainText === "") return;

    if ((nodeCntx.id && !showPreview) || !isEmptyObj(mediaDetails)) {
      if (
        plainText !== previousPlainText ||
        JSON.stringify(previousMediaDetails) !==
        JSON.stringify(currentMediaDetails) || getQuickAction(nodeCntx.quickActions, "user-query") !== userInputQuery
      ) {
        updateNode(nodeCntx.nodeId, message, mediaDetails, null, false, {
          type: "user-query",
          enabled: userInputQuery,
        });
      }
    }
    setPreview(true);
  });

  const handleBlur = () => {
    setMessage(getHTMLContent(editorState));
  };

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setMessage(getHTMLContent(editorState));
    setEditorState(editorState);
    setMediaDetails(cleanMediaDetails(mediaInfo));
  };

  const togglePreview = () => {
    setPreview(!showPreview);
    setActiveNode(nodeCntx.nodeId || nodeCntx.id);
  };

  const handleUserInputQueryChange = (checked: boolean) => {
    updateUserInputQuery(checked);
  };

  useEffect(() => {
    if (nodeCntx.id && activeNode !== null && activeNode === nodeCntx.id) {
      setPreview(false);
      return;
    }

    if (nodeCntx.nodeId === activeNode) {
      const text = getText(response);
      setEditorState(getInitialEditiorText(text));
      setMessage(text);
    }
  }, [activeNode]);

  const handleCancelEditing = () => {
    if (nodeCntx?.nodeId) {
      const plainText = getPlainTextData(editorState);
      setOutsideClicked(true);
      if (plainText !== '') {
        setActiveNode(null);
        togglePreview();
        return;
      }
    } else {
      togglePreview();
      removeNode();
    }
  };

  useEffect(() => {
    const value = response ? response[0]?.value : "";
    setMessage(value);
  }, [language]);

  useEffect(() => {
    setOutsideClicked(false);
    // setActiveNode(nodeCntx.nodeId || nodeCntx.id);
  }, []);

  return (
    <Box
      sx={{ position: "relative" }}
      id={props.id}
      {...(((activeNode !== null && activeNode === nodeCntx.id) ||
        activeNode === nodeCntx.nodeId) && { ref: wrapperRef })}
    >
      <Card
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
        onUserInputQueryChange={handleUserInputQueryChange}
        userInputQuery={userInputQuery}
      >
        <Box
          sx={{ p: "12px 16px" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {!showPreview ? (
            <Editor
              ref={editorRef}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onBlur={handleBlur}
              mediaInfo={mediaDetails}
              error={isOutsideClicked && getPlainTextData(editorState) === ""}
            />
          ) : (
            <Box
              onClick={(e) => {
                e.stopPropagation();
                togglePreview();
              }}
            >
              <MessagePreview mediaDetails={mediaDetails} text={message} />
            </Box>
          )}
        </Box>
        {userInputQuery && showPreview && (
          <>
            <Divider />
            <Box
              sx={{ p: "12px 16px" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Typography
                sx={{
                  display: 'inline-block',
                  p: '4px',
                  borderRadius: '4px',
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 400,
                  color: "#7E8392",
                  backgroundColor: "#F5F6F8"
                }}
              >
                User input query
              </Typography>
            </Box>
          </>
        )}
      </Card>
      <Action actions={actions} nodeContext={nodeCntx} />
    </Box>
  );
};
export default MessageNode;
