import { useEffect, useState } from "react";
import AppButton from "../../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormSwitchField,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../Store";
import { WorkspaceActions } from "../../../../Store/Slices/Settings/LiveChat/Workspace/workspace.actions";
import {
  TimeoutContainer,
  TimeoutHeader,
  TimeoutTable,
  TimeoutWrapper,
} from "./InactivityTimeoutStyles";
import { InactivityValidationSchema } from "./InactivityValidationSchema";

const InactivityTimeout = () => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  const [userInput, setUserInput] = useState<any>({
    unattended_chat_hours: "10",
    unattended_chat_minutes: "10",
    closed_chat_hours: "10",
    closed_chat_minutes: "10",
    closed_chat_enabled: false,
    unattended_chat_enabled: false,
  });

  const [localValues, setLocalValues] = useState<any>(null);

  // Getting Workspace Data
  const getWorkspaceData = () => {
    dispatch(WorkspaceActions.getWorkspaceConfigData("")).then((res: any) => {
      if (res?.payload?.status === 200) {
        const data = res?.payload?.data?.data;
        setUserInput({
          unattended_chat_hours:
            data?.inactivity_timeouts?.unttended_chat?.hours,
          unattended_chat_minutes:
            data?.inactivity_timeouts?.unttended_chat?.minutes,
          closed_chat_hours: data?.inactivity_timeouts?.closed_chat?.hours,
          closed_chat_minutes: data?.inactivity_timeouts?.closed_chat?.minutes,
          closed_chat_enabled: data?.inactivity_timeouts?.closed_chat?.enabled,
          unattended_chat_enabled:
            data?.inactivity_timeouts?.unttended_chat?.enabled,
        });
      } else {
        showAlert("Something went wrong", "error");
      }
    });
  };

  useEffect(() => {
    getWorkspaceData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values: any, SubmitProps: any) => {
    const payload = {
      inactivity_timeouts: {
        unttended_chat: {
          hours: Number(values?.unattended_chat_hours),
          minutes: Number(values?.unattended_chat_minutes),
          enabled: values?.unattended_chat_enabled,
        },
        closed_chat: {
          hours: Number(values?.closed_chat_hours),
          minutes: Number(values?.closed_chat_minutes),
          enabled: values?.closed_chat_enabled,
        },
      },
    };
    const data = {
      payload
    };
    const response: any = await dispatch(WorkspaceActions.updateWokspaceConfigData(data))
    if (response?.payload?.status === 200)
      showAlert("Inactivity Timeout Configs Updated", "success");
    else
      showAlert("Inactivity Timeout Configs Updation Failed", "error");
    SubmitProps?.setSubmitting(false);
  };

  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  return (
    <TimeoutWrapper>
      <TimeoutHeader>
        <div className="response-header__left">
          <h4>Inactivity Timeout</h4>
        </div>
        <div className="response-header__right">
          <div className="response-search"></div>
          <div className="response-button"></div>
        </div>
      </TimeoutHeader>

      <TimeoutContainer>
        <TimeoutTable>
          <div className="table-data">
            <AppForm
              initialValues={userInput}
              onSubmit={(values: any, SubmitProps: any) => {
                handleSubmit(values, SubmitProps);
              }}
              validationSchema={InactivityValidationSchema}
            >
              <div className="table-row__content">
                <div className="table-content ">
                  <div className="main-switch">
                    <div
                      className="table-content__text"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <h4>Do you want to enable inactivity timeouts?</h4>
                    </div>
                  </div>

                  <div style={{ position: "relative" }}>
                    <div className="table-content__text">
                      <h4>Unattended Chat</h4>
                      <AppFormSwitchField
                        name="unattended_chat_enabled"
                      />
                    </div>

                    <div className="table-content__text">
                      <p>
                        When the agent does not reply for a certain period of
                        timeframe then the chat is transferred to unattended.
                        Round-robin will not work as the chat is still open and
                        assigned to the agent. Good for tracking the agent's
                        productivity.
                      </p>
                    </div>
                    <div className="table-content__text b-bottom mb">
                      <div className="table-actions">
                        <AppFormField
                          sendValuesToParent={(values: any) =>
                            sendValuesToParent(values)
                          }
                          divStyle={{
                            minWidth: "180px",
                            maxWidth: "180px",
                          }}
                          name="unattended_chat_hours"
                          label="Hours"
                          placeholder="00"
                          disabled={!localValues?.unattended_chat_enabled}
                        />
                        <AppFormField
                          divStyle={{
                            minWidth: "180px",
                            maxWidth: "180px",
                          }}
                          name="unattended_chat_minutes"
                          label="Minutes"
                          placeholder="15"
                          disabled={!localValues?.unattended_chat_enabled}
                        />
                      </div>
                    </div>

                    <div className="table-content__text">
                      <h4>Closed Chat</h4>
                      <AppFormSwitchField name="closed_chat_enabled" />
                    </div>
                    <div className="table-content__text">
                      <p>
                        When the customer does not reply for a certain timeframe
                        then the chat will be marked as closed. The status of
                        the chat will be marked as closed. For more details
                        please visit our documentation.
                      </p>
                    </div>
                    <div className="table-content__text">
                      <div className="table-actions">
                        <AppFormField
                          divStyle={{
                            minWidth: "180px",
                            maxWidth: "180px",
                          }}
                          name="closed_chat_hours"
                          label="Hours"
                          placeholder="00"
                          disabled={!localValues?.closed_chat_enabled}
                        />
                        <AppFormField
                          divStyle={{
                            minWidth: "180px",
                            maxWidth: "180px",
                          }}
                          name="closed_chat_minutes"
                          label="Minutes"
                          placeholder="15"
                          disabled={!localValues?.closed_chat_enabled}
                        />
                      </div>
                    </div>

                    <div className="footer">
                      <AppButton variant="grey">Cancel</AppButton>
                      <div>
                        <AppSubmitButton
                          variant="cta"
                          style={{ width: "180px" }}
                          title={"Save"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </AppForm>
          </div>
        </TimeoutTable>
      </TimeoutContainer>
    </TimeoutWrapper>
  );
};

export default InactivityTimeout;
