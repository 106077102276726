import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as WelcomeToBotBuilderImage } from "../../../src/assets/images/welcometobotbuilder.svg";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import { theme } from "../../Customization/Theme";
import { useMyBotsStore } from "../../Services/Apis";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import ArrowImage from "../../assets/images/arrowBot.svg";

const WelcomeBotScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const { data: authData } = useSelector(useAuthStore);
  const { workspace_id } = useParams();
  const { data: paginatedMyBots } = useSelector(useMyBotsStore);
  const handleRedirectToCreateForm = () => {
    let ws_id = window.localStorage.getItem("ws_bot_id");
    if (ws_id) {
      navigate(`/workspace/${ws_id}/setup/create-bot`);
    } else if (workspace_id) {
      navigate(`/workspace/${workspace_id}/setup/create-bot`);
    }
  };

  return (
    <WelcomeWrapper>
      <Box className="welcome-left">
        <WelcomeToBotBuilderImage />
      </Box>
      <Box className="welcome-right">
        <Box className="title">
          <Typography
            color="#5B73E8"
            fontSize="24px"
            fontWeight="400"
            component="h6"
          >
            Hi &nbsp;
            {authData &&
              authData.data &&
              authData.data.user &&
              authData.data.user.first_name}
            ,
          </Typography>
          <Typography
            color="#5B73E8"
            fontSize="24px"
            fontWeight="600"
            component="h6"
          >
            Welcome To Bot builder
          </Typography>
        </Box>
        <Box className="sub-title">
          <Typography fontSize="16px" fontWeight="600" component="h6">
            Maximize Your Experience with the No-Code Chatbot Builder:
          </Typography>
        </Box>
        <Box className="list">
          <ol>
            <li>
              <Typography fontSize="16px" fontWeight="500" component="h6">
                Effortless Creation:
              </Typography>
              <Typography fontSize="16px" fontWeight="400" component="p">
                Build your chatbot hassle-free with our intuitive tools—no
                coding expertise needed!
              </Typography>
            </li>
            <li>
              <Typography fontSize="16px" fontWeight="500" component="h6">
                Personalize Your Bot:
              </Typography>
              <Typography fontSize="16px" fontWeight="400" component="p">
                Tailor your bot's responses and personality to represent your
                brand or service accurately.
              </Typography>
            </li>
            <li>
              <Typography fontSize="16px" fontWeight="500" component="h6">
                Seamless Deployment:
              </Typography>
              <Typography fontSize="16px" fontWeight="400" component="p">
                Easily deploy your chatbot across various platforms like
                websites or messaging apps.
              </Typography>
            </li>
            <li>
              <Typography fontSize="16px" fontWeight="500" component="h6">
                Optimization Made Easy:
              </Typography>
              <Typography fontSize="16px" fontWeight="400" component="p">
                Analyze performance, tweak, and enhance your bot's capabilities
                for better user interaction.
              </Typography>
            </li>
          </ol>
        </Box>
        <Box className="button">
          <ProtectedAppButton
            moduleId="create_bot"
            variant="primarydark"
            onClick={() => handleRedirectToCreateForm()}
            disabled={paginatedMyBots && paginatedMyBots?.count >= paginatedMyBots?.chatbot_limit ? true : false}
            title={paginatedMyBots && paginatedMyBots?.count >= paginatedMyBots?.chatbot_limit ? "You have reached the maximum number of chatbots on your current plan. If you would like to create more chatbots, please upgrade your subscription plan." : ""}
          >
            Create New Bot
            <AppMaterialIcons iconName="Add" />
          </ProtectedAppButton>
        </Box>
      </Box>
    </WelcomeWrapper>
  );
};

export default WelcomeBotScreen;

const WelcomeWrapper = styled.div`
  && {
    background-color: ${theme.palette.default.lightGrey};
    width: 100%;
    height: inherit;
    overflow: auto;
    display: flex;
    align-items: center;
    gap: 2.4rem;
    padding: 0 1rem;
    flex-wrap: wrap;
    justify-content: center;
    .welcome-left {
      background-color: inherit;
    }
    @media (max-width: 1399.98px) {
      gap: 0;
    }
    .welcome-right {
      background-color: inherit;
      .title {
        margin-bottom: 24px;
      }
      .sub-title {
        margin-bottom: 16px;
      }
      .list {
        padding-left: 1rem;
        position: relative;
        ol li {
          margin-bottom: 1rem;
        }
        ol li p {
          color: ${theme.palette.default.darkGrey};
        }
      }
      .list:after {
        content: "";
        background-image: url(${ArrowImage});
        background-size: cover;
        display: inline-block;
        width: 120px;
        height: 113px;
        position: absolute;
        left: 154px;
      }
      .button {
        margin-top: 113px;
      }
    }
  }
`;
