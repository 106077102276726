import { useState } from "react";

interface AppIconProps {
  size?: string;
  color?: string;
  title: string;
  cursor?: string;
  children?: React.ReactNode;
  onClick?: any;
  hoverColor?: any;
  [otherProps: string]: any;
}
const AppIcon: React.FC<AppIconProps> = ({
  title,
  size = "32px",
  color = "grey",
  onClick,
  hoverColor,
  cursor='pointer',
  ...otherProps
}) => {

  const [isHovering, setIsHovering] = useState<any>(false)

  return (
    <i
      onClick={onClick}
      {...otherProps}
      style={{ fontSize: `${size}`, color: isHovering ? hoverColor : `${color}`, cursor: cursor }}
      className={`${title} iconHover`}
      onMouseEnter={()=>setIsHovering(true)}
          onMouseLeave={()=>setIsHovering(false)}

    />
  );
};

export default AppIcon;


