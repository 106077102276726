import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import AppModel from "../../Hooks/useModel/AppModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { AutomationBuilderActions } from "../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import {
  automationBuilderPayload,
  automationUpdateError,
  getAutomationError,
  isAutomationPublished,
  isAutomationUpdated,
  singleAutomationData,
} from "../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  removeAutomationData,
  resetDelete,
} from "../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { findFormSpecificPermissionKeyUsingLocation } from "../../Utils/rolesUtils";
import Accordions from "./accordions";
import { clearGraph, createGraph } from "./config";
import ModelForms from "./modelForms";
import { AutomationSubWrap } from "./styles/AutomationCanvasStyle";
import { IconContainer, WaningContainer, Wrapper } from "./styles/style";

const AutomationBuilder: React.FC<LoaderComponentProps> = ({ setLoading }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const locationParams: any = useParams();
  const automationData = useAppSelector(singleAutomationData);
  const automationError = useAppSelector(getAutomationError);
  const isUpdated = useAppSelector(isAutomationUpdated);
  const isPublished = useAppSelector(isAutomationPublished);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const automationErrorOnUpdate: any = useAppSelector(automationUpdateError);
  const [isPublishedState, setIsPublishedState] = useState<boolean>(
    automationData?.status === "published" ? true : false || false
  );
  //*WILL BE USED TO SET THE FORM ACCESS TYPE
  const [formAccessType, setFormAccessType] = useState<
    "create" | "read" | "update" | "delete"
  >("create");
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findFormSpecificPermissionKeyUsingLocation();
    setFormAccessType(result);
  }, []);

  // Make API call on
  useEffect(() => {
    if (locationParams?.id) {
      setLoading(true);
      dispatch(
        AutomationBuilderActions.getAutomationDataById(locationParams?.id)
      ).finally(() => {
        setLoading(false);
      });
    } else {
      showAlert("Invalid Automation ID", "error");
    }
    return () => {
      clearGraph();
      dispatch(removeAutomationData({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Create Graph on API Data
  useEffect(() => {
    if (automationData) {
      createGraph(automationData?.draftedUIObject?.uiObject);
    }
  }, [automationData]);
  // Create Graph on API Data
  useEffect(() => {
    if (isUpdated) {
      showAlert("Automation Saved Successfully", "success");
      dispatch(resetDelete({}));
    } else if (isPublished) {
      showAlert("Automation Published Successfully", "success");
      dispatch(resetDelete({}));
    }
    if (automationErrorOnUpdate) {
      showAlert(automationErrorOnUpdate, "error");
      dispatch(resetDelete({}));
    }
  }, [isUpdated, isPublished, automationErrorOnUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Show Alert on API Failure
  useEffect(() => {
    if (automationError) {
      showAlert(automationError, "error");
      setLoading(true);
    }
  }, [automationError]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePublish = () => {
    setLoading(true);
    dispatch(
      AutomationBuilderActions.publishAutomationById(locationParams?.id)
    ).finally(() => {
      setLoading(false);
    });
  };
  const handleUpdateAutomation = async () => {
    setLoading(true);
    let payload = {
      ...automationPayload,
    };
    dispatch(
      AutomationBuilderActions.updateAutomationById({
        id: locationParams?.id,
        data: payload,
      })
    ).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (automationData?.name) {
      document.title = `Automation - ${automationData?.name}`;
    }
    setIsPublishedState(
      automationData?.status === "published" ? true : false || false
    );
  }, [automationData]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <React.Fragment>
      <AutomationSubWrap>
        <div className="topmenu">
          <div
            className="menu-left"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <IconButton onClick={() => navigate("/automation/lists")}>
              <AppMaterialIcons iconName="ArrowBackIosOutlined" />
            </IconButton>
            {automationData?.name || "Untitled"}
          </div>
          {automationData?.name && (
            <div className="menu-right">
              <div className="button">
                <ProtectedAppButton
                  moduleId="automation_crud"
                  specificPermissionKey="update"
                  onClick={handleUpdateAutomation}
                  style={{ width: "80px" }}
                  variant="outline"
                >
                  Save
                </ProtectedAppButton>
                <ProtectedAppButton
                  moduleId="automation_crud"
                  specificPermissionKey="update"
                  style={{ width: "100px" }}
                  onClick={handlePublish}
                  variant="contained"
                >
                  Publish
                </ProtectedAppButton>
              </div>
            </div>
          )}
        </div>
      </AutomationSubWrap>

      <Wrapper className={formAccessType === "read" ? "disableUIBlock" : ""}>
        <div className="sideBar">
          <div className="header">
            <h3>
              Automation Journey <br />
              Drag & Drop
            </h3>
            <p>to create an automation flow</p>
          </div>
          <div id="stencil-container">
            <Accordions />
          </div>
        </div>
        <div id="paper-container"></div>
      </Wrapper>
      <ModelForms />
      <AppModel isShowing={isPublishedState} divStyle={{ width: "500px" }}>
        <WaningContainer>
          <IconContainer>
            <NotificationsNoneIcon fontSize="large" style={{ fill: "#FFF" }} />
          </IconContainer>
          <p className="modal_container_header"> Important Notice:</p>
          <p className="text">
            Any changes made in the automation flow will only apply to future
            events/users and will not affect past events/users already in the
            system. Please proceed with caution and ensure that you are
            modifying settings for upcoming events only.
          </p>
          <AppButton
            variant="primary"
            onClick={() => setIsPublishedState(false)}
            style={{ width: "150px", margin: "15px auto 0" }}
          >
            Continue
          </AppButton>
        </WaningContainer>
      </AppModel>
    </React.Fragment>
  );
};
export default withLoader(AutomationBuilder);
