import styled from "styled-components";
import FacebookLogin from "react-facebook-login";
import { useSelector } from "react-redux";
import { StyleFacebookButton } from "../../../../Customization/CommonElements";
import facebook from "../../../../assets/images/facebook-icon.svg";
interface Props {
  componentClicked: any;
  hideLogo?: boolean;
}

const FacebookIntegration: React.FC<Props> = ({
  componentClicked,
  hideLogo,
}) => {
  // const currentBot = useSelector(useCurrentBot);
  var exchange_token = "";
  var facebook_response = "";
  var facebook_exchange_token = "";

  const responseFacebook = (response: any) => {
    facebook_response = response;
    facebook_exchange_token = response.accessToken;
  };

  return (
    <FacebookPageWrap>
      <div className="pageTopbar">
        {/* <AppIcon title={'ri-arrow-left-line'} size="26px" color="#282828" /> */}
      </div>
      <div className="ctaBox">
        {/* {!hideLogo ? ( */}
        <>
          <img className="companyLogo" src={facebook} alt="" />
          <p className="ctaTitle">Connect with Facebook Account</p>
        </>
        {/* ) : (
          <></>
        )} */}

        <FacebookLogin
          class="ctaTitle"
          appId="544372894068159"
          autoLoad={false}
          fields="name,email,picture"
          xfbml={true}
          scope="manage_pages,pages_messaging,pages_messaging_subscriptions,read_page_mailboxes,ads_management,leads_retrieval,pages_manage_ads,pages_read_engagement,pages_show_list,pages_manage_metadata,business_management,pages_manage_engagement"
          // onClick={componentClicked}
          callback={componentClicked}
          textButton={
            <StyleFacebookButton>
              <i className="ri-links-line"></i>{" "}
              {!hideLogo ? "Connect With Facebook" : "Connect a page"}
            </StyleFacebookButton>
          }
        />
      </div>
    </FacebookPageWrap>
  );
};

export default FacebookIntegration;

const FacebookPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .pageTopbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      width: 100%;
    }

    .ctaBox {
      display: flex;
      height: 40%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
      /* background-color: red; */
      display: flex;
      justify-content: center;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }
    .kep-login-facebook {
      font-size: 14px;
      border: none;
      border-radius: 6px !important;
      background-color: #3654e3 !important;
      height: 40px;
      padding: 6px 12px !important;
      font-weight: 400;
      text-transform: inherit;
    }
  }
`;
