import { createSlice } from "@reduxjs/toolkit";
import { WorkspaceActions } from "./workspace.actions";

interface initialStateTypes {
    data: {
        workspaceData: any;
    };
    error: string;
    loading: boolean;
}

const initialState: initialStateTypes = {
    data: {
        workspaceData: null,
    },
    error: "",
    loading: false,
};


const WorkspaceSlice = createSlice({
    name: "LiveChatSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

        //* Get MEssage
        .addCase(WorkspaceActions.getWorkspaceConfigData.pending, (state, action) => {
            state.error = "";
            state.loading = true;
        })
        .addCase(WorkspaceActions.getWorkspaceConfigData.fulfilled, (state, action) => {
            state.data.workspaceData = action.payload?.data?.data || {};
            state.error = "";
            state.loading = false;
        })
        .addCase(WorkspaceActions.getWorkspaceConfigData.rejected, (state, action) => {
            state.loading = false;
            state.data.workspaceData = null;
            state.error = action?.error?.message || "";
        })
    }
})

export default WorkspaceSlice.reducer;