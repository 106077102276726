import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RoleActions } from "./role.action";

interface initialStateTypes {
  data: {
    allRolesListing: any;
    allRolesListingCount: any;
    allAdminRolesListing: any;
    allPermissionListing: any;
    allPermissionOptions: any;
    permissionID: any;
    permissionData: any;
    hide: any;
    hideType: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    allRolesListing: "",
    allRolesListingCount: "",
    allAdminRolesListing: "",
    allPermissionListing: "",
    allPermissionOptions: "",
    permissionID: "",
    permissionData: [],
    hide: [],
    hideType: [],
  },
  error: "",
  loading: false,
};

const RoleSlice = createSlice({
  name: "UserSlice",
  initialState,
  reducers: {
    permissionIDs: (state: any, action: PayloadAction<any>) => {
      state.data.permissionID = action.payload;
      return state;
    },
    updatePermissionData: (state: any, action: PayloadAction<any>) => {
      state.data.permissionData = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      // Listing Of Users
      .addCase(RoleActions.getUserListing.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(RoleActions.getUserListing.fulfilled, (state, action) => {
        state.data.allRolesListing = action?.payload?.data?.data || [];
        state.data.allRolesListingCount =
          action?.payload?.data?.pagination?.totalRecords;
        state.loading = false;
        // state.data.allRolesListingCount = action?.payload?.data?.count || 0;
      })
      .addCase(RoleActions.getUserListing.rejected, (state, action) => {
        state.loading = false;
        state.data.allRolesListing = null;
        state.error = action?.error?.message || "";
      })

      // Listing Of permission options
      .addCase(RoleActions.getPermissionOptions.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(RoleActions.getPermissionOptions.fulfilled, (state, action) => {
        state.data.allPermissionOptions = action?.payload?.data?.data || [];
        state.loading = false;
        // state.data.allRolesListingCount = action?.payload?.data?.count || 0;
      })
      .addCase(RoleActions.getPermissionOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "";
      })

      // Add Roles
      .addCase(RoleActions.AddRoles.pending, (state, action) => {
        state.error = "";
      })
      .addCase(RoleActions.AddRoles.fulfilled, (state, action) => {
        state.data.allAdminRolesListing = action?.payload?.data || [];
      })
      .addCase(RoleActions.AddRoles.rejected, (state, action) => {
        state.data.allAdminRolesListing = null;
        state.error = action?.error?.message || "";
      })

      // listing of Permission
      .addCase(RoleActions.getPermissionListing.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(RoleActions.getPermissionListing.fulfilled, (state, action) => {
        state.data.allPermissionListing = action?.payload?.data || [];
        state.data.hide = action?.payload?.data?.data?.permission_config.filter(
          (item: any) =>
            item.childs?.some(
              (subitem: any) => subitem?.permission_config?.has_crud === true
            )
        );
        state.data.hideType = action?.payload?.data?.data?.permission_config?.filter((item: any) =>
          item.childs?.some((subitem: any) =>
            subitem?.type
          )
        );
        state.loading = false;
      })
      .addCase(RoleActions.getPermissionListing.rejected, (state, action) => {
        state.data.allPermissionListing = null;
        state.error = action?.error?.message || "";
        state.loading = false;
      })

      //Initial Listing Create Flow
      .addCase(RoleActions.getInitialPermissions.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(RoleActions.getInitialPermissions.fulfilled, (state, action) => {
        state.data.allPermissionListing = action?.payload?.data || [];
        state.data.hide = action?.payload?.data?.permission_config.filter((item: any) =>
          item.childs?.some((subitem: any) =>
            subitem?.permission_config?.has_crud === true
          )
        );
        state.data.hideType = action?.payload?.data?.permission_config?.filter((item: any) =>
          item.childs?.some((subitem: any) =>
            subitem?.type
          )
        );
        state.loading = false;
      })
      .addCase(RoleActions.getInitialPermissions.rejected, (state, action) => {
        state.data.allPermissionListing = null;
        state.error = action?.error?.message || "";
        state.loading = false;
      })
  }
});
export const { permissionIDs, updatePermissionData } = RoleSlice.actions;

export default RoleSlice.reducer;
