import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const AgentWrapper = styled.div`
 & {
    display: flex;
    flex-direction: column;
    width: 100%;
     background-color:#F5F6F8;
   
    .react-loading-skeleton {
      z-index: 0;
    }
     .parent_agent{
        width: 100%;
        padding: 10px;
        background-color: white;
        height: auto;
         background-color:#F5F6F8;
    }
    .ConversationStatus{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction:column;
        justify-content: flex-start;
        background-color: white;
        border-radius:10px;
        .status_box {
          width: 22%;
        }
        .conversation_status_count {
          font-size: 48px;
    text-align: center;
    font-family: Inter;
    font-weight: 500;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        }
        .conversation_header {
          text-align: center;
          display: flex;
          align-items:center;
          font-size:20px;
          padding:10px 24px 10px 10px;
          border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
          .g_div_tooltip{
            font-size: 15px;
          }
          .conversation_header_text{
            font-size:16px;
            font-family: Inter;
            font-weight:500;
          }
        }
        .status_container_parent{
          width: 100%;
          display: flex;
          height: 100%;
        }
        
        .status_container{
          height: auto;
          width:50%;
          display: flex;
          align-items:center;
          justify-content: space-around;
        }
        .status{
          display:flex;
          flex-direction:column;
          align-items:center;
        }
        .status_text{
          font-size:14px;
          font-family: Inter;
          font-weight:300;
        }
        .status_count{
          font-size: 26px;
          font-family: Inter;
          font-weight:500;
        }
        
        .g_div2_open {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          font-family: Inter;
        }
        .g_div2_icon {
          align-items: center;
          height:38px;
          width:38px;
        }
        .g_div2_text {
          text-align: center;
          font-size:14px;
          font-family: Inter;
          font-weight:300;
        }
        .g_div2_count {
          font-size: 32px;
          text-align: center;
          font-family: Inter;
          font-weight:500;
        }
    
        
    }
    .conversation_status{
        text-align: center;
        font-family: Inter;
    }
    .conversation_count{
        font-size: 72px;
        text-align: center; 
        font-family: Inter;
        font-weight:500;
    }
    
    .allAgent{
        width: 100%;
        height: 100%;
        background-color: ${theme.palette.default.white};
        border-radius: 10px;
        font-family: Inter;
    }
    .agent_csat_p{
       display:flex;
        font-family: Inter;
        font-size:18px;
        font-weight:500;
        align-items:center;
        padding:10px 24px;
        border-bottom: 1px solid #f3f3f3;
        padding: 10px 24px 10px 10px;
        .div_tooltip{
            font-size: 16px;
        }
    }
    .agent_graph_div{
        width:100%;
        height:calc(100% - 53px);
        display:flex;
        flex-wrap:row;
        justify-content:space-between;
        padding:24px;
        gap:10px;
        font-family: Inter;
    }
    .agent_graph_div_text{
        width:25%;
        height:auto;
        display:flex;
        flex-direction:column;
        padding:10px;
        font-family: Inter;
        justify-content: center;
    }
    .agent_graph_div_count{
        font-size:32px;
        font-family: Inter;
        font-weight:500;
        text-align:center;
    }
    .agent_graph_div_f{
        font-size:14px;
       text-align:center;
        font-family: Inter;
        font-weight:400;
    }
    .csat_graph{
        width:70%;
    }
    
    .ConversationAgent{
      border: 1px solid;
        width: 100%;
        min-width:97.6%;
        height: 100%;
        // min-height:415px;
        padding: 10px 30px 10px 30px;
        background-color: white;
        display:flex;
         flex-direction:column;
         border-radius:4px;
         font-family: Inter;
    }
    
    .export_btn{
        display:flex;
        gap:15px;
        font-family: Inter;
    }


    .g_div1 {
        width: 22%;
        border: 0px solid red;
      }
      .conversationHeader {
        text-align: center;
        display: flex;
        font-size:20px;
        font-family: Inter;
        width: 200px;
    align-items: center;
        .g_div_tooltip{
          font-size: 15px;
        }
      }
      .conversationHeaderText{
        font-size:18px;
        font-family: Inter;
        font-weight:500;
      }
      .conversationHeaderCount {
        font-size: 72px;
        margin-right:65px;
        text-align: center;
        font-family: Inter;
        font-weight:500;
        padding-top: 12px;
      }
      .conversationSubHeader {
        font-size: 24px;
        font-family: Inter;
        font-weight:300;
      }
      .TotalConversation {
        width: 40%;
        height: auto;
        border: 0px solid red;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 60px;
      }
      .ConversationInfo {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        font-family: Inter;
      }
      .g_div2_icon {
        width: 48px;
        height: 48px;
        align-items: center;
      }
      .conversationText {
        text-align: center;
        font-size:14px;
        font-family: Inter;
        font-weight:300;
      }
      .conversationCount {
        font-size: 26px;
        text-align: center;
        font-family: Inter;
        font-weight:500;
      }
      .dotted_hr{
        height: auto;
      border: 0.2px dashed gray; 
      opacity:50%; 
      height: 60%;
    margin: auto;
      }
 }
 @media (max-width: 1660px) {

  
  .g_div1_header_p{
    font-size:16px;
    font-family: Inter;
    font-weight:500;
  }
  .ConversationStatus .conversation_status_count {
    font-size: 40px;
  }
  .g_div1_i {
    font-size: 20px;
    margin-top:18px;
    font-family: Inter;
    font-weight:300;
  }
  .total_msg_count {
    font-size: 65px;
    font-family: Inter;
    font-weight:500;
  }

    .conversationHeaderText{
      font-size:18px;
      font-family: Inter;
      font-weight:500;
      border: 1px solid;
    }
    .conversationHeaderCount {
      font-size: 65px;
      text-align: center;
      padding-left: 30px;
    }
    .conversationSubHeader {
      font-size: 18px;
      font-family: Inter;
      font-weight:300;
    }
    .i_div_total_count {
      font-size: 65px;
      font-family: Inter;
      font-weight:500;
    }
`;
