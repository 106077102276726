import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FieldArray, getIn, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import {
  AppFormFastField,
  AppFormField,
  AppFormSelectFastField,
  AppFormTextArea,
} from "../../../../../AppComponents/Forms";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { MarketingListNetworkService } from "../../../../../Store/Slices/MarketingList/MarketingListNetworkService";
import { SMSDataStore } from "../../../../../Store/Slices/SMS/sms.selectors";
import { makeStringFirstLetterUppercase } from "../../../../../Utils";
import { AppToolTip } from "../../../../UiComponents/AppToolTip/AppToolTip";
import { CreditDetailsActions } from "../../../../../Store/Slices/CreditDetails/CreditDetails.actions";

const tempOption: any = {
  label: "Value + text",
  name: "value_text",
  placeholder: "Enter Your value",
  type: "text",
  valueEditorType: "input",
};

interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
}

const RenderFormStep3: React.FC<Props> = ({ formStep, setFormStep }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { templateByIdData }: any = useAppSelector(SMSDataStore);
  let [filterFieldData, setFilterFieldData] = useState<any>([]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      templateByIdData?.body_text?.length &&
      !getIn(values, "resolved_body_variables")?.length
    ) {
      setFieldValue("resolved_body_variables", templateByIdData?.body_text);
    }
    if (
      getIn(values, "resolved_body_variables")?.length !==
      templateByIdData?.body_text?.length
    ) {
      setFieldValue("resolved_body_variables", templateByIdData?.body_text);
    }
  }, [templateByIdData]);

  useEffect(() => {
    if (templateByIdData) {
      setFieldValue("body_preview", templateByIdData?.body_value);
    }
  }, [templateByIdData]);

  useEffect(() => {
    if (getIn(values, "marketing_list")) {
      MarketingListNetworkService.getFieldsTypeMappingList(
        getIn(values, "marketing_list"),
        getIn(values, "id")
      ).then((res: any) => {
        let abc: any = res?.data?.response?.fields || [];
        abc = [...abc, tempOption];
        setFilterFieldData(abc);
      });
    }
  }, [getIn(values, "marketing_list")]); //eslint-disable-line

  useEffect(() => {
    dispatch(
      CreditDetailsActions.getBroadcastCreditDetails(getIn(values, "id"))
    );
  }, [formStep]); // eslint-disable-line react-hooks/exhaustive-deps

  if (formStep !== 3) {
    return null;
  }

  if (
    // getIn(values, "resolved_header_variables")?.length <= 0 &&
    getIn(values, "resolved_body_variables")?.length <= 0
    // getIn(values, "resolved_buttons_variables")?.length <= 0
  ) {
    return <p>No dynamic varibles found. kindly proceed further</p>;
  }
  return (
    <div className="styledVariblesBox">
      <AppFormTextArea
        name={"body_preview"}
        label="Message Template Preview"
        placeholder="Template body"
        rows={5}
        disabled={true}
      />

      {/* RENDER DYANMIC variables BASES ON TYPE */}
      <p className="variablesTitle">{"Resolve custom variables"}</p>

      {getIn(values, "resolved_body_variables")?.length > 0 && (
        <RenderSingleTypeVariables
          name={"resolved_body_variables"}
          variableType="Body"
          fieldsData={filterFieldData}
        />
      )}
    </div>
  );
};

export default RenderFormStep3;
interface RenderSingalProps {
  variableType: string;
  name: string;
  isMedia?: boolean;
  fieldsData: any;
}
const RenderSingleTypeVariables: React.FC<RenderSingalProps> = ({
  variableType,
  name,
  isMedia = false,
  fieldsData = [],
}) => {
  const { values } = useFormikContext<any>();

  // let OPTIONS=  fieldsData?.push(tempOption) ||fieldsData ||[];
  if (getIn(values, name)?.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      <p className="variablesType">
        {makeStringFirstLetterUppercase(variableType)}
      </p>

      <FieldArray name={name}>
        {(arrayHelpers) => (
          <React.Fragment>
            {getIn(values, name)?.length > 0 &&
              getIn(values, name)?.map((item: any, index: number) => {
                const textfieldName: string = `${name}.${index}.value`;
                const selectfieldName: string = `${name}.${index}.index`;
                return (
                  <div className="variablesContent" key={index.toString()}>
                    <p className="variableIndex">{item?.key as string}</p>
                    {isMedia ? (
                      <AppFormFastField
                        name={textfieldName}
                        placeholder={"url"}
                      />
                    ) : (
                      <React.Fragment>
                        {fieldsData?.length > 0 && (
                          <AppFormSelectFastField
                            Options={fieldsData}
                            name={selectfieldName}
                            divStyle={{ maxWidth: "1000px" }}
                            valueKey="name"
                          />
                        )}

                        <CustomVariableWrapper>
                          <AppFormField
                            name={textfieldName}
                            placeholder={
                              getIn(values, selectfieldName) === "value_text"
                                ? "Static value"
                                : "Fallback Value"
                            }
                          />
                          <AppToolTip
                            tooltipProps={{
                              title:
                                getIn(values, selectfieldName) === "value_text"
                                  ? "Static Value - A fixed and unchanging piece of data or text that remains consistent for all users. It is not personalized and remains unchanged throughout interactions. Examples : URLs (e.g., www.example.com), coupon codes (e.g., SAVE10), default settings (e.g., language: English), company names (e.g., XYZ Corporation), etc."
                                  : "Fallback values are crucial. When a property is unavailable for a user, we automatically replace it with the specified fallback value. These alternatives ensure a seamless user experience and prevent errors. For example, if a user's First Name is not found, the fallback value should be defined as 'User'.",
                            }}
                            iconButtonProps={{ disableRipple: true }}
                            IconComponent={
                              <InfoOutlinedIcon fontSize="small" />
                            }
                          />
                        </CustomVariableWrapper>
                      </React.Fragment>
                    )}
                  </div>
                );
              })}
          </React.Fragment>
        )}
      </FieldArray>
    </React.Fragment>
  );
};

export const CustomVariableWrapper = styled.div`
  & {
    width: 100%;
    display: flex;
    align-items: top;
    input {
      width: calc(100% - 40px);
    }
    .MuiBox-root {
      margin-top: 12px;
    }
  }
`;
