import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormObserver,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import {
  automationBuilderPayload,
  automationBuilderState,
  modelConfigData,
  modelConfigError,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { UserActions } from "../../../Store/Slices/Settings/LiveChat/User/user.action";
import { userListigData } from "../../../Store/Slices/Settings/LiveChat/User/user.selectors";
import { EmailActions } from "../../../Store/Slices/email/email.actions";
import { templateDetailsByID } from "../../../Store/Slices/email/email.selectors";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { EmailConfigSchema } from "../validationSchema";
import { getDefaultValue } from "../utils";
import { saveGraph } from "../config";
import { withLoader } from "../../../AppComponents/Loader";

const EmailSend = ({ setLoading }: any): JSX.Element => {
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const templateDetails: any = useAppSelector(templateDetailsByID);
  const emailModelConfig: any = useAppSelector(modelConfigData);
  const emailModelError: any = useAppSelector(modelConfigError);
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [templateBody, setTemplateBody] = useState("");
  const currentBot = useAppSelector(useCurrentBot);
  const userlListing = useAppSelector(userListigData);
  const [initialValues, setInitialValues]: any = useState({
    subject: "",
    emailTemplateId: null,
    from: null,
    replyTo: "",
    emailAddress: null,
  });

  const [formikProps, setFormikProps] = useState<any>(null);
  const [limit] = useState(999);
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.emailTemplateId
    ) {
      dispatch(
        EmailActions.getEmailTempListById(
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.emailTemplateId,
        ),
      );
    }
  }, [automationPayload]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      templateDetails?.template_bodyautomationPayload?.draftedUIObject
        ?.graphData?.nodes?.[automationReduxData?.selectedNodeId]
        ?.nodeObjectData?.emailTemplateId
    ) {
      if (formRef?.current?.values?.emailAddress?.address) {
        let tempBody = JSON.parse(templateBody);
        setTemplateBody(
          JSON.stringify(
            tempBody.split("</body>")[0] +
              '<footer disabled="disabled" size="1" id="footer" style="text-align: center;">' +
              formRef?.current.values?.emailAddress?.address +
              "</footer></body>" +
              tempBody.split("</body>")[1],
          ),
        );
      } else {
        setTemplateBody(
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.templateBody,
        );
      }
    } else {
      setTemplateBody(templateDetails?.template_body);
    }
  }, [templateDetails?.template_body]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.emailTemplateId
    ) {
      //function to filter object based on Value
      const userInfo = getDefaultValue(
        userlListing?.data || [],
        "id",
        automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.to,
      );
      setInitialValues({
        subject:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.subject,
        emailTemplateId: {
          id: emailModelConfig?.template_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId,
          )?.id,
          value: emailModelConfig?.template_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId,
          )?.name,
        },
        emailAddress: emailModelConfig?.address_list.find(
          (ele: any) =>
            ele.id ===
            automationPayload?.draftedUIObject?.graphData?.nodes?.[
              automationReduxData?.selectedNodeId
            ]?.nodeObjectData?.emailAddress,
        ),
        from: {
          id: automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.from,
          value:
            automationPayload?.draftedUIObject?.graphData?.nodes?.[
              automationReduxData?.selectedNodeId
            ]?.nodeObjectData?.from,
        },
        to: {
          id: userInfo?.id,
          value: userInfo?.first_name,
        },
        replyTo:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.replyTo,
        fromName:
          automationPayload?.draftedUIObject?.graphData?.nodes?.[
            automationReduxData?.selectedNodeId
          ]?.nodeObjectData?.fromName,
      });
    }
  }, [automationPayload, emailModelConfig]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(AutomationBuilderActions.getModelConfigByType("email-config"));
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot?.bot_id,
    }),
  };
  useEffect(() => {
    const data = {
      HEADERS,
      limit: limit,
      offset: 0,
    };
    dispatch(UserActions.getUserListing(data));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  const handleSubmit = async (values: any, submitProps: any) => {
    let payload: any = {
      nodeObject: {
        subject: values?.subject,
        from: values?.from?.id,
        fromName: values?.fromName,
        emailTemplateId: values?.emailTemplateId?.id,
        emailAddress: values?.emailAddress?.id,
        templateBody: templateBody,
        variables: {},
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: `${templateDetails?.name}`,
    };
    if (values?.replyTo?.length > 0) {
      payload.nodeObject.replyTo = values?.replyTo;
    }
    if (automationReduxData?.modelType === "email-send_user") {
      payload.nodeObject.to = values?.to?.id;
    }
    submitProps.setSubmitting(false);

    dispatch(setNodeConfig(payload));
    await saveGraph();

    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  const onChange = (value: any) => {
    if (value?.id) {
      dispatch(EmailActions.getEmailTempListById(value.id));
    } else {
      formRef?.current?.setFieldValue("emailAddress", null);
    }
  };
  const onAddressChange = (value: any) => {
    if (value?.address) {
      let tempBody = JSON.parse(templateBody);
      setTemplateBody(
        JSON.stringify(
          tempBody.split("</body>")[0] +
            '<footer disabled="disabled" size="1" id="footer" style="text-align: center;">' +
            value.address +
            "</footer></body>" +
            tempBody.split("</body>")[1],
        ),
      );
    }
  };
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("subject", "", { shouldDirty: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ModelFormWrapper isDataAvaliable={emailModelError?.length}>
      <Typography variant="h6">
        {automationReduxData?.modelType === "email-send_user"
          ? "Send Email To User"
          : "Send Email"}
      </Typography>
      {emailModelError?.length ? (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      ) : (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={EmailConfigSchema}
          innerRef={formRef}
          initialIsValid={false}
        >
          <AppFormField
            name="subject"
            placeholder="Enter Email Subject"
            label="Email Subject" 
            requiredSign
          />
          <AppReactSelectField
            name={"emailTemplateId"}
            options={emailModelConfig?.template_list || []}
            label="Email Template"
            displayKey="name"
            valueKey="id"
            placeholder="Select Email Template"
            onChangeCallbackFn={onChange}
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
          />
          {formRef?.current?.values?.emailTemplateId && (
            <React.Fragment>
              <AppReactSelectField
                name={"emailAddress"}
                options={emailModelConfig?.address_list || []}
                label="Business Address"
                displayKey="address"
                valueKey="id"
                placeholder="Select Business Address"
                onChangeCallbackFn={onAddressChange}
                isSearchable={true}
                isClearable={true}
                hideSelectedOptions={true}
                isRequired={true}
                isDisabled={!formRef?.current?.values?.emailTemplateId}
                divStyle={{ background: "none", padding: "0" }}
              />
              <AppReactSelectField
                name={"from"}
                options={emailModelConfig?.email_list || []}
                label="From Email"
                displayKey="name"
                valueKey="id"
                placeholder="Select From Email"
                isSearchable={true}
                isClearable={true}
                hideSelectedOptions={true}
                isRequired={true}
                divStyle={{ background: "none", padding: "0" }}
              />
              <AppFormField
                name="fromName"
                placeholder="Enter from name"
                label="From Name"
              />
              {automationReduxData?.modelType === "email-send_user" && (
                <AppReactSelectField
                  name={"to"}
                  options={userlListing?.data || []}
                  label="Select User"
                  displayKey="first_name"
                  valueKey="id"
                  placeholder="Select User"
                  isSearchable={true}
                  isClearable={true}
                  hideSelectedOptions={true}
                  isDisabled={!formRef?.current?.values?.emailTemplateId}
                  divStyle={{ background: "none", padding: "0" }}
                />
              )}
              <AppFormField
                name="replyTo"
                placeholder="Enter email address"
                label="Reply To"
              />
            </React.Fragment>
          )}
          <AppFormObserver setFormikProps={setFormikProps} />
          <ModelFooter>
            <AppButton onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton
              title={"Save Config"}
              disabled={!formRef?.current?.isValid}
            />
          </ModelFooter>
        </AppForm>
      )}
    </ModelFormWrapper>
  );
};
export default withLoader(EmailSend);

const ModelFormWrapper = styled.div`
  width: ${(props: any) => (props.isDataAvaliable ? "500px" : "400px")};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container {
    text-align: center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
