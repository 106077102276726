import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { AppFormField } from '../../../../../AppComponents/Forms'
import { SettingPageMainBoxTitle } from '../../../../../Customization/CommonElements'
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../Store';
import { domainError } from '../../../../../Store/Slices/email/email.selectors';
import { resetError } from '../../../../../Store/Slices/email/email.slice';
interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
}
const RenderFormStep1: React.FC<Props> = ({ formStep }) => {
  const {setFieldError, errors} = useFormikContext<any>();
  const domainErroList = useAppSelector(domainError)
  const dispatch = useAppDispatch();
  useEffect(() => {
    if(domainErroList && !errors?.domain) {
      setFieldError('domain', domainErroList)
      dispatch(resetError(''))
    }
  },[domainErroList]) // eslint-disable-line

  return (
    <div className="mainDiv">
      <SettingPageMainBoxTitle>
        <h4>Step 1</h4>
      </SettingPageMainBoxTitle>
      <SettingPageMainBoxTitle>
        <p>
          Sending Domain Is Used To Verify Sender Whose Email Address Appearing In The FORM Header Of An Email.
          Add Your Own Verified Sending Domains To Send Emails On Behalf Of Your Domain
        </p>
      </SettingPageMainBoxTitle>

      <AppFormField
        name="domain"
        placeholder="Enter Domain"
        divStyle={{ width: '405px', marginBottom: '1rem' }}
      />
    </div>

  )
}


export default RenderFormStep1


