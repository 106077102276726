import { Backdrop, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { theme } from "../Components/Theme";

export interface HocBackdropLoaderProps {
  setLoading: (status: boolean) => void;
  isLoading: boolean;
  setLoadingText: React.Dispatch<React.SetStateAction<string>>
}

export const HocBackdropLoader = (WrappedComponent: any, loadingMessage?: string) => {
  function HocBackdropLoader(props: any) {
    const debounceDelay = 300;
    let isLoadingIntermediate: boolean = true;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>('Please wait while we process your request');
    let timeoutVar: any = null;

    const setLoadingState = (isComponentLoading: boolean) => {
      isLoadingIntermediate = isComponentLoading;
      if (!timeoutVar) {
        timeoutVar = setTimeout(() => {
          setIsLoading(isLoadingIntermediate);
          timeoutVar = null;
        }, debounceDelay);
      }
    };

    return (
      <React.Fragment>
        {isLoading && (
          <Backdrop sx={{ backgroundColor: 'rgba(0,0,0,0.5)', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1.5rem' }}>

              <CircularProgress color={`primary`} style={{ color: `${theme.palette.primary.main} !important` }} />
              <p style={{ color: 'white', fontSize: "1rem" }}>{loadingText}</p>
            </div>
          </Backdrop>
        )}
        <WrappedComponent {...props} setLoading={setLoadingState} isLoading={isLoading} setLoadingText={setLoadingText} />
      </React.Fragment>
    );
  }
  return HocBackdropLoader;
};
