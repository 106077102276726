import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { AppMaterialIcons } from "../../../../../../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../../../../../../assets/images/norecordsanalytics.svg";
import { TextTitle } from "../../../../../../../Dashboard/AnalyticsSummaryStyles";
import AppSkeletonLoader from "../../../../../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../../../../../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { AnalyticsHeader, Wrapper } from "../DashboardStyles";
import { GrahWrapper } from "./AllStyles";

interface Props {
  broadcastStats?: any;
  loading?: any;
}

const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#C589BF" : "#308fe8",
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FFDD55" : "#308fe8",
  },
}));

const BorderLinearProgress6 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "red" : "#308fe8",
  },
}));

export const Statistics: React.FC<Props> = ({ broadcastStats, loading }) => {
  return loading ? (
    <AppSkeletonLoader />
  ) : (
    <Wrapper>
      <Box sx={{ flexGrow: 1 }} className="graph_container" height={"100%"}>
        <GrahWrapper>
         
            <AnalyticsHeader>
              <Box className="heading__tooltip">
                <TextTitle style={{
                  marginLeft: "0px",
                }}>
                  <Typography className="heading">Statistics</Typography>
                </TextTitle>
                <AppToolTip
                  tooltipProps={{
                    title:
                      "Provides counts of sent, delivered and failed SMS messages.",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<AppMaterialIcons iconName="HelpOutline" />}
                />
              </Box>
            </AnalyticsHeader>
         
          {
              (broadcastStats?.sent ?? 0) === 0 &&
              (broadcastStats?.delivered ?? 0) === 0 &&
              (broadcastStats?.failed ?? 0) === 0 ? 
              (
                  <NoRecordsFound
                    imageWidth={"200px"}
                    headerTextSize={"19px"}
                    imageSrc={NoRecordImage}
                  /> 
              ):(
                <Box className="bar-wrap">
                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Sent</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{broadcastStats?.sent || 0}</p>
                        <BorderLinearProgress2
                          variant="determinate"
                          value={(broadcastStats?.sent / broadcastStats?.recipients) * 100}
                        />
                      </div>
                    </div>
                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Delivered</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{broadcastStats?.delivered || 0}</p>
                        <BorderLinearProgress3
                          variant="determinate"
                          value={(broadcastStats?.delivered / broadcastStats?.recipients) * 100}
                        />
                      </div>
                    </div>
                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Failed</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{broadcastStats?.failed || 0}</p>
                        <BorderLinearProgress6
                          variant="determinate"
                          value={(broadcastStats?.failed / broadcastStats?.recipients) * 100}
                        />
                      </div>
                    </div>
                </Box>
              )
          }     
        </GrahWrapper>
      </Box>
    </Wrapper>
  );
};
