export const customizedFilter = (filtersValue: any) => {
    const rulesArray: any[] = filtersValue?.rules?.map((obj: any) => {
        let tempObj = { ...obj } as any;
        // if (tempObj.field === "mobile" && tempObj.value.length > 0) {
        //     tempObj.value = Number(tempObj.value);
        // }
        if (tempObj?.operator === "$in" || tempObj?.operator === "$nin") {
            return { ...tempObj, value: tempObj?.value?.split(",") || [""] };
        }
        return tempObj;
    });
    let newFilter = {
        ...filtersValue,
        rules: rulesArray || [],
    };

    return newFilter;
};

export function getStatusTooltip(status :string,message:string) {
  if(status === "PENDING"){
    return "This template is under review, please check after sometime"
  }else{
    return "Rejected reason: " + message
  }
}

export function convertToK(data :any) {
  function roundToK(value :any) {
      if (value < 1000) {
          return value.toString();
      }
      return (value / 1000).toFixed(3) + 'K';
  }

  return {
      delivered: roundToK(data.delivered),
      received: roundToK(data.received),
      sent: roundToK(data.sent)
  };
}

export const WhatsappTemplateDashboardLayout = {

  lg: [
    { i: '1', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '2', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '3', x: 0, y: 0, w: 3, h: 3.3 },
    // { i: '3', x: 2, y: 0, w: 1, h: 3.3 },

    // { i: '4', x: 0, y: 3, w: 3, h: 2 },

    // { i: '5', x: 0, y: 6, w: 3, h: 2.5 },
  ],


  md: [
    { i: '1', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '2', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '3', x: 0, y: 0, w: 3, h: 3.3 },
    // { i: '3', x: 2, y: 0, w: 1, h: 3.3 },

    // { i: '4', x: 0, y: 1, w: 3, h: 2 },

    // { i: '5', x: 0, y: 5.5, w: 3, h: 2.5 },
  ],

  sm: [
    { i: '1', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '2', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '3', x: 0, y: 0, w: 3, h: 3.3 },

    // { i: '4', x: 0, y: 3.5, w: 2, h: 2 },

    // { i: '5', x: 0, y: 7, w: 3, h: 2.5 },
  ],

  xs: [
    { i: '1', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '2', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '3', x: 0, y: 0, w: 3, h: 3.3 },

    // { i: '4', x: 0, y: 2, w: 2, h: 2 },

    // { i: '5', x: 0, y: 0, w: 3, h: 2.5 },
  ],

  xxs: [
    { i: '1', x: 0, y: 0, w: 3, h: 3.3 },
    { i: '2', x: 0, y: 0, w: 3, h: 3.3 },
    // { i: '3', x: 0, y: 2, w: 1, h: 3.3 },

    // { i: '4', x: 0, y: 3, w: 1, h: 2 },

    // { i: '5', x: 0, y: 0, w: 3, h: 2.5 },
  ],

}

export const mainDashboardLayout = {
  lg: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 0, w: 1, h: 1 },
    { i: '3', x: 2, y: 0, w: 1, h: 1 },


    { i: '4', x: 0, y: 1, w: 1.5, h: 1 },
    { i: '5', x: 1.5, y: 1, w: 1.5, h: 1 },

    { i: '6', x: 0, y: 2, w: 1.8, h: 3 },
    { i: '7', x: 2, y: 2, w: 1.2, h: 3 },

    { i: '8', x: 0, y: 5, w: 3, h: 3 },


    { i: '9', x: 0, y: 8, w: 3, h: 4 },

    { i: '10', x: 0, y: 12, w: 1.5, h: 4 },
    { i: '11', x: 2, y: 12, w: 1.5, h: 4 },

    { i: '12', x: 0, y: 16, w: 1.5, h: 3 },
    { i: '13', x: 2, y: 16, w: 1.5, h: 3 },

    { i: '14', x: 0, y: 19, w: 1.5, h: 3.5 },
    // { i: '15', x: 2, y: 19, w: 1.5, h: 3.5 },

    { i: '15', x: 2, y: 19, w: 1.5, h: 3.5 },

    { i: '16', x: 2, y: 19, w: 3, h: 3.5 },

    { i: '17', x: 0, y: 22.5, w: 3, h: 3.5 },


  ],
  md: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 0, w: 1, h: 1 },
    { i: '3', x: 2, y: 0, w: 1, h: 1 },

    { i: '4', x: 0, y: 1, w: 1.5, h: 1 },
    { i: '5', x: 1.5, y: 1, w: 1.5, h: 1 },

    { i: '6', x: 0, y: 2, w: 1.8, h: 3 },
    { i: '7', x: 2, y: 2, w: 1.2, h: 3 },

    { i: '8', x: 0, y: 5, w: 3, h: 3 },

    { i: '9', x: 0, y: 8, w: 3, h: 4 },
    { i: '10', x: 2, y: 8, w: 3, h: 4 },

    { i: '11', x: 0, y: 12, w: 1.5, h: 3 },
    { i: '12', x: 2, y: 12, w: 1.5, h: 3 },
    { i: '13', x: 0, y: 15, w: 3, h: 3.5 },
    { i: '14', x: 1, y: 15, w: 3, h: 3.5 },

    { i: '15', x: 0, y: 19, w: 3, h: 4 },

    { i: '16', x: 2, y: 19, w: 3, h: 3.5 },

    { i: '17', x: 0, y: 22.5, w: 3, h: 3.5 },
  ],

  sm: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 0, w: 1, h: 1 },


    { i: '3', x: 0, y: 1, w: 1, h: 1 },
    { i: '4', x: 1, y: 1, w: 1, h: 1 },

    { i: '5', x: 0, y: 2, w: 2, h: 1 },


    { i: '6', x: 1, y: 3, w: 2, h: 3 },

    { i: '7', x: 0, y: 6, w: 2, h: 3 },

    { i: '8', x: 0, y: 9, w: 2, h: 3 },

    { i: '9', x: 0, y: 8, w: 3, h: 4 },
    { i: '10', x: 2, y: 8, w: 3, h: 4 },

    { i: '11', x: 0, y: 12, w: 3, h: 3 },
    { i: '12', x: 2, y: 12, w: 3, h: 3 },

    { i: '13', x: 0, y: 15, w: 3, h: 3.5 },
    { i: '14', x: 1, y: 15, w: 3, h: 3.5 },

    { i: '15', x: 0, y: 19, w: 3, h: 4 },

    { i: '16', x: 2, y: 19, w: 3, h: 3.5 },

    { i: '17', x: 0, y: 22.5, w: 3, h: 3.5 },
  ],

  xs: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },

    { i: '2', x: 0, y: 1, w: 1, h: 1 },

    { i: '3', x: 0, y: 2, w: 1, h: 1 },

    { i: '4', x: 0, y: 3, w: 1, h: 1 },

    { i: '5', x: 0, y: 4, w: 1, h: 1 },

    { i: '6', x: 0, y: 5, w: 1, h: 3 },

    { i: '7', x: 0, y: 8, w: 1, h: 3 },

    { i: '8', x: 0, y: 11, w: 1, h: 3 },

    { i: '9', x: 0, y: 8, w: 3, h: 4 },
    { i: '10', x: 2, y: 8, w: 3, h: 4 },

    { i: '11', x: 0, y: 12, w: 3, h: 3 },
    { i: '12', x: 2, y: 12, w: 3, h: 3 },

    { i: '13', x: 0, y: 15, w: 1, h: 3.5 },
    { i: '14', x: 1, y: 15, w: 2, h: 3.5 },

    { i: '15', x: 0, y: 19, w: 3, h: 4 },
    { i: '16', x: 2, y: 19, w: 3, h: 3.5 },
    { i: '17', x: 0, y: 22.5, w: 3, h: 3.5 },
  ],

};


export const whatsappBroadcastLayout = {
  lg: [

    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 0, w: 1, h: 1 },
    { i: '3', x: 2, y: 0, w: 1, h: 1 },


    { i: '4', x: 0, y: 1, w: 3, h: 3.5 },
  ],


  md: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 0, w: 1, h: 1 },
    { i: '3', x: 2, y: 0, w: 1, h: 1 },

    { i: '4', x: 0, y: 1, w: 3, h: 3.5 },
  ],

  sm: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 0, w: 1, h: 1 },

    { i: '3', x: 0, y: 1, w: 2, h: 1 },

    { i: '4', x: 0, y: 2, w: 2, h: 3.5 },
  ],

  xs: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 1, w: 1, h: 1 },
    { i: '3', x: 0, y: 2, w: 1, h: 1 },

    { i: '4', x: 0, y: 3, w: 1, h: 3.5 },
  ],

  xxs: [
    { i: '1', x: 0, y: 0, w: 1, h: 1 },
    { i: '2', x: 1, y: 1, w: 1, h: 1 },
    { i: '3', x: 0, y: 2, w: 1, h: 1 },

    { i: '4', x: 0, y: 3, w: 1, h: 3.5 },
  ],
};

export const whatsappBroadcastLayoutForConversations = {
  lg: [

    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 2.5 },
    { i: '3', x: 2, y: 0, w: 1, h: 2.5 },


    { i: '4', x: 0, y: 2.5, w: 3, h: 3.5 },

    { i: '5', x: 0, y: 6, w: 3, h: 2.5 },
  ],


  md: [
    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 2.5 },
    { i: '3', x: 2, y: 0, w: 1, h: 2.5 },

    { i: '4', x: 0, y: 2.5, w: 3, h: 3.5 },

    { i: '5', x: 0, y: 5.5, w: 3, h: 2.5 },
  ],

  sm: [
    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 2.5 },

    { i: '3', x: 0, y: 2.5, w: 2, h: 2.5 },

    { i: '4', x: 0, y: 4.5, w: 2, h: 3.5 },

    { i: '5', x: 0, y: 7, w: 3, h: 2.5 },
  ],

  xs: [
    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 2.5, w: 1, h: 2.5 },
    { i: '3', x: 0, y: 4.5, w: 1, h: 2.5 },

    { i: '4', x: 0, y: 7, w: 1, h: 3.5 },

    { i: '5', x: 0, y: 0, w: 3, h: 2.5 },
  ],

  xxs: [
    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 2.5, w: 1, h: 2.5 },
    { i: '3', x: 0, y: 4.5, w: 1, h: 2.5 },

    { i: '4', x: 0, y: 7, w: 1, h: 3.5 },

    { i: '5', x: 0, y: 0, w: 3, h: 2.5 },
  ],
};
export const smsBroadcastLayout = {
  lg: [
    { i: '1', x: 0, y: 0, w: 1, h: 3.3 },
    { i: '2', x: 1, y: 0, w: 1, h: 3.3 },
    { i: '3', x: 2, y: 0, w: 1, h: 3.3 },

    { i: '4', x: 0, y: 3, w: 3, h: 2 },

    { i: '5', x: 0, y: 6, w: 3, h: 2.5 },
  ],


  md: [
    { i: '1', x: 0, y: 0, w: 1, h: 3.3 },
    { i: '2', x: 1, y: 0, w: 1, h: 3.3 },
    { i: '3', x: 2, y: 0, w: 1, h: 3.3 },

    { i: '4', x: 0, y: 1, w: 3, h: 2 },

    { i: '5', x: 0, y: 5.5, w: 3, h: 2.5 },
  ],

  sm: [
    { i: '1', x: 0, y: 0, w: 1, h: 3.3 },
    { i: '2', x: 1, y: 0, w: 1, h: 3.3 },
    { i: '3', x: 0, y: 1, w: 2, h: 3.3 },

    { i: '4', x: 0, y: 3.5, w: 2, h: 2 },

    { i: '5', x: 0, y: 7, w: 3, h: 2.5 },
  ],

  xs: [
    { i: '1', x: 0, y: 0, w: 1, h: 3.3 },
    { i: '2', x: 1, y: 0, w: 1, h: 3.3 },
    { i: '3', x: 0, y: 1, w: 2, h: 3.3 },

    { i: '4', x: 0, y: 2, w: 2, h: 2 },

    { i: '5', x: 0, y: 0, w: 3, h: 2.5 },
  ],

  xxs: [
    { i: '1', x: 0, y: 0, w: 1, h: 3.3 },
    { i: '2', x: 0, y: 1, w: 1, h: 3.3 },
    { i: '3', x: 0, y: 2, w: 1, h: 3.3 },

    { i: '4', x: 0, y: 3, w: 1, h: 2 },

    { i: '5', x: 0, y: 0, w: 3, h: 2.5 },
  ],
};

export const emailBroadcastInsightsLayout = {

  lg: [
    { i: '1', x: 0, y: 0, w: 1, h: 3 },
    { i: '2', x: 1, y: 0, w: 1, h: 3 },
    { i: '3', x: 2, y: 0, w: 1, h: 3 },

    { i: '4', x: 0, y: 1, w: 3, h: 2.7 },

    { i: '5', x: 0, y: 6, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 6, w: 1, h: 3.5 },
    { i: '7', x: 2, y: 6, w: 1, h: 3.5 },

    { i: '8', x: 0, y: 9.5, w: 3, h: 3 },

    { i: '9', x: 0, y: 12.5, w: 3, h: 3 },

    { i: '10', x: 0, y: 15.5, w: 3, h: 2.5 },
  ],


  md: [
    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 2.5 },
    { i: '3', x: 2, y: 0, w: 1, h: 2.5 },

    { i: '4', x: 0, y: 1, w: 3, h: 3 },

    { i: '5', x: 0, y: 5.5, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 5.5, w: 1, h: 3.5 },
    { i: '7', x: 2, y: 5.5, w: 1, h: 3.5 },

    { i: '8', x: 0, y: 9, w: 3, h: 3 },

    { i: '9', x: 0, y: 12, w: 3, h: 3 },

    { i: '10', x: 0, y: 15, w: 3, h: 2.5 },

  ],

  sm: [
    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 2.5 },
    { i: '3', x: 0, y: 1, w: 2, h: 2.5 },

    { i: '4', x: 0, y: 2, w: 2, h: 3 },

    { i: '5', x: 0, y: 0, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 0, w: 1, h: 3.5 },
    { i: '7', x: 0, y: 1, w: 2, h: 3.5 },

    { i: '8', x: 0, y: 9, w: 3, h: 3 },

    { i: '9', x: 0, y: 12, w: 3, h: 3 },

    { i: '10', x: 0, y: 15, w: 3, h: 2.5 },
  ],

  xs: [
    { i: '1', x: 0, y: 0, w: 1, h: 2.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 2.5 },
    { i: '3', x: 0, y: 1, w: 2, h: 2.5 },

    { i: '4', x: 0, y: 2, w: 2, h: 3 },

    { i: '5', x: 0, y: 0, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 0, w: 1, h: 3.5 },
    { i: '7', x: 0, y: 1, w: 2, h: 3.5 },

    { i: '8', x: 0, y: 9, w: 3, h: 3 },

    { i: '9', x: 0, y: 12, w: 3, h: 3 },

    { i: '10', x: 0, y: 15, w: 3, h: 7.1 },
  ],

  xxs: [
    { i: '1', x: 0, y: 0, w: 1, h: 3 },
    { i: '2', x: 0, y: 1, w: 1, h: 3 },
    { i: '3', x: 0, y: 2, w: 1, h: 3 },

    { i: '4', x: 0, y: 3, w: 1, h: 3 },

    { i: '5', x: 0, y: 0, w: 1, h: 3 },
    { i: '6', x: 0, y: 1, w: 1, h: 3 },
    { i: '7', x: 0, y: 2, w: 1, h: 3 },

    { i: '8', x: 0, y: 9, w: 1, h: 3 },

    { i: '9', x: 0, y: 12, w: 1, h: 3 },

    { i: '10', x: 0, y: 15, w: 3, h: 7.1 },
  ],
  
};


export const emailDetailsLayouts = {
  lg: [
    { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
    { i: '3', x: 2, y: 0, w: 1, h: 1.5 },

    { i: '4', x: 0, y: 1, w: 3, h: 2.7 },

    { i: '5', x: 0, y: 6, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 6, w: 1, h: 3.5 },
    { i: '7', x: 2, y: 6, w: 1, h: 3.5 },

    { i: '8', x: 0, y: 9.5, w: 3, h: 3 },

    { i: '9', x: 0, y: 12.5, w: 3, h: 2.5 },

    { i: '10', x: 0, y: 15, w: 3, h: 2.5 },
  ],


  md: [
    { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
    { i: '3', x: 2, y: 0, w: 1, h: 1.5 },

    { i: '4', x: 0, y: 1, w: 3, h: 3 },

    { i: '5', x: 0, y: 5.5, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 5.5, w: 1, h: 3.5 },
    { i: '7', x: 2, y: 5.5, w: 1, h: 3.5 },

    { i: '8', x: 0, y: 9, w: 3, h: 3 },

    { i: '9', x: 0, y: 12, w: 3, h: 2.5 },

    { i: '10', x: 0, y: 14.5, w: 3, h: 2.5 },

  ],

  sm: [
    { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
    { i: '3', x: 0, y: 1, w: 2, h: 1.5 },

    { i: '4', x: 0, y: 2, w: 2, h: 3 },

    { i: '5', x: 0, y: 0, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 0, w: 1, h: 3.5 },
    { i: '7', x: 0, y: 1, w: 2, h: 3.5 },

    { i: '8', x: 0, y: 9, w: 3, h: 3 },

    { i: '9', x: 0, y: 12, w: 3, h: 2.5 },

    { i: '10', x: 0, y: 14.5, w: 3, h: 2.5 },
  ],

  xs: [
    { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
    { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
    { i: '3', x: 0, y: 1, w: 2, h: 1.5 },

    { i: '4', x: 0, y: 2, w: 2, h: 3 },

    { i: '5', x: 0, y: 0, w: 1, h: 3.5 },
    { i: '6', x: 1, y: 0, w: 1, h: 3.5 },
    { i: '7', x: 0, y: 1, w: 2, h: 3.5 },

    { i: '8', x: 0, y: 9, w: 3, h: 3 },

    { i: '9', x: 0, y: 12, w: 3, h: 2.5 },

    { i: '10', x: 0, y: 14.5, w: 3, h: 7.1 },
  ],

  xxs: [
    { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
    { i: '2', x: 0, y: 1, w: 1, h: 1.5 },
    { i: '3', x: 0, y: 2, w: 1, h: 1.5 },

    { i: '4', x: 0, y: 3, w: 1, h: 3 },

    { i: '5', x: 0, y: 0, w: 1, h: 3 },
    { i: '6', x: 0, y: 1, w: 1, h: 3 },
    { i: '7', x: 0, y: 2, w: 1, h: 3 },

    { i: '8', x: 0, y: 9, w: 1, h: 3 },

    { i: '9', x: 0, y: 12, w: 1, h: 2.5 },

    { i: '10', x: 0, y: 14.5, w: 3, h: 7.1 },
  ],
};