import { Skeleton } from "@mui/material";

interface AppSkeletonLoaderProps {
    skeletonVariant?: any;
    width?: string;
    height?: string;
}

const AppSkeletonLoader: React.FC<AppSkeletonLoaderProps> = ({
    skeletonVariant = "rectangular",
    width = "100%",
    height = "100%",
}) => {
    return (<Skeleton
        variant={skeletonVariant}
        width={width} 
        height={height}
        sx={{
            borderRadius: "4px",
        }} 
    />);
}

export default AppSkeletonLoader;