import { useSelector } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import moment from "moment";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Box, MenuItem, Typography, debounce, Select } from "@mui/material";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import TemplateAPIERROR from "../../../assets/images/No_records_found.svg";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import AppRangeDatePicker from "../../../AppComponents/AppRangeDatePicker";
import { IconButton } from "@mui/material";
import { AppForm } from "../../../AppComponents/Forms";
import AppFormSearchField from "../../../AppComponents/Forms/AppFormSearchField";
import {
  HocBackdropLoaderProps,
  HocBackdropLoader,
} from "../../../HOC/HocBackdropLoader";
import { useAppDispatch } from "../../../Store";
import {
  filtersValuesData,
  logListDataObj,
} from "../../../Store/Slices/BroadcastDashboard/Broadcast.selectors";
import { broadcastActions } from "../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { marketingListActions } from "../../../Store/Slices/MarketingList/marketingList.actions";
import { AppPagination } from "../../../TableStyle";
import {
  BroadcastMainWrapper,
  BroadcastMainWrapperTitle,
  BroadcastBodyWrapper,
} from "../../Broadcast/SMS/Logs/LogsStyles";
import { ErrorComponent } from "../../Broadcast_Dashboard/Email_Broadcast_Logs/EmailDashboardLogsStyles";
import { NewNativeSelect } from "../style";
import {
  BillingLogTable,
  BillingMainWrapper,
  BillingTableWrapper,
  LeftCol,
  RightCol,
  TopBar,
  TopInfoBarBox,
} from "./billing-styles";
import styled from "styled-components";
import { CoreService } from "../../../Services/Apis/CoreApis";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { useNavigate } from "react-router-dom";
import { SubscriptionActions } from "../../../Store/Slices/Subscriptions/subscriptions.actions";
import { getwhatsappCreditLogsData } from "../../../Store/Slices/Subscriptions/subscriptions.selectors";
import { DefinedCustomDates } from "../../../Models/defaultSelectOptions";

interface Props extends HocBackdropLoaderProps {}

const WhatsappBillingLogs: React.FC<Props> = ({ setLoading }) => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState<any>([]);
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const filtersValues = useSelector(filtersValuesData);
  const logListData = useSelector(getwhatsappCreditLogsData);
  const totalcount = logListData?.count;
  const list = logListData?.data?.response;
  const [logsData, setLogsData] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<any>("");
  const header = [
    "Type",
    "Total Conversations",
    "Total Messages",
    "Total Amount",
  ];

  const keyToHeaderMap: any = {
    Type: "label",
    "Total Conversations": "unique_conversations",
    "Total Messages": "total_messages",
    "Total Amount": "total_amount",
  };

  const colWidth: any = {
    Type: "220",
    "Total Conversations": "250",
    "Total Messages": "300",
    "Total Amount": "220",
  };
  // const keyList = ["sent_on", "recipients", "status", "sent", "delivered", "failed"]
  const [localValues, setLocalValues] = useState<any>({
    status: "",
    startDate: DefinedCustomDates?.last7Days?.startDate,
    endDate: DefinedCustomDates?.last7Days?.endDate,
    search: "",
    itemsPerPage: 10,
    currentPage: 0,
    broadcast_type: "email",
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorDownloadEl, setAnchorDownloadEl] = useState<null | HTMLElement>(
    null,
  );

  const handleDownClose = () => {
    setAnchorDownloadEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLogsData = () => {
    let start = null;
    let end = null;
    if (localValues.startDate && localValues.endDate) {
      start = format(localValues.startDate, "yyyy-MM-dd");
      end = format(localValues.endDate, "yyyy-MM-dd");
    }
    setLoading(true);
    dispatch(
      SubscriptionActions.getWhatsappCreditLogs({
        start: start,
        limit: localValues.itemsPerPage,
        offset: localValues.currentPage,
        end: end,
      }),
    )
      .then((res: any) => {
        setErrorApi(false);
        setLoading(false);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error",
        );
        setLoading(false);
        // setOnSearch(false);
      });
  };

  const getValueForFilterLogs = () => {
    dispatch(
      broadcastActions.getAllCommunicationFilterSettings({ type: "email" }),
    )
      .then((res: any) => {})
      .catch((error: any) => {});
  };

  const restFilter = () => {
    setLocalValues({
      status: "",
      startDate: null,
      endDate: null,
      search: "",
      itemsPerPage: 10,
      currentPage: 0,
      broadcast_type: "email",
    });
    setLogsData(true);
    handleClose();
  };

  const applyFilter = () => {
    setLogsData(true);
    handleClose();
  };

  useEffect(() => {
    if (logsData) {
      getLogsData();
      setLogsData(false);
    }
  }, [logsData]);

  useEffect(() => {
    getLogsData();
  }, []);

  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
    setLogsData(true);
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
    setLogsData(true);
  };
  const handleChangeDate = (data: { startDate: Date; endDate: Date }) => {
    setLocalValues({
      ...localValues,
      startDate: data.startDate,
      endDate: data.endDate,
    });
    setLogsData(true);
  };
  const handleSelectChange = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e.target.value,
    });
    setLogsData(true);
  };

  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format("DD MMM, YYYY HH:mm A");
    return date;
  };

  useEffect(() => {
    // handleExportClick();
  }, [filterType && isDownloading]);

  const handleExportClick = () => {
    if (filterType) {
      setIsDownloading(true);
      let payload: any;
      if (filterType === "filter") {
        payload = {
          start: format(localValues.startDate, "yyyy-MM-dd"),
          end: format(localValues.endDate, "yyyy-MM-dd"),
          status: localValues?.status,
          search: localValues?.search,
          type: "email",
          filter_type: filterType,
        };
      }
      if (filterType === "all") {
        payload = {
          type: "email",
          filter_type: filterType,
        };
      }
      dispatch(marketingListActions.getCommunicationLogsDownload(payload))
        .then((res) => {
          window.open(res?.payload?.data?.data.source, "_blank");
        })
        .catch((error) => {
          console.error("Error occurred while fetching the file URL.", error);
        });
      setIsDownloading(false);
      setFilterType("");
    }
  };

  const setDownloadFiled = (filterType: any) => {
    setFilterType(filterType);
    setIsDownloading(true);
    handleDownClose();
  };

  const renderSelectIconStatus = localValues.status ? (
    <GridCloseIcon fontSize="small" />
  ) : null;

  useEffect(() => {
    CoreService.getCredits().then((response: any) => {
      const reversedItems = response?.data;
      setCredits(reversedItems);
    });
  }, []);
  return (
    <>
      <BillingMainWrapper>
        <TopBar>
          <LeftCol>
            <label>To know your Messaging Limit please</label>
            <Box className="limit">
              <a
                href="https://developers.facebook.com/docs/whatsapp/messaging-limits/"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>
            </Box>
          </LeftCol>
          <RightCol>
            <Box className="contact">
              <label>
                <span className="contact-label">Connected WA No: </span>
                <span> {credits?.whatsapp_no}</span>
              </label>
            </Box>
            <Box className="monthly-conversations__wrapper">
              <Box className="conversations">
                <Box className="monthly-conversations">
                  <AppToolTip
                    tooltipProps={{
                      title: `Each WhatsApp Business Account gets 1,000 free service conversations (user-initiated conversations) each month across all of its business phone numbers. This number is refreshed at the beginning of each month, based on WhatsApp Business Account time zone. Marketing, utility and authentication conversations are not part of the free tier`,
                      placement: "right",
                    }}
                    iconButtonProps={{ disableRipple: "true" }}
                    IconComponent={<InfoOutlinedIcon />}
                    divStyle={{ marginRight: "5px", marginBottom: "2px" }}
                  />
                  <div className="conversations-label">
                    Free Monthly conversations:
                    <strong> {credits?.free_conversation || 0}</strong>
                  </div>
                </Box>
                <Box>{/* 80 */}</Box>
              </Box>
              <Box className="credits">
                <Box>
                  <label></label>
                </Box>
              </Box>
            </Box>
          </RightCol>
        </TopBar>
        <TopInfoBarBox>
          <Box>
            <IconButton onClick={() => navigate(-1)}>
              <AppMaterialIcons iconName="ChevronLeftOutlined" />
            </IconButton>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"16px"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography fontSize="17px" fontWeight="500" component="h6">
                Whatsapp Billing Details
              </Typography>
            </Box>
          </Box>
        </TopInfoBarBox>
        <BillingTableWrapper>
          <BroadcastMainWrapperTitle>
            <div className="fields-container">
              <div className="title">
                <p style={{ fontSize: "13px" }}>Filter By</p>
              </div>
              <div className="sms_right">
                <div className="sms-date_picker">
                  <AppRangeDatePicker
                    divStyle={{
                      width: "225px",
                    }}
                    reset={true}
                    dateRange={localValues}
                    setDateRange={handleChangeDate}
                  />
                </div>
              </div>
            </div>
          </BroadcastMainWrapperTitle>
          <BillingLogTable
            length={list?.length}
            // width={colWidth}
          >
            <div>
              {/* <LogTableWrapper> */}
              <Box className="table-header">
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        // style={{
                        //   maxWidth: colWidth[header] + "px",
                        //   minWidth: colWidth[header] + "px",
                        //   // border: "1px solid",
                        // }}
                      >
                        <Typography>{header}</Typography>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
              <Box className="table-body">
                {errorApi || list?.length === 0 ? (
                  <ErrorComponent
                    style={{
                      border: "none",
                      height: "calc(100vh - 214px)",
                    }}
                  >
                    <Box display="flex" justifyContent="center">
                      <img width="400" src={TemplateAPIERROR} alt="" />
                    </Box>
                    <Box className="error-Info">
                      <Typography variant="h6" fontSize="20px">
                        No Records Found
                      </Typography>
                    </Box>
                  </ErrorComponent>
                ) : (
                  <>
                    {list?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => (
                            <Box
                              className={`col-body ${header
                                .toLowerCase()
                                .replaceAll(" ", "_")}`}
                              title={
                                header === "Date"
                                  ? formatDateField(row[keyToHeaderMap[header]])
                                  : row[keyToHeaderMap[header]]
                                  ? row[keyToHeaderMap[header]]
                                  : "-"
                              }
                              // style={{
                              //   maxWidth: colWidth[header] + "px",
                              //   minWidth: colWidth[header] + "px",
                              //   // border: "1px solid",
                              // }}
                            >
                              <Typography className="table-text">
                                {header === "Date"
                                  ? formatDateField(row[keyToHeaderMap[header]])
                                  : row[keyToHeaderMap[header]]
                                  ? row[keyToHeaderMap[header]]
                                  : "-"}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
            </div>
            {/* </LogTableWrapper> */}
          </BillingLogTable>
        </BillingTableWrapper>
        {/* {list?.length ? (
          <AppPagination
            component="div"
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={localValues.currentPage}
            count={totalcount}
            rowsPerPage={localValues.itemsPerPage}
          />
        ) : null} */}
      </BillingMainWrapper>

      {/* Old */}
    </>
  );
};

// Default Export of component
export default HocBackdropLoader(WhatsappBillingLogs);
