import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useAppSelector } from "../../../Store";
import {
  BotList,
  botDateRange,
  project_id,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { DashboardNetworkService } from "../../../Store/Slices/DashboardRedux/DashboardNetworkService";
import { BroadcastDetailListWrapper } from "../../Broadcast/SMS/SenderID/SenderIDStyles";
import { TextTitle } from "../AnalyticsSummaryStyles";
import DashboardCommonInfScrollTable from "../../Analytics/CommonInfScrollTable";
import NoData from "../../../assets/images/noDatafound.svg";
import { ErrorComponent } from "../../Broadcast/SMS/SenderID/ListingStyles";
import { Box, Typography } from "@mui/material";
import SkeletonLoader from "../../../AppComponents/Loader/SkeletonLoader";
import styled from "styled-components";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";

interface Props {}

interface ColumnData {
  dataKey: string;
  label: string;
  width: string;
  align?: string;
  padding?: string;
}

const locationTableColumns: ColumnData[] = [
  {
    label: "Locations",
    dataKey: "names",
    align: "start",
    width: "50%",
  },
  {
    label: "Conversations",
    dataKey: "conversations",
    align: "start",
    width: "25%",
  },
  {
    label: "Leads",
    dataKey: "contacts",
    align: "start",
    width: "25%",
  },
];

const AcquisitionByLocation: React.FC<Props> = (props) => {
  const [pageNo, setPageNo] = useState(0);
  const dashdateRange: any = useAppSelector(botDateRange);
  const projectId = useAppSelector(project_id);
  const botList = useAppSelector(BotList);
  const [locationHasMoreBooleanValue, setLocationHasMoreBooleanValue] =
    useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  var PAGE_SIZE = 10;
  const [list, setList] = useState<any>([]);

  function getUniqueAfterMerge(arr1: any[], arr2: any[]) {
    // merge two arrays
    let arr = arr1.concat(arr2);
    let uniqueArr = [];
    // loop through array
    for (let i of arr) {
      if (uniqueArr.indexOf(i) === -1) {
        uniqueArr.push(i);
      }
    }
    return uniqueArr;
  }

  //API call for acquisition location

  const getLocationsData = (p: number = 0) => {
    setLoading(true);
    let payload2: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      // pageNumber: p,
      // pageSize: PAGE_SIZE,
    };
    if (botList.length > 0) {
      payload2 = {
        ...payload2,
        bot_id: botList,
        site_id: projectId,
      };
    }

    DashboardNetworkService.getAquisitionByLocation(payload2)
      .then((response: any) => {
        if (response?.data?.data?.data?.length) {
          const temp = list || [];
          const newData = getUniqueAfterMerge(temp, response?.data?.data?.data);
          setList(newData);
        } else {
          setList([]);
        }
        if (
          response?.data?.data?.pagination?.currentPage ===
          response?.data?.data?.pagination?.totalPages
        ) {
          setLocationHasMoreBooleanValue(false);
        }

        setPageNo(p);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate && projectId && botList?.length) {
      getLocationsData();
    }
  }, [dashdateRange, projectId, botList]); //eslint-disable-line react-hooks/exhaustive-deps

  function convertCountryDataIntoTableFormat(data: any[]) {
    const tempArray = data?.map((item: any, index: number) => {
      return {
        names: item?.name,
        conversations: item?.conversations,
        contacts: item?.contacts,
      };
    });
    return tempArray;
  }

  return (
    <WrapperContainer>
      <p className="time_stats">
        <TextTitle>
          Acquisition By Locations
          <AppToolTip
            tooltipProps={{
              title: "Number of visitors segregated by location.",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={
              <AppMaterialIcons
                iconName="HelpOutline"
                style={{ fontSize: "10px" }}
              />
            }
          />
        </TextTitle>
      </p>
      <BroadcastDetailListWrapper style={{ padding: "0px 16px 0px 16px" }}>
        {list.length === 0 ? (
          !loading ? (
            <ErrorComponent style={{ height: "225px" }}>
              <Box>
                <img src={NoData} width="150" alt="" />
              </Box>
              <Box className="err-content">
                <Typography variant="h6" fontSize="20px">
                  No Records Found.
                </Typography>
              </Box>
            </ErrorComponent>
          ) : (
            <SkeletonLoader numberOfCol={3} numberofRow={2} />
          )
        ) : (
          <DashboardCommonInfScrollTable
            columns={locationTableColumns}
            dataRows={convertCountryDataIntoTableFormat(list)}
            // fetchMoreData={() => getLocationsData(pageNo + 1)}
            fetchMoreData={() => {}}
            hasMore={locationHasMoreBooleanValue}
            loadingState={false}
          />
        )}
      </BroadcastDetailListWrapper>
    </WrapperContainer>
  );
};

// Default Export of component
export default AcquisitionByLocation;

export const WrapperContainer = styled.div`
  width: 100%;
`;
