import store from "../../Store/index";
import {
  removeElementFromPayload,
  removeLinkFromPayload,
  setGraphUI,
  setLinkNode,
  setNewNode,
  setTargetedNode,
} from "../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { ShapeConfigurations } from "./defaultOptions";
import {
  getElementType,
  openEditModal,
  renderCard,
  renderWhatsappCard,
} from "./utils";
const jQuery = require("jquery");
const joint = require("jointjs");
const { dia, shapes } = joint;
export const getSelectionList = (type) => {
  return "";
};

export const stencilLabelAttrs = {
  refX: null, // reset the default
  x: -10,
  textAnchor: "end",
  fontFamily: "sans-serif",
  fontSize: 12,
};

export const ShapeType = {
  Rectangle: "Rectangle",
  Ellipse: "Ellipse",
  Label: "Label",
  Document: "Document",
  Rhombus: "Rhombus",
  Tooltip: "Tooltip",
};

const shapesConfig = [
  {
    id: "emailActivity-input",
    group: "left",
  },
  {
    id: "out1",
    group: "right",
    attrs: {
      portLabel: {
        text: "Out 1",
      },
      ".joint-port-body": {
        fill: "red",
      },
    },
  },
  {
    id: "out2",
    group: "right",
    attrs: {
      portLabel: {
        text: "Out 2",
      },
      ".joint-port-body": {
        fill: "blue",
      },
    },
  },
  {
    id: "out3",
    group: "right",
    attrs: {
      portLabel: {
        text: "Out 3",
        fill: "#23a423",
      },
      ".joint-port-body": {
        fill: "#b1ffb1",
        stroke: "#23a423",
      },
    },
  },
];
const graph = new dia.Graph({}, { cellNamespace: shapes });
export const saveGraph = async () => {
  await store.dispatch(setGraphUI(graph.toJSON()));
};
export const clearGraph = async () => {
  graph.clear();
};
export const createGraph = (nodes) => {
  const paperElelemt = document.getElementById("paper-container");
  if (paperElelemt) {
    const paper = new dia.Paper({
      model: graph,
      cellViewNamespace: shapes,
      width: "100%",
      height: "100%",
      async: true,
      sorting: dia.Paper.sorting.APPROX,
      background: { color: "#F3F7F6" },
      linkPinning: false,
      embeddingMode: true,
      snapLabels: true,
      gridSize: 10,
      restrictTranslate: true,
      clickThreshold: 1,
      drawGrid: !0,
      markAvailable: !0,
      validateEmbedding: function (childView, parentView) {
        return parentView.model instanceof joint.shapes.devs.Coupled;
      },
      defaultLink: () => {
        const link = new shapes.standard.Link({
          router: {
            name: "manhattan",
          },
          labels: [
            {
              position: 0.5,
              attrs: {
                text: {
                  text: "",
                  "font-weight": "normal",
                  "font-size": "12px",
                  transform: "translate(0, 1)",
                },
                rect: {
                  rx: 10,
                  ry: 10,
                  "ref-y": 6,
                  "ref-x": 10,
                },
              },
            },
          ],
          connector: {
            name: "rounded",
            args: {
              radius: 5,
              raw: !0,
            },
          },
          attrs: {
            ".tool-options": {
              "data-tooltip-class-name": "small",
              "data-tooltip": "Click to open Inspector for this link",
              "data-tooltip-position": "left",
            },
            ".marker-source": {
              d: "M 15 0 L 0 15 L 15 30 z",
              stroke: "transparent",
              fill: "#6B778C",
              transform: "scale(0.08)",
            },
            ".marker-target": {
              d: "M 15 0 L 0 15 L 15 30 z",
              stroke: "transparent",
              fill: "#6B778C",
              transform: "scale(0.35)",
            },
            ".connection": {
              stroke: "#6B778C",
              "stroke-dasharray": "0",
              "stroke-width": 2,
            },
            line: {
              stroke: "#4666E5",
            },
          },
        });
        return link;
      },
      // validateMagnet: function (cellView, magnet) {
      //     var links = graph.getLinks();
      //     for (var i = 0; i < links.length; i++) {
      //         if (((cellView.model.id == links[i].get('source').id) && (magnet.getAttribute('port') == links[i].get('source').port)) ||
      //             ((cellView.model.id == links[i].get('target').id) && (magnet.getAttribute('port') == links[i].get('target').port)))
      //             return false;
      //     }
      //     var port = magnet.getAttribute('port');
      //     var links = graph.getConnectedLinks(cellView.model, { outbound: true });
      //     var portLinks = _.filter(links, function (o) {
      //         return o.get('source').port == port;
      //     });
      //     if (portLinks.length > 0) return false;
      //     // Note that this is the default behaviour. Just showing it here for reference.
      //     // Disable linking interaction for magnets marked as passive (see below `.inPorts circle`).
      //     return magnet.getAttribute('magnet') !== 'passive';
      // },
      validateConnection: function (S, d, T, E, r, s) {
        if (E == null) return false;
        // Prevent link to self
        if (S === T) return false;

        const sourceCell = S.model;
        const sourceLinks = graph.getConnectedLinks(sourceCell);

        const targetCell = T.model;
        const targetLinks = graph.getConnectedLinks(targetCell);

        let isConnection;

        // Compare link IDs of source and target elements
        targetLinks.forEach((linkT) => {
          sourceLinks.forEach((linkS) => {
            if (linkS.id === linkT.id) isConnection = true;
          });
        });
        if (isConnection) {
          return false;
        }
        // If source and target already contain a link with the same id , return false
        // if (
        //   isConnection ||
        //   S.el.childNodes[3].childNodes[0].textContent ===
        //     "Block not yet configured"
        // )
        //   return false;
        if (
          S.el.childNodes[3].childNodes[0].textContent ===
          "Block not yet configured"
        ) {
          S.el.classList.add("shake", "red");
          setTimeout(function () {
            S.el.classList.remove("shake");
          }, 1000);
          return false;
        } else if (
          T.el.childNodes[3].childNodes[0].textContent ===
          "Block not yet configured"
        ) {
          T.el.classList.add("shake", "red");
          setTimeout(function () {
            T.el.classList.remove("shake");
          }, 1000);
          return false;
        }
        if (T.el.getAttribute("data-type").indexOf("Trigger") > -1) {
          return false;
        }
        if (
          !((d && "left" === d.getAttribute("port-group")) || S === T) &&
          E &&
          ((E.getAttribute("class") || "").indexOf("card") > -1 ||
            "left" === E.getAttribute("port-group"))
        ) {
          return true;
        } else {
          return false;
        }
      },
      elementView: dia.ElementView.extend({
        events: {
          "change input,select": "onInputChange",
        },

        onInputChange: function (evt) {
          const input = evt.target;
          if (!input.validity.valid) return;
          const valuePath =
            input.getAttribute("joint-selector") + "/props/value";
          const currentValue = this.model.attr(valuePath);
          this.model.attr(valuePath, input.value, {
            previousValue: currentValue,
            calc: true,
          });
        },
      }),
    });

    var scale = joint.V(paper.viewport).scale(),
      dragStartPosition = { x: 0, y: 0 },
      saveDragPosition = { x: 0, y: 0 };
    paper.on("blank:pointerdown", function (event, x, y) {
      scale = joint.V(paper.viewport).scale();
      dragStartPosition = { x: x * scale.sx, y: y * scale.sy };
      document
        .getElementById("paper-container")
        .addEventListener("mousemove", mouseMoveEvent);
    });
    paper.on("blank:pointermove", function (event, x, y) {
      saveDragPosition = {
        x: event.offsetX - dragStartPosition.x,
        y: event.offsetY - dragStartPosition.y,
      };
    });
    paper.on("blank:pointerup", function (event, x, y) {
      document
        .getElementById("paper-container")
        .removeEventListener("mousemove", mouseMoveEvent);
    });
    function mouseMoveEvent(event) {
      if (dragStartPosition)
        paper.translate(
          event.offsetX - dragStartPosition.x,
          event.offsetY - dragStartPosition.y,
        );
    }
    if (nodes?.cells) {
      graph.fromJSON(nodes);
    }
    paper.on({
      "link:mouseenter": function (linkView) {
        linkView.addTools(
          new joint.dia.ToolsView({
            tools: [
              new joint.linkTools.Vertices({ snapRadius: 0 }),
              new joint.linkTools.Remove({
                distance: 20,
                attributes: {
                  class: "remove-tool-icon",
                },
                markup: [
                  {
                    tagName: "circle",

                    attributes: {
                      r: 12,
                      fill: "#DFE1E6",
                      cursor: "pointer",
                      stroke: "#ffffff",
                    },
                  },
                  {
                    tagName: "path",
                    selector: "icon",
                    attributes: {
                      d: "M9.5 3.375H9.07812L8.04688 12.0469C8.02344 12.2422 7.93359 12.4062 7.77734 12.5391C7.62109 12.6797 7.44531 12.75 7.25 12.75H2.75C2.55469 12.75 2.37891 12.6797 2.22266 12.5391C2.06641 12.4062 1.97656 12.2422 1.95312 12.0469L0.921875 3.375H0.5C0.398438 3.375 0.308594 3.33984 0.230469 3.26953C0.160156 3.19141 0.125 3.10156 0.125 3C0.125 2.89844 0.160156 2.8125 0.230469 2.74219C0.308594 2.66406 0.398438 2.625 0.5 2.625H3.125V1.875C3.125 1.5625 3.23438 1.29687 3.45312 1.07812C3.67188 0.859375 3.9375 0.75 4.25 0.75H5.75C6.0625 0.75 6.32812 0.859375 6.54688 1.07812C6.76562 1.29687 6.875 1.5625 6.875 1.875V2.625H9.5C9.60156 2.625 9.6875 2.66406 9.75781 2.74219C9.83594 2.8125 9.875 2.89844 9.875 3C9.875 3.10156 9.83594 3.19141 9.75781 3.26953C9.6875 3.33984 9.60156 3.375 9.5 3.375ZM6.125 1.875C6.125 1.77344 6.08594 1.6875 6.00781 1.61719C5.9375 1.53906 5.85156 1.5 5.75 1.5H4.25C4.14844 1.5 4.05859 1.53906 3.98047 1.61719C3.91016 1.6875 3.875 1.77344 3.875 1.875V2.625H6.125V1.875ZM1.67188 3.375L2.70312 11.9531C2.70312 11.9609 2.70703 11.9727 2.71484 11.9883C2.73047 11.9961 2.74219 12 2.75 12H7.25C7.25781 12 7.26562 11.9961 7.27344 11.9883C7.28906 11.9727 7.29688 11.9609 7.29688 11.9531L8.32812 3.375H1.67188Z",
                      fill: "#505F79",
                      stroke: "#505F79",
                      "stroke-width": 1,
                      cursor: "pointer",
                      stroke: 0.4,
                      "pointer-events": "none",
                      transform: "translate(-5, -7)",
                    },
                  },
                ],
                pointerclick: (state) => {},
              }),
            ],
          }),
        );
      },
      "link:mouseleave": function (linkView) {
        linkView.removeTools();
      },
    });

    // graph.on('remove', function (cell, collection, opt) {
    //     console.log(cell, collection, opt, "hidden");
    //     if (cell.isLink()) {
    //         // a link was removed  (cell.id contains the ID of the removed link)
    //     }
    // })

    var a = 0;
    paper.on("link:pointerup", function (n, e) {
      jQuery(".input-port-path").css({
        stroke: "rgb(76 154 255 / 0)",
      });

      a = false;
      if (
        n.model.attributes.source &&
        jQuery(e.target).parents(".joint-cell").length > 0
      ) {
      }

      // setTargetedNode(graph.getElements().filter(ele => ele.id === jQuery(t.target).parents(".joint-cell").attr("model-id"))[0])
      // store.dispatch()
      setTimeout(function () {
        a = 0;
      }, 500);
    });
    graph.on("remove", function (e, a, f) {
      if (e.attributes.type === "standard.Link") {
        store.dispatch(removeLinkFromPayload(e));
        setTimeout(function () {
          saveGraph();
        }, 500);
      } else {
        store.dispatch(removeElementFromPayload(e));
        setTimeout(function () {
          saveGraph();
        }, 500);
      }
    });
    graph.on("change:source change:target", function (link) {
      if (link.get("source").id && link.get("target").id) {
        let labelElement = shapesConfig.filter(
          (el) => link.get("source").port === el.id,
        );
        let targetedElement = jQuery(
          "[model-id=" + link.get("target").id + "]",
        );
        let sourceElement = jQuery("[model-id=" + link.get("source").id + "]");

        let payload = {
          nodeId: sourceElement.attr("model-id"),
          edges: [
            {
              to: targetedElement.attr("model-id"),
              class: getElementType(targetedElement.attr("data-type"), "class"),
              function: getElementType(
                targetedElement.attr("data-type"),
                "function",
              ),
              port: link.get("source").port,
            },
          ],
        };

        if (
          getElementType(sourceElement.attr("data-type"), "initial") ===
            "action" &&
          (getElementType(sourceElement.attr("data-type"), "class") ===
            "email" ||
            getElementType(sourceElement.attr("data-type"), "class") === "sms")
        ) {
          payload.edges[0].class = getElementType(
            sourceElement.attr("data-type"),
            "class",
          );
          payload.edges[0].function = link.get("source").port;
        }
        if (!targetedElement.hasClass("Selected")) {
          store.dispatch(setLinkNode(payload));
          store.dispatch(setGraphUI(graph.toJSON()));
          targetedElement.addClass("Selected");
        } else {
          targetedElement.removeClass("Selected");
        }
        setTimeout(function () {
          targetedElement.removeClass("Selected");
        }, 500);

        link.appendLabel({
          attrs: {
            text: {
              text:
                "On " +
                (labelElement?.[0]?.attrs?.portLabel?.text ||
                  link.get("source").port.split("-")[0]),
              textVerticalAnchor: "middle",
              textAnchor: "middle",
              fontSize: 10,
              fill: "black",
            },
            body: {
              fill: "blue",
            },
          },
        });
      }
    });
    paper.on("cell:pointermove", function (cellView, evt) {
      if (cellView.model.isLink()) {
        return;
      }
      a = true;
      var parent = cellView.model.getAncestors()[0];
      // console.log(parent, "parent");
      // if we trying to move with embedded cell
      if (parent) {
        // cancel move for the child (currently dragged element)
        cellView.pointerup(evt);
        var view = paper.findViewByModel(parent);

        // substitute currently dragged element with the parent
        paper.sourceView = view;

        // get parent's position and continue dragging (with the parent, children are updated automaticaly)
        var localPoint = paper.snapToGrid({ x: evt.clientX, y: evt.clientY });
        view.pointerdown(evt, localPoint.x, localPoint.y);
      }
    });
    paper.on("link:pointermove", function (n, t, e, S) {
      // console.log(n, t, e, S, "pointermove");
      if (
        n.model.attributes.source &&
        jQuery(t.target).parents(".joint-cell").attr("model-id") !==
          n.model.attributes.source.id &&
        jQuery(t.target).parents(".joint-cell").length > 0
      ) {
        jQuery(t.target).parents(".joint-cell").find(".input-port-path").css({
          stroke: "rgb(76 154 255 / 50%)",
          "stroke-width": "2px",
        });
        store.dispatch(setGraphUI(graph.toJSON()));
        store.dispatch(
          setTargetedNode(
            graph
              .getElements()
              .filter(
                (ele) =>
                  ele.id ===
                  jQuery(t.target).parents(".joint-cell").attr("model-id"),
              )[0],
          ),
        );
      } else {
        jQuery(".input-port-path").css({
          stroke: "rgb(76 154 255 / 0)",
        });
      }
      a = !0;
    });
    // paper.on('cell:mouseenter', function (Te, Fe) {
    //     console.log(Te, Fe, "cell:mouseenter")

    // })

    paper.on("element:mouseenter", (elementView, ele) => {
      elementView.addTools(
        new joint.dia.ToolsView({
          tools: [
            new joint.elementTools.Remove({
              useModelGeometry: true,
              y: "0%",
              x: "10%",
              selector: "button",
              attributes: {
                class: "btn with-tooltip remove-tool-icon",
                "data-tooltip": "Remove Node",
              },
              markup: [
                {
                  tagName: "circle",

                  attributes: {
                    r: 12,
                    fill: "#DFE1E6",
                    cursor: "pointer",
                    stroke: "#ffffff",
                  },
                },
                {
                  tagName: "path",
                  selector: "icon",
                  attributes: {
                    d: "M9.5 3.375H9.07812L8.04688 12.0469C8.02344 12.2422 7.93359 12.4062 7.77734 12.5391C7.62109 12.6797 7.44531 12.75 7.25 12.75H2.75C2.55469 12.75 2.37891 12.6797 2.22266 12.5391C2.06641 12.4062 1.97656 12.2422 1.95312 12.0469L0.921875 3.375H0.5C0.398438 3.375 0.308594 3.33984 0.230469 3.26953C0.160156 3.19141 0.125 3.10156 0.125 3C0.125 2.89844 0.160156 2.8125 0.230469 2.74219C0.308594 2.66406 0.398438 2.625 0.5 2.625H3.125V1.875C3.125 1.5625 3.23438 1.29687 3.45312 1.07812C3.67188 0.859375 3.9375 0.75 4.25 0.75H5.75C6.0625 0.75 6.32812 0.859375 6.54688 1.07812C6.76562 1.29687 6.875 1.5625 6.875 1.875V2.625H9.5C9.60156 2.625 9.6875 2.66406 9.75781 2.74219C9.83594 2.8125 9.875 2.89844 9.875 3C9.875 3.10156 9.83594 3.19141 9.75781 3.26953C9.6875 3.33984 9.60156 3.375 9.5 3.375ZM6.125 1.875C6.125 1.77344 6.08594 1.6875 6.00781 1.61719C5.9375 1.53906 5.85156 1.5 5.75 1.5H4.25C4.14844 1.5 4.05859 1.53906 3.98047 1.61719C3.91016 1.6875 3.875 1.77344 3.875 1.875V2.625H6.125V1.875ZM1.67188 3.375L2.70312 11.9531C2.70312 11.9609 2.70703 11.9727 2.71484 11.9883C2.73047 11.9961 2.74219 12 2.75 12H7.25C7.25781 12 7.26562 11.9961 7.27344 11.9883C7.28906 11.9727 7.29688 11.9609 7.29688 11.9531L8.32812 3.375H1.67188Z",
                    fill: "#505F79",
                    stroke: "#505F79",
                    "stroke-width": 1,
                    cursor: "pointer",
                    stroke: 0.4,
                    "pointer-events": "none",
                    transform: "translate(-5, -7)",
                  },
                },
              ],
              distance: 60,
              offset: 0,
              // action: function (evt) {
              //     // evt.stopPropagation();
              //     let payload = {
              //         node: this,
              //         modelId: this.model.id,
              //         viewId: this.id,
              //         callbackFunction: this.remove,
              //     }

              //     store.dispatch(showDeleteModal(payload))
              //     // alert('View id: ' + this.id + '\n' + 'Model id: ' + this.model.id);

              // }
            }),
          ],
        }),
      );
      document
        .querySelectorAll(".joint-port")
        .forEach((ele) => (ele.style.display = "block"));
    });

    paper.on("element:mouseleave", (elementView) => {
      elementView.removeTools();
    });
    function getOffset(el) {
      var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      return { x: rect.left - scrollLeft, y: rect.top - scrollTop };
    }
    document.addEventListener("mousedown", (e) => {
      if (e.target.classList.contains("stencil-item")) {
        // console.log(cellView, "cellView")
        let flyElement = document.createElement("div");
        flyElement.id = "flyPaper";
        flyElement.setAttribute(
          "data-type",
          e.target.getAttribute("data-type"),
        );
        flyElement.setAttribute(
          "data-trigger-type",
          e.target.getAttribute("data-trigger-type"),
        );
        flyElement.style.cssText =
          "position:fixed;z-index:100;opacity:.7;pointer-event:none;";
        flyElement.appendChild(e.target.cloneNode(true));
        document.querySelector("#paper-container")?.after(flyElement);

        var flyShape = e.target.cloneNode(true),
          offset = getOffset(e.target);
        jQuery(flyElement).offset({
          left: e.pageX,
          top: e.pageY,
        });
        // flyGraph.addCell(flyShape.outerHTML);
        let Element = document.querySelector("#flyPaper");
        if (Element) {
          jQuery(Element).offset({
            left: e.pageX,
            top: e.pageY,
          });
        }
        function mouseMove(e) {
          jQuery(Element).offset({
            left: e.pageX,
            top: e.pageY,
          });
          offset = {
            x: e.pageX,
            y: e.pageY,
          };
        }
        function mouseUp(e) {
          var x = e.pageX,
            y = e.pageY,
            target = paper.$el.offset();

          // markip: '<g class="rotatable"><g class="scalable annotation">' +
          // this.get("shape") : Xe.getStickerImage(this.attributes)) + "</g>" + O + Xe.getRemoveIcon() + "</g>"
          // Dropped over paper ?
          if (
            x > target.left &&
            x < target.left + paper.$el.width() &&
            y > target.top &&
            y < target.top + paper.$el.height()
          ) {
            let a = flyElement.getAttribute("data-type").split(".")[0];
            let b = flyElement.getAttribute("data-type").split(".")[1];
            let c = ShapeConfigurations[a][b];
            let s = {};
            if (c?.type === "web") {
              let renderConfig = {
                shape: c.shape,
                title: c.title,
                icon: c.icon,
                nodeDescription: c.nodeDescription,
                ports: c.ports,
              };
              s = renderCard(renderConfig, e);
            } else if (c?.type === "whatsapp") {
              let renderConfig = {
                shape: c.shape,
                title: c.title,
                type: c.componentType,
                markup: c.markup,
                ports: c.ports,
              };
              s = renderWhatsappCard(renderConfig, e);
              var c1 = new joint.shapes.devs.Coupled({
                position: {
                  x: 230,
                  y: 50,
                },
                size: {
                  width: 300,
                  height: 300,
                },
                ".body": {
                  rx: 6,
                  ry: 6,
                },
              });
              graph.addCells([c1]);

              c1.set("inPorts", ["in"]);
              c1.set("outPorts", ["out 1", "out 2"]);
              c1.embed(s);
            }
            s.attributes.type = flyElement.getAttribute("data-type");
            s.prop(
              "data-trigger-type",
              flyElement.getAttribute("data-trigger-type"),
            );

            s.position(
              offset.x -
                target.left -
                (saveDragPosition.x !== 0 ? saveDragPosition.x : 0),
              offset.y -
                target.top -
                (saveDragPosition.y !== 0 ? saveDragPosition.y : 0),
            );

            graph.addCell(s);
            store.dispatch(
              setNewNode({
                nodeId: s.id,
                type: getElementType(s.attributes.type, "initial"),
                class: getElementType(s.attributes.type, "class"),
                function: getElementType(s.attributes.type, "function"),
              }),
            );
            store.dispatch(setGraphUI(graph.toJSON()));
          }
          document
            .querySelector("#root")
            ?.removeEventListener("mousemove", mouseMove);
          document
            .querySelector("#root")
            ?.removeEventListener("mouseup", mouseUp);
          flyShape.remove();
          document.querySelector("#flyPaper")?.remove();
        }
        document
          .querySelector("#root")
          ?.addEventListener("mousemove", mouseMove);
        document.querySelector("#root")?.addEventListener("mouseup", mouseUp);
      }
    });

    // Check if the block is already configured else trigger Model
    paper.on("element:pointerup", function (e, evt, x, y) {
      // e.target.classList.contains("input-port-path")
      if (e.model.attributes["data-trigger-type"] === "web" && !a) {
        openEditModal(e);
        [...document.getElementsByClassName("joint-cell")].forEach((ele) => {
          ele.classList.remove("red");
        });
      } else {
        a = false;
      }
    });
    // Render Paper Element
    paperElelemt.appendChild(paper.el);
  }
};
