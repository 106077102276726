import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FaqSourceAction } from "./source.actions";
interface initialStateTypes {
  data: {
    crawlLinkList: any;
    sourceListing: any;
    sourceListingPagination: any;
    sourceTextById: any;
    deletedSourceData: any;
    createSource: any;
    sourceError: any;
    answeredListingCount: any;
    siteMappedUrlsData?: any;
    sourceSelectedIds: any;
    crawlLinkPagination: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    crawlLinkList: null,
    sourceListing: null,
    sourceListingPagination: null,
    sourceTextById: null,
    deletedSourceData: null,
    createSource: null,
    sourceError: null,
    answeredListingCount: null,
    siteMappedUrlsData: null,
    sourceSelectedIds: [],
    crawlLinkPagination: null,
  },
  error: "",
  loading: false,
};

const FaqSourceSliceReducer = createSlice({
  name: "FaqSourceSliceReducer",
  initialState,
  reducers: {
    setcrawlLinkList(state, { payload }: PayloadAction<any>) {
      state.data.crawlLinkList = payload;
    },
    setSiteMappedUrls(state, { payload }: PayloadAction<any>) {
      state.data.siteMappedUrlsData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //* add website crawl url
      .addCase(
        FaqSourceAction.CreateWebsiteCrawlUrl.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        FaqSourceAction.CreateWebsiteCrawlUrl.fulfilled,
        (state, action) => {
          state.loading = false;
          state.data.siteMappedUrlsData = action.payload.data;
        }
      )
      .addCase(
        FaqSourceAction.CreateWebsiteCrawlUrl.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      //* add sitemap crawl url
      .addCase(
        FaqSourceAction.CreateSiteMapCrawlUrl.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        FaqSourceAction.CreateSiteMapCrawlUrl.fulfilled,
        (state, action) => {
          state.loading = false;
          state.data.siteMappedUrlsData = action.payload.data;
        }
      )
      .addCase(
        FaqSourceAction.CreateSiteMapCrawlUrl.rejected,
        (state, action) => {
          state.loading = false;
        }
      )

      //* get website crawl url list
      .addCase(
        FaqSourceAction.GetWebsitesCrawlUrls.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        FaqSourceAction.GetWebsitesCrawlUrls.fulfilled,
        (state, action) => {
          state.data.crawlLinkList = action.payload.data?.urls;
          state.data.crawlLinkPagination = action.payload.data?.pagination;
          state.loading = false;
        }
      )
      .addCase(
        FaqSourceAction.GetWebsitesCrawlUrls.rejected,
        (state, action) => {
          state.loading = false;
          state.data.crawlLinkList = action?.error?.message || "";
        }
      )

      //* get siteMap crawl url list
      .addCase(FaqSourceAction.GetSiteMapCrawlUrls.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        FaqSourceAction.GetSiteMapCrawlUrls.fulfilled,
        (state, action) => {
          state.data.crawlLinkList = action.payload.data?.urls;
          state.loading = false;
        }
      )
      .addCase(
        FaqSourceAction.GetSiteMapCrawlUrls.rejected,
        (state, action) => {
          state.loading = false;
          state.data.crawlLinkList = action?.error?.message || "";
        }
      )

      //* Add suburl when crawl  list

      .addCase(FaqSourceAction.AddSubUrl.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FaqSourceAction.AddSubUrl.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(FaqSourceAction.AddSubUrl.rejected, (state, action) => {
        state.loading = false;
      })

      //* post   selected suburl   list

      .addCase(FaqSourceAction.PostSelectedSubUrl.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(
        FaqSourceAction.PostSelectedSubUrl.fulfilled,
        (state, action) => {
          state.loading = false;
        }
      )
      .addCase(FaqSourceAction.PostSelectedSubUrl.rejected, (state, action) => {
        state.loading = false;
      })

      // Source Listing
      .addCase(FaqSourceAction.GeteSourceListing.pending, (state, action) => {
        state.data.sourceListing = [];
        state.loading = true;
      })
      .addCase(FaqSourceAction.GeteSourceListing.fulfilled, (state, action) => {
        state.data.sourceListing = action?.payload?.data?.sources || [];
        state.data.sourceListingPagination =
          action?.payload?.data?.pagination || 0;
        state.loading = false;
      })
      .addCase(FaqSourceAction.GeteSourceListing.rejected, (state, action) => {
        state.loading = false;
        state.data.sourceListing = [];
      })

      // Get Source Text By ID
      .addCase(FaqSourceAction.GetSourceTextById.pending, (state, action) => {
        state.data.sourceTextById = [];
        state.loading = true;
      })
      .addCase(FaqSourceAction.GetSourceTextById.fulfilled, (state, action) => {
        state.data.sourceTextById = action?.payload?.data || {};
        state.loading = false;
      })
      .addCase(FaqSourceAction.GetSourceTextById.rejected, (state, action) => {
        state.loading = false;
        state.data.sourceTextById = [];
      })

      //* add one Link url
      .addCase(FaqSourceAction.addOneLink.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(FaqSourceAction.addOneLink.fulfilled, (state, action) => {
        // state.data.crawlLinkList = action.payload.data?.urls;
        state.loading = false;
      })
      .addCase(FaqSourceAction.addOneLink.rejected, (state, action) => {
        state.loading = false;
        // state.data.crawlLinkList = action?.error?.message || "";
      })

      //* Delete Source
      .addCase(FaqSourceAction.DeleteSourceByIds.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(FaqSourceAction.DeleteSourceByIds.fulfilled, (state, action) => {
        state.data.deletedSourceData = action?.payload?.data?.status;
        state.error = "";
        state.loading = false;
      })
      .addCase(FaqSourceAction.DeleteSourceByIds.rejected, (state, action) => {
        state.loading = false;
        state.data.deletedSourceData = null;
        state.error = action?.error?.message || "";
      })

      // Add Source
      .addCase(FaqSourceAction.CreateSourceText.pending, (state, action) => {
        state.data.createSource = [];
        state.loading = true;
      })
      .addCase(FaqSourceAction.CreateSourceText.fulfilled, (state, action) => {
        state.data.createSource = action?.payload?.data?.sources || [];
        state.loading = false;
      })
      .addCase(FaqSourceAction.CreateSourceText.rejected, (state, action) => {
        state.loading = false;
        state.data.createSource = [];
      });
  },
});

export const { setcrawlLinkList, setSiteMappedUrls } =
  FaqSourceSliceReducer.actions;

export default FaqSourceSliceReducer.reducer;
