export const compareStringBetweenSlashes = (a: string, b: string): boolean => {
  // Extract the string between the first two slashes in variable 'a'
  const firstTwoSlashes = a?.match(/^\/([^/]+)\/([^/]+)/);
  const stringBetweenSlashes = firstTwoSlashes ? firstTwoSlashes[1] : "";
  // Compare the extracted string with variable 'b'
  return stringBetweenSlashes === b ?? false;
};

export const getSelectedMenuObject = (
  menuOptions: any,
  locationPathname: string
) => {
  const selectedMenu = menuOptions?.find((item: any) => {
    return compareStringBetweenSlashes(locationPathname, item?.value);
  });

  return selectedMenu ?? undefined;
};
