import { createSlice } from "@reduxjs/toolkit";
import { CreditDetailsActions } from "./CreditDetails.actions";

interface initialStateTypes {
  data: {
    broadcastCreditDetails: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    broadcastCreditDetails: null,
  },
  error: "",
  loading: false,
};

const CreditDetailSlice = createSlice({
  name: "CreditDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Get Contact List by Id
      .addCase(
        CreditDetailsActions.getBroadcastCreditDetails.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        }
      )
      .addCase(
        CreditDetailsActions.getBroadcastCreditDetails.fulfilled,
        (state, action) => {
          state.data.broadcastCreditDetails = action?.payload?.data?.credits;
          state.error = "";
          state.loading = false;
        }
      )
      .addCase(
        CreditDetailsActions.getBroadcastCreditDetails.rejected,
        (state, action) => {
          state.loading = false;
          state.data.broadcastCreditDetails = null;
          state.error = action?.error?.message || "";
        }
      )
  },
});

export default CreditDetailSlice.reducer;
