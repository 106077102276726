export const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

export const getFromInitTime = new Date().setHours(9, 0, 0, 0);
export const getToInitTime = new Date().setHours(17, 0, 0, 0);

export const durationOptions = [
  { label: "15 mins", value: "15 mins" },
  { label: "30 mins", value: "30 mins" },
  { label: "45 mins", value: "45 mins" },
  { label: "60 mins", value: "60 mins" },
  { label: "Custom", value: "custom" },
];

export const timeIntervalUnit = [
  { label: "Mins", value: "mins" },
  { label: "Hrs", value: "hrs" },
];

export const initialData = [
  {
    day: "SUN",
    from: new Date(getFromInitTime).toISOString(),
    to: new Date(getToInitTime).toISOString(),
    enabled: true,
  },
  {
    day: "MON",
    from: new Date(getFromInitTime).toISOString(),
    to: new Date(getToInitTime).toISOString(),
    enabled: true,
  },
  {
    day: "TUE",
    from: new Date(getFromInitTime).toISOString(),
    to: new Date(getToInitTime).toISOString(),
    enabled: true,
  },
  {
    day: "WED",
    from: new Date(getFromInitTime).toISOString(),
    to: new Date(getToInitTime).toISOString(),
    enabled: true,
  },
  {
    day: "THU",
    from: new Date(getFromInitTime).toISOString(),
    to: new Date(getToInitTime).toISOString(),
    enabled: true,
  },
  {
    day: "FRI",
    from: new Date(getFromInitTime).toISOString(),
    to: new Date(getToInitTime).toISOString(),
    enabled: true,
  },
  {
    day: "SAT",
    from: new Date(getFromInitTime).toISOString(),
    to: new Date(getToInitTime).toISOString(),
    enabled: true,
  },
];
