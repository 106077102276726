import * as React from 'react';

import { SettingPageMainBoxTitle } from '../../../../../Customization/CommonElements';
import { Typography } from '@mui/material';

interface Props {
  formStep: number;
}

const RenderFromStep4: React.FC<Props> = ({ formStep }) => {

  return (
    <div className="mainDiv">
      <Typography fontSize={"20px"} mb={3} fontWeight={500}>Step 4</Typography>
        <Typography className='text-grey' mb={2}>
          Great! Your domain is verified! You can now enjoy the platform by<br />
          sending your emails from any address associated with this domain.
        </Typography>
      <Typography className='text-grey' mb={4}>
          <span>For example, you can send emails from <span className='text-dark'>company@getalabs.com</span> or <span className='text-dark'>info@getalabs.com.</span> It is up to you!</span>
      </Typography>


    </div>

  )
};
export default RenderFromStep4