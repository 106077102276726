import { Backdrop, Box, CircularProgress, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
} from "../../../AppComponents/Forms";
import { ProtectedAppUiElement } from "../../../AppComponents/ProtectedComponents";
import { AppQueryBuilder } from "../../../AppComponents/QueryBuilder";
import {
  convertQueryToMongo,
  initialQuery,
  isQueryValid,
} from "../../../AppComponents/QueryBuilder/utils/utils";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import {
  enabledViewsListing,
  getFilterQuery,
  getSavedFilterQuery,
  selectedContactViewState,
  viewsListing,
} from "../../../Store/Slices/Contacts/contacts.selector";
import { setFilterQuery } from "../../../Store/Slices/Contacts/contacts.slice";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import { QueryBuilderActions } from "../../../Store/Slices/QueryBuilder/QueryBuilder.actions";
import { useQueryBuilderStore } from "../../../Store/Slices/QueryBuilder/QueryBuilder.selectors";

interface Props {
  editData?: any;
  setLocalValues: any;
  toggleDrawer: any;
}

const QueryBuilderForContacts: React.FC<Props> = ({
  editData,
  setLocalValues,
  toggleDrawer,
}) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formikRef = useRef<any>(null);
  const filterQuery: any = useSelector(getFilterQuery);

  const savedFilterQuery = useSelector(getSavedFilterQuery);
  const allViewsData: any = useAppSelector(viewsListing);

  const { queryBuilderFields, queryBuilderOperators, loading } =
    useAppSelector(useQueryBuilderStore);
  const selectedContactView: any = useAppSelector(selectedContactViewState);
  const enabledViewsData: any = useAppSelector(enabledViewsListing);

  const [userInput, setUserInput] = useState({
    name: selectedContactView?.name,
    filter_query: filterQuery ? filterQuery : initialQuery,
  });

  // useEffect(() => {
  //   setUserInput({
  //     ...userInput,
  //     name: selectedContactView?.name,
  //     filter_query: filterQuery,
  //   });
  //   dispatch(setFilterQuery(filterQuery));
  // }, [filterQuery]);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    const validQuery = isQueryValid(values?.filter_query, savedFilterQuery);

    if (!validQuery) {
      showAlert("Please add only valid rules.", "error");
      SubmitProps.setSubmitting(false);
      return;
    }
    const mongoQuery = convertQueryToMongo(values?.filter_query);
    dispatch(setFilterQuery(values?.filter_query));
    setLocalValues((prev: any) => ({
      ...prev,
      mongoQuery: mongoQuery,
      currentPage: 0,
      // filter_query: values?.filter_query,
    }));
    toggleDrawer();
  };

  const getFieldsDataFromBackend = () => {
    let module_id = "other";
    let payload = {
      id: module_id,
    };
    dispatch(QueryBuilderActions.getQueryBuilderMappingFields(payload));
  };

  useEffect(() => {
    getFieldsDataFromBackend();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onQueryChangeCallbackFn = (filtersValue: any) => {
    // console.log("callback", filtersValue)
    formikRef?.current?.setFieldValue("filter_query", filtersValue);
    dispatch(setFilterQuery(filtersValue));
  };

  const resetFilter = () => {
    dispatch(setFilterQuery(initialQuery));
    const mongoQuery = convertQueryToMongo(initialQuery);
    dispatch(setFilterQuery(initialQuery));
    setLocalValues((prev: any) => ({
      ...prev,
      mongoQuery: mongoQuery,
      currentPage: 0,
      // filter_query: values?.filter_query,
    }));
    toggleDrawer();
  };
  const handleSaveAsView = () => {
    const values = formikRef?.current?.values;
    const validQuery = isQueryValid(values?.filter_query, savedFilterQuery);

    if (!validQuery) {
      showAlert("Please add only valid rules.", "error");
      formikRef?.current?.setSubmitting(false);
      return;
    }
    const mongoQuery = convertQueryToMongo(values?.filter_query);
    setLocalValues((prev: any) => ({
      ...prev,
      mongoQuery: mongoQuery,
      currentPage: 0,
      filter_query: values?.filter_query,
    }));
    let payload = {
      ...selectedContactView,
      name: values?.name + " copy",
      type: "custom",
      filter_query: values?.filter_query,
      mongodb_query: mongoQuery,
      position:
        (enabledViewsData?.[enabledViewsData?.length - 1]?.position ?? 0) + 1,
    };
    ContactsNetworkService.createContactView(payload)
      .then((res: any) => {
        dispatch(ContactsActions.getAllContactsViews({})).then(
          (response: any) => {
            if (response?.payload?.data) {
              navigate(`/contacts/list/?view=${res?.data?.data?.viewId}`);
            }
          }
        );
      })
      .catch((err: any) => {
        console.log("err", err);
      })
      .finally(() => {
        toggleDrawer();
      });
  };

  const handleUpdateView = () => {
    const values = formikRef?.current?.values;
    const validQuery = isQueryValid(values?.filter_query, savedFilterQuery);

    if (!validQuery) {
      showAlert("Please add only valid rules.", "error");
      formikRef?.current?.setSubmitting(false);
      return;
    }
    const mongoQuery = convertQueryToMongo(values?.filter_query);
    dispatch(setFilterQuery(values?.filter_query));
    setLocalValues((prev: any) => ({
      ...prev,
      mongoQuery: mongoQuery,
      currentPage: 0,
      filter_query: values?.filter_query,
    }));
    let payload = {
      id: selectedContactView?.viewId,
      data: {
        ...selectedContactView,
        filter_query: values?.filter_query,
        mongodb_query: mongoQuery,
        name: values?.name,
      },
    };

    ContactsNetworkService.updateContactView(payload)
      .then((res: any) => {
        showAlert("View updated", "success");
        dispatch(ContactsActions.getAllContactsViews({}));
        navigate(`/contacts/list?view=${selectedContactView?.viewId}`);
        formikRef?.current?.resetForm();
      })
      .catch((err: any) => {
        console.log("err", err);
        showAlert("Something went wrong", "error");
      })
      .finally(() => {
        // setEditDrawerStatus(false);
        toggleDrawer();
      });
  };

  const handleApplyFilter = () => {
    const values = formikRef?.current?.values;
    const validQuery = isQueryValid(values?.filter_query, savedFilterQuery);

    if (!validQuery) {
      showAlert("Please add only valid rules.", "error");
      formikRef?.current?.setSubmitting(false);
      return;
    }
    const mongoQuery = convertQueryToMongo(values?.filter_query);
    dispatch(setFilterQuery(values?.filter_query));
    setLocalValues((prev: any) => ({
      ...prev,
      mongoQuery: mongoQuery,
      currentPage: 0,
      // filter_query: values?.filter_query,
    }));
    toggleDrawer();
  };

  return (
    <Box className="form-first__area" sx={{ height: "calc(100% - 57px)" }}>
      <BackdropLoader loading={loading} />
      <AppForm
        innerRef={formikRef}
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        divStyle={{
          gap: "8px",
          flex: 1,
        }}
      >
        {queryBuilderFields?.length > 0 ? (
          <AppQueryBuilder
            fields={queryBuilderFields}
            operators={queryBuilderOperators}
            onChangeCallbackFn={onQueryChangeCallbackFn}
            defaultInputValueFromApi={
              userInput?.filter_query?.id ? userInput?.filter_query : initialQuery
            }
          />
        ) : null}

        <Divider />
        <Box
          display="flex"
          sx={{
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "8px 0",
            gap: "1.5rem",
          }}
        >
          <AppButton
            onClick={() => resetFilter()}
            style={{ marginRight: "26px" }}
            disabled={savedFilterQuery?.rules?.length === 0}
            variant="grey"
          >
            Reset
          </AppButton>
          <ProtectedAppUiElement
            moduleId="contacts_views"
            specificPermissionKey="create"
          >
            <AppButton
              onClick={() => handleSaveAsView()}
              style={{ marginRight: "20px" }}
              disabled={savedFilterQuery?.rules?.length === 0}
            >
              Save as view
            </AppButton>
          </ProtectedAppUiElement>
          {selectedContactView?.type !== "system" ? (
            <>
              {(selectedContactView?.can_edit ?? selectedContactView?.can_update) ? (
                <AppButton
                  onClick={() => handleUpdateView()}
                  style={{ marginRight: "20px" }}
                  disabled={savedFilterQuery?.rules?.length === 0}
                >
                  Update view
                </AppButton>
              ) : null}
            </>
          ) : null}
          <AppButton
            onClick={() => handleApplyFilter()}
            disabled={savedFilterQuery?.rules?.length === 0}
          >
            Apply
          </AppButton>

          {/* {isQueryValid(filterQuery, savedFilterQuery) ? (
            <AppSubmitButton title="SAVE" />
          ) : (
            <AppSubmitButton disabled={true} title="SAVE" />
          )} */}
        </Box>
      </AppForm>
    </Box>
  );
};

export default QueryBuilderForContacts;

interface BackdropProps {
  loading: boolean;
}
export const BackdropLoader: React.FC<BackdropProps> = ({
  loading = false,
}) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 99 }}
      open={loading ? true : false}
      onClick={() => undefined}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
