import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import useDocumentTitle from '../../../Hooks/useDocumentTitle'
import CustomizeSidebar from '../Customize/CustomizeSidebar'
import { DeployMenuOptions } from '../utils/contants'

const DeploySettings = () => {
  useDocumentTitle("Deploy Bot")
  return (
    <CustomizeWrap>
      <CustomizeSidebar Options={DeployMenuOptions} />
      <MainBox>
        <Outlet />
      </MainBox>
    </CustomizeWrap>
  )
}

export default DeploySettings;

const CustomizeWrap = styled.div`
&& {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
.MuiPaper-elevation1 {
    box-shadow: none !important;
  }
}
`;
const MainBox = styled.div`
&& {
width: 85%;
height: 100%;
flex: 1;
height: calc(100vh - 168px);
background-color: #f5f6f8;
display: flex;
overflow: hidden;
overflow-y: auto;
}
`;
