// vendors
import { useContext, useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useOnClickOutside from "use-onclickoutside";

// builder
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";
import { getNodeConfig } from "./../../flow-builder-v2/utils";

// hooks
import { useAction } from "./../../flow-builder-v2/hooks";

// components
import { NodeType, Editor, MessagePreview } from "./../../ui-components";
import Card from "../card";

// utils
import {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
} from "./../utils/editor";
import { getText } from "./../utils/helper";

const LanguageNode = (props: any) => {
  const builderContext = useContext(BuilderContext);
  const nodeCntx = useContext(NodeContext);
  const node = getNodeConfig(nodeCntx.subType || nodeCntx.type);
  const { updateNode, removeNode } = useAction();
  const { setActiveNode, activeNode } = builderContext;
  const text = getText(nodeCntx.response);
  const [message, setMessage] = useState("");
  const [nodeConfig, setNodeConfig] = useState<any>(null);
  const [showPreview, setPreview] = useState<boolean>(true);
  const [mediaDetails, setMediaDetails] = useState<any>(
    nodeCntx.mediaDetails || ""
  );
  const wrapperRef = useRef(null);

  const [editorState, setEditorState] = useState(getInitialEditiorText(""));

  useOnClickOutside(wrapperRef, (event) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }

    if (!showPreview) {
      const previousPlainText = nodeCntx?.response
      ? getPlainTextData(getInitialEditiorText(getText(nodeCntx.response)))
      : "";
      const plainText = getPlainTextData(editorState);

      if(plainText === '') return;

      setPreview(true);
      // setActiveNode(null);

      if (nodeCntx.id && !showPreview && plainText !== '') {
        updateNode(nodeCntx.nodeId, message, mediaDetails, undefined);
        return;
      }

      if (plainText !== previousPlainText) {
        if (plainText && plainText !== "") {
          setPreview(true);
          updateNode(nodeCntx.nodeId, message, mediaDetails, undefined);
        }
      }
    }
  });

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setMessage(getHTMLContent(editorState));
    setEditorState(editorState);
    setMediaDetails(mediaInfo);
  };

  const handleBlur = () => {
    setMessage(getHTMLContent(editorState));
  };

  const togglePreview = () => {
    setPreview(!showPreview);
    setActiveNode(nodeCntx.nodeId || nodeCntx.id);
  };

  useEffect(() => {
    if (nodeCntx.id && activeNode !== null && activeNode === nodeCntx.id) {
      setPreview(false);
      setNodeConfig(getNodeConfig(nodeCntx.type));
      return;
    }

    if (nodeCntx?.nodeId) {
      const text = getText(nodeCntx.response);
      setEditorState(getInitialEditiorText(text));
      setNodeConfig(getNodeConfig(nodeCntx.subType));
      setMessage(text);
    }
  }, [activeNode]);

  const handleCancelEditing = () => {
    if(nodeCntx?.nodeId) {
      setActiveNode(null);
    } else {
      removeNode();
    }
    togglePreview();
  };

  return (
    <Box id={props.id} {...((activeNode !== null && activeNode === nodeCntx.id || activeNode === nodeCntx.nodeId) && { ref: wrapperRef })}>
      <Card
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
      >
        <Box
          sx={{ p: "12px 16px" }}
          onClick={(e) => {
            e.stopPropagation();
            togglePreview();
          }}
        >
          {!showPreview ? (
            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onBlur={handleBlur}
              mediaInfo={mediaDetails}
            />
          ) : (
            <MessagePreview mediaDetails={mediaDetails} text={message} />
          )}
        </Box>
        <Divider />
        {node !== null && (
          <NodeType
            text={node.name}
            color={node.color}
            icon={node.icon}
          />
        )}
      </Card>
    </Box>
  );
};
export default LanguageNode;
