import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import loginPhoto from "../../assets/images/loginPhoto.svg";
import { AppForm, AppFormField, AppSubmitButton } from "../../AppComponents/Forms";
import loginIllustration from "../../assets/images/loginIllustration.svg";
import logo from "../../assets/images/logo.svg";
import {
  BrandBox,
  DetailsBox,
  ErrorText,
  LeftSide,
  LinkText,
  LoginPageWrap,
  LogoText,
  RightSide,
  SubTitleText,
  TitleText
} from "../../Customization/CommonElements";
import { ResetPasswordSchema } from "../../Models/ValidationSchema";
import { getLoginFn, getResetPassword } from "../../Store/Slices/AuthSlice";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { theme } from "../../Customization/Theme";

interface ResetPasswordPageProps {

}

const ResetPasswordPage = (props: ResetPasswordPageProps) => {
  let navigate = useNavigate();
  const [invalidMessage, setInvalidMessage] = useState<string>("");
  const [userInput, setUserInput] = useState<any>({
    email: "",
  });
  const dispatch = useDispatch();
  let { showAlert } = useNotification();

  const [showResults, setShowResults] = useState(false);



  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);

    try {

      // setInvalidMessage("Link Sent");
      SubmitProps.setSubmitting(false);
      SubmitProps.resetForm();

      getResetPassword(dispatch)({
        email: values.email
      }).then(
        (data: any) => {

          setShowResults(true);
          setInvalidMessage("");
          showAlert('Link was sent successfully!', 'success');
        },

        (err: any) => {

          SubmitProps.setSubmitting(false);
          SubmitProps.resetForm();
          setInvalidMessage("" + err.response.data.message);
        }
      );
    }
    catch (error: any) {
    }

  };




  const redirectSignup = () => {
    navigate("/signup");
  };

  const redirectLogin = () => {
    navigate("/login");
  };
  const redirectNewPassword = () => {
    navigate(`/change-password/${userInput.email}`);
  };
  const handleResendOtp = () => {
    // navigate("/login");
  };

  const [timer, setTimer] = useState(10);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => (currTimer <= 0 ? currTimer : currTimer - 1)),
    []
  );

  useEffect(() => {
    timer >= 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);


  const resetTimer = function () {
    if (!timer) {
      setTimer(10);
      // handleSubmitBtn();
    }
  };


  return (
    <LoginPageWrap>
      <LeftSide>
        <div>

          <img src={loginIllustration} alt="" />
        </div>
      </LeftSide>
      <RightSide id="reset-screen">
        <DetailsBox>
          <BrandBox>
            <img src={logo} alt="" />
            <LogoText>Geta</LogoText>
          </BrandBox>
          {
            showResults ? <TitleText>Link Sent Successfully!</TitleText>
              : (
                <TitleText>Reset Password</TitleText>

              )}
          {
            showResults ?
              <SubTitleText>
                Check your email for the reset link
              </SubTitleText>
              : (

                <SubTitleText>
                  Tell us your registered email address, we will send you reset link{" "}
                </SubTitleText>

              )}


          <ErrorText>{invalidMessage}</ErrorText>

          {
            showResults ? null : (<AppForm
              initialValues={userInput}
              onSubmit={(values: any, submitProps: any) => {
                handleSubmitBtn(values, submitProps);
                setUserInput(values);
              }}
              validationSchema={ResetPasswordSchema}
            >
              <AppFormField
                // disabled={showResults ? true : false}
                label="Email"
                name="email"
                placeholder="example@domain.com"
              />



              <AppSubmitButton
                variant="cta"
                style={{ width: "100%", margin: "0.5rem 0" }}
                title={"Reset Password"}
              />
            </AppForm>)
          }


          {/* <LinkText
              sx={{
                marginTop: "1rem",
              }}
              variant="subtitle2"
              color={theme.palette.primary.main}
            >
              Didn't Received Link ? {" "}
            
              <button
                disabled={timer <= 0 ? false : true}
                onClick={() => {resetTimer();setShowResults(false)}}
              >
                Resend Link  
              </button>
              ({timer})
            </LinkText> */}
          <LinkText
            sx={{
              marginTop: "1rem",
            }}
            variant="subtitle2"
            color={theme.palette.primary.main}
          >
            Back to  <span onClick={() => redirectLogin()}> Login</span>
          </LinkText>
        </DetailsBox>
      </RightSide>
      <RightSide className="search-results"
        style={{
          display: 'none'
        }}>
        <DetailsBox>
          <BrandBox>
            <img src={logo} alt="" />
            <LogoText>Geta</LogoText>
          </BrandBox>
          <TitleText>Successfully Reset Password</TitleText>

          <LinkText
            sx={{
              marginTop: "1rem",
            }}
            variant="subtitle2"
            color={theme.palette.primary.main}
          >
            Back to  <span onClick={() => redirectLogin()}> Login</span>
          </LinkText>
        </DetailsBox>
      </RightSide>
    </LoginPageWrap>
  );
};

export default ResetPasswordPage;
