// vendors
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// slices
import { useNodes, getLanguage, getUnSavedNodeId } from "../Slices/main.slice";

// utils
import { getResponseWithoutText } from "../Utils";

const useFlowBuilder = () => {
  const nodes = useSelector(useNodes);
  const language = useSelector(getLanguage);
  const unSavedNodeId = useSelector(getUnSavedNodeId);
  const [isValid, updateValidity] = useState<boolean>(true);
  const [error, updateError] = useState<string>("");

  useEffect(() => {
    checkEmptyNode();
    checkButtonsValidity();
  });

  const checkEmptyNode = () => {
    return unSavedNodeId !== null;
  };

  const checkButtonsValidity = () => {
    const buttons = nodes.filter((node: any) => node.subType === "button");
    const invalidButtonNodes = buttons.filter((button: any) => {
      if (button.response && button.response.length > 0) {
        const emptyType = button.response.filter((res: any) => res.type === "");
        return emptyType.length > 0;
      } else {
        return true;
      }
    });

    return invalidButtonNodes.length > 0;
  };

  const checkValidity = () => {
    if (checkEmptyNode()) {
      return { isValid: false, error: "Please save unsaved nodes!" };
    } else if (checkButtonsValidity()) {
      return { isValid: false, error: "Please check button nodes!" };
    } else {
      return { isValid: true, error: "" };
    }
  };

  return {
    checkValidity,
  };
};
export default useFlowBuilder;
