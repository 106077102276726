import React, { memo } from "react";

import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import {
  RenderCTACallButton,
  RenderCTAEmailButton,
  RenderCTALinkButton,
  RenderDocument,
  RenderFlowButton,
  RenderImage,
  RenderLiveChatButton,
  RenderOptOutButton,
  RenderText,
  RenderVideo,
} from "./AppRenderComponents";
import { MESSAGE_TYPES } from "../../utils/Enum";
import { getHtmlTextChannelWise } from "../Forms/RichEditor/WebLiveChat/utils";

interface Props {
  userMessage: boolean;
  time: string;
  children?: any;
  msgId: string;
  seen: string;
  sent: string;
  delivered: string;
  item: any;
  [otherProps: string]: any;
}

const AppRenderWhatsappInteractive: React.FC<Props> = ({
  time,
  userMessage = false,
  msgId,
  seen,
  sent,
  delivered,
  item,
}) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

  const header = item?.header;
  const footer = item?.footer;
  const body = item?.body;
  const actions = item?.action;

  if (!item) {
    return null;
  }
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      time={time}
      isMedia={true}
      msgId={msgId}
      seen={seen}
      sent={sent}
      delivered={delivered}
      divStyle={{
        gap: "0.8rem",
        padding: "0.5rem",
        backgroundColor: "#fff",
      }}
    >
      {/* HEADER TEXT*/}
      {header?.type === "text" && header?.text?.length > 0 ? (
        <RenderText text={header?.text} />
      ) : null}

      {/* HEADER IMAGE*/}
      {header?.type === "image" && header?.link?.length > 0 ? (
        <RenderImage src={header?.link} setIsImageLoaded={setIsImageLoaded} />
      ) : null}

      {/* HEADER VIDEO*/}
      {header?.type === "video" && header?.link?.length > 0 ? (
        <RenderVideo src={header?.link} setIsImageLoaded={setIsImageLoaded} />
      ) : null}

      {/* HEADER DOCUMENT*/}
      {header?.type === "document" && header?.link?.length > 0 ? (
        <RenderDocument
          src={header?.link}
          setIsImageLoaded={setIsImageLoaded}
          name={"3r8CtH6nqGrKAkAP_dummy.pdf" ?? header?.filename}
        />
      ) : null}

      {/* BODY */}
      {body?.length > 0 ? (
        <RenderText text={getHtmlTextChannelWise(body, "whatsapp")} />
      ) : null}

      {/* FOOTER */}
      {footer?.length > 0 ? <RenderText text={footer} /> : null}

      {/* ACTIONS */}

      {/* ACTION BUTTONS */}
      {actions ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {actions?.buttons?.length > 0 &&
            actions?.buttons?.map((button: any, index: number) => {
              if (button?.type === MESSAGE_TYPES.FLOW) {
                return (
                  <RenderFlowButton
                    key={index}
                    item={button}
                    disableRipple
                    disableFocusRipple
                    disabled
                  />
                );
              }

              if (button?.type === MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
                return (
                  <RenderLiveChatButton
                    key={index}
                    item={button}
                    disableRipple
                    disableFocusRipple
                    disabled
                  />
                );
              }

              if (button?.type === MESSAGE_TYPES.LINK) {
                return (
                  <RenderCTALinkButton
                    key={index}
                    item={button}
                    disableRipple
                    disableFocusRipple
                    disabled
                  />
                );
              }

              if (button?.type === MESSAGE_TYPES.EMAIL) {
                return (
                  <RenderCTAEmailButton
                    key={index}
                    item={button}
                    disableRipple
                    disableFocusRipple
                    disabled
                  />
                );
              }

              if (button?.type === MESSAGE_TYPES.MOBILE) {
                return (
                  <RenderCTACallButton
                    key={index}
                    item={button}
                    disableRipple
                    disableFocusRipple
                    disabled
                  />
                );
              }

              if (button?.type === MESSAGE_TYPES.OPT_OUT_BUTTON) {
                return (
                  <RenderOptOutButton
                    key={index}
                    item={button}
                    disableRipple
                    disableFocusRipple
                    disabled
                  />
                );
              }

              return null;
            })}
        </div>
      ) : null}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderWhatsappInteractive);
