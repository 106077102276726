import { useEffect, useState } from "react";
import { AppForm, AppFormFastField, AppFormMobileField, AppFormTextArea, AppSubmitButton } from "../../../../AppComponents/Forms"
import { Box } from "@mui/material";
import AppButton from "../../../../AppComponents/AppButton";
import { FooterSetupWrapper } from "../Styles";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { EmailSettingsActions } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.actions";
import { DefaultIndiaMobileFormat } from "../../../../Models/defaultSelectOptions";
import { addressLists, companyProfileData } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.selectors";
import FooterLists from "./Listing";
import { CompanyProfileSchema } from "./validation";

const FooterSetup = (props: any) => {
    const dispatch = useAppDispatch();
    const profileData = useAppSelector(companyProfileData);
    // Addresses List
    const addresses = useAppSelector(addressLists);

    // Add address fields to profile
    const [userInput, setUserInput] = useState<any>({
        firstName: "",
        lastName: "",
        companyName: "",
        address1: "",
        city: "",
        zip: "",
        state: "",
        countryID: "+91",
        phone: "",
        mobileCountryFormat: DefaultIndiaMobileFormat,
        website: "",
    });

    // Refresh list after adding new address
    useEffect(() => {
        if (profileData) {
            props.setAddAddress(false);
            dispatch(EmailSettingsActions.getAddressesList({}));
        }
    }, [profileData])
    // HandleSubmit
    const handleSubmit = (values: any, submittingProps: any) => {
        let payload = { ...values }
        payload.countryID = values.mobileCountryFormat.countryCode;
        delete payload.mobileCountryFormat;
        dispatch(EmailSettingsActions.updateProfile(payload));
        submittingProps.setSubmitting(false);
    }
    return (
        <FooterSetupWrapper>
            {(!addresses || addresses.length === 0 || props.addAddress) ? <AppForm
                initialValues={userInput}
                onSubmit={handleSubmit}
                validationSchema={CompanyProfileSchema}
            >
                <Box>
                    <label>Name <sup style={{ color: 'red' }}>*</sup></label>
                    <Box className="d-flex">
                        <AppFormFastField
                            name="firstName"
                            placeholder="Enter your first name"
                        ></AppFormFastField>
                        <AppFormFastField
                            name="lastName"
                            placeholder="Enter your last name"
                        ></AppFormFastField>
                    </Box>
                </Box>
                <h4>Add Footer</h4>
                <Box>
                    <label>Company Name <sup style={{ color: 'red' }}>*</sup></label>
                    <AppFormFastField
                        name="companyName"
                        placeholder="Enter your company name"
                    ></AppFormFastField>
                </Box>
                <Box>
                    <label>Company Address <sup style={{ color: 'red' }}>*</sup></label>
                    <AppFormTextArea
                        name="address1"
                        placeholder="Enter your company address"
                    ></AppFormTextArea>
                </Box>
                <Box className="d-flex">
                    <Box>
                        <label>City <sup style={{ color: 'red' }}>*</sup></label>
                        <AppFormFastField
                            name="city"
                            placeholder="Enter your city"
                        ></AppFormFastField>

                    </Box>
                    <Box>
                        <label>Zip code <sup style={{ color: 'red' }}>*</sup></label>
                        <AppFormFastField
                            name="zip"
                            placeholder="Enter your zip code"
                        ></AppFormFastField>

                    </Box>
                </Box>
                <Box className="d-flex">
                    <Box>
                        <label>State <sup style={{ color: 'red' }}>*</sup></label>
                        <AppFormFastField
                            name="state"
                            placeholder="Enter your state"
                        ></AppFormFastField>
                    </Box>
                    <Box>
                        <label>Country Code</label>
                        <AppFormFastField
                            name="countryID"
                            placeholder="Enter your country code"
                        ></AppFormFastField>

                    </Box>
                </Box>
                <Box className="d-flex">
                    <Box>
                        <label>Phone Number <sup style={{ color: 'red' }}>*</sup></label>
                        <AppFormMobileField
                            name="phone"
                            placeholder="+919991234567"
                            mobileCountryFormat="mobileCountryFormat"
                        />
                    </Box>
                    <Box>
                        <label>Company Website</label>
                        <AppFormFastField
                            name="website"
                            placeholder="Enter your company website url"
                        ></AppFormFastField>

                    </Box>
                </Box>
                <Box className="formFooter">
                    <AppButton variant="grey" onClick={() => props.setAddAddress(false)}>
                        Cancel
                    </AppButton>
                    <AppSubmitButton title="Save" />
                </Box>

            </AppForm> : <FooterLists></FooterLists>}
        </FooterSetupWrapper>
    )
}
export default FooterSetup;