import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { theme } from "../../../../../../Customization/Theme";
import { AppToolTip } from "../../../../../UiComponents/AppToolTip/AppToolTip";

interface AppBadgeProps {
    status: string;
}

const StatusBadge = ({ status }: AppBadgeProps) => {
    const [properties, setProperties] = useState<any>({});
    interface StatusProperties {
        label: string;
        color: string;
        backgroundColor: string;
        borderColor: string;
        toolTip?: string;
        padding?: number | string;
    }
    // Define the interface for the entire statuses object
    interface StatusesObject {
        PENDING: StatusProperties;
        APPROVED: StatusProperties;
        IN_APPEAL: StatusProperties;
        REJECTED: StatusProperties;
        PENDING_DELETION: StatusProperties;
        DELETED: StatusProperties;
        DISABLED: StatusProperties;
        PAUSED: StatusProperties;
        LIMIT_EXCEEDED: StatusProperties;
        ARCHIVED: StatusProperties;
    }


    function getStatus(status: string) {
        const statusesObject: StatusesObject = {
            PENDING: {
                label: "PENDING",
                color: `${theme.palette.default.coffee}`,
                backgroundColor: `${theme.palette.default.earlyDawn}`,
                borderColor: `1px solid ${theme.palette.default.corn}`,
                toolTip: "Bot knowledgebase is getting updated.",
            },
            APPROVED: {
                label: "APPROVED",
                color: `${theme.palette.default.successNew}`,
                padding: 0,
                backgroundColor: `${theme.palette.default.successNewBackground}`,
                borderColor: `1px solid ${theme.palette.default.successNewBorder}`,
                toolTip: "The template has been approved and you can now use it in your communications.",
            },
            IN_APPEAL: {
                label: "IN_APPEAL",
                color: `${theme.palette.default.coffee}`,
                backgroundColor: `${theme.palette.default.earlyDawn}`,
                borderColor: `1px solid ${theme.palette.default.corn}`,
                toolTip: "Bot is getting trained with the new knowledgebase.",
            },
            REJECTED: {
                label: "REJECTED",
                color: `${theme.palette.default.error}`,
                backgroundColor: `${theme.palette.default.errorLighter}`,
                borderColor: `1px solid ${theme.palette.default.error}`,
                toolTip: "The template has been rejected during our review process or violates one or more of our policies.",
            },
            PENDING_DELETION: {
                label: "PENDING_DELETION",
                color: `${theme.palette.primary.main}`,
                backgroundColor: `${theme.palette.default.pattensBlue}`,
                borderColor: `1px solid ${theme.palette.primary.main}`,
                toolTip:
                    "Bot is trained on the new supplied knowledgebase and now can answer user queries.",
            },
            DELETED: {
                label: "DELETED",
                color: `${theme.palette.default.error}`,
                backgroundColor: `${theme.palette.default.errorLighter}`,
                borderColor: `1px solid ${theme.palette.default.error}`,
                toolTip: "DELETED due to some problem.",
            },
            DISABLED: {
                label: "DISABLED",
                color: theme.palette.default.successNewBorder,
                borderColor: `1px solid ${theme.palette.default.successNewBorder}`,
                backgroundColor: theme.palette.default.successNewBackground,
                toolTip: "The template has been disabled due to recurring negative feedback from customers. Message templates with this status cannot be sent to customers."
            },
            PAUSED: {
                label: "PAUSED",
                color: theme.palette.default.Coffee,
                borderColor: `1px solid ${theme.palette.default.paleGold}`,
                backgroundColor: theme.palette.default.EarlyDawn,
                toolTip: "The template has been paused due to recurring negative feedback from customers, or low read-rates. Message templates with this status cannot be sent to customers."
            },
            LIMIT_EXCEEDED: {
                label: "LIMIT_EXCEEDED",
                color: theme.palette.default.Coffee,
                borderColor: `1px solid ${theme.palette.default.paleGold}`,
                backgroundColor: theme.palette.default.EarlyDawn,
            },
            ARCHIVED: {
                label: "ARCHIVED",
                color: theme.palette.default.Coffee,
                borderColor: `1px solid ${theme.palette.default.paleGold}`,
                backgroundColor: theme.palette.default.EarlyDawn,
            }
        };
        return statusesObject[status as keyof StatusesObject];
    }
    // useEffect to set the properties of current status to the badge
    useEffect(() => {
        setProperties(getStatus(status));
    }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AppToolTip
            tooltipProps={{
                title: properties?.toolTip,
                placement: "top",
            }}
            className="chip"
            iconButtonProps={{ disableRipple: true }}
            IconComponent={
                <Chip
                    label={properties?.label}
                    style={{
                        color: properties?.color,
                        backgroundColor: properties?.backgroundColor,
                        border: properties?.borderColor,
                        padding: "8px",
                        borderRadius: "0px",
                    }}
                />
            }
        />
    )
};

export default StatusBadge;
