import styled from 'styled-components';
import { theme } from '../../Customization/Theme';

export * from './LoaderComponent';
export * from './InfiniteLoaderComponent';

export const Table = styled.div`
  .table-body {
    //height: calc(100vh - 452px);
    overflow: auto;
  }
  .table-header {
    display: flex;
    align-items: baseline;
    font-weight: bold;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
    padding-bottom: 4px;
    padding-top: 4px;
    flex: 1;
    overflow:auto;
  }

  .table-row {
    display: flex;
    border-bottom: 1px solid ${theme.palette.default.BorderColorGrey};
  }
  .query1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
  }

  .query2 {
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .query3 {
    color: ${theme.palette.default.AccentOrange};
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    padding: 3px;
    border: 1px solid ${theme.palette.default.AccentOrange};
    width: 200px;
    text-align: center;
    border-radius: 4px;
  }

  .col-head,
  .col-body {
    padding: 16px 16px;
    color: ${theme.palette.default.newTextGrey};
    font-size: 14px;
    font-weight: 400;
    :nth-child(1) {
      /* Apply styles for the head first child */
      flex-basis: 426px;
    }

    :nth-child(2) {
      flex-basis: 315px;
    }
    :nth-child(n + 3) {
      flex: 1; /* columns from the third child to grow equally */
    }
    :nth-child(7) {
      display: flex;
      align-items: baseline;
      justify-content: end;
    }
  }

  .col-head {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${theme.palette.default.black};
    padding: 1rem;
    border-right: 1px solid ${theme.palette.default.RightBorderDivder};

    :nth-child(1) {
      /* Apply styles for the body first child */
      flex-basis: 426px;
    }

    :nth-child(2) {
      flex-basis: 315px;
    }
    :nth-child(6) {
      border-right: none;
    }
    :nth-child(7) {
      border-right: none;
    }
    :nth-child(n + 3) {
      flex: 1; /* Allow columns from the third child to grow equally */
    }
  }
`;