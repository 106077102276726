import ReactPlayer from "react-player";
import Slider from "react-slick";
import styled, { keyframes } from "styled-components";
import { theme } from "../../Customization/Theme";
import { ColorUtils } from "../../Utils/ColorUtils";
import { DEVICE_BREAKPOINT } from "../utils/BreakPoints";

interface StyledProps {
  userMessage?: any;
  tooltipPosition?: any;
  isTooltipVisible?: any;
  WIDGET_POSITION?: any;
  variant?: any;
  enableuserinput?: number;
  chatopened?: any;
  devicewidth?: any;
  deviceheight?: any;
  jumpPosition?: any;
  theme?: any;
  selected?: any;
  isInvalid?: any;
  isButton?: any;
  disabled?: boolean;
  ref?: any;
  showWrapperBox?: boolean;
}

export const StyledSlickSlider: any = styled(Slider)`
  && {
    max-width: 320px;

    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      /* height: auto !important; */
    }

    .nextPreBtn {
      position: absolute;
      z-index: 99;
      top: 5rem;
      opacity: 0.8;
      background-color: ${theme.palette.default.darkGrey};
      height: 24px;
      width: 24px;
      display: flex !important; // to override slick slider default inline display block
      justify-content: center;
      align-items: center;
      svg {
        color: ${theme.palette.default.text};
      }

      &:before {
        display: none;
      }
    }

    .slick-next {
      right: 1rem;
    }
    .slick-prev {
      left: 1rem;
    }

    .slick-dots {
      background-color: ${theme.palette.default.white};
      display: flex !important;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      margin-top: -6px;
      button {
        display: none;
      }
      li {
        color: ${theme.palette.default.darkGrey};
      }
      .slick-active {
        color: ${theme.palette.default.text};
      }
    }
  }
`;
export const StyledBannerCarouselSlickSlider: any = styled(Slider)`
  && {
    .slick-list {
      height: 150px !important;
    }
    .slick-next {
      position: absolute;
      z-index: 99;
      top: 35%;
      right: 5%;
      opacity: 0.8;
      /* background-color: red; */
      height: 24px;
      width: 24px;
      display: none;
      color: #fff !important;
      border-radius: 50%;
      svg {
        color: #fff !important;
      }
    }
    .slick-prev {
      position: absolute;
      display: none;

      color: #fff !important;
      border-radius: 50%;
      z-index: 99;
      top: 35%;
      left: 5%;
      opacity: 0.8;
      height: 24px;
      width: 24px;
      svg {
        color: #fff !important;
      }
    }

    .slick-prev:before{
      opacity: 1 !important;
      color: #fff !important;

    }
    .slick-next:before{
      opacity: 1 !important;
      color: #fff !important;

    }

    .slick-dots {
      background-color: ${theme.palette.default.white};
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 0.8rem;
      margin-top: -6px;
      display: none !important;
      button {
        display: none;
      }
      li {
        color: ${theme.palette.default.darkGrey};
      }
      .slick-active {
        color: ${theme.palette.default.text};
      }
    }

    .sliderSingleItem {
      display: flex !important;
      flex-direction: column;
      gap: 4px;
      /* justify-content: center; */
      .btnWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
      }

      .title {
        padding: 2px 6px 0px;
        padding-left: 18px;
        font-size: 0.9rem;
        font-weight: 500;
        text-overflow: ellipsis;
        font-family: ${({ theme }: any) =>
          theme?.components?.user_input.normal.text.family};

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      .description {
        padding: 0px 6px;
        padding-left: 14px;
        font-size: 0.9rem;
        text-overflow: ellipsis;
        font-family: ${({ theme }: any) =>
          theme?.components?.user_input.normal.text.family};

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
      }

      img {
        width: 100%;
        height: 150px;
        border-radius: 6px;
        min-height: 130px;
        max-height: 130px;
        overflow: hidden;
        border: none;
        object-fit: cover;
      }
    }
  }
`;

export const WidgetSliderBox = styled.div`
  && {
    background-color: transparent;
    background-color: ${theme.palette.secondary.main};
    border-radius: 6px 6px 6px 6px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 2px 4px 2px;
    margin: 4px 0;
    min-height: 100%;
    .btnWrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .title {
      padding: 6px 8px 2px;
      font-size: 0.95rem;
      letter-spacing: 0.5px;
      line-height: 24px;
      font-weight: 600;
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .subTitle {
      padding: 0px 8px 2px;
      font-size: 0.9rem;
      line-height: 20px;
      font-weight: 500;
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .description {
      padding: 0px 8px 2px;
      font-size: 0.9rem;
      line-height: 20px;
      font-weight: 300;
      text-overflow: ellipsis;
      font-family: ${theme.typography.fontFamily};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
    }
    .sliderImage {
      width: 100%;
      min-width: 100%;
      height: 150px;
      border-radius: 4px;
      min-height: 150px;
      max-height: 150px;
      overflow: hidden;
      border: none;
      object-fit: contain;
    }
  }
`;

export const StyledLanguageWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 3px;

    .LanguageIconBox {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 4px;
      border-radius: 40px;
      padding: 0 6px 0 2px;
      overflow: hidden;

      width: 40px;
      overflow: hidden;
      transition: width 0.5s;
      -webkit-transition: width 0.5s;
      -moz-transition: width 0.5s;

      .currentLanguage {
        display: none;
      }

      &:hover,
      &:focus {
        background-color: rgba(156, 39, 176, 0.08);
        width: 96px;
        .currentLanguage {
          display: -webkit-box;
        }
      }
    }
    .active {
      background-color: rgba(156, 39, 176, 0.08);
      width: 96px;
      .currentLanguage {
        display: -webkit-box;
      }
    }

    .languageIcon {
      svg {
        font-size: 19px;
        color: ${({ theme }: any) => {
          return theme?.components?.header.text.color;
        }};
      }
    }
    .currentLanguage {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      cursor: pointer;
      font-family: ${({ theme }: any) =>
        theme?.components?.bot_message?.text?.family};
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.5px;
      color: ${({ theme }: any) => {
        return theme?.components?.header.text.color;
      }};
    }

    .languageListWrapper {
      list-style-type: none;
      .title {
        font-family: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.color};
        font-weight: 400;

        font-size: 15px;
      }
      li {
        font-family: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.color};
        font-weight: 400;
        min-height: 36px;
        font-size: 15px;
        padding: 4px 12px;
        margin-right: 1px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        &:hover {
          background-color: ${({ theme }: any) => {
            return theme?.components?.chat_wall.background.color;
          }};
          transform: scale(1.01);
        }
      }
    }
  }
`;

export const StyledQuickMenuWrapper = styled.div`
  && {
    /* margin-bottom: 80px; */
    .MuiAccordion-root {
      box-shadow: none;
      .MuiAccordionSummary-root {
        min-height: 44px;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      }
      .MuiAccordionSummary-content {
        align-items: center;
        gap: 8px;
      }
      .Mui-expanded {
        margin: 0;
      }
      .MuiCollapse-root {
        overflow: hidden;
        overflow-y: overlay;
        max-height: 190px;
      }

      .MuiAccordionDetails-root {
        background-color: #fdfcfc;
        /* background-color: #24d31e; */
        width: 100%;
        padding: 0.15rem 0;
        /* padding: 0; */
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        overflow: overlay;
        overflow-x: hidden;
        height: auto;
        /* gap: 6px;  */
      }
    }
    .bodyContent {
    }

    .accordianTitle {
      font-family: ${({ theme }: any) =>
        theme?.components?.bot_message?.text?.family};
      font-weight: 500;
      font-size: 0.85rem;
      /* line-height: 1.2rem; */
      text-transform: capitalize;

      color: #606060;
    }

    .singleCard {
      width: 33.2%;

      height: 90px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;

      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;
      text-align: center;
      padding: 6px 2px 2px;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
      .cardImage {
        width: 52px;
        height: 44px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        font-family: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 12.5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .singleRow {
      width: 98%;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 6px;
      gap: 1rem;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.06);
      }
      .rowImage {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        width: 100%;
        font-family: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) =>
          theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
`;

export const StyledNotificationBlockWrapper = styled.div`
  && {
    .notificationIcon {
      svg {
        font-size: 19px;
        color: ${({ theme }: any) => {
          return theme?.components?.header.text.color;
        }};
      }
    }

    .notificationListWrapper {
      height: 100%;
      padding: 0.5rem 1rem 6px;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 3px;
      border-radius: 4px;
      overflow: hidden;
      flex: 1;

      .singleRow {
        background-color: rgba(0, 0, 0, 0.03);
        width: 100%;
        padding: 4px;
        height: 96px;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        color: black;
        cursor: pointer;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
        border: 1px solid transparent;

        &:hover {
          transform: scale(1.01);
          border: 1px solid rgba(0, 0, 0, 0.06);
        }
        .rowLeftBox {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          height: 100%;
          min-width: 80px;
          padding: 2px;
          img {
            height: 72px;
            width: 72px;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        .rowRightBox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 4px 6px 2px;
          overflow: hidden;

          .rowRightTop {
            display: flex;
            width: 100%;
            overflow: hidden;
            min-height: 20px;
            font-family: ${({ theme }: any) =>
              theme?.components?.bot_message?.text?.family};
            font-weight: 500;
            font-size: 0.85rem;
            line-height: 1.2rem;
            text-transform: capitalize;
            color: ${({ theme }: any) =>
              theme?.components?.bot_message?.text?.color};

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .rowRightMiddle {
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            max-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: ${({ theme }: any) =>
              theme?.components?.bot_message?.text?.family};
            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
          }
          .rowRightBottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            overflow: hidden;

            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
            .footerText {
              font-family: ${({ theme }: any) =>
                theme?.components?.bot_message?.text?.family};
              font-size: 0.75rem;
              line-height: 1.2rem;
              color: ${theme.palette.default.darkGrey};
            }

            button {
              color: dodgerblue;
            }
          }
        }
      }
    }
    .staticButton {
      button {
        color: #7e8392;
        min-height: 36px;
        max-height: 36px;
      }
      position: -webkit-sticky;
      position: sticky;
      z-index: 999;
      bottom: 0rem;
      border-radius: 10px;
      min-height: 36px;
      max-height: 36px;
      width: 100%;
      margin-top: auto;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
`;

const loadingFade = keyframes`
0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
`;
export const StyledTyping = styled.div<StyledProps>`
  background-color: ${({ userMessage }: any) =>
    userMessage ? theme?.palette.primary.main : theme?.palette.default.white};
  padding: 4px;
  border-radius: 5px;
  display: flex;
  && {
  }

  .typing__dot {
    float: left;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: ${({ userMessage }: any) =>
      userMessage ? theme?.palette.default.white : theme?.palette.default.text};
    border-radius: 50%;
    opacity: 0;
    animation: ${loadingFade} 0.8s infinite;
  }

  .typing__dot:nth-of-type(1) {
    animation-delay: 0s;
  }

  .typing__dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .typing__dot:nth-of-type(3) {
    animation-delay: 0.4s;
  }
`;
export const StyledUserChatAvatar = styled.img`
  && {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid
      ${({ theme }: any) => {
        return theme?.components?.header.background.color;
      }};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    margin: 1px 0;
  }
`;

export const StyledReactVideoPlayer = styled(ReactPlayer)`
  && {
    overflow: hidden !important;
    border-radius: 6px;
    min-width: 100%;
    object-fit: contain;
    min-height: 10rem;
    min-height: 170px;
    max-height: 12rem;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    .react-player__preview {
      svg {
        max-width: 54px;
        fill: #ffffff;
      }
    }
    .react-player__preview {
      width: 100%;
    }
  }
`;
export const StyledScrollDownBtn = styled.div`
  && {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 10%;
    width: 100%;
    z-index: 999;
  }
`;

export const StyledBannerCarouselWrapper = styled.div`
  && {
    padding: 0 6px 0.2rem;

    .indicatorIconButtonProps {
      color: rgba(0, 0, 0, 0.1);
      svg {
        font-size: 10px;
      }
    }
    .activeIndicatorIconButtonProps {
      color: rgba(0, 0, 0, 0.3);
      svg {
        font-size: 10px;
      }
    }
    .indicatorContainerProps {
      width: 100%;
      margin-top: -2px;
      text-align: center;
    }
    .indicatorContainerProps {
      width: 100%;
      margin-top: -2px;
      text-align: center;
    }
    .navButtonsProps {
      width: 32px;
      height: 32px;
      background-color: rgba(0, 0, 0, 0.04);
      color: #7e8392;
      border: 1px solid transparent;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    }
    .navButtonsProps {
      bottom: 0;
      top: calc(45% - 16px);
      height: 32px;
      background-color: transparent;
      z-index: 1;
    }

    .singleItem {
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 12px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding: 4px 4px 4px;
      cursor: pointer;
    }

    p {
      padding: 10px;
      padding-left: 14px;
      font-size: 0.9rem;
      text-overflow: ellipsis;
      font-family: ${({ theme }: any) =>
        theme?.components?.user_input.normal.text.family};
    }
    img {
      width: 100%;
      height: 170px;
      border-radius: 4px;
      min-height: 170px;
      max-height: 170px;
      border-radius: 2px;
      overflow: hidden;
      border: none;
      object-fit: contain;
    }
    video {
      width: 100%;
      height: 170px;
      overflow: hidden;
    }
  }
`;

export const StyledMessageRow = styled.div<StyledProps>`
  && {
    width: 100%;
    display: flex;
    flex-direction: ${(props: any) =>
      props.userMessage ? "row-reverse" : "row"};
    align-items: flex-start;
    overflow: hidden;
    justify-content: ${(props: any) =>
      props.userMessage ? "flex-start" : "flex-start"};
    gap: 6px;
    margin: 8px 0;
    ul,
    li {
      padding: 0 0 0 1rem;
      list-style-position: outside !important;
    }
  }
`;
export const StyledContentWrapper = styled.div<StyledProps>`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: ${(props: any) =>
      props.userMessage ? "flex-end" : "flex-start"};
    justify-content: flex-start;

    .replyMediaWrapper {
      width: 100%;
      display: ${(props: any) => (props.isButton ? "flex" : "flex")};
      flex-wrap: nowrap;
      flex-direction: ${(props: any) =>
        props.userMessage ? "row-reverse" : "row"};
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

export const StyledMessageContainer = styled.div<StyledProps>`
  && {
    max-width: 50%;
    word-wrap: break-word;
    word-break: break-all;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: ${(props: any) =>
      props.userMessage ? "row-reverse" : "row"};
    gap: 0.5rem;
    align-items: center;
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    animation-delay: 2s;
    position: relative;
    border-radius: ${({ userMessage }: any) =>
      userMessage ? "10px 4px 2px 10px" : "4px 10px 10px 0px"};
    margin: 1px 0;
    ul,
    ol {
      li {
        margin-left: 1rem;
      }
    }

    &:after {
      ${({ userMessage, theme }: any) =>
        userMessage
          ? `
        right: -3px;
        bottom: 0px;
        transform: rotate(180deg);
        `
          : `
        left: -4px;
        bottom: 0px;
        transform: rotate(180deg);
        `}
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: ${({ userMessage, theme }: any) =>
          userMessage
            ? theme?.components?.user_message?.background?.color
            : theme?.components?.bot_message?.background?.color}
        transparent transparent transparent;
    }
  }
`;

export const StyledMessageText = styled.div<StyledProps>`
  && {
    padding: ${({ userMessage }: any) => (userMessage ? "12px" : "10px 15px")};
    border-radius: ${({ userMessage }: any) =>
      userMessage ? "20px 4px 20px 20px" : "4px 20px 20px"};
    overflow: hidden;

    background-color: ${({ userMessage }: any) =>
      userMessage ? "#fff" : " #BBDCFE"};
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #091e42;
    font-family: ${({ userMessage, theme }: any) =>
      userMessage
        ? theme?.components?.user_message?.text?.family
        : theme?.components?.bot_message?.text?.family};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    max-width: 100%;
    a {
      color: ${({ userMessage }: any) =>
        userMessage
          ? theme?.palette.default.blue
          : theme?.palette.default.blue};
      cursor: pointer;
      text-decoration: underline;
    }

    @media ${DEVICE_BREAKPOINT.mobileL} {
      font-size: 14px;
    }
  }
`;

export const StyledMessageReplyRenderBox = styled.div<StyledProps>`
  && {
    padding: 2px 2px;
    border-radius: ${({ userMessage }: any) =>
      userMessage ? "8px 4px 0px 8px" : "4px 8px 8px 0px"};
    overflow: hidden;

    background-color: ${theme?.palette?.default?.lighterGreyNew};
    display: "block";
  }
`;

export const StyledRepliedMessageWrapper = styled.div<StyledProps>`
  && {
    padding: 6px 8px;
    border-radius: ${({ userMessage }: any) =>
      userMessage ? "18px 4px 2px 8px" : "4px 8px 8px 0px"};
    overflow: hidden;

    background-color: ${({ userMessage }: any) =>
      userMessage
        ? theme?.palette.primary.main
        : theme?.palette?.default?.lighterGreyNew};
    word-break: break-all;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ userMessage }: any) =>
      userMessage ? theme?.palette.default.white : theme?.palette.default.text};
    font-family: ${({ userMessage, theme }: any) =>
      userMessage
        ? theme?.components?.user_message?.text?.family
        : theme?.components?.bot_message?.text?.family};
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    max-width: 100%;
    a {
      color: ${({ userMessage }: any) =>
        userMessage
          ? theme?.palette.default.white
          : theme?.palette.default.text};
      cursor: pointer;
    }

    @media ${DEVICE_BREAKPOINT.mobileL} {
      font-size: 14px;
    }
  }
`;

export const StyledRepliedBox = styled.div<StyledProps>`
  && {
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: 8px;
    border: 1px solid transparent;
    border-left: 4px solid ${theme.palette.primary.light};
    padding: 0.3rem 0.5rem 0.3rem 0.5em;
    margin: 0 0 4px 0;
    .title {
      font-size: 0.75rem;
      line-height: 18px;
      font-weight: 600;
      color: ${(props: StyledProps) =>
        props?.userMessage
          ? theme.palette.primary.main
          : theme.palette.default.text};
    }

    .replyItemWrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      width: 100%;
      padding: 5px 8px;
      border-radius: 8px;
      background-color: ${theme.palette.default.greyishWhite};

      .replyItem {
        color: ${theme.palette.default.text};
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 13.5px;
        line-height: 18px;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        p {
          font-size: 13.5px;
          color: ${theme.palette.default.text};
        }
      }
      svg {
        font-size: 1.5rem;
        color: ${theme.palette.default.darkGrey};
      }
    }

    .closeAction {
      position: absolute;
      z-index: 1;
      right: -0.6rem;
      top: -0.6rem;
      .MuiIconButton-root {
        background-color: ${theme.palette.default.white};
        width: 30px;
        height: 30px;
      }
      svg {
        font-size: 1.3rem;
      }
    }

    .renderImage {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      object-fit: cover;
      border: 0.5px solid ${theme.palette.default.darkGrey};
    }
  }
`;

export const StyledMediaContainer = styled.div<StyledProps>`
  && {
    position: relative;
    padding: 4px;
    margin: 0px 0;
    overflow: hidden;
    max-width: 60%;
    min-width: ${({ isButton }: any) => (isButton ? "45%" : "32%")};
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 2px;
    background-color: ${({ userMessage, isButton }: any) =>
      isButton
        ? "transparent"
        : userMessage
        ? theme?.palette.default.lighterGreyNew
        : theme?.palette.default.lighterGreyNew};

    border-radius: ${({ userMessage }: any) =>
      userMessage ? "10px 4px 2px 10px" : "4px 10px 10px 0px"};

    .loaderGif {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &:after {
      display: ${({ isButton }: any) => (isButton ? "none" : "block")};
      ${({ userMessage, theme }: any) =>
        userMessage
          ? `
        right: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `
          : `
        left: -4px;
        bottom: 0px;
        transform: rotate(180deg);
        `}
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: ${({ userMessage, theme }: any) =>
          userMessage
            ? theme?.components?.user_message?.background?.color
            : theme?.components?.bot_message?.background?.color}
        transparent transparent transparent;
    }

    img {
      width: 100%;
      min-width: 290px;
      max-height: 11rem;
      min-height: 11rem;
      object-fit: contain;
      background-color: ${theme.palette.default.white};

      border: 1px solid ${theme.palette.default.border};
      border-radius: ${({ userMessage }: any) =>
        userMessage ? "6px 6px 6px 6px" : "6px 6px 6px 0px"};
      cursor: pointer;
    }
  }
`;

export const StyledMessageTimeStamps = styled.div<StyledProps>`
  && {
    word-wrap: break-word;
    display: ${(props: any) => (props.isButton ? "none" : "flex")};
    align-items: center;
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    flex-direction: row;
    font-style: italic;
    gap: 5px;
    font-family: ${({ userMessage, theme }: any) =>
      userMessage
        ? theme?.components?.user_message?.text?.family
        : theme?.components?.bot_message?.text?.family};
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    font-size: 12px;
    color: ${theme.palette.default.darkGrey};
    margin: 0px 4px 0px 0px;

    .MuiIconButton-root {
      width: 22px;
      height: 16px;
    }
    svg {
      font-size: 1rem;
    }
  }
`;

export const MessageMediaRowAttachment = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: ${theme.palette.default.lightGrey};
  font-size: 14px;
  border-radius: 5px;
  svg {
    margin-right: 5px;
    max-width: 15px;
  }
`;

export const StyledFileContainerBox = styled.a`
  width: 100%;
  height: 56px;
  border-radius: 4px;
  padding: 12px 8px;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  gap: 0.75rem;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  font-family: ${({ theme }: any) => {
    return theme?.components?.bot_message?.text?.family;
  }};
  border: 1px solid ${theme.palette.default.lighterGrey};
  background-color: ${theme.palette.default.white};
  color: ${theme.palette.default.text};
  &:hover {
    color: ${theme.palette.primary.main};
  }
  .fileNameBox {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    cursor: pointer;
    text-decoration: underline;
  }
  .fileActionBox {
    display: flex;
    cursor: pointer;
  }
`;

export const StyledInfoMessageText = styled.p<StyledProps>`
  && {
    display: flex;
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${({ userMessage, theme }: any) =>
      userMessage
        ? theme?.components?.user_message?.text?.family
        : theme?.components?.bot_message?.text?.family};
    font-weight: 300;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.5px;
    position: relative;
    margin: 4px 8px 4px;
    max-width: calc(100% - 180px);
    border-radius: 20px;
    text-align: center;
    padding: 5px 10px;
    color: ${ColorUtils.getContrastTextColorHex(
      theme.palette.default.darkGrey
    )};
    opacity: 0.9;
    &:before {
      content: "";
      background: ${ColorUtils.getContrastTextColorHex(
        theme.palette.default.darkGrey
      )};
      opacity: 0.5;
      width: 60px;
      height: 2px;
      transform: translateX(-60px);
      position: absolute;
      left: 0;
    }
    &:after {
      content: "";
      background: ${ColorUtils.getContrastTextColorHex(
        theme.palette.default.darkGrey
      )};
      opacity: 0.5;

      width: 60px;
      height: 2px;
      right: 0;
      transform: translateX(60px);
      position: absolute;
    }
  }
`;
