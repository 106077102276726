import http from "../../../NetworkServices/http";
let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";
export class FacebookNetworkService {
  static getAllBots(data: any) {
    return http.get(
      `${consoleBaseUrl}/${Endpoints.GET_BOT_LISTING}?limit=999&offset=0`,
    );
  }
  static saveFacbookConfig(data: any) {
    return http.post(`${consoleBaseUrl}/${Endpoints.SAVE_CONFIG}`, data);
  }
  static unassignFacbookConfig(data: any) {
    return http.post(`${consoleBaseUrl}/${Endpoints.UNASSIGN_CONFIG}`, data);
  }
  static getFacebookPageConfig(data: any) {
    return http.get(
      `${consoleBaseUrl}/${Endpoints.GET_FACEBOOK_PAGE_CONFIG}/${data.page_id}`,
    );
  }
}

//*marketing list endpoints *//
class Endpoints {
  // old-url
  // new-url
  static GET_BOT_LISTING = "/chatbot";
  static SAVE_CONFIG = "workspace/assign-facebook-page-to-chatbot";
  static UNASSIGN_CONFIG = "workspace/unassign-facebook-page-from-chatbot";
  static GET_FACEBOOK_PAGE_CONFIG = "workspace/facebook-page-connected-chatbot";
}
