import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface initialStateTypes {
  whatsappTemplateData: any[];
  selectedTemplateData: any;
  resolveVariblesData: any;
  resolovedFormData: any;
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  whatsappTemplateData: [],
  selectedTemplateData: [],
  resolveVariblesData: [],
  resolovedFormData:null,
  error: "",
  loading: false,
};

const LiveChatWhatsappTemplateSelect = createSlice({
  name: "LiveChatWhatsappTemplateSelect",
  initialState,
  reducers: {
    setWhatsappTemplateData(state, { payload }: PayloadAction<any>) {
      state.whatsappTemplateData = payload;
    },
    setSelectedTemplateData(state, { payload }: PayloadAction<any>) {
      state.selectedTemplateData = payload;
    },
    setResolovedFormData(state, { payload }: PayloadAction<any>) {
      state.resolovedFormData = payload;
    },
    removeLiveChatWhatsappTemplateSelectData() {
      return initialState;
    },
  },
});

export default LiveChatWhatsappTemplateSelect.reducer;

export const {
  setWhatsappTemplateData,
  setSelectedTemplateData,
  removeLiveChatWhatsappTemplateSelectData,
  setResolovedFormData
} = LiveChatWhatsappTemplateSelect.actions;
