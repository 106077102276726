import { AxiosResponse } from "axios";
import { FAQ } from "../../Models";
import { conversationAxios } from "../../Utils/Axios";
import { getHttpService } from "../HttpService";

import { CH_BOT_ENDPOINTS } from "./Endpoints";

export class ConversationService {
  static httpService = getHttpService(conversationAxios);
  static getWelcome = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.QUERY,
      params: queryData,
      headers: config,
    });
  };

  static postUserResponse = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.QUERY,
      data: queryData,
      headers: config,
    });
  };

  static getHistory = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.HISTORY,
      params: queryData,
      headers: config,
    });
  };

  static postHistory = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.HISTORY,
      data: queryData,
      headers: config,
    });
  };

  static getCountMetrics = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.METRIC_COUNT,
      data: queryData,
      headers: config,
    });
  };

  static getUserGrowthChart = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.USER_GROWTH,
      data: queryData,
      headers: config,
    });
  };

  static getUniqueUserGrowthChart = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.UNIQUE_USER_GROWTH,
      data: queryData,
      headers: config,
    });
  };

  static getConversionRateGrowthChart = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.CONVERSION_RATE_GROWTH,
      data: queryData,
      headers: config,
    });
  };

  static getReturningLeadsGrowthChart = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.RETURNING_LEADS_GROWTH,
      data: queryData,
      headers: config,
    });
  };

  static getEngagementRateGrowthChart = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.ENGAGEMENT_RATE_GROWTH,
      data: queryData,
      headers: config,
    });
  };

  static getAverageBotDurationGrowthChart = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.AVERAGE_BOT_DURATION_GROWTH,
      data: queryData,
      headers: config,
    });
  };

  static getTimeSavedGrowthChart = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.TIME_SAVED_GROWTH,
      data: queryData,
      headers: config,
    });
  };

  static getCommonIntents = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.COMMON_INTENTS,
      data: queryData,
      headers: config,
    });
  };

  static getActiveHours = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.ACTIVE_HOURS,
      data: queryData,
      headers: config,
    });
  };

  static getLeads = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.LEADS,
      data: queryData,
      headers: config,
    });
  };

  //  contacts apies =======>>>>>>>>
  static getContacts = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.CONTACTS,
      data: queryData,
      headers: config,
    });
  };
  static contactDeletById = (queryData: any = {}, config = {}) => {
    return ConversationService.httpService.httpDelete({
      url: `${CH_BOT_ENDPOINTS.CONTACTS_DEATAILS}/${queryData?.contact_id}`,
      headers: config,
    });
  };
  static getContactsDetails = (queryData: any = {}, config = {}) => {
    return ConversationService.httpService.get({
      url: `${CH_BOT_ENDPOINTS.CONTACTS_DEATAILS}/${queryData?.contact_id}`,
      headers: config,
    });
  };
  static getAddContacts = (data = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.ADDCONTACTS,
      data: data,
      // headers: config,
    });
  };

  static getLeadsColumns = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.LEADSCOLUMNS,
      params: queryData,
      headers: config,
    });
  };
  static postDownloadLeadData = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.DOWNLOADLEADS,
      data: queryData,
      headers: config,
    });
  };

  static getFaqList = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.FAQ_LIST,
      params: queryData,
      headers: config,
    });
  };
  static getNotAnsweredFaqList = (queryData = {}, config = {}) => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.NOT_ANSWERED_FAQ_LIST,
      params: queryData,
      headers: config,
    });
  };
  static getDevices = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.SESSIONS_DEVICES,
      data: queryData,
      headers: config,
    });
  };
  static postVisitorId = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.VISITOR_ID,
      data: queryData,
      headers: config,
    });
  };
  static postFaqsQuestion = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.CREATE_FAQ_QUESTION,
      data: queryData,
      headers: config,
    });
  };
  static postNotAnsweredFaqsQuestion = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.CREATE_NOT_ANSWERED_FAQ_QUESTION,
      data: queryData,
      headers: config,
    });
  };
  static updateFaqQuestion = (qid: string, queryData = {}, config = {}) => {
    return ConversationService.httpService.put({
      url: CH_BOT_ENDPOINTS.FAQ_QUESTION_DETAIL,
      data: queryData,
      headers: config,
      pathParams: [qid],
    });
  };

  static getFaqQuestionDetails = (
    qid: string,
    config = {}
  ): Promise<AxiosResponse<{ data: FAQ; status: number }>> => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.FAQ_QUESTION_DETAIL,
      headers: config,
      pathParams: [qid],
    });
  };

  static deleteFaqQuestion = (
    qid: string,
    config = {}
  ): Promise<AxiosResponse<{ data: FAQ; status: number }>> => {
    return ConversationService.httpService.httpDelete({
      url: CH_BOT_ENDPOINTS.FAQ_QUESTION_DETAIL,
      headers: config,
      pathParams: [qid],
    });
  };
  static deleteNotAnsweredFaqQuestion = (
    qid: string,
    config = {}
  ): Promise<AxiosResponse<{ data: FAQ; status: number }>> => {
    return ConversationService.httpService.httpDelete({
      url: CH_BOT_ENDPOINTS.NOt_ANSWEREDFAQ_QUESTION_DETAIL,
      headers: config,
      pathParams: [qid],
    });
  };
  static getFlowList = (config = {}): Promise<AxiosResponse<any>> => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.FLOW_LIST,
      headers: config,
    });
  };
  static getChildFlowList = (id="",config = {}): Promise<AxiosResponse<any>> => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.CHILD_FLOW_LIST+id,
      headers: config,
    });
  };
  static postFaqVariations = (
    qid: string,
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.FAQ_VARIATIONS,
      data: queryData,
      headers: config,
      pathParams: [qid],
    });
  };

  static updateFaqVariations = (qid: string, queryData = {}, config = {}) => {
    return ConversationService.httpService.put({
      url: CH_BOT_ENDPOINTS.FAQ_VARIATIONS,
      data: queryData,
      headers: config,
      pathParams: [qid],
    });
  };
  static postSearchFaqVariations = (
    queryData = {},
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.SEARCH_FAQ_VARIATIONS,
      data: queryData,
      headers: config,
    });
  };
  static getFaqVariations = (
    qid: string,
    config = {}
  ): Promise<AxiosResponse<any>> => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.FAQ_VARIATIONS,
      headers: config,
      pathParams: [qid],
    });
  };
  static getLocations = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.LOCATIONS,
      data: queryData,
      headers: config,
    });
  };
  static getBrowsers = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.BROWSERS,
      data: queryData,
      headers: config,
    });
  };
  static getMainFlowList = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.MAIN_FLOW_LIST,
      data: queryData,
      headers: config,
    });
  };
  static getSubFlowList = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.SUB_FLOW_LIST,
      data: queryData,
      headers: config,
    });
  };
  static getLinkSubFlow = (qid: string, config = {}) => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.LINK_SUB_FLOW,
      headers: config,
      pathParams: [qid],
    });
  };
  static generateAutomaticPhrases = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.GENERATE_PHRASES,
      data: queryData,
      headers: config,
    });
  };

  static publishBot = (queryData = {}, config = {}) => {
    return ConversationService.httpService.post({
      url: CH_BOT_ENDPOINTS.PUBLISH_BOT,
      data: queryData,
      headers: config,
    });
  };

  static getPhrases = (qid: string, config = {}) => {
    return ConversationService.httpService.get({
      url: CH_BOT_ENDPOINTS.GET_PHRASES,
      headers: config,
      pathParams: [qid],
    });
  };

  // static allLanguageTranslate = (queryData = {}, config = {}) => {
  //   return ConversationService.httpService.post({url:CH_BOT_ENDPOINTS.ALL_LANGUAGE_TRANSLATE, data:queryData,  headers: config});
  // };
}
