// CustomValueEditor.tsx
import React from "react";
import { ValueEditorProps } from "react-querybuilder";
import Select, { DropdownIndicatorProps, components } from "react-select";
import styled from "styled-components";
import { AppToolTip } from "../../../Components/UiComponents/AppToolTip/AppToolTip";
import { theme } from "../../../Customization/Theme";
import { getReactSelectCurrentValue } from "../utils/utils";
import { CustomQueryValueSearchField } from "./CustomQueryValueSearchField";

const NULL_VALUE_OPERATORS = ["null", "notNull"];

const TWO_VALUE_OPERATORS = ["between", "notBetween"];

export const CustomQueryValueField = (
  props: any | ValueEditorProps | undefined,
) => {
  const [value, setValue] = React.useState<any>(null);
  const [multipleTextValue1, setMultipleTextValue1] =
    React.useState<string>("");
  const [multipleTextValue2, setMultipleTextValue2] =
    React.useState<string>("");

  React.useEffect(() => {
    if (
      Array.isArray(props?.value) &&
      props?.value?.length === 2 &&
      typeof props?.value !== "string" &&
      props?.fieldData?.type !== "multiselect"
    ) {
      setMultipleTextValue1(props?.value?.[0]);
      setMultipleTextValue2(props?.value?.[1]);
      return;
    } else if (props?.fieldData?.type === "multiselect") {
      setValue(props?.value);
      return;
    } else {
      setValue(
        getReactSelectCurrentValue(props?.values, props?.value, "value", true),
      );
    }
  }, [props?.value, props?.values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFieldSelectChange = (selectedValue: any, isMulti?: boolean) => {
    if (isMulti) {
      props?.handleOnChange(selectedValue.map((ele: any) => ele.id || ele));
    } else {
      props?.handleOnChange(selectedValue?.value);
    }
  };

  const handleMultipleFieldTextChange = (e: any) => {
    let val1 = multipleTextValue1;
    let val2 = multipleTextValue2;
    if (customName + ".0" === e?.target?.name) {
      val1 = e?.target?.value ?? "";
    }
    if (customName + ".1" === e?.target?.name) {
      val2 = e?.target?.value ?? "";
    }

    props?.handleOnChange([val1, val2]);
  };

  const handleFieldTextChange = (e: any) => {
    props?.handleOnChange(e?.target?.value ?? "");
  };

  const handleAutoSearchOptionSelect = (selectedOption: any) => {
    // Handle the selected option, e.g., set it as a state in your parent component
    // props?.handleOnChange(selectedOption?.label ?? selectedOption?.value);
    props?.handleOnChange(selectedOption?.value ?? selectedOption?.label);
  };

  let customName: string = "CustomQueryValueField";

  if (props?.operator && NULL_VALUE_OPERATORS.includes(props?.operator)) {
    return null;
  }

  if (props?.operator && TWO_VALUE_OPERATORS.includes(props?.operator)) {
    return (
      <span>
        <StyledTextInput
          {...props}
          id={customName + ".0"}
          name={customName + ".0"}
          type={props?.type ?? "text"}
          value={multipleTextValue1 ?? ""}
          onChange={(e: any) => handleMultipleFieldTextChange(e)}
          style={{ minWidth: "180px", width: "180px" }}
        />
        <StyledTextInput
          {...props}
          id={customName + ".1"}
          name={customName + ".1"}
          type={props?.type ?? "text"}
          value={multipleTextValue2 ?? ""}
          onChange={(e: any) => handleMultipleFieldTextChange(e)}
          style={{ minWidth: "180px", width: "180px" }}
        />
      </span>
    );
  }

  if (props?.type === "select" || props?.type === "multiselect") {
    if (props?.fieldData?.type === "multiselect") {
      return (
        <Select
          id={customName}
          options={props?.values}
          value={value}
          onChange={(e: any) => handleFieldSelectChange(e, true)}
          placeholder={"Select"}
          isOptionSelected={(option: any, selectValue) => {
            return selectValue?.some((i: any) => i === option.id);
          }}
          getOptionLabel={(option: any) => {
            return props?.values.map((value: any) => {
              if (value.id === option?.id || value.id === option) {
                return value.label;
              }
              return "";
            });
          }}
          isMulti={true}
          isSearchable={true}
          isClearable={true}
          blurInputOnSelect={false}
          noOptionsMessage={() => "No Options "}
          styles={customStyles}
          menuPosition="fixed"
          components={{ DropdownIndicator, MultiValue }}
          className="react-select-container multiSelectContainer"
          classNamePrefix="react-select"
        />
      );
    } else {
      return (
        <Select
          id={customName}
          options={props?.values}
          value={value}
          onChange={(e: any) => handleFieldSelectChange(e)}
          placeholder={"Select"}
          isOptionSelected={(option: any) =>
            option?.value || option?.name || option?.label
          }
          isSearchable={true}
          isClearable={false}
          blurInputOnSelect={false}
          noOptionsMessage={() => "No Options "}
          styles={customStyles}
          menuPosition="fixed"
          components={{ DropdownIndicator }}
          className="react-select-container"
          classNamePrefix="react-select"
        />
      );
    }
  }

  if (props?.type === "search" || props?.type === "autocomplete") {
    return (
      <CustomQueryValueSearchField
        onOptionSelect={handleAutoSearchOptionSelect}
        {...props}
      />
    );
  }
  return (
    <StyledTextInput
      {...props}
      id={customName}
      name={customName}
      type={(["contains", "doesNotContain", "beginsWith", "endsWith", "doesNotBeginWith", "doesNotEndWith"].includes(props?.operator) && props?.type === "email")
        ? "text"
        : (props?.type ?? "text")}
      value={
        Array.isArray(props?.value)
          ? props?.value?.join(",")
          : props?.value ?? ""
      }
      onChange={(e: any) => handleFieldTextChange(e)}
      style={{
        minWidth: "180px",
        width: "180px",
      }}
    />
  );
};

const StyledTextInput = styled.input`
  && {
    width: 100%;
    min-width: 80px;
    max-width: 140px;
    height: 32px;
    border-radius: 4px;
    background-color: #f5f6f8;
    border: none;
    outline: none !important;
    padding: 0 8px 0 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    &::placeholder {
      color: ${theme.palette.default.darkGrey};
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }
    &:disabled {
      color: ${theme.palette.default.darkGrey};
      opacity: 0.7;
    }
  }
`;

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        height="20"
        width="20"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        className="css-tj5bde-Svg"
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </components.DropdownIndicator>
  );
};

const MultiValue = ({ index, getValue, options, ...props }: any) => {
  const maxToShow = 1;
  const overflow = options.filter((Option: any) =>
    getValue()
      .slice(maxToShow)
      .find((x: any) => x === Option.id),
  );
  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} />
  ) : null;
};
const MoreSelectedBadge = ({ items }: any) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const length = items.length;
  // const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
  const label = `+${length}`;

  return (
    <div style={style}>
      <AppToolTip
        tooltipProps={{
          title: (
            <React.Fragment>
              {items.map((ele: any) => {
                return <div>&#8226;&nbsp;&nbsp;{ele.label}</div>;
              })}
            </React.Fragment>
          ),
          placement: "top",
        }}
        className="chip"
        iconButtonProps={{ disableRipple: true }}
        IconComponent={<div>{label}</div>}
      />
    </div>
  );
};

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "rgb(245, 246, 248) !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    minWidth: "180px !important",
    maxWidth: "180px !important",
    boxShadow: "none !important",
    paddingBottom: "0px",
    borderColor: state?.isFocused ? "transparent" : "transparent",
    "&:hover": {
      borderColor: state?.isFocused ? "transparent" : "transparent",
    },
  }),
  menu: (base: any) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 2,
    // kill the gap
    marginTop: 4,
    backgroundColor: "rgb(245, 246, 248) !important",
  }),

  menuList: (base: any) => ({
    ...base,
    // kill the white space on first and last option
    backgroundColor: "white !important",
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: "transaparent !important",
    color: theme.palette.default.darkGrey,
    fontSize: "14px",

    "&:hover": {
      backgroundColor: "rgb(245, 246, 248) !important",
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "rgba(0, 0, 0, 0.54) !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "16px !important",
    padding: "0px !important",
    marginRight: "8px !important",
    svg: {
      fill: "rgba(0, 0, 0, 0.54) !important",
    },
  }),

  ValueEditor: (base: any) => ({
    ...base,
    backgroundColor: "red !important",
    fontSize: "23px",
  }),
  ".react-select__single-value": {
    backgroundColor: "red !important",
    fontSize: "19px",
  },
};
