import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Drawer, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  SettingPageMainBoxBtnBox,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
} from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import { ChatbotTrackingCode } from "../../../Models";
import { getBotTrackingCodeFn } from "../../../Services/Apis";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { useAppSelector } from "../../../Store";
interface Props {
  isBotPreviewOpen: boolean;
  setIsBotPreviewOpen: (value: React.SetStateAction<boolean>) => void;
}

const ChannelsBotLivePreview: React.FC<Props> = ({
  isBotPreviewOpen,
  setIsBotPreviewOpen,
}) => {
  let IFRAMEURL: string =
    process.env.REACT_APP_WHATSAPP_BOT_IFRAME_SCRIPT_BASE_URL ||
    "http://localhost:8083";
  const [trackingCode, setTrackingCode] = useState<ChatbotTrackingCode>();
  const authData = useSelector(useAuthStore);
  const currentBot: any = useAppSelector(useCurrentBot);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (currentBot?.id) {
      getBotTrackingCodeFn(dispatch)({
        pathParams: [currentBot?.id + ""],
      }).then((res: ChatbotTrackingCode) => {
        setTrackingCode(res);
      });
    }
  }, [authData, currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  const addScriptToHead = () => {
    if (!trackingCode?.bot_id) return;

    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `${IFRAMEURL}/output.js`;
    script.async = true;
    script.onload = () => {
      (window as any).on(
        `${trackingCode?.geta_host}`,
        `${trackingCode?.matomo_site_id}`,
        `${trackingCode?.bot_id}`
      );
    };
    head.appendChild(script);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  const removeScriptFromHead = () => {
    const script = document.querySelector("script[src*='/output.js']");
    script?.parentNode?.removeChild(script);
  };


  useEffect(() => {
    if (isBotPreviewOpen && trackingCode) {
      setIsLoading(true);
      addScriptToHead();
    } else {
      removeScriptFromHead();
      let iframe = document.querySelectorAll(
        '[title="geta_widget"]'
      )[0];
      iframe?.parentNode?.removeChild(iframe);
      setIsLoading(true);
    }
  }, [isBotPreviewOpen, trackingCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Drawer
      anchor={"right"}
      open={isBotPreviewOpen}
      onClose={() => setIsBotPreviewOpen(false)}
      hideBackdrop={false}
    >
      <SettingPageMainBoxTopbar
        style={{
          backgroundColor: theme.palette.default.white,
          height: "68px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.06)",
        }}
      >
        <SettingPageMainBoxTitle style={{ width: "100%" }}>
          {capitalizeFirstLetter(currentBot?.channel)} Bot Preview
        </SettingPageMainBoxTitle>
        <SettingPageMainBoxBtnBox>
          <IconButton onClick={() => setIsBotPreviewOpen(false)}>
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </SettingPageMainBoxBtnBox>
      </SettingPageMainBoxTopbar>
      <PreviewWrapBox>
        {isLoading ? <CircularProgress color="inherit" /> : null}
        {(isLoading || !trackingCode?.bot_id || !trackingCode?.geta_host) && (
          <h4 style={{ fontWeight: "500 !important", textAlign: "center" }}>
            {" "}
            Please wait while <br /> we fetching bot script
          </h4>
        )}
        {(!trackingCode?.bot_id || !trackingCode?.geta_host) && !isLoading && (
          <h4 style={{ fontWeight: "500 !important" }}>
            {" "}
            Bot script not found
          </h4>
        )}
      </PreviewWrapBox>
    </Drawer>
  );
};

export default ChannelsBotLivePreview;

const PreviewWrapBox = styled.div`
  && {
    width: 470px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    background-color: ${theme.palette.secondary.main};
  }
`;
