import { debounce } from "@mui/material";
import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import useDebounce from "../../Hooks/useDebounce";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../Models/ChatbotConfiguration";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { ConversationService } from "../../Services/Apis/ConversationService";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete.svg";
import {
  AppPagination,
  FaqTextWrap,
  Scroll,
  StyledAppInput,
} from "../BotBuilder/BotKnowledge/BotKnowledgeStyles";

import useModal from "../../Hooks/useModel/useModel";
import { useAppDispatch } from "../../Store";
import { FaqUserQueryAction } from "../../Store/Slices/Faq/UserQueries/userquery.actions";
import { ReactComponent as SearchIcon } from "../../assets/images/Searchicon.svg";
import DeleteConfirmationModal from "../BotBuilder/BotKnowledge/Faq/commonComponents/DeleteConfirmationModal";
import NotAnsweredTable from "../BotBuilder/BotKnowledge/Faq/commonComponents/NotAnsweredTable";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { valuesIn } from "lodash";

const NotAnswered: React.FC<any> = () => {
  useDocumentTitle("Not Answered");
  const currentBot = useSelector(useCurrentBot);
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const [faqListOptions, setFaqListOptions] = useState<any>([]);
  const [dataRows, setDataRows] = useState<any>([]);
  const [filteredRows, setFilteredRows] = useState<any>([]);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [botConfigData, setBotConfigData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Bot ID
  let { bot_id } = useParams();
  // Header For PayLoad
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };
  const [pagination, setPagination] = useState<any>({
    totalPages: 0,
    totalRecords: 0,
  });
  // Setting Values Locally
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    totalPages: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
    sort: false,
  });

  const [searchInput, setSearchInput] = useState<any>({
    leads_search_term: "",
  });

  const [SourceListing, SetSourceListing] = useState<any>([]);
  const [sourceListingCount, setSourceListingCount] = useState<any>(0);

  // ! this hook is used so that we can use Search  onchange with lower number of api calls
  // ! as it return the value once user stopped typing after given time

  // Previously Written Code
  const debouncedSearchTerm = useDebounce<string>(
    searchInput?.leads_search_term,
    300,
  );
  const getNotAnsweredFaqList = () => {
    // const headers = {
    //   appid: JSON.stringify({
    //     botid: "624624b9-3ad6-44e6-ba1a-502b4fd91b59",
    //   }),
    // };
    setLoading(true);
    const headers = HEADERS;

    const data = {
      limit: localValues?.itemsPerPage.value,
      offset: localValues?.currentPage,
      query: localValues?.query,
      sort: localValues?.sort,
    };

    ConversationService.getNotAnsweredFaqList(data, headers)
      .then((response: any) => {
        // setSourceListingCount(response?.data?.count);
        setPagination({
          totalPages: response?.data?.pagination?.totalPages,
          totalRecords: response?.data?.pagination?.totalRecords,
        });
        SetSourceListing(response?.data?.data);
        setDataRows(response?.data?.data || null);
        setFilteredRows(response?.data?.data || null);
        setLoading(false);
        // setLocalValues({
        // ...localValues,
        // itemsPerPage: response?.data?.pagination.perPage,
        // currentPage: response?.data?.pagination.currentPage,
        // totalPages: response?.data?.pagination.totalPages,
        // });
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getFaqList = () => {
    const headers = {
      appid: JSON.stringify({
        botid: currentBot.bot_id,
      }),
    };
    ConversationService.getFaqList(
      {
        page: 1,
        limit: 5000,
      },
      headers,
    )
      .then((response: any) => {
        setFaqListOptions(response?.data?.data || null);
      })
      .catch((error: any) => {});
  };

  useEffect(() => {
    if (debouncedSearchTerm.length === 0) {
      setFilteredRows(dataRows);
    } else {
      let temp = dataRows.filter((row: any) => {
        for (let key in row) {
          if (
            new String(row[key])
              .toLowerCase()
              .includes(debouncedSearchTerm.toLowerCase())
          ) {
            return new String(row);
          }
        }
      });
      setFilteredRows(temp);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (currentBot.configuration && currentBot.configuration) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot.configuration as string,
      ).then((res: AxiosResponse<ChatbotConfiguration>) => {
        if (res?.data?.show_suggestion) {
          setBotConfigData(res?.data);
          setShowSuggestions(res?.data?.show_suggestion);
        }
      });
    }
  }, [currentBot]);

  // New Code ============================================================
  const dispatch = useAppDispatch();
  const [mode, setMode] = useState<string>("");
  const AddEditModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingAddEditModal: isShowing, toggleAddEditModal: toggle };
  };
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();
  const { isShowingAddEditModal, toggleAddEditModal } = AddEditModal();
  // Single Document ID
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null,
  );
  // Multiple Document Ids
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  // PopOver For Table Row - Actions
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    documentId: any,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocumentId(documentId);
  };
  // Popover actions onclcik Close
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Function To Get Data By ID
  const getDocumentByID = () => {
    // if (selectedDocumentId) {
    //   const data = {
    //     documentid: selectedDocumentId,
    //     HEADERS,
    //   };
    //   dispatch(FaqSourceAction.GetSourceTextById(data));
    // }
  };

  // Function To Delete
  const deleteConversationByIds = () => {
    let conversation_id;
    if (selectedDocumentId) {
      conversation_id = [selectedDocumentId];
    } else {
      conversation_id = selectedDocumentIds;
    }
    const headers = {
      appid: JSON.stringify({
        botid: bot_id,
      }),
    };
    const data = {
      headers,
      conversation_id,
    };

    dispatch(FaqUserQueryAction.DeleteNotAnsConversationByIds(data))
      .then((res: any) => {
        toggleConfirmModal();
        setSelectedDocumentIds([]);
        getNotAnsweredFaqList();
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
    setSelectedDocumentIds([]);
    // toggleConfirmModal();
  };

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  };
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  useEffect(() => {
    SetSourceListing([]);
    getNotAnsweredFaqList();
    // getFaqList();
  }, [localValues]);
  return (
    <>
      <FaqTextWrap width="480px">
        <div className="top-header">
          <div className="header-left">
            <p>
              The bot had trouble understanding certain user queries. To improve
              its responses, please either include those queries in the
              appropriate Q&A section or create new questions and provide the
              corresponding answers.
            </p>
          </div>
          <div className="serach-class">
            {selectedDocumentIds.length ? (
              <span className="delete-button">
                <AppButton
                  onClick={() => toggleConfirmModal()}
                  variant="danger-filled"
                >
                  Delete <DeleteIcon />
                </AppButton>
              </span>
            ) : null}

            <div className="faq-search">
              <StyledAppInput>
                <input
                  type="text"
                  onChange={(e: any) => handleSelectSearch(e)}
                  name="query"
                  id="query"
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
          </div>
        </div>
        <Scroll>
          <NotAnsweredTable
            setLocalValues={setLocalValues}
            localValues={localValues}
            SourceListing={SourceListing}
            sourceType="text"
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClick={handleClick}
            handleClose={handleClose}
            getDocumentByID={getDocumentByID}
            toggle={toggleAddEditModal}
            setMode={setMode}
            setSelectedDocumentIds={setSelectedDocumentIds}
            selectedDocumentIds={selectedDocumentIds}
            selectedDocumentId={selectedDocumentId}
            setSelectedDocumentId={setSelectedDocumentId}
            toggleConfirmModal={toggleConfirmModal}
            loading={loading}
          />
        </Scroll>
      </FaqTextWrap>
      {SourceListing?.length ? (
        <AppPaginationComponent
          component="div"
          handlePageChange={handlePageChange}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          currentPage={localValues?.currentPage}
          totalCount={pagination?.totalRecords}
          totalPages={pagination.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
        />
      ) : null}
      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        toggleConfirmModal={toggleConfirmModal}
        deletSourceByDocumentId={deleteConversationByIds}
        selectedDocumentIds={selectedDocumentIds}
        isShowingConfirmModal={isShowingConfirmModal}
        type="Not Answered FAQs"
      />
    </>
  );
};

export default NotAnswered;
