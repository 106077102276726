import inline from "./inline";
import link from "./link";
import emoji from "./emoji";
import upload from "./upload";

export default {
  inline,
  link,
  emoji,
  upload,
};
