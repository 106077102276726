import { useState, useEffect } from "react";
import { RichUtils } from "draft-js";
import { getSelectionInlineStyle, getSelectedBlock } from "draftjs-utils";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { forEach } from "../../utils/common";

import Icon from "./../../../icon";

const Inline = (props: any) => {
  const { editorState, config, onChange } = props;
  const [currentStyles, setCurrentStyles] = useState<any>({});
  const [currentBlock, setCurrentBlock] = useState("");

  useEffect(() => {
    if (editorState) {
      setCurrentStyles(changeKeys(getSelectionInlineStyle(editorState)));
      setCurrentBlock(getListType());
    }
  }, []);

  useEffect(() => {
    setCurrentStyles(changeKeys(getSelectionInlineStyle(editorState)));
    setCurrentBlock(getListType());
  }, [editorState]);

  const isInlinActive = () => {
    let isCurrentStyleActive = false;
    if (currentStyles) {
      forEach(currentStyles, (key: string, value: any) => {
        if (value) isCurrentStyleActive = value;
      });
    }
    return isCurrentStyleActive || currentBlock !== "";
  };

  const getListType = () => {
    let listType = "";
    if (getSelectedBlock(editorState).get("type") === "unordered-list-item") {
      listType = "unordered";
    } else if (
      getSelectedBlock(editorState).get("type") === "ordered-list-item"
    ) {
      listType = "ordered";
    }
    return listType;
  };

  const changeKeys = (style: any) => {
    if (style) {
      const st: any = {};
      forEach(style, (key: string, value: any) => {
        st[key.toLowerCase()] = value;
      });
      return st;
    }
    return undefined;
  };

  const toggleInlineStyle = (style: string) => {
    const newStyle = style.toUpperCase();
    let newState = RichUtils.toggleInlineStyle(editorState, newStyle);
    if (newState) {
      onChange(newState);
    }
  };

  const onClick = (value: string) => {
    if (value === "unordered") {
      toggleBlockType("unordered-list-item");
    } else if (value === "ordered") {
      toggleBlockType("ordered-list-item");
    }
  };

  const toggleBlockType = (blockType: string) => {
    const { onChange, editorState } = props;
    const newState = RichUtils.toggleBlockType(editorState, blockType);
    if (newState) {
      onChange(newState);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        // pt: "30px",
        "&:hover > div": {
          display: "flex",
          opacity: 1,
        },
      }}
    >
      <Stack
        direction={"row"}
        spacing={1}
        sx={{
          position: "absolute",
          top: "-30px",
          zIndex: 1,
          py: "5px",
          display: "none",
        }}
      >
        {config.options.map((style: string, index: number) => (
          <IconButton
            sx={{
              p: "2px !important",
            }}
            size="small"
            key={`category-${index + 1}`}
            onClick={() => toggleInlineStyle(style)}
          >
            <Icon
              icon={config[style].icon}
              size={16}
              color={currentStyles[style] ? "#3654E3" : "#7E8392"}
            />
          </IconButton>
        ))}
        {config.blockOptions.map((style: string, index: number) => (
          <IconButton
            sx={{
              p: "2px !important",
            }}
            size="small"
            key={`category1-${index + 1}`}
            onClick={() => onClick(style)}
          >
            <Icon
              icon={config[style].icon}
              size={16}
              color={currentBlock === style ? "#3654E3" : "#7E8392"}
            />
          </IconButton>
        ))}
      </Stack>
      <IconButton sx={{ p: "2px !important" }} size="small">
        <Icon
          icon={config.icon}
          size={16}
          color={isInlinActive() ? "#3654E3" : "#7E8392"}
        />
      </IconButton>
    </Box>
  );
};
export default Inline;
