import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import {
  Caption,
  Label,
  StyledInputFieldWrapper,
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import { AppMaterialIcons } from "../AppMaterialIcons";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  Options: any;
  label?: string;
  name: string;
  onChangeEmptyField?: string;
  onChangeCallbackFn?: (value: any) => void;
  tooltip?: any;
  divStyle?: any;
  subTitle?: string;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  labelStyle?: any;
  [otherProps: string]: any;
}

const AppFormToggleButtonGroup: React.FC<Props> = ({
  Options,
  label,
  name,
  onChangeEmptyField,
  onChangeCallbackFn,
  tooltip,
  divStyle,
  subTitle,
  children,
  labelStyle,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, values, setFieldValue, setFieldError } =
    useFormikContext<any>();

  useEffect(() => {
    if (getIn(values, name) === 0) {
      setFieldValue(name, Options[0]);
    }
    if (getIn(values, name)) {
      const index = Options.findIndex(
        (x: any) => x?.value === getIn(values, name)
      );
      setFieldValue(name, Options[index] || Options[0]);
    }
  }, []); // eslint-disable-line

  const handleButtonChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: any
  ) => {
    setFieldValue("header_value", null);
    if (!newValue) {
      return null;
    }
    setFieldError("header_value", "")
    setFieldValue(name, newValue);
  };
  
  return (
    <StyledInputFieldWrapper style={divStyle}>
      <Label style={labelStyle}>
        {label}

        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <Caption>{subTitle}</Caption>

      <ToggleButtonGroup
        color="primary"
        value={typeof getIn(values, name) === 'object'?  getIn(values, name)?.value :getIn(values, name) || Options[0].value}
        exclusive
        onChange={(event, newValue) => {
          handleButtonChange(event, newValue);
        }}
        onBlur={() => {
          setFieldTouched(name, true);
        }}
        aria-label="toggle-buttons"
        {...otherProps}
        style={{
          margin: "0.5rem 0 0",
          width: "100%",
          flexWrap: "wrap",
          gap: "1.5rem",
        }}
      >
        {Options?.map((curItem: any, index: number) => {
          return (
            <ToggleButton
              value={curItem.value}
              key={index.toString()}
              style={{
                display: "flex",
                gap: "0.7rem",
                borderRadius: "3.125rem",
                padding: "0.8rem 1rem",
                border:
                  getIn(errors, name) && getIn(touched, name)
                    ? `1px solid ${theme.palette.default.error}`
                    : `1px solid ${theme.palette.default.border}`,
                textTransform: "capitalize",
              }}
              disabled={curItem?.disabled || curItem?.value === getIn(values, name)}
            >
              <AppMaterialIcons
                iconName={curItem?.icon}
                style={{ fontSize: "1.5rem" }}
              />
              {curItem?.name}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </StyledInputFieldWrapper>
  );
};

export default AppFormToggleButtonGroup;
