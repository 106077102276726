import { Box, Skeleton } from "@mui/material";
export const renderSkeleton = () => {
  return (
    <Box
      display="flex"
      sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
      className="tabel-heading"
    >
      <Box width="5%" style={{ gap: "36px", display: "grid" }} p={1}>
        <Skeleton width={65} height={50} />
        <Skeleton width={65} height={50} />
        <Skeleton width={65} height={50} />
        <Skeleton width={65} height={50} />
      </Box>
      <Box width="280px" style={{ gap: "36px", display: "grid" }} p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
      <Box width="280px" style={{ gap: "36px", display: "grid" }} p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
      <Box width="280px" style={{ gap: "36px", display: "grid" }} p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
      <Box width="280px" style={{ gap: "36px", display: "grid" }} p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
      <Box width="275px" style={{ gap: "36px", display: "grid" }} p={1}>
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
        <Skeleton width={210} height={50} />
      </Box>
    </Box>
  );
};

// To Show FAQ List
export const faqList = [
  {
    question: "How can I upgrade my subscription plan?",
    answer: (
      <>
        To upgrade your subscription plan, click the "Upgrade Plan" button on
        this screen. You'll be guided through the available plan options, and
        you can choose the one that best suits your needs. The upgrade will take
        effect at the end of your current billing cycle.
      </>
    ),
    defaultStatus: "panel1",
  },
  {
    question: "Can I view the details of my current subscription plan?",
    answer: (
      <>
        Yes, you can! Click the "View Plan Details" button to see all the
        features and benefits of your current subscription plan. This will help
        you understand what's included and whether an upgrade might be
        beneficial for you..
      </>
    ),
    defaultStatus: "panel2",
  },
  {
    question: " When will the changes from an upgrade take effect?",
    answer: (
      <>
        {" "}
        Any changes resulting from an upgrade will be applicable at the end of
        your current billing cycle. You will continue to have access to your
        current plan until that date.
      </>
    ),
    defaultStatus: "panel3",
  },
  {
    question:
      "Is there a refund policy in case I want to cancel my subscription?",
    answer: (
      <>
        {" "}
        Our cancellation and refund policy varies based on your subscription
        terms and any applicable trial periods. For specific information, please
        check the Refund policy page.
      </>
    ),
    defaultStatus: "panel4",
  },
  {
    question:
      "Can I switch back to my previous subscription plan after an upgrade?",
    answer: (
      <>
        {" "}
        Yes, in most cases, you can switch back to your previous subscription
        plan. However, please note that changes may take effect at the end of
        your current billing cycle. Contact our customer support team for
        assistance with plan changes.
      </>
    ),
    defaultStatus: "panel5",
  },
];
export const creditRate: any = {
  sms: [
    {
      name: "Destination country",
      value: "name",
    },
    {
      name: "Price per message",
      value: "price",
      margin: "margin",
    },
  ],
  email: [
    {
      name: "Destination country",
      value: "name",
    },
    {
      name: "Price per message",
      value: "price",
      margin: "margin",
    },
  ],
  whatsapp: [
    {
      name: "Destination country",
      value: "name",
    },
    {
      name: "utility",
      value: "price",
    },
    {
      name: "authentication",
      value: "price",
    },
    {
      name: "marketing",
      value: "price",
    },
    {
      name: "service",
      value: "price",
    },
  ],
};
