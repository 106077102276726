import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class CreditDetailsNetworkService {


    // * get Broadcast Credit details  *//

    static getBroadcastCreditDetails(id: any) {
        return http.get(`${consoleBaseUrl}${Endpoints.GET_BROADCAST_CREDIT_DETAILS}/${id}`);
      }
      
}

//*Broadcast credit details endpoints *//

class Endpoints {

    //new endpoints for node microservice
    static GET_BROADCAST_CREDIT_DETAILS = "/workspace/broadcast/broadcast-credits-calculation";

}
