// vendors
import {
  ContentState,
  EditorState,
  Modifier,
  SelectionState,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { MESSAGE_TYPES } from "../../../../utils/Enum";

export const IMAGE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
];
export const VIDEO_TYPES = ["video/mp4", "video/3gp"];
export const AUDIO_TYPES = [
  "audio/aac",
  "audio/mp4",
  "audio/mpeg",
  "audio/AMR",
];
export const STICKER_TYPES = ["image/webp"];

export const DOCUMENT_TYPES = [
  "text/csv",
  "text/plain",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export enum ImageState {
  INITIAL = "initial",
  SEARCH = "search",
  GALLERY = "gallery",
  UPLOADED = "uploaded",
}

export const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "video") {
    return VIDEO_TYPES;
  } else if (mediaType === "image") {
    return IMAGE_TYPES;
  } else if (mediaType === "document") {
    return DOCUMENT_TYPES;
  } else if (mediaType === "sticker") {
    return STICKER_TYPES;
  } else if (mediaType === "audio") {
    return AUDIO_TYPES;
  } else {
    return [];
  }
};
export const getUploadedMediaType = (mimeType: string) => {
  if (IMAGE_TYPES?.includes(mimeType)) {
    return "image";
  } else if (VIDEO_TYPES?.includes(mimeType)) {
    return "video";
  } else if (DOCUMENT_TYPES?.includes(mimeType)) {
    return "document";
  } else if (AUDIO_TYPES?.includes(mimeType)) {
    return "audio";
  } else {
    return "document";
  }
};
export const getCorrectMediaSize = (mediaType: string) => {
  if (mediaType === "video") {
    return 16777216;
  } else if (mediaType === "image") {
    return 5242880;
  } else if (mediaType === "document") {
    return 104857600;
  } else if (mediaType === "sticker") {
    return 1048576;
  } else if (mediaType === "audio") {
    return 5242880;
  } else {
    return 1048576;
  }
};

export const getMessageMediaType = (type: string) => {
  if (type === "image") {
    return MESSAGE_TYPES.IMAGE;
  } else if (type === "document") {
    return MESSAGE_TYPES.FILE;
  } else if (type === "video") {
    return MESSAGE_TYPES.VIDEO;
  } else if (type === "audio") {
    return MESSAGE_TYPES.AUDIO;
  } else {
    return MESSAGE_TYPES.FILE;
  }
};

export const sizeInMB = (sizeInBytes: number) => {
  return (sizeInBytes / (1024 * 1024)).toFixed(2);
};

export const getInitialEditiorText = (text: string) => {
  const contentBlock = htmlToDraft(text);
  let initialEditorState = EditorState.createEmpty();
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    const editorState = EditorState.createWithContent(contentState);
    initialEditorState = editorState;
    initialEditorState = EditorState.moveFocusToEnd(editorState); // Move the focus to the end

    // Alternatively, if you want to move the cursor to the end:
    // initialEditorState = EditorState.forceSelection(editorState, editorState.getSelection().merge({
    //   anchorOffset: editorState.getCurrentContent().getPlainText().length,
    //   focusOffset: editorState.getCurrentContent().getPlainText().length,
    // }));
  }
  return initialEditorState;
};

export const getHTMLContent = (editorState: EditorState) => {
  // return draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const rawContent = convertToRaw(editorState?.getCurrentContent());
  const htmlContent = draftToHtml(rawContent);

  // // Create a temporary div element to parse the HTML content
  // const tempDiv = document?.createElement("div");
  // tempDiv.innerHTML = htmlContent;

  // // Find all anchor tags and add the desired attributes

  // const anchorTags = tempDiv.getElementsByTagName("a");
  // for (let i = 0; i < anchorTags.length; i++) {
  //   anchorTags?.[i]?.setAttribute("rel", "noopener noreferrer");
  //   anchorTags?.[i]?.setAttribute("target", "_blank");
  // }

  // // Serialize the modified content back to HTML
  // // const serializer = new XMLSerializer();
  // // const modifiedHtmlContent = serializer?.serializeToString(tempDiv);

  // // Get the inner HTML of the temporary div without the parent div
  // const modifiedHtmlContent = tempDiv.innerHTML;

  // return modifiedHtmlContent;
  return htmlContent;
};

export function moveFocusToEnd(editorState: EditorState) {
  editorState = EditorState.moveSelectionToEnd(editorState);
  return EditorState.forceSelection(editorState, editorState.getSelection());
}

export const convertHtmlToWhatsappText = (text: string) => {
  if (!text) return "";
  let strBold = text;
  let replacedBold = strBold
    ?.replace(/<strong>/g, "*")
    ?.replace(/<\/strong>/g, "*");

  let strItalic = replacedBold;
  let replacedItalic = strItalic
    ?.replace(/<em>/g, "_")
    ?.replace(/<\/em>/g, "_");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    ?.replace(/<del>/g, "~")
    ?.replace(/<\/del>/g, "~");

  let strMono = replacedStrike;
  let replacedMono = strMono
    ?.replace(/<tt>/g, "```")
    ?.replace(/<\/tt>/g, "```")
    ?.replace(/<code>/g, "```")
    ?.replace(/<\/code>/g, "```");

  let whatsappText: string = getTextWithoutHtml(replacedMono || "")
    // .replace(/\n/g, "")
    .replace(/&nbsp;/g, "");
  return whatsappText;
};

export const getTextWithoutHtml = (string: string) => {
  let strippedHtml = string?.replace(/<[^>]+>/g, "");
  return strippedHtml;
};

export const insertEditorText = (text: any, editorValue: any) => {
  const currentContent = editorValue.getCurrentContent();
  const currentSelection = editorValue.getSelection();
  const newContent = Modifier.replaceText(
    currentContent,
    currentSelection,
    text,
  );
  const newEditorState = EditorState.push(
    editorValue,
    newContent,
    "insert-characters",
  );
  return EditorState.forceSelection(
    newEditorState,
    newContent.getSelectionAfter(),
  );
};

export const getMentionCustomNameKeys = (arr: any) => {
  let a: any = [];
  arr?.forEach((item: any) => {
    let temp = {
      ...item,
      name: item?.message,
    };

    a?.push(temp);
  });
  return a;
};

export const getEditorSelectedText = (editorState: EditorState): string => {
  const selectionState: SelectionState = editorState?.getSelection();
  const contentState: ContentState = editorState?.getCurrentContent();
  const currentContent = contentState?.getPlainText();
  const start = selectionState?.getStartOffset();
  const end = selectionState?.getEndOffset();
  const selectedText = currentContent?.slice(start, end);

  return selectedText;
};

export const editorAddLink = (
  editorState: EditorState,
  selectedText: string,
): EditorState => {
  if (!selectedText) return editorState;

  const contentState: ContentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "LINK",
    "MUTABLE",
    { url: selectedText }, // Replace with the desired URL
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const selectionState: SelectionState = editorState.getSelection();
  const updatedContentState = Modifier.replaceText(
    contentStateWithEntity,
    selectionState,
    selectedText,
    undefined,
    entityKey,
  );

  const newEditorState = EditorState.push(
    editorState,
    updatedContentState,
    "insert-characters",
  );

  return EditorState.forceSelection(
    newEditorState,
    updatedContentState.getSelectionAfter(),
  );
};

export const filterEditorCannedResponsesSearch = (
  data: any[],
  searchText: string,
) => {
  const filteredData = data.filter((item: any) => {
    const { name, short_code } = item;
    const lowercaseSearchText = searchText?.toLowerCase();
    const lowercaseName = name?.toLowerCase();
    const lowercaseShortCode = short_code?.toLowerCase();

    return (
      lowercaseName?.includes(lowercaseSearchText) ||
      lowercaseShortCode?.includes(lowercaseSearchText)
    );
  });
  return filteredData || [];
};

export const getChannelWiseText = (text: string, type?: string) => {
  let finalText = text;
  if (!finalText) return "";

  finalText = htmlStringToLink(finalText);

  if (type === "whatsapp") {
    finalText = convertHtmlToWhatsappText(finalText);
    return finalText;
  }
  if (type === "messenger") {
    finalText = convertHtmlToWhatsappText(finalText);
    return finalText;
  }

  finalText = finalText?.length > 0 ? replaceEmptyPTagsWithBr(finalText) : "";

  return finalText;
};

export const getHtmlTextChannelWise = (text: string, type?: string) => {
  let finalText = text;
  if (!finalText) return "";

  if (type === "whatsapp") {
    finalText = convertWhatsappToHtmlText(text);
    return finalText;
  }

  if (type === "messenger") {
    finalText = convertWhatsappToHtmlText(text);
    return finalText;
  }

  return finalText;
};

export const convertWhatsappToHtmlText = (text: string) => {
  if (!text) return "";
  let htmlText = text?.toString();
  // Replace new line characters with <br/> tags
  htmlText = htmlText.replace(/\n/g, "<br/>");
  const htmlFormat = [
    { symbol: "*", tag: "strong" },
    { symbol: "_", tag: "em" },
    { symbol: "~", tag: "del" },
    { symbol: "`", tag: "code" },
  ];
  htmlFormat.forEach(({ symbol, tag }) => {
    const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, "gm");
    const match = htmlText.match(regex);
    if (!match) return;

    match.forEach((m) => {
      let formatted = m;
      for (let i = 0; i < 2; i++) {
        formatted = formatted.replace(symbol, `<${i > 0 ? "/" : ""}${tag}>`);
      }
      htmlText = htmlText.replace(m, formatted);
    });
  });
  return htmlText;
};

export const checkEmptyString = (htmlText: string): string => {
  if (!htmlText) return "";

  const comparisonString1 = "<p></p>\n";
  const comparisonString2 = "<p></p>";

  if (htmlText === comparisonString1 || htmlText === comparisonString2) {
    return "";
  }

  return htmlText;
};

export const replaceEmptyPTagsWithBr = (htmlText: string) => {
  htmlText = checkEmptyString(htmlText);

  if (!htmlText) return "";

  let withoutEmptyPTags = htmlText?.replace(/<p><\/p>/g, "<br/>");
  return withoutEmptyPTags;
};

const htmlStringToLink = (htmlString: string) => {
  // Regular expression to match URLs
  const regex = /(https?:\/\/[^<>\s]+)/g;
  const parts = htmlString?.split(regex);

  if (!parts) return htmlString;

  return parts
    ?.map((part: any, index: number) => {
      if (part?.match(regex)) {
        return `<a href="${part}" target="_blank" rel="noopener noreferrer">${part}</a>`;
      }
      return part;
    })
    .join("");
};

export function removeCharactersFromString(str:string, length:number= 1) {
  return str?.slice(length) ?? str;
}
