import { createAsyncThunk } from "@reduxjs/toolkit";
import { SubscriptionsNetworkService } from "./subscriptionsNetworkService";

export class SubscriptionActions {
  // get All Subscription Plan
  static getAllPlans = createAsyncThunk(
    "SubscriptionSlice/getAllPlan",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getAllPlans(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // get Ative Subscription Plan
  static getSubscriptionData = createAsyncThunk(
    "SubscriptionSlice/getSubscriptionData",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getSubscriptionData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // get Active Subscription Plan
  static getAddonListData = createAsyncThunk(
    "SubscriptionSlice/getAddonListData",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getAddonListData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // get all invoice list
  static getAllInvoiceList = createAsyncThunk(
    "SubscriptionSlice/getAllInvoiceList",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getAllInvoiceList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  static updateSubscriptionCallbackData = createAsyncThunk(
    "SubscriptionSlice/updateSubscriptionCallbackData",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.updateSubscriptionCallbackData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  static getInvoiceCount = createAsyncThunk(
    "SubscriptionSlice/getInvoiceCount",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getInvoiceCount(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Get All Country List
  static getAllCountryList = createAsyncThunk(
    "SubscriptionSlice/getAllCountryList",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getAllCountryList({})
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Get Credit By List
  static getCreditListById = createAsyncThunk(
    "SubscriptionSlice/getCreditListById",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getCreditListById(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Get Wallet Inoice By List
  static getAllWalletInvoiceList = createAsyncThunk(
    "SubscriptionSlice/getAllWalletInvoiceList",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getAllWalletInvoiceList(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Get Wallet Inoice Count By List
  static getWalletInvoiceCount = createAsyncThunk(
    "SubscriptionSlice/getWalletInvoiceCount",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getWalletInvoiceCount(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Get Wallet Inoice Count By List
  static getCreditUtilization = createAsyncThunk(
    "SubscriptionSlice/getCreditUtilization",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getCreditUtilization(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );
  // Get Whatsapp Credit Logs
  static getWhatsappCreditLogs = createAsyncThunk(
    "SubscriptionSlice/getWhatsappCreditLogs",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getwhatsappCreditLogsData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Credit Summary Logs
  static getCreditSummaryLogsAction = createAsyncThunk(
    "SubscriptionSlice/getCreditSummaryLogsAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getCreditSummaryLogsNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

  // Credit Summary 
  static getCreditSummaryAction = createAsyncThunk(
    "SubscriptionSlice/getCreditSummaryAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return SubscriptionsNetworkService.getCreditSummaryNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );

}
