import React from "react";
import styled from "styled-components";

// import selectAChat from "../../../assets/images/selectAChat.svg";
// import selectAChat from "../../../assets/images/SelectChat.gif";
import selectAChat from "../../../assets/images/Live-Chat-select-convo.svg";
import { theme } from "../../../Customization/Theme";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

interface Props {}

const NoChatScreen: React.FC<Props> = () => {
  const { data: authData } = useSelector(useAuthStore);
  return (
    <StyledWrapper>
      <img width="320px" src={selectAChat} alt="icon" />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <h1>
          Hello there,&nbsp;
          <span style={{ color: "#2871D7" }}>
            {authData &&
              authData?.data &&
              authData?.data?.user &&
              authData?.data?.user?.first_name}
          </span>
        </h1>
        <p>You should select conversation from left pane.</p>
      </Box>
    </StyledWrapper>
  );
};

export default NoChatScreen;

const StyledWrapper = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    // background-color: ${theme.palette.default.white};
    h1 {
      font-size: 22px;
      color: #12344d;
      font-weight: 500;
      margin: 0px;
    }
    p {
      font-size: 18px;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
