// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import { useSelector } from "react-redux";
import { v4 as uuid } from "uuid";

// components
import UIButton from "../../Components/Button";
import Icon from "../../Components/Icon";
import NodeType from "../../Components/NodeType";
import EditableButton from "./EditableButton";
import ButtonInputOptions from "./button-input-options";
import ButtonPreview from "./buttonPreview";

// slices
import { getLanguage } from "../../Slices/main.slice";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

// utils
import { getActionByType } from "./helper";
import { getNodeConfig } from "../../Utils";
import { theme } from "../../../../Customization/Theme";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";

type Props = {
  buttonsList: any;
  isActiveNode: boolean;
  onLabelChange?: (
    label: string,
    activeButtonIdx: number | null,
    isDescription?: boolean,
  ) => void;
  onButtonSave?: (updatedAction: any) => void;
  onPreviewButtonClick: () => void;
  onButtonDelete: (button: any, index: number) => void;
  nodeType?: string;
  minLimit?: number | null;
  disableAddButton?: boolean;
  onOrderChange: (buttonList: any) => void;
  addDescription?: boolean;
  onDescriptionChange?: (
    description: string,
    activeButtonIdx: number | null,
  ) => void;
};

type Action = {
  icon: string;
  color: string;
  title: string;
  displayComponent: any;
  type: string;
};

const ButtonsList = (props: Props) => {
  const {
    buttonsList,
    onLabelChange,
    onButtonSave,
    onPreviewButtonClick,
    onButtonDelete,
    isActiveNode,
    nodeType,
    minLimit,
    disableAddButton = false,
    onOrderChange,
    addDescription,
  } = props;
  const language = useSelector(getLanguage);
  const [buttons, setButtons] = useState<any>([]);
  const [activeButton, setActiveButton] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedAction, setSelectedAction] = useState<{
    icon: string;
    color: string;
    title: string;
    displayComponent: any;
  } | null>(null);
  const [error, updateError] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const currentBot = useSelector(useCurrentBot);
  const nodeConfig = getNodeConfig(nodeType || "button");
  const validation = currentBot?.channel
    ? nodeConfig.validation[currentBot?.channel]
    : {};
  const maxLimit = validation?.maxLimit;
  const charLimit = validation?.charLimit;

  useEffect(() => {
    if (buttonsList && Array.isArray(buttonsList)) {
      setButtons(buttonsList);
    }
  }, [buttonsList]);

  const handleAddButtonClick = () => {
    const newButton = {
      id: uuid(),
      type: "",
      value: {
        text: `Button ${buttons.length + 1}`,
        url: "",
      },
    };
    setButtons([...buttons, newButton]);
    setActiveButton(buttons.length);
    onButtonSave && onButtonSave(newButton);
  };

  const handleEditableButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    setActiveButton(index);
    setAnchorEl(event.currentTarget);
    if (buttons[index] && buttons[index].type !== "") {
      setSelectedAction(getActionByType(buttons[index].type));
    }
  };

  const handleDeleteClick = (activeButtonData: any, index: number) => {
    onButtonDelete && onButtonDelete(activeButtonData, index);
    handleClose();
  };

  const handleOptionClick = (action: Action) => {
    setSelectedAction(action);
    if (activeButton !== null) {
      const newState = buttons.map((button: any, index: number) => {
        if (index === activeButton) {
          return {
            ...button,
            type: action.type,
            ...(action.type === "live_agent" && { flow_type: action.type }),
            ...(action.type === "transfer_to_faqs" && {
              flow_type: action.type,
              input: true,
              input_type: "TRANSFER_TO_FAQS",
            }),
          };
        }
        return button;
      });
      setButtons(newState);
    }
  };

  const handleButtonInputChange = (label: string, isDescription?: boolean) => {
    updateError(label === "");

    if (activeButton !== null) {
      const newState = buttons.map((button: any, index: number) => {
        if (index === activeButton) {
          let returnObject: any = { ...button, value: { ...button.value } };
          if (!isDescription) {
            returnObject.value.text = label;
          } else {
            returnObject.value.description = label;
          }
          return {
            ...returnObject,
          };
        }
        return button;
      });
      setButtons(newState);
    }

    if (
      activeButton !== null &&
      typeof buttonsList[activeButton] !== "undefined"
    ) {
      onLabelChange && onLabelChange(label, activeButton, isDescription);
    }
  };

  const handleBackClick = () => {
    setSelectedAction(null);
  };

  const handleSaveClick = (activeButtonData: any) => {
    onButtonSave && onButtonSave(activeButtonData);
    handleClose();
  };
  const handleCancelClick = () => {
    if (activeButton !== null) {
      const prevActiveButtonData = buttonsList[activeButton];
      if (prevActiveButtonData) {
        if (activeButton !== null) {
          const newState = buttons.map((button: any, index: number) => {
            if (index === activeButton) {
              return {
                ...button,
                type: prevActiveButtonData.type,
              };
            }
            return button;
          });
          setButtons(newState);
        }
      }
    }
    handleClose();
  };

  const handleClose = () => {
    setSelectedAction(null);
    setAnchorEl(null);
    setActiveButton(null);
  };

  const handlePreviewButtonClick = (index: number) => {
    const currentButton = buttonsList[index];
    const id = `button-${currentButton.id}-${index}`;
    setTimeout(() => {
      setAnchorEl(document.getElementById(id));
      if (currentButton && currentButton.type !== "") {
        setSelectedAction(getActionByType(currentButton.type));
      }
    }, 100);

    setActiveButton(index);
    onPreviewButtonClick && onPreviewButtonClick();
  };

  const Component =
    selectedAction !== null ? selectedAction?.displayComponent : <></>;
  return (
    <>
      {!isActiveNode ? (
        <ButtonPreview
          buttonList={buttonsList}
          onButtonClick={handlePreviewButtonClick}
        />
      ) : (
        <Box p={"16px"}>
          {buttons.length > 0 && (
            <>
              <Box
                borderRadius={"8px"}
                sx={{
                  backgroundColor: theme.palette.default.lightGrey,
                  padding: "1rem 1rem 0.5rem 1rem",
                }}
              >
                <EditableButton
                  buttonList={buttons}
                  onButtonClick={handleEditableButtonClick}
                  activeButton={activeButton}
                  onDelete={handleDeleteClick}
                  minLimit={minLimit}
                  onOrderChange={onOrderChange}
                />
              </Box>
            </>
          )}
          <Box sx={{ p: "1rem 0rem 0rem 0rem" }}>
            <UIButton
              fullWidth
              variant="outlined"
              onClick={handleAddButtonClick}
              disabled={
                language !== "english" ||
                maxLimit === buttonsList.length ||
                disableAddButton
              }
              style={{ backgroundColor: "#fff" }}
            >
              + Add Button
            </UIButton>
          </Box>
          <Popover
            className="ignore-onClickOutside"
            open={open}
            anchorEl={anchorEl}
            onClose={() => !error && handleClose()}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            sx={{ ml: "24px", borderRadius: "8px" }}
            elevation={0}
            slotProps={{
              root: {
                // @ts-ignore
                slotProps: {
                  backdrop: {
                    className: "button-popover-backdrop",
                    sx: {
                      backgroundColor: "transparent",
                    },
                  },
                },
              },
              paper: {
                sx: {
                  borderRadius: "8px",
                  boxShadow:
                    "0px 13.9892px 69.9461px rgba(126, 131, 146, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.08);",
                  marginLeft: "55px",
                },
              },
            }}
          >
            {selectedAction === null ? (
              <ButtonInputOptions
                // @ts-ignore
                activeButtonData={buttons[activeButton]}
                onClose={() => !error && handleClose()}
                onOptionClick={handleOptionClick}
                selectedAction={selectedAction}
                onDelete={(button) =>
                  handleDeleteClick(
                    button,
                    activeButton !== null ? activeButton : -1,
                  )
                }
                onButtonLabelInputChange={handleButtonInputChange}
                language={language}
                maxCharLength={charLimit}
                addDescription={addDescription}
              />
            ) : (
              <Box sx={{ width: "345px", maxWidth: "345px" }}>
                <Box
                  sx={{
                    py: "4px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid #F5F6F8;",
                  }}
                >
                  <IconButton
                    sx={{
                      ml: "5px",
                      mr: "-10px",
                    }}
                    onClick={handleBackClick}
                  >
                    <Icon icon="chevron-left" color="" size={12} />
                  </IconButton>
                  <NodeType
                    text={selectedAction?.title}
                    color={selectedAction?.color}
                    icon={selectedAction?.icon}
                  />
                </Box>
                <Component
                  language={language}
                  onCancelClick={handleCancelClick}
                  onSaveClick={handleSaveClick}
                  activeButtonData={
                    activeButton !== null && buttons[activeButton]
                  }
                  maxCharLength={charLimit}
                />
              </Box>
            )}
          </Popover>
        </Box>
      )}
    </>
  );
};
export default ButtonsList;
