import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { getProjectConfigFn } from "../../Services/Apis/CoreApis";
import { CORE_ENDPOINTS } from "../../Services/Apis/Endpoints";
import {
  ChangeSelectedBot,
  useCurrentBot,
} from "../../Store/Slices/DashboardSlices";
import { formatString } from "../../Utils";

import React from "react";
import { getMyBotsFn } from "../../Services/Apis";

const ClientLayout = () => {
  let { bot_id } = useParams();
  const dispatch = useDispatch();
  const currentBot = useSelector(useCurrentBot);

  //get current bot data if bot id exists in the url
  const getCurrentBotData = () => {
    ChatbotConsoleService.getBotById({ bot_id: bot_id }).then((response) => {
      const bot = response.data;
      dispatch(ChangeSelectedBot(response.data));
      const proj_v_url = formatString(CORE_ENDPOINTS.PROJECT_VER_CONFIG, [
        bot.project_version_id,
      ]);
      getProjectConfigFn(dispatch)({ url: proj_v_url }).then((res: any) => {});
    });
  };

  //get all bots listing for the workspace
  const getAllBotListings = () => {
    getMyBotsFn(dispatch)({});
  };

  React.useEffect(() => {
    getAllBotListings();
    if (!currentBot?.bot_id && bot_id) {
      getCurrentBotData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (currentBot?.bot_id || (!currentBot?.bot_id && bot_id) || !bot_id) {
    return (
        <Outlet />
    );
  }

  return null;
};

export default ClientLayout;

export const HomeLayoutWrap = styled.div`
  && {
    width: 100vw;
    overflow-x: hidden !important;
    height: 100%;
    display: flex;
    position: relative;
  }
`;

export const HomeLayoutWrapLeft = styled.div`
  && {
    height: 100%;
    position: -webkit-fixed;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 60px;
    z-index: 1000;
    background-color: ${theme.palette.primary.dark};
  }
`;

export const HomeLayoutWrapRight = styled.div`
  && {
    width: calc(100vw - 60px);
    min-height: 100%;
    margin-left: 60px;
  }
`;

export const ContentWrap = styled.div`
  && {
    background-color: ${theme.palette.secondary};
    height: calc(100% - 4rem);
    width: 100%;
    margin: 4rem 0rem 0 0;
    overflow-x: hidden;
    scroll-behavior: auto;
  }
`;
