// vendors
import { styled } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface ArrowButtonProps extends BoxProps {
  disabled?: boolean;
}

const commonButtonStyle = {
  //   top: "20%",
  //   transform: "traslateY(20%)",
  background: "#FFFFFF",
  border: "1px solid #CBCDD3",
  borderRadius: "8px",
  height: "40px",
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  zIndex: 1,
  "&:hover": {
    backgroundColor: "#CBCDD3",
    transition: "all 300ms ease-out",
  },
};

const commonButtonDisabledStyle = {
  cursor: "not-allowed",
  backgroundColor: "#f6f6f6",
  "&:hover": {
    backgroundColor: "#f6f6f6",
  },
};
export const LeftArrowButtonStyled = styled(Box)<ArrowButtonProps>(
  ({ disabled }) => ({
    position: "absolute",
    left: "-30px",
    ...commonButtonStyle,
    ...(disabled && commonButtonDisabledStyle),
  })
);

export const RightArrowButtonStyled = styled(Box)<ArrowButtonProps>(
  ({ disabled }) => ({
    position: "absolute",
    right: "-30px",
    ...commonButtonStyle,
    ...(disabled && commonButtonDisabledStyle),
  })
);

export const CarouselDotStyled = styled(Box)(() => ({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  width: "24px",
  height: "24px",
  border: "1px solid #CBCDD3",
  borderRadius: "8px",
  cursor: "pointer",
  backgroundColor: "#fff",
  padding: "5px",
  "&:hover": {
    backgroundColor: "#f1f1f1",
    transition: "all 300ms ease-out",
  },
  "& .slick-active": {
    color: "#5B73E8",
  },
}));

export const DeleteButtonStyled = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "40px",
  width: "40px",
  border: "1px solid #CBCDD3",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  backgroundColor: "#fff",
  position: "absolute",
  top: "16px",
  right: "16px",
  cursor: "pointer",
}));

export const LabelStyled = styled(Typography)(() => ({
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#7E8392",
  marginBottom: "4px",
}));
