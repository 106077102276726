import { Dialog } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
interface Props {
  isShowing: boolean;
  onClose?: any;
  innerRef?: any;
  divStyle?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
  container?: any
}

const AppModel: React.FC<Props> = ({
  isShowing,
  onClose,
  innerRef,
  divStyle,
  children,
  container,
  ...otherProps
}) => {
  if (isShowing) {
    return (
      <StyledDialog
        open={isShowing}
        onClose={onClose ? onClose : undefined}
        {...otherProps}
        container={container}
        {...otherProps}
      >
        <StyledModelBox style={divStyle}  {...otherProps}>{children}</StyledModelBox>
      </StyledDialog>
    );
  }

  return null;
};

export default AppModel;

const StyledDialog = styled(Dialog)`
  && {
    small {
      cursor: default;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 30px;
      color: #7e8392;
    }

    .MuiDialogContent-root {
      padding: 1rem 1.5rem 0rem;
    }
  }
`;
const StyledModelBox = styled.div`
  && {
    padding: 1rem 1.5rem;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
  }
`;
