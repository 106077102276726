import { useEffect, useState } from "react";
import { ContentMainBox, SubscriptionPlan, SubscriptionPlanToggleButton, SubscriptionToggleButton } from "./style";
import { SubscriptionActions } from "../../Store/Slices/Subscriptions/subscriptions.actions";
import { useAppDispatch } from "../../Store";
import PlanCard from "./PlanCard";
import ContactUs from "./ContactUs";
import { useSelector } from "react-redux";
import { getAllPlansData } from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import NavigationBar from "./NavigationBar";
import { resetAllPlans } from "../../Store/Slices/Subscriptions/subscriptions.slice";
const DisplayPlans = () => {
    const dispatch = useAppDispatch()
    const [alignment, setAlignment] = useState('months');
    const getAllPlans = useSelector(getAllPlansData);
    useEffect(() => {
        // Get All Subscription Plan
        dispatch(resetAllPlans({}))
        dispatch(SubscriptionActions.getAllPlans(alignment))
    }, [alignment]) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <ContentMainBox>
            <NavigationBar list={["Subscription plan", "Plans"]} />
            <SubscriptionPlan height={"fit-content"}>
                {/* Toggle Monthly Yearly Plan */}
                <div style={{ textAlign: "center", margin: "1rem 2rem 3rem" }}>
                    <SubscriptionToggleButton
                        color="primary"
                        value={alignment}
                        defaultValue={alignment}
                        exclusive
                        onChange={(e: any, data: string) => {
                            data ? setAlignment(data) : setAlignment(alignment)
                        }}
                        aria-label="Platform"
                    >
                        <SubscriptionPlanToggleButton status={alignment === "months" ? true : false} value="months">Monthly</SubscriptionPlanToggleButton>
                        <SubscriptionPlanToggleButton status={alignment === "years" ? true : false} value="years">Yearly</SubscriptionPlanToggleButton>
                    </SubscriptionToggleButton>
                </div>
                {/* Display Plan Details */}
                <PlanCard list={getAllPlans} />
                <ContactUs />
            </SubscriptionPlan>
        </ContentMainBox>
    )
}
export default DisplayPlans