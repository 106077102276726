import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { format } from "date-fns";
import Table from "./Table";
import ExportConfirmation from "../../../../../Leads/contactDetailedComponents/ExportConfirmation";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../../../../HOC/HocBackdropLoader";
import { dateRange } from "../../../../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { setDashboardDateRange } from "../../../../../../Store/Slices/DashboardRedux/dashboard.slice";
import { DashboardActions } from "../../../../../../Store/Slices/DashboardRedux/dashboard.actions";
import AppRangeDatePicker from "../../../../../../AppComponents/AppRangeDatePicker";
import AppButton from "../../../../../../AppComponents/AppButton";
import { TopBar, WrapperContainer } from "../../HistoryStyles";
import { AppMaterialIcons } from "../../../../../../AppComponents/AppMaterialIcons";
import AppSkeletonTableLoader from "../../../../../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import AppPaginationComponent from "../../../../../../AppComponents/Pagination/Pagination";
import { broadcastActions } from "../../../../../../Store/Slices/BroadcastDashboard/BroadcastDash.actions";
import { useNotification } from "../../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../../Store";
interface Props extends HocBackdropLoaderProps {
}
const LogsTable: React.FC<Props> = ({ setLoading }) => {
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(dateRange);
  const [selectedComponent, setSelectedComponent] = useState<any>(null);
  const { broadcast_id } = useParams();
  const navigate = useNavigate();
  const [errorApi, setErrorApi] = useState(false);
  const [statsData, setStatsData] = useState<any>([]);
  const [pagination, setPagination] = useState<any>();
  const [whatsappTotalMessagesLogsData, setWhatsappTotalMessagesLogsData] = useState<any>()
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: { label: "50  per page", value: 50 },
    currentPage: 0,
    totalPages: 0,
  });
  let { showAlert } = useNotification();
  const getStatsData = () => {
    dispatch(
      broadcastActions.getBroadcastStatsData(broadcast_id)
    )
      .then((res: any) => {
        setStatsData(res?.payload?.data?.broadcast_stats);
      })
      .catch((error: any) => {
        setErrorApi(true);
        showAlert(
          error?.response?.statusText || "Unable to fetch data",
          "error"
        );
      })
      .finally(() => { });
  };

  const onChange = (date: any) => {
    let temp = { ...date };
    delete temp.label;
    dispatch(setDashboardDateRange(temp));
  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: value,
      currentPage: 0,
    });
  };

  const getLogs = () => {
    // setLoading(true)
    setWhatsappTotalMessagesLogsData({})
    let data = {
      "log_type": "broadcast_logs",
      "broadcast_id": broadcast_id,
      "start_date": format(dashdateRange.startDate, "yyyy-MM-dd"),
      "end_date": format(dashdateRange.endDate, "yyyy-MM-dd"),
      "limit": localValues?.itemsPerPage?.value,
      "offset": localValues?.currentPage,
    }
    dispatch(
      broadcastActions.getWhatsappLogs(data)
    )
      .then((res: any) => {
        // setLoading(false)
        setPagination(res?.payload?.data?.data?.pagination)
        setWhatsappTotalMessagesLogsData(res?.payload?.data?.data)
      })
      .catch((error: any) => { });
  }
  useEffect(() => {
    getLogs()
    getStatsData()
  }, [dashdateRange, localValues]) // eslint-disable-line react-hooks/exhaustive-deps



  const whatsappExportTemplateLogs = () => {
    const createPayload = () => {
            const rawPayload = {
                "broadcast_id": broadcast_id,
      "start_date": format(dashdateRange.startDate, "yyyy-MM-dd") ? format(dashdateRange.startDate, "yyyy-MM-dd") : null,
      "end_date": format(dashdateRange.endDate, "yyyy-MM-dd") ? format(dashdateRange.endDate, "yyyy-MM-dd") : null,
            };
        
            // Filter out any fields that have null or undefined values
            return Object.fromEntries(Object.entries(rawPayload).filter(([_, value]) => value != null));
          };
          const payload = createPayload();
    setLoading(true)    
    dispatch(DashboardActions.getExportWhatsappLogsAction(payload)).then((res: any) => {
      setLoading(false);
      if (res?.error?.message === "Rejected") {
        showAlert(res?.error?.message || "Contact not exported", "error");
      } else {
        setSelectedComponent(true);
      }
    })
      .catch((error) => {
        showAlert("something went wrong", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const closeModal = () => {
    setSelectedComponent(null);
  };

  return (
    <>
      <WrapperContainer style={{ height: "calc(100vh - 250px)" }}>
        <TopBar>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={1}>
            <IconButton onClick={() => navigate(-1)}>
              <AppMaterialIcons
                iconName={"ArrowBackIosRounded"}
                style={{ fontSize: "0.8rem", color: "black" }}
              />
            </IconButton>
            <Typography fontSize="13px" fontWeight="500" component="h6">
              WhatsApp Broadcast Logs
            </Typography>
          </Box>

          <Box display={"flex"} gap={1} justifyContent={"center"} alignItems={"center"}>
            <AppRangeDatePicker
              dateRange={dashdateRange}
              setDateRange={onChange}
            />
            {

              whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"]?.length ? <AppButton
                style={{ width: "20px" }}
                variant="outline"
                onClick={whatsappExportTemplateLogs}
                downloadFile
              >
                <FileUploadOutlinedIcon />
              </AppButton> : null

            }
          </Box>
        </TopBar>
        {whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"] ?
          <Table
            headers={whatsappTotalMessagesLogsData?.label_mapping ?? []}
            data={whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"] || []} />
          : <AppSkeletonTableLoader
            numberOfCol={4}
            numberofRow={10}
            skeletonHeight={30}
            skeletonWidth={"100%"}
            skeletonHeaderHeight={40}
            skeletonHeaderWidth={"100%"}
            tableHeight={`calc(100vh - ${335}px)`}
          />}
      </WrapperContainer>
      {whatsappTotalMessagesLogsData && whatsappTotalMessagesLogsData["logs"]?.length ? (
        <AppPaginationComponent
          totalCount={pagination?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={pagination?.totalPages}
          rowsPerPage={localValues?.itemsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          title="Contacts"
        />
      ) : null}


      <ExportConfirmation
        onClose={closeModal}
        selectedComponent={selectedComponent}
      />
    </>
  );
};

// Default Export of component
export default HocBackdropLoader(LogsTable);
