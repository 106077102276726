import React from "react";
import Select, { DropdownIndicatorProps, components } from "react-select";
import { theme } from "../../../Customization/Theme";
import { QueryBuilderCombinators } from "../utils/combinators";
import { getReactSelectCurrentValue } from "../utils/utils";

export const CustomQueryCombinatorSelect = (props: any) => {
  const [value, setValue] = React.useState<any>(QueryBuilderCombinators?.[0]);

  React.useEffect(() => {
    setValue(
      getReactSelectCurrentValue(props?.options, props?.value, "name", false),
    );
  }, [props?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCombinatorSelectChange = (selectedValue: any) => {
    props?.handleOnChange(selectedValue?.name);
  };

  let customName: string = "CustomQueryCombinatorSelect";

  return (
    <Select
      id={customName}
      options={QueryBuilderCombinators}
      value={value}
      onChange={(e: any) => handleCombinatorSelectChange(e)}
      placeholder={"Select"}
      getOptionLabel={(option: any) =>
        option?.label || option?.name || option?.value || option?.toString()
      }
      isOptionSelected={(option: any) =>
        option?.value || option?.name || option?.label || option?.toString()
      }
      getOptionValue={(option: any) => option}
      isMulti={false}
      isSearchable={true}
      isClearable={false}
      blurInputOnSelect={false}
      isLoading={typeof value === "string" ? true : false}
      menuPosition="fixed"
      noOptionsMessage={() => "No Options "}
      styles={customStyles}
      components={{ DropdownIndicator }}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        height="20"
        width="20"
        viewBox="0 0 20 20"
        aria-hidden="true"
        focusable="false"
        className="css-tj5bde-Svg"
      >
        <path d="M7 10l5 5 5-5z"></path>
      </svg>
    </components.DropdownIndicator>
  );
};

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "rgb(245, 246, 248) !important",
    minHeight: "32px !important",
    maxHeight: "32px !important",
    minWidth: "217px !important",
    maxWidth: "217px !important",
    boxShadow: "none !important",
    paddingBottom: "0px",
    borderColor: state?.isFocused ? "transparent" : "transparent",
    "&:hover": {
      borderColor: state?.isFocused ? "transparent" : "transparent",
    },
  }),
  menu: (base: any) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 2,
    // kill the gap
    marginTop: 4,
    backgroundColor: "rgb(245, 246, 248) !important",
  }),

  menuList: (base: any) => ({
    ...base,
    // kill the white space on first and last option
    backgroundColor: "white !important",
  }),
  option: (base: any, state: any) => ({
    ...base,
    backgroundColor: "transaparent !important",
    color: theme.palette.default.darkGrey,
    fontSize: "14px",

    "&:hover": {
      backgroundColor: "rgb(245, 246, 248) !important",
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "rgba(0, 0, 0, 0.54) !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "16px !important",
    padding: "0px !important",
    marginRight: "8px !important",
    svg: {
      fill: "rgba(0, 0, 0, 0.54) !important",
    },
  }),

  ValueEditor: (base: any) => ({
    ...base,
    backgroundColor: "red !important",
    fontSize: "23px",
  }),
  ".react-select__single-value": {
    backgroundColor: "red !important",
    fontSize: "19px",
  },
};
