import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class QueryBuilderNetworkService {
  // * query builder field options *//
  static getQueryBuilderFieldDynamicOptions(data: any) {
    const { id, search } = data;
    const searchPath = search ? `?search=${search}` : "";
    return http.get(
      `${consoleBaseUrl}${Endpoints.DYNAMIC_FIELD_OPTIONS}/${id}/options${searchPath}`
    );
  }
  // * query builder field options as per module name *//
  static getQueryBuilderFieldDynamicOptionsAsPerModuleName(data: any) {
    const { id, module_name, search } = data;
    const searchPath = search ? `?search=${search}` : "";
    return http.get(
      `${consoleBaseUrl}${Endpoints.DYNAMIC_FIELD_OPTIONS_MODULEWISE}/${module_name}/field/${id}/options${searchPath}`
    );
  }

  static getQueryBuilderMappingFields(data: any) {
    const { id } = data;
    const idPath = id ? `?list_id=${id}` : "";
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_FIELDS_TYPE_MAPPING_LIST}${idPath}`
    );
  }
}

class Endpoints {
  static DYNAMIC_FIELD_OPTIONS = "/workspace/contact-properties/field";
  static DYNAMIC_FIELD_OPTIONS_MODULEWISE = "/workspace/module-properties";
  
  
  //new endpoints for node microservice
  static GET_FIELDS_TYPE_MAPPING_LIST = "/workspace/broadcast/fields_type_mapping_list";
}
