import styled from "styled-components";
import { Toolbar } from "@mui/material";
import { theme } from "../../Customization/Theme";

export const CreateSegmentWrapper = styled.div`
  & {
    .ruleGroup {
      height: calc(100vh - 160px);
    }
    .form-heading {
      display: flex;
      justify-content: space-between;
      height: 56px;
      align-items: center;
      padding: 0rem 1rem 0rem 1rem;
      position: sticky;
      background-color: #fff;
      top: 0;
      z-index: 9;
    }

    .form-first__area {
      padding: 2rem 1.5rem 1rem 1.5rem;
    }
    .form-first_area_c {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .footer {
      padding: 2rem 0rem 0rem 0rem;
      .footer-btns {
        justify-content: end;
        gap: 1.3rem;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        padding-right: 1rem;
        .saveBtn {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;

export const StyledModelHeader = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    height: 56px;
    min-height: 56px;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    position: sticky;
    background-color: #fff;
    top: 0;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

    h6 {
      font-size: 1.05rem;
      color: ${theme.palette.default.text};
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }
    .footer {
    }
  }
`;
export const StyledModelFooter = styled.div`
  && {
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.08);

    .footer-btns {
      justify-content: end;
      gap: 1.3rem;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      padding-right: 1rem;
      .saveBtn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const StyledTableToolbar = styled(Toolbar)`
  && {
    width: 100%;

    padding: 0px 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: 8px;
    .top {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      width: 100%;
    }
    .bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      width: 100%;

      padding: 0 0rem 1rem;

      .toolbarText {
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 1rem;
        color: ${theme.palette.default.text};
        line-height: 20px;
        white-space: nowrap;
      }

      .displayFlex {
        display: flex;
      }

      .toolbarTextred {
        color: ${theme.palette.default.error} !important;
        padding-right: 1rem;
        font-weight: 500;
      }

      .toolbarTextgreen {
        color: ${theme.palette.default.success} !important;
        font-weight: 500;
      }
    }
  }
`;
