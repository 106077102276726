import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FastField, getIn, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import uniqid from "uniqid";
import { theme } from "../../../Customization/Theme";
import { useAppSelector } from "../../../Store";
import { marketingListStore } from "../../../Store/Slices/MarketingList/marketingList.selectors";
import { AppMaterialIcons } from "../../AppMaterialIcons";
import { AppFormFieldArrayFastField } from "../../Forms";

interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
}

const RenderFormStep3: React.FC<Props> = ({ formStep, setFormStep }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { uploadDocumentFileData } = useAppSelector(marketingListStore);
  const formkey12 = "upload_file";

  const [alreadySelectedOptions, setAlreadySelectedOptions] = useState<any>(
    Object.values(getIn(values, formkey12)?.mapping) || []
  );

  useEffect(() => {
    setAlreadySelectedOptions(Object.values(getIn(values, formkey12)?.mapping));
  }, [getIn(values, formkey12)?.mapping]);  // eslint-disable-line react-hooks/exhaustive-deps
  if (formStep !== 3) {
    return null;
  } 
  return (
    <Box className="crm-field__area">
      <Box className="crm-field__header" sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={4} className="field-header__text">
            <Box>
              <Typography variant="h6">File Column Name</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="field-header__text">
            <Box>
              <Typography variant="h6">Predefined Fields</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className="field-header__text">
            <Box display="flex" justifyContent="center">
              <Typography variant="h6" style={{ paddingLeft: 0 }}>
                Mapped
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {uploadDocumentFileData?.mapping &&
        uploadDocumentFileData?.csv_sample_hash ? (
        <AppFormFieldArrayFastField
          name={formkey12}
          render={(arrayHelpers: any, value: any[]) => (
            uploadDocumentFileData?.csv_sample_hash?.map((item: any) => {
              const fieldName: string = `${formkey12}.mapping[${item?.index}]`;
              return (
                <Box key={uniqid()}>
                  <Box sx={{ flexGrow: 1 }} className="fieldto-map__row">
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={12} md={4}>
                        <Typography variant="subtitle1">
                          {item?.header}
                        </Typography>
                        <Typography variant="body2">
                          {item?.example}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        {uploadDocumentFileData?.fields?.length > 0 && (
                          <div style={{ display: "flex", gap: "1rem" }}>
                            <FastField
                              name={fieldName}
                              de
                              placeholder="Select a field"
                            >
                              {({ field, form, meta }: any) => (
                                <Select
                                  {...field}
                                  defaultValue={0}
                                  style={{ marginRight: "0" }}
                                  value={getIn(values, fieldName) || 0}
                                >
                                  <MenuItem value={0} disabled={true}>
                                    {"Select a field"}
                                  </MenuItem>

                                      {uploadDocumentFileData?.fields?.map(
                                        (curOpt: any, index: number) => {
                                          return (
                                            <MenuItem
                                              value={curOpt?.name}
                                              key={index.toString()}
                                              disabled={
                                                alreadySelectedOptions?.includes(
                                                  curOpt?.name
                                                )
                                                  ? true
                                                  : false
                                              }
                                            >
                                              {curOpt?.label}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  )}
                                </FastField>
                                {
                                  getIn(values,fieldName) === 0 ? null :  <IconButton
                                  onClick={() => setFieldValue(fieldName, '')}
                                >
                                  <AppMaterialIcons
                                    iconName={"Close"}
                                    style={{ fontSize: "1rem" }}
                                  />
                                </IconButton>
                                }
                               
                              </div>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box>
                              <IconButton
                                style={{
                                  backgroundColor: getIn(values, fieldName)
                                    ? theme.palette.default.success
                                    : theme.palette.default.error,
                                  width: "24px",
                                  height: "24px",
                                }}
                              >
                                <AppMaterialIcons
                                  iconName={
                                    getIn(values, fieldName) ? "Check" : "Close"
                                  }
                                  style={{
                                    fontSize: "1rem",
                                    color: theme.palette.default.white,
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Divider />
                    </Box>
                  )
               
                                }))}
           
          />
        ) : null}
      </Box>
  
  );
};

export default RenderFormStep3;
