import { Box, Button, Card, CardContent, CardMedia, Grid, IconButton } from "@mui/material";
import React from "react";
import AppButton from "../../../../../../AppComponents/AppButton";
import StyledCard from "../../../../../UiComponents/Card/StyledCard";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import MediaError from '../../../Assets/MediaError.svg';
import { StyledListCard } from "./ListCardCss";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

interface props {
  key?: number;
  curItem?: any;
  handleOpenUrlFn?: any;
  handleEditButton?: any;
  handleDeleteButton?: any;
  isEditing?: boolean;
  hideActionBtn?: boolean;
}

const QuickListCard: React.FC<props> = ({
  key = "",
  curItem = "",
  handleOpenUrlFn = "",
  handleEditButton = "",
  handleDeleteButton = "",
    isEditing = false,
  hideActionBtn = false,
}) => {
  return (
    <StyledListCard key={key} style={hideActionBtn ? {pointerEvents:"none"} : {}}>
      <StyledCard divStyle={{ display: "flex" }}>

        <div className="list-notification__wrapper">
          <img
            className="notification-image"
            src={curItem?.image?.source || MediaError}
            alt="list"
            title={curItem?.image?.source ? "Menu Image" : "Media Not Found"}
          />
          <div className="notification-text">
            {curItem?.journey?.main_flow?.name ? (
              <Button variant="text">{curItem?.journey?.main_flow?.name}</Button>
            ) : (
              <Button onClick={() => handleOpenUrlFn(curItem?.button_url)} variant="text">
                {curItem?.button_title}
              </Button>
            )}
          </div>
          {
            hideActionBtn ? null : ( <Box
            className="action-icons"
          >
            <IconButton onClick={() => handleEditButton(curItem)}>
              <EditOutlinedIcon />
            </IconButton>
            <IconButton disabled={isEditing} onClick={() => handleDeleteButton(curItem)}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Box>)
          }
         
        </div>
      </StyledCard>
    </StyledListCard>
  );
};

export default QuickListCard;
