import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import AppButton from "../../../../AppComponents/AppButton";
import {
  AppPageCaption,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox,
} from "../../../../Customization/CommonElements";
import { theme } from "../../../../Customization/Theme";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotTrackingCode } from "../../../../Models";
import { getBotTrackingCodeFn } from "../../../../Services/Apis";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const WebsiteDeployScript = (): JSX.Element => {
  const [trackingCode, setTrackingCode] = useState<ChatbotTrackingCode>();

  const authData = useSelector(useAuthStore);

  const currentBot = useSelector(useCurrentBot);
  const dispatch = useDispatch();
  const { showAlert } = useNotification();
  const myRef = useRef<HTMLPreElement>(null);

  useEffect(() => {
    if (currentBot?.id) {
      getBotTrackingCodeFn(dispatch)({
        pathParams: [currentBot?.id + ""],
      }).then((res: ChatbotTrackingCode) => {
        setTrackingCode(res);
      });
    }
  }, [authData, currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  const CopyTextToClipboard = async () => {
    let temp: any = myRef.current?.innerText;
    await navigator.clipboard.writeText(temp);
    showAlert("Script copied to clipboard", "success");
  };

  const IFRAMEURL: string =
    process.env.REACT_APP_IFRAME_SCRIPT_BASE_URL || "http://localhost:8082";

  return (
    <SettingPageMainBoxWrap>
      <SettingPageMainBoxTopbar>
        <SettingPageMainBoxTitle>Website Script</SettingPageMainBoxTitle>
      </SettingPageMainBoxTopbar>
      <AppPageCaption
        style={{
          marginTop: "-1.2rem",
          marginBottom: "1rem",
          marginLeft: "1.5rem",
        }}
      >
        Copy the code snippet below, and paste it into the Head tag on page of
        your website
      </AppPageCaption>

      <SettingPageMainContentBox
        style={{ alignItems: "start", gap: "1rem", padding: "1rem" }}
      >
        <CodeDiv>
          <CopyBtnBox>
            <AppButton
              variant="outline"
              onClick={() => CopyTextToClipboard()}
              endIcon="ri-file-copy-line"
            >
              Copy
            </AppButton>
          </CopyBtnBox>
          <StyledPre ref={myRef}>
            {`
              <!-- Geta.ai script -->

              <script>
              (function(w, d, s) {
                  var f = d.getElementsByTagName(s)[0],
                      j = d.createElement(s);
                  j.async = true;
                  j.onload = function() {
                      var geta = window.GetA('${trackingCode?.geta_host}', ${trackingCode?.matomo_site_id}, "${trackingCode?.bot_id}");
                  }
                  j.src =
                      '${IFRAMEURL}/output.js'
                  f.parentNode.insertBefore(j, f);

              })(window, document, 'script');
              </script>            
             <!-- Geta.ai script -->
              `}
          </StyledPre>
        </CodeDiv>
      </SettingPageMainContentBox>
    </SettingPageMainBoxWrap>
  );
};

export default WebsiteDeployScript;

const CodeDiv = styled.div`
  && {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: ${theme.palette.secondary.main};
    overflow: auto;
    width: 60%;
    height: 100%;
    margin-right: 1rem;
    border-radius: 4px;
    position: relative;
  }
`;
const CopyBtnBox = styled.div`
  && {
    position: absolute;
    right: 2%;
    top: 2%;
  }
`;

const StyledPre = styled.pre`
  && {
    color: ${theme.palette.default.text};
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    font-family: ${theme.typography.fontFamily};
    width: 100%;
    height: 100%;
    overflow: auto;
  }
`;
