import { useEffect, useState } from "react";
import { ContactPropertiesActions } from "../../../Store/Slices/Settings/ContactProperties/contactProperties.action";
import { useAppDispatch } from "../../../Store";
import {
  GetContactPropertices,
  getAddUpdateSectionModel,
  getAllHiddenField,
  getAllSectionDetails,
  getDeleteModelAction,
  getLevel,
  getListProperties,
  getSelectedFieldData,
  getSelectedSectionData,
} from "../../../Store/Slices/Settings/ContactProperties/contactProperties.selector";
import { useSelector } from "react-redux";
import { Card, Drawer, Grid, Typography } from "@mui/material";
import AppButton from "../../../AppComponents/AppButton";
import styled from "styled-components";
import AddSection from "./AddSection";
import { AppForm } from "../../../AppComponents/Forms";
import AppModel from "../../../Hooks/useModel/AppModel";
import ContactPropertiesList from "./ContactPropertiesList";
import { AddSectionValidation } from "../../../Models/ValidationSchema";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { getSectionInitialValues } from "./formkeys";
import {
  setAddUpdateSectionModel,
  setDeleteModel,
  setSelectedSectionData,
} from "../../../Store/Slices/Settings/ContactProperties/contactProperties.slice";
import DeleteModel from "./AppModel/DeleteModel";
import CustomField from "./CustomField";
import EditSection from "./EditField";
import DefaultField from "./DefaultField";
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../Customization/Theme";
import { editPayload } from "../../Leads/contactDetailedComponents/FieldsLogic/FieldsLogic";

const ContactProperties = () => {
  const dispatch = useAppDispatch();
  const { showAlert, clearAlert } = useNotification();
  let navigate = useNavigate();
  var childArray: any = [];

  const getListPropertiesData = useSelector(getListProperties);
  const selectedSectionData = useSelector(getSelectedSectionData);
  const selectedFieldData = useSelector(getSelectedFieldData);
  const deleteModel = useSelector(getDeleteModelAction);
  const sectionModel = useSelector(getAddUpdateSectionModel);
  const allSectionDetails = useSelector(getAllSectionDetails);
  const hiddenFields = useSelector(getAllHiddenField);
  const properticesList = useSelector(GetContactPropertices);
  const level = useSelector(getLevel);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<any>([]);
  const [defaultList, setDefaultList] = useState<any>([]);
  const [fieldProperties, setFieldProperties] = useState();
  const [action, setAction] = useState<string>("");
  const [onFocusSectionFieldId, setOnFocusSectionFieldId] = useState("");
  const [status, setStatus] = useState(false);
  const [sectionInitialValues, setSectionInitialValues] = useState(
    getSectionInitialValues(),
  );
  useEffect(() => {
    dispatch(ContactPropertiesActions.getListProperties({}));
    dispatch(ContactPropertiesActions.GetAllSectionDetails({}));
    dispatch(ContactPropertiesActions.GetHiddenField({}));
    dispatch(setAddUpdateSectionModel(false));
    dispatch(setSelectedSectionData(null));
  }, []);
  // Function to create a parent-child relationship
  const createParentChildRelationship = (originalArray: any) => {
    const resultMap = new Map();

    originalArray.forEach((field: any, index: any) => {
      const parentId = field.is_dependant ? field.dependant_field_id : null;

      if (!resultMap.has(parentId)) {
        resultMap.set(parentId, []);
      }

      resultMap.get(parentId).push(field);
    });

    return resultMap;
  };

  // Function to recursively build the hierarchy
  const buildHierarchy = (parent: any, resultMap: any) => {
    let data = { ...parent };
    const children = resultMap.get(data.id) || [];

    if (children.length > 0) {
      data.children = children.map((child: any) =>
        buildHierarchy(child, resultMap),
      );
    }

    return data;
  };

  const createParentChildRelationshipData = (propertiesData: any) => {
    var finalArray: any = [];
    let data = [...propertiesData];
    Object.preventExtensions(propertiesData);
    data.map((res: any) => {
      let sectionData = { ...res };
      const resultMap = createParentChildRelationship(res.fields);
      const hierarchy = buildHierarchy(
        { id: null, children: resultMap.get(null) },
        resultMap,
      ).children;
      let fieldArray = hierarchy?.map((field: any, index: any) => {
        field.position = index + 1;
        return field;
      });
      sectionData["fields"] = fieldArray;
      finalArray.push(sectionData);
    });
    return finalArray;
  };
  useEffect(() => {
    if (getListPropertiesData) {
      // to do action
      setFieldProperties(
        createParentChildRelationshipData(getListPropertiesData),
      );
    }
  }, [getListPropertiesData]);
  useEffect(() => {
    if (selectedSectionData !== null && !deleteModel) {
      setSectionInitialValues(selectedSectionData);
      dispatch(setAddUpdateSectionModel(true));
    }
  }, [selectedSectionData]);
  const closeModel = () => {
    dispatch(setSelectedSectionData(null));
    dispatch(setAddUpdateSectionModel(false));
    setSectionInitialValues(getSectionInitialValues());
  };
  const handleSubmitBtn = async (values: any, submitProps: any) => {
    if (action === "Add_Section" && values?.label) {
      let editSectionData = {
        ...values,
        parent: values?.parent?.id,
      };
      let data: any;
      if (selectedSectionData) {
        let payloadDaya = await editPayload(
          editSectionData,
          selectedSectionData,
        );

        data = {
          ...payloadDaya,
          id: values?.id,
          update: selectedSectionData ? true : false,
          parent: values?.parent?.id || values?.parent_id,
        };
      } else {
        data = {
          ...values,
          update: selectedSectionData ? true : false,
        };
      }
      dispatch(ContactPropertiesActions.AddContactPropertiesSection(data))
        .then((res: any) => {
          if (res?.payload?.data.status === 200) {
            showAlert(res?.payload?.data?.message, "success");
            dispatch(setAddUpdateSectionModel(false));
            dispatch(ContactPropertiesActions.getListProperties({}));
          } else {
            showAlert(
              res?.payload?.data?.message || "Something went wrong",
              "error",
            );
          }
        })
        .catch((err: any) => {})
        .finally(() => {
          submitProps.setSubmitting(false);
        });
    }
  };
  const handleEditSubmit = (values: any, submitProps: any) => {
    let updatedArray = values.options.map((obj: any, index: any) =>
      obj.id && obj.label !== "" ? { ...obj, position: index + 1 } : obj,
    );
    let updatedOptionArray = updatedArray.map((obj: any, index: any) =>
      obj.new && obj.label !== "" ? { ...obj, id: undefined } : obj,
    );
    let data = {
      ...values,
      options: updatedOptionArray?.filter((res: any) => res.label !== ""),
      remove_options_ids: values?.removeIds,
      parent_id: values?.parent[0]?.id || values?.parent?.id,
    };
    dispatch(ContactPropertiesActions.UpdateContactProperties(data))
      .then((res: any) => {
        if (res?.payload?.data.status === 200) {
          showAlert(res?.payload?.data?.message, "success");
          dispatch(setAddUpdateSectionModel(false));
          dispatch(ContactPropertiesActions.getListProperties({}));
        } else {
          showAlert(
            res?.payload?.data?.message || "Something went wrong",
            "error",
          );
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        submitProps.setSubmitting(false);
      });
  };
  const handleAddCustomField = (values: any, submitProps: any) => {
    let data = {
      ...values,
      options: values?.options?.filter((res: any) => res.label !== ""),
      parent: values?.parent?.id,
    };
    dispatch(ContactPropertiesActions.AddContactPropertiesFields(data))
      .then((res: any) => {
        if (res?.payload?.data.status === 200) {
          showAlert(res?.payload?.data?.message, "success");
          dispatch(setAddUpdateSectionModel(false));
          dispatch(ContactPropertiesActions.getListProperties({}));
        } else {
          showAlert(res?.payload?.data?.message, "error");
        }
      })
      .catch((err: any) => {
        // console.log("error while creating attachment", err);
      })
      .finally(() => {
        submitProps.setSubmitting(false);
      });
  };
  // Delete Section
  const deleteSection = () => {
    if (level === "Section") {
      let data = {
        section: selectedSectionData?.id,
      };
      dispatch(ContactPropertiesActions.DeleteContactPropertiesSection(data))
        .then((res: any) => {
          if (res?.payload?.data.status === 200) {
            showAlert(res?.payload?.data?.data, "success");
            dispatch(setDeleteModel(false));
            dispatch(ContactPropertiesActions.getListProperties({}));
          } else {
            showAlert(res?.payload?.data?.data, "error");
          }
        })
        .catch((err: any) => {})
        .finally(() => {});
    } else {
      let data = {
        field: selectedFieldData?.id,
      };
      dispatch(ContactPropertiesActions.DeleteContactPropertiesField(data))
        .then((res: any) => {
          if (res?.payload?.data.status === 200) {
            showAlert(res?.payload?.data?.message || "Field Deleted", "success");
            dispatch(setDeleteModel(false));
            dispatch(ContactPropertiesActions.getListProperties({}));
          } else {
            showAlert(res?.payload?.data?.data, "error");
          }
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  };
  const reorderFields = (id: any, action: any) => {
    let data = {
      id: id,
      action: action,
    };
    dispatch(ContactPropertiesActions.reorderFieldPosition(data))
      .then((res: any) => {
        if (
          res?.payload?.data?.message !== "" &&
          res?.payload?.status === 200
        ) {
          showAlert(res?.payload?.data?.message, "success");
          setFieldProperties(
            createParentChildRelationshipData(res?.payload?.data?.data),
          );
          dispatch(setAddUpdateSectionModel(false));
          // dispatch(ContactPropertiesActions.getListProperties({}))
        } else {
          showAlert(
            res?.payload?.data?.message || res?.payload?.data?.error,
            "error",
          );
        }
      })
      .catch((err: any) => {
        // console.log("error while creating attachment", err);
      })
      .finally(() => {});
  };
  const setOnClickAction = (action: string) => {
    setAction(action);
    dispatch(setAddUpdateSectionModel(true));
  };
  const handleDefaultFields = (values: any, submitProps: any) => {
    if (defaultList.length !== 0) {
      let data = {
        fields: defaultList,
        section: selectedSectionData?.id,
      };
      dispatch(ContactPropertiesActions.UpdateHiddenField(data))
        .then((res: any) => {
          if (res?.payload?.data.status === 200) {
            showAlert(res?.payload?.data?.message, "success");
            dispatch(setAddUpdateSectionModel(false));
            dispatch(ContactPropertiesActions.getListProperties({}));
            dispatch(ContactPropertiesActions.GetHiddenField({}));
          } else {
            showAlert(
              res?.payload?.data?.message || "Something went wrong",
              "error",
            );
          }
        })
        .catch((err: any) => {})
        .finally(() => {
          submitProps.setSubmitting(false);
        });
    } else {
      dispatch(setAddUpdateSectionModel(false));
    }
  };
  const setDefaultField = (field: any) => {
    const newChecked = [...defaultList];
    const currentIndex = newChecked.filter(
      (item: any, index: any) => item !== field.id,
    );
    const isPresent = newChecked.filter(
      (item: any, index: any) => item === field.id,
    );
    if (isPresent.length > 0) {
      setDefaultList(currentIndex);
    } else {
      newChecked.push(field.id);
      setDefaultList(newChecked);
    }
  };
  const updateField = (field: any, fieldKey: any, value?: any) => {
    let status = field?.is_dependant
      ? field?.is_dependant
      : field?.system_default
      ? field?.system_default
      : field?.name === "lost_reason"
      ? true
      : field?.name === "other_lost_reason"
      ? true
      : false;
    if (!status) {
      let data = { ...field };
      data[fieldKey] = !data[fieldKey];
      setOnFocusSectionFieldId("");
      dispatch(ContactPropertiesActions.UpdateContactProperties(data))
        .then((res: any) => {
          if (res?.payload?.data.status === 200) {
            dispatch(setAddUpdateSectionModel(false));
            setOnFocusSectionFieldId(res?.payload?.data.id);
            setFieldProperties(
              createParentChildRelationshipData(res?.payload?.data?.data),
            );
            // dispatch(ContactPropertiesActions.getListProperties({}))
            dispatch(ContactPropertiesActions.GetHiddenField({}));
            showAlert("Field Updated", "success");
          } else {
            showAlert(
              res?.payload?.data?.message || "Something went wrong",
              "error",
            );
          }
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  };
  const redirectToDependencyList = () => {
    navigate(
      "/settings/data-management/contact-properties/field-dependency-list",
    );
  };
  const recoredField = (id: any, data: any) => {
    let fields: any = [];
    data?.map((res: any, index: any) => {
      if (res?.children) {
        childArray = [];
        const flattenedData = flattenFields(res);
        fields.push(...flattenedData);
      } else {
        fields.push(res);
      }
    });
    //update data
    let payloadArray: any[] = [];
    let finalProperticesData = fields.map((item: any, index: any) => {
      return payloadArray.push({
        position: index + 1,
        id: item?.id,
      });
    });
    let payload = {
      section: id,
      fields: payloadArray,
    };
    dispatch(ContactPropertiesActions.changeFieldPosition(payload))
      .then((res: any) => {
        if (res?.payload?.data.status === 200) {
          showAlert(res?.payload?.data?.message, "success");
          dispatch(ContactPropertiesActions.GetHiddenField({}));
        } else {
          showAlert(
            res?.payload?.data?.message || "Something went wrong",
            "error",
          );
        }
      })
      .catch((err: any) => {})
      .finally(() => {});
  };
  function flattenFields(field: any) {
    const flattenedField = { ...field };
    childArray.push(field);
    if (flattenedField.children && flattenedField.children.length > 0) {
      flattenedField.children.forEach((child: any) => {
        const flattenedChild = flattenFields(child);
        Object.assign(flattenedField, flattenedChild);
      });
    }

    return childArray;
  }
  const hideUnhideField = (field: any, action: string) => {
    let data = {
      field: field?.id,
      action: action,
    };
    dispatch(ContactPropertiesActions.HideContactProperties(data))
      .then((res: any) => {
        if (res?.payload?.data.status === 200) {
          showAlert(res?.payload?.data?.message, "success");
          dispatch(ContactPropertiesActions.getListProperties({}));
          dispatch(ContactPropertiesActions.GetHiddenField({}));
        } else {
          showAlert(res?.payload?.data?.data, "error");
        }
      })
      .catch((err: any) => {})
      .finally(() => {});
  };
  return (
    <>
      <ContactTitle>
        <Typography variant="h6">Contact Properties</Typography>
        <Grid
          item
          position="-moz-initial"
          xs={6}
          style={{
            position: "absolute",
            right: "21px",
            display: "flex",
            gap: "10px",
          }}
        >
          <AppButton
            variant="outline"
            startIcon={"ri-add-circle-line"}
            iconSize={"150%"}
            onClick={() => {
              dispatch(setAddUpdateSectionModel(true));
              setAction("Add_Section");
              setStatus(true);
            }}
          >
            {" "}
            Add Section{" "}
          </AppButton>
          <AppButton
            variant="primarydark"
            onClick={() => redirectToDependencyList()}
          >
            <AppMaterialIcons iconName="Add" /> Dependency Field{" "}
          </AppButton>
        </Grid>
      </ContactTitle>
      <ContactContainer>
        <ContactPropertiesList
          list={fieldProperties}
          setAction={setOnClickAction}
          reorderFields={reorderFields}
          hideUnhideField={hideUnhideField}
          updateField={updateField}
          onFocusSectionFieldId={onFocusSectionFieldId}
          recoredField={recoredField}
        />
      </ContactContainer>
      <Drawer
        anchor={"right"}
        open={sectionModel}
        onClose={() => {}}
        sx={{
          "& .MuiPaper-root": {
            width:
              action == "Add_Section" ||
              action == "Edit_Field" ||
              action == "Default_Field"
                ? "45%"
                : "70%",
            minWidth: "600px",
          },
        }}
      >
        <div>
          {action == "Add_Section" ? (
            <AppForm
              initialValues={
                selectedSectionData ? selectedSectionData : sectionInitialValues
              }
              onSubmit={(values: any, submitProps: any) => {
                handleSubmitBtn(values, submitProps);
              }}
              validationSchema={AddSectionValidation}
              enableReinitialize={true}
            >
              {action == "Add_Section" && (
                <AddSection
                  listData={fieldProperties}
                  closeModel={closeModel}
                />
              )}
            </AppForm>
          ) : action == "Add_Custom_Field" ? (
            <AppForm
              initialValues={{
                parent: selectedSectionData ? selectedSectionData : "",
                options: [
                  { id: 1, label: "", position: 1 },
                  { id: 2, label: "", position: 2 },
                ],
                type: "text",
                visible: true,
              }}
              onSubmit={(values: any, submitProps: any) => {
                handleAddCustomField(values, submitProps);
              }}
              validationSchema={AddSectionValidation}
              enableReinitialize={false}
            >
              {action == "Add_Custom_Field" && (
                <CustomField
                  listData={fieldProperties}
                  closeModel={closeModel}
                  allSectionDetails={allSectionDetails}
                ></CustomField>
              )}
            </AppForm>
          ) : action == "Edit_Field" ? (
            <AppForm
              initialValues={
                selectedFieldData
                  ? selectedFieldData
                  : {
                      options: [
                        { id: 1, label: "", position: 1 },
                        { id: 2, label: "", position: 2 },
                      ],
                      type: "text",
                    }
              }
              onSubmit={(values: any, submitProps: any) => {
                handleEditSubmit(values, submitProps);
              }}
              validationSchema={AddSectionValidation}
              enableReinitialize={true}
            >
              {action == "Edit_Field" && (
                <EditSection
                  listData={fieldProperties}
                  allSectionDetails={allSectionDetails}
                  closeModel={closeModel}
                />
              )}
            </AppForm>
          ) : (
            <AppForm
              initialValues={{
                defaultFields: hiddenFields,
              }}
              onSubmit={(values: any, submitProps: any) => {
                handleDefaultFields(values, submitProps);
              }}
              enableReinitialize={true}
            >
              {action == "Default_Field" && (
                <DefaultField
                  setDefaultField={setDefaultField}
                  setDefaultList={setDefaultList}
                  closeModel={closeModel}
                />
              )}
            </AppForm>
          )}
        </div>
      </Drawer>
      <DeleteModel
        deleteModel={deleteModel}
        setDeleteModel={setDeleteModel}
        deleteSection={() => deleteSection()}
      />
    </>
  );
};
const ContactTitle = styled(Card)<{}>`
  padding: 15px 25px 15px 25px;
  font-weight: 600;
  font-size: 20px;
  font-style: normal;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    font-size: 18px;
  }
`;
const ContactContainer = styled(Card)<{}>`
  && {
    height: calc(100vh - 157px);
    padding: 0.8rem;
    margin: 15px;
    overflow-y: auto;
    scroll-behavior: auto;
    background-color: transperant;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  // margin:0px 20px 20px 20px;
  // height: -webkit-fill-available;
  // overflow: scroll !important;
`;
export default ContactProperties;
