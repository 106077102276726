import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FastField, getIn, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import uniqid from "uniqid";

import { theme } from "../../../../../../Customization/Theme";
import { useAppSelector } from "../../../../../../Store";
import { AppMaterialIcons } from "../../../../../../AppComponents/AppMaterialIcons";
import { AppFormFieldArrayFastField } from "../../../../../../AppComponents/Forms";
import { importFormStepOneRes } from "../../../../../../Store/Slices/MarketingList/marketingList.selectors";

interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
}

const RenderImportRecordsFormStep3: React.FC<Props> = ({}) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const uploadDocumentFileData = useAppSelector(importFormStepOneRes);
  const formkey12 = "f_mapping";

  const [alreadySelectedOptions, setAlreadySelectedOptions] = useState<any>();

  useEffect(() => {
    if (uploadDocumentFileData) {
      setAlreadySelectedOptions(
        Object?.values(uploadDocumentFileData?.mapping)
      );
    }
  }, [uploadDocumentFileData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className="step-three__wrap">
      <Box className="crm-field__area">
        <Box
          className="crm-field__header"
          sx={{
            flexGrow: 1,
            backgroundColor: `${theme.palette.default.lightGrey}`,
            p: 1,
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={5} sm={5} md={4} className="field-header__text">
              <Box>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  File Column Name
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5} sm={5} md={4} className="field-header__text">
              <Box>
                <Typography variant="body1" style={{ fontWeight: 500 }}>
                  Predefined Fields
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sm={2} md={4} className="field-header__text">
              <Box display="flex" justifyContent="center">
                <Typography
                  variant="body1"
                  style={{ paddingLeft: 0, fontWeight: 500 }}
                >
                  Mapped
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {uploadDocumentFileData?.mapping &&
        uploadDocumentFileData?.csv_sample_hash ? (
          <AppFormFieldArrayFastField
            name={formkey12}
            render={(arrayHelpers: any, value: any[]) =>
              uploadDocumentFileData?.csv_sample_hash?.map((item: any) => {
                const fieldName: string = `${formkey12}.mapping.${item?.header}`;
                return (
                  <Box key={uniqid()}>
                    <Box
                      sx={{ flexGrow: 1, mb: 1, mt: 1, p: 1 }}
                      className="fieldto-map__row"
                    >
                      <Grid container spacing={0}>
                        <Grid item xs={5} sm={5} md={4}>
                          <Typography variant="subtitle1">
                            {item?.header}
                          </Typography>
                          <Typography variant="body2">
                            {item?.example}
                          </Typography>
                        </Grid>
                        <Grid item xs={5} sm={5} md={4}>
                          {uploadDocumentFileData?.fields?.length > 0 && (
                            <div style={{ display: "flex", gap: "1rem" }}>
                              <FastField
                                name={fieldName}
                                placeholder="Select a field"
                              >
                                {({ field, form, meta }: any) => (
                                  <Select
                                    {...field}
                                    defaultValue={0}
                                    style={{
                                      marginRight: "0",
                                      maxWidth: "220px",
                                      minWidth: "220px",
                                    }}
                                    value={getIn(values, fieldName) || 0}
                                    onChange={(event) => {
                                      const selectedValue = event.target.value;
                                      const previouslySelectedValue = getIn(
                                        values,
                                        fieldName
                                      );

                                      // Remove the previously selected value from alreadySelectedOptions
                                      if (previouslySelectedValue !== 0) {
                                        setAlreadySelectedOptions(
                                          (prevOptions: any) =>
                                            prevOptions.filter(
                                              (option: any) =>
                                                option !==
                                                previouslySelectedValue
                                            )
                                        );
                                      }

                                      // Update the selected value in form values
                                      setFieldValue(fieldName, selectedValue);

                                      if (selectedValue !== 0) {
                                        // Add the newly selected value to alreadySelectedOptions
                                        setAlreadySelectedOptions(
                                          (prevOptions: any) => [
                                            ...prevOptions,
                                            selectedValue,
                                          ]
                                        );
                                      }
                                    }}
                                  >
                                    <MenuItem value={0} disabled={true}>
                                      {"Select a field"}
                                    </MenuItem>

                                    {uploadDocumentFileData?.fields?.map(
                                      (curOpt: any, index: number) => {
                                        return (
                                          <MenuItem
                                            value={curOpt?.name}
                                            key={index.toString()}
                                            disabled={
                                              alreadySelectedOptions?.includes(
                                                curOpt?.name
                                              )
                                                ? true
                                                : false
                                            }
                                          >
                                            {curOpt?.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Select>
                                )}
                              </FastField>

                              {getIn(values, fieldName) === 0 ? null : (
                                <IconButton
                                  onClick={() => {
                                    const selectedValue = getIn(
                                      values,
                                      fieldName
                                    );
                                    if (selectedValue !== 0) {
                                      const updatedSelectedOptions =
                                        alreadySelectedOptions.filter(
                                          (option: any) =>
                                            option !== selectedValue
                                        );
                                      setAlreadySelectedOptions(
                                        updatedSelectedOptions
                                      );

                                      setFieldValue(fieldName, "");
                                    }
                                  }}
                                >
                                  <AppMaterialIcons
                                    iconName={"Close"}
                                    style={{ fontSize: "1rem" }}
                                  />
                                </IconButton>
                              )}
                            </div>
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          sm={2}
                          md={4}
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box>
                            <IconButton
                              style={{
                                backgroundColor: getIn(values, fieldName)
                                  ? theme.palette.default.success
                                  : theme.palette.default.error,
                                width: "24px",
                                height: "24px",
                              }}
                            >
                              <AppMaterialIcons
                                iconName={
                                  getIn(values, fieldName) ? "Check" : "Close"
                                }
                                style={{
                                  fontSize: "1rem",
                                  color: theme.palette.default.white,
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                  </Box>
                );
              })
            }
          />
        ) : null}
      </Box>
    </Box>
  );
};

export default RenderImportRecordsFormStep3;
