import React from "react";
import scanning from "../../../../../assets/animations/dataLoader.gif";

import { Box, Typography } from "@mui/material";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";

const tableHeaderStyles = {
  pt: "0.5rem",
  pb: "0.5rem",
  color: "#000",
  fontSize: "14px",
  fontWeight: "400",
  background: "#7E83921A",
};
const loadingImage = {
  width: "100%",
  display: "flex",
  gap: "1rem",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  alignContent: "center",
  pt: "3rem",
  pb: "3rem",
};
interface Props extends LoaderComponentProps {}

const FaqLoader: React.FC<Props> = ({ setLoading }) => {
  return (
    <Box>
      {/* <Box sx={loadingImage}>
        <img
          width={50}
          src={scanning}
          alt="Crawling in progress"
          aria-label="Crawling in progress"
        />
        <Typography>Crawling link...</Typography>
      </Box> */}
    </Box>
  );
};

export default withLoader(FaqLoader);
