import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Menu
} from "@mui/material";
import React, { memo } from "react";
import { AppForm, AppSubmitButton } from "../../../../../AppComponents/Forms";
import { SelectActionsWrapper } from "./SelectActionsStyle";

import uniqid from "uniqid";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { ChatNetworkService } from "../../../../../Store/Slices/socket/liveChat/ChatNetworkService";
import { LiveChatActions } from "../../../../../Store/Slices/socket/liveChat/chat.actions";
import { LiveChatData } from "../../../../../Store/Slices/socket/liveChat/chat.selectors";
import { setAllBulkSelectedSessions } from "../../../../../Store/Slices/socket/liveChat/chat.slice";
import { AppMaterialIcons } from "../../../../appComponents/Icons";
import AppReactSelectField from "../../../../appComponents/UtilsComponents/AppFormReactSelect";
import {
  convertArrayToStringArray,
  getChatSessionFilterPayloadData,
} from "../../../../utils/utils";
import { chatFilterStatusOptions } from "../constants";
import { removeAllMessages } from "../../../../../Store/Slices/socket/AllMessagesSlice";
import { ChatbotConversationService } from "../../../../../Services/Apis/ChatbotConversationService";
import { useAuthStore } from "../../../../../Store/Slices/AuthSlice";

const botHeaders = {
  connection_type: "agent",
};

interface Props extends HocBackdropLoaderProps {}

const SelectActions: React.FC<Props> = ({ setLoading }) => {
  const dispatch = useAppDispatch();
  const {
    allLabelsData,
    bulkSelectedSessions,
    allSessionsData,
    chatSessionActiveFilter,
    limit,
    offset,
  } = useAppSelector(LiveChatData);
  const { data: authData } = useAppSelector(useAuthStore);
  const { showAlert } = useNotification();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuId, setMenuId] = React.useState<number>(1);
  const [userInput, setUserInput] = React.useState<any>({
    status: null,
    labels: null,
  });

  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    fieldId: number
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuId(fieldId);
  };
  const handleClose = (fieldId: number) => {
    setAnchorEl(null);
    setMenuId(fieldId);
  };

  const handleAllCheckboxClick = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let data = {};
    dispatch(setAllBulkSelectedSessions(event?.target?.checked));
    if (event?.target?.checked) {
    } else {
    }
  };
  const handleCancelAllBoxes = () => {
    dispatch(setAllBulkSelectedSessions(false));
  };
  const handleSubmitFn = (values: any, submitProps: any, fieldId: number) => {
    submitProps?.setSubmitting(false);

    if (fieldId === 1 && !values?.labels) {
      setLoading(false);
      return submitProps?.setFieldError("labels", "Please select a label");
    }
    if (fieldId === 2 && !values?.status) {
      setLoading(false);
      return submitProps?.setFieldError("status", "Please select a status");
    }
    setLoading(true);

    if (fieldId === 1) {
      let labels = convertArrayToStringArray(values?.labels, "id");
      let data = {
        id: labels,
        session_id: bulkSelectedSessions,
      };
      ChatNetworkService.changeChatLabelBulk(data)
        .then((res) => {
          showAlert(`Labels updated`, "success");
          getSessionsList();
        })
        .catch((err) => {
          showAlert("Something went wrong. Try again", "error");
          console.warn(err);
          setLoading(false);
        })
        .finally(() => {
          // setLoading(false);
          handleCancelAllBoxes();
          submitProps?.resetForm();
          handleClose(fieldId);
        });
    }
    if (fieldId === 2) {

      if (bulkSelectedSessions?.length === 1) {
        let singleData = {
          conversation_status: values?.status?.value,
          session_id: bulkSelectedSessions?.[0],
          username: authData?.data?.user?.id
        };
        ChatbotConversationService.changeChatStatus(singleData,botHeaders)
          .then((res) => {
            showAlert(`Status updated`, "success");
            getSessionsList();
            dispatch(removeAllMessages({}));
          })
          .catch((err) => {
            showAlert("Something went wrong. Try again", "error");
            console.warn(err);
            setLoading(false);
          })
          .finally(() => {
            // setLoading(false);
            handleCancelAllBoxes();
            submitProps?.resetForm();
            handleClose(fieldId);
          });
        return;
      }
      else if (bulkSelectedSessions?.length > 1) {
        let data = {
          conversation_status: values?.status?.value,
          session_id: bulkSelectedSessions,
          username: authData?.data?.user?.id
        };
       
        ChatbotConversationService.changeChatStatusBulk(data, botHeaders)
          .then((res) => {
            showAlert(`Status updated`, "success");
            getSessionsList();
            dispatch(removeAllMessages({}));
          })
          .catch((err) => {
            showAlert("Something went wrong. Try again", "error");
            console.warn(err);
            setLoading(false);
          })
          .finally(() => {
            // setLoading(false);
            handleCancelAllBoxes();
            submitProps?.resetForm();
            handleClose(fieldId);
          });
        return;
      }

      else {
        showAlert("Something went wrong. Try again", "error");
        setLoading(false);
        handleCancelAllBoxes();
        submitProps?.resetForm();
        handleClose(fieldId);
        return;
      }
    }
  };

  const getSessionsList = (values?: any) => {
    let data = getChatSessionFilterPayloadData(
      values ?? chatSessionActiveFilter
    );

    let config = {
      limit: limit,
      offset: offset,
      data: data,
    };

    // setLoading(true);
    // dispatch(removeAllMessages({}));
    dispatch(LiveChatActions.getSessionsListings(config)).finally(() => {
      setLoading(false);
    });
  };

  if (bulkSelectedSessions?.length === 0) {
    return null;
  }

  return (
    <SelectActionsWrapper>
      <div className="main-select">
        <FormControlLabel
          label="All"
          control={
            <Checkbox
              checked={allSessionsData?.length === bulkSelectedSessions?.length}
              onChange={(event: any) => handleAllCheckboxClick(event)}
              id={uniqid()}
            />
          }
        />

      </div>
      <div className="actions">
        <IconButton
          title="Assign Labels"
          onClick={(e: any) => handleClick(e, 1)}
        >
          <AppMaterialIcons iconName="LabelImportant" />
        </IconButton>
        <IconButton
          title="Assign Status"
          onClick={(e: any) => handleClick(e, 2)}
        >
          <AppMaterialIcons iconName="PublishedWithChanges" />
        </IconButton>
        <IconButton title="Cancel" onClick={() => handleCancelAllBoxes()}>
          <AppMaterialIcons iconName="Close" />
        </IconButton>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose(menuId)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitFn(values, submitProps, menuId);
            }}
          >
            <div
              style={{
                padding: "1rem",
                width: "320px",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {menuId === 1 && (
                <AppReactSelectField
                  name="labels"
                  options={allLabelsData}
                  label="Labels"
                  valueKey={"id"}
                  isMulti={true}
                />
              )}
              {menuId === 2 && (
                <AppReactSelectField
                  name="status"
                  options={chatFilterStatusOptions}
                  label="Status"
                  valueKey={"value"}
                  isMulti={false}
                />
              )}
              <Divider />
              <AppSubmitButton title={"Apply"} />
            </div>
          </AppForm>
        </Menu>
      </div>
    </SelectActionsWrapper>
  );
};

export default HocBackdropLoader(memo(SelectActions));
