import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { theme } from "../../../../Customization/Theme";

export const Domainwrapper = styled.div`
&{
    display:flex;


    .text-dark {
        color: ${theme.palette.default.black};
    }

    .text-grey {
        color: ${theme.palette.default.darkGrey};
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: flex-start;
        gap: 12px;
        line-height: 24px;
    }
    
marign-left:1rem;
background-color:white;
.mainDiv{
    padding: 8px 38px;
    // padding-top: 8px;

}
.mainDivSecond{
    width:100%;
     padding: 8px 38px;

}

.third-step_contemt {
    padding: 8px 38px;

    .help-image {
        width: 100%;
        max-width: 585px;
    }

    .dkim-text {
        color: ${theme.palette.default.darkGrey};
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: flex-start;
        gap: 12px;
        margin-bottom: 24px;
        line-height: 24px;
    }   
    label {
        color: ${theme.palette.default.black};
    }
    
    textarea {
        height: 180px !important;
        background: rgba(203, 205, 211, 0.3);
        color: ${theme.palette.default.darkGrey};
    }

    input {
        background: rgba(203, 205, 211, 0.3);
        color: ${theme.palette.default.darkGrey};
    }
}

.text{
    opacity:0.5;
    padding:1rem 0 0 0 ;
}
.buttons{
    
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        flex-wrap: wrap;
        padding:20px 0px;
        border-top: 1px solid rgba(126, 131, 146, 0.5);

}
.radio {
    & .MuiFormGroup-root {
        flex-direction: column;
    }
}

.lsiting{
    margin-left: 36px;
}

.note{
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top:24px;
    background-color: #E9EDFF; 
    padding:22px 32px;
    font-size: 14px;
    font-weight: 400;
    max-width:636px;
    width: 636px;
    border-radius: 4px;
    border: 1px solid rgba(221, 228, 255, 1);
    .link-color {
        color: ${theme.palette.default.blue};
        text-decoration: none;
        line-height: 20px;
    }
}
}

`

export const DomainSetupWrapper = styled.div`
& {
    width: 100%;
    // height: 100%;
    height: calc(100vh - 213px);
    padding: 0px 16px;
    padding-bottom: 16px;
    display: flex; 
    justify-content: space-between;
}
`;

export const DomainSetupsteps = styled.div`
& {
    width: 25%;
}
`;

export const DomainSetupPannel = styled.div`
& {
    width: 70%;
    background-color: #fff;
    border-radius: 4px;
    padding: 16px;
    height: fit-content;
    min-height: 100%;
}
`;
export const DomainScroll = styled.div`
    // max-height: calc(100vh - 240px);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 16px;
`
export const VerificationStep = styled.div`
  & {
    
    & .MuiListItemButton-root {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 1rem;
      border-radius: 6px;
      position: relative;
      box-shadow: rgba(126, 131, 146, 0.24) 0px 13.9892px 69.9461px;
    }
    
    .circle {
        border: 1px solid ${theme.palette.default.darkGrey};
        border-radius: 50%;
    }
  }
`;


export const ButtonContainer = styled.div`
& {
    padding-left: 38px;
    padding-right: 38px;
}
`;




export const CustomTimeLine = styled.div`
& {
    height: 42px;
    // & .MuiTimelineConnector-root {
    //     background-color: blue;
    // }
    & .MuiTimelineSeparator-root {
        position: absolute;
        left: 50%;
    }
}
`;