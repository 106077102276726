import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { AppConfig } from "../../../../Config/app-config";
import { FacebookSettingsActions } from "../../../../Store/Slices/Settings/facebookSettings/facebookSettings.actions";
import FacebookIntegration from "./FacebookIntegration";
import FacebookConfigure from "./FacebookConfigure";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  facebookConfigData,
  facebookConfigList,
} from "../../../../Store/Slices/Settings/facebookSettings/facebookSettings.selectors";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";

interface Props extends LoaderComponentProps {
  type: any;
  header: any;
  keyList: any;
  customStyle?: any;
}
const FacebookIndex: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [pageData, setPageData] = useState<any>();
  const configData = useAppSelector(facebookConfigList);
  // const configCreated = useAppSelector(facebookConfigData)

  const handleDisconnectButton = () => {
    setLoading(true);
    dispatch(FacebookSettingsActions.logoutFacebookConfiguration({}))
      .then((save_result: any) => {
        showAlert("Disconnected from Facebook successfully!", "success");
        setIsConnected(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSingleDisconnectButton = (row: any) => {
    setLoading(true);
    dispatch(
      FacebookSettingsActions.disconnectSingleFacebookPage({ page_id: row.id }),
    )
      .then((save_result: any) => {
        showAlert("Disconnected from Facebook successfully!", "success");
        dispatch(FacebookSettingsActions.facebookConfigList({}));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const componentClicked = (response: any) => {
    if (response.accessToken && !response?.error?.code) {
      const data_for_config = {
        fb_exchange_token: response.accessToken,
      };
      setLoading(true);
      dispatch(
        FacebookSettingsActions.facebookConfigData(data_for_config),
      ).then((save_result: any) => {
        showAlert("Connected to Facebook successfully!", "success");
        getConfigPageList();
        setIsConnected(true);
      });
    }
  };

  useEffect(() => {
    if (configData?.count !== undefined) {
      setPageData(configData);
      setIsConnected(true);
    }
    setLoading(false);
  }, [configData]);

  const getConfigPageList = () => {
    dispatch(FacebookSettingsActions.facebookConfigList({})).then(
      (res: any) => {
        // setPageData(res.data);
        // if (res.data != "") {
        //   setIsConnected(true);
        // }
      },
    );
  };
  useEffect(() => {
    getConfigPageList();
    // ChatbotConsoleService.getFacebookData(currentBot.bot_id).then(
    //   (res: any) => {
    //     setPageData(res.data?.page_data?.data);
    //     if (res.data != "") {
    //       setIsConnected(true);
    //     }
    //   }
    // );
  }, []);

  return (
    <FacebookWrap>
      {isConnected ? (
        <FacebookConfigure
          handleDisconnectButton={handleDisconnectButton}
          handleSingleDisconnectButton={handleSingleDisconnectButton}
          pageData={pageData}
          componentClicked={componentClicked}
        />
      ) : (
        <FacebookIntegration componentClicked={componentClicked} />
      )}
    </FacebookWrap>
  );
};

export default withLoader(FacebookIndex);

const FacebookWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
  }
`;
