import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import { AppForm, AppFormObserver, AppSubmitButton } from "../../../AppComponents/Forms";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import {
  automationBuilderPayload,
  automationBuilderState,
  modelConfigData,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { CheckEmailStatusConfigSchema } from "../validationSchema";
import { withLoader } from "../../../AppComponents/Loader";

const email_status_options = [
  { id: "sent", name: "Email Sent" },
  { id: "notsent", name: "Email Not Sent" },
  { id: "delivered", name: "Email Devlivered" },
  { id: "opened", name: "Email Read" },
  { id: "clicked", name: "Email Clicked" },
  { id: "unsubscribed", name: "Email Unsubscribed" },
  { id: "failed", name: "Email Failed" },
];
const CheckEmailStatus = ({ setLoading }: any): JSX.Element => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formRef: any = useRef();
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const emailModelConfig: any = useAppSelector(modelConfigData);
  const automationPayload: any = useAppSelector(automationBuilderPayload);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [initialValues, setInitialValues]: any = useState({
    email_status: "",
    emailTemplateId: "",
  });
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  useEffect(() => {
    // Manually set the isValid state to false
    formRef?.current?.setFormikState((prevState: any) => ({
      ...prevState,
      isValid: false,
    }));
    formRef?.current?.setFieldValue("emailTemplateId", "", { shouldDirty: true })
  }, []);

  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.emailTemplateId
    ) {
      //function to filter object based on Value
      setInitialValues({
        emailTemplateId: {
          id: emailModelConfig?.template_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId
          )?.id,
          name: emailModelConfig?.template_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId
          )?.name,
        },
        email_status: {
          id: email_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.id,
          name: email_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.name,
        },
      });
    }
  }, [automationPayload, emailModelConfig]);   // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(
      AutomationBuilderActions.getModelConfigByType("email-config")
    ).then(() => setLoading(false))
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  const handleSubmit = (values: any, submitProps: any) => {
    let payload: any = {
      function: values?.email_status.id,
      key: "function",
      nodeObject: {
        emailTemplateId: values?.emailTemplateId.id,
      },
      nodeId: automationReduxData?.selectedNodeId,
      configText: values?.email_status.name,
    };
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };

  return (
    <ModelFormWrapper isDataAvaliable={emailModelConfig?.template_list?.length}>
      <Typography variant="h6">Check Email Status</Typography>
      {emailModelConfig?.template_list ? (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={CheckEmailStatusConfigSchema}
          innerRef={formRef}
        >
          <AppReactSelectField
            name={"emailTemplateId"}
            options={emailModelConfig?.template_list || []}
            label="Email Template(s)"
            displayKey="name"
            valueKey="id"
            placeholder="Select Email Template"
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
          />
          <AppReactSelectField
            name={"email_status"}
            options={email_status_options}
            label="Select List"
            displayKey="name"
            valueKey="id"
            placeholder="Select List"
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No List Found"
          />
          <AppFormObserver setFormikProps={setFormikProps} />
          <ModelFooter>
            <AppButton onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
          </ModelFooter>
        </AppForm>
      ) : (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      )}

    </ModelFormWrapper>
  );
};

export default withLoader(CheckEmailStatus);

const ModelFormWrapper = styled.div`  
width: ${(props: any) => props.isDataAvaliable ? "400px" : "500px"};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
