import uniqid from "uniqid";
export const broadcastSendOptions: any[] = [
    {
      name: "Broadcast now",
      value: "broadcast_now",
      id: uniqid(),
    },
    {
      name: "Schedule Broadcast",
      value: "schedule_broadcast",
      id: uniqid(),
    },
  ];