import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface initialStateTypes {
  data: any;
  access_token: string | null | undefined;
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: null,
  access_token: null,
  error: "",
  loading: false,
};

const OnboardingSlice = createSlice({
  name: "OnboardingSlice",
  initialState,
  reducers: {

    setWhatsappOnboardingConfig(state, { payload }: PayloadAction<any>) {
      state.data = payload;
    },
    setWhatsappAccessToken(state, { payload }: PayloadAction<any>) {
      state.access_token = payload;
    },
   
  },
  extraReducers: (builder) => {
    // builder
  },
});

export default OnboardingSlice.reducer;

export const {
  setWhatsappOnboardingConfig,
  setWhatsappAccessToken,
} = OnboardingSlice.actions;
