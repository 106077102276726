import { createAsyncThunk } from "@reduxjs/toolkit";
import { BusinessHoursNetworkService } from "./WorkspaceNetworkService";

export class WorkspaceActions {

    // Get Worksapce Data
    static getWorkspaceConfigData = createAsyncThunk(
        "workspaceSlice/getWorkspaceConfigData",
        (data: any, { dispatch, rejectWithValue }) => {
            return BusinessHoursNetworkService.workspaceConfigData()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )

    // Update Workspace
    // Update Message By ID
    static updateWokspaceConfigData = createAsyncThunk(
        "WorkspaceSlice/updateWokspaceConfigData",
        (data: any, { dispatch, rejectWithValue }) => {
            return BusinessHoursNetworkService.UpdateWorkspaceData(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                });
        }
    );
}