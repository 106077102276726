import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormField,
  AppFormMobileField,
  AppSubmitButton
} from "../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../AppComponents/Forms/AppFormReactSelect";
import { LoaderComponentProps, withLoader } from "../../../../AppComponents/Loader";
import { MultipleFieldsRow } from "../../../../Customization/CommonElements";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { UserNetworkService } from "../../../../Store/Slices/Settings/LiveChat/User/UserNetworkService";
import { getReactSelectCurrentValue } from "../../../../Utils";
import { ResponseHeader } from "../../ChatSettings/CannedResponse/CannedResponsesstyles";
import { userSchema } from "./UserValidationSchema";
import { AddUserWrapper } from "./UsersStyles";

interface Props extends LoaderComponentProps { }

const AddUser: React.FC<Props> = ({ setLoading }): JSX.Element => {
  const { id } = useParams();
  const currentBot = useSelector(useCurrentBot);
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const [roles, setRoles] = useState<any>([])
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    concurrent_chat: 0,
    role_id: roles?.length > 0 ? roles?.[1] : null,
  });
  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot?.bot_id,
    }),
  };
  // Get User Roles 
  useEffect(() => {
    getAllUserRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getAllUserRoles = () => {
    const data = {
      HEADERS,
    };
    UserNetworkService.getUserRoles(data)
      .then((res: any) => {
        setRoles(res?.data?.data)
      }).finally(() => {
        setLoading(false)
      })
  };
  useEffect(() => {
    const data = {
      HEADERS,
      id: id
    }
    if (roles?.length > 0 && id) {
      UserNetworkService.getUser(data).then((res: any) => {
        const temp = res?.data;
        setInitialValues({
          first_name: temp?.first_name,
          last_name: temp?.last_name,
          email: temp?.email,
          phone: temp?.phone,
          concurrent_chat: temp?.chat_limit ?? 0,
          role_id: roles ? getReactSelectCurrentValue(roles, temp?.role_id, "id", false) : {}
        });
      }).finally(() => {
      });
    }
  }, [id, roles]) // eslint-disable-line react-hooks/exhaustive-deps

  // Add users functionality
  const handleAddUser = (values: any, SubmitProps: any) => {
    const payload = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      email: values?.email,
      phone: values?.phone,
      concurrent_chat: Number(values?.concurrent_chat) ?? 0,
      role_id: values?.role_id?.id,
    };
    const data = {
      payload,
      HEADERS,
    };
    if (id) {
      UserNetworkService.updateUser(data, id)
        .then((res: any) => {
          SubmitProps?.resetForm();
          showAlert("User is updated", "success");
          navigate(`/settings/users-and-permissions/users/list`);
        }).catch((err: any) => {
          showAlert(err?.response?.data?.message || "Something went wrong", "error");
        })
    }
    else {
      UserNetworkService.createUser(data)
        .then((res: any) => {
          SubmitProps?.resetForm();
          showAlert("User is added", "success");
          navigate(`/settings/users-and-permissions/users/list`);
        }).catch((err: any) => {
          console.error("error",err)
          showAlert(err?.response?.data?.error || err?.response?.data?.message || "Unable to add user at this moment please try again!", "error")
        })
    };
    SubmitProps?.setSubmitting(false);
  }
  return (
    <AddUserWrapper>
      <ResponseHeader>
        <div
          className="response-header__left"
          style={{ display: "flex", alignItems: "center" }}
        >
          <IconButton
            onClick={() =>
              navigate("/settings/users-and-permissions/users/list")
            }
          >
            <AppMaterialIcons iconName="ChevronLeftOutlined" />
          </IconButton>
          <h4>{id ? "Edit User" : "Add User"}</h4>
        </div>
        <div className="response-header__right">
          <div className="response-search"></div>
          <div className="response-button"></div>
        </div>
      </ResponseHeader>

      <div className="add-user__left">
        <AppForm
          initialValues={initialValues}
          onSubmit={(values: any, submitProps: any) => {
            handleAddUser(values, submitProps);
          }}
          validationSchema={userSchema}
          divStyle={{ height: "100%" }}
        >
          <div className="form-field__container">
            <MultipleFieldsRow>
              <AppFormField
                label="First Name"
                name="first_name"
                placeholder="First Name"
                requiredSign={true}
              />
              <AppFormField
                label="Last Name"
                name="last_name"
                placeholder="Last Name"
                requiredSign={true}
              />
            </MultipleFieldsRow>

            <MultipleFieldsRow>
              <AppFormMobileField
                label="Mobile"
                name="phone"
                placeholder="+919991234567"
                mobileCountryFormat="mobileCountryFormat"
                requiredSign={true}
              />
              <AppFormField
                label="Email"
                name="email"
                placeholder="eg@example.com"
                requiredSign={true}
                disabled={id ? true : false}
              />
            </MultipleFieldsRow>

            <MultipleFieldsRow>
              <AppReactSelectField
                name="role_id"
                options={roles}
                label="Roles"
                displayKey="name"
                valueKey="id"
                placeholder=""
                requiredSign={true}
              />
              <AppFormField
                label="Concurrent Chat"
                name="concurrent_chat"
                placeholder="4"
                // requiredSign={true}
                type="number"
              />
              {/* <AppFormSelectField
                Options={teams}
                label="Select Team (Optional)"
                name="team"
                placeholder=""
                requiredSign={false}
              /> */}
            </MultipleFieldsRow>
          </div>

          <div
            className="footer"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "0.5rem",
            }}
          >
            <AppButton
              onClick={() =>
                navigate("/settings/users-and-permissions/users/list")
              }
              variant="grey"
            >
              Back
            </AppButton>
            <div className="submit-button">
              <AppSubmitButton
                variant="cta"
                style={{ width: "180px" }}
                title={id ? "Update User" : "Add User"}
              />
            </div>
          </div>
        </AppForm>
      </div>
    </AddUserWrapper>
  );
};
export default withLoader(AddUser);
