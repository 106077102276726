import { Avatar } from "@mui/material";
import React, { memo } from "react";
import { getDefaultAvatar } from "../../../Utils";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import { getColorFromChar } from "../../utils/utils";

interface Props {
  userMessage: boolean;
}

const AppChatUserAvatar: React.FC<Props> = ({ userMessage = true }) => {
  const { userInfoData } = useAppSelector(allMessagesStore);
  const avatar: string = userInfoData?.avatar;
  const avatarName: string =getDefaultAvatar(
      userInfoData?.user?.toString() ?? userInfoData?.name?.toString()
    ) ?? "";

  const { bgColor, textColor } = getColorFromChar(avatarName);

  if (userMessage) {
    return null;
  }

  return (
    <Avatar
      sx={{
        backgroundColor: bgColor,
        color: textColor,
        width: 35,
        height: 35,
        fontSize: "14px",
        marginTop: "20px",
      }}
      src={avatar}
    >
      {avatarName}
    </Avatar>
  );
};

export default memo(AppChatUserAvatar);
