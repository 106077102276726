// vendors
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

type EditorMainWrapperStyledProps = {
  error: boolean
}

export const EditorMainWrapperStyled = styled(Box)<EditorMainWrapperStyledProps>(({ error }) => ({
  backgroundColor: "#F5F6F8",
  padding: "12px",
  borderRadius: "4px",
  maxHeight: "200px",
  overflowY: "auto",
  color: "#7E8392",
  ...(error && {
    border: '1px solid red',
    backgroundColor: 'rgb(243 209 209 / 36%)'
  }),
}));

export const EditorInnerWrapperStyled = styled(Box)(({ theme }) => ({
  position: "relative",
  "& .public-DraftStyleDefault-block": {
    position: "relative",
    width: "100%",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#7E8392",
    fontWeight: 400,
  },
  "& .DraftEditor-editorContainer": {
    paddingBottom: '30px',
    minHeight: "38px",
  },
  "& .public-DraftEditor-content": {
    minHeight: "38px",
  },
  cursor: "text",

  "& .public-DraftEditorPlaceholder-root": {
    position: "absolute",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#7E8392",
    opacity: 0.42,
  },

  "& .public-DraftStyleDefault-ul": {
    marginLeft: "40px"
  }
}));

export const UploadSectionCloseButtonStyled = styled(IconButton)(
  ({ theme }) => ({
    backgroundColor: "white",
    border: "1px solid #CBCDD3",
    padding: "4px",
    position: "absolute",
    top: "-10px",
    right: "-10px",
    zIndex: 1,
    "&:hover": {
      backgroundColor: "white",
    },
  })
);
