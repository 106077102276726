import Icon from "./../../../Icon";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";

const Upload = (props: any) => {
  const { config, onUploadClick } = props;

  return (
    <Box
      sx={{
        position: "relative",
        // pt: "30px",
        "&:hover > div": {
          display: "flex",
          opacity: 1,
        },
      }}
    >
      <Stack
        direction={"row"}
        spacing={1}
        sx={{
          position: "absolute",
          top: "-30px",
          zIndex: 1,
          py: "5px",
          display: "none",
        }}
      >
        {config.options.map((style: string, index: number) => (
          <IconButton
            sx={{
              p: "2px !important",
            }}
            size="small"
            key={`category-${index + 1}`}
            onClick={() => onUploadClick(style)}
          >
            <Icon icon={config[style].icon} size={16} color="#7E8392" />
          </IconButton>
        ))}
      </Stack>
      <IconButton
        sx={{
          p: "2px !important",
        }}
        size="small"
      >
        <Icon icon={config.icon} size={16} color="#7E8392" />
      </IconButton>
    </Box>
  );
};
export default Upload;
