import * as Yup from "yup";
import ValidateCountryMobileNumber from "../../../../Models/CountryMobileRegex";

export const ONLY_CHARACTER_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð 0-9]+$/u;

export const userSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3)
    .max(24)
    .matches(ONLY_CHARACTER_REGEX, "Enter a valid name")
    .required()
    .label("First Name"),
  last_name: Yup.string()
    .min(3)
    .max(24)
    .matches(ONLY_CHARACTER_REGEX, "Enter a valid name")
    .required()
    .label("Last Name"),
  email: Yup.string().email().required().label("Email"),
  phone: Yup.string()
    .min(1, "Invalid Mobile format")
    .max(19)
    .required()
    //@ts-ignore
    .test("strongPhoneTest", null, (value: any, ctx: any) => {
      let mobileCountryFormat: any = ctx?.parent?.mobileCountryFormat;
      let iso2: any = mobileCountryFormat?.iso2;

      const appendingPlusSignToMobileValue = "+" + value;

      const result = ValidateCountryMobileNumber(
        iso2,
        appendingPlusSignToMobileValue
      );

      if (result) {
        return true;
      } else {
        return new Yup.ValidationError(
          `Please enter valid Mobile number for ${mobileCountryFormat?.name}`,
          null,
          "phone"
        );
      }
    }).label("Phone"),
  role_id: Yup.mixed().required().label("Role"),
  concurrent_chat: Yup.number()
    .typeError("Number must be a valid number")
    .min(0)
    .max(1000)
    .required()
    .label("Number")
    .test('is-number', 'Number must be a valid number', (value: any) => !isNaN(value)),

});
