import styled from 'styled-components'
import { theme } from '../../../../../Customization/Theme'
import { useAppDispatch, useAppSelector } from '../../../../../Store'
import { setPreviewDevice } from '../../../../../Store/Slices/FormBuilder/formbuilder.slice'
import DevicesButtonGroup from '../../../Components/DevicesButtonGroup'
import { formBuilderStore, formSettingsStoreData, getDeviceForPreview } from '../../../../../Store/Slices/FormBuilder/formbuilder.selectors'
import FormPreview from './FormPreview'

const LivePreview = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const selectedDevice: string = useAppSelector(getDeviceForPreview);
    const formFieldsData: any = useAppSelector(formBuilderStore);
    const formSettings = useAppSelector(formSettingsStoreData);
    return (
        <Container>
            <Header>
                <p>Preview</p>
                <div>
                    <DevicesButtonGroup
                        lables={[
                            {
                                id: 0,
                                label: "Desktop",
                                value: "desktop",
                                handleButtonClick: () => {
                                    dispatch(setPreviewDevice("desktop"));
                                }
                            },
                            {
                                id: 1,
                                label: "Tablet",
                                value: "tablet",
                                handleButtonClick: () => {
                                    dispatch(setPreviewDevice("tablet"));

                                }
                            },
                            {
                                id: 2,
                                label: "Mobile",
                                value: "mobile",
                                handleButtonClick: () => {
                                    dispatch(setPreviewDevice("mobile"));

                                }
                            }
                        ]} />
                </div>
            </Header>
            <Text>When your contact submits this form a new deal, contact and companies are created.</Text>
            <Text>Filling out this below will not create any items.</Text>
            <PreviewContainer previewDevice={selectedDevice}>
                <FormPreview fields={formFieldsData} styles={formSettings} />
            </PreviewContainer>
        </Container>
    )
}

export default LivePreview;

const deviceWidths: any = {
    desktop: '100%',
    tablet: '65%',
    mobile: '35%'
};

const Container = styled.div`
&&{
    height:100%;
    width:100%;
}`
const Header = styled.div`
&&{
    height: 64px;
    background-color:${theme.palette.default.white};
    margin-top: 1px;
    display:flex;
    padding:0 1rem;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    color: ${theme.palette.default.newFontColor};
    margin-bottom: 1rem;
}`

const Text = styled.p
    `&&{
    padding:0 1rem;
    color: ${theme.palette.default.darkGrey};
}`

const PreviewContainer = styled.div`
&&{
    padding: 1rem;
    width: 100%;
    width: ${(props: any) => deviceWidths[props.previewDevice] || '100%'};
}`