import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormNetworkService } from "./FormBuilderNetworkService";

export class FormAction {
    static CreateFormAction = createAsyncThunk(
        "FormsSlice/CreateFormAction",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.createForm(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static UpdateFormAction = createAsyncThunk(
        "FormsSlice/UpdateFormActio",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.updateForm(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static GetFormListing = createAsyncThunk(
        "FormsSlice/GetFormListing",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getFormsListing(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static deleteForm = createAsyncThunk(
        "FormsSlice/DeleteForm",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.deleteForm(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );


    static getAddContactsDropDown = createAsyncThunk(
        "ContactSlice/getAddContactsDropDown",
        (data: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getAddContactsDropDown(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    static getFormDetails = createAsyncThunk(
        "FormsSlice/getFormDetails",
        (formId: any, { dispatch, rejectWithValue }) => {
            return FormNetworkService.getForm(formId)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );
}

