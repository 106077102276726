import * as Yup from "yup";

export const EmailDomainValdiation = Yup.object().shape({
    domain: Yup.string().required().label("Domain"),
})
export const EmailVerifyValidation = Yup.object().shape({
    email: Yup.string().required().label("Email"),
})
export const EmailDefaultValidation = Yup.object().shape({
    view_options: Yup.lazy((val: any) => {
        return (Array.isArray(val) ? Yup.array().min(1, "Please select atleast one option") : Yup.string().required())
    })

})
export const EmailBounceDomain = Yup.object().shape({
    domain: Yup.string().required().label("Email"),
})