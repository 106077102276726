import { Box, IconButton } from "@mui/material";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import AppButton from "../../../../../AppComponents/AppButton";
import { useEffect, useRef, useState } from "react";
import RenderImportRecordsFormStep1 from "./formsteps/RenderImportRecordsFormStep1";
import RenderImportRecordsFormStep2 from "./formsteps/RenderImportRecordsFormStep2";
import { useAppDispatch } from "../../../../../Store";
import { marketingListActions } from "../../../../../Store/Slices/MarketingList/marketingList.actions";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import {
  AllMarketingListData,
  GetImportID,
  SaveFormStepsValues,
  importFormStepOneRes,
  userListigData,
} from "../../../../../Store/Slices/MarketingList/marketingList.selectors";
import { useSelector } from "react-redux";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { useNavigate } from "react-router-dom";
import { saveFormSteps } from "../../../../../Store/Slices/MarketingList/marketingList.slice";
import RenderImportRecordsFormStep3 from "./formsteps/RenderImportRecordsFormStep3";
import { removeEmptyKeysObj } from "../../../../Broadcast/WhatsApp/Utils/utils";
import { ImportFormStep2Schema, ImportFormStep3Schema } from "./constants";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { ReactComponent as InfoIcon } from "../../../../../assets/images/danger.svg";
import { HocBackdropLoader } from "../../../../../HOC/HocBackdropLoader";
import { LoaderComponentProps } from "../../../../../AppComponents/Loader";
import React from "react";
import { getMarketingStatusOption } from "../../../../../Store/Slices/Contacts/contacts.selector";

interface Props {
  setFirstStep: any;
  setState: any;
  state: any;
  setOpenImportDrawer: any;
  type?: string; // for contact module doc upload
}

interface Props extends LoaderComponentProps {}

const DocumentUploadFirstStep: React.FC<Props> = ({
  setLoading,
  setFirstStep,
  setState,
  state,
  setOpenImportDrawer,
  type,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formRef: any = useRef();
  const { showAlert } = useNotification();
  const [formikProps, setFormikProps] = useState<any>(null);
  const [fname, setfname] = useState("");
  const [fileData, setfileData] = useState<any>(null);

      //*WILL BE USED TO SET THE FORM ACCESS TYPE 
      const [formAccessType, setFormAccessType] = useState<"create" | "read" | "update" | "delete" | undefined>("create");
      //*getting the form access type using the location on first render
      // useEffect(() => {
      //   const result = findPopupFormSpecificPermissionKeyUsingId();
      //   setFormAccessType(result ?? "create");
      // }, []);

  const [userInput, setUserInput] = useState<any>({
    data_contains: "mobile_phone_number",
    upload_file: null,
    skip_duplicates: true,

    import_option: "create_new_contacts",
    unique_value: "email",
    whatsapp_subscription: "Yes",
    email_subscription: "Yes",
    sms_subscription: "Yes",
    // lifecycle_stage: formikProps?.values?,
    // lifecycle_status: formikProps?.values?,
    marketing_list: "contact_module",
    contact_owner: 0,
    f_mapping: null,
    whatsapp_subscription_status: "",
    sms_subscription_status:"",
    email_subscription_status:""
  });
  const currentBot = useSelector(useCurrentBot);
  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot?.bot_id,
    }),
  };

  const MarketingList = useSelector(AllMarketingListData);
  const UserList = useSelector(userListigData);
  const formSaveStepData = useSelector(SaveFormStepsValues);
  const ImportID = useSelector(GetImportID);

  const [formStep, setFormStep] = useState<number>(1);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setLoading(true);
    if (formStep === 1) {
      // ============== Step 1 =============
      if (values?.upload_file === null) {
        setLoading(false);
        SubmitProps.setSubmitting(false);
      }
      if (!formikProps?.values?.upload_file) {
        SubmitProps?.setFieldError("upload_file", "File is required");
        return;
      }
      const formData = new FormData();

      formData.append("source", formikProps?.values?.upload_file);
      formData.append(
        "data_contains",
        formikProps?.values?.data_contains?.value
      );
      formData.append("skip_duplicates", formikProps?.values?.skip_duplicates);

      const requestData = {
        formData,
        ImportID,
      };

      let step1data = {
        source: formikProps?.values?.upload_file,
        data_contains: formikProps?.values?.data_contains?.value,
        skip_duplicates: formikProps?.values?.skip_duplicates,
      };

      dispatch(marketingListActions.ImportFormStepOne(requestData))
        .then((res: any) => {
          if (res?.payload?.data.status === true) {
            setFormStep(formStep + 1);
            dispatch(saveFormSteps({ step1data }));
            formRef?.current?.setFieldValue("f_mapping", res?.payload?.data);
            setLoading(false);
          } else {
            setLoading(false);
            showAlert(
              res?.payload?.data.error || "Something went wrong",
              "error"
            );
          }
        })
        .catch((err: any) => {
          setLoading(false);
        })
        .finally(() => {
          SubmitProps.setSubmitting(false);
        });
      return;
    }

    // ============ Step 2 ===========
    if (formStep === 2) {
      let step2data = {
        import_option: formikProps?.values?.import_option?.value,
        unique_value: formikProps?.values?.unique_value?.value,
        contact_owner: formikProps?.values?.contact_owner,
        whatsapp_subscription_status:
          formikProps?.values?.whatsapp_subscription_status?.id,
        email_subscription_status:
          formikProps?.values?.email_subscription_status?.id,
        sms_subscription_status: formikProps?.values?.sms_subscription_status?.id,
        lifecycle_stage: formikProps?.values?.lifecycle_stage,
        lifecycle_status: formikProps?.values?.lifecycle_status,
        lost_reason:formikProps?.values?.lost_reason,
        marketing_list: formikProps?.values?.marketing_list || "contact_module",
        ImportID,
      };

      const requestData = {
        step2data,
        ImportID,
      };

      dispatch(marketingListActions.ImportFormStepTwo(requestData))
        .then((res: any) => {
          if (res?.payload?.data.status === true) {
            setFormStep(formStep + 1);
            dispatch(saveFormSteps({ ...formSaveStepData, step2data }));
            setLoading(false);
          } else {
            setLoading(false);
            showAlert(
              res?.payload?.data.error || "Something went wrong",
              "error"
            );
          }
        })
        .catch((err: any) => {
          setLoading(false);
        })
        .finally(() => {
          SubmitProps.setSubmitting(false);
        });

      return;
    }

    if (formStep === 3) {
      // ============ Form Step 3 ============

      let playload = {
        mapping: removeEmptyKeysObj(formikProps?.values?.f_mapping?.mapping),
        ImportID,
      };

      dispatch(marketingListActions.ImportFormStepThree(playload))
        .then((res: any) => {
          if (res?.payload.data?.error) {
            showAlert(
              res?.payload.data?.error || "Something went wrong",
              "error"
            );
            setLoading(false);
            return;
          } else {
            localStorage.removeItem("M_ImportID");
            navigate(`/settings/data-management/import-history`);
          }
        })
        .catch((err: any) => {})
        .finally(() => {
          SubmitProps.setSubmitting(false);
        });
      return;
    }
  };

  const handleBackClick = () => {
    if (formStep === 1) {
      navigate(-1);
    } else {
      if (formStep !== 1) {
        setFormStep(formStep - 1);
      } else {
        navigate(-3);
      }
    }
  };

  const getMarketingList = () => {
    dispatch(marketingListActions.getAllMarketingListings({}));
  };

  const getAllUsersListing = () => {
    const data = {
      HEADERS,
      limit: 100,
      offset: 0,
    };
    dispatch(marketingListActions.getUserListing(data)).then((res) => {});
  };

  useEffect(() => {
    getMarketingList();
    getAllUsersListing();
  }, []);

  // Delete Temporary Object
  const deletTempData = () => {
    setState(false);
    setOpenImportDrawer(false);
    let data = {
      ImportID,
    };
    dispatch(marketingListActions.DeletTempObj(data));
  };

  return (
    <Box sx={{ 
      width: "918px",
      "@media (max-width: 1199.98px)": {
        width: "100%",
      }
    }}>
      <div className="header">
        <div className="form-steps">
          <h4>Import records</h4>
          <span>{formStep}/3</span>
        </div>
        <IconButton
          onClick={() => {
            deletTempData();
            localStorage.removeItem("M_ImportID");
          }}
        >
          <AppMaterialIcons iconName="Close" />
        </IconButton>
      </div>
      <AppForm
        initialValues={userInput}
        innerRef={formRef}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={
          (formStep === 2 && type !== "contact" && ImportFormStep2Schema) ||
          (formStep === 3 && ImportFormStep3Schema) ||
          null
        }
        moduleId="marketing_list_crud"
        specificPermissionKey={formAccessType}
        // initialTouched={null}
      >
        <AppFormObserver setFormikProps={setFormikProps} />
        {formStep === 1 && (
          <RenderImportRecordsFormStep1
            setFormStep={setFormStep}
            formStep={formStep}
            fname={fname}
            setfname={setfname}
            fileData={fileData}
            setfileData={setfileData}
            setUserInput={setUserInput}
            formSaveStepData={formSaveStepData}
            uniqueValue={formRef?.current?.values?.data_contains?.value}
            setState={setFirstStep}
            // state={secondStep}
            // type={type}
          />
        )}

        {formStep === 2 && (
          <RenderImportRecordsFormStep2
            setFormStep={setFormStep}
            formStep={formStep}
            marketinglist={MarketingList}
            uniqValue={formikProps?.values?.data_contains}
            type={type}
          />
        )}

        {formStep === 3 && (
          <RenderImportRecordsFormStep3
            setFormStep={setFormStep}
            formStep={formStep}
            // steponeresponse={StepOneResponse}
          />
        )}

        <div
          className="footer"
          style={{
            justifyContent: formStep === 3 ? "space-between" : "flex-end",
          }}
        >
          {formStep === 3 ? (
            <Box display="flex" alignItems="flex-start" sx={{ gap: "10px" }}>
              <InfoIcon />
              <p className="step3_text">
                Map the following mandatory CRM field primary email or mobile to
                proceed
              </p>
            </Box>
          ) : null}
          <Box display="flex" sx={{ gap: 1 }}>
            {formStep === 1 ? (
              <AppButton
                variant="grey"
                onClick={() => {
                  deletTempData();
                  setOpenImportDrawer(false);
                  localStorage.removeItem("M_ImportID");
                }}
              >
                Cancel
              </AppButton>
            ) : (
              <AppButton variant="grey" onClick={() => handleBackClick()}>
                Back
              </AppButton>
            )}

            <AppSubmitButton title={formStep === 3 ? "Import" : "Next"} />
          </Box>
        </div>
      </AppForm>
    </Box>
  );
};

export default HocBackdropLoader(DocumentUploadFirstStep);
