import { showConfigModal } from "../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import store from "../../Store/index";
const joint = require("jointjs");
const { shapes } = joint;

export const longPress = (e) => {
  let start = Date.now(),
    end,
    diff = 0,
    isLong = false;
  let clickTime = 1000;
  let div = e.el;
  function validate() {
    end = Date.now();
    diff = end - start + 1;
    if (diff > clickTime) {
      isLong = true;
      alert(true);
    } else {
      isLong = false;
      openEditModal(e);
    }
    div.removeEventListener("mouseup", validate);
    div = null;
    return isLong;
  }
  div.addEventListener("mouseup", validate);
};

export const openEditModal = (e) => {
  //add textContent to shapeConfigurationsArray to restrict open modal
  const shapeConfigurationsArray = [
    "Unsubscribe Email",
    "Contact Update",
    "Contact Created",
  ];
  if (
    e.$el[0].childNodes[3].childNodes[0].textContent ===
    "Block not yet configured"
  ) {
    store.dispatch(showConfigModal(e));
  } else if (
    !shapeConfigurationsArray.includes(e.$el[0].childNodes[2].textContent)
  ) {
    store.dispatch(showConfigModal(e));
    // const model = view.model;
    // model.resize(
    //     Math.max(coordinates.x - 10, 1),
    //     Math.max(coordinates.y - 10, 1)
    // );
  }
};

export const getElementType = (string, type) => {
  if (type === "initial") {
    return string.split(".")[0].toLowerCase();
  } else if (type === "class") {
    return string.split(".")[1].split("-")[0];
  } else if (type === "function") {
    return string.split(".")[1].split("-")[1];
  } else if (type === "name") {
    let stringArray = string.split(".")[1].split("-");
    let finalString = "";
    stringArray.map(
      (ele) =>
        (finalString = finalString + ele.charAt(0).toUpperCase() + ele.slice(1))
    );
    return finalString;
  }
};

export const getDefaultValue = (fieldArray, fieldArrayKey, defalutValue) => {
  if (fieldArray) {
    const value = fieldArray?.filter(
      (item) => item?.[fieldArrayKey] === defalutValue
    );
    return value ? value[0] : {};
  }
};

export const secondsConverter = (delayPeriod, delay) => {
  let seconds = 0;
  if (delayPeriod === "Minutes") {
    seconds = delay * 60;
  } else if (delayPeriod === "Hours") {
    seconds = delay * 3600;
  } else if (delayPeriod === "Days") {
    seconds = delay * 86400;
  } else if (delayPeriod === "Week") {
    seconds = delay * 604800;
  } else {
    seconds = delay;
  }
  return seconds;
};

export function isString(value) {
  return typeof value === "string";
}

export const getShape = (shape) => {
  if (shape === "Square") {
    return {
      tagName: "rect",
      style: {
        transform: "translate(16px, 24px) scale(1)",
      },
      attributes: {
        fill: "#4C9AFF",
        cursor: "pointer",
        stroke: "#ffffff",
        cx: "0",
        cy: "58",
        width: "48",
        height: "48",
      },
    };
  } else if (shape === "Circle") {
    return {
      tagName: "circle",
      style: {
        transform: "translate(38px, -10px)",
      },
      attributes: {
        r: 24,
        fill: "#36B37E",
        cursor: "pointer",
        stroke: "#ffffff",
        cx: "0",
        cy: "58",
      },
    };
  } else if (shape === "Hexagon") {
    return {
      tagName: "path",
      style: {
        transform: "translate(14px, 22px) scale(0.32)",
      },
      attributes: {
        d: "M99.5,0H50.5c-4.2,0-8.2,1.7-11.2,4.6L4.6,39.3c-3,3-4.6,7-4.6,11.2v49.1c0,4.2,1.7,8.2,4.6,11.2l34.7,34.7 c3,3,7,4.6,11.2,4.6h49.1c4.2,0,8.2-1.7,11.2-4.6l34.7-34.7c3-3,4.6-7,4.6-11.2V50.5c0-4.2-1.7-8.2-4.6-11.2L110.7,4.6 C107.7,1.7,103.7,0,99.5,0z",
        fill: "#505F79",
        cursor: "pointer",
        stroke: "#ffffff",
        cx: "0",
        cy: "58",
      },
    };
  }
};

export const renderWhatsappCard = (connectionsGroup) => {
  return new shapes.standard.Rectangle({
    layer: "group1",
    position: { x: 100, y: 100 },
    size: { width: 280, height: 120 },
    markup: connectionsGroup.markup,
    attrs: {
      root: {
        magnet: false,
      },
      body: {
        rx: 10,
        ry: 10,
        width: "calc(w)",
        height: "calc(h)",
        stroke: "#333333",
        strokeWidth: 2,
      },
      foreignObject: {
        width: "calc(w)",
        height: "calc(h)",
      },

      // label: {
      //     textVerticalAnchor: 'middle',
      //     textAnchor: 'middle',
      //     x: 'calc(0.5*w)',
      //     y: 'calc(0.5*h)',
      //     fontSize: 14,
      //     fill: '#333333',
      //     text: "asdasdasdasfafas"
      // }
    },
    portMarkup: [
      {
        tagName: "circle",
        selector: "portBody",
        attributes: {
          r: 6,
          // stroke: "#DFE1E6",
          fill: "#ffffff",
        },
      },
    ],
    portLabelMarkup: [
      {
        tagName: "rect",
        selector: "portLabelBackground",
      },
      {
        tagName: "text",
        selector: "portLabel",
        attributes: {
          fill: "#333333",
        },
      },
    ],
    ports: {
      groups: {
        left: {
          position: {
            name: "left",
            args: {
              x: 0,
              y: 0,
            },
          },
          magnet: "passive",
          label: {
            position: {
              name: "inside",
              args: {
                offset: 30,
              },
            },
          },
          class: "input-port-path",
          attrs: {
            ".port-label": {
              fill: "none",
            },
            position: {
              name: "left",
              args: {
                x: 0,
                y: 0,
                height: "calc(h+4)",
              },
            },
            portLabelBackground: {
              ref: "portLabel",
              fill: "#FFFFFF",
              fillOpacity: 0,
              // x: "calc(x - calc(w + 2))",
              // y: "calc(y - 2)",
              // width: "calc(w + 4)",
              // height: "calc(h + 4)"
            },
            portLabel: {
              fontFamily: "sans-serif",
            },
            portBody: {
              strokeWidth: 0,
              magnet: "passive",
            },
          },
        },
      },
      items: connectionsGroup.ports,
    },
  });
};

export const renderCard = (connectionsGroup) => {
  return new shapes.standard.Rectangle({
    layer: "group1",
    position: { x: 100, y: 100 },
    size: { width: 280, height: 120 },
    markup: [
      {
        tagName: "rect",
        attributes: {
          width: "280px",
          height: "120px",
          fill: "#ffffff",
          "follow-scale": "true",
          class: "card-box",
          rx: 10,
          ry: 10,
          filter: "drop-shadow(1px 0px 1px rgba(0, 0, 0, 0.2))",
        },
      },
      getShape(connectionsGroup.shape),
      {
        tagName: "text",
        textContent: connectionsGroup.title,
        style: {
          transform: "translate(74px, 40px)",
          fontSize: "16px",
          fontWeight: "bold",
        },
        attributes: {
          class: "title",
        },
      },
      {
        tagName: "foreignObject",
        style: {
          transform: "translate(74px, 40px)",
        },
        attributes: {
          width: "170px",
          height: "50px",
          class: "config",
          x: "0",
          y: "0",
        },
        children: [
          {
            tagName: "div",
            namespaceURI: "http://www.w3.org/1999/xhtml",
            textContent: connectionsGroup.nodeDescription,
            attributes: {
              fontSize: 10,
              fill: "black",
              width: "170px",
              height: "50px",
            },
            style: {
              color: "#1f62ff",
              display: "block",
              display: "-webkit-box",
              "-webkit-line-clamp": 2,
              "-webkit-box-orient": "vertical",
              width: "170px",
              height: "50px",
            },
          },
        ],
      },
      {
        tagName: "image",
        selector: "icon",
        attributes: {
          width: 30,
          height: 30,
          "xlink:href": connectionsGroup.icon,
        },
        style: {
          transform: "translate(25px, 32px)",
        },
      },
    ],
    attrs: {
      root: {
        magnet: false,
      },
      body: {
        stroke: "#333333",
        fill: "#fff",
        rx: 10,
        ry: 10,
      },

      // label: {
      //     textVerticalAnchor: 'middle',
      //     textAnchor: 'middle',
      //     x: 'calc(0.5*w)',
      //     y: 'calc(0.5*h)',
      //     fontSize: 14,
      //     fill: '#333333',
      //     text: "asdasdasdasfafas"
      // }
    },
    portMarkup: [
      {
        tagName: "circle",
        selector: "portBody",
        attributes: {
          r: 6,
          // stroke: "#DFE1E6",
          fill: "#ffffff",
        },
      },
    ],
    portLabelMarkup: [
      {
        tagName: "rect",
        selector: "portLabelBackground",
      },
      {
        tagName: "text",
        selector: "portLabel",
        attributes: {
          fill: "#333333",
        },
      },
    ],
    ports: {
      groups: {
        left: {
          position: {
            name: "left",
            args: {
              x: 0,
              y: 0,
            },
          },
          magnet: "passive",
          label: {
            position: {
              name: "inside",
              args: {
                offset: 30,
              },
            },
          },
          class: "input-port-path",
          markup:
            '<path d="M0 0 L281 0 L281 121 L0 121 L0 84 L20 84 L20 35 L0 35 Z" class="input-port-path" magnet="passive" />',

          attrs: {
            ".joint-port-body": {
              style: {
                fill: "transparent",
                "stroke-width": 1,
                stroke: "#0000",
              },
              width: "280px",
              height: "120px",
            },
            ".port-label": {
              fill: "none",
            },
            // position: {
            //     name: "left",
            //     args: {
            //         x: 0,
            //         y: 0
            //     }
            // },
            portLabelBackground: {
              ref: "portLabel",
              fill: "#FFFFFF",
              fillOpacity: 0,
              // x: "calc(x - calc(w + 2))",
              // y: "calc(y - 2)",
              // width: "calc(w + 4)",
              // height: "calc(h + 4)"
            },
            portLabel: {
              fontFamily: "sans-serif",
            },
            portBody: {
              strokeWidth: 0,
              magnet: "passive",
            },
          },
        },
        right: {
          position: "right",
          label: {
            position: {
              name: "outside",
              args: {
                offset: 30,
              },
            },
          },
          attrs: {
            // portLabelBackground: {
            //     ref: "portLabel",
            //     fill: "#FFFFFF",
            //     fillOpacity: 0.8,
            //     x: "calc(x - 2)",
            //     y: "calc(y - 2)",
            //     width: "calc(w + 4)",
            //     height: "calc(h + 4)"
            // },
            portLabel: {
              fontFamily: "sans-serif",
            },
            portBody: {
              strokeWidth: 2,
              magnet: "active",
            },
          },
        },
      },
      items: connectionsGroup.ports,
    },
  });
};
