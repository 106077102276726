import { registerActions } from "../../FlowBuilder/config/register-actions";

const getFacebookPayload = (values: any) => {
  let payload: any = [];
  values.forEach((element: any, index: number) => {
    if (element.action.type === "live_agent") {
      payload.push({
        type: "postback",
        title: element.label,
        payload: "LIVE_CHAT",
      });
    } else if (element.action.type === "link") {
      payload.push({
        type: "web_url",
        title: element.label,
        url: element.link,
        webview_height_ratio: "full",
        payload: `link${index}`,
      });
    } else if (element.action.type === "flow") {
      payload.push({
        type: "postback",
        title: element.label,
        payload: element.node.nodeId,
      });
    } else if (element.action.type === "opt_out") {
      payload.push({
        type: "postback",
        title: element.label,
        payload: "OPT_OUT",
      });
    }
  });
  return payload;
};
const getTelegramPayload = (values: any) => {
  let payload: any = [];
  values.forEach((element: any, index: number) => {
    if (element.action.type === "live_agent") {
      payload.push({
        type: "postback",
        title: element.label,
        command: "livechat",
      });
    } else if (element.action.type === "link") {
      payload.push({
        type: "web_url",
        title: element.label,
        url: element.link,
        webview_height_ratio: "full",
        command: `link${index}`,
      });
    } else if (element.action.type === "flow") {
      payload.push({
        type: "postback",
        title: element.label,
        payload: element.node.nodeId,
        command: `connect${index}`,
      });
    } else if (element.action.type === "opt_out") {
      payload.push({
        type: "postback",
        title: element.label,
        payload: "OPT_OUT",
      });
    }
  });
  return payload;
};

export const setTelegramMenu = (values: any) => {
  return values;
};
export const setFacebookMenu = (values: any) => {
  let menu = [];
  values.forEach((ele: any) => {
    menu.push({
      label: ele.label,
      action: registerActions.filter(
        (action: any) => action.type === ele.action,
      )[0],
      link: ele.link,
      flow: null,
      node: null,
    });
  });
  return values;
};
export const generatePayloadFromValues = (
  values: any,
  channel: string | undefined,
) => {
  let payload: any = [];
  if (channel === "messenger") {
    payload = getFacebookPayload(values);
  } else if (channel === "telegram") {
    payload = getTelegramPayload(values);
  }

  return payload;
};

export const getMaxMenuItemLength = (type: string | undefined) => {
  if (type === "messenger") {
    return 15;
  } else if (type === "telegram") {
    return 20;
  } else {
    return 10;
  }
};
