import { useEffect, useState } from "react";
import { Box, Divider, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppForm,
  AppFormDatePicker,
  AppFormField,
  AppFormMobileField,
  AppFormRadioField,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppButton from "../../../AppComponents/AppButton";
import { ReactComponent as SaveIcon } from "../../../assets/images/save-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import {
  ConatctsLifeCycle,
  ContactsAddDropDown,
  ContactsAddFields,
  UndePendantFieldDropDown,
  bulkUpdateFields,
} from "../../../Store/Slices/Contacts/contacts.selector";
import React from "react";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { createFilter } from "react-select";
import { getIn, useFormikContext } from "formik";
import AppFormCheckboxField from "../../../AppComponents/Forms/AppFormCheckboxField";
import DynamicsInputFields from "../contactDetailedComponents/FieldsLogic/DynamicsFieldsLogic";
import { AddContactFormSchema } from "../../../Models/ValidationSchema";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../HOC/HocBackdropLoader";
interface Props extends HocBackdropLoaderProps {
  closeModal: any;
  payload: any;
  SubmitReset?: any;
}
const UpdateContactsFields: React.FC<Props> = ({
  closeModal,
  payload,
  SubmitReset,
  setLoading,
}) => {
  // props
  let payLoad = payload;
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // form name key
  const formkey9 = "field";
  // tag list data from redux
  const DropDownData = useAppSelector(bulkUpdateFields);
  const AddField = useAppSelector(ContactsAddFields);
  const ContactsLifeCycleFields = useAppSelector(ConatctsLifeCycle);
  const unDepandantDropDownList: any = useAppSelector(UndePendantFieldDropDown);
  // states
  const [userInput, setUserInput] = useState({ field: [] });
  const [renderField, setRenderField] = useState<any[]>([]);
  const [lifeCycleStage, setLifeCycleStage] = useState<any>([]);
  const [lifeCycleStatus, setLifeCycleStatus] = useState<any>([]);
  const [test, setTest] = useState({
    name: "",
    id: "",
  });
  const [sectionData, setSectionData] = useState<any>([]);
  const [schemaName, setSchemaName] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [valid, setValid] = useState<any>({
    lost_reason: true,
  });
  // common state
  const [SelectDropDownData, setSelectDropDownData] = useState<any>([]);
  const [localStore, setLocalStore] = useState<any>({
    schemaName: [],
    lifeCycleStage: [],
    lifeCycleStatus: [],
    isEnabled: [],
    countryStates: [],
    stateCity: [],
  });

  useEffect(() => {
    let data: any[] = [];
    if (DropDownData?.length) {
      DropDownData.map((res: any) => {
        res?.fields?.map((field: any) => {
          let fieldData: any = {};
          if (!field?.is_dependant && field?.dependant_field_id === null) {
            fieldData = { ...field };
            if (fieldData.options) {
              delete fieldData.options;
            }

            if (
              field?.name !== "lifecycle_stage_status" &&
              field?.name !== "lost_reason"
            )
              data.push(fieldData);
          }
        });
      });
      setSelectDropDownData(data);
    }
  }, [DropDownData]);
  useEffect(() => {
    ContactsLifeCycleFields.forEach((el: any) => {
      let a = {
        ...el,
        value: el.id,
      };
      setLifeCycleStage((prev: any) => [...prev, a]);
    });
  }, [ContactsLifeCycleFields]);

  // get list data
  useEffect(() => {
    dispatch(ContactsActions.getBulkUpdateFields({}))
      .then(() => {})
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {});
    dispatch(ContactsActions.getLifeCycleContactsDropDown({}))
      .then()
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // post call for bulk add tags
  const handleSubmitBtn = (values: any, submitProps: any) => {
    payLoad.data = {};

    localStore?.schemaName.forEach((t: any) => {
      const { name, type } = t;
      let fieldName: any = values[name];
      switch (type) {
        case "dropdown":
          payLoad.data[name] = fieldName?.id;
          break;
        case "multiselect":
          payLoad.data[name] = fieldName.map((i: any) => i.id);
          break;
        case "radio":
          payLoad.data[name] =
            fieldName?.id === ""
              ? unDepandantDropDownList[0]?.value
              : fieldName?.id;
          break;
        case "string":
        case "text":
        case "checkbox":
          payLoad.data[name] = fieldName;
          break;
        case "phone":
          let countryCode =
            fieldName?.[`mobileCountryFormat${name}`]?.countryCode;
          let number = fieldName.replace(countryCode, "");
          payLoad.data[name] = { country_code: countryCode, number };
          break;
        case "landline":
          payLoad.data[name] = {
            country_code: fieldName?.[`landlineExtension${name}`],
            number: fieldName?.phone_number,
          };
          break;
        default:
          payLoad.data[name] = fieldName;
          break;
      }
    });
    setLoading(true);
    dispatch(ContactsActions.bulkUpdateContact(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Contact not update",
            "error"
          );
        } else {
          showAlert(res?.payload?.data?.message, "success");

          setUserInput({ field: [] });
          SubmitReset();
          setLoading(false);
        }
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "Contact not update", "error");
      })
      .finally(() => {
        delete payLoad.data;
        submitProps.setSubmitting(false);
      });
  };
  function removeDuplicateByKey(arrayOfObjects: any, key: any) {
    const uniqueValues = new Set();
    return arrayOfObjects.filter((obj: any) => {
      const value = obj[key];
      if (!uniqueValues.has(value)) {
        uniqueValues.add(value);
        return true;
      }
      return false;
    });
  }
  const fieldUpdate = (value: any) => {
    let data: any[] = [];
    setSectionData([]);
    setVisible(false);
    DropDownData?.map((section: any) => {
      section?.fields?.map((field: any) => {
        if (
          field?.id === value?.id ||
          field?.dependant_field_id === value?.id
        ) {
          data.push(field);
        }
        if (value.name === "lifecycle_stage") {
          if (
            field?.name === "lifecycle_stage_status" ||
            field?.name === "lost_reason"
          ) {
            data.push(field);
          }
        } else {
          data.map((res: any) => {
            if (res.id === field?.dependant_field_id && res.id !== field.id) {
              data.push(field);
            }
          });
        }
      });
    });
    let sectionData = [
      {
        label: "label",
        fields: removeDuplicateByKey(data, "position"),
      },
    ];
    setSectionData(sectionData);
    setVisible(true);
    setLocalStore((prevLocalStore: any) => ({
      ...prevLocalStore,
      schemaName: data,
    }));
  };
  return (
    <Box
      sx={{
        minWidth: "671px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", fontSize: "20px" }}>
          Update field
        </span>
        <CloseIcon
          onClick={() => closeModal()}
          sx={{
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider />
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
          setUserInput(values);
        }}
        validationSchema={AddContactFormSchema(localStore.schemaName, valid)}
        divStyle={{
          gap: "0px"
        }}
      >
        <Box sx={{ flex: 1, p: 2 }}>
          <AppReactSelectField
            menuPosition="fixed"
            label="Search for a field and update it"
            isMulti={false}
            hideSelectedOptions={false}
            name={formkey9}
            options={SelectDropDownData}
            valueKey="id"
            Group={false}
            onChangeCallbackFn={(value: any) => {
              fieldUpdate(value);
            }}
          />
        </Box>

        {/* dynamics field */}
        {/* <ChangeAbleFields
          AddContactsFields={SelectDropDownData}
          setRenderField={setRenderField}
          renderField={renderField}
          localStore={localStore}
          setLocalStore={setLocalStore}
          test={test}
          setTest={setTest}
          lifeCycleStage={lifeCycleStage}
          setUserInput={setUserInput}
          AddField={AddField}
        /> */}

        {visible ? (
          <div style={{ padding: "0rem 9px" }}>
            {" "}
            <DynamicsInputFields
              AddContactsFields={sectionData}
              schemaName={schemaName}
              lifeCycleStage={lifeCycleStage}
              setLifeCycleStatus={setLifeCycleStatus}
              lifeCycleStatus={lifeCycleStatus}
              ContactsLifeCycleFields={ContactsLifeCycleFields}
              setLocalStore={setLocalStore}
              localStore={localStore}
              setValid={setValid}
              valid={valid}
              hideLabelSectionTitle={true}
              // parentHeight={"calc(100vh - 185px)"}
            />{" "}
          </div>
        ) : null}

        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
            gap: "6px",
          }}
        >
          <AppButton
            style={{ width: "115px" }}
            variant="outline"
            startIcon="add"
            onClick={() => closeModal()}
          >
            Cancel
          </AppButton>
          <AppSubmitButton title="Update Contacts">
            <SaveIcon />
          </AppSubmitButton>
        </Box>
      </AppForm>
    </Box>
  );
};
export default HocBackdropLoader(UpdateContactsFields);

const ChangeAbleFields = (props: any) => {
  const {
    AddContactsFields,
    setRenderField,
    renderField,
    localStore,
    setTest,
    setLocalStore,
    test,
    lifeCycleStage,
    setUserInput,
    AddField,
  } = props;
  const dropDownListData: any = useAppSelector(ContactsAddDropDown);
  const ContactsLifeCycleFields: any = useAppSelector(ConatctsLifeCycle);
  const unDepandantDropDownList: any = useAppSelector(UndePendantFieldDropDown);

  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext();
  const { showAlert } = useNotification();
  let v = getIn(values, "field");
  const [lifeCycleStatus, setLifeCycleStatus] = useState<any>([]);
  const [localvalues, setLocalValues] = useState<any>({
    mobileCountryFormat: { countryCode: "" },
    country_region: { parent_id: "", id: "" },
    state_region: { id: "" },
    lifecycle_stage: { label: "", value: "" },
  });
  useEffect(() => {
    setUserInput({ field: v });
    setLocalStore({
      schemaName: [],
      lifeCycleStage: [],
      lifeCycleStatus: [],
      isEnabled: [],
      countryStates: [],
      stateCity: [],
    });
    const allowedFieldNames = [
      // "city",
      // "state_region",
      // "country_region",
      "lifecycle_stage",
      "lifecycle_stage_status",
      // "lost_reason",
    ];
    if (v?.name) {
      const filteredFields = AddContactsFields?.filter((item: any) => {
        if (
          allowedFieldNames?.includes(item?.name) &&
          allowedFieldNames?.includes(v.name)
        ) {
          return item;
        } else {
          if (item?.name === v.name) {
            return item;
          }
        }
      });

      if (
        (filteredFields[0].type === "dropdown" ||
          filteredFields[0].type === "multiselect" ||
          filteredFields[0].type === "radio") &&
        filteredFields?.length < 2
      ) {
        unDependantFieldsDropDown(filteredFields[0].id);
      } else {
        filteredFields.forEach((res: any) => {
          if (res.label === "Country Region") {
            unDependantFieldsDropDown(res?.id);
          }
        });
      }

      if (v?.type === "radio") {
        getData("radio", v?.id, "");
      }

      setTimeout(() => {
        setRenderField(filteredFields);
        setLocalStore((prevLocalStore: any) => ({
          ...prevLocalStore,
          schemaName: filteredFields,
        }));
      }, 500);
    } else {
      setRenderField([]);
    }
  }, [v]);

  useEffect(() => {
    let value: any = values;
    if (values) {
      setLocalValues(values);
    }
    if (value?.lifecycle_stage_status?.type) {
      if (value?.lifecycle_stage_status?.type === "closed_lost") {
        if (!renderField.find((item: any) => item.name === "lost_reason")) {
          const newFields = AddField.flatMap(
            (item: any) =>
              item?.fields?.filter(
                (items2: any) =>
                  items2.name === "other_lost_reason" ||
                  items2.name === "lost_reason"
              ) ?? []
          );
          setRenderField((prev: any) => [...prev, ...newFields]);
        }
      } else {
        setRenderField((prev: any) => {
          const filteredFields = prev.filter(
            (item: any) =>
              item.name !== "other_lost_reason" && item.name !== "lost_reason"
          );
          return filteredFields;
        });
      }
    }
  }, [values]);

  // dropdown enable&disabled
  const dropDownEnalbe = (field: any) => {
    if (field.is_dependant_field) {
      if (localStore?.isEnabled?.includes(field?.id)) {
        return true;
      }
    } else {
      return false;
    }
  };

  // dropdown list data*****
  useEffect(() => {
    if (
      test &&
      test?.name == "country_region" &&
      typeof dropDownListData !== "string"
    ) {
      setLocalStore({
        ...localStore,
        countryStates: dropDownListData,
      });
    } else if (
      test &&
      test?.name == "state_region" &&
      typeof dropDownListData !== "string"
    ) {
      setLocalStore({
        ...localStore,
        stateCity: dropDownListData,
      });
    } else {
      if (typeof dropDownListData === "string") {
        // showAlert(dropDownListData, "warning");
      }
    }
  }, [dropDownListData]);

  const getObjectValue = (field: any, value: any) => {
    setTest(field);
    if (field?.name === "lifecycle_stage") {
      getData("", field?.id, value?.id);
    }
    let dependentField = localStore.schemaName.find(
      (el: any) => el.dependant_field_id === field.id
    );
    if (
      field &&
      dependentField?.is_dependant_field &&
      !localStore.isEnabled?.includes(dependentField?.id)
    ) {
      setLocalStore({
        ...localStore,
        isEnabled: [...localStore.isEnabled, dependentField?.id],
      });
    }
    dropDownEnalbe(field);
  };

  // unDependent dropDownfields
  const unDependantFieldsDropDown = (id: any) => {
    let payload = {
      id,
    };
    dispatch(ContactsActions.getContactsDropDownForUndependant(payload))
      .then()
      .catch((error: any) => {
        showAlert("Field does not exists.", "warning");
      })
      .finally(() => {
        // props.setLoading(false);
      });
  };

  // getapi for  state and city ***************
  const getData = (type: string, parentId: string, selectedId: string) => {
    let payload = {
      parentId,
      selectedId,
    };
    dispatch(ContactsActions.getAddContactsDropDown(payload))
      .then()
      .catch((error: any) => {
        showAlert("Field does not exists.", "warning");
      })
      .finally(() => {
        // props.setLoading(false);
      });
  };

  useEffect(() => {
    if (
      localvalues?.lifecycle_stage &&
      localvalues?.lifecycle_stage?.label &&
      test.name === "lifecycle_stage"
    ) {
      setFieldValue("lifecycle_stage_status", "");
      let getStatusDropDown = ContactsLifeCycleFields.find((item: any) => {
        return item?.label === localvalues?.lifecycle_stage?.label;
      });
      let statusDropDown = getStatusDropDown?.statuses;
      statusDropDown.map((el: any) => {
        let a = {
          ...el,
          value: el.id,
        };
        setLifeCycleStatus((prev: any) => [...prev, a]);
      });
    }
  }, [localvalues]);

  useEffect(() => {
    if (test && test.name == "country_region") {
      getData(
        test.name,
        localvalues?.country_region?.parent_id,
        localvalues?.country_region?.id
      );
    } else if (test && test.name == "state_region") {
      getData(test.name, test?.id, localvalues?.state_region?.id);
    }
  }, [localvalues, test]);

  const DropDownOptions = (name: string, options: any) => {
    if (name === "state_region") {
      return localStore.countryStates;
    } else if (name === "lifecycle_stage") {
      return lifeCycleStage;
    } else if (name === "city") {
      return localStore.stateCity;
    } else if (name === "lifecycle_stage_status") {
      return lifeCycleStatus;
    } else {
      if (options?.length) {
        return options;
      } else {
        return unDepandantDropDownList;
      }
    }
  };

  // const RadioOptions = (arr: any[]) => {
  //   let modifiedArr = arr.map((i: any) => {
  //     let a = {
  //       name: i?.label,
  //       value: i?.label,
  //       id: i?.id,
  //     };
  //     return a;
  //   });
  //   return modifiedArr;
  // };
  return (
    <>
      <Grid sx={{ padding: "0.6rem" }}>
        {renderField.map((field: any) => (
          <React.Fragment key={"section_" + field?.name}>
            {field?.type === "dropdown" || field?.type === "multiselect" ? (
              <Grid>
                <AppReactSelectField
                  menuPosition="fixed"
                  filterOption={createFilter({
                    ignoreAccents: false,
                  })}
                  field={field}
                  onChangeCallbackFn={(value: any) => {
                    getObjectValue(field, value);
                  }}
                  label={field.label}
                  options={DropDownOptions(field?.name, field?.options)}
                  valueKey={field.value}
                  name={field.name}
                  placeholder={`Select ${field.label}`}
                  isMulti={field?.type === "multiselect" ? true : false}
                  // components={MenuList}
                  isDisabled={dropDownEnalbe(field)}
                />
              </Grid>
            ) : field?.type === "phone" ? (
              <Grid>
                <AppFormMobileField
                  label={field.label}
                  name={field.name}
                  placeholder="+1919991234567"
                  mobileCountryFormat={`mobileCountryFormat${field?.name}`}
                />
              </Grid>
            ) : field?.type === "landline" ? (
              <Grid
                item
                xs={6}
                sm={6}
                md={6}
                sx={{ display: "flex", gap: "10px" }}
              >
                <Grid item xs={3} sm={3} md={3}>
                  <AppFormField
                    label={"LandLine"}
                    name={`landlineExtension${field?.name}`}
                    placeholder={"Code"}
                    type="number"
                  />
                </Grid>
                <Grid item xs={9} sm={9} md={9}>
                  <AppFormField
                    divStyle={{ marginTop: "1.3rem" }}
                    label={""}
                    name={field.name}
                    placeholder={field.label}
                    type="number"
                  />
                </Grid>
              </Grid>
            ) : field?.type === "checkbox" ? (
              <AppFormCheckboxField name={field.name} label={field.label} />
            ) : field?.type === "datetime" ? (
              <Grid>
                <AppFormDatePicker
                  label={field.label}
                  name={field.name}
                  placeholder={field.label}
                />
              </Grid>
            ) : field?.type === "radio" ? (
              <Grid>
                <AppFormRadioField
                  label={field.label}
                  name={field.name}
                  Options={DropDownOptions(field?.name, field?.options)}
                  divStyle={{ marginTop: "12px" }}
                />
              </Grid>
            ) : (
              <Grid>
                <AppFormField
                  label={field.label}
                  name={field.name}
                  placeholder={field.label}
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
      </Grid>
    </>
  );
};
