import React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { ApexChatWrapper } from "./Doughnout";
import { TextTitle } from "./EmailBroadcastSummaryStyles";
import { WrapDeviceTable, WrapperDeviceTable } from "./DeviceTableStyles";
import { getRoundOffNumbers } from "../../../Utils";
import { ErrorComponent } from "../../Broadcast/SMS/SenderID/ListingStyles";
import SkeletonLoader from "../../../AppComponents/Loader/SkeletonLoader";
import NoData from "../../../assets/images/noDatafound.svg";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";

interface Props {
  data?: any[];
  loading?: boolean;
  deviceTablelength?: number;
}

interface RowProps {
  row: any;
  i: any;
  open: any;
  handleRowClick: any;
}

const Row: React.FC<RowProps> = ({ row, i, handleRowClick, open }) => {
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell id="arrow-icon">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleRowClick(i)}
          >
            {open === i ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" className="device-type-name">
          {row?.device_type}
        </TableCell>
        <TableCell className="table-cell">
          {getRoundOffNumbers(row?.opened_count || 0) || 0}
        </TableCell>
        <TableCell className="table-cell">
          {getRoundOffNumbers(row?.clicked_count || 0) || 0}
        </TableCell>
        <TableCell className="table-cell">
          {getRoundOffNumbers(row?.unsubscribed_count || 0) || 0}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} className="accordian-cell">
          <Collapse in={open === i} timeout="auto" unmountOnExit={true}>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableBody className="bodyThatOpen">
                  {row?.device_info?.map((historyRow: any, index: number) => (
                    <TableRow key={index} 
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className="device-cell"
                      >
                        {historyRow?.device}
                      </TableCell>
                      <TableCell align="center" className="device-count">
                        {getRoundOffNumbers(historyRow?.opened_count || 0) || 0}
                      </TableCell>
                      <TableCell align="center" className="device-count">
                        {getRoundOffNumbers(historyRow?.clicked_count || 0) ||
                          0}
                      </TableCell>
                      <TableCell align="center" id="device-count-unsubscribe">
                        {getRoundOffNumbers(
                          historyRow?.unsubscribed_count || 0
                        ) || 0}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const DeviceTable: React.FC<Props> = ({ data, loading, deviceTablelength }) => {
  const [open, setOpen] = React.useState(null);

  const handleRowClick = (rowIndex: any) => {
    setOpen((prevOpenRow) => (prevOpenRow === rowIndex ? undefined : rowIndex));
  };

  const hasNoData = data?.every((row: any) => row?.clicked_count === 0 && row?.opened_count === 0 && row?.total_count === 0);

  return (
    <WrapDeviceTable >

      {
        loading ? <AppSkeletonLoader /> : (
          <Box height={"inherit"} sx={{backgroundColor: "#fff"}}>
            <Box display={"flex"} alignItems={"center"}  >
              <ApexChatWrapper>
                <Box className="heading__tooltip">
                  <TextTitle>
                    <Typography className="heading">Device Stats</Typography>
                  </TextTitle>
                  <AppToolTip
                    tooltipProps={{
                      title:
                        "Insights into the types of devices used by recipients to interact with emails, providing information on the diversity of devices utilized for accessing email content.",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={
                      <AppMaterialIcons
                        iconName="HelpOutline"
                        style={{ fontSize: "14px" }}
                      />
                    }
                  />
                </Box>
              </ApexChatWrapper>
            </Box>

            <WrapperDeviceTable>
              {
                    hasNoData ? (
                      <NoRecordsFound
                        imageWidth={"200px"}
                        headerTextSize={"19px"}
                        imageSrc={NoRecordImage}
                      />
                    ) : (
                        <TableContainer
                          component={Paper}
                          className="table-container"
                          elevation={0}
                          style={{ height: "calc(100% - 16px)", margin: "8px", width: "calc(100% - 16px)" }}
                        >

                          <Table aria-label="collapsible table">
                            <TableHead className="table-head">
                              <TableRow>
                                <TableCell />
                                <TableCell> Device Type </TableCell>
                                <TableCell align="center">Opened</TableCell>
                                <TableCell align="center">Clicked&nbsp;</TableCell>
                                <TableCell align="center">Unsubscribed&nbsp;</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {data?.map((row: any, index: number) => (
                                <Row
                                  key={index}
                                  row={row}
                                  i={index}
                                  open={open}
                                  handleRowClick={handleRowClick}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                    )
              }

              
            </WrapperDeviceTable>       
          </Box>
        )
      }

     
    </WrapDeviceTable>
  );
};

export default DeviceTable;
