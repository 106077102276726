import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import { Pagination } from "@mui/material";
import AppReactSelect from "../AppReactSelect";

const AppPaginationComponent: React.FC<any> = (props: any) => {
  const {
    totalCount,
    handlePageChange,
    currentPage,
    totalPages,
    rowsPerPage,
    handleChangeRowsPerPage,
    title,
    hideCount,
  } = props;
  return (
    <FooterRow>
      {hideCount ? (
        ""
      ) : (
        <>
          Total {totalCount} {title || "Records"}
        </>
      )}
      <AppPagination
        style={{ marginLeft: "15px", marginRight: "15px" }}
        component="div"
        onChange={handlePageChange}
        // onRowsPerPageChange={handleChangeRowsPerPage}
        page={currentPage + 1}
        count={totalPages}
        rowsPerPage={rowsPerPage}
      />
      <AppReactSelect
        options={[
          { label: "10 per page", value: 10 },
          { label: "25 per page", value: 25 },
          { label: "50 per page", value: 50 },
        ]}
        // label="Rows per page:"
        name={"perPage"}
        placeholder="Select"
        // indent="horizontal"
        value={rowsPerPage}
        maxWidth="140px"
        onChangeCallbackFn={handleChangeRowsPerPage}
      ></AppReactSelect>
    </FooterRow>
  );
};

export default AppPaginationComponent;

const FooterRow = styled("p")`
  font-size: 13px;
  color: ${theme.palette.default.black};
  display: flex;
  align-items: center;
  padding: 8px 15px 6px;
`;
export const AppPagination = styled(Pagination)`
  & {
    .MuiPaginationItem-root {
      font-size: 13px;
      &.Mui-selected {
        background-color: ${theme.palette.primary.dark};
        color: ${theme.palette.default.white};
        border-radius: 6px;
      }
      &:hover {
        border-radius: 6px;
      }
    }
    .MuiTablePagination-toolbar {
      min-height: 30px;
    }
    .MuiSelect-select {
      border: 1px solid ${theme.palette.default.grey};
      border-radius: 6px;
    }

    p {
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-select {
      text-align: center !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-actions > button:last-child {
      margin-left: 8px;
    }
    .MuiTablePagination-actions > button {
      border: 1px solid ${theme.palette.default.grey} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.darkGrey};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
    .MuiTablePagination-actions .Mui-disabled {
      border: 1px solid ${theme.palette.default.RightBorderDivder} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.RightBorderDivder};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
  }
`;
