import { Box, Divider, Grid, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import {
  AppFormField,
  AppFormRadioField,
  AppFormSelectField
} from "../../Forms";
import {
  lifecycleStageOptions,
  listSourceNameOptions,
  whatShouldImportOptions,
  whatsappSubscriptionRadioOptions
} from "../Constants";
import MediaPicker from "./MediaPicker";

interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
  type: string;
}

const RenderFormStep2: React.FC<Props> = ({ formStep, setFormStep, type }) => {
  const SMS_SAMPLE_LIST_URL = '/sampleMarketingList/Geta_marketing_list_sample_sms.xlsx';
  const EMAIL_SAMPLE_LIST_URL = '/sampleMarketingList/Geta_marketing_list_sample_email.xlsx';
  const WHATSAPP_SAMPLE_LIST_URL = '/sampleMarketingList/Geta_marketing_list_sample_whatsapp.xlsx';
  // const SMS_SAMPLE_LIST_URL = require("../../../assets/sampleMarketingList/Geta_marketing_list_sample_sms.xlsx");
  // const EMAIL_SAMPLE_LIST_URL = require("../../../assets/sampleMarketingList/Geta_marketing_list_sample_email.xlsx");
  // const WHATSAPP_SAMPLE_LIST_URL = require("../../../assets/sampleMarketingList/Geta_marketing_list_sample_whatsapp.xlsx");

  const getCorrectSampleFileUrl = (type:string) => {
    if (type === "Email") {
      return EMAIL_SAMPLE_LIST_URL;
    } else if (type === "Sms") {
      return SMS_SAMPLE_LIST_URL;
    } else if (type === "Whatsapp") {
      return WHATSAPP_SAMPLE_LIST_URL;
    } 

    return "";
  }

  const { showAlert } = useNotification();
  const { setFieldTouched, errors, values, validateForm, touched } =
    useFormikContext<any>();
  const { data: authData } = useSelector(useAuthStore);

  const salesOwnerOptions = [
    {
      id: authData?.data?.user?.id,
      name:
        authData?.data?.user?.first_name +
        " " +
        authData?.data?.user?.last_name,
      value:
        authData?.data?.user?.first_name +
        " " +
        authData?.data?.user?.last_name,
      description: null,
    },
  ];
  const formkey1 = "name";
  const formkey2 = "list_source_name";
  const formkey3 = "upload_file";
  const formkey4 = "import_action";
  const formkey5 = "skip_duplicates_enabled";
  const formkey6 = "skip_duplicates_value";
  const formkey7 = "whatsapp_subscription";
  const formkey8 = "sms_subscription";
  const formkey9 = "email_subscription";
  const formkey10 = "sales_owner";
  const formkey11 = "lifecycle_stage";

  if (formStep !== 2) {
    return null;
  }
  return (
    <>
      <Box className="form-first__area" sx={{}}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <AppFormField
              label="List name"
              name={formkey1}
              placeholder="Enter list name"
              disabled={formStep === 2 ? true : false}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <AppFormSelectField
              label="List source name"
              Options={listSourceNameOptions}
              name={formkey2}
              // disabled={formStep === 2 ? true : false}
              disabled={true}
              defaultIndexValue={0}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="file-upload__area" sx={{}}>
        <Grid container spacing={2} sx={{ pt: "2rem" }}>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{}}>
              <Box className="file-upload__container">
                <MediaPicker
                  name={formkey3}
                  mediaType={"document"}
                  sizeLimit={1024000000}
                  extensions={[
                    // "text/csv",
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "application/vnd.ms-excel",
                    "application/wps-office.xlsx",
                  ]}
                  divStyle={{ margin: "0rem", width: "auto" }}
                />
                <Typography variant="body2">
                  ( .xlsx format supported )
                </Typography>
              </Box>
              {/* <AppErrorMessage
                error={getIn(errors, formkey3)}
                visible={getIn(touched, formkey3)}
              /> */}
              <div style={{ display: "inline-flex" }}>
                <Typography className="sample-csv" variant="body2" sx={{}}>
                  Import records using your own file, or use our {"  "}
                </Typography>
                <a
                  className="sample-csv-btn"

                  href={getCorrectSampleFileUrl(type)}
                  download
                >
                  {" "}
                  sample CSV
                </a>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{}} className="skip-duplicate">
              <AppFormSelectField
                label="What should import file do?"
                Options={whatShouldImportOptions}
                name={formkey4}
                disabled={true}
                defaultIndexValue={0}
              />


            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{}} className="subscriptions">
        <Typography variant="h6" sx={{}}>
          Subscriptions
        </Typography>
        <Divider />
        <Box sx={{ flexGrow: 1, mt: "0.7rem" }}>
          <Grid container spacing={2}>
            {type === "Whatsapp" && (
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="body2" sx={{}}>
                  Whatsapp subscriptions
                </Typography>
                <AppFormRadioField
                  name={formkey7}
                  Options={whatsappSubscriptionRadioOptions}
                />
              </Grid>
            )}
            {type === "Sms" && (
              <Grid item xs={12} sm={12} md={4}>
                <Typography variant="body2" sx={{}}>
                  SMS subscription
                </Typography>
                <AppFormRadioField
                  name={formkey8}
                  Options={whatsappSubscriptionRadioOptions}
                />
              </Grid>
            )}
            {type === "Email" && (
              <Grid item xs={12} sm={12} md={4}>
                <Typography sx={{}} variant="body2">
                  Email subscription
                </Typography>
                <AppFormRadioField
                  name={formkey9}
                  Options={whatsappSubscriptionRadioOptions}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
      <Box className="sales-and-lifecycle" sx={{}}>
        <Typography variant="h6">
          Manages sales owner and lifecycle stage
        </Typography>

        <Divider />

        <Box sx={{ flexGrow: 1, mt: "0.7rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <AppFormSelectField
                label="Sales owner"
                Options={salesOwnerOptions}
                name={formkey10}
                disabled={true}
                defaultIndexValue={0}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AppFormSelectField
                label="Lifecycle stage"
                Options={lifecycleStageOptions}
                name={formkey11}
                disabled={true}
                defaultIndexValue={0}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>

          <Typography variant="body2" sx={{}}>
            Sales owner will be mapped to all unassigned leads
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default RenderFormStep2;
