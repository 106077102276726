// vendors
import { styled } from "@mui/material/styles";
import Stack, { StackProps } from "@mui/material/Stack";
import Box, { BoxProps } from "@mui/material/Box";

interface IBoxProps extends BoxProps {
  nodeColor: string;
}

interface IStackProps extends StackProps {
  noPadding?: boolean;
}

export const IconContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== "nodeColor";
  },
})<IBoxProps>(({ nodeColor, theme }) => ({
  height: "32px",
  width: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "6px",
  marginRight: "12px",
  backgroundColor: nodeColor ? nodeColor : theme.palette.primary.main,
}));


export const StackContainerStyled =styled(Stack, {
  shouldForwardProp: (prop) => {
    return prop !== "noPadding";
  },
})<IStackProps>(({ noPadding }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: noPadding ? 0 : '12px 16px',
}));