import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AppButton from "../../../../../AppComponents/AppButton";
import { AppForm, AppSubmitButton } from "../../../../../AppComponents/Forms";
import { LoaderComponentProps, withLoader } from "../../../../../AppComponents/Loader";
import {
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox,
} from "../../../../../Customization/CommonElements";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import { BlackListUrlSchema } from "../../../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { formatString } from "../../../../../Utils";
import BlacklistUrlFieldArray from "./BlacklistUrlFieldArray";

interface Props extends LoaderComponentProps { }

const BlacklistUrl: React.FC<Props> = ({ setLoading }) => {
  const location: any = useLocation();
  const currentBot = useSelector(useCurrentBot);
  const navigate = useNavigate();
  const { showAlert } = useNotification();

  let URL = formatString("/bot/{0}/setup/faq", [currentBot.bot_id]);

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };

  const [userInput, setUserInput] = useState<any>({
    blacklisted_urls: [],
  });

  const [dynamicVaritionValue, setDynamicVaritionValue] = useState<string>("https://");
  const [urlData, setUrlData] = useState<any>([]);

  const getTableData = () => {
    ChatbotConsoleService.getBotConfiguration(
      currentBot?.chatbot_configuration as string
    ).then((res: AxiosResponse<ChatbotConfiguration>) => {
      setLoading(false)
      if (res.data?.urls) {

        if (res.data?.urls?.blacklisted_urls) {
          setUserInput({
            ...userInput,
            blacklisted_urls: res.data?.urls?.blacklisted_urls,
          });
          setUrlData(res.data?.urls?.blacklisted_urls);
        }
      }
    }).catch((error: any) => {
      setLoading(false)
    });
  };

  useEffect(() => {
    if (currentBot?.bot_id && currentBot?.chatbot_configuration) {
      getTableData();
    }
    else {
      setLoading(false)
    }
  }, [currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateBtn = (values: any, SubmitProps: any) => {
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          urls: {
            blacklisted_urls: values?.blacklisted_urls,
          },
        },
      }
    )
      .then((response) => {
        SubmitProps.setSubmitting(false);
        SubmitProps.resetForm();
        showAlert("Configuration updated successfully", "success");
        getTableData();

      })
      .catch((error) => {
        SubmitProps.setSubmitting(false);
        showAlert(error.response.statusText, "error");
      });
  };

  return (
    <AppForm
      initialValues={userInput}
      onSubmit={(values: any, submitProps: any) => {
        handleUpdateBtn(values, submitProps);
      }}
      validationSchema={BlackListUrlSchema}
    >
      <SettingPageMainBoxWrap>
        <SettingPageMainBoxTopbar>
          <SettingPageMainBoxTitle>Blacklist Urls</SettingPageMainBoxTitle>
          {/* <AppButton
            startIcon="ri-add-line"
            onClick={() => setDynamicVaritionValue("https://")}
          >
            Blacklist Url
          </AppButton> */}
          <AppSubmitButton title="Save Changes" />
        </SettingPageMainBoxTopbar>

        <SettingPageMainContentBox
          style={{
            alignItems: "flex-start",
            overflowX: "hidden",
            padding: "1rem 0",
          }}
        >
          <BlacklistUrlFieldArray
            setDynamicVaritionValue={setDynamicVaritionValue}
            dynamicVaritionValue={dynamicVaritionValue}
            name="blacklisted_urls"
          />
          {/* <div
            style={{ width: "100%", marginLeft: "3.5rem", marginTop: "1rem" }}
          >
            <AppSubmitButton variant="outline" title="Save Changes" />
          </div> */}
        </SettingPageMainContentBox>
      </SettingPageMainBoxWrap>
    </AppForm>
  );
};

export default withLoader(BlacklistUrl);
