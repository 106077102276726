import styled from "styled-components";
import { Box } from "@mui/material";
import { theme } from "../../../../Customization/Theme";

export const DependencyListWrapper = styled(Box)`
  && {
    height: 100%;
  }
`;

export const DependencyListTopBar = styled(Box)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${theme.palette.default.white};
    padding: 12px 16px;
    margin-bottom: 15px;
    .heading {
      font-size: 1rem;
      font-weight: 500;
      color: ${theme.palette.default.black};
    }
  }
`;
