import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { withLoader } from "../../../../AppComponents/Loader";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { EmailSettingsActions } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.actions";
import { domainLists } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.selectors";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import { isDomainDeleted } from "../../../../Store/Slices/email/email.selectors";
import { resetDelete, verifyActiveStep } from "../../../../Store/Slices/email/email.slice";
import { DomainListing } from "../Styles";
import DomainDetails from "./DomainDetails";
// import { resetDelete, verifyActiveStep } from "../../../../Store/Slices/email/email.slice";
import { useNavigate, useParams } from "react-router-dom";
// import { LoaderComponentProps, withLoader } from "../../../../AppComponents/Loader";
// import { isDomainDeleted } from "../../../../Store/Slices/email/email.selectors";
import { theme } from "../../../../Customization/Theme";
interface Props {
    userInput?: any;
    setUserInput: any;
    setLoading?: any;
}
const DomainList: React.FC<Props> = ({
    userInput,
    setUserInput,
    setLoading,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [detailView, setDetailView] = useState<boolean>(false);
    const domainsList = useAppSelector(domainLists);
    const isDeleted = useAppSelector(isDomainDeleted);
    const [selectedDomain, setSelectedDomain] = useState<any>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        dispatch(EmailSettingsActions.getDomainLists({}));
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    useEffect(() => {
        if (domainsList?.length) {
            setLoading(false);
        }
        if (isDeleted) {
            // setLoading(false);
            dispatch(resetDelete({}));
            dispatch(EmailSettingsActions.getDomainLists({}));
        }
    }, [domainsList, isDeleted]) // eslint-disable-line react-hooks/exhaustive-deps
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (window.location.search?.includes("isEdit")) {
            const urlSearch: any = JSON.parse('{"' + decodeURI(window.location.search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
            if (domainsList?.length > 0) {
                setSelectedDomain(domainsList[0]);
                setDetailView(true);
            }
            setLoading(false);
        }
    }, [window?.location?.search]) // eslint-disable-line react-hooks/exhaustive-deps
    const handleViewDetails = (item: any) => {
        if (item?.domain) {
            setDetailView(true);
            navigate(`/settings/channels/email/3?isEdit=${item?.domain}`)
        }
        setSelectedDomain(item);
    }

    const handleDeleteDomain = (item: any) => {
        setLoading(true)
        dispatch(EmailActions.deleteEmailDomain(item))
    }

    const editDomain = (item: any) => {
        setUserInput({
            ...userInput,
            domain: item.domain,
            isVerification: true
        })
        dispatch(verifyActiveStep(3))
    }

    return (<DomainListing>
        {!detailView && domainsList ? domainsList?.map((item: any) => {
            return <Box className="listWrapper">
                <Box className="listLeft">                
                    <Box className="column">
                        <label>Domain</label>
                        <div className="info" title={item.domain}>
                            {item?.domain}
                        </div>
                    </Box>
                    <Box className="column">
                        <label>Verification Status</label>
                        <div className={`info status ${item.spf ? "green" : "red"}`}>
                            {item?.spf ? "Verified" : "Unverified"}
                        </div>
                    </Box>
                    <Box className="column">
                        <label>Bounce Domain</label>
                        <div className="info status green">
                            <div className="tag successTag"></div>
                            {item?.custombouncesdomain}
                        </div>
                    </Box>
                </Box>


                <Box className="">
                    <IconButton id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                         
                    >
                        <AppMaterialIcons iconName='MoreVertOutlined' />
                    </IconButton>
                    <Menu
                        sx={{
                            
                            "& .MuiPaper-root": {
                              borderRadius: 1,
                              boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.1)",
                            },
                          }}
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "center",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Box sx={{ width: "168px", color: theme.palette.default.darkGrey}}>
                        <MenuItem  onClick={() => { handleViewDetails(item); handleClose(); }}>View details</MenuItem><Divider />
                        {!item?.spf ? <><MenuItem onClick={() => { editDomain(item); handleClose(); }}>Verify Domain</MenuItem><Divider /></> : <><MenuItem onClick={() => { editDomain(item); handleClose(); }}>Edit Domain</MenuItem><Divider /></>}
                        <MenuItem  onClick={() => { handleDeleteDomain(item); handleClose(); }}>Delete</MenuItem>
                        </Box>
                    </Menu>
                </Box>
            </Box>
        }) : <DomainDetails selectedDomain={selectedDomain} setDetailView={setDetailView}></DomainDetails>}

    </DomainListing>)

}

export default withLoader(DomainList);
