import { Close } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { renameContactViewSchema } from "../../../Models/ValidationSchema";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import {
  contactsViewsAccessOptions,
  contactsViewsShareWithOptions,
} from "./constants";
import { UserNetworkService } from "../../../Store/Slices/Settings/LiveChat/User/UserNetworkService";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { getReactSelectCurrentValue, getReactSelectMultiCurrentValues } from "../../../Utils";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../HOC/HocBackdropLoader";
import { userListigData } from "../../../Store/Slices/MarketingList/marketingList.selectors";
interface Props extends HocBackdropLoaderProps{
  status: any;
  setEditDrawerStatus: any;
  selectedView: any;
}

const EditViewDrawer  = ({
  status,
  setEditDrawerStatus,
  selectedView,
  setLoading,
}: Props) => {
  const dispatch = useAppDispatch();
  const formikRef = useRef<any>(null);
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const currentBot = useAppSelector(useCurrentBot);
  const { data: authData } = useAppSelector(useAuthStore);

  const [usersListingData, setUsersListingData] = useState([]);
  const [isAccessFieldShown, setIsAccessFieldShown] = useState(false);
  const [isSelectedUsersFieldShown, setIsSelectedUsersFieldShown] =
    useState(false);

  const [userInput, setUserInput] = useState({
    name: selectedView?.name,
    shared_with:
      getReactSelectCurrentValue(
        contactsViewsShareWithOptions,
        selectedView?.shared_with,
        "value",
        false
      ) ?? contactsViewsShareWithOptions?.[0],
    access_type:
      getReactSelectCurrentValue(
        contactsViewsAccessOptions,
        selectedView?.access_type,
        "value",
        false
      ) ?? contactsViewsAccessOptions?.[0],
    user_list:[],
  });

  const getUserListing = () => {
    const HEADERS = {
      appid: JSON.stringify({
        botid: currentBot?.bot_id,
      }),
    };

    const data = {
      HEADERS,
      limit: 50,
      offset: 0,
    };
    setLoading(true);
    UserNetworkService.getUserListing(data)
      .then((res: any) => {
        const modifiedIds = selectedView?.user_list?.filter((id: any) => authData?.data?.user?.id !== id);
        let list = res?.data?.data?.map((item: any) => {
          let obj: any = {
            label: item?.first_name + " " + item?.last_name,
            ...item,
          };
          return obj;
        });

        list = list?.filter((item: any) => item?.id !== authData?.data?.user?.id);
        setUsersListingData(list ?? []);
        setUserInput((prev) => ({
          ...prev,
          user_list: getReactSelectMultiCurrentValues(
            list ?? [],
            modifiedIds,
            "id",
            true
          ),
        }));
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitBtn = (values: any, submitProps: any) => {
    let shared_with = values?.shared_with?.value;
    let access_type = shared_with === "me" ? "can_edit" :values?.access_type?.value;
    let user_list = shared_with === "selected_users" ? (values?.user_list?.length > 0 ? values?.user_list?.map((user: any) => user?.id ) : null) : shared_with === "everyone" ? usersListingData?.map((user: any) => user?.id ) : null;

    let payload = {
      id: selectedView?.viewId,
      data: {
        ...selectedView,
        name: values?.name,
        shared_with,
        access_type,
        user_list,
      },
    };
    setLoading(true);
    ContactsNetworkService.updateContactView(payload)
      .then((res: any) => {
        showAlert("View updated", "success");
        dispatch(ContactsActions.getAllContactsViews({}));
        navigate(`/contacts/list?view=${selectedView?.viewId}`);
        // submitProps.resetForm();
      })
      .catch((err: any) => {
        console.log("err", err);
        showAlert("Something went wrong", "error");
      })
      .finally(() => {
        setEditDrawerStatus(false);
        submitProps.setSubmitting(false);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUserListing();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!selectedView) return;

    if (userInput?.shared_with?.value === "me") {
      isAccessFieldShown && setIsAccessFieldShown(false);
      isSelectedUsersFieldShown && setIsSelectedUsersFieldShown(false);
    }

    if (userInput?.shared_with?.value === "everyone") {
      !isAccessFieldShown && setIsAccessFieldShown(true);
      isSelectedUsersFieldShown && setIsSelectedUsersFieldShown(false);
    }

    if (userInput?.shared_with?.value === "selected_users") {
      !isAccessFieldShown && setIsAccessFieldShown(true);
      !isSelectedUsersFieldShown && setIsSelectedUsersFieldShown(true);
    }
  }, [userInput]); //eslint-disable-line

  return (
    <>
      <StyledDrawerHeader>
        <p className="title">Rename View</p>
        <IconButton
          onClick={(e: any) => setEditDrawerStatus(false)}
          title="close"
        >
          <Close />
        </IconButton>
      </StyledDrawerHeader>

      <AppForm
        innerRef={formikRef}
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any, event: any) => {
          event?.preventDefault();
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={renameContactViewSchema}
        divStyle={{
          flex: 1,
        }}
      >
        <StyledDrawerWrapper>
          <AppFormField
            name="name"
            placeholder="Untitled view"
            label="Name of view"
            requiredSign={true}
          />

          <AppReactSelectField
            name="shared_with"
            options={contactsViewsShareWithOptions ?? []}
            label="Share with"
            valueKey={"value"}
            hideSelectedOptions={false}
            onChangeCallbackFn={(value: any) => {
              setIsAccessFieldShown(value?.value === "me" ? false : true);
              setIsSelectedUsersFieldShown(
                value?.value === "selected_users" ? true : false
              );
              formikRef?.current?.setFieldValue("user_list", []);
              formikRef?.current?.setFieldValue(
                "access_type",
                contactsViewsAccessOptions?.[0]
              );
            }}
          />
          {isAccessFieldShown ? (
            <AppReactSelectField
              name="access_type"
              options={contactsViewsAccessOptions ?? []}
              label="Access level"
              valueKey={"value"}
              hideSelectedOptions={false}
              onChangeCallbackFn={(value: any) => {
                formikRef?.current?.setFieldValue("user_list", []);
              }}
            />
          ) : null}
          {isSelectedUsersFieldShown ? (
            <AppReactSelectField
              name="user_list"
              options={usersListingData ?? []}
              label="Selected users"
              valueKey={"id"}
              displayKey="label"
              isMulti={true}
              isOptionDisabled={(option: any) => {
                if (option?.id === authData?.data?.user?.id) {
                  return true;
                } else {
                  return false;
                }
              }}
            />
          ) : null}
        </StyledDrawerWrapper>
        <StyledDrawerFooter>
          <AppButton
            variant="grey"
            onClick={(e: any) => setEditDrawerStatus(false)}
          >
            Cancel
          </AppButton>
          <AppSubmitButton
            title="Update"
            checkIsValidProperty={false}
            onClick={(event: any) => event.stopPropagation()}
          >
            {" "}
            update
          </AppSubmitButton>
        </StyledDrawerFooter>
      </AppForm>
    </>
  );
};

export default HocBackdropLoader(memo(EditViewDrawer));

const StyledDrawerWrapper = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    flex: 1;
    padding: 1rem 2rem;
    height: calc(100vh - 128px);
    overflow-y: overlay;
    overflow-x: hidden;
    .inlineFields {
      display: flex;
      gap: 1rem;
    }
    .basicInfoBox {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 0rem 0 1rem;
      .row {
        display: flex;
        gap: 1rem;
        align-items: center;
        .label {
          font-weight: 500;
          font-size: 15px;
        }
        .value {
          font-weight: 400;
          font-size: 15px;
        }
      }
    }
  }
`;

const StyledDrawerFooter = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    padding: 1rem 2rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
const StyledDrawerHeader = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    .title {
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;

      svg {
        font-size: 21px;
        margin-right: 8px;
      }
    }
  }
`;
