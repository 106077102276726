// vendors
import { useState, useEffect, useContext } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

// contexts
import { BuilderContext } from "../../../../flow-builder-v2/contexts";

// components
import { Icon, UIButton } from "../../../../ui-components";

// hooks
import { useFetchFlowById, useAddNewNode } from "../../../service-hooks";
import { getText } from "../../../utils/helper";

interface Props {
  activeFlowId: string;
  flowOrder: number;
  onNodeSelect: (qId: string, nodeId: string, order: number) => void;
  selectedNode?: string;
  connectedFlow: any;
}

const EmptyState = () => (
  <Stack direction="column" alignItems="center" sx={{ py: "16px" }}>
    <Icon icon="exclamation-triangle" size={64} color="#7E8392" />
    <Typography
      fontSize={"12px"}
      lineHeight="18px"
      fontWeight={400}
      color="#7E8392"
    >
      No existing nodes
    </Typography>
  </Stack>
);

function strip(html: string) {
  let doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
}

const DisplayNodes = (props: Props) => {
  const { onNodeSelect, flowOrder, activeFlowId, selectedNode, connectedFlow } = props;
  const [selectedNodeId, setSelectedNode] = useState<string>('');
  const [nodes, setNodes] = useState<Array<any> | null>(null);
  const { data, loading, getNodes } = useFetchFlowById(activeFlowId);
  const { addNode } = useAddNewNode();
  const builderContext = useContext(BuilderContext); 
  const { onAutomateNodeCreate } = builderContext;

  useEffect(() => {
    setNodes(data);
  }, [data]);

  const handleCreateNewNode = () => {
    if(data !== null && data.length > 0) {
      addNode(activeFlowId, `${flowOrder}.${data.length + 1}`, data.length + 1 , (success: boolean, payload: any) => {
        if (success) {
          onAutomateNodeCreate(payload, activeFlowId);
          getNodes(activeFlowId).then((response: any) => {
            setNodes(response);
          });
        }
      });
    } else {
      addNode(activeFlowId, `${flowOrder}.1`, 1, (success: boolean) => {
        if (success) {
          getNodes(activeFlowId).then((response: any) => {
            setNodes(response);
          });
        }
      });
    }

  };

  const onNodeClick = (node: any, order: number) => {
    setSelectedNode(node.nodeId);
    onNodeSelect && onNodeSelect(node.qid, node.nodeId, order);
  }

  return (
    <Box sx={{ p: "16px" }}>
      <UIButton
        variant="outlined"
        fullWidth
        startIcon={<Icon icon="add" size={16} />}
        onClick={handleCreateNewNode}
      >
        New Node
      </UIButton>
      <Box sx={{ maxHeight: "200px", overflowY: "auto", mt: '12px' }}>
        {loading ? (
          "fetching nodes"
        ) : nodes === null ? (
          <EmptyState />
        ) : (
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
            }}
            component="nav"
          >
            {nodes.map((node: any, index: number) => {
              const text = strip(
                getText(node.response)?.replace(/[\n\t\r]/g, "")
              );
              const active = connectedFlow?.nodeId === node?.nodeId;
              return (
                <ListItem
                  disablePadding
                  sx={{
                    "&:hover": { backgroundColor: "#F5F6F8" },
                  }}
                >
                  <ListItemButton
                    sx={{
                      fontSize: "14px",
                      lineHeight: "21px",
                      fontWeight: 400,
                      color: "#7E8392",
                      "&:hover": { backgroundColor: "transparent" },
                      ...(active && { backgroundColor: "#F5F6F8" }),
                    }}
                    onClick={() => onNodeClick(node, index + 1)}
                  >
                    <Typography
                              fontWeight={400}
                              fontSize={14}
                              lineHeight="21px"
                              color="#7E8392"
                            >
                    {`${props.flowOrder}.${index + 1} `}
                    {text.length > 40 ? `${text.slice(0, 30)}...` : text}
                    </Typography>
                    {active && <Box sx={{marginLeft: 'auto'}}>
                      <Icon icon="done" size={14} color="#2ECC71" />
                    </Box>}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    </Box>
  );
};
export default DisplayNodes;
