import { Typography } from "@mui/material";
import noData from "../assets/images/noDatafound.svg";
import { NoDataBox } from "../Customization/CommonElements";

interface Props {
  [otherProps: string]: any;
}

const AppNoDataLoader: React.FC<Props> = ({ ...otherProps }) => {
  return (
    <NoDataBox {...otherProps}>
      <img src={noData} alt="no data available" />
      <Typography variant="h6" fontSize="20px">
        No data available.
      </Typography>
    </NoDataBox>
  );
};

export default AppNoDataLoader;
