import { FieldArray, getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";
import {
    AppFormFastField,
    AppFormSelectFastField,
    AppFormTextArea,
} from "../../../../AppComponents/Forms";
import { WhatsappNetworkService } from "../../../../Store/Slices/Whatsapp/WhatsappNetworkService";
import { makeStringFirstLetterUppercase } from "../../../../Utils";
import WhatsappMediaPicker from "../../../Broadcast/WhatsApp/Templates/components/WhatsappMediaPicker";
import { generateResolveVariables } from "../../../Broadcast/WhatsApp/Utils/utils";

const IMAGE_TYPES = ["image/jpeg", "image/png"];
const VIDEO_TYPES = ["video/mp4", "video/3gp"];
const AUDIO_TYPES = ["audio/aac", "audio/mp4", "audio/mpeg", "audio/amr"];
const STICKER_TYPES = ["image/webp"];

const DOCUMENT_TYPES = [
  "txt/plain",
  "application/pdf",
  "application/document",
  "application/vnd.ms-powerpoint",
  "application/msword",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
// const MediaObj: any = ["image", "document", "video"];
const tempOption: any = {
  label: "Value + Text",
  name: "value_text",
  placeholder: "Enter Your value",
  type: "text",
  valueEditorType: "input",
};
const getCorrectMediaFormats = (mediaType: string) => {
  if (mediaType === "video") {
    return VIDEO_TYPES;
  } else if (mediaType === "image") {
    return IMAGE_TYPES;
  } else if (mediaType === "document") {
    return DOCUMENT_TYPES;
  } else if (mediaType === "sticker") {
    return STICKER_TYPES;
  } else if (mediaType === "audio") {
    return AUDIO_TYPES;
  } else {
    return [];
  }
};
interface Props {
  // selectedTemplateData: any;
}

const SmsBroadcastResolveVariables: React.FC<Props> = () => {
  const { values, setValues} = useFormikContext<any>();
  let [filterFieldData, setFilterFieldData] = React.useState<any>([]);

  const selectedTemplateData = getIn(values, "template");

  useEffect(() => {
    WhatsappNetworkService.getFieldsTypeMappingList("").then((res: any) => {
      let mappingFields: any = res?.data?.mappingFields || [];
      let list = [tempOption, ...mappingFields];
      setFilterFieldData(list);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const header_type = selectedTemplateData?.components?.header?.header_type;
    const a =
      header_type === "text"
        ? generateResolveVariables(
            selectedTemplateData?.components?.header?.value?.value,
            selectedTemplateData?.components?.header?.value?.header_text
          )
        : [];
    const c =
      header_type !== "text"
        ? selectedTemplateData?.components?.header?.value
        : null;
    const b =
      generateResolveVariables(
        selectedTemplateData?.components?.body?.value,
        selectedTemplateData?.components?.body?.body_text
      ) || [];

    const d =
      header_type === "media"
        ? [
            {
              key: "{{1}}",
              value: "",
              index: 0,
              type: header_type,
            },
          ]
        : a;

    let newValues = {
      ...values,
      resolved_header_variables: d,
      header_media: c,
      header_type: header_type,
      header_media_type: selectedTemplateData?.components?.header?.value?.type,
      header_value: selectedTemplateData?.components?.header?.value?.value,
      body_preview: selectedTemplateData?.components?.body?.value,
      resolved_body_variables: b,
      resolved_buttons_variables: [],
      footer_value: selectedTemplateData?.components?.footer?.value,
    };

    setValues(newValues);
  }, [selectedTemplateData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!selectedTemplateData) return null;

  return (
    <>
      <StyledVariblesBox className="styledVariblesBox">
        <AppFormTextArea
          name={"body_preview"}
          label="Body Preview"
          placeholder="body preview"
          rows={3}
          disabled={true}
        />
        {getIn(values, "resolved_header_variables")?.length > 0 ||
        getIn(values, "resolved_body_variables")?.length > 0 ? (
          <p className="variablesTitle">{"Resolve custom variables"}</p>
        ) : null}
        {getIn(values, "resolved_header_variables")?.length > 0 && (
          <RenderSingleTypeVariables
            name={"resolved_header_variables"}
            variableType={
              "Header"
            }
            mediaType={
              getIn(values, "template")?.components?.header?.value?.type
            }
            isMedia={values?.header_type === "media" ? true : false}
            fieldsData={filterFieldData}
          />
        )}
        {getIn(values, "resolved_body_variables")?.length > 0 && (
          <RenderSingleTypeVariables
            name={"resolved_body_variables"}
            variableType="Body"
            fieldsData={filterFieldData}
          />
        )}
      </StyledVariblesBox>
    </>
  );
};

export default SmsBroadcastResolveVariables;

interface RenderSingalProps {
  variableType: string;
  name: string;
  isMedia?: boolean;
  fieldsData: any;
  mediaType?: string;
}
const RenderSingleTypeVariables: React.FC<RenderSingalProps> = ({
  variableType,
  name,
  isMedia = false,
  fieldsData = [],
  mediaType = "",
}) => {
  const { values } = useFormikContext<any>();
  if (getIn(values, name)?.length === 0) {
    return null;
  }

  return (
    <>
      <p className="variablesType">
        {makeStringFirstLetterUppercase(variableType)}:
      </p>

      <FieldArray name={name}>
        {(arrayHelpers) => (
          <>
            {getIn(values, name)?.length > 0 &&
              getIn(values, name)?.map((item: any, index: number) => {
                const textfieldName: string = `${name}.${index}.value`;
                const selectfieldName: string = `${name}.${index}.index`;
                return (
                  <div className="variablesContentt" key={index.toString()}>
                    <p className="variableIndex">{item?.key as string}</p>
                    {isMedia ? (
                      <WhatsappMediaPicker
                        name={textfieldName}
                        isResolveVariable={true}
                        supportedFormat={getCorrectMediaFormats(mediaType)}
                        mediaType={mediaType}
                        divStyle={{ marginTop: "0.3rem" }}
                        tooltipTitle={
                          "The supported format is mp4. The video size must be smaller than 16MB."
                        }
                      />
                    ) : (
                      <>
                        {fieldsData?.length > 0 && (
                          <AppFormSelectFastField
                            Options={fieldsData}
                            name={selectfieldName}
                            valueKey="name"
                          />
                        )}
                        <AppFormFastField
                          name={textfieldName}
                          placeholder={
                            getIn(values, selectfieldName) === "value_text"
                              ? "Static value"
                              : "Fallback Value"
                          }
                        />
                      </>
                    )}
                  </div>
                );
              })}
          </>
        )}
      </FieldArray>
    </>
  );
};

const StyledVariblesBox = styled.div`
  && {
    .variablesTitle {
      font-size: 16px;
      font-weight: 500;
      margin: 0.5rem 0;
    }

    .variablesType {
      font-size: 15px;
      font-weight: 500;
    }
    .variablesContentt {
      display: flex;
      align-items: flex-start;
      gap: 1rem;

      .variableIndex {
        padding-top: 0.8rem;
        display: flex;
        height: 100%;
      }
    }

    .StyledUploadedImageWrap {
      margin: 0px;
    }
    .MediaUploadBox {
      margin: 0px;
    }
  }
`;
