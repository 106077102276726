import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormField,
  AppFormObserver,
  AppFormRadioField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../../AppComponents/Forms/AppFormReactSelect";
import { AppQueryBuilder } from "../../../../../AppComponents/QueryBuilder";
import {
  convertQueryToMongo,
  initialQuery,
  isQueryValid,
} from "../../../../../AppComponents/QueryBuilder/utils/utils";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { AutoAssignmentSchema } from "../../../../../Models/ValidationSchema";
import { automationAssignmentTabsOptions } from "../../../../../Models/defaultSelectOptions";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { ContactsActions } from "../../../../../Store/Slices/Contacts/contacts.action";
import { AssigneeList } from "../../../../../Store/Slices/Contacts/contacts.selector";
import { QueryBuilderActions } from "../../../../../Store/Slices/QueryBuilder/QueryBuilder.actions";
import { useQueryBuilderStore } from "../../../../../Store/Slices/QueryBuilder/QueryBuilder.selectors";
import { AutoAssignmentRulesSettingsActions } from "../../../../../Store/Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.actions";
import { contactRulesById } from "../../../../../Store/Slices/Settings/Automation/AutoAssignmentRules/autoAssignmentRulesSettings.selectors";
import { ContactMarketingWrap } from "../../../../ContactMarketingList/ContactMarketingListStyle";
import { getapplicationFieldsMapping } from "../../../../../Store/Slices/ApplicationsForm/applicationForm.selectors";
import { ApplicationFormNetworkActions } from "../../../../../Store/Slices/ApplicationsForm/applicationForm.actions";
import { useAuthStore } from "../../../../../Store/Slices/AuthSlice";
import { getRestrictedMenuOptions } from "../../../../../Utils/rolesUtils";

interface Props {
  getTableData?: any;
}
const ContactsAutoAssignmentRule: React.FC<Props> = ({ getTableData }) => {
  const { showAlert } = useNotification();
  const param = useParams();
  const { id } = param;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const editData = useAppSelector(contactRulesById);
  const { userConfig } = useAppSelector(useAuthStore);
  const { queryBuilderFields, queryBuilderOperators, loading: contactLoading } =
    useAppSelector(useQueryBuilderStore);
  const { applicationsFieldsMapping, applicationsOperators, loading: applicationLoading } = useAppSelector(getapplicationFieldsMapping)
  const [assignData, setAssignData] = useState<string[]>([]);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const autoAssignmentTabsOptionsMemo = useMemo(() => {
    const permissionsData: any = userConfig?.payload?.allowed_permissions ?? null;
    const result = getRestrictedMenuOptions(automationAssignmentTabsOptions, permissionsData) ?? [];
    return result;
  }, [location]); // eslint-disable-line

  const [automationRuleValues, setAutomationRuleValues] = useState<any>({
    name: "",
    description: "",
    trigger: "contact_add",
    owner: [],
    filter_query: initialQuery,
  });

  // assignee list data from redux
  const assigneeList: any = useAppSelector(AssigneeList);
  // get assign list data
  const getAssignee = async () => {
    try {
      await dispatch(ContactsActions.getAssigneeList({}));
    } catch (error: any) {
      showAlert(
        error?.response?.statusText || "Enabled to fetch Data",
        "error"
      );
    }
  };

  // get contact rule data by id
  const getContactRulesById = async () => {
    try {
      await dispatch(
        AutoAssignmentRulesSettingsActions.getContactsRulesById(id)
      ).then((res) => {
        const trigger = res?.payload?.data?.assignment_rule?.trigger
        if (["contact_add", "contact_update", "contact_add_or_update"].includes(trigger))
          setSelectedTabIndex(0)
        else
          setSelectedTabIndex(1)
      });
    } catch (error: any) {
      showAlert(
        error?.response?.statusText || "Enabled to fetch Data",
        "error"
      );
    }
  };

  useEffect(() => {
    if (assigneeList?.length > 0) {
      let list: string[] = assigneeList?.map((res: any) => {
        let obj: any = {
          label: res?.first_name + " " + res?.last_name,
          value: res?.id,
        };
        return obj;
      });
      setAssignData(list);
    }
  }, [assigneeList]);

  useEffect(() => {
    if (editData?.rule_id) {
      insertPreviousFormValues(editData);
    }
  }, [editData, assignData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id) {
      getContactRulesById();
    }
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const insertPreviousFormValues = (data: any) => {
    if (!data?.rule_id || editData?.filter_query?.rules === 0) return;
    setAutomationRuleValues({
      name: editData?.name,
      description: editData?.description,
      trigger: editData?.trigger,
      owner: editData?.owners,
      filter_query: editData?.filter_query,
    });
  };

  const handleSubmitBtn = async (values: any, SubmitProps: any) => {
    try {
      const validQuery = isQueryValid(values?.filter_query);
      if (!validQuery) {
        setIsActive(false);
        showAlert("Please add only valid rules.", "error");
        SubmitProps.setSubmitting(false);
        return;
      }

      const mongoQuery = convertQueryToMongo(values?.filter_query);
      const data = {
        mongodb_query: mongoQuery,
        filter_query: values?.filter_query,
        name: values?.name?.trim(),
        trigger: values?.trigger?.value,
        is_active: isActive,
        owners: values?.owner,
        description: values?.description,
        rule_type: selectedTabIndex === 0 ? "contact" : "application"
      };

      const endpoint = editData?.rule_id
        ? AutoAssignmentRulesSettingsActions.UpdateContactsRulesById
        : AutoAssignmentRulesSettingsActions.createContactRules;

      const payload = editData?.rule_id
        ? { data, rule_id: editData?.rule_id }
        : data;

      const res = await dispatch(endpoint(payload));
      if (res?.payload?.data?.Status === "Success") {
        const successMessage = editData?.rule_id
          ? "Assignment rules is updated"
          : "Assignment rules Created";

        showAlert(successMessage, "success");
        navigate("/settings/automation/auto-assignment/rules/contacts");
        getTableData();
      } else {
        showAlert(res?.payload?.data?.error, "error");
      }
    } catch (err) {
      console.error("Error while creating/updating assignment rules", err);
    } finally {
      SubmitProps.setSubmitting(false);
      setIsActive(false);
    }
  };

  const getFieldsDataFromBackend = () => {
    let module_id = "other";
    let payload = {
      id: module_id,
    };
    dispatch(QueryBuilderActions.getQueryBuilderMappingFields(payload));
    dispatch(ApplicationFormNetworkActions.getApplicationFieldsMapping(""));
  };

  useEffect(() => {
    getFieldsDataFromBackend();
    getAssignee();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onQueryChangeCallbackFn = (filtersValue: any) => {
    formikProps?.setFieldValue("filter_query", filtersValue);
  };

  const contactAutomationTriggerRadioOptions: any[] = [
    {
      name: "Contact added",
      value: "contact_add",
      id: "contact_add",
    },
    {
      name: "Contact updated",
      value: "contact_update",
      id: "contact_update",
    },

    {
      name: "Contact added or updated",
      value: "contact_add_or_update",
      id: "contact_add_or_update",
    },
  ];


  const applicationAutomationTriggerRadioOptions: any[] = [
    {
      name: "Application added",
      value: "application_add",
      id: "application_add",
    },
    {
      name: "Application updated",
      value: "application_update",
      id: "application_update",
    },

    {
      name: "Application added or updated",
      value: "application_add_or_update",
      id: "application_add_or_update",
    },
  ];


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
    // navigate(autoAssignmentTabsOptionsMemo[newValue].url);
  };
  return (
    <ContactMarketingWrap>
      <Box className="form-first__area">
        <AppForm
          initialValues={automationRuleValues}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
            setAutomationRuleValues(values);
          }}
          validationSchema={AutoAssignmentSchema}
        >
          <BackdropLoader loading={selectedTabIndex === 0 ? contactLoading : applicationLoading} />
          <Tabs
            className="website-tabs"
            value={selectedTabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{
              display: "flex !important",
              flexDirection: "row",
              alignItems: "center !important",
              justifyContent: "space-between !important",
            }}
          >
            {autoAssignmentTabsOptionsMemo?.map((option: any, index: number) => {
              return  <Tab label={option.name} id={`simple-tab-${index}`} disabled={id && selectedTabIndex !== index ? true : false} key={index} />
            })}
          </Tabs>

          {selectedTabIndex === 0 ? (
            <FormFieldWrapper>
              <Grid container spacing={4} xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={6}>
                  <AppFormField
                    label="Rules name"
                    name={"name"}
                    placeholder="Enter rules name"
                    requiredSign={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <AppFormTextArea
                    label="Description"
                    name={"description"}
                    placeholder="Enter rules desciption"
                  />
                </Grid>
              </Grid>

              <Box sx={{ flexGrow: 1, mt: "0.7rem" }}>
                <Radio className="test">
                  <AppFormRadioField
                    name={"trigger"}
                    Options={contactAutomationTriggerRadioOptions}
                    labelPlacement="top"
                  />
                </Radio>
              </Box>

              {queryBuilderFields?.length > 0 ? (
                <Grid item xs={12} sm={12} md={6}>
                  <QueryBuilderWrapper className="query-wrapp">
                    <AppQueryBuilder
                      fields={queryBuilderFields}
                      operators={queryBuilderOperators}
                      onChangeCallbackFn={onQueryChangeCallbackFn}
                      defaultInputValueFromApi={
                        editData?.rule_id ? automationRuleValues?.filter_query : null
                      }
                    />
                  </QueryBuilderWrapper>
                </Grid>
              ) : null}

              <AppFormObserver setFormikProps={setFormikProps} />

              <Box sx={{ flexGrow: 1, mt: "0.7rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <AppReactSelectField
                      label="User to whome the records will be auto-assigned in round robin"
                      menuPosition="fixed"
                      name={"owner"}
                      isMulti={true}
                      options={assignData}
                      requiredSign={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            </FormFieldWrapper>
          ) :
            <FormFieldWrapper>
              <Grid container spacing={4} xs={12} sm={12} md={12}>
                <Grid item xs={12} sm={12} md={6}>
                  <AppFormField
                    label="Rules name"
                    name={"name"}
                    placeholder="Enter rules name"
                    requiredSign={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}></Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <AppFormTextArea
                    label="Description"
                    name={"description"}
                    placeholder="Enter rules desciption"
                  />
                </Grid>
              </Grid>

              <Box sx={{ flexGrow: 1, mt: "0.7rem" }}>
                <Radio className="test">
                  <AppFormRadioField
                    name={"trigger"}
                    Options={applicationAutomationTriggerRadioOptions}
                    labelPlacement="top"
                  />
                </Radio>
              </Box>

              {queryBuilderFields?.length > 0 ? (
                <Grid item xs={12} sm={12} md={6}>
                  <QueryBuilderWrapper className="query-wrapp">
                    <AppQueryBuilder
                      fields={applicationsFieldsMapping}
                      operators={applicationsOperators}
                      onChangeCallbackFn={onQueryChangeCallbackFn}
                      defaultInputValueFromApi={
                        editData?.rule_id ? automationRuleValues?.filter_query : null
                      }
                    />
                  </QueryBuilderWrapper>
                </Grid>
              ) : null}

              <AppFormObserver setFormikProps={setFormikProps} />

              <Box sx={{ flexGrow: 1, mt: "0.7rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={6}>
                    <AppReactSelectField
                      label="User to whome the records will be auto-assigned in round robin"
                      menuPosition="fixed"
                      name={"owner"}
                      isMulti={true}
                      options={assignData}
                      requiredSign={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            </FormFieldWrapper>
          }
          <Divider />

          <Box
            display="flex"
            sx={{
              justifyContent: "flex-end",
              marginTop: "8px",
              gap: "10px",
            }}
          >
            <AppButton
              variant="outline"
              onClick={() => {
                navigate(
                  "/settings/automation/auto-assignment/rules/contacts"
                );
              }}
            >
              Cancel
            </AppButton>
            <AppSubmitButton
              title="SAVE"
              onClick={() => setIsActive(false)}
            />
            <AppSubmitButton
              title="Save and Activate"
              onClick={() => setIsActive(true)}
            />
          </Box>
        </AppForm>
      </Box>
    </ContactMarketingWrap>
  );
};

export default ContactsAutoAssignmentRule;

interface BackdropProps {
  loading: boolean;
}
export const BackdropLoader: React.FC<BackdropProps> = ({
  loading = false,
}) => {
  return (
    <Backdrop
      sx={{ color: "#FFF", zIndex: 99 }}
      open={loading ? true : false}
      onClick={() => undefined}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const FormFieldWrapper = styled.div`
&& {
  height: calc(100vh - 370px);
  overflow: auto;
  @media(max-width: 1359px) {
    height: calc(100vh - 341px);
  }
}
`;

const Radio = styled.div`
  && {
    .MuiFormControlLabel-root {
      width: 180px;
      height: 106px;
      border-radius: 8px;
      display: flex;
      flex-direction: column-reverse;
      min-height: 106px;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-shadow: 0px 2px 8px 0px #00000012;
    }
  }
`;

const QueryBuilderWrapper = styled.div`
  && {
    position: relative;
    overflow: auto;
    .queryBuilder {
      .ruleGroup {
        height: auto;
        .ruleGroup-header {
          position: sticky;
          top: 0;
        }
        .ruleGroup-body {
        }
      }
    }
  }
`;
