import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import useDocumentTitle from '../../../Hooks/useDocumentTitle';
import CustomizeSidebar from './CustomizeSidebar';
import { CustomizeMenuOptions } from '../utils/contants';

const Customize = () => {
  useDocumentTitle("Customize")

  return (
      <CustomizeWrap>
           <CustomizeSidebar Options={CustomizeMenuOptions} />
          <MainBox>
              <Outlet/>
          </MainBox>
    </CustomizeWrap>
  )
}

export default Customize;

const CustomizeWrap = styled.div`
  && {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    /* .MuiPaper-elevation1 {
      box-shadow: none !important;
    } */
  }
`;
const MainBox = styled.div`
  && {
    width: 100%;
    height: 100%;
    height: calc(100vh - 164px);
    background-color: #f5f6f8;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
  }
`;