import { createAsyncThunk } from "@reduxjs/toolkit";
import { SegmentNetworkService } from "./SegmentNetworkService";

export class SegmentActions{
   static getAllSegmentListData = createAsyncThunk("SegmentSlice/getAllSegmentListData",(data:any,{dispatch,rejectWithValue})=>{
      
    return SegmentNetworkService.getAllSegmentListData(data)
        .then((response)=>{
            
            return response
        })
        .catch((error)=>{
            return rejectWithValue(error)
        })
        .finally(()=>{})
   })

   static getSegmentById = createAsyncThunk(
      "SegmentSlice/getSegmentById",
      (id: any, { dispatch, rejectWithValue }) => {
        return SegmentNetworkService.getSegmentById(id)
          .then(async (response: any) => {
            return response;
          })
          .catch((error: { response: unknown }) => {
            return rejectWithValue(error?.response);
          })
          .finally(() => {});
      }
    );


    static getAllSegmentConatctListData = createAsyncThunk("SegmentSlice/getAllSegmentConatctListData",(data:any,{dispatch,rejectWithValue})=>{
      return SegmentNetworkService.getAllSegmentConatctListData(data)
        .then((response)=>{
            
            return response
        })
        .catch((error)=>{
            return rejectWithValue(error)
        })
        .finally(()=>{})
   })


   static createSegment = createAsyncThunk("SegmentSlice/createSegment",(data:any,{dispatch,rejectWithValue})=>{
       return SegmentNetworkService.createSegmentData(data)
         .then((response)=>{
            return response
         })
         .catch((error)=>{
            return rejectWithValue(error)
         })
         .finally(()=>{})
   })


   static deleteSegmentById = createAsyncThunk("SegmentSlice/deleteSegmentById",(id: string, { dispatch, rejectWithValue })=>{
      return SegmentNetworkService.deleteSegmentById(id)
      .then(async (response: any) => {
        return response;
      })
      .catch((error: { response: unknown }) => {
        return rejectWithValue(error?.response);
      })
      .finally(() => {});
  
   })
}