import styled from "styled-components";

export const StyledLifeCycleWrapper = styled.ul`
  && {
    display: flex;
    // justify-content:space-between;
    // min-height: calc(100vh - 160px);
    // align-items: flex-start;
    flex-direction: ${(props: any) =>
      props.orientation === "horizontal" ? "row" : "column"};
    gap: ${(props: any) =>
      props.orientation === "horizontal" ? "15px" : "10px"};
    padding: ${(props: any) =>
      props.orientation === "horizontal" ? "15px" : "15px 0px"};
    list-style: none;
    overflow: ${(props: any) =>
      props.orientation === "horizontal" ? "auto" : "hidden"};
  }
`;
