import { Avatar, Fab } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import WHATSAPP_SVG from "../../../../../assets/images/whatsappBot/whatsappIcon.svg";
import { useAppSelector } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import TELEGRAM_SVG from "../../../../../assets/images/telegramBot/telegramWidgetPrevireIcon.svg"
import messengerWidgetPreviewIcon from "../../../../../assets/images/messengerBot/messengerIcon.svg";

interface Props {
  button_background_color: string;
}

const BotFloatingIcon: React.FC<Props> = ({
  button_background_color,
}) => {
  const currentBot: any = useAppSelector(useCurrentBot);

  return (
    <SytledFabButton
      onClick={() => undefined}
      disableRipple
      $button_background_color={button_background_color}
    >
      <Avatar src={currentBot?.channel === "messenger" ? messengerWidgetPreviewIcon : currentBot?.channel === "telegram" ? TELEGRAM_SVG : WHATSAPP_SVG} />
    </SytledFabButton>
  );
};

export default React.memo(BotFloatingIcon);

const SytledFabButton = styled(Fab)`
  && {
    width: 60px;
    height: 60px;
    text-align: center;
    background: ${({ $button_background_color }: any) =>
    $button_background_color ?? "#25D366"};
    box-shadow: none;
    pointer-events: none;
    z-index: 1;
    &:hover {
      background: ${({ $button_background_color }: any) =>
    $button_background_color ?? "#25D366"};
    }
    .MuiSvgIcon-root {
      font-size: 2.2rem;
      color: white;
    }

    & .MuiAvatar-root {
      width: 32px;
      height: 32px;
      border-radius: 0;
    }
  }
`;
