import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction
} from "@reduxjs/toolkit";
import { Chatbot } from "../../Models";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { RootState } from "../index";

interface Props {
  allBots: Chatbot[];
  loading: boolean;
  error: null | string | undefined;
}

const INITIAL_STATE: Props = {
  allBots: [],
  loading: false,
  error: null,
};



export const fetchBots = createAsyncThunk(
  "bots/fetchBots",
  async () => {

    return ChatbotConsoleService.getAllBots({}).then(
      (response: any) => response.data.results as Chatbot[]
    );
  }
);

export const deleteBot = createAsyncThunk(
  "bots/deleteBot",
  async (id: number) => {
    return id;
  }
);

export const updateBot = createAsyncThunk(
  "bots/updateBot",
  async (newObj: any) => {
    const { id, changes } = newObj;
    return { id: id, changes: changes };
  }
);




export const allBotsAdapter = createEntityAdapter<Chatbot>();

const AllBotsSlice = createSlice({
  name: "bots",
  initialState: allBotsAdapter.getInitialState(INITIAL_STATE),
  reducers: {},
  extraReducers: (builder) => {
    //fetch get cases

    builder.addCase(fetchBots.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchBots.fulfilled,
      (state, action: PayloadAction<Chatbot[]>) => {
 
        allBotsAdapter.setAll(state, action.payload);
        // state.users = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(fetchBots.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });

    //delete cases

    builder.addCase(deleteBot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBot.fulfilled, (state, payload) => {
      allBotsAdapter.removeOne(state, payload);
      state.loading = false;
    });
    builder.addCase(deleteBot.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });

    //update cases

    builder.addCase(updateBot.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBot.fulfilled, (state, { payload }) => {
      allBotsAdapter.updateOne(state, {
        id: payload.id,
        changes: payload.changes,
      });
      state.loading = false;
    });

    builder.addCase(updateBot.rejected, (state, action) => {
      state.error = action.error.message || "Something went wrong";
      state.loading = false;
    });
  },
});

export default AllBotsSlice.reducer;

export const {
  selectById: selectBotById,
  selectIds: selectBotIds,
  selectEntities: selectBotEntities,
  selectAll: selectAllBots,
  selectTotal: selectTotalBots,
} = allBotsAdapter.getSelectors((state: RootState) => state.bots);

// export const useUsersStore = (state: any) => {
//   return state.users as any;
// };
