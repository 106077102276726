import * as Yup from "yup";

export const SourceTexSchema = Yup.object().shape({
  title: Yup.string()
    .required()
    .max(1000, "Source Title must not exceed 1000 characters.")
    .label("Source Name"),
  text: Yup.string()
    .required()
    .max(10000, "Source Description must not exceed 10000 characters.")
    .label("Source Description"),
});
