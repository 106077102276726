import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { AppConfig } from "../../../../Config/app-config";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useSelector } from "react-redux";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import ZohoConfigure from "./ZohoConfigure";
import ZohoIntegrations from "./ZohoIntegrations";
import { ConversationService } from "../../../../Services/Apis/ConversationService";
import ZohoConnectedPage from "./ZohoConnectedPage";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
const BOT_ID_KEY = "currentBotId";

interface Props extends LoaderComponentProps { }

const ZohoIndex = (props: Props) => {
  const { showAlert } = useNotification();
  const bot_id = AppConfig.getUserData(BOT_ID_KEY);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [fieldMapping, setFieldMapping] = useState<any>(null);
  const [getaVariables, setGetaVariables] = useState<any>([]);
  const [configData, setConfigData] = useState<any>([]);
  const [userInput, setUserInput] = useState<any>({});
  let navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);
  const urlParams = new URLSearchParams(window.location.search);
  const authorizationCode = urlParams.get("code");

  const saveZohoConfigData = () => {
    const bot_id = AppConfig.getUserData(BOT_ID_KEY);
    const data = {
      bot_id: bot_id,
      code: authorizationCode,
    };
    ChatbotConsoleService.saveZohoConfiguration(data).then((res: any) => {
      const zohoRedirect: any = navigate(`/integrations/zoho`);
      if (zohoRedirect) {
        showAlert("Connected from Zoho CRM successfully!", "success");
        setIsConnected(true);
      }
    });
  };

  useEffect(() => {
    if (authorizationCode) {
      saveZohoConfigData();
    }
  }, [authorizationCode]);

  const handleDisconnectButton = () => {
    const data = {
      bot_id: bot_id,
    };
    ChatbotConsoleService.logoutZohoConfiguration(data).then(
      (save_result: any) => {
        showAlert("Disconnected from ZOHO CRM successfully!", "success");
        setIsConnected(false);
        setZohoCRMFieldsData([]);
      }
    );
  };

  const handleConnectButton = (bot_id: string) => {
    AppConfig.setUserData(BOT_ID_KEY, bot_id);
    ChatbotConsoleService.getZohoOauthRedirectUrl().then((res: any) => {
      window.location.href = res.data.url;
    });
  };

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    const data = { ...configData };
    data["config_value"] = values;
    ChatbotConsoleService.updateZohoFieldsMapping({
      config: data,
      bot_id: currentBot.bot_id,
    }).then((res: any) => {
      if (res) {
        showAlert("Data Saved Successfully", "success");
        // setIsConnected(true);
        getZohoFieldsData();
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      props.setLoading(false);
    }, 1500);
    const bot_id = AppConfig.getUserData(BOT_ID_KEY);
    ChatbotConsoleService.getZohoFieldsMapping(bot_id).then((res: any) => {
      setConfigData(res.data);
      if (res?.data?.zoho_response) {
        const mappingData = res.data.zoho_response.fields;
        // const resZoho = Object.keys(mappingData.zoho_response.fields).map((r: string) => {
        //   return {
        //     'zoho_response': mappingData.zoho_response.fields[r],
        //     // 'label': mappingData.zoho_response.fields.
        //   }
        // });
        // const fm = Object.keys(mappingData.field_mapping).map((k: string) => {
        //   return {
        //     'zoho_field_name': k,
        //     // 'geta_variable': mappingData.field_mapping[k],
        //   }
        // });
        setFieldMapping(mappingData);
        setIsConnected(true);
      }
    });
  }, []);

  const [ZohoCRMFieldsData, setZohoCRMFieldsData] = useState([]);

  const getZohoFieldsData = () => {
    ChatbotConsoleService.getZohoFieldsData(bot_id).then((res: any) => {
      let zohoObjectData: any = Object.entries(res?.data?.config_value);
      setZohoCRMFieldsData(zohoObjectData);
    });
  };

  useEffect(() => {
    if (isConnected) {
      getZohoFieldsData();
    }
  }, [isConnected]);

  return (
    <ZohoWrap>
      {isConnected ? (
        ZohoCRMFieldsData && ZohoCRMFieldsData?.length > 0 ? (
          <ZohoConnectedPage
            ZohoCRMFieldsData={ZohoCRMFieldsData}
            handleDisconnectButton={handleDisconnectButton}
          />
        ) : (
          <ZohoConfigure
            handleDisconnectButton={handleDisconnectButton}
            fieldMapping={fieldMapping}
            configData={configData}
            handleSubmitBtn={handleSubmitBtn}
            userInput={userInput}
            setUserInput={setUserInput}
            getaVariables={getaVariables}
          />
        )
      ) : (
        <ZohoIntegrations
          handleConnectButton={handleConnectButton}
          authorizationCode={authorizationCode}
        />
      )}
    </ZohoWrap>
  );
};

export default withLoader(ZohoIndex);

const ZohoWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
  }
`;
