import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { FieldArray, getIn, useFormikContext } from "formik";
import styled from "styled-components";
import AppButton from "../../../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../../../AppComponents/AppMaterialIcons";
import {
    AppErrorMessage,
    AppFormField,
    AppFormSelectField,
} from "../../../../../../../AppComponents/Forms";
import { useAppSelector } from "../../../../../../../Store";
import { getTemplateButtonsLengthChecker } from "../../../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { ConversationService } from "../../../../../../../Services/Apis/ConversationService";
import { AxiosResponse } from "axios";
import { useAuthStore } from "../../../../../../../Store/Slices/AuthSlice";

interface Props {
    disabled?: boolean;
}

const QuickReplyButtonFieldArray: React.FC<Props> = ({
    disabled,
    ...otherProps
}) => {
    const { values, errors, touched } = useFormikContext<any>();
    const name = "quick_replies"
    const buttonsLengthChecker = useAppSelector(getTemplateButtonsLengthChecker)
    const { userConfig } = useAppSelector(useAuthStore);
    // const [flowList, setFlowList] = useState<any>([])
    const handleAddButtonClick = (push: any) => {
        push({
            text: "",
            type: "QUICK_REPLY"
        })
    };

    const HEADERS = {
        appid: JSON.stringify({
            botid: userConfig?.payload?.whatasapp_config?.botid
        }),
    };
    /*useEffect to get Flow List */
    // useEffect(() => {
    //     ConversationService.getFlowList(HEADERS)
    //         .then((res: AxiosResponse<any>) => {
    //             if (Array.isArray(res?.data?.data)) {
    //                 setFlowList(res?.data?.data);
    //             } else {
    //                 setFlowList([]);
    //             }
    //         })
    //         .catch((error: any) => { });
    // }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <FieldArray name={name}>
            {({ insert, remove, push, swap, unshift, pop, replace }) => (
                <React.Fragment>
                    {values &&
                        name &&
                        getIn(values, name)?.length > 0 &&
                        getIn(values, name)?.map((item: any, index: number) => {
                            const fieldName: string = `${name}.${index}.text`;
                            // const flowId: string = `${name}.${index}.payload`;
                            return (
                                <Container>
                                    <React.Fragment key={index.toString()}>
                                        <AppFormField
                                            name={fieldName}
                                            placeholder="Enter text for the Quick Reply button *"
                                            charactersLimit={25}
                                            disabled={disabled}
                                            id={
                                                getIn(values, name)?.length > 1 &&
                                                    index + 1 === getIn(values, name)?.length
                                                    ? "add_button"
                                                    : ""
                                            }
                                        />
                                        {/* {userConfig?.payload?.whatasapp_config?.botid && (
                                            <AppFormSelectField
                                                label=""
                                                Options={flowList}
                                                name={flowId}
                                                disabled={disabled}
                                                displayLabel="name"
                                                valueKey="qid"
                                                placeholder="Select Flow"
                                            />
                                        )} */}

                                        {/* Remove */}
                                        <div className="removeBtnBox">
                                            <IconButton
                                                onClick={() => remove(index)}
                                                disabled={disabled}
                                            >
                                                <AppMaterialIcons iconName={"Delete"} />
                                            </IconButton>
                                        </div>
                                    </React.Fragment>
                                    <AppErrorMessage
                                        error={getIn(errors, name)?.[index]?.name}
                                        visible={getIn(touched, name)}
                                        index={index}
                                    />
                                </Container>
                            );
                        })}

                    {/* Add new field at last position */}
                    <AppButton
                        variant="text"
                        onClick={() => handleAddButtonClick(push)}
                        style={{
                            marginTop: "0.5rem",
                            minWidth: "170px",
                            width: "170px",
                            border: "1px solid rgba(91, 115, 232, 1)",
                            borderRadius: "6px",
                        }}
                        startIcon={"ri-add-line"}
                        isDisabled={
                            buttonsLengthChecker || (getIn(values, name)?.length === 10 ? true : false) || disabled
                        }
                        id="add_button"
                    >
                        Add Another Quick Reply
                    </AppButton>
                </React.Fragment>
            )}
        </FieldArray>
    );
};
export default QuickReplyButtonFieldArray;

const Container = styled.div`
&&{
    display: flex;  
    gap: 1rem;
}`

