import { useEffect, useState } from "react"
import styled from "styled-components"
import AppButton from "../../../../AppComponents/AppButton"
import { LeftCol, NewNativeSelect, RightCol, TopBar } from "../BroadcastHistory/HistoryStyles"
import { Box, MenuItem, Select, Typography } from "@mui/material"
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BroadcastTopTitle } from "../../../../TableStyle"
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents"
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons"
import { useNavigate } from "react-router-dom"
import { PageDiv } from "../ScheduledBroadcast/Styles"
import TemplatesListing from "./TemplatesListing"
import DefaultTemplateCard from "./DefaultTemplateCard"
import { GridCloseIcon } from "@mui/x-data-grid"
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService"
import AppPaginationComponent from "../../../../AppComponents/Pagination/Pagination"
import { CoreService } from "../../../../Services/Apis/CoreApis"

interface Props { }

const DefaultTemplate: React.FC<Props> = () => {
    const [selectedTemplate, setSelectedTemplate] = useState("template_gallery")
    const [credits, setCredits] = useState<any>([]);
    const [category, setCategory] = useState<any>("")
    const [defaultTemplatObj, setDefaultTemplatObj] = useState<any>({})
    const has_low_balance = localStorage.getItem("has_low_balance")
    const navigate = useNavigate();
    const headerList = [
        {
            "label": "Template Gallery",
            "value": "template_gallery"
        },
        {
            "label": "Saved Templates",
            "value": "saved_templates"
        }
    ]
    const [localValues, setLocalValues] = useState<any>({
        status: "",
        search: "",
        itemsPerPage: { label: "10  per page", value: 10 },
        currentPage: 0,
        category: "",
    });
    const onChangeTemplate = (value: any) => {
        // setInputValue('')
        // setLocalValues({
        //     ...localValues,
        //     "search": "",
        // });
        setSelectedTemplate(value)
    }
    useEffect(() => {
        let data = {
            limit: localValues?.itemsPerPage?.value,
            offset: localValues?.currentPage,
            category: category
        }
        ChatbotConsoleService.getDefaultTemplateData(data)
            .then((res: any) => {
                setDefaultTemplatObj(res.data)
            })
            .catch((error: any) => {
            })
            .finally(() => {
            });
    }, [category, localValues])
    function handlePageChange(event: any, value: any) {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    }
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 0,
        });
    };
    useEffect(() => {
        CoreService.getCredits().then((response) => {
            const reversedItems = response?.data;
            setCredits(reversedItems);
        });
    }, []);

    return (
        <>
            <TopBar>
                {/* Left Col */}
                <LeftCol>
                    <label>To know your Messaging Limit please</label>
                    <Box className="limit">
                        <a
                            href="https://developers.facebook.com/docs/whatsapp/messaging-limits/"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Click here
                        </a>
                    </Box>
                </LeftCol>

                {/* Right Col */}
                <RightCol>
                    <Box className="contact">
                        <label>
                            <span className="contact-label">Connected WA No.:</span>
                            <span> {credits?.whatsapp_no}</span>
                        </label>
                    </Box>
                    <Box>
                        <Box className="monthly-conversations__wrapper" >
                            <Box className="conversations">
                                <Box className="monthly-conversations">
                                    <AppToolTip
                                        tooltipProps={{
                                            title: `Each WhatsApp Business Account gets 1,000 free service conversations (user-initiated conversations) each month across all of its business phone numbers. This number is refreshed at the beginning of each month, based on WhatsApp Business Account time zone. Marketing, utility and authentication conversations are not part of the free tier`,
                                            placement: "right",
                                        }}
                                        iconButtonProps={{ disableRipple: true }}
                                        IconComponent={<InfoOutlinedIcon />}
                                        divStyle={{ marginRight: "5px", marginBottom: "2px" }}
                                    />{" "}
                                    <div className="conversations-label">
                                        Free Monthly conversations:<strong> {credits?.free_conversation || 0}</strong>
                                    </div>
                                </Box>
                            </Box>
                            <Box>{/* 80 */}</Box>
                        </Box>
                        <Box className="credits">
                            <Box></Box>
                        </Box>
                    </Box>
                </RightCol>
            </TopBar>
            <PageDiv >
                <BroadcastTopTitle style={{ padding: "16px 0" }}>
                    <Box className="topTitle-left">
                        <Typography fontSize="16px" fontWeight="500" component="h6">
                            Broadcast Template
                        </Typography>
                    </Box>
                    <Box className="topTitle-right">
                        <ProtectedAppButton
                            moduleId="whatsapp_broadcast_crud"
                            specificPermissionKey="create"
                            variant="outline"
                            onClick={() => navigate("/broadcast/whatsapp/create-broadcast/1")}
                            style={{ minWidth: "170px" }}
                            isDisabled={has_low_balance === "true"}
                            title={has_low_balance === "true" ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit." : ""}
                        >
                            Create Broadcast
                            <AppMaterialIcons iconName="Add" />
                        </ProtectedAppButton>


                        {credits?.whatsapp_no && (<ProtectedAppButton
                            moduleId="whatsapp_template"
                            specificPermissionKey="create"
                            variant="primarydark"
                            onClick={() => navigate("/broadcast/whatsapp/create-template")}
                            style={{ minWidth: "170px" }}
                        >
                            Create Template
                            <AppMaterialIcons iconName="Add" />
                        </ProtectedAppButton>
                        )}

                    </Box>
                </BroadcastTopTitle>
                <EmailTemplateButton>
                    <div style={{ display: "flex" }}>
                        {headerList.map((data: any) => {
                            return (
                                <>
                                    <Button
                                        variant="outline"
                                        radius={"50px"}
                                        margin={"-1rem 1rem 1rem"}
                                        selected={selectedTemplate === data?.value}
                                        onClick={() => {
                                            onChangeTemplate(data?.value)
                                        }}
                                    >
                                        {data?.label}
                                    </Button>
                                </>
                            )
                        })}

                    </div>
                </EmailTemplateButton>
                {selectedTemplate === "saved_templates" &&
                    <TemplatesListing />
                }
                {selectedTemplate === "template_gallery" &&
                    <>
                        <NewNativeSelect style={{ width: "15%", margin: "15px 37px 0px" }}>
                            <Select
                                defaultValue={undefined}
                                name="category"
                                value={category}
                                onChange={(e) => setCategory(e?.target?.value)}
                                displayEmpty
                                className="new-select-main"
                                style={{
                                    marginBottom: "0px",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                    width: "100%",
                                    maxWidth: "auto",
                                    minWidth: "100%",
                                    height: "40px",
                                }}
                            >
                                <MenuItem value="" disabled>
                                    Select Category
                                </MenuItem>
                                {defaultTemplatObj?.categorieList?.map((res: any) => {
                                    return (
                                        <MenuItem value={res?.id}>{res?.name}</MenuItem>

                                    )
                                })}
                            </Select>
                            <div
                                className="clear-icon"
                                style={{ cursor: "pointer" }}
                                onClick={(e: any) => setCategory("")}
                            >
                                {category !== "" ? <GridCloseIcon fontSize="small" /> : null}
                            </div>
                        </NewNativeSelect>
                        <DefaultTemplateCard
                            list={defaultTemplatObj?.templateList}
                        // list={list}
                        />

                    </>
                }
            </PageDiv>
            {defaultTemplatObj?.templateList?.length && selectedTemplate === "template_gallery" ? (
                <AppPaginationComponent
                    totalCount={defaultTemplatObj?.pagination?.totalRecords}
                    handlePageChange={handlePageChange}
                    currentPage={localValues?.currentPage}
                    totalPages={defaultTemplatObj?.pagination?.totalPages}
                    rowsPerPage={localValues?.itemsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
            ) : null}
        </>
    )
}
const EmailTemplateButton = styled.div`
display: flex;
margin: 25px 0px 0px;
justify-content: space-between;
border-bottom:1px solid lightgray;
}
`
const Button = styled(AppButton) <{ props: any }>`
  min-width: ${(props: any) =>
        props.minWidth ? props.minWidth + "!important" : "170px !important"};
  font-size: 16px !important;
  font-family: Inter !important;
  font-weight: 500 !important;
  background-color: ${(props: any) =>
        props.selected ? "rgb(33, 28, 82)" + "!important" : ""};
  margin: ${(props: any) => (props.margin ? props.margin + "!important" : "")};
  border-radius: ${(props: any) =>
        props.radius ? props.radius + "!important" : ""};
  border: 1px solid white !important;
  color : ${(props: any) =>
        props.selected ? "white" + "!important" : ""};
  height : 41px !important;

`;
export default DefaultTemplate