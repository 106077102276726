// vendors
import { styled, alpha } from "@mui/material/styles";
import { PopoverProps } from "@mui/material/Popover";
import Box from "@mui/material/Box";

export const PopoverConfig = (open: boolean): PopoverProps => ({
  open,
  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "center" as "center",
    horizontal: "left",
  },
  sx: {
    marginLeft: "24px",
    borderRadius: "8px",
  },
  elevation: 0,
  PaperProps: {
    sx: {
      width: "403px",
      borderRadius: "8px",
      background: "#F5F6F8",
      padding: "12px",
      boxShadow: `0px 0px 20px 0px ${alpha("#000000", 0.1)}!important`,
    },
  },
});

export const HeaderStyled = styled(Box)`
  background-color: ${(props) => props.theme.palette.common.white};
  padding: 4px 0;
`;

export const WrapperStyled = styled(Box)`
  background-color: ${(props) => props.theme.palette.common.white};
  margin-top: 4px;
  padding: 16px;
  .custom-select {
    background-color: #f5f6f8;
    border: 1px solid #d9d9d9;
  }
`;

export const ButtonWrapperStyled = styled(Box)`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

export const ResponseTargetFieldWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
