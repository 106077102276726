export const whatsAppBroadcastOverviewLayout = {
    lg: [
        { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
        { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
        { i: '3', x: 2, y: 0, w: 1, h: 1.5 },

        { i: '4', x: 0, y: 1, w: 3, h: 2.7 },

        { i: '5', x: 0, y: 6, w: 1, h: 3.5 },
        { i: '6', x: 1, y: 6, w: 1, h: 3.5 },
        { i: '7', x: 2, y: 6, w: 1, h: 3.5 },

        { i: '8', x: 0, y: 9.5, w: 1, h: 3 },

        { i: '9', x: 0, y: 12.5, w: 3, h: 2.5 },

        { i: '10', x: 0, y: 15, w: 3, h: 2.5 },
    ],


    md: [
        { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
        { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
        { i: '3', x: 2, y: 0, w: 1, h: 1.5 },

        { i: '4', x: 0, y: 1, w: 3, h: 3 },

        { i: '5', x: 0, y: 5.5, w: 1, h: 3.5 },
        { i: '6', x: 1, y: 5.5, w: 1, h: 3.5 },
        { i: '7', x: 2, y: 5.5, w: 1, h: 3.5 },

        { i: '8', x: 0, y: 9, w: 1, h: 3 },

        { i: '9', x: 0, y: 12, w: 3, h: 2.5 },

        { i: '10', x: 0, y: 14.5, w: 3, h: 2.5 },

    ],

    sm: [
        { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
        { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
        { i: '3', x: 0, y: 1, w: 2, h: 1.5 },

        { i: '4', x: 0, y: 2, w: 2, h: 3 },

        { i: '5', x: 0, y: 0, w: 1, h: 3.5 },
        { i: '6', x: 1, y: 0, w: 1, h: 3.5 },
        { i: '7', x: 0, y: 1, w: 1, h: 3.5 },

        { i: '8', x: 0, y: 9, w: 1, h: 3 },

        { i: '9', x: 0, y: 12, w: 3, h: 2.5 },

        { i: '10', x: 0, y: 14.5, w: 3, h: 2.5 },
    ],

    xs: [
        { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
        { i: '2', x: 1, y: 0, w: 1, h: 1.5 },
        { i: '3', x: 0, y: 1, w: 2, h: 1.5 },

        { i: '4', x: 0, y: 2, w: 2, h: 3 },

        { i: '5', x: 0, y: 0, w: 1, h: 3.5 },
        { i: '6', x: 1, y: 0, w: 1, h: 3.5 },
        { i: '7', x: 0, y: 1, w: 1, h: 3.5 },

        { i: '8', x: 0, y: 9, w: 1, h: 3 },

        { i: '9', x: 0, y: 12, w: 3, h: 2.5 },

        { i: '10', x: 0, y: 14.5, w: 3, h: 7.1 },
    ],

    xxs: [
        { i: '1', x: 0, y: 0, w: 1, h: 1.5 },
        { i: '2', x: 0, y: 1, w: 1, h: 1.5 },
        { i: '3', x: 0, y: 2, w: 1, h: 1.5 },

        { i: '4', x: 0, y: 3, w: 1, h: 3 },

        { i: '5', x: 0, y: 0, w: 1, h: 3 },
        { i: '6', x: 0, y: 1, w: 1, h: 3 },
        { i: '7', x: 0, y: 2, w: 1, h: 3 },

        { i: '8', x: 0, y: 9, w: 1, h: 3 },

        { i: '9', x: 0, y: 12, w: 1, h: 2.5 },

        { i: '10', x: 0, y: 14.5, w: 3, h: 7.1 },
    ],
};