import * as React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CheckOutlined } from "@material-ui/icons";
import AppButton from '../../../../../AppComponents/AppButton';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from "react-redux";
import { emailDomainSteps, emailVerification } from '../../../../../Store/Slices/email/email.selectors';
import { useNavigate } from 'react-router-dom';
import { verifyActiveStep } from '../../../../../Store/Slices/email/email.slice';
import { useAppDispatch } from '../../../../../Store';
import { AppMaterialIcons } from '../../../../../AppComponents/AppMaterialIcons';
import { theme } from '../../../../../Customization/Theme';
import { IconButton } from '@mui/material';
// import VerfiedIcon  from "../../../../../assets/images/domain-verified.gif"

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <>
    <Box>
      <Box sx={{ position: 'relative', display: 'inline-flex', justifyContent: "center" }}>
        <CircularProgress  variant="determinate" {...props} sx={{ margin: "0px 178px 0 180px" }} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >

          {
            props.value === 100 ?
              <CheckOutlined className="check" style={{
                position: "absolute",
                top: "56%",
                left: "50%",
                transform: "translate(-50%, -50%)"
              }} />
               : null
          } 
        </Box>

      </Box>
        <Box fontSize={"14px"} mt={1} style={{ display: "flex", justifyContent: "center" }}>Verified</Box>
    </Box>
    </>
  );
}

interface Props {
  setClose: Dispatch<SetStateAction<boolean>>

  ;
}

const RenderFormSub4: React.FC<Props> = ({ setClose }) => {
  const status = useSelector(emailVerification)
  const activeStep = useSelector(emailDomainSteps)
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [progress, setProgress] = React.useState(100);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 100));
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);
  const hanldeclose = () => {
    setClose(false)
    // if(status!==true){
    dispatch(verifyActiveStep(activeStep + 1))

    // }
  }

  return (<Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"center"} height={"100%"}> 
    <Box display={"flex"} justifyContent={"flex-end"} width={"100%"}>
        <Box pt={1} pr={1}>
          <IconButton onClick={hanldeclose}>
            <AppMaterialIcons style={{color: theme.palette.default.darkGrey, fontSize: "18px"}} iconName='Close' />
          </IconButton>
        </Box>
      </Box>
    <CircularProgressWithLabel value={progress} />
    <Box display={'flex'} justifyContent={"flex-end"} px={1} py={2} width={"100%"} bgcolor={"#f7fafc"} borderTop={"1px solid rgba(126, 131, 146, 0.3)"}>
      <AppButton  width={"100px"} onClick={hanldeclose} >ok</AppButton>
    </Box>
    </Box>
  )
}

export default RenderFormSub4