import React from "react";
import { useAppSelector } from "../../../../../Store";
import { allMessagesStore } from "../../../../../Store/Slices/socket/AllMessagesSlice";
import { AppMaterialIcons } from "../../../../appComponents/Icons";
import { shortStringFn } from "../../../../utils/utils";
import { UserDetailSummary } from "../UsersDetailStyles";

interface Props {}

const UserInfo: React.FC<Props> = () => {
  const { userInfoData, userContactDetailsData } =
    useAppSelector(allMessagesStore);

  return (
    <UserDetailSummary>
      <div className="title-wrapper">
        <div className="icon">
          <AppMaterialIcons iconName="PersonOutlineOutlined" />
        </div>
        <div className="title">
          <p>Name</p>
          <h6>
            {shortStringFn(
              userContactDetailsData?.first_name || userInfoData?.name
            ) || "N/A"}{" "}
            {userContactDetailsData?.last_name}
          </h6>
        </div>
      </div>

      <div className="title-wrapper">
        <div className="icon">
          <AppMaterialIcons iconName="AlternateEmailOutlined" />
        </div>
        <div className="title">
          <p>Email</p>
          <h6>{userContactDetailsData?.email || "N/A"}</h6>
        </div>
      </div>

      <div className="title-wrapper">
        <div className="icon">
          <AppMaterialIcons iconName="LocalPhoneOutlined" />
        </div>
        <div className="title">
          <p>Phone</p>
          <h6>
            {userContactDetailsData?.mobile_phone_number ||
              userContactDetailsData?.phone ||
              "N/A"}
          </h6>
        </div>
      </div>

      <div className="title-wrapper">
        <div className="icon">
          <AppMaterialIcons iconName="LocationOnOutlined" />
        </div>
        <div className="title">
          <p>Location</p>
          <h6>
            {userContactDetailsData?.city},{" "}
            {userContactDetailsData?.state_region},{" "}
            {userContactDetailsData?.country_region || "N/A"}{" "}
          </h6>
        </div>
      </div>
    </UserDetailSummary>
  );
};

export default UserInfo;
