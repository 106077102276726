import React from "react";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";

interface Props {
  error?: any;
  visible?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppErrorMessage: React.FC<Props> = ({
  error,
  visible,
  ...otherProps
}) => {
  if (!visible || !error || (typeof error !== "string" && typeof error !== "object")) return null;

  return (
    <StyledText {...otherProps}>
      {error}
    </StyledText>
  );
};

export default AppErrorMessage;

const StyledText = styled.p`
  color: #f34e4e;
  font-size: 13px;
  font-family: ${theme.typography.fontFamily};
`;
