import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppButton from "../../../../../AppComponents/AppButton";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormField,
  AppFormImagePicker,
  AppFormSwitchField,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import {
  SettingPageMainBoxTitle,
  StyledCustomizerActionBtnsWrapper,
} from "../../../../../Customization/CommonElements";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import { NotificationBlockSchema } from "../../../../../Models/ValidationSchema";
import { NotificationBlockIconOptions } from "../../../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useAppSelector } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import {
  setBotConfig,
  useBotConfigStore,
} from "../../../../../Store/Slices/botConfig/BotConfigSlice";
import WebBotStaticPreview from "../../StaticPreview/webBot/WebBotStaticPreview";
import IconToggleButton from "./IconToggleButton";
import NotificationCard from "./SubComponents/NotificationCard";

export default function NotificationBlock() {
  const [notificationData, setNotificationData] = useState<any>([]);
  const { botConfig } = useAppSelector(useBotConfigStore);
  const currentBot = useSelector(useCurrentBot);
  const dispatch = useDispatch();
  let { showAlert } = useNotification();
  const [localValues, setLocalValues] = useState<any>([]);
  const [reRenderState, setReRenderState] = useState<boolean>(false);
  const [enableState, setEnableState] = useState<any>({
    enabled: false,
    selected_icon: 1,
  });
  const [userInput, setUserInput] = useState<any>({
    image: "null",
    notification_title: "",
    notification_description: "",
    button_title: "",
    button_url: "https://",
    footer_text: "",
  });

  const [isEditing, setIsEditing] = useState<any>(null);

  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  const getPreviousData = () => {
    if (
      currentBot?.chatbot_configuration &&
      currentBot?.chatbot_configuration
    ) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot?.chatbot_configuration as string
      )
        .then((res: AxiosResponse<ChatbotConfiguration>) => {
          dispatch(
            setBotConfig({ ...botConfig, chatbot_configuration: res.data })
          );
          if (res.data?.notification_block) {
            setNotificationData(res.data?.notification_block);
            setEnableState({
              enabled: res.data?.notification_block?.enabled || false,
              selected_icon: res.data?.notification_block?.selected_icon || 1,
            });
          }
          setReRenderState(!reRenderState);
        })
        .catch((error: any) => { });
    }
  };
  useEffect(() => {
    getPreviousData();
  }, [currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEnableStateForm = (values: any, SubmitProps: any) => {
    let SorId: any = [];
    notificationData?.data?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          notification_block: {
            enabled: values?.enabled,
            selected_icon: values?.selected_icon,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();

        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps?.setSubmitting(false);
      });
  };

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    let xyz: any = [];
    notificationData?.data?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      xyz.push(current);
    });

    let temp = {
      image: values.image.id,
      notification_title: values.notification_title,
      notification_description: values.notification_description,
      button_title: values.button_title,
      button_url: values.button_url,
      footer_text: values.footer_text,
    };
    let abc = [...xyz];
    abc.push(temp);

    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          notification_block: {
            ...notificationData,
            data: abc,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        SubmitProps.resetForm();
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps?.setSubmitting(false);
      });
  };

  const handleUpdateBtn = (values: any, SubmitProps: any) => {
    let xyz = notificationData?.data.filter(
      (index: any) => index !== isEditing
    );
    let SorId: any = [];
    xyz?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });
    let temp = {
      image: values.image?.id,
      notification_title: values.notification_title,
      notification_description: values.notification_description,
      button_title: values.button_title,
      button_url: values.button_url,
      footer_text: values.footer_text,
    };

    SorId.push(temp);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          notification_block: {
            ...notificationData,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();
        SubmitProps.resetForm();
        setUserInput({
          image: "null",
          notification_title: "",
          notification_description: "",
          button_title: "",
          button_url: "https://",
          footer_text: "",
        });
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        setIsEditing(null);
      });
  };

  const handleEditButton = (item: any) => {
    setIsEditing(item);
    setUserInput({
      image: item?.image,
      notification_title: item?.notification_title,
      notification_description: item?.notification_description,
      button_title: item?.button_title,
      button_url: item?.button_url,
      footer_text: item?.footer_text,
    });
  };
  const handleDeleteButton = (item: any) => {
    let abc = notificationData?.data.filter(
      (el: any) => el.button_title !== item.button_title
    );
    let SorId: any = [];

    abc?.forEach((currentItem: any) => {
      let current = { ...currentItem, image: currentItem?.image?.id };
      SorId.push(current);
    });
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          notification_block: {
            ...notificationData,
            data: SorId,
          },
        },
      }
    )
      .then((response) => {
        getPreviousData();

        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      });
  };

  const handleOpenUrlFn = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };
  const handleCancel = (SubmitProps: any) => {
    setIsEditing(false);
    setUserInput({
      image: "",
      video: "",
      banner_url: "https://",
      View_options: "Image",
      position: "",
      main_flow: "",
      sub_flow: "",
    });
  };

  return (
    <React.Fragment>
      <AppSmallWindowPane>
        <AppForm
          initialValues={enableState}
          onSubmit={(values: any, submitProps: any) => {
            handleEnableStateForm(values, submitProps);
          }}
        >
          <AppFormSwitchField
            label="Notification Block"
            name="enabled"
            submitOnChange={true}
          />
          <IconToggleButton
            label="Select Notification icon Style"
            name="selected_icon"
            placeholder="Admissions open"
            Options={NotificationBlockIconOptions}
            submitOnChange={true}
            sendValuesToParent={(values: any) => sendValuesToParent(values)}
          />
        </AppForm>

        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            isEditing
              ? handleUpdateBtn(values, submitProps)
              : handleSubmitBtn(values, submitProps);
          }}
          validationSchema={NotificationBlockSchema}
        >
          <p style={{ opacity: "50%", padding: "10px 0" }}>
            Notification call to Action
          </p>
          <AppFormImagePicker
            label="Upload Image"
            name="image"
            tooltip={
              <ul>
                <li>Recommended size 64*64 px</li>
                <li>Your logo must be smaller than 1 MB</li>
                <li>A PNG, JPEG or JPG works best</li>
              </ul>
            }
            sizeLimit={1000000}
            sizeErrorMsg={"Image size must be smaller than 1MB."}
            formatErrorMsg={"image should be PNG, JPEG or JPG."}
            squareImageBox={true}
          />
          <AppFormField
            label="Notification Title"
            name="notification_title"
            placeholder="Admissions open"
            maxLength={60}
          // sendValuesToParent={(values: any) => sendValuesToParent(values)}
          />
          <AppFormField
            label="Notification Description"
            name="notification_description"
            placeholder="This is a description."
            maxLength={100}
          />

          <AppFormField
            label="Button Title"
            name="button_title"
            placeholder="Click now"
            maxLength={28}
          />
          <AppFormField
            label="Enter Button Url"
            name="button_url"
            placeholder="https://www.geta.ai"
          />
          <AppFormField
            label="Footer Text"
            name="footer_text"
            placeholder="T&C"
            maxLength={20}
          />

          <StyledCustomizerActionBtnsWrapper>
            {isEditing && (
              <div className="Cancel_button">
                <AppButton
                  onClick={handleCancel}
                  variant="outline"
                  style={{ width: "150px" }}
                >
                  Cancel
                </AppButton>
              </div>
            )}
            <AppSubmitButton
              variant=""
              title={isEditing ? "Update" : "Create"}
            />
          </StyledCustomizerActionBtnsWrapper>
        </AppForm>
      </AppSmallWindowPane>
      <AppSmallWindowPane>
        <SettingPageMainBoxTitle
          style={{ fontSize: "16px", marginBottom: "0.5rem" }}
        >
          Available Notification Blocks
        </SettingPageMainBoxTitle>

        {notificationData?.data?.map((curItem: any, index: number) => {
          return (
            <NotificationCard
              key={index}
              curItem={curItem}
              handleEditButton={handleEditButton}
              handleDeleteButton={handleDeleteButton}
              handleOpenUrlFn={handleOpenUrlFn}
              isEditing={isEditing ? true : false}
            />
          );
        })}
      </AppSmallWindowPane>
      <WebBotStaticPreview />
    </React.Fragment>
  );
}
