import React, { useCallback, useState } from "react";
import norecord from "../../assets/images/noDatafound.svg";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import useModal from "../../Hooks/useModel/useModel";
import { useNavigate, useParams } from "react-router-dom";
import CreateListModal from "./commonComponents/marketingListModals/CreateListModal";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";

interface CreateListModalProps {
  // userInput: any; //
  toggleModalCreate: () => void;
  handlClick: () => void;
  isShowing: boolean;
  setLocalValues: any;
  localValues: any;
  // setUserInput: any;
}

const ContactMarketingData: React.FC<CreateListModalProps> = ({
  toggleModalCreate,
  handlClick,
  isShowing,
  setLocalValues,
  localValues,
}) => {
  // hooks
  // const navigate = useNavigate();
  // const bot = useParams();
  // const { isShowing, toggle } = useModal();

  // statet

  return (
    <React.Fragment>
      <div className="data-container">
        <div className="norecord-data">
          <img src={norecord} alt="img" />
          <p>No Marketing list found</p>
        </div>

        <div className="add-list">
          <p>
            Before proceeding, please create a marketing list. A marketing list
            is a curated group of contacts for targeted marketing
            campaigns.Click the "Create New List" button below to get started.
            Give your list a descriptive name that reflects its purpose or
            criteria. Once your list is ready,you can use it to personalize your
            marketing efforts and reach specific audiences effectively.
          </p>

          <ProtectedAppButton
            moduleId="marketing_list_crud"
            specificPermissionKey="create"
            onClick={() => toggleModalCreate()}
          >
            <AppMaterialIcons iconName="add" />
            Create New List
          </ProtectedAppButton>
        </div>
      </div>

      {/* create marketing list  modal */}
      <CreateListModal
        toggleModalCreate={toggleModalCreate}
        handlClick={handlClick}
        isShowing={isShowing}
      />
    </React.Fragment>
  );
};
export default ContactMarketingData;
