// RouteGuard.tsx

import React from "react";
import { LoaderComponentProps, withLoader } from "../AppComponents/Loader";
import { ProtectedAccessDeniedPage } from "../AppComponents/ProtectedComponents";
import useRolesPermissionsAccess from "../Hooks/useRolesPermissionsAccess";
import { useAppSelector } from "../Store";
import { useAuthStore } from "../Store/Slices/AuthSlice";
import { isEmptyObject } from "../Utils";

interface RouteGuardProps extends LoaderComponentProps {
  moduleId: string;
  element: React.ReactElement<any>;
}

const RouteGuard: React.FC<RouteGuardProps> = ({
  moduleId,
  element,
  setLoading,
}) => {
  const { userConfig } = useAppSelector(useAuthStore);
  const { isValidAccess, isContentReady } = useRolesPermissionsAccess(moduleId);

  React.useEffect(() => {
    if (!isContentReady) {
      setLoading(false);
    }
  }, [isContentReady]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    !moduleId ||
    !userConfig ||
    isEmptyObject(userConfig?.payload?.allowed_permissions)
  )
    return null;

  if (!isContentReady) return null; // using this approach to avoid flickering of 404 page

  if (isContentReady && !isValidAccess) {
    return <ProtectedAccessDeniedPage />;
  }
  return element;
};

export default withLoader(RouteGuard);
