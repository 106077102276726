import * as Yup from "yup";

export const InactivityValidationSchema = Yup.object().shape({
    unattended_chat_hours: Yup.number().typeError('Hours must be a valid number').min(0).max(23).required().label("Hours"),
    unattended_chat_minutes: Yup.number().typeError('Minutes must be a valid number').min(0).max(59).required().label("Minutes"),
    // unattended_chat_minutes: Yup.number().min(0).max(59).required().label("Minutes"),
    closed_chat_hours: Yup.number().typeError('Hours must be a valid number').min(0).max(23).required().label("Hours"),
    // closed_chat_hours: Yup.number().min(0).max(59).required().label("Hours"),
    closed_chat_minutes: Yup.number().typeError('Minutesgma must be a valid number').min(0).max(59).required().label("Minutes"),
    // closed_chat_minutes: Yup.number().min(0).max(59).required().label("Minutes"),
});