import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Drawer, IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { enabledViewsListing, selectedContactViewState } from "../../../Store/Slices/Contacts/contacts.selector";
import { ContactsNetworkService } from "../../../Store/Slices/Contacts/contactsNetworkService";
import EditViewDrawer from "./edit-view-drawer";
import { ProtectedAppUiElement } from "../../../AppComponents/ProtectedComponents";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  marginTop: 0,
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.default.darkGrey,
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: 13,
      "& .MuiSvgIcon-root": {
        fontSize: 14,
        color: theme.palette.default.darkGrey,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {},
    },
  },
}));

interface Props {
  id: string;
  can_edit: boolean;
  can_delete: boolean;
}

export default function ViewMoreBtn({
  id,
  can_delete = false,
  can_edit = false,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const enabledViewsData: any = useAppSelector(enabledViewsListing);
  // const [selectedView, setSelectedView] = useState<any>(
  //   enabledViewsData?.find((view: any) => view?.viewId === id)
  // );

  const selectedContactView: any = useAppSelector(selectedContactViewState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editDrawerStatus, setEditDrawerStatus] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    setEditDrawerStatus(!editDrawerStatus);
  };

  const handleEditView = () => {
    handleClose();
    toggleDrawer();
  };
  const handleCloseView = () => {
    let payload = {
      id: id,
      data: {
        ...selectedContactView,
        position: null,
      },
    };
    ContactsNetworkService.updateContactView(payload)
      .then((res: any) => {
        dispatch(ContactsActions.getAllContactsViews({})).then((res) => {
          showAlert("View closed", "success");
          handleDefaultNavigate();
         });
      })
      .catch((err: any) => {
        console.log("err", err);
      })
      .finally(() => {
        handleClose();
      });
  };
  const handleDeleteView = () => {
    let payload = {
      id: id,
    };
    ContactsNetworkService.deleteContactView(payload)
      .then((res: any) => {
        dispatch(ContactsActions.getAllContactsViews({})).then((res) => {
          showAlert("View deleted", "success");
          handleDefaultNavigate();
        });
      })
      .catch((err: any) => {
        console.log("err", err);
      })
      .finally(() => {
        handleClose();
      });
  };

  const handleDefaultNavigate = () => {
    let viewId = enabledViewsData?.[0]?.viewId;
    navigate(`/contacts/list?view=${viewId}`);
  };


  return (
    <>
      <IconButton
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        title="Menu"
        disableRipple
      >
        <MoreHorizIcon />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {can_edit ? (
          <MenuItem onClick={handleEditView} disableRipple>
            <EditIcon />
            Rename view
          </MenuItem>
        ) : null}
        {can_edit ? (
          <MenuItem onClick={handleCloseView} disableRipple>
            <ClearIcon />
            Close view
          </MenuItem>
        ) : null}
        <Divider sx={{ my: 0.5 }} />
        {can_delete ? (
          <MenuItem onClick={handleDeleteView} disableRipple>
            <DeleteIcon />
            Delete view
          </MenuItem>
        ) : null}
      </StyledMenu>

      <Drawer
        anchor="right"
        open={editDrawerStatus}
        onClose={undefined}
        sx={{
          "& .MuiDrawer-paper": {
            width: "600px",
            padding: "0px",
            overflow: "hidden",
          },
        }}
        id="asdsa"
      >
        <EditViewDrawer
          selectedView={selectedContactView}
          status={editDrawerStatus}
          setEditDrawerStatus={setEditDrawerStatus}
        />
      </Drawer>
    </>
  );
}
