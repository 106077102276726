import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import uniqid from "uniqid";
import AppDeleteItem from "../../AppComponents/AppDeleteItem";
import AppIcon from "../../AppComponents/AppIcon";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import AppNoDataLoader from "../../AppComponents/AppNoDataLoader";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { formatString } from "../../Utils";
import {
  FaqTable,
  Scroll,
} from "../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { ConversationService } from "../../Services/Apis/ConversationService";

interface AppTableProps {
  rows?: any;
  getFaqList?: any;
  setSelectedDocumentIds: any;
  selectedDocumentIds: string[];
  localValues: any;
}

const FaqlistTable: React.FC<AppTableProps> = ({
  rows,
  getFaqList,
  setSelectedDocumentIds,
  selectedDocumentIds,
  localValues
}) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<any>(null);
  const navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);
  const { showAlert } = useNotification();

  const handleDeleteBtn = (item: any) => {
    setDeleteData(null);
    const headers = {
      appid: JSON.stringify({
        botid: currentBot.bot_id,
      }),
    };

    ConversationService.deleteFaqQuestion(item.qid, headers)
      .then((response: any) => {
        getFaqList();
        showAlert("FAQ is deleted", "success");
      })
      .catch((error) => {
        console.error("something went wrong", error);
      });
  };

  const handleViewClick = (currentElement: any) => {
    let url = formatString(
      "/bot/{0}/setup/bot-knowledge/faqs/faq-answer/{1}",
      [currentBot.bot_id, currentElement.qid]
    );
    navigate(url);
  };

  // Select And Unselct State
  // Handle All Check
  const handleCheckAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const allDocumentIds = rows.map((item: any) => item.qid);
      setSelectedDocumentIds(allDocumentIds);
    } else {
      setSelectedDocumentIds([]);
    }
  };
  // Handle Single Check
  const handleSelectChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    qid: string
  ) => {
    const isChecked = event.target.checked;

    setSelectedDocumentIds((prevSelectedIds: any) => {
      let updatedSelectedIds = isChecked
        ? [...prevSelectedIds, qid]
        : prevSelectedIds.filter((id: any) => id !== qid);

      // Check if all individual checkboxes are selected
      const allDocumentsSelected =
        rows.every((item: any) => updatedSelectedIds.includes(item.qid)) &&
        rows?.length > 0;

      // Update selectAll state based on the allDocumentsSelected flag
      setSelectAll(allDocumentsSelected);

      return updatedSelectedIds;
    });
  };
  // To select Next page Rows if Select All Option is Selected
  useEffect(() => {
    const allDocumentIds = rows?.map((item: any) => item?.qid);
    let idsArray: any;
    idsArray = [...selectedDocumentIds, ...allDocumentIds];
    idsArray = idsArray.filter(
      (value: any, index: number, array: any) => array.indexOf(value) === index
    );
    selectAll && setSelectedDocumentIds(idsArray);
  }, [rows, localValues]);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!rows || rows?.length < 1 ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "4rem 2rem 2rem 2rem",
            boxShadow: "none important",
          }}
        >
          <Paper
            sx={{
              width: "40%",
              mb: 2,
              boxShadow: "none !important",
              backgroundColor: "transparent",
            }}
          >
            <AppNoDataLoader />
          </Paper>
        </Box>
      ) : (
        <React.Fragment>
          <Scroll>
            <FaqTable>
              <Box className="table-header">
                <Box className="table-row">
                  {/* <Box className="col-head">Sr.No</Box> */}
                  <Box className="col-head">
                    <Checkbox
                      checked={selectAll}
                      onChange={handleCheckAllChange}
                    />
                  </Box>
                  <Box className="col-head" style={{ border: "none" }}>
                    FAQs Total: {rows?.length}
                  </Box>
                  <Box className="col-head">Created On</Box>
                  <Box
                    className="col-head"
                    style={{
                      maxWidth: "6rem",
                    }}
                  >
                    Action
                  </Box>
                </Box>
              </Box>
              <Box className="table-body">
                {rows &&
                  rows?.length > 0 &&
                  rows?.map((item: any, index: any) => {
                    return (
                      <Box
                        className="table-row"
                        key={uniqid()}
                        style={{ padding: "0px" }}
                      >
                        {/* <Box className="col-body"> {index + 1}</Box> */}
                        <Box className="col-body">
                          <Checkbox
                            checked={selectedDocumentIds.includes(item.qid)}
                            onChange={(event: any) =>
                              handleSelectChanges(event, item.qid)
                            }
                            id={item?.qid}
                          />
                        </Box>
                        <Box className="col-body"> {item?.question}</Box>
                        <Box className="col-body">
                          {!!item?.createdAt
                            ? moment(item?.createdAt).format(
                              "DD/MM/YYYY hh:mm A"
                            )
                            : "-"}
                        </Box>
                        <Box className="col-body">
                          <Box className="col-body">
                            <Stack
                              style={{
                                display: "flex",
                                gap: "1.5rem",
                                alignItems: "center",
                                flexDirection: "row",
                                justifyContent: "end",
                                width: "156px",
                              }}
                            >
                              <AppIcon
                                title={"ri-pencil-line"}
                                onClick={() => {
                                  handleViewClick(item);
                                }}
                                size="22px"
                                color="#7E8392"
                                hoverColor={"#282828"}
                                style={{
                                  marginRight: "1rem !important",
                                }}
                              />
                              <AppIcon
                                title={"ri-delete-bin-6-line"}
                                onClick={() => {
                                  setDeleteData(item);
                                }}
                                size="20px"
                                color="#7E8392"
                                hoverColor={"#F34E4E"}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "0.1rem",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleViewClick(item);
                                }}
                              >
                                <AppMaterialIcons iconName="RemoveRedEyeOutlined" />
                              </div>
                            </Stack>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </FaqTable>
          </Scroll>

          <AppDeleteItem
            title="Delete FAQ"
            subTitle="Are you sure, you want to delete this item ?"
            onDelete={() => handleDeleteBtn(deleteData)}
            setDeleteData={setDeleteData}
            deleteData={deleteData}
          />
        </React.Fragment>
      )}
    </>
  );
};

export default FaqlistTable;
