import { Box, List } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { getCurrentHoursMinutes } from "../../../../../Utils";
import BotRenderText from "./BotRenderText";

interface Props {
  welcome_message_text: string;
}

const BotChatScreen: React.FC<Props> = ({welcome_message_text}) => {

  return (
    <StyledChatScreenContainer>
      <List className="styledList">
        <BotRenderText userMessage={false} time={getCurrentHoursMinutes()}>
          {welcome_message_text}
        </BotRenderText>
      </List>
    </StyledChatScreenContainer>
  );
};

export default React.memo(BotChatScreen);

// const hH = 56;
// const fH = 28;
// const iH = 56;
const tH = 140;
const minH = 360 - tH;

const StyledChatScreenContainer = styled(Box)`
  && {
    padding: 10px 4px;
    overflow-y: overlay;
    height: calc(100% - 140px);
    max-height: 400px;
    min-height: ${minH}px;
    width: 100%;

    .styledList {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
`;
