import React, { memo } from "react";
import { StyledMessageText } from "../../Styles/StyledComponents";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";

interface Props {
  src: string;
  alt?: string;
  time: string;
  userMessage: boolean;
  msgId: string;
  children?: any;
  seen: string;
  sent: string;
  delivered: string;
  [otherProps: string]: any;
}

const AppRenderAudio: React.FC<Props> = ({
  src = "",
  alt = "",
  time,
  userMessage = false,
  msgId,
  seen,
  sent,
  delivered,
}) => {
  if (!src) {
    return null;
  }
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      isMedia={true}
      time={time}
      msgId={msgId}
      divStyle={{ minWidth: "45%" }}
      seen={seen}
      sent={sent}
      delivered={delivered}
    >
        <audio src={src} controls autoPlay={false} style={{ width: "100%" }} />
        {alt && alt?.length > 0 ? (
          <StyledMessageText
            userMessage={userMessage}
            dangerouslySetInnerHTML={{
              __html: alt,
            }}
            className="getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
          />
        ) : null}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderAudio);
