import React, { useEffect, useState } from 'react';
import AppButton from "../Components/Button";
import { AddScreenModel, FlowBuilderScreenHeader, FlowBuilderScreenList, FlowBuilderScreenWapper, RowWrapper } from "../Utils/style";
import AddIcon from '@material-ui/icons/Add';
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useAppDispatch } from '../../Store';
import { resetContentData, setContentData, setFlowBuilderList, setScreenId, setScreenType, setSubScreenList } from '../Store/FlowBuilder.slice';
import { useSelector } from 'react-redux';
import { getContentData, getFlowBuilderList, getScreenId, getSubScreenList } from '../Store/FlowBuilder.selectors';
import AppModel from '../Components/Model';
import AppInput from '../Components/Input';
import { set } from 'lodash';
import { randomString } from '../Utils/common';
import { useNotification } from '../../Hooks/useNotification/useNotification';

const FlowBuilderScreen = () => {
    let parentScreen: any = "parentScreen"
    let childScreen: any = "childScreen"
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const currentBot = useSelector(getScreenId);
    const contentData = useSelector(getContentData);
    const subScreens = useSelector(getSubScreenList);
    const flowBuilderList = useSelector(getFlowBuilderList);
    const [isShowing, setIsShowing] = useState(false)
    const [screenName, setScreenName] = useState('')
    const [resultArrayIds, setResultArrayIds] = useState<any>([])
    const resultArray: any[] = [];
    const [list, setList] = React.useState<any>({
        "version": "4.0",
        "screens": [


        ]
    });
    useEffect(() => {
        console.log("screen");

        let array: any = []
        let arrayId: any = []
        list?.screens?.map((parent: any) => {
            parent?.layout?.children?.forEach((child: any) => {
                if (child?.type === 'Form') {
                    child?.children?.forEach((grandChild: any) => {

                        if (grandChild?.type === 'OptIn') {
                            list?.screens?.map((res: any) => {
                                if (res?.id === grandChild['on-click-action']?.next?.name) {
                                    let data = {
                                        id: parent?.id,
                                        dataObj: res
                                    }
                                    arrayId.push(res?.id)
                                    array?.push(data)
                                }
                            })
                        }
                    });
                }
            });
        })
        dispatch(setSubScreenList(array))
        setResultArrayIds(arrayId)
        console.log("1");

        dispatch(setFlowBuilderList(list))
        // dispatch(setScreenId(list?.screens[0]?.id));
        // dispatch(setContentData(list?.screens[0]))
        dispatch(setScreenType(parentScreen))
    }, [])

    useEffect(() => {
        console.log("screen");

        let array: any = []
        let arrayId: any = []
        list?.screens?.map((parent: any) => {
            parent?.layout?.children?.forEach((child: any) => {
                if (child?.type === 'Form') {
                    child?.children?.forEach((grandChild: any) => {

                        if (grandChild?.type === 'OptIn') {
                            list?.screens?.map((res: any) => {
                                if (res?.id === grandChild['on-click-action']?.next?.name) {
                                    let data = {
                                        id: parent?.id,
                                        name: grandChild?.label,
                                        dataObj: res
                                    }
                                    arrayId?.push(res?.id)
                                    array?.push(data)
                                }
                            })
                        }
                    });
                }
            });
        })
        dispatch(setSubScreenList(array))
        setResultArrayIds(arrayId)
        dispatch(setFlowBuilderList(list))
    }, [list])

    useEffect(() => {
        if (contentData || flowBuilderList) {
            const updatedScreens = flowBuilderList?.screens?.map((screen: any) =>
                screen?.id === contentData?.id ? contentData : screen
            );
            setList({
                ...flowBuilderList,
                screens: updatedScreens
            });
            console.log("2")
            dispatch(setFlowBuilderList({
                ...flowBuilderList,
                screens: updatedScreens
            }))
        }
    }, [contentData]);

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;
        const items: any = Array.from(list.screens);
        if (result.source.index + 1 === list.screens.length) {
            console.log("items", items[result.source.index]);
            let status = false
            items[result.source.index]?.layout?.children[0]?.children?.map((res: any) => {
                if (!status) {
                    if (res.type === "PhotoPicker" || res.type === "DocumentPicker") {
                        status = true
                        showAlert("Upload Image and Upload Document components are only allowed on the final screen ( last screen ).", "error");
                        setList({
                            ...list,
                            screens: items,
                        });
                        console.log("3", items);
                        dispatch(setFlowBuilderList(list))
                        return true
                    } else {
                        const [reorderedItem] = items?.splice(result.source.index, 1);
                        items.splice(result?.destination?.index, 0, reorderedItem);
                        setList({
                            ...list,
                            screens: items,
                        });
                        console.log("3", items);
                        dispatch(setFlowBuilderList(list))
                    }
                }
            })
        } else {
            const [reorderedItem] = items?.splice(result.source.index, 1);
            items.splice(result?.destination?.index, 0, reorderedItem);
            setList({
                ...list,
                screens: items,
            });
            console.log("3", items);
            dispatch(setFlowBuilderList(list))
        }
    }
    useEffect(() => {
        setList(flowBuilderList);
    }, [flowBuilderList]);

    const AddScreensModel = () => {
        setScreenName('')
        setIsShowing(true)
    }
    const AddScreen = () => {
        var val = randomString(4);
        let screenObj = [
            ...flowBuilderList?.screens,
            {
                "id": "screen_" + val,
                "title": screenName,
                "data": {},
                "layout": {
                    "type": "SingleColumnLayout",
                    "children": [
                        {
                            "type": "Form",
                            "name": "flow_path",
                            "children": [
                                {
                                    "type": "Footer",
                                    "label": "Continue",
                                    "on-click-action": {
                                        "name": "navigate",
                                        "next": {
                                        },
                                        "payload": {
                                            resultArrayIds
                                        }
                                    }
                                },
                            ]
                        }
                    ]
                }
            }
        ]
        setList({
            ...flowBuilderList,
            screens: screenObj
        })
        console.log("4");
        dispatch(setFlowBuilderList({
            ...flowBuilderList,
            screens: screenObj
        }))
        setIsShowing(false)
    }
    const SetScreenNameFn = (name: string) => {
        setScreenName(name)
    }
    const removeScreen = (id: string) => {
        let ids = [id]
        subScreens.map((res: any) => {
            if (res?.id === id) {
                ids.push(res?.dataObj?.id)
            }
        })
        
        const updatedScreens = list.screens.filter((screen: any) => !ids?.includes(screen?.id));

        setList({
            ...list,
            screens: updatedScreens
        });
        dispatch(setFlowBuilderList({
            ...list,
            screens: updatedScreens
        }))
        // dispatch(resetContentData())
    };

    console.log("SubScreenList", list, flowBuilderList, subScreens);

    return (
        <>
            <FlowBuilderScreenWapper>
                <FlowBuilderScreenHeader>
                    <div className="headerTitle">Screens</div>
                    <AppButton disabled={list?.screens?.length === 9 ? true : false} onClick={AddScreensModel} startIcon={<AddIcon />} backgroundColor={list?.screens?.length === 9 ? "rgba(229,232,240,1)" : "rgb(33, 28, 82)"} text={"Add New"} />
                </FlowBuilderScreenHeader>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable-1" type="row">
                        {(provided) => (
                            <FlowBuilderScreenList ref={provided.innerRef} {...provided.droppableProps}>
                                {list?.screens?.map((screen: any, index: any) => {
                                    return (<Draggable key={screen?.id} draggableId={screen?.id} index={index}>
                                        {(provided) => (
                                            <div ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>


                                                {!resultArrayIds?.includes(screen?.id) ? <RowWrapper
                                                    active={currentBot === screen?.id}
                                                >
                                                    <div className="title" onClick={() => { dispatch(setScreenId(screen?.id)); dispatch(setContentData(screen)); dispatch(setScreenType(parentScreen)); }}>
                                                        <DragIndicatorIcon style={{ cursor: "pointer" }} />
                                                        <p>{screen.title}</p>
                                                    </div>
                                                    <div>
                                                        {list?.screens?.length > 1 ? <button className="closeBtn" onClick={() => removeScreen(screen?.id)}>
                                                            &times;
                                                        </button> : null}
                                                    </div>
                                                </RowWrapper> : null}
                                                {subScreens?.map((res: any) => {
                                                    if (res?.id !== screen?.id) return null;

                                                    return (
                                                        <RowWrapper
                                                            key={res?.id}
                                                            active={currentBot === res?.dataObj?.id}
                                                            style={{ marginLeft: "45px" }}
                                                        >
                                                            <div
                                                                className="title"
                                                                style={{
                                                                    width: "100%",
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis"
                                                                }}
                                                                title={res?.dataObj?.title}
                                                                onClick={() => {
                                                                    dispatch(setScreenId(res?.dataObj?.id));
                                                                    dispatch(setContentData(res?.dataObj));
                                                                    dispatch(setScreenType(childScreen));
                                                                }}
                                                            >
                                                                <p>{`${res?.dataObj?.title} (${res?.name})`}</p>
                                                            </div>
                                                        </RowWrapper>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </Draggable>)
                                })}
                                {provided.placeholder}
                            </FlowBuilderScreenList>
                        )}
                    </Droppable>
                </DragDropContext>
            </FlowBuilderScreenWapper>
            <AppModel width="546px" style={{ width: "100%" }} isShowing={isShowing} onClose={() => setIsShowing(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">Add New</div>
                        <button className="closeBtn" onClick={() => setIsShowing(false)}>
                            &times;
                        </button>
                    </div>
                    <div>
                        <div className="bodyTitle">Screen name</div>
                        <AppInput value={screenName} handleChange={SetScreenNameFn} placeholder="Enter your screen name" />
                    </div>
                    <div className='footer'>
                        <AppButton onClick={() => setIsShowing(false)} width="220%" fontColor="black" backgroundColor="#fff" text={"Cancel"} />
                        <AppButton onClick={AddScreen} width="220%" backgroundColor="#075E54" text={"Add"} />
                    </div>
                </AddScreenModel>
            </AppModel>
        </>
    )
}

export default FlowBuilderScreen;
