import React, { memo, useEffect } from "react";
import useRolesPermissionsAccess from "../../../Hooks/useRolesPermissionsAccess";
import { useNavigate } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { useAppSelector } from "../../../Store";
import { useAuthStore } from "../../../Store/Slices/AuthSlice";
import { isEmptyObject } from "../../../Utils";

interface Props {
  moduleId: string; // it is compulsory to pass moduleId
  specificPermissionKey: "create" | "read" | "update" | "delete" | undefined;

  initialValues: any;
  onSubmit: any;
  validationSchema?: any;
  innerRef?: any;
  validateOnChange?: boolean;
  divStyle?: React.CSSProperties;
  id?: string;
  enableReinitialize?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  [otherProps: string]: any;
}

const ProtectedAppForm: React.FC<Props> = ({
  moduleId,
  specificPermissionKey = undefined,
  initialValues,
  onSubmit,
  validationSchema,
  innerRef,
  id = "",
  divStyle,
  validateOnChange = true,
  disabled = false,
  children,
  ...otherProps
}) => {
  const { userConfig } = useAppSelector(useAuthStore);
  const { isValidAccess, isContentReady } = useRolesPermissionsAccess(
    moduleId,
    specificPermissionKey,
  );
  const navigate = useNavigate();

  useEffect(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;

    if (
      permissionsData &&
      isContentReady &&
      !isValidAccess &&
      specificPermissionKey
    ) {
      navigate("/forbidden");
    }
  }, [isContentReady, isValidAccess, specificPermissionKey]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isContentReady) return null; // using this approach to avoid flickering

  if (!specificPermissionKey) return null; // using this approach to avoid flickering

  if (!moduleId) return null;

  if (!isValidAccess) return null;
  // if (!isValidAccess) return <ProtectedAccessDeniedPage />;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
      innerRef={innerRef}
      validateOnChange={validateOnChange}
      {...otherProps}
    >
      {(props: FormikProps<any>) => {
        return (
          <form
            onSubmit={props.handleSubmit}
            id={id}
            style={divStyle}
            className={`formikFormWrapper ${
              specificPermissionKey === "read" || disabled
                ? "disableEntireForm"
                : ""
            }`} //DO NOT USE disableEntireForm for styling purpose only used to identify this component and disable conditional rendering
          >
            {children}
          </form>
        );
      }}
    </Formik>
  );
};

export default memo(ProtectedAppForm);
