import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { AppConfig } from "../../../../Config/app-config";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useSelector } from "react-redux";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import InstagramIntegrations from "./InstagramIntegration";
import InstagramConfigure from "./InstagramConfigure";
import { InstagramSettingsActions } from "../../../../Store/Slices/Settings/instagramSettings/instagramSettings.actions";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { instagramConfigList } from "../../../../Store/Slices/Settings/instagramSettings/instagramSettings.selectors";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
interface Props extends LoaderComponentProps {
  type: any;
  header: any;
  keyList: any;
  customStyle?: any;
}
const InstagramIndex: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [pageData, setPageData] = useState<any>();
  const configData = useAppSelector(instagramConfigList);

  const handleDisconnectButton = () => {
    dispatch(InstagramSettingsActions.logoutInstagramConfiguration({})).then(
      (save_result: any) => {
        showAlert("Disconnected from Facebook successfully!", "success");
        setIsConnected(false);
      },
    );
  };

  const componentClicked = (response: any) => {
    if (response.accessToken) {
      const data_for_config = {
        fb_exchange_token: response.accessToken,
      };
      setLoading(true);
      dispatch(
        InstagramSettingsActions.instagramConfigData(data_for_config),
      ).then((save_result: any) => {
        showAlert("Connected to Facebook successfully!", "success");
        getConfigPageList();
        setIsConnected(true);
      });
    }
  };

  useEffect(() => {
    if (configData?.count > 0) {
      setPageData(configData);
      setIsConnected(true);
    }
    setLoading(false);
  }, [configData]);

  const getConfigPageList = () => {
    dispatch(InstagramSettingsActions.instagramConfigList({})).then(
      (res: any) => {
        // setPageData(res.data);
        // if (res.data != "") {
        //   setIsConnected(true);
        // }
      },
    );
  };
  useEffect(() => {
    getConfigPageList();
    // ChatbotConsoleService.getFacebookData(currentBot.bot_id).then(
    //   (res: any) => {
    //     setPageData(res.data?.page_data?.data);
    //     if (res.data != "") {
    //       setIsConnected(true);
    //     }
    //   }
    // );
  }, []);

  return (
    <ZohoWrap>
      {isConnected ? (
        <InstagramConfigure
          handleDisconnectButton={handleDisconnectButton}
          pageData={pageData}
        />
      ) : (
        <InstagramIntegrations componentClicked={componentClicked} />
      )}
    </ZohoWrap>
  );
};

export default withLoader(InstagramIndex);

const ZohoWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
  }
`;
