import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class FacebookSettingsService {
  // post whatsapp config
  static facebookConfigData(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.ADD_FACEBOOK_CONFIG_URL}`,
      data,
    );
  }

  // get whatsapp config data list
  static getFacebookConfigData() {
    return http.get(`${consoleBaseUrl}${Endpoints.FACEBOOK_CONFIG_DATA_URL}`);
  }

  // get whatsapp config data list
  static logoutFacebookConfiguration(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.FACEBOOK_LOGOUT_URL}`, data);
  }

  static disconnectSingleFbPage(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.FACEBOOK_DISCONNECT_PAGE_URL}`,
      data,
    );
  }
}

// Label EndpointValidate whatsapp Settings
class Endpoints {
  static ADD_FACEBOOK_CONFIG_URL = "/workspace/facebook-integrations";
  static FACEBOOK_CONFIG_DATA_URL = "/workspace/get-facebook-configuration";
  static FACEBOOK_LOGOUT_URL = "/workspace/facebook-logout";
  static FACEBOOK_DISCONNECT_PAGE_URL =
    "/workspace/delete-facebook-subscribe-page";
}
