import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { format } from "date-fns";
import { FunctionComponent, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import {
  AnalyticsLoadingArray,
  BotList,
  BotTypeName,
  botDateRange,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import {
  removeDashboard,
  removeLoadingFromArray,
} from "../../../Store/Slices/DashboardRedux/dashboard.slice";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import CSAT from "../SummaryCSAT/CSAT";
import AvgDuration from "./AvgDuration";
import { WrapperBot } from "./BotStyle";
import Intents from "./Intents";
import MessageExchanged from "./MessagesExchanged";
import TotalConversationFailed from "./TotalAnsweredFailed";
import TotalConversations from "./TotalConversations";
import { SkeletonCard } from "../SkeletonLoader.tsx/SkeletonCard";
const ResponsiveGridLayout: any = WidthProvider(Responsive);
interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
  isDraggable?: boolean;
  isResizable?: boolean;
  isRearrangeable?: boolean;
}
const BotDashboard: FunctionComponent<Props> = (props) => {
  const [mounted, setMounted] = useState(false);
  const [compactType, setCompactType] = useState<
    "vertical" | "horizontal" | null | undefined
  >("vertical");
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });

  const loadingArray: any = useAppSelector(AnalyticsLoadingArray);
  const dashdateRange: any = useAppSelector(botDateRange);
  const dispatch = useAppDispatch();
  const botType = useAppSelector(BotTypeName);
  const botList = useAppSelector(BotList);
  const [botratingCount, setBotratingCount] = useState<any>({});
  const [botavgCsat, setBotavgCsat] = useState<any>(0);
  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };
  const onDrop = (layout: any, layoutItem: any, _ev: any) => {
    // alert(
    //   `Element parameters:\n${JSON.stringify(
    //     layoutItem,
    //     ["x", "y", "w", "h"],
    //     2
    //   )}`
    // );
  };

  const getaverageCsat = () => {
    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      type: "bot",
    };
    payload.csatRef = "botCSATDetails";
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
      };
    }
    dispatch(DashboardActions.getCsatDetails(payload))
      .then((res: any) => {
        let data: any = res?.payload;
        setBotratingCount(data?.data?.per_rating_count || {});
        setBotavgCsat(data?.data?.average_csat_rating || 0);
        dispatch(removeLoadingFromArray("botCSATDetails"));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      getaverageCsat();
    }
  }, [dashdateRange, botList]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperBot>
      <div className="parent_agent">
        <ResponsiveGridLayout
          {...props}
          //   layouts={layouts}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}
          //onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          onDrop={onDrop}
          isDroppable
          isBounded={true}
        >
          <div
            key="a"
            data-grid={{
              x: 0,
              y: 0,
              w: 4,
              h: 6,
              minW: 4,
              minH: 7,
              maxH: 7,
              maxW: 4,
              static: true,
            }}
            className="MessageBox"
          >
            <MessageExchanged />
          </div>
          <div
            key="b"
            data-grid={{
              x: 4,
              y: 0,
              w: 3,
              h: 6,
              minW: 3,
              minH: 7,
              maxH: 7,
              maxW: 3,
              static: true,
            }}
          >
            <AvgDuration />
          </div>
          <div
            key="d"
            data-grid={{
              x: 7,
              y: 0,
              w: 3,
              h: 6,
              minW: 3,
              minH: 7,
              maxH: 7,
              maxW: 3,
              static: true,
            }}
            className="botCsat"
          >
            <div>
              <p className="botCsat_header">
                Overall CSAT
                <AppToolTip
                  tooltipProps={{
                    title: "The overall CSAT of the bots ",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
                />
              </p>
            </div>
            <div className="botCsat_info">
              {loadingArray.includes("botCSATDetails") ? (
                <SkeletonCard />
              ) : (
                <div className="botCsat_info_d">
                  <p className="botCsat_info_count">{botavgCsat || "0"}</p>
                  <p className="botCsat_info_total">Avg CSAT</p>
                </div>
              )}
              <div className="csat_graph">
                <CSAT
                  ratingCount={botratingCount}
                  loading={loadingArray.includes("botCSATDetails")}
                />
              </div>
            </div>
          </div>

          <div
            key="e"
            data-grid={{
              x: 0,
              y: 6,
              w: 7,
              h: 6,
              minW: 7,
              minH: 6,
              maxH: 6,
              maxW: 7,
              static: true,
            }}
          >
            <TotalConversations />
          </div>
          <div
            key="f"
            data-grid={{
              x: 7,
              y: 6,
              w: 3,
              h: 6,
              minW: 3,
              minH: 6,
              maxH: 6,
              maxW: 3,
              static: true,
            }}
            className="failedCoversation"
          >
            <TotalConversationFailed />
          </div>

          <div
            key="g_parent"
            data-grid={{
              x: 0,
              y: 12,
              w: 10,
              h: 8,
              minW: 10,
              minH: 14,
              maxH: 14,
              maxW: 10,
              static: true,
            }}
            className="tableContainer"
          >
            <Intents />
          </div>
        </ResponsiveGridLayout>
      </div>
    </WrapperBot>
  );
};
BotDashboard.defaultProps = {
  className: "layout",
  rowHeight: 30,
  // onLayoutChange: (layout: any, layouts: any) => {},
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  isDraggable: false,
  isResizable: false,
  isRearrangeable: false,
};
export default BotDashboard;
