import { Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import AppButton from '../../../AppComponents/AppButton';
import { AppForm, AppSubmitButton } from '../../../AppComponents/Forms';
import { theme } from '../../../Customization/Theme';
import { useNotification } from '../../../Hooks/useNotification/useNotification';
import { useAppDispatch, useAppSelector } from '../../../Store';
import { automationBuilderPayload, automationBuilderState } from '../../../Store/Slices/AutomationBuilder/automationBuilder.selectors';
import { closeConfigModal, setNodeConfig } from '../../../Store/Slices/AutomationBuilder/automationBuilder.slice';
import { marketingListActions } from '../../../Store/Slices/MarketingList/marketingList.actions';
import { marketingListStore } from '../../../Store/Slices/MarketingList/marketingList.selectors';
import AppReactSelectField from '../../../socket/appComponents/UtilsComponents/AppFormReactSelect';
import { AddToListConfigSchema } from '../validationSchema';
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import { getDefaultValue } from '../utils';
import { saveGraph } from '../config';
import { withLoader } from '../../../AppComponents/Loader';

const AddOrRemoveList = ({ setLoading }: any): JSX.Element => {
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const formRef: any = useRef();
    const automationReduxData: any = useAppSelector(automationBuilderState);
    const { allMarketingListData } = useAppSelector(marketingListStore);
    const automationPayload: any = useAppSelector(automationBuilderPayload);
    const [initialValues, setInitialValues]: any = useState({
        marketing_list_id: ""
    })
    const closeModel = () => {
        dispatch(closeConfigModal({}));
    }
    useEffect(() => {
        if (automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.marketing_list_id) {
            const listInfo = getDefaultValue(allMarketingListData, "id", automationPayload?.draftedUIObject?.graphData?.nodes?.[automationReduxData?.selectedNodeId]?.nodeObjectData?.marketing_list_id)
            setInitialValues({
                marketing_list_id: {
                    value: listInfo?.name,
                    id: listInfo?.id,
                }
            })
        }
    }, [automationPayload, allMarketingListData])   // eslint-disable-line react-hooks/exhaustive-deps
    const handleSubmit = async (values: any, submitProps: any) => {
        let payload: any = {
            nodeObject: {
                marketing_list_id: values?.marketing_list_id?.id,
            },
            nodeId: automationReduxData?.selectedNodeId,
            configText: values?.marketing_list_id?.name
        }
        submitProps.setSubmitting(false);
        dispatch(setNodeConfig(payload));
        await saveGraph();
        showAlert("Node Config Saved Successfully!", "success");
        closeModel();
    }
    useEffect(() => {
        let data = {
            search: "",
            limit: 100,
            offset: 0,
        };
        dispatch(marketingListActions.getAllMarketingListings(data)).finally(() => {
            setLoading(false);
        });
    }, [])   // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <ModelFormWrapper isDataAvaliable={allMarketingListData?.length}>
            <Typography variant="h6">
                {automationReduxData?.modelType === "list-add" ? "Add To List " : "Remove From List"}
            </Typography>
            {allMarketingListData?.length ? (
                <AppForm
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validationSchema={AddToListConfigSchema}
                    innerRef={formRef}
                >
                    <AppReactSelectField
                        name={"marketing_list_id"}
                        options={allMarketingListData}
                        label="Select List"
                        displayKey="name"
                        valueKey="id"
                        placeholder="Select List"
                        isSearchable={true}
                        isClearable={true}
                        hideSelectedOptions={true}
                        isRequired={true}
                        divStyle={{ background: "none", padding: "0" }}
                        optionsPlaceholder="No List Found"
                    />
                    <ModelFooter>
                        <AppButton onClick={closeModel}>Cancel</AppButton>
                        <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
                    </ModelFooter>
                </AppForm>
            ) : (
                <React.Fragment>
                    <div className="image_container">
                        <NoDataFound />
                    </div>
                    <ModelFooter>
                        <AppButton onClick={closeModel}>Close</AppButton>
                    </ModelFooter>
                </React.Fragment>
            )}
        </ModelFormWrapper>
    )
}

export default withLoader(AddOrRemoveList);

const ModelFormWrapper = styled.div`
width: ${(props: any) => props.isDataAvaliable ? "400px" : "500px"};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
    display: flex;
    align-items:center;
    justify-content: flex-end;
    border-top: 1px solid ${theme.palette.default.border};
    padding: 15px 0 0;
    button:last-child {
        margin-left: 15px;
    }
`