// vendors
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

// components
import Icon from "../Icon";

const UICheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      size="small"
      disableRipple
      value={"user-input-query"}
      onChange={props.onChange}
      checked={props.checked}
      checkedIcon={<Icon icon="checked" size={16} />}
      icon={<Icon icon="checkbox-default" size={16} />}
      sx={{
        "&.MuiButtonBase-root": {
          padding: "0 !important",
        },
        "&.MuiCheckbox-root": {
          "& > .MuiSvgIcon-root": {
            stroke: "transparent",
            strokeWidth: "1px",
            borderRadius: "4px",
            height: "16px",
            width: "16px",
          },
        },
      }}
    />
  );
};
export default UICheckbox;
