import React, { useEffect } from "react";
import { WhatsappSettingsActions } from "../../../Store/Slices/Settings/WhatsappSettings/whatsappSettings.actions";
import { useAppDispatch } from "../../../Store";
import { useNotification } from "../../../Hooks/useNotification/useNotification";

const FacebookLoginButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  useEffect(() => {
    // Load the Facebook SDK asynchronously
    const loadFacebookSDK = () => {
      if (!(window as any).FB) {
        (window as any).fbAsyncInit = function () {
          (window as any).FB.init({
            appId: "544372894068159",
            cookie: true,
            xfbml: true,
            version: "v20.0",
          });
        };

        (function (d, s, id) {
          var js: any,
            fjs: any = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      }
    };

    loadFacebookSDK();
  }, []);

  const launchWhatsAppSignup = () => {
    if (!(window as any).FB) {
      console.error("Facebook SDK not loaded.");
      return;
    }

    // Facebook Login with JavaScript SDK
    (window as any).FB.login(
      function (response: any) {
        console.log(response, "response");
        console.log(
          response.authResponse.accessToken,
          "response.authResponse.accessToken",
        );
        if (response.authResponse) {
          const code: string = response.authResponse.code;
          const accessToken = response.authResponse.accessToken;
          let payload = { "access_token" : code };
          dispatch(WhatsappSettingsActions.whatsappConfigData(payload))
            .then(() => {
              dispatch(WhatsappSettingsActions.whatsappConfigList({}));
              showAlert("Config Added Successfully", "success");
            })
            .catch((error: any) => {
              showAlert(error?.error || "Something went wrong", "error");
            });
          // window.location.href =
          //   `${process.env.NOTIFIER_APP_ENDPOINT}/api/v1/whatsapp/auth/callback?access_token=` +
          //   code;
          // The returned code must be transmitted to your backend
          // which will perform a server-to-server call from there to our servers for an access token
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "707200291383635",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {
            solutionID: "951670443632073",
          },
        },
      },
    );
  };

  return (
    <button
      onClick={launchWhatsAppSignup}
      style={{
        backgroundColor: "#1877f2",
        border: "0",
        borderRadius: "4px",
        color: "#fff",
        cursor: "pointer",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        height: "40px",
        padding: "0 24px",
      }}
    >
      Login with Facebook
    </button>
  );
};

export default FacebookLoginButton;
