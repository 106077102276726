import { Box, Divider } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material/styles";
import * as React from "react";
import AppButton from "../../../AppComponents/AppButton";
import AppVerticleDragAndDrop from "../../../AppComponents/AppVerticleDragAndDrop";
import { theme } from "../../../Customization/Theme";
import { ReactComponent as ListIcon } from "../../../assets/images/drag-icon.svg";
import { ReactComponent as MenuButtonIcon } from "../../../assets/images/drag-menu-btn.svg";
// check List ==============**********
function CheckboxList(props: any) {
  const { setChecked, checked, contactHeaderMapping, totalCount } = props;
  const [checkAll, setCheckAll] = React.useState(true);
  const handleToggle = (value: any) => () => {
    const currentIndex = checked.filter(
      (item: any) => item.name !== value.name,
    );
    const isPresent = checked?.filter((item: any) => item.name === value.name);
    const newChecked = [...checked];

    if (isPresent?.length > 0) {
      // setSelectedFields(currentIndex);
      setChecked(currentIndex);
    } else {
      let data = {
        name: value?.name,
        label: value?.label,
      };
      newChecked.push(data);
      // setSelectedFields(newChecked);
      setChecked(newChecked);
    }
  };
  React.useEffect(() => {
    if (checked?.length === totalCount) {
      setCheckAll(false);
    } else {
      setCheckAll(true);
    }
  }, [checked]);
  React.useEffect(() => {
    if (checked?.length === totalCount) {
      setCheckAll(false);
    } else {
      setCheckAll(true);
    }
  }, []);
  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    if (checkAll) {
      let data = [
        {
          label: "Id",
          name: "id",
        },
        { label: "Name", name: "name" },
        ...contactHeaderMapping,
      ];
      setChecked(data);
      return true;
    } else {
      setChecked([
        {
          label: "Id",
          name: "id",
        },
        {
          label: "Name",
          name: "name",
        },
      ]);
      return false;
    }
  };
  return (
    <CustomisedMenuWrapper
      style={{
        overflowY: "auto",
        width: "267px",
        maxHeight: "calc(60vh - 20px)",
      }}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 255,
          bgcolor: "background.paper",
          height: "inherit",
        }}
      >
        <ListItem
          key={"selectAll"}
          style={{
            borderBottom: `1px solid ${theme.palette.default.border}`,
          }}
          disablePadding
        >
          <ListItemIcon style={{ minWidth: "10px", marginLeft: "8px" }}>
            <Checkbox
              edge="start"
              checked={!checkAll}
              onClick={() => handleCheckAll()}
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": "" }}
              size="small"
              style={{
                borderColor: theme.palette.default.border,
              }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ fontSize: "12px" }}
            id={"select_all"}
            primary={"Select All"}
          />
        </ListItem>
        {contactHeaderMapping?.map((value: any) => {
          const labelId = `checkbox-list-label-${value?.id}`;
          if (value.name !== "id") {
            return (
              <ListItem key={value?.name} disablePadding>
                <ListItemButton
                  role={undefined}
                  onClick={handleToggle(value)}
                  dense
                >
                  <ListItemIcon
                    style={{
                      marginLeft: "8px",
                    }}
                  >
                    <Checkbox
                      edge="start"
                      checked={checked?.some((ele: any) =>
                        ele?.name === value?.name ? true : false,
                      )}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      size="small"
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={value?.label?.toString()}
                    style={{ fontSize: "12px" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </List>
    </CustomisedMenuWrapper>
  );
}

// menu=====*********
export default function CustomizedMenus(props: any) {
  const {
    availableFields,
    setSelectedFields,
    dynamicsColumn,
    contactHeaderMapping,
    setContactHeaderMapping,
    AllContactPropertiesListdata,
    updateContactList,
  } = props;
  const [checked, setChecked] = React.useState(dynamicsColumn);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dragList, setDrageList] = React.useState(dynamicsColumn);
  const [upDatetdColumn, setUpdatedColumn] = React.useState([]);
  const open = Boolean(anchorEl);

  const [searchResults, setSearchResults] = React.useState(availableFields);
  // Hadnling Search Functionality
  const handleSelectSearch = (e?: any) => {
    let updatedContactMapping = AllContactPropertiesListdata.filter(
      (item: any) =>
        item?.label.toLowerCase()?.includes(e.target.value.toLowerCase()),
    );
    setContactHeaderMapping(updatedContactMapping);
    // setSearchResults(filteredData);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setContactHeaderMapping(AllContactPropertiesListdata);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setSearchResults(availableFields);
    }, 400);
  };

  React.useEffect(() => {
    setSearchResults(availableFields);
  }, [availableFields]);

  const OnSaveChanges = () => {
    // setSelectedFields(checked);
    // setDynamicsColumn(upDatetdColumn);
    updateContactList(upDatetdColumn);
    handleClose();
  };
  const resetFilter = () => {
    let data = upDatetdColumn.filter(
      (res: any) => res.name === "id" || res.name === "name",
    );
    updateContactList(data);
    setChecked(data);
    handleClose();
  };

  React.useEffect(() => {
    setDrageList(dynamicsColumn);
  }, [dynamicsColumn]);

  React.useEffect(() => {
    setChecked(dynamicsColumn);
  }, [dynamicsColumn]);

  const onDrag = (id: any, index: any, sortedArray: any) => {
    setChecked(sortedArray);
  };
  const ChildrenComponent = (i: any) => {
    if (i?.label !== "Id" && i?.label !== "Name") {
      return (
        <li>
          <div
            style={{
              display: "flex",
              margin: "5px -1.7rem",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <ListIcon />
            <DragList>{i.label}</DragList>
          </div>
        </li>
      );
    }
  };

  // On cancel-menu
  const onCancel = () => {
    setChecked(dynamicsColumn);
    handleClose();
  };
  return (
    <div>
      <AppButton
        onClick={handleClick}
        variant="outline"
        style={{
          width: "37px",
          minWidth: "37px",
        }}
        className="btn-grp"
      >
        <MenuButtonIcon />
      </AppButton>
      <StyledMenu
        className="style-menu-class"
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Box display={"flex"}>
          <Box flex={1}>
            <StyledAppInput>
              <input
                type="text"
                onChange={(e: any) => handleSelectSearch(e)}
                placeholder="Search columns"
              />
              {/* <SearchIcon /> */}
            </StyledAppInput>
          </Box>
          <Box
            flex={1}
            display={"flex"}
            justifyContent={"flex-start"}
            pl={"52px"}
            alignItems={"center"}
          >
            <p>Drag to Re-order columns</p>
          </Box>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box>
            <CheckboxList
              searchResults={searchResults}
              checked={checked}
              setChecked={setChecked}
              setSelectedFields={setSelectedFields}
              contactHeaderMapping={contactHeaderMapping}
              totalCount={availableFields?.length + 2}
            />
          </Box>
          <Box
            style={{
              maxHeight: "calc(60vh)",
              overflowY: "auto",
              scrollBehavior: "auto",
            }}
          >
            <section
              style={{
                padding: "0rem 3rem",
              }}
            >
              <ul style={{ listStyle: "none" }}>
                <AppVerticleDragAndDrop
                  element={ChildrenComponent}
                  listData={checked}
                  setUpdatedColumn={setUpdatedColumn}
                  onDrag={onDrag}
                />
              </ul>
            </section>
          </Box>
        </Box>
        <Divider sx={{ my: 0.5 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", marginLeft: "auto" }}>
            <AppButton
              onClick={onCancel}
              variant="grey"
              style={{
                margin: "0.5rem",
              }}
            >
              Cancel
            </AppButton>
            <AppButton
              onClick={OnSaveChanges}
              variant="primary"
              style={{
                margin: "0.5rem",
              }}
            >
              Save
            </AppButton>
          </Box>
        </Box>
      </StyledMenu>
    </div>
  );
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: "-40px",
    minWidth: 558,
    width: 558,
    minHeight: 145,
    color: "#7E8392",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "rgb(0, 0, 0, 0.6)",
        marginRight: theme?.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "transparent",
      },
      ".style-menu-class": {
        display: "flex",
      },
    },
  },
}));

const CustomisedMenuWrapper = styled("div")`
  .MuiTypography-body1,
  .MuiTypography-body2 {
    font-size: 13px;
  }
  li .MuiListItemButton-root {
    padding-left: 0;
  }
`;
const DragList = styled("div")`
  & {
    width: 231px;
    height: 37px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    /* padding: 1rem; */
    display: flex;
    align-items: center;
    padding: 12px;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
`;
const StyledAppInput = styled("div")`
  & {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0.6rem;
    input {
      height: 48px;
      border-radius: 4px;
      outline: none;
      border: 1px solid transparent;
      padding: 0 1.3rem 0 14px;
      font-family: "Rubik", sans-serif;
      border: 1px solid #cbcdd3;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      background-color: #fcfcfc;
      max-width: 238px;
      min-width: 238px;
      min-height: 35px;
      max-height: 35px;
    }
    svg {
      position: absolute;
      right: 10px;
    }
  }

  &::placeholder {
    color: ${theme.palette.default.grey};
  }
`;
