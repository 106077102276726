import React from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";


import {ReactComponent as SmsCostIcon} from "../../../../assets/images/sms-cost.svg"
import { ReactComponent as EmailCostIcon } from "../../../../assets/images/email-cost.svg"
import { ReactComponent as SmartAiCostIcon } from "../../../../assets/images/smartai-cost.svg"
import { ReactComponent as InboxCostIcon } from "../../../../assets/images/inbox-cost.svg"
import { ReactComponent as WhatsappCostIcon } from "../../../../assets/images/whatsapp-cost.svg"
import { kFormatter } from "../../../../Utils";


const InitialData = [
    {
        
        icon: <SmsCostIcon />,
        tooltipText: "",
    },
    {
        
        icon: <SmartAiCostIcon />,
        tooltipText: "",
    },
    {
        
        icon: <EmailCostIcon />,
        tooltipText: "",
    },
    {
        
        icon: <InboxCostIcon />,
        tooltipText: "",
    },
    {
        
        icon: <WhatsappCostIcon />,
        tooltipText: "",
    },
    {
        
        icon: <WhatsappCostIcon />,
        tooltipText: "",
    },
    {
       
        icon: <WhatsappCostIcon />,
        tooltipText: "",
    },
    {
        icon: <WhatsappCostIcon />,
        tooltipText: "",
    }
]

interface TotalCostSummaryBoxesProps {
    data: any,
    isLoading: any,
}


interface DataProps {
    icon: any,
    tooltipText: string,
}

const TotalCostSummaryBoxes: React.FC<TotalCostSummaryBoxesProps> = ({
    data = [],
    isLoading = false,
}) => {

    



   

    return <Box>
        <GridWrapper container spacing={2}>
            {InitialData.map((item: DataProps, index: number) => {
                return (
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                        <Box className="cost_box" sx={{padding: "1rem"}}>
                            <Box>
                                <Typography className="title">{isLoading ?  <Skeleton animation="wave" width={140} height={20} /> : data?.[index]?.name }

                                {
                                    !isLoading && <AppToolTip
                                    tooltipProps={{
                                    title: item?.tooltipText,
                                    placement: "top",
                                    }}
                                    iconButtonProps={{ disableRipple: "true" }}
                                    IconComponent={<AppMaterialIcons style={{fontSize: "14px"}} iconName="HelpOutline" />}
                                        /> 
                                } 
                                
                                </Typography>
                                <Typography className="inr_value">
                                    {
                                        isLoading ? <Skeleton  animation="wave" width={70} height={30} /> : <><span className="inr">INR</span>{kFormatter(data?.[index]?.total_amount)}</>
                                    }
                                </Typography>
                            </Box>
                            <Box className="icon">
                                {
                                    isLoading ? <Skeleton variant="circular" width={59} height={59} /> : item.icon
                                }
                            </Box>
                        </Box>
                    </Grid>
                )
            })}
        </GridWrapper>
    </Box> 
}

export default TotalCostSummaryBoxes;

const GridWrapper = styled(Grid)`
&& {
    .cost_box {
        border: 1px solid rgba(206, 201, 246, 1);
        display: flex;
        padding: 26px 24px;
        justify-content: space-between;
        align-items: center;
        height: 108px;
        max-height: 108px;
        background-color: ${theme.palette.default.white};
        border-radius: 4px;
        box-shadow: ${theme.palette.default.boxShadow};

        .icon {
            width: 60px;
            max-width: 60px;
            max-height: 60px;
            height: 60px;
        }

        .title {
            font-size: 15px;
            font-weight: 400;
            color: #6150E1;
            margin-bottom: 6px;
            display: flex;
            align-items: center;
        }

        .inr {
            font-size: 20px;
            font-weight: 400;
            margin-right: 6px;
        }

        .inr_value {
            font-size: 20px;
            font-weight: 500;
            display: flex;
            align-items: center;
        }
    }


    @media (max-width: 1399.98px) {

    .cost_box {

    .title {
            font-size: 14px;
            
        }

         .inr_value {
            font-size: 17px;
        }

        .inr {
            font-size: 17px;
        }

    }        

    }
}
`;
 