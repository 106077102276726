import { ListItem, ListItemButton } from "@mui/material";
import { ReactElement } from "react";
import uniqid from "uniqid";





interface EntryComponentProps {
  className?: string;
  onMouseDown(event: any): void;
  onMouseUp(event: any): void;
  onMouseEnter(event: any): void;
  role: string;
  id: string;
  "aria-selected"?: boolean | "false" | "true";
  theme?: any;
  mention: any;
  isFocused: boolean;
  searchValue?: string;
  selectMention(mention: any): void;
}

const MentionEntry = (props: EntryComponentProps): ReactElement => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    selectMention,
    ...parentProps
  } = props;
  return (
    <div {...parentProps} className="mentionItem" key={uniqid()}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={`${theme?.mentionSuggestionsEntryText}`}>
            <ListItem disablePadding>
              <ListItemButton>
                <div className="item">
               <span>{mention?.short_code} </span>  - {mention?.name}
                </div>
              </ListItemButton>
            </ListItem>

          </div>
        </div>
      </div>
    </div>
  );
}

export default MentionEntry;

