import React from "react";
import { StyledErrorText } from "../../Styles/StyledForms";
import { useFormikContext } from "formik";

interface Props {
  error?: any;
  visible?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppErrorMessage: React.FC<Props> = ({
  error,
  visible,
  ...otherProps
}) => {
  const {submitCount} = useFormikContext();
  if (!visible || !error || typeof error !== "string" || submitCount === 0) return null;

  return <StyledErrorText {...otherProps}>{error}</StyledErrorText>;
};

export default AppErrorMessage;
