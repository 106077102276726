import { AxiosResponse } from "axios";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  AppForm,
  AppFormRadioField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import { device } from "../../../../../Customization/BreakPoints";
import {
  Label,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox,
} from "../../../../../Customization/CommonElements";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import {
  ChatbotConfiguration,
  DefaultMessages,
} from "../../../../../Models/ChatbotConfiguration";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import AppReactSelectField from "../../../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { useParams } from "react-router-dom";
import {
  fetchFlow,
  fetchFlows,
} from "../../../../../Modules/FlowBuilder/Slices/main.service";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { useFlows } from "../../../../../Modules/FlowBuilder/Slices/main.slice";
import { ReactComponent as DangerSVG } from "../../../../../assets/images/danger.svg";
interface Props extends LoaderComponentProps {}
const OptOut = (props: Props) => {
  const dispatch = useAppDispatch();
  const flowsList = useSelector(useFlows);
  const form: any = useRef();
  const { bot_id }: any = useParams();
  const currentBot = useSelector(useCurrentBot);
  let { showAlert } = useNotification();
  const botId = { botid: bot_id || currentBot.bot_id };

  const [subFlowList, setSubFlowList] = useState<any>([]);

  const [userInput, setUserInput] = useState<any>({
    isFlowLinked: "false",
    message:
      "You have successfully unsubscribed from the WhatsApp messages. In case you wish to start receiving messages, reply with START.",
  });

  const onFlowChange = (values: any) => {
    if (values?.qid) {
      dispatch(fetchFlow({ botId, flowId: values?.qid }))
        .then((res: any) => {
          setSubFlowList(res?.payload);
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
  };
  const onChangeCallbackFn = (values: any) => {
    if (values.value === "true" && flowsList.length === 0) {
      dispatch(fetchFlows({ botId }))
        .then((res: any) => {
          console.log(res?.data?.data);
        })
        .catch((err: any) => {})
        .finally(() => {});
    }
    setUserInput({
      ...userInput,
      isFlowLinked: values.value,
    });
  };

  const getData = () => {
    if (
      currentBot?.chatbot_configuration &&
      currentBot?.chatbot_configuration
    ) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot?.chatbot_configuration as string,
      )
        .then((res: AxiosResponse<ChatbotConfiguration>) => {
          if (res.data?.enabled_devices) {
            const data: DefaultMessages | undefined | null =
              res.data?.default_messages;
            if (!data) {
              return;
            }
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setTimeout(() => {
            props.setLoading(false);
          }, 310);
        });
    } else {
      props.setLoading(false);
    }
  };

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    props.setLoading(true);

    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {},
      },
    )
      .then((response) => {
        SubmitProps.resetForm();
        showAlert("Configuration updated", "success");
        getData();
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error",
        );
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        props.setLoading(false);
      });
  };

  useEffect(() => {
    if (currentBot) {
      getData();
    }
  }, []);
  return (
    <SettingPageMainBoxWrap>
      <SettingPageMainBoxTopbar>
        <SettingPageMainBoxTitle>Opt Out Management</SettingPageMainBoxTitle>
      </SettingPageMainBoxTopbar>

      <SettingPageMainContentBox
        style={{
          alignItems: "flex-start",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          marginBottom: "1rem",
        }}
      >
        <StyledFormBox>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            innerRef={form}
            // validationSchema={AutoRepliesSchema}
          >
            <GroupBox>
              <Label style={{ fontWeight: "500" }}>
                Customise the message when user Opt-out to WhatsApp
                Communication.
              </Label>
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <DangerSVG style={{ minWidth: "20px" }} />{" "}
                  <small>
                    <strong>START</strong> is a predefined keyword used by the
                    system to initiate the opt-in process for WhatsApp
                    communication. Feel free to customize the message as needed,
                    but please do not change the word <strong>'START'</strong>.
                    Any alteration to <strong>'START'</strong> will break the
                    opt-in process
                  </small>
                </div>
                <AppFormTextArea name="message" count={true} maxCount={1000} />
              </>
            </GroupBox>

            {/* <GroupBox>
              <Label style={{ fontWeight: "500" }}>
                Collect User Information when outside Business Hours.
              </Label>

              <div
                className="checkboxDiv"
              >
                <AppFormCheckboxField
                  label={"Name"}
                  name="outside_business_hours.user_info.name"
                />
                <AppFormCheckboxField
                  label={"Email"}
                  name="outside_business_hours.user_info.email"
                />
                <AppFormCheckboxField
                  label={"Phone"}
                  name="outside_business_hours.user_info.phone"
                />
              </div>

              <AppFormSwitchField
                label="The message is shown when the Bot did not understand the query."
                name="live_agent.enabled"
                labelStyle={{ fontWeight: "500" }}
                divStyle={{ marginTop: "1.2rem", marginBottom: "0" }}
                tooltip={"Button will be only shown during business hours"}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "0rem",
                  gap: "1rem",
                }}
              >
                <AppFormSelectField
                  label="Show button after number of retries."
                  Options={RetryOptions}
                  name="live_agent.retry"
                  divStyle={{ width: "100%", marginBottom: 0 }}
                  disabled={
                    localValues && getIn(localValues, "live_agent.enabled")
                      ? false
                      : true
                  }
                />
            
              </div>
            </GroupBox> */}

            {/* <GroupBox>
              <Label style={{ fontWeight: "500" }}>
                Similar queries with message
              </Label>
              <AppFormTextArea
                rows={3}
                label="The message is shown when did not understood the query."
                name="conflict.message"
                placeholder="Did you mean this?"
              />
            </GroupBox> */}
            {/* 
            <div style={{ width: "45%" }}>
              <AppSubmitButton
                variant="cta"
                style={{ width: "45%", margin: "0.8rem 0" }}
                title={"Save Changes"}
              />
            </div> */}
            <AppSubmitButton
              variant="cta"
              style={{ width: "200px", margin: "0.8rem 0" }}
              title={"Save Changes"}
            />
          </AppForm>
        </StyledFormBox>
      </SettingPageMainContentBox>
    </SettingPageMainBoxWrap>
  );
};

export default withLoader(OptOut);

const StyledFormBox = styled.div`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    .checkboxDiv {
      display: flex;
      gap: 1rem;
      margin-top: -0.5rem;
      flex-wrap: nowrap;
      @media ${device.laptop} {
        flex-wrap: wrap;
      }
    }
  }
`;

const GroupBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: 100%;
    margin-bottom: 1rem;
  }
`;
