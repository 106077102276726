import styled from "styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import React from "react";
import * as MUI from "@mui/material";
import { Switch } from "@mui/material";
import { theme } from "../../../../../Customization/Theme";

interface AppInputFieldProps {
  label?: string;
  divStyle?: any;
  labelStyle?: any;
  tooltip?: any;
  submitOnChange?: boolean;
  className?: string;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const SwitchButton: React.FC<AppInputFieldProps> = ({
  label,
  divStyle,
  tooltip,
  labelStyle,
  submitOnChange = false,
  sendValuesToParent,
  children,
  className,
  ...otherProps
}) => {

  const CustomSwitch = MUI.styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 15,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 4,
      '&.Mui-checked': {
        transform: 'translateX(12px)',
        color: '#FFFFFF',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#5B73E8;',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 8,
      height: 8,
      borderRadius: 6,
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  return (
    <Div style={divStyle} className={className}>
      <CustomSwitch
        inputProps={{ "aria-label": "controlled" }}
        {...otherProps}
      />
      <Label style={labelStyle}>
        {label}
      </Label>
    </Div>
  );
};

export default SwitchButton;

const Div = styled.div<StyledProps>`
  && {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 1rem;

    .MuiFormControlLabel-root {
      margin-right: 0px !important;
      margin-left: 0px !important;
    }

    .Mui-disabled{
      pointer-events: auto !important;
      cursor: not-allowed !important;
    }
    &.automationSwitch {
      margin-bottom: 0;
      label {
        color: ${theme.palette.default.black};
      }
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
