import React, { memo } from "react";
import { StyledReactVideoPlayer } from "../../Styles/StyledComponents";


interface Props {
  url?: any;
  lightMode?: boolean;
}

const AppVideoPlayer: React.FC<Props> = ({ url, lightMode=true }) => {
  return (
    <StyledReactVideoPlayer
      url={url}
      width={"100%"}
      controls={true}
      light={lightMode}
      />
  );
};

export default memo(AppVideoPlayer);
