import { Chatbot, PaginatedResponse } from '../../../Models';
import { chatbotConsoleAxios } from '../../../Utils/Axios';

import { CH_CONSOLE_ENDPOINTS } from '../Endpoints';
import { createGenericSliceAPICaller, HTTPMethod } from '../Common';
import { GenericState } from '../../../Store';



const {
  reducer: myBotsReducer,
  apiFn: getMyBotsFn,
  storeAccessFn: useMyBotsStore
} = createGenericSliceAPICaller<GenericState<PaginatedResponse<Chatbot>>, any>({
  sliceName: 'chatbots',
  axiosObj: chatbotConsoleAxios,
  endpoint: CH_CONSOLE_ENDPOINTS.CHATBOTS,
  data: {},
  httpMethod: HTTPMethod.GET,
  storeAccessPath: 'userData.dashboardData.myBots'
});
// const useMyBotsStore = (state: any): PaginatedResponse<Chatbot> => {
//   return state.userData.dashboardData.myBots as PaginatedResponse<Chatbot>;
// };

export {getMyBotsFn};
export {myBotsReducer};
export {useMyBotsStore};
