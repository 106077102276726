import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { EmailSettingsActions } from "./emailSettings.actions";

interface initialStateTypes {
    data: {
        isEmailEnabled: boolean;
        profileData: any;
        emailLists: any;
        isDeleted: boolean;
        addressList: any;
        domainList: any;
        domainName: any
    };
    error: string;
    loading: boolean;
}

const initialState: initialStateTypes = {
    data: {
        isEmailEnabled: false,
        profileData: null,
        emailLists: null,
        isDeleted: false,
        addressList: null,
        domainList: null,
        domainName: null
    },
    error: "",
    loading: false,
};

const EmailSettingsSlice = createSlice(
    {
        name: "EmailSettingsSlice",
        initialState,
        reducers: {
            makeEmailEnabled: (state: any, action?: PayloadAction<any>) => {
                state.data.isEmailEnabled = true;
                return state;
            },
            resetError: (state: any, action?: PayloadAction<any>) => {
                state.error = "";

                return state;
            }
        },
        extraReducers: (builder) => {
            builder
                // Enable Channel Settings
                .addCase(
                    EmailSettingsActions.deleteEmail.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    EmailSettingsActions.deleteEmail.fulfilled,
                    (state, action) => {
                        if (action?.payload?.data?.status === 200) {
                            state.data.isDeleted = true
                        } else {
                            state.data.isDeleted = false
                            state.error = action?.payload?.data?.error
                        }

                    }
                )
                .addCase(
                    EmailSettingsActions.deleteEmail.rejected,
                    (state, action) => {
                        state.loading = false
                        state.data.isDeleted = false
                        state.error = action?.error?.message || ""
                    }
                )
                // Enable Channel Settings
                .addCase(
                    EmailSettingsActions.getValidEmailLists.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    EmailSettingsActions.getValidEmailLists.fulfilled,
                    (state, action) => {
                        if (action?.payload?.data?.status === 200 || action?.payload?.data?.response) {
                            state.data.emailLists = action?.payload?.data?.response;
                            state.loading = false
                        } else {
                            state.error = action?.payload?.data?.error
                            state.loading = false
                        }

                    }
                )
                .addCase(
                    EmailSettingsActions.getValidEmailLists.rejected,
                    (state, action) => {
                        state.loading = false
                        state.data.emailLists = null
                        state.error = action?.error?.message || ""
                    }
                )
                // Enable Channel Settings
                .addCase(
                    EmailSettingsActions.enableEmailChannel.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    EmailSettingsActions.enableEmailChannel.fulfilled,
                    (state, action) => {
                        if (action?.payload?.data?.status === 200 || action?.payload?.data?.response?.config_key) {
                            state.data.isEmailEnabled = true
                        } else {
                            state.error = action?.payload?.data?.error
                        }

                    }
                )
                .addCase(
                    EmailSettingsActions.enableEmailChannel.rejected,
                    (state, action) => {
                        state.loading = false
                        state.data.isEmailEnabled = false
                        state.error = action?.error?.message || ""
                    }
                )
                // Validate Email Settings
                .addCase(
                    EmailSettingsActions.validateEmailList.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    EmailSettingsActions.validateEmailList.fulfilled,
                    (state, action) => {
                        // if (action?.payload?.data?.status === 200) {
                        //     state.data.isEmailEnabled = true
                        // } else {
                        //     state.data.isEmailEnabled = false
                        // }

                    }
                )
                .addCase(
                    EmailSettingsActions.validateEmailList.rejected,
                    (state, action) => {
                        state.loading = false
                        // state.data.isEmailEnabled = false
                        state.error = action?.error?.message || ""
                    }
                )
                // Update Email Profile
                .addCase(
                    EmailSettingsActions.updateProfile.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    EmailSettingsActions.updateProfile.fulfilled,
                    (state, action) => {
                        if (action?.payload?.data?.status === 200) {
                            state.data.profileData = true
                        } else {
                            state.data.profileData = null
                        }

                    }
                )
                .addCase(
                    EmailSettingsActions.updateProfile.rejected,
                    (state, action) => {
                        state.loading = false
                        // state.data.isEmailEnabled = false
                        state.error = action?.error?.message || ""
                    }
                )
                // Get addresses list
                .addCase(
                    EmailSettingsActions.getAddressesList.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    EmailSettingsActions.getAddressesList.fulfilled,
                    (state, action) => {
                        if (action?.payload?.data?.status === 200) {
                            state.data.addressList = action?.payload?.data?.response?.data
                        } else {
                            state.data.addressList = null
                        }

                    }
                )
                .addCase(
                    EmailSettingsActions.getAddressesList.rejected,
                    (state, action) => {
                        state.loading = false
                        state.data.addressList = null
                        state.error = action?.error?.message || ""
                    }
                )
                // Get domain list
                .addCase(
                    EmailSettingsActions.getDomainLists.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    EmailSettingsActions.getDomainLists.fulfilled,
                    (state, action) => {
                        if (action?.payload?.data?.status) {
                            state.data.domainList = action?.payload?.data?.response
                            state.data.domainName = action?.payload?.data?.response.map((item: any) =>
                                state.data.domainName = item.domain)
                        } else {
                            state.data.domainList = []
                        }

                    }
                )
                .addCase(
                    EmailSettingsActions.getDomainLists.rejected,
                    (state, action) => {
                        state.loading = false
                        state.data.domainList = []
                        state.error = action?.error?.message || ""
                    }
                )
                
        }
    }
)
export const { resetError, makeEmailEnabled } = EmailSettingsSlice.actions;
export default EmailSettingsSlice.reducer;