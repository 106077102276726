import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";

interface Props {
    tooltip?: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppDeleteIcon: React.FC<Props> = ({ tooltip='Delete',children, ...otherProps }) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <IconButton {...otherProps}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

export default AppDeleteIcon;
