// vendors
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";

const getInitialEditiorText = (text: string) => {
  const contentBlock = htmlToDraft(text);
  let initialEditorState = EditorState.createEmpty();
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    initialEditorState = editorState;
  }
  return initialEditorState;
};

const getPlainTextData = (editorState: EditorState) => {
  if (editorState) {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const value = blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
      
    return value !== "\n" ? value : "";
  }
};

const getHTMLContent = (editorState: EditorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

const hasIgnoredClass = (element: any, ignoredClass: any) =>
  (element.correspondingElement
    ? element.correspondingElement
    : element
  ).classList.contains(ignoredClass);

const isInIgnoredElement = (element: any, ignoredClass: any) => {
  do {
    if (
      hasIgnoredClass(element, ignoredClass) ||
      hasIgnoredClass(element, "MuiPopover-root") ||
      hasIgnoredClass(element, "MuiPickersPopper-root")
    ) {
      return true;
    }
  } while ((element = element.parentElement));

  return false;
};

export {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
};
