import React from "react";
import { WrapperScroller } from "./ResponsiveTableStyles";
import {
  humanizeDate,
  makeStringCapitalizeAndRemoveSpace,
} from "../../../../../../Utils";
import { AppToolTip } from "../../../../../UiComponents/AppToolTip/AppToolTip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const ResponsiveTable = <T extends object>({
  columns,
  data,
  subscriptionName = "",
}: InfiniteScrollTableProps<T>) => {
  // Generate render type using getType function
  const renderType: RenderType<any> = getType(data && data?.[0]);

  if (data?.length <= 0) {
    return null;
  }

  return (
    <WrapperScroller>
      <div className="tableStickyHead">
        <div className="tablHeadRow">
          {columns?.map((column: any, index: number) => {
            return (
              <React.Fragment>
                {column.value !== "id" &&
                  column.value !== "list_id" &&
                  column.value !== "broadcast_id" &&
                  column.value !== "created_time" &&
                  column.value !== "status_description" ? (
                  <div className="tableHeadCell" key={index.toString()}>
                    {makeStringCapitalizeAndRemoveSpace(
                      column?.label ||
                      column?.name ||
                      (typeof column === "string" && column) ||
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className="tableBody">
        {data?.map((item: any, index: number) => {
          return (
            <div
              key={index.toString()}
              role="checkbox"
              aria-checked={item?.isSelected}
              tabIndex={-1}
              className={`tableBodyRow ${item?.isSelected ? " activeRow" : ""
                } ${item[subscriptionName] === "No" ? "disabled" : ""}`}
            >
              {columns?.map((column: any, index2: number) => {
                if (
                  column.value !== "id" &&
                  column.value !== "created_time" &&
                  column.value !== "list_id" &&
                  column.value !== "broadcast_id" &&
                  column.value !== "status_description"
                ) {
                  return (
                    <div className="tableBodyCell" key={index2.toString()}>
                      {column.value === "message_status"
                        ? renderData(
                          item,
                          item[column.value].charAt(0).toUpperCase() +
                          item[column.value].slice(1),
                          column,
                          renderType
                        ) || "-"
                        : renderData(
                          item,
                          item[column.value],
                          column,
                          renderType
                        ) || "-"}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          );
        })}
      </div>
    </WrapperScroller>
  );
};

export default ResponsiveTable;

interface InfiniteScrollTableProps<T> {
  columns: T[];
  data: T[];
  rowsPerPage?: number;
  pageNumber?: number;
  totalDataCount: number;
  selectedDataCount?: number;
  hasMoreData?: boolean;
  name: string;
  setCheckboxFieldValue: any;
  handleClick?: any;
  handleSelectAllClick?: any;
  subscriptionName?: string;
  tableValue?: any;
}

type RenderType<T> = {
  [key in keyof T]:
  | "string"
  | "number"
  | "boolean"
  | "date"
  | "null"
  | "object"
  | "array";
};

const getType = <T,>(data: T): RenderType<T> => {
  const renderType: RenderType<T> = {} as RenderType<T>;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];
      let type = typeof value;

      if (type === "number" && Number.isFinite(value)) {
        // Render numbers as float with 2 digits after decimal
        renderType[key as keyof T] = "number";
      } else if (type === "string") {
        // Render strings as string
        renderType[key as keyof T] = "string";
      } else if (
        type === "object" &&
        (Array.isArray(value) || value === null)
      ) {
        // Render arrays and null as null
        renderType[key as keyof T] = "null";
      } else if (type === "object" && value instanceof Date) {
        // Render Date objects as local time
        renderType[key as keyof T] = "date";
      } else if (type === "boolean") {
        // Render booleans as boolean
        renderType[key as keyof T] = "boolean";
      } else {
        renderType[key as keyof T] = "string";
      }
    }
  }
  return renderType;
};

const renderData = (item: any, value: any, field: any, renderType: any) => {
  if (field?.value === "message_status") {
    if (value === "Failed") return <span style={{ color: "red", display: "flex", alignItems: "center" }}>Failed <AppToolTip
    divStyle={{ padding: "0 8px" }}
    tooltipProps={{
      title: item?.status_description.charAt(0).toUpperCase() + item?.status_description.slice(1),
      placement: "top",
    }}
    iconButtonProps={{ disableRipple: true }}
    IconComponent={<InfoOutlinedIcon className="div_tooltip" style={{ fontSize: "16px" }}
    />}
  />
  </span>
  }
  if (typeof value !== "object") {
    switch (renderType[field]) {
      case "string":
        return value as string;
      case "number":
        return typeof value === "number" ? value : value;
      case "boolean":
        return typeof value === "boolean" ? value.toString() : null;
      case "object":
        return value;
      case "array":
        return null;
      case "date":
        return typeof value === "string" ? humanizeDate(value, true) : null;
      default:
        return value ? value : null;
    }
  }
};
