import React, { memo } from "react";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppSmallButton from "../UtilsComponents/AppSmallButtons";

interface Props {
  userMessage: boolean;
  item: any;
  rowWrapperStyle?: React.CSSProperties | undefined;
  containerStyle?: React.CSSProperties | undefined;
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  msgId: string;
  [otherProps: string]: any;
}

const AppRenderLiveButtons: React.FC<Props> = ({
  userMessage,
  item,
  rowWrapperStyle,
  containerStyle,
  time,
  readStatus = "delivered",
  msgId,
  otherProps,
}) => {
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      time={time}
      isMedia={true}
      isButton={true}
      msgId={msgId}
      divStyle={containerStyle}
      rowStyle={rowWrapperStyle}
    >
      <AppSmallButton
        disableRipple disableFocusRipple disabled
        variant="outline"
        style={{ cursor: "not-allowed" }}
      >
        {item?.value?.text}
      </AppSmallButton>
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderLiveButtons);
