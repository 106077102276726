import styled from "styled-components";
import AppRangeDatePicker from "../../AppComponents/AppRangeDatePicker";
import { theme } from "../../Customization/Theme";
import { useAppDispatch, useAppSelector } from "../../Store";
import { Box } from "@mui/material";
import { dateRange } from "../../Store/Slices/DashboardRedux/dashboard.selectors";
import { setDashboardDateRange } from "../../Store/Slices/DashboardRedux/dashboard.slice";

interface Menubar2Props {
  wrapperStyle?: any;
}

const Menubar2: React.FC<Menubar2Props> = ({
  wrapperStyle
}) => {
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(dateRange);
  const onChange = (date: any) => {
    let temp = { ...date };
    delete temp.label;
    dispatch(setDashboardDateRange(temp));
  };
  return (
    <MenubarWrap style={wrapperStyle}>
      <MenubarLeftBox>
        <Box className="subheader">Summary</Box>
      </MenubarLeftBox>

      <MenubarRightBox>
        <DateBox>
          <AppRangeDatePicker
            dateRange={dashdateRange}
            setDateRange={onChange}
            // hideTodayOption={true}
          />
        </DateBox>
      </MenubarRightBox>
    </MenubarWrap>
  );
};

export default Menubar2;

export const MenubarWrap = styled.div`
  && {
    background-color: ${theme.palette.default.white};
    z-index: 1;
    height: 4rem;
    width: ${(props: any) => (props?.fullWidth ? `100%` : "calc(100% - 2px)")};
    padding: 0 1rem;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MenubarRightBox = styled.div`
  && {
    gap: 2rem;
    align-items: center;
    display: flex;
    float: right;
    text-align: right;
    margin-left: auto;
  }
`;

export const MenubarLeftBox = styled.div`
  && {
    gap: 2rem;
    align-items: center;
    display: flex;
    float: left;
    text-align: left;

    .subheader {
      color: #101010;
      font-size: 13px;
      font-family: Inter;
      font-weight: 500;
      padding: 18px 6px;
    }
  }
`;

export const SelectBox = styled.select`
  border: none;
  outline: none;
  background-color: white;
  font-size: 16px;
  color: blue;
`;

export const OptionTag = styled.option`
  border: none;
  outline: none;
  background-color: white;
  font-size: 16px;
  color: blue;
`;

export const DateBox = styled.div`
  color: blue;
`;
