import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class WebhookNetworkService {
  // get Webhook Key
  static getWebhookNetwork() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_WEBHOOK}`);
  }

  // generate Webhook
  static generateWebhookNetwork(data: any) {
    console.log(data)
    return http.post(
        `${consoleBaseUrl}${Endpoints.GENERATE_WEBHOOK}`, data
    )
  }
}
class Endpoints {
  static GET_WEBHOOK = "/workspace/whatsapp/setup/webhook";
  static GENERATE_WEBHOOK = "/workspace/whatsapp/setup/webhook";
}
