import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import AppButton from "../../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormFastField,
  AppFormObserver,
} from "../../../../AppComponents/Forms";
import { StyledContentBox } from "./LifeCycleListingSyles";
import { useSelector } from "react-redux";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { useAppDispatch } from "../../../../Store";
import { LifecycleStageActions } from "../../../../Store/Slices/Settings/LifecycleStage/lifecycleStage.actions";
import { LifecycleStage } from "../../../../Store/Slices/Settings/LifecycleStage/lifecycleStage.selectors";
import { CreateEditModes } from "./LifecycleListing";
import { LifecycleStageService } from "../../../../Store/Slices/Settings/LifecycleStage/lifecycleStageService";
import { withLoader } from "../../../../AppComponents/Loader";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { LifecycleFormCreateSchema } from "../../../../Models/ValidationSchema";
import LifecycleStatusArrayForm from "./LifecycleStatusArrayForm";
import { hasLostStatus, hasWonStatus } from "./utils";
import { getIn } from "formik";
import React from "react";
interface props {
  newId?: any;
  onModalClose: (val?: boolean | undefined) => void;
  setLoading: (val: boolean) => void;
  mode: string;
}

const CreateLifeCycle: React.FC<props> = ({
  newId,
  onModalClose,
  mode,
  setLoading,
}) => {
  const formRef = useRef<any>(null);
  const currentBot = useSelector(useCurrentBot);
  const currentLifecycleStage = useSelector(LifecycleStage);
  const dispatch = useAppDispatch();
  let { showAlert } = useNotification();
  const botid = currentBot?.bot_id;
  const new_id = newId;
  const [lifecycleStageData, setLifecycleStageData] = useState<any>(
    currentLifecycleStage
  );
  const [mData, setMData] = useState<any>([]);
  const [deletedStatuses, setDeletedStatuses] = useState<any>([]);
  const [createData, setCreateDatas] = useState<any>(false);

  const [userInput, setUserInput] = useState<any>({
    name: "",
    status: [],
  });
  const [newInput, setNewInput] = useState<any>({
    name: "",
    status: [
      { id: "", label: "", position: 1, type: "open" },
      { id: "", label: "", position: 2, type: "closed_lost" },
    ],
  });

  const [formikProps, setFormikProps] = useState<any>(null);

  useEffect(() => {
    if (new_id) {
      getLifeCycleData();
    }
    if (mode === CreateEditModes.CREATE) {
      formikProps?.resetForm();
      setUserInput({
        name: "",
        status: [],
      });
      setNewInput({
        name: "",
        status: [{ id: "new_1", label: "", position: 1, type: "open" }],
      });
      setLifecycleStageData(undefined);
      setCreateDatas(undefined);
    }
    setLoading(false);
  }, [new_id, mode]);

  function getLifeCycleData() {
    const params = {
      stage: new_id,
    };
    const data = { params: params };
    dispatch(LifecycleStageActions.getLifecycleStage(data));
  }

  useEffect(() => {
    if (currentLifecycleStage && mode === CreateEditModes.EDIT) {
      setLifecycleStageData(currentLifecycleStage);
      const newData = currentLifecycleStage?.statuses;

      setCreateDatas(currentLifecycleStage?.label);
      setMData(newData);
      let abc: any = [];
      newData?.forEach((item: any) => {
        abc.push({
          id: item?.id,
          label: item?.label,
          position: item?.position,
          type: item?.type,
        });
      });
      setUserInput({
        name: currentLifecycleStage?.label,
        status: abc,
      });
    }
  }, [currentLifecycleStage]);

  const onDragStart = (event: any, index: any) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
  };

  const onDrop = (items: any[], prevIndex: number, nextIndex: number) => {
    if (items.length > 1) {
      let str = `current status will always remain last`;
      let flag = true;
      if (items[nextIndex - 1]?.type === "closed_lost") {
        str = `lost status will always remain below the open statuses`;
        flag = false;
      } else if (items[nextIndex - 1]?.type === "closed_won") {
        str = `won status will always remain below the open statuses`;
        flag = false;
      }
      if (!flag) {
        showAlert(str, "error");
        return;
      } else {
        setUserInput({ ...userInput, status: items });
        formRef?.current?.setFieldValue("status", items);
      }
    }
  };

  const handleAddNewStatus = (
    values: any,
    push: any,
    insert: any,
    index: any,
    type: string = "open"
  ) => {
    const temp = {
      id: `new_${index + 1}`,
      label: "",
      position: index + 1,
      type: type,
    };
    if (type === "closed_lost") {
      if (hasLostStatus(getIn(values, "status"))) {
        return;
      } else {
        push(temp);
      }
    } else if (type === "closed_won") {
      if (hasWonStatus(getIn(values, "status"))) {
        return;
      } else {
        insert(values?.status?.length - 1, temp);
      }
    } else if (type === "open") {
      if (values?.status?.length < 1) {
        push(temp);
      } else if (
        !hasWonStatus(getIn(values, "status")) &&
        hasLostStatus(getIn(values, "status"))
      ) {
        insert(values?.status?.length - 1, temp);
      } else if (
        hasWonStatus(getIn(values, "status")) &&
        !hasLostStatus(getIn(values, "status"))
      ) {
        insert(values?.status?.length - 1, temp);
      } else if (
        hasWonStatus(getIn(values, "status")) &&
        hasLostStatus(getIn(values, "status"))
      ) {
        insert(values?.status?.length - 2, temp);
      } else if (
        !hasWonStatus(getIn(values, "status")) &&
        !hasLostStatus(getIn(values, "status"))
      ) {
        push(temp);
      }
    }
  };

  const removeStatus = (values: any, remove: any, index: any) => {
    if (values?.status?.length === 1) {
      return;
    }

    const removedItem = values?.status?.filter(
      (key: any, innerIndex: number) => index === innerIndex
    )[0];
    if (removedItem?.id) {
      removedItem.deleted = true;
      setDeletedStatuses([...deletedStatuses, removedItem]);
    }
    remove(index);
  };

  function handleReset(submitProps: any) {
    submitProps.resetForm();
  }

  function updateStatusPositions(statuses: any[]) {
    const updatedStatuses = statuses.map((item, index) => {
      return { ...item, position: index + 1 };
    });
    return updatedStatuses;
  }

  const onFormSubmit = (values: any, submitProps: any) => {
    const trimdata = updateStatusPositions(values?.status).map((item) => {
      if (item.id.includes("new_")) {
        // If id contains "_value", set it to an empty string
        return { ...item, id: "" };
      } else {
        // Otherwise, keep the original id
        return item;
      }
    });

    const updatedStatuses = trimdata;

    updatedStatuses.push(...deletedStatuses);
    const data: any = {
      label: values?.name,
      statuses: updatedStatuses,
    };
    if (mode === CreateEditModes.EDIT) {
      data["id"] = currentLifecycleStage?.id;
    }
    setLoading(true);
    if (mode === CreateEditModes.EDIT) {
      LifecycleStageService.updateLifecycleStage(data)
        .then((res) => {
          showAlert(res?.data?.message, "success");
          handleReset(submitProps);
          setLoading(false);
          onModalClose();
        })
        .catch((err) => {
          setLoading(false);
          const error = err?.response?.data?.message || "Something went wrong";
          showAlert(error, "error");
        });
    } else {
      LifecycleStageService.createLifecycleStage(data)
        .then((res) => {
          showAlert(res?.data?.message, "success");
          handleReset(submitProps);
          setLoading(false);
          onModalClose();
        })
        .catch((err) => {
          setLoading(false);
          const error = err?.response?.data?.message || "Something went wrong";
          showAlert(error, "error");
        });
    }
  };

  return (
    <StyledContentBox>
      <div className="formBox">
        <div className="form">
          <div>
            <h4>
              {mode === CreateEditModes.EDIT ? "Edit " : "Create "} Lifecycle
              Stage{" "}
            </h4>{" "}
          </div>
          <div>
            <button>
              <CloseIcon onClick={(e) => onModalClose()} />
            </button>
          </div>
        </div>
        <AppForm
          initialValues={createData ? userInput : newInput}
          onSubmit={(values: any, submitProps: any) => {
            onFormSubmit(values, submitProps);
          }}
          validationSchema={LifecycleFormCreateSchema}
          innerRef={formRef}
        >
          <div className="form-app">
            <AppFormFastField
              name="name"
              label="Lifecycle Stage name"
              placeholder="Stage Name"
            />
          </div>
          <LifecycleStatusArrayForm
            name={"status"}
            onDrop={onDrop}
            removeStatus={removeStatus}
            mode={mode}
            handleAddNewStatus={handleAddNewStatus}
          />
          <AppFormObserver setFormikProps={setFormikProps} />
          <Box display="flex" gap="1rem" paddingLeft="36rem">
            <AppButton variant="outline" onClick={() => onModalClose()}>
              {" "}
              Cancel
            </AppButton>
            <AppButton type="submit" variant="contained">
              Save
            </AppButton>
          </Box>
        </AppForm>
      </div>
    </StyledContentBox>
  );
};

export default withLoader(CreateLifeCycle);
