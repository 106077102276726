import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class ContactPropertiesSettingsService {
  // Enable Channel Settings
  static getListProperties() {
    const url = `${consoleBaseUrl}${Endpoints.LIST_PROPERTISE}`;
    return http.get(url);
  }

  static updateFieldProperties(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.UPDATE_FIELD_PROPERTISE}`;
    return http.post(url, data);
  }
  static ChangeOrderFieldProperties(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CHANGE_ORDER}`;
    return http.put(url, data);
  }
  static AddContactPropertiesSection(data: any) {
    if (data?.update) {
      const url = `${consoleBaseUrl}${Endpoints.Add_SECTION}`;
      return http.put(url, data);
    } else {
      const url = `${consoleBaseUrl}${Endpoints.Add_SECTION}`;
      return http.post(url, data);
    }
  }
  static DeleteContactPropertiesSection(data: any) {
    return http.delete(`${consoleBaseUrl}${Endpoints.Add_SECTION}`, {
      data: data,
    });
  }
  static UpdateContactPropertiesSection(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.Add_SECTION}`;
    return http.put(url, data);
  }
  static AddContactPropertiesFields(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES_FIELDS}`;
    return http.post(url, data);
  }
  static UpdateContactProperties(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES_PUT}`;
    return http.put(url, data);
  }
  static HideContactProperties(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES_HIDE}`;
    return http.put(url, data);
  }
  static DeleteContactProperties(data: any) {
    return http.delete(
      `${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES_DELETE}`,
      { data: data }
    );
  }
  static GetAllSectionDetails() {
    const url = `${consoleBaseUrl}${Endpoints.GTE_ALL_SECTION}`;
    return http.get(url);
  }
  static GetAllOptions(fieldId: any) {
    const url =
      `${consoleBaseUrl}${Endpoints.CONTACT_PROPERTIES_FIELDS}` +
      "/" +
      fieldId +
      "/options";
    return http.get(url);
  }
  static reorderFieldPosition(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.REORDER_FIELD_POSITION}`;
    return http.post(url, data);
  }
  static GetHiddenField() {
    const url = `${consoleBaseUrl}${Endpoints.HIDDEN_FIELDS}`;
    return http.get(url);
  }
  static UpdateHiddenField(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.UNHIDDEN_FIELDS}`;
    return http.put(url, data);
  }
  // Contact Dependencies Properties Services
  static GetDependencyProperties() {
    const url = `${consoleBaseUrl}${Endpoints.DEPENDENCY_FIELDS}`;
    return http.get(url);
  }

  // Contact Dependencies Properties Services
  static GetDependencyOptions(data: any) {
    // const url = `${consoleBaseUrl}${Endpoints.DEPENDENCY_FIELDS_OPTIONS}`;
    const url = `${consoleBaseUrl}/workspace/contact-properties/field/${data}/options`;
    return http.get(url);
  }

  // Create Dependency
  static CreateDependencyMapping(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CREATE_DEPENDENCY_MAPPING}`;
    if (data?.is_Edit) {
      return http.put(url, data);
    } else {
      return http.post(url, data);
    }
  }

  // Contact Dependencies Properties Services
  static GetMappingByID(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.GET_MAPPING_BY_ID}?field_id=${data}`;
    return http.get(url);
  }
  static GetDependencyList() {
    const url = `${consoleBaseUrl}${Endpoints.GET_DEPENDENCY_LIST}`;
    return http.get(url);
  }
  static DeleteDependency(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.DELETE_DEPENDENCY}`;
    return http.delete(url, { data: data });
  }
  static DeleteContactPropertiesField(data: any) {
    return http.delete(
      `${consoleBaseUrl}${Endpoints.UPDATE_FIELD_PROPERTISE}`,
      { data: data }
    );
  }
  static changeFieldPosition(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CHANGE_FIELD_POSITION}`;
    return http.post(url, data);
  }
}

// Label Endpoint
class Endpoints {
  static LIST_PROPERTISE = "/workspace/contact-properties-field-structure";
  static UPDATE_FIELD_PROPERTISE = "/workspace/contact-properties/field";
  static CHANGE_ORDER = "/workspace/contact-properties/change-order";
  static Add_SECTION = "/workspace/contact-properties/section";
  static CONTACT_PROPERTIES_FIELDS = "/workspace/contact-properties/field";
  static CONTACT_PROPERTIES_DELETE = "/workspace/contact-properties/field";
  static CONTACT_PROPERTIES_PUT = "/workspace/contact-properties/field";
  static CONTACT_PROPERTIES_HIDE = "/workspace/contact-properties/hide-field";
  static GTE_ALL_SECTION = "/workspace/contact-properties/sections";
  static REORDER_FIELD_POSITION =
    "/workspace/contact-properties/contact-position-update";
  static HIDDEN_FIELDS = "/workspace/contact-properties/hidden-fields";
  static UNHIDDEN_FIELDS = "/workspace/contact-properties/unhide-fields";
  static CREATE_DEPENDENCY_MAPPING =
    "/workspace/contact-properties/field-dependancy";
  static DEPENDENCY_FIELDS =
    "/workspace/contact-properties/properties-for-dependancy";
  static GET_MAPPING_BY_ID = "/workspace/contact-properties/field-dependancy";
  static GET_DEPENDENCY_LIST =
    "/workspace/contact-properties/field-dependancies";
  static DELETE_DEPENDENCY = "/workspace/contact-properties/field-dependancy";
  static CHANGE_FIELD_POSITION = "/workspace/contact-properties/change-positions"
}
