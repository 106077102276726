import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { createColumnHelper } from "@tanstack/react-table";
import React, { HTMLProps, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";
import AppButton from "../../AppComponents/AppButton";
import AppCustomTable from "../../AppComponents/AppCustomTable";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormField,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import AppReactSelectField from "../../AppComponents/Forms/AppFormReactSelect";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import { AppConfig } from "../../Config/app-config";
import { NoDataBox } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppModel from "../../Hooks/useModel/AppModel";
import useModal from "../../Hooks/useModel/useModel";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../Store";
import { ApplicationFormNetworkActions } from "../../Store/Slices/ApplicationsForm/applicationForm.actions";
import {
  getAttachmentData,
  getAttachmentError,
  getAttachmentLoading,
  getPaymentHistoryData,
  getPaymentHistoryError,
  getPaymentHistoryLoading,
} from "../../Store/Slices/ApplicationsForm/applicationForm.selectors";
import { ContactsActions } from "../../Store/Slices/Contacts/contacts.action";
import { ContactListingPagination } from "../../Store/Slices/Contacts/contacts.selector";
import { editId } from "../../Store/Slices/Contacts/contacts.slice";
import { capitalizeFirstLetter, getDefaultValue } from "../../Utils";
import NoDataImage from "../../assets/images/noDatafound.svg";
import { ReactComponent as SaveIcon } from "../../assets/images/save-icon.svg";
import { ReactComponent as TagCrossIcon } from "../../assets/images/tag-cross-icon.svg";
import { ActionPopover } from "../Broadcast/Email/Template/EmailTemplateListingStyle";
import AppSkeletonTableLoader from "../UiComponents/AppSkeletonTableLoader/AppSkeletonTableLoader";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";
import { ApplicationFormWrapper } from "./ApplicationFormStyles";
import ConfirmationDraftModal from "./Components/ConfirmationDraftModal";
import HandleOfflinePaymentModal from "./Components/HandleOfflinePaymentModal";
import MarkedAsReviewedModal from "./Components/MarkedAsReviewedModal";

interface BasicInfo {
  applicant_id: string;
  last_name: string;
  designername: string;
  email: string;
  emptyemail: string;
  phone: string;
  workphone: string;
  birthdate: string;
  facebook: string;
  twitter: string;
  linkdin: string;
  hasUpdateAceess?: string;
  sorting?: any;
  hidePagination?: boolean;
  removeApplicationsFormsLink?: boolean;
  totalCount?: any;
  sortingApply?: boolean;
  getApplicationsFormsTableData?: any;
}
export const ApplicationsListTable: React.FC<any> = ({
  data,
  toggleModal2,
  dynamicsColumn,
  setLocalValues,
  localValues,
  setShouldApply,
  toggleContactModal,
  selectedDocumentIds,
  setSelectedDocumentIds,
  NodataButton,
  createImportDoc,
  setSelectAll,
  selectAll,
  sorting,
  hasUpdateAceess,
  loader,
  hidePagination,
  removeApplicationsFormsLink,
  totalCount,
  sortingApply,
  leadRefresh,
  getApplicationsFormsTableData,
}) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const PaymentHistoryData: any = useSelector(getPaymentHistoryData);
  const isPaymentHistoryLoading = useSelector(getPaymentHistoryLoading);
  const isErrorInPaymenttHistoryAPI = useSelector(getPaymentHistoryError);
  const AttachmentData: any = useSelector(getAttachmentData);
  const isAttachmentDataLoading = useSelector(getAttachmentLoading);
  const isErrorInAttachmentDataAPI = useSelector(getAttachmentError);
  const paginationCount = useAppSelector(ContactListingPagination);
  const [dynamicsColumns, setDynamicsColumns] = useState<any>([]);
  const [applicationFormResID, setApplicationFormResID] = useState("");
  const [applicationFormRow, setApplicationFormRow] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAttachmentDrawer, setIsOpenAttachmentDrawer] = useState(false);
  const userData: any = AppConfig.getUserData("loggedInUserData")?.data;
  const [basicInfo] = useState<BasicInfo>({
    applicant_id: "Shrikant",
    last_name: "Dange",
    designername: "Graphics Designer",
    email: "shrikant.dange@srvmedia.com",
    emptyemail: "-",
    phone: "",
    workphone: "",
    birthdate: "",
    facebook: "",
    twitter: "",
    linkdin: "",
  });

  const OpenHandleOfflineModal = () => {
    const { isShowing, toggle } = useModal();
    return {
      isShowingOpenHandleOfflineModal: isShowing,
      toggleOpenHandleOfflineModal: toggle,
    };
  };
  const { isShowingOpenHandleOfflineModal, toggleOpenHandleOfflineModal } =
    OpenHandleOfflineModal();

  const DraftModal = () => {
    const { isShowing, toggle } = useModal();
    return {
      isShowingConfirmationDraftModal: isShowing,
      toggleConfirmationDraftModal: toggle,
    };
  };
  const { isShowingConfirmationDraftModal, toggleConfirmationDraftModal } =
    DraftModal();

  const ReviewModal = () => {
    const { isShowing, toggle } = useModal();
    return {
      isShowingReviewModal: isShowing,
      toggleReviewModal: toggle,
    };
  };
  const { isShowingReviewModal, toggleReviewModal } = ReviewModal();
  const [actionDrawerChildComponent] = useState<any>(null);

  const toggleDrawerNew = (contactId: any) => {
    setIsOpen(true);
    if (contactId) {
      dispatch(ApplicationFormNetworkActions.getPaymentDetails(contactId));
    }
  };

  const toggleDrawerDocument = (contactId?: any) => {
    setIsOpenAttachmentDrawer(true);
    if (contactId) {
      dispatch(ApplicationFormNetworkActions.getAttachmentData(contactId));
    }
  };

  // message
  const checkAllSelect: boolean =
    paginationCount?.totalRecords < localValues?.perPage
      ? false
      : selectedDocumentIds?.length === data?.length
        ? true
        : false;

  const useUpDateContactModal = () => {
    const { isShowing, toggle } = useModal();
    return { isUpdateShowingModal: isShowing, toggleUpdateContact: toggle };
  };
  const { isUpdateShowingModal, toggleUpdateContact } = useUpDateContactModal();

  //handle download form
  const handleApplicationFormDownload = (item: any) => {
    dispatch(ApplicationFormNetworkActions.getApplicationFormPdf(item?.application_response_id)).then((res: any) => {
      window.open(res?.payload?.data?.data?.pdf_url, "_blank");
    })
  };

  // handle view form flow 
  const handleViewFormFlow = (item: any) => {
    //check to check all the required fields are avaliable then only redirect
    if (item?.portal_user_id && item?.application_response_id && item?.portal_id) {
      window.open(
        `${process.env.REACT_APP_APPLICATION_PORTAL_BASE_URL}verify?view=true&geta_host=${userData?.geta_host}&portal_user_id=${item?.portal_user_id}&application_response_id=${item?.application_response_id}&portal_id=${item?.portal_id}`,
        "_blank",
      );
    }
  };
  // getcontact by id
  const getContactById = (contact_id: any) => {
    dispatch(ContactsActions.getEditContactsFilds(contact_id))
      .then((res: any) => {
        if (res?.payload?.data?.status !== 200) {
          showAlert(
            res?.payload?.data?.error || "Enabled to fetch Data",
            "error",
          );
        } else {
          dispatch(editId(contact_id));
          toggleContactModal();
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error",
        );
      })
  };

  const getPathColors = (percentage: number) => {
    if (percentage <= 25) {
      return "#E11212";
    } else if (percentage > 25 && percentage <= 50) {
      return "#F2D505";
    } else if (percentage > 50 && percentage <= 75) {
      return "#25E3F3";
    } else if (percentage > 75 && percentage <= 100) {
      return "#2AE230";
    }
  };
  const FirstColumn = (original: any, firstObjectKey: any) => {
    return original?.applicant_name && original?.last_name
      ? `${original?.applicant_name} ${original?.last_name}`
      : original?.applicant_name
        ? original?.applicant_name
        : original?.last_name
          ? original?.last_name
          : original?.email &&
            (original?.[firstObjectKey]?.country_code ||
              original?.[firstObjectKey]?.extension)
            ? `${original?.email} ${original?.[firstObjectKey]?.country_code ||
            original?.[firstObjectKey]?.extension +
            original?.[firstObjectKey]?.number
            }`
            : original?.email
              ? original?.email
              : original?.[firstObjectKey]?.country_code ||
                original?.[firstObjectKey]?.extension
                ? (original?.[firstObjectKey]?.country_code ||
                  original?.[firstObjectKey]?.extension) +
                original?.[firstObjectKey]?.number
                : "-";
  };

  // columns
  const nameTooltipComponent = (elm: any, index: number) => {
    return columnHelper.accessor(elm?.name, {
      header: headerName(elm?.label, elm),
      id: elm?.name,
      size: 240,
      minSize: 240,
      cell: (info: any) => {
        const original = info?.cell?.row?.original;
        let firstObjectKey: any = null;
        if (!(original?.status || original?.last_name || original?.email)) {
          for (const key in original) {
            if (
              typeof original[key] === "object" &&
              original[key] !== null &&
              original[key] === "status"
            ) {
              firstObjectKey = key;
              break;
            }
          }
        }
        return (
          <NameWrapper>
            <AppToolTip
              tooltipProps={{
                placement: "right",
              }}
              iconButtonProps={{
                disableRipple: true,
                style: { padding: 0 },
              }}
            >
              <div
                className="avatar long-word"
                title={FirstColumn(original, firstObjectKey) || "-"}
                style={{
                  display: "flex",
                  fontSize: "14px",
                  gap: "1rem",
                  alignItems: "center",
                  fontWeight: "400",
                  cursor: "pointer",
                  color: removeApplicationsFormsLink
                    ? theme.palette.primary.main
                    : theme.palette.default.black,
                }}
              >
                <span
                  style={
                    !removeApplicationsFormsLink
                      ? {
                        textDecoration: "underline",
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }
                      : {
                        fontSize: "13px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        color: theme.palette.default.black,
                      }
                  }
                >
                  {FirstColumn(original, firstObjectKey) || "-"}
                </span>
              </div>
            </AppToolTip>
            <EditDelete id={info} />
          </NameWrapper>
        );
      },
    });
  };

  const EditDelete: React.FC<any> = ({ id }) => {
    const rowItem = id.cell.row.original;
    const rowId = id?.cell?.row?.original?.application_response_id;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleDetails = (id: string) => {
      setTimeout(() => {
        getContactById(id);
      }, 100);
    };
    const deleteRow = () => {
      toggleModal2();
      dispatch(editId(rowId));
    };

    const handleOfflinePayment = (id: string) => {
      setApplicationFormResID(id);
      toggleOpenHandleOfflineModal();
    };

    // Handle Status
    const handleStatusToDraft = (row: any) => {
      if (row) {
        setApplicationFormRow(row);
        toggleConfirmationDraftModal();
      }
    };

    // Handle Review
    const handleReview = (row: any) => {
      if (row) {
        setApplicationFormRow(row);
        toggleReviewModal();
      }
    };

    return (
      <div className="buttonWrapper">
        <IconButton aria-describedby={id} onClick={(e: any) => handleClick(e)}>
          <AppMaterialIcons iconName="MoreVert" />
        </IconButton>
        <ActionPopover
          id={id}
          name="popup"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <List>
            {rowItem?.can_update ? (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleDetails(rowId);
                    handleClose();
                  }}
                >
                  Edit
                </ListItemButton>
              </ListItem>
            ) : null}

            {rowItem?.can_delete ? (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    deleteRow();
                    handleClose();
                  }}
                >
                  Delete
                </ListItemButton>
              </ListItem>
            ) : null}
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleDrawerNew(rowId);
                  handleClose();
                }}
              >
                Payment Details
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleViewFormFlow(rowItem);
                }}
              >
                View
              </ListItemButton>
            </ListItem>


            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleApplicationFormDownload(rowItem);
                  handleClose();
                }}
                disabled={rowItem?.payment_status !== "completed"}
              >
                Download Application Form
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleDrawerDocument(rowId);
                  setApplicationFormRow(rowItem);
                  handleClose();
                }}
              >
                View Documents
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  if (rowItem?.status !== "submitted") {
                    handleOfflinePayment(rowId);
                    handleClose();
                  }
                }}
                disabled={rowItem?.status === "submitted"}
              >
                Mark Payment Offline
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  if (rowItem?.status !== "incomplete") {
                    handleStatusToDraft(rowItem);
                    handleClose();
                  }
                }}
                disabled={rowItem?.status === "incomplete"}
              >
                Allow Resubmission
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  handleReview(rowItem);
                  handleClose();
                }}
              >
                Mark as{" "}
                {rowItem?.hvJvHdZX === "Yes" ? "Not Reviewed" : "Reviewed"}
              </ListItemButton>
            </ListItem>
          </List>
        </ActionPopover>
      </div>
    );
  };
  const columnHelper = createColumnHelper<any>();
  let idColumn: any = null; // Variable to store the column with "id" header
  let temp: any = [];

  const otherColumns = (data: any, name: any) => {
    return data[name] === "submitted" ? (
      <StatusChip>
        <div className="success" title="Completed">
          Completed
        </div>
      </StatusChip>
    ) : data[name] === "completed" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="completed">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "pending" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="pending">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "initiated" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="initiated">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "dropped" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="dropped">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "incomplete" ? (
      <StatusChip title="Incomplete">
        <div className="incomplete">Incomplete</div>
      </StatusChip>
    ) : data[name] === "completed" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="completed">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "pending" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="pending">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "initiated" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="initiated">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "dropped" ? (
      <StatusChip
        title={data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
      >
        <div className="dropped">
          {data[name]?.charAt(0)?.toUpperCase() + data[name]?.slice(1)}
        </div>
      </StatusChip>
    ) : data[name] === "pending" ? (
      "Pending"
    ) : data[name] === "completed" ? (
      "Completed"
    ) : data[name] && data[name]?.number ? (
      "+ " +
      (data[name]?.country_code
        ? data[name]?.country_code
        : data[name]?.extension) +
      " " +
      data[name]?.number
    ) : Array.isArray(data[name]) && data[name]?.[0]?.label ? (
      data[name]?.map((item: any) => item.label).join(", ")
    ) : Array.isArray(data[name]) ? (
      data[name]?.map((item: any) => item).join(", ")
    ) : data[name] ? (
      data[name]
    ) : (
      "-"
    );
  };

  const renderColumnData = (info: any, elm: any, index: any) => {
    let data = info.cell.row.original;
    if (elm?.name === "percentage_completed") {
      return (
        <div className="percentage-completed-ui">
          <Box>
            <div
              style={{
                maxWidth: "50px",
                minWidth: "50px",
                maxHeight: "50px",
                minHeight: "50px",
              }}
            >
              <CircularProgressbar
                text={`${data?.percentage_completed}%`}
                value={data?.percentage_completed}
                styles={buildStyles({
                  pathColor: data && getPathColors(data?.percentage_completed),
                })}
              />
            </div>
          </Box>
        </div>
      );
    } else {
      return (
        <span className="long-word" title={otherColumns(data, elm?.name)}>
          <span>{otherColumns(data, elm?.name) || "-"}</span>
        </span>
      );
    }
  };
  let headerName = (label: any, elm: any) => {
    if (localValues?.sort_by === 1 && localValues?.sort_field === elm?.name) {
      return label;
    } else if (
      localValues?.sort_by === -1 &&
      localValues?.sort_field === elm?.name
    ) {
      return label;
    } else {
      return label;
    }
  };
  const CreateCoulumns = () => {
    if (dynamicsColumn?.length !== 0) {
      dynamicsColumn?.forEach((elm: any, index: number) => {
        let colData;
        if (
          elm?.name !== "applicant_name" &&
          elm?.name !== "applicant_id" &&
          elm?.name !== "percentage_completed" &&
          elm?.name !== "payment_status"
        ) {
          colData = columnHelper.accessor(elm?.name, {
            header: headerName(elm?.label, elm),
            id: elm?.name,
            cell: (info: any) => renderColumnData(info, elm, index),
            size: 190,
            minSize: 190,
          });
          temp.push(colData);
        } else if (elm?.name === "applicant_id") {
          colData = columnHelper.accessor(elm?.name, {
            header: headerName(elm?.label, elm),
            id: elm?.name,
            cell: (info: any) => renderColumnData(info, elm, index),
            size: 70,
            minSize: 70,
          });
          temp.push(colData);
        } else if (elm?.name === "percentage_completed") {
          colData = columnHelper.accessor(elm?.name, {
            header: headerName(elm?.label, elm),
            id: elm?.name,
            cell: (info: any) => renderColumnData(info, elm, index),
            size: 140,
            minSize: 140,
          });
          temp.push(colData);
        } else if (elm?.name === "payment_status") {
          colData = columnHelper.accessor(elm?.name, {
            header: headerName(elm?.label, elm),
            id: elm?.name,
            cell: (info: any) => renderColumnData(info, elm, index),
            size: 100,
            minSize: 100,
          });
          temp.push(colData);
        } else {
          if (elm?.name !== "status") {
            let tollTip: any = nameTooltipComponent(elm, index);
            temp?.push(tollTip);
          }
        }

        if (elm?.name === "applicant_name") {
          idColumn = columnHelper.accessor("applicant_name", {
            id: "select",
            size: 10,
            maxSize: 10,
            header: ({ table }) => (
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            ),
            cell: ({ row }) => (
              <IndeterminateCheckbox
                {...{
                  checked: row?.getIsSelected(),
                  disabled: !row?.getCanSelect(),
                  indeterminate: row?.getIsSomeSelected(),
                  onChange: row?.getToggleSelectedHandler(),
                }}
              />
            ),
            footer: (info: any) => info.column.id,
          });
        }
      });
      temp.unshift(idColumn);
      setDynamicsColumns(temp);
    }
  };

  useEffect(() => {
    if (dynamicsColumn && dynamicsColumn?.length > 0) {
      CreateCoulumns();
    }
  }, [data, dynamicsColumn]); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePageChange(event: any, value: any) {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        currentPage: value - 1,
      });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        perPage: value,
      });
  };
  const getSortData = (data: any) => {
    setLocalValues &&
      setLocalValues({
        ...localValues,
        sort_field: data[0]?.id,
        sort_by: data[0]?.desc ? -1 : 1,
        defaultApply: true,
      });
  };

  const handleVewiDocument = (url: any) => {
    window.open(url, "_blank", "noreferrer");
  };
  const options = [
    { label: "Approved", value: "approved" },
    { label: "Rejected", value: "rejected" },
  ];

  return (
    <React.Fragment>
      {!data?.length && !loader ? (
        <React.Fragment>
          <ContactNodata
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "4rem 2rem 2rem 2rem",
              boxShadow: "none !important",
              backgroundColor: "white",
            }}
          >
            <Paper
              sx={{
                width: "40%",
                mb: 2,
                boxShadow: "none !important",
                backgroundColor: "transparent",
              }}
            >
              <NoDataBox>
                <img src={NoDataImage} alt="no data available" />
                <p>No Applications Found</p>
              </NoDataBox>
            </Paper>
          </ContactNodata>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="data-container">
            {/* header message */}
            {checkAllSelect ? (
              <div
                style={{
                  textAlign: "center",
                  backgroundColor: "#cfe8f1",
                  display:
                    hasUpdateAceess === "global_access" ? "block" : "none",
                }}
              >
                <React.Fragment>
                  {selectAll
                    ? "Clear selection"
                    : `${selectedDocumentIds?.length}   selected`}
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      setSelectedDocumentIds((prevState: any) => {
                        if (selectAll) {
                          setSelectAll(false);
                          return false;
                        } else {
                          setSelectAll(true);
                          return prevState;
                        }
                      });
                    }}
                  >
                    {selectAll
                      ? `${totalCount
                        ? totalCount
                        : paginationCount?.totalRecords
                      } applications selected.`
                      : `Select all ${totalCount
                        ? totalCount
                        : paginationCount?.totalRecords
                      } applications ?`}
                  </Button>
                  <IconButton
                    size="small"
                    style={{ color: theme.palette.default.black }}
                    disableRipple={true}
                    onClick={() => {
                      setSelectedDocumentIds(false);
                      setSelectAll(false);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              </div>
            ) : null}
            {/* table */}
            <AppCustomTable
              data={data}
              dynamicsColumns={dynamicsColumns}
              setSelectedRow={setSelectedDocumentIds}
              selectedRow={selectedDocumentIds}
              getSortData={getSortData}
              loader={loader}
              removeContactDetailsLink={removeApplicationsFormsLink}
              sortingApply={true}
              type="biggerRows"
              tableHeight={checkAllSelect ? "calc(100vh - 216px)" : "calc(100vh - 185px)"}
            />
          </div>

          {!hidePagination && data && data?.length ? (
            <AppPaginationComponent
              totalCount={paginationCount?.totalRecords}
              handlePageChange={handlePageChange}
              currentPage={localValues?.currentPage}
              totalPages={paginationCount?.totalPages}
              rowsPerPage={localValues?.perPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              title="Contacts"
            />
          ) : null}
        </React.Fragment>
      )}

      {/* basic inforamtion edit */}
      <AppModel
        isShowing={isUpdateShowingModal}
        onClose={() => toggleUpdateContact()}
        divStyle={{
          width: "671px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <ApplicationFormWrapper>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Basic Information</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => toggleUpdateContact()}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>

          <AppForm
            initialValues={basicInfo}
            onSubmit={(values: any, submitProps: any) => {
              // handleSubmitBtn(values, submitProps);
            }}
          >
            <div className="basic-info-popup">
              <div className="fname">
                <div className="input-field">
                  <AppFormField
                    name={"firstname"}
                    label="First Name"
                  />
                </div>
                <div className="input-field">
                  <AppFormField name={"last_name"} label="Last Name" />
                </div>
              </div>
              <div className="input-field">
                <AppFormField name={"designername"} label="Job title" />
              </div>

              <div className="email-verification">
                <div className="input-field-email">
                  <AppFormField name={"email"} label="Email" />
                </div>
                <div className="verify-email-button">
                  <AppButton variant="outline">Verify email</AppButton>
                </div>
              </div>

              <div className="input-field">
                <AppFormField name={"emptyemail"} label="Email" />
              </div>
              <div className="input-field">
                <AppFormField name={"phone"} label="Phone" />
              </div>

              <div className="input-field">
                <AppFormField name={"workphone"} label="work Phone" />
              </div>
              <div className="input-field">
                <AppFormField name={"birthdate"} label="D. O. B." />
              </div>
            </div>

            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleUpdateContact()} variant="grey">
                cancel
              </AppButton>
              <AppButton type="submit">
                <IconButton>
                  <SaveIcon />
                </IconButton>
                save
              </AppButton>
            </div>
          </AppForm>
        </ApplicationFormWrapper>
      </AppModel>

      {/* Drawer */}
      {isOpen && (
        <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
          <Box
            p={2}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: "#EEF1FF",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              fontSize={"16px"}
              fontWeight={500}
            >
              Payment Details
            </Typography>
            <Box onClick={() => setIsOpen(false)} sx={{ cursor: "pointer" }}>
              <AppMaterialIcons iconName="Close" />
            </Box>
          </Box>
          <Box
            sx={{
              width: "80vw",
              p: 3,
              height: "calc(100% - 78px)",
              overflow: "auto",
            }}
            role="presentation"
          >
            {isPaymentHistoryLoading ? (
              <AppSkeletonTableLoader
                numberOfCol={1}
                numberofRow={10}
                skeletonHeight={30}
                skeletonWidth={"100%"}
                skeletonHeaderHeight={40}
                skeletonHeaderWidth={"100%"}
                tableHeight={`calc(100vh - 128px)`}
              />
            ) : isErrorInPaymenttHistoryAPI ? (
              <NoRecordsFound
                headerText="Something Went Wrong!"
                subText="Error Loading Data.."
                height="calc(100vh - 126px)"
              />
            ) : Object.keys(PaymentHistoryData).length === 0 ? (
              <NoRecordsFound height="calc(100vh - 126px)" />
            ) : (
              <ListItem disablePadding sx={{ mb: 4 }}>
                <ContactHitstoyWrapper>
                  <Box className="payment-table">
                    <Box className="payment-table_row">
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          Payment Type
                        </Typography>
                      </Box>
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          {capitalizeFirstLetter(
                            PaymentHistoryData?.payment_details?.offline_payment_type || PaymentHistoryData?.payment_details?.card_type || "",
                          ) || "-"}

                        </Typography>
                      </Box>
                    </Box>
                    <Box className="payment-table_row">
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          Amount
                        </Typography>
                      </Box>
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          {PaymentHistoryData?.payment_details?.amount || "-"}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="payment-table_row">
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          Payment Mode
                        </Typography>
                      </Box>
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          {capitalizeFirstLetter(
                            PaymentHistoryData?.payment_mode,
                          ) || "-"}
                        </Typography>
                      </Box>
                    </Box>

                    {PaymentHistoryData?.payment_details
                      ?.offline_payment_type === "draft" ? (
                      <React.Fragment>
                        <Box className="payment-table_row">
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              Draft Number
                            </Typography>
                          </Box>
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              {PaymentHistoryData?.payment_details
                                ?.draft_number || "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box className="payment-table_row">
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              Draft Image
                            </Typography>
                          </Box>
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              {PaymentHistoryData?.payment_details?.draft_image
                                ?.source ? (
                                <img
                                  src={
                                    PaymentHistoryData?.payment_details
                                      ?.draft_image?.source
                                  }
                                  alt=""
                                />
                              ) : (
                                "-"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </React.Fragment>
                    ) : null}

                    {PaymentHistoryData?.payment_details
                      ?.offline_payment_type === "cheque" ? (
                      <React.Fragment>
                        <Box className="payment-table_row">
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              Cheque Number
                            </Typography>
                          </Box>
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              {PaymentHistoryData?.payment_details
                                ?.cheque_number || "-"}
                            </Typography>
                          </Box>
                        </Box>

                        <Box className="payment-table_row">
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              Cheque Image
                            </Typography>
                          </Box>
                          <Box className="table-data">
                            <Typography
                              variant="h6"
                              fontSize={"14px"}
                              fontWeight={"500"}
                            >
                              {PaymentHistoryData?.payment_details?.cheque_image
                                ?.source ? (
                                <img
                                  style={{
                                    maxWidth: "400px",
                                    maxHeight: "300px",
                                  }}
                                  src={
                                    PaymentHistoryData?.payment_details
                                      ?.cheque_image?.source
                                  }
                                  alt=""
                                />
                              ) : (
                                "-"
                              )}
                            </Typography>
                          </Box>
                        </Box>
                      </React.Fragment>
                    ) : null}

                    <Box className="payment-table_row">
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          Payment Status
                        </Typography>
                      </Box>
                      <Box className="table-data">
                        <Typography
                          variant="h6"
                          fontSize={"14px"}
                          fontWeight={"500"}
                        >
                          {capitalizeFirstLetter(
                            PaymentHistoryData?.payment_status || "",
                          ) || "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </ContactHitstoyWrapper>
              </ListItem>
            )}
          </Box>
        </Drawer>
      )}

      {isOpenAttachmentDrawer && (
        <Drawer
          anchor="right"
          open={isOpenAttachmentDrawer}
          onClose={() => setIsOpenAttachmentDrawer(false)}
        >
          <Box
            p={2}
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: "#EEF1FF",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              fontSize={"16px"}
              fontWeight={500}
            >
              Documents
            </Typography>
            <Box
              onClick={() => setIsOpenAttachmentDrawer(false)}
              sx={{ cursor: "pointer" }}
            >
              <AppMaterialIcons iconName="Close" />
            </Box>
          </Box>
          <Box
            sx={{
              width: "80vw",
              p: 3,
              height: "calc(100% - 78px)",
              overflow: "auto",
            }}
            role="presentation"
          >
            {isAttachmentDataLoading ? (
              <AppSkeletonTableLoader
                numberOfCol={1}
                numberofRow={10}
                skeletonHeight={30}
                skeletonWidth={"100%"}
                skeletonHeaderHeight={40}
                skeletonHeaderWidth={"100%"}
                tableHeight={`calc(100vh - 128px)`}
              />
            ) : isErrorInAttachmentDataAPI ? (
              <NoRecordsFound
                headerText="Something Went Wrong!"
                subText="Error Loading Data.."
                height="calc(100vh - 126px)"
              />
            ) : AttachmentData.length === 0 ? (
              <NoRecordsFound height="calc(100vh - 126px)" />
            ) : (
              <AppForm
                initialValues={AttachmentData}
                onSubmit={(values: any, submitProps: any) => {
                  const validate = values?.every((item: any) => item?.status);
                  if (!validate) {
                    showAlert(
                      "Please select status for all the documents",
                      "error",
                    );
                    submitProps.setSubmitting(false);
                    return;
                  }
                  const data = values?.map((item: any) => {
                    return {
                      status:
                        typeof item?.status === "object"
                          ? item?.status?.value
                          : item?.status,
                      name: item?.label,
                      id: item?.id,
                    };
                  });
                  const payload = {
                    id: applicationFormRow?.application_response_id,
                    data: { attachment_status_list: data },
                  };
                  dispatch(
                    ApplicationFormNetworkActions.updateAttachmentStatus(
                      payload,
                    ),
                  ).then((res) => {
                    if (res?.payload?.data?.Status) {
                      showAlert(
                        "Documents Status Updated Successfully",
                        "success",
                      );
                      setIsOpenAttachmentDrawer(false);
                    } else {
                      showAlert("Error Updating Status", "error");
                    }
                  });

                  submitProps.setSubmitting(false);
                }}
              >
                <ListItem disablePadding sx={{ mb: 4 }}>
                  <ContactHitstoyWrapper>
                    <Box className="payment-table">
                      {AttachmentData.map((ele: any, index: any) => {
                        return (
                          <Box className="payment-table_row" key={index}>
                            <Box className="table-data_2">
                              <Typography
                                variant="h6"
                                fontSize={"14px"}
                                fontWeight={"500"}
                              >
                                {ele?.label}
                              </Typography>
                            </Box>
                            <Box className="table-data_2">
                              <Typography
                                variant="h6"
                                fontSize={"14px"}
                                fontWeight={"400"}
                              >
                                {ele?.name}
                              </Typography>
                            </Box>
                            <Box
                              className="table-data_2"
                              display={"flex"}
                              alignItems={"center"}
                              justifyContent={"flex-end"}
                            >
                              <AppReactSelectField
                                name={`${index}.status`}
                                options={options}
                                displayKey="label"
                                valueKey="value"
                                defaultInputValue={
                                  getDefaultValue(options, "value", ele?.status)
                                    ?.label
                                }
                                placeholder="Select Status"
                                isSearchable={true}
                                isClearable={true}
                                hideSelectedOptions={true}
                                isRequired={false}
                                divStyle={{ background: "none", padding: "0" }}
                              />
                              <AppButton
                                onClick={() => handleVewiDocument(ele?.source)}
                                style={{
                                  marginLeft: "20px",
                                  padding: "10px",
                                  width: "150px",
                                }}
                              >
                                View Document
                              </AppButton>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </ContactHitstoyWrapper>
                </ListItem>
                <AppSubmitButton
                  title="Submit"
                  style={{ width: "100px", marginLeft: "auto" }}
                />
              </AppForm>
            )}
          </Box>
        </Drawer>
      )}

      <HandleOfflinePaymentModal
        isShowingOpenHandleOfflineModal={isShowingOpenHandleOfflineModal}
        toggleOpenHandleOfflineModal={toggleOpenHandleOfflineModal}
        applicationFormResID={applicationFormResID}
        getApplicationsFormsTableData={getApplicationsFormsTableData}
      />

      <ConfirmationDraftModal
        isShowingConfirmationDraftModal={isShowingConfirmationDraftModal}
        toggleConfirmationDraftModal={toggleConfirmationDraftModal}
        applicationFomrRow={applicationFormRow}
        getApplicationsFormsTableData={getApplicationsFormsTableData}
      />

      <MarkedAsReviewedModal
        isShowingReviewModal={isShowingReviewModal}
        toggleReviewModal={toggleReviewModal}
        applicationFomrRow={applicationFormRow}
        getApplicationsFormsTableData={getApplicationsFormsTableData}
      />

      <Drawer
        anchor="right"
        open={actionDrawerChildComponent?.type?.length > 0 ? true : false}
        onClose={undefined}
        sx={{
          "& .MuiDrawer-paper": {
            minWidth: "40%",
            maxWidth: "40%",
            padding: "0px",
            overflow: "hidden",
          },
        }}
      ></Drawer>
    </React.Fragment>
  );
};

export default ApplicationsListTable;

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <input
      type="checkbox"
      ref={ref}
      className="tanstack-check"
      {...rest}
      style={{ width: "12px", height: "12px" }}
    />
  );
}

const ContactNodata = styled(Box)`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: none !important;
  }
`;

const NameWrapper = styled("div")`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 250px;
  .buttonWrapper {
    position: absolute;
    right: 0px;
    background: #fff;
    box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.2);
    top: -3px;
    display: none;
    padding: 1px 4px;
    .MuiIconButton-root {
      padding: 6px;
    }
    svg {
      font-size: 15px;
    }
  }
  &:hover {
    .buttonWrapper {
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: flex-end;
    }
  }
`;

const ContactHitstoyWrapper = styled("div")`
  && {
    width: 100%;
    gap: 24px;
    display: flex;
    .details-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .visit {
        font-size: 12px;
        border-radius: 2px;
        padding: 6px 8px;
      }
      .time {
        text-align: center;
        margin-top: 4px;
        font-size: 13px;
        color: ${theme.palette.default.darkGrey};
      }
    }
    .date-box {
      max-height: 64px;
      border-radius: 4px;
      min-width: 106px;
      width: 106px;
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
      background-color: ${theme.palette.primary.dark};
      .day {
        font-size: 32px;
        font-weight: 700;
        color: ${theme.palette.default.white};
      }
      .month-year {
        color: ${theme.palette.default.white};
      }
    }

    .payment-table {
      width: 100%;
      border: 1px solid ${theme.palette.default.border};
      .payment-table_row {
        width: 100%;
        display: flex;
        border-bottom: 1px solid ${theme.palette.default.border};
        .table-data {
          width: 50%;
          padding: 20px 12px;
          border-right: 1px solid ${theme.palette.default.border};
          word-break: break-all;
        }
        .table-data_2 {
          width: 33.33%;
          padding: 20px 12px;
          border-right: 1px solid ${theme.palette.default.border};
          word-break: break-all;
        }
      }
    }

    .history-content_box {
      width: calc(100%);
      background-color: #fafafb;

      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;

      .border-r {
        border-right: 1px dashed ${theme.palette.default.border};
      }

      .user-details {
        min-width: 210px;
        max-width: 210px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .username {
          max-width: 190px;
          font-size: 16px;
          font-weight: 500;
          color: ${theme.palette.default.black};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .userText {
          max-width: 190px;
          font-size: 14px;
          color: ${theme.palette.default.darkGrey};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .user-details_2 {
        padding-bottom: 8px;
        .username {
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 4px;
          color: ${theme.palette.default.black};
        }
        .userText {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          color: ${theme.palette.default.darkGrey};
        }
      }
    }
  }
`;


const StatusChip = styled(Box)`
  && {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding: 2px 8px;
    border-radius: 4px;
    max-width: 160px;
    .incomplete {
      border: 1px solid ${theme.palette.default.error};
      background-color: #ffeded;
      color: ${theme.palette.default.error};
    }
    .success {
      border: 1px solid ${theme.palette.default.successNew};
      background-color: ${theme.palette.default.successNewBackground};
      color: ${theme.palette.default.successNew};
    }

    .completed {
      color: ${theme.palette.default.success};
      text-align: left;
    }
    .pending {
      color: ${theme.palette.default.warning};
      text-align: left;
    }
    .initiated {
      color: ${theme.palette.default.blue};
      text-align: left;
    }
    .dropped {
      color: ${theme.palette.default.error};
      text-align: left;
    }
  }
`;
