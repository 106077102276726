import React from "react";

interface Props {
    
}
const SmartAi: React.FC<Props> = ({}) => {
    return(
        <div>SmartAi</div>
    )
}

export default SmartAi;