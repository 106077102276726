import { useState } from "react";
import AppSmallWindowPane from "../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm, AppFormMobileField, AppSubmitButton
} from "../../../../AppComponents/Forms";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { DefaultIndiaMobileFormat } from "../../../../Models/defaultSelectOptions";
import { SmsNotificationSchema } from "../../../../Models/ValidationSchema";

const SmsNotifications = () => {
  const { showAlert } = useNotification();
  const [userInput, setUserInput] = useState<any>({
    mobile: "",
    mobileCountry: DefaultIndiaMobileFormat,
  });

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    // setTimeout(() => {
    SubmitProps.resetForm();
    showAlert("Configuration updated successfully", "success");
    SubmitProps.setSubmitting(false);
    // }, 5000);
  };

  return (
    <AppSmallWindowPane>
      {/* <AppPageTitle>Alert & Notifications</AppPageTitle> */}
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={SmsNotificationSchema}
      >
        <AppFormMobileField
          label="Mobile Number"
          name="mobile"
          placeholder="+919191923467"
          mobileCountryFormat='mobileCountryFormat'
        />

        <AppSubmitButton
          variant="cta"
          style={{ width: "100%", margin: "0.5rem 0" }}
          title={"Save Changes"}
        />
      </AppForm>
    </AppSmallWindowPane>
  );
};

export default SmsNotifications;
