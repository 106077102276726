import styled from "styled-components";
import { theme } from "../../Customization/Theme";

export const WebsiteWrap = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  .website-tabs .MuiTab-root {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: ${theme.palette.default.darkGrey};
    margin-left: 1rem;
    text-transform: capitalize;
  }

  .website-tabs .MuiTab-root.Mui-selected,
  .website-tabs .MuiTab-root.Mui-selected:hover {
    color: ${theme.palette.default.blue};
  }
`;

export const CustomTabPanelContainer = styled.div`
  
  // height: 700px;
  // border: 3px solid blue;
  
`;
