import React from "react";
import { useAppSelector } from "../Store";
import { useAuthStore } from "../Store/Slices/AuthSlice";
import { isEmptyObject } from "../Utils";
import { checkRouteHaveValidPermissions } from "../Utils/rolesUtils";

const useRolesPermissionsAccess = (moduleId: string, specificPermissionKey: "create" | "read" | "update" | "delete" | undefined = "read") => {
  const { userConfig } = useAppSelector(useAuthStore);

  const [isValidAccess, setIsValidAccess] = React.useState<boolean>(false);
  const [isContentReady, setIsContentReady] = React.useState<boolean>(false);

  const getAccessModuleDetails = () => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;

    if (!moduleId) return;

    if (!specificPermissionKey) return;

    const result: boolean =
      checkRouteHaveValidPermissions(permissionsData, moduleId,specificPermissionKey) ?? false;
    setIsValidAccess(result);
    if (!isContentReady) {
      setIsContentReady(true);
    }
  };

  React.useEffect(() => {
    getAccessModuleDetails();
  }, [userConfig?.payload, moduleId, specificPermissionKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return { isValidAccess, isContentReady };
};

export default useRolesPermissionsAccess;
