import { RootState } from "../../index";

// get All Subscription Plan
export const getAllPlansData = (state: RootState) =>
  state.subscription.getAllPlans;
export const getSubscriptionData = (state: RootState) =>
  state.subscription.getActiveSubscriptionPlanDetails;
export const getAddonListData = (state: RootState) =>
  state.subscription.getAddonList;
export const addonList = (state: RootState) => state.subscription.addonList;
export const getSelectPlanData = (state: RootState) =>
  state.subscription.selectPlanData;
export const getAllInvoiceList = (state: RootState) =>
  state.subscription.getAllInvoiceList;
export const getInvoiceCount = (state: RootState) =>
  state.subscription.getInvoiceCount;
export const getCountryList = (state: RootState) =>
  state.subscription.getCountryList;
export const getCreditListById = (state: RootState) =>
  state.subscription.getCreditListById;
export const getWallectInvoiceList = (state: RootState) =>
  state.subscription.getWallectInvoiceList;
export const getWallectInvoiceCount = (state: RootState) =>
  state.subscription.getWallectInvoiceCount;
export const getPendingInvoiceStatus = (state: RootState) =>
  state.subscription.getPendingInvoiceStatus;
export const invoicePaidStatus = (state: RootState) =>
  state.subscription.invoicePaid;
export const getCreditsUtilization = (state: RootState) =>
  state.subscription.getCreditsUtilization;
export const getwhatsappCreditLogsData = (state: RootState) =>
  state.subscription.whatsappCreditLogsData;

// Credit Summary Logs
export const creditSummaryLogsData  = (state: RootState) => state.subscription.creditSummaryLogsData;
export const creditSummaryLogsDataError  = (state: RootState) => state.subscription.creditSummaryLogsDataError;
export const creditSummaryLogsDataLoading  = (state: RootState) => state.subscription.creditSummaryLogsDataLoading;
export const creditSummaryLogsDataPagination  = (state: RootState) => state.subscription.creditSummaryLogsDataPagination;

// Credit Summary 
export const creditSummaryData  = (state: RootState) => state.subscription.creditSummaryData;
export const creditSummaryDataError  = (state: RootState) => state.subscription.creditSummaryDataError;
export const creditSummaryDataLoading  = (state: RootState) => state.subscription.creditSummaryDataLoading;;