import { useEffect, useRef, useState } from "react";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormField,
  AppFormObserver,
  AppFormSwitchField,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { useAppSelector } from "../../../../../Store";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { StyledLookFeelContainer } from "../../../utils/styles-bot-builder";
import { BotPersistantMenu } from "../../../../../Models/ValidationSchema";
import { FieldArray, FormikContextType } from "formik";
import AppReactSelectField from "../../../../../AppComponents/Forms/AppFormReactSelect";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete-icon.svg";
import AppButton from "../../../../../AppComponents/AppButton";
import { registerActions } from "../../../../../Modules/FlowBuilder/Nodes/Buttons/buttonRegisterActions";
import { FlowBuilderService } from "../../../../../Services/Apis/FlowBuilder";
import { AxiosResponse } from "axios";
import {
  generatePayloadFromValues,
  getMaxMenuItemLength,
  setFacebookMenu,
  setTelegramMenu,
} from "../../customizeUtils";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotLookFeel } from "../../../../../Models/ChatbotTheme";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import React from "react";
import { getText } from "../../../../../Modules/FlowBuilder/Utils";

// import WebBotLookFeel from "./WebBotLookFeel";
// import WhatsappLookFeel from "./WhatsappLookFeel";
interface Props extends LoaderComponentProps {}
const PersistantMenu: React.FC<Props> = ({ setLoading }) => {
  const formRef: any = useRef();
  const { showAlert } = useNotification();
  const currentBot = useAppSelector(useCurrentBot);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [flows, setFlows] = useState<Array<any> | null>(null);
  const [botConfig, setBotConfig] = useState<any>(null);
  const maxMenuItems = getMaxMenuItemLength(currentBot?.channel);
  const [nodes, setNodes] = useState<any>([]);
  const [userInput, setUserInput] = useState<any>({
    menu: false,
    dynamicInputs: [
      { label: "", action: null, link: "", flow: null, node: null },
    ],
  });

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (!currentBot?.bot_id) {
      SubmitProps.setSubmitting(false);
      return;
    }
    let payload = generatePayloadFromValues(
      values.dynamicInputs,
      currentBot?.channel,
    );
    SubmitProps.setSubmitting(false);
    ChatbotConsoleService.updatePersistentMenu(
      currentBot.bot_id as string,
      {
        persistent_menu: payload,
        values: values,
      },
      currentBot?.channel === "messenger"
        ? "facebook-configuration"
        : "telegram-configuration",
    )
      .then((response) => {
        SubmitProps.setSubmitting(false);
        showAlert("Menu updated successfully", "success");
      })
      .catch((error) => {
        SubmitProps.setSubmitting(false);
        showAlert(error?.response?.message, "error");
      });
  };

  const getFlowsById = async (flowId: string, index: number) => {
    const botId = { botid: currentBot.bot_id };
    try {
      const flowIDRes: AxiosResponse<any> =
        await FlowBuilderService.getFlowById(flowId, {
          appid: JSON.stringify(botId),
        });
      if (flowIDRes?.data?.data) {
        flowIDRes.data?.data.forEach((ele: any) => {
          ele.label = getText(ele.response);
        });
        let tempArray: any = [...nodes]; // create a new array to avoid mutating the state directly
        tempArray[index] = flowIDRes.data.data || nodes[index] || [];

        setNodes(tempArray);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFlows = (index: number) => {
    const botId = { botid: currentBot.bot_id };
    FlowBuilderService.getFlows({}, { appid: JSON.stringify(botId) }).then(
      (res: AxiosResponse<any>) => {
        if (res.data) {
          let tempArray: any = [...res.data.data];
          setFlows(tempArray);
        }
      },
    );
  };
  const fetchNodes = (values: any, index: number) => {
    getFlowsById(values.qid, index);
  };
  const fetchFlows = (value: any, index: number) => {
    getFlows(value.qid);
  };
  const onFlowChange = (value: any, index: number) => {
    if (!nodes[index]) {
      getFlowsById(value.qid, index);
    }
  };

  const onActionChange = (value: any, index: number) => {
    if (value.type === "flow" && !flows?.length) {
      getFlows(index);
    } else {
      removeSelectedFlowAndNode(index);
    }
  };

  const removeSelectedFlowAndNode = (index: number) => {
    formikProps?.setFieldValue(`dynamicInputs[${index}].flow`, null);
    formikProps?.setFieldValue(`dynamicInputs[${index}].node`, null);
  };

  const onSwitchChange = (isMenuEnabled: boolean) => {
    const payload = {
      action: isMenuEnabled ? "enable" : "disable",
      config_key: "persistent_menu",
    };
    ChatbotConsoleService.updatePersistentMenu(
      currentBot.bot_id as string,
      payload,
      currentBot?.channel === "messenger"
        ? `enable-facebook-configuration`
        : "enable-telegram-configuration",
    )
      .then((response) => {
        showAlert("Menu updated successfully", "success");
      })
      .catch((error) => {
        showAlert(error?.response?.message, "error");
      });
  };
  const getPreviousData = () => {
    ChatbotConsoleService.getChatbotLookFeel(currentBot.id as string)
      .then((res: AxiosResponse<any>) => {
        if (res.data) {
          const cconfig: any = res.data.chatbot_configuration;

          // setTempValues({
          //   avatar: cconfig?.avatar?.id,
          //   company_logo: cconfig?.company_logo?.id,
          //   background_image: cconfig?.background_image?.id,
          // });

          if (
            currentBot?.channel === "messenger" &&
            cconfig?.facebook_configuration?.values
          ) {
            setUserInput(cconfig.facebook_configuration.values);
          } else if (cconfig.telegram_configuration) {
            setUserInput(cconfig.telegram_configuration.values);
          }
          setBotConfig(cconfig);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!botConfig) {
      getPreviousData();
    }
  }, [currentBot]);
  return (
    <>
      <StyledLookFeelContainer>
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
          }}
          validationSchema={BotPersistantMenu}
          innerRef={formRef}
          divStyle={{
            minWidth: "100%",
            padding: "0.5rem",
            position: "relative",
          }}
        >
          <AppSmallWindowPane
            style={{
              minWidth: "100%",
            }}
          >
            <AppFormObserver setFormikProps={setFormikProps} />
            <h3>
              Persistant Menu{" "}
              <AppFormSwitchField
                name={"menu"}
                label=""
                className="menuSwitch"
                sendValuesToParent={onSwitchChange}
              />
            </h3>
            <p>Create / Manage Persistant Menu of facebook messenger</p>

            {formikProps?.values?.menu && (
              <div style={{ marginTop: "10px" }}>
                <FieldArray name="dynamicInputs">
                  {(arrayHelpers) => (
                    <div className="fieldWrapper">
                      {formikProps?.values?.dynamicInputs.map(
                        (input: any, index: number) => (
                          <div key={index} className="dynamicInputsWrapper">
                            <AppFormField
                              name={`dynamicInputs[${index}].label`}
                              placeholder="Enter button label"
                              label="Button Label"
                              divStyle={{ width: "200px" }}
                            />
                            <AppReactSelectField
                              options={registerActions.filter(
                                (ele: any) =>
                                  ele.type !== "mobile" &&
                                  ele.type !== "email" &&
                                  ele.type !== "opt_out",
                              )}
                              name={`dynamicInputs[${index}].action`}
                              label="Select Action"
                              displayKey="title"
                              valueKey="type"
                              className="action-select"
                              onChangeCallbackFn={(value: any) =>
                                onActionChange(value, index)
                              }
                            />

                            {formikProps?.values?.dynamicInputs?.[index]?.action
                              ?.type === "link" && (
                              <AppFormField
                                name={`dynamicInputs[${index}].link`}
                                label="Url"
                                divStyle={{ width: "200px" }}
                                placeholder="Enter url"
                              />
                            )}
                            {formikProps?.values?.dynamicInputs?.[index]?.action
                              ?.type === "flow" && (
                              <>
                                <AppReactSelectField
                                  options={flows || []}
                                  name={`dynamicInputs[${index}].flow`}
                                  label="Select Flow"
                                  displayKey="label"
                                  valueKey="qid"
                                  className="action-select"
                                  onChangeCallbackFn={(value: any) =>
                                    onFlowChange(value, index)
                                  }
                                  onMenuOpen={() =>
                                    !flows
                                      ? fetchFlows(
                                          formikProps?.values?.dynamicInputs?.[
                                            index
                                          ]?.flow,
                                          index,
                                        )
                                      : {}
                                  }
                                />
                                {formikProps?.values?.dynamicInputs?.[index]
                                  ?.flow && (
                                  <AppReactSelectField
                                    options={nodes?.[index] || []}
                                    name={`dynamicInputs[${index}].node`}
                                    label="Select Node"
                                    displayKey="name"
                                    valueKey="nodeId"
                                    className="action-select"
                                    onMenuOpen={() =>
                                      !nodes?.[index]
                                        ? fetchNodes(
                                            formikProps?.values
                                              ?.dynamicInputs?.[index]?.flow,
                                            index,
                                          )
                                        : {}
                                    }
                                  />
                                )}
                              </>
                            )}
                            {formikProps?.values?.dynamicInputs?.length > 1 && (
                              <AppButton
                                type="button"
                                variant="text"
                                onClick={() => arrayHelpers.remove(index)}
                                buttonStyle={{
                                  marginTop: "25px",
                                }}
                              >
                                <DeleteIcon />
                              </AppButton>
                            )}
                          </div>
                        ),
                      )}
                      {arrayHelpers.form.values?.dynamicInputs?.length <=
                        maxMenuItems && (
                        <AppButton
                          type="button"
                          onClick={() => {
                            arrayHelpers.push({
                              label: "",
                              action: null,
                              link: "",
                              flow: null,
                              node: null,
                            });
                          }}
                        >
                          Add Another Button
                        </AppButton>
                      )}
                    </div>
                  )}
                </FieldArray>
                <AppSubmitButton
                  variant="cta"
                  style={{ width: "150px", margin: "0.5rem 0 0.5rem auto" }}
                  title={"Save"}
                  disabled={
                    formikProps?.errors?.dynamicInputs ||
                    formikProps?.values?.dynamicInputs?.[0]?.action === ""
                      ? true
                      : false
                  }
                />
              </div>
            )}
          </AppSmallWindowPane>
        </AppForm>
      </StyledLookFeelContainer>
    </>
  );
  //   if (currentBot?.channel === "whatsapp") {
  //     return <WhatsappLookFeel />;
  //   }

  //   return <WebBotLookFeel />;
};

export default withLoader(PersistantMenu);
