import { AxiosResponse } from 'axios';
import { Plan } from '../../../Models';
import { PackageSubscription } from './../../../Models/Subscription';

import { coreAxios } from "../../../Utils";
import { getHttpService } from "../../HttpService";
import { CORE_ENDPOINTS } from "../Endpoints";

export class CoreService {
  static httpService = getHttpService(coreAxios);
  static businessSignup = (data = {}, config = {}) => {
    return CoreService.httpService.post({ url: CORE_ENDPOINTS.AUTH_BUSINESS_SIGNUP, data, headers: config });
  };
  static tokenVerify = (): Promise<AxiosResponse<{ status: boolean, token: any }>> => {
    return CoreService.httpService.get({ url: CORE_ENDPOINTS.AUTH_TOKEN_VERIFY });
  };
  static switchAccount = (data: { 'selected_business': string | number }): Promise<AxiosResponse<{ status: boolean, token: string }>> => {
    return CoreService.httpService.post({ url: CORE_ENDPOINTS.AUTH_SWITCH_ACC, data });
  };
  static getPlans = (): Promise<AxiosResponse<Plan[]>> => {
    return CoreService.httpService.get({ url: CORE_ENDPOINTS.PLANS });
  };
  static getCredits = (): Promise<any> => {
    return CoreService.httpService.get({ url: CORE_ENDPOINTS.CREDITS });
  };
  static startSubscription = (plan_id: string): Promise<AxiosResponse<any>> => {
    return CoreService.httpService.post({ url: CORE_ENDPOINTS.START_SUBSCRIPTION, data: { plan_id: plan_id } });
  };
  static upgradeSubscription = (plan_id: string): Promise<AxiosResponse<any>> => {
    return CoreService.httpService.post({ url: CORE_ENDPOINTS.UPGRADE_SUBSCRIPTION, data: { plan_id: plan_id } });
  };
  static finalizeSubscription = (hostedpage_id: string): Promise<AxiosResponse<{ status: string }>> => {
    return CoreService.httpService.post({ url: CORE_ENDPOINTS.FINALIZE_SUBSCRIPTION, data: { hostedpage_id: hostedpage_id } });
  };
  static getActiveSubscriptions = (): Promise<AxiosResponse<PackageSubscription[]>> => {
    return CoreService.httpService.get({ url: CORE_ENDPOINTS.ACTIVE_SUBSCRIPTIONS });
  };
  static loginUserForSSO = (enc_user_id: string): Promise<AxiosResponse<{ enc_user_id: string }>> => {
    return CoreService.httpService.get({ url: CORE_ENDPOINTS.SSO_MOCK_LOGIN, params: { enc_user_id } });
  };

  static businessAccountVerify = (data: any, headers: any): Promise<AxiosResponse<any>> => {
    return CoreService.httpService.post({ url: CORE_ENDPOINTS.ACCOUNT_VERIFY, data, headers });
  };
  static userLogout = (data: any): Promise<AxiosResponse<any>> => {
    return CoreService.httpService.post({ url: CORE_ENDPOINTS.AUTH_LOGOUT, data });
  };

  //* whatsapp
  static getWhasAppTemplateOptions = (): Promise<AxiosResponse<any>> => {
    return CoreService.httpService.get({ url: CORE_ENDPOINTS.WHATSAPP_TEMPLATE_OPTIONS });
  }
}