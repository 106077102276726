import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { BoxProps } from "@mui/material";

interface EditorContainerProps extends BoxProps {
  error?: boolean;
}

export const EditorContainerStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error",
})<EditorContainerProps>(({ error }) => ({
  background: "#F5F6F8",
  padding: "12px",
  borderRadius: "4px",
  position: "relative",
  ...(error && { border: "1px solid red" }),
}));

export const EditorWrapperStyled = styled(Box)(({ theme }) => ({
  '& .public-DraftStyleDefault-block': {
    fontSize: "14px",
    lineHeight: "21px",
    color: "#7E8392",
    fontWeight: 400,
  },
  cursor: "text",

  "& .public-DraftEditorPlaceholder-root": {
    position: "absolute",
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#7E8392",
    opacity: 0.42,
  },
}));
