const getCarouselSlides = (node: any, language: string) => {
  let carousel = [];
  const lanResponse =
    language !== "english" ? `response_${language.toLowerCase()}` : "response";
  const response = node?.[lanResponse] ? node?.[lanResponse] : node?.response;
  if (response && response !== null && response.length > 0) {
    carousel = response.find((item: any) => item.type === "carousel");
  }
  return carousel?.value && carousel?.value?.length > 0
    ? JSON.parse(JSON.stringify(carousel.value))
    : [];
};

export default getCarouselSlides;