import { FormControlLabel, FormHelperText } from "@mui/material"
import { CustomSwitch, NotificationContainer, NotificationHeader, NotificationWrapper } from "./notificationStyle"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../../../Store";
import { NotificationActions } from "../../../Store/Slices/Notification/notification.actions";
import { useNotification } from "../../../Hooks/useNotification/useNotification";

const NotificationScreen = () => {
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const [taskReminder, setTaskReminder] = useState<any>({})
    useEffect(() => {
        dispatch(NotificationActions.getNotificationConfig({}))
            .then((res: any) => {
                setTaskReminder(res.payload?.data?.data?.filter((action: any) => action?.action_name === "task_reminder")[0])
            })
            .catch((error: any) => {
                console.error(error);
            })
    }, [])
    // Function to toggle reminder active state in options
    const toggleReminderActive = (index: number) => {
        const updatedOptions = taskReminder?.options?.map((reminder: any, i: number) => {
            if (i === index) {
                return { ...reminder, active: !reminder.active };
            }
            return reminder;
        });
        setTaskReminder({
            ...taskReminder,
            options: updatedOptions
        });
        dispatch(NotificationActions.updateNotificationConfig({
            ...taskReminder,
            options: updatedOptions
        }))
        .then((res: any) => {
            if(res?.payload?.data?.status){
                showAlert("Notification Confing Update successfully","success")
            }            
        })
        .catch((error: any) => {
            console.error(error);
        })
    };
    return (
        <NotificationWrapper>
            <NotificationHeader>
                <div className="response-header__left">
                    <h4>Notification preferences</h4>
                </div>
            </NotificationHeader>
            <NotificationContainer>
                <div className="body_title">
                    <h4>Upcoming Activity Reminders</h4>
                </div>
                <div className="body_contain">
                    <div className="body_contain_row">
                        <FormControlLabel
                            control={
                                <CustomSwitch
                                    checked={taskReminder?.at_time_of_activity}
                                    onChange={() => {
                                        setTaskReminder({
                                            ...taskReminder,
                                            "at_time_of_activity": !taskReminder?.at_time_of_activity
                                        })
                                    }}
                                    name="userOnline"
                                    size="small"
                                />
                            }
                            label={""}
                        />
                        <FormHelperText
                            style={{
                                fontSize: "14px",
                                margin: "3px 9px 1px",
                                width: "100%", //added to skip layout shift
                            }}
                        >
                            {"At time of activity"}
                        </FormHelperText>
                    </div>
                    {taskReminder?.options?.map((reminder: any,index:any) => {
                        return (
                            <div className="body_contain_row">

                                <FormControlLabel
                                    control={
                                        <CustomSwitch
                                            checked={reminder?.active}
                                            onChange={() => toggleReminderActive(index)}
                                            name="userOnline"
                                            size="small"
                                        />
                                    }
                                    label={""}
                                />
                                <FormHelperText
                                    style={{
                                        fontSize: "14px",
                                        margin: "3px 9px 1px",
                                        width: "100%", //added to skip layout shift
                                    }}
                                >
                                    {`${reminder?.label} before activity`}
                                </FormHelperText>
                            </div>
                        )
                    })}
                </div>

            </NotificationContainer>

        </NotificationWrapper >
    )
}

export default NotificationScreen