import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AppSmallWindowPane from "../../../../../AppComponents/AppSmallWindowPane";
import {
  AppForm,
  AppFormImagePicker,
  AppFormRadioInput,
  AppFormSwitchField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../../../../Models/ChatbotConfiguration";
import { PopoutMessageSchema } from "../../../../../Models/ValidationSchema";
import { popupPositionOptions } from "../../../../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
import { MEDIA_FILE_UPLOAD_STATUS } from "../../../../../Utils";
import { LoaderComponentProps, withLoader } from "../../../../../AppComponents/Loader";

interface Props extends LoaderComponentProps {
  // children?: React.ReactNode;
}


const PopoutMessage:React.FC<Props> = ({setLoading}) => {
  const [userInput, setUserInput] = useState<any>({
    tooltip_text: "",
    enabled: false,
    position: "Side",
    image: "",
  });

  const { showAlert } = useNotification();
  const navigate = useNavigate();
  let { bot_id } = useParams();
  const currentBot = useSelector(useCurrentBot);
  const [localValues, setlocalValues] = useState<any>(null);

  const getPreviousData = () => {
    ChatbotConsoleService.getBotConfiguration(
      currentBot?.chatbot_configuration as string
    ).then((res: AxiosResponse<ChatbotConfiguration>) => {
      if (res.data?.message_tone) {

        setUserInput({
          enabled: res.data?.tooltip?.enabled
            ? res.data?.tooltip?.enabled
            : false,
          tooltip_text: res.data?.tooltip?.tooltip_text
            ? res.data?.tooltip?.tooltip_text
            : "",
          position: res.data?.tooltip?.position
            ? res.data?.tooltip?.position
            : "Side",
          image: res.data?.tooltip?.image ? res.data?.tooltip?.image : "null",
        });
      }
    }).finally(() => {
      setLoading(false);
     });
  }

  useEffect(() => {
    if (currentBot && currentBot?.chatbot_configuration) {
      getPreviousData();
    }
    else {
      setLoading(false);
    }
  }, [currentBot]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitBtn = (values: any, SubmitProps: any) => {

    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          tooltip: {
            enabled: values.enabled,
            tooltip_text: values.tooltip_text,
            position: values.position,
            image: values?.image,
          },
        },
      }
    )
      .then((response) => {
        SubmitProps.setSubmitting(false);
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        console.error("api error", error);
        SubmitProps.setSubmitting(false);
        showAlert(error.response.statusText, "error");
      });
  };

  // // Set Initial User Values for Formik
  const sendValuesToParent = (values: any): void => {
    setlocalValues(values);
  };

  return (
    <AppSmallWindowPane
      style={{
        minWidth: "55%",
        borderRadius: "8px",
        padding: "1.1rem 1.5rem 0rem 1.5rem",
        position: "relative",
      }}
    >
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
        }}
        validationSchema={PopoutMessageSchema}
        divStyle={{ marginTop: "0.5rem" }}
      >
        <AppFormSwitchField label="Visibility" name="enabled" />
        <AppFormRadioInput
          label="PopUp Position"
          name="position"
          Options={popupPositionOptions}
        />
        <AppFormTextArea
          disableNewLineOnEnter={true}
          label="PopUp Text"
          name="tooltip_text"
          placeholder="Hey, need help? try me"
          sendValuesToParent={(values: any) => sendValuesToParent(values)}
        />
        {localValues?.position === "Top" ? (
          <AppFormImagePicker
            label="Upload Image (optional)"
            name="image"
            tooltip={
              <ul>
                <li>Your Image/gif must be smaller than 5 MB</li>
                <li>A PNG, JPEG ,JPG or GIF works best</li>
              </ul>
            }
            sizeLimit={5000000}
            sizeErrorMsg={`File size exceeds 5MB`}
            formatErrorMsg={MEDIA_FILE_UPLOAD_STATUS.FORMAT}
          />
        ) : null}

        <AppSubmitButton
          variant="cta"
          style={{ width: "100%", margin: "0.5rem 0" }}
          title={"Save Changes"}
        />
      </AppForm>
    </AppSmallWindowPane>
  );
};

export default withLoader(PopoutMessage);
