import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
    bot_id : "",
    agent_id : "",
    DateRange : {}
}


const dashboardAnalyticsSlice = createSlice({
    name : "dashboardAnalyticsSlice",
    initialState : INITIAL_STATE,
    reducers : {
        setDashboardDateRange(state,action){
            state.DateRange = action.payload
            
        },
        resetDateRange(state,action){
            state.DateRange = {}
        }
       
    }
})

export const {setDashboardDateRange, resetDateRange} = dashboardAnalyticsSlice.actions

export default dashboardAnalyticsSlice.reducer