import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { EmailSettingsActions } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.actions";
import { addressLists } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.selectors";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import useModal from '../../../../Hooks/useModel/useModel';
import { ReactComponent as DeleteIcon } from "../../../../assets/images/delete-icon.svg";
import { AddressesTable } from "../Styles";
import { TableWrapper } from "../../../../TableStyle";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";

const FooterLists = () => {
    const dispatch = useAppDispatch();
    const addresses = useAppSelector(addressLists);
    const [selectedEmail, setSelectedEmail] = useState<any>();
    const { isShowing, toggle } = useModal();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        dispatch(EmailSettingsActions.getAddressesList({}));
    }, [])
    const handleDeletetoggle = (item: any) => {
        setSelectedEmail(item)
        toggle();
    };
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleDeleteEmail = () => {
        dispatch(EmailSettingsActions.deleteEmail(selectedEmail));
        toggle(false);
    };


    const header = [
        "First Name",
        "Last Name",
        "Company Name",
        "Company Address",
        "City",
        "Zip code",
        "State",
        "Country code",
        "Phone no.",
        "Company website",
        "Actions"
      ];
    
      const keyToHeaderMap: any = {
        "First Name": "firstName",
        "Last Name": "lastName",
        "Company Name": "companyName",
        "Company Address": "address1",
        "City": "city",
        "Zip code": "zip",
        "State": "state",
        "Country code": "countryID",
        "Phone no.": "phone",
        "Company website": "website",
      };
    
      const colWidth: any = {
        "First Name": "220",
        "Last Name": "150",
        "Company Name": "150",
        "Company Address": "420",
        "City": "120",
        "Zip code": "150",
        "State": "200",
        "Country code": "150",
        "Phone no.": "150",
        "Company website": "200",
        "Actions": "200"
      };


      const formatDateField = (date: any) => {

      }


      const renderColumns = (row: any, header: any,) => {
        if(header === "Actions") {
            return <IconButton onClick={() => handleDeletetoggle(row)}>
                    <AppMaterialIcons iconName="DeleteOutlineOutlined" />
                </IconButton>
        }else {
            return <Typography
                className="table-text"
                sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                }}
            >
              {row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"}
            </Typography>
        }
      }


    return (<>
        <TableWrapper height={"calc(100vh - 256px)"}>
            <div>
            <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* Table Body */}
              </Box>

              <Box className="table-body">
                {addresses?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 307px)" />
                ) : (
                  <>
                    {addresses?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>

            </div>
        </TableWrapper>
        
    
    </>)

}

export default FooterLists;
