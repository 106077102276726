import { useState } from "react";

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);

  function toggle(val?: boolean) {
    if (val) {
      setIsShowing(val);
    } else {
      setIsShowing(!isShowing);
    }
  }

  return {
    isShowing,
    toggle,
  };
};

export default useModal;
