// import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  ErrorMessage,
  Field,
  FieldArray,
  getIn,
  useFormikContext,
} from "formik";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import {
  AppErrorMessage,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { theme } from "../../../../../Customization/Theme";
import {
  getFaqVaritionProgressValue,
  getNestedPropertyByString,
} from "../../../../../Utils";
import uniqid from "uniqid";
import AppNoDataLoader from "../../../../../AppComponents/AppNoDataLoader";

interface Props {
  label?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  dynamicVaritionValue?: any;
  setDynamicVaritionValue?: any;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const BlacklistUrlFieldArray: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  setProgress,
  renderAddBtn,
  dynamicVaritionValue,
  setDynamicVaritionValue,
  sendValuesToParent,
  children,
  ...otherProps
}) => {
  const {
    errors,
    touched,
    values,
  } = useFormikContext<any>();

  const buttonRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const TriggerClickEvent = () => {
    buttonRef?.current?.click();
  };

  // useEffect(() => {
  //   if (dynamicVaritionValue && dynamicVaritionValue?.length > 0) {
  //     TriggerClickEvent();
  //   }
  // }, [dynamicVaritionValue]);

  useEffect(() => {
  }, [getIn(values, name)]);

  return (
    <ComponentWrap>
      <ComponentTopbar>
        <p>Sr.</p>
        <p>Urls</p>
        <p>Action</p>
      </ComponentTopbar>
      <FieldArray name={name}>
        {({ insert, remove, push, swap, unshift, pop, replace }) => (
          <ComponentMainContent>
            {values &&
              name &&
              values[name] &&
              values[name].length > 0 &&
              values[name].map((item: any, index: number) => {
                const fieldName: string = `${name}[${index}]`;
                return (
                  <div
                    key={index}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <StyledSingleRow key={index}>
                      <p>{index + 1}</p>
                      <Input
                        name={fieldName}
                        placeholder={`Enter url ${index + 1}`}
                        style={{
                          border: getNestedPropertyByString(
                            errors,
                            name,
                            index
                          )
                            ? `2px solid ${theme.palette.default.error}`
                            : `2px solid transparent`,
                        }}
                      />

                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </StyledSingleRow>

                    <AppErrorMessage
                      style={{ marginTop: "-4px", marginLeft: "2.3rem" }}
                      error={getNestedPropertyByString(errors, name, index)}
                      visible={getNestedPropertyByString(
                        touched,
                        name,
                        index
                      )}
                    />
                  </div>
                );
              })}

            <AppButton
              innerRef={buttonRef}
              variant="text"
              onClick={() => {
                push(dynamicVaritionValue);
                // setDynamicVaritionValue("");
              }}
              style={{
                marginTop: "0.5rem",
                minWidth: "80px",
                width: "12%",
                display: "flex",
              }}
              startIcon={"ri-add-line"}
            >
              Blacklist Url
            </AppButton>
          </ComponentMainContent>
        )}
      </FieldArray>
    </ComponentWrap>
  );
};

export default BlacklistUrlFieldArray;

interface StyledProps {
  isError?: any;
}

const ComponentWrap = styled.div<StyledProps>`
  && {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    width: 100%;
    padding: 0rem 2rem;
    max-width: 1000px;
    min-width: 450px;
    gap: 4px;
    position: relative;
    .MuiFormControl-root {
      width: 100% !important;
    }
  }
`;
const ComponentTopbar = styled.div<StyledProps>`
  && {
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: space-between;
  }
`;
const ComponentMainContent = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
const StyledSingleRow = styled.div<StyledProps>`
  && {
    display: flex;
    width: 100%;
    height: 64px;
    gap: 2rem;
    align-items: center;
    p {
      color: #7e8392;
    }
  }
`;

const Input = styled(Field) <StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
`;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#282828",
    fontWeight: "400",
    fontSize: "1rem",
    textTransform: "capitalize",
    lineHeight: "8px",
    // borderBottom: "2px solid #F5F6F8",
    borderBottom: "none !important",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#7E8392",
    fontWeight: "400",
    fontSize: "0.9rem",
    // borderBottom: "1px solid #F5F6F8",
    borderBottom: "none !important",
    lineHeight: "16px",
    height: 60,
    maxHeight: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    padding: "2px 16px",
    // backgroundColor: "red",
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: "yellow",
    borderBottom: "none !important",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    borderBottom: "none !important",
  },
  "&:hover": {
    // backgroundColor: "#F5F6F8",
  },
}));
