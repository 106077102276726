import { Box } from "@mui/material";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import { RecipientsWrapper, WrapRecipients } from "./EmailBroadcastSummaryStyles";

interface Props {
  recipients?: any;
  totalCost?: any;
  loading?:any;
}

const Recipients: React.FC<Props> = ({ recipients, totalCost,loading }) => {

  return (
    loading ? <AppSkeletonLoader/> :
    <Box height={"100%"}>
      
<WrapRecipients>
    <RecipientsWrapper>
      
    <Box height={"inherit"}>
        <Box display = { "flex" } alignItems = { "center" }>
          <p className = "main_header">Recipients</p >
          <AppToolTip
            tooltipProps={{
              title: "Total individuals for Email message delivery.",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={
              <AppMaterialIcons
                iconName="HelpOutline"
                style={{ color: "#fff", fontSize: "16px" }}
              />
            }
          />
        </Box >

        {
          (recipients ?? 0) === 0 &&
          (totalCost ?? 0) === 0 ? (

              <NoRecordsFound
                imageSrc={NoRecordImage}
                imageWidth={"200px"}
                headerTextSize={"19px"}
                bgColor="transparent"
                headerTextColor="#fff"
                subTextColor="#fff"
                height="calc(100% - 13px)"
              />

          ) : (
              <div className="info_values">
                <p className="header">Total Recipients</p>
                <p className="value">{recipients || 0}</p>
                <Box className="credits_btn">
                  <p>Cost: Rs. {totalCost || 0} </p>
                </Box>
              </div>
          )

        }

        
 </Box>
 
 
</RecipientsWrapper >
</WrapRecipients >
        
    </Box>
  );
};

export default Recipients;
