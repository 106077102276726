import { ReactComponent as WhatsApp } from "../../../assets/images/icons/whatsApp.svg";
import { ReactComponent as SMS } from "../../../assets/images/icons/sms.svg";
import { ReactComponent as Email } from "../../../assets/images/icons/email_broadcast.svg";


export const getBroadcastMenuLogo = () => {
    let type:string=getBroadcastTypeUsingLocation();
    switch (type) {
        case "whatsapp":
            return WhatsApp;
        case "sms":
            return SMS;
        case "email":
            return Email;
        default:
            return null;
    }
}

export const getBroadcastMenuOptions = () => {
    let type:string=getBroadcastTypeUsingLocation();
    switch (type) {
        case "whatsapp":
            return whatsappSideBarMenu;
        case "sms":
            return smsSideBarMenu;
        case "email":
            return emailSideBarMenu;
        default:
            return [];
    }
}
export const getBroadcastMenuKeyName= () => {
    let type:string=getBroadcastTypeUsingLocation();
    switch (type) {
        case "whatsapp":
            return "whatsapp_broadcast_sidebar_menu";
        case "sms":
            return "sms_broadcast_sidebar_menu";
        case "email":
            return "email_broadcast_sidebar_menu";
        default:
            return "";
    }
}

export const getBroadcastTypeUsingLocation = () => {
    let pathname = window.location.pathname;
    if (pathname.includes("sms")) return "sms";
    if (pathname.includes("whatsapp")) return "whatsapp";
    if (pathname.includes("email")) return "email";
    return "";
  };

export const whatsappSideBarMenu = [
    
    {
        label: "Broadcast History",
        value: "history",
        url: "/broadcast/whatsapp/history",
        moduleId: "whatsapp_broadcast_crud",
        parentModuleId: "whatsapp_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Scheduled Broadcast",
        value: "scheduled",
        url: "/broadcast/whatsapp/scheduled",
        moduleId: "whatsapp_broadcast_crud",
        parentModuleId: "whatsapp_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Broadcast Templates",
        value: "template",
        url: "/broadcast/whatsapp/templates",
        moduleId: "whatsapp_template",
        parentModuleId: "whatsapp_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
];

export const smsSideBarMenu = [
    {
        label: "Insights",
        value: "dashboard",
        url: "/broadcast/sms/dashboard/summary",
        moduleId: "sms_insights",
        parentModuleId: "sms_broadcast",
        isDisabled: false,
        isAvailable: false,
    },

    {
        label: "Broadcast History",
        value:"history",
        url: "/broadcast/sms/history",
        moduleId: "sms_broadcast_crud",
        parentModuleId: "sms_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Scheduled Broadcast",
        value:"scheduled",
        url: "/broadcast/sms/scheduled",
        moduleId: "sms_broadcast_crud",
        parentModuleId: "sms_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "SMS Templates",
        value:"template",
        url: "/broadcast/sms/template",
        moduleId: "sms_template",
        parentModuleId: "sms_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Sender ID",
        value:"sender_id",
        url: "/broadcast/sms/sender_id",
        moduleId: "sender_ids",
        parentModuleId: "sms_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Reports",
        value:"reports",
        url: "/broadcast/sms/reports",
        moduleId: "sms_reports",
        parentModuleId: "sms_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
];

export const emailSideBarMenu = [
    {
        label: "Insights",
        url: "/broadcast/email/dashboard/summary",
        value: "dashboard",
        isDisabled: false,
        isAvailable: false,
        moduleId: "email_insights",
        parentModuleId: "email_broadcast",
    },
    {
        label: "Broadcast History",
        value: "history",
        url: "/broadcast/email/history",
        moduleId: "email_broadcast_crud",
        parentModuleId: "email_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Scheduled Broadcast",
        value: "scheduled",
        url: "/broadcast/email/scheduled",
        moduleId: "email_broadcast_crud",
        parentModuleId: "email_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Broadcast Templates",
        value: "template",
        url: "/broadcast/email/templates",
        moduleId: "email_template",
        parentModuleId: "email_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
    {
        label: "Reports",
        value: "reports",
        url: "/broadcast/email/reports",
        moduleId: "email_reports",
        parentModuleId: "email_broadcast",
        isDisabled: false,
        isAvailable: false,
    },
]