import React, { memo } from "react";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import { StyledMessageText } from "./../../Styles/StyledComponents";

interface Props {
  userMessage: boolean;
  children: any;
  time: string;
  msgId: string;
  seen: string;
  sent: string;
  delivered: string;

  [otherProps: string]: any;
}
const AppRenderText: React.FC<Props> = React.memo(
  ({
    userMessage = false,
    children,
    time,
    readStatus = "delivered",
    msgId,

    seen,
    sent,
    delivered,

    ...otherProps
  }) => {
    if (!children || children?.length < 1) {
      return null;
    }

    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        time={time}
        isMedia={false}
        msgId={msgId}
        seen={seen}
        sent={sent}
        delivered={delivered}
      >
          <StyledMessageText
            userMessage={userMessage}
            dangerouslySetInnerHTML={{
              __html: children,
            }}
            className="getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
            {...otherProps}
          />
      </AppMessageBoxWrapper>
    );
  }
);

export default memo(AppRenderText);
