import { debounce } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AppButton from "../../../../../AppComponents/AppButton";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import useModal from "../../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../../Store";
import { FaqSourceAction } from "../../../../../Store/Slices/Faq/Source/source.actions";
import {
  SourceList,
  SourceListCount,
  SourceTexByID,
} from "../../../../../Store/Slices/Faq/Source/source.selectors";
import { ReactComponent as SearchIcon } from "../../../../../assets/images/Searchicon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete.svg";
import {
  AppPagination,
  FaqTextWrap,
  StyledAppInput,
} from "../../BotKnowledgeStyles";
import { getSourceListing } from "../SourceUtils";
import DeleteConfirmationModal from "../commonComponents/DeleteConfirmationModal";
import DocumentAndTextTable from "../commonComponents/DocumentAndTextTable";
import SourceTextModal from "../commonComponents/SourceTextModal";

interface Props extends LoaderComponentProps {}

const FaqText: React.FC<Props> = ({ setLoading }) => {
  // Setting the source type for api
  const sourceType = "text";
  const [mode, setMode] = useState<string>("");
  // Select And Unselct State
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // Bot ID
  let { bot_id } = useParams();
  // Header For PayLoad
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };

  // Modals
  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();

  const AddEditModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingAddEditModal: isShowing, toggleAddEditModal: toggle };
  };
  const { isShowingAddEditModal, toggleAddEditModal } = AddEditModal();

  // Listing Of Data For Table
  const SourceListing = useSelector(SourceList);
  // Count Of Listing
  const SourceListingCount = useSelector(SourceListCount)?.totalRecords;
  const SourceTextDataByID = useSelector(SourceTexByID);

  // Setting Values Locally
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
  });

  // Initial Form Values
  const [userInput, setUserInput] = useState<any>({
    title: "",
    text: "",
  });

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };

  // Function To Add Data Source Type Text
  const handleSubmit = (values: any, SubmitProps: any) => {
    const payload = {
      title: values.title,
      text: values.text,
    };
    // Data Paylod For API
    const data = {
      payload,
      HEADERS,
    };
    dispatch(FaqSourceAction.CreateSourceText(data))
      .then((res: any) => {
        const responseStatus = res?.payload?.status;
        if (responseStatus === 200) {
          SubmitProps?.resetForm();
          toggleAddEditModal();
          getSourceListing(
            dispatch,
            HEADERS,
            sourceType,
            localValues,
            setLoading
          );
          showAlert("Source Text Is Added", "success");
        } else {
          showAlert(res?.payload?.data?.error, "error");
          SubmitProps.setSubmitting(false);
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
  };

  // PopOver For Table Row - Actions
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    documentId: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocumentId(documentId);
  };
  // Popover actions onclcik Close
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Single Document ID
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );
  // Multiple Document Ids
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);

  // Function To Get Data By ID
  const getDocumentByID = () => {
    if (selectedDocumentId) {
      const data = {
        documentid: selectedDocumentId,
        HEADERS,
      };
      dispatch(FaqSourceAction.GetSourceTextById(data));
    }
  };

  // Function To Update Document Source Text
  const handleUpdate = (values: any, SubmitProps: any) => {
    if (selectedDocumentId) {
      const payload = {
        documentId: selectedDocumentId,
        title: values?.title,
        text: values?.text,
      };

      // Data Paylod For API
      const data = {
        payload,
        HEADERS,
      };

      dispatch(FaqSourceAction.UpdateSourceTextById(data))
        .then((res) => {
          const responseStatus = res?.payload?.status;
          if (responseStatus === 200) {
            SubmitProps?.resetForm();
            toggleAddEditModal();
            getSourceListing(
              dispatch,
              HEADERS,
              sourceType,
              localValues,
              setLoading
            );
            showAlert("Source Text Is Updated", "success");
          } else {
            showAlert("Error", "error");
          }
        })
        .catch((error: any) => {
          showAlert(error, "error");
        });
    }
  };

  // Function To Delete
  const deletSourceByDocumentId = () => {
    let documentIds;
    if (selectedDocumentId) {
      documentIds = [selectedDocumentId];
    } else {
      documentIds = selectedDocumentIds;
    }
    const data = {
      HEADERS,
      documentIds,
    };

    dispatch(FaqSourceAction.DeleteSourceByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          getSourceListing(
            dispatch,
            HEADERS,
            sourceType,
            localValues,
            setLoading
          );
          showAlert("Source Text Is Deleted", "success");
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
    setSelectedDocumentIds([]);
    setSelectAll(false);
    toggleConfirmModal();
  };

  // Use Effect - List
  useEffect(() => {
    getSourceListing(dispatch, HEADERS, sourceType, localValues, setLoading);
  }, [localValues]);

  // Use Efeect For Edit
  useEffect(() => {
    if (SourceTextDataByID) {
      setUserInput({
        title: SourceTextDataByID?.title,
        text: SourceTextDataByID?.text,
      });
    }
  }, [SourceTextDataByID]);

  return (
    <div>
      <FaqTextWrap width="600px">
        <div className="top-header">
          <div className="header-left">
            <p>
              Empower your bot with any text you desire! Simply paste it into
              the text box and witness the transformation. Our AI engine will
              convert your input into engaging dialogues, ready to interact and
              respond to user queries. Give it a try now!
            </p>
          </div>
          <div className="serach-class">
            {selectedDocumentIds.length ? (
              <span className="delete-button">
                <AppButton
                  onClick={() => toggleConfirmModal()}
                  variant="danger-filled"
                >
                  Delete <DeleteIcon />
                </AppButton>
              </span>
            ) : null}
            <div className="faq-search">
              <StyledAppInput>
                <input
                  type="text"
                  onChange={(e: any) => handleSelectSearch(e)}
                  name="query"
                  id="query"
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
            <div className="button">
              <AppButton
                onClick={() => {
                  setUserInput({
                    title: "",
                    text: "",
                  });
                  toggleAddEditModal();
                  setMode("add");
                }}
              >
                Paste text
              </AppButton>
            </div>
          </div>
        </div>

        {/* Table */}
        <DocumentAndTextTable
          SourceListing={SourceListing}
          sourceType="text"
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          getDocumentByID={getDocumentByID}
          toggle={toggleAddEditModal}
          setMode={setMode}
          setSelectedDocumentIds={setSelectedDocumentIds}
          selectedDocumentIds={selectedDocumentIds}
          toggleConfirmModal={toggleConfirmModal}
        />
      </FaqTextWrap>

      {/* Add And Edit Modal */}
      <SourceTextModal
        isShowingAddEditModal={isShowingAddEditModal}
        toggleAddEditModal={toggleAddEditModal}
        mode={mode}
        userInput={userInput}
        handleUpdate={handleUpdate}
        handleSubmit={handleSubmit}
      />

      {/* Confirm Delete Modal */}
      <DeleteConfirmationModal
        toggleConfirmModal={toggleConfirmModal}
        deletSourceByDocumentId={deletSourceByDocumentId}
        selectedDocumentIds={selectedDocumentIds}
        isShowingConfirmModal={isShowingConfirmModal}
        type="Text"
      />

      {/* Pagination */}
      {SourceListing?.length ? (
        <AppPagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={localValues?.currentPage}
          count={SourceListingCount.totalRecords}
          rowsPerPage={localValues?.itemsPerPage}
        />
      ) : null}
    </div>
  );
};

export default withLoader(FaqText);
