import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { device } from "../../../../Customization/BreakPoints";
// Css main Layout
export const WrapperContainer = styled.div`
  width: 100%;
  /* height: calc(100% - 64px); */
  height: 100%;
`;
// Css for topbar
export const TopBar = styled.div`
  box-shadow: ${theme.palette.default.shadow};
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
// Css for topbar leftCol
export const LeftCol = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  color: ${theme.palette.default.darkGrey};
  flex-wrap: wrap;
  .limit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    strong {
      color: ${theme.palette.default.text};
    }
  }
  span {
    color: ${theme.palette.default.text};
    margin-left: 5px;
  }
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-left: 5px;
  }
  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
  }
`;
// Css for topbar rightCol
export const RightCol = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  gap: 1.4rem;
  .contact {
    display: flex;
    flex-direction: column;
  }
  .conversations {
    display: flex;
    align-items: center;
    /* gap: 0.6rem; */
  }
  .credits {
    display: flex;
    align-items: center;
  }

  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
    gap: 0.8rem;
    .buy-credits__btn {
      button {
        width: 93px;
      }
    }
  }
`;
// Css for topbar col
export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  /* margin-left: 32px; */
  flex-direction: column;
  color: ${theme.palette.default.darkGrey};
  label {
  }
  &:first-child {
    strong {
      margin-left: 0;
    }
  }
  strong {
    color: ${theme.palette.default.text};
    font-weight: 500;
    font-size: 1rem;
    margin-left: 4px;
  }
`;
// Css for Analytics List Wrapper
export const AnalyticsWrapper = styled.div`
  padding: 10px 16px 6px;
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      color: ${theme.palette.default.black};
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      font-weight: 500;
    }
  }
  .analyticsDataset {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .col {
      border: 1px solid ${theme.palette.default.lightPrimaryBorder};
      background-color: ${theme.palette.default.lightPrimaryBackground};

      height: 154px;
      padding: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-radius: 8px;
      margin-top: 17px;

      .iconWrapper {
        background-color: ${theme.palette.default.white};
        width: 40px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        svg {
          color: ${theme.palette.primary.main};
        }
      }
      h6 {
        display: flex;
        align-items: center;
        color: ${theme.palette.default.black};
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        .customTooltip {
          margin-left: 5px;
          max-width: 13px;
          max-height: 13px;
        }
      }
      strong {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
`;
export const BroadcastListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  padding: 36px 24px 14px;
`;

export const BroadcastDetailListWrapper = styled.div`
  // background-color: ${theme.palette.default.lightGrey};
  padding: 0px 24px 14px;
`;
