import React from "react";
import { SettingPageMainBoxTitle } from "../../../../../Customization/CommonElements";
import { Box, Typography } from "@mui/material";
interface Props {
  formStep: number;
}
const RenderFormStep6: React.FC<Props> = ({ formStep }) => {
  return (
    <Box mb={3} className="mainDiv">
      <Typography fontSize={"20px"} mb={4} fontWeight={500}>Step 6</Typography>
      <Box pl={6}>
        <Typography fontSize={"26px"} fontWeight={500}>
          Great!
        </Typography>
        <Typography className="text-grey">
          Your domain is now verified
        </Typography>
        <Typography className="text-grey">
          and the default sender is set.
        </Typography>
      </Box>
    </Box>
  );
};

export default RenderFormStep6;
