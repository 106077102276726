import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const MessageWrapper = styled.div`
  & {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
    padding: 0 24px;
    height: auto;
    height: calc(100% - 45px);

    .msg_info {
      width: 300px;
      font-family: Inter;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .sub_header_div {
      display: flex;
      flex-direction: row;
      align-items: center;
      .time_stats {
        display: flex;
        font-weight: 500;
        align-items: center;
        padding: 10px 24px 10px 10px;
        border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
        .div_tooltip {
          font-size: 15px;
          margin-top: 0.094rem;
        }
      }
    }

    // .msg_info_header {
    //   display: flex;
    //   flex-direction: row;
    //   align-items: center;
    //   font-size: 16px;
    //   line-height: 2.8;
    //   font-family: Inter;
    //   font-weight: 500;
    //   color: #282828;
    //   .div_tooltip {
    //     font-size: 16px;
    //   }
    // }

    .msg_info_subheader {
      font-size: 15px;
      line-height: 2.8;
      font-family: Inter;
      font-weight: 500;
    }

    .msg_info_div {
      display: flex;
      align-items: center;
      overflow: auto;
      font-family: Inter;
    }

    .msg_info_all {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
      align-items: stretch;
      padding-top: 10px;
    }

    .msg_info_dot {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 3px;
      margin-top: 0px;
      font-family: Inter;
    }

    .msg_info_name {
      display: flex;
      justify-content: space-between;
      border: 0px solid red;
      width: 100%;
      gap: 10px;
      font-family: Inter;
    }

    .msg_info_name_p {
      position: relative;
      font-size: 14px;
      line-height: 1.8;
      font-family: Inter;
      font-weight: 400;
    }

    .msg_info_name_s {
      position: relative;
      font-size: 14px;
      line-height: 1.8;
      font-family: Inter;
      font-weight: 400;
    }

    .donout_div {
      border: 0px solid red;
      font-family: Inter;
      display: flex;
      align-items: center;
      height: 100%;
    }
    .apexcharts-legend {
      top: 50% !important;
      transform: translateY(-20%) !important;
    }
  }
`;
