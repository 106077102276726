import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SubscriptionActions } from "./subscriptions.actions";
interface initialStateTypes {
  getAllPlans: any;
  getActiveSubscriptionPlanDetails: any;
  getAddonList: any;
  addonList: any;
  selectPlanData: any;
  getAllInvoiceList: any;
  error: string;
  loading: boolean;
  getInvoiceCount: any;
  getCountryList: any;
  getCreditListById: any;
  getWallectInvoiceList: any;
  getWallectInvoiceCount: any;
  getPendingInvoiceStatus: any;
  getCreditsUtilization: any;
  invoicePaid: any;
  whatsappCreditLogsData: any;

  creditSummaryLogsData: any[];
  creditSummaryLogsDataLoading: boolean;
  creditSummaryLogsDataError: boolean;
  creditSummaryLogsDataPagination: any;

  creditSummaryData: any[];
  creditSummaryDataLoading: boolean;
  creditSummaryDataError: boolean;
}

const initialState: initialStateTypes = {
  getAllPlans: [],
  getActiveSubscriptionPlanDetails: [],
  getAddonList: [],
  addonList: [],
  getAllInvoiceList: [],
  selectPlanData: {},
  error: "",
  loading: false,
  getInvoiceCount: null,
  getCountryList: [],
  getCreditListById: {
    results: [],
  },
  getWallectInvoiceList: [],
  getWallectInvoiceCount: null,
  getPendingInvoiceStatus: false,
  getCreditsUtilization: {},
  invoicePaid: false,
  whatsappCreditLogsData: null,

  creditSummaryLogsData: [],
  creditSummaryLogsDataLoading: false,
  creditSummaryLogsDataError: false,
  creditSummaryLogsDataPagination: {},

  creditSummaryData: [],
  creditSummaryDataLoading: false,
  creditSummaryDataError: false,
};
const SubscriptionSlice = createSlice({
  name: "SubscriptionSlice",
  initialState,
  reducers: {
    setUpdateAddonData: (state: any, action: PayloadAction<any>) => {
      state.addonList = action?.payload;
      return state;
    },
    setPlanData: (state: any, action: PayloadAction<any>) => {
      state.selectPlanData = action?.payload;
      return state;
    },
    resetAllPlans: (state: any, action: PayloadAction<any>) => {
      state.getAllPlans = [];
      return state;
    },
    resetInvoicePlanList: (state: any, action: PayloadAction<any>) => {
      state.getAllInvoiceList = [];
      return state;
    },
    resetPendingInvoiceStatus: (state: any, action: PayloadAction<any>) => {
      state.getPendingInvoiceStatus = false;
      return state;
    },
    resetInvoicePaidStatus: (state: any, action: PayloadAction<any>) => {
      state.invoicePaid = false;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      //  get All Subscription Plan
      .addCase(SubscriptionActions.getAllPlans.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(SubscriptionActions.getAllPlans.fulfilled, (state, action) => {
        state.getAllPlans = action.payload.data.sort();
      })
      .addCase(SubscriptionActions.getAllPlans.rejected, (state, action) => {
        state.getAllPlans = [];
      })

      //  get active Subscription Plan data
      .addCase(
        SubscriptionActions.getSubscriptionData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getSubscriptionData.fulfilled,
        (state, action) => {
          state.getActiveSubscriptionPlanDetails = action.payload.data;
        },
      )
      .addCase(
        SubscriptionActions.getSubscriptionData.rejected,
        (state, action) => {
          state.getActiveSubscriptionPlanDetails = [];
        },
      )

      //  get Addon List data
      .addCase(
        SubscriptionActions.getAddonListData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getAddonListData.fulfilled,
        (state, action) => {
          state.getAddonList = action.payload.data;
        },
      )
      .addCase(
        SubscriptionActions.getAddonListData.rejected,
        (state, action) => {
          state.getAddonList = [];
        },
      )

      //  get Invoice List
      .addCase(
        SubscriptionActions.getAllInvoiceList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getAllInvoiceList.fulfilled,
        (state, action) => {
          state.getAllInvoiceList = action.payload.data;
        },
      )
      .addCase(
        SubscriptionActions.getAllInvoiceList.rejected,
        (state, action) => {
          state.getAllInvoiceList = [];
        },
      )

      //  get Invoice List
      .addCase(SubscriptionActions.getInvoiceCount.pending, (state, action) => {
        state.error = "";
        state.loading = true;
        state.getInvoiceCount = null;
      })
      .addCase(
        SubscriptionActions.getInvoiceCount.fulfilled,
        (state, action) => {
          state.getInvoiceCount = action.payload.data.total_count;
        },
      )
      .addCase(
        SubscriptionActions.getInvoiceCount.rejected,
        (state, action) => {
          state.getInvoiceCount = null;
        },
      )

      //  get Country List
      .addCase(
        SubscriptionActions.getAllCountryList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getAllCountryList.fulfilled,
        (state, action) => {
          state.getCountryList = action.payload.data;
        },
      )
      .addCase(
        SubscriptionActions.getAllCountryList.rejected,
        (state, action) => {
          state.getCountryList = [];
        },
      )

      //  get Credit By List
      .addCase(
        SubscriptionActions.getCreditListById.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getCreditListById.fulfilled,
        (state, action) => {
          state.getCreditListById = action.payload.data;
        },
      )
      .addCase(
        SubscriptionActions.getCreditListById.rejected,
        (state, action) => {
          state.getCreditListById = {
            results: [],
          };
        },
      )

      //  get Wallet Invoice List
      .addCase(
        SubscriptionActions.getAllWalletInvoiceList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getAllWalletInvoiceList.fulfilled,
        (state, action) => {
          state.getWallectInvoiceList = action.payload.data;
          // state.getWallectInvoiceCount = action.payload.count
        },
      )
      .addCase(
        SubscriptionActions.getAllWalletInvoiceList.rejected,
        (state, action) => {
          state.getWallectInvoiceList = [];
          // state.getWallectInvoiceCount = 0
        },
      )

      //  get Wallet Invoice Count List
      .addCase(
        SubscriptionActions.getWalletInvoiceCount.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
          state.getWallectInvoiceCount = null;
        },
      )
      .addCase(
        SubscriptionActions.getWalletInvoiceCount.fulfilled,
        (state, action) => {
          state.getWallectInvoiceCount = action.payload.data.total_count;
        },
      )
      .addCase(
        SubscriptionActions.getWalletInvoiceCount.rejected,
        (state, action) => {
          state.getWallectInvoiceCount = null;
        },
      )

      //  get Wallet Invoice Count List
      .addCase(
        SubscriptionActions.updateSubscriptionCallbackData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.updateSubscriptionCallbackData.fulfilled,
        (state, action) => {
          state.getPendingInvoiceStatus = action.payload.data.pending_invoice;
          if (!action.payload.data.pending_invoice) {
            state.invoicePaid = true;
          }
        },
      )
      .addCase(
        SubscriptionActions.updateSubscriptionCallbackData.rejected,
        (state, action) => {
          state.getPendingInvoiceStatus = 0;
        },
      )

      //  get credit untilization
      .addCase(
        SubscriptionActions.getCreditUtilization.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getCreditUtilization.fulfilled,
        (state, action) => {
          state.getCreditsUtilization = action.payload.data;
        },
      )
      .addCase(
        SubscriptionActions.getCreditUtilization.rejected,
        (state, action) => {
          state.getCreditsUtilization = 0;
        },
      )
      //  get whatsapp credits logs
      .addCase(
        SubscriptionActions.getWhatsappCreditLogs.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        SubscriptionActions.getWhatsappCreditLogs.fulfilled,
        (state, action) => {
          state.whatsappCreditLogsData = action.payload.data;
        },
      )
      .addCase(
        SubscriptionActions.getWhatsappCreditLogs.rejected,
        (state, action) => {
          state.whatsappCreditLogsData = null;
        },
      )
      
      
      
      // Credit Summary Logs
      .addCase(
        SubscriptionActions.getCreditSummaryLogsAction.pending,
        (state, action) => {
          state.creditSummaryLogsDataLoading = true;
          state.creditSummaryLogsDataError = false;
        },
      )
      .addCase(
        SubscriptionActions.getCreditSummaryLogsAction.fulfilled,
        (state, action) => {
          state.creditSummaryLogsData = action.payload?.data?.data;
          state.creditSummaryLogsDataPagination = action.payload?.data?.pagination;
          state.creditSummaryLogsDataLoading = false;
          state.creditSummaryLogsDataError = false;
        },
      )
      .addCase(
        SubscriptionActions.getCreditSummaryLogsAction.rejected,
        (state, action) => {
          state.creditSummaryLogsDataLoading = false;
          state.creditSummaryLogsDataError = true;
        },
      )
      
      // Credit Summary
      .addCase(
        SubscriptionActions.getCreditSummaryAction.pending,
        (state, action) => {
          state.creditSummaryDataLoading = true;
          state.creditSummaryDataError = false;
        },
      )
      .addCase(
        SubscriptionActions.getCreditSummaryAction.fulfilled,
        (state, action) => {
          state.creditSummaryData = action.payload?.data?.data || [];
          state.creditSummaryDataLoading = false;
          state.creditSummaryDataError = false;
        },
      )
      .addCase(
        SubscriptionActions.getCreditSummaryAction.rejected,
        (state, action) => {
          state.creditSummaryDataLoading = false;
          state.creditSummaryDataError = true;
        },
      );
  },
});
export const {
  setUpdateAddonData,
  setPlanData,
  resetAllPlans,
  resetInvoicePlanList,
  resetPendingInvoiceStatus,
  resetInvoicePaidStatus,
} = SubscriptionSlice.actions;
export default SubscriptionSlice.reducer;
