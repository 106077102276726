import { Button, Menu, MenuItem, Popover, Tooltip, Typography } from "@mui/material";
import styled from "styled-components";
import { useState } from "react";
import { ReactComponent as TextIcon } from '../Assets/TextIcon.svg';
import { ReactComponent as More } from '../Assets/More.svg';
import { ReactComponent as Media } from '../Assets/Media.svg';
import { ReactComponent as TextAnswer } from '../Assets/TextAnswer.svg';
import { ReactComponent as Section } from '../Assets/Section.svg';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
import NotesIcon from '@mui/icons-material/Notes';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { MenuProps } from "react-select";


const AppButton = (props: any) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [subMenuContent, setSubMenuContent] = useState<string>("");
    const open = Boolean(anchorEl);
    const subMenuOpen = Boolean(subMenuAnchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSubMenuAnchorEl(null);
    };

    const handleMenuItemHover = (event: React.MouseEvent<HTMLElement>, content: string) => {
        setSubMenuAnchorEl(event.currentTarget);
        setSubMenuContent(content);
    };

    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
    };

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            open={false}
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }: any) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            minWidth: 160,
            left: "1118px !important",
            color:
                theme?.palette?.mode === 'light' ? 'rgb(55, 65, 81)' : theme?.palette?.grey[300],
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme?.palette?.text?.secondary,
                },
                '&:active': {
                    backgroundColor: "white"
                },
            },
        },
    }));
    const subMenuClick = (name: string) => {
        console.log("subMenuClick");

        props?.onSubMenuClick(name)
            ; setAnchorEl(null)
    }

    return (
        <>


            <ButtonWapper
                onClick={props?.isSubMenu ? handleClick : props?.onClick}
                {...props}
                variant="outlined"
                startIcon={props?.startIcon}
                endIcon={props?.endIcon}
                disabled={props?.disabled}

            >
                {props?.text}
            </ButtonWapper>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Tooltip title={
                    <>
                        <TypographyWappper onClick={() => subMenuClick('large_heading')} sx={{ p: 0.8,fontSize:"14px" }}> <SortByAlphaIcon className="icon" /> Large Heading</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('small_heading')} sx={{ p: 0.8,fontSize:"14px" }}><SortByAlphaIcon className="icon" /> Small Heading</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('body')} sx={{ p: 0.8,fontSize:"14px" }}> <SortByAlphaIcon className="icon" /> Body</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('caption')} sx={{ p: 0.8,fontSize:"14px" }}> <SortByAlphaIcon className="icon" /> Caption</TypographyWappper>
                    </>
                } componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            color: "common.black",
                            margin: "0px 3px 0px 8px !important",
                            cursor: "pointer !important",
                            padding: "5px !important",
                            border: "1px solid lightgray",
                            '& .MuiTooltip-arrow': {
                                color: 'common.black',
                            },
                        },
                    },
                }} placement="right-start">

                    <MenuItemWappper
                        disableRipple
                    >
                        <TextIcon />
                        <div>Text</div>
                        <More />
                    </MenuItemWappper>
                </Tooltip>

                <Tooltip title={
                    <>
                        <TypographyWappper onClick={() => subMenuClick('image')} sx={{ p: 0.8,fontSize:"14px" }}> <PhotoCameraBackIcon className="icon"/> Image</TypographyWappper>
                        {!props?.isSubScreen? <> <TypographyWappper onClick={() => subMenuClick('upload_image')} sx={{ p: 0.8,fontSize:"14px" }}> <PhotoCameraIcon className="icon"/> Upload Image</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('upload_document')} sx={{ p: 0.8,fontSize:"14px" }}> <InsertDriveFileIcon className="icon"/> Upload Document</TypographyWappper> </>:null}
                    </>
                } componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            color: "common.black",
                            margin: "0px 3px 0px 8px !important",
                            cursor: "pointer !important",
                            padding: "5px !important",
                            border: "1px solid lightgray",
                            '& .MuiTooltip-arrow': {
                                color: 'common.black',
                            },
                        },
                    },
                }} placement="right-start">
                    <MenuItemWappper
                        onClick={handleClose}
                        disableRipple
                    >
                        <Media /> Media <More />
                    </MenuItemWappper>
                </Tooltip>

                {!props?.isSubScreen ? <Tooltip title={
                    <>
                        <TypographyWappper onClick={() => subMenuClick('textInput')}  sx={{ p: 0.8,fontSize:"14px" }}> <FontDownloadIcon className="icon"/> Short Answer</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('textArea')}  sx={{ p: 0.8,fontSize:"14px" }}><NotesIcon className="icon"/> Paragraph</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('datePicker')}  sx={{ p: 0.8,fontSize:"14px" }}> <CalendarMonthIcon className="icon"/> Date Picker</TypographyWappper>
                    </>
                } componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            color: "common.black",
                            margin: "0px 3px 0px 8px !important",
                            cursor: "pointer !important",
                            padding: "5px !important",
                            border: "1px solid lightgray",
                            '& .MuiTooltip-arrow': {
                                color: 'common.black',
                            },
                        },
                    },
                }} placement="right-start">
                    <MenuItemWappper
                        onClick={handleClose}
                        disableRipple
                    >
                        <TextAnswer /> Text Answer<More />
                    </MenuItemWappper>
                </Tooltip> :null}

                {!props?.isSubScreen ? <Tooltip title={
                    <>
                        <TypographyWappper onClick={() => subMenuClick('single_choice')} sx={{ p: 0.8,fontSize:"14px" }}> <RadioButtonCheckedIcon className="icon"/> Single Choice</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('multipal_choice')} sx={{ p: 0.8,fontSize:"14px"  }}> <CheckBoxIcon/> Multipal Choice</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('dropdown')} sx={{ p: 0.8,fontSize:"14px"  }}><FormatListBulletedIcon className="icon"/> Dropdown</TypographyWappper>
                        <TypographyWappper onClick={() => subMenuClick('optIn')} sx={{ p: 0.8,fontSize:"14px"  }}><ToggleOffIcon className="icon"/> Opt-in</TypographyWappper>
                    </>
                } componentsProps={{
                    tooltip: {
                        sx: {
                            bgcolor: 'white',
                            color: "common.black",
                            margin: "0px 3px 0px 8px !important",
                            cursor: "pointer !important",
                            padding: "5px !important",
                            border: "1px solid lightgray",
                            '& .MuiTooltip-arrow': {
                                color: 'common.black',
                            },
                        },
                    },
                }} placement="right-start">
                    <MenuItemWappper
                        onClick={handleClose}
                        disableRipple
                    >
                        <Section /> Selection<More />
                    </MenuItemWappper>
                </Tooltip>:null}
            </StyledMenu>
        </>
    );
};

export const ButtonWapper = styled(Button) <{ props: any }>`
    height: 29px;
    text-transform: capitalize !important;
    font-size: 13px !important;
    color: ${(props: any) =>
        props.fontColor ? `${props.fontColor} !important` : "#fff !important"};
    font-weight: 400 !important;
    background-color: ${(props: any) =>
        props.backgroundColor ? `${props.backgroundColor} !important` : "#128C7E !important"};
    white-space: nowrap;
    width: ${(props: any) =>
        props.width ? `${props.width} !important` : ""};
`;

export const MenuItemWappper = styled(MenuItem)`
    display: flex !important;
    justify-content: space-between !important;
    margin: 0px 3px 0px 8px !important;
    cursor: pointer !important;
    padding: 5px !important;
    &:hover {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
    .text{
        margin-left: 12px;
        margin-right: 3px;
    }
`;

export const MenuItemWappperPopover = styled(Popover)`
    .MuiPaper-root {
        top: 122px !important;
        left: 1116px !important;
        min-width: 163px !important;
    }
`;
export const TypographyWappper = styled(Typography)`
    cursor:pointer;
    pointer-events: auto;

           padding:5px;
        display:flex;
    font-size: 12px;
    .icon{
    margin-right:5px;
    color : #2f4c55;
        font-size: 18px;
        
    }
        &:hover {
        background-color: rgba(0, 0, 0, 0.08) !important;
 
    }
`;
export default AppButton;
