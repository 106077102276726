import styled from "styled-components";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AppForm,
  AppFormSwitchField,
  AppSubmitButton
} from "../../../../AppComponents/Forms";
import AppFormCustomRadioField from "../../../../AppComponents/Forms/AppFormCustomRadioField";
import AppFormMultipleValues from "../../../../AppComponents/Forms/AppFormMultipleValues";
import {
  SettingPageMainBoxBtnBox,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox
} from "../../../../Customization/CommonElements";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { ChatbotConfiguration } from "../../../../Models/ChatbotConfiguration";
import { EmailViewOptions } from "../../../../Models/defaultSelectOptions";
import { EmailNotificationSchema } from "../../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const EmailNotifications = () => {
  const { showAlert } = useNotification();
  const currentBot = useSelector(useCurrentBot);
  const [userInput, setUserInput] = useState<any>({
    emails: [],
    email_view: "",
    enabled: false,
  });

  useEffect(() => {
    if (currentBot.configuration && currentBot.configuration) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot.configuration as string
      ).then((res: AxiosResponse<ChatbotConfiguration>) => {
        if (res.data?.email_notification) {
          setUserInput({
            emails: res.data?.email_notification?.emails
              ? res.data?.email_notification?.emails
              : [],
            email_view: res.data?.email_notification?.email_view
              ? res.data?.email_notification?.email_view
              : "",
            enabled: res.data?.email_notification?.enabled
              ? res.data?.email_notification?.enabled
              : false,
          });
        }
      });
    }
  }, []);

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          email_notification: {
            emails: values.emails,
            email_view: values.email_view,
            enabled: values.enabled,
          },
        },
      }
    )
      .then((response) => {
        SubmitProps.setSubmitting(false);
        SubmitProps.resetForm();
        showAlert("Configuration updated successfully", "success");
      })
      .catch((error) => {
        SubmitProps.setSubmitting(false);
        showAlert(error.response.statusText, "error");
      });
  };

  return (
    <SettingPageMainBoxWrap>
      <SettingPageMainBoxTopbar>
        <SettingPageMainBoxTitle>Email Notifications</SettingPageMainBoxTitle>
        <SettingPageMainBoxBtnBox></SettingPageMainBoxBtnBox>
      </SettingPageMainBoxTopbar>

      <SettingPageMainContentBox
        style={{
          alignItems: "start",
          padding: "1rem 1rem",
          height: "100%",
          gap: "1rem",
        }}
      >
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
          }}
          validationSchema={EmailNotificationSchema}
        >
          <AppFormSwitchField label="Enable" name="enabled" />
          <div style={{ width: "50%" }}>
            <AppFormMultipleValues
              name="emails"
              label="Send notification using Email"
              placeholder="Press Enter to add new email"
              caption="Enter up to 5 Email (press Enter or Tab to add new entry)"
            />
          </div>
          <AppFormCustomRadioField
            label="Email View"
            name="email_view"
            Options={EmailViewOptions}
          />
          <div style={{ width: "30%" }}>
            <AppSubmitButton
              variant="cta"
              style={{ width: "100%", margin: "0.5rem 0" }}
              title={"Save Changes"}
            />
          </div>
        </AppForm>
      </SettingPageMainContentBox>
    </SettingPageMainBoxWrap>
  );
};

export default EmailNotifications;

const Div = styled.div`
  && {
    width: 100%;
    height: 100%;

    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;
const Topbar = styled.div`
  && {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const ContentDiv = styled.div`
  && {
    width: 100%;
    padding: 1rem 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;
