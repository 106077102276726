import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChatNetworkService } from "./ChatNetworkService";
import { templateDataToUserFormData } from "../../../../Components/Broadcast/WhatsApp/Utils/utils";

export class LiveChatActions {
  //* GET ALL TEMPLATES LIST
  static getChannelsListings = createAsyncThunk(
    "LiveChatSlice/getChannelsListings",
    (data: any, { dispatch, rejectWithValue }: any) => {
      return ChatNetworkService.getChannelsListings(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //* GET ALL LABLES LIST
  static getLabelsListings = createAsyncThunk(
    "LiveChatSlice/getLabelsListings",
    (data: any, { dispatch, rejectWithValue }: any) => {
      return ChatNetworkService.getLabelsListings(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //* GET ALL LABLES LIST
  static getAgentsListings = createAsyncThunk(
    "LiveChatSlice/getAgentsListings",
    (data: any, { dispatch, rejectWithValue }: any) => {
      return ChatNetworkService.getAgentsListings(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  //* GET ALL SESSIONS LIST
  static getSessionsListings = createAsyncThunk(
    "LiveChatSlice/getSessionsListings",
    (data: any, { dispatch, rejectWithValue }: any) => {
      return ChatNetworkService.getSessionsListings(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  //* GET ALL SESSIONS LIST
  static appendSessionsListings = createAsyncThunk(
    "LiveChatSlice/appendSessionsListings",
    (data: any, { dispatch, rejectWithValue }: any) => {
      return ChatNetworkService.getSessionsListings(data)
        .then((response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
      // Search canned messages
      static getChatCannedResponses = createAsyncThunk(
        "LiveChatSlice/getChatCannedResponses",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return ChatNetworkService.getChatCannedResponses(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                });
        }
    );

}
