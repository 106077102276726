import React from 'react'
import AppFormSearchField from '../../../../../AppComponents/Forms/AppFormSearchField';
import { TableFilter, TableWrapper } from '../../../../../TableStyle';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import AppButton from '../../../../../AppComponents/AppButton';

const FieldPermission = (): JSX.Element => {
    const [list] = React.useState([
        { id: "123456", c_field: "Original Campaign", editable: "true", readonly: "false", hidden: "false" },
        { id: "789101", c_field: "Original Medium", editable: "false", readonly: "true", hidden: "true" }
    ])
    //Table settings
    const header = [
        "Contact Fields",
        "Editable",
        "Read-Only",
        "Hidden"
    ];

    const keyToHeaderMap: any = {
        "Contact Fields": "c_field",
        "Editable": "editable",
        "Read-Only": "readonly",
        "Hidden": "hidden"
    };

    const colWidth: any = {
        "Contact Fields": `calc(${100}vw - 998px)`,
        "Editable": "200px",
        "Read-Only": "200px",
        "Hidden": "200px"
    };
    return (
        <React.Fragment>
            <TableFilter style={{ paddingLeft: "20px", paddingRight: "20px", marginTop: "1rem" }}>
                <div className="filter-left">
                    <AppFormSearchField
                        name="search"
                        placeholder="Search"
                        defaultBorder={true}
                        divStyle={{ width: "240px", marginBottom: "0" }}
                        onChange={(e: any) => { }}
                        clearCallback={(e: any) => { }}
                    />
                </div>
                <div className="filter-right"></div>
            </TableFilter>
            <TableWrapper height={list?.length ? "350px" : "306px"} style={{ marginBottom: "1rem" }}>
                <div>
                    <Box className="table-header" sx={{ width: "100%" }}>
                        {/* Table Header */}
                        <Box className="table-row" sx={{ justifyContent: "space-between" }}>
                            <Box className="rowleft" sx={{ display: "flex", }}>
                                {header?.map((header: string, index: number) => {
                                    return (
                                        <Box
                                            key={index}
                                            className={`col-head ${header
                                                .toLowerCase()
                                                .replaceAll(" ", "_")}`}
                                            style={{
                                                maxWidth: colWidth[header].toString(),
                                                minWidth: colWidth[header].toString(),

                                            }}
                                            sx={{ justifyContent: header === "Editable" || header === "Read-Only" || header === "Hidden" ? "center" : "" }}
                                        >
                                            <Typography sx={{ fontWeight: 500 }}>
                                                {header}
                                            </Typography>
                                        </Box>
                                    );
                                })}
                            </Box>
                            <Box sx={{ minHeight: "47px", maxHeight: "47px", borderBottom: "1px solid rgba(126, 131, 146, 0.5)", borderTop: "1px solid rgba(126, 131, 146, 0.5)", flex: 1 }}>

                            </Box>

                        </Box>
                        {/* Table Body */}
                    </Box>
                    <Box className="table-body" sx={{ width: "100%" }} >
                        {list?.length === 0 ? (
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                sx={{ height: "calc(100vh - 205px)" }}
                            >
                                <Box className="" sx={{ fontSize: "18px", fontWeight: 500, mb: "12px" }}>
                                    No Data Found
                                </Box>
                                <Box>
                                    <AppButton variant="primarydark" >Add Dependency</AppButton>
                                </Box>
                            </Box>
                        ) : (
                            <React.Fragment>
                                {list?.map((row: any, rowIndex: any) => {
                                    return (
                                        <Box key={rowIndex} className="table-row" sx={{
                                            justifyContent: "space-between"
                                        }}>
                                            <Box className="rowleft" sx={{ display: "flex" }}>
                                                {header?.map((header: any, ColumnIndex: any) => {
                                                    const isActionColumn = header === "Action";
                                                    return (
                                                        <Box
                                                            className={`col-body ${header
                                                                .toLowerCase()
                                                                .replaceAll(" ", "_")}`}
                                                            title={
                                                                row[keyToHeaderMap[header]]
                                                                    ? row[keyToHeaderMap[header]]
                                                                    : "-"
                                                            }
                                                            style={{
                                                                maxWidth: isActionColumn
                                                                    ? "400px"
                                                                    : colWidth[header].toString(),
                                                                minWidth: isActionColumn
                                                                    ? "400px"
                                                                    : colWidth[header].toString(),
                                                                // borderBottom: "none",

                                                            }}
                                                            sx={{ justifyContent: header === "Editable" || header === "Read-Only" || header === "Hidden" ? "center" : "" }}
                                                        >
                                                            {header === "Editable" || header === "Read-Only" || header === "Hidden" ? (
                                                                <RadioGroup
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    defaultValue="female"
                                                                    name="radio-buttons-group"
                                                                >
                                                                    <FormControlLabel sx={{ m: 0 }} value="female" control={<Radio sx={{
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: "18px",
                                                                        },
                                                                    }} />} label="" />
                                                                </RadioGroup>
                                                            ) : (
                                                                <Typography
                                                                    className="table-text"
                                                                    sx={{
                                                                        whiteSpace: "nowrap",
                                                                        overflow: "hidden",
                                                                        textOverflow: "ellipsis",
                                                                    }}
                                                                >
                                                                    {row[keyToHeaderMap[header]]
                                                                        ? row[keyToHeaderMap[header]]
                                                                        : "-"}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                            <Box sx={{ borderBottom: "1px solid rgba(126, 131, 146, 0.2)", flex: 1 }}>

                                            </Box>
                                            <Box className="rowright">
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </React.Fragment>
                        )}
                    </Box>
                </div>
            </TableWrapper>
        </React.Fragment >
    )
}

export default FieldPermission