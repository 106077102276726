import React from 'react'
import styled from 'styled-components';

const DragColumnInfo = () => {
  return (
    <Container>
      <p>
        Please Click on a Column
      </p>
    </Container>
  )
}

export default DragColumnInfo;

const Container = styled.div`
&&{
  height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  p{
    font-size:2rem;
    color:#9e9e9e;
  }
}
`