import { Paper } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";


export const TimeoutWrapper = styled.div`
    // width: 100%;
    height: 100%;
`;

export const TimeoutHeader = styled.div`
    /* width: 100%; */
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fff;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .response-header__left {
        h4 {
            font-size: 1rem;
            font-weight: 500;
        }
    }
    .response-header__right {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`

export const TimeoutContainer = styled(Paper)`
    width: 60%;
    height: calc(100% - (60px + 52px));
    margin-left: 1rem;
`

export const TimeoutTable = styled.div`
    height: 100%;


    .overlay {

        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color:${theme.palette.default.lightGrey}; */
        background-color: rgba(204, 204, 204, 0.2);
        pointer-events: auto; /* This prevents interactions with elements below the overlay */
    }

    .table-header {
        display: flex;
        padding: 1rem;
        // border-bottom: 1px solid ${theme.palette.default.grey};
        .heading-text {
            min-width: 280px;
            h4 {
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }

    .main-switch {
        width: 100%;
    }

    

    .table-data {
        height: calc(100% - 1rem);
        overflow: auto;
        padding: 0rem 1rem;

        .table-row__content {
            width: 100%;
        }

        .mb {
            pading-bottom: 1rem !important;
            margin-bottom:1rem;
        }

        form {
            height: 100%;
            justify-content: space-between;
            align-items: flex-end;
            position: relative;
            
        }

        
    }

    .footer {
        margin-top: 1.5rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1rem;

    }

    .table-row__content {
        
    }

    .b-bottom {
        border-bottom: 1px solid ${theme.palette.default.grey};
        padding-bottom: 1rem;
    }

    .table-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        padding-top: 1rem;
        
        .table-content__text {
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 1rem;
            .link-text {
                color: ${theme.palette.default.blue}
            }
             h4 {
                font-weight: 500;
                font-size: 0.875rem;
            }
            p {
                 font-size: 0.875rem;
                 line-height: 25px;
                color: ${theme.palette.default.darkGrey};
             }
            .table-actions {
                display: flex;
                gap: 1rem;
                padding-top: 1rem;
            }
            
        }
    }
    
    .table-content > :first-child {
        min-width: 280px;
        
    }
    .table-content > :last-child {
        
    }
    .table-content > :nth-child(2) {
        width: calc(100%);
    }
`