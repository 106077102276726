import React, { useEffect } from 'react'
import { AppFormField } from '../../../../../AppComponents/Forms'
import { SettingPageMainBoxTitle } from '../../../../../Customization/CommonElements'
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../Store';
import { emailError } from '../../../../../Store/Slices/email/email.selectors';
import { resetError } from '../../../../../Store/Slices/email/email.slice';
import { Typography } from '@mui/material';
interface Props {
  formStep: number;

}
const RenderFromStep1: React.FC<Props> = ({ formStep }) => {

  const {setFieldError, errors} = useFormikContext<any>();
  const emailErroList = useAppSelector(emailError)
  const dispatch = useAppDispatch();
  useEffect(() => {
    if(emailErroList && !errors?.domain) {
      setFieldError('email', emailErroList)
      dispatch(resetError(''))
    }
  },[emailErroList])

  return (

    <div className="mainDiv">
      <Typography fontSize={"20px"} mb={3} fontWeight={500}>Step 5</Typography>
        <Typography className='text-grey'>
          Default sender is an email address used in your emails as a 'From' address.
          We strongly recommend setting a default sender to help increase your deliverability performance.
        </Typography>

      <AppFormField
        label="Email Address"
        name="email"
        placeholder="@getalabs.com"
        divStyle={{ width: '280px', margin: "3rem 0 2rem 0" }}
      />

    </div>

  )
}

export default RenderFromStep1