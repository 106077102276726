import styled from "styled-components";
import { theme } from "../../Customization/Theme";

export const SidebarMenuContentWrap = styled.div`
  && {
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: none;
    scroll-behavior: none;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
    padding: 0.5rem 0.5rem;
    z-index: 1;

    .sidebarLogoBox {
      padding: 8px 16px;
    }

    .sidebarTitle {
      display: flex;
      padding: 8px 16px;
      min-height: 2.5rem;
      height: 2.5rem;
      max-height: 2.5rem;
      color: ${theme.palette.default.darkGrey};
      font-family: ${theme.typography.fontFamily};
      font-size: 14px;
      line-height: 1.5rem;
    }

    .MuiList-root {
      padding: 0px 0px;
      display: flex;
      flex-direction: column;
      gap: 2px;

      .MuiMenuItem-root {
        padding: 0px 0;
        font-family: ${theme.typography.fontFamily};
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 1.2rem;
      }

      .Mui-selected {
        background-color: rgba(54, 84, 227, 0.08);
      }

      .MuiListItemButton-root {
        border-radius: 4px;
      }
    }

    .MuiAccordion-root {
      box-shadow: none !important;
      /* margin-bottom: 0.5rem; */

      &::before {
        background-color: transparent !important;
      }
      &::after {
        background-color: transparent !important;
      }

      .MuiAccordionSummary-root {
        padding: 1rem 1rem;
        min-height: 2.5rem;
        height: 2.5rem;
        max-height: 2.5rem;

        font-family: ${theme.typography.fontFamily};
        font-size: 14px;
        line-height: 1.5rem;
      }
    }
  }
`;
