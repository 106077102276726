import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  AppFormField,
  AppFormSelectField
} from "../../../../../AppComponents/Forms";
import { LoaderComponentProps } from "../../../../../AppComponents/Loader";
import { MultipleFieldsRow } from "../../../../../Customization/CommonElements";
import { HocBackdropLoader } from "../../../../../HOC/HocBackdropLoader";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { EmailActions } from "../../../../../Store/Slices/email/email.actions";
import { emailDataStore, validateEmailListing } from "../../../../../Store/Slices/email/email.selectors";
import { AppToolTip } from "../../../../UiComponents/AppToolTip/AppToolTip";
import MediaFilePicker from "../MediaFilePicker";
import { StyledEmailFormOne,EmailBroadcast1Step,EmailBroadcastStep1Tooptip } from "../Styles";
import  Email  from "../../../../../assets/images/email.png";

interface Props extends LoaderComponentProps {
  formStep: number;
  isView: boolean;

  setFormStep: Dispatch<SetStateAction<number>>;
}

const RenderFormStep1: React.FC<Props> = ({ setLoading, formStep, isView }) => {
  const { values, errors } = useFormikContext()

  const dispatch = useAppDispatch();
  const { footerListData } = useAppSelector(emailDataStore);
  const allTemplatesData = useAppSelector(validateEmailListing);

  const getValidEmailAndFooterData = () => {
    setLoading(true);
    dispatch(
      EmailActions.getValidateEmailList()).then((res: any) => {
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
    dispatch(
      EmailActions.getBroadcastFooterList()).then((res: any) => {

      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
  }
  useEffect(() => {
    // AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
    getValidEmailAndFooterData()
  }, []);

  useEffect(() => {
    getValidEmailAndFooterData()
  }, [!allTemplatesData, !footerListData])



  if (formStep !== 1) {
    return null;
  }

  return (
    <EmailBroadcast1Step>
      <StyledEmailFormOne >
        <MultipleFieldsRow>
          <AppFormField
            name="broadcast_name"
            label="Email Broadcast Name"
            placeholder="Write broadcast name"
            disabled={isView ? true : false}
            divStyle={{ width: "100%" }}
            requiredSign={true}

          />
          <AppFormField
            name="subject"
            label="Email Subject"
            placeholder="Write subject line"
            disabled={isView ? true : false}
            requiredSign={true}


          />
        </MultipleFieldsRow>

        <MultipleFieldsRow>

          <AppFormField
            name="from_name"
            label="From Name"
            placeholder="Write a name"
            disabled={isView ? true : false}
            divStyle={{ width: "100%" }}


          />
          <AppFormSelectField
            name="from_email"
            label="From Email"
            Options={allTemplatesData}
            className="select-field"
            isDisabled={isView ? true : false}
            requiredSign={true}
            divStyle={{ width: "100%" }}


          />

        </MultipleFieldsRow>

        <MultipleFieldsRow>

          <AppFormField
            name="reply_to"
            label="Reply To"
            placeholder="i.e: abc@awesome.com"
            disabled={isView ? true : false}


          />
          <AppFormSelectField
            name="footer"
            label="Footer"
            Options={footerListData}
            className="select-field"
            isDisabled={isView ? true : false}
            requiredSign={true}
            divStyle={{ maxWidth: "calc(50% - 15px)" }}
            popOverMaxWidth={"200px"}

          />

        </MultipleFieldsRow>

        <div style={{ display: "flex" }}>
          <label style={{ marginTop: "8px" }}
          >Email Attachment</label>
          <AppToolTip
            tooltipProps={{
              title: "User is able to Upload document as attachment with diffrent format like pdf,img ,video with valid document size 10MB.",
              placement: "right",

          }}
          iconButtonProps={{ disableRipple: true }}
          IconComponent={
            <InfoOutlinedIcon fontSize="small" />
          }


          />
        </div>
        <div className="upload_file" style={{ maxWidth: "initial" }}>

          <MediaFilePicker
            name="email_attachment"
            mediaType={"document"}
            sizeLimit={10000000}
            extensions={[
              "text/csv",
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "application/pdf",
              "application/wps-office.xlsx",
              "application/vnd.ms-excel",
              "image/jpeg",
              "image/jpg",
              "image/png",
              "image/svg+xml",
              "video/mp4",
              "video/3gp",
              "image/webp"
            ]}
            className=""
            divStyle={{ margin: "0rem", width: "auto" }}
            disabled={isView ? true : false}

          />

          <Typography variant="body2">
            (Maximum file size 10 MB
            )
          </Typography>

        </div>

      </StyledEmailFormOne>
      <EmailBroadcastStep1Tooptip>
      <img src={Email} style={{    maxWidth: "100%",
    minWidth: "100%",
    margin: "0% 0% 0% 2%"}} alt="no data available" />
      </EmailBroadcastStep1Tooptip>
    </EmailBroadcast1Step>

  );
};

export default HocBackdropLoader(RenderFormStep1);
