import React, { useRef, useState } from "react";
import { LeadPageWrapper } from "../../../LeadsStyles";
import {
  AppForm,
  AppFormField,
  AppFormSelectField,
  AppSubmitButton,
} from "../../../../../AppComponents/Forms";
import { ReactComponent as TagCrossIcon } from "../../../../../assets/images/tag-cross-icon.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/images/save-icon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/icons/pencil.svg";
import AppButton from "../../../../../AppComponents/AppButton";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import useModal from "../../../../../Hooks/useModel/useModel";
import { useParams } from "react-router";
import { useEffect } from "react";
import ContactsBasicInformation from "./ContactsBasicInformation";
import { withLoader } from "../../../../../AppComponents/Loader";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ContactsActions } from "../../../../../Store/Slices/Contacts/contacts.action";
import { ContactBasicInfo } from "../../../../../Store/Slices/Contacts/contacts.selector";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import RenderDynamicFields from "../../FieldsLogic/RenderDynamicFields";
import { ProtectedAppUiElement } from "../../../../../AppComponents/ProtectedComponents";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import { AppToolTip } from "../../../../UiComponents/AppToolTip/AppToolTip";

const initialValue = {
  firstname: "Shrikant",
  lastname: "Dange",
  designername: "Graphics Designer",
  email: "shrikant.dange@srvmedia.com",
  emptyemail: "-",
  phone: "",
  workphone: "",
  birthdate: "",
  facebook: "",
  twitter: "",
  linkdin: "",
};
// interface BasicInfo {
//   firstname: string;
//   lastname: string;
//   designername: string;
//   email: string;
//   emptyemail: string;
//   phone: string;
//   workphone: string;
//   birthdate: string;
//   facebook: string;
//   twitter: string;
//   linkdin: string;
// }

function LeadsDetailsPage(props: any) {
  const params = useParams();
  const { contact_id } = params;
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const basicInformation: any = useAppSelector(ContactBasicInfo);
  const useModal3 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingLifeCycle: isShowing, toggleLifeCycleModal: toggle };
  };
  const useModal4 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal4: isShowing, toggleModal4: toggle };
  };
  const useModal5 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal5: isShowing, toggleModal5: toggle };
  };
  const { isShowingModal4, toggleModal4 } = useModal4();
  const { isShowingModal5, toggleModal5 } = useModal5();
  const { isShowingLifeCycle, toggleLifeCycleModal } = useModal3();
  const [userLifeCycleValues, setUserLifeCycleValues] = useState<any>({});
  const [lifeCycleValue, setLifeCycleValue] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const assignedname = [{ name: "Shubham Puranik" }];
  useEffect(() => {
    let tempValues: any = {};
    if (basicInformation?.lifecycle_fields?.[0]?.fields.length > 0) {
      basicInformation?.lifecycle_information?.forEach((field: any) => {
        if (field.is_selected) {
          tempValues.lifecycle_stage = field;
          tempValues.lifecycle_stage_status = field.statuses.find(
            (status: any) => status.is_selected
          );
        }
      });
      setUserLifeCycleValues(tempValues);
    }
  }, [basicInformation?.lifecycle_fields?.[0]?.fields]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (basicInformation?.lifecycle_fields?.[0]?.fields) {
      let data = [...basicInformation?.lifecycle_fields];

      let lifecycleFieldData: any = {
        ...data[0],
        "fields" : basicInformation?.lifecycle_fields?.[0]?.fields
      }
      setLifeCycleValue(lifecycleFieldData)
    }
  }, [basicInformation?.lifecycle_fields?.[0]?.fields]);

  const getContactDetails = () => {
    setLoading(true);
    let payload = {
      contact_id,
    };

    dispatch(ContactsActions.getBasicInfo(payload))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(
            res?.payload?.data?.message || "Enabled to fetch Data",
            "error"
          );
        } else {
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (contact_id) {
      getContactDetails();
    }
  }, [contact_id]); // eslint-disable-line

  const refRibbon1 = useRef<HTMLElement>(null);
  const refRibbon2 = useRef<HTMLElement>(null);
  const [hideButton, setHideButton] = useState(false);
  const [hideButtonRef2, setHideButtonRef2] = useState(false)

  const ribbon1Scroll = (scrollOffset: number) => {
    if (refRibbon1.current) {
      refRibbon1.current.scrollLeft += scrollOffset;
    }
  };

  const ribbon2Scroll = (scrollOffset: number) => {
    if (refRibbon2.current) {
      refRibbon2.current.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    const ribbonElement = refRibbon1?.current;

    const handleResize = () => {
      if (ribbonElement) {
        const hasHorizontalScroll =
          ribbonElement.scrollWidth > ribbonElement.clientWidth;

        if (hasHorizontalScroll) {
          setHideButton(true);
        } else {
          setHideButton(false);
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoading, refRibbon1]);


  useEffect(() => {
    const ribbonElement = refRibbon2?.current;

    const handleResize = () => {
      if (ribbonElement) {
        const hasHorizontalScroll =
          ribbonElement.scrollWidth > ribbonElement.clientWidth;

        if (hasHorizontalScroll) {
          setHideButtonRef2(true);
        } else {
          console.log('No horizontal scrollbar');
          setHideButtonRef2(false);
        }
      }
    };

    window.addEventListener('resize', handleResize);

    // Call handleResize initially to set initial state
    handleResize();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoading, refRibbon2]);


  return (
    <>
      {!isLoading && basicInformation?.lifecycle_information?.length > 0 ? (
        <Stage>
          <div className="stage-wrapper">
            <StageContainer>
              <h5>Stages</h5>
              
                <Ribbon ref={refRibbon1}>
                {basicInformation?.lifecycle_information.map(
                  (stage: any, index: number) => {
                    if (index === 0) {
                      return (
                        <StartArrow
                          className={`start ${
                            stage.is_selected ? "active" : ""
                          }`}
                        >
                          <Typography
                          sx={{
                            fontSize:"13px",
                            mt: "2px",
                          }}
                          >
                            <AppToolTip
                              tooltipProps={{
                                title:
                                  `${stage?.label}`,
                                placement: "top",
                              }}
                              iconButtonProps={{ disableRipple: true }}

                            >
                            {stage.label}

                            </AppToolTip>
                          </Typography>
                        </StartArrow>
                      );
                    } else if (
                      index ===
                      basicInformation?.lifecycle_information.length - 1
                    ) {
                      return (
                        <EndArrow
                          className={`end ${stage.is_selected ? "active" : ""}`}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: "2px",
                            }}
                          >
                            <AppToolTip
                              tooltipProps={{
                                title:
                                  `${stage?.label}`,
                                placement: "top",
                              }}
                              iconButtonProps={{ disableRipple: true }}

                            >
                              {stage.label}

                            </AppToolTip>
                          </Typography>
                        </EndArrow>
                      );
                    } else {
                      return (
                        <Medium
                          className={`mid ${stage.is_selected ? "active" : ""}`}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: "2px",
                            }}
                          >
                            <AppToolTip
                              tooltipProps={{
                                title:
                                  `${stage?.label}`,
                                placement: "top",
                              }}
                              iconButtonProps={{ disableRipple: true }}

                            >
                              {stage.label}
                            </AppToolTip>
                          </Typography>
                        </Medium>
                      );
                    }
                  }
                )}
                </Ribbon>
              
              
              {hideButton && (
                <Box className="scroll-btns">
                  <Box className="scroll-back" onClick={() => ribbon1Scroll(-100)}>
                    <AppMaterialIcons style={{ fontSize: "16px" }} iconName="ArrowBackIosOutlined" />
                  </Box>
                  <Box className="scroll-forward" onClick={() => ribbon1Scroll(100)}>
                    <AppMaterialIcons style={{ fontSize: "16px" }} iconName="ArrowForwardIosOutlined" />
                  </Box>
                </Box>
              )}
                
              
              
            </StageContainer>
            <StageContainer>
              <h5>Status</h5>
            
              <Ribbon ref={refRibbon2}>
                {basicInformation?.lifecycle_information
                  .find((ele: any) => ele.is_selected === true)
                  ?.statuses?.map((stage: any, index: number) => {
                    if (index === 0) {
                      return (
                        <StartArrow
                          className={`start ${
                            stage.is_selected ? "active" : ""
                          }`}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: "2px",
                            }}
                          >
                            <AppToolTip
                              tooltipProps={{
                                title:
                                  `${stage?.label}`,
                                placement: "top",
                              }}
                              iconButtonProps={{ disableRipple: true }}

                            >
                              {stage.label}
                            </AppToolTip>
                          </Typography>
                        </StartArrow>
                      );
                    } else if (
                      index ===
                      basicInformation?.lifecycle_information.find(
                        (ele: any) => ele.is_selected === true
                      )?.statuses.length -
                        1
                    ) {
                      return (
                        <EndArrow
                          className={`end ${stage.is_selected ? "active" : ""}`}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: "2px",
                            }}
                          >
                            <AppToolTip
                              tooltipProps={{
                                title:
                                  `${stage?.label}`,
                                placement: "top",
                              }}
                              iconButtonProps={{ disableRipple: true }}

                            >
                              {stage?.label}
                            </AppToolTip>
                          </Typography> 
                        </EndArrow>
                      );
                    } else {
                      return (
                        <Medium
                          className={`mid ${stage.is_selected ? "active" : ""}`}
                        >
                          <Typography
                            sx={{
                              fontSize: "13px",
                              mt: "2px",
                            }}
                          >
                            <AppToolTip
                              tooltipProps={{
                                title:
                                  `${stage?.label}`,
                                placement: "top",
                              }}
                              iconButtonProps={{ disableRipple: true }}

                            >
                              {stage.label}
                            </AppToolTip>
                          </Typography>
                        </Medium>
                      );
                    }
                  })}
              </Ribbon>
                  
                  {
                    hideButtonRef2 && (
                  <Box className="scroll-btns">
                    <Box className="scroll-back" onClick={() => ribbon2Scroll(-100)}>
                      <AppMaterialIcons style={{ fontSize: "16px" }} iconName="ArrowBackIosOutlined" />
                    </Box>
                    <Box className="scroll-forward" onClick={() => ribbon2Scroll(100)}>
                      <AppMaterialIcons style={{ fontSize: "16px" }} iconName="ArrowForwardIosOutlined" />
                    </Box>
                  </Box>
                    )
                  }
            </StageContainer>
          </div>
          <EditLifecycleButtonWrapper>
            {basicInformation?.can_update && (
              <AppButton className="edit-btn" onClick={toggleLifeCycleModal}>
                <EditIcon />
              </AppButton>
            )}
          </EditLifecycleButtonWrapper>
        </Stage>
      ) : (
        <Stage>
          <div className="stage-wrapper">
            <StageContainer className="loading">
              <h5>Stages</h5>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </StageContainer>
            <StageContainer className="loading">
              <h5>Status</h5>
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
              <Skeleton variant="rectangular" width={"100%"} height={"24px"} />
            </StageContainer>
          </div>
        </Stage>
      )}
      <SectionWrapper>
        <div className="body-data">
          {/* accordian contacts details */}
          {basicInformation?.section_information?.length && (
            <ContactsBasicInformation
              contactSectionsArray={basicInformation?.section_information}
              isHavingEditPermission={basicInformation?.can_update}
              contactId={contact_id}
              // basicInfo={basicInfo}
              // setBasicinfo={setBasicinfo}
            />
          )}
        </div>
        <AppModel
          isShowing={isShowingModal4}
          onClose={() => toggleModal4()}
          divStyle={{
            width: "671px",
            //   height:'371px',
            gap: "0.5rem",
            overflowY: "hidden",
            padding: "1rem 1rem",
          }}
        >
          <LeadPageWrapper>
            <div className="social-media-popup">
              <div className="popup-header">
                <div className="popup-heading-text">
                  <h4>Edit social profile</h4>
                </div>
                <div className="popup-heading-icon">
                  <IconButton onClick={() => toggleModal4()}>
                    <TagCrossIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <AppForm initialValues={initialValue} onSubmit={() => {}}>
              <div className="input">
                <AppFormField
                  label="Facebook"
                  placeholder="facebook.com/shrikant202"
                  name="  "
                />
              </div>
              <div className="input">
                <AppFormField
                  label="Twitter"
                  name=" "
                  placeholder="Twitter.com/shrikant202 "
                />
              </div>
              <div className="input">
                <AppFormField label="Linked In" name=" " placeholder=" " />
              </div>

              <div className="mark-complete-buttons">
                <AppButton onClick={() => toggleModal4()} variant="grey">
                  cancel
                </AppButton>
                <AppButton onClick={() => toggleModal4()}>
                  <IconButton>
                    <SaveIcon />
                  </IconButton>
                  save
                </AppButton>
              </div>
            </AppForm>
          </LeadPageWrapper>
        </AppModel>

        <AppModel
          isShowing={isShowingModal5}
          onClose={() => toggleModal5()}
          divStyle={{
            width: "671px",
            //   height:'371px',
            gap: "0.5rem",
            overflowY: "hidden",
            padding: "1rem 1rem",
          }}
        >
          <LeadPageWrapper>
            <div className="social-media-popup">
              <div className="popup-header">
                <div className="popup-heading-text">
                  <h4>Edit Assigned to</h4>
                </div>
                <div className="popup-heading-icon">
                  <IconButton onClick={() => toggleModal5()}>
                    <TagCrossIcon />
                  </IconButton>
                </div>
              </div>
            </div>
            <AppForm initialValues={initialValue} onSubmit={() => {}}>
              <div className="assigned-to-popup-input">
                <AppFormSelectField
                  label="Assigned to"
                  Options={assignedname}
                  name="assignedname"
                />
              </div>

              <div className="mark-complete-buttons">
                <AppButton onClick={() => toggleModal5()} variant="grey">
                  cancel
                </AppButton>
                <AppButton onClick={() => toggleModal5()}>
                  <IconButton>
                    <SaveIcon />
                  </IconButton>
                  save
                </AppButton>
              </div>
            </AppForm>
          </LeadPageWrapper>
        </AppModel>
        {/* LifeCycle Fields */}
        <AppModel
          isShowing={isShowingLifeCycle}
          onClose={() => toggleLifeCycleModal()}
          divStyle={{
            width: "671px",
            //   height:'371px',
            gap: "0.5rem",
            overflowY: "hidden",
            padding: "1rem 1rem",
          }}
        >
          <ModalUI>
            <div className="social-media-popup">
              <div className="popup-header">
                <div className="popup-heading-text">
                  <h4>Edit Lifecycle Stage/Status</h4>
                </div>
                <div className="popup-heading-icon">
                  <IconButton onClick={() => toggleLifeCycleModal()}>
                    <TagCrossIcon />
                  </IconButton>
                </div>
              </div>
              <Box className="wrapper">
                {basicInformation?.lifecycle_fields?.length > 0 &&
                basicInformation?.lifecycle_fields?.[0]?.fields?.length > 0 &&
                Object.keys(userLifeCycleValues).length > 0 ? (
                  <>
                    <RenderDynamicFields
                      AddContactsFields={[lifeCycleValue]}
                      ConatctDetailsById={JSON.parse(
                        JSON.stringify(
                          basicInformation?.contact_lifecycle_data || {}
                        ),
                        (key, value) =>
                          value === null || value === "" ? undefined : value
                      )}
                      ContactId={contact_id}
                      cancel={() => toggleLifeCycleModal()}
                      setLoading={setLoading}
                      hideLabelSectionTitle={true}
                      updateData={getContactDetails}
                    />
                  </>
                ) : null}
              </Box>
            </div>
          </ModalUI>
        </AppModel>
      </SectionWrapper>
    </>
  );
}

export default LeadsDetailsPage;

export const ModalUI = styled.div`

  .wrapper {
    padding: 0px 16px;
    .input-field_wrapper {
      height: auto !important;
      padding-top: 16px;
    }
  }
  .mark-complete-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid ${theme.palette.default.border};
    padding-top: 10px;
    gap: 15px;
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 10px;
  }
  .btn {
    border: none;
    background: none;
  }
  .popup-heading-text h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #323232;
  }
  .popup-heading-icon svg {
    width: 12px;
    height: 12px;
    color: red;
  }
`;
const SectionWrapper = styled.div`
  background-color: ${theme.palette.default.white};
  margin: 15px 15px 0;
`;

const Stage = styled.div`
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  justify-content: space-between;
  min-height: 104px;
  display: flex;
  align-items: stretch;
  margin-bottom: 15px;
  margin: 0 15px;
  .stage-wrapper {
    width: calc(100% - 45px);
    padding: 16px;
  }
`;
const StageContainer = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
  .scroll-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:6px;
    .scroll-back {
      border: 1px solid ${theme.palette.default.border};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 6px;
      border-radius: 4px;
    }
    .scroll-forward {
      border: 1px solid ${theme.palette.default.border};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 6px;
      border-radius: 4px;
    }
  }
  &.loading {
    gap: 10px;
  }
`;

const StartArrow = styled.div`
  width: 100%;
  min-width: 130px;
  // max-width: 130px;
  height: 40px;
  display: inline-block;
  position: relative;
  height: 24px;
  line-height: 24px;
  text-align: center;
  // padding: 0 40px 0 18px;
  padding: 0 18px;
  font-size: 13px;
  background: #ececef;
  color: black;
  box-sizing: border-box;

  .icon-wrapper {
    text-overflow: ellipsis !important;
    display: block !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    &::before {
      content: "" !important;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0px;
    left: -12px;
    border-width: 12px;
    border-style: solid;
    border-radius: 50%;
    color: #ececef;
  }

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    right: -12px;
    border-width: 12px 0px 12px 13px;
    border-color: transparent transparent transparent #ececef;
    border-style: solid;
  }
`;
const Medium = styled.div`
  width: 100%;
  height: 24px;
  min-width: 100px;
  // max-width: 100px;
  display: inline-block;
  position: relative;
  line-height: 24px;
  text-align: center;
  // padding: 0 18px 0 40px; /* Adjusted padding for the left side */
  padding: 0 18px;
  font-size: 13px;
  background: #ececef;
  color: black;
  box-sizing: border-box;
  .icon-wrapper {
    text-overflow: ellipsis !important;
    display: block !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    &::before {
      content: "" !important;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    left: -16px;
    border-width: 12px 0px 12px 17px;
    border-color: #ececef transparent #ececef transparent;
    border-style: solid;
  }

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    right: -16px;
    border-width: 12px 0px 12px 17px;
    border-color: transparent transparent transparent #ececef;
    border-style: solid;
  }
`;
const EndArrow = styled.div`
  width: 100%;
  height: 40px;
  display: inline-block;
  min-width: 130px;
  // max-width: 130px;
  position: relative;
  height: 24px;
  line-height: 24px;
  text-align: center;
  // padding: 0 40px 0 18px;
  padding: 0 18px;
  font-size: 13px;
  background: #ececef;
  color: black;
  box-sizing: border-box;
  .icon-wrapper {
    text-overflow: ellipsis !important;
    display: block !important;
    text-decoration: none !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    &::before {
      content: "" !important;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0;
    left: -17px;
    border-width: 13px 0px 12px 18px;
    border-color: #ececef transparent #ececef transparent;
    border-style: solid;
  }

  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    z-index: 1;
    top: 0px;
    right: -12px;
    border-width: 12px;
    border-style: solid;
    border-radius: 50%;
    color: #ececef;
  }
`;

const Ribbon = styled.div`
  display: flex;
  gap: 30px;

   padding: 0 1rem;
    overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;  
  white-space: nowrap;
  margin-left: 2rem;
  margin-right: 2rem;
  width: calc(100% - 100px);
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: transparent !important;
  }
  
  > div.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    :after {
      color: ${theme.palette.primary.main};
    }
  }
  > div.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
    &:after {
      border-color: transparent transparent transparent
        ${theme.palette.primary.main};
    }
    &.end:after {
      border-color: ${theme.palette.primary.main};
    }
    &:before {
      border-color: ${theme.palette.primary.main} transparent;
    }
    &.start:before {
      border-color: ${theme.palette.primary.main};
    }
  }
`;

const EditLifecycleButtonWrapper = styled.div`
  box-shadow: -1px 0px 1px 0px #0000001a;
  width: 40px;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  > .edit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    height: 100%;
    padding: 0 !important;
    flex-direction: column;
  }
`;
