import { Grid } from "@mui/material";
import { AddScreenModel, FlowScreenHeader, WhatsappFlowSection, WhatsappFlowWapper } from "../Utils/style";
import FlowBuilderPreview from "./flowBuilderPreview";
import '../Utils/whatsappFlowBuilder.css';
import FlowBuilderScreen from "./flowBuilderScreen";
import FlowBuilderContent from "./FlowBuilderContent";
import { useEffect, useState } from "react";
import AppModel from "../Components/Model";
import AppButton from "../Components/Button";
import { getContactMapping, getContentData, getFlowBuilderList, getFormName, getScreenType } from "../Store/FlowBuilder.selectors";
import { useSelector } from "react-redux";
import FlowBuilderSubScreenContent from "./flowBuilderSubScreenContent";
import SaveIcon from '@mui/icons-material/Save';
import PublishIcon from '@mui/icons-material/Publish';
import RuleIcon from '@mui/icons-material/Rule';
import { HocBackdropLoader, HocBackdropLoaderProps } from "../HOC/HocBackdropLoader";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate, useParams } from "react-router-dom";
import { FlowBuilderActions } from "../Store/FlowBuilder.actions";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import AppInput from "../Components/Input";
import { deepEqual } from "../Utils/common";
import { useAuthStore } from "../../Store/Slices/AuthSlice";

interface Props extends HocBackdropLoaderProps {

}

const WhatsAppWapper: React.FC<Props> = ({
    setLoading,
}) => {
    const [isShowing, setIsShowing] = useState(false);
    const [isPublish, setIsPublish] = useState(false);
    const [testFlow, setTestFlow] = useState(false);
    const [shouldReload, setShouldReload] = useState(false);
    const [recipientNumber, setRecipientNumber] = useState("")
    const scrrenType = useSelector(getScreenType);
    const contactMapping = useSelector(getContactMapping);
    const formName = useSelector(getFormName)
    const flowList = useSelector(getFlowBuilderList);
    const { userConfig }: any = useAppSelector(useAuthStore);
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { showAlert } = useNotification();
    const [errorMessage, setErrorMessage] = useState("")
    const [beforeGo, setBeforeGo] = useState(false)
    const [previousList, setPreviousList] = useState<any>({})
    const params = useParams();
    const { id } = params;    

    useEffect(() => {
        if(flowList?.screens?.length === 0){
            navigate("/whatsapp/flow")
        }
    },[flowList])
    useEffect(() => {
        setPreviousList(flowList)
    },[])
    const handleDiscard = () => {
        setIsShowing(false);
        setIsPublish(false)
        setTestFlow(false)
        if(beforeGo){
            navigate("/whatsapp/flow")
        }
    };

    const handleSave = () => {
        // Add save functionality here
        setIsShowing(false);
        // Optionally, you can also set shouldReload to true and reload after saving
        // setShouldReload(true);
        // window.location.reload();
    };

    const handlePublish = () => {
        setIsPublish(false)
        setLoading(true)
        dispatch(FlowBuilderActions.publishFlow(id))
            .then((res: any) => {
                console.log("res----------------------->", res);
                if (res?.payload?.data?.status === "error") {
                    setLoading(false)
                    showAlert(res?.payload?.error || "Error In Deleting", "error");
                }
                if (res?.payload?.data?.status === "success") {
                    showAlert("Publish Successfully", "success");
                    setTimeout(() => {
                        navigate("/whatsapp/flow")
                    }, 500)
                }
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("res----------------------->", error?.payload?.error);
            })
    };

    const testFlowFn = () => {
        setTestFlow(false)
        setLoading(true)
        let data = {
            "flow_id" : id,
            "recipient_number" : "+91"+recipientNumber
        }
        dispatch(FlowBuilderActions.testFlow(data))
            .then((res: any) => {
                console.log("res----------------------->", res);
                if (res?.payload?.data?.status === "error") {
                    setLoading(false)
                    showAlert(res?.payload?.data?.data?.message ||  res?.payload?.data?.data || "Error In Deleting", "error");
                }
                if (res?.payload?.data?.status === "success") {
                    setLoading(false)
                    showAlert("Send Successfully", "success");
                }
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("res----------------------->", error?.payload?.error);
            })
    };


    interface Screen {
        id: string;
        layout: {
            children: {
                children: any;
                type: string;
                'on-click-action'?: {
                    next?: any;     // Adjust type as per your actual structure
                };
            }[];
        };
    }
    const saveFlowData = () => {
        setLoading(true)
        setIsShowing(false)
        // Deep clone of flowList to avoid mutating original data
        let data: { screens: Screen[] } = JSON.parse(JSON.stringify(flowList));
        const filteredScreens = data.screens.filter((screen: any) => !screen.id.includes('TERMS_AND_CONDITIONS'));
        filteredScreens.forEach((screen: any, index: any) => {
            let screenLayout = screen?.layout?.children[0]?.children;
            let footer = screenLayout?.find((child: any) => child.type === 'Footer');
            let previousScreenFooter = index > 0 ? { ...filteredScreens[index - 1]?.layout?.children[0]?.children.find((child: any) => child.type === 'Footer') } : null;
            let previousScreenObject: any = filteredScreens[index - 1]
            let nextScreen = filteredScreens[index + 1];
            let dataPayload: any = previousScreenObject ? { ...previousScreenObject['data'] } : {}
            let isLastScreen: any = false
            if (footer && footer.type === "Footer" && index + 1 === filteredScreens?.length) {
                isLastScreen = true
                let payloadObj = previousScreenFooter ? {
                    ...Object.keys(previousScreenFooter['on-click-action']['payload']).reduce((acc: any, key: any) => {
                        acc[key] = "${data." + key + "}";
                        return acc;
                    }, {})
                } : {};
                screenLayout?.forEach((child: any) => {
                    if (['Image', 'TextInput', 'TextArea', 'DatePicker', 'OptIn','DocumentPicker','PhotoPicker'].includes(child.type)) {
                        delete child['data-source']
                    }
                    if (['Image', 'TextInput', 'TextArea', 'DatePicker', 'RadioButtonsGroup', 'CheckboxGroup', 'Dropdown', 'OptIn','DocumentPicker','PhotoPicker'].includes(child.type) && child.name) {
                        payloadObj[child.name] = "${form." + child.name + "}";
                    }
                });

                previousScreenObject?.layout?.children[0]?.children.map((child: any) => {
                    
                    if (['TextInput', 'TextArea', 'DatePicker', 'RadioButtonsGroup', 'Dropdown'].includes(child.type) && child.name) {
                        dataPayload[child.name] = {
                            "type": "string",
                            "__example__": "Example"
                        }
                    } else if (['CheckboxGroup'].includes(child.type) && child.name) {
                        dataPayload[child.name] = {
                            "type": "array",
                            "items": {
                                "type": "string"
                            },
                            "__example__": []
                        }
                    } else if (['OptIn'].includes(child.type) && child.name) {
                        dataPayload[child.name] = {
                            "type": "boolean",
                            "__example__": false
                        }
                    }
                })

                if (footer['on-click-action']) {
                    let onClickAction = { ...footer['on-click-action'] };
                    delete onClickAction.next;
                    onClickAction['payload'] = payloadObj;
                    onClickAction['name'] = "complete";
                    footer['on-click-action'] = onClickAction;
                }
            } else if (index + 1 < filteredScreens.length) {
                isLastScreen = false
                if (footer && nextScreen) {
                    let onClickAction = { ...footer['on-click-action'] };
                    onClickAction['next'] = { "type": "screen", "name": nextScreen?.id };

                    let payloadObj = previousScreenFooter ? {
                        ...Object.keys(previousScreenFooter['on-click-action']['payload']).reduce((acc: any, key: any) => {
                            acc[key] = "${data." + key + "}";
                            return acc;
                        }, {})
                    } : {};


                    screenLayout?.forEach((child: any) => {
                        if (['Image', 'TextInput', 'TextArea', 'DatePicker', 'OptIn','DocumentPicker','PhotoPicker'].includes(child.type)) {
                            delete child['data-source']
                        }
                        if (['Image', 'TextInput', 'TextArea', 'DatePicker', 'RadioButtonsGroup', 'CheckboxGroup', 'Dropdown', 'OptIn','DocumentPicker','PhotoPicker'].includes(child.type) && child.name) {
                            payloadObj[child.name] = "${form." + child.name + "}";
                        }
                    });
                    previousScreenObject?.layout?.children[0]?.children.map((child: any) => {
                        if (['TextInput', 'TextArea', 'DatePicker', 'RadioButtonsGroup', 'Dropdown'].includes(child.type) && child.name) {
                            dataPayload[child.name] = {
                                "type": "string",
                                "__example__": "Example"
                            }
                        } else if (['CheckboxGroup'].includes(child.type) && child.name) {
                            dataPayload[child.name] = {
                                "type": "array",
                                "items": {
                                    "type": "string"
                                },
                                "__example__": []
                            }
                        } else if (['OptIn'].includes(child.type) && child.name) {
                            dataPayload[child.name] = {
                                "type": "boolean",
                                "__example__": false
                            }
                        }
                    })
                    onClickAction['name'] = "navigate";
                    onClickAction['payload'] = payloadObj;
                    footer['on-click-action'] = onClickAction;
                }
            }
            if (!isLastScreen) {
                delete screen['success']
                delete screen['terminal']
            } else {
                screen['success'] = true
                screen['terminal'] = true
            }
            screen['data'] = dataPayload
        });
        const subScreen: any = data.screens.filter((screen: any) => screen.id.includes('TERMS_AND_CONDITIONS'));
        console.log("subScreen", subScreen);

        let allScreens: any = []
        if (subScreen && subScreen[0]) {
            allScreens = [
                ...filteredScreens,
                ...subScreen
            ]
        }
        data['screens'] = allScreens.length > 0 ? allScreens : filteredScreens
        console.log("data", data);
        let payload = {
            flow: data,
            mapping_data: contactMapping,
            "flow_id": id
        }
        console.log("paylaod", payload);
        dispatch(FlowBuilderActions.updateFlow(payload))
            .then((res: any) => {
                console.log("res----------------------->", res);
                if (res?.payload?.error) {
                    setLoading(false)
                    setErrorMessage(res?.payload?.error)
                    showAlert(res?.payload?.error || "Error In Deleting", "error");
                    // setErrorMessage(res?.payload?.error)
                }
                if (res?.payload?.data) {
                    setLoading(false)
                    setErrorMessage('')
                    showAlert("Save Successfully", "success");
                    setPreviousList(flowList)
                    if(beforeGo){
                        setTimeout(() => {
                            navigate("/whatsapp/flow")
                        }, 500)
                    }
                }
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("res----------------------->", error?.payload?.error);
            })
        return data;
    }
    const goBack = () =>{
        console.log("flowList",deepEqual(flowList,previousList));
        
        if(!deepEqual(flowList,previousList)){
            setIsShowing(true);
            setBeforeGo(true)
        }else{
            navigate("/whatsapp/flow")
        }
    }
    return (
        <>
            <FlowScreenHeader>
                <div className="headerContainer" style={{ justifyContent: "space-between" }}>
                    <div>
                        <div style={{ padding: "12px" }}>
                            <div style={{ display: "flex", cursor: "pointer" }} onClick={() => goBack()}>
                                <KeyboardArrowLeftIcon />
                                <a style={{ margin: "2px 0px 0px 7px" }}>{ formName || "Whatsapp Flow"}</a>
                            </div>
                        </div>
                    </div>
                    <div style={{ padding: "13px" }}>
                        <a style={{ color: "red" }}>{errorMessage}</a>
                    </div>
                    <div style={{ margin: "12px" }}>
                        <AppButton onClick={saveFlowData} startIcon={<SaveIcon />} className="bodyOptionsButton" fontColor={'#ffff'} backgroundColor={"rgb(33, 28, 82)"} text={"Save"} />
                        <AppButton onClick={() => setIsPublish(true)} style={{ marginLeft: "15px" }} startIcon={<PublishIcon />} className="bodyOptionsButton" fontColor={'#ffff'} backgroundColor={"rgb(33, 28, 82)"} text={"Publish"} />
                        <AppButton onClick={() => {setTestFlow(true);setRecipientNumber("")}} style={{ marginLeft: "15px" }} startIcon={<RuleIcon />} className="bodyOptionsButton" fontColor={'#ffff'} backgroundColor={"rgb(33, 28, 82)"} text={"Test Flow"} />

                    </div>

                </div>
            </FlowScreenHeader>

            <WhatsappFlowWapper>
                <Grid container spacing={2}>
                    <WhatsappFlowSection item xs={3}>
                        <FlowBuilderScreen />
                    </WhatsappFlowSection>
                    <WhatsappFlowSection item xs={5}>
                        {scrrenType === "parentScreen" ?
                            <FlowBuilderContent /> : <FlowBuilderSubScreenContent />}
                    </WhatsappFlowSection>
                    <WhatsappFlowSection item xs style={{ paddingTop: "5%" }}>
                        <FlowBuilderPreview />
                    </WhatsappFlowSection>
                </Grid>
                <AppModel width="546px" style={{ width: "100%" }} isShowing={isShowing} onClose={() => setIsShowing(false)}>
                    <AddScreenModel>
                        <div className='addScreenHeader'>
                            <div className="headerTitle">Before you go...</div>
                        </div>
                        <div>
                            <div className="leaveBodyTitle">Are you sure you want to leave this page? Check if your content is saved before leaving.</div>
                        </div>
                        <div className='footer'>
                            <AppButton onClick={handleDiscard} width="220%" fontColor="black" backgroundColor="#fff" text={"Discard"} />
                            <AppButton onClick={saveFlowData} width="220%" backgroundColor="rgb(33, 28, 82)" text={"Save"} />
                        </div>
                    </AddScreenModel>
                </AppModel>
            </WhatsappFlowWapper>
            <AppModel width="546px" style={{ width: "100%" }} isShowing={isPublish} onClose={() => setIsPublish(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">{"Publish flow"}
                        </div>
                    </div>
                    <div>
                        <div className="leaveBodyTitle">{"Are you sure you want to publish? The flow cannot be edited after being published."}                           </div>
                    </div>
                    <div className='footer'>
                        <AppButton onClick={handleDiscard} width="220%" fontColor="black" backgroundColor="#fff" text={"Discard"} />
                        <AppButton onClick={handlePublish} width="220%" backgroundColor="rgb(33, 28, 82)" text={"Publish"} />
                    </div>
                </AddScreenModel>
            </AppModel>

            <AppModel width="750px" style={{ width: "100%" }} isShowing={testFlow} onClose={() => setTestFlow(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">{"Send flow"}
                        </div>
                    </div>
                    <div>
                        <div className="leaveBodyTitle">{"Test the flow experience by sending it to your phone number. The message will be subject to limits and pricing. Make sure that you are within the 24-hour customer service window to receive the message."}                           </div>
                    </div>
                    <div>
                        <div>
                            <div className="bodyTitle">Sender number                           
                                 </div>
                            <div style={{ padding: "7px 15px 14px",color:"#7d7373" }}>{userConfig?.payload?.whatasapp_config?.whatsapp_no || ""}</div>
                        </div>
                        <div>
                            <div className="bodyTitle">Recipient number </div>
                            <AppInput startText = "+91"  value={recipientNumber} handleChange={setRecipientNumber} characterLimit={10} placeholder="Enter your recipient number" />
                        </div>
                    </div>
                    <div className='footer'>
                        <AppButton onClick={handleDiscard} width="220%" fontColor="black" backgroundColor="#fff" text={"Discard"} />
                        <AppButton disabled={/^\d{10}$/.test(recipientNumber) ? false:true} onClick={testFlowFn} width="220%" fontColor={/^\d{10}$/.test(recipientNumber) ? "#ffff" : "#000000"} backgroundColor={/^\d{10}$/.test(recipientNumber) ? "rgb(33, 28, 82)" : "rgba(229,232,240,1)"} text={"Send"} />
                    </div>
                </AddScreenModel>
            </AppModel>
        </>
    );
};

export default HocBackdropLoader(WhatsAppWapper);
