import React, { useEffect } from "react";
import { FieldArray, getIn, useFormikContext } from "formik";
import {
  AppErrorMessage,
  AppFormError,
  AppFormField
} from "../../../../../AppComponents/Forms";
import { makeStringFirstLetterUppercase } from "../../../../../Utils";

const RenderDynamicVariableMapping: React.FC<IRenderDynamicVariableMapping> = ({
  boxTitle = "Add Sample Data",
  headerVariables = [],
  bodyVariables = [],
  footerVariables = [],
  buttonVariables = [],
  disabled = false,
}) => {
  const {
    values,
    setFieldValue,
  } = useFormikContext<any>();
  useEffect(() => {
    if (values?.body_text) {
      let data: any[] = []
      values?.body_text.map((res: any, index: any) => {
        data?.push(values?.variables_body?.[index])
      })
      setFieldValue("variables_body", data)
    }
  }, [values?.body_text])// eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (values?.header_text?.length === 0) {
      setFieldValue("variables_header", [])
    }
  }, [values?.header_text])// eslint-disable-line react-hooks/exhaustive-deps
  if (
    headerVariables?.length <= 0 &&
    bodyVariables?.length <= 0 &&
    footerVariables?.length <= 0 &&
    buttonVariables?.length <= 0
  ) {
    return null;
  }
  return (
    <div className="templateBoader">
      <div className="styledVariblesBox">
        <p className="variablesTitle">{boxTitle}</p>
        {/* RENDER DYANMIC HEADERS BASES ON TYPE */}
        <RenderSingleTypeVariables disabled={disabled} data={headerVariables} variableType="header" />
        <AppFormError name="headerSampleData" />
        <RenderSingleTypeVariables disabled={disabled} data={bodyVariables} variableType="body" />
        <AppFormError name="bodySampleData" />
        <RenderSingleTypeVariables disabled={disabled} data={footerVariables} variableType="footer" />
        <RenderSingleTypeVariables disabled={disabled} data={buttonVariables} variableType="button" />
      </div>
    </div>
  );
};

export default RenderDynamicVariableMapping;

const RenderSingleTypeVariables: React.FC<IRenderSingleTypeVariables> = ({
  data = [],
  variableType = "",
  disabled = false
}) => {
  const { values, errors, touched } = useFormikContext<any>();
  let textFieldName = getFieldName(variableType) || "";
  return (
    <>
      {data?.length > 0 ? (
        <p className="variablesType">
          {makeStringFirstLetterUppercase(variableType)} Variables
        </p>
      ) : null}

      <FieldArray name={textFieldName}>
        {({ insert, remove, push, swap, unshift, pop, replace }) => (
          <React.Fragment>
            {values &&
              textFieldName &&
              getIn(values, textFieldName)?.length > 0 &&
              getIn(values, textFieldName)?.map((item: any, index: number) => {
                const fieldName: string = `variables_${variableType}.${index}`;
                return (
                  <div className="variablesContent" key={index.toString()}>
                    <p className="variableIndex">{item as string}</p>

                    <AppFormField
                      name={fieldName}
                      placeholder="Sample Value"
                      charactersLimit={20}
                      disabled={disabled}
                    />
                  </div>
                );
              })}
          </React.Fragment>
        )}
      </FieldArray>
      <AppErrorMessage
        error={getIn(errors, textFieldName)}
        visible={getIn(touched, textFieldName)}
      />
    </>
  );
};

const getFieldName = (variableType: string) => {
  if (variableType === "header") {
    return "header_text";
  } else if (variableType === "body") {
    return "body_text";
  } else if (variableType === "footer") {
    return "footer_text";
  } else if (variableType === "button") {
    return "buttons_example";
  } else {
    return "";
  }
};
