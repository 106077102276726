import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getChatbotHeight, getChatbotWidth } from "../../../socket/utils/utils";

import { RootState } from "../..";

interface Props {
  width: number;
  height: number;
}

let INITIAL_STATE: Props = {
  width: 0,
  height: 0,
};

const DeviceSizeSlice = createSlice({
  name: "DeviceSizeSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setDeviceSize(state, { payload }: PayloadAction<Props>) {
      const wh = {
        height: getChatbotHeight(payload.width, payload?.height) + 16,
        width: getChatbotWidth(payload.width),
      };
      state.width = wh.width;
      state.height = wh.height;
    },
  },
});

export default DeviceSizeSlice.reducer;
export const { setDeviceSize } = DeviceSizeSlice.actions;

export const deviceSizeStore = (state: RootState) => state.currrentDeviceSize;;

