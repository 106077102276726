import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Box } from "@mui/material";
export const SkeletonCard = () => {
  return (
    <div
      sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
      className="tabel-heading"
    >
      <Skeleton width={80} height={35} />
    </div>
  );
};

export const SkeletonReciEmailId = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"214px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonCardEmail = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"393px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonStats = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"444px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonStatsSmsSummary = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"306px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonToplinks = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"352px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonErrorReasons = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"444px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonDoughnoutGraph = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"536px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonSmsRecipients = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"490px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonBUS = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"352px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonStatisticsEmail = () => {
  return (
    <span>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <span
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <span width="100%">
                <Skeleton width={"100%"} height={"393px"} />
              </span>
            </span>
          ))}
      </ul>
    </span>
  );
};

export const SkeletonTable = () => {
  return (
    <Box>
      <Box display="flex" sx={{ color: "#000" }} className="tabel-heading">
        <Box width="20%" p={1}>
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1}>
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1}>
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1}>
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1}>
          <Skeleton height={30} />
        </Box>
      </Box>
      <Box display="flex" sx={{ color: "#000" }} className="tabel-heading">
        <Box width="20%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
        <Box width="20%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
          <Skeleton height={30} />
        </Box>
      </Box>
    </Box>
  );
};

export const SkeletonShortTableData = () => {
  return (
    <Box>
      <Box
        display="flex"
        sx={{ color: "#000", pt: "1rem", gap: "10px" }}
        className="tabel-heading"
      >
        <Box width="50%" p={1} sx={{ textAlign: "center" }}>
          <Skeleton width={130} height={30} />
        </Box>
        <Box width="50%" p={1} sx={{ textAlign: "center" }}>
          <Skeleton width={130} height={30} />
        </Box>
      </Box>
      <Box display="flex" sx={{ color: "#000" }} className="tabel-heading">
        <Box
          width="50%"
          p={1}
          borderTop="1px solid #B8AFAF"
          sx={{ textAlign: "center" }}
        >
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
        </Box>
        <Box
          width="50%"
          p={1}
          borderTop="1px solid #B8AFAF"
          sx={{ textAlign: "center" }}
        >
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
          <Skeleton width={130} height={30} />
        </Box>
      </Box>
    </Box>
  );
};

export const SkeletonGraph = () => {
  return (
    <section>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <Box
              sx={{ pt: "0.5rem", pb: "0.5rem", color: "#000" }}
              className="tabel-heading"
              key={index}
            >
              <Box width="100%">
                <Skeleton height={350} />
              </Box>
            </Box>
          ))}
      </ul>
    </section>
  );
};

export const SkeletonSkeletonDoughnutCard = () => {
  return (
    <section>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <Box sx={{ color: "#000" }} className="tabel-heading" key={index}>
              <Box width="100%">
                <Skeleton width={80} height={25} />
              </Box>
            </Box>
          ))}
      </ul>
    </section>
  );
};

export const SkeletonCSATCard = () => {
  return (
    <section>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <Box
              sx={{
                pt: "0.5rem",
                pb: "3rem",
                color: "#000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="tabel-heading"
              key={index}
            >
              <Box
                width="100%"
                sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
              >
                <Skeleton height={10} />
                <Skeleton height={10} />
                <Skeleton height={10} />
                <Skeleton height={10} />
                <Skeleton height={10} />
              </Box>
            </Box>
          ))}
      </ul>
    </section>
  );
};

export const SkeletonSquareIconCard = () => {
  return (
    <section>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <Box
              sx={{
                color: "#000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="tabel-heading"
              key={index}
            >
              <Box width="100%">
                <Skeleton width={50} height={50} />
              </Box>
            </Box>
          ))}
      </ul>
    </section>
  );
};

export const SkeletonCircleIconCard = () => {
  return (
    <div>
      <Skeleton
        variant="circular"
        width={38}
        height={38}
        style={{ borderRadius: "50%" }}
      />
    </div>
  );
};

export const SkeletonSmallCircle = () => {
  return (
    <section>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <Box key={index}>
              <Skeleton
                variant="circular"
                width={20}
                height={20}
                style={{ borderRadius: "50%" }}
              />
            </Box>
          ))}
      </ul>
    </section>
  );
};

export const SkeletonCircleIconDoughnut = () => {
  return (
    <Skeleton
      variant="circular"
      width={"100%"}
      height={"100%"}
      style={{ borderRadius: "50%" }}
    />
  );
};

export const SkeletonProgressBar = () => {
  return (
    <section>
      <ul className="list">
        {Array(4)
          .fill()
          .map((item, index) => (
            <Box key={index} style={{margin: "2px 3px 6px"}}>
              <Skeleton width={480} height={10} key={index} />
            </Box>
          ))}
      </ul>
    </section>
  );
};

export const SkeletonLongProgressBar = () => {
  return (
    <section>
      <ul className="list">
        {Array(1)
          .fill()
          .map((item, index) => (
            <Box key={index}>
              <Skeleton width={1450} height={10} key={index} />
            </Box>
          ))}
      </ul>
    </section>
  );
};

export const SkeletonAgentDatatabel = () => {
  return (
    <Box>
      <Box display="flex" sx={{ color: "#000" }} className="tabel-heading">
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1}>
          <Skeleton width={105} height={30} />
        </Box>
      </Box>
      <Box display="flex" sx={{ color: "#000" }} className="tabel-heading">
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
        <Box width="10%" p={1} borderTop="1px solid #B8AFAF">
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
          <Skeleton width={105} height={30} />
        </Box>
      </Box>
    </Box>
  );
};
