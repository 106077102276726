// vendors
import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// builder
import { NodeContext } from "./../../flow-builder-v2/contexts";

// components
import { UIButton, Icon } from "../../ui-components";

// utils
import { getIcon } from "./utils";

type Props = {
  buttonList: any;
  onButtonClick: (buttonIndex: number) => void;
};

const ButtonPreview = (props: Props) => {
  const { buttonList, onButtonClick } = props;
  const nodeCntx = useContext(NodeContext);

  const isEmptyAction = (button: any) => {
    return button.type === ''
  };

  const renderButtonList = () => {
    return buttonList.map((button: any, index: number) => {
      const type = button?.value?.flow_type
        ? button?.value?.flow_type
        : button.type;
      const stage = button.type === "flow" ? button.value.stage : "";
      const icon = getIcon(type);
      return (
        <Box
          sx={{ mt: index === 0 ? 0 : "5px" }}
          key={`preview-button-${nodeCntx.id}-${index + 1}`}
        >
          <UIButton
            key={`preview-button-${nodeCntx.id}-${index + 1}`}
            variant="outlined"
            onClick={() => onButtonClick(index)}
            fullWidth
            color={isEmptyAction(button) ? 'warning' : 'primary'}
          >
            <Box component="span">{button.value.text}</Box>
            <Box
              component="span"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: "10px",
                marginLeft: "auto",
              }}
            >
              {stage && (
                <Typography
                  component={"span"}
                  sx={{
                    marginLeft: "10px",
                    fontStyle: "italic",
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "20px",
                    backgroundColor: "rgb(117 124 232 / 38%)",
                    color: "#3654E3",
                    p: "1px 5px",
                    borderRadius: "4px",
                  }}
                >
                  {`Stage ${stage}`}
                </Typography>
              )}
              <Icon icon={icon} size={16} />
            </Box>
          </UIButton>
        </Box>
      );
    });
  };

  return Array.isArray(buttonList) && buttonList.length > 0 ? <Box sx={{ p: "16px" }}>{renderButtonList()}</Box> : null;
};
export default ButtonPreview;
