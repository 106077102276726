import { Menu } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const AllChatPannelWrapper = styled.div`
  // box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.08), 2px 0px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  z-index: 1;
  flex-grow: 1;
  min-width: 380px;
  max-width: 380px;
  margin: 0;
  .chat-action-bar {
    border: 1px solid;
    width: 100%;
    .wrapper-action {
      display: flex;
    }
  }
  .chat-window-content {
    margin-top: 4px;
    /* padding-top: 8px; */
    padding-left: 4px;
    /* margin-right: 4px; */
    /* height: 100%; */
    overflow: hidden;
    height: calc(100vh - 200px);
  }
  .chat-window-content:hover {
    overflow: auto;
  }
  .chat-window-content::-webkit-scrollbar {
    width: 6px;
  }

  .chat-window-content::-webkit-scrollbar-track {
    background-color: rgba(236, 236, 236, 1);
    /* margin-bottom: 9rem; */
  }

  .chat-window-content::-webkit-scrollbar-thumb {
    background-color: rgba(126, 131, 146, 0.5);
    border-radius: 4px;
  }
`;

export const TopBar = styled.div`
  width: 100%;
  background-color: ${theme.palette.default.white};
  display: flex;
  align-items: center;
  padding: 0.675rem 0.675rem 0.675rem 0.675rem;
  .icon {
    width: 14%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search {
    width: 86%;
    input {
      width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #fff;
    outline: none;
    border: 1px solid transparent;
    padding: 0 1.3rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
  

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 1px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 1px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    color: ${theme.palette.default.darkGrey};
    opacity: 0.7;
  }
      background-color: rgb(245, 246, 248);
      border: 1px solid rgb(203, 205, 211);
    }
  }
`;

export const TabFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0rem 0.675rem 0rem 0.675rem;
  height: 48px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);

  .MuiBox-root {
    border-bottom: none;
  }

  .filter {
    width: 28px;
    height: 28px;
    & .MuiSvgIcon-root {
      color: ${theme.palette.default.darkGrey};
      font-size: 1.4rem;
    }
  }
`;

export const AllChatFilterMenu = styled(Menu)`
  & .MuiPaper-root {
    color: ${theme.palette.default.darkGrey};
    font-size: 12px;
  }
  & .MuiMenuItem-root {
    display: flex;
    justify-content: space-between;
  }
`;
export const StyleTabHeaderWrapper = styled.div`
  && {
    width: 100%;
    .countBox {
      margin-left: 0.5rem;
      background-color: ${theme.palette.default.lightGrey};
      border-radius: 20%;
      padding: 0.2rem 0.5rem;

      font-size: 0.85rem;
    }
  }
`;

export const StyledCircularLoaderBox = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;
    .MuiCircularProgress-root {
      height: 32px;
      width: 32px;
    }

    p {
      font-size: 14px;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
