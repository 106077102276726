import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Divider, IconButton, Link, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { AppForm, AppFormField, AppFormRadioInput, AppSubmitButton } from "../../../../AppComponents/Forms";
import { LoaderComponentProps, withLoader } from "../../../../AppComponents/Loader";
import { SettingPageMainBoxTitle } from "../../../../Customization/CommonElements";
import { theme } from "../../../../Customization/Theme";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { domainNames } from "../../../../Store/Slices/Settings/EmailSettings/emailSettings.selectors";
import { EmailActions } from "../../../../Store/Slices/email/email.actions";
import { BreadcrumbsItem, DomainDetailsWrapper } from "../Styles";
import EditBounceDomain from "./BounceDomain";
import VerifiedDomain from "./BounceDomain/VerifiedDomain";
import { EmailBounceDomain } from "./EmailValidationSchema";
import { ReactComponent as GreenCheck } from "../../../../assets/images/green-check.svg";

import { ReactComponent as CopyIcon } from "../../../../assets/images/copy.svg";

interface props extends LoaderComponentProps {
    selectedDomain: string;
    setDetailView: boolean;
}

const DomainDetails: React.FC<props> = (
    props: any) => {
    const { setLoading } = props;
    const navigate = useNavigate();
    const { showAlert } = useNotification();
    const dispatch = useDispatch<any>();
    const { isShowing, toggle } = useModal();
    const [close, setClose] = useState(false)
    const domainName = useSelector(domainNames)
    const selectedDomain = props.selectedDomain;
    const [userInput, setUserInput] = useState<any>({
        domain: "",
        selectDomainOptions: "Custom",
        CnameNameInput: "",
        CnameNameTextArea: "",
    });
    const [steps, setSteps] = useState<any>(1)
    const [localValues, setLocalValues] = useState<any>({
        selectDomainOptions: "",

    });


    // const { values, setFieldValue, errors } = useFormikContext<any>();
    const [expanded, setExpanded] = useState<string | false>('panel1');


    const [editBounceDomain, setEditBounceDomain] = useState<any>();
    const EmailDomainOptions = [
        { label: "Default", value: "Default", isDisabled: true },
        { label: "Custom", value: "Custom", isDisabled: false },
        { label: "TurnOff", value: "TurnOff", isDisabled: true },
    ];

    useEffect(() => {
        setLoading(false);
    }, [])

    const handleEditBounceDomain = () => {
        setEditBounceDomain(true);
    }
    const handleSubmit = (values: any, submitProps: any) => {
        props.setLoading(true);
        setUserInput({ ...values, CnameNameInput: values?.domain + "." + domainName, CnameNameTextArea: values?.domain + "." + domainName })
        if (steps < 3) {
            setSteps(steps + 1);
            props.setLoading(false);
        }
        submitProps.setSubmitting(false);

        if (steps == 3) {
            const data = {
                domain: values.CnameNameInput
            }
            dispatch(EmailActions.postBounceDomainVerification(data))
                .then((res: any) => {
                    if (res.payload) {
                        setClose(true);
                        toggle()
                    }
                })
                .catch((error: any) => {
                    showAlert(
                        error?.response?.data.error || "Unable to fetch data",
                        "error"
                    );
                })
                .finally(() => {
                    props.setLoading(false);
                    submitProps.setSubmitting(false);
                });
        }
    }
    const sendValuesToParent = (values: any): void => {
        setLocalValues(values);
    };
    const handleBackClick = () => {
        if (steps > 1) {
            setSteps(steps - 1);
        }
    }

    // const handleNavigate = () => {
    //     setFieldValue("view_options", EmailDomainOptions[1].value == "")
    // }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    const handleCopy = (e: any, text: any) => {
        var textArea = document.createElement("textarea");
        textArea.value = text;

        document.body.appendChild(textArea);

        textArea.select();
        e.target.parentElement.nextSibling.style.display = "inline";
        document.execCommand("Copy");
        setTimeout(function () {
            e.target.parentElement.nextSibling.style.display = "none";
        }, 1000);
        textArea.remove();
    }
    return <DomainDetailsWrapper>
        <Box pr={2}>

        

        {editBounceDomain !== true &&
            <>
                <Box className="domainDetails" mb={6}>
                    <Box className='title'>{selectedDomain?.domain}</Box>
                    <div className='checktext'><AppMaterialIcons iconName={`${selectedDomain?.spf ? "CheckCircleOutline" : "CancelOutlined"}`} style={{ color: `${selectedDomain?.spf ? "green" : "red"}` }} /> <p style={{ color: `${selectedDomain?.spf ? "green" : "red"}` }}>{selectedDomain?.spf ? "This domain is verified for sending" : "This domain is unverified"}</p></div>
                    {selectedDomain?.spf && <p>Great! your domain is verified! You can now enjoy the platform by sending your emails from any address associated with this domain. For example, you can emails from <strong>{selectedDomain?.domain}</strong></p>}
                    <div className="tasksDetails">
                        <Typography className='text-gray' mb={1}>Required</ Typography>
                        {selectedDomain?.spf ? <div className='checktext green'>
                            <AppMaterialIcons iconName="CheckCircleOutline" style={{ color: "green" }} /> <p>SPF</p>
                        </div> : (
                            <div className='checktext red'>
                                <AppMaterialIcons iconName="CancelOutlined" style={{ color: "red" }} /> <p>SPF</p>
                            </div>
                        )}

                        
                    </div>
                    <div className="tasksDetails">
                        <Typography mb={1} className='text-gray'>Recommended</Typography>
                        <div className={`checktext ${selectedDomain?.dkim ? "green" : "red"}`}><AppMaterialIcons iconName={`${selectedDomain?.dkim ? "CheckCircleOutline" : "CancelOutlined"}`} style={{ color: selectedDomain?.dkim ? "green" : "red" }} /> <p>DKIM</p></div>
                        <div className={`checktext ${selectedDomain?.dmarc ? "green" : "red"}`}>
                            <AppMaterialIcons iconName={`${selectedDomain?.dmarc ? "CheckCircleOutline" : "CancelOutlined"}`} style={{ color: selectedDomain?.dmarc ? "green" : "red" }} />
                            <p>DMARC</p></div>
                    </div>
                    <Divider sx={{maxWidth: "785px"}} />
                </Box>
                <div className="domainDetails bounceDomain">
                    <Box className="title">Bounce Domain</Box>
                    {!selectedDomain?.custombouncesdomain ? editBounceDomain ? <EditBounceDomain setEditBounceDomain={setEditBounceDomain} selectedDomain={selectedDomain}></EditBounceDomain> : <>
                        <div className='checktext'><AppMaterialIcons iconName="CancelOutlined" style={{ color: "red" }} /> 
                            <Typography fontSize={"14px"} fontWeight={400}>Bounce domain is turned off</Typography>
                        </div>
                        <Typography className='text-gray'>This domain does not use a bounce domain. System will use your "From" email address as the bounce address instead.</Typography>
                        <div className="divider"></div>
                        <div className="domainFooter">
                            <AppButton variant="grey" onClick={() => { props.setDetailView(false); navigate("/settings/channels/email/1") }}>Back</AppButton>
                            <AppButton onClick={handleEditBounceDomain}>Edit Bounce Domain</AppButton>
                        </div>
                    </> : <>{selectedDomain?.custombouncesdomain}</>}


                </div>
            </>
        }
        {editBounceDomain === true &&
            <>
                <Typography fontSize={"16px"} className='text-dark' fontWeight={500}>Bounce Domain</Typography>
                <AppForm
                    initialValues={userInput}
                    validationSchema={EmailBounceDomain}
                    onSubmit={(values: any, submitProps: any) => {
                        handleSubmit(values, submitProps);
                    }}
                >
                    <AppFormRadioInput
                        name="selectDomainOptions"
                        className="radioInput"
                        Options={EmailDomainOptions}
                        divStyle={{ display: "inline-block" }}
                        // disabled={localValues.selectDomainOptions === "Default" ? false : false}
                        sendValuesToParent={(values: any) => sendValuesToParent(values)}
                    />
                    <Box style={{ borderBottom: `1px solid ${theme.palette.default.border}`, padding: "20px 0px" }} mt={6} mb={3}>
                        {
                            localValues.selectDomainOptions === "Custom" &&
                            <Breadcrumbs aria-label="breadcrumb" separator=">">
                                <Link underline="hover" color="inherit" >
                                    <BreadcrumbsItem>
                                        <div className="icon">
                                            {/* <img src={Setup} style={{ fill: "red" }} /> */}
                                        </div>
                                        <div className="text">
                                            <p style={{ color: steps === 1 ? "blue" : "" }}>Address</p>
                                        </div>
                                    </BreadcrumbsItem>
                                </Link>

                                <Link underline="hover" color="inherit" >
                                    <BreadcrumbsItem>
                                        <div className="icon">
                                            {/* <img src={Setup} style={{ fill: "red" }} /> */}
                                        </div>
                                        <div className="text">
                                            <p style={{ color: steps === 2 ? "blue" : "" }}>Provider</p>
                                        </div>
                                    </BreadcrumbsItem>
                                </Link>

                                <Link underline="hover" color="inherit" >
                                    <BreadcrumbsItem>
                                        <div className="icon">
                                            {/* <img src={Setup} style={{ fill: "red" }} /> */}
                                        </div>
                                        <div className="text">
                                            <p style={{ color: steps === 3 ? "blue" : "" }}>DNS Settings</p>
                                        </div>
                                    </BreadcrumbsItem>
                                </Link>

                                <Link underline="hover" color="inherit" >
                                    <BreadcrumbsItem>
                                        <div className="icon">
                                            {/* <img src={Setup} style={{ fill: "red" }} /> */}
                                        </div>
                                        <div className="text">
                                            <p style={{ color: steps === 4 ? "blue" : "" }}>
                                                Success
                                            </p>
                                        </div>
                                    </BreadcrumbsItem>
                                </Link>


                            </Breadcrumbs>
                        }

                    </Box>

                    {
                        localValues.selectDomainOptions === "Custom" ?
                            <div>

                                {steps === 1 &&
                                    <div>
                                        <div className="customDomain">

                                            <Typography className='text-dark' fontSize={"15px"} fontWeight={500} mb={3}>Address for Bounce Domain</Typography>
                                            <Typography className='text-color-gray' fontSize={"14px"}>Domain</Typography>
                                            <Box className="subDiv" style={{ display: "flex" }} alignItems={"center"} gap={1}>
                                                <AppFormField
                                                    name="domain"
                                                    placeholder="Bounce Domain"
                                                    divStyle={{ width: '405px'}}
                                                />
                                                <Typography className='text-dark' mb={1} fontSize={"15px"}>@getalabs.com</Typography>
                                            </Box>
                                        </div>

                                    </div>
                                }

                                {steps === 2 &&
                                    <div className="mainDivSecond">
                                        <Typography className='text-dark' fontSize={"15px"} fontWeight={500} mb={2}>Address for Bounce Domain</Typography>
                                        <Typography className='text-dark' fontSize={"14px"} fontWeight={400} mb={2}>To verify your domain, you need to set DNS records for your domain.</Typography>

                                        <Box ml={3} mb={2}>
                                            <Box display={"flex"} alignItems={"center"} gap={1} mb={2}>
                                                <GreenCheck />
                                                <Typography className='text-color-gray' sx={{fontWeight: 400}} fontWeight={400} fontSize={"14px"}>Open A New Tab And Navigate And Log In To Platform Where Your Domain Is Hosted.</Typography>
                                             </Box>

                                             <Box display={"flex"} alignItems={"center"} gap={1} mb={2}>
                                                <GreenCheck />
                                                <Typography className='text-color-gray' sx={{fontWeight: 400}} fontWeight={400} fontSize={"14px"}>Find The Screen Where You Can Manage Your Domain.There Will Be An Option To Adjust Your DNS Settings(DNS Zone).</Typography>
                                             </Box>

                                             <Box display={"flex"} alignItems={"center"} gap={1} mb={2}>
                                                <GreenCheck />
                                                <Typography className='text-color-gray' sx={{fontWeight: 400}} fontWeight={400} fontSize={"14px"}>When Your Find It.You Are Ready For The Next Step!</Typography>
                                             </Box>
                                        </Box>

                                    </div>
                                }
                                {steps === 3 &&
                                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{
                                        "& .MuiCollapse-root": {
                                            boxShadow: "rgba(126, 131, 146, 0.24) 0px 0px 10px 0px"
                                        }
                                    }} style={{ padding: "20px 0 0 0" }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                            style={{ backgroundColor: "#E9EDFF" }}

                                        >
                                            <Typography >CNAME</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{p: 2}}>


                                        <Box display={"flex"} alignItems={"center"} gap={1} mb={2}>
                                                <GreenCheck />
                                                <Typography className='text-color-gray' sx={{fontWeight: 400}} fontWeight={400} fontSize={"14px"}>Create a new CNAME record.</Typography>
                                        </Box>

                                        <Box display={"flex"} alignItems={"flex-start"} gap={1} mb={2}>
                                                <GreenCheck />
                                                <Typography className='text-color-gray' sx={{fontWeight: 400}} fontWeight={400} fontSize={"14px"}>Fill in the Name (Host) field of this record with variable displayed below,<br />
                                                you probably have to follow one of these options.</Typography>
                                        </Box>

                                        <Box display={"flex"} alignItems={"center"} gap={1} mb={2}>
                                                <GreenCheck />
                                                <Typography className='text-color-gray' sx={{fontWeight: 400}} fontWeight={400} fontSize={"14px"}>Fill in the Value of this record with variable displayed below.</Typography>
                                        </Box>


                                            
                                                    <Box style={{ display: "flex" }}>
                                                        <AppFormField
                                                            divStyle={{ paddingTop: "15px", width: '320px' }}
                                                            name="CnameNameInput"
                                                            label="Name:"
                                                            value={`${domainName}`} readOnly
                                                        />
                                                        
                                                            <IconButton  style={{ width: "40px", height: "40px", marginLeft: "4px", marginTop: "36px" }}  onClick={(e: any) => handleCopy(e, "tracking")} ><CopyIcon /></IconButton>
                                                        
                                                        <Box id='copyone' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }} px={1}>Copied</Box>
                                                    </Box>
                                                    <div style={{ display: "flex" }}>
                                                        <AppFormField
                                                            divStyle={{ paddingTop: "15px", width: '520px' }}
                                                            label="Value"
                                                            name="CnameNameTextArea"
                                                            value={`${domainName}`} readOnly

                                                        /><IconButton style={{ width: "40px", height: "40px", marginLeft: "4px", marginTop: "36px" }} onClick={(e: any) => handleCopy(e, "api.elasticemail.com")} ><CopyIcon /></IconButton>
                                                        <Box id='copyone' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }} px={1}>Copied</Box>

                                                    </div>
                                                    <p>When you are ready, click the 'Verify record' button.</p>

                                                
                                        </AccordionDetails>
                                    </Accordion>
                                }
                                <Box mt={3} className="buttonContinue" style={{ display: "flex", }}>
                                    {steps !== 1 &&
                                        <AppButton variant="outline" onClick={handleBackClick} style={{ marginRight: "10px" }}
                                        >Back</AppButton>
                                    }
                                    {steps < 3 &&
                                        <AppSubmitButton title="Continue" />
                                    }
                                    {steps === 3 &&
                                        <AppSubmitButton title="Verify" />

                                    }
                                </Box>
                            </div>
                            : null}
                    {
                        localValues.selectDomainOptions == 'TurnOff' &&
                        <div>
                            <div>
                                <h3 style={{ border: "none" }}>Turn off bounce domain</h3>
                                <h5>
                                    This domain does not use a bounce domain. If you choose this option, you do not set any bounce domain,<br />
                                    and the system will use your "From" email address as the bounce address instead.
                                </h5>
                            </div>
                            <div className="buttonContinue">
                                <AppSubmitButton title="Save" />
                            </div>
                        </div>
                    }

                    {
                        localValues.selectDomainOptions === "Default" &&


                        <div>
                            <div className="customDomain">

                                <h3 style={{ borderBottom: "none", fontWeight: "none" }}>Default bounce domain</h3>
                                <h3 style={{ borderBottom: "none", fontWeight: "none" }}>Bounce Domain</h3>
                                <div className="subDiv">
                                    <AppFormField
                                        name="domain"
                                        placeholder="bounce Domain"
                                        divStyle={{ width: '405px', marginBottom: '1rem' }}
                                    />
                                    <h4>@getalabs.com</h4>
                                </div>
                                <div className="buttonContinue">
                                    <AppSubmitButton title="Save" />
                                </div>
                            </div>
                        </div>


                    }


                </AppForm>
            </>
        }
        {
            close === true &&
            <AppModel
                isShowing={isShowing}
                onClose={() => toggle()}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "400px",
                        height: "450px",
                    },
                }}
            >

                <VerifiedDomain setClose={setClose} setDetailView={props.setDetailView}

                />

            </AppModel>
        }

</Box>

    </DomainDetailsWrapper>


}
export default withLoader(DomainDetails);