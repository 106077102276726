import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  AppForm,
  AppFormField,
  AppFormSelectField,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import AppFormAutoSearchField from "../../AppComponents/Forms/AppFormAutoSearchField";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { ErrorTextMsg, TitleText } from "../../Customization/CommonElements";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { WorkspaceValidationSchema } from "../../Models/ValidationSchema";
import { LanguageOptions } from "../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { AppConfig } from "../../Config/app-config";
import workspaceImg from "../../assets/images/workspaceImg.svg";
import TimezonesList from "../BotBuilder/BotSettings/LiveChat/ActiveTimings/TimezonesList.json";

interface CreateWorkspaceInterface extends LoaderComponentProps {
  isFormActive: any;
  setIsFormActive: any;
  setEditData: any;
}

const CreateWorkspace = (props: CreateWorkspaceInterface) => {
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  const loggedData = AppConfig.getLoggedInData();
  const workspace_id = loggedData.data.user.workspace_id;
  const [userInput, setUserInput] = useState<any>({
    name: "",
    primary_language: "english",
    timezone: null
  });
  const [workspaceData, setWorkspaceData] = useState<any>(null);
  useEffect(() => {
    if (!workspace_id) {
      props.setLoading(false);
      navigate("/login");
    } else {
      ChatbotConsoleService.getWorkspaceById({ workspace_id: workspace_id })
        .then((response: any) => {
          setWorkspaceData(response.data);
          setUserInput({
            name: response.data?.name || "",
            primary_language: response.data?.primary_language
              ? response.data?.primary_language
              : LanguageOptions[0].value,
            timezone: response.data?.timezone ? response.data?.timezone : null,
          });
        })
        .finally(() => {
          props.setLoading(false);
        });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [apiErrorMsg, setApiErrorMsg] = useState<any>(null);

  const handleUpdateBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
    props.setLoading(true);
    ChatbotConsoleService.updateWorkspace({
      ...workspaceData,
      name: values.name,
      primary_language: values.primary_language,
      timezone: values.timezone,
      workspace_enabled: true,
    })
      .then((response) => {
        SubmitProps.setSubmitting(false);
        SubmitProps.resetForm();
        setApiErrorMsg(null);
        showAlert("Bot updated successfully", "success");
        navigate(`/workspace/${workspace_id}/setup/bots`);
      })
      .catch((error) => {
        setApiErrorMsg(error.response.statusText);
        SubmitProps.setSubmitting(false);
        showAlert(error.response.statusText, "error");
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  return (
    <StyledWrap>
      <StyledFormWrap>
        <TitleText style={{ fontWeight: 500, fontSize: "1.1rem" }}>
          {workspace_id ? "Update workspace" : "Workspace name"}{" "}
        </TitleText>
        <TitleText
          style={{ fontWeight: 500, fontSize: "1rem", color: "#7E8392" }}
        >
          A workspace is a virtual place where individuals or teams carry out
          their work activities, such as building a bot, creating automations,
          and broadcasts
        </TitleText>
        <ErrorTextMsg>{apiErrorMsg}</ErrorTextMsg>
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            workspace_id && handleUpdateBtn(values, submitProps);
            setUserInput(values);
          }}
          validationSchema={WorkspaceValidationSchema}
        >
          <AppFormField
            label="Workspace name"
            name="name"
            placeholder="Workspace 2"
          />

          <AppFormSelectField
            label="Primary Language"
            Options={LanguageOptions}
            name="primary_language"
            disabled={true}
            tooltip={
              "Our Magicians still working on support for other languages"
            }
          />

          <AppFormAutoSearchField
            label="Timezone"
            Options={TimezonesList}
            name="timezone"
          />
          <div className="btnSubmit">
            {workspace_id ? (
              <AppSubmitButton
                // variant="cta"
                style={{ width: "30%", minWidth: "210px", margin: "0.5rem 0" }}
                title={"Update Workspace"}
              />
            ) : (
              <AppSubmitButton
                style={{ width: "30%", minWidth: "210px", margin: "0.5rem 0" }}
                title={"Create Workspace"}
              />
            )}
          </div>
        </AppForm>
      </StyledFormWrap>
      <StyledFormSideImg>
        <img src={workspaceImg} alt="" />
      </StyledFormSideImg>
    </StyledWrap>
  );
};

export default withLoader(CreateWorkspace);

const StyledWrap = styled.div`
  && {
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: cen;
    padding: 1rem;
    padding-left: 2rem;
    border-radius: 8px;
    background-color: #ffffff;
    overflow: hidden;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08);
  }
`;

const StyledFormWrap = styled.div`
  && {
    width: 40%;
    height: 100%;
    min-width: 400px;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    /* box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08); */
    gap: 0.5rem;
    justify-content: center;
    .btnSubmit {
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 2rem;
    }
  }
`;

const StyledFormSideImg = styled.div`
  && {
    /* margin-left: 5%;
    width: 50%;
    height: 100%; */

    display: flex;

    justify-content: center;
    align-items: center;

    img {
      width: auto;
      height: auto;
      background: #f3f5ff;
      border-radius: 16px 0px 0px 16px;
      /* box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.08); */
    }
  }
`;
