import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import "./progress-bar.scss"
import { ReactComponent as ChatbotImg } from "../../assets/images/CloseIcon.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/images/successIcon.svg";


const { useSnackbar, VariantType } = require("notistack");
export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar()}
    >
      <CloseIcon fontSize="small" style={{ fontSize: 14 }} />
    </IconButton>
  );
  const customMessage = (message: any, variant: any) => {
    return (
      <div style={{zIndex:9999999}}>
        <div className={variant === "success" ? "success-bar" : "error-bar"}>
          {variant === "success" ? <CheckCircleIcon style={{ fontSize: 25, color: "green", margin: "-1px 6px 5px -3px" }} /> : <ChatbotImg style={{ fontSize: 25, color: "red", margin: "2px 9px 5px -3px" }} />}
          <p style={{ fontSize: "14px", fontWeight: 400, width: "190px" }}>{message}</p>
          <IconButton
            aria-label="close"
            onClick={() => { clearAlert() }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon style={{ fontSize: "16px", margin: "-5px 1px" }} />
          </IconButton>
        </div>
        <div className="container">
          <div className={variant === "success" ? "progress-striped-success" : "progress-striped-error"}>
            <div className="progress-bar">
            </div>
          </div>
        </div>
      </div>)
  }
  const showAlert = (message: string, variant: typeof VariantType) => {
    enqueueSnackbar(message, {
      variant: variant, action: action, autoHideDuration: 5000, content: customMessage(message, variant),  anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      }
    })
  };
  const clearAlert = () => {
    closeSnackbar();
  };

  return { showAlert, clearAlert } as const;
};
