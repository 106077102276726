import { useRef } from 'react'
import styled from 'styled-components'
import * as Yup from "yup"
import { AppForm, AppFormField, AppSubmitButton } from '../../../../../AppComponents/Forms'
import { theme } from '../../../../../Customization/Theme'
import { useNotification } from '../../../../../Hooks/useNotification/useNotification'
import { useAppDispatch, useAppSelector } from '../../../../../Store'
import { formSettingsStoreData } from '../../../../../Store/Slices/FormBuilder/formbuilder.selectors'
import { addSettings } from '../../../../../Store/Slices/FormBuilder/formbuilder.slice'

const validationSchema = Yup.object().shape({
    redirect_url: Yup.string()
});

const ThankYouPageSetting = (): JSX.Element => {
    const formRef: any = useRef(null)
    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();
    const formSettings: any = useAppSelector(formSettingsStoreData);
    return (
        <Container>
            <AppForm
                initialValues={{
                    redirect_url: formSettings?.redirect_url || "",
                }}
                validationSchema={validationSchema}
                innerRef={formRef}
                onSubmit={(values: any, formikProps: any) => {
                    const formBuilderObject: any = {
                        redirect_url: values.redirect_url,
                        show_thankyou_modal: values.redirect_url ? false : true,
                    }
                    showAlert("Thank you Page Config Saved Successfully", "success");
                    dispatch(addSettings(formBuilderObject))
                    formikProps.setSubmitting(false)
                }}>
                <AppFormField
                    name="redirect_url"
                    errorMessage='' label='Redirect URL'
                    defaultBorder={true}
                    labelStyle={{ color: theme.palette.default.newFontColor }}
                />
                <div style={{ width: "250px" }}>
                    <AppSubmitButton title="Save" variant={"primarydark"} />
                </div>
            </AppForm>
        </Container>
    )
}
const Container = styled.div`
&&{
    max-width: 400px;
}`
export default ThankYouPageSetting