// vendors
import { useEffect, useState } from "react";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReactPlayer from "react-player";

// @ts-ignore
import parse from "html-react-parser";
import { theme } from "../../../../Customization/Theme";

type URL = {
  url: string;
};

type MediaInfoType = {
  file: URL;
  image: URL;
  video: URL;
};

interface MessagePreviewProps {
  text: string;
  mediaDetails?: MediaInfoType;
}

const MessagePreviewStyled = styled(Box)(() => ({
  backgroundColor: theme.palette.secondary.main,
  padding: theme.spacing(1.5),
  borderRadius: "4px",
  color: theme.palette?.default?.darkGrey,
  fontSize: "14px",
  lineHeight: "21px",
  fontWeight: 400,
  wordBreak: "break-word",
  "& p": {
    margin: 0,
  },
}));

const MessagePreview = (props: MessagePreviewProps) => {
  const { mediaDetails, text } = props;
  const [mediaURL, setMediaURL] = useState("");
  const [mediaURLType, setMediaURLType] = useState("");

  useEffect(() => {
    if (mediaDetails) {
      Object.entries(mediaDetails).map((item) => {
        if (item[1]?.url !== "") {
          setMediaURL(item[1]?.url);
          setMediaURLType(item[0]);
        }
      });
    }
  }, [mediaDetails]);

  return (
    <MessagePreviewStyled>
      {mediaURLType === "image" && (
        <Box
          component="img"
          src={mediaURL}
          sx={{ width: "100%", objectFit: "cover" }}
        />
      )}
      {mediaURLType === "video" && (
        <ReactPlayer
          url={mediaURL}
          width={"100%"}
          height="230px"
          controls={true}
        />
      )}
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "21px",
          fontWeight: 400,
          color: "#7E8392",
          ...(mediaURLType !== "" && { mt: "12px" }),
        }}
        component="div"
      >
        {text !== "" && typeof text === "string" && parse(text)}
      </Typography>
    </MessagePreviewStyled>
  );
};

export default MessagePreview;
