import { RootState } from "../../../index";

// crawl urls listdata
export const CrawlLinkList = (state: RootState) =>
  state?.faqSource?.data.crawlLinkList;
// crawl urls listdata
export const crawlLinkPagination = (state: RootState) =>
  state?.faqSource?.data.crawlLinkPagination;

// Source Listing
export const SourceList = (state: RootState) =>
  state?.faqSource?.data?.sourceListing;

// Source Listing Count
export const SourceListCount = (state: RootState) =>
  state?.faqSource?.data?.sourceListingPagination;

// Source Listing Count
export const SourceTexByID = (state: RootState) =>
  state?.faqSource?.data?.sourceTextById;

// Deleted Data Source Status
export const DeletedDatasourceStatus = (state: RootState) =>
  state?.faqSource?.data?.deletedSourceData;

export const SourceSelectedIds = (state: RootState) =>
  state?.faqSource?.data?.sourceSelectedIds;

export const siteMappedUrlsData = (state: RootState) =>
  state?.faqSource?.data?.siteMappedUrlsData;
