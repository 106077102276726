import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FastField, getIn, useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import { Label } from "../../../Customization/CommonElements";
import { theme } from "../../../Customization/Theme";
import AppErrorMessage from "../AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  Options: any;
  divStyle?: any;
  row?: boolean;
  onChangeCallbackFn?: (value: any) => void;

  tooltip?: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const AppFormRadioFastField: React.FC<Props> = ({
  label,
  name,
  Options,
  divStyle,
  row = true,
  onChangeCallbackFn,
  tooltip,
  children,
  ...otherProps
}) => {
  const { errors, touched, values, handleChange } = useFormikContext<any>();

  return (
    <Div style={divStyle}>
      <FormControl style={{ width: "100%" }}>
        <Label>
          {label}{" "}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        <FastField name={name}>
          {({ field, form, meta }: any) => (
            <RadioGroup
              {...field}
              {...otherProps}
              value={getIn(values, name) || Options[0]?.value}
              defaultValue={Options[0]?.value}
              onChange={(e: any) => {
                handleChange(e);
                onChangeCallbackFn && onChangeCallbackFn(e.target.value);
              }}
              row={row}
            >
              {Options &&
                Options.length > 0 &&
                Options.map((curItem: any, index: number) => {
                  return (
                    <FormControlLabel
                      key={index.toString()}
                      value={curItem?.value}
                      control={<Radio />}
                      label={curItem?.name || curItem?.label}
                    />
                  );
                })}
            </RadioGroup>
          )}
        </FastField>

        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </FormControl>
    </Div>
  );
};

export default AppFormRadioFastField;
const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
    .MuiFormControlLabel-label {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.darkGrey};
    }
    .MuiRadio-root {
      margin: 0 0.5rem 0 0;
    }
    .MuiFormControlLabel-root {
      margin: 0 !important;
      margin-right: 1rem !important ;
    }
  }
`;
