import http from "../../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class UserNetworkService {
  // Add User
  static createUser(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.USER}`, {
      ...data?.payload,
      headers: data?.HEADERS,
    });
  }

  // User Listig
  static getUserListing(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.USER}`;
    const { limit, offset } = data;
    const params = { limit, offset };
    return http.get(url, { headers: data.HEADERS, params });
  }

  // Get User By ID
  static getUser(data: any) {
    
    const { id, HEADERS } = data;
    return http.get(`${consoleBaseUrl}${Endpoints.USER}/${id}`, {
      headers: HEADERS,
    });
  }

  // Update Label By ID
  static updateUser(data: any,id:any) {
  
    const {payload, HEADERS } = data;
    return http.put(`${consoleBaseUrl}${Endpoints.USER}/${id}`, payload, {
      headers: HEADERS,
    });
  }

  // Delete Message By ID
  static deleteUser(data: any) {
    const { id, HEADERS } = data;
    return http.delete(`${consoleBaseUrl}${Endpoints.USER}/${id}`, {
      headers: HEADERS,
    });
  }

  // Get User Roles
  static getUserRoles(data: any) {
    const { id, HEADERS } = data;
    return http.get(`${consoleBaseUrl}${Endpoints.USER_ROLES}`, {
      headers: HEADERS,
    });
  }
}

class Endpoints {
  static USER = "/workspace/user";
  static USER_ROLES = "/workspace/user/roles";
}
