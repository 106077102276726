import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";

export const StyledNotificationCard = styled.div`
  && {
    margin-bottom: 1rem;
    width: 560px;
    max-width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    overflow: hidden;
    object-fit: cover;
    position: relative;

    img {
      border-radius: 6px;
      width: 100%;
      min-height: 10rem;
      max-height: 12rem;
      object-fit: cover;
      cursor: pointer;
    }

    iframe,
    video {
      border-radius: 6px;
      width: 100%;
      object-fit: contain;
      min-height: 10rem;
      max-height: 12rem;
    }
  }

  .rightTopAction {
    button:hover {
      border-radius: 5px;
    }
    background-color:  ${theme.palette.default.white};
    padding-right: 4px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    top: 9%;
    right: 2%;
    height: 30px;
    svg {
      font-size: 30px;
      padding: 4px;
    }
    button.Mui-disabled {
      background-color: #f2f2f2;
      border-radius: 5px;
      cursor: not-allowed;
      svg {
        fill: #e1e1e1;
      }
    }
  }
`;
