import { FormHelperText, InputLabel, OutlinedInput } from "@mui/material"
import { CustomFormControlForTextArea } from "../Utils/common"

const AppPreviewTextArea = (props:any) => {
    return (
        <>
            <CustomFormControlForTextArea sx={{ width: '100%', position: "relative", marginTop: "5px" }} variant="outlined">
                <InputLabel htmlFor="component-error">{`${props?.content?.label}${!props?.content?.required ? " (optional)" : ""}`}</InputLabel>

                <OutlinedInput
                    id="outlined-adornment-weight"
                    fullWidth
                    type={props?.content['input-type']}
                    placeholder={`${props?.content?.label}${!props?.content?.required ? " (optional)" : ""}`}
                    autoComplete="off"
                    onResize={() => { }}
                    multiline
                    rows={4}
                    style={{
                        border: "1px solid rgb(126, 131, 146)",
                        height: "110px"
                    }}
                    aria-describedby="outlined-weight-helper-text"
                />
                <FormHelperText style={{ marginBottom: "-8px" }} id="outlined-weight-helper-text">{props?.content['helper-text']}</FormHelperText>
            </CustomFormControlForTextArea>
        </>
    )
}

export default AppPreviewTextArea