import { RootState } from "../../../..";

// export const searchTerm = (state: RootState) =>
//   state?.autoAssignmentRulesSettings?.data?.searchTerm;

export const contactsList = (state: RootState) =>
  state?.autoAssignmentRulesSettings?.data?.contactList;
export const contactListCount = (state: RootState) =>
  state?.autoAssignmentRulesSettings?.data?.count;
export const allTabsPagination = (state: RootState) =>
  state?.autoAssignmentRulesSettings?.data?.allTabspagination;

export const contactRulesById = (state: RootState) =>
  state?.autoAssignmentRulesSettings?.data?.contactRulesById;
