import {
  Accordion,
  AccordionSummary,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuList,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import React, { useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import {
  formatString,
  getModifiedRegexesForAccordionOptions,
} from "../../Utils";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { CSSObject, Theme, styled, useTheme } from "@mui/material/styles";
import { SidebarMenuContentWrap } from "./styles";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxShadow: `2px 0px 4px rgba(0, 0, 0, 0.08)`,
  border: "none",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  border: "none",
  boxShadow: `2px 0px 4px rgba(0, 0, 0, 0.08)`,
  width: `26px`,
  [theme.breakpoints.up("sm")]: {
    width: `26px`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",

  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      position: "absolute",
      zIndex: "1 !important",
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      position: "absolute",
      zIndex: "1 !important",
    },
  }),

  "& .drawerIcon": {
    // backgroundColor: theme.palette.primary.main,
    position: "absolute",
    zIndex: 9,
    top: "43%",
    right: 0,
    padding: "4px",
  },
}));

interface Props {
  Options: any;
}

const SidebarAccordionMenu: React.FC<Props> = ({ Options }) => {
  const navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);
  const location = useLocation();

  var [selectedIndex, setSelectedIndex] = useState<any>(0);
  let [selectedAccordion, setSelectedAccordion] = useState<any>(0);

  // Memoize Options array
  let memoizedOptions = React.useMemo(() => {
    return (
      Options?.filter((curElem: any) => {
        return curElem?.isAvailable !== false && curElem?.children?.length > 0;
      }) || []
    );
  }, [Options]);

  //this is a temporary fix to hide whatsapp menu
  const filteredSettings = memoizedOptions?.map((setting: any) => {
    if (setting.children) {
      return {
        ...setting
      };
    }
    return setting;
  });

  const getCurrentPathAfterRefresh = () => {
    if (Options?.length === 0) return;
    const MenuOptionsWithRegexes =
      getModifiedRegexesForAccordionOptions(Options);
    let SelectedMenu = MenuOptionsWithRegexes?.find((o: any, index: number) => {
      return (
        o.urlRegex.test(location.pathname) ||
        location.pathname.includes(o?.value)
      );
    });

    setSelectedIndex(SelectedMenu?.value);
    setSelectedAccordion(SelectedMenu?.categoryIndex);
  };

  useLayoutEffect(() => {
    getCurrentPathAfterRefresh();
  }, [Options, location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAccordionClick = (item: any, index: number) => {
    selectedIndex = item?.children?.[0]?.value;
    setSelectedIndex(selectedIndex);
    setSelectedAccordion(index);
    handleRedirect(item?.children?.[0]?.url);
  };

  const handleClickListItem = (item: any, child: any, index: any) => {
    selectedIndex = child?.value;
    setSelectedIndex(selectedIndex);
    handleRedirect(child?.url);
  };

  const handleRedirect = (subUrl: string) => {
    let url = subUrl;
    if (subUrl?.includes(`{0}`)) {
      url = formatString(subUrl, [currentBot.bot_id]);
    }
    navigate(url);
  };

  // export default function MiniDrawer() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <IconButton
        color="inherit"
        className="drawerIcon"
        aria-label="open drawer"
        onClick={handleDrawer}
        disableRipple
      >
        {open ? (
          <ChevronLeftIcon style={{ color: theme.palette.default.darkGrey }} />
        ) : (
          <ChevronRightIcon style={{ color: theme.palette.default.darkGrey }} />
        )}
      </IconButton>
      <SidebarMenuContentWrap style={{ display: open ? " flex" : "none" }}>
        {filteredSettings &&
          filteredSettings?.length > 0 &&
          filteredSettings?.map((item: any, index: number) => {
            if (item?.children?.length === 0) return null;

            if (item?.isAvailable) {
              return (
                <Accordion
                  key={index}
                  expanded={selectedAccordion === index ? true : false}
                >
                  <AccordionSummary
                    expandIcon={
                      <GridExpandMoreIcon
                        sx={{
                          color:
                            selectedAccordion === index
                              ? theme.palette.primary.main
                              : theme.palette.default.darkGrey,
                        }}
                      />
                    }
                    onClick={() => handleAccordionClick(item, index)}
                    sx={{
                      color:
                        selectedAccordion === index
                          ? theme.palette.primary.main
                          : theme.palette.default.darkGrey,
                    }}
                  >
                    {item?.label}
                  </AccordionSummary>

                  <MenuList variant="selectedMenu" dense>
                    {item?.children?.length > 0 &&
                      item?.children.map((curElem: any, index2: number) => {
                        if (!curElem?.isAvailable) return null;
                        return (
                          <ListItemButton
                            selected={selectedIndex === curElem?.value}
                            onClick={() => {
                              handleClickListItem(item, curElem, index);
                            }}
                            disabled={!curElem?.isAvailable}
                            key={index2}
                            sx={{
                              color:
                                selectedIndex === curElem?.value
                                  ? theme.palette.primary.main
                                  : theme.palette.default.darkGrey,
                            }}
                            disableRipple
                          >
                            {curElem?.brandLogo ? (
                              <ListItemIcon>
                                <img
                                  src={curElem?.brandLogo}
                                  alt=""
                                  style={{
                                    width: 24,
                                    height: 24,
                                    objectFit: "contain",
                                    borderRadius: "50%",
                                  }}
                                />
                              </ListItemIcon>
                            ) : null}

                            <ListItemText>{curElem?.label}</ListItemText>
                          </ListItemButton>
                        );
                      })}
                  </MenuList>
                </Accordion>
              );
            }
            return null;
          })}
      </SidebarMenuContentWrap>
    </Drawer>
  );
};

export default React.memo(SidebarAccordionMenu);
