import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";

export const TemplateListWrapper = styled.div`
  && {
    width: 100%;
    height: calc(100% - 64px);
    padding: 1rem;
  }
`;

export const TemplateTable = styled.div`
  && {
    &.custom-table {
      border: 1px solid ${theme.palette.default.border};
    }
    .table-wrapper {
      .tabel-heading {
        h6 {
          font-size: 1rem;
          font-weight: 500;
          font-family: ${theme.typography.fontFamily};
        }
      }

      .pending {
        color: ${theme.palette.default.text};
        font-weight: 600;
      }
      .rejected {
        color: ${theme.palette.default.error};
        font-weight: 600;
      }
      .success {
        color: ${theme.palette.default.success};
        font-weight: 600;
      }
      .align-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .table-head{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const ErrorComponent = styled.div`
    && {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        height: calc(100% - 48px);
        /* height: 60vh; */
        /* margin-top: 2rem; */
        img {
          max-width: 100%;
          height: auto;
        }
        .error-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .err-content {
            gap: 1rem;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
`;
