import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
export const GrahWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .time_stats {
      font-size: 18px;
      display: flex;
      font-weight: 500;
      align-items: center;
      padding: 10px 24px 10px 10px;
      border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      .div_tooltip {
        font-size: 10px;
        margin-top: 0.094rem;
      }
    }
    .header_avg {
      font-size: 14px;
      font-family: Inter;
      margin-bottom: -4px;
    }
    .progess_bar {
      width: 100%;
      display: flex;
      align-items: flex-end;
      padding: 0px 22px;
    }
    .progress_bar_text {
      font-family: Inter;
      max-width: 200px;
      min-width: 200px;
    }
    .progress_bar_count {
      width: 85%;
      font-family: Inter;
      align-items: flex-start;
      margin-left: 10px;
      .stats {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 8px;
        font-size: 14px;
      }
    }
    .graph_container {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 20px;
      font-family: Inter;
    }
    .x_axis_data {
      width: 100%;
      height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #4d4f52;
      font-family: Inter;
      margin-left: 13px;
    }
  }
`;
