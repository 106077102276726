import styled from "styled-components";
import recibackground_logo from '../../../../../assets/images/emailrecipients.png'
import { theme } from "../../../../../Customization/Theme";

export const DWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 4px;
  }
`;

export const SummaryWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .parent_div2 {
      width: 100%;
      padding: 6px;
      height: auto;
    }
    .o_div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .horizontal_line {
      height: auto;
      border: 0.2px solid gray;
      opacity: 50%;
    }
    .box-wrapper{
      width: 100%;
      height: 100%;
    }

    .box-wrap{
      width: 100%;
     height: 100%;
      // background-color: white;
      border-radius: 4px;
      //padding: 16px 20px;
    }
    
    .a_div_text {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      font-family: Inter;
    }
    .credits_btn {
      display: inline-block;
      border-radius: 38px;
      background-color: #ffffff;
      color: black;
      margin-top: 10px;
      padding: 4px 18px;
    }
    .credits_btn > p {
      font-color: black;
      font-size: 15px;
      font-weight: 500;
    }
    
    
    .chart_text_div {
      padding: 0px 40px;
      align-items: center;
      text-align: center;
    }
    .chart_text {
      font-weight: 400;
      font-family: Inter;
      font-size: 14px;
      color: #7e8392;
    }
    .chartTotalCount {
      font-size: 18px;
      font-weight: 500;
      font-family: Inter;
      text-align: center;
      color: #101010;
      padding-top: 18px;
    }
    
    
    .j_div_Link_all {
      // display: flex;
      // flex-direction: column;
      overflow: auto;
      // max-height: 17rem;
      max-height: calc(100% - 78px);
      padding-left: 8px;
      margin-right: 8px;
      padding-right: 8px;
      margin-left: 8px;
      margin-top: 8px;
    }
    .link_header {
      font-size: 25px;
      font-family: Poppins;
      color: #000000;
    }
    .date_with_link {
      width: 100%;
      // margin: auto;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      border-bottom: 1px solid ${theme.palette.default.grey};
    }
    .Date_info {
      color: #7e8392;
      font-family: Poppins;
      font-weight: 400;
      font-size: 14px;
    }
    .link_a {
      color: #5b73e8;
      text-decoration: none;
    }
    .horizontal_line {
      border: 0.4px solid #cbcdd3;
    }
    
    .b_div1_parametr {
      font-size: 18px;
      font-family: Inter;
      font-weight: 500;
    }
   
    .c_div2_icon {
      width: 48px;
      height: 48px;
    }
    .c_div2_icon > img {
      width: 100%;
    }
    .dotted_hr {
      height: auto;
      border: 0.2px dashed gray;
      opacity: 50%;
    }
    
    .link {
      align-items: center;
    }
    .link > p {
      text-align: center;
    }
  }
`;


export const WrapRecipients = styled.div`
 & {
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-image:   url(${recibackground_logo}); 
  background-position: center bottom -10px;
  background-size: cover;
  background-repeat: no-repeat;
 }
`;

export const WrapTableReci = styled.div`
 & {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 8px;
  
 }
`;

export const Wrapstatistics = styled.div`
 & {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: auto;

  .j_div_Link {
      height: inherit;
  }

  .no-data-box{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    gap:1rem;
  }

  .no-data-text{
    font-size: 18px;
    color: gray;
    text-align: center;
  }

  .no-data-image{
    width:170px;
  }

  
 }
`;


export const ApexChatWrapper = styled.div`
  && {
    height: inherit;
    // border: 1px solid red;

    .data-body {
      height: calc(100% - 61px);
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      .data-content_wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .apexcharts-toolbar {
      .exportCSV {
        border: 1px solid red !important;
        display: none !important;
      }
    }
    width: 100%;
    .heading__tooltip {
      height: 61px;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      z-index: 1;
      .heading {
        font-size: 16px;
        font-weight: 500;
      }
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }
`;



export const TextTitle = styled.p`
  && {
    font-size: 16px;
    font-wight: 500;
    position: relative;
    padding-left: 10px;
    margin-left: 10px;
    align-items: center;
    display: flex;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: linear-gradient(
        to bottom,
        rgba(132, 90, 223, 0.5) 50%,
        rgba(35, 183, 229, 0.5) 50%
      );
      border-radius: 0.5rem;
    }
  }
`;

export const Wrappertable = styled.div`
& {
    width : 100%;
    height : 100%;
    display: flex;
    flex-direction: column;

    .roww_table{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        text-align:center;
        flex: 1;
    }

    .text{
        padding:10px;
        flex:1;
        text-align:left;
        max-width:129px;
        border-right:1px solid #CBCDD3;
        display:flex;
        align-items:center;
    }

    .value{
        padding:10px;
        flex:2;
        text-align:left;
        color:#7E8392;

        white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
       padding:10px;
       display:flex;
       align-items:center;
    }

    .staightght_line{
        height:auto;
        background-color:#CBCDD3;
        opacity:50%;
    }

    .horizontal_line{
        background-color:#CBCDD3;
        opacity:50%;
        border:0.1px solid #CBCDD3;
    }
    .roww_table_last{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        text-align:center;
    }

    .info_box{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
`;

export const WrapWhatsApp = styled.div`
  & {
    width: 100%;
    height: inherit;
    // border: 4px solid green;
    background-color: #fff;
    border-radius: 4px;


    
  }
`;


export const WebsiteWrap = styled.div`
  width: 100%;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  .website-tabs .MuiTab-root {
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    color: ${theme.palette.default.darkGrey};
    margin-left: 1rem;
    text-transform: capitalize;
  }

  .website-tabs .MuiTab-root.Mui-selected,
  .website-tabs .MuiTab-root.Mui-selected:hover {
    color: ${theme.palette.default.blue};
  }
`;
export const GrahWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    height: 100%;

    .time_stats {
      font-size: 18px;
      display: flex;
      font-family: Inter;
      color: #101010;
      font-weight: 500;
      .div_tooltip {
        font-size: 15px;
      }
    }
    .header_avg {
      font-size: 16px;
      font-family: Inter;
      font-weight: 500;
      text-align: left;
    }
    .progess_bar {
      padding: 0 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: end;
      text-align: center;
      z-index: 998;
      .css-1d3ml05-MuiPopper-root-MuiTooltip-popper {
        z-index: 998;
      }
    }
    .progress_bar_text {
      width: 15%;
      font-family: Inter;
    }
    .progress_bar_count {
      width: 85%;
      font-family: Inter;
      .stats {
        display: flex;
        justify-content: end;
        padding-bottom: 0.8rem;
      }
    }
    .graph_container {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 20px;
      font-family: Inter;
    }
    .x_axis_data {
      width: 85%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #4d4f52;
      font-family: Inter;
    }
  }
`;

export const WrapperScroller = styled.div`
  overflow-x: auto;

  border-left: 1px solid ${theme.palette.default.border};
    border-right: 1px solid ${theme.palette.default.border};
    border-bottom: 1px solid ${theme.palette.default.border};

 .main_container{
    width: 100%;
 }

 .tableBody {
    background-color:#ffffff;
    height: calc(100vh - 400px);
    width:fit-content;
    min-width: 100%;
  }

  .MuiTablePagination-root {
    padding: 0 !important;
    overflow: hidden;
    height: 3rem;
    background-color: red;
    .MuiTablePagination-toolbar {
      padding: 0 !important;
    }
  }

  .MuiTablePagination-actions {
    svg {
      color: ${theme.palette.default.darkGrey} !important;
    }
  }

  .table {
}
.tableStickyHead {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #fff !important;
  border-bottom: 1px solid ${theme.palette.default.border};
  width: fit-content;
  min-width: 100%;
  // padding:20px;
}


.tablHeadRow {
    display: flex;
    flex: 1 !important;
    width: fit-content;
    background-color: #fff;
   
  }

  .tableHeadCell {
    padding:20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align:center;
    min-width: 220px;
    max-width: 220px;
    &:first-child {
      min-width: 240px;
      max-width: 240px;
      width:auto;
      text-align:center;
    }
  }

  .tableBodyCell {
     min-width: 220px;
     max-width: 220px;
    word-break: break-all;
    display: flex;
    align-items: center;
    
    padding:20px;
    justify-content: flex-start;
    
    
    &:first-child {
      min-width: 240px;
      max-width: 240px;
      width:auto;
      text-align:center;
    }
    
    
  }


  .tableBodyRow {
    display: flex;
    width: fit-content;
    min-width: 100%;
    border-bottom: 1px solid ${theme.palette.default.border};
    height:50px;
  }
  .activeRow {
    width: fit-content;
    background-color: ${theme.palette.action.selected};
  }

  .no-more-entries {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 400;
    font-size: 1rem;
    display: flex;
    justify-content: center;
  }
}`

export const CustomTabPanelContainer = styled.div``;
