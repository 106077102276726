import { useEffect, useState } from "react";
import {
  AppForm,
  AppFormFastField,
  AppFormMobileField,
  AppFormRadioField,
  AppFormTextArea,
  AppSubmitButton,
} from "../../../../AppComponents/Forms";
import { Box, Typography } from "@mui/material";
import AppButton from "../../../../AppComponents/AppButton";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import AppFormCustomRadioField from "../../../../AppComponents/Forms/AppFormCustomRadioField";
import { LinkText } from "../../../../Customization/CommonElements";
import { theme } from "../../../../Customization/Theme";
import { whatsappDataStore } from "../../../../Store/Slices/Whatsapp/whatsapp.selectors";
import { WhatsappSettingsActions } from "../../../../Store/Slices/Settings/WhatsappSettings/whatsappSettings.actions";
import { WhatsappSettingsWrapper } from "../../Email/Styles";
import { whatsappConfigList } from "../../../../Store/Slices/Settings/WhatsappSettings/whatsappSettings.selectors";
import { Link } from "react-router-dom";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { WhatsAppConfigValidations } from "../../../../Models/ValidationSchema";
import FacebookLoginButton from "../FacebookWhatsappLogin";
import styled from "styled-components";

const WhatsAppForm = (props: any) => {
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const configData = useAppSelector(whatsappConfigList);
  console.log("configData", configData);

  const [isFilled, setIsFilled] = useState<boolean>(false);
  const [userInput, setUserInput] = useState<any>({
    email: "",
    customer_company_name: "",
    company_registred_address: "",
    company_website: "",
    company_facebook_business_manage_ID: "",
    display_name: "",
    display_number: "",
    is_business_verified_on_facebook: "",
    migrating_from_other_whatsapp_api_provider: "",
    is_two_factor_authentication_removed_from_other_vendor: "",
  });

  useEffect(() => {
    dispatch(WhatsappSettingsActions.whatsappConfigList({}));
  }, [dispatch]);


  return (
    <WhatsappSettingsWrapper>
      {configData?.status === "Missing" || configData?.status === "MISSING" ? (
        <StyledIntialState>
          <FacebookLoginButton />
        </StyledIntialState>
      ) : (
        configData?.status === "Approved" || configData?.status === "APPROVED" ? <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <Box p={3} sx={{ width: "30%", border: "1px solid", borderRadius: "8px", padding: "15px" }}>
            <Typography variant="h4" gutterBottom textAlign="center">WhatsApp Configuration</Typography>
            {configData ? (
              <Box display="flex" flexDirection="column" gap={2}>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="bold">Display Name:</Typography>
                  <Typography variant="body1">{configData.display_name}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="bold">Quality:</Typography>
                  <Typography variant="body1">{configData.quality}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="bold">Status:</Typography>
                  <Typography variant="body1">{configData.status}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="bold">WABA ID:</Typography>
                  <Typography variant="body1">{configData.waba_id}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="body1" fontWeight="bold">WhatsApp No:</Typography>
                  <Typography variant="body1">{configData.whatsapp_no}</Typography>
                </Box>
              </Box>
            ) : (
              <Typography variant="body1">No configuration data available.</Typography>
            )}
          </Box>
        </Box> : <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <Box p={3} sx={{ width: "30%", border: "1px solid", borderRadius: "8px", padding: "15px",opacity: "50%" }}>
            <Typography variant="h4" gutterBottom textAlign="center" >Please wait.. we are processing your request </Typography>
          </Box>
        </Box>
      )}
    </WhatsappSettingsWrapper>
  );
};

const StyledIntialState = styled.div`
  border: 3px solid white;
  text-align: center;
  height: 100px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export default WhatsAppForm;
