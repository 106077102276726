import { createAsyncThunk } from "@reduxjs/toolkit";
import { BroadcastNetworkService } from "./BroadcastDashNetworkService";

export class broadcastActions {
  static getEmail_InsightsAll = createAsyncThunk(
    "broadcastSlice/getEmail_InsightsAll",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getBroadcastEmailInsightsData(data)
        .then((res: any) => {
          return res;
        })
        .catch((err) => {
          return rejectWithValue(err?.response);
        })
        .finally(() => {});
    }
  );

  static getAllCommunicationLogs = createAsyncThunk(
    "broadcastSlice/getAllCommunicationLogs",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getAllCommunicationLogsData(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getWhatappDetailsLogs = createAsyncThunk(
    "broadcastSlice/getWhatappDetailsLogs",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappDetailsLogs(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getBroadcastStatsData = createAsyncThunk(
    "broadcastSlice/getBroadcastStatsData",
    (id: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getBroadcastStatsByID(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getBroadcastByID = createAsyncThunk(
    "broadcastSlice/getBroadcastByID",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getBroadcastByID(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getAllCommunicationFilterSettings = createAsyncThunk(
    "broadcastSlice/getAllCommunicationFilterSettings",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getAllFilterSettings(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );


  static getCommunicationReportsList = createAsyncThunk(
    "broadcastSlice/getCommunicationReportsList",
    (params: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getCommunicationReportsList(params)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );

  static getWhastappConversationInsights = createAsyncThunk(
    "broadcastSlice/getWhastappConversationInsights",
    (params: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhastappConversationInsights(params)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    }
  );
  static getWhatsappTemplateGraphAction = createAsyncThunk(
    "broadcastSlice/getWhatsappTemplateGraphAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappTemplateGraphNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getWhatsappTotalMessagesAction = createAsyncThunk(
    "broadcastSlice/getWhatsappTotalMessagesAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappTotalMessagesNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static whatsappExportTemplateLogs = createAsyncThunk(
    "broadcastSlice/whatsappExportTemplateLogs",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.whatsappExportTemplateLogs(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getWhatsappTotalMessagesLogsAction = createAsyncThunk(
    "broadcastSlice/getWhatsappTotalMessagesLogsAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappTotalMessagesLogsNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  
  static getWhatsappConversationGraphAction = createAsyncThunk(
    "broadcastSlice/getWhatsappConversationGraphAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappConversationGraphNetwork(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getWhatsappMessageSummary = createAsyncThunk(
    "broadcastSlice/getWhatsappMessageSummary",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappMessageSummary(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static getWhatsappMessageSummaryGraph = createAsyncThunk(
    "broadcastSlice/getWhatsappMessageSummaryGraph",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappMessageSummaryGraph(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  static getWhatsappLogs = createAsyncThunk(
    "broadcastSlice/getWhatsappLogs",
    (data: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappLogs(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
  // whatsapp broadcast overview
  static getWhatsappBroadcastOverview = createAsyncThunk(
    "broadcastSlice/getWhatsappBroadcastOverview",
    (id: any, { dispatch, rejectWithValue }) => {
      return BroadcastNetworkService.getWhatsappBroadcastOverview(id)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
}
