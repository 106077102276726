import {
  Badge,
  Box,
  CircularProgress,
  IconButton,
  Tab,
  Tabs,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import AppButton from "../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import { theme } from "../../../../Customization/Theme";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../../../HOC/HocBackdropLoader";
import useDebounce from "../../../../Hooks/useDebounce";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import {
  allMessagesStore,
  removeAllMessages,
  updateUserInfoData,
} from "../../../../Store/Slices/socket/AllMessagesSlice";

import { LiveChatActions } from "../../../../Store/Slices/socket/liveChat/chat.actions";
import { LiveChatData } from "../../../../Store/Slices/socket/liveChat/chat.selectors";
import {
  TEMP_ACTIVE_FILTERS,
  appendNewSessionData,
  removeSessionData,
  setActiveChatTab,
  setChatSessionActiveFilters,
  setPageNumber,
} from "../../../../Store/Slices/socket/liveChat/chat.slice";
import { getDefaultAvatar } from "../../../../Utils";
import { StyledAppInput } from "../../../Styles/StyledForms";
import {
  colorPairs,
  getChatAppliedFiltersCount,
  getChatSessionFilterPayloadData,
  getCurrentChatTabId,
} from "../../../utils/utils";
import {
  AllChatPannelWrapper,
  StyleTabHeaderWrapper,
  StyledCircularLoaderBox,
  TabFilter,
  TopBar,
} from "./AllchatPannelStyle";
import ChatCard from "./ChatCard/ChatCard";
import ChatFiltersScreen from "./ChatFiltersScreen";
import SelectActions from "./SelectActions/SelectActions";
import { ProtectedAppUiElement } from "../../../../AppComponents/ProtectedComponents";
import { useAuthStore } from "../../../../Store/Slices/AuthSlice";
import { getHtmlTextChannelWise } from "../../../appComponents/Forms/RichEditor/WebLiveChat/utils";
import { useSocket } from "../../../hooks/socket-context";
import { SOCKET_CONNECTION_TYPES } from "../../../utils/Enum";

let audio: any = new Audio(require("../../../../assets/tong.mp3"));

const playSoundLastBotMsg = () => {
  audio.volume = 1;
  audio.play();
};

interface Props extends HocBackdropLoaderProps {}

const AllchatPannel: React.FC<Props> = ({
  setLoading: setBackdropLoading,
  isLoading: isBackdropLoading,
  // setLoadingText,
}) => {
  const dispatch = useAppDispatch();
  const { data: authData } = useAppSelector(useAuthStore);
  const { sessionId } = useAppSelector(allMessagesStore);
  const {
    allSessionsData,
    chatSessionActiveFilter,
    limit,
    offset,
    activeChatTab,
    bulkSelectedSessions,
  } = useAppSelector(LiveChatData);
  const { socket } = useSocket();
  const { userConfig } = useAppSelector(useAuthStore);

  const debouncedSearchTerm = useDebounce<string>(
    chatSessionActiveFilter?.search || "",
    1000
  );

  const [isShowMoreBtnShowing, setIsShowMoreBtnShowing] =
    useState<boolean>(true);
  const [isFilterScreenOpen, setIsFilterScreenOpen] =
    React.useState<boolean>(false);
  // Create a ref to hold the input element
  const inputRef = useRef<HTMLInputElement>(null);


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setActiveChatTab(newValue));
    dispatch(setPageNumber(0));

    const id: string =
    getCurrentChatTabId(
      newValue,
      userConfig?.payload?.allowed_permissions?.can_view_all_chats_tab === true ?? false
      ) || "mine";
    let payload = { conversation: id };

    dispatch(setChatSessionActiveFilters({ conversation: id }));

    if (typeof payload !== "object") return;

    let keys: string[] = Object.keys(payload);
    let temp: any = {};
    if (keys?.length === 1 && keys?.[0] === "conversation") {
      temp = { ...TEMP_ACTIVE_FILTERS, ...payload };
    } else {
      temp = {
        ...TEMP_ACTIVE_FILTERS,
        ...payload,
        conversation: "",
      };
    }
    getSessionsList(temp);
  };

  const handleLoadMoreButtton = () => {
    refetchSessionsList(1 + offset);
  };

  const refetchSessionsList = (page: number) => {
    let data = getChatSessionFilterPayloadData(chatSessionActiveFilter);

    let config = {
      limit: limit,
      offset: page,
      data: data,
    };

    setBackdropLoading(true);
    dispatch(LiveChatActions.appendSessionsListings(config))
      .then((res: any) => {
        if (res?.payload?.data?.data?.length > 0) {
          dispatch(setPageNumber(offset + 1));
        } else {
          console.warn("No more data to load");
          setIsShowMoreBtnShowing(false);
        }
      })
      .finally(() => {
        setBackdropLoading(false);
      });
  };

  const getSessionsList = (values?: any) => {
    let data = getChatSessionFilterPayloadData(
      values ?? chatSessionActiveFilter
    );

    let config = {
      limit: limit,
      offset: 0,
      data: data,
    };

    // inputRef?.current?.blur(); // set search input blur
    setBackdropLoading(true);
    dispatch(LiveChatActions.getSessionsListings(config)).finally(() => {
      setBackdropLoading(false);
    });
  };

  useEffect(() => {
    setIsShowMoreBtnShowing(true);
  }, [chatSessionActiveFilter]);

  useEffect(() => {
    if (
      chatSessionActiveFilter?.conversation !== "unassigned" &&
      chatSessionActiveFilter?.conversation !== "mine"
    ) {
      dispatch(setActiveChatTab(0));
    }
  }, [chatSessionActiveFilter?.conversation]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchFn = (searchTextChat: string) => {
    dispatch(removeAllMessages({}));
    activeChatTab !== 0 && dispatch(setActiveChatTab(0));
    offset !== 0 && dispatch(setPageNumber(0));

    let payload = { search: searchTextChat, conversation:"" };

    if (typeof payload !== "object") return;
    const id: string =
    getCurrentChatTabId(
      0,
      userConfig?.payload?.allowed_permissions?.can_view_all_chats_tab === true ?? false
      ) || "mine";

    let temp: any = {};
    temp = {
      ...TEMP_ACTIVE_FILTERS,
      ...payload,
      conversation: "",
    };

    if (searchTextChat?.length > 0) {
      getSessionsList(temp);
    } else if (searchTextChat?.length === 0) {
      getSessionsList({...TEMP_ACTIVE_FILTERS, conversation: id});
    }
  };

  useEffect(() => {
    handleSearchFn(chatSessionActiveFilter?.search || "");
  }, [debouncedSearchTerm]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchChange = (e: any) => {
    let searchTextChat: string = e?.target?.value || "";
    let payload = { search: searchTextChat, conversation: "" };

    if (typeof payload !== "object") return;

    if (searchTextChat?.length > 0) {
      dispatch(setChatSessionActiveFilters(payload));
    } else if (searchTextChat?.length === 0) {
      dispatch(setChatSessionActiveFilters(TEMP_ACTIVE_FILTERS));
    }
  };

  useEffect(() => {
    if (!socket) return;

    const handleUserSessions = (response: any) => {
      if (response?.error) {
        console.warn("receiveUserSessionsEvent", response?.error);
        return;
      }
      if (response?.data?.agent !== authData?.data?.user?.id) {
        dispatch(removeSessionData(response?.data));
        dispatch(removeAllMessages({}));
        return;
      }
      if (response?.data) {
        if (!response?.data?.session_id) {
          // console.warn("new session does not have session id", response?.data);
          return;
        }

        dispatch(updateUserInfoData(response?.data));
        dispatch(appendNewSessionData(response?.data));
      }

      playSoundLastBotMsg();
    };

    //NEW SESSIONS
    socket.on(SOCKET_CONNECTION_TYPES.GET_NEW_SESSIONS, handleUserSessions);

    //CLEAN UP
    return () => {
      socket.off(SOCKET_CONNECTION_TYPES.GET_NEW_SESSIONS, handleUserSessions);
    };
  }, [socket]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AllChatPannelWrapper>
      {isFilterScreenOpen ? (
        <Box sx={{ position: "relative", height: "calc(100vh - 64px)" }}>
          <ChatFiltersScreen setIsFilterScreenOpen={setIsFilterScreenOpen} />
        </Box>
      ) : (
        <Fragment>
          <TopBar>
            <div className="icon"></div>
            <div className="search">
              <input
                ref={inputRef}
                type="search"
                // value={chatSessionActiveFilter?.search || ""}
                onChange={(e: any) => handleSearchChange(e)}
                name="search"
                id="search"
                placeholder="Search"
                // style={{ backgroudColor: theme.palette.secondary.main }}
              />
            </div>
          </TopBar>
          {bulkSelectedSessions?.length > 0 ? (
            <SelectActions />
          ) : (
            <TabFilter>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={activeChatTab}
                    onChange={handleChange}
                        aria-label="basic tabs example"
                  >
                    {userConfig?.payload?.allowed_permissions?.can_view_all_chats_tab === true ? (
                      <Tab
                        style={{ flex: 1, fontSize: "13px" }}
                        label={CustomTabHeader("All", 0)}
                            {...a11yProps(0)}
                            disableRipple
                      />
                    ) : null}
                    <Tab
                      style={{ flex: 1, fontSize: "13px" }}
                      label={CustomTabHeader("You", 0)}
                          {...a11yProps(1)}
                          disableRipple
                    />
                    <Tab
                      style={{ flex: 1, fontSize: "13px" }}
                      label={CustomTabHeader("Unassigned", 0)}
                      {...a11yProps(2)}
                      disableRipple
                    />
                  </Tabs>
                </Box>
              </Box>
              <ProtectedAppUiElement moduleId="can_apply_filters">
                <IconButton
                  className="filter"
                  onClick={() => setIsFilterScreenOpen(!isFilterScreenOpen)}
                >
                  <Badge
                    badgeContent={getChatAppliedFiltersCount(
                      chatSessionActiveFilter
                    )}
                    variant="dot"
                    max={5}
                    color="primary"
                    showZero={false}
                  >
                    <AppMaterialIcons
                      iconName="FilterList"
                      style={{ fontSize: "22px" }}
                    />
                  </Badge>
                </IconButton>
              </ProtectedAppUiElement>
            </TabFilter>
          )}

          <div className="chat-window-content">
            {allSessionsData && allSessionsData?.length > 0 ? (
              allSessionsData?.map((data: any, index: number) => {
                // let itemColor = index  ;
                const colorPair = colorPairs[index % colorPairs.length]; // Calculate the color pair
                return (
                  <ChatCard
                    session_id={data?.session_id}
                    avatar={data?.avatar}
                    avatarName={getDefaultAvatar(
                      data?.user?.toString() ?? data?.name?.toString() ?? "A"
                    )}
                    avatarColor={colorPair}
                    name={data?.user ?? data?.name ?? "Anonymous"}
                    channel={data?.channel || "website"}
                    channelName={
                      data?.channel_name ?? data?.channel ?? "website"
                    }
                    status={data?.conversation_status || "Open"}
                    description={data?.session_id}
                    date={data?.date ?? data?.created_at ?? data?.createdAt}
                    unreadCount={
                      data?.session_id === sessionId ? 0 : data?.count
                    }
                    customTag={data?.label}
                    key={data?.session_id ?? index.toString()}
                    item={data}
                    botType={data?.conversation_type ?? "bot"}
                    agentName={data?.agent_info?.name}
                    last_message={
                      data?.last_message
                        ? typeof data?.last_message === "string"
                          ? getHtmlTextChannelWise(data?.last_message)
                          : "🖼️ Media"
                        : ""
                    }
                  />
                );
              })
            ) : isBackdropLoading ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                No Conversations to show
              </Box>
            )}

            {isBackdropLoading &&
            allSessionsData &&
            allSessionsData?.length > 8 ? (
              <StyledCircularLoaderBox>
                <CircularProgress size={24} />
                <p>loading your conversations...</p>
              </StyledCircularLoaderBox>
            ) : isShowMoreBtnShowing &&
              allSessionsData &&
              allSessionsData?.length > 8 ? (
              <AppButton
                variant="primary"
                style={{ margin: "1rem auto", width: "80%" }}
                onClick={() => handleLoadMoreButtton()}
              >
                Load more
              </AppButton>
            ) : null}
          </div>
        </Fragment>
      )}
    </AllChatPannelWrapper>
  );
};

export default HocBackdropLoader(AllchatPannel);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabHeader = (label: string, count: number = 0) => {
  let unReadCount: string | number = count;
  if (count > 99) {
    unReadCount = "99+";
  }
  return (
    <StyleTabHeaderWrapper>
      {label}
      {count > 0 ? <span className="countBox">{unReadCount}</span> : null}
    </StyleTabHeaderWrapper>
  );
};
