import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { Files } from "../../../../../Store/Slices/Contacts/contacts.selector";
import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";
import AppButton from "../../../../../AppComponents/AppButton";
import { useEffect, useState } from "react";

const LeadActivities = () => {
  // const { isShowing, toggle } = useModal();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const [activeValue, setActiveValue] = useState(
    location.pathname.split("/")[location.pathname.split("/").length - 1]
  );
  useEffect(() => {
    let url = `/contacts/view/${params.contact_id}/activities/${activeValue}`;
    if (location.pathname !== url) navigate(url);
  }, [activeValue]);

  const handleRedirect = (value: string) => {
    setActiveValue(value);
  };
  return (
    <ActivitiesWrapper>
      <div className="header">
        <div className="button-wrapper">
          <AppButton
            className={activeValue === "tasks" ? "active" : ""}
            onClick={() => handleRedirect("tasks")}
          >
            Tasks
          </AppButton>
          <AppButton
            className={activeValue === "notes" ? "active" : ""}
            onClick={() => handleRedirect("notes")}
          >
            Notes
          </AppButton>
        </div>
        <div className="actionWrapper"></div>
      </div>
      <div>
        <Outlet />
      </div>
    </ActivitiesWrapper>
  );
};

export default LeadActivities;

const ActivitiesWrapper = styled.div`
  background: ${theme.palette.default.white};
  margin: 0 15px;
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #7e839280;
    justify-content: space-between;
    padding: 0 15px;
  }
  .button-wrapper {
    display: flex;
    align-items: center;
    button {
      background: none;
      color: #7e8392;
      font-size: 13px;
      border: none;
      border-bottom: 2px solid transparent;
      padding: 0 20px !important;
      border-radius: 0;
      min-height: 60px;
      &:hover,
      &.active {
        color: ${theme.palette.primary.main};
        font-weight: 500;
        border-color: ${theme.palette.primary.main};
      }
    }
  }
  .actionWrapper {
    .action-btn {
      svg path {
        fill: ${theme.palette.primary.main};
      }
    }
  }
`;
