// import styled from "styled-components";
import { addYears, isSameDay } from "date-fns";
import { addDays } from "date-fns/esm";
import moment from "moment";
import { DateRange, DateRangePicker } from "mui-daterange-picker";
import React, { useEffect, useRef, useState } from "react";
import AppButton from "./AppButton";
import AppIcon from "./AppIcon";

import styled from "@emotion/styled";
import { Backdrop } from "@mui/material";
import { css } from "styled-components";
import { theme } from "../Customization/Theme";
import { DateRangeOptions, DefinedCustomFutureDates } from "../Models/defaultSelectOptions";
import { StyledBox, StyledDatePickerWrapper, StyledTextField, StyledDateField, ActionBox } from '../Customization/CommonElements'

const defaultRanges = [
  {
    label: "Today",
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    label: "Tomorrow",
    startDate: addDays(new Date(Date.now()), 1),
    endDate: addDays(new Date(Date.now()), 1)
  },
  {
    label: "Next 7 Days",
    startDate: DefinedCustomFutureDates?.next7Days?.startDate,
    endDate: DefinedCustomFutureDates?.next7Days?.endDate,
  },
  {
    label: "Next 30 Days",
    startDate: DefinedCustomFutureDates?.next30Days?.startDate,
    endDate: DefinedCustomFutureDates?.next30Days?.endDate,
  },
  {
    label: "Next 90 Days",
    startDate: DefinedCustomFutureDates?.next90Days?.startDate,
    endDate: DefinedCustomFutureDates?.next90Days?.endDate,
  }
];


const defaultRangesWithCustomDate = [
  {
    label: "Today",
    startDate: new Date(Date.now()),
    endDate: new Date(Date.now()),
  },
  {
    label: "Tomorrow",
    startDate: addDays(new Date(Date.now()), 1),
    endDate: addDays(new Date(Date.now()), 1)
  },
  {
    label: "Next 7 Days",
    startDate: DefinedCustomFutureDates?.next7Days?.startDate,
    endDate: DefinedCustomFutureDates?.next7Days?.endDate,
  },
  {
    label: "Next 30 Days",
    startDate: DefinedCustomFutureDates?.next30Days?.startDate,
    endDate: DefinedCustomFutureDates?.next30Days?.endDate,
  },
  {
    label: "Next 90 Days",
    startDate: DefinedCustomFutureDates?.next90Days?.startDate,
    endDate: DefinedCustomFutureDates?.next90Days?.endDate,
  },
  {
  label: "Custom",
    startDate: DefinedCustomFutureDates?.custom?.startDate,
    endDate: DefinedCustomFutureDates?.custom?.endDate,
  },
];

interface Props {
  OPENING_SIDE?: string;
  OPENING_POSITION?: string;
  dateRange: any;
  setDateRange: any;
  divStyle?: any;
  reset?: boolean;
  dateFieldStyle?: any;
  isAllowCustomDate ? :boolean;
  customIcon?: React.ReactNode;
}
const AppRangeFutureDatePicker: React.FC<Props> = ({
  dateRange,
  setDateRange,
  divStyle,
  reset,
  dateFieldStyle,
  customIcon,
  isAllowCustomDate
}) => {
  const myRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [tempDateRange, setTempDateRange] = useState<DateRange| null>(dateRange);
  const [OPENING_SIDE, setOPENING_SIDE] = useState<string>("Left");
  const [OPENING_POSITION, setOPENING_POSITION] = useState<string>("Bottom");
  const [selectedInputLabel, setSelectedInputLabel] = useState<string | number>(0);
  const [showRestButton, setResetButton] = useState<any>(false);
  const toggle = () => setOpen(!open);

  const handleCancelBtn = () => {
    setTempDateRange(dateRange);
    toggle();
  };
  const handleApplyBtn = () => {
    setDateRange(tempDateRange);
    toggle();
  };

  const handleResetBtn = () => {
    setDateRange({startDate: "", endDate: ""});
    setTempDateRange(null);
    setSelectedInputLabel(0);
    toggle();
  };
  
  useEffect(() => {
    const x = myRef?.current?.offsetLeft;
    const y = myRef?.current?.offsetTop;
    let tempSide = window.innerWidth / 2 - x > 0;
    let tempPosition = window.innerHeight / 2 - y > 0;
    if (tempSide) {
      setOPENING_SIDE("Right");
    } else {
      setOPENING_SIDE("Left");
    }
    if (tempPosition) {
      setOPENING_POSITION("Bottom");
    } else {
      setOPENING_POSITION("Top");
    }
  }, [myRef?.current, open]);

  const checkLabelActive = (tempDate: any) => {
    const result: any =
      DateRangeOptions &&
      DateRangeOptions?.length > 0 &&
      DateRangeOptions?.map((curItem: any, index) => {
        if (
          isSameDay(curItem?.range?.startDate, tempDate?.startDate) &&
          isSameDay(curItem?.range?.endDate, tempDate?.endDate)
        ) {
          setSelectedInputLabel(curItem?.value || 0);
          return "found";
        } else {
          return "notFound";
        }
      });
    if (!result?.includes("found")) {
      setSelectedInputLabel(0);
    }
  };

  const handleLabelRangeSelection = (selectedValue: string | number) => {
    if (!selectedValue) {
      return;
    }

    let selectedLabel: any = DateRangeOptions.find(
      (item) => item?.value === selectedValue
    );
    if (selectedLabel) {
      setTempDateRange(selectedLabel?.range)

    }
  };

  useEffect(() => {
    if(reset){
      setResetButton(true)
    }

    if (selectedInputLabel) {
      handleLabelRangeSelection(selectedInputLabel);
    }
  }, [selectedInputLabel]);

  useEffect(() => {

    if (tempDateRange) {
      checkLabelActive(tempDateRange);
    }
  }, []);
  return (
    <StyledBox ref={myRef} style={divStyle}>
      <StyledDateField onClick={toggle}>
        <StyledTextField style={dateFieldStyle}>
          {customIcon ? <> {customIcon}</> :
          <>
           {dateRange?.startDate ? moment(dateRange?.startDate).format("L") : "Start Date"} {" "}
          <AppIcon
            title={"ri-arrow-right-line"}
            size="16px"
            color={`${theme.palette.default.lighterBlack}`}
          />
          {dateRange?.endDate ? moment(dateRange?.endDate).format("L") : "End Date"}</> }
        </StyledTextField>
      </StyledDateField>

      {open && (
        <StyledDatePickerWrapper
          OPENING_SIDE={OPENING_SIDE}
          OPENING_POSITION={OPENING_POSITION}
        >

          <DateRangePicker
            open={open}
            toggle={toggle}
            onChange={(range: any) => { setTempDateRange(range); checkLabelActive(range) }}
            closeOnClickOutside
            initialDateRange={tempDateRange ? tempDateRange : undefined}
            // maxDate={new Date(Date.now())}
            // minDate={addYears(new Date(Date.now()), -2)}
            definedRanges={isAllowCustomDate ? defaultRangesWithCustomDate :defaultRanges}
            wrapperClassName={"styledDate"}

          />
          <ActionBox>
          <div className="leftBtnBox">
            {
              showRestButton ? 
              <AppButton
                  variant="outline"
                  className={"applyBtn"}
                  style={{ width: "80px" }}
                  onClick={() => {
                    handleResetBtn();
                  }}
                >
                  Reset
                </AppButton> : ""
            }
              </div>
            <div className="rightBtnBox">

              <AppButton
                // variant="outline"
                className={"applyBtn"}
                style={{ width: "80px" }}
                onClick={() => {
                  handleCancelBtn();
                }}
              >
                Cancel
              </AppButton>
              <AppButton
                variant="outline"
                className={"applyBtn"}
                style={{ width: "90px" }}
                onClick={() => {
                  handleApplyBtn();
                }}
              >
                Apply
              </AppButton>

            </div>

          </ActionBox>
          <Backdrop
            sx={{
              color: `${theme.palette.default.white}`,
              zIndex: (theme) => theme.zIndex.drawer + 1,
              overflow: "auto",
            }}
            open={open}
            onClick={toggle}
          ></Backdrop>
        </StyledDatePickerWrapper>
      )}
    </StyledBox>
  );
};

export default AppRangeFutureDatePicker;


