import React, { useLayoutEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  allMessagesStore,
  toggleInputBox,
} from "../../Store/Slices/socket/AllMessagesSlice";
import AppNoInputBox from "../appComponents/UtilsComponents/AppNoInputBox";
import { INPUT_TYPES } from "../utils/Enum";
import AppInputHandler from "./AppInputHandler";
import AppReplyScreen from "./AppReplyScreen";
import { ProtectedAppUiElement } from "../../AppComponents/ProtectedComponents";
import WhatsappChooseTemplateBtn from "../appComponents/Forms/RichEditor/WebLiveChat/WhatsappChooseTemplate/WhatsappChooseTemplateBtn";

interface Props {}

const InputBoxScreen: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const {
    lastInputBoxType,
    userInfoData,
    showAttachedReplyItem,
    attachedReplyId,
  } = useAppSelector(allMessagesStore);

  useLayoutEffect(() => {
    dispatch(toggleInputBox(userInfoData?.editor_status ?? false));
  }, [lastInputBoxType, userInfoData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (lastInputBoxType === INPUT_TYPES.NONE) {
    return null;
  }
  
  if(userInfoData?.channel === "whatsapp" && !userInfoData?.whatsapp_window && userInfoData?.conversation_status === "Closed"){
    return <WhatsappChooseTemplateBtn />
  }

  if (!userInfoData?.editor_status) {
    return <AppNoInputBox message={userInfoData?.editor_status_description} rating={userInfoData?.rating} feedback={userInfoData?.feedback}/>;
  }
  if (userInfoData?.conversation_type === "livechat" && userInfoData?.editor_status && !userInfoData?.editor_status_description) {
    return (
        <ProtectedAppUiElement moduleId="live_chat_agent">
        {showAttachedReplyItem && attachedReplyId ? <AppReplyScreen /> : null}
        <AppInputHandler />
      </ProtectedAppUiElement>
    );
  }

  return <AppNoInputBox message={userInfoData?.editor_status_description ?? "You do not have valid permissions"} rating={userInfoData?.rating} feedback={userInfoData?.feedback} />;
};

export default InputBoxScreen;
