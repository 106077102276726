import styled from "styled-components";
import recibackground_logo from "../../../assets/images/emailrecipients.png";
import { theme } from "../../../Customization/Theme";

export const DWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: white;
    border-radius: 4px;
  }
`;

export const SummaryWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .parent_div2 {
      width: 100%;
      padding: 6px;
      height: auto;
    }
    .o_div {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .horizontal_line {
      height: auto;
      border: 0.2px solid gray;
      opacity: 50%;
    }
    .box-wrapper{
      width: 100%;
      height: 100%;
    }

    .box-wrap{
      width: 100%;
     height: 100%;
      // background-color: white;
      border-radius: 4px;
      //padding: 16px 20px;
    }
    
    .a_div_text {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      font-family: Inter;
    }
    .credits_btn {
      display: inline-block;
      border-radius: 38px;
      background-color: #ffffff;
      color: black;
      margin-top: 10px;
      padding: 4px 18px;
    }
    .credits_btn > p {
      font-color: black;
      font-size: 15px;
      font-weight: 500;
    }
    
    
    .chart_text_div {
      padding: 0px 40px;
      align-items: center;
      text-align: center;
    }
    .chart_text {
      font-weight: 400;
      font-family: Inter;
      font-size: 14px;
      color: #7e8392;
    }
    .chartTotalCount {
      font-size: 18px;
      font-weight: 500;
      font-family: Inter;
      text-align: center;
      color: #101010;
      padding-top: 18px;
    }
    
    
    .j_div_Link_all {
      // display: flex;
      // flex-direction: column;
      overflow: auto;
      // max-height: 17rem;
      max-height: calc(100% - 78px);
      padding-left: 8px;
      margin-right: 8px;
      padding-right: 8px;
      margin-left: 8px;
      margin-top: 8px;
    }
    .link_header {
      font-size: 25px;
      font-family: Poppins;
      color: #000000;
    }
    .date_with_link {
      width: 100%;
      // margin: auto;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      align-items: center;
      border-bottom: 1px solid ${theme.palette.default.grey};
    }
    .Date_info {
      color: #7e8392;
      font-family: Poppins;
      font-weight: 400;
      font-size: 14px;
    }
    .link_a {
      color: #5b73e8;
      text-decoration: none;
    }
    .horizontal_line {
      border: 0.4px solid #cbcdd3;
    }
    
    .b_div1_parametr {
      font-size: 18px;
      font-family: Inter;
      font-weight: 500;
    }
   
    .c_div2_icon {
      width: 48px;
      height: 48px;
    }
    .c_div2_icon > img {
      width: 100%;
    }
    .dotted_hr {
      height: auto;
      border: 0.2px dashed gray;
      opacity: 50%;
    }
    
    .link {
      align-items: center;
    }
    .link > p {
      text-align: center;
    }
  }
`;


export const WrapRecipients = styled.div`
 & {
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 4px;
  background-image:   url(${recibackground_logo}); 
  background-position: center bottom -10px;
  background-size: cover;
  background-repeat: no-repeat;
 }
`;

export const WrapTableReci = styled.div`
 & {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 8px;
  
 }
`;

export const Wrapstatistics = styled.div`
 & {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: auto;

  .j_div_Link {
      height: inherit;
  }

  .no-data-box{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    gap:1rem;
  }

  .no-data-text{
    font-size: 18px;
    color: gray;
    text-align: center;
  }

  .no-data-image{
    width:170px;
  }

  
 }
`;





