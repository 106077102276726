// vendors
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";

// components
import Icon from "./../icon";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

interface INodeType {
  icon?: string;
  text: string;
  color?: string;
  secondaryText?: string;
  noPadding?: boolean;
  secondaryAction?: any;
  actionCallback?: any;
  isChecked?: boolean;
}

const NodeType = (props: INodeType) => {
  const {
    icon,
    text,
    color,
    secondaryText,
    noPadding,
    secondaryAction,
    actionCallback,
    isChecked,
  } = props;
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent={"space-between"}
      sx={{ p: noPadding ? 0 : "12px 16px" }}
    >
      <Stack flexDirection="row" alignItems="center">
        {icon && (
          <Box
            sx={{
              height: 32,
              width: 32,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
              mr: "12px",
              ...(color && {
                backgroundColor: color,
              }),
            }}
          >
            <Icon icon={icon} size={16} color="#101010" />
          </Box>
        )}
        <Typography
          sx={{ color: "#101010" }}
          fontSize="16px"
          fontWeight={500}
          lineHeight="24px"
        >
          {text}
        </Typography>
      </Stack>
      {secondaryAction && (
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <Checkbox onChange={actionCallback} defaultChecked={isChecked} />
          }
          label="Verify Otp?"
        />
      )}
      {secondaryText && (
        <Typography
          sx={{ color: "#7E8392" }}
          fontSize="14px"
          fontWeight={500}
          lineHeight="21px"
        >
          {secondaryText}
        </Typography>
      )}
    </Stack>
  );
};
export default NodeType;
