// vendors
import { useContext, useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import useOnClickOutside from "use-onclickoutside";
import Checkbox from "@mui/material/Checkbox";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

// builder
import { NodeContext, BuilderContext } from "../../flow-builder-v2/contexts";
import { getNodeConfig } from "./../../flow-builder-v2/utils";

// hooks
import { useAction } from "./../../flow-builder-v2/hooks";

// components
import { NodeType, Editor, MessagePreview } from "./../../ui-components";
import Card from "../card";
import Action from "../action";

// utils
import {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
} from "./../utils/editor";
import { getText, getResponse, getQuickAction } from "./../utils/helper";
import { Typography } from "@mui/material";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import React from "react";

type FileType = { type: string; extensions: Array<{ label: string, value: string }>, isChecked: boolean };

const ImgFileNode = (props: any) => {
  const builderContext = useContext(BuilderContext);
  const nodeCntx = useContext(NodeContext);
  const { updateNode, removeNode } = useAction();
  const { setActiveNode, activeNode, language } = builderContext;
  const response = getResponse(nodeCntx, language);
  const text = getText(response);
  const [message, setMessage] = useState(text || "");
  const [showPreview, setPreview] = useState<boolean>(true);
  const [mediaDetails, setMediaDetails] = useState<any>(
    nodeCntx.mediaDetails || ""
  );
  const [nodeConfig, setNodeConfig] = useState<any>(null);
  const wrapperRef = useRef(null);
  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || "")
  );
  const [isOutsideClicked, setOutsideClicked] = useState<boolean>(false);
  const [userInputQuery, updateUserInputQuery] = useState<boolean>(
    getQuickAction(nodeCntx.quickActions, "user-query") || false
  );
  const node = getNodeConfig(nodeCntx.subType || nodeCntx.type);
  const actions = nodeCntx?.actions || [];
  const [selected, setSelected] = useState<FileType[]>(
    cloneDeep(nodeCntx.allowedFileTypes) || []
  );

  useOnClickOutside(wrapperRef, (event) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }
    setOutsideClicked(true);

    if (!showPreview) {
      const previousPlainText = response
        ? getPlainTextData(getInitialEditiorText(getText(response)))
        : "";
      const plainText = getPlainTextData(editorState);

      if (plainText === "" || selected.length === 0) return;

      if (nodeCntx.id && !showPreview) {
        if (
          plainText !== previousPlainText ||
          !isEqual(nodeCntx.allowedFileTypes, selected) ||
          getQuickAction(nodeCntx.quickActions, "user-query") !== userInputQuery
        ) {
          updateNode(
            nodeCntx.nodeId,
            message,
            mediaDetails,
            {
              allowedFileTypes: selected,
            },
            false,
            {
              type: "user-query",
              enabled: userInputQuery,
            }
          );
        }
      }
      setPreview(true);
      // setActiveNode(null);
    }
  });

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setMessage(getHTMLContent(editorState));
    setEditorState(editorState);
    setMediaDetails(mediaInfo);
  };

  const handleBlur = () => {
    setMessage(getHTMLContent(editorState));
  };

  const togglePreview = () => {
    setPreview(!showPreview);
    setActiveNode(nodeCntx.nodeId || nodeCntx.id);
  };

  const handleCancelEditing = () => {
    if (nodeCntx?.nodeId) {
      const plainText = getPlainTextData(editorState);
      setOutsideClicked(true);
      if (plainText !== '') {
        setActiveNode(null);
        togglePreview();
        return;
      }
    } else {
      togglePreview();
      removeNode();
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    options: Array<{ label: string, value: string }>
  ) => {
    const value = event.target.value;
    if (selected === null) {
      setSelected([{ type: value, extensions: options, isChecked: true }]);
      return;
    }

    if (selected !== null) {
      let list = [...selected.filter((ele: any) => ele.type != value)];
      let tempList = selected.filter((ele: any) => ele.type === value);
      if (event.target.checked) {
        if (tempList.length) {
          tempList[0].extensions = options;
        } else {
          tempList = [{ type: value, extensions: options, isChecked: true }];
        }
        list = [...list, ...tempList]

        setSelected([...list]);
      } else {
        const filterCategory = list.filter(
          (item: FileType) => item.type !== value
        );
        setSelected([...filterCategory]);
      }
    }
  };

  const handleChangeChild = (
    value: { label: string, value: string },
    parentCategory: string
  ) => {
    if (selected === null) {
      setSelected([{ type: parentCategory, extensions: [value], isChecked: false }]);
      return;
    }

    if (selected !== null) {
      const list = [...selected];
      const filterCategory = list.filter(
        (item: FileType) => item.type === parentCategory
      );

      const objIndex = list.findIndex(
        (item: FileType) => item.type === parentCategory
      );

      if (objIndex === -1) {
        setSelected([
          ...selected,
          ...[{ type: parentCategory, extensions: [value], isChecked: false }],
        ]);
      } else {
        const currentCategory = [...filterCategory[0].extensions];
        const index = currentCategory.findIndex((ele: any) => ele.value === value.value);
        index === -1
          ? currentCategory.push(value)
          : currentCategory.splice(index, 1);
        list[objIndex].extensions = currentCategory;
        setSelected(list);
      }
    }
  };

  const isCategorySelected = (parentCategory: string) => {
    var nodeData = [...cloneDeep(node.allowedFiles)];
    if (selected !== null) {
      const list = [...selected];
      const filterCategory = list.filter(
        (item: FileType) => item.type === parentCategory
      );
      const listCategory = nodeData.filter(
        (item: { name: string, supportedExtensions: Array<any> }) => item.name === parentCategory
      );
      return filterCategory.length > 0 && filterCategory?.[0]?.extensions?.length === listCategory?.[0]?.supportedExtensions?.length
        ;
    }
    return false;
  };

  const isSubCategorySelected = (
    parentCategory: string,
    subCategory: string
  ) => {
    if (selected !== null) {
      const list = [...selected];
      const filterCategory = list.filter(
        (item: FileType) => item.type === parentCategory
      );
      if (filterCategory.length > 0) {
        const subCategories = [...filterCategory[0].extensions];
        return subCategories.length > 0 && subCategories.filter((ele: { label: string, value: string }) => ele.value === subCategory)?.length > 0;
      }
      return false;
    }
    return false;
  };

  const handleUserInputQueryChange = (checked: boolean) => {
    updateUserInputQuery(checked);
  };

  useEffect(() => {
    if (nodeCntx.id && activeNode !== null && activeNode === nodeCntx.id) {
      setPreview(false);
      setNodeConfig(getNodeConfig(nodeCntx.type));
      return;
    }

    if (nodeCntx?.nodeId) {
      const text = getText(response);
      setEditorState(getInitialEditiorText(text));
      setNodeConfig(getNodeConfig(nodeCntx.subType));
      setMessage(text);
    }
  }, [activeNode]);

  useEffect(() => {
    const value = response ? response[0].value : "";
    setMessage(value);
  }, [language]);

  useEffect(() => {
    setOutsideClicked(false);
  }, []);

  return (
    <Box
      id={props.id}
      {...(((activeNode !== null && activeNode === nodeCntx.id) ||
        activeNode === nodeCntx.nodeId) && { ref: wrapperRef })}
    >
      <Card
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
        onUserInputQueryChange={handleUserInputQueryChange}
        userInputQuery={userInputQuery}
      >
        <Box
          sx={{ p: "12px 16px" }}
          onClick={(e) => {
            e.stopPropagation();
            togglePreview();
          }}
        >
          {!showPreview ? (
            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onBlur={handleBlur}
              mediaInfo={mediaDetails}
              error={isOutsideClicked && getPlainTextData(editorState) === ""}
            />
          ) : (
            <MessagePreview mediaDetails={mediaDetails} text={message} />
          )}
        </Box>
        <Divider />
        {node !== null && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '16px' }}>
            <NodeType text={node.name} color={node.color} icon={node.icon} />
            <AppToolTip
              tooltipProps={{
                title:
                  "Please ensure that you select an appropriate file format that is allowed for upload by referring to the list of permitted file types.",
                placement: 'top',
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={<HelpOutlineOutlinedIcon sx={{ color: '#7E8392', fontSize: "20px" }} />}
            />
          </div>
        )}
        {!showPreview && (
          <Box sx={{ p: "12px 16px" }}>
            {node.allowedFiles.map(
              (
                allowedFile: { name: string; supportedExtensions: Array<{ label: string, value: string }> },
                index: number
              ) => {
                const sup_extensions =
                  allowedFile.supportedExtensions.length > 0
                    ? allowedFile.supportedExtensions
                    : [];
                return (
                  <Box key={`img-file-${allowedFile.name}-${index + 1}`}>
                    <Box display="flex" sx={{ justifyContent: 'space-between', alignItems: 'center', background: '#F5F6F8' }}>
                      <Typography variant="subtitle1" gutterBottom sx={{ margin: 0, padding: '0.7rem' }}>
                        {allowedFile.name === 'Images' ? 'Images' : 'Documents'}
                      </Typography>
                      <Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disableRipple
                              value={allowedFile.name}
                              onChange={(event) =>
                                handleChange(event, allowedFile.supportedExtensions)
                              }
                              checked={selected?.filter((fileType: any) => fileType.type === allowedFile.name)?.[0]?.extensions?.length === sup_extensions.length}
                              sx={{
                                margin: 0,
                                "&.MuiButtonBase-root": {
                                  padding: "0 !important",
                                },
                                '& .MuiSvgIcon-root': {
                                  width: '1.3rem', // Replace 'your_desired_width' with the desired value, e.g. '40px'
                                  height: 'auto',
                                },
                              }}
                            />
                          }
                          label={'Select All'}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "16px",
                              lineHeight: "21px",
                              fontWeight: 400,
                              color: "#7E8392",
                              pl: "8px",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                    <Box className="checkRow" sx={{
                      pl: '0.7rem',
                      mt: "0.9rem",
                      mb: '0rem',
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap'

                    }}>
                      {sup_extensions.map(
                        (sup_extension: { label: string, value: string }, sEIndex: number) => {
                          return (
                            <Box
                              key={`img-file-${sup_extension}-${sEIndex + 1}`}
                              sx={{
                                mb: '1.7rem',
                                marginLeft: '0.3rem',
                                minWidth: 'calc(33.33% - 0.3rem)',
                                boxSizing: 'border-box',

                              }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disableRipple
                                    value={sup_extension.value}
                                    onChange={() =>
                                      handleChangeChild(sup_extension, allowedFile.name)
                                    }
                                    checked={isSubCategorySelected(
                                      allowedFile.name,
                                      sup_extension.value
                                    ) || selected?.filter((fileType: any) => fileType.type === allowedFile.name)?.[0]?.extensions?.length === allowedFile.supportedExtensions?.length}

                                    sx={{
                                      "&.MuiButtonBase-root": {
                                        padding: "0 !important",
                                        "svg": {
                                          color: 'red'
                                        }
                                      },
                                      '&.MuiCheckbox-root': {
                                        // Change the border color when not selected
                                        '& .MuiSvgIcon-root': {
                                          stroke: 'borderColor',
                                          // strokeWidth: 2,
                                          borderRadius: 2,
                                          fill: '#7E8392',
                                        },
                                      },
                                      '&.Mui-checked': {
                                        // Change the border color when selected (checked)
                                        '& .MuiSvgIcon-root': {
                                          // stroke: '#fff',
                                          // strokeWidth: 2,
                                          borderRadius: 2,
                                          fill: '#3654E3',
                                        },
                                      },
                                      '& .MuiSvgIcon-root': {
                                        width: '1.3rem', // Replace 'your_desired_width' with the desired value, e.g. '40px'
                                        height: 'auto',
                                      },
                                    }}

                                  // sx={{
                                  //   "&.MuiButtonBase-root": {
                                  //     padding: "0 !important",
                                  //     "svg": {
                                  //       color: 'red'
                                  //     }
                                  //   },
                                  // }}
                                  />
                                }
                                label={sup_extension.label}
                                sx={{
                                  ml: 0,
                                  "& .MuiFormControlLabel-label": {
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    fontWeight: 400,
                                    color: "#7E8392",
                                    pl: "8px",
                                  },
                                }}
                              />
                            </Box>
                          )
                        }
                      )}

                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
        )
        }
        {
          userInputQuery && showPreview && (
            <>
              <Divider />
              <Box
                sx={{ p: "12px 16px" }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Typography
                  sx={{
                    display: "inline-block",
                    p: "4px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontWeight: 400,
                    color: "#7E8392",
                    backgroundColor: "#F5F6F8",
                  }}
                >
                  User input query
                </Typography>
              </Box>
            </>
          )
        }
      </Card >
      <Action actions={actions} nodeContext={nodeCntx} />
    </Box >
  );
};
export default ImgFileNode;
