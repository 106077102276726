import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class EmailSettingsService {
  // Enable Channel Settings
  static enableEmailChannel() {
    return http.post(`${consoleBaseUrl}${Endpoints.ENABLE_EMAIL_CHANNEL}`);
  }
  // Validate Email Settings
  static validateEmailList() {
    return http.get(`${consoleBaseUrl}${Endpoints.VALIDATE_EMAIL_SETTINGS}`);
  }
  // Validate Email Settings
  static getValidEmailLists() {
    return http.get(`${consoleBaseUrl}${Endpoints.VALID_EMAIL_LIST}`);
  }
  // Get address lists
  static getAddressesList() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_ADDRESS_LIST}`);
  }
  // Get Domain lists
  static getDomainLists() {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_DOMAIN_LIST}`);
  }
  // Validate Email Settings
  static updateProfile(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.UPDATE_EMAIL_PROFILE}`,
      data,
    );
  }
  // Validate Email Settings
  static deleteEmail(data: any) {
    debugger;
    return http.delete(
      `${consoleBaseUrl}${Endpoints.DELETE_EMAIL}/${data.email}`,
    );
  }
  // Validate Vliad Email Settings
  static addEmail(data: any) {
    return http.post(`${consoleBaseUrl}${Endpoints.ADD_EMAIL}`, data);
  }
}

// Label Endpoint
class Endpoints {
  static ENABLE_EMAIL_CHANNEL = "/workspace/create-sub-account";
  static VALIDATE_EMAIL_SETTINGS = "/workspace/valid-email-list";
  static UPDATE_EMAIL_PROFILE = "/workspace/update-profile";
  static VALID_EMAIL_LIST = "/workspace/valid-email-list";
  static GET_DOMAIN_LIST = "/workspace/domain-list";
  static ADD_EMAIL = "/workspace/add-valid-email";
  static DELETE_EMAIL = "/workspace/delete-valid-email";

  //new endpoints for node microservice
  static GET_ADDRESS_LIST = "/workspace/broadcast/update-profile-list";
}
