// import styled from "styled-components";
// import { theme } from "../Components/Theme";
// import { Accordion, Box, Grid } from "@mui/material";

// export const WhatsappFlowWapper = styled.div`
//   background-color: ${theme.palette.default.background};
//       margin: 27px 13px 12px 11px;
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//     text-align: center;
//     height : calc(-90px + 100vh);
// `;

// export const WhatsappFlowSection = styled(Grid)`
//     border-right: 1px solid lightgray;
//     height: calc(-90px + 100vh);
// }
// `
// export const FlowBuilderPrevireWapper = styled.div`
//   .PreviewTitle{
//     font-weight: 500;
//     font-size: 16px;
//     margin-right: 28px;
//     margin-top:40px
//     margin-bottom: -7px;
//   },
//   .PreviewMode{
//         font-weight: 400;
//     font-size: 13px;
//         margin-bottom: 5px;
//         .MuiSwitch-root{
//           height:43px ;
//               margin-left: 5px;
//         }
//   }
// `

// export const FlowBuilderScreenWapper = styled.div`

// `
// export const FlowBuilderScreenHeader = styled.div`
//       display: flex;
//     justify-content: space-between;
//     padding: 1px 12px;
//         border-bottom: 1px solid lightgray;
//     padding: 0px 9px 11px 11px;
//     .headerTitle{
//       font-weight: 500;
//     font-size: 15px;
//     margin-top:5px
//     }
// }
// `
// export const FlowBuilderContentWapper = styled.div`
//     margin-left: 0px;
//    .body{
//     height: calc(-94px + 93vh);
//     overflow: scroll;
//         margin-left: 0px;
//    }
// `
// export const FlowBuilderContentHeader = styled.div`
//       display: flex;
//     justify-content: space-between;
//     padding: 1px 12px;
//         border-bottom: 1px solid lightgray;
//     padding: 0px 8px 11px 23px;
//         width: 102%;
//     margin-left: -15px;
//     .headerTitle{
//       font-weight: 500;
//     font-size: 15px;
//     margin-top:5px
//     }
// }
// `

// export const RowWrapper = styled.div`
//   && {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border : 1px solid lightgray;
//     background-color: ${(props: { active: any; }) => props.active ? "#075E541A" : ""};
//     margin: 16px;
//     padding: 5px 12px;
//     cursor: pointer;
//     height: 36px;
//     .closeBtn{
//       font-size: 20px;
//       color: black;
//       background: none;
//       border: none;
//       cursor: pointer;
//     }
//     .title {
//       min-width: 190px;
//       display: flex;
//       min-height: 40px;
//       align-items: center;
//       margin-right: auto;
//       width: 100%;
//       cursor: pointer;
//       p {
//         font-size: 14px;
//         font-weight: 300;
//         margin-left: 10px;
//         cursor: pointer;
//       }
//     }
//     .showinform {
//       .MuiTypography-root {
//         font-size: 14px;
//       }
//     }
//     .options {
//       .MuiTypography-root {
//         font-size: 14px;
//       }
//     }
//     .view {
//       svg {
//         width: 20px;
//       }
//     }

//     @media (max-width: 1499px) {
//       .title {
//         p {
//           font-size: 13px;
//         }
//       }
//       .showinform {
//         .MuiTypography-root {
//           font-size: 13px;
//         }
//       }
//       .options {
//         .MuiTypography-root {
//           font-size: 13px;
//         }
//       }
//     }
//   }
// `;

// export const FlowBuilderScreenList = styled(Box)`
//     height: calc(-94px + 93vh);
//     overflow: scroll;
// `
// export const AddScreenModel = styled.div`
//   .bodyTitle{
//     font-weight: 500;
//   }
//   .leaveBodyTitle{
//     margin: 22px 0px;
//     opacity: 48%;
//   }
//   }
//   .footer{
//     display: flex;
//     justify-content: space-between;
//     gap: 2%;
//     margin-top:5px
//   }
//   .MuiBox-root{
//       width: 500px;
//   }
//   .MuiFormControl-root{
//     width:100%;
//     margin-top: 4px;
//     border : 1px soild;
//     .MuiInputBase-root{
//         border: 1px solid;
//         max-height: 37px;
//         margin-bottom: 18px;
//     }
//   }

//   .addScreenHeader{
//     display:flex;
//     margin-bottom: 16px;
//     justify-content: space-between;
//     .closeBtn{
//       font-size: 20px;
//       color: black;
//       background: none;
//       border: none;
//       cursor: pointer;
//       font-size: 26px;
//       color: gray;
//       margin-top: -6px;
//     }
//     .headerTitle{
//       font-size: 15px;
//       font-weight: 500;
//     } 
//   }
// `
// export const FlowBuilderContentList = styled.div`

// `
// export const FlowBuilderContentAccordian = styled(Accordion)`
//     padding: 15px !important;
//     background-color: #e8eff4 !important;
//     margin:14px 22px 15px 8px !important;
//     .MuiPaper-root{
//       padding:  0px 9px !important;
//     }
//   .MuiButtonBase-root{
//     height: 6px !important;
//     padding: 17px 13px 10px !important;
//     margin : -19px -9px -22px !important;
//   }
//   .MuiCollapse-root{
//       background-color: #fff !important;
//       margin-top: 14px !important;
//   }
//       .MuiFormControl-root{
//           width: 100% !important;
//           border: 1px solid !important;
//           border-radius: 5px;
//           .MuiInputBase-root{
//               height: 40px !important;
//           }
//       }
//   .bodyTitle{
//         font-size: 15px;
//         margin: 14px 0px -8px;
//         a{
//           opacity: 50%;
//         }
//     }
//         .bodyOptionsButton{
//           margin : 9px 37px !important;
//           padding : 18px 11px 15px !important
//         }
//   .bodyOptions{
//         display: flex;
//         justify-content: flex-end;
//         .bodyOptionsDelete{
//           margin-top:25px;
//         }
//           .bodyOptionsInput{
//           width : 90% !important;
//           }
//   }
//     .MuiAccordionSummary-content{
//       cursor: pointer;
//     }
// `

// export const AccordionSelect = styled.div`
//   .MuiInputBase-root{
//     border: 1px solid !important;
//     width: 100% !important;
//     height: 45px !important;
//     font-synthesis: none !important;
//   }

// `
// export const AccordionInput = styled.div`


// `

// export const AccordionDelete = styled.div`
//     text-align: end;
//     margin-right: 8px;
//     cursor: pointer;
//     display: flex;
//     justify-content: end;
//     padding: 8px 0px 6px;
//     .MuiFormControlLabel-root{
//         margin: -6px 5px !important; 

//     .MuiButtonBase-root{
//         margin:0 !important;
//           .MuiSwitch-thumb{
//     margin-top: 4px !important;
//     }
//       }


//     }


// `


import styled from "styled-components";
import { theme } from "../Components/Theme";
import { Accordion, Box, FormControl, Grid } from "@mui/material";
// styles.js
import { makeStyles } from "@mui/styles";

export const WhatsappFlowWapper = styled.div`
  background-color: ${theme.palette.default.background};
  margin: 27px 13px 12px 11px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  height: calc(-155px + 100vh);
`;

export const WhatsappFlowSection = styled(Grid)`
  border-right: 1px solid lightgray;
  height: calc(-90px + 100vh);
`;

export const FlowBuilderPrevireWapper = styled.div`
  .PreviewTitle {
    font-weight: 500;
    font-size: 16px;
    margin-right: 28px;
    margin-top: 40px;
    margin-bottom: -7px;
  }
  .PreviewMode {
    font-weight: 400;
    font-size: 13px;
    margin-bottom: 5px;
    .MuiSwitch-root {
      height: 43px;
      margin-left: 5px;
    }
  }
`;

export const FlowBuilderScreenWapper = styled.div``;

export const FlowBuilderScreenHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1px 12px;
  border-bottom: 1px solid lightgray;
  padding: 0px 9px 11px 11px;
  .headerTitle {
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
  }
`;

export const FlowBuilderContentWapper = styled.div`
  margin-left: 0px;
  .body {
    height: calc(-94px + 93vh);
    overflow: scroll;
    margin-left: 0px;
  }
`;

export const FlowBuilderContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1px 12px;
  border-bottom: 1px solid lightgray;
  padding: 0px 8px 11px 23px;
  width: 102%;
  margin-left: -15px;
  .headerTitle {
    font-weight: 500;
    font-size: 15px;
    margin-top: 5px;
  }
`;

export const RowWrapper = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgray;
    background-color: ${(props: { active: any }) => (props.active ? "#075E541A" : "")};
    margin: 16px;
    padding: 5px 12px;
    cursor: pointer;
    height: 36px;
    .closeBtn {
      font-size: 20px;
      color: black;
      background: none;
      border: none;
      cursor: pointer;
    }
    .title {
      min-width: 190px;
      display: flex;
      min-height: 40px;
      align-items: center;
      margin-right: auto;
      width: 100%;
      cursor: pointer;
      p {
        font-size: 14px;
        font-weight: 300;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .showinform {
      .MuiTypography-root {
        font-size: 14px;
      }
    }
    .options {
      .MuiTypography-root {
        font-size: 14px;
      }
    }
    .view {
      svg {
        width: 20px;
      }
    }

    @media (max-width: 1499px) {
      .title {
        p {
          font-size: 13px;
        }
      }
      .showinform {
        .MuiTypography-root {
          font-size: 13px;
        }
      }
      .options {
        .MuiTypography-root {
          font-size: 13px;
        }
      }
    }
  }
`;

export const FlowBuilderScreenList = styled(Box)`
  height: calc(-94px + 93vh);
  overflow: scroll;
`;

export const AddScreenModel = styled.div`
  .bodyTitle {
    font-weight: 500;
  }
  .leaveBodyTitle {
    margin: 22px 0px;
    opacity: 48%;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    gap: 2%;
    margin-top: 5px;
  }
  .MuiBox-root {
    width: 500px;
  }
  .MuiFormControl-root {
    width: 100%;
    margin-top: 4px;
    // border: 1px solid;
    .MuiInputBase-root {
      border: 1px solid;
      max-height: 37px;
      margin-bottom: 18px;
    }
  }
  .addScreenHeader {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    .closeBtn {
      font-size: 20px;
      color: black;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 26px;
      color: gray;
      margin-top: -6px;
    }
    .headerTitle {
      font-size: 15px;
      font-weight: 500;
    }
  }
`;

export const CreateScreen = styled.div`
  .bodyTitle {
    font-weight: 500;
    margin-bottom: 9px;
    margin-top:15px;
  }
        .templateTitle{
    .MuiFormControl-root{
        width:100%

    }
    }
  .leaveBodyTitle {
    margin: 22px 0px;
    opacity: 48%;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    gap: 2%;
    margin-top: 5px;
  }
  .MuiBox-root {
    width: 500px;
  }
  .MuiFormControl-root {
    width: 50%;
    margin-top: 4px;
    // border: 1px solid;
    .MuiInputBase-root {
      border: 1px solid;
      max-height: 37px;
      margin-bottom: 18px;
    }
  }
  .addScreenHeader {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    .closeBtn {
      font-size: 20px;
      color: black;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 26px;
      color: gray;
      margin-top: -6px;
    }
    .headerTitle {
      font-size: 15px;
      font-weight: 500;
    }
  }
`;

export const FlowBuilderContentList = styled.div``;

export const FlowBuilderContentAccordian = styled(Accordion)`
  padding: 15px !important;
  background-color: #e8eff4 !important;
  margin: 14px 22px 15px 8px !important;
  .MuiPaper-root {
    padding: 0px 9px !important;
  }
  .MuiButtonBase-root {
    height: 6px !important;
    padding: 17px 13px 10px !important;
    margin: -19px -9px -22px !important;
  }
  .MuiCollapse-root {
    background-color: #fff !important;
    margin-top: 14px !important;
  }
  .MuiFormControl-root {
    width: 100% !important;
    border: 1px solid !important;
    border-radius: 5px;
    .MuiInputBase-root {
      height: 40px !important;
    }
  }
  .bodyTitle {
    font-size: 15px;
    margin: 14px 0px -8px;
    a {
      opacity: 50%;
    }
  }

  .bodyOptionsButton {
    margin: 9px 25px !important;
    padding: 18px 11px 15px !important;
  }
  .bodyOptionsButton:hover {
     background-color: white;
  }
  .bodyOptions {
    display: flex;
    justify-content: flex-end;
    .bodyOptionsDelete {
      margin-top: 18px !important;
      margin-left: 0px !important;
    }
    .bodyOptionsInput {
      width: 90% !important;
    }
  }
  .MuiAccordionSummary-content {
    cursor: pointer;
  }
`;

export const AccordionSelect = styled.div`
  .MuiInputBase-root {
    border: 1px solid !important;
    width: 100% !important;
    height: 45px !important;
    font-synthesis: none !important;
  }
`;

export const AccordionInput = styled.div``;

export const AccordionDelete = styled.div`
  text-align: end;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 8px 0px 6px;

  .MuiFormControlLabel-root {
    margin: -6px 5px !important;

    .MuiButtonBase-root {
      margin: 0 !important;

      .MuiSwitch-thumb {
        margin-top: 4px !important;
      }
    }
  }

  & > div {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
  }

  a {
    text-decoration: none;
  }
`;


export const FormLabelStyle = styled(FormControl)`
width : 100%;
.MuiFormGroup-root{
display: flex;
    align-content: space-between;
    margin-top: 0px;
    .MuiButtonBase-root{
          position: relative;
    left: 17rem;
    }
    .MuiTypography-root{
    position: absolute;
    left: 0;
    }
}
`
export const FlowScreenHeader = styled.div`
    height: 50px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px 0px, rgba(0, 0, 0, 0.19) 0px 1px 0px 0px;
    .headerContainer{
      display: flex;
      justify-content: flex-end;
       .search .MuiOutlinedInput-root{
          margin-right: 11px;
          height: 29px;
          width: 216px;
          border: 1px solid;
      };
    .status .MuiOutlinedInput-root {
          height: 29px;
          width: 100%;
          border: 1px solid;
    }
`
export const ImgaeUploadStyle = styled.div`
.imageContainerTitle{
    margin-top: 15px;
}
    .imageContainerPreview{
        display: flex;
    justify-content: flex-start;
    }
    .imageContainerTitle{
        font-weight: 600;
    }
           .file-upload__container {
                border: 1px dashed ${theme.palette.default.darkGrey};
                border-radius: 4px;
                display: flex;
                flex-direction: column; 
                justify-content: center; 
                align-items: center; 
                gap: 0.6rem;
                padding-top: 24px; 
                padding-bottom: 24px;
                margin-top: 15px;
.container { position: relative;}
.chooser { position: absolute; z-index: 1; opacity: 0; cursor: pointer;}
.chooser-2 { opacity:1;}
                .upload {
                   display: flex;
                   flex-direction: column;
                   justify-content: center;
                   align-items: center;
                   label {
                    width: 100%;
                   }
                    p {
                        text-transform: capitalize;
                        color: ${theme.palette.default.error};
                    }
                }
                
                .file-info {
                    text-align: center;
                    h6 {
                        font-size: 0.75rem;
                        color: ${theme.palette.default.black};
                        font-weight: 500;
                        margin-bottom: 4px;
                    }
                    p {
                        font-size: 0.625rem;
                        color: ${theme.palette.default.darkGrey};
                        font-weight: 500;
                    }
                }
                p {
                    color:  ${theme.palette.default.darkGrey};
                    font-weight: 400;
                }
                svg {
                    width: 2rem;
                }
            }
`



export const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  required: {
    color: 'red',
    marginLeft: theme.spacing(0.5),
  },
  select: {
    margin: theme.spacing(1, 0),
    border: '1px solid',
    width: '100%',
    height: '36px',
  },
  buttonStyle: {
    border: '1px solid',
    borderColor: 'grey.500',
    borderRadius: 1,
    height: '36px',
    width: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 150,
    border: '1px solid',
    borderColor: 'grey.500',
    borderRadius: 1,
    height: '36px',
  },
  flowPreview: {
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      position: 'static',
      marginTop: theme.spacing(4),
    },
  },
}));
