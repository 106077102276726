import React, { useEffect } from "react";
import WebBotStaticHeader from "./components/WebBotStaticHeader";
import WebBotStaticFooter from "./components/WebBotStaticFooter";
import WebBotStaticChat from "./components/WebBotStaticChat";
import {
  StyledWebBotFrameWrapper,
  StyledWebBotStaticContainer,
} from "./components/styles-web-bot-static-preview";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import {
  setLocalBotConfig,
  useBotConfigStore,
} from "../../../../../Store/Slices/botConfig/BotConfigSlice";
import WebBotStaticInput from "./components/WebBotStaticInput";
import { isColorLight } from "../../../../../Utils/ColorUtils";
import { useLocation } from "react-router-dom";

interface Props {
  localConfig?: any;
}

const WebBotStaticPreview: React.FC<Props> = ({ localConfig }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { botConfig, localBotConfig } = useAppSelector(useBotConfigStore);
  const isLookFeelTab = location.pathname.includes("look-feel") ?? false;

  const getBotConfig = () => {
    let temp: any = {};

    if (isLookFeelTab) {
      temp["company_logo"] = localConfig?.company_logo?.source ?? "";
      temp["backgroundImage"] = localConfig?.background_image?.source ?? "";
      temp["chat_avatar"] = localConfig?.avatar?.source ?? "";
      temp["bot_name"] = localConfig?.bot_name ?? "";
      temp["fontSize"] = localConfig?.font_size ?? "14";
      temp["fontFamily"] = localConfig?.font_family ?? "Inter";
      temp["primaryColor"] = localConfig?.primary ?? "#3f51b5";
      temp["userMessageBgColor"] = localConfig?.primary ?? "";
      temp["userMessageTextColor"] = isColorLight(
        localConfig?.primary ?? "#3f51b5"
      )
        ? "#121212"
        : "#fff";
      temp["botMessageBgColor"] = "#fff";
      temp["botMessageTextColor"] = "#121212";
      temp["contrastText"] = isColorLight(localConfig?.primary ?? "")
        ? "#121212"
        : "#fff";

      temp["banner_carousel"] = localConfig?.banner_carousel ?? null;
      temp["quick_menu"] = localConfig?.quick_menu ?? null;
      temp["notification_block"] = localConfig?.notification_block ?? null;
    } else {
      temp["company_logo"] =
        botConfig?.chatbot_configuration?.company_logo?.source ?? "";
      temp["backgroundImage"] =
        botConfig?.chatbot_configuration?.background_image?.source ?? "";
      temp["chat_avatar"] =
        botConfig?.chatbot_configuration?.avatar?.source ?? "";
      temp["bot_name"] = botConfig?.chatbot?.name ?? "";
      temp["fontSize"] = botConfig?.chatbot_theme?.font?.primary?.size ?? "14";
      temp["fontFamily"] =
        botConfig?.chatbot_theme?.font?.primary?.family ?? "Inter";
      temp["primaryColor"] =
        botConfig?.chatbot_theme?.primary?.main ?? "#3f51b5";
      temp["userMessageBgColor"] =
        botConfig?.chatbot_theme?.primary?.main ?? "#3f51b5";
      temp["userMessageTextColor"] = isColorLight(
        botConfig?.chatbot_theme?.primary?.main ?? "#3f51b5"
      )
        ? "#121212"
        : "#fff";
      temp["botMessageBgColor"] = "#fff";
      temp["botMessageTextColor"] = "#121212";
      temp["contrastText"] = isColorLight(
        botConfig?.chatbot_theme?.primary?.main ?? ""
      )
        ? "#121212"
        : "#fff";
      temp["banner_carousel"] =
        botConfig?.chatbot_configuration?.banner_carousel ?? null;
      temp["quick_menu"] = botConfig?.chatbot_configuration?.quick_menu ?? null;
      temp["notification_block"] =
        botConfig?.chatbot_configuration?.notification_block ?? null;
    }

    dispatch(setLocalBotConfig(temp));
  };

  useEffect(() => {
    getBotConfig();
  }, [botConfig, localConfig]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledWebBotStaticContainer>
      <StyledWebBotFrameWrapper
        fontSize={localBotConfig?.fontSize}
        fontFamily={localBotConfig?.fontFamily}
        primaryColor={localBotConfig?.primaryColor}
        contrastText={localBotConfig?.contrastText}
      >
        <WebBotStaticHeader />
        <WebBotStaticChat />
        <WebBotStaticInput />
        <WebBotStaticFooter />
      </StyledWebBotFrameWrapper>
    </StyledWebBotStaticContainer>
  );
};

export default WebBotStaticPreview;
