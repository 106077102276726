import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Box,
  Card,
  Divider,
  ListItem,
  ListItemText,
  Stack,
  Switch
} from "@mui/material";
import { useEffect, useState } from "react";
import AppButton from "../../../../AppComponents/AppButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import uniqid from "uniqid";
import { withLoader } from "../../../../AppComponents/Loader";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import { useAppDispatch } from "../../../../Store";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { LifecycleStageActions } from "../../../../Store/Slices/Settings/LifecycleStage/lifecycleStage.actions";
import { LifecycleStages } from "../../../../Store/Slices/Settings/LifecycleStage/lifecycleStage.selectors";
import { LifecycleStageService } from "../../../../Store/Slices/Settings/LifecycleStage/lifecycleStageService";
import noTableData from "../../../../assets/images/noDatafound.svg";
import {
  Center,
  ContactMarketingWrap,
} from "../../../ContactMarketingList/ContactMarketingListStyle";
import { SortableList } from "../../../UiComponents/DndKitDraggable/SortableList/SortableList";
import CreateLifeCycle from "./CreateLifeCycle";
import {
  StyledLifeCycle,
  StyledLifeCycleNewWrapper,
} from "./LifeCycleListingSyles";

export enum CreateEditModes {
  CREATE = "create",
  EDIT = "edit",
}

function LifecycleListing(props: any) {
  const currentBot = useSelector(useCurrentBot);
  const dispatch = useAppDispatch();
  const botid = currentBot?.bot_id;
  let { showAlert } = useNotification();

  const lifecycleStageStructure = useSelector(LifecycleStages);

  const [lifecycleStageData, setLifecycleStageData] = useState<any>(
    lifecycleStageStructure
  );
  const [newId, setNewId] = useState<any>(undefined);
  const [createEditMode, setCreateEditMode] = useState<string>(
    CreateEditModes.CREATE
  );
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    setLifecycleStageData(lifecycleStageStructure);
  }, [lifecycleStageStructure]);

  const onDrop = (items: any[], prevIndex: number, nextIndex: number) => {
    if (items.length - 1 === nextIndex) {
      showAlert("Default stage will always remain last", "error");
      return;
    }
    setLifecycleStageData(items);
    handleStagePositionChange(nextIndex, items);
  };

  const handleStagePositionChange = (index: any, stages: any[]) => {
    const payload = stages.map((stage: any, index: any) => {
      return { id: stage?.id, position: index + 1 };
    });
    LifecycleStageService.changeLifecycleStageOrder(payload)
      .then((response: any) => {
        showAlert(response?.data?.message, "success");
        getLifeCycleData();
      })
      .catch((err: any) => {
        props.setLoading(false);
        const error = err?.response?.data?.message || "Something went wrong";
        showAlert(error, "error");
      });
  };

  useEffect(() => {
    getLifeCycleData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function getLifeCycleData() {
    dispatch(LifecycleStageActions.getLifecycleStageStructure({})).finally(
      () => {
        props.setLoading(false);
      }
    );
  }
  const handleAdd = () => {
    setCreateEditMode(CreateEditModes.CREATE);
    setNewId(undefined);
    toggle();
  };
  const handleEditIconClicked = (id: any) => {
    setNewId(id);
    setCreateEditMode(CreateEditModes.EDIT);
    toggle();
  };

  const handleModalClose = () => {
    toggle();
    setNewId(undefined);
    setCreateEditMode(CreateEditModes.CREATE);
    getLifeCycleData();
  };

  const onStageStateChanged = (event: any, item: any) => {
    props.setLoading(true);
    LifecycleStageService.lifecycleStageChangeState({
      stage_id: item.id,
      state_type: event.target.checked ? "enable" : "disable",
    })
      .then((response: any) => {
        getLifeCycleData();
        props.setLoading(false);
      })
      .catch((err: any) => {
        props.setLoading(false);
        const error = err?.response?.data?.message || "Something went wrong";
        showAlert(error, "error");
      });
  };

  const handleStageDelete = (id: any) => {
    LifecycleStageService.deleteLifecycleStage({ stage: id })
      .then((response: any) => {
        showAlert(response?.data?.message, "success");
        getLifeCycleData();
      })
      .catch((err: any) => {
        props.setLoading(false);
        const error = err?.response?.data?.message || "Something went wrong";
        showAlert(error, "error");
      });
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",

    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 4,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#FFFFFF",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#5B73E8;",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 8,
      height: 8,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <ContactMarketingWrap>
      <div className="topmenu">
        <div className="left-part">
          <div className="icon">Lifecycle Stages</div>
        </div>

        <div className="right-part">
          <div className="button">
            <AppButton
              startIcon="ri-add-line"
              variant="outline"
              onClick={() => handleAdd()}
            >
              Add Stages
            </AppButton>
          </div>
        </div>
      </div>
      {lifecycleStageData.length === 0 ? (
        <Center>
          <div className="fallback_ui_container">
            <div className="fallback_ui">
              <img src={noTableData} alt="" />
              <p className="error-message">No LifeCycle Stages Found.</p>
              <AppButton
                variant="outline"
                onClick={() => {
                  toggle();
                  handleAdd();
                }}
              >
                Create LifeCycle
              </AppButton>
            </div>
          </div>
        </Center>
      ) : (
        <StyledLifeCycleNewWrapper>
          <Box className="lifecycle-stages" sx={{}}>
            <SortableList
              items={lifecycleStageData}
              onChange={onDrop}
              orientation="horizontal"
              renderItem={(item) => (
                <SortableList.Item id={item.id} key={uniqid()}>
                  <StyledLifeCycle className="stylelife">
                    <Card className="main-box">
                      <Card className="header">
                        <div className="left">
                          {!item.default && <SortableList.DragHandle />}
                          <h4> {item?.label}</h4>
                        </div>
                        <div className="right">
                          {!item?.default && !item?.custom && (
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <AntSwitch
                                checked={!item?.disable}
                                inputProps={{ "aria-label": "ant design" }}
                                onChange={(e) => onStageStateChanged(e, item)}
                              />
                            </Stack>
                          )}
                          {
                            <ModeEditOutlineOutlinedIcon
                              onClick={() => {
                                handleEditIconClicked(item?.id);
                              }}
                            />
                          }
                          {item?.custom && (
                            <DeleteOutlineOutlinedIcon
                              onClick={() => {
                                handleStageDelete(item?.id);
                              }}
                            />
                          )}
                        </div>
                      </Card>
                      <Card className="body-list">
                        {item?.statuses.map((status: any, index: number) => {
                          return (
                            <div key={index}>
                              <ListItem className="list-item">
                                <ListItemText
                                  primary={status?.label}
                                  color="red"
                                />
                              </ListItem>
                              <Divider
                                sx={{
                                  borderColor: "#F5F6F8",
                                }}
                              />
                            </div>
                          );
                        })}
                      </Card>
                    </Card>
                  </StyledLifeCycle>
                </SortableList.Item>
              )}
            />
          </Box>
        </StyledLifeCycleNewWrapper>
      )}

      <AppModel isShowing={isShowing} onClose={() => toggle()}>
        <CreateLifeCycle
          newId={newId}
          onModalClose={handleModalClose}
          mode={createEditMode}
        />
      </AppModel>
    </ContactMarketingWrap>
  );
}

export default withLoader(LifecycleListing);
