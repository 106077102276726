import styled from "styled-components";

export const UsersDetailMenuWrapper = styled.div`
  /* width: 22%; */
  min-width: 40px;
  max-width: 40px;
  /* box-shadow: rgba(0, 0, 0, 0.08) 2px 0px 4px; */
  background: #ebeff3;
  z-index: 1;
  height: calc(100% - 16px);
  position: absolute;
  right: 0;
  z-index: 9;
`;
