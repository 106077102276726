import { FormikContextType, useFormikContext } from "formik";
import React from "react";

// use like this in parent form componenet and then pass setFormikProps to AppFormObserver inside the form
// const [formikBag, setFormikBag] = React.useState<FormikContextType<unknown>>();

interface Props {
  setFormikProps?: React.Dispatch<FormikContextType<any>> | undefined | any;
}

const AppFormObserver: React.FC<Props> = ({ setFormikProps }) => {
  const formikProps = useFormikContext();

  const memoizedSetFormikProps = React.useCallback(
    (props: any) => {
      if (setFormikProps && props?.values) {
        setFormikProps(props);
      }
    },
    [setFormikProps]
  );

  const memoizedFormikProps = React.useMemo(
    () => formikProps,
    [formikProps?.values, formikProps?.errors, formikProps?.isSubmitting] // eslint-disable-line react-hooks/exhaustive-deps
  );
  React.useLayoutEffect(() => {
    memoizedSetFormikProps(memoizedFormikProps);
  }, [memoizedFormikProps, memoizedSetFormikProps]);

  return null;
};
export default AppFormObserver;
