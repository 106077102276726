import React, { useEffect, useState } from "react";
import { AppForm, AppSubmitButton } from "../../../../../AppComponents/Forms";
import AppButton from "../../../../../AppComponents/AppButton";
import useModal from "../../../../../Hooks/useModel/useModel";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import { IconButton, debounce } from "@mui/material";
import { ReactComponent as FailedIcon } from "../../../../../assets/images/close.svg";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import DocumentAndTextTable from "../commonComponents/DocumentAndTextTable";
import {
  AppPagination,
  FaqTextWrap,
  StyledAppInput,
  UploadFilePopupWrap,
} from "../../BotKnowledgeStyles";
import Filepicker from "./Filepicker";
import { FaqSourceAction } from "../../../../../Store/Slices/Faq/Source/source.actions";
import { useAppDispatch } from "../../../../../Store";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";

import { useSelector } from "react-redux";
import {
  SourceList,
  SourceListCount,
} from "../../../../../Store/Slices/Faq/Source/source.selectors";
import { getSourceListing } from "../SourceUtils";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";

import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/images/Searchicon.svg";
import { useParams } from "react-router-dom";
import DeleteConfirmationModal from "../commonComponents/DeleteConfirmationModal";

interface Props extends LoaderComponentProps { }

const FaqPdf: React.FC<Props> = ({ setLoading }) => {
  // Setting the source type for api
  const sourceType = "file";

  // Setting Values Locally
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
    query: "",
    checkbox: [],
    checkboxAll: false,
  });

  // Bot ID
  let { bot_id } = useParams();

  // Headers For API
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };

  const dispatch = useAppDispatch();

  // Listing Of Data For Table
  const SourceListing = useSelector(SourceList);
  const SourceListingCount = useSelector(SourceListCount);

  const { showAlert } = useNotification();

  // Modal For Upload Document
  // const { isShowing, toggle } = useModal();
  const UploadDocModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingUploadDocModal: isShowing, toggleUploadDocModal: toggle };
  };
  const { isShowingUploadDocModal, toggleUploadDocModal } = UploadDocModal();

  const ConfirmModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingConfirmModal: isShowing, toggleConfirmModal: toggle };
  };
  const { isShowingConfirmModal, toggleConfirmModal } = ConfirmModal();

  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(
    null
  );

  // Upload Document Functions
  const [fname, setfname] = useState("");
  const [fileData, setfileData] = useState<any>(null);
  const [userInput, setUserInput] = useState<any>({
    upload_file: null,
  });

  // Upload File Function
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    if (!values?.upload_file) {
      SubmitProps?.setFieldError("upload_file", "File is required");
      SubmitProps.setSubmitting(false);
      return;
    }
    const formData = new FormData();
    formData.append("file", fileData);
    // formData.append("file_name", fname);
    let temp = {
      file: formData,
      HEADERS,
    };
    dispatch(FaqSourceAction.UploadFaqDocument(temp))
      .then((res: any) => {
        if (res?.payload?.data?.error) {
          showAlert(res?.payload?.data?.error, "error");
          return;
        }
        SubmitProps?.resetForm();
        toggleUploadDocModal();
        setUserInput({ upload_file: null });
        getSourceListing(
          dispatch,
          HEADERS,
          sourceType,
          localValues,
          setLoading
        );
        showAlert("File Is Uploaded", "success");
      })
      .catch((err: any) => {
        showAlert("error while creating attachment", err);
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
      });
    return;
  };

  const handleChange = () => { };

  // Table Functions
  // PopOver For Table Row - Actions
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    documentId: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedDocumentId(documentId);
  };
  // Popover actions onclcik Close
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Function To Delete
  const deletSourceByDocumentId = () => {
    let documentIds;
    if (selectedDocumentId) {
      documentIds = [selectedDocumentId];
    } else {
      documentIds = selectedDocumentIds;
    }
    const data = {
      HEADERS,
      documentIds,
    };

    dispatch(FaqSourceAction.DeleteSourceByIds(data))
      .then((res: any) => {
        if (res?.payload?.status === 200) {
          getSourceListing(
            dispatch,
            HEADERS,
            sourceType,
            localValues,
            setLoading
          );
          showAlert("Source Text Is Deleted", "success");
        } else {
          showAlert("Error", "error");
        }
      })
      .catch((error: any) => {
        showAlert(error, "error");
      });
    setSelectedDocumentIds([]);
    toggleConfirmModal();
  };

  // Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };

  // Hadnling Search Functionality
  const handleSelectSearch = debounce((e?: any) => {
    setLocalValues({
      ...localValues,
      query: e.target.value,
      currentPage: 0,
    });
  }, 700);

  // Use Effect - List
  useEffect(() => {
    getSourceListing(dispatch, HEADERS, sourceType, localValues, setLoading);
  }, [localValues]);

  return (
    <>
      <FaqTextWrap width={"658px"}>
        <div className="top-header">
          <div className="header-left">
            <p>
              Turn your documents into a powerful knowledge base! Upload PDF,
              .doc, .docx, or .txt files to fuel your bot's intelligence. It'll
              answer user queries with precision. Experience it now!
            </p>
          </div>
          <div className="serach-class">
            {selectedDocumentIds.length ? (
              <span className="delete-button">
                <AppButton
                  onClick={() => toggleConfirmModal()}
                  variant="danger-filled"
                >
                  Delete <DeleteIcon />
                </AppButton>
              </span>
            ) : null}
            <div className="faq-search">
              <StyledAppInput>
                <input
                  type="text"
                  onChange={(e: any) => handleSelectSearch(e)}
                  name="query"
                  id="query"
                  placeholder="Search"
                />
                <SearchIcon />
              </StyledAppInput>
            </div>
            <div className="button">
              <AppButton onClick={toggleUploadDocModal}>
                upload document
                <AppMaterialIcons iconName="DescriptionOutlined" />
              </AppButton>
            </div>
          </div>
        </div>

        {/* Table  */}
        <DocumentAndTextTable
          SourceListing={SourceListing}
          sourceType="file"
          id={id}
          open={open}
          anchorEl={anchorEl}
          handleClick={handleClick}
          handleClose={handleClose}
          setSelectedDocumentIds={setSelectedDocumentIds}
          selectedDocumentIds={selectedDocumentIds}
          toggleConfirmModal={toggleConfirmModal}
        />
      </FaqTextWrap>

      {/* Delete Confirmation Modal */}
      <DeleteConfirmationModal
        toggleConfirmModal={toggleConfirmModal}
        deletSourceByDocumentId={deletSourceByDocumentId}
        selectedDocumentIds={selectedDocumentIds}
        isShowingConfirmModal={isShowingConfirmModal}
        type="Document"
      />

      {/* Pagination */}
      {SourceListing?.length ? (
        <AppPagination
          component="div"
          rowsPerPageOptions={[10, 25, 50]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={localValues?.currentPage}
          count={SourceListingCount.totalRecords}
          rowsPerPage={localValues?.itemsPerPage}
        />
      ) : null}

      {/* Upload Document Modal */}
      <AppModel
        isShowing={isShowingUploadDocModal}
        onClose={() => { }}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "750px",
          },
        }}
      >
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
            setUserInput(values);
          }}
          divStyle={{
            width: "100%",
          }}
        // validationSchema={SMSUploadValidationSchema}
        >
          <UploadFilePopupWrap>
            <div className="crawl-popup">
              <div className="crawl-heading">
                <p>Upload document</p>
              </div>
              <div className="close-icon">
                <IconButton
                  onClick={() => {
                    toggleUploadDocModal();
                    setfileData(null);
                  }}
                >
                  <FailedIcon />
                </IconButton>
              </div>
            </div>

            <div className="upload-file">
              <div className="header">
                <h3>Upload any document file (.PDF, .DOCX and .TXT).</h3>
              </div>
              <Filepicker
                name="upload_file"
                mediaType={"document"}
                sizeLimit={100000000}
                extensions={[
                  "application/pdf",
                  "text/plain",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ]}
                divStyle={{ margin: "0rem", textAlign: "center" }}
                handleChange={() => handleChange()}
                setfname={setfname}
                fname={fname}
                fileData={fileData}
                setfileData={setfileData}
              />
              <div className="paragraph">
                <div className="para">
                  <p>
                    Please upload document files (.PDF, .DOCX, .TXT) to build
                    your bot's knowledge base. The bot will utilize this
                    information to respond to user queries accurately.
                  </p>
                </div>
              </div>
            </div>
            <div className="popup-buttons">
              <AppButton
                onClick={() => {
                  toggleUploadDocModal();
                  setfileData(null);
                }}
                variant="grey"
              >
                Cancel
              </AppButton>
              <AppSubmitButton
                title="Upload"
                endIcon="ArrowForwardOutlined"
                disabled={fileData == null ? true : false}
              />
            </div>
          </UploadFilePopupWrap>
        </AppForm>
      </AppModel>
    </>
  );
};

export default withLoader(FaqPdf);
