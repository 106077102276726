// vendors
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";

//components
import { UIButton, Icon, NodeType } from "../../ui-components";
import ButtonPreview from "./buttonPreview";
import EditableButton from "./editableButton";
import ButtonInputOptions from "./button-input-options";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

// config
import { getActionByType } from "./utils";

type Props = {
  language: string;
  showPreview: boolean;
  buttonsList: any;
  onLabelChange?: (label: string, activeButtonIdx: number | null) => void;
  onButtonSave?: (updatedAction: any) => void;
  onPreviewButtonClick: () => void;
  onButtonDelete: (button: any) => void;
  maxLimit?:  number;
};

type Action = {
  icon: string;
  color: string;
  title: string;
  displayComponent: any;
  type: string;
};

const ButtonList = (props: Props) => {
  const {
    showPreview,
    buttonsList,
    onLabelChange,
    onButtonSave,
    language,
    onPreviewButtonClick,
    onButtonDelete,
    maxLimit
  } = props;
  const [activeButton, setActiveButton] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedAction, setSelectedAction] = useState<{
    icon: string;
    color: string;
    title: string;
    displayComponent: any;
  } | null>(null);
  const open = Boolean(anchorEl);
  const [buttons, setButtons] = useState<any>([]);
  const [error, updateError] = useState<boolean>(false);

  useEffect(() => {
    if (buttonsList && Array.isArray(buttonsList)) {
      setButtons(buttonsList);
    }
  }, [buttonsList]);

  const handleEditableButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number
  ) => {
    setActiveButton(index);
    setAnchorEl(event.currentTarget);
    if(buttons[index] && buttons[index].type !== "" ) {
      setSelectedAction(getActionByType(buttons[index].type))
    }
  };

  const handlePreviewButtonClick = (index: number) => {
    setActiveButton(index);
    onPreviewButtonClick && onPreviewButtonClick();
  };

  const handleAddButtonClick = () => {
    const newButton = {
      id: uuid(),
      type: "",
      value: {
        text: `Button ${buttons.length + 1}`,
        url: "",
      },
    };
    setButtons([
      ...buttons,
      newButton,
    ]);
    setActiveButton(buttons.length);
    onButtonSave && onButtonSave(newButton);
  };

  const handleClose = () => {
    setSelectedAction(null);
    setAnchorEl(null);
    setActiveButton(null);
  };

  const handleOptionClick = (action: Action) => {
    setSelectedAction(action);
    if(activeButton !== null) {
      const newState = buttons.map((button: any, index: number) => {
        if (index === activeButton) {
          return {
            ...button,
            type: action.type,
            ...(action.type === "live_agent" && { flow_type: action.type }),
            ...(action.type === "transfer_to_faqs" && {
              flow_type: action.type,
              input: true,
              input_type: "TRANSFER_TO_FAQS",
            }),
          };
        }
        return button;
      });
      setButtons(newState);
    }
  };

  const handleBackClick = () => {
    setSelectedAction(null);
  };

  const handleDeleteClick = (activeButtonData: any) => {
    onButtonDelete && onButtonDelete(activeButtonData);
    handleClose();
  };

  const handleButtonInputChange = (label: string) => {
    updateError(label === '');

    if(activeButton !== null) {
      const newState = buttons.map((button: any, index: number) => {
        if (index === activeButton) {
          return {
            ...button,
            value: { ...button.value, ...{ text: label } },
          };
        }
        return button;
      });
      setButtons(newState);
    }

    if(activeButton !== null && typeof buttonsList[activeButton] !== 'undefined') {
      onLabelChange && onLabelChange(label, activeButton);
    }
  }

  const handleSaveClick = (activeButtonData: any) => {
    handleClose();
    onButtonSave && onButtonSave(activeButtonData);
  }

  const handleCancelClick = () => {
    if(activeButton !== null) {
      const prevActiveButtonData = buttonsList[activeButton];
      if(prevActiveButtonData) {
        if(activeButton !== null) {
          const newState = buttons.map((button: any, index: number) => {
            if (index === activeButton) {
              return {
                ...button,
                type: prevActiveButtonData.type
              };
            }
            return button;
          });
          setButtons(newState);
        }
      }
    }
    handleClose();
  };

  const Component =
    selectedAction !== null ? selectedAction?.displayComponent : <></>;
  return (
    <>
      {showPreview ? (
        <ButtonPreview
          buttonList={buttons}
          onButtonClick={handlePreviewButtonClick}
        />
      ) : (
        <Box sx={{ p: "16px" }}>
          <EditableButton
            buttonList={buttons}
            onButtonClick={handleEditableButtonClick}
            activeButton={activeButton}
            onDelete={handleDeleteClick}
          />
          <Box sx={{ py: "5px" }}>
            <UIButton
              fullWidth
              variant="outlined"
              onClick={handleAddButtonClick}
              disabled={language !== 'english' || maxLimit === buttonsList.length}
            >
              + Add Button
            </UIButton>
          </Box>
          <Popover
            className="ignore-onClickOutside"
            open={open}
            anchorEl={anchorEl}
            onClose={() => !error && handleClose()}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}
            sx={{ ml: "24px", borderRadius: "8px" }}
            elevation={0}
            PaperProps={{
              sx: {
                borderRadius: "8px",
                boxShadow:
                  "0px 13.9892px 69.9461px rgba(126, 131, 146, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.08);",
              },
            }}
          >
            {selectedAction === null ? (
              <ButtonInputOptions
                //@ts-ignore
                activeButtonData={buttons[activeButton]}
                onClose={() => !error && handleClose()}
                onOptionClick={handleOptionClick}
                selectedAction={selectedAction}
                onDelete={handleDeleteClick}
                onButtonLabelInputChange={handleButtonInputChange}
                language={language}
              />
            ) : (
              <Box sx={{ width: "345px", maxWidth: "345px" }}>
                <Box
                  sx={{
                    py: "4px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "1px solid #F5F6F8;",
                  }}
                >
                  <IconButton
                    sx={{
                      ml: "5px",
                      mr: "-10px",
                    }}
                    onClick={handleBackClick}
                  >
                    <Icon icon="chevron-left" color="" size={12} />
                  </IconButton>
                  <NodeType
                    text={selectedAction.title}
                    color={selectedAction.color}
                    icon={selectedAction.icon}
                  />
                </Box>
                <Component
                  language={language}
                  onCancelClick={handleCancelClick}
                  onSaveClick={handleSaveClick}
                  activeButtonData={
                    activeButton !== null && buttons[activeButton]
                  }
                />
              </Box>
            )}
          </Popover>
        </Box>
      )}
    </>
  );
};
export default ButtonList;
