import React, { memo } from "react";
import { MESSAGE_TYPES } from "../../../Models/Enum";
import { useAppSelector } from "../../../Store";
import { allMessagesStore } from "../../../Store/Slices/socket/AllMessagesSlice";
import { AppMaterialIcons } from "../Icons";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppSmallButton from "../UtilsComponents/AppSmallButtons";

interface Props {
  userMessage: boolean;
  item: any;
  rowWrapperStyle?: React.CSSProperties | undefined;
  containerStyle?: React.CSSProperties | undefined;
  time: string;
  msgId: string;
  [otherProps: string]: any;
}

const AppRenderCtaButtons: React.FC<Props> = ({
  userMessage,
  item,
  rowWrapperStyle,
  containerStyle,
  time,
  msgId,
}) => {
  const { typingStatus } = useAppSelector(allMessagesStore);

  if (item.type === MESSAGE_TYPES.LINK) {
    let finalUrl: string = item?.value?.url;
    if (
      !item?.value?.url.includes("https://") &&
      !item?.value?.url.includes("Https://") &&
      !item?.value?.url.includes("http://")
    ) {
      finalUrl = "https://" + item?.value?.url;
    }
    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        time={time}
        isMedia={true}
        isButton={true}
        msgId={msgId}
        divStyle={containerStyle}
        rowStyle={rowWrapperStyle}
      >
        <AppSmallButton disabled={typingStatus ? true : false}>
          <a href={`${finalUrl}`} target="_blank" rel="noreferrer">
            <AppMaterialIcons iconName="OpenInNew" />
            {item?.value?.text ?? item?.value?.url}
          </a>
        </AppSmallButton>
      </AppMessageBoxWrapper>
    );
  }

  if (item.type === MESSAGE_TYPES.MOBILE) {
    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        time={time}
        isMedia={true}
        isButton={true}
        msgId={msgId}
        divStyle={containerStyle}
        rowStyle={rowWrapperStyle}
      >
        <AppSmallButton disabled={typingStatus ? true : false}>
          <a href={`tel:${item?.value?.url}`} target="_blank" rel="noreferrer">
            <AppMaterialIcons iconName="AddIcCall" />
            {item?.value?.text ?? item?.value?.url}
          </a>
        </AppSmallButton>
      </AppMessageBoxWrapper>
    );
  }

  if (item.type === MESSAGE_TYPES.EMAIL) {
    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        time={time}
        isMedia={true}
        isButton={true}
        msgId={msgId}
        
        divStyle={containerStyle}
        rowStyle={rowWrapperStyle}
      >
        <AppSmallButton disabled={typingStatus ? true : false}>
          <a
            href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${item?.value?.url}`}
            target="_blank"
            rel="noreferrer"
          >
            <AppMaterialIcons iconName="AlternateEmail" />
            {item?.value?.text ?? item?.value?.url}
          </a>
        </AppSmallButton>
      </AppMessageBoxWrapper>
    );
  }

  return null;
};

export default memo(AppRenderCtaButtons);
