import { useEffect, useState } from "react";
import { format } from "date-fns";
import { useAppSelector } from "../../../Store";
import {
  BotList,
  botDateRange,
  project_id,
} from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { DashboardNetworkService } from "../../../Store/Slices/DashboardRedux/DashboardNetworkService";
import { BroadcastDetailListWrapper } from "../../Broadcast/SMS/SenderID/SenderIDStyles";
import { TextTitle } from "../AnalyticsSummaryStyles";
import DashboardCommonInfScrollTable from "../../Analytics/CommonInfScrollTable";
import { ErrorComponent } from "../../Broadcast/SMS/SenderID/ListingStyles";
import { Box, Typography } from "@mui/material";
import NoData from "../../../assets/images/noDatafound.svg";
import SkeletonLoader from "../../../AppComponents/Loader/SkeletonLoader";
import styled from "styled-components";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {}

interface ColumnData {
  dataKey: string;
  label: string;
  width: string;
  align?: string;
  padding?: string;
}

const languageTableColumns: ColumnData[] = [
  {
    label: "Language",
    dataKey: "language",
    align: "start",
    width: "50%",
  },
  {
    label: "Visitors",
    dataKey: "count",
    align: "start",
    width: "50%",
  },
];

const BrowserLanguage: React.FC<Props> = (props) => {
  const [pageNo, setPageNo] = useState<any>(0);
  const [loading, setLoading] = useState(false);
  const dashdateRange: any = useAppSelector(botDateRange);
  const botList = useAppSelector(BotList);
  const [languageHasMoreBooleanValue, setLanguageHasMoreBooleanValue] =
    useState<boolean>(true);

  var PAGE_SIZE = 10;
  const [list, setList] = useState<any>([]);

  function getUniqueAfterMerge(arr1: any[], arr2: any[]) {
    // merge two arrays
    let arr = arr1.concat(arr2);
    let uniqueArr = [];
    // loop through array
    for (let i of arr) {
      if (uniqueArr.indexOf(i) === -1) {
        uniqueArr.push(i);
      }
    }
    return uniqueArr;
  }
  const getBrowsersData = (p: number = 0) => {
    setLoading(true);

    let payload1: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      pageNumber: p,
      pageSize: PAGE_SIZE,
    };
    if (botList.length > 0) {
      payload1 = {
        ...payload1,
        bot_id: botList,
      };
    }

    DashboardNetworkService.getAquisitionByLanguage(payload1)
      .then((response: any) => {
        if (response?.data?.data?.data?.length > 0) {
          const temp = list || [];

          const newData: any = getUniqueAfterMerge(
            temp,
            response?.data?.data?.data
          );

          //setList(newData || [])
          const latestData = newData.filter((obj: any, index: any) => {
            return (
              index ===
              newData.findIndex((o: any) => obj.language === o.language)
            );
          });

          setList(latestData || []);
        } else {
          setList([]);
        }
        if (
          response?.data?.data?.pagination?.currentPage ===
          response?.data?.data?.pagination?.totalPages
        ) {
          setLanguageHasMoreBooleanValue(false);
        }
        setPageNo(p);
      })
      .catch((error: any) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate && botList.length) {
      getBrowsersData();
    }
  }, [botList, dashdateRange]); //eslint-disable-line react-hooks/exhaustive-deps

  function convertCountryDataIntoTableFormat(data: any[]) {
    const tempArray = data?.map((item: any, index: number) => {
      return {
        language: item?.language,
        count: item?.count,
      };
    });
    return tempArray;
  }

  return (
    <WrapperContainer>
      <p className="time_stats">
        <TextTitle>
          Browser Language
          <AppToolTip
            divStyle={{ padding: "0 8px" }}
            tooltipProps={{
              title: "Number of visitors segregated by browser language.",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon style={{ fontSize: "10px" }} />}
          />
        </TextTitle>
      </p>
      <BroadcastDetailListWrapper style={{ padding: "0 16px 0px 16px" }}>
        {list?.length === 0 ? (
          !loading ? (
            <ErrorComponent style={{ height: "225px" }}>
              <Box>
                <img src={NoData} width="150" alt="" />
              </Box>
              <Box className="err-content">
                <Typography variant="h6" fontSize="20px">
                  No Records Found.
                </Typography>
              </Box>
            </ErrorComponent>
          ) : (
            <SkeletonLoader numberOfCol={2} numberofRow={2} />
          )
        ) : (
          <DashboardCommonInfScrollTable
            columns={languageTableColumns}
            dataRows={convertCountryDataIntoTableFormat(list)}
            fetchMoreData={() => getBrowsersData(pageNo + 1)}
            hasMore={languageHasMoreBooleanValue}
            loadingState={false}
          />
        )}
      </BroadcastDetailListWrapper>
    </WrapperContainer>
  );
};

// Default Export of component
export default BrowserLanguage;

export const WrapperContainer = styled.div`
  width: 100%;
`;
