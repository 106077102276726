import { createAsyncThunk } from "@reduxjs/toolkit";
import { RoleNetworkService } from "./RoleNetworkService";

export class RoleActions {

    // Listing All Roles
    static getUserListing = createAsyncThunk(
        'RoleSlice/getUserListing',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.getRoleListing(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static getPermissionOptions = createAsyncThunk(
        'RoleSlice/getPermissionOptions',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.getPermissionOptions()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static getPermissionListing = createAsyncThunk(
        'RoleSlice/getPermissionListing',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.getPermissionListing(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static getInitialPermissions = createAsyncThunk(
        'RoleSlice/getInitialPermissions',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.getInitialPermissions()
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static AddRoles = createAsyncThunk(
        'RoleSlice/AddRoles',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.postRole(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static UpdateRole = createAsyncThunk(
        'RoleSlice/UpdateRole',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.putRole(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static DeleteRole = createAsyncThunk(
        'RoleSlice/DeleteRole',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.deleteUser(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )
    static UpdatePermissionData = createAsyncThunk(
        'RoleSlice/UpdatePermissionData',
        (data: any, { dispatch, rejectWithValue }) => {
            return RoleNetworkService.putPermission(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )

}
