import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppForm, AppFormColorPicker, AppFormField, AppFormObserver, AppFormRadioField, AppFormSelectField, AppFormTextArea } from '../../../../../AppComponents/Forms'
import { notSupportedHexCodes } from '../../../../../Models/defaultSelectOptions'
import { useAppDispatch, useAppSelector } from '../../../../../Store'
import { formSettingsStoreData } from '../../../../../Store/Slices/FormBuilder/formbuilder.selectors'
import { addSettings } from '../../../../../Store/Slices/FormBuilder/formbuilder.slice'
import AppAccordion from '../../../Components/AppAccordion'
import { backgroundImagePositions, backgroundRepeatOptions, buttonBoldOptions, buttonBorderWidthOptions, buttonPositionOptions, coverWholeWidthOptions, formatOptions, formBackgroundImageFittingOptions, formBorderOptions, formBordeWidthOptions, formButtonFontSizeOptions, formFieldBorderRadiusOptions, FormStylesSchema, labelMarginBottomOptions, labelUpperCase, showLableOptions } from '../../../Components/constants'
import FieldLabel from '../../../Components/FieldLabel'
import ImageUploader from '../../../Components/ImageUploader';

const Styles = () => {
    const [formikProps, setFormikProps] = useState<any>(null);
    const formSettings = useAppSelector(formSettingsStoreData)
    const dispatch = useAppDispatch();
    const [formConfigurations, setFormConfigurations] = useState<any>({})
    useEffect(() => {
        setFormConfigurations({
            formWidth: formSettings?.formWidth || '100',
            formWidthOptions: {
                label: formSettings?.formWidthOptions?.value || 'Percentage',
                value: formSettings?.formWidthOptions?.name || 'percentage'
            },
            formBackgroundColor: formSettings?.formBackgroundColor || '#FFFFFF',
            formBorderType: formSettings?.formBorderType || 'none',
            formBackgroundImageUrl: formSettings?.formBackgroundImageUrl || '',
            formBackgroundImageFitting: formSettings?.formBackgroundImageFitting || 'fill',
            formBorderColor: formSettings?.formBorderColor || '#FFFFFF',
            formBorderWidth: formSettings?.formBorderWidth || "0px",
            formBorderRadius: formSettings?.formBorderRadius || "0px",
            formPadding: formSettings?.formPadding || "10px",
            formMargin: formSettings?.formMargin || "0px",
            backgroundRepeat: formSettings?.backgroundRepeat || 'no-repeat',
            backgroundPosition: formSettings?.backgroundPosition || 'center',
            button_style: {
                button_position: formSettings?.button_style_position || "center",
                button_radius: formSettings?.button_style?.button_radius || "10px",
                cover_whole_width: formSettings?.button_style?.cover_whole_width === true ? "yes" : "no" || "no",
            },
            fontSize: formSettings?.fontSize || "14px",
            button_background_color: formSettings?.button_background_color || "#FF0000",
            button_name: formSettings?.button_name || "Submit",
            button_text_color: formSettings?.button_text_color || "#FFFFFF",
            button_background_hover_color: formSettings?.button_background_hover_color || "#FF0000",
            button_text_hover_color: formSettings?.button_text_hover_color || "#FFFFFF",
            buttonBorderColor: formSettings?.buttonBorderColor || '#FF0000',
            buttonWeight: formSettings?.buttonWeight || '400',
            buttonPadding: formSettings?.buttonPadding || "8px",
            buttonMargin: formSettings?.buttonMargin || "0px",
            buttonBorder: formSettings?.buttonBorder || "0px",
            buttonfontSize: formSettings?.buttonfontSize || "16px",
            declarationColor: formSettings?.declarationColor || "#000000",
            declarationFontSize: formSettings?.declarationFontSize || "14px",
            fieldWidth: formSettings?.fieldWidth || "100%",
            fieldHeight: formSettings?.fieldHeight || "35px",
            show_label: formSettings?.show_label === true ? "yes" : "no" || "",
            fieldBorderRadius: formSettings?.fieldBorderRadius || "4px",
            fieldBorderColor: formSettings?.fieldBorderColor || "#D3D3D3",
            fieldPlaceholderColor: formSettings?.fieldPlaceholderColor || "#4d5556",
            fieldTextColor: formSettings?.fieldTextColor || "#4d5556",
            fieldGap: formSettings?.fieldGap || "16px",
            fieldFontSize: formSettings?.fieldFontSize || "16px",
            fieldPadding: formSettings?.fieldPadding || "8px",
            fieldMargin: formSettings?.fieldMargin || "0px",
            labelMarginBottom: formSettings?.labelMarginBottom || "6px",
            fieldLabelCase: {
                label: formSettings?.fieldLabelCase?.label || 'No',
                value: formSettings?.fieldLabelCase?.value || 'no'
            },
            fieldLabelColor: formSettings?.fieldLabelColor || '#1B2863',
            customCss: formSettings?.customCss || ''
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (formikProps?.values) {
            const data = {
                ...formikProps?.values,
                show_label: formikProps?.values?.show_label === "yes" ? true : false,
                button_style: {
                    "button_position": formikProps?.values?.button_style?.button_position,
                    "button_radius": formikProps?.values?.button_style?.button_radius,
                    "cover_whole_width": formikProps?.values?.button_style?.cover_whole_width === "yes" ? true : false,
                }
            }
            dispatch(addSettings(data));
        }
    }, [formikProps?.values]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleResetClick = (label: string) => {
        const basicSection = ['formWidth', 'formWidthOptions', 'formBackgroundColor', 'backgroundPosition', 'backgroundRepeat', 'formBorderType', 'formBackgroundImageUrl', 'formBackgroundImageFitting', 'formBorderColor', 'formBorderWidth', 'formBorderRadius', 'formPadding', 'formMargin'];
        const formFieldsSection = ["fieldWidth", "fieldHeight", "show_label", "fieldBorderRadius", "fieldBorderColor", "fieldPlaceholderColor", "fieldTextColor", "fieldGap", "fieldFontSize", "fieldPadding", "fieldMargin", "fieldLabelCase", "fieldLabelColor", "labelMarginBottom"];
        const buttonSection = ['button_name', 'fontSize', 'button_style.button_radius', 'button_style.button_position', 'button_style.cover_whole_width', 'buttonfontSize', 'button_background_color', 'button_text_color', 'button_background_hover_color', 'button_text_hover_color', "buttonMargin", "buttonPadding", "buttonWeight", "buttonBorder", "buttonBorderColor"];
        const declarationTextSection = ["declarationColor", "declarationFontSize"]

        if (label === 'Form Styles') {
            basicSection.forEach((field: any) => {
                formikProps.setFieldValue(field, formikProps.initialValues[field])
            })
        } else if (label === 'Form Fields') {
            formFieldsSection.forEach((field: any) => {
                formikProps.setFieldValue(field, formikProps.initialValues[field])
            })
        } else if (label === 'Button') {
            buttonSection.forEach((field: any) => {
                formikProps.setFieldValue(field, formikProps.initialValues[field])
            })
        } else if (label === 'Declaration Text') {
            declarationTextSection.forEach((field: any) => {
                formikProps.setFieldValue(field, formikProps.initialValues[field])
            })
        } else {
            formikProps?.setFieldValue("customCss", "")
        }
    }
    return (
        <AppForm
            initialValues={formConfigurations}
            validationSchema={FormStylesSchema}
            onSubmit={() => console.log("Submited")}>
            {/* Form Section */}
            <AppAccordion label='Form Styles' isDefaultOpen={true} isResetAvaliable={true} handleResetClick={handleResetClick}>
                <FormContainer>
                    <p className='info'>Customize your form and page background and form border color here.</p>
                    <div className='fieldsContainer'>
                        <AppFormField name='formWidth' label='Form Width' errorMessage='' />
                        <span>
                            <FieldLabel label='Format' />
                            <AppFormRadioField name='formWidthOptions' Options={formatOptions} />
                        </span>
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Background Color"
                            name="formBackgroundColor"
                            onColorChange={(color) => { console.log("color", color) }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                        <ImageUploader name="formBackgroundImageUrl" label='Background Image' />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormSelectField
                            label="Background Position"
                            Options={backgroundImagePositions}
                            name="backgroundPosition"
                            valueKey='value'
                            displayLabel='label'
                            tooltip="Used as the Form Border."
                        />
                        <AppFormSelectField
                            label="Background Repeat"
                            Options={backgroundRepeatOptions}
                            name="backgroundRepeat"
                            valueKey='value'
                            displayLabel='label'
                            tooltip="Used as the Form Border."
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormSelectField
                            label="Background Image Fitting"
                            Options={formBackgroundImageFittingOptions}
                            name="formBackgroundImageFitting"
                            tooltip="Used as the chatbots font option for chats."
                        />
                        <AppFormSelectField
                            label="Border Type"
                            Options={formBorderOptions}
                            name="formBorderType"
                            valueKey='value'
                            displayLabel='label'
                            tooltip="Used as the Form Border."
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormSelectField
                            label="Border Width"
                            Options={formBordeWidthOptions}
                            name="formBorderWidth"
                            valueKey='value'
                            displayLabel='label'
                            tooltip="Used as the Form Border."
                        />
                        <AppFormColorPicker
                            label="Border Color"
                            name="formBorderColor"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                    </div>

                    <div className='fieldsContainer'>
                        <AppFormField name='formPadding' label='Padding' errorMessage='' />
                        <AppFormField name='formMargin' label='Margin' errorMessage='' />
                    </div>
                </FormContainer>
            </AppAccordion>

            {/* Fields Section */}
            <AppAccordion label='Form Fields' isDefaultOpen={true} isResetAvaliable={true} handleResetClick={handleResetClick}>
                <FormContainer>
                    <p className='info'>Customize form input labels, label text sizes, and border colors</p>
                    <div className='fieldsContainer'>
                        <AppFormField name='fieldWidth' label='Width' errorMessage='' />
                        <AppFormField name='fieldHeight' label='Height' errorMessage='' />
                    </div>

                    <div className='fieldsContainer'>
                        <AppFormSelectField
                            label="Label Visible"
                            Options={showLableOptions}
                            name="show_label"
                            tooltip="Used as the chatbots font option for chats."
                        />
                        <AppFormSelectField
                            label="Border Radius"
                            Options={formFieldBorderRadiusOptions}
                            name="fieldBorderRadius"
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Border Color"
                            name="fieldBorderColor"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                        <AppFormColorPicker
                            label="Text Color"
                            name="fieldTextColor"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                    </div>

                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Placeholder Color"
                            name="fieldPlaceholderColor"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                        <AppFormSelectField
                            label="Gap"
                            Options={formFieldBorderRadiusOptions}
                            name="fieldGap"
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormField name='fieldFontSize' label='Font/Text Size' errorMessage='' />
                        <AppFormField name='fieldPadding' label='Padding' errorMessage='' />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormField name='fieldMargin' label='Margin' errorMessage='' />
                        <span>
                            <FieldLabel label='Label UpperCase' />
                            <AppFormRadioField name='fieldLabelCase' Options={labelUpperCase} />
                        </span>
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Label Color"
                            name="fieldLabelColor"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />

                        <AppFormSelectField
                            label="Label Bottom Margin"
                            Options={labelMarginBottomOptions}
                            name="labelMarginBottom"
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                </FormContainer>
            </AppAccordion>


            {/* Button Sextion */}
            <AppAccordion label='Button' isDefaultOpen={true} isResetAvaliable={true} handleResetClick={handleResetClick}>
                <FormContainer>
                    <p className='info'>Customize the form submit button color, border radius,and text size</p>
                    <div className='fieldsContainer'>
                        <AppFormField name='button_name' label='Text/Label' errorMessage='' />
                        <AppFormSelectField
                            label="Text/Font Size"
                            Options={formButtonFontSizeOptions}
                            name="buttonfontSize"
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormField name='button_style.button_radius' label='Border Radius' errorMessage='' />
                        <AppFormSelectField
                            label="Position"
                            Options={buttonPositionOptions}
                            name="button_style.button_position"
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormSelectField
                            label="Full Size"
                            Options={coverWholeWidthOptions}
                            name="button_style.cover_whole_width"
                            placeholder='Select'
                            tooltip="Used as the chatbots font option for chats."
                        />
                        <AppFormField name='buttonPadding' label='Padding' errorMessage='' />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormField name='buttonMargin' label='Margin' errorMessage='' />
                        <AppFormColorPicker
                            label="Background Color"
                            name="button_background_color"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Text Color"
                            name="button_text_color"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                        <AppFormColorPicker
                            label="Hover Background Color"
                            name="button_background_hover_color"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Hover Text Color"
                            name="button_text_hover_color"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                        <AppFormSelectField
                            label="Button Border"
                            Options={buttonBorderWidthOptions}
                            name="buttonBorder"
                            placeholder='Select'
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Button Border Color"
                            Options={buttonBorderWidthOptions}
                            name="buttonBorderColor"
                            placeholder='Select'
                            tooltip="Used as the chatbots font option for chats."
                        />
                        <AppFormSelectField
                            label="Font Weight"
                            Options={buttonBoldOptions}
                            name="buttonWeight"
                            placeholder='Select'
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                </FormContainer>
            </AppAccordion>
            {/* Declaration Text Customization */}
            <AppAccordion label='Declaration Text' isDefaultOpen={true} isResetAvaliable={true} handleResetClick={handleResetClick}>
                <FormContainer>
                    <div className='fieldsContainer'>
                        <AppFormColorPicker
                            label="Color"
                            name="declarationColor"
                            onColorChange={(color) => { }}
                            notSupportedHexCodes={notSupportedHexCodes}
                            tooltip="Used as a background color to highlight areas and buttons"
                        />
                        <AppFormSelectField
                            label="Font Size"
                            Options={formButtonFontSizeOptions}
                            name="declarationFontSize"
                            tooltip="Used as the chatbots font option for chats."
                        />
                    </div>
                </FormContainer>
            </AppAccordion >
            {/* Custom CSS */}
            <AppAccordion label='Custom CSS' isDefaultOpen={true} isResetAvaliable={true} handleResetClick={handleResetClick} >
                <FormContainer>
                    <p className='info'>Customize this widget's look & feel by adding CSS code below.</p>
                    <AppFormTextArea name='customCss' label='' rows="10" placeholder="Please write your css here..." />
                </FormContainer>
            </AppAccordion>
            <AppFormObserver setFormikProps={setFormikProps} />
        </AppForm >
    )
}

export default Styles;

const FormContainer = styled.div`
&&{
   width:100%;
  .fieldsContainer{
    gap:16px;
    display: grid; 
    grid-template-columns: 1fr 1fr;
    padding:8px 10px;
    }
}`
