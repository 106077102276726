import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LifecycleStageActions } from "./lifecycleStage.actions";

interface initialStateTypes {
    data: {
        lifecycleStages: any[],
        lifecycleStage: any,
        stageChangeMessage: string
    };
    error: string;
    loading: boolean;
}

const initialState: initialStateTypes = {
    data: {
        lifecycleStages: [],
        lifecycleStage: {},
        stageChangeMessage: ""
    },
    error: "",
    loading: false,
};

const LifecyleStageSlice = createSlice(
    {
        name: "LifecyleStageSlice",
        initialState,
        reducers: {
            resetError: (state: any, action?: PayloadAction<any>) => {
                state.error = "";

                // console.log("action.payload.activeSteps", state.data.activeSteps);
                return state;
            }
        },
        extraReducers: (builder) => {
            builder
                // Enable Channel Settings
                .addCase(
                    LifecycleStageActions.getLifecycleStageStructure.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    LifecycleStageActions.getLifecycleStageStructure.fulfilled,
                    (state, action) => {
                        state.loading = false
                        state.data.lifecycleStages = action.payload?.data?.data
                    }
                )
                .addCase(
                    LifecycleStageActions.getLifecycleStageStructure.rejected,
                    (state, action) => {
                        state.loading = false
                        state.error = action?.error?.message || ""
                    }
                )
                .addCase(
                    LifecycleStageActions.getLifecycleStage.pending,
                    (state, action) => {
                        state.error = ""
                        state.loading = true
                    }
                )
                .addCase(
                    LifecycleStageActions.getLifecycleStage.fulfilled,
                    (state, action) => {
                        state.loading = false
                        state.data.lifecycleStage = action.payload?.data?.data
                    }
                )
                .addCase(
                    LifecycleStageActions.getLifecycleStage.rejected,
                    (state, action) => {
                        state.loading = false
                        state.error = action?.error?.message || ""
                    }
                )
                // .addCase(
                //     LifecycleStageActions.lifecycleStageChangeState.pending,
                //     (state, action) => {
                //         state.error = ""
                //         state.loading = true
                //     }
                // )
                // .addCase(
                //     LifecycleStageActions.lifecycleStageChangeState.fulfilled,
                //     (state, action) => {
                //         state.loading = false
                //         state.data.stageChangeMessage = action.payload?.data?.message
                //     }
                // )
                // .addCase(
                //     LifecycleStageActions.lifecycleStageChangeState.rejected,
                //     (state, action) => {
                //         state.loading = false
                //         state.error = action?.error?.message || ""
                //     }
                // )
        }
    }
)
export const { resetError } = LifecyleStageSlice.actions;
export default LifecyleStageSlice.reducer;