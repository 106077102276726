import styled from "styled-components";
import { theme } from "../../Customization/Theme";

export const GraphWrapper = styled.div`
& {
    display:flex;
   flex-direction:row;
   justify-content:space-between;
   margin-right:8px;
   padding:10px 24px 10px 10px;
}
  
  .graph_header{
    width:100%;
    font-size:16px;
    display:flex;
    align-items:center;
    font-family: Inter;
    font-weight:500;
    .userGrowthTooltip{
      font-size:10px;
      color: ${theme.palette.default.darkGrey};
    }
  }

  .graphselect{
    background-color:white;
     color: blue;
    font-size: 16px;
     border: 0.1px solid blue;
    cursor: pointer;
    border-radius:10px;
    font-family: Inter;
  }
`;


export const Graph = styled.div`
  & {
    width:auto;
    height:100%;
    overflow:hidden;
    padding:24px;
  }
`;


export const NewGraphNativeSelect = styled.div`
position: relative;
width: 100%;
.mb0 {
  margin-bottom: 0px !important;
}
.new-select-main {
  max-width: 210px;
  min-width: 180px;
  width: 210px;
  height: 35px;
  background-color: #f5f6f8;
  border: 1px solid rgb(203, 205, 211);
  border-radius: 4px;
  float:right;

  &::placeholder {
    color: ${theme.palette.default.darkGrey} !important;
  }

  &:active {
    color: ${theme.palette.default.text};
  }
  &:focus {
    color: ${theme.palette.default.text};
  }
}
.clear-icon {
  background-color: ${theme.palette.default.lightGrey};
  position: absolute;
  top: 1px;
  right: 10px;
  height: calc(100% - 2px);
  display: flex;
  align-items: center;
  justify-content: center;
}
`




