// vendors
import { useContext } from "react";
import { v4 as uuid } from "uuid";
import cloneDeep from "lodash.clonedeep";

// contexts
import { BuilderContext, NodeContext } from "./../contexts";

// utils
import { createNewNode, computeNodesPath } from "./../utils";

const useAction = () => {
  const {
    registerNodes,
    registerActions,
    nodes,
    onNodeCreate,
    onNodeUpdate,
    onActionCreate,
    onActionDelete,
    onDeleteNode,
    activeNode,
    language,
    activeFlow,
    setActiveNode,
  } = useContext(BuilderContext);
  const currentNode = useContext(NodeContext);
  // Add Node
  const addNode = (_newNodeType: string, isInitialNode?: boolean, isChildNode?: boolean, childType?: string, type?: string) => {
    const node = currentNode as any;
    const newNode = type === 'action' ? createNewNode(registerActions, _newNodeType, childType, type) : createNewNode(registerNodes, _newNodeType, childType);
    if (!newNode) {
      return;
    }

    if (isInitialNode) {
      nodes?.splice(0, 0, newNode);
    } else {
      const path = Number(node.displayOrder);
      nodes?.splice(path + 1, 0, newNode);
    }
    setActiveNode(newNode.id);
    onNodeCreate(computeNodesPath(nodes, activeFlow.displayOrder), newNode);
    if (type === 'action') {
      updateNode(newNode.id, newNode.text, null, undefined)
    }
  };

  const prepareMediaResponse = (mediaDetails: any) => {
    let mediaObj = { type: "", value: '' };
    if (isMediaDetailsExists(mediaDetails)) {
      for (let key in mediaDetails) {
        mediaObj.type = key;
        mediaObj.value = mediaDetails[key];
      }
      return mediaObj;
    }
  }

  const isMediaDetailsExists = (mediaDetails: any) => {
    return mediaDetails && Object.keys(mediaDetails).length !== 0
  }

  const updateNode = (
    id: string,
    message: any,
    mediaDetails?: any,
    additionalData?: any,
    isChecked?: boolean,
    actions?: any
  ) => {
    const node = currentNode as any;
    let match = nodes.find((nodeObj: any) => {
      if (nodeObj?.nodeId) {
        return nodeObj.nodeId === activeNode || nodeObj.nodeId === id;
      } else {
        return nodeObj.id === activeNode || nodeObj.id === id;
      }
    });
    if (match) {
      if (language !== "english") {
        match[`response_${language.toLowerCase()}`] = [
          {
            type: "text",
            value: message,
            media: mediaDetails,
          },
        ];
      } else {
        if (message !== '') {
          const mediaResponse = prepareMediaResponse(mediaDetails);

          match.response = [
            {
              type: "text",
              value: message,
              media: mediaDetails,
            },
          ];

          if (mediaResponse) {
            match.response.push(mediaResponse)
          }
        } else {
          match.response = [];
        }
      }

      match.text = message;
      match.subType = match.isAction ? match.type : node?.nodeId ? node.subType : node.type;
      match.isValid = message !== "";
      if (actions?.type === 'user-query') {
        const filteredActions =
          match?.quickActions?.length > 0
            ? match.quickActions.filter(
              (action: any) => action.type !== actions?.type
            )
            : [];
        match.quickActions = [...filteredActions, ...[actions]];
      };


      if (match.subType === "transfer_to_faqs") {
        match.input = true;
        match.input_type = "TRANSFER_TO_FAQS";
      }

      if (match.subType === "name") {
        match.input = true;
        match.input_type = "NAME";
      }
      if (match.subType === "email") {
        match.input = true;
        match.input_type = "EMAIL";
      }
      if (match.subType === "phone-number") {
        match.input = true;
        match.input_type = "MOBILE";
        if (isChecked) {
          match.isChecked = true;
        } else {
          match.isChecked = false;
        }
      }

      if (match.subType === "otp") {
        match.input = true;
        match.input_type = "OTP";
      }

      if (match.subType === "alphanumeric") {
        match.input = true;
        match.input_type = "ALPHANUMERIC";
      }

      if (match.subType === "number") {
        match.input = true;
        match.input_type = "NUMBER";
      }

      if (match.subType === "img-file") {
        match.input = true;
        match.input_type = "FILE_UPLOAD";
        match = { ...match, ...additionalData };
      }

      if (match.subType === "textfield") {
        match.input = true;
        match.input_type = "TEXT";
      }

      if (match.subType === "time") {
        match.input = true;
        match.input_type = "TIME";
        match = {...match, time: additionalData};
      }

      if (match.subType === "date") {
        match.input = true;
        match.input_type = "DATE";
        match = {...match, ...additionalData};
      }

      if (match.subType === "carousel") {
        if (language !== "english") {
          match[`response_${language.toLowerCase()}`] = [
            ...match[`response_${language.toLowerCase()}`],
            ...additionalData,
          ];
        } else {
          const carouselRes = { type: "carousel", value: additionalData };
          match.response = [carouselRes];
        }
      }
      if (match.subType === "button") {
        if (language !== "english") {
          match[`response_${language.toLowerCase()}`] = [
            ...match[`response_${language.toLowerCase()}`],
            ...additionalData,
          ];
        } else {
          match.response = [...match.response, ...additionalData];
        }
      }
      if (match.subType === "article") {
        match.article = additionalData;
      }

      if (match.subType === "custom") {
        match.input_type = "CUSTOM";
        match = { ...match, ...additionalData };
      }

      if (match.subType === "dropdown") {
        match.input_type = "DROPDOWN";
        match = { ...match, ...additionalData };
      }

    }
    onNodeUpdate(match, computeNodesPath(nodes, activeFlow.displayOrder));
  };
  // Update Node
  const removeNodeIds = (targetNodeIds: string[], allNodes: any[]) => {
    const restNodes = allNodes.filter(
      (item) => !targetNodeIds.includes(item.id)
    );
    return restNodes;
  };

  const removeNode = (targetNode: any = currentNode, isChildNode?: boolean) => {
    if (!targetNode) {
      return;
    }
    if (!targetNode.nodeId) {
      const restNodes = nodes.filter(
        (item: any) =>
          item.nodeId !== targetNode.nodeId || item.id !== targetNode.id
      );
      onDeleteNode(false, false, restNodes, targetNode.id);
    }
    if (targetNode.nodeId) {
      onDeleteNode(targetNode.nodeId, targetNode.qid);
    }
  };

  const cloneNode = (targetNode: any = currentNode) => {
    if (!targetNode) {
      return;
    }

    const path = Number(currentNode.displayOrder);
    const duplicatedNode = {
      ...currentNode,
      id: uuid(),
      displayOrder: path + 1,
    };
    nodes?.splice(path + 1, 0, duplicatedNode);
    delete duplicatedNode.nodeId;
    onNodeUpdate(
      duplicatedNode,
      computeNodesPath(nodes, activeFlow.displayOrder)
    );
    onNodeCreate(
      computeNodesPath(nodes, activeFlow.displayOrder),
      duplicatedNode
    );
  };

  const addAction = (type: string, node: any) => {
    const tempNodes = cloneDeep(nodes);
    tempNodes.map((nodeObj: any) => {
      if (nodeObj.nodeId === node.nodeId) {
        if (type === "transfer_to_faqs") {
          const action = {
            id: uuid(),
            type: type,
            input: true,
            input_type: "TRANSFER_TO_FAQS",
            text: 'Please input your query below.',
          };
          if (nodeObj.actions && Array.isArray(nodeObj.actions)) {
            nodeObj.actions = [...nodeObj.actions, ...[action]]
          } else {
            nodeObj.actions = [action]
          }
        }

        if (type === "connect_node") {
          const action = {
            id: uuid(),
            type: type,
            nodeId: '',
            qid: '',
          };
          if (nodeObj.actions && Array.isArray(nodeObj.actions)) {
            nodeObj.actions = [...nodeObj.actions, ...[action]]
          } else {
            nodeObj.actions = [action]
          }
        }

        if (type === "function") {
          const action = {
            id: uuid(),
            type: type,
            text: '',
          };
          if (nodeObj.actions && Array.isArray(nodeObj.actions)) {
            nodeObj.actions = [...nodeObj.actions, ...[action]]
          } else {
            nodeObj.actions = [action]
          }
        }
      }
    });
    onActionCreate(computeNodesPath(tempNodes, activeFlow.displayOrder));
  };

  const deleteAction = (node: any, id: string) => {
    const tempNodes = cloneDeep(nodes);
    tempNodes.map((nodeObj: any) => {
      if (nodeObj.nodeId === node.nodeId) {
        if (nodeObj.actions.length > 0) {
          nodeObj.actions = nodeObj.actions.filter((action: any) => action.id !== id);
        }
      }
    });
    const updateNode = tempNodes.filter((nodeObj: any) => nodeObj.nodeId === node.nodeId);
    onActionDelete(updateNode[0], computeNodesPath(tempNodes, activeFlow.displayOrder))
  };

  const updateAction = (actionData: any, node: any, data: any) => {
    const tempNodes = cloneDeep(nodes);
    let match = nodes.find((nodeObj: any) => {
      if (nodeObj?.nodeId) {
        return nodeObj.nodeId === activeNode || nodeObj.nodeId === node.nodeId;
      } else {
        return nodeObj.id === activeNode || nodeObj.id === node.id;
      }
    });

    if (data) {
      tempNodes.map((nodeObj: any) => {
        if (nodeObj.nodeId === node.nodeId) {
          if (nodeObj.actions.length > 0) {
            nodeObj.actions = nodeObj.actions.map((action: any) => {
              return { ...action, ...data, ...{ isSaved: true } }
            });
          }
        }
      });
      match.actions = match?.actions?.map((action: any) => {
        return { ...action, ...data, ...{ isSaved: true } }
      });
    }
    onNodeUpdate(match, computeNodesPath(tempNodes, activeFlow.displayOrder));
  };

  return {
    cloneNode,
    addNode,
    updateNode,
    removeNode,
    addAction,
    deleteAction,
    updateAction
  };
};

export default useAction;
