import http from "../../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class RoleNetworkService {

  // User Listing
  static getRoleListing(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.USER_ROLES}`;
    const { page_size, page_number, search } = data;
    const params = { page_size, page_number, search };
    return http.get(url, { params });
  }
  static getPermissionOptions() {
    const url = `${consoleBaseUrl}${Endpoints.PERMISSIONS_OPTIONS}`;
    return http.get(url);
  }

  // Permission Listing
  static getPermissionListing(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.USER_PERMISSIONS_LIST}`;
    const { id } = data;
    return http.get(`${url}/${id}`);
  }

  static getInitialPermissions() {
    const url = `${consoleBaseUrl}${Endpoints.GET_INITIAL_PERMISSIONS}`;
    return http.get(`${url}`)
  }
  // Add Role
  static postRole(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.ADD_USER_ROLES}`;
    return http.post(url, data);
  }

  // Update Role
  static putRole(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.UPDATE_USER_ROLES}`;
    const { id } = data;
    const newData = {
      label: data.label,
    };
    return http.put(`${url}/${id}`, newData);
  }
  // update Permission
  static putPermission(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.UPDATE_USER_PERMISSIONS}`;
    const { id, permission_config, label,plan_code } = data;
    return http.put(`${url}/${id}`, { label: label, permission_config: permission_config,plan_code });
  }
  // Delete user By ID
  static deleteUser(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.DELETE_ROLES}`;
    const { id } = data;

    return http.delete(`${url}/${id}`);
  }
}

class Endpoints {
  static USER_ROLES = "/workspace/users/roles";
  static DELETE_ROLES = "/workspace/users/roles";

  static USER_PERMISSIONS_LIST = "/workspace/users/roles";

  static ADD_USER_ROLES = "/workspace/users/roles";

  static UPDATE_USER_ROLES = "/workspace/users/roles";

  static UPDATE_USER_PERMISSIONS = "/workspace/users/roles";
  static PERMISSIONS_OPTIONS = "/core/users/access_level"

  static GET_INITIAL_PERMISSIONS = "/workspace/users/get-blank-perm-config"
}
