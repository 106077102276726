import { Box, DialogTitle, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AppButton from "../../../AppComponents/AppButton";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { useNavigate } from "react-router-dom";
import AppModel from "../../../Hooks/useModel/AppModel";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const ExportConfirmation = (props: any) => {
  const { onClose, selectedComponent } = props;
  const navigate = useNavigate();

  return (
    <AppModel
      isShowing={selectedComponent === true}
      onClose={onClose}
      divStyle={{
        padding: 0,
        // overflow: "visible",
      }}
      PaperProps={{
        style: {
          boxShadow: "none",
        },
      }}
    >
      <Box
        sx={{
        maxWidth: "671px",
      }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "1rem",
            alignItems: "center",
          }}
        >
          <StyledDialogTitle>Export in Progress 🚀</StyledDialogTitle>
          <CloseIcon
            onClick={() => onClose()}
            sx={{
              cursor: "pointer",
            }}
          />
        </Box>
        <Divider />
        <div>
          <Message style={{ fontSize: "1rem", color: "gray" }}>
            Your export is underway in the background. You can track the status
            and download your files later from the Export History page.
          </Message>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px",
              gap: "10px",
            }}
          >
            <AppButton
              variant="filled"
              onClick={() => onClose()}
            >
              Stay on this page
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() =>
                navigate("/settings/data-management/export-contact-history")
              }
            >
              Continue to Export History page
            </AppButton>
          </Box>
        </div>
      </Box>
    </AppModel>
  );
};

export default ExportConfirmation;

const Message = styled("p")`
  font-size: 1rem;
  color: ${theme?.palette?.default?.grey};
  display: flex;
  justify-content: center;
  padding: 16px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  && {
    cursor: default;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 20px;
    color: #282828;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0;
    svg {
      font-size: 1.1rem;
      color: ${theme.palette.default.error};
    }
  }
`;
