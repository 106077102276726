import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { device } from "../../../Customization/BreakPoints";

export const BillingMainWrapper = styled.div`
  min-height: calc(100vh - 240px);
  .fields-container {
    width: 100%;
    background-color: ${theme.palette.default.white};
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
  }
  .sms_right {
    display: flex;
    justify-content: flex-end;
  }
`;

export const LeftCol = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  color: ${theme.palette.default.darkGrey};
  flex-wrap: wrap;
  background-color: ${theme.palette.default.white};
  .limit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    strong {
      color: ${theme.palette.default.text};
    }
  }
  span {
    color: ${theme.palette.default.text};
    margin-left: 5px;
  }
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-left: 5px;
  }
  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
  }
`;

// Css for topbar
export const TopBar = styled.div`
  box-shadow: rgba(18, 52, 77, 0.16) 0px 1px 4px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${theme.palette.default.white};
  box-shadow: 0px 2px 4px 0px #00000014;
  position: relative;
`;

// Css for topbar rightCol
export const RightCol = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  // gap: 1.4rem;
  .contact {
    .contact-label {
      color: ${theme.palette.default.darkGrey};
      margin-right: 4px;
    }
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e1e1e1;
    padding-right: 8px;
  }
  .monthly-conversations__wrapper {
    padding-left: 8px;
  }
  .conversations {
    display: flex;
    align-items: center;
    /* gap: 0.6rem; */

    .monthly-conversations {
      display: flex;
      align-items: center;
      button {
        padding: 0;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }

    label > .MuiBox-root {
      display: inline-block;
      button {
        padding: 0;
        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
    .conversations-label {
      color: ${theme.palette.default.darkGrey};
      strong {
        color: ${theme.palette.default.text};
      }
    }
  }
  .credits {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
  }

  @media ${device.laptopL2} {
    font-size: 13px;
  }
  @media ${device.laptop2} {
    font-size: 12px;
    gap: 0.8rem;
    .buy-credits__btn {
      button {
        width: 93px;
      }
    }
  }
`;

export const TopInfoBarBox = styled.div`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0px;
    background-color: ${theme.palette.default.white};
    padding: 0;
    h6 {
      color: ${theme.palette.default.black};
    }

    p {
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
export const BillingTableWrapper = styled.div`
  padding: 16px;
`;
export const BillingLogTable = styled.div`
  & {
    width: 100%;
    > div {
      overflow: auto;
      height: calc(100vh - 300px);
    }
    .table-header {
      background-color: #efeefc;
      position: sticky;
      top: 0;
      .table-row {
        display: flex;
        align-items: center;
      }
      .date {
        max-width: 220px;
        min-width: 220px;
      }
      .from {
        min-width: 150px;
      }
      .to {
        min-width: 150px;
        // border: 1px solid;
      }
      .body {
        max-width: 420px;
        min-width: 420px;
      }
      .status {
        min-width: 150px;
      }
      .error_reason {
        max-width: 200px;
        min-width: 200px;
      }
      .price {
        min-width: 150px;
      }
      .price {
        min-width: 150px;
      }
      .unit {
        min-width: 150px;
      }
      .total_price {
        min-width: 150px;
      }
      .direction {
        min-width: 150px;
      }
      .col-head {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        padding: 16px 0;
        background-color: ${theme.palette.default.white};
        p {
          padding: 0 16px;
          border-left: 1px solid rgba(126, 131, 146, 0.5);
          font-size: 13px;
        }
        &:first-child {
          p {
            border: none;
          }
        }
      }
    }
    .table-body {
      background-color: ${theme.palette.default.white};
      .table-row {
        display: flex;
        padding: 10px 0;
        color: ${theme.palette.default.black};
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .date {
        max-width: 220px;
        min-width: 220px;
        // border: 1px solid;
      }
      .from {
        // max-width: 150px;
        min-width: 150px;
        // border: 1px solid;
      }
      .to {
        // max-width: 150px;
        min-width: 150px;
        // border: 1px solid;
      }
      .body {
        max-width: 420px;
        min-width: 420px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .status {
        // max-width: 150px;
        min-width: 150px;
      }
      .error_reason {
        max-width: 200px;
        min-width: 200px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .price {
        // max-width: 150px;
        min-width: 150px;
      }
      .unit {
        // max-width: 150px;
        min-width: 150px;
      }
      .total_price {
        // max-width: 150px;
        min-width: 150px;
      }
      .direction {
        // max-width: 150px;
        min-width: 150px;
      }
      //   .table-row > div:last-child {
      //     display: flex;
      //     justify-content: flex-end;
      //   }
      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.3);
        text-transform: capitalize;
        p {
          font-size: 13px;
          color: ${theme.palette.default.black};
        }
      }
    }
  }
`;
