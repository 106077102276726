import { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from "yup";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AppButton from '../../../../AppComponents/AppButton';
import { AppMaterialIcons } from '../../../../AppComponents/AppMaterialIcons';
import { AppFormField, AppFormSelectField, AppSubmitButton } from '../../../../AppComponents/Forms';
import { FilterPannel } from '../../../../Customization/CommonElements';
import AppModel from '../../../../Hooks/useModel/AppModel';
import { useNotification } from '../../../../Hooks/useNotification/useNotification';
import { useAppDispatch, useAppSelector } from '../../../../Store';
import { EmailActions } from '../../../../Store/Slices/email/email.actions';
import { EmailHtmlPreview } from './EmailTemplateListingStyle';
import { BasicType, BlockManager, JsonToMjml } from 'easy-email-core';
import { MainBroadCastTitleBar } from '../../Utils/CommomBroadcastStyle';
import HTMLEditor from './Editors/htmlEditor';
import Yakou from './Editors/yakou';
import { ProtectedAppForm } from '../../../../AppComponents/ProtectedComponents';
import { findFormSpecificPermissionKeyUsingLocation } from '../../../../Utils/rolesUtils';
import { ReactComponent as CreateTemplate } from '../../../../assets/images/create-template.svg';
import { getIsCountinued } from '../../../../Store/Slices/email/email.selectors';
import { setClickOnContinue } from '../../../../Store/Slices/email/email.slice';

var mjml2html = require('mjml-browser');
const parameters = new URLSearchParams(window.location.search);
export const EmailCreateTemplate = () => {
  const [userInput, setUserInput] = useState({
    name: "",
    template_options: ""
  })
  const [isShowingModal, toggleModal] = useState<boolean>(false);
  const next = useAppSelector(getIsCountinued)
  const [localValues, setLocalValues] = useState<any>({
    template_options: "",
    template_html: ""
  });
  //*WILL BE USED TO SET THE FORM ACCESS TYPE 
  const [formAccessType, setFormAccessType] = useState<"create" | "read" | "update" | "delete" | undefined>();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findFormSpecificPermissionKeyUsingLocation();
    if (result === "update" && parameters?.get("view")) {
      setFormAccessType("read");
    }
    else {
      setFormAccessType(result);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const templateOptions = [
    { name: "Create Template" },
    { name: "Write HTML" },
  ];
  const [template, setTemplate] = useState<boolean>(false);
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const [initialValues, setInifialValues] = useState({
    subject: 'Welcome to Easy-email',
    subTitle: 'Nice to meet you!',
    content: localStorage.getItem("JSONTemplate") ? JSON.parse(localStorage.getItem("JSONTemplate") || "")?.content : BlockManager.getBlockByType(BasicType.PAGE)!.create({}),
  });
  useEffect(() => {
    if (localStorage.getItem("JSONTemplate")) {
      setInifialValues({
        subject: 'Welcome to Easy-email',
        subTitle: 'Nice to meet you!',
        content: localStorage.getItem("JSONTemplate") ? JSON.parse(localStorage.getItem("JSONTemplate") || "")?.content : BlockManager.getBlockByType(BasicType.PAGE)!.create({}),
      })
    }
  }, [localStorage.getItem("JSONTemplate")]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTemplate(true);
    localStorage.removeItem("HTMLTemplate");
    localStorage.removeItem("JSONTemplate");
  }, [])
  const { showAlert } = useNotification();

  const handleSubmit = (values: any, submitProps: any) => {
    let payload: any = {
      "name": values.name.trim(),
      "template_body": localStorage.getItem("HTMLTemplate"),
      "template_design": null,
    }
    dispatch(EmailActions.postEmailTemplateCreate(payload))
      .then((payload: any) => {
        if (!payload.error) {
          showAlert("Template created", "success");
          navigate("/broadcast/email/templates");
          localStorage.removeItem("HTMLTemplate");
          localStorage.removeItem("JSONTemplate");
        } else {
          showAlert(payload.payload.data.error || "Error while creating template", "error");
        }
      })
  };
  const sendValuesToParent = (values: any, isName?: boolean | undefined): void => {
    if (isName) {
      setUserInput(values)
    } else {
      setLocalValues(values);
      setUserInput({ ...userInput, ...values })
    }
  };
  const onSubmitJSON = (values: any) => {
    const mjmlString = JsonToMjml({
      data: { ...values.content },
      mode: 'production',
      context: { ...values.content },
    });
    const html = mjml2html(mjmlString, {}).html;
    localStorage.setItem("HTMLTemplate", JSON.stringify(html));
    localStorage.setItem("JSONTemplate", JSON.stringify(values));
    let payload: any = {
      "name": userInput.name.trim(),
      "template_body": JSON.stringify(html),
      "template_design": values,
    }
    dispatch(EmailActions.postEmailTemplateCreate(payload))
      .then((payload: any) => {
        if (!payload.error) {
          showAlert("Template created", "success");
          navigate("/broadcast/email/templates");
          localStorage.removeItem("HTMLTemplate");
          localStorage.removeItem("JSONTemplate");
        } else {
          showAlert(payload.payload.data.error || "Error while creating template", "error");
        }
      }).catch((error: any) => {
      })
  }
  const EmailCreateTemplate = Yup.object().shape({
    name: Yup.string().required().min(3).max(100).label("Name"),
    template_options: Yup.string().required().min(2, "Please Select an option").max(100).label("Template option"),
  });
  const handleNext = () => {
    if (localValues.template_options?.length > 0 && userInput.name.length > 0) {
      dispatch(setClickOnContinue(true));
    }
  }

  const handleBack = () => {
    dispatch(setClickOnContinue(false));
  }

  useEffect(() => {
    return () => {
      localStorage.removeItem("HTMLTemplate");
      localStorage.removeItem("JSONTemplate");
    }
  }, [])

  return (
    <Container>
      {isShowingModal && <AppModel
        isShowing={true}
        onClose={toggleModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "750px",
          },
        }}
      >
        <EmailHtmlPreview>
          <div className='header'>
            <div className='header-top'>
              <h2>HTML Preview</h2>
              <AppButton variant='grey' onClick={() => toggleModal(false)} >
                Exit Preview
              </AppButton>
            </div>
            <div className='header-left'>
              <iframe
                title="HTML Preview"
                srcDoc={JSON.parse(localStorage?.getItem('HTMLTemplate') || "")}
                className='iframe'
                style={{ width: "700px", height: "80vh", border: "1px solid #ccc", margin: "25px 0 0 23px", padding: "5px" }}
              />
            </div>
          </div>
        </EmailHtmlPreview>
      </AppModel>}

      {template &&
        <ProtectedAppForm initialValues={userInput}
          validationSchema={EmailCreateTemplate}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmit(values, submitProps);
          }}
          moduleId="email_template"
          specificPermissionKey={formAccessType}
        >
          <div className=''>
            <MainBroadCastTitleBar>
              <div className="left">
                <IconButton onClick={() => navigate(-2)}>
                  <AppMaterialIcons
                    iconName={"ArrowBackIosRounded"}
                    style={{ fontSize: "0.8rem" }}
                  /></IconButton>
                <p>
                  Create Email Template
                </p>
              </div>
              <div className="right"></div>
            </MainBroadCastTitleBar>

            <FilterPannel style={{ borderRadius: 0, margin: "1px 0px 0px 0px" }}>
              <Box sx={{ width: "100%" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", gap: "1rem", width: "100%" }}>
                    <div style={{ minWidth: "270px" }}>
                      <AppFormField
                        name='name'
                        placeholder='Template Name'
                        label=' Template Name'
                        labelStyle={{ fontSize: "13px" }}
                        className="custom-input"
                        sendValuesToParent={(values: any) => sendValuesToParent(values, true)}
                        disabled={next === true ? true : false}
                        requiredSign={true}
                      />
                    </div>
                    <div style={{ width: "40%" }}>
                      <AppFormSelectField
                        name="template_options"
                        label="Select template Option"
                        labelStyle={{ fontSize: "13px" }}
                        Options={templateOptions}
                        valueKey="id"
                        divStyle={{ minWidth: "220px", maxWidth: "260px" }}
                        sendValuesToParent={(values: any) => sendValuesToParent(values)}
                        disabled={next === true ? true : false}
                        requiredSign={true}
                      />
                    </div>
                  </div>
                  {localValues.name !== "" && localValues.template_options === "Write HTML" && next === true ?
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "15px" }}>
                      <div style={{ display: "flex", gap: "8px" }}>
                        <AppButton variant='outline' onClick={handleBack}>
                          Back
                        </AppButton>
                        <AppButton variant='outline' onClick={() => toggleModal(true)}>
                          <VisibilityOutlinedIcon style={{ fontSize: "30px" }} />
                        </AppButton>
                        <AppSubmitButton title="Save & Continue" variant='outline' />
                      </div>
                    </div>
                    : !next === true &&
                    <div style={{ display: "flex", gap: "8px", marginTop: "15px" }}>
                      <AppSubmitButton title="Continue" onClick={handleNext} />
                    </div>
                  }
                </div>
              </Box>
            </FilterPannel>
          </div>

          {localValues.name !== "" && localValues.template_options === "Write HTML" && next ? (
            <HTMLEditor />
          ) : localValues.template_options === "Create Template" && next === true ? (
            <Box sx={{ mt: "8px", mr: "16px", ml: "16px" }}>
              <EmailEditorWrapper>
                <Yakou onSubmit={onSubmitJSON} handleBack={handleBack} initialValues={initialValues} />
              </EmailEditorWrapper>
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: "calc(100vh - 220px)", overflow: "auto" }}>
              <CreateTemplate />
            </Box>
          )}
        </ProtectedAppForm>}
    </Container >
  );
};


const EmailEditorWrapper = styled.div`
.arco-layout .arco-layout-sider:last-child ._Item-fill_1jdgv_131{
  .arco-collapse .arco-collapse-item:first-child {
    display: none;
  }
  .arco-tabs-header .arco-tabs-header-title{
    display: none;
    &.arco-tabs-header-title-active {
      display: block;
    }
  }
} 
`
const Container = styled.div`
  display: flex;
  flex-direction: column; 
  position: relative;
  height: 100%;
  width: 100%;

  .mainDiv{
    flex: 1;
    background-color: #dbd9d9;
    color: #000;
    padding: 10px;
    display: flex;
    max-height: 80px;

    button {
      padding: 25px;
      padding-top: 20px;
      margin-left: 10px;
      margin-top: 10px;
      max-width: 150px;
      cursor: pointer;
    }
  }

`;


