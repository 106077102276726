import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import recibackground_logo from "../../../assets/images/emailrecipients.png";
import Mesh from "../../../assets/images/Mesh.svg";
import dotted_mesh from "../../../assets/images/bgmesh.png";

export const SummaryWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    width: 100%;

    .parent_div2 {
      width: 100%;
      padding: 6px;
      height: auto;
    }

      .horizontal_line{
        height: auto;
        border: 0.2px solid gray; 
        opacity:50%; 
      }
      
      .credits_btn{
        width: 33%;
        display:flex ;
        justify-content:space-between;
        border-radius:38px;
        background-color:#ffffff;
        color:black;
        margin-top:10px;
      }
      .credits_btn>p{
        font-color:black;
      }
      
      
      .box-wrap{
        width: 100%;
       height: 100%;
        // background-color: white;
        border-radius: 4px;
        //padding: 16px 20px;
      }
      .box-wrapper{
        width: 100%;
        height: 100%;
      }
      .chart_text_div{
        padding:0px 40px;
        align-items:center;
        text-align:center;
      }
      .chart_text{
        font-weight:400;
        font-family:Inter;
        font-size:14px;
        color:#7E8392;
      }
      .chartTotalCount{
        font-size:16px;
        font-weight:500;
        font-family:Inter;
        text-align:center;
        color:#101010;
        padding-top:18px;
      }
      
      
      .j_div_Link{
        // display:flex;
        // flex-direction:column;
       
      }
      .j_div_Link_all{
        display:flex;
        flex-direction:column;
        overflow:auto;
        // max-height: 17rem;
        max-height: calc(100% - 78px);
        padding-left: 1rem;
        margin-right: 8px;
        padding-right: 8px;
        padding-top: 8px;
        margin-left: 8px;
        margin-top: 8px;
      }
      .link_header{
        font-size:25px;
        font-family:Poppins;
        color:#000000;
        
      }
      .date_with_link{
        width:100%;
        // margin:auto;
        padding: 1rem;
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        align-items:center;
        border-bottom:1px solid ${theme.palette.default.grey};
      }
      .Date_info{
        color:#7E8392;white
        font-family:Poppins;
        font-weight:400;
        font-size:14px;
      }
      .link_a{
        color:#5B73E8;
        text-decoration:none;
      }
      .horizontal_line{
        border:0.4px solid #CBCDD3;
      }
      
      .dotted_hr {
        height: auto;
        border: 0.2px dashed gray; 
        opacity:50%; 
      }
      
      
`;

export const WrapEmail = styled.div`
  & {
    width: 100%;
    height: inherit;
    // border: 4px solid green;
    background-color: #fff;
    border-radius: 4px;


    
  }
`;

export const WrapperBUS = styled.div`
  & {
    width: 100%;
    height: 100%;
    // padding: 10px;
    background-color: white;
    border-radius: 4px;

    .Bus_wrap {
      height: 100%;
      margin: auto;
      padding: 12px;
      height: 100%;
    }

    .BUS_header {
      width: 85%;
      align-items: center;
    }

    .header_text {
      padding: 0px 0px 40px 0px;
      font-size: 40px;
    }

    .BUS_body {
      height: inherit;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 12px;
      @media (max-width: 1118px) {
        flex-direction: column;
      }
    }

    .Bounces_d {
      // min-width: calc(33.33% - 6px);
      // width: calc(33.33% - 6px);
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: center;
      @media (max-width: 1118px) {
        min-width: 100%;
        width: 100%;
      }
    }

    .Bounces_c {
      height: 100%;
      background-image: url(${Mesh});
      background-size: cover;
      background-repeat: no-repeat;
      // min-width: 400px;
      // min-width: 33.33%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 10px;
      // min-height: 22rem;
      // padding: 90px;
      // background-color: #fff4f2;
      border: 1px solid #ff9797;
    }

    .count {
      font-size: 36px;
      font-weight: 400;
      font-family: Inter;
      color: ${theme.palette.default.black};
    }

    .text {
      font-size: 18px;
      font-weight: 500;
      font-family: Inter;
      margin-bottom: 8px;
      color: ${theme.palette.default.black};
    }

    .text-info {
      font-size: 12px;
      color: ${theme.palette.default.darkGrey};
    }

    .description {
      font-size: 14px;
      font-weight: 400;
      font-family: Inter;
      color: ${theme.palette.default.darkGrey};
      margin-top: 30px;
    }
  }
`;

export const Wrapstatistics = styled.div`
  & {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 4px;
    .j_div_Link {
      height: inherit;
    }
  }
`;

export const GrahWrapper = styled.div`
  & {
    display: flex;
    flex-direction: column;
    // gap: 24px;
    height: 100%;

    .time_stats {
      font-size: 16px;
      display: flex;
      font-family: Inter;
      color: #101010;
      font-weight: 500;
      .div_tooltip {
        font-size: 15px;
      }
    }
    .header_avg {
      font-size: 14px;
      font-family: Inter;
      text-align: left;
      margin-bottom: -6px;
    }
    .progess_bar {
      padding: 0px 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-end;
      text-align: center;
    }
    .progress_bar_text {
      width: 15%;
      font-family: Inter;
      display: flex;
    }
    .progress_bar_count {
      width: 85%;
      font-family: Inter;
      .stats {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 6px;
      }
    }
    .graph_container {
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 20px;
      font-family: Inter;
    }
    .x_axis_data {
      width: 85%;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #4d4f52;
      font-family: Inter;
    }
  }
`;

export const WrapRecipients = styled.div`
  & {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 4px;
    padding: 20px;
    background-image: url(${recibackground_logo});
    background-position: right bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

export const RecipientsWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    position: relative;

    .main_header {
      font-size: 1rem;
      font-weight: 500;
      font-family: Inter;
      color: #ffffff;
    }

    .info_values {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .header {
      font-size: 16px;
      text-align: center;
      font-family: Inter;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
    .value {
      font-size: 32px;
      font-weight: 700;
      font-family: Inter;
      text-align: center;
      color: #ffffff;
    }
    .credits_btn {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 38px;
      background-color: #ffffff;
      color: black;
      padding 8px 1rem;
      margin-top: 10px;
      p {
        font-size: 15px;
        font-weight: 500;
      }
      
    }
  }
`;

export const UniqueEmailWrapper = styled.div`
  & {
    // background-image: url(${dotted_mesh});
    // background-size: auto;
    // background-repeat: no-repeat;
    // width: 100%;
    // height: 100%;
    // position: relative;



    width: 100%;
height: 100%;
background-color: white;
border-radius: 4px;
// padding: 20px;
background-image: url(${dotted_mesh});
background-position: right 10px bottom 10px;
background-size: cover;
background-repeat: repeat !important;




    .body {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 61px);
    }

    .main_header {
      font-size: 16px;
      font-weight: 500;
      font-family: Inter;
    }

    .info_values {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      gap: 1rem;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .header {
      font-size: 16px;
      text-align: center;
      font-family: Inter;
      font-weight: 500;
      text-align: center;
    }
    .value {
      font-size: 32px;
      font-weight: 700;
      font-family: Inter;
      text-align: center;
    }
    .credits_btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 38px;
      background-color: #d18fdb;
      color: #ffffff;
      margin-top: 10px;
    }
    .campaign_info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      .dotted_hr {
        height: 2rem;
        border: 0.2px dashed gray;
        opacity: 50%;
      }
      .campaign_value-wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
          font-size: 15px;
          font-weight: 500;
        }
        .campaign_value {
          color: ${theme.palette.default.darkGrey};
        }
      }
    }
  }
`;

export const CampaignWrapper = styled.div`
  & {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;

    .mainCampaign {
      padding-top: 3.3rem;
    }

    .main_header {
      font-size: 16px;
      font-weight: 500;
      font-family: Inter;
      color: ${theme.palette.default.newFontColor};
    }

    .info_values {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .header {
      font-size: 16px;
      text-align: center;
      font-family: Inter;
      font-weight: 500;
      text-align: center;
      color: ${theme.palette.default.newFontColor};
    }
    .value {
      font-size: 32px;
      font-weight: 700;
      font-family: Inter;
      text-align: center;
      color: ${theme.palette.default.newFontColor};
    }
    .credits_btn {
      width: 60%;
      display: flex;
      justify-content: space-between;
      border-radius: 38px;
      background-color: #d18fdb;
      color: #ffffff;
      margin-top: 10px;
    }
    .dotted_hr {
      height: auto;
      border: 0.2px dashed gray;
      opacity: 50%;
    }

    .distributed_values {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding-top: 20px;
    }
  }
`;

export const TextTitle = styled.div`
  && {
    position: relative;
    padding-left: 10px;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 4px;
      background: linear-gradient(
        to bottom,
        rgba(132, 90, 223, 0.5) 50%,
        rgba(35, 183, 229, 0.5) 50%
      );
      border-radius: 0.5rem;
    }
  }
`;
