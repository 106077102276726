import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import AppButton from "../../../../AppComponents/AppButton";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { AppForm, AppSubmitButton } from "../../../../AppComponents/Forms";
import AppReactSelectField from "../../../../AppComponents/Forms/AppFormReactSelect";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { useAppDispatch, useAppSelector } from "../../../../Store";
import { ContactPropertiesActions } from "../../../../Store/Slices/Settings/ContactProperties/contactProperties.action";
import { useSelector } from "react-redux";
import {
  DependantOptions,
  DependencyPropertiesListing,
  FieldsMappingObject,
  GetDependencyList,
  GetDependencyListCount,
  MappingObjectByID,
  PrimaryOptions,
  UpdatedSlectOptionID,
} from "../../../../Store/Slices/Settings/ContactProperties/contactProperties.selector";
import {
  UpdatedFields,
  UpdatedOptionID,
  clearTheIds,
  resetDependantOptions,
  resetDependencyObject,
  resetPrimaryOptions,
} from "../../../../Store/Slices/Settings/ContactProperties/contactProperties.slice";
import {
  DependencyListTopBar,
  DependencyListWrapper,
} from "./DependencyFieldListStyles";
import { AppPagination, TableWrapper } from "../../../../TableStyle";
import TemplateAPIERROR from "../../../../assets/images/No_records_found.svg";
import { ReactComponent as EditIcon } from "../../../../assets/images/editIcon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/images/deletegrey.svg";
import { DeleteCannedResponse } from "../../ChatSettings/CannedResponse/CannedResponsesstyles";
import { SearchInput } from "../../../FlowBuilder/ui-components";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";
import Skeleton from "react-loading-skeleton";

interface Props {}

const DependencyFiledList: React.FC<Props> = ({}) => {
  // DependencyPropertiesListing
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();

  const DependecyFieldListing = useSelector(DependencyPropertiesListing);
  const PrimaryOptionArray = useSelector(PrimaryOptions);
  const DependentOptionArray = useSelector(DependantOptions);
  const slectedIDDD = useSelector(UpdatedSlectOptionID);
  const MappingObject = useSelector(FieldsMappingObject);
  const MappingObjectById = useSelector(MappingObjectByID);
  const DependencyList = useSelector(GetDependencyList);
  const DependencyListCount = useSelector(GetDependencyListCount);
  const [selectedOptionId, setSelectedOptionId] = useState<any>("");
  const [selectedItemId, setSelectedItemId] = React.useState("");
  const [checkedOptions, setCheckedOptions] = React.useState<{
    [key: string]: boolean;
  }>({});
  const [editId, setEditId] = React.useState<any>("");
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isDelete, setIsDelete] = React.useState<boolean>(false);
  const [primaryFiledsListing, setPrimaryFiledsListing] = React.useState([]);
  const [selectedOptionIDS, setSelectedOptionIDS] = React.useState<any>([]);
  const [dependencyId, setDependencyId] = useState("");
  const [list, setList] = React.useState([
    { id: "123456", p_name: "Email Confirmed", d_name: "Time Zone" },
    { id: "789101", p_name: "School Type", d_name: "School Medium" },
  ]);
  const [PrimaryOptionList, setPrimaryOptionList] =
    useState(PrimaryOptionArray);
  const [DependecyOptionList, setDependecyOptionList] =
    useState(DependentOptionArray);
  const [mObject, setMObject] = React.useState<any>(MappingObject[0]);
  const navigate = useNavigate();
  useEffect(() => {
    setPrimaryOptionList(PrimaryOptionArray);
  }, [PrimaryOptionArray]);
  useEffect(() => {
    setDependecyOptionList(DependentOptionArray);
  }, [DependentOptionArray]);
  // console.log("DependencyList", DependentOptionArray, DependantOptions);

  const [initialValues, setInitialValues]: any = React.useState({
    field: "",
    dependant_field: "",
    option_mapping: [
      {
        option_id: "",
        ids: [],
      },
    ],
  });

  const AddDependencyModal = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingAddModal: isShowing, toggleAddModal: toggle };
  };
  const { isShowingAddModal, toggleAddModal } = AddDependencyModal();

  const handleSubmitFn = (values: any, submitProps: any) => {
    // ===== Validation ====
    // let selected;

    // if (selectedOptionId) {
    //     selected = MappingObject[0]?.option_mapping?.find((obj: any) => {
    //         return obj.option_id === selectedOptionId;
    //     });
    // }

    // console.log(selected)

    if (
      MappingObject?.length === 0 ||
      MappingObject[0]?.option_mapping.length === 0
    ) {
      submitProps?.setSubmitting(false);

      showAlert("Something went wrong", "error");
      return;
    }

    submitProps.setSubmitting(false);

    let data = {};
    if (isEditing) {
      data = {
        dependant_field_id: values?.dependant_field?.id,
        field_id: values?.field?.id,
        option_mapping: MappingObject[0]?.option_mapping,
        is_Edit: isEditing,
        id: dependencyId,
      };
    } else {
      data = {
        dependant_field_id: values?.dependant_field?.id,
        field_id: values?.field?.id,
        option_mapping: MappingObject[0]?.option_mapping,
      };
    }
    dispatch(ContactPropertiesActions.CreateDependencyMapping(data)).then(
      (res: any) => {
        dispatch(ContactPropertiesActions.GetDependencyList({}));
        clearall();
      }
    );
    // CreateDependencyMapping
  };

  const handleCheckBox = (optionId: string) => {
    setCheckedOptions((prevCheckedOptions: any) => {
      const newCheckedOptions = {
        ...prevCheckedOptions,
        [optionId]: !prevCheckedOptions[optionId],
      };

      // Get the selected option IDs
      const selectedIds = Object.keys(newCheckedOptions).filter(
        (id) => newCheckedOptions[id]
      );

      if (selectedIds) {
        setSelectedOptionIDS(selectedIds);
      }
      // Update the matching object with the selected option
      dispatch(
        UpdatedFields({
          ...MappingObject[0],
          currentId: optionId,
          option_mapping: [
            {
              option_id: slectedIDDD || selectedItemId,
              ids: selectedIds,
            },
          ],
        })
      );

      return newCheckedOptions;
    });
  };

  const handleChangePrimaryField = (selectedOption: any) => {
    // console.log(selectedOption?.id)
    dispatch(
      UpdatedFields({
        field: selectedOption?.id,
        dependant_field: "",
        option_mapping: [],
      })
    );

    // Filter out the selected primary field from the dependency field options
    // const filteredOptions = DependecyFieldListing.filter((option: any) => option.id !== selectedOption?.id);
    // setDependentFieldOptions(filteredOptions);
    const updatedOptions = dependentFieldOptions11.filter(
      (option: any) => option.id !== selectedOption.id
    );
    setDependentFieldOptions11(updatedOptions);
    dispatch(
      ContactPropertiesActions.GetPrimaryOptions(selectedOption?.id)
    ).then((res: any) => {
      // console.log("Primary Filed Res", res)
      setPrimaryFiledsListing(PrimaryOptionArray);
    });
  };

  const handleChangeDeoendentField = (selectedOption: any) => {
    const updatedOptions = primaryFieldOptions11.filter(
      (option: any) => option.id !== selectedOption.id
    );
    setPrimaryFieldOptions11(updatedOptions);
    dispatch(
      UpdatedFields({
        ...MappingObject[0],
        dependant_field: selectedOption?.id,
      })
    );
    dispatch(
      ContactPropertiesActions.GetDependantOptions(selectedOption?.id)
    ).then((res: any) => {
      // console.log("Dependant Field Res", res)
    });
  };

  const [primaryFieldOptions11, setPrimaryFieldOptions11] = React.useState(
    DependecyFieldListing
  );
  const [dependentFieldOptions11, setDependentFieldOptions11] = React.useState(
    DependecyFieldListing
  );

  React.useEffect(() => {
    // dispatch(ContactPropertiesActions.GetDependencyProperties({})).then((res: any) => {

    //     setPrimaryFieldOptions11(res?.payload?.data?.non_dependent_fields);
    //     setDependentFieldOptions11(res?.payload?.data?.depedent_fields);
    // });
    dispatch(ContactPropertiesActions.GetDependencyList({}));
  }, []);

  const handleListItemClick = (id: any) => {
    // selectedItemId
    setSelectedOptionId(id);
    dispatch(UpdatedOptionID(id));
    setSelectedItemId((prevId: any) => (prevId === id ? selectedItemId : id));

    // const checkForItemID = MappingObject[0]?.option_mapping.find((obj: any) => obj?.option_id === id)

    // if (selectedItemId === checkForItemID?.option_id) {
    //     console.log("Need to remove ids from ids")
    //     setSelectedOptionId("");
    //     dispatch(clearTheIds(checkForItemID?.option_id))
    // }

    const optionMappingArray = MappingObject[0]?.option_mapping || [];
    const indexOfMatchingObject = optionMappingArray.findIndex(
      (obj: any) => obj?.option_id === id
    );

    if (indexOfMatchingObject !== -1) {
      // If a matching object is found, update the state with the selected option
      const selectedIds = optionMappingArray[indexOfMatchingObject]?.ids || [];
      setCheckedOptions({
        [id]: selectedIds.includes(id),
      });
    } else {
      // If no matching object is found, clear the checkboxes
      setCheckedOptions({});
    }
  };

  //   Table settings
  const header = ["Primary Field", "Dependent Field"];

  const keyToHeaderMap: any = {
    "Primary Field": "field",
    "Dependent Field": "dependant_field",
  };

  const colWidth: any = {
    "Primary Field": "320",
    "Dependent Field": "350",
  };

  function handlePageChange(event: any, value: any) {
    // setLocalValues({
    //     ...localValues,
    //     currentPage: value,
    // });
    // setLogsData(true);
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    // setLocalValues({
    //     ...localValues,
    //     itemsPerPage: parseInt(event.target.value),
    //     currentPage: 0,
    // });
    // setLogsData(true);
  };

  let backendData: any;

  const getElementByID = (id: any) => {
    setInitialValues({});
    setDependencyId(id);

    dispatch(ContactPropertiesActions.GetMappingByID(id)).then((res: any) => {
      let data = res?.payload?.data?.data;

      setInitialValues(res?.payload?.data?.data);
      // console.log("pppp", MappingObject[0])
      dispatch(UpdatedFields(res?.payload?.data?.data));

      // handleListItemClick(res?.payload?.data?.data?.option_mapping[0]?.option_id)
      // setSelectedItemId(res?.payload?.data?.data?.option_mapping[0]?.option_id)

      const updatedPOptions = DependecyFieldListing.filter(
        (option: any) => option.id !== data?.field?.id
      );
      setDependentFieldOptions11(updatedPOptions);
      const updatedDOptions = DependecyFieldListing.filter(
        (option: any) => option.id !== data?.dependant_field?.id
      );
      setPrimaryFieldOptions11(updatedDOptions);
      dispatch(
        ContactPropertiesActions.GetPrimaryOptions(data?.field?.id)
      ).then((res: any) => {
        handleListItemClick(
          res?.payload?.data?.data[0]?.id ||
            res?.payload?.data?.data?.options[0]?.id
        );
      });
      dispatch(
        ContactPropertiesActions.GetDependantOptions(data?.dependant_field?.id)
      ).then((res: any) => {});
      //});
    });
  };

  const clearall = () => {
    dispatch(resetPrimaryOptions());
    dispatch(resetDependencyObject());
    dispatch(resetDependantOptions());
    dispatch(UpdatedOptionID(""));
    setPrimaryFiledsListing([]);
    setCheckedOptions({});
    toggleAddModal();
    setSelectedItemId("");
    setIsEditing(false);
    setInitialValues({});
  };
  const handleClickDelete = () => {
    let data = {
      id: editId,
    };
    dispatch(ContactPropertiesActions.DeleteDependency(data)).then(
      (res: any) => {
        // console.log("Primary Filed Res", res)
        dispatch(ContactPropertiesActions.GetDependencyList({}));
        setIsDelete(false);
      }
    );
  };
  const handlePrimaryOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedContactMapping = PrimaryOptionArray.filter((item: any) =>
      item?.label.toLowerCase()?.includes(event.target.value.toLowerCase())
    );
    setPrimaryOptionList(updatedContactMapping);
  };
  const handleDependentOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let updatedContactMapping = DependentOptionArray.filter((item: any) =>
      item?.label.toLowerCase()?.includes(event.target.value.toLowerCase())
    );
    setDependecyOptionList(updatedContactMapping);
  };

  const editDependency = (row: any) => {
    setEditId(row?.id);
    setIsEditing(true);
    getElementByID(row?.id);
    toggleAddModal();
    dispatch(ContactPropertiesActions.GetDependencyProperties({})).then(
      (res: any) => {
        setPrimaryFieldOptions11(res?.payload?.data?.non_dependent_fields);
        setDependentFieldOptions11(res?.payload?.data?.depedent_fields);
        // clearall();
        // toggleAddModal();
        // setIsEditing(false)
      }
    );
  };

  React.useEffect(() => {
    // console.log("MappingObject", MappingObject)
    if (MappingObject[0]?.option_mapping) {
      const optionMappingArray = MappingObject[0]?.option_mapping;
      const indexOfMatchingObject = optionMappingArray.findIndex(
        (obj: any) => obj?.option_id === selectedOptionId
      );

      if (indexOfMatchingObject !== -1) {
        // If a matching object is found, update the state with the selected option
        const selectedIds =
          optionMappingArray[indexOfMatchingObject]?.ids || [];
        setCheckedOptions({
          [selectedOptionId]: selectedIds.includes(selectedOptionId),
        });
      } else {
        // If no matching object is found, clear the checkboxes
        setCheckedOptions({});
      }
    } else {
      // If MappingObject[0]?.option_mapping is not present, clear the checkboxes
      setCheckedOptions({});
    }
  }, [MappingObject[0]?.option_mapping]);

  const addDependency = () => {
    dispatch(ContactPropertiesActions.GetDependencyProperties({})).then(
      (res: any) => {
        setPrimaryFieldOptions11(res?.payload?.data?.non_dependent_fields);
        setDependentFieldOptions11(res?.payload?.data?.depedent_fields);
        clearall();
        toggleAddModal();
        setIsEditing(false);
        setEditId("");
      }
    );
  };

  return (
    <DependencyListWrapper>
      <DependencyListTopBar>
        <Box className="heading">
          <IconButton
            color="inherit"
            onClick={() => {
              navigate("/settings/data-management/contact-properties/list");
            }}
          >
            <ArrowBackIosIcon style={{ fontSize: "15px" }} />
          </IconButton>
          Dependency Fields
        </Box>
        <Box className="add-dependency__button">
          <AppButton variant="primarydark" onClick={() => addDependency()}>
            Add Dependency
          </AppButton>
        </Box>
      </DependencyListTopBar>

      <Box
        sx={{
          margin: "1rem",
          // border: "1px solid green"
        }}
      >
        <TableWrapper height={list?.length ? "210px" : "158px"}>
          <div>
            <Box className="table-header" sx={{ width: "100%" }}>
              {/* Table Header */}
              <Box
                className="table-row"
                sx={{ justifyContent: "space-between" }}
              >
                <Box className="rowleft" sx={{ display: "flex" }}>
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{
                          maxWidth: colWidth[header] + "px",
                          minWidth: colWidth[header] + "px",
                        }}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    minHeight: "47px",
                    maxHeight: "47px",
                    borderBottom: "1px solid rgba(126, 131, 146, 0.5)",
                    borderTop: "1px solid rgba(126, 131, 146, 0.5)",
                    flex: 1,
                  }}
                ></Box>
                <Box
                  className="rowright"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pr: "8px",
                    minHeight: "47px",
                    maxHeight: "47px",
                    borderBottom: "1px solid rgba(126, 131, 146, 0.5)",
                    borderTop: "1px solid rgba(126, 131, 146, 0.5)",
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      maxWidth: "100px",
                      minWidth: "100px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                    className="action"
                  >
                    Actions
                  </Box>
                </Box>
              </Box>
              {/* Table Body */}
            </Box>
            <Box className="table-body" sx={{ width: "100%" }}>
              {DependencyListCount === 0 ? (
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{ height: "calc(100vh - 205px)" }}
                >
                  <img src={TemplateAPIERROR} width="280px" />
                  <Box
                    className=""
                    sx={{ fontSize: "18px", fontWeight: 500, mb: "12px" }}
                  >
                    Dependency List Not Found
                  </Box>
                  <Box>
                    <AppButton
                      variant="primarydark"
                      onClick={() => {
                        clearall();
                        toggleAddModal();
                        setIsEditing(false);
                      }}
                    >
                      Add Dependency
                    </AppButton>
                  </Box>
                </Box>
              ) : DependencyListCount !== null && DependencyList ? (
                <>
                  {DependencyListCount !== 0 &&
                    DependencyList?.map((row: any, rowIndex: any) => {
                      // console.log(row);
                      return (
                        <Box
                          key={rowIndex}
                          className="table-row"
                          sx={{
                            // borderBottom: "1px solid rgba(126, 131, 146, 0.2)",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box className="rowleft" sx={{ display: "flex" }}>
                            {header?.map((header: any, ColumnIndex: any) => {
                              const isActionColumn = header === "Action";
                              return (
                                <Box
                                  className={`col-body ${header
                                    .toLowerCase()
                                    .replaceAll(" ", "_")}`}
                                  title={
                                    row[keyToHeaderMap[header]]?.label
                                      ? row[keyToHeaderMap[header]]?.label
                                      : "-"
                                  }
                                  style={{
                                    maxWidth: isActionColumn
                                      ? "400px"
                                      : colWidth[header] + "px",
                                    minWidth: isActionColumn
                                      ? "400px"
                                      : colWidth[header] + "px",
                                    // borderBottom: "none",
                                  }}
                                >
                                  <Typography
                                    className="table-text"
                                    sx={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {row[keyToHeaderMap[header]]?.label
                                      ? row[keyToHeaderMap[header]]?.label
                                      : "-"}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                          <Box
                            sx={{
                              borderBottom:
                                "1px solid rgba(126, 131, 146, 0.2)",
                              flex: 1,
                            }}
                          ></Box>
                          <Box className="rowright">
                            <Box
                              display="flex"
                              sx={{
                                maxWidth: "100px",
                                minWidth: "100px",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                borderBottom:
                                  "1px solid rgba(126, 131, 146, 0.2)",
                                minHeight: "53px",
                                maxHeight: "53px",
                              }}
                              className="action"
                            >
                              <IconButton
                                // onClick={() => editBroadcastTemplate(row?.id)}
                                onClick={() => {
                                  editDependency(row);
                                }}
                              >
                                <EditIcon />
                              </IconButton>

                              <IconButton
                                onClick={() => {
                                  setIsDelete(true);
                                  setEditId(row?.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                </>
              ) : (
                <Skeleton width={3} height={3} />
              )}
            </Box>
          </div>
        </TableWrapper>
        {list?.length ? (
          <AppPagination
            component="div"
            rowsPerPageOptions={[10, 25, 50]}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={0}
            count={200}
            rowsPerPage={10}
          />
        ) : null}
      </Box>

      <AppModel
        isShowing={isShowingAddModal}
        onClose={() => {
          toggleAddModal();
          clearall();
        }}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "922px",
          },
        }}
      >
        <AppForm
          initialValues={initialValues}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitFn(values, submitProps);
          }}
        >
          <AddDependencyModalWrappper>
            <Box className="header">
              <Box className="title">Add Dependency</Box>
              <Box className="close" onClick={() => toggleAddModal()}>
                <IconButton>
                  <AppMaterialIcons iconName="Close" />
                </IconButton>
              </Box>
            </Box>
            <Box className="body">
              <Box className="select_fields">
                <Box className="field">
                  <Box className="label">
                    <label>
                      Primary Field<sup>*</sup>
                    </label>
                    <AppToolTip
                      tooltipProps={{
                        title: "Lorem ipsum",
                        placement: "top",
                      }}
                      iconButtonProps={{ disableRipple: true }}
                      IconComponent={
                        <InfoOutlinedIcon className="div_tooltip" />
                      }
                    />
                  </Box>
                  <AppReactSelectField
                    isDisabled={editId? true:false}
                    valueKey="id"
                    placeholder="Select a List"
                    name="field"
                    options={primaryFieldOptions11}
                    onChangeCallbackFn={handleChangePrimaryField}
                  />

                  <Box className="container">
                    <Box className="header_search">
                      <SearchInput
                        placeholder="Search"
                        size="small"
                        onChange={handlePrimaryOptionChange}
                      />
                    </Box>
                    <Box className="container_data">
                      {PrimaryOptionList?.map((option: any) => (
                        <ListItem
                          key={option?.id}
                          selected={selectedItemId === option?.id}
                          onClick={() => {
                            handleListItemClick(option?.id);
                          }}
                        >
                          <ListItemButton>
                            <ListItemText primary={option?.label} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Box className="field">
                  <Box className="label">
                    <label>
                      Dependent Field<sup>*</sup>
                    </label>
                    <AppToolTip
                      tooltipProps={{
                        title: "Lorem ipsum",
                        placement: "top",
                      }}
                      iconButtonProps={{ disableRipple: true }}
                      IconComponent={
                        <InfoOutlinedIcon className="div_tooltip" />
                      }
                    />
                  </Box>
                  <AppReactSelectField
                    valueKey="id"
                    isDisabled={editId? true:false}
                    placeholder="Select a List"
                    name="dependant_field"
                    options={dependentFieldOptions11}
                    onChangeCallbackFn={handleChangeDeoendentField}
                  />
                  <Box className="container">
                    <Box className="header_search">
                      <SearchInput
                        placeholder="Search"
                        size="small"
                        onChange={handleDependentOptionChange}
                      />
                    </Box>
                    <Box className="container_data">
                      {DependecyOptionList.map((option: any) => {
                        const optionId = option.id;

                        // Find the corresponding option_mapping for the current option_id
                        const correspondingMapping =
                          MappingObject[0]?.option_mapping.find(
                            (mapping: any) =>
                              mapping.option_id === selectedItemId
                          );

                        // console.log(correspondingMapping, selectedOptionId, checkedOptions, optionId, "correspondingMapping");

                        // Check if the current optionId is present in the IDs of the corresponding mapping
                        const isChecked =
                          checkedOptions[optionId] ||
                          (correspondingMapping?.ids?.includes(optionId) &&
                            selectedItemId ===
                              correspondingMapping.option_id) ||
                          false;

                        return (
                          <Box className="options" key={optionId}>
                            <Checkbox
                              checked={isChecked}
                              onChange={() => handleCheckBox(optionId)}
                              disabled={!selectedItemId}
                            />
                            <ListItemButton
                              onClick={() => handleCheckBox(optionId)}
                            >
                              {option?.label}
                            </ListItemButton>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="container_data"></Box>
            <Box className="footer">
              <Box className="footer-btns">
                <AppButton
                  variant="grey"
                  onClick={() => {
                    toggleAddModal();
                    clearall();
                  }}
                >
                  Cancel
                </AppButton>
                <AppSubmitButton title={isEditing ? "Update" : "Add"} />
              </Box>
            </Box>
          </AddDependencyModalWrappper>
        </AppForm>
      </AppModel>
      <AppModel
        isShowing={isDelete}
        onClose={() => setIsDelete(false)}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "500px",
          },
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">{/* <img src={Danger} alt="" /> */}</div>
              <div className="text">
                <h4>Delete Dependency Field</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => setIsDelete(false)}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton
              variant="grey"
              onClick={() => {
                setIsDelete(false);
              }}
            >
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => {
                handleClickDelete();
              }}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
    </DependencyListWrapper>
  );
};

export default DependencyFiledList;

const AddDependencyModalWrappper = styled.div`
  && {
    .header {
      height: 77px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.25rem 1.5rem;
      border-bottom: 1px solid ${theme.palette.default.RightBorderDivder};
      .title {
        font-size: 16px;
        font-weight: 500;
      }
      .close {
        display: flex;
        justify-content: center;
        align-items: center;
        .MuiSvgIcon-root {
          font-size: 1.25rem;
        }
      }
    }
    .body {
      .select_fields {
        height: calc(100vh - 422px);
        display: flex;
        overflow: auto;

        .field {
          &:first-child {
            border-right: 1px solid ${theme.palette.default.RightBorderDivder};
          }
          // height: fit-content;
          width: 50%;
          padding: 1.25rem 1.5rem;
          .label {
            display: flex;
            align-items: center;
            label {
              font-size: 14px;
              font-weight: 500;
            }
            .div_tooltip {
              font-size: 1rem;
            }
          }
          .container {
            // mein-height: 397px;
            overflow: hidden;
            height: calc(100% - 92px);
            border-radius: 4px;
            padding: 16px 20px;
            border: 1px solid ${theme.palette.default.RightBorderDivder};
            oveflow: auto;
            margin-top: 12px;
            .header_search {
              height: 48px;
            }
            .container_data {
              height: calc(100% - 48px);
              overflow: auto;
              .options {
                display: flex;
                justify-content: flex-start;
              }
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 1.25rem 1.5rem;
      border-top: 1px solid ${theme.palette.default.RightBorderDivder};
      .footer-btns {
        gap: 1rem;
        display: flex;
      }
    }
  }
`;
