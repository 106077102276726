import { createSlice, current } from "@reduxjs/toolkit";
import { QueryBuilderActions } from "./QueryBuilder.actions";

interface initialStateTypes {
  data: {
    queryBuilderDynamicFieldsOptions: any[];
    queryBuilderOperators: any;
    queryBuilderFields: any[];
    loading: boolean;
  };
}

const initialState: initialStateTypes = {
  data: {
    queryBuilderDynamicFieldsOptions: [],
    queryBuilderOperators: null,
    queryBuilderFields: [],
    loading: false,
  },
};

const QueryBuilderSlice = createSlice({
  name: "QueryBuilderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get Query fields dynamic options
      .addCase(
        QueryBuilderActions.getQueryBuilderFieldDynamicOptions.pending,
        (state, action) => {
          state.data.loading = true;
        }
      )
      .addCase(
        QueryBuilderActions.getQueryBuilderFieldDynamicOptions.fulfilled,
        (state, action) => {
          state.data.loading = false;

          let fieldObj = action.payload?.data?.data;
          if (!fieldObj) return;

          let previousFieldObjArray =
            current(state)?.data?.queryBuilderDynamicFieldsOptions;

          // Check if the fieldObj.id exists in the previous array

          if (previousFieldObjArray?.length > 0) {
            const existingArray =
              previousFieldObjArray?.filter(
                (item) => item?.id !== fieldObj?.id
              ) ?? [];

            if (existingArray?.length === 0) {
              state.data.queryBuilderDynamicFieldsOptions = [fieldObj];
            } else {
              state.data.queryBuilderDynamicFieldsOptions = [
                ...existingArray,
                fieldObj,
              ];
            }
          } else {
            state.data.queryBuilderDynamicFieldsOptions = [fieldObj];
          }
        }
      )
      .addCase(
        QueryBuilderActions.getQueryBuilderFieldDynamicOptions.rejected,
        (state, action) => {
          state.data.loading = false;
        }
      )
      // Get Query fields dynamic options as per module name
      .addCase(
        QueryBuilderActions.getQueryBuilderFieldDynamicOptionsAsPerModuleName.pending,
        (state, action) => {
          state.data.loading = true;
        }
      )
      .addCase(
        QueryBuilderActions.getQueryBuilderFieldDynamicOptionsAsPerModuleName.fulfilled,
        (state, action) => {
          state.data.loading = false;

          let fieldObj = action.payload?.data?.data;
          if (!fieldObj) return;

          let previousFieldObjArray =
            current(state)?.data?.queryBuilderDynamicFieldsOptions;

          // Check if the fieldObj.id exists in the previous array

          if (previousFieldObjArray?.length > 0) {
            const existingArray =
              previousFieldObjArray?.filter(
                (item) => item?.id !== fieldObj?.id
              ) ?? [];

            if (existingArray?.length === 0) {
              state.data.queryBuilderDynamicFieldsOptions = [fieldObj];
            } else {
              state.data.queryBuilderDynamicFieldsOptions = [
                ...existingArray,
                fieldObj,
              ];
            }
          } else {
            state.data.queryBuilderDynamicFieldsOptions = [fieldObj];
          }

        }
      )
      .addCase(
        QueryBuilderActions.getQueryBuilderFieldDynamicOptionsAsPerModuleName.rejected,
        (state, action) => {
          state.data.loading = false;
        }
      )

      // Get Mapping fields and operators dynamic options
      .addCase(
        QueryBuilderActions.getQueryBuilderMappingFields.pending,
        (state, action) => {
          state.data.loading = true;
        }
      )
      .addCase(
        QueryBuilderActions.getQueryBuilderMappingFields.fulfilled,
        (state, action) => {
          // console.log("Action payload", action.payload?.data);
          let data = action.payload?.data;
          state.data.queryBuilderOperators =
            data?.response?.type_mapping_data ?? null;
          // state.data.queryBuilderFields = data?.response?.fields ?? [];
          state.data.queryBuilderFields = data?.mappingFields ?? [];
          state.data.loading = false;
        }
      )
      .addCase(
        QueryBuilderActions.getQueryBuilderMappingFields.rejected,
        (state, action) => {
          state.data.loading = false;
        }
      );
  },
});

export default QueryBuilderSlice.reducer;
