import uniqid from "uniqid";
import * as Yup from "yup";

export const someOptions = [];

export const listSourceNameOptions = [
  {
    id: uniqid(),
    name: "Other",
    value: "other",
    description: null,
  },
];

export const whatShouldImportOptions = [
  {
    id: uniqid(),
    name: "Create new records",
    value: "create_new_record",
    description: null,
  },
  {
    id: uniqid(),
    name: "Create new and update existig contacts without overwrite",
    value: "create_new_and_update_existig_contacts_without_overwrite",
    description: null,
  },
  {
    id: uniqid(),
    name: "Create new and update existig contacts with overwrite",
    value: "create_new_and_update_existig_contacts_with_overwrite",
    description: null,
  },
  {
    id: uniqid(),
    name: "Update only existig contacts without overwrite",
    value: "update_only_existig_contacts_without_overwrite",
    description: null,
  },
  {
    id: uniqid(),
    name: "Update only existig contacts with overwrite",
    value: "update_only_existig_contacts_with_overwrite",
    description: null,
  },
];

export const lifecycleStageOptions = [
  {
    id: uniqid(),
    name: "Verified",
    value: "verified",
    description: null,
  },
];

export const skipDuplicatesRadioOptions: any[] = [
  {
    name: "Email",
    value: "email",
    id: uniqid(),
  },
  {
    name: "Mobile",
    value: "mobile",
    id: uniqid(),
  },
];

export const whatsappSubscriptionRadioOptions: any[] = [
  {
    name: "Yes",
    value: "yes",
    id: uniqid(),
  },
  {
    name: "No",
    value: "no",
    id: uniqid(),
  },
];

export const listTypeOptions = [
  {
    id: uniqid(),
    name: "Whatsapp",
    value: "whatsapp",
    description: null,
  },
  {
    id: uniqid(),
    name: "SMS",
    value: "sms",
    description: null,
  },
  {
    id: uniqid(),
    name: "Email",
    value: "email",
    description: null,
  },
];

export const MarketingListFormStep1Schema = Yup.object().shape({
  name: Yup.string().ensure().required().min(3).max(100).label("Name"),
  list_source_name: Yup.string()
    .ensure()
    .required()
    .min(2, "Source is a required field")
    .label("Source"),
  list_type: Yup.string().ensure().required().min(2, "List Type is a required field").max(100).label("List type"),
});
export const MarketingListFormStep2Schema = Yup.object().shape({
  upload_file: Yup.mixed()
    .required()
    .label("File"),
    list_source_name: Yup.string()
    .ensure()
    .required()
    .min(2, "Import Action is a required field")
    .label("Import Action"),
    whatsapp_subscription: Yup.mixed()
    .required()
    .default("no")
    .label("Whatsapp Action"),
    sms_subscription: Yup.mixed()
    .required()
    .default("no")
    .label("Sms Action"),
    email_subscription: Yup.mixed()
    .required()
    .default("no")
    .label("Email Action"),
    sales_owner: Yup.string()
    .ensure()
    .required()
    .min(2, "Owner is a required field")
    .label("Owner"),
    lifecycle_stage: Yup.string()
    .ensure()
    .required()
    .min(2, "Lifecycle Stage is a required field")
    .label("Lifecycle Stage"),
});
