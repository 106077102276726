import React, { useContext, useEffect, useMemo, useState } from "react";
import { Socket, io } from "socket.io-client";
import { AppConfig } from "../../Config/app-config";
import { SOCKET_CONNECTION_TYPES } from "../utils/Enum";

const parser = require("socket.io-msgpack-parser");

declare module "socket.io-client" {
  export interface Socket {
    username?: string;
  }
}

const SOCKET_BASE_URL =
  process.env.REACT_APP_SOCKET_BASE_URL || "http://localhost:8082";

interface SocketProviderProps {
  children?: React.ReactNode;
}

interface ISocketContext {
  socket: Socket | undefined;
  livechatSocket: boolean | undefined;
}

export const SocketContext = React.createContext<ISocketContext>({
  socket: undefined,
  livechatSocket: false,
});

export const useSocket = () => {
  const state = useContext(SocketContext);
  return state ?? undefined;
};

export const SocketContextProvider: React.FC<SocketProviderProps> = ({
  children,
}) => {
  const [socket, setSocket] = useState<Socket | undefined>();
  const [livechatSocket, setLivechatSocket] = useState<boolean | undefined>(undefined);

  const data = AppConfig.getLoggedInData();
  const domain = data?.data?.geta_host;
  const online_status = data?.data?.user?.onlinestatus ?? false;
  const workspace_id = data?.data?.user?.workspace_id ?? "";
  const username = useMemo(() => data?.data?.user?.id, [data]);

  useEffect(() => {
    if (!username) return;
    
    const _socket: Socket = io(SOCKET_BASE_URL, {
      auth: {
        appid: JSON.stringify({
          botid: null,
        }),
        "geta-host": domain,
        visitor_id: null,
        preview: null,
        connection_type: "agent",
        username: username,
      },
      autoConnect: true,
      secure: true,
      transports: ["websocket"],
      withCredentials: true,
      parser: parser,
    });
    
    //Attach username to socket
    _socket.username = username;
    
    _socket.on("connect", () => {
      setSocket(_socket);
      // console.warn(`:incoming event: [connect] --->`, _socket);

      const agentConnectData = {
        socket_id: _socket.id,
        agent: username,
        workspace_id: workspace_id,
        online_status: online_status ? true : false,
      };
      _socket.emit(
        SOCKET_CONNECTION_TYPES.AGENT_CONNECT,
        agentConnectData, (error: any, data:any) => { 
          if (error) {
            console.warn("error while connecting", error);
          }
          else if (!error && data) {
            const result = data?.live_chat_connected ?? false;
            console.warn("connected to socket", result);
            setLivechatSocket(result);
          }
          else {
            //do nothing
          }
        }
      );
    });

    _socket.on("connect_error", (err) => {
      if (err.message === "invalid username") {
        console.warn(`:incoming event: [connect_error] --->`, err);
      }
    });


    const handleAgentConnectStatus = (response: any) => { 
      const result = response?.live_chat_connected ?? false;
      setLivechatSocket(result);
    }

    _socket.on(SOCKET_CONNECTION_TYPES.MY_SESSION, handleAgentConnectStatus);

    //** SOCKET DEBUGGING **//
    // _socket.onAnyOutgoing((event, ...args) => {
    //   console.warn(`:outgoing event: [${event}] --->`, args?.[0]);
    // });
    // _socket.onAny((event, ...args) => {
    //   console.warn(`:incoming event: [${event}] --->`, args?.[0]);
    // });

    return () => {
      _socket.off(SOCKET_CONNECTION_TYPES.AGENT_RESPONSE, handleAgentConnectStatus);

      _socket.disconnect();
      setSocket(undefined);
    };
  }, [username]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SocketContext.Provider value={{ socket, livechatSocket }}>
      {children}
    </SocketContext.Provider>
  );
};
