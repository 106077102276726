import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { isEmptyObject } from "../../../Utils";
import { colorPairs } from "../../../socket/utils/utils";

interface Props {
  allMessages: any[];
  originalNodeResponses: any;
  lastNodeResponse: any;
  typingStatus: string | any;
  sessionId: string | any;
  lastInputBoxType: string | any;
  lastInputBoxStatus: boolean;
  botLanguage: string | any;
  isSessionIdFromLocalStorage: boolean;
  liveChatConnected: boolean;
  liveChatActiveSessionIds: string[];
  chatVisitorData: any;
  userInfoData: any;
  userContactDetailsData: any;
  previousConversationsData: any;
  isSearchBoxVisible: boolean;
  userNotesData: any[];
  toggleScrollFn: boolean;
  isInputEnabled: boolean;
  //reply
  showAttachedReplyItem: boolean;
  attachedReplyId: string;
  isPreviousChatLoading: false,
}

let INITIAL_STATE: Props = {
  allMessages: [],
  originalNodeResponses: [],
  lastNodeResponse: null,
  lastInputBoxType: null,
  lastInputBoxStatus: false,
  typingStatus: "",
  // sessionId: AppConfig.getUserData("SESSION_ID") || null,
  sessionId: null,
  botLanguage: "english",
  isSessionIdFromLocalStorage: false,
  liveChatConnected: false,
  liveChatActiveSessionIds: [],
  chatVisitorData: null,
  userInfoData: null,
  userContactDetailsData: null,
  previousConversationsData: [],
  isSearchBoxVisible: false,
  userNotesData: [],
  toggleScrollFn: false,
  isInputEnabled: false,
  //reply
  showAttachedReplyItem: false,
  attachedReplyId: "",
  isPreviousChatLoading: false,
};

const AllMessagesSlice = createSlice({
  name: "AllMessagesSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setAllMessages(state, { payload }: PayloadAction<any>) {
      if (!Array.isArray(payload) && typeof payload === "object") {
        payload = [payload];
      }

      //* to append msg only to targeted session
      const newSessionId = payload?.[payload?.length - 1]?.session_id;
      if (newSessionId && newSessionId !== state.sessionId) {
        return;
      }

      const existingMsgs = JSON.parse(JSON.stringify(state.allMessages));
      const newMsgs = [...existingMsgs, ...payload];
      state.allMessages = newMsgs;
    },
    removeMessage(state, { payload }: PayloadAction<any>) {
      state.allMessages = state.allMessages?.filter(
        (message:any) => message?.message_id !== payload?.message_id
      );
    },
    setNewAllMessages(state, { payload }: PayloadAction<any>) {
      if (!Array.isArray(payload)) {
        return;
      }

      state.allMessages = payload;
    },

    removeAllMessages(state, payload?: PayloadAction<any>) {
      const tempData = {
        ...INITIAL_STATE,
        ...payload,
      };
      return tempData;
    },

    setTypingStatus(state, { payload }: PayloadAction<any>) {
      state.typingStatus = payload;
    },
    setToggleScrollFn(state, { payload }: PayloadAction<any>) {
      state.toggleScrollFn = payload;
    },
    setChatUserNotesData(state, { payload }: PayloadAction<any>) {
      state.userNotesData = payload;
    },
    setVisitorChatData(state, { payload }: PayloadAction<any>) {
      state.chatVisitorData = payload;
    },
    setUserInfoData(state, { payload }: PayloadAction<any>) {
      let temp = {
        ...payload,
        count: 0,
      };
      state.userInfoData = temp;
    },
    updateUserInfoData(state, { payload }: PayloadAction<any>) {      
      let currentSessionTempId: string =
      current(state)?.userInfoData?.session_id ?? "";    
      if (!payload?.session_id || payload.session_id !== currentSessionTempId) return;
      let temp = {      
        ...state.userInfoData,
        ...payload,
      };
      state.userInfoData = temp;
    },
    setUserContactDetailsData(state, { payload }: PayloadAction<any>) {
      state.userContactDetailsData = payload;
    },
    toggleInputBox(state, { payload }: PayloadAction<any>) {
      state.isInputEnabled = payload;
    },
    setPreviousConversationsData(state, { payload }: PayloadAction<any>) {
      state.previousConversationsData = payload;
    },
    setIsSearchBoxVisible(state, { payload }: PayloadAction<any>) {
      state.isSearchBoxVisible = payload;
    },
    setLastNodeResponse(state, { payload }: PayloadAction<any>) {
      state.lastNodeResponse = payload;
      state.lastInputBoxStatus = payload?.["input"] ? true : false;
      state.lastInputBoxType = payload?.["input_type"]
        ? payload?.["input_type"]
        : null;
    },

    setOriginalResponses(state, { payload }: PayloadAction<any>) {
      const existingResponses = JSON.parse(
        JSON.stringify(state.originalNodeResponses)
      );
      const newResponses = [...existingResponses, payload];
      state.originalNodeResponses = newResponses;
    },

    setSessionId(state, { payload }: PayloadAction<any>) {
      state.sessionId = payload;
    },
    setBotLanguage(state, { payload }: PayloadAction<any>) {
      state.botLanguage = payload;
    },
    setIsSessionIdFromLocalStorage(state, { payload }: PayloadAction<any>) {
      state.isSessionIdFromLocalStorage = payload;
    },

    setLiveChatConnected(state, { payload }: PayloadAction<any>) {
      state.liveChatConnected = payload;
    },
    setLiveChatActiveSessionIds(state, { payload }: PayloadAction<any>) {
      const existingIds = JSON.parse(JSON.stringify(state.allMessages));
      const newIds = [...existingIds, payload];
      state.liveChatActiveSessionIds = newIds;
    },

    setAttachedReplyId(state, { payload }: PayloadAction<any>) {
      if (isEmptyObject(payload)) return;

      state.attachedReplyId = payload;
      state.showAttachedReplyItem = true;
    },
    setIsPreviousChatLoading(state, { payload }: PayloadAction<any>) {
      state.isPreviousChatLoading = payload;
    },
    
    removeAttachedReplyId(state) {
      state.attachedReplyId = "";
      state.showAttachedReplyItem = false;
    },
  },
});

export default AllMessagesSlice.reducer;

export const {
  removeAllMessages,
  setAllMessages,
  setNewAllMessages,removeMessage,
  setTypingStatus,
  setVisitorChatData,
  setUserInfoData,
  setSessionId,
  setLastNodeResponse,
  setOriginalResponses,
  setBotLanguage,
  setIsSessionIdFromLocalStorage,
  setLiveChatConnected,
  setLiveChatActiveSessionIds,
  setUserContactDetailsData,
  setPreviousConversationsData,
  setIsSearchBoxVisible,
  setChatUserNotesData,
  setToggleScrollFn,
  updateUserInfoData,
  removeAttachedReplyId,
  setAttachedReplyId,
  toggleInputBox,
  setIsPreviousChatLoading
} = AllMessagesSlice.actions;

export const allMessagesStore = (state: RootState) =>
  state.currrentAllMessagesStore;
