import React, { useEffect, useState } from "react";
import { Box, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppForm, AppSubmitButton } from "../../../AppComponents/Forms";
import AppButton from "../../../AppComponents/AppButton";
import { ReactComponent as SaveIcon } from "../../../assets/images/save-icon.svg";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ContactsTagsList } from "../../../Store/Slices/Contacts/contacts.selector";
import AppFormCreateAbleSelect from "../../../AppComponents/Forms/AppFormCreateAbleSelect";
import { BulkAddTags } from "../../../Models/ValidationSchema";
import AppReactSelectField from "../../../AppComponents/Forms/AppFormReactSelect";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../../HOC/HocBackdropLoader";
interface Props extends HocBackdropLoaderProps {
  closeModal?:any;
  payload?:any;
  type?:any;
  SubmitReset?:any
}
const AddTags  : React.FC<Props> = ({
  closeModal,
  payload,
  type,
  SubmitReset,
  setLoading
}) => {
  // props
  let payLoad = payload;
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // form name key
  const formkey9 = "tags";
  const formkey10 = "remove_tags";
  // tag list data from redux
  const TagsList: any = useAppSelector(ContactsTagsList);

  // states
  const [userInput, setUserInput] = useState({ tags: [], remove_tags: [] });
  const [taglistData, setTagListData] = useState<any[]>([]);
  // get list data
  useEffect(() => {
    getTagListCall();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // convert object
  useEffect(() => {
    setTagListData([]);

    if (TagsList && TagsList?.length) {
      TagsList.forEach((el: any) => {
        let a = {
          value: el.id,
          label: el.tag_title,
        };
        setTagListData((prev: any) => [...prev, a]);
      });
    }
  }, [TagsList]);

  const getTagListCall = () => {
    dispatch(ContactsActions.getContactsTagsList({}))
      .then(() => {})
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {});
  };

  // post call for bulk add tags
  const handleSubmitBtn = (values: any, submitProps: any) => {
    submitProps.setSubmitting(true);
    if (type === "add_tag") {
      let a = values?.tags;
      const new_tags = a
        ?.filter((item: any) => item?.__isNew__)
        .map((item: any) => item?.label);

      const existing_tags = a
        ?.filter((item: any) => !item?.__isNew__)
        .map((item: any) => item?.value);

      payLoad["existing_tags"] = existing_tags;
      payLoad["new_tags"] = new_tags;
      payLoad["operation"] = "add";
    } else {
      let a = values?.remove_tags;
      const existing_tags = a
        ?.filter((item: any) => !item?.__isNew__)
        .map((item: any) => item?.value);

      if ("new_tags" in payLoad) {
        delete payLoad["new_tags"];
      }

      payLoad["existing_tags"] = existing_tags;
      payLoad["operation"] = "remove";
    }
    setLoading(true)
    dispatch(ContactsActions.addTagsList(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(res?.payload?.data?.message || "tags not added", "error");
        } else {
          submitProps.setSubmitting(false);
          showAlert(res?.payload?.data?.message || "tags not added", "success");
          setUserInput({ tags: [], remove_tags: [] });
          SubmitReset();
          setLoading(false)
        }
      })
      .catch((error: any) => {
        showAlert(error?.response?.statusText || "tags not added", "error");
      })
      .finally(() => {
        setLoading(false)
        submitProps.setSubmitting(false);
      });
  };

  return (
    <Box
      sx={{
        minWidth: "671px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: "1rem",
          alignItems: "center",
        }}
      >
        <span style={{ fontWeight: "500", fontSize: "20px" }}>
          {type === "add_tag" ? "Add Tags" : "Remove Tags"}
        </span>
        <CloseIcon
          onClick={() => closeModal()}
          sx={{
            cursor: "pointer",
          }}
        />
      </Box>
      <Divider />
      <AppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
          setUserInput(values);
        }}
        validationSchema={BulkAddTags}
        divStyle={{
          gap: 0
        }}
      >
        <Box sx={{ flex: 1, p: 2 }}>
          {type === "add_tag" ? (
            <AppFormCreateAbleSelect
              menuPosition="fixed"
              label="Tags"
              isMulti={true}
              name={formkey9}
              options={taglistData}
            />
          ) : (
            <AppReactSelectField
              menuPosition="fixed"
              label="Tags"
              isMulti={true}
              name={formkey10}
              options={taglistData}
              valueKey="value"
              displayKey="label"
            />
          )}
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "16px",
            gap: "6px",
          }}
        >
          <AppButton
            style={{ width: "115px" }}
            variant="outline"
            startIcon="add"
            onClick={() => closeModal()}
          >
            Cancel
          </AppButton>
          <AppSubmitButton
            title={type === "add_tag" ? "Add Tags" : "Remove Tags"}
          >
            <SaveIcon />
          </AppSubmitButton>
        </Box>
      </AppForm>
    </Box>
  );
};
export default HocBackdropLoader(AddTags);
