import React from 'react'
import './loader.css';

const InfiniteLoaderComponent = () => {
  return (
    <div className="infinite-loader">
        <span className="four-dot-loader-css"></span>
    </div>
  )
}

export default InfiniteLoaderComponent;
