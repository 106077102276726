import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { format } from "date-fns";
import { FunctionComponent, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import styled from "styled-components";
import { device } from "../../Customization/BreakPoints";
import {
  GraphFilterBox,
  GraphInfoBox,
  GraphTopbarBox,
} from "../../Customization/CommonElements";
import { useAppDispatch, useAppSelector } from "../../Store";
import { DashboardActions } from "../../Store/Slices/DashboardRedux/dashboard.actions";
import {
  AgentChannel,
  AgentID,
  AnalyticsLoadingArray,
  BotList,
  applicationFormData,
  contactVsApplicationData,
  dateRange,
} from "../../Store/Slices/DashboardRedux/dashboard.selectors";

import moment from "moment";
import {
  daySorterHeatMap,
  xLabelsHeatMap,
  yLabelsHeatMap,
} from "../../Models/defaultSelectOptions";
import { DashboardNetworkService } from "../../Store/Slices/DashboardRedux/DashboardNetworkService";
import {
  removeDashboard,
  removeLoadingFromArray,
} from "../../Store/Slices/DashboardRedux/dashboard.slice";
import { formatTime, kFormatter } from "../../Utils";
import LeadsIcon from "../../assets/images/Leads.svg";
import Menubar2 from "../Menu/SummaryTopMenu";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import AgentStatusDoughnout from "./AgentStatusDoughnout";
import { SummaryWrapper, TextTitle } from "./AnalyticsSummaryStyles";
import BotConversationDoughnout from "./BotConversationDoughnout";
import AverageDuration from "./Dash_Assests/AverageDuration.svg";
import ReturnigLeads from "./Dash_Assests/ReturningLeads.svg";
import TimeSave from "./Dash_Assests/TmeSaved.svg";
import User from "./Dash_Assests/UsersIcon.svg";
import ReactVirtualizedTable from "./LabelsTable";
import ResponseTime from "./ResponseTime";
import {
  SkeletonCard,
  SkeletonCircleIconCard,
  SkeletonSkeletonDoughnutCard,
} from "./SkeletonLoader.tsx/SkeletonCard";
import ConversationStatus from "./Summary Components/conversationStatus";
import CSAT from "./SummaryCSAT/CSAT";
import UserGrowth from "./UserGrowthGraph";
import CoversationalTraffic from "./conversationalTraffic";
import "./styles.css";

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
  margin?: any;
  isDraggable?: boolean;
  isResizable?: boolean;
  isRearrangeable?: boolean;
}

const ResponsiveGridLayout = WidthProvider(Responsive);
function convertValue(value: number, conversionUnit: string) {
  if (conversionUnit === "min") {
    return Math.round((value / 60) * 100) / 100;
  } else if (conversionUnit === "hr") {
    return Math.round((value / 3600) * 100) / 100;
  } else {
    return Math.round(value);
  }
}

const Summary: FunctionComponent<Props> = (props) => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>("lg");
  const [compactType] = useState<"vertical" | "horizontal" | null | undefined>(
    "vertical"
  );
  const [mounted, setMounted] = useState(true);
  const [toolbox, setToolbox] = useState<{ [index: string]: any[] }>({
    lg: [],
  });
  const [users, setUsers] = useState<any>(0);
  const [totalleads, setTotaleads] = useState<any>(0);
  const [retleads, setRetleads] = useState<any>(0);

  const [avg, setAvg] = useState<any>(0);
  const [botSavedTime, setBotSavedTime] = useState<any>(0);

  const [totalMsg, setTotalMsg] = useState<any>(0);
  const [totalIncomingMsg, setTotalIncomingMsg] = useState<any>(0);
  const [totalOutGoingMsg, setTotalOutGoingMsg] = useState<any>(0);
  const [ratingCount, setRatingCount] = useState<any>({});
  const [avgAgentCsat, setAvgAgentCsat] = useState<any>(0);
  const dashdateRange: any = useAppSelector(dateRange);
  const loadingArray: any = useAppSelector(AnalyticsLoadingArray);
  const dispatch = useAppDispatch();
  const AgentBotId = useAppSelector(AgentID);
  const AgentChannelName = useAppSelector(AgentChannel);
  const botList: any = useAppSelector(BotList);
  const [botratingCount, setBotratingCount] = useState<any>({});
  const [botavgCsat, setBotavgCsat] = useState<any>(0);
  const [allcsatavg, setAllcsatavg] = useState<any>(0);
  const [allcsatRating, setAllcsatRating] = useState<any>({});
  const [resolution_time, setResolution_time] = useState<number>(0);
  const [response_time, setResponse_time] = useState<number>(0);
  const [xAxisMinValue, setxAxisMinValue] = useState<number>(0);
  const [resolutionTimeTooltip, setResolutionTimeTooltip] = useState<number>(0);
  const [responseTimeTooltip, setResponseTimeTooltip] = useState<number>(0);
  const xAxisCount = 10;
  const ApplicationFormData = useAppSelector(applicationFormData);
  const ContactVSApplicationData = useAppSelector(contactVsApplicationData);
  const [data_Arr, setData_Arr] = useState<any>({
    labels: [],
    datasets: [],
  });

  const backgroundColors = ["#008ffb", "#00a76e", "#7973BC", "#ff4545"];
  const [localValues, setLocalValues] = useState<any>({
    channel: "",
    bot: "",
  });
  const [ChannelList, setChannelList] = useState<any>([]);
  const [graphDataObj, setGraphDataObj] = useState<any>(null);
  const [datalength, setDatalength] = useState<number>(0);
  const [list, setList] = useState<any>([]);
  // Users API CALL
  const getDashboardUsers = () => {
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getDashboardUsers(payload))
      .then((res) => {
        setUsers(res.payload.data.user_count || 0);
      })
      .catch((err) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("userDashboard"));
      });
  };

  // Returning Leads API CALL
  const getReturningLeads = () => {
    let paylaod = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getDashReturningLead(paylaod))
      .then((res) => {
        setTotaleads(res.payload.data.total_leads || 0);
        setRetleads(res.payload.data.returning_leads || 0);
      })
      .catch((err) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("returningLeads"));
      });
  };

  // Duration API CALL
  const getDuration = () => {
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getDashDuration(payload))
      .then((res) => {
        setAvg(res.payload.data.average || 0);
        setBotSavedTime(res.payload.data.time_saved_by_bot || 0);
      })
      .catch((err) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("duration"));
      });
  };

  // SummaryMsg API CALL
  const getDashSummaryMsg = () => {
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getSummaryMsg(payload))
      .then((res) => {
        let data: any = res?.payload;
        setTotalMsg(data.data?.total_message_count || 0);
        setTotalIncomingMsg(
          data?.data?.message_data?.incoming_messages_count || 0
        );
        setTotalOutGoingMsg(
          data?.data?.message_data?.outgoing_messages_count || 0
        );
      })
      .catch((err) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("messageSummary"));
      });
  };

  const getaverageCsat = () => {
    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    if (botList.length > 0) {
      payload = {
        ...payload,
        bot_id: botList,
        type: "bot",
      };
    }
    payload.csatRef = "botCSATDetails";
    payload.type = "bot";
    dispatch(DashboardActions.getCsatDetails(payload))
      .then((res: any) => {
        let data: any = res?.payload;
        setBotratingCount(data?.data?.per_rating_count || {});
        setBotavgCsat(data?.data?.average_csat_rating || 0);
        dispatch(removeLoadingFromArray("botCSATDetails"));
      })
      .catch((err) => { });
  };

  //Agent CSAT API call
  const getAgentResponse = () => {
    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      type: "livechat",
    };
    if (AgentBotId.length > 0 && AgentChannelName.length > 0) {
      payload = {
        ...payload,
        agent_id: AgentBotId,
        channel: AgentChannelName,
      };
    } else if (AgentBotId.length > 0) {
      payload = {
        ...payload,
        agent_id: AgentBotId,
      };
    } else if (AgentChannelName.length > 0) {
      payload = {
        ...payload,
        channel: AgentChannelName,
      };
    }
    payload.csatRef = "agentResponse";
    dispatch(DashboardActions.getCsatDetails(payload))
      .then((res: any) => {
        let data: any = res?.payload;
        setRatingCount(data?.data?.per_rating_count || {});
        setAvgAgentCsat(data?.data?.average_csat_rating || 0);
      })
      .catch((err) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("agentResponse"));
      });
  };

  // Overall CSAT Api call
  const getAllCsatResponse = () => {
    let payload: any = {
      from_date: format(dashdateRange?.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange?.endDate, "yyyy-MM-dd"),
    };
    payload.csatRef = "overallCSATDetails";
    dispatch(DashboardActions.getCsatDetails(payload))
      .then((res) => {
        let data: any = res?.payload;
        setAllcsatRating(data?.data?.per_rating_count || {});
        setAllcsatavg(data?.data?.average_csat_rating || 0);
      })
      .catch((err: any) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("overallCSATDetails"));
      });
  };
  // Response Time API  Call
  const getResponse = () => {
    let payload: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    payload.csatRef = "csat";
    dispatch(DashboardActions.getResponseTime(payload))
      .then((res: any) => {
        // to convert values in min and hr

        let resolutionTime = res?.payload?.data?.avg_resolution_time,
          responseTime = res?.payload?.data?.avg_conversation_time,
          MaxValue = 0,
          MinValue = 0,
          conversionUnit = "sec",
          maxUnit = "resultionTime";
        if (resolutionTime > responseTime) {
          MaxValue = resolutionTime;
          MinValue = responseTime;
          maxUnit = "resolutionTime";
        } else {
          MaxValue = responseTime;
          MinValue = resolutionTime;
          maxUnit = "responseTime";
        }
        // increment maxvalue by 20 percent
        let incrementalValue = (MaxValue * 20) / 100;
        incrementalValue = MaxValue + incrementalValue;
        // convert values in min and hr
        if (MaxValue < 10000) {
          incrementalValue = convertValue(incrementalValue, "min");
          MaxValue = convertValue(MaxValue, "min");
          MinValue = convertValue(MinValue, "min");
          conversionUnit = "min";
        } else if (MaxValue > 10000) {
          incrementalValue = convertValue(incrementalValue, "hr");
          MaxValue = convertValue(MaxValue, "hr");
          MinValue = convertValue(MinValue, "hr");
          conversionUnit = "hr";
        }
        // let xAxisUnitWidth = document.getElementsByClassName("chartX")[0].clientWidth/ 2;

        let xAxisUnitWidth = 0;
        if (maxUnit === "resolutionTime") {
          setResolution_time(MaxValue + xAxisUnitWidth);
          setResponse_time(MinValue + xAxisUnitWidth);
        } else {
          setResolution_time(MinValue + xAxisUnitWidth);
          setResponse_time(MaxValue + xAxisUnitWidth);
        }

        setxAxisMinValue(incrementalValue / xAxisCount);
        setResolutionTimeTooltip(res?.payload?.data?.avg_resolution_time || 0);
        setResponseTimeTooltip(res?.payload?.data?.avg_conversation_time || 0);
        dispatch(removeLoadingFromArray("responseTime"));
      })
      .catch((err) => {
        dispatch(removeLoadingFromArray("responseTime"));
      });
  };

  // User Growth API Call
  const getUserGrowth = () => {
    setData_Arr({
      labels: [],
      datasets: [],
    });
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
      bot: localValues.bot,
    };

    dispatch(DashboardActions.getUserGrowthChannelWise(payload))
      .then((res) => {
        let data: any = res?.payload;
        let channel: any = "";
        let labelsData: any = [];
        let gData1: any = [];
        let t: any = [];
        if (data?.data?.data) {
          data?.data?.data?.map((curElem: any, index: any) => {
            channel = curElem.channel;
            let user_growth_channel = [...curElem?.user_growth_channel]
            user_growth_channel.pop()
            user_growth_channel?.map((el: any) => {
              if (!gData1[index]) {
                gData1[index] = [];
              }
              gData1[index].push(el.count);
              if (index === 0) {
                labelsData = [...labelsData, moment(el.date).format("D MMM")];
              }
            });
            let a = {
              label: channel[index],
              fill: false,
              lineTension: 0.3,
              backgroundColor: backgroundColors[index],
              borderColor: backgroundColors[index],
              borderCapStyle: "butt",
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: "miter",
              pointBorderColor: "rgba(131,138,133,1)",
              pointBackgroundColor: "#fff",
              pointBorderWidth: 2,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: "rgba(131,138,133,1)",
              pointHoverBorderColor: "rgba(220,220,220,1)",
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: gData1[index],
              borderWidth: 5,
            };
            t.push(a);
          });
          let b = {
            labels: labelsData,
            datasets: t,
          };
          setData_Arr(b);
        }
        dispatch(removeLoadingFromArray("userGrowth"));
      })
      .catch((err) => {
        dispatch(removeLoadingFromArray("userGrowth"));
      });
  };

  const getApplicationFormData = () => {
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getApplicationFormAnalytics(payload)).then(() => {
      dispatch(removeLoadingFromArray("applicationformdata"));
    }).catch(() => {
      dispatch(removeLoadingFromArray("applicationformdata"));
    });
  }

  const getApplicationVsContactData = () => {
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getContactVSApplicationAction(payload)).then(() => {
      dispatch(removeLoadingFromArray("applicationvscontact"));
    }).catch(() => {
      dispatch(removeLoadingFromArray("applicationvscontact"));
    });
  }

  useEffect(() => {
    DashboardNetworkService.getBotList().then((res: any) => {
      setChannelList(res?.data?.bot_list || []);
    });
  }, []);

  // Traffic API call
  const getTraffic = async () => {
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getCoversationalTraffic(payload))
      .then((response: any) => {
        let temp: any = Object.entries(response?.payload?.data);
        temp &&
          temp.length > 0 &&
          temp.sort(function sortByDay(a: any, b: any) {
            let day1 = a[0].toLowerCase();
            let day2 = b[0].toLowerCase();
            return daySorterHeatMap[day1] - daySorterHeatMap[day2];
          });

        let graphData: any[] = [];

        //extracting only data values array required as per heat map requirement
        temp &&
          temp.length > 0 &&
          temp.forEach((item: any, index: number) => {
            graphData.push(Object.values(item[1]));
          });

        setGraphDataObj({
          xLabels: xLabelsHeatMap,
          yLabels: yLabelsHeatMap,
          data: graphData,
        });

        setDatalength(graphData.length || 0);
        dispatch(removeLoadingFromArray("traffic"));
      })
      .catch((error: any) => {
        dispatch(removeLoadingFromArray("traffic"));
      });
  };

  //API call for conversation label
  const labelTable = () => {
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getlabelsSummary(payload))
      .then((res: any) => {
        setList(res?.payload?.data?.labels || []);
      })
      .catch((err: any) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("conversationalLabels"));
      });
  };

  const handleFileDownload = (e: any) => {
    e.preventDefault();
    let payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    DashboardNetworkService.getDownloadLabel(payload)
      .then((res) => {
        res.data && window.open(res?.data?.data?.source, "_blank");
      })
      .catch((err) => { })
      .finally(() => {
        dispatch(removeLoadingFromArray("conversationalLabels"));
      });
  };
  const [conversationStats, setCoversationStats] = useState<any>({
    openConversations: 0,
    closeConversations: 0,
    pendingConversations: 0,
    assginedConversations: 0,
    unassignedConverstations: 0,
    unattendedConversations: 0,
    agentClosed: 0,
    totalConversations: 0,
    totalBotConversations: 0,
    totalAgentConversations: 0,
  });
  const getConversationStatus = () => {
    const payload = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    dispatch(DashboardActions.getCoversationStatus(payload))
      .then((res: any) => {
        if (res?.payload?.data?.total_conversations > 0) {
          const totalCount = res?.payload?.data.total_conversations || {};
          const agent_conversations =
            res?.payload?.data.agent_conversations || {};
          const bot_conversations = res?.payload?.data.bot_conversations || {};
          setCoversationStats({
            openConversations: agent_conversations.open,
            closeConversations:
              agent_conversations.closed + bot_conversations.closed,
            pendingConversations: agent_conversations.pending,
            assginedConversations:
              agent_conversations.assigned + bot_conversations.assigned,
            unassignedConverstations: agent_conversations.unassigned,
            unattendedConversations: agent_conversations.unattended,
            totalConversations: totalCount,
            agentClosed: agent_conversations.closed,
            totalBotConversations: bot_conversations.total,
            totalAgentConversations: agent_conversations.total,
          });
        }
      })
      .finally(() => {
        dispatch(removeLoadingFromArray("conversationStatus"));
      });
  };
  useEffect(() => {
    return () => {
      dispatch(removeDashboard({}));
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dashdateRange.startDate) {
      getConversationStatus();
      getDashboardUsers();
      getReturningLeads();
      getDuration();
      getDashSummaryMsg();
      getAllCsatResponse();
      getUserGrowth();
      // getAgentApi();
      getResponse();
      getTraffic();
      getAgentResponse();
      // getBotResponse();
      labelTable();
      getaverageCsat();
      getApplicationFormData();
      getApplicationVsContactData();
    }
  }, [dashdateRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getUserGrowth();
  }, [localValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const [layouts, setLayouts] = useState<any[]>([
    { i: "a", x: 2, y: 0, w: 3, h: 5, minW: 3, minH: 5 },
    { i: "b", x: 0, y: 0, w: 2, h: 5 },
    { i: "c", x: 5, y: 0, w: 2, h: 5 },
    { i: "d", x: 7, y: 0, w: 3, h: 5 },
    { i: "e", x: 0, y: 5, w: 7, h: 7 },
    { i: "f", x: 7, y: 5, w: 3, h: 7 },
    { i: "g", x: 0, y: 5, w: 3.5, h: 7, minW: 3.5, minH: 7 },
    { i: "h", x: 3.5, y: 5, w: 3.5, h: 7, minW: 3.5, minH: 7 },
    { i: "i", x: 7, y: 5, w: 3, h: 7, minW: 3, minH: 7 },
    { i: "j", x: 0, y: 12, w: 10, h: 12, minH: 12 },
    { i: "k", x: 0, y: 12, w: 10, h: 12, minH: 12 },
  ]);

  const onBreakpointChange = (breakpoint: any) => {
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onLayoutChange = (layout: any, layouts: any) => {
    setLayouts({ ...layouts });
  };
  return (
    <SummaryWrapper>
      <Menubar2 />

      <div className="parent_div2">
        <ResponsiveGridLayout
          {...props}
          //@ts-ignore
          layouts={layouts}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          compactType={compactType}
          preventCollision={!compactType}
          onLayoutChange={onLayoutChange}
          onBreakpointChange={onBreakpointChange}
          isDroppable
          onDrop={() => { }}
          isBounded={true}
          isRearrangeable={false}
        >
          <div
            key="a"
            className="average"
            data-grid={{
              x: 2,
              y: 0,
              w: 4,
              h: 3,
              minW: 4,
              minH: 5,
              maxH: 5,
              maxW: 4,
              static: true,
            }}
          >
            <div className="section_f">
              <p className="section_f_text">
                {" "}
                Contacts
                <AppToolTip
                  tooltipProps={{
                    title:
                      "The number of visitors who given their contact information",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon className="c_div_tooltip" />}
                />
              </p>
              <div className="avg_count">
                <p className="users_count_text">
                  {loadingArray.includes("userDashboard") ? (
                    <SkeletonCard />
                  ) : (
                    kFormatter(totalleads) || 0
                  )}
                </p>
                <div className="c_div1_icon">
                  {loadingArray.includes("userDashboard") ? (
                    <SkeletonCircleIconCard />
                  ) : (
                    <img src={LeadsIcon} alt="Summary_page_leads_icon" />
                  )}
                </div>
              </div>
            </div>

            <hr className="dotted_hr"></hr>

            <div className="section_f">
              <p className="section_f_text">
                Returning Contacts
                <AppToolTip
                  tooltipProps={{
                    title: "The total number of returning contacts",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon className="c_div_tooltip" />}
                />
              </p>
              <div className="avg_count">
                <p className="users_count_text">
                  {loadingArray.includes("returningLeads") ? (
                    <SkeletonCard />
                  ) : (
                    kFormatter(retleads) || 0
                  )}
                </p>
                <div className="c_div1_icon">
                  {loadingArray.includes("returningLeads") ? (
                    <SkeletonCircleIconCard />
                  ) : (
                    <img src={ReturnigLeads} alt="Not Found" />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            key="b"
            data-grid={{
              x: 0,
              y: 0,
              w: 2,
              h: 3,
              minW: 2,
              minH: 4,
              maxH: 5,
              maxW: 2,
              static: true,
            }}
            className="users"
          >
            <p className="section_f_text">
              Users
              <AppToolTip
                tooltipProps={{
                  title:
                    " The total number of users who have interacted with the bot and agents",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<InfoOutlinedIcon className="c_div_tooltip" />}
              />
            </p>
            <div className="users_count">
              <p className="users_count_text">
                {loadingArray.includes("userDashboard") ? (
                  <SkeletonCard />
                ) : (
                  kFormatter(users) || 0
                )}
              </p>
              <div className="c_div1_icon">
                {loadingArray.includes("userDashboard") ? (
                  <SkeletonCircleIconCard />
                ) : (
                  <img src={User} alt="Not Found" />
                )}
              </div>
            </div>
          </div>

          <div
            key="c"
            data-grid={{
              x: 7,
              y: 0,
              w: 4,
              h: 3,
              minW: 4,
              minH: 5,
              maxH: 5,
              maxW: 4,
              static: true,
            }}
            className="average"
          >
            <div className="section_f">
              <p className="section_f_text">
                {" "}
                Avg. Duration
                <AppToolTip
                  tooltipProps={{
                    title: " Average conversation durations with the bot",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon className="c_div_tooltip" />}
                />
              </p>
              <div className="avg_count">
                <div className="users_count_text">
                  {loadingArray.includes("duration") ? (
                    <SkeletonCard />
                  ) : (
                    formatTime(avg, "min") || 0
                  )}
                  {loadingArray.includes("duration") ? <></> : <span>min</span>}
                </div>
                <div className="c_div1_icon">
                  {loadingArray.includes("duration") ? (
                    <SkeletonCircleIconCard />
                  ) : (
                    <img
                      src={AverageDuration}
                      alt="Not Found"
                      className="avg_icon"
                    />
                  )}
                </div>
              </div>
            </div>

            <hr className="dotted_hr"></hr>
            <div className="section_f">
              <p className="section_f_text">
                Time Saved By Bot
                <AppToolTip
                  tooltipProps={{
                    title: "The time saved due the bot",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon className="c_div_tooltip" />}
                />
              </p>
              <div className="avg_count">
                <p className="users_count_text">
                  {loadingArray.includes("duration") ? (
                    <SkeletonCard />
                  ) : (
                    formatTime(botSavedTime || 0, "hrs") || 0
                  )}
                  {loadingArray.includes("duration") ? <></> : <span>hrs</span>}
                </p>
                <div className="c_div1_icon">
                  {loadingArray.includes("duration") ? (
                    <SkeletonCircleIconCard />
                  ) : (
                    <img src={TimeSave} alt="Not Found" className="avg_icon" />
                  )}
                </div>
              </div>
            </div>
          </div>









          <div
            key="d"
            data-grid={{
              x: 0,
              y: 3,
              w: 3.4,
              h: 6.5,
              minW: 3,
              maxH: 7,
              maxW: 3,
              static: true,
            }}
            className="content_box"
          >
            <div>
              <p className="csat_header">
                <TextTitle className="section_f_text">
                  Overall CSAT{" "}
                  <AppToolTip
                    tooltipProps={{
                      title: "The combined CSAT for Agents and Bots",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={
                      <InfoOutlinedIcon className="c_div_tooltip" />
                    }
                  />
                </TextTitle>
              </p>
            </div>

            <div className="csat_box">
              <div className="csat_info">
                <p className="csat_count">
                  {loadingArray.includes("overallCSATDetails") ? (
                    <SkeletonCard />
                  ) : (
                    allcsatavg || 0
                  )}
                </p>
                <p className="csat_name">
                  {" "}
                  {loadingArray.includes("overallCSATDetails") ? (
                    <SkeletonCard />
                  ) : (
                    "Avg CSAT"
                  )}
                </p>
              </div>
              <div className="csat_graph">
                <CSAT
                  ratingCount={allcsatRating}
                  loading={loadingArray.includes("overallCSATDetails")}
                />
              </div>
            </div>
          </div>

          <div
            key="e"
            data-grid={{
              x: 3.4,
              y: 3,
              w: 3.4,
              h: 6.5,
              minW: 3,
              maxH: 7,
              maxW: 3,
              static: true,
            }}
            className="content_box"
          >
            <div>
              <p className="csat_header">
                <TextTitle className="section_f_text">
                  BOT CSAT{" "}
                  <AppToolTip
                    tooltipProps={{
                      title: " The overall CSAT of the bots",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={
                      <InfoOutlinedIcon className="c_div_tooltip" />
                    }
                  />
                </TextTitle>
              </p>
            </div>

            <div className="csat_box">
              <div className="csat_info">
                <p className="csat_count">
                  {loadingArray.includes("botCSATDetails") ? (
                    <SkeletonCard />
                  ) : (
                    botavgCsat || 0
                  )}
                </p>
                <p className="csat_name">
                  {" "}
                  {loadingArray.includes("botCSATDetails") ? (
                    <SkeletonCard />
                  ) : (
                    "Avg CSAT"
                  )}
                </p>
              </div>
              <div className="csat_graph">
                <CSAT
                  ratingCount={botratingCount}
                  loading={loadingArray.includes("botCSATDetails")}
                />
              </div>
            </div>
          </div>

          <div
            key="f"
            data-grid={{
              x: 6.8,
              y: 3,
              w: 3.2,
              h: 6.5,
              minW: 3,
              maxH: 7,
              maxW: 3,
              static: true,
            }}
            className="content_box"
          >
            <div>
              <p className="csat_header">
                <TextTitle className="section_f_text">
                  Agent CSAT{" "}
                  <AppToolTip
                    tooltipProps={{
                      title: "The overall CSAT of the agents",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={
                      <InfoOutlinedIcon className="c_div_tooltip" />
                    }
                  />
                </TextTitle>
              </p>
            </div>

            <div className="csat_box">
              <div className="csat_info">
                <p className="csat_count">
                  {loadingArray.includes("agentResponse") ? (
                    <SkeletonCard />
                  ) : (
                    avgAgentCsat || 0
                  )}
                </p>
                <p className="csat_name">
                  {" "}
                  {loadingArray.includes("agentResponse") ? (
                    <SkeletonCard />
                  ) : (
                    "Avg CSAT"
                  )}
                </p>
              </div>
              <div className="csat_graph">
                <CSAT
                  ratingCount={ratingCount}
                  loading={loadingArray.includes("agentResponse")}
                />
              </div>
            </div>
          </div>

          <div
            key="g"
            data-grid={{
              x: 0,
              y: 9.5,
              w: 6.8,
              h: 6.5,
              minW: 7,
              minH: 7,
              maxH: 7,
              maxW: 7,
              static: true,
            }}
            className="conversation_box"
          >
            <ConversationStatus conversationStats={conversationStats} />
          </div>

          <div
            key="h"
            data-grid={{
              x: 7,
              y: 9.5,
              w: 3.2,
              h: 6.5,
              minW: 3,
              minH: 7,
              maxH: 7,
              maxW: 3,
              static: true,
            }}
            className="content_box"
          >
            <BotConversationDoughnout
              botdata={[
                conversationStats.totalBotConversations,
                conversationStats.totalAgentConversations,
              ]}
              loading={loadingArray.includes("conversationStatus")}
            />
          </div>

          <div
            key="i"
            data-grid={{
              x: 0,
              y: 16,
              w: 3.5,
              h: 6.5,
              minW: 3.5,
              minH: 7,
              maxH: 7,
              maxW: 3.5,
              static: true,
            }}
            className="content_box"
          >
            <p className="title">
              <TextTitle className="section_f_text">
                Messages{" "}
                <AppToolTip
                  tooltipProps={{
                    title: "The total number of messages for agents and bot",
                    placement: "top",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon className="c_div_tooltip" />}
                />
              </TextTitle>
            </p>
            <div className="i_div_in">
              <div className="count_box">
                <p className="total_msg_count">
                  {loadingArray.includes("messageSummary") ? (
                    <SkeletonCard />
                  ) : (
                    kFormatter(totalMsg) || 0
                  )}
                </p>
              </div>
              <div className="msg_box">
                <div className="messages_box">
                  <div className="messages">
                    <div className="message_text">
                      {loadingArray.includes("messageSummary") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        "Received"
                      )}
                    </div>
                    <div className="message_text">
                      {loadingArray.includes("messageSummary") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        "Messages"
                      )}
                    </div>
                  </div>
                  <div className="message_count">
                    {loadingArray.includes("messageSummary") ? (
                      <SkeletonCard />
                    ) : (
                      kFormatter(totalIncomingMsg) || 0
                    )}
                  </div>
                </div>
                <hr />

                <div className="messages_box">
                  <div className="messages">
                    <div className="message_text">
                      {loadingArray.includes("messageSummary") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        "Sent"
                      )}
                    </div>
                    <div className="message_text">
                      {loadingArray.includes("messageSummary") ? (
                        <SkeletonSkeletonDoughnutCard />
                      ) : (
                        "Messages"
                      )}
                    </div>
                  </div>
                  <div className="message_count">
                    {loadingArray.includes("messageSummary") ? (
                      <SkeletonCard />
                    ) : (
                      kFormatter(totalOutGoingMsg) || 0
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            key="j"
            data-grid={{
              x: 3.5,
              y: 16,
              w: 3.3,
              h: 6.5,
              minW: 3.5,
              minH: 7,
              maxH: 7,
              maxW: 3.5,
              static: true,
            }}
            className="content_box"
          >
            <ResponseTime
              resolution_time={resolution_time}
              response_time={response_time}
              xAxisMinValue={xAxisMinValue}
              resolutionTimeTooltip={resolutionTimeTooltip}
              responseTimeTooltip={responseTimeTooltip}
              xAxisCount={xAxisCount}
              loading={loadingArray.includes("responseTime")}
            />
          </div>

          <div
            key="k"
            data-grid={{
              x: 7,
              y: 16,
              w: 3.2,
              h: 6.5,
              minW: 3,
              minH: 7,
              maxH: 7,
              maxW: 3,
              static: true,
            }}
            className="content_box"
          >
            <AgentStatusDoughnout
              agent={[
                conversationStats.agentClosed,
                conversationStats.openConversations,
                conversationStats.pendingConversations,
              ]}
              loading={loadingArray.includes("conversationStatus")}
            />
          </div>
          <div
            key="l"
            date-grid={{
              x: 0,
              y: 24.5,
              w: 10,
              h: 10,
              minW: 10,
              minH: 10,
              maxH: 10,
              maxW: 10,
              static: true,
            }}
            className="usergrowth"
          >
            <UserGrowth
              data_Arr={data_Arr}
              loading={loadingArray.includes("userGrowth")}
              localValues={localValues}
              setLocalValues={setLocalValues}
              ChannelList={ChannelList}
            />
          </div>

          <div
            key="n"
            data-grid={{
              x: 0,
              y: 40.7,
              w: 10,
              h: 12,
              // minW: 10,
              // minH: 10,
              // maxH: 15,
              // maxW: 10,
              static: true,
            }}
            className="content_box"
          >
            <StyledBox>
              <GraphTopbarBox>
                <GraphInfoBox>
                  <TextTitle className="section_f_text">
                    Coversational Traffic
                  </TextTitle>

                  <AppToolTip
                    tooltipProps={{
                      title:
                        "Times during which visitors engage most with the bot during the week.",
                      placement: "top",
                    }}
                    iconButtonProps={{ disableRipple: true }}
                    IconComponent={
                      <InfoOutlinedIcon className="c_div_tooltip" />
                    }
                  />
                </GraphInfoBox>
                <GraphFilterBox></GraphFilterBox>
              </GraphTopbarBox>
              <div className="traffic_graph">
                <CoversationalTraffic
                  graphDataObj={graphDataObj}
                  datalength={datalength}
                  loading={loadingArray.includes("traffic")}
                />
              </div>
            </StyledBox>
          </div>

          <div
            key="m"
            data-grid={{
              x: 0,
              y: 32.5,
              w: 10,
              h: 8,
              minH: 12,
              maxH: 12,
              maxW: 10,
              static: true,
            }}
            className="content_box"
          >
            <ReactVirtualizedTable
              list={list}
              handleFileDownload={handleFileDownload}
              loading={loadingArray.includes("conversationalLabels")}
            />
          </div>


        </ResponsiveGridLayout>
      </div>
    </SummaryWrapper>
  );
};

Summary.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => { },
  cols: { lg: 10, md: 10, sm: 6, xs: 4, xxs: 2 },
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  containerPadding: [16, 16],
  margin: [16, 16],
  preventCollision: true,
  isDraggable: false,
  isResizable: false,
  isRearrangeable: false,
};

export default Summary;

export const DateBox = styled.div`
  color: blue;
`;

const StyledBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    // height: 24rem !important;
    height: inherit;

    &:nth-of-type(1) {
      width: 100%;
    }
    &:nth-of-type(2) {
      width: 30%;
    }
    @media ${device.laptopM} {
      width: 100%;
      &:nth-of-type(1) {
        width: 100%;
      }
      &:nth-of-type(2) {
        width: 100%;
      }
    }
  }
`;
