import styled from "styled-components";
import { ErrorTextMsg } from "../Customization/CommonElements";
import { theme } from "../Customization/Theme";
import AppIcon from "./AppIcon";
import { getIn, useFormikContext } from "formik";
interface AppInputFieldProps {
  label?: string;
  name?: string;
  icon?: string;
  Sizeicon?: string;
  errorMessage?: string;
  iconClick?: () => void;
  // children?: React.ReactNode,
  [otherProps: string]: any;
}

interface StyledProps {
  errorMessage?: string;
}
const AppInputField: React.FC<AppInputFieldProps> = ({
  label,
  name,
  errorMessage,
  // isValid,
  Sizeicon,
  icon = "",
  iconClick,
  ...otherProps
}) => {
  const { values } = useFormikContext();
  return (
    <Div errorMessage={errorMessage}>
      <span>
        <AppIcon
          title={icon}
          onClick={iconClick}
          size={Sizeicon ? Sizeicon : "20px"}
          color={"#AFAFAF"}
        />
      </span>
      <Label>{label}</Label>
      <Input
        errorMessage={errorMessage}
        value={name ? getIn(values, name) : ""}
        {...otherProps}
      />

      {errorMessage === "" ? (
        <ErrorTextMsg style={{ display: "none" }}> {errorMessage}</ErrorTextMsg>
      ) : (
        <ErrorTextMsg> {errorMessage}</ErrorTextMsg>
      )}
    </Div>
  );
};

export default AppInputField;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 4px;

    position: relative;
    margin-bottom: 1rem;
    span {
      position: absolute;
      right: 8px;
      top: ${(props: any) => (props.errorMessage === "" ? "55%" : "40%")};
      z-index: 19;
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
  }
`;
const Input = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 35px;
    background-color: ${theme.palette.secondary.main};
    border-radius: 4px;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    border: 2px solid
      ${(props: any) =>
        props.errorMessage === ""
          ? "transparent"
          : theme.palette.default.error};

    &::placeholder {
      color: ${theme.palette.default.lighterBlack};
    }

    &:active {
      border: 2px solid ${theme.palette.default.border};
      color: ${theme.palette.default.text};
    }
    &:focus {
      border: 2px solid ${theme.palette.default.border} !important;
      color: ${theme.palette.default.text};
    }
  }
`;
