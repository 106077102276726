import { ProjectVersionConfiguration, PV_CONFIG_TYPES } from './../../../Models';
import { coreAxios } from '../../../Utils/Axios';

import { CORE_ENDPOINTS } from '../Endpoints';
import { createGenericSliceAPICaller, HTTPMethod } from '../Common';
import { GenericState } from '../../../Store';
import { PayloadAction } from '@reduxjs/toolkit';



const {
  reducer: projectVersionConfigReducer,
  apiFn: getProjectConfigFn,
  storeAccessFn: useProjectConfigStore
} = createGenericSliceAPICaller<GenericState<ProjectVersionConfiguration[]>, any>({
  sliceName: 'current_project_configuration',
  axiosObj: coreAxios,
  endpoint: CORE_ENDPOINTS.PROJECT_VER_CONFIG,
  data: {},
  httpMethod: HTTPMethod.GET,
  storeAccessPath: 'userData.dashboardData.currentBotConfiguration.projectConfig'
});
// const useMyBotsStore = (state: any): PaginatedResponse<Chatbot> => {
//   return state.userData.dashboardData.myBots as PaginatedResponse<Chatbot>;
// };

export const getMatomoConfig = (projectConfigs: ProjectVersionConfiguration[]) => {
  if (projectConfigs) {
    const mConfig = projectConfigs.find(pc => pc.config_key === PV_CONFIG_TYPES.MATOMO_CONFIG);
    return mConfig;
  }
}

export {getProjectConfigFn};
export {projectVersionConfigReducer};
export {useProjectConfigStore};
