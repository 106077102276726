const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  // laptop: "1024px",
  tabletM: "991.98px",
  laptop: "1080px",
  laptop2: "1199.98px",
  laptopM: "1400px",
  laptopL: "1440px",
  laptopL2: "1599.98px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptop2: `(max-width: ${size.laptop2})`,
  laptopM: `(max-width: ${size.laptopM})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
  laptopL2: `(max-width: ${size.laptopL2})`,
  tabletM: `(max-width: ${size.tabletM})`,
};
