import { Link } from "@material-ui/core";
import { Box, Typography } from "@mui/material";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { ApexChatWrapper } from "../../Email_Broadcast_Overview_Summary/Doughnout";
import { TextTitle } from "../../Email_Broadcast_Overview_Summary/EmailBroadcastSummaryStyles";
import { Wrapstatistics } from "../Emai_broadcastStyles";

interface Props {
  topLink?: any;
  loading?: boolean;
}

const TopLink: React.FC<Props> = ({ topLink, loading }) => {
  return loading ? (
    <AppSkeletonLoader />
  ) : (
    <Wrapstatistics>
        <div className="j_div_Link" >
        <Box>
        <ApexChatWrapper>
          <Box className="heading__tooltip">
            <TextTitle>
              <Typography className="heading">Top Clicked Link</Typography>
            </TextTitle>
            <AppToolTip
              tooltipProps={{
                title:
                  "The most frequently clicked hyperlink or URL within an email campaign, highlighting the content or call-to-action that generated the highest recipient engagement.",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<AppMaterialIcons iconName="HelpOutline" />}
                />
          </Box>
        </ApexChatWrapper>
        </Box>


          <div className="j_div_Link_all" style={{  height: "100%" }}>
          {topLink && topLink?.length > 0 ? (
            topLink?.map((el: any) => {
              return (
                <>
                  <div className="date_with_link">
                    <Link href="#" className="link_a">
                      {el?.url}working
                    </Link>
                    <p className="Date_info">{el?.count}</p>
                  </div>
                </>
              );
            })
          ) : (
                <NoRecordsFound
                  imageWidth={"200px"}
                  headerTextSize={"19px"}
                  imageSrc={NoRecordImage}
                />
          )}
        </div>
      </div>
    </Wrapstatistics>
  );
};

export default TopLink;
