import {
  MessageNode,
  NameNode,
  PhoneNode,
  EmailNode,
  LanguageNode,
  ArticleNode,
  ButtonNode,
  CarouselNode,
  AlphanumericNode,
  DateNode,
  FeedbackNode,
  DropdownNode,
  NumberNode,
  ImgFileNode,
  CustomNode,
  TextfieldNode,
  TimeNode,
  OTPNode,
} from "./../nodes";

export const registerNodes: any = [
  {
    type: "start",
    name: "Hello",
    icon: "nodes",
    color: "#B8C4FF",
    isStart: true,
    category: null,
    displayComponent: null,
  },
  {
    type: "text",
    name: "Message",
    icon: "message",
    color: "#C8EF87",
    category: null,
    displayComponent: MessageNode,
    supports: ["Web Bot", "Whatsapp", "Messenger"],
  },
  {
    type: "name",
    name: "Name",
    icon: "user",
    category: "customer-details",
    color: "#BDD4FF",
    displayComponent: NameNode,
    supports: ["Web Bot", "Whatsapp", "Messenger"],
  },
  {
    type: "phone-number",
    name: "Phone Number",
    icon: "phone",
    category: "customer-details",
    color: "#93E9C8",
    displayComponent: PhoneNode,
    childNodes: [
      {
        type: "otp",
        name: "OTP",
        icon: "user",
        category: "customer-details",
        color: "#93E9C8",
        displayComponent: OTPNode,
        parentNode: "phone-number",
      },
    ],
    supports: ["Web Bot", "Whatsapp", "Messenger"],
  },
  {
    type: "language",
    name: "Language",
    icon: "language",
    category: "customer-details",
    color: "#FFC0AC",
    displayComponent: LanguageNode,
    supports: ["Web Bot", "Whatsapp", "Messenger"],
  },
  {
    type: "email",
    name: "Email",
    icon: "email",
    category: "customer-details",
    color: "#FFB9C5",
    displayComponent: EmailNode,
    supports: ["Web Bot", "Whatsapp", "Messenger"],
  },
  // {
  //   type: "article",
  //   name: "Article",
  //   icon: "article",
  //   category: "input-options",
  //   color: "#DFCBFF",
  //   displayComponent: ArticleNode,
  //   supports: ["Web Bot"],
  // },
  {
    type: "button",
    name: "Button",
    icon: "mouse",
    category: "input-options",
    color: "#FDDB84",
    displayComponent: ButtonNode,
    supports: ["Web Bot", "Whatsapp"],
    validation: {
      web: {},
      Whatsapp: {
        maxLimit: 3,
      },
    },
  },
  {
    type: "carousel",
    name: "Carousel",
    icon: "carousel",
    category: "input-options",
    color: "#B7D3FE",
    displayComponent: CarouselNode,
  },
  // {
  //   type: "alphanumeric",
  //   name: "Alphanumeric",
  //   icon: "alphanumeric",
  //   category: "input-options",
  //   color: "#FFD1AB",
  //   displayComponent: AlphanumericNode,
  //   supports: ["Web Bot", "Whatsapp", "Messenger"],
  // },
  // {
  //   type: "date",
  //   name: "Date",
  //   icon: "date",
  //   category: "input-options",
  //   color: "#EDEF8A",
  //   displayComponent: DateNode,
  // },
  // {
  //   type: "feedback",
  //   name: "Feedback",
  //   icon: "feedback",
  //   category: "input-options",
  //   color: "#9DF8D7",
  //   displayComponent: FeedbackNode,
  //   supports: ["Web Bot", "Whatsapp"],
  // },
  // {
  //   type: "dropdown",
  //   name: "Dropdown",
  //   icon: "dropdown",
  //   category: "input-options",
  //   color: "#91EAF5",
  //   displayComponent: DropdownNode,
  //   optionsLimit: null,
  //   supports: ["Web Bot", "Whatsapp"],
  //   validation: {
  //     web: {},
  //     Whatsapp: {
  //       maxLimit: 10,
  //     },
  //   },
  // },
  {
    type: "number",
    name: "Number",
    icon: "number",
    category: "input-options",
    color: "#ECB3DF",
    displayComponent: NumberNode,
    supports: ["Web Bot", "Whatsapp", "Messenger"],
  },
  {
    type: "img-file",
    name: "Img/File",
    icon: "file-attachment",
    category: "input-options",
    color: "#FFCC9C",
    displayComponent: ImgFileNode,
    allowedFiles: [
      {
        name: "Images",
        supportedExtensions: [
          {
            label: "jpg/jpeg",
            value: "image/jpeg",
          },
          {
            label: "png",
            value: "image/png",
          },
          {
            label: "svg",
            value: "image/svg+xml",
          },
          {
            label: "webp",
            value: "image/webp",
          },
          {
            label: "gif",
            value: "image/gif",
          },
        ],
      },
      {
        name: "Documents",
        supportedExtensions: [
          {
            label: "pdf",
            value: "application/pdf",
          },
          {
            label: "docx",
            value:
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          },
          {
            label: "xlsx",
            value:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          {
            label: "csv",
            value: "text/csv",
          },
        ],
      },
    ],
    supports: ["Web Bot", "Whatsapp"],
  },
  // {
  //   type: "custom",
  //   name: "Custom",
  //   icon: "custom",
  //   category: "input-options",
  //   color: "#C3D1FF",
  //   displayComponent: CustomNode,
  //   supports: ["Web Bot", "Whatsapp", "Messenger"],
  // },
  {
    type: "textfield",
    name: "Textfield",
    icon: "text-field",
    category: "input-options",
    color: "#9AE2B2",
    displayComponent: TextfieldNode,
    supports: ["Web Bot", "Whatsapp", "Messenger"],
  },
  // {
  //   type: "time",
  //   name: "Time",
  //   icon: "time",
  //   category: "input-options",
  //   color: "#D6F1AA",
  //   displayComponent: TimeNode,
  //   supports: ["Web Bot"],
  // },
  // {
  //   type: "time",
  //   name: "Time",
  //   icon: "time",
  //   category: "input-options",
  //   color: "#D6F1AA",
  //   displayComponent: TimeNode,
  // },
];
