import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { useCurrentBot } from "../Store/Slices/DashboardSlices";

interface Props {
  showBackButton?: boolean;
}

const App404: React.FC<Props> = ({ showBackButton = false }) => {
  const navigate = useNavigate();
  const currentBot = useSelector(useCurrentBot);

  const navigatetoHome = () => {
    if (window.localStorage.getItem("expiry_time") && currentBot) {
      const subUrl = `/workspace/{0}/setup/bots`;
      navigate(subUrl);
    } else {
      window.localStorage.removeItem("expiry_time");
      navigate("/login");
    }
  };
  const navigatetoBack = () => {
    navigate(-2);
  };

  return (
    // <StyledContainer404>
    //   <img src={error404} alt="page not found" className="pageImage" />
    //   <p className="pageTitle">
    //     Unfortunately the page you are looking for has been moved or deleted.
    //   </p>
    //   <div className="actionBox">
    //     <AppButton onClick={() => handleClick()}>Go to Homepage</AppButton>
    //   </div>
    // </StyledContainer404>
    <StyledContainer404>
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Look like you're lost</h3>

                  <p>the page you are looking for not available!</p>
                  {showBackButton ? (
                    <button
                      className="link_404"
                      onClick={() => navigatetoBack()}
                    >
                      Go Back
                    </button>
                  ) : (
                    <button
                      className="link_404"
                      onClick={() => navigatetoHome()}
                    >
                      Go to home
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </StyledContainer404>
  );
};

export default App404;

const StyledContainer404 = styled.div`
  && {
    /* min-height: calc(100vh - 100px); */
    min-height: 100%;
    width: 100%;
    gap: 1.5rem;
    /*======================
    404 page
    =======================*/

    .page_404 {
      padding: 40px 0;
      background: #fff;
      font-family: "Arvo", serif;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      min-height: calc(100vh - 4rem);
      /* height: 100%; */
    }

    .page_404 img {
      width: 100%;
    }

    .four_zero_four_bg {
      width: 100%;
      background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
      background-repeat: no-repeat;
      background-position: center;
      height: 400px;
      background-position: center;
    }

    .four_zero_four_bg h1 {
      font-size: 80px;
    }

    .four_zero_four_bg h3 {
      font-size: 80px;
    }

    .link_404 {
      color: #fff !important;
      padding: 10px 20px;
      background: #39ac31;
      margin: 20px 0;
      border: none;
      cursor: pointer;
      display: inline-block;
    }
    .contant_box_404 {
      margin-top: -50px;
    }
  }
`;
