import { convertWhatsappToHtmlText } from "./editor";

const getText = (response: any, channel?: string) => {
  let text = "";
  if (response && response !== null && response.length > 0) {
    const match = response.filter(
      (item: any) =>
        item.type === "text" ||
        item.type === "carousel" ||
        item.hasOwnProperty("interactive"),
    );
    if (match.length > 0) {
      if (match?.[0]?.value) {
        if (Array.isArray(match?.[0]?.value) && match?.[0]?.value?.length > 0) {
          text = match?.[0]?.value?.[0].text;
        } else {
          text = match?.[0]?.value;
        }
      } else if (match?.[0]?.text) {
        text = match?.[0]?.text;
      } else if (match?.[0]?.interactive) {
        text = match?.[0]?.interactive.body;
      } else {
        text = "";
      }
    }
  }
  if (channel === "whatsapp") {
    return convertWhatsappToHtmlText(text);
  }
  return text;
};
export default getText;
