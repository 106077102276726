import React, { useEffect, useState } from "react";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import Container from "./Container";
import { ContactsCard } from "./SortableCards";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import {
  CardDetails,
  GridData,
  SeeMore,
  afterDeletedContacts,
  getFilterQuery,
  getNotesDetails,
} from "../../../Store/Slices/Contacts/contacts.selector";
import {
  DeleteData,
  SeeMorePaginationData,
  kanBanCardDetails,
  setNotesData,
} from "../../../Store/Slices/Contacts/contacts.slice";
import { withLoader } from "../../../AppComponents/Loader";
import AppDeleteItem from "../../../AppComponents/AppDeleteItem";
import styled from "styled-components";
import AppModel from "../../../Hooks/useModel/AppModel";
import { DeleteCannedResponse } from "../../Broadcast/WhatsApp/Templates/TemplatesListing";
import AppButton from "../../../AppComponents/AppButton";
import { StyledProps } from "@material-ui/core";
import { theme } from "../../../Customization/Theme";
import { useSelector } from "react-redux";
import {
  convertQueryToMongo,
  initialQuery,
} from "../../../AppComponents/QueryBuilder/utils/utils";
import { Box } from "@mui/material";

const wrapperStyle: any = {
  display: "flex",
  flexDirection: "row",
  // background: "red",
};

function SwimLaneView(props: any) {
  // local values
  const retrievedValue = localStorage.getItem("grid_view") || null;
  // redux data
  const { localValues, toggleContactModal, shouldApply, setShouldApply } =
    props;
  // redux
  const gridItems = useAppSelector(GridData);
  const moreData: any = useAppSelector(SeeMore);
  const filterQuery = useSelector(getFilterQuery);
  const refreshData: any = useAppSelector(afterDeletedContacts);
  const noteDetails = useSelector(getNotesDetails);
  const [noteContext, setNoteContext] = useState();
  const allContacts = useAppSelector(CardDetails);
  // hooks
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  // states
  const [deleteData, setDeleteData] = useState<any>(null);
  const [deletedContactStore, setDeletedContactStore] = useState<any>({});
  const [items, setItems] = useState<any>([]);
  const [Loader, setLoader] = useState(false);
  const [applyFilter, setApplyFilter] = useState(true);
  const [activeId, setActiveId] = useState<any>();
  const [pagination, setPagination] = useState<any>({
    index: 0,
    status: "",
    statusId: "",
    stageId: "",
    limit: 10,
  });
  const [updateIds, setUpdateIds] = useState<any>({
    id: "",
    stage: "",
    status: "",
  });
  // sensor to control  onclick behavior
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );
  // find container
  function findContainer(id: string) {
    const containerKey = items?.find((key: any) =>
      key?.statuses?.some((item: { contacts: { id: string }[] }) =>
        item?.contacts?.some((contact) => contact?.id === id)
      )
    );

    // for blank array
    const result = items?.find((key: any) =>
      key?.statuses?.some((item: { name: string }) => item?.name === id)
    );
    if (containerKey) {
      // const container = items[containerKey];
      const container = containerKey?.statuses;

      const matchingItem = container?.find(
        (item: { contacts: { id: string }[] }) =>
          item?.contacts?.some((contact) => contact.id === id)
      );

      if (matchingItem) {
        return {
          stage: containerKey?.name,
          name: matchingItem.name,
        };
      }
    } else if (result) {
      return {
        stage: result.name,
        name: id,
      };
    } else if (id in items) {
      return {
        stage: id,
        name: null,
      };
    }

    return {
      stage: null,
      name: null,
    };
  }

  // drag start contact
  function handleDragStart(event: { active: any }) {
    const { active } = event;
    const { id } = active;
    setActiveId(allContacts?.find((elm: any) => elm?.id === id) || null);
  }

  // drag over contact container
  function handleDragOver(event: {
    active: any;
    over: any;
    draggingRect: any;
  }) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    if (!over) {
      return;
    }

    const { id: overId } = over;

    // Find the containers
    const activeContainer: any = findContainer(id);
    const overContainer: any = findContainer(overId);

    if (
      !activeContainer?.name ||
      !overContainer?.name ||
      activeContainer?.name === overContainer?.name
    ) {
      return;
    }

    const getContactArray = (container: { stage: string; name: any }) => {
      const foundStage = items.find((i: any) => i.name === container.stage);

      if (foundStage) {
        const foundStatus = foundStage.statuses.find(
          (item: { name: any }) => item.name === container.name
        );

        if (foundStatus) {
          return foundStatus.contacts;
        }
      }

      // Return an empty array if the stage or status is not found
      return [];
    };

    setItems((prev: { [x: string]: any }) => {
      const activeItems = getContactArray(activeContainer);
      const overItems = getContactArray(overContainer);

      // Find the indexes for the items
      const activeIndex = activeItems.findIndex(
        (contact: { id: string }) => contact.id === id
      );
      const overIndex = overItems.findIndex(
        (contact: { id: string }) => contact.id === overId
      );

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems.length - 1 &&
          draggingRect?.offsetTop > over.rect?.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length;
      }

      // for update
      const foundStage = items.find((i: any) => i.name === overContainer.stage);
      const testObject = foundStage.statuses.find(
        (item: { name: any }) => item.name === overContainer.name
      );

      // for source
      const foundStage2 = items.find(
        (i: any) => i.name === activeContainer.stage
      );
      const activContact = foundStage2.statuses.find(
        (item: { name: any }) => item.name === activeContainer.name
      );

      // Update the contact data for the destination container
      const statusIndex = foundStage.statuses.findIndex(
        (status: { name: any }) => status.name === overContainer.name
      );

      const updatedStatuses = foundStage.statuses;
      let records = updatedStatuses[statusIndex]?.total_records || 0;
      let totalRecords = records >= 0 ? records + 1 : 0;
      updatedStatuses[statusIndex] = {
        ...updatedStatuses[statusIndex],
        total_records: totalRecords,
        contacts: [
          activContact?.contacts[activeIndex],
          ...testObject?.contacts?.slice(0, newIndex),
          ...testObject?.contacts?.slice(newIndex),
        ],
      };

      // Update the contact data for the source container
      const statusIndex2 = foundStage2.statuses.findIndex(
        (status: { name: any }) => status.name === activeContainer.name
      );
      const activContacts = foundStage2.statuses;
      let a = activContacts[statusIndex2]?.total_records - 1;
      activContacts[statusIndex2] = {
        ...activContacts[statusIndex2],
        total_records: a,
        contacts: activContact.contacts.filter(
          (contact: { id: string }) => contact.id !== id
        ),
      };

      // for api call payload **********
      setUpdateIds({
        id: id,
        stage: foundStage?.id,
        status: testObject?.id,
      });

      return prev.map((item: any) => {
        if (item.stage === activeContainer.stage) {
          return { ...item, contacts: activContacts };
        } else if (item.stage === overContainer.stage) {
          return { ...item, statuses: updatedStatuses };
        }
        return item;
      });
    });
  }

  // drag end
  function handleDragEnd(event: { active: any; over: any }) {
    const { active, over } = event;
    const { id } = active;
    if (!over) {
      return;
    }
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    submitFunctionCall();
    if (
      !activeContainer?.name ||
      !overContainer?.name ||
      activeContainer?.name !== overContainer?.name
    ) {
      return;
    }

    const getContactArray = (container: { stage: string; name: any }) => {
      const foundStage = items.find((i: any) => i.name === container.stage);

      if (foundStage) {
        const foundStatus = foundStage.statuses.find(
          (item: { name: any }) => item.name === container.name
        );

        if (foundStatus) {
          return foundStatus.contacts;
        }
      }

      // Return an empty array if the stage or status is not found
      return [];
    };

    const activeItems = getContactArray(activeContainer);
    const overItems = getContactArray(overContainer);

    const activeIndex = activeItems.findIndex(
      (contact: { id: string }) => contact.id === id
    );
    const overIndex = overItems.findIndex(
      (contact: { id: string }) => contact.id === overId
    );

    if (activeIndex !== overIndex) {
      setItems((prev: { [x: string]: any }) => {
        const { stage, name } = overContainer;
        const foundStage2 = prev.find((i: any) => i.name === stage);

        if (foundStage2) {
          const updatedStages = foundStage2.statuses.map((item: any) =>
            item.name === name
              ? {
                  ...item,
                  // contacts: arrayMove(item.contacts, activeIndex, overIndex),
                  contacts: item.contacts,
                }
              : item
          );

          return prev?.map((item: any) =>
            item.name === stage ? { ...item, statuses: updatedStages } : item
          );
        }
      });
    }

    setActiveId(null);
  }

  // paginaton
  useEffect(() => {
    if (pagination.statusId && pagination?.statusId?.length) {
      setLoader(true);
      dispatch(ContactsActions.GetMoreContacts(pagination))
        .then((res) => {
          if (res.payload.data.status) {
          } else {
            showAlert(
              res?.payload?.message || "Enabled to fetch Data",
              "error"
            );
            // sortArray();
            showAlert(
              res?.payload?.message || "Enabled to fetch Data",
              "error"
            );
          }
        })
        .catch((error) => {
          // sortArray();
          showAlert(
            error?.response?.statusText || "Enabled to fetch Data",
            "error"
          );
        })
        .finally(() => {
          setLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  useEffect(() => {
    if (moreData && moreData?.contacts?.length && items && items?.length) {
      setItems((prev: { [x: string]: any }) => {
        // for update
        const foundStage = items.find((i: any) => i.id === pagination?.stageId);
        const testObject = foundStage?.statuses?.find(
          (item: { id: any }) => item?.id === pagination?.statusId
        );

        // Update the contact data for the destination container
        const statusIndex = foundStage?.statuses.findIndex(
          (status: { id: any }) => status?.id === pagination?.statusId
        );

        const updatedStatuses = foundStage?.statuses;
        updatedStatuses[statusIndex] = {
          ...updatedStatuses[statusIndex],
          contacts: [...testObject?.contacts, ...moreData?.contacts],
        };
        return prev.map((item: any) => {
          if (item?.id === pagination?.stageId) {
            return { ...item, statuses: updatedStatuses };
          }
          return item;
        });
      });
      dispatch(SeeMorePaginationData(moreData));
    } else {
      if (
        refreshData &&
        refreshData?.length >= 0 &&
        deletedContactStore?.statusId
      ) {
        setItems((prev: { [x: string]: any }) => {
          // for update
          const foundStage = items.find(
            (i: any) => i.id === deletedContactStore?.stageId
          );
          // Update the contact data for the destination container
          const statusIndex = foundStage?.statuses.findIndex(
            (status: { id: any }) =>
              status?.id === deletedContactStore?.statusId
          );

          const updatedStatuses = foundStage?.statuses;
          updatedStatuses[statusIndex] = {
            ...updatedStatuses[statusIndex],
            total_records: updatedStatuses[statusIndex]?.total_records - 1,
            contacts: refreshData,
          };
          return prev.map((item: any) => {
            if (item?.id === deletedContactStore?.stageId) {
              return { ...item, statuses: updatedStatuses };
            }
            return item;
          });
        });
        dispatch(DeleteData(moreData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moreData, deletedContactStore]);

  useEffect(() => {
    if (items && items?.length) {
      const updatedStore =
        items?.flatMap(({ statuses = [] }) =>
          statuses?.flatMap(({ contacts = [] }) => contacts)
        ) || [];
      // dispatch function on items value update to show in card when drag ***
      dispatch(kanBanCardDetails(updatedStore));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  // store all contact data in array ob object
  const sortArray = () => {
    let ArrayCopy =
      gridItems && gridItems.length
        ? JSON.parse(JSON.stringify(gridItems))
        : [];
    let aa = ArrayCopy.map((item: any) => {
      return {
        ...item,
        statuses: [...item.statuses].sort(),
      };
    });
    setItems(aa);
  };

  useEffect(() => {
    if (gridItems && gridItems.length) {
      sortArray();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridItems]);

  //  api's calling
  // get data api
  const getGridData = () => {
    props.setLoading(true);
    let payload: any;

    if (applyFilter) {
      payload = {
        contact_filter: localValues?.contact_filter,
        search: localValues?.search,
      };
    } else {
      payload = {
        contact_filter: localValues?.contact_filter,
        search: localValues?.search,
        mongodb_query: convertQueryToMongo(
          filterQuery ? filterQuery : initialQuery
        ),
        filter_query: filterQuery ? filterQuery : initialQuery,
      };
    }
    dispatch(ContactsActions.getContactGridView(payload))
      .then((res: any) => {
        props.setLoading(false);
        props.toggleDrawer(false);
        setApplyFilter(false);
        if (res?.error?.message === "Rejected") {
          // showAlert(
          //   res?.payload?.data?.message || "Enabled to fetch Data",
          //   "error"
          // );
        } else {
          props.toggleDrawer(false);
        }
      })
      .catch((error: any) => {
        // showAlert(
        //   error?.response?.statusText || "Enabled to fetch Data",
        //   "error"
        // );
      })
      .finally(() => {
        props.setLoading(false);
      });
  };
  // geta data
  useEffect(() => {
    if (shouldApply === true) {
      getGridData();
      setShouldApply(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldApply]);

  // useEffect get data for grid view
  useEffect(() => {
    props.setLoading(false);
    if (retrievedValue === "true") {
      getGridData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValues]);

  // submit for update contacts
  const submitFunctionCall = () => {
    if (updateIds?.status) {
      dispatch(ContactsActions.ContactGridViewUpdate(updateIds))
        .then((res) => {
          if (res.payload.data.status) {
            // getGridData();
            // return null;
          } else {
            showAlert(
              res?.payload?.message || "Enabled to fetch Data",
              "error"
            );
            sortArray();
            showAlert(
              res?.payload?.message || "Enabled to fetch Data",
              "error"
            );
          }
        })
        .catch((error) => {
          sortArray();
          showAlert(
            error?.response?.statusText || "Enabled to fetch Data",
            "error"
          );
        })
        .finally(() => {});
    }
  };

  // contacts delete api
  const handleDeleteBtn = async (item: any) => {
    setLoader(true);
    try {
      let payload = {
        contact_id: deleteData?.id,
        lifecycle_status: deleteData?.statusId,
        record_size: deleteData?.index,
        contact_filter: localValues?.filter_query,
        search: localValues?.search,
        mongodb_query: localValues.mongoQuery,
      };
      const res: any = await dispatch(ContactsActions.DeletContacts(payload));

      if (res?.error?.message === "Rejected") {
        showAlert(res?.error?.message || "Contact not deleted", "error");
      } else {
        showAlert(res?.payload?.data?.msg || "Contact deleted", "success");
        setDeletedContactStore(deleteData);
        setDeleteData(null);
      }
    } catch (error) {
      showAlert("Something went wrong", "error");
    } finally {
      setLoader(false);
    }
  };

  // add notes api
  const addNote = () => {
    let data = {
      contact_id: noteDetails?.contactId,
      context: noteContext,
    };
    setLoader(true);
    dispatch(ContactsActions.addNoteAsPerId(data))
      .then((response: any) => {
        showAlert(response?.payload?.data?.message, "success");
        const payload = {
          status: false,
          contactId: "",
        };
        dispatch(setNotesData(payload));
        getGridData();
      })
      .catch((error: any) => {
        // setPaginationData({ ...paginationData, pageReady: false });
      })
      .finally(() => {
        // props.setLoading(false);
        setLoader(false);
      });
  };
  const closeNote = () => {
    const payload = {
      status: false,
      contactId: "",
    };
    dispatch(setNotesData(payload));
  };
  let subTitle = `Are you sure, you want to delete item`;

  return (
    <TableWrapper style={wrapperStyle} className="check">
      {/* <div className="card-container"> */}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={(e: any) => handleDragOver(e)}
        onDragEnd={handleDragEnd}
      >
        {/* container ----1 */}

        {items?.map(
          (res: {
            id: any;
            label:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | React.ReactFragment
              | React.ReactPortal
              | null
              | undefined;
            statuses: { k: any; label: any; contacts: any; name: any }[];
          }) => {
            return (
              <div className="data-container" style={{ borderRadius: "8px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "40px",
                    background: "#D7DEFF",
                    borderRadius: "8px 8px 0px 0px",
                    alignItems: "center",
                  }}
                >
                  {res.label}
                </div>
                <div style={{ display: "flex", background: "white" }}>
                  {res?.statuses?.map((k: any, index: number) => {
                    return (
                      k?.contacts && (
                        <Container
                          id={k?.name}
                          items={k?.contacts}
                          label={k.label}
                          setPagination={setPagination}
                          totalRecords={k?.total_records || 0}
                          index={index}
                          // for pagination
                          stageId={res.id}
                          statusId={k?.id}
                          setDeleteData={setDeleteData}
                          Loader={Loader}
                          pagination={pagination}
                          toggleContactModal={toggleContactModal}
                        />
                      )
                    );
                  })}
                </div>
              </div>
            );
          }
        )}
        <DragOverlay>
          {activeId ? (
            <ContactsCard id={activeId?.id} items={activeId} />
          ) : null}
        </DragOverlay>
      </DndContext>
      {/* </div> */}

      {/* delete modal*/}
      {items && items?.length ? (
        <AppDeleteItem
          title="Delete Contact"
          subTitle={subTitle}
          onDelete={() => handleDeleteBtn(deleteData)}
          setDeleteData={setDeleteData}
          deleteData={deleteData}
        />
      ) : null}
      {/* add note modal */}
      <AppModel
        isShowing={noteDetails?.status}
        divStyle={{
          width: "550px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <DeleteCannedResponse>
          <div className="text">
            <h4>Add Note</h4>
          </div>
          <div className="header">
            <Textarea
              onChange={(e: any) => {
                setNoteContext(e.target.value);
              }}
            />
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => closeNote()} style={{}}>
              Cancel
            </AppButton>
            <AppButton onClick={() => addNote()} style={{}}>
              Update
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
    </TableWrapper>
  );
}
export default withLoader(SwimLaneView);

const TableWrapper = styled("div")`
  & {
    height: calc(100vh - 174px); /* Set your desired height */
    min-height: 400px;
    overflow: auto;
    scroll-behavior: auto;

    * {
      box-sizing: border-box;
    }

    html {
      /* font-family: sans-serif; */
      font-size: 14px;
    }

    table {
      border: none;
      box-shadow: none;
      position: relative;
      tr {
        th {
          box-shadow: none;
          border-bottom: 1px solid lightgray;
        }
      }
    }
    .table-container {
      overflow: auto;
      scroll-behavior: auto;
    }

    table,
    .divTable {
      /* border: 1px solid lightgray; */

      /* width: fit-content; */
    }

    .tr {
      display: flex;
    }

    tr,
    .tr {
      width: fit-content;
      height: 30px;
    }

    th,
    .th,
    td,
    .td {
      /* box-shadow: 0 0 0 1px lightgray; */
      padding: 0.25rem;
    }

    th,
    .th {
      padding: 1rem;
      position: relative;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      width: 100px;
      font-style: 16px !important;
    }

    td,
    .td {
      width: 100px;
      text-align: left;
    }
    .table-data {
      font-style: 14px !important;
      color: #7e8392 !important;
      line-height: 21px;
      font-style: normal !important;
      border: none !important;
      /* border: 1px solid red !important; */
    }
    .sc-gCkVGe kePTvV {
      overflow: auto;
      scroll-behavior: auto;
    }
    .borderless-table {
      border: none;
    }
    .resizer {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 1px;
      background: #cbcdd3;
      cursor: col-resize;
      user-select: none;
      touch-action: none;
      /* margin: 5px; */
    }

    .resizer.isResizing {
      background: #cbcdd3;
      opacity: 1;
    }

    @media (hover: hover) {
      .resizer {
        opacity: 0;
      }

      *:hover > .resizer {
        opacity: 1;
      }
    }
  }
`;
const Textarea = styled.textarea<StyledProps>`
  && {
    width: -webkit-fill-available;
    height: 120px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 4px 2rem 8px 14px;
    padding: 6px 1rem 6px 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    /* overflow:hidden; */
    resize: none;

    /* ::-webkit-scrollbar {
   display: none;
   width: 1px;
 } */
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    // height: 80px;
  }
`;
const ContactNodata = styled(Box)`
  && {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 4rem 2rem 2rem 2rem;
    box-shadow: none !important;
  }
`;
