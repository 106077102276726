import { Box, Paper, Typography, debounce } from "@mui/material";
import {
  DetailsTable,
  DocumentAndTextTableWrapper,
  LivePlanContainer,
  NewNativeSelect,
  Scroll,
  SubscriptionPlan,
  Title,
  WebsiteWrap,
} from "./style";
import { AppForm } from "../../AppComponents/Forms";
import AppFormSearchField from "../../AppComponents/Forms/AppFormSearchField";
import { DefinedCustomDates } from "../../Models/defaultSelectOptions";
import { useEffect, useState } from "react";
import AppRangeDatePicker from "../../AppComponents/AppRangeDatePicker";
import { useSelector } from "react-redux";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { useAppDispatch } from "../../Store";
import { SubscriptionActions } from "../../Store/Slices/Subscriptions/subscriptions.actions";
import {
  getPendingInvoiceStatus,
  invoicePaidStatus,
} from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import moment from "moment";
import { ErrorComponent } from "../../AppComponents/Forms/Styles";
import TemplateAPIERROR from "../../assets/images/TemplateAPIERROR.png";
import { SubscriptionsNetworkService } from "../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import AddWallet from "../Menu/AddWallet";
import { renderSkeleton } from "./const";
import {
  resetInvoicePaidStatus,
  resetPendingInvoiceStatus,
} from "../../Store/Slices/Subscriptions/subscriptions.slice";
import PayNowIfram from "./Pay_now/payNowIfram";
import AppPaginationComponent from "../../AppComponents/Pagination/Pagination";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { TableWrapper } from "../../TableStyle";
import NoRecordsFound from "../UiComponents/NoRecordsFound/NoRecordsFound";

interface MenuProps extends HocBackdropLoaderProps {
  check?: any;
  setCheck?: any;
  callBackApi?: any;
  creditList?: any;
  pagination?: any;
}
const CrediteUtilization: React.FC<MenuProps> = ({
  setLoading,
  check,
  setCheck,
  callBackApi,
  creditList,
  pagination,
}) => {
  const { userConfig } = useSelector(useAuthStore);
  const pendingInvoiceStatus = useSelector(getPendingInvoiceStatus);
  const invoicePaid = useSelector(invoicePaidStatus);
  const { data: authData } = useSelector(useAuthStore);
  const [localValues, setLocalValues] = useState<any>({
    search: "",
    status: "All invoices",
    category: "",
    start_date: DefinedCustomDates?.last30Days?.startDate,
    end_date: DefinedCustomDates?.last30Days?.endDate,
    currentPage: 0,
    perPage: { label: "10  per page", value: 10 },
  });
  const [dateRange, setDateRange] = useState<any>({
    startDate: DefinedCustomDates?.last30Days?.startDate,
    endDate: DefinedCustomDates?.last30Days?.endDate,
  });
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [payNow, setPayNow] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [invoiceLink, setInvoiceLink] = useState("");
  const dispatch = useAppDispatch();
  // get invoice details
  const getPreviousData = () => {
    setShowSkeleton(false);
    callBackApi(localValues, dateRange);
  };
  const handleSelectSearch = debounce((e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  }, 700);
  const clearCallback = (e?: any, field?: any) => {
    setLocalValues({
      ...localValues,
      [field]: e,
    });
  };
  function handlePageChange(event: any, value: any) {
    setLocalValues({
      ...localValues,
      currentPage: value - 1,
    });
  }
  const handleChangeRowsPerPage = (value: any) => {
    setLocalValues({
      ...localValues,
      perPage: value,
      currentPage: 0,
    });
    // setShouldApply(true);
  };
  useEffect(() => {
    if (creditList?.length) {
      setShowSkeleton(false);
      setLoading(false);
    }
  }, [creditList]);

  useEffect(() => {
    if (pagination?.totalRecords === 0) {
      setShowSkeleton(false);
    }
  }, [pagination]);

  useEffect(() => {
    getPreviousData();
  }, [localValues, dateRange]);

  // view Invoice API
  const downloadInvoice = (invoice_Id: any) => {
    setLoading(true);
    SubscriptionsNetworkService.getInvoiceByID(invoice_Id)
      .then((res: any) => {
        setLoading(false);
        window.open(res?.data?.url, "_blank");
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {});
  };
  const addBalance = (balance: number) => {
    setLoading(true);
    setCheck(false);
    let data = {
      subscription_id: userConfig?.payload?.subscription_id,
      addons: [
        {
          addon_code: "wallet",
          quantity: balance,
        },
      ],
      redirect_url: "/subscription/success",
    };
    SubscriptionsNetworkService.addCredits(
      data,
      userConfig?.payload?.subscription_id
    )
      .then((res: any) => {
        window.open(res?.data?.hostedpage?.url, "_self");
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getInvoiceLink = (invoice_id?: any) => {
    // setPayNow(true)
    setLoading(true);
    setInvoiceId(invoice_id);
    SubscriptionsNetworkService.getInvoicePaymentLink(invoice_id)
      .then((res: any) => {
        setInvoiceLink(res?.data?.response);
        setLoading(false);
        setPayNow(true);
        updateInvoiceStatus(invoice_id);
        setInvoiceId(invoice_id);
      })
      .catch((err: any) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const updateInvoiceStatus = (invoice_id?: any) => {
    let data = {
      subscription_id: userConfig?.payload?.subscription_id,
      business_id: authData?.data?.user?.business_id,
      customer_id: authData?.data?.business?.subscription_data?.customer_id,
      invoice_id: invoice_id ? invoice_id : invoiceId,
    };
    setTimeout(() => {
      dispatch(SubscriptionActions.updateSubscriptionCallbackData(data));
      dispatch(resetPendingInvoiceStatus(false));
    }, 2000);
  };
  const onClose = () => {
    setPayNow(false);
  };
  useEffect(() => {
    if (invoicePaid) {
      setPayNow(false);
      callBackApi(localValues, dateRange);
      dispatch(resetInvoicePaidStatus(false));
    }
  }, [invoicePaid]);
  useEffect(() => {
    if (pendingInvoiceStatus && payNow) {
      updateInvoiceStatus(invoiceId);
    }
  }, [pendingInvoiceStatus, payNow]);


  const header = [
    "Sr No",
    "Date",
    "Transactions",
    "Amount",
    "Invoice",
  ];

  const keyToHeaderMap: any = {
    "Date": "date"
  };

  const colWidth: any = {
    "Sr No": "90",
    "Date": "120",
    "Transactions": "260",
    "Amount": "200",
    "Invoice": "200",
  };

  const renderColumns = (row: any, header: any, rowIndex: any) => {
    return <Box
        className="table-text"
        sx={{
          width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        }}
    >
        {

          header === "Invoice" ? <>
            {row?.transactionType !== "debit" ? (
                          <a
                            className="sample-csv-btn"
                            onClick={() => downloadInvoice(row?.invoice_id)}
                          >
                            <FileDownloadOutlinedIcon
                              style={{ color: "#5B73E8" }}
                            />
                          </a>
                        ) : "-"}
          </>:
          header === "Amount" ? <Box style={{
            color:
              row?.transactionType === "debit" ? "red" : "green",
          }}>
            {`₹ ${row?.totalCost}`}
          </Box> :

          header === "Transactions" ? <>
          
          {row?.transactionType === "debit"
                          ? "Balance deducted for " +
                          row?.category +
                            " conversations"
                          : "Balance added to wallet"}
          
          </> :
          header === "Sr No" ?  <Box> {rowIndex + 1} </Box> :
          header === "Date" ? <>{moment(row?.import_date).format("DD/MM/YYYY") || "-"}</> :
          row[keyToHeaderMap[header]]
          ? row[keyToHeaderMap[header]]
          : "-"
        }
    </Box>



}
  return (
    <>
      <DetailsTable>
        <div style={{marginBottom: 0}} className="flex-wrapper">
          Total Invoices: <strong> {pagination?.totalRecords}</strong>
          <LivePlanContainer
            align={"flex-end"}
            gap={"8px"}
            style={{ marginLeft: "auto" }}
          >
            <AppForm
              initialValues={{ search: "" }}
              onSubmit={(values: any, submitProps: any) => {}}
              divStyle={{ width: "100%", paddingLeft: "10px" }}
            >
              <div
                style={{
                  display: "-webkit-inline-flex",
                  justifyContent: "right",
                  marginLeft: "auto",
                }}
              >
                <AppFormSearchField
                  name="search"
                  placeholder="Search"
                  defaultBorder={true}
                  onChange={(e: any) => {
                    handleSelectSearch(e, "search");
                  }}
                  clearCallback={(e: any) => clearCallback("", "search")}
                  divStyle={{
                    minWidth: "280px",
                    maxWidth: "280px",
                    margin: 0,
                  }}
                />
                <div className="right">
                  <AppRangeDatePicker
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                  />
                </div>
              </div>
            </AppForm>
          </LivePlanContainer>
        </div>


        <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 464px)"}>
          <div>
            <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                      key={index}
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ 
                          minWidth: colWidth[header] + "px",
                          maxWidth: header === "Sr No" ? "60px" : "auto"
                        }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                
                {/* Table Body */}
              </Box>

              <Box className="table-body">
                {creditList && creditList?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 511px)" />
                ) : (
                  <>
                    {creditList && creditList?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className={`table-row`} >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ 
                                  minWidth: colWidth[header] + "px",
                                  maxWidth: header === "Sr No" ? "60px" : "auto"
                                }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header, rowIndex)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
          </div>
        </TableWrapper>


        
      </DetailsTable>

      <AppPaginationComponent
          totalCount={pagination?.totalRecords}
          handlePageChange={handlePageChange}
          currentPage={localValues?.currentPage}
          totalPages={pagination?.totalPages}
          rowsPerPage={localValues?.perPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />

      <PayNowIfram
        open={payNow}
        invoiceLink={invoiceLink}
        onClose={() => onClose()}
      />
      <AddWallet check={check} setCheck={setCheck} addBalance={addBalance} />
    </>
  );
};

export default HocBackdropLoader(CrediteUtilization);
