import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import AppCircularProgressBar from "../../AppComponents/AppCircularProgressBar";
import {
  AppForm,
  AppFormSelectField,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import AppFormAutoSearchField from "../../AppComponents/Forms/AppFormAutoSearchField";
import AppFormLabel from "../../AppComponents/Forms/AppFormLabel";

import {
  StyledHeaderLeft,
  StyledTableHeader,
  StyledTableHeaderActionButtons,
  StyledTableHeaderTitle,
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { FAQ } from "../../Models";
import { FAQDetailsSchema } from "../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { ConversationService } from "../../Services/Apis/ConversationService";
import {
  useCurrentBot,
  // NotAnsUserInput,
} from "../../Store/Slices/DashboardSlices";
import {
  checkForDuplicatesArrayValues,
  formatString,
  getFaqVaritionProgressValue,
} from "../../Utils";
import FaqFieldArray from "./FaqFieldArray";
import FaqLiveSearchAutoComplete from "./FaqLiveSearchAutoComplete";

import AppFormRichTextEditor from "../../AppComponents/Forms/AppFormRichTextEditor";
import {
  NotAnsUserInput,
  AnsweredSubmitData,
} from "../../Store/Slices/Faq/UserQueries/userquery.selectors";
import {
  updateUserInput,
  setAnsweredSubmitData,
} from "../../Store/Slices/Faq/UserQueries/userquery.slice";
import { useAppDispatch, useAppSelector } from "../../Store";
import { getChannelWiseText, getHtmlTextChannelWise } from "../../socket/appComponents/Forms/RichEditor/WebLiveChat/utils";

interface FaqProps extends HocBackdropLoaderProps {}

const FaqDetails = (props: FaqProps) => {
  useDocumentTitle("FAQ");
  const NewuserInput = useSelector(NotAnsUserInput);
  const dispatch = useAppDispatch();

  let { faq_id } = useParams<string>();
  const location: any = useLocation();
  const currentBot = useSelector(useCurrentBot);
  const navigate = useNavigate();
  const { showAlert } = useNotification();
  const getPayloadFromAnsweredModal = useAppSelector(AnsweredSubmitData);
  let URL = formatString("/bot/{0}/setup/bot-knowledge/faqs", [
    currentBot.bot_id,
  ]);

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };
  const [dynamicVaritionValue, setDynamicVaritionValue] = useState<string>("");
  // const [dynamicQuestionValue, setDynamicQuestionValue] = useState<string>("");
  const [conversationIdValue, setConversationIdValue] = useState<string>("");
  const [selectedJourney, setSelectedJourney] = useState(null); // Initialize with a default value if needed

  useEffect(() => {
    if (location?.state?.variation && location?.state?.variation) {
      setTimeout(() => {
        setDynamicVaritionValue(location?.state?.variation || "");
      }, 1000);
    }
  }, [location]);

  const [userInput, setUserInput] = useState<any>({
    question: "",
    response: [],
    phrases: [],
    journey: null,

    text: "",
    media: {
      file: { url: "" },
      image: { url: "" },
      video: { url: "" },
    },

    language: "english",
  });
  // value comming form answered faq****
  // useEffect(() => {
  //   if (getPayloadFromAnsweredModal && getPayloadFromAnsweredModal?.question) {
  //     props.setLoading(true);
  //     setUserInput(getPayloadFromAnsweredModal);
  //     setTimeout(() => {
  //       props.setLoading(false);
  //       dispatch(setAnsweredSubmitData({}));
  //     }, 500);
  //   }
  // }, [getPayloadFromAnsweredModal]);

  useEffect(() => {
    if (!faq_id && location?.state?.name.query && location?.state?.name.query) {
      setUserInput({
        ...userInput,
        question: location?.state?.name.query || "",
      });
      setConversationIdValue(location?.state?.name.conversation_id || "");
    }
  }, []);

  const [faqIdData, setFaqIdData] = useState<any>(null);
  const [getPhrasesData, setGetPhrasesData] = useState<any>(null);
  const [localValues, setLocalValues] = useState<any>(null);
  let [automaticPhrasesData, setAutomaticPhrasesData] = useState<any>(null);
  const [isLanguageAlreadySynced, setIsLanguageAlreadySynced] =
    useState<boolean>(true);

  const [toSaveValues, setToSaveValues] = useState<any>({});
  const [progress, setProgress] = useState<number>(0);
  const [flowOptions, setFlowOptions] = useState([]);
  let [attachmentsData, setAttachmentsData] = useState<any>([]);
  const [languagesOptions, setLanguagesOptions] = useState<any>([]);
  const [languageStringArray, setLanguageStringArray] = useState<string[]>([]);

  const [value, setValue] = useState<any>(1);

  const [selectedLanguageKey, setSelectedLanguageKey] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let temp = Number((event.target as HTMLInputElement).value);
    setValue(temp);
  };

  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  useEffect(() => {
    if (faq_id) {
      let temporaryData: any = null;

      //* get faq details
      ConversationService.getFaqQuestionDetails(faq_id, HEADERS)
        .then((res: AxiosResponse<{ data: FAQ; status: number }>) => {
          temporaryData = { ...res?.data?.data };
          setFaqIdData(res?.data?.data);
          //  props.setLoading(false)
        })
        .catch((error: any) => {
          // props.setLoading(false)
        });

      // * get phrases
      ConversationService.getPhrases(faq_id, HEADERS)
        .then((res: any) => {
          setGetPhrasesData(res?.data?.data);
        })
        .catch((error: any) => {});
    }

    //* get Flow List
    ConversationService.getFlowList(HEADERS)
      .then((res: AxiosResponse<any>) => {
        if (Array.isArray(res?.data?.data)) {
          setFlowOptions(res?.data?.data);
        } else {
          setFlowOptions([]);
        }
      })
      .catch((error: any) => {});

    //* get available languages
    ChatbotConsoleService.getBotById({ bot_id: currentBot.bot_id })
      .then((response: any) => {
        const data = response?.data?.chatbot_languages;
        setLanguagesOptions(data);

        let temp: string[] = [];
        data &&
          data?.length > 0 &&
          data?.map((curItem: any, index: number) => {
            temp.push(curItem?.language);
          });
        setLanguageStringArray(temp);
      })
      .catch((error: any) => {});
  }, [faq_id, location, currentBot]);

  useEffect(() => {
    if (localValues?.language && localValues?.language != "english") {
      props.setLoading(true);
      setTimeout(() => {
        setSelectedLanguageKey("_" + localValues?.language);
        props.setLoading(false);
      }, 300);
    } else {
      setSelectedLanguageKey("");
    }
  }, [localValues?.language]);

  useEffect(() => {
    if (getPhrasesData && faqIdData) {
      setAlreadyValuesFn(faqIdData, getPhrasesData, selectedLanguageKey);
    }
  }, [faqIdData, getPhrasesData, selectedLanguageKey]);

  useEffect(() => {
    if (faq_id) {
      props.setLoading(true);
      setTimeout(() => {
        props.setLoading(false);
      }, 300);
    }
  }, []);

  const setAlreadyValuesFn = (data: any, getPhrasesData: any, key: string) => {
    // props.setLoading(true);

    let text = "";

    let video: any = {
      url: "",
    };
    let file: any = {
      url: "",
    };
    let image: any = {
      url: "",
    };

    let question = data?.["question" + key];
    let journey = data?.journey;
    let phrases = getPhrasesData?.["phrases" + key];

    if (data?.journey) {
      // setIsLinkToFLowVisible(true);
      setValue(2);
    }

    data &&
      data?.["response" + selectedLanguageKey]?.length > 0 &&
      data?.["response" + selectedLanguageKey]?.map(
        (curElem: any, index: number) => {
          if (curElem?.type === "text") {
            text = curElem?.value || "";
          }
          if (curElem.type === "video") {
            video["url"] = curElem?.value?.url || "";
          }
          if (curElem.type === "file") {
            file["url"] = curElem?.value?.url || "";
          }
          if (curElem.type === "image") {
            image["url"] = curElem?.value?.url || "";
          }
          if (curElem.type === "live_agent") {
            setValue(3);
          }
        }
      );

    let media = {
      file: file,
      video: video,
      image: image,
    };

    // setUserInput({
    //   ...localValues,
    //   question: question,
    //   journey: journey,
    //   phrases: phrases,
    //   text: text || "",
    //   // text: NewuserInput ? NewuserInput?.text : text || "",
    //   media: media,
    // });
    // setProgress(getFaqVaritionProgressValue(phrases?.length || []));

    if (Object.keys(NewuserInput).length === 0) {
      setUserInput({
        ...localValues,
        question: question,
        journey: journey,
        phrases: phrases,
        text: getHtmlTextChannelWise(
          text,
          currentBot?.channel,
        ) || "",
        // text: NewuserInput ? NewuserInput?.text : text || "",
        media: media,
      });
      // dispatch(updateUserInput({}));
      setProgress(getFaqVaritionProgressValue(phrases?.length || []));
    } else {
      setUserInput({
        ...localValues,
        question: question,
        journey: NewuserInput?.journey,
        phrases: phrases,
        text: getHtmlTextChannelWise(
          text,
          currentBot?.channel,
        ),
        media: NewuserInput?.media,
      });
      setTimeout(() => {
        dispatch(updateUserInput({}));
      }, 5000);
      // dispatch(updateUserInput({}));
      setProgress(getFaqVaritionProgressValue(phrases?.length || []));
    }
  };

  const handleRedirect = () => {
    let url = formatString("/bot/{0}/setup/bot-knowledge/faqs/", [
      currentBot.bot_id,
    ]);
    navigate(url);
  };

  const handleCreateBtn = (values: any, submitProps: any, key: string) => {
    let ABC: any = {};

    ABC["question"] = values?.question;

    //if journey exits then send journey otherwise response
    if (value === 2) {
      if (!values?.journey) {
        submitProps.setFieldError(
          "journey",
          "value required in selected field"
        );
        submitProps?.setSubmitting(false);
        return;
      }

      ABC["journey"] = values?.journey;
      ABC["response"] = [];
    } else {
      ABC["journey"] = null;
      let result: any = [];

      if (value === 1) {
        if (
          !values?.text ||
          values?.text === "<p></p>\n" ||
          values?.text === "<p></p>↵"
        ) {
          submitProps.setFieldError("text", "value required in selected field");
          submitProps?.setSubmitting(false);
          return;
        }
        //* add text to response
        let text = {
          type: "text",
          // value: values["text"],
          value: getChannelWiseText(values["text"], currentBot?.channel),
        };
        result?.push(text);

        //* add image to response
        if (
          values?.media &&
          values?.media?.image &&
          values?.media?.image?.url
        ) {
          let image = {
            type: "image",
            value: {
              text: "image",
              url: values?.media?.image?.url,
            },
          };
          result?.push(image);
        }

        //* add video to response
        if (
          values?.media &&
          values?.media?.video &&
          values?.media?.video?.url
        ) {
          let video = {
            type: "video",
            value: {
              text: "video",
              url: values?.media?.video?.url,
            },
          };
          result?.push(video);
        }

        //* add file to response
        if (values?.media && values?.file?.image && values?.media?.file?.url) {
          let file = {
            type: "file",
            value: {
              text: "file",
              url: values?.media?.file?.url,
            },
          };
          result?.push(file);
        }
      }

      if (value === 3) {
        //* add live chat btn to response

        let live = {
          type: "live_agent",
          value: {
            flow_type: "live_agent",
            text: "Connect with Agent",
          },
        };
        result?.push(live);
        // }
      }

      ABC["response"] = result;
      if (conversationIdValue) {
        ABC["conversation_id"] = conversationIdValue;
      }
    }
    ABC["languages"] = languageStringArray || [];

    ConversationService.postFaqsQuestion(ABC, HEADERS)
      .then((res: any) => {
        submitProps?.setSubmitting(false);
        submitProps?.resetForm();
        let temp: any = { ...automaticPhrasesData };
        temp["phrases"] = values?.phrases;
        handlePostPhrases(res?.data?.data?.qid, temp, submitProps);
      })
      .catch((error: any) => {
        if (error?.response?.status === 500) {
          showAlert("Question already exist", "error");

          let url = formatString(`/bot/{0}/setup/bot-knowledge/faqs`, [
            currentBot.bot_id,
          ]);
          navigate(url);
        }
      });
  };

  const handleUpdateBtn = (values: any, submitProps: any, key: string) => {
    if (NewuserInput) {
      // dispatch(updateUserInput({}));
    }
    if (faq_id) {
      let ABC: any = { ...faqIdData };

      const data = { ...values };
      ABC["question" + key] = values?.question;
      // ABC["phrases"] = values?.phrases;
      if (value === 2) {
        if (!values?.journey) {
          submitProps.setFieldError(
            "journey",
            "value required in selected field"
          );
          submitProps?.setSubmitting(false);
          return;
        }

        ABC["journey"] = values?.journey;
        ABC["response"] = null;
      } else {
        ABC["journey"] = null;
        let result: any = [];

        if (value === 1) {
          if (
            !values?.text ||
            values?.text === "<p></p>\n" ||
            values?.text === "<p></p>↵"
          ) {
            submitProps.setFieldError(
              "text",
              "value required in selected field"
            );
            submitProps?.setSubmitting(false);
            return;
          }

          //* add text to response
          let text = {
            type: "text",
            // value: values["text"],
            value: getChannelWiseText(values["text"], currentBot?.channel),
          };
          result?.push(text);

          //* add image to response
          if (
            values?.media &&
            values?.media?.image &&
            values?.media?.image?.url
          ) {
            let image = {
              type: "image",
              value: {
                text: "image",
                url: values?.media?.image?.url,
              },
            };
            result?.push(image);
          }

          //* add video to response
          if (
            values?.media &&
            values?.media?.video &&
            values?.media?.video?.url
          ) {
            let video = {
              type: "video",
              value: {
                text: "video",
                url: values?.media?.video?.url,
              },
            };
            result?.push(video);
          }

          //* add file to response
          if (
            values?.media &&
            values?.file?.image &&
            values?.media?.file?.url
          ) {
            let file = {
              type: "file",
              value: {
                text: "file",
                url: values?.media?.file?.url,
              },
            };
            result?.push(file);
          }
        }
        if (value === 3) {
          //* add live chat btn to response

          let live = {
            type: "live_agent",
            value: {
              flow_type: "live_agent",
              text: "Connect with Agent",
            },
          };
          result?.push(live);
          // }
        }

        ABC["response" + key] = result;
      }
      ABC["languages"] = languageStringArray || [];

      ConversationService.updateFaqQuestion(faq_id, ABC, HEADERS)
        .then((res: any) => {
          showAlert("faq updated", "success");
          submitProps?.setSubmitting(false);
          submitProps?.resetForm();
        })
        .catch((error: any) => {
          showAlert("something went wrong", "error");
        });

      let temp: any = { ...getPhrasesData };
      temp["query"] = faqIdData?.["query"];
      temp["intent"] = faqIdData?.["intent"];
      temp["phrases" + key] = values?.phrases;
      handleUpdatePhrases(faq_id, temp, submitProps);
    }
  };

  const handlePostPhrases = (
    FAQ_ID: string,
    phrasesObj: any,
    submitProps: any
  ) => {
    let uniqueArray = checkForDuplicatesArrayValues(phrasesObj?.phrases);

    let temp = {
      ...phrasesObj,
      phrases: uniqueArray,
      languages: languageStringArray || [],
    };
    ConversationService.postFaqVariations(FAQ_ID, temp, HEADERS)
      .then((res: any) => {
        props.setLoading(true);
        setTimeout(() => {
          props.setLoading(false);
          let url = formatString(
            `/bot/{0}/setup/bot-knowledge/faqs/faq-answer/{1}`,
            [currentBot.bot_id, FAQ_ID]
          );
          navigate(url);
          showAlert("FAQs added to list", "success");
        }, 1000);
        // navigate(URL);
        submitProps?.setSubmitting(false);
        submitProps?.resetForm();
      })
      .catch((error: any) => {});
  };

  const handleUpdatePhrases = (
    faq_id: string,
    phrasesObj: any,
    submitProps: any
  ) => {
    let uniqueArray = checkForDuplicatesArrayValues(phrasesObj?.phrases);

    let temp = {
      ...phrasesObj,
      phrases: uniqueArray,
      languages: languageStringArray || [],
    };
    ConversationService.updateFaqVariations(faq_id, temp, HEADERS)
      .then((res: any) => {
        navigate(URL);
        submitProps.setSubmitting(false);
        submitProps.resetForm();
      })
      .catch((error: any) => {});
  };

  const handleSyncButton = (): void => {
    if (!isLanguageAlreadySynced) {
      props.setLoading(true);
      setTimeout(() => {
        setIsLanguageAlreadySynced(!isLanguageAlreadySynced);
        props.setLoading(false);
        showAlert(
          "our magicians currently cooking on language sync feature",
          "warning"
        );
      }, 2000);
    } else {
      showAlert(
        "our magicians currently cooking on language sync feature",
        "success"
      );
    }
  };

  useEffect(() => {
    if (localValues && localValues?.phrases) {
      setIsLanguageAlreadySynced(false);
    }
  }, [localValues?.phrases]);

  const handleAddNewLanguageBtn = () => {
    let url = formatString("/bot/{0}/setup/languages/", [currentBot.bot_id]);
    navigate(url);
  };
  const handleAutoGenerateBtn = () => {
    if (localValues?.question && localValues?.question?.length > 0) {
      // showAlert("Hold on generating phrases can take some time", "warning");
      props.setLoading(true);
      ConversationService.generateAutomaticPhrases(
        { query: localValues?.question },
        HEADERS
      )
        .then((res: any) => {
          if (
            res?.data?.data?.phrases &&
            res?.data?.data?.phrases?.length > 0 &&
            Array.isArray(res?.data?.data?.phrases)
          ) {
            showAlert("Phrases generation completed", "success");
            setAutomaticPhrasesData(res?.data?.data);
            setUserInput({
              ...localValues,
              phrases: res?.data?.data?.phrases,
            });
          } else {
            showAlert("something went wrong", "error");
          }
          props.setLoading(false);
        })
        .catch((error: any) => {
          showAlert("something went wrong", "error");
          props.setLoading(false);
        });
    } else {
      showAlert("Question is required", "error");
    }
  };

  useEffect(() => {
    if (NewuserInput?.question && !faq_id) {
      if (NewuserInput?.response_or_flow === "Add Response") {
        setValue(1);
      } else {
        setValue(2);
        setSelectedJourney(NewuserInput?.journey);
        // setUserInput((prev: any) => ({
        //   ...prev,
        //   question: NewuserInput?.question,
        //   journey: NewuserInput?.journey?.qid,
        // }));
      }
      setUserInput(NewuserInput);
      setTimeout(() => {
        dispatch(updateUserInput({}));
      }, 5000);
    }
  }, [NewuserInput]);
  return (
    <AppForm
      initialValues={userInput}
      onSubmit={(values: any, submitProps: any) => {
        faq_id
          ? handleUpdateBtn(values, submitProps, selectedLanguageKey)
          : handleCreateBtn(values, submitProps, selectedLanguageKey);
      }}
      validationSchema={FAQDetailsSchema}
    >
      <FaqAnswerWrap>
        <StyledTableHeader>
          <StyledHeaderLeft style={{ paddingLeft: "0rem" }}>
            <StyledTableHeaderTitle
              style={{ fontSize: "1.1rem", gap: "0.2rem" }}
              onClick={() => handleRedirect()}
              isActive={false}
            >
              <ArrowBackIosRoundedIcon style={{ fontSize: "1.1rem" }} />
              FAQ Answer
            </StyledTableHeaderTitle>
          </StyledHeaderLeft>
          <StyledTableHeaderActionButtons>
            {/* {isLanguageAlreadySynced ? (
                <AppButton
                  variant="text"
                  style={{ color: theme.palette.default.success }}
                  onClick={() => handleSyncButton()}
                >
                  <PublishedWithChangesIcon />
                  Languages synced
                </AppButton>
              ) : (
                <AppButton
                  variant="text"
                  style={{ color: theme.palette.default.error }}
                  onClick={() => handleSyncButton()}
                >
                  <SyncDisabledIcon />
                  Sync languages now
                </AppButton>
              )} */}

            {faq_id ? (
              <AppSubmitButton variant="outline" title="Update FAQ" />
            ) : (
              <AppSubmitButton variant="outline" title="Save FAQ" />
            )}
          </StyledTableHeaderActionButtons>
        </StyledTableHeader>

        <StyledBoxWrap>
          <StyledBoxTopbar>
            <StyledAnswerBox
              style={{
                paddingTop: "0.6rem",
                paddingBottom: "1rem",
              }}
            >
              <FaqLiveSearchAutoComplete
                label="Question"
                name="question"
                placeholder="Why hasn't my order arrived yet?"
                sendValuesToParent={(values: any) => sendValuesToParent(values)}
                disabled={
                  faq_id ||
                  (localValues &&
                    localValues?.phrases &&
                    localValues?.phrases?.length > 0)
                    ? true
                    : false
                }
                tooltip={"Question cannot be edited once variation added"}
              />
              <FormControl>
                <RadioGroup value={value} onChange={handleChange}>
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Add Response"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Link to Flow"
                  />
                  {/* <FormControlLabel
                      value={3}
                      control={<Radio />}
                      label="Live Agent (Only 
                        applicable during business hours.)"
                    /> */}
                </RadioGroup>
              </FormControl>

              <StyledResponseBox>
                {value === 1 && (
                  <AppFormRichTextEditor
                    label="Bot Response"
                    placeholder="Start typing..."
                    name="text"
                    mediaKey="media"
                  />
                )}
                {value === 2 && (
                  <AppFormAutoSearchField
                    label="Link to Flow"
                    Options={flowOptions}
                    disabled={false}
                    name="journey"
                    initialValue={selectedJourney}
                  />
                )}
                {value === 3 && null}
              </StyledResponseBox>
            </StyledAnswerBox>
          </StyledBoxTopbar>

          <StyledBoxBottombar
            style={{
              gap: 0,
            }}
          >
            <StyledAnswerBox
              style={{
                padding: "0.3rem 0rem 1rem",
                overflow: "hidden",
              }}
            >
              <StyledVariationBoxTopbar>
                <AppFormLabel
                  tooltip={"Add at least 5 variants to answer better"}
                >
                  Question variations
                </AppFormLabel>

                <div className="actionDiv">
                  {faq_id ? null : (
                    <AppButton
                      variant="text"
                      style={{ minWidth: "130px", width: "25%" }}
                      onClick={() => handleAutoGenerateBtn()}
                      disabled={localValues?.question ? false : true}
                    >
                      Auto Generate
                    </AppButton>
                  )}

                  <AppCircularProgressBar
                    progress={progress}
                    variant="determinate"
                  />
                </div>
              </StyledVariationBoxTopbar>
              <StyledVariationBox
                style={{
                  padding: "0 0 0 1rem",
                  overflowY: "auto",
                }}
              >
                <FaqFieldArray
                  // label="phrases"
                  name="phrases"
                  setDynamicVaritionValue={setDynamicVaritionValue}
                  dynamicVaritionValue={dynamicVaritionValue}
                  setProgress={setProgress}
                  // disabled={selectedLanguageKey && selectedLanguageKey?.length > 1 ? true : false}
                  secondaryFieldNameForError={"question"}
                  // tooltip={"Add Additional variations"}
                  addBtnDisable={
                    selectedLanguageKey && selectedLanguageKey?.length > 1
                      ? true
                      : false
                  }
                />
              </StyledVariationBox>
            </StyledAnswerBox>
          </StyledBoxBottombar>

          {/* <StyledLanguageBox>
              <AppFormToggleButtonField
                name="language"
                Options={languagesOptions}
                label={"Available Language"}
                tooltip={
                  "Languages that have been enabled under chat languages are visible here."
                }
                disabled={faq_id ? false : true}
              />
            </StyledLanguageBox> */}
        </StyledBoxWrap>
      </FaqAnswerWrap>
    </AppForm>
  );
};

export default HocBackdropLoader(FaqDetails);

const FaqAnswerWrap = styled.div`
  && {
    background-color: #f5f6f8;

    width: 100%;
    height: 100%;
    padding: 0.4rem 1rem 1rem;
    overflow: hidden;

    .public-DraftStyleDefault-block {
      font-family: ${theme.typography.fontFamily} !important;
      color: ${theme.palette.default.text} !important;
    }
  }
`;

const StyledBoxWrap = styled.div`
  && {
    background-color: #f5f6f8;
    width: 100%;
    height: 90%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0.5rem;
    overflow: hidden;
    overflow-y: auto;
    ::-webkit-scrollbar {
      /* display: none; */

      width: 0px;
      border-radius: 4px;
    }
  }
`;
const StyledBoxTopbar = styled.div`
  && {
    background-color: #f5f6f8;
    width: 100%;
    width: 35vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const StyledBoxBottombar = styled.div`
  && {
    background-color: #f5f6f8;
    width: 35vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

const StyledAnswerBox = styled.div`
  && {
    display: flex;
    padding: 0.5rem 1rem;
    flex-direction: column;

    gap: 1rem;
    width: 100%;
    height: 100%;
    padding-bottom: 2rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;

    .MuiFormControlLabel-root .MuiFormControlLabel-label {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
const StyledResponseBox = styled.div`
  && {
    display: flex;

    flex-direction: column;

    gap: 0.4rem;
    width: 100%;
    /* max-width: 550px; */
  }
`;
const StyledVariationBox = styled.div`
  && {
    /* background-color: yellow; */
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    gap: 1rem;
  }
`;
const StyledVariationBoxTopbar = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    gap: 0.4rem;
    width: 100%;
    min-height: 48px;
    height: 48px;
    overflow: hidden;
    padding: 0 1rem;
    .actionDiv {
      display: flex;
      align-items: center;
      gap: 1rem;

      font-size: 0.9rem;
      color: ${theme.palette.default.darkGrey};
    }

    .css-3kw1a0-MuiCircularProgress-root {
      width: 32px !important;
      height: 32px !important;
    }
  }
`;

const StyledLanguageBox = styled.div`
  && {
    background-color: #f5f6f8;
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    /* flex-wrap: nowrap; */
    gap: 1.2rem;
    min-width: 200px;
    height: 100%;
    justify-content: space-between;
  }
`;
