import { Box } from "@mui/material";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import { ReactComponent as EmailUniqueImage } from "../../../assets/images/unique-email.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import { ApexChatWrapper } from "./Doughnout";
import { TextTitle, UniqueEmailWrapper, WrapEmail } from "./EmailBroadcastSummaryStyles";
interface Props {
  uniqueEmail?: any;
  loading?:boolean;
}

const UniqueEmail: React.FC<Props> = ({ uniqueEmail ,loading}) => {

  return (
    loading ? <AppSkeletonLoader/> : <Box height={"inherit"}>
     
            <WrapEmail>
              <UniqueEmailWrapper>
                <Box display={"flex"} alignItems={"center"}>
                  <ApexChatWrapper>
                    <Box className="heading__tooltip">
                      <TextTitle>
                        <p className="main_header">Unique Email</p>
                      </TextTitle>
                      <AppToolTip
                        tooltipProps={{
                          title:
                            "Distinct email addresses, counted once regardless of multiple occurrences.",
                          placement: "top",
                        }}
                        iconButtonProps={{ disableRipple: true }}
                        IconComponent={
                          <AppMaterialIcons
                            iconName="HelpOutline"
                            style={{ fontSize: "16px" }}
                          />
                        }
                      />
                    </Box>
                  </ApexChatWrapper>
                </Box>
                {
                  (uniqueEmail ?? 0) === 0 ? (
                    <NoRecordsFound
                      imageWidth={"200px"}
                      headerTextSize={"19px"}
                      imageSrc={NoRecordImage}
                      bgColor="transparent"
                      height="calc(100% - 61px)"
                    />
                  ) : (
                    <Box
                      className="body"

                    >
                      <Box className="content">
                        <Box display={"flex"} justifyContent={"center"} mb={"6px"}>
                          <EmailUniqueImage />
                        </Box>
                        <Box>
                          <p className="header">Total Unique Email </p>
                          <p className="value">{uniqueEmail || 0}</p>
                        </Box>

                      </Box>
                    </Box> 
                  )
                }
                

                
              </UniqueEmailWrapper>
            </WrapEmail>
       
    </Box> 
  );
};

export default UniqueEmail;
