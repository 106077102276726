import { Route, Routes } from "react-router-dom";
import BotBuilder from "../Components/BotBuilder/BotBuilder";
import EmailNotifications from "../Components/BotBuilder/BotSettings/Alerts & Notifications/EmailNotifications";
import PushNotifications from "../Components/BotBuilder/BotSettings/Alerts & Notifications/PushNotifications";
import SmsNotifications from "../Components/BotBuilder/BotSettings/Alerts & Notifications/SmsNotifications";
import BotSettings from "../Components/BotBuilder/BotSettings/BotSettings";
import AutoReplies from "../Components/BotBuilder/BotSettings/Conversations/AutoReplies/AutoReplies";
import BlacklistUrl from "../Components/BotBuilder/BotSettings/Conversations/Blacklist/BlacklistUrl";
import EnabledDevices from "../Components/BotBuilder/BotSettings/Conversations/EnabledDevices/EnabledDevices";
import ChatLanguages from "../Components/BotBuilder/ChatLanguages/ChatLanguages";
import PopoutMessage from "../Components/BotBuilder/Customize/Appearence/Callout/PopoutMessage";
import LookFeel from "../Components/BotBuilder/Customize/Appearence/LookFeel";
import NotificationsPreferences from "../Components/BotBuilder/Customize/Appearence/Notifications/NotificationsPreferences";
import BannerCarousel from "../Components/BotBuilder/Customize/Conversation/Banner/BannerCarousel";
import NotificationBlock from "../Components/BotBuilder/Customize/Conversation/NotificationBlock/NotificationBlock";
import QuickMenu from "../Components/BotBuilder/Customize/Conversation/QuickMenu/QuickMenu";
import Customize from "../Components/BotBuilder/Customize/Customize";
import DeploySettings from "../Components/BotBuilder/DeploySettings/DeploySettings";
import BotKnowledge from "../Components/BotBuilder/BotKnowledge/BotKnowledge";
import FaqIndex from "../Components/BotBuilder/BotKnowledge/Faq/FaqIndex";
import NotAnsweredIndex from "../Components/BotBuilder/BotKnowledge/NotAnswered/NotAnsweredIndex";
import SmartAi from "../Components/BotBuilder/BotKnowledge/SmartAi/SmartAi";
import DeployScripts from "../Components/BotBuilder/DeploySettings/Scripts";
import FAQs from "../Components/FAQ";
import FaqDetails from "../Components/FAQ/FaqDetails";
import ClientLayout from "../Components/Layouts/ClientLayout";
// import Contacts from "../Components/Settings/ContactModule/Contact";
import FlowBuilderV2 from "../Modules/FlowBuilder";

import FaqPdf from "../Components/BotBuilder/BotKnowledge/Faq/Pdf/FaqPdf";
import FaqSitemap from "../Components/BotBuilder/BotKnowledge/Faq/Sitemap/FaqSitemap";
import FaqText from "../Components/BotBuilder/BotKnowledge/Faq/Text/FaqText";
import FaqWebsite from "../Components/BotBuilder/BotKnowledge/Faq/Website/FaqWebsite";
import AnsweredFaq from "../Components/BotBuilder/BotKnowledge/NotAnswered/FaqAnswered/AnsweredFaq";
import NotAnswered from "../Components/FAQ/NotAnswered";

import BlankLayout from "../Components/Layouts/BlankLayout";
import RouteGuard from "./RouteGuard";
import FreshStartPage from "../Components/MyChatbots/FreshStartPage";
import AllChatbots from "../Components/MyChatbots/AllChatbots";
import CreateBotForm from "../Components/MyChatbots/CreateBots/CreateBotForm";
import CreateWorkspace from "../Components/Workspace/CreateWorkspace";
import App404 from "../AppComponents/App404";
import React from "react";
import LinearLoader from "../AppComponents/Loader/LinearLoader";
import FaqBuilder from "../Components/FAQ/FaqBuilder";
import LinkAndQRCode from "../Components/BotBuilder/Customize/Appearence/LinkAndQRCode/LinkAndQRCode";
import OptIn from "../Components/BotBuilder/BotSettings/Conversations/Opt-In/OptIn";
import OptOut from "../Components/BotBuilder/BotSettings/Conversations/Opt-Out/OptOut";
import WelcomeMessage from "../Components/BotBuilder/Customize/Appearence/WelcomeMessage";
import PersistantMenu from "../Components/BotBuilder/Customize/Appearence/PersistantMenu";
import LandingPageBot from "../Components/BotBuilder/DeploySettings/LandingPageBot/LandingPageBot";

// const BotBuilder = React.lazy(
//   () => import("../Components/BotBuilder/BotBuilder")
// );

const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ClientLayout />}>
        <Route
          path="/workspace"
          element={
            <RouteGuard moduleId="bot_builder" element={<BlankLayout />} />
          }
        >
          <Route path="" element={<FreshStartPage />} />

          <Route path="create" element={<CreateWorkspace />} />

          <Route path=":workspace_id" element={<FreshStartPage />} />
          <Route path=":workspace_id/setup/bots" element={<AllChatbots />} />
          <Route
            path=":workspace_id/setup/create-bot"
            element={
              <RouteGuard moduleId="create_bot" element={<CreateBotForm />} />
            }
          />
          <Route
            path=":workspace_id/setup/edit-bot/:bot_id"
            element={
              <RouteGuard moduleId="manage_bot" element={<CreateBotForm />} />
            }
          />
          <Route
            path=":workspace_id/setup/view-bot/:bot_id"
            element={
              <RouteGuard moduleId="manage_bot" element={<CreateBotForm />} />
            }
          />
        </Route>

        <Route
          path="/bot/:bot_id/setup"
          element={
            <RouteGuard
              moduleId="manage_bot"
              // element={
              //   <React.Suspense fallback={<LinearLoader />}>
              //     <BotBuilder />
              //   </React.Suspense>
              // }
              element={<BotBuilder />}
            />
          }
        >
          <Route
            path="customize"
            element={
              <RouteGuard moduleId="customize" element={<Customize />} />
            }
          >
            <Route path="look-feel" element={<LookFeel />} />
            <Route path="welcome-message" element={<WelcomeMessage />} />
            <Route path="persistant-menu" element={<PersistantMenu />} />
            <Route path="popout-message" element={<PopoutMessage />} />
            <Route path="link-qrcode" element={<LinkAndQRCode />} />
            <Route
              path="notifications"
              element={<NotificationsPreferences />}
            />
            <Route path="notification-block" element={<NotificationBlock />} />
            <Route path="quick-menu" element={<QuickMenu />} />
            <Route path="banner" element={<BannerCarousel />} />
          </Route>

          <Route
            path="flow"
            element={
              <RouteGuard moduleId="flow_builder" element={<BlankLayout />} />
            }
          >
            <Route path="" element={<FlowBuilderV2 />} />
            <Route path=":flow_id" element={<FlowBuilderV2 />} />
          </Route>

          <Route
            path="bot-knowledge"
            element={
              <RouteGuard
                moduleId="manage_bot_knowledgebase"
                element={<BotKnowledge />}
              />
            }
          >
            <Route
              path="sources"
              element={
                <RouteGuard moduleId="smart_ai" element={<FaqIndex />} />
              }
            >
              <Route path="website" element={<FaqWebsite />} />
              <Route path="sitemap" element={<FaqSitemap />} />
              <Route path="document" element={<FaqPdf />} />
              <Route path="text" element={<FaqText />} />
            </Route>

            <Route path="user-queries" element={<NotAnsweredIndex />}>
              <Route path="not-answered" element={<NotAnswered />} />
              <Route path="answered" element={<AnsweredFaq />} />
            </Route>
            <Route path="faqs" element={<BlankLayout />}>
              <Route index path="" element={<FaqBuilder />} />
              <Route path="faq-answer" element={<FaqDetails />} />
              <Route path="faq-answer/:faq_id" element={<FaqDetails />} />
            </Route>

            <Route path="smart-ai" element={<SmartAi />} />
          </Route>

          <Route
            path="languages"
            element={
              <RouteGuard moduleId="multilingual" element={<BlankLayout />} />
            }
          >
            <Route path="" element={<ChatLanguages />} />
          </Route>

          <Route
            path="configure"
            element={
              <RouteGuard moduleId="bot_settings" element={<BotSettings />} />
            }
          >
            <Route path="blacklist-urls" element={<BlacklistUrl />} />
            <Route path="enabled-devices" element={<EnabledDevices />} />
            <Route path="auto-replies" element={<AutoReplies />} />
            <Route path="opt-in" element={<OptIn />} />
            <Route path="opt-out" element={<OptOut />} />
            <Route
              path="email-notifications"
              element={<EmailNotifications />}
            />
            <Route path="sms-notifications" element={<SmsNotifications />} />
            <Route path="push-notifications" element={<PushNotifications />} />
          </Route>

          <Route
            path="deploy"
            element={
              <RouteGuard moduleId="deploy_bot" element={<DeploySettings />} />
            }
          >
            <Route path="website" element={<DeployScripts />} />
            <Route path="landing-page-bot" element={<LandingPageBot />} />
          </Route>
        </Route>

        <Route path="/404" element={<App404 />} />
      </Route>
    </Routes>
  );
};

export default ClientRoutes;
