import React, { useState } from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Typography, styled } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
    AccordionSummaryProps
} from "@mui/material/AccordionSummary";

const AppAccordion = (props: any) => {
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const Accordion = styled((props: AccordionProps) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        backgroundColor: "white",
        border: "none",
        borderBottom: "none"
    }));
    const AccordionSummary = styled((props: AccordionSummaryProps) => (
        <MuiAccordionSummary
            expandIcon={
                <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "#5B73E8" }} />
            }
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255, 255, 255, .05)"
                : "rgba(0, 0, 0, .03)",
        flexDirection: "row-reverse",
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)"
        },
        "& .MuiAccordionSummary-content": {
            marginLeft: theme.spacing(1)
        }
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2)
    }));
    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(panel);
        };
    return (
        <React.Fragment>
            {props?.data?.map((res: any, index: number) => {
                return (
                    <Accordion
                        expanded={expanded === res?.defaultStatus}
                        onChange={handleChange(res?.defaultStatus)}
                        key={index}
                    >
                        <AccordionSummary
                            style={{ backgroundColor: "white" }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                        >
                            <Typography
                                style={{
                                    backgroundColor: "white",
                                    border: "none",
                                    fontSize: "16px",
                                    fontWeight: 600
                                }}
                            >
                                {res?.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography style={{ marginLeft: "4%", color: "#7E8392", fontSize: "14px" }}>
                                {res?.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </React.Fragment>
    )
}

export default AppAccordion