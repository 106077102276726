import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { Menu } from "@mui/material";

export const TemplateListWrapper = styled.div`
  && {
    width: 100%;
    height: calc(100% - 64px);
    // padding: 1rem;
  }
`;

export const TemplateTable = styled.div`
  && {
    .table-wrapper {
      .tabel-heading {
        h6 {
          font-size: 1rem;
          font-weight: 500;
          font-family: ${theme.typography.fontFamily};
        }
      }

      .pending {
        color: ${theme.palette.default.text};
        font-weight: 600;
      }
      .rejected {
        color: ${theme.palette.default.error};
        font-weight: 600;
      }
      .success {
        color: ${theme.palette.default.success};
        font-weight: 600;
      }
      .align-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .table-head {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const TemplateTableFixedFirstCol = styled.div`
  position: relative;
  overflow: auto;
  height: calc(100vh - 234px);
  background-color: #fff;
  border: 1px solid ${theme.palette.default.border};
  && {
    .table {
      display: flex;
      width: 100%;
      border-collapse: collapse;
    }

    .tbl-heading {
      font-weight: 500;
      font-size: 13px;
    }

    .tbl-heading__two {
      color: ${theme.palette.default.darkGrey};
    }

    .column {
      min-width: 200px;
      padding: 10px;
      font-size: 13px;
      border-bottom: 1px solid rgba(203, 205, 211, 0.5);
      background-color: #fff;
    }

    .data-column {
      font-size: 13px !important;
      min-width: 200px;
      border-bottom: 1px solid rgba(203, 205, 211, 0.5);
      padding: 10px;
      background-color: #fff;
    }

    .success {
      color: ${theme.palette.default.success};
      font-weight: 400;
    }

    .pending {
      color: ${theme.palette.default.warning};
      font-weight: 400;
    }

    .rejected {
      color: ${theme.palette.default.error};
      font-weight: 400;
    }
    .tooltip {
      .MuiButtonBase-root {
        padding: 0 !important;
      }
    }

    .column:first-child,
    .column:nth-child(2) {
      min-width: 80px;
      position: sticky;
      left: 0;
      z-index: 1;  /* Adjust the z-index to make it appear above other columns */
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(203, 205, 211, 0.5);
      background-color: #fff;
    }

    .column:nth-child(2) {
      min-width: 330px;
      left: 80px;
      border-right: 1px solid rgba(203, 205, 211, 0.5);
    }
    
    .data-column:nth-child(3) {
      min-width: 360px;
    }
    .column:nth-child(3) {
      min-width: 360px;
    }
    .data-column:nth-child(8) {
      min-width: 340px;
    }

    .column:nth-child(8) {
      min-width: 340px;
    }

    .table-container {
      overflow-x: auto;
      // margin-left: 410px;
      overflow-y: hidden;
      form {
        gap: 0rem !important;
      }
    }
  }
`;



export const ErrorComponent = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    height: calc(100% - 48px);
    overflow: auto;
    /* height: 60vh; */
    /* margin-top: 2rem; */
    img {
      max-width: 100%;
      height: auto;
    }
    .error-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .err-content {
      gap: 1rem;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .error-Info {
      margin-top: 8px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      h6 {
        color: ${theme.palette.default.black};
      }
    }
    .err-action {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      p {
        color: ${theme.palette.default.darkGrey};
      }
    }
  }
`;

export const SmsMenuFilter = styled(Menu)`
  && {
    width: 400px;
    & .MuiMenu-list {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    }

    & .MuiMenuItem-root {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-weight: 400;
        margin-bottom: 0.4rem;
        font-size: 0.875rem;
        color: ${theme.palette.default.black};
      }
    }
  }
`;

export const SmsTag = styled.div`
  && {
    display: inline-block;

    margin-right: 0.4rem;
    margin-bottom: 0.5rem;
    font-size: 0.75rem;

    background-color: ${theme.palette.default.antiFlashWhite};
    border: 1px solid rgba(126, 131, 146, 0.5);
    color: ${theme.palette.default.darkGrey};
    border-radius: 25px;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.8rem;
    }
    .text {
      /* padding-top: 0.2rem; */
      /* padding-bottom: 0.2rem; */
      padding-right: 0.2rem;
      padding-left: 0.4rem;
      display: flex;
      align-items: center;
    }
    .close {
      display: flex;
      align-items: center;
      border-top-right-radius: 25px;
      border-bottom-right-radius: 25px;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      padding-left: 0.3rem;
      padding-right: 0.4rem;
      cursor: pointer;
    }

    .close:hover {
      background-color: ${theme.palette.default.blue};
      & .MuiSvgIcon-root {
        color: #fff;
      }
    }

    & .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
`;

export const TagListToolTip = styled.div`
  && {
    width: 240px;
    li {
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem;
      color: ${theme.palette.default.darkGrey};
    }

    & .MuiSvgIcon-root {
      font-size: 1rem;
    }
  }
`;

export const SmsTagNumber = styled.div`
  && {
    background-color: ${theme.palette.default.antiFlashWhite};
    border: 1px solid rgba(126, 131, 146, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    color: #000;
    font-size: 0.75rem;
  }
`;
