import { getIn, useFormikContext } from "formik";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import {
  AppFormFastField,
  AppFormField,
  AppFormSelectField,
  AppFormTextArea
} from "../../../../../AppComponents/Forms";
import { LoaderComponentProps } from "../../../../../AppComponents/Loader";
import { MultipleFieldsRow } from "../../../../../Customization/CommonElements";
import { HocBackdropLoader } from "../../../../../HOC/HocBackdropLoader";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { marketingListActions } from "../../../../../Store/Slices/MarketingList/marketingList.actions";
import { SMSActions } from "../../../../../Store/Slices/SMS/sms.actions";
import { SMSDataStore } from "../../../../../Store/Slices/SMS/sms.selectors";
interface Props extends LoaderComponentProps {
  formStep: number;
  isView: boolean;

  setFormStep: Dispatch<SetStateAction<number>>;
}

const RenderFormStep1: React.FC<Props> = ({ setLoading, formStep, isView }) => {
  const dispatch = useAppDispatch();
  const { smsTemplateListData, templateByIdData }: any = useAppSelector(SMSDataStore);
  const { values, setFieldValue, setValues } = useFormikContext<any>();
  const { showAlert } = useNotification();
  const formkey1 = "broadcast_name";
  const formkey2 = "broadcast_template_id";
  const formkey3 = "sender_id";
  const formkey4 = "template_type";
  const formkey5 = "body_preview";
  useEffect(() => {
    if (getIn(values, formkey2)) {
      setLoading(true);
      dispatch(
        SMSActions.getTemplateById(getIn(values, formkey2))
      )
        .then((res: any) => {
        })
        .catch((err: any) => {
          showAlert("Something went wrong", "success");
        })
        .finally(() => {
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
    }
  }, [getIn(values, formkey2)]); // eslint-disable-line react-hooks/exhaustive-deps


  const changeTemplate = (e: any) => {
    setValues({
      ...values,
      "broadcast_template_id": e,
      "resolved_header_variables": [],
      "resolved_body_variables": [],
      "resolved_buttons_variables": []
    })

    setValues({ ...values, [formkey2]: e });
    setFieldValue(formkey3, "");
  }


  useEffect(() => {
    if (getIn(values, formkey2)) {
      setFieldValue(formkey4, templateByIdData?.template_type);
      setFieldValue(formkey5, templateByIdData?.body_value);
    }
  }, [templateByIdData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(marketingListActions.resetFieldsTypeMappingList({}));
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeFn = (value: any) => { };
  if (formStep !== 1 || !smsTemplateListData?.length) {
    return null;
  }
  return (
    <React.Fragment>
      <AppFormFastField
        name={formkey1}
        label="SMS Broadcast Name"
        placeholder="Broadcast 1"
        disabled={isView ? true : false}
        require={true}
      />
      <AppFormSelectField
        name={formkey2}
        label="Select DLT Template"
        Options={smsTemplateListData?.filter(
          (ele: any) => ele?.status === "APPROVED"
        )}
        divStyle={{ maxWidth: "1000px" }}
        defaultText="Select a broadcast DLT template"
        isDisabled={isView ? true : false}
        enableSearch={true}
        onChange={changeTemplate}
        requiredSign={true}
      />

      <MultipleFieldsRow>
        <AppFormSelectField
          name={formkey3}
          label="Sender ID"
          Options={templateByIdData?.sender_ids || []}
          divStyle={{ maxWidth: "1000px" }}
          defaultText="Select a Sender ID"
          onChangeCallbackFn={onChangeFn}
          enableSearch={true}
          isDisabled={isView ? true : false}
          requiredSign={true}
        />
        <AppFormField
          name={formkey4}
          label="Template Type"
          placeholder="Enter Template Type"
          disabled={true}
        />
      </MultipleFieldsRow>

      <AppFormTextArea
        name={formkey5}
        label="Message Template Preview"
        placeholder="Template body"
        rows={5}
        disabled={true}
      />
    </React.Fragment>
  );
};

export default HocBackdropLoader(RenderFormStep1);
