import React, { memo } from "react";
import { MESSAGE_TYPES } from "../../utils/Enum";
import {
  StyledMessageReplyRenderBox,
  StyledRepliedBox,
} from "./../../Styles/StyledComponents";
import { getFileNameFromUrl } from "../../utils/utils";
import { AppMaterialIcons } from "../Icons";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface Props {
  userMessage?: boolean;
  replyToType: string;
  replyToValue: string;
  replyToAvatar: string;
  children?: any;
}
const AppRenderRepliedMessage: React.FC<Props> = React.memo(
  ({
    userMessage = false,
    replyToType,
    replyToAvatar,
    replyToValue,
    children,
    ...otherProps
  }) => {
    const isReplyAttached =
      replyToType && replyToValue && replyToAvatar ? true : false;

    if (isReplyAttached) {
      return (
        <StyledMessageReplyRenderBox userMessage={userMessage}>
          <StyledRepliedBox>
            <div className="title">{replyToAvatar} </div>
            <div className="replyItemWrapper">
              {replyToType === MESSAGE_TYPES.IMAGE ? (
                <img
                  src={replyToValue}
                  alt={"replied "}
                  className="renderImage"
                  onError={(error: any) => {
                    error.target.src = FallbackImage;
                  }}
                />
              ) : null}
              {replyToType === MESSAGE_TYPES.VIDEO ? (
                <AppMaterialIcons
                  iconName="Movie"
                />
              ) : null}
              {replyToType === MESSAGE_TYPES.CAROUSEL ? (
                <AppMaterialIcons
                  iconName="ViewCarousel"
                />
              ) : null}
              {replyToType === MESSAGE_TYPES.FILE ||
              replyToType === MESSAGE_TYPES.DOCUMENT ? (
                <AppMaterialIcons
                  iconName="Description"
                />
              ) : null}
              {replyToType === MESSAGE_TYPES.AUDIO ? (
                <AppMaterialIcons
                  iconName="Audiotrack"
                />
              ) : null}

              <div
                className="replyItem"
                dangerouslySetInnerHTML={{
                  __html:
                    replyToType !== MESSAGE_TYPES.TEXT
                      ? getFileNameFromUrl(replyToValue)
                      : replyToValue ?? "",
                }}
              />
            </div>
          </StyledRepliedBox>
          {children}
        </StyledMessageReplyRenderBox>
      );
    }

    return children;
  }
);

export default memo(AppRenderRepliedMessage);
