// actions
import ConnectFlow from "./actions/connect-flow";
import LiveAgent from "./actions/live-agent";

export const registerActions = [
  {
    icon: "nodes",
    type: "flow",
    color: "#B8C4FF",
    title: "Connect flow",
    displayComponent: ConnectFlow,
    supports: ["Web Bot", "whatsapp", "messenger"],
  },
  {
    icon: "live-agent",
    type: "live_agent",
    color: "#FFD1AB",
    title: "Live Agent",
    displayComponent: LiveAgent,
    supports: ["Web Bot", "whatsapp", "messenger"],
  },
  // {
  //   icon: "faq-icon",
  //   type: "transfer_to_faqs",
  //   color: "#C8EF87",
  //   title: "Transfer to FAQs",
  //   displayComponent: TransferToFAQs,
  // },
];
