import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Popover, Typography } from "@mui/material"
import NavigationBar from "./NavigationBar"
import { AmountCard, BillingDetailsCard, Button, DetailsCard, LeftDay, LivePlanContainer, SubscriptionDetails, SubscriptionPlan, Title, TotalCost } from "./style"
import { ReactComponent as Addons } from "../../assets/images/icons/addons.svg";
import { ReactComponent as TotalAmount } from "../../assets/images/icons/totalAmount.svg";
import { ReactComponent as PaymentMethod } from "../../assets/images/icons/paymentMethod.svg";
import { ReactComponent as BillingFrequency } from "../../assets/images/icons/billingFrequency.svg";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../Store";
import { SubscriptionActions } from "../../Store/Slices/Subscriptions/subscriptions.actions";
import { getAddonListData, getSubscriptionData } from "../../Store/Slices/Subscriptions/subscriptions.selectors";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { setPlanData, setUpdateAddonData } from "../../Store/Slices/Subscriptions/subscriptions.slice";
import AppButton from "../../AppComponents/AppButton";
import styled from "styled-components";
import { SubscriptionsNetworkService } from "../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../../HOC/HocBackdropLoader";
import { BroadcastInfo, BroadcastText, StyledWalletDialog, actionIson } from "../Menu/MenuStyle";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CloseIcon from '@mui/icons-material/Close';

interface MenuProps extends HocBackdropLoaderProps {
}
// Subscription Deatisl Page
const PlanDetails: React.FC<MenuProps> = ({
    setLoading,
}) => {
    const [anchorEl, setAnchorEl] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const getActiveSubscriptionData = useSelector(getSubscriptionData);
    const addonListData = useSelector(getAddonListData);
    const { data: authData } = useSelector(useAuthStore);
    const [check, setCheck] = useState(false)
    const { userConfig } = useSelector(useAuthStore);
    const navigate = useNavigate()
    const { showAlert } = useNotification()
    // get Subscription details
    useEffect(() => {
        dispatch(setPlanData({}))
        dispatch(SubscriptionActions.getSubscriptionData(userConfig?.payload?.subscription_id))
    }, [])

    // Set Addon Data
    useEffect(() => {
        if (addonListData) {
            dispatch(setUpdateAddonData(addonListData))
        }
    }, [addonListData])
    // Subscription Details
    const billingDetails = [
        {
            title: "Activation Date:",
            date: moment(getActiveSubscriptionData?.trial_expiry_date ? authData?.data?.business?.created_time : getActiveSubscriptionData?.activation_date).format("DD/MM/yyyy")
        },
        {
            title: "Previous billing Date:",
            date: getActiveSubscriptionData?.name === "Free" ? "---" : getActiveSubscriptionData?.last_billing_date ? moment(getActiveSubscriptionData?.last_billing_date).format("DD/MM/yyyy") : "---"
        },
        {
            title: "Expiration Date:",
            date: getActiveSubscriptionData?.name === "Free" ? "---" : moment(getActiveSubscriptionData?.next_billing_date).format("DD/MM/yyyy")
        }
    ]
    // Subscription Amount Details
    const SubscriptionPlanDetails = [
        {
            title: "Item",
            date: "Active Plan: " + getActiveSubscriptionData?.name + " " + getActiveSubscriptionData?.interval_unit
        },
        {
            title: "Quantity",
            date: getActiveSubscriptionData?.quantity
        },
        {
            title: "Discount",
            date: "-"
        },
        {
            title: "Rate",
            date: "₹ " + getActiveSubscriptionData?.sub_total
        },
        {
            title: "Tax",
            date: "₹ " + (getActiveSubscriptionData?.amount - getActiveSubscriptionData?.sub_total || 0).toFixed(2)
        },
        {
            title: "Amount",
            date: "₹ " + getActiveSubscriptionData?.amount
        }
    ]

    useEffect(() => {
    }, [getActiveSubscriptionData?.addon])

    const planDetails = [
        {
            "icon": <TotalAmount />,
            "name": "Total Amount",
            "sibTitle": "₹ " + getActiveSubscriptionData?.amount,
            "footer": "",
            "addonsDetails": []
        },
        // {
        //     "icon": <Addons />,
        //     "name": "Addons",
        //     "sibTitle": "",
        //     "footer": "",
        //     "addonsDetails": getActiveSubscriptionData?.addon
        // },
        {
            "icon": <PaymentMethod />,
            "name": "Associated Payment Method",
            "sibTitle": getActiveSubscriptionData?.associated_payment_method,
            "footer": "",
            "addonsDetails": []
        },
        {
            "icon": <BillingFrequency />,
            "name": "Billing Frequency",
            "sibTitle": getActiveSubscriptionData?.name === "Free" ? "---" : getActiveSubscriptionData?.interval_unit,
            "footer": "",
            "addonsDetails": []
        }
    ]
    // const goToPlanDeatilsPage = () => {
    //     dispatch(setPlanData({}))
    //     if (getActiveSubscriptionData?.name === "Free") {
    //         navigate("/broadcast/subscription/subscription-plans/plans", { replace: true })
    //     } else {
    //         navigate("manage-subscription")
    //     }
    // }

    // Cancle Subscription API  
    const cancelSubscripation = () => {
        setLoading(true)
        setCheck(false)
        setAnchorEl(false)
        const data = {
            "customer_id": userConfig?.payload?.customer_id,
            "business_id": userConfig?.payload?.business_id
        }
        SubscriptionsNetworkService.cancleSubscripation(
            getActiveSubscriptionData?.subscription_id,
            data
        )
            .then((res: any) => {
                if (res?.data?.message) {
                    setLoading(false)
                    showAlert(res?.data?.message, "success");
                    // navigate("/subscription/subscription-plans/", { replace: true })
                    // window.location.reload()
                }
            })
            .catch((err: any) => {
            })
            .finally(() => {
            });
    }
    return (<>
        <NavigationBar list={["Subscription plan", "Plan Details"]} />

        <SubscriptionPlan padding={"21px"} width={"-webkit-fill-available"}>
            <Title fontSize={16}>Active Plan: {getActiveSubscriptionData?.name}</Title>
            <LivePlanContainer align={"flex-end"} gap={"8px"} style={{ margin: "-2rem 0rem 2rem" }} >
                {/* <Button
                    variant="outline"
                    minWidth={"170px"}
                    onClick={() => { goToPlanDeatilsPage()}}
                >
                    Manage Subscription
                </Button> */}
                {getActiveSubscriptionData?.name !== "Free" ? <Button
                    variant="outline"
                    minWidth={"0px"}
                    onClick={() => setAnchorEl(true)}
                >
                    <IconButton color="inherit">
                        <AppMaterialIcons iconName="MoreVert" />
                    </IconButton>
                </Button> : null}
                <Popover
                    open={anchorEl}
                    className="ignore-onClickOutside"
                    onClose={() => setAnchorEl(false)}
                    // onClick={() => setCheck(!check)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    sx={{ top: "15rem", left: "76rem" }}
                    PaperProps={{
                        sx: {
                            top: "-2rem !important",
                            right: "0rem !important",
                            left: "auto !important",
                            borderRadius: "8px",
                            margin: "-0.5rem 3rem !important",
                            textAlign: "-webkit-right !important",
                            boxShadow:
                                "0px 13.9892px 69.9461px rgba(126, 131, 146, 0.24), 0px 2px 4px rgba(0, 0, 0, 0.08);",
                        },
                    }}
                >
                    <Typography onClick={() => setCheck(!check)} sx={{ p: 2, cursor: "pointer" }}>Cancel Subscription.</Typography>
                </Popover>
            </LivePlanContainer>
            <LivePlanContainer gap={"30px"} align={"a"}>
                {planDetails.map((res: any) => {
                    return (
                        <AmountCard>
                            <Grid container spacing={2} style={{ margin: "1.5rem 5rem 1rem 0rem" }}>
                                <Grid item xs={res?.addonsDetails?.length === 0 ? 12 : 6} style={{ borderRight: res?.addonsDetails?.length !== 0 ? "1px solid rgba(126, 131, 146, 0.40)" : "", padding: "0rem 1rem", textAlign: "center", height: "190px" }}>
                                    <IconButton color="inherit">
                                        {res?.icon}
                                    </IconButton>
                                    <SubscriptionDetails style={{ color: "#7E8392" }}>{res.name}</SubscriptionDetails>
                                    <Title fontSize={18}>{res.sibTitle ? res.sibTitle : "---"}</Title>
                                    <LeftDay>{res.footer ? res.footer : ""}</LeftDay>

                                </Grid>
                                {res?.addonsDetails?.length !== 0 ? <Grid item xs={6} style={{ width: "333px", padding: "0px" }}>
                                    {res?.addonsDetails?.map((res: any, index:number) => {
                                        return (
                                            <div style={{ margin: "12px" }} key={index}>
                                                <Title fontSize={16}>{res.name + " : " + res.quantity}</Title>
                                                <br />
                                            </div>
                                        )
                                    })}
                                </Grid> : null}
                            </Grid>
                        </AmountCard>
                    )
                })}
            </LivePlanContainer>
            <BillingDetailsCard>
                <div style={{ margin: "1rem 2rem 0.5rem" }}>
                    <Title fontSize={16}>{"Billing details:"}</Title>
                </div>
                <div style={{ display: "flex" }}>
                    {billingDetails?.map((res: any) => {
                        return (
                            <DetailsCard>
                                <Title color={"#7E8392"}>{res?.title}</Title> <br />
                                <Title fontSize={14}>{res.date}</Title>
                            </DetailsCard>
                        )
                    })}
                </div>
            </BillingDetailsCard>
            <BillingDetailsCard height={"170px"}>
                <div style={{ margin: "1rem 2rem 0.5rem" }}>
                    <Title fontSize={16}>{"Subscription Details:"}</Title>
                </div>
                <div style={{ display: "flex" }}>
                    {SubscriptionPlanDetails?.map((res: any,index:number) => {
                        return (
                            <DetailsCard height={"23px"} key={index}>
                                <Title color={"#7E8392"}>{res?.title}</Title> <br />  <br />
                                <Title fontSize={14}>{res.date}</Title>
                            </DetailsCard>
                        )
                    })}
                </div>
            </BillingDetailsCard>
            {/* <BillingDetailsCard height={"170px"}>
                <div style={{ margin: "1rem 2rem 0.5rem" }}>
                    <Title fontSize={16}>{"Addons:"}</Title>
                </div>
                <div style={{ display: "flex" }}>
                    {SubscriptionAddonPlanDetails?.map((res: any) => {
                        return (
                            <DetailsCard height ={"23px"}>
                                <Title color={"#7E8392"}>{res?.title}</Title> <br />  <br />
                                <Title fontSize={14}>{res.date}</Title>
                            </DetailsCard>
                        )
                    })}
                </div>
            </BillingDetailsCard> */}
            {/* <TotalCost>Total : ₹ {getActiveSubscriptionData?.total_amount}</TotalCost> */}

            {/* Cancel Subscription Popover */}
            <StyledWalletDialog
                open={check}
                onClose={() => setCheck(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Are you sure you want to cancel your current subscription?
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setCheck(false); setAnchorEl(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle className="walletTitle" sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    <BroadcastText>
                        Term And Conditions
                    </BroadcastText>
                    <div className="walletCondition">
                        <BroadcastInfo>
                            <FiberManualRecordIcon style={actionIson} /> If you proceed with cancellation, your access to  {getActiveSubscriptionData?.name} will expire at the end of your current billing cycle.
                        </BroadcastInfo>
                        <BroadcastInfo >
                            <FiberManualRecordIcon style={actionIson} /> We do not offer refunds for the remaining period of your current billing cycle.
                        </BroadcastInfo>
                        <BroadcastInfo >
                            <FiberManualRecordIcon style={actionIson} /> You can reactivate your subscription at any time by visiting your account settings.
                        </BroadcastInfo>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{width: "50rem"}}>
                        By clicking "Cancel Subscription," you acknowledge and accept the terms of cancellation, and your subscription will be deactivated at the end of the current billing cycle.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <AppButton
                        onClick={() => { cancelSubscripation() }}
                        style={{margin : "10px 8px"}}
                    >
                        Cancel Subscription
                    </AppButton>
                </DialogActions>

            </StyledWalletDialog>
        </SubscriptionPlan>
    </>
    )
}
const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    max-width: 38% !important;
  }
`;
export default HocBackdropLoader(PlanDetails)