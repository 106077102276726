import AppMarketingList from "../../../../../AppComponents/Forms/AppMarketingList";
import { useAppSelector } from "../../../../../Store";
import {
  whatsappDataStore
} from "../../../../../Store/Slices/Whatsapp/whatsapp.selectors";
const RenderFormStep2 = (prop: any) => {
  const { postWhatsappBroadcastData } = useAppSelector(whatsappDataStore);
  return (
    <AppMarketingList type="whatsapp" subscriptionName="whatsapp_subscription" broadcast_id={postWhatsappBroadcastData} />
  )
}

export default RenderFormStep2;
