import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { Accordion, ListItem } from "@mui/material";

export const LiveChatSidebarWrapper = styled.div`
  position: relative;
  background-color: #ebeff3;

  height: 100%;


  .boxContainer{
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    &:hover {
        overflow-y: overlay;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(236, 236, 236, 1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(126, 131, 146, 0.5);
        border-radius: 4px;
      }
  }

  .sideBarToggleBtn {
    top: 18px;
    right: -40px;
    position: absolute;
    z-index: 9;
    cursor: pointer;
    & .MuiSvgIcon-root {
      color: ${theme.palette.default.darkGrey};
      font-size: 1.35rem;
    }
  }

  .main-accordian {
    width: 100%;
    margin-bottom: 0 !important;

    .add-button {
      color: ${theme.palette.default.darkGrey};
      font-size: 0.875rem;
      & .MuiSvgIcon-root {
        font-size: 1.2rem;
        margin-right: 0.6rem;
        margin-left: 0.5rem;
      }
    }

    &.MuiAccordion-root {
      cursor: pointer;
      width: 100%;
      margin: 0 !important;
      & MuiAccordionDetails-root {
        width: 100%;
      }
      & .MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 0 !important;
      }
      & .MuiAccordionSummary-root {
        background-color: rgba(216, 222, 250, 0.5);
        height: 37px;
        svg {
          color: ${theme.palette.default.darkGrey};
        }
        &.Mui-expanded {
          min-height: 48px;
          margin: 0 !important;
        }
      }
      & .MuiAccordionSummary-root:before {
      }
      & .MuiAccordionDetails-root {
        padding: 0.875rem;
      }
    }
    .accordian-title {
      font-weight: 500;
      font-size: 0.875rem;
    }
    .my-team-head {
      display: flex;
      gap: 0.875rem;
      font-size: 0.875rem;
      align-items: center;
      color: ${theme.palette.default.darkGrey};
      border-bottom: 1px solid ${theme.palette.default.grey};
      padding-bottom: 0.875rem;
    }
  }
`;

export const CustomAccordian = styled(Accordion)`
  && {
    .MuiAccordionDetails-root {
      max-height: 200px; // do not change this value as scroll will show up if items are more than 5 in list
      overflow-y: hidden;
      &:hover {
        overflow-y: overlay;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(236, 236, 236, 1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(126, 131, 146, 0.5);
        border-radius: 4px;
      }
      &:last-child {
        padding-left: 0rem;
        padding-right: 0rem;
      }

      svg {
        font-size: 1rem;
      }
    }
    & .MuiAccordionSummary-root {
      background-color: ${theme.palette.default.lightPurple};
      height: 37px;
    }

    .items {
      padding: 0;
      font-size: 0.875rem;
      color: ${theme.palette.default.darkGrey};
      background-color: ${theme.palette.default.white};

      form {
        span {
          padding: 0 !important;
          .MuiSvgIcon-root {
            font-size: 1rem;
            color: ${theme.palette.default.darkGrey};
          }
        }
      }

      .avatar {
        position: relative;
        img {
          position: absolute;
        }
      }
    }

    .my-team-head {
      display: flex;
      gap: 0.875rem;
      font-size: 0.875rem;
      align-items: center;
      color: ${theme.palette.default.darkGrey};
      border-bottom: 1px solid ${theme.palette.default.grey};
      padding-bottom: 0.875rem;
      padding-top: 0.875rem;
      margin-bottom: 0.875rem;
    }

    .MuiButtonBase-root {
      display: flex;
      align-items: center;
      svg {
        margin-top: 2px;
      }
    }
    .p-data {
      text-align: left;
      width: calc(100% - 52px);
    }
  }
`;

export const LiveChatAccordianCard = styled.div`
  & {
  }
`;

export const LiveChatAccordian = styled(Accordion)`
  & {
    border-radius: 4px !important;
    margin-bottom: 16px;
    box-shadow: 0 1px 4px rgba(18, 52, 77, 0.16) !important;
    border-radius: ${(props: any) =>
      props.isExpanded ? "4px 4px 0px 0px" : "4px"};
    padding-bottom: ${(props: any) =>
      props.isExpanded ? "8px !important" : "0px"};

    .title-wrapper {
      display: flex;
      width: 100%;
      // justify-content: space-between;
      align-items: center;
    }
    &::before {
      background: none !important;
    }
    .MuiAccordionSummary-root {
      // border: 1px solid green;
      min-height: 40px;
      max-height: 40px;

      align-items: center;
      background-color: #f5f7f9;
      box-shadow: 0 1px 4px rgba(18, 52, 77, 0.16);
      display: flex;
      margin: 0 1px;
      padding: 12px;
      width: -webkit-fill-available;
      p {
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 0.08em;
        color: rgb(9, 30, 66);
      }
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
    .MuiAccordionDetails-root {
      padding: 0px;
      margin-top: 8px;
      // padding-bottom: 8px;
      // background-color: #f9f3f2;
      border-radius: 0 0 14px 14px !important;
      font-size: 13px;
      color: rgb(87, 108, 125);
      font-weight: 600;
      letter-spacing: 0.06em;
      max-height: ${(props: any) => (props.height ? props.height : "auto")};
      margin-right: 4px;
      margin-left: 4px;
      margin-bottom: 4px;

      overflow-y: hidden;
      /* } */
      &:hover {
        overflow-y: overlay;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(126, 131, 146, 0.5);
        border-radius: 4px;
      }

      .MuiListItem-root {
        padding: 0px;
        .MuiSvgIcon-root {
          font-size: 16px;
        }
      }
    }
  }
`;

export const LiveChatAccordianAgent = styled(Accordion)`
  && {   
     border-radius: 4px !important;
    margin-bottom: 8px;
    box-shadow: 0 1px 4px rgba(18, 52, 77, 0.16) !important;
    border-radius: ${(props: any) =>
      props.isExpanded ? "8px 8px 0px 0px" : "4px"};
    padding-bottom: ${(props: any) =>
      props.isExpanded ? "8px !important" : "0px"};
    &::before {
      background: none !important;
    }
    .MuiAccordionSummary-root {
      min-height: 40px;
      max-height: 40px;

      align-items: center;
      background-color: #f5f7f9;
      box-shadow: 0 1px 4px rgba(18, 52, 77, 0.16);
      display: flex;
      margin: 0 1px;
      padding: 12px;
      width: -webkit-fill-available;
      p {
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 0.08em;
        color: rgb(9, 30, 66);
      }
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
    .MuiAccordionDetails-root {
      margin-right: 4px;
      margin-left: 4px;
      .agent-wrapper {
        border-radius: 0 0 14px 14px !important;
        .MuiListItemButton-root {
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 8px;
        }
        max-height: ${(props: any) =>
          props.height
            ? props.height
            : "auto"}; // do not change this value as scroll will show up if items are more than 5 in list
        overflow-x: hidden;
        overflow-y: auto;
        margin-bottom: 8px;

        overflow-y: hidden;
        /* } */
        &:hover {
          overflow-y: overlay;
        }
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(126, 131, 146, 0.5);
          border-radius: 4px;
        }
      }

      padding: 0px;
      // padding-bottom: 8px;
      // background-color: #f9f3f2;
      border-radius: 0 0 14px 14px !important;
      font-size: 13px;
      color: rgb(87, 108, 125);
      font-weight: 600;
      letter-spacing: 0.06em;

      .MuiListItem-root {
        padding: 0px;
        .MuiSvgIcon-root {
          font-size: 16px;
        }
      }
    }
  }
`;

export const AgentCustomAccordianExtended = styled(CustomAccordian)`
  && {
    .MuiAccordionDetails-root {
      padding-left: 0rem !important;
      padding-right: 0rem !important;
      overflow-y: hidden;

      &:hover {
        overflow-y: hidden;
      }
    }
    .agent-wrapper {
      max-height: 160px; // do not change this value as scroll will show up if items are more than 5 in list
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 2rem;

      overflow-y: hidden;
      /* } */
      &:hover {
        overflow-y: overlay;
      }
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(236, 236, 236, 1);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(126, 131, 146, 0.5);
        border-radius: 4px;
      }
    }
  }
`;

export const StyledAccordainListItem = styled(ListItem)`
  && {
    padding-left: 0.4rem;
    .icon-text-wrapper {
      display: flex;
      align-items: center;
    }

    .icon {
      display: flex;
      align-items: center;
      margin-right: 0.8rem;
      svg {
        font-size: 1rem;
        color: ${theme.palette.default.darkGrey};
      }
    }

    .user-icon {
      background: #ffe2e7;
      color: #aa747d;
      display: flex;
      align-items: center;
      margin-right: 0.8rem;
      width: 28px;
      height: 28px;
      justify-content: center;
      border-radius: 50%;
    }
    .text {
      display: flex;
      align-items: center;
      color: ${theme.palette.default.darkGrey};
      font-size: 0.875rem;
    }
    .MuiButtonBase-root {
      /* padding: 5px !important; */
    }
  }
`;
