import { getIn, useFormikContext } from "formik";
import React from "react";
import Select, { components, createFilter } from "react-select";
import styled from "styled-components";

import { Tooltip } from "@mui/material";
import AppErrorMessage from "./AppErrorMessage";
import { theme } from "../../Customization/Theme";
// import { AppMaterialIcons } from "../Icons";
import { AppMaterialIcons } from "../AppMaterialIcons";
import CreatableSelect from "react-select/creatable";
import { AppToolTip } from "../../Components/UiComponents/AppToolTip/AppToolTip";
// import defaultProps from "react-select/dist/declarations/src/Select";
interface Props {
  options: any[];
  label?: string;
  displayKey?: string;
  valueKey?: string;
  placeholder?: string;
  defaultIndexValue?: number;
  name: string;
  divStyle?: any;
  tooltip?: any;
  onChangeCallbackFn?: (value: any) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isOptionDisabled?: ((option: any, selectValue: any) => boolean) | undefined;
  hideSelectedOptions?: boolean;
  //   components: any;
  [otherProps: string]: any;
  children?: React.ReactNode;
  field?: any;
  // getObjectValue: any;
}

const AppFormCreateAbleSelect: React.FC<Props> = ({
  options,
  label,
  name,
  placeholder = "Select an option",
  divStyle,
  displayKey = "label",
  valueKey = "value",
  tooltip,
  onChangeCallbackFn,
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  isLoading = false,
  isMulti = false,
  field,
  isOptionDisabled = (option: any) => option?.disabled,
  hideSelectedOptions = true,
  ...otherProps
}) => {
  const {
    errors,
    touched,
    setFieldValue,
    values,
    setFieldTouched,
    setFieldError,
    handleChange,
  } = useFormikContext<any>();

  const handleChange1 = (e: any) => {
    let event = {
      target: { name: name, value: e },
    };
    handleChange(event);
    if (e) {
      setFieldValue(name, e);
      onChangeCallbackFn && onChangeCallbackFn(e);
    } else {
      setFieldValue(name, null);
      setFieldTouched(name, true);
      onChangeCallbackFn && onChangeCallbackFn(null);
    }
  };

  // for search
  const filterConfig = {
    stringify: (option: any) => `${option[displayKey]}`,
  };

  return (
    <Div>
      <Label>
        {label}

        {tooltip && (
          <Tooltip title={tooltip} placement="right">
            <AppMaterialIcons iconName="HelpOutline" />
          </Tooltip>
        )}
      </Label>
      <CreatableSelect
        id={name}
        name={name}
        options={options}
        value={getIn(values, name) ?? null}
        onChange={(e) => handleChange1(e)}
        onBlur={(e: any) => {
          // setFieldTouched(name, true);
          if (!getIn(values, name)) setFieldError(name, undefined);
        }}
        placeholder={placeholder}
        getOptionLabel={(option: any) =>
          option?.[displayKey] || option?.label || option?.name || option?.value
        }
        isOptionSelected={(option: any) =>
          option?.[valueKey] === getIn(values, name)?.[valueKey]
        }
        isMulti={isMulti}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isDisabled={isDisabled}
        blurInputOnSelect={true}
        isLoading={isLoading}
        isOptionDisabled={isOptionDisabled}
        hideSelectedOptions={hideSelectedOptions}
        filterOption={createFilter(filterConfig)}
        // components={{ MultiValue }}
        // components={components}
        // styles={
        //   {
        //     //   indicatorSeparator: () => ({ display: "none" }),
        //   }
        // }
        {...otherProps}
      />
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default AppFormCreateAbleSelect;

const MultiValue = ({ index, getValue, options, ...props }: any) => {
  const maxToShow = 1;
  const overflow = getValue().slice(maxToShow);
  return index < maxToShow ? (
    <components.MultiValue {...props} />
  ) : index === maxToShow ? (
    <MoreSelectedBadge items={overflow} props={props} />
  ) : null;
};
const MoreSelectedBadge = ({ items, props }: any) => {
  const style = {
    marginLeft: "auto",
    background: "#d4eefa",
    fontSize: "11px",
    padding: "3px",
    order: 99,
  };

  const length = items.length;
  // const label = `+ ${length} item${length !== 1 ? "s" : ""} selected`;
  const label = `+${length}`;

  return (
    <div style={style}>
      <AppToolTip
        tooltipProps={{
          title: (
            <React.Fragment>
              {items.map((ele: any) => {
                return <components.MultiValue {...props} />;
              })}
            </React.Fragment>
          ),
          placement: "top",
        }}
        className="chip"
        iconButtonProps={{ disableRipple: true }}
        divStyle={{ padding: "2px" }}
        IconComponent={<div style={{ fontSize: "13px" }}>{label}</div>}
      />
    </div>
  );
};

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    gap: 6px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;

export const StyledReactSelectInput = styled(Select)`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 0 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 2.4rem;
    position: relative;
    .clearBtn {
      background-color: #f5f6f8;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }

    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 2px solid ${theme.palette.default.lightGrey};
    }
  }
`;
