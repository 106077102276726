import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppMarketingHistory from "../../../../AppComponents/Forms/AppMarketingHistory";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import AppModel from "../../../../Hooks/useModel/AppModel";
import useModal from "../../../../Hooks/useModel/useModel";
import { BroadcastTopTitle } from "../../../../TableStyle";
import UploadTemplate from "../BroadCastTemplate/UploadTemplate";
import { BroadcastListWrapper, WrapperContainer } from "./SmsHistoryStyles";
const SmsBroadcastHistory = () => {
  const { isShowing, toggle } = useModal();
  const navigate = useNavigate();
  const has_low_balance = localStorage.getItem("has_low_balance");
  const header = [
    "Name",
    "Sent On",
    "Recipients",
    "Status",
    "Sent",
    "Delivered",
    "Failed",
    "Actions",
  ];
  const keyList = [
    "broadcast_name",
    "sent_on",
    "recipients",
    "status",
    "sent",
    "delivered",
    "failed",
    "",
  ];
  return (
    <>
      <WrapperContainer style={{ padding: "0px 16px" }}>
        <BroadcastListWrapper style={{ padding: 0 }}>
          <BroadcastTopTitle style={{ padding: "16px 0px" }}>
            <Box className="topTitle-left">
              <Typography fontSize="16px" fontWeight="600" component="h6">
                SMS Broadcast List
              </Typography>
            </Box>
            <Box className="topTitle-right">
              <ProtectedAppButton
                moduleId="sms_template"
                specificPermissionKey="create"
                variant="outline"
                onClick={() => toggle()}
                style={{ minWidth: "170px" }}
              >
                Upload Template
              </ProtectedAppButton>
              <ProtectedAppButton
                moduleId="sms_broadcast_crud"
                specificPermissionKey="create"
                variant="primarydark"
                onClick={() => navigate("/broadcast/sms/create-broadcast/1")}
                style={{ minWidth: "170px" }}
                isDisabled={has_low_balance === "true"}
                title={
                  has_low_balance === "true"
                    ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit."
                    : ""
                }
              >
                Create Broadcast
                <AppMaterialIcons iconName="Add" />
              </ProtectedAppButton>
            </Box>
          </BroadcastTopTitle>

          <AppMarketingHistory
            height={"236"}
            customStyle={{ background: "#fff" }}
            type="sms"
            header={header}
            keyList={keyList}
            skeltonLoaderHeight={"282"}
          />
        </BroadcastListWrapper>
      </WrapperContainer>
      <AppModel
        isShowing={isShowing}
        onClose={() => toggle()}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
            width: "950px",
          },
        }}
      >
        <UploadTemplate close={() => toggle()} />
      </AppModel>
    </>
  );
};

export default SmsBroadcastHistory;
