import { FaqSourceAction } from "../../../../Store/Slices/Faq/Source/source.actions";

// Get Listing Of Source Type Text For Table
export const getSourceListing = (
  dispatch: any,
  HEADERS: any,
  sourceType: any,
  localValues: any,
  setLoading?: any
) => {
  
  const data = {
    limit: localValues?.itemsPerPage,
    offset: localValues?.currentPage,
    query: localValues?.query,
    HEADERS,
    sourceType,
  };
  dispatch(FaqSourceAction.GeteSourceListing(data)).then((res: any) => {
    setLoading(false);
  });
};
