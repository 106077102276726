import http from "../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class SubscriptionsNetworkService {
  // get All Subscription Plan
  static getAllPlans(id: string) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_All_PLAN}${id}`);
  }
  // get active subscription data
  static getSubscriptionData(subscriptionId: string) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_SUBSCRIPTION_BY_ID}${subscriptionId}`,
    );
  }

  static getAddonListData(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_ADDON_LITS}${data?.product_id}/${data?.subscription_id}`,
    );
  }

  static updateSubscriptionData(data: any, subscription_id: any) {
    return http.put(
      `${consoleBaseUrl}${Endpoints.UPDATE_SUBSCRIPTION}${subscription_id}`,
      data,
    );
  }

  static getAllInvoiceList(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_ALL_INVOICE}${data?.customer_Id}`,
      data,
    );
  }

  static updateSubscriptionCallbackData(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.UPDATE_SUBSCRIPTION_CALLBACK}${data?.invoice_id}`,
      data,
    );
  }

  static cancleSubscripation(subscripation_Id: any, data: any) {
    return http.post(
      `${consoleBaseUrl}/core/subscription/cancel_subscription/${subscripation_Id}`,
      data,
    );
  }

  static successSubscripation(data: any) {
    return http.post(
      `${consoleBaseUrl}/core/hostedpage/success_callback`,
      data,
    );
  }

  static addCredits(data: any, subscripation_Id: any) {
    return http.post(
      `${consoleBaseUrl}/core/subscription/add_credits/${subscripation_Id}`,
      data,
    );
  }

  static getInvoiceByID(invoideId: any) {
    return http.get(
      `${consoleBaseUrl}/core/invoice/get_invoice_by_id/${invoideId}`,
    );
  }

  static getInvoiceCount(data: any) {
    return http.post(
      `${consoleBaseUrl}/core/invoice/get_invoice_count/${data.customer_Id}`,
      data,
    );
  }

  static freePlanSuccessCallback(data: any) {
    return http.post(
      `${consoleBaseUrl}/core/hostedpage/success_callback_free_plan`,
      data,
    );
  }

  static getAllCountryList(data: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.GET_ALL_COUNTRY}`);
  }

  static getCreditListById(data: any) {
    return http.get(
      data.search
        ? `${consoleBaseUrl}${Endpoints.GET_CREDIT_LIST_BY_TYPE}type=${data.type}&search=${data.search}&limit=${data.limit}&offset=${data.offset}`
        : `${consoleBaseUrl}${Endpoints.GET_CREDIT_LIST_BY_TYPE}type=${data.type}&limit=${data.limit}&offset=${data.offset}`,
    );
  }

  static getAllWalletInvoiceList(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_WALLET_INVOICE}${data?.customer_Id}`,
      data,
    );
  }

  static getWalletInvoiceCount(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_WALLET_INVOICE_COUNT}${data.customer_Id}`,
      data,
    );
  }

  static getInvoicePaymentLink(invoiceID: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_INVOICE_PAYMENT_LINK}${invoiceID}`,
    );
  }

  static getCreditUtilization(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.GET_CREDITS_UTILIZATION}`,
      data,
    );
  }
  static getwhatsappCreditLogsData(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.GET_WHATSAPP_CREDIT_LOGS}?start_date=${data.start}&end_date=${data.end}`,
      data,
    );
  }

  // Credit Summary Logs
  static getCreditSummaryLogsNetwork(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.CREDIT_SUMMARY_LOGS}?start_date=${data?.start}&end_date=${data?.end}&limit=${data?.limit}&offset=${data?.offset}`,
      data,
    );
  }

   // Credit Summary 
   static getCreditSummaryNetwork(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.CREDIT_SUMMARY}?start_date=${data?.start}&end_date=${data?.end}`,
      data,
    );
  }
}
class Endpoints {
  static GET_All_PLAN = "/core/subscription/plans/";
  static GET_SUBSCRIPTION_BY_ID = "/core/subscription/get_subscription_by_id/";
  static GET_ADDON_LITS = "/core/addon/get_addon/";
  static UPDATE_SUBSCRIPTION = "/core/subscription/update_subscription/";
  static GET_ALL_INVOICE = "/core/invoice/get_invoice_by_customer_id/";
  static UPDATE_SUBSCRIPTION_CALLBACK =
    "/core/invoice/check_for_pending_invoice/";
  static CANCLE_SUBSCRIPTION: "/core/subscription/cancel_subscription/";
  static SUCCESS_SUBSCRIPATION: "/core/subscription/update_subscription_success/";
  static ADD_CREDITS = "core/subscription/add_credits";
  static GET_ALL_COUNTRY = "/workspace/country-info";
  static GET_CREDIT_LIST_BY_TYPE = "/workspace/credits-rate?";
  static GET_WALLET_INVOICE = "/core/invoice/get_wallet_invoice/";
  static GET_WALLET_INVOICE_COUNT = "/core/invoice/get_wallet_invoice_count/";
  static GET_INVOICE_PAYMENT_LINK = "/core/invoice/get_invoice_payment_link/";
  static GET_CREDITS_UTILIZATION = "/core/credits-utilization";
  static GET_WHATSAPP_CREDIT_LOGS =
    "/workspace/analytics/whatsapp-billing-summary-table";

  // Credit Summary Logs
  static CREDIT_SUMMARY_LOGS = "/workspace/analytics/credit-summary-logs";

  // Credit Summary
  static CREDIT_SUMMARY = "/workspace/analytics/credit-summary";
  
}
