import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";
import { ApexChatWrapper } from "../../Email_Broadcast_Overview_Summary/Doughnout";
import { TextTitle } from "../../Email_Broadcast_Overview_Summary/EmailBroadcastSummaryStyles";
import { Wrapstatistics } from "../Emai_broadcastStyles";
import { GrahWrapper } from "./StatisticsStyle";

interface Props {
  statsData?: any;
  broadcastDetails?: any;
  loading?:any;
}


const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#C589BF" : "#308fe8",
  },
}));

const BorderLinearProgress3 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#FFDD55" : "#308fe8",
  },
}));

const BorderLinearProgress4 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1FA3D1" : "#308fe8",
  },
}));

const BorderLinearProgress5 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1FD171" : "#308fe8",
  },
}));

const BorderLinearProgress6 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E5E4E2",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "red" : "#308fe8",
  },
}));

export const ResponseTimeAgent: React.FC<Props> = ({
  statsData,
  broadcastDetails,
  loading
}) => {


  return (
    loading ? <AppSkeletonLoader /> :
    <Wrapstatistics>
    <Box sx={{ flexGrow: 1, height: '100%' }} className="graph_container">

      <GrahWrapper>
        
          <Box display={"flex"} alignItems={"center"}>
            <ApexChatWrapper>
              <Box className="heading__tooltip">
                <TextTitle>
                  <p className="time_stats">Statistics</p>
                </TextTitle>
                <AppToolTip
                  tooltipProps={{
                    title: "Agent time performance metrics",
                    placement: "top-end",
                    value: 80,
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={
                    <AppMaterialIcons
                      iconName="HelpOutline"
                      style={{ fontSize: "16px" }}
                    />
                  }
                />
              </Box>
            </ApexChatWrapper>
          </Box>
         
        
                  {
                      (statsData?.sent ?? 0) === 0 &&
                      (statsData?.delivered ?? 0) === 0 &&
                      (statsData?.opened ?? 0) === 0 &&
                      (statsData?.clicked ?? 0) === 0 &&
                      (statsData?.failed ?? 0) === 0 ?
                      (
                  <NoRecordsFound
                    imageSrc={NoRecordImage}
                    imageWidth={"200px"}
                    headerTextSize={"19px"}
                  />
                      ):(
                  <StatisticsBarsWrapper>
                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Sent</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{statsData?.sent || 0}</p>
                        <BorderLinearProgress2
                          variant="determinate"
                          value={(statsData?.sent / statsData?.recipients) * 100}

                        />
                      </div>
                    </div>

                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Delivered</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{statsData?.delivered || 0}</p>
                        <BorderLinearProgress3
                          variant="determinate"
                          value={(statsData?.delivered / statsData?.recipients) * 100}
                        />
                      </div>
                    </div>

                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Open</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{statsData?.opened || 0}</p>
                        <BorderLinearProgress4
                          variant="determinate"
                          value={(statsData?.opened / statsData?.recipients) * 100}
                        />
                      </div>
                    </div>

                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Clicks</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{statsData?.clicked || 0}</p>
                        <BorderLinearProgress5
                          variant="determinate"
                          value={(statsData?.clicked / statsData?.recipients) * 100}
                        />
                      </div>
                    </div>

                    <div className="progess_bar">
                      <div className="progress_bar_text">
                        <p className="header_avg">Failed</p>
                      </div>
                      <div className="progress_bar_count">
                        <p className="stats">{statsData?.failed || 0}</p>
                        <BorderLinearProgress6
                          variant="determinate"
                          value={(statsData?.failed / statsData?.recipients) * 100}
                        />
                      </div>
                    </div>
                    </StatisticsBarsWrapper>
                      )
                  } 
      </GrahWrapper>
    </Box>
    </Wrapstatistics>
  );
};

const StatisticsBarsWrapper = styled(Box)`
&& {
  height: inherit;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
`;