import { Button, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField } from "@mui/material"
import { FlowBuilderPrevireWapper, FormLabelStyle } from "../Utils/style"
import { Android12Switch, convertWhatsappTextToHtml, escapeHtmlEntities, replacePlaceholders } from "../Utils/common"
import { useEffect, useRef, useState } from "react"
import PreviewImage from '../Assets/Preview.png'
import ChatScreen from '../Assets/chatScreen.svg'
import { getContentData, getScreenType } from "../Store/FlowBuilder.selectors"
import { useSelector } from "react-redux"
import AppButton from "../Components/Button"
import CancelIcon from '@mui/icons-material/Cancel';
import { debounce } from "lodash"
import DoneIcon from '@mui/icons-material/Done';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AppDatePicker from "../Components/Wheel"
import AppPreviewInput from "../Components/PreviewInput"
import AppPreviewRadioCheckbox from "../Components/PreviewRadioCheckbox"
import PreviewText from "../Components/PreviewText"
import AppPreviewTextArea from "../Components/PreviewTextArea"
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import DescriptionIcon from '@material-ui/icons/Description';
const FlowBuilderPreview = (props: any) => {
    const drawerRef = useRef<HTMLDivElement | null>(null);
    const dropdownDrawerRef = useRef<HTMLDivElement | null>(null);
    const DatePickerRef = useRef<HTMLDivElement | null>(null);
    const [drawerStyle, setDrawerStyle] = useState({});
    const [width, setWidth] = useState<any>('');
    const [open, setOpen] = useState(false);
    const contentData = useSelector(getContentData);
    const screenType = useSelector(getScreenType);
    const appRef = useRef<HTMLDivElement | null>(null);
    const [dropdownContactData, setDropdownContactData] = useState<any>({})
    const [selectValue, setSelectValue] = useState<any>("")
    const [inputData, setInputData] = useState<any>({})
    const [selectDateValue, setSelectDateValue] = useState<any>("")
    const handleResize = debounce(() => {
        if (drawerRef.current) {
            const rect = drawerRef.current.getBoundingClientRect();
            setDrawerStyle({
                width: `${rect.width}px`,
                height: `${rect.height}px`,
            });
        }
    }, 100);

    useEffect(() => {
        if (drawerRef.current) {
            drawerRef.current.style.display = 'none';
        }
        const resizeObserver = new ResizeObserver(handleResize);
        if (drawerRef.current) {
            resizeObserver.observe(drawerRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [drawerRef.current]);

    useEffect(() => {
        if (drawerRef.current) {

            drawerRef.current.style.display = 'none';
        }
    }, [])
    const openDrawer = (event: any) => {
        setSelectValue("")
        if (drawerRef.current) {
            const imageWidth = event.target.width; // Adjust how you get the image dimensions
            const imageHeight = event.target.height; // Adjust how you get the image dimensions
            setWidth(imageWidth)
            // Set dimensions of the drawer
            drawerRef.current.style.width = `${imageWidth - 13}px`;
            drawerRef.current.style.height = `${imageHeight - 35}px`; // Adjust based on your needs
            drawerRef.current.style.display = 'block';
            drawerRef.current.style.overflowY = "clip"
            drawerRef.current.style.marginBottom = "9px";


            setTimeout(() => {
                drawerRef.current?.classList.add('open');
            }, 10);

        }
    };
    const closeDrawer = () => {
        if (drawerRef.current) {
            drawerRef.current.classList.remove('open');
            setTimeout(() => {
                if (drawerRef.current) {
                    drawerRef.current.style.display = 'none';
                }
            }, 500); // Duration matches the transition duration
        }
    };
    const openDropdownDrawer = (event: any, options: any, data?: any) => {
        if (!Array.isArray(options['data-source'])) {
            let data = {
                ...options,
                "data-source": contentData?.data?.[options?.name]?.['__example__']
            }
            setDropdownContactData(data)
        } else {
            setDropdownContactData(options)
        }
        if (dropdownDrawerRef.current) {
            const imageWidth = width; // Adjust how you get the image dimensions
            const imageHeight = event.target.height; // Adjust how you get the image dimensions

            // Set dimensions of the drawer
            dropdownDrawerRef.current.style.width = `${imageWidth - 13}px`;
            dropdownDrawerRef.current.style.height = `${imageHeight - 35}px`; // Adjust based on your needs
            dropdownDrawerRef.current.style.display = 'block';
            dropdownDrawerRef.current.style.overflowY = "clip";
            dropdownDrawerRef.current.style.marginBottom = "9px";
            // drawerRef.current.style.display = 'none';


            setTimeout(() => {
                dropdownDrawerRef.current?.classList.add('open');
            }, 10);

        }
    };
    const closeDropdownDrawer = (e: any, value?: any) => {
        setSelectValue(value)
        if (dropdownDrawerRef.current) {
            dropdownDrawerRef.current.classList.remove('open');
            setTimeout(() => {
                if (dropdownDrawerRef.current) {
                    dropdownDrawerRef.current.style.display = 'none';
                    // drawerRef.current.style.display = 'block';
                }
            }, 500); // Duration matches the transition duration
        }
    };

    const openDatePickerDrawer = (event: any) => {
        if (DatePickerRef.current) {
            const imageWidth = width; // Adjust how you get the image dimensions
            // Set dimensions of the drawer
            DatePickerRef.current.style.width = `${imageWidth - 13}px`;
            DatePickerRef.current.style.height = `300px`; // Adjust based on your needs
            DatePickerRef.current.style.display = 'block';
            DatePickerRef.current.style.overflowY = "clip";
            DatePickerRef.current.style.marginTop = "150px";
            DatePickerRef.current.style.border = "1px solid";
            DatePickerRef.current.style.bottom = "30%";
            // drawerRef.current.style.display = 'none';


            setTimeout(() => {
                DatePickerRef.current?.classList.add('open');
            }, 10);

        }
    };
    const closeDatePickerDrawer = (e: any, value?: any) => {
        setSelectDateValue(value)
        if (DatePickerRef.current) {
            DatePickerRef.current.classList.remove('open');
            setTimeout(() => {
                if (DatePickerRef.current) {
                    DatePickerRef.current.style.display = 'none';
                    // drawerRef.current.style.display = 'block';
                }
            }, 500); // Duration matches the transition duration
        }
    };
    const getData = () => {
        console.log("screenType", screenType, contentData?.layout?.children[0]?.children);

        if (screenType === "parentScreen" && contentData?.layout?.children[0]?.children) {
            return contentData?.layout?.children[0]?.children
        } else if (screenType === "childScreen" && contentData?.layout?.children) {
            return contentData?.layout?.children
        } else {
            return []

        }
    }
    const onChange = (e: any, fieldData: any) => {
        let value = e.target.value
        setInputData({
            ...inputData,
            [fieldData?.name]: value
        })
    }
    const clickOn = () => {
        const drawerButton = document.getElementById('drawer-button');
        if (drawerButton) {
            drawerButton.click();
        }
    }
    console.log("contentData -------------------> ", contentData, selectValue);
    return (
        <FlowBuilderPrevireWapper>
            {/* <div className="PreviewTitle">Preview</div>
            <div className="PreviewMode">Interactive mode <FormControlLabel
                control={<Android12Switch defaultChecked />}
                label=""
            /></div> */}
            <div ref={appRef}>
                <div className="App">
                    <div>
                        <img
                            id="chat-screen"
                            style={{
                                width: '63%',
                                position: 'absolute',
                                left: '21%',
                                top: '112px',
                                cursor:"pointer"
                            }}
                            onClick={clickOn}
                            src={ChatScreen}
                            alt="Preview"
                        />
                        <a href="#" >
                            <img
                                style={{ width: '100%' }}
                                src={PreviewImage}
                                alt="Preview"
                                id="drawer-button"
                                onClick={openDrawer}
                            />
                        </a>
                    </div>
                    <div ref={drawerRef} className="drawer" style={drawerStyle}>
                        <div className="header">
                            <button className="close-btn" onClick={closeDrawer}>
                                &times;
                            </button>
                            <div>
                                <a>{contentData?.title}</a>
                            </div>
                        </div>
                        <div className="main-content-body">
                            {
                                getData()?.map((content: any, index: number) => {
                                    console.log("contentData?.data?.[content?.text]?.['__example__']", content?.text?.replace(/\${[^}]+}/g, ''));
                                    const formattedValue = convertWhatsappTextToHtml(content?.text?.includes("${") ? replacePlaceholders(content?.text, contentData?.data) : content?.text)?.replaceAll("\n", "<br/>");
                                    console.log('content', content);

                                    return (
                                        <div key={index}>
                                            {content.type !== "Footer" ?
                                                <>
                                                    {content?.type === "TextHeading" &&
                                                        <PreviewText style={{ fontSize: "1.5rem" }} formattedValue={formattedValue} />}
                                                    {content?.type === "TextSubheading" &&
                                                        <PreviewText style={{ fontSize: "1.25rem" }} formattedValue={formattedValue} />}

                                                    {content?.type === "TextBody" &&
                                                        <PreviewText style={{ fontSize: "1rem" }} formattedValue={formattedValue} />}

                                                    {content?.type === "TextCaption" &&
                                                        <PreviewText style={{ fontSize: "0.875rem" }} formattedValue={formattedValue} />}

                                                    {content?.type === "TextInput" &&
                                                        <AppPreviewInput value={inputData[content?.name]} onChange={(e: any) => onChange(e, content)} style={{ border: "1px solid rgb(126, 131, 146)", height: "50px" }} content={content} onClick={() => { }} icon={<CancelIcon />} />
                                                    }
                                                    {content?.type === "TextArea" &&
                                                        <AppPreviewTextArea content={content} />
                                                    }
                                                    {
                                                        content?.type === "RadioButtonsGroup" &&
                                                        <AppPreviewRadioCheckbox contentData={contentData} content={content} icon={<Radio />} />
                                                    }
                                                    {
                                                        content?.type === "CheckboxGroup" &&
                                                        <AppPreviewRadioCheckbox contentData={contentData} content={content} icon={<Checkbox />} />
                                                    }
                                                    {content?.type === "Dropdown" &&
                                                        <AppPreviewInput data={contentData} type="dropdown" style={{ border: "1px solid rgb(126, 131, 146)", height: "50px" }} content={content} value={selectValue?.title} onClick={(e: any) => openDropdownDrawer(e, content)} icon={<KeyboardArrowRightIcon />} />
                                                    }
                                                    {content?.type === "OptIn" &&
                                                        <FormGroup style={{ display: "contents" }}>
                                                            <FormControlLabel
                                                                control={<Checkbox defaultChecked />}
                                                                label={
                                                                    <>
                                                                        {!content?.required ? " (optional)" : ""} {content?.label}
                                                                        {content['on-click-action'] && (
                                                                            <a style={{ color: "green", cursor: "pointer", marginLeft: "5px" }}>Read More</a>
                                                                        )}
                                                                    </>
                                                                }
                                                            />
                                                        </FormGroup>

                                                    }
                                                    {content?.type === "Image" && content?.src &&
                                                        <div style={{ width: "100%", height: content?.height + "px" }}>
                                                            <img style={{
                                                                objectFit: "contain",
                                                                height: content?.height + "px",
                                                                width: "100%"
                                                            }} src={"data:image/png;base64," + content?.src} />

                                                        </div>
                                                    }
                                                    {content?.type === "DatePicker" &&
                                                        <AppPreviewInput style={{ border: "1px solid rgb(126, 131, 146)", height: "50px" }} content={content} value={selectDateValue} onClick={openDatePickerDrawer} icon={<CancelIcon />} />
                                                    }
                                                    {(content?.type === "PhotoPicker" || content?.type === "DocumentPicker") && (
                                                        <>
                                                            <div style={{ fontWeight: 600, fontSize: "16px" }}>
                                                                {content?.label}
                                                                {(content['min-uploaded-photos'] == 0) && <a> (optional)</a>}
                                                                {(content['min-uploaded-documents'] == 0) && <a> (optional)</a>}
                                                            </div>
                                                            <div>{content?.description}</div>
                                                            <div style={{
                                                                border: '1px solid green',
                                                                borderRadius: '32px',
                                                                height: '41px',
                                                                textAlign: 'center',
                                                                padding: '10px',
                                                                marginTop: '12px',
                                                                color: "green"
                                                            }}>
                                                                {content?.type === "DocumentPicker" ? <DescriptionIcon style={{ margin: "-6px 3px" }} /> : <PhotoCameraIcon style={{ margin: "-6px 3px" }} />}  {content?.type === "DocumentPicker" ? "Upload Document" : "Take Photo"}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                                : null}
                                        </div>
                                    )
                                })
                            }

                        </div>
                        {screenType === "parentScreen" ? <div className="main-content">

                            <AppButton className="footerButton" fontColor="#fff" backgroundColor="#25D366" text={contentData?.layout?.children[0]?.children[contentData?.layout?.children[0]?.children?.length - 1]?.label || "Continue"} style={{ position: 'absolute', bottom: 20, left: 14, right: 14, borderRadius: "15px", height: "auto" }}
                            />

                        </div> : null}
                    </div>
                    <div ref={dropdownDrawerRef} className="dropdownDrawer" style={drawerStyle}>
                        <div className="dropdownHeader">
                            <button className="close-btn" onClick={(e: any) => closeDropdownDrawer(e)}>
                                &times;
                            </button>
                            <div>
                                <a>{dropdownContactData?.label}</a>
                            </div>
                        </div>
                        <div className="main-content-body">
                            {dropdownContactData['data-source']?.map((res: any) => {
                                return (
                                    <div className="select-content" style={{ color: selectValue?.id === res.id ? "green" : "" }} onClick={(e: any) => closeDropdownDrawer(e, res)}>
                                        <div>
                                            {res?.title}
                                        </div>
                                        <div>
                                            <DoneIcon />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div ref={DatePickerRef} className="dropdownDrawer" style={drawerStyle}>
                        <div className="main-content-body">
                            <AppDatePicker handleClose={closeDatePickerDrawer} />

                        </div>
                    </div>
                </div>
            </div>

        </FlowBuilderPrevireWapper>
    )
}
export default FlowBuilderPreview