// vendors
import { useContext, useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useOnClickOutside from "use-onclickoutside";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { useSelector } from "react-redux";

// builder
import { NodeContext, BuilderContext } from "./../../flow-builder-v2/contexts";
import { useAction } from "../../flow-builder-v2/hooks";
import { getNodeConfig } from "./../../flow-builder-v2/utils";

// components
import {
  NodeType,
  Editor,
  MessagePreview,
  UIButton,
  Icon,
} from "./../../ui-components";
import Card from "../card";
import ButtonList from "./buttonsList";
import Action from "../action";

// utils
import {
  getInitialEditiorText,
  getPlainTextData,
  isInIgnoredElement,
  getHTMLContent,
} from "./../utils/editor";
import {
  getText,
  getResponseWithoutText,
  getResponse,
  getMediaDetails,
  isEmptyObj,
  cleanMediaDetails,
  getQuickAction,
} from "./../utils/helper";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";

const ButtonNode = (props: any) => {
  const builderContext = useContext(BuilderContext);
  const nodeCntx = useContext(NodeContext);
  const node = getNodeConfig(nodeCntx.subType || nodeCntx.type);
  const { setActiveNode, activeNode, language } = builderContext;
  const { updateNode, removeNode } = useAction();
  const response = getResponse(nodeCntx, language);
  const text = getText(response);
  const [message, setMessage] = useState(text || "");
  const [showPreview, setPreview] = useState<boolean>(false);
  const [userInputQuery, updateUserInputQuery] = useState<boolean>(getQuickAction(nodeCntx.quickActions, "user-query") || false);
  const [buttonsList, setButtonList] = useState<
    Array<{
      id: string;
      type: string;
      value: { text: string; url: string };
    }>
  >(getResponseWithoutText(nodeCntx, language) || []);
  const [mediaDetails, setMediaDetails] = useState<any>(
    getMediaDetails(response) || {}
  );
  const wrapperRef = useRef(null);
  const editorRef = useRef(null);
  const actions = nodeCntx?.actions || [];

  const [editorState, setEditorState] = useState(
    getInitialEditiorText(text || "")
  );
  const [isOutsideClicked, setOutsideClicked] = useState<boolean>(false);
  const currentBot = useSelector(useCurrentBot);
  const validation = currentBot?.channel ? node.validation[currentBot?.channel] : {};
  const maxLimit = validation?.maxLimit;

  useOnClickOutside(wrapperRef, (event) => {
    if (isInIgnoredElement(event.target, "ignore-onClickOutside")) {
      return;
    }
    setOutsideClicked(true);
    if (!showPreview) {
      const previousPlainText = nodeCntx?.response
        ? getPlainTextData(getInitialEditiorText(getText(nodeCntx.response)))
        : "";
      const plainText = getPlainTextData(editorState);
      const previousMediaDetails = response ? getMediaDetails(response) : {};
      const currentMediaDetails = mediaDetails;

      if (plainText === '') return;
      if(buttonsList.length === 0) return;

      if (plainText && buttonsList.length > 0 || !isEmptyObj(mediaDetails)) {
        const isButtonListEqual = isEqual(getResponseWithoutText(nodeCntx, language), buttonsList);
        if (
          plainText !== previousPlainText ||
          !isButtonListEqual ||
          !isEqual(previousMediaDetails, currentMediaDetails) ||
          getQuickAction(nodeCntx.quickActions, "user-query") !== userInputQuery
        ) {
          updateNode(
            nodeCntx.nodeId,
            message,
            mediaDetails,
            buttonsList,
            false,
            { type: "user-query", enabled: userInputQuery }
          );
        }
      }
      setPreview(true);
    }
  });

  const handleBlur = () => {
    setMessage(getHTMLContent(editorState));
  };

  const onEditorStateChange = (editorState: any, mediaInfo: any) => {
    setMessage(getHTMLContent(editorState));
    setEditorState(editorState);
    setMediaDetails(cleanMediaDetails(mediaInfo));
  };

  const handlePopOverClose = (buttons: any) => {
    setButtonList(buttons);
  };

  const togglePreview = () => {
    setPreview(!showPreview);
    setActiveNode(nodeCntx.nodeId || nodeCntx.id);
  };

  const handleCancelEditing = () => {
    if (nodeCntx?.nodeId) {
      setButtonList(getResponseWithoutText(nodeCntx, language));
      setOutsideClicked(false);
      setActiveNode(null);
    } else {
      removeNode();
    }
    togglePreview();
  };

  const handleButtonLabelChange = (label: string, activeButtonIdx: number | null) => {
    if (activeButtonIdx !== null) {
      const newState = buttonsList.map((button, index) => {
        if (index === activeButtonIdx) {
          return {
            ...button,
            value: { ...button.value, ...{ text: label } },
          };
        }
        return button;
      });
      setButtonList(newState);
    }
  };

  const handleButtonDelete = (activeButton: any) => {
    if (activeButton.id) {
      const newState = buttonsList.filter((button, index) => button.id !== activeButton.id);
      setButtonList(newState);
    }
  };

  const handleButtonSave = (updatedAction: any) => {
    if (updatedAction.id) {
      const previousValue = getById(updatedAction.id);

      const isDifferent = isEqual(
        previousValue,
        updatedAction
      )

      if (previousValue) {
        if (!isDifferent) {
          const newState = buttonsList.map((button, index) => {
            if (button.id === updatedAction.id) {
              return {
                ...button,
                type: updatedAction.type,
                value: {
                  ...button.value,
                  ...{
                    text: updatedAction.value.text,
                    url: updatedAction.value.url,
                    ...updatedAction.value
                  },
                },
                ...(updatedAction.type === "transfer_to_faqs" && {
                  text: updatedAction.text,
                }),
              };
            }
            return button;
          });
          setButtonList(newState);
        }
        return;
      } else {
        setButtonList([...buttonsList, ...[updatedAction]])
      }
    }
  };

  const getById = (id: string) => buttonsList.find(button => button.id === id);

  const handlePreviewButtonClick = () => {
    setPreview(false);
  };

  const handleUserInputQueryChange = (checked: boolean) => {
    updateUserInputQuery(checked);
  };

  useEffect(() => {
    if (nodeCntx.id && activeNode !== null && activeNode === nodeCntx.id) {
      setPreview(false);
    }

    if (nodeCntx.nodeId === activeNode) {
      const text = getText(response);
      setEditorState(getInitialEditiorText(text));
      setMessage(text);
    }
  }, [activeNode]);

  useEffect(() => {
    setActiveNode(nodeCntx.nodeId || nodeCntx.id);
    setPreview(text !== "");
    setOutsideClicked(false);
  }, []);

  useEffect(() => {
    const value = response ? response[0].value : "";
    setMessage(value);
    setButtonList(getResponseWithoutText(nodeCntx, language));
  }, [language]);

  return (
    <Box
      id={props.id}
      {...((nodeCntx.nodeId || nodeCntx.id) && { ref: wrapperRef })}
    >
      <Card
        activeIndex={props.id}
        isEditState={!showPreview}
        nodeOrder={props.id + 1}
        onCancelClick={handleCancelEditing}
        onUserInputQueryChange={handleUserInputQueryChange}
        userInputQuery={userInputQuery}
      >
        <Box
          sx={{ p: "12px 16px" }}
          onClick={(e) => {
            e.stopPropagation();
            togglePreview();
          }}
        >
          {!showPreview ? (
            <Editor
              error={isOutsideClicked && getPlainTextData(editorState) === ""}
              ref={editorRef}
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              onBlur={handleBlur}
              mediaInfo={mediaDetails}
            />
          ) : (
            <MessagePreview mediaDetails={mediaDetails} text={message} />
          )}
        </Box>
        <Divider />
        <NodeType text={node.name} color={node.color} icon={node.icon} />
        <Divider />
        {isOutsideClicked && buttonsList.length === 0 && (
          <Typography
            sx={{ fontSize: "14px", lineHeight: "21px", fontWeight: 400, color: '#F44336', px: '16px', pt: '12px' }}
          >
            Please add atleast one button
          </Typography>
        )}
        <ButtonList
          language={language}
          showPreview={showPreview}
          buttonsList={cloneDeep(buttonsList)}
          onLabelChange={handleButtonLabelChange}
          onButtonSave={handleButtonSave}
          onPreviewButtonClick={handlePreviewButtonClick}
          onButtonDelete={handleButtonDelete}
          maxLimit={maxLimit}
        />
        {userInputQuery && showPreview && (
          <>
            <Divider />
            <Box
              sx={{ p: "12px 16px" }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Typography
                sx={{
                  display: 'inline-block',
                  p: '4px',
                  borderRadius: '4px',
                  fontSize: "12px",
                  lineHeight: "18px",
                  fontWeight: 400,
                  color: "#7E8392",
                  backgroundColor: "#F5F6F8"
                }}
              >
                User input query
              </Typography>
            </Box>
          </>
        )}
      </Card>
      <Action actions={actions} nodeContext={nodeCntx} />
    </Box>
  );
};
export default ButtonNode;
