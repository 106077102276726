import { html } from '@codemirror/lang-html';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, IconButton } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import 'draft-js/dist/Draft.css';
import { useFormikContext } from 'formik';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import AppButton from '../../../../../AppComponents/AppButton';
import { AppMaterialIcons } from '../../../../../AppComponents/AppMaterialIcons';
import { AppConfig } from '../../../../../Config/app-config';
import { theme } from '../../../../../Customization/Theme';
import { BROADCAST_EMAIL_FORM_DATA } from '../../Utils/Constants';

const HTMLEditor = (props: any) => {
    const { values, setFieldValue } = useFormikContext();
    const [editor, setEditorState] = useState<any>(localStorage.getItem("HTMLTemplate") ? JSON.parse(localStorage.getItem("HTMLTemplate") || "") : "");
    const onChange = useCallback((value: any, viewUpdate: any) => {
        setEditorState(value)
        localStorage.setItem("HTMLTemplate", JSON.stringify(value));
        setFieldValue("HTMLTemplate", JSON.stringify(value))
        AppConfig.setUserData(
            BROADCAST_EMAIL_FORM_DATA, values
        )
    }, []); // eslint-disable-line
    return (
        <React.Fragment>
            <EditorBtnWrapper isFullScreen={props?.isFullScreen}>
                {props.handleBack && !props?.isBroadcast && <AppButton className="positionBtn back" variant='outline' onClick={props.handleBack}>
                    Back
                </AppButton>}
                {props?.isBroadcast ? <> <div className="left">
                    {(
                        <IconButton onClick={() => props?.handlePageBack(1)}>
                            <AppMaterialIcons
                                iconName={"ArrowBackIosRounded"}
                                style={{ fontSize: "0.8rem" }}
                            />
                        </IconButton>
                    )}
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <p className="heading" style={{ padding: "8px 0", marginLeft: "4px" }}>
                            {props?.templateName}
                        </p>
                    </Box>
                </div>
                    <div>
                        <a>Template Name:- {props?.templateName}</a> <a onClick={props.handleBack} style={{
                            marginLeft: "15px",
                            color: "blue",
                            cursor: "pointer"
                        }}> Change Template</a>
                    </div> </> : null}
                <div className="right" style={{ display: "flex" }}>
                    {props?.isBroadcast ?
                        <React.Fragment>
                            {
                                <React.Fragment>
                                    <AppButton variant='outline' onClick={() => props?.toggleModal(true)}>
                                        <VisibilityOutlinedIcon style={{ fontSize: "30px" }} />
                                    </AppButton>
                                    <AppButton onClick={() => props?.handleEditorChanges()} variant='outline' >
                                        {"Continue"}
                                    </AppButton>
                                    <AppButton onClick={() => props.updateTemplateAndSubmit()} variant='outline' >
                                        {"Save & Continue"}
                                    </AppButton>
                                </React.Fragment>
                            }
                        </React.Fragment>
                        : null}
                </div>
            </EditorBtnWrapper>

            <EditorMainWrapperStyled isFullScreen={props?.isFullScreen} error={false}>
                <CodeMirror
                    value={editor}
                    minHeight="60vh"
                    theme="dark"
                    onChange={onChange}
                    extensions={[html({
                        matchClosingTags: true
                    })]}
                    placeholder="Type your HTML code here!!"
                />
            </EditorMainWrapperStyled>
        </React.Fragment>
    );
}

export default HTMLEditor;

const EditorMainWrapperStyled = styled(Box)(({ error, isFullScreen }: any) => ({
    backgroundColor: "#FFFFFF",
    border: "1px solid #d9d9d9",
    margin: isFullScreen ? "0px 20px 20px 20px" : "20px 20px 0",
    padding: "12px",
    fontSize: "1rem",
    overflow: "auto",
    borderRadius: "4px",
    height: isFullScreen ? "calc(100vh - 80px)" : "calc(100vh - 320px)",
    color: "#7E8392",
    minHeight: "400px",
    ...(error && {
        border: '1px solid red',
        backgroundColor: 'rgb(243 209 209 / 36%)'
    }),
}));

const EditorBtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: ${(props: any) => props?.isFullScreen ? "" : "-53px"};
    background-color: ${(props: any) => props?.isFullScreen ? "#fff" : "transparent"};
    height: ${(props: any) => props?.isFullScreen && "64px"};
    right: ${(props: any) => props?.isFullScreen ? "0px" : "0px"};
    width: ${(props: any) => props?.isFullScreen && "100%"};
    padding-right: ${(props: any) => props?.isFullScreen && "1rem"};
    button {
        margin-left: 10px;
    }
    .left {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        p.heading {
            font-size: 16px;
            color: ${theme.palette.default.black};
            font-weight: 500;
        }
    }

    .test-tamplate__btn {
        display: flex;
        gap: 0.5rem;
        min-width: 287px;
        .back-btn {
            

        }
    }
`
