import { Box, Typography } from "@mui/material";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import NoRecordImage from "../../../assets/images/norecordsanalytics.svg";
import AppSkeletonLoader from "../../UiComponents/AppSkeletonLoader/AppSkeletonLoader";
import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import NoRecordsFound from "../../UiComponents/NoRecordsFound/NoRecordsFound";
import { TextTitle, WrapEmail } from "./EmailBroadcastSummaryStyles";

interface Props {
  broadcastStats: any;
  loading?: boolean;
  heading?: any;
  color?: any;
  tooltiptext?: any;
  chartText?: any;
  totalText?: any;
  downloadBtnPosition?: any;
}

const Doughnout: React.FC<Props> = ({
  broadcastStats,
  loading,
  heading,
  color,
  tooltiptext,
  chartText,
  totalText,
  downloadBtnPosition,
}) => {
  let deliverability_Rate = broadcastStats || 0;

  const [options, setOptions] = React.useState<any>({
    colors: [color],
    chart: {
      height: 350,
      type: "radialBar",
      toolbar: {
        show: true,

        offsetX: -10,
        offsetY: downloadBtnPosition && downloadBtnPosition,
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(dashdateRange: any) {
              return new Date(dashdateRange).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
      },
    },
    chartOptions: {
      colors: ["red"],
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 225,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 1,
            left: 0,
            blur: 2,
            opacity: 0.1,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0,
          dropShadow: {
            enabled: true,
            top: 1,
            left: 0,
            blur: 2,
            opacity: 0.1,
          },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: false,
            color: "#888",
            fontSize: "17px",
          },
          value: {
            formatter: function (val: string) {
              return val + " %";
            },
            color: "#111",
            fontSize: "32px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        gradientToColors: [color],
        colors: [color],
        stops: [100, 100],
      },
    },
    legend: {
      show: false,
    },
    title: {
      text: "",
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "18px",
        fontWeight: "500",
        fontFamily: "Rubik",
        color: "#101010",
      },
    },
    stroke: {
      lineCap: "round",
    },
  });


 

  return loading ? (
    <AppSkeletonLoader />
  ) : (
    <WrapEmail>
      <div id="card" style={{height: "inherit"}}>
          <div id="chart" style={{ height: "inherit" }}>
          <ApexChatWrapper>
            <Box className="heading__tooltip">
              <TextTitle>
                  <Typography className="heading">{heading}</Typography>
              </TextTitle>
              <AppToolTip
                tooltipProps={{
                  title: tooltiptext,
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<AppMaterialIcons iconName="HelpOutline" />}
              />
            </Box>
              
              <Box className="data-body" height={"calc(100% - 61px);"}>

                {
                  (deliverability_Rate ?? 0) === 0 ? (
                    <NoRecordsFound
                      imageWidth={"200px"}
                      headerTextSize={"19px"}
                      imageSrc={NoRecordImage}
                    />
                  ) : (
                      <Box className="data-content_wrapper" >
                            <ReactApexChart
                            options={options}
                            series={[deliverability_Rate]}
                            type="radialBar"
                            height={350}
                            />

                            <div className="chart_text_div">
                              <p className="chart_text">
                                {chartText}
                              </p>
                          <p className="chartTotalCount">
                            {totalText}
                          </p>
                            </div>
                        </Box> 
                  )
                }

                

                
              </Box>

          </ApexChatWrapper>
        </div>
      </div>
    </WrapEmail>
  );
};

export default Doughnout;

export const ApexChatWrapper = styled.div`
  && {
    height: inherit;
    // border: 1px solid red;

    .data-body {
      height: calc(100% - 61px);
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: center;
      .data-content_wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .apexcharts-toolbar {
      .exportCSV {
        border: 1px solid red !important;
        display: none !important;
      }
    }
    width: 100%;
    .heading__tooltip {
      height: 61px;
      padding: 16px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #f3f3f3;
      z-index: 1;
      .heading {
        font-size: 16px;
        font-weight: 500;
      }
      .MuiSvgIcon-root {
        font-size: 16px;
      }
    }
  }
`;
