import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as SMSIcon } from "../../../assets/images/icons/sms-summary-icon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/icons/email-summary-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../../assets/images/icons/whatsapp-summary-icon.svg";
import { theme } from "../../../Customization/Theme";

const BillingSummary = () => {
  return (
    <BillingSummaryWrapper>
      <ListBox to="/subscription/billing-summary/sms">
        <IconWrapper>
          <SMSIcon />
        </IconWrapper>
        <h6>SMS</h6>
      </ListBox>
      <ListBox to="/subscription/billing-summary/sms">
        <IconWrapper>
          <EmailIcon />
        </IconWrapper>
        <h6>Email</h6>
      </ListBox>
      <ListBox to="/subscription/billing-summary/whatsapp">
        <IconWrapper>
          <WhatsappIcon />
        </IconWrapper>
        <h6>WhatsApp</h6>
      </ListBox>
    </BillingSummaryWrapper>
  );
};

export default BillingSummary;

const BillingSummaryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
`;

const ListBox = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 230px;
  width: 230px;
  gap: 38px;
  border: 1px solid rgba(126, 131, 146, 0.2);
  border-radius: 8px;
  background: ${theme.palette.default.white};
  color: ${theme.palette.default.black};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  * {
    pointer-events: none;
  }

  h6 {
    font-size: 16px;
    font-weight: normal;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8e8ff;
  width: 80px;
  height: 80px;
  border-radius: 100%;
`;
