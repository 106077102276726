import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import styled from "styled-components";
import NoRecordImage from "../../../../../../assets/images/norecordsanalytics.svg";
import { Wrappertable } from "../Styles";
import NoRecordsFound from "../../../../../UiComponents/NoRecordsFound/NoRecordsFound";
interface Props {
    broadcastDetails?: any;
}

const BroadcastTemplateTableInfo: React.FC<Props> = ({ broadcastDetails }) => {

    const hasData = (
        broadcastDetails &&
        (broadcastDetails.subject || broadcastDetails.from_email || broadcastDetails.from_name !== null && broadcastDetails.from_name !== 0)
    ) ? true : false;

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(() => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: "#ffffff",
            color: 'rgba(0, 0, 0, 0.87)',
            boxShadow: "1px 1px 1px 1px rgba(99, 99, 99, 0.2)",
            fontSize: 11,

        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "white",
            "&::before": {
                backgroundColor: "white",
                border: "1px solid rgba(99, 99, 99, 0.2)",
            }
        },
    }));

    return (
        <Wrappertable>
            {
                hasData ? (
                    <>
                        <div className="roww_table">
                            <p className="text">Sender Number</p>
                            <LightTooltip title={broadcastDetails?.sender_number} arrow placement="top-start">
                                <p className="value">{broadcastDetails?.sender_number || "N/A"}</p>
                            </LightTooltip>
                        </div>
                        <hr className="horizontal_line" />
                        <div className="roww_table">
                            <p className="text">Template Name</p>
                            <LightTooltip title={broadcastDetails?.template_name} arrow placement="top-start">
                                <p className="value">{broadcastDetails?.template_name    || "N/A"}</p>
                            </LightTooltip>
                        </div>
                        <hr className="horizontal_line" />
                        <div className="roww_table">
                            <p className="text">Template Type</p>
                            <p className="value">{broadcastDetails?.template_type === 0 ? "N/A" : broadcastDetails?.template_type}</p>
                        </div>
                    </>
                ) : (
                    <NoRecordsFound
                        imageSrc={NoRecordImage}
                        imageWidth={"180px"}
                        headerTextSize={"19px"}
                    />
                )
            }
        </Wrappertable>
    );
};

export default BroadcastTemplateTableInfo;
