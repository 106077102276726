import styled from "styled-components";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface AppInputFieldProps {
  label?: string;
  name: string;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormTimePicker: React.FC<AppInputFieldProps> = ({
  label,
  name,
  children,
  ...otherProps
}) => {
  const {
    // setFieldTouched,
    // handleChange,
    errors,
    touched,
    // getFieldMeta,
    // initialValues,
    // setFieldValue,
    values,
   
  } = useFormikContext<any>();

  // const [field, meta, helpers] = useField<any>(name);
  // const { value, error } = meta;
  // const { setValue, setTouched, setError } = helpers;
  

  return (
    <Div>
      <Label>{label}</Label>
      <Input
        {...otherProps}
        name={name}
        // onBlur={() => setFieldTouched(name)}
        // onChange={(e:any) => setFieldValue(name, e.target.value)}
        // value={values[name]}
        style={{
          border:
            errors[name] && touched[name]
              ? `2px solid ${theme.palette.default.error}`
              : `2px solid transparent`,
        }}
      />
      {/* <AppErrorMessage error={errors[name]} visible={touched[name]} /> */}
      <ErrorMessage component="div" name={name} />
    </Div>
  );
};

export default AppFormTimePicker;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.7rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
  }
`;
const Input = styled(Field)<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    /* outline: 1px solid ${theme.palette.default.border} !important; */
  }
`;
