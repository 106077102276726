import http from "../../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class BusinessHoursNetworkService {

    // Get Workspace
    static workspaceConfigData() {
        return http.get(`${consoleBaseUrl}${Endpoints.WORKSPACE_CONFIG}`);
    }

    // Update Workspace
    static UpdateWorkspaceData(data: any) {
        const { payload } = data;
        return http.put(`${consoleBaseUrl}${Endpoints.WORKSPACE_CONFIG}`, payload);
    }

}

// Business Hours Endpoint
class Endpoints {
    static WORKSPACE_CONFIG = "/workspace/config"
}