import * as Yup from "yup";
export const newURLRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
export const LandingPageBotSchema = Yup.object().shape({
    landing_page_heading: Yup.string()
        .min(2)
        .max(100)
        .required("Heading For Landing Page Is Required")
        .label("Landing Page Heading"),
    landing_page_sub_heading: Yup.string()
        .min(2)
        .max(225)
        .required("Sub Heading For Landing Page Is Required")
        .label("Landing Page Sub Heading"),
    landing_page_button_text: Yup.string()
        .min(2)
        .max(24)
        .label("Landing Page Sub Heading"),
    landing_page_button_link: Yup.string()
        .matches(newURLRegex, "Invalid url"),  
});