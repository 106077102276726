import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Domainwrapper } from '../DomainStyles'
import { AppForm, AppFormField, AppFormRadioInput, AppFormTextArea } from '../../../../../AppComponents/Forms'
import { SettingPageMainBoxTitle } from '../../../../../Customization/CommonElements'
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, IconButton, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DomainVerifation from '../../../../../../src/assets/images/DomainVerifation.png'
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from '../../../../../AppComponents/AppMaterialIcons';
import AppButton from '../../../../../AppComponents/AppButton';
import { LoaderComponentProps } from '../../../../../AppComponents/Loader';
import { EmailDomainOptions } from '../../../../../Models/defaultSelectOptions';
import { useFormikContext } from 'formik';
import { domainVerifyError, emailDomainSteps } from '../../../../../Store/Slices/email/email.selectors';
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from '../../../../../Store';
import { resetError } from '../../../../../Store/Slices/email/email.slice';
import { ReactComponent as GreenCheck } from "../../../../../assets/images/green-check.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/copy.svg";
import { ReactComponent as SPFImage } from "../../../../../assets/images/spf-image.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/images/infoicon.svg";
import { useNotification } from '../../../../../Hooks/useNotification/useNotification';
import { theme } from '../../../../../Customization/Theme';
import styled from 'styled-components';

interface Props {
    formStep: number;
}
const RenderFormStep4: React.FC<Props> = ({ formStep }) => {
    const activeStep = useSelector(emailDomainSteps)
    const dispatch = useAppDispatch()
    const domainVerifyErroList = useAppSelector(domainVerifyError)
    const [expanded, setExpanded] = React.useState<string | false>('panel1');
    const [copiedMessage, setCopiedMessage] = React.useState('');
    const { values, setFieldValue, errors, setFieldError } = useFormikContext<any>();
    const { showAlert } = useNotification();


    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };
    const handleNavigate = () => {
        setFieldValue("view_options", [])
    }
    // const handleCopy = (e: any, text: any) => {
    //     var textArea = document.createElement("textarea");
    //     textArea.value = text;

    //     document.body.appendChild(textArea);

    //     textArea.select();
    //     e.target.parentElement.nextSibling.style.display = "inline";
    //     document.execCommand("Copy");
    //     setTimeout(function () {
    //         e.target.parentElement.nextSibling.style.display = "none";
    //     }, 1000);
    //     textArea.remove();
    // }

    const handleCopy = (e: any, textToCopy: any) => {
        navigator.clipboard.writeText(textToCopy).then(() => {
          setCopiedMessage('Text is copied');
          showAlert(`Text is copied`, "success");
          
          setTimeout(() => {
            setCopiedMessage('');
          }, 2000); // Clear the message after 2 seconds
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      };

    useEffect(() => {
        if (domainVerifyErroList && !errors?.domain) {
            setFieldError('spfNamefound', domainVerifyErroList)
            dispatch(resetError(''))
        }
    }, [domainVerifyErroList])


    const domainName = "< Domain Name >"

    return (
        <ThirdStepWrapper>

            <div className="mainDivSecond" >
                    <Typography fontSize={"20px"} mb={3} fontWeight={500}>Step 3</Typography>
                    <Typography className='text-grey'>
                        <span> In Your Domain Settings,You Need To Add Record For <span className='text-dark'> {domainName}</span></span>
                    </Typography>
            </div>
            <div className="third-step_contemt">
                <Typography mb={1} className='text-grey'>Required</Typography>
                <Accordion disableGutters expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
                    sx={{
                        "& .MuiCollapse-root": {
                            boxShadow: "rgba(126, 131, 146, 0.24) 0px 0px 10px 0px"
                        },
                        boxShadow: theme.palette.default.boxShadow,
                        border: "1px solid rgba(221, 228, 255, 1)",
                        marginBottom: "32px",
                    }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ backgroundColor: "#E9EDFF" }}
                    >
                        <Typography fontSize={"14px"}>SPF</Typography>
                    </AccordionSummary>
                    <AccordionDetails  sx={{
                        padding: 0
                    }}>
                        {
                            values.view_options == "" &&
                            <Box p={3}>
                                <img className='help-image' src={DomainVerifation} alt='' />
                                <Typography fontSize={"14px"} mt={"20px"} className="dkim-text">Check if you already have an SPF record in your DNS.It shoukd be a TXT-type <br /> record with SPF variables(starts with"v=spf1").
                                    This check will determine your next steps.</Typography>
                                <div className="radio">
                                    <AppFormRadioInput
                                        label=""
                                        name="view_options"
                                        Options={EmailDomainOptions}
                                        divStyle={{ display: "inline-block" }}
                                    // sendValuesToParent={(values: any) => sendValuesToParent(values)}
                                    />
                                </div>
                            </Box>
                        }
                        {
                            values.view_options === "notFound" &&
                            <Box p={3} >
                                <Box display={"flex"} alignItems={"center"} gap={1}>
                                    <Box className="back-icon">
                                        <IconButton onClick={handleNavigate}>
                                            <AppMaterialIcons
                                                iconName={"ArrowBackIosRounded"}
                                                style={{ fontSize: "0.8rem" }}
                                                />
                                        </IconButton>
                                    </Box>
                                    <Typography className='back-btn-text' fontSize={"14px"}>
                                        Back
                                    </Typography>
                                </Box>
                                <Typography mt={3} className="text-grey">Ok! Follow the instruction described below.</Typography>
                                <Box className='text-grey' my={3}>
                                    <GreenCheck />  <span>Edit the <span className='text-dark'>TXT</span> record and merge your existing <span className='text-dark'>Value</span> with variable display below.</span>  
                                </Box>
                                <Box className="text-grey" mb={3}>
                                    <span style={{textAlign: "center"}}>
                                        For example, if your domain already has the record:<br />
                                        <span>v=spf1 a mx include:_spf.google.com ~all</span>
                                    </span>
                                </Box>


                                <Typography className='text-grey'>You need to add to this variable:</Typography>
                                <div style={{ display: "flex", alignItems: "flex-end" }}>
                                    <AppFormField
                                        divStyle={{ width: '320px' }}
                                        name="spfNameNotfound"
                                        label=""
                                        // value={values} 
                                        readOnly
                                    />
                                    <IconButton 
                                    sx={{
                                        marginBottom: "11px",
                                        marginLeft: "8px"
                                    }}
                                    onClick={(e: any) => handleCopy(e, "include:_spf.elasticemail.com")} style={{  }}>
                                        <CopyIcon />
                                    </IconButton>
                                    <div id='copyone' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }}>Copied</div>

                                </div>
                                <Typography mt={2} className="text-grey">So, created variable will look like this:</Typography>
                                <Typography ml={2} mt={2} className="text-grey" sx={{opacity: 0.7}}>v=spf1 a mx include:_spf.elasticemail.com include:_spf.google.com ~all</Typography>
                                <Box mb={1} className="note" style={{ display: "flex" }}>
                                    <InfoIcon />
                                    <span>
                                        If you followed all the instructions above and still have a problem with<br />
                                        SPF verification, here is a handy article about <a className='link-color' href='#'>common SPF errors.</a>
                                    </span>
                                </Box>
                            </Box>
                        }
                        {
                            values.view_options === "found" &&
                            <Box p={3} >
                                <Box display={"flex"} alignItems={"center"} gap={1}>
                                    <Box className="back-icon">
                                        <IconButton onClick={handleNavigate}>
                                            <AppMaterialIcons
                                                iconName={"ArrowBackIosRounded"}
                                                style={{ fontSize: "0.8rem" }}
                                                />
                                        </IconButton>
                                    </Box>
                                    <Typography className='back-btn-text' fontSize={"14px"}>
                                        Back
                                    </Typography>
                                </Box>

                                <Typography mt={3} className="text-grey">Great! Follow the instruction described below.</Typography>


                                <Box className='text-grey' my={3}>
                                    <GreenCheck />  <span>Create a new <span className='text-dark'>TXT</span> record.</span>  
                                </Box>

                                <Box className='text-grey' mt={3}>
                                    <GreenCheck />  <span>Fill in the Name (Host) field. Depending on the provider,<br />you probably have to follow one of these options:</span>  
                                </Box>

                                <Box className='text-grey' mt={1.2}>
                                    <ul className="lsiting">
                                        <li>Leave it empty</li>
                                        <li> Fill it with <span className='text-dark'>@</span></li>
                                        <li> Enter your domain name (geta.ai)</li>
                                    </ul>
                                </Box>

                                <Box className='text-grey' mt={3}>
                                    <GreenCheck />  <span>Fill in the Value of this record with variable displayed below.</span>  
                                </Box>

                                <Box className='text-grey' mt={3} mb={3}>
                                    For example, if your domain already has the record:v=spf1 a mx include:_spf.google.com ~all
                                </Box>


                                <div style={{ display: "flex", alignItems: "flex-end"  }}>
                                    <AppFormField
                                        divStyle={{ width: '320px' }}
                                        name="spfNamefound"
                                        label="Value:"
                                        // value={values} 
                                        readOnly
                                    />
                                    <Box>
                                        <IconButton sx={{
                                            marginBottom: "11px",
                                            marginLeft: "8px"
                                            }} onClick={(e: any) => handleCopy(e, "v=spf1 a mx include:_spf.elasticemail.com ~all")} ><CopyIcon /></IconButton>
                                    </Box>
                                    <div id='copyone' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }}>Copied</div>

                                </div>

                            </Box>
                        }
                    </AccordionDetails>
                </Accordion >



                
                <Typography mb={1} className='text-grey'>Recommended</Typography>
                <Accordion disableGutters expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{
                    "& .MuiCollapse-root": {
                        boxShadow: theme.palette.default.boxShadow,
                        border: "1px solid rgba(221, 228, 255, 1)",
                        marginBottom: "32px",
                    }
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ backgroundColor: "#E9EDFF", cursor: "pointer" }}

                    >
                        <Typography fontSize={"14px"}>DKIM</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{
                        padding: 0
                    }}>
                        <Box p={3} mb={4}>
                            <div className="dkim-text">
                                <GreenCheck /> <span>Create a new <span className="text-dark">TXT</span> record.</span>  
                            </div>

                            <div className="dkim-text">
                                <GreenCheck /> <span>Fill in the <span className="text-dark">Name (Host)</span> field. Depending on the provider,<br />
                                you probably have to follow one of these options.</span>  
                            </div>

                            <div className="dkim-text"><GreenCheck /> <span>Fill in the <span className="text-dark">Value</span> of this record with variable displayed below.</span>  </div>

                            <div style={{ display: "flex" }}>
                                <AppFormField
                                    divStyle={{ paddingTop: "15px", width: '320px' }}
                                    name="dkimNameInput"
                                    label="Name:"
                                    // value={values} 
                                    readOnly
                                />
                                <Box>
                                    <IconButton style={{ marginTop: "38px", marginLeft: "10px" }} onClick={(e: any) => handleCopy(e, "api._domainkey")} >
                                        <CopyIcon />
                                    </IconButton>
                                </Box>
                                <div id='copyone' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }}>Copied</div>

                            </div>
                            <div style={{ display: "flex" }}>
                                <AppFormTextArea
                                    divStyle={{ paddingTop: "15px", width: '520px' }}
                                    label="Value"
                                    name="dkimNameTextArea"
                                    value={values} readOnly

                                />
                                <Box display={"flex"} alignItems={"center"}>
                                    <IconButton style={{ 
                                        margin: "28px 0px 0px 20px"
                                     }} onClick={(e: any) => handleCopy(e, "k=rsa;t=s;p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbmGbQMzYeMvxwtNQoXN0waGYaciuKx8mtMh5czguT4EZlJXuCt6V+l56mmt3t68FEX5JJ0q4ijG71BGoFRkl87uJi7LrQt1ZZmZCvrEII0YO4mp8sDLXC8g1aUAoi8TJgxq2MJqCaMyj5kAm3Fdy2tzftPCV/lbdiJqmBnWKjtwIDAQAB")} >
                                        <CopyIcon />
                                    </IconButton>
                                </Box>
                                <Box></Box>
                                <div id='' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }}>{copiedMessage}</div>

                            </div>
                        </Box>
                    </AccordionDetails>
                </Accordion>



                <SettingPageMainBoxTitle style={{ opacity: 0.7, margin: "1rem 0 0 1rem" }}>
                </SettingPageMainBoxTitle>
                <Accordion disableGutters expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{
                    "& .MuiCollapse-root": {
                        boxShadow: theme.palette.default.boxShadow,
                        border: "1px solid rgba(221, 228, 255, 1)",
                        marginBottom: "24px",
                    }
                }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                        style={{ backgroundColor: "#E9EDFF" }}

                    >
                        <Typography fontSize={"14px"} >CNAME</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box p={3} mb={4}>

                                {/* <div className="dkim-text">
                                    <GreenCheck /> <span>Create a new <span className="text-dark">TXT</span> record.</span>  
                                </div> */}


                                <div className="dkim-text">
                                    <GreenCheck style={{marginTop: "2px"}} /> <span>Create a new <span className="text-dark">CNAME</span> record.</span> 
                                </div>

                                <div className="dkim-text">
                                    <GreenCheck style={{marginTop: "2px"}} /> <span>Fill in the <span className="text-dark">Name (Host)</span> field of this record with variable displayed below,<br />
                                    you probably have to follow one of these options.</span>
                                </div>


                                <div className="dkim-text">
                                    <GreenCheck style={{marginTop: "2px"}} /> <span>Fill in the <span className="text-dark">Value</span> of this record with variable displayed below.</span></div>

                                <div style={{ display: "flex" }}>
                                    <AppFormField
                                        divStyle={{ paddingTop: "15px", width: '320px' }}
                                        name="CnameNameInput"
                                        label="Name"
                                        // value={values} 
                                        readOnly
                                    />
                                    <Box>
                                        <IconButton style={{ marginTop: "38px", marginLeft: "10px" }} onClick={(e: any) => handleCopy(e, "tracking")} ><CopyIcon /></IconButton>
                                    </Box>
                                    <div id='copyone' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }}>Copied</div>

                                </div>
                                <div style={{ display: "flex" }}>
                                    <AppFormField
                                        divStyle={{ paddingTop: "15px", width: '520px' }}
                                        label="Value"
                                        name="CnameNameTextArea"
                                        // value={values} 
                                        readOnly

                                    />
                                    <Box>
                                        <IconButton style={{ marginTop: "38px", marginLeft: "10px" }} onClick={(e: any) => handleCopy(e, "api.elasticemail.com")} ><CopyIcon /></IconButton>
                                    </Box>
                                    <div id='copyone' style={{ backgroundColor: " #000000df", borderRadius: " 4px", color: "#fff", display: "none", height: "20px", marginTop: "50px" }}>Copied</div>

                                </div>
                                <Typography fontSize={"14px"} mt={2} >When you are ready, click the 'Verify record' button.</Typography>

                                </Box>
                    </AccordionDetails>
                </Accordion>

            </div>




        </ThirdStepWrapper >
    )
}

export default RenderFormStep4


const ThirdStepWrapper = styled.div`
&& {

    .back-icon{
        svg {
            fill: ${theme.palette.default.blue}; 
        }
    }

    .back-btn-text {
        font-size: 14px;
        color: ${theme.palette.default.blue};
        font-weight: 400;
    }

}
`;

