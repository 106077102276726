import styled from "styled-components";
import { theme } from "../../../Customization/Theme";


export const WrapperDeviceTable = styled.div`
 && {
    height: calc(100% - 61px);
    .table-container{
        overflow: auto;
        // padding: 0 1rem 1rem 1rem;
        background: ${theme.palette.default.white} !important;
        z-index: 1;
    }

    .table-head{
        position: sticky;
        top: 0;
        z-index: 1;
        background: #fff;
    }

    .wraprow{
        width:100%;
    }

    .device-type-name{
        width: 65%;
    }
    #arrow-icon{
        width: 5%;
    }

    .table-cell{
        width:152px;
        text-align:center;
    }

    .device-cell{
        width:  70.7%;
        padding-left: 68px;
        color: #7E8392;
        height: 60px;
    }

    .device-count{
        width:10.5%;
        color: #7E8392;
         height: 60px;
    }

    .accordian-cell{
        padding-bottom: 0;
         padding-top: 0;
    }

    #device-count-unsubscribe{
        width:10.5%;
        color: #7E8392;
         height: 60px;
         padding-right:3px;
    }

    @media screen and (min-width: 1440px) and (max-width: 1550px) {
        .device-cell{
            width:  70.7%;
            padding-left: 61px;
            color: #7E8392;
            height: 60px;
        }
}

    

 }
`;

export const WrapDeviceTable = styled.div`
 & {
    width: 100%;
      height: 100%;
    //   background-color: white;
      border-radius:10px;
      .heading__tooltip {
        
      }
 }
`;

