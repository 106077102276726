import styled from "styled-components";
import { device } from "../../Customization/BreakPoints";
import { theme } from "../../Customization/Theme";

interface Props {
  children?: React.ReactNode;
  label?: any;
  [otherProps: string]: any;
}

const AppMultiColumnsFields: React.FC<Props> = ({
  children,
  label,
  otherProps,
}) => {
  return (
    <Div>
      <label className="mutliLabel">{label}</label>
      <div className="columnField formDiv">{children}</div>
    </Div>
  );
};

export default AppMultiColumnsFields;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0rem;
    

    /* background-color: green; */

    .mutliLabel {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.darkGrey};
    }

    .columnField {
      display: flex;
      gap: 1.5rem;
      flex-wrap: none;

      @media ${device.laptopL} {
        /* flex-wrap: wrap; */
      }
    }

    .formDiv {
      display: flex;
    }

    i {
      margin-top: 0.7rem;
    }
  }
`;
