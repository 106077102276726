import Matercolor from "matercolors";
let shorthandColorHexRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
let colorHexRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;

export const isColorLight = (hexCode: string): boolean => {
  // Convert hex to RGB values
  const r = parseInt(hexCode.slice(1, 3), 16);
  const g = parseInt(hexCode.slice(3, 5), 16);
  const b = parseInt(hexCode.slice(5, 7), 16);

  // Calculate relative luminance using standard formula
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // You can adjust this threshold based on your definition of a light color
  const lightThreshold = 128;

  // Return true if luminance is above the threshold, indicating a light color
  return luminance > lightThreshold;
};

var colourIsLight = function (r: number, g: number, b: number) {
  // Counting the perceptive luminance
  // human eye favors green color...
  var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  return a < 0.5;
};

export class ColorUtils {
  static getTextColorForBackgroundRGB(r: number, g: number, b: number) {
    return colourIsLight(r, g, b) ? "#282828" : "#ffffff";
  }
  static getTextColorForBackgroundHex(hexCode: string) {
    const result = ColorUtils.hexToRgb(hexCode);
    if (result) {
      const { r, g, b } = result;
      return colourIsLight(r, g, b) ? "#282828" : "#ffffff";
    }
    throw Error("HexCode Incorrect");
  }
  static hexToRgb(hex: string) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")

    hex = hex.replace(shorthandColorHexRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = colorHexRegex.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  }

  static getContrastTextColorHex = (hexcolor: string) => {
    // If a leading # is provided, remove it
    if (hexcolor?.slice(0, 1) === "#") {
      hexcolor = hexcolor?.slice(1);
    }

    // Convert to RGB value
    var r = parseInt(hexcolor?.substr(0, 2), 16);
    var g = parseInt(hexcolor?.substr(2, 2), 16);
    var b = parseInt(hexcolor?.substr(4, 2), 16);

    // Get YIQ ratio
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;

    // Check contrast
    return yiq >= 128 ? "#282828" : "#ffffff";
  };

  static getMatchingBackgroundColorHex = (hexcolor: string) => {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1);
    }

    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);

    var a = 1 - (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return a < 0.5 ? "#FAFAFA" : "#424242";
  };

  public static convertHexToRgb(hexcode: string): { r: number; g: number; b: number } {
    const r = parseInt(hexcode?.substring(1, 3), 16);
    const g = parseInt(hexcode?.substring(3, 5), 16);
    const b = parseInt(hexcode?.substring(5, 7), 16);

    return { r, g, b };
  }

  public static hexToRgba = (color: string, opacity: number = 1) => {
    const hexColor = color?.replace(/^#/, "");
    const r = parseInt(hexColor?.slice(0, 2), 16);
    const g = parseInt(hexColor?.slice(2, 4), 16);
    const b = parseInt(hexColor?.slice(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  public static getContrastTextColor(hexcode: string, op: number = 1): string {
    // Convert the hexcode to a RGB value.
    const rgb = this.convertHexToRgb(hexcode);

    // Calculate the brightness of the color.
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;

    // Return the contrast text color, based on the brightness of the color.
    return brightness > 128
      ? this.hexToRgba("#000000", op)
      : this.hexToRgba("#ffffff", op);
  }



}

export const generateWidgetColorPallete = (
  primaryColor: string,
  buttonColor?: string,
  hoverColor?: string
) => {
  let scheme = new Matercolor(primaryColor);

  let tempPrimaryLight = scheme.palette.primary["200"];
  let tempPrimary = scheme.palette.primary["500"];
  let tempPrimaryLightest = scheme.palette.primary["50"];
  let tempPrimaryDark = scheme.palette.primary["700"];
  let tempPrimaryDarkest = scheme.palette.primary["900"];

  let colorScheme = {
    header: {
      background: primaryColor,
      text: ColorUtils.getTextColorForBackgroundHex(primaryColor),
    },
    chat_input: {
      background: primaryColor,
      text: ColorUtils.getTextColorForBackgroundHex(primaryColor),
    },
    chat_wall: {
      background: tempPrimaryLightest,
      text: primaryColor,
    },
    bot_message: {
      background: "#fff",
      text: "#000",
    },
    button: {
      background: buttonColor ? buttonColor : primaryColor,
      text: buttonColor
        ? ColorUtils.getTextColorForBackgroundHex(buttonColor)
        : ColorUtils.getTextColorForBackgroundHex(primaryColor),
      border: primaryColor,

      hover_text: hoverColor
        ? ColorUtils.getTextColorForBackgroundHex(hoverColor)
        : ColorUtils.getTextColorForBackgroundHex(primaryColor),

      hover_background: hoverColor ? hoverColor : primaryColor,
      hover_border: primaryColor,
    },
    scheme: {
      primaryLight: tempPrimaryLight,
      primary: tempPrimary,
      primaryLightest: tempPrimaryLightest,
      primaryDark: tempPrimaryDark,
      primaryDarkest: tempPrimaryDarkest,
    },
  };

  return colorScheme;
};
