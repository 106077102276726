import React from 'react'
import useRolesPermissionsAccess from '../../../Hooks/useRolesPermissionsAccess';
import { AppSubmitButton } from '../../Forms';


interface AppButtonProps {
    variant?: string;
    startIcon?: string;
    endIcon?: string;
    title: any;
    iconColor?: any;
    iconHoverColor?: any;
    iconSize?: any;
    width?: any;
    [otherProps: string]: any;
    children?: React.ReactNode;
    specificPermissionKey?: string;
    autoFocus?: boolean;
    onClick?: any;
    moduleId: string; // it is compulsory to pass moduleId
}

const ProtectedAppSubmitButton: React.FC<AppButtonProps> = ({
    children,
    moduleId,
    specificPermissionKey,
    title,
    ...otherProps
}) => {
    const { isValidAccess, isContentReady } = useRolesPermissionsAccess(moduleId);

    // React.useEffect(() => { }, [isValidAccess]);

    if (!isContentReady) return null; // using this approach to avoid flickering of 404 page

    if (!moduleId) return null;

    if (!isValidAccess) return null;

    return <AppSubmitButton title={title}  {...otherProps}>{children}</AppSubmitButton>;
};

export default ProtectedAppSubmitButton;