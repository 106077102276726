import { AppToolTip } from "../../UiComponents/AppToolTip/AppToolTip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { DashboardActions } from "../../../Store/Slices/DashboardRedux/dashboard.actions";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { useEffect, useState } from "react";
import { BotList, botDateRange } from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import { TotalFailedWrapper } from "./BotStyle";
import FailedLogo from "../Dash_Assests/ansfailedtodeliverbybot.svg";
import { format } from "date-fns";
import { SkeletonCard, SkeletonCircleIconCard } from "../SkeletonLoader.tsx/SkeletonCard";
import { getRoundOffNumbers } from "../../../Utils";

interface Props { }

const TotalConversationFailed: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const dashdateRange: any = useAppSelector(botDateRange)
  const botList = useAppSelector(BotList)
  const [answerList, setAnswerList] = useState<any>([])
  const [loading, setLoading] = useState(false);


  // Total Failed Answer API call
  const getFailedAnswersList = () => {
    setLoading(true)

    let payload5: any = {
      from_date: format(dashdateRange.startDate, "yyyy-MM-dd"),
      end_date: format(dashdateRange.endDate, "yyyy-MM-dd"),
    };
    if (botList.length > 0) {
      payload5 = {
        ...payload5,
        bot_id: botList,
      }
    }
    dispatch(DashboardActions.getFailedAnswer(payload5))
      .then((res) => {

        setAnswerList(res.payload.data || []);
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false)

      });
  };

  useEffect(() => {
    if (dashdateRange?.startDate) {
      getFailedAnswersList()

    }
  }, [dashdateRange, botList]);
  return (
    <TotalFailedWrapper>


      <div className="FailedWrap">
        <p className="failed_text">
          Total Answers Failed
          <br />
          To Deliver By BOT
          <AppToolTip
            tooltipProps={{
              title:
                "Bot Conversations Distribution Metrics",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={
              <InfoOutlinedIcon
                className="div_tooltip"
              />
            }
          />
        </p>
        <div style={{display:"flex"}}>
        <div className="failedInformation">
          <p className="failedCount">
            {loading ? <SkeletonCard /> : getRoundOffNumbers(answerList.failed_conversations_by_bot) || 0}
          </p>

        </div>

        {loading ? <SkeletonCircleIconCard /> :
          <div className="failedImage">
            <img src={FailedLogo} alt="Not Found" />
          </div>}
      </div>
        </div>
    </TotalFailedWrapper>

  )
}
export default TotalConversationFailed;