import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Menu, MenuItem, IconButton, Popover, TextField, FormControl, InputLabel, Select, Checkbox, ListItemText, OutlinedInput, SwipeableDrawer, Drawer, Tooltip } from "@mui/material";
import { SaveAs } from '@mui/icons-material';
import { AddScreenModel, FlowScreenHeader, WhatsappFlowWapper } from "../Utils/style";
import AppButton from "../Components/Button";
import { HocBackdropLoader, HocBackdropLoaderProps } from "../HOC/HocBackdropLoader";
import { FlowBuilderActions } from '../Store/FlowBuilder.actions';
import { useAppDispatch, useAppSelector } from '../../Store';
import { convertDateAndTimeToIST, mapNamesToIds } from '../Utils/common';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
import AppModel from '../Components/Model';
import AppInput from '../Components/Input';
import { useNotification } from '../../Hooks/useNotification/useNotification';
import Nodata from '../Assets/nodata.svg';
import { setContactMapping, setContentData, setFlowBuilderList, setFormName, setScreenId, setScreenType } from '../Store/FlowBuilder.slice';
import { ArrowBack, ArrowForward, Refresh, Close } from '@mui/icons-material';
import FlowBuilderPreview from './flowBuilderPreview';
import SyncIcon from '@mui/icons-material/Sync'
import WarningIcon from '@material-ui/icons/Warning';
import { useAuthStore } from '../../Store/Slices/AuthSlice';
import AppPaginationComponent from '../../AppComponents/Pagination/Pagination';

interface Props extends HocBackdropLoaderProps { }
const statuses = [
    { label: "Draft", value: "DRAFT" },
    { label: "Published", value: "PUBLISHED" },
    { label: "Deprecated", value: "DEPRECATED" },
    { label: "Blocked", value: "BLOCKED" },
    { label: "Throttled", value: "THROTTLED" }
]
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
            marginBottom: 15
        },
    },
};
const FlowBuilderList: React.FC<Props> = ({ setLoading }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    let parentScreen: any = "parentScreen"
    let childScreen: any = "childScreen"
    const { showAlert } = useNotification();
    const [flowList, setFlowList] = useState<any>({
        data: []
    })
    const [isShowing, setIsShowing] = useState(false);
    const [isClonepop, setIsClonepop] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteFlowId, setDeleteFlowId] = useState<any>("");
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>(["All"]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [rendewrSearchTerm, setRendewrSearchTerm] = useState<string>('');
    const [categorieListData, setCategorieListData] = useState<any>([]);
    const [categories, setCategories] = useState<string[]>(["Select Categories"]);
    const [heading, setHeading] = useState<string>('');
    const [body, setBody] = useState<string>('');
    const { userConfig }: any = useAppSelector(useAuthStore);
    const [buttonText, setButtonText] = useState<string>('');
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [screenName, setScreenName] = useState<any>('')
    const [allScreens, setAllScreens] = useState<any>({});
    const [selectScreen, setSelectScreen] = useState<any>("");
    const [currentScreenIndex, setCurrentScreenIndex] = useState<any>(0);
    const [showPreview, setShowPreview] = useState<any>(false);
    const [uploadError, setUploadError] = useState(false)
    const [localValues, setLocalValues] = useState<any>({
        itemsPerPage: { label: "25  per page", value: 25 },
        currentPage: 0,
    });
    const handleClick = (event: React.MouseEvent<HTMLElement>, flowData: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(flowData);
    };
    const getFlow = () => {
        if (userConfig?.payload?.whatasapp_config?.business_verified) {
            if (selectedStatuses || searchTerm) {
                setLoadingStatus(true)
                setLoading(true)
                let data = {
                    status: selectedStatuses[0] === "All" ? "" : selectedStatuses,
                    search: searchTerm,
                    limit: localValues?.itemsPerPage?.value,
                    offset: localValues?.currentPage
                }
                dispatch(FlowBuilderActions.getFlows(data))
                    .then((res: any) => {
                        console.log("res----------------------->", res);
                        if (res?.payload?.error) {
                            setLoading(false)
                            setLoadingStatus(false)
                            // setErrorMessage(res?.payload?.error)
                        } else {
                            setFlowList(res?.payload?.data)
                            setLoading(false)
                            setLoadingStatus(false)
                        }
                    })
                    .catch((error: any) => {
                        setLoading(false)
                        setLoadingStatus(false)
                        console.log("res----------------------->", error?.payload?.error);
                    })
            }
        }

    }
    useEffect(() => {
        getFlow()
    }, [selectedStatuses, searchTerm, localValues])
    useEffect(() => {
        dispatch(FlowBuilderActions.whatsappConfig({}))
            .then((res: any) => {
                console.log("res----------------------->", res);
                // if (res?.payload?.error) {
                //     setLoading(false)
                //     setLoadingStatus(false)
                //     // setErrorMessage(res?.payload?.error)
                // } else {
                //     setFlowList(res?.payload?.data)
                //     setLoading(false)
                //     setLoadingStatus(false)
                // }
            })
            .catch((error: any) => {
                setLoading(false)
                setLoadingStatus(false)
                console.log("res----------------------->", error?.payload?.error);
            })
    }, [])
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (userConfig?.payload?.whatasapp_config?.business_verified) {

            dispatch(FlowBuilderActions.getDefaultFlowAndCategories(""))
                .then((res: any) => {
                    console.log("res", res?.payload?.data[0]);
                    setCategorieListData(res?.payload?.data[0]?.categorie_list)
                    // dispatch(setCategorieList(res?.payload?.data[0]?.categorie_list))
                    // dispatch(setDefaultList(res?.payload?.data[0]?.flow_list))
                    // setFlowListDataWithEndPoint(res?.payload?.data[0]?.flow_list?.filter((res: any) => res?.is_endpoint_configured))
                    // setFlowListDataWithOutEndPoint(res?.payload?.data[0]?.flow_list?.filter((res: any) => !res?.is_endpoint_configured))
                    // setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }, [])
    const handleMenuItemClick = (action: string) => {
        if (action === "Delete") {
            handleClose()
            setIsShowing(true)
            setHeading("Are you sure you want to delete this flow?")
            setBody("Once deleted, you won't be able to access this flow again. This cannot be undone.")
            setButtonText("Delete")
        }
        if (action === "Deprecate") {
            handleClose()
            setIsShowing(true)
            setHeading("Are you sure you want to deprecate this flow?")
            setBody("Once deprecated, you won't be able to revert. This cannot be undone.")
            setButtonText("Deprecate")
        }
        if (action === "Clone") {
            setIsClonepop(true)
            handleClose()
            let array: any = []
            categorieListData?.map((res: any) => {
                if (selectedRow?.categories?.includes(res.id)) {
                    array.push(res.name)
                }
            })
            setCategories(array)
            setScreenName(selectedRow?.name + " clone")
        }
    };

    const debouncedSearch = useCallback(
        debounce((searchValue) => {
            setSearchTerm(searchValue);
            setLocalValues({
                ...localValues,
                currentPage: 0,
            });
        }, 800), []
    );

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRendewrSearchTerm(event.target.value)
        debouncedSearch(event.target.value);

    };

    const handleStatusChange = (event: React.ChangeEvent<{ value: any }>) => {
        let value = event.target.value;

        let data = value.filter((res: any) => res !== "All");
        setSelectedStatuses(data?.length === 0 ? ["All"] : data);
    };

    const handleDiscard = () => {
        setIsShowing(false);
        setIsClonepop(false)
    };
    console.log("selectedRow", selectedRow);

    const handleSave = () => {
        // Add save functionality here
        setLoading(true)
        setIsShowing(false);
        if (buttonText === "Delete") {
            dispatch(FlowBuilderActions.deleteFlow(selectedRow?.meta_id))
                .then((res: any) => {
                    console.log("res----------------------->", res);
                    if (res?.payload?.data?.status === "error") {
                        setLoading(false)
                        showAlert(res?.payload?.data?.data || "Error In Deleting", "error");
                        // setErrorMessage(res?.payload?.error)
                    }
                    if (res?.payload?.data?.status === "success") {
                        setLoading(false)
                        handleClose()
                        setIsShowing(false);
                        getFlow()
                        showAlert("Deleted Successfully", "success");

                    }
                })
                .catch((error: any) => {
                    setLoading(false)
                    console.log("res----------------------->", error?.payload?.error);
                })
        } else {
            dispatch(FlowBuilderActions.deprecateFlow(selectedRow?.meta_id))
                .then((res: any) => {
                    console.log("res----------------------->", res);
                    if (res?.payload?.data?.status === "error") {
                        setLoading(false)
                        showAlert(res?.payload?.data?.data || "Error In Deleting", "error");
                        // setErrorMessage(res?.payload?.error)
                    }
                    if (res?.payload?.data?.status === "success") {
                        setLoading(false)
                        handleClose()
                        setIsShowing(false);
                        getFlow()
                        showAlert("Deprecated Successfully", "success");

                    }
                })
                .catch((error: any) => {
                    setLoading(false)
                    console.log("res----------------------->", error?.payload?.error);
                })
        }
    };

    const flowClone = () => {
        // Add save functionality here
        let payload = {
            categories: mapNamesToIds(categorieListData, categories),
            name: screenName,
            clone_id: selectedRow?.meta_id
        }
        setLoading(true)
        setIsClonepop(false)
        dispatch(FlowBuilderActions.cloneFlow(payload))
            .then((res: any) => {
                console.log("res----------------------->", res);
                if (res?.payload?.error) {
                    setLoading(false)
                    showAlert(res?.payload?.error || "Error In Deleting", "error");
                    // setErrorMessage(res?.payload?.error)
                }
                if (res?.payload?.data) {
                    setLoading(false)
                    handleClose()
                    setIsClonepop(false)
                    getFlow()
                    showAlert("Cloned Successfully", "success");
                    dispatch(setFlowBuilderList(selectedRow?.template_flow))
                    dispatch(setScreenType(parentScreen))
                    dispatch(setContentData(selectedRow?.template_flow?.screens[0]))
                    dispatch(setScreenId(selectedRow?.template_flow?.screens[0]?.id))
                    dispatch(setContactMapping(selectedRow?.mapping_object))
                    dispatch(setFormName(screenName))
                    navigate("/whatsapp/flow/builder/" + selectedRow?.meta_id)
                }
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("res----------------------->", error?.payload?.error);
            })
    };


    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;
        console.log("vlauesssssssssssssssssssss", value);

        if (value?.length === 0) {
            setCategories(['Select Categories'])
        } else {
            const filteredCategories = value.filter((category: any) => category !== 'Select Categories');
            setCategories(
                // On autofill we get a stringified value.
                typeof filteredCategories === 'string' ? filteredCategories.split(',') : filteredCategories,
            );
        }
    };
    const onClick = (data: any) => {
        if (data?.status === "DEPRECATED" || data?.status === "PUBLISHED") {
            return true
        }
        console.log("data", data);
        dispatch(setFlowBuilderList(data?.template_flow))
        dispatch(setScreenType(parentScreen))
        dispatch(setContentData(data?.template_flow?.screens[0]))
        dispatch(setScreenId(data?.template_flow?.screens[0]?.id))
        dispatch(setContactMapping(data?.mapping_object))
        navigate("/whatsapp/flow/builder/" + data?.meta_id)
        dispatch(setFormName(data.name))
    }
    const SetScreenNameFn = (name: string) => {
        setScreenName(name)
    }
    const setScreen = (event: any) => {
        const {
            target: { value },
        } = event;

        // setTemplate(value)
        // let data: any = getJSON()[0]
        let selectTemplate = allScreens?.screens?.filter((res: any) => res?.title === value)
        const screenIndex = allScreens?.screens?.findIndex((screen: any) => screen?.title === value);
        setCurrentScreenIndex(screenIndex)

        if (!selectTemplate[0]?.layout?.children[0]?.children) {
            dispatch(setScreenType(childScreen))
        } else {
            dispatch(setScreenType(parentScreen))
        }
        setSelectScreen(selectTemplate[0]?.title)
        dispatch(setContentData(selectTemplate[0]));
    }
    const handleBack = () => {
        if (currentScreenIndex > 0 && allScreens && selectScreen) {
            const newIndex = currentScreenIndex - 1;
            setCurrentScreenIndex(newIndex);
            setSelectScreen(allScreens?.screens[newIndex]?.title);
            dispatch(setContentData(allScreens?.screens[newIndex]));
            if (allScreens?.screens[newIndex]?.id.includes("TERMS_AND_CONDITIONS_")) {
                dispatch(setScreenType(childScreen))
            } else {
                dispatch(setScreenType(parentScreen))
            }
        }
    };

    const handleForward = () => {
        if (currentScreenIndex < allScreens?.screens?.length - 1 && allScreens && selectScreen) {
            const newIndex = currentScreenIndex + 1;
            setCurrentScreenIndex(newIndex);
            dispatch(setContentData(allScreens?.screens[newIndex]));
            setSelectScreen(allScreens?.screens[newIndex]?.title);
            if (allScreens?.screens[newIndex]?.id.includes("TERMS_AND_CONDITIONS_")) {
                dispatch(setScreenType(childScreen))
            } else {
                dispatch(setScreenType(parentScreen))
            }
        }
    };

    const resetHandle = () => {
        if (allScreens && selectScreen) {
            console.log("allScreens?.screens", allScreens?.screens);
            setCurrentScreenIndex(0);
            dispatch(setContentData(allScreens?.screens[0]));
            setSelectScreen(allScreens?.screens[0]?.title);
            dispatch(setScreenType(parentScreen))
        }
    };
    const preview = () => {
        setAnchorEl(null);
        console.log("selectedRow", selectedRow);
        setAllScreens(selectedRow?.template_flow)
        setShowPreview(true)
        setCurrentScreenIndex(0);
        dispatch(setContentData(selectedRow?.template_flow?.screens[0]));
        setSelectScreen(selectedRow?.template_flow?.screens[0]?.title);
        dispatch(setFlowBuilderList(selectedRow?.template_flow))
        dispatch(setScreenType(parentScreen))
    }
    const syncFlow = () => {
        setUploadError(false)
        setLoading(true)
        dispatch(FlowBuilderActions.syncFlow({}))
            .then((res: any) => {
                console.log("res----------------------->", res);
                if (res?.payload?.error) {
                    setLoading(false)
                    showAlert(res?.payload?.error || "Error In Deleting", "error");
                    // setErrorMessage(res?.payload?.error)
                }
                if (res?.payload?.data) {
                    setLoading(false)
                    handleClose()
                    setIsClonepop(false)
                    getFlow()
                    showAlert(res?.payload?.data?.data || "Sync Completed Successfully", "success");
                }
            })
            .catch((error: any) => {
                setLoading(false)
                console.log("res----------------------->", error?.payload?.error);
            })
    }
    // Handle Pagination Functions
    const handlePageChange = (event: any, value: any) => {
        setLocalValues({
            ...localValues,
            currentPage: value - 1,
        });
    };
    const handleChangeRowsPerPage = (value: any) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: value,
            currentPage: 0,
        });
    };
    const buttonStyle = {
        border: '1px solid',
        borderColor: 'grey.500',
        borderRadius: 1,
        height: '36px',
        width: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    };
    return (
        <>
            <FlowScreenHeader>
                <div className="headerContainer">
                    {userConfig?.payload?.whatasapp_config?.business_verified ? <div style={{ margin: "12px" }}>
                        <Tooltip title="Sync With Meta">
                            <IconButton onClick={() => setUploadError(true)} style={{
                                marginBottom: "-10px",
                                paddingTop: "1px",
                                backgroundColor: "white"
                            }}>
                                <SyncIcon style={{ marginRight: "10px", cursor: "pointer" }} />
                            </IconButton>
                        </Tooltip>
                        <TextField
                            label=""
                            className='search'
                            variant="outlined"
                            placeholder='Search'
                            value={rendewrSearchTerm}
                            onChange={handleSearchChange}
                            style={{ height: 29, width: 216, marginRight: '12px' }}
                        />
                        <a style={{ marginRight: "10px", fontSize: "12px" }}>Status</a>
                        <FormControl className='status' variant="outlined" style={{ height: 29, width: 150, marginRight: 10 }}>
                            <Select
                                multiple

                                value={selectedStatuses}
                                placeholder='status'
                                onChange={(e: any) => handleStatusChange(e)}
                                renderValue={(selected) => (selected as string[]).join(', ')}
                                label="Status"
                                sx={{ broder: "1px solid lightgray" }}
                                MenuProps={{ PaperProps: { style: { maxHeight: 200 } } }}

                            >
                                {statuses?.map((status) => (
                                    <MenuItem key={status?.value} value={status?.value}>
                                        <ListItemText primary={status?.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <AppButton
                            startIcon={<SaveAs />}
                            className="bodyOptionsButton"
                            fontColor={'#ffff'}
                            backgroundColor={"rgb(33, 28, 82)"}
                            text={"Create Flow"}
                            onClick={() => navigate("/whatsapp/flow/create")}
                        />
                    </div> : null}

                </div>
            </FlowScreenHeader>
            <WhatsappFlowWapper>
                {!userConfig?.payload?.whatasapp_config?.business_verified ? <div className="business-verification-container">
                    <h2>Before you get started</h2>
                    <div className="business-verification-box">
                        <div className="verification-header">
                            <i className="icon-info-circle"></i>
                            <span>Verify your business</span>
                        </div>
                        <div className="verification-content">
                            <p>You need to verify that your account belongs to a legitimate business or organisation.</p>
                            <p>Complete the business verification application in the Security Centre. Only a business admin can do this. <a href="https://www.facebook.com/business/help/2058515294227817" target="_blank">Learn more</a></p>
                        </div>
                    </div>
                </div> :
                    <>
                        {flowList?.data?.length !== 0 ? <div className="flow-table-container" style={{height:"calc(-227px + 100vh)"}}>
                            <table className="flow-table">
                                <thead>
                                    <tr>
                                        <th>Flow Name</th>
                                        <th>Flow ID</th>
                                        <th>Status</th>
                                        <th>Last Update</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {flowList?.data?.map((row: any, index: number) => (
                                        <tr key={index}>
                                            <td>{row.status === "DEPRECATED" || row.status === "PUBLISHED" ? row.name : <a onClick={() => onClick(row)}>  {row.name} </a>}</td>
                                            <td>{row.meta_id}</td>
                                            <td className="status"><span className={row.status === "DEPRECATED" ? "status-deprecate" : row.status === "PUBLISHED" ? "status-publish" : row.status === "BLOCKED" ? "status-blocked" : row.status === "THROTTLED" ? "status-throttled" : "status-draft"}>{row.status === "DEPRECATED" ? "Deprecate" : row.status === "PUBLISHED" ? "Published" : row.status === "BLOCKED" ? "Blocked" : row.status === "THROTTLED" ? "Throttled" : "Draft"}</span></td>
                                            <td>{convertDateAndTimeToIST(row.modified_time)}</td>
                                            <td>
                                                <IconButton className="action-btn" onClick={(event) => handleClick(event, row)}>
                                                    ...
                                                </IconButton>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div> : !loadingStatus ? <div style={{ padding: "150px 0", textAlign: "center" }}>
                            <img src={Nodata} />
                            <div style={{ marginTop: "15px", fontSize: "14px", fontWeight: 500 }}>
                                <a>No Flows Found</a>

                            </div>
                            <div style={{ marginTop: "15px", fontSize: "14px", fontWeight: 500 }}>
                                <AppButton
                                    startIcon={<SaveAs />}
                                    className="bodyOptionsButton"
                                    fontColor={'#ffff'}
                                    backgroundColor={"#128C7E"}
                                    text={"Create Flow"}
                                    onClick={() => navigate("/whatsapp/flow/create")}
                                />
                            </div>
                        </div> : null}
                    </>}
                <div style={{
                    bottom: "21px",
                    position: "absolute",
                    width: "100%"
                }}>
                    {flowList?.data && flowList?.data?.length ? (
                        <AppPaginationComponent
                            totalCount={flowList?.pagination?.totalRecords}
                            handlePageChange={handlePageChange}
                            currentPage={localValues?.currentPage}
                            totalPages={flowList?.pagination?.totalPages}
                            rowsPerPage={localValues?.itemsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            title="Whatsapp Form"
                        />
                    ) : null}
                </div>
            </WhatsappFlowWapper>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >

                {/* <Menu> */}
                {selectedRow?.status === "DRAFT" && <> <MenuItem onClick={() => handleMenuItemClick('Clone')}>Clone</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Delete')}>Delete</MenuItem> <MenuItem onClick={() => preview()}>Preview</MenuItem> </>}
                {selectedRow?.status === "PUBLISHED" && <> <MenuItem onClick={() => handleMenuItemClick('Clone')}>Clone</MenuItem>
                    <MenuItem onClick={() => handleMenuItemClick('Deprecate')}>Deprecate</MenuItem> <MenuItem onClick={() => preview()}>Preview</MenuItem> </>}
                {selectedRow?.status === "DEPRECATED" && <> <MenuItem onClick={() => handleMenuItemClick('Clone')}>Clone</MenuItem> <MenuItem onClick={() => preview()}>Preview</MenuItem>
                </>}
                {/* </Menu> */}
            </Popover>
            <AppModel width="546px" style={{ width: "100%" }} isShowing={isShowing} onClose={() => setIsShowing(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">{heading}
                        </div>
                    </div>
                    <div>
                        <div className="leaveBodyTitle">{body}                           </div>
                    </div>
                    <div className='footer'>
                        <AppButton onClick={handleDiscard} width="220%" fontColor="black" backgroundColor="#fff" text={"Discard"} />
                        <AppButton onClick={handleSave} width="220%" backgroundColor="#075E54" text={buttonText} />
                    </div>
                </AddScreenModel>
            </AppModel>

            <AppModel width="546px" style={{ width: "100%" }} isShowing={isClonepop} onClose={() => setIsClonepop(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">Copy flow
                        </div>
                    </div>
                    <div style={{ margin: "10px 0px" }}>
                        <div style={{ margin: "10px 0px" }} className="leaveBodyTitle">You are cloning the <a style={{ fontStyle: "oblique", fontWeight: 600 }}> {selectedRow?.name} </a>  flow.
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="bodyTitle">Screen name<a style={{ color: "red", marginLeft: "5px" }}>*</a></div>
                            <AppInput value={screenName} handleChange={SetScreenNameFn} characterLimit={200} placeholder="Enter your screen name" />
                        </div>
                        <div>
                            <div className="bodyTitle">Categories<a style={{ color: "red", marginLeft: "5px" }}>*</a></div>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={categories}
                                onChange={handleChange}
                                input={<OutlinedInput label="Tag" />}
                                placeholder="Select value"
                                renderValue={(selected) => {
                                    if (selected?.length === 0) {
                                        return <em>Placeholder</em>;
                                    }

                                    return selected.join(', ');
                                }} MenuProps={MenuProps}
                                style={{
                                    margin: "12px 0px 25px",
                                    border: "1px solid",
                                    width: "100%",
                                    height: "36px",
                                }}
                            >
                                {categorieListData?.map((name: any) => (
                                    <MenuItem key={name?.id} value={name?.name}>
                                        <ListItemText primary={name?.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className='footer'>
                        <AppButton onClick={handleDiscard} width="220%" fontColor="black" backgroundColor="#fff" text={"Cancle"} />
                        <AppButton disabled={screenName && categories?.map((res) => res !== "Select Categories") ? false : true} onClick={flowClone} width="220%" fontColor={screenName && categories?.map((res) => res !== "Select Categories") ? "#ffff" : "#000000"} backgroundColor={screenName && categories?.map((res) => res !== "Select Categories") ? "#075E54" : "rgba(229,232,240,1)"} text={"Clone"} />
                    </div>
                </AddScreenModel>
                <Drawer
                    variant="temporary"
                    open={showPreview}
                    anchor="right"
                    ModalProps={{
                        keepMounted: false,
                    }}

                    sx={{ width: "30%", '& .MuiDrawer-paper': { width: '30%' } }}
                >
                    <Box display="flex" justifyContent="flex-end" p={1}>
                        <IconButton onClick={() => setShowPreview(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <div style={{ marginTop: "20%" }}>
                        {<FlowBuilderPreview height="560px" />}
                        <Box display="flex" alignItems="center" justifyContent="center" mt={2} gap={2}>
                            <IconButton style={buttonStyle} onClick={() => handleBack()} disabled={currentScreenIndex === 0}>

                                <ArrowBack />
                            </IconButton>
                            <IconButton style={buttonStyle} onClick={() => handleForward()} disabled={currentScreenIndex === allScreens?.screens?.length - 1}>
                                <ArrowForward />
                            </IconButton>
                            <FormControl variant="outlined" sx={{ minWidth: 150, border: '1px solid', borderColor: 'grey.500', borderRadius: 1, height: '36px' }}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectScreen}
                                    onChange={setScreen}
                                    label="Select"

                                    placeholder="select"
                                    sx={{ height: '36px', lineHeight: '36px' }}
                                >
                                    {allScreens?.screens?.map((res: any) => {
                                        return (
                                            <MenuItem value={res?.title}>{res.title}</MenuItem>
                                        )
                                    })}

                                    {/* Add more MenuItem components as needed */}
                                </Select>
                            </FormControl>
                            <IconButton onClick={() => resetHandle()} style={buttonStyle}>
                                <Refresh />
                            </IconButton>
                        </Box>
                    </div>
                </Drawer>
            </AppModel>
            <AppModel width="546px" style={{ width: "100%" }} isShowing={uploadError} onClose={() => setUploadError(false)}>
                <AddScreenModel>
                    <div className='addScreenHeader'>
                        <div className="headerTitle">{<WarningIcon style={{ color: "red" }} />}
                        </div>
                    </div>
                    <div>
                        <div className="leaveBodyTitle">{"Before syncing, please ensure that only without endpoint templates flow is supported"}                           </div>
                    </div>
                    <div className='footer'>
                        <AppButton onClick={() => setUploadError(false)} width="220%" fontColor="black" backgroundColor="#fff" text={"Cancel"} />
                        <AppButton onClick={() => syncFlow()} width="220%" backgroundColor="#075E54" text={"Sync"} />                    </div>
                </AddScreenModel>
            </AppModel>
        </>
    );
};

export default HocBackdropLoader(FlowBuilderList);
