
import Tooltip from '@mui/material/Tooltip';

// Props Type
interface Props {
    children?: any;
    title: string;
    placement?: 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

const AppCustomTooltip = (props: Props) => {
    return (
        <Tooltip
            className="customTooltip"
            // Tooltip Text
            title={<div dangerouslySetInnerHTML={{ __html: props.title }}></div>}
            // Tooltip Position
            placement={props.placement}
        >
            {/* Render Children */}
            {props?.children}
        </Tooltip>
    );
};

// Default Export of component
export default AppCustomTooltip;
