import React, { useEffect, useState } from 'react'
import { AddCannedResponse, CannedResponsesWrapper, DeleteCannedResponse, MessageContainer, MessagesTable, ResponseHeader } from './CannedResponsesstyles'
import { AppForm, AppFormField, AppFormTextArea, AppSubmitButton } from '../../../../AppComponents/Forms'
import AppFormSearchField from '../../../../AppComponents/Forms/AppFormSearchField'
import AppButton from '../../../../AppComponents/AppButton'
import { AppMaterialIcons } from '../../../../AppComponents/AppMaterialIcons'
import { Box, IconButton, TablePagination, Typography, debounce } from '@mui/material'
import AppModel from '../../../../Hooks/useModel/AppModel'
import useModal from '../../../../Hooks/useModel/useModel'

import Danger from '../../../../assets/images/danger-live.svg'
import { cannedSchema } from './CannedValidationSchema'
import { useAppDispatch, useAppSelector } from '../../../../Store'
import { LiveChatActions } from '../../../../Store/Slices/Settings/LiveChat/CannedResponse/cannedres.actions'
import { useCurrentBot } from '../../../../Store/Slices/DashboardSlices'
import { useSelector } from 'react-redux'
// import { cannedResponseListing, getCannedMessageData, cannedResponseListingCount } from '../../../../../Store/Slices/Settings/LiveChat/ChatLabel/livechat.selectors'

import { cannedResponseListingCount, getCannedMessageData, cannedResponseListing, cannedSearchResponse, searchCannedMessageCount } from '../../../../Store/Slices/Settings/LiveChat/CannedResponse/cannedres.selectors'

import { useNotification } from '../../../../Hooks/useNotification/useNotification'
import { ErrorComponent } from '../../../Broadcast/WhatsApp/Templates/TemplatesListingCSS'
import TemplateAPIERROR from "../../../../assets/images/TemplateAPIERROR.png";
import {
    LoaderComponentProps,
    withLoader,
} from "../../../../AppComponents/Loader";
import { TableWrapper } from '../../../../TableStyle'
import NoRecordsFound from '../../../UiComponents/NoRecordsFound/NoRecordsFound'

interface Props extends LoaderComponentProps { }


const CannedResponse: React.FC<Props> = ({ setLoading }) => {

    const currentBot = useSelector(useCurrentBot);
    const listingData = useSelector(cannedResponseListing)
    const listingDataCount = useSelector(cannedResponseListingCount)
    const cannedMessage = useSelector(getCannedMessageData)
    const searchedMessage = useSelector(cannedSearchResponse)
    const messageCount = useSelector(searchCannedMessageCount)

    const [selectedId, setSelectedID] = useState()
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const { showAlert } = useNotification();
    const dispatch = useAppDispatch();


    // Initial Form Values 
    const [userInput, setUserInput] = useState<any>({
        short_code: "",
        response_text: "",
    });

    const [localValues, setLocalValues] = useState<any>({
        search: "",
        itemsPerPage: 10,
        currentPage: 0,
    });


    // Modals
    const useModal1 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingModal1: isShowing, toggleModal1: toggle };
    };

    const useModal2 = () => {
        const { isShowing, toggle } = useModal();
        return { isShowingModal2: isShowing, toggleModal2: toggle };
    };
    const { isShowingModal1, toggleModal1 } = useModal1();
    const { isShowingModal2, toggleModal2 } = useModal2();

    const HEADERS = {
        appid: JSON.stringify({
            botid: currentBot?.bot_id,
        }),
    };

    // Get listing of all canned messages
    useEffect(() => {
        // if (currentBot?.bot_id) {
            searchCannedMessage()

        // }

    }, [localValues, currentBot?.bot_id])

    const getAllCannedMessagesListing = () => {
        const data = {
            HEADERS,
            limit: localValues.itemsPerPage,
            offset: localValues.currentPage,
            search: localValues.search,
        }
        dispatch(LiveChatActions.getCannedMessagesListings(data)).then().finally(() => {
            setLoading(false);
        });
    }

    // Add Canned Message
    const handleAddResponse = (values: any, SubmitProps: any) => {

        setLoading(true);
        const payload = {
            short_code: values.short_code,
            message: values.response_text,
        }
        const data = {
            payload,
            HEADERS
        }

        dispatch(LiveChatActions.postCannedMessage(data))
            .then((res: any) => {
                showAlert("Message is added", "success");
                toggleModal1()
                searchCannedMessage();
            })
            .catch((err: any) => {
                showAlert("Something went wrong", "success");
                console.error("error", err);
            })
            .finally(() => {
                toggleModal1()
                SubmitProps?.resetForm();
                setLoading(true);
            });



    }

    // Search
    const searchCannedMessage = () => {
        const data = {
            HEADERS,
            limit: localValues.itemsPerPage,
            offset: localValues.currentPage,
            search: localValues.search,
        }
        dispatch(LiveChatActions.searchCannedMessages(data)).then().finally(() => {
            setLoading(false);
        });
    }

    // Delete Canned Message
    const handleDeleteMessage = (id: any) => {
        setLoading(true);
        const data = {
            id,
            HEADERS
        }
        dispatch(LiveChatActions.deleteCannedMessageById(data)).then((res: any) => {
            toggleModal2()
            showAlert("Message is deleted", "success");
            searchCannedMessage()

        }).catch(err => { }).finally(() => {
            setLoading(false)
        })
    };

    useEffect(() => {
        if (cannedMessage) {
            setUserInput({
                short_code: cannedMessage?.short_code,
                response_text: cannedMessage?.message,
            })
        }
    }, [cannedMessage])

    // Edit / Update canned message
    const updateAddResponse = (values: any, SubmitProps: any) => {
        setLoading(true);
        const payload = {
            short_code: values.short_code,
            message: values.response_text,
        }
        const data = {
            id: selectedId,
            payload,
            HEADERS
        }
        if (selectedId) {

            dispatch(LiveChatActions.updateCannedMessageById(data))
                .then((res: any) => {
                    showAlert("Message is updated", "success");
                    toggleModal1()
                    searchCannedMessage();
                })
                .catch((err: any) => {
                    showAlert("Something went wrong", "success");
                    console.error("error", err);
                })
                .finally(() => {
                    setLoading(false);
                    SubmitProps?.resetForm();
                });
        }
    }

    const editMessageById = (id: any) => {
        setLoading(true);
        const data = {
            id,
            HEADERS
        }
        dispatch(LiveChatActions.getCannedMessageById(data)).finally(() => {
            setLoading(false);
        })
    }


    function handlePageChange(event: any, value: any) {
        setLocalValues({
            ...localValues,
            currentPage: value,
        });
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setLocalValues({
            ...localValues,
            itemsPerPage: parseInt(event.target.value),
            currentPage: 0,
        });
    };

    const handleSelectSearch = debounce((e?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            [field]: e,
        });
    }, 700);

    const clearCallback = (e?: any, field?: any) => {
        setLocalValues({
            ...localValues,
            [field]: "",
        });
    };

    const header = [
        "Short Code",
        "Message",
        "Actions",
      ];
    
      const keyToHeaderMap: any = {
        "Short Code": "short_code",
        "Message": "message",
      };
    
      const colWidth: any = {
        "Short Code": "200",
        "Message": "200",
      };

      const renderColumns = (row: any, header: any,) => {
        if(header === "Actions") {
            return <Box>
    
    <IconButton onClick={() => {
                                                            editMessageById(row.id);
                                                            toggleModal1();
                                                            setIsEditing(true);
                                                            setSelectedID(row.id);
                                                        }}>
                                                            <AppMaterialIcons iconName='EditOutlined' />
                                                        </IconButton>
                                                        <IconButton onClick={() => { toggleModal2(row.id); setSelectedID(row.id) }}>
                                                            <AppMaterialIcons iconName='DeleteOutlineOutlined' />
                                                        </IconButton>
                   
                </Box>
        } else {
            return <Typography
                className="table-text"
                sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                }}
            >
              {
                
                header === "Color" ? <Box display={"flex"} gap={1}>
    
                              <Box
                              width={17}
                              height={17}
                              borderRadius={100}
                              className="color"
                              style={{ background: `${row.color}` }}
                            >
                              
                            </Box>
                            <Box>
                            {row?.color}
                              </Box>
                
                </Box> :
               row[keyToHeaderMap[header]]
                  ? row[keyToHeaderMap[header]]
                  : "-"
              }
            </Typography>
        }
      }

    return (
        <CannedResponsesWrapper>
            <ResponseHeader>
                <div className='response-header__left'>
                    <h4>Canned Responses</h4>
                </div>
                <div className='response-header__right'>
                    <div className='response-search'>
                        <AppForm initialValues={{}} onSubmit={() => { }}>
                            <AppFormSearchField
                                defaultBorder={true}
                                placeholder="Search"
                                divStyle={{ marginBottom: '0' }}
                                name="message-search"
                                onChange={(e: any) => handleSelectSearch(e, "search")}
                                clearCallback={(e: any) => clearCallback(e, "search")}
                            />
                        </AppForm>
                    </div>
                    <div className='response-button'>
                        <AppButton onClick={() => {
                            toggleModal1();
                            setIsEditing(false);
                            setUserInput({
                                short_code: '',
                                response_text: ''
                            })
                        }}>
                            <AppMaterialIcons iconName='add' />
                            Add Responses
                        </AppButton>
                    </div>
                </div>
            </ResponseHeader>

            <MessageContainer>


            <TableWrapper style={{paddingTop: "16px"}} height={"calc(100vh - 217px)"}>
              <div>
              <Box className="table-header">
                {/* Table Header */}
                <Box className="table-row">
                  {header?.map((header: string, index: number) => {
                    return (
                      <Box
                      key={index}
                        className={`col-head ${header
                          .toLowerCase()
                          .replaceAll(" ", "_")}`}
                        style={{ minWidth: colWidth[header] + "px" }}
                        justifyContent={header === "Actions" ? "flex-end" : ""}
                      >
                        <Typography sx={{ fontWeight: 500 }}>
                          {header}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
                {/* Table Body */}
              </Box>

              <Box className="table-body">
                {searchedMessage && searchedMessage?.length === 0 ? (
                  <NoRecordsFound height="calc(100vh - 279px)" subText='Create New Response' >
                    <AppButton variant='outline' onClick={() => {
                                            toggleModal1();
                                            setIsEditing(false);
                                            setUserInput({
                                                short_code: '',
                                                response_text: ''
                                            })
                                        }}>
                                            <AppMaterialIcons iconName='add' />
                                            Add Responses
                                        </AppButton>
                  </NoRecordsFound>
                ) : (
                  <>
                    {searchedMessage && searchedMessage?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className={`table-row`} >
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                    row[keyToHeaderMap[header]]
                                }
                                style={{ minWidth: colWidth[header] + "px" }}
                                justifyContent={header === "Actions" ? "flex-end" : ""}
                              >
                                    {
                                        renderColumns(row, header)
                                    }    
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                  </>
                )}
              </Box>
              </div>
        </TableWrapper>

                <MessagesTable>

                    {/* Edit Modal */}
                    <AppModel
                        isShowing={isShowingModal1}
                        onClose={() => toggleModal1()}
                        divStyle={{
                            padding: 0,
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: "none",
                                width: "500px",
                            },
                        }}
                    >
                        <AddCannedResponse>
                            <div className='header'>
                                <h4>Add Canned Response</h4>
                                <span onClick={() => toggleModal1()}>
                                    <IconButton>
                                        <AppMaterialIcons iconName="close" />
                                    </IconButton>
                                </span>
                            </div>
                            <div className='body'>
                                <AppForm
                                    initialValues={userInput}
                                    onSubmit={(values: any, submitProps: any) => {
                                        if (isEditing && selectedId) {
                                            updateAddResponse(values, submitProps);
                                        } else {
                                            handleAddResponse(values, submitProps);
                                        }
                                    }}
                                    validationSchema={cannedSchema}
                                >
                                    <AppFormField
                                        label="Short Code"
                                        name="short_code"
                                        placeholder="Help"
                                    />

                                    <AppFormTextArea
                                        label="Response"
                                        name="response_text"
                                        tooltip={""}
                                        placeholder="Great! I can Surely Help You. Before That, I Will Need Some Information From You. What Is The Order Date?"
                                    />

                                    <div className='footer'>
                                        <AppButton variant='grey' onClick={() => toggleModal1()}>
                                            Cancel
                                        </AppButton>
                                        <div className='submit-button'>
                                            {isEditing ? (
                                                <AppSubmitButton
                                                    variant="cta"
                                                    style={{ width: "180px" }}
                                                    title={"Update Response"}
                                                />
                                            ) : (
                                                <AppSubmitButton
                                                    variant="cta"
                                                    style={{ width: "180px" }}
                                                    title={"Add Response"}
                                                />
                                            )}
                                        </div>
                                    </div>

                                </AppForm>
                            </div>
                        </AddCannedResponse>
                    </AppModel>

                    {/* Delete Modal */}
                    <AppModel
                        isShowing={isShowingModal2}
                        onClose={toggleModal2}
                        divStyle={{
                            padding: 0,
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: "none",
                                width: "500px",
                            },
                        }}
                    >
                        <DeleteCannedResponse>
                            <div className='header'>
                                <div className='header-left'>
                                    <div className='danger'>
                                        <img src={Danger} />
                                    </div>
                                    <div className='text'>
                                        <h4>Delete The Canned Response</h4>
                                        <p>Are you sure, you want to delete?</p>
                                    </div>
                                </div>
                                <div className='header-right'>
                                    <span onClick={() => toggleModal2()}>
                                        <AppMaterialIcons iconName="close" />
                                    </span>
                                </div>
                            </div>
                            <div className='footer'>
                                <AppButton variant='grey' onClick={() => toggleModal2()} >
                                    Cancel
                                </AppButton>
                                <AppButton variant='danger-filled' onClick={() => {
                                    return handleDeleteMessage(selectedId)
                                }}>
                                    <AppMaterialIcons iconName='DeleteOutlineOutlined' />Delete
                                </AppButton>
                            </div>
                        </DeleteCannedResponse>
                    </AppModel>

                </MessagesTable>



            </MessageContainer>
            <div className='footer-pagination'>
                <TablePagination
                    component="div"
                    rowsPerPageOptions={[10, 25, 50]}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    page={localValues?.currentPage}
                    count={+messageCount}
                    rowsPerPage={localValues?.itemsPerPage}
                />
            </div>
        </CannedResponsesWrapper>
    )
}

export default withLoader(CannedResponse);