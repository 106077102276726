
import React from "react";
import SmsLogsTableData from "./SmsLogsTableData";
import { AnalyticsWrapper, BroadcastListWrapper, WrapperContainer } from "../../../BroadcastHistory/SmsHistoryStyles";
import useModal from "../../../../../../Hooks/useModel/useModel";
import AppModel from "../../../../../../Hooks/useModel/AppModel";
import UploadTemplate from "../../../BroadCastTemplate/UploadTemplate";



const SmsDashboardLogs = () => {
    
    const { isShowing, toggle } = useModal();


    return (
        <React.Fragment>
            <WrapperContainer className="check">
                <AnalyticsWrapper>
                </AnalyticsWrapper>
                <BroadcastListWrapper>


                    <SmsLogsTableData />
                </BroadcastListWrapper>
            </WrapperContainer>
            <AppModel
                isShowing={isShowing}
                onClose={() => toggle()}
                divStyle={{
                    padding: 0,
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",
                        width: "500px",
                    },
                }}
            >
                <UploadTemplate />
            </AppModel>
        </React.Fragment>
    );
};

// Default Export of component
export default SmsDashboardLogs;
