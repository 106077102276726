import { createAsyncThunk } from "@reduxjs/toolkit";
import { LiveChatNetworkService } from "./CannedResNetworkService";
import { templateDataToUserFormData } from "../../../../../Components/Broadcast/WhatsApp/Utils/utils";

export class LiveChatActions {

    // Create Message
    static postCannedMessage = createAsyncThunk(
        "LiveChatSlice/postCannedMessage",
        (data: any, { dispatch, rejectWithValue }) => {
            return LiveChatNetworkService.postCannedMessage(data)
                .then(async (response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Get All Canned Messages
    static getCannedMessagesListings = createAsyncThunk(
        "LiveChatSlice/getCannedMessagesListings",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return LiveChatNetworkService.getCannedMessageListings(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Delete Message By ID
    static deleteCannedMessageById = createAsyncThunk(
        "LiveChatSlice/deleteCannedMessageById",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return LiveChatNetworkService.deleteCannedMessage(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Get 1 Message By ID
    static getCannedMessageById = createAsyncThunk(
        "LiveChatSlice/getCannedMessageById",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return LiveChatNetworkService.getCannedMessage(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Update Message By ID
    static updateCannedMessageById = createAsyncThunk(
        "LiveChatSlice/updateCannedMessageById",
        (data: any, { dispatch, rejectWithValue }) => {
            return LiveChatNetworkService.updateCannedMessage(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                });
        }
    );

    // Search
    static searchCannedMessages = createAsyncThunk(
        "LiveChatSlice/searchCannedMessages",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return LiveChatNetworkService.searchCannedMessage(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                });
        }
    );
}
