import { IconButton, InputAdornment, MenuItem, Select, Tooltip } from "@mui/material";
import { FastField, getIn, useFormikContext } from "formik";
import styled from "styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useEffect, useRef, useState } from "react";

import { StylesProvider } from "@material-ui/core/styles";
import AppErrorMessage from "../AppErrorMessage";
import { theme } from "../../../Customization/Theme";
import { AppMaterialIcons } from "../../AppMaterialIcons";

interface Props {
  Options: any;
  label?: string;
  displayKey?: string;
  valueKey?: string;
  defaultText?: string;
  defaultIndexValue?: number;
  name: string;
  divStyle?: any;
  tooltip?: any;
  onChangeCallbackFn?: (value: any) => void;
  filterCondition?: string[];
  children?: React.ReactNode;
  [otherProps: string]: any;
  onClear?: (value: any) => void;
  disabledOptionsList?: any;
}

const AppFormSelectFastField: React.FC<Props> = ({
  Options,
  label,
  name,
  defaultText = "Select an option",
  divStyle,
  displayKey,
  valueKey,
  tooltip,
  onChangeCallbackFn,
  filterCondition,
  onClear,
  disabledOptionsList,
  disabled,
  ...otherProps
}) => {
  const { errors, touched, setFieldValue, values, handleChange } =
    useFormikContext<any>();
  const fieldRef: any = useRef<any>(null);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  useEffect(() => {
    if (!getIn(values, name)) {
      setFieldValue(name, 0);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    disablePortal: true,
    PaperProps: {
      sx: {
        padding: "8px",
        backgroundColor: "white",
        minWidth: `${fieldRef?.current?.offsetWidth}px !important`,
        marginTop: "4px",
        maxWidth: "600px !important",
        maxHeight: "350px !important",
        // boxShadow: `0px 2px 4px rgb(0 0 0 / 10%) !important`,
        transition:
          "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
        "& ul": {
          padding: "6px 0rem 4px",
        },
        "& .MuiMenuItem-root": {
          padding: "0px 1rem",
        },
        "& li": {
          fontSize: "0.95rem !important",
          height: "38px",
          marginBottom: "2px",
          color: "#282828",
        },
      },
    },
  };
  return (
    <StylesProvider injectFirst>
      <Div style={divStyle} ref={fieldRef}>
        <Label>
          {label}{" "}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>

        <FastField name={name}>
          {({ field, form, meta }: any) => (
            <StyledSelectInput
              {...field}
              {...otherProps}
              value={getIn(values, name) || 0}
              defaultValue={0}
              label={defaultText}
              MenuProps={menuProps}
              autoWidth={false}
              fullWidth={true}
              disabled={disabled}
              onChange={(e: any) => {
                handleChange(e);
                onChangeCallbackFn && onChangeCallbackFn(e.target.value);
              }}
              style={{
                border: isFocus
                  ? `2px solid ${theme.palette.default.border}`
                  : getIn(errors, name) && getIn(touched, name)
                    ? `2px solid  ${theme.palette.default.error}`
                    : `2px solid transparent`,
              }}
              endAdornment={
                <InputAdornment position="end">
                  {getIn(values, name) === 0 ? null : (
                    <IconButton
                      onClick={(value: any) => { setFieldValue(name, 0); onClear && onClear(value) }}
                      className="clearBtn"
                      disabled={disabled}
                    >
                      <AppMaterialIcons
                        iconName={"Close"}
                        style={{ fontSize: "1rem" }}
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            >
              <StyledMenuItem value={0} disabled={true} key={0}>
                {Options &&
                  Options?.length > 0 ? defaultText : "No Records Found"}
              </StyledMenuItem>
              {Options &&
                Options?.length > 0 &&
                Options?.map((curElem: any, index: any) => {
                  return (
                    <StyledMenuItem
                      value={
                        valueKey
                          ? curElem?.[valueKey]
                          : curElem?.value || curElem?.id || curElem?.name
                      }
                      key={index}
                      disabled={disabledOptionsList?.includes(valueKey
                        ? curElem?.[valueKey]
                        : curElem?.value || curElem?.id || curElem?.name)}
                    >
                      {displayKey
                        ? curElem?.[displayKey]
                        : curElem?.label || curElem?.name || curElem?.id}


                    </StyledMenuItem>
                  );
                })}
            </StyledSelectInput>
          )}
        </FastField>

        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </Div>
    </StylesProvider>
  );
};

export default AppFormSelectFastField;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 750px;
    gap: 8px;
    position: relative;
    margin-bottom: 0.5rem;
    overflow: hidden;

    .clearBtn {
      /* position: absolute; */
      /* right: 5%; */
      /* top: 3%; */
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
const StyledMenuItem = styled(MenuItem)`
  && {
    text-overflow: ellipsis !important;
    width: 100%;
  }
`;

export const StyledSelectInput = styled(Select)`
  && {
    width: 100%;
    height: 35px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 0 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-right: 2.4rem;
    position: relative;
    .clearBtn {
      background-color: #f5f6f8;
    }
    &::placeholder {
      color: ${theme.palette.default.darkGrey} !important;
    }

    &:active {
      color: ${theme.palette.default.text};
    }
    &:focus {
      color: ${theme.palette.default.text};
    }

    &:disabled {
      color: ${theme.palette.default.lightGrey};
      border: 2px solid ${theme.palette.default.lightGrey};
    }

    .Mui-disabled {
      opacity: 0.5;
      .MuiSelect-icon {
        display: none !important;
      }
    }

    .css-1ja9cqb-MuiSvgIcon-root-MuiSelect-icon,
    .css-n9yzhl-MuiSvgIcon-root-MuiSelect-icon {
      color: ${theme.palette.default.border};
      font-size: 1.2rem;
    }

    fieldset {
      border: none !important;
      outline: none !important;
      display: none !important;
    }
    legend {
      display: none !important;
    }
    input {
      display: none !important;
    }

    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
      padding-left: 0px !important ;
      padding: 0px 0px !important;
    }
  }
`;
