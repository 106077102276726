import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

// Email Domain
export class UserQueryNetworkService {
  // Listng Of Answered
  static getAnsweredListing(data: any) {
    const { limit, offset, query, sort, No, Yes, smartAiAnswer, faqDatabase } =
      data;
    const headers = data?.headers;
    const params = {
      limit,
      offset,
      query,
      sort,
      No,
      Yes,
      smartAiAnswer,
      faqDatabase,
    };
    return http.get(
      `${consoleBaseUrl}${Endpoints.ANSWERED_GET_SOURCE_LISTING}`,
      {
        headers,
        params,
      }
    );
  }

  //answered  flow question option data
  static getAnsweredFlowOption(headers: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.ANSWERED_GET_FLOW}`, {
      headers,
    });
  }
  //answered  faq question option data
  static getAnsweredFaqQuestionOption(headers: any) {
    return http.get(`${consoleBaseUrl}${Endpoints.ANSWERED_GET_FAQ_QUESTION}`, {
      headers,
    });
  }

  static updateNotAnswered(data: any) {
    const headers = data?.headers;
    const payload = data?.payload;
    return http.put(`${consoleBaseUrl}${Endpoints.UPDATE_NOTANS}`, payload, {
      headers,
    });
  }

  static updateFlow(data: any) {
    const headers = data?.headers;
    const payload = data?.payload;
    return http.put(`${consoleBaseUrl}${Endpoints.UPDATE_FLOW}`, payload, {
      headers,
    });
  }

  // Delete Conversation By ID
  static deleteConversationById(data: any) {
    const headers = {
      ...data.HEADERS,
    };
    const requestBody = {
      conversationIds: data.conversationIds,
    };
    return http.delete(`${consoleBaseUrl}${Endpoints.DELETE_CONVERSATION}`, {});
  }
  //  Submit answered data
  static submitAnsweredData(data: any) {
    const { url, headers } = data;
    return http.put(`${consoleBaseUrl}${Endpoints.SUBMIT_ANSWERED_DATA}`, url, {
      headers: headers,
    });
  }

  // Delete Source Text By ID
  static deleteFaqsById(data: any) {
    const { HEADERS, faqids } = data;
    const headers = {
      ...data.HEADERS,
    };

    return http.post(`${consoleBaseUrl}${Endpoints.FAQS_DELETE}`, {
      headers,
      faqids,
    });
  }

  // Delete Conversation By ID
  static deleteNotAnsConversationById(data: any) {
    const headers = {
      ...data?.headers,
    };

    const requestBody = {
      headers,
      conversation_id: data?.conversation_id, // Assuming data contains the conversation_id array
    };
    return http.post(
      `${consoleBaseUrl}${Endpoints.NOT_ANS_Q_DELETE}`,
      requestBody
    );
  }

  // Delete Conversation By ID
  static removeNotAnsConversationById(data: any) {
    const headers = {
      ...data?.headers,
    };

    const requestBody = {
      headers,
      conversation_id: data?.conversation_id, // Assuming data contains the conversation_id array
    };
    return http.post(
      `${consoleBaseUrl}${Endpoints.REMOVE_NOT_ANS}`,
      requestBody
    );
  }
}

//*user Queries *//
class Endpoints {
  // answered apies
  static ANSWERED_GET_SOURCE_LISTING = "/console/answered";

  // answered flow list
  static ANSWERED_GET_FLOW = "/console/list-flows";

  // answered faq question list
  static ANSWERED_GET_FAQ_QUESTION = "/console/faq";

  // FAQ Update
  static UPDATE_NOTANS = "/console/not-ans-faq-update";

  // Flow Update
  static UPDATE_FLOW = "/console/not-ans-flow-update";

  // Delete Conversation
  static DELETE_CONVERSATION = "/console/answeredbulk";
  //  Submit answered data
  static SUBMIT_ANSWERED_DATA = "/console/not-ans-faq-update";

  // faqs delete
  static FAQS_DELETE = "/console/faq-bulk-delete";

  // No Answer Question Delete
  static NOT_ANS_Q_DELETE = "/console/non-faq/bulk-delete";

  static REMOVE_NOT_ANS = "/console/non-faq/remove-query-from-list";
}
