import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
  AppForm,
  AppFormTextArea,
  AppSubmitButton
} from "../../../../../AppComponents/Forms";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../../AppComponents/Loader";
import { device } from "../../../../../Customization/BreakPoints";
import {
  Label,
  SettingPageMainBoxTitle,
  SettingPageMainBoxTopbar,
  SettingPageMainBoxWrap,
  SettingPageMainContentBox,
} from "../../../../../Customization/CommonElements";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import {
  ChatbotConfiguration,
  DefaultMessages,
} from "../../../../../Models/ChatbotConfiguration";
import { AutoRepliesSchema } from "../../../../../Models/ValidationSchema";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";
interface Props extends LoaderComponentProps { }
const AutoReplies = (props: Props) => {
  const currentBot = useSelector(useCurrentBot);
  let { showAlert } = useNotification();

  const [localValues, setLocalValues] = useState<any>(null);
  const [autoConfigData, setAutoConfigData] = useState<DefaultMessages | null>(
    null
  );
  const sendValuesToParent = (values: any): void => {
    setLocalValues(values);
  };

  const [userInput, setUserInput] = useState<any>({
    outside_business_hours: {
      message:
        "Hi! We are currently away. Please leave a message and our team will reach out to you at the earliest.",
      user_info: {
        name: false,
        email: false,
        phone: false,
      },
    },

    default: {
      message: "I did not understood the query properly.",
    },
    conflict: {
      message: "Did you mean this?",
    },

    live_agent: {
      enabled: false,
      retry: '3',
    },
  });

  const getData = () => {
    if (currentBot?.chatbot_configuration && currentBot?.chatbot_configuration) {
      ChatbotConsoleService.getBotConfiguration(
        currentBot?.chatbot_configuration as string
      )
        .then((res: AxiosResponse<ChatbotConfiguration>) => {
          if (res.data?.enabled_devices) {
            const data: DefaultMessages | undefined | null =
              res.data?.default_messages;
            if (!data) {
              return;
            }
            setAutoConfigData(data);
            setUserInput({
              outside_business_hours: {
                message:
                  data?.outside_business_hours?.response[0]?.value ||
                  "Hi! We are currently away. Please leave a message and our team will reach out to you at the earliest.",
                user_info: {
                  name: data?.outside_business_hours?.user_info?.name || false,
                  email:
                    data?.outside_business_hours?.user_info?.email || false,
                  phone:
                    data?.outside_business_hours?.user_info?.phone || false,
                },
              },

              default: {
                message:
                  data?.default?.response[0]?.value ||
                  "I did not understood the query properly.",
              },
              conflict: {
                message:
                  data?.conflict?.response[0]?.value || "Did you mean this?",
              },

              live_agent: {
                enabled: data?.live_agent?.enabled || false,
                retry: data?.live_agent?.retry?.toString() || "0",
              },
            });
          }
        })
        .catch((error: any) => {
        })
        .finally(() => {
          setTimeout(() => {
            props.setLoading(false);
          }, 310);
        });
    } else {
      props.setLoading(false);
    }
  };

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    props.setLoading(true);
    let temp: any = {
      conflict: {
        ...autoConfigData?.conflict,
        response: [
          {
            type: "text",
            value: values?.conflict?.message || userInput?.conflict?.message,
          },
        ],
      },
      default: {
        ...autoConfigData?.default,
        response: [
          {
            type: "text",
            value: values?.default?.message || userInput?.default?.message,
          },
        ],
      },
      live_agent: {
        retry: Number(values?.live_agent?.retry) || 3,
        enabled: values?.live_agent?.enabled || false,
      },
      outside_business_hours: {
        response: [
          {
            type: "text",
            value:
              values?.outside_business_hours?.message ||
              userInput?.outside_business_hours?.message,
          },
        ],
        user_info: {
          name: values?.outside_business_hours?.user_info?.name || false,
          email: values?.outside_business_hours?.user_info?.email || false,
          phone: values?.outside_business_hours?.user_info?.phone || false,
        },
      },
    };
    ChatbotConsoleService.createUpdateBotConfiguration(
      currentBot.id as string,
      {
        chatbot: { ...currentBot },
        chatbot_configuration: {
          default_messages: temp,
        },
      }
    )
      .then((response) => {
        SubmitProps.resetForm();
        showAlert("Configuration updated", "success");
        getData();
      })
      .catch((error) => {
        showAlert(
          error?.response?.statusText || "Something went wrong",
          "error"
        );
      })
      .finally(() => {
        SubmitProps.setSubmitting(false);
        props.setLoading(false);
      });
  };

  useEffect(() => {
    if (currentBot) {
      getData();
    }
  }, []);

  return (
    <SettingPageMainBoxWrap>
      <SettingPageMainBoxTopbar>
        <SettingPageMainBoxTitle>Auto Replies</SettingPageMainBoxTitle>
      </SettingPageMainBoxTopbar>

      <SettingPageMainContentBox
        style={{
          alignItems: "flex-start",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          marginBottom: "1rem",
        }}
      >
        <StyledFormBox>
          <AppForm
            initialValues={userInput}
            onSubmit={(values: any, submitProps: any) => {
              handleSubmitBtn(values, submitProps);
            }}
            validationSchema={AutoRepliesSchema}
          >
            {/* <GroupBox>
              <Label style={{ fontWeight: "500" }}>Away Messages</Label>

              <AppFormTextArea
                rows={3}
                label="The message is shown when outside Business Hours."
                name="outside_business_hours.message"
                placeholder="Hi! We are currently away."
                sendValuesToParent={(values: any) => sendValuesToParent(values)}
              />
            </GroupBox> */}

            <GroupBox>
              <Label style={{ fontWeight: "500" }}>
                Bot did not understand message
              </Label>

              <AppFormTextArea
                rows={3}
                label="The message is shown when the bot did not understand the query."
                name="default.message"
                placeholder="I did not understood the query properly."
              />
            </GroupBox>

            {/* <GroupBox>
              <Label style={{ fontWeight: "500" }}>
                Collect User Information when outside Business Hours.
              </Label>

              <div
                className="checkboxDiv"
              >
                <AppFormCheckboxField
                  label={"Name"}
                  name="outside_business_hours.user_info.name"
                />
                <AppFormCheckboxField
                  label={"Email"}
                  name="outside_business_hours.user_info.email"
                />
                <AppFormCheckboxField
                  label={"Phone"}
                  name="outside_business_hours.user_info.phone"
                />
              </div>

              <AppFormSwitchField
                label="The message is shown when the Bot did not understand the query."
                name="live_agent.enabled"
                labelStyle={{ fontWeight: "500" }}
                divStyle={{ marginTop: "1.2rem", marginBottom: "0" }}
                tooltip={"Button will be only shown during business hours"}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "0rem",
                  gap: "1rem",
                }}
              >
                <AppFormSelectField
                  label="Show button after number of retries."
                  Options={RetryOptions}
                  name="live_agent.retry"
                  divStyle={{ width: "100%", marginBottom: 0 }}
                  disabled={
                    localValues && getIn(localValues, "live_agent.enabled")
                      ? false
                      : true
                  }
                />
            
              </div>
            </GroupBox> */}

            {/* <GroupBox>
              <Label style={{ fontWeight: "500" }}>
                Similar queries with message
              </Label>
              <AppFormTextArea
                rows={3}
                label="The message is shown when did not understood the query."
                name="conflict.message"
                placeholder="Did you mean this?"
              />
            </GroupBox> */}
            {/* 
            <div style={{ width: "45%" }}>
              <AppSubmitButton
                variant="cta"
                style={{ width: "45%", margin: "0.8rem 0" }}
                title={"Save Changes"}
              />
            </div> */}
            <AppSubmitButton
              variant="cta"
              style={{ width: "45%", margin: "0.8rem 0" }}
              title={"Save Changes"}
            />
          </AppForm>
        </StyledFormBox>
      </SettingPageMainContentBox>
    </SettingPageMainBoxWrap>
  );
};

export default withLoader(AutoReplies);

const StyledFormBox = styled.div`
  && {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 45%;

    .checkboxDiv{

      display: flex; gap: 1rem; margin-top: -0.5rem;
      flex-wrap: nowrap;
      @media ${device.laptop} {
        flex-wrap: wrap;
    }
    }
  }
`;

const GroupBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    width: 100%;
    margin-bottom: 1rem;
  }
`;
