import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { theme } from "../../../Customization/Theme";
import { useAppDispatch } from "../../../Store";
import { Divider, Menu, MenuItem, MenuProps } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/images/deletegrey.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/edit.svg";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { editId } from "../../../Store/Slices/Contacts/contacts.slice";
import { setNotesData } from "../../../Store/Slices/Contacts/contacts.slice";
interface ContactsCardProps {
  id: string;
  setDeleteData?: Function;
  index?: number;
  status?: string;
  statusId?: string;
  stageId?: string;
  toggleContactModal?: Function;
  items?: any;
}

export const ContactsCard: React.FC<ContactsCardProps> = (props) => {
  const {
    id,
    setDeleteData,
    index,
    status,
    statusId,
    stageId,
    toggleContactModal,
    items,
  } = props;
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showAlert } = useNotification();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 30;
  const EditModal = (contact_id: string) => {
    dispatch(ContactsActions.getEditContactsFilds(contact_id))
      .then((res: any) => {
        if (res?.payload?.data?.status !== 200) {
          showAlert(
            res?.payload?.data?.error || "Enabled to fetch Data",
            "error"
          );
        } else {
          dispatch(editId(contact_id));
          toggleContactModal && toggleContactModal();
        }
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      })
      .finally(() => {});
  };
  const addNotes = () => {
    const payload = {
      status: true,
      contactId: id,
    };
    dispatch(setNotesData(payload));
  };

  return (
    <Card sx={{ maxWidth: 274, maxHeight: 197, m: 2 }}>
      <CardHeader
        avatar={
          <Avatar
            alt={items?.first_name || ""}
            src="/static/images/avatar/1.jpg"
            sx={{
              bgcolor: theme.palette.default.lightBlue,
              color: theme.palette.default.blue,
            }}
          />
        }
        action={
          <IconButton
            aria-label="settings"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        }
        title={(items?.first_name || "") + " " + (items?.last_name || "")}
      />
      {/* *******kabab menu**** */}
      <StyledMenu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem
          key={"dwdw"}
          selected={false}
          onClick={() => {
            EditModal(id);
            handleClose();
          }}
        >
          <IconButton
            aria-label="settings"
            style={{ marginRight: "0.8rem" }}
            disableFocusRipple={true}
          >
            <EditIcon />
          </IconButton>
          Edit
        </MenuItem>
        <Divider />
        <MenuItem
          key={"dwdw"}
          selected={false}
          onClick={() => {
            setDeleteData &&
              setDeleteData({
                id: id,
                index: index,
                status: status,
                statusId: statusId,
                stageId: stageId,
              });
            handleClose();
          }}
        >
          <IconButton
            aria-label="settings"
            style={{ marginRight: "0.8rem" }}
            disableFocusRipple={true}
          >
            <DeleteIcon />
          </IconButton>
          Delete
        </MenuItem>
      </StyledMenu>
      <CardContent>
        <Divider />
        <Typography
          variant="body2"
          style={{ color: `${theme?.palette?.default?.blue}` }}
        >
          {items?.notes_count || 0} Note
          <IconButton
            onClick={() => addNotes()}
            style={{ color: `${theme?.palette?.default?.blue}` }}
          >
            <ControlPointIcon />
          </IconButton>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default function SortableItem(props: {
  id: any;
  items: any;
  setDeleteData: any;
  index: any;
  status: string;
  statusId: string;
  stageId: string;
  toggleContactModal: any;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });
  const {
    setDeleteData,
    index,
    status,
    statusId,
    stageId,
    toggleContactModal,
  } = props;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ContactsCard
        id={props.id}
        items={props.items}
        setDeleteData={setDeleteData}
        index={index}
        status={status}
        statusId={statusId}
        stageId={stageId}
        toggleContactModal={toggleContactModal}
      />
    </div>
  );
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme?.palette?.default?.newTextGrey,
    fontSize: "10px",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },

    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 15,
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        color: theme?.palette?.default?.blue,
        background: theme?.palette?.default?.GhostWhite,
      },
    },
  },
}));
