import { IconButton } from "@mui/material";
import AppButton from "../../../AppComponents/AppButton";
import AppModel from "../../../Hooks/useModel/AppModel";
import { useEffect, useState } from "react";
import AssignTo from "./AssignTo";
import AddTags from "./AddTags";
import React from "react";
import UpdateContactsFields from "./UpdateContactsFields";
import AppDeleteItem from "../../../AppComponents/AppDeleteItem";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { ContactsActions } from "../../../Store/Slices/Contacts/contacts.action";
import { useAppDispatch } from "../../../Store";
import { ReactComponent as UpdateIcon } from "../../../assets/images/update-icon.svg";
import { ReactComponent as AssignIcon } from "../../../assets/images/assign-to-icon.svg";
import { ReactComponent as TagIcon } from "../../../assets/images/tag-icon.svg";
import AddToList from "./AddToList";
import UnassignBulk from "./UnassignBulk";
import ExportConfirmation from "../contactDetailedComponents/ExportConfirmation";
import { useSelector } from "react-redux";
import { getFilterQuery } from "../../../Store/Slices/Contacts/contacts.selector";
import { convertQueryToMongo } from "../../../AppComponents/QueryBuilder/utils/utils";
import { ProtectedAppButton } from "../../../AppComponents/ProtectedComponents";

interface PayloadProps {
  selectAll: boolean;
  selectedDocumentIds: string[];
  columns: Array<any>;
  sort_by: string;
  sort_field: string;
  localValues?: {
    search?: string;
    currentPage?: number;
    page_size?: number;
    limit: number;
    contact_filter?: string;
    page_number: number;
  };
}
interface Props {
  selectedDocumentIds: any;
  setShouldApply: any;
  selectAll: any;
  setSelectAll: any;
  dynamicColumn: Array<any>;
  sortingObject: any;
  setSelectedDocumentIds: any;
  localValues: any;
  leadRefresh?: any;
}

// interface Props extends LoaderComponentProps { }

const BulkActionHeader: React.FC<Props> = ({
  selectedDocumentIds,
  setShouldApply,
  selectAll,
  setSelectAll,
  setSelectedDocumentIds,
  dynamicColumn,
  sortingObject,
  // setLoading,
  localValues,
  leadRefresh,
}) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const filterQuery = useSelector(getFilterQuery);
  const [selectedComponent, setSelectedComponent] = useState<any>(null);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [combinedProps, setCombinedProps] = useState<any>({});
  const [payLoad, setPayLoad] = useState<PayloadProps>({
    selectAll: selectAll,
    selectedDocumentIds: selectedDocumentIds,
    columns: dynamicColumn.map((item) => { return item.name}),
    sort_by: sortingObject?.sort_by,
    sort_field: sortingObject?.sort_field,
    localValues: {
      search: localValues?.search,
      page_size: localValues?.currentPage,
      limit: localValues?.perPage,
      page_number: localValues?.currentPage,
      contact_filter: localValues?.contact_filter,
    },
  });

  // common payload for bulk
  const combinePayload = () => {
    let payLoad: any = {
      selectAll: selectAll,
      contactId_list: selectedDocumentIds, // selected contacts id
      search: localValues?.search,
      page_number: localValues?.currentPage,
      page_size: localValues?.perPage,
      limit: localValues?.perPage,
      mongodb_query: convertQueryToMongo(filterQuery),
      contact_filter: localValues?.contact_filter,
      filter_query: filterQuery,
      columns: dynamicColumn.map((item) => { return item.name}),
      sort_by: sortingObject?.sort_by,
      sort_field: sortingObject?.sort_field
    };
    // Conditionally remove keys
    if (!selectAll) {
      delete payLoad.contact_filter;
      delete payLoad.filter_query;
      delete payLoad.search;
      delete payLoad.page_number;
      delete payLoad.page_size;
      delete payLoad.limit;
      delete payLoad.mongodb_query;
    }
    // Conditionally add a new key
    if (selectAll) {
      delete payLoad.contactId_list;
    }
    return payLoad;
  };

  useEffect(() => {
    setPayLoad(combinePayload());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectAll,
    selectedDocumentIds,
    filterQuery,
    localValues,
    selectedComponent,
  ]);

  // onSubmitCall
  const SubmitReset = () => {
    leadRefresh();
    setSelectedDocumentIds(false);
    setSelectAll(false);
    setSelectedComponent(null);
    setShouldApply(true);
  };

  // Function to open the modal with the selected component
  const openModalWithComponent = (component: any) => {
    setSelectedComponent(component);
  };

  // Function to close the modal and reset the selected component
  const closeModal = () => {
    setSelectedComponent(null);
  };

  const handleDeleteBtn = (item: any) => {
    dispatch(ContactsActions.deleteBulkContact(payLoad))
      .then((res: any) => {
        if (res?.error?.message === "Rejected") {
          showAlert(res?.error?.message || "Contact not deleted", "error");
        } else {
          showAlert(res?.payload?.data?.msg || "Contact deleted", "success");
          setDeleteData(null);
          SubmitReset();
          // leadRefresh();
        }
      })
      .catch((error) => {
        showAlert("something went wrong", "error");
      });
  };

  // export button
  const handleExport = () => {
    // setLoading(true);
    dispatch(ContactsActions.bulkExport(payLoad))
      .then((res: any) => {
        // setLoading(false);
        if (res?.error?.message === "Rejected") {
          showAlert(res?.error?.message || "Contact not exported", "error");
        } else {
          // showAlert("Contact exported", "success");

          // for confirmation after exported
          setSelectedComponent(true);
        }
      })
      .catch((error) => {
        showAlert("something went wrong", "error");
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  // cobine all props

  // delete title
  let subTitle = `Are you sure, you want to delete ${selectedDocumentIds?.length} item`;

  useEffect(() => {
    setCombinedProps({
      closeModal: closeModal,
      selectedDocumentIds: selectedDocumentIds,
      setShouldApply: setShouldApply,
      selectAll: selectAll,
      payload: payLoad,
      SubmitReset: SubmitReset,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLoad, selectedDocumentIds, selectAll, selectedComponent]);

  return (
    <React.Fragment>
      <ProtectedAppButton
        moduleId="bulk_delete"
        onClick={() => setDeleteData(true)}
        variant="danger-filled"
        startIcon=""
        style={{}}
      >
        Delete
      </ProtectedAppButton>
      <ProtectedAppButton
        moduleId="bulk_update_contacts"
        style={{ width: "200px" }}
        variant="grey"
        startIcon="add"
        onClick={() =>
          openModalWithComponent(<UpdateContactsFields {...combinedProps} />)
        }
      >
        <IconButton disableRipple={true}>
          <UpdateIcon />
        </IconButton>
        Update
      </ProtectedAppButton>
      <ProtectedAppButton
        moduleId="bulk_assign_contact"
        style={{ width: "260px" }}
        variant="grey"
        startIcon="add"
        onClick={() => openModalWithComponent(<AssignTo {...combinedProps} />)}
      >
        <IconButton disableRipple={true}>
          <AssignIcon />
        </IconButton>
        Assign to
      </ProtectedAppButton>
      <ProtectedAppButton
        moduleId="bulk_unassign_contacts"
        style={{ width: "180px" }}
        variant="grey"
        startIcon="add"
        onClick={() =>
          openModalWithComponent(<UnassignBulk {...combinedProps} />)
        }
      >
        Unassign
      </ProtectedAppButton>
      <ProtectedAppButton
        moduleId="export_contacts"
        style={{ width: "180px" }}
        variant="grey"
        startIcon="add"
        onClick={() => handleExport()}
      >
        Export
      </ProtectedAppButton>
      <ProtectedAppButton
        moduleId="bulk_add_tags"
        style={{ width: "230px" }}
        variant="grey"
        startIcon="add"
        onClick={() =>
          openModalWithComponent(<AddTags {...combinedProps} type="add_tag" />)
        }
      >
        <IconButton disableRipple={true}>
          <TagIcon />
        </IconButton>
        Add tags
      </ProtectedAppButton>
      <ProtectedAppButton
        moduleId="remove_tags"
        style={{ width: "293px" }}
        variant="grey"
        startIcon="add"
        onClick={() =>
          openModalWithComponent(
            <AddTags {...combinedProps} type="remove_tag" />
          )
        }
      >
        <IconButton disableRipple={true}>
          <TagIcon />
        </IconButton>
        Remove tags
      </ProtectedAppButton>
      <ProtectedAppButton
        moduleId="add_contacts_to_list"
        style={{ width: "180px" }}
        variant="grey"
        startIcon="add"
        onClick={() => openModalWithComponent(<AddToList {...combinedProps} />)}
      >
        Add to list
      </ProtectedAppButton>

      <AppModel
        isShowing={selectedComponent !== null}
        onClose={closeModal}
        divStyle={{
          padding: 0,
        }}
        PaperProps={{
          style: {
            boxShadow: "none",
          },
        }}
      >
        {/* Render the selected component in the modal */}
        {selectedComponent}
      </AppModel>

      {/* export  modal */}
      <ExportConfirmation
        onClose={closeModal}
        selectedComponent={selectedComponent}
      />
      {/* delete confirmation */}
      <AppDeleteItem
        title="Delete Contact"
        subTitle={subTitle}
        onDelete={() => handleDeleteBtn(deleteData)}
        setDeleteData={setDeleteData}
        deleteData={deleteData}
      />
    </React.Fragment>
  );
};
export default BulkActionHeader;
