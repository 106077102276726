import React, { Dispatch, SetStateAction, useState } from 'react'
import { Domainwrapper } from '../DomainStyles'
import { SettingPageMainBoxTitle } from '../../../../../Customization/CommonElements'
import { AppForm, AppFormField } from '../../../../../AppComponents/Forms'
import AppButton from '../../../../../AppComponents/AppButton'
import { AppMaterialIcons } from '../../../../../AppComponents/AppMaterialIcons'
import { LoaderComponentProps } from '../../../../../AppComponents/Loader'
import { useNavigate } from 'react-router-dom'
interface Props {
  formStep: number;
}
const RenderFormStep3: React.FC<Props> = ({ formStep }) => {

  return (
    <div className="mainDivSecond">
      <SettingPageMainBoxTitle>
        <h4>
          Step 2
        </h4>
      </SettingPageMainBoxTitle>
      <SettingPageMainBoxTitle>
        <p>
          To Verify Your Domain,You Need To Set DNS Records For Your Domain
        </p>
      </SettingPageMainBoxTitle>

      <SettingPageMainBoxTitle style={{  padding: "16px 16px 16px 30px", margin: "0 0 48px 0" }}>
        <div className='checktext'><AppMaterialIcons iconName="CheckCircleOutline" style={{ color: "green", marginBottom: "6px" }} /> <p style={{marginBottom: "6px"}}>Open A New Tab And Navigate And Log In To Platform Where Your Domain Is Hosted.</p></div>
        <div className='checktext'><AppMaterialIcons iconName="CheckCircleOutline" style={{ color: "green", marginBottom: "6px" }} /> <p style={{marginBottom: "6px"}}>Find The Screen Where You Can Manage Your Domain.There Will Be An Option To Adjust Your DNS Settings(DNS Zone).</p></div>
        <div className='checktext'><AppMaterialIcons iconName="CheckCircleOutline" style={{ color: "green", marginBottom: "6px" }} /> <p style={{marginBottom: "6px"}}>When Your Find It.You Are Ready For The Next Step!</p></div>

      </SettingPageMainBoxTitle>

    </div>

  )
}

export default RenderFormStep3