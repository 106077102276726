import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import * as Yup from "yup"
import { AppForm, AppFormField, AppFormObserver, AppFormSelectField, AppSubmitButton } from '../../../../../AppComponents/Forms'
import AppFormCheckboxField from "../../../../../AppComponents/Forms/AppFormCheckboxField"
import AppReactSelectField from '../../../../../AppComponents/Forms/AppFormReactSelect'
import { theme } from '../../../../../Customization/Theme'
import { useNotification } from '../../../../../Hooks/useNotification/useNotification'
import { useAppDispatch, useAppSelector } from "../../../../../Store"
import { formBuilderStore, formSettingsStoreData } from '../../../../../Store/Slices/FormBuilder/formbuilder.selectors'
import { addSettings } from "../../../../../Store/Slices/FormBuilder/formbuilder.slice"
import { SMSActions } from '../../../../../Store/Slices/SMS/sms.actions'
import { applicationWidgetFormOptions } from '../../../Components/constants'

const validationSchema = Yup.object().shape({
  capcha_id: Yup.string()
    .label("Capcha Id")
    .min(3, "Declaration must be at least 3 characters")
});
const GeneralSetting = (): JSX.Element => {
  const formRef: any = useRef()
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const formSettings: any = useAppSelector(formSettingsStoreData);
  const formFieldsData: any = useAppSelector(formBuilderStore);
  const [formikProps, setFormikProps] = useState<any>(null);
  const [templateOptions, setTemplateOptions] = useState<any>([]);
  const [paramsOptions] = useState<any>(formFieldsData?.flat()?.filter((field: any) => field?.type !== "dropdown"))
  useEffect(() => {
    const payload: any = {
      limit: 999,
      offset: 0,
    };
    dispatch(SMSActions.getSMSTemplateListData(payload))
      .then((res: any) => {
        let tempArray: any = [];
        res?.payload.data?.results?.map((ele: any) => {
          if (ele.status === "APPROVED") {
            tempArray.push({
              id: ele.id,
              name: ele.name,
              sender_ids: ele.sender_ids,
            });
          }
          return null;
        });
        setTemplateOptions(tempArray);
      }).then(() => {
      }).catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Unable to fetch SMS Templates List for now please try again later.",
          "error"
        );
      })
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <AppForm
      initialValues={{
        capcha_id: formSettings?.capcha_id || "",
        is_application_widget_form: formSettings?.is_application_widget_form === true ? "yes" : "no" || "",
        is_opt_required: formSettings?.is_opt_required || false,
        template_id: formSettings?.template_id || "",
        pdf_download_details: formSettings?.pdf_download_details || "",
        pdf_attachment_url: formSettings?.pdf_attachment_url || "",
        params: formSettings?.params || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values: any, formikProps: any) => {
        const formBuilderObject: any = {
          capcha_id: values.capcha_id,
          is_application_widget_form: values.is_application_widget_form === "yes" ? true : false,
          pdf_download_details: values?.pdf_download_details,
          is_opt_required: values.is_opt_required,
          template_id: values?.template_id || "",
          pdf_attachment_url: values?.pdf_attachment_url,
          params: values?.params,
        }
        showAlert("General Settings Saved Successfully", "success");
        dispatch(addSettings(formBuilderObject))
        formikProps.setSubmitting(false)
      }}
      innerRef={formRef}
    >
      <Container>
        <FieldLabel>General Settings</FieldLabel>
        <div className='fields-container'>
          <AppFormField
            name="capcha_id"
            errorMessage=''
            label='Google Captcha Site Key'
            placeholder='Google Captcha Site Key'
            defaultBorder={true} labelStyle={{ color: theme.palette.default.newFontColor }}
            divStyle={{
              width: "33.33%"
            }}
          />
          <AppFormSelectField
            name="is_application_widget_form"
            Options={applicationWidgetFormOptions}
            popOverMaxWidth={"100%"}
            label='Application Widget Form'
            labelStyle={{ color: theme.palette.default.newFontColor }}
            divStyle={{
              width: "33.33%"
            }}
            enableSearch
          />
          <div style={{ width: "33%" }}></div>
        </div>

        <FieldLabel>File Download Settings</FieldLabel>
        <div className='fields-container'>
          <AppFormField
            name="pdf_attachment_url"
            errorMessage=''
            label='PDF URL'
            placeholder='Enter PDF URL'
            defaultBorder={true}
            labelStyle={{ color: theme.palette.default.newFontColor }}
            divStyle={{
              width: "33.33%"
            }}
          />
        </div>

        <FieldLabel>OPT Settings</FieldLabel>
        <div className='fields-container'>
          <div style={{ minWidth: "32.50%" }}>
            <AppFormCheckboxField name="is_opt_required" label={"OTP Validation Required?"}
              onChange={(e: any) => {
                if (!e.target.checked) {
                  formikProps.setFieldValue('template_id', '');
                }
              }}
            />
          </div>
          {formRef?.current?.values?.is_opt_required ?
            <AppFormSelectField
              name="template_id"
              Options={templateOptions}
              popOverMaxWidth={"100%"}
              errorMessage=''
              label='Choose SMS Template'
              placeholder='Select SMS Template'
              defaultBorder={true}
              labelStyle={{ color: theme.palette.default.newFontColor }}
              divStyle={{ maxWidth: "32.50%" }}
            />
            : null}
        </div>
        <FieldLabel>URL Parameter Settings</FieldLabel>
        <div className='fields-container' style={{ width: "32%" }}>
          <AppReactSelectField
            name={"params"}
            options={paramsOptions}
            label={"Select URL Parameters"}
            displayKey="label"
            valueKey="id"
            isSearchable={true}
            isClearable={true}
            isMulti={true}
            hideSelectedOptions={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No Options Found"
          />
        </div>
        <AppFormObserver setFormikProps={setFormikProps} />
        <div style={{ width: "250px", marginTop: "10px" }}>
          <AppSubmitButton title="Save" variant={"primarydark"} />
        </div>
      </Container>
    </AppForm >
  )
}
const Container = styled.div`
&&{
  padding: 16px;
    .infoText{
      color:${theme.palette.default.darkGrey};
      padding: 0.5rem 0;
    }
    .fields-container{
      display:flex;
      align-items:center;
      gap:20px;
    }
}`

const FieldLabel = styled('p')({
  color: theme.palette.default.newFontColor,
  fontSize: "1.1rem",
  fontWeight: 600,
  padding: "0.8rem 0",
});


export default GeneralSetting;
