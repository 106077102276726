import { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, CircularProgress, Paper, TableContainer, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import uniqid from "uniqid";
import { LoaderComponentProps, withLoader } from "../../../../AppComponents/Loader";
import AppNoDataLoader from "../../../../AppComponents/AppNoDataLoader";

export interface Props extends LoaderComponentProps {
  rows?: any;
}

const InstagramPageTable: React.FC<Props> = ({ rows, setLoading }) => {
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageReady, setPageReady] = useState<boolean>(false)

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



  useEffect(() => {
    setLoading(false);
  }, []);

  // no data found
  if (!rows || rows.length < 1) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "4rem 2rem 2rem 2rem",
          boxShadow: "none important",
          // backgroundColor: 'red',
          height: '480px'
        }}
      >
        <Paper sx={{ width: "100%", mb: 2, boxShadow: "none !important" }}>
          <AppNoDataLoader />
        </Paper>
      </Box>
    )

  }


  return (
    <StyledSessionDevicesBox>
      <TableContainer>
        <Table
          size={"small"}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell key={uniqid()} align={"left"} padding={"normal"}>
                Page Name
              </StyledTableCell>
              <StyledTableCell
                key={uniqid()}
                align={"right"}
                padding={"normal"}
              >
                Status
              </StyledTableCell>
              <StyledTableCell
                key={uniqid()}
                align={"right"}
                padding={"normal"}
              >
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              // rows
              //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              //   .map((singleRow: any, index: number) => {
              // return (
              <StyledTableRow hover tabIndex={-1} key={uniqid()}>
                <StyledTableCell
                  key={uniqid()}
                  align={"left"}
                  padding="normal"
                >
                  {rows.name}
                </StyledTableCell>

                <StyledTableCell
                  key={uniqid()}
                  align={"right"}
                  padding="normal"
                  style={{ color: "#745BFF" }}
                >
                  Active
                </StyledTableCell>

                <StyledTableCell
                  key={uniqid()}
                  align={"right"}
                  padding="normal"
                >
                  Unsubscribe
                </StyledTableCell>
              </StyledTableRow>
              // );
              // })
            }

            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (48) * emptyRows,
                  borderBottom: 'none'
                }}
              >
                <TableCell colSpan={6} style={{

                  borderBottom: 'none'
                }} />
              </TableRow>
            )}

          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          // rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        />
      </TableContainer>
    </StyledSessionDevicesBox>
  );
};

export default withLoader(InstagramPageTable);

const StyledSessionDevicesBox = styled.div`
  && {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    min-height: 480px;
    table{
     
    }
    .extraInfoValue{
      margin-left: 8px;
      padding: 4px 8px;
      font-weight: 400;
      font-size: 12px;
      border-radius: 500px;
      color: #27B783;
      background-color: #D6FFF0;
    }

    .success{
      color: #27B783;
      background-color: #D6FFF0;
    }
    .error{
      color: #F34E4E;
      background-color: #FFE3E3;
    }
  }
`;

const StyledTableCell = styled(TableCell)(({ }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#7E8392",
    fontWeight: "500",
    fontSize: "16px",
    textTransform: "capitalize",
    height: "48px",
    borderBottom: "1px solid #F5F6F8",
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#7E8392",
    // backgroundColor: "red",
    fontWeight: "400",
    fontSize: "16px",
    height: "48px",
    borderBottom: "1px solid #F5F6F8",
    // backgroundColor:'blue',
    // borderRadius:'8px',
    // border: "1px solid #F5F6F8",
  }
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {

  },
  "&:nth-of-type(odd)": {
    // backgroundColor: "blue",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
    // backgroundColor: "yellow",
  },
  "&:hover": {
    backgroundColor: "#F5F6F8",
  },
}));
