export enum MESSAGE_TYPES {
  TEXT = "text",
  IMAGE = "image",
  VIDEO = "video",
  LINK = "link",
  MOBILE = "mobile",
  EMAIL = "email",
  CAROUSEL = "carousel",
  BUTTONS = "buttons",
  CHOICE = "choice",
  FLOW = "flow",
}
export enum UPLOAD_FILE_TYPES {
  IMAGE = "image/png, image/gif, image/jpeg",
  VIDEO = "video/*",
  AUDIO = "audio/*",
  DOCUMENT = ".pdf",
}

export enum WIDGET_POSITIONS_OPTIONS {
  LEFT = "Left",
  CENTER = "Center",
  RIGHT = "Right",
}
export enum TOOLTIP_POSITIONS_OPTIONS {
  TOP = "Top",
  SIDE = "Side",
}

export enum CHANNELS {
  WHATSAPP = "Whatsapp",
  WEB = "Web",
  MESSENGER = "Messenger",
}

export enum GETA_MODULES {
  CHATBOT_MODULE_ID = 1,
}

export enum PreviewType {
  getaWidgetPreview = "getaWidgetPreview",
}


