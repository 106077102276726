// vendors
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

// components
import Icon from "../../Components/Icon";
import Checkbox from "../../Components/Checkbox";

// styled
import { FormControlLabelStyled } from "../NodeWrapper.styled";

type Props = {
  label: string;
  tooltipLabel?: string;
  checked: boolean;
  onCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ActionCheckbox = (props: Props) => {
  const { label, checked, onCheckBoxChange, tooltipLabel } = props;

  return (
    <Box sx={{ p: "16px" }}>
      <FormControlLabelStyled
        control={<Checkbox onChange={onCheckBoxChange} checked={checked} />}
        label={
          <Box
            component="span"
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: "6px",
            }}
          >
            <Typography component="span" variant="body2">
              {label}
            </Typography>
            {tooltipLabel && <Tooltip title={tooltipLabel}>
              <Box
                component="span"
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <Icon icon="query" size={16} color="default.darkGrey" />
              </Box>
            </Tooltip>}
          </Box>
        }
      />
    </Box>
  );
};
export default ActionCheckbox;
