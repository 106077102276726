// vendors
import { useEffect, useState, Fragment, useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemButton from "@mui/material/ListItemButton";
import { useSelector } from "react-redux";
import { AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";
import IconButton from "@mui/material/IconButton";

// components
import { UIButton, Icon, SearchInput, NodeType } from "../../../ui-components";
import DisplayNodes from "./components/display-nodes";

// services
import { FlowBuilderService } from "../../../../../Services/Apis/FlowBuilder";
import { useCurrentBot } from "../../../../../Store/Slices/DashboardSlices";

// contexts
import { BuilderContext } from "../../../flow-builder-v2/contexts";

interface Flow {
  displayOrder: number;
  name: string;
  nodeCounts: number;
  qid: string;
}

const ConnectFlow = ({ onSaveClick, activeButtonData, onCancelClick, language }: any) => {
  const [hierarchyLevel, setHierarchyLevel] = useState(1);
  const [flows, setFlows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [flowId, setflowId] = useState<string | null>(null);
  const [flowOrder, setFlowOrder] = useState(0);
  const [displayNodes, setShowNodes] = useState<string>("");
  const [selectedFlow, setFlow] = useState<{
    qid: string,
    stage: string,
    nodeId: string,
  } | null>(null);
  const currentBot = useSelector(useCurrentBot);
  const botId = { botid: currentBot.bot_id };

  const builderContext = useContext(BuilderContext); 
  const { onAutomateFlowCreate } = builderContext;

  const initFlow = (flowList: any) => {
    for (let index = 0; index < flowList.length; index++) {
      const flow = flowList[index];
      if (flow.displayOrder) {
        flow.displayOrder = flow.displayOrder;
      } else {
        flow.displayOrder = 0;
      }
    }
    return flowList.sort((a: Flow, b: Flow) =>
      a.displayOrder < b.displayOrder
        ? -1
        : Number(a.displayOrder > b.displayOrder)
    );
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleNewFlowCreate = () => {
    FlowBuilderService.createFlow(
      { name: "Untitled" },
      {
        appid: JSON.stringify(botId),
      }
    ).then((response: AxiosResponse<any>) => {
      if (response.data.status === 200) {
        onAutomateFlowCreate({
          name: "Untitled",
          qid: response.data.data.qid,
          displayOrder: response.data.data.displayOrder,
          nodeCounts: 1
        });
        FlowBuilderService.createNode(
          {
            id: uuid(),
            subType: "text",
            qid: response.data.data.qid,
            stage: `${response.data.data.displayOrder}.1`,
            displayOrder: 0,
            response: [
              {
                type: "text",
                value: "<p>This is the first node</p>",
                media: {},
              },
            ],
          },
          { appid: JSON.stringify(botId) }
        );
        FlowBuilderService.getFlows({}, { appid: JSON.stringify(botId) }).then(
          (flowListResponse: AxiosResponse<any>) => {
            if (flowListResponse.data) {
              setSearchResults(initFlow(flowListResponse.data.data));
              setFlows(initFlow(flowListResponse.data.data));
            }
          }
        );
      }
    });
  };
  const handleClick = (id: string, order: number) => {
    setHierarchyLevel(2);
    setFlowOrder(order);
    setflowId(id);
    setShowNodes((prevState: string) => {
      if (prevState !== id) {
        return id;
      } else {
        return prevState;
      }
    });
  };

  const handleBackClick = () => {
    setHierarchyLevel(1);
  };

  const handleNodeSelect = (qId: string, nodeId: string, order: number) => {
    setFlow({
      qid: qId,
      stage: `${flowOrder}.${order}`,
      nodeId: nodeId,
    });
  };

  const handleSaveClick = () => {
    let updatedAction = activeButtonData;
    updatedAction.type = 'flow';
    updatedAction.value.nodeId = selectedFlow?.nodeId;
    updatedAction.value.qid = selectedFlow?.qid;
    updatedAction.value.stage = selectedFlow?.stage;
    updatedAction.value.url = '';
    onSaveClick && onSaveClick(updatedAction);
  };

  const handleCancelClick = () => {
    onCancelClick && onCancelClick();
  };

  useEffect(() => {
    FlowBuilderService.getFlows({}, { appid: JSON.stringify(botId) }).then(
      (response: AxiosResponse<any>) => {
        if (response.data.status === 200) {
          setSearchResults(initFlow(response.data.data));
          setFlows(initFlow(response.data.data));
          // setflowId(connectedFlow?.qid);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (activeButtonData && activeButtonData.type == "flow") {
      setflowId(activeButtonData.value?.qid);
      setFlow(activeButtonData?.value)
    }
  }, [activeButtonData]);

  return (
    <>
      <Box sx={{ p: "16px" }}>
        <UIButton
          startIcon={<Icon icon="nodes" size={12} disableFill />}
          fullWidth
          variant="outlined"
        >
          Connect Flow
        </UIButton>
      </Box>
      {displayNodes && hierarchyLevel == 2 ? (
        <>
          <Box
            sx={{
              py: "4px",
              position: "relative",
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #F5F6F8;",
              borderTop: "1px solid #F5F6F8;",
            }}
          >
            <IconButton
              sx={{
                ml: "5px",
                mr: "-10px",
              }}
              onClick={handleBackClick}
            >
              <Icon icon="chevron-left" color="" size={12} />
            </IconButton>
            <Typography
              fontWeight={400}
              fontSize={14}
              lineHeight="21px"
              color="#7E8392"
              sx={{ ml: '20px' }}
            >
              Node List
            </Typography>
          </Box>
          <DisplayNodes
            activeFlowId={displayNodes}
            flowOrder={flowOrder}
            onNodeSelect={handleNodeSelect}
            selectedNode={selectedFlow?.nodeId}
            connectedFlow={selectedFlow}
          />
        </>
      ) : (
        <Box sx={{ p: "16px" }}>
          <SearchInput
            placeholder="Search"
            size="small"
            onChange={handleSearchInputChange}
          />
          <UIButton
            startIcon={<Icon icon="add" size={10} disableFill />}
            fullWidth
            variant="outlined"
            sx={{ mt: "16px" }}
            onClick={handleNewFlowCreate}
            disabled={language !== 'english'}
          >
            New Flow
          </UIButton>
          <Box
            sx={{
              mt: "16px",
              maxHeight: "200px",
              minHeight: "200px",
              overflowY: "scroll",
            }}
          >
            {searchResults.length > 0 ? (
              <>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                  component="nav"
                >
                  {searchResults.map((flow: any, index: number) => {
                    const openChild = activeButtonData.value?.qid === flow?.qid;
                    return (
                      <Fragment key={`flow-${index + 1}`}>
                        <ListItem
                          disablePadding
                          sx={{
                            "&:hover": {
                              backgroundColor:
                                language !== "english"
                                  ? openChild
                                    ? "#F5F6F8"
                                    : "transparent"
                                  : "#F5F6F8",
                            },
                            border: "1px solid #F5F6F8",
                            ...(openChild && { backgroundColor: "#F5F6F8" }),
                          }}
                        >
                          <ListItemButton
                            sx={{
                              "&:hover": { backgroundColor: "transparent" },
                            }}
                            onClick={() =>
                              handleClick(flow.qid, flow.displayOrder)
                            }
                            disabled={language !== "english"}
                          >
                            <Typography
                              fontWeight={400}
                              fontSize={14}
                              lineHeight="21px"
                              color="#7E8392"
                            >{`${index + 1} ${flow?.name}`}</Typography>
                            {flowId === flow.qid && (
                              <Box sx={{ marginLeft: "auto" }}>
                                <Icon icon="done" size={14} color="#2ECC71" />
                              </Box>
                            )}
                          </ListItemButton>
                        </ListItem>
                      </Fragment>
                    );
                  })}
                </List>
              </>
            ) : (
              <Typography
                fontWeight={400}
                fontSize={12}
                lineHeight="16px"
                color="#7E8392"
              >
                No Flows Available
              </Typography>
            )}
          </Box>
        </Box>
      )}
      <Box sx={{ borderTop: "1px solid #F5F6F8", p: "16px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UIButton variant="outlined" onClick={handleCancelClick}>
            Cancel
          </UIButton>
          <UIButton
            color="primary"
            variant="contained"
            startIcon={<Icon icon="save" color="#fff" size={13} />}
            disabled={
              language !== "english" || !selectedFlow?.qid || !selectedFlow?.nodeId
            }
            onClick={handleSaveClick}
          >
            Save
          </UIButton>
        </Box>
      </Box>
    </>
  );
};
export default ConnectFlow;
