import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FacebookSettingsActions } from "./facebookSettings.actions";

interface initialStateTypes {
  data: {
    facebookConfigData: any;
    facebookConfigList: any;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    facebookConfigData: "",
    facebookConfigList: "",
  },
  error: "",
  loading: false,
};

const FacebookSettingsSlice = createSlice({
  name: "FacebookSettingsSlice",
  initialState,
  reducers: {
    resetError: (state: any, action?: PayloadAction<any>) => {
      state.error = "";
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      // add  Facebook config data
      .addCase(
        FacebookSettingsActions.facebookConfigData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        FacebookSettingsActions.facebookConfigData.fulfilled,
        (state, action) => {
          if (action?.payload?.data?.status === 200) {
            state.data.facebookConfigData = true;
            state.data.facebookConfigList = "";
          } else {
            state.data.facebookConfigData = null;
            state.data.facebookConfigList = "";
          }
        },
      )
      .addCase(
        FacebookSettingsActions.facebookConfigData.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // get  facebook config data
      .addCase(
        FacebookSettingsActions.facebookConfigList.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        FacebookSettingsActions.facebookConfigList.fulfilled,
        (state, action) => {
          state.data.facebookConfigList = action?.payload?.data;
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        FacebookSettingsActions.facebookConfigList.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // get  Facebook config data
      .addCase(
        FacebookSettingsActions.logoutFacebookConfiguration.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        FacebookSettingsActions.logoutFacebookConfiguration.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        FacebookSettingsActions.logoutFacebookConfiguration.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // get  Facebook Disconnect page
      .addCase(
        FacebookSettingsActions.disconnectSingleFacebookPage.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        FacebookSettingsActions.disconnectSingleFacebookPage.fulfilled,
        (state, action) => {
          state.error = "";
          state.loading = false;
        },
      )
      .addCase(
        FacebookSettingsActions.disconnectSingleFacebookPage.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      );
  },
});
export const { resetError } = FacebookSettingsSlice.actions;
export default FacebookSettingsSlice.reducer;
