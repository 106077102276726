import { RootState } from "../../index";

export const dashboardDataStore = (state: RootState) =>
  state.dashboard.data.dashboardUsers;
export const dashboardReturningLeads = (state: RootState) =>
  state.dashboard.data.returning_leads;
export const dashDuration = (state: RootState) => state.dashboard.data.duration;
export const dashConversationStatus = (state: RootState) =>
  state.dashboard.data.conversation_status;
export const dashAgentStatus = (state: RootState) =>
  state.dashboard.data.agent_status;
export const dashUserGrowth = (state: RootState) =>
  state.dashboard.data.user_growth;
export const dashSummaryMsg = (state: RootState) =>
  state.dashboard.data.summary_msg_dash;
export const dashBotConversation = (state: RootState) =>
  state.dashboard.data.bot_conversation;
export const dashResponseTime = (state: RootState) =>
  state.dashboard.data.response_time;
export const dashChannelOverview = (state: RootState) =>
  state.dashboard.data.channel_overview;
export const dashCoversationalTraffic = (state: RootState) =>
  state.dashboard.data.conversational_traffic;
export const dashSummaryIntents = (state: RootState) =>
  state.dashboard.data.summary_intents;
export const overallData = (state: RootState) => state.dashboard.data.overall;
export const botData = (state: RootState) => state.dashboard.data.bot;
export const agentData = (state: RootState) => state.dashboard.data.agent;
export const agentConversationStatus = (state: RootState) =>
  state.dashboard.data.agent_conversation_status;
export const botConversationStatus = (state: RootState) =>
  state.dashboard.data.bot_conversation_status;
export const AgentID = (state: RootState) => state.dashboard.data.AgentBotID;
export const AgentChannel = (state: RootState) =>
  state.dashboard.data.AgentChannelName;
export const BotList = (state: RootState) => state.dashboard.BotId;
export const BotTypeName = (state: RootState) => state.dashboard.Bottype;
export const Loading = (state: RootState) => state.dashboard.data.loading;
export const ChannelOverview = (state: RootState) =>
  state.dashboard.data.channel_overview;
export const dateRange = (state: RootState) => state.dashboard.data.DateRange;
export const agentDateRange = (state: RootState) =>
  state.dashboard.data.agentDateRange;
export const botDateRange = (state: RootState) =>
  state.dashboard.data.botDateRange;
export const AnalyticsLoadingArray = (state: RootState) =>
  state.dashboard.loadingArray;
export const project_id = (state: RootState) => state.dashboard.project_id;

// Applciation Form Data
export const applicationFormData = (state: RootState) => state.dashboard.data.applicationFormData;

//Contact vs Application
export const contactVsApplicationData = (state: RootState) => state.dashboard.data.contactVsApplicationData;

// Application Payment Filter
export const applicationPaymentFIlter = (state: RootState) => state.dashboard.data.applicationPaymentFIlter;

// Application Payment Filter City
export const applicationPaymentFIlterCity = (state: RootState) => state.dashboard.data.applicationPaymentFIlterCity;

// Application Date Filter
export const applicationDateFIlter = (state: RootState) => state.dashboard.data.applicationDateFilter;

// Application Submission Filter
export const applicationSubmissionFilter = (state: RootState) => state.dashboard.data.applicationSubmissionFilter;

// Application Analytics Filter
export const applicationAnalyticsFilter = (state: RootState) => state.dashboard.data.applicationAnalyticsFilter;

// Application Filter
export const applicationFilter = (state: RootState) => state.dashboard.data.applicationFilter;

// Application Data
export const applicationData = (state: RootState) => state.dashboard.data.applicationData;

// Application Data Online OFfline
export const applicationFilterOfflineOnline = (state: RootState) => state.dashboard.data.applicationFilterOfflineOnline;

// Table Data
export const storedTableDataSelector = (state: RootState) => {
  return state?.dashboard?.data?.tableData;
};

// Error in API
export const applicationFormDataError = (state: RootState) =>
  state?.dashboard?.data?.applicationFormDataError



export const exportLogsData = (state: RootState) => state?.dashboard?.data?.exportLogsData;