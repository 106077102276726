import { IconButton, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import { theme } from "../../Customization/Theme";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import useFlowBuilder from "../../Modules/FlowBuilder/Hooks/useFlowBuilder";
import { getBotTrackingCodeFn } from "../../Services/Apis";
import { ConversationService } from "../../Services/Apis/ConversationService";
import { ParaphraserService } from "../../Services/Apis/ParaphraserService";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { FaqSourceAction } from "../../Store/Slices/Faq/Source/source.actions";
import { formatString, getLastUrlSegment, isEmptyObject } from "../../Utils";
import ChannelsBotLivePreview from "./LivePreviewWidget/ChannelsBotLivePreview";
import WebBotLivePreview from "./LivePreviewWidget/WebBotLivePreview";

export interface Props extends HocBackdropLoaderProps { }

const BotBuilder: React.FC<Props> = ({ setLoading }) => {
  const { showAlert } = useNotification();
  useDocumentTitle("Bot Builder");
  const { bot_id }: any = useParams();
  const { userConfig }: any = useAppSelector(useAuthStore);
  const currentBot: any = useAppSelector(useCurrentBot);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<any>(0);
  const [isBotPreviewOpen, setIsBotPreviewOpen] = useState<boolean>(false);
  // const [tabsOptions, setTabsOptions] = useState<any>([]);

  const { checkValidity } = useFlowBuilder();
  let dispatch = useAppDispatch();

  useEffect(() => {
    if (currentBot?.id) {
      getBotTrackingCodeFn(dispatch)({
        pathParams: [currentBot?.id],
      });
    }
  }, [currentBot?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrentTabAfterRefresh = () => {
    memoizedMenuOptions?.forEach((item: any, index: number) => {
      if (location.pathname.includes(item?.value)) {
        setValue(index);
      }
    });
  };

  const memoizedMenuOptions = useMemo(() => {
    const permissionsData: any =
      userConfig?.payload?.allowed_permissions ?? null;

    if (isEmptyObject(permissionsData)) return;

    const result =
      userConfig?.payload?.menu_items?.["bot_builder_tabs_options"] ?? [];
    return result;
  }, [userConfig?.payload]); // eslint-disable-line react-hooks/exhaustive-deps

  //*  do not remove location dependency
  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [memoizedMenuOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const sourceType = getLastUrlSegment(location?.pathname);
  // headers
  const HEADERS = {
    appid: JSON.stringify({
      botid: bot_id,
    }),
  };
  //fetching all Tabs Data Function
  const getAllTabsData = () => {
    const dataForTabs: any = {
      HEADERS,
      sourceType: sourceType === "document" ? "file" : sourceType,
      offset: 0,
      limit: 10,
      query: "",
    };

    dispatch(FaqSourceAction.GeteSourceListing(dataForTabs))
      .then((res: any) => {
        setLoading(false);
      })
      .catch((error: any) => {
        showAlert(
          error?.response?.statusText || "Enabled to fetch Data",
          "error"
        );
      });
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleRedirect(memoizedMenuOptions?.[newValue]?.url);
  };
  const handleRedirect = (subUrl: string) => {
    const url = formatString(subUrl, [bot_id || currentBot.bot_id]);
    navigate(url);
  };

  const publishBotFn = () => {
    const HEADERS = {
      appid: JSON.stringify({
        botid: bot_id || currentBot.bot_id,
      }),
    };
    const { isValid, error } = checkValidity();
    if (!isValid) {
      showAlert(error, "error");
      return;
    }
    setLoading(true);
    ConversationService.publishBot({}, HEADERS)
      .then((res: any) => {
        showAlert("Bot Published", "success");
        //only call this on faq bot knowledge base
        if (value === 2) {
          getAllTabsData();
        }
      })
      .catch((error: any) => {
        showAlert("Something went wrong", "error");
      });

    ParaphraserService.trainBot({}, HEADERS)
      .then((res: any) => {
        showAlert("Bot trained", "success");
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const previewBot = () => {
    const { isValid, error } = checkValidity();
    if (!isValid) {
      showAlert(error, "error");
      return;
    }
    setIsBotPreviewOpen(!isBotPreviewOpen);
  };
  const handleSeeAllBots = () => {
    const subUrl = `/workspace/{0}/setup/bots`;
    const workspace_id: string = window.localStorage.getItem("ws_id") || "";
    const url = formatString(subUrl, [workspace_id]);
    navigate(url);
    return;
  };

  if (memoizedMenuOptions?.length === 0) return null;

  return (
    <BotBuilderWrap>
      <BuilderTitleBox>
        <div className="botTitle">
          <IconButton onClick={() => handleSeeAllBots()}>
            <AppMaterialIcons
              iconName="ArrowBackIos"
              style={{ fontSize: "1.1rem" }}
            />
          </IconButton>
          <p>{currentBot?.name} </p>
        </div>
        <div className="builderTopActionBox">
          <AppButton variant="grey" onClick={previewBot}>
            Preview
          </AppButton>
          {currentBot?.bot_id?.length > 0 ? (
            <>
              {["whatsapp", "telegram", "messenger"].includes(currentBot?.channel) ? (
                <ChannelsBotLivePreview
                  setIsBotPreviewOpen={setIsBotPreviewOpen}
                  isBotPreviewOpen={isBotPreviewOpen}
                />
              ) : (
                <WebBotLivePreview
                  setIsBotPreviewOpen={setIsBotPreviewOpen}
                  isBotPreviewOpen={isBotPreviewOpen}
                />
              )}
            </>
          ) : null}

          <AppButton startIcon="ri-rocket-line" onClick={publishBotFn}>
            Publish
          </AppButton>
        </div>
      </BuilderTitleBox>
      <TopTabbarWrap>
        <TabsBox>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledBuilderTabs
                value={value}
                onChange={handleChange}
                aria-label="bot builder"
                variant="scrollable"
                scrollButtons="auto"
              >
                {memoizedMenuOptions &&
                  memoizedMenuOptions?.length > 0 &&
                  memoizedMenuOptions.map((curElem: any, index: number) => {
                    return (
                      <Tab
                        label={curElem?.label}
                        id={`simple-tab-${index}`}
                        key={index}
                        disableRipple
                      />
                    );
                  })}
              </StyledBuilderTabs>
            </Box>
          </Box>
        </TabsBox>
        <ActionBox></ActionBox>
      </TopTabbarWrap>

      {memoizedMenuOptions &&
        memoizedMenuOptions?.length > 0 &&
        memoizedMenuOptions.map((curElem: any, index: number) => {
          return (
            <TabPanel value={value} index={index} key={index}>
              <Outlet />
            </TabPanel>
          );
        })}
    </BotBuilderWrap>
  );
};

export default HocBackdropLoader(BotBuilder);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: "100%", overflowY: "auto" }}
    >
      {value === index && (
        <BotBuilderContentBox>{children}</BotBuilderContentBox>
      )}
    </div>
  );
}

const BotBuilderWrap = styled.div`
  && {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
    height: 100%;
    background-color: #f5f6f8;

    overflow: hidden;
  }
`;
const BuilderTitleBox = styled.div`
  && {
    display: flex;

    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    gap: 1rem;
    width: 100%;
    min-height: 64px;
    height: 64px;
    overflow: hidden;

    background-color: white;

    .botTitle {
      display: flex;
      align-items: center;
      width: 50%;
      gap: 0.5rem;
      height: 100%;

      p {
        font-size: 1rem;
        font-weight: 500;
        line-height: 21px;
        color: #7e8392;
        text-transform: capitalize;
      }
    }

    .builderTopActionBox {
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 1.5rem;
      width: 50%;
      height: 100%;
      button:hover .ri-rocket-line {
        color: ${theme.palette.primary.dark} !important;
      }
    }
  }
`;
const TopTabbarWrap = styled.div`
  && {
    display: flex;

    justify-content: space-between;
    padding: 0 1.5rem 0 1.1rem;
    gap: 0.5rem;
    width: 100%;
    min-height: 40px;
    height: 40px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08) !important;
  }
`;
const TabsBox = styled.div`
  && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    background-color: white;

    .MuiBox-root {
      border: none !important;
    }
  }
`;
const ActionBox = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0.5rem;
    width: 40%;
    height: 100%;
    background-color: white;
  }
`;

const BotBuilderContentBox = styled.div`
  && {
    display: flex;

    gap: 0.5rem;
    width: 100%;
    height: 100%;

    overflow: hidden;
  }
`;

export const StyledBuilderTabs = styled(Tabs)`
  && {
    .MuiTabs-indicator {
      background-color: #5b73e8;
    }
    .Mui-selected {
      color: #5b73e8 !important;
      background-color: white;
    }
    .MuiTab-root {
      min-height: 38px;
      max-height: 38px;
      height: 38px;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      color: #7e8392;
      text-transform: capitalize;
      margin-right: 1rem;

      overflow: hidden;
    }
  }
`;
