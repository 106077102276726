import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { getIn, useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";
import { DecsText, Label } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  Options: any;
  divStyle?: any;
  row?: boolean;
  sendValuesToParent?: (values: any) => void | undefined;
  tooltip?: any;
  children?: React.ReactNode;
  disabled?: boolean;
  labelStyle?: React.CSSProperties;
  description?: string;
  optionalText?: string;
  sendOnlyValue?: boolean;
  onChangeCallbackFn?: (value: any) => void;
  [otherProps: string]: any;
}

const AppFormRadioField: React.FC<Props> = ({
  label,
  name,
  Options,
  divStyle,
  row = true,
  sendValuesToParent,
  tooltip,
  children,
  disabled = false,
  labelStyle,
  description,
  optionalText,
  sendOnlyValue,
  onChangeCallbackFn,
  ...otherProps
}) => {
  const { errors, touched, values, setFieldValue, setFieldError } =
    useFormikContext<any>();
  useEffect(() => {
    if (Options[0]?.value) {
      setFieldValue(name, Options[0]?.value);
    }
  }, [Options[0]?.value]);

  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getIn(values, name) && typeof getIn(values, name) === "string") {
      const selectedOption = Options?.find(
        (option: any) => option?.value === getIn(values, name)
      );
      if (selectedOption) {
        if (sendOnlyValue) {
          setFieldValue(name, selectedOption.value);
        } else {
          setFieldValue(name, selectedOption);
        }
        setFieldError(name, undefined);
      }
    }
  }, [getIn(values, name)]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleValueChange = (event: any) => {
    const selectedOption = Options.find(
      (option: any) => option.value === event.target.value
    );
    if (selectedOption) {
      if (sendOnlyValue) {
        setFieldValue(name, selectedOption.value);
      } else {
        setFieldValue(name, selectedOption);
      }

      onChangeCallbackFn && onChangeCallbackFn(selectedOption);
    }
  };
  return (
    <Div style={divStyle}>
      <FormControl style={{ width: "100%" }}>
        <div className="textLabelOptional">
          <Label style={labelStyle}>
            {label}{" "}
            {tooltip && (
              <Tooltip title={tooltip} placement="top">
                <HelpOutlineIcon />
              </Tooltip>
            )}
          </Label>
          {optionalText && (
            <span style={{ color: "rgba(126, 131, 146, 1)" }}>
              {optionalText}
            </span>
          )}
        </div>
        {description && <DecsText>{description}</DecsText>}
        <RadioGroup
          name={name}
          defaultValue={Options[0]?.value}
          onChange={(event: any) => handleValueChange(event)}
          value={
            getIn(values, name)?.value ||
            getIn(values, name) ||
            Options[0]?.value ||
            ""
          }
          row={row}
          {...otherProps}
        >
          {Options &&
            Options.length > 0 &&
            Options.map((curItem: any, index: number) => {
              return (
                <FormControlLabel
                  disabled={disabled || curItem?.disabled}
                  key={index.toString()}
                  value={curItem?.value}
                  control={<Radio />}
                  label={
                    curItem?.image ? (
                      curItem?.image
                    ) : (
                      <>
                        <span className="main_label">
                          {curItem?.name || curItem?.label}
                        </span>
                        <br />
                        <span className="main_label_info">
                          {curItem?.infoText}
                        </span>
                      </>
                    )
                  }
                />
              );
            })}
        </RadioGroup>
        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </FormControl>
    </Div>
  );
};

export default AppFormRadioField;
const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    margin-bottom: 0.5rem;
    .MuiFormControlLabel-label {
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.5px;
      color: ${theme.palette.default.darkGrey};
    }
    .MuiRadio-root {
      margin: 0 0.5rem 0 0;
    }
    .MuiFormControlLabel-root {
      margin: 0 !important;
      margin-right: 1rem !important ;
    }
    .textLabelOptional {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 6px;
    }

    .radioImage {
      width: 100%;
      height: 60px;
      object-fit: contain;
    }
  }
`;
