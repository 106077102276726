import React, { useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { BotKnowladgeUserQueriesOptions } from "../../../../Models/defaultSelectOptions";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { formatString } from "../../../../Utils";
import { CustomTabPanelContainer, WebsiteWrap } from "../BotKnowledgeStyles";

const NotAnsweredIndex = () => {
  const [value, setValue] = React.useState(0);

  const currentBot = useSelector(useCurrentBot);
  const navigate = useNavigate();
  const location = useLocation();
  const getCurrentTabAfterRefresh = () => {
    BotKnowladgeUserQueriesOptions.map((item: any, index: number) => {
      if (location.pathname.includes(item?.value)) {
        setValue(index);
      }
    });
  };
  const handleRedirect = (subUrl: string) => {
    const url = formatString(subUrl, [currentBot.bot_id]);
    navigate(url);
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleRedirect(BotKnowladgeUserQueriesOptions[newValue].url);
  };
  useEffect(() => {
    getCurrentTabAfterRefresh();
  }, [location]);
  return (
    <WebsiteWrap>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex" }}>
          <Tabs
            className="website-tabs"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {BotKnowladgeUserQueriesOptions &&
              BotKnowladgeUserQueriesOptions &&
              BotKnowladgeUserQueriesOptions.map(
                (curElem: any, index: number) => {
                  return (
                    <Tab
                      label={curElem?.name}
                      id={`simple-tab-${index}`}
                      key={index}
                    />
                  );
                }
              )}
          </Tabs>
          <Box></Box>
        </Box>
        {BotKnowladgeUserQueriesOptions &&
          BotKnowladgeUserQueriesOptions &&
          BotKnowladgeUserQueriesOptions.map((curElem: any, index: number) => {
            return (
              <CustomTabPanel value={value} index={index} key={index}>
                <Outlet />
              </CustomTabPanel>
            );
          })}
      </Box>
    </WebsiteWrap>
  );
};

export default NotAnsweredIndex;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <CustomTabPanelContainer
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </CustomTabPanelContainer>
  );
}
