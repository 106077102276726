import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { MyBotCard } from "../../Customization/CommonElements";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import { Chatbot } from "../../Models";
import { useMyBotsStore } from "../../Services/Apis/ChatbotConsoleApis";
import { getProjectConfigFn } from "../../Services/Apis/CoreApis";
import { CORE_ENDPOINTS } from "../../Services/Apis/Endpoints";
import { useAuthStore } from "../../Store/Slices/AuthSlice";
import { ChangeSelectedBot } from "../../Store/Slices/DashboardSlices";
import { formatString } from "../../Utils";
import { ReactComponent as ManageBot } from "../../assets/images/bot.svg";
import defaultAvatar from "../../assets/images/botbuilderbot.webp";
import { ReactComponent as EditBot } from "../../assets/images/edit.svg";
import { ReactComponent as CahnnelWebbot } from "../../assets/images/webbot.svg";
import { ReactComponent as CahnnelWhatsappBot } from "../../assets/images/whatsappbot.svg";
import { ReactComponent as CahnnelFacebookBot } from "../../assets/images/facebook-icon.svg";
import { ReactComponent as CahnnelTelegramBot } from "../../assets/images/telegram.svg";
import WelcomeBotScreen from "./WelcomeBotScreen";
import { ProtectedAppUiElement } from "../../AppComponents/ProtectedComponents";

interface Props {
  children?: React.ReactNode;
  loading?: any;
}

const Chatbots = (props: Props) => {
  const dispatch = useDispatch();
  useDocumentTitle("Chatbots");
  const { userConfig } = useSelector(useAuthStore);
  const tabsMenuOptionFirstUrl: any =
    userConfig?.payload?.menu_items?.bot_builder_tabs_options?.[0]?.url ?? null;

  const { data: paginatedMyBots } = useSelector(useMyBotsStore);
  const [myBots, setMyBots] = useState<Chatbot[]>([]);

  const navigate = useNavigate();

  const handleManageBot = (bot: Chatbot) => {
    dispatch(ChangeSelectedBot(bot));
    const proj_v_url = formatString(CORE_ENDPOINTS.PROJECT_VER_CONFIG, [
      bot.project_version_id,
    ]);
    getProjectConfigFn(dispatch)({ url: proj_v_url }).then((res: any) => {});

    let tempUrl =
      tabsMenuOptionFirstUrl ?? "/bot/{0}/setup/customize/look-feel";
    let URL = formatString(tempUrl, [bot?.bot_id]);
    navigate(URL);
  };

  const renderChannelIcon = (channle: any) => {
    if (channle === "whatsapp") {
      return <CahnnelWhatsappBot />;
    } else if (channle === "messenger") {
      return <CahnnelFacebookBot width={"38px"} height={"38px"} />;
    } else if (channle === "telegram") {
      return <CahnnelTelegramBot width={"38px"} height={"38px"} />;
    }
    return <CahnnelWebbot />;
  };

  useEffect(() => {
    if (
      paginatedMyBots &&
      paginatedMyBots?.results &&
      paginatedMyBots?.results.length > 0
    ) {
      props.loading.setLoading(false);
      setMyBots(paginatedMyBots.results);
    }
  }, [paginatedMyBots]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEditBtn = (bot: Chatbot) => {
    navigate(`/workspace/${bot?.workspace_id}/setup/edit-bot/${bot?.bot_id}`);
  };

  return (
    <Box sx={{ height: "inherit" }}>
      {myBots && myBots.length ? (
        <Box
          sx={{
            flexGrow: 1,
            padding: "1rem 2rem",
            height: "100%",
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, xl: 4 }}
            columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
          >
            {myBots &&
              myBots.length > 0 &&
              myBots.map((bot: Chatbot | any, index: number) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={index}>
                    <MyBotCard elevation={0} key={index}>
                      <div className="botTitleBox">
                        <Box className="avatar">
                          <img
                            src={
                              bot.company_logo
                                ? bot.company_logo
                                : defaultAvatar
                            }
                            alt=""
                          />
                        </Box>
                        <div className="botExtraBtns">
                          <Box className="bot-timestamp">
                            <span className="date">
                              {moment(bot.modified_time).format("MMMM D, YYYY")}
                            </span>
                            <span className="time">
                              {moment(bot.modified_time).format("h:mm A")}
                            </span>
                          </Box>
                          {renderChannelIcon(bot?.channel)}
                        </div>
                      </div>
                      <div className="botInfoBox">
                        <p title={bot?.name} className="botTitle">
                          {bot?.name}
                        </p>
                        <p className="botSummary">{bot.description}</p>
                      </div>
                      <div className="botChannelsBox"></div>

                      <ProtectedAppUiElement moduleId="manage_bot">
                        <div
                          className={`botActionBox ${
                            bot?.is_active ? "" : "disable_buttons"
                          }`}
                        >
                          <Box
                            className="actions right_border"
                            onClick={() => handleManageBot(bot)}
                          >
                            <ManageBot />
                            <p>Manage</p>
                          </Box>
                          <Box
                            className="actions"
                            onClick={() => handleEditBtn(bot)}
                          >
                            <EditBot />
                            <p>Edit</p>
                          </Box>
                        </div>
                      </ProtectedAppUiElement>
                    </MyBotCard>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      ) : (
        <WelcomeBotScreen />
      )}
    </Box>
  );
};

export default Chatbots;
