import styled from "styled-components";
import { theme } from "../../Customization/Theme";


export const HeaderWrapper = styled.div`
 &{
      font-size:20px;
      display:flex;
      align-items:center;
      padding:10px 24px 10px 20px;
      border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
      .div_tooltip{
         font-size: 10px;
      }
 }`;

export const WrapperD = styled.div`
    & {
        display:flex;
        justify-content:space-between;
        .p{
            font-size:18px;
            padding:15px 0;
           }
    }
 `;


export const WholeWrap = styled.div`
  & {
    display:flex;
    flex-direction:column;
    gap:17px;
    .ResponseTimeHeader{
       font-size:16px;
       font-weight:500;
       margin-left:-10px;
    }
    .p{
       font-size:14px;
       font-weight:400;
    }
  }
 `;


export const GraphContainer = styled.div`
&&{
   padding: 10px 24px;
}`
