import moment from "moment-timezone";
import { getText } from "../Components/FlowBuilder/nodes/utils/helper";

export const humanizeDate = (date: any, time: boolean = false) => {
  return moment(date).format(`LL${time ? "L" : ""}`);
};

export function formatString(string: string, replacements: string[]): string {
  return string?.replace(/{(\d+)}/g, function (match, number) {
    return typeof replacements[number] !== "undefined"
      ? replacements[number]
      : match;
  });
}

export const searchStringInArray = (string: string, array: any) => {
  for (var j = 0; j < array.length; j++) {
    if (array[j].match(string)) return true;
  }
  return false;
};
export const getTextWithoutHtml = (string: string) => {
  let strippedHtml = string?.replace(/<[^>]+>/g, "");
  return strippedHtml;
};

export const makeStringFirstLetterUppercase = (string: string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const makeStringCapitalizeAndRemoveSpace = (string: string) => {
  const words = string.split("_");
  const capitalizedString = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedString;
};
export const checkForDuplicatesArrayValues = (array: string[]) => {
  const results: string[] = array?.filter((element: any) => {
    return element !== "";
  });
  return results?.filter(
    (item: any, index: number) => results?.indexOf(item) === index
  );
};

export const checkDuplicatesArray = (array: string[]) => {
  for (let i = 0; i < array?.length; i++) {
    if (array.indexOf(array[i]) !== array?.lastIndexOf(array[i])) {
      return true;
    }
  }
  return false;
};

export const isEmptyObject = (obj: object | any) => {
  if (!obj) {
    return true;
  } else {
    return Object.keys(obj)?.length === 0;
  }
};

export const defaultAvatarWithFistLetter = (string: string) => {
  return string.charAt(0).toUpperCase();
};

export const RemoveArrayElementsFromOtherArray = (
  originalArray: any,
  toRemovedArray: any
) => {
  let temp = originalArray.filter((el: never) => !toRemovedArray.includes(el));
  return temp;
};
export const RemoveItemFromArray = (originalArray: any, item: any) => {
  //send complete item not just index
  let temp = originalArray.filter((i: any) => i !== item);
  return temp;
};

export const kFormatter = (num: any) => {
  if (num) {
    const absoluteNum = Math.abs(num);
    if (absoluteNum > 999) {
      return `${(Math.sign(num) * (absoluteNum / 1000)).toFixed(1)}k`;
    } else {
      return Math.sign(num) * absoluteNum;
    }
  } else if (!isNaN(parseInt(num))) {
    return num;
  } else {
    return 0;
  }
};

export const getLastWordFromStringAfterSlash = (pathname: string) => {
  let temp = pathname.substring(pathname.lastIndexOf("/") + 1);
  return temp;
};

export const getRoundOffNumbers = (number: any) => {
  // let temp = new Intl.NumberFormat('en',{notation: 'compact', maximumSignificantDigits: 4, style: 'currency',currency:'INR'}).format(number);
  let temp = number;
  if (number) {
    temp = new Intl.NumberFormat("en", {
      notation: "compact",
      maximumSignificantDigits: 5,
    }).format(number);
    return temp;
  }
  if (parseFloat(temp).toString() !== "NaN") return temp;
  return 0;
};

export const getRegexesForMenuOptions = (menuOptions: any) => {
  const regexes = menuOptions?.map((m: any) => {
    const b = m.url?.replace(
      "{0}",
      "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"
    );
    const finalString = `^${b}$`;
    const finalRegex = new RegExp(finalString);
    m.urlRegex = finalRegex;
    return m;
  });
  return regexes;
};

export const getRegexesForAccordionOptions = (menuOptions: any) => {
  let a: any = [];
  const regexes = menuOptions?.map((m: any, index: number) => {
    let temp = m?.options?.map((n: any, index2: number) => {
      const b = n?.url?.replace(
        "{0}",
        "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"
      );
      const finalString = `^${b}$`;
      const finalRegex = new RegExp(finalString);
      n.urlRegex = finalRegex;
      n.categoryIndex = index;
      a.push(n);
      return n;
    });
    // a=[...temp]
    return temp;
  });
  // return reagexes;
  return a;
};

export const getModifiedRegexesForAccordionOptions = (menuOptions: any) => {
  let modifiedOptions: any = [];

  menuOptions?.forEach((menu: any, index: number) => {
    let modifiedChildren: any = [];

    menu?.children?.forEach((child: any, index2: number) => {
      const regexString = child?.url?.replace(
        "{0}",
        "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}"
      );
      const finalString = `^${regexString}$`;
      const finalRegex = new RegExp(finalString);

      modifiedChildren.push({
        ...child,
        urlRegex: finalRegex,
        categoryIndex: index,
      });
    });

    modifiedOptions.push({
      ...menu,
      children: modifiedChildren,
    });
  });

  return modifiedOptions?.flatMap((menu: any) => menu.children);
};

export const getFaqVaritionProgressValue = (length: number) => {
  // let length = a.length || 0;

  if (length == 1) {
    return 20;
  } else if (length == 2) {
    return 40;
  } else if (length == 3) {
    return 60;
  } else if (length == 4) {
    return 80;
  } else if (length > 4) {
    return 100;
  } else {
    return 0;
  }
};

export const InsertArrayElementAtIndex = (
  arr: [],
  index: number,
  newItem: any
) => [...arr.slice(0, index), newItem, ...arr.slice(index)];

export const getNestedPropertyByString = (
  object: any,
  firstKey: string | number,
  secondKey: string | number
) => {
  let t: any = object?.[firstKey];
  let p: any = t && t.length > 0 && t?.[secondKey];
  return p;
};
export const getDefaultAvatar = (text: string = "U", index: number = 0) => {
  if (typeof text !== "string") {
    text = "U";
  }
  let a = text || "U";
  let result = a?.trim().charAt(index).toUpperCase() || "U";
  if (result === "+" || typeof result === "number") {
    result = "W";
  }
  return result;
};
export const getDefaultValue = (
  fieldArray: any,
  fieldArrayKey: any,
  defalutValue: any
) => {
  if (fieldArray) {
    const value = fieldArray?.filter(
      (item: any) => item?.[fieldArrayKey] === defalutValue
    );
    return value ? value[0] : {};
  }
};

export const simplifyMessages = (newMessages: any) => {
  let temp: any = [];
  newMessages &&
    newMessages.length > 0 &&
    newMessages.forEach((item: any, index: number) => {
      item?.response?.forEach((singleMsg: any, index2: number) => {
        if (item.time) {
          singleMsg.time = item.time;
        }

        temp.push(singleMsg);
      });
    });
  return temp;
};

export function capitalizeFirstLetter(string: any) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getReactSelectCurrentValue = (
  opts: any[],
  val: string,
  valueKey: string = "value",
  showPlaceholder: boolean = true
) => {
  if (val === undefined || val === null)
    return showPlaceholder ? null : opts?.[0] ?? null;

  if (typeof val === "object") return val;

  const found = opts?.find((o: any) => o?.[valueKey] === val);
  if (found) return found;
};

export const getReactSelectMultiCurrentValues = (
  opts: any[],
  vals: string[],
  valueKey: string = "value",
  showPlaceholder: boolean = true
) => {
  if (!vals || vals.length === 0)
    return showPlaceholder ? null : opts?.[0] ?? null;
  const found = opts?.filter((o: any) => vals.includes(o?.[valueKey]));
  if (found && found.length > 0) return found;

  return null;
};

const AGENT_ROLE_ID = 3;
export const isChatAgentLoggedIn = (authData: any) => {
  if (authData) {
    if (authData && authData?.data && authData?.data?.user) {
      if (
        authData.data?.user?.groups &&
        authData.data?.user?.groups?.length > 0
      ) {
        const currGroup = authData.data?.user?.groups[0];
        return currGroup === AGENT_ROLE_ID;
      }
    }
  }
  return false;
};

export const getDisplayValidNodes = (nodes: any) => {
  let validNodes = [];
  let displayValidTextNodes: any = [];
  if (nodes && nodes.length > 0) {
    validNodes = nodes.filter((node: any) => {
      if (getText(node?.response) !== "") {
        return node;
      }
    });
  }

  validNodes.forEach(
    (
      {
        id,
        text,
        name,
        isValid,
        response,
        ...rest
      }: {
        id: any;
        text: any;
        isValid: boolean;
        response: any;
        name: string;
      },
      nodeIndex: number
    ) => {
      const messageText = getText(response)?.replace(/<[^>]+>/g, "");
      let temp = {
        nodeIndex,
        id,
        name: messageText,
      };
      displayValidTextNodes.push(temp);
    }
  );

  return displayValidTextNodes;
};

export function setWithExpiry(key: string, ttl: any) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    expiry: now.getTime() + ttl,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
}

export function validateToken(milliSeconds?: number) {
  const time: any = milliSeconds
    ? new Date().getTime() + milliSeconds
    : getWithExpiry("expiry_time");
  const timeDiff = time - new Date().getTime();
  if (time && timeDiff > 0) {
    setTimeout(() => {
      getWithExpiry("expiry_time");
    }, timeDiff);
  }
}

export function getWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    window.localStorage.removeItem(key);
    window.location.href = "/login";
    return item.expiry;
  }
  return item.expiry;
}
export const getHoursOptions = [
  { name: "00:00", value: "00:00" },
  { name: "00:30", value: "00:30" },
  { name: "01:00", value: "01:00" },
  { name: "01:30", value: "01:30" },
  { name: "02:00", value: "02:00" },
  { name: "02:30", value: "02:30" },
  { name: "03:00", value: "03:00" },
  { label: "03:30", value: "03:30" },
  { label: "04:00", value: "04:00" },
  { label: "04:30", value: "04:30" },
  { label: "05:00", value: "05:00" },
  { label: "05:30", value: "05:30" },
  { label: "06:00", value: "06:00" },
  { label: "06:30", value: "06:30" },
  { label: "07:00", value: "07:00" },
  { label: "07:30", value: "07:30" },
  { label: "08:00", value: "08:00" },
  { label: "08:30", value: "08:30" },
  { label: "09:00", value: "09:00" },
  { label: "09:30", value: "09:30" },
  { label: "10:00", value: "10:00" },
  { label: "10:30", value: "10:30" },
  { label: "11:00", value: "11:00" },
  { label: "11:30", value: "11:30" },
  { label: "12:00", value: "12:00" },
  { label: "12:30", value: "12:30" },
  { label: "13:00", value: "13:00" },
  { label: "13:30", value: "13:30" },
  { label: "14:00", value: "14:00" },
  { label: "14:30", value: "14:30" },
  { label: "15:00", value: "15:00" },
  { label: "15:30", value: "15:30" },
  { label: "16:00", value: "16:00" },
  { label: "16:30", value: "16:30" },
  { label: "17:00", value: "17:00" },
  { label: "17:30", value: "17:30" },
  { label: "18:00", value: "18:00" },
  { label: "18:30", value: "18:30" },
  { label: "19:00", value: "19:00" },
  { label: "19:30", value: "19:30" },
  { label: "20:00", value: "20:00" },
  { label: "20:30", value: "20:30" },
  { label: "21:00", value: "21:00" },
  { label: "21:30", value: "21:30" },
  { label: "22:00", value: "22:00" },
  { label: "22:30", value: "22:30" },
  { label: "23:00", value: "23:00" },
  { label: "23:30", value: "23:30" },
];

export const renderTime = (time: string) => {
  let daysDiff = moment().diff(moment(time), "days");
  if (daysDiff < 2) {
    return moment(time).fromNow();
  } else if (daysDiff < 365) {
    return moment(time).format("MMM DD, HH:mm A");
  } else {
    return moment(time).format("MMM DD YYYY, HH:mm A");
  }
};

export const getMessageTime = (datetime: string): string => {
  // let messageTime = moment.utc(datetime, "ddd, DD MMM YYYY HH:mm:ss ZZ");
  let messageTime = moment.utc(datetime);

  if (!messageTime.isValid()) {
    messageTime = moment();
  }

  const currentTime = moment();

  const isToday = messageTime.isSame(currentTime, "day");
  const timeFormat = isToday ? "h:mm A" : "MMM D, h:mm A";

  return messageTime.local().format(timeFormat);
};

export const getMessageTimeAndDate = (datetime: string): string => {
  let messageTime = moment.utc(datetime, "ddd, DD MMM YYYY HH:mm:ss ZZ");

  if (!messageTime.isValid()) {
    messageTime = moment();
  }

  const currentTime = moment();

  const isToday = messageTime.isSame(currentTime, "day");
  const timeFormat = isToday ? "MMM D, YYYY, h:mm A" : "MMM D, YYYY, h:mm A";

  return messageTime.local().format(timeFormat);
};

export const getCurrentUTCTime = (): string => {
  const currentUTCTime = moment.utc().format("ddd, DD MMM YYYY HH:mm:ss");
  return currentUTCTime + " GMT";
};

export enum MEDIA_FILE_UPLOAD_STATUS {
  SUCCESS = "File uploaded successfully.",
  ERROR_UNDEFINED = "Unable to upload File.",
  SIZE = "File size exceeds {{mediaSizeAllowed}} MB",
  FORMAT = "File format not supported.",
}

export const getMediaSizeStatus = (maxSize: number): string => {
  return MEDIA_FILE_UPLOAD_STATUS.SIZE.replace(
    "{{mediaSizeAllowed}}",
    maxSize.toString()
  );
};
export const fileDownload = (url: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.click();
};
export const getTimezonesList = () => {
  let r: any = [];
  let temp = moment.tz.names();
  temp?.forEach((item: any) => {
    r.push({ name: item, id: item });
  });
  return r || [];
};

export function arrayUnique(array: any) {
  let newArray = array.concat();
  for (var i = 0; i < newArray.length; ++i) {
    for (var j = i + 1; j < newArray.length; ++j) {
      if (newArray[i] === newArray[j]) newArray.splice(j--, 1);
    }
  }

  return newArray;
}

export function getLastUrlSegment(url: any) {
  return new URL(window.location.origin.toString() + url).pathname
    .split("/")
    .filter(Boolean)
    .pop();
}

export const getCurrentHoursMinutes = (): string => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, "0"); // Ensure 2-digit format
  const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure 2-digit format
  const formattedTime = `${hours}:${minutes}`;

  return formattedTime;
};

// Function to replace :colon with provided ID
export const replaceIdInPath = (path: string, id: string): string => {
  // Split the path into segments using '/'
  const pathSegments = path.split("/");

  // Iterate through the segments to find and replace :colon with the provided ID
  const updatedSegments = pathSegments.map((segment) => {
    if (segment.startsWith(":")) {
      // Replace :colon with the provided ID
      return id;
    } else {
      return segment;
    }
  });

  // Join the updated segments back into a string with '/'
  const updatedPath = updatedSegments.join("/");

  return updatedPath;
};

export function formatTime(seconds: any, format?: string) {
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = Math.round(seconds % 60);
  var timeString = "";
  if (format === "min") {
    timeString = (seconds / 60).toFixed(2).toString();
    return timeString;
  }
  if (format === "hrs") {
    timeString = (seconds / 3600).toFixed(2).toString();
    return timeString;
  }
  if (hours > 0) {
    timeString += hours + " hr";
    if (hours > 1) {
      timeString += "s";
    }
    timeString += " ";
  }
  if (minutes > 0) {
    timeString += minutes + " min";
    if (minutes > 1) {
      timeString += "s";
    }
    timeString += " ";
  }
  if (remainingSeconds > 0 || timeString === "") {
    timeString += remainingSeconds + " sec";
    if (remainingSeconds !== 1) {
      timeString += "s";
    }
  }
  return timeString;
}

export function convertToLocaleDates(obj: any): any {
  try {
    if (Array.isArray(obj)) {
      return obj.map(convertToLocaleDates);
    } else if (obj !== null && typeof obj === 'object') {
      let newObj = { ...obj };
      for (let key in newObj) {
        if (newObj.hasOwnProperty(key)) {
          if (
            typeof newObj[key] === 'string' && 
            newObj[key].includes("GMT") && 
            !isNaN(Date.parse(newObj[key])) // Check if it's a valid date
          ) {
            // Convert GMT date strings to local date
            let date = new Date(newObj[key]);
            let day = date.getDate().toString().padStart(2, "0");
            let month = date.toLocaleString("en-US", { month: "short" });
            let year = date.getFullYear();
            let hours = date.getHours() % 12 || 12;  // 12-hour format
            let minutes = date.getMinutes().toString().padStart(2, "0");
            let ampm = date.getHours() >= 12 ? "PM" : "AM";  // AM/PM
  
            let formattedDate = `${day} ${month}, ${year} ${hours}:${minutes} ${ampm}`;
            newObj[key] = formattedDate;
          } else if (typeof newObj[key] === 'object') {
            // Recursively convert dates in nested objects or arrays
            newObj[key] = convertToLocaleDates(newObj[key]);
          }
        }
      }
      return newObj;
    }
    return obj;
  } catch (error) {
    console.error("Error converting dates:", error);
    return obj; 
  }

}

export function getCurrentTimeZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}


//whats-app template body dynamic variables resolve methods
export function getBodyComponentDetails(components: any) {
  // Find the component with type "BODY"
  if (!components) return;
  const bodyComponent = components?.find((component: any) => component?.type === "BODY");

  // If such a component is found, return both flattened body_text and text
  if (bodyComponent && bodyComponent.example && bodyComponent.example?.body_text) {
    return {
      body_text: bodyComponent?.example?.body_text?.flat(),
      text: bodyComponent?.text
    };
  }
  //if body components examples is not found then return text
  if (bodyComponent && !bodyComponent?.example) {
    return {
      body_text: [],
      text: bodyComponent?.text
    };
  }

  // Return default values if no matching component is found
  return {
    body_text: [],
    text: ""
  };
}

//whats-app template button dynamic variables resolve methods

export function getButtonComponentDetails(components: any) {
  if (!components) return;

  // Find the component with type "BUTTONS"
  const buttonComponent = components?.find((component: any) => component.type === "BUTTONS");

  // If such a component is found, filter buttons and return details
  if (buttonComponent && buttonComponent.buttons) {
    return buttonComponent.buttons.map((button: any) => {
      // Create a base object with optional properties
      const buttonDetails: any = {
        sub_type: button?.type || "", // Default to empty string if undefined
        text: button?.type === "COPY_CODE" ? button?.example?.[0] || "" : button?.text || "",
      };

      // Conditionally add the 'example' key if it's present
      if (Array.isArray(button?.example)) {
        buttonDetails.example = button.example.flat();
      }

      return buttonDetails;
    });
  }

  // Return an empty array if no matching component is found
  return [];
}

//whats-app template header dynamic variables resolve methods

export function getHeaderComponentDetails(components: any) {
  if (!components) return;
  // Find the component with type "HEADER"
  const headerComponent = components?.find((component: any) => component?.type === "HEADER");

  // If such a component is found, return both header_text and text
  if (headerComponent && headerComponent?.example && headerComponent?.example?.header_text) {
    return {
      header_text: headerComponent?.example?.header_text,
      text: headerComponent?.text
    };
  }

  //if header components examples is not found then return text
  if (headerComponent && !headerComponent.example) {
    return {
      header_text: [],
      text: headerComponent?.text
    };
  }

  // Return default values if no matching component is found
  return {
    header_text: [],
    text: ""
  };
}
