import styled from "styled-components";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import { AppErrorMessage } from "../../AppComponents/Forms";
import { theme } from "../../Customization/Theme";
import useDebounce from "../../Hooks/useDebounce";
import { ConversationService } from "../../Services/Apis/ConversationService";
import { useCurrentBot } from "../../Store/Slices/DashboardSlices";
import { formatString } from "../../Utils";

interface AppInputFieldProps {
  label?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  disabled?: any;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const FaqLiveSearchAutoComplete: React.FC<AppInputFieldProps> = ({
  label,
  name,
  tooltip,
  divStyle,
  disabled,
  sendValuesToParent,
  children,
  ...otherProps
}) => {
  const {
    setFieldTouched,
    // handleChange,
    errors,
    touched,
    // getFieldMeta,
    // initialValues,
    setFieldValue,
    values,
  } = useFormikContext<any>();

  const currentBot = useSelector(useCurrentBot);
  const navigate = useNavigate();

  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]);

  const HEADERS = {
    appid: JSON.stringify({
      botid: currentBot.bot_id,
    }),
  };

  const [inputValue, setInputValue] = useState<string>("");
  const [liveResults, setLiveResults] = useState<any>([]);
  const debouncedSearchTerm = useDebounce<string>(inputValue || "", 900);

  const SuggestionFAQ = (text: string | null) => {
    if (text && text?.length > 1) {
      ConversationService.postSearchFaqVariations(
        {
          query: text,
        },
        HEADERS
      )
        .then((res: any) => {
          setLiveResults(res?.data?.data);
        })
        .catch((error: any) => {
        });
    }
  };

  useEffect(() => {

    if (debouncedSearchTerm.length > 0) {
      SuggestionFAQ(debouncedSearchTerm);
    } else {
      setLiveResults([]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {

  }, [values[name]]);

  const handleSuggestionAdd = (curItem: any) => {
    let url = formatString("/bot/{0}/setup/faq-answer/{1}", [
      currentBot.bot_id,
      curItem?.qid,
    ]);
    // window.location.href = url
    // setLiveResults([]);
    navigate(url, { state: { variation: values[name] } });
  };


  return (
    <Div style={divStyle}>
      <Label>
        {label}

        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <Textarea
        disabled={disabled}
        {...otherProps}
        onBlur={() => {
          setFieldTouched(name);
          setTimeout(() => {

            setLiveResults([]);
          }, 300);
        }}
        onFocus={() => SuggestionFAQ(debouncedSearchTerm)}
        onChange={(e: any) => {
          setFieldValue(name, e.target.value);
          setInputValue(e.target.value);
        }}
        value={values[name] || ''}
        style={{
          border:
            errors[name] && touched[name]
              ? `2px solid ${theme.palette.default.error}`
              : `2px solid transparent`,
        }}
      // rows={2}
      />

      {!disabled && liveResults && liveResults.length > 0 && (
        <SuggestionBox>
          <p>Similar Questions Found</p>
          {liveResults.map((curItem: any, index: number) => {
            return (
              <SuggestionBoxSingleRow
                key={index}
                onClick={() => handleSuggestionAdd(curItem)}
              // onClick={()=>alert("sdas")}
              >
                {curItem?.query || curItem?.qid}
                <AppButton
                  variant="text"
                  style={{ height: "2rem", backgroundColor: "transparent" }}
                  type="button"
                // onClick={() => handleSuggestionAdd(curItem)}
                >
                  Add as variation
                </AppButton>
              </SuggestionBoxSingleRow>
            );
          })}
        </SuggestionBox>
      )}

      <AppErrorMessage error={errors[name]} visible={touched[name]} />
    </Div>
  );
};

export default FaqLiveSearchAutoComplete;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
  }
`;
const SuggestionBox = styled.div`
  && {
    background-color: #f5f6f8;
    background-color: white;
    width: 100%;
    position: absolute;
    top: 110%;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    z-index: 10;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
`;
const SuggestionBoxSingleRow = styled.div`
  && {
    background-color: white;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.5rem;
    font-size: 15px;
    cursor: pointer;
    border-radius: 4px;

    &:hover {
      background-color: #f5f6f8;
    }
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
  }
`;
const Input = styled.input<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 0 2rem 0 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
   opacity: 0.8;
   color: ${theme.palette.default.darkGrey};
  }
`;

const Textarea = styled.textarea<StyledProps>`
  && {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 4px 2rem 8px 14px;
    padding: 6px 1rem 6px 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    /* overflow:hidden; */
    resize: none;

    /* ::-webkit-scrollbar {
   display: none;
   width: 1px;
 } */

  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    // height: 80px;
  }
`;
