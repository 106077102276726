export const getText = (response: any) => {
  let text = "";
  if (response && response !== null && response.length > 0) {
    const match = response.filter(
      (item: any) => item.type === "text" || item.type === "carousel"
    );
    if (match.length > 0) {
      if (match?.[0]?.value) {
        if (Array.isArray(match?.[0]?.value) && match?.[0]?.value?.length > 0) {
          text = match?.[0]?.value?.[0].text;
        } else {
          text = match?.[0]?.value;
        }
      } else if (match?.[0]?.text) {
        text = match?.[0]?.text;
      } else {
        text = "";
      }
    }
  }
  return text;
};

export const getResponseWithoutText = (node: any, language: string) => {
  let buttons = [];
  const lanResponse =
    language !== "english" ? `response_${language.toLowerCase()}` : "response";
  const response = node?.[lanResponse] ? node?.[lanResponse] : node?.response;
  if (response && response !== null && response.length > 0) {
    buttons = response.filter((item: any) => item.type !== "text");
  }
  return JSON.parse(JSON.stringify(buttons));
};

export const getCarouselResponse = (node: any, language: string) => {
  let carousel = [];
  const lanResponse =
    language !== "english" ? `response_${language.toLowerCase()}` : "response";
  const response = node?.[lanResponse] ? node?.[lanResponse] : node?.response;
  if (response && response !== null && response.length > 0) {
    carousel = response.find((item: any) => item.type === "carousel");
  }
  return carousel?.value && carousel?.value?.length > 0
    ? JSON.parse(JSON.stringify(carousel.value))
    : [];
};

export const getResponse = (node: any, language: string) => {
  const response =
    language !== "english" ? `response_${language.toLowerCase()}` : "response";
  return node?.[response] ? node?.[response] : node?.response;
};

const clean = (object: any) => {
  Object.keys(object).forEach(function (key) {
    if (object[key].url === "") {
      delete object[key];
    }
  });
  return object;
};

export const cleanMediaDetails = (mediaDetails: any) => {
  return clean(mediaDetails);
};

export const isEmptyObj = (obj: any) => {
  for (var prop in obj) {
    if (obj?.hasOwnProperty(prop)) return false;
  }
  return true;
};

export const getMediaDetails = (response: any) => {
  let mediaDetails = {};
  if (response && response !== null && response.length > 0) {
    const match = response.filter((item: any) => item.type === "text");
    mediaDetails = match.length === 1 ? match?.[0]?.media : "";
  }
  return mediaDetails;
};

export const objectsEqual: any = (o1: any, o2: any) =>
  typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const arraysEqual = (a1: any, a2: any) =>
  a1.length === a2.length &&
  a1.every((o: any, idx: number) => objectsEqual(o, a2[idx]));

export const isValidCarousel = (slides: any) => {
  if (slides && slides.length > 0) {
    const validSlides = slides.find((slide: any) => {
      const plainText = slide.text.replace(/<[^>]*>/g, "");
      return slide.image === "" || plainText.trim() === "";
    });
    return !validSlides;
  }
  return false;
};

export const isImageAdded = (slides: any) => {
  if (slides && slides.length > 0) {
    const validSlides = slides.find((slide: any) => {
      return slide.image === "";
    });
    return !validSlides;
  }
  return false;
};

export const getQuickAction = (quickActions: any, action: string) => {
  const nAction =
    quickActions && quickActions.length > 0
      ? quickActions.filter((qaItem: any) => qaItem.type === action)
      : [];
  return nAction.length === 0 ? false : nAction?.[0]?.enabled;
};
