import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { AppMaterialIcons } from "../../AppMaterialIcons";
import AppButton from "../../AppButton";
import { logoutAuth } from "../../../Store/Slices/AuthSlice";
import { useAppDispatch } from "../../../Store";

// import { useCurrentBot } from "../Store/Slices/DashboardSlices";

interface Props {
  showBackButton?: boolean;
}

const ProtectedAccessDeniedPage: React.FC<Props> = ({
  showBackButton = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const navigatetoHome = () => {
      navigate("/", { replace: true });
  };

  const navigatetoBack = () => {
    navigate(-2);
  };

  const handleLogout = () => {
    dispatch(logoutAuth(null));
    navigate("/login");
  };

  return (
    // <StyledContainer404>
    //   <img src={error404} alt="page not found" className="pageImage" />
    //   <p className="pageTitle">
    //     Unfortunately the page you are looking for has been moved or deleted.
    //   </p>
    //   <div className="actionBox">
    //     <AppButton onClick={() => handleClick()}>Go to Homepage</AppButton>
    //   </div>
    // </StyledContainer404>
    <StyledContainer404>
      <div className="imageBox">
        <AppMaterialIcons
          iconName="LockOutlined"
          style={{ fontSize: "8rem" }}
        />
      </div>

      <div className="contant_box_404">
        <h2>Access Restricted</h2>

        <p >
          You don't have permission to view this link, or the link may not be
          available. Please contact the owner and ask to get permissions or switch accounts.
        </p>
        <div className="actionBox">

        <AppButton onClick={() => handleLogout()} variant="grey">
         Login with another account
        </AppButton>
        <AppButton onClick={() => navigatetoHome()} variant="primary">
          Go to Home
        </AppButton>
        </div>

       
      </div>
    </StyledContainer404>
  );
};

export default ProtectedAccessDeniedPage;

export const StyledContainer404 = styled.div`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    min-height: calc(100vh - 100px);
    width: 100%;
    gap: 1.5rem;

    .actionBox{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .imageBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
    }
   
    .contant_box_404 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
     
      h2{
        margin: 0rem 0rem;
      }
      p{
        width: 70%;
        font-size: 1.05rem;
        text-align: center;
        line-height: 2rem;
      }
    }
  }
`;
