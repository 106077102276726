import { Paper } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";


export const TimeoutWrapper = styled.div`
    // width: 100%;
    height: 100%;
        background-color: #e6e6e6;

`;

export const TimeoutHeader = styled.div`
    /* width: 100%; */
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fff;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .response-header__left {
        h4 {
            font-size: 1.5rem;
            font-weight: 400;
        }
    }
    .response-header__right {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`

