import { Paper } from "@mui/material";
import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";


export const TimeoutWrapper = styled.div`
    width: 100%;
    height: 100%;
`;

export const TimeoutHeader = styled.div`
    /* width: 100%; */
    width: auto;
    // margin-left: -0.95rem;
    // margin-right: -1rem;
    // margin-top: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: #fff;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .response-header__left {
        h4 {
            font-size: 1rem;
            font-weight: 500;
        }
    }
    .response-header__right {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
`

export const TimeoutContainer = styled(Paper)`
    width: 60%;
    height: calc(100% - (60px + 52px));
    margin-left: 1rem;
    margin-right: 1rem;
`

export const TimeoutTable = styled.div`
    height: 100%;
    .table-header {
        display: flex;
        padding: 1rem;
        // border-bottom: 1px solid ${theme.palette.default.grey};
        .heading-text {
            min-width: 280px;
            h4 {
                font-size: 0.875rem;
                font-weight: 500;
            }
        }
    }

    .table-data {
        height: calc(100%);
        overflow: auto;
        padding: 0rem 1rem 1rem 0;

        form {
            height: 100%;
            justify-content: space-between;
            align-items: flex-end;
        }
    }

    .footer {
        display: flex;
        gap: 1rem;
    }

    .table-row__content {
        
    }

    .b-bottom {
        border-bottom: 1px solid ${theme.palette.default.grey};
    }

    .table-content {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        padding-top: 1rem;
        
        .table-content__text {
            display: flex;
            align-items: start;
            .tool-tip {
                display: flex;
                align-items: center;
                padding-top: 0.875rem;
                p {
                    padding-bottom: 0;
                }
            }
            .checkbox-text {
                padding-top: 7px;
                // p {
                //     padding-bottom: 0.875rem;
                // }
            }
             h4 {
                font-weight: 500;
                font-size: 0.875rem;
            }
            p {
                 font-size: 0.875rem;
                color: ${theme.palette.default.darkGrey};
                padding-bottom: 0.875rem;
             }
            .table-actions {
                display: flex;
                gap: 1rem;
                padding-top: 1rem;
            }
            
        }
    }
    
    .table-content > :first-child {
        min-width: 280px;
        
    }
    .table-content > :last-child {
        
    }
    .table-content > :nth-child(2) {
        width: calc(100%);
    }
`