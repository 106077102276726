import AppMarketingList from "../../../../../AppComponents/Forms/AppMarketingList"
import { SMSDataStore } from "../../../../../Store/Slices/SMS/sms.selectors";
import { useAppSelector } from "../../../../../Store";

const RenderFormStep2 = (prop : any) =>{
    const { postSMSBroadcastData } = useAppSelector(SMSDataStore);
    return (
        <>
            <AppMarketingList type="sms" subscriptionName = "sms_subscription" broadcast_id = {postSMSBroadcastData}/>
        </>
    )
}
export default RenderFormStep2