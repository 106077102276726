import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";

import AppButton from "../../../../../AppComponents/AppButton";
import { AppErrorMessage } from "../../../../../AppComponents/Forms";
import { Caption, Label, StyledInputFieldWrapper, StyledTextInput } from "../../../../../Customization/CommonElements";
import { theme } from "../../../../../Customization/Theme";
import useVariableHook from "./useVariableHook";
interface AppInputFieldProps {
  label?: string;
  subTitle?: string;
  name: string;
  variablesKey: string;
  tooltip?: any;
  divStyle?: any;
  endVariableRequired?: boolean;
  charactersLimit?: number;
  variablesButtonLimit?: number;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  [otherProps: string]: any;
  removeSpaceBeforAfterVariable?: boolean;
  labelStyle?: React.CSSProperties;
}

const WhatsappTextField: React.FC<AppInputFieldProps> = ({
  label,
  name,
  variablesKey,
  tooltip,
  divStyle,
  subTitle,
  endVariableRequired = false,
  sendValuesToParent,
  children,
  charactersLimit = 0,
  variablesButtonLimit = 10,
  removeSpaceBeforAfterVariable = false,
  labelStyle,
  disabled,
  ...otherProps
}) => {
  const inputRef: any = useRef();
  const {
    setFieldTouched,
    errors,
    touched,
    setFieldValue,
    values
  } = useFormikContext<any>();
  useVariableHook(name, variablesKey, variablesButtonLimit);
  useEffect(() => {
    if (typeof (getIn(values, name)) === 'object') {
      setFieldValue(name, "")
    }
  }, [getIn(values, name)]);  // eslint-disable-line react-hooks/exhaustive-deps
  const handleOnChange = async (e: any) => {
    setFieldValue(name, e.target.value);
  };
  const handleAddVariableClick = () => {
    setFieldTouched(name, true);
    const lastMatchedVar = getIn(values, variablesKey)[
      getIn(values, variablesKey)?.length - 1
    ];
    const lastVarNumber = parseInt(lastMatchedVar && lastMatchedVar?.match(/\d+/)![0]) || 0;
    const nextVarNumber = lastVarNumber + 1;
    //IE support
    const start: any = inputRef.current.selectionStart
    const end: any = inputRef.current.selectionEnd
    const text: any = inputRef.current.value
    const before: any = text.substring(0, start)
    const after: any = text.substring(end, text.length)
    if (removeSpaceBeforAfterVariable) {
      inputRef.current.value = (before + `{{${nextVarNumber}}}` + after)
    }
    else {
      inputRef.current.value = (before + ` {{${nextVarNumber}}} ` + after)
    }
    inputRef.current.selectionStart = inputRef.current.selectionEnd = start + (`{{${nextVarNumber}}}`.length + 1)
    inputRef.current.focus()
    if (removeSpaceBeforAfterVariable) {
      setFieldValue(name, (before + `{{${nextVarNumber}}}` + after));
    }
    else {
      setFieldValue(name, (before + ` {{${nextVarNumber}}} ` + after));
    }
  };
  return (
    <StyledInputFieldWrapper style={divStyle}>
      <Label style={labelStyle}>
        {label}

        {tooltip && (
          <Tooltip title={tooltip} placement="top">
            <HelpOutlineIcon />
          </Tooltip>
        )}
      </Label>
      <Caption>{subTitle}</Caption>
      <StyledTextInput
        {...otherProps}
        onBlur={() => setFieldTouched(name, true)}
        ref={inputRef}
        onChange={(e: any) => {
          handleOnChange(e);
        }}
        value={getIn(values, name) || ""}
        style={{
          border:
            getIn(errors, name) && getIn(touched, name)
              ? `2px solid ${theme.palette.default.error}`
              : `2px solid transparent`,
        }}
        disabled={disabled}
        maxLength={charactersLimit}
      />
      {charactersLimit !== 0 && (
        <Caption>
          {" "}
          Characters: {getIn(values, name)?.length || 0}/{charactersLimit}
        </Caption>
      )}

      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <AppButton
          variant="text"
          onClick={() => {
            handleAddVariableClick();
          }}
          style={{ marginTop: "-0.5rem", minWidth: "170px", width: "170px", border: "1px solid rgba(91, 115, 232, 1)", borderRadius: "6px" }}
          startIcon={"ri-add-line"}
          isDisabled={disabled ||
            getIn(values, variablesKey)?.length >= variablesButtonLimit
            ? true
            : false
          }
        >
          Add Variable
        </AppButton>
      </div>
    </StyledInputFieldWrapper>
  );
};

export default WhatsappTextField;
