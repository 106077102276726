import { Box, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import React from "react";
import StyledCard from "../../../../../UiComponents/Card/StyledCard";
import MediaError from '../../../Assets/MediaError.svg';
import { BlockCardStyle } from "./BlockCardCss";
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { theme } from "../../../../../../Customization/Theme";
interface props {
  curItem?: any;
  handleOpenUrlFn?: any;
  handleEditButton?: any;
  handleDeleteButton?: any;
  isEditing?: boolean;
  hideActionBtn?: boolean;
  isPannelPreview?: boolean;
}
const BlockCard: React.FC<props> = ({
  curItem = "",
  handleOpenUrlFn = "",
  handleEditButton = "",
  handleDeleteButton = "",
  isEditing = false,
  hideActionBtn = false,
  isPannelPreview,
}) => {
  return (
    <BlockCardStyle
    pannelPreview={isPannelPreview && isPannelPreview}
    style={hideActionBtn ? {pointerEvents:"none"} : {}}
    >
      <StyledCard>
        <Box className="block-notification__wrapepr">
          {
            hideActionBtn ? null : (
              <Box
              className="action-icons"
            >
              <IconButton onClick={() => handleEditButton(curItem)}>
                <EditOutlinedIcon />
              </IconButton>
              <IconButton disabled={isEditing} onClick={() => handleDeleteButton(curItem)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Box>
            )
          }
         
          <Box className="block-image">
            <img
              src={curItem?.image?.source || MediaError}
              alt="block"
              title={curItem?.image?.source ? "Menu Image" : "Media Not Found"}
            />
          </Box>
          <Box className="block-text__container">
            {curItem?.position === "Link To Flow" ? (
              <Typography>{curItem?.journey?.main_flow?.name}</Typography>
            ) : (
              <Typography
                className="block-text"
                variant="body2"
                onClick={() => handleOpenUrlFn(curItem?.button_url)}
              >
                {curItem?.button_title}
              </Typography>
            )}
          </Box>
        </Box>


      </StyledCard>
    </BlockCardStyle>

  );
};

export default BlockCard;
