import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChatLabelNetworkService } from "./ChatLabelNetworkService";

export class LabelActions {

    // Create Label
    static createChatLabel = createAsyncThunk(
        "LabelSlice/createLabels",
        (data: any, { dispatch, rejectWithValue }) => {
            return ChatLabelNetworkService.postChatLabel(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )

    // Get Listing Of All Labels
    static getListingOfLabels = createAsyncThunk(
        "LabelSlice/getLabelListing",
        (data: any, { dispatch, rejectWithValue }) => {
            return ChatLabelNetworkService.getChatLabelListing(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )

    // Get 1 Message By ID
    static getLabelById = createAsyncThunk(
        "LabelSlice/getLabelById",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return ChatLabelNetworkService.getLabel(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // Update Label By ID
    static updateLabelById = createAsyncThunk(
        "LabelSlice/updateLabelById",
        (data: any, { dispatch, rejectWithValue }) => {
            return ChatLabelNetworkService.updateLabel(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                });
        }
    );

    // Delete Message By ID
    static deleteLabelById = createAsyncThunk(
        "LabelSlice/deleteLabelById",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return ChatLabelNetworkService.deleteLabel(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

}