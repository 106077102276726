/* eslint-disable react/jsx-props-no-spreading */
import styled from "styled-components";
import React, { memo, useLayoutEffect, useRef } from "react";
import { theme } from "../../../Customization/Theme";
import usePrevious from "./usePrevious";

export interface SingleOTPInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  focus?: boolean;
}

export function SingleOTPInputComponent({
  focus,
  autoFocus,
  ...props
}: SingleOTPInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const prevFocus = usePrevious(!!focus);

  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);

  return <StyledOtpBox ref={inputRef} {...props} />;
}

const SingleOTPInput = memo(SingleOTPInputComponent);
export default SingleOTPInput;

const StyledOtpBox = styled.input`
  && {
    display: "flex";
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    flex-direction: "row";
    width: 4.2rem;
    height: 3.5rem;
    overflow: hidden;
    /* margin: 0 1rem;
    margin-bottom: 0.5rem; */
    background: #f5f6f8;
    border-radius: 4px;
    border: none;
    outline: none;

    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;

    border: 2px solid transparent;
    &::placeholder {
      color: ${theme.palette.default.darkGrey};
    }

    &:active {
      border: 2px solid ${theme.palette.default.border};
      color: ${theme.palette.default.text};
    }
    &:focus {
      border: 2px solid ${theme.palette.default.border} !important;
      color: ${theme.palette.default.text};
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;
