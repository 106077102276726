import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  setAllMessages,
  setLastNodeResponse,
  setOriginalResponses,
  setTypingStatus,
} from "../../Store/Slices/socket/AllMessagesSlice";
import { getHtmlTextChannelWise } from "../appComponents/Forms/RichEditor/WebLiveChat/utils";

import {
  AppRenderAudio,
  AppRenderCtaButtons,
  AppRenderFile,
  AppRenderFlowButtons,
  AppRenderImage,
  AppRenderInfo,
  AppRenderLiveButtons,
  AppRenderPrivateNote,
  AppRenderSlider,
  AppRenderTemplate,
  AppRenderText,
  AppRenderTypingIndicator,
  AppRenderVideo,
  AppRenderWhatsappInteractive,
  AppRenderWhatsappInteractiveList,
} from "../appComponents/RenderComponents";
import { MESSAGE_TYPES, SOCKET_CONNECTION_TYPES } from "../utils/Enum";
import { getFileNameFromUrl, getMessageTextFromMessage } from "../utils/utils";
import { useSocket } from "../hooks/socket-context";

interface Props {
  [otherProps: string]: any;
}

const MessagesScreen: React.FC<Props> = () => {
  //redux
  const allMessages = useAppSelector(
    (state) => state.currrentAllMessagesStore.allMessages
  );
  const userInfoData = useAppSelector(
    (state) => state.currrentAllMessagesStore.userInfoData
  );
  const sessionId = useAppSelector(
    (state) => state.currrentAllMessagesStore.sessionId
  );
  const { socket } = useSocket();
  const messagesEndRef = useRef<any>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "instant",
        block: "end",
        inline: "nearest",
      });
    }
  }, [allMessages]);

  useEffect(() => {
    if (!socket || !sessionId) return;

    const handleTypingStatus = (response: any) => {
      if (response?.session_id && response?.session_id !== sessionId) {
        return;
      }
      dispatch(setTypingStatus(response?.data));
    };

    const handleNewMessage = (response: any) => {
      if (response?.data?.response?.length <= 0 || !response?.data?.response) {
        return;
      }

      if (response?.data?.response?.length > 0) {
        dispatch(setAllMessages(response?.data?.response));
      }

      dispatch(setLastNodeResponse(response?.data));
      dispatch(setOriginalResponses(response?.data));
    };

    // RESET UNREAD MSG COUNT
    socket?.emit(SOCKET_CONNECTION_TYPES.RESET_UNREAD_MSG_COUNT, {
      session_id: sessionId,
    });

    //NEW MESSAGES
    socket.on(SOCKET_CONNECTION_TYPES.AGENT_RESPONSE, handleNewMessage);

    //TYPINGS INDICATOR
    socket.on(SOCKET_CONNECTION_TYPES.TYPING_STATUS, handleTypingStatus);

    //CLEAN UP
    return () => {
      socket.off(SOCKET_CONNECTION_TYPES.AGENT_RESPONSE, handleNewMessage);
      socket.off(SOCKET_CONNECTION_TYPES.TYPING_STATUS, handleTypingStatus);

      // RESET UNREAD MSG COUNT When component unmount
      socket?.emit(SOCKET_CONNECTION_TYPES.RESET_UNREAD_MSG_COUNT, {
        session_id: sessionId,
      });
    };
  }, [socket, sessionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {allMessages &&
        allMessages?.map((singleMsg: any, index: number) => {
          //* TEXT
          if (singleMsg?.type === MESSAGE_TYPES.TEXT) {
            return (
              <AppRenderText
                userMessage={!singleMsg?.isUser ? true : false}
                time={singleMsg?.time}
                key={index.toString()}
                msgId={singleMsg?.message_id}
                sent={singleMsg?.sent || singleMsg?.time}
                seen={singleMsg?.seen}
                delivered={singleMsg?.delivered}
              >
                {getHtmlTextChannelWise(
                  singleMsg?.value,
                  userInfoData?.channel
                )}
              </AppRenderText>
            );
          }
          //* Private Note
          if (singleMsg?.type === MESSAGE_TYPES.PRIVATE_NOTE) {
            return (
              <AppRenderPrivateNote
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                time={singleMsg?.time}
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
              >
                {getHtmlTextChannelWise(
                  singleMsg?.value,
                  userInfoData?.channel
                )}
              </AppRenderPrivateNote>
            );
          }

          //* INFO Alert
          if (singleMsg?.type === MESSAGE_TYPES.INFO) {
            return (
              <AppRenderInfo
                key={index.toString()}
                userMessage={!singleMsg?.isUser ? true : false}
                time={singleMsg?.time}
                msgId={singleMsg?.message_id}
              >
                {singleMsg?.value}
              </AppRenderInfo>
            );
          }

          //* FLOW BUTTONS
          if (singleMsg?.type === MESSAGE_TYPES.FLOW) {
            return (
              <AppRenderFlowButtons
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                item={singleMsg}
                time={singleMsg?.time}
                msgId={singleMsg?.message_id}
              />
            );
          }

          //* LIVE BUTTONS
          if (singleMsg?.type === MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
            return (
              <AppRenderLiveButtons
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                item={singleMsg}
                time={singleMsg?.time}
                msgId={singleMsg?.message_id}
              />
            );
          }
          //* CTA BUTTONS
          if (
            singleMsg?.type === MESSAGE_TYPES.LINK ||
            singleMsg?.type === MESSAGE_TYPES.MOBILE ||
            singleMsg?.type === MESSAGE_TYPES.EMAIL
          ) {
            return (
              <AppRenderCtaButtons
                userMessage={!singleMsg?.isUser ? true : false}
                time={singleMsg?.time}
                item={singleMsg}
                key={index.toString()}
                avatar={!singleMsg?.isUser ? "You" : userInfoData?.name}
                msgId={singleMsg?.message_id}
              />
            );
          }

          //* VIDEO
          if (singleMsg?.type === MESSAGE_TYPES.VIDEO) {
            return (
              <AppRenderVideo
                userMessage={!singleMsg?.isUser ? true : false}
                time={singleMsg?.time}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={
                  getHtmlTextChannelWise(
                    singleMsg?.value?.text,
                    userInfoData?.channel
                  ) || ""
                }
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
              />
            );
          }
          //* AUDIO
          if (singleMsg?.type === MESSAGE_TYPES.AUDIO) {
            return (
              <AppRenderAudio
                time={singleMsg?.time}
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={
                  getHtmlTextChannelWise(
                    singleMsg?.value?.text,
                    userInfoData?.channel
                  ) || ""
                }
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
              />
            );
          }

          // //* IMAGE
          if (singleMsg?.type === MESSAGE_TYPES.IMAGE) {
            return (
              <AppRenderImage
                time={singleMsg?.time}
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={
                  getHtmlTextChannelWise(
                    singleMsg?.value?.text,
                    userInfoData?.channel
                  ) || ""
                }
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
              />
            );
          }

          //* FILE
          if (
            singleMsg?.type === MESSAGE_TYPES.FILE ||
            singleMsg?.type === MESSAGE_TYPES.DOCUMENT
          ) {
            return (
              <AppRenderFile
                time={singleMsg?.time}
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={
                  getHtmlTextChannelWise(
                    singleMsg?.value?.text,
                    userInfoData?.channel
                  ) || ""
                }
                name={
                  singleMsg?.value?.fileName ||
                  getFileNameFromUrl(singleMsg?.value?.url)
                }
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
              />
            );
          }

          //* CAROUSEL
          if (singleMsg?.type === MESSAGE_TYPES.CAROUSEL) {
            return (
              <AppRenderSlider
                time={singleMsg?.time}
                userMessage={!singleMsg?.isUser ? true : false}
                items={singleMsg?.value}
                key={index.toString()}
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
              />
            );
          }

          //* WHATSAPP INTERACTIVE

          if (singleMsg?.type === MESSAGE_TYPES.WHATSAPP_INTERACTIVE) {
            return (
              <AppRenderWhatsappInteractive
                time={singleMsg?.time}
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
                item={singleMsg?.interactive}
              />
            );
          }

          //* WHATSAPP INTERACTIVE LIST

          if (singleMsg?.type === MESSAGE_TYPES.WHATSAPP_INTERACTIVE_LIST) {
            return (
              <AppRenderWhatsappInteractiveList
                time={singleMsg?.time}
                userMessage={!singleMsg?.isUser ? true : false}
                key={index.toString()}
                msgId={singleMsg?.message_id}
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
                item={singleMsg?.interactive}
              />
            );
          }

          if (singleMsg?.type === MESSAGE_TYPES.WHATSAPP_TEMPLATE) {
            return (
              <AppRenderTemplate
                time={singleMsg?.time}
                userMessage={!singleMsg?.isUser ? true : false}
                items={singleMsg?.value}
                key={index.toString()}
                avatar={!singleMsg?.isUser ? "You" : userInfoData?.name}
                msgId={singleMsg?.message_id}
                replyToType={singleMsg?.replyTo?.type}
                replyToValue={getMessageTextFromMessage(
                  singleMsg?.replyTo?.value,
                  singleMsg?.replyTo?.type
                )}
                replyToAvatar={
                  !singleMsg?.isUserReplied ? "You" : userInfoData?.name
                }
                seen={singleMsg?.seen}
                sent={singleMsg?.sent || singleMsg?.time}
                delivered={singleMsg?.delivered}
                templateData={singleMsg?.templateData}
                src={""}
              />
            );
          }

          return null;
        })}
      <AppRenderTypingIndicator />
      <div ref={messagesEndRef} />
    </>
  );
};

export default MessagesScreen;
