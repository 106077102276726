import LiveAll from "../../../../assets/images/all-live.svg";
import { ReactComponent as Facebook } from "../../../../assets/images/facebook.svg";
import Unattended from "../../../../assets/images/unattended-live.svg";
// import { ReactComponent as Website } from "../../../../assets/images/websiteLiveChat.svg";
import { ReactComponent as Website } from "../../../../assets/images/webChatLive1.svg";
import { ReactComponent as ChannelBot } from "../../../../assets/images/channel-bot.svg";
import { ReactComponent as Whatsapp } from "../../../../assets/images/whatsapp-live.svg";
import { ReactComponent as Telegram } from "../../../../assets/images/telegram.svg";

export const ConversationMenuOptions = [
  {
    avatar: LiveAll,
    name: "All",
    id: "all",
    url: "#",
    isAvailable: true,
  },
  {
    avatar: Unattended,
    name: "Unattended",
    id: "unattended",
    url: "#",
    isAvailable: true,
  },
];
export const getChannelAvatar = (channel?: string) => {
  if (channel === "messenger") {
    return <Facebook />;
  } else if (channel === "whatsapp") {
    return <Whatsapp />;
  } else if (channel === "telegram") {
    return <Telegram />;
  } else {
    return <ChannelBot />;
  }
};

// export const ChannelsMenuOptions = [
//   {
//     avatar: Whatsapp,
//     name: "WhatsApp",
//     // id: uniqid(),
//     url: "#",
//     isAvailable: true,
//     agentRoute: true,
//   },
//   {
//     avatar: Facebook,
//     name: "Facebook",
//     // id: uniqid(),
//     url: "#",
//     isAvailable: true,
//     agentRoute: true,
//   },
// ];

export const LabelsMenuOptions = [
  {
    avatar: "",
    name: "All",
    // id: uniqid(),
    url: "#",
    isAvailable: true,
    agentRoute: true,
  },
  {
    avatar: "",
    name: "Marketing",
    // id: uniqid(),
    url: "#",
    isAvailable: true,
    agentRoute: true,
  },
  {
    avatar: "",
    name: "Product",
    // id: uniqid(),
    url: "#",
    isAvailable: true,
    agentRoute: true,
  },
];
