import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from '../../Customization/Theme'

const AppButtonGroup = (props: any): JSX.Element => {
    const { lables } = props;
    const [activeButton, setActiveButton] = useState(1)
    return (
        <AppButtonContainer>
            {lables?.map((label: any, index: number) => (
                <Button type='button' active={label.id === activeButton} key={index + 1}
                    firstButton={index + 1 === label.id}
                    onClick={() => {
                        setActiveButton(label.id);
                        label.handleButtonClick();
                    }}>
                    {label.lable}
                </Button>
            ))}
        </AppButtonContainer >
    )
}

const AppButtonContainer = styled.div`
&&{
    display:flex;
    cursor:pointer;
    margin-bottom:8px;
    cursor:pointer;
}`

const Button = styled.button`
&&{
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:${(props: { active: boolean }) => props.active ? `${theme.palette.primary.dark}` : `${theme.palette.default.white}`};
    padding:15px 0;
    color:${(props: { active: boolean }) => props.active ? `${theme.palette.default.white}` : `${theme.palette.primary.dark}`};
    border: 1px solid ${theme.palette.primary.dark};
    font-weight:500;
    font-weight:14px;
}`
export default AppButtonGroup