import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppMarketingHistory from "../../../../AppComponents/Forms/AppMarketingHistory";
import { ProtectedAppButton } from "../../../../AppComponents/ProtectedComponents";
import { AppConfig } from "../../../../Config/app-config";
import { CoreService } from "../../../../Services/Apis/CoreApis";
import { saveRecipientFilterData } from "../../../../Store/Slices/Whatsapp/whatsapp.slice";
import { BroadcastTopTitle } from "../../../../TableStyle";
import { AppToolTip } from "../../../UiComponents/AppToolTip/AppToolTip";
import { BROADCAST_WHATSAPP_FORM_DATA } from "../Utils/Constants";
import { LeftCol, RightCol, TopBar, WrapperContainer } from "./HistoryStyles";
import { useAppDispatch } from "../../../../Store";
const WhatsappBroadcastHistory = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState<any>([]);
  const dispatch = useAppDispatch();
  const has_low_balance = localStorage.getItem("has_low_balance");
  const header = [
    "Name",
    "Sent On",
    "Recipients",
    "Status",
    "Processing",
    "Delivered",
    "Seen",
    "Replied",
    "Failed",
    "Actions",
  ];
  const keyList = [
    "broadcast_name",
    "sent_on",
    "recipients",
    "status",
    "processing",
    "delivered",
    "seen",
    "replied",
    "failed",
    "",
  ];
  //* Render only once*//
  useEffect(() => {
    AppConfig.setUserData(BROADCAST_WHATSAPP_FORM_DATA, null);
    dispatch(saveRecipientFilterData([]));
  }, []); // eslint-disable-line
  useEffect(() => {
    CoreService.getCredits().then((response) => {
      const reversedItems = response?.data;
      setCredits(reversedItems);
    });
  }, []);
  return (
    <WrapperContainer style={{height: "inherit"}}>
      {/* Top Bar JSX */}
      <TopBar>
        <LeftCol>
          <label>To know your Messaging Limit please</label>
          <Box className="limit">
            <a
              href="https://developers.facebook.com/docs/whatsapp/messaging-limits/"
              target="_blank"
              rel="noreferrer"
            >
              Click here
            </a>
          </Box>
        </LeftCol>

        {/* Right Col */}
        <RightCol>
          <Box className="contact">
            <label>
              <span className="contact-label">Connected WA No: </span>
              <span> {credits?.whatsapp_no}</span>
            </label>
          </Box>
          <Box className="monthly-conversations__wrapper">
            <Box className="conversations">
              <Box className="monthly-conversations">
                <AppToolTip
                  tooltipProps={{
                    title: `Each WhatsApp Business Account gets 1,000 free service conversations (user-initiated conversations) each month across all of its business phone numbers. This number is refreshed at the beginning of each month, based on WhatsApp Business Account time zone. Marketing, utility and authentication conversations are not part of the free tier`,
                    placement: "right",
                  }}
                  iconButtonProps={{ disableRipple: true }}
                  IconComponent={<InfoOutlinedIcon />}
                  divStyle={{ marginRight: "5px", marginBottom: "2px" }}
                />
                <div className="conversations-label">
                  Free Monthly conversations:
                  <strong> {credits?.free_conversation || 0}</strong>
                </div>
              </Box>
              <Box>{/* 80 */}</Box>
            </Box>
            <Box className="credits">
              <Box>
                <label></label>
              </Box>
            </Box>
          </Box>
        </RightCol>
      </TopBar>

      <BroadcastTopTitle style={{ padding: "16px" }}>
        <Box className="topTitle-left">
          <Typography fontSize="16px" fontWeight="500" component="h6">
            WhatsApp Broadcast List
          </Typography>
        </Box>
        <Box className="topTitle-right">
          {credits?.whatsapp_no && (
            <ProtectedAppButton
              moduleId="whatsapp_template"
              specificPermissionKey="create"
              variant="outline"
              onClick={() => navigate("/broadcast/whatsapp/create-template")}
              style={{ minWidth: "170px" }}
            >
              Create Template
              <AppMaterialIcons iconName="Add" />
            </ProtectedAppButton>
          )}
          <ProtectedAppButton
            moduleId="whatsapp_broadcast_crud"
            specificPermissionKey="create"
            variant="primarydark"
            onClick={() => navigate("/broadcast/whatsapp/create-broadcast/1")}
            style={{ minWidth: "170px" }}
            isDisabled={has_low_balance === "true"}
            title={
              has_low_balance === "true"
                ? "Unable to create broadcast. Your balance is below the required minimum. Please ensure your balance is higher than the low balance limit."
                : ""
            }
          >
            Create Broadcast
            <AppMaterialIcons iconName="Add" />
          </ProtectedAppButton>
        </Box>
      </BroadcastTopTitle>

      <Box px={2}>
        <AppMarketingHistory
          type="whatsapp"
          header={header}
          keyList={keyList}
          height={"304"}
          skeltonLoaderHeight="335"
          // customStyle={{ height: "calc(100vh - 324px)", background: "#fff" }}
        />
      </Box>
    </WrapperContainer>
  );
};

export default WhatsappBroadcastHistory;
