import { Stack, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import AppButton from "../../AppComponents/AppButton";
import loginIllustration from "../../assets/images/loginIllustration.svg";
import logo from "../../assets/images/logo.svg";
import {
  BrandBox,
  DetailsBox,
  ErrorText,
  LeftSide,
  LinkText,
  LoginPageWrap,
  LogoText,
  RightSide,
  SubTitleText,
  TitleText
} from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import OTPInput from "./OTPInput";

const VerifyMobilePage = () => {
  let navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidMessage, setInvalidMessage] = useState<string>("");


  const handleSubmit = async () => {
    if (finalOtp.length >= 4) {
      setLoading(true);
      // await userSignup();
      // await formReset();
      setInvalidMessage("");
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } else {
      setInvalidMessage("Invalid OTP");
    }
  };

  const redirectSignup = () => {
    navigate("/signup");
  };

  var [finalOtp, setFinalOtp] = useState<any>("");

  const [timer, setTimer] = useState(10);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => (currTimer <= 0 ? currTimer : currTimer - 1)),
    []
  );

  useEffect(() => {
    timer >= 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);


  const resetTimer = function () {
    if (!timer) {
      setTimer(10);
      handleSubmit();
      setInvalidMessage("");
    }
  };

  return (
    <LoginPageWrap>
      <LeftSide>
        <img src={loginIllustration} alt="" />
      </LeftSide>
      <RightSide>
        <DetailsBox>
          <BrandBox>
            <img src={logo} alt="" />
            <LogoText>Geta</LogoText>
          </BrandBox>
          <TitleText>Hello </TitleText>
          <SubTitleText> Please verify your mobile number</SubTitleText>
          <ErrorText>{invalidMessage}</ErrorText>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              gap: "1rem",
              marginBottom: "2rem",
              flexWrap: "nowrap",
              // backgroundColor: "green",
            }}
          >
            <OTPInput
              setFinalOtp={setFinalOtp}
              autoFocus
              isNumberInput
              length={4}
              className="otpContainer"
              inputClassName="otpInput"
              onChangeOTP={(otp) => { }}
            />
            {/* - String OTP:
              <OTPInput
                autoFocus
                length={4}
                className="otpContainer"
                inputClassName="otpInput"
                onChangeOTP={(otp) => }
              /> */}
          </Stack>

          <AppButton
            loading={loading}
            loadingPosition="center"
            onClick={() => handleSubmit()}
            variant="cta"
          >
            {loading ? "" : "Verify Mobile"}
          </AppButton>
          <LinkText
            sx={{
              marginTop: "1rem",
            }}
            variant="subtitle2"
            color={theme.palette.primary.main}
          >
            Didn't Received OTP ? {" "}
            <button
              disabled={timer <= 0 ? false : true}
              onClick={() => resetTimer()}
            >
              Resend OTP
            </button>{" "}
            in ({timer}s)
          </LinkText>
          <LinkText
            sx={{ marginTop: "1rem" }}
            variant="subtitle2"
            color={theme.palette.primary.main}
          >
            Back to
            <span onClick={() => redirectSignup()}> Create Account</span>
          </LinkText>
        </DetailsBox>
      </RightSide>
    </LoginPageWrap>
  );
};

export default VerifyMobilePage;
