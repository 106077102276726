import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserNetworkService } from "./UserNetworkService";

export class UserActions {

    // Create a new user
    static createUser = createAsyncThunk(
        'UserSlice/createUser',
        (data: any, {dispatch, rejectWithValue}) => {
            return UserNetworkService.createUser(data)
            .then(async(response: any) => {
                return response
            })
        }
    )
    
    // Listing All users
    static getUserListing = createAsyncThunk(
        'UserSlice/getUserListing',
        (data: any, {dispatch, rejectWithValue}) => {
            return UserNetworkService.getUserListing(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )

    // Get User By ID
    static getUserByID = createAsyncThunk(
        "UserSlice/getUserById",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return UserNetworkService.getUser(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );


    // Delete User By ID
    static deleteUserByID = createAsyncThunk(
        "UserSlice/deleteUserById",
        (data: any, { dispatch, rejectWithValue }: any) => {
            return UserNetworkService.deleteUser(data)
                .then((response: any) => {
                    return response;
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response);
                })
                .finally(() => { });
        }
    );

    // GET USER ROLES
    static getUserROles = createAsyncThunk(
        'UserSlice/getUserRoleListing',
        (data: any, {dispatch, rejectWithValue}) => {
            return UserNetworkService.getUserRoles(data)
                .then(async (response: any) => {
                    return response
                })
                .catch((error: { response: unknown }) => {
                    return rejectWithValue(error?.response)
                })
                .finally(() => { })
        }
    )

}