import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import { TextTitle } from "./AnalyticsSummaryStyles";
import { DonoughtWrapper, ParentDonoughtWrapper } from "./BotConvDounoutstyle";
import {
  SkeletonCircleIconDoughnut
} from "./SkeletonLoader.tsx/SkeletonCard";

interface Props {
  botdata?: any;
  loading?: any;
}

const BotConversationDoughnout: React.FC<Props> = ({ botdata, loading }) => {
  const data = {
    labels: ["Handled By BOT", "Handed to Agents"],
    datasets: [
      {
        label: "# of Votes",
        data: Object.values(botdata) ?? [],
        backgroundColor: ["#00a76e", "#7973BC"],
        borderWidth: 4,
      },
    ],
  };

  const chartData: any = {
    series: botdata,

    options: {
      chart: { type: "donut" },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: "300px",
            },
            legend: {
              position: "bottom",
            },
          },
        },
        {
          breakpoint: 1440,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      labels: ["Handled By BOT", "Handed to Agents"],
      dataLabels: { enabled: false },
      // // tooltip: { enabled: false },
      fill: { colors: ["#00a76e", "#7973BC"] },
      colors: ["#00a76e", "#7973BC"],
      legend: {
        position: "bottom",
      },
      states: {
        hover: { filter: { type: "none", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
      },
      stroke: { width: 0 },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: "80%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "17px",
                fontWeight: "600",
                color: ["#000"],
                offsetY: -10,
              },
              total: {
                show: true,
                showAlways: true,
              },
            },
          },
        },
      },
    },
  };
  return (
    <ParentDonoughtWrapper>
      <div className="tooltip_header">
        <p className="dounoht_header">
          <TextTitle>Total Conversations</TextTitle>
          <AppToolTip
            tooltipProps={{
              title: "Total Conversations Distribution Metrics",
              placement: "top",
            }}
            iconButtonProps={{ disableRipple: true }}
            IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
          />
        </p>
      </div>
      <DonoughtWrapper
        isdata={
          data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0
        }
      >
        {loading ? (
          <DoughnutLoading>
            <SkeletonCircleIconDoughnut />
          </DoughnutLoading>
        ) : data.datasets[0].data[0] === 0 && data.datasets[0].data[1] === 0 ? (
          <NoPreview>No Preview</NoPreview>
        ) : (
        <div className="">
            {Object.values(botdata)?.length > 0 ? (
              <ReactApexChart
                type="donut"
                series={chartData.series}
                options={chartData.options}
              />
            ) : (
              <NoPreview>No Preview</NoPreview>
            )}
          </div>
        )}
      </DonoughtWrapper>
    </ParentDonoughtWrapper>
  );
};
export default BotConversationDoughnout;

const NoPreview = styled.div`
  min-width: 120px;
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DoughnutLoading = styled.div`
  min-width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  > span {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
