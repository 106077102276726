import React from "react";
import {
  WidgetMainChat,
  WidgetMainContent,
} from "../../Styles/StyledScreens";

import useDebounce from "../../../Hooks/useDebounce";
import AppSearchWithInChat from "./AppSearchWithInChat";
import AppWhatsappInboxTimeBox from "./AppWhatsappInboxTimeBox";
import { MessagesScreen } from "../../screens";

interface Props {
  children?: React.ReactNode;
}

const AppChatScreenWrapper: React.FC<Props> = () => {
  const messagesEndRef = React.useRef<any>(null);

  const [searchValue, setSearchValue] = React.useState<string>("");
  const debouncedSearchTerm = useDebounce<string>(searchValue, 800);

  return (
    <WidgetMainContent ref={messagesEndRef}>
      <AppSearchWithInChat
        messagesEndRef={messagesEndRef}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        debouncedSearchTerm={debouncedSearchTerm}
      />

      <AppWhatsappInboxTimeBox />
      <WidgetMainChat
        followButtonClassName="scrollToBottomDefaultButton"
        className="WidgetMainChat"
        scrollViewClassName="styledList"
        initialScrollBehavior="auto"
        behavior="smooth"
        mode="bottom"
        debug={false}
      >
        <MessagesScreen/>
      </WidgetMainChat>
    </WidgetMainContent>
  );
};

export default AppChatScreenWrapper;
