import React, { memo } from "react";
import { useAppDispatch, useAppSelector } from "../../../Store";
import {
  allMessagesStore,
  setToggleScrollFn,
} from "../../../Store/Slices/socket/AllMessagesSlice";
import { StyledMessageText } from "../../Styles/StyledComponents";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

interface Props {
  src: string;
  alt?: string;
  userMessage: boolean;
  time: string;
  children?: any;
  msgId: string;
  seen: string;
  sent: string;
  delivered: string;
  [otherProps: string]: any;
}

const AppRenderImage: React.FC<Props> = ({
  src = "",
  alt = "",
  time,
  userMessage = false,
  msgId,
  seen,
  sent,
  delivered,
}) => {
  const dispatch = useAppDispatch();
  const { toggleScrollFn } = useAppSelector(allMessagesStore);

  const [isImageLoaded, setIsImageLoaded] = React.useState<boolean>(false);

  if (!src) {
    return null;
  }
  return (
    <AppMessageBoxWrapper
      userMessage={userMessage}
      time={time}
      isMedia={true}
      msgId={msgId}
      seen={seen}
      sent={sent}
      delivered={delivered}
    >
        <img
          src={src}
          alt={alt}
          onClick={() => window.open(src, "_blank")}
          onLoad={() => {
            if (!isImageLoaded) {
              setIsImageLoaded(true);
              dispatch(setToggleScrollFn(!toggleScrollFn));
            }
          }}
          onError={(error: any) => {
            error.target.src = FallbackImage;
          }}
        />
        {alt && alt?.length > 0 ? (
          <StyledMessageText
            userMessage={userMessage}
            dangerouslySetInnerHTML={{
              __html: alt,
            }}
            className="getaChatMessageTextBox" // do not remove this class as it is used in AppChatScreenWrapper.tsx for chat search
          />
        ) : null}
    </AppMessageBoxWrapper>
  );
};

export default memo(AppRenderImage);
