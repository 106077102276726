import { Checkbox } from "@mui/material";
import { StyledResponsiveDivTable } from "./Styles";
import { humanizeDate, makeStringCapitalizeAndRemoveSpace } from "../../Utils";
import styled from "styled-components";

const ResponsiveTable = <T extends object>({
  columns,
  data,
  // pageSize = data?.length,
  rowsPerPage = 10,
  pageNumber = 1,
  totalDataCount = 0,
  selectedDataCount = 0,
  name,
  setCheckboxFieldValue: setFieldValue,
  hasMoreData = false,

  handleClick,
  handleSelectAllClick,
  subscriptionName = ""

}: InfiniteScrollTableProps<T>) => {
  // Generate render type using getType function
  const renderType: RenderType<any> = getType(data && data?.[0]);

  if (data?.length <= 0) {
    return null;
  }


  return (
    <ScrollerDiv>
      <StyledResponsiveDivTable>
        {/* <InfiniteScroll
          dataLength={data?.length}
          next={fetchMoreTableData}
          hasMore={hasMoreData}
          loader={<InfiniteLoaderComponent />}
          scrollThreshold={0.9}
          endMessage={<span className="no-more-entries">No more entries to show</span>}
          height={430}
          className="table"
        > */}
        <div className="tableStickyHead">
          <div className="tablHeadRow">
            <div className="tableHeadCell" style={{ justifyContent: "left" }}>
              {" "}
              <Checkbox

                checked={selectedDataCount === totalDataCount ? true : false}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all items" }}
              />
            </div>
            {columns?.map((column: any, index: number) => {
              return (
                <>
                  {column !== "id" &&
                    column !== "isSelected" ? (
                    <div className="tableHeadCell" key={index.toString()}>
                      {makeStringCapitalizeAndRemoveSpace(
                        column?.label ||
                        column?.name ||
                        (typeof column === "string" && column) ||
                        ""
                      )}
                    </div>
                  ) : null}
                </>
              );
            })}
          </div>
        </div>
        <div className="tableBody">
          {data?.map((item: any, index: number) => {
            return (
              <div
                key={index.toString()}
                role="checkbox"
                aria-checked={item?.isSelected}
                tabIndex={-1}
                className={`tableBodyRow ${item?.isSelected ? " activeRow" : ""} ${item[subscriptionName] === "No" ? "disabled" : ""}`}
              >
                <div className="tableBodyCell" style={{ justifyContent: "left" }}>
                  <Checkbox
                    checked={item?.isSelected && item[subscriptionName] !== "No"}
                    onClick={(event) => handleClick(event, item, index)}
                    disabled={item[subscriptionName] === "No"}
                  />
                </div>
                {columns?.map((column: any, index2: number) => {
                  if (
                    column !== "id" &&
                    column !== "isSelected"
                  ) {
                    return (
                      <div className="tableBodyCell" key={index2.toString()}>
                        {renderData(item[column], column, renderType)}
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            );
          })}
        </div>
        {/* </InfiniteScroll> */}
      </StyledResponsiveDivTable>
    </ScrollerDiv>
  );
};

export default ResponsiveTable;

interface InfiniteScrollTableProps<T> {
  columns: T[];
  data: T[];

  rowsPerPage?: number;
  pageNumber?: number;
  totalDataCount: number;
  selectedDataCount?: number;

  hasMoreData?: boolean;

  name: string;
  setCheckboxFieldValue: any;

  handleClick?: any;
  handleSelectAllClick?: any;
  subscriptionName?: string
}

type RenderType<T> = {
  [key in keyof T]:
  | "string"
  | "number"
  | "boolean"
  | "date"
  | "null"
  | "object"
  | "array";
};

const getType = <T,>(data: T): RenderType<T> => {
  const renderType: RenderType<T> = {} as RenderType<T>;
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];
      let type = typeof value;
      
      if (type === "number" && Number.isFinite(value)) {
        // Render numbers as float with 2 digits after decimal
        renderType[key as keyof T] = "number";
      } else if (type === "string") {
        // Render strings as string
        renderType[key as keyof T] = "string";
      } else if (
        type === "object" &&
        (Array.isArray(value) || value === null)
      ) {
        // Render arrays and null as null
        renderType[key as keyof T] = "null";
      } else if (type === "object" && value instanceof Date) {
        // Render Date objects as local time
        renderType[key as keyof T] = "date";
      } else if (type === "boolean") {
        // Render booleans as boolean
        renderType[key as keyof T] = "boolean";
      } else {
        renderType[key as keyof T] = "string";
      }
    }
  }
  return renderType;
};

const renderData = (value: any, field: string, renderType: any) => {  
  if(typeof value !== 'object'){
    switch (renderType[field]) {
      case "string":
        return value as string;
      case "number":
        // return typeof value === "number" ? getRoundOffNumbers(value) : value;
        return typeof value === "number" ? value : value;
      case "boolean":
        return typeof value === "boolean" ? value.toString() : null;
      case "object":
        return value;
      case "array":
        return null;
      case "date":
        return typeof value === "string" ? humanizeDate(value, true) : null;
      default:
        return value ? value : null;
    }
  }
};


const ScrollerDiv = styled.div`
  overflow-x: auto;
`