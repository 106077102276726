import http from "../../../NetworkServices/http";
import { getCurrentTimeZone } from "../../../Utils";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class BroadcastNetworkService {
  static getBroadcastEmailInsightsData(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.EMAIL_BROADCAST_INSIGHTS}`,
      data
    );
  }

  static getAllCommunicationLogsData(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.COMMUNICATION_LOGS_LIST}`,
      data
    );
  }

  static getWhatsappDetailsLogs(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.WHATSAPP_DETAILS_LOGS}?start_date=${data?.start}&end_date=${data?.end}&search=${data?.search}&status=${data?.status}&source=${data?.source}&limit=${data?.limit}&offset=${data?.offset}&category=${data?.category}`
    );
  }

  static getBroadcastStatsByID(id: string) {
    return http.get(`${consoleBaseUrl}${Endpoints.BROADCAST_STATS_ID}/${id}`);
  }

  static getBroadcastByID(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.BROADCAST_BY_ID}/${data?.id}`,
      data
    );
  }

  static getAllFilterSettings(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.COMMUNICATION_FILTER_SETTINGS}?type=${data?.type}`,
      data
    );
  }

  static getCommunicationReportsList(params: any) {
    params.timezone = getCurrentTimeZone()
    const url = `${consoleBaseUrl}${Endpoints.COMMUNICATION_LOGS_REPORT_LIST}`;
    return http.get(url, { params });
  }

  static getWhastappConversationInsights(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHATSAPP_CONVERSATION_INSIGHTS}`;
    return http.get(url, { params });
  }

  static getWhatsappTemplateGraphNetwork(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.WHATSAPP_TEMPLATE_GRAPH}/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`,
    );
  }

  static getWhatsappTotalMessagesNetwork(data: any) {
    return http.get(
      `${consoleBaseUrl}${Endpoints.WHATSAPP_TOTAL_MESSAGES}/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`,
    );
  }

  static whatsappExportTemplateLogs(data: any) {
    return http.post(
      `${consoleBaseUrl}${Endpoints.WHATSAPP_EXPORT_TEMPLATE}/${data?.id}?start_date=${data?.start_date}&end_date=${data?.end_date}`,
      {}
    );
  }

  static getWhatsappTotalMessagesLogsNetwork(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHATSAPP_LOGS}`;
    return http.get(url, { params })
  }

  static getWhatsappConversationGraphNetwork(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHATSAPP_CONVERSATION_GRAPH}`;
    return http.get(url, { params });
  }

  static getWhatsappMessageSummary(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHATSAPP_MESSAGE_SUMMARY}`;
    return http.get(url, { params });
  }

  static getWhatsappMessageSummaryGraph(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHATSAPP_MESSAGE_SUMMARY_GRAPH}`;
    return http.get(url, { params });
  }
  static getWhatsappLogs(params: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHATSAPP_LOGS}`;
    return http.get(url, { params });
  }
  //whatsapp broadcast overview
  static getWhatsappBroadcastOverview(id: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHATSAPP_BROADCAST_OVERVIEW}/${id}`;
    return http.get(url);
  }
}

class Endpoints {
  // Broadcasting APIS


  //new endpoints for node microservice
  static COMMUNICATION_LOGS_REPORT_LIST = "/workspace/broadcast/communication-logs/report-list";
  static COMMUNICATION_FILTER_SETTINGS = "/workspace/broadcast/communication-filter-settings";
  static COMMUNICATION_LOGS_LIST = "/workspace/broadcast/communication-logs";
  static WHATSAPP_DETAILS_LOGS = "/workspace/analytics/whatsapp-detailed-logs";
  static EMAIL_BROADCAST_INSIGHTS = "/workspace/broadcast/all-broadcasting-stats";
  static BROADCAST_STATS_ID = "/workspace/broadcast/broadcast-stats-id";
  static BROADCAST_BY_ID = "/workspace/broadcast/broadcast-by-id";
  static WHATSAPP_CONVERSATION_INSIGHTS = "/workspace/analytics/whatsapp-conversation-insights"
  static WHATSAPP_TEMPLATE_GRAPH = "/workspace/analytics/whatsapp-template-graph"
  static WHATSAPP_TOTAL_MESSAGES = "/workspace/analytics/whatsapp-template-summary"
  static WHATSAPP_EXPORT_TEMPLATE = "/workspace/analytics/whatsapp-export-template-logs"
  static WHATSAPP_TOTAL_MESSAGES_LOGS = "/workspace/analytics/whatsapp-template-logs"
  static WHATSAPP_CONVERSATION_GRAPH = "/workspace/analytics/whatsapp-conversation-graph"
  static WHATSAPP_MESSAGE_SUMMARY = "/workspace/analytics/whatsapp-message-summary"
  static WHATSAPP_MESSAGE_SUMMARY_GRAPH = "/workspace/analytics/whatsapp-message-summary-graph"
  static WHATSAPP_LOGS = "/workspace/analytics/whatsapp-logs"
  static WHATSAPP_BROADCAST_OVERVIEW = "/workspace/broadcast/whatsapp-broadcast-overview"
}
