import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import Grow from "@mui/material/Grow";
import { getIn, useFormikContext } from "formik";
import React, { Fragment, useState } from "react";
import styled from "styled-components";

import AppButton from "../../../../../AppComponents/AppButton";
import { theme } from "../../../../../Customization/Theme";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import useModal from "../../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { ChatbotConsoleService } from "../../../../../Services/Apis/ChatbotConsoleService";
import { AppMaterialIcons } from "../../../Icons";
import AppVideoPlayer from "../../../UtilsComponents/AppVideoPlayer";
import AppErrorMessage from "../../AppErrorMessagesField";
import AppProgressBar from "./AppProgressBar";
import {
  ImageState,
  getCorrectMediaSize,
  getUploadedMediaType,
  removeCharactersFromString,
  sizeInMB,
} from "./utils";
import { StyledErrorText } from "../../../../Styles/StyledForms";
import {
  MEDIA_FILE_UPLOAD_STATUS,
  getMediaSizeStatus,
} from "../../../../../Utils";
import { ProtectedAppUiElement } from "../../../../../AppComponents/ProtectedComponents";

interface Props {
  name?: string;
  icon?: string;
  focusEditor: () => void;
}

const EditorMediaUpload: React.FC<Props> = ({
  name = "media",
  icon = "FileUploadOutlined",
  focusEditor,
}) => {
  const { showAlert } = useNotification();
  const { isShowing, toggle } = useModal();
  const { values, setFieldError, setFieldValue, errors } = useFormikContext();
  const [file, setFile] = React.useState<any>(getIn(values, "media"));

  React.useEffect(() => {
    if (!getIn(values, "media")) {
      imageResetHandler();
    }
  }, [getIn(values, "media")]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    toggle(!isShowing);
  };

  const handleClose = async () => {
    toggle(false);

    setTimeout(() => {
      focusEditor();
    }, 1);
  };

  const [mainState, setMainState] = useState<ImageState>(ImageState.INITIAL); // initial, search, gallery, uploaded
  const [uploadMediaType, setUploadMediaType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const handleUploadClick = (event: any) => {
    // setFieldTouched(name, true);
    var file = event.target.files[0];
    const reader: any = new FileReader();
    if (!reader || !file) {
      imageResetHandler();
      return null;
    }
    var url = reader?.readAsDataURL(file);

    reader.onloadend = (e: any) => {
      var mimeType = e?.target?.result
        ?.split(",")[0]
        .split(":")[1]
        .split(";")[0];

      const resultMedia = getUploadedMediaType(mimeType);
      const resultSize = getCorrectMediaSize(resultMedia);

      setUploadMediaType(resultMedia);

      if (!resultMedia) {
        setTimeout(() => {
          setFieldError(name, MEDIA_FILE_UPLOAD_STATUS.FORMAT);
        }, 10);
        return;
      }

      if (resultMedia && resultSize && file.size > resultSize) {
        setFieldError(name, getMediaSizeStatus(resultSize / 1000000));
        return;
      }

      onImageChange(file, resultMedia);
    };
  };

  const onImageChange = (file: any, resultMedia: string) => {
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append("source", file);

      const config: any = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;

          let percent = Math.floor((loaded * 100) / total);

          setProgress(percent);
        },
      };

      let temp = {
        formData: formData,
        config: config,
      };
      ChatbotConsoleService.uploadFileRichEditor(temp)
        .then((response: any) => {
          let data = response?.data;
          data["name"] = removeCharactersFromString(data?.name, 7);
          data["type"] = resultMedia || uploadMediaType;
          setFile(data);
          setMainState(ImageState.UPLOADED);
          setTimeout(() => {
            setFieldError(name, undefined);
          }, 100);
          showAlert(MEDIA_FILE_UPLOAD_STATUS.SUCCESS, "success");
        })
        .catch((error: any) => {
          setMainState(ImageState.INITIAL);
          setFile(null);
          setTimeout(() => {
            setFieldError(
              name,
              error?.payload.data?.error ||
              MEDIA_FILE_UPLOAD_STATUS.ERROR_UNDEFINED
            );
          }, 10);
          showAlert(
            error?.payload.data?.error ||
            MEDIA_FILE_UPLOAD_STATUS.ERROR_UNDEFINED,
            "error"
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const imageResetHandler = () => {
    setMainState(ImageState.INITIAL);
    setFile(null);
    setFieldValue(name, null);
    setUploadMediaType("");
    setFieldError(name, undefined);
  };

  const inputRef = React.useRef<any>(); // Create a ref to hold the input element

  React.useEffect(() => {
    // Focus the input element when the component is mounted
    if (mainState === ImageState.INITIAL) {
      inputRef?.current?.focus();
    }
  }, [mainState]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderInitialState = () => {
    return (
      <>
        <StyledIntialState>
          <StyledLabel htmlFor={name}>
            <span className="drop-title">Drop files here</span>
            or
            <input
              accept={"*"}
              id={name}
              name={name}
              type="file"
              onChange={handleUploadClick}
              value=""
              autoFocus={true}
              ref={inputRef}
            />
          </StyledLabel>
          {getIn(errors, name) ? (
            <StyledErrorText style={{ backgroundColor: "transparent" }}>
              {getIn(errors, name)?.toString()}
            </StyledErrorText>
          ) : null}
        </StyledIntialState>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            marginTop: "1rem",
          }}
        >
          <AppButton
            variant="text"
            onClick={(e: any) => {
              imageResetHandler();
              handleClose();
            }}
            style={{ color: "red" }}
          >
            {" "}
            Cancel
          </AppButton>
          <AppButton
            onClick={(e: any) => {
              setFieldValue(name, file);
              handleClose();
            }}
            disabled={!getIn(values, "media")}
          >
            Continue
          </AppButton>
        </div>
      </>
    );
  };

  const renderUploadedState = () => {
    return (
      <StyledUploadedState>
        {" "}
        <StyledMediaBox>
          {!file?.source && <CircularProgress style={{ color: "red" }} />}
          {uploadMediaType === "image" && (
            <img
              src={file?.source}
              alt="media"
              style={{ height: "200px", width: "100%" }}
            />
          )}
          {uploadMediaType === "document" && (
            <AppMaterialIcons
              iconName={"Article"}
              style={{ fontSize: "2.5rem" }}
            />
          )}
          {uploadMediaType === "video" && (
            <AppVideoPlayer lightMode={false} url={file?.source} />
          )}
        </StyledMediaBox>
        <StyledUploadedImageWrap>
          {file?.name}
          <Tooltip
            arrow
            enterDelay={1}
            leaveDelay={0}
            TransitionComponent={Grow}
            placement="top"
            title={"Delete"}
          >
            <IconButton onClick={() => imageResetHandler()}>
              <ClearRoundedIcon />
            </IconButton>
          </Tooltip>
        </StyledUploadedImageWrap>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <AppButton
            variant="text"
            onClick={(e: any) => {
              imageResetHandler();
            }}
            style={{ color: "red" }}
          >
            {" "}
            Cancel
          </AppButton>
          <AppButton
            onClick={(e: any) => {
              setFieldValue(name, file);
              handleClose();
            }}
            autoFocus={true}
          >
            Continue
          </AppButton>
        </div>
      </StyledUploadedState>
    );
  };

  return (
    <Fragment>
      <AppModel isShowing={isShowing} onClose={() => handleClose()}>
        <StyledViewWrap>
          {loading ? (
            <AppProgressBar progress={progress} setProgress={setProgress} />
          ) : (
            (mainState === ImageState.INITIAL && renderInitialState()) ||
            (mainState === ImageState.UPLOADED && renderUploadedState())
          )}
        </StyledViewWrap>
      </AppModel>
        <IconButton title={"Upload"} onClick={handleClick}>
          <AppMaterialIcons
            iconName={"FileUploadOutlined"}
            style={{ fontSize: "1rem" }}
          />
        </IconButton>
    </Fragment>
  );
};

export default EditorMediaUpload;

const StyledUploadedImageWrap = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      cursor: pointer;
      font-size: 1.1rem;
      color: ${theme.palette.default.darkGrey};
    }
  }
`;

const StyledViewWrap = styled.div`
  && {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    margin: 0rem -0.5rem 0rem;
    input {
      padding: 0.5rem 1rem;

      color: #444;
      cursor: pointer;
    }
  }
`;

const StyledIntialState = styled.div`
  && {
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    border: 2px dashed #555;
    color: #444;
    cursor: pointer;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

    &:hover {
      background: #eee;
      border-color: #111;
      .drop-title {
        color: #222;
      }
    }

    .drop-title {
      color: #444;
      font-size: 1rem;
      font-weight: bold;
      text-align: center;
      transition: color 0.2s ease-in-out;
    }

    input[type="file"]::file-selector-button {
      margin-right: 20px;
      border: none;
      background: #084cdf;
      padding: 10px 20px;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      transition: background 0.2s ease-in-out;
    }

    input[type="file"]::file-selector-button:hover {
      background: #0d45a5;
    }

    .MuiFab-root {
      width: 48px;
      height: 48px;
      color: ${theme.palette.primary.main};
    }

    .MuiIconButton-root {
      width: 48px;
      height: 48px;
      color: ${theme.palette.primary.main};
      background-color: ${theme.palette.secondary.main};
      border-radius: 50%;
    }
  }
`;

const StyledUploadedState = styled.div`
  && {
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
`;

const StyledLabel = styled.label`
  && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledMediaBox = styled.div`
  && {
    width: 100%;
    max-height: 200px;
    overflow: hidden;
    display: flex;
    position: relative;
    justify-content: center;
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 6px;
    }

    svg {
      color: ${theme.palette.default.darkGrey};
    }
  }
`;
