import * as Yup from "yup";
import uniqid from "uniqid";

// Step 1 Options
export const DataContainsRadioOptions: any[] = [
  {
    name: "Mobile",
    value: "mobile_phone_number",
    id: uniqid(),
  },
  {
    name: "Email ID",
    value: "email",
    id: uniqid(),
  },
  {
    name: "Both",
    value: "both",
    id: uniqid(),
  },
];

// Step 2 Options
export const WhatIfDuplicateData: any[] = [
  {
    name: "Create new contacts",
    value: "create_new_contacts",
    infoText:
      "If you're starting with a brand new list, use this option to add completely new contacts.",
    id: uniqid(),
  },
  {
    name: "Create new and update existing contacts (without overwrite - Update only empty fields)",
    value: "create_new_and_update_existing_contacts_without_overwrite",
    infoText:
      "This will create new contacts and update contact fields which are currently empty. This action is irreversible.",
    id: uniqid(),
  },
  {
    name: "Create new and update existing contacts (with overwrite - Update all the fields)",
    value: "create_new_and_update_existing_contacts_with_overwrite",
    infoText:
      "This will create new contacts and update all contact fields with the data from uploaded file. This action is irreversible.",
    id: uniqid(),
  },
  {
    name: "Update only existing contacts (without overwrite - Update only empty fields)",
    value: "update_only_existing_contacts_without_overwrite",
    infoText:
      "This will fill data in only the empty contact field from the data available in the uploaded file.",
    id: uniqid(),
  },
  {
    name: "Update only existing contacts (with overwrite - Update all the fields)",
    value: "update_only_existing_contacts_with_overwrite",
    infoText:
      "This will update all the exiting contact records fields from the data available in the uploaded file. This action is irreversible.",
    id: uniqid(),
  },
];

export const WhatsappSubScriptionValueData: any[] = [
  {
    name: "Yes",
    value: "Yes",
    id: uniqid(),
  },
  {
    name: "No",
    value: "No",
    id: uniqid(),
  },
];

export const UniqueValueData: any[] = [
  {
    name: "Email ID",
    value: "email",
    id: uniqid(),
  },
  {
    name: "Mobile",
    value: "mobile_phone_number",
    id: uniqid(),
  },
];

export const SMSSubScriptionValueData: any[] = [
  {
    name: "Yes",
    value: "Yes",
    id: uniqid(),
  },
  {
    name: "No",
    value: "No",
    id: uniqid(),
  },
];

export const EmailSubScriptionValueData: any[] = [
  {
    name: "Yes",
    value: "Yes",
    id: uniqid(),
  },
  {
    name: "No",
    value: "No",
    id: uniqid(),
  },
];

export const ImportFormStep1Schema = Yup.object().shape({
  data_contains: Yup.string()
    .oneOf(DataContainsRadioOptions, "only select from these options")
    .required("Select a option for position")
    .label("PopUp Position"),
});

export const ImportFormStep2Schema = Yup.object().shape({
  marketing_list: Yup.string()
    .min(2, "Marketing list is a required field")
    .required()
    .label("Source"),
    lifecycle_stage: Yup.string().min(2, "Lifecycle stage is a required field").required().label("lifecycle Stage"),
    lifecycle_status: Yup.string().min(2, "Lifecycle status is a required field").required().label("lifecycle Status"),
    lost_reason: Yup.string().min(2, "Lost reason is a required field").required().label("Lost reason")
                .when(["lostReasonStatus"], (value:any, schema:any) => {
                  return value 
                    ? schema.required("Please select a template.")
                    : schema.optional();
                })
});

export const ImportFormStep3Schema = Yup.object().shape({
  //   contact_owner: Yup.string()
  //     .required()
  //     .min(2, "Source is a required field")
  //     .label("Source"),
  //   marketing_list: Yup.string()
  //     .required()
  //     .min(2, "Source is a required field")
  //     .label("Source"),
});