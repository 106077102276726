import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import NoData from "../../assets/images/noDatafound.svg";
import moment from "moment";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import { ErrorComponent } from "../Broadcast/SMS/SenderID/ListingStyles";
import { BroadcastDetailListWrapper } from "../Broadcast/SMS/SenderID/SenderIDStyles";
import { BroadcastTopTitle } from "../../TableStyle";
import { AcquitionTableWrapper } from "./LabelsTableStyles";
import AppButton from "../../AppComponents/AppButton";
import { theme } from "../../Customization/Theme";
import ExportLogo from "./Dash_Assests/export 1.svg";
import SkeletonLoader from "../../AppComponents/Loader/SkeletonLoader";
import styled from "styled-components";

interface Props {
  list?: any;
  handleFileDownload?: any;
  loading?: boolean;
}

const ReactVirtualizedTable: React.FC<Props> = ({
  list,
  handleFileDownload,
  loading,
}) => {
  function formatTime(seconds: any) {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.round(seconds % 60);
    var timeString = "";
    if (hours > 0) {
      timeString += hours + " hr";
      if (hours > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (minutes > 0) {
      timeString += minutes + " min";
      if (minutes > 1) {
        timeString += "s";
      }
      timeString += " ";
    }
    if (remainingSeconds > 0 || timeString === "") {
      timeString += remainingSeconds + " sec";
      if (remainingSeconds !== 1) {
        timeString += "s";
      }
    }
    return timeString;
  }

  // Headers For Table
  const header = [
    "Label",
    "No. of Conversations",
    "Avg. Resolution Time",
    "Avg. Conversation Time",
  ];
  // Mapping For Table Column
  const keyToHeaderMap: any = {
    Label: "label",
    "No. of Conversations": "count",
    "Avg. Resolution Time": "average_rtime",
    "Avg. Conversation Time": "average_ctime",
  };
  // Width For Table Column
  const colWidth: any = {
    Label: "200",
    "No. of Conversations": "200",
    "Avg. Resolution Time": "200",
    "Avg. Conversation Time": "200",
  };
  const formatDateField = (dateObj: any) => {
    const date = moment(dateObj).format(" DD MMM , YYYY hh:mm A ");
    return date;
  };

  return (
    <WrapperContainer>
      <BroadcastDetailListWrapper style={{ padding: "16px 16px 0px 16px" }}>
        <BroadcastTopTitle style={{ padding: "10px 0px 0px 18px" }}>
          <Box
            className="topTitle-left"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              component="h6"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Labels{" "}
              <AppToolTip
                tooltipProps={{
                  title: "Labels used in conversations",
                  placement: "top",
                }}
                iconButtonProps={{ disableRipple: true }}
                IconComponent={<InfoOutlinedIcon className="div_tooltip" />}
              />
            </Typography>
            {
              <AppButton
                style={{
                  display: "flex",
                  gap: "15px",
                  backgroundColor: theme.palette.default.lighterFilterGrey,
                  color: "gray",
                }}
                variant="gray"
                onClick={(e: any) => handleFileDownload(e)}
                disabled={list?.length > 0 ? false : true}
              >
                Export
                <img src={ExportLogo} alt="Not Found" />
              </AppButton>
            }
          </Box>
        </BroadcastTopTitle>

        <AcquitionTableWrapper>
          <div>
            <Box className="table-header">
              <Box className="table-row">
                {header?.map((header: string, index: number) => {
                  return (
                    <Box
                      className={`col-head ${header
                        .toLowerCase()
                        .replaceAll(" ", "_")}`}
                      style={{
                        minWidth: colWidth[header] + "px",
                      }}
                      key={index}
                    >
                      <Typography sx={{ fontWeight: 500 }}>{header}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box className="table-body">
              {list?.length === 0 ? (
                !loading ? (
                  <ErrorComponent style={{ height: "225px" }}>
                    <Box>
                      <img src={NoData} width="150" alt="" />
                    </Box>
                    <Box className="err-content">
                      <Typography variant="h6" fontSize="20px">
                        No Records Found.
                      </Typography>
                    </Box>
                  </ErrorComponent>
                ) : (
                  <SkeletonLoader numberOfCol={4} numberofRow={3} />
                )
              ) : (
                <>
                  {list &&
                    list?.map((row: any, rowIndex: any) => {
                      return (
                        <Box key={rowIndex} className="table-row">
                          {header?.map((header: any, ColumnIndex: any) => {
                            const isActionColumn = header === "Action";
                            return (
                              <Box
                                className={`col-body ${header
                                  .toLowerCase()
                                  .replaceAll(" ", "_")}`}
                                title={
                                  header === "Scheduled"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]]
                                      )
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"
                                }
                                style={{
                                  minWidth: isActionColumn
                                    ? "400px"
                                    : colWidth[header] + "px",
                                }}
                                key={ColumnIndex}
                              >
                                <Typography
                                  title={row[keyToHeaderMap[header]]}
                                  className="table-text"
                                >
                                  {header === "Scheduled"
                                    ? formatDateField(
                                        row[keyToHeaderMap[header]]
                                      )
                                    : header === "Avg. Resolution Time" ||
                                      header === "Avg. Conversation Time"
                                    ? formatTime(row[keyToHeaderMap[header]])
                                    : row[keyToHeaderMap[header]]
                                    ? row[keyToHeaderMap[header]]
                                    : "-"}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Box>
                      );
                    })}
                </>
              )}
            </Box>
          </div>
        </AcquitionTableWrapper>
      </BroadcastDetailListWrapper>
    </WrapperContainer>
  );
};

// Default Export of component
export default ReactVirtualizedTable;

export const WrapperContainer = styled.div`
  width: 100%;
`;
