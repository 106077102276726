import { Backdrop, Box, CircularProgress, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  AppFormField,
  AppFormObserver,
  AppSubmitButton,
} from "../../AppComponents/Forms";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { SegmentSchema } from "../../Models/ValidationSchema";
import { useAppDispatch, useAppSelector } from "../../Store";
import { QueryBuilderActions } from "../../Store/Slices/QueryBuilder/QueryBuilder.actions";
import { useQueryBuilderStore } from "../../Store/Slices/QueryBuilder/QueryBuilder.selectors";
import { SegmentActions } from "../../Store/Slices/Segment/Segment.actions";
import { SegmentNetworkService } from "../../Store/Slices/Segment/SegmentNetworkService";

import { ProtectedAppForm } from "../../AppComponents/ProtectedComponents";
import { AppQueryBuilder } from "../../AppComponents/QueryBuilder";
import {
  convertQueryToMongo,
  initialQuery,
  isQueryValid,
} from "../../AppComponents/QueryBuilder/utils/utils";
import { findPopupFormSpecificPermissionKeyUsingId } from "../../Utils/rolesUtils";
import styled from "styled-components";

interface Props {
  editData: any;
  getTableData: any;
}

const CreateSegment: React.FC<Props> = ({ editData = null, getTableData }) => {
  const { showAlert } = useNotification();
  const dispatch = useAppDispatch();
  const formRef: any = useRef();

  const { queryBuilderFields, queryBuilderOperators, loading } =
    useAppSelector(useQueryBuilderStore);

  const [formikProps, setFormikProps] = useState<any>(null);
  const [userInput, setUserInput] = useState({
    name: "",
    filter_query: initialQuery,
  });

  //*WILL BE USED TO SET THE FORM ACCESS TYPE
  const [formAccessType, setFormAccessType] = useState<
    "create" | "read" | "update" | "delete" | undefined
  >();
  //*getting the form access type using the location on first render
  useEffect(() => {
    const result = findPopupFormSpecificPermissionKeyUsingId(editData);
    setFormAccessType(result ?? "create");
  }, [editData]);

  useEffect(() => {
    if (editData?.id) {
      insertPreviousFormValues(editData);
    }
  }, [editData]); // eslint-disable-line react-hooks/exhaustive-deps

  const insertPreviousFormValues = (data: any) => {
    if (!data?.id || editData?.filter_query?.rules === 0) return;

    setUserInput({
      ...userInput,
      name: editData?.name,
      filter_query: data?.filter_query,
    });
  };
  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    const validQuery = isQueryValid(values?.filter_query);

    if (!validQuery) {
      showAlert("Please add only valid rules.", "error");
      SubmitProps.setSubmitting(false);
      return;
    }

    const mongoQuery = convertQueryToMongo(values?.filter_query);

    if (!editData?.id) {
      let data = {
        mongodb_query: mongoQuery,
        filter_query: values?.filter_query,
        name: values?.name?.trim(),
      };
      dispatch(SegmentActions.createSegment(data))
        .then((res: any) => {
          if (res?.payload?.data) {
            getTableData();
            showAlert("Segment is Created", "success");
          } else {
            showAlert(res?.payload?.data?.error, "error");
          }
        })
        .catch((err: any) => {
          console.warn("error while creating segment", err);
        })
        .finally(() => {
          SubmitProps.setSubmitting(false);
        });
    }

    if (editData?.id) {
      let data = {
        mongodb_query: mongoQuery,
        filter_query: values?.filter_query,
        name: values?.name?.trim(),
      };

      SegmentNetworkService.editSegment(data, editData?.id)
        .then((res: any) => {
          if (res?.data?.error) {
            showAlert(res?.data?.error, "error");
          } else {
            getTableData();
            showAlert("Segment is Updated", "success");
          }
        })
        .catch((err: any) => {
          showAlert(
            err?.response?.data.error || "Something went wrong",
            "error"
          );
          console.warn("error while creating segment", err);
        })
        .finally(() => {
          SubmitProps.setSubmitting(false);
        });
    }
  };

  const getFieldsDataFromBackend = () => {
    let module_id = "other";
    let payload = {
      id: module_id,
    };
    dispatch(QueryBuilderActions.getQueryBuilderMappingFields(payload));
  };

  useEffect(() => {
    getFieldsDataFromBackend();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onQueryChangeCallbackFn = (filtersValue: any) => {
    formikProps?.setFieldValue("filter_query", filtersValue);
  };
  return (<Wrapper>
    <Box className="form-first__area">
      <ProtectedAppForm
        initialValues={userInput}
        onSubmit={(values: any, submitProps: any) => {
          handleSubmitBtn(values, submitProps);
          setUserInput(values);
        }}
        validationSchema={SegmentSchema}
        moduleId="segments_crud"
        specificPermissionKey={formAccessType}
        innerRef={formRef}
      >
        <BackdropLoader loading={loading} />

        <AppFormField
          label="Segment name"
          name={"name"}
          placeholder="First time buyer"
        />
        {queryBuilderFields?.length > 0 ? (
          <AppQueryBuilder
            fields={queryBuilderFields}
            operators={queryBuilderOperators}
            onChangeCallbackFn={onQueryChangeCallbackFn}
            defaultInputValueFromApi={
              editData?.id ? userInput?.filter_query : null
            }
          />
        ) : null}

        <AppFormObserver setFormikProps={setFormikProps} />
        <Divider />
        <Box
          display="flex"
          sx={{ justifyContent: "flex-end", marginTop: "8px" }}
        >
          <AppSubmitButton title="SAVE" />
        </Box>
      </ProtectedAppForm>
    </Box>
  </Wrapper>
  );
};

export default CreateSegment;

interface BackdropProps {
  loading: boolean;
}
export const BackdropLoader: React.FC<BackdropProps> = ({
  loading = false,
}) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 99 }}
      open={loading ? true : false}
      onClick={() => undefined}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const Wrapper =  styled.div`
&& {
  height: calc(100vh - 59px);
  .ruleGroup {
    height: calc(100vh - 242px);
  }
}
`;
