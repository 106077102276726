import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { device } from "../../../../Customization/BreakPoints";
// Css main Layout
export const WrapperContainer = styled.div`
  width: 100%;
  //   height: calc(100% - 64px);
  height: 100%;
`;
// Css for topbar
export const TopBar = styled.div`
  box-shadow: ${theme.palette.default.shadow};
  padding: 7px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
`;
// Css for topbar leftCol
export const LeftCol = styled.div`
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.6rem;
  color: ${theme.palette.default.darkGrey};
  flex-wrap: wrap;
  .limit {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    strong {
      color: ${theme.palette.default.text};
    }
  }
  span {
    color: ${theme.palette.default.text};
    margin-left: 5px;
  }
  svg {
    max-width: 20px;
    max-height: 20px;
    margin-left: 5px;
  }
  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
  }
`;
// Css for topbar rightCol
export const RightCol = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  gap: 1.4rem;
  .contact {
    display: flex;
    flex-direction: column;
  }
  .conversations {
    display: flex;
    align-items: center;
  }
  .credits {
    display: flex;
    align-items: center;
    padding: 16px;
  }

  @media ${device.laptopL2} {
    font-size: 0.875rem;
  }
  @media ${device.laptop2} {
    font-size: 0.813rem;
    gap: 0.8rem;
    .buy-credits__btn {
      button {
        width: 93px;
      }
    }
  }
`;
// Css for topbar col
export const Col = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${theme.palette.default.darkGrey};
  label {
  }
  &:first-child {
    strong {
      margin-left: 0;
    }
  }
  strong {
    color: ${theme.palette.default.text};
    font-weight: 500;
    font-size: 1rem;
    margin-left: 4px;
  }
`;
// Css for Analytics List Wrapper
export const AnalyticsBoxWrapper = styled.div`
  & {
    display: flex;
    gap: 10px;
    -webkit-box-pack: start;
    justify-content: space-between;
    overflow: auto;
    padding-bottom: 8px;
    margin-top: 16px;
  }
`;

export const AnalyticsBox = styled.div`
  & {
    display: flex;
    padding: 2rem 0;
    border-radius: 1rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
    flex-grow: 1;
    max-height: 191px;
    min-height: 191px;
    background-color: ${theme.palette.default.white};
    .top {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      // margin-bottom: 0.75rem;
      .icon {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #0d9d1b;

        .MuiSvgIcon-root {
          font-size: 22px;
          color: ${theme.palette.default.white};
        }
      }

      .send {
        .MuiSvgIcon-root {
          transform: rotate(315deg);
        }
      }

      h6 {
        font-size: 1.25rem;
        font-weight: 400;
      }
    }

    .bottom {
      h4 {
        font-size: 2.25rem;
        font-weight: 400;
        .percent {
          font-size: 1rem;
          color: #0f5500;
          /* font-weight: 600; */
        }
      }
    }

    .box-left {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      /* padding-right: 1rem; */
      padding-left: 1rem;
      gap: 1rem;
      width: 50%;
    }

    .box-right {
      display: flex;
      align-items: start;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      padding-left: 1rem;
      border-left: 1px dashed ${theme.palette.default.grey};
      padding-left: 1rem;
      width: 50%;
    }
  }
`;

export const AnalyticsWrapper = styled.div`
  padding: 10px 16px 6px;
  .topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h6 {
      color: ${theme.palette.default.black};
      font-size: 20px;
      line-height: 30px;
      margin: 0;
      font-weight: 500;
    }
  }
  .analyticsDataset {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .col {
      // border: 1px solid ${theme.palette.default.lightPrimaryBorder};
      // background-color: ${theme.palette.default.lightPrimaryBackground};

      height: 154px;
      padding: 24px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-radius: 8px;
      margin-top: 17px;

      .iconWrapper {
        background-color: ${theme.palette.default.white};
        width: 40px;
        height: 40px;
        border-radius: 5px;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
        background: #ffffff;
        box-shadow: 3px 3px 6px rgba(112, 170, 112, 0.15),
          inset 2px 2px 4px rgba(177, 199, 177, 0.15);
        border-radius: 8px;
        svg {
          color: ${theme.palette.primary.main};
        }
      }
      h6 {
        display: flex;
        align-items: center;
        color: ${theme.palette.default.black};
        margin-bottom: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        .customTooltip {
          margin-left: 5px;
          max-width: 13px;
          max-height: 13px;
        }
      }
      strong {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
`;
export const BroadcastListWrapper = styled.div`
  // background-color: ${theme.palette.default.lightGrey};
  //   padding: 10rem;
`;

export const BroadcastDetailListWrapper = styled.div`
  background-color: ${theme.palette.default.lightGrey};
  .detailSMStableAction-wrapepr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .detailSMStableAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
`;
