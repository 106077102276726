import { Link } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import AppButton from "../../../AppComponents/AppButton";
import { theme } from "../../../Customization/Theme";
import { useAppSelector } from "../../../Store";
import { useCurrentBot } from "../../../Store/Slices/DashboardSlices";
import { formatString } from "../../../Utils";

const GIF_FILE = require("../../../assets/animations/ServerReconnectError.gif");

interface Props {}

const ServerError: React.FC<Props> = () => {
  const navigate = useNavigate();
  // const currentBot = useAppSelector(useCurrentBot);

  const handleHomeClick = () => {
    // if (currentBot?.workspace_id) {
      const subUrl = `/`;
      // const url = formatString(subUrl, [currentBot?.workspace_id]);
      navigate(subUrl);
    // }
  };
  return (
    <StyledWrapper>
      <img src={GIF_FILE} className="image" alt="icon" />
      <div className="contentBox">
        <h3>We are unable to connect to our servers</h3>
        <p>
          Either try coming back after sometime or reach out to us{" "}
          <Link
            href="mailto:support@geta.ai"
            target="_blank"
            underline="hover"
            rel="noopener"
          >
            support@geta.ai
          </Link>{" "}
        </p>

        <div className="actionBox">
          <AppButton onClick={() => handleHomeClick()}>
            Go to homepage
          </AppButton>
          <AppButton onClick={() => window.location?.reload()}>
            Reconnect
          </AppButton>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default ServerError;

const StyledWrapper = styled.div`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: ${theme.palette.default.white};

    .image {
    }

    .contentBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      justify-content: center;
      align-items: center;
    }

    .actionBox {
      width: 100%;
      display: flex;
      gap: 2rem;
      justify-content: center;
      align-items: center;
    }
  }
`;
