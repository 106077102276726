import { ThemeProvider } from "@mui/system";
import { theme } from "./Customization/Theme";
import RootNavigator from "./Routes/RootNavigator";
import { Provider } from "react-redux";
import CustomSnackbarProvider from "./Hooks/useNotification/CustomSnackbarProvider";
import store from "./Store";
import { validateToken } from "./Utils/Utils";
import * as Sentry from "@sentry/react";

validateToken();

if (window.location.origin === "https://app.geta.ai/") {
  Sentry.init({
    dsn: "https://c38cc9c1273082c611f3ec8baf54831b@o4507446172188672.ingest.us.sentry.io/4507447376019456",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: process.env.REACT_APP_SENTRY_ENV,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost"],
    tracePropagationTargets: [process.env.REACT_APP_SENTRY_DOMAIN as string],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CustomSnackbarProvider>
          <RootNavigator />
        </CustomSnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
