import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  HomeLayoutWrap,
  HomeLayoutWrapLeft,
  HomeLayoutWrapRight,
} from "../Components/Layouts/ClientLayout";
import LeftMenu from "../Components/Menu/LeftMenu";
import TopMenu from "../Components/Menu/TopMenu";
import useAuthentication from "../Hooks/useAuthentication";
import { GETA_LAYOUT, useAppSelector, useLayoutStore } from "../Store";
import BuildRoutes from "./BuildRoutes";
import ClientRoutes from "./ClientRoutes";
import WelcomeRoutes from "./WelcomeRoutes";
import ExpireSubscription from "../Components/Subscription/Expired/ExpireSubscription";
import { useAuthStore } from "../Store/Slices/AuthSlice";
import { isEmptyObject } from "../Utils";
import { SocketContextProvider } from "../socket/hooks/socket-context";
import AppInternetReconnectingBox from "../socket/appComponents/UtilsComponents/AppInternetReconnectingBox";
import SocketSessionInfoModel from "../socket/appComponents/UtilsComponents/SocketSessionInfoModel";

const RootNavigator = () => {
  const { isUserLoggedIn } = useAuthentication();
  const { activeLayout } = useAppSelector(useLayoutStore);
  const { userConfig } = useAppSelector(useAuthStore);
  const location = useLocation();
  const permissionsData: any = userConfig?.payload?.allowed_permissions ?? null;

  if (
    isUserLoggedIn &&
    window.localStorage.getItem("expiry_time") &&
    userConfig?.payload &&
    isEmptyObject(permissionsData)
  )
    return null;

  return (
    <>
      <SocketContextProvider>
      <AppInternetReconnectingBox />
      {isUserLoggedIn &&
      window.localStorage.getItem("expiry_time") &&
      userConfig?.payload ? (
            <HomeLayoutWrap>
              <SocketSessionInfoModel/>
          <HomeLayoutWrapLeft>
            <LeftMenu />
          </HomeLayoutWrapLeft>
          <HomeLayoutWrapRight>
            <TopMenu />
            {userConfig?.payload?.subscription_active ||
            location?.pathname?.includes("subscription-plans/plans") ||
            location?.pathname?.includes("subscription/success") ? (
              <ContentWrap id="main-section">
                {activeLayout === GETA_LAYOUT.CLIENT_LAYOUT && <ClientRoutes />}
                {activeLayout === GETA_LAYOUT.BUILD_LAYOUT && <BuildRoutes />}
              </ContentWrap>
            ) : (
              <ExpireSubscription />
            )}
          </HomeLayoutWrapRight>
        </HomeLayoutWrap>
      ) : (
        <WelcomeRoutes />
        )}
      </SocketContextProvider>
        
    </>
  );
};

export const ContentWrap = styled.div`
  && {
    background-color: #f5f6f8;
    height: calc(100vh - 4rem);
    width: 100%;
    margin: 4rem 0rem 0 0;
    overflow-x: hidden;
    scroll-behavior: auto;
  }
`;
export default RootNavigator;
