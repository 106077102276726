import inline from "./inline";
import link from "./link";
import emoji from "./emoji";
import upload from "./upload";
import insert from "./insert"

export default {
  inline,
  link,
  emoji,
  upload,
  insert
};
