import { Box, Link, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { AppFormRadioField } from "../../../../../../AppComponents/Forms";
import AppFormCheckboxField from "../../../../../../AppComponents/Forms/AppFormCheckboxField";
import FilePicker from "../../FilePicker";
import { DataContainsRadioOptions } from "../constants";

interface Props {
  formStep: number;
  setFormStep: Dispatch<SetStateAction<number>>;
  fname: any;
  setfname: any;
  fileData: any;
  setfileData: any;
  setUserInput: any;
  formSaveStepData: any;
  uniqueValue: any;
  setState: any;
  // state: any;
}

const RenderImportRecordsFormStep1: React.FC<Props> = ({
  fileData,
  setfileData,
  uniqueValue,
  // state,
}) => {
  const formkey1 = "data_contains";
  const formkey2 = "upload_file";
  const formkey3 = "skip_duplicates";

  return (
    <Box className="step-one__wrap">
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        Data Contains
      </Typography>
      <AppFormRadioField
        name={formkey1}
        Options={DataContainsRadioOptions}
        divStyle={{ marginTop: "12px" }}
      />

      <Box sx={{ mt: "24px", mb: "24px" }}>
        <AppFormCheckboxField name={formkey3} label="Skip Duplicates" />
      </Box>

      <FilePicker
        name={formkey2}
        mediaType={"document"}
        sizeLimit={100000000}
        extensions={[
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel",
          "application/wps-office.xlsx",
          "text/csv",
        ]}
        divStyle={{ margin: "0rem", textAlign: "center" }}
        fileData={fileData}
        setfileData={setfileData}
      />

      <Box>
        {uniqueValue === "both" ? (
          <p className="sample-csv">
            Both valid <strong>emails</strong> and
            <strong>mobile numbers</strong> with country code is mandatory
            &nbsp;
            <Link href={"/csv/contact_import_sample.xlsx"} download>
              (refer sample file)
            </Link>
          </p>
        ) : uniqueValue === "email" ? (
          <p className="sample-csv">
            Valid <strong>emails</strong> will be imported &nbsp;
            <Link href={"/csv/contact_import_sample.xlsx"} download>
              (refer sample file)
            </Link>
          </p>
        ) : (
          <p className="sample-csv">
            <strong>Mobile number</strong> with country code is mandatory for
            the import process &nbsp;
            <Link href={"/csv/contact_import_sample.xlsx"} download>
              (refer sample file)
            </Link>
          </p>
        )}

        <div className="info">
          <ul>
            <li>
              Choose a name that clearly reflects the purpose or criteria of the
              list. For example, if you're targeting customers interested in a
              particular product category, you might name the list "ProductX
              Enthusiasts."
            </li>
            <li>
              Keep the name concise and descriptive so you can easily identify
              the list later when selecting recipients for your campaigns.
            </li>
            <li>
              Once you've entered the name, click "Save" to finalize the
              creation of your marketing list and then import the users using
              the import button.
            </li>
          </ul>
        </div>
      </Box>
    </Box>
  );
};

export default RenderImportRecordsFormStep1;
