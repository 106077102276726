import { DndContext, DragEndEvent, useDraggable } from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { useEffect, useMemo, useState } from "react";
import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { FC } from "react";
import { CSS } from "@dnd-kit/utilities";
import { v4 as uuid } from "uuid";

interface SortableItemProps {
  obj: any;
  elementTemp: any;
  loopIndex?: number;
  inputArray?: boolean;
}
interface DragAndDropProps {
  listData: any;
  children?: React.ReactNode;
  [otherProps: string]: any;
}

const SortableItem: FC<SortableItemProps> = (
  { elementTemp, obj, loopIndex, inputArray = false },
  props,
) => {
  const id = obj?.id ? obj?.id : obj;
  const { setNodeRef, listeners, transform, transition } = useSortable({ id });
  const styles = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <>
      {inputArray === true ? (
        <div {...props.setNodeRef} {...props.listeners} styles={props.styles}>
          {elementTemp(obj, loopIndex, {
            ...props,
            setNodeRef,
            listeners,
            styles,
          })}
        </div>
      ) : (
        <div ref={setNodeRef} {...listeners} style={styles}>
          {elementTemp(obj)}
        </div>
      )}
    </>
  );
};

export default function AppDragAndDrop(props: DragAndDropProps): JSX.Element {
  const { element, listData, inputArray, ...otherProps } = props;
  // const [items, setItems] = useState(listData);
  // updated list
  useEffect(() => {
    if (otherProps && otherProps?.setUpdatedColumn) {
      otherProps?.setUpdatedColumn(listData);
    }
  }, [listData]); //  eslint-disable-line react-hooks/exhaustive-deps

  function dragEndEvent(e: DragEndEvent) {
    const { over, active } = e;
    if (
      over?.data?.current?.sortable?.index > -1 &&
      listData.length > 0 &&
      active?.data?.current?.sortable?.index > -1
    ) {
      let sortedArray = arrayMove(
        listData,
        active?.data?.current?.sortable.index,
        over?.data?.current?.sortable.index,
      );
      if (props?.onDrag) {
        props.onDrag(
          active?.id,
          over?.data?.current?.sortable.index,
          sortedArray,
        );
      }
    }
  }

  return (
    <div>
      <DndContext onDragEnd={dragEndEvent}>
        <SortableContext items={listData}>
          {listData?.map((v: any, index: number) => (
            <SortableItem
              key={uuid()}
              obj={v}
              loopIndex={index}
              inputArray={inputArray}
              elementTemp={element}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
}
