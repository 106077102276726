import React from "react";
import { AppForm, AppFormField, AppFormTextArea, AppSubmitButton } from "../../AppComponents/Forms";
import AppButton from "../../AppComponents/AppButton";
import { Box } from "@mui/material";
import styled from "styled-components";
import { LoaderComponentProps, withLoader } from "../../AppComponents/Loader";
import { useAppDispatch } from "../../Store";
import { WebhookActions } from "../../Store/Slices/Webhook/webhook.actions";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { webhookValidationSchema } from "./webhookValidationSchema";
import { useSelector } from "react-redux";
import { getStoredWebhook } from "../../Store/Slices/Webhook/webhook.selectors";


interface Props extends LoaderComponentProps { }


const Webhook: React.FC<Props> = ({ setLoading }) => {

    const dispatch = useAppDispatch();
    const { showAlert } = useNotification();


    const GetWebhook = useSelector(getStoredWebhook);


    const [isEditing, setIsEditing] = React.useState<boolean>(false);

    const [initialVlues, setInitialVlues] = React.useState({
        callback_url: "",
        verify_token: "",
      });

      const handleSubmit = (values: any, SubmitProps: any) => {
        setLoading(true);
        const payload = {
            callback_url: values.callback_url,
            verify_token: values.verify_token,
        };
        
        SubmitProps?.resetForm();
        dispatch(WebhookActions.generateWebhookAction(payload))
          .then((res: any) => {
            setLoading(false);
            if (res?.payload?.status === 200) {
                showAlert("Webhook is added", "success");
                getWebhook();
            }else {
                showAlert(res?.payload?.data?.error?.message, "error");
            }
          })
          .catch((err: any) => {
            setLoading(false)
            showAlert("Something went wrong", "error");
          })
          .finally(() => {
            setLoading(false);
          });
      };


      const getWebhook = () => {
        dispatch(WebhookActions.getWebhookAction({}))
          .then((res: any) => {
            if (res?.payload?.status === 200) {
                const isEmpty = res?.payload?.data?.data && Object.entries(res?.payload?.data?.data).length === 0;
                if (!isEmpty) {
                    setInitialVlues({
                        callback_url: res?.payload?.data?.data?.config?.callback_url,
                        verify_token: res?.payload?.data?.data?.config?.verify_token,
                    })
                }
            }
           })
          .finally(() => {
            setLoading(false);
          });
      };



      // Get Webhook
  React.useEffect(() => {
    getWebhook();
  }, []);


  const onEditClikc = () => {
    setIsEditing(true)
  }

  const onCncelClick = () => {
    setIsEditing(false)
  }



    return <WebhookWrapper>

        <Box className="form-area">

        
    
                <AppForm
                    initialValues={initialVlues}
                    onSubmit={(values: any, submitProps: any) => {
                        handleSubmit(values, submitProps)
                    
                    }}
                    validationSchema={webhookValidationSchema}
                  >
                    <div className="">
                      <AppFormField
                        label="Enter webhook url"
                        name="callback_url"
                        placeholder="URL"
                        requiredSign={true}
                      />

                      

                      <AppFormTextArea
                        label="Enter webhook token"
                        name="verify_token"
                        tooltip={""}
                        placeholder="Token"
                        requiredSign={true}
                      />
                    </div>

                    <div className="footer">
                      {/* <AppButton variant="grey">
                        Cancel
                      </AppButton> */}
                      <div className="submit-button">
                        {initialVlues?.callback_url === "" || undefined && initialVlues?.verify_token === "" || undefined ? (
                          <AppSubmitButton
                            variant="cta"
                            style={{ width: "180px" }}
                            title={"Save"}
                          /> 
                         ) : ( 

                            isEditing ? <Box display={"flex"} gap={1}> <AppSubmitButton
                            variant="cta"
                            style={{ width: "180px" }}
                            title={"Update"}
                          /> 
                            
                            <AppButton
                            variant="grey"
                            style={{ width: "180px" }}
                            onClick={() => onCncelClick}
                          >
                            Cancel
                            </AppButton>
                            
                            </Box> :
                            <AppButton
                                variant="primarydark"
                                style={{ width: "180px" }}
                                onClick={() => onEditClikc}
                            >
                                Edit
                                </AppButton>
                         )} 
                      </div>
                    </div>
                  </AppForm>

                  </Box>
    
    </WebhookWrapper>
}

export default withLoader(Webhook);


const WebhookWrapper = styled.div`
  && {
    // border: 1px solid;

    width: 100%;
    height: 100%;

    overflow: hidden;
    padding: 24px;

    .form-area {
        border-radius: 8px;
        padding: 16px;
        height: inherit;
        background-color: #fff;
    }

  }
`;