// vendors
import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// components
import Icon from "./../icon";

type Props = {
  label: string;
  icon: string;
  color: string;
};

const InputOption = (props: Props) => {
  const { label, icon, color } = props;
  return (
    <Stack flexDirection="row" alignItems={"center"}>
      <Box
        sx={{
          backgroundColor: color,
          height: 32,
          width: 32,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
        }}
      >
        <Icon icon={icon} size={16} color="#101010" />
      </Box>
      <Typography
        sx={{ color: "#7E8392", pl: "8px" }}
        fontSize="14px"
        lineHeight="21px"
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default React.memo(InputOption);
