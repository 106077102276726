import styled from "styled-components";
import { theme } from "../../../Customization/Theme";

export const AcquitionTableWrapper = styled.div`
  && {
    width: 100%;
    background-color: ${theme.palette.default.white};
    overflow: auto;

    

    > div {
      height: calc(100vh - ${(props: any) => props.height});
    }
    > div:hover {
     // overflow: overlay;
    }
    > div::-webkit-scrollbar {
      width: 6px;
    }
    > div::-webkit-scrollbar-track {
    }
    > div::-webkit-scrollbar-thumb {
      background-color: rgba(126, 131, 146, 0.5);
      border-radius: 4px;
    }
    .table-header {
      // position: absolute;
      top: 74px;
      /* z-index:1; */

      .table-row {
        display: flex;
        align-items: center;
      }
      .col-head {
        max-height: 47px;
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);

        padding: 13px 20px;
        background-color: ${theme.palette.default.white};
        p {
          font-size: 13px;
          font-weight: 500;
        }
        &:first-child {
          p {
          }
        }
      }
    }

    .conversationbox {
      display: flex;
      text-align: center;
      align-items: center;
      gap: 8px;
    }

    .agent-name {
      text-align: center;
    }
    .table-body {
      max-height: 215px;
       

      .actionColumn {
        border-bottom: 1px solid rgba(126, 131, 146, 0.2);
      }
      .table-row {
        display: flex;
        justify-content: flex-start;

        color: rgb(126, 131, 146, 1.5);
        // padding-left:90px;
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .col-body {
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        max-height: 53px;
        padding: 13px 20px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.2);
        &:first-child {
          p {
            color: #101010;
          }
        }
        P {
          font-size: 13px;
        }
      }
    }
    
  }
`;

export const BroadcastTopTitle = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${theme.palette.default.antiFlashWhite};
    .topTitle-left {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .div_tooltip {
        font-size: 10px;
      }
      .time_stats {
        display: flex;
        font-weight: 500;
        align-items: center;
        padding: 10px 24px 10px 10px;
        .div_tooltip {
          font-size: 10px;
          margin-top: 0.094rem;
        }
      }
    }
    .topTitle-right {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
`;
