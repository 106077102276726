// vendors
import { useContext, useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import { v4 as uuid } from "uuid";

// context
import { BuilderContext } from "../../flow-builder-v2/contexts";

// services
import { FlowBuilderService } from "../../../../Services/Apis/FlowBuilder";

const defaultData = {
  response: [{ type: "text", value: "<p>Message comes here</p>" }],
  subType: "text",
};

const useAddNewNode = () => {
  const builderContext = useContext(BuilderContext);
  
  const addNode =(id: string, stage: string, displayOrder: number, callback: Function) => {
    const payload = {
      ...defaultData,
      qid: id,
      stage,
      displayOrder,
      id: uuid(),
    };
    FlowBuilderService.createNode(
      payload,
      {
        appid: JSON.stringify({ botid: builderContext.botId }),
      }
    ).then((response: AxiosResponse<any>) => {
      callback(response.data.status === 200, payload);
    });
  }
  
  return { addNode };
};
export default useAddNewNode;