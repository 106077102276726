import { Menu } from "@mui/material";
import styled from "styled-components";

export const ChatMenuWrapper = styled.div`
  .MuiButtonBase-root-MuiMenuItem-root.Mui-disabled {
    cursor: not-allowed;
  }

  .moreBtn {
    z-index: 1;
  }
`;

export const MainMenu = styled(Menu)`
  && {
    & .MuiPaper-root {
      min-width: "220px";
      box-shadow: "0 2px 8px rgba(0, 0, 0, 0.2) !important";
    }
  }
`;
