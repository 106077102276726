import React from "react";
import styled from "styled-components";
import { AppMaterialIcons } from "../../../AppComponents/AppMaterialIcons";
import { ProtectedAppButton } from "../../../AppComponents/ProtectedComponents";
import { useAppSelector } from "../../../Store";
import { Automationlisting } from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import NoRecords from "../../../assets/images/No_records_found.svg";
import CreateAutomationModal from "./CreateAutomationModal";

interface CreateListModalProps {
  toggleModalCreate: () => void;
  isShowing: boolean;
}

const AutomationNoData: React.FC<CreateListModalProps> = ({
  toggleModalCreate,
  isShowing,
}) => {
  const data = useAppSelector(Automationlisting);
  return (
    <NoRecordUIWrapper>
      <div className="data-container">
        <div className="norecord-data">
          <img width={400} src={NoRecords} alt="" />
          <p>No Records found</p>
        </div>
        <div className="add-list">
          <p>Get Started by creating an automation first.</p>
          <ProtectedAppButton variant="primarydark"
            onClick={() => toggleModalCreate()}
            moduleId="automation_crud"
            specificPermissionKey="create"
            disabled={data?.limit_exceeded || data?.automation_limit <= data?.count}
            title={data?.limit_exceeded || data?.automation_limit <= data?.count ? "You have reached the maximum number of automations on your current plan. If you would like to create more automations, please upgrade your subscription plan." : ""}
          >
            Create Automation
            <AppMaterialIcons iconName="add" />
          </ProtectedAppButton>
        </div>
      </div>

      {/* create marketing list modal */}
      <CreateAutomationModal
        toggleModalCreate={toggleModalCreate}
        isShowing={isShowing}
      />
    </NoRecordUIWrapper>
  );
};
export default AutomationNoData;

const NoRecordUIWrapper = styled.div`
  .data-container {
    min-height: calc(100vh - 140px);
  }
  .data-container,
  .add-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .add-list {
    max-width: 500px;
    text-align: center;
  }
  .norecord-data {
    p {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
    }
  }
  .add-list {
    padding-bottom: 40px;
    p {
      margin-bottom: 15px;
    }
  }
`;
