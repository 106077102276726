import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface Props {
    isShowing: boolean;
    onClose?: any;
    innerRef?: any;
    divStyle?: any;
    children?: React.ReactNode;
    [otherProps: string]: any;
    container?: any;
    width?:any
}

const AppModel: React.FC<Props> = ({
    isShowing,
    onClose,
    innerRef,
    divStyle,
    children,
    container,
    width = "auto",
    ...otherProps
}) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: "8px"
      };
    return (
        <div>
            <Modal
                keepMounted
                open={isShowing}
                onClose={onClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                {...otherProps}
            >
                <Box sx={style}>
                    {children}

                </Box>
            </Modal>
        </div>
    );
}

export default AppModel