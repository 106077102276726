import { Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import uniqid from "uniqid";
import AppButton from "../../../AppComponents/AppButton";
import {
  AppForm,
  AppFormObserver,
  AppSubmitButton,
} from "../../../AppComponents/Forms";
import AppFormCustomRadioField from "../../../AppComponents/Forms/AppFormCustomRadioField";
import { theme } from "../../../Customization/Theme";
import { useNotification } from "../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../Store";
import { AutomationBuilderActions } from "../../../Store/Slices/AutomationBuilder/automationBuilder.actions";
import {
  automationBuilderPayload,
  automationBuilderState,
  modelConfigData,
  modelConfigError,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.selectors";
import {
  closeConfigModal,
  setNodeConfig,
} from "../../../Store/Slices/AutomationBuilder/automationBuilder.slice";
import { templateDetailsByID } from "../../../Store/Slices/email/email.selectors";
import { ReactComponent as NoDataFound } from "../../../assets/images/noDatafound.svg";
import AppReactSelectField from "../../../socket/appComponents/UtilsComponents/AppFormReactSelect";
import { saveGraph } from "../config";
import { EmailActivityConfigSchema } from "../validationSchema";
import { withLoader } from "../../../AppComponents/Loader";

export const EmailViewOptions = [
  {
    id: uniqid(),
    value: "Any Email",
    name: "any",
  },
  {
    id: uniqid(),
    value: "Particular Email",
    name: "perticular",
  },
];
const email_status_options = [
  { id: "sent", name: "Email Sent" },
  { id: "delivered", name: "Email Delivered" },
  { id: "opened", name: "Email Read" },
  { id: "clicked", name: "Email Clicked" },
  { id: "unsubscribed", name: "Email Unsubscribed" },
  { id: "failed", name: "Email Failed" },
  { id: "bounced", name: "Email Bounced" },
];
const EmailActivity = ({ setLoading }: any): JSX.Element => {
  const formRef: any = useRef();
  const dispatch = useAppDispatch();
  const { showAlert } = useNotification();
  const templateDetails: any = useAppSelector(templateDetailsByID);
  const emailModelConfig: any = useAppSelector(modelConfigData);
  const emailModelError: any = useAppSelector(modelConfigError);
  const automationReduxData: any = useAppSelector(automationBuilderState);
  const automationPayload: any = useAppSelector(automationBuilderPayload);

  const [initialValues, setInitialValues]: any = useState({
    emailTemplateId: null,
    email_view: "Any Email",
    email_activity: null,
  });
  useEffect(() => {
  }, [templateDetails]);
  const [formikProps, setFormikProps] = useState<any>(null);
  useEffect(() => {
    if (
      automationPayload?.draftedUIObject?.graphData?.nodes?.[
        automationReduxData?.selectedNodeId
      ]?.nodeObjectData?.any !== undefined
    ) {
      //function to filter object based on Value
      let preFilledData: any = {
        email_activity: {
          id: email_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.id,
          name: email_status_options.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.function
          )?.name,
        },
      };
      if (
        automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.any
      ) {
        preFilledData.email_view = "Any Email";
      } else {
        preFilledData.email_view = "Particular Email";
      }
      if (
        automationPayload?.draftedUIObject?.graphData?.nodes?.[
          automationReduxData?.selectedNodeId
        ]?.nodeObjectData?.emailTemplateId
      ) {
        preFilledData.emailTemplateId = {
          id: emailModelConfig?.template_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId
          )?.id,
          value: emailModelConfig?.template_list.find(
            (ele: any) =>
              ele.id ===
              automationPayload?.draftedUIObject?.graphData?.nodes?.[
                automationReduxData?.selectedNodeId
              ]?.nodeObjectData?.emailTemplateId
          )?.name,
        };
      }
      setInitialValues(preFilledData);
    }
  }, [automationPayload, emailModelConfig]);   // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    dispatch(
      AutomationBuilderActions.getModelConfigByType("email-config")
    ).then(() => setLoading(false))
  }, []);   // eslint-disable-line react-hooks/exhaustive-deps
  const closeModel = () => {
    dispatch(closeConfigModal({}));
  };
  const handleSubmit = async (values: any, submitProps: any) => {
    let payload: any = {
      function: values?.email_activity.id,
      key: "function",
      nodeObject: {},
      nodeId: automationReduxData?.selectedNodeId,
      configText: `${values?.email_activity.name}`,
    };
    if (values?.email_view === "Any Email") {
      payload.nodeObject.any = true;
    } else {
      payload.nodeObject.any = false;
      payload.nodeObject.emailTemplateId = values?.emailTemplateId.id;
    }
    submitProps.setSubmitting(false);
    dispatch(setNodeConfig(payload));
    await saveGraph();

    showAlert("Node Config Saved Successfully!", "success");
    closeModel();
  };
  return (
    <ModelFormWrapper isDataAvaliable={emailModelError?.length}>
      <Typography variant="h6">Email Activity</Typography>
      {emailModelError?.length ? (
        <React.Fragment>
          <div className="image_container">
            <NoDataFound />
          </div>
          <ModelFooter>
            <AppButton onClick={closeModel}>Close</AppButton>
          </ModelFooter>
        </React.Fragment>
      ) : (
        <AppForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={EmailActivityConfigSchema}
          innerRef={formRef}
        >
          <AppFormCustomRadioField
            name="email_view"
            layout="vertical"
            Options={EmailViewOptions}
          />
          {formRef?.current?.values?.email_view === "Particular Email" ? (
            <AppReactSelectField
              name={"emailTemplateId"}
              options={emailModelConfig?.template_list || []}
              label="Email Template"
              displayKey="name"
              valueKey="id"
              placeholder="Select Email Template"
              isSearchable={true}
              isClearable={true}
              hideSelectedOptions={true}
              isRequired={true}
              divStyle={{ background: "none", padding: "0" }}
            />
          ) : null}
          <AppReactSelectField
            name={"email_activity"}
            options={email_status_options}
            label="Email Activity"
            displayKey="name"
            valueKey="id"
            placeholder="Select Activity"
            isSearchable={true}
            isClearable={true}
            hideSelectedOptions={true}
            isRequired={true}
            divStyle={{ background: "none", padding: "0" }}
            optionsPlaceholder="No List Found"
          />
          <AppFormObserver setFormikProps={setFormikProps} />
          <ModelFooter>
            <AppButton onClick={closeModel}>Cancel</AppButton>
            <AppSubmitButton title={"Save Config"} disabled={!formRef?.current?.isValid} />
          </ModelFooter>
        </AppForm>
      )}
    </ModelFormWrapper>
  );
};
export default withLoader(EmailActivity);

const ModelFormWrapper = styled.div`
width: ${(props: any) => props.isDataAvaliable ? "500px" : "400px"};
  h6 {
    border-bottom: 1px solid ${theme.palette.default.border};
    margin-bottom: 15px;
    padding: 0 0 0 0;
    font-size: 16px;
    font-weight: 500;
  }
  .image_container{
    text-align:center;
  }
`;
const ModelFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid ${theme.palette.default.border};
  padding: 15px 0 0;
  button:last-child {
    margin-left: 15px;
  }
`;
