import { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Paper, TableContainer, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow, { tableRowClasses } from "@mui/material/TableRow";
import uniqid from "uniqid";
import {
  LoaderComponentProps,
  withLoader,
} from "../../../../AppComponents/Loader";
import AppNoDataLoader from "../../../../AppComponents/AppNoDataLoader";
import AppButton from "../../../../AppComponents/AppButton";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../../Customization/Theme";
import { TableWrapper } from "../../../../TableStyle";
import NoRecordsFound from "../../../UiComponents/NoRecordsFound/NoRecordsFound";

export interface Props {
  rows?: any;
  handleSingleDisconnectButton?: any;
}

const FacebookPageTable: React.FC<Props> = ({
  rows,
  handleSingleDisconnectButton,
}) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  // const handleDisconnectButton = (singleRow: any) => {
  //   console.log("singleRow", singleRow);
  // }

  // no data found
  if (!rows || rows.length < 1) {
    return (
      <NoRecordsFound height="calc(100vh - 144px)" />
    );
  }

  return (
    <StyledSessionDevicesBox>
      <TableContainer>
        <TableWrapper height={"calc(100vh - 170px)"}>

        <div>

        <Box className="table-header">
            <Box className="table-row">
              <Box className="col-head">
                Page Name
              </Box>
              <Box
                className="col-head"
                justifyContent={"center"}
              >
                Connect/Disconnect
              </Box>
              <Box
                justifyContent={"flex-end"}
                className="col-head"
              >
                Action
              </Box>
            </Box>
          </Box>
          <Box className="table-body">
            {rows &&
              rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((singleRow: any, index: number) => {
                  return (
                    <Box className="table-row">
                      <Box
                        className="col-body handle-long-text"
                        minWidth={200}
                      >
                        <p>
                        {singleRow.name}
                        </p>
                      </Box>

                      <Box
                         className="col-body"
                         justifyContent={"center"}
                      >
                        <AppButton
                          variant="danger"
                          onClick={() =>
                            handleSingleDisconnectButton(singleRow)
                          }
                          style={{ display: "inline-flex" }}
                        >
                          {/* onClick={() => handleSingleDisconnectButton(singleRow)}> */}
                          Disconnect
                        </AppButton>
                      </Box>

                      <Box
                        className="col-body"
                        justifyContent={"flex-end"}
                      >
                        <AppButton
                          variant="primary"
                          onClick={() =>
                            navigate(
                              `/settings/channels/facebook/config/${singleRow.id}`,
                            )
                          }
                          style={{ display: "inline-flex" }}
                        >
                          Configure
                        </AppButton>
                      </Box>
                    </Box>
                  );
                })}

            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 48 * emptyRows,
                  borderBottom: "none",
                }}
              >
                <TableCell
                  colSpan={6}
                  style={{
                    borderBottom: "none",
                  }}
                />
              </TableRow>
            )}
          </Box>
          </div>
        </TableWrapper>
        {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          // rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton
          showLastButton
        /> */}
      </TableContainer>
    </StyledSessionDevicesBox>
  );
};

export default FacebookPageTable;

const StyledSessionDevicesBox = styled.div`
  && {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    min-height: 480px;
    background-color: ${theme.palette.default.white};
    padding-top: 16px;
    table {
    }
    .handle-long-text {
    }
    .extraInfoValue {
      margin-left: 8px;
      padding: 4px 8px;
      font-weight: 400;
      font-size: 12px;
      border-radius: 500px;
      color: #27b783;
      background-color: #d6fff0;
    }

    .success {
      color: #27b783;
      background-color: #d6fff0;
    }
    .error {
      color: #f34e4e;
      background-color: #ffe3e3;
    }
  }
`;

const StyledTableCell = styled(TableCell)(({}) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.default.black,
    fontWeight: "400",
    fontSize: "14px",
    textTransform: "capitalize",
    height: "48px",
    borderBottom: "1px solid #F5F6F8",
  },
  [`&.${tableCellClasses.body}`]: {
    color: theme.palette.default.black,
    fontWeight: "400",
    fontSize: "15px",
    height: "48px",
    borderBottom: "1px solid #F5F6F8",
  },
  [`&.${tableRowClasses.root}`]: {},
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&": {},
  "&:nth-of-type(odd)": {
    // backgroundColor: "blue",
  },
  // hide last border
  "&:last-child td, &:last-child th": {},
  "&:hover": {
    // backgroundColor: "#F5F6F8",
  },
}));
