import { createAsyncThunk } from "@reduxjs/toolkit";
import { WebhookNetworkService } from "./webhookNetworkService";

export class WebhookActions {
  // get Webhook
  static getWebhookAction = createAsyncThunk(
    "WebhookSlice/getWebhookAction",
    (data: any, { dispatch, rejectWithValue }) => {
      return WebhookNetworkService.getWebhookNetwork()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => {});
    },
  );
  // generate Webhook

  static generateWebhookAction = createAsyncThunk(
    "WebhookSlice/generateWebhookAction",
    (data: any, { dispatch, rejectWithValue }) => {
        return WebhookNetworkService.generateWebhookNetwork(data)
            .then(async (response: any) => {
                return response
            })
            .catch((error: { response: unknown }) => {
                return rejectWithValue(error?.response)
            })
            .finally(() => { })
    }
  )
}
