import { FunctionComponent } from "react";
import styled from "styled-components";
import { useAppSelector } from "../../../Store";
import { BotTypeName } from "../../../Store/Slices/DashboardRedux/dashboard.selectors";
import BotTopMenu from "../../Menu/BotTopMenu";
import Bot from "./Bot";
import Bot2 from "./Bot2";

interface Props {
  domElements?: any[];
  className?: string;
  rowHeight?: number;
  onLayoutChange?: (layout: any, layouts: any) => void;
  cols?: any;
  breakpoints?: any;
  containerPadding?: number[];
  preventCollision?: boolean;
}
const ParentBot: FunctionComponent<Props> = (props) => {
  const botType = useAppSelector(BotTypeName);
  return (
    <ParentBotWrapper>
      <BotTopMenu />
      {botType && botType === "Web Bot" ? (
        <Bot2 domElements={[]} preventCollision={false} margin={undefined} />
      ) : (
        <Bot domElements={[]} preventCollision={false} margin={undefined} />
      )}
    </ParentBotWrapper>
  );
};
export default ParentBot;

const ParentBotWrapper = styled.div`
  & {
  }
`;
