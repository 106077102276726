import { Outlet } from "react-router-dom";
import styled from "styled-components";
import useDocumentTitle from "../../../Hooks/useDocumentTitle";

import CustomizeSidebar from "../Customize/CustomizeSidebar";
import { ConfigureMenuOptions } from "../utils/contants";

const BotSettings = () => {
  useDocumentTitle("Bot Settings");
  return (
    <CustomizeWrap>
      <CustomizeSidebar Options={ConfigureMenuOptions} />
        <MainBox>
          <Outlet />
        </MainBox>
    </CustomizeWrap>
  );
};

export default BotSettings;

const CustomizeWrap = styled.div`
  && {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    overflow: hidden;

    .MuiPaper-elevation1 {
      box-shadow: none !important;
    }
  }
`;
const MainBox = styled.div`
  && {
    width: 85%;
    /* height: 100%; */
    /* height: calc(100vh - 168px); */
    background-color: #f5f6f8;
   
    display: flex;
    overflow: hidden;
    overflow-y: auto;
  }
`;
