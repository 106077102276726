import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface IModalHeader {
  children: React.ReactElement;
}

const ModalHeader = (props: IModalHeader) => {
  const { children } = props;
  return (
    <Stack
      flexDirection="row"
      sx={{ p: "16px", borderBottom: "1px solid #F5F6F8" }}
      justifyContent="space-between"
    >
      {children}
    </Stack>
  );
};
export default ModalHeader;
