import { LoadingButton } from "@mui/lab";
import {
  List,
  ListItem,
  TextField,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import "react-phone-input-2/lib/style.css";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { theme } from "../../../../../../Customization/Theme";


interface StyledProps {
  userMessage?: any;
  tooltipPosition?: any;
  isTooltipVisible?: any;
  WIDGET_POSITION?: any;
  variant?: any;
  enableuserinput?: number;
  chatopened?: any;
  devicewidth?: any;
  deviceheight?: any;
  jumpPosition?: any;
  theme?: any;
  selected?: any;
  isInvalid?: any;
  disabled?: boolean;
  ref?: any;
  fontSize?: any;
  fontFamily?: any;
  primaryColor?: any;
  contrastText?: any;
}


export const StyledWebBotStaticContainer = styled.div`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledWebBotFrameWrapper = styled.div<StyledProps>`
  && {
    width: 384px;
    height: calc(100vh - 280px);
    max-height:610px;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    overflow: hidden;
    border-radius: 8px;
    border-top: 10px solid ${({ primaryColor }: any) => { return primaryColor }};
    border-right: 2px solid ${({ primaryColor }: any) => { return primaryColor }};
    border-left: 2px solid ${({ primaryColor }: any) => { return primaryColor }};
    border-bottom: 2px solid ${({ primaryColor }: any) => { return primaryColor }};

    /* HEADER */
    .Web-StyledWidgetHeader{
      
    }
    /* FOOTER */
    .Web-WidgetCopyrightBox{

    }
    /* INPUT */

    .Web-StyledWidgetInputBox{

    }

    .Web-inputActionBox{
    background-color: ${({ primaryColor }: any) => primaryColor} !important;
    svg{
      fill: ${({ contrastText }: any) => contrastText} !important;

    }
    }

    /* CHATSCREEN */
    .Web-StyledMessageRow{

    }
    .Web-StyledMessageRowOverflow-Visible {
      overflow: visible !important;
    }
    .Web-ChatAvatar{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50px;
      object-fit: cover;
      font-family: Rubik !important;
      pointer-events: none;
      margin-bottom: 1rem;
    }

    .Web-StyledContentWrapper{

    }

    .Web-StyledMessageContainer{
      max-width: 80%;
    }

    .Web-StyledMessageText{
      width: 100%;
      line-height: 1.5;
      letter-spacing: 0.5px;
      padding: 8px 10px;
      font-size:  ${({ fontSize }: any) => { return fontSize ? fontSize : "14" }}px;
      font-family: ${({ fontFamily }: any) => { return fontFamily ? fontFamily : "Inter" }} !important;
      
    }

    .Web-StyledMessageTimeStamps{
      font-family: ${({ fontFamily }: any) => { return fontFamily ? fontFamily : "Inter" }} !important;
    }

    .notificationBlockWrapper{
      width: 100%;
      height: 100%;
      background-color: ${theme.palette.default.white};
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
    }
    .bannerCarouselBlockWrapper{
      width: 100%;
      margin-bottom: 4px;
    }
    .quickMenuBlockWrapper{
      width: 100%;
      background-color: ${theme.palette.default.white};
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
    }
  }
`;

export const StyledWidgetHeader = styled.div<StyledProps>`
  && {
    background-color: ${theme.palette.default.white};
    height: 74px;
    max-height: 74px;
    min-height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 8px;
    // border-radius: 12px 12px 0px 0px;
    position: relative;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
    // z-index: 1;
    svg {
      fill: ${({ theme }: any) => {
    return theme?.components?.header?.icon?.color;
  }};
    }
  }
`;

export const WidgetHeaderBrand = styled(Box)`
  && {
    height: 40px;
    flex: 0.9;
    display: flex;
    align-items: center;
    gap: 12px;

    font-family: ${({ theme }: any) => {
    return theme?.components?.header.text.family;
  }};
    color: ${({ theme }: any) => {
    return theme?.components?.header.text.color;
  }};

    //disable user copy and select
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      object-fit: cover;
      border: 1px solid;
    }

    .titleBox {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const WidgetHeaderBrandTitle = styled(Typography)`
  && {
    font-family: ${({ theme }: any) => {
    return theme?.components?.header.text.family;
  }};
    color: ${({ theme }: any) => {
    return "#282828";

    // return theme?.components?.header.text.color;
  }};
    font-weight: 500;
    font-size: 1rem;
    line-height: 18px;
    cursor: default;

    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
   
  }
`;
export const WidgetHeaderStatus = styled(Typography)`
  && {
    font-family: ${({ theme }: any) => {
    return theme?.components?.header.text.family;
  }};
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }: any) => {
    return theme?.components?.header.text.color;
  }};
    cursor: default;
  }
`;

export const WidgetHeaderRight = styled(Box)`
  && {
    color: ${({ theme }: any) => {
    return theme?.components?.header.text.color;
  }};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;
    position: relative;
    .MuiIconButton-root {
      padding: 6px !important;
    }
  }
`;

export const WidgetHeaderLogo = styled.img`
  && {
    height: 40px;
    width: 40px;
  }
`;

export const WidgetMainContent = styled(Box) <StyledProps>`
  && {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    height: 100%;
    padding: 0 !important;

    -ms-overflow-style: none;
    scrollbar-width: none;

    background-color: #f1f1f1;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    .css-1oexdke.css-1oexdke {
      padding: 0 !important;
    }

  
  }
`;
export const WidgetMainChat = styled.div<StyledProps>`
  && {
    position: relative;
      height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    // border: 4px solid green;
    padding: 12px 5px 12px 12px;

    .styledList {
      padding: 2px 7px 2px 4px;
       overflow-y: overlay;
    overflow-x: hidden;
      ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #cacaca;
      }
      ::-webkit-scrollbar {
        width: 4px;
        background-color: #dfdfdf;
      }
    }

    .scrollToBottomDefaultButton {
      display: none;
      border-radius: 50%;
      border-width: 0;
      cursor: pointer;
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: 16px;
      transform: translate(-50%, -50%);
      margin: 0 auto;
      width: 28px;

      &:before {
        content: "▼";
        color: ${theme.palette.default.darkGrey};
      }
    }

    /* -ms-overflow-style: none; IE and Edge */
    /* scrollbar-width: none; Firefox */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    /* display: none; */

    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const StyledList = styled(List)`
  && {
    margin: 2px 0 0;
    padding: 2px 0;
  }
`;
export const StyledListItem = styled(ListItem) <StyledProps>`
  && {
    display: flex;
    align-items: flex-end;
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    padding: 4px 0;
    margin: 8px 0;
    gap: 10px;
  }

  img {
    display: ${(props: any) => (props.userMessage ? "none" : "flex")};
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid
      ${(props: any) =>
    props.userMessage
      ? theme.palette.default.text
      : theme.palette.secondary.main};
    box-shadow: 0px 1px 4px #999;
  }
`;

export const MessageContainer = styled.div<StyledProps>`
  && {
    max-width: 75%;

    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: ${(props: any) => (props.userMessage ? "right" : "left")};
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    /* padding: 4px 0; */
    overflow: hidden;
    color: ${({ userMessage, theme }: any) =>
    userMessage
      ? theme?.components?.user_message?.text?.color
      : theme?.components?.bot_message?.text?.color};
    animation-delay: 2s;
    position: relative;

    &:after {
      ${({ userMessage, theme }: any) =>
    userMessage
      ? `
        right: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `
      : `
        left: -1px;
        bottom: 6px;
        transform: rotate(180deg);
        `}
      content: ' ';
      position: absolute;
      width: 0;
      height: 0;
      border: 10px solid;
      border-color: ${({ userMessage, theme }: any) =>
    userMessage
      ? theme?.components?.user_message?.background?.color
      : theme?.components?.bot_message?.background?.color}
        transparent transparent transparent;
    }
  }
`;

export const MessageMediaRowAttachment = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: ${theme.palette.default.lightGrey};
  font-size: 14px;
  border-radius: 5px;
  svg {
    margin-right: 5px;
    max-width: 15px;
  }
`;
export const MessageRow = styled.div<StyledProps>`
  && {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: ${(props: any) =>
    props.userMessage ? "flex-end" : "flex-start"};
    gap: 4px;
    margin: 6px 0;
    /* margin-bottom: 10px; */
    word-wrap: break-word;
    align-items: center;
    //disable user copy and select
    /* -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    .avtar img,
    img {
      display: flex;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid
        ${({ theme }: any) => {
    return theme?.components?.header.background.color;
  }};
      box-shadow: 0px 1px 4px #999;
    }
  }
`;

export const MessageContainerText = styled.div<StyledProps>`
  && {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: ${({ userMessage, theme }: any) =>
    userMessage ? "2px 5px 2px 4px" : "2px 4px"};
    font-family: ${({ userMessage, theme }: any) =>
    userMessage
      ? theme?.components?.user_message?.text?.family
      : theme?.components?.bot_message?.text?.family};
    font-weight: 400;
    font-size: ${({ userMessage, theme }: any) =>
    userMessage
      ? theme?.components?.user_message?.text?.size
      : theme?.components?.bot_message?.text?.size};
    line-height: 24px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    padding: 8px;
    border-radius: ${({ userMessage, theme }: any) =>
    userMessage ? "10px 0px 0px 10px" : "0px 10px 10px 0px"};
    background-color: ${({ userMessage, theme }: any) =>
    userMessage
      ? theme?.components?.user_message?.background?.color
      : theme?.components?.bot_message?.background?.color};

    p {
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 15px 4px 2px;
      font-family: ${({ userMessage, theme }: any) =>
    userMessage
      ? theme?.components?.user_message?.text?.family
      : theme?.components?.bot_message?.text?.family};
      font-weight: 400;
      font-size: ${({ userMessage, theme }: any) =>
    userMessage
      ? theme?.components?.user_message?.text?.size
      : theme?.components?.bot_message?.text?.size};
      line-height: 24px;
      &:first-child {
        margin-top: 0;
      }
    }
    a {
      color: blue;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        color: ${({ theme }: any) => theme?.palette?.primary.dark};
      }
      &:active {
        color: blue;
      }
    }

 
  }
`;

export const MessageTimeStampBox = styled.div<StyledProps>`
  && {
    display: flex;
    width: 100%;
    justify-content: ${(props: any) => (props.userMessage ? "right" : "left")};
    word-wrap: break-word;
    display: flex;
    flex-direction: row;
    font-family: ${({ theme }: any) => theme?.typography.fontFamily};
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
  }
`;

export const MessageMediaRow = styled.div<StyledProps>`
  && {
    background-color: ${({ theme }: any) => theme?.palette?.secondary.main};
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: 6px;
    word-wrap: break-word;
    width: 75%;
    max-width: 75%;

    img {
      width: 100%;
      height: 11rem;
      object-fit: cover;
      border: 1px solid ${theme.palette.default.border};
      border-radius: 4px;
    }
  }
`;

export const StyledIframeVideoPlayer = styled.iframe`
  && {
    overflow: hidden !important;
    border-radius: 6px;
    width: 100%;
    object-fit: contain;
    min-height: 10rem;
    min-height: 170px;
    max-height: 12rem;
    background-color: ${({ theme }: any) => theme?.palette?.default?.text};
  }
`;
export const StyledReactVideoPlayer = styled(ReactPlayer)`
  && {
    overflow: hidden !important;
    border-radius: 6px;
    width: 100%;
    object-fit: contain;
    min-height: 10rem;
    min-height: 170px;
    max-height: 12rem;
    background-color: ${({ theme }: any) => theme?.palette?.default?.text};
  }
`;



export const WidgetInputBoxWrap = styled(Box) <StyledProps>`
  && {
    height: 10%;
    max-height: 44px;
    min-height: 44px;
    width: 100%;
    background-color: ${({ theme }: any) =>
    theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.08);

    pointer-events: ${(props: any) => (props.disabled ? "none" : "auto")};
    opacity: ${(props: any) => (props.disabled ? 0.6 : 1)};
  }
`;

export const MessageInputField = styled(TextField)`
  && {
    height: 40px;
    background-color: ${({ theme }: any) =>
    theme?.components?.chat_wall.background.color};
    width: 100%;
    font-family: ${({ theme }: any) => theme?.font?.primary.family};
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #7e8392;
    padding: 4px 8px 4px 1rem;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    // justify-content:baseline;
  }
`;

export const StyledWidgetInputBox = styled.div<StyledProps>`
  && {
    width: 100%;
    padding: 10px 6px;
    justify-content: space-between;
    margin: 0px auto 0px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #f1f1f1;
    pointer-events: ${(props: any) => (props.disabled ? "none" : "auto")};
    transition: all 0.5s linear 0s;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    gap: 8px;


    .inputIconBox {
      background-color: ${({ theme }: any) =>
    theme?.components?.user_input.normal.background.color};
      background-color: transparent;
      min-width: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};

      .MuiSvgIcon-root {
        font-size: 1.3rem;
        color: ${({ theme }: any) =>
    theme?.components?.user_input.placeholder.text.color};
        color: ${({ theme }: any) => theme?.components?.primary_color_scheme["500"]};
        color: ${(props: any) =>
    props.disabled
      ? theme?.palette?.default.darkGrey
      : props.theme?.components?.primary_color_scheme["500"]};
      }
    }

    .inputActionBox {
      border-radius: 50%;
      background-color: ${theme?.palette.default.darkGrey};
      min-width: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      overflow: hidden;

      cursor: ${(props: any) => (props.disabled ? "not-allowed" : "pointer")};

      .MuiIconButton-root {
        border-radius: 8px;
        height: 34px;
        width: 34px;

        &:hover {
        }
      }

      .MuiSvgIcon-root {
        font-size: 20px;
        color: ${theme.palette.default.white};
      }
    }
  }
`;

export const StyledWidgetBotInputField = styled.input`
  && {
    background-color: ${({ theme }: any) =>
    theme?.components?.user_input.normal.background.color};
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    width: 100%;
    outline: none;
    border: none;
    padding: 0px 1rem 0px 1rem;
    font-family: ${({ theme }: any) =>
    theme?.components?.user_input.normal.text.family};
    font-weight: 400;
    font-size: 0.95rem;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &::placeholder {
      color: ${({ theme }: any) =>
    theme?.components?.user_input.placeholder.text.color};
    }

    border: 1px solid ${({ theme }: any) => theme?.palette?.primary?.main} !important;
      border-radius: 43px;
      height: 40px;
      position: relative;


    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
    }
  }
`;

export const WidgetCopyrightBox = styled(Box) <StyledProps>`
  && {
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
    z-index: 1;
    background-color:#F1F1F1;
    border-radius: 0px 0px 8px 8px;
    display: flex;
    justify-content: right;
    padding-right: 10px;
    align-items: center;
    font-family: ${({ theme }: any) => theme?.font?.primary.family};
    border-top: 1px solid
      ${({ theme }: any) => theme?.components?.primary_color_scheme["100"]};
    font-weight: 400;
    font-size: 10px;
    box-shadow: none;
    color: #7e8392;
    overflow: hidden;
  }

  span,
  a {
    font-family: ${({ theme }: any) => theme?.font?.primary.family};

    font-weight: 500;
    font-size: 10px;
    letter-spacing: 1px;
    /* text-transform: capitalize; */
    color: #7e8392;
    max-height: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
  }

  img {
    height: 15px;
    object-fit: cover;
  }
`;






//* Buttons *//

export const PrimaryButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: capitalize;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid
      ${({ theme }: any) => {
    return theme?.components?.button_background?.main;
  }} !important;

    background-color: ${({ theme }: any) => {
    return theme?.components?.button_background?.main;
  }};
    color: ${({ theme }: any) => {
    return theme?.components?.button_text_color?.main;
  }};

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${({ theme }: any) => {
    return theme?.components?.button_text_color?.main;
  }};
      width: 100%;
      svg {
        margin-right: 5px;
        max-width: 13px;
      }
    }

    &:hover {
      background-color: ${({ theme }: any) => {
    return (
      theme?.components?.hover_background?.main ||
      theme?.components?.header.background.color
    );
  }};
      color: ${({ theme }: any) => {
    return (
      theme?.components?.button_hover_text?.main ||
      theme?.components?.header.text.color
    );
  }};
      box-shadow: 0px 1px 4px #999;
      a {
        color: ${({ theme }: any) => {
    return (
      theme?.components?.button_hover_text?.main ||
      theme?.components?.header.text.color
    );
  }};
        width: 100%;
      }
    }

    &:disabled {
      background-color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;

export const OutlineButton = styled(LoadingButton)`
  && {
    outline: none;
    text-transform: none;
    text-align: center;

    padding: 6px 12px;
    font-size: 14px;
    font-weight: 500;
    font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }};

    border-radius: 6px;
    border: 1px solid
      ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }} !important;

    background-color: transparent;
    color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};

    a {
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
      width: 100%;
      font-family: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.family;
  }} !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Poppins !important;
      gap: 12px;
      svg {
        font-size: 17px;
      }
    }

    &:hover {
      background-color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.background
      ?.color;
  }};
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};

      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.normal?.text?.color;
  }};
        width: 100%;
      }
    }
    &:disabled {
      background-color: transparent;
      color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};

      a {
        color: ${({ theme }: any) => {
    return theme?.components?.bot_options_standard?.hover?.text?.color;
  }};
        width: 100%;
      }
    }
  }
`;


export const StyledLanguageBox = styled.div`
  && {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 0.7rem;
    z-index: 92333923;
    ::-webkit-scrollbar {
      /* display: none; */

      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
    .title {
      font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
      color: ${({ theme }: any) => theme?.components?.bot_message?.text?.color};
      font-weight: 400;

      font-size: 15px;
    }
    li {
      font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
      color: ${({ theme }: any) => theme?.components?.bot_message?.text?.color};
      font-weight: 400;
      min-height: 30px;
      font-size: 15px;
      padding: 4px 12px;
      margin-right: 1px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      &:hover {
        background-color: ${({ theme }: any) => {
    return theme?.components?.chat_wall.background.color;
  }};
      }
    }
  }
`;


export const StyledQuickMenuWrapper = styled.div`
  && {
    .MuiAccordion-root {
      box-shadow: none;
      .MuiAccordionSummary-root {
        min-height: 44px;
        box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.08);
      }
      .MuiAccordionSummary-content {
        align-items: center;
        gap: 8px;
      }
      .Mui-expanded {
        margin: 0;
      }
      .MuiCollapse-root {
        overflow: hidden;
        overflow-y: overlay;
        max-height: 190px;
      }

      .MuiAccordionDetails-root {
        background-color: #fdfcfc;
        /* background-color: #24d31e; */
        width: 100%;
        padding: 0.15rem 0;
        /* padding: 0; */
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        overflow: overlay;
        overflow-x: hidden;
        height: auto;
        /* gap: 6px;  */
      }
    }
    .bodyContent {
    }

    .accordianTitle {
      font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
      font-weight: 500;
      font-size: 0.85rem;
      /* line-height: 1.2rem; */
      text-transform: capitalize;
      /* color: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.color}; */
      /* opacity: 0.9; */
      color: #606060;
    }

    /* width: 100%;
    padding: 0.5rem 0.4rem;
    background-color: #fdfcfc;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: overlay;
    overflow-x: hidden;
    height: auto;
    gap: 6px; */
    .singleCard {
      /* background-color: #bb2727; */
      width: 33.2%;
      /* max-width: 33%; */
      /* flex: 1 1 100%;   */
      /* flex-basis: 100%;   */
      /* max-width: 130px;
      flex: 1 0 28%; */
      height: 90px;
      /* margin: 6px; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;
      /* border-radius: 8px; */
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;
      text-align: center;
      padding: 6px 2px 2px;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
      .cardImage {
        width: 52px;
        height: 44px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 12.5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }

    .singleRow {
      width: 98%;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 6px;
      gap: 1rem;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.06);
      }
      .rowImage {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        width: 100%;
        font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 13px;
      }
    }
  }
`;

export const StyledNotificationBlockWrapper = styled.div`
  && {
    .notificationIcon {
      svg {
        font-size: 19px;
        color: ${({ theme }: any) => {
    return theme?.components?.header.text.color;
  }};
      }
    }

    .notificationListWrapper {
      height: 100%;
      padding: 0.5rem 1rem 6px;
      position: relative;
      background-color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: start;
      gap: 3px;
      border-radius: 4px;
      overflow: hidden;
      flex: 1;

      .singleRow {
        background-color: rgba(0, 0, 0, 0.03);
        width: 100%;
        padding: 4px;
        height: 96px;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        color: black;
        cursor: pointer;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
        border: 1px solid transparent;

        &:hover {
          transform: scale(1.01);
          border: 1px solid rgba(0, 0, 0, 0.06);
        }
        .rowLeftBox {
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          height: 100%;
          min-width: 80px;
          padding: 2px;
          img {
            height: 72px;
            width: 72px;
            object-fit: cover;
            border-radius: 4px;
          }
        }

        .rowRightBox {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 4px 6px 2px;
          overflow: hidden;

          .rowRightTop {
            display: flex;
            width: 100%;
            overflow: hidden;
            min-height: 20px;
            font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
            font-weight: 500;
            font-size: 0.85rem;
            line-height: 1.2rem;
            text-transform: capitalize;
            color: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.color};

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .rowRightMiddle {
            display: flex;
            justify-content: left;
            align-items: center;
            width: 100%;
            max-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
          }
          .rowRightBottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            overflow: hidden;

            font-size: 0.75rem;
            line-height: 1.2rem;
            color: ${theme.palette.default.darkGrey};
            .footerText {
              font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
              font-size: 0.75rem;
              line-height: 1.2rem;
              color: ${theme.palette.default.darkGrey};
            }

            button {
              color: dodgerblue;
            }
          }
        }
      }
    }
    .staticButton {
      button {
        color: #7e8392;
        min-height: 36px;
        max-height: 36px;
      }
      position: -webkit-sticky;
      position: sticky;
      z-index: 999;
      bottom: 0rem;
      border-radius: 10px;
      min-height: 36px;
      max-height: 36px;
      width: 100%;
      margin-top: auto;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
`;

export const StyledResendBoxWrap = styled.div`
  && {
    width: 200px;
    height: 40px;
    color: ${({ theme }: any) => theme?.components?.user_input.focus.text.color};
    font-size: 14.5px;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 4px 1rem;
    position: absolute;
    bottom: 24px;
    right: 45px;
    button {
      margin-left: auto;
      text-decoration: underline;
      font-size: 12px;
      &:hover {
        background-color: transparent;
        text-decoration: none;
      }
    }
    .timerWrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: ${theme.palette.default.placeholder};
      margin-left: auto;
      min-width: 50px;
      svg {
        margin-right: 5px;
        width: 12px;
        margin-top: -2px;
      }
    }
  }
`;

//inputs
export const ActionBoxWrapper = styled.div<StyledProps>`
  display: flex;
  justify-content: flex-start;
  padding: 6px 12px;
  background-color: white;
  button {
    padding: 2px 10px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
`;

// Quick Menu Header
export const QuickMenuHeaderWrapper = styled.div`
  & {
    .notificationListWrapper {
      display: flex;
      flex-wrap: wrap;
      padding-right: 6px;
      ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: #cacaca;
      }
      ::-webkit-scrollbar {
        width: 4px;
        background-color: #dfdfdf;
      }
    }
    .singleRow {
      width: 98%;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 6px;
      gap: 1rem;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.06);
      }
      .rowImage {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        width: 100%;
        font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 13px;
      }
    }

    .singleCard {
      /* background-color: #bb2727; */
      width: 33.2%;
      /* max-width: 33%; */
      /* flex: 1 1 100%;   */
      /* flex-basis: 100%;   */
      /* max-width: 130px;
      flex: 1 0 28%; */
      height: 90px;
      /* margin: 6px; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 6px;
      /* border-radius: 8px; */
      overflow: hidden;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(0, 0, 0, 0.08);
      border: 1px solid transparent;
      text-align: center;
      padding: 6px 2px 2px;
      border: 1px solid rgba(0, 0, 0, 0.08);

      &:hover {
        transform: scale(1.01);
        border: 1px solid rgba(0, 0, 0, 0.08);
      }
      .cardImage {
        width: 52px;
        height: 44px;
        object-fit: cover;
        border-radius: 4px;
      }

      .buttonTitle {
        font-family: ${({ theme }: any) =>
    theme?.components?.bot_message?.text?.family};
        color: ${({ theme }: any) => theme?.components?.bot_message?.text?.color};
        opacity: 0.8;
        font-weight: 500;
        font-size: 12.5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
`;
