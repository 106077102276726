import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Grid } from "@mui/material";
import uniqid from "uniqid";
import { AppMaterialIcons } from "../../../../AppComponents/AppMaterialIcons";
import AppCustomTooltip from "../../../../AppComponents/AppTooltip";
import { getTextWithoutHtml } from "../../../../Utils";
import moment from "moment";

// Render WhatsApp Analytics Statistics
export const renderAnalyticsColumn = (data: any, apiData?: any) => {
  return data.map((col: any) => {
    return (
      <Grid item xs={3} md={3} lg={2} xl={1.7}>
        <Box key={uniqid()} className="col">
          <span className="iconWrapper">
            <AppMaterialIcons iconName={col.icon} />
          </span>
          <h6>
            {col.label}{" "}
            <AppCustomTooltip placement="bottom" title={col.info}>
              <InfoOutlinedIcon />
            </AppCustomTooltip>
          </h6>
          <strong>{apiData[col.key]}</strong>
        </Box>
      </Grid>
    );
  });
};

export const convertHtmlToWhatsappText = (text: string) => {
  if (!text) return;

  let strBold = text;
  let replacedBold = strBold
    ?.replace(/<strong>/g, "*")
    ?.replace(/<\/strong>/g, "*");

  let strItalic = replacedBold;
  let replacedItalic = strItalic
    ?.replace(/<em>/g, "_")
    ?.replace(/<\/em>/g, "_");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    ?.replace(/<del>/g, "~")
    ?.replace(/<\/del>/g, "~");

  let strMono = replacedStrike;
  let replacedMono = strMono
    ?.replace(/<tt>/g, "```")
    ?.replace(/<\/tt>/g, "```")
    ?.replace(/<code>/g, "```")
    ?.replace(/<\/code>/g, "```");

  let whatsappText: string = getTextWithoutHtml(replacedMono || "")
    .replace(/\n/g, "")
    .replace(/&nbsp;/g, "");
  return whatsappText;
};
export const convertWhatsappTextToHtml = (text: string) => {
  if (!text) return;

  let strBold = text;
  let replacedBold = strBold
    .replace(/\*(?=\w)/, "<strong>")
    .replace(/(?<=\w)\*/, "</strong>");

  let strItalic = replacedBold;
  let replacedItalic = strItalic
    .replace(/\_(?=\w)/, "<em>")
    .replace(/(?<=\w)\_/, "</em>");

  let strStrike = replacedItalic;
  let replacedStrike = strStrike
    .replace(/\~(?=\w)/, "<del>")
    .replace(/(?<=\w)\~/, "</del>");

  return replacedStrike;
};

export const VariableRegexReplace = (
  originalString: string,
  replacementArray: string[]
) => {
  // Define the regex pattern and replacement array
  const regexPattern = /\{\{([1-9]|10)\}\}/gm;

  // Function to handle the replacement
  const handleReplacement = (match: string, index: number) => {
    // Get the index from the match
    const matchIndex = parseInt(match?.slice(2, -2), 10);

    // Check if the index is within the range of the replacement array
    if (matchIndex >= 1 && matchIndex <= replacementArray?.length) {
      // Return the replacement string from the array
      return replacementArray[matchIndex - 1];
    } else {
      // Return the original match if the index is out of range
      return match;
    }
  };
};
// export const updateVariableStatus = (
//   inputString: string,
//   variableLimit: number = 10
// ) => {

// var tempArray = inputString?.split(" ");
// var variablesArray = [];
// for (var i = 0; i < tempArray.length; i++) {
//   if (/\{{([^)]+)\}}/gm.test(tempArray[i])) {
//     if (variableLimit >= variablesArray?.length) {
//       variablesArray.push(tempArray[i]);
//     }
//   }
// }
// return variablesArray;
// };

const generateResolveVariables = (text: string, variables: any[]) => {
  if (!variables?.length) {
    return [];
  }

  let array: any[] = [];
  let regexArray: any = getVariablesArray(text);
  variables?.length > 0 &&
    variables?.forEach((curItem: any, index: number) => {
      let temp = {
        key: regexArray[index],
        // value: typeof curItem === "string" && curItem,
        value: "",
        index: 0,
      };
      array.push(temp);
    });
  return array;
};

const getVariablesArray = (text: string) => {
  const getUniqueMatches = (matches: string[]): string[] => {
    const sortedMatches = matches.sort((a, b) => {
      return parseInt(a.match(/\d+/)![0]) - parseInt(b.match(/\d+/)![0]);
    });

    const isConsecutiveSeries = matches.every((match, index) => {
      if (index === 0) return parseInt(match?.match(/\d+/)![0]) === 1;
      const prevMatch = sortedMatches[index - 1];
      const currentNumber = parseInt(match?.match(/\d+/)![0]);
      const prevNumber = parseInt(prevMatch?.match(/\d+/)![0]);
      return currentNumber === prevNumber + 1;
    });

    if (!isConsecutiveSeries) {
      sortedMatches.pop(); // Remove unmatched element
    }

    return sortedMatches?.filter((match, index, arr) => {
      const isFirstOccurrence = arr.indexOf(match) === index;
      const isLastOccurrence = arr.indexOf(match) === arr.lastIndexOf(match);
      const isNotIncludedInPrevious =
        index === 0 || !arr[index - 1].includes(match);
      const isNotAtStart = !text?.startsWith(match);
      const isNotAtEnd = !text?.endsWith(match);

      return (
        isFirstOccurrence &&
        isLastOccurrence &&
        isNotIncludedInPrevious &&
        isNotAtStart &&
        isNotAtEnd
      );
    });
  };

  const pattern = /\{\{([1-9]|10)\}\}/gm;
  const matches = text?.match(pattern) || [""];
  const uniqueMatches = getUniqueMatches(matches);
  return uniqueMatches;
};

export const removeDuplicateObjects = (array: any[], property: string) => {
  const uniqueIds: any[] = [];
  const unique = array?.filter((element) => {
    const isDuplicate = uniqueIds?.includes(element[property]);
    if (!isDuplicate) {
      uniqueIds?.push(element[property]);
      return true;
    }
    return false;
  });
  return unique;
};

export const removeEmptyKeysObj = (obj: any) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const convertDateTimeToUTC = (date: any, time: any) => {
  let a = moment(date).format("YYYY-MM-DD");
  let b = moment(time).format("HH:mm:ss");
  let c = moment(a + " " + b, "YYYY-MM-DD HH:mm:ss").toString();
  let utc = moment(c).utc().format();
  return utc;
};
export const convertUTCToDateTime = (utcDateTime: any) => {
  let a = moment.utc(utcDateTime).local().format()
  return a;
};


export const convertDataToEmailBroadcastForm = (res: any) => {
  let formData: any = {
    broadcast_name: res?.broadcast_name,
    broadcast_template_id: res?.broadcast_template_id,

    subject: res?.subject || 0,
    previewText: res?.previewText || 0,
    from_name: res?.from_name || 0,
    from_email: res?.from_email || null,
    reply_to: res?.reply_to || [],
    footer: res?.footer || "",
    email_attachment: res?.email_attachment || [],
    broadcast_action: "broadcast_marketing_list",
    marketing_list: 0,
    filters: null,
    selected_rows: [],
    search_term: "",

    when_to_send: "schedule_broadcast",
    date: res?.sent_on ? convertUTCToDateTime(res?.sent_on) : new Date(Date.now()),
    time: res?.sent_on ? convertUTCToDateTime(res?.sent_on) : new Date(Date.now()),

    resolved_header_variables: [],
    resolved_body_variables: [],
    resolved_buttons_variables: [],
  };
  return formData;
};
