import styled from "styled-components";
import { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../../../../Config/app-config";
import { StyleFacebookButton } from "../../../../Customization/CommonElements";
import { useCurrentBot } from "../../../../Store/Slices/DashboardSlices";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";


const BOT_ID_KEY = 'currentBotId'
const EXCHANGE_KEY = 'currentBotExchangeKey'
interface Props {
  componentClicked: any;
}
const handleConnectButton = (bot_id: string) => {
  AppConfig.setUserData(BOT_ID_KEY, bot_id);
};


const InstagramIntegrations: React.FC<Props> = ({
  componentClicked
}) => {
  const [resultData, setResultData] = useState<any>();
  let navigate = useNavigate();
  let { showAlert } = useNotification();

  const currentBot = useSelector(useCurrentBot);
  var exchange_token = "";
  var facebook_response = "";
  var facebook_exchange_token = "";

  const responseFacebook = (response: any) => {
    facebook_response = response;
    facebook_exchange_token = response.accessToken;
  };

  return (
    <InstagramPageWrap>
      <div className="pageTopbar">
        {/* <AppIcon title={'ri-arrow-left-line'} size="26px" color="#282828" /> */}
      </div>
      <div className="ctaBox">
        <img
          className="companyLogo"
          src="https://upload.wikimedia.org/wikipedia/commons/e/e7/Instagram_logo_2016.svg"
          alt=""
        />
        <p className="ctaTitle">Connect with Instagram Account</p>
        {
          currentBot &&

          <FacebookLogin class="ctaTitle"
            appId="544372894068159"
            autoLoad={false}
            scope="instagram_basic,instagram_manage_messages"
            onClick={componentClicked}
            callback={componentClicked}
            textButton={<StyleFacebookButton><i className="ri-links-line"></i> Connect With Click </StyleFacebookButton>}
          />
        }

      </div>
    </InstagramPageWrap>
  );
};

export default InstagramIntegrations;

const InstagramPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
   
    .pageTopbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      width: 100%;
    }

    .ctaBox {
      display: flex;
      height: 40%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
      /* background-color: red; */
      display: flex;
      justify-content: center;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }
    .kep-login-facebook
    {
      font-size:14px;
      border: none;
      border-radius: 6px!important;
      background-color:#3654E3!important;
      height: 40px;
     padding: 6px 12px!important;
     font-weight: 400;
     text-transform: inherit;
    }
  }
`;
