// vendors
import { styled, alpha } from "@mui/material/styles";
import Paper, { PaperProps } from "@mui/material/Paper";

interface CardProps extends PaperProps {
  secondary?: boolean;
  focused?: boolean;
}

export const PaperStyled = styled(Paper)<CardProps>(
  ({ secondary, focused, theme }) => ({
    width: "100%",
    // boxShadow: `0px 2px 4px ${alpha(theme.palette.common.black, 0.08)}`,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
    position: "relative",
    border: "1px solid transparent",
    cursor: "pointer",
    ...(secondary && {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.08)}`,
    }),
    ...(focused && {
      border: `2px solid ${theme.palette.primary.main}`,
      // boxShadow: `0 0 0 0.25rem ${alpha(
      //   theme.palette.primary.main,
      //   0.3
      // )} !important`,
    }),
    ul: {
      marginLeft: "15px",
    },
  })
);
