import { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

function findLinkEntities(contentBlock: any, callback: any, contentState: any) {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "PROPERTY"
    );
  }, callback);
}

function getLinkComponent(config: any) {
  interface Props {
    entityKey: string;
    children: any;
    contentState: any;
  }

  return class Link extends Component<Props> {
    static propTypes = {
      entityKey: PropTypes.string.isRequired,
      children: PropTypes.array,
      contentState: PropTypes.object,
    };

    state = {
      showPopOver: false,
    };

    render() {
      const { children, entityKey, contentState } = this.props;
      const data = contentState.getEntity(entityKey).getData();
      return (
        <span className="rdw-property-decorator-wrapper">
          <a>{children}</a>
        </span>
      );
    }
  };
}

export default (config: any) => ({
  strategy: findLinkEntities,
  component: getLinkComponent(config),
});
