import React from 'react'
import { AppFormField, AppFormMobileField } from '../../../../../../../AppComponents/Forms'
import styled from 'styled-components'

interface PhoneNumberProps {
  disabled: boolean
}

const PhoneNumber = (props: PhoneNumberProps): JSX.Element => {
  const { disabled } = props
  return (
    <Container>
      <AppFormMobileField name="phone_number" label="Phone Number" mobileCountryFormat="phone_number_country"
        disabled={disabled} />
      <AppFormField name="phone_number_button_text" disabled={disabled} placeholder="Enter text for the button *" charactersLimit={25} />
    </Container>
  )
}

export default PhoneNumber

const Container = styled.div`
&&{
  display:flex;
  flex-direction:column;
}`