import styled from "styled-components";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import AppButton from "../../../../AppComponents/AppButton";
import AppIcon from "../../../../AppComponents/AppIcon";
import {
  AppForm, AppSubmitButton
} from "../../../../AppComponents/Forms";
import AppMultiColumnsFields from "../../../../AppComponents/Forms/AppMultiColumnsFields";
import { useNotification } from "../../../../Hooks/useNotification/useNotification";


export const ZohoConfigureValidationSchema = Yup.object().shape({
  botname: Yup.string()
    .required("botname is required")
    .label("Email"),
  friends: Yup.array().of(Yup.object({
    name:Yup.string().required("name is required"),
    email:Yup.string().email('email sahi daal na bhai').required("email is required"),
    phone:Yup.number().required("name is required"),
  }))
});

interface Props {
  handleDisconnectButton: any;
}






const ShopifyConfigure: React.FC<Props> = ({ handleDisconnectButton }) => {
  const { showAlert } = useNotification();
  const navigate = useNavigate();
  let { bot_id } = useParams();
  const [userInput, setUserInput] = useState<any>({
    botname:'',
    friends: [
      {
        name: "",
        email: "",
        phone: "",
      },
    ],
  });

  const handleSubmitBtn = (values: any, SubmitProps: any) => {
    setUserInput(values);
  };

  useEffect(() => {}, []);

  return (
    <ZohoPageWrap>
      <div className="pageTopbar">
        <AppIcon
          title={"ri-arrow-left-line"}
          onClick={() => handleDisconnectButton()}
          size="26px"
          color="#282828"
        />
        <div className="accountInfo">
          Connected: abc@gmail.com
          <AppButton variant="danger" onClick={() => handleDisconnectButton()}>
            Disconnect
          </AppButton>
        </div>
      </div>
      <div className="ctaBox">
        <img
          className="companyLogo"
          src="https://www.zoho.com/branding/images/zoho-logo.png"
          alt=""
        />
        <p className="ctaTitle">Configure your Zoho Account</p>
      </div>

      <div className="configureFormFieldsWrap">
        <AppForm
          initialValues={userInput}
          onSubmit={(values: any, submitProps: any) => {
            handleSubmitBtn(values, submitProps);
            setUserInput(values);
          }}
          // validationSchema={ZohoConfigureValidationSchema}
        >
    
          





          <AppMultiColumnsFields>
            <AppButton style={{ margin: "0.5rem 0", opacity: 0 }} disabled />
            <AppButton style={{ margin: "0.5rem 0", opacity: 0 }} disabled />
            <AppSubmitButton
              variant="cta"
              style={{ margin: "0.5rem 0" }}
              title={"Save Changes"}
            />
          </AppMultiColumnsFields>
        </AppForm>
      </div>
    </ZohoPageWrap>
  );
};

export default ShopifyConfigure;

const ZohoPageWrap = styled.div`
  && {
    height: 100%;
    width: 100%;
    /* padding: 1rem; */
    display: flex;
    flex-direction: column;
    align-items: center;

    .pageTopbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      /* background-color: #F5F6F8; */
      padding: 0 0.4rem;
      width: 100%;

      .accountInfo {
        /* background-color: red; */
        height: 100%;
        /* width: 30%; */
        display: flex;
        justify-content: end;
        align-items: center;
        gap: 2rem;
        font-size: 1rem;
        color: #7e8392;
      }
    }

    .ctaBox {
      display: flex;
      height: 20%;
      flex-direction: column;
      gap: 1.6rem;
      padding: 1rem;
      justify-content: center;
      align-items: center;
    }

    .companyLogo {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      object-fit: contain;
      background-color: #f5f6f8;
      padding: 0.2rem;
    }

    .buttonBox {
      width: 280px !important;
    }

    .ctaTitle {
      font-size: 1.2rem;
    }

    .configureFormFieldsWrap {
      display: flex;
      /* justify-content: center; */
      /* background-color: #f5f6f8; */
      padding: 1rem 2rem;
      flex-direction: column;
      gap: 1rem;
      /* width: 60%; */
    }
  }
`;
