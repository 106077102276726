import React, { memo } from "react";
import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { Rating } from "@mui/material";

interface Props {
  message: string;
  feedback?: string | null;
  rating?: number | null;
}

const AppNoInputBox: React.FC<Props> = ({ message, rating, feedback }) => {
  return (
    <StyledBoxWrapper>
      {message}
      {rating && (
        <>
          <div className="rating">
            Chat rating:{" "}
            <Rating name="read-only" value={Number(rating)} readOnly />
          </div>
          <div className="rating">Feedback: {feedback ?? "No feedback provided"}</div>
        </>
      )}
    </StyledBoxWrapper>
  );
};

export default memo(AppNoInputBox);

const StyledBoxWrapper = styled.div`
  && {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap:8px;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    position: relative;
    background-color: #fef1e1;
    border: 1px solid rgb(254, 220, 179);

    .rating{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
`;
