import React, { useEffect, useState } from 'react'
import { TimeoutContainer, TimeoutHeader, TimeoutTable, TimeoutWrapper } from './ChatAssignmentStyles';
import AppButton from '../../../../AppComponents/AppButton';
import { AppForm, AppSubmitButton } from '../../../../AppComponents/Forms';
import AppFormCheckboxField from '../../../../AppComponents/Forms/AppFormCheckboxField';
import { AppToolTip } from '../../../UiComponents/AppToolTip/AppToolTip';

import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useSelector } from 'react-redux';
import { WorkspaceData } from '../../../../Store/Slices/Settings/LiveChat/Workspace/workspace.selectors';
import { WorkspaceActions } from '../../../../Store/Slices/Settings/LiveChat/Workspace/workspace.actions';
import { useAppDispatch } from '../../../../Store';
import { useParams } from 'react-router-dom';
import { ChatAssignmentValidationSchema } from './ChatAssignmentValidationSchema';
import { withLoader, LoaderComponentProps } from '../../../../AppComponents/Loader';
import { useNotification } from '../../../../Hooks/useNotification/useNotification';



interface Props extends LoaderComponentProps { }


const ChatAssignment: React.FC<Props> = ({ setLoading }) => {



    const { bot_id } = useParams();
    const WorkspaceConfigData = useSelector(WorkspaceData)
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const { showAlert } = useNotification();

    const HEADERS = {
        appid: JSON.stringify({
            botid: bot_id,
        }),
    };

    const [userInput, setUserInput] = useState<any>({
        assignment: ""
    });
    const searchedMessage: any = [{}];
    const listingData: any = [];


    const handleSubmit = (values: any, SubmitProps: any) => {
        // setIsLoading(true);
        if (WorkspaceConfigData) {
            const payload = {
                auto_assignment: values?.assignment,

            }
            const data = {
                payload,
                HEADERS
            }

            dispatch(WorkspaceActions.updateWokspaceConfigData(data));
            SubmitProps?.setSubmitting(false);
            showAlert("Updated", "success");

        }

    }

    useEffect(() => {
        setUserInput({
            assignment: WorkspaceConfigData?.auto_assignment
        })
        // setLoading(false);
    }, [WorkspaceConfigData?.auto_assignment])

    useEffect(() => {
        getWorkspaceData()
        setLoading(false);
    }, [])

    const getWorkspaceData = () => {
        const data = {
            HEADERS
        }
        dispatch(WorkspaceActions.getWorkspaceConfigData(data))
    }






    return (
        <TimeoutWrapper>
            <TimeoutHeader>
                <div className="response-header__left">
                    <h4>Chat Assignment</h4>
                </div>
                <div className="response-header__right">
                    <div className="response-search">

                    </div>
                    <div className="response-button">

                    </div>
                </div>
            </TimeoutHeader>

            <TimeoutContainer>
                <TimeoutTable>


                    <div className='table-data'>



                        <AppForm
                            initialValues={userInput}
                            onSubmit={(values: any, submitProps: any) => {
                                handleSubmit(values, submitProps);
                            }}
                            validationSchema={ChatAssignmentValidationSchema}
                        >


                            <div className='table-row__content'>
                                <div className='table-content '>
                                    <div className='table-content__text'>
                                        <div>
                                        <AppFormCheckboxField style={{width: "auto"}} name="assignment" />
                                        </div>
                                        <div className='checkbox-text'>
                                            <h4>Auto Assignment</h4>

                                            <p className='table-content__text b-bottom'>Chats are evenly distributed among agents with accepting chats status. When all agents hit their limit, new visitors are queued.</p>

                                            <div className='tool-tip'>
                                                <AppToolTip
                                                    tooltipProps={{
                                                        title: "The maximum number chat conversation handled simultaneously",
                                                        placement: "top",
                                                    }}
                                                    iconButtonProps={{ disableRipple: true }}
                                                    IconComponent={
                                                        <HelpOutlineOutlinedIcon
                                                            sx={{ color: "#7E8392", fontSize: "20px" }}
                                                        />
                                                    }
                                                />
                                                <p>Set each agent's chat limit in their profile details</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='table-content__text'>

                                    </div>
                                    <div className='table-content__text'>
                                        <div className='table-actions'>

                                        </div>
                                    </div>

                                </div>


                            </div>


                            <div className='footer'>
                                <AppButton variant='grey'>
                                    Cancel
                                </AppButton>
                                <div>

                                    <AppSubmitButton
                                        variant="cta"
                                        style={{ width: "180px" }}
                                        title={"Save"}
                                    // locading={isLoading} 
                                    />
                                </div>
                            </div>

                        </AppForm>





                    </div>

                </TimeoutTable>
            </TimeoutContainer>
        </TimeoutWrapper>
    )
}

export default withLoader(ChatAssignment);
