import styled from "styled-components";
import { theme } from "../../../../Customization/Theme";
import { Paper } from "@mui/material";

export const BusinessWrapper = styled.div`
  //   width: 100%;
  margin-left: 1rem;
  margin-top: 1rem;
`;

export const BusinessHeader = styled.div`
  display: flex; /* width: 100%; */
  width: auto;

  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #fff;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  .response-header__left {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    h4 {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .response-header__right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;

export const BusinessBody = styled.div`
  & {
    width: 100%;
    display: flex;
    /* height: calc(100%); */
  }
`;

export const HoursContainer = styled(Paper)`
  width: 65%;
  height: calc(100%);
  overflow: auto;
  form {
    // height: 100%;
  }
`;

export const PreviewArea = styled.div`
  & {
    width: 35%;
    // height: calc(100% - 112px);
  }
`;

export const HoursTable = styled.div`
  height: 100%;
  .table-header {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid rgba(203, 205, 211, 0.3);
    .heading-text {
      min-width: 280px;
      h4 {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
  .table-body {
    overflow: auto;
  }
  .table-content {
    display: flex;
    // padding: 0.675rem;
    padding: 5px 16px;
    justify-content: space-between;
    .table-content__text {
      display: flex;
      align-items: center;
      .day-switch__wrapper {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        justify-content: space-between;
        min-width: 200px;
      }
      h4 {
        font-weight: 500;
        font-size: 0.875rem;
      }
      p {
        font-size: 0.875rem;
        color: ${theme.palette.default.darkGrey};
      }
      .table-actions {
      }
    }
  }

  .table-content > :first-child {
    min-width: 280px;
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
  }

  .table-content > :last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 120px;
  }
  .table-content > :nth-child(2) {
    width: calc(100% - 360px);
    gap: 0.875rem;
  }
`;

export const SwitchWrapper = styled.div`
  & {
    display: flex;
    align-items: center;
    form {
      div {
        margin: 0 !important;
      }
    }
  }
`;

export const BusinessHoursTop = styled.div`
  & {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid ${theme.palette.default.grey};

    input {
      max-width: 586px;
      min-width: 240px;
    }

    textarea {
      max-width: 586px;
      min-width: 240px;
    }
  }
`;

export const BusinessHoursBody = styled.div`
  & {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .overlay {
      position: absolute;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color:${theme.palette.default.lightGrey}; */
      background-color: rgba(204, 204, 204, 0.2);
      pointer-events: auto; /* This prevents interactions with elements below the overlay */
    }

    .submit-btn {
      padding: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
`;
