
import { RootState } from "../../index";

export const SMSDataStore = (state: RootState) => state.SMS.data;
export const SMSTemplateListing = (state: RootState) => state.SMS.data.filteredData;
export const SMSTemplateListingPagination = (state: RootState) => state?.SMS?.data?.smstemplatePagination;
export const SMSTemplateListingCount = (state: RootState) => state.SMS.data.smsTemplateListCount;
export const SMSTagOptionList = (state: RootState) => state.SMS.data.tagListOptionsData;
export const CategoriesListOptionsData = (state: RootState) => state.SMS.data.categoriesListOptionsData;
export const templateUploadedData = (state: RootState) => state.SMS.data.templateUploadedData;
export const testSMSModel = (state: RootState) => state.SMS.data.testSMSModel;
export const smsEnabled = (state: RootState) => state.SMS.data?.smsEnable;

export const templateByIdData = (state: RootState) => state.SMS.data.templateByIdData
export const DeleteError = (state: RootState) => state?.SMS?.data?.deleteError;
export const DeleteSuccess = (state: RootState) => state?.SMS?.data?.deleteSuccess;
export const CategoriesPaginationData = (state: RootState) => state.SMS.data.categoriesPaginationData;

export const SMSTemplateListingLoading = (state: RootState) => state.SMS.loading;