import { registerNodes } from "./../Config/RegisterNodes";

const getNodeConfig = (type: string, childNode?: string) => {
  let selectedNode = registerNodes.filter(
    (regNode: any) => regNode.type === type
  );
  if (childNode) {
    selectedNode = selectedNode[0].childNodes.filter(
      (regNode: any) => regNode.type === childNode
    );
  }

  return selectedNode[0] ? selectedNode[0] : registerNodes[1];
};
export default getNodeConfig;