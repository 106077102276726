import { createSlice } from "@reduxjs/toolkit";
import { APIKeyActions } from "./apikey.actions";
interface initialStateTypes {
  apiKey: any;
  error: any;
  loading: boolean;
}

const initialState: initialStateTypes = {
  apiKey: null,
  error: null,
  loading: false,
};
const ApiKeySlice = createSlice({
  name: "ApiKeySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //  get API Key
      .addCase(APIKeyActions.getAPIKey.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(APIKeyActions.getAPIKey.fulfilled, (state, action) => {
        state.apiKey = action?.payload?.data?.api_token;
      })
      .addCase(APIKeyActions.getAPIKey.rejected, (state, error: any) => {
        state.apiKey = null;
        state.error = error?.data?.error;
      })
      //  Generate API Key
      .addCase(APIKeyActions.generatedAPIKey.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(APIKeyActions.generatedAPIKey.fulfilled, (state, action) => {
        state.apiKey = action?.payload?.data?.api_token;
      })
      .addCase(APIKeyActions.generatedAPIKey.rejected, (state, error: any) => {
        state.apiKey = null;
        state.error = error?.data?.error;
      });
  },
});

export default ApiKeySlice.reducer;
