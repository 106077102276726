import styled from "styled-components";
import { theme } from "./Customization/Theme";
import { TablePagination, Typography } from "@mui/material";

// Table Wrapper
export const TableWrapper = styled.div`
  & {
    padding: 0px 12px 10px;
    width: 100%;
    border-left: 1px solid  ${(props: any) => props.border ? theme.palette.default.border : "transperant" };;
    
    border-right: 1px solid  ${(props: any) => props.border ? theme.palette.default.border : "transperant" };;
    border-bottom: 1px solid  ${(props: any) => props.border ? theme.palette.default.border : "transperant" };;
    background-color: ${theme.palette.default.white};
    > div {
      overflow: hidden;
      height: ${(props: any) => props.height};
    }
    > div:hover {
      overflow: overlay;
    }
    > div::-webkit-scrollbar {
      width: 6px;
    }
    > div::-webkit-scrollbar-track {
      //   background-color: rgba(236, 236, 236, 1);
    }
    > div::-webkit-scrollbar-thumb {
      background-color: rgba(126, 131, 146, 0.5);
      border-radius: 4px;
    }
    .table-header {
      position: sticky;
      top: 0;
      z-index: 1;
      .table-row {
        display: flex;
        align-items: center;
      }
      .col-head {
        max-height: 47px;
        text-transform: capitalize;
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        text-align: center;
        font-weight: 500;
        border-bottom: 1px solid rgba(126, 131, 146, 0.5);
        border-top: 1px solid rgba(126, 131, 146, 0.5);
        padding: 13px 20px;
        background-color: ${theme.palette.default.white};
        p {
          font-size: 13px;
          font-weight: 500;
        }
        &:first-child {
          p {
          }
        }
      }
    }
    .table-body {
      .actionColumn {
        border-bottom: 1px solid rgba(126, 131, 146, 0.2);
      }
      .table-row {
        display: flex;
        jsutify-content: center;

        color: rgb(126, 131, 146, 1.5);
      }
      .latest_report {
        max-width: 780px;
        min-width: 780px;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .col-body {
        text-transform: capitalize;
        div > :first-child {
        }
        flex: 1;
        display: flex;
        word-break: break-all;
        align-items: center;
        max-height: 53px;
        padding: 13px 20px;
        border-bottom: 1px solid rgba(126, 131, 146, 0.2);
        &:first-child {
          p {
            color: #101010;
            text-decoration: none;
            max-width: ${(props: any) => props.firstColTextWidth};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        P {
          font-size: 13px;
        }
      }
    }
  }
`;

// Table Filter
export const TableFilter = styled.div`
  & {
    height: 64px;
    background-color: ${theme.palette.default.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid  ${(props: any) => props.border ? theme.palette.default.border : "transperant" };
    border-bottom: none;

    .filter-left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .filter-right {
      display: flex;
      align-items: center;
    }
  }
`;

// Pagination Styled For Table
export const AppPagination = styled(TablePagination)`
  & {
    padding: 8px 0 !important;
    .MuiSelect-select {
      border: 1px solid ${theme.palette.default.grey};
      border-radius: 6px;
    }

    p {
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-select {
      text-align: center !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.default.darkGrey};
    }

    .MuiTablePagination-actions > button:last-child {
      margin-left: 8px;
    }
    .MuiTablePagination-actions > button {
      border: 1px solid ${theme.palette.default.grey} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.darkGrey};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
    .MuiTablePagination-actions .Mui-disabled {
      border: 1px solid ${theme.palette.default.RightBorderDivder} !important;
      padding: 4px;
      border-radius: 0;
      color: ${theme.palette.default.RightBorderDivder};
      .MuiSvgIcon-root {
        font-size: 20px;
      }
    }
  }
`;

export const BroadcastTopTitle = styled.div`
  & {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .topTitle-left {
      display: flex;
      align-items: center;

      .div_tooltip {
        font-size: 10px;
      }
    }
    .topTitle-right {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
`;

export const TypographyColumn = styled(Typography)`
  & {
    font-weight: ${(props: any) =>
      props?.header === "Status" && "500 !important"};
    color: ${(props: any) => {
      if (props?.header === "Status") {
        const status = props?.row?.status;
        switch (status) {
          case "APPROVED":
            return theme.palette.default.success;
          case "REJECTED":
            return theme.palette.default.error;
          case "FAILED":
            return "red";
          default:
            return "";
        }
      } else {
        return "";
      }
    }};
  }
`;
