// vendors
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// components
import Icon from "./../Icon";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// styles
import { IconContainerStyled, StackContainerStyled } from "./nodeType.styled";

interface INodeType {
  icon?: string;
  text: string;
  color?: string;
  secondaryText?: string;
  noPadding?: boolean;
  secondaryAction?: any;
  actionCallback?: any;
  isChecked?: boolean;
}

const NodeType = (props: INodeType) => {
  const {
    icon,
    text,
    color,
    secondaryText,
    noPadding,
    secondaryAction,
    actionCallback,
    isChecked,
  } = props;
  return (
    <StackContainerStyled noPadding={noPadding}>
      <Stack flexDirection="row" alignItems="center">
        {icon && (
          <IconContainerStyled nodeColor={color ? color : ""}>
            <Icon icon={icon} size={16} color="default.black" />
          </IconContainerStyled>
        )}
        <Typography
          variant="subtitle1"
          fontWeight={500}
          color={"default.black"}
        >
          {text}
        </Typography>
      </Stack>
      {secondaryAction && (
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <Checkbox onChange={actionCallback} defaultChecked={isChecked} />
          }
          label="Verify Otp?"
        />
      )}
      {secondaryText && (
        <Typography fontWeight={500}>{secondaryText}</Typography>
      )}
    </StackContainerStyled>
  );
};
export default NodeType;
