import { createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationNetworkService } from "./notificationNetworkService";
import { templateDataToUserFormData } from "../../../Components/Broadcast/WhatsApp/Utils/utils";

export class NotificationActions {

  //* testWhatsapp // 
  static getNotificationConfig = createAsyncThunk(
    "WhatsappSlice/getNotificationConfig",
    (data: any, { dispatch, rejectWithValue }) => {
      return NotificationNetworkService.getNotificationConfig()
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );

  static updateNotificationConfig = createAsyncThunk(
    "WhatsappSlice/updateNotificationConfig",
    (data: any, { dispatch, rejectWithValue }) => {
      return NotificationNetworkService.updateNotificationConfig(data)
        .then(async (response: any) => {
          return response;
        })
        .catch((error: { response: unknown }) => {
          return rejectWithValue(error?.response);
        })
        .finally(() => { });
    }
  );
}
